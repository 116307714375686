import create from "zustand";

interface ISelectedEvent {
  selectEventStartDate: Date | null;
  setSelectEventStartDate: (eventDate: Date) => void;
};

const UseSelectedEventStartDate = create<ISelectedEvent>(
    (set) => ({
        selectEventStartDate: null,
        setSelectEventStartDate: (selectEvent) => {
          set(() => ({
            selectEventStartDate: selectEvent,
          }));
        },
    })
);

export default UseSelectedEventStartDate;
