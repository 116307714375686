import { useMemo, useState } from "react";
import LicensesAccordion from "../components/licensesAccordion/LicensesAccordion";
import { Drawer, InputAdornment, TextField } from "@material-ui/core";
import styles from "./DevicePage.module.scss";
import "./DevicePage.css";
import { Search } from "@material-ui/icons";
import UseDevicePage from "./UseDevicePage";
import DeviceList from "../components/deviceList/DeviceList";
import DeviceForm from "../components/deviceForm/DeviceForm";
import SelectedDevices from "../components/selectedDevices/SelectedDevices";
import FilterDevice from "../components/filterDevice/FilterDevice";
import SetDeviceProfileForm from "../components/deviceProfileForm/SetDeviceProfileForm";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import UseDimension from "components/dimension/UseDimension";
import UseProfilePage from "modules/config/deviceConfig/presentation/pages/profile/UseProfilePage";
import EventDevices from "../components/eventDevices/EventDevices";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

const DevicePage = () => {
  const [open, setOpen] = useState(true);
  const {
    preloading,
    deviceList,
    filteredDeviceList,
    selectedDevices,
    filterDevice,
    profileList,
    action,
    searchTerm,
    totalCount,
    setSearchTerm,
    onChangeProfileHandler,
    onFilterHandle,
    onSelectItem,
    onChangeFilterHandle,
    onClickEditDeviceHandle,
    setSelectedDevices,
    onCloseModal,
    onChangeDeviceProfile,
    onChangePaginationHandle,
    onSearch,
  } = UseDevicePage();

  const { perfilVinculo } = UseProfilePage();

  const { hasAccessRole } = useAuth();
  const { dimensions, isMobile } = UseDimension();

  const hasLicensesAccess = useMemo(
    () => hasAccessRole("LicencaEquipamento") ?? false,
    [hasAccessRole]
  );

  return (
    <div id={styles.DevicePage}>
      {preloading ? (
        <div>carregando</div>
      ) : (
        <>
          {hasLicensesAccess && (
            <div className={styles.licenses}>
              <LicensesAccordion expanded={open} setExpanded={setOpen} />
            </div>
          )}
          <div className={styles.filter}>
            <FilterDevice
              value={filterDevice}
              onFilter={onFilterHandle}
              onChange={onChangeFilterHandle}
            />
            <form
              onSubmit={onSearch}
              style={{
                width: isMobile && dimensions.width < 746 ? "100%" : "",
              }}
              className={styles.deviceSearchForm}
            >
              <FormItemContainer
                padding={0}
                className={styles.formTextField}
                minWidth={dimensions.width > 530 ? 300 : undefined}
                maxWidth={dimensions.width > 750 ? 300 : undefined}
                width="100%"
              >
                <TextField
                  className={`device-header-search ${styles.formSearch}`}
                  variant="outlined"
                  label="Buscar"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </FormItemContainer>
            </form>
          </div>
          {!!selectedDevices.length && (
            <SelectedDevices
              deviceList={selectedDevices}
              onChangeProfileHandler={onChangeProfileHandler}
            />
          )}
          {perfilVinculo === 2 && <EventDevices />}
          <Drawer open={action === "editarPefil"} anchor="right">
            <SetDeviceProfileForm
              deviceProfileList={profileList}
              onClickClose={onCloseModal}
              onClickNext={onChangeDeviceProfile}
              onClickBack={onCloseModal}
              selectedDevice={selectedDevices}
            />
          </Drawer>
          <DeviceList
            deviceList={filteredDeviceList || []}
            onClickCopyItem={(device) => {}}
            onClickItem={(device) => {
              onClickEditDeviceHandle(device.id);
            }}
            selectedDevices={selectedDevices}
            onSelectItem={onSelectItem}
            onChangePaginationHandle={onChangePaginationHandle}
            pagination={filterDevice.pagination}
            totalCount={totalCount}
            perfilVinculo={perfilVinculo}
          />
          <Drawer
            open={action === "adicionar" || action === "editar"}
            anchor="right"
          >
            <DeviceForm
              deviceList={deviceList}
              setSelectedDevices={setSelectedDevices}
            />
          </Drawer>
        </>
      )}
    </div>
  );
};
export default DevicePage;
