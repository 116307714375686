import Layout from "components/layout/presentation/Layout"
import { ConfigPlansPage } from "./ConfigPlansPage"
import { useMenuContext } from "components/layout/presentation/menu/context/MenuContext";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export const ConfigPlans = () => {
    const { verifyRouter } = useMenuContext();

    const [verifying, setVerifying] = useState(true);

    useEffect(() => {
        verifyRouter() && setVerifying(false);
    }, [verifyRouter]);

    return (
        <Layout>
            {verifying 
                ? (
                    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress style={{ margin: "auto" }} />
                    </div>
                ) : <ConfigPlansPage />
            }
        </Layout>
    )
}