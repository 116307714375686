import { TicketPaymentDetailsTypes } from "modules/cashlessDashboard/domain/dto/rechargePaymentMethodsData/IGetRechargePaymentMethodsDataResponse";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetRechargePaymentMethodsDataUseCase = async (
  service: ICashlessDashboardService,
  params: IUseCaseParams
): Promise<TicketPaymentDetailsTypes> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getRechargePaymentMethodsData(filterRequest);

  return response;
};

export default GetRechargePaymentMethodsDataUseCase;
