import { UseConfigMeepTickets } from "modules/config/integrationsConfig/presentation/pages/configMeepTickets/UseConfigMeepTickets";
import IntegratedPage from "../components/integratedPage/IntegratedPage";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import LandingPage from "components/freePage/LandingPage";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import ConfigMeepTickets from "modules/config/integrationsConfig/presentation/pages/configMeepTickets/ConfigMeepTickets";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

const MeepTicketsPage = () => {
  const { loading, integrated } = UseConfigMeepTickets();
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  const hasIntegration = useMemo(() => integrated ?? false, [integrated]);

  return (
    <div style={{ marginBottom: "2rem" }}>
      {loading && <CircularProgress size={16} color="inherit" />}
      {!loading && (hasIntegration ? <IntegratedPage /> :
        <LandingPage
          title="Meep Tickets"
          subtitle="Ingressos"
          description="Meep Tickets é a plataforma de venda de ingressos da Meep, a funcionalidade completa em um só sistema"
          aboutTitle={<>Venda além de ingressos, <span className='purpleText'>venda experiências!</span></>} 
          about="Com a Meep Tickets você tem tudo em um só lugar: Da venda de ingressos pelo site, aplicativo e pontos de venda físicos, até a gestão financeira exclusiva para o fechamento do evento e suporte para vendas online."
          logo="/assets/icon/meep-tickets-filled.png"
          includedItemsTitle={<>Solucao <span className='purpleText'>completa, funcional e segura!</span></>}
          customFooter={<></>}
          includedItems={[
            {
              title: "Segurança em sua vendas",
              description:
              "Vendas de ingressos via site, app e pontos de venda físicos com sistema antifraude e blocklist para contenção de golpes.",
              img: "/assets/icon/tickets/security.svg",
            },
            {
              title: "Acompanhamento em tempo real",
              description:
                "Sistema integrado com multicanais de venda de ingressos e acompanhamento em tempo real.",
              img: "/assets/icon/tickets/realtime.svg",
            },
            {
              title: "Vendas físicas",
              description:
                "Maquininhas com impressão dos ingressos em papel térmico reagente à luz negra e código de barras.",
            },
            {
              title: "Sistema de validação",
              description:
                "Sistema completo para validação dos ingressos na portaria do seu evento, evitando ingressos falsos ou duplicidade.",
              img: "/assets/icon/tickets/validation.svg",
            },
            {
              title: "Financeiro",
              description:
                "Conta digital com split de pagamento para terceiros.",
              img: "/assets/icon/tickets/financial.svg",
            },
            {
              title: "Comissários",
              description:
                "Sistema completo para venda de comissários, que inclui códigos de desconto, links personalizados e login pessoal.",
              img: "/assets/icon/tickets/commissioners.svg",
            },
          ]}
          setOpen={() => setOpen(IntegrationTypeEnum.MEEP_TICKETS)}
          featureType={FeatureTypeEnum.TICKETS}
          footerImg="/assets/icon/tickets/video.png"
          footerImgLink="https://youtu.be/63uuXzxxZ6U"
        />
      )}

      {open && open === "Meep Tickets" && (
        <ConfigMeepTickets
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default MeepTicketsPage;
