import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './ProductAsComplement.module.scss'
import { ICategory, IProduct } from 'modules/catalog/presentation/fragments/catalogFragment/CatalogFragment'
import { ContainerLabel } from '../../ui/ContainerLabel';
import { Autocomplete } from '@mui/material';
import SelectProductPicker, { IProductItemToSelect } from '../../catalogListForm/selectProduct/SelectProductPicker';
import { InputLabel } from '../../ui/InputLabel';
import ProductListItemSkeleton from '../../categoryAndProducts/productList/ProductListItemSkeleton';
import { is } from 'immutable';
import { ILocaisSelect } from '../../productForm/IProducFormValuet';


export interface IExistCategoryAndProducts {
    category: ICategory;
    products: IProductItemToSelect[];
};
export interface IExistsProductListFormProps {
    //propertys
    categories: ICategory[];
    isLoadingCategory: boolean;
    onSubmit: (category: ICategory, products: IProductItemToSelect[]) => void;
    getProducts: (categoryId: string, isShared?: boolean,  sharedLocals?: ILocaisSelect[] ) => Promise<IProductItemToSelect[]>;
    onClose: () => void;
    sharedLocals: ILocaisSelect[] | undefined
    isShared: boolean | undefined
    categoryIds: React.MutableRefObject<IExistCategoryAndProducts>
    setProductList: React.Dispatch<React.SetStateAction<IProductItemToSelect[]>>
    productList: IProductItemToSelect[]
    mainProductId: string
}
const ProductAsComplement: FC<IExistsProductListFormProps> = ({ categories, getProducts, mainProductId, onSubmit, productList, setProductList, isLoadingCategory, onClose, sharedLocals, isShared, categoryIds }) => {

    // const [step, setStep] = useState<"category" | "product">();
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState<IExistCategoryAndProducts>({} as IExistCategoryAndProducts)

    const onCloseSideSheet = () => {
        onClose();
        setProductList([])

    }

    const changeCategoryHandle = useCallback(async (ev, value: ICategory | null) => {
        if (value) {
            try {
                setIsLoading(true);
                setValues(prev => ({ ...prev, category: value }));
                categoryIds.current.category = value;
    
                const response = await getProducts(value.id, isShared, sharedLocals);
                // Filtrando os produtos que não têm o mesmo ID do mainProductId
                const filteredProducts = response.filter(product => product.id !== mainProductId);
                
                setProductList(filteredProducts);
            } finally {
                setIsLoading(false);
            }
        } else {
            setProductList([]);
        }
    }, [categoryIds, getProducts, isShared, mainProductId, setProductList, sharedLocals]);
    const changeProductHandle = useCallback((products: IProductItemToSelect[]) => {
        if (values.category) {
            onSubmit(values.category, products)
        }
    }, [onSubmit, values.category])

    return (
        <div id={styles.ProductAsComplement} >
            <div className={styles.container} >
                <ContainerLabel alignItems="flex-start" flex={0}>
                    <Autocomplete
                        fullWidth
                        renderInput={(params) => <InputLabel label={"Selecione uma categoria:"} {...params} placeholder={isLoadingCategory ? 'Carregando categorias...' : 'Selecione uma categoria'} />}
                        options={categories ?? []}
                        getOptionLabel={(option) => option.description}
                        value={values.category}
                        disabled={isLoadingCategory}
                        onChange={changeCategoryHandle}
                        size='small'
                    />
                </ContainerLabel>
                <SelectProductPicker
                    products={productList}
                    isLoading={isLoading}
                    onSubmit={changeProductHandle}
                    onClose={onCloseSideSheet}
                />
                {isLoading && <>
                    <ProductListItemSkeleton />
                    <ProductListItemSkeleton />
                    <ProductListItemSkeleton />
                    <ProductListItemSkeleton />
                </>}
            </div>
        </div>
    )
}
export default ProductAsComplement