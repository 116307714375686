import { UseFidelityPage } from "./UseFidelityPage";
import styles from "./FidelityPage.module.scss";
import "./FidelityPage.css";
import { Skeleton } from "modules/cashlessDashboard/presentation/components/skeleton/Skeleton";
import FidelityForm, {
  FidelityFormStep,
} from "./components/forms/FidelityForm";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { DeleteModal } from "./components/deleteModal/DeleteModal";
import { PremiumHint } from "modules/plans/presentation/components/premiumHint/PremiumHint";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { PremiumModal } from "modules/plans/presentation/components/premiumModal/PremiumModal";
import { ConfigExchangeModal } from "./components/configExchangeModal/ConfigExchangeModal";
import { BlurPremium } from "modules/plans/presentation/components/blurPremium/BlurPremium";
import { FidelityCustomers } from "./components/fidelityCustomers/FidelityCustomers";
import { HistoryModal } from "./components/historyModal/HistoryModal";
import Button from "components/ui/Button/Button";
import { IconButton, Popover, Drawer } from "@mui/material";
import { Close, DeleteOutlined, EditOutlined, MoreVert } from "@material-ui/icons";
import { TextSnippet } from "@mui/icons-material";
import UseDimension from "components/dimension/UseDimension";
import Sidesheet from "components/sidesheet/Sidesheet";

export const FidelityPage = () => {
  const {
    fidelityList: data,
    loading,
    itemToDelete,
    deleteLoading,
    selectedItem,
    listCustomersOpen,
    customersLoading,
    fidelityCustomers,
    currentPage,
    setSelectedItem,
    setListCustomersOpen,
    setItemToDelete,
    getData,
    deleteFidelityPlan,
    exchangeEnabled,
    toggleExchangeModalOpened,
    setToggleExchangeModalOpened,
    savingExchange,
    handleToggleExchange,
    getFidelityCustomers,
    handleClickShowCustomers
  } = UseFidelityPage();

  const { checkPermission } = usePlans();
  const { step, fidelityId } = useParams<{ step?: string; fidelityId?: string; }>();
  const { push } = useHistory();
  const { currentLocal } = useLocal();
  const { dimensions } = UseDimension();
  
  const [modalOpened, setModalOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [historyModalOpened, setHistoryModalOpened] = useState(false);
  const [fidelityTitleForm, setFidelityTitleForm] = useState({ title: "", strong: "" });

  const [fidelityInfo, setFidelityInfo] = useState<boolean>();

  const onClose = useCallback(() => {
    push("/private/fidelity");
    getData();
  }, [getData, push]);

  const onClickAdd = () => {
    if (!checkPermission(FeatureTypeEnum.FIDELITY))
      setModalOpened(true);
    else
      push(`/private/fidelity/${FidelityFormStep[FidelityFormStep.name]}`);
  };

  const onClickEdit = (fidelityId: string) => {
    push(
      `/private/fidelity/${FidelityFormStep[FidelityFormStep.name]
      }/${fidelityId}`
    );
  };

  const handleCloseInfo = useCallback(() => {
    setFidelityInfo(false);
    localStorage.setItem("@fidelityInfo", "false");
  }, []);

  useEffect(() => {
    const localFidelityInfo = localStorage.getItem("@fidelityInfo");

    if (localFidelityInfo) {
      setFidelityInfo(JSON.parse(localFidelityInfo));
    } else {
      setFidelityInfo(true);
    }
  }, []);

  const handleOpenEnableExchange = () => {
    if (!checkPermission(FeatureTypeEnum.FIDELITY))
      setModalOpened(true);
    else
      setToggleExchangeModalOpened(true);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div id={styles.FidelityPage}>
      {!checkPermission(FeatureTypeEnum.FIDELITY)
        ? (
          <div style={{ marginTop: 16, marginBottom: -24 }}>
            <PremiumHint />
          </div>
        ) : fidelityInfo && (
          <div className={styles.howItWorks}>
            <span>
              Utilize o programa de <strong>Fidelidade Meep</strong> e promova
              mais recorrência no seu estabelecimento!
            </span>
            <IconButton onClick={handleCloseInfo} size="small"><Close /></IconButton>
          </div>
        )
      }

      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>
            Programa de <strong>Fidelidade</strong>
          </span>
          <IconButton onClick={handleClick}><MoreVert /></IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={styles.popoverContainer}>
              <button
                onClick={() => {
                  setHistoryModalOpened(true);
                  handleClose();
                }}
              >
                <TextSnippet />
                Histórico de alterações
              </button>
            </div>
          </Popover>
        </div>
        <span className={styles.description}>
          Crie o plano de fidelidade que é a cara do seu estabelecimento e valorize seus clientes!
        </span>

        <div>
          <Button onClick={onClickAdd}>Novo nível de fidelidade</Button>
          <Button variant="outlined" onClick={handleOpenEnableExchange} disabled={exchangeEnabled === undefined}>
            {exchangeEnabled ? 'Desabilitar' : 'Habilitar'} troca de moedas
          </Button>
          {/* <button className={styles.filledButton}>
                        Cotação das moedas
                        <img src="/assets/icon/currency.svg" alt="" />
                    </button> */}
          {/* <Switch color='secondary' className={styles.switch} />
                    <span>Troca de moedas habilitadas</span> */}
        </div>
      </div>

      <div className={styles.body} style={loading || !data.length || dimensions.width < 501 ? { borderLeft: 0 } : {}}>
        <BlurPremium feature={FeatureTypeEnum.FIDELITY}>
          <>
            {!loading && !data.length && (
              <div className={styles.empty}>
                <img src="/assets/img/fidelity-empty-1.svg" alt="" />
                <img src="/assets/img/fidelity-empty-2.svg" alt="" />
                <img src="/assets/img/fidelity-empty-3.svg" alt="" />
              </div>
            )}
            {loading ? (
              <Skeleton items={3} />
            ) : (
              data.map((item, index) => (
                <div className={styles.row} key={item.id}>
                  <div className={styles.bullet} style={{ display: dimensions.width > 500 ? "" : "none" }}>
                    <div />
                  </div>
                  <div className={styles.line} style={{ display: dimensions.width > 500 ? "" : "none" }}/>

                  <div className={styles.content}>
                    <div className={styles.contentHeader}>
                      <span>{item.name}</span>
                      <div
                        className={styles.levelContainer}
                        style={{
                          backgroundColor: item.customization?.color ?? "#1ac",
                        }}
                      >
                        Nível {index + 1}
                      </div>
                      <div>
                        {item.customization?.icon && (
                          <img
                            src={`/assets/icon/fidelity/gray/${item.customization.icon}.svg`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.infoActions}>
                      <div className={styles.info}>
                        <span>
                          Pontuação mínima:{" "}
                          <strong>{item.pointsRequired || "0"}</strong>
                        </span>
                        <span className={styles.last}>
                          Clientes fidelizados:{" "}
                          <strong>{item.numberOfCustomers}</strong>
                        </span>
                      </div>
                      <div className={styles.actions}>
                        <IconButton
                          size="small"
                          onClick={() => handleClickShowCustomers(item)}
                        >
                          <img src="/assets/icon/fidelity-customers.svg" alt="" height={18}/>
                        </IconButton>
                        {/* <IconButton size="small">
                          <PersonAdd />
                        </IconButton> */}
                        <IconButton
                          size="small"
                          onClick={() => onClickEdit(item.id)}
                        >
                          <EditOutlined />
                        </IconButton>
                        {index > 0 && (
                          <IconButton
                            size="small"
                            onClick={() => setItemToDelete(item)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className={styles.whatDoesItHave}>
                      <span>O que tem nessa categoria?</span>
                      <div>
                          <span>Trocar moedas por dinheiro</span>
                          <span>Nome de benefício personalizado 1</span>
                          <span>Nome de benefício personalizado 2</span>
                          <span>Nome de benefício personalizado 3</span>
                      </div>
                  </div> */}
                </div>
              ))
            )}
          </>
        </BlurPremium>
      </div>

      {currentLocal && (
        <Sidesheet
          open={!!step}
          onClose={onClose}
          title={
            <h2>
              {fidelityTitleForm.title} <b>{fidelityTitleForm.strong}</b>
            </h2>
          }
          content={
            <FidelityForm
              fidelityId={fidelityId}
              onClose={onClose}
              step={FidelityFormStep[step as keyof typeof FidelityFormStep]}
              localId={currentLocal.id}
              isFirst={!data.length || data[0].id === fidelityId}
              data={data}
              setFidelityTitleForm={setFidelityTitleForm}
            />
          }
          currentStep={1}
          totalSteps={1}
        />
      )}

      <Drawer
        anchor="right"
        open={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
      >
        {itemToDelete && (
          <DeleteModal
            onDelete={deleteFidelityPlan}
            customerCount={itemToDelete?.numberOfCustomers}
            loading={deleteLoading}
            onClose={() => setItemToDelete(undefined)}
          />
        )}
      </Drawer>
      <Drawer
        anchor="right"
        open={toggleExchangeModalOpened}
        onClose={() => setToggleExchangeModalOpened(false)}
      >
        <ConfigExchangeModal
          onSubmit={handleToggleExchange}
          enabled={exchangeEnabled || false}
          loading={savingExchange}
          onClose={() => setToggleExchangeModalOpened(false)}
        />
      </Drawer>
      <PremiumModal opened={modalOpened} onClose={() => setModalOpened(false)} />
      <Drawer
        anchor="right"
        open={!!listCustomersOpen}
        onClose={() => { setListCustomersOpen(false); setSelectedItem(undefined); }}
      >
        {selectedItem && (
          <FidelityCustomers
            onClose={() => { setListCustomersOpen(false); setSelectedItem(undefined); }}
            loading={customersLoading}
            data={fidelityCustomers}
            getData={getFidelityCustomers}
            fidelityPlanName={selectedItem.name}
            page={currentPage}
          />
        )}
      </Drawer>

      {
        currentLocal &&
        <HistoryModal open={historyModalOpened} onClose={() => setHistoryModalOpened(false)} ownerId={currentLocal.id} />
      }
    </div>
  );
};
