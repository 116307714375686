import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IDeviceValue } from "../deviceForms/deviceLicenseForm/interface/IDeviceValue";
import UseDeviceLicense from "../../hooks/UseDeviceLicense";
import UseDeviceLink from "../../hooks/UseDeviceLink";
import UseDeviceManual from "../../hooks/UseDeviceManual";
import UseDeviceProfile from "../../hooks/UseDeviceProfile";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IDeviceItem } from "../deviceList/interfaces/IDeviceItem";

interface UseDeviceFormProps {
  setSelectedDevices?: (devices: IDeviceItem[]) => void;
}

const UseDeviceForm = ({ setSelectedDevices }: UseDeviceFormProps) => {
  const { action, step, deviceType, id } = useParams<{
    action: string;
    step?: string;
    deviceType?: string;
    id: string;
  }>();
  const [currentStep, setcurrentStep] = useState<Number>(Number(step));
  const [currentDeviceType, setCurrentDeviceType] = useState<DeviceType>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const {
    addNewDevice,
    editNewDevice,
    onClickLicense,
    licenses,
    getLicenseList,
  } = UseDeviceLicense();
  const { code, checkLink, getCode, linked } = UseDeviceLink(
    currentDeviceType,
    currentLocal?.id
  );
  const {
    getDeviceProfileList,
    getDefaultDeviceProfile,
    deviceProfileList,
    onChangeDeviceProfile,
  } = UseDeviceProfile();
  const { addDevice } = UseDeviceManual(currentLocal?.id, currentDeviceType);

  const { push } = useHistory();

  const closeStepForm = () => {
    push(`/private/configuracao/pdv/lista`);
  };

  const onChangeFormStep = useCallback(
    (stepForm: FormDeviceStep) => {
      push(`/private/configuracao/pdv/lista/adicionar/${id}/${stepForm}`);
    },
    [id, push]
  );

  const onSubmitDevice = useCallback(
    (values: IDeviceValue) => {
      if (values.id) {
        addNewDevice({ type: values.type });
      } else {
        editNewDevice(id, values);
      }
    },
    [addNewDevice, id, editNewDevice]
  );

  useEffect(() => {
    if (action === "adicionar" || "editar") {
      if (currentLocal) {
        switch (Number(step)) {
          case FormDeviceStep.LICENSE:
            //Get licences
            if (currentLocal) {
              getLicenseList(currentLocal.id);
            }
            break;
          case FormDeviceStep.LINK:
            //Get code
            getCode();

            break;
          case FormDeviceStep.MANUAL:
            //Get code
            if (currentLocal) {
            }
            break;
          case FormDeviceStep.MULTIPLE:
          case FormDeviceStep.PROFILE:
            //Get code
            if (currentLocal) {
              if (id) {
                getDefaultDeviceProfile(id);
              }
              getDeviceProfileList(currentLocal.id);
            }
            break;
          default:
            if (currentLocal) {
              getLicenseList(currentLocal.id);
            }
            break;
        }
      }
    }
  }, [
    action,
    currentLocal,
    id,
    deviceType,
    getCode,
    getDefaultDeviceProfile,
    getDeviceProfileList,
    getLicenseList,
    step,
  ]);

  useEffect(() => {
    setcurrentStep(step ? Number(step) : FormDeviceStep.LICENSE);
    setCurrentDeviceType(deviceType ? Number(deviceType) : undefined);
  }, [deviceType, step]);

  const onClickGoToManualRegister = useCallback(() => {
    push(
      `/private/configuracao/pdv/lista/adicionar/${FormDeviceStep.MANUAL}/${currentDeviceType}`
    );
  }, [currentDeviceType, push]);

  const onChangeDeviceProfileHandle = useCallback((profileId: string) => {
    // const values = currentStep === FormDeviceStep.PROFILE ? [{ id: deviceId }] : deviceId.split(',').map(x => ({ id: x }));
    // onChangeDeviceProfile(profileId, values);
    // push(`/private/configuracao/pdv/lista`);
    // toast("Equipamentos alterados com sucesso", "success");
    // setSelectedDevices?.([]);
  }, []);

  return {
    currentStep,
    currentDeviceType,
    licenses,
    code,
    linked,
    deviceProfileList,
    onSubmitDevice,
    onChangeFormStep,
    onClickLicense,
    checkLink,
    getCode,
    addDevice,
    closeStepForm,
    onClickGoToManualRegister,
    onChangeDeviceProfileHandle,
  };
};
export default UseDeviceForm;
