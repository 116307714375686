import { Radio } from "@material-ui/core";
import styles from './TriggerForm.module.scss';
import { AutomationTriggerType } from "modules/automation/domain/interfaces/AutomationTriggerType";
import { FC } from "react";
import { IAutomationForm } from "modules/automation/presentation/interfaces/IForm";


export interface ITriggerTypeProps {
    form: IAutomationForm;
    onChange: (form: IAutomationForm) => void;
}
export const TriggerForm: FC<ITriggerTypeProps> = ({ onChange,
    form
}) => {




    return (
        <div className={styles.container}>
            <span className={styles.title}>O gatilho inicia a sua automação. Escolha qual gatilho deseja usar para automatizar as ações:</span>

            <div className={styles.radioContainer}>
                <div>
                    <Radio
                        className={styles.radio}
                        size="small"
                        onChange={(_, value) => onChange({ ...form, triggerType: AutomationTriggerType.ON_CLOSER_CASHIER })}
                        checked={form.triggerType === AutomationTriggerType.ON_CLOSER_CASHIER}
                    />
                    <span>Fechamento do caixa/evento</span>
                </div>
                <span className={styles.description}>O disparo acontecerá aos clientes que compraram no caixa</span>
            </div>

            <div className={styles.radioContainer}>
                <div>
                    <Radio
                        className={styles.radio}
                        size="small"
                        onChange={(_, value) => onChange({ ...form, triggerType: AutomationTriggerType.ON_PAYMENT })}
                        checked={form.triggerType === AutomationTriggerType.ON_PAYMENT}
                    />
                    <span>Ao efetuar pagamento</span>
                </div>
                <span className={styles.description}>O disparo acontecerá após o seu cliente efetuar o pagamento de um produto ou comanda</span>
            </div>



        </div>
    )
}