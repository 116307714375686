
import { ICreateTrackConfigurationRequest } from 'modules/survey/domain/dtos/ICreateTrackConfigurationRequest';
import { IGetTrackConfigurationResponse } from 'modules/survey/domain/dtos/IGetTrackConfigurationResponse';
import { IGetSurveyListResponse } from 'modules/survey/domain/dtos/IGetSurveyListResponse';
import { IGetWidgetsResponse } from 'modules/survey/domain/dtos/IGetWidgetsResponse';
import { ISurveyService } from 'modules/survey/domain/interfaces/ITrackConfigService';
import { IUpdateTrackConfigurationRequest } from 'modules/survey/domain/dtos/IUpdateTrackConfigurationRequest';
import { api } from 'services/api/Api';
import { ICreateCustomeSuveryRequest } from 'modules/survey/domain/dtos/ICreateCustomeSuveryRequest';
import { ICreateCustomeSuveryResponse } from 'modules/survey/domain/dtos/ICreateCustomeSuveryResponse';
import { IGetCustomeSuveryResponse } from 'modules/survey/domain/dtos/IGetCustomeSuveryResponse';
import { IUpdateCustomeSuveryRequest } from 'modules/survey/domain/dtos/IUpdateCustomeSuveryRequest';
import { IUpdateCustomeSuveryResponse } from 'modules/survey/domain/dtos/IUpdateCustomeSuveryResponse';
import { IGetTrackSurveyListResponse } from 'modules/survey/domain/dtos/IGetTrackSurveyListResponse copy';

const baseUrl = "https://crm-api.meep.cloud/api";

const SurveyApi = (): ISurveyService => {
    const createConfig = async (request: ICreateTrackConfigurationRequest, localId: string): Promise<IGetTrackConfigurationResponse> => {
        const response = await api.post(`${baseUrl}/customerSurveys/track/configuration/create`, {
            ...request,
            ownerId: localId
        });
        return response.data
    }

    const updateConfig = async (request: IUpdateTrackConfigurationRequest, localId: string): Promise<IGetTrackConfigurationResponse> => {
        const response = await api.put(`${baseUrl}/customerSurveys/track/configuration/update`, {
            ...request,
            ownerId: localId
        });
        return response.data
    }

    const getConfig = async (localId: string): Promise<IGetTrackConfigurationResponse> => {
        const response = await api.get<IGetTrackConfigurationResponse>(`${baseUrl}/customerSurveys/track/configuration/${localId}`, { params: { disableError: true } });
        return (response.data);
    }

    const getWidgetsList = async (localId: string): Promise<IGetWidgetsResponse> => {
        const response = await api.get<IGetWidgetsResponse>(`${baseUrl}/customerSurveys/getWidgets/${localId}`, { params: { disableError: true } });
        return (response.data);
    }
    const getTrackSurveyList = async (localId: string): Promise<IGetTrackSurveyListResponse> => {
        const response = await api.get<IGetTrackSurveyListResponse>(`${baseUrl}/customerSurveys/getTrackSurveys/${localId}`, { params: { disableError: true } });
        return (response.data);
    }

    const getList = async (localId: string): Promise<IGetSurveyListResponse> => {
        const response = await api.get<IGetSurveyListResponse>(`${baseUrl}/customerSurveys/getList/${localId}`, { params: { disableError: true } });
        return (response.data);
    }

    const create = async (request: ICreateCustomeSuveryRequest): Promise<ICreateCustomeSuveryResponse> => {
        const response = await api.post<ICreateCustomeSuveryResponse>(`${baseUrl}/customerSurveys/create/`, {
            ...request
        });
        return response.data
    }

    const get = async (ownerID: string, id: string): Promise<IGetCustomeSuveryResponse> => {
        const response = await api.get<IGetCustomeSuveryResponse>(`${baseUrl}/customerSurveys/get/${id}`, { params: { disableError: true } });
        return (response.data);
    }

    const update = async (request: IUpdateCustomeSuveryRequest): Promise<IUpdateCustomeSuveryResponse> => {
        const response = await api.put<IUpdateCustomeSuveryResponse>(`${baseUrl}/customerSurveys/update/` + request.id, {
            ...request
        });
        return response.data
    }

    return {
        createConfig,
        updateConfig,
        getConfig,
        getWidgetsList,
        getTrackSurveyList,
        getList,
        create,
        update,
        get
    }
}

export default SurveyApi
