import { Payments, Event, ShoppingBagOutlined, AccountBalanceOutlined } from '@mui/icons-material'
import { format, parseISO } from 'date-fns';
import styles from './InfoTicket.module.scss'
import { ptBR } from 'date-fns/locale';
interface InfoTicketProps {
    period: string,
    monthResume?: {
        orders: number,
        totalSales: number,
        averageTicket: number,
        period: string
    }
    dayResume?: {
        orders: number
        totalSales: number
        period: string
    }
}

const InfoTicket = ({ period, monthResume, dayResume }: InfoTicketProps) => {
    return (
        <div id={styles.InfoTicket}>
            <div className={styles.box}>
                <Payments style={{ color: '#399C54' }} />
                <p className={styles.value}>{dayResume?.totalSales.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                <p>{period === "cashier" ? "Vendas do caixa" : "Vendas do dia"}</p>
                <p className={styles.period}>{dayResume && format(parseISO(dayResume.period), 'dd/MM/yyyy HH:mm')}</p>
            </div>
            <div className={styles.box}>
                <ShoppingBagOutlined style={{ color: '#955CFF' }} />
                <p className={styles.value}>{dayResume?.orders}</p>
                <p>{period === "cashier" ? "Pedidos do caixa" : "Pedidos do dia"}</p>
                <p className={styles.period}>{dayResume && format(parseISO(dayResume.period), 'MMMM/yyyy',  { locale: ptBR })}</p>
            </div>
            <div className={styles.box}>
                <Event style={{ color: '#54A2EA' }} />
                <p className={styles.value}>{monthResume?.totalSales.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                <p>Vendas do mês</p>
                <p className={styles.period}>{monthResume && format(parseISO(monthResume.period), 'dd/MM/yyyy HH:mm')}</p>
            </div>

            <div className={styles.box}>
                <ShoppingBagOutlined style={{ color: '#B3B3B3' }} />
                <p className={styles.value}>{monthResume?.orders}</p>
                <p>Pedidos do mês</p>
                <p className={styles.period}>{monthResume && format(parseISO(monthResume.period), 'MMMM/yyyy', { locale: ptBR })}</p>
            </div>
            <div className={styles.box}>
                <AccountBalanceOutlined style={{ color: '#95BE75' }} />
                <p className={styles.value}>{monthResume?.averageTicket.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                <p>Ticket médio do mês</p>
                <p className={styles.period}>{monthResume && format(parseISO(monthResume.period), 'MMMM/yyyy', { locale: ptBR })}</p>
            </div>
        </div>
    )
}
export default InfoTicket