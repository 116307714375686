import { useCallback, useEffect, useState } from "react";
import UseLinkCardsList, { IParamsLinkCardsList } from "../hooks/UseLinkCardsList";
import { ILinkCardItem } from "../interfaces/ILinkCardsList";


export const UseLinkCardsPage = () => {

    const { getLinkCardsList, linkCardsList, isLoading } = UseLinkCardsList();


    const [selectedLinkCard, setSelectedLinkCards] = useState<ILinkCardItem[]>([] as ILinkCardItem[])
    const [allSelected, setAllSelected] = useState(false);

    const [params, setParams] = useState<IParamsLinkCardsList>({
        sort: { orientation: 'asc', type: 'tag' }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IParamsLinkCardsList) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getLinkCardsList({ ...params });
    }, [getLinkCardsList, params]);


    const onSelectLinkCardsHandle = useCallback((product: ILinkCardItem, checked: boolean) => {
        if (checked) {
            setSelectedLinkCards(prev => [...prev, product]);
        } else {
            setSelectedLinkCards(prev => prev.filter(item => item.id !== product.id));
        }
    }, []);


    return (
        {
            linkCardsList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getLinkCardsList,
            setSelectedLinkCards,
            selectedLinkCard,
            onSelectLinkCardsHandle,
            allSelected,
            setAllSelected
        }
    )
}
