import { ShortcutsEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/usabilityForm/IUsabilityValues";
import { IDeviceProfile } from "modules/config/deviceConfig/presentation/interfaces/IDeviceProfile";
import { IDeviceProfileService } from "../../../domain/interface/IDeviceProfileService"

const GetDeviceProfileByIdUseCase = async (deviceProfileService: IDeviceProfileService, localId: string, profileId: string): Promise<IDeviceProfile> => {
    const response = await deviceProfileService.getDeviceProfileById(localId, profileId);
    const homeScreenShortcuts = response.usability?.homeScreenShortcuts?.split(';');
    const deviceProfile: IDeviceProfile = {
        ...response,
        usability: {
            ...response.usability,
            homeScreenShortcuts: homeScreenShortcuts?.map(it => Number(it) as ShortcutsEnum)
        }
             
    }
    return (deviceProfile)
}
export default GetDeviceProfileByIdUseCase