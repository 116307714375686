import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetOrganizerByDocumentUseCase } from "modules/config/integrationsConfig/application/useCases/GetOrganizerByDocumentUseCase";
import { IntegrateOrganizerUseCase } from "modules/config/integrationsConfig/application/useCases/IntegrateOrganizerUseCase";
import { VerifyOrganizerUseCase } from "modules/config/integrationsConfig/application/useCases/VerifyOrganizerUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { IOrganizer } from "../../interfaces/IOrganizer";
import { ILocalMainData } from "../../interfaces/ILocalMainData";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";

const service = IntegrationApi();

export const UseConfigMeepTickets = () => {
  const [organizer, setOrganizer] = useState<IOrganizer | null>();
  const [localMainData, setLocalMainData] = useState<ILocalMainData>();
  const [loading, setLoading] = useState(true);
  const [integrated, setIntegrated] = useState(false);
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (currentLocal) {
          const response = await GetOrganizerByDocumentUseCase(
            service,
            currentLocal?.id
          );
          setOrganizer(response);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentLocal]);

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        const response = await GetLocalMainDataUseCase(service, currentLocal.id);
        setLocalMainData(response);
      }
    })();
  }, [currentLocal]);

  const integrateOrganizer = useCallback(async () => {
    if (integrated) return;
    try {
      setLoading(true);
      if (currentLocal) {
        await IntegrateOrganizerUseCase(service, currentLocal?.id);
      }

      setIntegrated(true);
      return "ok";
    } catch {
      toast(
        "Não foi possível vincular o organizador. Tente novamente",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }, [currentLocal, integrated, toast]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (currentLocal) {
          const response = await VerifyOrganizerUseCase(
            service,
            currentLocal?.id
          );
          setIntegrated(response.integrated);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentLocal]);

  // /api/MeepTickets/IntegrateOrganizer/9AC4882D-B18D-4F96-BCBB-00075DD61497
  // /api/MeepTickets/IntegrateEvent
  // body:
  // {
  //     "eventId": "A67B5443-6665-4AA0-AB73-BC9466A49D6E",
  //     "IoticketsEventId": 2038
  // }
  return {
    organizer,
    loading,
    integrateOrganizer,
    integrated,
    localMainData,
  };
};
