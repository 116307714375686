import React, { FC, useCallback, useMemo } from 'react'
import styles from './InventoryList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { IInventoryFilterValue } from './inventoryFilter/IInventoryFilter';
import { IGetInventoryItemResponse, IGetInventoryListResponse } from 'modules/meepErp/application/dtos/invetory/InvetoryOperationDto';
import InventoryFilter from './inventoryFilter/InventoryFilter';
import { CheckCircle, Info, KeyboardArrowRight, Sync } from '@mui/icons-material';
import { InventoryStatus } from 'modules/meepErp/models/inventory/InventoryStatus';
import { format } from 'date-fns';

export interface IInventoryListProps {
    //propertys
    filter: IInventoryFilterValue
    isLoading: boolean;
    inventory?: IGetInventoryListResponse
    onChangeFilter: (filter: IInventoryFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onView: (id: string) => void
}
const IconStatus = ({ value }: { value: InventoryStatus }) => {
    return (
        <>
            {value === InventoryStatus.Creating &&
                <span className={styles.info}>
                    <Info />
                    Não finalizado
                </span>

            }
            {(value === InventoryStatus.Created || value === InventoryStatus.Processing) &&
                <span className={styles.process}>
                    <Sync />
                    Processando
                </span>
            }

            {value === InventoryStatus.Processed &&
                <span className={styles.success}>
                    <CheckCircle />
                    Finalizado
                </span>
            }

            {value === InventoryStatus.ErrorProcessing &&
                <span className={styles.error}>
                    <Info />
                    Erro
                </span>
            }

        </>
    )
}

const InventoryList: FC<IInventoryListProps> = ({
    inventory,
    onDelete,
    onView,
    filter,
    onChangeFilter,
    isLoading
}) => {

    const submitFilter = useCallback((values: IInventoryFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])

    const columnsConfig: ColumnConfigs<IGetInventoryItemResponse> = {
        date: { 
            title: "Data do inventário", sortable: true,
            renderRow: (value: InventoryStatus) => format(new Date(value), 'dd/MM/yyyy') ,
         },
        description: { title: "Descrição", sortable: true },
        suppliesCount: { title: "Quantidade de insumos", sortable: true },
        status: {
            renderRow: (value: InventoryStatus) => <IconStatus value={value} />,
        }
    }

    const actionButtons = [
        {
            tooltip: "Excluir",
            icon: "delete",
            showLoading: true,
            onClick: (value: IGetInventoryItemResponse) => onDelete(value.id)
        },
        {
            iconRender: () => <KeyboardArrowRight />,
            showLoading: true,
            onClick: (value: IGetInventoryItemResponse) => onView(value.id)
        },
    ]
    const pagination = useMemo(() => {
        return {
            page: inventory?.page ?? 1,
            pageSize: 10,
            count: inventory?.totalPages ?? 0
        }
    }, [inventory?.totalPages, inventory?.page])

    return (
        <div id={styles.InventoryList} >
            <InventoryFilter
                onSubmit={submitFilter}
                defaultValue={filter}
            />
            <div></div>
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={inventory?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={actionButtons}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default InventoryList