import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './AddOrUpdateInvoicePage.module.scss'
import InvoiceForm from 'modules/meepErp/presentation/components/invoices/InvoiceForm'
import { IInvoiceFormValue, InvoiceSupplyValues } from 'modules/meepErp/presentation/components/invoices/IInvoiceFormValue'
import { get } from 'http'
import { PartnerItemResponse, PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos'
import { getPartnersListUseCase } from 'modules/meepErp/application/useCases/partners/PartnersListUseCase'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { Drawer } from '@mui/material'
import { IGetSupplyListRequest } from 'modules/meepErp/application/dtos/supply/IGetSupplyListRequest'
import { getSuppliesListUseCase, getSupplyUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases'
import { IGetSupplyItemResponse, IGetSupplyListResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse'
import { IGetStockOperationListRequest } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListRequest'
import { getStockOperationsUseCase } from 'modules/meepErp/application/useCases/operations/StockOperationsUseCase'
import { useMutation, useQuery } from 'react-query'
import { AddInvoiceUseCase, UpdateInvoiceUseCase } from 'modules/meepErp/application/useCases/invoice/SaveInvoiceUseCases'
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase'
import { IGetStorageLocalsListRequest } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListRequest'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import { useHistory, useParams } from 'react-router-dom'
import GetInvoiceUseCase from 'modules/meepErp/application/useCases/invoice/GetInvoiceUseCase'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import { IGetStockOperationListResponse, IStockOperationItemResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse'
import { getQuantityStockUseCase } from 'modules/meepErp/application/useCases/invoice/InvoiceUseCases'

const AddOrUpdateInvoicePage: FC = () => {
    const {
        currentLocal,
    } = useLocal();

    const {
        auth
    } = useAuth()

    const { id } = useParams<{ id?: string }>();

    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();
    const [operationsList, setOperationsList] = useState<IStockOperationItemResponse[] | undefined>()
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Central de lançamentos",
                url: '/private/meeperp/invoices'
            },  
            {
                title: "Incluir notas fiscais",
            },  
        ]);
        
        return () => {
            updateRouters([]);
        };
    }, [updateRouters]); 

    const GetPartnerHandler = useCallback(async (request: {
        name?: string;
        document?: string;
        page: number;
        pageSize: number;
    }) => {
        if (request.document) {
            if (request.document.length < 11) {
                return { items: [] as PartnerItemResponse[] } as PartnerListResponse;
            }
        }
        return await getPartnersListUseCase(currentLocal?.id!, { ...request });
    }, [currentLocal])


    const getSupplyList = useCallback(async (request: IGetSupplyListRequest) => {
        try {
            showLoading();
            if (currentLocal) {
                return getSuppliesListUseCase(currentLocal.id, request)
            } else {
                return { items: [] as IGetSupplyItemResponse[] } as IGetSupplyListResponse
            }
        } finally {
            hideLoading();
        }
    }, [currentLocal])

    const getSupply = useCallback(async (id: string) => {
        try {
            showLoading();
            if (!currentLocal) throw new Error("Local não econtrado!");
            const response = await getSupplyUseCase(currentLocal.id, id);
            return response;
        } finally {
            hideLoading();
        }
    }, [currentLocal])

    // const getSupplyStorageLocation = useCallback(async (request: IGetSupplyStorageLocationListRequest) => {
    //     if (!currentLocal) throw new Error("Local não econtrado!");
    //     const response = await GetSupplyStockListUseCase(currentLocal.id, {
    //         page: 1,
    //         pageSize: 30,
    //         supplyId: request.supplyId
    //     })

    //     const storageLocations: IGetSupplyStorageLocationListResponse = {
    //         page: response.page,
    //         pageSize: response.pageSize,
    //         totalPages: response.totalPages,
    //         items: response.items.map(item => {
    //             return {
    //                 id: item.id,
    //                 name: item.storageLocationName
    //             }
    //         })
    //     }
    //     return storageLocations
    // }, [currentLocal])

    const getSupplyStorageLocation = useCallback(async (request: IGetStorageLocalsListRequest) => {
        if (!currentLocal) throw new Error("Local não econtrado!");
        const response = await getSupplyStorageLocalsUseCase(request)
        return response
    }, [currentLocal])

    const getQuantityStock = useCallback(async (storageLocationId: string, supplyId : string) => {
        const response = await getQuantityStockUseCase(storageLocationId, supplyId)
        return response
    }, [currentLocal])

    const getOperationList = useCallback(async () => {
        if (!currentLocal) throw new Error("Local não econtrado!");
        const response = await getStockOperationsUseCase(currentLocal.id, {page: 1, pageSize: 10})
        setOperationsList(response.items)
    }, [currentLocal])

    useEffect(() => {
        getOperationList()
    },[])

    const addOrUpdateInvoice = useCallback(async (values: IInvoiceFormValue) => {
        try {
            showLoading();
            if (!currentLocal) throw new Error("Local não econtrado!");
            if (!auth) throw new Error("Usuário não autenticado!");
            if (id) {
                const response = await UpdateInvoiceUseCase(currentLocal.id, id, { ...values, createdById: auth?.user.id!,  })
                replace("/private/meeperp/invoices");
                return response;
            }
            const response = await AddInvoiceUseCase(currentLocal.id, { ...values, createdById: auth?.user.id!,  })
            replace("/private/meeperp/invoices");
            return response;
        } finally {
            hideLoading();
        }
    }, [auth, currentLocal, id, replace])


    const getDefaultValues = useCallback(async (id: string) => {
        try {
            showLoading();
            if (!currentLocal) throw new Error("Local não econtrado!");
            const response = await GetInvoiceUseCase(currentLocal.id, id);
            return response;
        } finally {
            hideLoading();
        }
    }, [currentLocal])


    const defaultValues = useQuery(['@invoice', currentLocal?.id, id], () => getDefaultValues(id!), {
        enabled: !!id && !!currentLocal?.id,
        staleTime: 0
    })

    const mutation = useMutation(addOrUpdateInvoice)

    return (
        <div id={styles.AddOrUpdateInvoicePage} >
            <Back route='/private/meeperp/invoices' margin />
            <InvoiceForm
                onSubmit={mutation.mutateAsync}
                defaultValues={defaultValues.data}
                isSubmintting={mutation.isLoading}
                disabled={mutation.isLoading}
                getPartnerList={GetPartnerHandler}
                getSupplyList={getSupplyList}
                getSupply={getSupply}
                getSupplyStorageLocation={getSupplyStorageLocation}
                getOperationList={operationsList}
                getQuantityStock={getQuantityStock}
            />

        </div>
    )
}
export default AddOrUpdateInvoicePage