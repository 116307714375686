import React, { FC, ReactNode, useEffect, useState } from 'react'
import styles from './GridDashboard.module.scss'
export interface IGridDashboardProps {
    //propertys
    children: ReactNode
}
const GridDashboard: FC<IGridDashboardProps> = ({ children }) => {
    return (
        <div id={styles.GridDashboard} >
            <div className={styles.container} >
                {children}
            </div>
        </div>
    )
}
export default GridDashboard