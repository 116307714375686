import { Icon, IconButton } from '@material-ui/core'
import { ITips, useTips } from 'components/breadcumbs/TipsContext'
import React, { FC, useEffect } from 'react'
import styles from './AlertTips.module.scss'
export interface IAlertTipsProps {
    //propertys
    tips: ITips
}
const AlertTips: FC<IAlertTipsProps> = ({ tips }) => {
    const { setTips, onClickCloseTips, openTips } = useTips()
    useEffect(() => {
        setTips(tips)
        return () => {
            setTips(undefined)
        }
    }, [setTips, tips])

    return (
        openTips ? <div id={styles.AlertTips}>
            <div className={styles.iconContainer}>
                <img src="/assets/icon/lightbulb-on.svg" alt="Informação" />
            </div>
            <div className={styles.value}>
                {tips.value}
            </div>
            <IconButton className={styles.closeButton} size={'small'} onClick={onClickCloseTips}><Icon fontSize='small' className={styles.icon}>close</Icon></IconButton>
        </div> : null
    )
}
export default AlertTips