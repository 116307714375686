import { useCallback, useState } from 'react'
import { IPayFormErrors, IPayFormItem } from './interfaces/IPayForm'
import { validate } from './validade/PayFormValidation';

export const UsePayForm = (onSubmit: (values: IPayFormItem) => void, stepPay: number, defaultValues?: IPayFormItem, ) => {
    const [values, setValues] = useState(defaultValues ?? {
        valor: 0.00,
        CodigoBarras: '',
        multa: 0.00,
        desconto: 0.00,
        documento: '',
        codigo: '',
        SaldoRestante: 0,
        custoSaque: 3.67
    })

    const [errors, setErrors] = useState<IPayFormErrors>({});

    const onChangeHandle = useCallback((name: string, value: string | number) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback(() => {
        if (validate(values, setErrors, stepPay)) {
            onSubmit(values);
        }
    }, [onSubmit, values, stepPay]);

    return (
        {
            values,
            errors,
            onChangeHandle,
            onSubmitHandle,
        }
    )
}