import {
  IAddMultiplePromotersRequest,
  IAddPromoterRequest,
} from 'modules/events/domain/dto/IEventsRequest';
import { IPromotersResponse } from 'modules/events/domain/dto/IEventsResponse';
import { api } from '../../Api';
import { IAccessService } from '../../../../modules/events/application/interfaces/IAccessService';

export const AccessApi = (): IAccessService => {
  const getPromotersWithAccess = async (
    eventId: string,
    localId: string,
  ): Promise<IPromotersResponse[]> => {
    const result = await api.get(`SuperApp/Event/${eventId}/Promoters?localId=${localId}`);
    return result.data;
  };

  const getPromotersFromLocal = async (
    localId: string,
  ): Promise<IPromotersResponse[]> => {
    const result = await api.get(
      `SuperApp/Event/FindPromotersByLocal?localId=${localId}`,
    );
    return result.data;
  };

  const getPromotersFromList = async (
    listId: string,
    localId: string,
  ): Promise<IPromotersResponse[]> => {
    const result = await api.get(
      `SuperApp/Event/FindPromotersByPeopleList?listId=${listId}&localId=${localId}`,
    );
    return result.data;
  };

  const deletePromoterFromList = async (
    peopleListId: string,
    promoterId: string,
    localId: string,
  ): Promise<void> => {
    await api.delete(`SuperApp/Event/RemovePromoterFromPeopleList?localId=${localId}`, {
      data: { peopleListId, promoterId },
    });
  };

  const addPromoterToList = async (
    newPromoter: IAddPromoterRequest,
    localId: string,
  ): Promise<void> => {
    await api.post(`SuperApp/Event/AddPromoterToPeopleList?localId=${localId}`, {
      ...newPromoter,
    });
  };

  const addMultiplePromotersToEvent = async (
    promoters: IAddMultiplePromotersRequest,
    localId: string,
  ): Promise<void> => {
    await api.post(`SuperApp/Event/AddMultiplePromotersToPeopleList?localId=${localId}`, {
      ...promoters,
    });
  };

  return {
    getPromotersFromLocal,
    getPromotersFromList,
    deletePromoterFromList,
    addPromoterToList,
    addMultiplePromotersToEvent,
    getPromotersWithAccess,
  };
};
