import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './ObservationForm.module.scss'
import { Button, FormControlLabel, Icon, Radio, RadioGroup } from '@material-ui/core'
import ObservationItemForm from './ObservationItemForm'
import { v4 } from 'uuid'
import { EnumObservationType } from 'modules/catalog/domain/enums/CatalogProductEnums'
import { ContainerLabel } from '../ui/ContainerLabel'
import { SwitchLabel } from '../ui/SwitchLabel'

export interface IOpcoesFormValue {
    id?: string;
    optionId: string; // original name: id
    description: string; // original name: descricao
}

export interface IObservationProductFormValue {
    observationId: string; // original name: id
    id?: string;
    description: string; // original name: descricao
    type: EnumObservationType; // original name: tipo
    isReserved?: boolean; // original name: ehDeReserva
    options: IOpcoesFormValue[]; // original name: opcoes
}
export interface IObservationFormProps {
    //propertys
    values: IObservationProductFormValue[],
    disabled?: boolean,
    onChange?: (values: IObservationProductFormValue[]) => void
    onChangeGeneralObservation: (values: boolean) => void
    productGeneralObservation?: boolean
}
const ObservationForm: FC<IObservationFormProps> = ({ onChange, values, disabled, productGeneralObservation, onChangeGeneralObservation }) => {
    const [hasObservation, setHasObservation] = useState(false);
    const changeHasObservationHandle = useCallback((_, value: string) => {
        if (value === '1') {
            onChange?.([...values, { observationId: v4(), description: "", type: EnumObservationType.INCLUSIVO, options: [{ description: "", optionId: v4() }] }])
            setHasObservation(true);
        } else {
            setHasObservation(false);
            onChange?.([])
        }
    }, [onChange, values])

    const addObservation = useCallback(() => {
        onChange?.([...values, { observationId: v4(), description: "", type: EnumObservationType.INCLUSIVO, options: [{ description: "", optionId: v4() }] }])
        setHasObservation(true);
    }, [onChange, values])

    const removeObservation = useCallback((id: string) => {
        onChange?.(values.filter(item => item.observationId !== id))
        setHasObservation(true);
    }, [onChange, values])

    const onChangeItem = useCallback((value: IObservationProductFormValue) => {
        onChange?.(values.map(item => item.observationId === value.observationId ? value : item))
    }, [onChange, values])


    useEffect(() => { onChange?.(values) }, [onChange, values])

    return (
        <div id={styles.ObservationForm} >

            <div className={styles.container} >
                <ContainerLabel>
                    <RadioGroup
                        row
                        value={hasObservation || values.some(x => x.description !== '') ? 1 : 0}
                        onChange={changeHasObservationHandle}
                    >
                        <FormControlLabel disabled={disabled} value={0} control={<Radio />} label="Não, este produto não possui observações" />
                        <FormControlLabel disabled={disabled} value={1} control={<Radio />} label="Sim, este produto possui observações" />
                        <SwitchLabel disabled={disabled} checked={productGeneralObservation} onChange={(e) => onChangeGeneralObservation(e.target.checked)} label={"Solicitar observação geral"} />
                    </RadioGroup>
                </ContainerLabel>
                {values.map((observationItem, index, array) =>
                    <ObservationItemForm key={index} onChange={onChangeItem} values={observationItem} onRemove={array.length > 1 ? removeObservation : undefined} />)
                }
                {(hasObservation || values.length > 0) && <Button disabled={disabled} fullWidth color='primary' variant="outlined" onClick={addObservation}><Icon>add</Icon>Adicionar observação</Button>}
            </div>
        </div>
    )
}
export default ObservationForm