
import { IEnabledPlaceScheduleRequest } from "modules/schedule/domain/dto/placeSchedule/IEnabledPlaceScheduleRequest";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const PostDisablePlaceByIdUseCase = async (request: IEnabledPlaceScheduleRequest, placeScheduleApi: PlaceScheduleApiFunction) => {
    const _placeScheduleApi = placeScheduleApi();
    await _placeScheduleApi.disablePlace(request);
    return request;
}


