import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { IGetDiscountCouponsResponse } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IParamsClientDiscountCouponsList } from '../../hooks/UseDiscountCouponsList';
import { ICupomItem, IDiscountCouponsList } from '../../interfaces/IDiscountCouponsList';
import DiscountCouponHeader from './components/discountCouponContainer/DiscountCouponHeader';
import DiscountCouponItem from './components/discountCouponContainer/DiscountCouponItem';
import styles from './DiscountCouponsList.module.scss'
import { IDiscountCouponsColumnsConfig } from './interfaces/IColumnsConfig';


export interface IDiscountCouponsListProps {
    discountCouponsList: IDiscountCouponsList | undefined;
    isLoading: boolean | undefined;
    onChangePaginationHandle: (pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    }) => void;
    params: IParamsClientDiscountCouponsList;
    setParams: React.Dispatch<React.SetStateAction<IParamsClientDiscountCouponsList>>;
    pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    } | undefined;
    refrash: (params?: IParamsClientDiscountCouponsList | undefined) => Promise<IGetDiscountCouponsResponse | undefined>
}

export const DiscountCouponsList: React.FC<IDiscountCouponsListProps> = ({ discountCouponsList, isLoading, onChangePaginationHandle, params, setParams, pagination, refrash }) => {


    const { dimensions } = UseDimension();

    const [colunms, setColunms] = useState<IDiscountCouponsColumnsConfig[]>([
        {
            property: "code",
            title: "Código",
        },
        {
            property: "value",
            title: "Valor do desconto",
        },
        {
            property: "quantity",
            title: "Quantidade disponível",

        },
        {
            property: "createdAt",
            title: "Data de criação",
        },
        {
            property: "finishAt",
            title: "Data de expiração",

        },

    ]);

    const columnsLimit = useCallback((_columns: IDiscountCouponsColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 5
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])

    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, discountCouponsList])


    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, page: page - 1, pageSize });
        },
        [onChangePaginationHandle, pagination]
    );

    const List = useMemo(
        () => (
            <div className={styles.list}>
                <DiscountCouponHeader
                    columns={colunms}
                    params={params}
                    setParams={setParams}
                />
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    discountCouponsList?.records?.map((item: ICupomItem, index) => {
                        return (
                            <DiscountCouponItem
                                key={index}
                                userItemData={item}
                                columns={colunms}
                                params={params}
                                discountCouponsList={discountCouponsList}
                                refrash={refrash}
                            />
                        );
                    })
                )}
            </div>
        ),
        [colunms, discountCouponsList, isLoading, params, refrash, setParams]
    );


    const countPage = useMemo(() => Math.ceil((discountCouponsList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [discountCouponsList?.totalRecords, pagination?.pageSize])

    return (
        <div id={styles.DiscountCouponsList}>

            {
                !isLoading && !discountCouponsList?.records?.length
                    ? <ErrorMessage type="warning" />
                    : (
                        <>
                            {List}
                            <div className={styles.conteinerPagination}>
                                <div className={styles.boxMultiplePage}>
                                    <span>Exibir</span>
                                    <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                        <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                        <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                        <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                    </TextField>
                                </div>
                                <Pagination
                                    variant="outlined"
                                    className={styles.paginationNumber}
                                    shape="rounded"
                                    count={countPage}
                                    sx={{
                                        '.MuiPaginationItem-root': {
                                            borderColor: '#BDBDBD',
                                            color: '#001537',
                                        },
                                        '.Mui-selected': {
                                            fontWeight: 'bold',
                                            borderColor: '#001537',
                                        },
                                    }}
                                    onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                    page={((pagination?.page ? pagination?.page + 1 : 1))}
                                />
                            </div>
                        </>
                    )
            }

        </div>
    )
}
