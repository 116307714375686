import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { IEventsForm } from "modules/events/domain/models/IEvents";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { IEventsFormErrors } from "./interfaces/IEventsForm";
import { validate } from "./validation/EventFormValidation";

interface IUseEventsForm {
  handleSubmitForm: (evt: React.SyntheticEvent) => void;
  errors: IEventsFormErrors;
  setFormTouched: React.Dispatch<React.SetStateAction<boolean>>;
  eventForm: IEventsForm;
  handleChangeEvent: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean | undefined
  ) => void;
  handleChangeImage: (image: string) => void;
  handleCancelForm: () => void;
  toggleModal: () => void;
  cleanForm: () => void;
  openModal: boolean;
  attractionName: string | undefined;
  attractionImage: {
    imagemUrl: string;
  }[];
  handleAttractionName: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleAttractionImage: (name: string) => void;
  handleAddAttraction: () => void;
  handleDeleteAttraction: (attId: string) => void;
}

const UseEventsForm = (
  handleSubmit: (event: IEventsForm) => void,
  defaultEvent?: IEventsForm,
  handleCancel?: () => void
): IUseEventsForm => {
  const [eventForm, setEventForm] = useState<IEventsForm>({} as IEventsForm);
  const [errors, setErrors] = useState<IEventsFormErrors>({});
  const [formTouched, setFormTouched] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [attractionName, setAttractionName] = useState<string>();
  const [attractionImage, setAttractionImage] = useState<{ imagemUrl: string }[]>([]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (defaultEvent) {
      setEventForm(defaultEvent);
    }
  }, [defaultEvent]);

  const { updateRouters } = useBreadcumbs()

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Eventos', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.ADD_EVENTO, title: "Adicionar Evento", url: '/private/eventos/adicionar' },
    ])
  }, [updateRouters])

  const handleChangeEvent = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
    setEventForm((prev) => ({
      ...prev,
      [evt.target.name]: checked ?? evt.target.value,
    }));
  };

  const handleChangeImage = (image: string) => {
    setEventForm((prev) => ({
      ...prev,
      imageUrl: image,
    }));
  };

  const handleAddAttraction = useCallback(() => {
    const attractionToAdd = {
      name: attractionName ?? "",
      imageUrl: attractionImage?.length ? attractionImage[0].imagemUrl : "",
      id: uuid(),
    };
    const attractionList = eventForm?.attractions?.length
      ? [...eventForm.attractions, attractionToAdd]
      : [attractionToAdd];
    setEventForm((prev) => ({
      ...prev,
      attractions: attractionList,
    }));
    setAttractionName("");
    setAttractionImage([]);
  }, [attractionImage, attractionName, eventForm.attractions]);

  const handleDeleteAttraction = useCallback((attId: string) => {
    setEventForm((prev) => ({
      ...prev,
      attractions: prev.attractions.filter((item) => item.id !== attId),
    }));
  }, []);

  const handleAttractionName = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAttractionName(evt.target.value);
  };

  const handleAttractionImage = (imagemUrl: string) => {
    setAttractionImage([{ imagemUrl }]);
  };

  const handleSubmitForm = (evt: React.SyntheticEvent) => {
    evt.preventDefault();

    if (validate(eventForm, setErrors)) {
      handleSubmit(eventForm);
    }
  };

  const cleanForm = () => {
    setEventForm({} as IEventsForm);
    handleCancel && handleCancel();
  };

  const handleCancelForm = () => {
    if (formTouched) {
      toggleModal();
    } else {
      handleCancel && handleCancel();
    }
  };

  return {
    handleSubmitForm,
    setFormTouched,
    eventForm,
    handleChangeEvent,
    handleChangeImage,
    handleCancelForm,
    toggleModal,
    cleanForm,
    openModal,
    errors,
    attractionName,
    attractionImage,
    handleAttractionName,
    handleAttractionImage,
    handleAddAttraction,
    handleDeleteAttraction,
  };
};

export default UseEventsForm;
