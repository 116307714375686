import { ICashierResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

const GetOpenCashierUseCase = async (
  eventsService: IEventsApi,
  localId: string,
): Promise<ICashierResponse> => {
  return await eventsService.getOpenCashier(localId);
};

export default GetOpenCashierUseCase;
