import { IAuthPageRequest } from "../UseAuthPage";

export interface IAuthPageFormErrors {
  userName?: string;
  password?: string;
  code?: string;
}

export const AuthPageValidation = (formData: IAuthPageRequest, setErrors: React.Dispatch<React.SetStateAction<IAuthPageFormErrors>>) => {
  let _errors: IAuthPageFormErrors = {};
  let hasError = false;

  if (!formData.userName) {
    _errors = { ..._errors, userName: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.password) {
    _errors = { ..._errors, password: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
