import { Drawer, IconButton } from '@mui/material';
import React, { FC } from 'react';
import styles from './RightDrawer.module.scss';
import CloseIcon from '@mui/icons-material/Close';

interface IRightDrawerProps {
  open: boolean;
  onClose: () => void;
  className?: string;
  title?: string | JSX.Element;
  titleBold?: string;
};

export const RightDrawer: FC<IRightDrawerProps> = ({ open, onClose, children, className, title, titleBold }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <div id={styles.RightDrawer} className={className}>
        <header>
          <p>{title} <b>{titleBold}</b></p>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </header>
        <div className={styles.children}>
          {children}
        </div>
      </div>
    </Drawer>
  );
};