import GetHouseFlowUseCase from "modules/houseFlow/application/useCases/GetHouseFlowUseCase";
import { EnumCardStatus } from "modules/houseFlow/domain/dto/IGetHouseFlowListResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import HouseFlowApi from "services/api/houseFlow/HouseFlowApi";
import { IHouseFlowFilter } from "../components/houseFlowFilter/components/interfaces/IHouseFlowFilter";
import { IHouseFlow } from "../interfaces/IHouseFlow";


export interface ParamsClientHouseFlow {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  filter?: IHouseFlowFilter;
}

const UseHouseFlowList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [houseFlowList, setHouseFlowList] = useState<IHouseFlow>();
  const { currentLocal } = useLocal();
  const [available, setAvailable] = useState<number>(0)
  const [idle, setIdle] = useState<number>(0)
  const [inUse, setInUse] = useState<number>(0)

  const getHouseFlowList = useCallback(
    async (params?: ParamsClientHouseFlow) => {
      try {
        const serviceHouseFlow = HouseFlowApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetHouseFlowUseCase(serviceHouseFlow,
            currentLocal.id,
            params?.filter?.cardId,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter
            ,);
          setHouseFlowList(response);
          response.cardsResume.find(item => {
            if (item.cardStatus === EnumCardStatus.AVAILABLE) {
              setAvailable(item.total)
            } else if (item.cardStatus === EnumCardStatus.INUSE) {
              setInUse(item.total)
            } else {
              setIdle(item.total)
            }
          })

          setHouseFlowList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );



  return {
    houseFlowList,
    isLoading,
    getHouseFlowList,
    setHouseFlowList,
    available,
    idle,
    inUse

  };
};

export default UseHouseFlowList;
