import React, { FC } from "react";
import maleIcon from "../../assets/icons/man_icon.svg";
import femaleIcon from "../../assets/icons/woman_icon.svg";
import style from "./GuestCount.module.scss";

interface IGuestCountProps {
  total: number;
  male: number;
  female: number;
  title: string;
}

const GuestCount: FC<IGuestCountProps> = ({ total, female, male, title }) => {
  return (
    <div id={style.GuestCount}>
      <p>
        <aside className={style.title}>{title}:</aside> 
        <aside className={style.info}>{total || 0}</aside>
      </p>
      {/* <div>
        <p>
          <img src={maleIcon} alt="Male_icon" />
          <span>{male || 0}</span>
        </p>
        <p>
          <img src={femaleIcon} alt="Female_icon" color="red" />
          <span>{female || 0}</span>
        </p>
      </div> */}
    </div>
  );
};

export default GuestCount;
