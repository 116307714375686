import Layout from "components/layout/presentation/Layout";
import CrmCampaignEditPage from "modules/crm/presentation/pages/campaignEdit/CrmCampaignEditPage";

export const CrmCampaignEdit = () => {
  return (
    <Layout>
      <CrmCampaignEditPage />
    </Layout>
  );
};
