import styles from "./TerminalSidesheets.module.scss";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import Button from "components/ui/Button/Button";
import { ContentCopyOutlined } from "@mui/icons-material";

interface ITerminalQRCodeProps {
    value?: string | null;
}

export const TerminalSidesheetsQRCode = ({
    value
}: ITerminalQRCodeProps) => {
    return (
        <div className={styles.qrcodeContent}>
            {value && (
                <div>
                    <QRCode
                        size={200}
                        value={value}
                    />
                    <span>Faça a leitura do QR Code para acessar pelo celular</span>
                    <Button
                        variant="outlined"
                        color="inherit"
                        endIcon={<ContentCopyOutlined />}
                        onClick={() => {
                            navigator.clipboard.writeText(value);
                            toast.info("Link copiado para area de transferência!");
                        }}
                    >
                        Copiar link
                    </Button>
                    <div className={styles.tip}>
                        <img src="/assets/icon/saas/icon-idea.svg" alt="Dica"/>
                        <span>
                            Sabia que você pode deixar seu terminal como atalho no seu celular e exibi-lo como um aplicativo?{` `}
                            <a href="https://www.youtube.com/@MeepConnect">Saiba mais!</a>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};
