import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { useEffect, useState } from 'react'
import { Cell, Pie, PieChart } from 'recharts';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '../../skeleton/Skeleton';
import { ICanceled } from '../interfaces/ICanceled';
import styles from './PieCanceled.module.scss'

interface IChartData {
    id?: string;
    name: string;
    value: number;
    color: string;
}

interface PieCanceledProps {
    loading: boolean;
    data?: ICanceled
}

const PieCanceled = ({ loading, data }: PieCanceledProps) => {
    const [chartData, setChartData] = useState<IChartData[]>([])

    useEffect(() => {
        setChartData([
            { id: '1', name: 'Cancelamento de consumo cashless', value: data?.consumption.total || 0, color: '#955CFF' },
            { id: '2', name: 'Taxa de serviço', value: data?.consumption.serviceTax || 0, color: '#001537' },
            { id: '3', name: 'Cancelamento de recarga', value: data?.recharge.total || 0, color: '#CAAEFF' },
            
        ])
    }, [data?.consumption.serviceTax, data?.consumption.total, data?.recharge.total])

    if (loading) return (
        <div className={styles.skeletonContainer}>
            <Skeleton items={1} containerStyle={styles.skeletonPieContentStyle} cardStyle={styles.skeletonPieCardStyle} />
            <Skeleton items={4} containerStyle={styles.skeletonContentStyle} cardStyle={styles.skeletonCardStyle} />
        </div>
    )

    return (
        <div id={styles.PieCanceled} >
            {
                !chartData.reduce((acc, next) => acc + next.value, 0) ? <ErrorMessage type="warning" /> :
                <>
                    <div className={styles.pie} >
                        <PieChart width={200} height={200} >
                            <Pie
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {chartData?.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                            {/* <Legend /> */}
                        </PieChart>
                    </div>
                    <div className={styles.legend}>
                        {chartData?.map((entry, index) => (
                            <div key={index} className={styles.item} >
                                <div
                                    style={{
                                        width: 20,
                                        minWidth: 20,
                                        borderRadius: 2,
                                        height: 20,
                                        backgroundColor: entry?.color || '#ccc'
                                    }}>
                                </div>
                                <div>
                                    <span>{entry.name}</span> <span>{moneyMaskNumber(entry.value)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div >
    )
}

export default PieCanceled
