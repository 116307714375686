export enum IDiscountProductType {
  percentual = 'Percentual',
  money = 'Absoluto',
}

export enum ProductTypeEnum {
  Ingresso_Nome_na_Lista = 15,
  Ingresso_Avulso = 16,
}

export interface IDiscountProduct {
  name: string;
  id: string;
  price: number;
}
export interface IDiscountProductListForm {
  id?: string;
  type: IDiscountProductType;
  product: IDiscountProduct;
  discount: number;
  validDate?: Date; //date
  validTime?: Date | string; //time
}

export interface IDiscountProductListFormOut {
  percentualDiscount: {
    percentual: number;
  };
  productId: string;
}

export interface IDiscountProductListFormErrors {
  product?: string;
  discount?: string;
  validDate?: string;
  validTime?: string;
}
