import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import ConfigContentOmie from "../../components/_configCampaign/configContentOmie/ConfigContentOmie";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";

export interface IConfigOmieProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigOmie = ({ open, setOpen, step, setStep }: IConfigOmieProps) => {
  return (
    <>
      <ContentBox
        status="unavailable"
        type={IntegrationTypeEnum.OMIE}
        text="Integração para gestão de negócios e ERP em diversos serviços"
        setOpen={setOpen}
        getMoreRef="/private/omie/detail"
      />
      {open && open === "OMIE" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={<ConfigContentOmie step={step} />}
          cancelButton
          continueButton="Concluir"
          handleContinueButton={() => setStep(step + 1)}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração realizada com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigOmie;
