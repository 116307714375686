import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, Icon, IconButton, Tooltip } from "@material-ui/core";
import styles from "./CrmUser.module.scss";
import CrmAcorddion from "../crmAcorddion/CrmAcorddion";
import { EnumGender } from "modules/crm/presentation/intefaces/GenderEnum";
import {
  CRMColunmsPropertyType,
  ICrmColumnsConfig,
  ICrmCustomerEventsColumnsConfig,
} from "../interfaces/IColumnsConfig";
import { ICrmCustomerData } from "modules/crm/presentation/intefaces/CrmUserData";
import { useSelectCrmCustomer } from "modules/crm/presentation/context/SelectCustomerContext";
import { differenceInYears } from "date-fns";
import { ICustomerEventsListData } from "modules/crm/presentation/intefaces/ICustomerEventData";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFidelityPlans } from "modules/fidelity/presentation/interfaces/IFidelityPlans";
import { WhatsApp } from "@material-ui/icons";

interface Props {
  columns: ICrmColumnsConfig[];
  userItemData: ICrmCustomerData;
  fidelityList: IFidelityPlans[];
  getCustomerEvents: (
    customerId: string,
    pagination: { page: number; pageSize: number }
  ) => Promise<ICustomerEventsListData>;
  columnsCustomerEvent: ICrmCustomerEventsColumnsConfig[];
  onOpenNps: () => void
}

const CrmUserItem: React.FC<Props> = ({
  userItemData,
  columns,
  fidelityList,
  getCustomerEvents,
  columnsCustomerEvent,
}) => {
  const [open, setOpen] = useState<boolean>();
  const { checkPermission } = usePlans();
  const [fidelityPlansOrdered, setFidelityPlansOrdered] = useState<IFidelityPlans[]>();

  const handleUserAge = useCallback(
    () =>
      userItemData.birthday
        ? differenceInYears(new Date(), new Date(userItemData.birthday))
        : "-",
    [userItemData.birthday]
  );

  useEffect(() => {
    if (fidelityList.length && !fidelityPlansOrdered) {
      setFidelityPlansOrdered(JSON.parse(JSON.stringify(fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1))));
    }
  }, [fidelityList, fidelityPlansOrdered]);
  
  const getFidelity = useCallback((points: number) => {
    let fidelityName = '';
    fidelityPlansOrdered?.forEach(x => {
      const pointsRequired = x.pointsRequired || 0;
      if (points >= pointsRequired && !fidelityName) {
        fidelityName = x.name;
      }
    });

    return fidelityName || '-';
  }, [fidelityPlansOrdered]);

  const formatValues = useCallback(
    (property: CRMColunmsPropertyType): ReactNode => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {
          case "birthday":
            return new Date(userItemData["birthday"]).toLocaleDateString();
          case "surveyHealth":
            return (
              <Tooltip title={userItemData.surveyHealth ?? "Saúde"}>
                <div>{getHealthIcon(userItemData.surveyHealth, checkPermission(FeatureTypeEnum.CRM))}</div>
              </Tooltip>
            );
          case "gender":
            //formatar genero genero
            if (userItemData["gender"]?.id === EnumGender.male) {
              return (
                <div className={`${styles.genreChip} ${styles.male}`}>
                  Masculino
                </div>
              );
            } else if (userItemData["gender"]?.id === EnumGender.female) {
              return (
                <div className={`${styles.genreChip} ${styles.female}`}>
                  Feminino
                </div>
              );
            } else {
              return <div className={styles.genreChip}>Desconhecido</div>;
            }
          case "age":
            return handleUserAge();
          case "customerFidelityPlan":
            return userItemData?.customerFidelityPlan ? getFidelity(userItemData?.customerFidelityPlan?.points) : '-';
          case "phoneNumber":
            return userItemData?.[property] && (
              <span className={styles.wrapTextIcon}>
                {userItemData?.[property]}  
                <a href={`https://api.whatsapp.com/send?phone=55${userItemData?.[property]?.replace(/[^0-9]+/gi, '')}`} title="Clique para abrir o WhatsApp">
                  <WhatsApp color="secondary" />
                </a>
              </span>
            );            
          default:
            return userItemData?.[property];
        }
      }
    },
    [userItemData, checkPermission, handleUserAge, getFidelity]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={`${styles.row} ${styles.bodyRow}`}
              style={colunm.style ?? { flex: 1 }}
            >
              {
                colunm.isPremium && !checkPermission(FeatureTypeEnum.CRM) 
                ? <div style={{ background: '#F5F5F5', height: 25, maxWidth: 174, width: '100%', }} />
                : <span>{formatValues(colunm.property)}</span>
              }
            </div>
          )
        );
      }),
    [checkPermission, columns, formatValues]
  );

  return (
    <div id={styles.CrmItemContainer}>
      <div className={styles.container}>
        <div className={styles.checkbox}>
          <CheckBoxUser userItemData={userItemData} />
        </div>
        {List}
        <div className={styles.icon}>
          <IconButton onClick={() => setOpen((prev) => !prev)}>
            <Icon>{open ? "expand_less" : "expand_more"}</Icon>
          </IconButton>
        </div>
      </div>
      {open && (
        <CrmAcorddion
          columns={columns}
          columnsConfig={columnsCustomerEvent}
          userDetail={userItemData}
          getCustomerEvents={getCustomerEvents}
          fidelityList={fidelityList}
        />
      )}
    </div>
  );
};

export default CrmUserItem;

/**
 * Checkbox com contexto direto para selecionar clientes
 */
const CheckBoxUser = (props: { userItemData: ICrmCustomerData }) => {
  const { onCheckItem, isChecked } = useSelectCrmCustomer();
  return (
    <Checkbox
      checked={isChecked(props.userItemData)}
      onChange={(_, value) => onCheckItem?.(props.userItemData, value)}
    />
  );
};

export const getHealthIcon = (value?: number, isPremium?: boolean) => {
  if (value) {
    if (value >= 0 && value < 33) {
      return (
        <Icon
          style={{
            color: !isPremium ? '#A2A2A2' : "red",
            fontSize: 26,
            height: 30,
            alignItems: `center`,
            justifyContent: `center`,
            display: "flex",
          }}
          fontSize={"large"}
        >
          sentiment_dissatisfied
        </Icon>
      );
    } else if (value >= 33 && value < 80) {
      return (
        <Icon
          style={{
            color: !isPremium ? '#A2A2A2' : "orange",
            fontSize: 26,
            height: 30,
            alignItems: `center`,
            justifyContent: `center`,
            display: "flex",
          }}
          fontSize={"large"}
        >
          sentiment_neutral
        </Icon>
      );
    } else {
      return (
        <Icon
          style={{
            color: !isPremium ? '#A2A2A2' : "green",
            fontSize: 26,
            height: 30,
            alignItems: `center`,
            justifyContent: `center`,
            display: "flex",
          }}
          fontSize={"large"}
        >
          sentiment_very_satisfied
        </Icon>
      );
    }
  }
};
