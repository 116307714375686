import { Radio } from "@material-ui/core";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { useEffect, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { Skeleton } from "../../skeleton/Skeleton";
import { IOriginType } from "../interfaces/IOriginType";
import { IRechargeOrigin } from "../interfaces/IRechargeOrigin";
import styles from "./PieRechargeOrigin.module.scss";

interface IChartData {
  id?: string;
  name: string;
  value: number;
  percentage: number;
  color: string;
}

interface PieRechargeOriginProps {
  loading: boolean;
  data?: IRechargeOrigin;
  defaultType?: IOriginType;
  onGeneratingPDF?: boolean;
}

const PieRechargeOrigin = ({ loading, data, defaultType, onGeneratingPDF }: PieRechargeOriginProps) => {
  const [type, setType] = useState<IOriginType>(defaultType ?? IOriginType.recharged);
  const [chartData, setChartData] = useState<IChartData[]>([]);

  useEffect(() => {
    const values =
      type === IOriginType.consumed
        ? data?.totalConsumed
        : data?.totalRecharged;

    setChartData([
      {
        id: "1",
        name: "Aplicativo",
        value: values?.app || 0,
        percentage: values?.appPercentage || 0,
        color: "#32008E",
      },
      {
        id: "2",
        name: "Totem",
        value: values?.totem || 0,
        percentage: values?.totemPercentage || 0,
        color: "#955CFF",
      },
      {
        id: "3",
        name: "POS",
        value: values?.pos || 0,
        percentage: values?.posPercentage || 0,
        color: "#CAAEFF",
      },
      {
        id: "3",
        name: "POS+",
        value: values?.posPlus || 0,
        percentage: values?.posPlusPercentage || 0,
        color: "#B68EFF",
      },
      {
        id: "3",
        name: "Outros",
        value: values?.other || 0,
        percentage: values?.otherPercentage || 0,
        color: "#BDBDBD",
      },
    ]);
  }, [data?.totalConsumed, data?.totalRecharged, type]);

  if (loading)
    return (
      <div className={styles.skeletonContainer}>
        <Skeleton
          items={1}
          containerStyle={styles.skeletonPieContentStyle}
          cardStyle={styles.skeletonPieCardStyle}
        />
        <Skeleton
          items={4}
          containerStyle={styles.skeletonContentStyle}
          cardStyle={styles.skeletonCardStyle}
        />
      </div>
    );

  const checkError = () => {
    return (
      (type === IOriginType.recharged && !data?.totalRecharged.total) ||
      (type === IOriginType.consumed && !data?.totalConsumed.total)
    );
  };

  return (
    <div id={styles.PieRechargeOrigin}>
        <div className={styles.filter}>
          {onGeneratingPDF 
          ? (
            <h3>{type === 1 ? "Consumo cashless" : "Recarga"}</h3>
          ) : (
            <>
              <div>
                <Radio
                  size="small"
                  checked={type === IOriginType.recharged}
                  onChange={(_, checked) => setType(IOriginType.recharged)}
                />{" "}
                <span>Recarga</span>
              </div>
              <div>
                <Radio
                  size="small"
                  checked={type === IOriginType.consumed}
                  onChange={(_, checked) => setType(IOriginType.consumed)}
                />{" "}
                <span>Consumo cashless</span>
              </div>
            </>
          )}
        </div>
        {checkError() ? (
          <div className={styles.warning}>
            <ErrorMessage type="warning" />
          </div>
        ) : (
          <div className={styles.flexRow}>
            <div className={styles.pie}>
              <PieChart width={200} height={200}>
                <Pie
                  data={chartData.filter(x => x.value > 0)}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartData?.filter(x => x.value > 0).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className={styles.legend}>
              {chartData?.filter(x => x.value > 0).map((entry, index) => (
                <div key={index} className={styles.item}>
                  <div
                    style={{
                      width: 20,
                      minWidth: 20,
                      borderRadius: 2,
                      height: 20,
                      backgroundColor: entry?.color || "#ccc",
                    }}
                  ></div>
                  <div>
                    <span>{entry.name}</span> <span>{entry.percentage}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default PieRechargeOrigin;
