import { Button } from '@material-ui/core';
import { ICampaignValues } from 'modules/crm/presentation/intefaces/ICampaign';
import { FC, useCallback, useState } from 'react'
import { InputContainer } from '../../_inputContainer/InputContainer';
import { InputCRM } from '../../_inputCRMFilter/InputCRM'
import styles from './ConfirmationSend.module.scss'
export interface IConfirmationSendProps {
    //propertys
    campaign: ICampaignValues
    onClose: () => void,
    onClickSendCampaign: () => void,
    onClickSendPreview: (email: string[]) => void,
}
const ConfirmationSend: FC<IConfirmationSendProps> = ({
    onClose,
    onClickSendCampaign,
    onClickSendPreview,
    campaign
}) => {
    const [values, setValues] = useState({ emails: "" });

    const [detectedEmails, setDetectedEmails] = useState<string[]>([])



    const changeHandleEmails = useCallback((value: string) => {
        const emailsList = value.split(',')
        if (emailsList.length > 0) {
            setDetectedEmails(emailsList.map(item=> item.trim()).filter(item => validateEmail(item)))
        }
        setValues((prev) => ({ ...prev, emails: value }));

    }, []);

    const submitCampaignHandle = useCallback(() => {
        onClickSendCampaign();
    }, [onClickSendCampaign])

    const submitPreviewHandle = useCallback(() => {
        onClickSendPreview(detectedEmails);
    }, [detectedEmails, onClickSendPreview])


    return (
        <div id={styles.ConfirmationSend} >
            <h1>
                Enviar campanha
            </h1>
            <form className={styles.container} >
                <InputContainer className={styles.itemContainer}>
                    <div className={styles.description}>
                        <p>
                            Você está prestes a enviar esta campanha para <b>{campaign.totalCustomers}</b> clientes.
                        </p>
                        <p>
                            Deseja enviar uma mensagem de teste para que você possa revisar antes de enviar?
                        </p>
                    </div>
                </InputContainer>
                <InputContainer className={styles.itemContainer} >
                    <div className={styles.emailTagList}>
                        {detectedEmails.map((item) => <div className={styles.emailTag}>{item}</div>)}
                    </div>
                </InputContainer>
                <InputContainer className={styles.itemContainer}>
                    <InputContainer>
                        <InputCRM
                            label={"E-mails"}
                            rows={2}
                            placeholder={"primeiroemail@meep.com.br, segundoemail@meep.com.br"}
                            name="emails"
                            value={values.emails}
                            onChange={(ev) => changeHandleEmails(ev.target.value)}
                            multiline
                        ></InputCRM>
                    </InputContainer>
                </InputContainer>

                <InputContainer row className={styles.footer}>
                    <Button className={styles.backButton} onClick={onClose} variant='outlined'> Voltar</Button>
                    <Button className={styles.sendButton} disabled={detectedEmails.length < 1} onClick={submitPreviewHandle} color={'primary'} variant="outlined">Enviar Teste</Button>
                    <Button className={styles.sendCampaignButton} onClick={submitCampaignHandle} color={'secondary'} variant={'contained'}> Enviar campanha</Button>
                </InputContainer>

            </form>
        </div>
    )
}
export default ConfirmationSend

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};