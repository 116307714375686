import Layout from "components/layout/presentation/Layout";
import { ReserveListPage } from "modules/schedule/presentation/pages/reserveList/ReserveListPage";
import React from "react";

const ReserveList = () => {
  return (
    <Layout>
      <ReserveListPage />
    </Layout>
  );
};

export default ReserveList;
