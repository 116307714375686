import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import DeleteSimplePosUseCase from "modules/salesPos/application/DeleteSimplePosUseCase";
import PutEditSimplePosUseCase from "modules/salesPos/application/PutEditSimplePosUseCase";
import { IPutEditSimplePosRequest } from "modules/salesPos/domain/dto/IPutEditSimplePosRequest";
import { useCallback, useState } from "react";
import AddSimplePosApi from "services/api/salesPos/AddSimplePosApi";

const simplePosService = AddSimplePosApi();

export const UseSimplePosConfig = (disableFetch?: boolean) => {
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();

    const [isLoading, setIsLoading] = useState(false);

    const updateSimplePosConfig = useCallback(
        async (simplePosId: string, request: IPutEditSimplePosRequest) => {
            if (currentLocal) {
                try {
                    showLoading()
                    const res = await PutEditSimplePosUseCase(simplePosService, currentLocal.id, simplePosId, request);
                    return res;
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const delelteSimplePos = useCallback(
        async (simplePosId: string) => {
            if (currentLocal) {
                try {
                    setIsLoading(true);
                    await DeleteSimplePosUseCase(simplePosService, currentLocal.id, simplePosId);
                    return "ok";
                } finally {
                    setIsLoading(false);
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal]
    );    

    return {
        isLoading,
        updateSimplePosConfig,
        delelteSimplePos
    }
}