import { Checkbox, IconButton, Switch } from '@material-ui/core';
import { Cancel, Delete, Edit, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Modal } from 'components/ui/modal/Modal';
import { addHours, format } from 'date-fns';
import { IReservationList, IReserveItem, IState, IStateEnum } from 'modules/schedule/domain/models/IReserveList';
import React, { useState } from 'react'
import styles from './ReserveListCard.module.scss';
import { UseReserveListCard } from './UseReserveListCard';


export interface IReserveListCardProps {
    value: IReserveItem;
    index?: number
    refreshList: () => void;
}

export const ReserveListCard: React.FC<IReserveListCardProps> = ({ value, index, refreshList }) => {

    const [openCustomersDetails, setOpenCustomersDetails] = useState(false);
    const { removeReserve, handleModal, toggleModal, handleChangeChecked } = UseReserveListCard(refreshList);

    return (
        <div id={styles.containerDetailsCustomer} key={index}>
            <div className={styles.customerContainer}>
                <div className={styles.customer}>
                    <div className={styles.name}>Nome: {value.responsable?.name}</div>
                    <div className={styles.service}>Serviço: {value.service?.name}</div>
                    <div className={styles.participantsQuantity}>Qtd: {value.participantsQuantity}</div>
                    <div className={styles.dateTour}>
                        <div>
                            Data do passeio: {new Date(value.startAt).toLocaleDateString()}
                        </div >
                    </div>
                    <div className={styles.netValue}> Valor: {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(value.netValue)}</div>
                    <div className={styles.orderDate}>                       
                            <p> Data do pagamento: {new Date(value.orderDate).toLocaleDateString()} </p>
                            <p> Horário do pagamento: {new Date(value.orderDate).toLocaleTimeString().substr(0, 5)}</p>                       
                    </div>
                    {
                        <div className={styles.document}>Status: {IStateEnum[Number(value.currentState?.id)]}</div>
                    }
                    <div className={styles.formPayment}> Pagamento: {value.formOfPayment?.name}</div>

                    <div className={styles.buttonOptions}>
                        <IconButton>
                            {/* <Edit /> */}
                        </IconButton>
                        <IconButton>
                            <Cancel onClick={handleModal} />
                            {/* <Delete onClick={() => removeReserve(value.id)} /> */}
                        </IconButton>
                        <IconButton onClick={() => setOpenCustomersDetails((prev) => !prev)}>
                            {!openCustomersDetails ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </div>
                </div>
                <div
                    className={
                        !openCustomersDetails ? styles.customersDetailsOpen : styles.customersDetailsClose
                    }
                >
                    <div className={styles.containerDetailsCustomers}>
                        {/* <div className={styles.headerTitle}>
                            <div className={styles.titlePeople}><p>Pessoas na reserva</p></div>
                            <div className={styles.titleDocument}><p>Documento</p></div>
                            <div className={styles.titleDocument}><p>Hotel</p></div>
                            <div className={styles.titleAge}><p>Idade</p></div>
                            <div className={styles.titleAge}><p>Telefone</p></div>
                            <div className={styles.titleDocument}><p>Conferido</p></div>
                        </div> */}
                        <div>Participantes: </div>
                        {value.participants?.map((participant) => (
                            <div className={styles.containerCustomersList}>
                                <div className={styles.note}>Nome: {participant.name}</div>
                                <div className={styles.note}>Documento: {participant.document}</div>
                                <div className={styles.note}>Hotel: {participant.note}</div>
                                <div className={styles.note}>Telefone: {participant.phone}</div>
                                <div className={styles.note}> Data Nacimento:
                                    <span>{participant.age} anos</span>
                                    <span>{`(${format(addHours(new Date(participant.birthday), 3), 'dd/MM/yyyy')})`}</span>
                                </div>
                                <div className={styles.note}>
                                    Conferido: <Switch checked={participant.checkedByStaff} onChange={(_event: React.ChangeEvent<HTMLInputElement>) => handleChangeChecked(_event.target.checked, participant.serviceDemandedScheduledId)} />
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal toggleModal={handleModal} onConfirm={() => { removeReserve(value.id) }} open={toggleModal} title="Deseja cancelar está reserva?">
                <p>Tem certeza que deseja cancelar?</p>
            </Modal>
        </div>
    )
}
