import { IPostAddChildRequest } from "modules/permutation/domain/dto/IPostAddChildRequest";
import { IAddChild } from "modules/permutation/presentation/interfaces/IAddChild";

const ParseAddChildRequestUseCase  = (
  request: IAddChild,
  localId: string
) => {
  const _request: IPostAddChildRequest = {
    placeId: localId,
    name: request.name,
    document: request.document,
    hasLimit: request.hasLimit,
    limit: request.limit
  };

  return _request;
};

export default ParseAddChildRequestUseCase;
