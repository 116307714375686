import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetGuestsOnListUseCase from "modules/events/application/useCases/guests/GetGuestsOnListUseCase";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { IPeopleList } from "../eventGuestsLists/interfaces/IEventGuestsListsPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";

interface IUseGuestList {
  nameList: IPeopleList | undefined;
  getGetList: () => Promise<void>;
  onClickGoToAddGuest: () => void
}

export const UseGuestListPage = (): IUseGuestList => {
  const { guestListId } = useParams<{ guestListId: string }>();
  const { push } = useHistory();
  const { hideLoading, showLoading } = useUi();
  const [nameList, setNameList] = useState<IPeopleList>();
  const { currentLocal } = useLocal();

  const guestService = EventsGuestsApi();

  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Eventos', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.LISTA_CONVIDADOS, title: nameList?.eventName, label: "Lista de Convidados", url: `/private/event/eventos/listas/${nameList?.eventId}` },
      { id: BreadcrumbsEnum.CONVIDADOS, title: nameList?.name ?? "Convidados", label: nameList?.name ? "Convidados" : '', url: `/private/event/eventos/listas/convidados/${guestListId}` },
    ])
  }, [guestListId, nameList?.eventId, nameList?.eventName, nameList?.name, updateRouters])

  const getGetList = async () => {
    if (!currentLocal) return;

    showLoading();
    try {
      const result = await GetGuestsOnListUseCase(guestService, guestListId, currentLocal.id);
      setNameList(result);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  const onClickGoToAddGuest = () => {
    push({
      pathname: `/private/event/eventos/listas/convidados/lista/adicionar/${guestListId}`,
      search: `?guestListId=${guestListId}`,
    });
  };

  useEffect(() => {
    getGetList();
  }, [guestListId]);

  return {
    nameList,
    getGetList,
    onClickGoToAddGuest
  };
};
