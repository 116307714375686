import { useCallback, useMemo, useState } from "react";
import styles from "./CrmReportCustomerList.module.scss";
import { Pagination } from "@material-ui/lab";
import { Icon, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";
import Utils from "services/utils/Utils";
import { ICampaignMessagesData } from "modules/crm/presentation/intefaces/CampaignMessage";
import { CampaignChannel, CampaignTemplate } from "modules/crm/presentation/intefaces/ICampaign";
export interface Props {
  messageData?: ICampaignMessagesData;
  getData: (pagination: { page: number; pageSize?: number }) => void;
  loading?: boolean;
  campaingChannel?: CampaignChannel
  showError?: boolean
}

const CrmReportCustomerList = ({
  messageData,
  getData,
  loading,
  campaingChannel,
  showError
}: Props) => {

  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const onChangePageHandle = useCallback(
    (page: number, pageSize: number) => {
      getData({ page, pageSize: pageSize });
      setCurrentPageSize(pageSize);
      setCurrentPage(page);
    },
    [getData]
  );

  const List = useMemo(() => {
    return (
      <>
        {!loading ? (
          <>
            {messageData?.data.map((item) => {
              return (
                <>
                  <tr key={item.id}>
                    <td className={styles.name} >{item.sendedTo.name.toLowerCase()}</td>
                    {campaingChannel === CampaignChannel.phone ?
                      <td className={styles.phone}>{item.sendedTo.phoneNumber}</td>
                      :
                      <td className={styles.email}>{item.sendedTo.email}</td>
                    }
                    {!showError && <>
                      <td>{item.sendedAt ? <Tooltip title={new Date(item.sendedAt + "Z").toLocaleString()}><Icon fontSize="small" style={{ color: "#399c54" }}>check</Icon></Tooltip> : "-"}</td>
                      <td>{item.receivedAt ? <Tooltip title={new Date(item.receivedAt + "Z").toLocaleString()}><Icon fontSize="small" style={{ color: "#399c54" }}>check</Icon></Tooltip> : "-"}</td>
                      <td>{item.openedAt ? <Tooltip title={new Date(item.openedAt + "Z").toLocaleString()}><Icon fontSize="small" style={{ color: "#399c54" }}>check</Icon></Tooltip> : "-"}</td>
                      <td>{item.clickedAt ? <Tooltip title={new Date(item.clickedAt + "Z").toLocaleString()}><Icon fontSize="small" style={{ color: "#399c54" }}>check</Icon></Tooltip> : "-"}</td>
                      <td>{item.purchasedAt ? <Tooltip title={new Date(item.purchasedAt + "Z").toLocaleString()}><Icon fontSize="small" style={{ color: "#399c54" }}>check</Icon></Tooltip> : "-"}</td>
                    </>}
                    <td>{item.failAt ? <Tooltip title={new Date(item.failAt).toLocaleString() + "\n" + item.failReason && item.failReason}><Icon fontSize="small" style={{ color: "#f44336" }}>close</Icon></Tooltip> : "-"}</td>
                    {showError && <td className={styles.errorReason}>{item.failReason ? item.failReason : "-"}</td>}
                  </tr>
                </>
              );
            })}
          </>
        ) : (
          <>
            <tr><Skeleton variant="rectangular" width="100%" height={65} /></tr>
            <tr><Skeleton variant="rectangular" width="100%" height={65} /></tr>
            <tr><Skeleton variant="rectangular" width="100%" height={65} /></tr>
            <tr><Skeleton variant="rectangular" width="100%" height={65} /></tr>
            <tr><Skeleton variant="rectangular" width="100%" height={65} /></tr>
          </>
        )}
      </>
    );
  }, [loading, messageData?.data, campaingChannel, showError]);

  return (
    <div id={styles.CrmReportCostumersList}>
      <h1 className={styles.titleList}>Histórico de envio</h1>
      <table >
        <thead>
          <tr>
            <th className={styles.name}>Nome</th>
            {campaingChannel === CampaignChannel.phone ?
              <th className={styles.phone}><Icon fontSize="small" style={{ color: "#333" }}>phone</Icon></th>
              :
              <th className={styles.email}><Icon fontSize="small" style={{ color: "#333" }}>email</Icon></th>
            }
            {!showError && <>
              <th><Icon fontSize="small" style={{ color: "#333" }}>forward_to_inbox</Icon></th>
              <th><Icon fontSize="small" style={{ color: "#333" }}>mark_email_read</Icon></th>
              <th><Icon fontSize="small" style={{ color: "#333" }}>drafts</Icon></th>
              <th><Icon fontSize="small" style={{ color: "#333" }}>insert_link</Icon></th>
              <th><Icon fontSize="small" style={{ color: "#333" }}>shopping_cart</Icon></th>
            </>}
            <th><Icon fontSize="small" style={{ color: "#333" }}>cancel_schedule_send</Icon></th>
            {
              showError &&
              <th className={styles.errorReason}><Icon fontSize="small" style={{ color: "#333" }}>sms_failed</Icon></th>
            }
          </tr>
        </thead>
        <tbody>
          {List}
        </tbody>
      </table>
      <div style={{ display: "flex", justifyContent: "center", padding: 16 }}>
        <Pagination
          color="primary"
          className={styles.pagination}
          defaultPage={messageData?.currentPage}
          count={messageData?.totalPages}
          onChange={(_, value) => onChangePageHandle(value, currentPageSize)}
          style={{
            padding: 0
          }}
        />
        <Select size={"small"} value={currentPageSize} onChange={(ev) => onChangePageHandle(currentPage, Number(ev.target.value))}>
          <MenuItem value={10}>
            10
          </MenuItem>
          <MenuItem value={20}>
            20
          </MenuItem>
          <MenuItem value={50}>
            50
          </MenuItem>
        </Select>
      </div>
    </div >
  );
};

export default CrmReportCustomerList;
