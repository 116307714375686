import { IPaginatedRequest } from "modules/meepErp/application/dtos/paginated/IPaginatedRequest";
import { IGetSimpleProductsListResponse } from "modules/products/domain/dto/IGetProductsListResponse";
import { ProductsApi } from "services/api/products/ProductsApi";

export interface IProductListFilter extends IPaginatedRequest {
  name?: string;
}

export const GetSimpleProductsListUseCase = async (
  localId: string,
  filter: IProductListFilter
): Promise<IGetSimpleProductsListResponse> => {
  const service = ProductsApi();
  const params = {
    localId,
    keyword: filter.name,
    page: filter.page,
    pageSize: filter.pageSize,
  };

  return service.getSimpleProductsList(params);
};
