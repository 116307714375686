import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import { IEvents, OriginType } from "modules/events/domain/models/IEvents";
import EventsForm from "modules/events/presentation/components/eventsForm/EventsForm";
import React, { FC } from "react";
import warningImg from "../../../../../../../res/assets/images/warning.png";
import styles from "./ManageHeader.module.scss";
import UseManageHeader from "./UseManageHeader";
import Utils from "services/utils/Utils";

interface IManageHeaderProps {
  event: IEvents;
  refreshEvent: () => Promise<void>;
  responseConfigLocal: boolean | undefined
}

export const ManageHeader: FC<IManageHeaderProps> = ({
  event,
  refreshEvent,
  responseConfigLocal
}) => {
  const {
    openEdit,
    closeEditModal,
    openEditModal,
    handleSubmitEdit,
    parseIEventToIEventForm,
    closeDeleteModal,
    openDelete,
    openDeleteModal,
    deleteEvent,
    goToCloseEvent,
  } = UseManageHeader(refreshEvent);

  return (
    <div id={styles.ManageHeader}>
      <div className={styles.headerTitle}>
        <h1>{event.name}</h1>
        <div>
          {!!event?.cashier && (
            <Button
              variant="outlined"
              className={styles.outlined}
              color="secondary"
              fullWidth={false}
              onClick={() => goToCloseEvent(event.id)}
            >
              Relatório de fechamento
            </Button>
          )}
          {event.originType === OriginType.Portal && responseConfigLocal && (
            <>
              <Button
                variant="outlined"
                className={styles.outlined}
                color="secondary"
                fullWidth={false}
                onClick={openDeleteModal}
              >
                Excluir
              </Button>
              <Button
                fullWidth={false}
                className={styles.edit}
                onClick={openEditModal}
              >
                Editar
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.headerBody}>
        <div>
          <h4>{event.address.address || event.local.name}</h4>
          <h4>Horário do evento</h4>
          <p>{`${Utils.toDateAndTime(
            event.openingDates[0].startDate
          )} até ${Utils.toDateAndTime(event.openingDates[0].endDate)}`}</p>
          <p>{event.description}</p>
          <div>
            {event.attractions.map((it, i) => (
              <div key={i}>
                {it.imageUrl ? (
                  <img src={it.imageUrl} alt="attraction_image" />
                ) : (
                  <TheaterComedyIcon />
                )}
                <p>{it.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <img
            src={`${event.imageUrl}?timestamp=${new Date().toISOString()}`}
            alt="event_image"
          />
        </div>
      </div>
      {event.originType === OriginType.Portal && (
        <>
          <div className={styles.headerFooter}>
            <div>
              <h2>{event.guestCount}</h2>
              <p>Convidados</p>
              <div>
                <h4>Masculino:</h4>
                <h4>{event.maleGuestCount}</h4>
              </div>
              <div>
                <h4>Feminino:</h4>
                <h4>{event.femaleGuestCount}</h4>
              </div>
            </div>
            <div>
              <h2>{event.totalGuestSales + event.totalSalesNotInvited}</h2>
              <p>Ingressos vendidos</p>
              <div>
                <h4>Masculino:</h4>
                <h4>{event.maleGuestSales + (event.maleNotInvitedSales ?? 0)}</h4>
              </div>
              <div>
                <h4>Feminino:</h4>
                <h4>
                  {event.femaleGuestSales + (event.femaleNotInvitedSales ?? 0)}
                </h4>
              </div>
            </div>
            <div>
              <h2>{event.totalGuestCheckin + event.totalCheckinNotInvitedCount}</h2>
              <p>Check-in realizados</p>
              <div>
                <h4>Masculino:</h4>
                <h4>{event.maleGuestCheckin + event.maleCheckinNotInvitedCount}</h4>
              </div>
              <div>
                <h4>Feminino:</h4>
                <h4>
                  {event.femaleGuestCheckin + event.femaleCheckinNotInvitedCount}
                </h4>
              </div>
            </div>
          </div>
      </>)}
      <RightDrawer
        title="Editar Evento"
        open={openEdit}
        onClose={closeEditModal}
        className={styles.editModal}
      >
        <EventsForm
          handleSubmit={handleSubmitEdit}
          handleCancel={closeEditModal}
          defaultEvent={parseIEventToIEventForm(event)}
        />
      </RightDrawer>
      <RightDrawer
        title="Deseja realmente excluir o evento?"
        open={openDelete}
        onClose={closeDeleteModal}
      >
        <div className={styles.deleteModal}>
          <div>
            <img src={warningImg} alt="warning_image" />
            <p>Lembre-se: essa ação não poderá ser desfeita!</p>
          </div>
          <div>
            <Button
              fullWidth={false}
              onClick={closeDeleteModal}
              color="primary"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              fullWidth={false}
              onClick={() => deleteEvent(event.id)}
              color="primary"
              variant="contained"
            >
              Excluir
            </Button>
          </div>
        </div>
      </RightDrawer>
    </div>
  );
};
