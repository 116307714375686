import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import styles from '../FidelityForm.module.scss'
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import { TextField } from '@material-ui/core';
import { Icon } from '@mui/material';

export interface ICoinExchangeValues {
    amountValue: number,
    enabled: boolean,

}
export interface IExchangeFormProps {
    //propertys
    onChange?: (value: ICoinExchangeValues) => void;
    values?: ICoinExchangeValues
}
const ExchangeForm: FC<IExchangeFormProps> = ({ onChange, values }) => {


    const changeHandleMoney = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {

            onChange?.({
                ...values,
                enabled: moneyToFloat(ev.target.value) > 0,
                amountValue: moneyToFloat(ev.target.value),
            });
        },
        [onChange, values]
    );

    var amountValue = useMemo(() => moneyMaskNumber(values?.amountValue ?? 0), [values?.amountValue])
    return (
        <div className={styles.exchangeForm} >
            <div className={styles.description} >
                O valor de uma moeda definido será usada no momento de sua troca por desconto:
            </div>

            <div className={styles.row}>
                <div className={styles.item}>
                    <label htmlFor="target">Cada moeda:</label>
                    <TextField
                        name="target"
                        type="text"
                        value={1}
                        variant="outlined"
                        fullWidth
                        size="small"
                        className={styles.textField}
                        placeholder={"Ex: 1"}
                        disabled={true}
                        inputProps={{
                            classes: {
                                root: styles.cssOutlinedInput,
                                focused: styles.cssFocused,
                                notchedOutline: styles.notchedOutline,
                            }
                        }}
                    />
                </div>
                <div className={styles.separatorRowArrow}>
                    <Icon>east</Icon>
                </div>
                <div className={styles.item}>
                    <label htmlFor="points">Equivale a:</label>
                    <TextField
                        name="value"
                        type="tel"
                        onChange={changeHandleMoney}
                        value={amountValue}
                        variant="outlined"
                        fullWidth
                        size="small"
                        className={styles.textField}
                        placeholder={"Ex: R$2,00"}
                    />
                </div>
            </div>
        </div>
    )
}
export default ExchangeForm