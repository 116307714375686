import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";

const DeletePaymentLinkUseCase = async (
  service: IPaymentLinkService,
  paymentLinkId: string
) => {
  const response = await service.deletePaymentLink(paymentLinkId);
  return response;
};

export default DeletePaymentLinkUseCase;
