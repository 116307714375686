import { IPostSearchAnticipateService } from "../interfaces/IContaDigitalApiService";
import { SearchAnticipateRequest } from "modules/contaDigital/models/dtos/SearchAnticipateRequest";

const PostSearchAnticipate = async (
  accountService: IPostSearchAnticipateService,
  body: SearchAnticipateRequest
): Promise<any> => {
  return await accountService.postSearchAnticipate(body);
};

export default PostSearchAnticipate;
