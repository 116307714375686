import { Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React from 'react'
import styles from './Tooltip.module.scss';


interface TooltipProps {
    title: string;
    paragraph: string;
}

// const TooltipStyle = withStyles((theme: Theme) => ({
//     tooltip: {
//         backgroundColor: '#FFF',
//         color: 'rgba(0, 0, 0, 0.87)',
//         maxWidth: 420,
//         fontSize: theme.typography.pxToRem(16),
//         fontWeight: theme.typography.fontWeightBold,
//         border: '1px solid #dadde9',
//         padding: 16
//     },
// }))(Tooltip);

export const TooltipInfo: React.FC<TooltipProps> = ({ title, paragraph }) => {
    return (
        <div id={styles.Tooltip}>
            <Tooltip
                title={
                    <>
                        <p>{title}</p>
                        {paragraph}
                    </>
                }
            >
                <Help />
            </Tooltip>
        </div>
    )
}
