import { ISurveyService } from "modules/survey/domain/interfaces/ITrackConfigService"
import { EventSurvey } from "modules/survey/presentation/components/EventSurvey"
import { TrackSurveyWidget } from "modules/survey/presentation/components/TrackSurveyWidget"
import { EnumSurveySendMethod } from "modules/survey/presentation/interfaces/EnumSurveySendMethod"
import { ITrackSurvey } from "modules/survey/presentation/interfaces/ITrackSurveyList"

const CreateCustomerSurveyUseCase = async (service: ISurveyService, ownerId: string, event: EventSurvey, trackSurvey: ITrackSurvey, sendMethod: EnumSurveySendMethod) => {
        const response = await service.create({
                ownerId,
                enabled: true,
                trackSurveyName: trackSurvey.name,
                trackSurveyId: trackSurvey.id,
                sendEventType: event.type,
                sendMethod: sendMethod
        })
        return (response)
}
export default CreateCustomerSurveyUseCase