import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useCallback, useEffect, useState } from "react";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { SaasPaymentsApi } from "services/api/saasPayment/SaasPaymentsApi";
import { IGetAllCardsResponse } from "modules/saasPayment/domain/dto/IGetAllCardsResponse";
import { GetAllCardsUseCase } from "modules/saasPayment/application/GetAllCardsUseCase";
import { IPostNewCardRequest } from "modules/saasPayment/domain/dto/IPostNewCardRequest";
import axios from "axios";
import { GetLocalSubscriptionIdUseCase } from "modules/saasPayment/application/GetLocalSubscriptionIdUseCase";
import { PostNewCardUseCase } from "modules/saasPayment/application/PostNewCardUseCase";
import { PutEditCardUseCase } from "modules/saasPayment/application/PutEditCardUseCase";
import { isValid, parse } from "date-fns";
import { DeleteCardUseCase } from "modules/saasPayment/application/DeleteCardUseCase";

const service = SaasPaymentsApi();

export interface INewCardFormErrors {
    document?: string;
    street?: string;
    number?: string;
    city?: string;
    zipCode?: string;
    cardNumber?: string;
    holderName?: string;
    expirationDate?: string;
    securityCode?: string;
}

export const UseChangePaymentMethod = () => {
    const { toast, showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();

    const [isLoading, setIsLoading] = useState(false);
    const [cardsList, setCardsList] = useState<IGetAllCardsResponse[]>();
    const [newCardForm, setNewCardForm] = useState<IPostNewCardRequest>({
        subscriptionId: "",
        payer: {
            name: "",
            document: "",
            address: {
                street: "",
                number: "",
                complement: "",
                neighborhood: "",
                city: "",
                state: "",
                zipCode: "",
            }
        },
        card: {
            cardNumber: "",
            holderName: "",
            expirationMonth: 0,
            expirationYear: 0,
            securityCode: "",
        },
        isDefault: true,
      } as IPostNewCardRequest);
    const [creditCardExpiration, setCreditCardExpiration] = useState("");
    const [errors, setErrors] = useState<INewCardFormErrors>({} as INewCardFormErrors);
    const [subscriptionCardId, setSubscriptionCardId] = useState("");
    const [defaultCard, setDefaultCard] = useState("");

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    const subscriptionCard = await GetLocalSubscriptionIdUseCase(service, currentLocal?.id);
                    setSubscriptionCardId(subscriptionCard.id ?? "");
                } finally {
                    hideLoading();
                }
            }
        })();
    }, [currentLocal, hideLoading, setSubscriptionCardId, showLoading]);

    const clearForm = () => {
        setNewCardForm({
            subscriptionId: "",
            payer: {
                name: "",
                document: "",
                address: {
                    street: "",
                    number: "",
                    complement: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                    zipCode: "",
                }
            },
            card: {
                cardNumber: "",
                holderName: "",
                expirationMonth: 0,
                expirationYear: 0,
                securityCode: "",
            },
            isDefault: true,
        } as IPostNewCardRequest);
        setCreditCardExpiration("");
    };

    const handleCreditCardExpiration = useCallback((value: string) => {
        setNewCardForm(prev => ({
            ...prev,
            card: {
                ...prev.card,
                expirationMonth: Number(value.split("/")[0]),
                expirationYear: Number("20" + value.split("/")[1])
            }
        }));
        setCreditCardExpiration(value);
    }, [setNewCardForm]);
      
    const validate = useCallback(() => {
        let hasError = false;
        setErrors({} as INewCardFormErrors);
      
        if (!newCardForm.card.cardNumber) {
            setErrors(prev => ({ ...prev, cardNumber: "O número do cartão é obrigatório" }));
            hasError = true;
        }
        if (newCardForm.card.cardNumber.replace(/\D/g, "").length < 16) {
            setErrors(prev => ({ ...prev, cardNumber: "Número inválido" }))
            hasError = true;
        }
        if (!newCardForm.card.holderName) {
            setErrors(prev => ({ ...prev, holderName: "O nome no cartão é obrigatório" }));
            hasError = true;
        }
        if (newCardForm.card.holderName.length < 3) {
            setErrors(prev => ({ ...prev, holderName: "Nome inválido" }));
            hasError = true;
        }
        if (!newCardForm.payer.document) {
            setErrors(prev => ({ ...prev, document: "O CPF é obrigatório" }));
            hasError = true;
        }
        if (newCardForm.payer.document.replace(/\D/g, "").length < 11) {
            setErrors(prev => ({ ...prev, document: "CPF inválido" }))
            hasError = true;
        }
        if (!creditCardExpiration) {
            setErrors(prev => ({ ...prev, expirationDate: "A validade é obrigatória" }));
            hasError = true;
        }
        if (creditCardExpiration && creditCardExpiration.split("/").length !== 2) {
            setErrors(prev => ({ ...prev, expirationDate: "A data não é válida" }));
            hasError = true;
        }
        if (creditCardExpiration && (Number(creditCardExpiration.split("/")[0]) > 12 || Number(creditCardExpiration.split("/")[1]) < 24)) {
            setErrors(prev => ({ ...prev, expirationDate: "A data não é válida" }));
            hasError = true;
        }
        if (creditCardExpiration && 
            (!isValid(parse(creditCardExpiration, "MM/yyyy", new Date())) ||
            new Date(Number(newCardForm.card.expirationYear), Number(newCardForm.card.expirationMonth) - 1) <= new Date())
        ) {
            setErrors(prev => ({ ...prev, expirationDate: "A data não é válida" }));
            hasError = true;
        }
        if (!newCardForm.card.securityCode) {
            setErrors(prev => ({ ...prev, securityCode: "O CVV é obrigatório" }));
            hasError = true;
        }
        if (newCardForm.card.securityCode.replace(/\D/g, '').length < 3) {
            setErrors(prev => ({ ...prev, securityCode: 'CVV inválido' }))
            hasError = true;
        }
        if (!newCardForm.payer.address.zipCode) {
            setErrors(prev => ({ ...prev, zipCode: "O CEP é obrigatório" }));
            hasError = true;
        }
        if (!newCardForm.payer.address.street) {
            setErrors(prev => ({ ...prev, street: "A rua é obrigatória" }));
            hasError = true;
        }
        if (!newCardForm.payer.address.number) {
            setErrors(prev => ({ ...prev, number: "O número é obrigatório" }));
            hasError = true;
        }
        if (!newCardForm.payer.address.city) {
            setErrors(prev => ({ ...prev, city: "A cidade é obrigatória" }));
            hasError = true;
        }
      
        return !hasError;
    }, [creditCardExpiration, newCardForm]);

    const getCardsList = useCallback(async () => {
        if (subscriptionCardId) {
            try {
                setIsLoading(true);
                const response = await GetAllCardsUseCase(service, subscriptionCardId);
                setCardsList(response);
                setDefaultCard(response.find(card => card.isDefault)?.subscriptionCardId ?? "");
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [subscriptionCardId]);

    const handleSearchByCEP = useCallback(async (value: string) => {
        const cep = value.replace(/\D/g, "");
        const validateCEP = /^[0-9]{8}$/;

        if(validateCEP.test(cep)) {
            try {
                setErrors(prev => ({ ...prev, zipCode: undefined }));
                showLoading();
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                
                if (!response.data.erro) {
                    setErrors(prev => ({ ...prev, zipCode: undefined, street: undefined, city: undefined }));
                    setNewCardForm(prev => ({
                        ...prev,
                        payer: {
                            ...prev.payer,
                            address: {
                                ...prev.payer.address,
                                street: response.data.logradouro,
                                neighborhood: response.data.bairro,
                                city: response.data.localidade,
                                state: response.data.uf,
                            }
                        }
                    }));
                } else {
                    setErrors(prev => ({ ...prev, zipCode: "CEP não encontrado" }));
                }
            } finally {
                hideLoading();
            }            
        } else {
            setErrors(prev => ({ ...prev, zipCode: "CEP inválido" }));
        }
    }, [hideLoading, showLoading]);

    const handleDeleteCard = useCallback(async (cardId: string) => {
        try {
            setIsLoading(true);
            await DeleteCardUseCase(service, cardId);
            return "success";
        } catch (error) {
            setIsLoading(false);
        }
    }, []);

    const handleAddCard = useCallback(async () => {
        if (subscriptionCardId) {
            try {
                if (!validate()) return;

                setIsLoading(true);
                await PostNewCardUseCase(service, subscriptionCardId, { ...newCardForm, subscriptionId: subscriptionCardId });
                toast("Cartão adicionado com sucesso!", "success");
                return "success";
            } finally {
                setIsLoading(false);
            }
        }
    }, [newCardForm, subscriptionCardId, toast, validate]);

    const handleEditCard = useCallback(async (cardId: string) => {
        try {
            setIsLoading(true);
            await PutEditCardUseCase(service, cardId);
            return "success";
        } catch (error) {
            return;
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {
        isLoading,
        cardsList,
        getCardsList,
        newCardForm,
        setNewCardForm,
        handleSearchByCEP,
        errors,
        setErrors,
        creditCardExpiration,
        handleCreditCardExpiration,
        clearForm,
        handleDeleteCard,
        handleAddCard,
        handleEditCard,
        defaultCard,
    }
}