import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './LinkCardsList.module.scss'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { ILinkCardsList, ILinkCardItem } from '../../interfaces/ILinkCardsList';
import { ILinkCardsColumnsConfig } from './interfaces/IColumnsConfig';
import LinkCardsItemHeader from './components/linkCardsListContainer/LinkCardsItemHeader';
import { LinkCardsItemMobile } from './components/linkCardsItemMobile/LinkCardsItemMobile';
import LinkCardsItem from './components/linkCardsListContainer/LinkCardsItem';
import { IParamsLinkCardsList } from '../../hooks/UseLinkCardsList';


export interface ILinkCardsListProps {
    linkCardsList: ILinkCardsList | undefined;
    isLoading: boolean | undefined;
    params: IParamsLinkCardsList,
    setParams: React.Dispatch<React.SetStateAction<IParamsLinkCardsList>>;
    pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    } | undefined;
    reload: () => void;
    open: boolean;
    setOpenSidesheet: React.Dispatch<React.SetStateAction<"add" | null>>
    onChangePaginationHandle: (pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    }) => void;
    onSelectLinkCardsHandle: (product: ILinkCardItem, checked: boolean) => void
    setSelectedLinkCards: React.Dispatch<React.SetStateAction<ILinkCardItem[]>>
    allSelected: boolean
    setAllSelected: React.Dispatch<React.SetStateAction<boolean>>
}

export const LinkCardsList: React.FC<ILinkCardsListProps> = ({ open, allSelected, setAllSelected, linkCardsList,setSelectedLinkCards, isLoading, onChangePaginationHandle, pagination, params, reload, setOpenSidesheet, setParams, onSelectLinkCardsHandle }) => {

    const { dimensions } = UseDimension();
    const { isMobile } = useUi();

    const [colunms, setColunms] = useState<ILinkCardsColumnsConfig[]>([
        {
            property: "tag",
            title: "Tag",
        },
        {
            property: "createdBy",
            title: "Vinculado por",
        },
        {
            property: "createdAt",
            title: "Vinculado em",

        },
    ]);

    const columnsLimit = useCallback((_columns: ILinkCardsColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 5
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])


    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, linkCardsList])


    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, page: page - 1, pageSize });
        },
        [onChangePaginationHandle, pagination]
    );


    const onCheckLinkCardHandle = useCallback((product: ILinkCardItem, value: boolean) => {
        onSelectLinkCardsHandle?.(product, value);
    }, [onSelectLinkCardsHandle])


    const List = useMemo(
        () => (
            <div className={styles.list}>
                {
                    !isMobile &&
                    <LinkCardsItemHeader
                        columns={colunms}
                        params={params}
                        setParams={setParams}
                        setSelectedLinkCards={setSelectedLinkCards}
                        allSelected={allSelected}
                        setAllSelected={setAllSelected}
                        linkCardsList={linkCardsList}
                    />

                }
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    linkCardsList?.records?.map((item: ILinkCardItem, index) => {
                        return (
                            isMobile ?
                                <LinkCardsItemMobile
                                    key={index}
                                    userItemData={item}
                                    params={params}
                                    linkCardsList={linkCardsList}
                                    reload={reload}
                                    setOpenSidesheet={setOpenSidesheet}

                                />
                                :
                                <LinkCardsItem
                                    key={index}
                                    open={open}
                                    userItemData={item}
                                    setOpenSidesheet={setOpenSidesheet}
                                    columns={colunms}
                                    params={params}
                                    linkCardsList={linkCardsList}
                                    reload={reload}
                                    allSelected={allSelected}
                                    setAllSelected={setAllSelected}
                                    onCheckLinkCardHandle={onCheckLinkCardHandle}
                                    setSelectedLinkCards={setSelectedLinkCards}
                                />
                        );
                    })
                )}
            </div>
        ),
        [isMobile, colunms, params, setParams, setSelectedLinkCards, allSelected, setAllSelected, isLoading, linkCardsList, reload, setOpenSidesheet, open, onCheckLinkCardHandle]
    );

    const countPage = useMemo(() => Math.ceil((linkCardsList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [linkCardsList?.totalRecords, pagination?.pageSize])



    return (
        <div id={styles.LinkCardsList}>

            {
                !isLoading && !linkCardsList?.records?.length
                    ?
                    (<div className={styles.emptyList}>
                        <img src="/assets/img/empty-box.png" alt="" />

                        <span>Seu estabelecimento ainda <b>não possui
                            </b> vínculo de cartões.</span>
                    </div>)
                    : (
                        <>
                            {List}
                            <div className={styles.conteinerPagination}>
                                <div className={styles.boxMultiplePage}>
                                    <span>Exibir</span>
                                    <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                        <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                        <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                        <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                    </TextField>
                                </div>
                                <Pagination
                                    variant="outlined"
                                    className={styles.paginationNumber}
                                    shape="rounded"
                                    count={countPage}
                                    sx={{
                                        '.MuiPaginationItem-root': {
                                            borderColor: '#BDBDBD',
                                            color: '#001537',
                                        },
                                        '.Mui-selected': {
                                            fontWeight: 'bold',
                                            borderColor: '#001537',
                                        },
                                    }}
                                    onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                    page={((pagination?.page ? pagination?.page + 1 : 1))}
                                />
                            </div>
                        </>
                    )
            }


        </div>
    )
}
