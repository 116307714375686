import React, {
  useCallback,
  useRef,
  useState
} from "react";
import styles from "./DateRangerPicker.module.scss";
import Calendar from "react-calendar";
import { InputCRM } from "../../../_inputCRMFilter/InputCRM";
import { Icon, IconButton, Popover } from "@material-ui/core";
import { InputProps } from "@mui/material";

export const DateRangePicker = ({
  label, placeholder, value, selectRange, onChange, valueCalendar, required, premium, showPremiumCrown, inputProps, error, helperText, notShowCloseIcon = false
}: {
  label: string;
  placeholder: string;
  value: string;
  selectRange?: boolean;
  onChange: (values?: [Date, Date] | [Date]) => void;
  valueCalendar?: Date | [Date | null, Date | null] | null;
  required?: boolean;
  premium?: boolean;
  showPremiumCrown?: boolean;
  inputProps?: Partial<InputProps>;
  notShowCloseIcon?: boolean;
  error?: boolean
  helperText?: string
}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClose = useCallback(() => {
    inputRef.current?.blur();
    setAnchorEl(null);
  }, [])

  const handleClick = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
    inputRef.current?.blur();
  }, []);

  const onChangeHandle = useCallback(
    (values?: [Date, Date] | [Date]) => {
      onChange?.(values);
      handleClose();
    },
    [handleClose, onChange]
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className={styles.inputContainer}   >
        <InputCRM
          label={label}
          inputRef={inputRef}
          onFocus={handleClick}
          placeholder={placeholder}
          value={value}
          required={required}
          premium={premium}
          showPremiumCrown={showPremiumCrown}
          InputProps={inputProps}
          error={error}
          helperText={helperText}
        />
       {!!value && !notShowCloseIcon && <div className={styles.clearButton}>
          <IconButton size="small" onClick={() => onChangeHandle()}><Icon>close</Icon></IconButton>
        </div>}
      </div >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.calendarContainer}>
          <Calendar
            className={styles.calendar}
            onChange={onChangeHandle}
            selectRange={selectRange}
            value={valueCalendar} />
        </div>
      </Popover>
    </>
  );
};
