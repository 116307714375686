import { useLocal } from 'modules/local/presentation/context/LocalContext'
import qs from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService'
import GetEventListUseCase from '../application/useCases/GetEventListUseCase'
import { ICustomerDashboardFilter } from '../domain/dto/FilterRequest.type'
import { IEventListFilterAutocomplete, IRequestEventListFilter } from './components/filter/FilterAutocompleteEventsList'
import { OperationStringEnum } from './components/operationFilter/OperationFilter'

const UseCustomersDashboardPage = () => {
    const { currentLocal } = useLocal();
    const [filter, setFilter] = useState<ICustomerDashboardFilter>({ eventId: "", cashierId: "", localId: currentLocal?.id })
    const [operationFilter, setOperationFilterFilter] = useState<OperationStringEnum>(OperationStringEnum.all)
    const [eventList, setEventList] = useState<IEventListFilterAutocomplete>();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id && eventList?.records.length) {
            setFilter(prev => ({ ...prev, localId: currentLocal?.id, eventId: id, cashierId: eventList.records.find(item => item.eventId === id)?.cashierId }))
        }
    }, [currentLocal?.id, eventList?.records, id])


    useEffect(() => {
        window.history.replaceState(null, "", `?${qs.stringify({ cashierId: filter.cashierId })}`)
    }, [filter.cashierId])


    // useEffect(() => {
    //     if (currentLocal) {
    //         setFilter(prev => ({ ...prev, localId: currentLocal.id }))
    //     }
    // }, [currentLocal]);


    const getEventList = useCallback(async (request: IRequestEventListFilter) => {
        if (currentLocal) {
            const serviceCustomersDashboard = CustomersDashboardService();
            const response = await GetEventListUseCase(serviceCustomersDashboard, currentLocal.id, request)
            setEventList(response)
        } else {
            throw new Error('local não selecionado')
        }
    }, [currentLocal])

    return ({ getEventList, setFilter, filter, operationFilter, setOperationFilterFilter, eventList })
}

export default UseCustomersDashboardPage