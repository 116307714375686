import { IAccessService } from '../../interfaces/IAccessService';

const DeleletePromoterFromListUseCase = async (
  accessService: IAccessService,
  listId: string,
  promoterId: string,
  localId: string,
): Promise<void> => {
  return await accessService.deletePromoterFromList(listId, promoterId, localId);
};

export default DeleletePromoterFromListUseCase;
