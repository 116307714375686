import React, { useCallback, useState } from 'react'
import styles from './UseFormItem.module.scss'
import { IChildren } from 'modules/permutation/presentation/interfaces/IAddPermutionAccount'
import { Switch, TextField } from '@material-ui/core'
import InputMasked from 'components/inputMasked/InputMasked'
import { IChildrenErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros'
import { validateUseForm } from '../../../../validation/UseFormValidation'
import Button from 'components/ui/Button/Button'
import { v4 } from 'uuid'
import { IAddChild } from 'modules/permutation/presentation/interfaces/IAddChild'

interface IUseFormItemProps {
  closeSideSheetUseForm: () => void
  values: IChildren[]
  onSaveUse: (newChildren: IChildren[], isEdit: boolean) => void
  isEdit: React.MutableRefObject<boolean>
  onSubmitHandleEdit: (item: IAddChild) => Promise<void>
  setErrors: React.Dispatch<React.SetStateAction<IChildrenErros>>
  errors: IChildrenErros
}


export const UseFormItem: React.FC<IUseFormItemProps> = ({ setErrors, errors, onSubmitHandleEdit, isEdit, closeSideSheetUseForm, onSaveUse, values
}) => {


  const [inputChecked, setInputChecked] = useState<boolean>(false)
  const [cpfUse, setCpfUse] = useState('')
  const [nameUse, setNameUse] = useState('')
  const [limitUse, setLimitUse] = useState(0);


  const onSubmitHandle = () => {
    const newItem: IChildren = {
      id: v4(),
      document: cpfUse.replace(/\D/g, ''),
      name: nameUse,
      limit: limitUse,
      hasLimit: inputChecked
    };

    const updatedChildren = [...values, newItem];
    if (validateUseForm(newItem, setErrors)) {

      onSaveUse(updatedChildren, false);
    }
  }

  const changeHandleCPFUse = useCallback((val: string) => {
    setCpfUse(val.replace(/\D/g, ''))
  }, []);
  const changeHandleNameUse = useCallback((val: string) => {
    setNameUse(val)
  }, []);
  const changeHandleLimitUse = useCallback((val: string) => {
    setLimitUse(Number(val))
  }, []);

  const changeHandleChecked = useCallback((checked: boolean) => {
    setInputChecked(checked)
  }, []);

  return (
    <div id={styles.UseFormItem}>
      <div>

        <div className={styles.item}>
          <label>CPF <span>*</span></label>
          <InputMasked
            name="document"
            variant='outlined'
            fullWidth
            mask="999.999.999-99"
            placeholder="Digite seu CPF"
            value={undefined}
            onChange={(ev: any) =>
              changeHandleCPFUse(ev.target.value)
            }
            type="tel"
            error={!!errors.document}
            helperText={errors.document}
          />
        </div>
        <div className={styles.item}>
          <label>Nome <span>*</span></label>
          <TextField
            fullWidth
            inputProps={{ maxLength: 250 }}
            size="small"
            placeholder=""
            variant="outlined"
            name="name"
            error={!!errors.name}
            helperText={errors.name}
            onChange={(ev) =>
              changeHandleNameUse(ev.target.value)
            }
          />
          <div className={styles.containerSwitch}>
            <Switch
              color="secondary"
              name="forFirstOrder"
              onChange={(ev) =>
                changeHandleChecked(ev.target.checked)
              }
            />
            Inserir valor de utilização
          </div>

          {
            inputChecked &&
            <div className={styles.item}>
              <label>Valor de utilização</label>
              <TextField
                fullWidth
                inputProps={{ maxLength: 50 }}
                size="small"
                placeholder=""
                variant="outlined"
                name="limit"
                onChange={(ev) =>
                  changeHandleLimitUse(ev.target.value)
                }
                type='number'
              />
            </div>
          }
        </div>
      </div>
      <div className={styles.containerFooter}>
        <div className={styles.buttonFooter}>
          <Button variant='outlined' onClick={closeSideSheetUseForm}>
            Cancelar
          </Button>
          <Button variant='contained' onClick={isEdit.current ? () => onSubmitHandleEdit({
            document: cpfUse.replace(/\D/g, ''),
            hasLimit: inputChecked,
            limit: limitUse,
            name: nameUse
          }) : onSubmitHandle}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  )
}
