import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import { INotificationsService } from "components/layout/domain/interfaces/INotificationsService";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const GetNotificationsByTypeUseCase = async (
  service: INotificationsService,
  token: string | null,
  systemIdentifier: SystemIdentifierEnum,
  tipo?: string
): Promise<IGetNotificationsByTypeResponse[]> => {
  const response = await service.getNotificationsByType(token, systemIdentifier, tipo);
  return response;
};

export default GetNotificationsByTypeUseCase;
