import React, { useCallback } from "react";
import styles from "./AccessDeniedPage.module.scss";
import Layout from "components/layout/presentation/Layout";
import Button from "components/ui/Button/Button";
import { useHistory } from "react-router-dom";

interface IAccessDeniedProps { }

const AccessDeniedPage: React.FC<IAccessDeniedProps> = () => {
    const { replace } = useHistory();

    const goToLocals = useCallback(() => {
        replace("/private");
    }, [replace]);

  return (
    <Layout>
        <div id={styles.accessDenied}>
            <div className={styles.emptyContainer}>
                <img src="/assets/icon/empty-box.svg" alt="Sem permissão" />
                <p>
                Você está sem permissão para acessar. <b>Entre em contato com o administrador</b> e solicite o acesso. 
                </p>
                <Button onClick={goToLocals}>Voltar para o início</Button>
            </div>
        </div>
    </Layout>
  );
};

export default AccessDeniedPage;
