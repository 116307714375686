import { endOfDay, startOfDay } from "date-fns";
import { ICrmFilterRequest } from "modules/crm/models/dtos/ICrmFilterRequest";
import { ICrmFilterValue } from "modules/crm/presentation/components/crmFilterForm/interfaces/ICrmFilterValue";

const parseFilterToRequestUseCase = (ownerId: string, filter?: ICrmFilterValue, pagination?: { page?: number | undefined; pageSize?: number | undefined; }) => {

  const request: ICrmFilterRequest = {
    page: pagination?.page,
    keyword: filter?.keyword,
    ownerId: ownerId,
    genderId: filter?.gender,
    hasEmail: filter?.hasEmail,
    hasPhoneNumber: filter?.hasPhoneNumber,
    hasMeepApp: filter?.hasMeepApp,
    "frequency.filterType": filter?.frequency?.filterType,
    "frequency.StartValue": !Number.isNaN(filter?.frequency?.startValue) ? filter?.frequency?.startValue : undefined,
    "frequency.EndValue": !Number.isNaN(filter?.frequency?.endValue) ? filter?.frequency?.endValue : undefined,
    "age.filterType": filter?.age?.filterType,
    "age.StartAge": !Number.isNaN(filter?.age?.startAge) ? filter?.age?.startAge : undefined,
    "age.EndAge": !Number.isNaN(filter?.age?.endAge) ? filter?.age?.endAge : undefined,
    "totalConsumed.filterType": filter?.totalConsuptionValue?.filterType,
    "totalConsumed.StartValue": !Number.isNaN(filter?.totalConsuptionValue?.startValue) ? filter?.totalConsuptionValue?.startValue : undefined,
    "totalConsumed.EndValue": !Number.isNaN(filter?.totalConsuptionValue?.endValue) ? filter?.totalConsuptionValue?.endValue : undefined,
    "totalTicketPurchased.filterType": filter?.totalPurchasedTicketsValue?.filterType,
    "totalTicketPurchased.StartValue": !Number.isNaN(filter?.totalPurchasedTicketsValue?.startValue) ? filter?.totalPurchasedTicketsValue?.startValue : undefined,
    "totalTicketPurchased.EndValue": !Number.isNaN(filter?.totalPurchasedTicketsValue?.endValue) ? filter?.totalPurchasedTicketsValue?.endValue : undefined,
    "eventPeriod.StartDate":filter?.eventPeriod?.startValue?.toISOString(),
    "eventPeriod.EndDate": filter?.eventPeriod?.endValue?.toISOString(),
    "eventId": filter?.events?.map(x => x.id),
    "fidelityPlanIds": filter?.fidelityLevel?.id ? [filter.fidelityLevel.id] : undefined,
    "birthday.StartDate": filter?.birthday?.startValue ? startOfDay(filter?.birthday?.startValue)?.toISOString() : undefined,
    "birthday.EndDate": filter?.birthday?.endValue ? endOfDay(filter?.birthday?.endValue)?.toISOString() : undefined,
    "averageTicketEventTicket.filterType": filter?.averageTicketEventTicket?.filterType,
    "averageTicketEventTicket.StartValue": !Number.isNaN(filter?.averageTicketEventTicket?.startValue) ? filter?.averageTicketEventTicket?.startValue : undefined,
    "averageTicketEventTicket.EndValue": !Number.isNaN(filter?.averageTicketEventTicket?.endValue) ? filter?.averageTicketEventTicket?.endValue : undefined,
    "averageTicketConsuption.filterType": filter?.averageTicketConsuption?.filterType,
    "averageTicketConsuption.StartValue": !Number.isNaN(filter?.averageTicketConsuption?.startValue) ? filter?.averageTicketConsuption?.startValue : undefined,
    "averageTicketConsuption.EndValue": !Number.isNaN(filter?.averageTicketConsuption?.endValue) ? filter?.averageTicketConsuption?.endValue : undefined,
    "surveyHealth.filterType": filter?.surveyHealth?.filterType,
    "surveyHealth.StartValue": !Number.isNaN(filter?.surveyHealth?.startValue) ? filter?.surveyHealth?.startValue : undefined,
    "surveyHealth.EndValue": !Number.isNaN(filter?.surveyHealth?.endValue) ? filter?.surveyHealth?.endValue : undefined,
    "coins.filterType": filter?.coins?.filterType,
    "coins.StartValue": !Number.isNaN(filter?.coins?.startValue) ? filter?.coins?.startValue : undefined,
    "coins.EndValue": !Number.isNaN(filter?.coins?.endValue) ? filter?.coins?.endValue : undefined,
    "points.filterType": filter?.points?.filterType,
    "points.StartValue": !Number.isNaN(filter?.points?.startValue) ? filter?.points?.startValue : undefined,
    "points.EndValue": !Number.isNaN(filter?.points?.endValue) ? filter?.points?.endValue : undefined,
  };

  if (request) {
    if (request["age.filterType"] === 0) {
      delete request["age.filterType"];
    }
    if (request["totalConsumed.filterType"] === 0) {
      delete request["totalConsumed.filterType"];
    }
    if (request["totalTicketPurchased.filterType"] === 0) {
      delete request["totalTicketPurchased.filterType"];
    }
  }

  return request;
};

export default parseFilterToRequestUseCase
