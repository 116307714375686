import { AxiosInstance } from "axios";
import { IGetSessionsItemResponse, IGetSessionsListResponse } from "modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse";

export interface IBuscarSessoesResponse {
    sessaoId: string,
    dataInicio: string,
    dataFim: string,
    sessaoNome: string,
}

export const getBuscarSessoes = async (api: AxiosInstance, localId: string): Promise<IGetSessionsListResponse> => {
    const response = await api.get<IBuscarSessoesResponse[]>("/Relatorio/BuscarSessoesPOSDoLocal?localClienteId=" + localId)
    
    const parsedResponse: IGetSessionsListResponse = {
        items: response.data.map<IGetSessionsItemResponse>(item => ({
            id: item.sessaoId,
            name: item.sessaoNome,
            startDate: item.dataInicio,
            endDate: item.dataFim,
            localId,
        })),
        totalCount: response.data.length
    }

    return parsedResponse
}
