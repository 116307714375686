import { format } from "date-fns";
import styles from "./MeepTicketsTable.module.scss";
import { IGetOrganizerEventDataContent } from "modules/meepTickets/domain/dto/IGetOrganizerEventResponse";

export interface IMeepTicketsTableProps {
    data: IGetOrganizerEventDataContent[];
}

export const handleStatus = (status: string) => {
    switch (status) {
        case "UNPUBLISHED":
          return (
          <div className={`${styles.generalStatus} ${styles.statusUnpublished}`}>
            <span>Não publicado</span>
          </div>
        );
        case "PUBLISHED":
          return (
            <div className={`${styles.generalStatus} ${styles.statusPublished}`}>
              <span>Publicado</span>
            </div>
        );
        case "FINISHED":
          return (
            <div className={`${styles.generalStatus} ${styles.statusFinished}`}>
              <span>Finalizado</span>
            </div>
        );
        default:
          break;
    }
}

const MeepTicketsTable = ({ data }: IMeepTicketsTableProps) => {    
    return (
        <div id={styles.MeepTicketsTable}>
            {data.map((item) => (
                <div className={styles.table} key={item.id}>
                    <div className={styles.container} >
                        {item.name}
                    </div>
                    <div className={styles.container} >
                        {format(new Date(item.startDate), "dd/MM/yyyy' 'HH':'mm")}
                    </div>
                    <div className={styles.container} >
                        {format(new Date(item.endDate), "dd/MM/yyyy' 'HH':'mm")}
                    </div>
                    <div className={styles.container} >
                        {item.eventsLocation?.cityNameLong} - {item.eventsLocation?.stateNameLong}
                    </div>
                    <div className={styles.container} >
                        {handleStatus(item.status)}
                    </div>
                    <div
                        className={styles.last}
                        onClick={() =>
                            window.open(`https://produtor.meep.com.br/evento/${item.id}/dashboard`, "_blank")
                        }
                    >
                        <img src="/assets/icon/open-in-new-icon.svg" alt=""/>
                    </div>
                </div>
            ))}
        </div>
    )
};

export default MeepTicketsTable;
