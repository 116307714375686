import Sidesheet from "components/sidesheet/Sidesheet"
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback"
import { useCallback, useEffect, useState } from "react"
interface IPropsDeleteErp {
    open: any,
    title: string,
    text: string
    textFeedback: string
    onSubmit: () => Promise<void>
    onClose: () => void
    auxiliaryText?: string
}

const DeleteErp = ({ open, text, textFeedback, onSubmit, onClose, auxiliaryText, title }: IPropsDeleteErp) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1)
    const onSubmitHandler = useCallback(async () => {
        try {
            setIsLoading(true);
            await onSubmit();
            setStep(2)
        } finally {
            setIsLoading(false);
        }
    }, [onSubmit])

    useEffect(() => {
        if (open) {
            setStep(1)
        }
    }, [open])

    return (
        <Sidesheet
            open={open}
            onClose={onClose}
            title={
                <h2>
                    Remover <b>{title}</b>
                </h2>
            }
            content={
                <>
            <SidesheetFeedback text={text} />
            <p>{auxiliaryText}</p>
            </>
        }
            currentStep={step}
            totalSteps={2}
            notTotalHeight
            continueButton="Excluir"
            handleContinueButton={onSubmitHandler}
            cancelButton
            isLoading={isLoading}
            feedback={
                <SidesheetFeedback text={textFeedback} success />
            }
        />
    )

}

export default DeleteErp