import React, { FC, useMemo } from 'react'
import styles from './ProductList.module.scss'
import { Skeleton } from '@mui/material'

const ProductListItemSkeleton: FC = () => {

    const item = useMemo(() => <div id={styles.ProductListItemSkeleton} >
        <div className={styles.end} >
            <Skeleton variant="rectangular" width={20} height={20} />
        </div>
        <Skeleton variant="rectangular" width={80} height={80} />
        <div className={styles.content} >
            <Skeleton variant="text" width={"80%"} />
            <Skeleton variant="text" width={"60%"} style={{ marginTop: 18 }} />
            <Skeleton variant="text" width={"60%"} />
            <Skeleton variant="text" width={"30%"} />
        </div>
        <div className={styles.end} >

            <Skeleton variant="text" width={100} />
        </div>
        <div className={styles.end} >
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
        </div>
    </div>, [])
    return (
        <>
            {item}
            {item}
            {item}
            {item}
        </>
    )
}
export default ProductListItemSkeleton