import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import { IBaseList } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";
import DiscountProductForm from "../form/DiscountProductForm";
import styles from "./BaseListCard.module.scss";
import UseBaseListCard from "./UseBaseListCard";
import { IEvents } from "modules/events/domain/models/IEvents";

interface IBaseListCardProps {
  event: IEvents;
  baseList: IBaseList;
  refreshDiscountList: () => Promise<void>;
}

export const BaseListCard: FC<IBaseListCardProps> = ({
  event,
  baseList,
  refreshDiscountList,
}) => {
  const {
    closeEditModal,
    openEdit,
    openAdd,
    closeAddModal,
    openAddModal,
    hideContent,
    isCollapsed,
    showContent,
    productList,
    handleDiscountToEdit,
    discountToEdit,
    handleEditDiscount,
    handleAddDiscount,
    LightTooltip,
  } = UseBaseListCard(baseList, refreshDiscountList);

  return (
    <div id={styles.BaseListCard}>
      <div>
        <p>
          {baseList.name}

          {baseList.discounts.find((element) => !element.eventAssociation) && (
            <LightTooltip
              title="Existem descontos ainda não vinculados ao evento!"
              placement="top-start"
              arrow
            >
              <i className={styles.errorIcon}>
                <ErrorIcon fontSize="small" />
              </i>
            </LightTooltip>
          )}
        </p>

        <IconButton onClick={isCollapsed ? hideContent : showContent}>
          {isCollapsed ? (
            <ExpandLessIcon fontSize="large" />
          ) : (
            <ExpandMoreIcon fontSize="large" />
          )}
        </IconButton>
      </div>

      {isCollapsed ? (
        <div className={styles.openedContent}>
          <Button
            fullWidth={false}
            onClick={() => openAddModal(baseList.id)}
            endIcon={<AddCircleIcon />}
            color="secondary"
            variant="outlined"
          >
            Novo desconto de ingresso
          </Button>
          <div>
            <div>
              <h3>Ingresso</h3>
              <h3>Evento</h3>
              <h3>Horário limíte</h3>
              <h3>Valor</h3>
            </div>
            {baseList.discounts.map((item, key) => (
              <div key={key} className={styles.ticket}>
                <p>
                  {item.productName}
                  {!item.eventAssociation && (
                    <LightTooltip
                      title="Este ingresso não está vinculado ao evento!"
                      placement="top-start"
                      arrow
                    >
                      <i className={styles.errorIcon}>
                        <ErrorIcon fontSize="small" />
                      </i>
                    </LightTooltip>
                  )}
                </p>

                <p>{event.name}</p>

                <p>{Utils.toDateAndTime(item.validUntil)}</p>
                <p className={styles.pIconButton}>
                  <p>
                    <small>{moneyMaskNumber(item.price)}</small>
                    {moneyMaskNumber(item.priceWithDiscount)}
                  </p>
                  <IconButton
                    onClick={() => handleDiscountToEdit(item, baseList.id)}
                    size="small"
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.closedContent}>
          <p>{baseList.description}</p>
        </div>
      )}
      <RightDrawer
        title="Editar desconto de ingresso"
        open={openEdit}
        onClose={closeEditModal}
        className={styles.modal}
      >
        <DiscountProductForm
          onClose={closeEditModal}
          onSubmit={handleEditDiscount}
          productList={productList}
          defaultDiscount={discountToEdit}
          discounts={baseList.discounts}
          event={event}
        />
      </RightDrawer>
      <RightDrawer
        title="Adicionar desconto de ingresso"
        open={openAdd}
        onClose={closeAddModal}
        className={styles.modal}
      >
        <DiscountProductForm
          onClose={closeAddModal}
          onSubmit={handleAddDiscount}
          productList={productList}
          discounts={baseList.discounts}
          event={event}
        />
      </RightDrawer>
    </div>
  );
};
