import { IInvoiceFormValue, InvoiceSupplyValues } from "modules/meepErp/presentation/components/invoices/IInvoiceFormValue";
import InvoiceErpService from "modules/meepErp/services/api/Invoice/InvoiceErpService";
import { v4 } from "uuid";

const GetInvoiceUseCase = async (localId: string, id: string): Promise<IInvoiceFormValue> => {
  const service = InvoiceErpService();
  const response = await service.getInvoice(localId, id);
  const responseValue: IInvoiceFormValue = {
    createdAt: response.createdAt,
    createdByName: response.createdByName,
    partnerId: response.ownerId,
    partnerName: response.corporateName,
    partnerDocument: response.document,
    createdById: response.createdById,
    operationId: response.operationId,
    operationName: response.operationName ?? "OPERATION",
    currency: "BRL",
    number: response.number,
    series: response.series,
    accessKey: response.accessKey,
    issueDate: response.issueDate.split("T")[0],
    date: response.date.split("T")[0],
    shipping: response.shipping.amount,
    increase: response.increase.amount,
    insurance: response.insurance.amount,
    discountValue: response.discount.amount,
    others: response.others.amount,
    stHighlighted: response.stHighlighted.amount,
    supplies: response.supplies.map<InvoiceSupplyValues>(supply => ({
      id: v4(),
      storageLocationName: supply.storageLocationName,
      name: supply.supplyName,
      quantity: supply.quantity,
      totalValue: supply.total.amount,
      icms: supply.icms.amount,
      confins: supply.confins.amount,
      pis: supply.pis.amount,
      ipi: supply.ipi.amount,
      st: supply.st.amount,
      discountPercentage: supply.discountPercentage ?? 0,
      discountValue: supply.discountValue.amount ?? 0,
      storageLocationId: supply.storageLocationId,
      measurementUnitId: supply.measurementUnitId,
      supplyId: supply.supplyId,
      supplyName: supply.supplyName,
      supplyCode: Number(supply.supplyCode),
      measurementUnitSymbol: supply.measurementUnitSymbol,
      unitPrice: supply.unitPrice.amount,
    })),
    observation: response.observation,
  }
  return responseValue;
}
export default GetInvoiceUseCase
