import { FC, useEffect } from "react";
import Layout from "components/layout/presentation/Layout";
import CadastrarTutorialPage from "modules/cadastrarTutorial/presentation/pages/CadastrarTutorialPage";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

const CadastrarTutorial: FC = () => {
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    updateRouters([
      {
        title: "Admin",
      },
      {
        label: "Admin",
        title: "Adicionar tutorial",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <CadastrarTutorialPage />
    </Layout>
  );
};
export default CadastrarTutorial;
