import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const ApproveTIDsSolicitationUseCase = async (
  service: IComplienceService,
  id: string,
) => {
  const response = await service.approveTIDsSolicitation(id);
  return response;
};

export default ApproveTIDsSolicitationUseCase;
