import { Icon } from "@material-ui/core";
import styles from "./BILandingPage.module.scss";
import { useHistory } from "react-router-dom";

export const BILandingPage = () => {
  const history = useHistory();

  return (
      <div className={styles.container}>
        <div className={styles.content}>
          <header>
            <div>
              <h1>
                Experimente ser <span>estratégico</span>
              </h1>
              <p>
              Unifique todas as suas informações, alcance resultados notáveis, antecipe as tendências do mercado, 
              tome decisões mais concretas e melhore suas estratégias de negócio. <b>Comece agora!</b> 
              </p>
            </div>
            <div>
              <button onClick={() => history.push("/private/bi/purchase")}>
                Contratar BI
              </button>
              <a className={styles.anchor} target="_blank" rel="noreferrer" href="https://app.powerbi.com/view?r=eyJrIjoiZjYzYzU0ZWYtMDE4My00NzQyLWJmNTQtYjVmNGRmM2Y0MDIxIiwidCI6ImM1ZDQ4Njc5LWQxZDUtNDBlZC04NDJhLWUyMTQ0ZjNmOGM5ZiJ9">
                <span className={styles.buttonText}>Veja exemplo</span>
                <Icon className={styles.buttonIcon}>visibility</Icon>
              </a>
            </div>
          </header>

          <div className={styles.videoContainer}>
            <img src="/assets/icon/bi/bg.png" alt="" />
          </div>

          <h2>
            <b>Mais inteligência</b> para o seu negócio!
          </h2>

          <div className={styles.benefitsContainer}>
            <div>
              <div className={styles.row}>
                <div>
                  <div className={styles.header}>
                    <img src="/assets/icon/bi/management.svg" alt="" />
                    <span>Análise de Tendências</span>
                  </div>
                  <p>
                    Através de ferramentas avançadas, o BI permite prever tendências futuras e identificar padrões, 
                    auxiliando na elaboração de estratégias mais precisas.
                  </p>
                </div>
                <div>
                  <div className={styles.header}>
                    <img src="/assets/icon/bi/reports.svg" alt="" />
                    <span>Dashboard personalizada</span>
                  </div>
                  <p>
                   Use os relatórios detalhados sobre seu caixa, produtos e todas as vendas realizadas para otimizar seus processos e gerar mais receita!
                  </p>
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <div className={styles.header}>
                    <img src="/assets/icon/bi/real-time.svg" alt="" />
                    <span>Informações em tempo real</span>
                  </div>
                  <p>
                    Acompanhe os resultados e os insigths gerados pelo processamento dos dados de vendas e consumo, em tempo real.
                  </p>
                </div>
                <div>
                  <div className={styles.header}>
                    <img src="/assets/icon/bi/order-tracking.svg" alt="" />
                    <span>Centralização de dados</span>
                  </div>
                  <p>
                  Crie visualizações interativas e intuitivas dos seus dados para facilitar a compreensão das informações por meio de gráficos, tabelas e painéis dinâmicos.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img src="/assets/icon/bi/customer-feedback.svg" alt="" />
            </div>
          </div>
        </div>
        <div className={styles.fullContainer}>
          <div className={styles.content}>
            <h1>
              Tenha uma visão completa e integrada do seu negócio!
            </h1>
            <p>
              Descubra como a nossa solução de BI pode revolucionar a maneira como você realiza a gestão, proporcionando benefícios tangíveis e 
              impulsionando o sucesso do seu estabelecimento. Visualize métricas cruciais, identifique tendências e padrões em tempo real, 
              possibilitando ajustes instantâneos e potencializando o impacto de suas decisões. <a target="_blank"  rel="noreferrer" href="https://api.whatsapp.com/send?phone=5531999946706&text=Ol%C3%A1!%20Tenho%20d%C3%BAvidas%20sobre%20o%20BI%20do%20Portal%20Meep"><b>Clique aqui e fale com nossos consultores!</b></a>
            </p>

            <div className={styles.howItWorksContainer}>
              <div>
                <span>
                  1 <span>Solicite</span>
                </span>
                <p>
                  Clique no botão abaixo e peça a contratação do Power BI personalizado do seu estabelecimento.
                </p>
              </div>

              <div>
                <span>
                  2 <span>Aguarde</span>
                </span>
                <p>
                  Depois de solicitado, aguarde o prazo de 7 dias para que seu Power BI seja disponibilizado.
                </p>
              </div>

              <div>
                <span>
                  3 <span>Aproveite!</span>
                </span>
                <p>
                  Seu Power BI ficará disponível nessa tela com os dados estratégicos do seu negócio!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={styles.content}>
          <footer>
            <button onClick={() => history.push("/private/bi/purchase")}>
              Contratar BI
            </button>
          </footer>
        </div> */}
      </div>
  );
};
