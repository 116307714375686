import { Button, Checkbox, IconButton, TextField } from '@material-ui/core'
import { InfoOutlined, SearchOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IProductItem as IItem } from '../smartPrinterIForm/interfaces/ISmartPrinterForm'
import styles from './ProductSelector.module.scss'
import { FormControlLabel } from '@mui/material'
import Sidesheet from 'components/sidesheet/Sidesheet'
import { IProduct } from 'models/product/IProduct'
import UseIsVisible from 'modules/cashlessDashboard/presentation/components/isVisble/UseIsVisible'
export interface IProductSelectorProps {
    list: IItem[];
    defaultValues?: IItem[];
    instructions?: string;
    // onSubmit?: (selected: IProductItem[]) => void
    onChange?: (selected: IItem[]) => void;
    buttonLabel?: string | JSX.Element;
    title?: string;
}

interface ICheckableItem extends IItem {
    checked: boolean
}

const ProductSelector: FC<IProductSelectorProps> = ({
    list: productsList,
    defaultValues,
    onChange,
    buttonLabel,
    instructions,
    title,
}) => {
    const [listProducts, setListProducts] = useState<ICheckableItem[]>([]);
    const [listProductsFiltered, setListProductsFiltered] = useState<ICheckableItem[]>([]);
    const [filter, setFilter] = useState({ text: "", checked: false });
    const [openDialog, setOpenDialog] = useState(false);

    // const [selectedProductsLength, setSelectedProductsLength] = useState(0);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    // const selectedContent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const initialValues = productsList.map(productItem => ({ ...productItem, checked: defaultValues?.map(defaultItem => defaultItem.id).includes(productItem.id) ? true : false }))
        setListProducts(initialValues)
        setListProductsFiltered(initialValues)
    }, [defaultValues, productsList]);


    // useEffect(() => {
    //     setListProducts(prev => prev.map((item) =>  ? { ...item, checked: true } : { ...item, checked: false }))
    //     setListProductsFiltered(prev => prev.map((item) => defaultValues?.map(item => item.id).includes(item.id) ? { ...item, checked: true } : { ...item, checked: false }));

    // }, [defaultValues])


    const onClickItemHandle = useCallback((product: IItem) => {
        const newListProducts = (listProducts.map(item => (item.id === product.id ? { ...item, checked: !item.checked } : { ...item })));
        setListProducts(newListProducts);
        // setSelectedProductsLength(newListProducts.filter(item => item.checked).length)

        const newListProductsFiltered = (listProducts.map(item => (item.id === product.id ? { ...item, checked: !item.checked } : { ...item })));
        setListProductsFiltered(newListProductsFiltered);

        onChange?.(newListProducts.filter(item => item.checked));
    }, [listProducts, onChange]);




    const isVisible = useCallback((productItem: ICheckableItem, filter: { text: string, checked: boolean }) => {
        if (filter.checked) {
            if (!productItem.checked) {
                return false
            }
        }
        if (filter.text) {
            const _filter = filter?.text.toLowerCase().trim();
            const _name = productItem.name?.toLowerCase().trim();
            const _category = productItem.category?.toLowerCase().trim();

            if (_name?.includes(_filter) || _category?.includes(_filter)) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return true
        }
    }, []);

    useEffect(() => {
        if (!openDialog) {
            setFilter({ text: "", checked: false })
            setPage(1);
        }
        return () => {

        }
    }, [openDialog])


    const selectedProductsLength = useMemo(() => listProducts.filter(item => item.checked).length, [listProducts]);




    const listView = useMemo(() => (
        listProducts.map(productItem => isVisible(productItem, filter) &&
            <ProductItem productItem={productItem} onClickItemHandle={onClickItemHandle} />
        )
    ), [filter, isVisible, listProducts, onClickItemHandle])


    const listViewFilteredAndPaginate = useMemo(() => (
        <>
            {listProductsFiltered.map((productItem, index) =>
                (index >= (page - 1) * pageSize && index < (page) * pageSize) &&
                <ProductItem productItem={productItem} onClickItemHandle={onClickItemHandle} />
            )}
        </>
    ), [listProductsFiltered, onClickItemHandle, page, pageSize]);


    useEffect(() => {
        setListProductsFiltered(listProducts.filter(item => isVisible(item, filter)));
    }, [filter, isVisible, listProducts]);

    const onClickSelectAll = useCallback((_, checked: boolean) => {
        const filteredListProducts: ICheckableItem[] = listProductsFiltered.map(item => ({ ...item, checked: checked }));

        if (!checked) {
            setFilter(prev => ({ ...prev, checked: false }));
        }

        const newListProducts: ICheckableItem[] = [...listProducts, ...filteredListProducts].reduce((acc: ICheckableItem[], current) => {
            const findSameItem = acc.find(item => item.id === current.id);

            if (findSameItem && findSameItem.checked !== current.checked) {
                acc = acc.filter(item => item.id !== findSameItem.id);                
            }

            acc.push(current);
            return acc;
        }, []);

        setListProducts(newListProducts);
        onChange?.(newListProducts.filter(item => item.checked));
    }, [listProducts, listProductsFiltered, onChange]);

    const allSelected = useMemo(
        () => 
            listProductsFiltered.length === listProductsFiltered.filter(item => item.checked).length,
        [listProductsFiltered]
    );

    return (
        <div id={styles.ProductSelector} >
            <div className={styles.buttonContainer} >
                <Button
                    color={"secondary"}
                    fullWidth
                    variant={"outlined"}
                    onClick={() => setOpenDialog(true)}
                >
                    {buttonLabel ??
                        <>
                            {
                                !!selectedProductsLength ? (selectedProductsLength.toString() + " Produtos Selecionados") :
                                    <div>
                                        No <b>cardápio</b>, selecione essa impressão nos produtos que desejar
                                    </div>
                            }
                        </>
                    }
                </Button>
            </div>

            <Sidesheet
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                title={<h2>{title ?? "Selecione os Produtos"}</h2>}
                content={
                    <div id={styles.ProductSelectorDialog} >
                        <div className={styles.filter}>
                            <div>
                                <label>Buscar</label>
                                {/* <TextField
                                    fullWidth
                                    value={filter.text}
                                    variant='outlined'
                                    size='small'
                                    onChange={(ev) => { setFilter(prev => ({ ...prev, text: ev.target.value })); setPage(1); }}
                                /> */}
                                <TextField
                                    fullWidth
                                    value={filter.text}
                                    variant='outlined'
                                    size='small'
                                    onChange={(ev) => { setFilter(prev => ({ ...prev, text: ev.target.value })); setPage(1); }}
                                />
                                <IconButton
                                    title="Buscar"
                                    size='small'
                                    className={styles.button}
                                    onClick={(ev) => { setFilter(prev => ({ ...prev, text: filter.text })); setPage(1); }}
                                >
                                    <SearchOutlined />
                                </IconButton>
                            </div>
                        </div>
                        {instructions && <div className={styles.instruction}><InfoOutlined />{instructions}</div>}
                        <div className={styles.actions}>
                            <FormControlLabel label={"Selecionar todos"} control={<Checkbox />} checked={allSelected} onChange={onClickSelectAll} />
                            {!!selectedProductsLength &&
                                <div className={styles.checked}>
                                    <IconButton onClick={(ev) => { setFilter(prev => ({ ...prev, checked: !prev.checked })) }} size="small">
                                        {filter.checked ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    {!filter.checked ? `Exibir apenas selecionados` : `Exibir todos`}
                                </div>
                            }
                        </div>
                        <div className={styles.list} >
                            {listView}
                            {/* {listViewFilteredAndPaginate} */}
                        </div>
                        {/* <Pagination
                            count={Math.ceil(listProductsFiltered.length / pageSize)}
                            page={page}
                            onChange={(ev, page) => setPage(page)}
                        /> */}
                        {/* {page} -- {(page - 1) * 5} - {(page) * 5} /{listProductsFiltered.length} -- {} */}
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Concluir"
                handleContinueButton={() => setOpenDialog(false)}
            />
        </div >
    )
}
export default ProductSelector



const compare = (a: string | number, b: string | number) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

const ProductItem = ({ productItem, onClickItemHandle }: { productItem: ICheckableItem, onClickItemHandle: (value: ICheckableItem) => void }) => {

    const divRef = useRef<HTMLDivElement>(null);

    const isVisible = UseIsVisible(divRef)
    return <div ref={divRef} onClick={() => onClickItemHandle(productItem)} className={styles.item}>
        {isVisible && <>
            <div>
                {productItem.category && <h3>{productItem.category}</h3>}
                <div>
                    <Checkbox checked={productItem.checked} /> {productItem.name}
                </div>
            </div>
            {productItem.description && <div className={styles.description}>{productItem.description}</div>}
        </>}
    </div>
}