import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './PartnerList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { PartnerType } from 'modules/meepErp/models/partner/PartnerType';
import { IPartnerFilterValue } from './partnerFilter/IPartnerFilter';
import PartnerFilter from './partnerFilter/PartnerFilter';
import { PartnerItemResponse, PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';

export interface IPartnerListProps {
    isLoading: boolean
    filter: IPartnerFilterValue
    partners?: PartnerListResponse 
    onChangeFilter: (filter: IPartnerFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string, originType: PartnerOriginType) => Promise<void>
}
const PartnerList: FC<IPartnerListProps> = ({
    isLoading,
    filter,
    partners: partner,
    onChangeFilter,
    onDelete,
    onEdit,
}) => {
    const { hasAccessRole } = useAuth()
    const submitFilter = useCallback((values: IPartnerFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])


    const columnsConfig: ColumnConfigs<PartnerItemResponse> = {
        code: { title: "Código", sortable: true },
        document: { title: "CNPJ/CPF", sortable: true },
        name: { title: "Nome", sortable: true },
        type: {
            title: "Tipo",
            renderRow: (value: number) => value === PartnerType.Customer ? "Cliente" : "Fornecedor",
            sortable: true
        },

    }
    const actionButtons = [
        {
            icon: "edit",
            onClick: (item: PartnerItemResponse) => onEdit(item.id, item.origin)
        },
        {
            icon: "delete",
            onClick: (item: PartnerItemResponse) => onDelete(item.id)
        }
    ]
    const pagination = useMemo(() => {
        return {
            page: partner?.page ?? 1,
            pageSize: 10,
            count: partner?.totalPages ?? 0
        }
    }, [partner?.totalPages, partner?.page])

    return (
        <div id={styles.InventoryPartnerList} >
            <PartnerFilter
                onSubmit={submitFilter}
                defaultValue={filter}
            />
            <div></div>
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={partner?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default PartnerList