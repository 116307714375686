import { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@material-ui/core';

import styles from './IfoodCalculator.module.scss';
import Button from 'components/ui/Button/Button';
import RenderIf from 'components/renderIf/RenderIf';
import { InputLabel } from 'modules/catalog/presentation/componentes/ui/InputLabel';
import { InputMoney } from 'modules/catalog/presentation/componentes/ui/InputMoney';

interface IfoodCalculatorForm {
    value: number;
    deliveryType: string;
    deliveryFeeValue: number;
    embedDeliveryFee: string;
    embedDiscountCoupon: string;
    couponValue: number;
    transferMode: string;
}

const IfoodCalculator = () => {
    const [formValues, setFormValues] = useState<IfoodCalculatorForm>({ value: 0, deliveryType: 'OWN', deliveryFeeValue: 0, embedDeliveryFee: 'EMBED', embedDiscountCoupon: '', couponValue: 0, transferMode: 'WEEKLY' });
    const [result, setResult] = useState(0);
    const [formInvalid, setFormInvalid] = useState(true);

    useEffect(() => {
        isFormInvalid();
    }, [formValues]);

    const changeFormValue = (ev: any) => {
        setResult(0);
        setFormValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    };

    const changeDeliveryType = (type: string) => {
        setResult(0);
        setFormValues((prev) => ({ ...prev, deliveryType: type }));
    }

    const isFormInvalid = () => {
        if (formValues.value === 0) {
            setFormInvalid(true);
            return;
        }

        if (!formValues.embedDiscountCoupon) {
            setFormInvalid(true);
            return;
        }

        if (formValues.embedDiscountCoupon === 'WITH_COUPON' && formValues.couponValue === 0) {
            setFormInvalid(true);
            return;
        }

        setFormInvalid(false);
    }

    const calculateProductValue = () => {
        const deliveryFee = formValues.deliveryType === 'OWN' ? 0.13 : 0.27;
        const deliveryFeeValue = formValues.embedDeliveryFee === 'EMBED' ? parseFloat(formValues.deliveryFeeValue.toString()) : 0;
        const couponValue = formValues.embedDiscountCoupon === 'WITH_COUPON' ? parseFloat(formValues.couponValue.toString()) : 0;
        const transferFee = formValues.transferMode === 'WEEKLY' ? 0.0159 : 0;

        // STEP 1
        const product = (formValues.value * 100) / (1 - deliveryFee) / 100;

        // STEP 2
        const initCalc = product + deliveryFeeValue + couponValue;

        // STEP 3
        const midCalc = (initCalc * 100) / (1 - 0.0350) / 100;

        // STEP 4
        const finalCalc = (midCalc * 100) / (1 - transferFee) / 100;

        setResult(finalCalc);
    };

    const resultHandler = useMemo(() => {
        return result.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
    }, [result]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
                <div className={styles.toolCalculatorBox}>
                    <div className={styles.toolCalculatorIconBox}>
                        <div className={styles.toolCalculatorIcon}>🍔</div>
                        <div className={styles.toolCalculatorIcon}>🍕</div>
                        <div className={styles.toolCalculatorIcon}>🍟</div>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputMoney
                                onChange={changeFormValue}
                                name="value"
                                value={formValues?.value}
                                label="Valor do seu produto"
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputMoney
                                onChange={changeFormValue}
                                name="deliveryFeeValue"
                                value={formValues?.deliveryFeeValue}
                                label="Valor da taxa de entrega"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <label style={{ fontSize: '14px' }}>Tipo de entrega</label>
                            <div className={styles.toolCalculatorDeliveryGrid}>
                                <div className={styles.toolCalculatorDeliveryOption} data-selected={formValues?.deliveryType === 'OWN'} onClick={() => changeDeliveryType('OWN')}>
                                    <div className={styles.toolCalculatorDeliveryOptionIcon}>
                                        <img src="/assets/icon/icon-delivery.svg" alt="delivery icon" />
                                    </div>
                                    <span>Por conta própria</span>
                                </div>

                                <div className={styles.toolCalculatorDeliveryOption} data-selected={formValues?.deliveryType === 'THIRD'} onClick={() => changeDeliveryType('THIRD')}>
                                    <div className={styles.toolCalculatorDeliveryOptionIcon}>
                                        <img src="/assets/icon/icon-ifood.svg" alt="ifood icon" />
                                    </div>
                                    <span>Pelo iFood</span>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <label style={{ fontSize: '14px' }}>Embutir taxa de entrega?</label>
                            <RadioGroup
                                row
                                name={"embedDeliveryFee"}
                                value={formValues?.embedDeliveryFee}
                                onChange={changeFormValue}
                            >
                                <FormControlLabel value={'EMBED'} control={<Radio />} label={<span>Embutir</span>} />
                                <FormControlLabel value={'NOT_EMBED'} control={<Radio />} label={<span>Não embutir</span>} />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel
                                select
                                name={"embedDiscountCoupon"}
                                value={formValues?.embedDiscountCoupon}
                                label="Calcular com cupom de desconto?"
                                required
                                onChange={changeFormValue}
                            >
                                <MenuItem value={'WITH_COUPON'}>Com cupom de desconto</MenuItem>
                                <MenuItem value={'WITHOUT_COUPON'}>Sem cupom de desconto</MenuItem>
                            </InputLabel>
                        </Grid>

                        <RenderIf condition={formValues?.embedDiscountCoupon === 'WITH_COUPON'}>
                            <Grid item xs={12}>
                                <InputMoney
                                    onChange={changeFormValue}
                                    name="couponValue"
                                    value={formValues?.couponValue}
                                    label="Valor do cupom"
                                    required
                                />
                            </Grid>
                        </RenderIf>

                        <Grid item xs={12}>
                            <label style={{ fontSize: '14px' }}>Repasse</label>
                            <RadioGroup
                                row
                                name={"transferMode"}
                                value={formValues?.transferMode}
                                onChange={changeFormValue}
                            >
                                <FormControlLabel value={'WEEKLY'} control={<Radio />} label={<span>Semanal</span>} />
                                <FormControlLabel value={'WITHOUT_TRANSFER'} control={<Radio />} label={<span>Sem repasse</span>} />
                            </RadioGroup>
                        </Grid>

                        <Button
                            disabled={formInvalid}
                            style={{ marginTop: '1.5rem' }}
                            onClick={() => calculateProductValue()}>Calcular</Button>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={4}>
                <div className={styles.toolCalculatorResultBox}>
                    <p className={styles.toolCalculatorResultText}>Total do valor do seu produto</p>
                    <p className={styles.toolCalculatorResultValue}>{resultHandler}</p>
                </div>
            </Grid>
        </Grid>
    )
}

export default IfoodCalculator;