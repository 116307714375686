import styles from "./NovidadesItem.module.scss";
import {
  EditOutlined,
  DeleteOutlineOutlined,
  InsertLink,
} from "@mui/icons-material";

interface INovidadesItem {
  data: string;
  titulo: string;
  categoria: string;
  actionEdit: () => void
  actionDelete: () => void
}

const NovidadesItem = ({ data, titulo, categoria, actionEdit, actionDelete }: INovidadesItem) => {
  return (
    <>
      <div id={styles.NovidadesItem}>
        <span>{data}</span>
        <span>{titulo}</span>
        <span>{categoria}</span>
        <div className={styles.icons}>
          <InsertLink />
          <EditOutlined onClick={actionEdit}/>
          <DeleteOutlineOutlined onClick={actionDelete}/>
        </div>
      </div>
    </>
  );
};
export default NovidadesItem;
