import { ICrmService } from "modules/crm/models/interface/ICrmService";
import { ICrmFilterValue } from "modules/crm/presentation/components/crmFilterForm/interfaces/ICrmFilterValue";
import parseFilterToRequestUseCase from "./parseFilterToRequestUseCase";

const GetClientListUseCase = async (
  service: ICrmService,
  localId: string,
  pagination?: { page?: number, pageSize?: number },
  filter?: ICrmFilterValue,
) => {

  const request = parseFilterToRequestUseCase(localId, filter, pagination);

  let response = await service?.getClients(request);

  response = {
    ...response,
    data: response.data.map(x => ({ ...x, email: x.email === 'null' ? '' : x.email}))
  }

  return response;
};
export default GetClientListUseCase;



