import { IPostValidationTIDsRequest } from "modules/aprovacaoTIDs/domain/dto/IPostValidationTIDsRequest";
import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const ValidateTIDsUseCase = async (
  service: IComplienceService,
  payload: IPostValidationTIDsRequest,
) => {
  const response = await service.validateAdditionalTids(payload);
  return response;
};

export default ValidateTIDsUseCase;
