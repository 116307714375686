import { useCallback, useEffect, useMemo, useState } from "react";
import GetEventsUseCase from "modules/events/application/useCases/events/GetEventsUseCase";
import { EventStatusEnum, IEvents } from "modules/events/domain/models/IEvents";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { EventsApi } from "services/api/events/events/EventsApi";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { EventPriodResumeValue, IFilterDateEventsList } from "../components/FilterEvents/FilterEventList";
import GetEventsPeriodResumeUseCase from "modules/events/application/useCases/events/GetEventsPeriodResumeUseCase";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import qs from "qs";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { IEventCardResume } from "../components/myEvents/components/eventCard/EventCard";
import GetEventsResumeUseCase from "modules/events/application/useCases/events/GetEventsResumeUseCase";
import { VerifyOrganizerUseCase } from "modules/config/integrationsConfig/application/useCases/VerifyOrganizerUseCase";
import IntegrationApi from "services/api/config/integration/IntegrationApi";

const service = IntegrationApi();
const eventsService = EventsApi();
const PAGESIZE = 10;
export interface IEventListFilter {
  status?: EventStatusEnum,
  page?: number,
  pageSize?: number,
  keyword?: string,
  month?: number,
  year?: number,
}
interface IUseEventsPage {
  getMore: (pagination: { page: number, pageSize?: number }) => void;
  eventsData: IEvents[];
  eventsLoading: boolean;
  totalRecords: number;
  onChangeFilter: (values: IFilterDateEventsList) => void
  filter?: IEventListFilter
  resume?: EventPriodResumeValue
  goToAddEvents: () => void
  eventsResumeLoading: boolean
  eventResumeCard?: IEventCardResume[]
  getEventCardResumeItem: (id: string) => IEventCardResume | undefined
  integrated: boolean,
 }

export const UseEventsPage = (): IUseEventsPage => {
  const { currentLocal } = useLocal();
  const [eventsData, setEventsList] = useState<IEvents[]>([]);
  const [integrated, setIntegrated] = useState(false);
  const [filter, setFilter] = useState<IEventListFilter>({
    status: EventStatusEnum.ALL,
    page: 0,
    pageSize: PAGESIZE,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [eventsLoading, setEventsLoading] = useState(false);
  const [eventsResumeLoading, setEventsResumeLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [resume, setResume] = useState<EventPriodResumeValue>()
  const { updateRouters, updateLastRouter } = useBreadcumbs();

  const { push  } = useHistory();
  const { url } = useRouteMatch()

  const [eventResumeCard, setEventResumeCard] = useState<IEventCardResume[]>([])

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const onChangeFilter = useCallback((values: IFilterDateEventsList) => {
    window.history.replaceState(null, "", `?${qs.stringify({ ...values, page: 0 })}`)
    setFilter(prev => ({ ...prev, ...values, page: 0 }));
  }, [])

  useEffect(() => {
    const _status = params.get("status") as unknown
    const _page = params.get("page") as unknown
    const _pageSize = params.get("pageSize") as unknown
    const _search = params.get("search")
    const _month = params.get("month") as unknown
    const _year = params.get("year") as unknown
    if (_status ||
      _page ||
      _pageSize ||
      _search ||
      _month ||
      _year) {
      setFilter(prev => ({
        status: _status ? _status as EventStatusEnum : prev?.status,
        page: _page ? Number(_page) : prev?.page,
        pageSize: _pageSize ? Number(_pageSize) : prev?.pageSize,
        keyword: _search ? _search as string : prev?.keyword,
        month: _month ? Number(_month) : prev?.month,
        year: _year ? Number(_year) : prev?.year,
      }))
    }
  }, [params]);

  const getEventCardResumeItem = useCallback((id: string) => {
    return Array.isArray(eventResumeCard) ? eventResumeCard.find(item => item.eventId === id) : undefined;
  }, [eventResumeCard]);

  useEffect(() => {
    updateLastRouter({ url: `${url}?${qs.stringify(filter)}` })
  }, [filter, updateLastRouter, url])

  useEffect(() => {
    updateRouters([{ id: BreadcrumbsEnum.EVENTOS, title: 'Eventos', url: '/private/eventos/list/eventos' }])
  }, [updateRouters, filter])

  const getEvents = useCallback(async (locaId: string, filter: IEventListFilter) => {
    try {
      setEventsLoading(true);
      const keyword = filter?.keyword ?? "";
      const response = await GetEventsUseCase(eventsService, locaId, filter?.status ?? EventStatusEnum.ALL, filter?.page ?? 0, filter?.month ?? new Date().getMonth() + 1, filter?.year ?? new Date().getFullYear(), keyword);

      setEventsList(response.records);
      setTotalRecords(response.totalRecords);

    } finally {
      setEventsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        const response = await VerifyOrganizerUseCase(service, currentLocal?.id);
        setIntegrated(response.integrated)
      }
    })()
  }, [currentLocal])

  const getEventCardResume = useCallback(async (_eventsData: IEvents[]) => {
    try {
      const eventService = EventsApi();
      if (currentLocal) {
        const response = await GetEventsResumeUseCase(eventService, currentLocal.id, _eventsData.map(item => item.id));
        setEventResumeCard(response);
      }
    } catch (error) {
      console.error("Failed to fetch event card resume:", error);
    }
  }, [currentLocal]);


  const getPeriodEventResume = useCallback(async () => {
    if (currentLocal?.id) {
      try {
        filter?.page === 0 && setEventsResumeLoading(true);
        const response = await GetEventsPeriodResumeUseCase(eventsService, currentLocal?.id, filter?.month, filter?.year);
        setResume(response)
      } finally {
        setEventsResumeLoading(false);
      }
    }
  }, [currentLocal?.id, filter]);

  const getMore = useCallback((pagination: { page: number, pageSize?: number }) => {
    window.history.replaceState(null, "", `?${qs.stringify({ ...filter, ...pagination })}`)
    setFilter(prev => ({ ...prev, ...pagination }))
  }, [filter]);

  useEffect(() => {
    if (currentLocal && filter) {
      getEvents(currentLocal?.id, filter);
    }
  }, [currentLocal, filter, getEvents]);

  useEffect(() => {
    if (eventsData?.length) {
      getEventCardResume(eventsData)
    }
  }, [eventsData, getEventCardResume])

  useEffect(() => {
    getPeriodEventResume();
  }, [getPeriodEventResume]);

  const goToAddEvents = useCallback(() => {
    push('/private/eventos/adicionar');
  }, [push]);

  return {
    getMore,
    getEventCardResumeItem,
    eventResumeCard,
    eventsData,
    eventsLoading,
    totalRecords,
    onChangeFilter,
    filter,
    resume,
    goToAddEvents,
    eventsResumeLoading,
    integrated,
  };
};
