import { Drawer, Icon, IconButton } from "@material-ui/core";
import Layout from "components/layout/presentation/Layout";
import { Skeleton } from "modules/salesDashboard/presentation/components/skeleton/Skeleton";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SaveSurveysForm from "../../components/SaveSurveysForm";
import styles from "./Track.module.scss";
import { UseTrack } from "./UseTrack";
import { getName } from "../../interfaces/EnumSurveyEvent";
import { BlurPremium } from "modules/plans/presentation/components/blurPremium/BlurPremium";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

export const TrackPage = () => {
  const [hintOpened, setHintOpened] = useState(true);
  const {
    loading,
    surveyList: trackList,
    addTrackSurvey,
    getTrackSurveyList,
    getSurveyById,
    updateSurveyWidget,
    enabled,
  } = UseTrack();

  const [openModalAdd, setOpenModalAdd] = useState(false); //TODO colocar na url

  const { action, id } = useParams<{ action?: string; id?: string }>();
  const { push } = useHistory();

  const addSurvey = () => {
    // push(`/private/track/add`);
    push(`/private/automation`);
  };
  const editSurvey = (id: string) => {
    push(`/private/track/edit/` + id);
  };

  const getDefault = useCallback(
    async (id: string) => {
      return await getSurveyById(id);
    },
    [getSurveyById]
  );

  const onClose = useCallback(() => {
    setOpenModalAdd(false);
    push(`/private/track`);
  }, [push]);

  useEffect(() => {
    if (action === "add" || action === "edit") {
      setOpenModalAdd(true);
    }
  }, [action]);

  return (
    <Layout>
      <div className={styles.container}>
        {/* <div className={styles.header}>
                    <IconButton onClick={() => goBack()}><Icon>chevron_left</Icon></IconButton>
                    <span>Voltar</span>
                </div> */}

        {/* {hintOpened && (
          <div className={styles.hint}>
            <img src="/assets/icon/book.svg" alt="" />
            <span>
              Bem-vindo a tela de clientes, caso tenha dúvidas acesse o tutorial
              da página <a href="/private/track">clicando aqui!</a>
            </span>
            <button onClick={() => setHintOpened(false)}>
              <Icon className={styles.close}>close</Icon>
            </button>
          </div>
        )} */}

        <h1>
          Pesquisa de <strong>satisfação</strong>
        </h1>
        <div className={styles.subtitle}>
          <span>Track.Co</span>
          {enabled && (
            <div className={styles.statusContainer}>
              <div className={styles.statusBullet} />{" "}
              <span className={styles.status}>Conectado</span>
            </div>
          )}
        </div>
        <p>
          Conheça melhor a experiência de seus clientes. Automatize pesquisas
          nos pontos de contato do seu cliente junto à Meep: envie suas
          pesquisas de forma automatizada e colete insights valiosos!
        </p>

        <div className={styles.buttonsContainer}>
          <button className={styles.addButton} onClick={addSurvey}>
            <span>Automatizar pesquisas</span>
          </button>
          <a
            href="https://app.track.co/surveys/create/"
            target="_blank"
            rel="noreferrer"
            className={styles.trackButton}
          >
            Criar pesquisa
            <img src="/assets/icon/track-icon.svg" alt="" />
          </a>
        </div>

        <BlurPremium feature={FeatureTypeEnum.SURVEY}>
          <>
            <div className={styles.listContainer}>
              {loading ? (
                <Skeleton />
              ) : (
                trackList?.data.map((item, index) => (
                  <div key={index} className={styles.listItem}>
                    <span>
                      {getName(item.sendEventType)} - {item.trackSurveyName}
                    </span>
                    {/* <IconButton
                      onClick={() => {
                        editSurvey(item.id);
                      }}
                    > */}
                    <a href="https://app.track.co/surveys" target="_blank" rel="noreferrer">
                      <Icon>chevron_right</Icon>
                    </a>
                    {/* </IconButton> */}
                  </div>
                ))
              )}
            </div>

            {!loading && !trackList?.totalRegisters && (
              <div className={styles.noData}>
                <img src="/assets/img/survey-no-data.svg" alt="" />
                <p>
                  Para uma experiência completa,
                  <br />
                  crie <strong>uma pesquisa de satisfação</strong>
                </p>
              </div>
            )}
          </>
        </BlurPremium>
        <Drawer open={openModalAdd} onClose={onClose} anchor={"right"}>
          <SaveSurveysForm
            isOpen={openModalAdd}
            surveyList={trackList?.data}
            onSubmit={addTrackSurvey}
            onUpdate={updateSurveyWidget}
            getTrackSurveys={getTrackSurveyList}
            onClose={onClose}
            getDefaultValue={getDefault}
            defaultId={id}
          />
        </Drawer>
      </div>
    </Layout>
  );
};

