import { IRecords } from 'modules/houseFlow/presentation/interfaces/IHouseFlowProductList';
import React from 'react'
import { moneyMaskNumber } from 'services/utils/Money';
import styles from './ConsumptionProductList.module.scss'



export interface IConsumptionProductListProps {
    item: IRecords;
}

export const ConsumptionProductList: React.FC<IConsumptionProductListProps> = ({ item }) => {
    return (
        <div id={styles.ConsumptionProductList}>
            <p title={item.productName} className={styles.productName}>{item.quantity}x {item.productName}</p>
            <p className={styles.priceNet}>{(item.priceGloss > item.priceNet) && <s className={styles.discount}>{moneyMaskNumber(item.priceGloss)}</s>}{moneyMaskNumber(item.priceNet)}</p>
            <p className={styles.totalNet}>{moneyMaskNumber(item.totalNet)}</p>
        </div>
    )
}
