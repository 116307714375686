import { create } from "zustand";
import { IOnBoardingItem, IOnBoardingItemRequest } from "../domain/models/IOnBoardingItem";
import { GetOnboardingUseCase } from "../applicaiton/OnboardingUseCases";
import { OnBoardingSteps } from "../domain/enums/OnBoardingSteps";
import { IOnBoardService } from "../domain/Interfaces/IOnBoardService";
import { on } from "events";




export interface IOnboardingStore {
    onBoarding: IOnBoardingItem[] | undefined;
    isLoading: boolean;
    onFinishStep: (onBoardingService: IOnBoardService, onBoarding: IOnBoardingItemRequest) => Promise<void>;
    getOnBoarding: (onBoardingService: IOnBoardService, localId: string) => Promise<IOnBoardingItem[]>;
    resetSteps: (onBoardingService: IOnBoardService, localId: string) => Promise<void>;
    hasOnboardingStep: (onBoardingService: IOnBoardService, localId: string, step: OnBoardingSteps) => Promise<boolean>;
    closedSteps: OnBoardingSteps[];
    onCloseStep: ( onBoarding: IOnBoardingItemRequest) => Promise<void>;
}


const UseOnboardingStore = create<IOnboardingStore>((set, get) => ({
    onBoarding: undefined,
    isLoading: false,
    closedSteps: [],
    onFinishStep: async (onBoardingService: IOnBoardService, onBoarding: IOnBoardingItemRequest) => {
        try {
            set({ isLoading: true });
            onBoardingStepStorage().addStepOnStorage(onBoarding.localId, onBoarding.step);
            await onBoardingService.setOnBoarding(onBoarding);
            const response = await GetOnboardingUseCase(onBoardingService, onBoarding.localId);

            set({ onBoarding: response });
        } finally {
            set({ isLoading: false });
        }
    },
    onCloseStep: async ( onBoarding: IOnBoardingItemRequest) => {
        set((store) => ({ isLoading: true, closedSteps: [...store.closedSteps, onBoarding.step] }));
    },
    getOnBoarding: async (onBoardingService: IOnBoardService, localId: string) => {
        try {
            set({ isLoading: true });
            const response = await GetOnboardingUseCase(onBoardingService, localId);
            set({ onBoarding: response });
            return response;
        } finally {
            set({ isLoading: false });
        }
    },
    resetSteps: async (onBoardingService: IOnBoardService, localId: string) => {
        try {
            set({ isLoading: true });
            onBoardingStepStorage().removeAllStepsOnStorage(localId);
            await onBoardingService.resetOnBoarding(localId);
            const response = await GetOnboardingUseCase(onBoardingService, localId);
            set({ onBoarding: response, closedSteps: [] });
        } finally {
            set({ isLoading: false });
        }
    },
    hasOnboardingStep: async (onBoardingService: IOnBoardService, localId: string, step: OnBoardingSteps) => {
        set({ isLoading: true });
        try {
            const localSteps = onBoardingStepStorage().getStepsOnStorage(localId);
            const closedSteps = get().closedSteps;
            if (closedSteps.includes(step)) {
                return true;
            }
            if (localSteps.includes(step)) {
                return true;
            }

            if (get().onBoarding === undefined) {
                await get().getOnBoarding(onBoardingService, localId);
            }
            const response = get().onBoarding;
            return response?.some(item => item.step === step) ?? false;
        } finally {
            set({ isLoading: false });
        }
    }
}));

export default UseOnboardingStore;






const onBoardingStepStorage = () => {

    const addStepOnStorage = (localId: string, step: OnBoardingSteps) => {
        const steps = localStorage.getItem(`onBoardingSteps_${localId}`);
        if (steps === null) {
            localStorage.setItem(`onBoardingSteps_${localId}`, JSON.stringify([step]));
            return;
        }
        const parsedSteps = JSON.parse(steps);
        parsedSteps.push(step);
        localStorage.setItem(`onBoardingSteps_${localId}`, JSON.stringify(parsedSteps));
    }

    const removeStepOnStorage = (localId: string, step: OnBoardingSteps) => {
        const steps = localStorage.getItem(`onBoardingSteps_${localId}`);
        if (steps === null) return;
        const parsedSteps = JSON.parse(steps);
        const index = parsedSteps.indexOf(step);
        parsedSteps.splice(index, 1);
        localStorage.setItem(`onBoardingSteps_${localId}`, JSON.stringify(parsedSteps));
    }

    const removeAllStepsOnStorage = (localId: string) => {
        localStorage.removeItem(`onBoardingSteps_${localId}`);
    }


    const getStepsOnStorage = (localId: string) => {
        const steps = localStorage.getItem(`onBoardingSteps_${localId}`);
        if (steps === null) return [];
        return JSON.parse(steps);
    }

    return ({
        addStepOnStorage,
        removeStepOnStorage,
        removeAllStepsOnStorage,
        getStepsOnStorage
    })
}