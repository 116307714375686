import { useCallback, useEffect, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useQuery } from "react-query";
import { ISimplePos } from "modules/salesPos/domain/dto/IGetSimplePosResponse";
import AddSimplePosApi from "services/api/salesPos/AddSimplePosApi";
import GetAllSimplePosWithDeviceUseCase from "modules/salesPos/application/GetAllSimplePosWithDeviceUseCase";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import GetTerminalDevicesUseCase from "modules/Terminal/application/useCases/GetTerminalDevicesUseCase";
import TerminalService from "services/api/terminal/TerminalService";

const salesSimplePosService = AddSimplePosApi();
const terminalService = TerminalService();

export const UseConfigCielo = (disableFetch?: boolean) => {
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();

    const [terminalsList, setTerminalsList] = useState<IDeviceTerminalItemResponse[]>();

    const getSimplePos = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    const response = await GetAllSimplePosWithDeviceUseCase(salesSimplePosService, currentLocal.id);
                    return response.items;
                } finally {
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const { data, isLoading, refetch, isFetching } = useQuery<ISimplePos[] | undefined>(
        `@SimplePos`, () => {
            return getSimplePos();
        },
        { enabled: !!currentLocal, retry: false }
    )

    useEffect(() => {
        if (isLoading && !isFetching && !disableFetch) {
            showLoading();
        } else {
            hideLoading();
        }
    }, [isLoading, showLoading, hideLoading, isFetching, disableFetch])

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    const response = await GetTerminalDevicesUseCase(terminalService, currentLocal.id);
                    setTerminalsList(response);
                } catch (error) {
                    console.error(error);
                }
            }
            return undefined;
        })();
    }, [currentLocal]);

    return {
        data,
        isLoading,
        refetch,
        terminalsList,
    }
}