import React, { useMemo, useState } from "react";
import styles from "./FinancialInvoicesItemMobile.module.scss";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  VerticalAlignBottom,
} from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";
import { IFinancialInvoicesList } from "modules/faturas/presentation/interfaces/IFinancialInvoicesList";

interface IFinancialInvoicesItemMobileProps {
  userItemData: IFinancialInvoicesList;
  financialInvoicesList: IFinancialInvoicesList[];
  hasBoleto: boolean | undefined;
  hasNotaFiscal: boolean | undefined;
}

export const FinancialInvoicesItemMobile: React.FC<
  IFinancialInvoicesItemMobileProps
> = ({ userItemData, hasBoleto, hasNotaFiscal }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const List = useMemo(
    () => (
      <div className={styles.containerItem}>
        <div className={styles.mid}>
          <div>
            <span>Vencimento:</span>
            <span>{userItemData?.dataVencimento}</span>
          </div>
          <div>
            <span>Descrição:</span>
            <span>{userItemData.descricao}</span>
          </div>
          <div>
            <span>Valor:</span>
            <span>{moneyMaskNumber(userItemData?.valor)}</span>
          </div>
          <div>
            <span>Status:</span>
            <span>{userItemData.status}</span>
          </div>
          <div
            className={
              hasBoleto || hasNotaFiscal ? styles.columnLarge : styles.icon
            }
          >
            {!userItemData.quitada &&
              !userItemData.debitoEmTransacoes &&
              userItemData.linkBoleto && (
                <div className={styles.iconAction}>
                  <a href={`${userItemData.linkBoleto}`}>
                    <VerticalAlignBottom /> <u>Boleto</u>
                  </a>
                </div>
              )}
            {userItemData.notaFiscal && (
              <div className={styles.iconAction}>
                <a href={`${userItemData.notaFiscal}`}>
                  <VerticalAlignBottom /> <u>Baixar NF</u>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          {!isDropdownOpen ? (
            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span>Ver mais</span>
              <KeyboardArrowDown />
            </div>
          ) : (
            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span>Ver menos</span>
              <KeyboardArrowUp />
            </div>
          )}
        </div>
      </div>
    ),
    [
      hasBoleto,
      hasNotaFiscal,
      isDropdownOpen,
      userItemData?.dataVencimento,
      userItemData.debitoEmTransacoes,
      userItemData.descricao,
      userItemData.linkBoleto,
      userItemData.notaFiscal,
      userItemData.quitada,
      userItemData.status,
      userItemData?.valor,
    ]
  );

  return (
    <div id={styles.FinancialInvoicesItemMobile}>
      <div className={styles.container}>{List}</div>
      <div
        className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ""}`}
      >
        {isDropdownOpen &&
          userItemData.itens &&
          userItemData.itens.length > 0 &&
          userItemData.itens.map((item) => (
            <div key={item.id} className={styles.dropdownItem}>
              <div className={styles.title}>
                <span>{Utils.toDate(item.dataCadastro)}</span>
                <span>{item.nome}</span>
              </div>
              <span>{moneyMaskNumber(Number(item.valor))}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
