import styles from "./HeaderTitle.module.scss";
export const titleHeader = ["Equipamento", "Quantidade", "Total"];

const HeaderTitle = () => {
    return (
        <div id={styles.tableHeader}>
            {titleHeader.map((item, index) => {
                return (
                    <div key={index} className={styles.container}>
                        {item}
                    </div>
                );
            })}
        </div>
    )
}

export default HeaderTitle