import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './AutocompleteInput.module.scss'
import { TextField } from '@material-ui/core'
import { v4 } from 'uuid';
import debounce from 'lodash.debounce';


export interface IAutocompleteItemResponse { id: string, name: string };
export interface IAutocompletePaginatedListResponse<T = IAutocompleteItemResponse> {
    page: number;
    pageSize: number;
    totalPages: number;
    total?: number;
    orderBy?: string[];
    ascending?: boolean;
    items: T[];

}

export interface IAutocompletePaginatedRequest {
    page: number;
    pageSize: number;
    keyword: string;
}



export interface IAutocompleteInputProps<T> {
    //propertys
    // list: IPaginatedList<T>
    name?: string;
    id?: string;
    value?: T;
    getList: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<T>>
    getOptionLabel: (option: T) => string | number | null | undefined;
    getOptionDescription?: (option: T) => string;
    getOptionValue?: (option: T) => any;
    onSelect: (option?: T, value?: any) => void;
    type?: "Number"
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    textInput?: string | null | undefined | number;
    onChange?: (value?: any) => void
    //TODO //minLength?: number
}
const AutocompleteInput = <T,>(
    {
        getList,
        getOptionLabel,
        value,
        onSelect,
        getOptionValue = (option: T) => option,
        getOptionDescription,
        id = v4().replace(/-/g, ''),
        type,
        // minLength = 3
        error,
        helperText,
        disabled,
        textInput,
        onChange
    }: IAutocompleteInputProps<T>
) => {

    const [keyword, setKeyword] = useState<string | null | number | undefined>('');

    const [list, setList] = useState<T[]>();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number>(2);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [openSelect, setOpenSelect] = useState<boolean>(false);

    const inputRef = React.createRef<HTMLInputElement>();

    useEffect(() => {
        setKeyword(textInput);
    }, [textInput])


    const getListHandler = useCallback(async (keyword, page) => {
        // if (keyword.length < 3) return;
        // if (!openSelect) return;
        if (page > totalPages && page !== 1) return;
        try {
            setIsLoading(true);

            const response = await getList({ page: page, pageSize: 10, keyword: keyword });
            setTotalPages(response.totalPages);
            if (page === 1)
                setList(response.items);
            else
                setList(prev => [...prev ?? [], ...response.items]);
        } finally {
            setIsLoading(false);
        }
    }, [getList, totalPages])

    const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            getListHandler(keyword, page + 1);
            setPage(page + 1);
        }
    }, [getListHandler, page, keyword])



    const onChangeDebounce = useMemo(
        () =>
            debounce((value: string) => {
                getListHandler(value, 1);
            }, 500),
        [getListHandler]
    );

    useEffect(() => {
        return () => {
            onChangeDebounce.cancel();
        };
    }, [onChangeDebounce]);



    const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let _value = e.target.value;
        if (type === "Number") {
            _value = e.target.value.replace(/\D/g, '');
        }
        setKeyword(_value);
        setList(undefined);
        setIsLoading(true);
        setOpenSelect(true);
        setTotalPages(2);
        onChangeDebounce(_value);
        onChange && onChange(_value)
    }, [onChangeDebounce, type])

    const onClickSelect = useCallback((value: T) => {
        setKeyword(getOptionLabel(value));
        onSelect(value, getOptionValue(value));
        setOpenSelect(false);
    }, [getOptionLabel, getOptionValue, onSelect])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const autocompleteInput = document.getElementById(id);
            if (autocompleteInput && !autocompleteInput.contains(event.target as Node)) {
                setOpenSelect(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [id]);

    const onFocusHandler = useCallback(() => {
        setOpenSelect(true);
        setTotalPages(2);
        getListHandler(keyword, 1);
    }, [getListHandler, keyword])



    return (
        <div id={id} className={styles.AutocompleteInput} >
            <TextField
                ref={inputRef}
                variant="outlined"
                fullWidth
                onChange={onChangeHandler}
                value={keyword}
                onFocus={onFocusHandler}
                error={error}
                disabled={disabled}
                helperText={helperText}
            />
            {
                openSelect &&
                <div className={styles.list} onScroll={handleScroll} style={{
                    // position: 'fixed',
                    // zIndex: 100000,
                    // maxHeight: 200,
                    // // height: 'auto',
                    // // overflowY: 'auto',
                    // top: `calc(${inputRef.current?.offsetHeight} + ${inputRef.current?.offsetTop}})`,
                    // width: inputRef.current?.offsetWidth
                }}  >
                    {list?.map((item, index) => (
                        <div

                            onClick={() => onClickSelect(item)}
                            key={index}
                            className={styles.item}>
                            <b>{getOptionLabel(item)}</b>
                            {getOptionDescription && <span>{getOptionDescription(item)}</span>}
                        </div>
                    ))}
                    {list?.length === 0 && <div className={styles.item}>Nenhum item encontrado</div>}
                    {

                        isLoading &&
                        <div className={styles.footer} >
                            {/* {isLoading && <Skeleton variant='rectangular' width={'100%'} height={20} />} */}
                            {/* {isLoading && <Skeleton variant='rectangular' width={'100%'} height={20} />} */}
                            <span >Carregando...</span>
                        </div>
                    }
                </div>
            }
        </div >
    )
}
export default AutocompleteInput