import { Drawer, Icon } from '@material-ui/core';
import styles from './DowngradePermissionModal.module.scss';

interface DowngradePermissionModalProps {
    open: boolean;
    onClose: () => void;
}

export const DowngradePermissionModal = ({ open, onClose }: DowngradePermissionModalProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Voltar para o <b>Básico</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    <img src="/assets/icon/plans/downgrade-permission.svg" alt="" />
                    <p>
                        Ops, parece que você não pode fazer o downgrade do plano!
                        Para isso, é necessário ser <b>administrador do estabelecimento.</b>
                    </p>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}