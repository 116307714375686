import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import LinkCardsApi from "services/api/linkCards/LinkCardsApi";
import { IAddLinkCards } from "../interfaces/IAddLinkCards";
import { AddLinkCardsItemUseCase } from "modules/linkCards/application/useCases/AddLinkCardsItemUseCase";

const UseAddLinkCardsItem = () => {

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>();
  const { currentLocal } = useLocal();
  const [addLinkCards, setAddLinkCards] = useState<IAddLinkCards>()
 
  


  const addLinkCardsItem = useCallback(
    async (tags: string) => {
      try {
        const serviceLinkCards = LinkCardsApi();

        setIsLoadingAdd(true);
        if (currentLocal) {
         const response = await AddLinkCardsItemUseCase(serviceLinkCards,
            tags,
            currentLocal.id,
          );
          setAddLinkCards(response)
          console.log(response)
          return response
        }
      } 
      finally {
        setIsLoadingAdd(false);
      }
    },
    [currentLocal]
  );


  return {        
    isLoadingAdd,
    addLinkCardsItem,
    addLinkCards

  };
};

export default UseAddLinkCardsItem;
