import { ISignupValues } from "../signup/UseSignupPage";

export interface ISignupFormErrors {
  nome?: string;
  cpf?: string;
  email?: string;
  senha?: string;
  confirmarSenha?: string;
}

export const SignupValidation = (formData: ISignupValues, setErrors: React.Dispatch<React.SetStateAction<ISignupFormErrors>>) => {
  let _errors: ISignupFormErrors = {};
  let hasError = false;

  if (!formData.nome) {
    _errors = { ..._errors, nome: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.cpf) {
    _errors = { ..._errors, cpf: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.email) {
    _errors = { ..._errors, email: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.senha) {
    _errors = { ..._errors, senha: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.confirmarSenha) {
    _errors = { ..._errors, confirmarSenha: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
