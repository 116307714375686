export interface IRequestParams {
  cashierIds: Array<string>,
  page?: number,
  pageSize?: number,
  sort?: ISort,
  exportFormat?: ExportFormat
  shouldUseNewSchema?: boolean;
  ownerId?: string;
}

interface ISort {
  type: string;
  orientation: 'asc' | 'desc';
}

export enum ExportFormat {
  PDF = 1,
  EXCEL = 2,
}
