import React from 'react';
import styles from './ProgressBar.module.scss';
import { moneyMaskNumber } from 'services/utils/Money';

interface IProgressBarProps {
    goal: number;
    currentValue: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ goal, currentValue }) => {
    const progress = Math.min((currentValue / goal) * 100, 100);
    const stepsToShow = 11;
    const stepValue = goal / (stepsToShow - 1);

    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBarBackground}>
                <div className={styles.progressIndicator} style={{ left: `${progress}%` }}>
                    <span>Estamos aqui</span>
                    <div className={styles.triangle}></div>
                </div>
                {Array.from({ length: stepsToShow }).map((_, index) => {
                    const stepLabelValue = index * stepValue;
                    const stepPosition = (index / (stepsToShow - 1)) * 100;

                    return (
                        <div key={index} className={styles.step} style={{ left: `${stepPosition}%` }}>
                            <div className={`${styles.stepCircle} ${progress >= stepPosition ? styles.stepCircleActive : ''}`}></div>
                            <div className={`${styles.stepLabel} ${progress >= stepPosition ? styles.stepLabelActive : ''}`}>
                                {stepLabelValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </div>
                        </div>
                    );
                })}
                <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
            </div>
            <div className={styles.verticalLine} style={{ left: `${progress}%` }}></div>
            <div className={styles.containerCurrentValue} style={{ left: `${progress}%` }}>
                <p>{moneyMaskNumber(currentValue)}</p>
            </div>
        </div>
    );
};