import styles from "./FunnelItem.module.scss";

export interface Props {
  title?: string;
  background?: string;
  width?: number;
  value?: number;
}

const FunnelItem = ({ title, background, width, value }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.funnel}>
        <div
          className={styles.funnelItem}
          style={{ background: background,width: "100%", maxWidth: width }}
        >
          <p>{value} {title}</p>
        </div>
        {/* <div className={styles.contentLine}>
          <div className={styles.line} />
        </div> */}
        {/* <div className={styles.contentLine}>
          <div className={styles.line} />
        </div> */}
      </div>
      {/* <div className={styles.contentLine}>
        <div className={styles.line} />
        <div className={styles.contentTitle}>
          <p className={styles.title}>{title}</p>
        </div>
      </div> */}
    </div>
  );
};

export default FunnelItem;
