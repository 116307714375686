export enum FeatureTypeEnum {
  BASIC = 0,
  CRM = 1,
  TICKETS = 2,
  AUTOMACAO = 3,
  SURVEY = 4,
  FIDELITY = 5,
  PUSH = 6,
  EMAIL = 7,
  BI = 8,
  TERMINAL = 9,
  EXTERNAL_POS = 10,
  CIELO_EVENT = 11,
  CIELO_ESTABLISHMENT = 12
}
