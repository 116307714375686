import Lottie from "react-lottie";
import load_food_saas from "../../../../../res/assets/animations/load_food_saas.json";
import styles from "./FoodSaasLoading.module.scss";

const loadingAnimation = {
  loop: true,
  autoplay: true,
  animationData: load_food_saas,
  rendererSettings: {
    className: styles.loading,
  }
};

interface IFoodSaasLoadingProps {
  text?: React.ReactNode;
}

const FoodSaasLoading = ({ text }: IFoodSaasLoadingProps) => {
  const { innerWidth: width } = window;

  return (
    <div id={styles.FoodSaasLoading}>
      <div>
        <Lottie
          width={width > 600 ? 230 : 200}
          height={width > 600 ? 173 : 150}
          speed={1}
          options={loadingAnimation}
          isStopped={false}
          isPaused={false}
        />
        {text ?? <h1><span style={{ color: "#6C2EE0" }}>Aguarde,</span> estamos preparando seu estabelecimento!</h1>}
        <span>Falta pouco para começar a usar!</span>
      </div>
    </div>
  )
};

export default FoodSaasLoading;
