import LandingPage from "components/freePage/LandingPage";
import Layout from "components/layout/presentation/Layout";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import ConfigSankhya from "modules/config/integrationsConfig/presentation/pages/configSankhya/ConfigSankhya";
import { useState, useEffect } from "react";

export const SankhyaDetail = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);
  const [existPayment, setExistPayment] = useState(false);
  const [creditCard, setCreditCard] = useState(false);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  return (
    <Layout>
      <LandingPage
        title="Sankhya"
        subtitle="Sistema ERP"
        description="Por meio de uma metodologia exclusiva, a Sankhya oferece a solução mais completa de gestão para o seu segmento, permitindo que você esteja mais próximo de cada etapa da sua estratégia."
        aboutTitle={<>O sistema ERP que <span className="purpleText">otimiza o dia a dia</span> da sua empresa</>}
        about="O ERP Sankhya é a solução ideal para as empresas que buscam por um sistema completo, que combine maturidade de gestão com tecnologia e mobilidade. Com a Sankya, os gestores têm em mãos uma ferramenta de automação de processos e análise de rentabilidade, capaz de reduzir custos operacionais e garantir uma melhor gestão financeira."
        logo="/assets/icon/sankhya-detail.svg"
        footerButtonLink="https://www.ajuda.meep.com.br/sistemaerp"
        includedItems={[
          {
            title: "Business Intelligence",
            description:
              "Tome decisões mais seguras, ágeis e precisas com uma solução de inteligência de mercado totalmente nativa no ERP.",
            img: "/assets/icon/sankhya/chart-line.svg",
          },
          {
            title: "Inteligência artificial",
            description:
              "O Sankya oferece um assistente virtual com base em Inteligência Artificial que responde sobre toda a operação do seu negócio.",
            img: "/assets/icon/sankhya/brain.svg",
          },
          {
            title: "Gestão comercial",
            description:
              "Tenha soluções completas e integradas que auxiliam na gestão da sua equipe comercial e potencializam suas vendas.",
            img: "/assets/icon/sankhya/shopping-cart.svg",
          },
          {
            title: "Gestão contábil",
            description:
              "Automatize e agilize sua contabilização, e garanta segurança frente às obrigações fiscais da sua empresa.",
            img: "/assets/icon/sankhya/money-withdrawal.svg",
          },
          {
            title: "Gestão de colaboradores",
            description:
              "Gerencie os aspectos legais dos colaboradores de forma fácil e garanta a máxima segurança na extração das informações.",
            img: "/assets/icon/track/client.svg",
          },
          {
            title: "Service Desk",
            description:
              "Conte com um suporte ágil, eficiente e proativo.  ",
            img: "/assets/icon/sankhya/call-center.svg",
          },
        ]}
        setOpen={() => setOpen(IntegrationTypeEnum.SANKHYA)}
        footerImg="/assets/img/sankhya-video.png"
        footerImgLink="https://www.youtube.com/watch?v=B06Ea_hNqv4"
      />

      {open && open === IntegrationTypeEnum.SANKHYA && (
        <ConfigSankhya
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
          existPayment={existPayment}
          setExistPayment={setExistPayment}
          creditCard={creditCard}
          setCreditCard={setCreditCard}
        />
      )}
    </Layout>
  );
};
