import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PaymentLinkRecurrenceApi from "services/api/paymentLinkRecurrence/PaymentLinkRecurrenceApi";
import { IPaymentLinkDatailsList } from "../interfaces/IPaymentLinkDatailsList";
import GetPaymentLinkDetailsUseCase from "modules/paymentLinkReport/application/useCases/GetPaymentLinkDetailsUseCase";


const UsePaymentLinkDetailsList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [paymentLinkDetailsList, setPaymentLinkDetailsList] = useState<IPaymentLinkDatailsList>()
  const { currentLocal } = useLocal();

  const getPaymentLinkDetailsList = useCallback(
    async (recurrenceId: string) => {
      try {
        const servicePermutation = PaymentLinkRecurrenceApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPaymentLinkDetailsUseCase(servicePermutation,
            recurrenceId,
            currentLocal.id
          );
          setPaymentLinkDetailsList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    paymentLinkDetailsList,
    isLoading,
    getPaymentLinkDetailsList,
    setPaymentLinkDetailsList,
  };
};

export default UsePaymentLinkDetailsList;
