import { useCallback, useState } from 'react'
import { ISaqueFormErrors, ISaqueFormItem } from './interfaces/ISaqueForm'
import { validate } from './validade/SaqueFormValidation';

export const UseSaqueForm = (onSubmit: (values: ISaqueFormItem) => void, defaultValues?: ISaqueFormItem) => {

    const [values, setValues] = useState(defaultValues ?? {
        valor: ''
    })

    const [errors, setErrors] = useState<ISaqueFormErrors>({});

    const onChangeHandle = useCallback((name: string, value: string) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback(() => {
        if (validate(values, setErrors)) {
            onSubmit(values);
        }
    }, [onSubmit, values]);

    return (
        {
            values,
            errors,
            onChangeHandle,
            onSubmitHandle,
        }
    )
}