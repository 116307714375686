import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { PasswordRecoveryUseCase } from "modules/saas/application/useCases/PasswordRecoveryUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { IForgetPassSchema } from "modules/saas/domain/schema/forgetPassSchema";
import { useQuery } from "hooks/UseQuery";

const service = SaasApi();

export const UseForgetPasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    const history = useHistory();
    const { toast } = useUi();
    const query = useQuery();
    const location = useLocation<{ type: string }>();

    useEffect(() => {
        query.get("email") && setEmail(query.get("email") as string);
        query.get("type") && setType(query.get("type") as string ?? location.state?.type);
    }, [location.state?.type, query]);

    useEffect(() => {
        if (type === "3" || type === "6" || type === "7") {
            setBackgroundColorTop("#00AEEF");
            setBackgroundColorBottom("#00AEEF");
        } else {
            setBackgroundColorTop("#6B41C9");
            setBackgroundColorBottom("#865FDD");
        }
    }, [type]);

    const handleOnBack = useCallback(() => {
        if (type === "5") {
            return history.push(`/public/saas/simple-login?type=5&email=${email}`);
        }
        return history.push(`/public/saas/login?type=${type}&email=${email}`);
    }, [email, history, type]);

    const handleRecoverPass = useCallback(async (value: IForgetPassSchema) => {
        let emailOrDocument = value.emailOrDocument;
        if (!value.emailOrDocument.includes("@")) {
            emailOrDocument = emailOrDocument.replace(/\D/g, "");
        }
        
        try {
            setIsLoading(true);

            const res = await PasswordRecoveryUseCase(service, emailOrDocument);
            
            if (res.success ) {
                if (type === "5") {
                    history.push(`/public/saas/simple-login?type=5&email=${res.email}`);
                    return toast("O link de recuperação foi enviado para o seu email!", "success");
                }

                history.push(`/public/saas/login?email=${email}`);
                return toast("O link de recuperação foi enviado para o seu email!", "success");
            }
            toast("Ocorreu um erro ao recuperar a senha. Tente novamente.", "error");
        } catch {
            toast('Ocorreu um erro ao recuperar a senha. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [email, history, toast, type]);

    return {
        isLoading,
        type,
        backgroundColorTop,
        backgroundColorBottom,
        handleOnBack,
        handleRecoverPass,
    }
}