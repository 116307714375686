import { Icon, IconButton, Switch, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import { IKDSStep } from '../../interfaces/IKDSSteps';
import { IKDSTable } from '../../interfaces/IKDSTable';
import { IKDSStores } from '../../interfaces/_IKDSStores';
import SelectorSteps from '../selectorSteps/SelectorSteps';
import styles from './KDSFilter.module.scss'
import { IKDSFilter } from '../../interfaces/IKDSFilter';
import { IStepLoading } from '../../UseKDSPage';
import { ISelect } from 'interfaces/ISelect';
import Utils from 'services/utils/Utils';
import UseSelectedEventStartDate from '../../hooks/UseSelectedEventStartDate';
import UseDimension from 'components/dimension/UseDimension';
import SoundOn from "../Icons/SoundOn.svg";
import SoundOff from "../Icons/SoundOff.svg";
import Button from 'components/ui/Button/Button';
import { Close, Fullscreen, FullscreenExit, SortOutlined } from '@material-ui/icons';

export interface ISaasKDSFilterProps {
    tables?: IKDSTable[];
    steps?: IKDSStep[];
    stores?: IKDSStores[];
    onSubmit: (values: IKDSFilter) => void;
    defaultValues: IKDSFilter;
    enableRefresh: React.MutableRefObject<boolean>;
    getStepTotals: (stepId: string) => Promise<{ total: number; } | null | undefined>;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    total: number;
    summaryView: boolean;
    setSummaryView: React.Dispatch<React.SetStateAction<boolean>>;
    handleSummaryView: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: IStepLoading[];
    isButtonDisabled: boolean;
    setFilter: React.Dispatch<React.SetStateAction<IKDSFilter>>;
    deviceList: ISelect[]
    saas?: boolean;
    kdsAudioPlay?: boolean;
    audioShowPause?: () => void;
    audioShowPlay?: () => void;
}

const defaultDevice = {
    id: '',
    name: 'Selecione o equipamento',
};
const defaulWebApp = {
    id: '00000000-0000-0000-0000-000000000000',
    name: 'WebApp/App',
};


const SaasKDSFilter: FC<ISaasKDSFilterProps> = ({
    tables,
    steps,
    stores,
    onSubmit,
    defaultValues,
    enableRefresh,
    getStepTotals,
    setTotal,
    total,
    summaryView,
    setSummaryView,
    handleSummaryView,
    isLoading,
    isButtonDisabled,
    setFilter,
    deviceList,
    saas,
    kdsAudioPlay,
    audioShowPause,
    audioShowPlay,
}) => {
    const [values, setValues] = useState<IKDSFilter>(defaultValues);
    const [selectedDefaultDevice, setSelectedDefaultDevice] = useState(defaultDevice);

    const { selectEventStartDate } = UseSelectedEventStartDate(); 
    const { dimensions, isMobile, fullscreen } = UseDimension();

    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

    useEffect(() => {
        if (selectEventStartDate) {            
            setValues({...defaultValues, startDate: selectEventStartDate});
        }
    }, [defaultValues, selectEventStartDate]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)       
    }, [onSubmit, values]);

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeStepsHandle = useCallback((steps: IKDSStep[]) => {
        setFilter((prev) => ({ ...prev, steps: steps }));
        setValues((prev) => ({ ...prev, steps: steps }));
    }, [setFilter]);

    const changeStoresHandle = useCallback((store?: IKDSStores | null) => {
        setValues((prev) => ({ ...prev, store: store ?? undefined }));
    }, []);

    const changeTableHandle = useCallback((tables: IKDSTable[] | null) => {
        setValues((prev) => ({ ...prev, tables: tables || undefined }));
    }, []);
    
    const onClickShowAdevancedFilterHandle = useCallback(() => {
        setValues(prev => ({ ...prev, tables: undefined, store: undefined }))
        setShowAdvancedFilter(prev => !prev);
    }, []);

    const sort = defaultValues?.orientation === 'asc' ? 'desc' : 'asc';
    const onClickSortHandle = useCallback(() => {
        onSubmit({ ...(defaultValues ?? {}), orientation: sort });
    }, [defaultValues, onSubmit, sort]);

    const onClickSortTableHandle = useCallback(() => {
        onSubmit({ ...(defaultValues ?? {}), sortByTable: !defaultValues.sortByTable });
    }, [defaultValues, onSubmit]);

    const handleChangeDevice = useCallback((value: string | undefined | null) => {
        setValues((prev) => ({
            ...prev,
            deviceId: value ?? '',
        }));
    }, []);

    const onClickFullscreenHandle = useCallback(() => {
      document.documentElement.requestFullscreen();
    }, []);

    const onClickFullscreeExit = useCallback(() => {
      document.exitFullscreen();
    }, []);

    return (
      <div id={styles.SaasKDSFilter}>
        <form onSubmit={submitHandle}>
          <div className={styles.selector}>
            <SelectorSteps
              steps={steps}
              total={total}
              setTotal={setTotal}
              values={values?.steps}
              onChange={(values) => changeStepsHandle(values)}
              getStepTotals={getStepTotals}
              isLoading={isLoading}
            />
          </div>
          <div className={styles.containerRefresh}>
            {dimensions.width < 717 && (
              <div className={styles.soundContentStatus}>
                <img src={kdsAudioPlay ? SoundOn : SoundOff} alt="Sound On" />
                <span>Som de Alerta</span>
                <Switch
                  checked={kdsAudioPlay}
                  onChange={kdsAudioPlay ? audioShowPause : audioShowPlay}
                  color="secondary"
                />
              </div>
            )}

            <div
              className={styles.containerSearch}
              style={{
                borderRadius: "8px",
                marginRight: "1rem",
              }}
            >
              <TextField
                placeholder="Nome, CPF, Produto ou Nº do pedido"
                fullWidth
                className={styles.search}
                size="small"
                name={"input"}
                onChange={changeHandle}
                value={values.input}
              />
              <IconButton
                title="Buscar estabelecimento"
                className={styles.button}
                type={"submit"}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>

            {dimensions.width >= 717 && (
              <div className={styles.soundContentStatus}>
                <img src={kdsAudioPlay ? SoundOn : SoundOff} alt="Sound On" />
                <span>Som de Alerta</span>
                <Switch
                  checked={kdsAudioPlay}
                  onChange={kdsAudioPlay ? audioShowPause : audioShowPlay}
                  color="secondary"
                />
              </div>
            )}

            <div>
              {/* <IconButton onClick={() => push("/meepFood/configuracao")}>
                <Settings />
              </IconButton> */}
              {!isMobile && (
                fullscreen ?
                  <IconButton size="small" onClick={onClickFullscreeExit}><FullscreenExit /></IconButton>
                  :
                  <IconButton size="small" onClick={onClickFullscreenHandle}><Fullscreen /></IconButton>
              )}
              {dimensions.width < 717 && <span>Filtros avançados</span>}
              <IconButton onClick={onClickShowAdevancedFilterHandle}>
                {!showAdvancedFilter ? <SortOutlined /> : <Close />}
              </IconButton>
            </div>
          </div>
          {showAdvancedFilter && (
            <div className={styles.advancedFilter}>
              <div className={styles.topAdvancedFilter}>
                <div className={styles.refresh}>
                  <div>
                    <Switch
                      color="secondary"
                      defaultChecked={true}
                      onChange={(ev, value) => (enableRefresh.current = value)}
                    />{" "}
                    Atualizar Automaticamente
                  </div>
                  <div>
                    <Switch
                      color="secondary"
                      checked={summaryView}
                      onChange={handleSummaryView}
                    />{" "}
                    Versão completa do KDS
                  </div>
                </div>
                {!defaultValues.groupedByTable ? (
                  <>
                    <button onClick={onClickSortTableHandle}>
                      <Icon>swap_vert</Icon>
                      {!values.sortByTable
                        ? `Ordernar por mesa`
                        : `Ordernar por tempo`}
                    </button>
                    {!!values.sortByTable && (
                      <button onClick={onClickSortHandle}>
                        <Icon>swap_vert</Icon>
                        {values.orientation === "desc"
                          ? `Crescente`
                          : `Decrecente`}
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button onClick={onClickSortHandle}>
                      <Icon>swap_vert</Icon>
                      {values.orientation === "desc"
                        ? `Últimos pedidos`
                        : `Primeiros pedidos`}
                    </button>
                  </>
                )}
              </div>
              <div className={styles.containerTop}>
                <div className={styles.item}>
                  <label>Inicio</label>
                  <TextField
                    size="small"
                    name={"startDate"}
                    onChange={changeHandle}
                    fullWidth
                    value={Utils.toInputDateString(values.startDate)}
                    type="datetime-local"
                  />
                </div>
                <div className={styles.item}>
                  <label>Fim</label>
                  <TextField
                    size="small"
                    name={"endDate"}
                    onChange={changeHandle}
                    fullWidth
                    defaultValue={Utils.toInputDateString(values.endDate)}
                    type="datetime-local"
                  />
                </div>
                <div className={styles.item}>
                  <label>Equipamento</label>
                  <Autocomplete
                    disableClearable
                    fullWidth
                    options={[
                      defaultDevice,
                      defaulWebApp,
                      ...(deviceList ?? []),
                    ]}
                    getOptionLabel={(option) => option.name}
                    value={selectedDefaultDevice}
                    onChange={(_, value: any) => {
                      setSelectedDefaultDevice(value);
                      handleChangeDevice(value.id);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className={styles.item}>
                  <label>Mesa</label>
                  <Autocomplete
                    fullWidth
                    size="small"
                    multiple
                    limitTags={2}
                    options={tables ?? []}
                    getOptionLabel={(option) => option.number}
                    value={values?.tables}
                    onChange={(ev, value) => changeTableHandle(value)}
                    placeholder="mesa"
                    renderInput={(params) => <TextField {...params} />}
                    style={{ width: "100%" }}
                  />
                </div>
                {!stores?.length && (
                  <div
                    className={styles.containerButton}
                    style={{ width: "100%" }}
                  >
                    <Button
                      className={styles.button}
                      fullWidth
                      style={{
                        height: 40,
                        borderRadius: 8,
                        width: dimensions.width < 600 ? "100%" : 110,
                      }}
                      variant="contained"
                      color={"primary"}
                      type="submit"
                      disabled={
                        // isLoading.some((item) => item.isLoading) ||
                        isButtonDisabled
                      }
                    >
                      Buscar
                    </Button>
                  </div>
                )}
              </div>
              {!!stores?.length && (
                <div className={styles.containerBottom}>
                  <div
                    className={styles.item}
                    style={{ flex: 3, minWidth: 300 }}
                  >
                    <label>Lojas</label>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={stores ?? []}
                      getOptionLabel={(option) => option.name}
                      onChange={(ev, values) => changeStoresHandle(values)}
                      placeholder="Loja"
                      // renderOption={(props, option) => <li {...props} style={{ color: option.color }} >{option.name}</li>}
                      // renderTags={(options, getProps) => (options.map((option, index) => <Chip label={option.name} {...getProps({ index })} style={{ backgroundColor: option.color, color: `#fff`, height: 24 }} />))}
                      value={values?.store}
                      limitTags={3}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>

                  <div className={styles.containerButton}>
                    <Button
                      className={styles.button}
                      fullWidth
                      style={{ height: 40, borderRadius: 8, width: 110 }}
                      variant="contained"
                      color={"primary"}
                      type="submit"
                      disabled={
                        isLoading.some((item) => item.isLoading) ||
                        isButtonDisabled
                      }
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    );
}
export default SaasKDSFilter;