import { ImportExport } from '@mui/icons-material';
import { Button, Icon, IconButton } from '@mui/material';
import { ICupomReport, IDiscountCouponsReport } from 'modules/config/discountCoupons/presentation/interfaces/IDiscountCouponsReport';
import React, { useCallback, useState } from 'react'
import styles from './CouponsList.module.scss';

export interface ICouponsProps {
    discountCouponsReport: IDiscountCouponsReport | undefined;
    closeCoupons: () => void;
}
type IOrder = "asc" | "desc";

export const CouponsList: React.FC<ICouponsProps> = ({ discountCouponsReport, closeCoupons }) => {
    const [order, setOrder] = useState<IOrder>();
    const [newArray, setNewArray] = useState<ICupomReport[]>(discountCouponsReport?.codes ?? [])

    const orderCode = useCallback(() => {
        if (!discountCouponsReport?.codes) {
            return;
        }

        let sortedCodes;

        if (order === 'asc') {
            sortedCodes = [...discountCouponsReport.codes].sort((a, b) => a.code.localeCompare(b.code));
            setOrder('desc');
        } else {
            sortedCodes = [...discountCouponsReport.codes].sort((a, b) => b.code.localeCompare(a.code));
            setOrder('asc');
        }

        setNewArray(sortedCodes);
    }, [discountCouponsReport?.codes, order]);

    const orderUsed = useCallback(() => {
        const cuponsOrder = [...discountCouponsReport?.codes ?? []].sort((p1: ICupomReport, p2: ICupomReport) => {
            if (order === 'asc') {
                return p1.used - p2.used;
            } else {
                return p2.used - p1.used;
            }
        });

        setNewArray(cuponsOrder)
        setOrder(order === "asc" ? 'desc' : 'asc');
    }, [discountCouponsReport?.codes, order])

    return (
        <div id={styles.CouponsList}>
            <div className={styles.titleTop}>
                <h2>Todos <b>cupons</b></h2>
                <IconButton size='small' onClick={closeCoupons}><Icon>close</Icon></IconButton>
            </div>
            <div className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <span onClick={orderCode}>Nome <ImportExport /></span>
                        <span onClick={orderUsed}>Quantidade usada <ImportExport /></span>
                    </div>
                    {
                        newArray.map(item => (
                            <div className={styles.content}>
                                <span>{item.code}</span>
                                <span>{item.used}</span>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.buttonFooter}>
                    <Button onClick={closeCoupons}>Fechar</Button>
                </div>
            </div>
        </div>
    )
}
