import { ServicesScheduleApi } from 'services/api/schedule/service/ServiceScheduleApi';
import React from 'react'
import { ISelect } from '../../../domain/models/IDropDown';
import { IServiceScheduleApiCB } from '../../interfaces/api/IServicesScheduleApi';

export const GetMainServiceCategoriesByLocalIdUseCase = async (localId: string, _serviceApi: IServiceScheduleApiCB): Promise<ISelect[]> => {
    const servicesApi = _serviceApi();
    const response = await servicesApi.getMainCategoriesByLocalId(localId);
    return response.items.map((item) => ({ 
        description: item.name, 
        id: item.id, 
        subItems: item.subCategories.map(x => ({ id: x.id, description: x.name  })) 
    }));
}
