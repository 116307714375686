import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './_ProductItemFormToCatalogList.module.scss'
import { IProductItem } from '../../categoryAndProducts/productList/IProductItem'
import { Checkbox, FormControl, FormControlLabel, FormLabel, Icon, IconButton, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';
import { InputNumber } from '../../ui/InputNumber';
import { IItem } from '../../ui/DnDList';
import Row from '../../ui/Row';
import { InputMoney } from '../../ui/InputMoney';
import { PercentSharp } from '@mui/icons-material';
import { ContainerLabel } from '../../ui/ContainerLabel';

export interface ICatalogListProductItemFormValue {
    id: string,
    name: string,
    imageUrl?: string,
    value: number,
    discount?: number
}
export interface IProductItemFormToCatalogListProps {
    //propertys
    values: ICatalogListProductItemFormValue,
    onChangeValues: (values: ICatalogListProductItemFormValue) => void
    onClickRemove: (values: ICatalogListProductItemFormValue) => void
}
const ProductItemFormToCatalogList: FC<IProductItemFormToCatalogListProps> = ({ values, onChangeValues, onClickRemove }) => {


    const [typeValue, setTypeValue] = useState<'percent' | 'value'>('percent');

    const [discountInValue, setDiscountInValue] = useState(0);
    const [discountInPercent, setDiscountInPercent] = useState(0);

    // const changeDiscountHandle = useCallback((_, value: number) => {
    //     onChangeValues(({ ...values, discount: value }));
    // }, [onChangeValues, values]);
    // const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    //     let _value = Number(ev.target.value);
    //     _value = _value > 100 ? 100 : (_value < 0 ? 0 : _value);
    //     onChangeValues(({ ...values, discount: _value }));
    // }, [onChangeValues, values]);



    const changeDiscountPercentHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        let _valueInPercent = Number(ev.target.value.match(/\d+/g)?.join(""));
        _valueInPercent = _valueInPercent > 100 ? 100 : (_valueInPercent < 0 ? 0 : _valueInPercent);
        // setDiscountInPercent(_valueInPercent)
        setDiscountInValue(Number((_valueInPercent * values.value / 100).toFixed(2)))
        onChangeValues(({ ...values, discount: _valueInPercent }));
    }, [onChangeValues, values]);

    const changeDiscountValueHandle = useCallback((ev: ChangeEvent<HTMLInputElement>, dicountInValue: number) => {
        let _value = dicountInValue;
        _value = _value > values.value ? values.value : (_value < 0 ? 0 : _value);
        const _valueInPercent = (_value / values.value) * 100;
        setDiscountInValue(_value);
        // setDiscountInPercent(_valueInPercent);
        onChangeValues(({ ...values, discount: _valueInPercent }));
    }, [onChangeValues, values]);

    useEffect(() => {
        if (values.discount) {
            setDiscountInValue(Number((values.discount * values.value / 100).toFixed(2)))
            setDiscountInPercent(values.discount);
        } else {
            setDiscountInValue(0);
            setDiscountInPercent(0);
        }
    }, [values])    


    return (
        <div id={styles.ProductItemFormToCatalogList} >
            <IconButton className={styles.removeButton} onClick={() => onClickRemove(values)}>
                <Icon>c,kls</Icon>
            </IconButton>
            <div className={styles.product}>
                {
                    <img
                        onError={({ currentTarget }) => {
                            currentTarget.src = "/assets/icon/placeholder.svg";
                        }}
                        alt='Imagem produto'
                        src={(values.imageUrl && values.imageUrl !== "" ? values.imageUrl : "/assets/icon/placeholder.svg")}
                        className={styles.image}
                    />
                }
                {/* image:{productItem.image !== "" ?? productItem.imageUrl} */}
                <div className={styles.descriptions} >
                    <div className={styles.name} >
                        {values.name}
                    </div>
                </div>
            </div>
            <ContainerLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="percent"
                    name="radio-buttons-group"
                    value={typeValue}
                    row
                    onChange={(ev, value) => setTypeValue(value as 'percent' | 'value')}
                >
                    <FormControlLabel value="percent" control={<Radio />} label="Porcentagem" />
                    <FormControlLabel value="value" control={<Radio />} label="Valor" />
                </RadioGroup>
            </ContainerLabel>
            <Row>
                <InputMoney value={values.value} disabled label="Valor" gap={0} styledSize></InputMoney>
                {typeValue === 'percent' && <ContainerLabel
                    label={"Desconto aplicado"}
                    gap={0}
                >
                    <TextField
                        variant='outlined'
                        size='small'
                        fullWidth
                        inputProps={{
                            max: 100,
                            min: 0
                        }}
                        value={`${!!discountInPercent ? discountInPercent : 0}%`}
                        onChange={changeDiscountPercentHandle}
                        sx={{
                            '& input': {
                                paddingTop: "14px",
                                height: "48px",
                                boxSizing: "border-box"
                            },
                        }}
                    />
                </ContainerLabel>}
                {typeValue === 'value' && <ContainerLabel
                    label={"Desconto"}
                >
                    <InputMoney
                        variant='outlined'
                        size='small'
                        fullWidth
                        inputProps={{
                            max: 100,
                            min: 0
                        }}
                        value={discountInValue}
                        onChange={changeDiscountValueHandle}
                        styledSize
                    />
                </ContainerLabel>
                }
            </Row>

        </div>
    )
}
export default ProductItemFormToCatalogList