import styles from './PaymentPage.module.scss';
import { PaymentForm } from '../../components/paymentForm/PaymentForm';
import { UsePaymentPage } from './UsePaymentPage';
import FoodSaasLoading from '../../components/foodSaasLoading/FoodSaasLoading';
import { UseBackPage } from '../../hooks/UseBackPage';
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum';
import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { KeyboardArrowLeftOutlined } from '@material-ui/icons';
import CieloEventContainer from '../../components/cieloEventContainer/CieloEventContainer';
import CieloPosContainer from '../../components/cieloPosContainer/CieloPosContainer';
import SaasPlanList from 'components/saasPlansOverlay/SaasPlanList';

export const PaymentPage = () => {
    const {
        type,
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        wPos,
        setWPos,
        form, 
        setForm,
        formLoading,
        handleSubmit,
        count,
        posPriceValues,
        posPriceCalc,
        setAmount
    } = UsePaymentPage();
    const { onBack } = UseBackPage();

    

    const handleShowPlans = useCallback(() => {
        if (type === ProspectType.CieloEvent) {
            return (
                <CieloEventContainer />
            );
        }
        return (
            <SaasPlanList
                isCieloCustomer={type === ProspectType.CieloEstablishment}
                hasBalance={type === ProspectType.Balance}
                plans={plans}
                selectedMode={selectedType}
                selectedPlan={selectedPlan}
                selectModeFn={setSelectedType}
                selectPlanFn={(plan: any) => {setSelectedPlan(plan); setAmount(0)}}
            />
        );
    }, [plans, selectedPlan, selectedType, setAmount, setSelectedPlan, setSelectedType, type]);

    return (
        <div id={styles.PaymentPage}>
            <div className={styles.header}>
                <img src="/assets/img/LogoMee.svg" alt="" />
            </div>
            <div className={styles.container}>
                <IconButton className={styles.backContainer} onClick={() => onBack("address")}>
                    <KeyboardArrowLeftOutlined />
                    <span>Voltar</span>
                </IconButton>
                <div className={styles.content}>
                    {type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment 
                        ? <header>
                            <h1>Efetue o pagamento</h1>
                            <p>Para finalizar, selecione seu plano e forma de pagamento:</p>
                        </header>
                        : <header>
                            <h1>Você está quase lá!</h1>
                            <p>Para finalizar, selecione a forma de pagamento:</p>
                        </header>
                    }

                    {handleShowPlans()}

                    {(type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment) &&
                        <CieloPosContainer
                            selectedPlan={type === ProspectType.CieloEstablishment ? selectedPlan : undefined}
                            count={count}
                            posPriceCalc={posPriceCalc}
                        />
                    }

                    <PaymentForm
                        selectedType={selectedType}
                        selectedPlan={selectedPlan}
                        plans={plans}
                        form={form}
                        wPos={wPos}
                        setForm={setForm}
                        formLoading={formLoading}
                        onSubmit={handleSubmit}
                        type={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? type : undefined}
                        cieloPosPrice={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? posPriceValues.price : undefined}
                        cieloPosFullPrice={type === ProspectType.CieloEvent ? posPriceValues.fullPrice : undefined}
                        cieloPosDiscount={type === ProspectType.CieloEvent ? posPriceValues.discount : undefined}
                    />
                </div>
            </div>

            {formLoading && <FoodSaasLoading text={<h1><span style={{ color: "#6C2EE0" }}>Aguarde,</span> estamos processando seu pagamento</h1>} />}
        </div>
    )
}