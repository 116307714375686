import React, { FC, useCallback, useMemo, useState } from 'react'
import { IKDSStep } from '../../interfaces/IKDSSteps'
import styles from './SelectorSteps.module.scss'
import StepItem from './StepItem'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IStepLoading } from '../../UseKDSPage';
export interface ISelectorEtapaProps {
    //propertys
    steps?: IKDSStep[],
    values?: IKDSStep[],
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    onChange: (value: IKDSStep[]) => void,
    getStepTotals: (stepId: string) => Promise<{ total: number } | null | undefined>
    total: number;
    isLoading: IStepLoading[];
}
const SelectorSteps: FC<ISelectorEtapaProps> = ({ steps, values, onChange, getStepTotals, total, setTotal, isLoading }) => {
    const [clickTotal, setClickTotal] = useState(true);

    const { currentLocal } = useLocal();

    const onClickHandle = useCallback((step: IKDSStep) => {
      if (isLoading.some((item) => item.isLoading)) return;
        if (values?.find(item => item.id === step.id)) {
            onChange(values.filter(item => item.id !== step.id));
        } else {
            onChange([...values ?? [], step]);
        }
        setClickTotal(false);
    }, [isLoading, onChange, values]);

    const onClickHandleTotal = useCallback(() => {
      if (isLoading.some((item) => item.isLoading)) return;
        onChange([]);
        setClickTotal(!clickTotal)
    }, [clickTotal, isLoading, onChange]);

    const isSelected = useCallback(
      (step: IKDSStep) => !!values?.find((item) => item.id === step.id),
      [values]
    );

    const list = useMemo(
      () =>
        steps
          ?.filter((item) => item.id !== "53cb20cb-4ebb-40b8-8738-6ba24a31302f")
          .map((step) => (
            <StepItem
              getStepTotals={getStepTotals}
              setTotal={setTotal}
              step={step}
              isSelected={isSelected}
              onClickHandle={onClickHandle}
            />
          )),
      [getStepTotals, isSelected, onClickHandle, setTotal, steps]
    );

    const isSaas = currentLocal?.systemIdentifier === 1;

    return (
      <div id={isSaas ? styles.SaasSelectorSteps : styles.SelectorSteps}>
        <div className={styles.listContainer}>
          <div
            onClick={onClickHandleTotal}
            className={clickTotal ? styles.itemSelected : styles.item}
            style={{ marginRight: "20px" }}
          >
            <label className={styles.label}>Total</label>
            <div
              className={`${isSaas ? styles.saasBadge : styles.badge} ${
                clickTotal ? styles.saasBadgeSelected : ""
              }`}
            >
              {total > 99 ? "+99" : total}
            </div>
          </div>
          <div className={styles.list}>
            {list}
          </div>
        </div>
      </div>
    );
}
export default SelectorSteps


