import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { GetOrderPadsIdsByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetOrderPadsIdsByLocalIdUseCase';
import { GetServiceScheduleByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetServiceScheduleByLocalIdUseCase';
import { PostAddPlaceScheduleUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostAddPlaceScheduleUseCase';
import { IAddPlaceScheduleRequest, ISpecialDays } from 'modules/schedule/domain/dto/placeSchedule/IAddPlaceScheduleRequest';
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import PlacesScheduleApi from 'services/api/schedule/places/PlacesScheduleApi';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import { IPlaceScheduleForm } from '../../components/placeScheduleForm/interface/IPlaceScheduleForm';
import { IAddSpecialDays } from 'modules/schedule/domain/models/ISpecialDays';

const UseAddPlaceSchedulePage = () => {

    const [orderPads, setOrderPads] = useState<ISelect[]>([]);
    const [services, setService] = useState<ISelect[]>([]);
    const [specialDays, setSpecialDays] = useState<IAddSpecialDays[]>([])

    const { currentLocal } = useLocal();
    const { showLoading, hideLoading, toast } = useUi();
    const { push } = useHistory();

    useEffect(() => {
        if (currentLocal) {
            showLoading()
            GetOrderPadsIdsByLocalIdUseCase(currentLocal?.id, PlacesScheduleApi).then(response => {
                setOrderPads(response);
            }).finally(() => {
                hideLoading()
            })
        }
    }, [currentLocal])

    useEffect(() => {
        if (currentLocal) {
            showLoading()
            GetServiceScheduleByLocalIdUseCase(currentLocal?.id, PlacesScheduleApi).then(response => {
                setService(response);
            }).finally(() => {
                hideLoading()
            })
        }
    }, [currentLocal])

    const handleSpecialDays = useCallback(
        (specialDays: IAddSpecialDays[]) => {                       
            setSpecialDays(specialDays);          
        },
        [specialDays],
    )
    
    const onAddPlaceHandle = useCallback(
        (values: IPlaceScheduleForm) => {
            showLoading()
            if (currentLocal) {
                const placeScheduleRequest: IAddPlaceScheduleRequest = {
                    localId: currentLocal.id,
                    prefix: values.prefix,
                    description: values.description,
                    maxQuantity: values.maxQuantity,
                    // minimumLeadTimeInDays: values.minimumLeadTimeInDays,
                    minimumLeadTimeInMinutes: values.minimumLeadTimeInMinutes,
                    maximumLeadTimeInDays: values.maximumLeadTimeInDays,
                    // orderPadsIds: values.orderPadsIds,
                    servicesScheduleIds: values.servicesScheduleIds,
                    availableDays: values.availableDays,
                    specialDays
                }
                
                PostAddPlaceScheduleUseCase(placeScheduleRequest, PlacesScheduleApi).then((response) => {
                    //redirect to list here
                    toast("Passeios criados com sucesso", "success");
                    push('/private/agendamento/list/lugares')
                }).catch(() => {
                    
                }).finally(() => {
                    hideLoading();
                });
            }
        },
        [currentLocal, hideLoading, push, showLoading, specialDays, toast],
    )

    return (
        {
            orderPads,
            services,
            onAddPlaceHandle,
            handleSpecialDays
        }
    )
}

export default UseAddPlaceSchedulePage
