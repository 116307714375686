import { Button } from "@material-ui/core";
import { AddCircle } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { FC, useMemo } from "react";
import TIDsAdicionaisHeader from "../components/tidsAdicionaisHeader/TIDsAdicionaisHeader";
import TIDsAdicionaisSideSheet from "../components/tidsAdicionaisSideSheet/TIDsAdicionaisSideSheet";
import TIDsList from "../components/tidsList/TIDsList";
import styles from "./TIDsAdicionais.module.scss";
import { UseTIDsAdicionais } from "./UseTIDsAdicionais";

export interface ITIDsAdicionais {}

const TIDsAdicionaisPage: FC<ITIDsAdicionais> = ({}) => {
  const {
    columns,
    isMobile,
    openSidesheet,
    sideSheetRef,
    isLoading,
    tidsList,
    getTIDsList,
  } = UseTIDsAdicionais();

  const loadAnimation = useMemo(
    () =>
      isMobile ? (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
        </div>
      ) : (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
        </div>
      ),
    [isMobile]
  );

  return (
    <div id={styles.TIDsAdicionais}>
      <div className={styles.addTids}>
        {
          <Button onClick={() => openSidesheet()}>
            Adicionar TIDs
            <AddCircle />
          </Button>
        }
      </div>

      {isLoading ? (
        <>{loadAnimation}</>
      ) : !tidsList ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            <b>Falha ao obter</b> solicitações TIDs.
          </span>
          <span>Tente novamente!</span>
        </div>
      ) : tidsList?.length === 0 ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            Você não possui <b>solicitações TIDs</b> recentes!
          </span>
        </div>
      ) : (
        <>
          <TIDsAdicionaisHeader columns={columns} />
          <TIDsList
            columns={columns}
            tidsList={tidsList}
            getTIDsList={getTIDsList}
          />
        </>
      )}

      <TIDsAdicionaisSideSheet ref={sideSheetRef} getTIDsList={getTIDsList} />
    </div>
  );
};

export default TIDsAdicionaisPage;
