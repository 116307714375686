import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import { CashlessConsummationForm } from "modules/events/presentation/components/cashlessConsummationForm/CashlessConsummationForm";
import React, { FC } from "react";
import CPF from "services/utils/CPF";
import { moneyMaskNumber } from "services/utils/Money";
import { ICashlessConsummation } from "../../ICashlessConsummation";
import styles from "./CashlessConsummationCard.module.scss";
import UseCashlessConsummationCard from "./UseCashlessConsummationCard";

interface ICashlessConsummationCardProps {
  cashlessConsummation: ICashlessConsummation;
  refreshCashlessList: () => Promise<void>;
}

export const CashlessConsummationCard: FC<ICashlessConsummationCardProps> = ({
  cashlessConsummation,
  refreshCashlessList,
}) => {
  const {
    closeEditModal,
    openEdit,
    openEditModal,
    handleRechargeCashlessConsummation,
  } = UseCashlessConsummationCard(refreshCashlessList);

  return (
    <div id={styles.CashlessConsummationCard}>
      <p>{cashlessConsummation.name?.trim() || "-"}</p>
      <p>{CPF.cpfMask(cashlessConsummation.cpf) || "-"}</p>
      <p>{cashlessConsummation.motive?.trim() || "-"}</p>
      <div>
        <p>{moneyMaskNumber(cashlessConsummation.currentBalance)}</p>
        <IconButton onClick={openEditModal}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </div>
      <RightDrawer
        title="Editar voucher"
        open={openEdit}
        onClose={closeEditModal}
        className={styles.modal}
      >
        <CashlessConsummationForm
          defaultVoucher={cashlessConsummation}
          handleCancel={closeEditModal}
          handleSubmit={handleRechargeCashlessConsummation}
        />
      </RightDrawer>
    </div>
  );
};
