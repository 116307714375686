import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useQuery } from "hooks/UseQuery";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IProfileSaasForm } from "../../interfaces/profile/IProfileSaasForm";
import { ProspectSaasUseCase } from "modules/saas/application/useCases/ProspectSaasUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { CheckDocumentUseCase } from "modules/saas/application/useCases/CheckDocumentUseCase";
import { SaveProfileUseCase } from "modules/saas/application/useCases/SaveProfileUseCase";
import { getParamsString } from "../../utils/params";
import { format } from "date-fns";
import { IProspect, ProspectStatus } from "../../interfaces/prospect/IProspect";
import UseProspect from "../../hooks/UseProspect";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import { IProfileSchema } from "modules/saas/domain/schema/profileSchema";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";

const service = SaasApi();

interface IBlockProfileForm {
    name: boolean;
    document: boolean;
    birthday: boolean;
    phoneNumber: boolean;
  }

export const UseProfilePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState<IProfileSaasForm>({} as IProfileSaasForm);
    const [prospect, setProspect] = useState({} as IProspect);
    const [blockForm, setBlockForm] = useState<IBlockProfileForm>();
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    let query = useQuery();
    const history = useHistory();
    const { toast } = useUi();
    const { setEmail, setProspectId, setKey, setType, type } = UseProspect();
    const location = useLocation<{ type: string }>();

    const prospectId = query.get('prospectId');
    const key = query.get('key');

    useEffect(() => {
        if (!prospectId || !key) {
            history.push("/public/saas/register?" + getParamsString(query));
        } else {
            setProspectId(prospectId);
            setKey(key);
        }
    }, [history, key, prospectId, query, setKey, setProspectId]);

    useEffect(() => {
        (async () => {
            try {
                if (prospectId && key) {
                    const response = await ProspectSaasUseCase(service, prospectId, key);
                    if(response.status === ProspectStatus.Trial){
                        history.push(`/public/saas/trial?prospectId=${prospectId}&key=${key}`);
                    } else if (response.status === ProspectStatus.Plan) {
                        history.push(`/public/saas/success?prospectId=${prospectId}&key=${key}`);
                    }
                    setEmail(response.email);
                    setType(response.type);
                    setDefaultValues(prev => ({
                        ...prev,
                        name: response.name,
                        document: response.document,
                        birthday: response.birthday ? format(new Date(response.birthday), 'dd/MM/yyyy') : '',
                        phoneNumber: response.phoneNumber,
                    }));
                    setProspect(response);

                    if (response.type === ProspectType.Cielo || response.type === ProspectType.CieloEvent || response.type === ProspectType.CieloEstablishment) {
                        setProgressBarColor("#00739E");
                        setBackgroundColorTop("#00AEEF");
                        setBackgroundColorBottom("#00AEEF");
                    } else {
                        setProgressBarColor("#865FDD");
                        setBackgroundColorTop("#6B41C9");
                        setBackgroundColorBottom("#865FDD");
                    }

                    const userMeepId = query.get("userMeepId");
                    if (userMeepId) {
                        setBlockForm({ 
                            name: !!response.name,
                            document: !!response.document,
                            birthday: !!response.birthday,
                            phoneNumber: !!response.phoneNumber,
                         });
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    toast('A chave fornecida está inválida ou expirou.', 'error');
                    history.push("/public/saas/register", { type: location.state?.type });
                } else {
                    toast('Ocorreu um erro ao fazer a busca dos dados. Tente novamente.', 'error');
                }
            }
        })();
    }, [history, key, location.state?.type, prospectId, query, setEmail, setType, toast]);

    const handleHideEmail = (email: string) => {
        const parts = email.split("@");
        const username = parts[0];
        const hiddenUsername = username.substring(0, 3).padEnd(username.length, '*');
        return hiddenUsername + "@" + parts[1];
    };

    const handleSubmit = async (values: IProfileSchema, clearErrors: UseFormClearErrors<IProfileSchema>, setError: UseFormSetError<IProfileSchema>) => {
        try {
            setIsLoading(true);
            clearErrors("document");

            if (!blockForm?.document) {
                const alredyRegistered = await CheckDocumentUseCase(service, values.document.replace(/\./g, '').replace('-', ''));
                if (alredyRegistered.exists) {
                    setError("document", { message: `Esse CPF já está sendo usado pela conta ${handleHideEmail(alredyRegistered.email)}` });
                    return;
                }
            }

            await SaveProfileUseCase(service,
                {
                    ...values,
                    name: blockForm?.name ? prospect.name : values.name,
                    document: blockForm?.document ? prospect.document : values.document.replace(/\./g, '').replace('-', ''),
                    birthday: blockForm?.birthday ? format(new Date(prospect.birthday), 'dd/MM/yyyy') : values.birthday,
                    phoneNumber: blockForm?.phoneNumber ? prospect.phoneNumber : values.phoneNumber,
                },
                prospectId!, key!); 

            history.push(`/public/saas/local?prospectId=${prospectId}&key=${key}`, { type: type });
        } catch (err) {
            toast('Ocorreu um erro ao salvar os dados. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        defaultValues,
        blockForm,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit
    }
}