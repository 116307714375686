import { MenuItem, TextField } from '@material-ui/core'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IRankinProduct, IRakingProductFilter, IProductRankingData, sortByEnum, groupByEnum } from './IRankinProduct'
import styles from './RakingProducts.module.scss'
import './RakingProducts.css'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import SubItemsProducts from './subItemProducts/SubItemsProducts'


export interface IRakingProductsProps {
    //propertys
    defaultProductList?: IRankinProduct[],
    getProductList: ({ page, pageSize, sortField, sortOrientation, groupBy }: IGetDataParams, productGroupId? :string) => Promise<IProductRankingData>,
    isMobile?: boolean

}
const RakingProducts: FC<IRakingProductsProps> = ({ getProductList, defaultProductList, isMobile }) => {
    const [productList, setproductList] = useState<IRankinProduct[] | undefined>(defaultProductList);
    const [count, setCount] = useState(50);
    const [onLoading, setOnLoading] = useState(false);
    const [filterProductRanking, setFilterProductRanking] = useState<IRakingProductFilter>({
        sortBy: sortByEnum.total,
        groupBy: groupByEnum.None,
        sortOrientation: 'desc'
    })
    const { setOnError, clearContainerError } = useError();

    const [currentPage, setCurrentPage] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);
    // const { isVisible } = UseIsVisible(containerRef, true);

    const getData = useCallback(async (page?: number) => {
        try {
            clearContainerError(dashboardContainerEnum.RANKING_PRODUCTS);
            !page && setOnLoading(true);
            const response = await getProductList?.({ page, sortField: filterProductRanking.sortBy, groupBy: filterProductRanking.groupBy, sortOrientation: filterProductRanking.sortOrientation });

            if (page) {
                setproductList(prev => ([...(prev ?? []), ...response.records]));
                setCount(response.totalRecords);
            } else {
                setproductList(prev => ([...response.records]));
                setCount(response.totalRecords);
            }

            if (!page && !response.records.length) {
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.RANKING_PRODUCTS
                })
            }
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RANKING_PRODUCTS
            })
        } finally {
            setOnLoading(false);
        }
    }, [clearContainerError, filterProductRanking.groupBy, filterProductRanking.sortBy, filterProductRanking.sortOrientation, getProductList, setOnError]);

    useEffect(() => {
        getData(0);
    }, [getData]);

    const onChangeGroupBy = useCallback((value: unknown) => {
        const _value = value as groupByEnum;
        setFilterProductRanking(prev => ({ ...prev, groupBy: _value }))
        setCurrentPage(0);
    }, []);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    const onClickSort = useCallback((sortBy: sortByEnum) => {
        setFilterProductRanking(prev => ({ ...prev, sortBy: sortBy, sortOrientation: prev.sortOrientation === 'asc' ? 'desc' : 'asc' }))
        setCurrentPage(0);
    }, [],
    )

    return (
        (<div ref={containerRef} id={styles.RakingProducts}>

            <div className={styles.container}>
                {
                    <RankingList
                        // keyProp="id"
                        list={productList}
                        header={
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                                <TextField
                                    label={"Agrupar por"}
                                    name={"groupBy"}
                                    variant={'outlined'}
                                    size='small'
                                    onChange={(ev) => { onChangeGroupBy(ev.target.value) }}
                                    value={filterProductRanking.groupBy}
                                    select
                                    style={{ minWidth: 150 }}
                                    className={`${styles.groupSelector} rankingProductsGroupSelector`}
                                >
                                    <MenuItem value={groupByEnum.None}>Não Agrupar</MenuItem>
                                    <MenuItem value={groupByEnum.Name} >Primeiro Nome</MenuItem>
                                    <MenuItem value={groupByEnum.FullName} >Nome Completo</MenuItem>
                                </TextField>
                            </div>
                        }
                        getMore={getMore}
                        isLoading={onLoading}
                        count={count}
                        colunms={{
                            name: { title: "Nome", onClickSort: () => onClickSort(sortByEnum.ProductName) },
                            categoryName: { title: "Categoria", onClickSort: () => onClickSort(sortByEnum.CategoryName), hide: isMobile },
                            quantity: { title: isMobile ? "Qtd" : "Quantidade", onClickSort: () => onClickSort(sortByEnum.Quantity) },
                            total: { title: "Valor", onClickSort: () => onClickSort(sortByEnum.total) }
                        }}

                        accordeon={
                            filterProductRanking.groupBy ? 
                            ((productItem) => <SubItemsProducts groupBy={filterProductRanking.groupBy} getSubItems={getProductList} categoryId={productItem.id!} />) : 
                            undefined
                        }
                    />
                }
            </div>
        </div>)
    )
}
export default RakingProducts