import { FC } from 'react';
import { Tab } from './tab/Tab';
import styles from './TabFilter.module.scss';

interface TabFilterProps {
    items: any[];
    value: string;
    label: string;
    selectedItems: string[];
    onChange: (item: any) => void;
}

const TabFilter: FC<TabFilterProps> = ({ items, value, label, selectedItems, onChange }) => {
    const checkIfIsSelected = (item: string) => {
        return !!selectedItems.find(x => x.toString() === item.toString());
    }
    
    return (
        <div className={styles.tabFilter}>
            {items.map(item => (
                <Tab key={item[value]} item={item} label={label} selected={checkIfIsSelected(item[value])} onChange={onChange} />
            ))}
        </div>
    )
}

export { TabFilter };