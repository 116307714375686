import React, { FC } from "react";
import styles from "./FormItemContainer.module.scss";
export interface IFormItemContainerProps {
  label?: string;
  flex?: number;
  direction?: "row" | "column";
  row?: boolean;
  column?: boolean;
  justifyContent?:string,
  alignItems?:string,
  minWidth?: number;
  maxWidth?: number;
  className?: string;
  required?: boolean;
  style?: React.CSSProperties;
  padding?: string | number;
  labelMargin?: string | number;
  width?: string | number;
  labelSize?: string | number;
}

const FormItemContainer: FC<IFormItemContainerProps> = ({
  flex = 1,
  label,
  direction = "column",
  className,
  children,
  column,
  justifyContent,
  row,
  minWidth,
  maxWidth,
  required = false,
  alignItems,
  padding,
  labelMargin,
  width,
  labelSize,
}) => {
  return (
    <div
      id={styles.formItemContainer}
      style={{ flex: flex, flexDirection: "column", minWidth, maxWidth, justifyContent: justifyContent, alignItems: alignItems, width: width }}
      className={className}
    >
      {label && (
        <label className={styles.row} style={{ margin: labelMargin, fontSize: labelSize }}>
          <span>{label}{required && <span className={styles.requiredMessage}> *</span>}</span>
        </label>
      )}
      <div className={direction === "row" || row ? styles.row : styles.column} style={{ padding: padding }}>{children}</div>
    </div>
  );
};
export default FormItemContainer;
