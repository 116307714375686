export enum DeviceType {
    TOTEM = 2,
    POS = 4,
    CHECK = 5,
    TABLET = 9,
    TERMINAL = 11,
}

export const deviteTypeList = [
    {
        id: DeviceType.POS,
        name: 'POS',
    },
    {
        id: DeviceType.TOTEM,
        name: 'Totem',
    },
    {
        id: DeviceType.TABLET,
        name: 'Tablet',
    },
    {
        id: DeviceType.CHECK,
        name: 'MeepCheck',
    },
]
