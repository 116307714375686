import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import { create } from "zustand";

interface IProspectProps {
    email: string;
    setEmail: (email: string) => void;
    type: ProspectType;
    setType: (type: ProspectType) => void;
    prospectId: string;
    setProspectId: (id: string) => void;
    key: string;
    setKey: (key: string) => void;
};

const UseProspect = create<IProspectProps>(
    (set) => ({
        email: "",
        setEmail: (email) => {
            set(() => ({
                email: email,
            }));
        },
        type: ProspectType.Unknown,
        setType: (type) => {
            set(() => ({
                type: type,
            }));
        },
        prospectId: "",
        setProspectId: (id) => {
            set(() => ({
                prospectId: id,
            }));
        },
        key: "",
        setKey: (key) => {
            set(() => ({
                key: key,
            }));
        },
    })
);

export default UseProspect;
