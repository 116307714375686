import React, { FC, useCallback, useState } from 'react'
import styles from './LinkCardsActions.module.scss'
import { Button, Icon, Menu, MenuItem } from '@material-ui/core'
import UseLinkCardsActions from './UseLinkCardsActions';
import { ILinkCardItem } from 'modules/linkCards/presentation/interfaces/ILinkCardsList';
import Sidesheet from 'components/sidesheet/Sidesheet';
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback';
import DeleteValidation from '../../../../../../../components/deleteValidation/DeleteValidation';
import FeedBackAddLinkCards from '../addLinkCards/feedBackAddLinkCards/FeedBackAddLinkCards';

export interface IProductActionsProps {
    //propertys
    reloadList: () => void,
    selectedLinkCard: ILinkCardItem[],
    setSelectedLinkCards: React.Dispatch<React.SetStateAction<ILinkCardItem[]>>,
    setAllSelected: React.Dispatch<React.SetStateAction<boolean>>
    
}
const LinkCardsActions: FC<IProductActionsProps> = ({ reloadList, setAllSelected, selectedLinkCard, setSelectedLinkCards }) => {

    const [step, setStep] = useState(1);

    const {
        onClickAction,
        deleteProductsModalOpen,
        setDeleteProductsModalOpen,
        onDeleteLinkCards,
        isLoadingDelete,
        successDelete,
        deleteData,
    } = UseLinkCardsActions(reloadList, selectedLinkCard, setSelectedLinkCards);

    const [anchorEl, setAnchorEl] = React.useState<
        EventTarget & HTMLButtonElement
    >();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const onClickActionHandle = useCallback(
        (action: boolean) => {
            onClickAction?.();
            handleClose();
        },
        [onClickAction]
    );
    
  const onClose = useCallback(() => {
        if(step === 2) {
            reloadList();
            setSelectedLinkCards([])
        }
        setStep(1)
        setDeleteProductsModalOpen(false)
        setAllSelected(false)
  }, [reloadList, setAllSelected, setDeleteProductsModalOpen, setSelectedLinkCards, step])
 
  const continueOrSave = useCallback(async () => {  
      const res = await onDeleteLinkCards();
      if (successDelete.current) {  
        return setStep(step + 1);
      }
    
  }, [onDeleteLinkCards, step, successDelete])

  const closedOrDeleteButton = useCallback(() => {
    if (step === 1) {
      return "Excluír"
    } else {
      return "Fechar"
    }
  }, [step])

    const handleContent = useCallback(() => {
        let highlightText = `${selectedLinkCard.map(item => item.tag)}?`;
        let message = `Deseja realmente excluir estes vínculos de cartões`;
      
    
        return <DeleteValidation text={message} highlightText={highlightText} />
      }, [selectedLinkCard])

    return (
        !selectedLinkCard.length ? null : 
        <div id={styles.LinkCardsActions} >
            <div className={styles.container} >
                <div><b>{selectedLinkCard.length}</b> produto{selectedLinkCard.length > 1 && "s"} selecionado{selectedLinkCard.length > 1 && "s"}</div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"

                    className={styles.action}
                    onClick={handleClick}
                >
                    Ações
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    // keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() =>
                            onClickActionHandle(true)
                        }
                    >
                        <Icon>delete</Icon>Excluir
                    </MenuItem>
                </Menu>
            </div>
            <Sidesheet
                isLoading={isLoadingDelete}
                open={deleteProductsModalOpen}
                onClose={onClose}
                title={
                    <h2>
                    Excluir <b>vínculos em massa</b>
                    </h2>
                }
                content={handleContent()}
                currentStep={step}
                totalSteps={2}
                handleContinueButton={continueOrSave} 
                continueButton={closedOrDeleteButton()}
                feedback={
       
                    deleteData?.totalCardsWithError && deleteData?.totalCardsWithError > 0 ?
                          <FeedBackAddLinkCards deleteData={deleteData} text="Erro ao deletar cartões" helperText={`${deleteData?.totalDeletedCards} cartões deletados com sucesso!`}/>
                          :
                          <SidesheetFeedback text="Cartões deletados com sucesso!" success />
          
                  }
            />                   
        </div>
    )
}
export default LinkCardsActions