import axios from 'axios';
import {ILocalPrinterOrder, ILocalPrinterOrderItem} from '../../../modules/localPrinter/domain/dtos/ILocalPrinterOrder';
import {ILocalPrinterService} from '../../../modules/localPrinter/domain/interfaces/ILocalPrinterService';
import AnticorruptionLocalPrinter from './anticorruption/AnticorruptionLocalPrinter';

const LocalPrinterApi = (): ILocalPrinterService => {
  const sendToLocalPrinter = async (
    order: ILocalPrinterOrder,
    tagOrNumber: string,
    deviceName: string,
    printerServeIp?: string,
  ) => {
    if (printerServeIp) {
      return _sendToMultiplesLocalPrinter(printerServeIp, order, tagOrNumber, deviceName);
    }
  };

  const _sendToMultiplesLocalPrinter = async (
    printerServeIp: string,
    order: ILocalPrinterOrder,
    tagOrNumber: string,
    deviceName: string,
  ) => {
    const api = axios.create();
    const sendToPrinterOld = AnticorruptionLocalPrinter(api, printerServeIp);

    const printOrders = groupByPrinterName(order).map(_order => {
      return sendToPrinterOld.EnviarPedidoParaImpressora(_order, tagOrNumber, _order.printerName, deviceName);
    });

    try {
      await Promise.all(printOrders);
    } catch (error) {
      new Error('Falha ao enviar pedido para cozinha');
    }
  };

  return {sendToLocalPrinter};
};
export default LocalPrinterApi;

export const groupByPrinterName = (order: ILocalPrinterOrder) => {
  let _orderPrintersGroupedByPrinterName: {[key: string]: ILocalPrinterOrderItem[]} = {};

  order.orderItem.map(item => {
    if (item.printerName) {
      if (!_orderPrintersGroupedByPrinterName[item.printerName]) {
        _orderPrintersGroupedByPrinterName[item.printerName] = [];
      }
      _orderPrintersGroupedByPrinterName[item.printerName].push(item);
    }
  });

  const groupedOrder = Object.keys(_orderPrintersGroupedByPrinterName).map((printerName, index, array) => {
    return {
      id: order.id,
      orderItem: _orderPrintersGroupedByPrinterName[printerName] as ILocalPrinterOrderItem[],
      printerName,
      table: order.table,
    };
  });
  return groupedOrder;
};
