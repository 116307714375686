import styles from './TaxForm.module.scss';
import { FormHelperText, MenuItem, Switch, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Select } from '@mui/material';
import { ITaxForm } from '../interfaces/ITaxForm';
import { ITaxSelect } from '../interfaces/ITaxSelect';
import { GetCfopListUseCase } from 'modules/invoice/application/useCases/GetCfopListUseCase';
import { GetCofinsListUseCase } from 'modules/invoice/application/useCases/GetCofinsListUseCase';
import { GetIcmsListUseCase } from 'modules/invoice/application/useCases/GetIcmsListUseCase';
import { GetIcmsSourcesListUseCase } from 'modules/invoice/application/useCases/GetIcmsSourcesListUseCase';
import { GetIpiListUseCase } from 'modules/invoice/application/useCases/GetIpiListUseCase';
import { GetNcmListUseCase } from 'modules/invoice/application/useCases/GetNcmListUseCase';
import { GetPisListUseCase } from 'modules/invoice/application/useCases/GetPisListUseCase';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout/presentation/Layout';
import TabView from 'components/tab/TabView';
import { InputMoney } from 'modules/catalog/presentation/componentes/ui/InputMoney';
import { GetBaseModeCalculationIcmsUseCase } from 'modules/invoice/application/useCases/GetBaseModeCalculationIcmsUseCase';
import { GetBaseModeCalculationIcmsstUseCase } from 'modules/invoice/application/useCases/GetBaseModeCalculationIcmsstUseCase';
import { GetStatesUseCase } from 'modules/invoice/application/useCases/GetStatesUseCase';
import { CreateTaxUseCase } from 'modules/invoice/application/useCases/CreateTaxUseCase';
import { GetTaxByIdUseCase } from 'modules/invoice/application/useCases/GetTaxByIdUseCase';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'services/utils/debounce';

interface IError {
    ncm: {
        id: string;
    },
    cest: string;
    additionalInformation: string;
    cfop: {
        id: string;
    },
    receiverState: {
        id: string;
    },
    icms: {
        id: string;
        sourceId: string;
        taxSituationId: string;
        codigoBeneficioFiscal: string;
        modoBaseCalculoICMSST: string;
        aliquotaAplicavelDeCalculoDoCredito: string;
        percentualDaMargemDeValorAdicionadoDoICMSST: string;
        percentualDaReducaoDeBaseCalculoDoICMSST: string;
        baseCalculoICMSST: string;
        aliquotaICMSST: string;
        valorICMSST: string;
        creditoICMS: string;
        modoBaseCalculoICMS: string;
        percentualReducaoBaseCalculo: string;
        baseCalculoDoIcmsstRetido: string;
        aliquotaSuportadaPeloConsumidorFinal: string;
        valorDoICMSProprioDoSubstituto: string;
        valorDoIcmsstRetido: string;
        valorDoICMSEfetivo: string;
        valorDaBaseDeCalculoEfetiva: string;
        percentualDeReducaoDaBaseDeCalculoEfetiva: string;
        aliquotaDoICMSEfetiva: string;
        baseCalculoUFDestino: string;
        baseCalculoFundoCombatePobrezaUFDestino: string;
        percentualFundoCombatePobrezaUFDestino: string;
        aliquotaInternaUFDestino: string;
        aliquotaInterestadualUFEnvolvidas: string;
        percentualProvisorioDePartilhaInterestadual: string;
        valorFundoCombatePobrezaUFDestino: string;
        valorUFDestino: string;
        valorUFRemetente: string;
        baseCalculo: string;
        aliquota: string;
        valor: string;
        valorBaseCalculoFundoCombatePobreza: string;
        percentualDeICMSRelativoAoFundoDeCombatePobreza: string;
        percentualDoDiferimentoDoICMSRelativoAoFundoDeCombatePobreza: string;
        valorDoICMSRelativoAoFundoDeCombatePobreza: string;
        valorBaseCalculoFundoCombatePobrezaST: string;
        percentualFundoCombatePobrezaST: string;
        valorTotalFundoCombatePobrezaST: string;
        valorBaseDeCalculoFCPRetidoAnteriormentePorST: string;
        percentualFCPRetidoAnteriormentePorST: string;
        valorFCPRetidoPorST: string;
    },
    ipi: {
        taxSituationId: string;
        ehPorAlicota: string;
        codigoEnquadramentoLegal: string,
        aliquotaPercentual: string;
        valorPorUnidadeTributavel: string;
    },
    cofins: {
        taxSituationId: string;
        ehPorAlicota: string;
        percentualReducaoBaseCalculo: string;
        aliquotaPercentual: string;
        aliquotaValor: string;
    },
    pis: {
        taxSituationId: string;
        percentualReducaoBaseCalculo: string;
        aliquotaPercentual: string;
        aliquotaValor: string;
        ehPorAlicota: string;
    },
}

const service = InvoiceApi();

enum tabEnum {
    ICMS,
    IPI,
    COFINS,
    PIS
}

export const TaxForm = () => {
    const [tab, setTab] = useState(tabEnum.ICMS);
    const [loading, setLoading] = useState(false);
    const [preLoading, setPreLoading] = useState(true);
    const [errors, setErrors] = useState({
        ncm: {},
        cfop: {},
        receiverState: {},
        icms: {},
        ipi: {},
        cofins: {},
        pis: {},
    } as IError);
    const [form, setForm] = useState({
        ncm: {},
        cfop: {},
        receiverState: {},
        icms: {},
        ipi: {},
        cofins: {},
        pis: {},
    } as ITaxForm);
    const [ncmList, setNcmList] = useState<ITaxSelect[]>([]);
    const [cfopList, setCfopList] = useState<ITaxSelect[]>([]);
    const [icmsList, setIcmsList] = useState<ITaxSelect[]>([]);
    const [ipiList, setIpiList] = useState<ITaxSelect[]>([]);
    const [cofinsList, setCofinsList] = useState<ITaxSelect[]>([]);
    const [pisList, setPisList] = useState<ITaxSelect[]>([]);
    const [icmsSourcesList, setIcmsSourcesList] = useState<ITaxSelect[]>([]);
    const [icmsstBcList, setIcmsstBcList] = useState<ITaxSelect[]>([]);
    const [icmsBcList, setIcmsBcList] = useState<ITaxSelect[]>([]);
    const [statesList, setStatesList] = useState<ITaxSelect[]>([]);

    const { id } = useParams<{ id: string }>();

    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                if (currentLocal?.id) {
                    const response = await Promise.all([
                        GetNcmListUseCase(service, currentLocal.id),
                        GetCfopListUseCase(service, currentLocal.id),
                        GetIcmsListUseCase(service, currentLocal.id),
                        GetIpiListUseCase(service, currentLocal.id),
                        GetCofinsListUseCase(service, currentLocal.id),
                        GetPisListUseCase(service, currentLocal.id),
                        GetIcmsSourcesListUseCase(service, currentLocal.id),
                        GetBaseModeCalculationIcmsUseCase(service, currentLocal.id),
                        GetBaseModeCalculationIcmsstUseCase(service, currentLocal.id),
                        GetStatesUseCase(service, currentLocal.id),
                        id ? GetTaxByIdUseCase(service, currentLocal.id, id) : undefined
                    ]);
                    setNcmList(response[0]);
                    setCfopList(response[1]);
                    setIcmsList(response[2]);
                    setIpiList(response[3]);
                    setCofinsList(response[4]);
                    setPisList(response[5]);
                    setIcmsSourcesList(response[6]);
                    setIcmsBcList(response[7]);
                    setIcmsstBcList(response[8]);
                    setStatesList(response[9]);
                    if (response[10]) setForm(response[10]);
                }
            } catch {
                toast('Ocorreu um erro ao buscar as listas. Tente novamente.', 'error');
            } finally {
                setPreLoading(false);
            }
        })();
    }, [currentLocal?.id, id, toast]);

    const validate = () => {
        let isValid = true;
        setErrors({} as IError);

        if (!form.ncm?.id) {
            setErrors(prev => ({ ...prev, ncm: { id: 'Campo obrigatório' } }));
            isValid = false;
        }

        if (!form.cfop?.id) {
            setErrors(prev => ({ ...prev, cfop: { id: 'Campo obrigatório' } }));
            isValid = false;
        }

        if (!form.receiverState?.id) {
            setErrors(prev => ({ ...prev, receiverState: { id: 'Campo obrigatório' } }));
            isValid = false;
        }

        return isValid;
    }

    const handleSubmit = async () => {
        if (!validate()) return;

        try {
            setLoading(true);
            await CreateTaxUseCase(service, currentLocal!.id, form);
            history.push('/private/invoice');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (errors.icms && !Object.values(errors.icms).every(x => !x)) {
            setTab(tabEnum.ICMS);
            return;
        }
        if (errors.ipi && !Object.values(errors.ipi).every(x => !x)) {
            setTab(tabEnum.IPI);
            return;
        }
        if (errors.cofins && !Object.values(errors.cofins).every(x => !x)) {
            setTab(tabEnum.COFINS);
            return;
        }
        if (errors.pis && !Object.values(errors.pis).every(x => !x)) {
            setTab(tabEnum.PIS);
            return;
        }
    }, [errors]);

    const isSimpleCofins = () => {
        const id = form.cofins?.taxSituationId;
        if (!id) return true;
        return ![1, 2, 3, 5, 49, 99].includes(id);
    }

    const isSimpleIpi = () => {
        const id = form.ipi?.taxSituationId;
        if (!id) return true;
        return ![50, 99].includes(id);
    }

    if (preLoading !== false) {
        return (
            <Layout>
                <div className={styles.preLoading}>
                    <img src="/assets/icon/saas/loading.gif" alt="" />
                </div>
            </Layout>
        )
    }

    const handleChangeNcm = async (value: string) => {
        const callNcmList = debounce(async () => {
            const response = await GetNcmListUseCase(service, currentLocal!.id, value);
            setNcmList(response);
        }, 500);

        callNcmList();
    }

    const handleChangeCfop = async (value: string) => {
        const callCfopList = debounce(async () => {
            const response = await GetCfopListUseCase(service, currentLocal!.id, value);
            setCfopList(response);
        }, 500);

        callCfopList();
    }

    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.column} style={{ maxWidth: 540 }}>
                            <label className={styles.required}>NCM</label>
                            <Autocomplete
                                value={ncmList.find(x => x.id === form.ncm?.id)}
                                renderInput={(params) =>
                                    <TextField
                                        variant='outlined'
                                        onChange={ev => handleChangeNcm(ev.target.value)}
                                        {...params}
                                    />
                                }
                                size="small"
                                options={ncmList}
                                onChange={(event, value) => setForm(prev => ({ ...prev, ncm: { id: Number(value?.id) } }))}
                                getOptionLabel={(option) => option.name}
                            />
                            {
                                errors.ncm?.id &&
                                <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.ncm?.id}</FormHelperText>
                            }
                        </div>
                        <div className={styles.column}>
                            <label>CEST</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={form.cest}
                                onChange={(event) => setForm(prev => ({ ...prev, cest: event.target.value }))}
                                error={!!errors.cest}
                                helperText={errors.cest}
                            />
                        </div>

                        <div className={styles.column} style={{ maxWidth: 540 }}>
                            <label className={styles.required}>CFOP</label>
                            <Autocomplete
                                value={cfopList.find(x => x.id === form.cfop?.id)}
                                renderInput={(params) =>
                                    <TextField
                                        variant='outlined'
                                        onChange={ev => handleChangeCfop(ev.target.value)}
                                        {...params}
                                    />
                                }
                                size="small"
                                options={cfopList}
                                onChange={(event, value) => setForm(prev => ({ ...prev, cfop: { id: Number(value?.id) } }))}
                                getOptionLabel={(option) => option.name}
                            />
                            {
                                errors.cfop?.id &&
                                <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.cfop?.id}</FormHelperText>
                            }
                        </div>
                        <div className={styles.column}>
                            <label className={styles.required}>UF</label>
                            <Select
                                value={form.receiverState?.id}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(event) => setForm(prev => ({ ...prev, receiverState: { id: Number(event.target.value) } }))}
                            >
                                {
                                    statesList.map(x => (
                                        <MenuItem key={x.id} value={x.id}>
                                            {x.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                errors.receiverState?.id &&
                                <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.receiverState?.id}</FormHelperText>
                            }
                        </div>
                    </div>

                    <div className={styles.tabsContainer}>
                        <TabView
                            index={tab}
                            scrollButton="off"
                            tabs={[
                                { label: 'ICMS', value: tabEnum.ICMS },
                                { label: 'IPI', value: tabEnum.IPI },
                                { label: 'COFINS', value: tabEnum.COFINS },
                                { label: 'PIS', value: tabEnum.PIS },
                            ]}
                            onChangeTab={tab => setTab(tab.value)}
                        />
                    </div>
                    <div style={{ height: 20 }} />

                    {tab === tabEnum.ICMS && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <label>Origem</label>
                                    <Select
                                        value={form.icms?.sourceId}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, sourceId: Number(event.target.value) } }))}
                                    >
                                        {
                                            icmsSourcesList.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.icms?.sourceId &&
                                        <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.sourceId}</FormHelperText>
                                    }
                                </div>

                                <div className={styles.column}>
                                    <label>Situação tributária</label>
                                    <Select
                                        value={form.icms?.taxSituationId}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, taxSituationId: Number(event.target.value) } }))}
                                    >
                                        {
                                            icmsList.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.icms?.taxSituationId &&
                                        <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.taxSituationId}</FormHelperText>
                                    }
                                </div>

                                <div className={styles.column}>
                                    <label>Código do benefício fiscal</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={form.icms?.codigoBeneficioFiscal}
                                        onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, codigoBeneficioFiscal: event.target.value } }))}
                                        error={!!errors.icms?.codigoBeneficioFiscal}
                                        helperText={errors.icms?.codigoBeneficioFiscal}
                                    />
                                </div>
                            </div>

                            {
                                form.icms?.taxSituationId === 101 && (
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <label>Alíquota aplicável de cálculo do crédito</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                placeholder='%'
                                                type="number"
                                                value={form.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                                onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaAplicavelDeCalculoDoCredito: Number(event.target.value) } }))}
                                                error={!!errors.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                                helperText={errors.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [201, 202, 203, 30].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS ST.</label>
                                                <Select
                                                    value={form?.icms.modoBaseCalculoICMSST}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMSST: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsstBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMSST &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMSST}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de margem de valor adicionado ao ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaMargemDeValorAdicionadoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    helperText={errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BC do ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaReducaoDeBaseCalculoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    helperText={errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Alíquota do imposto do ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquotaICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquotaICMSST}
                                                    helperText={errors.icms?.aliquotaICMSST}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual de FCP retido por substituição tributária</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualFundoCombatePobrezaST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualFundoCombatePobrezaST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualFundoCombatePobrezaST}
                                                    helperText={errors.icms?.percentualFundoCombatePobrezaST}
                                                />
                                            </div>

                                        </div>
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [500, 60].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Alíquota suportada pelo consumidaor final</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquotaSuportadaPeloConsumidorFinal}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaSuportadaPeloConsumidorFinal: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquotaSuportadaPeloConsumidorFinal}
                                                    helperText={errors.icms?.aliquotaSuportadaPeloConsumidorFinal}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de redução da base de cálculo efetiva</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDeReducaoDaBaseDeCalculoEfetiva}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeReducaoDaBaseDeCalculoEfetiva: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDeReducaoDaBaseDeCalculoEfetiva}
                                                    helperText={errors.icms?.percentualDeReducaoDaBaseDeCalculoEfetiva}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Alíquota do ICMS efetiva</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquotaDoICMSEfetiva}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaDoICMSEfetiva: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquotaDoICMSEfetiva}
                                                    helperText={errors.icms?.aliquotaDoICMSEfetiva}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de FCP retido anteriormente por substituição tributária</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualFCPRetidoAnteriormentePorST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualFCPRetidoAnteriormentePorST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualFCPRetidoAnteriormentePorST}
                                                    helperText={errors.icms?.percentualFCPRetidoAnteriormentePorST}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [900, 10, 70, 90].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMS}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMS: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMS &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMS}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Alíquota de imposto</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquota}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquota: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquota}
                                                    helperText={errors.icms?.aliquota}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BC</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaReducaoDeBaseCalculoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    helperText={errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS ST</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMSST}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMSST: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsstBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMSST &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMSST}</FormHelperText>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual da margem de valor adicionado do ICMS ST</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaMargemDeValorAdicionadoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    helperText={errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BC do ICMS ST</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaReducaoDeBaseCalculoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    helperText={errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Alíquota do imposto ICMS ST</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquotaICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquotaICMSST}
                                                    helperText={errors.icms?.aliquotaICMSST}
                                                />
                                            </div>
                                            {
                                                [900, 70, 90].includes(form.icms?.taxSituationId) ?
                                                    <div className={styles.column}>
                                                        <label>Alíquota aplicável de cálculo de crédito</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            placeholder='%'
                                                            type="number"
                                                            value={form.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                                            onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaAplicavelDeCalculoDoCredito: Number(event.target.value) } }))}
                                                            error={!!errors.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                                            helperText={errors.icms?.aliquotaAplicavelDeCalculoDoCredito}
                                                        />
                                                    </div> :
                                                    <div className={styles.column}>
                                                        <label>Percentual de ICMS relativo ao fundo de compate à pobreza (FCP)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            placeholder='%'
                                                            type="number"
                                                            value={form.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                            onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                            error={!!errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                            helperText={errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                        {
                                            [900, 10].includes(form.icms?.taxSituationId) ?
                                                <div className={styles.row}>
                                                    <div className={styles.column}>
                                                        <label>Percentual de FCP retido por substituição tributária</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            placeholder='%'
                                                            type="number"
                                                            value={form.icms?.percentualFundoCombatePobrezaST}
                                                            onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualFundoCombatePobrezaST: Number(event.target.value) } }))}
                                                            error={!!errors.icms?.percentualFundoCombatePobrezaST}
                                                            helperText={errors.icms?.percentualFundoCombatePobrezaST}
                                                        />
                                                    </div>
                                                </div> :
                                                <div className={styles.row}>
                                                    <div className={styles.column}>
                                                        <label>Percentual de ICMS relativo ao fundo de combate à pobreza (FCP)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            placeholder='%'
                                                            type="number"
                                                            value={form.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                            onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                            error={!!errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                            helperText={errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                        />
                                                    </div>
                                                    <div className={styles.column}>
                                                        <label>Percentual de FCP retido por substituição tributária</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            placeholder='%'
                                                            type="number"
                                                            value={form.icms?.percentualFundoCombatePobrezaST}
                                                            onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualFundoCombatePobrezaST: Number(event.target.value) } }))}
                                                            error={!!errors.icms?.percentualFundoCombatePobrezaST}
                                                            helperText={errors.icms?.percentualFundoCombatePobrezaST}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [0].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMS}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMS: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMS &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMS}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Alíquota de imposto</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquota}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquota: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquota}
                                                    helperText={errors.icms?.aliquota}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column} style={{ flex: 0.5 }}>
                                                <label>Percentual de ICMS relativo ao fundo de combate à pobreza (FCP)</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    value={form.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    helperText={errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [20].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMS}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMS: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsstBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMS &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMS}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BNC do ICMS</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.percentualReducaoBaseCalculo}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualReducaoBaseCalculo: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualReducaoBaseCalculo}
                                                    helperText={errors.icms?.percentualReducaoBaseCalculo}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Alíquota do imposto</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.aliquota}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquota: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquota}
                                                    helperText={errors.icms?.aliquota}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de ICMS relativo ao fundo de combate à pobreza (FCP)</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    type="number"
                                                    value={form.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    helperText={errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [51].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMS}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMS: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMS &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMS}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de redução de BC</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.percentualReducaoBaseCalculo}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualReducaoBaseCalculo: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualReducaoBaseCalculo}
                                                    helperText={errors.icms?.percentualReducaoBaseCalculo}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Alíquota do imposto</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.aliquota}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquota: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquota}
                                                    helperText={errors.icms?.aliquota}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de ICMS relativo ao fundo de combate à pobreza (FCP)</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDeICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                    helperText={errors.icms?.percentualDeICMSRelativoAoFundoDeCombatePobreza}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual do diferimento do ICMS relativo ao fundo de combate à pobreza (FCP)</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.percentualDoDiferimentoDoICMSRelativoAoFundoDeCombatePobreza}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDoDiferimentoDoICMSRelativoAoFundoDeCombatePobreza: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDoDiferimentoDoICMSRelativoAoFundoDeCombatePobreza}
                                                    helperText={errors.icms?.percentualDoDiferimentoDoICMSRelativoAoFundoDeCombatePobreza}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                form.icms?.taxSituationId && [30].includes(form.icms?.taxSituationId) && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Modalidade de determinação da BC do ICMS ST.</label>
                                                <Select
                                                    value={form.icms?.modoBaseCalculoICMSST}
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, modoBaseCalculoICMSST: Number(event.target.value) } }))}
                                                >
                                                    {
                                                        icmsstBcList.map(x => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {
                                                    errors.icms?.modoBaseCalculoICMSST &&
                                                    <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icms?.modoBaseCalculoICMSST}</FormHelperText>
                                                }
                                            </div>
                                            <div className={styles.column}>
                                                <label>Percentual de margem de valor adicionado do ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder="%"
                                                    type="number"
                                                    value={form.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaMargemDeValorAdicionadoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                    helperText={errors.icms?.percentualDaMargemDeValorAdicionadoDoICMSST}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BC do ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    value={form.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualDaReducaoDeBaseCalculoDoICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                    helperText={errors.icms?.percentualDaReducaoDeBaseCalculoDoICMSST}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Alíquota do imposto do ICMS ST.</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    value={form.icms?.aliquotaICMSST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, aliquotaICMSST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.aliquotaICMSST}
                                                    helperText={errors.icms?.aliquotaICMSST}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.column} style={{ flex: 0.5 }}>
                                                <label>Percentual de FCP retido por substituição tributária</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='%'
                                                    value={form.icms?.percentualFundoCombatePobrezaST}
                                                    onChange={(event) => setForm(prev => ({ ...prev, icms: { ...prev.icms || {} as any, percentualFundoCombatePobrezaST: Number(event.target.value) } }))}
                                                    error={!!errors.icms?.percentualFundoCombatePobrezaST}
                                                    helperText={errors.icms?.percentualFundoCombatePobrezaST}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )}

                    {tab === tabEnum.IPI && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <label>Situação tributária</label>
                                    <Select
                                        value={form.ipi?.taxSituationId}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(event) => setForm(prev => ({ ...prev, ipi: { ...prev.ipi || {} as any, taxSituationId: Number(event.target.value) } }))}
                                    >
                                        {
                                            ipiList.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.ipi?.taxSituationId &&
                                        <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.ipi?.taxSituationId}</FormHelperText>
                                    }
                                </div>

                                <div className={styles.column}>
                                    <label>Código por enquadramento legal do IPI</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={form.ipi?.codigoEnquadramentoLegal}
                                        onChange={(event) => setForm(prev => ({ ...prev, ipi: { ...prev.ipi || {} as any, codigoEnquadramentoLegal: event.target.value } }))}
                                        error={!!errors.ipi?.codigoEnquadramentoLegal}
                                        helperText={errors.ipi?.codigoEnquadramentoLegal}
                                    />
                                </div>
                            </div>

                            {
                                !isSimpleIpi() && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.switchContainer}>
                                                <Switch
                                                    checked={form.ipi?.ehPorAlicota}
                                                    onChange={(_, checked) => setForm(prev => ({ ...prev, ipi: { ...prev.ipi || {} as any, ehPorAlicota: checked } }))}
                                                />
                                                <p>Cálculo do IPI é por alíquota?</p>
                                            </div>
                                        </div>

                                        <div className={styles.row}>
                                            {
                                                !form.ipi?.ehPorAlicota ?
                                                    <div className={styles.column} style={{ flex: 0.5 }}>
                                                        <label>Valor por unidade tributável</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            value={form.ipi?.valorPorUnidadeTributavel}
                                                            onChange={(event) => setForm(prev => ({ ...prev, ipi: { ...prev.ipi || {} as any, valorPorUnidadeTributavel: Number(event.target.value) } }))}
                                                            error={!!errors.ipi?.valorPorUnidadeTributavel}
                                                            helperText={errors.ipi?.valorPorUnidadeTributavel}
                                                        />
                                                    </div> :
                                                    <div className={styles.column} style={{ flex: 0.5 }}>
                                                        <label>Alíquota de IPI</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            value={form.ipi?.aliquotaPercentual}
                                                            onChange={(event) => setForm(prev => ({ ...prev, ipi: { ...prev.ipi || {} as any, aliquotaPercentual: Number(event.target.value) } }))}
                                                            error={!!errors.ipi?.aliquotaPercentual}
                                                            helperText={errors.ipi?.aliquotaPercentual}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )}

                    {tab === tabEnum.COFINS && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.column} style={{ flex: 0.5 }}>
                                    <label>Situação tributária</label>
                                    <Select
                                        value={form.cofins?.taxSituationId}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(event) => setForm(prev => ({ ...prev, cofins: { ...prev.cofins || {} as any, taxSituationId: Number(event.target.value) } }))}
                                    >
                                        {
                                            cofinsList.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.cofins?.taxSituationId &&
                                        <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.cofins?.taxSituationId}</FormHelperText>
                                    }
                                </div>
                            </div>

                            {
                                !isSimpleCofins() && (
                                    <>
                                        <div className={styles.row}>
                                            <div className={styles.switchContainer}>
                                                <Switch
                                                    checked={form.cofins?.ehPorAlicota}
                                                    onChange={(_, checked) => setForm(prev => ({ ...prev, cofins: { ...prev.cofins || {} as any, ehPorAlicota: checked } }))}
                                                />
                                                <p>Cálculo do COFINS é por alíquota?</p>
                                            </div>
                                        </div>

                                        <div className={styles.row}>
                                            {
                                                !form.cofins?.ehPorAlicota ?
                                                    <div className={styles.column} style={{ flex: 0.5 }}>
                                                        <InputMoney
                                                            onChange={(event) => setForm(prev => ({ ...prev, cofins: { ...prev.cofins || {} as any, aliquotaValor: Number(event.target.value) } }))}
                                                            name="value"
                                                            value={form.cofins?.aliquotaValor}
                                                            error={!!errors.cofins?.aliquotaValor}
                                                            label="Alíquota de COFINS em reais"
                                                            helperText={errors.cofins?.aliquotaValor}
                                                        />
                                                    </div> :
                                                    <>
                                                        <div className={styles.column}>
                                                            <label>Percentual da redução de BC</label>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder="%"
                                                                value={form.cofins?.percentualReducaoBaseCalculo}
                                                                onChange={(event) => setForm(prev => ({ ...prev, cofins: { ...prev.cofins || {} as any, percentualReducaoBaseCalculo: event.target.value ? Number(event.target.value) : undefined } }))}
                                                                error={!!errors.cofins?.percentualReducaoBaseCalculo}
                                                                helperText={errors.cofins?.percentualReducaoBaseCalculo}
                                                            />
                                                        </div>
                                                        <div className={styles.column}>
                                                            <label>Alíquota do COFINS</label>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder="%"
                                                                value={form.cofins?.aliquotaPercentual}
                                                                onChange={(event) => setForm(prev => ({ ...prev, cofins: { ...prev.cofins || {} as any, aliquotaPercentual: event.target.value ? Number(event.target.value) : undefined } }))}
                                                                error={!!errors.cofins?.aliquotaPercentual}
                                                                helperText={errors.cofins?.aliquotaPercentual}
                                                            />
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )}

                    {tab === tabEnum.PIS && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.column} style={{ flex: 0.5 }}>
                                    <label>Situação tributária</label>
                                    <Select
                                        value={form.pis?.taxSituationId}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(event) => setForm(prev => ({ ...prev, pis: { ...prev.pis || {} as any, taxSituationId: Number(event.target.value) } }))}
                                    >
                                        {
                                            pisList.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.pis?.taxSituationId &&
                                        <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.pis?.taxSituationId}</FormHelperText>
                                    }
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.switchContainer}>
                                    <Switch
                                        checked={form.pis?.ehPorAlicota}
                                        onChange={(_, checked) => setForm(prev => ({ ...prev, pis: { ...prev.pis || {} as any, ehPorAlicota: checked } }))}
                                    />
                                    <p>Cálculo do PIS é por alíquota?</p>
                                </div>
                            </div>

                            <div className={styles.row}>
                                {
                                    !form.pis?.ehPorAlicota ?
                                        <div className={styles.column} style={{ flex: 0.5 }}>
                                            <InputMoney
                                                onChange={(event) => setForm(prev => ({ ...prev, pis: { ...prev.pis || {} as any, aliquotaValor: event.target.value ? Number(event.target.value) : undefined } }))}
                                                name="value"
                                                value={form.pis?.aliquotaValor}
                                                error={!!errors.pis?.aliquotaValor}
                                                label="Alíquota do PIS em reais"
                                                helperText={errors.pis?.aliquotaValor}
                                            />
                                        </div> :
                                        <>
                                            <div className={styles.column}>
                                                <label>Percentual da redução de BC</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    value={form.pis?.percentualReducaoBaseCalculo}
                                                    placeholder='%'
                                                    onChange={(event) => setForm(prev => ({ ...prev, pis: { ...prev.pis || {} as any, percentualReducaoBaseCalculo: event.target.value ? Number(event.target.value) : undefined } }))}
                                                    error={!!errors.pis?.percentualReducaoBaseCalculo}
                                                    helperText={errors.pis?.percentualReducaoBaseCalculo}
                                                />
                                            </div>
                                            <div className={styles.column}>
                                                <label>Alíquota do PIS</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    value={form.pis?.aliquotaPercentual}
                                                    placeholder='%'
                                                    onChange={(event) => setForm(prev => ({ ...prev, pis: { ...prev.pis || {} as any, aliquotaPercentual: event.target.value ? Number(event.target.value) : undefined } }))}
                                                    error={!!errors.pis?.aliquotaPercentual}
                                                    helperText={errors.pis?.aliquotaPercentual}
                                                />
                                            </div>
                                        </>
                                }
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={() => history.push('/private/invoice')}>
                        Cancelar
                    </button>
                    <button onClick={handleSubmit} disabled={loading}>
                        {
                            loading ? 'Enviando...' : 'Concluir'
                        }
                    </button>
                </div>
            </div>
        </Layout>
    )
}
