import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { PermutationPage } from "modules/permutation/presentation/pages/PermutationPage";

const Permutation: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Financeiro",
      },
      {
        label: "Financeiro",
        title: "Permuta",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <PermutationPage />
    </Layout>
  );
};
export default Permutation;
