import { useUi } from "contexts/userInterface/UserInterfaceContext";
import ClosePendingsUseCase from "modules/events/application/useCases/events/ClosePendingsUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";
import {
  ICloseEvent,
  ICloseSystemTypesResum,
  IPendingItems,
  PendingSystemTypeEnum,
} from "../../ICloseEvent";

export interface IClosePedingFormErros {
  observation?: string;
  historicoAssociacaoId?: string;
  hasError?: boolean;
}


const eventService = EventsApi();
const UseSystemTypeResume = (event: ICloseEvent, refreshEvent: () => Promise<void>) => {
  const { showLoading, hideLoading, toast } = useUi();
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState<IClosePedingFormErros[]>([]);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [pendingType, setPendingType] = useState<PendingSystemTypeEnum | null>(
    null
  );
  const { currentLocal } = useLocal();

  const [closeEventSystemResum, setCloseEventSystemResum] =
    useState<ICloseSystemTypesResum>();

  const [pendingItems, setPendingCard] = useState<IPendingItems[]>();

  const onChangePedingCard = useCallback((id: string, text: string) => {
    const pendingItem: IPendingItems[] | undefined = pendingItems?.map(item => {
      if (item.historicoAssociacaoId === id) {
        return { ...item, observation: text }
      } else {
        return item
      }
    })
    setPendingCard(pendingItem)
  }, [pendingItems]);

  useEffect(() => {
    if (modalTitle) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [modalTitle]);

  useEffect(() => {
    switch (pendingType) {
      case PendingSystemTypeEnum.posPaidCashless:
        setModalTitle("Pendências pós-pago comanda individual");
        setPendingCard(event.eventSystemTypes?.posPaidCashless?.pendingItems);
        break;
      case PendingSystemTypeEnum.posPaidShared:
        setModalTitle("Pendências pós-pago compartilhada");
        setPendingCard(event.eventSystemTypes?.posPaidShared?.pendingItems);
        break;
      case PendingSystemTypeEnum.prePaidCashless:
        setModalTitle("Pendências pré-pago cashless");
        setPendingCard(event.eventSystemTypes?.prePaidCashless?.pendingItems);
        break;
      default:
        break;
    }
  }, [event.eventSystemTypes?.posPaidCashless, event.eventSystemTypes?.posPaidShared, event.eventSystemTypes?.prePaidCashless, pendingType]);

  const handlePendingType = useCallback((type: PendingSystemTypeEnum) => {
    setPendingType(type);
  }, []);

  const handleCloseModal = useCallback(() => {
    setPendingType(null);
    setModalTitle("");
  }, []);

  const validate = useCallback(() => {
    const findErrors: IClosePedingFormErros[] = []
    pendingItems?.map(item => {
      if (!item.observation) {
        findErrors.push({ observation: "Campo observação obrigatório", historicoAssociacaoId: item.historicoAssociacaoId, hasError: true });
      } else {
        return {}
      }
    })
    const newValue = findErrors.filter(item => item.hasError)
    setErrors(newValue);
    return !newValue.find(item => item.hasError);
  }, [pendingItems]);

  const handleClose = useCallback(async () => {
    if (pendingItems?.length) {
      try {
        showLoading();
        const response = await Promise.allSettled(
          pendingItems.map((item) =>
            ClosePendingsUseCase(eventService, {
              eventId: event.eventId,
              historicoAssociacaoId: item.historicoAssociacaoId,
              localId: currentLocal ? currentLocal.id : '',
              motivo: item.observation,
              number: item.number
            })
          )
        );
        response.forEach((item: any) => {
          if (item.status === "rejected") {
            toast(`Pendência: ${JSON.parse(item.reason.config.data).number} - ${item.reason.response.data.message}`, "error");
          } else {
            toast(`Pendência regularizada com sucesso.`, "success");
          }
        })
        refreshEvent();
      } catch (error) {
      } finally {
        handleCloseModal();
        hideLoading();
      }
    }
  }, [currentLocal, event.eventId, handleCloseModal, hideLoading, pendingItems, refreshEvent, showLoading, toast]);

  const handleClosePending = useCallback(() => {
    if (validate()) {
      handleClose();
    }
  }, [handleClose, validate]);

  return {
    openModal,
    handleCloseModal,
    modalTitle,
    handlePendingType,
    handleClosePending,
    closeEventSystemResum,
    pendingType,
    pendingItems,
    onChangePedingCard, errors
  };
};

export default UseSystemTypeResume;
