import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { useEffect } from "react";
import DashboardPage from "./DashboardPage";
function Dashboard() {
  return (
    <Layout>
      <DashboardPage />
    </Layout>
  );
}

export default Dashboard;
