import React, { FC, useState } from 'react';
import styles from './Tooltip.module.scss';
import MUITooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { Icon, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

interface ITooltipProps {
  title: string;
  text: string | string[];
  icon?: string;
  buttonClassName?: string;
};

export const Tooltip: FC<ITooltipProps> = ({ text, title, icon = "help", buttonClassName }) => {

  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!open)
  }

  return (
    <MUITooltip 
      arrow
      open={open}
      onClose={toggleTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      // PopperProps={{
      //   disablePortal: true,
      // }}
      TransitionComponent={Zoom} 
      placement="top-start"
      classes={{ popper: styles.popup }}
      title={
        <div>
          <IconButton size='small' onClick={toggleTooltip}>
            <CloseIcon/>  
          </IconButton>
          <h2>{title}</h2>
          <p>
            {typeof text === 'string' ? text : (
              text.map(x => (
                <span className={styles.lineRow}>
                  {x.indexOf(':') > -1 ? (
                    <>
                      <strong>{x.split(':')[0]}: </strong>
                      {(x.split(':')?.[1] || x) + ' '}
                    </>
                  ) : {x}}
                </span>
              ))
            )}
          </p>
        </div>
      }
    >
      <IconButton size="small" onClick={toggleTooltip} className={buttonClassName || "tooltip-button"}>
        <Icon>{icon}</Icon>
      </IconButton>
    </MUITooltip>
  );
};