import { ISmartPrinterItem } from "../smartPrinterIForm/interfaces/ISmartPrinterForm";
import { ISmartPrinterFormErros } from "./interface/ISmartPrinterFormErros";

export const validate = (formData: ISmartPrinterItem, setErrors: React.Dispatch<React.SetStateAction<ISmartPrinterFormErros>>, setIndex: React.Dispatch<React.SetStateAction<number>>) => {
  let _errors: ISmartPrinterFormErros = {};
  let hasError = false;

  if (!formData.name) {
    _errors = { ..._errors, name: "Campo nome obrigatório" };
    hasError = true;
  }
  if (!formData.printerId) {
    _errors = { ..._errors, printerId: "Necessário adicionar uma impressora" };
    hasError = true;
  }
  setErrors(_errors);

  if (_errors.name){
    setIndex(0);
  } else if(_errors.printerId){
    setIndex(1);
  }

  return !hasError;
};
