import Layout from "components/layout/presentation/Layout";
import React, { FC } from "react";
import ReportPage from "./ReportPage";

const Relatorio: FC = () => {
  return (
    <Layout>
      <ReportPage />
    </Layout>
  );
};
export default Relatorio;
