import { Icon, Switch } from '@material-ui/core';
import styles from './Posts.module.scss';
import Layout from 'components/layout/presentation/Layout';
import { CreatePostForm } from './CreatePostForm/CreatePostForm';
import { UsePosts } from './UsePosts';
import { Pagination, Skeleton } from '@mui/material';
import { Post } from './Post/Post';
import { IPost } from '../../interfaces/IPosts';
import { DeleteModal } from './DeleteModal/DeleteModal';

export const Posts = () => {
    const {
        currentPage,
        data,
        loading,
        modalVisible,
        selectedPost,
        deleteModalOpened,
        deleteLoading,
        includeFuturesPosts, 
        setIncludeFuturesPosts,
        setDeleteModalOpened,
        getData,
        setSelectedPost,
        setModalVisible,
        handleSubmit,
        handleDeletePost
    } = UsePosts();

    return (
        <Layout>
            <div className={styles.container}>
                <h1>Publicações - Aplicativo Meep</h1>
                <header>
                    <button type="button" className={styles.button} onClick={() => setModalVisible(true)}>
                        Nova publicação <Icon>add_circle</Icon>
                    </button>

                    <Switch checked={includeFuturesPosts} onChange={() => setIncludeFuturesPosts(!includeFuturesPosts)} />
                    <p>Exibir publicações futuras</p>
                </header>

                <h2>Minhas <b>publicações</b></h2>

                {
                    !loading && !data?.data?.length &&
                    <div className={styles.noContent}>
                        <img src="/assets/icon/social/no-posts.svg" alt="" />
                        <p>Ainda <b>não há</b> publicações criadas.</p>
                    </div>
                }

                {
                    loading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={305} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={305} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={305} style={{ marginBottom: 8, borderRadius: 8 }} />
                        </> :
                        <>
                            {
                                data?.data?.map(x => (
                                    <Post
                                        post={x}
                                        localImageUrl={data.localImageUrl}
                                        localName={data.localName}
                                        onEdit={(post: IPost) => {
                                            setSelectedPost(post);
                                            setModalVisible(true);
                                        }}
                                        onDelete={(post: IPost) => {
                                            setSelectedPost(post);
                                            setDeleteModalOpened(true);
                                        }}
                                    />
                                ))
                            }
                        </>
                }

                <Pagination
                    variant="outlined"
                    shape="rounded"
                    style={{ alignSelf: 'flex-end' }}
                    sx={{
                        '.MuiPaginationItem-root': {
                            borderColor: '#BDBDBD',
                            color: '#001537',
                        },
                        '.Mui-selected': {
                            fontWeight: 'bold',
                            borderColor: '#001537',
                        },
                        '.MuiPagination-ul': {
                            justifyContent: 'flex-end'
                        }
                    }}
                    className={styles.contentPagination}
                    page={currentPage}
                    count={data?.totalPages}
                    onChange={(ev, page) => getData(page)}
                />
            </div>

            <DeleteModal
                loading={deleteLoading}
                open={deleteModalOpened}
                onDelete={handleDeletePost}
                onClose={() => {
                    setDeleteModalOpened(false);
                    setSelectedPost(undefined);
                }}
            />

            <CreatePostForm
                defaultValues={selectedPost}
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false)
                    setSelectedPost(undefined);
                }}
                onSubmit={handleSubmit}
            />
        </Layout>
    )
}