import { IGetPrintQueueParams } from "modules/printQueue/domain/dto/IGetPermutationAccountParams";
import { IPrintQueueFilter } from "modules/printQueue/presentation/components/printQueueFilter/interfaces/IPrintQueueFilter";


const ParseFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IPrintQueueFilter) => {

  const request: IGetPrintQueueParams = {

    ownerId: localId,
    page: pagination?.page,
    pageSize: pagination?.pageSize,
    status: filter?.status,
    deviceId: filter?.deviceId,
    printerName: filter?.printer,
    orderValue: filter?.value,
    tableId: filter?.tableId,
    customer: filter?.customer,
    operatorId: filter?.operatorId,
    orderId: filter?.orderId

  };

  return request;
};

export default ParseFilterToRequestUseCase

