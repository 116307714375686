import Sidesheet from "components/sidesheet/Sidesheet";
import { useCallback, useEffect, useMemo, useState } from "react";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { IPostPageWizardItems, IPostNewWizard } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";
import UseCadastrarTutorialPage from "../../hooks/UseCadastrarTutorialPage";
import NewAndEditTutorial from "./newAndEditTutorial/NewAndEditTutorial";
import { ITutorialFormErrors, TutorialValidation } from "./newAndEditTutorial/validade/TutorialValidation";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useMenuContext } from "components/layout/presentation/menu/context/MenuContext";
import { ArrowForwardIosRounded, LinkOutlined } from "@material-ui/icons";
import styles from "./newAndEditTutorial/NewAndEditTutorial.module.scss";
import { UseWizard } from "components/wizard/presentation/UseWizard";
import { IGetWizardParams } from "components/wizard/domain/dto/IGetWizardParams";

export interface ISidesheetTutoriaisProps {
  open: "isNew" | "isEditing" | "isDeleting" | null;
  onCloseSideSheet: () => void;
  item?: ITutoriaisRecords;
}

const SidesheetTutoriais = ({ open, onCloseSideSheet, item }: ISidesheetTutoriaisProps) => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState<IPostNewWizard>({} as IPostNewWizard);
  const [errors, setErrors] = useState<ITutorialFormErrors>({} as ITutorialFormErrors);
  const [stepsValues, setStepsValues] = useState<IPostPageWizardItems[]>([]);
  const [justAddFullTutorialUrl, setJustAddFullTutorialUrl] = useState("");

  const getWizardParams = {} as IGetWizardParams;

  const { toast } = useUi();
  const { isLoading, postNewTutorial, putEditTutorial, deleteTutorial } = UseCadastrarTutorialPage();
  const { getWizard, wizard, isLoading: isLoadingTutorialInfo } = UseWizard();

  const { menuListContext } = useMenuContext();

  const menuOptions = useMemo(() => {
    const result: {id: string, title: string }[] = [];
  
    menuListContext.forEach(item => {
      if (item.title === "Favoritos") return;
      if (item.submenus && item.submenus.length > 0) {
        item.submenus.forEach((it) => {
          result.push({ id: it.id, title: it.title });
        });
      } else {
        result.push({ id: item.id, title: item.title });
      }
    });
  
    return result;
  }, [menuListContext]); 

  useEffect(() => {
    if (item && open === "isEditing") {
      const menuId = menuOptions.find((option) => option.title === item.menuTitle)?.id ?? "";
      getWizard(menuId, {...getWizardParams, IsPageWizardCrud: true });
    }
  }, [open, item, menuOptions, getWizard]);  

  useEffect(() => {
    if (item && open === "isEditing") {
      setValues({
        ...values,
        Title: item.title,
        Description: item.description,
        ImagePath: item.imagePath,
        FullTutorialUrl: item.fullTutorialUrl,
        MenuId: menuOptions.find((option) => option.title === item.menuTitle)?.id ?? "",
      });
      if (wizard && wizard.pageWizardItems) {
        setValues({
          ...values,
          PageWizardItems: wizard.pageWizardItems.map((it) => ({
            Title: it.title,
            Description: it.description,
            ImagePath: it.imagePath,
            Order: 1,
          })),
        });
        setStepsValues(wizard.pageWizardItems.map((it) => ({
          Title: it.title,
          Description: it.description,
          ImagePath: it.imagePath,
          Order: 1,
        })));
      }
    } else {
      setValues({} as IPostNewWizard);
    }
  }, [open, item, menuOptions, wizard]);  

  const handleContinueNewAndEdit = useCallback(async () => {
    if (TutorialValidation(values, setErrors)) {
      if (!values.PageWizardItems) {
        return toast("Ao menos 01 passo é requerido.", "error");
      }
      let res;
      if (open === "isNew") {        
        res = await postNewTutorial(values);
      }
      if (open === "isEditing" && item) {
        res = await putEditTutorial({...values, Id: item.id});
      }
      if (res === "ok") {
        setJustAddFullTutorialUrl(values.FullTutorialUrl);
        setValues({} as IPostNewWizard);
        setStepsValues([]);
        return setStep(step + 1);
      }
    }
  }, [step, open, item, values, postNewTutorial, putEditTutorial, toast]);

  const handleContinueDelete = useCallback(async () => {
    if (item) {
      const res = await deleteTutorial(item.id);
      if (res === "ok") {
        setValues({} as IPostNewWizard);
        setStepsValues([]);
        return setStep(step + 1);
      }
    }
  }, [step, item, deleteTutorial]);

  return (
    <>
      {(open === "isNew" || open === "isEditing") && (
        <Sidesheet
          isLoading={isLoading || (open === "isEditing" && isLoadingTutorialInfo)}
          open={open}
          onClose={onCloseSideSheet}
          title={
            <h2>
              {open === "isNew" ? "Novo" : "Editar"} <b>tutorial</b>
            </h2>
          }
          content={
            <NewAndEditTutorial
              values={values}
              setValues={setValues}
              stepsValues={stepsValues}
              setStepsValues={setStepsValues}
              errors={errors}
              setErrors={setErrors}
              menuOptions={menuOptions}
            />
          }
          currentStep={step}
          totalSteps={2}
          continueButton="Concluir"
          handleContinueButton={handleContinueNewAndEdit}
          cancelButton
          feedback={
            <SidesheetFeedback
              text={
                open === "isNew"
                  ? "Tutorial publicado com sucesso!!"
                  : "Tutorial editado com sucesso!"
              }
              success
              helperText={
                <div className={styles.successButton}>
                  <div className={styles.startItens}>
                    <div
                      className={styles.icon}
                      onClick={() => navigator.clipboard.writeText(justAddFullTutorialUrl)}
                    >
                      <LinkOutlined />
                    </div>
                    <span>Ver publicação</span>
                  </div>
                  <div
                    className={styles.icon}
                    onClick={() => window.open(justAddFullTutorialUrl, "_blank")}
                  >
                    <ArrowForwardIosRounded style={{ width: "18px" }} />
                  </div>
                </div>
              }
            />
          }
          notTotalHeight={step < 2}
        />
      )}
      {open === "isDeleting" && (
        <Sidesheet
          isLoading={isLoading}
          open={open}
          onClose={onCloseSideSheet}
          title={
            <h2>
              Excluir <b>tutorial</b>
            </h2>
          }
          content={<SidesheetFeedback text="Essa ação não poderá ser desfeita!" />}
          currentStep={step}
          totalSteps={2}
          continueButton="Excluir"
          handleContinueButton={handleContinueDelete}
          cancelButton
          feedback={
            <SidesheetFeedback text="Tutorial excluído com sucesso!" success />
          }
        />
      )}
    </>
  );
};

export default SidesheetTutoriais;
