import React from "react";
import { PieChart, Pie, Cell,  Tooltip } from "recharts";
import { IPosPaid } from "../../interfaces/IPosPaid";
import styles from "./PosPaidPieChart.module.scss";
import { CreditCard } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { CircularProgress } from "@material-ui/core";
import { TooltipInfo } from "components/tooltipInfo/TooltipInfo";

interface IPosPaidPieChartProps {
  posPaidData: IPosPaid | undefined;
  isLoading: boolean | undefined;
}

const PosPaidPieChart: React.FC<IPosPaidPieChartProps> = ({ isLoading, posPaidData }) => {
  const { isMobile } = useUi(); 

  const COLORS = ["#32008E", "#CAAEFF", "#001537"];

  const pieData = [
    { name: "Comandas fechadas", value: posPaidData?.totalClosedCommands },
    { name: "Comandas abertas", value: posPaidData?.totalOpenCommands },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div id={styles.PosPaidPieChart}>
      <div className={styles.top}>
        <p>Pós-pago</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile}>
        <div className={styles.containerleft}>
          <div className={styles.topContainerLeft}>
            <div>
              <p className={styles.totalCommands}>Total de comandas  <TooltipInfo text={"Total de comandas ativadas de acordo com o filtro selecionado"} /></p>
              <span>{posPaidData?.totalCommands ?? 0}</span>         
            </div>
            <span className={styles.discount}>{moneyMaskNumber(posPaidData?.commandsValue)}</span>        
          </div>
          <div className={styles.bottomContainerLeft}>
            <div>
              <p>Taxa de serviço</p>
              <span>{moneyMaskNumber(posPaidData?.serviceFeeValue)}</span>
            </div>
            <div>
              <p>Desconto</p>
              <span>{moneyMaskNumber(posPaidData?.discountValue)}</span>
            </div>
          </div>
        </div>
        {!isMobile && <div className={styles.separator}></div>}
        <div className={styles.containerRight}>
          <PieChart width={170} height={170}>
            <Pie
              data={pieData}
              cx={85}
              cy={85}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value: number) => `Quantidade: ${value}`} />
          </PieChart>
          <div className={styles.contentPosPaid}>
            <div className={styles.containerClosed}>
              <p className={styles.topPaid}>
                {" "}
                <CreditCard className={styles.paidIcon} />
                Comandas fechadas: 
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <p className={[styles.mid, styles.fullText].join(" ")}>{posPaidData?.totalClosedCommands ?? 0}  <TooltipInfo text={"Comanda que já foram desativadas e pagas"} /></p>
                )}
              </p>

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Com taxa de serviço: </span>                  
                  <span>
                    <span>{posPaidData?.totalClosedCommandsWithServiceFee} </span> 
                    ({moneyMaskNumber(posPaidData?.closedCommandsWithServiceFeeValue)})
                  </span>                 
                </p>
              )}

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Taxa de serviço:</span>                  
                  <span>({moneyMaskNumber(posPaidData?.closedServiceFeeValue)})</span>
                </p>
              )}

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Sem taxa de serviço paga: </span>
                  <span>
                    <span>{posPaidData?.totalClosedCommandsWithoutServiceFee} </span> 
                    ({moneyMaskNumber(posPaidData?.closedCommandsWithoutServiceFeeValue)})
                  </span>
                </p>
              )}

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>
                    Fechada manualmente: 
                    <TooltipInfo text={"Comandas com consumo, fechadas manualmente sem o pagamento e/ou pagamento parcial. Para comandas com pagamento parcial o valor representa o total da comanda e não somente o valor pago parcialmente."} />
                  </span>
                  
                  <span className={styles.closedCommandsManuallyValue}>
                    <span>{posPaidData?.totalClosedCommandsManually} </span>
                    ({moneyMaskNumber(posPaidData?.closedCommandsManuallyValue)})
                  </span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Sem consumo: </span>
                  <span>{posPaidData?.totalClosedCommandsWithoutConsumption}</span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Total de pessoas: </span>
                  <span>{posPaidData?.totalTable ?? 0}</span>
                </p>
              )}
            </div>
            <div className={styles.containerOpened}>
              <p className={styles.topNotPaid}>                
                <CreditCard className={styles.notePaidIcon} />
                Comandas abertas:
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <p className={styles.mid}>{posPaidData?.totalOpenCommands ?? 0} <TooltipInfo text={"Comandas onde os clientes ainda estão no estabelecimento ou comandas que já foram pagas, mas ainda não foram desativadas."} /></p>
                )}
              </p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Com consumo: </span>
                  <span>
                    <span>{posPaidData?.totalOpenCommandsWithConsumption} </span>
                    ({moneyMaskNumber(posPaidData?.openCommandsWithConsumptionValue)})
                  </span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Sem consumo: </span>
                  <span>({moneyMaskNumber(posPaidData?.totalOpenCommandsWithoutConsumption)})</span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Totalmente paga: </span>
                  <span>
                    <span>{posPaidData?.totalOpenCommandsPaidValue} </span>
                    ({moneyMaskNumber(posPaidData?.openCommandsPaidValue)})
                  </span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                 <span> Taxa de serviço: </span>
                  <span>({moneyMaskNumber(posPaidData?.openServiceFeeValue)})</span>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Parcialmente paga: </span>
                  <span>
                    <span>{posPaidData?.totalOpenCommandsPartiallyPaidValue} </span>
                    ({moneyMaskNumber(posPaidData?.openCommandsPartiallyPaidValue)})
                  </span>
                </p>
              )}              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosPaidPieChart;
