import {  Skeleton } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './FinancialInvoicesList.module.scss'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IFinancialInvoicesList } from '../../interfaces/IFinancialInvoicesList';
import { IFinancialInvoicesColumnsConfig } from './interfaces/IColumnsConfig';
import FinancialInvoicesHeader from './components/financialInvoicesListContainer/FinancialInvoicesHeader';
import FinancialInvoicesItem from './components/financialInvoicesListContainer/FinancialInvoicesItem';
import { FinancialInvoicesItemMobile } from './components/financialInvoicesItemMobile/FinancialInvoicesItemMobile';

export interface IFinancialInvoicesListProps {
    financialInvoicesList: IFinancialInvoicesList[] | undefined
    isLoading: boolean | undefined;
}

export const FinancialInvoicesList: React.FC<IFinancialInvoicesListProps> = ({ financialInvoicesList, isLoading}) => {
    const { dimensions } = UseDimension();
    const { isMobile } = useUi();

    const hasBoleto = financialInvoicesList?.some(item =>
        !item.quitada && !item.debitoEmTransacoes && item.linkBoleto
    );

    const hasNotaFiscal = financialInvoicesList?.some(item => item.notaFiscal);

    const [colunms, setColunms] = useState<IFinancialInvoicesColumnsConfig[]>([
        {
            property: "dataVencimento",
            title: "Vencimento",
        },
        {
            property: "descricao",
            title: "Descrição",
        },
        {
            property: "valor",
            title: "Valor",
        },
        {
            property: "status",
            title: "Status",
        },   
           
    ]);

    const columnsLimit = useCallback((_columns: IFinancialInvoicesColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 5
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])

    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, financialInvoicesList])

    const List = useMemo(
        () => (
            <div className={styles.list}>
                {
                    !isMobile &&
                    <FinancialInvoicesHeader
                        columns={colunms}
                        hasBoleto={hasBoleto}
                        hasNotaFiscal={hasNotaFiscal}
                    />

                }
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                        financialInvoicesList?.map((item: IFinancialInvoicesList, index) => {
                            return (                     
                                isMobile ?
                                    <FinancialInvoicesItemMobile
                                        key={index}
                                        userItemData={item}    
                                        hasBoleto={hasBoleto}
                                        hasNotaFiscal={hasNotaFiscal}
                                        financialInvoicesList={financialInvoicesList}
                                    />
                                    :       
                                    <FinancialInvoicesItem
                                        key={index}
                                        financialInvoicesList={financialInvoicesList}
                                        userItemData={item}
                                        columns={colunms}
                                        hasBoleto={hasBoleto}
                                        hasNotaFiscal={hasNotaFiscal}
                                    />
                            );
                        })
                )}
            </div>
        ),
        [isMobile, colunms, hasBoleto, hasNotaFiscal, isLoading, financialInvoicesList]
    ); 

    return (
        <div id={styles.DiscountCouponsList}>
            {
                !isLoading && !financialInvoicesList?.length ? (
                    <div className={styles.emptyList}>
                        <img src="/assets/img/empty-box.png" alt="" />
                        <span>
                            Seu estabelecimento ainda <b>não possui nenhuma</b> permuta configurada.
                        </span>
                    </div>
                ) : (
                    List
                )
            }
        </div>
    );    
}
