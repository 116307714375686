import Utils from "services/utils/Utils";
import { IDetailsFormErrors, IDiscountsFormErrors, IDiscountsValidation } from "./IGuestListFormErros";

export const validate = (useDiscountLimit: IDiscountsValidation, formData: IDiscountsFormErrors, detailsForm: IDetailsFormErrors, setErrors: React.Dispatch<React.SetStateAction<IDiscountsFormErrors>>) => {
  let _errors: IDiscountsFormErrors = {};
  let hasError = false;

  if (!useDiscountLimit.discountLimit && useDiscountLimit.useDiscountLimit) {
    _errors = { ..._errors, discountLimit: "Insira um limite de descontos maior que zero!" };
    hasError = true;
  }

  if (formData.validUntil &&
    detailsForm.closingDateTime &&
    !(Utils.dateValidation(formData.validUntil, "<", detailsForm.closingDateTime) || Utils.dateValidation(formData.validUntil, "=", detailsForm.closingDateTime))) {
    _errors = { ..._errors, validUntil: "A data de expiração de descontos deve ser menor ou igual a data de fechamento da lista de convidados." };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};
