import { IAntecipateFormErrors, IAtecipateFormItem } from "../interfaces/IAntecipateForm";

export const validate = (formData: IAtecipateFormItem, setErrors: React.Dispatch<React.SetStateAction<IAntecipateFormErrors>>) => {
  let _errors: IAntecipateFormErrors = {};
  let hasError = false;

  if (!formData.conta) {
    _errors = { ..._errors, conta: "Campo conta bancária é obrigatório" };
    hasError = true;
  }

  if (!formData.valor) {
    _errors = { ..._errors, valor: "O Campo valor da antecipação é obrigatório" };
    hasError = true;
  }

  if (!formData.senha) {
    _errors = { ..._errors, senha: "O Campo senha é obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
