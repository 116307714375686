import React, { FC, PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react'
import Item, { IActionButtons, IMenuButton } from './Item/Item'
import styles from './List.module.scss'
import { IListItem } from './IList'
import { Icon, IconButton } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd'

export interface ColumnConfigs {
    [key: string]: {
        text?: string
        sort?: boolean
        hide?: boolean
        hideTitle?: boolean
        width?: number
        onClickSort?: () => void
        renderRow?: (value: string | boolean | number) => ReactElement
    }
}

export interface ListProps<T extends IListItem> {
    /** Array of items to List */
    list: T[],
    onClick?: (value: T) => void
    formatTitle?: (property: string) => string
    onClickSort?: (property: string) => void
    renderItem?: (item: T, ReactElement: ReactElement) => ReactElement
    selectable?: boolean;
    multipleSelectable?: boolean;
    columnsConfigs?: ColumnConfigs;
    pagination?: IPagination;
    actionsRender?: (item: T) => ReactElement;
    onChangePage?: (page: number, size: number) => void;
    exportButton?: boolean;
    actionButtons?: IActionButtons<T>[],
    enableDnd?: boolean,
    onChangeDnd?: (list: T[]) => void,
    menuButtons?: IMenuButton<T>[]
}

export interface IListValues {
    hide?: boolean,
    selected?: boolean
}

export interface IPagination {
    page: number
    pageSize: number
    count: number
}


const List = <T extends IListItem,>({ list, formatTitle, enableDnd, onClickSort, renderItem, columnsConfigs, pagination, onChangePage, actionsRender, actionButtons, onChangeDnd, menuButtons }: ListProps<T>) => {
    const [listValues, setListValues] = useState<(T & IListValues)[]>(list);

    useEffect(() => {
        setListValues([...list.map(item => ({ ...item, actionsRender: actionsRender?.(item) }))])
    }, [list, actionsRender]);


    const reorder = useCallback((list: T[], startIndex: number, endIndex: number) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }, []);

    const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newList = reorder(
            list,
            result.source.index,
            result.destination.index
        );
        setListValues(newList);
        onChangeDnd?.(newList);

    }, [list, onChangeDnd, reorder])


    return (
        <div id={styles.List}>
            <div className={styles.header} >
                {
                    enableDnd && <Icon className={styles.dragIcon}>drag_handle</Icon>
                }
                {!!listValues.length && Object.keys(columnsConfigs ?? listValues[0]).map((key, index) => {
                    if (columnsConfigs?.hasOwnProperty("imageUrl") && key === "imageUrl" && !columnsConfigs?.[key].hide) {
                        return (<span
                            className={styles.title}
                            style={{ maxWidth: columnsConfigs[key].width ? columnsConfigs[key].width : 76 }}
                        >
                            {!columnsConfigs[key].hideTitle ? columnsConfigs[key].text : ""}
                        </span>
                        )
                    }
                    if (columnsConfigs?.hasOwnProperty(key) && key !== "id" && !columnsConfigs?.[key].hide) {
                        return (
                            <span
                                className={styles.title}
                                onClick={() => {
                                    onClickSort && onClickSort(key);
                                    columnsConfigs?.[key].onClickSort?.();
                                }}
                                style={{ maxWidth: columnsConfigs[key].width }}
                            >
                                {!columnsConfigs[key].hideTitle ? columnsConfigs[key].text : ""}
                                {columnsConfigs[key].sort && <Icon fontSize={"small"}>swap_vert</Icon>}
                            </span>
                        );
                    } else {
                        return null
                    }
                })}
                {
                    actionButtons && <div className={styles.title}>Ações</div>
                }
            </div>
            {

                enableDnd ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(providedDroppable: DroppableProvided, snapshot) => (
                                <div
                                    ref={providedDroppable.innerRef}
                                    {...providedDroppable.droppableProps}
                                    className={styles.list}
                                >
                                    {listValues?.map((item, index) => (

                                        <Draggable draggableId={item.id + index} index={index}
                                        >
                                            {(provided: DraggableProvided, snapshot) => (
                                                <div
                                                    key={item.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Item
                                                        style={snapshot.isDragging ? { backgroundColor: '#eee', boxShadow: "3px 3px 6px -3px #0005", transition: "all 0.5s" } : {}}
                                                        item={item}
                                                        key={item.id}
                                                        enableDnd={enableDnd}
                                                        columnsConfigs={columnsConfigs}
                                                        actionButtons={actionButtons}
                                                        menuButtons={menuButtons}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {providedDroppable.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext >

                    :
                    <div className={styles.list} >
                        {listValues?.map((item) => (
                            renderItem ? renderItem(item,
                                <Item
                                    item={item}
                                    columnsConfigs={columnsConfigs}
                                    actionButtons={actionButtons}
                                />) :
                                <Item
                                    item={item}
                                    columnsConfigs={columnsConfigs}
                                    actionButtons={actionButtons}
                                    menuButtons={menuButtons}
                                />
                        ))}
                    </div>
            }

            {
                pagination && <div id={styles.pagination}>
                    <select value={pagination.pageSize} onChange={(ev) => { onChangePage?.(pagination.page, Number(ev.target.value)) }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                    <Pagination count={pagination?.count} page={pagination?.page} onChange={(ev, page) => { onChangePage && onChangePage(page, pagination.pageSize) }} />
                </div>
            }
        </div >
    )
}

export default List
