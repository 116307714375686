import { IChildren } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";
import { IChildrenErros } from "modules/permutation/presentation/interfaces/IPermutationFormErros";
import CPF from "services/utils/CPF";


export const validateUseForm = (formData: IChildren, setErrors: React.Dispatch<React.SetStateAction<IChildrenErros>>) => {
  let _errors: IChildrenErros = {};
  let hasError = false;


  if (!formData.name.trim()) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.document) {
    _errors = { ..._errors, document: "Campo obrigatório" };
    hasError = true;
  }

  if (formData.document && !CPF.isValid(formData.document)) {
    _errors = { ..._errors, document: "CPF inválido" };
    hasError = true;
  }


  setErrors(_errors);
  return !hasError;
};
