import { Cancel } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Slider,
    Typography,
} from '@mui/material';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { createDefferedPromise, ICreateDefferedPromise } from 'modules/events/presentation/utils/DefferedPromise';
import { IServiceScheduleForm } from 'modules/schedule/presentation/components/serviceForm/interfaces/IServiceForm';
import React, { FC, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { getCroppedImg } from './CanvasUtils';

interface CropEasyProps {


}

export interface CropEasyHandler {
    getCrop: (imageUrl: string) => Promise<string>
}

const CropEasy: React.ForwardRefRenderFunction<CropEasyHandler, CropEasyProps> = (props, ref) => {

    const { showLoading, hideLoading } = useUi();

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
    const [imageUrl, setImageUrl] = useState<string>()
    const [visible, setVisible] = useState(false)
    const promiseCreateDeffered = useRef<ICreateDefferedPromise<string>>(createDefferedPromise())


    const getCrop = useCallback((imageUrl: string): Promise<string> => {
        promiseCreateDeffered.current = createDefferedPromise()
        setImageUrl(imageUrl);
        setVisible(true)
        return promiseCreateDeffered.current.promise
    },[]);
    
    useImperativeHandle(
        ref,
        () => ({
            getCrop
        })
    )

    const cropComplete = useCallback(
        (croppedArea: Area, croppedAreaPixels: Area) => {
            setCroppedAreaPixels(croppedAreaPixels);
        },
        []
    );

    const close = useCallback(() => {
        setVisible(false)
        promiseCreateDeffered.current.rejecter(new Error("Operação cancelada pelo usuário"))
    },[])

    const cropImage = async () => {
        showLoading();
        try {
            if (imageUrl) {
                const croppedImage = await getCroppedImg(
                    imageUrl,
                    croppedAreaPixels,
                    rotation
                );
                if(croppedImage){
                    promiseCreateDeffered.current.resolver(croppedImage)
                } else {
                    promiseCreateDeffered.current.rejecter(new Error("Falha ao cortar imagem"))
                }
            } 
        } catch (error) {
            promiseCreateDeffered.current.rejecter(new Error("Falha ao cortar imagem"))

        } finally {
            setVisible(false)
            hideLoading();
        }

    };
    return (
        <Dialog open={visible}>
            <DialogContent
                dividers
                sx={{
                    background: '#333',
                    position: 'relative',
                    height: 400,
                    width: 'auto',
                    minWidth: { sm: 500 },
                }}
            >
                <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={16 / 9}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={cropComplete}
                />
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
                <Box sx={{ width: '100%', mb: 1 }}>
                    <Box>
                        <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            valueLabelFormat={zoomPercent}
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e, zoom: any) => setZoom(zoom)}
                        />
                    </Box>
                    <Box>
                        <Typography>Rotation: {rotation + '°'}</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            min={0}
                            max={360}
                            value={rotation}
                            onChange={(e, rotation: any) => setRotation(rotation)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={close}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<CropIcon />}
                        onClick={cropImage}
                    >
                        Cortar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default React.forwardRef(CropEasy);

const zoomPercent = (value: number) => {
    return `${Math.round(value * 100)}%`;
};