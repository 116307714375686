import React, { FC, MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from 'react'

const UseIsVisible = (ref: RefObject<HTMLDivElement>, once = true) => {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting || !once) {
                setIntersecting(entry.isIntersecting)
            }
        }
    ), [once])

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [observer, ref])

    return { isVisible: isIntersecting }
}



export default UseIsVisible