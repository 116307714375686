import Layout from 'components/layout/presentation/Layout'
import SaasChangePlanPage from 'modules/saasChangePlan/presentation/SaasChangePlanPage'

export const SaasChangePlan = () => {
    return (
        <Layout disabledMenu>
            <SaasChangePlanPage />
        </Layout>
    )
}
