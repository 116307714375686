import { ICrmEvent } from "./ICrmEventData";

export enum CampaignChannel {
    unknown = 0,
    email = 1,
    phone = 2,
    whatsapp = 3,
    signup = 4,
    push = 5
}
export enum CampaignTemplate {
    event = 1,
    info = 2
}
export enum CampaignStatus {
    Draft = 0,
    Running = 1,
    Sent = 2,
}
// export interface ICampaignEvent {
//     id: string;
//     name: string;
//     startDate?: string;
//     endDate?: string;
//     url?: string;
//     bannerUrl?: string;
//     thumbnailUrl?: string;
// }
export interface ICampaignEmail {
    event?: ICrmEvent;
    from?: string;
    replyTo: string;
    subject: string;
    // greeting: string,
    messageBody: string;
}
export interface ICampaignWhatsapp {
    event?: ICrmEvent;
    message: string;
}
export interface ICampaignFormValue {
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    email?: ICampaignEmail;
    whatsapp?: ICampaignWhatsapp;
}



export interface ICampaignValues {
    id: string,
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    status: CampaignStatus;
    totalCustomers: number;

    createdAt?: string;
    eventId?: string;
    eventName?: string;
    // addresseeQuantity: number;
    sendedAt: string,
    subject: string,
    replyTo: string,
    messageBody: string,
    totalCustomersPhoneNumber: number,
    totalCustomersEmail: number
}


export interface ICampaignItem {
    id: string,
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    status: CampaignStatus;
    totalCustomers: number;

    createdAt?: string;
    eventId?: string;
    eventName?: string;
    // addresseeQuantity: number;
    sendedAt: string,
}

export interface ICampaignData {


    currentPage: number,
    totalPages: number,
    totalRegisters: number,
    data: ICampaignItem[]
}
