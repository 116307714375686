import { FC, useCallback, useEffect, useState } from "react";
import styles from "./SimplePosConfig.module.scss";
import { Autocomplete, TextField } from "@mui/material";
import { DeleteOutlined } from "@material-ui/icons";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import { UseSimplePosConfig } from "./UseSimplePosConfig";
import { IPutEditSimplePosRequest } from "modules/salesPos/domain/dto/IPutEditSimplePosRequest";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";

export interface ISimplePosConfigProps {
    posId: string;
    terminalId: string;
    terminalsList?: IDeviceTerminalItemResponse[];
    getPosList: () => void;
}

const SimplePosConfig: FC<ISimplePosConfigProps> = ({
    posId,
    terminalId,
    terminalsList,
    getPosList
}) => {
    const { isLoading, updateSimplePosConfig, delelteSimplePos } = UseSimplePosConfig();

    const [terminal, setTerminal] = useState<IDeviceTerminalItemResponse | null>(null);
    const [openDeletePosSidesheet, setOpenDeletePosSidesheet] = useState(false);
    const [deletePosStep, setDeletePosStep] = useState(1);

    const onChangeTerminalHandler = useCallback(async (value: IDeviceTerminalItemResponse | null) => {
        const configValues: IPutEditSimplePosRequest = {
            id: posId,
            deviceId: value?.device.id,
        };

        const response = await updateSimplePosConfig(posId, configValues);
        const terminalSelected = terminalsList?.find((terminal) => terminal.device.id === response.deviceId);

        if (terminalSelected) {
            setTerminal(terminalSelected);
        }
    }, [posId, terminalsList, updateSimplePosConfig]);

    useEffect(() => {
        if (terminalId) {
            const terminalSelected = terminalsList?.find((terminal) => terminal.device.id === terminalId);
            setTerminal(terminalSelected ?? null);
        }
    }, [terminalId, terminalsList]);

    const handleDeletePos = useCallback(async () => {
        const res = await delelteSimplePos(posId);
        if (res === "ok") {
            setDeletePosStep(deletePosStep + 1);
        }
    }, [delelteSimplePos, deletePosStep, posId]);

    return (
        <>
            <div id={styles.SimplePosConfig} >
                <div className={styles.config}>
                    <div className={styles.terminals}>
                        <span className={styles.configTitle}>Terminal Online</span>
                        <label htmlFor="deviceId" className={styles.required}>Terminal vinculado</label>
                        <Autocomplete
                            value={terminal}
                            onChange={(event: any, newValue: IDeviceTerminalItemResponse | null) => {
                                onChangeTerminalHandler(newValue);
                            }}
                            options={terminalsList ?? []}
                            getOptionLabel={(option) => option.device.name}
                            noOptionsText="Nenhuma opção encontrada"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="deviceId"
                                    placeholder="Selecione um terminal"
                                    InputProps={{
                                        style: { padding: "0 20px 0 10px" },
                                        ...params.InputProps,
                                    }}
                                    variant="outlined"
                                />
                            )}
                            fullWidth
                        />
                        {!terminal && <div className={styles.operationAlert}>
                            <p>Nenhuma terminal selecionado</p>
                        </div>}
                    </div>
                </div>
                <div className={styles.deletePos} onClick={() => setOpenDeletePosSidesheet(true)}>
                    <DeleteOutlined />
                    <span>Remover máquina</span>
                </div>
            </div>

            <Sidesheet
                isLoading={isLoading}
                open={openDeletePosSidesheet}
                onClose={() => {
                    setOpenDeletePosSidesheet(false);
                    setDeletePosStep(1);
                    getPosList();
                }}
                title={<h2>Remover <b>máquina</b></h2>}
                content={
                    <SidesheetFeedback
                        text={
                            <span style={{ lineHeight: "150%" }}>
                                Deseja realmente remover a máquina? <br />Você não poderá mais alterar as configurações.
                            </span>
                        }
                    />
                }
                currentStep={deletePosStep}
                totalSteps={2}
                continueButton="Remover"
                handleContinueButton={handleDeletePos}
                customizedCancelButton="Voltar"
                cancelButton
                feedback={
                    <SidesheetFeedback text="Máquina excluída com sucesso!" success />
                }
            />
        </>
    )
}

export default SimplePosConfig;