import ITicketsReportService from "modules/ticketsReport/domain/interfaces/ITicketsReportService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetTicketsValidationReportUseCase = async (service: ITicketsReportService, params: IUseCaseParams): Promise<void> => {
  const filterRequest = makeFilterRequest(params);
  await service.getTicketValidationReport(filterRequest);
}

export default GetTicketsValidationReportUseCase
