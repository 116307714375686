import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { getRolesByFunctionUseCase } from "modules/cargos/application/useCases/getRolesByFunctionUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetSubscriptionUseCase } from "modules/plans/application/GetSubscriptionUseCase";
import { SubscribeFeatureUseCase } from "modules/plans/application/SubscribeFeatureUseCase";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFeatures } from "modules/plans/presentation/interfaces/IFeatures";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CargosApi from "services/api/cargos/CargosApi";
import PlansApi from "services/api/plans/PlansApi";

const service = PlansApi()
const roleService = CargosApi();

export const UsePurchasePush = () => {
  const { getAdditionalFeatures } = usePlans();
  const [features, setFeatures] = useState<IFeatures[]>([]);
  const [pushFeature, setPushFeature] = useState<IFeatures>();
  const [defaultPushFeature, setDefaultPushFeature] = useState<IFeatures>();
  const [loading, setLoading] = useState(true);
  const { currentLocal } = useLocal();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionModalOpened, setPermissionModalOpened] = useState(false);
  const { toast } = useUi();

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        try {
          const response = await getRolesByFunctionUseCase(roleService, currentLocal.id);
          setHasPermission(!!response.find(x => x.name.toLocaleLowerCase() === 'acessos' || x.name.toLocaleLowerCase() === 'admin'));
        } finally { }
      }
    })()
  }, [currentLocal]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const subscription = await GetSubscriptionUseCase(service, currentLocal!.id);
        const response = await getAdditionalFeatures([FeatureTypeEnum.PUSH]);
        const pushFeature = subscription?.additionalFeatures?.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH);
        setFeatures(pushFeature ? response.filter(x => x.index! >= (pushFeature.index || 0)) : response);

        setDefaultPushFeature(pushFeature);
        setPushFeature(pushFeature);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentLocal, getAdditionalFeatures]);

  const subscribeFeature = async () => {
    try {
      if (!hasPermission) {
        setPermissionModalOpened(true);
      } else {
        setSaving(true);
        const getToken = localStorage.getItem("@token");
        const token = getToken && JSON.parse(getToken);
        const user = JSON.parse(token.user);

        await SubscribeFeatureUseCase(service, user.cpf, currentLocal!.id, pushFeature!.id);
        history.push('/private/automatizacao');
      }
    } catch {
      toast('Ocorreu um erro ao contratar o plano', 'error');
      setSaving(false);
    }
  }

  return {
    features,
    pushFeature,
    setPushFeature,
    defaultPushFeature,
    loading,
    subscribeFeature,
    saving,
    permissionModalOpened,
    setPermissionModalOpened
  }
}
