import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetDiscountCouponsReportUseCase from "../../application/useCases/GetDiscountCouponsReportUseCase";
import { IDiscountCouponFilter } from "../components/discountCouponFilter/interfaces/IDiscountCouponFilter";
import { IDiscountCouponsReportFilter } from "../components/discountCouponFilter/interfaces/IDiscountCouponReportFilter";
import { IDiscountCouponsReport } from "../interfaces/IDiscountCouponsReport";

export interface IParamsClientDiscountCouponsReport {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: IDiscountCouponsReportFilter
}

const UseDiscountCouponsReport = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentLocal } = useLocal();

  const [couponsReport, setCouponsReport] = useState<IDiscountCouponsReport>()


  const getDiscountCouponsReport = useCallback(
    async (params: IParamsClientDiscountCouponsReport) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetDiscountCouponsReportUseCase(serviceDiscountCoupons,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );

          setCouponsReport(response);
          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );

  return {
    isLoading,
    getDiscountCouponsReport,
    couponsReport,
    setCouponsReport
  };
};

export default UseDiscountCouponsReport;
