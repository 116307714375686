import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './CouponsReportPage.module.scss'
import UseDiscountCouponsReport, { IParamsClientDiscountCouponsReport } from '../../hooks/UseDiscountCouponsReport'
import { ArrowForwardIos } from '@mui/icons-material';
import Button from 'components/ui/Button/Button';
import { Drawer, MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import { IDiscountCouponsReportFilter } from '../../components/discountCouponFilter/interfaces/IDiscountCouponReportFilter';
import { moneyMaskNumber } from 'services/utils/Money';
import UseDimension from 'components/dimension/UseDimension';
import { IDiscountCouponsReportColumnsConfig } from './interfaces/IColumnsConfig';
import { IReportItem } from '../../interfaces/IDiscountCouponsReport';
import DiscountCouponsReportHeader from './components/discountCouponContainer/DiscountCouponsReportHeader';
import DiscountCouponsReportItem from './components/discountCouponContainer/DiscountCouponsReportItem';
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { ReportCouponFilter } from './components/discountCouponFilter/ReportCouponFilter';
import UseSession from '../../hooks/UseSession ';
import UseReportFilterDeviceList from '../../hooks/UseReportFilterDeviceList';
import { CouponsList } from './components/discountCouponContainer/components/couponsList/CouponsList';

const CouponsReportPage: FC = () => {
    const {
        isLoading,
        couponsReport,
        getDiscountCouponsReport
    } = UseDiscountCouponsReport();
    const { getSessionItem, sessionItem, isSessionLoading } = UseSession();
    const { getReportDeviceList, reportDeviceList } = UseReportFilterDeviceList();


    const [sideSheetCoupons, setSideSheetCoupons] = useState(false)
    const [params, setParams] = useState<IParamsClientDiscountCouponsReport>({
        sort: { orientation: 'asc', type: 'customerName' },
        filter: {
            cashierId: sessionItem?.items[0].id
        }
    });

    const openCoupons = useCallback(() => {
        setSideSheetCoupons(true)
    }, [])
    const closeCoupons = useCallback(() => {
        setSideSheetCoupons(false)
    }, [])

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IDiscountCouponsReportFilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }));
    }, [setParams]);


    useEffect(() => {
        getSessionItem()
    }, [getSessionItem])

    useEffect(() => {
        getReportDeviceList()
    }, [getReportDeviceList])

    useEffect(() => {
        if (params.filter?.cashierId || params.filter?.createdStartDate)
            getDiscountCouponsReport({
                ...params,
            })
    }, [getDiscountCouponsReport, params])


    useEffect(() => {
        if (sessionItem?.items.length) {
            setParams(prev => ({
                ...prev,
                filter: {
                    cashierId: sessionItem.items[0].id
                }
            }))
        }
    }, [sessionItem])


    const { dimensions } = UseDimension();

    const [colunms, setColunms] = useState<IDiscountCouponsReportColumnsConfig[]>([
        {
            property: "customerName",
            title: "Nome",
        },
        {
            property: "customerCPF",
            title: "CPF",
        },
        {
            property: "couponUsedAt",
            title: "Data/hora de utilização",

        },
        {
            property: "code",
            title: "Código",
        },
        {
            property: "value",
            title: "Desconto",

        },

    ]);

    const columnsLimit = useCallback((_columns: IDiscountCouponsReportColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 5
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])

    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, couponsReport])

    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...params.pagination, page: page - 1, pageSize });
        },
        [onChangePaginationHandle, params.pagination]);



    const List = useMemo(
        () => (
            <div className={styles.list}>
                <DiscountCouponsReportHeader
                    columns={colunms}
                    params={params}
                    setParams={setParams}
                />
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    couponsReport?.records?.map((item: IReportItem, index) => {
                        return (
                            <DiscountCouponsReportItem
                                key={index}
                                userItemData={item}
                                columns={colunms}
                                // params={params}
                                discountCouponsReport={couponsReport}
                            />
                        );
                    })
                )}
            </div>
        ),
        [colunms, couponsReport, isLoading, params]
    );


    const countPage = useMemo(() => Math.ceil((couponsReport?.totalRecords ?? 1) / (params.pagination?.pageSize ?? 20)), [couponsReport?.totalRecords, params.pagination?.pageSize])



    return (
        <>
            <div id={styles.CouponsReportPage}>

                <ReportCouponFilter
                    onSubmit={onChangeFilterHandle}
                    sessionItem={sessionItem}
                    reportDeviceList={reportDeviceList}
                />

                <div className={styles.resumeTotal}>
                    <div>
                        <p>Valor total de descontos aplicados</p>
                        <strong>{moneyMaskNumber(couponsReport?.totalDiscounts)}</strong>
                    </div>
                    <div>
                        <p>Quantidade de cupons</p>
                        <strong>{couponsReport?.codes.length ?? 0}</strong>
                        <Button
                            variant="text"
                            color="inherit"
                            endIcon={<ArrowForwardIos className={styles.arrowIcon} fontSize="small" />}
                            fullWidth={false}
                            className={styles.viewCouponsButton}
                            onClick={openCoupons}
                        >
                            Ver todos cupons
                        </Button>
                    </div>
                </div>

                {
                    !isLoading && !couponsReport?.records?.length
                        ? <ErrorMessage type="warning" />
                        : (
                            <>
                                {List}

                                <div className={styles.containerPagination}>
                                    <div className={styles.boxMultiplePage}>
                                        <span>Exibir</span>
                                        <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                            <MenuItem className={styles.textPage} value={10}>10</MenuItem>
                                            <MenuItem className={styles.textPage} value={20}>20</MenuItem>
                                            <MenuItem className={styles.textPage} value={50}>50</MenuItem>
                                        </TextField>
                                    </div>
                                    <Pagination
                                        variant="outlined"
                                        className={styles.paginationNumber}
                                        shape="rounded"
                                        count={countPage}
                                        sx={{
                                            '.MuiPaginationItem-root': {
                                                borderColor: '#BDBDBD',
                                                color: '#001537',
                                            },
                                            '.Mui-selected': {
                                                fontWeight: 'bold',
                                                borderColor: '#001537',
                                            },
                                        }}
                                        onChange={(_, page) => onChangePageHandle?.(page, params.pagination?.pageSize)}
                                        page={((params.pagination?.page ? params.pagination?.page + 1 : 1))}
                                    />
                                </div>
                            </>
                        )
                }

            </div>
            <Drawer open={sideSheetCoupons} anchor="right" onClose={closeCoupons}>
                <div id={styles.drawer}>
                    <CouponsList
                        closeCoupons={closeCoupons}
                        discountCouponsReport={couponsReport}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default CouponsReportPage;