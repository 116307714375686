import { useHistory } from "react-router-dom";
import { EditOutlined } from "@material-ui/icons";
import { Divider, IconButton } from "@mui/material";
import styles from "../ConfigCieloSmart.module.scss";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";

export interface IPosListProps {
  data?: IDeviceTerminalItemResponse[];
}

const PosList = ({ data }: IPosListProps) => {
  const history = useHistory();

  return (
    <>
      {data?.map((posDevice, index) => (
        <div key={posDevice.device.id}className={styles.listItem}>
          <div>
            <span>{posDevice.profile?.name}</span>
            <IconButton disableTouchRipple onClick={() => history.push("/private/vendas/pos")}><EditOutlined /></IconButton>
          </div>
          {index < data.length - 1 && <Divider />}
        </div>
      ))}
    </>
  );
};

export default PosList;
