import { useCallback, useMemo, useRef } from "react";
import styles from "./TutorialStep.module.scss";
import { PhotoCamera } from "@mui/icons-material";
import Input from "components/ui/input/Input";
import { Select } from "@mui/material";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import { IPostPageWizardItems } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";
import { ITutorialFormErrors } from "../validade/TutorialValidation";
import { CloseRounded } from "@material-ui/icons";
import { MenuItem } from "@material-ui/core";

export interface ITutorialStepProps {
    index: number;
    totalSteps: number;
    values: IPostPageWizardItems[];
    onChangeHandle: (index: number, name: string, value: string | number) => void;
    errors: ITutorialFormErrors;
    setErrors: React.Dispatch<React.SetStateAction<ITutorialFormErrors>>;
    handleRemoveStep: (index: number) => void;
}

const TutorialStep = ({ index, totalSteps, values, onChangeHandle, errors, setErrors, handleRemoveStep }: ITutorialStepProps) => {
    const refInput = useRef<HTMLInputElement>(null);

    const orderOptions = useMemo(() => {
        let options: number[] = [];
        for (let i = 1; i <= totalSteps; i++) {
            options.push(i);
        }
        return options;
    },[totalSteps]);

    const onClick = () => {
        refInput.current?.click();
    };    

    const fileToBase64 = (file: File) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            
            reader.onload = function (event) {
            resolve(event.target?.result);
            };

            reader.readAsDataURL(file);
        });
    };

    const onChangeHandleImg = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const files = ev.currentTarget.files;
        let images: string[] = [];
        if (files?.length) {
            for (let i = 0; i < files.length; i++) {
            const _file = files?.item(i);

            const base64 = _file && (await fileToBase64(_file));
            images.push(String(base64));
            }
        }
        onChangeHandle(index, "ImagePath", images[0]);
    };

    const handleRemoveError = useCallback(() => {
        const newErrors = errors.PageWizardItems ? [...errors.PageWizardItems] : [];
    
        newErrors[index] = undefined;
    
        setErrors((prev) => ({ ...prev, PageWizardItems: newErrors }));
    }, [errors, index, setErrors]);

    return (
        <div id={styles.TutorialStep}>
            <div className={styles.stepHeader}>
                <span className={styles.step}>Passo {index + 1}</span>
                <div
                    onClick={() => handleRemoveStep(index)}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <CloseRounded style={{ cursor: "pointer" }}/>
              </div>
            </div>
            <div className={styles.contentImg}>
                <input
                    name="ImagePath"
                    ref={refInput}
                    id="input-file"
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    style={{ display: "none" }}
                    onChange={onChangeHandleImg}
                />
                {values[index].ImagePath ? (
                    <div className={styles.boxImg} onClick={onClick}>
                        <img src={values[index].ImagePath} alt="upload_img" />
                    </div>
                    ) : (
                    <div
                        className={styles.uploadImg}
                        onClick={onClick}
                    >
                        <PhotoCamera />
                        <p>Adicionar foto</p>
                    </div>
                )}
                <div className={styles.headerInputs}>
                    <div>
                        <label className={styles.required}>Título</label>
                        <Input
                            name={"Title"}
                            value={values[index].Title}
                            onChange={(ev) => {
                                handleRemoveError();
                                onChangeHandle(index, ev.target.name, ev.target.value);
                            }}
                            required
                            variant="outlined"
                            placeholder="Título"
                            error={errors.PageWizardItems ? !!errors.PageWizardItems[index] : false}
                            helperText={errors.PageWizardItems && errors.PageWizardItems[index]}
                        />
                    </div>
                    <div className={styles.orderInput}>
                        <label>Ordem</label>
                        <Select
                            name="Order"
                            value={values[index].Order}
                            style={{ width: "100%" }}
                            size="small"
                            placeholder="Passo 01"
                            onChange={(ev) => {
                                if (ev.target.name) {
                                    onChangeHandle(index, ev.target.name, Number(ev.target.value));
                                }
                            }}
                        >
                            {orderOptions?.map((option) => (
                                <MenuItem value={option} key={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <div>
                <label>Descrição</label>
                <InputTextArea
                    name={"Description"}
                    value={values[index].Description}
                    variant="outlined"
                    required
                    onChange={(ev) => {
                        onChangeHandle(index, ev.target.name, ev.target.value);
                    }}
                    placeholder="Insira aqui seu texto..."
                    helperText={<span className={styles.caracteres}>Max.: 300 caracteres</span>}
                />
            </div>
        </div>
    )
}

export default TutorialStep;