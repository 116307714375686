import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService"
import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import { IRankingOperators } from "modules/salesDashboard/presentation/components/rakingOperators/IRankingOperators"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetRankingOperatorsUseCase = async (service: ISalesDashboardService, { sortField = 'totalConsumption', ...params }: IUseCaseParams): Promise<IRankingOperators> => {
    const request = makeFilterRequest({ sortField, ...params })

    const response = await service.getOperatorRanking(request)
    return (response)
}
export default GetRankingOperatorsUseCase