import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService"
import { IProductRankingData } from "modules/salesDashboard/presentation/components/rakingProducts/IRankinProduct"
import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetRankingProductsSalesUseCase = async (service: ISalesDashboardService, { sortField = 'total', ...params }: IUseCaseParams, productGroupId?: string): Promise<IProductRankingData> => {
    const filterRequest = makeFilterRequest({ sortField, ...params });

    return await service.getProductRanking(filterRequest, productGroupId);
}
export default GetRankingProductsSalesUseCase