import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./SalesPosPage.module.scss";
import { AddCircleOutlineOutlined, Close } from "@material-ui/icons";
import { Drawer, IconButton } from "@mui/material";
import { UseTerminalPage } from "modules/Terminal/presentation/hooks/UseTerminalPage";
import TerminalDeviceItem from "modules/Terminal/presentation/component/TerminalItem/TerminalDeviceItem";
import Button from "components/ui/Button/Button";
import UseProfilePage from "modules/config/deviceConfig/presentation/pages/profile/UseProfilePage";
import AddPosFragment from "./fragment/addPosFragment/AddPosFragment";
import DeviceProfileForm from "modules/config/deviceConfig/presentation/fragment/deviceProfile/DeviceProfileForm";
import PosModal from "./components/posModal/PosModal";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import UseDevicePosTutorials from "./hook/UseDevicePosTutorials";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { UseSalesPosPage } from "./hook/UseSalesPosPage";
import SimplePosItem from "./components/simplePosItem/SimplePosItem";
import OperatorAndTableFragment from "modules/Terminal/presentation/component/operatorAndTableFragment/OperatorAndTableFragment";
import PendingPosItem from "./components/pendingPosItem/PendingPosItem";
import LicensesModal from "./components/licensesModal/LicensesModal";
import { useQuery } from "hooks/UseQuery";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";

export interface ISalesPosPageProps {
    //propertys
}

const SalesPosPage = () => {
    const {
        devices: posDevices,
        catalogList,
        patchUpdateTerminalConfig,
        getNavigatorSolicitations,
        postApproveSolicitation,
        postRefuseSolicitation,
        postRefuseAllSolicitations,
        postCloseSession,
        getCatalogs,
        refetch,
        loadingDeletePos,
        deletePos,
    } = UseTerminalPage(DeviceType.POS);

    const {
        simplePosList,
        refetch: refetchSimplePos,
        terminalsList,
        pendingPosList,
        isLoadingLicenses,
        posLicenses,
        getTerminalDevices,
        getPendingSolicitations,
        putCompleteSolicitation,
        getPosLicenses
    } = UseSalesPosPage();
    const query = useQuery();
  
    const showAcquirerTypes = query.get("showAcquirerTypes") === "true";

    const { currentLocal } = useLocal();
    const { showDeviceTutorial } = UseDevicePosTutorials();
    const { action, onChangeFilterHandle, profileNameList, onClickEditProfileHandle } = UseProfilePage(false, "/private/vendas/pos");
    const { subscription } = usePlans();

    const refAddButton = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getCatalogs();
        getTerminalDevices();
        getPosLicenses();
        showAcquirerTypes && getPendingSolicitations();
    }, [getCatalogs, getPendingSolicitations, getPosLicenses, getTerminalDevices, showAcquirerTypes]);

    const [bannerOpen, setBannerOpen] = useState(true);
    const [deletePosStep, setDeletePosStep] = useState(1);
    const [openAddPos, setOpenAddPos] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openLicensesModal, setOpenLicensesModal] = useState(false);
    const [completeSolicitationLoading, setCompleteSolicitationLoading] = useState(false);

    const isCieloLocal = useMemo(() =>
        subscription && subscription?.local.creationStep
            ? subscription.local.creationStep === ProspectType.Cielo
            : false,
    [subscription]);

    const handleDeletePos = useCallback(async (posId: string) => {
        const res = await deletePos(posId);
        if (res === "ok") {
            setDeletePosStep(deletePosStep + 1);
            getPosLicenses();
        }
    }, [deletePos, deletePosStep, getPosLicenses]);

    const handleCompleteSolicitation = useCallback(async (solicitationId: string) => {
        try {
            setCompleteSolicitationLoading(true);
            await putCompleteSolicitation(solicitationId);
            getPendingSolicitations();
            getPosLicenses();
        } finally {
            setCompleteSolicitationLoading(false);
        }
    }, [getPendingSolicitations, getPosLicenses, putCompleteSolicitation]);

    const onClosePosFragment = useCallback(() => {
        setOpenAddPos(false)
        refetch();
        refetchSimplePos();
        getPendingSolicitations();
        getPosLicenses();
    }, [getPendingSolicitations, getPosLicenses, refetch, refetchSimplePos]);


    const onLoadFirstItem = useCallback((ev: SyntheticEvent<HTMLDivElement, Event>) => {
        if (ev.currentTarget && refAddButton.current) {
            showDeviceTutorial(ev.currentTarget as HTMLDivElement, refAddButton);
        }
    }, [showDeviceTutorial])

    const listDevice = useMemo(() => posDevices?.map((terminaDevice) =>
        <div onLoad={onLoadFirstItem} key={terminaDevice.device.id}>
            <TerminalDeviceItem
                deviceType={terminaDevice.device.type.value}
                updateTerminalConfig={patchUpdateTerminalConfig}
                key={terminaDevice.device.id}
                device={terminaDevice}
                catalogs={catalogList ?? []}
                getNavigatorSolicitations={getNavigatorSolicitations}
                onAproveSolicitation={postApproveSolicitation}
                onRefuseSolicitation={postRefuseSolicitation}
                postRefuseAllSolicitations={postRefuseAllSolicitations}
                onEndSession={postCloseSession}
                getTerminalDevices={refetch}
                onClickEditProfileHandle={onClickEditProfileHandle}
                handleDeletePos={handleDeletePos}
                deletePosStep={deletePosStep}
                setDeletePosStep={setDeletePosStep}
                loadingDeletePos={loadingDeletePos}
            />
        </div>
    ), [posDevices, onLoadFirstItem, patchUpdateTerminalConfig, catalogList, getNavigatorSolicitations, postApproveSolicitation, postRefuseSolicitation, postRefuseAllSolicitations, postCloseSession, refetch, onClickEditProfileHandle, handleDeletePos, deletePosStep, loadingDeletePos]);
    
    const listSimplePos = useMemo(() => simplePosList?.map((pos) =>
        <div onLoad={onLoadFirstItem} key={pos.id}>
            <SimplePosItem
                pos={pos}
                terminalsList={terminalsList}
                getPosList={refetchSimplePos}
            />
        </div>
    ), [onLoadFirstItem, refetchSimplePos, simplePosList, terminalsList]);

    const listPendingPos = useMemo(() => pendingPosList?.map((pos) =>
        <div key={pos.id}>
            <PendingPosItem pos={pos} handleCompleteSolicitation={handleCompleteSolicitation} isLoading={completeSolicitationLoading}/>
        </div>
    ), [completeSolicitationLoading, handleCompleteSolicitation, pendingPosList]);

    return (
        <>
            <div id={styles.SalesPosPage}>
                {bannerOpen && (
                    <div className={styles.bannerConfig}>
                        <IconButton className={styles.bannerCloseButton} onClick={() => setBannerOpen(false)} size="small"><Close /></IconButton>
                        <div>
                            <h2><b>Máquina de cartão</b></h2>
                            <span>Essas são suas maquininhas cadastradas. Para usar, basta baixar o App Meep na loja de aplicativos e cadastrar por aqui.</span>
                            <span>Em quais POS posso usar o sistema PDV da Meep?</span>
                        </div>
                        <img src="/assets/icon/saas/banner-pos.svg" alt="" />
                    </div>
                )}

                <OperatorAndTableFragment />
                
                <div className={styles.headerButtons}>
                    <span>💡 Você pode adicionar todas as suas máquinas e gerenciar as vendas e configurações por aqui.</span>
                    <div ref={refAddButton} >
                        <Button variant="outlined" onClick={() => setOpenModal(true)}>Entenda como funciona</Button>
                        <Button
                            endIcon={<AddCircleOutlineOutlined />}
                            onClick={() => 
                                !isCieloLocal && (!posLicenses?.available || posLicenses?.available <= (listPendingPos?.length ?? 0))
                                    ? setOpenLicensesModal(true)
                                    : setOpenAddPos(true)
                            }
                            disabled={isLoadingLicenses}
                        >
                            Adicionar maquininha
                        </Button>
                    </div>
                </div>

                {!isCieloLocal && posLicenses?.licenses && <span className={styles.licenses}>Licenças usadas: {posLicenses?.used}/{posLicenses?.licenses}</span>}

                <div className={styles.container}>
                    {posDevices?.length ? listDevice : <div/>}
                    {simplePosList?.length ? listSimplePos : <div/>}
                    {pendingPosList?.length ? listPendingPos : <div/>}
                    {!posDevices?.length && !simplePosList?.length && !pendingPosList?.length && (
                        <div className={styles.empty}>
                            <p>Sem máquinas de cartão por aqui!</p>
                            <p>Adicione uma máquina para começar a usar</p>
                        </div>
                    )}
                </div>
            </div>

            <Drawer
                open={action === "adicionar" || action === "editar"}
                anchor="right"
            >
                <DeviceProfileForm
                    onChangeFilterHandle={onChangeFilterHandle}
                    profileNameList={profileNameList}
                    systemIdentifier={currentLocal?.systemIdentifier}
                    path="/private/vendas/pos"
                />
            </Drawer>

            {openAddPos && <AddPosFragment open={openAddPos} onClose={onClosePosFragment} terminalsList={terminalsList} />}
            {openModal && <PosModal open={openModal} onClose={() => setOpenModal(false)} />}
            {openLicensesModal && <LicensesModal open={openLicensesModal} onClose={() => setOpenLicensesModal(false)} />}
        </>
    )
}

export default SalesPosPage;
