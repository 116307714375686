import React, { ChangeEvent, FC, useCallback, useRef } from 'react';
import styles from './ui.module.scss';
import { Icon } from '@material-ui/core';
import Resizer from 'react-image-file-resizer';

interface InputImageProps {
    onChange?: (value: string) => void
    value?: string;
    label?: string
    icon?: string;
    size?: number;
    disabled?: boolean;
    style?: React.CSSProperties;
}
export const InputImage: FC<InputImageProps> = ({ label = "Adicionar imagem", size = 120, icon = "photo_camera", onChange, value, disabled, style }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const onClickHandle = useCallback(
        () => {
            inputRef.current?.click()
        },
        [],
    )

    const resizeFile = (file: File): Promise<string | Blob | File | ProgressEvent<FileReader>> => new Promise((resolve) => {
        Resizer.imageFileResizer(file, 400, 400, 'PNG', 100, 0,
            uri => {
                resolve(uri);
            }, 'base64');
    });

    const changeHandle = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {

        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const image = await resizeFile(fileObj);

        onChange?.(image as string)

    }, [onChange]);

    return <>
        <button type={"button"} disabled={disabled} onClick={onClickHandle} className={styles.inputImage} style={{ width: size, height: size, backgroundImage: `url(${value})`, ...style }} >
            {!value &&
                <>
                    <Icon fontSize={'large'}>{icon}</Icon>
                    <label>{label}</label>
                </>
            }
            {value && <img
                alt="imagem do produto"
                style={{ width: size, height: size }}
                src={value}
                onError={({ currentTarget }) => {
                    currentTarget.src = "/assets/img/edit-catalog-default.svg";
                }}
            />}
            <input onChange={changeHandle} style={{ display: "none" }} ref={inputRef} type='file'></input>
        </button >
        {/* {value === "https://meepcustomblobdev.blob.core.windows.net/49953f8a-8a69-4a82-bf3e-8f18396313bb/bb3deb7a-6207-43ef-b6e7-56784cf1bb36_large.jpg" ? (
          <>
            <div
              className={styles.uploadImg}
              onClick={onClickHandle}
            >
              <PhotoCamera />
              <p>Adicionar <b>foto</b> do produto</p>
            </div>
          </>
        ) : ( */}
        {/* <div className={styles.boxImg} style={{ width: size, height: size }} onClick={onClickHandle}>
            <img
                src={value}
                alt="imagem"
                onError={({ currentTarget }) => {
                    currentTarget.src = "/assets/img/edit-catalog-default.svg";
                }}
                style={{ width: size, height: size }}
            />
        </div> */}
        {/* )} */}
        <input onChange={changeHandle} style={{ display: "none" }} ref={inputRef} type='file'></input>
    </>
};
