import React, { FC, useEffect } from 'react'
import styles from './SupplyStockPage.module.scss'
import SupplyStockList from '../../components/supplyStock/SupplyStockList';
import { IGetSupplyStockListRequest } from 'modules/meepErp/application/dtos/supplyStock/IGetSupplyStockListResponse';
import UseQueryState from '../../hook/UseQueryFilter';
import { useMutation, useQuery } from 'react-query';
import GetSupplyStockListUseCase, { GetSupplyStockTotalUseCase } from 'modules/meepErp/application/useCases/supplyStock/GetSupplyStockListUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IAutocompletePaginatedRequest} from '../../components/autocomplete/AutocompleteInput';
import { getSuppliesListUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases';
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { tabsStock } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
export interface ISupplyStockPageProps {
    //propertys
}
const SupplyStockPage: FC<ISupplyStockPageProps> = () => {

    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Estoque",
            },
            {
                title: "Posição de estoque",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const { state: filter, updateState: updateFilter } = UseQueryState<IGetSupplyStockListRequest>();

    const { currentLocal } = useLocal();

    const supplyStocks = useQuery(['supplyStocks', currentLocal, filter], async () => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        return await GetSupplyStockListUseCase(currentLocal.id, filter);
    }, {
        enabled: !!currentLocal
    });


    const getSupply = useMutation(async (request: IAutocompletePaginatedRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        const response = await getSuppliesListUseCase(currentLocal.id, request);
        return response;
    });

    const getSupplyLocalStorage = useMutation(async (request: IAutocompletePaginatedRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        const response = await getSupplyStorageLocalsUseCase(request);
        return {
            ...response,
            items: response.items.map(item => ({
                ...item,
                name: item.name,
            }))
        };
    });

    const supplyStocksTotal = useQuery(['supplyStocks'], async () => {       
        return await GetSupplyStockTotalUseCase();
    });
    
    return (
        <div id={styles.SupplyStockPage} >
            <TabsErp data={tabsStock} />
            <SupplyStockList
                supplyStocks={supplyStocks.data}
                filter={filter}
                onChangeFilter={updateFilter}
                isLoading={supplyStocks.isLoading}
                getSupply={getSupply.mutateAsync}
                getSupplyLocalStorage={getSupplyLocalStorage.mutateAsync}
                supplyStocksTotal={supplyStocksTotal.data}
            />
        </div>
    )
}
export default SupplyStockPage



