import { Icon, IconButton } from "@material-ui/core"
import { ITicketsValidation } from "../../interfaces/ITicketsValidation"
import styles from './ProductList.module.scss'
import { ProductItem } from "./ProductItem/ProductItem";
import { Pagination } from "@mui/material";
import { useState } from "react";
import { IFilterValue } from "../filter/IFilter";
import { ITicketsValidationDetail } from "../../interfaces/ITicketsValidationDetail";
import { Skeleton } from "modules/cashlessDashboard/presentation/components/skeleton/Skeleton";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";

interface ProductListProps {
    loading: boolean
    data?: ITicketsValidation
    getData: ({ page, sortField, sortOrientation }: { page?: number, sortField?: string, sortOrientation?: 'asc' | 'desc' }, _filter?: IFilterValue) => Promise<void>
    getSubitemData: (ticketId: string) => Promise<ITicketsValidationDetail>
}

export const ProductList = ({ data, loading, getData, getSubitemData }: ProductListProps) => {
    const [sortField, setSortField] = useState('productId')
    const [sortOrientation, setSortOrientation] = useState<'asc' | 'desc'>('asc')
    const [currentPage, setCurrentPage] = useState(0)

    const handleSort = (field: string) => {
        setCurrentPage(0)
        let orientation = sortOrientation;

        if (field !== sortField) {
            setSortField(field)
            orientation = 'asc'
        } else {
            orientation = orientation === 'desc' ? 'asc' : 'desc'
        }

        setSortOrientation(orientation)
        getData({ page: 0, sortField: field, sortOrientation: orientation })
    }

    const handlePagination = (page: number) => {
        setCurrentPage(page)
        getData({ page: page, sortField, sortOrientation })
    }

    return (
        <div id={styles.ProductList}>
            {
                !!data &&
                <>
                    <div className={styles.header}>
                        <div>
                            <span>Produto</span>
                            <IconButton onClick={() => handleSort('productName')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                        <div>
                            <span>Venda</span>
                            <IconButton onClick={() => handleSort('saleDate')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                        <div>
                            <span>Validação</span>
                            <IconButton onClick={() => handleSort('validationDate')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                        <div>
                            <span>Cliente</span>
                            <IconButton onClick={() => handleSort('customerName')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                        <div>
                            <span>CPF</span>
                            <IconButton onClick={() => handleSort('customerCPF')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                        <div>
                            <span>Valor</span>
                            <IconButton onClick={() => handleSort('productValue')} size='small'><Icon className={styles.orderIcon}>swap_vert</Icon></IconButton>
                        </div>
                    </div>

                    <div className={styles.body}>
                        {
                            loading ?
                                <Skeleton items={10} containerStyle={styles.skeletonContainerStyle} />
                                : data.records?.length ?
                                    data.records?.map(item => <ProductItem getData={getSubitemData} item={item} />)
                                    :
                                    <div className={styles.noData}>
                                        <ErrorMessage type="warning" />
                                    </div>
                        }
                    </div>

                    <Pagination
                        count={Math.ceil((data.totalRecords ?? 1) / 20) || 1}
                        page={currentPage + 1}
                        disabled={(data.records?.length && data.totalRecords) ? data.records?.length >= data.totalRecords : true}
                        onChange={(_, page) => handlePagination(page - 1)}
                    />
                </>
            }
        </div>
    )
}