import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import styles from "./ExportSideSheet.module.scss";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ILocalConfiguration } from "../../interfaces/ILocalConfiguration";

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  exportType: string | undefined;
  onConfirm: (control: boolean) => void;
  changeSelectExportType: (exportType: string) => void;
  isLoadingExport: boolean;
  localConfiguration: ILocalConfiguration | undefined;
}

export const ExportSideSheet = ({
  open,
  title,
  onClose,
  onConfirm,
  changeSelectExportType,
  exportType,
  isLoadingExport,
  localConfiguration,
}: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <span>Selecione uma opção e clique em exportar.</span>
        <div className={styles.containerRadio}>
          <RadioGroup
            row
            onChange={(_, value: any) => changeSelectExportType(value)}
            aria-labelledby="demo-radio-buttons-group-label"
          >
            <div className={styles.radio}>
              <FormControlLabel value={"PDF"} control={<Radio />} label="PDF" />
            </div>
            <div className={styles.radio}>
              <FormControlLabel
                value={"EXCELOPENXML"}
                control={<Radio />}
                label="Excel"
              />
            </div>
            {localConfiguration?.podeExportarProdutos && (
              <div className={styles.radioRetaguarda}>
                <FormControlLabel
                  value={"RETAGUARDA"}
                  control={<Radio />}
                  label="Retaguarda"
                />
                <span>
                  Esta ação pode demorar alguns minutos para ser concluída.
                </span>
              </div>
            )}
          </RadioGroup>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            variant="outlined"
            fullWidth={false}
            className={styles.button}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            fullWidth={false}
            color="secondary"
            variant="contained"
            className={
              !!(
                exportType !== "PDF" &&
                exportType !== "EXCELOPENXML" &&
                exportType !== "RETAGUARDA"
              )
                ? ""
                : styles.button
            }
            disabled={
              !!(
                exportType !== "PDF" &&
                exportType !== "EXCELOPENXML" &&
                exportType !== "RETAGUARDA"
              )
            }
            onClick={() => onConfirm(true)}
          >
            Exportar
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
