import { FormControl, FormControlLabel, Icon, IconButton, Radio, RadioGroup } from '@material-ui/core'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Search } from '@mui/icons-material';
import { TextField, FormLabel, Button } from '@mui/material'
import styles from './GuestListCustomer.module.scss'
import GestListItem from './guestItem/GestListItem'
import { IGuestListData } from './IGuestItem'
import { SwapVert } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component'
import { Pagination } from '@material-ui/lab'
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

const PAGESIZE = 5;
export interface IGestListLiveProps {
    //propertys
    data?: IGuestListData,
    getData?: (filter?: { keyword?: string, page?: number, pageSize?: number, guestsFilter?: string, sortType?: string, sortOrientation?: string }) => void,
    isLoading?: boolean
    error?: boolean
    infinityScroll?: boolean
}

const GuestListLive: FC<IGestListLiveProps> = memo(({ data, getData, isLoading, error, infinityScroll = false }) => {
    // const [menuSortOpen, setMenuSortOpen] = useState<EventTarget & HTMLButtonElement>()

    const [filter, setFilter] = useState<{ page: number, pageSize?: number, guestsFilter?: string, sortType?: string, sortOrientation?: string }>({ page: 0, pageSize: PAGESIZE, guestsFilter: "list", sortOrientation: 'ASC', sortType: 'name' })
    const [keyword, setKeyword] = useState('')
    const { isMobile } = useUi();

    const sortByKey = useCallback((key: string) => {
        setFilter(prev => ({
            ...prev,
            sortType: key,
            sortOrientation: prev.sortOrientation === "ASC" ? "DESC" : "ASC",
            page: 0,
        }))
    }, [])
    const onChangeVariant = (value: string) => {
        setFilter(prev => ({ ...prev, guestsFilter: value }))
    }

    const handleSearch = () => {
        setFilter(prev => ({ ...prev, keyword }))
    }


    // const calcTotal = (values: IGenreValues) => {
    //     return values.female + values.male + values.unknown
    // }

    const getMore = useCallback(() => {
        setFilter(prev => ({ ...prev, page: prev.page + 1 }))
    }, [])

    const getByPage = useCallback((page: number) => {
        setFilter(prev => ({ ...prev, page }))
    }, [])

    useEffect(() => {
        getData?.({ ...filter, keyword });
    }, [filter, getData])


    const list = useMemo(() => <>
        {data?.records.map((item, index) => (
            <GestListItem key={index} gestItem={item} />)
        )}
    </>, [data?.records])


    const loadingView = useMemo(() => <div id={styles.GestListLive} >
        {[...Array(filter.pageSize)].map(() => <div className={styles.list} >
            <GestListItem loading gestItem={null} />
        </div>)}
    </div>, [filter.pageSize])

    // if (!data?.records.length && !isLoading) {
    //     return <div id={styles.GestListLive}>
    //         <ErrorMessage type="warning" />
    //     </div>
    // }

    if (error) {
        return (
            <div id={styles.GestListLive} >
                <aside className={styles.row}>
                    <Icon fontSize={'large'} >error</Icon>
                </aside>
            </div>
        )
    }

    return (
        <div id={styles.GestListLive} >
            <div className={styles.filter} >
                <div>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Gender</FormLabel> */}
                        <FormLabel className={styles.label}>Visualizar por</FormLabel>
                        <RadioGroup
                            aria-label="filterGuest"
                            defaultValue="list"
                            row
                            name="radio-buttons-group"
                            onChange={(ev, value: string) => onChangeVariant(value)}
                        >
                            <FormControlLabel value="list" control={<Radio />} label="Lista de convidados" />
                            <FormControlLabel value="promoter" control={<Radio />} label="Promoter" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth={isMobile ? true : false}>
                        <FormLabel className={styles.label}>{filter.guestsFilter === 'list' ? 'Lista' : 'Promoter'}</FormLabel>
                        <TextField
                            placeholder={`Selecione ${filter.guestsFilter === 'list' ? 'uma lista' : 'um promoter'}`}
                            fullWidth
                            className={styles.search}
                            InputProps={{
                                endAdornment: <Search style={{ color: "#5f5f5f" }} />
                            }}
                            size='small' name={'input'} onChange={(e) => setKeyword(e.target.value)} value={keyword}
                        />
                    </FormControl>
                </div>
                <div className={styles.containerButton}>
                    <Button
                        className={styles.filterButton}
                        fullWidth
                        variant='contained'
                        type='submit'
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </div>
            </div>
            {
                !data?.records.length ?
                    <div id={styles.GestListLive}>
                        <ErrorMessage type="warning" />
                    </div>
                    :
                    <>
                        <div className={styles.sortHeader} >
                            <div>{filter.guestsFilter === "promoter" ? `Operador` : `Lista`} <IconButton onClick={() => sortByKey("name")}><SwapVert /></IconButton></div>
                            <div>Convidados</div>
                            <div>Vendas realizadas</div>
                            <div>Check-in</div>
                            <div>Ticket médio de consumo</div>
                        </div>
                        <div id={"ListGuest"} className={styles.list} style={{ height: infinityScroll ? 500 : 'unset' }} >
                            {
                                !infinityScroll ?
                                    <>
                                        {
                                            !!isLoading ? <>
                                                {loadingView}
                                                {loadingView}
                                                {loadingView}
                                            </>
                                                :
                                                list
                                        }
                                        <Pagination count={Math.ceil((data?.totalRecords ?? 1) / (filter.pageSize ?? 1))} page={(filter.page ?? 0) + 1} onChange={(_, page) => getByPage(page - 1)} />
                                    </>
                                    :
                                    <InfiniteScroll
                                        next={getMore}
                                        hasMore={!!(data && (data.records.length < data.totalRecords))}
                                        loader={
                                            <>
                                                {loadingView}
                                                {loadingView}
                                                {loadingView}
                                            </>
                                        }
                                        dataLength={data?.records.length ?? 0}
                                        scrollableTarget="ListGuest"
                                    >
                                        {list}
                                    </InfiniteScroll>
                            }
                        </div>
                    </>
            }
        </div>
    )
})



export default GuestListLive