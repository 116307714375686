export var FidelityColors = [
  { colors: ["#020202"] },
  { colors: ["#5F5F5F"] },
  { colors: ["#00073E"] },
  { colors: ["#32008E"] },
  { colors: ["#5803F5"] },
  { colors: ["#2C4E10"] },
  { colors: ["#217B00"] },
  { colors: ["#31A52E"] },
  { colors: ["#42005B"] },
  { colors: ["#8600B6"] },
  { colors: ["#AB00E7"] },
  { colors: ["#54002C"] },
  { colors: ["#8C094E"] },
  { colors: ["#C8206F"] },
  { colors: ["#790C14"] },
  { colors: ["#A4151E"] },
  { colors: ["#D01E29"] },
  { colors: ["#642B00"] },
  { colors: ["#AD3400"] },
  { colors: ["#EC5500"] },
  { colors: ["#937004"] },
  { colors: ["#4E595B"] },
  { colors: ["#694229"] },
  { colors: ["#005A6F"] },
  { colors: ["#00073E", "#5803F5"] },
  { colors: ["#42005B", "#AB00E7"] },
  { colors: ["#54002C", "#890E4B"] },
  { colors: ["#790C14", "#D01E29"] },
  { colors: ["#642B00", "#EC5500"] },
  { colors: ["#2C4E10", "#31A52E"] },
  { colors: ["#42005B", "#C8206F"] },
  { colors: ["#5803F5", "#AB00E7"] },
  { colors: ["#A4151E", "#EC5500"] },
  { colors: ["#AE8625", "#F7EF8A"] },
];
