import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styles from "./ContentBox.module.scss";
import React, { FC, useCallback, useMemo, useState } from "react";
import { IntegrationTypeEnum } from "../../../interfaces/IntegrationTypeEnum";
import {
  DeleteOutline,
  FacebookRounded,
  SmsOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { PremiumModal } from "modules/plans/presentation/components/premiumModal/PremiumModal";
import UseDeleteSidesheet from "../../../hook/UseDeleteSidesheet";
import { AccessTimeOutlined, SettingsOutlined } from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import { Switch } from "@mui/material";

export interface Props {
  type?: IntegrationTypeEnum;
  status: "enabled" | "disabled" | "unavailable";
  text?: string | React.ReactNode;
  loading?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  getMoreRef?: string;
  featureType?: FeatureTypeEnum;
  showConnect?: boolean;
  premium?: boolean;
  soon?: boolean;
  fullWidth?: boolean;
  extraContent?: React.ReactNode;
  enabledConnection?: boolean;
  handleToggleConnection?: (enable: boolean) => Promise<void>;
  showSwitch?: boolean;
}

const ContentBox: FC<Props> = ({
  status,
  type,
  text,
  loading = false,
  setOpen,
  showConnect = true,
  getMoreRef,
  featureType,
  premium,
  soon,
  fullWidth,
  extraContent,
  enabledConnection,
  showSwitch,
  handleToggleConnection
}: Props) => {
  const { push } = useHistory();
  const { checkPermission } = usePlans();
  const { setDeleteType } = UseDeleteSidesheet();
  const { dimensions } = UseDimension();

  const [modalOpened, setModalOpened] = useState(false);

  const getImage = () => {
    switch (type) {
      case IntegrationTypeEnum.EMAIL:
        return <MailOutlineIcon className={styles.defaultIcon} />;
      case IntegrationTypeEnum.WHATSAPP:
        return <WhatsAppIcon className={styles.whatsappIcon} />;
      case IntegrationTypeEnum.TRACK:
        return (
          <img
            src="/assets/icon/track-icon.svg"
            alt="Track.co"
            className={styles.iconSize}
          />
        );
      case IntegrationTypeEnum.SMS:
        return <SmsOutlined className={styles.defaultIcon} />;
      case IntegrationTypeEnum.FACEBOOK:
        return <FacebookRounded className={styles.facebookIcon} />;
      case IntegrationTypeEnum.IFOOD:
      case IntegrationTypeEnum.IFOOD_MARKET:
        return (
          <img
            src="/assets/icon/ifood-icon.svg"
            alt="Ifood"
            className={styles.biggerIcons}
            style={{ height: "28px" }}
          />
        );
        case IntegrationTypeEnum.PUSH:
          return (
            <img
              src="/assets/icon/push_icon.svg"
              alt="Push"
              className={styles.biggerIcons}
              style={{ height: "28px" }}
            />
          );
      case IntegrationTypeEnum.OMIE:
        return (
          <img
            src="/assets/icon/omie-icon.png"
            alt="Track.co"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.EVEREST:
        return (
          <img
            src="/assets/icon/everest-logo.png"
            alt="Track.co"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.SANKHYA:
        return (
          <img
            src="/assets/icon/sankhya-icon.png"
            alt="Track.co"
            className={styles.iconSize}
          />
        );
      case IntegrationTypeEnum.MOBI:
        return (
          <img
            src="/assets/icon/mobi-logo.svg"
            alt="Track.co"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.CIELO:
      case IntegrationTypeEnum.CIELO_SMART:
        return (
          <img
            src="/assets/icon/logo-cielo.png"
            alt="Cielo"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.PAGSEGURO:
        return (
          <img
            src="/assets/icon/logo-pagseguro.png"
            alt="Pagseguro"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.GETNET:
        return (
          <img
            src="/assets/icon/logo-getnet.png"
            alt="Getnet"
            className={styles.biggerIcons}
          />
        );
      case IntegrationTypeEnum.STONE:
        return (
          <img
            src="/assets/icon/logo-stone.svg"
            alt="Getnet"
            className={styles.biggerIcons}
          />
        );
      default:
        return (
          <img
            src="/assets/icon/logo-meep-onboarding.svg"
            alt="meep tickets"
            className={styles.iconSize}
          />
        );
    }
  };

  const titleCondition = useMemo(
    () =>
      type === IntegrationTypeEnum.IFOOD ||
      type === IntegrationTypeEnum.IFOOD_MARKET ||
      type === IntegrationTypeEnum.OMIE ||
      type === IntegrationTypeEnum.EVEREST ||
      type === IntegrationTypeEnum.MOBI ||
      type === IntegrationTypeEnum.CIELO ||
      type === IntegrationTypeEnum.CIELO_SMART ||
      type === IntegrationTypeEnum.PAGSEGURO ||
      type === IntegrationTypeEnum.GETNET ||
      type === IntegrationTypeEnum.STONE,
    [type]
  );

  const handleSettings = useCallback(() => {
    if (type === IntegrationTypeEnum.TRACK) {
      return push("/private/track");
    }

    if (setOpen && type) {
      return setOpen(type);
    }
  }, [push, type, setOpen]);

  const handleShowEndButtons = useCallback(() => {
    if (status === "enabled") {
      return (
        <div className={styles.connectedIcons}>
          {
          type !== IntegrationTypeEnum.CIELO &&
          type !== IntegrationTypeEnum.CIELO_SMART &&
          type !== IntegrationTypeEnum.PAGSEGURO &&
          type !== IntegrationTypeEnum.GETNET &&
          type !== IntegrationTypeEnum.STONE &&
          type !== IntegrationTypeEnum.MEEP_HUB && (
            <div className={styles.connectedIcon} onClick={() => setDeleteType(type ?? null)}>
              <DeleteOutline style={{ cursor: "pointer" }} />
            </div>
          )}
          <div className={styles.connectedIcon} onClick={handleSettings}>
            <SettingsOutlined style={{ cursor: "pointer" }} />
          </div>
        </div>
      );
    }
    if (showConnect) {
      return (
        <button
          onClick={() =>
            !!featureType && !checkPermission(featureType)
              ? setModalOpened(true)
              : setOpen && type
              ? setOpen(type)
              : null
          }
        >
          {loading ? <CircularProgress size={16} /> : "Conectar"}
        </button>
      );
    }
    return <div style={{ height: "31px" }} />;
  }, [
    checkPermission,
    featureType,
    loading,
    setOpen,
    showConnect,
    type,
    handleSettings,
    status,
    setDeleteType,
  ]);

  return (
    <div className={styles.container} style={fullWidth ? { width: "100%", height: dimensions.width < 770 ? "100%" : "" } : {}}>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.contentImgTitle}>
            <div
              className={`${styles.contentImg} ${
                titleCondition && styles.contentBiggerImg
              }`}
              style={{ width: type === IntegrationTypeEnum.PAGSEGURO ? "80px" : "" }}
            >
              {getImage()}
            </div>
            {(type !== IntegrationTypeEnum.PAGSEGURO &&
              type !== IntegrationTypeEnum.GETNET && 
              type !== IntegrationTypeEnum.STONE) && (
              <h3
                style={{
                  marginLeft: titleCondition ? "11px" : "0px",
                }}
                className={(type === IntegrationTypeEnum.CIELO || type === IntegrationTypeEnum.CIELO_SMART) ? styles.modifiedTitle : ""}
              >
                {type}
              </h3>
            )}          
          </div>

          {premium && (
            <img
              src="/assets/icon/golden-crown.svg"
              alt="Coroa Dourada"
              style={{ height: "28px" }}
            />
          )}
          {soon && status !== "enabled" && (
            <div className={styles.soon}>
              <AccessTimeOutlined fontSize="small"/>
              <span>Em breve!</span>
            </div>
          )}
          {status === "enabled" && type === IntegrationTypeEnum.MEEP_HUB && handleToggleConnection && showSwitch &&  (
            <div className={styles.toggleConnection}>
              <span>{enabledConnection ? "Conectado" : "Conectar"}</span>
              <Switch
                checked={enabledConnection}
                onChange={(_, checked) => handleToggleConnection(checked)}
              />
            </div>
          )}
        </div>
        {status === "enabled" && type !== IntegrationTypeEnum.MEEP_HUB && (
          <div className={styles.connected}>
            <div className={styles.greenCircle} />
            <span>Conectado</span>
          </div>
        )}
        <div
          style={{
            display: fullWidth ? "flex" : "",
            gap: fullWidth ? "20px" : "",
            height: "100%",
            flexDirection: dimensions.width < 500 && extraContent ? "column" : "row",
          }}
        >
          <div className={styles.cardDescription} style={{ marginTop: dimensions.width < 500 && extraContent ? "12px" : "", maxWidth: typeof text !== "string" ? "100%" : "" }}>
            {typeof text === "string" ? <span>{text}</span> : text}
            {getMoreRef && (
              <a href={getMoreRef}>
                <span>Saiba mais...</span>
              </a>
            )}
          </div>
          {extraContent}
        </div>
        <div className={styles.cardFooter}>
          {!soon && ((!!featureType && checkPermission(featureType)) || !featureType)
            ? handleShowEndButtons()
            : !!featureType &&
              !checkPermission(featureType) && (
                <div style={{ height: "31px" }} />
              )}
        </div>
      </div>

      <PremiumModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />
    </div>
  );
};

export default ContentBox;
