import styles from "./Notifications.module.scss";
import { Close, ExpandMore, Circle } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import { useMemo, useState } from "react";

interface NotificationsProps {
  onClose?: () => void;
  isMobile?: boolean;
  getNotificationsList?: IGetNotificationsByTypeResponse[];
  handleSaveNotification: (id: string) => Promise<void>
}

export const Notifications = ({
  onClose,
  isMobile,
  getNotificationsList,
  handleSaveNotification,
}: NotificationsProps) => {
  const useStyles = makeStyles(() => ({
    accordion: {
      borderBottom: "1px solid #E0E0E0",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      boxShadow: "none",
      "&::before": {
        display: "none",
      },
    },
    accordionSumary: {
      padding: 0,
      margin: 0,
    },
    customExpanded: {
      margin: "0 !important",
      minHeight: "auto !important",
    },
    accordionDetails: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }));

  const stylesMui = useStyles();
  const [unreadMessages, setUnreadMessages] = useState(false);

  const notificationsList = useMemo(() => {
    if (getNotificationsList) {
      if (unreadMessages) {
        return getNotificationsList.filter((item) => !item.MensagemLida);
      }
      return getNotificationsList;
    }
    return [];
  }, [getNotificationsList, unreadMessages]);

  return (
    <div id={styles.Notifications}>
      {isMobile && (
        <div className={styles.containerTitle}>
          <h1>Todas notificações</h1>
          <Close onClick={onClose} />
        </div>
      )}
      {notificationsList.length > 0 ? (
        <>
          <div className={styles.switch}>
            <FormControlLabel
              control={<Switch size="small" />}
              label="Mostrar apenas não lidas"
              value={unreadMessages}
              onChange={() => setUnreadMessages(!unreadMessages)}
            />
          </div>
          {notificationsList.map((item) => {
            return (
              <Accordion
                key={item.Id}
                className={stylesMui.accordion}
                classes={{ expanded: stylesMui.customExpanded }}
                onClick={() => !item.MensagemLida ? handleSaveNotification(item.Id) : null}
              >
                <AccordionSummary
                  className={stylesMui.accordionSumary}
                  expandIcon={<ExpandMore />}
                  classes={{ expanded: stylesMui.customExpanded }}
                >
                  <div className={styles.boxTitle}>
                    {item.MensagemLida ? null : <Circle className={styles.circle} />}
                    <p className={styles.title}>
                      {format(new Date(item.DataInicio), "dd/MM/yyyy")}{" "}
                      {item.Titulo}
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={stylesMui.accordionDetails}>
                  {item.Texto.includes("\n\n") ? (
                    <div>
                      {item.Texto.split("\n\n").map((line) => {
                        return <p className={styles.texto} key={line}>{line}</p>;
                      })}
                    </div>
                  ) : (
                    <p>{item.Texto}</p>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : (
        <div className={styles.noNotifications}>
          <img
            src="/assets/img/no-notifications.png"
            alt="Nenhuma notificação encontrada"
          />
          <p>Você não possui notificações.</p>
        </div>
      )}
    </div>
  );
};
