import { IEventsListResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

const CloseEventUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  localId: string,
): Promise<IEventsListResponse> => {
  return await eventsService.closeEvent(eventId, localId);
};

export default CloseEventUseCase;
