import { useCallback, useState } from "react";
import ComplienceService from "services/api/complience/ComplienceService";
import GetTIDsListUseCase from "modules/tidsAdicionais/application/useCases/GetTIDsListUseCase";
import { ITID } from "../interfaces/ITIDsList";

export const UseTIDsList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tidsList, setTidsList] = useState<ITID[] | null>(null);

  const getTIDsList = useCallback(async () => {
    try {
      const service = ComplienceService();
      setIsLoading(true);
      const response = await GetTIDsListUseCase(service);
      setTidsList(response);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    tidsList,
    getTIDsList,
  }
};
