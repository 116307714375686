import LandingPage from "components/freePage/LandingPage";
import Layout from "components/layout/presentation/Layout";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import ConfigOmie from "modules/config/integrationsConfig/presentation/pages/configOmie/ConfigOmie";
import { useState, useEffect } from "react";

export const OmieDetail = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  return (
    <Layout>
      <LandingPage
        title="OMIE"
        subtitle="Sistema ERP"
        description="O sistema OMIE oferece uma variedade de recursos para te ajudar a gerenciaras operações da sua empresa de maneira integrada, desde finanças e vendas até estoque e compras."
        aboutTitle={<>O sistema ERP Omie é uma <span className='purpleText'>solução empresarial abrangente e inovadora!</span></>}
        about="Com uma interface intuitiva o Omie otimiza os processos, aumenta a eficiência e proporciona mais eficiência na tomada de decisões. Ele oferece recursos essenciais, como controle financeiro, emissão de notas fiscais, gerenciamento de estoque e acompanhamento de vendas, todos acessíveis a partir de um painel unificado."
        logo="/assets/icon/omie-detail.svg"
        footerButtonLink="https://www.ajuda.meep.com.br/sistemaerp"
        includedItems={[
          {
            title: "Interface amigável",
            description:
              "Com um layout simples e intuitivo fica fácil dominar a gestão do seu negócio.",
          },
          {
            title: "Compras e estoque",
            description:
              "Controle compras, estoque, inventário, fornecedores e produtos, facilitando a gestão de suprimentos.",
            img: "/assets/icon/omie/box.svg",
          },
          {
            title: "Gestão financeira",
            description:
              "Tenha o controle de contas a pagar, contas a receber, emissão de boletos, conciliação bancária e fluxo de caixa.",
            img: "/assets/icon/omie/money-stack.svg",
          },
          {
            title: "Personalização",
            description:
              "Algumas versões do sistema permitem personalização de acordo com as necessidades específicas do seu negócio. ",
            img: "/assets/icon/omie/edit.svg",
          },
          {
            title: "Eficiência",
            description:
              "O Omie é mais do que um simples sistema de ERP: é um parceiro confiável para empresas que buscam eficiência, crescimento e sucesso contínuo.",
            img: "/assets/icon/omie/setting.svg",
          },
          {
            title: "Treinamentos e suporte",
            description:
              "Tenha acesso aos treinamentos, webinars e suporte para usufruir de todas as ferramentas da plataforma. ",
            img: "/assets/icon/omie/book.svg",
          },
        ]}
        setOpen={() => setOpen(IntegrationTypeEnum.OMIE)}
      />

      {open && open === IntegrationTypeEnum.OMIE && (
        <ConfigOmie
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
        />
      )}
    </Layout>
  );
};
