import styles from './ProductsCanceledOperatorList.module.scss'
import RankingList from "modules/salesDashboard/presentation/components/rakingList/RankingList"
import { FC, useCallback, useEffect, useState } from "react"
import { IProductsCanceledByOperatorData } from "../IProductRanking"

const PageSize = 10

export interface IProductsCanceledOperatorProps {
    //propertys
    dataProductsCanceledOperator?: IProductsCanceledByOperatorData
    getDataProductsCanceledOperator: (fitler: { page?: number, pageSize?: number, operatorId: string, sort?: { type?: string, orientation?: 'asc' | 'desc' } }) => void
    onLoadingProductsCanceledOperator: boolean,
    operatorId: string
}
const ProductsCanceledOperatorList: FC<IProductsCanceledOperatorProps> = ({
    dataProductsCanceledOperator,
    getDataProductsCanceledOperator,
    onLoadingProductsCanceledOperator,
    operatorId
}) => {

    const [productsCanceledOperatorFilter, setProductsCanceledOperatorFilter] = useState<{ page?: number, pageSize?: number, sort?: { type?: string, orientation?: 'asc' | 'desc' } }>({ page: 0, pageSize: PageSize })

    useEffect(() => {
        getDataProductsCanceledOperator({ ...productsCanceledOperatorFilter, operatorId: operatorId })
    }, [getDataProductsCanceledOperator, operatorId, productsCanceledOperatorFilter])

    const getMoreProductsCanceledOperator = useCallback((filter?: { pagination?: { page?: number, pageSize?: number } }) => {
        setProductsCanceledOperatorFilter(prev => ({ ...prev, ...filter?.pagination }));
    }, [])

    const sortProductsCanceledOperator = useCallback((key: string) => {
        setProductsCanceledOperatorFilter(prev => ({ ...prev, page: 0, sort: { type: key, orientation: prev.sort?.orientation === 'asc' ? 'desc' : 'asc' } }));
    }, [])

    return (
        <div className={styles.productsCanceledList}>
            <RankingList
                list={dataProductsCanceledOperator?.records}
                count={dataProductsCanceledOperator?.totalRecords}
                getMore={getMoreProductsCanceledOperator}
                isLoading={onLoadingProductsCanceledOperator}
                pagination={productsCanceledOperatorFilter}
                colunms={{
                    productName: { title: "Produto", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    cancelOperatorName: { title: "Cancelamento", iconButtonStyles: { fontSize: '1rem' } },
                    saleOperatorName: { title: "Venda", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    comment: { title: "Observação", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    type: { title: "Tipo", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    unityPrice: { title: "Valor un.", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    quantity: { title: "Quantidade", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                    totalNet: { title: "Total", onClickSort: sortProductsCanceledOperator, iconButtonStyles: { fontSize: '1rem' } },
                }}
            />
        </div>
    )

}
export default ProductsCanceledOperatorList