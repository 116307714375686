import { SimulateAntecipateRequest } from "modules/contaDigital/models/dtos/SimulateAntecipateRequest";
import { IPostSimulateAnticipateService } from "../interfaces/IContaDigitalApiService";

const PostSimulateAnticipate = async (
  accountService: IPostSimulateAnticipateService,
  body: SimulateAntecipateRequest
): Promise<any> => {
  return await accountService.postSimulateAnticipate(body);
};

export default PostSimulateAnticipate;
