import { api } from "../Api";
import { ISignupApi } from "modules/auth/application/interfaces/_api/ISignupApi";
import { ISignupRequest } from "modules/auth/models/dto/ISignupRequest";
import { ISignupResponse } from "modules/auth/models/dto/ISignupResponse";


export const SignupApi = (): ISignupApi => {
    const postSignup = async (request: ISignupRequest): Promise<ISignupResponse> => {
        const result = await api.post(`Account/Register`, request, { params: { disableError: true } });
        return result.data;
    };

    return {
        postSignup
    };
};
