import { IPartnerFilterValue } from 'modules/meepErp/presentation/components/partners/partnersList/partnerFilter/IPartnerFilter';
import PartnerService from 'modules/meepErp/services/api/Partner/PartnerService';
import { IGetPartnerListRequest } from 'modules/meepErp/application/dtos/partner/PartnerDtos';

export const getPartnersListUseCase = async (localId: string, filter: IPartnerFilterValue) => {
    const service = PartnerService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
    const request: IGetPartnerListRequest = {
        page: page,
        pageSize: pageSize,
        orderBy: sortBy ?? 'code',
        ascending: filter.ascending === undefined ? true : filter.ascending,
        name: filter.name,
        code: filter.code,
        document: filter.document,
        type: filter.partnerType
    };
    return service.getPartnerList(localId, request);
};
export const onDeleteLocalsUseCase = async (id: string) => {
    const service = PartnerService();
    return await service.deletePartner(id);
};
