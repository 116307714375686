import Layout from "components/layout/presentation/Layout";
import ServiceCategoriesPage from "modules/schedule/presentation/pages/serviceCategories/ServiceCategoriesPage";
import React, { FC } from "react";
import styles from "./index.module.scss";
export interface IindexProps {
  //propertys
}
const ServiceCategories: FC<IindexProps> = () => {
  return (
    <Layout>
      <ServiceCategoriesPage />
    </Layout>
  );
};
export default ServiceCategories;
