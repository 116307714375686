import React, { FC } from 'react'
import styles from './FormStyles.module.scss'
export interface IFormContainersProps extends React.HTMLAttributes<HTMLDivElement> {
    //propertys
    children?: React.ReactNode;
    flex?: number
    maxWidth?: number | string;
    minWidth?: number | string;
}
export const InputContainer: FC<IFormContainersProps & { label?: string, row?: boolean, htmlFor?: string, requerid?: boolean }> = (props) => {
    return (<div
        className={`${styles.inputContainer} ${props.className}`}
        style={{ maxWidth: props.maxWidth, minWidth: props.minWidth ?? 150, alignItems: props.row ? "center" : "flex-start", justifyContent: 'flex-end', flexDirection: props.row ? "row" : "column", flex: props.flex, ...props.style }}
        {...props}
    >
         {props.label && <label htmlFor={props.htmlFor} className={props.requerid ? styles.requerid : ''}>{props.label}</label>}
        {props.children}
    </div>)
}
export const Row: FC<IFormContainersProps> = (props) => {
    return (<div className={`${styles.row} ${props.className}`} {...props} />)
}
export const Col: FC<IFormContainersProps> = (props) => {
    return (<div className={`${styles.col} ${props.className}`} {...props} />)
}



export const Container: FC<IFormContainersProps & { subContainer?: boolean, title?: string, border?: boolean, center?: boolean }> = (props) => {
    return (<div
        className={`${styles.container} ${props.className} ${!!props.subContainer && styles.subContainer} ${!!props.title && styles.subContainerTitle}`}
        style={{ alignSelf: props.center ? "center" : "unset", maxWidth: props.maxWidth, borderWidth: props.border ? 1 : 0, ...props.style }}
    // {...props} 
    >
        {props.title && <NewTitle>{props.title}</NewTitle>}
        {props.children}
    </div>)
}


interface ITitle extends React.HTMLAttributes<HTMLHeadingElement> {
}
export const Title: FC<ITitle> = (props) => <h2 className={styles.title}>{props.children}</h2>

export const ButtonContainer: FC<IFormContainersProps> = (props: IFormContainersProps) => <div className={styles.buttonsContainer} {...props} />

export const IconButtonContainer = (props: IFormContainersProps) => <div className={styles.iconButtonContainer} {...props} />
export const FilterButtonContainer = (props: IFormContainersProps) => <div className={styles.filterButtonContainer} {...props} />

export const NewTitle: FC<ITitle> = (props) => {
    const title = props.children as string;
    const regex = /^(\d+)\.\s(.+)$/;
    const match = title.match(regex);

    if (match) {
        const number = match[1];
        const words = match[2].split(' ');
        const secondWord = words[0];
        const lastWord = words[words.length - 1];

        return (
            <div className={styles.title}>
                <span style={{ color: "var(--secondary)" }}>{number}.</span>
                <span>{secondWord}</span>
                {words.length > 1 && (
                    <>
                        {words.slice(1, -1).map((word, index) => (
                            <span key={index}> {word}</span>
                        ))}
                        <strong> {lastWord}</strong>
                    </>
                )}
            </div>
        );
    }

    return props.children as JSX.Element;
}


export const Box = (props: IFormContainersProps) => <div className={styles.box} {...props} />