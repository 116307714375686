import Layout from "components/layout/presentation/Layout";
import PageHeader from "components/ui/pageHeader/PageHeader";
import GuestsListPage from "modules/events/presentation/pages/guestsList/GuestsListPage";
import { EventGuestsListsPage } from "modules/events/presentation/pages/eventGuestsLists/EventGuestsListsPage";
import React from "react";
import EventPeopleListPage from "modules/events/presentation/pages/eventPeopleList/EventPeopleListPage";

const EventPeopleList = () => {
  return (
    <Layout>
      <EventPeopleListPage />
    </Layout>
  );
};

export default EventPeopleList;
