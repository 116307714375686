import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpandMore,
  Battery2Bar,
  MoreVert,
  Wifi,
  SignalCellularAlt,
  PublicOff,
  LockOpen,
  Lock,
  Battery5Bar,
  BatteryFull,
  Block,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import styles from "./AccordionItemMobile.module.scss";
import { colorIcon } from "../../pages/utils";
import { IAccordionItem } from "../../pages/interfaces";
import { useState } from "react";
import PopoverItem from "../popover/PopoverItem";

const AccordionItem = ({
  nome,
  bloqueado,
  modelo,
  tipo,
  bateria,
  bobina,
  operador,
  sincronizado,
  status,
  versao,
  rede,
  tempo_resposta,
  ip,
  nome_rede,
  transacao,
  pedido,
  atualizacao,
  velocidade,
  caixa,
  totem,
  isChecked,
  index,
  onAccordionItemnClick,
  setIsMenuPopover
}: IAccordionItem) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const useStyles = makeStyles(() => ({
    accordion: {
      border: "1px solid #E0E0E0",
      boxShadow: "none",
      borderRadius: "16px !important",
    },
    accordionSumary: {
      margin: "0 7px 0 0",
      color: "#5f5f5f",
      padding: "0",
    },
    accordionDetails: {
      padding: "0 16px 23px 16px",
      flexDirection: "column",
    },
    buttonAccordion: {
      position: "absolute",
    },
    customTooltip: {
      backgroundColor: "white",
      color: "#5F5F5F",
      boxShadow: "0px 4px 12px 0px #0000001A",
    },
  }));

  const stylesMui = useStyles();

  const handleAccordionClick = (index: number) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };
  const batery = () => {
    switch (bateria) {
      case "baixa":
        return (
          <Tooltip
            title="30% de bateria"
            classes={{ tooltip: stylesMui.customTooltip }}
          >
            <Battery2Bar className={colorIcon("red")} />
          </Tooltip>
        );
      case "media":
        return (
          <Tooltip
            title="50% de bateria"
            classes={{ tooltip: stylesMui.customTooltip }}
          >
            <Battery5Bar className={colorIcon("yellow")} />
          </Tooltip>
        );
      case "alta":
        return (
          <Tooltip
            title="100% de bateria"
            classes={{ tooltip: stylesMui.customTooltip }}
          >
            <BatteryFull className={colorIcon("green")} />
          </Tooltip>
        );
    }
  };

  const redeChange = () => {
    switch (rede) {
      case "wifi":
        return <Wifi className={colorIcon()} />;
      case "dados":
        return <SignalCellularAlt className={colorIcon()} />;
      case "sem_conexao":
        return <PublicOff className={colorIcon()} />;
    }
  };
  return (
    <div id={styles.AccordionItemMobile}>
      <Accordion expanded={expanded === index} className={stylesMui.accordion}>
        <AccordionSummary
          className={stylesMui.accordionSumary}
          expandIcon={
            <ExpandMore onClick={() => handleAccordionClick(index)} />
          }
        >
          <div className={styles.container}>
            <div className={styles.containerTitle}>
              <div className={styles.item}>
                <Checkbox
                  checked={isChecked}
                  onChange={onAccordionItemnClick}
                />
                <span>{nome}</span>
                {bloqueado && <Block className={colorIcon("red")} />}
              </div>
              <div className={styles.item}>
                {caixa ? (
                  <LockOpen className={colorIcon("green")} />
                ) : (
                  <Lock className={colorIcon("red")} />
                )}
                {redeChange()}
                <button
                  className={styles.buttonIcon}
                  onClick={(event) => setIsMenuPopover(event.currentTarget)}
                >
                  <MoreVert className={colorIcon()} />
                </button>
              </div>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Modelo:</span>
              <span>{modelo}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Tipo:</span>
              <span>{tipo}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Bateria:</span>
              <span>{batery()}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Bobina:</span>
              {bobina ? (
                <Tooltip
                  title="Com Bobina"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <CheckCircle className={colorIcon("green")} />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Sem Bobina"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <Cancel className={colorIcon("red")} />
                </Tooltip>
              )}
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Operador:</span>
              <span>{operador}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Sincronizado:</span>
              <span>{sincronizado}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Status:</span>
              <span>{status}</span>
            </div>
            <div className={styles.box}>
              <span className={styles.bold}>Versão:</span>
              <span>{versao}</span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={stylesMui.accordionDetails}>
          <div className={styles.details}>
            <div className={styles.itemDetails}>
              <span>Tempo de Resposta:</span>
              <span>{tempo_resposta}</span>
            </div>
            <div className={styles.itemDetails}>
              <span>IP:</span>
              <span>{ip}</span>
            </div>
            {rede !== "sem_conexao" && (
              <div className={styles.itemDetails}>
                {rede === "wifi" && <span>Wi-Fi:</span>}
                {rede === "dados" && <span>Operadora:</span>}
                <span>{nome_rede}</span>
              </div>
            )}
            <div className={styles.itemDetails}>
              <span>MAC ID:</span>
              <span>{totem}</span>
            </div>
            <div className={styles.itemDetails}>
              <span>Última transação:</span>
              <span>{transacao}</span>
            </div>
            <div className={styles.itemDetails}>
              <span>Último pedido:</span>
              <span>{pedido}</span>
            </div>
            <div className={styles.itemDetails}>
              <span>Última atualização de dados:</span>
              <span>{atualizacao}</span>
            </div>
            <div className={styles.itemDetails}>
              <span>Velocidade:</span>
              <span>{velocidade}</span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionItem;
