import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { useHistory } from "react-router-dom";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { UseConfigMobi } from "./UseConfigMobi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IMobiFormErrors, MobiValidation } from "./validade/MobiValidation";
import ConfigContentMobi from "../../components/_configCampaign/configContentMobi/ConfigContentMobi";
import { IPostMobiConfigRequest } from "modules/config/integrationsConfig/domain/dto/MobiConfig/IPostMobiConfigRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";

export interface IConfigMobiProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigMobi = ({ open, setOpen, step, setStep }: IConfigMobiProps) => {
  const history = useHistory();
  const { getIntegration, postMobiConfiguration, isLoading } = UseConfigMobi();
  const [values, setValues] = useState<IPostMobiConfigRequest>(
    {} as IPostMobiConfigRequest
  );
  const [errors, setErrors] = useState<IMobiFormErrors>({});

  const { currentLocal } = useLocal();

  const handleStatus = useMemo(
    () => getIntegration?.UsuarioNFCe ? "enabled" : "disabled",
    [getIntegration]
  );

  useEffect(() => {
    if (currentLocal) {
      setValues((prev) => ({ ...prev, LocalClienteId: currentLocal.id }));
    }
    if (getIntegration) {
      setValues(getIntegration);
    }
  }, [currentLocal, setValues, getIntegration]);

  const handleSubmit = useCallback(async () => {
    if (MobiValidation(values, setErrors)) {
      const res = await postMobiConfiguration(values);
      if (res === "ok") return setStep(step + 1);
    }
  }, [values, postMobiConfiguration, setStep, step]);


  const validateMobiUser = () => {
    if (values.LocalClienteId && values.UsuarioNFCe) {
      setOpen(IntegrationTypeEnum.MOBI);
    } else {
      history.push('/private/invoice');
    }
  }

  return (
    <>
      <ContentBox
        status={handleStatus}
        loading={isLoading}
        type={IntegrationTypeEnum.MOBI}
        text="Integração para gestão de negócios e ERP em diversos serviços"
        setOpen={validateMobiUser}
      />
      {open && open === "MOBI" && (
        <Sidesheet
          open={open}
          isLoading={isLoading}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            <ConfigContentMobi
              step={step}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          }
          cancelButton
          continueButton="Concluir"
          handleContinueButton={handleSubmit}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração realizada com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigMobi;
