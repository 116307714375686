import { IAddCouponRequest } from "../../domain/dto/IAddCouponRequest";
import { IAddCouponResponse } from "../../domain/dto/IAddCouponResponse";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";
import ParseAddCouponRequestUseCase from "./ParseAddCouponRequestUseCase ";


export const AddCouponUseCase = async (service: IDiscountCouponsService, request: IAddCouponRequest, localId: string): Promise<IAddCouponResponse> => {

    const parse = ParseAddCouponRequestUseCase(request, localId);
    const _request = await service.postAddCoupon(parse);
    return _request;
}


