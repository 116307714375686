import UseDimension from "components/dimension/UseDimension";
import styles from "components/sidesheet/Sidesheet.module.scss";

interface DeleteValidationProps {
  text: string;
  highlightText?: string;
}

const DeleteValidation = ({ text, highlightText }: DeleteValidationProps) => {
  const { isMobile } = UseDimension();

  return (
    <>
      <div
        className={`${styles.successContent} ${
          isMobile && styles.successContentMobile
        }`}
      >
        <img src={"/assets/icon/warning-icon.svg"} alt="Aviso" />
        <div className={styles.successText}>
          <span>{text}</span> <b>{highlightText}</b>
        </div>
      </div>
    </>
  );
};

export default DeleteValidation;
