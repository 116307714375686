import { ISort } from "interfaces/ISort";
import { IGetDiscountCouponsResponse } from "../../domain/dto/IGetDiscountCouponsResponse";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";
import { IDiscountCouponFilter } from "../../presentation/components/discountCouponFilter/interfaces/IDiscountCouponFilter";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";


const GetDiscountCouponsUseCase = async (service: IDiscountCouponsService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IDiscountCouponFilter): Promise<IGetDiscountCouponsResponse> => {
   
    const request =  ParseFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getDiscountCoupons(request, sort)
    return (response)
}
export default GetDiscountCouponsUseCase