import { IGetDiscountProductsItemResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsDiscountsApi } from '../../interfaces/IDiscountsService';

const GetProductsUseCase = async (
  discountsService: IEventsDiscountsApi,
  localId: string,
): Promise<IGetDiscountProductsItemResponse[]> => {
  return await discountsService.getDiscountProductsLists(localId);
};

export default GetProductsUseCase;
