import { FC } from 'react'
import { Cell, Pie, PieChart } from 'recharts';
import { ErrorMessage } from '../../../../../components/ui/errorMessage/ErrorMessage';
import { Skeleton } from '../skeleton/Skeleton';
import styles from './PieSalesChart.module.scss'

export type IPieChartDataItem = {
    id?: string;
    name: string;
    value: number;
    color: string;
};

export interface IPieSalesChartProps {
    data?: IPieChartDataItem[],
    isLoading: boolean,
    format?: boolean
}

const PieSalesChart: FC<IPieSalesChartProps> = ({ data, isLoading, format }) => {

    if (isLoading) return (
        <div className={styles.skeletonContainer}>
            <Skeleton items={1} containerStyle={styles.skeletonPieContentStyle} cardStyle={styles.skeletonPieCardStyle} />
            <Skeleton items={4} containerStyle={styles.skeletonContentStyle} cardStyle={styles.skeletonCardStyle} />
        </div>
    )

    if (!data?.reduce((acc, next) => acc + next.value, 0)) {
        return <div id={styles.PieSalesChart}>
            <ErrorMessage type="warning" />
        </div>
    }


    return (
        <div id={styles.PieSalesChart} >
            <div className={styles.pie} >
                <PieChart width={200} height={200} >
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color || '#ccc'} />
                        ))}
                    </Pie>
                    {/* <Legend /> */}
                </PieChart>
            </div>
            <div className={styles.legend}>
                {data?.map((entry, index) => (
                    <div key={index} className={styles.item} >
                        <div
                            style={{
                                width: 20,
                                minWidth: 20,
                                borderRadius: 2,
                                height: 20,
                                backgroundColor: entry?.color || '#ccc'
                            }}>
                        </div>
                        <label style={{ color: entry.color || '#ccc' }}>{format? formatCurrency(entry.value): (entry.value).toFixed(0)} {entry.name}</label>
                    </div>
                ))}
            </div>
        </div >
    )
}
export default PieSalesChart

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)
