import styles from "./SolicitationItem.module.scss";
import { format } from "date-fns";
import { IPosSolicitationsData } from "modules/admin/posSolicitations/domain/dto/IGetPosSolicitations";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import UseDimension from "components/dimension/UseDimension";
import { PendingExternalPosStatusEnum } from "modules/salesPos/domain/dto/IGetPendingExternalPosResponse";

interface ISolicitationItemProps {
  item: IPosSolicitationsData;
  setOpenImportSidesheet: React.Dispatch<React.SetStateAction<string>>;
  setOpenApproveSidesheet: React.Dispatch<React.SetStateAction<string>>;
}

const SolicitationItem = ({ item, setOpenImportSidesheet, setOpenApproveSidesheet }: ISolicitationItemProps) => {
  const { dimensions } = UseDimension();

  const getStatusText = (status: PendingExternalPosStatusEnum) => {
    switch (status) {
      case PendingExternalPosStatusEnum.Pending:
        return <span className={styles.actionButton} onClick={() => setOpenImportSidesheet(item.id)}>
          Importar
        </span>;
      case PendingExternalPosStatusEnum.Imported:
        return <span className={styles.actionButton} onClick={() => setOpenApproveSidesheet(item.id)}>
          Liberar
        </span>;
      case PendingExternalPosStatusEnum.Approved:
        return <span className={styles.actionButton} style={{ color: "#399C54" }}>
          Liberado
        </span>;
      default:
        return "-";
    }
  }

  return (
    <div id={styles.SolicitationItem}>
      {dimensions.width > 670 
        ? (
          <div className={styles.tableItem}>
            <span>{item.local.name}</span>
            <span>{(AcquirerTypeEnum)[item.acquirerType]}</span>
            <span>{item.createdAt ? format(new Date(item.createdAt), "dd/MM/yyyy' 'HH:mm") : "-"}</span>
            <span>{item.integrationCode}</span>
            <span>{item.serialNumber}</span>
            {getStatusText(item.status)}
          </div>
        ) : (
          <div className={styles.tableItemMobile}>
            <div className={styles.item}>
              <span className={styles.title}>Local</span>
              <span>{item.local.name}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.title}>Adquirente</span>
              <span>{(AcquirerTypeEnum)[item.acquirerType]}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.title}>Data da solicitação</span>
              <span>{item.createdAt ? format(new Date(item.createdAt), "dd/MM/yyyy' 'HH:mm") : "-"}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.title}>Número para liberação</span>
              <span>{item.integrationCode}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.title}>Número de serial</span>
              <span>{item.serialNumber}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.title}>Ação</span>
              {getStatusText(item.status)}
            </div>
          </div>
        )
      }
      
    </div>
  );
};

export default SolicitationItem;
