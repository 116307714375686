import { TextField } from "@material-ui/core";

import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { IProductFilterValue } from "./IProductFilterValue";
import styles from "./ProductFilter.module.scss";
import { IProductFilter } from "../../interfaces/IProductFilter";
import { ICategory } from "../../fragments/catalogFragment/CatalogFragment";
import { Autocomplete } from "@material-ui/lab";
import { ContainerLabel } from "../ui/ContainerLabel";
import Button from "components/ui/Button/Button";
import UseQueryParams from "../../hooks/UseQueryParams";

export interface IProductFilterProps {
  setFilterExport: React.Dispatch<React.SetStateAction<IProductFilter | undefined>>
  onChangeFilter: (filter: IProductFilter) => void
  filter: IProductFilter
  categoriesList?: ICategory[]
}

const ProductFilter: FC<IProductFilterProps> = ({ setFilterExport, onChangeFilter, categoriesList, filter }) => {
  const [values, setValues] = useState<IProductFilterValue>(
    {
      keyword: "",
      categories: [],
    }
  );
  const queryParams = UseQueryParams();

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onChangeFilter(values);
    },
    [onChangeFilter, values]
  );

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
      page: 0,
    }));
    setFilterExport((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
      page: 0,
    }));


  }, [setFilterExport]);

  const changeCategoriesHandle = useCallback(
    (values: { description: string; id: string }[]) => {
      setValues((prev) => ({ ...prev, categories: values, page: 0 }));
      setFilterExport((prev) => ({ ...prev, categories: values, page: 0 }));
      sessionStorage.setItem('@categories', JSON.stringify(values));
    },
    [setFilterExport]
  );

  useEffect(() => {
    const storedCategories = sessionStorage.getItem('@categories');
    const _filterCategories = queryParams.get("filterCategories");
    if (storedCategories) {
      if (_filterCategories) {
        const parsedCategories = JSON.parse(storedCategories);
        setValues((prev) => ({ ...prev, categories: parsedCategories, page: 0 }));
        setFilterExport((prev) => ({ ...prev, categories: parsedCategories, page: 0 }));
        onChangeFilter({categories: parsedCategories})
      } else {
        setValues((prev) => ({ ...prev, categories: [], page: 0 }));
        sessionStorage.removeItem('@categories')
      }
    }
  }, []);

  return (
    <form id={styles.ProductFilter} onSubmit={submitHandle}>
      <div className={styles.container}>
        <ContainerLabel label="Buscar produto" color="rgba(51, 51, 51, 1)">
          <TextField
            fullWidth
            placeholder="Digite o nome do produto"
            size="small"
            name="keyword"
            variant="outlined"
            value={values.keyword}
            onChange={changeHandle}
          />
        </ContainerLabel>
        <ContainerLabel label="Categorias" color="rgba(51, 51, 51, 1)">
          <Autocomplete
            fullWidth
            size="small"
            loading={!categoriesList}
            getOptionLabel={(option) => option.description}
            multiple
            value={values.categories ?? []}
            onChange={(ev, values) => changeCategoriesHandle(values)}
            renderInput={(params) => (
              <TextField {...params}
                variant="outlined"
                placeholder={`Selecione uma ou mais categorias`}
                size="small"
              />
            )}
            options={categoriesList ? categoriesList.sort((a, b) => a.description.localeCompare(b.description)) : []}
          />
        </ContainerLabel>
        {/* <TextField
          fullWidth
          size="small"
          name="productType"
          value={values.productType}
          select
          onChange={changeHandle}
        >
          <MenuItem value="">Filtre por tipo</MenuItem>
          <MenuItem value={0}>Produto</MenuItem>
          <MenuItem value={1}>Adicionais</MenuItem>
        </TextField> */}
        <div className={styles.containerButton}>
          <Button variant="contained" fullWidth type="submit" color="secondary">
            Buscar
          </Button>
        </div>
      </div>
    </form>
  );
};
export default ProductFilter;
