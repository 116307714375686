import GetPromotersFromListUseCase from "modules/events/application/useCases/access/GetPromotersFromListUseCase";
import GetPromotersFromLocalUseCase from "modules/events/application/useCases/access/GetPromotersFromLocalUseCase";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import { IGuestListForm } from "modules/events/presentation/components/guestListForm/interfaces/GuestListForm.types";
import { IPromoters } from "modules/events/presentation/components/guestListForm/interfaces/IPromoters";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback, useEffect, useState } from "react";
import { AccessApi } from "services/api/events/access/AccessApi";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { moneyToFloat } from "services/utils/Money";
import Utils from "services/utils/Utils";
import { IBaseList } from "../../interfaces/IEventGuestsListsPage";
import { GuestListFormStep } from "./IGuestListForm";
import { validate as validateDetails } from "./validation/DetailsFormValidation";
import { validate as validateDiscounts } from "./validation/DiscountsFormValidation";
import { IDetailsFormErrors, IDiscountsFormErrors, IPromotersFormErrors, IShareListFormErrors } from "./validation/IGuestListFormErros";
import { validate as validatePromoter } from "./validation/PromotersFormValidation";
import { useParams } from "react-router-dom";
import GetEventsDetailsUseCase from "modules/events/application/useCases/events/GetEventsDetailsUseCase";
import { EventsApi } from "services/api/events/events/EventsApi";

const accessService = AccessApi();
const eventsService = EventsApi();
const baseListService = DiscountBaseListApi();

const UseGuestListForm = (guestList: IGuestListForm | null, handleSubmit: (guestListForm: IGuestListForm) => void) => {
  const { currentLocal } = useLocal();
  const { eventId } = useParams<{ eventId: string }>();

  const [localId, setLocalId] = useState<string>();

  const [formType, setFormType] = useState<"ADD" | "EDIT">("ADD");

  const [isActive, setIsActive] = useState<boolean>(true);

  const [id, setId] = useState<string>();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [closingDateTime, setClosingDateTime] = useState<string>();
  const [copyConfiguration, setCopyConfiguration] = useState<boolean>(false);
  const [copyGuests, setCopyGuests] = useState<boolean>(false);

  const [mainPromoter, setMainPromoter] = useState<IPromoters>();
  const [promotersList, setPromotersList] = useState<IPromoters[]>([]);
  const [promotersWithAccess, setPromotersWithAccess] = useState<IPromoters[]>([]);

  const [detailsErrors, setDetailsErrors] = useState<IDetailsFormErrors>({});
  const [promotersErrors, setPromotersErrors] = useState<IPromotersFormErrors>({});
  const [discountsErrors, setDiscountsErrors] = useState<IDiscountsFormErrors>({});
  const [guestListErrors, setGuestListErrors] = useState<IShareListFormErrors>({});

  const [baseList, setBaseList] = useState<IBaseList>();
  const [useDiscountLimit, setUseDiscountLimit] = useState<boolean>(true);
  const [discountLimit, setDiscountLimit] = useState<number>();
  const [discountsList, setDiscountsList] = useState<IBaseList[]>([]);
  const [validUntil, setValidUntil] = useState<string | undefined>();
  const [enableDiscountExpiration, setEnableDiscountExpiration] = useState(false)

  const [shareEnabled, setShareEnabled] = useState<boolean>();
  const [shareUrl, setShareUrl] = useState<string>();
  const [shareDescription, setShareDescription] = useState<string>();

  const [formStep, setFormStep] = useState(GuestListFormStep.DETAILS);

  const [showUrlForm, setShowUrlForm] = useState(false);

  useEffect(() => {
    if (currentLocal?.id === '6a6a6bc6-5561-d166-3993-04648e423d04') {
      setShowUrlForm(true);
    }
  }, [currentLocal?.id])

  useEffect(() => {
    if (currentLocal) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  useEffect(() => {
    (async () => {
      if (!shareUrl && localId) {
        const response = await GetEventsDetailsUseCase(eventsService, eventId, localId);
        setShareUrl(response?.name)
      }
    })();
  }, [eventId, shareUrl, localId])

  useEffect(() => {
    if (guestList && currentLocal) {
      const getPromoterWithAccess = async (listId: string) => {
        const result = await GetPromotersFromListUseCase(accessService, listId, currentLocal.id);
        setPromotersWithAccess(result);
      };
      guestList?.id && getPromoterWithAccess(guestList.id);

      setFormType("EDIT");
      setId(guestList.id);
      setIsActive(guestList?.isActive);
      setName(guestList?.name);
      setDescription(guestList?.description);
      setClosingDateTime(Utils.toInputDateString(guestList?.closingDateTime ?? ""));
      setMainPromoter(guestList?.mainPromoter);
      setDiscountLimit(guestList?.discountLimit);
      setUseDiscountLimit(true);
      setBaseList(guestList?.baseList);
      setCopyConfiguration(guestList?.copyConfiguration);
      setCopyGuests(guestList?.copyGuests);
      setValidUntil(guestList?.validUntil ? Utils.toInputDateString(guestList?.validUntil ?? "") : undefined);
      setShareEnabled(guestList.shareEnabled || false);
      setShareUrl(guestList.shareUrl);
      setShareDescription(guestList.shareDescription);
    }
  }, [guestList]);

  useEffect(() => {
    const getPromotersList = async () => {
      try {
        if (localId) {
          const response = await GetPromotersFromLocalUseCase(accessService, localId);
          setPromotersList(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getPromotersList();

    const getDiscountsList = async () => {
      try {
        if (localId) {
          const response = await GetBaseLIstUseCase(baseListService, localId);
          setDiscountsList(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getDiscountsList();
  }, [localId]);

  const validateDetailsForm = useCallback(() => {
    if (validateDetails({ closingDateTime, description, name }, setDetailsErrors)) {
      return true;
    } else {
      return false;
    }
  }, [closingDateTime, description, name]);

  const validatePromoterForm = useCallback(() => {
    if (validatePromoter({ mainPromoter: mainPromoter?.id }, setPromotersErrors)) {
      return true;
    } else {
      return false;
    }
  }, [mainPromoter?.id]);

  const validateDiscountsForm = useCallback(() => {
    if (validateDiscounts({ discountLimit: discountLimit, useDiscountLimit }, { validUntil }, { closingDateTime }, setDiscountsErrors)) {
      return true;
    } else {
      return false;
    }
  }, [closingDateTime, discountLimit, useDiscountLimit, validUntil]);

  const validateUrlForm = useCallback(() => {
    return true;
  }, []);

  const validateStep = useCallback(() => {
    switch (formStep) {
      case GuestListFormStep.DETAILS:
        return validateDetailsForm();
      case GuestListFormStep.PROMOTERS:
        return validatePromoterForm();
      case GuestListFormStep.DISCOUNTS:
        return validateDiscountsForm();
      case GuestListFormStep.URL:
        return validateUrlForm();
      default:
        return false;
    }
  }, [formStep, validateDetailsForm, validateDiscountsForm, validatePromoterForm, validateUrlForm]);

  const handleFormStep = useCallback(
    (nextStep: GuestListFormStep) => {
      if (validateStep()) {
        setFormStep(nextStep);
      }
    },
    [validateStep]
  );


  const validateAll = useCallback(() => {
    if (!validateDetailsForm()) {
      handleFormStep(GuestListFormStep.DETAILS);
      return false;
    }
    if (!validatePromoterForm()) {
      handleFormStep(GuestListFormStep.PROMOTERS);
      return false;
    }
    if (!validateDiscountsForm()) {
      handleFormStep(GuestListFormStep.DISCOUNTS);
      return false;
    }
    return true;
  }, [handleFormStep, validateDetailsForm, validateDiscountsForm, validatePromoterForm]);

  const submit = useCallback(() => {
    if (validateAll()) {
      if (name && closingDateTime && description && mainPromoter) {
        const formValues: IGuestListForm = {
          id,
          name,
          closingDateTime,
          description,
          baseList,
          discountLimit,
          mainPromoter,
          isActive: !!isActive,
          copyConfiguration,
          copyGuests,
          validUntil,
          shareEnabled,
          shareDescription
        };

        handleSubmit(formValues);
      }
    }
  }, [validateAll, name, closingDateTime, description, mainPromoter, id, baseList, discountLimit, isActive, copyConfiguration, copyGuests, validUntil, shareEnabled, shareDescription, handleSubmit]);

  const handleSubmitButton = useCallback(
    () => {
      switch (formStep) {
        case GuestListFormStep.DETAILS:
          handleFormStep(GuestListFormStep.PROMOTERS);
          break
        case GuestListFormStep.PROMOTERS:
          handleFormStep(GuestListFormStep.DISCOUNTS);
          break
        case GuestListFormStep.DISCOUNTS:
          if (showUrlForm) {
            handleFormStep(GuestListFormStep.URL);
          } else {
            submit();
          }
          break
        case GuestListFormStep.URL:
          submit();
          break
        default:
          break;
      }
    },
    [formStep, handleFormStep, showUrlForm, submit],
  )

  useEffect(() => {
    if (!!guestList?.validUntil) {
      setEnableDiscountExpiration(true)
    } else {
      setEnableDiscountExpiration(false)
    }
  }, [guestList?.validUntil])

  const handleChangeIsActive = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsActive(checked);
  }, []);

  const handleChangeEnableDiscountExpiration = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableDiscountExpiration(event.target.checked);
    if (!event.target.checked) {
      setValidUntil(undefined);
    }
  }, []);

  const handleChangeDiscountLimit = useCallback((value: string) => {
    setDiscountLimit(moneyToFloat(value));
  }, []);

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  }, []);

  const handleChangeShareUrl = useCallback((value: string) => {
    setShareUrl(value);
  }, []);

  const handleChangeShareDescription = useCallback((value: string) => {
    setShareDescription(value);
  }, []);

  const handleChangeShareEnabled = useCallback((value: boolean) => {
    setShareEnabled(value);
  }, []);

  const handleChangeCopyConfiguration = useCallback((checked: boolean) => {
    setCopyConfiguration(checked);
  }, []);

  const handleChangeCopyGuests = useCallback((checked: boolean) => {
    setCopyGuests(checked);
  }, []);

  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }, []);

  const handleChangeDay = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setClosingDateTime(event.target.value);
  }, []);

  const handleChangeMainPromoter = useCallback((promoter: IPromoters) => {
    setMainPromoter(promoter);
  }, []);

  const changePromotersHandle = useCallback((promoters: IPromoters[]) => {
    setPromotersWithAccess(promoters);
  }, []);

  const handleChangeUseDiscountLimit = useCallback((useDiscountLimit: boolean) => {
    setUseDiscountLimit(useDiscountLimit);
  }, []);

  const handleChangeDiscountList = useCallback((list: IBaseList) => {
    setBaseList(list);
  }, []);

  const changeValidDateHandle = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValidUntil(ev?.target.value);
  }


  return {
    changePromotersHandle,
    closingDateTime,
    description,
    detailsErrors,
    discountLimit,
    discountsErrors,
    discountsList,
    formStep,
    formType,
    handleChangeDay,
    handleChangeDescription,
    handleChangeDiscountLimit,
    handleChangeDiscountList,
    handleChangeIsActive,
    handleChangeMainPromoter,
    handleChangeName,
    handleChangeUseDiscountLimit,
    handleFormStep,
    isActive,
    mainPromoter,
    name,
    promotersErrors,
    promotersList,
    promotersWithAccess,
    baseList,
    handleSubmitButton,
    useDiscountLimit,
    copyConfiguration,
    copyGuests,
    handleChangeCopyConfiguration,
    handleChangeCopyGuests,
    validUntil,
    changeValidDateHandle,
    handleChangeEnableDiscountExpiration,
    enableDiscountExpiration,
    handleChangeShareUrl,
    handleChangeShareDescription,
    handleChangeShareEnabled,
    shareEnabled,
    shareUrl,
    shareDescription,
    guestListErrors,
    showUrlForm
  };
};

export default UseGuestListForm;
