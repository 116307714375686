import { ICategoriesSales } from "components/graphs/categoriesSales/ICategoriesSales";
import IDashboardService from "modules/dashboard/application/interfaces/IDashboardService";
import {
  IAccountBalanceResponse,
  IAccountResponse,
} from "modules/dashboard/domain/dto/account/IAccountResponse";
import { IBarChartResponse } from "modules/dashboard/domain/dto/barChart/IBarChart.type";
import { ICashFlowResponse } from "modules/dashboard/domain/dto/cashFlow/ICashFlow.type";
import { ITotalConsumption } from "modules/dashboard/domain/dto/cashFlow/ITotalConsumption";
import { ITotalNotPaid } from "modules/dashboard/domain/dto/cashFlow/ITotalNotPaid";
import { ITotalPaid } from "modules/dashboard/domain/dto/cashFlow/ITotalPaid";
import { ICategoryListItemResponse } from "modules/dashboard/domain/dto/categoriesList/ICategoriesList.type";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import { IGetSessionsListResponse } from "modules/dashboard/domain/dto/getSessions/IGetSessionsListResponse";
import { IGradientTableResponse } from "modules/dashboard/domain/dto/gradientTable/IGradientTable.type";
import { IPizzaChartResponse } from "modules/dashboard/domain/dto/pizzaChart/IPizzaChart.type";
import { IPlaceResponse } from "modules/dashboard/domain/dto/place/IPlace.type";
import { ITicketMedioResponse } from "modules/dashboard/domain/dto/ticketMedio/ITicketMedioResponse";
import {
  ITopListResponse,
  ITopProductsListResponse,
} from "modules/dashboard/domain/dto/topList/ITopList.type";
import { IVerticalBarResponse } from "modules/dashboard/domain/dto/verticalBar/IVerticalBar.type";
import { api } from "../Api";
import BuscarTopOperadores from "./antiCorruption/BuscarOperadores";
import { getBuscarSessoes } from "./antiCorruption/BuscarSessoes";
import { IDelayedInvoicesResponse } from "modules/dashboard/domain/dto/delayedInvoices/IDelayedInvoicesResponse";

export const DashboardApi = (): IDashboardService => {
  const getAccounts = async (
    localId: string,
    selectedPlaces?: string[]
  ): Promise<IAccountResponse[]> => {
    const query = selectedPlaces?.length
      ? selectedPlaces
          .map((item, index) => `${index > 0 ? "&" : ""}selectedPlaces=${item}`)
          .join("")
      : "";

    const result = await api.get<IAccountResponse[]>(
      `Local/BuscarContasVirtuaisMeep?${query}`,
      { params: { disableError: true } }
    );
    
    return result.data;
  };
  const getAccountById = async (
    localId: string,
    accountId: string
  ): Promise<IAccountBalanceResponse> => {
    const result = await api.get<IAccountBalanceResponse>(
      `ContaDigital/BuscarSaldoDaContaBancaria/${localId}/${accountId}`,
      { params: { disableError: true } }
    );
    return result.data;
  };
  const getPlaces = async (
    request?: IFilterRequest,
    page?: number,
    pageSize?: number
  ): Promise<IPlaceResponse[]> => {
    let query = page ? `&page=${page}` : "&page=0";
    query += pageSize ? `&pageSize=${pageSize}` : "";
    const result = await api.get<IPlaceResponse[]>(
      `dashboard/v2/sales/PlacesSelector?startDate=${
        request?.startDate
      }&endDate=${request?.endDate}${query}&periodoBusca=${
        request?.periodoBusca
      }${request?.cashierId ? "&CashierId=" + request?.cashierId : ""}`
    );
    return result.data;
  };

  const getCashFlow = async (
    request: IFilterRequest
  ): Promise<ICashFlowResponse[]> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<ICashFlowResponse[]>(
      `dashboard/v2/sales/CashFlow${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      { params: { disableError: true } }
    );
    return result.data;
  };

  async function executeGetRequest<T>(
    request: IFilterRequest,
    uri: string
  ): Promise<T> {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");

    const result = await api.get<T>(
      `dashboard/v2/sales/${uri}${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      { params: { disableError: true } }
    );

    return result.data;
  }

  const getTotalPaid = async (request: IFilterRequest): Promise<ITotalPaid> => {
    return executeGetRequest<ITotalPaid>(request, "TotalPaid");
  };

  const getTotalNotPaid = async (
    request: IFilterRequest
  ): Promise<ITotalNotPaid> => {
    return executeGetRequest<ITotalNotPaid>(request, "TotalNotPaid");
  };

  const getTotalConsumption = async (
    request: IFilterRequest
  ): Promise<ITotalConsumption> => {
    return executeGetRequest<ITotalConsumption>(request, "TotalConsumption");
  };

  const getTicketMedio = async (
    request: IFilterRequest
  ): Promise<ITicketMedioResponse> => {
    const result = await executeGetRequest<ITicketMedioResponse>(
      request,
      "AverageTicketTables"
    );

    return result;
  };

  const getSaleByHouse = async (
    request: IFilterRequest
  ): Promise<IPizzaChartResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<IPizzaChartResponse>(
      `dashboard/v2/sales/SalesByStore${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      { params: { disableError: true } }
    );
    return result.data;
  };

  const getSalePerDay = async (
    request: IFilterRequest
  ): Promise<IVerticalBarResponse[]> => {
    // const selectedPlaces = '?' + request.selectedPlaces
    //   .map(item => `&selectedPlaces=${item}`)
    //   .join('');
    // const result = await api.get<IVerticalBarResponse[]>(
    //  '',
    //   { params: { disableError: true } },
    // );
    // return result.data;
    return Promise.resolve({} as IVerticalBarResponse[]);
  };

  const getPaymentFormEquipment = async (
    request: IFilterRequest
  ): Promise<IGradientTableResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<IGradientTableResponse>(
      `dashboard/v2/sales/GradiantTable${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      { params: { disableError: true } }
    );
    return result.data;
  };
  const getSaleByOperator = async (
    request: IFilterRequest
  ): Promise<IBarChartResponse[][]> => {
    // const selectedPlaces =
    //   "?" +
    //   request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    // const result = await api.get<IBarChartResponse[][]>(
    //   `dashboard/v2/sales/SalesByOperator${selectedPlaces}&startDate=${request?.startDate}&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}`,
    //   { params: { disableError: true } }
    // );
    // return result.data;

    const response = await BuscarTopOperadores(api, request);
    return response.records.map((item) =>
      item.map((_item) => ({
        label: _item.name,
        amount: _item.amount,
        index: _item.index,
      }))
    );
  };
  const getTopOperators = async (
    request: IFilterRequest
  ): Promise<ITopListResponse> => {
    // const selectedPlaces =
    //   "?" +
    //   request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    // const result = await api.get<ITopListResponse>(
    //   `dashboard/v2/sales/TopOperatorSales${selectedPlaces}&startDate=${request?.startDate}&endDate=${request?.endDate}`,
    //   { params: { disableError: true, page: request?.page, pageSize: request?.pageSize } }
    // );
    // return result.data;

    const response = await BuscarTopOperadores(api, request);
    return response;
  };
  const getTopProducts = async (
    request: IFilterRequest
  ): Promise<ITopProductsListResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");

    const uri = `dashboard/v2/sales/TopProductSales${selectedPlaces}&Sort.Orientation=${request?.sort?.order}&Sort.Type=${request?.sort?.type}&periodoBusca=${request.periodoBusca}`;

    const result = await api.get<ITopProductsListResponse>(uri, {
      params: {
        disableError: true,
        page: request?.page,
        pageSize: request?.pageSize,
        startDate: request?.startDate,
        endDate: request?.endDate,
      },
    });
    return result.data;
  };
  const getTopClients = async (
    request: IFilterRequest
  ): Promise<ITopListResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<ITopListResponse>(
      `dashboard/v2/sales/TopCustomers${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      {
        params: {
          disableError: true,
          page: request?.page,
          pageSize: request?.pageSize,
        },
      }
    );
    return result.data;
  };
  const getSaleByEquipment = async (
    request: IFilterRequest
  ): Promise<ITopListResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<ITopListResponse>(
      `dashboard/v2/sales/SalesByDevice${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      {
        params: {
          disableError: true,
          page: request?.page,
          pageSize: request?.pageSize,
        },
      }
    );
    return result.data;
  };
  const getSaleByCategory = async (
    request: IFilterRequest
  ): Promise<ICategoriesSales> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<ICategoriesSales>(
      `dashboard/v2/sales/CategoriesList${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      {
        params: {
          disableError: true,
          page: request?.page,
          pageSize: request?.pageSize,
        },
      }
    );
    return result.data;
  };

  const getSaleByCategoryId = async (
    request: IFilterRequest,
    categoryId: string
  ): Promise<ICategoryListItemResponse> => {
    const selectedPlaces =
      "?" +
      request.selectedPlaces.map((item) => `&selectedPlaces=${item}`).join("");
    const result = await api.get<ICategoryListItemResponse>(
      `dashboard/v2/sales/CategoriesListItens${selectedPlaces}&startDate=${
        request?.startDate
      }&endDate=${request?.endDate}&periodoBusca=${request.periodoBusca}${
        request?.cashierId ? "&CashierId=" + request?.cashierId : ""
      }`,
      {
        params: {
          disableError: true,
          page: request?.page,
          pageSize: request?.pageSize,
          CategoryId: categoryId,
        },
      }
    );
    return result.data;
  };

  const getSessions = async (
    localId: string
  ): Promise<IGetSessionsListResponse> => {
    const response = await getBuscarSessoes(api, localId);
    return response;
  };

  const getDelayedInvoices = async (localId: string): Promise<IDelayedInvoicesResponse> => {
    const result = await api.get(
      `/Admin/BuscarFaturasAtrasadas/${localId}`
    );
    return result.data;
  };

  return {
    getPlaces,
    getCashFlow,
    getTotalPaid,
    getTotalNotPaid,
    getTotalConsumption,
    getTicketMedio,
    getSaleByHouse,
    getPaymentFormEquipment,
    getSaleByOperator,
    getTopOperators,
    getTopProducts,
    getTopClients,
    getSaleByEquipment,
    getSaleByCategory,
    getAccounts,
    getAccountById,
    getSalePerDay,
    getSessions,
    getSaleByCategoryId,
    getDelayedInvoices
  };
};
