
import { IGetCustomersRequest } from "modules/customers/domain/dtos/IGetCustomersRequest"
import { IPagination } from "modules/customers/presentation/components/list/List"
import { ICustomerFilter } from "modules/customers/presentation/interfaces/ICustomerFilter"
import { ICustomerItem } from "modules/customers/presentation/interfaces/ICustomerItem"
import { v4 } from "uuid"
import { ICustomersService } from "../interfaces/ICustomersService"

const GetCustomersListUseCase = async (customerService: ICustomersService, filter: ICustomerFilter, pagination: { page: number, size: number, }): Promise<{ customers: ICustomerItem[], length: number }> => {

    // const request: IGetCustomersRequest
    return ({ customers: mock, length: 20 })
}
export default GetCustomersListUseCase



const mock: ICustomerItem[] = [
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
    { imageUrl: "teste", id: v4(), name: "Thiago José Oliveira e Silva", phone: "(31) 9 9477-0583", email: "teste@email.com", countVisit: "3", lastVisit: "05/10/2020", event: "Show Diferente", averageConsumer: "R$ 150,00", totalConsumer: "R$ 1250,00" },
]
