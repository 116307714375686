import React, { useCallback, useMemo, useState } from "react";
import styles from "./PrintersInfoModal.module.scss";
import { Close, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Slide } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} style={{ backgroundColor: "transparent" }} />;
});

export interface IPrintersInfoModalProps {
    open: boolean;
    onClose: () => void;
}

const PrintersInfoModal = ({ open, onClose }: IPrintersInfoModalProps) => {
    const [step, setStep] = useState(1);

    const contentByStep = useMemo(() => {
        switch (step) {
          case 1:
            return (
                <div className={styles.infoContent}>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/printer-modal-step1.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>1.</p>
                            <p>Baixe o <b>Meep Hub</b> no menu de Integrações ou{" "}
                                <a href="/private/automatizacao/integrations" target="_blank"><b style={{ color: "#32008E" }}>clique aqui</b></a>
                            </p>
                        </div>
                    </div>
                </div>
            );
          case 2:
            return (
                <div className={styles.infoContent}>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/printer-modal-step2.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>2.</p>
                            <p>Abra o Meep Hub e insira o <b>token</b>. Isso é necessário para identificarmos o seu estabelecimento.</p>
                        </div>
                    </div>
                </div>
            );
          case 3:
            return (
                <div className={styles.infoContent}>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/printer-modal-step3.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>3.</p>
                            <p>Depois disso, vamos criar uma configuração padrão para você imprimir pedidos, cupons fiscais e outras informações.</p>
                        </div>
                    </div>
                </div>
            );
          case 4:
            return (
                <div className={styles.infoContent}>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/printer-modal-step4.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>4.</p>
                            <p>Caso queira alterar ou criar novas, basta fazer pela tela de impressões. </p>
                        </div>
                    </div>
                </div>
            );
          default:
            return <div />
        }
    }, [step]);

    const handleCloseModal = useCallback(() => {
        onClose();
        setStep(1);
    }, [onClose]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseModal}
            PaperProps={{
                style: { backgroundColor: 'transparent', boxShadow: 'none' },
            }}
        >
            <div id={styles.PrintersInfoModal}>
                <img src="/assets/icon/saas/pos-modal-bg-2.svg" alt="" className={styles.modalBgBottom}/>
                <header>
                    <IconButton onClick={handleCloseModal}>
                        <Close />
                    </IconButton>
                </header>
                <DialogContent className={styles.modalContent}>
                    <div className={styles.containerInfo}>
                        {step !== 1 ? <IconButton onClick={() => setStep(step - 1)}><KeyboardArrowLeftOutlined /></IconButton> : <div />}
                        {contentByStep}
                        {step !== 4 ? <IconButton onClick={() => setStep(step + 1)}><KeyboardArrowRightOutlined /></IconButton> : <div />}
                    </div>
                    <div className={styles.contentStep}>
                        <div className={`${styles.step} ${step === 1 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 2 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 3 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 4 && styles.currentStep}`}/>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
};
export default PrintersInfoModal;
