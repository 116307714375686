import LandingPage from "components/freePage/LandingPage";
import Layout from "components/layout/presentation/Layout";
import ContentBox from "modules/config/integrationsConfig/presentation/components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import { useState, useEffect } from "react";

export const EverestDetail = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  return (
    <Layout>
      <LandingPage
        title="Everest"
        subtitle="Sistema ERP"
        description="Um sistema de gestão ERP + Internet Banking em um só lugar. Otimize seus processos e faça seu negócio crescer com o sistema de gestão Omie."
        aboutTitle={<>De ponta a ponta, o Everest viabiliza o <span className='purpleText'>controle total</span> de processos externos e internos!</>}
        about="O Everest conecta seu BackOffice, dos pedidos recebidos pelo seu PDV ao fechamento contábil do seu negócio. Você controla cada etapa da sua operação com transparência e eficiência."
        logo="/assets/icon/everest-detail.svg"
        showConnect={false}
        footerButtonLink="https://www.ajuda.meep.com.br/sistemaerp"
        includedItems={[
          {
            title: "Tecnologia e Inovação",
            description:
              "Solução SaaS que atua com Machine Learning e RPA em parte de seus processos.",
          },
          {
            title: "Adequação à legislação",
            description:
              "ERP em conformidade com todos os Regimes Tributários e alinhado às legislações vigentes.",
            img: "/assets/icon/everest/book-open.svg",
          },
          {
            title: "Automatização",
            description:
              "Automatize operações financeiras e contábeis e obtenha resultados com maior confiabilidade e assertividade.",
            img: "/assets/icon/omie/setting.svg",
          },
          {
            title: "Controle estratégico",
            description:
              "Controle pontos estratégicos da sua operação como estoques e CMV, de forma objetiva, fácil e transparente.",
            img: "/assets/icon/everest/data.svg",
          },
          {
            title: "Comparação entre lojas",
            description:
              "Monitore e compare a performance de vendas e informações dos produtos de cada uma de suas lojas.",
            img: "/assets/icon/everest/store.svg",
          },
          {
            title: "Personalização",
            description:
              "Onboarding completo para você começar a utilizar nossas funcionalidades da melhor maneira.",
            img: "/assets/icon/omie/edit.svg",
          },
        ]}
        setOpen={() => setOpen(IntegrationTypeEnum.EVEREST)}
      />

      {open && open === IntegrationTypeEnum.EVEREST && (
        <ContentBox
          status="unavailable"
          type={IntegrationTypeEnum.EVEREST}
          text="Integração para gestão de negócios e ERP em diversos serviços"
          setOpen={setOpen}
        />
      )}
    </Layout>
  );
};
