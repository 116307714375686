import React, { FC, useCallback, useMemo } from 'react'
import { INotificationItem } from '../components/notificationV2/INotificationItem';
import TerminalService from 'services/api/terminal/TerminalService';
import NotificationApi from 'services/api/notifications/NotificationApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetNotificationsUseCase from 'modules/notification/application/useCases/GetNotificationsUseCase';
import NotificationV2 from '../components/notificationV2/NotificationV2';
import ReadNotificationUseCase from 'modules/notification/application/useCases/_ReadNotificationUseCase';
import { NotificationType } from 'modules/notification/domain/TypeNotification';
import { INotification } from 'modules/notification/domain/INotification';
import PostApproveSolicitationUseCase from 'modules/Terminal/application/useCases/PostApproveSolicitationUseCase';
import PostRefuseSolicitationUseCase from 'modules/Terminal/application/useCases/PostRefuseSolicitationUseCase';
import { useQuery, useQueryClient } from 'react-query';
export interface INotificationButtonFragmentProps {
    //propertys
}
const NotificationFragment: FC<INotificationButtonFragmentProps> = () => {
    const { currentLocal } = useLocal();

    const getNotificationsHandler = useCallback(async () => {

        if (currentLocal?.id) {
            // setIsLoadingNotifications(true);
            const notificationService = NotificationApi();
            const terminalService = TerminalService();
            const notifications = await GetNotificationsUseCase(notificationService, terminalService, currentLocal.id);
            return (notifications);
        }


    }, [currentLocal?.id])

    const { status, data: notifications, isLoading, error, isFetching, refetch } = useQuery<INotification[] | undefined>(
        'notification', getNotificationsHandler,
        { refetchInterval: 60000, refetchOnWindowFocus: true, retry: false, enabled: !!currentLocal?.id }
    )




    const onClickAproveTerminal = useCallback(async (id: string) => {
        if (currentLocal?.id) {
            const terminalService = TerminalService();
            await PostApproveSolicitationUseCase(terminalService, currentLocal.id, id);
            // setNotifications(prev => [...prev.map(item => {
            //     if (item.id === id) {
            //         return { ...item, open: false, onConfirm: undefined, onDismiss: undefined }
            //     }
            //     return item;
            // })])
            refetch();
            // setNotifications(prev => prev.filter(item => item.id !== id))
        }
    }, [currentLocal?.id, refetch])
    const onClickRefuseTerminal = useCallback(async (id: string) => {
        if (currentLocal?.id) {
            const terminalService = TerminalService();
            await PostRefuseSolicitationUseCase(terminalService, currentLocal.id, id);
            // setNotifications(prev => [...prev.map(item => {
            //     if (item.id === id) {
            //         return { ...item, open: false, onConfirm: undefined, onDismiss: undefined }
            //     }
            //     return item;
            // })])y
            refetch();
            // setNotificrefetchations(prev => prev.filter(item => item.id !== id))

        }
    }, [currentLocal?.id, refetch])


    const readNotificationHandler = useCallback((item: INotificationItem) => {
        try {
            if (currentLocal?.id && item.type !== NotificationType.TERMINAL_SOLICITATION) {
                const notificationService = NotificationApi();
                ReadNotificationUseCase(notificationService, item.id);
            }
        }
        catch (e) {
            console.error(e);
        }
    }, [currentLocal?.id])

    const notificationToNotificationItem = useCallback((notification: INotification): INotificationItem => {
        return {
            id: notification.id,
            startDate: notification.startDate,
            description: notification.description,
            title: notification.title,
            type: notification.type,
            read: notification.read,
            open: notification.type === NotificationType.TERMINAL_SOLICITATION,
            onClickConfirm: notification.type === NotificationType.TERMINAL_SOLICITATION ? () => onClickAproveTerminal(notification.id) : undefined,
            confirmLabel: "Aprovar",
            onClickDismiss: notification.type === NotificationType.TERMINAL_SOLICITATION ? () => onClickRefuseTerminal(notification.id) : undefined,
            dismissLabel: "Negar",
        }
    }, [onClickAproveTerminal, onClickRefuseTerminal])






    const notiticationItem = useMemo(() => notifications?.map(item => notificationToNotificationItem(item)), [notificationToNotificationItem, notifications])

    React.useEffect(() => {
        getNotificationsHandler()
    }, [getNotificationsHandler]);

    return (
        currentLocal ? <NotificationV2 isLoading={isLoading} defaultNotifications={notiticationItem} onOpen={readNotificationHandler} /> : null
    )
}
export default NotificationFragment