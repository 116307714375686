import { IProductsService } from "modules/products/domain/interfaces/IProductsService";

import { IProductFilter } from "modules/products/presentation/interfaces/IProductFilter";
import { IProductListData } from "modules/products/presentation/interfaces/IProductListData";

const GetProductsListUseCase = async (
  service: IProductsService,
  localId: string,
  filter: IProductFilter
): Promise<IProductListData> => {
  const response = await service.getProductsList({
    ...filter,
    localId,
    categoriesIds: filter.categories?.map((item) => item.id),
  });
  return response;
};
export default GetProductsListUseCase;
