import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import DeviceProfileEventoPage from "modules/config/deviceConfig/presentation/pages/profileEvento/DeviceProfileEventoPage";
import UseManageEventPage from "modules/events/presentation/pages/manageEvent/UseManageEventPage";

const PerfilEventos: FC = () => {
  const { updateRouters } = useBreadcumbs();
  const { event } = UseManageEventPage()

  useEffect(() => {
    updateRouters([
      {
        title: "Evento/Caixa",
        url: "/private/eventos/list",
      },
      {
        title: event?.name
      },
      {
        title: "PDV",
      },
      {
        title: "Perfil",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters, event]);

  return (
    <Layout>
      <DeviceProfileEventoPage />
    </Layout>
  );
};
export default PerfilEventos;
