import { useCallback, useState } from "react";
import { SaasApi } from "services/api/saas/SaasApi";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import { PasswordRecoveryUseCase } from "modules/saas/application/useCases/PasswordRecoveryUseCase";
import { ISimpleForgetPassSchema } from "modules/saas/domain/schema/simpleForgetPassSchema";

export enum StepEnum {
    email = 1,
    local = 2
}

const service = SaasApi();

export const UseSimpleForgetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const { toast } = useUi();

    const handleRecoverPass = useCallback(async (value: ISimpleForgetPassSchema) => {
        let emailOrDocument = value.emailOrDocument;
        if (!value.emailOrDocument.includes("@")) {
            emailOrDocument = emailOrDocument.replace(/\D/g, "");
        }
        
        try {
            setIsLoading(true);

            const res = await PasswordRecoveryUseCase(service, emailOrDocument);
            
            if (res.success) {
                history.push(`/public/saas/simple-login?email=${res.email}`);
                return toast("O link de recuperação foi enviado para o seu email!", "success");
            }
            toast("Ocorreu um erro ao recuperar a senha. Tente novamente.", "error");
        } catch {
            toast('Ocorreu um erro ao recuperar a senha. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [history, toast]);

    return {
        isLoading,
        handleRecoverPass,
    }
}