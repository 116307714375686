import { Autocomplete, FormHelperText, Icon, MenuItem, Select, TextField } from "@mui/material";
import styles from "./MenuPai.module.scss";
import Button from "components/ui/Button/Button";
import { IMenuPaiFormErrors } from "./MenuPaiValidation";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Icons from '@mui/icons-material';
import { IPostCreateMenuPayload, MenuTypeEnum } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";
import { IGetMenuList } from "../AddMenuSidesheet";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";
import { removeAccentsAndSpace } from "services/utils/String";
import { Switch } from "@material-ui/core";

interface IMenuPaiProps {
    menuPaiValues: IPostCreateMenuPayload;
    setMenuPaiValues: React.Dispatch<React.SetStateAction<IPostCreateMenuPayload>>;
    errors: IMenuPaiFormErrors;
    setErrors: React.Dispatch<React.SetStateAction<IMenuPaiFormErrors>>;
    menuList: IGetMenuList[];
    menuPaiEditItem?: IPutEditMenuPayload;
    defaultPlans?: IPlans[];
}

export const selectPositionTypes = [
    {
        title: "Configuração",
        value: 0,
    },
    {
        title: "Evento",
        value: 1,
    },
    {
        title: "Local",
        value: 2,
    },
    {
        title: "Favoritos",
        value: 3,
    },
]

const MenuPai = ({ menuPaiValues, setMenuPaiValues, errors, setErrors, menuList, menuPaiEditItem, defaultPlans }: IMenuPaiProps) => {
    const [createdIcon, setCreatedIcon] = useState("");
    const [createdIconFormatted, setCreatedIconFormatted] = useState("");
    const [role, setRole] = useState("VisualizarMenu");

    useEffect(() => {
        if (menuPaiEditItem?.id) {
            setMenuPaiValues({
                title: menuPaiEditItem.title,
                description: menuPaiEditItem.description,
                role: menuPaiEditItem.role,
                icon: menuPaiEditItem.icon,
                route: menuPaiEditItem.route,
                menuConfigurationId: menuPaiEditItem.menuConfigurationId,
                positionType: menuPaiEditItem.positionType,
                parentId: "00000000-0000-0000-0000-000000000000",
                planIds: menuPaiEditItem.planIds,
                type: menuPaiEditItem.type,
            });
            setRole(menuPaiEditItem.role);
            setCreatedIconFormatted(menuPaiEditItem.icon);
        }
    }, [menuPaiEditItem, setMenuPaiValues]);

    const handleIcon = useCallback((iconName: string) => {
        const formattedIconName = iconName.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

        type IconType = {
            [key: string]: React.ElementType;
        };

        const iconNames: IconType = Icons;

        const IconComponent = iconNames[iconName];

        if (IconComponent) {
            setCreatedIconFormatted(formattedIconName);
            setMenuPaiValues({ ...menuPaiValues, icon: formattedIconName });
            setErrors((prev) => ({ ...prev, icon: undefined }));
            return formattedIconName;
        }
        return null;
    }, [menuPaiValues, setErrors, setMenuPaiValues]);

    const menusIcons = useMemo(() => {
        let a: string[] = [];

        for (let item of menuList) {
            for (let menu of item.menus) {
                if (menu.icon) {
                    a.push(menu.icon);
                }
            }
        }

        return a;
    }, [menuList]);

    const selectIcons = useMemo(() =>
        (createdIcon ? [...menusIcons, createdIconFormatted] : menusIcons) ?? []
        , [createdIcon, createdIconFormatted, menusIcons]);

    return (
        <div id={styles.MenuPai}>
            {(menuPaiEditItem &&  (menuPaiValues.icon || !menuPaiEditItem.id)) &&
                <>
                    <div className={styles.input}>
                        <label htmlFor="name" className={styles.required}>
                            Nome
                        </label>
                        <TextField
                            name="name"
                            variant="outlined"
                            size="small"
                            value={menuPaiValues.title}
                            onChange={(ev) => {
                                setMenuPaiValues({
                                    ...menuPaiValues,
                                    title: ev.target.value,
                                    role: `VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`,
                                    description: `Visualizar Menu ${ev.target.value}`,
                                });
                                setRole(`VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`);
                                setErrors((prev) => ({ ...prev, title: undefined }));
                            }}
                            error={!!errors.title}
                            helperText={errors.title}
                        />
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="permission">
                            Permissão
                        </label>
                        <TextField
                            name="permission"
                            variant="outlined"
                            size="small"
                            value={role}
                            disabled
                            className={styles.disabled}
                        />
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="permission-name">
                            Nome da permissão
                        </label>
                        <TextField
                            name="permission-name"
                            variant="outlined"
                            size="small"
                            value={menuPaiValues.description}
                            onChange={(ev) => {
                                setMenuPaiValues({ ...menuPaiValues, description: ev.target.value });
                            }}
                        />
                    </div>
                    <div className={styles.flexColumn}>
                        <div className={styles.inputIcon}>
                            <div className={styles.input} style={{ width: "110px" }}>
                                <label htmlFor="icon" className={styles.required}>
                                    Ícone
                                </label>
                                <Select
                                    name="icon"
                                    variant="outlined"
                                    size="small"
                                    value={menuPaiValues.icon}
                                    defaultValue={menuPaiValues.icon}
                                    onChange={(ev) => {
                                        setMenuPaiValues({ ...menuPaiValues, icon: ev.target.value });
                                        setErrors((prev) => ({ ...prev, icon: undefined }));
                                    }}
                                    error={!!errors.icon}
                                    className={menuPaiValues.icon ? styles.select : ""}
                                >
                                    {selectIcons.map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                        >
                                            <Icon>{option}</Icon>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.icon}</FormHelperText>
                            </div>
                            <div className={styles.input}>
                                <label htmlFor="name">
                                    Nome do ícone
                                </label>
                                <TextField
                                    name="name"
                                    variant="outlined"
                                    size="small"
                                    value={createdIcon}
                                    onChange={(ev) => {
                                        setCreatedIcon(ev.target.value);
                                        handleIcon(ev.target.value);
                                    }}
                                />
                            </div>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => window.open("https://mui.com/material-ui/material-icons/", "_blank")}
                            >
                                Acessar MUI
                            </Button>
                        </div>
                        <span>*Caso você não encontre o ícone que deseja, procure um no MUI e digite o nome do ícone</span>
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="position">
                            Posição
                        </label>
                        <Select
                            name="position"
                            variant="outlined"
                            size="small"
                            value={menuPaiValues.positionType}
                            onChange={(ev) => setMenuPaiValues({ ...menuPaiValues, positionType: Number(ev.target.value) })}
                            defaultValue={menuPaiValues.positionType}
                        >

                            {selectPositionTypes.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="plans">
                            Planos
                        </label>
                        <Autocomplete
                            options={defaultPlans ?? []}
                            getOptionLabel={option => option.name}
                            noOptionsText="Nenhuma opção encontrada"
                            multiple
                            limitTags={1}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Selecione os planos"
                                    InputProps={{
                                        style: { padding: '0 20px 0 10px' },
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            onChange={(_ev, values) => {
                                const plansIds = values.map((it) => it.id);
                                setMenuPaiValues({ ...menuPaiValues, planIds: plansIds });
                            }}
                        />
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="url">
                            URL
                        </label>
                        <TextField
                            name="url"
                            variant="outlined"
                            size="small"
                            value={menuPaiValues.route}
                            onChange={(ev) => {
                                setMenuPaiValues({ ...menuPaiValues, route: ev.target.value })
                            }}
                        />
                    </div>
                    <div className={`${styles.input} ${styles.switch}`}>
                        <Switch
                            checked={menuPaiValues.type === MenuTypeEnum.PREMIUM}
                            onChange={(_, value: boolean) => setMenuPaiValues({ ...menuPaiValues, type: value ? MenuTypeEnum.PREMIUM : MenuTypeEnum.STANDARD })}
                            id="display-crown-icon" />
                        <label htmlFor="display-crown-icon">Exibir ícone de coroa</label>
                    </div>                    
                </>
            }
        </div>
    )
}

export default MenuPai;