export default class Validate {

  public static isNull(value: any) {
    return value == null || value === undefined;
  }

  public static isNullOrEmpty(value: string | Date | null | undefined) {
    return this.isNull(value) || value === '';
  }

  public static isNullOrZero(value: number | null | undefined) {
    return this.isNull(value) || value === 0;
  } 
}
