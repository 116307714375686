import { ICrmService } from "modules/crm/models/interface/ICrmService";

const GetTicketCategories = async (
  service: ICrmService,
  OwnerId: string,
  Name: string
) => {
  const response = service.getTicketCategory({ ownerId: OwnerId, name: Name });

  return response;
};

export default GetTicketCategories;
