import React, { useEffect } from "react";
import styles from "./DisconnectedStep.module.scss";
import QrCodeIcon from "@mui/icons-material/QrCode";

export interface Props {
  qrCode?: string;
  getQrCode?: () => void
}

const DisconnectedStep = ({ qrCode, getQrCode }: Props) => {
  useEffect(() => {
    let timer: NodeJS.Timer | null = null
    if (getQrCode) {
      timer = setInterval(getQrCode, 30000)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [getQrCode])

  return (
    <div className={styles.content}>
      {/* {data.type !== "Email" ? ( */}
      <>
        {!qrCode ?
          <div>
            <h1 className={styles.step}>Passo 3/3</h1>
            <h2 className={styles.title}>Faça a leitura do QR Corde</h2>
            <div className={styles.contentText}>
              <p className={styles.text}>
                Após a confirmação da contratação é necessário{" "}
                <strong>aguardar até 8h</strong> para que o envio de campanhas via
                Whatsapp seja liberado. Não se preocupe: você será notificado via
                email quando estiver tudo pronto!
              </p>
            </div>
          </div>
          :
          <div className={styles.containerConfirm}>
            <img src={qrCode} />
            <div className={styles.contentAll}>
              <p className={styles.text}>
                Realize a leitura do QR Code no aplicativo Whatsapp do telefone
                que deseja enviar mensagens. Recomendamos o uso de um chip
                pré-pago por não possui nenhum vínculo fixo com operadoras.
              </p>
              <div className={styles.contentInfo}>
                <div className={styles.info}>
                  <p className={styles.statusContent}>
                    Status:{""}
                    <p className={styles.status}>Desconectado</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      </>
      {/* ) : (
        <div className={styles.contentEmail}>
          <div>
            <h1 className={styles.step}>Pronto</h1> 
            <h2 className={styles.title}>
              O disparo de emails já está disponível!
            </h2>
            <div className={styles.contentText}>
              <p className={styles.textEmail}>
                Criamos o email <strong>chalezinho@meep.com.br</strong> para que
                você possa enviar campanhas para seus clientes. Basta acessar{" "}
                <a className={styles.link}>
                  Meus clientes {">"} Criar nova campanha
                </a>{" "}
                para começar a utilizar o serviço.
              </p>
            </div>
          </div>
          <div className={styles.contentButtons}>
            <button>
              <CloseIcon fontSize="small" className={styles.button} /> Cancelar
              assinatura
            </button>
            <button>
              <PriorityHighRoundedIcon
                fontSize="small"
                className={styles.button}
              />
              Ver detalhes
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default DisconnectedStep;
