import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import UseManageEventPage from 'modules/events/presentation/pages/manageEvent/UseManageEventPage';
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage';
import TabPage from 'pages/private/dashboard/tabPage/TabPage';
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom';

interface ICashlessMenu {
    label: string;
    tab: string;
    url?: string;
    papel?: string;
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        papel: string;
    }[]
}

export const CashlessPage = () => {    
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();
    const {event} = UseManageEventPage()

    useEffect(() => {
        const currentTab = transactionsTabs.find(item => tab === item.tab);

        let routers = [
            {
                title: "Evento/Caixa",
                url: "/private/eventos/list",
            },
            {
                title: event?.name
            },
            {
                title: "Informações do cliente",
            },
            {
                title: "Cashless",
            },
            {
                label: "Cashless",
                title: currentTab?.label
            }
        ];

        if (currentTab?.tab === "gruposDeCartao") {
            routers = [
                {
                  title: 'Mapa de operação'
                },
                {
                    label: "Grupo de cartões",
                    title: currentTab?.label
                }
            ];
        }

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.label : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.label
            })
        }

        updateRouters(routers)
        return () => {
            updateRouters([])
        }
    }, [subTab, tab, updateRouters, event])

    const subTabComponent = useCallback((tabItem: ICashlessMenu) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/event/cashless/" + tabItem.tab}
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: `${subTabItem.tab}/`,
                    roles: subTabItem.papel,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        />
        : tabItem.component || <IframePage src={tabItem.url!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/event/cashless'}
            tabsPages={transactionsTabs.map((tabItem) => (
                {
                    label: tabItem.label,
                    tab: `${tabItem.tab}`,
                    roles: tabItem.papel,
                    component: subTabComponent(tabItem)
                }
            ))}
            hideTabs
        />
    )
}

const transactionsTabs: ICashlessMenu[] = [
    {
        label: "Cartões",
        tab: "cartoes",
        url: "/cashless/cartoes",
        papel: "CashlessCartoesPortal",
    },
    {
        label: "Recorrências",
        tab: "recorrencias",
        url: "/cashless/recorrencias",
        papel: "CashlessRecorrenciasPortal",
    },
    {
        label: "Grupos de cartão",
        tab: "gruposDeCartao",
        url: "/cashless/gruposDeCartao",
        papel: "CadastrarEtiquetaPortal",
    },
    {
        label: "Importar",
        tab: "importar",
        url: "/cashless/importar",
        papel: "ImportarUsuariosComCriacaoCartaoNominal",
    },
]
