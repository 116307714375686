export interface IPaymentLinkDatailsList {
    customer: Customer;
    billings: Billing[];
}

export interface Customer {
    name: string;
    document: string;
    phone: string;
    email: string;
    cardLastDigits: string;
    billingDay: number;
    cardBrand: string;
}

export interface Billing {
    date: string;
    amount: number;
    status: PaymentTransactionStatusEnum;
}
export enum PaymentTransactionStatusEnum {
    Unknown = 'Unknown',
    Created = 'Created',
    Pending = 'Pending',
    Authorized = 'Authorized',
    NotAuthorized = 'NotAuthorized',
    Captured = 'Captured',
    PartialCapture = 'PartialCapture',
    WaitingCapture = 'WaitingCapture',
    Refunded = 'Refunded',
    RefundRequested = 'RefundRequested',
    PartialRefunded = 'PartialRefunded',
    Cancelled = 'Cancelled',
    PartialVoid = 'PartialVoid',
    ErrorOnVoiding = 'ErrorOnVoiding',
    ErrorOnRefunding = 'ErrorOnRefunding',
    WaitingCancellation = 'WaitingCancellation',
    WithError = 'WithError',
    Failed = 'Failed',
    StatedDispute = 'StatedDispute',
    ChargedBack = 'ChargedBack'
}
