import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ProductTypeEnum } from "modules/events/presentation/pages/discountsList/components/discountListEditForm/interfaces/IDiscountProductListForm";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import qs from "qs";
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProductsApi } from "services/api/products/ProductsApi";
import UseEnableDisableAllModal from "../UseEnableDisableAllModal";
import { IProductFilter } from "../interfaces/IProductFilter";
import CopyProductUseCase from "modules/products/application/useCases/copyProductUseCase/CopyProductUseCase";
import GetCategoriesListUseCase from "modules/products/application/useCases/getCategoriesListUseCase/GetCategoriesListUseCase";
import GetProductsListUseCase from "modules/products/application/useCases/getProductsListUseCase/GetProductsListUseCase";
import { IProductFilterValue } from "../components/productFilters/IProductFilterValue";
import { IProductListData, IProductItem } from "../interfaces/IProductListData";
import GetExportProductListUseCase from "modules/products/application/useCases/getExportProductListUseCase/GetExportProductListUseCase";
import { apiUrl } from "Enviroment";
import GetExportProductRearguardUseCase from "modules/products/application/useCases/getExportProductRearguardUseCase/GetExportProductRearguardUseCase";
import { ILocalConfiguration } from "../interfaces/ILocalConfiguration";
import GetLocaLConfigurationUseCase from "modules/products/application/useCases/localConfiguration/GetLocalConfigurationUseCase";

const ProductContext = createContext({});

const ProductContextProvider: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const PAGESIZE = 20;
  const { currentLocal } = useLocal();
  const { push } = useHistory();
  const { showLoading, hideLoading, toast } = useUi();

  const { toggleEDAModal } = UseEnableDisableAllModal();

  const [filter, setFilter] = useState<IProductFilter>({
    keyword: "",
    page: 0, //inicia com zero
    pageSize: PAGESIZE,
    categories: [],
  });
  const isLoadingExport:boolean = false;
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState<IProductListData>();
  const [filterExport, setFilterExport] = useState<IProductFilter>();
  const [verifyAllChecked, setVerifyAllChecked] = useState<boolean>(true);
  const [exportSideSheet, setExportSideSheet] = useState(false);
  const [localConfiguration, setLocalConfiguration] =
    useState<ILocalConfiguration>();

  const [categoriesList, setCategoriesList] =
    useState<{ id: string; name: string }[]>();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (categoriesList && params) {
      const _page = params.get("page") as unknown;
      const _pageSize = params.get("pageSize") as unknown;
      const _keyword = params.get("keyword") as unknown;
      const _productType = params.get("productType") as unknown;
      const _categoriesIds = params.getAll("categoriesIds") as unknown;

      const categorieList = categoriesList?.filter((item) =>
        (_categoriesIds as string[])?.find((idItem) => idItem === item.id)
      );

      setFilter((prev) => ({
        page: _page ? Number(_page) : prev.page,
        pageSize: _pageSize ? Number(_pageSize) : prev.pageSize,
        keyword: _keyword ? (_keyword as string) : prev.keyword,
        productType: _productType
          ? (_productType as ProductTypeEnum)
          : prev.productType,
        categories: categorieList?.length ? categorieList : prev.categories,
      }));
    }
  }, [categoriesList, params]);

  const AllChecked = useCallback(() => {
    return !productList?.items?.some(function (produto) {
      return !produto?.enabled;
    });
  }, [productList?.items]);

  useEffect(() => {
    const checked = AllChecked();

    setVerifyAllChecked(checked);
  }, [AllChecked, productList?.items]);

  const getData = useCallback(
    async (filter: IProductFilter) => {
      if (currentLocal?.id) {
        try {
          setIsLoading(true);
          const ServiceProduct = ProductsApi();
          const response = await GetProductsListUseCase(
            ServiceProduct,
            currentLocal.id as string,
            filter
          );
          setProductList(response);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentLocal?.id]
  );

  const getLocalConfigurations = useCallback(async () => {
    try {
      if (currentLocal?.id) {
        const ServiceProduct = ProductsApi();
        const result = await GetLocaLConfigurationUseCase(
          ServiceProduct,
          currentLocal?.id
        );
        setLocalConfiguration(result);
      }
    } catch (error) {}
  }, [currentLocal?.id]);

  useEffect(() => {
    getLocalConfigurations();
  }, [getLocalConfigurations]);

  const getExportList = useCallback(
    async (extension: string) => {
      try {
        showLoading();
        if (extension === "PDF" || extension === "EXCELOPENXML") {
          const ServiceProduct = ProductsApi();
          const response = await GetExportProductListUseCase(
            ServiceProduct,
            currentLocal?.id as string,
            {
              categoriesIds: filterExport?.categories?.map((item) => item.id),
              keyword: filterExport?.keyword,
              extension: extension,
            }
          );
          window.open(
            apiUrl +
              "/download/file?filename=" +
              response?.FileName +
              "&relativepath=" +
              response.RelativePath,
            "_blank"
          );
          toast("Arquivo exportado com sucesso.", "success");
        } else if (extension === "RETAGUARDA") {
          const ServiceProduct = ProductsApi();
          await GetExportProductRearguardUseCase(
            ServiceProduct,
            currentLocal?.id as string
          );
          toast("Produtos exportados com sucesso.", "success");
        }
        setExportSideSheet(false);
      } catch (error) {
        toast("Falha ao exportar.", "error");
      } finally {
        hideLoading();
      }
    },
    [
      currentLocal?.id,
      filterExport?.categories,
      filterExport?.keyword,
      hideLoading,
      showLoading,
      toast,
    ]
  );

  const getCategoriesList = useCallback(async () => {
    if (currentLocal?.id) {
      try {
        const ServiceProduct = ProductsApi();
        const response = await GetCategoriesListUseCase(
          ServiceProduct,
          currentLocal?.id
        );
        setCategoriesList(response);
      } finally {
      }
    }
  }, [currentLocal?.id]);

  useEffect(() => {
    getData(filter);
  }, [getData, filter]);

  const upadateParams = useCallback(
    (filter: IProductFilterValue & { page?: number; pageSize?: number }) => {
      const _page = params.get("page") as unknown;
      const _pageSize = params.get("pageSize") as unknown;
      const _keyword = params.get("keyword") as unknown;
      const _productType = params.get("productType") as unknown;
      const _categoriesIds = params.getAll("categoriesIds") as unknown;

      const requestParams = {
        page: filter.page ?? _page ?? 0, //inicia com zero
        pageSize: filter.pageSize ?? _pageSize ?? PAGESIZE,
        productType: filter.productType ?? _productType, //todo:enum
        keyword: filter.keyword ?? _keyword,
      };
      const requestParamsCategories =
        filter.categories
          ?.map((item) => "&categoriesIds=" + item.id)
          .join("") ?? _categoriesIds;

      window.history.replaceState(
        null,
        "",
        `?${qs.stringify({ ...requestParams })}${requestParamsCategories}`
      );
    },
    [params]
  );

  const onChangeFilter = useCallback(
    (values: IProductFilterValue) => {
      upadateParams(values);
      setFilter((prev) => ({ ...prev, ...values }));
    },
    [upadateParams]
  );

  const onChangePagination = useCallback(
    (pagination: { page: number; pageSize?: number }) => {
      upadateParams({ ...pagination });
      setFilter((prev) => ({ ...prev, ...pagination }));
    },
    [upadateParams]
  );

  const onClickEdit = useCallback(
    (productId: string) => {
      push(`/private/products/edit/product/${productId}`);
    },
    [push]
  );

  const onClickAdd = useCallback(() => {
    push(`/private/products/add/product/item/`);
  }, [push]);

  const onClickDelete = useCallback(
    async (productId: string) => {
      if (currentLocal?.id) {
        showLoading();
        try {
          const ServiceProduct = ProductsApi();
          await ServiceProduct.deleteProduct(productId); //Tamporario
          setProductList(
            (prev) =>
              prev && {
                items: prev?.items.filter((item) => item.id !== productId),
                count: prev?.count - 1,
              }
          );
        } finally {
          hideLoading();
        }
      }
    },
    [currentLocal?.id, hideLoading, showLoading]
  );

  const onChangeEnabled = useCallback(
    async (productId: string, checked: boolean) => {
      if (currentLocal?.id) {
        try {
          showLoading();
          const ServiceProduct = ProductsApi();
          if (checked) {
            await ServiceProduct.activateProduct(productId);
          } else {
            await ServiceProduct.disableProduct(productId);
          }
        } finally {
          hideLoading();
        }
      }
    },
    [currentLocal?.id, hideLoading, showLoading]
  );

  const onEnableDisableAll = useCallback(
    async (checked: boolean) => {
      if (currentLocal?.id) {
        try {
          showLoading();
          const ServiceProduct = ProductsApi();

          const count = productList?.items.length as number;
          const items = productList?.items;

          for (let i = 0; i < count; i++) {
            const item = items?.[i];

            if (!item?.id && i !== count) {
              continue;
            } else if (!item?.id && i === count) {
              getData(filter);
              continue;
            }

            const productId = item?.id as string;
            if (checked) {
              await ServiceProduct.activateProduct(productId).catch(() =>
                toast(
                  `Não foi possível habilitar o produto ${item?.name}`,
                  "error"
                )
              );
            } else {
              await ServiceProduct.disableProduct(productId).catch(() =>
                toast(
                  `Não foi possível desabilitar o produto ${item?.name}`,
                  "error"
                )
              );
            }
          }
        } finally {
          getData(filter);
          toggleEDAModal();
          hideLoading();
        }
      }
    },
    [
      currentLocal?.id,
      filter,
      getData,
      hideLoading,
      productList?.items,
      showLoading,
      toast,
      toggleEDAModal,
    ]
  );

  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  const onClickCopy = useCallback(
    async (item: IProductItem) => {
      showLoading();
      try {
        const ServiceProduct = ProductsApi();
        await CopyProductUseCase(ServiceProduct, item.id);
        getData(filter);
      } finally {
        hideLoading();
      }
    },
    [filter, getData, hideLoading, showLoading]
  );

  return (
    <ProductContext.Provider
      value={{
        productList,
        onChangeFilter,
        onChangePagination,
        onClickEdit,
        isLoading,
        filter,
        categoriesList,
        onClickDelete,
        onChangeEnabled,
        onEnableDisableAll,
        onClickAdd,
        onClickCopy,
        getData,
        verifyAllChecked,
        getExportList,
        setFilterExport,
        exportSideSheet,
        setExportSideSheet,
        isLoadingExport,
        localConfiguration,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContextProvider, ProductContext };
