import { BaseTextFieldProps, createStyles, InputAdornment, InputLabelProps, makeStyles, TextField, TextFieldProps, Theme } from "@material-ui/core";
import React, { FC, InputHTMLAttributes } from "react";
import styles from "./InputTextArea.module.scss";

export interface InputProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  multiline?: boolean;
  endAdornmentText?: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  className?: string;
  fullwidth?: boolean;
  name?: string;
  rows?: number;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  select?: boolean;
  disabled?: boolean;
  testId?: string;
  label?: string
  InputLabelProps?: Partial<InputLabelProps>,
  error?: boolean
  helperText?: React.ReactNode;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
        border: '1px solid black'
      },
    },
  }),
);

const InputTextArea: FC<InputProps> = ({
  inputProps,
  children,
  multiline,
  onChange,
  value,
  type,
  className,
  variant,
  fullWidth = true,
  name,
  rows,
  required,
  placeholder,
  select,
  disabled,
  testId,
  label,
  InputLabelProps,
  error,
  helperText
}) => {
  const classes = useStyles();
  return (
    <TextField

      size="small"
      select={select}
      name={name}
      rows={rows}
      className={className && styles.textfield && classes.root}
      multiline={multiline}
      value={value}
      variant={variant}
      placeholder={placeholder}
      color={"secondary"}
      type={type}
      fullWidth={fullWidth}
      onChange={onChange}
      required={required}
      label={label}
      InputLabelProps={InputLabelProps}
      inputProps={{
        ...inputProps,
        "data-testid": testId,
        // classes: { underline: styles.underlineClass },
        // endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      }}

      error={error}
      helperText={helperText}

    >
      {children}
    </TextField >
  );
};

export default InputTextArea;
