import DashboarContainer from 'modules/salesDashboard/presentation/components/dashboarContainer/DashboarContainer'
import GridDashboard from 'modules/salesDashboard/presentation/components/gridDashboard/GridDashboard'
import PieSalesChart from 'modules/salesDashboard/presentation/components/pieSales/PieSalesChart'
import { FC } from 'react'
import CheckinPerHourBarChart from './components/checkinPerHourBarChart/CheckinPerHourBarChart'
import CheckinTable from './components/checkinTable/CheckinTable'
import PermanenceTable from './components/permanencyTable/PermanencyTable'
import styles from './CustomersDashboardPage.module.scss'
import UseCheckinTable from './hook/UseCheckinTable'
import UseCustomersDashboardPage from './UseCustomersDashboardPage'
import AverageTicketConsume from './components/averageTicketConsume/AverageTicketConsume'
import UseAverageTicketConsume from './hook/UseAverageTicketConsume'
import GuestListLive from './components/guestList/GuestListCustomer'
import { UseGuestList } from './hook/UseGuestList'
import { UseCustomerList } from './hook/UseCustomerList'
import CustomerList from './components/customerList/CustomerList'
import AverageTicketBarByTimeChart from './components/averageTicketBarChartByTime/AverageTicketBarByTimeChart'
// import UseCanceledProductRanking from './hook/UseCanceledProductRanking'
// import UseProductRanking from './hook/UseProductRanking'
// import ProductRanking from './components/productRaking/ProductRanking'
import PieVouchersChart from './components/pieVouchers/PieVouchersChart'
import UseVouchers from './hook/UseVouchers'
import ProductRanking from './components/productRaking/ProductRanking'
import UseCanceledProductRanking from './hook/UseCanceledProductRanking'
import UseProductRanking from './hook/UseProductRanking'
import OperationFilter from './components/operationFilter/OperationFilter'
import UseLastOrdersRanking from './hook/UseLastOrdersRanking'
import LastOrdersRanking from './components/_lastOrdersRanking/LastOrdersRanking'
import AutocompleteEvents from './components/filter/AutocompleteEvents'
import UseProductsCanceledByOperator from './hook/UseProductsCanceledByOperator'
export interface ICustomersDashboardPageProps {
    //propertys
}
const CustomersDashboardPage: FC<ICustomersDashboardPageProps> = () => {
    const { getEventList, filter, setFilter, operationFilter, setOperationFilterFilter,eventList } = UseCustomersDashboardPage();
    const checkinTable = UseCheckinTable(filter);
    const vouchers = UseVouchers(filter);
    // const checkinPerHour = UseCheckinPerHour(filter);
    // const permanencyTable = UsePermanencyTable(filter);
    const averageCustomerConsumption = UseAverageTicketConsume(filter);
    const guestList = UseGuestList(filter);
    const customerList = UseCustomerList(filter);
    const canceledProductRanking = UseCanceledProductRanking(filter, operationFilter);//inserir filtro de operacoes
    const productRanking = UseProductRanking(filter, operationFilter);//inserir filtro de operacoes
    const lastOrders = UseLastOrdersRanking(filter, operationFilter);//inserir filtro de operacoes
    const productsCanceledByOperator = UseProductsCanceledByOperator(filter, operationFilter);//inserir filtro de operacoes

    return (
        <div id={styles.CustomersDashboardPage} >
            <GridDashboard>
                <DashboarContainer isChartContainer={false}>
                    <AutocompleteEvents eventList={eventList} filter={filter}  getEventList={getEventList} onChange={item => setFilter(prev => ({ ...prev, eventId: item.eventId, cashierId: item.cashierId }))} />
                    {/* <FilterAutocompleteEventsList eventId={filter.eventId} getEventList={getEventList} onChangeEvent={item => setFilter(prev => ({ ...prev, eventId: item }))} /> */}
                </DashboarContainer>
                {
                    (filter.eventId || filter.cashierId) &&
                    <>
                        <DashboarContainer span={3} title='Check-in' tooltip='Quantidade do público presente no evento, relação da média de idade e correlação com o sexo.'>
                            <CheckinTable  {...checkinTable} />
                        </DashboarContainer>

                        <DashboarContainer span={3} title='Gráfico de Check-in' tooltip='Gráfico com a proporção do público do evento separado pelo sexo.'>
                            <PieSalesChart isLoading={checkinTable.isLoading} data={checkinTable.checkinPieData} />
                        </DashboarContainer>

                        <DashboarContainer span={3} title='Entrada por Hora' tooltip='Gráfico de barras relacionando a quantidade de pessoas que entraram no evento pelo horário de funcionamento.'>
                            <CheckinPerHourBarChart {...checkinTable}  />
                        </DashboarContainer>

                        <DashboarContainer span={3} title='Permanência' tooltip='Esse bloco é voltado somente para estabelecimentos que usam o sistema pós-pago, onde há uma etapa de saída de cliente que é registrada no sistema Meep. Informações de retenção de clientes, total e por sexo, no evento.'>
                            <PermanenceTable {...checkinTable} />
                        </DashboarContainer>

                        <DashboarContainer span={3} borderless isChartContainer={false}>
                            <DashboarContainer span={3} title='Ticket médio de consumo' tooltip='Informações de valor médio de consumo do público, total e por sexo, no evento e sua origem.'>
                                <AverageTicketConsume type="products" {...averageCustomerConsumption} />
                            </DashboarContainer>

                            <DashboarContainer span={3} title='Ticket médio de ingresso' tooltip='Informações de valor médio de compra de ingressos do público, total e por sexo, no evento e sua origem.'>
                                <AverageTicketConsume type="tickets" {...averageCustomerConsumption} />
                            </DashboarContainer>
                        </DashboarContainer>

                        <DashboarContainer span={3} title='Utilização de cupom' tooltip='Valor usuado de cada cupom e o valor total somando todos os cupons utilizados.'>
                            <PieVouchersChart {...vouchers} />
                        </DashboarContainer>

                        <DashboarContainer span={6} title='Ticket médio por hora' tooltip='Gráfico de barras relacionando o ticket médio do público, total e por sexo, pelo horário de funcionamento.'>
                            <AverageTicketBarByTimeChart {...averageCustomerConsumption} />
                        </DashboarContainer>

                        <DashboarContainer span={6} title='Detalhes dos clientes' tooltip='Lista de clientes com detalhamento de idade, data de aniversário, histórico de visitas ao local e consumo total no evento.'>
                            <CustomerList
                                {...customerList}
                                averageTicket={averageCustomerConsumption.data?.products?.consume?.value}
                            />
                        </DashboarContainer>


                        <DashboarContainer span={6} title='Conversão de convidados' tooltip='Conversão das listas de convidados do evento. Número de convidados, quantos comparam, quantos entraram e o ticket médio.'>
                            <GuestListLive {...guestList} />
                        </DashboarContainer>

                        <DashboarContainer hideIcons={true} title={'Filtre de acordo com a operacão:'} span={6}>
                            <OperationFilter onChange={setOperationFilterFilter} />
                        </DashboarContainer>
                        <DashboarContainer span={6} title='Produtos' tooltip='Lista de detalhamento de produtos mais vendidos e cancelamentos organizados por operador que foram realizados no evento.' >
                            <ProductRanking
                                dataProduct={productRanking.data}
                                getDataProduct={productRanking.getData}
                                onLoadingProduct={productRanking.isLoading}
                                dataCanceledProduct={canceledProductRanking.data}
                                getDataCanceledProduct={canceledProductRanking.getData}
                                onLoadingCanceledProduct={canceledProductRanking.isLoading}
                                eventId={filter.eventId}
                                getProductCanceledByOperator={productsCanceledByOperator.getData}
                                dataProductCanceledByOperator={productsCanceledByOperator.data}
                                onLoadingProductCanceledByOperator={productsCanceledByOperator.isLoading}
                            />
                        </DashboarContainer>
                        <DashboarContainer span={6} title='Últimos pedidos' tooltip='Lista de detalhamento das últimas vendas do evento em tempo real.' >
                            <LastOrdersRanking  {...lastOrders} />
                        </DashboarContainer>

                    </>
                }
            </GridDashboard>
        </div>
    )
}
export default CustomersDashboardPage