import { FC } from "react";
import styles from "./StatusBox.module.scss";
import { StatusBoxProps } from "./StatusBox.types";
import { SvgIcons } from "../SvgIcons/SvgIcons";

const StatusBox: FC<StatusBoxProps> = ({ type, message }) => {
  const renderIcon = () => {
    switch (type) {
      case "sucess":
        return <SvgIcons.Sucess />;
      case "warning":
        return <SvgIcons.Warning />;
      default:
        break;
    }
  };

  return (
    <div className={styles.statusBox}>
      {renderIcon()}
      <p>{message}</p>
    </div>
  );
};

export { StatusBox };
