import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import styles from "./ForDeviceFilter.module.scss";
import { CloseOutlined, KeyboardArrowRightOutlined, SortOutlined, VerticalAlignBottomOutlined } from "@material-ui/icons";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { TabFilter } from "components/ui/tabFilter/TabFilter";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import UseCashlessDashboardPage from "modules/cashlessDashboard/presentation/UseCashlessDashboardPage";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { endOfDay, format, startOfDay } from "date-fns";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { UseSaleForDevicePage } from "../../hooks/UseSaleForDevicePage";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { EnumDeviceTypes } from "modules/saleForDevice/domain/enum/EnumDeviceTypes";
import Utils from "services/utils/Utils";

export interface IForDeviceFilterProps {
    params: IPostSaleForDeviceParams;
    setParams: React.Dispatch<React.SetStateAction<IPostSaleForDeviceParams>>;
    handleOpenSidesheet: (startDate: Date, endDate: Date) => void;
    handleOnClickExport: () => void;
    showExportButton: boolean;
    totalDevices: number;
}

export const deviceTypeFilter = [
    {
        id: 0,
        name: "Todos",
    },
    {
        id: EnumDeviceTypes.POS,
        name: "POS",
    },
    {
        id: EnumDeviceTypes.TOTEM,
        name: "Totem",
    },
    {
        id: EnumDeviceTypes.TABLET,
        name: "Tablet",
    },
];

const ForDeviceFilter = ({ params, setParams, handleOpenSidesheet, handleOnClickExport, showExportButton, totalDevices, }: IForDeviceFilterProps) => {
    const { toast } = useUi();
    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();
    const { cashiers } = UseCashlessDashboardPage();
    const { vendingDevices, profileList, getAdvancedFiltersOptions, isLoading } = UseSaleForDevicePage();

    const [filterBy, setFilterBy] = useState("");
    const [advancedFilters, setAdvancedFilters] = useState(false);
    const [filters, setFilters] = useState({ localId: currentLocal?.id, TiposDispositivos: [0] } as IPostSaleForDeviceParams);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        getAdvancedFiltersOptions();
    }, [getAdvancedFiltersOptions]);

    const handleChangeFilterBy = useCallback((filter: string) => {
        if (filter === 'period') {
            setFilters({ ...filters, dataInicio: startOfDay(new Date()).toISOString(), dataFim: endOfDay(new Date()).toISOString(), sessaoId: "" })
        }
        else {
            setFilters({ ...filters, dataInicio: "", dataFim: "", sessaoId: "" });
        }
        return setFilterBy(filter);
    }, [filters]);

    const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const selectedDateTime = new Date(ev.target.value);
        const dateTimeInUTC = selectedDateTime.toISOString();
        setFilters((prev) => ({ ...prev, [ev.target.name]: dateTimeInUTC }));
    }, []);
    const onChangeDeviceType = useCallback((item: { id: number, name: string }) => {
        let arr: number[] = [];

        if (item.id === 0) {
            return setFilters({ ...filters, TiposDispositivos: [0] });
        }

        const removeAllSelected = filters.TiposDispositivos?.filter((x) => x !== 0) ?? [];

        const selectedItem = filters.TiposDispositivos?.find((x) => x === item.id);

        if (selectedItem) {
            arr = removeAllSelected?.filter((x) => x !== item.id) ?? [];
            if (item.id === 3) {
                arr = arr?.filter((x) => x !== 4) ?? [];
            }
        } else {
            arr = [...(removeAllSelected ?? []), item.id];
            if (item.id === 3) {
                arr = [...arr, 4];
            }
        }

        if (!arr.length) {
            return setFilters({ ...filters, TiposDispositivos: [0] });
        }
        setFilters({ ...filters, TiposDispositivos: arr });
    }, [filters]);

    const handleSubmit = useCallback(() => {
        setIsSearching(true);
        if (filters.TiposDispositivos?.length && filters.TiposDispositivos[0] === 0) {
            return setParams({ ...filters, TiposDispositivos: [] });
        }
        setParams(filters);
    }, [filters, setParams]);

    const handleClickOpenSidesheet = useCallback(() => {
        if (filters.dataInicio && filters.dataFim) {
            handleOpenSidesheet(new Date(filters.dataInicio ?? ""), new Date(filters.dataFim ?? ""));
            return;
        }
        toast("Selecione um período.", "error");
    }, [toast, filters.dataInicio, filters.dataFim, handleOpenSidesheet]);

    return (
        <div id={styles.ForDeviceFilter}>
            <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
                <div className={styles.input}>
                    <span className={styles.required}>Filtrar por</span>
                    <Select
                        value={filterBy}
                        size="small"
                        onChange={(ev) => handleChangeFilterBy(ev.target.value)}
                    >
                        <MenuItem value="period">Período</MenuItem>
                        <MenuItem value="cashier">Caixa</MenuItem>
                    </Select>
                </div>
                {filterBy === "period" && (
                    <>
                        <div className={styles.input}>
                            <span className={styles.required}>Inicio</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TextField
                                    size='small'
                                    name={'dataInicio'}
                                    onChange={changeHandleDate}
                                    fullWidth
                                    value={filters.dataInicio && Utils.toInputDateString(filters.dataInicio)}
                                    type="datetime-local" />
                            </LocalizationProvider>

                        </div>
                        <div className={styles.input}>
                            <span className={styles.required}>Fim</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TextField
                                    size='small'
                                    name={'dataFim'}
                                    onChange={changeHandleDate}
                                    fullWidth
                                    value={filters.dataFim && Utils.toInputDateString(filters.dataFim)}
                                    type="datetime-local" />
                            </LocalizationProvider>
                        </div>
                    </>
                )}
                {filterBy === "cashier" && (
                    <div className={styles.input} style={{ width: "100%", maxWidth: dimensions.width > 650 ? "350px" : "100%" }}>
                        <span className={styles.required}>Caixa</span>
                        <Select
                            style={{ flex: 2, minWidth: 350 }}
                            size="small"
                            onChange={(ev) => setFilters({ ...filters, sessaoId: ev.target.value })}
                            value={filters.sessaoId}
                        >
                            {cashiers.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
                {!advancedFilters && <Button color="secondary" onClick={handleSubmit} disabled={isLoading}>Buscar</Button>}
            </div>
            <div className={`${styles.displayRow} ${styles.filterEndButton}`} style={{ justifyContent: "space-between" }}>
                {params.dataInicio && params.dataFim ? (
                    <div className={styles.btnRow} onClick={handleClickOpenSidesheet}>
                        <span>Caixas do período filtrado</span>
                        <KeyboardArrowRightOutlined />
                    </div>
                ) : (
                    <div style={{ width: "10px" }} />
                )}
                <div className={`${styles.displayRow} ${styles.endButtons}`} style={{ justifyContent: "space-between" }}>
                    {showExportButton ? (
                        <div className={styles.btnRow} onClick={handleOnClickExport}>
                            <span>Exportar</span>
                            <VerticalAlignBottomOutlined />
                        </div>
                    ) : (<div />)}
                    <div className={styles.btnRow} onClick={() => setAdvancedFilters(!advancedFilters)}>
                        <span>Filtros avançados</span>
                        {advancedFilters ? <CloseOutlined style={{ color: "rgba(95, 95, 95, 1)", width: "16px" }} /> : <SortOutlined style={{ color: "rgba(95, 95, 95, 1)" }} />}
                    </div>
                </div>
            </div>
            {advancedFilters && (
                <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
                    <div className={styles.filters}>
                        <FormItemContainer
                            label="Tipo de equipamento"
                            labelSize="14px"
                            className={styles.formLabel}
                            padding={0}
                            labelMargin="0 0 8px 0"
                        >
                            <TabFilter
                                items={deviceTypeFilter}
                                value="id"
                                label="name"
                                selectedItems={filters.TiposDispositivos?.map((x) => x.toString()) ?? []}
                                onChange={onChangeDeviceType}
                            />
                        </FormItemContainer>
                    </div>
                    <div className={styles.input}>
                        <span>Equipamento</span>
                        <Autocomplete
                            options={vendingDevices}
                            getOptionLabel={option => option.nome}
                            noOptionsText="Nenhuma opção encontrada"
                            multiple
                            limitTags={1}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Filtrar por equipamento"
                                    InputProps={{
                                        style: { padding: '0 20px 0 10px' },
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            onChange={(_ev, values) => {
                                const posIds = values.map((it) => it.id);
                                setFilters({ ...filters, usuarioMeepPOSIds: posIds });
                            }}
                        />
                    </div>
                    <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "200px" : "100%" }}>
                        <span>Venda ou faturamento</span>
                        <Select
                            name="Order"
                            size="small"
                            placeholder="Selecione"
                            onChange={(ev) => setFilters({ ...filters, dateType: Number(ev.target.value) })}
                        >
                            <MenuItem value={1}>Data da venda</MenuItem>
                            <MenuItem value={2}>Data do faturamento</MenuItem>
                        </Select>
                    </div>
                    <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}>
                        <span>PDV</span>
                        <Autocomplete
                            options={profileList}
                            getOptionLabel={option => option.name}
                            noOptionsText="Nenhuma opção encontrada"
                            multiple
                            limitTags={1}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Filtrar por PDV"
                                    InputProps={{
                                        style: { padding: '0 20px 0 10px' },
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            onChange={(_ev, values) => {
                                const profilesIds = values.map((it) => it.id);
                                setFilters({ ...filters, profileIds: profilesIds });
                            }}
                        />
                    </div>
                    <Button color="secondary" onClick={handleSubmit} disabled={isLoading}>Buscar</Button>
                </div>
            )}
            {filters.dataInicio && filters.dataFim && isSearching && (
                <div className={styles.btnRow} style={{ cursor: "default" }}>
                    <span><b>Período:</b> {filters.dataInicio ? format(new Date(filters.dataInicio), "dd/MM/yyyy' - 'HH:mm") : "-"} <b>até</b> {filters.dataFim ? format(new Date(filters.dataFim), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
                </div>
            )}
            {totalDevices > 0 && (<div className={styles.totalDevices}>Total de equipamentos: {totalDevices}</div>)}
        </div>
    )
}

export default ForDeviceFilter;