import { IGetTutoriaisParams } from "modules/tutoriais/domain/dto/IGetTutoriaisParams";
import { IGetTutoriaisResponse } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { ITutoriaisService } from "modules/tutoriais/domain/interface/ITutoriaisService";
import { api } from 'services/api/Api';

const baseUrl = process.env.REACT_APP_DYNAMICMENU_URL;

const TutoriaisApi = (): ITutoriaisService => {
    const getTutoriais = async (params?: IGetTutoriaisParams): Promise<IGetTutoriaisResponse> => {
        const respomse = await api.get<IGetTutoriaisResponse>(`${baseUrl}/PageWizard/`, {
          params: params
        });
        return respomse.data;
    }

    return {getTutoriais};
}

export default TutoriaisApi;
