import React, { useCallback, useEffect, useRef, useState } from 'react'
import { v4 } from 'uuid';
import { IAvalableDaysPlaceForm } from '../placeScheduleForm/interface/IAvalableDaysPlaceScheduleForm'



export interface IWeekDays {
    label: string,
    value: number
}


const UseAvailableDaysForm = (onChange: (values: IAvalableDaysPlaceForm[]) => void, values?: IAvalableDaysPlaceForm[]) => {



    const [selectedDay, setselectedDay] = useState<IWeekDays>();
    const [selectedInicio, setSelectedInicio] = useState<string>();
    const [selectedFim, setSelectedFim] = useState<string>();
    const days = [
        { label: "Domingo", value: 0 },
        { label: "Segunda", value: 1 },
        { label: "Terça", value: 2 },
        { label: "Quarta", value: 3 },
        { label: "Quinta", value: 4 },
        { label: "Sexta", value: 5 },
        { label: "Sábado", value: 6 },
    ]
    const [weekDays, setWeekDays] = useState(days)
    const [hideButtonEdit, setHideButtonEdit] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState("")



    useEffect(() => {
        values?.map(value => {
            weekDays.map((weekDay, index) => {
                if (value.dayOfWeek === weekDay.value) {
                    weekDays.splice(index, 1)
                }
            })
        })
    }, [values, weekDays]);


    const validate = useCallback(
        () => {
            if (values?.find(item => selectedDay?.value === item.dayOfWeek && selectedFim + ":00" === item.endHour && selectedInicio + ":00" === item.startHour)) {
                setError("Intervalo ja foi cadastrado");
                return false
            } else {
                setError("");
                return true
            }
        },
        [values, selectedDay, selectedFim, selectedInicio],
    )

    const onClickAdd = useCallback(() => {


        if (selectedInicio && selectedFim && selectedDay && validate()) {

            if (inputRef.current) {
                inputRef.current.value = ""
            }
            onChange([...values ?? [], { id: v4(), startHour: selectedInicio, endHour: selectedFim, dayOfWeek: selectedDay.value }])

            setSelectedInicio("")
            setSelectedFim("")
            setselectedDay(undefined)

            const indexDay = weekDays.findIndex((item) => (item.value === selectedDay.value))
            if (indexDay !== -1) {
                weekDays.splice(indexDay, 1);
            }

        }
        setHideButtonEdit(false)

    }, [selectedInicio, selectedFim, selectedDay, validate, onChange, values, weekDays, days])

    const onClickRemoveItem = useCallback((index: number) => {
        if (values) {
            var remove = [...values];
            if (index !== -1) {
                remove.splice(index, 1);
                onChange(remove)
            }
        }

        let value = values?.find((item, idx) => {
            if (index === idx) {
                return item;
            }
        });

        const findDay = days.find(day => day.value === value?.dayOfWeek)
        if (weekDays.findIndex(weekDay => weekDay.value === findDay?.value) === -1) {

            findDay && setWeekDays([...weekDays, findDay])
        }

    }, [days, onChange, values, weekDays])

    const onClickEditItem = useCallback((dayOfWeekItem: number) => {
        if (values) {
            const item = values.find(_item => _item.dayOfWeek === dayOfWeekItem)
            setselectedDay(days.find(day => day?.value === item?.dayOfWeek));
            setSelectedInicio(item?.startHour ?? undefined);
            setSelectedFim(item?.endHour ?? undefined);
            onChange(values.filter(item => item.dayOfWeek !== dayOfWeekItem));
        }

        let value = values?.find(item => item.dayOfWeek === dayOfWeekItem);

        const findDay = days.find(day => day.value === value?.dayOfWeek);
        if (weekDays.findIndex(weekDay => weekDay.value === findDay?.value) === -1) {

            findDay && setWeekDays([...weekDays, findDay]);
        }

        setHideButtonEdit(true);

    }, [days, onChange, values, weekDays])

    return (
        {
            selectedDay,
            setselectedDay,
            selectedInicio,
            selectedFim,
            setSelectedInicio,
            setSelectedFim,
            onClickAdd,
            onClickRemoveItem,
            onClickEditItem,
            weekDays,
            error,
            inputRef,
            hideButtonEdit
        }
    )
}

export default UseAvailableDaysForm
