
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IPermutationForm } from "../interfaces/IPermutationForm";
import { AddPermutationAccountUseCase } from "modules/permutation/application/useCases/AddPermutationAccountUseCase";

const UseAddPermutationAccount = () => {

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successAdd, setSuccessAdd] = useState(false)
  

  const postAddPermutation = useCallback(
    async (values: IPermutationForm) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingAdd(true);
        if (currentLocal) {
           await AddPermutationAccountUseCase(servicePermutation,
            {...values},
            currentLocal.id,
            );
            toast("Permuta criada com sucesso", "success")        
            setSuccessAdd(true)
          return 'ok'
        }
      }  catch {
        setSuccessAdd(false)
      }
      
      finally {
        setIsLoadingAdd(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessAdd,
    successAdd,
    isLoadingAdd,
    postAddPermutation,   

  };
};

export default UseAddPermutationAccount;
