import React, { FC, useCallback, useMemo, useState } from 'react'
import styles from './IconColorPicker.module.scss'
import { Icon, Popover } from '@material-ui/core'
import { FidelityColors } from './Colors'

export interface IColor { colors: string[] }
export interface IPicker {
    onChange: (value: IColor) => void,
    value?: IColor
}
export const PickerColor: FC<IPicker> = ({ onChange, value }) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    var isSameColor = useCallback((item: IColor) => value?.colors[0] === item?.colors[0] && value.colors?.[1] === item?.colors[1], [value])


    const onChangeHandle = (item: IColor) => {
        onChange(item);
        handleClose()

    }

    return (
        <div id={styles.ColorPicker} >
            <button type='button' className={styles.container} onClick={handleClick}>
                {value ?
                    <div className={styles.color}
                        style={{
                            background: value?.colors.length === 1 ? value.colors[0] : `linear-gradient(to bottom, ${value?.colors[0]}, ${value?.colors[1]})`
                        }}
                    /> : <div></div>}
                <Icon className={styles.arrowIcon} >expand_more</Icon>
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={styles.popover}>
                    <h4>Solidas</h4>
                    <div className={styles.category}>
                        {FidelityColors.filter(item => item.colors.length === 1).map((item, index) => (
                            <div
                                key={index}
                                className={styles.color}
                                style={{
                                    background: item.colors.length === 1 ? item.colors[0] : `linear-gradient(to bottom, ${item.colors[0]}, ${item.colors[1]})`
                                }}
                                onClick={() => onChangeHandle(item)}
                            >
                            </div>
                        ))}
                    </div>
                    {/* <h4>Gradiente</h4>
                    <div className={styles.category}>
                        {FidelityColors.filter(item => item.colors.length === 2).map((item, index) => (
                            <div
                                key={index}
                                className={styles.color}
                                style={{
                                    background: item.colors.length === 1 ? item.colors[0] : `linear-gradient(to bottom, ${item.colors[0]}, ${item.colors[1]})`
                                }}
                                onClick={() => onChangeHandle(item)}
                            >
                            </div>
                        ))}
                    </div> */}
                </div>

            </Popover>
        </div>
    )
}


