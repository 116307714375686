import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IGetPreRechargeMethodsDataResponse } from "modules/cashlessDashboard/domain/dto/preRechargePaymentMethodsData/IGetPreRechargeMethodsDataResponse";

const GetPreRechargeMethodsUseCase = async (
  service: ICashlessDashboardService,
  cashierIds: string[],
): Promise<IGetPreRechargeMethodsDataResponse> => {
  const response = await service.getPreRechargeMethodsData(cashierIds);

  return response;
};

export default GetPreRechargeMethodsUseCase;
