import styles from "../accordionContent/AccordionContent.module.scss";
import { format } from "date-fns";
import { CircularProgress } from "@material-ui/core";
import { IGetContractResponse } from "modules/equipamento/domain/dto/IGetContractResponse";
import { IGetEquipmentsResponse } from "modules/equipamento/domain/dto/IGetEquipmentsResponse";

export interface IAccordionContentMobileProps {
  contractTitle: string[];
  contractLicenses?: IGetContractResponse[];
  equipmentsTitle: string[];
  equipments?: IGetEquipmentsResponse;
}

const AccordionContentMobile = ({
  contractTitle,
  contractLicenses,
  equipmentsTitle,
  equipments,
}: IAccordionContentMobileProps) => {
  return (
    <>
      <div className={styles.accordionContainer}>
        <div
          className={`${styles.licensesContainer} ${styles.licensesContainerMobile}`}
        >
          {!contractLicenses ? (
            <div className={styles.flexCenter}>
              <CircularProgress size={16} color="inherit" />
            </div>
          ) : (
            contractLicenses.map((contract) => (
              <div
                key={contract.Numero}
                className={`${styles.contractsGrid} ${styles.contractsGridMobile}`}
              >
                <div className={styles.gridMobile}>
                  {contractTitle.map((title) => (
                    <span key={title}>
                      <b>{title}</b>
                    </span>
                  ))}
                </div>
                <div className={styles.gridMobile} style={{ textAlign: "end" }}>
                  <span>{contract.Numero}</span>
                  <span>
                    {format(new Date(contract.DataInicio), "dd/MM/yyyy")}
                  </span>
                  <span>
                    {format(new Date(contract.DataFim), "dd/MM/yyyy")}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
        <div
          className={`${styles.licensesContainer} ${styles.licensesContainerMobile}`}
        >
          {!equipments ? (
            <div className={styles.flexCenter}>
              <CircularProgress size={16} color="inherit" />
            </div>
          ) : (
            equipments.Records.map((equipment) => (
              <div
                key={equipment.Licenses}
                className={`${styles.contractsGrid} ${styles.contractsGridMobile}`}
                style={{ gridTemplateRows: "1fr 1fr 1fr 1fr" }}
              >
                <div className={styles.gridMobile}>
                  {equipmentsTitle.map((title) => (
                    <span key={title}>
                      <b>{title}</b>
                    </span>
                  ))}
                </div>
                <div className={styles.gridMobile} style={{ textAlign: "end" }}>
                  <span>{equipment.DeviceTypeName}</span>
                  <span>{equipment.Licenses}</span>
                  <span>{equipment.Used}</span>
                  <span>{equipment.Available}</span>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default AccordionContentMobile;
