import UseDimension from "components/dimension/UseDimension";
import styles from "./ModalGestaoVendas.module.scss";
import { TaskAlt, Close } from "@mui/icons-material";
interface IModalGestaoVendas {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalGestaoVendas = ({ setOpenModal }: IModalGestaoVendas) => {
  const {dimensions} = UseDimension()
  return (
    <div id={styles.ModalGestaoVendas}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <TaskAlt />
            <h1>PRONTO!</h1>
          </div>
          <p>
            Agora que você concluiu as configurações necessárias do Gestão de
            Venda, <b>é hora de aumentar o seu faturamento</b>{" "}
            <span>vendendo mais e melhor!</span>
          </p>
        </div>
        <Close className={styles.close} onClick={() => setOpenModal(false)} />
        <img src={dimensions.width > 800 ? "/assets/img/gestao_vendas/img_modal.png" : "/assets/img/gestao_vendas/img_modal_mobile.png"} alt="imagem modal" />
      </div>
    </div>
  );
};

export default ModalGestaoVendas;
