import GetCashiersUseCase from "modules/cashlessDashboard/application/useCases/GetCashiersUseCase";
import { ICashierItem } from "modules/cashlessDashboard/presentation/components/filter/ICashier";
import GetProfileListUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/GetProfileListUseCase";
import { IDeviceProfileItem } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileList/interfaces/IProfileItem";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import PostSaleForDeviceUseCase from "modules/saleForDevice/application/PostSaleForDeviceUseCase";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";
import { IPostSaleForDeviceResponse } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceResponse";
import GetVendingDeviceUseCase from "modules/ticketsReport/application/useCases/GetVendingDeviceUseCase";
import { IVendingDevice } from "modules/ticketsReport/presentation/interfaces/IVendingDevice";
import { IDeviceType } from "modules/ticketsReport/presentation/interfaces/IDeviceType";
import { useCallback, useState } from "react";
import CashlessDashboardService from "services/api/cashlessDashboard/CashlessDashboardService";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import SaleForDeviceReportApi from "services/api/saleForDeviceReport/SaleForDeviceReportApi";
import TicketsReportService from "services/api/ticketsReport/TicketsReportService";

const forDeviceApi = SaleForDeviceReportApi();
const ticketsReportService = TicketsReportService();
const profileService = DeviceProfileApi();
const cashlessDashboardService = CashlessDashboardService();

export const UseSaleForDevicePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState<IPostSaleForDeviceResponse>();
    const [vendingDevices, setVendingDevices] = useState<IVendingDevice[]>([]);
    const [devicesTypes, setDeviceTypes] = useState<IDeviceType[]>([]);
    const [profileList, setProfileList] = useState<IDeviceProfileItem[]>([]);
    const [cashiersByPeriod, setCashiersByPeriod] = useState<ICashierItem[]>([]);
    const { currentLocal } = useLocal();

    const postSaleForDeviceReport = useCallback(
      async (params: IPostSaleForDeviceParams) => {
        if (currentLocal) {
            try {
            setIsLoading(true);
            params.shouldUseNewSchema = currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas;
            const response = await PostSaleForDeviceUseCase(forDeviceApi, {...params, localId: currentLocal.id});
            setReportData(response);
            } catch (error: any) {
              return error.response;
            } finally {
            setIsLoading(false);
            }
        }
      },
      [currentLocal]
    );

    const getAdvancedFiltersOptions = useCallback(
      async () => {
        if (currentLocal) {
          try {
            setIsLoading(true);
            const promises = [
              GetVendingDeviceUseCase(ticketsReportService, currentLocal.id),
              GetProfileListUseCase(profileService, currentLocal.id, {}, { IncludeDeletedProfiles: true, limit: 500 }),
            ];
    
            const response: Array<any> = await Promise.all(promises);
            setVendingDevices(response[0]);
            setProfileList(response[1]);
          } catch (error: any) {
            return error.response;
          } finally {
            setIsLoading(false);
          }
        }
      },
      [currentLocal]
    );

    const getCashiersByPeriod = useCallback(
      async (startDate: Date, endDate: Date) => {
        if (currentLocal) {
            try {
              setIsLoading(true);
              const response = await GetCashiersUseCase(cashlessDashboardService, currentLocal.id, startDate, endDate);
              setCashiersByPeriod(response);
            } catch (error: any) {
              return error.response;
            } finally {
              setIsLoading(false);
            }
        }
      },
      [currentLocal]
    );

    return {
        isLoading,
        reportData,
        vendingDevices,
        devicesTypes,
        profileList,
        cashiersByPeriod,
        postSaleForDeviceReport,
        getAdvancedFiltersOptions,
        getCashiersByPeriod,
    }
}