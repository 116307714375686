import { useCallback, useEffect, useMemo, useState } from "react";
import CrmHeaderInfo from "../../components/report/headerInfo/_HeaderInfo";
import Funnel from "../../components/report/_funnel/_Funnel";
import CrmReportCustomerList from "../../components/report/crmReportCustomerList/CrmReportCustomerList";
import { CampaignStatus } from "../../intefaces/ICampaign";
import styles from "./CrmReportPage.module.scss";
import UseCampaignMessages from "../../hook/UseCampaignMessages";
import { useParams } from "react-router-dom";
import ErroCrmMessageAlert from "../../components/report/erroCrmMessageAlert/ErroCrmMessageAlert";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { Drawer } from "@material-ui/core";
export interface ICrmCampaignPageProps {
  //propertys
}

const CrmReportPage = () => {

  const { campaignId } = useParams<{ campaignId: string }>()
  const { showLoading, hideLoading } = useUi()

  const {
    campaignMessages,
    isLoadingMessageList,
    getCampaignMessages,
    getCampaigTotals,
    campaignTotals,
    isLoadingCampaignTotals,
    resendCampaign,
    campaignFailedMessages,
    isLoadingFailed,
    getFailedCampaignMessages,
    stopCampaign
  } = UseCampaignMessages();

  // const statusWhasapp = UseWhatsappConfiguration();

  const [openFailedModal, setOpenFailedModal] = useState(false)


  const getData = useCallback(
    (pagination: { page: number }, hasFailed?: boolean) => {
      getCampaignMessages(campaignId, { pagination, hasFailed })
    },
    [campaignId, getCampaignMessages]
  );
  const getFailedData = useCallback(
    (pagination: { page: number }) => {
      getFailedCampaignMessages(campaignId, { pagination })
    },
    [campaignId, getFailedCampaignMessages]
  );

  const resendHandle = useCallback(async () => {
    try {
      showLoading()
      await resendCampaign(campaignId);
      hideLoading()
      getCampaignMessages(campaignId, { pagination: { page: 1 } })
      getCampaigTotals(campaignId)
    } finally {
      hideLoading()
    }
  }, [campaignId, getCampaigTotals, getCampaignMessages, hideLoading, resendCampaign, showLoading])

  const stopHandle = useCallback(async () => {
    try {
      showLoading()
      await stopCampaign(campaignId);
      hideLoading()
      getCampaignMessages(campaignId, { pagination: { page: 1 } })
      getCampaigTotals(campaignId)
    } finally {
      hideLoading()
    }
  }, [campaignId, getCampaigTotals, getCampaignMessages, hideLoading, showLoading, stopCampaign])


  useEffect(() => {
    getCampaignMessages(campaignId, { pagination: { page: 1, pageSize: 10 } })
  }, [campaignId, getCampaignMessages])

  useEffect(() => {
    getCampaigTotals(campaignId)
  }, [campaignId, getCampaigTotals])


  useEffect(() => {
    if (openFailedModal) {
      getFailedCampaignMessages(campaignId, { pagination: { page: 1 } })
    }
  }, [campaignId, getFailedCampaignMessages, openFailedModal])




  const funnelData = useMemo(() => ([
    {
      title: "Mensagens enviadas",
      value: campaignTotals?.totalSent,
    }, {
      title: "Mensagens entregues",
      value: campaignTotals?.totalReceived,
    }, {
      title: "Cliques no link",
      value: campaignTotals?.totalClicked,
    }, {
      title: "Comprados",
      value: campaignTotals?.totalPurchased,
    }
  ]), [campaignTotals?.totalClicked, campaignTotals?.totalPurchased, campaignTotals?.totalReceived, campaignTotals?.totalSent])

  return (
    <div id={styles.CrmReportPage}>
      {/* <StatusWhatsapp absolute {...statusWhasapp} /> */}
      <div className={styles.container}>
        <CrmHeaderInfo
          isLoading={isLoadingCampaignTotals}
          onClickStop={stopHandle}
          data={campaignTotals?.campaign}
          totalCostumers={campaignTotals?.totalCustomers || campaignTotals?.total}
          totalFail={campaignTotals?.totalFail}
        />
        <Funnel
          isLoading={isLoadingCampaignTotals}
          data={funnelData}
          total={campaignTotals?.totalCustomers || 0}
          totalFail={campaignTotals?.totalFail || 0}
          campaignStatus={campaignTotals?.campaign.status}
        />
        {
          !!campaignTotals?.totalFail &&
          campaignTotals.campaign.status === CampaignStatus.Sent &&
          <ErroCrmMessageAlert
            onClickResend={resendHandle}
            totalFail={campaignTotals?.totalFail}
            view
            onClickView={() => { setOpenFailedModal(true) }}
          />
        }
        <CrmReportCustomerList
          campaingChannel={campaignTotals?.campaign.channel}
          loading={isLoadingMessageList}
          messageData={campaignMessages}
          getData={getData}
        />
      </div>
      <Drawer anchor="right" open={openFailedModal} onClose={() => setOpenFailedModal(false)}>
        {
          !!campaignTotals?.totalFail &&
          campaignTotals.campaign.status === CampaignStatus.Sent &&
          <div className={styles.errorDrawer}>
            {/* <h1>Mensagens não enviada</h1> */}
            <ErroCrmMessageAlert
              onClickResend={resendHandle} totalFail={campaignTotals?.totalFail}
            />
            <CrmReportCustomerList
              campaingChannel={campaignTotals?.campaign.channel}
              loading={isLoadingFailed}
              messageData={campaignFailedMessages}
              getData={(pagination) => getFailedData(pagination)}
              showError
            />
          </div>
        }
      </Drawer>
    </div>
  );
};
export default CrmReportPage;
