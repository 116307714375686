import GetPosPaidListUseCase from "modules/events/application/useCases/events/GetPosPaidListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { EventsApi } from "services/api/events/events/EventsApi";
import { IPosPaid } from "./IPosPaid";

const UsePosPaid = (cashierId?: string) => {
  const [posPaidList, setPosPaidList] = useState<IPosPaid[]>([]);
  const { currentLocal } = useLocal();
  const [loading, setLoading] = useState(false);
  const eventService = EventsApi();
  const [totalRecords, setTotalRecords] = useState(1);
  const [localId, setLocalId] = useState<string>();
  
  useEffect(() => {
    if (currentLocal?.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  const getPosPaidList = useCallback(
    async (page: number = 0) => {
      if (localId && cashierId) {
        setLoading(true);
        try {
          const result = await GetPosPaidListUseCase(eventService, localId, cashierId, page);
          if (page === 0) {
            setPosPaidList(result.records);
            setTotalRecords(result.totalRecords);
          } else if (page > 0) {
            posPaidList && setPosPaidList(posPaidList.concat(result.records));
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [cashierId, localId, eventService, posPaidList]
  );

  return {
    posPaidList,
    loading,
    getPosPaidList,
    totalRecords,
    localId
  };
};

export default UsePosPaid;
