import React, { FC } from 'react';
import styles from './CloseTitle.module.scss';

interface ICloseTitleProps {
  title: string;
  subTitle: string;
  isExport: boolean;
};

export const CloseTitle: FC<ICloseTitleProps> = ({ title, subTitle, isExport }) => {
  return (
    <div id={styles.CloseTitle} className={isExport ? styles.export : ''}>
      <h2>{title}</h2>
      <p>{subTitle}</p>
    </div>
  );
};