import { useCallback, useEffect, useState } from "react";
import {
  IAverageTopList,
  IAverageTopListItem,
  IAverageTopProductsList,
  IAverageTopProductsListItem,
} from "components/graphs/averageTopList/IAverageTopList";
import { ICategoriesSales } from "components/graphs/categoriesSales/ICategoriesSales";
import { IGradientTable } from "components/graphs/gradientTable/IGradientTable.types";
import { IHorizontalBarsChart } from "components/graphs/horizontalBarsChart/IHorizontalBarsChart";
import { IHorizontalSingleBar } from "components/graphs/horizontalSingleBar/IHorizontalSingleBar.types";
import { IPizzaChartItem } from "components/graphs/pizzaChart/IPizzaChart.types";
import GetAccountByIdUseCase from "modules/dashboard/application/useCases/GetAccountByIdUseCase";
import GetAccountsUseCase from "modules/dashboard/application/useCases/GetAccountsUseCase";
import GetCashFlowUseCase from "modules/dashboard/application/useCases/GetCashFlowUseCase";
import GetPaymentFormEquipmentUseCase from "modules/dashboard/application/useCases/GetPaymentFormEquipmentUseCase";
import GetPlacesUseCase from "modules/dashboard/application/useCases/GetPlacesUseCase";
import GetSaleByCategoryUseCase from "modules/dashboard/application/useCases/GetSaleByCategoryUseCase";
import GetSaleByEquipmentUseCase from "modules/dashboard/application/useCases/GetSaleByEquipmentUseCase";
import GetSaleByHouseUseCase from "modules/dashboard/application/useCases/GetSaleByHouseUseCase";
import GetSaleByOperatorUseCase from "modules/dashboard/application/useCases/GetSaleByOperatorUseCase";
import GetSalePerDayUseCase from "modules/dashboard/application/useCases/GetSalePerDayUseCase";
import GetSelectedLocalsUseCase from "modules/dashboard/application/useCases/GetSelectedLocalsUseCase";
import GetSessionsUseCase from "modules/dashboard/application/useCases/GetSessionsUseCase";
import GetTopClientsUseCase from "modules/dashboard/application/useCases/GetTopClientsUseCase";
import GetTopOperatorsUseCase from "modules/dashboard/application/useCases/GetTopOperatorsUseCase";
import GetTopProductsUseCase from "modules/dashboard/application/useCases/GetTopProductsUseCase";
import { DashboardApiError } from "modules/dashboard/domain/dto/ApiError";
import {
  IFilterRequest,
  ISort,
} from "modules/dashboard/domain/dto/FilterRequest.type";
import { IVerticalBarResponse } from "modules/dashboard/domain/dto/verticalBar/IVerticalBar.type";
import {
  IAccount,
  IAccountBalance,
} from "modules/dashboard/domain/models/account/IAccount.type";
import { FilterEnum } from "modules/dashboard/domain/models/filter/FilterButtons";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { DashboardApi } from "services/api/dashboard/DashboardApi";
import { DashboardSelectedLocals } from "services/repository/dashboard/DashboardSelectedLocals";
import { IBalance } from "../components/balance/IBalance";
import { ISession } from "../components/dashboardFilter/ISession";
import { ILocal } from "../components/toggleLocal/ToggleLocal.types";
import GetSaleByCategoryIdUseCase from "modules/dashboard/application/useCases/GetSaleByCategoryIdUseCase";
import {
  ICategoriesListSubItems,
  ICategoryListItemResponse,
} from "modules/dashboard/domain/dto/categoriesList/ICategoriesList.type";
import { ICashFlow } from "../components/cashFlow/interfaces/ICashFlow";
import { ITicketMedio } from "../components/ticketMedio/ITicketMedio";
import GetTicketMedioUseCase from "modules/dashboard/application/useCases/GetTicketMedioUseCase";
import { variedColors } from "components/graphs/utils/colors";
import { endOfDay, startOfDay, subDays, subHours } from "date-fns";
import { convertToUTC } from "services/utils/Date";
import GetDelayedInvoicesUseCase from "modules/dashboard/application/useCases/GetDelayedInvoicesUseCase";
import { IDelayedInvoicesResponse } from "modules/dashboard/domain/dto/delayedInvoices/IDelayedInvoicesResponse";

const dashboardApi = DashboardApi();

const UseDashboardPage = () => {
  const { currentLocal } = useLocal();
  const [balance, setBalance] = useState<IBalance[] | DashboardApiError>();
  const [places, setPlaces] = useState<ILocal[] | DashboardApiError>();
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [selectedSession, setSelectedSession] = useState<
    ISession | undefined
  >();
  const [selectedPlaces, setSelectedPlaces] = useState<string[] | undefined>(
    () => {
      return GetSelectedLocalsUseCase(DashboardSelectedLocals) || undefined;
    }
  );
  const [cashFlow, setCashFlow] = useState<ICashFlow | DashboardApiError>();
  const [ticketMedio, setTicketMedio] = useState<
    ITicketMedio | DashboardApiError
  >();
  const [paymentFormHorizontal, setPaymentFormHorizontal] = useState<
    IHorizontalSingleBar[] | DashboardApiError
  >();
  const [saleOrigin, setSaleOrigin] = useState<
    IHorizontalSingleBar[] | DashboardApiError
  >();
  const [saleByHouse, setSaleByHouse] = useState<
    IPizzaChartItem[] | DashboardApiError
  >();
  const [salePerDay, setSalePerDay] = useState<
    IVerticalBarResponse[] | DashboardApiError
  >();
  const [paymentFormEquipment, setPaymentFormEquipment] = useState<
    IGradientTable | DashboardApiError
  >();
  const [saleByOperator, setSaleByOperator] = useState<
    IHorizontalBarsChart[][] | DashboardApiError
  >();
  const [topOperators, setTopOperators] = useState<
    IAverageTopList | DashboardApiError
  >();
  const [topProducts, setTopProducts] = useState<
    IAverageTopProductsList | DashboardApiError
  >();
  const [topClients, setTopClients] = useState<
    IAverageTopList | DashboardApiError
  >();
  const [topClientsList, setTopClientsList] = useState<IAverageTopListItem[][]>(
    []
  );
  const [topProductsList, setTopProductsList] = useState<
    IAverageTopProductsListItem[][]
  >([]);
  const [topOperatorsList, setTopOperatorsList] = useState<
    IAverageTopListItem[][]
  >([]);
  const [saleByCategories, setSaleByCategories] = useState<
    ICategoriesSales | DashboardApiError
  >();
  const [saleByEquipment, setSaleByEquipment] = useState<
    IAverageTopList | DashboardApiError
  >();
  const [saleByEquipmentList, setSaleByEquipmentList] = useState<
    IAverageTopListItem[][]
  >([]);
  const [accountsList, setAccountsList] = useState<
    IAccount[] | DashboardApiError
  >();
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>();
  const [accountBalance, setAccountBalance] = useState<IAccountBalance>();
  const [showPizzaChart, setShowPizzaChart] = useState(false);

  const [startDate, setStartDate] = useState(
    convertToUTC(startOfDay(new Date()))
  );
  const [endDate, setEndDate] = useState(convertToUTC(endOfDay(new Date())));
  const [dashboardFilter, setDashboardFilter] = useState<IFilterRequest>();
  const [selectedFilterButton, setSelectedFilterButton] = useState<
    FilterEnum | undefined
  >(FilterEnum.LAST_SESSION);
  const [disableFilterButton, setDisableFilterButton] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingOperators, setLoadingOperators] = useState(false);
  const [loadingSaleByEquipment, setLoadingSaleByEquipment] = useState(false);
  const [loadingCategoriesSales, setLoadingCategoriesSales] = useState(false);
  const [saleByCategoryId, setSaleByCategoryId] =
    useState<ICategoryListItemResponse>();
  const [saleByCategoryIdSubItems, setSaleByCategoryIdSubItems] =
    useState<ICategoriesListSubItems[]>();
  const [clientsPage] = useState<number>(0);
  const [productsPage] = useState<number>(0);
  const [operatorsPage] = useState<number>(0);
  const [clientsPageSize] = useState<number>(20);
  const [productsPageSize] = useState<number>(20);
  const [operatorsPageSize] = useState<number>(20);
  const [saleByEquipmentPageSize] = useState<number>(20);

  const [topClientsTotalRecords, setTopClientsTotalRecords] =
    useState<number>(0);
  const [topProductsTotalRecords, setTopProductsTotalRecords] =
    useState<number>(0);
  const [topOperatorsTotalRecords, setTopOperatorsTotalRecords] =
    useState<number>(0);
  const [saleByEquipmentTotalRecords, setSaleByEquipmentTotalRecords] =
    useState<number>(0);
  const [categoriesSalesTotalRecords] = useState<number>(0);

  const [resetTopClientsScroll] = useState(false);
  const [resetTopProductsScroll, setResetTopProductsScroll] = useState(false);
  const [resetTopOperatorsScroll] = useState(false);
  const [resetCategoriesSalesScroll] = useState(false);
  const [resetSaleByEquipmentScroll] = useState(false);
  const [sort, setSort] = useState<ISort>({
    order: "DESC",
    type: "quantidade",
  });

  const [openedCard, setOpenedCard] = useState<string>("");
  const [delayedInvoices, setDelayedInvoices] = useState<IDelayedInvoicesResponse | undefined>()

  const handleFilterButton = (button: FilterEnum | undefined) => {
    setSelectedFilterButton(button);
  };

  const handleOpenedCard = useCallback(
    (id: string) => {
      if (openedCard === id) {
        setSaleByCategoryId(undefined);
        return setOpenedCard("");
      }

      return setOpenedCard(id);
    },
    [openedCard]
  );

  const refeshData = () => {
    setPaymentFormEquipment(undefined);
    setCashFlow(undefined);
    setTicketMedio(undefined);
    setPaymentFormHorizontal(undefined);
    setSaleOrigin(undefined);
    setSaleByHouse(undefined);
    setPaymentFormEquipment(undefined);
    setSaleByOperator(undefined);
    setTopOperators(undefined);
    setTopProducts(undefined);
    setTopClients(undefined);
    setSaleByCategories(undefined);
    setSaleByEquipment(undefined);
    setSalePerDay(undefined);
  };

  const handleInterval = useCallback((startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const handleFilterDates = useCallback((filter: FilterEnum) => {
    handleFilterButton(filter);
    setEndDate(convertToUTC(endOfDay(new Date())));
    switch (filter) {
      case FilterEnum.LAST_HOUR:
        setStartDate(convertToUTC(subHours(new Date(), 1)));
        setSelectedSession(undefined);
        return;
      case FilterEnum.LAST_24_HOURS:
        setStartDate(convertToUTC(subHours(new Date(), 24)));
        setSelectedSession(undefined);
        return;
      case FilterEnum.LAST_WEEK:
        setStartDate(convertToUTC(subDays(new Date(), 7)));
        setSelectedSession(undefined);
        return;
      case FilterEnum.INTERVAL:
        setSelectedSession(undefined);
        return;
      // case FilterEnum.THIS_MONTH:
      //   setStartDate(new Date(newDateWithNoSecondsAndMilliseconds().setDate(1)).toISOString());
      //   return;
      default:
        break;
    }
  }, []);

  const handleFilterSession = useCallback((session: ISession | undefined) => {
    setSelectedSession(session);
    if (session) {
      setStartDate(session.startDate);
      setEndDate(session.endDate || convertToUTC(endOfDay(new Date())));
    }
  }, []);

  useEffect(() => {
    setDashboardFilter((prev) =>
      prev
        ? {
          ...prev,
          startDate,
          endDate,
          periodoBusca: selectedFilterButton,
          cashierId: selectedSession?.id,
        }
        : ({
          startDate,
          endDate,
          periodoBusca: selectedFilterButton,
          cashierId: selectedSession?.id,
        } as IFilterRequest)
    );
  }, [startDate, endDate, selectedFilterButton, selectedSession]);

  useEffect(() => {
    if (selectedPlaces)
      setDashboardFilter((prev) =>
        prev
          ? { ...prev, selectedPlaces }
          : ({ selectedPlaces } as IFilterRequest)
      );
  }, [selectedPlaces]);

  const handleAccount = useCallback((account: IAccount) => {
    setSelectedAccount(account);
  }, []);

  useEffect(() => {
    const getAccounts = async (localId: string, selectedPlaces: string[]) => {
      try {
        const result = await GetAccountsUseCase(
          dashboardApi,
          localId,
          selectedPlaces
        );
        if (result.length > 0) {
          setSelectedAccount(result[0]);
        } else if (result.length === 0) {
          setSelectedAccount(null);
        }
        setAccountsList(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setAccountsList(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setAccountsList(apiError);
        }
      }
    };

    !!selectedPlaces?.length && getAccounts("", selectedPlaces);
  }, [dashboardFilter, selectedPlaces]);

  useEffect(() => {
    const getAccountById = async (localId: string, accountId: string) => {
      try {
        const result = await GetAccountByIdUseCase(
          dashboardApi,
          localId,
          accountId
        );
        setAccountBalance(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setBalance(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setBalance(apiError);
        }
      }
    };

    if (selectedAccount)
      getAccountById(selectedAccount.localId, selectedAccount.id);
  }, [dashboardFilter?.localId, selectedAccount]);

  useEffect(() => {
    if (accountBalance) {
      setBalance([
        {
          label: "Disponível",
          amount: accountBalance.saldoResult.SaldoDisponivel,
        },
        {
          label: "A liberar",
          amount: accountBalance.saldoResult.SaldoALiberar,
        },
        {
          label: "Retido",
          amount: accountBalance.saldoResult.SaldoRetido,
        },
      ]);
    }
  }, [accountBalance]);

  // const getPlaces = useCallback(async (filter: IFilterRequest) => {
  //   try {
  //     const result = await GetPlacesUseCase(dashboardApi, filter, 0, 999);
  //     setPlaces(result);
  //   } catch (error: any) {
  //     if (error.response?.data?.message) {
  //       const apiError = new DashboardApiError(
  //         `[ERROR] ${error.response.data.message}`
  //       );
  //       setPlaces(apiError);
  //     } else {
  //       const apiError = new DashboardApiError(
  //         `[ERROR ${error?.response?.status}] ${error?.message}`
  //       );
  //       setPlaces(apiError);
  //     }
  //   }
  // }, []);

  const getCashFlow = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetCashFlowUseCase(dashboardApi, filter);
      setCashFlow(result);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setCashFlow(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setCashFlow(apiError);
      }
    }
  }, []);

  const getDelayedInvoices = useCallback(async () => {
    if (currentLocal) {
      const result = await GetDelayedInvoicesUseCase(dashboardApi, currentLocal.id);
      setDelayedInvoices(result);
    }
  }, [currentLocal]);

  useEffect(() => {
      getDelayedInvoices();    
  }, [currentLocal]);

  const getTicketMedio = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetTicketMedioUseCase(dashboardApi, filter);
      setTicketMedio(result);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setTicketMedio(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setTicketMedio(apiError);
      }
    }
  }, []);

  useEffect(() => {
    if (saleByHouse) {
      setShowPizzaChart(true);
    }
  }, [saleByHouse]);

  const getSaleByHouse = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetSaleByHouseUseCase(dashboardApi, filter);
      setSaleByHouse(result.records);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setSaleByHouse(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setSaleByHouse(apiError);
      }
    }
  }, []);

  const getSalePerDay = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetSalePerDayUseCase(dashboardApi, filter);
      setSalePerDay(result);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setSalePerDay(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setSalePerDay(apiError);
      }
    }
  }, []);

  const getPaymentForm = useCallback((gradientTable: IGradientTable) => {
    const label = gradientTable.paymentForm.map((item) => item);
    const amount = gradientTable.amount.reduce((amounts: number[], current) => {
      current.forEach((item, index) => {
        if (!amounts[index]) {
          amounts[index] = item[0];
        } else {
          amounts[index] += item[0];
        }
      });
      return amounts;
    }, []);
    const paymentForm = label.map((item, key) => ({
      label: item,
      amount: amount[key],
    }));

    setPaymentFormHorizontal(paymentForm);
  }, []);

  const getSaleOrigin = useCallback((gradientTable: IGradientTable) => {
    const label = gradientTable.equipments.map((item) => item);
    const amount = gradientTable.amount.reduce(
      (amounts: number[], current, index) => {
        const rowValue = current.reduce(
          (total: number, currentValue) => (total += currentValue[0]),
          0
        );
        amounts[index] = rowValue;
        return amounts;
      },
      []
    );
    const saleOrigin = label.map((item, key) => ({
      label: item,
      amount: amount[key],
    }));
    setSaleOrigin(saleOrigin);
  }, []);

  const getPaymentFormEquipment = useCallback(
    async (filter: IFilterRequest) => {
      try {
        const result = await GetPaymentFormEquipmentUseCase(
          dashboardApi,
          filter
        );
        setPaymentFormEquipment(result);
        if (filter.selectedPlaces.length === 1) {
          setShowPizzaChart(false);
          getPaymentForm(result);
          getSaleOrigin(result);
        }
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setPaymentFormEquipment(apiError);
          if (filter.selectedPlaces.length === 1) {
            setPaymentFormHorizontal(apiError);
            setSaleOrigin(apiError);
          }
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setPaymentFormEquipment(apiError);
          if (filter.selectedPlaces.length === 1) {
            setPaymentFormHorizontal(apiError);
            setSaleOrigin(apiError);
          }
        }
      }
    },
    [getPaymentForm, getSaleOrigin]
  );

  const getSaleByOperator = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetSaleByOperatorUseCase(dashboardApi, filter);
      setSaleByOperator(result);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setSaleByOperator(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setSaleByOperator(apiError);
      }
    }
  }, []);

  const getOperators = useCallback(
    async (filter: IFilterRequest) => {
      try {
        const result = await GetTopOperatorsUseCase(dashboardApi, filter);
        if (filter.page === 0) {
          setTopOperatorsList(result.records);
        } else {
          topOperatorsList &&
            setTopOperatorsList(topOperatorsList.concat(result.records));
        }
        setTopOperatorsTotalRecords(result.totalRecords);
        setTopOperators(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setTopOperators(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setTopOperators(apiError);
        }
      }
    },
    [topOperatorsList]
  );

  useEffect(() => {
    setResetTopProductsScroll(false);
  }, [topProductsList]);

  const getProducts = useCallback(
    async (filter: IFilterRequest) => {
      try {
        // setLoadingProducts(true);
        const result = await GetTopProductsUseCase(dashboardApi, filter);
        if (filter.page === 0) {
          setTopProductsList(result.records);
        } else {
          topProductsList &&
            setTopProductsList(topProductsList.concat(result.records));
        }
        setTopProductsTotalRecords(result.totalRecords);
        setTopProducts(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setTopProducts(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setTopProducts(apiError);
        }
      } finally {
        setLoadingProducts(false);
      }
    },
    [topProductsList]
  );

  const getTopClients = useCallback(
    async (filter: IFilterRequest) => {
      try {
        setLoadingClients(true);
        const result = await GetTopClientsUseCase(dashboardApi, filter);
        if (filter.page === 0) {
          setTopClientsList(result.records);
        } else {
          topClientsList &&
            setTopClientsList(topClientsList.concat(result.records));
        }
        setTopClientsTotalRecords(result.totalRecords);
        setTopClients(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setTopClients(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setTopClients(apiError);
        }
      } finally {
        setLoadingClients(false);
      }
    },
    [topClientsList]
  );

  const getSaleByEquipment = useCallback(
    async (filter: IFilterRequest) => {
      try {
        const result = await GetSaleByEquipmentUseCase(dashboardApi, filter);
        if (filter.page === 0) {
          setSaleByEquipmentList(result.records);
        } else {
          saleByEquipmentList &&
            setSaleByEquipmentList(saleByEquipmentList.concat(result.records));
        }
        setSaleByEquipmentTotalRecords(result.totalRecords);
        setSaleByEquipment(result);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setSaleByEquipment(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setSaleByEquipment(apiError);
        }
      }
    },
    [saleByEquipmentList]
  );

  const refreshTopClients = useCallback(
    async (page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          setLoadingClients(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: clientsPageSize,
            periodoBusca: selectedFilterButton,
          };

          getTopClients(filter);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        } finally {
          setLoadingClients(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingClients)
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingClients,
      clientsPageSize,
      selectedPlaces,
      selectedFilterButton,
      getTopClients,
    ]
  );

  const refreshTopProducts = useCallback(
    async (page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number,
        sort: ISort
      ) => {
        try {
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: productsPageSize,
            sort,
            periodoBusca: selectedFilterButton,
          };

          getProducts(filter);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingProducts) {
        getPlaces(start, end, currentLocal.id, page, clientsPageSize, sort);
      }
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingProducts,
      selectedPlaces,
      productsPageSize,
      selectedFilterButton,
      getProducts,
      clientsPageSize,
      sort,
    ]
  );

  const handleSortTopProducts = useCallback(
    async (
      page: number = 0,
      sort: ISort = { order: "DESC", type: "quantidade" }
    ) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          setLoadingProducts(true);
          setResetTopProductsScroll(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];
          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: productsPageSize,
            sort,
            periodoBusca: selectedFilterButton,
          };

          getProducts(filter);
          setLoadingProducts(false);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
          setLoadingProducts(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingProducts) {
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
      }
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingProducts,
      selectedPlaces,
      productsPageSize,
      selectedFilterButton,
      getProducts,
      clientsPageSize,
    ]
  );

  const handleChangeSort = useCallback(
    (value: ISort) => {
      setSort(value);
      handleSortTopProducts(0, value);
    },
    [handleSortTopProducts]
  );

  const refreshTopOperators = useCallback(
    async (page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          setLoadingOperators(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: operatorsPageSize,
            periodoBusca: selectedFilterButton,
          };

          getOperators(filter);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        } finally {
          setLoadingOperators(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingOperators)
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingOperators,
      clientsPageSize,
      selectedPlaces,
      operatorsPageSize,
      selectedFilterButton,
      getOperators,
    ]
  );

  const refreshSaleByEquipment = useCallback(
    async (page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          setLoadingSaleByEquipment(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: saleByEquipmentPageSize,
            periodoBusca: selectedFilterButton,
          };

          getSaleByEquipment(filter);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        } finally {
          setLoadingSaleByEquipment(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingSaleByEquipment)
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingSaleByEquipment,
      clientsPageSize,
      selectedPlaces,
      saleByEquipmentPageSize,
      selectedFilterButton,
      getSaleByEquipment,
    ]
  );

  const getGetSaleByCategory = useCallback(async (filter: IFilterRequest) => {
    try {
      const result = await GetSaleByCategoryUseCase(dashboardApi, filter);
      setSaleByEquipmentTotalRecords(result.totalRecords);
      setSaleByCategories(result);
    } catch (error: any) {
      if (error.response?.data?.message) {
        const apiError = new DashboardApiError(
          `[ERROR] ${error.response.data.message}`
        );
        setSaleByCategories(apiError);
      } else {
        const apiError = new DashboardApiError(
          `[ERROR ${error?.response?.status}] ${error?.message}`
        );
        setSaleByCategories(apiError);
      }
    }
  }, []);

  const getGetSaleByCategoryId = useCallback(
    async (filter: IFilterRequest, categoryId: string) => {
      try {
        const result = await GetSaleByCategoryIdUseCase(
          dashboardApi,
          filter,
          categoryId
        );
        setSaleByCategoryId(result);
        setSaleByCategoryIdSubItems(result.records);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setSaleByCategories(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setSaleByCategories(apiError);
        }
      }
    },
    []
  );

  const refreshCategoriesSales = useCallback(
    async (page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          setLoadingCategoriesSales(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: saleByEquipmentPageSize,
            periodoBusca: selectedFilterButton,
          };

          getGetSaleByCategory(filter);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        } finally {
          setLoadingCategoriesSales(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingCategoriesSales)
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingCategoriesSales,
      clientsPageSize,
      selectedPlaces,
      saleByEquipmentPageSize,
      selectedFilterButton,
      getGetSaleByCategory,
    ]
  );

  const refreshSaleByCategoryId = useCallback(
    async (categoryId: string, page: number = 0) => {
      const getPlaces = async (
        start: string,
        end: string,
        localId: string,
        page: number,
        pageSize: number
      ) => {
        try {
          // setLoadingCategoriesSales(true);
          let defaultPlace = selectedPlaces?.map((x) => x) || [];

          const filter: IFilterRequest = {
            localId: localId,
            startDate: start,
            endDate: end,
            selectedPlaces: defaultPlace,
            page: page,
            pageSize: 100, //TODO: paginar
            periodoBusca: selectedFilterButton,
          };

          getGetSaleByCategoryId(filter, categoryId);
        } catch (error: any) {
          if (error.response?.data?.message) {
            const apiError = new DashboardApiError(
              `[ERROR] ${error.response.data.message}`
            );
            setPlaces(apiError);
          } else {
            const apiError = new DashboardApiError(
              `[ERROR ${error?.response?.status}] ${error?.message}`
            );
            setPlaces(apiError);
          }
        } finally {
          // setLoadingCategoriesSales(false);
        }
      };

      const start = startDate;
      const end = endDate;

      if (currentLocal?.id && !loadingCategoriesSales)
        getPlaces(start, end, currentLocal.id, page, clientsPageSize);
    },
    [
      startDate,
      endDate,
      currentLocal?.id,
      loadingCategoriesSales,
      clientsPageSize,
      selectedPlaces,
      selectedFilterButton,
      getGetSaleByCategoryId,
    ]
  );

  useEffect(() => {
    (async () => {
      try {
        if (places) {
          const result = await GetSessionsUseCase(
            dashboardApi,
            (places! as ILocal[]).filter((x) => selectedPlaces!.includes(x.id))
          );
          setSessions(result.items);
        }
      } catch (error: any) {
        // if (error.response?.data?.message) {
        //   const apiError = new DashboardApiError(`[ERROR] ${error.response.data.message}`);
        //   // setSessions(apiError);
        // } else {
        //   const apiError = new DashboardApiError(`[ERROR ${error?.response?.status}] ${error?.message}`);
        //   // setSessions(apiError);
        // }
      }
    })();
  }, [places, selectedPlaces]);

  const handleFilter = useCallback(
    async (filter: IFilterRequest) => {
      setDisableFilterButton(true);
      refeshData();

      try {
        let topClientsFilter = filter;
        let topOperatorsFilter = filter;
        let topProductsFilter = filter;
        topClientsFilter.pageSize = clientsPageSize;
        topClientsFilter.page = clientsPage;
        topOperatorsFilter.pageSize = operatorsPageSize;
        topOperatorsFilter.page = operatorsPage;
        topProductsFilter.pageSize = productsPageSize;
        topProductsFilter.page = productsPage;
        if (filter.selectedPlaces.length > 1) {
          await Promise.allSettled([
            // getPlaces(filter),
            getCashFlow(filter),
            getTicketMedio(filter),
            getPaymentFormEquipment(filter),
            getSaleByOperator(filter),
            getSalePerDay(filter),
            getOperators(topOperatorsFilter),
            getProducts(topProductsFilter),
            getTopClients(topClientsFilter),
            getSaleByEquipment(filter),
            getGetSaleByCategory(filter),
            getSaleByHouse(filter), //Gráfico de pizza, somente se mais de um local estiver selecionado
          ]);
        } else {
          await Promise.allSettled([
            // getPlaces(filter),
            getCashFlow(filter),
            getTicketMedio(filter),
            getPaymentFormEquipment(filter),
            getSaleByOperator(filter),
            getOperators(topOperatorsFilter),
            getSalePerDay(filter),
            getProducts(topProductsFilter),
            getTopClients(topClientsFilter),
            getSaleByEquipment(filter),
            getGetSaleByCategory(filter),
          ]);
        }
      } finally {
        setDisableFilterButton(false);
      }
    },
    [
      clientsPage,
      clientsPageSize,
      getCashFlow,
      getTicketMedio,
      getGetSaleByCategory,
      getOperators,
      getPaymentFormEquipment,
      // getPlaces,
      getProducts,
      getSaleByEquipment,
      getSaleByHouse,
      getSaleByOperator,
      getSalePerDay,
      getTopClients,
      operatorsPage,
      operatorsPageSize,
      productsPage,
      productsPageSize,
    ]
  );

  useEffect(() => {
    const getPlaces = async (
      start: string,
      end: string,
      localId: string,
      page: number,
      pageSize: number
    ) => {
      try {
        const result = await GetPlacesUseCase(
          dashboardApi,
          {
            startDate: start,
            endDate: end,
            periodoBusca: selectedFilterButton,
          } as IFilterRequest,
          page,
          pageSize
        );
        setPlaces(result);

        let defaultPlace = selectedPlaces?.map((x) => x) || [result[0].id];

        if (!result.filter((x) => defaultPlace.includes(x.id)).length) {
          defaultPlace = [result[0].id];
          setSelectedPlaces([result[0].id]);
        }

        const filter: IFilterRequest = {
          localId: localId,
          startDate: start,
          endDate: end,
          selectedPlaces: defaultPlace,
          page: 0,
          pageSize: 999,
          sort,
          periodoBusca: selectedFilterButton,
        };

        setDashboardFilter(filter);
        handleFilter(filter);
        setLoaded(true);
      } catch (error: any) {
        if (error.response?.data?.message) {
          const apiError = new DashboardApiError(
            `[ERROR] ${error.response.data.message}`
          );
          setPlaces(apiError);
        } else {
          const apiError = new DashboardApiError(
            `[ERROR ${error?.response?.status}] ${error?.message}`
          );
          setPlaces(apiError);
        }
      }
    };

    const start = convertToUTC(startOfDay(new Date()));
    const end = convertToUTC(endOfDay(new Date()));

    if (currentLocal?.id && !loaded)
      getPlaces(start, end, currentLocal.id, 0, 100);
  }, [
    currentLocal,
    handleFilter,
    loaded,
    selectedFilterButton,
    selectedPlaces,
    sort,
  ]);

  const handleSelectedPlaces = useCallback((places: string[]) => {
    setSelectedPlaces(places);
  }, []);

  const getPlaceIndexById = useCallback(
    (localId: string) => {
      if (places instanceof Array<ILocal>) {
        const place = places.find((item) => item.id === localId);
        const index = places.findIndex((item) => item.id === localId);
        const color = variedColors[index];
        return { place, index, color };
      }

      return {};
    },
    [places]
  );

  return {
    balance,
    places,
    sessions,
    selectedSession,
    handleSelectedPlaces,
    cashFlow,
    ticketMedio,
    paymentFormHorizontal,
    saleOrigin,
    saleByHouse,
    selectedPlaces,
    paymentFormEquipment,
    saleByOperator,
    topOperators,
    topProducts,
    topClients,
    saleByCategories,
    saleByEquipment,
    accountsList,
    selectedAccount,
    handleAccount,
    handleFilterDates,
    handleFilterSession,
    handleInterval,
    startDate,
    endDate,
    handleFilter,
    dashboardFilter,
    showPizzaChart,
    selectedFilterButton,
    handleFilterButton,
    disableFilterButton,
    salePerDay,
    topClientsList,
    topProductsList,
    topOperatorsList,
    saleByEquipmentList,
    refreshTopClients,
    loadingClients,
    topClientsTotalRecords,
    refreshTopProducts,
    refreshTopOperators,
    refreshSaleByEquipment,
    loadingProducts,
    loadingOperators,
    loadingSaleByEquipment,
    topOperatorsTotalRecords,
    topProductsTotalRecords,
    saleByEquipmentTotalRecords,
    resetTopClientsScroll,
    resetTopProductsScroll,
    resetTopOperatorsScroll,
    resetSaleByEquipmentScroll,
    refreshCategoriesSales,
    loadingCategoriesSales,
    resetCategoriesSalesScroll,
    categoriesSalesTotalRecords,
    getGetSaleByCategoryId,
    saleByCategoryId,
    refreshSaleByCategoryId,
    saleByCategoryIdSubItems,
    handleChangeSort,
    sort,
    handleOpenedCard,
    openedCard,
    getPlaceIndexById,
    delayedInvoices
  };
};

export default UseDashboardPage;
