import { Icon, TextField } from '@material-ui/core';
import styles from './CreatePostForm.module.scss';
import { IPost } from 'modules/posts/presentation/interfaces/IPosts';
import { IPostForm } from 'modules/posts/presentation/interfaces/IPostForm';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { Drawer, Select } from '@mui/material';
import { PhotoCamera } from '@material-ui/icons';
import Resizer from 'react-image-file-resizer';
import InputMasked from "components/inputMasked/InputMasked";
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';

interface CreatePostFormProps {
    open: boolean;
    defaultValues?: IPost;
    onClose: () => void;
    onSubmit: (form: IPostForm) => Promise<void>;
}

interface ErrorType {
    content: string;
    image: string;
    date: string;
    time: string;
}

export const CreatePostForm = ({ open, defaultValues, onClose, onSubmit }: CreatePostFormProps) => {
    const [form, setForm] = useState({} as IPostForm);
    const [errors, setErrors] = useState<ErrorType>({} as ErrorType);
    const [loading, setLoading] = useState(false);
    const refInputImage = useRef<HTMLInputElement>(null);

    const { toast } = useUi();

    useEffect(() => {
        if (defaultValues) {
            const isScheduled = isAfter(new Date(defaultValues.date + 'Z'), new Date());
            setForm({
                ...defaultValues,
                date: isScheduled ? format(new Date(defaultValues.date + 'Z'), 'dd/MM/yyyy') : '',
                time: isScheduled ? format(new Date(defaultValues.date + 'Z'), 'HH:mm') : '',
                image: defaultValues.imageUrl
            })
        } else {
            setForm({} as IPostForm);
        }
    }, [defaultValues]);

    const handleClose = () => {
        setErrors({} as ErrorType);
        setForm({} as IPostForm);
        onClose();
    }

    const validate = async () => {
        let isValidValues = true;
        setErrors({} as ErrorType);

        if (!form.image && !form.content?.trim()) {
            setErrors(prev => ({ ...prev, content: 'Preencha o conteúdo ou imagem para criar um post.' }))
            setErrors(prev => ({ ...prev, image: 'Preencha o conteúdo ou imagem para criar um post.' }))
            isValidValues = false;
        }

        if ((form.date && !form.time) || (!form.date && form.time)) {
            if (!form.date) {
                setErrors(prev => ({ ...prev, date: 'A data é obrigatória.' }))
            }
            if (!form.time) {
                setErrors(prev => ({ ...prev, time: 'O horário é obrigatório.' }))
            }
            isValidValues = false;
        } else {
            const dt = parse(`${form.date} ${form.time}`, 'dd/MM/yyyy HH:mm', new Date());
            if (isBefore(dt, new Date())) {
                setErrors(prev => ({ ...prev, date: 'Informe uma data e horário posteriores ao atual.' }))
                isValidValues = false;
            }
        }

        if (form.date && !isValid(parse(form.date, 'dd/MM/yyyy', new Date()))) {
            setErrors(prev => ({ ...prev, date: 'Data inválida.' }))
            isValidValues = false;
        }

        if (form.time && !isValid(parse(form.time, 'HH:mm', new Date()))) {
            setErrors(prev => ({ ...prev, time: 'Horário inválido.' }))
            isValidValues = false;
        }

        return isValidValues;
    }

    const handleSubmit = async () => {
        const isValid = await validate();
        if (!isValid) return;

        try {
            setLoading(true);
            await onSubmit(form);
            handleClose();
        } catch {
            toast('Ocorreu um erro ao salvar a publicação. Tente novamente.', 'error');
        } finally {
            setLoading(false);
        }
    }

    const onClickImage = () => {
        refInputImage.current?.click();
    };

    const mountSrc = (src: string) => {
        if (src.indexOf('http') > -1)
            return src + '?v=' + new Date().getTime();

        return src;
    }

    const resizeFile = (file: File): Promise<string | Blob | File | ProgressEvent<FileReader>> => new Promise((resolve) => {
        Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
            }, 'base64');
    });

    const onChangeHandleImg = async (ev: ChangeEvent<HTMLInputElement>) => {
        const fileObj = ev.target.files && ev.target.files[0];

        if (!fileObj) {
            return;
        }
        const image = await resizeFile(fileObj);

        setForm(prev => ({ ...prev, image: image.toString() }));
    };

    return (
        <Drawer anchor='right' open={open} onClose={handleClose} PaperProps={{
            sx: {
                width: '100%',
                maxWidth: 600
            }
        }}>
            <div className={styles.container}>
                <header>
                    <h1>{defaultValues?.id ? 'Editar' : 'Nova'} <b>publicação</b></h1>
                    <button onClick={handleClose}><Icon>close</Icon></button>
                </header>

                <div className={styles.content}>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <div>
                                <input
                                    name="banner"
                                    ref={refInputImage}
                                    id="input-file"
                                    accept=".jpg, .jpeg, .png"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={onChangeHandleImg}
                                />
                                {form.image ? (
                                    <div className={styles.boxImg}>
                                        <img src={mountSrc(form.image)} alt="upload_img" onClick={onClickImage} />
                                        <button className={styles.removeImage} onClick={(ev) => {
                                            ev.preventDefault();
                                            setForm(prev => ({ ...prev, image: undefined }))
                                        }
                                        }>
                                            <Icon>close</Icon>
                                        </button>
                                    </div>
                                ) : (
                                    <div className={styles.uploadImg} onClick={onClickImage}>
                                        <PhotoCamera />
                                        <p>Adicionar foto para a publicação</p>
                                        <p>(resolução recomendada: 500x220)</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Texto da publicação</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={5}
                                size='small'
                                value={form.content}
                                onChange={(event) => setForm(prev => ({ ...prev, content: event.target.value }))}
                                helperText={errors.content}
                                error={!!errors.content}
                            />
                            <p className={styles.hint}>Máx. caracteres: 240</p>
                        </div>
                    </div>

                    <label>Programar publicação</label>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <InputMasked
                                name={"date"}
                                variant={"outlined"}
                                value={form.date}
                                fullWidth
                                mask="99/99/9999"
                                placeholder='dd/mm/aaaa'
                                onChange={(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    setForm(prev => ({ ...prev, date: ev.target.value }))
                                }}
                                error={!!errors.date}
                                helperText={errors.date}
                            />
                        </div>
                        <div className={styles.column}>
                            <InputMasked
                                name={"time"}
                                variant={"outlined"}
                                value={form.time}
                                fullWidth
                                mask="99:99"
                                placeholder='hh:mm'
                                onChange={(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    setForm(prev => ({ ...prev, time: ev.target.value }))
                                }}
                                error={!!errors.time}
                                helperText={errors.time}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label>Evento</label>
                            <Select
                                value={form.eventId}
                                style={{ width: "100%" }}
                                size="small"
                                placeholder="Passo 01"
                                onChange={(ev) => setForm(prev => ({ ...prev, segment: Number(ev.target.value) }))}
                            >
                                {/* {
                                    segments.map(x => (
                                        <MenuItem value={x.id} key={x.id}>
                                            {x.description}
                                        </MenuItem>
                                    ))
                                } */}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className={styles.buttonsContainer}>
                    <button onClick={handleClose} className={styles.outlinedButton}>Cancelar</button>
                    <button disabled={loading} onClick={handleSubmit}>
                        {
                            loading ? 'Salvando...' : 'Salvar'
                        }
                    </button>
                </div>
            </div>
        </Drawer>
    )
}