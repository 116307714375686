import { Radio } from '@material-ui/core';
import styles from './TrackConfig.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import { ISurveyItem } from 'modules/automation/presentation/interfaces/ISurvey';
import { IAutomationForm } from 'modules/automation/presentation/interfaces/IForm';
import { useEffect, useState } from 'react';
import { AutomationCampaignChannel } from 'modules/automation/domain/interfaces/AutomationCampaignChannel';

interface TrackConfigProps {
    surveys: ISurveyItem[];
    form: IAutomationForm;
    onChange: (form: IAutomationForm) => void;
}

export const TrackConfig = ({ surveys, form, onChange }: TrackConfigProps) => {
    const [selectedSurvey, setSelectedSurvey] = useState<ISurveyItem | null>(null);

    useEffect(() => {
        if (form.payload.surveyId)
            setSelectedSurvey(surveys.find(x => x.id === form.payload.surveyId) || null)
    }, [form.payload.surveyId, surveys])

    return (
        <div className={styles.container}>
            <span className={styles.title}>Selecione qual pesquisa você quer que seus clientes recebam quando o gatilho acontecer:</span>

            <label>Pesquisa Track.co <span>*</span></label>
            <Autocomplete
                className={styles.input}
                style={{ flex: 2, minWidth: 350 }}
                size='small'
                renderInput={(props) =>
                    <div className={styles.inputContainer}>
                        <TextField
                            maxRows={1}
                            name={"locals"}
                            placeholder='Selecione a pesquisa'
                            {...props}
                        />
                    </div>
                }
                renderOption={(props, option) => {
                    return (
                        <span {...props} key={option.id}>
                            {option.trackSurveyName}
                        </span>
                    );
                }}
                isOptionEqualToValue={(option, values) => option.id === values.id}
                options={surveys}
                getOptionLabel={(option) => option.trackSurveyName}
                onChange={(ev, value) => {
                    setSelectedSurvey(value)
                    onChange({ ...form, payload: { ...form.payload, surveyId: value?.id } })
                }}
                value={selectedSurvey}
            />

            <div className={styles.separator} />

            <span className={styles.sendingType}>Forma de envio:</span>

            <div className={styles.radioContainer}>
                <div>
                    <Radio
                        className={styles.radio}
                        checked={form.payload.channel === AutomationCampaignChannel.push}
                        onChange={(_, checked) => onChange({ ...form, payload: { ...form.payload, channel: AutomationCampaignChannel.push } })}
                        size="small"
                    />
                    <span>Pesquisa via Push</span>
                </div>
                <span className={styles.description}>Envie uma pesquisa por meio de notificação no App Meep</span>
            </div>

            <div className={styles.radioContainer}>
                <div>
                    <Radio
                        className={styles.radio}
                        checked={form.payload.channel === AutomationCampaignChannel.email}
                        onChange={(_, checked) => onChange({ ...form, payload: { ...form.payload, channel: AutomationCampaignChannel.email } })}
                        size="small"
                    />
                    <span>Email</span>
                </div>
                <span className={styles.description}>Envie uma pesquisa via email para seus clientes</span>
            </div>
        </div>
    )
}