import { ISaveTableRequest } from "modules/operators/domain/dtos/ITableItemResponse";
import { ITableService } from "modules/table/domain/interfaces/ITableService"
import { ITableItem } from "modules/table/presentation/components/tableList/TableList";

const GetTablesUseCase = (service: ITableService, localId: string): Promise<ITableItem[]> => {
    return service.getTableList(localId);
}
export default GetTablesUseCase

export const AddTableUseCase = (service: ITableService, localId: string, table: ISaveTableRequest): Promise<void> => {
    return service.addTable(localId, table);
}

export const EditTableUseCase = (service: ITableService, localId: string, table: ISaveTableRequest): Promise<void> => {
    return service.updateTable(localId, table);
}

export const DeleteTableUseCase = (service: ITableService, localId: string, id: string): Promise<void> => {
    return service.deleteTable(localId, id);
}
export const ChangeStatusTableUseCase = (service: ITableService, localId: string, table: ISaveTableRequest, status: number): Promise<void> => {
    return service.changeStatusTable(localId, table, status);
}

