import styles from "./TutorialItem.module.scss";
import { EditOutlined, DeleteOutlineOutlined, LinkOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";

interface ITutorialItemProps {
  item: ITutoriaisRecords;
  setOpenSidesheet: React.Dispatch<
    React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>
  >;
  setEditAndDeleteItem: React.Dispatch<React.SetStateAction<ITutoriaisRecords>>;
}

const TutorialItem = ({
  item,
  setOpenSidesheet,
  setEditAndDeleteItem,
}: ITutorialItemProps) => {
  return (
    <>
      <div id={styles.TutorialItem}>
        <span>{format(new Date(item.createdAt), "dd/MM/yyyy")}</span>
        <span>{item.title}</span>
        <span>{item.menuTitle}</span>
        <div className={styles.icons}>
          <div onClick={() => navigator.clipboard.writeText(item.fullTutorialUrl)}>
            <LinkOutlined />
          </div>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isEditing");
          }}>
            <EditOutlined />
          </div>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isDeleting");
          }}>
            <DeleteOutlineOutlined />
          </div>
        </div>
      </div>
    </>
  );
};
export default TutorialItem;
