import { FC, useState } from 'react';
import styles from './ConfigForm.module.scss';
import { IDiscountList, IDiscountListForm } from '../../IDiscountListForm';
import { IDiscountListFormErrors } from '../../validation/IDiscountListFormErrors';
import { IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { NewDiscountForm } from './newDiscountForm/NewDiscountForm';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { moneyMaskNumber } from 'services/utils/Money';
import { ITicketForm } from '../../../../../tickets/components/ticketForm/ITicketForm';

interface IConfirmFormProps {
    errors: IDiscountListFormErrors;
    discountList: IDiscountListForm;
    productList?: ITicketForm[];
    onChangeDiscountListFormValue: (prop: string, value: any) => void;
    onAddDiscount: (newDiscount: IDiscountList[]) => void;
    onRemoveDiscount: (index: number) => void;
}

export const ConfigForm: FC<IConfirmFormProps> = ({
    errors,
    discountList,
    productList,
    onChangeDiscountListFormValue,
    onAddDiscount,
    onRemoveDiscount
}) => {
    const [isNewDiscountAdding, setIsNewDiscountAdding] = useState(false);

    const getValueWithDiscount = (price: number | undefined, discount: IDiscountList) => {
        if (!price) return '0';

        if (discount.percentualDiscount) {
            return moneyMaskNumber(price - (price * (discount.percentualDiscount.percentual / 100)));
        } else {
            return moneyMaskNumber(price - discount.priceDiscount!.price);
        }
    }

    if(productList && discountList.discounts?.length > 0){
        productList = productList.filter(productListValue => !discountList?.discounts?.find(discountValue => discountValue.productId === productListValue.id));
    }

    return (
        <div id={styles.ConfigForm}>
            <strong>{discountList.discounts?.length || 0}</strong> Descontos na lista

            {
                !isNewDiscountAdding ?
                    <>
                        <button className={styles.newDiscountButton} onClick={() => setIsNewDiscountAdding(true)}>
                            <AddCircle />
                            <span>Novo desconto</span>
                        </button>
                        {
                            discountList.discounts?.map((x, index) => (
                                <div className={styles.discountListCard}>
                                    <span>{x.name ?? ''}</span>
                                    <span>{moneyMaskNumber(x.price)}</span>
                                    <span>{getValueWithDiscount(x.price, x)}</span>
                                    <IconButton onClick={() => onRemoveDiscount(index)} className={styles.deleteButton}>
                                        <DeleteOutlineIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            ))
                        }
                    </> :
                    <NewDiscountForm onClose={() => setIsNewDiscountAdding(false)} productList={productList} onAddDiscount={onAddDiscount} />
            }

        </div>
    );
};
