import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { IGetMobiConfigResponse } from "modules/config/integrationsConfig/domain/dto/IGetMobiConfigResponse";
import GetMobiConfigUseCase from "modules/config/integrationsConfig/application/useCases/GetMobiConfigUseCase";
import PostMobiConfigUseCase from "modules/config/integrationsConfig/application/useCases/PostMobiConfigUseCase";
import { IPostMobiConfigRequest } from "modules/config/integrationsConfig/domain/dto/MobiConfig/IPostMobiConfigRequest";

const service = IntegrationApi();

export const UseConfigMobi = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getIntegration, setGetIntegration] =
    useState<IGetMobiConfigResponse>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const postMobiConfiguration = useCallback(
    async (values: IPostMobiConfigRequest) => {
      try {
        setIsLoading(true);
        await PostMobiConfigUseCase(service, values);
        return "ok";
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [toast]
  );

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          const response = await GetMobiConfigUseCase(
            service,
            currentLocal?.id
          );
          setGetIntegration(response);
          return "ok";
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [currentLocal, postMobiConfiguration]);

  return {
    isLoading,
    getIntegration,
    postMobiConfiguration,
  };
};
