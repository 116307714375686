import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import { PatchUpdateTerminalConfigRequest } from "modules/Terminal/application/dtos/PatchUpdateTerminalConfigRequest";
import { PatchUpdateTerminalConfigResponse } from "modules/Terminal/application/dtos/PatchUpdateTerminalConfigResponse";
import GetNavigatorSolicitationsUseCase from "modules/Terminal/application/useCases/GetNavigatorSolicitationsUseCase";
import GetTerminalDevicesUseCase from "modules/Terminal/application/useCases/GetTerminalDevicesUseCase";
import PatchUpdateTerminalConfigUseCase from "modules/Terminal/application/useCases/PatchUpdateTerminalConfigUseCase";
import PostApproveSolicitationUseCase from "modules/Terminal/application/useCases/PostApproveSolicitationUseCase";
import PostCloseSessionUseCase from "modules/Terminal/application/useCases/PostCloseSessionUseCase";
import PostRefuseAllSolicitationsUseCase from "modules/Terminal/application/useCases/PostRefuseAllSolicitationsUseCase";
import PostRefuseSolicitationUseCase from "modules/Terminal/application/useCases/PostRefuseSolicitationUseCase";
import GetCatalogListsUseCase from "modules/catalog/application/useCases/GetCatalogListsUseCase";
import { ICatalogSelect } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet";
import GetPaymentMethodsByLocalIdUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/devicePayment/GetPaymentMethodsByLocalIdUseCase";
import { IPaymentMethodItem } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/devicePaymentForm/interfaces/IDevicePaymentValue";
import GetSmartPrinterUseCase from "modules/config/smartPrinterConfig/application/useCases/GetSmartPrinterUseCase";
import { PrinterTypeEnum } from "modules/config/smartPrinterConfig/presentation/components/printerSectorConfig/PrinterSectorConfig";
import { ISmartPrinterItem } from "modules/config/smartPrinterConfig/presentation/components/smartPrinterIForm/interfaces/ISmartPrinterForm";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetOperatorByNameUseCase from "modules/operators/application/useCases/GetOperatorByNameUseCase";
import GetOperatorListUseCase from "modules/operators/application/useCases/GetOperatorListUseCase";
import GetOperatorByLocalIdUseCase from "modules/saleForProduct/application/GetOperatorByLocalIdUseCase";
import DeletePosUseCase from "modules/salesPos/application/DeletePosUseCase";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import CatalogService from "services/api/catalog/CatalogService";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import PrinterApi from "services/api/config/printer/PrinterApi";
import OperatorApi from "services/api/operator/OperatorApi";
import AddPosApi from "services/api/salesPos/AddPosApi";
import TerminalService from "services/api/terminal/TerminalService";

const service = TerminalService();
const catalogService = CatalogService();
const serviceDevice = DeviceProfileApi();
const operatorService = OperatorApi();
const posService = AddPosApi();

export const UseTerminalPage = (deviceType?: DeviceType, disableFetch?: boolean) => {
    const [updatedTerminalConfig, setUpdatedTerminalConfig] = useState<PatchUpdateTerminalConfigResponse>();
    const [catalogList, setCatalogList] = useState<ICatalogSelect[]>();
    const [printerSectors, setPrinterSectors] = useState<ISmartPrinterItem[]>();
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodItem[]>();
    const [loadingDeletePos, setLoadingDeletePos] = useState(false);

    const { showLoading, hideLoading, toast } = useUi();

    const { currentLocal } = useLocal();

    const getTerminalDevices = useCallback(
        async (type?: DeviceType) => {
            if (currentLocal) {
                try {
                    const response = await GetTerminalDevicesUseCase(service, currentLocal.id, type);
                    return response;
                } finally {
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const getPrinterSectors = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading()
                const response = await GetSmartPrinterUseCase(PrinterApi, currentLocal.id);
                setPrinterSectors(response.list.filter(item => item.type === PrinterTypeEnum.receipt));
            } finally {
                hideLoading()
            }
        }
        else {
            throw new Error('Local não selecionado');
        }
    }
        , [currentLocal, hideLoading, showLoading]
    );


    const { data: devices, isLoading, refetch, isFetching } = useQuery<IDeviceTerminalItemResponse[] | undefined>(
        `@terminals-${deviceType}`, () => {
            return getTerminalDevices(deviceType)
        },
        { enabled: !!currentLocal, retry: false }
    )

    useEffect(() => {
        if (isLoading && !isFetching && !disableFetch) {
            showLoading();
        } else {
            hideLoading();
        }
    }, [isLoading, showLoading, hideLoading, isFetching, disableFetch])


    const patchUpdateTerminalConfig = useCallback(
        async (request: PatchUpdateTerminalConfigRequest) => {
            if (currentLocal) {
                try {
                    showLoading()
                    const response = await PatchUpdateTerminalConfigUseCase(service, currentLocal.id, request);
                    setUpdatedTerminalConfig(response);
                    refetch();
                    return "ok";
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, refetch, hideLoading, showLoading]
    );

    const getCatalogs = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    showLoading()
                    const categoryResponse = await GetCatalogListsUseCase(catalogService, currentLocal.id);
                    setCatalogList(categoryResponse);
                } finally {
                    hideLoading()
                }
            }
        },
        [currentLocal, hideLoading, showLoading],
    );

    const getNavigatorSolicitations = useCallback(async (deviceId: string) => {
        if (currentLocal && deviceType !== DeviceType.POS) {
            try {
                showLoading()
                const response = await GetNavigatorSolicitationsUseCase(service, currentLocal.id, deviceId);
                return response
            } finally {
                hideLoading()
            }
        } else {
            throw new Error('Local não selecionado não realizada');
        }
    },
        [currentLocal, deviceType, hideLoading, showLoading],
    );

    const postApproveSolicitation = useCallback(
        async (solicitationId: string) => {
            if (currentLocal) {
                try {
                    showLoading()
                    await PostApproveSolicitationUseCase(service, currentLocal.id, solicitationId);
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const postRefuseSolicitation = useCallback(
        async (solicitationId: string) => {
            if (currentLocal) {
                try {
                    showLoading()
                    await PostRefuseSolicitationUseCase(service, currentLocal.id, solicitationId);
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const postRefuseAllSolicitations = useCallback(
        async (deviceId: string) => {
            if (currentLocal) {
                try {
                    showLoading()
                    await PostRefuseAllSolicitationsUseCase(service, currentLocal.id, deviceId);
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const postCloseSession = useCallback(
        async (deviceId: string) => {
            if (currentLocal) {
                try {
                    showLoading()
                    await PostCloseSessionUseCase(service, currentLocal.id, deviceId);
                } finally {
                    hideLoading()
                }
            } else {
                throw new Error('Local não selecionado');
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const getPaymentTypes = useCallback(async () => {
        try {
            if (currentLocal) {
                showLoading()
                const response = await GetPaymentMethodsByLocalIdUseCase(serviceDevice, currentLocal.id);
                setPaymentMethods(response);
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, showLoading, hideLoading]);

    const getAdminOperator = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    // const response = await GetOperatorByNameUseCase(operatorService, currentLocal.id, "Operador Admin");
                    const response = await GetOperatorListUseCase(operatorService, currentLocal.id);
                    const operator = response.find(item => item.name.includes("Admin")) ?? (!!response.length ? response[0] : null);
                    console.log("OPERATOR", operator);
                    return operator;
                } finally {
                    hideLoading()
                }
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const deletePos = useCallback(
        async (posId: string) => {
            if (currentLocal) {
                try {
                    setLoadingDeletePos(true);
                    await DeletePosUseCase(posService, { localId: currentLocal.id, deviceId: posId });
                    return "ok";
                } catch (error: any) {
                    if (error.response?.data?.message.includes("está com caixa aberto")) {
                        const message = error.response?.data?.message;
                        const findDot = message.indexOf(".");
                        const errorMessage = message.substring(0, findDot + 1);
                        return toast(errorMessage, "error");
                    }
                    toast("Ocorreu um erro. Tente novamente", "error");
                } finally {
                    setLoadingDeletePos(false);
                }
            }
        },
        [currentLocal, toast]
    );

    return {
        devices,
        updatedTerminalConfig,
        catalogList,
        paymentMethods,
        patchUpdateTerminalConfig,
        getCatalogs,
        getNavigatorSolicitations,
        postApproveSolicitation,
        postRefuseSolicitation,
        postRefuseAllSolicitations,
        postCloseSession,
        getPaymentTypes,
        getAdminOperator,
        isLoading,
        refetch,
        loadingDeletePos,
        deletePos,
        getPrinterSectors,
        printerSectors
    }
}
