import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { OnBoardingSteps } from 'modules/onBoardingTutorials/domain/enums/OnBoardingSteps';
import UseOnboardingStore from 'modules/onBoardingTutorials/store/OnBoardingStore';
import { closeTutorialCard, openTutorialCard } from 'modules/onBoardingTutorials/store/TutorialCardStore';
import React, { FC, RefObject, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import OnBoardingService from 'services/api/onBoardingTutorial/OnBoardingService';
const UseMenuTutorials = () => {
    const { onFinishStep, hasOnboardingStep, onCloseStep, resetSteps } = UseOnboardingStore();
    const { currentLocal } = useLocal();
    const { push } = useHistory();
 

    const showWelcomeTutorial = useCallback(
        async (divRef: RefObject<HTMLDivElement>) => {
            if (currentLocal === undefined) return;

            const onBoardingService = OnBoardingService();
            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.Welcome)


            if (hasOnBoardStep) return;


            openTutorialCard({
                element: divRef.current,
                options: {
                    title: `Bem-vindo ao \nPortal Meep!`,
                    description: 'Preparamos tudo para que você começar a lançar seus pedidos! Vamos lá?',
                    arrowPosition: 'left-top',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    enabledBackdrop: true,
                    action: async () => {
                        // onClose: () => {

                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.Welcome,
                        })
                        closeTutorialCard();
                        push("/private/terminal/salenow")
                    },
                    onClose: () => {
                        onCloseStep({
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.Welcome,
                        })
                    },
                    buttonLabel: 'Lançar meu primeiro pedido',
                }
            });
        }, [currentLocal, hasOnboardingStep, onCloseStep, onFinishStep, push])

    const resetStepsHandler = useCallback(async () => {
        if (currentLocal === undefined) return;

        const onBoardingService = OnBoardingService();
        await resetSteps(onBoardingService, currentLocal?.id ?? '');
    }, [currentLocal, resetSteps])


    useEffect(() => {
        return () => {
            closeTutorialCard();
        }
    }, [])
    return ({ showWelcomeTutorial, resetStepsHandler })
}

export default UseMenuTutorials 