import Layout from "components/layout/presentation/Layout";
import { CollaboratorListSchedulePage } from "modules/schedule/presentation/pages/collaboratorScheduleList/CollaboratorListSchedulePage";
import React from "react";

export const Collaborators = () => {
  return (
    <Layout>
      <CollaboratorListSchedulePage />
    </Layout>
  );
};
