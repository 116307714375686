import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import ProductResponseToValue from "./ProductResponseToValue";
import { ICatalogSelect, ICategorySelect, IPrinterSelect, IProductFormValue } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet";

const GetProductUseCase = async (service: ICatalogService, localId: string, productId: string, categories?: ICategorySelect[], catalogs?: ICatalogSelect[], printers?: IPrinterSelect[]): Promise<IProductFormValue> => {
    try {
        const response = await service.getProduct(localId, productId);
        const values = ProductResponseToValue(response, categories, catalogs, printers);
        return (values)
    } catch (error) {
        throw new Error("Error in GetProductUseCase")
    }
}
export default GetProductUseCase
