import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { IAccessUser } from "modules/catalog/presentation/componentes/productActions/accessProductForm/AccessProductForm";

const GetAccessUsersUseCase = async (catalogService: ICatalogService, localId: string): Promise<IAccessUser[]> => {
    const response = await catalogService.getAccessUsers(localId);
    return (response)
}
export default GetAccessUsersUseCase



