import { useUi } from "contexts/userInterface/UserInterfaceContext";
import EditDeviceProductsUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/deviceProducts/EditDeviceProductsUseCase";
import GetProductListListUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/deviceProducts/GetProductListListUseCase";
import { FormDeviceProfileStep } from "modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import { ProductsApi } from "services/api/products/ProductsApi";
import { IDeviceProducts, IProductList } from "../../components/deviceProfile/profileForms/productsForm/IProducts";

const UseProductss = (path: string = "/private/pdv/perfil") => {
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const [productList, setProductList] = useState<IProductList[]>();
  const { push } = useHistory();

  const getProductList = useCallback(async () => {
    if (currentLocal?.id) {
      try {
        showLoading();
        const productsService = ProductsApi();
        const response = await GetProductListListUseCase(productsService, currentLocal.id);
        setProductList(response);
      } finally {
        hideLoading();
      }
    }
  }, [hideLoading, showLoading, currentLocal?.id]);

  const editDeviceProducts = useCallback(
    async (profileId: string, values: IDeviceProducts) => {
      try {
        if (currentLocal?.id) {
          showLoading();
          const deviceProfileService = DeviceProfileApi();
          await EditDeviceProductsUseCase(profileId, currentLocal.id, deviceProfileService, values);
          push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal?.id, showLoading, push, path, hideLoading]
  );

  return {
    productList,
    getProductList,
    editDeviceProducts,
  };
};

export default UseProductss;
