export interface ILocalsValues {
  id?: string; // GUID
  code: string; // Código (obrigatório)
  name: string; // Local (obrigatório)
  isActive: boolean;
}

export const EMPTY_LOCALS_VALUES = {
  code: '',
  name: ''
};
