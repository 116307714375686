import { DeleteOutlineOutlined, EditOutlined, LinkOutlined } from "@material-ui/icons";
import styles from "./TutorialItemMobile.module.scss";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { format } from "date-fns";

interface ITutorialItemMobileProps {
  item: ITutoriaisRecords;
  setOpenSidesheet: React.Dispatch<
    React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>
  >;
  setEditAndDeleteItem: React.Dispatch<React.SetStateAction<ITutoriaisRecords>>;
}

const TutorialItemMobile = ({
  item,
  setOpenSidesheet,
  setEditAndDeleteItem,
}: ITutorialItemMobileProps) => {
  return (
    <div id={styles.TutorialItemMobile}>
      <div className={styles.item}>
        <span className={styles.title}>{item.title}</span>
        <div className={styles.icons}>
          <div onClick={() => navigator.clipboard.writeText(item.fullTutorialUrl)}>
            <LinkOutlined />
          </div>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isEditing");
          }}>
            <EditOutlined />
          </div>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isDeleting");
            }}
          >
            <DeleteOutlineOutlined />
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.header}>Data publicação</span>
        <span>{format(new Date(item.createdAt), "dd/MM/yyyy")}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.header}>Menu</span>
        <span>{item.menuTitle}</span>
      </div>
    </div>
  );
};
export default TutorialItemMobile;
