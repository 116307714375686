import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import styles from "./SideshetMonitoramento.module.scss";
import { Info } from "@mui/icons-material";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Input from "components/ui/input/Input";
import { UseSidesheetMonitoramento } from "./UseSidesheetMonitoramento";
import { ISidesheetFormItem } from "./interfaces/ISidesheetForm";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";

interface SideshetProps {
  setOpenSideshet: React.Dispatch<React.SetStateAction<string | null>>;
  openSideshet: string | null;
  qtdChecked: number;
  bulkActions: boolean;
  setBulkActions: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideshetMonitoramento = ({
  setOpenSideshet,
  openSideshet,
  bulkActions,
  qtdChecked,
  setBulkActions,
}: SideshetProps) => {
  const onSubmit = (values: ISidesheetFormItem) => {
    console.log(values);
  };

  const titleSideshet = () => {
    switch (openSideshet) {
      case "mensagem":
        return (
          <h2>
            Enviar <b>mensagem</b>
          </h2>
        );
      case "bloquear":
        return (
          <h2>
            Bloquear <b>{step === 1 ? <>equipamento</> : <>terminal</>}</b>
          </h2>
        );
      case "atualizações":
        return (
          <h2>
            Enviar <b>atualizações </b>
          </h2>
        );
      case "fechar":
        return (
          <h2>
            Fechar <b>caixa manual</b>
          </h2>
        );
      case "wifi":
        return (
          <h2>
            Habilitar/desabilitar <b>Wi-Fi</b>
          </h2>
        );
    }
  };

  const buttonSideshet = () => {
    switch (openSideshet) {
      case "mensagem":
        return "Enviar";
      case "bloquear":
        return "Bloquear";
      case "atualizações":
        return "Enviar atualizações";
      case "fechar":
        return "Fechar";
      case "wifi":
        return "Desabilitar ";
      default:
        return "Concluir";
    }
  };

  const { errors, values, step, setValues, setStep, onChangeHandle, onSubmitHandle, setErrors } =
    UseSidesheetMonitoramento(onSubmit);

  const contentSideshet = () => {
    switch (openSideshet) {
      case "mensagem":
        return (
          <div>
            <FormItemContainer label={"Titulo"} required>
              <Input
                name={"titulo"}
                value={values.titulo}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                required
                variant="outlined"
                error={!!errors.titulo}
                helperText={errors.titulo}
              />
            </FormItemContainer>
            <FormItemContainer label={"Mensagem"} required>
              <InputTextArea
                name={"mensagem"}
                value={values.mensagem}
                variant="outlined"
                rows={4}
                multiline
                required
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                error={!!errors.mensagem}
                helperText={errors.mensagem}
                placeholder="Insira aqui seu texto..."
              />
              <span className={styles.caracteres}>Máximo 200 caracteres</span>
            </FormItemContainer>
          </div>
        );
      case "bloquear":
        return <SidesheetFeedback text="Deseja bloquear o terminal PAG3360?" />;
      case "atualizações":
        return (
          <SidesheetFeedback
            text="Deseja enviar atualizações para todos terminais selecionados?"
            helperText={
              <>
                {bulkActions && (
                  <div className={styles.info}>
                    <Info />
                    <span>
                      <b>{qtdChecked} </b> equipamentos selecionados
                    </span>
                  </div>
                )}
              </>
            }
          />
        );
      case "fechar":
        return (
          <SidesheetFeedback text="Deseja fechar o caixa do terminal manualmente?" />
        );
      case "wifi":
        return (
          <SidesheetFeedback
            text="Deseja desabilitar o Wi-Fi do terminal? "
            helperText={
              <>
                {bulkActions && (
                  <div className={styles.info}>
                    <Info />
                    <span>
                      <b>{qtdChecked} </b> equipamentos selecionados
                    </span>
                  </div>
                )}
              </>
            }
          />
        );
    }
  };

  const feedbackSideshet = () => {
    switch (openSideshet) {
      case "mensagem":
        return "Mensagem enviada com sucesso!";
      case "bloquear":
        return "Terminal bloqueado com sucesso!";
      case "atualizações":
        return "Atualizações enviada";
      case "fechar":
        return "Caixa do terminal fechado com sucesso!";
      case "wifi":
        return "Wi-Fi desabilitado com sucesso!";
      default:
        return "Ação concluída com sucesso!";
    }
  };

  const handleConfirm = () => {
    if (openSideshet === "mensagem") {
      onSubmitHandle();
    } else {
      setStep(step + 1);
    }
  };

  return (
    <Sidesheet
      open={openSideshet}
      onClose={() => {
        setOpenSideshet(null);
        setStep(1);
        setBulkActions(false);
        setValues({
          titulo: "",
          mensagem: "",
        })
        setErrors({})
      }}
      title={<>{titleSideshet()}</>}
      content={<>{contentSideshet()}</>}
      cancelButton
      continueButton={buttonSideshet()}
      handleContinueButton={() => handleConfirm()}
      currentStep={step}
      totalSteps={2}
      feedback={<SidesheetFeedback text={feedbackSideshet()} success />}
    />
  );
};
export default SideshetMonitoramento;
