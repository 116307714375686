import React, { useCallback, useMemo, useState } from 'react'
import styles from './OrderList.module.scss'
import { IOrderItem } from 'modules/config/discountCoupons/presentation/interfaces/IReportOrder'
import { IParamsClientDiscountCouponsOrderReport } from 'modules/config/discountCoupons/presentation/hooks/UseReportOrder'
import { moneyMaskNumber } from 'services/utils/Money'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from 'components/skeleton/Skeleton'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

export interface IOrderListProps {
    item: IOrderItem[] | undefined
    count: number
    getMore: () => void
    params: IParamsClientDiscountCouponsOrderReport | undefined
    isLoading: boolean | undefined
}

export const OrderList: React.FC<IOrderListProps> = ({ count, getMore, isLoading, item, params }) => {

    const [openDropDown, setOpenDropDown] = useState<boolean[]>([]);

    const clickOpenDropDown = useCallback((index: number) => {
        setOpenDropDown(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    }, []);

    const clickCloseDropDown = useCallback((index: number) => {
        setOpenDropDown(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
        });
    }, []);

    const listOrder = useMemo(() => (
        item?.map((item, index) => (
            <div className={styles.content}>
                {
                    item.additionals && (item.additionals?.length > 0) ?
                        <div className={styles.containerDropDown}>
                            <span>{item.quantity}x {item.productName}</span>
                            <div className={styles.headerWithAdditional}>
                                <span>{moneyMaskNumber(item.price)}</span>
                                {
                                    item.additionals && item.additionals.length > 0 && (
                                        openDropDown[index] ? (
                                            <KeyboardArrowUp onClick={() => clickCloseDropDown(index)} />
                                        ) : (
                                            <KeyboardArrowDown onClick={() => clickOpenDropDown(index)} />
                                        )
                                    )
                                }
                            </div>
                        </div>
                        :
                        <span>{item.quantity}x {item.productName}</span>
                }
                {
                    item.additionals && (item.additionals?.length > 0) &&
                    openDropDown[index] &&
                    (
                        <div className={styles.additionals}>
                            {
                                item.additionals.map(additional => (
                                    <div className={styles.contentAdditionals}>
                                        <span>
                                            {additional.productName}
                                        </span>
                                        <span>
                                            {moneyMaskNumber(additional.price)}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <div className={styles.value}>
                    <span>Valor:</span>
                    <span>{moneyMaskNumber(item.totalGross)}</span>
                </div>
            </div>
        ))), [clickCloseDropDown, clickOpenDropDown, item, openDropDown])


    return (
        <div id={styles.OrderList}>
            <div className={styles.container}>
                {
                    !item?.length ?
                        <section className={styles.wrapper}>
                            <div>Nenhum resultado</div>
                        </section>
                        :
                        <InfiniteScroll
                            next={() => {
                                getMore?.();
                            }}
                            hasMore={count ? item?.length < count : false}
                            loader={<Skeleton items={6} />}
                            dataLength={item?.length ?? 0}
                            scrollableTarget={styles.OrderList}
                        >
                            <div className={styles.list}>
                                {listOrder}
                            </div>
                        </InfiniteScroll>
                }
            </div>
        </div>
    )
}
