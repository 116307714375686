import React, { FC } from "react";
import Layout from "components/layout/presentation/Layout";
import { ServiceScheduleListPage } from "modules/schedule/presentation/pages/serviceScheduleList/ServiceScheduleListPage";
import TabPage from "../dashboard/tabPage/TabPage";
import { PlaceScheduleListPage } from "modules/schedule/presentation/pages/placeScheduleList/PlaceScheduleListPage";
import { ReserveListPage } from "modules/schedule/presentation/pages/reserveList/ReserveListPage";
import { CollaboratorListSchedulePage } from "modules/schedule/presentation/pages/collaboratorScheduleList/CollaboratorListSchedulePage";
import ServiceCategoriesPage from "modules/schedule/presentation/pages/serviceCategories/ServiceCategoriesPage";

const RouterPrefix = "/private/agendamento/list";

function Agendamento() {
  return (
    <Layout>
      <>
        <TabPage
          tabsPages={[
            {
              label: "Reservas",
              tab: "reservas",
              component: <ReserveListPage />,
              roles: "ListaAgendamento",
            },
            {
              label: "Agenda de Serviços",
              tab: "servicos",
              component: <ServiceScheduleListPage />,
            },
            {
              label: "Categorias de Serviços",
              tab: "categorias",
              component: <ServiceCategoriesPage />,
              roles: "ListaCategoriaAgendamento",
            },
            {
              label: "Agenda de Passeios",
              tab: "lugares",
              component: <PlaceScheduleListPage />,
            },
            {
              label: "Agenda de Colaboradores",
              tab: "colaborador",
              component: <CollaboratorListSchedulePage />,
            },
          ]}
          routerPrefix={RouterPrefix}
        />
      </>
    </Layout>
  );
}
export default Agendamento;
