import { useUi } from "contexts/userInterface/UserInterfaceContext";
import EditDevicePaymentUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/devicePayment/EditDevicePaymentUseCase";
import GetPaymentMethodsByLocalIdUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/devicePayment/GetPaymentMethodsByLocalIdUseCase";
import GetCustomPaymentMethodsByLocalIdUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/devicePayment/GetCustomPaymentMethodsByLocalIdUseCase";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import {
  IDevicePaymentValue,
  IPaymentMethodItem,
} from "../../components/deviceProfile/profileForms/devicePaymentForm/interfaces/IDevicePaymentValue";
import { FormDeviceProfileStep } from "../../../domain/interface/FormDeviceProfileSteps";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { ICustomPaymentItemResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getCustomPaymentList/ICustomPaymentResponse";

const UseDevicePayment = (perfilVinculo: number | undefined, path: string = "/private/pdv/perfil") => {
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodItem[]>();
  const [customPaymentMethods, setCustomPaymentMethods] = useState<ICustomPaymentItemResponse[]>();

  const { push } = useHistory();
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();

  const editPaymentConfig = useCallback(
    async (profileId: string, values: IDevicePaymentValue) => {
      try {
        if (currentLocal && perfilVinculo) {
          showLoading();
          const service = DeviceProfileApi();
          await EditDevicePaymentUseCase(
            service,
            values,
            currentLocal.id,
            profileId
          );
          if (perfilVinculo === 1) {
            push(
              `${path}/editar/${profileId}/` +
                FormDeviceProfileStep.DEVICE
            );
          } else {
            push(
              `${path}/editar/${profileId}/` +
                FormDeviceProfileStep.PRODUCTS
            );
          }
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, perfilVinculo, showLoading, push, path, hideLoading]
  );

  const getPaymentMethods = useCallback(
    async (localId: string) => {
      try {
        showLoading();
        const service = DeviceProfileApi();
        const response = await GetPaymentMethodsByLocalIdUseCase(
          service,
          localId
        );
        const responseCustomPayment = await GetCustomPaymentMethodsByLocalIdUseCase(
          service,
          localId
        );
        setPaymentMethods(response);
        setCustomPaymentMethods(responseCustomPayment);
      } finally {
        hideLoading();
      }
    },
    [hideLoading, showLoading]
  );

  return {
    editPaymentConfig,
    paymentMethods,
    getPaymentMethods,
    customPaymentMethods,
  };
};

export default UseDevicePayment;
