import { Icon } from "@material-ui/core";
import { ModeEditOutlineOutlined, Delete } from "@mui/icons-material";
import Button from "components/ui/Button/Button";
import { Autocomplete } from "@material-ui/lab";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import LinkPeopleListToBaseListUseCase from "modules/events/application/useCases/baseList/LinkPeopleListToBaseListUseCase";
import GuestCount from "modules/events/presentation/components/guestCount/GuestCount";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import Utils from "services/utils/Utils";
import promotersIcon from "../../../../../../assets/icons/lock_icon.svg";
import guestsIcon from "../../../../../../assets/icons/users.svg";
import { IBaseList, IEventsGuestList, IEventsGuestListLink } from "../../../../interfaces/IEventGuestsListsPage";
import styles from "./GuestsListCard.module.scss";
import { TextField } from "@material-ui/core";
import { PublicLinkModal } from "./components/PublicLinkModal/PublicLinkModal";
import { DeleteModal } from "./components/deleteModal/DeleteModal";
import { IUpdateGuestListLinkRequest } from "modules/events/domain/dto/IEventsRequest";
import { UpdateGuestsListLinkUseCase } from "modules/events/application/useCases/guests/UpdateGuestsListLinkUseCase";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { DeleteGuestsListLinkUseCase } from "modules/events/application/useCases/guests/DeleteGuestsListLinkUseCase";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import UseDimension from "components/dimension/UseDimension";
import PopMenu from "components/popMenu/PopMenu";

interface IEventListCardProps {
  eventList: IEventsGuestList;
  onClickGoToGuestList: (eventListId: string) => void;
  onClickEditGuestList: (eventList: IEventsGuestList) => void;
  onClickDeleteGuestList: (listId: string) => void;
  onClickGrantAccess: (eventListId: string) => void;
  baseList: IBaseList[];
  refreshEventLists: () => Promise<void>;
  adminAccess?: boolean;
}

const service = EventsGuestsApi();

export const GuestsListCard: React.FC<IEventListCardProps> = ({
  eventList,
  onClickGoToGuestList,
  onClickEditGuestList,
  onClickGrantAccess,
  onClickDeleteGuestList,
  baseList,
  refreshEventLists,
  adminAccess,
}) => {
  const { hideLoading, showLoading, toast } = useUi();
  const baseListService = DiscountBaseListApi();
  const [hasLink, setHasLink] = useState(false);
  const [publicLinkModalOpened, setPublicLinkModalOpened] = useState(false);
  const [deletePublicLinkModalOpened, setDeletePublicLinkModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<IEventsGuestListLink>();
  const { hasAccessRole } = useAuth();
  const { currentLocal } = useLocal();
  const { dimensions } = UseDimension();


  useEffect(() => {
    if (eventList.peopleListLink) {
      setHasLink(true);
      setDefaultValues(eventList.peopleListLink)
    }
  }, [eventList.peopleListLink]);

  const handleBaseList = async (value: IBaseList | null) => {
    if (!currentLocal) return;
    showLoading();
    try {
      const id = value ? value.id : null;
      await LinkPeopleListToBaseListUseCase(baseListService, id, eventList.id, currentLocal.id);
      refreshEventLists();
      toast("Lista alterada com sucesso!", "success");
    } catch (error) {
      toast("Falha ao alterar lista!", "error");
    } finally {
      hideLoading();
    }
  };

  const onChangeBaseList = (event: ChangeEvent<{}>, value: IBaseList | null) => {
    handleBaseList(value).then();
  }

  const handleSubmit = async (values: IUpdateGuestListLinkRequest) => {
    if (!currentLocal) return;
    try {
      setLoading(true);
      await UpdateGuestsListLinkUseCase(service, { ...values, peopleListId: eventList.id }, currentLocal.id);
      setPublicLinkModalOpened(false);
      refreshEventLists();
    } catch (err: any) {
      if (err.response.data.message)
        toast(err.response.data.message, 'error');
      else
        toast('Houve um erro ao salvar o link. Tente novamente.', 'error');
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    if (!currentLocal) return;

    try {
      setLoading(true);
      await DeleteGuestsListLinkUseCase(service, eventList.id, currentLocal.id);
      setDeletePublicLinkModalOpened(false);
      refreshEventLists();
      setHasLink(false);
      setDefaultValues(undefined);
    } catch {
      toast('Houve um erro ao deletar o link. Tente novamente.', 'error')
    } finally {
      setLoading(false);
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.origin + '/public/lista-de-convidados/' + eventList.id)
    toast("Url copiada com sucesso!", "success");
  }

  const catalogAction = useMemo(() => [
    { label: `Excluír`, icon: <Delete />, action: () => onClickDeleteGuestList(eventList.id) },
    { label: `Promoters`, icon: <img src={promotersIcon} alt="Promoters_icon" />, action: () => onClickGrantAccess(eventList.id) },
    { label: `Editar`, icon: <ModeEditOutlineOutlined />, action: () => onClickEditGuestList(eventList) },
    { label: eventList.isActive ? "Convidados" : "Pessoas", icon: <img src={guestsIcon} alt="Guests_icon" />, action: () => onClickGoToGuestList(eventList.id) },    
], [eventList, onClickDeleteGuestList, onClickGrantAccess, onClickEditGuestList, onClickGoToGuestList])

  return (
    <>
      <div id={styles.EventListCard}>
        <div className={styles.container}>
          <div className={styles.textEventList}>
            <div className={styles.nameList}>
              {(eventList.copyConfiguration || eventList.copyGuests) && <Icon>push_pin</Icon>}
              <h2 style={{ opacity: eventList.isActive ? 1 : 0.5 }}>{eventList.name}</h2>
              {
                dimensions.width < 600 ?
                <div>                
                  <PopMenu
                    actions={catalogAction}
                  />
                </div>
                :                               
                <div>
                  {/* <Button
                    startIcon={<CopyAllOutlined />}
                    variant="text"
                    color="default"
                    onClick={() => onClickCopyUrl(eventList.id)}
                    disabled={!eventList.isActive && eventList.id === eventList.notOnGuestListPeopleListId}
                    style={{ opacity: (eventList.id !== eventList.notOnGuestListPeopleListId) ? 1 : 0.5 }}
                  >
                      <h4>Link publico</h4>
                  </Button>    */}
                  <Button
                    startIcon={<Delete />}
                    variant="text"
                    color="default"
                    onClick={() => onClickDeleteGuestList(eventList.id)}
                    disabled={!eventList.isActive || eventList.id === eventList.notOnGuestListPeopleListId}
                    style={{ opacity: (eventList.id !== eventList.notOnGuestListPeopleListId) ? 1 : 0.5 }}
                  >
                    <h4>Excluir</h4>
                  </Button>

                  {adminAccess && (
                    <Button
                      startIcon={<img src={promotersIcon} alt="Promoters_icon" />}
                      variant="text"
                      color="default"
                      onClick={() => onClickGrantAccess(eventList.id)}
                      disabled={!eventList.isActive}
                      style={{ opacity: eventList.isActive ? 1 : 0.5 }}
                    >
                      <h4>Promoters</h4>
                    </Button>
                  )}

                  <Button
                    startIcon={<ModeEditOutlineOutlined />}
                    variant="text"
                    color="default"
                    onClick={() => onClickEditGuestList(eventList)}
                    disabled={!eventList.isActive || eventList.id === eventList.notOnGuestListPeopleListId}
                    style={{ opacity: (eventList.id !== eventList.notOnGuestListPeopleListId) ? 1 : 0.5 }}
                  >
                    <h4>Editar</h4>
                  </Button>

                  <Button
                    startIcon={<img src={guestsIcon} alt="Guests_icon" />}
                    variant="text"
                    color="default"
                    onClick={() => onClickGoToGuestList(eventList.id)}
                  >
                    <h4>{!eventList.isActive ? "Pessoas" : "Convidados"}</h4>
                  </Button>
              </div>

              }
            </div>
            <section className={styles.row}>
              <section className={styles.column}>
                <p style={{ opacity: eventList.isActive ? 1 : 0.5 }}>
                  Fechamento:
                  <h5>{Utils.toDateAndTime(eventList.closingDateTime)}</h5>
                </p>
                <p style={{ opacity: eventList.isActive ? 1 : 0.5 }}>
                  Promoter responsável:
                  <h5>{eventList?.mainPromoter?.name}</h5>
                </p>
                <p style={{ opacity: eventList.isActive ? 1 : 0.5 }}>
                  Lista de desconto:
                  <h4>
                    <Autocomplete
                      disabled={!eventList.isActive}
                      className={styles.baseList}
                      options={baseList}
                      value={eventList.baseList}
                      getOptionLabel={(option: IBaseList) => option.name}
                      onChange={onChangeBaseList}
                      renderInput={(params) => (
                        <TextField color={"secondary"} {...params} />
                      )}
                    />
                  </h4>
                </p>
              </section>
              <section className={styles.column} style={{ opacity: eventList.isActive ? 1 : 0.5 }}>
                <GuestCount
                  title={!eventList.isActive ? "Pessoas" : "Convidados"}
                  total={!eventList.isActive ? eventList.totalSalesNotInvite : eventList.guestCount}
                  male={eventList.maleGuestCount}
                  female={eventList.femaleGuestCount}
                />
                {eventList.isActive && (
                  <GuestCount
                    title="Vendas"
                    total={eventList.totalGuestSales}
                    male={eventList.maleGuestSales}
                    female={eventList.femaleGuestSales}
                  />
                )}

                <GuestCount
                  title="Check-in"
                  total={eventList.totalGuestCheckinCount}
                  male={eventList.maleGuestCheckinCount}
                  female={eventList.femaleGuestCheckinCount}
                />
              </section>
            </section>

            {
              eventList.isActive && hasAccessRole('CriarLinkPublico') &&
              <>
                {

                  !hasLink ?
                    <button className={styles.guestListButton} onClick={() => setPublicLinkModalOpened(true)}>
                      <span><b>Gerar um link</b> para esta lista de convidados</span>
                      <button>
                        <Icon>chevron_right</Icon>
                      </button>
                    </button> :
                    <div className={styles.guestListLink}>
                      <span>Link: <b>{eventList.eventName}</b></span>
                      <div>
                        {eventList.peopleListLink?.visibleInApp && <span>Visível no aplicativo</span>}
                        <button onClick={handleCopyToClipboard}><Icon>link</Icon></button>
                        <button onClick={() => setDeletePublicLinkModalOpened(true)}><Icon>delete_outline</Icon></button>
                        <button onClick={() => setPublicLinkModalOpened(true)}><img src="/assets/icon/edit_outlined_white.svg" alt="" /></button>
                      </div>
                    </div>
                }
              </>
            }
          </div>
        </div>
      </div>

      <PublicLinkModal open={publicLinkModalOpened} loading={loading} defaultValues={defaultValues} eventName={eventList.eventName} onClose={() => setPublicLinkModalOpened(false)} onSubmit={handleSubmit} />
      <DeleteModal open={deletePublicLinkModalOpened} loading={loading} onClose={() => setDeletePublicLinkModalOpened(false)} onDelete={handleDelete} />
    </>
  );
};
