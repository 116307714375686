
export interface IServiceScheduleForm {
    id?: string,
    duration: number, // Valor em minutos, apenas valores inteiros
    durationType: EnumDurationType,
    name: string,
    description: string,
    moreDescriptions: IDescriptionForm[],
    productId?: string,
    image?: string, // string em base 64 
    imageUrl?: string, // string em base 64 
    value: number, // Valor do serviços em decimal
    showcaseImagesUrls?: string[];
    serviceCategoryId: string, // Guid Valor virá através de uma lista em outro serviço
    serviceSubCategoryId?: string,
    serviceCategoryName: string, // Ao informar o nome sem o Id será criado uma nova categoria
    otherValues /*Lista*/: IOtherValuesForm[]
}

export interface IOtherValuesForm {
    id?: string,
    productListId: string,
    description: string, // Ao enviar nome sem enviar id será criado uma nova lista de produtos
    value: number,
    minAge: number,
    maxAge: number
}
export interface IDescriptionForm {
    id?: string,
    title: string,
    description: string,
}

export enum EnumDurationType {

    "Minutos" = 0,
    "Horas" = 1,
}