import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import TabView from "components/tab/TabView";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConfigSankhyaIntegration from "../../components/_configCampaign/configContentSankhya/configSankhyaIntegration/ConfigSankhyaIntegration";
import ConfigSankhyaPayment from "../../components/_configCampaign/configContentSankhya/configSankhyaPayment/ConfigSankhyaPayment";
import { UseConfigSankhya } from "./UseConfigSankhya";
import { ISankhyaPaymentRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentRequest";
import { ISankhyaConfigurationRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import {
  ISankhyaFormErrors,
  SankhyaValidation,
} from "./validade/SankhyaValidation";
import { ISankhyaPaymentFormErrors, SankhyaPaymentValidation } from "./validade/SankhyaPaymentValidation";

export interface IConfigSankhyaProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  existPayment: boolean;
  setExistPayment: React.Dispatch<React.SetStateAction<boolean>>;
  creditCard: boolean;
  setCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum SankhyaIntegrationStepEnum {
  integration = 0,
  payment = 1,
  success = 2,
}

const ConfigSankhya = ({
  open,
  setOpen,
  step,
  setStep,
  existPayment,
  setExistPayment,
  creditCard,
  setCreditCard,
}: IConfigSankhyaProps) => {
  const {
    loading,
    configValues: integratedValues,
    paymentForms,
    createAndUpdateConfig,
    createPaymentForm,
  } = UseConfigSankhya();
  const { currentLocal } = useLocal();

  const [errors, setErrors] = useState<ISankhyaFormErrors>({});
  const [errorsPayment, setErrorsPayment] = useState<ISankhyaPaymentFormErrors>({});

  const [configValues, setConfigValues] =
    useState<ISankhyaConfigurationRequest>({} as ISankhyaConfigurationRequest);
  const [creditValues, setCreditValues] = useState<ISankhyaPaymentRequest>(
    {} as ISankhyaPaymentRequest
  );

  useEffect(() => {
    if (integratedValues) {
      setConfigValues({
        OwnerId: integratedValues.mappingView.ownerId,
        SankhyaCompanyId: integratedValues.mappingView.sankhyaCompanyId?.toString(),
        Protocol: integratedValues.mappingView.protocol,
        Host: integratedValues.mappingView.host,
        Port: integratedValues.mappingView.port,
        DefaultSeller: integratedValues.mappingView.defaultSeller?.toString(),
        SankhyaUser: integratedValues.mappingView.sankhyaUser,
        SankhyaPass: integratedValues.mappingView.sankhyaPass,
        ValidateTicketTop: integratedValues.mappingView.validateTicketTop?.toString(),
        SankhyaCodNat: integratedValues.mappingView.sankhyaCodNat?.toString(),
        SankhyaTypeSaleCode: integratedValues.mappingView.sankhyaTypeSaleCode?.toString(),
        TicketTopProduction: integratedValues.mappingView.ticketTopProduction,
        CashTop: integratedValues.mappingView.cashTop,
      });
    }
  }, [integratedValues]);

  useEffect(() => {
    if (integratedValues && integratedValues.formsOfPayment.length > 0) {
      setExistPayment(true);
    }
  }, [integratedValues, setExistPayment]);

  useEffect(() => {
    if (currentLocal) {
      setConfigValues({ ...configValues, OwnerId: currentLocal.id });
    }
  }, [currentLocal, setConfigValues]);

  const sankhyaIntegrationSteps = useMemo(() => {
    switch (step - 1) {
      case SankhyaIntegrationStepEnum.integration:
        return (
          <ConfigSankhyaIntegration
            values={configValues}
            setValues={setConfigValues}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case SankhyaIntegrationStepEnum.payment:
        return (
          <ConfigSankhyaPayment
            creditCard={creditCard}
            setCreditCard={setCreditCard}
            creditValues={creditValues}
            setCreditValues={setCreditValues}
            formsOfPayment={paymentForms}
            errors={errorsPayment}
            setErrors={setErrorsPayment}
          />
        );
    }
  }, [
    step,
    creditCard,
    setCreditCard,
    configValues,
    setConfigValues,
    creditValues,
    setCreditValues,
    paymentForms,
    errors,
    errorsPayment,
  ]);

  const handleFooterButton = () => {
    if (step - 1 === SankhyaIntegrationStepEnum.payment) {
      if (creditCard) {
        return "Salvar";
      }
      return "Concluir";
    }
    return "Continuar";
  };

  const handleOnClickFooterButton = useCallback(async () => {
    if (step - 1 === SankhyaIntegrationStepEnum.integration) {
      if (SankhyaValidation(configValues, setErrors)) {
        const res = await createAndUpdateConfig(configValues);
        if (res === "ok") return setStep(step + 1);
      }
      return;
    }
    if (creditCard ) {  
      if (SankhyaPaymentValidation(creditValues, setErrorsPayment)) {
        const res = await createPaymentForm(creditValues);
        if (res === "ok") {
          setCreditValues({} as ISankhyaPaymentRequest);
          setCreditCard(false);
          return setStep(1);
        }
      }    
      return;
    }
    return setStep(step + 1);
  }, [configValues, setErrors, creditCard, createAndUpdateConfig, createPaymentForm, creditValues, setCreditCard, setStep, step]);

  const handleShowTabView = useCallback(() => {
    if (creditCard || step - 1 === SankhyaIntegrationStepEnum.success) {
      return undefined;
    }
    return (
      <TabView
        index={step - 1 || SankhyaIntegrationStepEnum.integration}
        tabs={[
          {
            label: "Integração",
            value: SankhyaIntegrationStepEnum.integration,
          },
          {
            label: "Pagamento",
            value: SankhyaIntegrationStepEnum.payment,
          },
        ]}
        onChangeTab={(tab) => setStep(tab.value + 1)}
      />
    );
  }, [creditCard, step, setStep]);

  const handleStatus = useMemo(
    () => (integratedValues ? "enabled" : "disabled"),
    [integratedValues]
  );

  return (
    <>
      <ContentBox
        status={handleStatus}
        loading={loading}
        type={IntegrationTypeEnum.SANKHYA}
        text="Integração para gestão de negócios e ERP em diversos serviços"
        setOpen={setOpen}
        getMoreRef="/private/sankhya/detail"
      />
      {open && open === "Sankhya" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            step - 1 !== SankhyaIntegrationStepEnum.success ? (
              <>{sankhyaIntegrationSteps}</>
            ) : (
              <div />
            )
          }
          arrowBack={
            step - 1 === SankhyaIntegrationStepEnum.payment && creditCard
          }
          handleArrowBack={() => setCreditCard(false)}
          notTotalHeight={step - 1 === SankhyaIntegrationStepEnum.integration}
          cancelButton
          continueButton={handleFooterButton()}
          handleContinueButton={handleOnClickFooterButton}
          currentStep={step}
          totalSteps={3}
          feedback={
            step - 1 === SankhyaIntegrationStepEnum.success ? (
              <SidesheetFeedback
                text="Integração realizada com sucesso!"
                success
              />
            ) : undefined
          }
          tabView={handleShowTabView()}
          disableContinueButton={
            step - 1 === SankhyaIntegrationStepEnum.payment &&
            !creditCard &&
            !existPayment
          }
        />
      )}
    </>
  );
};

export default ConfigSankhya;
