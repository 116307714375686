import { Dispatch, SetStateAction } from 'react';
import styles from './ChangeEnvironmentModal.module.scss';
import { Radio } from '@material-ui/core';
import { EnvironmentEnum } from '../../interfaces/EnvironmentEnum';

interface ChangeEnvironmentModalProps {
    onSave: () => void;
    onClose: () => void;
    loading: boolean;
    environment: EnvironmentEnum;
    setEnvironment: Dispatch<SetStateAction<EnvironmentEnum>>;
}

export const ChangeEnvironmentModal = ({ onClose, onSave, setEnvironment, environment, loading }: ChangeEnvironmentModalProps) => {
    return (
        <div className={styles.container}>
            <h1>Escolha seu ambiente fiscal</h1>

            <div className={styles.radioContainer}>
                <Radio checked={environment === EnvironmentEnum.HOMOLOGATION} onChange={() => setEnvironment(EnvironmentEnum.HOMOLOGATION)} />
                <p>Homologação</p>
            </div>
            <p className={styles.environmentDescription}>Vincule os mesmos equipamentos para todos os caixas/evento criados.</p>

            <div className={styles.radioContainer}>
                <Radio checked={environment === EnvironmentEnum.PRODUCTION} onChange={() => setEnvironment(EnvironmentEnum.PRODUCTION)} />
                <p>Produção</p>
            </div>
            <p className={styles.environmentDescription}>Vincule diferentes equipamentos para cada caixa/evento criado.</p>

            <div className={styles.buttonsContainer}>
                <button onClick={onClose}>
                    Cancelar
                </button>
                <button onClick={onSave} disabled={loading}>
                    {
                        loading ? 'Enviando...' : 'Concluir'
                    }
                </button>
            </div>
        </div>
    )
}