import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './SupplyGroupPage.module.scss'
import SupplyGroupList from 'modules/meepErp/presentation/components/supply/suplyGroup/supplyGroupList/SupplyGroupList'
import { IGetSupplyGroupListResponse, IGetSupplyGroupItemResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyGroupListDtos'
import { ISupplyGroupFilterValue } from 'modules/meepErp/presentation/components/supply/suplyGroup/supplyGroupList/supplyGroupListFilter/ISupplyGroupFilter'
import UseQueryState from 'modules/meepErp/presentation/hook/UseQueryFilter'
import { useMutation } from 'react-query'
import { queryClient } from 'services/api/Api'
import { useHistory } from 'react-router-dom'
import { DeleteSupplyGroupUseCase, EnableSupplyGroupUseCase, UpdateSupplyGroupUseCase, getSupplyGroupListUseCase, searchNameSupplyGroupUseCase } from 'modules/meepErp/application/useCases/supply/supplyGroup/SupplyGroupUseCases'
import { Add } from '@mui/icons-material'
import DeleteErp from 'modules/meepErp/presentation/components/ui/delete/DeleteErp'
import { Button } from '@material-ui/core'
import TabsErp from 'modules/meepErp/presentation/components/ui/tabs/TabsErp'
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import { tabsRegister } from '../../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IGetSupplyNameRequest } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'

const SupplyGroupPage: FC = () => {
    const { state: filter, updateState: updateFilter } = UseQueryState<ISupplyGroupFilterValue>();
    const { push } = useHistory();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Grupo de insumos",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);


    const onSuccessDeleteSupplyGroup = (_: void, id: string) => {
        queryClient.setQueriesData<IGetSupplyGroupListResponse | undefined>(['getSupplyGroupList'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const GetSearchNameSupplyGroup = useCallback(async (request: IGetSupplyNameRequest) => {
        return await searchNameSupplyGroupUseCase(request);
    }, [])

    const onDeleteMutation = useMutation((id: string) => DeleteSupplyGroupUseCase(id), {
        onSuccess: onSuccessDeleteSupplyGroup
    });

    const onClickEditHandler = (id: string) => {
        push(`/private/meeperp/supply/supplyGroups/update/${id}`)
    }

    const onClickEnableHandler = async (id: string, value: boolean, item: IGetSupplyGroupItemResponse) => {
        await EnableSupplyGroupUseCase(id, value)
        return {
            ...item,
            isActive: value
        }
    }

    const onConfirmDelete = async (id: string) => {
        await onDeleteMutation.mutateAsync(id)
    }

    const getSupplyGroupList = useCallback(async (newFilter: ISupplyGroupFilterValue) => {
        return getSupplyGroupListUseCase({ ...newFilter })
    }, [])

    const onClickAddHandler = () => {
        push('/private/meeperp/supply/supplyGroups/add')
    }

    return (
        <div id={styles.SupplyGroupPage} >
            <div className={styles.container} >
                <TabsErp data={tabsRegister} />
                {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                    <div>
                        <Button
                            fullWidth={false}
                            className={styles.buttonAdd}
                            onClick={onClickAddHandler}
                            endIcon={<Add className={styles.iconAdd} />}>
                            Adicionar grupo de insumo
                        </Button>
                    </div>
                }
                <SupplyGroupList
                    getSupplyGroupList={getSupplyGroupList}
                    onDelete={(item) => setItemToDelete(item)}
                    onEdit={onClickEditHandler}
                    onClickEnable={onClickEnableHandler}
                    filter={filter}
                    onChangeFilter={updateFilter}
                    GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}

                />
                <DeleteErp
                    open={!!itemToDelete}
                    onClose={() => setItemToDelete(undefined)}
                    onSubmit={() => onConfirmDelete(itemToDelete!)}
                    title={'operação'}
                    text={'Deseja realmente excluir a operação?'}
                    textFeedback={'Operação excluído com sucesso!'}
                    auxiliaryText='Ao excluir um grupo de insumo todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
                />
            </div>
        </div>
    )
}
export default SupplyGroupPage