
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IEditChild } from "../interfaces/IEditChild";
import { EditChildUseCase } from "modules/permutation/application/useCases/EditChildUseCase";

const UseEditChild = () => {

  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successEditChild, setSuccessEditChild] = useState(false)


  const putEditChild = useCallback(
    async (accountId: string, childrenId: string, values: IEditChild) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingEdit(true);
        if (currentLocal) {
          const response = await EditChildUseCase(servicePermutation,
            { ...values },
            currentLocal.id,
            accountId,
            childrenId
          );
          toast("Editado com sucesso", "success")
          setSuccessEditChild(true)
          return response
        }
      } catch {
        setSuccessEditChild(false)
      }

      finally {
        setIsLoadingEdit(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessEditChild,
    successEditChild,
    isLoadingEdit,
    putEditChild,
  };
};

export default UseEditChild;
