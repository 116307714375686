import { ITicketService } from 'modules/events/application/interfaces/ITicketService';
import { ITicketForm } from 'modules/events/presentation/pages/manageEvent/components/tickets/components/ticketForm/ITicketForm';

const UpdateTicketUseCase = async (ticketService: ITicketService, localId: string, eventId: string, ticketId: string, ticketForm: ITicketForm): Promise<string> => {
  const payload = {
    ...ticketForm, 
    expireTime: ticketForm.hasExpireTime ? ticketForm.expireTime as number : null, 
    localClienteId: localId,
    maxQuantityPerUser: ticketForm.maxQuantityPerUser || 1
  }
  return await ticketService.update(eventId, ticketId, payload);
};

export default UpdateTicketUseCase;
