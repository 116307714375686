import UseDimension from "components/dimension/UseDimension";
import styles from "./TutorialPage.module.scss";
import {
  ArrowBackIosNewRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import FilterContent from "../components/filterContent/FilterContent";
import UseTutorialPage from "../hooks/UseTutorialPage";
import { MenuItem, Pagination, TextField } from "@mui/material";

const TutorialPage = () => {
  const { isMobile } = UseDimension();
  const { push } = useHistory();
  const { tutoriaisList, totalPages, currentPage, nextPage, getTutoriaisList} = UseTutorialPage();

  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState<string>("10");

  useEffect(() => {
    getTutoriaisList(currentPage, {}, Number(pageSize));
  }, [currentPage, pageSize, getTutoriaisList]); 

  const filters = useMemo(() => {
    const filtersOnly = tutoriaisList ? tutoriaisList.map((it) => {
      return { title: it.menuTitle };
    }) : [];
    return filtersOnly.filter((it, index) => filtersOnly.indexOf(it) === index);
  }, [tutoriaisList]);

  const tutorials = useMemo(() => filters.map((filter) => {
    return {
      ...filter, 
      data: tutoriaisList 
        ? tutoriaisList.filter((it) => it.menuTitle === filter.title) 
        : [] 
      }
  }), [tutoriaisList, filters]);
  
  const handleSelectedFilter = (filter: string) => {
    if (selectedFilter.length === 0) {
      return setSelectedFilter([filter]);
    }
    if (selectedFilter.includes(filter)) {
      const removeFilter = selectedFilter.filter((item) => item !== filter);
      return setSelectedFilter(removeFilter);
    }
    setSelectedFilter([...selectedFilter, filter]);
  };

  return (
    <div id={styles.TutorialPage}>
      <div
        className={`${styles.tutorialContainer} ${
          isMobile && styles.tutorialContainerMobile
        }`}
      >
        <div>
          <div className={styles.containerTop} onClick={() => push("/")}>
            <ArrowBackIosNewRounded style={{ width: "10px" }} />
            <span>Voltar ao portal</span>
          </div>
        </div>
        <div className={styles.containerHeader}>
          <div className={styles.containerTitle}>
            <img src="/assets/icon/open-book-emoji.svg" alt="" />
            <h1> Tutoriais</h1>
          </div>
          <button>
            <img src="/assets/icon/hot-icon.svg" alt="" />
            <span>Veja todas as novidades</span>
            <KeyboardArrowRightRounded />
          </button>
        </div>
        <div
          className={styles.filters}
          style={{
            flexWrap: isMobile ? "nowrap" : "wrap",
            justifyContent: isMobile ? "flex-start" : "center",
          }}
        >
          {filters.length > 0 && filters.map((filter) => (
            <button
              key={filter.title}
              className={
                selectedFilter && selectedFilter.includes(filter.title)
                  ? styles.selectedFilter
                  : ""
              }
              onClick={() => handleSelectedFilter(filter.title)}
            >
              {filter.title}
            </button>
          ))}
        </div>
        <div className={styles.containerFilter}>
        {tutorials && tutorials.map((item) => {
          if (
            selectedFilter.length === 0 ||
            selectedFilter.includes(item.title)
          ) {
            return <FilterContent title={item.title} data={item.data} />;
          }
          return null;
        })}
        </div>
        {!!totalPages && (
          <div className={styles.conteinerPagination}>
            <div className={styles.boxMultiplePage}>
              <span>Exibir</span>
              <TextField 
                variant="outlined"
                size="small"
                select
                value={pageSize}
                defaultValue={pageSize}
                onChange={(ev) => setPageSize(ev.target.value)}
              >
                <MenuItem className={styles.textPage} value={10}>
                  10
                </MenuItem>
                <MenuItem className={styles.textPage} value={20}>
                  20
                </MenuItem>
                <MenuItem className={styles.textPage} value={50}>
                  50
                </MenuItem>
              </TextField>
            </div>
            {totalPages > 0 && (
              <Pagination
                variant="outlined"
                className={styles.paginationNumber}
                shape="rounded"
                sx={{
                  ".MuiPaginationItem-root": {
                    borderColor: "#BDBDBD",
                    color: "#001537",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    borderColor: "#001537",
                  },
                }}
                count={totalPages}
                page={currentPage + 1}
                onChange={(_, value) => nextPage(value - 1, {}, Number(pageSize))}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorialPage;
