import { IProfiles } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListResponse";
import styles from "./CargosItem.module.scss";
import { EditOutlined, DeleteOutlineOutlined } from "@mui/icons-material";

interface ICargosItem {
  item: IProfiles;
  setOpenSidesheet: React.Dispatch<
    React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>
  >;
  setEditAndDeleteItem: React.Dispatch<React.SetStateAction<IProfiles | undefined>>;
}

const CargosItem = ({
  item,
  setOpenSidesheet,
  setEditAndDeleteItem,
}: ICargosItem) => {
  return (
    <>
      <div id={styles.CargosItem}>
        <span>{item.Name}</span>
        <span>{item.PermissionCount}</span>
        <span>{item.EmployeeCount}</span>
        <div className={styles.icons}>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isEditing");
          }}>
            <EditOutlined className={styles.edit} />
          </div>
          <div onClick={() => {
            setEditAndDeleteItem(item);
            setOpenSidesheet("isDeleting");
          }}>
            <DeleteOutlineOutlined />
          </div>
        </div>
      </div>
    </>
  );
};
export default CargosItem;
