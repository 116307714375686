import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks/UseQuery";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { getParamsString } from "../../utils/params";

export const UseCieloSystemPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState("");

    let query = useQuery();
    const history = useHistory();
    const { toast } = useUi();

    useEffect(() => {
        const _type = query.get("type") ?? "";
        let storageType = localStorage.getItem("@type") ?? undefined;
        
        if (_type === "3" || _type === "7") {
            localStorage.setItem("@type", _type);
            storageType = _type;
        }
        
        if (storageType) {
            setType(storageType);
        } else {
            setType(_type);
        }
    }, [query]);

    const handleSubmit = async (cieloSystem: number) => {
        try {
            setIsLoading(true);
            history.push(`/public/saas/register?type=${cieloSystem}&` + getParamsString(query), { cieloSystem });
        } catch {
            toast("Ocorreu um erro ao se registrar. Tente novamente.", "error");
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        type,
        handleSubmit,
    }
}