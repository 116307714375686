import React, { FC, useCallback, useEffect } from 'react'
import styles from './UnitAddOrUpdatePage.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import UnitService from 'modules/meepErp/services/api/Unit/UnitService'
import { IUnitValues } from 'modules/meepErp/presentation/components/unit/unitForm/UnitValues'
import UnitForm from 'modules/meepErp/presentation/components/unit/unitForm/UnitForm'
import { IGetUnitListResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse'
import { IUnitResponse } from 'modules/meepErp/application/dtos/unit/IUnitResponse'
import { UseLocalStore } from 'modules/local/presentation/store/UseLocalStore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'

const UnitAddOrUpdatePage: FC = () => {
    const [currentLocal] = UseLocalStore(state => [state.currentLocal])
    const { id } = useParams<{ id?: string }>();
    const queryClient = useQueryClient();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Unidade de medida",
                url: '/private/meeperp/unit/'
            },
            {
                title: "Cadastro de unidade de medida",
            },                                  
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const submitHandler = useCallback(async (values: IUnitValues, id?: string) => {
        try {
            showLoading();
        if (id) {
            const service = UnitService();
            return await service.updateUnit({ ...values, id: id! });
        } else {
            const service = UnitService();
            return await service.createUnit(values);
        }
    } finally {
        hideLoading();
    }
    }, [])



    const getDefaultValue = useCallback(async (localId?: string, id?: string) => {
        try {
            // showLoading();
        if (!id) return;
        if (!localId) throw new Error("Local não econtrado!");
        const response = await UnitService().getUnitItem(localId, id);
        return response;
    } finally {
        hideLoading();
    }
    }, [])


    //REACT QUERY
    const defaultValue = useQuery('@unit', () => getDefaultValue(currentLocal?.id, id), {
        enabled: !!id && !!currentLocal?.id,
    })

    const onSuccessfulSubmit = useCallback((data: IUnitResponse) => {
        try {
            showLoading();
        if (id) {
            queryClient.setQueriesData<IGetUnitListResponse | undefined>(['@unit'], (previous) =>
            (previous ? {
                ...previous,
                items: previous?.items?.map(item => item.id === id ? data : item) ?? []
            } : undefined))
        } else {
            queryClient.setQueriesData<IGetUnitListResponse | undefined>(['@unit'], (previous) =>
            (previous ? {
                ...previous,
                items: [data, ...previous.items]
            } : undefined))
        }
 
        replace('/private/meeperp/unit')
    } finally {
        hideLoading();
    }
    }, [id, queryClient, replace])

    const submitForm = useMutation((values: IUnitValues) => submitHandler(values, id), {
        onSuccess: onSuccessfulSubmit
    })

    const onClickSubmitForm = async (values: IUnitValues) => {
        await submitForm.mutateAsync(values);
    }

    return (
        <div id={styles.InvetoryOperationAddOrUpdatePage} >
            <Back route='/private/meeperp/unit/' margin/>
            <div className={styles.container} >
                <UnitForm
                     isLoading={submitForm.isLoading || defaultValue.isLoading}
                     disabled={submitForm.isLoading || defaultValue.isLoading}
                     defaultValue={defaultValue.data}
                     onSubmit={onClickSubmitForm}
                />
            </div>
        </div>
    )
}
export default UnitAddOrUpdatePage
