import { InputAdornment, Switch, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import React, { FC, FormEvent, memo, useCallback, useEffect, useState } from 'react'
import FooterButtons from '../../../footerButtons/FooterButtons'
import { IOperatorItem, IOperatorItemValue } from './interfaces/IOperatorsValues'
import styles from './OperatorsForm.module.scss'
import './OperatorsForm.css'

export interface IOperatorsFormProps {
    //propertys
    operators?: IOperatorItem[];
    defaultValues?: IOperatorItemValue[];
    modified: boolean;
    onModified: (modified: boolean) => void;
    onChange?: (values: IOperatorItemValue[]) => void
    onClickBack: (values: IOperatorItemValue[]) => void
    onSubmit: (values: IOperatorItemValue[]) => void
}
const DeviceOperatorsForm: FC<IOperatorsFormProps> = ({ operators, defaultValues, modified, onModified, onChange, onClickBack, onSubmit }) => {
    const [values, setValues] = useState(defaultValues ?? []);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState(operators);

    useEffect(() => {
        if (operators) {
            if (defaultValues) {
                setValues(prev => operators.map((item) => ({ ...item, enabled: defaultValues.find(defaultItem => defaultItem.id === item.id)?.enabled ?? false })))
            }
        } else {
            console.error("Operators list nao foi informado")
        }

        return () => {

        }
    }, [defaultValues, operators])


    const onChangeEnabled = useCallback((id: string, checked: boolean) => {
        onModified(true);
        setValues(prev => prev.map((item) => item.id === id ? ({ ...item, enabled: checked }) : item))
    }, [onModified])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])


    useEffect(()=>{
        if (searchTerm)
            setFilteredList(operators?.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase())))
        else
            setFilteredList(operators);
    }, [operators, searchTerm])

    return (
        <form id={styles.OperatorsForm} onSubmit={submitHandle}>
            <TextField
                className="operators-form-search"
                variant="outlined"
                label="Buscar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end"><Search /></InputAdornment>,
                }}
            />
            <div className={styles.container} >
                <FormItemContainer className={styles.list}>
                    <div className={styles.list}>
                        {
                            filteredList?.map((operator) =>
                            (
                                <div className={styles.item}>
                                    <div className={styles.imageUrl}>
                                        <img src={operator.imageUrl || '/assets/icon/image_placeholder.svg'} alt="Foto do operador" className={operator.imageUrl ? '' : styles.imagePlaceholder} />
                                    </div>
                                    <label>{operator.name}</label>
                                    <Switch
                                        checked={values.find(item => item.id === operator.id)?.enabled}
                                        onChange={(ev, checked) => onChangeEnabled(operator.id, checked)}
                                        color='secondary'
                                    />

                                </div>
                            ))
                        }
                    </div>
                </FormItemContainer>
            </div>
            <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit' />
        </form>
    )
}
export default memo(DeviceOperatorsForm)