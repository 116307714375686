import Layout from 'components/layout/presentation/Layout'
import SaasPaymentPage from 'modules/saasPayment/presentation/SaasPaymentPage'

export const SaasPayment = () => {
    return (
        <Layout disabledMenu>
            <SaasPaymentPage />
        </Layout>
    )
}
