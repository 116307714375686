import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import React, { FC } from 'react'
import ColaboratorScheduleForm from '../../components/colaboratorScheduleForm/ColaboratorScheduleForm'
import { ICollaboratorScheduleFormValue } from '../../components/colaboratorScheduleForm/ColaboratorScheduleFormValue'
import styles from './AddColaboratorSchedulePage.module.scss'
import { UseAddColaboratorSchedulePage } from './UseAddColaboratorSchedulePage'
export interface IColaboratorSchedulePageProps {
    //propertys
}
const AddColaboratorSchedulePage: FC<IColaboratorSchedulePageProps> = () => {
    const { toast } = useUi();
    const {
        onSubmitFormHandle,
        operators,
        services
    } = UseAddColaboratorSchedulePage();
    return (
        <div id={styles.ColaboratorSchedulePage} >
            <div className={styles.container} >
                {
                    operators &&
                    services &&
                    <ColaboratorScheduleForm
                        onSubmit={(value: ICollaboratorScheduleFormValue) => {onSubmitFormHandle(value) 
                        toast("Colaborador criado com sucesso!", "success") }}
                        operators={operators}
                        services={services}
                    />}
            </div>
        </div>
    )
}
export default AddColaboratorSchedulePage