import { Email } from "@mui/icons-material"
import { format, parse, subMinutes } from "date-fns"
import { ISaveCampaingRequest } from "modules/crm/models/dtos/ISaveCampaingRequest"
import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { CampaignChannel, CampaignTemplate, ICampaignFormValue } from "modules/crm/presentation/intefaces/ICampaign"

const SaveCampaignUseCase = (service: ICrmService, campaignId: string, campaignFormValues: ICampaignFormValue) => {

    // const deliveryTime = campaignFormValues.push?.date ? parse(campaignFormValues?.push?.date + ' ' + campaignFormValues?.push?.time, 'dd/MM/yyyy HH:mm', new Date()) : null;
    const deliveryTime = campaignFormValues.push?.date && campaignFormValues?.push?.time ? new Date(campaignFormValues?.push?.date + ' ' + campaignFormValues?.push?.time) : null

    const request: ISaveCampaingRequest = {
        name: campaignFormValues.name,
        channel: campaignFormValues.channel,
        template: campaignFormValues.template,
        messageBody: campaignFormValues.channel === CampaignChannel.email ? campaignFormValues.email!.messageBody : campaignFormValues.channel === CampaignChannel.whatsapp ? campaignFormValues.whatsapp!.message : campaignFormValues.push!.messageBody || '',
        eventId: campaignFormValues.channel === CampaignChannel.email ? campaignFormValues.email?.event?.id : campaignFormValues.whatsapp?.event?.id,
        replyTo: campaignFormValues.email?.replyTo,
        subject: campaignFormValues.channel === CampaignChannel.email ? campaignFormValues.email?.subject : campaignFormValues.push?.subject,
        promoter: campaignFormValues.email?.promoter,
        imageUrl: campaignFormValues.push?.imageUrl,
        deliveryTime: deliveryTime ? deliveryTime : undefined,
        surveyId: campaignFormValues.channel === CampaignChannel.email ? campaignFormValues.email?.survey?.id : campaignFormValues.channel == CampaignChannel.push ? campaignFormValues.push?.survey?.id: undefined
    }

    const response = service.saveCampaign(campaignId, request)
    return (response)
}
export default SaveCampaignUseCase  