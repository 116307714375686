import UserButton from "../userButton/UserButton";
import styles from "./MyAccount.module.scss";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";

export interface IMyAccount {
  logout: () => void;
}

export const MyAccount = ({ logout }: IMyAccount) => {
  const { user } = useUser();

  return (
    <div id={styles.MyAccount}>
      <div className={styles.user}>
        <UserButton />
        <div>
          <p className={styles.name}>{user?.name}</p>
          <p className={styles.email}>{user?.email}</p>
        </div>
      </div>
      <p className={styles.logout} onClick={logout}>
        Sair
      </p>
    </div>
  );
};
