import React, { FC, useMemo } from "react";
import styles from "./TableList.module.scss";
import { Divider, IconButton, Skeleton, Switch } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";

export interface ITableItem {
    id: string;
    number: string;
    status: number;
}

export interface ITableListProps {
    //propertys
    tableList: ITableItem[],
    isLoading?: boolean,
    onClickEdit: (table: ITableItem) => void,
    onClickDelete: (table: ITableItem) => void,
    onChageStatus: (table: ITableItem, checked: boolean) => void
}



const TableList: FC<ITableListProps> = ({ tableList, isLoading, onClickEdit, onClickDelete, onChageStatus }) => {

    const list = useMemo(
      () => (
        <div className={styles.listContainer}>
          {tableList.map((table, index) => (
            <>
              <div key={index} className={styles.row}>
                {/* <div className={styles.cell}>{table.id}</div> */}
                <div className={styles.number}>{table.number}</div>
                <div className={styles.active}>
                  <Switch
                    defaultChecked={table.status === 1}
                    onChange={(_, checked) => onChageStatus(table, checked)}
                  />
                </div>
                <div className={styles.actions}>
                  <IconButton onClick={() => onClickEdit(table)}>
                    <EditOutlined />
                  </IconButton>
                  <IconButton onClick={() => onClickDelete(table)}>
                    <DeleteOutlined />
                  </IconButton>
                </div>
              </div>
              {index !== tableList.length - 1 && <Divider />}
            </>
          ))}
        </div>
      ),
      [tableList, onChageStatus, onClickEdit, onClickDelete]
    );
    
    const skeleton = useMemo(() => (
        <div className={styles.listContainer}>
            {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} width="100%" height={50} />
            ))}
        </ div>
    ), []);
    
    return (
        <div id={styles.TableList} >
            <div className={styles.container} >
                {isLoading ? skeleton : list}
            </div>
        </div>
    )
}
export default TableList