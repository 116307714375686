import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IPermutationAccountfilter } from "../components/permutationFilter/interfaces/IPermutationAccountfilter";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IUseChildList } from "../interfaces/IUseChildList";
import GetChildUseCase from "modules/permutation/application/useCases/GetChildUseCase";

export interface IParamsUseChildList {
  accountId?: string,
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: IPermutationAccountfilter
}

const UseChildList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [useChildList, setUseChildList] = useState<IUseChildList>()
  const { currentLocal } = useLocal();

  const getUseChildList = useCallback(
    async (accountId: string, params?: IParamsUseChildList) => {
      try {
        const servicePermutation = PermutationApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetChildUseCase(servicePermutation,
            accountId,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );

          setUseChildList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    useChildList,
    isLoading,
    getUseChildList,
    setUseChildList,
  };
};

export default UseChildList;
