import { IconButton, Radio } from "@mui/material";
import styles from "../../ChangePaymentMethod.module.scss";
import { IGetAllCardsResponse } from "modules/saasPayment/domain/dto/IGetAllCardsResponse";
import { useCallback, useEffect, useMemo } from "react";
import { DeleteOutline } from "@material-ui/icons";

interface ICardItemProps {
  card: IGetAllCardsResponse;
  isCreatingNewCard: boolean;
  newDefaultCard: string;
  setNewDefaultCard: React.Dispatch<React.SetStateAction<string>>;
  setOpenDeletCard: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

const brands = [
    { name: "Visa", pattern: "4" },
    { name: "Mastercard", pattern: "5" },
    { name: "American Express", pattern: "3" },
];

const CardItem = ({ card, isCreatingNewCard, newDefaultCard, setNewDefaultCard, setOpenDeletCard, isLoading }: ICardItemProps) => {
    const brandImg = (brandName: string) => {
      switch (brandName) {
        case "Visa":
          return <img src="/assets/icon/saas/cards/borderless-visa.svg" alt="" />;
        case "Mastercard":
          return <img src="/assets/icon/saas/cards/borderless-master.svg" alt="" />;
        case "American Express":
          return <img src="/assets/icon/saas/cards/borderless-amex.svg" alt="" />;
        default:
          return <img src="/assets/icon/saas/cards/borderless-master.svg" alt="" />;
      }
    };

    const handleFindBrand = useCallback(() => {
        const brand = brands.find(brand => brand.pattern === card.card.firstSixDigits[0]);
        return brandImg(brand?.name ?? "");
    }, [card.card.firstSixDigits]);

    useEffect(() => {
      if (card.isDefault) {
        setNewDefaultCard(card.subscriptionCardId);
      }
    }, [card, setNewDefaultCard]);

    const isDefaultCard = useMemo(() =>
      (newDefaultCard === card.subscriptionCardId && !isCreatingNewCard) ?? false,
    [card, newDefaultCard, isCreatingNewCard]);

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <div className={styles.cardName}>
                  <Radio
                    className={styles.radio}
                    checked={isDefaultCard}
                    onChange={() => setNewDefaultCard(card.subscriptionCardId)}
                    disabled={isLoading}
                  />
                  <p>{card.card.holderName}</p>
                </div>
                {!card.isDefault && (
                  <IconButton
                    size="small"
                    disableFocusRipple
                    onClick={() => setOpenDeletCard(card.subscriptionCardId)}
                    disabled={isLoading}
                  >
                    <DeleteOutline style={{ color: "#5F5F5F" }}/>
                  </IconButton>
                )}
            </div>
            
            <div className={styles.row} style={{ marginBottom: 0 }}>
                {handleFindBrand()}
                <span className={styles.ccData}>**** {card.card.lastFourDigits} | {card.card.expirationMonth}/{card.card.expirationYear.toString().slice(2)}</span>
            </div>
        </div>
    )
}

export default CardItem;