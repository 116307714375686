import { IGetIsMenuFavoriteResponse } from "components/layout/domain/dto/IGetIsMenuFavoriteResponse";
import { IGetMenuResponse } from "components/layout/domain/dto/IGetMenuResponse";
import { IGetMenuSearchResponse } from "components/layout/domain/dto/IGetMenuSearchResponse";
import { IPostMenuRequest } from "components/layout/domain/dto/IPostMenuRequest";
import { IMenuService } from "components/layout/domain/interfaces/IMenuService";
import { api } from "../Api";
import { IGetMenuHistoryResponse } from "components/layout/domain/dto/IGetMenuHistoryResponse";

const baseUrl = process.env.REACT_APP_DYNAMICMENU_URL;

const MenuApi = (): IMenuService => {
    const getMenu = async (token: string | null, positionType: number, ownerId: string, systemIdentifier: number): Promise<IGetMenuResponse[]> => {
        const respomse = await api.get<IGetMenuResponse[]>(`${baseUrl}/Menu/${positionType}`, {
          params: { ownerId: ownerId, systemIdentifier: systemIdentifier },
          headers: { Authorization: `Bearer ${token}` },
        });
        return respomse.data;
    }

    const postMenuFavorite = async (request: IPostMenuRequest): Promise<void> => {
        const respomse = await api.post<any>(`${baseUrl}/Menu/favorite`, request);
        return respomse.data;
    }

    const deleteMenuFavorite = async (request: IPostMenuRequest): Promise<void> => {
        const respomse = await api.delete<any>(`${baseUrl}/Menu/favorite`, {
            data: {
                menuId: request.menuId,
                ownerId: request.ownerId
            },
        });
        return respomse.data;
    }

    const postRecordMenuActivity = async (request: IPostMenuRequest): Promise<void> => {
        const respomse = await api.post<any>(`${baseUrl}/Menu/activity`, request);
        return respomse.data;
    }

    const getIsMenuFavorite = async (params: IPostMenuRequest): Promise<IGetIsMenuFavoriteResponse> => {
        const respomse = await api.get<IGetIsMenuFavoriteResponse>(`${baseUrl}/Menu/favorite`, { params: params });
        return respomse.data;
    }

    const getMenuSearch = async (keyword: string): Promise<IGetMenuSearchResponse[]> => {
        const respomse = await api.get<IGetMenuSearchResponse[]>(`${baseUrl}/Menu/searchByKeyword`, { params: { keyword: keyword} });
        return respomse.data;
    }

    const getMenuHistory = async (ownerId: string): Promise<IGetMenuHistoryResponse[]> => {
        const respomse = await api.get<IGetMenuHistoryResponse[]>(`${baseUrl}/Menu/AccessHistory`, { params: { ownerId: ownerId} });
        return respomse.data;
    }

    return { getMenu, postMenuFavorite, deleteMenuFavorite,postRecordMenuActivity, getIsMenuFavorite, getMenuSearch, getMenuHistory };
}

export default MenuApi;
