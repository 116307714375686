import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import styles from "../FidelityForm.module.scss";
import { Button, Icon, TextField } from "@material-ui/core";
import { v4 } from "uuid";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

export interface IFidelityBenefitsFormValue {
  id?: string;
  name: string;
  description: string;
  icon: string;
}

type error = {
  id: string;
  errors?: {
    name?: string;
    description?: string;
    icon?: string;
  }
}

export interface IFidelityBenefitsFormProps {
  onSubmit?: (value: IFidelityBenefitsFormValue[]) => void;
  onClickBack?: () => void;
  defaultValues?: IFidelityBenefitsFormValue[];
}

const FidelityBenefitsForm: FC<IFidelityBenefitsFormProps> = ({
  onClickBack,
  onSubmit,
  defaultValues
}) => {
  var [values, setValues] = useState<IFidelityBenefitsFormValue[]>([{ id: v4(), name: '', description: '', icon: '' }]);
  var [error, setError] = useState<error[]>([]);
  const [showPicker, setShowPicker] = useState<number>();

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setValues(prev => prev.map((x, i) => i !== index ? x : { ...x, [ev.target.name]: ev.target.value }))
  }, []);

  const changeIcon = useCallback((icon, index: number) => {
    setValues(prev => prev.map((x, i) => i !== index ? x : { ...x, icon }));
    setShowPicker(undefined);
  }, []);

  const validate = useCallback(() => {
    setError([]);
    const errors: error[] = [];

    values.forEach(x => {
      const _error: error = { id: x.id!, errors: {} }
      if (!x.name) {
        _error.errors!.name = 'Insira um nome para o benefício';
      }
      if (!x.description) {
        _error.errors!.description = 'Insira uma descrição para o benefício';
      }
      if (!x.icon) {
        _error.errors!.icon = 'Selecione um ícone para o benefício';
      }

      if (Object.keys(_error.errors!).length) {
        errors.push(_error);
      }
    })

    if (errors.length) {
      setError(errors);
      return false;
    }

    return true;
  }, [values]);

  const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (validate()) {
      onSubmit?.(values);
    }
  },
    [onSubmit, validate, values]
  );

  const handleAddBenefit = () => {
    setValues(prev => ([...prev, { id: v4(), name: '', description: '', icon: '' }]));
  }

  const handleRemoveBenefit = (id: string) => {
    setValues(prev => prev.filter(x => x.id !== id));
    setError(prev => prev.filter(x => x.id !== id));
  }

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues)
    }
  }, [defaultValues])

  return (
    <form onSubmit={submitHandle}>
      <div className={styles.formContainer}  >
        <div className={styles.item}>
          <div className={styles.itemHeader}>
            <Icon>info</Icon>
            <h4>
              Esse texto estará visível para seu cliente no App Meep. <strong>É responsabilidade do estabelecimento</strong> cumprir os benefícios aqui descritos.
            </h4>
          </div>
          {
            values.map((val, index) => (
              <div className={styles.benefitsContainer}>
                <div className={styles.header}>
                  Benefício {index + 1}
                  <button type="button" className={styles.btnClean} onClick={() => handleRemoveBenefit(val.id!)}>
                    <Icon>delete_outline</Icon>
                  </button>
                </div>
                <label htmlFor='name' className={`${styles.required} ${styles.benefitsLabel}`}>
                  Nome do benefício:
                </label>
                <TextField
                  name='name'
                  onChange={ev => changeHandle(ev, index)}
                  value={val.name}
                  variant="outlined"
                  fullWidth
                  size='small'
                  helperText={error.find(x => x.id === val.id)?.errors?.name}
                  error={!!error.find(x => x.id === val.id)?.errors?.name}
                  className={styles.textField}
                  placeholder='Estacionamento exclusivo'
                />

                <label htmlFor='name' className={`${styles.required} ${styles.benefitsLabel}`}>
                  Selecione o ícone
                </label>

                <TextField
                  name='icon'
                  onChange={ev => changeHandle(ev, index)}
                  value={val.icon ? String.fromCodePoint.apply(null, val.icon.split('-').map(x => Number(`0x${x}`))) : ''}
                  variant="outlined"
                  fullWidth
                  size='small'
                  helperText={error.find(x => x.id === val.id)?.errors?.icon}
                  error={!!error.find(x => x.id === val.id)?.errors?.icon}
                  className={styles.textField}
                  placeholder='Escolha o ícone'
                  InputProps={{
                    readOnly: true,
                  }}
                  onClick={() => setShowPicker(index)}
                />

                {
                  showPicker === index &&
                  <div className={styles.pickerContainer}>
                    <Picker
                      data={data}
                      onEmojiSelect={({ unified }: { unified: string }) => changeIcon(unified, index)}
                      onClickOutside={() => setShowPicker(undefined)}
                      locale="pt"
                      theme="light"
                      icons="solid"
                      previewPosition="none"
                      skinTonePosition="none"
                    />
                  </div>
                }

                <label htmlFor='name' className={`${styles.required} ${styles.benefitsLabel}`}>
                  Descrição do benefício
                </label>
                <TextField
                  name='description'
                  onChange={ev => changeHandle(ev, index)}
                  value={val.description}
                  variant="outlined"
                  multiline
                  minRows={4}
                  fullWidth
                  size='small'
                  helperText={error.find(x => x.id === val.id)?.errors?.description}
                  error={!!error.find(x => x.id === val.id)?.errors?.description}
                  className={styles.textField}
                  placeholder='Estacionamento exclusivo ao lado do local com manobrista'
                />
              </div>
            ))
          }
          <button type="button" className={styles.addBenefit} onClick={handleAddBenefit}>
            Adicionar novo benefício <Icon>add_circle</Icon>
          </button>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button className={styles.buttons} type='submit' variant='contained' color='secondary'>Salvar</Button>
      </div>
    </form>
  )
}

export default FidelityBenefitsForm
