import React, { useCallback, useMemo, useState } from "react";
import styles from "./PermutationAccount.module.scss";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import { IPermutationAccountList, IRecordsItem } from "modules/permutation/presentation/interfaces/IPermutationAccountList";
import { IParamsPemutationAccountList } from "modules/permutation/presentation/hooks/UsePermutationAccountList";
import { IPermutationColumnsConfig, PermutationAccountColunmsPropertyType } from "../../interfaces/IColumnsConfig";
import { IGetPermutionItemResponse } from "modules/permutation/domain/dto/IGetPermutionItemResponse";
import CPF from "services/utils/CPF";
import CNPJ from "services/utils/CNPJ";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UseDeletePermutationAccount from "modules/permutation/presentation/hooks/UseDeletePermutationAccount";
import DeleteValidation from "./deleteValidation/DeleteValidation";

interface Props {
  columns: IPermutationColumnsConfig[];
  userItemData: IRecordsItem;
  permutationAccountList: IPermutationAccountList;
  reload: () => void;
  params: IParamsPemutationAccountList;
  setOpenSidesheet: React.Dispatch<React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>>
  getPermutationAccountItem: (accountId: string) => Promise<IGetPermutionItemResponse | undefined>
  open: boolean
}

const PermutationAccountItem: React.FC<Props> = ({
  userItemData,
  columns,
  setOpenSidesheet,
  reload,
  getPermutationAccountItem
}) => {

  const [step, setStep] = useState(1);
  const [openDrownDelete, setOpenDrownDelete] = useState(false)
  const { deletePermutation, isLoadingDelete } = UseDeletePermutationAccount();

  const openDrownEdit = useCallback(
    (accountId: string) => {
      setOpenSidesheet("isEditing");
      getPermutationAccountItem(accountId);
    },
    [getPermutationAccountItem, setOpenSidesheet]
  );
  const onClose = useCallback(() => {
    if(step === 2) {
      reload();
    }
    setStep(1)
    setOpenDrownDelete(false)
  }, [reload, step])
 
  const continueOrSave = useCallback(async () => {
    if (step === 1 && userItemData.hasOpenMeepAccount) {
      onClose();
    } else {
      const res = await deletePermutation(userItemData.id);
      if (res === "ok") {       
        return setStep(step + 1);
      }
    }
  }, [deletePermutation, onClose, step, userItemData.hasOpenMeepAccount, userItemData.id])

  const closedOrDeleteButton = useCallback(() => {
    if (step === 1 && userItemData.hasOpenMeepAccount) {
      return "Fechar"
    } else {
      return "Excluír"
    }
  }, [step, userItemData.hasOpenMeepAccount])

  
  const openDrowDelete = useCallback(
    () => {
      setOpenDrownDelete(true);
    },
    []
  );

  const formatDocument = useCallback((document: string) => {
    if (CPF.isValid(document))
      return CPF.cpfMask(document);
    else
      return CNPJ.mask(document);
  }, []);

  const formatValues = useCallback(
    (property: PermutationAccountColunmsPropertyType): string => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {
          case "document":
            return userItemData?.document
              ? formatDocument(userItemData?.document)
              : "-";
          case "balance":
            return userItemData?.balance
              ? moneyMaskNumber(userItemData?.balance)
              : "-";

          default:
            return String(userItemData?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [formatDocument, userItemData]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span title={formatValues(colunm.property)}>{formatValues(colunm.property)}</span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  const handleContent = useCallback(() => {
    let highlightText = `${userItemData.name}?`;
    let message = `Deseja realmente excluir a permuta`;

    if (step === 1 && userItemData.hasOpenMeepAccount) {
      highlightText = "";
      message = "Existe cashless virtual ativo vinculado a permuta, não é possível excluir.";
    }

    return <DeleteValidation text={message} highlightText={highlightText} />
  }, [step, userItemData.hasOpenMeepAccount, userItemData.name])

  return (
    <>
      <div id={styles.DiscountCouponItem}>
        <div className={styles.container}>
          {List}
          <div className={styles.icon}>
            <EditOutlined
              titleAccess="Editar"
              onClick={() => openDrownEdit(userItemData.id)}
            />

            <DeleteOutline titleAccess="Excluir" onClick={openDrowDelete} />
          </div>
        </div>
      </div>
      <Sidesheet
        isLoading={isLoadingDelete}
        open={openDrownDelete}
        onClose={onClose}
        title={
          <h2>
            Excluir <b>permuta</b>
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        handleContinueButton={continueOrSave} 
        continueButton={closedOrDeleteButton()}
        cancelButton={!userItemData.hasOpenMeepAccount}
        feedback={
          <SidesheetFeedback text="Permuta excluída com sucesso!" success />
        }
      />
    </>
  );
};

export default PermutationAccountItem;
