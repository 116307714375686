export interface IArrObj {
  [x: string]: string;
}

export interface IListObj {
  id: string;
  name: string;
}

export const parseObjToArr = (obj: IArrObj) => {
  let arr: IListObj[] = [];
  Object.keys(obj).forEach((item) => arr.push({ id: item, name: obj[item] }));
  return arr;
};