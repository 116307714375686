import { IGetEquipmentsResponse } from "modules/printQueue/domain/dto/IGetEquipmentsResponse";
import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";


const GetEquipmentsUseCase = async (service: IPrintQueueApiService, localId: string): Promise<IGetEquipmentsResponse[]> => {
  const response = service.getEquipments(localId);
  return response;
};

export default GetEquipmentsUseCase;
