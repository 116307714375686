import { IDevicePaymentValue } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/devicePaymentForm/interfaces/IDevicePaymentValue";
import { IDeviceCatalogValue } from "../components/deviceProfile/profileForms/deviceCatalogForm/IDeviceCatalogForm";
import { IDeviceEnviromentValue } from "../components/deviceProfile/profileForms/deviceEnvironmentForm/Interfaces/IEnvironmentValue";
import { IDeviceInvoiceValue } from "../components/deviceProfile/profileForms/deviceInvoiceForm/interfaces/InvoiceValues";
import { IDeviceItemValue } from "../components/deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem";
import { IOperationParamsValues } from "../components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import { IOperatorItemValue } from "../components/deviceProfile/profileForms/operatorsForm/interfaces/IOperatorsValues";
import { IPrinterParamsValues } from "../components/deviceProfile/profileForms/printerParamsForm/IPrinterParamsValues";
import { IUsabilityValues } from "../components/deviceProfile/profileForms/usabilityForm/IUsabilityValues";
import { IUserInterfaceValues } from "../components/deviceProfile/profileForms/userInterfaceForm/IUserInterfaceValues";
import { IPagSeguroParamsValues } from "../components/deviceProfile/profileForms/pagSeguroParamsForm/IPagSeguroParamsValues";
import { ITerminalValues } from "../components/deviceProfile/profileForms/terminalForm/ITerminalValues";

export interface IDeviceProfile {
    id: string;
    name: string;
    description: string;
    isEnabled: boolean;
    isDefault: boolean;
    payment: IDevicePaymentValue;
    honestMarket: IDeviceProfileHonestMarket;
    products: IDeviceProfileProducts;
    schedule: IDeviceProfileSchedule;
    security: IDeviceProfileSecurity;
    school: IDeviceProfileSchool;
    delivery: IDeviceProfileDelivery;

    usability: IUsabilityValues;
    userInterface: IUserInterfaceValues;

    invoice: IDeviceInvoiceValue;
    operation: IOperationParamsValues;
    printer: IPrinterParamsValues;
    pagSeguroConfiguration: IPagSeguroParamsValues;
    // customerIdentifications: ICustomerIdentification;
    environments: IDeviceEnviromentValue[];
    catalogs: IDeviceCatalogValue[];
    operators: IOperatorItemValue[];

    // TODO: check and remove
    devices: IDeviceItemValue[];
    terminal: ITerminalValues;
}

export interface IDeviceProfileHonestMarket {
    isSingleScreenEnabled: true;
    isBarReaderEnabled: true;
    isOnlyBarReaderEnabled: true;
}

export interface IDeviceProfileProducts {
    productListId: string | null;
}

export interface IDeviceProfileSchedule {
    enabled: true;
}

export interface IDeviceProfileSecurity {
    isStrongPasswordEnabled: true;
    analyzeConfiguredAlerts: boolean;
}

export interface IDeviceProfileSchool {
    isSearchCardByNicknameEnabled: true;
    isNumericKeypadNicknameEnabled: true;
}

export interface IDeviceProfileDelivery {
    isAcceptPayDeliveryOrderEnabled: true;
}

export enum CustomerFieldType {
    NAME = 1,
    DOCUMENT,
    PHONE,
    EMAIL,
    BIRTHDAY,
    TAG,
    IDENTIFIER,
    ADRRESS
  }
export interface ICustomerIdentification {
    localId: string;
    profileId: string;
    ticket: ICustomerSystemTypeIdentification[],
    prePaydCashless: ICustomerSystemTypeIdentification[],
    posPaidAccount: ICustomerSystemTypeIdentification[],
}

export interface ICustomerSystemTypeIdentification {
    required: boolean,
    enabled?: boolean,
    id?: string,
    identification: {
        name: string,
        value: CustomerFieldType
    }
}
