import styles from "./NovidadesItemMobile.module.scss";
import {
  EditOutlined,
  DeleteOutlineOutlined,
  InsertLink,
} from "@mui/icons-material";

interface INovidadesItem {
  data: string;
  titulo: string;
  categoria: string;
  actionEdit: () => void;
  actionDelete: () => void;
}

const NovidadesItemMobile = ({ data, titulo, categoria,actionEdit, actionDelete }: INovidadesItem) => {
  return (
    <div id={styles.NovidadesItemMobile}>
      <div className={styles.item}>
        <span className={styles.cargo}>{titulo}</span>
        <div className={styles.icons}>
          <InsertLink />
          <EditOutlined onClick={actionEdit}/>
          <DeleteOutlineOutlined onClick={actionDelete}/>
        </div>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Data</span>
        <span>{data}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Categoria</span>
        <span>{categoria}</span>
      </div>
    </div>
  );
};
export default NovidadesItemMobile;
