import { Button, FormControl, FormControlLabel, Icon, IconButton, Radio, RadioGroup, Switch, Tab, Tabs, TextField } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { EnumSurveyEvent, getName } from '../interfaces/EnumSurveyEvent';
import { EnumSurveySendMethod } from '../interfaces/EnumSurveySendMethod';
import { EventSurvey } from './EventSurvey';
import styles from './SaveSurveysForm.module.scss';
import { ITrackSurvey } from '../interfaces/ITrackSurveyList';
import { ISurvey } from '../interfaces/ISurveyList';

export interface ISaveSurveysFormProps {
    onClose: () => void;
    isOpen: boolean;
    getTrackSurveys?: () => Promise<ITrackSurvey[] | undefined>;
    onSubmit: (values: {
        event: EventSurvey,
        trackSurvey: ITrackSurvey,
        sendMethod: EnumSurveySendMethod
    }) => Promise<any>
    onUpdate: (values: {
        id: string;
        event: EventSurvey,
        trackSurvey: ITrackSurvey,
        sendMethod: EnumSurveySendMethod
    }) => Promise<any>

    getDefaultValue?: (id: string) => Promise<{
        id: string,
        event: EventSurvey,
        sendMethod: EnumSurveySendMethod
        trackSurvey: ITrackSurvey
    } | undefined>

    defaultId?: string
    surveyList?: ISurvey[]
}

enum EnumStep {
    SELECT_EVENT,
    SELECT_WIDGET,
    SUCCESS_SAVE
}

const events = [
    {
        type: EnumSurveyEvent.ON_PAYMENT_APP,
    },
    {
        type: EnumSurveyEvent.ON_CLOSE_ACCOUNT,
    },
    {
        type: EnumSurveyEvent.ON_ADD_PROMOTER_LIST,
    }
]

const SaveSurveysForm: FC<ISaveSurveysFormProps> = ({ onClose, getTrackSurveys, onUpdate, onSubmit, getDefaultValue, surveyList, defaultId, isOpen }) => {
    const [index, setIndex] = useState<EnumStep>(EnumStep.SELECT_EVENT);
    const [selectedSendEvent, setSelectedSendEvent] = useState<EventSurvey | null>(null);
    const [selectedTrackSurvey, setSelectedTrackSurvey] = useState<ITrackSurvey | null>(null);
    const [trackSurveys, setTrackSurveys] = useState<ITrackSurvey[]>();
    const [isLoading, setIsLoading] = useState(false);
    const { showLoading, hideLoading } = useUi();

    const [selectedSendMethod, setSelectedSendMethod] = useState<EnumSurveySendMethod | null>(null)

    const [defaultValue, setDefaultValue] = useState<{
        id: string,
        event: EventSurvey,
        sendMethod: EnumSurveySendMethod
        trackSurvey: ITrackSurvey
    }>();

    const getSurveysHandle = useCallback(async () => {

        try {
            setIsLoading(true);
            let defaultValuePromise;
            let TrackSurveysPromise;
            if (defaultId && getDefaultValue) {
                defaultValuePromise = getDefaultValue(defaultId)
            }
            if (getTrackSurveys) {
                TrackSurveysPromise = getTrackSurveys();
            }
            const [defaultValue, TrackSurveys] = await Promise.all([defaultValuePromise, TrackSurveysPromise])

            setTrackSurveys(TrackSurveys);
            if (defaultValue) {
                const _event = events.find(item => item.type === defaultValue.event.type) ?? null;
                setDefaultValue(defaultValue);
                setSelectedTrackSurvey(TrackSurveys?.find(item => item.id === defaultValue.trackSurvey.id) ?? null);
                setSelectedSendMethod(defaultValue.sendMethod ?? null)
                setSelectedSendEvent(_event);
            }
        } finally {
            setIsLoading(false);
        }
    }, [getDefaultValue, getTrackSurveys, defaultId]);

    useEffect(() => {
        if(isOpen){
            getSurveysHandle();
        }
    }, [getSurveysHandle, isOpen])

    const onClickBack = useCallback(() => {
        if (index === EnumStep.SELECT_EVENT) {
            onClose();
        } else {
            setIndex(prev => prev - 1)
        }
    }, [index, onClose]);

    const onClickConclude = useCallback(() => {
        onClose();
    }, [onClose]);

    const onClickSubmit = useCallback(async () => {

        if (selectedSendEvent && selectedTrackSurvey && selectedSendMethod) {
            try {
                showLoading();
                await onSubmit({ event: selectedSendEvent, trackSurvey: { ...selectedTrackSurvey }, sendMethod: selectedSendMethod })
                setIndex(EnumStep.SUCCESS_SAVE)
            } finally {
                hideLoading();
            }
        }

    }, [hideLoading, onSubmit, selectedSendEvent, selectedSendMethod, selectedTrackSurvey, showLoading]);

    const onClickUpdate = useCallback(async () => {

        if (selectedSendEvent && selectedTrackSurvey && defaultValue && selectedSendMethod) {
            try {
                showLoading();
                await onUpdate({ id: defaultValue.id, event: selectedSendEvent, trackSurvey: { ...selectedTrackSurvey }, sendMethod: selectedSendMethod })
                setIndex(EnumStep.SUCCESS_SAVE)
            } finally {
                hideLoading();
            }
        }
    }, [defaultValue, hideLoading, onUpdate, selectedSendEvent, selectedSendMethod, selectedTrackSurvey, showLoading]);

    const validated = useMemo(() => (selectedSendEvent && selectedTrackSurvey), [selectedSendEvent, selectedTrackSurvey])

    const disabledEvent = useCallback(
        (eventType: EnumSurveyEvent) => {
            return !!(surveyList?.find(item => item.sendEventType === eventType) || defaultValue)
        }, [defaultValue, surveyList],
    )

    return (
        <div id={styles.SaveSurveysForm}>
            <div className={styles.header}>
                <h1>{defaultId ? "Editar Pesquisa" : "Adicionar Pesquisa"}</h1>
                <IconButton onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </div>
            <div className={styles.content}>
                {index !== EnumStep.SUCCESS_SAVE ? <>

                    <div>
                        <h4>Selecione quais tipos de pesquisa você deseja e vincule com as que você criou no Track.co</h4>
                        {
                            !!events.length ? events.map((item, index) =>
                                <div key={index} style={{ marginBottom: 8, opacity: !!disabledEvent(item.type) || isLoading ? 0.5 : 1 }}>
                                    <Switch
                                        disabled={!!disabledEvent(item.type) || isLoading}
                                        checked={item.type === selectedSendEvent?.type}
                                        onChange={(ev, checked) => { setSelectedSendEvent(item) }}
                                    />
                                    {getName(item.type)}
                                </div>
                            )
                                :
                                <div>Nenhum evento cadastrado</div>
                        }
                    </div>
                    {/* } */}
                    {/* {index === EnumStep.SELECT_WIDGET && */}
                    <div>
                        <h3>Pesquisa</h3>
                        <div className={styles.autoCompleteContainer}>
                            {
                                isLoading ?
                                    <div className={styles.loading}>

                                        <Skeleton variant="rect" height={60}></Skeleton>
                                        <Skeleton variant="rect" height={30}></Skeleton>
                                        <Skeleton variant="rect" height={24}></Skeleton>
                                        <Skeleton variant="rect" height={24}></Skeleton>
                                        <Skeleton variant="rect" height={24}></Skeleton>

                                    </div>
                                    :
                                    <>
                                        {
                                            !trackSurveys?.length ?
                                                <div className={styles.alertTrackSurveyNotFound}><Icon>error</Icon>Você primeiro precisa criar uma pesquisa em TRACK.CO</div>
                                                :
                                                <Autocomplete
                                                    value={selectedTrackSurvey}
                                                    renderInput={(params) => <TextField
                                                        label="Pesquisa"
                                                        variant='outlined' {...params}
                                                    />}
                                                    options={trackSurveys}
                                                    onChange={(ev, value) => setSelectedTrackSurvey(value)}
                                                    getOptionLabel={(option) => option.name}
                                                />


                                        }
                                        <h3>Forma de Envio</h3>
                                        <FormControl>
                                            <RadioGroup
                                                defaultValue={EnumSurveySendMethod.PUSH_IN_APP}
                                                value={selectedSendMethod?.toString()}
                                                onChange={(_, value: unknown) => setSelectedSendMethod(value as EnumSurveySendMethod)}
                                            >
                                                <FormControlLabel value={EnumSurveySendMethod.PUSH_IN_APP.toString()} control={<Radio />} label="Push no Aplicativo" />
                                                <FormControlLabel value={EnumSurveySendMethod.SMS.toString()} control={<Radio />} label="SMS com link" />
                                                <FormControlLabel value={EnumSurveySendMethod.EMAIL.toString()} control={<Radio />} label="E-Mail" />
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                            }
                        </div>

                    </div>
                    {/* } */}
                </>
                    :
                    <div>
                        Enviado com sucesso!
                    </div>}
            </div>
            <div className={styles.buttons}>
                {(index !== EnumStep.SUCCESS_SAVE) && <Button onClick={onClickBack} variant='outlined' color={'primary'}>Voltar</Button>}
                {/* {index === EnumStep.SELECT_EVENT && <Button disabled={!selectedEvent} onClick={onClickNext} variant='contained' color={'primary'}>Continuar</Button>} */}
                {index !== EnumStep.SUCCESS_SAVE && !defaultValue && <Button disabled={!validated} onClick={onClickSubmit} variant='contained' color={'primary'}>Enviar</Button>}
                {index !== EnumStep.SUCCESS_SAVE && !!defaultValue && <Button disabled={!validated} onClick={onClickUpdate} variant='contained' color={'primary'}>Salvar</Button>}
                {index === EnumStep.SUCCESS_SAVE && <Button disabled={!validated} onClick={onClickConclude} variant='contained' color={'primary'}>Concluir</Button>}
            </div>
        </div >
    );
};

export default SaveSurveysForm;