import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { BreadcrumbsEnum } from 'components/breadcumbs/IBreadcumbsRouter';
import GetEventsDetailsUseCase from 'modules/events/application/useCases/events/GetEventsDetailsUseCase';
import listTicketUseCase from 'modules/events/application/useCases/events/tickets/ListTicketUseCase';
import { IEvents } from 'modules/events/domain/models/IEvents';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventsApi } from 'services/api/events/events/EventsApi';
import TicketsApi from 'services/api/events/tickets/TicketsApi';
import { ITicketList } from './components/tickets/components/ticketForm/ITicketForm';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

const ticketService = TicketsApi();

const UseManageEventPage = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<IEvents>();
  const [tickets, setTickets] = useState<ITicketList>({ totalRecords: 0, records: [] });
  const [refreshDiscountList, setRefreshDiscountList] = useState<boolean>(false);
  const { updateRouters } = useBreadcumbs();
  const { currentLocal } = useLocal();

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Evento/Caixa', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.GERENCIA, title: event?.name ?? "Gerenciar", label: event?.name ? "Gerenciar" : '', url: `/private/event/eventos/gerenciar/${eventId}` },
      { title: event?.name ? "Gerenciar" : '', label: event?.name ? "Gerenciar" : '' },
    ])
  }, [event?.name, eventId, updateRouters])

  const resetRefreshDiscountList = useCallback(() => {
    setRefreshDiscountList(false);
  }, [])

  const getTickets = useCallback(async () => {
    try {
      const result = await listTicketUseCase(ticketService, eventId);
      setTickets(result);
    } catch(e) {
      console.log(e);
     }
  }, [eventId]);

  const getEvent = useCallback(async () => {
    const eventsService = EventsApi();
    try {
      if (!currentLocal) return;
      
      const result = await GetEventsDetailsUseCase(eventsService, eventId, currentLocal?.id);
      getTickets();
      setEvent(result);
    } catch(e) {
      console.log(e);
     }
  }, [eventId, getTickets, currentLocal]);

  useEffect(() => {
    if(eventId){
      getEvent();
    }
  }, [eventId, getEvent]);

  useEffect(() => {
    setRefreshDiscountList(true);
  }, [event?.products]);


  return {
    event,
    tickets,
    refreshDiscountList,
    getEvent,
    resetRefreshDiscountList
  };
};

export default UseManageEventPage;
