import { RadioGroup, FormControlLabel } from "@material-ui/core";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Radio from "components/ui/radio/Radio";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { IBaseList } from "../../../../interfaces/IEventGuestsListsPage";
import { UseDiscountLimit } from "../../IGuestListForm";
import { IDiscountsFormErrors } from "../../validation/IGuestListFormErros";
import styles from "./DiscountsForm.module.scss";
import { Switch } from "@mui/material";

interface IDiscountsFormProps {
  discountLimit?: number;
  discountList: IBaseList[];
  selectedDiscountList?: IBaseList;
  handleDiscountList: (discountList: IBaseList) => void;
  errors: IDiscountsFormErrors;
  handleChangeDiscountLimit: (value: string) => void;
  useDiscountLimit: boolean;
  handleChangeUseDiscountLimit: (useDiscountLimit: boolean) => void;
  validUntil: string | undefined;
  changeValidDateHandle: (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  enableDiscountExpiration: boolean;
  handleChangeEnableDiscountExpiration: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const DiscountsForm: FC<IDiscountsFormProps> = ({
  discountLimit,
  discountList,
  selectedDiscountList,
  handleDiscountList,
  errors,
  handleChangeDiscountLimit,
  useDiscountLimit,
  handleChangeUseDiscountLimit,
  validUntil,
  changeValidDateHandle,
  handleChangeEnableDiscountExpiration,
  enableDiscountExpiration
}) => {
  return (
    <form id={styles.DiscountsForm}>
      <FormItemContainer label="Lista de descontos">
        <AutoComplete
          variant="outlined"
          placeholder="Selecione a lista de descontos"
          className={styles.baseList}
          options={discountList}
          value={selectedDiscountList}
          getOptionLabel={(option) => option.name}
          onChange={(_evt, item) => handleDiscountList(item as IBaseList)}
        />
      </FormItemContainer>
      {/* <FormItemContainer label="Limitar utilização de desconto?">
        <RadioGroup
          value={useDiscountLimit}
          row
          onChange={(_, use) => handleChangeUseDiscountLimit(use === "true" ? true : false)}
        >
          <FormControlLabel value={false} control={<Radio color="primary" />} label="Não" />
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Sim" />
        </RadioGroup>
      </FormItemContainer> */}
      {true && (
        // {useDiscountLimit && (
        <FormItemContainer label="Limite de desconto">
          <Input
            variant="outlined"
            fullWidth={false}
            placeholder="Valor"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 50,
            }}
            value={moneyMaskNumber(discountLimit)}
            onChange={(ev) => handleChangeDiscountLimit(ev.target.value)}
            error={!!errors.discountLimit}
            helperText={errors.discountLimit}
          />
        </FormItemContainer>
      )}
      <Switch color='secondary' checked={enableDiscountExpiration} onChange={handleChangeEnableDiscountExpiration} /> Expiração de desconto

      {
        enableDiscountExpiration &&
        <FormItemContainer label={"Ativo(s) até"}>
          <Input
            error={!!errors.validUntil}
            helperText={errors.validUntil}
            variant="outlined"           
            type={"datetime-local"}
            name={"validUntil"}
            value={validUntil}
            onChange={changeValidDateHandle}
          />
        </FormItemContainer>
      }
    </form>
  );
};
