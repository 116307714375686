import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { UseSidesheetNovidades } from "./UseSidesheetNovidades";
import { ISidesheetFormItem } from "./interfaces/ISidesheetForm";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Input from "components/ui/input/Input";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import styles from "./SidesheetNovidades.module.scss";
import { ChangeEvent, useEffect, useRef } from "react";
import { PhotoCamera } from "@mui/icons-material";
import UseDimension from "components/dimension/UseDimension";

const initialValues = {
  id: "",
  Title: "",
  Description: "",
  Category: "",
  Link: "",
  img: "",
};
interface SideshetProps {
  setOpenSidesheet: React.Dispatch<React.SetStateAction<string | null>>;
  openSidesheet: string | null;
  onSubmit: (values: ISidesheetFormItem) => void;
  objEdit: ISidesheetFormItem | undefined;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const SidesheetNovidades = ({
  openSidesheet,
  setOpenSidesheet,
  onSubmit,
  objEdit,
  step,
  setStep,
}: SideshetProps) => {
  const {
    errors,
    values,
    onChangeHandle,
    onSubmitHandle,
    setValues,
    setErrors,
  } = UseSidesheetNovidades(onSubmit);

  const { dimensions } = UseDimension();

  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (objEdit) {
      setValues({
        id: objEdit.id,
        Title: objEdit.Title,
        Description: objEdit.Description,
        Category: objEdit.Category,
        Link: objEdit.Link,
        img: objEdit.img,
      });
    }
  }, [objEdit]);

  const onClick = () => {
    refInput.current?.click();
  };

  const fileToBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const onChangeHandleImg = async (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;
    // eslint-disable-next-line prefer-const
    let images: string[] = [];
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const _file = files?.item(i);

        const base64 = _file && (await fileToBase64(_file));
        images.push(String(base64));
      }
    }
    onChangeHandle(ev.target.name, images[0]);
  };

  return (
    <Sidesheet
      open={openSidesheet}
      onClose={() => {
        setOpenSidesheet(null);
        setStep(1);
        setValues(initialValues);
        setErrors({});
      }}
      title={
        <h1>
          {openSidesheet === "adicionar" ? "Nova" : "Editar"} <b> novidade</b>
        </h1>
      }
      content={
        <div>
          <div className={styles.contentImg}>
            <input
              name="img"
              ref={refInput}
              id="input-file"
              accept=".jpg, .jpeg, .png"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeHandleImg}
            />
            {values.img ? (
              <div className={styles.boxImg} onClick={onClick}>
                <img src={values.img} alt="upload_img" />
              </div>
            ) : (
              <div
                className={
                  errors.img ? styles.uploadImgError : styles.uploadImg
                }
                onClick={onClick}
              >
                <PhotoCamera />
                <p>Adicionar foto para a novidade</p>
              </div>
            )}
            <ul>
              <li>
                Envie uma imagem nos formatos, PNG ou JPG com o tamanho mínimo
                de 300x300px e máximo 1080x1080px.
              </li>
              <li>A imagem será usada na página do seu evento.</li>
            </ul>
          </div>
          <p className={styles.errorImg}>{errors.img}</p>
          <FormItemContainer label={"Título"} required>
            <Input
              name={"Title"}
              value={values.Title}
              onChange={(ev) => {
                onChangeHandle(ev.target.name, ev.target.value);
              }}
              required
              variant="outlined"
              error={!!errors.Title}
              helperText={errors.Title}
            />
          </FormItemContainer>
          <FormItemContainer label={"Descrição"} required>
            <InputTextArea
              name={"Description"}
              value={values.Description}
              variant="outlined"
              rows={4}
              multiline
              required
              onChange={(ev) => {
                onChangeHandle(ev.target.name, ev.target.value);
              }}
              error={!!errors.Description}
              helperText={errors.Description}
              placeholder="Insira aqui seu texto..."
            />
            <span className={styles.caracteres}>Max.: 300 caracteres</span>
          </FormItemContainer>
          <FormItemContainer label={"Categoria"} required>
            <Input
              name={"Category"}
              value={values.Category}
              onChange={(ev) => {
                onChangeHandle(ev.target.name, ev.target.value);
              }}
              required
              variant="outlined"
              error={!!errors.Category}
              helperText={errors.Category}
              placeholder="Insira aqui seu texto..."
            />
          </FormItemContainer>
          <FormItemContainer label={"Link"} required>
            <Input
              name={"Link"}
              value={values.Link}
              onChange={(ev) => {
                onChangeHandle(ev.target.name, ev.target.value);
              }}
              required
              variant="outlined"
              error={!!errors.Link}
              helperText={errors.Link}
              placeholder="https://"
            />
          </FormItemContainer>
        </div>
      }
      cancelButton
      continueButton={"Concluir"}
      handleContinueButton={onSubmitHandle}
      currentStep={step}
      totalSteps={2}
      notTotalHeight={dimensions.height < 660}
      feedback={
        <SidesheetFeedback text="Novidade publicada com sucesso" success />
      }
    />
  );
};

export default SidesheetNovidades;
