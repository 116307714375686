import { Autocomplete, TextField } from "@mui/material";
import styles from "../menuPai/MenuPai.module.scss";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";
import { IPostCreateMenuPayload, MenuTypeEnum } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";
import UseSelectParentId from "../../../hooks/UseSelectParentId";
import { useEffect, useState } from "react";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";
import { removeAccentsAndSpace } from "services/utils/String";
import { Switch } from "@material-ui/core";

interface IMenuFilhoProps {
    menuFilhoValues: IPostCreateMenuPayload;
    setMenuFilhoValues: React.Dispatch<React.SetStateAction<IPostCreateMenuPayload>>;
    errors: string | undefined;
    setErrors: React.Dispatch<React.SetStateAction<string | undefined>>;
    menuFilhoEditItem?: IPutEditMenuPayload;
    defaultPlans?: IPlans[];
}

const MenuFilho = ({ menuFilhoValues, setMenuFilhoValues, errors, setErrors, menuFilhoEditItem, defaultPlans }: IMenuFilhoProps) => {
    const [role, setRole] = useState("VisualizarMenu");

    const { parentId, parentTitle } = UseSelectParentId();

    useEffect(() => {
        if (parentId) {
            setMenuFilhoValues({...menuFilhoValues, parentId: parentId })
        }
    }, [parentId, setMenuFilhoValues]);

    useEffect(() => {
        if (menuFilhoEditItem?.title) {
            setMenuFilhoValues({
                title: menuFilhoEditItem.title,
                description: menuFilhoEditItem.description,
                role: menuFilhoEditItem.role,
                icon: menuFilhoEditItem.icon,
                route: menuFilhoEditItem.route,
                menuConfigurationId: menuFilhoEditItem.menuConfigurationId,
                positionType: menuFilhoEditItem.positionType,
                parentId: menuFilhoEditItem.parentId,
                planIds: menuFilhoEditItem.planIds,
                type: menuFilhoEditItem.type,
            });
            setRole(menuFilhoEditItem.role);
        }
    }, [menuFilhoEditItem, setMenuFilhoValues]);

    return (
        <div id={styles.MenuFilho}>
            <div className={styles.input}>
                <label htmlFor="menu-pai">
                    Menu pai
                </label>
                <TextField
                    name="menu-pai"
                    variant="outlined"
                    size="small"
                    value={parentTitle}
                    disabled
                    className={styles.disabled}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="name" className={styles.required}>
                    Nome
                </label>
                <TextField
                    name="name"
                    variant="outlined"
                    size="small"
                    value={menuFilhoValues.title}
                    onChange={(ev) => {
                        setMenuFilhoValues({
                            ...menuFilhoValues,
                            title: ev.target.value,
                            role: `VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`,
                            description: `Visualizar Menu ${ev.target.value}`,
                        });
                        setRole(`VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`);
                        setErrors(undefined);
                    }}
                    error={!!errors}
                    helperText={errors}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="permission">
                    Permissão
                </label>
                <TextField
                    name="permission"
                    variant="outlined"
                    size="small"
                    value={role}
                    disabled
                    className={styles.disabled}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="permission-name">
                    Nome da permissão
                </label>
                <TextField
                    name="permission-name"
                    variant="outlined"
                    size="small"
                    value={menuFilhoValues.description}
                    onChange={(ev) => {
                        setMenuFilhoValues({...menuFilhoValues, description: ev.target.value });
                    }}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="plans">
                    Planos
                </label>
                <Autocomplete
                    options={defaultPlans ?? []}
                    getOptionLabel={option => option.name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Selecione os planos"
                            InputProps={{
                                style: { padding: '0 20px 0 10px' },
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const plansIds = values.map((it) => it.id);
                        setMenuFilhoValues({...menuFilhoValues, planIds: plansIds});
                    }}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="url">
                    URL
                </label>
                <TextField
                    name="url"
                    variant="outlined"
                    size="small"
                    value={menuFilhoValues.route}
                    onChange={(ev) => {
                        setMenuFilhoValues({...menuFilhoValues, route: ev.target.value })
                    }}
                />
            </div>
            <div className={`${styles.input} ${styles.switch}`}>
                <Switch
                    checked={menuFilhoValues.type === MenuTypeEnum.PREMIUM}
                    onChange={(_, value: boolean) => setMenuFilhoValues({ ...menuFilhoValues, type: value ? MenuTypeEnum.PREMIUM : MenuTypeEnum.STANDARD })}
                    id="display-crown-icon" />
                <label htmlFor="display-crown-icon">Exibir ícone de coroa</label>
            </div>   
        </div>
    )
}

export default MenuFilho;