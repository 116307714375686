import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import RechargeCashlessVoucherUseCase from 'modules/events/application/useCases/voucher/RechargeCashlessVoucherUseCase';
import { ICashlessConsummationForm } from 'modules/events/presentation/components/cashlessConsummationForm/interfaces/ICashlessConsummationForm';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useState } from 'react';
import { CashlessVoucherApi } from 'services/api/events/voucher/CashlessVoucherApi';

const UseCashlessConsummationCard = (refreshCashlessList: () => Promise<void>) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { currentLocal } = useLocal();
  const cashlessVoucherService = CashlessVoucherApi();
  const { toast, showLoading, hideLoading } = useUi();

  const closeEditModal = useCallback(() => {
    setOpenEdit(false);
  }, []);

  const openEditModal = useCallback(() => {
    setOpenEdit(true);
  }, []);

  const handleRechargeCashlessConsummation = useCallback(
    async (cashlessValues: ICashlessConsummationForm) => {
      if(currentLocal?.id){
        try {
          showLoading();
          await RechargeCashlessVoucherUseCase(
            cashlessVoucherService,
            {...cashlessValues, localId: currentLocal.id, paymentMethod: cashlessValues.paymentType},
          );
          refreshCashlessList();
          closeEditModal();
          toast('Recarga efetuada com sucesso!', 'success');
        } catch (error) {
        } finally {
          hideLoading();
        }
      }
    },
    [],
  );

  return {
    openEdit,
    closeEditModal,
    openEditModal,
    handleRechargeCashlessConsummation
  };
};

export default UseCashlessConsummationCard;
