import { IAutomationFormStep } from "modules/automation/domain/interfaces/IAutomationFormStep"
import { useCallback, useEffect, useState } from "react"
import { IAutomationForm } from "../../interfaces/IForm";
import { AutomationType } from "modules/automation/domain/interfaces/AutomationType";
import { AutomationTriggerType } from "modules/automation/domain/interfaces/AutomationTriggerType";
import { AutomationCampaignTemplate } from "modules/automation/domain/interfaces/AutomationCampaignTemplate";
import { ISurveyItem } from "../../interfaces/ISurvey";
import { GetSurveysUseCase } from "modules/automation/application/useCases/GetSurveysUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import AutomationService from "services/api/automation/AutomationService";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { CreateAutomationUseCase } from "modules/automation/application/useCases/CreateAutomationUseCase";
import { ICreateAutomationRequest } from "modules/automation/domain/dtos/ICreateAutomationRequest";
import { SyncWithTrackUseCase } from "modules/automation/application/useCases/SyncWithTrackUseCase";
import { IAutomationItem } from "../../interfaces/IAutomation";
import { EditAutomationUseCase } from "modules/automation/application/useCases/EditAutomationUseCase";

const service = AutomationService()

export const UseAutomationForm = (closeStepForm: () => void, getData: (params?: { pagination?: { page?: number, pageSize?: number } },) => void, selectedItem?: IAutomationItem) => {
    const [formStep, setFormStep] = useState<IAutomationFormStep>(IAutomationFormStep.DETAILS);
    const [surveys, setSurveys] = useState<ISurveyItem[]>([])
    const [triggerType, setTriggerType] = useState<AutomationTriggerType>()
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<IAutomationForm>({
        automationType: AutomationType.CAMPAIGN,
        triggerType: AutomationTriggerType.ON_CLOSER_CASHIER,
        name: '',
        description: '',
        payload: {
            surveyId: undefined,
            campaignTemplate: AutomationCampaignTemplate.survey,
            channel: undefined
        }
    });

    const { currentLocal } = useLocal();
    const { toast } = useUi();

    const onChangeFormStep = (step: IAutomationFormStep) => {
        setFormStep(step);
    }

    useEffect(() => {
        if (selectedItem) {
            setForm({
                id: selectedItem?.id,
                automationType: selectedItem?.type,
                triggerType: selectedItem?.triggerType,
                name: selectedItem?.name,
                description: selectedItem?.description,
                payload: {
                    surveyId: selectedItem?.automationData.payload.surveyId,
                    campaignTemplate: selectedItem?.automationData.payload.campaignTemplate,
                    channel: selectedItem?.automationData.payload.channel,
                }
            })
        }
    }, [selectedItem])

    useEffect(() => {
        SyncWithTrackUseCase(service, currentLocal!.id)
    }, [currentLocal])

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                const response = await GetSurveysUseCase(service, currentLocal.id);
                setSurveys(response.data)
            }
        })()
    }, [currentLocal])

    const handleBackButton = () => {
        if (formStep === IAutomationFormStep.DETAILS) {
            closeStepForm()
        } else if (formStep === IAutomationFormStep.TRIGGER) {
            setFormStep(IAutomationFormStep.DETAILS)
        } else if (formStep === IAutomationFormStep.TOOL) {
            setFormStep(IAutomationFormStep.TRIGGER)
        } else {
            setFormStep(IAutomationFormStep.TOOL)
        }
    }

    const submit = async () => {
        try {
            setLoading(true);
            if (!form.payload.surveyId) {
                toast('Selecione uma pesquisa.', 'error');
                return;
            }
            if (!form.payload.channel) {
                toast('Selecione uma forma de envio.', 'error');
                return;
            }

            if (form.id)
                await EditAutomationUseCase(service, currentLocal!.id, form as ICreateAutomationRequest);
            else
                await CreateAutomationUseCase(service, currentLocal!.id, form as ICreateAutomationRequest);

            setFormStep(IAutomationFormStep.SUCCESS)
            getData()
        } catch {
            toast('Ocorreu um erro ao salvar a pesquisa. Tente novamente', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleNextButtonClick = () => {
        if (formStep === IAutomationFormStep.SUCCESS) {
            closeStepForm()
            return;
        }

        if (formStep === IAutomationFormStep.DETAILS) {
            setFormStep(IAutomationFormStep.TRIGGER)
            return;
        }

        if (formStep === IAutomationFormStep.TRIGGER) {
            if (form.id)
                setFormStep(IAutomationFormStep.TRACK)
            else
                setFormStep(IAutomationFormStep.TOOL)
                
            return;
        }

        submit();
    }

    return {
        formStep,
        form,
        surveys,
        loading,
        onChangeFormStep,
        handleBackButton,
        onChangeForm: setForm,
        handleNextButtonClick
    }
}