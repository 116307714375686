import { IGetPermutationAccountParams } from "modules/permutation/domain/dto/IGetPermutationAccountParams";
import { IPermutationAccountfilter } from "modules/permutation/presentation/components/permutationFilter/interfaces/IPermutationAccountfilter";


const ParseFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IPermutationAccountfilter) => {

  const request: IGetPermutationAccountParams = {

    placeId: localId,
    balanceMax: filter?.balanceMax,
    balanceMin: filter?.balanceMin,
    name: filter?.name,
    document: filter?.document,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterToRequestUseCase

