import { IPostConfirmAnticipateService } from "../interfaces/IContaDigitalApiService";
import { ConfirmAntecipateRequest } from "modules/contaDigital/models/dtos/ConfirmAntecipateRequest";

const PostConfirmAnticipate = async (
  accountService: IPostConfirmAnticipateService,
  body: ConfirmAntecipateRequest
): Promise<any> => {
  return await accountService.postConfirmAnticipate(body);
};

export default PostConfirmAnticipate;
