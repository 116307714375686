import { useCallback, useEffect, useState } from "react";
import styles from "./TransactionsFilter.module.scss";
import { IGetTransactionsParams } from "modules/transactionReport/domain/dto/IGetTransactionsParams";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { DateRangePicker } from "modules/crm/presentation/components/crmFilterForm/components/dateRangerPicker/DateRangePicker";
import { format } from "date-fns";
import { InsertInvitationOutlined, VerticalAlignBottomOutlined } from "@material-ui/icons";
import { MenuItem, Select, TextField } from "@mui/material";
import { UseTransactionReportPage } from "../../hooks/UseTransactionReportPage";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface ITransactionsFilterProps {
    filterParams: IGetTransactionsParams;
    setFilterParams: React.Dispatch<React.SetStateAction<IGetTransactionsParams>>;
}

const TransactionsFilter = ({ filterParams, setFilterParams }: ITransactionsFilterProps) => {
    const [filter, setFilter] = useState<IGetTransactionsParams>(filterParams);

    const {
        isLoading,
        equipments,
        postTransactionsExport,
        getTransactionsReport,
    } = UseTransactionReportPage();

    const { dimensions } = UseDimension();
    const { toast } = useUi();

    useEffect(() => {
        getTransactionsReport();
    }, [getTransactionsReport]);

    const onChangeDate = useCallback(
        (_values?: [Date, Date] | [Date]) => {
          if (_values) {
            const _startDate = _values[0];
            const _endDate = _values[1] ?? undefined;
                
            setFilter({
                ...filter,
                StartDate: _startDate.toISOString(),
                EndDate: _endDate?.toISOString() ?? "",
            });
          } else {
            setFilter({
                ...filter,
                StartDate: new Date().toISOString(),
                EndDate: new Date().toISOString(),
            });
          }
        },
        [filter]
    );

    const onChangeHandle = useCallback((name: string, value: string | number) => {      
        setFilter({...filter, [name]: value});
    }, [filter]);

    const handleGrossValue = (value: string) => {
        const onlyNumber = value.replace(/[^\d,]/g, '').replace(",", ".");
        const parts = onlyNumber.split('.');
        parts[0] = parts[0].replace(".", "");
        return parts.join('.');
    };

    const onSubmitHandle = useCallback(() => {
        if (filter.GrossValue) {
            return setFilterParams({ ...filter, GrossValue: handleGrossValue(filter.GrossValue) })
        }
        setFilterParams(filter);
    }, [filter, setFilterParams]);

    const handleExportTransactions = useCallback(async () => {
        const res = await postTransactionsExport({
            LocalClienteId: "",
            Orientation: filter.Orientation,
            OrderBy: filter.OrderBy,
            EndDate: filter.EndDate,
            StartDate: filter.StartDate,
            CardNumber: filter.CardNumber,
            PaymentType: filter.PaymentType,
            Equipment: filter.Equipment,
            GrossValue: filter.GrossValue ? handleGrossValue(filter.GrossValue) : "",
        });
        if (res === 200) {
            toast("O relatório foi enviado para o e-mail cadastrado em nosso portal.", "success");
        } else {
            toast("Ocorreu um erro. Tente novamente.", "error");
        }
    }, [filter, postTransactionsExport, toast]);

    return (
        <div id={styles.TransactionsFilter}>
            <div className={styles.filter}>
                <div className={styles.inputContainer} style={{ minWidth: "245px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <DateRangePicker
                            label={"Período "}
                            placeholder={"00/00/0000 - 00/00/0000"}
                            onChange={onChangeDate}
                            selectRange
                            value={
                                filter.StartDate && filter.EndDate
                                  ? `${format(new Date(filter.StartDate), "dd/MM/yyyy")} - ${format(new Date(filter.EndDate), "dd/MM/yyyy")}`
                                  : ""
                            }
                            valueCalendar={[
                                filter.StartDate ? new Date(filter.StartDate) : null,
                                filter.EndDate ? new Date(filter.EndDate) : null,
                            ]}
                            required
                            inputProps={{ endAdornment: <InsertInvitationOutlined /> }}
                            notShowCloseIcon
                        />
                    </LocalizationProvider>
                </div>
                <div className={styles.inputContainer}>
                    <label>Equipamento</label>
                    <Select
                        size="small"
                        name="Equipment"
                        value={filterParams.Equipment}
                        onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                    >
                        <MenuItem value={""} style={{ height: "34px" }}/>
                        {equipments?.map((item) => (
                            <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div className={styles.inputContainer} style={{ maxWidth: dimensions.width <= 450 ? "100%" : "140px" }}>
                    <label>Final do cartão</label>
                    <TextField
                        size="small"
                        placeholder="4 últimos dígitos"
                        name="CardNumber"
                        value={filter.CardNumber}
                        onChange={(ev) => { 
                            if (ev.target.value.length === 5) return;
                            onChangeHandle(ev.target.name, ev.target.value)}} 
                        type="number"

                    />
                </div>
                <div className={styles.inputContainer}>
                    <label>Tipo de pagamento</label>
                    <Select
                        size="small"
                        name="PaymentType"
                        value={filterParams.PaymentType}
                        onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                    >
                        <MenuItem value={""} style={{ height: "34px" }}/>
                        <MenuItem value="1">Crédito</MenuItem>
                        <MenuItem value="2">Débito</MenuItem>
                    </Select>
                </div>
                <div className={styles.inputContainer} style={{ maxWidth: dimensions.width <= 450 ? "100%" : "140px" }}>
                    <label>Valor bruto</label>
                    <TextField
                        size="small"
                        placeholder="R$ 0,00"
                        name="GrossValue"
                        value={filter.GrossValue}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            const number = ev.target.value.replace(/[^\d,]/g, '');
                            const parts = number.split(',');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                            onChangeHandle(ev.target.name, `R$ ${parts.join(',')}`);
                        }}
                    />
                </div>
                <Button color="secondary" disabled={isLoading} onClick={onSubmitHandle}>Buscar</Button>
                {dimensions.width < 1000 && dimensions.width > 450 && (
                    <div className={styles.export} onClick={handleExportTransactions}>
                        <span>Exportar</span>
                        <VerticalAlignBottomOutlined />
                    </div>
                )}
            </div>
            {(dimensions.width >= 1000 || dimensions.width <= 450) && (
                <div className={styles.export} onClick={handleExportTransactions}>
                    <span>Exportar</span>
                    <VerticalAlignBottomOutlined />
                </div>
            )}
        </div>
    )
}

export default TransactionsFilter;