import React from 'react';
import styles from './List.module.scss';
import { MenuItem, Pagination, TextField } from '@mui/material';
import { IPagination } from './List';

interface IListPaginationProps {
    pagination: IPagination;
    onChangePage: ((page: number, size: number) => void) | undefined;
}

export const ListPagination = ({
    pagination, onChangePage
}: IListPaginationProps) => {
    return (
        <div id={styles.conteinerPagination}>
            <div className={styles.boxMultiplePage}>
                <span>Exibir</span>
                <TextField
                    variant="outlined"
                    size="small"
                    select
                    value={pagination.pageSize}
                    onChange={(ev) => { onChangePage?.(pagination.page, Number(ev.target.value)); }}
                >
                    <MenuItem className={styles.textPage} value={10}>
                        10
                    </MenuItem>
                    <MenuItem className={styles.textPage} value={20}>
                        20
                    </MenuItem>
                    <MenuItem className={styles.textPage} value={50}>
                        50
                    </MenuItem>
                    <MenuItem className={styles.textPage} value={100}>
                        100
                    </MenuItem>
                </TextField>
            </div>
            {/* <select
                value={pagination.pageSize}
                className={styles.boxMultiplePage}
                onChange={(ev) => { onChangePage?.(pagination.page, Number(ev.target.value)); }}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
            </select> */}
            <Pagination
                className={styles.paginationNumber}
                variant="outlined"
                shape="rounded"
                count={pagination?.count}
                page={pagination?.page}
                onChange={(ev, page) => { onChangePage && onChangePage(page, pagination.pageSize); }}
                sx={{
                    ".MuiPaginationItem-root": {
                        borderColor: "#BDBDBD",
                        color: "#001537",
                    },
                    ".Mui-selected": {
                        fontWeight: "bold",
                        borderColor: "#001537",
                    },
                }}
            />
        </div>
    );
};
