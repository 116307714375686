import { IAutomationService } from "modules/automation/domain/interfaces/AutomationService";
import { api } from "../Api";
import { IGetAutomationResponse } from "modules/automation/domain/dtos/IGetAutomationsResponse";
import { IGetAutomationsRequest } from "modules/automation/domain/dtos/IGetAutomationsRequest";
import { ICreateAutomationRequest } from "modules/automation/domain/dtos/ICreateAutomationRequest";
import { IGetSurveysResponse } from "modules/automation/domain/dtos/IGetSurveysResponse";


const AutomationService = (): IAutomationService => {
  const baseUrl = "https://crm-api.meep.cloud/api";

  const getAutomations = async (ownerId: string, request: IGetAutomationsRequest): Promise<IGetAutomationResponse> => {
    const response = await api.get<IGetAutomationResponse>(`${baseUrl}/owner/${ownerId}/automations`, { params: request });
    return response.data;
  };

  const getSurveys = async (ownerId: string): Promise<IGetSurveysResponse> => {
    const response = await api.get<IGetSurveysResponse>(`${baseUrl}/customerSurveys/getList/${ownerId}`);
    return response.data;
  };

  const syncWithTrack = async (ownerId: string): Promise<void> => {
    await api.get(`${baseUrl}/customerSurveys/syncWithTrack/${ownerId}`, { params: { disableError: true }});
  };

  const enable = async (ownerId: string, automationId: string): Promise<void> => {
    await api.patch(`${baseUrl}/owner/${ownerId}/automations/${automationId}/enable`);
  };

  const disable = async (ownerId: string, automationId: string): Promise<void> => {
    await api.patch(`${baseUrl}/owner/${ownerId}/automations/${automationId}/disable`);
  };

  const createAutomation = async (ownerId: string, request: ICreateAutomationRequest): Promise<void> => {
    await api.post(`${baseUrl}/owner/${ownerId}/automations`, request);
  }

  const editAutomation = async (ownerId: string, request: ICreateAutomationRequest): Promise<void> => {
    await api.put(`${baseUrl}/owner/${ownerId}/automations/${request.id}`, request);
  }

  return {
    getAutomations,
    getSurveys,
    syncWithTrack,
    enable,
    disable,
    createAutomation,
    editAutomation,
  };
};

export default AutomationService;
