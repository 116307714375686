import Button from 'components/ui/Button/Button'
import { AddCircle } from '@material-ui/icons'
import React from 'react'
import { ServiceProductCard } from '../../components/serviceProductCard/ServiceProductCard'
import styles from './ServiceScheduleListPage.module.scss'
import { UseServiceScheduleListPage } from './UseServiceScheduleListPage'

export const ServiceScheduleListPage = () => {

    const { values, goAddProductList, getServicesList } = UseServiceScheduleListPage();

    return (
        <div id={styles.ServiceScheduleListPage}>
            <Button fullWidth={false} className={styles.addtionalService} onClick={goAddProductList}>
                Adicionar serviço <AddCircle />
            </Button>

            <div className={styles.containerServiceCard}>
                {
                    values?.map((item, index) => (

                        <ServiceProductCard key={index} value={item} refreshList={getServicesList} />

                    ))
                }
            </div>

        </div>
    )
}
