import Layout from "components/layout/presentation/Layout";
import React, { FC } from "react";
import { CashlessPage } from "./cashleesPage";

const Cashless: FC = () => {
  return (
    <Layout>
      <CashlessPage />
    </Layout>
  );
};
export default Cashless;
