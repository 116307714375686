import { KeyboardArrowLeftOutlined } from "@material-ui/icons";
import styles from "./SaasPaymentSuccessPage.module.scss"
import { useHistory } from "react-router-dom";

const SaasPaymentSuccessPage = () => {
    const history = useHistory();

    const navigate = () => {
        return history.push("/private/plans");
    }

    return (
        <div id={styles.SaasPaymentSuccessPage}>
            <div className={styles.backContainer} onClick={navigate}>
                <KeyboardArrowLeftOutlined className={styles.icon} />
                <span>Voltar</span>
            </div>
            <div className={styles.container}>
                {/* {isTrial 
                    ? (
                        <header>
                            <h1>Você chegou ao fim do teste grátis!</h1>
                            <p>Para continuar usando o Portal Meep, selecione seu plano e forma de pagamento:</p>
                        </header>
                    ) : (
                        <header>
                            <h1>Alterar o seu plano</h1>
                            <p>Selecione o plano que deseja alterar</p>
                        </header>
                    )
                } */}
            </div>
        </div>
    )
}

export default SaasPaymentSuccessPage;