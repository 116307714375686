import styles from '../Fees/FeesPage.module.scss';

const fees = [
    {
        quantity: "Quantidade",
        value: "Valor",
    },
    {
        quantity: "Até 5 licenças",
        value: "R$50,00 cada",
    },
    {
        quantity: "6 - 10 licenças",
        value: "R$40,00 cada",
    },
    {
        quantity: "11 - 15 licenças",
        value: "R$35,00 cada",
    },
    {
        quantity: "Acima de 16",
        value: "R$30,00 cada",
    },
]

export const CieloPosTable = () => {
    return (
        <div id={styles.CieloPosTable}>
            <div className={styles.header}>
                <img src="/assets/img/LogoMee.svg" alt="" />
            </div>
            <div className={styles.container}>
                <div>
                    <h2>Conheça nossa tabela de preços</h2>
                    <p>Esses são os valores para adquirir a licença de uso do nosso aplicativo na sua maquininha</p>

                    <div className={styles.main}>
                        <div className={styles.card}>
                            {
                                fees.map(x => (
                                    <div>
                                        <span>{x.quantity}</span>
                                        <span>{x.value}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}