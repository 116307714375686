import {
  DeleteOutline,
  EditOutlined,
  LaunchOutlined,
  LinkOutlined,
  QrCode,
} from "@mui/icons-material";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeletePaymentLinkUseCase from "modules/paymentLink/application/useCases/DeletePaymentLinkUseCase";
import DeleteValidation from "modules/permutation/presentation/pages/permutationAccountList/components/permutationAccountListContainer/deleteValidation/DeleteValidation";
import { FC, useCallback, useMemo, useState } from "react";
import PaymentLinkService from "services/api/paymentLink/PaymentLinkService";
import { moneyMaskNumber } from "services/utils/Money";
import { IGetPaymentLinkListParams } from "../../interfaces/IGetPaymentLinkListParams";
import { IPaymentLink } from "../../interfaces/IPaymentLink";
import {
  IPaymentLinkColumns,
  IPaymentLinkProperty,
} from "../../interfaces/IPaymentLinkColumns";
import styles from "./PaymentLinkItem.module.scss";
import { IconButton, Tooltip } from "@material-ui/core";
import { QrcodeItem } from "../qrcodeItem/QrcodeItem";

export interface IPaymentLinkItem {
  paymentLink: IPaymentLink;
  columns: IPaymentLinkColumns[];
  openSidesheet: (id?: string) => void;
  getPaymentLinkList: (params?: IGetPaymentLinkListParams) => Promise<void>;
}

const PaymentLinkItem: FC<IPaymentLinkItem> = ({
  paymentLink,
  columns,
  openSidesheet,
  getPaymentLinkList,
}) => {
  const { isMobile, toast } = useUi();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [openDrownQrcode, setOpenDrownQrcode] = useState(false);

  const handleDelete = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const deletePaymentLink = useCallback(async () => {
    try {
      setIsLoading(true);
      const service = PaymentLinkService();
      await DeletePaymentLinkUseCase(service, paymentLink.id);
      getPaymentLinkList({
        pagination: {
          page: 0,
          pageSize: 20,
        },
        sort: {
          orientation: "asc",
          type: "name",
        },
      });
      setStep(1);
    } finally {
      setIsLoading(false);
    }
  }, [paymentLink.id, getPaymentLinkList]);

  const openLinkNewTab = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_MEPAY_URL}/paymentlink/${paymentLink.id}`,
      "_blank"
    );
  }, [paymentLink.id]);

  const copyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_MEPAY_URL}/paymentlink/${paymentLink.id}`
    );
    toast("Link copiado para areá de transferencia", "success");
  }, [paymentLink.id, toast]);

  const expired = useMemo(
    () => new Date(paymentLink.expiration) < new Date(),
    [paymentLink.expiration]
  );

  const openDrowModalQrcode = useCallback(() => {
    setOpenDrownQrcode(true);
  }, []);

  const onCloseQrcode = useCallback(() => {
    setOpenDrownQrcode(false);
  }, []);

  const continueQrcode = useCallback(() => {
    if (step === 1) {
      onCloseQrcode();
    }
  }, [onCloseQrcode, step]);
 
  const handleContentQrcode = useCallback(() => {
    return <QrcodeItem item={paymentLink} />;
  }, [paymentLink]);

  const formatValues = useCallback(
    (property: IPaymentLinkProperty): string => {
      if (paymentLink.hasOwnProperty("id")) {
        switch (property) {
          case "expiration":
            return paymentLink?.expiration
              ? new Date(paymentLink.expiration).toLocaleDateString()
              : "-";
          case "price":
            return paymentLink?.price
              ? moneyMaskNumber(Number(paymentLink?.price))
              : "-";

          default:
            return String(paymentLink?.[property] ?? "");
        }
      }
      return "-";
    },
    [paymentLink]
  );

  const WebItem = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={
                colunm.style ?? {
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                }
              }
            >
              <span title={formatValues(colunm.property)}>
                {formatValues(colunm.property)}
              </span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  const MobileItem = useMemo(
    () => (
      <>
        <div className={styles.top}>
          <span>{paymentLink.name ?? ""}</span>
          <div className={styles.icon}>
            <Tooltip title={expired ? "Link expirado" : "Qr Code"}>
              <span>
                <IconButton
                  size="small"
                  onClick={openDrowModalQrcode}
                  disabled={expired}
                >
                  <QrCode />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={expired ? "Link expirado" : "Nova aba"}>
              <span>
                <IconButton
                  size="small"
                  onClick={openLinkNewTab}
                  disabled={expired}
                >
                  <LaunchOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={expired ? "Link expirado" : "Copiar link"}>
              <span>
                <IconButton
                  size="small"
                  onClick={copyLinkToClipboard}
                  disabled={expired}
                >
                  <LinkOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                size="small"
                onClick={() => openSidesheet(paymentLink.id)}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={styles.mid}>
          <span>Valor:</span>
          <span>{moneyMaskNumber(Number(paymentLink.price))}</span>
        </div>
        <div className={styles.footer}>
          <span>Expira em:</span>
          <span>{new Date(paymentLink.expiration).toLocaleDateString()}</span>
        </div>
      </>
    ),
    [
      paymentLink.name,
      paymentLink.price,
      paymentLink.expiration,
      paymentLink.id,
      expired,
      openDrowModalQrcode,
      openLinkNewTab,
      copyLinkToClipboard,
      handleDelete,
      openSidesheet,
    ]
  );

  return (
    <div id={styles.PaymentLinkItem}>
      {isMobile ? (
        <div className={styles.mobileContainer}>{MobileItem}</div>
      ) : (
        <div className={styles.webContainer}>
          {WebItem}
          <div className={styles.icon}>
          <Tooltip title={expired ? "Link expirado" : "Qr Code"}>
              <span>
                <IconButton
                  size="small"
                  onClick={openDrowModalQrcode}
                  disabled={expired}
                >
                 <QrCode />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={expired ? "Link expirado" : "Nova aba"}>
              <span>
                <IconButton
                  size="small"
                  onClick={openLinkNewTab}
                  disabled={expired}
                >
                  <LaunchOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={expired ? "Link expirado" : "Copiar link"}>
              <span>
                <IconButton
                  size="small"
                  onClick={copyLinkToClipboard}
                  disabled={expired}
                >
                  <LinkOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                size="small"
                onClick={() => openSidesheet(paymentLink.id)}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      <Sidesheet
        open={showDeleteModal}
        onClose={closeDeleteModal}
        isLoading={isLoading}
        currentStep={step}
        totalSteps={1}
        title={
          <h2>
            Excluir <b>link de pagamento</b>
          </h2>
        }
        content={
          <DeleteValidation
            text="Deseja realmente excluir o link de pagamento?"
            highlightText="Essa ação não poderá ser desfeita!"
          />
        }
        handleContinueButton={deletePaymentLink}
        notTotalHeight
        continueButton="Excluir"
        feedback={
          <SidesheetFeedback
            text={`Link de pagamento excluído com sucesso!`}
            success
          />
        }
      />
      <Sidesheet
        isLoading={isLoading}
        open={openDrownQrcode}
        onClose={onCloseQrcode}
        title={
          <h2>
            <b>QR Code</b>
          </h2>
        }
        content={handleContentQrcode()}
        currentStep={step}
        totalSteps={1}
        handleContinueButton={onCloseQrcode}
        continueButton={"Fechar"}
      />
    </div>
  );
};

export default PaymentLinkItem;
