export interface IGetHouseFlowListResponse {
    cashierOpenDate: Date,
    cardsResume: ICardsResumeResponse[],
    totalRecords: number,
    records: IRecordsResponse[]
}

export interface IRecordsResponse {
    cashless: boolean,
    lastConsumption: string,
    cardStatus: number,
    cardId: string,
    cardNumber: string,
    cardTag: string,
    customerName: string,
    customerPhone: string,
    customerEmail: string,
    customerCPF: string,
    cardOrders: number,
    cardTotalNet: number,
    cardTotalService: number,
    cardTotalDiscounts: number,
    cardIdleMinutes: number,
}

export interface ICardsResumeResponse {
    cardStatus: EnumCardStatus,
    total: number
}

export enum EnumCardStatus {
    INUSE = 1,
    IDLE,
    AVAILABLE
}