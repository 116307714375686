import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { IDeviceProfileService } from "../../../domain/interface/IDeviceProfileService";
import { IOperationItem } from "../../../presentation/components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue";

const GetOperationListUseCase = async (deviceProfileService: IDeviceProfileService, localId: string, systemIdentifier?: SystemIdentifierEnum): Promise<IOperationItem[]> => {
    const response = await deviceProfileService.getOperationListByLocalId(localId, systemIdentifier);
    return response.items
}

export default GetOperationListUseCase
