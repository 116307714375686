import React, { FC, useMemo } from 'react';
import styles from './ui.module.scss';
import { TextField, TextFieldProps } from '@mui/material';
// import { TextField, TextFieldProps } from '@material-ui/core';

//input



export interface InputLabelProps {
    flex?: number;
    maxWidth?: number | string;
    minWidth?: number | string;
    required?: boolean;
    gap?: number | string;
    styledSize?: boolean;
    noLabel?: boolean;
    counter?: boolean;
}
export const InputLabel: FC<InputLabelProps & TextFieldProps> = (props) => {

    var _propsTextField = useMemo(() => {
        var newValue = { ...props }
        delete newValue.maxWidth
        delete newValue.minWidth
        return newValue
    }, [props])
    return (
        <div className={styles.item} style={{ flex: props.flex, maxWidth: props.maxWidth, minWidth: props.minWidth ?? 100, gap: props.gap }}>
            {!props.noLabel && <label className={props.required ? styles.required : ""}>{props.label}</label>}
            <TextField
                {..._propsTextField}
                size='small'
                label={undefined}
                variant='outlined'
                style={{ ...props.style }}
                className={props.counter ? styles.counterInput : ""}
                sx={ props.styledSize
                    ? {
                        '& input': {
                            paddingTop: "14px",
                            height: "48px",
                            boxSizing: "border-box"
                        },
                    }
                    : {}
                }
            />
        </div>);
};
