import React, { FC, useCallback } from 'react'
import styles from './IconColorPicker.module.scss'
import { Icon, Popover } from '@material-ui/core'
import { FidelityIcons } from './Icons'

export interface IIcon { id: string }
export interface IPicker {
    onChange: (value: IIcon) => void,
    value?: IIcon
}
export const PickerIcon: FC<IPicker> = ({ onChange, value }) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // var isSameColor = useCallback((item: IIcon) => value?.id === item?.id, [value])

    const onChangeHandle = (item: IIcon) => {
        onChange(item)
        handleClose()
    }

    return (
        <div id={styles.IconPicker} >
            <button type='button' className={styles.container} onClick={handleClick}>
                {value?.id ? <img src={"/assets/icon/fidelity/gray/" + value?.id + ".svg"} alt={value?.id} className={styles.icon} /> : <div />}
                <Icon className={styles.arrowIcon} >expand_more</Icon>
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={styles.popover}>
                    <div className={styles.category}>
                        {FidelityIcons.map((item, index) => (
                            <img onClick={onChangeHandle.bind(this, item)} width={16} height={16} key={index} src={"/assets/icon/fidelity/gray/" + item.id + ".svg"} alt={"Icone" + item.id} className={styles.item} />
                        ))}
                    </div>
                </div>

            </Popover>
        </div>
    )
}


