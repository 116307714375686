import { Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./QrcodeItem.module.scss";

import { saveAs } from "file-saver";
import { toPng } from "html-to-image";
import { LocalRepository } from "services/repository/local/LocalRepository";
import { IPaymentLink } from "../../interfaces/IPaymentLink";

export interface IDownloadCouponProps {
  item: IPaymentLink;
}

export const QrcodeItem: React.FC<IDownloadCouponProps> = ({ item }) => {
  const { getCurrentLocal } = LocalRepository();

  const divRef = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState("");

  const convertHtmltoImage = useCallback(async () => {
    if (divRef.current === null) {
      return;
    }
    try {
      const result = await toPng(divRef.current, { cacheBust: true });
      setImage(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    convertHtmltoImage();
  }, [convertHtmltoImage, getCurrentLocal]);

  const downloadQrcode = useCallback(() => {
    saveAs(image, "qrcode.png");
  }, [image]);

  return (
    <div id={styles.QrcodeItem}>
      <div className={styles.title}>
        <p>
          Esse é o <b>QR Code do seu link de pagamento.</b> Disponibilize-os
          para o seus clientes e torne a experiência deles ainda melhor.
        </p>
      </div>
      <div className={styles.content}>
        <div ref={divRef}>
          {
            <div className={styles.main}>
              {
                <QRCodeSVG
                  width={219}
                  height={223}
                  value={`${process.env.REACT_APP_MEPAY_URL}/paymentlink/${item.id}`}
                />
              }
              <span title={item?.paymentLink}>{item?.paymentLink}</span>
            </div>
          }
        </div>
        <div className={styles.containerButton}>
          <Button variant="text" size="small" onClick={downloadQrcode}>
            <span>Salvar imagem</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
