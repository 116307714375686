import { IProductList } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/productsForm/IProducts";
import { IProductsService } from "modules/products/domain/interfaces/IProductsService";
import { parseProductListResponseToIProductList } from "./ParseProductList";

const GetProductListListUseCase = async (
  productsService: IProductsService,
  localId: string
): Promise<IProductList[]> => {
  const response = await productsService.getProductListListByLocalId(localId);
  return parseProductListResponseToIProductList(response);
};
export default GetProductListListUseCase;
