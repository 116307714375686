import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { moneyToFloat } from "services/utils/Money";
import Utils from "services/utils/Utils";
import { IBaseList } from "../../../discounts/interfaces/IDiscounts";
import {
  IDiscountListForm,
  IDiscountProductListFormErrors,
  IDiscountProductType,
} from "../../interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "../../interfaces/IProductList";

const UseDiscountProductListForm = (
  onSubmit: (values: IDiscountListForm[]) => void,
  productList: IProductItemToDiscount[], 

) => {
  const [type, setType] = useState<IDiscountProductType>(IDiscountProductType.money);
  const [discount, setDiscount] = useState<number>(0);
  const [products, setProducts] = useState<IProductItemToDiscount[]>([]);
  const [errors, setErrors] = useState<IDiscountProductListFormErrors>({});
  const [validDateTime, setValidDateTime] = useState<string>('');


  useEffect(() => {
    setDiscount(0);
  }, [type]);

  const validate = (discount: number, products: IProductItemToDiscount[], date: string, type: IDiscountProductType) => {
    let _errors: IDiscountProductListFormErrors = {};
    let hasError = false;

    // if (discount <= 0) {
    //   _errors = { ..._errors, discount: "Desconto deve ser maior que 0(zero)." };
    //   hasError = true;
    // }

    if (type === IDiscountProductType.money) {
      products.forEach((item, index) => {
        if (discount > item.price) {
          _errors = { ..._errors, discount: _errors.discount?.concat(`Produto(s) "${item.name}" com desconto maior que o seu valor;`) || `Produto "${item.name}" com desconto maior que o seu valor;` };
          hasError = true;
        }
      });
    }

    if (type === IDiscountProductType.percentual) {
      if (discount > 100) {
        _errors = { ..._errors, discount: `Insira um valor de no maximo 100%` };
        hasError = true;
      }
    }
  

    if (!products.length) {
      _errors = { ..._errors, product: "Escolha um ou mais produtos" };
      hasError = true;
    } 
    // if (!time) {
    //   _errors = { ..._errors, validTime: "Insira um horário válido" };
    //   hasError = true;
    // }

    setErrors(_errors);
    return !hasError;
  }

  const submitHandle = () => {
    if (validate(discount, products, validDateTime, type)) {
      const newValue = products.map((product) => ({
        type,
        discount,
        product,
        validDateTime,
      }));
      onSubmit(newValue);
    }
  }

  const changeValidDateHandle = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: newDate } = ev.target;
    setValidDateTime(newDate);
  }

  // const changeValidTimeHandle = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { value: newTime } = ev.target;
  //   setValidTime(newTime);
  // }

  const changeDiscountPercentualHandle = (value: string) => {
    setDiscount(Number.parseFloat(value) | 0);
  }

  const changeDiscountValueHandle = (value: string) => {
    setDiscount(moneyToFloat(value));
  }

  const changeDiscountTypeHandle = (value: string) => {
    setType(value as IDiscountProductType);
  }

  const changeProductHandle = (productItems: IProductItemToDiscount[]) => {
    setProducts([...productItems]);
  }

  const handleSelectAll = () => {
    setProducts(productList);
  };

  const handleClearAll = () => {
    setProducts([]);
  };

  return {
    discount,
    products,
    type,
    changeDiscountValueHandle,
    submitHandle,
    changeProductHandle,
    errors,
    changeDiscountTypeHandle,
    changeDiscountPercentualHandle,
    handleSelectAll,
    handleClearAll,
    validDateTime,
    changeValidDateHandle,
    // changeValidTimeHandle,
  };
};

export default UseDiscountProductListForm;
