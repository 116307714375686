import styles from './BoxDigitalAccount.module.scss'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';

interface BoxDigitalAccountProps {
    data?: {
        SaldoDisponivel: number
        SaldoALiberar: number
        SaldoRetido: number
    }
}

const BoxDigitalAccount = ({ data }: BoxDigitalAccountProps) => {
    const { push } = useHistory();
    
    return (
        <div id={styles.BoxDigitalAccount}>
            <div className={styles.title}>
                <p >Conta digital</p>
                <OpenInNewIcon onClick={() => push('/private/contaDigital')}/>
            </div>
            <div className={styles.container}>
                <div className={styles.box}>
                    <AttachMoneyIcon style={{ color: '#399C54' }} />
                    <p className={styles.value}>{data?.SaldoDisponivel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p>Saldo disponível</p>
                </div>
                <div className={styles.box}>
                    <AttachMoneyIcon style={{ color: '#FFB300' }} />
                    <p className={styles.value}>{data?.SaldoALiberar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p>Saldo à liberar</p>
                </div>
                <div className={styles.box}>
                    <AttachMoneyIcon style={{ color: '#F44336' }} />
                    <p className={styles.value}>{data?.SaldoRetido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p>Saldo retido</p>
                </div>
            </div>
        </div>
    )
}
export default BoxDigitalAccount