import { IPostCashCancellationResponse } from "modules/cashCancellation/domain/dto/IPostCashCancellationResponse"
import { formatarValor } from "modules/contaDigital/presentation/pages/utils"
import styles from "./FooterTotal.module.scss";

interface PropsFooter {
    cancellationList: IPostCashCancellationResponse| undefined
}

const FooterTotal = ({cancellationList} : PropsFooter) => {
    return(
        <div id={styles.footerInfo}>
        <div className={styles.footerContainer}>
          <span>TotalGeral:</span>
          <span>{cancellationList?.QuantidadeTotal}</span>
          <span>{cancellationList && formatarValor(cancellationList?.TotalGeral)}</span>
        </div>
      </div>
    )
}

export default FooterTotal