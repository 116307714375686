import React, { FC, useCallback, useMemo } from 'react'
import styles from './SupplyStockMovementFilter.module.scss'
import { SupplyStockMovementType } from 'modules/meepErp/models/stockMovement/_SupplyStockMovementType';
import { useForm } from 'react-hook-form';
import { Icon } from '@material-ui/core';
import { FilterButtonContainer, InputContainer, Row } from '../../ui/form/FormContainers';
import AutocompleteInput, { IAutocompletePaginatedListResponse, IAutocompletePaginatedRequest } from '../../autocomplete/AutocompleteInput';
import { IGetSupplySelectItemResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
import { ISupplyFilterValue } from '../../supply/supplyList/supplyFilter/ISupplyFilter';
import Button from 'components/ui/Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { ISupplyStockMovementFilterSchema } from './SupplyStockMovementFilterValidation';
import { DateRangePicker } from 'modules/crm/presentation/components/crmFilterForm/components/dateRangerPicker/DateRangePicker';
import "./SupplyStockMovementFilter.css";
export interface ISupplyStockMovementFilterValues {
    dateStart?:  Date | null;
    dateEnd?:  Date | null;
    supplyId?: string;
    supplyName?: string | null;
    supplyCode?: number | null;
    supplyGroupId?: string;
    supplyGroupName?: string;
    supplyGroupCode?: string;
    operationId?: string;
    operationName?: string;
    movementType?: SupplyStockMovementType | null;
    quantityMin?: number | null;
    quantityMax?: number | null;
    unitPrice?: number | null,
    totalCost?: number | null;
    page?: number;
    pageSize?: number;
    orderBy?: string;
    ascending?: boolean
    storageLocationName?: string | null;
    storageLocationId?: string | null
}
export interface ISupplyStockMovementFilterProps {
    //propertys
    filter: ISupplyStockMovementFilterValues,
    onSubmmit: (data: ISupplyStockMovementFilterValues) => void
    getSupply: (request: ISupplyFilterValue) => Promise<IAutocompletePaginatedListResponse<IGetSupplySelectItemResponse>>
    getOperation: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
    getSuplyStorageLocals: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
}
const SupplyStockMovementFilter: FC<ISupplyStockMovementFilterProps> = ({
    filter,
    onSubmmit,
    getSupply,
    getOperation,
    getSuplyStorageLocals
}) => {
    const { register, handleSubmit, setValue, watch, reset, formState } = useForm<ISupplyStockMovementFilterValues>({
        resolver: zodResolver(ISupplyStockMovementFilterSchema),
        defaultValues: filter
    });

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.orderBy;
        delete allFields.ascending;

        return Object.values(allFields).some(value => value !== undefined && value !== '' && value !== null);
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            dateStart: null,
            dateEnd: null,
            supplyId: '',
            supplyName: null,
            supplyCode: null,
            operationId: '',
            operationName: '',
            storageLocationName: null,
            storageLocationId: null
        });
        onSubmmit({
            dateStart: null,
            dateEnd: null,
            supplyId: '',
            supplyName: null,
            supplyCode: null,
            operationId: '',
            operationName: '',
            storageLocationName: null,
            storageLocationId: null
        })
    }, [onSubmmit, reset])

    const onChangeDate = useCallback(
        (_values?: [Date, Date] | [Date]) => {
            if (_values) {
                const _startDate = _values[0];
                const _endDate = _values[1] || undefined;
                setValue('dateStart', _startDate)
                setValue('dateEnd', _endDate)
            }
        },
        []
    );

    return (
        <div id={styles.SupplyStockMovementFilter} >
            <form onSubmit={handleSubmit(onSubmmit)}>
                <Row>
                    <InputContainer label="Período" maxWidth={130} requerid>
                    <div className={styles.date}>
                        <DateRangePicker
                            label={""}
                            placeholder={""}
                            onChange={onChangeDate}
                            selectRange
                            value={
                                allFields.dateStart && allFields.dateEnd
                                    ? `${new Date(allFields.dateStart).toLocaleDateString(
                                        "pt-BR"
                                    )} a ${new Date(allFields.dateEnd).toLocaleDateString(
                                        "pt-BR"
                                    )}`
                                    : ""
                            }

                            valueCalendar={[
                                allFields.dateStart || null,
                                allFields.dateEnd || null,
                            ]}
                            error={!!formState.errors.dateStart}
                            helperText={formState.errors.dateStart?.message}
                        />
                        </div>
                    </InputContainer>
                    <InputContainer label="Código" maxWidth={150} requerid>
                        <AutocompleteInput
                            getList={request => getSupply({ ...request, code: request.keyword ? Number(request.keyword) : undefined })}
                            getOptionLabel={(option) => option?.code ?? ''}
                            getOptionDescription={(option) => option?.name ?? ''}
                            textInput={watch('supplyCode')?.toString() ?? ''}
                            value={
                                (!!watch('supplyId') &&
                                    !!watch('supplyCode') &&
                                    !!watch('supplyName')) ?
                                    {
                                        id: watch('supplyId'),
                                        code: watch('supplyCode'),
                                        name: watch('supplyName'),
                                    } : undefined}
                            onSelect={(value) => {
                                setValue('supplyName', value?.name)
                                setValue('supplyId', value?.id)
                                setValue('supplyCode', value?.code)
                            }}
                            error={!!formState.errors.supplyCode}
                            helperText={formState.errors.supplyCode?.message}
                        />
                    </InputContainer>
                    <InputContainer label='Insumo' requerid>
                        <AutocompleteInput
                            value={
                                {
                                    id: watch('supplyId') ?? '',
                                    name: watch('supplyName') ?? '',
                                    code: watch('supplyCode')
                                }}
                            getList={request => getSupply({ ...request, name: request.keyword })}
                            getOptionLabel={(option) => option.name}
                            textInput={watch('supplyName')}
                            onSelect={(value) => {
                                setValue('supplyName', value?.name)
                                setValue('supplyId', value?.id)
                                setValue('supplyCode', value?.code)
                            }}
                            error={!!formState.errors.supplyName}
                            helperText={formState.errors.supplyName?.message}
                        />
                    </InputContainer>
                    <InputContainer label='Local de estoque' requerid>
                        <AutocompleteInput
                            getList={getSuplyStorageLocals}
                            textInput={watch('storageLocationName') ?? ''}
                            value={{
                                id: watch('storageLocationId'),
                                name: watch('storageLocationName') ?? ''
                            }}
                            getOptionLabel={(option) => option.name}
                            onSelect={(value) => {
                                setValue('storageLocationName', value?.name)
                                setValue('storageLocationId', value?.id)
                            }}
                            error={!!formState.errors.storageLocationId}
                            helperText={formState.errors.storageLocationId?.message}
                        />
                    </InputContainer>
                    <InputContainer label="Tipo de operação">
                        <AutocompleteInput
                            value={{
                                id: watch('operationId'),
                                name: watch('operationName') ?? ''
                            }}
                            textInput={watch('operationName')}
                            getList={getOperation}
                            getOptionLabel={(option) => option.name}
                            onSelect={(value) => {
                                setValue('operationName', value?.name)
                                setValue('operationId', value?.id)
                            }}
                        />
                    </InputContainer>

                        <FilterButtonContainer minWidth={100}>
                            {isAnyFieldFilled && <Button onClick={onClickResetFilter} variant="text" color="primary" startIcon={<Icon>close</Icon>}>Limpar filtro</Button>}
                            <Button type="submit" variant="contained" color="secondary" style={{ width: '76px', minWidth: '76px' }}>Buscar</Button>
                        </FilterButtonContainer>

                </Row>
            </form>
        </div>
    )
}
export default SupplyStockMovementFilter