import { CircularProgress, Icon, IconButton } from '@mui/material'
import { IGetDiscountCouponsResponse } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse'
import React from 'react'
import { CouponForm } from '../../components/couponForm/CouponForm'
import { IParamsClientDiscountCouponsList } from '../../hooks/UseDiscountCouponsList'
import { ICouponForm } from '../../interfaces/ICouponForm'
import { IDiscountCouponItem } from '../../interfaces/IDiscountCouponItem'
import styles from './EditCoupon.module.scss'

export interface IEditCouponProps {
    closeDrown: () => void
    selectedItem: IDiscountCouponItem | undefined
    refrash: (params?: IParamsClientDiscountCouponsList | undefined) => Promise<IGetDiscountCouponsResponse | undefined>
    isLoadingEdit: boolean | undefined
}


export const EditCoupon: React.FC<IEditCouponProps> = ({ closeDrown, selectedItem, refrash, isLoadingEdit }) => {
    return (
        <div id={styles.EditCoupon}>
            <div className={styles.titleTop}>
                <h2>Editar <b>cupom de desconto</b></h2>
                <IconButton size='small' onClick={closeDrown}><Icon>close</Icon></IconButton>
            </div>

            {isLoadingEdit &&
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            }

            <CouponForm onClose={closeDrown} defaultValues={selectedItem} refrash={refrash} />
        </div>
    )
}
