import React, { useCallback, useState } from "react";
import styles from "./NotificationsModal.module.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import Checkbox from "components/ui/checkbox/Checkbox";
import { TransitionProps } from "@material-ui/core/transitions";
import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import UseLayout from "components/layout/presentation/UseLayout";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface INotificationsModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  notification: IGetNotificationsByTypeResponse;
}

const NotificationsModal = ({
  openModal,
  setOpenModal,
  notification,
}: INotificationsModalProps) => {
  const [notShowAgain, setNotShowAgain] = useState(true);

  const { saveReadNotification } = UseLayout();

  const handleClose = useCallback(() => {
    setOpenModal(false);
    if (notShowAgain) {
      localStorage.removeItem("@showNotificationModal");
      return saveReadNotification(notification.Id);
    }
    localStorage.setItem("@showNotificationModal", "false");
  }, [notShowAgain, saveReadNotification, notification.Id, setOpenModal]);

  return (
    <Dialog open={openModal} TransitionComponent={Transition}>
      <div id={styles.NotificationsModal}>
        <header>
          <h2>{notification.Titulo}</h2>
          <IconButton onClick={() => setOpenModal(false)}>
            <Close />
          </IconButton>
        </header>
        <DialogContent className={styles.modalContent}>
          <img src="/assets/img/notifications-modal-img.png" alt="" />
          <div className={styles.modalText}>
            <span>{notification.Texto}</span>
          </div>
        </DialogContent>
        <DialogActions className={styles.modalActions}>
          <div className={styles.checkboxContent}>
            <Checkbox
              defaultChecked
              className={styles.checkbox}
              value={notShowAgain}
              onChange={() => {
                setNotShowAgain(!notShowAgain);
              }}
            />
            <label htmlFor="trackco-id" className={styles.labelCheckbox}>
              Não exibir novamente
            </label>
          </div>
          <Button
            fullWidth={false}
            style={{ width: "90px" }}
            color="primary"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default NotificationsModal;
