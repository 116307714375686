import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@mui/icons-material";
import styles from "./LicensesAccordion.module.scss";
import AccordionContent from "./accordionContent/AccordionContent";
import UseDimension from "components/dimension/UseDimension";
import AccordionContentMobile from "./accordionContentMobile/AccordionContentMobile";
import { UseGetContract } from "../../hooks/UseGetContract";
import { UseGetEquipments } from "../../hooks/UseGetEquipments";

export interface ILicensesAccordionProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const LicensesAccordion = ({
  expanded,
  setExpanded,
}: ILicensesAccordionProps) => {
  const { isMobile } = UseDimension();

  const { contractLicenses } = UseGetContract();
  const { equipments } = UseGetEquipments();

  const contractTitle = ["Contrato", "Data início", "Data final"];
  const equipmentsTitle = ["Equipamento", "Licenças", "Usado", "Disponíveis"];

  return (
    <>
      <div className={styles.licensesContainer}>
        <Accordion
          expanded={expanded}
          onClick={() => setExpanded(!expanded)}
          className={styles.licensesAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            Minhas licenças
          </AccordionSummary>
          <AccordionDetails>
            {isMobile ? (
              <AccordionContentMobile
                contractTitle={contractTitle}
                contractLicenses={contractLicenses}
                equipmentsTitle={equipmentsTitle}
                equipments={equipments}
              />
            ) : (
              <AccordionContent
                contractTitle={contractTitle}
                contractLicenses={contractLicenses}
                equipmentsTitle={equipmentsTitle}
                equipments={equipments}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default LicensesAccordion;
