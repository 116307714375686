import { Icon, IconButton } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { IDiscountListForm, IDiscountProductListForm, IDiscountProductType } from "../../interfaces/IDiscountProductListForm";
import styles from "./DiscountProductList.module.scss";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
export interface IDiscountProductListProps {
  //propertys
  discountsList: IDiscountListForm[];
  onPressEdit?: (item: IDiscountProductListForm, index: number) => void;
  onPressDelete?: (item: IDiscountProductListForm, index: number) => void;
}
const DiscountProductList: FC<IDiscountProductListProps> = ({ discountsList, onPressEdit, onPressDelete }) => {

  const listItems = useMemo(
    () => (
      <div className={styles.discountList}>
        {discountsList?.length === 0 && <div className={styles.emptyMessage}>Nenhum desconto cadastrado</div>}
        {discountsList?.map((item, index) => (
          <div key={index} className={styles.discountItem}>
            <div>
              <div>
                {item.product.name}:
                {item.type === IDiscountProductType.money ? (
                  <p>
                    <b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(item.discount)}
                    </b>
                    {' '}de desconto - Valor final:{' '}
                    <b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format((item.product.price - item.discount))}
                    </b>
                  </p>
                ) : (
                  <p>
                    <b>{Number(item.discount)}%</b> de desconto - Valor final:{" "}
                    <b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(item.product.price - ((item.discount / 100) * item.product.price))}
                    </b>
                  </p>
                )}
              </div>
            </div>

            {onPressEdit && (
              <IconButton onClick={() => onPressEdit(item.product, index)}>
                <Icon>edit</Icon>
              </IconButton>
            )}
            {onPressDelete && (
              <IconButton onClick={() => onPressDelete(item.product, index)} className={styles.deleteIcon}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    ),
    [discountsList]
  );

  return (
    <div id={styles.DiscountProductList}>
      <div className={styles.container}>{listItems}</div>
    </div>
  );
};
export default DiscountProductList;
