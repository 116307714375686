import { IEditCouponRequest } from "../../domain/dto/IEditCouponRequest ";

const ParseEditCouponRequestUseCase = (
  request: IEditCouponRequest,
  localId: string,
  couponId: string
) => {
  const _request: IEditCouponRequest = {
    id: couponId,
    ownerId: localId,
    code: request.code,
    description: request.description,
    valueType: request.valueType,
    value: request.value,
    maxValue: request.maxValue,
    quantity: request.quantity,
    forFirstOrder: request.forFirstOrder,
    quantityPerUser: request.quantityPerUser,
    minOrderValue: request.minOrderValue,
    maxOrderValue: request.maxOrderValue,
    customerCPFs: request.customerCPFs?.map((value) =>
      value.replace(/\D/g, "")
    ),
    isRequiredCustomerCPF: request?.isRequiredCustomerCPF,
    startAt: request.startAt,
    finishAt: request.finishAt,
    operations: request.operations,
    deviceTypes: request.deviceTypes,
    devices: request.devices,
    appliedIn: 1,
    type: 1,
  };

  return _request;
};

export default ParseEditCouponRequestUseCase;
