import { Autocomplete, TextField } from "@mui/material";
import styles from "../AddSystemSidesheet.module.scss";
import { IPostSystemConfigPayload } from "modules/admin/menuConfig/domain/dto/IPostSystemConfigPayload";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { useEffect, useState } from "react";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";

interface IPlansStepProps {
    addSystemConfigValues: IPostSystemConfigPayload;
    setAddSystemConfigValues: React.Dispatch<React.SetStateAction<IPostSystemConfigPayload>>;
}

const PlansStep = ({ addSystemConfigValues, setAddSystemConfigValues }: IPlansStepProps) => {
    const { plans, getPlans } = usePlans();

    const [defaultPlans, setDefaultPlans] = useState<IPlans[]>([]);

    useEffect(() => {
        getPlans();
    }, [getPlans]);

    useEffect(() => {
        if (!!addSystemConfigValues.plans && addSystemConfigValues.plans.length && plans) {
            const findPlansById = addSystemConfigValues.plans.map((item) => {
                const findPlan = plans.find((plan) => plan.id === item);
                if (findPlan) return findPlan;
                return undefined;
            });

            const plansById: IPlans[] = findPlansById.filter((item): item is IPlans => item !== undefined);
            setDefaultPlans(plansById);
        }
    }, [addSystemConfigValues, plans]);

    return (
        <div id={styles.PlansStep}>
            <div>
                <label htmlFor="plans" className={styles.required}>
                    Planos
                </label>
                <Autocomplete
                    options={plans ?? []}
                    getOptionLabel={option => option.name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Selecione os planos"
                            InputProps={{
                                style: { padding: '0 20px 0 10px' },
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const plansIds = values.map((it) => it.id);
                        setAddSystemConfigValues({ ...addSystemConfigValues, plans: plansIds });
                        setDefaultPlans(values);
                    }}
                    sx={{ width: "100%" }}
                    defaultValue={defaultPlans}
                />
            </div>
        </div>
    );
}

export default PlansStep;