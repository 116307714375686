import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Button from 'components/ui/Button/Button';
import { IReservationList } from 'modules/schedule/domain/models/IReserveList';
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import styles from './Pagination.module.scss'

interface PaginationProps<T> {
    data: T[],
    RenderComponent: (value: T, index: number) =>  ReactElement,
    title?: string,
    pageHandle: (page: number) => void,
    pageLimit: number,
    dataLimit: number,
    page: number,

}

export const Pagination = <T,>({ data, RenderComponent, title, pageLimit, dataLimit, page, pageHandle }: PaginationProps<T> ) =>  {

    const [totalPage, setTotalPage] = useState(Math.ceil(pageLimit / dataLimit));

    const goToNextPage = () => {
        if (page < totalPage) {
            pageHandle(page + 1);
        }

    }

    const goToPreviousPage = () => {
        if (page > 0) {
            pageHandle(page - 1);
        }

    }

    const changePage = (pageNumber: number) => {


        pageHandle(pageNumber);

    }

    const getPaginatedData = () => {
        return data;
    };

    const getPaginationGroup = () => {
        return new Array(totalPage).fill('').map((_, index) => index + 1);
    };


    return (
        <div id={styles.Pagination}>

            <h1>{title}</h1>

            <div className={styles.dataContainer}>
                {getPaginatedData()?.map((item: T, index: number) => (
                    RenderComponent(item, index)
                ))}
            </div>
            <div className={styles.pagination}>

                <button
                    onClick={goToPreviousPage}
                    className={`${styles.prev} ${page === 0 ? `${styles.disabled}` : ''}`}
                >
                    <KeyboardArrowLeft />
                </button>



                {getPaginationGroup().map((item, index) => (
                    <button
                        key={index}
                        onClick={() => changePage(index)}
                        className={`${styles.paginationItem} ${page === item - 1 ? `${styles.active} ` : null}`}
                    >
                        <span>{item}</span>
                    </button>
                ))}


                <button
                    onClick={goToNextPage}
                    className={`${styles.next} ${page === totalPage - 1 ? `${styles.disabled}` : ''}`}
                >
                    <KeyboardArrowRight />
                </button>
            </div>
        </div>

    )
}
