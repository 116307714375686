import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigIFoodMarket } from "./UseConfigIFoodMarket";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IFoodMarketFormErrors, IFoodMarketValidation } from "./validade/IFoodMarketValidation";
import ConfigContentIFoodMarket from "../../components/_configCampaign/configContentIFoodMarket/ConfigContentIFoodMarket";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UseDeleteSidesheet from "../../hook/UseDeleteSidesheet";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

export interface IConfigIFoodMarketProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigIFoodMarket = ({ open, setOpen, step, setStep }: IConfigIFoodMarketProps) => {
  const [error, setError] = useState<IFoodMarketFormErrors>({} as IFoodMarketFormErrors);
  const [success, setSuccess] = useState(true);
  const [hasCalled, setHasCalled] = useState(false);
  const [deleteStep, setDeleteStep] = useState(1);

  const  { deleteType, setDeleteType } = UseDeleteSidesheet();
  const { toast } = useUi();

  const { isLoading, values, setValues, getStatusIfoodMarketConfiguration, getIFoodMarketStatus, postIFoodMarketConfiguration } = UseConfigIFoodMarket();

  const handleCallStatus = useCallback(async () => {
    if (!hasCalled) {
      const res = await getStatusIfoodMarketConfiguration();
      if (res === "ok") setHasCalled(true);
    }
  }, [getStatusIfoodMarketConfiguration, hasCalled]);

  useEffect(() => {
    handleCallStatus();
  }, [handleCallStatus]);

  const handleContinueButton = useCallback(async () => {
    if (!IFoodMarketValidation(values, setError)) return;
    const response = await postIFoodMarketConfiguration({
      ...values, id: getIFoodMarketStatus.id ?? "", isActive: true, integrationType: 2
    });
    if (response === "ok") {
      getStatusIfoodMarketConfiguration();
    } else {
      setSuccess(false);
    };
    setStep(step + 1);
  }, [getIFoodMarketStatus.id, getStatusIfoodMarketConfiguration, postIFoodMarketConfiguration, setStep, setSuccess, step, values]);

  const handleStatus = useMemo(
    () => (getIFoodMarketStatus.isActive ? "enabled" : "disabled"),
    [getIFoodMarketStatus.isActive]
  );

  const handleDeleteConfig = useCallback(async () => {
    const response = await postIFoodMarketConfiguration({
      ...values, id: getIFoodMarketStatus.id ?? "", isActive: false, integrationType: 2
    });
    if (response === "ok") {
      getStatusIfoodMarketConfiguration();
      setDeleteStep(deleteStep + 1);
    } else {
      toast("Ocorreu um erro. Tente novamente", "error");
    };
  }, [getIFoodMarketStatus.id, getStatusIfoodMarketConfiguration, postIFoodMarketConfiguration, deleteStep, toast, values]);

  return (
    <>
      <ContentBox
        status={handleStatus}
        type={IntegrationTypeEnum.IFOOD_MARKET}
        text="Receba pedidos do iFood Mercado através de nosso portal"
        setOpen={setOpen}
        loading={isLoading}
      />
      {open && open === "iFood Mercado" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            <ConfigContentIFoodMarket
              step={step}
              values={values}
              setValues={setValues}
              error={error}
              setError={setError}
            />
          }
          cancelButton
          continueButton="Concluir"
          handleContinueButton={handleContinueButton}
          isLoading={isLoading}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text={success ? "Integração realizada com sucesso!" : "Erro ao realizar integração, tente novamente!"}
              success={success}
            />
          }
        />
      )}
      {deleteType && deleteType === "iFood Mercado" && (
        <Sidesheet
          open={deleteType}
          onClose={() => setDeleteType(null)}
          title={<h2>Remover integração</h2>}
          content={
            <SidesheetFeedback
              text="Deseja remover a integração?"
            />
          }
          cancelButton
          continueButton="Remover"
          handleContinueButton={handleDeleteConfig}
          isLoading={isLoading}
          currentStep={deleteStep}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração removida com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigIFoodMarket;
