import { addMinutes, format, isValid } from "date-fns";

export const newDateWithNoSecondsAndMilliseconds = () => {
    const date = new Date(new Date().setSeconds(0, 0));
    return date;
}

export const convertToUTC = (date: Date) => {
    if (!isValid(date)) return '';
    const result = format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
    return result;
}

export const getWeekDayFromNumber = (day: number) => {
  switch (day) {
    case 0:
      return 'Domingo';
    case 1:
      return 'Segunda-Feira';
    case 2:
      return 'Terça-Feira';
    case 3:
      return 'Quarta-Feira';
    case 4:
      return 'Quinta-Feira';
    case 5:
      return 'Sexta-Feira';
    case 6:
      return 'Sábado';
  }
}
