import Sidesheet from "components/sidesheet/Sidesheet";
import TabView from "components/tab/TabView";
import { useCallback, useEffect, useState } from "react";
import { IPostSystemConfigPayload } from "modules/admin/menuConfig/domain/dto/IPostSystemConfigPayload";
import { INameFormErrors, NameValidation } from "./nameStep/NameValidation";
import { UseMenuConfigPage } from "../../hooks/UseMenuConfigPage";
import { IGetSystemConfigByIdResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigByIdResponse";
import NameStep from "./nameStep/NameStep";
import PlansStep from "./plansStep/PlansStep";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface IAddSystemSidesheetProps {
    openAddSystemSidesheet: "isNew" | "isEditing";
    setOpenAddSystemSidesheet: React.Dispatch<React.SetStateAction<"isNew" | "isEditing" | null>>;
    editItem?: IGetSystemConfigByIdResponse;
    getSystemConfigList: () => Promise<any>;
}

export enum AddSystemConfigStepEnum {
    name = 0,
    plans = 1,
}

const AddSystemSidesheet = ({ openAddSystemSidesheet, setOpenAddSystemSidesheet, editItem, getSystemConfigList }: IAddSystemSidesheetProps) => {
    const [addSystemConfigStep, setAddSystemConfigStep] = useState(1);
    const [addSystemConfigValues, setAddSystemConfigValues] = useState<IPostSystemConfigPayload>({} as IPostSystemConfigPayload);
    const [editItemId, setEditItemId] = useState("");
    const [errors, setErrors] = useState<INameFormErrors>({});

    const { toast } = useUi();
    const { postSystemConfig, isLoading, putSystemConfig } = UseMenuConfigPage();

    useEffect(() => {
        if (editItem?.name && openAddSystemSidesheet === "isEditing") {
            setAddSystemConfigValues({
                name: editItem.name,
                systemIdentifier: editItem.systemIdentifier,
                plans: editItem.plans.map((plan) => plan.planId),
            });
            setEditItemId(editItem.id);
        }
    }, [editItem, openAddSystemSidesheet]);

    const handleChangeTab = useCallback((currentTabe: AddSystemConfigStepEnum) => {
        if (!addSystemConfigValues.name) return;
        setAddSystemConfigStep(currentTabe);
    }, [addSystemConfigValues, setAddSystemConfigStep]);

    const onCloseAddSystemSidesheet = useCallback(() => {
        setOpenAddSystemSidesheet(null);
        setAddSystemConfigStep(1);
    }, [setOpenAddSystemSidesheet]);

    const handleSubmit = useCallback(async () => {
        if (addSystemConfigStep === 1) {
            if (NameValidation(addSystemConfigValues, setErrors)) {
                return setAddSystemConfigStep(addSystemConfigStep + 1);
            }
            return;
        }
        let res = "";
        if (editItemId) {
            res = await putSystemConfig(editItemId, addSystemConfigValues);
        } else {
            res = await postSystemConfig(addSystemConfigValues);
        }
        if (res === "ok") {
            toast(`Sistema ${editItemId ? "atualizado" : "criado"} com sucesso!`, "success");
            onCloseAddSystemSidesheet();
            getSystemConfigList();
        }
    }, [
        addSystemConfigStep,
        addSystemConfigValues,
        editItemId,
        getSystemConfigList,
        onCloseAddSystemSidesheet,
        postSystemConfig,
        putSystemConfig,
        toast
    ]);

    return (
        <Sidesheet
            isLoading={isLoading}
            open={openAddSystemSidesheet}
            onClose={onCloseAddSystemSidesheet}
            title={
                <h2>
                    {openAddSystemSidesheet === "isNew" ? "Nova" : "Editar"} configuração de <b>sistema</b>
                </h2>
            }
            content={
                addSystemConfigStep === 1 
                    ? <NameStep
                        addSystemConfigValues={addSystemConfigValues}
                        setAddSystemConfigValues={setAddSystemConfigValues}
                        errors={errors}
                        setErrors={setErrors}
                    /> 
                    : <PlansStep addSystemConfigValues={addSystemConfigValues} setAddSystemConfigValues={setAddSystemConfigValues} />
            }
            currentStep={addSystemConfigStep}
            totalSteps={2}
            continueButton={addSystemConfigStep === 1 
                ? "Continuar" 
                : editItemId 
                    ? "Atualizar" : "Criar"
            }
            handleContinueButton={handleSubmit}
            cancelButton
            tabView={
                <TabView
                    index={addSystemConfigStep - 1 || AddSystemConfigStepEnum.name}
                    tabs={[
                    {
                        label: "Nome",
                        value: AddSystemConfigStepEnum.name,
                    },
                    {
                        label: "Planos",
                        value: AddSystemConfigStepEnum.plans,
                    },
                    ]}
                    onChangeTab={(tab) => handleChangeTab(tab.value + 1)}
                />
            }
        />
    );
}

export default AddSystemSidesheet;