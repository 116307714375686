import { FormControlLabel, Switch } from '@material-ui/core'
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import { IDeviceProfile } from 'modules/config/deviceConfig/presentation/interfaces/IDeviceProfile';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './GeneralParamsForm.module.scss'

export interface IGeneralParamsFormProps {
    defaultValue?: IDeviceProfile;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IDeviceProfile) => void
    onClickBack: (value: IDeviceProfile) => void
}

const GeneralParamsForm: React.FC<IGeneralParamsFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IDeviceProfile>(defaultValue ?? {} as IDeviceProfile);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeChecked = (property: string, value: any) => {
        const properties = property.split('.');
        const fullObj = values[properties[0] as keyof IDeviceProfile] as object;

        if (properties.length === 1) {
            setValues((prev) => ({ ...prev, [properties[0]]: value }));
        } else {
            setValues((prev) => ({ ...prev, [properties[0]]: { ...fullObj, [properties[1]]: value } }));
        }

        onModified(true);
    }
    
    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])

    return (
        <form id={styles.GeneralParamsForm} onSubmit={submitHandle}>
            <div className={styles.container}>
                <h4>Mercado Honesto</h4>
                <FormControlLabel
                    checked={values.honestMarket?.isSingleScreenEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("honestMarket.isSingleScreenEnabled", checked)}
                    control={<Switch color='secondary' />} label={<p>Habilitar visualização de uma página<Tooltip title="Equipamento" text="Totem" icon="info" /></p>}
                />
                <FormControlLabel
                    checked={values.honestMarket?.isBarReaderEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("honestMarket.isBarReaderEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar leitor de código de barras"}
                />
                <FormControlLabel
                    checked={values.honestMarket?.isOnlyBarReaderEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("honestMarket.isOnlyBarReaderEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Aceitar apenas produtos lidos pelo código de barras"}
                />

                <h4>Agendamento/Reserva</h4>
                <FormControlLabel
                    checked={values.schedule?.enabled || false}
                    onChange={(ev, checked) => onChangeChecked("schedule.enabled", checked)}
                    control={<Switch color='secondary' />} label={<p>Habilitar reserva<Tooltip title="Equipamento" text="Totem" icon="info" /></p>}
                />

                <h4>Escola</h4>
                <FormControlLabel
                    checked={values.school?.isNumericKeypadNicknameEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("school.isNumericKeypadNicknameEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar uso de teclado numérico em busca por apelido"}
                />
                <FormControlLabel
                    checked={values.school?.isSearchCardByNicknameEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("school.isSearchCardByNicknameEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Buscar cartão com apelido"}
                />
                <FormControlLabel
                    checked={values.security?.isStrongPasswordEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("security.isStrongPasswordEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Utilizar senha forte"}
                />

                <h4>Delivery</h4>
                <FormControlLabel
                    checked={values.delivery?.isAcceptPayDeliveryOrderEnabled || false}
                    onChange={(ev, checked) => onChangeChecked("delivery.isAcceptPayDeliveryOrderEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Aceitar pagamento de pedidos do delivery"}
                />

                <h4>Segurança</h4>
                <FormControlLabel
                    checked={values.security?.analyzeConfiguredAlerts || false}
                    onChange={(ev, checked) => onChangeChecked("security.analyzeConfiguredAlerts", checked)}
                    control={<Switch color='secondary' />} label={"Analisar alertas configurados"}
                />
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default GeneralParamsForm