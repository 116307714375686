import { EnumDurationType, IServiceScheduleForm } from "../interfaces/IServiceForm";
import { IServiceFormErros } from "../interfaces/IServiceFormErros";

const MAX_HOURS_SERVICE_SCHEDULE_DURATION = 24;
const MAX_MINUTES_SERVICE_SCHEDULE_DURATION = 1440;
const MAX_CHARACTERS_PER_WORD = 25;

export const validate = (formData: IServiceScheduleForm, setErrors: React.Dispatch<React.SetStateAction<IServiceFormErros>>, showCases: string[]) => {
  let _errors: IServiceFormErros = {};
  let hasError = false;
  if (!formData.name.trim()) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }else{
    var nameSplit = formData.name.split(" ");
    for(const element of nameSplit){
      if(element.length > MAX_CHARACTERS_PER_WORD){
        _errors = { ..._errors, name: "Campo não deve conter palavras com mais de "+MAX_CHARACTERS_PER_WORD+" caracteres" };
        hasError = true;
      }
    }
  }
  if (!formData.imageUrl) {
    _errors = { ..._errors, imageUrl: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.duration) {
    _errors = { ..._errors, duration: "Campo obrigatório" };
    hasError = true;
  }else if (formData.duration <= 0) {
    _errors = { ..._errors, duration: "A duração deve ser maior que zero" };
    hasError = true;
  }
  if (formData.durationType === EnumDurationType.Horas && formData.duration > MAX_HOURS_SERVICE_SCHEDULE_DURATION) {
    _errors = { ..._errors, duration: "A duração máxima permitida é de 24 horas" };
    hasError = true;
  }
  if (formData.durationType === EnumDurationType.Minutos && formData.duration > MAX_MINUTES_SERVICE_SCHEDULE_DURATION) {
    _errors = { ..._errors, duration: "A duração máxima permitida é de 1440 minutos (24 horas)" };
    hasError = true;
  }
  if (!formData.serviceCategoryId) {
    _errors = { ..._errors, serviceCategoryId: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.description) {
    _errors = { ..._errors, description: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.otherValues.length < 1) {
    _errors = { ..._errors, otherValues: "Campo obrigatório" };
    hasError = true;
  }
  if (showCases.length < 1) {
    _errors = { ..._errors, showCases: "Campo obrigatório" };
    hasError = true;
  }
  setErrors(_errors);
  return !hasError;
};
