import ApproveCampaignUseCase from "modules/crm/application/useCases/ApproveCampaignUseCase";
import GetPushCampaignsUseCase from "modules/crm/application/useCases/GetPushCampaignsUseCase";
import ReproveCampaignUseCase from "modules/crm/application/useCases/ReproveCampaignUseCase";
import { CampaignStatus, ICampaignData, ICampaignItem } from "modules/crm/presentation/intefaces/ICampaign"
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react"
import CrmServiceApi from "services/api/crm/CrmApi";

const service = CrmServiceApi();

export const UsePushApprovalPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [approvalLoading, setApprovalLoading] = useState<boolean>();
    const [campaignsList, setCampaigns] = useState<ICampaignData>();
    const [filter, setFilter] = useState<CampaignStatus[]>([CampaignStatus.Pending]);
    const [selectedCampaign, setSelectedCampaign] = useState<ICampaignItem>();
    const { currentLocal } = useLocal();
    const [page, setPage] = useState(1);
    const [reproving, setReproving] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        setPage(1)
    }, [filter])

    const getData = useCallback(async (params?: { pagination?: { page?: number, pageSize?: number }, _filter?: CampaignStatus[] }) => {
        try {
            setIsLoading(true);
            if (currentLocal) {
                const response = await GetPushCampaignsUseCase(service, params?.pagination, params?._filter);
                setCampaigns(response);
                return response
            }
        } finally {
            setIsLoading(false);
        }
    }, [currentLocal]);

    useEffect(() => {
        getData({ _filter: filter })
    }, [filter, getData])

    const handleSelectCampaign = (campaign?: ICampaignItem) => {
        setSelectedCampaign(campaign)
        setReason('');
        setReproving(false);
    }

    const handleApprove = async (campaignId: string) => {
        try {
            setApprovalLoading(true);
            if (currentLocal) {
                await ApproveCampaignUseCase(service, campaignId);
            }
            setSelectedCampaign(undefined);
            getData({ _filter: filter })
            setReason('');
            setReproving(false);
        } finally {
            setApprovalLoading(false);
        }
    }   
    
    const handleReprove = async (campaignId: string, reason: string) => {
        try {
            setApprovalLoading(true);
            await ReproveCampaignUseCase(service, campaignId, reason);
            setSelectedCampaign(undefined);
            getData({ _filter: filter })
            setReason('');
            setReproving(false);
        } finally {
            setApprovalLoading(false);
        }
    }

    const handleFilter = (value: CampaignStatus) => {
        if (filter.find(x => x === value)) {
            setFilter(prev => prev.filter(x => x !== value))
        } else {
            setFilter(prev => [ ...prev, value])
        }
    }

    return {
        campaignsList,
        isLoading,
        selectedCampaign,
        approvalLoading,
        filter,
        page,
        reproving,
        reason,
        setReason,
        setReproving,
        setPage,
        getData,
        handleSelectCampaign,
        handleApprove,
        handleReprove,
        handleFilter
    };
}
