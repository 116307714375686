import { Print, VerticalAlignBottom } from '@mui/icons-material';
import { Button, CircularProgress, Icon, IconButton } from '@mui/material'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IDiscountCouponItem } from '../../interfaces/IDiscountCouponItem';
import styles from './DownloadCoupon.module.scss'

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import { LocalRepository } from 'services/repository/local/LocalRepository';



export interface IDownloadCouponProps {
    item: IDiscountCouponItem | undefined;
    closeDownloadCoupon: () => void;
    isLoading: boolean | undefined;
}

export const DownloadCoupon: React.FC<IDownloadCouponProps> = ({ item, closeDownloadCoupon, isLoading }) => {


    const { getCurrentLocal } = LocalRepository()

    const { currentLocal } = useLocal();
    const divRef = useRef<HTMLDivElement>(null);
    const [image, setImage] = useState('')

    const [nameLocal, setNameLocal] = useState('')

    const imageLocal = `${process.env.REACT_APP_URL_IMAGEM}clientes-profile/${currentLocal?.id}.png`;



    const convertHtmltoImage = useCallback(async () => {
        if (divRef.current === null) {
            return
        }
        try {
            const result = await toPng(divRef.current, { cacheBust: true, })
            setImage(result)
            return result
        } catch (error) {
            console.log(error);
        }

    }, [])

    useEffect(() => {
        const local = getCurrentLocal();
        local && setNameLocal(local?.name)

        convertHtmltoImage()
    }, [convertHtmltoImage, getCurrentLocal])


    const downloadCoupon = useCallback(() => {

        saveAs(image, 'cupom.png');


    }, [image])


    const handlePrint = useCallback(() => {
        window.print();
    }, []);



    return (
        <div id={styles.DownloadCoupon}>
            <div className={styles.titleTop}>
                <h2>Download <b>QR Code</b></h2>
                <IconButton size='small' onClick={closeDownloadCoupon}><Icon>close</Icon></IconButton>
            </div>
            <div className={styles.container}>
                <div className={styles.content} ref={divRef}>

                    <div className={styles.header}>
                        <img src={imageLocal} alt="Logo estabelecimento"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "/assets/icon/image_placeholder.svg";
                            }}
                        />
                        <span>{nameLocal}</span>
                    </div>
                    {
                        !isLoading ?
                            <>
                                <div className={styles.main}>
                                    {item?.code && <QRCodeSVG value={item.code} />}
                                    <span title={item?.code}>{item?.code}</span>
                                </div>
                                <div className={styles.footer}>
                                    <span title={item?.description}>{item?.description}</span>
                                </div>
                            </>
                            :
                            <div className={styles.loading}>
                                <CircularProgress />
                            </div>
                    }
                </div>
                <div className={styles.containerButton}>
                    <Button size='small' disabled={isLoading} onClick={downloadCoupon}><VerticalAlignBottom /><span>Download</span></Button>
                    <Button size='small' disabled={isLoading} onClick={handlePrint}><Print /><span>Imprimir</span></Button>

                </div>
            </div>

            <div className={styles.buttonFooter}>
                <Button onClick={closeDownloadCoupon}>Fechar</Button>
            </div>
        </div>
    )
}
