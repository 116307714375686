import { IProductsService } from "modules/products/domain/interfaces/IProductsService"

const ChangeDefaultPrinterUseCase = async (service: IProductsService, productIds: string[], defaultPrinterId?: string): Promise<void> => {
    const promises: Promise<void>[] = []

    productIds.forEach(x => {
        promises.push(service.changeDefaultPrinter(x, defaultPrinterId));
    })

    await Promise.all(promises);
}
export default ChangeDefaultPrinterUseCase