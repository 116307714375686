import UseDimension from "components/dimension/UseDimension";
import styles from "./WizardInfo.module.scss";
import { CloseRounded } from "@mui/icons-material";

interface IWizardInfoProps {
  onClose: () => void;
}

const WizardInfo = ({ onClose }: IWizardInfoProps) => {
  const { isMobile } = UseDimension();

  return (
    <div className={isMobile ? styles.wizardInfoMobile : styles.wizardInfo}>
      <div className={styles.dialogTitle}>
        <span>Ainda estaremos aqui!</span>
        <div
          onClick={onClose}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CloseRounded className={styles.closeIcon} />
        </div>
        <img
          src="/assets/icon/wizard-info-triangle.svg"
          alt=""
          width="30px"
          className={styles.triangle}
        />
      </div>
      <div className={styles.dialogDescription}>
        <img src="/assets/img/wizard-info.png" alt="Tutorial" width="124px" />
        <span>
          Caso precise de ajuda, o tutorial do <b>Portal Meep</b> estará no menu
          de Ajuda, basta clicar e retomar do ponto em que parou.
        </span>
      </div>
    </div>
  );
};

export default WizardInfo;
