import { useEffect, useState } from "react";
import { usePlans } from "../../hooks/PlansContext";
import { FeatureTypeEnum } from "../../interfaces/FeatureTypeEnum";
import { useHistory, useParams } from "react-router-dom";
import { SubscribePlanUseCase } from "modules/plans/application/SubscribePlanUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import PlansApi from "services/api/plans/PlansApi";
import { IPlans } from "../../interfaces/IPlans";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IFeatures } from "../../interfaces/IFeatures";
import { getRolesByFunctionUseCase } from "modules/cargos/application/useCases/getRolesByFunctionUseCase";
import CargosApi from "services/api/cargos/CargosApi";

const service = PlansApi();
const roleService = CargosApi();

export const UsePurchasePlan = () => {
    const { subscription, getPlan, getSubscription, getAdditionalFeatures } = usePlans();
    const [additionalFeatures, setAdditionalFeaturesFeatures] = useState<IFeatures[]>([]);
    const [pushFeature, setPushFeature] = useState<IFeatures>();
    const [emailFeature, setEmailFeature] = useState<IFeatures>();
    const [defaultEmailFeature, setDefaultEmailFeature] = useState<IFeatures>();
    const [defaultPushFeature, setDefaultPushFeature] = useState<IFeatures>();
    const [loading, setLoading] = useState(true);
    const { id } = useParams<{ id: string }>();
    const { currentLocal } = useLocal();
    const [plan, setPlan] = useState<IPlans>({} as IPlans)
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionModalOpened, setPermissionModalOpened] = useState(false);
    const { toast } = useUi();

    useEffect(() => {
        if (!id) history.push('/private/plans');
    }, [history, id])

    useEffect(() => {
      (async () => {
        if (currentLocal) {
          try {
            const response = await getRolesByFunctionUseCase(roleService, currentLocal.id);
            setHasPermission(!!response.find(x => x.name.toLocaleLowerCase() === 'acessos' || x.name.toLocaleLowerCase() === 'admin'));
          } finally {}
        }
      })()
    }, [currentLocal]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                getSubscription();
                if (id) {
                    const response = await getPlan(id);
                    setPlan(response);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [getPlan, getSubscription, id]);

    useEffect(() => {
        (async () => {
            try {
                const response = await getAdditionalFeatures([FeatureTypeEnum.PUSH, FeatureTypeEnum.EMAIL]);
                setAdditionalFeaturesFeatures(response);
                const emailFeature = subscription?.additionalFeatures?.find(x => x.featureTypeValue === FeatureTypeEnum.EMAIL) || response.find(x => x.featureTypeValue === FeatureTypeEnum.EMAIL && x.index === 0);
                const pushFeature = subscription?.additionalFeatures?.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH) || response.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH && x.index === 0);

                setDefaultEmailFeature(emailFeature);
                setDefaultPushFeature(pushFeature);
                setEmailFeature(emailFeature);
                setPushFeature(pushFeature);
            } finally {

            }
        })();
    }, [getAdditionalFeatures, subscription?.additionalFeatures]);

    const subscribePlan = async () => {
        try {
            if (!hasPermission) {
              setPermissionModalOpened(true);
            } else {
              setSaving(true);
              const getToken = localStorage.getItem("@token");
              const token = getToken && JSON.parse(getToken);
              const user = JSON.parse(token.user)

              await SubscribePlanUseCase(service, user.cpf, currentLocal!.id, id, [pushFeature?.id || '', emailFeature?.id || '']);
              history.push('/private/plans');
            }
        } catch {
            toast('Ocorreu um erro ao contratar o plano', 'error');
            setSaving(false);
        }
    }

    const getTotalValue = () => {
        const planValue = plan.price || 0;
        const pushValue = pushFeature && pushFeature.index! > 0 ? additionalFeatures.find(x => x.id === pushFeature?.id)!.price || 0 : 0;
        const emailValue = emailFeature && emailFeature.index! > 0 ? additionalFeatures.find(x => x.id === emailFeature?.id)!.price || 0 : 0;

        return planValue + pushValue + emailValue;
    }

    return {
        additionalFeatures,
        subscription,
        pushFeature,
        emailFeature,
        setPushFeature,
        setEmailFeature,
        loading,
        subscribePlan,
        plan,
        getTotalValue,
        defaultEmailFeature,
        defaultPushFeature,
        saving,
        permissionModalOpened,
        setPermissionModalOpened
    }
}

