import UseDimension from "components/dimension/UseDimension";
import styles from "components/sidesheet/Sidesheet.module.scss";

const DeleteValidation = () => {
  const { isMobile } = UseDimension();

  return (
    <>
      <div
        className={`${styles.successContent} ${
          isMobile && styles.successContentMobile
        }`}
      >
        <img src={"/assets/icon/warning-icon.svg"} alt="Aviso" />
        <div className={styles.successText}>
          <span>Existe cashless virtual ativo vinculado a permuta. Não é possível excluir.</span>
        </div>
      </div>
    </>
  );
};

export default DeleteValidation;
