import { Button, Icon } from "@material-ui/core";

import React, { FC, useCallback, useState } from "react";
import ProductFilter from "./components/productFilters/ProductFilter";
import ProductList from "./components/productList/ProductList";
import styles from "./ProductPage.module.scss";
import UseProductActions from "./UseProductActions";
import UseProductPage from "./UseProductPage";
import { DeleteProductsModal } from "./components/deleteProductsModal/DeleteProductsModal";
import { ChangePrinterModal } from "./components/changePrinterModal/ChangePrinterModal";
import { ChangeStockControlModal } from "./components/changeStockControlModal/ChangeStockControlModal";
import { ChangeStoreModal } from "./components/changeStoreModal/ChangeStoreModal";
import { ChangeTaxDataModal } from "./components/changeTaxDataModal/ChangeTaxDataModal";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { ImportDrawer } from "./components/ImportDrawer/importDrawer";

import { EnableDisableAllModal } from "./components/enableDisableAllModal/_EnableDisableAllModal";
import { ProductContextProvider } from "./context/ProductPageContext";
import { VerticalAlignBottom, VerticalAlignTop } from "@mui/icons-material";
import { ExportSideSheet } from "./components/exportSideSheet/ExportSideSheet";
import { AddCircle } from "@material-ui/icons";

export interface IProductPageProps {
  //propertys
}


const ProductsPage: FC = () => {
  return (
    <ProductContextProvider>
      <ProductPageContent />
    </ProductContextProvider>
  );
};


const ProductPageContent: FC = () => {
  const {
    onChangePagination,
    isLoading,
    filter,
    productList,
    onClickEdit,
    onClickDelete,
    onChangeEnabled,
    onClickAdd,
    onClickCopy,
    getData,
    getExportList,
    setFilterExport,
    exportSideSheet,
    setExportSideSheet,
    isLoadingExport,
    localConfiguration
  } = UseProductPage();

  const { hasAccessRole } = useAuth();

  const [importDrawer, setImportDrawer] = useState<boolean>(false);
  
  const [exportType, setExportType] = useState<string>()



  const changeSelectExportType = useCallback((exportType: string) => {
    setExportType(exportType);
  }, []);

  const openImportDrawer = useCallback(() => {
    setImportDrawer(true);
  }, []);

  const closeImportDrawer = useCallback(() => {
    setImportDrawer(false);
  }, []);

  const openExportSideSheet = useCallback(() => {
    setExportSideSheet(true);
  }, [setExportSideSheet]);

  const closeExportSideSheet = useCallback(() => {
    setExportSideSheet(false);
    setExportType('')
  }, [setExportSideSheet]);  

  const {
    onClickAction,
    changePrinterModalOpen,
    changeStockControlModalOpen,
    changeStoreModalOpen,
    changeTaxDataModalOpen,
    deleteProductsModalOpen,
    onCloseModals,
    onChangePrinter,
    onChangeStore,
    onChangeStockControl,
    onChangeTaxData,
    onDeleteProducts,
    printers,
    stores,
  } = UseProductActions(getData, filter);

  return (
    <div id={styles.ProductPage}>
      {/* <h2>Produtos</h2> */}
      <div className={styles.actionsRow}>
        {hasAccessRole("AdicionarProdutoPortal") && (
          <div className={styles.contentButtonAdd}>
            <Button
              onClick={onClickAdd}
              variant={"contained"}
              color={"primary"}
            >
              Adicionar produto  <AddCircle />
            </Button>
          </div>
        )}
        <div className={styles.containerButton}>
          {hasAccessRole("ExportarProdutoPortal") && (
            <div className={styles.contentButton}>
              <Button
                onClick={openExportSideSheet}
                variant="text"
                color={"secondary"}
              >
                <span> Exportar </span> <VerticalAlignBottom />
              </Button>
            </div>
          )}
          {hasAccessRole("AdicionarProdutoPortal") && (
            <div className={styles.contentButton}>
              <Button
                onClick={openImportDrawer}
                variant="text"
                color={"secondary"}
              >
                <span> Importar </span> <VerticalAlignTop />
              </Button>
            </div>
          )}
        </div>
      </div>
      <ProductFilter
        setFilterExport={setFilterExport}
      />
      <div className={styles.container}>
        <ProductList
          productList={productList}
          isLoading={isLoading}
          pagination={filter}
          onChangePage={onChangePagination}
          onClickEdit={onClickEdit}
          onChangeEnabled={onChangeEnabled}
          onClickDelete={onClickDelete}
          onClickActionWithProducts={onClickAction}
          onClickCopy={onClickCopy}
        />
      </div>

      <ChangePrinterModal
        open={changePrinterModalOpen}
        onClose={onCloseModals}
        title="Alterar impressora"
        printers={printers}
        onConfirm={onChangePrinter}
      />
      <ChangeStockControlModal
        open={changeStockControlModalOpen}
        onClose={onCloseModals}
        title="Alterar controle de estoque"
        onConfirm={onChangeStockControl}
      />
      <ChangeStoreModal
        open={changeStoreModalOpen}
        onClose={onCloseModals}
        title="Alterar loja"
        stores={stores}
        onConfirm={onChangeStore}
      />
      <ChangeTaxDataModal
        open={changeTaxDataModalOpen}
        onClose={onCloseModals}
        title="Alterar dados fiscais"
        onConfirm={onChangeTaxData}
      />
      <DeleteProductsModal
        open={deleteProductsModalOpen}
        onClose={onCloseModals}
        title="Excluir produtos?"
        onConfirm={onDeleteProducts}
      />
      <ExportSideSheet
        open={exportSideSheet}
        onClose={closeExportSideSheet}
        title="Exportar"
        exportType={exportType}
        onConfirm={() => getExportList(exportType)}
        changeSelectExportType={changeSelectExportType}
        isLoadingExport={isLoadingExport}
        localConfiguration={localConfiguration}
      />
      <ImportDrawer
        open={importDrawer}
        onClose={closeImportDrawer}
        getData={() => { getData(filter) }}
      />
      <EnableDisableAllModal />
    </div>
  );
};
export default ProductsPage;
