import { IPromotersResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IAccessService } from '../../interfaces/IAccessService';

const GetPromotersFromListUseCase = async (
  accessService: IAccessService,
  listId: string,
  localId: string,
): Promise<IPromotersResponse[]> => {
  return await accessService.getPromotersFromList(listId, localId);
};

export default GetPromotersFromListUseCase;
