import { FC } from 'react'
import styles from "./ForgetPasswordPage.module.scss";
import { TextField } from '@material-ui/core'
import { UseForgetPasswordPage } from './UseForgetPasswordPage'
import SaasPublicLayout from '../SaasPublicLayout'
import Button from 'components/ui/Button/Button'
import { CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { IForgetPassSchema, forgetPassSchema } from 'modules/saas/domain/schema/forgetPassSchema'
import { zodResolver } from '@hookform/resolvers/zod'

export interface IRegisterPageProps {
    //propertys
}

const ForgetPasswordPage: FC<IRegisterPageProps> = () => {
    const {
        isLoading,
        type,
        backgroundColorTop,
        backgroundColorBottom,
        handleOnBack,
        handleRecoverPass,
    } = UseForgetPasswordPage();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IForgetPassSchema>({
        resolver: zodResolver(forgetPassSchema)
    });

    return (
        <SaasPublicLayout backgroundColorTop={backgroundColorTop} backgroundColorBottom={backgroundColorBottom}>
            <div className={styles.content}>
                {type === "5" && <img src="/assets/icon/restaurant-icon.svg" alt=""/>}
                <h1 style={{ marginBottom: "10px" }}>Use seu email ou CPF para continuar:</h1>
                <form
                    onSubmit={handleSubmit(handleRecoverPass)}
                >
                    <div className={styles.column}>
                        <label htmlFor="email" className={styles.required}>E-mail ou CPF</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Digite seu email ou CPF"
                            {...register("emailOrDocument")}
                            helperText={errors.emailOrDocument?.message}
                            error={!!errors.emailOrDocument}
                            disabled={isLoading}
                        />
                    </div>

                    <Button type="submit" style={{ backgroundColor: backgroundColorTop }}>
                        {isLoading ? <CircularProgress size={16} color="inherit" /> : "Recuperar"}
                    </Button>
                    <Button
                        type="button"
                        style={{ color: backgroundColorTop }}
                        variant="text"
                        onClick={handleOnBack}
                    >
                        Voltar
                    </Button>
                </form>
            </div>
        </SaasPublicLayout>
    )
}
export default ForgetPasswordPage