

export enum OnBoardingSteps {
      // Description: "Bem-vindo ao Portal Meep!"
      Welcome = 1,
      // Description: "Vender Agora - Esse é seu terminal de vendas"
      SellNow = 2,
      // Description: "Escolha lançar na mesa ou balcão"
      ChooseTableOrCounter = 3,
      // Description: "Preencha informações de quem está na mesa"
      CustomerInformationForTable = 4,
      // Description: "Selecione os itens"
      SelectItemsTable = 5,
      // Description: "Envie o pedido para cozinha"
      SendOrderToKitchenTable = 6,
      // Description: "Efetue o pagamento"
      MakePayment = 7,
      // Description: "Parabéns! Você realizou um pedido!"
      CongratulationsOrderPlacedTable = 8,
      // Description: "Aqui ficam seus pedidos"
      OrdersPlacedHere = 9,
      //Description: Ao enviar o pedido
      FinishTutorials = 10,
      OnSendOrder = 11,
      OnSendOrderTicket = 14,
      SelectItemsTicket = 12,
      SendOrderToKitchenTicket = 13,
      CongratulationsOrderPlacedTicket = 15,
      Cielo = 16,
      //adicionar ao terminal
      DevicePosConfig = 17,
      DevicePosAdd = 18,
     
}
