import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import DeleteServiceScheduleUseCase from 'modules/schedule/application/useCases/serviceSchedule/DeleteServiceScheduleUseCase';
import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ServicesScheduleApi } from 'services/api/schedule/service/ServiceScheduleApi';
import { IServiceProductCard } from './interfaces/IServiceProductCard';

export const UseServiceProductCard = (refreshList: (currentLocal: string) => void) => {

    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModalRemoveService, setToggleModalRemoveService] = useState(false);

    const { showLoading, hideLoading, toast } = useUi();
    const { currentLocal } = useLocal();


    const { push } = useHistory();

    const handleModal = () => {
        setToggleModal(!toggleModal)
    };

    const handleModalRemoveService = () => {
        setToggleModalRemoveService(!toggleModalRemoveService)
    }

    const deleteService = useCallback((serviceId: string) => {
        showLoading()
        DeleteServiceScheduleUseCase(serviceId, ServicesScheduleApi).then((response) => {
            toast("Serviço removido com sucesso!", "success");
            if (currentLocal) {
                refreshList(currentLocal.id);
            }
        }).catch((error) => {
            toast("Algo deu errado, tente novamente!", "error");
        }).finally(() => {
            setToggleModalRemoveService(false);
            hideLoading();
        });
    }, []);


    const onClickServiceHandle =
        (serviceId: string) => {
            push(`/private/agendamento/servicos/editar/${serviceId}`)
        }



    return (
        {
            handleModal,
            toggleModal,
            onClickServiceHandle,
            toggleModalRemoveService,
            handleModalRemoveService,
            deleteService
        }
    )
}
