import { ICashlessVoucherRequest, IRechargeVoucherRequest } from 'modules/events/domain/dto/IEventsResponse';
import { ICashlessVoucerApi } from '../../interfaces/ICashlessVoucherService';

const RechargeCashlessVoucherUseCase = async (
  cashlessVoucherService: ICashlessVoucerApi,
  cashlessVoucher: IRechargeVoucherRequest,
): Promise<void> => {
  return await cashlessVoucherService.rechargeVoucher(cashlessVoucher);
};

export default RechargeCashlessVoucherUseCase;