import { IAddSimplePosService } from "modules/salesPos/domain/interface/IAddSimplePosService";
import { IGetSimplePosResponse } from "modules/salesPos/domain/dto/IGetSimplePosResponse";
import { IPostAddSimplePosRequest } from "modules/salesPos/domain/dto/IPostAddSimplePosRequest";
import { IGetAllSimplePosWithDeviceRes } from "modules/salesPos/domain/dto/IGetAllSimplePosWithDeviceRes";
import { IPutEditSimplePosRequest } from "modules/salesPos/domain/dto/IPutEditSimplePosRequest";
import { deviceConfigApi } from "../equipamento/DeviceConfigApi";
import { IPutEditSimplePosResponse } from "modules/salesPos/domain/dto/IPutEditSimplePosResponse";

const api = deviceConfigApi;

const AddSimplePosApi = (): IAddSimplePosService => {
  const getSimplePos = async (localId: string): Promise<IGetSimplePosResponse> => {
    const response = await api.get<IGetSimplePosResponse>(`/locals/${localId}/simplePos`, {
      params: { disableError: true }
    });
    return response.data;
  }

  const postAddSimplePos = async (localId: string, request: IPostAddSimplePosRequest): Promise<void> => {
      const response = await api.post<void>(`/locals/${localId}/simplePos`, request, { params: { disableError: true } });
      return response.data;
  }

  const deleteSimplePos = async (localId: string, simplePosId: string): Promise<void> => {
    const response = await api.delete<void>(`/locals/${localId}/simplePos/${simplePosId}`, { params: { disableError: true } });
    return response.data;
  }

  const getAllSimplePosWithDevice = async (localId: string): Promise<IGetAllSimplePosWithDeviceRes> => {
    const response = await api.get<IGetAllSimplePosWithDeviceRes>(`/locals/${localId}/simplePos/device`, {
      params: { disableError: true }
    });
    return response.data;
  }

  const putEditSimplePos = async (localId: string, simplePosId: string, request: IPutEditSimplePosRequest): Promise<IPutEditSimplePosResponse> => {
    const response = await api.put<IPutEditSimplePosResponse>(`/locals/${localId}/simplePos/${simplePosId}`, request, {
      params: { disableError: true }
    });
    return response.data;
  }

  return { getSimplePos, postAddSimplePos, deleteSimplePos, getAllSimplePosWithDevice, putEditSimplePos };
}

export default AddSimplePosApi;
