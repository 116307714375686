import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import styles from "./Signup.module.scss";
import LayoutAuth from "../../components/layoutAuth/LayoutAuth";
import UseDimension from "components/dimension/UseDimension";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { UseSignupPage } from "./UseSignupPage";
import ShowPassword from "../../components/showPassword/ShowPassword";
import { useState } from "react";
import InputMasked from "components/inputMasked/InputMasked";

const Signup = () => {
  const {
    values,
    submitHandle,
    changeHandle,
    goBackLogin,
    errors,
    setErrors
  } = UseSignupPage();
  const { isMobile } = UseDimension();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <LayoutAuth>
      <div id={styles.ForgotPassword}>
        <div className={styles.container}>
          {isMobile && (
            <img
              src="/assets/img/meep-primary.svg"
              alt=""
              className={styles.logoMobile}
            />
          )}
          <div className={styles.containerTitle}>
            <KeyboardArrowLeftRounded
              onClick={goBackLogin}
              style={{ cursor: "pointer" }}
            />
            <span>Cadastre-se</span>
          </div>
          <p>Explore um mundo de possibilidades para o seu negócio:</p>
          <form className={styles.form} onSubmit={(ev) => submitHandle(ev)}>
            <div className={styles.input}>
              <label htmlFor="nome" className={styles.required}>
                Nome
              </label>
              <Input
                name={"nome"}
                variant={"outlined"}
                value={values.nome}
                placeholder="Nome e sobrenome"
                onChange={(ev) => {
                  changeHandle(ev);
                  setErrors((prev) => ({ ...prev, nome: undefined }));
                }}
                error={!!errors.nome}
                helperText={errors.nome}
              />
            </div>
            <div className={`${styles.input} ${styles.inputMasked}`}>
              <label htmlFor="cpf" className={styles.required}>
                CPF
              </label>
              <InputMasked
                name={"cpf"}
                variant={"outlined"}
                value={values.cpf}
                mask="999.999.999-99"
                onChange={(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  changeHandle(ev);
                  setErrors((prev) => ({ ...prev, cpf: undefined }));
                }}
                error={!!errors.cpf}
                helperText={errors.cpf}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor="email" className={styles.required}>
                E-mail
              </label>
              <Input
                name={"email"}
                variant={"outlined"}
                value={values.email}
                placeholder="email@email.com"
                onChange={(ev) => {
                  changeHandle(ev);
                  setErrors((prev) => ({ ...prev, email: undefined }));
                }}
                error={!!errors.email}
                helperText={errors.email}
              />
            </div>
            <div
              className={styles.inputFlex}
              style={{ flexDirection: isMobile ? "column" : "row" }}
            >
              <div className={styles.input}>
                <label htmlFor="senha" className={styles.required}>
                  Senha
                </label>
                <Input
                  name={"senha"}
                  type={showPassword ? "text" : "password"}
                  variant={"outlined"}
                  value={values.senha}
                  placeholder="******"
                  onChange={(ev) => {
                    changeHandle(ev);
                    setErrors((prev) => ({ ...prev, senha: undefined }));
                  }}
                  error={!!errors.senha}
                  helperText={errors.senha}
                />
                <ShowPassword
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </div>
              <div className={styles.input}>
                <label htmlFor="confirmarSenha" className={styles.required}>
                  Confirmar senha
                </label>
                <Input
                  name={"confirmarSenha"}
                  type={showConfirmPassword ? "text" : "password"}
                  variant={"outlined"}
                  value={values.confirmarSenha}
                  placeholder="******"
                  onChange={(ev) => {
                    changeHandle(ev);
                    setErrors((prev) => ({ ...prev, confirmarSenha: undefined }));
                  }}
                  error={!!errors.confirmarSenha}
                  helperText={errors.confirmarSenha}
                />
                <ShowPassword
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button type={"submit"}>Cadastrar</Button>
              </div>
              <div className={styles.button}>
                {!isMobile ? (
                  <Button variant={"outlined"} onClick={goBackLogin}>
                    Voltar
                  </Button>
                ) : (
                  <span onClick={goBackLogin} className={styles.backButton}>
                    Voltar
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutAuth>
  );
};
export default Signup;
