import { IPromoters } from "modules/events/presentation/components/guestListForm/interfaces/IPromoters";
import { IBaseList } from "../../interfaces/IEventGuestsListsPage";

export interface IEditGuestListForm {
  id: string;
  name: string;
  description: string;
  closingDateTime: string;
  isActive: boolean;
  mainPromoter: IPromoters;
  promotersWithAccess: IPromoters[];
  useDiscountLimit: boolean;
  discountLimit?: number;
  baseList?: IBaseList;
}

export enum GuestListFormStep {
  DETAILS = 0,
  PROMOTERS = 1,
  DISCOUNTS = 2,
  URL = 3,
  SUBMIT
}

export enum UseDiscountLimit {
  NÃO = 0,
  SIM = 1
}