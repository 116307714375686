import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { FC, useEffect } from "react";
import ExtratoPage from "modules/contaDigital/presentation/pages/extrato/ExtratoPage";

const Extrato: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Conta Digital",
      },
      {
        label: "Conta Digital - Extrato",
        title: "Extrato",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
     <ExtratoPage/>
    </Layout>
  );
};
export default Extrato;
