import { FC, ReactNode } from "react";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import styles from "./indicatorValue.module.scss";

type DashboardIndicatorProps = {
  value?: number | string;
  label: string | ReactNode;
  formatValue?: string;
  tooltip?: {
    title: string;
    text: string;
  };
};

const IndicatorValue: FC<DashboardIndicatorProps> = ({
  value,
  label,
  tooltip,
}) => {
  return (
    <div id={styles.DashboardIndicator} className={styles.container}>
      <span>{value}</span>
      <div>
        <span>{label}</span>
        {tooltip && <Tooltip title={tooltip.title} text={tooltip.text} />}
      </div>
    </div>
  );
};

export { IndicatorValue };
