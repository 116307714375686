import React, { FC } from 'react'
import styles from './AlertButton.module.scss'
import sucessIcon from './sucessIcon.svg'
import warningIcon from './warningIcon.svg'

export interface IAlertButtonProps {
    //propertys
    label: string;
    type: "sucess" | "warnning"
}
const AlertButton: FC<IAlertButtonProps> = ({ label, type }) => {
    return (
        <div id={styles.AlertButton} >
            <img
                width={60}
                height={60}
                src={type === "sucess" ? sucessIcon : type === "warnning" ? warningIcon : undefined}
                alt={"Icone"}
            />
            <div>{label}</div>
        </div>
    )
}
export default AlertButton