import { AxiosInstance } from "axios"
import { ISaveTableRequest, ITableItemResponse } from "modules/operators/domain/dtos/ITableItemResponse";


const TableAnticorruption = (api: AxiosInstance) => {
    const getTableList = async (localId: string): Promise<ITableItemResponse[]> => {
        const response = await api.get<MesaResponse[]>(`/comandaseletronicas?localClienteId=${localId}`);
        return toTable(response.data);
    }
    const addTable = (localId: string, table: ISaveTableRequest): Promise<void> => {
        const request = {
            ProprietarioId: localId,
            Numero: table.number,
            Status: table.status
        };
        return api.post(`/comandaseletronicas`, request, { params: { disableError: true } });
    }
    const editTable = (localId: string, table: ISaveTableRequest): Promise<void> => {
        const request = {
            ProprietarioId: localId,
            Id: table.id,
            Numero: table.number,
            Status: table.status
        };
        return api.post(`/comandaseletronicas`, request, { params: { disableError: true } });
    }
    const deleteTable = (localId: string, id: string): Promise<void> => {
        return api.delete(`/comandaseletronicas/${id}`, { params: { disableError: true } });
    }
    const changeStatusTable = (localId: string, table: ISaveTableRequest, status: number): Promise<void> => {
        const request = [{
            Id: table.id,
            Numero: table.number,
            Status: status,
            ProprietarioId: localId
        }]
        return api.post(`/comandaseletronicas/ModificarStatus`, request);
    }
    return ({
        getTableList,
        addTable,
        editTable,
        changeStatusTable,
        deleteTable
    })
}
export default TableAnticorruption


interface MesaResponse {
    Id: string;
    Numero: string;
    Status: number;
}

function toTable(response: MesaResponse[]): ITableItemResponse[] | PromiseLike<ITableItemResponse[]> {
    return response.map(item => {
        return {
            id: item.Id,
            number: item.Numero,
            status: item.Status
        };
    });
}

