import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import {
  ICloseEvent,
  PendingSystemTypeEnum,
  ICloseSystemTypesResum,
} from "../../ICloseEvent";
import { CloseTitle } from "../closeTitle/CloseTitle";
import { SettlePending } from "./components/settlePending/SettlePending";
import styles from "./SystemTypeResum.module.scss";
import UseSystemTypeResume from "./UseSystemTypeResume";

interface ISystemTypeResumProps {
  event: ICloseEvent;
  isExport: boolean;
  breakSystemType: "last" | "second" | null;
  refreshEvent: () => Promise<void>;
}

export const SystemTypeResum: FC<ISystemTypeResumProps> = ({
  event,
  isExport,
  breakSystemType,
  refreshEvent,
}) => {
  const {
    handleCloseModal,
    openModal,
    modalTitle,
    handlePendingType,
    handleClosePending,
    pendingItems,
    onChangePedingCard,
    errors,
  } = UseSystemTypeResume(event, refreshEvent);

  return (
    <div id={styles.SystemTypeResum}>
      <CloseTitle
        isExport={isExport}
        title="Fechamento por tipo de sistema"
        subTitle="Informações de acordo com os sistemas utilizados durante seu evento."
      />
      <div className={styles.typeCard}>
        <h2>Pré-pago Cashless</h2>
        <div className={isExport ? styles.export : ""}>
          <div>
            <h3>
              {moneyMaskNumber(event.eventSystemTypes?.prePaidCashless?.total)}
            </h3>
            <p>Valor total recarregado</p>
            <h5>
              <span className={styles.description}>Valor residual: </span>
              {`${moneyMaskNumber(
                event.eventSystemTypes?.prePaidCashless?.serviceTax
              )}`}
            </h5>
            <p>
              {`Consumido: ${moneyMaskNumber(
                event.eventSystemTypes?.prePaidCashless?.consumed
              )}`}
            </p>
          </div>
          <div>
            <h4>
              {moneyMaskNumber(
                event.eventSystemTypes?.prePaidCashless?.averageRecharge
              )}
            </h4>
            <p>Ticket médio de recarga</p>
            <h4>
              {moneyMaskNumber(
                event.eventSystemTypes?.prePaidCashless?.averageConsumption
              )}
            </h4>
            <p>Ticket médio de consumo</p>
          </div>
          <div>
            <h4>{event.eventSystemTypes?.prePaidCashless?.activeCard ?? 0}</h4>
            <p>Cartões ativados</p>
            <h4
              className={
                event.eventSystemTypes?.prePaidCashless?.activeCard
                  ? styles.red
                  : ""
              }
            >
              {event.eventSystemTypes?.prePaidCashless?.pendingCards ?? 0}
            </h4>
            <p
              className={
                event.eventSystemTypes?.prePaidCashless?.pendingCards
                  ? styles.red
                  : ""
              }
            >
              Cartões em aberto
            </p>
          </div>
          <div>
            {!isExport && (
              <Button
                onClick={() =>
                  handlePendingType(PendingSystemTypeEnum.prePaidCashless)
                }
                variant="outlined"
                fullWidth={false}
                disabled={
                  !!!event.eventSystemTypes?.prePaidCashless?.pendingCards
                }
              >
                Regularizar
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.typeCard}>
        <h2>Pós-pago comanda individual</h2>
        <div className={isExport ? styles.export : ""}>
          <div>
            <h3>
              {moneyMaskNumber(event.eventSystemTypes?.posPaidCashless?.total)}
            </h3>
            <p>Valor total faturado</p>
            <h5>{`Taxa de serviço: ${moneyMaskNumber(
              event.eventSystemTypes?.posPaidCashless?.serviceTax
            )}`}</h5>
            <p>{`Consumido: ${moneyMaskNumber(
              event.eventSystemTypes?.posPaidCashless?.consumed
            )}`}</p>
          </div>
          <div>
            <h4>
              {moneyMaskNumber(
                event.eventSystemTypes?.posPaidCashless?.averageConsumption
              )}
            </h4>
            <p>Ticket médio faturado</p>
            <h4>{event.eventSystemTypes?.posPaidCashless?.activeCards ?? 0}</h4>
            <p>Cartões ativados</p>
          </div>
          <div>
            <h4
              className={
                event.eventSystemTypes?.posPaidCashless?.pendingCards
                  ? styles.red
                  : ""
              }
            >
              {event.eventSystemTypes?.posPaidCashless?.pendingCards ?? 0}
            </h4>
            <p
              className={
                event.eventSystemTypes?.posPaidCashless?.pendingCards
                  ? styles.red
                  : ""
              }
            >
              Cartões em aberto
            </p>
            <h4
              className={
                event.eventSystemTypes?.posPaidCashless
                  ?.pendingConsumptionAmount
                  ? styles.red
                  : ""
              }
            >
              {moneyMaskNumber(
                event.eventSystemTypes?.posPaidCashless
                  ?.pendingConsumptionAmount
              )}
            </h4>
            <p
              className={
                event.eventSystemTypes?.posPaidCashless
                  ?.pendingConsumptionAmount
                  ? styles.red
                  : ""
              }
            >
              Consumo em aberto
            </p>
          </div>
          <div>
            {!isExport && (
              <Button
                onClick={() =>
                  handlePendingType(PendingSystemTypeEnum.posPaidCashless)
                }
                variant="outlined"
                disabled={
                  !!!event.eventSystemTypes?.posPaidCashless?.pendingCards
                }
                fullWidth={false}
              >
                Regularizar
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.typeCard} ${
          breakSystemType === "second" ? styles.pageBreakSecond : ""
        }`}
      >
        <h2>Pós-pago Compartilhada</h2>
        <div className={isExport ? styles.export : ""}>
          <div>
            <h3>
              {moneyMaskNumber(event.eventSystemTypes?.posPaidShared?.total)}
            </h3>
            <p>Valor total faturado</p>
            <h5>{`Taxa de serviço: ${moneyMaskNumber(
              event.eventSystemTypes?.posPaidShared?.serviceTax
            )}`}</h5>
            <p>{`Consumido: ${moneyMaskNumber(
              event.eventSystemTypes?.posPaidShared?.consumed
            )}`}</p>
          </div>
          <div>
            <h4>
              {moneyMaskNumber(
                event.eventSystemTypes?.posPaidShared?.averageConsumption
              )}
            </h4>
            <p>Ticket médio faturado</p>
            <h4>{event.eventSystemTypes?.posPaidShared?.commandCount ?? 0}</h4>
            <p>Total de contas</p>
          </div>
          <div>
            <h4
              className={
                event.eventSystemTypes?.posPaidShared?.pendingCommand
                  ? styles.red
                  : ""
              }
            >
              {event.eventSystemTypes?.posPaidShared?.pendingCommand ?? 0}
            </h4>
            <p
              className={
                event.eventSystemTypes?.posPaidShared?.pendingCommand
                  ? styles.red
                  : ""
              }
            >
              Contas em aberto
            </p>
            <h4
              className={
                event.eventSystemTypes?.posPaidShared?.pendingConsumptionAmount
                  ? styles.red
                  : ""
              }
            >
              {moneyMaskNumber(
                event.eventSystemTypes?.posPaidShared?.pendingConsumptionAmount
              )}
            </h4>
            <p
              className={
                event.eventSystemTypes?.posPaidShared?.pendingConsumptionAmount
                  ? styles.red
                  : ""
              }
            >
              Consumo em aberto
            </p>
          </div>
          <div>
            {!isExport && (
              <Button
                onClick={() =>
                  handlePendingType(PendingSystemTypeEnum.posPaidShared)
                }
                variant="outlined"
                disabled={
                  !!!event.eventSystemTypes?.posPaidShared?.pendingItems?.length
                }
                fullWidth={false}
              >
                Regularizar
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.typeCard} ${
          breakSystemType === "last" ? styles.pageBreak : ""
        }`}
      >
        <h2>Pré-pago Ficha</h2>
        <div className={isExport ? styles.export : ""}>
          <div>
            <h3>
              {moneyMaskNumber(event.eventSystemTypes?.prePaidChip?.total)}
            </h3>
            <p>Valor total faturado</p>
            <h5>{`Taxa de serviço: ${moneyMaskNumber(
              event.eventSystemTypes?.prePaidChip?.serviceTax
            )}`}</h5>
            <p>{`Consumido: ${moneyMaskNumber(
              event.eventSystemTypes?.prePaidChip?.consumed
            )}`}</p>
          </div>
          <div>
            <h4>
              {moneyMaskNumber(
                event.eventSystemTypes?.prePaidChip?.averageConsumption
              )}
            </h4>
            <p>Ticket médio faturado</p>
            <h4>{event.eventSystemTypes?.prePaidChip?.chipAmount}</h4>
            <p>Fichas emitidas</p>
          </div>
          <div>
            {/* <h4>40</h4>
            <p>Contas em aberto</p>
            <h4>R$1.5000,00</h4>
            <p>Consumo em aberto</p> */}
          </div>
          <div>
            {/* <Button onClick={() => handleModalContent('')} variant='outlined' fullWidth={false}>Regularizar</Button> */}
          </div>
        </div>
      </div>
      {pendingItems && (
        <RightDrawer
          title={modalTitle}
          open={openModal}
          onClose={handleCloseModal}
          className={styles.modal}
        >
          <SettlePending
            handleCloseModal={handleCloseModal}
            errors={errors}
            closePending={handleClosePending}
            pendingItems={pendingItems}
            onChangePedingCard={onChangePedingCard}
          />
        </RightDrawer>
      )}
    </div>
  );
};
