
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import { DeleteCouponUseCase } from "../../application/useCases/DeleteCouponUseCase";


const UseDeleteCoupon = () => {

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()

  const deleteCoupon = useCallback(
    async (couponId: string) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoadingDelete(true);
        if (currentLocal) {
          const response = await DeleteCouponUseCase(serviceDiscountCoupons,
            couponId,
            currentLocal.id,
          );
          toast("Cupom deletado com sucesso", "success")          
          return response
        }
      } finally {
        setIsLoadingDelete(false);
      }
    },
    [currentLocal, toast]
  );

  return {
    isLoadingDelete,
    deleteCoupon,
  };
};

export default UseDeleteCoupon;
