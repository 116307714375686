import { useCallback, useEffect, useState } from 'react'
export interface IUseDimensionProps {
    //propertys
}
const UseDimension = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [dimensions, setDimension] = useState({ width: 0, height: 0 });
    const [fullscreen, setFullscreen] = useState(false);


    const resizeEvent = useCallback(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setDimension({ width, height })
        if (width <= 1000) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        if (window.innerHeight === window.screen.height) {
            setFullscreen(true);
        } else {
            setFullscreen(false);
        }
    }, [])

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        resizeEvent();
        window.addEventListener("resize", resizeEvent);
        return () => {
            window.removeEventListener("resize", resizeEvent)
        }
    }, [resizeEvent])

    return (
        {
            isMobile,
            dimensions,
            fullscreen
        }
    )
}
export default UseDimension