import Layout from "components/layout/presentation/Layout";
import { CustomFieldPage } from "modules/schedule/presentation/pages/customField/CustomFieldPage";
import React from "react";

const CustomField = () => {
  return (
    <Layout>
      <CustomFieldPage />
    </Layout>
  );
};

export default CustomField;
