import React, { FC } from 'react'
import styles from './CatalogSkeleton.module.scss'
import ProductListItemSkeleton from '../../categoryAndProducts/productList/ProductListItemSkeleton'
import { Skeleton } from '@mui/material'
export interface ICatalogSkeletonProps {
    //propertys
}
const CatalogSkeleton: FC<ICatalogSkeletonProps> = () => {
    return (<div id={styles.catalogSkeleton}>
        <div className={styles.header}>
            <Skeleton variant="rectangular" className={styles.action}  height={20}  width={15} />
            <Skeleton variant="text" className={styles.title} width={`100%`} height={50} />
            <Skeleton variant="circular" className={styles.action}  height={30}  width={30} />
            <Skeleton variant="circular" className={styles.action}  height={30}  width={30} />
            <Skeleton variant="circular" className={styles.action}  height={30}  width={30} />
        </div>
        <div className={styles.products}>
        <ProductListItemSkeleton />
        <Skeleton variant="rectangular" width={`100%`} height={30} />
        <ProductListItemSkeleton />
        </div>
    </div>
    )
}
export default CatalogSkeleton