import { IPostConfigDataRequest } from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";
import { create } from "zustand";

interface IConfigValues {
  configValues: IPostConfigDataRequest;
  setConfigValues: (values: IPostConfigDataRequest) => void;
};

const UseConfigValues = create<IConfigValues>(
    (set) => ({
      configValues: {} as IPostConfigDataRequest,
      setConfigValues: (values) => {
        set(() => ({
          configValues: values,
        }));
      },
    })
);

export default UseConfigValues;
