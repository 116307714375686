import { useMemo, useState } from 'react';
import { Icon } from '@material-ui/core';

import styles from './SaasPlanCard.module.scss';
import { ISaasPlan, ISaasPlanViewInfo } from './ISaasPlan';
import RenderIf from 'components/renderIf/RenderIf';
import { SubscriptionType } from 'modules/saas/domain/interfaces/SubscriptionType';

interface ISaasPlanCard extends ISaasPlanViewInfo {
    currentPlanMode: string;
    currentSelectedPlan: string;
    isCurrentPlan: boolean;
    selectFn: (id: string) => void;
}

const SaasPlanCard = ({ planView, isCieloCustomer, currentPlanMode, currentSelectedPlan, isCurrentPlan, selectFn }: {
    planView: ISaasPlan;
    isCieloCustomer: boolean;
    currentPlanMode: SubscriptionType;
    currentSelectedPlan?: ISaasPlan;
    isCurrentPlan: boolean;
    selectFn: (id: ISaasPlan) => void;
}) => {
    const [showAllFeatures, setShowAllFeatures] = useState(false);


    const price = useMemo(() => {
        if (currentPlanMode === SubscriptionType.ANNUAL) {
            return planView.plan.price / 12;
        }
        return planView.plan.price;
    }, [currentPlanMode, planView]);

    return (
        <div className={styles.saasPlanCard} data-current={isCurrentPlan} data-selected={currentSelectedPlan?.plan === planView.plan} onClick={() => selectFn(planView)}>
            <p className={styles.saasPlanTitle}>Mee <span className={styles.highlight}>{planView.name}</span></p>

            <div className={styles.saasPlanFeatureList}>
                <RenderIf condition={!isCieloCustomer}>
                    {planView.features.slice(0, (showAllFeatures ? planView.features.length : 3)).map((feature: string, index: number) => <p key={index} className={styles.saasPlanFeatureItem}>{feature}</p>)}

                    <RenderIf condition={planView.features.length > 3}>
                        <div className={styles.saasPlanFeatureSeeMore} onClick={($event) => {$event.stopPropagation(); setShowAllFeatures(prev => !prev)}}>
                            <span>{showAllFeatures ? 'Ver menos' : 'Ver mais'}</span>
                            <Icon>{showAllFeatures ? 'expand_less' : 'expand_more'}</Icon>
                        </div>
                    </RenderIf>
                </RenderIf>

                <RenderIf condition={isCieloCustomer}>
                    {planView.cieloFeatures.map((feature: string, index: number) => <p key={index} className={styles.saasPlanFeatureItem}>{feature}</p>)}
                </RenderIf>
            </div>

            <div className={styles.saasPlanPriceContainer}>
                <RenderIf condition={planView.plan.price <= 0}>
                    <p style={{ fontSize: '1.5rem' }}>Grátis</p>
                </RenderIf>

                <RenderIf condition={planView.plan.price > 0}>
                    <div style={{ textAlign: 'right' }}>
                        <RenderIf condition={planView.plan.subscriptionType === SubscriptionType.ANNUAL }>
                            <span className={styles.saasPlanInstallments}>12X</span>
                        </RenderIf>
                        <span>R$</span>
                    </div>

                    <p className={styles.saasPlanPrice}>{price}<span style={{ fontSize: '2rem' }}></span></p>

                    <p>/mês</p>
                </RenderIf>
            </div>
        </div>
    );
}

export default SaasPlanCard;