import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IPermutationAccountfilter } from "../components/permutationFilter/interfaces/IPermutationAccountfilter";
import { IPermutationAccountList } from "../interfaces/IPermutationAccountList";
import PermutationApi from "services/api/permutation/PermutationApi";
import GetPermutationUseCase from "modules/permutation/application/useCases/GetPermutationUseCase";

export interface IParamsPemutationAccountList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: IPermutationAccountfilter
}

const UsePermutationAccountList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [permutationAccountList, setPermutationAccountList] = useState<IPermutationAccountList>()
  const { currentLocal } = useLocal();

  const getPermutationAccountList = useCallback(
    async (params?: IParamsPemutationAccountList) => {
      try {
        const servicePermutation = PermutationApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPermutationUseCase(servicePermutation,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );

          setPermutationAccountList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    permutationAccountList,
    isLoading,
    getPermutationAccountList,
    setPermutationAccountList,
  };
};

export default UsePermutationAccountList;
