import { useLocal } from "modules/local/presentation/context/LocalContext"
import { useCallback, useEffect, useState } from "react"
import { GetTrackConfigUseCase } from "modules/survey/application/useCases/GetTrackConfigUseCase"
import trackApi from "services/api/tracksales/SurveyApi"
import CreateCustomerSurveyUseCase from "../../../application/useCases/CreateCustomerSurveyUseCase"
import GetCustomerSurveyUseCase from "../../../application/useCases/GetCustomerSurveyByIdUseCase"
import { GetSurveyListUseCase } from "../../../application/useCases/GetSurveyListUseCase"
import GetWidgetsListUseCase from "../../../application/useCases/GetWidgetsListUseCase"
import UpdateCustomerSurveyUseCase from "../../../application/useCases/UpdateCustomerSurveyByIdUseCase"
import { EventSurvey } from "../../components/EventSurvey"
import { ISurveyList } from "../../interfaces/ISurveyList"
import { GetTrackSurveyListUseCase } from "../../../application/useCases/GetTrackSurveyListUseCase"
import { ITrackSurvey } from "../../interfaces/ITrackSurveyList"
import { EnumSurveySendMethod } from "../../interfaces/EnumSurveySendMethod"

const service = trackApi()

export const UseTrack = () => {
    const [surveyList, setSurveyList] = useState<ISurveyList>()
    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const { currentLocal } = useLocal();

    const getSurveyList = useCallback(() => {
        (async () => {
            try {
                if (currentLocal) {
                    setLoading(true);
                    const response = await GetSurveyListUseCase(service, currentLocal.id)
                    setSurveyList(response)
                }
            } finally {
                setLoading(false)
            }
        })()
    }, [currentLocal])


    useEffect(() => {
        getSurveyList()
        return () => {

        }
    }, [getSurveyList])


    const getWidgetList = useCallback(
        async () => {
            if (currentLocal) {
                const response = await GetWidgetsListUseCase(service, currentLocal.id);
                return response
            }
        },
        [currentLocal],
    )
    const getTrackSurveyList = useCallback(
        async () => {
            if (currentLocal) {
                const response = await GetTrackSurveyListUseCase(service, currentLocal.id);
                return response.data
            }
        },
        [currentLocal],
    )

    const addTrackSurvey = useCallback(async (values: { event: EventSurvey, trackSurvey: ITrackSurvey, sendMethod: EnumSurveySendMethod }) => {
        if (currentLocal) {
            const response = await CreateCustomerSurveyUseCase(service, currentLocal.id, values.event, values.trackSurvey, values.sendMethod);
            getSurveyList()
            return response
        }
    }, [currentLocal, getSurveyList])

    const getSurveyById = useCallback(async (id: string) => {
        if (currentLocal) {
            const response = await GetCustomerSurveyUseCase(service, currentLocal.id, id);
            return response
        }
    }, [currentLocal])

    const updateSurveyWidget = useCallback(async (values: { id: string, event: EventSurvey, trackSurvey: ITrackSurvey, sendMethod: EnumSurveySendMethod }) => {
        if (currentLocal) {
            const response = await UpdateCustomerSurveyUseCase(service, currentLocal.id, values.id, values.event, values.trackSurvey, values.sendMethod);
            getSurveyList()
            return response
        }
    }, [currentLocal, getSurveyList])

    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            if (currentLocal) {
              const response = await GetTrackConfigUseCase(
                service,
                currentLocal?.id
              );
              setEnabled(response.enabled);
            }
          } finally {
            setLoading(false);
          }
        })();
      }, [currentLocal]);

    return {
        loading,
        enabled,
        surveyList,
        getWidgetList,
        addTrackSurvey,
        getSurveyById,
        updateSurveyWidget,
        getTrackSurveyList,
        getSurveyList
    }
}