import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage';
import TabPage from 'pages/private/dashboard/tabPage/TabPage';
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom';


interface IPosPagoMenu {
    label: string;
    tab: string;
    url?: string;
    papel?: string;
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        papel: string;
    }[]
}


export const PosPagoPage = () => {

    const { tab, subTab } = useParams<{ tab: string, subTab: string }>()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const currentTab = posPagoTabs.find(item => tab === item.tab)
        const routers = [{
            label: "Pós-pago",
            title: currentTab?.label
        }]

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.label : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.label
            })
        }

        updateRouters(routers)
        return () => {
            updateRouters([])
        }
    }, [subTab, tab, updateRouters])

    const subTabComponent = useCallback((tabItem: IPosPagoMenu) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/posPago/" + tabItem.tab}
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.papel,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        />
        :
        tabItem.component || <IframePage src={tabItem.url!} />
        , [])
    return (
        <TabPage
            routerPrefix={'/private/posPago'}
            tabsPages={posPagoTabs.map((tabItem) => (
                {
                    label: tabItem.label,
                    tab: tabItem.tab,
                    roles: tabItem.papel,
                    component: subTabComponent(tabItem)
                }
            ))} />
    )
}


const posPagoTabs: IPosPagoMenu[] = [   
    {
        label: "Contas",
        tab: `contasPosPago`,
        url: `/posPago/contas`,
        papel: "ContasPosPagoPortal",
    },

]
