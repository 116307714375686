import Input from "components/ui/input/Input";
import styles from "components/sidesheet/Sidesheet.module.scss";
import { ISankhyaConfigurationRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationRequest";
import { ISankhyaFormErrors } from "modules/config/integrationsConfig/presentation/pages/configSankhya/validade/SankhyaValidation";

export interface IConfigSankhyaIntegrationProps {
  values: ISankhyaConfigurationRequest;
  setValues: React.Dispatch<React.SetStateAction<ISankhyaConfigurationRequest>>;
  errors: ISankhyaFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<ISankhyaFormErrors>>;
}

const ConfigSankhyaIntegration = ({
  values,
  setValues,
  errors,
  setErrors,
}: IConfigSankhyaIntegrationProps) => {
  return (
    <>
      <div>
        <label htmlFor="company-id" className={styles.required}>
          ID da empresa
        </label>
        <Input
          name="company-id"
          variant={"outlined"}
          value={values?.SankhyaCompanyId}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              SankhyaCompanyId: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, SankhyaCompanyId: undefined }));
          }}
          error={!!errors.SankhyaCompanyId}
          helperText={errors.SankhyaCompanyId}
        />
      </div>
      <div>
        <label htmlFor="sankhya-user" className={styles.required}>
          Usuário
        </label>
        <Input
          name="sankhya-user"
          variant={"outlined"}
          value={values?.SankhyaUser}
          onChange={(ev) => {
            setValues((prev) => ({ ...prev, SankhyaUser: ev.target.value }));
            setErrors((prev) => ({ ...prev, SankhyaUser: undefined }));
          }}
          error={!!errors.SankhyaUser}
          helperText={errors.SankhyaUser}
        />
      </div>
      <div>
        <label htmlFor="sankhya-pass" className={styles.required}>
          Senha
        </label>
        <Input
          name="sankhya-pass"
          variant={"outlined"}
          value={values?.SankhyaPass}
          onChange={(ev) => {
            setValues((prev) => ({ ...prev, SankhyaPass: ev.target.value }));
            setErrors((prev) => ({ ...prev, SankhyaPass: undefined }));
          }}
          error={!!errors.SankhyaPass}
          helperText={errors.SankhyaPass}
        />
      </div>
      <div>
        <label htmlFor="validate-ticket-top" className={styles.required}>
          Top vendas tickets
        </label>
        <Input
          name="validate-ticket-top"
          variant={"outlined"}
          value={values?.ValidateTicketTop}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              ValidateTicketTop: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, ValidateTicketTop: undefined }));
          }}
          error={!!errors.ValidateTicketTop}
          helperText={errors.ValidateTicketTop}
        />
      </div>
      <div>
        <label htmlFor="default-seller" className={styles.required}>
          Vendedor Sankhya padrão
        </label>
        <Input
          name="default-seller"
          variant={"outlined"}
          value={values?.DefaultSeller}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              DefaultSeller: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, DefaultSeller: undefined }));
          }}
          error={!!errors.DefaultSeller}
          helperText={errors.DefaultSeller}
        />
      </div>
      <div>
        <label htmlFor="sankhya-cod-nat" className={styles.required}>
          Código natureza
        </label>
        <Input
          name="sankhya-cod-nat"
          variant={"outlined"}
          value={values?.SankhyaCodNat}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              SankhyaCodNat: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, SankhyaCodNat: undefined }));
          }}
          error={!!errors.SankhyaCodNat}
          helperText={errors.SankhyaCodNat}
        />
      </div>
      <div>
        <label htmlFor="sankhya-type-sale-code" className={styles.required}>
          Código tipo de venda
        </label>
        <Input
          name="sankhya-type-sale-code"
          variant={"outlined"}
          value={values?.SankhyaTypeSaleCode}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              SankhyaTypeSaleCode: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, SankhyaTypeSaleCode: undefined }));
          }}
          error={!!errors.SankhyaTypeSaleCode}
          helperText={errors.SankhyaTypeSaleCode}
        />
      </div>
      <div>
        <label htmlFor="protocol" className={styles.required}>
          Protocolo
        </label>
        <Input
          name="protocol"
          variant={"outlined"}
          value={values?.Protocol}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              Protocol: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, Protocol: undefined }));
          }}
          error={!!errors.Protocol}
          helperText={errors.Protocol}
        />
      </div>
      <div>
        <label htmlFor="host" className={styles.required}>
          Host
        </label>
        <Input
          name="host"
          variant={"outlined"}
          value={values?.Host}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              Host: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, Host: undefined }));
          }}
          error={!!errors.Host}
          helperText={errors.Host}
        />
      </div>
      <div>
        <label htmlFor="port" className={styles.required}>
          Porta
        </label>
        <Input
          name="port"
          variant={"outlined"}
          value={values?.Port}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              Port: ev.target.value,
            }));
            setErrors((prev) => ({ ...prev, Port: undefined }));
          }}
          error={!!errors.Port}
          helperText={errors.Port}
        />
      </div>
      <div>
        <label htmlFor="ticket-top-production">Top vendas tickets como forma de pagamento</label>
        <Input
          name="ticket-top-production"
          variant={"outlined"}
          value={values?.TicketTopProduction}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              TicketTopProduction: ev.target.value,
            }));
          }}
        />
      </div>
      <div>
        <label htmlFor="cash-top">Top vendas com GeraNFCE desabilitado</label>
        <Input
          name="cash-top"
          variant={"outlined"}
          value={values?.CashTop}
          onChange={(ev) => {
            setValues((prev) => ({
              ...prev,
              CashTop: ev.target.value,
            }));
          }}
        />
      </div>
    </>
  );
};

export default ConfigSankhyaIntegration;
