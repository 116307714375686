import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import OperatorApi from "services/api/operator/OperatorApi";
import { IOperatorFormValue } from "../components/operatorForm/IOperatorValue";
import GetOperatorListUseCase from "modules/operators/application/useCases/GetOperatorListUseCase";
import AddOperatorUseCase from "modules/operators/application/useCases/AddOperatorUseCase";
import EditOperatorUseCase from "modules/operators/application/useCases/EditOperatorUseCase";
import DeleteOperatorUseCase from "modules/operators/application/useCases/DeleteOperatorUseCase";
import { IOperatorItem } from "modules/operators/domain/models/IOperatorItem";

const operatorService = OperatorApi();

export const UseOperatorFragment = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [operatorList, setOperatorList] = useState<IOperatorItem[]>([]);

    const { currentLocal } = useLocal();

    const getOperators = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                const response = await GetOperatorListUseCase(operatorService, currentLocal.id);
                setOperatorList(response);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const onSubmitAdd = useCallback(async (value: IOperatorFormValue) => {
        if (currentLocal) {
            try {
                setIsLoading(true)
                await AddOperatorUseCase(operatorService, currentLocal.id, value);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const onSubmitEdit = useCallback(async (selectedOperatorToEdit: IOperatorFormValue, value: IOperatorFormValue) => {
        if (currentLocal && selectedOperatorToEdit?.id) {
            try {
                setIsLoading(true);
                await EditOperatorUseCase(operatorService, currentLocal.id, selectedOperatorToEdit.id, value);
                getOperators();
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal, getOperators])

    const onSubmitDelete = useCallback(async (selectedOperatorToDelete: IOperatorItem) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await DeleteOperatorUseCase(operatorService, currentLocal.id, selectedOperatorToDelete.id);
                getOperators();
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal, getOperators]);

    return {
        isLoading,
        operatorList,
        getOperators,
        onSubmitAdd,
        onSubmitEdit,
        onSubmitDelete,
    }
}
