import { Icon } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import styles from "./ColumnDragAndDropSelect.module.scss";
export interface IColumnDragAndDropSelectProps<T = object> {
  //propertys
  columns: {
    hide?: boolean;
    property: keyof T;
    title: string;
  }[];
  onChange: (
    columns: {
      hide?: boolean;
      property: keyof T;
      title: string;
    }[]
  ) => void;
  onClickHide?: (index: number) => void;
}
const ColumnDragAndDropSelect = <T,>({
  columns,
  onChange,
}: IColumnDragAndDropSelectProps<T>) => {
  const reorder = useCallback(
    (
      list: {
        hide?: boolean;
        property: keyof T;
        title: string;
      }[],
      startIndex: number,
      endIndex: number
    ) => {
      const result = list;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newList = reorder(
        columns,
        result.source.index,
        result.destination.index
      );
      onChange?.(newList);
    },
    [columns, onChange, reorder]
  );

  const handleClickHide = useCallback(
    (property: keyof T) => {
      const newColumns = columns.map((item) => {
        return item.property === property
          ? { ...item, hide: !item.hide }
          : item;
      });

      onChange?.(newColumns);
    },
    [columns, onChange]
  );

  return (
    <div id={styles.ColumnDragAndDropSelect}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(providedDroppable: DroppableProvided) => (
            <div
              className={styles.droppable}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              <div className={styles.contentTitle}>
                <p className={styles.title}>Informações</p>
                <p className={styles.title}>Visibilidade</p>
              </div>
              {columns.map((item, index) => (
                <>
                  <Draggable
                    key={index}
                    draggableId={item.title + index}
                    index={index}
                  >
                    {(draggableProvided: DraggableProvided, snapshot) => (
                      <div
                        key={index}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <div className={styles.item}>
                          <div className={styles.start}>
                            <Icon className={styles.iconTitle}>
                              drag_indicator
                            </Icon>
                            {item.title}
                          </div>

                          <Icon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickHide(item.property)}
                          >
                            {item.hide ? "visibility_off" : "visibility_on"}
                          </Icon>
                        </div>
                      </div>
                    )}
                  </Draggable>
                </>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default ColumnDragAndDropSelect;
