import { api } from "../Api";
import { plansUrl } from "Enviroment";
import { ISaasPaymentsService } from "modules/saasPayment/domain/interface/ISaasPaymentsService";
import { IGetAllCardsResponse } from "modules/saasPayment/domain/dto/IGetAllCardsResponse";
import { IPostNewCardRequest } from "modules/saasPayment/domain/dto/IPostNewCardRequest";
import { IGetLocalSubscriptionIdResponse } from "modules/saasPayment/domain/dto/IGetLocalSubscriptionIdResponse";

const baseUrl = plansUrl;

export const SaasPaymentsApi = (): ISaasPaymentsService => {
    const getLocalSubscriptionId = async (localId: string): Promise<IGetLocalSubscriptionIdResponse> => {
        const response = await api.get(`${baseUrl}/paymentSubscriptions/owner/${localId}/active`);
        return response.data;
    }

    const getAllCards = async (subscriptionCardId: string): Promise<IGetAllCardsResponse[]> => {
        const response = await api.get(`${baseUrl}/paymentSubscriptions/${subscriptionCardId}/cards`);
        return response.data;
    }

    const postNewCard = async (subscriptionCardId: string, request: IPostNewCardRequest): Promise<void> => {
        const response = await api.post(`${baseUrl}/paymentSubscriptions/${subscriptionCardId}/cards`, request);
        return response.data;
    }

    const putEditCard = async (cardId: string): Promise<void> => {
        const response = await api.put(`${baseUrl}/paymentSubscriptions/cards/${cardId}/default`, );
        return response.data;
    }

    const deleteCard = async (cardId: string): Promise<void> => {
        const response = await api.delete(`${baseUrl}/paymentSubscriptions/cards/${cardId}`, );
        return response.data;
    }

    return {
        getLocalSubscriptionId,
        getAllCards,
        postNewCard,
        putEditCard,
        deleteCard
    }
}