import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IOperationBalance } from "../interfaces/IOperationBalance";
import GetOperationBalance from "modules/permutation/application/useCases/GetOperationBalance";

const UseOperationBalance = () => {

  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>();
  const [operationBalance, setOperationBalance] = useState<IOperationBalance>()
  const { currentLocal } = useLocal();

  const getOperationBalance = useCallback(
    async (accountId: string) => {
      try {
        const servicePermutation = PermutationApi();
        setIsLoadingEdit(true);
        if (currentLocal) {
          const response = await GetOperationBalance(servicePermutation,
            currentLocal.id,
            accountId
          );

          setOperationBalance(response);

          return response
        }
      } finally {
        setIsLoadingEdit(false);
      }
    },
    [currentLocal]
  );


  return {
    operationBalance,
    isLoadingEdit,
    getOperationBalance,
    setOperationBalance,
  };
};

export default UseOperationBalance;
