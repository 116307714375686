import React, { FC } from 'react';
import styles from './ApiError.module.scss';
import error from '../../../res/assets/images/error.png'

interface IApiErrorProps {
  errorMessage: string;
};

export const ApiError: FC<IApiErrorProps> = ({ errorMessage }) => {
  return (
    <div id={styles.ApiError}>
      <img src={error} alt="empty_img" />
      <h2>Não foi possível exibir as informações.<br/>Por favor, tente novamente mais tarde.</h2>
      <h3>{errorMessage}</h3>
    </div>
  );
};