export interface IPaymentLinkValuesForm {
  productId: string;
  price: string;
  isVariable?: boolean;
  productName?: string;
  pix?: boolean;
  ticket?: boolean;
  credit?: boolean;
  installments?: number;  
  tax?: number;
  showDocumentPix?: boolean;
  showDocumentBoleto?: boolean;
  showNameOrCompanyNamePix?: boolean;
  showNameOrCompanyNameBoleto?: boolean;
  showPhoneNumberPix?: boolean;
  showPhoneNumberBoleto?: boolean;
  showEmailPix?: boolean;
  showEmailBoleto?: boolean;
  showAddressPix?: boolean;
  showAddressBoleto?: boolean;
  shouldChargeFee?: boolean;
  isSinglePayment?: boolean;
  isRecurrenceLimit?: boolean;  
  amountOfCharges?: number;
  billingStartsInCurrentMonth?: boolean;
}

export enum PaymentFormEnum {
  CREDIT = 1,
  PIX = 18,
  TICKET = 9,
}
export interface IPaymentlinkValuesErrors { 
  paymentForm?: string;
  installments?: string;
  tax?: string;
  credit?: string;
}
