import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";

const GetPaymentLinkByIdUseCase = async (
  service: IPaymentLinkService,
  paymentLinkId: string
) => {
  const response = await service.getPaymentLinkById(paymentLinkId);
  return response;
};

export default GetPaymentLinkByIdUseCase;
