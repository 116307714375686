import { IGetBuscarSaldoAtualResponse } from "../../dtos/IGetBuscarSaldoAtualResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetBuscarSaldoAtualUseCase = async (
  service: IMainDashoboardService,
  localId: string,
): Promise<IGetBuscarSaldoAtualResponse> => {
  return await service.getBuscarSaldoAtual(localId);
};

export default GetBuscarSaldoAtualUseCase;
