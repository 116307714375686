import { erpUrl } from "Enviroment";
import { IGetSupplyGroupItemResponse, IGetSupplyGroupListRequest, IGetSupplyGroupListResponse, IGetSupplyGroupResponse, ISaveSupplyGroupRequest, IUpdateSupplyGroupRequest } from "modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyGroupListDtos";
import { IGetSupplyNameRequest, IGetSupplyNameResponse } from "modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos";
import { ISupplyGroupService } from "modules/meepErp/application/interfaces/supply/supplyGroup/ISupplyGroupService"
import { Api } from "services/api/Api"


const api = Api();
const BASE_URL = erpUrl + '/api/Inventory/supplyGroups'

const SupplyGroupService = (): ISupplyGroupService => {
  const getSupplyGroup = async (id: string): Promise<IGetSupplyGroupResponse> => {
    const url = `${BASE_URL}/${id}`;
    const response = await api.get(url);
    return response.data;
  };

  const getSupplyGroupsList = async (request: IGetSupplyGroupListRequest): Promise<IGetSupplyGroupListResponse> => {
    const url = `${BASE_URL}`;
    const response = await api.get(url, { params: request });
    return response.data;
  };

  const createSupplyGroup = async (data: ISaveSupplyGroupRequest): Promise<IGetSupplyGroupItemResponse> => {
    const url = `${BASE_URL}`;
    const response = await api.post(url, data);
    return response.data;
  };

  const updateSupplyGroup = async (data: IUpdateSupplyGroupRequest): Promise<IGetSupplyGroupItemResponse> => {
    const url = `${BASE_URL}/${data.id}`;
    const response = await api.put(url, data);
    return response.data;
  };

  const deleteSupplyGroup = async (id: string): Promise<void> => {
    const url = `${BASE_URL}/${id}`;
    await api.delete(url);
  };

  const enableSupplyGroup = async (id: string): Promise<void> => {
    const url = `${BASE_URL}/${id}/Enable`;
    await api.patch(url);
  };

  const disableSupplyGroup = async (id: string): Promise<void> => {
    const url = `${BASE_URL}/${id}/Disable`;
    await api.patch(url);
  };

  const searchNameSupplyGroup = async (request: IGetSupplyNameRequest): Promise<IGetSupplyNameResponse> => {
    const url = `${BASE_URL}/Search/${request.keyword}`;
    const response = await api.get(url, { params: request });
    return response.data;
  };

  return {
    getSupplyGroup,
    getSupplyGroupsList,
    createSupplyGroup,
    updateSupplyGroup,
    deleteSupplyGroup,
    enableSupplyGroup,
    disableSupplyGroup,
    searchNameSupplyGroup
  };
};

export default SupplyGroupService;
// GET
// /api/Inventory/SupplyGroups

// GET
// /api/Inventory/SupplyGroups

// PUT
// /api/Inventory/SupplyGroups/{id}

// GET
// /api/Inventory/SupplyGroups/{id}

// PATCH
// /api/Inventory/SupplyGroups/{id}/Enable

// PATCH
// /api/Inventory/SupplyGroups/{id}/Disable
