import { ITotalConsumption } from "modules/dashboard/domain/dto/cashFlow/ITotalConsumption";
import { ITotalNotPaid } from "modules/dashboard/domain/dto/cashFlow/ITotalNotPaid";
import { ITotalPaid } from "modules/dashboard/domain/dto/cashFlow/ITotalPaid";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import { ICashFlow } from "modules/dashboard/presentation/components/cashFlow/interfaces/ICashFlow";
import IDashboardService from "../interfaces/IDashboardService";

type PromisesType = [Promise<ITotalPaid>, Promise<ITotalNotPaid>, Promise<ITotalConsumption>];

const GetCashFlowUseCase = async (dashboardService: IDashboardService, filter: IFilterRequest): Promise<ICashFlow> => {
  const promises: PromisesType = [
    dashboardService.getTotalPaid(filter),
    dashboardService.getTotalNotPaid(filter),
    dashboardService.getTotalConsumption(filter)
  ]

  const result = await Promise.all(promises);

  /*
    counterSales: number;
    consumption: number;
    consumptionFee: number;
    consumptionTotal: number;
  */

  const cashFlow: ICashFlow = {
    totalPaid: result[0],
    totalNotPaid: result[1],
    totalConsumption: result[2],
  }

  return cashFlow;
}

export default GetCashFlowUseCase;