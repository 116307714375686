import { FC } from "react";
import { AuthProvider } from "modules/auth/presentation/context/AuthContext";
import SaasForgotPassword from "modules/auth/presentation/page/forgotPassword/SaasForgotPassword";
import ForgotPasswordPage from "modules/auth/presentation/page/forgotPassword/ForgotPasswordPage";

export interface IForgotPasswordProps {
  //propertys
}
const ForgotPassword: FC<IForgotPasswordProps> = () => {
  const baseUrl = window.location.origin;

  return (
    <AuthProvider disableRedirectToLogin>
      {baseUrl.includes("connect.meep") ? <SaasForgotPassword /> : <ForgotPasswordPage />}
    </AuthProvider>
  );
};
export default ForgotPassword;
