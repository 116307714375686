import UseDimension from "components/dimension/UseDimension";
import styles from "./SaasLayoutAuth.module.scss";

export interface ISaasLayoutAuthProps {
  children: JSX.Element;
  isSaas?: boolean;
}

const SaasLayoutAuth = ({ children, isSaas = false }: ISaasLayoutAuthProps) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.SaasLayoutWebContainer}>
      <div
        className={styles.backgroundContainer}
        style={{ display: dimensions.width >= 1000 ? "flex" : "none" }}
      >
        <div className={styles.loginSaas}>
            <h1><b>Mee</b> para o seu estabelecimento</h1>
            <img src="/assets/img/saas-login-devices.svg" alt="" />
            <img src="/assets/img/logo-mee.png" alt="Logo" />
        </div>
      </div>
      <div
        className={styles.layoutWebContent}
        style={{ width: dimensions.width >= 1000 ? "45vw" : "100%" }}
      >
        {children}
      </div>
    </div>
  );
};

export default SaasLayoutAuth;
