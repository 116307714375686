import { useCallback, useEffect, useState } from "react";
import UseChildList, { IParamsUseChildList } from "modules/permutation/presentation/hooks/UseChildList";
import { IUseChildfilter } from "modules/permutation/presentation/interfaces/IUseChildfilter";
import { IPermutationForm } from "modules/permutation/presentation/interfaces/IPermutationForm";


export const UseChildForm = (values: IPermutationForm) => {

    const { getUseChildList, useChildList, isLoading } = UseChildList();


    const [params, setParams] = useState<IParamsUseChildList>({
        sort: { orientation: 'asc', type: 'name' }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IUseChildfilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        values.id &&  getUseChildList(values.id, { ...params });
    }, [getUseChildList, params, values.id]);


    return (
        {
            useChildList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getUseChildList
        }
    )
}
