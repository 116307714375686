import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import FooterButtons from "../../../footerButtons/FooterButtons";
import { IDeviceProfileValue } from "./IDeviceProfileValue";
import styles from "./ProfileForm.module.scss";

export interface IProfileFormProps {
  //propertys
  defaultValue?: IDeviceProfileValue;
  modified: boolean;
  onSubmit: (value: IDeviceProfileValue) => void;
  onModified: (modified: boolean) => void;
  profileNameList: string[];
  onCancel: () => void;
}
const ProfileForm: FC<IProfileFormProps> = ({ defaultValue, onCancel, modified, onSubmit, profileNameList, onModified }) => {
  const [values, setValues] = useState<IDeviceProfileValue>(
    defaultValue ?? {
      name: "",
      description: "",
    }
  );
  const [repeatedName, setRepeatedName] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValues(defaultValue);
      onModified(false);
    }
  }, [defaultValue, onModified]);

  const changeHandle = useCallback(
    (name: string, value: string) => {
      setValues((prev) => ({ ...prev, [name]: value }));
      onModified(true);
    },
    [onModified]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(values);
    },
    [onSubmit, values]
  );

  const validateName = useCallback(
    (name: string) => {
      changeHandle("name", name);
      if (profileNameList.findIndex((it) => it.trim() === name.trim()) >= 0) {
        setRepeatedName(true);
      } else {
        setRepeatedName(false);
      }
    },
    [changeHandle, profileNameList]
  );

  return (
    <form id={styles.ProfileForm} onSubmit={submitHandle}>
      <div className={styles.container}>
        {/* <Input ></Input> */}
        <span>Dê um nome e adicione uma descrição para o seu novo PDV, isso te ajudará a identificar futuramente.</span>
        <div>
          <FormItemContainer flex={0}>
            <FormItemContainer label="Nome" className={styles.formItemContainer}>
              <Input
                value={values.name}
                onChange={(ev) => validateName(ev.target.value)}
                variant={"outlined"}
                name={"name"}
                placeholder="Ex.: Pista"
                className={styles.input}
                inputProps={{
                  maxLength: 100,
                }}
                error={repeatedName}
                helperText={repeatedName ? "Já existe um perfil com este nome!" : ""}
              ></Input>
            </FormItemContainer>
            <FormItemContainer label="Descrição" className={styles.formItemContainer}>
              <Input
                value={values.description}
                multiline
                onChange={(ev) => changeHandle(ev.target.name, ev.target.value)}
                variant={"outlined"}
                name={"description"}
                inputProps={{
                  maxLength: 200,
                }}
                placeholder="Insira uma descrição para seu PDV"
                className={styles.input}
              ></Input>
            </FormItemContainer>
          </FormItemContainer>
        </div>
      </div>
      <FooterButtons
        nextType="submit"
        disabledNext={!values.name.trim() || repeatedName}
        labelNext="Salvar e continuar"
        labelBack="Cancelar"
        onClickBack={onCancel}
      />
    </form>
  );
};
export default ProfileForm;
