import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";
import { api } from "../Api";
import { IGetPrintQueueParams } from "modules/printQueue/domain/dto/IGetPermutationAccountParams";
import { IGetPrintQueueResponse } from "modules/printQueue/domain/dto/IGetPrintQueueResponse";
import { IGetEquipmentsResponse } from "modules/printQueue/domain/dto/IGetEquipmentsResponse";
import { IGetPrinterListByLocalClientIdResponse } from "modules/printQueue/domain/dto/IGetPrinterListByLocalClientIdResponse";
import qs from "qs";
import { IGetTableListByLocalClienteIdResponse } from "modules/printQueue/domain/dto/IGetTableListByLocalClienteIdResponse";
import { IGetOperatorListResponse } from "modules/printQueue/domain/dto/IGetOperatorListResponse";


const PrintQueueApi = (): IPrintQueueApiService => {

  const getPrintQueue = async (params?: IGetPrintQueueParams): Promise<IGetPrintQueueResponse> => {
    const data = {
      ...params,
    }

    const response = await api.get(`PedidoPOSPrinterStatus?${qs.stringify(data,{
      arrayFormat: "repeat",

    })}`);
    return {
      ...response.data,
      data: response.data.data.map((item: any) => {
        try {
          const orderItem = JSON.parse(item.printOrder);
          const body = JSON.parse(orderItem.payload?.body);
          const htmlResponse = {
            ...item,
            printOrder: body,
          };
          return htmlResponse;
        } catch (e) {
          console.error(e);
        }
        return { ...item };
      }),
    };
  };

  const getOperators = async (localId: string): Promise<IGetOperatorListResponse[]> => {
    const response = await api.get<{ id: string, nome: string }[]>(`/Proprietario/BuscarOperadoresDoLocal/${localId}`)
    return response.data.map((item) => ({ id: item.id, name: item.nome }))
}


  const getEquipments = async (localId: string): Promise<IGetEquipmentsResponse[]> => {
    const response = await api.get(`/Proprietario/BuscarEquipamentosQueJaTrabalharamNoLocal/${localId}`);
    return response.data;
  }

  
  const getPrinterListByLocalClenteId = async (localClienteId: string): Promise<IGetPrinterListByLocalClientIdResponse[]> => {
    const response = await api.get<IGetPrinterListByLocalClientIdResponse[]>(`/PrintingSector/Printers?localClienteId=${localClienteId}`);
    return (response.data);
}

const getTableListByLocalClenteId = async (localClienteId: string): Promise<IGetTableListByLocalClienteIdResponse[]> => {
  const response = await api.get<IGetTableListByLocalClienteIdResponse[]>(`/PrintingSector/Tables?localClienteId=${localClienteId}`);
  return (response.data);
}
  

  return { getPrintQueue, getEquipments, getPrinterListByLocalClenteId, getTableListByLocalClenteId, getOperators };
}

export default PrintQueueApi;
