import UseDimension from "components/dimension/UseDimension";
import styles from "./ForDeviceTotalItem.module.scss";
import { IPostSaleForDeviceResponse } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface IForDeviceItem {
  item: IPostSaleForDeviceResponse;
  onExport?: string | null;
}

const ForDeviceTotalItem = ({ item, onExport }: IForDeviceItem) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.ForDeviceTotalItem} className="div-to-capture">
      {dimensions.width > 650 || onExport === "pdf" ? (
        <div className={styles.totalItem}>
          <span>Total Geral:</span>
          <span>{moneyMaskNumber(item.cancelado)}</span>
          <span>{moneyMaskNumber(item.cashless)}</span>
          <span>{moneyMaskNumber(item.debito)}</span>
          <span>{moneyMaskNumber(item.debitoNT)}</span>
          <span>{moneyMaskNumber(item.credito)}</span>
          <span>{moneyMaskNumber(item.creditoNT)}</span>
          {/* <span>PIX</span> */}
          <span>{moneyMaskNumber(item.dinheiro)}</span>
          <span>{moneyMaskNumber(item.desconto)}</span>
          <span>{moneyMaskNumber(item.voucher)}</span>
          <span>{moneyMaskNumber(item.outros)}</span>
          <span>{moneyMaskNumber(item.emAberto)}</span>
          <span>{moneyMaskNumber(item.suprimento)}</span>
          <span>{moneyMaskNumber(item.sangria)}</span>
          <span>{moneyMaskNumber(item.taxaServico)}</span>
          <span>{moneyMaskNumber(item.total)}</span>
        </div>
      ) : (
        <div className={styles.totalItemMobile}>
          <div className={styles.item}>
            <span>Total geral</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total cancelado:</span>
            <span>{moneyMaskNumber(item.cancelado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total cashless:</span>
            <span>{moneyMaskNumber(item.cashless ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total débito:</span>
            <span>{moneyMaskNumber(item.debito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total débito (NT):</span>
            <span>{moneyMaskNumber(item.debitoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total crédito:</span>
            <span>{moneyMaskNumber(item.credito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total crédito (NT):</span>
            <span>{moneyMaskNumber(item.creditoNT ?? 0)}</span>
          </div>
          {/* <div className={styles.item}>
            <span className={styles.title}>Total PIX:</span>
            <span>PIX</span>
          </div> */}
          <div className={styles.item}>
            <span className={styles.title}>Total dinheiro:</span>
            <span>{moneyMaskNumber(item.dinheiro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total desconto:</span>
            <span>{moneyMaskNumber(item.desconto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total voucher:</span>
            <span>{moneyMaskNumber(item.voucher ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total outros:</span>
            <span>{moneyMaskNumber(item.outros ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total em aberto:</span>
            <span>{moneyMaskNumber(item.emAberto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total suprimento:</span>
            <span>{moneyMaskNumber(item.suprimento ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total sangria:</span>
            <span>{moneyMaskNumber(item.sangria ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total tx. de serviço:</span>
            <span>{moneyMaskNumber(item.taxaServico ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total:</span>
            <span>{moneyMaskNumber(item.total ?? 0)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ForDeviceTotalItem;
