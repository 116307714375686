import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./TerminalDeviceItem.module.scss";
import { IDeviceItem } from "modules/Terminal/models/ITerminalDeficeItem";
import TerminalConfigForm, { IItemSelector, ITerminalConfigFormValue, ITerminalConfigFormValueDefault } from "../terminalConfigForm/TerminalConfigForm";
import { ExpandLess, ExpandMore, LinkOutlined } from "@material-ui/icons";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { QrCodeOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Icon } from "@mui/material";
import { ICatalogSelect } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet";
import { OperationMethods, PatchUpdateTerminalConfigRequest, TerminalOperatorMode, TerminalTableMode } from "modules/Terminal/application/dtos/PatchUpdateTerminalConfigRequest";
import SideSheetSolicitations from "../terminalSidesheets/TerminalSidesheets";
import { TerminalSidesheetsQRCode } from "../terminalSidesheets/TerminalSidesheetsQRCode";
import Sidesheet from "components/sidesheet/Sidesheet";
import { terminaUrl } from "Enviroment";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import { GetNavigatorSolicitationsResponse } from "modules/Terminal/application/dtos/GetNavigatorSolicitationsResponse";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { format } from "date-fns";
import UseDimension from "components/dimension/UseDimension";

export interface INavigatorItem {
    id: string;
    localId: string;
    deviceId: string;
    navigatorSessionId: string;
    navigatorName: string;
    deviceName: string;
}

export interface ITerminalDeviceItemProps {
    deviceType: DeviceType;
    device: IDeviceItem;
    catalogs: ICatalogSelect[];
    printerSectors?: IItemSelector[];
    getTerminalDevices: () => void;
    updateTerminalConfig: (request: PatchUpdateTerminalConfigRequest) => Promise<string>
    getNavigatorSolicitations: (deviceId: string) => Promise<GetNavigatorSolicitationsResponse>;
    onAproveSolicitation: (solicitationID: string) => Promise<void>;
    onRefuseSolicitation: (solicitationID: string) => Promise<void>;
    postRefuseAllSolicitations: (deviceId: string) => Promise<void>;
    onEndSession: (deviceId: string) => Promise<void>;
    onClickEditProfileHandle?: (profileId: string) => void;
    handleDeletePos?: (posId: string) => Promise<void>;
    deletePosStep?: number;
    setDeletePosStep?: React.Dispatch<React.SetStateAction<number>>;
    loadingDeletePos?: boolean;
    salesNowTerminal?: boolean;
}

const useStyles = makeStyles({
    root: {
        '&.MuiAccordion-root': {
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            "&::before": {
                display: "none",
            },
            '&.MuiAccordion-root:first-of-type': {
                borderRadius: "10px",
            },
            '&.MuiAccordion-root:last-of-type': {
                borderRadius: "10px",
            },
            '@media (max-width: 400px)': {
                boxShadow: "none",
                border: "1px solid #DFCDFF"
            }
        },
    },
});

const TerminalDeviceItem: FC<ITerminalDeviceItemProps> = ({
    deviceType,
    device,
    catalogs,
    getTerminalDevices,
    updateTerminalConfig,
    getNavigatorSolicitations,
    onAproveSolicitation,
    onRefuseSolicitation,
    postRefuseAllSolicitations,
    onEndSession,
    onClickEditProfileHandle,
    handleDeletePos,
    deletePosStep,
    setDeletePosStep,
    loadingDeletePos,
    printerSectors,
    salesNowTerminal = false
}) => {
    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();
    const [expand, setExpand] = useState(false);
    const accordionStyle = useStyles();

    const [defaultValue, setDefaultValue] = useState<ITerminalConfigFormValueDefault>({} as ITerminalConfigFormValueDefault);

    const [openQrCode, setOpenQrCode] = useState<string | null>(null);
    const [openSolicitations, setOpenSolicitations] = useState<IDeviceItem>();
    const [deletePos, setDeletePos] = useState<string | null>(null);

    const currentQrcodeLink = useMemo(() => `${terminaUrl ?? "http://terminal.meep.cloud"}/navigator/${currentLocal?.id}/${device.device.id}`, [currentLocal?.id, device.device.id]);

    const hasProfile = useMemo(() => !!device.profile?.id, [device]);

    const handleOnChangeConfig = useCallback(async (values: ITerminalConfigFormValue) => {

        const operationMethodPosPaid = values.isPosPaid ? [
            OperationMethods.ORDERPAID,
        ] : [];

        const operationMethodTicket = values.isTicket ? [
            OperationMethods.TICKET,
        ] : [];

        const configValues: PatchUpdateTerminalConfigRequest = {
            profileId: device.profile?.id,
            profileName: device.profile?.name,
            terminal: {
                tableMode: values.isTableMap ? TerminalTableMode.TABLE : TerminalTableMode.INPUT,
                operatorMode: values.isOneOperator ? TerminalOperatorMode.ONE : TerminalOperatorMode.MANNY
            },
            printer: {
                receiptPrinterSectorId: values.printerSector?.id,
            },
            productListId: values.catalog?.id,
            operationMethods: operationMethodPosPaid.concat(operationMethodTicket),
        };

        const response = await updateTerminalConfig(configValues);
        return response;
    }, [device.profile?.id, device.profile?.name, updateTerminalConfig]);

    useEffect(() => {
        if (device.profile) {
            setDefaultValue({
                id: device.profile.id,
                isTicket: !!device.profile.operationMethods.find((item) => item.type === OperationMethods.TICKET && item.enabled),
                isPosPaid: !!device.profile.operationMethods.find((item) => item.type === OperationMethods.ORDERPAID && item.enabled),
                receiptPrinterSectorId: printerSectors?.find((item) => item.id === device.profile?.printer?.receiptPrinterSectorId) ?? null,
                catalog: {
                    id: device.profile.productsListId,
                    name: catalogs?.find((item) => item.id === device.profile?.productsListId)?.description ?? "",
                },

                isTableMap: device.profile.terminal.tableMode === TerminalTableMode.TABLE,
                isOneOperator: device.profile.terminal.operatorMode === TerminalOperatorMode.ONE,
            });
        }
    }, [catalogs, device, printerSectors]);

    return (
        <>
            <Accordion
                elevation={0}
                disableGutters
                expanded={expand}
                className={`${accordionStyle.root} ${styles.rootMobile}`}
            >
                <AccordionSummary style={{ cursor: "default" }}>
                    <div id={styles.TerminalDeviceItem}>
                        <div className={styles.header}>
                            <div className={styles.start}>
                                <div className={styles.iconContainer}>
                                    {deviceType === DeviceType.TERMINAL
                                        ? <Icon className={styles.icon}>computer</Icon>
                                        : <img src="/assets/icon/saas/pos-saas.svg" alt="POS" />
                                    }
                                </div>
                                <span>{salesNowTerminal ? "Terminal: Vender Agora" : device.profile?.name}</span>
                            </div>
                            {deviceType === DeviceType.TERMINAL
                                ? (
                                    <div className={styles.end}>
                                        <a
                                            href={currentQrcodeLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => {
                                                if (!hasProfile || salesNowTerminal) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            className={salesNowTerminal ? styles.disabled : ""}
                                        >
                                            <LinkOutlined style={{ width: "20px" }} />
                                            Acessar link
                                        </a>
                                        <button
                                            onClick={() => setOpenQrCode(currentQrcodeLink)}
                                            disabled={salesNowTerminal}
                                            className={salesNowTerminal ? styles.disabled : ""}
                                        >
                                            <QrCodeOutlined style={{ width: "20px" }} />
                                            QR Code
                                        </button>
                                        <button onClick={() => setExpand(!expand)}>
                                            {expand ? <ExpandLess /> : <ExpandMore />}
                                        </button>
                                    </div>
                                ) : (
                                    <div className={styles.end}>
                                        <span>POS Smart</span>
                                        <button onClick={() => setExpand(!expand)}>
                                            {expand ? <ExpandLess /> : <ExpandMore />}
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.operatorAndDevice}>
                            {deviceType === DeviceType.TERMINAL
                                ? (
                                    <div className={styles.device}>
                                        <span>Dispositivo:{device.navigator?.navigatorName ? <>{device.navigator.navigatorName} <IconButton
                                            onClick={() => setOpenSolicitations(device)} size="small"><Icon fontSize="small">close</Icon></IconButton></> : "-"}</span>
                                        {/* {!!device.navigatorSolicitation.length && */}
                                            <button className={styles.navigatorSolicitation} onClick={() => setOpenSolicitations(device)}>
                                                <span>{device.navigatorSolicitation.length} Pendente de aprovação</span>
                                            </button>
                                        {/* } */}
                                    </div>
                                ) : (
                                    <div className={styles.posInfo}>
                                        <span>Maquininha criada em: {format(new Date(device.device.createdAt), "dd/MM/yyyy' 'HH':'mm")}</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <TerminalConfigForm
                        deviceType={deviceType}
                        catalogs={catalogs}
                        printerSectors={printerSectors}
                        onChange={handleOnChangeConfig}
                        defaultValue={defaultValue ?? device.profile}
                        hasProfile={hasProfile}
                        posDeviceId={device.device.id}
                        setDeletePos={setDeletePos}
                        onClickEditProfileHandle={onClickEditProfileHandle}
                    />
                </AccordionDetails>
            </Accordion >
            <Sidesheet
                open={!!openSolicitations}
                onClose={() => setOpenSolicitations(undefined)}
                title={<h2>Sessão de dispositivo</h2>}
                content={openSolicitations ?
                    <SideSheetSolicitations
                        terminalDeviceItem={openSolicitations}
                        getNavigatorSolicitations={getNavigatorSolicitations}
                        onAproveSolicitation={onAproveSolicitation}
                        onRefuseSolicitation={onRefuseSolicitation}
                        postRefuseAllSolicitations={postRefuseAllSolicitations}
                        onEndSession={onEndSession}
                        onClose={() => {
                            setOpenSolicitations(undefined);
                            getTerminalDevices();
                        }}

                    />
                    :
                    <div />
                }
                continueButton={"Fechar"}
                handleContinueButton={() => setOpenSolicitations(undefined)}
                currentStep={0}
                totalSteps={0}
                notTotalHeight
            />
            <Sidesheet
                open={!!openQrCode}
                onClose={() => setOpenQrCode(null)}
                title={<h2>QRCode</h2>}
                content={<TerminalSidesheetsQRCode value={openQrCode} />}
                continueButton={"Fechar"}
                handleContinueButton={() => setOpenQrCode(null)}
                currentStep={0}
                totalSteps={0}
            />
            <Sidesheet
                isLoading={loadingDeletePos}
                open={!!deletePos}
                onClose={() => {
                    setDeletePos(null);
                    setDeletePosStep!(1);
                    getTerminalDevices();
                }}
                title={<h2>Remover <b>máquina</b></h2>}
                content={
                    <SidesheetFeedback
                        text={
                            <span style={{ lineHeight: "150%" }}>
                                Deseja realmente remover a máquina? <br />Você não poderá mais alterar as configurações.
                            </span>
                        }
                    />
                }
                currentStep={deletePosStep}
                totalSteps={2}
                continueButton="Remover"
                handleContinueButton={() => handleDeletePos!(deletePos ?? "")}
                customizedCancelButton="Voltar"
                cancelButton
                feedback={
                    <SidesheetFeedback text="Máquina excluída com sucesso!" success />
                }
            />
        </>
    )
}
export default TerminalDeviceItem