import Layout from 'components/layout/presentation/Layout';
import styles from './InvoiceForm.module.scss';
import { useEffect, useState } from 'react';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { UpdateCompanyUseCase } from 'modules/invoice/application/useCases/UpdateCompanyUseCase';
import { ICompany } from '../interfaces/ICompany';
import { ICompanyForm } from '../interfaces/ICompanyForm';
import { InvoiceRegister } from '../InvoiceRegister/InvoiceRegister';
import { InvoiceAccounting } from '../InvoiceAccounting/InvoiceAccounting';
import { useHistory } from 'react-router-dom';
import { GetCompanyUseCase } from 'modules/invoice/application/useCases/GetCompanyUseCase';

const service = InvoiceApi();

export const InvoiceForm = () => {
    const [tab, setTab] = useState<'register' | 'accounting'>('register');
    const [preLoading, setPreloading] = useState(false);
    const [company, setCompany] = useState<ICompany>();
    const [form, setForm] = useState({} as ICompanyForm);
    const [loading, setLoading] = useState(false);

    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    setPreloading(true);
                    const response = await GetCompanyUseCase(service, currentLocal.id);
                    setCompany(response);
                    setForm({ ...response, certificate: undefined, hasCertificate: !!response.certificate?.url });
                } finally {
                    setPreloading(false);
                }
            }
        })();
    }, [currentLocal]);

    const onNextStep = () => {
        setTab('accounting');
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await UpdateCompanyUseCase(service, company!.id, { ...form, certificate: !form.hasCertificate ? { ...form.certificate!, saveFile: true } : undefined });
            toast('Informações fiscais salvas com sucesso.', 'success');
            history.push('/private/invoice');
        } catch {
            setLoading(false);
        }
    }

    if (preLoading !== false) {
        return (
            <Layout>
                <div className={styles.noDataContainer}>
                    <img src="/assets/icon/saas/loading.gif" alt="" />
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className={styles.container}>
                <header>
                    <div className={`${styles.tabContainer} ${tab === 'register' ? styles.selected : ''}`}>
                        <span>
                            {`1 ${tab === 'register' ? 'Cadastro' : ''}`}
                        </span>
                    </div>
                    <div className={`${styles.tabContainer} ${tab === 'accounting' ? styles.selected : ''}`}>
                        <span>
                            {`2 ${tab === 'accounting' ? 'Informações contábeis' : ''}`}
                        </span>
                    </div>
                </header>
                {
                    tab === 'register'
                        ? <InvoiceRegister setForm={setForm} form={form} onCancel={() => history.push('/private/invoice')} onSubmit={onNextStep} />
                        : <InvoiceAccounting loading={loading} setForm={setForm} form={form} onCancel={() => setTab('register')} onSubmit={handleSubmit} />
                }
            </div>
        </Layout>
    )
}