import { erpUrl } from "Enviroment"
import { IGetSupplyStockMovementListRequest, IGetSupplyStockMovementListResponse } from "modules/meepErp/application/dtos/supplyStockMovement/ISupplyStockMovementDtos"
import { SupplyStockMovementType } from "modules/meepErp/models/stockMovement/_SupplyStockMovementType"


import { Api } from "services/api/Api"
import { v4 } from "uuid"


const baseUrl = erpUrl + '/api/Inventory/SupplyStocks/Movements'

const SupplyStockMovementsService = () => {
  const api = Api();

  const getSupplyStockMovements = async (localId: string, request: IGetSupplyStockMovementListRequest): Promise<IGetSupplyStockMovementListResponse> => {

    const response = await api.get<IGetSupplyStockMovementListResponse>(`${baseUrl}`, { params: request });
    return response.data;

    // await new Promise(resolve => setTimeout(resolve, 1000))
    // return MOCK
  }



  return ({
    getSupplyStockMovements
  })
}

export default SupplyStockMovementsService


export const MOCK: IGetSupplyStockMovementListResponse = {
  page: 1,
  pageSize: 5,
  totalPages: 1,
  total: 5,
  items: [
    {
      id: v4(),
      createdAt: "2021-08-01",
      supplyCode: 1,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
    {
      id: v4(),
      createdAt: "2021-08-02",
      supplyCode: 2,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
    {
      id: v4(),
      createdAt: "2021-08-03",
      supplyCode: 3,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
    {
      id: v4(),
      createdAt: "2021-08-03",
      supplyCode: 4,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
    {
      id: v4(),
      createdAt: "2021-08-04",
      supplyCode: 5,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
    {
      id: v4(),
      createdAt: "2021-08-05",
      supplyCode: 6,
      supplyId: v4(),
      supplyName: "supplyName",
      supplyGroupId: v4(),
      supplyGroupName: "supplyGroupName",
      invoiceOperationId: v4(),
      invoiceOperationName: "operationName",
      movementType: SupplyStockMovementType.Input,
      quantity: 1,
      measurementUnitId: v4(),
      measurementUnitSymbol: "Kg",
      stockUnitCost: 1,
      stockTotalCost: 1,
    },
  ]
}

