import { IUpdateFidelityRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityRequest";
import { ScoringSchemeType } from "modules/fidelity/domain/dtos/ScoringSchemeType";
import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService"
import { IFidelityCoinsFormValue } from "modules/fidelity/presentation/components/forms/coinsRules/FidelityCoinsForm";
import { IFidelityNameFormValue } from "modules/fidelity/presentation/components/forms/name/FidelityNameForm";

const UpdateCoinsRulesFidelityLevelUseCase = async (service: IFidelityService, ownerId: string, fidelityId: string, values: IFidelityCoinsFormValue): Promise<IFidelityNameFormValue> => {
    const request: IUpdateFidelityRequest = {
        coinsScoringRules: values.rules.map(item => ({
            coins: item.value,
            target: item.type === ScoringSchemeType.FREQUENCY ? 1 : item.target,
            type: item.type,
            expireDays: 0
        })),
        coinExchange: values.coinExchange
    }
    var response = await service.updateFidelityLevel(ownerId, fidelityId, request);
    return response
}

export default UpdateCoinsRulesFidelityLevelUseCase