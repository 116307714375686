import Layout from "components/layout/presentation/Layout";
import { PurchasePushPage } from "./PurchasePushPage";

export const PurchasePush = () => {
  return (
    <Layout>
      <PurchasePushPage />
    </Layout>
  );
};
