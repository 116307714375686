export enum dashboardContainerEnum {
    FINANCIAL_DATA = 'financialData',
    RECHARGE_PAYMENT_DATA = 'rechargePaymentMethodsData',
    RECHARGE_ORIGIN = 'rechargeOrigin',
    RECHARGE_ORIGIN_CHART = 'rechargeOriginChart',
    AVERAGE_TICKET = 'averageTicket',
    AVERAGE_TICKET_CHART = 'averageTicketChart',
    CANCELED = 'canceled',
    CANCELED_CHART = 'canceledChart',
    CARDS_ACTIVATION = 'cardsActivation',
    CARDS_ACTIVATION_CHART = 'cardsActivationChart',
    TOP_OPERATORS = 'topOperators',
    TOP_CUSTOMERS = 'topCustomers',
    TOP_DEVICES = 'topDevices',
    TOP_PRODUCTS = 'topProducts',
    CATEGORIES = 'categories',
    PRE_RECHARGE = 'preRechargeResume',
}
