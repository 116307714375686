import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import FinancialInvoicesPage from "modules/faturas/presentation/pages/FinancialInvoicesPage";
import React, { FC, useEffect } from "react";
export interface IFaturasProps {
  //propertys
}
const Faturas: FC<IFaturasProps> = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Financeiro",
      },
      {
        label: "Financeiro",
        title: "Faturas",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <FinancialInvoicesPage />
    </Layout>
  );
};

export default Faturas;
