import { FC, useEffect, useState } from "react";
import styles from "./FinancialData.module.scss";
import { IFinancialData } from "./interfaces/IFinancialData";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { useError } from "../../contexts/error/ErrorContext";
import { moneyMaskNumber } from "services/utils/Money";
import { Skeleton } from "../skeleton/Skeleton";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import { IGetPreRechargeMethodsDataResponse } from "modules/cashlessDashboard/domain/dto/preRechargePaymentMethodsData/IGetPreRechargeMethodsDataResponse";

export interface IFinancialDataProps {
  getData: () => Promise<{
    response: IFinancialData;
    preRechargeRes: IGetPreRechargeMethodsDataResponse;
} | undefined>;
  onGeneratingPDF?: boolean;
}

const FinancialData: FC<IFinancialDataProps> = ({ getData, onGeneratingPDF }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IFinancialData>();
  const [preRechargeData, setPreRechargeData] = useState<number>();

  const { setOnError, clearContainerError } = useError();

  useEffect(() => {
    (async () => {
      try {
        clearContainerError(dashboardContainerEnum.FINANCIAL_DATA);
        setLoading(true);
        const response = await getData();
        setData(response?.response);
        setPreRechargeData(response?.preRechargeRes.total ?? 0);
      } catch {
        setOnError({
          type: "error",
          containerId: dashboardContainerEnum.FINANCIAL_DATA,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [clearContainerError, getData, setOnError]);

  if (loading) {
    return (
      <div id={styles.FinancialData} style={{ display: "flex" }}>
        <div className={styles.container}>
          <div className={styles.column}>
            <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />
          </div>
          <div className={styles.column}>
            <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
          </div>
          <div className={styles.column}>
            <Skeleton items={2} cardStyle={styles.skeletonCardStyle} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.FinancialData}>
      <div className={`${styles.container} ${styles.detail}`}>
        <div className={`${styles.column} ${styles.faceColumn}`}>
          <span className={styles.label}>Total faturado</span>
          <span className={`${styles.value} ${styles.recharged}`}>
            {moneyMaskNumber(data?.totalInvoiced )}{" "}
            {!onGeneratingPDF && <Tooltip
              title="Total faturado"
              text="Faturamento total do período selecionado. Ele leva em consideração o valor total que foi consumido, o valor total de saldo residual, o valor total da taxa de serviço e total da venda em fichas. O valor total que foi devolvido aos clientes não consta no faturamento."
            />}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <span className={styles.label}>
              Consumo cashless
            </span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.totalConsumed)}
            </span>
          </div>

          <div className={styles.column}>
            <span className={styles.label}>
              Residual
            </span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.totalResidual)}
            </span>
          </div>

          <div className={styles.column}>
            <span className={styles.label}>
              Taxa de serviço
            </span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.serviceTax)}
            </span>
          </div>

          <div className={styles.column}>
            <span className={styles.label}>
              Venda fichas
            </span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.totalTicket)}
            </span>
          </div>

          <div className={styles.column}>
            <span className={styles.label}>
              Pré-recarga
            </span>
            <span className={styles.value}>
              {moneyMaskNumber(preRechargeData)}
            </span>
          </div>

          <div className={styles.column}>
            <span className={styles.label}>
              Total devolvido
            </span>
            <span className={styles.value}>
              ({moneyMaskNumber(data?.totalReturned)})
            </span>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.columnBox}>
          <div className={styles.contentTitle}>
            <span className={styles.label}>Total recarregado</span>
            <span className={`${styles.value} ${styles.recharged}`}>
              {moneyMaskNumber(data?.totalReloaded)}
              {!onGeneratingPDF && <Tooltip
                title="Total recarregado"
                text="Valor total recarregado no período selecionado, inclusive contando com o valor que já foi devolvido aos clientes. Não considera o valor de pré-recarga."
              />}
            </span>
          </div>
        </div>
        <div className={styles.columnBox}>
          <div className={styles.contentTitle}>
            <span className={styles.label}>Total descontos</span>
            <span className={`${styles.value} ${styles.recharged}`}>
              {moneyMaskNumber(data?.totalDiscount)}
              {!onGeneratingPDF && <Tooltip
                title="Total de desconto"
                text="Valor total de descontos liberados aos clientes no período selecionado."
              />}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FinancialData;
