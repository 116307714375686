import React, { FC, useEffect } from 'react';
import { CashlessConsummation } from './components/cashlessConsummation/CashlessConsummation';
import { CashSelection } from './components/cashSelection/CashSelection';
import { TicketDiscount } from './components/discounts/TicketDiscount';
import { ManageHeader } from './components/manageHeader/ManageHeader';
import { PosPaid } from './components/posPaid/PosPaid';
import { ManageEventSkeleton } from './components/skeleton/ManageEventSkeleton';
import { Tickets } from './components/tickets/Tickets';
import styles from './ManageEventPage.module.scss';
import UseManageEventPage from './UseManageEventPage';
import UseSelectedEventStartDate from 'modules/kds/presentation/hooks/UseSelectedEventStartDate';
import { parse } from 'date-fns';
import { OriginType } from 'modules/events/domain/models/IEvents';
import { UseGestaoVendas } from 'modules/gestaoVendas/presentation/hooks/UseGestaoVendas';

interface IManageEventPageProps {}

export const ManageEventPage: FC<IManageEventPageProps> = () => {
  const { event, tickets, getEvent, refreshDiscountList, resetRefreshDiscountList } = UseManageEventPage();
  const { setSelectEventStartDate } = UseSelectedEventStartDate();

  useEffect(() => {
    if (event) {
      setSelectEventStartDate(parse(event.openingDates[0].startDate.toString().slice(0, 16), "yyyy-MM-dd'T'HH:mm", new Date()) ?? null);
    }
  }, [event]);

  const { getSegmentos, responseConfigLocal } = UseGestaoVendas(true);

  useEffect(() => {
    getSegmentos();
  }, [getSegmentos]);
  
  return (
    <div id={styles.ManageEventPage}>
      {event ? (
        <>
          <ManageHeader event={event} refreshEvent={getEvent} responseConfigLocal={responseConfigLocal?.trabalhaComEventos}/>
          <CashSelection event={event} refreshEvent={getEvent} responseConfigLocal={responseConfigLocal?.trabalhaComEventos}/>
          {event.originType === OriginType.Portal && (
            <Tickets eventName={event.name} tickets={tickets.records} refreshEvent={getEvent} />
          )}
          <TicketDiscount event={event} refreshDiscountList={refreshDiscountList} refreshEvent={getEvent} resetRefreshDisountList={resetRefreshDiscountList} />
          <CashlessConsummation event={event} cashierId={event.cashier?.cashierId} />
          <PosPaid cashierId={event.cashier?.cashierId} />
        </>
      ) : (
        <ManageEventSkeleton />
      )}
    </div>
  );
};
