import GetOpenCashierUseCase from 'modules/events/application/useCases/events/GetCashierListUseCase';
import SelectCashierUseCase from 'modules/events/application/useCases/events/SelectCashierUseCase';
import { ICashier } from 'modules/events/domain/models/IEvents';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventsApi } from 'services/api/events/events/EventsApi';

const UseCashSelection = (refreshEvent: () => Promise<void>, cashier: ICashier | undefined) => {
  const { currentLocal } = useLocal();
  const { eventId } = useParams<{ eventId: string }>();
  const eventsService = EventsApi();
  const [openCashier, setOpenCashier] = useState<ICashier>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCashierList = async (localId: string) => {
      try {
        const result = await GetOpenCashierUseCase(eventsService, localId);
        setOpenCashier(result);
      } catch (error) {
      }
    };

    if (currentLocal?.id && !cashier) {
      getCashierList(currentLocal.id);
    }
  }, []);

  const handleCashSelection = useCallback(async (cashierId: string) => {
    if(eventId && currentLocal) {
      try {
        setLoading(true);
        await SelectCashierUseCase(eventsService, eventId, cashierId, currentLocal.id);
        await refreshEvent();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [eventId, eventsService, currentLocal, refreshEvent]);

  return {
    handleCashSelection,
    openCashier,
    loading
  };
};

export default UseCashSelection;
