import { ImportExport } from "@mui/icons-material";
import styles from "./TransactionsHeader.module.scss";
import { useEffect, useState } from "react";
import { IGetTransactionsParams } from "modules/transactionReport/domain/dto/IGetTransactionsParams";
import UseDimension from "components/dimension/UseDimension";
import { IconButton } from "@mui/material";

export interface ICargosHeaderProps {
  params: IGetTransactionsParams;
  setParams: React.Dispatch<React.SetStateAction<IGetTransactionsParams>>;
}

const titleHeader = [
  {
    title: "Data/hora transação",
    value: "DataCriacao",
  },
  {
    title: "Equipamento",
    value: "equipament",
  },
  {
    title: "Serial",
    value: "serial",
  },
  {
    title: "Cartão",
    value: "card",
  },
  {
    title: "Bandeira",
    value: "flag",
  },
  {
    title: "Tipo de pagamento",
    value: "payment",
  },
  {
    title: "% da taxa",
    value: "percent",
  },
  {
    title: "Taxa",
    value: "fee",
  },
  {
    title: "Recebimento",
    value: "receipt",
  },
  {
    title: "Antecipado",
    value: "anticipation",
  },
  {
    title: "Valor bruto",
    value: "value",
  },
  {
    title: "Valor líquido",
    value: "value",
  },
  {
    title: "Status",
    value: "status",
  },
];

const titleHeaderMobile = [
  {
    title: "Data/hora transação",
    value: "DataCriacao",
  },
  {
    title: "Equipamento",
    value: "equipament",
  },
  {
    title: "Tipo de pagamento",
    value: "payment",
  },
  {
    title: "Recebimento",
    value: "receipt",
  },
  {
    title: "Valor bruto",
    value: "value",
  },
  {
    title: "Valor líquido",
    value: "value",
  },
  {
    title: "Status",
    value: "status",
  },
];

const TransactionsHeader = ({ params, setParams }: ICargosHeaderProps) => {
  const [orderBy, setOrderBy] = useState({ OrderBy: "DataCriacao" } as {
    Orientation: "asc" | "desc",
    OrderBy: string,
  });
  
  const { dimensions } = UseDimension();

  useEffect(() => {
    if (orderBy.OrderBy && orderBy.Orientation) {
      setParams({
        ...params,
        Orientation: orderBy.Orientation,
        OrderBy: orderBy.OrderBy,
      });
    }
  }, [orderBy]);
  

  return (
    <div id={styles.TransactionsHeader}>
      {dimensions.width > 1600 && titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={`${styles.container} ${item.title === "Status" ? styles.status : ""}`}
          >
            {item.title}
            <IconButton
              disableRipple
              onClick={() => {
                setOrderBy({
                  Orientation: params.Orientation === "asc" ? "desc" : "asc",
                  OrderBy: item.value ?? "",
                });
              }}
            >
              <ImportExport />
            </IconButton>
          </div>
        );
      })}
      {dimensions.width <= 1600 && titleHeaderMobile.map((item) => {
        return (
          <div 
            key={item.value}
            className={`${styles.container} ${item.title === "Status" ? styles.status : ""}`}
          >
            {item.title}
            <IconButton
              onClick={() => {
                setOrderBy({
                  Orientation: params.Orientation === "asc" ? "desc" : "asc",
                  OrderBy: item.value ?? "",
                });
              }}
            >
              <ImportExport />
            </IconButton>
          </div>
        );
      })}
      <div className={styles.last}/>
    </div>
  );
};
export default TransactionsHeader;
