import { ICheckoutSaasService } from "modules/saasPayment/domain/interface/ICheckoutSaasService";
import { api } from "../Api";
import { ISaasCheckoutRequest } from "modules/saasPayment/domain/dto/ISaasCheckoutRequest";
import { plansUrl } from "Enviroment";

const baseUrl = plansUrl;

export const CheckoutSaasApi = (): ICheckoutSaasService => {
    const checkout = async (request: ISaasCheckoutRequest): Promise<void> => {
        return await api.post(`${baseUrl}/subscriptions/saas-checkout`, request);
    }

    return {
        checkout
    }
}