import Layout from "components/layout/presentation/Layout";
import PageHeader from "components/ui/pageHeader/PageHeader";
import { EventGuestsListsPage } from "modules/events/presentation/pages/eventGuestsLists/EventGuestsListsPage";
import React from "react";

const EventGuestLists = () => {
  return (
    <Layout>
      <EventGuestsListsPage />
    </Layout>
  );
};

export default EventGuestLists;
