
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditDeviceSystemUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceSystem/EditDeviceSystemUseCase';
import GetOperationListUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/GetOperationListUseCase';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IDeviceOperationItemValue, IOperationItem } from '../../components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue';
import { FormDeviceProfileStep } from "../../../domain/interface/FormDeviceProfileSteps";
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IFilterProfileDevice } from '../../components/deviceProfile/filterProfile/IFilterDeviceProfile';

const UseDeviceOperation = (onChangeFilterHandle: (filter: IFilterProfileDevice) => void, path: string = "/private/pdv/perfil") => {
    // const [systemDefault, setSystemDefault] = useState<IDeviceOperationItemValue[]>();
    const [operations, setOperations] = useState<IOperationItem[]>();
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();
    const [localId, setLocalId] = useState<string>();

    useEffect(() => {
      if(currentLocal) {
        setLocalId(currentLocal.id)
      }
    }, [currentLocal])
    

    const editOperation = useCallback(async (profileId: string, values: IDeviceOperationItemValue[]) => {
        try {
            if (localId) {
                showLoading()
                const service = DeviceProfileApi();
                await EditDeviceSystemUseCase(service, values, localId, profileId)
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.PAYMENT);
                onChangeFilterHandle({});
            } else {
                console.error("local não selecionado");
            }
        }
        finally {
            hideLoading()
        }
    }, [localId, showLoading, push, path, onChangeFilterHandle, hideLoading])

    const getOperationList = useCallback(async (localId: string) => {
        try {
            showLoading()
            const service = DeviceProfileApi();
            const response = await GetOperationListUseCase(service, localId, currentLocal?.systemIdentifier);
            setOperations(response);
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, showLoading])


    return ({
        editOperation,
        getOperationList,
        operations
    })
}

export default UseDeviceOperation;