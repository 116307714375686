import { erpUrl } from "Enviroment"
import { IAddMoreSupplyInventoryRequest, ICreateInventoryRequest, ICreateInventoryResponse, IGetInventoryListRequest, IGetInventoryListResponse, IGetInventoryRequest, IGetInventoryResponse, IUpdateInventorSupplyBalanceRequest, IUpdateInventorySupplyBalanceResponse } from "modules/meepErp/application/dtos/invetory/InvetoryOperationDto"
import { Api } from "services/api/Api";
import { delayMock, getPaginatedInventoryMOCK } from "./InventoryMOCK";

const BASE_URL = erpUrl;
const InvetoryService = () => {
  const api = Api()
  const createInvetory = async (localId: string, request: ICreateInventoryRequest): Promise<ICreateInventoryResponse> => {
    const response = await api.post<ICreateInventoryResponse>(`${BASE_URL}/api/${localId}/Inventories`, request)
    return (response.data)
    // await delayMock(3000);
    // return { id: v4() }
  }
  const addMoreSupplyInvetory = async (localId: string, inventoryId: string, request: IAddMoreSupplyInventoryRequest): Promise<ICreateInventoryResponse> => {
    const response = await api.put<ICreateInventoryResponse>(`${BASE_URL}/api/${localId}/Inventories/${inventoryId}`, request)
    return (response.data)
    // await delayMock(3000);
    // return { id: v4() }
  }

  const getInventoryList = async (localId: string, request: IGetInventoryListRequest): Promise<IGetInventoryListResponse> => {
    const response = await api.get<IGetInventoryListResponse>(`${BASE_URL}/api/${localId}/Inventories`, { params: request });
    return response.data;
  };

  const deleteInventory = async (localId: string, id: string): Promise<void> => {
    await api.delete(`${BASE_URL}/api/${localId}/Inventories/${id}`);

  };

  const updateInventorySupplyCountedQuantity = async (localId: string, id: string, request: IUpdateInventorSupplyBalanceRequest): Promise<IUpdateInventorySupplyBalanceResponse> => {
    const response = await api.patch<IUpdateInventorySupplyBalanceResponse>(`${BASE_URL}/api/${localId}/Inventories/InventorySupply/${id}`, request);
    return response.data;
    // await delayMock(3000);
    // return mockUpdateInventoryCountResponse
  }
  const getInventory = async (localId: string, id: string, request: IGetInventoryRequest): Promise<IGetInventoryResponse> => {
    const response = await api.get<IGetInventoryResponse>(`${BASE_URL}/api/${localId}/Inventories/${id}`, { params: request });
    return response.data;
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    // return mockGetInventoryResponse
  }

  const deleteInventorySupply = async (localId: string, id: string): Promise<void> => {
    await api.delete(`${BASE_URL}/api/${localId}/Inventories/InventorySupply/${id}`);
  }
///api/{ownerId}/Inventories/{inventoryId}/Finish

  const finishInventory = async (localId: string, id: string): Promise<void> => {
    await api.patch(`${BASE_URL}/api/${localId}/Inventories/${id}/Finish`);
  }



  return ({
    createInvetory,
    getInventoryList,
    deleteInventory,
    getInventory,
    updateInventorySupplyCountedQuantity,
    addMoreSupplyInvetory,
    deleteInventorySupply,
    finishInventory
  })
}
export default InvetoryService
