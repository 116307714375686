import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import { IArrObj, IListObj, parseObjToArr } from "modules/events/presentation/utils/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IDiscountProductListForm } from "./interfaces/IDiscountProductListForm";


interface IUseDiscountListEditForm {
  openModalAdd: boolean;
  onClickDeleteHandle: (item: IDiscounts) => Promise<void>;
  onClickAddHandle: () => void;
  onSubmitDiscountHandle: (newValue: IDiscountProductListForm, type: 'EDIT' | 'ADD') => Promise<void>;
  onClickCloseHandle: () => void;
  deleting: IDiscounts | null;
  onClickEditHandle: (discount: IDiscounts) => void;
  discountToEdit: IDiscounts | null
  discountsFilterList: IListObj[]
  filterByProduct: (value: IListObj | null) => void
  filteredDiscounts: IDiscounts[]
}

const UseDiscountListEditForm = (
  baseListId: string,
  values: IDiscounts[],
  onDeleteItem: (itemId: string) => Promise<void>,
  onAddDiscount: (newValue: IDiscountProductListForm, baseListId: string) => Promise<any>,
  handleEditDiscount: (discount: IDiscountProductListForm) => Promise<void>
): IUseDiscountListEditForm => {
  const { toast } = useUi();
  const [deleting, setDeleting] = useState<IDiscounts | null>(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [discountToEdit, setDiscountToEdit] = useState<IDiscounts | null>(null);
  const [filteredDiscounts, setFilteredDiscounts] = useState<IDiscounts[]>(values);

  const { discountsFilterList } = useMemo(() => {
    const discounts: IListObj[] = values?.map((item) => ({ id: item.id, name: item.productName })) ?? [];

    const discountsFilterList = parseObjToArr(
      discounts?.reduce(
        (reducer, current) => ({
          ...reducer,
          [current.id]: current.name,
        }),
        {} as IArrObj
      ) || ({} as IArrObj)
    );

    setFilteredDiscounts(values);

    return {
      discountsFilterList,
    };
  }, [values]);

  const filterByProduct = (value: IListObj | null) => {
    if (value) {
      setFilteredDiscounts(filteredDiscounts?.filter((item) => item.id === value.id));
    } else {
      setFilteredDiscounts(values);
    }
  };

  const onClickEditHandle = (discount: IDiscounts) => {
    setDiscountToEdit(discount);
    setOpenModalAdd(true);
  }

  const onClickAddHandle = useCallback(() => {
    setOpenModalAdd(true);
  }, []);

  const onClickCloseHandle = useCallback(() => {
    setDiscountToEdit(null);
    setOpenModalAdd(false);
  }, []);

  const onClickDeleteHandle = useCallback(
    async (item: IDiscounts): Promise<void> => {
      setDeleting(item);
      try {
        await onDeleteItem(item.id);
        setFilteredDiscounts(prev => prev.filter(_item => _item.id !== item.id));
      } finally {
        setDeleting(null);
      }
    },
    []
  );

  const onSubmitDiscountHandle = async (newValue: IDiscountProductListForm, type: 'EDIT' | 'ADD'): Promise<void> => {
    try {
      if (type === "ADD") {
        await onAddDiscount(newValue, baseListId);
      }
      if (type === "EDIT") {
        await handleEditDiscount(newValue)
      }
    } catch (error) {
      throw error
    }
  };

  return {
    openModalAdd,
    onClickDeleteHandle,
    onClickAddHandle,
    onSubmitDiscountHandle,
    onClickCloseHandle,
    deleting,
    onClickEditHandle,
    discountToEdit,
    discountsFilterList,
    filterByProduct,
    filteredDiscounts
  };
};

export default UseDiscountListEditForm;
