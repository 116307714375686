import LandingPage from "components/freePage/LandingPage";
import { CircularProgress } from "@material-ui/core";
import Layout from "components/layout/presentation/Layout";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import ConfigTrack from "modules/config/integrationsConfig/presentation/pages/configTrack/ConfigTrack";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { useState, useEffect } from "react";
import { TrackPage } from "../track/Track";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetTrackConfigUseCase } from "modules/survey/application/useCases/GetTrackConfigUseCase";
import SurveyApi from "services/api/tracksales/SurveyApi";

const service = SurveyApi();

export const TrackDetail = () => {
  const [open, setOpen] = useState<IntegrationTypeEnum | null>(null);
  const [step, setStep] = useState(1);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentLocal } = useLocal();

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (currentLocal) {
          const response = await GetTrackConfigUseCase(
            service,
            currentLocal?.id
          );
          setHasIntegration(!!response.token);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentLocal]);

  return (
    <Layout>
      <div style={{ marginBottom: "2rem" }}>
      {loading && <CircularProgress size={16} color="inherit" />}
      {!loading && (hasIntegration ? <TrackPage /> :
      <LandingPage
        title="Track.Co"
        subtitle="Pesquisa de satisfação"
        description="Ferramenta para pesquisa de satisfação com o cliente. Descubra o que seus clientes acham de seu estabelecimento e quais pontos você pode melhorar realizando pesquisas personalizadas e automáticas."
        aboutTitle={<>Obtenha mais que <span className='purpleText'>feedbacks</span> dos seus clientes</>}
        about="Descubra insights essenciais para enriquecer a experiência dos clientes. Demonstre interesse pelo seu público e construa uma imagem positiva. Com pesquisas de satisfação, você cultiva relações valiosas que impulsionam o crescimento do seu negócio e o diferenciam em um mercado competitivo."
        logo="/assets/icon/track-detail.svg"
        includedItemsTitle={<>Vantagens dessa <span className='purpleText'>integração</span>:</>}
        footerButtonLink="https://www.ajuda.meep.com.br/pesquisadesatisfa%C3%A7%C3%A3o"
        includedItems={[
          {
            title: "Conheça o seu cliente",
            description:
              "Entenda as necessidades, expectativas e preferências do seu público com as informações certas. ",
            img: '/assets/icon/track/client.svg'
          },
          {
            title: "Inove o seu negócio",
            description:
              "Crie novos produtos, serviços ou recursos inspirado nas opiniões dos seus clientes. ",
            img: '/assets/icon/track/business.svg'
          },
          {
            title: "Fidelize seu cliente",
            description:
              "Valorize a opinião dos seus clientes  mostrando mudanças a partir de feedbacks gerados e conquiste um público interessado e fiel.",
            img: '/assets/icon/track/fidelity.svg'
          },
          {
            title: "Embase decisões estratégicas",
            description:
              "Com feedbacks reais, você pode direcionar seus investimentos com mais inteligência, otimizando recursos e maximizando os resultados.",
            img: '/assets/icon/track/strategy.svg'
          },
          {
            title: "Redução de Churn",
            description:
              "Identifique e resolva problemas que afetam a satisfação dos seus clientes e reduza a taxa de cancelamento e abandono.",
            img: '/assets/icon/track/churn.svg'
          },
          {
            title: "Mitigue problemas",
            description:
              "Identifique rapidamente pontos de atrito na experiência do cliente e implemente melhorias antes que os problemas se tornem críticos.",
            img: '/assets/icon/track/problems.svg'
          },
        ]}
        setOpen={() => setOpen(IntegrationTypeEnum.TRACK)}
        featureType={FeatureTypeEnum.SURVEY}
        footerImg="/assets/img/track_video.png"
        footerImgLink="https://www.youtube.com/watch?v=BF7GsCcr2RA"
      />
      )}

      {open && open === "Track.co" && (
        <ConfigTrack
          open={open}
          setOpen={setOpen}
          step={step}
          setStep={setStep}
        />
      )}
      </div>
    </Layout>
  );
};
