import { IDiscountList, IDiscountListForm } from "../interfaces/DiscountListForm.types";
import { IDiscountListFormErros } from "./interface/IDiscountListFormValidationErros";



export const validate = (name: string, description: string, discounts: IDiscountList[], setErrors: React.Dispatch<React.SetStateAction<IDiscountListFormErros>>, setIndex: React.Dispatch<React.SetStateAction<number>>) => {
  let _errors: IDiscountListFormErros = {};
  let hasError = false;

  if (!name) {
    _errors = { ..._errors, name: "Campo nome obrigatório" };
    hasError = true;
  }
  if (!description) {
    _errors = { ..._errors, description: "Necessário adicionar uma descrição" };
    hasError = true;
  }
  if (discounts.length < 1) {
    _errors = { ..._errors, discounts: "Necessário adicionar ao menos um desconto" };
    hasError = true;
  }
  setErrors(_errors);

  return !hasError;
};
