import { Drawer, Icon, Switch } from '@material-ui/core';
import styles from './ChangeOnlinePaymentModal.module.scss';
import { useEffect, useState } from 'react';

interface ChangeOnlinePaymentModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (enabled: boolean) => void;
    enabled: boolean;
    loading: boolean;
}

export const ChangeOnlinePaymentModal = ({ open, onClose, onSubmit, enabled, loading }: ChangeOnlinePaymentModalProps) => {
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        setIsEnabled(enabled);
    }, [enabled]);

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Solicitações de <b>pagamento</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    <p>
                        Ao habilitar o pagamento online para o estabelecimento,
                        App e WebApp estarão disponíveis com as opções de crédito. 
                        Cerifique-se da ação antes de realizá-la e de que o cliente <b>está ciente das taxas.</b>
                    </p>

                    <div className={styles.switchContainer}>
                        <Switch checked={isEnabled} onChange={() => setIsEnabled(!enabled)} />
                        <span className={styles.swithText}>{isEnabled ? 'Desabilitar' : 'Habilitar'} pagamento online</span>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Cancelar</button>
                    <button onClick={() => {
                        if (enabled !== isEnabled) onSubmit(isEnabled)
                        else onClose();
                    }} disabled={loading}>{loading ? 'Enviando...' : 'Salvar'}</button>
                </div>
            </div>
        </Drawer>
    )
}