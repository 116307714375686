import React, { FC } from "react";
import { ManageTitle } from "../manageTitle/ManageTitle";
import { PosPaidCard } from "./components/posPaidCard/PosPaidCard";
import styles from "./PosPaid.module.scss";
import UsePosPaid from "./UsePosPaid";
import { InfinityScroll } from "components/infinityScroll/InfinityScroll";

interface IPosPaidProps {
  cashierId?: string;
}

export const PosPaid: FC<IPosPaidProps> = ({ cashierId }) => {
  const { posPaidList, loading, getPosPaidList, totalRecords, localId } = UsePosPaid(cashierId);

  return (
    <div id={styles.PosPaid}>
      <ManageTitle title="Comandas em aberto" subTitle="Veja quais cartões pós pago ainda não fecharam a comanda." />

      {cashierId && localId && (
        <InfinityScroll className={styles.cardList} onBottomHit={getPosPaidList} hasNext={posPaidList.length < totalRecords} isLoading={loading}>
          {posPaidList && posPaidList.length > 0 && (
            <>
              <div className={styles.list}>
                <h3>Nome</h3>
                <h3>CPF</h3>
                <h3>Valor em aberto</h3>
                {/* <h3>Total consumido</h3> */}
                {/* <h3>Cartão cadastrado</h3> */}
              </div>
              {posPaidList.map((item, key) => (
                <PosPaidCard key={key} posPaid={item} />
              ))}
            </>
          )}
          {posPaidList && posPaidList.length === 0 && (
            <div className={styles.empty}>
              <h3>Nenhum comanda em aberto encontrada!</h3>
              <p>As comandas vinculadas a este evento, aparecerão nesta lista!</p>
            </div>
          )}
        </InfinityScroll>
      )}
      {!cashierId && (
        <div className={styles.empty}>
          <h3>Caixa desvinculado!</h3>
          <p>As comandas vinculadas a este evento, aparecerão nesta lista!</p>
        </div>
      )}
    </div>
  );
};
