
import { IFilterDeviceRequest } from "modules/equipamento/domain/dto/getDevice/IFilterDeviceRequest";
import { deviteTypeList } from "modules/equipamento/domain/enum/DeviceType";
import { IDeviceProfileService } from "modules/config/deviceConfig/domain/interface/IDeviceProfileService";
import { IDeviceItem } from "modules/equipamento/presentation/components/deviceList/interfaces/IDeviceItem";
import { IFilterDevice } from "modules/equipamento/presentation/components/filterDevice/IFilterDevice";
import { IDeviceService } from "modules/equipamento/domain/interface/IDeviceService";

const GetDeviceListUseCase = async (localId: string, deviceService: IDeviceService, deviceFilter?: IFilterDevice): Promise<IDeviceItem[]> => {
    const filter: IFilterDeviceRequest = {
        types: deviceFilter?.types?.map(x => Number(x.id)),
        profileIds: deviceFilter?.profiles?.map(x => x.id),
    }
    const response = await deviceService.GetDeviceList(localId, filter);
    return response.items
}
const GetDeviceListMergedUseCase = async (localId: string, deviceService: IDeviceService, profileService: IDeviceProfileService, deviceFilter?: IFilterDevice): Promise<IDeviceItem[]> => {

    // const filter: IFilterDeviceRequest = { typeIds: deviceFilter.types?.map(x => Number(x.id)), profileIds: deviceFilter.profiles?.map(x => x.id) }
    const filter: IFilterDeviceRequest = {}
    // const promiseWithStatusAndProfile = deviceService.GetDeviceWithStatusAndProfileListUseCase(filter);
    const deviceList = await deviceService.GetDeviceList(localId, filter);

    let mergedDataResponse = deviceList.items.map<IDeviceItem>(deviceItem => ({
        id: deviceItem.id,
        type: deviceItem.type,
        identifier: deviceItem.identifier,
        name: deviceItem.name,
        // profile: deviceItem.profile,
        typeName: deviceItem.type.name || deviteTypeList.find(x => x.id === deviceItem.type.value)?.name || '',
    }));

    if (deviceFilter?.profiles?.length) {
        mergedDataResponse = mergedDataResponse.filter(x => x.profile && deviceFilter.profiles?.map(y => y.id).includes(x.profile!.id));
    }

    if (deviceFilter?.types?.length) {
        mergedDataResponse = mergedDataResponse.filter(x => deviceFilter.types?.map(y => y.id.toString()).includes(x.type.value.toString()));
    }


    return mergedDataResponse;
}

export default GetDeviceListUseCase