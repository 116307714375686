import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IProductListFilter } from "modules/paymentLink/application/useCases/GetSimpleProductsListUseCase";
import {
  IPaymentLinkValuesForm,
  IPaymentlinkValuesErrors,
} from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";
import { IGetSimpleProductsListResponse } from "modules/products/domain/dto/IGetProductsListResponse";
import { FC, useCallback, useRef, useState } from "react";
import styles from "./ValuesForm.module.scss";
import { Checkbox } from "@mui/material";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";

function formatValue(value: string) {
  let numberValue = parseFloat(value.replace(",", "."));
  const result = numberValue.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return result;
}

export interface IValuesForm {
  handleChangeValues: (name: string, value: string | boolean | number) => void;
  errors: IPaymentlinkValuesErrors;
  valuesFormValues?: IPaymentLinkValuesForm;
  getProductsList: (
    request: IProductListFilter
  ) => Promise<IGetSimpleProductsListResponse>;
}

const ValuesForm: FC<IValuesForm> = ({
  errors,
  handleChangeValues,
  valuesFormValues,
}) => {
  const valueRef = useRef<HTMLInputElement>(null);

  const { isMobile } = useUi();

  const [values, setValues] = useState<IPaymentLinkValuesForm>(
    valuesFormValues
      ? {
          ...valuesFormValues,
          showNameOrCompanyNamePix: true,
          showEmailPix: true,
          showDocumentPix: true,
          showEmailBoleto: true,
          price: valuesFormValues.price
            ? formatValue(valuesFormValues.price)
            : "",
          productName: valuesFormValues.productName,
        }
      : { productId: "", price: "", shouldChargeFee: false }
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeValues(event.target.name, Number(event.target.value));
      setValues((prev) => ({
        ...prev,
        [event.target.name]: Number(event.target.value),
      }));
    },
    [handleChangeValues]
  );

  const handleChangeInstallments = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/\D/g, "");
      handleChangeValues(event.target.name, Number(value));
      setValues((prev) => ({
        ...prev,
        [event.target.name]: Number(value),
      }));
    },
    [handleChangeValues]
  );

  const handleChangeSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeValues(event.target.name, event.target.checked);
      setValues((prev) => ({
        ...prev,
        [event.target.name]: event.target.checked,
      }));
    },
    [handleChangeValues]
  );

  const handleChangeRadio = useCallback(
    (_, value: string) => {
      const isChecked = value === "1";
      handleChangeValues("shouldChargeFee", isChecked);
      setValues((prev) => ({
        ...prev,
        shouldChargeFee: isChecked,
      }));
    },
    [handleChangeValues]
  );

  const handleChangeRecurrenceLimit = useCallback(
    (_, value: string) => {
      const isChecked = value === "1";
      const newAmountOfCharges = isChecked ? 1 : 0;

      handleChangeValues("isRecurrenceLimit", isChecked);
      handleChangeValues("amountOfCharges", newAmountOfCharges);

      setValues((prev) => ({
        ...prev,
        isRecurrenceLimit: isChecked,
        amountOfCharges: newAmountOfCharges,
      }));
    },
    [handleChangeValues]
  );

  const handleChangeFirstCharge = useCallback(
    (_, value: string) => {
      const isChecked = value === "1";
      handleChangeValues("billingStartsInCurrentMonth", isChecked);
      setValues((prev) => ({
        ...prev,
        billingStartsInCurrentMonth: isChecked,
      }));
    },
    [handleChangeValues]
  );

  const handleChangeRadioPaymentMethod = useCallback(
    (_, value: string) => {
      const isSinglePayment = value === "1";
      handleChangeValues("isSinglePayment", isSinglePayment);

      if (!isSinglePayment) {
        handleChangeValues("installments", 0);
      }
      if (isSinglePayment) {
        handleChangeValues("amountOfCharges", 0);
        handleChangeValues("isRecurrenceLimit", false);
      }

      setValues((prev) => ({
        ...prev,
        isSinglePayment,
        isRecurrenceLimit: isSinglePayment ? false : prev.isRecurrenceLimit,
        billingStartsInCurrentMonth: isSinglePayment
          ? undefined
          : prev.billingStartsInCurrentMonth,
        installments: isSinglePayment ? prev.installments : 0,
      }));
    },
    [handleChangeValues]
  );

  const handleIncrement = useCallback(() => {
    setValues((prev) => {
      const newValue = (prev?.installments ?? 0) + 1;
      handleChangeValues("installments", newValue);
      return {
        ...prev,
        installments: newValue,
      };
    });
  }, [handleChangeValues]);

  const handleDecrement = useCallback(() => {
    setValues((prev) => {
      const newValue = (prev?.installments ?? 0) - 1;
      handleChangeValues("installments", newValue <= 0 ? 1 : newValue);
      return {
        ...prev,
        installments: newValue <= 0 ? 1 : newValue,
      };
    });
  }, [handleChangeValues]);

  const onChangeCheckbox = useCallback(
    (value: boolean, key: string) => {
      handleChangeValues(key, value);
      if (values) {
        setValues((prev) => ({ ...prev, [key]: value }));
      }
    },
    [handleChangeValues, values]
  );

  return (
    <div id={styles.ValuesForm}>
      <div className={styles.paymentFormContainer}>
        <p className={styles.title}>
          Selecione <b>quais formas de pagamento</b> deseja
        </p>
        <div className={styles.value}>
          <FormGroup
            className={styles.formGroup}
            style={
              errors?.paymentForm
                ? {
                    border: "1px solid #f44336",
                    borderRadius: 5,
                    backgroundColor: "red",
                  }
                : {}
            }
          >
            <FormGroup className={styles.methodPayment}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={values?.credit}
                    onChange={handleChangeSwitch}
                    name="credit"
                  />
                }
                label="Crédito"
              />
              {values?.credit && (
                <>
                  <div className={styles.installmentsFee}>
                    <RadioGroup
                      row
                      onChange={handleChangeRadioPaymentMethod}
                      defaultValue={1}
                      value={values?.isSinglePayment ?? false ? 1 : 0}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Pagamento único"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Pagamento recorrente"
                      />
                    </RadioGroup>
                  </div>
                  <div className={styles.installmentsFee}>
                    <label>
                      Repassar taxa de juros para o cliente?
                      <Tooltip
                        title="Taxa de juros"
                        text="A taxa de juros aplicada é cobrada com base no contrato. Verifique em: Financeiro > Taxas"
                      />
                    </label>
                    <RadioGroup
                      row
                      onChange={handleChangeRadio}
                      defaultValue={0}
                      value={values?.shouldChargeFee ?? false ? 1 : 0}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Sim"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Não"
                      />
                    </RadioGroup>
                  </div>
                  {values.isSinglePayment && (
                    <div
                      className={styles.installmentsContainer}
                      style={{ flexDirection: isMobile ? "column" : "row" }}
                    >
                      <div className={styles.installments}>
                        <label>
                          Máximo de parcelas<span> *</span>
                        </label>
                        <TextField
                          inputRef={valueRef}
                          size="small"
                          placeholder={"1"}
                          type="number"
                          value={
                            values?.installments
                              ? values.installments > 0
                                ? values.installments
                                : undefined
                              : undefined
                          }
                          variant="outlined"
                          name={"installments"}
                          onChange={handleChangeInstallments}
                          error={!!errors?.installments}
                          helperText={errors?.installments}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className={styles.inputArrows}
                              >
                                <KeyboardArrowUpIcon
                                  onClick={handleIncrement}
                                />
                                <KeyboardArrowDownIcon
                                  onClick={handleDecrement}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {!values.isSinglePayment && (
                    <>
                      <div className={styles.installmentsFee}>
                        <label>
                          Limite de recorrência
                          <Tooltip
                            title="Limite de recorrência"
                            text="O limite de recorrência define o prazo de cobrança. O limite do cartão não será afetado."
                          />
                        </label>
                        <RadioGroup
                          row
                          onChange={handleChangeRecurrenceLimit}
                          defaultValue={0}
                          value={values?.isRecurrenceLimit ?? false ? 1 : 0}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Sim"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Não"
                          />
                        </RadioGroup>

                        {values.isRecurrenceLimit && (
                          <div className={styles.installments}>
                            <label>Quantidade de cobranças</label>
                            <TextField
                              size="small"
                              value={values.amountOfCharges}
                              type="number"
                              variant="outlined"
                              name={"amountOfCharges"}
                              onChange={handleChange}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className={styles.installmentsFee}>
                        <label>Primeira cobrança</label>
                        <RadioGroup
                          row
                          onChange={handleChangeFirstCharge}
                          defaultValue="1"
                          value={
                            values.billingStartsInCurrentMonth ?? false ? 1 : 0
                          }
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Mês atual"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Mês seguinte"
                          />
                        </RadioGroup>
                      </div>
                    </>
                  )}
                </>
              )}
            </FormGroup>
            <FormGroup className={styles.methodPayment}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={values?.pix}
                    onChange={handleChangeSwitch}
                    name="pix"
                  />
                }
                label="PIX"
              />

              {values.pix && (
                <div
                  className={styles.pixContainer}
                  style={{ flexDirection: isMobile ? "column" : "row" }}
                >
                  <label>
                    <b>Solicitar seguintes dados</b>
                  </label>
                  <div className={styles.pixContent}>
                    <div className={styles.pixCheckBox}>
                      <FormControlLabel
                        checked={values?.showDocumentPix}
                        label={"Documento (CPF/CNPJ)"}
                        defaultChecked
                        disabled
                        onChange={(_, checked) => {
                          onChangeCheckbox(checked, "showDocumentPix");
                        }}
                        control={<Checkbox color="secondary" />}
                      />
                      <span>* Obrigatório</span>
                    </div>
                  </div>
                  <div className={styles.pixCheckBox}>
                    <FormControlLabel
                      checked={values?.showNameOrCompanyNamePix}
                      label={"Nome/Razão social"}
                      color="#333"
                      defaultChecked
                      disabled
                      onChange={(_, checked) => {
                        onChangeCheckbox(checked, "showNameOrCompanyNamePix");
                      }}
                      control={<Checkbox />}
                    />
                    <span>* Obrigatório</span>
                  </div>
                  <div className={styles.pixCheckBox}>
                    <FormControlLabel
                      checked={values?.showPhoneNumberPix}
                      label={"Telefone"}
                      onChange={(_, checked) => {
                        onChangeCheckbox(checked, "showPhoneNumberPix");
                      }}
                      control={<Checkbox />}
                    />
                  </div>
                  <div className={styles.pixCheckBox}>
                    <FormControlLabel
                      checked={values?.showEmailPix}
                      label={"E-mail"}
                      defaultChecked
                      disabled
                      onChange={(_, checked) => {
                        onChangeCheckbox(checked, "showEmailPix");
                      }}
                      control={<Checkbox />}
                    />
                    <span>* Obrigatório</span>
                  </div>
                  <div className={styles.pixCheckBox}>
                    <FormControlLabel
                      checked={values?.showAddressPix}
                      label={"Endereço"}
                      onChange={(_, checked) => {
                        onChangeCheckbox(checked, "showAddressPix");
                      }}
                      control={<Checkbox />}
                    />
                  </div>
                </div>
              )}
            </FormGroup>
            <FormGroup className={styles.methodPayment}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={values?.ticket}
                    onChange={handleChangeSwitch}
                    name="ticket"
                  />
                }
                label="Boleto"
              />
              {values?.ticket && (
                <>
                  <p className={styles.ticketWarning}>
                    O pagamento será liquidado em até 2 dias úteis.
                  </p>
                  <div
                    className={styles.pixContainer}
                    style={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    <label>
                      <b>Solicitar seguintes dados</b>
                    </label>
                    <div className={styles.pixContent}>
                      <FormControlLabel
                        checked={values?.showDocumentBoleto}
                        label={"Documento (CPF/CNPJ)"}
                        onChange={(_, checked) => {
                          onChangeCheckbox(checked, "showDocumentBoleto");
                        }}
                        control={<Checkbox color="secondary" />}
                      />
                    </div>
                    <div className={styles.pixCheckBox}>
                      <FormControlLabel
                        checked={values?.showNameOrCompanyNameBoleto}
                        label={"Nome/Razão social"}
                        color="#333"
                        onChange={(_, checked) => {
                          onChangeCheckbox(
                            checked,
                            "showNameOrCompanyNameBoleto"
                          );
                        }}
                        control={<Checkbox />}
                      />
                    </div>
                    <div className={styles.pixCheckBox}>
                      <FormControlLabel
                        checked={values?.showPhoneNumberBoleto}
                        label={"Telefone"}
                        onChange={(_, checked) => {
                          onChangeCheckbox(checked, "showPhoneNumberBoleto");
                        }}
                        control={<Checkbox />}
                      />
                    </div>
                    <div className={styles.pixCheckBox}>
                      <FormControlLabel
                        checked={values?.showEmailBoleto}
                        label={"E-mail"}
                        defaultChecked
                        disabled
                        onChange={(_, checked) => {
                          onChangeCheckbox(checked, "showEmailBoleto");
                        }}
                        control={<Checkbox />}
                      />
                      <span>* Obrigatório</span>
                    </div>
                    <div className={styles.pixCheckBox}>
                      <FormControlLabel
                        checked={values?.showAddressBoleto}
                        label={"Endereço"}
                        onChange={(_, checked) => {
                          onChangeCheckbox(checked, "showAddressBoleto");
                        }}
                        control={<Checkbox />}
                      />
                    </div>
                  </div>
                </>
              )}
            </FormGroup>
          </FormGroup>
          {errors?.paymentForm && (
            <p className={styles.error}>{errors.paymentForm}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValuesForm;
