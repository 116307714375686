import { CircularProgress, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { IPaymentMethod, IRecordPermutationAccount } from 'modules/events/domain/dto/IEventsResponse';
import { FC, useEffect, useState } from 'react';
import CPF from 'services/utils/CPF';
import { moneyMaskNumber } from 'services/utils/Money';
import styles from './CashlessConsummationForm.module.scss';
import { ICashlessConsummationForm } from './interfaces/ICashlessConsummationForm';
import UseCashlessConsummationForm from './UseCashlessConsummationForm';
import { InfoOutlined } from '@mui/icons-material';

interface ICashlessConsummationFormProps {
  defaultVoucher?: ICashlessConsummationForm;
  handleSubmit: (cashlessValues: ICashlessConsummationForm) => Promise<void>;
  handleCancel?: () => void;
}

export const CashlessConsummationForm: FC<ICashlessConsummationFormProps> = ({
  defaultVoucher,
  handleSubmit,
  handleCancel,
}) => {
  const {
    values,
    voucherValue,
    errors,
    paymentMethods,
    isCpfLoaded,
    cpfLoading,
    handleSubmitForm,
    handleChangeValues,
    HandleChangeDiscountValue,
    handleChangePaymentType,
    handleAddValue,
    handleReduceValue,
    permutationAccount,
    handleChangeCpf,
    handleChangePermutation,
    paymentTypeId,
    balancePermutation,
    setSelectedPaymentMethod,
    permutationAccountSelected,
    selectedPaymentMethod,
    permutationTypeId
  } = UseCashlessConsummationForm(handleSubmit, defaultVoucher);

  const [valueError, setValueError] = useState<boolean>(true);

  useEffect(() => {
    if (balancePermutation() >= 0) {
      setValueError(false)
    } else {
      setValueError(true)
    }
  }, [balancePermutation]);

  const exibirTextVerdeVermelho = () => {
    if (paymentTypeId.current === 21) {
      if (values.permutationAccountId !== null && values.permutationAccountId !== undefined && values.permutationAccountId !== '') {
        if (valueError) {
          return <span className={styles.valueMaxError}>Valor superior ao valor máximo disponível.</span>
        } else {
          return <span className={styles.valueMax}>Valor máximo disponível: R$ {balancePermutation()}</span>
        }
      } else {
        return ''
      }
    } else {
      return '';
    }
  }

  if (!paymentMethods.length) {
    return <div>Carregando...</div>;
  }


  return (
    <div id={styles.CashlessConsummationForm}>
      <form onSubmit={handleSubmitForm}>
        <FormItemContainer label={'CPF'} flex={0}>
          <Input
            fullWidth={false}
            disabled={!!defaultVoucher || cpfLoading}
            placeholder="000.000.000-00"
            value={CPF.cpfMask(values?.cpf || '')}
            name="cpf"
            onChange={handleChangeCpf}
            error={!!errors.cpf}
            variant='outlined'
            helperText={errors.cpf}
            inputProps={{
              maxLength: 14,
            }}
          />
          {cpfLoading && <CircularProgress className={styles.spinner} color="primary" />}
        </FormItemContainer>
        <FormItemContainer label={'Nome'} flex={0}>
          <Input
            fullWidth={false}
            value={values?.name || ''}
            disabled={!!defaultVoucher || cpfLoading || isCpfLoaded}
            placeholder="Insirá o nome da pessoa"
            name="name"
            onChange={handleChangeValues}
            error={!!errors.name}
            variant='outlined'
            helperText={errors.name}
            inputProps={{
              maxLength: 50,
            }}
          />
        </FormItemContainer>
        <FormItemContainer label={'Descrição'} flex={0}>
          <Input
            fullWidth={false}
            disabled={cpfLoading}
            variant='outlined'
            value={values?.motive || ''}
            placeholder="Ex.: Membro da produção"
            name="motive"
            onChange={handleChangeValues}
            error={!!errors.motive}
            helperText={errors.motive}
            inputProps={{
              maxLength: 50,
            }}
          />
        </FormItemContainer>
        <FormItemContainer label="Forma de pagamento" flex={0}>
          <AutoComplete
            options={paymentMethods}
            disabled={cpfLoading || !!defaultVoucher}
            variant='outlined'
            placeholder="Selecione a forma de pagamento"
            noOptionsText="Sem opções"
            name="paymentType"
            renderOption={(option) => (
              <li key={option.id.toString()}>{option.description}</li>
            )}
            value={selectedPaymentMethod || undefined}
            getOptionLabel={option => option.description}
            onChange={(_event, newValue) => handleChangePaymentType(newValue as IPaymentMethod)}

            error={!!errors.paymentType}
            helperText={errors.paymentType}
          />
        </FormItemContainer>
        {
          paymentTypeId.current === 21 && (
            <FormItemContainer label="Selecione a permuta *" flex={0}>
              <AutoComplete
                options={permutationAccount?.records && permutationAccount?.records.length === 1 ? [permutationAccount.records[0]] : permutationAccount?.records ?? []}
                disabled={cpfLoading || !!defaultVoucher}
                variant='outlined'
                placeholder="Selecione a permuta que utilizará"
                noOptionsText="Sem opções"
                name="permutation"
                value={!!defaultVoucher ? permutationAccount?.records[0] : undefined}
                getOptionLabel={option => option.name}
                onChange={(_value, item) =>
                  handleChangePermutation(item as IRecordPermutationAccount)
                }
                error={!!errors.paymentType}
                helperText={errors.paymentType}
              />
            </FormItemContainer>
          )
        }
        {!defaultVoucher ? (
          <FormItemContainer label={'Valor'} flex={0}>
            <Input
              fullWidth={false}
              variant='outlined'
              disabled={!!defaultVoucher || cpfLoading}
              value={values?.initialBalance}
              name="initialBalance"
              onChange={HandleChangeDiscountValue}
              error={!!errors.initialBalance}
              helperText={errors.initialBalance}
            />
            {exibirTextVerdeVermelho()}
          </FormItemContainer>
        ) : (
          <FormItemContainer label={'Valor'} flex={0}>
            <Input
              fullWidth={false}
              variant='outlined'
              disabled={!defaultVoucher || cpfLoading}

              name="initialBalance"
              onChange={HandleChangeDiscountValue}
              error={!!errors.initialBalance}
              helperText={errors.initialBalance}
            />
            {exibirTextVerdeVermelho()}
          </FormItemContainer>
        )}
        {
          defaultVoucher &&
          <div className={styles.currentBalance}>
            <p>Saldo atual: </p>
            <span>
              {moneyMaskNumber(defaultVoucher.currentBalance + voucherValue)}
            </span>
          </div>
        }
        {
          !permutationTypeId && !!defaultVoucher &&
          <div className={styles.info}>
            <span>
              <InfoOutlined />
              Não é possível adicionar saldo utilizando permuta junto a outras formas de pagamento
            </span>
          </div>
        }
        {
          !permutationTypeId && !defaultVoucher &&
          <div className={styles.info}>
            <span>
              <InfoOutlined />
              Já existe saldo no cashless virtual para o usuário
            </span>
            <span className={styles.text}>Não é possível adicionar saldo utilizando permuta junto a outras formas de pagamento</span>
          </div>
        }
        <div className={styles.buttons}>
          <Button fullWidth={false} variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button fullWidth={false} type="submit" disabled={cpfLoading || valueError}>
            Confirmar
          </Button>
        </div>
      </form>
    </div>
  );
};
