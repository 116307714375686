import InventoryOperationsService from 'modules/meepErp/services/api/stockOperations/StockOperationsService';
import { IGetStockOperationListRequest } from '../../dtos/stockOperations/IGetStockOperationListRequest';
import { IStockOperationFilterValue } from 'modules/meepErp/presentation/components/stockOperations/stockOperationsList/InventoryOperationsFilter/IInvetoryFilter';

export const getStockOperationsUseCase = async (localId: string, filter: IStockOperationFilterValue) => {
    const service = InventoryOperationsService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.orderBy?.length ? filter.orderBy : undefined;
    const request: IGetStockOperationListRequest = {
        page: page,
        pageSize: pageSize,
        orderBy: sortBy ??  'code',
        ascending: sortBy ? filter.ascending : true,
        typeId: filter.type,
        name: filter.name,
        code: filter.code
    };
    return service.getInventoryOperationsList(localId, request);
};
export const getOpeartionTypesUseCase = async () => {
    return InventoryOperationsService().getOperationTypes();
};
export const onDeleteOperationUseCase = async (id: string) => {
    const service = InventoryOperationsService();
    return await service.deleteInventoryOperation(id);

};
