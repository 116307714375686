import React, { useEffect, useState } from "react";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { Skeleton } from "../skeleton/Skeleton";
import styles from "../rechargePaymentMethods/RechargePaymentMethods.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import { IGetPreRechargeMethodsDataResponse } from "modules/cashlessDashboard/domain/dto/preRechargePaymentMethodsData/IGetPreRechargeMethodsDataResponse";

export interface IPreRechargePaymentMethodsProps {
  defaultData?: IGetPreRechargeMethodsDataResponse;
  onGeneratingPDF?: boolean;
}

const PreRechargePaymentMethods: React.FC<IPreRechargePaymentMethodsProps> = ({
  defaultData,
  onGeneratingPDF,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IGetPreRechargeMethodsDataResponse>();

  const { setOnError, clearContainerError } = useError();

  useEffect(() => {
    (() => {
      try {
        clearContainerError(dashboardContainerEnum.PRE_RECHARGE);
        setLoading(true);
        if (defaultData) setData(defaultData);
      } catch {
        setOnError({
          type: "error",
          containerId: dashboardContainerEnum.PRE_RECHARGE,
        });
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    })();
  }, [clearContainerError, defaultData, setOnError]);

  if (loading) {
    return (
      <div id={styles.RechargePaymentMethods}>
        <div className={styles.container}>
          <div className={styles.column}>
            <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.RechargePaymentMethods}>
      <div className={styles.container}>
        <div className={styles.column} style={{ flexWrap: "nowrap" }}>
          <div className={styles.displayColumn}>
            <div className={styles.contentTitle}>
              <span className={styles.label}>
                Total
                {!onGeneratingPDF && <Tooltip
                  buttonClassName={styles.helpIcon}
                  title="Valor total da pré-recarga"
                  text="Somatório dos totais de pré-recarga de todos os meios de pagamento."
                />}
              </span>
              <span className={styles.total}>
                {moneyMaskNumber(data?.total)}
              </span>
            </div>
            <div className={styles.displayColumn} style={{ gap: "8px" }}>
              {data?.transacted && data.transacted.length 
                ? data.transacted.map((item) => (
                  <div className={styles.displayRow} key={item.paymentDescription}>
                    <span className={styles.label}>{item.paymentDescription}</span>
                    <span className={styles.value}>
                      {moneyMaskNumber(item.total)}
                    </span>
                  </div>
                ))
                : <div />
              }
            </div>
          </div>
          <div className={styles.displayColumn}>
            <div className={styles.column} style={{ alignItems: "center" }}>
              <div className={styles.contentTitle}>
                <span className={styles.label}>
                  Valor total ativado
                  {!onGeneratingPDF && <Tooltip
                    buttonClassName={styles.helpIcon}
                    title="Valor total ativado"
                    text="Somatório do valor das pré-recargas ativadas, ou seja, transferidas para cartões ou pulseiras cashless."
                  />}
                </span>
                <span className={styles.total}>
                  {moneyMaskNumber(data?.totalTransacted)}
                </span>
              </div>
            </div>
            <div className={styles.column} style={{ alignItems: "center" }}>
              <div className={styles.contentTitle}>
                <span className={styles.label}>
                  Valor residual
                  {!onGeneratingPDF && <Tooltip
                    buttonClassName={styles.helpIcon}
                    title="Valor residual"
                    text="Somatório do valor das pré-recargas que não foram ativadas, ou seja, que não foram transferidos para cartões ou pulseiras cashless."
                  />}
                </span>
                <span className={styles.total}>
                  {moneyMaskNumber(data?.residual)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRechargePaymentMethods;
