import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { DeleteUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/DeleteUnavailabilityByServiceIdUseCase';
import { GetAllDemandedServicesByPlaceId } from 'modules/schedule/application/useCases/placeSchedule/GetAllDemandedServicesByPlaceIdUseCase';
import { PostAddUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostAddUnavailabilityByServiceIdUseCase';
import { PutEditUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PutEditUnavailabilityByServiceIdUseCase';
import { ISpecialDays } from 'modules/schedule/domain/models/ISpecialDays';
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom';
import PlacesScheduleApi from 'services/api/schedule/places/PlacesScheduleApi';

const UseSpecialDaysForm = (reloadPageEdit: () => void) => {
    const [values, setValues] = useState<ISpecialDays[]>([]);
    const [specialDays, setSpecialDays] = useState<ISpecialDays>();
    const [isEdit, setIsEdit] = useState(false);
    const [selectedStart, setSelectedStart] = useState<string>('');
    const [selectedEnd, setSelectedEnd] = useState<string>('');
    const [timeStart, setTimeStart] = useState<string>('');
    const [timeEnd, setTimeEnd] = useState<string>('');
    const [isOpenChecked, setIsOpenChecked] = useState<boolean>(false);
    const [amountOccupancy, setAmountOccupancy] = useState<number>(0)

    const [toggleModalUnavailability, setToggleModalUnavailability] = useState(false);
    const [toggleHaveReservationModal, setToggleHaveReservationModal] = useState(false);
    const { showLoading, hideLoading, toast } = useUi();

    const { placeId } = useParams<{ placeId: string }>();

    const onClickRemoveUnavailability = useCallback((id: string) => {
        showLoading()
        DeleteUnavailabilityByServiceIdUseCase(id, PlacesScheduleApi).then(response => {
            hideLoading();            
            toast("Dia especial deletado com sucesso", "success");
            reloadPageEdit();
        })
    }, []);

    const changeAmountOccupancyHandle = (value: string) => {
        setAmountOccupancy(Number.parseFloat(value) | 0);
    }

    const handleChangeChecked = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsOpenChecked(checked);
    };

    const handleOpenModalEdit = useCallback((item: ISpecialDays) => {

        setSelectedStart(item.start.split('T')[0])
        setSelectedEnd(item.end.split('T')[0])
        setTimeEnd(item.opened?.end || item.closed?.end || '')
        setTimeStart(item.opened?.start || item.closed?.start || '')
        setAmountOccupancy(item.opened?.maxOccupancy || 0);
        setSpecialDays(item);
        setIsOpenChecked(!!item.opened)

        setIsEdit(true);
        setToggleModalUnavailability(!toggleModalUnavailability)
    }, [placeId, selectedEnd, selectedStart, toggleModalUnavailability, values]);


    const handleModal = () => {
        setToggleModalUnavailability(!toggleModalUnavailability)
        setIsEdit(false);
        setSelectedEnd('');
        setSelectedStart('');
        setTimeStart('');
        setTimeEnd('');
        setAmountOccupancy(0);
        setIsOpenChecked(false)
    }

    const handleModalHaveReservation = useCallback(() => {

        setToggleHaveReservationModal(!toggleHaveReservationModal);

    }, [toggleHaveReservationModal]);

    const UnavailabilityHandle = useCallback(async () => {
        showLoading();

        if (isEdit) {

            if (specialDays) {
                const request: ISpecialDays = {
                    id: specialDays.id,
                    start: selectedStart,
                    end: selectedEnd,
                    opened: isOpenChecked ? {
                        maxOccupancy: amountOccupancy,
                        start: timeStart,
                        end: timeEnd
                    } : undefined,
                    closed: !isOpenChecked ? {
                        start: timeStart,
                        end: timeEnd
                    } : undefined
                }

                specialDays.id && PutEditUnavailabilityByServiceIdUseCase(request, specialDays.id, PlacesScheduleApi).then(() => {
                    setToggleModalUnavailability(false);
                    toast("Dia especial editado com sucesso", "success");
                    hideLoading();
                    setToggleHaveReservationModal(false)
                }).catch((error) => {
                    toast('Falha em adicionar data especial', 'error');
                }).finally(() => {
                    reloadPageEdit();
                });
            }

        } else {

            const request: ISpecialDays = {
                start: selectedStart,
                end: selectedEnd,
                opened: isOpenChecked ? {
                    maxOccupancy: amountOccupancy,
                    start: timeStart,
                    end: timeEnd
                } : undefined,
                closed: !isOpenChecked ? {
                    start: timeStart,
                    end: timeEnd
                } : undefined
            }

            PostAddUnavailabilityByServiceIdUseCase(request, placeId, PlacesScheduleApi).then(() => {
                setToggleModalUnavailability(false);
                toast("Dia especial adicionado com sucesso", "success");
                hideLoading();
                setToggleHaveReservationModal(false)

            }).catch((error) => {
                toast('Falha em adicionar data especial', 'error');
            }).finally(() => {
                reloadPageEdit();
            });

        }

    }, [showLoading, isEdit, specialDays, selectedStart, selectedEnd, isOpenChecked, amountOccupancy, timeStart, timeEnd, toast, hideLoading, placeId]);

    const haveReservationThisDay = useCallback(async () => {

        const response = await GetAllDemandedServicesByPlaceId(selectedStart, selectedEnd, placeId, PlacesScheduleApi);
        if (response.length > 0) {
            handleModalHaveReservation();
        } else {
            setToggleHaveReservationModal(false)
            UnavailabilityHandle();
        }

    }, [UnavailabilityHandle, handleModalHaveReservation, placeId, selectedEnd, selectedStart]);

    return (
        {
            values,
            selectedStart,
            selectedEnd,
            setSelectedStart,
            setSelectedEnd,
            toggleModalUnavailability,
            onClickRemoveUnavailability,
            handleModal,
            handleOpenModalEdit,
            isEdit,
            haveReservationThisDay,
            toggleHaveReservationModal,
            handleModalHaveReservation,
            UnavailabilityHandle,
            timeStart,
            setTimeStart,
            timeEnd,
            setTimeEnd,
            isOpenChecked,
            setIsOpenChecked,
            amountOccupancy,
            setAmountOccupancy,
            changeAmountOccupancyHandle,
            handleChangeChecked

        }
    )
}

export default UseSpecialDaysForm
