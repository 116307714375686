
import { ISaveInvoiceRequest, ISaveInvoiceSupplyRequest } from "../../dtos/invoice/IInvoiceDtos";
import { IInvoiceFormValue } from "modules/meepErp/presentation/components/invoices/IInvoiceFormValue";
import InvoiceErpService from "modules/meepErp/services/api/Invoice/InvoiceErpService";

export const AddInvoiceUseCase = async (localId: string, invoice: IInvoiceFormValue) => {
  const service = InvoiceErpService();
  const request: ISaveInvoiceRequest = InvoiceFormValueToSaveRequest(invoice);
  const response = await service.addInvoice(localId, request);
  return response;
}


export const UpdateInvoiceUseCase = async (localId: string, id: string, invoice: IInvoiceFormValue) => {
  const service = InvoiceErpService();
  const request: ISaveInvoiceRequest = InvoiceFormValueToSaveRequest(invoice);
  const response = await service.updateInvoice(localId, id, request);
  return response;
}


function InvoiceFormValueToSaveRequest(invoice: IInvoiceFormValue): ISaveInvoiceRequest {
  return {
    partnerId: invoice.partnerId,
    createdById: invoice.createdById,
    operationId: invoice.operationId,
    number: invoice.number || "",
    series: invoice.series || "",
    accessKey: invoice.accessKey || "",
    issueDate: invoice.issueDate,
    date: invoice.date,
    shipping: {
      amount: invoice.shipping || 0,
      currency: invoice.currency
    },
    increase: {
      amount: invoice.increase || 0,
      currency: invoice.currency
    },
    insurance: {
      amount: invoice.insurance || 0,
      currency: invoice.currency
    },
    discount: {
      amount: invoice.discountValue || 0,
      currency: invoice.currency
    },
    others: {
      amount: invoice.others || 0,
      currency: invoice.currency
    },
    stHighlighted: {
      amount: invoice.stHighlighted || 0,
      currency: invoice.currency
    },
    observation: invoice.observation || "",
    supplies: invoice?.supplies?.map<ISaveInvoiceSupplyRequest>((supply) => ({
      supplyId: supply.supplyId,
      storageLocationId: supply.storageLocationId,
      // inventoryQuantity: supply.inventoryQuantity,
      unitPrice: {
        amount: supply.unitPrice,
        currency: invoice.currency
      },
      quantity: supply.quantity,
      measurementUnitId: supply.measurementUnitId,
      icms: {
        amount: supply.icms,
        currency: invoice.currency
      },
      confins: {
        amount: supply.confins,
        currency: invoice.currency
      },
      pis: {
        amount: supply.pis,
        currency: invoice.currency
      },
      st: {
        amount: supply.st,
        currency: invoice.currency
      },
      ipi: {
        amount: supply.ipi,
        currency: invoice.currency
      },
      discountPercentage: supply.discountPercentage,
      discountValue: {
        amount: supply.discountValue,
        currency: invoice.currency
      }
    }))
  };
}
