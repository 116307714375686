import { erpUrl } from "Enviroment";
import { IGetInvoiceResponse, IInvoiceListRequest, IInvoiceListResponse, ISaveInvoiceRequest, ISaveInvoiceResponse } from "modules/meepErp/application/dtos/invoice/IInvoiceDtos"
import { Api } from "services/api/Api";


const BaseUrl = erpUrl + '/api/Invoices';
const InvoiceErpService = () => {
  const api = Api();


  const addInvoice = async (localId: string, request: ISaveInvoiceRequest): Promise<ISaveInvoiceResponse> => {
    const response = await api.post<ISaveInvoiceResponse>(`${BaseUrl}`, request)
    return response.data;
  }

  const getInvoice = async (localId: string, id: string): Promise<IGetInvoiceResponse> => {
    const response = await api.get<IGetInvoiceResponse>(`${BaseUrl}/${id}`)
    return response.data;
  }

  const getInvoices = async (localId: string, request: IInvoiceListRequest): Promise<IInvoiceListResponse> => {
    const response = await api.get<IInvoiceListResponse>(BaseUrl, { params: request })
    return response.data;
  }
  const updateInvoice = async (localId: string, id: string, request: ISaveInvoiceRequest): Promise<ISaveInvoiceResponse> => {
    const response = await api.put<ISaveInvoiceResponse>(`${BaseUrl}/${id}`, request)
    return response.data;
  }

  const deleteInvoice = async (localId: string, id: string): Promise<void> => {
    await api.delete(`${BaseUrl}/${id}`)
  }

  return ({
    addInvoice,
    getInvoice,
    getInvoices,
    updateInvoice,
    deleteInvoice
  })
}
export default InvoiceErpService
