import { ISpecialDaysRequest } from "modules/schedule/domain/dto/placeSchedule/IAddUnavailabilityRequest";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const DeleteUnavailabilityByServiceIdUseCase = async (id: string, placeScheduleApi: PlaceScheduleApiFunction) => {
    const _placeScheduleApi = placeScheduleApi();
    await _placeScheduleApi.deleteUnavailability(id);
    return null;
}


