import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { ICloseEvent } from "../../ICloseEvent";
import { CloseTitle } from "../closeTitle/CloseTitle";
import styles from "./ProductConsumption.module.scss";

interface IResumeProps {
  event: ICloseEvent;
  isExport: boolean;
  renderPercentage: Function;
}

export const ProductConsumption: FC<IResumeProps> = ({ event, isExport, renderPercentage }) => {
  return (
    <div id={styles.ProductConsumption}>
      <CloseTitle
        isExport={isExport}
        title="Consumo de produtos"
        subTitle="Informações referente ao consumo de produtos."
      />
      <div className={isExport ? styles.export : ""}>
        <section className={styles.row}>
          <section className={styles.card}>
            <h2>
              {moneyMaskNumber(
                event?.eventProductConsumption.totalBilled.totalBilled
              )}
            </h2>
            <h3>Total faturado</h3>
            <p>
              <span className={styles.description}>Taxa de serviço:</span>
              {`${moneyMaskNumber(
                event?.eventProductConsumption.totalBilled.serviceTax
              )} (${renderPercentage(
                event?.eventProductConsumption.totalBilled.serviceTax,
                event?.eventProductConsumption.totalBilled.totalBilled
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Consumo de produtos:</span>
              {`${moneyMaskNumber(
                event?.eventProductConsumption.totalBilled.productConsumption
              )} (${renderPercentage(
                event?.eventProductConsumption.totalBilled.productConsumption,
                event?.eventProductConsumption.totalBilled.totalBilled
              )}%)`}
            </p>
          </section>
          <section className={styles.card}>
            <h2>
              {moneyMaskNumber(
                event?.eventProductConsumption.averageSpend.total
              )}
            </h2>
            <h3>Ticket médio de consumo</h3>
          </section>
        </section>
      </div>
    </div>
  );
};
