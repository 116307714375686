import styles from './AppParams.module.scss';
import { Close, ExpandLessRounded, InfoOutlined, KeyboardArrowRightRounded, LaunchRounded, WatchLaterOutlined } from '@material-ui/icons';
import UseDimension from 'components/dimension/UseDimension';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material';
import UseConfigValues from '../../hooks/UseConfigValues';
import { format } from 'date-fns';
import { CheckOutlined, Circle } from '@mui/icons-material';
import { segmentos } from "../../pages/uteis";
import { Autocomplete } from '@material-ui/lab';
import { ICatalogListItem } from 'modules/catalog/presentation/componentes/catalogListTab/CatalogListTab';
import Button from 'components/ui/Button/Button';
import { IResponseConfigLocal } from 'modules/gestaoVendas/domain/dtos/IGetSegmentos';
import { IGetOnlinePaymentStatusUseCase } from 'modules/onlinePayment/domain/dtos/IGetOnlinePaymentStatusUseCase';
import { IGetConfigDataResponse } from 'modules/gestaoVendas/application/interfaces/IGestaoVendasService';

export interface IAppParamsProps {
    isAppMeepVisible: boolean;
    setIsAppMeepVisible: React.Dispatch<React.SetStateAction<boolean>>;
    postSuccess: boolean;
    setPostSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    postError: boolean;
    setPostError: React.Dispatch<React.SetStateAction<boolean>>;
    onConfigChangeHandle: (name: string, value: string | number | boolean) => void
    catalogList: ICatalogListItem[]
    responseConfigLocal: IResponseConfigLocal | undefined
    setOperation: Dispatch<SetStateAction<IOperationOptionValue[]>>
    operation: IOperationOptionValue[];
    setOpenPaymentSidesheet: (value: SetStateAction<string | null>) => void;
    onlinePaymentStatus: IGetOnlinePaymentStatusUseCase;
    dataConfig?: IGetConfigDataResponse;
}

export type IOperationOptionValue = {
    value: string;
    name: string;
};

const operationsOptnos: IOperationOptionValue[] = [
    {
        value: "allowConsumeCashless",
        name: "Cashless"
    },
    {
        value: "allowConsumeTicketInApp",
        name: "Ficha"
    },
];

const AppParams = ({
    isAppMeepVisible,
    setIsAppMeepVisible,
    postSuccess,
    setPostSuccess,
    postError,
    setPostError,
    onConfigChangeHandle,
    catalogList,
    responseConfigLocal,
    setOperation,
    operation,
    setOpenPaymentSidesheet,
    onlinePaymentStatus,
    dataConfig,
}: IAppParamsProps) => {
    const { dimensions } = UseDimension();
    const { configValues } = UseConfigValues();

    const [localType, setLocalType] = useState("");

    useEffect(() => {
        if (responseConfigLocal) {
            const localName = segmentos.find((item) => item.id === responseConfigLocal.tipoLocalId)?.nome;
            if (localName) setLocalType(localName);
        }
    }, [responseConfigLocal]);

    const hasPaymentSolicitation = useMemo(
        () => (postError || !onlinePaymentStatus.isEnabled) && !!onlinePaymentStatus.solicitationDate,
    [onlinePaymentStatus, postError]);

    const handleAutoCompleteChange = useCallback((ev: any, newValue: IOperationOptionValue[]) => {
        setOperation(newValue);
    }, [setOperation]);

    return (
        <div id={styles.AppParams}>
            <div className={styles.header}>
                <div>
                    <h1>App Meep</h1>
                    <div className={styles.headerClick}>
                        <LaunchRounded style={{ color: "rgba(149, 92, 255, 1)" }} />
                    </div>
                </div>
                <div>
                    {dimensions.width >= 650 && (
                        <div>
                            <Checkbox
                                style={{ width: "40px", height: "40px", color: "rgba(50, 0, 142, 1)" }}
                                name="isVisibleInApp"
                                checked={configValues.isVisibleInApp ?? null}
                                onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                            />
                            <label>Visível no App</label>
                        </div>
                    )}
                    <TextField
                        variant='outlined'
                        value={configValues.isActive ? "Loja aberta" : "Loja fechada"}
                        InputProps={{
                            startAdornment: (
                                <Circle style={{ color: configValues.isActive ? "#399C54" : "#ed0029", marginRight: "10px" }} />
                            )
                        }}
                        size="small"
                        style={{ width: "160px", margin: 0 }}
                        disabled
                    />
                    <div
                        onClick={() => setIsAppMeepVisible(!isAppMeepVisible)}
                        style={{ transform: isAppMeepVisible ? "rotate(0deg)" : "rotate(180deg)" }}
                    >
                        <ExpandLessRounded style={{ width: "35px", height: "35px" }} />
                    </div>
                </div>
            </div>

            {isAppMeepVisible && (
                <div className={styles.content}>
                    {postSuccess && (
                        <div
                            className={styles.postFeedback}
                            style={{ backgroundColor: "rgba(238, 251, 242, 1)", border: "1px solid rgba(57, 156, 84, 1)" }}
                        >
                            <CheckOutlined style={{ color: "rgba(57, 156, 84, 1)" }} />
                            <span>Sua solicitação de <b>pagamento online foi aprovada</b>. Seus clientes já podem pagar pelo App Meep e  Venda Online.</span>
                            <div
                                className={styles.flexCenter}
                                onClick={() => {
                                    setPostSuccess(false);
                                    localStorage.setItem("@showPaymentSuccess", "false");
                                }}
                            >
                                <Close />
                            </div>
                        </div>
                    )}
                    {hasPaymentSolicitation && (
                        <div className={styles.postFeedback}>
                            <WatchLaterOutlined />
                            <span>Você solicitou a habilitação do <b>pagamento online</b> em {format(new Date(`${onlinePaymentStatus.solicitationDate}Z`), "dd/MM/yyyy HH:mm")}. Aguarde nosso contato.</span>
                            <div className={styles.flexCenter} onClick={() => setPostError(false)}>
                                <Close />
                            </div>
                        </div>
                    )}
                    {dimensions.width < 650 && (
                        <div style={{ margin: 0 }} className={styles.flexCenter}>
                            <Checkbox
                                style={{ width: "40px", height: "40px", color: "rgba(50, 0, 142, 1)" }}
                                name="isVisibleInApp"
                                checked={configValues.isVisibleInApp}
                                onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                            />
                            <label>Visível no App</label>
                        </div>
                    )}
                    <div className={styles.row}>
                        <span className={styles.text}>Permitir consumo pelo app</span>
                        <Switch
                            checked={configValues.allowConsumeInApp}
                            name="allowConsumeInApp"
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente adicione itens no carrinho de compra.</p>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span className={styles.text}>Habilitar pagamento online</span>
                            {!dataConfig?.allowOnlinePayment && (
                                <Tooltip title="Solicite pagamento online.">
                                    <InfoOutlined style={{ color: "rgba(51, 51, 51, 1)" }} />
                                </Tooltip>
                            )}
                        </div>
                        <Switch
                            name="allowOnlinePaymentApp"
                            checked={configValues.allowOnlinePaymentApp}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                            disabled={!dataConfig?.allowOnlinePayment}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente use a forma de pagamento crédito para pagar pelos produtos.</p>
                    {dataConfig && !dataConfig?.allowOnlinePayment && (
                        <div className={styles.row}>
                            <Button
                                variant='text'
                                color='secondary'
                                style={{ width: "191px", padding: 0 }}
                                onClick={() => setOpenPaymentSidesheet("open")}
                                endIcon={<KeyboardArrowRightRounded />}
                                disabled={hasPaymentSolicitation}
                            >
                                Solicitar pagamento online
                            </Button>
                        </div>
                    )}
                    <div className={styles.row}>
                        <span className={styles.text}>Permitir entrar no local [NOW]</span>
                        <Switch
                            name="inPlaceEnabled"
                            checked={configValues.inPlaceEnabled}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente faça check-in no NOW pela home do App, se sua localização estiver próxima.</p>
                    <div className={styles.row}>
                        <span className={styles.text}>Permitir que o cliente ative o cartão</span>
                        <Switch
                            name="allowManualCheckinWithCardActivation"
                            checked={configValues.allowManualCheckinWithCardActivation}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente faça a leitura do QR Code e ative o cartão para entrar no local</p>
                    <div className={styles.row}>
                        <span className={styles.text}>Permitir check-in independente de localização</span>
                        <Switch
                            name="allowCheckinWithoutLocation"
                            checked={configValues.allowCheckinWithoutLocation}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente faça check-in mesmo que esteja longe do local.</p>
                    <div className={styles.column} style={{ marginBottom: "6px" }}>
                        <label className={styles.required}>
                            Categoria do estabelecimento
                        </label>
                        <Select
                            variant='outlined'
                            style={{ margin: 0, backgroundColor: "rgba(189, 189, 189, 0.4)" }}
                            disabled
                            size="small"
                            value={localType}
                        >
                            <MenuItem value={localType}>{localType}</MenuItem>
                        </Select>
                    </div>
                    <div className={styles.column} style={{ marginBottom: "6px" }}>
                        <label className={styles.required}>
                            Tipos de operações no app
                        </label>
                        <Autocomplete
                            renderInput={params => <TextField {...params} variant='outlined' />}
                            value={operation}
                            options={operationsOptnos}
                            multiple
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.value === value.value}
                            placeholder="Selecione os tipos"
                            className={styles.inputComplete}
                            noOptionsText="Nenhuma opção encontrada"
                            onChange={handleAutoCompleteChange}
                        />
                    </div>
                    <div
                        className={styles.row}
                        style={dimensions.width < 750 ? { flexDirection: "column" } : {}}
                    >
                        <div className={styles.column}>
                            <label className={styles.required}>
                                Cardápio no App
                            </label>
                            <Select
                                variant='outlined'
                                size="small"
                                placeholder="Selecione um cardápio"
                                name="catalogAppId"
                                onChange={(ev) => {
                                    if (ev.target.name) {
                                        const newValue = ev.target.value as string;
                                        onConfigChangeHandle(ev.target.name, newValue);
                                    }
                                }}
                                value={configValues?.catalogAppId ?? null}
                            >
                                {catalogList.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.column}>
                            <label className={styles.required}>
                                Distância para check-in no estabelecimento
                            </label>
                            <TextField
                                variant='outlined'
                                value={`${configValues.maxCheckInDistanceMeters ?? 0}km`}
                                style={{ backgroundColor: "rgba(189, 189, 189, 0.4)", borderRadius: "5px" }}
                                disabled
                                size='small'
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span>Enviar notificações de consumo</span>
                            <Tooltip title="Sempre que o cliente realizar um consumo, ele será notificado.">
                                <InfoOutlined style={{ color: "rgba(51, 51, 51, 1)" }} />
                            </Tooltip>
                        </div>
                        <Switch
                            name="notificationPhone"
                            checked={configValues.notificationPhone}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                            disabled
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span>Destacar seu perfil no App</span>
                            <Tooltip title="Com o plano premium, você pode destacar o seu estabelecimento no app.">
                                <InfoOutlined style={{ color: "rgba(51, 51, 51, 1)" }} />
                            </Tooltip>
                            <img src="/assets/icon/mdi_crown_yellow.svg" alt="" />
                        </div>
                        <Switch
                            name="featuredApp"
                            checked={configValues.featuredApp}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span>Habilitar taxa de serviço?</span>
                        </div>
                        <Switch
                            name="enableServiceTaxInApp"
                            checked={configValues.enableServiceTaxInApp}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <div className={styles.column} style={dimensions.width < 750 ? { width: "100%" } : { width: "50%" }}>
                        <label className={styles.required}>
                            Taxa de serviço
                        </label>
                        <TextField
                            variant="outlined"
                            name="serviceTaxPercentageInApp"
                            value={`${!!configValues.serviceTaxPercentageInApp ? configValues.serviceTaxPercentageInApp : 0}%`}
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, Number(ev.target.value.match(/\d+/g)?.join("")))}
                            size="small"
                            disabled={!configValues.enableServiceTaxInApp}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AppParams;