import { AxiosInstance } from "axios"
import { DigitalAccountResponse } from "modules/contaDigital/models/dtos/DigitalAccountResponse"

const ObterContasDigitais = async (api: AxiosInstance, ownerId: string): Promise<DigitalAccountResponse[]> => {
    const response = await api.get<ContaDigital[]>(`/Local/BuscarContasVirtuaisMeep/${ownerId}`);

    return response.data.map((digitalAccount: ContaDigital) => (
        {
            id: digitalAccount.id,
            description: digitalAccount.descricao,
            agencia: digitalAccount.agencia,
            banco: digitalAccount.banco,
            contaCorrente: digitalAccount.banco,
            documento: digitalAccount.documento,
            nome: digitalAccount.nome
        }
    ));
};

export default ObterContasDigitais;

export interface ContaDigital {
    id: string
    descricao: string
    agencia: string
    banco: string
    contaCorrente: string
    documento: string
    nome: string
}