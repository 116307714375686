export function formatarValor(valor: number) {
  return valor?.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function formatCpfCnpj(value: string) {
  const cleanedValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

  if (cleanedValue.length <= 11) {
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); // Aplica a máscara de CPF
  } else {
    return cleanedValue
      .slice(0, 14)
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); // Aplica a máscara de CNPJ
  }
}

export function onlyNumber(value: string) {
  const cleanedValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

  return cleanedValue;
}

export const optionsBancos = [
  { value: "117", text: "117 - Advanced Corretora de Câmbio Ltda" },
  { value: "172", text: "172 - Albatross Corretora de Câmbio e Valores S.A" },
  {
    value: "188",
    text: "188 - Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores",
  },
  { value: "654", text: "654 - Banco A.J. Renner S.A" },
  { value: "246", text: "246 - Banco ABC Brasil S.A" },
  { value: "075", text: "075 - Banco ABN Amro S.A" },
  { value: "121", text: "121 - Banco Agibank S.A" },
  { value: "025", text: "025 - Banco Alfa S.A" },
  { value: "641", text: "641 - Banco Alvorada S.A" },
  { value: "065", text: "065 - Banco Andbank (Brasil) S.A" },
  { value: "213", text: "213 - Banco Arbi S.A" },
  { value: "024", text: "024 - Banco Bandepe S.A" },
  { value: "740", text: "740 - Banco Barclays S.A" },
  { value: "096", text: "096 - Banco BM&FBovespa" },
  { value: "318", text: "318 - Banco BMG S.A" },
  { value: "752", text: "752 - Banco BNP Paribas Brasil S.A" },
  { value: "248", text: "248 - Banco Boavista Interatlântico S.A" },
  { value: "107", text: "107 - Banco Bocom BBM S.A" },
  { value: "063", text: "063 - Banco Bradescard S.A" },
  { value: "036", text: "036 - Banco Bradesco BBI S.A" },
  { value: "122", text: "122 - Banco Bradesco BERJ S.A" },
  { value: "204", text: "204 - Banco Bradesco Cartões S.A" },
  { value: "394", text: "394 - Banco Bradesco Financiamentos S.A" },
  { value: "237", text: "237 - Banco Bradesco S.A" },
  { value: "125", text: "125 - Banco Brasil Plural S.A. – Banco Múltiplo" },
  { value: "081", text: "081 - Banco Brasileiro de Negócios S.A. – BBN" },
  { value: "218", text: "218 - Banco BS2 S.A" },
  { value: "208", text: "208 - Banco BTG Pactual S.A" },
  { value: "473", text: "473 - Banco Caixa Geral – Brasil S.A" },
  { value: "412", text: "412 - Banco Capital S.A" },
  { value: "040", text: "040 - Banco Cargill S.A" },
  { value: "266", text: "266 - Banco Cédula S.A" },
  { value: "739", text: "739 - Banco Cetelem S.A" },
  { value: "233", text: "233 - Banco Cifra S.A" },
  { value: "745", text: "745 - Banco Citibank S.A" },
  { value: "241", text: "241 - Banco Clássico S.A" },
  { value: "095", text: "095 - Banco Confidence de Câmbio S.A" },
  { value: "756", text: "756 - Banco Cooperativo do Brasil S.A. – Bancoob" },
  { value: "748", text: "748 - Banco Cooperativo Sicredi S.A" },
  { value: "222", text: "222 - Banco Credit Agricole Brasil S.A" },
  { value: "505", text: "505 - Banco Credit Suisse (Brasil) S.A" },
  { value: "069", text: "069 - Banco Crefisa S.A" },
  { value: "003", text: "003 - Banco da Amazônia S.A" },
  { value: "083", text: "083 - Banco da China Brasil S.A" },
  { value: "707", text: "707 - Banco Daycoval S.A" },
  { value: "070", text: "070 - Banco de Brasília S.A. (BRB)" },
  { value: "250", text: "250 - Banco de Crédito e Varejo S.A. – BCV" },
  {
    value: "505",
    text: "505 - Banco de Investimento Credit Suisse (Brasil) S.A",
  },
  { value: "300", text: "300 - Banco de La Nacion Argentina" },
  { value: "495", text: "495 - Banco de La Provincia de Buenos Aires" },
  { value: "494", text: "494 - Banco de La Republica Oriental del Uruguay" },
  { value: "001", text: "001 - Banco do Brasil S.A" },
  { value: "047", text: "047 - Banco do Estado de Sergipe S.A" },
  {
    value: "021",
    text: "021 - Banco do Estado do Espírito Santo – Baneste S.A",
  },
  { value: "037", text: "037 - Banco do Estado do Pará S.A" },
  { value: "041", text: "041 - Banco do Estado do Rio Grande do Sul S.A" },
  { value: "004", text: "004 - Banco do Nordeste do Brasil S.A" },
  { value: "265", text: "265 - Banco Fator S.A" },
  { value: "224", text: "224 - Banco Fibra S.A" },
  { value: "626", text: "626 - Banco Ficsa S.A" },
  { value: "094", text: "094 - Banco Finaxis S.A" },
  { value: "612", text: "612 - Banco Guanabara S.A" },
  { value: "012", text: "012 - Banco Inbursa S.A" },
  { value: "604", text: "604 - Banco Industrial do Brasil S.A" },
  { value: "653", text: "653 - Banco Indusval S.A" },
  { value: "077", text: "077 - Banco Inter S.A" },
  { value: "630", text: "630 - Banco Intercap S.A" },
  {
    value: "719",
    text: "719 - Banco Internacional do Funchal (Brasil) S.A. – Banif",
  },
  { value: "249", text: "249 - Banco Investcred Unibanco S.A" },
  { value: "341", text: "341 - Banco Itaú Unibanco S.A" },
  { value: "376", text: "376 - Banco J. P. Morgan S.A" },
  { value: "074", text: "074 - Banco J. Safra S.A" },
  { value: "217", text: "217 - Banco John Deere S.A" },
  { value: "076", text: "076 - Banco KDB S.A" },
  { value: "757", text: "757 - Banco Keb Hana do Brasil S.A" },
  { value: "600", text: "600 - Banco Luso Brasileiro S.A" },
  { value: "243", text: "243 - Banco Máxima S.A" },
  { value: "389", text: "389 - Banco Mercantil do Brasil S.A" },
  { value: "370", text: "370 - Banco Mizuho do Brasil S.A" },
  { value: "746", text: "746 - Banco Modal S.A" },
  { value: "066", text: "066 - Banco Morgan Stanley S.A" },
  { value: "456", text: "456 - Banco MUFG Brasil S.A" },
  {
    value: "007",
    text: "007 - Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
  },
  { value: "735", text: "735 - Banco Neon S.A" },
  { value: "169", text: "169 - Banco Olé Bonsucesso Consignado S.A" },
  { value: "079", text: "079 - Banco Original do Agronegócio S.A" },
  { value: "212", text: "212 - Banco Original S.A" },
  { value: "712", text: "712 - Banco Ourinvest S.A" },
  { value: "623", text: "623 - Banco Pan S.A" },
  { value: "611", text: "611 - Banco Paulista S.A" },
  { value: "643", text: "643 - Banco Pine S.A" },
  { value: "747", text: "747 - Banco Rabobank International Brasil S.A" },
  { value: "088", text: "088 - Banco Randon S.A" },
  { value: "633", text: "633 - Banco Rendimento S.A" },
  { value: "741", text: "741 - Banco Ribeirão Preto S.A" },
  { value: "120", text: "120 - Banco Rodobens S.A" },
  { value: "422", text: "422 - Banco Safra S.A" },
  { value: "033", text: "033 - Banco Santander (Brasil) S.A" },
  { value: "743", text: "743 - Banco Semear S.A" },
  { value: "754", text: "754 - Banco Sistema S.A" },
  { value: "366", text: "366 - Banco Société Générale Brasil S.A" },
  { value: "637", text: "637 - Banco Sofisa S.A" },
  { value: "464", text: "464 - Banco Sumitomo Mitsui Brasileiro S.A" },
  { value: "082", text: "082 - Banco Topázio S.A" },
  { value: "634", text: "634 - Banco Triângulo S.A" },
  { value: "018", text: "018 - Banco Tricury S.A" },
  { value: "655", text: "655 - Banco Votorantim S.A" },
  { value: "610", text: "610 - Banco VR S.A" },
  { value: "119", text: "119 - Banco Western Union do Brasil S.A" },
  { value: "124", text: "124 - Banco Woori Bank do Brasil S.A" },
  {
    value: "755",
    text: "755 - Bank of America Merrill Lynch Banco Múltiplo S.A",
  },
  { value: "268", text: "268 - Barigui Companhia Hipotecária" },
  { value: "144", text: "144 - Bexs Banco de Câmbio S.A" },
  { value: "253", text: "253 - Bexs Corretora de Câmbio S.A" },
  {
    value: "134",
    text: "134 - BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda",
  },
  { value: "017", text: "017 - BNY Mellon Banco S.A" },
  { value: "126", text: "126 - BR Partners Banco de Investimento S.A" },
  {
    value: "092",
    text: "092 - Brickell (BRK) S.A. Crédito, Financiamento e Investimento",
  },
  {
    value: "173",
    text: "173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A",
  },
  { value: "142", text: "142 - Broker Brasil Corretora de Câmbio Ltda" },
  { value: "080", text: "080 - BT Corretora de Câmbio Ltda" },
  { value: "336", text: "336 - C6 Bank" },
  { value: "104", text: "104 - Caixa Econômica Federal" },
  {
    value: "130",
    text: "130 - Caruana S.A. Sociedade de Crédito, Financiamento e Investimento",
  },
  {
    value: "159",
    text: "159 - Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
  },
  {
    value: "114",
    text: "114 - Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP",
  },
  {
    value: "091",
    text: "091 - Central de Cooperativas de Economia e Crédito Mútuo do Estado RS – Unicred",
  },
  {
    value: "320",
    text: "320 - China Construction Bank (Brasil) Banco Múltiplo S.A",
  },
  { value: "477", text: "477 - Citibank N.A" },
  { value: "127", text: "127 - Codepe Corretora de Valores e Câmbio S.A" },
  { value: "163", text: "163 - Commerzbank Brasil S.A. – Banco Múltiplo" },
  {
    value: "136",
    text: "136 - Confederação Nacional das Cooperativas Centrais Unicred Ltda (Unicred do Brasil)",
  },
  { value: "060", text: "060 - Confidence Corretora de Câmbio S.A" },
  {
    value: "097",
    text: "097 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda. (CentralCredi)",
  },
  {
    value: "085",
    text: "085 - Cooperativa Central de Crédito Urbano – Cecred",
  },
  {
    value: "016",
    text: "016 - Cooperativa de Crédito Mútuo dos Despachantes de Trânsito de Sta.Catarina e RS",
  },
  {
    value: "089",
    text: "089 - Cooperativa de Crédito Rural da Região da Mogiana",
  },
  { value: "403", text: "403 - CORA" },
  { value: "010", text: "010 - Credicoamo Crédito Rural Cooperativa" },
  {
    value: "011",
    text: "011 - Credit Suisse Hedging-Griffo Corretora de Valores S.A",
  },
  {
    value: "133",
    text: "133 - Cresol – Confederação Nacional Cooperativas Centrais de Crédito e Econ Familiar",
  },
  {
    value: "182",
    text: "182 - Dacasa Financeira S/A – Sociedade de Crédito, Financiamento e Investimento",
  },
  { value: "487", text: "487 - Deutsche Bank S.A. – Banco Alemão" },
  { value: "140", text: "140 - Easynvest – Título Corretora de Valores SA" },
  {
    value: "149",
    text: "149 - Facta Financeira S.A. – Crédito Financiamento e Investimento",
  },
  { value: "196", text: "196 - Fair Corretora de Câmbio S.A" },
  { value: "138", text: "138 - Get Money Corretora de Câmbio S.A" },
  { value: "064", text: "064 - Goldman Sachs do Brasil Banco Múltiplo S.A" },
  {
    value: "135",
    text: "135 - Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A",
  },
  { value: "177", text: "177 - Guide Investimentos S.A. Corretora de Valores" },
  { value: "146", text: "146 - Guitta Corretora de Câmbio Ltda" },
  { value: "078", text: "078 - Haitong Banco de Investimento do Brasil S.A" },
  { value: "062", text: "062 - Hipercard Banco Múltiplo S.A" },
  {
    value: "189",
    text: "189 - HS Financeira S/A Crédito, Financiamento e Investimentos",
  },
  { value: "269", text: "269 - HSBC Brasil S.A. Banco de Investimento" },
  {
    value: "271",
    text: "271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda",
  },
  {
    value: "157",
    text: "157 - ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda",
  },
  { value: "132", text: "132 - ICBC do Brasil Banco Múltiplo S.A" },
  { value: "492", text: "492 - ING Bank N.V" },
  { value: "139", text: "139 - Intesa Sanpaolo Brasil S.A. – Banco Múltiplo" },
  { value: "488", text: "488 - JPMorgan Chase Bank, National Association" },
  { value: "399", text: "399 - Kirton Bank S.A. (Banco Múltiplo)" },
  {
    value: "105",
    text: "105 - Lecca Crédito, Financiamento e Investimento S/A",
  },
  { value: "145", text: "145 - Levycam – Corretora de Câmbio e Valores Ltda" },
  {
    value: "113",
    text: "113 - Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
  },
  { value: "128", text: "128 - MS Bank S.A. Banco de Câmbio" },
  { value: "137", text: "137 - Multimoney Corretora de Câmbio Ltda" },
  { value: "014", text: "014 - Natixis Brasil S.A. Banco Múltiplo" },
  {
    value: "191",
    text: "191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda",
  },
  { value: "753", text: "753 - Novo Banco Continental S.A. – Banco Múltiplo" },
  { value: "260", text: "260 - Nu Pagamentos S.A (Nubank)" },
  {
    value: "111",
    text: "111 - Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A",
  },
  { value: "613", text: "613 - Omni Banco S.A" },
  { value: "254", text: "254 - Paraná Banco S.A" },
  { value: "290", text: "290 - PagBank" },
  {
    value: "194",
    text: "194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda",
  },
  {
    value: "174",
    text: "174 - Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento",
  },
  { value: "100", text: "100 - Planner Corretora de Valores S.A" },
  {
    value: "093",
    text: "093 - Pólocred Sociedade de Crédito ao Microempreendedor e Empresa de Pequeno Porte",
  },
  {
    value: "108",
    text: "108 - PortoCred S.A. Crédito, Financiamento e Investimento",
  },
  {
    value: "101",
    text: "101 - Renascença Distribuidora de Títulos e Valores Mobiliários Ltda",
  },
  { value: "751", text: "751 - Scotiabank Brasil S.A. Banco Múltiplo" },
  {
    value: "545",
    text: "545 - Senso Corretora de Câmbio e Valores Mobiliários S.A",
  },
  {
    value: "190",
    text: "190 - Servicoop – Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio",
  },
  {
    value: "183",
    text: "183 - Socred S.A. – Sociedade de Crédito ao Microempreendedor",
  },
  {
    value: "118",
    text: "118 - Standard Chartered Bank (Brasil) S.A. Banco de Investimento",
  },
  { value: "197", text: "197 - Stone Pagamentos S.A" },
  { value: "143", text: "143 - Treviso Corretora de Câmbio S.A" },
  {
    value: "131",
    text: "131 - Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda",
  },
  { value: "129", text: "129 - UBS Brasil Banco de Investimento S.A" },
  {
    value: "015",
    text: "015 - UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A",
  },
  {
    value: "099",
    text: "099 - Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda",
  },
  {
    value: "084",
    text: "084 - Uniprime Norte do Paraná – Cooperativa de Crédito Ltda",
  },
  {
    value: "102",
    text: "102 - XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S/A",
  },
  { value: "380", text: "380 - PicPay Serviços S.A." },
  { value: "323", text: "323 - Mercado Pago" },
  { value: "280", text: "280 - Will Financeira S.A Crédito" },
  { value: "348", text: "348 - XP Investimento" },
  { value: "461", text: "461 - Banco Asaas" },
];

export const titleHeaderAntecipar = [
  "Conta bancária",
  "Solicitação",
  "Solicitado por",
  "Recebimento",
  "Status",
  "Tx. antecipação",
  "Tx. processamento",
  "Valor solicitado",
];
