import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import EditDeviceEnvironmentsUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceAmbient/EditDeviceEnvironmentsUseCase'
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi'
import { IDeviceEnviromentValue } from '../../components/deviceProfile/profileForms/deviceEnvironmentForm/Interfaces/IEnvironmentValue'

const UseDeviceEnvironment = (path: string = "/private/pdv/perfil") => {
    const { showLoading, hideLoading } = useUi();
    const { push } = useHistory();
    const { currentLocal } = useLocal();

    const editEnvironmentList = useCallback(async (profileId: string, ambientValues: IDeviceEnviromentValue[]) => {
        try {
            if (currentLocal) {
                showLoading();
                const deviceProfileService = DeviceProfileApi();
                await EditDeviceEnvironmentsUseCase(deviceProfileService, currentLocal.id, profileId, ambientValues);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);

            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading();
        }

    }, [currentLocal, showLoading, push, path, hideLoading]);



    return ({ editEnvironmentList })
}

export default UseDeviceEnvironment 