import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { moneyToFloat } from "services/utils/Money";
import Utils from "services/utils/Utils";
import {
  IDiscountProduct,
  IDiscountProductListForm,
  IDiscountProductListFormErrors,
  IDiscountProductType,
} from "../../interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "../../interfaces/IProductList";

interface IUseDiscountProductListForm {
  changeDiscountTypeHandle: (value: string) => void;
  errors: IDiscountProductListFormErrors;
  changeProductHandle: (productItem: IProductItemToDiscount) => void;
  changeHandle: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  submitHandle: () => Promise<void>;
  changeDiscountValueHandle: (value: string) => void;
  values: IDiscountProductListForm;
  closeModal: () => void;
}

const UseDiscountProductListForm = (
  onSubmit: (value: IDiscountProductListForm, type: 'EDIT' | 'ADD') => Promise<any>,
  onClose: () => void,
  productList: IProductItemToDiscount[],
  defaultValue: IDiscounts | undefined | null,
  discountsList: IDiscounts[],

) => {
  const [values, setValues] = useState<IDiscountProductListForm>({
    type: IDiscountProductType.percentual,
  } as IDiscountProductListForm);
  const [errors, setErrors] = useState<IDiscountProductListFormErrors>({});
  const { hideLoading, showLoading, toast } = useUi();

  useEffect(() => {
    if (defaultValue) {
      setValues({
        discount: defaultValue.percentualDiscount?.percentual || defaultValue.priceDiscount?.price || 0,
        product: productList.find(item => item.id === defaultValue?.productId) || {} as IDiscountProduct,
        type: defaultValue.discountType === 'Percentual' ? IDiscountProductType.percentual : IDiscountProductType.money,       
        id: defaultValue.id
      })
    }
  }, [defaultValue])

  const closeModal = () => {
    // setValues({
    //   type: IDiscountProductType.percentual,
    //   product: { id: "undefined", name: "undefined", price: 0 },
    //   validDate: "",
    //   validTime: "",
    // } as IDiscountProductListForm);
    onClose();
  };

  const validate = useCallback((values: IDiscountProductListForm) => {
    let _errors: IDiscountProductListFormErrors = {};
    let hasError = false;
    if (!values.product) {
      _errors = { ..._errors, product: "Escolha um produto" };
      hasError = true;
    }
    // if (!values.discount) {
    //   _errors = { ..._errors, discount: "Insira um valor valido" };
    //   hasError = true;
    // }  
    // if (new Date(values.validDate) < new Date(new Date().toLocaleDateString().split('/').reverse().join('-'))) {
    //   _errors = { ..._errors, validDate: "Data deve ser superior a data de hoje" };
    //   hasError = true;
    // }
    // if (!values.validTime) {
    //   _errors = { ..._errors, validTime: "Insira um horário válido" };
    //   hasError = true;
    // }

    setErrors(_errors);
    return !hasError;
  }, []);



  const submitHandle = useCallback(async () => {   
    if (validate(values)) {
      showLoading();
      try {
        await onSubmit(values, defaultValue ? 'EDIT' : 'ADD');
        closeModal();
      } catch (error) {
        // toast("Falha ao adicionar desconto!", "error");
      } finally {
        hideLoading();
      }
    }
    // TODO: Incluir rotina de edição de descontos
  }, [values]);

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  const changeDiscountValueHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, discount: moneyToFloat(value) }));
  }, []);

  const changeDiscountTypeHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, type: value as IDiscountProductType, discount: 0 }));
  }, []);

  const changeProductHandle = useCallback((productItem: IProductItemToDiscount) => {
    setValues((prev) => ({ ...prev, product: productItem }));
  }, []);

  return {
    values,
    changeDiscountValueHandle,
    submitHandle,
    changeHandle,
    changeProductHandle,
    errors,
    changeDiscountTypeHandle,
    closeModal,
  };
};

export default UseDiscountProductListForm;
