import { IEventsApi } from '../../interfaces/IEventsService';

const SelectCashierUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  cashierId: string,
  localId: string,
): Promise<void> => {
  return await eventsService.selectCashier(eventId, cashierId, localId);
};

export default SelectCashierUseCase;
