import { IServiceScheduleApi } from 'modules/schedule/application/interfaces/api/IServicesScheduleApi'
import { IGetServiceScheduleByLocalIdResponseDTO } from 'modules/schedule/domain/dto/placeSchedule/IGetServiceScheduleByLocalIdResponse'
import { IGetReservationListResponse } from 'modules/schedule/domain/dto/ReserveSchedule/IGetReservationListResponse'
import { IAddServiceCategoryRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceCategoryRequest'
import { IAddServiceProductListRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceProductListRequest'
import { IAddServiceScheduleRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceScheduleRequest'
import { IEditServiceCategoryRequest } from 'modules/schedule/domain/dto/serviceSchedule/IEditServiceCategoryRequest'
import { GetProductListByLocalIdResponseDto } from 'modules/schedule/domain/dto/serviceSchedule/IGetProductListsResponse'
import { IGetServiceCategoriesResponse } from 'modules/schedule/domain/dto/serviceSchedule/IGetServiceCategoryResponse'
import { IGetServiceResponse } from 'modules/schedule/domain/dto/serviceSchedule/IGetServiceResponse'
import { IGetServiceScheduleListByLocalIdResponse } from 'modules/schedule/domain/dto/serviceSchedule/IGetServicesScheduleListResponse'
import { IReorderCategoriesRequest } from 'modules/schedule/domain/dto/serviceSchedule/IReorderCategoriesRequest'
import React from 'react'
import { api } from '../../Api'

export const ServicesScheduleApi = (): IServiceScheduleApi => {
    const getCategoriesByLocalId = async (localId: string): Promise<IGetServiceCategoriesResponse> => {
        const response = await api.get<IGetServiceCategoriesResponse>(`/Schedule/ServiceCategory/Subcategories/ByLocalId/${localId}`)
        return response.data
    }

    const getMainCategoriesByLocalId = async (localId: string): Promise<IGetServiceCategoriesResponse> => {
        const response = await api.get<IGetServiceCategoriesResponse>(`/Schedule/ServiceCategory/Principals/ByLocalId/${localId}`)
        return response.data
    }

    const getProductListsByLocalId = async (localId: string): Promise<GetProductListByLocalIdResponseDto> => {
        const response = await api.get<GetProductListByLocalIdResponseDto>(`/ServiceSchedule/ProductLists/${localId}`)
        return response.data
    }

    const getServicesById = async (serviceId: string): Promise<IGetServiceResponse> => {
        const response = await api.get<IGetServiceResponse>(`/ServiceSchedule/ById/${serviceId}`)
        return response.data
    }
    const deleteServiceById = async (serviceId: string): Promise<void> => {
        const response = await api.delete(`/ServiceSchedule/${serviceId}`)
        return
    }

    const getServicesListByLocalId = async (localId: string): Promise<IGetServiceScheduleListByLocalIdResponse> => {
        const response = await api.get<IGetServiceScheduleListByLocalIdResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: false } })//TODO: implementar url
        return response.data
    }

    const addServiceCategory = async (object: IAddServiceCategoryRequest) => {
        await api.post<IAddServiceCategoryRequest>("/Schedule/ServiceCategory/Add", object);
        return;
    }
    const editServiceCategory = async (object: IEditServiceCategoryRequest) => {
        await api.put<IAddServiceCategoryRequest>("/Schedule/ServiceCategory/Update", object);
        return;
    }
    const deleteServiceCategory = async (serviceCategoryId: string) => {
        await api.delete<IAddServiceCategoryRequest>("/Schedule/ServiceCategory/Delete/" + serviceCategoryId);
        return;
    }

    const addServiceProduct = async (object: IAddServiceProductListRequest) => {
        await api.post<IAddServiceProductListRequest>("/ServiceSchedule/ProductLists/Add", object);
        return;
    }

    const addService = async (request: IAddServiceScheduleRequest): Promise<void> => {
        await api.post<IAddServiceCategoryRequest>("/ServiceSchedule/", request);
        return
    }
    const editService = async (request: IAddServiceScheduleRequest): Promise<void> => {
        await api.put<IAddServiceCategoryRequest>("/ServiceSchedule/Update/", request);
        return
    }


    const reorderCategories = async (request: IReorderCategoriesRequest): Promise<void> => {
        await api.patch<void>("Schedule/ServiceCategory/ReOrder", request);
        return
    }


    return {
        getCategoriesByLocalId,
        addServiceCategory,
        editServiceCategory,
        deleteServiceCategory,
        addServiceProduct,
        getProductListsByLocalId,
        addService,
        getServicesListByLocalId,
        getMainCategoriesByLocalId,
        getServicesById,
        editService,
        deleteServiceById,
        reorderCategories
    };
}