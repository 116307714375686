import React, { FC, useCallback, useEffect } from 'react'
import styles from './LocalAddOrUpdatePage.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import { ILocalsValues } from 'modules/meepErp/presentation/components/locals/localsForm/LocalsValues'
import LocalsForm from 'modules/meepErp/presentation/components/locals/localsForm/LocalsForm'
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import LocalsService from 'modules/meepErp/services/api/Locals/LocalsService'
import { UseLocalStore } from 'modules/local/presentation/store/UseLocalStore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IStorageLocalsResponse } from 'modules/meepErp/application/dtos/storageLocals/ILocalsResponse'
import { IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'

const LocalAddOrUpdatePage = () => {
    const [currentLocal] = UseLocalStore(state => [state.currentLocal])
    const { id } = useParams<{ id?: string }>();
    const queryClient = useQueryClient();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Locais de estoque",
                url: '/private/meeperp/locals'
            }, 
            {
                title: "Cadastro de locais de estoque",
            },                       
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  

    const submitHandler = useCallback(async (values: ILocalsValues, id?: string) => {
        try {
            showLoading();
            if (id) {
                const service = LocalsService();
                return await service.updateLocals({ ...values, id: id! });
            } else {
                const service = LocalsService();
                return await service.createLocals(values);
            }
        } finally {
            hideLoading();
        }
    }, [])



    const getDefaultValue = useCallback(async (localId?: string, id?: string) => {
        try {
            showLoading();
            if (!id) return;
            if (!localId) throw new Error("Local não econtrado!");
            const response = await LocalsService().getLocalsItem(localId, id);
            return response;
        } finally {
            hideLoading();
        }
    }, [])


    //REACT QUERY
    const defaultValue = useQuery('@localsStock', () => getDefaultValue(currentLocal?.id, id), {
        enabled: !!id && !!currentLocal?.id,
    })

    const onSuccessfulSubmit = useCallback((data: IStorageLocalsResponse) => {
        try {
            showLoading();
            if (id) {
                queryClient.setQueriesData<IGetStorageLocalsListResponse | undefined>(['@localsStock'], (previous) =>
                (previous ? {
                    ...previous,
                    items: previous?.items?.map(item => item.id === id ? data : item) ?? []
                } : undefined))
            } else {
                queryClient.setQueriesData<IGetStorageLocalsListResponse | undefined>(['@localsStock'], (previous) =>
                (previous ? {
                    ...previous,
                    items: [data, ...previous.items]
                } : undefined))
            }

            replace('/private/meeperp/locals/')
        } finally {
            hideLoading();
        }
    }, [id, queryClient, replace])

    const submitForm = useMutation((values: ILocalsValues) => submitHandler(values, id), {
        onSuccess: onSuccessfulSubmit
    })

    const onClickSubmitForm = async (values: ILocalsValues) => {
        await submitForm.mutateAsync(values);
    }


    return (
        <div id={styles.InvetoryOperationAddOrUpdatePage} >
            <Back route='/private/meeperp/locals' margin />
            <div className={styles.container} >
                <LocalsForm
                    isLoading={submitForm.isLoading || defaultValue.isLoading}
                    disabled={submitForm.isLoading || defaultValue.isLoading}
                    defaultValue={defaultValue.data}
                    onSubmit={onClickSubmitForm}
                />
            </div>
        </div>
    )
}
export default LocalAddOrUpdatePage

