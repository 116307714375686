import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditUsabilityUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/usability/EditUsabilityUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IUsabilityValues } from '../../components/deviceProfile/profileForms/usabilityForm/IUsabilityValues';

const UseUsability = (path: string = "/private/pdv/perfil") => {
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();

    const editUsability = useCallback(async (profileId: string, values: IUsabilityValues) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                await EditUsabilityUseCase(deviceProfileService, currentLocal.id, profileId, values);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        editUsability,
    })
}


export default UseUsability