import { useHistory } from 'react-router-dom';

import styles from './ToolLinkCard.module.scss';
import Button from "components/ui/Button/Button";

interface ToolLinkCardProps {
    type: string;
    iconUrl: string;
    title: string;
    description: string;
    linkUrl: string;
}

const ToolLinkCard = ({ type, iconUrl, title, description, linkUrl }: ToolLinkCardProps) => {
    const history = useHistory();

    return (
        <div className={styles.toolsCard}>
            <div className={styles.toolsCardBadge}>{type}</div>

            <div className={styles.toolsCardHeader}>
                <span className={styles.toolsCardIcon}>
                    <img src={iconUrl} alt={title} />
                </span>
                <span className={styles.toolsCardTitle}>{title}</span>
            </div>

            <p className={styles.toolsCardText}>{description}</p>

            <Button fullWidth={false} color="secondary" onClick={() => history.push(linkUrl)}>Usar agora</Button>
        </div>
    );
}

export default ToolLinkCard;