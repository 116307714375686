import { Icon } from '@material-ui/core';
import styles from './PurchaseEmail.module.scss';
import { Checkbox, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureTypeEnum } from 'modules/plans/presentation/interfaces/FeatureTypeEnum';
import { UsePurchaseEmail } from './UsePurchaseEmail';
import { moneyMaskNumber } from 'services/utils/Money';
import { PermissionModal } from 'modules/plans/presentation/components/permissionModal/PermissionMoral';

export const PurchaseEmailPage = () => {
    const [agreeded, setAgreeded] = useState(false);
    const history = useHistory();

    const { 
        features, 
        emailFeature, 
        setEmailFeature, 
        defaultEmailFeature,
        subscribeFeature,
        saving,
        loading,
        permissionModalOpened,
        setPermissionModalOpened
    } = UsePurchaseEmail();

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.backContainer}>
                    <button onClick={() => history.goBack()}>
                        <Icon>chevron_left</Icon>
                    </button>
                    <span>Voltar</span>
                </div>

                <div className={styles.header}>
                    <span>Adicional de <b>e-mails</b></span>
                </div>

                <div className={styles.cardsContainer}>
                    <div className={styles.cardContainer}>
                        <span>Período de <b>cobrança</b></span>

                        <div className={styles.card}>
                            <img src="/assets/icon/plans/monthly.svg" alt="" />
                            <div>
                                <strong>Mensal</strong>
                                <span>{moneyMaskNumber(emailFeature?.price)}<span>/mês</span></span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardContainer}>
                        <span>Forma de <b>pagamento</b></span>

                        <div className={styles.card}>
                            <img src="/assets/icon/plans/payment.svg" alt="" />
                            <div>
                                <strong>Fatura Meep</strong>
                                <span>Aditivo contratual</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.addonsContainer}>
                    {
                        loading ?
                        <Skeleton variant="rectangular" width="100%" height={72} style={{ borderRadius: 8 }} /> :
                        features.filter(x => x.featureTypeValue === FeatureTypeEnum.EMAIL).map(x => (
                            <button
                                onClick={() => setEmailFeature(x)}
                                className={`${defaultEmailFeature?.id === x.id ? styles.current : ''} ${emailFeature?.id === x.id ? styles.selected : ''}`}
                            >
                                <div>
                                    <img src={`/assets/icon/plans/email-outlined-${emailFeature?.id === x.id ? 'white' : 'purple'}.svg`} alt="" />
                                    <span>Email</span>
                                </div>
                                <p>{x.purchaseDescription}</p>
                            </button>
                        ))
                    }
                </div>

                {/* <div className={styles.bill}>
                    <h3>Dados da cobrança</h3>

                    <div>
                        <div>
                            <span>João Carlos de Almeida</span>
                            <span><b>CPF/CNPJ: </b>999.999.999-99</span>
                            <span><b>Telefone: </b>(31) 99999-9999</span>
                        </div>

                        <div>
                            <span><b>CEP: </b>30458-99</span>
                            <span><b>Endereço: </b>Rua Carlos Dias 33, Alto Barroca - Belo Horizonte</span>
                        </div>
                    </div>
                </div> */}

                <div className={styles.checkboxContainer}>
                    <Checkbox checked={agreeded} color="primary" className={styles.checkbox} onChange={() => setAgreeded(prev => !prev)} />
                    <span>Concordo com os <a href="a">termos de uso</a> e com a cobrança mensal de <b>{moneyMaskNumber(emailFeature?.price)}</b> na próxima fatura</span>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.summary}>
                    <header>Resumo do <b>adicional</b></header>

                    <div className={styles.summaryContent}>
                        <span className={styles.summaryTitle}>Adicional:</span>

                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemDescription}>+ {(emailFeature?.quantity || 0) - (defaultEmailFeature?.quantity || 0)} emails/mês</span>
                        </div>

                        <div className={styles.separator} />

                        <footer>
                            <span className={styles.value}>R$ <b>{moneyMaskNumber(emailFeature?.price).replace('R$', '')}</b><span>/mês</span></span>
                            <span className={styles.highlight}>Cobrado na próxima fatura</span>
                        </footer>

                        <button onClick={subscribeFeature} disabled={!agreeded || emailFeature?.id === defaultEmailFeature || saving}>{saving ? 'Enviando...' : 'Contratar adicional'}</button>
                    </div>
                </div>
            </div>
            <PermissionModal title="Upgrade de Email" open={permissionModalOpened} onClose={() => setPermissionModalOpened(false)} />
        </div>
    )
}