import { Icon } from '@material-ui/core';
import styles from './ToolForm.module.scss';
import { IAutomationFormStep } from 'modules/automation/domain/interfaces/IAutomationFormStep';

interface ToolFormProps {
    onClickCard: (step: IAutomationFormStep) => void;
}

export const ToolForm = ({ onClickCard }: ToolFormProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <img src="/assets/icon/info.svg" alt="" />
                <span>Você está configurando: <strong>Após fechamento do caixa/evento</strong></span>
            </div>

            <button className={styles.card} onClick={() => onClickCard(IAutomationFormStep.TRACK)}>
                <img src="/assets/icon/track-icon.svg" alt="" />
                <span className={styles.name}>Track.co</span>
                <Icon style={{ marginLeft: 'auto' }}>navigate_next</Icon>
            </button> 
        </div>
    )
}