import { useUi } from "contexts/userInterface/UserInterfaceContext";
import EditMassiveDeviceProfileUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/EditMassiveDeviceProfileUseCase";
import { FormDeviceProfileStep } from "modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceApi from "services/api/equipamento/DeviceApi";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import { IDeviceItemValue } from "../../components/deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem";
import { IGetDeviceListResponse } from "modules/equipamento/domain/dto/getDevice/IGetDeviceListResponse";
import { IFilterDevice } from "modules/equipamento/presentation/components/filterDevice/IFilterDevice";
import GetDeviceUseCase from "modules/equipamento/application/useCases/GetDeviceUseCase";

const UseDeviceToProfile = (path: string = "/private/pdv/perfil") => {
  const [deviceList, setDevices] = useState<IGetDeviceListResponse>();

  const { push } = useHistory();
  const { showLoading, hideLoading } = useUi();

  const { currentLocal } = useLocal();

  const getDevicesList = useCallback(
    async (localId: string, deviceFilter?: IFilterDevice) => {
      try {
        showLoading();
        const deviceApi = DeviceApi();
        const response = await GetDeviceUseCase(localId, deviceApi, deviceFilter);
        if (!deviceFilter?.pagination?.offset) {
          setDevices(response)
      } else {
          setDevices(prev => prev ? {...prev, items: [...prev.items, ...response.items]} : response);
      }
      } finally {
        hideLoading();
      }
    },
    [hideLoading, showLoading]
  );

  const editDevices = useCallback(
    async (profileId: string, DeviceValues: IDeviceItemValue[], nivelEvento?: boolean) => {
      try {
        if (currentLocal) {
          showLoading();
          const deviceProfileService = DeviceProfileApi();
          await EditMassiveDeviceProfileUseCase(
            profileId,
            currentLocal.id,
            deviceProfileService,
            DeviceValues
          );
          if (!nivelEvento) {
            push(
              `${path}/editar/${profileId}/` +
                FormDeviceProfileStep.PRODUCTS
            );
          }
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, hideLoading, path, push, showLoading]
  );

  return {
    deviceList,
    getDevicesList,
    editDevices,
  };
};

export default UseDeviceToProfile;
