export const Sucess = () => {
  return (
    <svg
      width="62"
      height="61"
      viewBox="0 0 62 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1834_18640)">
        <path
          d="M55.2075 54.8406C45.7111 62.3998 16.0639 62.3998 6.75828 54.8406C-2.73809 47.2814 -1.77074 14.9722 6.75828 6.4698C15.2873 -2.03262 46.6785 -2.03262 55.2075 6.4698C63.7365 14.9722 64.7039 47.2814 55.2075 54.8406Z"
          fill="#399C54"
        />
        <path
          d="M31.148 41.607C22.6326 48.2229 13.1363 51.6239 4.21211 51.8099C-2.36859 40.8497 -1.01975 14.2133 6.73267 6.46809C13.7085 -0.519835 35.6033 -1.84834 48.2061 2.49587C51.2989 14.9705 44.9089 30.8328 31.148 41.607Z"
          fill="#399C54"
        />
        <path
          d="M26.4331 46.8257C25.0439 46.8257 23.6686 46.2866 22.8768 45.221L11.8191 32.3832C10.2354 30.603 10.6383 28.1081 12.6109 26.6789C14.5835 25.2497 17.3479 25.6133 18.9316 27.3935L26.4331 36.1317L43.0336 16.6996C44.6172 14.9194 47.3817 14.5558 49.3543 15.985C51.3269 17.4142 51.7297 19.909 50.1461 21.6893L29.9893 45.221C29.003 46.1111 27.8222 46.8257 26.4331 46.8257Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1834_18640">
          <rect width="61.9649" height="60.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
