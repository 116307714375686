import { useState } from "react";
import styles from './FeesPage.module.scss';

const fees = [
    {
        name: 'Débito',
        visaMaster: '1,29%',
        others: '1,65%',
    },
    {
        name: 'PIX',
        visaMaster: '1,29%',
        others: '1,65%',
    },
    {
        name: '1x',
        visaMaster: '3,87%',
        others: '4,70%',
    },
    {
        name: '2x',
        visaMaster: '6,89%',
        others: '7,89%',
    },
    {
        name: '3x',
        visaMaster: '7,88%',
        others: '8,88%',
    },
    {
        name: '4x',
        visaMaster: '8,50%',
        others: '9,50%',
    },
    {
        name: '5x',
        visaMaster: '9,90%',
        others: '10,90%',
    },
    {
        name: '6x',
        visaMaster: '11,20%',
        others: '12,20%',
    },
    {
        name: '7x',
        visaMaster: '12,80%',
        others: '13,80%',
    },
    {
        name: '8x',
        visaMaster: '14,50%',
        others: '15,80%',
    },
    {
        name: '9x',
        visaMaster: '15,90%',
        others: '16,90%',
    },
    {
        name: '10x',
        visaMaster: '17,60%',
        others: '18,60%',
    },
    {
        name: '11x',
        visaMaster: '18,90%',
        others: '19,90%',
    },
    {
        name: '12x',
        visaMaster: '20,50%',
        others: '21,50%',
    },
]

export const FeesPage = () => {
    const [selectedCard, setSelectedCard] = useState<0 | 1>(0);

    return (
        <div id={styles.FeesPage}>
            <div className={styles.header}>
                <img src="/assets/img/LogoMee.svg" alt="" />
            </div>
            <div className={styles.container}>
                <div>
                    <h2>Conheça mais sobre nossas taxas</h2>
                    <p>As taxas variam de acordo com a bandeira do cartão do cliente e também no número de parcelas.</p>

                    <div className={styles.main}>
                        <span>Selecione para ver as taxas</span>
                        <div className={styles.row}>
                            <button onClick={() => setSelectedCard(0)} className={selectedCard === 0 ? styles.selected : ''}>
                                <img src="/assets/icon/saas/cards/borderless-master.svg" alt="" />
                                <img src="/assets/icon/saas/cards/borderless-visa.svg" alt="" />
                            </button>
                            <button onClick={() => setSelectedCard(1)} className={selectedCard === 1 ? styles.selected : ''}>Outras bandeiras</button>
                        </div>
                        <div className={styles.card}>
                            {
                                fees.map(x => (
                                    <div>
                                        <span>{x.name}</span>
                                        <span>{selectedCard === 0 ? x.visaMaster : x.others}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* <div>
                    <img src="/assets/icon/saas/fees/blur.svg" alt="" />
                    <img src="/assets/icon/saas/fees/hand.svg" alt="" />
                </div> */}
            </div>
        </div>
    )
}