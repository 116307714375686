import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";

import styles from "./cashiersSideSheet.module.scss";
import { CashiersTypesCollection } from "services/api/outputreport";
import { FormatCashierDate } from "../../utils";
import { Button } from "@material-ui/core";
import { ICashierItem } from "modules/cashlessDashboard/presentation/components/filter/ICashier";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  cashiers?: CashiersTypesCollection;
  cashiersFilter?: ICashierItem[];
  filterType?: string
}

export const CashierSideSheet = ({ open, onClose, cashiers, filterType, cashiersFilter }: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={filterType === 'cashier' ? "Caixas" : 'Caixas do'} titleBold={filterType === 'cashier' ? "filtrados" : 'período'}>
      <div className={styles.cashierContainer}>
        {filterType === 'cashier' ? (
          <ul className={styles.cashierList}>
            {cashiersFilter?.map((cashier) => (
              <li className={styles.cashierItemBox}>
                {cashier?.name}
              </li>
            ))}
          </ul>
        ) : (
          <ul className={styles.cashierList}>
            {cashiers?.map((cashier) => (
              <li className={styles.cashierItemBox}>
                {cashier?.name ??
                  FormatCashierDate({
                    startDate: cashier.StartDate,
                    endDate: cashier.EndDate,
                  })}
              </li>
            ))}
          </ul>
        )}
        <div className={styles.cashierContainerButton}>
          <Button className={styles.button} variant='contained' onClick={onClose}>Fechar</Button>
        </div>
      </div>
    </RightDrawer>
  );
};
