
import { INovidadesParam, IGetNovidadesService } from "../interfaces/INovidadesService";

const GetNovidades = async (
  novidadesService: IGetNovidadesService,
  param: INovidadesParam,
): Promise<any> => {
  return await novidadesService.getNovidades(param);
};

export default GetNovidades;