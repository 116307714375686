import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { ICloseEvent } from "../../ICloseEvent";
import UseCloseEventPage from "../../UseCloseEventPage";
import { CloseTitle } from "../closeTitle/CloseTitle";
import styles from "./Tickets.module.scss";

interface ITicketsProps {
  event: ICloseEvent;
  isExport: boolean;
  pageBreak: boolean;
  renderPercentage: Function;
}

export const Tickets: FC<ITicketsProps> = ({ event, isExport, pageBreak , renderPercentage}) => {
  return (
    <div id={styles.Tickets} className={pageBreak ? styles.pageBreak : ""}>
      <CloseTitle
        isExport={isExport}
        title="Ingressos/Entradas"
        subTitle="Informações referentes à quantidade de ingressos vendidos e pessoas nas listas de convidados."
      />
      <div className={styles.content}>
        <div>
          <h2>Resumo</h2>
          <div className={isExport ? styles.export : ""}>
            <div>
              <h3>
                {moneyMaskNumber(
                  event?.eventTickets?.eventTicketsResume?.totalBilled
                )}
              </h3>
              <p>Total faturado</p>
            </div>
            <div>
              <h3>
                {event?.eventTickets?.eventTicketsResume?.ticketsSold?.amount ??
                  0}
              </h3>
              <p>Total de ingressos vendidos</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.men ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.men,
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.women ??
                  0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.women,
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.ticketsSold
                    ?.notSpecified ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.ticketsSold
                    ?.notSpecified,
                  event?.eventTickets?.eventTicketsResume?.ticketsSold?.amount
                )}%)`}
              </p>
            </div>
            <div>
              <h3>
                {event?.eventTickets?.eventTicketsResume?.checkins.amount ?? 0}
              </h3>
              <p>Total de Check-in realizados</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.checkins.men ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.checkins.men,
                  event?.eventTickets?.eventTicketsResume?.checkins.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.checkins.women ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.checkins.women,
                  event?.eventTickets?.eventTicketsResume?.checkins.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${
                  event?.eventTickets?.eventTicketsResume?.checkins
                    ?.notSpecified ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsResume?.checkins?.notSpecified,
                  event?.eventTickets?.eventTicketsResume?.checkins.amount
                )}%)`}
              </p>
            </div>
            <div>
              <h3>
                {moneyMaskNumber(
                  event?.eventTickets?.eventTicketsResume?.averageSpend.total ??
                    0
                )}
              </h3>
              <p>Ticket médio total</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsResume?.averageSpend.men ?? 0
                )}`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsResume?.averageSpend.women ??
                    0
                )}`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsResume?.averageSpend
                    .notSpecified ?? 0
                )}`}
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2>Não vinculados à lista de convidados</h2>
          <div className={isExport ? styles.export : ""}>
            <div>
              <h3>
                {moneyMaskNumber(
                  event?.eventTickets?.eventTicketsNotGuests?.totalBilled ?? 0
                )}
              </h3>
              <p>Total faturado</p>
            </div>
            <div>
              <h3>
                {event?.eventTickets?.eventTicketsNotGuests.ticketsSold
                  ?.amount ?? 0}
              </h3>
              <p>Ingressos vendidos</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.men ??
                  0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.men,
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold
                    ?.women ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.women,
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold
                    ?.notSpecified ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold
                    ?.notSpecified,
                  event?.eventTickets?.eventTicketsNotGuests.ticketsSold?.amount
                )}%)`}
              </p>
            </div>
            <div>
              <h3>
                {event?.eventTickets?.eventTicketsNotGuests.checkins.amount ??
                  0}
              </h3>
              <p>Check-in realizados</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.checkins.men ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.checkins.men,
                  event?.eventTickets?.eventTicketsNotGuests.checkins?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.checkins.women ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.checkins.women,
                  event?.eventTickets?.eventTicketsNotGuests.checkins?.amount
                )}%)`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${
                  event?.eventTickets?.eventTicketsNotGuests.checkins
                    ?.notSpecified ?? 0
                } (${renderPercentage(
                  event?.eventTickets?.eventTicketsNotGuests.checkins
                    ?.notSpecified,
                  event?.eventTickets?.eventTicketsNotGuests.checkins?.amount
                )}%)`}
              </p>
            </div>
            <div>
              <h3>
                {moneyMaskNumber(
                  event?.eventTickets?.eventTicketsNotGuests.averageSpend
                    .total ?? 0
                )}
              </h3>
              <p>Ticket médio</p>
              <p>
                <span className={styles.description}>Masculino:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsNotGuests.averageSpend.men ??
                    0
                )}`}
              </p>
              <p>
                <span className={styles.description}>Feminino:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsNotGuests.averageSpend
                    .women ?? 0
                )}`}
              </p>
              <p>
                <span className={styles.description}>Não informado:</span>
                {`${moneyMaskNumber(
                  event?.eventTickets?.eventTicketsNotGuests.averageSpend
                    .notSpecified ?? 0
                )}`}
              </p>
            </div>
          </div>
          <h2>Vinculados à lista de convidados</h2>
          <div className={isExport ? styles.export : ""}>
            <div>
              <h3>
                {moneyMaskNumber(
                  event?.eventTickets?.eventTicketsGuests?.totalBilled ?? 0
                )}
              </h3>
              <p>Total faturado</p>
            </div>
            <div>
              <div>
                <h3>
                  {event?.eventTickets?.eventTicketsGuests?.checkins.amount ??
                    0}
                </h3>
                <h5>Total de convidados</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.checkins.men ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.checkins.men,
                    event?.eventTickets?.eventTicketsGuests?.checkins.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.checkins.women ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.checkins.women,
                    event?.eventTickets?.eventTicketsGuests?.checkins.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.checkins
                      ?.notSpecified ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.checkins
                      ?.notSpecified,
                    event?.eventTickets?.eventTicketsGuests?.checkins.amount
                  )}%)`}
                </p>
              </div>
              <div>
                <h3>
                  {moneyMaskNumber(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend
                      .total ?? 0
                  )}
                </h3>
                <h5>Ticket médio</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${moneyMaskNumber(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.men ??
                      0
                  )} (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.men,
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.total
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${moneyMaskNumber(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend
                      .women ?? 0
                  )} (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.women,
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.total
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${moneyMaskNumber(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend
                      .women ?? 0
                  )} (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.women,
                    event?.eventTickets?.eventTicketsGuests?.averageSpend.total
                  )}%)`}
                </p>
              </div>
            </div>
            <div>
              <div>
                <h3>
                  {event?.eventTickets?.eventTicketsGuests?.ticketsSold
                    ?.amount ?? 0}
                </h3>
                <h5>Ingressos vendidos</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.men ??
                    0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.men,
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold
                      ?.women ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.women,
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold
                      ?.notSpecified ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold
                      ?.notSpecified,
                    event?.eventTickets?.eventTicketsGuests?.ticketsSold?.amount
                  )}%)`}
                </p>
              </div>
              <div>
                <h3>
                  {event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                    ?.amount ?? 0}
                </h3>
                <h5>Quantidade de OFF's</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .men ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .men,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.notSpecified ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.notSpecified,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.amount
                  )}%)`}
                </p>
              </div>
            </div>
            <div>
              <div>
                <h3>
                  {event?.eventTickets?.eventTicketsGuests?.checkins
                    ?.amount ?? 0}
                </h3>
                <h5>Check-in realizados</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.checkins
                      .men ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.checkins
                      .men,
                    event?.eventTickets?.eventTicketsGuests?.checkins
                      ?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.amount
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women ?? 0
                  } (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      .women,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                      ?.amount
                  )}%)`}
                </p>
              </div>
              <div>
                <h3>
                  {moneyMaskNumber(
                    event.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .total ?? 0
                  )}
                </h3>
                <h5>Valor Total OFF</h5>
                <p>
                  <span className={styles.description}>Masculino:</span>
                  {`${moneyMaskNumber(
                    event.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .men ?? 0
                  )} (${renderPercentage(
                    event.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .men,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .total
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Feminino:</span>
                  {`${moneyMaskNumber(
                    event.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .women ?? 0
                  )} (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .women,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .total
                  )}%)`}
                </p>
                <p>
                  <span className={styles.description}>Não informado:</span>
                  {`${moneyMaskNumber(
                    event.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .notSpecified ?? 0
                  )} (${renderPercentage(
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .notSpecified,
                    event?.eventTickets?.eventTicketsGuests?.freeTicketsTotal
                      .total
                  )}%)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
