import UseDimension from "components/dimension/UseDimension";
import styles from "./LayoutAuth.module.scss";

export interface ILayoutAuthProps {
  children: JSX.Element;
}

const LayoutAuth = ({ children }: ILayoutAuthProps) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.LayoutWebContainer}>
      <div
        className={styles.backgroundContainer}
        style={{ display: dimensions.width >= 1000 ? "flex" : "none" }}
      >
        <img
          src="/assets/img/logo-white-beta.svg"
          alt=""
          className={styles.logoImage}
        />
      </div>
      <div
        className={styles.layoutWebContent}
        style={{ width: dimensions.width >= 1000 ? "45vw" : "100%" }}
      >
        {children}
      </div>
    </div>
  );
};

export default LayoutAuth;
