import styles from "./ForDeviceItemMobile.module.scss";
import { format } from "date-fns";
import { ISales } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface IForDeviceItemMobileProps {
  item: ISales;
}

const ForDeviceItemMobile = ({ item }: IForDeviceItemMobileProps) => {
  return (
    <div id={styles.ForDeviceItemMobile} className="div-to-capture">
      {item.equipamentos && item.equipamentos.map((device) => (
        <div className={styles.deviceItem} key={device.equipamentoId}>
          <div className={styles.item}>
            <span className={styles.title}>Equipamento:</span>
            <span>{device.equipamentoNome}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Nº Série:</span>
            <span>
              {device.equipamentoNumeroSerie && device.equipamentoNumeroSerie.length > 10 
                ? `${device.equipamentoNumeroSerie.substring(0, 6)}...` : device.equipamentoNumeroSerie
              }
            </span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cancelado:</span>
            <span>{moneyMaskNumber(device.cancelado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cashless:</span>
            <span>{moneyMaskNumber(device.valorCashless ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito:</span>
            <span>{moneyMaskNumber(device.valorDebito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito (NT):</span>
            <span>{moneyMaskNumber(device.valorDebitoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito:</span>
            <span>{moneyMaskNumber(device.valorCredito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito (NT):</span>
            <span>{moneyMaskNumber(device.valorCreditoNT ?? 0)}</span>
          </div>
          {/* <div className={styles.item}>
            <span className={styles.title}>PIX:</span>
            <span>PIX</span>
          </div> */}
          <div className={styles.item}>
            <span className={styles.title}>Dinheiro:</span>
            <span>{moneyMaskNumber(device.valorDinheiro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Desconto:</span>
            <span>{moneyMaskNumber(device.valorDesconto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Voucher:</span>
            <span>{moneyMaskNumber(device.valorVoucher ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Outros:</span>
            <span>{moneyMaskNumber(device.valorOutro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Em aberto:</span>
            <span>{moneyMaskNumber(device.valorEmAberto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Suprimento:</span>
            <span>{moneyMaskNumber(device.suprimentos ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Sangria:</span>
            <span>{moneyMaskNumber(device.sangrias ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Taxa de serviço:</span>
            <span>{moneyMaskNumber(device.taxaServico ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total:</span>
            <span>{moneyMaskNumber(device.valorTotal ?? 0)}</span>
          </div>
        </div>
      ))}
      <div className={styles.totalItem}>
        <div className={styles.item}>
          <span>{item.dataInicio ? format(new Date(item.dataInicio + "Z"), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total cancelado:</span>
          <span>{moneyMaskNumber(item.cancelado ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total cashless:</span>
          <span>{moneyMaskNumber(item.cashless ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total débito:</span>
          <span>{moneyMaskNumber(item.debito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total débito (NT):</span>
          <span>{moneyMaskNumber(item.debitoNT ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total crédito:</span>
          <span>{moneyMaskNumber(item.credito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total crédito (NT):</span>
          <span>{moneyMaskNumber(item.creditoNT ?? 0)}</span>
        </div>
        {/* <div className={styles.item}>
          <span className={styles.title}>Total PIX:</span>
          <span>PIX</span>
        </div> */}
        <div className={styles.item}>
          <span className={styles.title}>Total dinheiro:</span>
          <span>{moneyMaskNumber(item.dinheiro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total desconto:</span>
          <span>{moneyMaskNumber(item.desconto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total voucher:</span>
          <span>{moneyMaskNumber(item.voucher ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total outros:</span>
          <span>{moneyMaskNumber(item.outro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total em aberto:</span>
          <span>{moneyMaskNumber(item.emAberto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total suprimento:</span>
          <span>{moneyMaskNumber(item.suprimento ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total sangria:</span>
          <span>{moneyMaskNumber(item.sangria ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total tx. de serviço:</span>
          <span>{moneyMaskNumber(item.taxaServico ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total:</span>
          <span>{moneyMaskNumber(item.total ?? 0)}</span>
        </div>
      </div>
    </div>
  );
};
export default ForDeviceItemMobile;
