import { IconButton } from '@material-ui/core'
import { Icon } from '@mui/material'
import { Skeleton } from 'components/skeleton/Skeleton';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { moneyMaskNumber } from 'services/utils/Money';
import styles from './OrderItem.module.scss'
import UseReportOrder, { IParamsClientDiscountCouponsOrderReport } from 'modules/config/discountCoupons/presentation/hooks/UseReportOrder';
import { IDiscountCouponsReport, IReportItem } from 'modules/config/discountCoupons/presentation/interfaces/IDiscountCouponsReport';
import { OrderList } from '../orderList/OrderList';
import { IOrderItem, IReportOrder } from 'modules/config/discountCoupons/presentation/interfaces/IReportOrder';
import { IGetReportOrderResponse } from 'modules/config/discountCoupons/domain/dto/IGetReportOrderResponse';

export interface IOrderItemProps {
    onClose: () => void;
    reportOrder: IReportOrder | undefined
    count: number;
    recordOrder: IOrderItem[] | undefined
    isLoading: boolean | undefined
    getReportOrder: (params: IParamsClientDiscountCouponsOrderReport) => Promise<IGetReportOrderResponse | undefined>
}

export const OrderItem: React.FC<IOrderItemProps> = ({ onClose, reportOrder, getReportOrder, count, isLoading, recordOrder }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [params, setParams] = useState<IParamsClientDiscountCouponsOrderReport>();



    useEffect(() => {
        reportOrder?.orderId && getReportOrder({ ...params, orderId: reportOrder?.orderId });
    }, [getReportOrder, params, reportOrder?.orderId])


    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        setParams({ orderId: reportOrder?.orderId ?? '', pagination: { page: currentPage + 1, pageSize: 20 } });

    }, [currentPage, reportOrder?.orderId]);



    return (
        <div id={styles.OrderItem}>
            <div className={styles.titleTop}>
                <h2>Pedido <b>{reportOrder?.orderId.substring(0, 6)}</b></h2>
                <IconButton size='small' onClick={onClose}><Icon>close</Icon></IconButton>
            </div>
            <OrderList
                item={recordOrder}
                count={count}
                getMore={getMore}
                params={params}
                isLoading={isLoading}
            />
            <div className={styles.containerTotal}>
                <div><span>Taxa de serviço</span><b>{moneyMaskNumber(reportOrder?.serviceTax)}</b></div>
                <div><span>Cupom de desconto</span>{reportOrder?.coupons.map(item => (
                    <div className={styles.coupon}>
                        <b>{item.code}</b>
                        <b>({moneyMaskNumber(item.value)})</b>
                    </div>
                ))}</div>
                <div><span>Total de pedido</span> <span className={styles.totalOrder}><s>{moneyMaskNumber(reportOrder?.totalGross)}</s><b>{moneyMaskNumber(reportOrder?.totalNet)}</b></span></div>
            </div>
        </div>
    )
}
