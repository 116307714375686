import { IEventsApi } from '../../interfaces/IEventsService';

const DeleteEventUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  localId: string,
): Promise<void> => {
  return await eventsService.deleteEvent(eventId, localId);
};

export default DeleteEventUseCase;
