export interface IKDSItemStep {
    id: string;
    name: string;
    color: string;
}

export enum KDSStatusStep {
  PENDING = "Pendente",
  ACCEPTED = "Aceito",
  FINISHED = "Finalizado",
  PREPARING = "Em preparo",
  TRANSPORT = "Em transporte",
  DELIVERED = "Entregue",
  REFUSED = "Recusado"
}

export interface IKDSItem {
    id: string;
    customer?: {
        name?: string;
        document?: string;
        address?: string;
        phoneNumber?: string;
        email?: string;
    };
    code: string;
    createdAt: string;
    deviceName: string;
    acceptedAt: string;
    isPresentialPayment?: boolean;
    observation?: string;
    table: string;
    orderOrigin?: number; //1=meep 2=ifood
    paymentType: {
        id: number;
        description: string;
    };
    change?: number;
    amount?: number;
    remotePrinter: {
        address: string;
    };
    order: {
        items: {
            id: string;
            name: string;
            value: number;
            quantity: number;
            printerName: string;
            nameCategory: string;
            compositions?: {
                description: string;
                options: string[]
            }[]
        }[];
    };
    currentStep: IKDSItemStep;
    panelCode: number;
    password?: string;
}
