import { Icon } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { FC } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import styles from './GenderTable.module.scss'
export interface IGenderTableProps {
    //propertys
    isLoading?: boolean
    data?: {
        icon?: string
        valueLabel?: string,
        value?: string | number,
        male?: string | number
        female?: string | number,
        unknown?: string | number,
    }
}
const GenderTable: FC<IGenderTableProps> = ({ data, isLoading }) => {
    return (
        <div id={styles.GenderTable} >
            <div className={styles.top} >
                { !isLoading ?(data?.icon && <Icon className={styles.icon}>{data?.icon}</Icon>): <Skeleton  animation={'wave'} variant={'circle'} height={50} width={50} />}
                {!isLoading ? <b>{typeof data?.value === 'number' ? moneyMaskNumber(Number(data?.value)) : data?.value}</b> : <Skeleton variant={'rect'} height={30} width={80} />}
                {!isLoading ? <span>{data?.valueLabel}</span> : <Skeleton variant={'rect'} height={30} width={200} />}
            </div>
            <div className={styles.content} >
                <div className={styles.row} >
                    {!isLoading ? <><label>Masculino:</label><span>{typeof data?.value === 'number' ? moneyMaskNumber(Number(data?.male)) : data?.male}</span></> : <Skeleton animation={'wave'}  variant={'rect'} height={30} width="100%" />}
                </div>
                <div className={styles.row} >
                    {!isLoading ? <><label>Feminino:</label><span>{typeof data?.value === 'number' ? moneyMaskNumber(Number(data?.female)) : data?.female}</span></> : <Skeleton animation={'wave'}  variant={'rect'} height={30} width="100%" />}
                </div>
                <div className={styles.row} >
                    {!isLoading ? <><label>Não informado:</label><span>{typeof data?.value === 'number' ? moneyMaskNumber(Number(data?.unknown)) : data?.unknown}</span></> : <Skeleton animation={'wave'}  variant={'rect'} height={30} width="100%" />}
                </div>
            </div>
        </div>
    )
}
export default GenderTable