import styles from "components/sidesheet/Sidesheet.module.scss";
import Input from "components/ui/input/Input";
import { Switch } from "@material-ui/core";
import { IIFoodStatusConfigRequest } from "modules/config/integrationsConfig/domain/dto/iFoodStatusConfig/iFoodStatusConfigRequest";
import { useEffect } from "react";

export interface IConfigContentIFoodProps {
  step: number;
  values: IIFoodStatusConfigRequest;
  setValues: React.Dispatch<React.SetStateAction<IIFoodStatusConfigRequest>>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}

export enum SankhyaIntegrationStep {
  integration = 0,
  payment = 1,
  success = 2,
}

const ConfigContentIFood = ({
  step,
  values,
  setValues,
  error,
  setError,
}: IConfigContentIFoodProps) => {
  useEffect(() => {
    if(values?.isActive === undefined){
      setValues((prev) => ({
        ...prev,
        isActive: true,
      }))
    }
  },[])

  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="store-id" className={styles.required}>
              ID da loja
            </label>
            <Input
              name="store-id"
              variant={"outlined"}
              value={values?.merchantId}
              onChange={(ev) => {
                setError(null);
                setValues((prev) => ({
                  ...prev,
                  merchantId: ev.target.value,
                }));
              }}
              error={!!error}
              helperText={error}
            />
          </div>
          <div className={styles.flexCenter}>
            <Switch
              color="secondary"
              name="find-orders"
              defaultChecked={values?.isActive !== undefined ? values.isActive : true}
              value={values?.isActive}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  isActive: ev.target.checked,
                }));
              }}
            />
            <label htmlFor="find-orders">Buscar pedidos do iFood</label>
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentIFood;
