import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import styles from './DataForm.module.scss'
import { IPermutationForm } from 'modules/permutation/presentation/interfaces/IPermutationForm'
import { DocumentType } from 'modules/permutation/presentation/pages/permutationAccountList/addPermutation/AddPermutation'
import InputMasked from 'components/inputMasked/InputMasked'
import { IPermutationFormErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros'


interface IDataFormProps {
    changeHandle: (name: string, value: string) => void
    values: IPermutationForm
    optionDocument: DocumentType
    setOptionDocument: React.Dispatch<React.SetStateAction<DocumentType>>
    changeHandleDocument: (document: DocumentType) => void
    errors: IPermutationFormErros
    setErrors: React.Dispatch<React.SetStateAction<IPermutationFormErros>>
    changeHandleCpfOrCnpj: (name: string, value: string) => void
    isEdit: React.MutableRefObject<boolean>
    containerType: DocumentType
}

export const DataForm: React.FC<IDataFormProps> = ({ errors, containerType, isEdit, changeHandleCpfOrCnpj, changeHandle, values, optionDocument, changeHandleDocument }) => {


    return (
        <div id={styles.DataForm}>
            <RadioGroup
                onChange={(_, value) => changeHandleDocument(Number(value))}
                value={isEdit.current ? values.documentTypeId : optionDocument}
                aria-labelledby="demo-radio-buttons-group-label"

            >
                <div className={styles.containerRadio}>
                    <div className={styles.radioItem}>
                        <FormControlLabel
                            value={DocumentType.CPF}
                            control={<Radio />}
                            label="CPF"
                            disabled={isEdit.current}
                        />
                    </div>
                    <div className={styles.radioItem}>
                        <FormControlLabel
                            value={DocumentType.CNPJ}
                            control={<Radio />}
                            label="CNPJ"
                            disabled={isEdit.current}
                        />
                    </div>
                </div>
            </RadioGroup>
            <div className={styles.item}>
                {
                    containerType === DocumentType.CPF ?
                        <>
                            <label>CPF <span>*</span></label>
                            <InputMasked
                                name="document"
                                variant='outlined'
                                disabled={isEdit.current}
                                fullWidth
                                mask="999.999.999-99"
                                placeholder="Digite seu CPF"
                                value={values.document}
                                onChange={(ev: any) =>
                                    changeHandleCpfOrCnpj(ev.target.name, ev.target.value)
                                }
                                type="tel"
                                error={!!errors?.document}
                                helperText={errors.document}
                            />
                        </>
                        :
                        <>
                            <label>CPNJ <span>*</span></label>
                            <InputMasked
                                name="document"
                                variant='outlined'
                                disabled={isEdit.current === true}
                                fullWidth
                                mask="99.999.999/9999-99"
                                placeholder="Digite o CNPJ"
                                value={values.document}
                                onChange={(ev: any) =>
                                    changeHandleCpfOrCnpj(ev.target.name, ev.target.value)
                                }
                                type="tel"
                                error={!!errors?.document}
                                helperText={errors.document}
                            />
                        </>
                }
            </div>
            <div className={styles.item}>
                <label>{containerType === DocumentType.CNPJ ? "Nome empresarial ou Razão social" : "Nome"} <span>*</span></label>
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    size="small"
                    placeholder=""
                    variant="outlined"
                    disabled={isEdit.current === true}
                    name="name"
                    value={values.name}
                    error={!!errors?.name}
                    helperText={errors?.name}
                    onChange={(ev) =>
                        changeHandle(ev.target.name, ev.target.value)
                    }
                />
            </div>
        </div >
    )
}
