import { Icon } from '@material-ui/core';
import styles from './Post.module.scss';
import { IPost } from "modules/posts/presentation/interfaces/IPosts"
import { useHistory } from 'react-router-dom';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInWeeks } from 'date-fns';
import { capitalize } from 'services/utils/String';
import { useState } from 'react';

interface PostProps {
    post: IPost;
    localName: string;
    localImageUrl: string;
    onEdit: (post: IPost) => void
    onDelete: (post: IPost) => void
}

export const Post = ({ post, localName, localImageUrl, onEdit, onDelete }: PostProps) => {
    const [showLikes, setShowLikes] = useState(false);

    const history = useHistory();

    const mountDate = () => {
        const minutes = differenceInMinutes(new Date(), new Date(post.date + 'Z'));
        const hours = differenceInHours(new Date(), new Date(post.date + 'Z'));
        const days = differenceInDays(new Date(), new Date(post.date + 'Z'));
        const weeks = differenceInWeeks(new Date(), new Date(post.date + 'Z'));

        if (!minutes) {
            return `Agora`;
        }
        if (!hours) {
            return `${minutes < 0 ? 'Daqui' : 'Há'} ${minutes < 0 ? minutes * -1 : minutes} minuto${minutes > 1 || minutes < -1 ? 's' : ''}`;
        }
        if (!days) {
            return `${hours < 0 ? 'Daqui' : 'Há'} ${hours < 0 ? hours * -1 : hours} hora${hours > 1 || hours < -1 ? 's' : ''}`;
        }
        if (!weeks) {
            return `${days < 0 ? 'Daqui' : 'Há'} ${days < 0 ? days * -1 : days} dia${days > 1 || days < -1 ? 's' : ''}`;
        }
        return `${weeks < 0 ? 'Daqui' : 'Há'} ${weeks < 0 ? weeks * -1 : weeks} semana${weeks > 1 || weeks < -1 ? 's' : ''}`;
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <img src={localImageUrl} alt="" className={styles.localImg} />
                <span className={styles.localName}>{capitalize(localName)}:</span>

                <span className={styles.date}>{mountDate()}</span>
                <button className={styles.actionButton} onClick={() => setShowLikes(!showLikes)}>
                    {
                        showLikes &&
                        <div className={styles.likes}>
                            <div>
                                <span>Curtidas</span>
                                <button onClick={() => setShowLikes(false)}><Icon>close</Icon></button>
                            </div>
                            <p>A sua publicação {post.likes ? ' recebeu ' + post.likes + ` curtida${post.likes > 1 ? 's' : ''}.` : 'ainda não recebeu nenhuma curtida.'}</p>
                        </div>
                    }
                    <Icon>info_outlined</Icon>
                </button>
                <button className={styles.actionButton} onClick={() => onEdit(post)}>
                    <Icon>create_outlined</Icon>
                </button>
                <button className={styles.actionButton} onClick={() => onDelete(post)}>
                    <Icon>delete_outlined</Icon>
                </button>
            </div>
            <div className={styles.content}>
                {post.content}
            </div>
            {
                post.imageUrl &&
                <div className={styles.imageContainer}>
                    <img src={post.imageUrl} alt="" className={post.eventId ? styles.straight : ''} />
                    {
                        post.eventId &&
                        <button onClick={() => history.push('/private/event/eventos/gerenciar/' + post.eventId)}>
                            Acessar evento
                        </button>
                    }
                </div>
            }
        </div>
    )
}