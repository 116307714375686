import {
  ITransferFormErrors,
  ITransferFormItem,
} from "../interfaces/ITransferForm";

export const validate = (
  formData: ITransferFormItem,
  setErrors: React.Dispatch<React.SetStateAction<ITransferFormErrors>>,
  currentStep: number
) => {
  let _errors: ITransferFormErrors = {};
  let hasError = false;

  if (!formData.conta) {
    _errors = { ..._errors, conta: "Campo Conta obrigatório" };
    hasError = true;
  }

  if (!formData.banco) {
    _errors = { ..._errors, banco: "Campo Banco obrigatório" };
    hasError = true;
  }

  if (!formData.agencia) {
    _errors = { ..._errors, agencia: "Campo Agência obrigatório" };
    hasError = true;
  }

  if (!formData.digitoAg) {
    _errors = { ..._errors, digitoAg: "Campo Digito obrigatório" };
    hasError = true;
  }

  if (!formData.contaCorrente) {
    _errors = { ..._errors, contaCorrente: "Campo Conta Corrente obrigatório" };
    hasError = true;
  }

  if (!formData.digitoConta) {
    _errors = { ..._errors, digitoConta: "Campo Digito obrigatório" };
    hasError = true;
  }

  if (!formData.documento) {
    _errors = { ..._errors, documento: "CPF/CNPJ da conta titular" };
    hasError = true;
  }

  if (!formData.nome) {
    _errors = { ..._errors, nome: "Campo Nome do titular é obrigatório" };
    hasError = true;
  }

  if (!formData.valor) {
    _errors = { ..._errors, valor: "Campo valor obrigatório" };
    hasError = true;
  }

  if (currentStep === 3) {
    if (!formData.codigo) {
      _errors = { ..._errors, codigo: "O campo código é obrigatório" };
      hasError = true;
    }
  }

  setErrors(_errors);

  return !hasError;
};
