import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { ICloseEvent } from "../../ICloseEvent";
import { CloseTitle } from "../closeTitle/CloseTitle";
import styles from "./Details.module.scss";

interface IDetailsProps {
  event: ICloseEvent;
  isExport: boolean;
  pageBreak: boolean;
}

export const Details: FC<IDetailsProps> = ({ event, isExport, pageBreak }) => {
  return (
    <div id={styles.Details} className={pageBreak ? styles.pageBreak : ""}>
      <CloseTitle
        isExport={isExport}
        title="Detalhamento"
        subTitle="Informações detalhadas do uso dos sistemas no seu evento."
      />

      <div className={styles.tableContainer}>
        <div className={`${styles.table} ${isExport ? styles.export : ""}`}>
          <div className={styles.legend}>
            <span />
            <h2>Crédito</h2>
            <h2>Débito</h2>
            <h2>Dinheiro</h2>
            <h2>Voucher</h2>
            <h2>Permuta</h2>
            <h2>Bonús</h2>
            <h2>Perda</h2>
            <h2>Outros</h2>
            <div>
              <h2>Total</h2>
            </div>
          </div>
          <div className={styles.data}>
            {event?.eventDetailing?.map((item, key) => (
              <div key={key}>
                <h2>{item.label}</h2>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.credit)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.debit)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.cash)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.voucher)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.exchange)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.bonus)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.lost)}</p>
                </div>
                <div>
                  <p>{moneyMaskNumber(item.detailingData.others)}</p>
                </div>
                <span>
                  <div>
                    <p>{moneyMaskNumber(item.detailingData.total)}</p>
                  </div>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
