import { erpUrl } from "Enviroment";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { SaveCorporatePartnerRequest, GetDefaultCorporatePartnerResponse, GetDefaultIndividualPartnerResponse, IGetPartnerListRequest, PartnerListResponse, SaveIndividualPartnerRequest } from "modules/meepErp/application/dtos/partner/PartnerDtos";
import { IPartnerService } from "modules/meepErp/application/interfaces/_partner/IPartnerService";
import { Api } from "services/api/Api";

const api = Api();
const BASE_URL = erpUrl;

const PartnerService = (): IPartnerService => {
  const getPartnerList = async (ownerId: string, request: IGetPartnerListRequest): Promise<PartnerListResponse> => {
    const response = await api.get<PartnerListResponse>(BASE_URL + "/api/Inventory/Patners", { params: request });
    return response.data;
  };

  const deletePartner = async (id: string): Promise<void> => {
    return await api.delete(BASE_URL + `/api/Inventory/Patners/${id}`);
  };

  const getCorporatePartner = async (id: string): Promise<GetDefaultCorporatePartnerResponse> => {
    const response = await api.get<GetDefaultCorporatePartnerResponse>(BASE_URL + `/api/Inventory/Patners/Corporates/${id}`);
    return response.data;
  };

  const getIndividualPartner = async (id: string): Promise<GetDefaultIndividualPartnerResponse> => {
    const response = await api.get<GetDefaultIndividualPartnerResponse>(BASE_URL + `/api/Inventory/Patners/Individuals/${id}`);
    return response.data;
  };

  const createCorporatePartner = async (partner: SaveCorporatePartnerRequest): Promise<GetDefaultIndividualPartnerResponse> => {
    const response = await api.post(BASE_URL + `/api/Inventory/Patners/Corporates`, partner);
    return response.data;
  };

  const createIndividualPartner = async (partner: SaveIndividualPartnerRequest): Promise<GetDefaultCorporatePartnerResponse> => {
    const response = await api.post(BASE_URL + `/api/Inventory/Patners/Individuals`, partner);
    return response.data;
  };

  const updateCorporatePartner = async (id: string, partner: SaveCorporatePartnerRequest): Promise<GetDefaultIndividualPartnerResponse> => {
    const response = await api.put(BASE_URL + `/api/Inventory/Patners/Corporates/${id}`, partner);
    return response.data;
  };

  const updateIndividualPartner = async (id: string, partner: SaveIndividualPartnerRequest): Promise<GetDefaultCorporatePartnerResponse> => {
    const response = await api.put(BASE_URL + `/api/Inventory/Patners/Individuals/${id}`, partner);
    return response.data;
  };

  const enablePartner = async (id: string): Promise<IEnableDisableResponse> => {
    return await api.patch(BASE_URL + `/api/Inventory/Patners/Enable/${id}`);
  }
  const disablePartner = async (id: string): Promise<IEnableDisableResponse> => {
    return await api.patch(BASE_URL + `/api/Inventory/Patners/Disable/${id}`);
  }

  return {
    getPartnerList,
    getCorporatePartner,
    getIndividualPartner,
    createCorporatePartner,
    createIndividualPartner,
    updateCorporatePartner,
    updateIndividualPartner,
    deletePartner,
    enablePartner,
    disablePartner
  };
};

export default PartnerService;
