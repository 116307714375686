import React, { FC, useMemo } from "react";
import styles from "./FinancialInvoices.module.scss";
import { IFinancialInvoicesColumnsConfig } from "../../interfaces/IColumnsConfig";

interface FinancialInvoicesHeaderProps {
  columns: IFinancialInvoicesColumnsConfig[],
  hasBoleto: boolean | undefined,
  hasNotaFiscal: boolean | undefined
}


const FinancialInvoicesHeader: FC<FinancialInvoicesHeaderProps> = ({ columns, hasBoleto, hasNotaFiscal }) => { 

  const header = useMemo(() => columns.map((column, index) =>
    !column.hide && <div
      key={index}
      className={styles.row}
      style={column.style}
    >
      <span className={styles.order}>{column.title}</span>
    </div>
  ), [columns])

  return (
    <div id={styles.FinancialInvoicesHeaderContainer}>
      <div className={styles.container}>      
        {header}
        {
          (hasBoleto || hasNotaFiscal) &&
          <div className={hasBoleto || hasNotaFiscal ? styles.columnLarge : styles.icon}></div>     
        }
      </div>
    </div>
  );
};

export default FinancialInvoicesHeader;
