import React, { FC, useEffect } from 'react'
import styles from './StockOperationForm.module.scss'
import { IStockTypeItemSelect, IStockOperationValues } from './StockOperationValues'
import { zodResolver } from "@hookform/resolvers/zod";
import { IInventoryOperationSchema } from './StockOperationFormValidation';
import { useForm } from 'react-hook-form';
import { ButtonContainer, Container, InputContainer, Row } from '../../ui/form/FormContainers';
import { SupplyStockMovementType } from 'modules/meepErp/models/stockMovement/_SupplyStockMovementType';
import { FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import Back from '../../ui/back/Back';
import Button from 'components/ui/Button/Button';
import { useHistory } from 'react-router-dom';

export interface IStockOperationFormProps {
    isLoading?: boolean,
    disabled?: boolean,
    onSubmit: (values: IStockOperationValues) => Promise<void>
    operationTypes?: IStockTypeItemSelect[]
    defaultValue?: IStockOperationValues
}
const StockOperationForm: FC<IStockOperationFormProps> = ({
    // getDefaultValues,
    onSubmit,
    isLoading,
    disabled,
    // getOpeartionTypes,
    defaultValue,
    operationTypes
}) => {
    const { push } = useHistory()
    // const [operationTypes, setOperationTypes] = React.useState<IOperationTypeItemSelect[]>([]);
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm<IStockOperationValues>({
        resolver: zodResolver(IInventoryOperationSchema),
        defaultValues: defaultValue ?? {
            name: "",
            type: "",
            canUpdateInventory: false,
            inventoryMovementType: SupplyStockMovementType.Input,
            canUpdateInventoryCost: false,
            isActive: true
        },
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        } else {
            reset({
                name: "",
                type: "",
                canUpdateInventory: false,
                inventoryMovementType: SupplyStockMovementType.Input,
                canUpdateInventoryCost: false,
                isActive: true
            })
        }
    }, [defaultValue, reset]);


    useEffect(() => {
        console.log(errors);

    }, [errors]);


    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Container center >
                <Back route='/private/meeperp/inventory/operations' margin />
                <Row>
                    {defaultValue?.code && <InputContainer label='Código'>
                        <TextField
                            value={defaultValue?.code}
                            disabled
                        />
                    </InputContainer>}
                    <InputContainer flex={3} label='Nome' requerid>
                        <TextField
                            {...register("name")}
                            disabled={isLoading || disabled}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </InputContainer>
                </Row>
                <Row>
                    <InputContainer label='Tipo' requerid>
                        <TextField
                            {...register("type")}
                            disabled={isLoading || disabled}
                            error={!!errors.type}
                            select
                            value={watch("type")}
                            helperText={errors.type?.message}
                        >
                            {operationTypes?.map((type) => (
                                <MenuItem key={type.id} value={type.id}>{type.description}</MenuItem>
                            ))}
                        </TextField>
                    </InputContainer>
                </Row>
                <Row>
                    <InputContainer>
                        <FormControlLabel
                            {...register("canUpdateInventory")}
                            disabled={isLoading || disabled}
                            checked={watch('canUpdateInventory')}
                            control={<Switch />}
                            label="Atualizar Estoque"
                        />
                    </InputContainer>
                </Row>
                {
                    watch('canUpdateInventory') &&
                    <Container subContainer border >
                        <Row>
                            <InputContainer
                                label="Movimentação"
                            >
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={watch('inventoryMovementType')}
                                        onChange={(e) => {
                                            setValue('inventoryMovementType', Number(e.target.value) as SupplyStockMovementType)
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value={SupplyStockMovementType.Input}
                                            control={<Radio />}
                                            label="Entrada"
                                        />
                                        <FormControlLabel
                                            value={SupplyStockMovementType.Output}
                                            control={<Radio />}
                                            label="Saída"
                                        />
                                    </RadioGroup>
                                    <FormHelperText error={!!errors.inventoryMovementType}>
                                        {errors.inventoryMovementType?.message}
                                    </FormHelperText>
                                </FormControl>
                            </InputContainer>
                        </Row>
                        {watch('inventoryMovementType') === SupplyStockMovementType.Input &&
                            <Row>
                                <FormControlLabel
                                    {...register("canUpdateInventoryCost")}
                                    disabled={isLoading || disabled}
                                    checked={watch('canUpdateInventoryCost')}
                                    control={<Switch />}
                                    label="Atualizar Custo do Estoque"
                                />

                            </Row>}
                    </Container>
                }
                {/* {JSON.stringify(watch())} */}
                <ButtonContainer >
                    <Button color='secondary' disabled={isLoading || disabled} onClick={() => push('/private/meeperp/inventory/operations')} fullWidth={false} variant='outlined'>Cancelar</Button>
                    <Button disabled={isLoading || disabled} type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                </ButtonContainer>
            </Container>
        </form>
    )
}
export default StockOperationForm