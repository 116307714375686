import { IGetExtractService } from "../interfaces/IContaDigitalApiService";

const GetExtract = async (
  accountService: IGetExtractService,
  BankAccountId: string,
  StartDate: string,
  EndDate: string,
  LocalId: string,
  Page: number,
  PageSize: number
): Promise<any> => {
  return await accountService.getExtract(
    BankAccountId,
    StartDate,
    EndDate,
    LocalId,
    Page,
    PageSize
  );
};

export default GetExtract;
