import ICashlessDashboardService from "modules/cashlessDashboard/domain/interfaces/ICashlessDashboardService";
import { ICashierItem } from "modules/cashlessDashboard/presentation/components/filter/ICashier";

const GetCashiersUseCase = async (
  dashboardService: ICashlessDashboardService,
  localId: string,
  startDate?: Date,
  endDate?: Date
): Promise<ICashierItem[]> => {
  return await dashboardService.getCashiers(localId, startDate, endDate);
};

export default GetCashiersUseCase;
