import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './DashboardContainer.module.scss'
import UseIsVisible from '../isVisble/UseIsVisible';

export interface IDashboarContainerProps {
    title?: string;
    tooltip?: string | string[];
    id?: string;
    removedCharts?: string[];
    onAddChart?: (chartId?: string) => void;
    onRemoveChart?: (chartId?: string) => void;
    onExport?: () => void;
    children: ReactNode;
    isChartContainer?: boolean;
    borderless?: boolean;
    separator?: boolean;
    removable?: boolean;
    span?: 1 | 2 | 3 | 4 | 5 | 6,
    width?: number | string;
    minHeight?: number,
    hideIcons?: boolean,
    mainTitle?: boolean,
    showData?: boolean,
}

const DashboardContainer: FC<IDashboarContainerProps> = ({
    title,
    id,
    children,
    tooltip,
    borderless = false,
    separator = false,
    isChartContainer = true,
    span = 6,
    minHeight,
    hideIcons,
    mainTitle,
    showData,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const { isVisible } = UseIsVisible(containerRef, true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width } = window;
            if (width < 800) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ minHeight, gridColumn: `span ${isMobile ? 6 : span}` }} id={styles.DashboardContainer} className={id} ref={containerRef}>
            <>
                <div className={`${styles.content} ${(!isChartContainer || borderless) ? styles.borderless : ''}`}>
                    {
                        isChartContainer &&
                        <div className={`${styles.header} ${separator ? styles.separator : ''}`} >
                            <h2 className={mainTitle ? styles.mainTitle : ""}>{title}</h2>
                            {!hideIcons && <Tooltip title={title || ''} text={tooltip || ''} />}


                        </div>
                    }
                    <div className={`${styles.childContainer}`}>
                        {(isVisible || showData) && (
                            <>

                                <div className={styles.childrenContent}>
                                    {children}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        </div>
    )
}
export default DashboardContainer