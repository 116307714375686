import React, { FC } from "react";
import EventsForm from "../../components/eventsForm/EventsForm";
import { UseNewEventsPage } from "./UseNewEventsPage";

export interface INewEventsPageProps {}

const NewEventsPage: FC<INewEventsPageProps> = () => {
  const { handleCancel, handleSubmit } = UseNewEventsPage();
  return <EventsForm handleSubmit={handleSubmit} handleCancel={handleCancel} />;
};
export default NewEventsPage;
