import { ISidesheetFormErrors, ISidesheetFormItem } from "../interfaces/ISidesheetForm";

export const SidesheetValidation = (formData: ISidesheetFormItem, setErrors: React.Dispatch<React.SetStateAction<ISidesheetFormErrors>>) => {
  let _errors: ISidesheetFormErrors = {};
  let hasError = false;

  if (!formData.titulo || formData.titulo.trim().length === 0) {
    _errors = { ..._errors, titulo: "Campo TÍtulo é obrigatório" };
    hasError = true;
  }

  if (!formData.mensagem || formData.mensagem.trim().length === 0) {
    _errors = { ..._errors, mensagem: "Campo Mensagem é obrigatório" };
    hasError = true;
  } else if (formData.mensagem.length > 200) {
    _errors = { ..._errors, mensagem: "A mensagem não pode ter mais de 200 caracteres" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
