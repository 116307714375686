import React, { useEffect, useMemo, useState } from "react";
import styles from "./FinancialInvoices.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IFinancialInvoicesColumnsConfig } from "../../interfaces/IColumnsConfig";
import { IFinancialInvoicesList } from "modules/faturas/presentation/interfaces/IFinancialInvoicesList";
import { KeyboardArrowDown, KeyboardArrowUp, VerticalAlignBottom } from "@mui/icons-material";
import Utils from "services/utils/Utils";

interface Props {
  columns: IFinancialInvoicesColumnsConfig[];
  userItemData: IFinancialInvoicesList;
  financialInvoicesList: IFinancialInvoicesList[];  
  hasBoleto: boolean | undefined,
  hasNotaFiscal: boolean | undefined
}

const PermutationAccountItem: React.FC<Props> = ({
  userItemData,
  columns,
  hasBoleto,
  hasNotaFiscal
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        let displayValue = "-";
        switch (colunm.property) {        
          case "valor":
            displayValue = userItemData?.valor
              ? moneyMaskNumber(userItemData?.valor)
              : "-";
            break;
          default:
            displayValue = String(userItemData?.[colunm.property]) ?? "-";
            break;
        }

        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span title={displayValue}>{displayValue}</span>
            </div>
          )
        );
      }),
    [columns, userItemData]
  );



  return (
    <div id={styles.FinancialInvoicesItem}>
      <div className={styles.container}>
        {List}
        <div className={ hasBoleto || hasNotaFiscal ? styles.columnLarge : styles.icon}>
          {
            !userItemData.quitada && !userItemData.debitoEmTransacoes && userItemData.linkBoleto &&
            <div className={styles.iconAction}>
               <a href={`${userItemData.linkBoleto}`}>
                <VerticalAlignBottom /> <u>Boleto</u>
               </a>
            </div>
          }
          {
            userItemData.notaFiscal &&
            <div className={styles.iconAction} >
              <a href={`${userItemData.notaFiscal}`}>
                <VerticalAlignBottom /> <u>Baixar NF</u>
              </a>
            </div>
          }         
        </div>
       <div>
          {
              (!isDropdownOpen ?

              <KeyboardArrowDown onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
              :
              <KeyboardArrowUp onClick={() => setIsDropdownOpen(!isDropdownOpen)} />)
            }
       </div>
      </div>
      
      <div className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ""}`}>
        {(userItemData.itens && userItemData.itens.length > 0) && userItemData.itens.map(item => (
          <div key={item.id} className={styles.dropdownItem}>
            <span>{item.nome}</span>
            <span>{Utils.toDate(item.dataCadastro + "Z")}</span>
            <span>{moneyMaskNumber(Number(item.valor))}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermutationAccountItem;
