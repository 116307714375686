import React, { FC } from "react";
import styles from "./InputContainer.module.scss";

export const InputContainer: FC<{
  style?: React.CSSProperties;
  className?: string;
  alignItems?: string;
  flex?: number;
  row?: boolean;
  gap?: number;
}> = ({ style, className, flex = 1, row, gap = 16, children, alignItems }) => {
  return (
    <div
      className={styles.inputContainer + className ? ` ${className}` : ``}
      style={{
        display: "flex",
        flex: flex,
        alignItems: alignItems,
        flexWrap: "wrap",
        flexDirection: row ? "row" : "column",
        gap: gap,
        justifyContent: row ? "flex-end" : "flex-start",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
