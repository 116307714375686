import { ILeads } from "../interfaces/leads/ILeads"

export const getParamsObject = (query: URLSearchParams): ILeads => {
  return {
    // id: query.get('id') ?? '',
    email: query.get('email') ?? '',
    name: query.get('name') ?? '',
    type: Number(query.get('type')) ?? 0,
    phoneNumber: query.get('phoneNumber') ?? '',
    imei: query.get('imei') ?? '',
    serialNumber: (query.get('serialNumber') ?? query.get('serial')) ?? '',
    utm: {
      source: query.get('utm_source') ?? '',
      medium: query.get('utm_medium') ?? '',
      campaign: query.get('utm_campaign') ?? '',
      term: query.get('utm_term') ?? '',
      content: query.get('utm_content') ?? '',
      state: query.get('utm_state') ?? '',
      conversionIdentifier: query.get('utm_conversionIdentifier') ?? '',
      segment: query.get('utm_segment') ?? '',
      landingPage: query.get('utm_landingPage') ?? '',
    }
  }
}

export const getParamsString = (query: URLSearchParams) => {
  const obj = getParamsObject(query);
  let result = '';

  Object.keys(obj.utm).forEach((x: any) => {
    if (obj.utm[x as keyof typeof obj.utm]) result += `${x}=${obj.utm[x as keyof typeof obj.utm]}&`;
  });

  if (obj.email) result += `email=${obj.email}&`;
  // if (obj.id) result += `id=${obj.id}&`;
  if (obj.name) result += `name=${obj.name}&`;
  if (obj.phoneNumber) result += `phoneNumber=${obj.phoneNumber}&`;
  if (obj.type) result += `type=${obj.type}&`;
  if (obj.imei) result += `imei=${obj.imei}&`;
  if (obj.serialNumber) result += `serialNumber=${obj.serialNumber}`;

  if (result.charAt(result.length - 1) === '&') result = result.substring(0, result.length - 1);

  return result;
}
