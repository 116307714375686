import { IAddCouponRequest } from "../../domain/dto/IAddCouponRequest";

const ParseAddCouponRequestUseCase = (
  request: IAddCouponRequest,
  localId: string
) => {
  const _request: IAddCouponRequest = {
    ownerId: localId,
    code: request.code,
    description: request.description,
    valueType: request.valueType,
    value: request.value,
    maxValue: request.maxValue,
    quantity: request.quantity,
    forFirstOrder: request.forFirstOrder,
    quantityPerUser: request.quantityPerUser,
    minOrderValue: request.minOrderValue,
    maxOrderValue: request.maxOrderValue,
    startAt: request.startAt,
    finishAt: request.finishAt,
    operations: request.operations,
    deviceTypes: request.deviceTypes,
    devices: request.devices,
    isRequiredCustomerCPF: request.isRequiredCustomerCPF,
    customerCPFs: request.customerCPFs?.map((value) =>
      value.replace(/\D/g, "")
    ),
    appliedIn: 1,
    type: 1,
  };

  return _request;
};

export default ParseAddCouponRequestUseCase;
