import React, { FC, useCallback, useEffect } from 'react'
import styles from './InventoryForm.module.scss'
import AutocompleteInput, { IAutocompletePaginatedListResponse, IAutocompletePaginatedRequest } from '../../autocomplete/AutocompleteInput'
import { IInventoryFormValue, InventorySchema } from './InventoryFormValue'
import { useForm } from 'react-hook-form'
import { Container, FilterButtonContainer, InputContainer, Row } from '../../ui/form/FormContainers'
import { Icon, MenuItem, TextField } from '@material-ui/core'
import { ISupplyGroupItem, ISupplyGroupList, ISupplyGroupListRequest } from '../../supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import SupplyGroupInput from '../../supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/ParentGroupInput'
import { watch } from 'fs'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/ui/Button/Button'



export interface SelectAutocompleteItem { id: string, name: string }
export interface IInventoryFormValueProps {
    //propertys
    getSupply: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    getSuplyStorageLocals: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
    onSubmit: (data: IInventoryFormValue) => Promise<{ id: string }>
    disabled?: boolean
    defaultValues?: {
        id: string,
        description: string,
    }
}

const InventoryForm: FC<IInventoryFormValueProps> = ({
    getSupply,
    getSupplyGroupList,
    getSuplyStorageLocals,
    onSubmit,
    defaultValues,
    disabled
}) => {
    const { register, handleSubmit, formState, setValue, watch, reset } = useForm<IInventoryFormValue>(
        {
            resolver: zodResolver(InventorySchema),
            defaultValues: {
                description: defaultValues?.description ?? '',
                filterType: 'all',
                includeAllSupplies: true
            }
        }
    );



    const [supplyStorageLocals, setSupplyStorageLocals] = React.useState<SelectAutocompleteItem>()
    const [supplyGroup, setSupplyGroup] = React.useState<ISupplyGroupItem>()
    const [supply, setSupply] = React.useState<SelectAutocompleteItem>()



    useEffect(() => {
        if (defaultValues) {
            setValue('description', defaultValues.description)
            setSupply(undefined);
            setSupplyGroup(undefined);
            setSupplyStorageLocals(undefined);
        }

    }, [defaultValues, setValue]);

    useEffect(() => {
        console.log('errors', formState.errors);
    }, [formState.errors])

    return (
        <form onSubmit={handleSubmit(onSubmit)} id={styles.InventoryForm} >
            <Container>
                <InputContainer label="Descrição" >
                    <TextField
                        disabled={
                            !!defaultValues
                        }
                        {...register('description', { required: !defaultValues ? 'Campo obrigatório' : undefined })}
                        error={!!formState.errors.description}
                        helperText={formState.errors.description?.message}
                    />
                </InputContainer>
                {
                    !disabled &&
                    <Row>
                        <InputContainer style={{marginTop: '29px'}}>
                            <TextField
                                {...register('filterType')}
                                select
                                disabled={disabled}
                                value={watch("filterType")}
                                onChange={(e) => {
                                    setValue('filterType', e.target.value as 'all' | 'supply' | 'supplyGroup' | 'supplyStorageLocals')
                                    if (e.target.value === 'all') {
                                        setValue("includeAllSupplies", true)
                                    } else {
                                        setValue("includeAllSupplies", false)
                                    }
                                    setSupply(undefined);
                                    setSupplyGroup(undefined);
                                    setSupplyStorageLocals(undefined);
                                }}
                            >
                                <MenuItem value="all">Todos</MenuItem>
                                <MenuItem value="supply">Insumo</MenuItem>
                                <MenuItem value="supplyGroup">Grupo de insumo</MenuItem>
                                <MenuItem value="supplyStorageLocals">Local de estoque</MenuItem>
                            </TextField>
                        </InputContainer>
                        {watch("filterType") === "supplyGroup" && <InputContainer label="Grupo de insumo" >
                            <SupplyGroupInput
                                getSupplyGroupList={getSupplyGroupList}
                                value={supplyGroup}
                                onSelect={(item) => {
                                    setValue('supplyGroupId', item?.id)
                                    setSupplyGroup(item)
                                }}
                                disabled={disabled}
                                error={!!formState.errors.supplyGroupId}
                                helperText={formState.errors.supplyGroupId?.message}
                            // inputText={watch('supplyGroupName')}
                            />
                        </InputContainer>}
                        {watch("filterType") === "supply" && <InputContainer label="Insumo" >
                            <AutocompleteInput
                                value={supply}
                                getList={getSupply}
                                getOptionLabel={(option) => option.name}
                                onSelect={(value) => {
                                    setValue('supplyId', value?.id)
                                    setSupply(value)
                                }}
                                error={!!formState.errors.supplyId}
                                helperText={formState.errors.supplyId?.message}
                            />
                        </InputContainer>}
                        {watch("filterType") === "supplyStorageLocals" && <InputContainer label="Local de estoque" >
                            <AutocompleteInput
                                value={supplyStorageLocals}
                                getList={getSuplyStorageLocals}
                                getOptionLabel={(option) => option.name}
                                onSelect={(value) => {
                                    setValue('storageLocationId', value?.id)
                                    setSupplyStorageLocals(value)
                                }}
                                error={!!formState.errors.storageLocationId}
                                helperText={formState.errors.storageLocationId?.message}
                            />
                        </InputContainer>}
                        <FilterButtonContainer>
                            <Button endIcon={<Icon className={styles.iconAdd}>add</Icon>} type="submit" variant="contained" color="primary">Adicionar</Button>
                        </FilterButtonContainer>
                    </Row>}
            </Container>
        </form>
    )
}
export default InventoryForm