export enum ExportSalesFormatEnum {
  PDF = 1,
  Excel = 2,
}

export type GetExportSalesReportProps = {
  Categories: string[];
  Products: string[];
  Operators: string[];
  Equipaments: string[];
  Systems: string[];
  exportFormat?: ExportSalesFormatEnum;
  startDate?: Date;
  endDate?: Date;
  localId: string;
  cashierIds?: string[];
  onSuccess?: () => void;
  onError?: () => void;
};
