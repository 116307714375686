import { IAddDiscountToListRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const AddDiscountToBaseListUseCase = async (
  baseListService: IDiscountBaseList,
  discount: IAddDiscountToListRequest,
  localId: string,
): Promise<void> => {
  return await baseListService.addDiscountToBaseList(discount, localId);
};

export default AddDiscountToBaseListUseCase;
