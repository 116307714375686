export interface IPrintQueueList {
  data: IPrintQueueItem[];
  totalRegisters: number;
}

export interface IPrintQueueItem {
  id: string;
  status: PrintingStatus;
  createdAt: string;
  updatedAt: string | null;
  message: string;
  printOrder: IPrintOrder;
  printerName: string;
  order: GetOrderModel;
}

export interface GetOrderModel {
  id: string;
  amount: number;
  status: number;
}

export interface IPrintOrder {

    number: number;
    businessName: string;
    customer: string;
    date: string;
    note: string;
    details: IDetailsOrder[];
    table: string;
    pointOfSale: string;

}
export interface IDetailsOrder {
    quantity: number;
    productName: string
    productCategory: string;
    value: number;
    notes: [];
  }

export enum PrintingStatus {
  Received = 0,
  InQueue = 1,
  Printed = 2,
  Error = 3,
  Printing = 4,
  SendedToSpooler = 5,
  RetryPrinter = 6,
  SpoolerError = 7,
}
