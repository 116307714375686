export interface IGetPaymentHistoryResponse {
  currentPage: number;
  totalPages: number;
  totalRegisters: number;
  data: IPaymentHistoryData[];
}

export interface IPaymentHistoryData {
  id: string;
  value: number;
  installments: number;
  status: PaymentStatusEnum;
  createdAt: string;
}

export enum PaymentStatusEnum {
  Unknown = 0,
  Created = 1,
  Pending = 2,
  Authorized = 3, //Autorizada pendente de captura
  NotAuthorized = 4, //Não autorizada
  Captured = 5, //Capturada
  PartialCapture = 6, //Capturada parcialmente
  WaitingCapture = 7, //Aguardando captura
  Refunded = 8, //Estornada
  RefundRequested = 9, //Estorno solicitado
  PartialRefunded = 10, //Estornada parcialmente
  Cancelled = 11, //Cancelada
  PartialVoid = 12, //Cancelada parcialmente
  ErrorOnVoiding = 13, //Erro no cancelamento
  ErrorOnRefunding = 14, //Erro no estorno
  WaitingCancellation = 15, //Aguardando cancelamento
  WithError = 16, //Com erro
  Failed = 17, //Falha
}
