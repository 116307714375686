import { IPostIFoodMarketConfigRequest } from "modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigRequest";

export interface IFoodMarketFormErrors {
  username?: string;
  password?: string;
}

export const IFoodMarketValidation = (formData: IPostIFoodMarketConfigRequest, setErrors: React.Dispatch<React.SetStateAction<IFoodMarketFormErrors>>) => {
  let _errors: IFoodMarketFormErrors = {};
  let hasError = false;

  if (!formData.username) {
    _errors = { ..._errors, username: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.password) {
    _errors = { ..._errors, password: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
