import React, { FC, useState } from 'react'
// import styles from './ParentGroupInput.module.scss'
import { TextField, TextFieldProps } from '@material-ui/core';
import Sidesheet from 'components/sidesheet/Sidesheet';
import SelectSupplyParentGroup, { ISupplyGroupItem, ISupplyGroupList, ISupplyGroupListRequest } from './SelectSupplyParentGroup';
import { set } from 'date-fns';
export interface IParentGroupInputProps {
    //propertys
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    onSelect: (item: ISupplyGroupItem) => void
    value?: ISupplyGroupItem
    inputText?: string
    error?: boolean
    disabled?: boolean
    helperText?: string
}
const SupplyGroupInput: FC<IParentGroupInputProps> = ({
    value,
    getSupplyGroupList,
    onSelect,
    error,
    helperText,
    inputText,
    disabled
}) => {

    const [opensSupplyGroup, setOpensSupplyGroup] = useState<boolean>(false);

    return (
        <>
            <TextField
                onClick={() => setOpensSupplyGroup(true)}
                value={inputText ?? value?.name ?? ''}
                error={error}
                helperText={helperText}
                disabled={disabled}

                onChange={() => {
                    setOpensSupplyGroup(true)
                }}
            />
            <Sidesheet
                open={opensSupplyGroup}
                onClose={() => setOpensSupplyGroup(false)}
                title={<h2>Selecionar <b>grupo pai</b></h2>}
            >
                <SelectSupplyParentGroup
                    getSupplyGroupList={getSupplyGroupList}
                    value={value?.id}
                    onSelect={(item) => {
                        onSelect(item)
                        setOpensSupplyGroup(false)
                    }}
                />
            </Sidesheet>
        </>
    )
}
export default SupplyGroupInput