import { Autocomplete } from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { ICampaignWhatsapp } from '../../intefaces/ICampaign'
import { ICrmEvent } from '../../intefaces/ICrmEventData'
import { InputContainer } from '../_inputContainer/InputContainer'
import { InputCRM } from '../_inputCRMFilter/InputCRM'
import styles from './TempateCampaignEventWhatsapp.module.scss'
export interface ITempateCampaignEventWhatsappProps {
    events: ICrmEvent[],
    campaign: {
        name: string
    },
    values?: ICampaignWhatsapp
    validate?: (validate: boolean) => void,
    withButton?: boolean;
    onChange: (values: ICampaignWhatsapp) => void
    errors?: {
        event?: string,
        message?: string
    }
}
const TempateCampaignEventWhatsapp: FC<ITempateCampaignEventWhatsappProps> = ({ events, values, onChange, errors }) => {

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...values ?? { event: undefined, message: "" }, [ev.target.name]: ev.target.value });
    }, [onChange, values]);

    const onChangeEventHandle = useCallback((event?: ICrmEvent) => {
        onChange({ ...values ?? { event: undefined, message: "" }, event: event });
    }, [onChange, values]);


    return (
        <div id={styles.TempateCampaignEventWhatsapp} >
            <div className={styles.container} >
                <InputContainer>
                    <InputContainer>
                        <InputContainer>
                            <Autocomplete

                                options={[...events]}

                                defaultValue={values?.event}
                                onChange={(_, value) => onChangeEventHandle(value ?? undefined)}
                                value={values?.event}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <InputCRM
                                        error={!!errors?.event}
                                        helperText={errors?.event}
                                        label={"Evento"}
                                        {...params}
                                    />
                                )}
                            />
                        </InputContainer>
                    </InputContainer>
                    <InputContainer>
                        <div className={styles.previewContainer} >
                            <div className={styles.paper}>
                                <InputContainer>
                                    <InputCRM 
                                    error={!!errors?.message}
                                    helperText={errors?.message}
                                    label={"Mensagem de Whatsapp (limite de 300 caracteres)"} name='message' rows={4} multiline value={values?.message} onChange={changeHandle} />
                                </InputContainer>
                                <div className={styles.phoneFrame}>
                                    <div className={styles.screen}>
                                        <div className={styles.headerPhone}>
                                        </div>
                                        <div className={styles.messagesContainer}>
                                            <div className={styles.message}>
                                                <div className={styles.image}>
                                                    <img alt='preview' src={values?.event?.thumbnailUrl}></img>
                                                    <span>{values?.event?.name}</span>
                                                </div>
                                                <div className={styles.link}>{values?.event?.url}</div>
                                                <div className={styles.text}
                                                    dangerouslySetInnerHTML={{ __html: format_text(values?.message && values.message !== "" ? "Olá [nome do usuario] \n" + values?.message : `Olá [nome do usuario] \nEstamos te convidando para o nosso próximo evento que acontecerá no dia *25 de janeiro* as *17h30* \n(EXEMPLO DE MENSAGEM)`) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InputContainer>
                </InputContainer>
            </div>

        </div>
    )
}
export default TempateCampaignEventWhatsapp


const format_text = (text: string) => {
    return text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, '<b>$1</b>')
        .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, '<i>$1</i>')
        .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, '<s>$1</s>')
        .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, '<u>$1</u>')
        .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, '<tt>$1</tt>');

    // extra:
    // --For underlined text--
    // ```Monospace font```
}