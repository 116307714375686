import { Button, Icon, IconButton } from '@material-ui/core';
import styles from './ConfirmModal.module.scss';
import { valuesType } from '../UseFidelityEditSummary';
import { IFidelitySummary } from 'modules/fidelity/presentation/interfaces/IFidelitySummary';
import { useEffect, useMemo, useState } from 'react';
import { BalanceType } from 'modules/fidelity/presentation/interfaces/IFidelityBalance';
import { ISummaryPointsFormValue } from '../pointsForm/FidelityPointsForm';

interface ConfirmMoralProps {
    type: 'points' | 'coins';
    balanceType: BalanceType;
    customerName: string;
    loading: boolean;
    values: ISummaryPointsFormValue;
    data: IFidelitySummary;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmModal = ({ type, balanceType, customerName, loading, values, data, onClose, onConfirm }: ConfirmMoralProps) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [newPlan, setNewPlan] = useState('');

    useEffect(() => {
        if (type === 'points') {
            const points = balanceType === BalanceType.ADD_BALANCE ? (values.points || 0) * -1 : values.points;
            if (data.summaries.pointsSummary - (points || 0) <= 0) {
                setIsDeleting(true);
            }
        }
    }, [data.summaries.pointsSummary, balanceType, type, values.points])

    const totalPoints = useMemo(() => data.summaries.pointsSummary + (balanceType === BalanceType.ADD_BALANCE ? (values.points || 0) : (values.points || 0) * -1), [data.summaries.pointsSummary, balanceType, values.points]);

    useEffect(() => {
        data.ownerFidelityPlans.every((x, index) => {
            if (index === data.ownerFidelityPlans.length - 1) {
                setNewPlan(x.name);
                return false;
            }
            else if (totalPoints >= x.pointsRequired) {
                if (totalPoints < data.ownerFidelityPlans[index + 1].pointsRequired) {
                    setNewPlan(x.name);
                    return false;
                }
            }
            return true;
        });
    }, [data.ownerFidelityPlans, totalPoints]);

    return (
        <div className={styles.container}>
            <header>
                {
                    isDeleting ?
                        <><h1>Excluir {type === 'points' ? 'todos os pontos' : 'todas as moedas'}</h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton></> :
                        <><h1>Editar {type === 'points' ? 'pontos' : 'moedas'}</h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton></>
                }
            </header>
            <div className={styles.alertBox}>
                <img src="/assets/img/alert.png" alt="" />
                {
                    isDeleting ?
                        <span>Deseja realmente <strong>excluir {type === 'points' ? 'todos os pontos' : 'todas as moedas'} de {customerName}?</strong></span> :
                        <span>Deseja realmente
                            <strong>
                                <span className={balanceType === BalanceType.ADD_BALANCE ? styles.add : styles.sub}>
                                    {balanceType === BalanceType.ADD_BALANCE ? ' adicionar ' : ' remover '}
                                    {type === 'points' ? `${values.points} pontos ` : `${values.coins} moedas `}
                                </span>
                                {balanceType === BalanceType.ADD_BALANCE ? 'para' : 'de'} {customerName}?
                            </strong>
                        </span>
                }
            </div>

            <div className={styles.separator}>
                <div />
                <span>O que isso significa?</span>
                <div />
            </div>

            <ul className={styles.list}>
                {
                    isDeleting ?
                        <>
                            {
                                type === 'points' ?
                                    <li>Ao excluir, seu cliente perderá todos os pontos acumulados até então e não verá mais esse nível de fidelidade no App Meep;</li> :
                                    <li>Ao excluir, seu cliente perderá todas as moedas acumulados até então;</li>
                            }
                        </> :
                        <>
                            <li>Ao alterar os pontos para essa quantidade, seu cliente passará para o nível {newPlan} e terá no total {totalPoints} pontos.</li>
                        </>
                }
            </ul>

            <div className={styles.buttonContainer}>
                <div>
                    <Button className={styles.buttons} color='secondary' onClick={onClose} variant='outlined'>Fechar</Button>
                </div>
                <div>
                    <Button disabled={loading} onClick={onConfirm} className={styles.buttons} type='button' variant='contained' color='secondary'>{
                        loading ? 'Salvando...' :
                            'Confirmar'
                    }</Button>
                </div>
            </div>
        </div>
    )
}