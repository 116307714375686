export interface IDiscountListForm {
  id?: string;
  name: string;
  description: string;
  discounts: IDiscountList[]
}

export interface IDiscountList {
  priceDiscount?: {
    price: number
  },

  percentualDiscount?: {
    percentual: number
  },
  name?: string,
  price?: number,
  productId: string,
}

export enum DiscountListFormStep {
  BASE = 0,
  CONFIG = 1,
  SUCCESS = 2
}
