import { Button } from '@material-ui/core';
import { FC } from 'react';
import styles from './TicketForm.module.scss';
import UseTicketForm from './UseTicketForm';
import TabNavigation from 'components/ui/tabNavigation/TabNavigation';
import { TicketBaseForm } from './forms/baseForm/BaseForm';
import { ITicketForm, TicketFormStep } from './ITicketForm';
import { ConfigForm } from './forms/configForm/ConfigForm';

interface ITicketFormProps {
  ticket: ITicketForm;
  eventName: string;
  closeFormModal: () => void;
  handleFormTicket: (ticket: ITicketForm) => void;
}

export const TicketForm: FC<ITicketFormProps> = ({
  ticket,
  eventName,
  closeFormModal,
  handleFormTicket,
}) => {
  const {
    ticketForm,
    errors,
    deleting,
    formStep,
    handleFormStep,
    handleChangeTicketFormValue,
    handleChangeMaxQuantityPerUser,
    submitForm,
    handleCancelDeleteProduct,
    deleteProduct
  } = UseTicketForm(ticket, closeFormModal, handleFormTicket);

  return (
    <div id={styles.TicketForm}>
      <TabNavigation
        tabs={[
          {
            title: "Início",
            component: (
              <TicketBaseForm 
                errors={errors}
                ticket={ticketForm}
                eventName={eventName}
                onChangeTicketFormValue={handleChangeTicketFormValue}
              />
            ),
          },
          {
            title: "Configurações",
            component: (
              <ConfigForm 
                errors={errors}
                ticket={ticketForm}
                eventName={eventName}
                onChangeTicketFormValue={handleChangeTicketFormValue}
                changeMaxQuantityPerUser={handleChangeMaxQuantityPerUser}
              />
            ),
          },
        ]}
        selectedTab={formStep}
        handleTabChange={handleFormStep}
        bodyClassName={styles.tabContent}
      />
      <div className={styles.actions}>
        <Button
          fullWidth={false}
          onClick={deleting ? handleCancelDeleteProduct : closeFormModal}
          color="secondary"
          className={styles.cancel}
          variant="outlined">
          Cancelar
        </Button>
        <Button
          fullWidth={false}
          className={styles.confirm}
          onClick={deleting ? deleteProduct : formStep === TicketFormStep.CONFIG ? submitForm : () => handleFormStep(TicketFormStep.CONFIG)}
          color="secondary"
          variant="contained">
          {deleting ? 'Excluir' : formStep === TicketFormStep.CONFIG ? 'Concluir' : 'Continuar'}
        </Button>
      </div>
    </div>
  );
};
