import { useLocal } from "modules/local/presentation/context/LocalContext";
import { AddPaymentLinkUseCase } from "modules/paymentLink/application/useCases/AddPaymentLinkUseCase";
import { EditPaymentLinkUseCase } from "modules/paymentLink/application/useCases/EditPaymentLinkUseCase";
import GetPaymentLinkByIdUseCase from "modules/paymentLink/application/useCases/GetPaymentLinkByIdUseCase";
import {
  GetSimpleProductsListUseCase,
  IProductListFilter,
} from "modules/paymentLink/application/useCases/GetSimpleProductsListUseCase";
import { useCallback, useMemo, useState } from "react";
import PaymentLinkService from "services/api/paymentLink/PaymentLinkService";
import {
  IPaymentLinkInfoErrors,
  IPaymentLinkInfoForm,
} from "../../interfaces/IPaymentLinkForms";
import {
  IPaymentLinkValuesForm,
  IPaymentlinkValuesErrors,
  PaymentFormEnum,
} from "../../interfaces/IPaymentValuesFroms";
import { validateInformationForm } from "../forms/validation/InformationFormValidation";
import { validateValuesForm } from "../forms/validation/ValuesFormValidation";
import { TabViewSteps } from "./PaymentLinkSideSheet";
import { PaymentTypeEnum, PaymentTypeForm } from "modules/paymentLink/domain/dto/IPostPaymentLinkRequest";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IGetPaymentLinkListParams } from "../../interfaces/IGetPaymentLinkListParams";
import { validateProductForm } from "../forms/validation/ProductFormValidation";
import { IPaymentlinkProductErrors } from "../../interfaces/IPaymentProductForms";
import { IGetPaymentLinkByIdResponse, IPaymentTypeResponse } from "modules/paymentLink/domain/dto/IGetPaymentLinkByIdResponse";

export const UsePaymentLinkSideSheet = (
  getPaymentLinkList: (params?: IGetPaymentLinkListParams) => Promise<void>
) => {
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<TabViewSteps>(TabViewSteps.INFO);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentLinkEditId, setPaymentLinkEditId] = useState<string | null>(
    null
  );
  const [infoFormValues, setInfoFormValues] = useState<IPaymentLinkInfoForm>({
    name: "",
    description: "",
    successMessage: "",
    expiration: "",
    img: "",
  });
  const [infoFormError, setInfoFormError] = useState<IPaymentLinkInfoErrors>(
    {}
  );
  const [productFormError, setProductFormError] = useState<IPaymentlinkProductErrors>(
    {}
  );
  const [valuesFormsValues, setValuesFormsValues] =
    useState<IPaymentLinkValuesForm>({
      productId: "",
      price: "",
      credit: false,
      installments: 0,
      pix: false,
      tax: 0,
      ticket: false,
      isVariable: false,
      shouldChargeFee: false,
    });
  const [valuesFormsErrors, setValuesFormsErrors] =
    useState<IPaymentlinkValuesErrors>({});

  const [newLinkId, setNewLinkId] = useState<string>('');

  const handleChangeValuesForm = useCallback(
    (name: string, value: string | boolean | number) => {
      setValuesFormsValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleChangeInfoForm = useCallback((name: string, value: string) => {
    setInfoFormValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const getProductsList = useCallback(
    async (request: IProductListFilter) => {
      if (!currentLocal) throw new Error("Local não econtrado!");

      return GetSimpleProductsListUseCase(currentLocal.id, request);
    },
    [currentLocal]
  );

  const handleChangeTab = useCallback(
    (tab: TabViewSteps) => {
      if (step === TabViewSteps.INFO && tab === TabViewSteps.PRODUCT) {
        if (validateInformationForm(infoFormValues, setInfoFormError)) {
          setStep(tab);
        }
      }
      if (step === TabViewSteps.PRODUCT && tab === TabViewSteps.VALUES) {
        if (validateProductForm(valuesFormsValues, setProductFormError)) {
          setStep(tab);
        }
      }
      if (step === TabViewSteps.VALUES && tab === TabViewSteps.PRODUCT) {
        if (validateValuesForm(valuesFormsValues, setValuesFormsErrors)) {
          setStep(tab);
        }
      }
    },
    [infoFormValues, step, valuesFormsValues]
  );
  

  const handleContinue = useCallback(async () => {
    if (step === TabViewSteps.INFO) {
      handleChangeTab(TabViewSteps.PRODUCT);
    }
  
    if (step === TabViewSteps.PRODUCT) {
      handleChangeTab(TabViewSteps.VALUES);
    }
  
    if (step === TabViewSteps.VALUES) {
      if (validateValuesForm(valuesFormsValues, setValuesFormsErrors)) {
        try {
          setIsLoading(true);
          if (!currentLocal) throw new Error("Local não econtrado!");
          const service = PaymentLinkService();
          if (paymentLinkEditId) {
            await EditPaymentLinkUseCase(
              service,
              currentLocal.id,
              paymentLinkEditId,
              infoFormValues,
              valuesFormsValues
            );
            setStep(TabViewSteps.SUCCESS);
            getPaymentLinkList({
              pagination: {
                page: 0,
                pageSize: 20,
              },
              sort: {
                orientation: "asc",
                type: "name",
              },
            });
          } else {
            const newLink = await AddPaymentLinkUseCase(
              service,
              currentLocal.id,
              infoFormValues,
              valuesFormsValues
            );
            setNewLinkId(newLink.id);
            setStep(TabViewSteps.SUCCESS);
            getPaymentLinkList({
              pagination: {
                page: 0,
                pageSize: 20,
              },
              sort: {
                orientation: "asc",
                type: "name",
              },
            });
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [
    currentLocal,
    handleChangeTab,
    infoFormValues,
    paymentLinkEditId,
    step,
    valuesFormsValues,
    getPaymentLinkList,
  ]);
  

  const onClose = useCallback(() => {
    setValuesFormsValues({
      productId: "",
      price: "",
      credit: false,
      installments: 0,
      pix: false,
      tax: 0,
      ticket: false,
      shouldChargeFee: false,
    });
    setPaymentLinkEditId(null);
    setInfoFormValues({
      name: "",
      description: "",
      successMessage: "",
      expiration: "",
      img: "",
    });
    setInfoFormError({});
    setValuesFormsErrors({});
    setIsOpen(false);
    setStep(TabViewSteps.INFO);
  }, []);

  const handleReturn = useCallback(() => {
    if (step === TabViewSteps.INFO || step === TabViewSteps.SUCCESS) {
      return onClose();
    }
    if (step === TabViewSteps.PRODUCT) {
      setStep(TabViewSteps.INFO);
    }
    if (step === TabViewSteps.VALUES) {
      setStep(TabViewSteps.PRODUCT);
    }
  }, [onClose, step]);

  const backButton = useCallback(() => {
    if (step === TabViewSteps.INFO) {
      return "Cancelar";
    } else if (step === TabViewSteps.PRODUCT) {
      return "Voltar";
    } else if (step === TabViewSteps.VALUES) {
      return "Voltar";
    }
  }, [step]);

  const handleBackButton = backButton();


  const mapPaymentTypeToFormEnum = (type: PaymentTypeEnum): PaymentFormEnum | null => {
    switch (type) {
      case PaymentTypeEnum.CREDIT:
        return PaymentFormEnum.CREDIT;
      case PaymentTypeEnum.PIX:
        return PaymentFormEnum.PIX;
      case PaymentTypeEnum.TICKET:
        return PaymentFormEnum.TICKET;
      default:
        return null;
    }
  };
  

  const mapShowFields = useCallback((paymentTypes: IGetPaymentLinkByIdResponse) => {
    const showFields = {
      showDocumentPix: false,
      showDocumentBoleto: false,
      showNameOrCompanyNamePix: false,
      showNameOrCompanyNameBoleto: false,
      showPhoneNumberPix: false,
      showPhoneNumberBoleto: false,
      showEmailPix: false,
      showEmailBoleto: false,
      showAddressPix: false,
      showAddressBoleto: false,
    };
  
    paymentTypes.paymentTypes.forEach((paymentType) => {
      const mappedType = mapPaymentTypeToFormEnum(paymentType.type); 
      if (!mappedType) return;  

      paymentType.fields.forEach((field) => {
        if (!field) return;  
  
        switch (field.type) {
          case PaymentTypeForm.DOCUMENT:
            if (mappedType === PaymentFormEnum.PIX) {
              showFields.showDocumentPix = true;
            } 
            if (mappedType === PaymentFormEnum.TICKET) {
              showFields.showDocumentBoleto = true;
            }
            break;
          case PaymentTypeForm.NAME:
            if (mappedType === PaymentFormEnum.PIX) {
              showFields.showNameOrCompanyNamePix = true;
            } 
            if (mappedType === PaymentFormEnum.TICKET) {
              showFields.showNameOrCompanyNameBoleto = true;
            }
            break;
          case PaymentTypeForm.PHONE:
            if (mappedType === PaymentFormEnum.PIX) {
              showFields.showPhoneNumberPix = true;
            } 
            if (mappedType === PaymentFormEnum.TICKET) {
              showFields.showPhoneNumberBoleto = true;
            }
            break;
          case PaymentTypeForm.EMAIL:
            if (mappedType === PaymentFormEnum.PIX) {
              showFields.showEmailPix = true;
            } 
            if (mappedType === PaymentFormEnum.TICKET) {
              showFields.showEmailBoleto = true;
            }
            break;
          case PaymentTypeForm.ADDRESS:
            if (mappedType === PaymentFormEnum.PIX) {
              showFields.showAddressPix = true;
            } 
            if (mappedType === PaymentFormEnum.TICKET) {
              showFields.showAddressBoleto = true;
            }
            break;
        }
      });
    });
  
    return showFields;
  },[]);

  const openSideSheet = useCallback(async (id?: string) => {
    setIsOpen(true);
    if (id) {
      try {
        setIsLoading(true);
        setPaymentLinkEditId(id);
        const service = PaymentLinkService();
        const result = await GetPaymentLinkByIdUseCase(service, id);
        const showFields = mapShowFields(result);
        setInfoFormValues({
          name: result.name,
          expiration: result?.expirationDate?.split("T")[0] ?? "",
          description: result.description,
          successMessage: result.successMessage,
          img: result.image,
        });
        setValuesFormsValues({
          productId: result.productId,
          productName: result.product.nome,
          price: result.price.toString(),
          installments: result.maximumInstallments,
          tax: result.interestPerInstallment,
          isVariable: result.isVariable,
          shouldChargeFee: result.shouldChargeFee,
          credit: result.paymentTypes.some(
            (it) => it.type === PaymentTypeEnum.CREDIT,
          ),
          ticket: result.paymentTypes.some(
            (it) => it.type === PaymentTypeEnum.TICKET
          ),
          pix: result.paymentTypes.some(
            (it) => it.type === PaymentTypeEnum.PIX
          ),
          amountOfCharges: result.amountOfCharges,
          billingStartsInCurrentMonth: result.billingStartsInCurrentMonth,
          isRecurrenceLimit: result.isRecurrenceLimit,
          isSinglePayment: result.isSinglePayment,
          ...showFields
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [mapShowFields]);

  const link = useMemo(
    () => `${process.env.REACT_APP_MEPAY_URL}/paymentlink/${paymentLinkEditId ?? newLinkId}`,
    [newLinkId, paymentLinkEditId]
  );

  const openLinkNewTab = useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  const copyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(link);
    toast("Link copiado para área de transferência", "success");
  }, [link, toast]);

  return {
    openSideSheet,
    step,
    handleChangeTab,
    infoFormValues,
    handleChangeInfoForm,
    infoFormError,
    productFormError,
    valuesFormsErrors,
    handleChangeValuesForm,
    valuesFormsValues,
    isOpen,
    isLoading,
    paymentLinkEditId,
    handleContinue,
    handleBackButton,
    handleReturn,
    getProductsList,
    openLinkNewTab,
    copyLinkToClipboard,
    link,
  };
};
