import { GetCollaboratorScheduleDto } from "modules/schedule/domain/dto/collaboratorSchedule/GetCollaboratorScheduleDto";
import { ICollaboratorScheduleFormValue } from "modules/schedule/presentation/components/colaboratorScheduleForm/ColaboratorScheduleFormValue";
const GetCollaboratorUseCase = async (
    service: { getCollaborator: (collaboratorId: string) => Promise<GetCollaboratorScheduleDto> },
    collaboratorId: string
): Promise<ICollaboratorScheduleFormValue> => {

    const response = await service.getCollaborator(collaboratorId);
    return ({...response, servicesIds: response.servicesScheduleIds})
}
export default GetCollaboratorUseCase