import PostCashClosingUseCase from "modules/cashClosing/application/PostCashClosingUseCase";
import { IPostCashClosingParams } from "modules/cashClosing/domain/dto/IPostCashClosingParams";
import { IPostCashClosingResponse } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";
import GetCashiersUseCase from "modules/cashlessDashboard/application/useCases/GetCashiersUseCase";
import { ICashierItem } from "modules/cashlessDashboard/presentation/components/filter/ICashier";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CashClosingReportApi from "services/api/cashClosingReport/CashClosingReportApi";
import CashlessDashboardService from "services/api/cashlessDashboard/CashlessDashboardService";

const forDeviceApi = CashClosingReportApi();
const cashlessDashboardService = CashlessDashboardService();

export const UseCashClosingPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState({} as IPostCashClosingParams);
    const [reportData, setReportData] = useState<IPostCashClosingResponse>();
    const [cashiers, setCashiers] = useState<ICashierItem[]>([]);

    const { currentLocal } = useLocal();

    const postCashClosingReport = useCallback(
      async () => {
        if (currentLocal) {
            try {
              setIsLoading(true);
              const response = await PostCashClosingUseCase(forDeviceApi, {...params, localId: currentLocal.id});
              setReportData(response);
            } catch (error: any) {
              return error.response;
            } finally {
              setIsLoading(false);
            }
        }
      },
      [currentLocal, params]
    );

    const getCashiers = useCallback(
      async (startDate?: Date, endDate?: Date) => {
        if (currentLocal) {
            try {
              setIsLoading(true);
              const response = await GetCashiersUseCase(cashlessDashboardService, currentLocal.id, startDate, endDate);
              setCashiers(response);
            } catch (error: any) {
              return error.response;
            } finally {
              setIsLoading(false);
            }
        }
      },
      [currentLocal]
    );

    return {
        isLoading,
        reportData,
        cashiers,
        params,
        setParams,
        postCashClosingReport,
        getCashiers,
    }
}