import TableAnticorruption from "./TableAnticorruption"
import { api } from "../Api"
import { ITableService } from "modules/table/domain/interfaces/ITableService"
import { ISaveTableRequest, ITableItemResponse } from "modules/operators/domain/dtos/ITableItemResponse"

const TableApi = (): ITableService => {
    const getTableList = async (localId: string): Promise<ITableItemResponse[]> => {
        return await TableAnticorruption(api).getTableList(localId);
    }
    const addTable = async (localId: string, table: ISaveTableRequest): Promise<void> => {
        return await TableAnticorruption(api).addTable(localId, table)
    }
    const updateTable = async (localId: string, table: ISaveTableRequest): Promise<void> => {
        return await TableAnticorruption(api).editTable(localId, table)
    }
    const deleteTable = async (localId: string, id: string): Promise<void> => {
        return await TableAnticorruption(api).deleteTable(localId, id);
    }

    const changeStatusTable = async (localId: string, table: ISaveTableRequest, status: number): Promise<void> => {
        return await TableAnticorruption(api).changeStatusTable(localId, table, status);
    }
    return ({
        getTableList,
        addTable,
        updateTable,
        deleteTable,
        changeStatusTable
    })
}
export default TableApi