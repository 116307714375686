import { FC, useCallback, useEffect } from "react";
import styles from "./SaasPublicLayout.module.scss";
import { IconButton } from "@mui/material";
import BackgroundImage from "../assets/background-simple-register-saas.svg";
import { Instagram, YouTube } from "@material-ui/icons";
  
export interface ISaasPublicLayoutProps {
    step?: number;
    totalSteps?: number;
    backgroundColorTop: string;
    backgroundColorBottom: string;
    progressBarColor?: string;
    terms?: boolean;
}

const SaasPublicLayout: FC<ISaasPublicLayoutProps> = ({
    children,
    step,
    totalSteps,
    backgroundColorTop,
    backgroundColorBottom,
    progressBarColor,
    terms,
}) => {
    useEffect(() => {
        const keyframes = `
            @keyframes slideIn {
                0% {
                    width: ${(step! - 1) / totalSteps! * 100}%;
                }
                100% {
                    width: ${step! / totalSteps! * 100}%;
                }
            }
            
            @keyframes slideInSuccess {
                0% {
                    width: ${(step! - 1) / totalSteps! * 100}%;
                }
                100% {
                    width: 100%;
                    border-top-right-radius: 140px 90px;
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [step, totalSteps]);

    const progressBar = useCallback(() => {
        if (step === totalSteps) {
            return (
                <div className={styles.successBar}>
                    <div style={{ backgroundColor: progressBarColor, animation: "slideInSuccess 2s linear forwards" }} />
                </div>
            );
        }
        if (step && totalSteps) {
            return (
                <div className={styles.progressBar}>
                    <div style={{ backgroundColor: progressBarColor, animation: "slideIn 2s linear forwards" }}/>
                </div>
            );
        }
    }, [progressBarColor, step, totalSteps]);
    
    return (
        <div
            id={styles.SaasPublicLayout}
            style={{
                background: `linear-gradient(179.05deg, ${backgroundColorTop} 0.82%, ${backgroundColorBottom} 106.68%), url(${BackgroundImage})`
            }}
        >
            <img src="/assets/img/logo-mee-white.svg" alt="Logo"/>

            <div className={styles.container}>
                <div className={styles.content}>
                    {step && totalSteps && progressBarColor
                        ? progressBar()
                        : <div />
                    }
                    {children}
                </div>
                {terms 
                    ? <p>Ao continuar, declaro que concordo com os{" "}
                        <a href="https://975d0d01-7cfd-477b-86b2-035f8412c27c.usrfiles.com/ugd/975d0d_aa0583717f694182961ba85f3412d0bb.pdf" target="_blank" rel="noreferrer">
                            Termos de Uso e Adesão da Mee
                        </a>
                    </p> : <p/>
                }
            </div>

            <div className={styles.footer}>
                <div className={styles.socialMedia}>
                    <IconButton onClick={() => window.open("https://www.instagram.com/meebymeep?igsh=MWd4eDI3dmU5bzRueg==", "_blank")}><Instagram /></IconButton>
                    <IconButton onClick={() => window.open("https://www.youtube.com/@Meebymeep/videos", "_blank")}><YouTube /></IconButton>
                </div>
                <p>Feito com 💜 para o seu negócio!</p>
                <span>Rua da Bahia, 888 - Centro | Belo Horizonte/MG</span>
            </div>
        </div>
    );
};

export default SaasPublicLayout;
  