import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './LocalsList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';

import { ILocalsFilterValue } from './localsFilter/ILocalsFilter';
import LocalsFilter from './localsFilter/LocalsFilter';

import DefaultActionButtonBuilder from '../../ui/defaultListActionButtons/DefaultListActionButtons';
import { IGetStorageLocalsItemResponse, IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';

export interface ILocalsListProps {
    //propertys
    isLoading: boolean;
    filter: ILocalsFilterValue
    localsStock?: IGetStorageLocalsListResponse
    onChangeFilter: (filter: ILocalsFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => Promise<void>
    onClickEnable: (id: string, isActive: boolean, value: IGetStorageLocalsItemResponse) => Promise<IGetStorageLocalsItemResponse>
}
const LocalsList: FC<ILocalsListProps> = ({
    isLoading,
    filter,
    localsStock,
    onChangeFilter,
    onDelete,
    onEdit,
    onClickEnable
}) => {
    const {hasAccessRole} = useAuth()

    const submitFilter = useCallback((values: ILocalsFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])

    const columnsConfig: ColumnConfigs<IGetStorageLocalsItemResponse> = {
        code: { title: "Código", sortable: true },
        name: { title: "Nome", sortable: true },

    }

    const actionButtons = DefaultActionButtonBuilder<IGetStorageLocalsItemResponse>({ onDelete, onEdit, onClickEnable })
    
    const pagination = useMemo(() => {
        return {
            page: localsStock?.page ?? 1,
            pageSize: 10,
            count: localsStock?.totalPages ?? 0
        }
    }, [localsStock?.total, localsStock?.page])
    
    return (
        <div id={styles.LocalsList} >
            <LocalsFilter
                onSubmit={submitFilter}
                defaultValue={filter}
            />
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={localsStock?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default LocalsList