import { IVerticalBarsChart } from "components/graphs/verticalBarsChart/IVerticalBarsChart";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import { IVerticalBarResponse } from "modules/dashboard/domain/dto/verticalBar/IVerticalBar.type";
import { ILocal } from "modules/dashboard/presentation/components/toggleLocal/ToggleLocal.types";
import IDashboardService from "../interfaces/IDashboardService";

const GetSalePerDayUseCase = async (dashboardService: IDashboardService, filter: IFilterRequest): Promise<IVerticalBarResponse[]> => {
  return await dashboardService.getSalePerDay(filter);
}

export default GetSalePerDayUseCase;