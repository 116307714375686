import { ISelect } from "modules/schedule/domain/models/IDropDown";
import { IPlaceFormErros } from "../interface/IPlaceFormErros";
import { IPlaceScheduleForm } from "../interface/IPlaceScheduleForm";

const MAX_CHARACTERS_PER_WORD = 25;

export const validate = (formData: IPlaceScheduleForm, setErrors: React.Dispatch<React.SetStateAction<IPlaceFormErros>>, selectedServices: ISelect[], minimumLeadTime: number) => {
  let _errors: IPlaceFormErros = {};
  let hasError = false;
  if (!formData.prefix.trim()) {
    _errors = { ..._errors, prefix: "Campo obrigatório" };
    hasError = true;
  }else{
    var nameSplit = formData.prefix.split(" ");
    for(const element of nameSplit){
      if(element.length > MAX_CHARACTERS_PER_WORD){
        _errors = { ..._errors, prefix: "Campo não deve conter palavras com mais de "+MAX_CHARACTERS_PER_WORD+" caracteres" };
        hasError = true;
      }
    }
  }
  if (!formData.maxQuantity) {
    _errors = { ..._errors, maxQuantity: "Campo obrigatório" };
    hasError = true;
  }else if (formData.maxQuantity <= 0) {
    _errors = { ..._errors, maxQuantity: "A quantidade deve ser maior que zero" };
    hasError = true;
  }
  // if (!minimumLeadTime) {
  //   _errors = { ..._errors, minimumLeadTime: "Campo obrigatório" };
  //   hasError = true;
  // }
  if (!formData.maximumLeadTimeInDays) {
    _errors = { ..._errors, maximumLeadTimeInDays: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.availableDays.length < 1) {
    _errors = { ..._errors, availableDays: "Campo obrigatório" };
    hasError = true;
  }
  if (selectedServices.length < 1) {
    _errors = { ..._errors, selectedServices: "Campo obrigatório" };
    hasError = true;
  }
  // if (showCases.length < 1) {
  //   _errors = { ..._errors, showCases: "Campo obrigatório" };
  //   hasError = true;
  // }
  setErrors(_errors);
  return !hasError;
};
