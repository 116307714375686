import { ISpecialDaysRequest } from "modules/schedule/domain/dto/placeSchedule/IAddUnavailabilityRequest";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const PostAddUnavailabilityByServiceIdUseCase = async (request: ISpecialDaysRequest, placeId: string, placeScheduleApi: PlaceScheduleApiFunction) => {
    const _placeScheduleApi = placeScheduleApi();
    await _placeScheduleApi.addUnavailability(request, placeId);
    return request;
}


