
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { DeleteChildUseCase } from "modules/permutation/application/useCases/DeleteChildUseCase";

const UseDeleteChild = () => {

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successDeleteChild, setSuccessDeleteChild] = useState(false)


  const deleteChild = useCallback(
    async (accountId: string, childrenId: string) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingDelete(true);
        if (currentLocal) {
          await DeleteChildUseCase(
            servicePermutation,
            currentLocal.id,
            accountId,
            childrenId
          );
          toast("Deletado com sucesso", "success")
          setSuccessDeleteChild(true)
          return "ok"
        }
      } catch {
        setSuccessDeleteChild(false)
      }

      finally {
        setIsLoadingDelete(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessDeleteChild,
    successDeleteChild,
    isLoadingDelete,
    deleteChild,

  };
};

export default UseDeleteChild;
