import { ICardsActivation } from "modules/cashlessDashboard/presentation/components/cardsActivation/interfaces/ICardsActivation";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetCardsActivationUseCase = async (
  service: ICashlessDashboardService,
  params: IUseCaseParams
): Promise<ICardsActivation> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getCardsActivation(filterRequest);

  const result: ICardsActivation = {
    activated: {
      ...response.activated,
      malePercentage: response.activated.total
        ? Number(
            (
              (response.activated.male / response.activated.total) *
              100
            ).toFixed(2)
          )
        : 0,
      femalePercentage: response.activated.total
        ? Number(
            (
              (response.activated.female / response.activated.total) *
              100
            ).toFixed(2)
          )
        : 0,
      unknownPercentage: response.activated.total
        ? Number(
            (
              (response.activated.unknown / response.activated.total) *
              100
            ).toFixed(2)
          )
        : 0,
    },
    deactivated: {
      ...response.deactivated,
      malePercentage: response.deactivated.total
        ? Number(
            (
              (response.deactivated.male / response.deactivated.total) *
              100
            ).toFixed(2)
          )
        : 0,
      femalePercentage: response.deactivated.total
        ? Number(
            (
              (response.deactivated.female / response.deactivated.total) *
              100
            ).toFixed(2)
          )
        : 0,
      unknownPercentage: response.deactivated.total
        ? Number(
            (
              (response.deactivated.unknown / response.deactivated.total) *
              100
            ).toFixed(2)
          )
        : 0,
    },
    remainsOpen: response.remainsOpen,
    deactivatedOherPeriod: response.deactivatedOherPeriod,
    deactivatedInThePeriod: response.deactivatedInThePeriod,
  };

  return result;
};

export default GetCardsActivationUseCase;
