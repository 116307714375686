import { useEffect, useMemo, useState } from 'react';
import { Grid, Radio, TextField } from '@material-ui/core';

import styles from './CoilCalculator.module.scss';
import Button from 'components/ui/Button/Button';
import RenderIf from 'components/renderIf/RenderIf';
import { onlyNumber } from 'modules/contaDigital/presentation/pages/utils';

interface CoilCalculatorForm {
    orders: string;
    printSize: string;
    customPrintSize: string;
    coilSize: string;
    customCoilSize: string;
}

enum PRINT_SIZE {
    '2CM' = '2',
    '3CM' = '3',
    '5CM' = '5',
    'OTHER' = 'OTHER',
}

enum COIL_SIZE {
    '15M' = '1500',
    '16M' = '1600',
    '18M' = '1800',
    'OTHER' = 'OTHER',
}

const CoilCalculator = () => {
    const [formValues, setFormValues] = useState<CoilCalculatorForm>({ orders: '', printSize: PRINT_SIZE['2CM'], coilSize: COIL_SIZE['15M'], customPrintSize: '', customCoilSize: '' });
    const [result, setResult] = useState(0);
    const [formInvalid, setFormInvalid] = useState(true);

    useEffect(() => {
        isFormInvalid();
    }, [formValues]);

    const changeOrders = (value: string) => {
        setResult(0);
        setFormValues((prev) => ({ ...prev, orders: value }));
    };

    const changePrintSize = (size: string) => {
        setResult(0);
        setFormValues(prev => ({ ...prev, printSize: size, customPrintSize: '' }));
    }

    const changeCoilSize = (size: string) => {
        setResult(0);
        setFormValues(prev => ({ ...prev, coilSize: size, customCoilSize: '' }));
    }

    const isFormInvalid = () => {
        if (!formValues.orders.length || parseInt(formValues.orders) === 0) {
            setFormInvalid(true);
            return;
        }

        if (formValues.printSize === PRINT_SIZE.OTHER) {
            if (!formValues.customPrintSize || parseInt(formValues.customPrintSize) <= 0) {
                setFormInvalid(true);
                return;
            }
        }

        if (formValues.coilSize === COIL_SIZE.OTHER) {
            if (!formValues.customCoilSize || parseInt(formValues.customCoilSize) <= 0) {
                setFormInvalid(true);
                return;
            }
        }

        setFormInvalid(false);
    }

    const calculateProductValue = () => {
        const average = parseInt(formValues.orders);
        const print = formValues.printSize !== PRINT_SIZE.OTHER ? parseInt(formValues.printSize) : parseInt(formValues.customPrintSize);
        const coil = formValues.coilSize !== COIL_SIZE.OTHER ? parseInt(formValues.coilSize) : parseInt(formValues.customCoilSize);
        const result = Math.ceil((average * print) / coil);

        setResult(result);
    };

    const resultHandler = useMemo(() => {
        return `${result} ${result === 1 ? 'bobina' : 'bobinas'}`;
    }, [result]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
                <div className={styles.toolCalculatorBox}>
                    <div className={styles.toolCalculatorIconBox}>
                        <div className={styles.toolCalculatorIcon}>🧻</div>
                        <div className={styles.toolCalculatorIcon}>🖨️</div>
                        <div className={styles.toolCalculatorIcon}>📎</div>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <label className={styles.coilCalculatorLabel} data-required>Quantidade média de pedidos impressos/dia</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                required
                                value={formValues?.orders}
                                onChange={(ev) => changeOrders(onlyNumber(ev.target.value))}
                            />
                        </Grid>

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <label className={styles.coilCalculatorLabel} data-required>Selecione o tamanho da sua impressão</label>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div
                                    className={styles.coilCalculatorPaperIcon}
                                    data-size="2cm"
                                    style={{ backgroundImage: 'url(/assets/icon/paper-coil-receipt.png)' }}
                                >
                                    <Radio
                                        color="primary"
                                        checked={formValues.printSize === PRINT_SIZE['2CM']}
                                        onChange={() => changePrintSize(PRINT_SIZE['2CM'])}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div
                                    className={styles.coilCalculatorPaperIcon}
                                    data-size="3cm"
                                    style={{ backgroundImage: 'url(/assets/icon/paper-coil-receipt.png)' }}
                                >
                                    <Radio
                                        color="primary"
                                        checked={formValues.printSize === PRINT_SIZE['3CM']}
                                        onChange={() => changePrintSize(PRINT_SIZE['3CM'])}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div
                                    className={styles.coilCalculatorPaperIcon}
                                    data-size="5cm"
                                    style={{ backgroundImage: 'url(/assets/icon/paper-coil-receipt.png)' }}
                                >
                                    <Radio
                                        color="primary"
                                        checked={formValues.printSize === PRINT_SIZE['5CM']}
                                        onChange={() => changePrintSize(PRINT_SIZE['5CM'])}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <Radio
                                        color="primary"
                                        checked={formValues.printSize === PRINT_SIZE['OTHER']}
                                        onChange={() => changePrintSize(PRINT_SIZE['OTHER'])}
                                        />
                                    <span className={styles.coilCalculatorCoilLabel}>Outro tamanho</span>
                                </div>

                                <RenderIf condition={formValues.printSize === PRINT_SIZE['OTHER']}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        placeholder="Centímetros"
                                        value={formValues?.customPrintSize}
                                        onChange={(ev) => setFormValues(prev => ({ ...prev, customPrintSize: onlyNumber(ev.target.value) }))}
                                    />
                                </RenderIf>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <label className={styles.coilCalculatorLabel} data-required>Selecione o tamanho da sua bobina</label>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div>
                                    <Radio
                                        color="primary"
                                        checked={formValues.coilSize === COIL_SIZE['15M']}
                                        onChange={() => changeCoilSize(COIL_SIZE['15M'])}
                                        />
                                    <span className={styles.coilCalculatorCoilLabel}>15 metros</span>
                                </div>

                                <div className={styles.coilCalculatorIcon} style={{ backgroundImage: 'url(/assets/icon/paper-coil.png)' }}></div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div>
                                    <Radio
                                        color="primary"
                                        checked={formValues.coilSize === COIL_SIZE['16M']}
                                        onChange={() => changeCoilSize(COIL_SIZE['16M'])}
                                        />
                                    <span className={styles.coilCalculatorCoilLabel}>16 metros</span>
                                </div>

                                <div className={styles.coilCalculatorIcon} style={{ backgroundImage: 'url(/assets/icon/paper-coil.png)' }}></div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div>
                                    <Radio
                                        color="primary"
                                        checked={formValues.coilSize === COIL_SIZE['18M']}
                                        onChange={() => changeCoilSize(COIL_SIZE['18M'])}
                                        />
                                    <span className={styles.coilCalculatorCoilLabel}>18 metros</span>
                                </div>

                                <div className={styles.coilCalculatorIcon} style={{ backgroundImage: 'url(/assets/icon/paper-coil.png)' }}></div>
                            </Grid>

                            <Grid item xs={6}>
                                <div>
                                    <Radio
                                        color="primary"
                                        checked={formValues.coilSize === COIL_SIZE['OTHER']}
                                        onChange={() => changeCoilSize(COIL_SIZE['OTHER'])}
                                        />
                                    <span className={styles.coilCalculatorCoilLabel}>Outro tamanho</span>
                                </div>

                                <RenderIf condition={formValues.coilSize === COIL_SIZE['OTHER']}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        placeholder="Centímetros"
                                        value={formValues?.customCoilSize}
                                        onChange={(ev) => setFormValues(prev => ({ ...prev, customCoilSize: onlyNumber(ev.target.value) }))}
                                    />
                                </RenderIf>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                disabled={formInvalid}
                                style={{ marginTop: '1.5rem' }}
                                onClick={() => calculateProductValue()}>Calcular</Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={4}>
                <div className={styles.toolCalculatorResultBox}>
                    <p className={styles.toolCalculatorResultText}>Quantidade de bobinas necessárias por dia</p>
                    <p className={styles.toolCalculatorResultValue}>{resultHandler}</p>
                </div>
            </Grid>
        </Grid>
    )
}

export default CoilCalculator;