import { KeyboardArrowDownRounded, KeyboardArrowRightRounded } from "@material-ui/icons";
import styles from "./TransactionItemAccoridon.module.scss";
import { ITransactionsRecords } from "modules/transactionReport/domain/dto/IGetTransactionsResponse";
import { format } from "date-fns";
import { useState } from "react";
import { Divider } from "@mui/material";

interface ITransactionItemAccoridonProps {
  item: ITransactionsRecords;
  setOpenDetailsSidesheet: React.Dispatch<React.SetStateAction<string>>;
  hasDetailAccess: boolean;
}

const moreInfoHeader = ["Serial", "Cartão", "Bandeira", "% da taxa", "Taxa", "Antecipado"];

const TransactionItemAccoridon = ({
  item,
  setOpenDetailsSidesheet,
  hasDetailAccess,
}: ITransactionItemAccoridonProps) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <div id={styles.TransactionItemAccoridon}>
      <div className={styles.firstInfo}>
        <span>{item.TransactionDate ? format(new Date(item.TransactionDate + "Z"), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
        <span>{item.Equipment ?? "-"}</span>
        <span>{item.PaymentTypeName}</span>
        <span>{item.ReceiptDate ? format(new Date(item.ReceiptDate + "Z"), "dd/MM/yyyy") : "-"}</span>
        <span>{item.GrossValue ? `R$ ${item.GrossValue.toString().replace(".", ",")}` : "-"}</span>
        <span>{item.NetValue ? `R$ ${item.NetValue.toString().replace(".", ",")}` : "-"}</span>
        <div 
          className={styles.status} 
          onClick={() => hasDetailAccess ? setOpenDetailsSidesheet(item.Id) : null}
          style={hasDetailAccess ? {} : { cursor: "default", color: "#5F5F5F" }}
          >
          <span>{item.StatusDescription}</span>
          <KeyboardArrowRightRounded />
        </div>
        <div 
          className={styles.icon}
          onClick={() => setShowMoreInfo(!showMoreInfo)}
          style={{
            transform: showMoreInfo ? "rotate(-180deg)" : "rotate(0deg)",
            justifyContent: "flex-end",
          }}
        >
          <KeyboardArrowDownRounded />
        </div>
      </div>
      {showMoreInfo && (
        <>
          <Divider />
          <div className={styles.moreInfo}>
            {moreInfoHeader.map((item) => {
              return (
                <div 
                  key={item}
                  className={styles.header}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className={styles.moreInfo}>
            <span>{item.Serial ?? "-"}</span>
            <span>{item.FinalCard ?? "-"}</span>
            <span>{item.PaymentCardFlagDescription ?? "-"}</span>
            <span>{item.PercentFee ? `${item.PercentFee}%` : "-"}</span>
            <span>{item.FeeValue ? `R$ ${item.FeeValue.toString().replace(".", ",")}` : "-"}</span>
            <span>{item.Anticipation === "0" ? "Não" : "Sim"}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionItemAccoridon;
