import { Dialog } from '@material-ui/core';
import { Close, Delete, Edit } from '@material-ui/icons';
import Button from 'components/ui/Button/Button';
import { Modal } from 'components/ui/modal/Modal';
import Switch from 'components/ui/switch/Switch';
import React from 'react';
import { ISchedulePlaceCard } from './interfaces/ISchedulePlaceCard';
import styles from './SchedulePlaceCard.module.scss';
import { UseSchedulePlaceCard } from './UseSchedulePlaceCard';

interface ISchedulePlaceCardProps {
    value: ISchedulePlaceCard;
    getPlaceList: () => void;
}

const dayWeekToString = (dayWeek: number) => {
    switch (dayWeek) {
        case 0:
            return "Domingo"
        case 1:
            return "Segunda"
        case 2:
            return "Terça"
        case 3:
            return "Quarta"
        case 4:
            return "Quinta"
        case 5:
            return "Sexta"
        case 6:
            return "Sábado"
        default:
            break;
    }
}



export const SchedulePlaceCard: React.FC<ISchedulePlaceCardProps> = ({ value, getPlaceList }) => {

    const {
        handleModalAvailableDays,
        toggleModalAvailableDays,
        handleModalServices,
        toggleModalServices,
        onClickServiceHandle,
        handleChangeEnabled,
        deletePlace,
        toggleModalRemovePlace,
        handleModalRemovePlace,
        removeUuid
    } = UseSchedulePlaceCard(value, getPlaceList);

    return (
        <div id={styles.SchedulePlaceCard}>

            <div className={styles.schedulePlaceCardContainer}>
                <div className={styles.details}>
                    <div className={styles.detailsHeader}>
                        <div className={styles.titleName}>
                            <h3>{removeUuid(value.name)}</h3>
                        </div>
                        <div>
                            <Edit onClick={() => onClickServiceHandle(value.id)} />
                            <Delete onClick={() => handleModalRemovePlace()} />
                        </div>
                    </div>
                    <div>
                        <p>Maximo de pessoas: <span>{value.maxQuantity}</span> </p>
                    </div>
                    <div className={styles.servicesSchedule}>
                        <p>Serviços: </p>
                        <Button onClick={handleModalServices} variant="text"><u>Visualizar serviços</u></Button>
                    </div>
                    <div className={styles.servicesSchedule}>
                        <p>Horários: </p>
                        <Button onClick={handleModalAvailableDays} variant="text"><u>Visualizar horários</u></Button>
                    </div>
                    <div className={styles.toggleTour}>
                        {
                            !value.isEnabled ?
                                <div>
                                    <u>Habilitar passeio</u>
                                    <Switch checked={value.isEnabled} onChange={handleChangeEnabled} />
                                </div>
                                :
                                <div>
                                    <u>Desabilitar passeio</u>
                                    <Switch checked={value.isEnabled} onChange={handleChangeEnabled} />
                                </div>
                        }
                    </div>
                </div>
            </div>

            <Dialog fullWidth open={toggleModalAvailableDays} onClose={handleModalAvailableDays}>
                <div className={styles.containerModal}>

                    <div className={styles.containerTitle}>
                        <h1>Todos os horários</h1>
                        <Close onClick={handleModalAvailableDays} />
                    </div>
                    {
                        value.availableDays?.map((item, index) =>
                        (
                            <div className={styles.modalAvailableDays} key={index}>
                                <p>Dia da semana: <span>{dayWeekToString(item.dayOfWeek)}</span> </p>
                                <p>Inicio: <span>{item.startHour}</span> </p>
                                <p>Fim: <span>{item.endHour}</span> </p>
                            </div>
                        )
                        )
                    }
                </div>
            </Dialog>

            <Dialog fullWidth open={toggleModalServices} onClose={handleModalServices}>
                <div className={styles.containerModal}>
                    <div className={styles.containerTitle}>
                        <h1>Todos os serviços</h1>
                        <Close onClick={handleModalServices} />
                    </div>
                    {
                        value.services?.map((item, index) =>
                        (
                            <div className={styles.modalAvailableDays} key={index}>
                                <p>Serviço: <span>{item.name}</span> </p>

                            </div>
                        )
                        )
                    }
                </div>
            </Dialog>

            <Modal toggleModal={handleModalRemovePlace} onConfirm={() => deletePlace(value.id)} open={toggleModalRemovePlace} title="Deseja remover este passeio?">
                <p>Tem certeza que deseja excluír este passeio?</p>
                <p>Está ação não poderar ser desfeita!</p>
            </Modal>
        </div>
    )
}
