import Layout from "components/layout/presentation/Layout";
import LocalPage from "modules/local/presentation/_LocalPage";
import React, { FC } from "react";

export interface IindexProps {
  //propertys
}
const Local: FC<IindexProps> = () => {
  return (
    <Layout disabledMenu disablePageRequests>
      <LocalPage />
    </Layout>
  );
};
export default Local;
