import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Switch from "components/ui/switch/Switch";
import React, { FC, useEffect, useState } from "react";
import { IDetailsFormErrors } from "../../validation/IGuestListFormErros";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styles from "./DetailsForm.module.scss";
import Button from "components/ui/Button/Button";
import { Checkbox } from "@material-ui/core";

interface IDetailsFormProps {
  type: "EDIT" | "ADD";
  isActive: boolean;
  name?: string;
  copyGuests: boolean;
  copyConfiguration: boolean;
  description?: string;
  day?: string;
  errors: IDetailsFormErrors;
  handleChangeIsActive: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  handleChangeName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleChangeDescription: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleChangeDay: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleChangeCopyConfiguration: (checked: boolean) => void;
  handleChangeCopyGuests: (checked: boolean) => void;
}

export const DetailsForm: FC<IDetailsFormProps> = ({
  day,
  description,
  isActive,
  copyConfiguration,
  copyGuests,
  name,
  type,
  errors,
  handleChangeDay,
  handleChangeDescription,
  handleChangeName,
  handleChangeIsActive,
  handleChangeCopyConfiguration,
  handleChangeCopyGuests
}) => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    setIsFixed(prev => !prev ? copyConfiguration || copyGuests : prev)
  }, [copyConfiguration, copyGuests]);

  useEffect(() => {
    if (!isFixed) {
      handleChangeCopyConfiguration(false);
      handleChangeCopyGuests(false);
    }
  }, [handleChangeCopyConfiguration, handleChangeCopyGuests, isFixed]);

  return (
    <form onSubmit={() => {}} id={styles.DetailsForm}>
      <FormItemContainer label="Nome da lista">
        <Input
          variant="outlined"
          value={name}
          fullWidth={false}
          placeholder="Lista"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeName}
          error={!!errors.name}
          helperText={errors.name}
        />
      </FormItemContainer>
      <FormItemContainer label="Descrição">
        <Input
          variant="outlined"
          value={description}
          fullWidth={false}
          placeholder="Escreva a descrição da lista"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeDescription}
          error={!!errors.description}
          helperText={errors.description}
        />
      </FormItemContainer>
      <FormItemContainer label="Data de fechamento">
        <Input
          variant="outlined"
          value={day}
          fullWidth={false}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeDay}
          error={!!errors.closingDateTime}
          helperText={errors.closingDateTime}
        />
      </FormItemContainer>
      <FormItemContainer className={styles.switchContainer}>
        <div className={styles.row}>
          <Switch checked={isActive} onChange={handleChangeIsActive} />
          <p>Lista ativa</p>
        </div>
        <div className={styles.row}>
          <Switch checked={isFixed} onChange={(_, checked) => setIsFixed(checked)} />
          <p>Lista fixa para todos os eventos?</p>
        </div>
        {isFixed && (
          <FormItemContainer label="Copiar" className={styles.checkboxContainer}>
            <span>
              <Checkbox checked={copyConfiguration} onChange={(_, checked) => handleChangeCopyConfiguration(checked)} /> Configurações
            </span>
            <span>
              <Checkbox checked={copyGuests} onChange={(_, checked) => handleChangeCopyGuests(checked)} /> Convidados
            </span>
          </FormItemContainer>
        )}
        
      </FormItemContainer>
      {/* {type === "EDIT" && (
        <FormItemContainer row className={styles.buttons}>
          <div>
            <Switch checked={isActive} onChange={handleChangeIsActive} />
            <p>Lista ativa</p>
          </div>
          <div> */}
            {/* <Button startIcon={<DeleteOutlineOutlinedIcon />} variant="text" color="default" onClick={() => {}}>
              <h4>Excluir</h4>
            </Button> */}
          {/* </div>
        </FormItemContainer>
      )} */}
    </form>
  );
};
