import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import styles from "./ExportSideSheet.module.scss";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ExportSalesFormatEnum } from "services/api/outputreport/types/GetExportSalesReport.types";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  exportType?: ExportSalesFormatEnum;
  onConfirm: () => void;
  changeSelectExportType: (exportType: ExportSalesFormatEnum) => void;
  isLoadingExport: boolean;
}

export const ExportSideSheet = ({
  open,
  title,
  onClose,
  onConfirm,
  changeSelectExportType,
  exportType,
  isLoadingExport,
}: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <span>Selecione uma opção e clique em exportar.</span>
        <div className={styles.containerRadio}>
          <RadioGroup
            row
            onChange={(_, value: any) => changeSelectExportType(value)}
            aria-labelledby="demo-radio-buttons-group-label"
          >
            <div className={styles.radio}>
              <FormControlLabel
                value={ExportSalesFormatEnum.PDF}
                control={<Radio />}
                label="PDF"
              />
            </div>
            <div className={styles.radio}>
              <FormControlLabel
                value={ExportSalesFormatEnum.Excel}
                control={<Radio />}
                label="Excel"
              />
            </div>
          </RadioGroup>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            variant="outlined"
            fullWidth={false}
            className={styles.button}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            fullWidth={false}
            className={
              !(
                exportType !== ExportSalesFormatEnum.PDF &&
                exportType !== ExportSalesFormatEnum.Excel
              )
                ? ""
                : styles.button
            }
            disabled={
              !(
                exportType !== ExportSalesFormatEnum.PDF &&
                exportType !== ExportSalesFormatEnum.Excel
              )
            }
            onClick={() => onConfirm()}
          >
            Export
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
