import { ImportExport } from "@mui/icons-material";
import styles from "./Header.module.scss";

interface IHeaderProps {
  titleHeader: string[];
  gridTemplateColumns: string;
  orderFilter?: boolean;
}

const Header = ({
  titleHeader,
  gridTemplateColumns,
  orderFilter,
}: IHeaderProps) => {
  return (
    <div
      id={styles.AccordionHeader}
      style={{ gridTemplateColumns: gridTemplateColumns }}
    >
      {titleHeader.map((item) => {
        return (
          <div className={styles.container}>
            {item}
            {orderFilter && <ImportExport />}
          </div>
        );
      })}
      <div className={styles.last} />
    </div>
  );
};
export default Header;
