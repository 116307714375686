import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import styles from "./SaasHomePage.module.scss"
import TerminalCard from "./components/terminalCard/TerminalCard";
import { useHistory } from "react-router-dom";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import OperatorAndTableFragment from "modules/Terminal/presentation/component/operatorAndTableFragment/OperatorAndTableFragment";
import CieloModal from "./components/cieloModal/CieloModal";
import UseHomeTutorials from "./hooks/UseHomeTutorials";
import UseTerminal from "modules/Terminal/presentation/hooks/UseTerminal";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const SaasHomePage = () => {
    const { user } = useUser();
    const history = useHistory();
    const { showCieloTutorial, onFinishCieloStep, openModalCielo } = UseHomeTutorials();
    const { getTerminalDevices } = UseTerminal();
    const [terminalDevices, setTerminalDevices] = useState<IDeviceTerminalItemResponse[]>();
    const [posDevices, setPosDevices] = useState<IDeviceTerminalItemResponse[]>();

    const { showLoading, hideLoading } = useUi();

    useEffect(() => {
        const baitFirstLogin = localStorage.getItem('@baitFirstLogin');
        setTimeout(() => {
            if (baitFirstLogin !== null) {
                history.push('/private/ferramentas');
            }
        }, 1000);
    }, []);

    const getTerminalAndPosDevices = useCallback(async () => {
        try {
            showLoading();
            await getTerminalDevices(DeviceType.TERMINAL);
            const terminalDevicesPromise = getTerminalDevices(DeviceType.TERMINAL);
            const posDevicesPromise = getTerminalDevices(DeviceType.POS);
            const [terminalDevices, posDevices] = await Promise.all([terminalDevicesPromise, posDevicesPromise]);
            setTerminalDevices(terminalDevices);
            setPosDevices(posDevices);
        } finally {
            hideLoading();
        }
    }, [getTerminalDevices, hideLoading, showLoading]);

    useEffect(() => {
        getTerminalAndPosDevices();
    }, [getTerminalAndPosDevices]);


    const width = window.innerWidth;

    const navigatePos = useCallback(() => {
        return history.push("/private/vendas/pos");
    }, [history]);

    const posList = useMemo(() => posDevices?.map((posDevice, index) => (
        <>
            {index <= 1 && (
                <div className={styles.posCard} key={posDevice.device.id}>
                    <h2>
                        <img src="/assets/icon/saas/saas-pos.svg" alt="" />
                        {posDevice.profile?.name}
                    </h2>
                </div>
            )}
        </>
    )), [posDevices]);

    useEffect(() => {
        posDevices?.length && posDevices[0].device.name.includes("LIO") && showCieloTutorial();
    }, [posDevices, showCieloTutorial]);

    const onClickModalCielo = useCallback(() => {
        onFinishCieloStep();
        history.push("/private/vendas/pos");
    }, [history, onFinishCieloStep])


    const terminalList = useMemo(() => terminalDevices?.map((terminalDevice, index) =>
        index <= 2
            ? (
                <TerminalCard
                    key={terminalDevice.device.id}
                    terminalDevice={terminalDevice}
                    salesNowTerminal={index === 0}
                />
            ) : null
    ), [terminalDevices]);

    return (
        <div id={styles.SaasHomePage}>
            <div className={styles.info}>

                <div className={styles.headerInfo}>
                    <h2>Como você quer vender hoje{user?.name === "Usuário Temporário" ?  "?" : `, ${user?.name}?`}</h2>
                    <p>Venda de forma digital ou física:</p>
                </div>
                <div className={styles.terminalList}>
                    <div className={styles.list}>
                        <p>Venda digital</p>
                        {terminalList}
                    </div>
                    <div className={styles.list}>
                        <p>Venda física</p>
                        {posDevices?.length ? posList : null}
                        <div className={`${styles.posCard} ${styles.posButton}`} onClick={navigatePos}>
                            <h2>
                                <AddCircleOutlineOutlined />
                                Adicionar máquina
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <OperatorAndTableFragment />

            <div className={styles.info}>
                <div className={styles.headerInfo}>
                    <h2>Seus atalhos</h2>
                    <p>O que você quer fazer?</p>
                </div>
                <div className={styles.shortcuts}>
                    <div className={styles.shortcutInfo} onClick={() => history.push("/private/meepfood/painel")}>
                        <div className={styles.image}>
                            <img src="/assets/icon/saas/home-orders.svg" alt="" />
                        </div>
                        <span>Ver meu painel de pedidos</span>
                    </div>

                    <div className={styles.shortcutInfo} onClick={() => history.push("/private/catalog")}>
                        <div className={styles.image}>
                            <img src="/assets/icon/saas/home-products.png" alt="" />
                        </div>
                        <span>Alterar meus produtos</span>
                    </div>

                    <div className={styles.shortcutInfo} onClick={() => history.push("/private/terminal")}>
                        <div className={styles.image}>
                            <img src="/assets/icon/saas/home-terminals.png" alt="" />
                        </div>
                        <span style={{ padding: "1rem 0" }}>Aprovar acessos aos terminais de venda</span>
                    </div>

                    <div className={styles.shortcutInfo} onClick={() => history.push("/private/solucoesonline")}>
                        <div className={styles.image}>
                            <img src="/assets/icon/saas/home-catalog.svg" alt="" height={80} />
                        </div>
                        <span>Configurar meu cardápio online</span>
                    </div>
                </div>
            </div>

            {/* <div className={styles.info}>
                <div className={styles.headerInfo}>
                    <h3>Insights para você:</h3>
                </div>

                <div className={styles.shortcuts} style={{ flexWrap: width > 750 ? "nowrap" : "wrap" }}>
                    <div className={styles.insights}>
                        <span>✨</span>
                        <span>Você fez 227 vendas essa semana. <a>Veja mais detalhes.</a></span>
                    </div>

                    <div className={styles.insights}>
                        <span>✨</span>
                        <span>45 novos clientes foram registrados no seu estabelecimento. <a>Envie uma mensagem</a> convidando para voltarem!</span>
                    </div>

                    <div className={styles.insights}>
                        <span>✨</span>
                        <span>As combinações de pedidos mais comuns foram Coca-Cola 220ml e Pizza de calabresa. <a>Veja mais detalhes.</a></span>
                    </div>
                </div>
            </div> */}


            <CieloModal
                open={openModalCielo}
                onClose={onFinishCieloStep}
                onClick={onClickModalCielo}
            />

        </div>
    )
}

export default SaasHomePage;
