import { IDeviceProducts } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/productsForm/IProducts";
import { IDeviceProfileService } from "../../../../domain/interface/IDeviceProfileService";

const EditDeviceProductsUseCase = async (
  profileId: string,
  localId: string,
  deviceProfileService: IDeviceProfileService,
  values: IDeviceProducts
) => {
  await deviceProfileService.editDeviceProducts(localId, profileId, values);
};
export default EditDeviceProductsUseCase;
