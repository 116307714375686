import { FC, useEffect } from "react";
import styles from "./CardsActivation.module.scss";
import { ICardsActivation } from "./interfaces/ICardsActivation";
import { Skeleton } from "../skeleton/Skeleton";
import { moneyMaskNumber } from "services/utils/Money";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";

export interface ICardsActivationProps {
  getData: () => Promise<void>;
  loading: boolean;
  data?: ICardsActivation;
  onGeneratingPDF?: boolean;
  triggerOperator: boolean;
  expiresAt: string | undefined;
  triggerCustomer: boolean;
  triggerDevices: boolean;
  triggerProducts: boolean;
  triggerCategories: boolean
}

const CardsActivation: FC<ICardsActivationProps> = ({
  getData,
  loading,
  data,
  onGeneratingPDF,
  triggerOperator,
  expiresAt,
  triggerCustomer,
  triggerDevices,
  triggerProducts,
  triggerCategories
}) => {
  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data && expiresAt && new Date() > new Date(expiresAt))
      getData();
  }, [triggerCategories, triggerOperator, triggerCustomer, triggerDevices, triggerProducts]);

  if (loading) {
    return (
      <div id={styles.CardsActivation}>
        <div className={styles.container}>
          <div className={styles.column}>
            <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
          </div>
          <div className={styles.column}>
            <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.CardsActivation}>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.header}>
            <span className={styles.total}>{data?.activated.total}</span>
            <span>
              Cartões ativados
              {!onGeneratingPDF && <Tooltip
                title="Cartões ativados"
                text="Quantidade de cartões ativados no período selecionado, quantidade dos ativos nesse momento, o valor total de ativação e separação de quantidade por sexo."
              />}
            </span>
            <span>({data?.remainsOpen} ainda ativos)</span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Ativação:</span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.activated.value)}
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Masculino:</span>
            <span className={styles.value}>
              {data?.activated.male} ({data?.activated.malePercentage}%)
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Feminino:</span>
            <span className={styles.value}>
              {data?.activated.female} ({data?.activated.femalePercentage}%)
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Não informado:</span>
            <span className={styles.value}>
              {data?.activated.unknown} ({data?.activated.unknownPercentage}%)
            </span>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.header}>
            <span className={styles.total}>{data?.deactivated.total}</span>
            <span>
              Cartões desativados
              {!onGeneratingPDF && <Tooltip
                title="Cartões desativados"
                text="Quantidade de cartões desativados no período selecionado, quantidade dos que já estavam ativos em períodos anteriores, quantidade dos que foram ativados no período, o valor total de desativação e separação de quantidade por sexo."
              />}
            </span>
            <span>
              {data?.deactivatedInThePeriod} ativados no período
              {/* |{" "} {data?.deactivatedOherPeriod} em períodos anteriores) */}
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Desativação:</span>
            <span className={styles.value}>
              {moneyMaskNumber(data?.deactivated.value)}
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Masculino:</span>
            <span className={styles.value}>
              {data?.deactivated.male} ({data?.deactivated.malePercentage}%)
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Feminino:</span>
            <span className={styles.value}>
              {data?.deactivated.female} ({data?.deactivated.femalePercentage}%)
            </span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Não informado:</span>
            <span className={styles.value}>
              {data?.deactivated.unknown} ({data?.deactivated.unknownPercentage}
              %)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardsActivation;
