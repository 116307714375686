import { z } from "zod";

const emailSchema = z.string().email("E-mail inválido");

const documentValidate = (input: string): boolean => {
  const onlyNumbers = input.replace(/\D/g, "");
  return onlyNumbers.length === 11;
};

export const forgetPassSchema = z.object({
  emailOrDocument: z.string()
    .min(1, { message: "Campo obrigatório!" })
    .refine((input) => {
      return emailSchema.safeParse(input).success || documentValidate(input);
    }, {
      message: "O campo deve conter um email ou um CPF válido",
    }),
});

export type IForgetPassSchema = z.infer<typeof forgetPassSchema>;
