import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import InputImage from 'components/ui/inputImage/InputImage';
import React, { FC } from 'react'
import { ICatagoryServiceScheduleForm } from './interfaces/ICatagoryServiceScheduleForm'
import UseServiceCategoryForm from './UseServiceCategoryForm'
import styles from "./ServiceCategoryForm.module.scss";
import { ArrowBackIos, FiberManualRecord } from '@material-ui/icons';
import { TooltipInfo } from 'components/ui/tooltip/Tooltip';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import { MenuItem } from '@material-ui/core';
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';

interface ServiceCategoryFormProps {
    defaultValues?: ICatagoryServiceScheduleForm,
    onSubmit: (values: ICatagoryServiceScheduleForm) => void,
    onClickClose: () => void,
    serviceMainCategories: ISelect[];
}
const ServiceCategoryForm: FC<ServiceCategoryFormProps> = ({ defaultValues, onSubmit, onClickClose: toggleModal, serviceMainCategories }) => {
    const {
        values,
        onChangeHandle,
        handleChangeSingleImage,
        onSubmitHandle
    } = UseServiceCategoryForm(onSubmit, defaultValues);
    
    return (
        <div id={styles.ServiceCategoryForm}>
            <h2><ArrowBackIos onClick={toggleModal} /> Nova <span>Categoria</span> </h2>

            <form onSubmit={onSubmitHandle}>
                <FormItemContainer >
                    {/* <div className={styles.containerHelp}>
                        <h2>Informações do cadastro</h2>
                        <TooltipInfo
                            title={"Informações do cadastro"}
                            paragraph={'Para cadastrar categoria geral preencha apenas o campo "Nome da categoria geral", se deseja cadastrar a categoria do serviço preencha o campo "Nome da categoria do Serviço"  '}
                        />
                    </div> */}
                    <FormItemContainer label={"Nome da categoria principal"}>
                        <Input
                            name={"name"}
                            value={values.name}
                            placeholder="Ex.: Passeio de lancha"
                            onChange={ev => { onChangeHandle(ev.target.name, ev.target.value) }}
                            required
                        />
                    </FormItemContainer>
                    <FormItemContainer label={"Nome da sub-categoria"}>
                        <Input
                            name="categoryId"
                            onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                            value={values.categoryId}
                            select
                            placeholder="Selecione a categoria"
                            
                        // label="seviceCategoryId"description
                        >                           
                            {
                                serviceMainCategories?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                ))
                            }

                        </Input>
                    </FormItemContainer>
                    <FormItemContainer row >
                        <div className={styles.containerInputImage}>
                            <InputSingleImage value={values.imageUrl} onChangeImage={handleChangeSingleImage} />
                            <div>
                                <h3> Selecione uma imagem</h3>
                                <p><FiberManualRecord /> Tamanho mínimo de 300x300px e máximo 1080x1080px.</p>
                                <p><FiberManualRecord /> A imagem será usada na página do seu passeio.</p>
                            </div>
                        </div>
                    </FormItemContainer>
                    <div className={styles.containerButton}>

                        <Button fullWidth={false} type="submit">{defaultValues ? "Atualizar Categoria" : "Concluir"}</Button>
                        <Button fullWidth={false} variant="outlined" onClick={toggleModal}>Voltar</Button>
                    </div>
                </FormItemContainer>
            </form>
        </div>
    )
}

export default ServiceCategoryForm