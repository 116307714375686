import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import styles from './AccessProductForm.module.scss'
import { Autocomplete } from '@material-ui/lab'
import { Drawer, Icon, TextField } from '@material-ui/core'
import ContainerDrawer from '../../ui/ContainerDrawer'
import { IProduct } from 'modules/catalog/presentation/fragments/catalogFragment/CatalogFragment'
import ButtonsForm from '../../ui/ButtonsForm'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { ContainerLabel } from '../../ui/ContainerLabel'
import Column from '../../ui/Column'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { Link } from 'react-router-dom'

export interface IAccessUser {
    id: string
    name: string
}

export interface IAccessProductValues {
    accessUsers?: IAccessUser[];
}

export interface IAccessProductFormProps {
    //propertys
    productToEditAccess: IProduct | undefined;
    onClose: () => void;
    onSubmit: (product: IProduct, values: IAccessProductValues) => Promise<void>;
    getAccessUser: () => Promise<IAccessUser[]>;
    getDefault: (product: IProduct) => Promise<IAccessProductValues>;
}
const AccessProductForm: FC<IAccessProductFormProps> = memo(({ getAccessUser, onSubmit, productToEditAccess, onClose, getDefault }) => {

    const [accessUsers, setAccessUsers] = useState<IAccessUser[]>();
    const [accessUsersSelected, setAccessUsersSelected] = useState<IAccessUser[]>();
    const { showLoading, hideLoading } = useUi();

    useEffect(() => {
        const handleGetDefault = async () => {
            try {
                showLoading();
                if (productToEditAccess) {
                    const _defaultValues = await getDefault(productToEditAccess)
                    setAccessUsersSelected(_defaultValues.accessUsers);
                }

            } finally {
                hideLoading();
            }
        }
        handleGetDefault();
    }, [getDefault, hideLoading, productToEditAccess, showLoading])

    useEffect(() => {
        const handleGetAccessUser = async () => {
            try {
                showLoading();
                if (productToEditAccess) {
                    const accessUsers = await getAccessUser()
                    setAccessUsers(accessUsers)
                }
            } finally {
                hideLoading();
            }
        }
        handleGetAccessUser();
    }, [getAccessUser, hideLoading, productToEditAccess, showLoading])

    const onSubmitHandle = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); try {
            showLoading();
            if (productToEditAccess) {
                await onSubmit(productToEditAccess, { accessUsers: accessUsersSelected });
                onClose();
            }
        } finally {
            hideLoading();
        }
    }, [accessUsersSelected, hideLoading, onClose, onSubmit, productToEditAccess, showLoading])

    const onChangeHandle = useCallback((event: React.ChangeEvent<{}>, value: IAccessUser[]) => {
        setAccessUsersSelected(value);
    }, [])

    const handleOnClose = useCallback(() => {
        setAccessUsersSelected([]);
        onClose();
    }, [onClose])

    return (
        <Drawer anchor='right' open={!!productToEditAccess} onClose={handleOnClose}>
            <ContainerDrawer title={<div>Limitar <b>produto</b></div>} onClose={handleOnClose}>
                <form id={styles.AccessProductForm} onSubmit={onSubmitHandle} >
                    <div className={styles.description}>
                        <div>
                            Selecione as pessoas que deseja limitar o acesso apenas ao produto selecionado. Isso significa que, as pessoas abaixo verão apenas esse produto.
                        </div>
                        <div className={styles.link}>
                            <Icon>info</Icon> Para ver a listagem completa de limitação, <Link to="/private/produtos/limitarAcesso">clique aqui</Link>.
                        </div>
                    </div>
                    <FormItemContainer column>
                        <Column>
                            <ContainerLabel>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    value={accessUsersSelected ?? []}
                                    getOptionLabel={(option) => option.name}
                                    options={accessUsers ?? []}
                                    onChange={onChangeHandle}
                                    renderInput={(params) => <TextField {...params} label={`Usuarios`} variant="outlined" />}
                                />
                            </ContainerLabel>
                            <div className={styles.buttonContainers}>
                                <ButtonsForm color='primary' onCancel={handleOnClose} />
                            </div>

                        </Column>
                    </FormItemContainer>
                </form>
            </ContainerDrawer>
        </Drawer>
    )
})
export default AccessProductForm