import { AppBar, Tab, Tabs } from "@material-ui/core";
import { FC, useCallback } from "react";
import { TabPanel } from "./components/tabProps/TabProps";
import { ITabs } from "./interfaces/ITabNavigation";
import styles from "./TabNavigation.module.scss";

interface ITabNavigationProps {
  tabs: ITabs[];
  selectedTab: number;
  handleTabChange: (newTabIndex: number) => void;
  className?: string;
  bodyClassName?: string;
}
const TabNavigation: FC<ITabNavigationProps> = ({ tabs, selectedTab, handleTabChange, className, bodyClassName }) => {
  const tabProps = useCallback((index: string | number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }, []);

  return (
    <div id={styles.TabNavigation} className={className}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          classes={{
            indicator: styles.indicator,
          }}
          value={selectedTab}
          onChange={(_, tab) => handleTabChange(tab)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {tabs &&
            tabs.map((item, index) => (
              <Tab
                key={index}
                label={item.title}
                {...tabProps(index)}
                classes={{
                  root: styles.root,
                  selected: styles.selected,
                }}
              />
            ))}
        </Tabs>
      </AppBar>
      {tabs &&
        tabs.map((item, index) => (
          <TabPanel key={index} value={selectedTab} index={index} className={bodyClassName}>
            {item.component}
          </TabPanel>
        ))}
    </div>
  );
};
export default TabNavigation;
