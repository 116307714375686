import CNPJ from "services/utils/CNPJ";
import CPF from "services/utils/CPF";
import { z } from "zod";
import { PartnerType } from "modules/meepErp/models/partner/PartnerType";
import { PartnerOriginType } from "modules/meepErp/models/partner/PartnerOriginType";

export const CorporatePartnerValidatorSchema = z.object({
    partnerOriginType: z.nativeEnum(PartnerOriginType),
    document: z.string().min(1, { message: "CNPJ é obrigatório" }).refine((value) => value ? CNPJ.isValid(value) : true),
    name: z.string().min(3, { message: "O nome deve ter no mínimo 3 caracteres" }),
    type: z.nativeEnum(PartnerType),
    tradingName: z.string().min(1, { message: "Nome fantasia é obrigatório" }),
    stateRegistration: z.string(),
    municipalRegistration: z.string(),
    sectorTypeId: z.string().min(1, { message: "O setor deve ser informado" }),
    legalRepresentative: z.object({
        name: z.string(),
        document: z.string().refine((value) => value ? CPF.isValid(value) : true, {
            message: "CPF inválido",
        }),
    }),
    contacts: z.array(
        z.object({
            name: z.string(),
            phone: z.string(),
            email: z.string().refine(value => {
                // Se o valor não for fornecido, a validação será ignorada
                if (!value) return true;
            
                // Se o valor for fornecido, ele deve ser um email válido
                return z.string().email();
            })
        })
    ),
    address: z.object({
        street: z.string().min(1,{ message: "Rua é obrigatória"}),
        number: z.string().min(1,{ message: "Número é obrigatório"}),
        neighborhood: z.string().min(1,{ message: "Bairro é obrigatório"}),
        city: z.string().min(1,{ message: "Cidade é obrigatória"}),
        state: z.string().min(1,{ message: "Estado é obrigatório"}),
        zipCode: z.string().min(1,{ message: "CEP é obrigatório"}),
    }),
})