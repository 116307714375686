

export enum sortByEnum {
    ProductName = 'name',
    CategoryName = 'categoryName',
    Quantity = 'quantity',
    total = 'total'
}
export enum groupByEnum {
    None = '',
    Name = 'FirstName',
    FullName = 'FullName'
}

export interface IRakingProductFilter {
    sortBy?: sortByEnum;
    sortOrientation?: 'asc' | 'desc';
    groupBy?: groupByEnum;
    skip?: number;
    take?: number;
}

export interface IRankinProduct {
    placeId: string;
    placeName: string;
    color?: string;

    id: string;
    name: string;
    imageUrl: string;
    total: number;
    quantity: number;   
    // categoryId: string;
    categoryName: string;
    subItems?: IRankinProduct[];
}

export interface IProductRankingData {
    records: IRankinProduct[];
    totalRecords: number;
}
