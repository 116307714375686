import { IDeviceOperationItemValue } from "../deviceSystemForm/interfaces/IDeviceSystemValue";
import { RefundType } from "modules/config/deviceConfig/domain/enum/RefundType";

export enum OperatingSystemEnum {
    ANDROID = 1,
    WINDOWS,
}

export enum AcquirerTypeEnum {
    PagarMe = 1,
    Stone = 2,
    PagSeguro = 3,
    // Linx,
    GetNet = 5,
    Cielo = 6,
    // "Software Express",
    // "Mercado Pago",
    // "Pin Bank",
}

export interface IOperationParamsValues {
    isRequestTableNumberEnabled: boolean;
    isTakeOutOrder: boolean;
    isShouldUsePRISM: boolean;
    isSendToKDSEnabled: boolean;
    isReplaceCardEnabled: boolean; //cashless postAccount
    isMeepCard: boolean;
    isRechargeEnabled: boolean;
    isActivationEnabled: boolean;
    isDeactivationEnabled: boolean;
    isConsumptionEnabled: boolean;
    isConsumptionOnActivationEnabled: boolean;
    isRequiredConsumptionOnActivationEnabled: boolean;
    isBlockConsumptionAfterPaymentEnabled: boolean;
    isBlockConsumptionAfterCloseEnabled: boolean; //posPagoConta Compartilhado
    maximumConsumption: number; //cashless
    minimunConsumption: number; //pospago
    operatingSystemType: OperatingSystemEnum;
    acquirerType: AcquirerTypeEnum;
    printType: number;
    refundType: {
        name: string;
        value: RefundType;
    }; //cashless
    isPaymentAfterCloseAccountEnabled: boolean; //pos pago conta
    isRequestConsumerNumberEnabled: boolean;
    ignoreValueToActivateCard: boolean;
    isPreValidateOrderEnabled: boolean;
    isRequireDocumentOnConsumptionEnabled: boolean;
    isCloseSessionEnabled: boolean;
    methods?: IDeviceOperationItemValue[];
    isPostPaidRecharge: boolean;
    isDivisionIndividualPosPaidConsumption: boolean;
}
