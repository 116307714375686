import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import AdminNovidadesPage from "modules/adminNovidades/presentation/pages/adminNovidadesPage";

const AdminNovidades: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Admin",
      },
      {
        label: "Admin",
        title: "Adicionar novidades",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <AdminNovidadesPage />
    </Layout>
  );
};
export default AdminNovidades;
