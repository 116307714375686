import { Icon } from '@material-ui/core'
import { Schedule } from '@mui/icons-material'
import { differenceInDays, differenceInHours, differenceInSeconds, format } from 'date-fns'
import { differenceInMinutes } from 'date-fns/esm'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { ptBR } from 'date-fns/locale'
import React, { FC, useEffect, useState } from 'react'
import styles from './KDSTimer.module.scss'
export interface IKDSTimerProps {
    //propertys
    startDate: Date;
    timeToPrepareInMinutes?: number
}

const KDSTimer: FC<IKDSTimerProps> = ({ startDate }) => {
    const [time, setTime] = useState<string>();
    const [isTimer, setIsTimer] = useState(false);
    const [statusColor, setStatusColor] = useState("")
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            const totalSecondsDifference = differenceInSeconds(now, startDate);
            const hoursDifference = Math.floor(totalSecondsDifference / 3600);
            const minutesDifference = Math.floor((totalSecondsDifference % 3600) / 60); 
            const secondsDifference = totalSecondsDifference % 60;  
            if (hoursDifference >= 1 && differenceInDays(now, startDate) < 1) {
                setTime(formatDistanceToNow(startDate, { locale: ptBR }));
                setStatusColor("#ddd");
                setIsTimer(true);
            } else if (hoursDifference < 1) {
                setStatusColor("#399C54");
    
                if (minutesDifference >= 10 && minutesDifference < 20) {
                    setStatusColor("#DE9C00");
                } else if (minutesDifference >= 20) {
                    setStatusColor("#F44336");
                }
    
                setTime(`${String(minutesDifference).padStart(2, '0')}:${String(secondsDifference).padStart(2, '0')}`);
                setIsTimer(true);
            } else {
                setIsTimer(false);
            }
        }, 1000);
    
        return () => clearInterval(timer);
    }, [startDate]);

    return (
        <>
            {
              isTimer &&
                <div id={styles.KDSTimer} style={{ background: "#FFFFFF", borderColor: statusColor, color: statusColor }} >
                    <Schedule />
                    <span>{time}</span>
                </div>


            }
        </>
    )
}
export default KDSTimer