import { QRCodeSVG } from "qrcode.react";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./LinkForm.module.scss";
import FormContainer from "../../formContainer/FormContainer";

export interface ILinkFormProps {
  //propertys
  code: string;
  onClose?: () => void;
  onClickNext?: () => void;
  onClickBack?: () => void;
  onClickManual?: () => void;
}
const LinkForm: FC<ILinkFormProps> = ({
  onClickNext,
  code,
  onClickManual,
  onClose,
  onClickBack,
}) => {
  return (
    <div id={styles.LinkForm}>
      <FormContainer
        title={
          <span>
            Adicionar <b>Equipamento</b>
          </span>
        }
        onClickClose={onClose}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
      >
        <div className={styles.label}>Digite o código em seu equipamento</div>
        <div className={styles.code}>{code}</div>
        <div className={styles.label}>Ou aponte a camera</div>
        <div className={styles.qrCode}>
          <QRCodeSVG size={200} value={code} />
        </div>
        <div className={styles.manualLink} onClick={onClickManual}>
          Cadastrar Manualmente
        </div>
      </FormContainer>
    </div>
  );
};
export default LinkForm;
