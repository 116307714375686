import { useCallback, useEffect, useState } from "react";
import { IMeepFee } from "../interfaces/IMeepFee";
import { MeepFeeApiService } from "services/api/meepFee/MeepFeeApiService";
import GetMeepFeeUseCase from "modules/meepFee/application/useCases/GetMeepFeeUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";

export const UseMeepFeePage = () => {
    const { currentLocal } = useLocal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [feeList, setFeeList] = useState<IMeepFee[]>([]);

    const getMeepFee = useCallback(async () => {
        try {
            const meepFeeService = MeepFeeApiService();
            if (currentLocal?.id) {
                setIsLoading(true);
                const feeList = await GetMeepFeeUseCase(meepFeeService, currentLocal.id);
                setFeeList(feeList);
            }
        } finally {
            setIsLoading(false);
        }
    }, [currentLocal?.id]);

    useEffect(() => {
        getMeepFee();
    }, [getMeepFee]);

    return ({isLoading, feeList});
}
