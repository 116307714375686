import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from './Back.module.scss'
import { useHistory } from 'react-router-dom';

interface Props {
    route: string;
    margin?: boolean
}

const Back = ({ route, margin }: Props) => {

    const { push } = useHistory()

    return (
        <div id={styles.Back} style={{ marginBottom: `${margin ? '30px' : ''}` }} onClick={() => push(route)}>
            <ArrowBackIosIcon />
            <p>Voltar</p>
        </div>

    )
}

export default Back