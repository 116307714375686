import Layout from "components/layout/presentation/Layout";
import { ServiceScheduleListPage } from "modules/schedule/presentation/pages/serviceScheduleList/ServiceScheduleListPage";
import React, { FC } from "react";

const ServicesSchedule: FC = () => {
  return (
    <Layout>
      <ServiceScheduleListPage />
    </Layout>
  );
};
export default ServicesSchedule;
