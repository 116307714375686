import { IEventPeopleListResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsGuestsApi } from '../../interfaces/IGuestsService';
import { IEventGuestFilter } from 'modules/events/presentation/pages/eventPeopleList/UseEventPeopleListPage';

const GetGuestsOnEventUseCase = async (
  guestsService: IEventsGuestsApi,
  eventId: string,
  localId: string,
  pagination?: { page?: number, pageSize?: number },
  filter?: IEventGuestFilter
): Promise<IEventPeopleListResponse> => {
  return await guestsService.getPeopleOnEvent(eventId, localId, filter, pagination);
};

export default GetGuestsOnEventUseCase;
