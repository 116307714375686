import { TextField } from '@mui/material';
import styles from './DetailsForm.module.scss';
import { IAutomationForm } from 'modules/automation/presentation/interfaces/IForm';

interface DetailsFormProps {
    form: IAutomationForm;
    onChange: (form: IAutomationForm) => void;
}

export const DetailsForm = ({ form, onChange }: DetailsFormProps) => {
    return (
        <div className={styles.container}>
            <span className={styles.title}>Informe o nome e a descrição da automação que você está criando:</span>

            <div className={styles.formContainer}>
                <label>Nome da automação <span>*</span></label>
                <TextField
                    placeholder='Digite o nome da automação'
                    inputProps={{ maxLength: 50 }}
                    size='small'
                    value={form.name}
                    onChange={(e) => onChange({ ...form, name: e.target.value })}
                />

                <label>Descrição da automação</label>
                <TextField
                    placeholder='Digite a descrição do que deseja que sua automação realize'
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 124 }}
                    value={form.description}
                    onChange={(e) => onChange({ ...form, description: e.target.value })}
                />
            </div>

        </div>
    )
}