import { IGetOrganizerEventParams } from "modules/meepTickets/domain/dto/IGetOrganizerEventParams";
import { IGetOrganizerEventResponse } from "modules/meepTickets/domain/dto/IGetOrganizerEventResponse";
import { IMeepTicketsService } from "modules/meepTickets/domain/interface/IMeepTicketsService";
import { api } from 'services/api/Api';

const MeepTicketsApi = (): IMeepTicketsService => {
    const getOrganizerEvent = async (localId: string, page?: number, pageSize?: number, params?: IGetOrganizerEventParams): Promise<IGetOrganizerEventResponse> => {
        const respomse = await api.get<IGetOrganizerEventResponse>(`/MeepTickets/getOrganizerEventPaged/${localId}/paged`, {
          params: {
            page: page,
            size: pageSize,
            ...params,
          }
        });
        return respomse.data;
    }

    return {getOrganizerEvent};
}

export default MeepTicketsApi;
