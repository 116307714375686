export enum IDiscountProductType {
  percentual = "Percentual",
  money = "Absoluto",
}
export interface IDiscountProductListForm {
  name: string;
  id: string;
  price: number;
}

export interface IDiscountProductListFormErrors {
  product?: string;
  discount?: string;  
  // validTime?: string,//date
}

export interface IDiscountListForm {
  type: IDiscountProductType;
  discount: number;
  product: IDiscountProductListForm;
  // validTime: string,//date
}


export interface IProductDiscount {
  priceDiscount?: {
    price: number;
  };

  percentualDiscount?: {
    percentual: number;
  };
  productId: string;
}

export interface IDiscountListSubmitForm {
  name: string;
  description: string;
  discounts: IProductDiscount[];
}
