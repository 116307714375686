import ProfileDeviceList from "../../components/deviceProfile/profileList/ProfileDeviceList";
import UseProfilePage from "./UseProfilePage";
import styles from "./DeviceProfilePage.module.scss";
import "./DeviceProfilePage.css";
import { Drawer, InputAdornment, TextField } from "@mui/material";
import { AddCircle, Search } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import DeviceProfileForm from "../../fragment/deviceProfile/DeviceProfileForm";
import DeleteProfileAlert from "../../components/deviceProfile/profileList/deleteAlert/DeleteAlert";
import FilterDeviceProfile from "../../components/deviceProfile/filterProfile/FilterDeviceProfile";
import {
  IOperationSelect,
  SystemType,
} from "../../components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue";
import ProfileBond from "../../components/deviceProfile/profileBond/ProfileBond";
import { PerfilApi } from "services/api/perfil/PerfilApi";
import { useCallback, useEffect, useState } from "react";
import PostPerfilVinculo from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/PostPerfilUseCase";
import { UseGestaoVendas } from "modules/gestaoVendas/presentation/hooks/UseGestaoVendas";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";

const perfilService = PerfilApi();

const DeviceProfilePage = () => {
  const {
    onChangeFilterHandle,
    searchTerm,
    filteredProfileList,
    action,
    onCopyProfileHandle,
    setSearchTerm,
    onClickAddProfileHandle,
    onClickEditProfileHandle,
    onDeleteProfileHandle,
    onClickChangeStatusHandle,
    profileToDelete,
    setProfileToDelete,
    profileNameList,
    onChangePaginationHandle,
    filterProfile,
    totalCount,
    perfilVinculo,
  } = UseProfilePage();
  const [step, setStep] = useState(1);
  const [vinculoAtual, setVinculoAtual] = useState(
    perfilVinculo ? perfilVinculo.toString() : "1"
  );
  const [vinculoDesejado, setVinculoDesejado] = useState<string>("");

  const { currentLocal } = useLocal();

  const { getSegmentos, responseConfigLocal } = UseGestaoVendas(true);

  useEffect(() => {
    getSegmentos();
  }, [getSegmentos]);

  useEffect(() => {
    if (perfilVinculo) {
      setVinculoAtual(perfilVinculo.toString());
    }
    setVinculoDesejado(vinculoAtual);
  }, [perfilVinculo]);

  const operationList: IOperationSelect[] = [
    {
      id: SystemType.TICKET,
      name: "Pré-pago Ficha",
    },
    {
      id: SystemType.PRE_CASHLESS,
      name: "Pré-pago Cashless",
    },
    {
      id: SystemType.POST_ACCOUNT,
      name: "Pós-pago Comanda",
    },
  ];

  const { toast } = useUi();

  const postPerfilVinculo = useCallback(() => {
    if (currentLocal) {
      PostPerfilVinculo(perfilService, currentLocal.id, {
        LocalClienteId: currentLocal.id,
        TipoVinculo: Number(vinculoDesejado),
      })
        .then(() => {
          setVinculoAtual(vinculoDesejado);
          setStep(3);
        })
        .catch((error) => {
          toast(error.response?.data.message, "error");
        });
    }
  }, [currentLocal, toast, vinculoDesejado]);

  return (
    <div id={styles.DeviceProfilePage}>
      {filteredProfileList && (
        <>
          {responseConfigLocal && responseConfigLocal?.trabalhaComEventos && (
            <ProfileBond
              setStep={setStep}
              step={step}
              postPerfilVinculo={postPerfilVinculo}
              setVinculo={setVinculoDesejado}
              vinculo={vinculoAtual}
            />
          )}
          <div className={styles.header}>
            <Button
              fullWidth={false}
              onClick={onClickAddProfileHandle}
              className={styles.addButton}
            >
              Adicionar perfil <AddCircle />
            </Button>
          </div>
          <div className={styles.header}>
            <FilterDeviceProfile
              value={filterProfile}
              operationList={operationList}
              onChange={onChangeFilterHandle}
            />
            <TextField
              className="device-profile-header-search"
              variant="outlined"
              label="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <ProfileDeviceList
            profileList={filteredProfileList}
            onClickCopyItem={onCopyProfileHandle}
            onClickItem={(profile) => {
              onClickEditProfileHandle(profile.id);
            }}
            onClickDeleteItem={(value) => setProfileToDelete(value)}
            onClickChangeStatusItem={(profile, status) =>
              onClickChangeStatusHandle(profile, status)
            }
            onChangePaginationHandle={onChangePaginationHandle}
            pagination={filterProfile.pagination}
            totalCount={totalCount}
          />
          <Drawer
            open={action === "adicionar" || action === "editar"}
            anchor="right"
          >
            <DeviceProfileForm
              onChangeFilterHandle={onChangeFilterHandle}
              profileNameList={profileNameList}
              systemIdentifier={currentLocal?.systemIdentifier}
            />
          </Drawer>
          <DeleteProfileAlert
            profile={profileToDelete}
            onClose={function (): void {
              setProfileToDelete(undefined);
            }}
            onConfirm={(profile) => {
              onDeleteProfileHandle(profileToDelete!.id);
            }}
          />
        </>
      )}
    </div>
  );
};

export default DeviceProfilePage;
