import { Drawer, Icon } from '@material-ui/core';
import styles from './ConfigBIModal.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { ILocal } from 'modules/local/domain/models/ILocal';
import { v4 } from "uuid";
import { IPowerBI } from '../../interfaces/IPowerBI';
import { IPowerBIConfigItem } from '../../interfaces/IPowerBIConfig';

type error = {
    id: string;
    errors?: {
        name?: string;
        url?: string;
    }
}

interface ConfigBIModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (ownerId: string, data: IPowerBI[]) => void;
    defaultValues?: IPowerBIConfigItem;
    loading: boolean;
}

export const ConfigBIModal = ({ open, onClose, onSubmit, defaultValues, loading }: ConfigBIModalProps) => {
    const { locals } = useLocal();
    const [owner, setOwner] = useState<ILocal | null>(null);
    const [values, setValues] = useState<IPowerBI[]>([{ id: v4(), name: '', url: '' }]);
    const [error, setError] = useState<error[]>([]);
    const [ownerError, setOwnerError] = useState('');

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        setValues(prev => prev.map((x, i) => i !== index ? x : { ...x, [ev.target.name]: ev.target.value }))
    }, []);

    const validate = useCallback(() => {
        setError([]);
        setOwnerError('');
        const errors: error[] = [];
        let hasError = false;

        if (!owner) {
            hasError = true;
            setOwnerError('Selecione um local');
        }

        values.forEach(x => {
            const _error: error = { id: x.id!, errors: {} };


            if (!x.name) {
                _error.errors!.name = 'Insira um nome';
            }
            if (!x.url) {
                _error.errors!.url = 'Insira uma URL válida';
            }

            if (Object.keys(_error.errors!).length) {
                errors.push(_error);
            }
        })

        if (errors.length || hasError) {
            setError(errors);
            return false;
        }

        return true;
    }, [owner, values]);

    const submitHandle = useCallback((ev: FormEvent<HTMLButtonElement>) => {
        if (validate()) {
            onSubmit?.(owner!.id, values);
        }
    },
        [onSubmit, owner, validate, values]
    );

    const handleAddBI = () => {
        setValues(prev => ([...prev, { id: v4(), name: '', url: '' }]));
    }

    const handleRemoveBI = (id: string) => {
        setValues(prev => prev.filter(x => x.id !== id));
        setError(prev => prev.filter(x => x.id !== id));
    }

    useEffect(() => {
        if (defaultValues) {
            setOwner({
                id: defaultValues.localId,
                name: defaultValues.localName,
            });
            setValues(defaultValues.powerBIFeature.map(x => ({ id: x.id, name: x.name, url: x.url })))
        } else {
            setOwner(null);
            setValues([{ id: v4(), name: '', url: '' }])
        }
    }, [defaultValues])

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Adicionar <b>BI</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>

                <div className={styles.infoContainer}>
                    <Icon>info_outlined</Icon>
                    <p>Os links inseridos estarão disponíveis para o estabelecimento no <b>menu BI.</b> Por isso, insira o nome e link correto:</p>
                </div>

                <div className={styles.content}>
                    <Autocomplete
                        className={styles.input}
                        style={{ minWidth: 300 }}
                        size='small'
                        disabled={!!defaultValues}
                        renderInput={(props) =>
                            <div className={styles.inputContainer}>
                                <span className={styles.required}>Local</span>
                                <TextField
                                    className={styles.textField}
                                    maxRows={1}
                                    name={"locals"}
                                    error={!!ownerError}
                                    helperText={ownerError}
                                    {...props}
                                />
                            </div>
                        }
                        renderOption={(props, option) => {
                            return (
                                <span {...props} key={option.id}>
                                    {option.name}
                                </span>
                            );
                        }}
                        isOptionEqualToValue={(option, values) => option.id === values.id}
                        options={locals || []}
                        getOptionLabel={(option) => option.name}
                        onChange={(ev, value) => {
                            setOwner(value)
                        }}
                        value={owner}
                    />

                    <div className={styles.configContainer}>
                        {
                            values.map((val, index) => (
                                <div>
                                    <div className={styles.configHeader}>
                                        <h1>Power BI {index + 1}</h1>
                                        {
                                            index > 0 &&
                                            <button onClick={() => handleRemoveBI(val.id!)}>
                                                <Icon>delete_outlined</Icon>
                                            </button>
                                        }
                                    </div>
                                    <div className={styles.inputContainerRow}>
                                        <span className={styles.required}>Nome</span>
                                        <TextField
                                            className={styles.textField}
                                            name={"name"}
                                            placeholder='Ex.: Clientes'
                                            size='small'
                                            helperText={error.find(x => x.id === val.id)?.errors?.name}
                                            error={!!error.find(x => x.id === val.id)?.errors?.name}
                                            onChange={ev => changeHandle(ev, index)}
                                            value={val.name}
                                        />
                                    </div>

                                    <div className={styles.inputContainerRow}>
                                        <span className={styles.required}>Link do Power BI</span>
                                        <TextField
                                            className={styles.textField}
                                            name={"url"}
                                            placeholder='https://'
                                            size='small'
                                            helperText={error.find(x => x.id === val.id)?.errors?.url}
                                            error={!!error.find(x => x.id === val.id)?.errors?.url}
                                            onChange={ev => changeHandle(ev, index)}
                                            value={val.url}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>


                    <button className={styles.addNewOne} onClick={handleAddBI}>
                        <Icon>add_circle_outlined</Icon>
                        <label>Adicionar novo BI</label>
                    </button>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Voltar</button>
                    <button onClick={submitHandle}>{loading ? 'Salvando' : 'Concluir'}</button>
                </div>
            </div>
        </Drawer>
    )
}