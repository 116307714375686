import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CancelPermutationAccountUseCase } from "modules/paymentLinkReport/application/useCases/CancelPermutationAccountUseCase";
import { useCallback, useState } from "react";
import PaymentLinkRecurrenceApi from "services/api/paymentLinkRecurrence/PaymentLinkRecurrenceApi";

const UsePaymentLinkCancelSubscription = () => {

  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>();
  const { currentLocal } = useLocal();
  const [successCancel, setSuccessCancel] = useState(false)


  const cancelSubscriptionPaymentLink = useCallback(
    async (recurrenceId: string) => {
      try {
        const service = PaymentLinkRecurrenceApi();

        setIsLoadingCancel(true);
        if (currentLocal) {
          await CancelPermutationAccountUseCase(service,
            recurrenceId,
          );
          setSuccessCancel(true)
          return "ok"
        }
      } catch {
        setSuccessCancel(false)
      }

      finally {
        setIsLoadingCancel(false);
      }
    },
    [currentLocal]
  );

  return {
    setSuccessCancel,
    successCancel,
    isLoadingCancel,
    cancelSubscriptionPaymentLink,

  };
};

export default UsePaymentLinkCancelSubscription;
