import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { ILocal } from 'modules/local/domain/models/ILocal';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import AddServiceScheduleUseCase from 'modules/schedule/application/useCases/serviceSchedule/AddServiceScheduleUseCase';
import { GetProductListByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetProductListByLocalIdUseCase';
import { GetServiceCategoriesByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetServiceCategoriesByLocalIdUseCase';
import { SaveServiceCategoryUseCase } from 'modules/schedule/application/useCases/serviceSchedule/SaveServiceCategoryUseCase';
import { SaveServiceProductListUseCase } from 'modules/schedule/application/useCases/serviceSchedule/SaveServiceProductListUseCase';
import { IAddServiceCategoryRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceCategoryRequest';
import { IAddServiceProductListRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceProductListRequest';
import { IAddServiceScheduleRequest } from 'modules/schedule/domain/dto/serviceSchedule/IAddServiceScheduleRequest';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import { ServicesScheduleApi } from 'services/api/schedule/service/ServiceScheduleApi';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import { ICatagoryServiceScheduleForm } from '../../components/serviceCategoryForm/interfaces/ICatagoryServiceScheduleForm';
import { IServiceScheduleForm } from '../../components/serviceForm/interfaces/IServiceForm';
import { IProductListServiceScheduleForm } from '../../components/serviceProdutctForm/interfaces/IProductListServiceScheduleForm';
import { GetMainServiceCategoriesByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetMainServiceCategoriesByLocalIdUseCase';
import { GetServiceByIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetServiceByIdUseCase';
import EditServiceScheduleUseCase from 'modules/schedule/application/useCases/serviceSchedule/EditServiceScheduleUseCase';


export const UseFormService = () => {

    const { currentLocal } = useLocal();
    const { showLoading, hideLoading, toast } = useUi();
    const { push } = useHistory();

    const { serviceId } = useParams<{ serviceId: string }>();

    const [productLists, setProductLists] = useState<ISelect[]>([]);
    const [serviceCategories, setServiceCategories] = useState<ISelect[]>([]);
    const [serviceMainCategories, setServiceMainCategories] = useState<ISelect[]>([]);
    const [openDrawerCagetory, setOpenDrawerCagetory] = useState(false)
    const [openDrawerProduct, setOpenDrawerProduct] = useState(false)
    const [defaultValues, setDefaultValues] = useState<IServiceScheduleForm>()
    const [serviceCategoriesAll, setServiceCategoriesAll] = useState<ISelect[]>([])

    const getServiceCategories = useCallback((currentLocal: ILocal) => {
        // if (currentLocal) {
        //     showLoading();
        //     GetServiceCategoriesByLocalIdUseCase(currentLocal.id, ServicesScheduleApi).then((response) => {
        //         setServiceCategories(response);
        //         setServiceCategoriesAll((prev => ([...prev,...response])));
        //     }).finally(() => {
        //         hideLoading();
        //     })
        // }
    }, [hideLoading, showLoading])

    const getServiceMainCategories = useCallback((currentLocal: ILocal) => {
        if (currentLocal) {
            showLoading();
            GetMainServiceCategoriesByLocalIdUseCase(currentLocal.id, ServicesScheduleApi).then((response) => {
                setServiceMainCategories(response);
                setServiceCategoriesAll(response);               
            }).finally(() => {
                hideLoading();
            })
        }
    }, [hideLoading, showLoading])

    const getProductLists = useCallback((currentLocal: ILocal) => {
        if (currentLocal) {
            showLoading();
            GetProductListByLocalIdUseCase(currentLocal.id, ServicesScheduleApi).then((response) => {
                setProductLists(response);
            }).finally(() => {
                hideLoading();
            })
        }
    }, [hideLoading, showLoading])

    const getServiceById = useCallback((serviceId: string) => {

        showLoading();
        GetServiceByIdUseCase(serviceId, ServicesScheduleApi).then((response) => {
            setDefaultValues(response);
        }).finally(() => {
            hideLoading();
        })

    }, [hideLoading, showLoading])


    const toggleModalCategory = () => {
        setOpenDrawerCagetory(!openDrawerCagetory)
    }

    const toggleModalProduct = () => {
        setOpenDrawerProduct(!openDrawerProduct)
    }

    useEffect(() => {
        if (currentLocal && serviceId) {
            getServiceMainCategories(currentLocal);
            getServiceCategories(currentLocal);
            getProductLists(currentLocal);
            getServiceById(serviceId);
        }
    }, [currentLocal, getServiceCategories, getServiceMainCategories, getServiceById]);


    const addNewServiceCategoryHandle = useCallback((values: ICatagoryServiceScheduleForm) => {
        if (currentLocal) {
            showLoading();
            const request: IAddServiceCategoryRequest = {
                localId: currentLocal.id,
                name: values.name,
                categoryId: values.categoryId,
                image: values.image ?? "",
            }
            SaveServiceCategoryUseCase(request, ServicesScheduleApi).then(() => {
                setOpenDrawerCagetory(false);
                hideLoading();
                getServiceCategories(currentLocal);
            })
        }
    }, [currentLocal, getServiceCategories]);

    const addNewServiceProductListHandle = useCallback((values: IProductListServiceScheduleForm) => {
        if (currentLocal) {
            showLoading();
            const request: IAddServiceProductListRequest = {
                localClientId: currentLocal.id,
                description: values.description,
            }
            SaveServiceProductListUseCase(request, ServicesScheduleApi).then(() => {
                setOpenDrawerProduct(false);
                getProductLists(currentLocal);
                toast("Lista criada com sucesso", "success");
            }).catch(() => {
                
            }).finally(() => {
                hideLoading();
            });
        }
    }, [currentLocal, getProductLists]);


    const addServiceScheduleHandle = useCallback(
        (values: IServiceScheduleForm) => {
            if (currentLocal) {
                const request: IAddServiceScheduleRequest = {
                    ...values,
                    localClientId: currentLocal.id,
                }
                showLoading();
                if (!!serviceId) {
                    EditServiceScheduleUseCase(request, ServicesScheduleApi).then(() => {
                        toast("Serviço editado com sucesso", "success");
                        //todo redirect to List
                        push('/private/agendamento/list/servicos');
                    }).catch((_error) => {
                        
                    }).finally(() => {
                        hideLoading();
                    })
                } else {
                    AddServiceScheduleUseCase(request, ServicesScheduleApi).then(() => {
                        toast("Serviço adicionado com sucessoss", "success");
                        //todo redirect to List
                        push('/private/agendamento/list/servicos');
                    }).catch((_error) => {
                        
                    }).finally(() => {
                        hideLoading();
                    })

                }
            }
        },
        [currentLocal, hideLoading, showLoading, toast],
    )

    return (
        {
            productLists,
            serviceCategories,
            serviceMainCategories,
            openDrawerCagetory,
            setOpenDrawerCagetory,
            addNewServiceCategoryHandle,
            addNewServiceProductListHandle,
            toggleModalCategory,
            toggleModalProduct,
            addServiceScheduleHandle,
            openDrawerProduct,
            setOpenDrawerProduct,
            defaultValues,
            serviceCategoriesAll
        }
    )
}
