export const Warning = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1834_18614)">
        <path
          d="M54.3487 55.2937C45.0002 62.9154 15.8146 62.9154 6.65392 55.2937C-2.69457 47.672 -1.74229 15.0958 6.65392 6.52316C15.0501 -2.04953 45.9525 -2.04953 54.3487 6.52316C62.7449 15.0958 63.6972 47.672 54.3487 55.2937Z"
          fill="#FFB300"
        />
        <path
          d="M30.663 41.9508C22.2802 48.6215 12.9317 52.0505 4.14652 52.2381C-2.33171 41.1873 -1.00388 14.3307 6.62783 6.52154C13.495 -0.524131 35.0489 -1.86361 47.4554 2.51649C50.5001 15.0942 44.2096 31.0876 30.663 41.9508Z"
          fill="#FFB300"
        />
        <rect
          x="26.4338"
          y="8.13322"
          width="8.13332"
          height="33.55"
          rx="4.06666"
          fill="white"
        />
        <circle cx="30.6063" cy="49.6063" r="4.60625" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1834_18614">
          <rect width="61" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
