import { Autocomplete } from '@material-ui/lab';
import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import AvailableDaysForm from '../availableDaysForm/AvailableDaysForm';
import styles from './ColaboratorScheduleForm.module.scss'
import { ICollaboratorScheduleFormValue } from './ColaboratorScheduleFormValue'
import UseColaboratorScheduleForm from './UseColaboratorScheduleForm';

export interface OperatorSelect {
    id: string;
    name: string;
}


export interface ISelect {
    id: string,
    description: string,
    subItems?: Array<Omit<ISelect, 'subItems'>>
}

export interface IColaboratorScheduleFormProps {
    //propertys
    defaultValues?: ICollaboratorScheduleFormValue
    onSubmit: (value: ICollaboratorScheduleFormValue) => void
    operators: OperatorSelect[]
    services: ISelect[]
}
const ColaboratorScheduleForm: FC<IColaboratorScheduleFormProps> = ({ defaultValues, onSubmit, operators, services }) => {
    const {
        values,
        selectedOperator,
        selectedService,
        onSubmitHandle,
        changeHandle,
        onChangeAvailableDaysHandle,
        handleChangeSingleImage,
        handleChangeOperator,
        handleChangeService
    } = UseColaboratorScheduleForm(operators, services, onSubmit, defaultValues)

    return (
        <div id={styles.ColaboratorScheduleForm} >
            <div className={styles.container} >
                <h2>Informações do Colaborador</h2>
                <form onSubmit={onSubmitHandle}>
                    <FormItemContainer label={"Nome"}>
                        <Input value={values.name} required name={"name"} onChange={changeHandle} placeholder="Ex.: Nome de identificação" />
                    </FormItemContainer>
                    {/* <FormItemContainer label={"Descrição"}>
                        <Input value={values.description} required name={"description"} onChange={changeHandle} multiline placeholder="Ex.: Profissional qualificado ..." />
                    </FormItemContainer>
                    <div>

                        <FormItemContainer label={"Foto"}>
                            <InputSingleImage
                                value={values.imageUrl}
                                onChangeImage={handleChangeSingleImage}
                            />
                        </FormItemContainer>
                    </div> */}
                    <FormItemContainer label={"Operador"}>
                        {/* {selectedOperator?.name} */}
                        {operators && selectedOperator &&
                            <AutoComplete
                                value={selectedOperator}
                                options={operators}
                                getOptionLabel={(option) => option.name}
                                // getOptionSelected={(option, value) => option.id === value.id}
                                required
                                name={"Operador"}
                                placeholder="Ex.: Operador Meep"
                                onChange={(event, value) => {
                                    handleChangeOperator(value as OperatorSelect);
                                }} />
                        }
                    </FormItemContainer>

                    <FormItemContainer label={"Serviço"}>
                        {services && selectedService &&
                            <AutoComplete
                                value={selectedService}
                                options={services}
                                multiple
                                getOptionLabel={(option) => (option.description)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                name={"Services"}
                                placeholder="Ex.: Corte de cabelo"
                                onChange={(event, value) => {
                                    handleChangeService(value as ISelect[]);
                                }} />}
                    </FormItemContainer>
                    <FormItemContainer>
                        <AvailableDaysForm values={values.availableDays} onChange={onChangeAvailableDaysHandle}></AvailableDaysForm>
                    </FormItemContainer>
                    <div className={styles.buttonSave} >
                        <Button
                            fullWidth={false}
                            disabled={values?.availableDays?.length < 1}
                            type="submit">Salvar
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ColaboratorScheduleForm