import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './ExistComplementForm.module.scss'
import { IProduct } from 'modules/catalog/presentation/fragments/catalogFragment/CatalogFragment'
import { ContainerLabel } from '../../ui/ContainerLabel';
import { Autocomplete } from '@material-ui/lab';
import { InputLabel } from '../../ui/InputLabel';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { IComplementGroupFormValue } from '../complementForm/ComplementForm';
import { IProductFormValue } from '../../productForm/IProducFormValuet';
import Column from '../../ui/Column';
import ButtonsForm from '../../ui/ButtonsForm';
import { Skeleton } from '@mui/material';

export interface IExistsProductListFormProps {
    //propertys
    // categories: ICategory[];
    onSubmit: (complement: IComplementGroupFormValue) => void;
    getAllProducts: () => Promise<IProduct[]>;
    // getProducts: (categoryId: string) => Promise<IProduct[]>;
    getProductDetail: (productId: string) => Promise<IProductFormValue>;
    onClose: () => void;
}
const ExistComplementForm: FC<IExistsProductListFormProps> = ({/* categories, getProducts, */getProductDetail, onSubmit, onClose, getAllProducts }) => {
    // const [step, setStep] = useState<"product" | "composition">();
    const [productList, setProductList] = useState<IProduct[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    // const [selectedCategory, setSelectedCategory] = useState<ICategory>();
    const [productDetail, setProductDetail] = useState<IProductFormValue>();
    const [selectedComplement, setSelectedComplement] = useState<IComplementGroupFormValue>();

    // const changeCategoryHandle = useCallback(async (ev, value: ICategory | null) => {
    //     if (value) {
    //         try {

    //             setIsLoading(true)
    //             setSelectedCategory(value)
    //             // setStep("product")
    //             const reponse = await getProducts(value.id)
    //             setProductList(reponse)
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     } else {
    //         setProductList([])
    //     }

    // }, [getProducts])
    const getProductsList = useCallback(async () => {

        try {
            setIsLoading(true)
            const reponse = await getAllProducts();
            setProductList(reponse)
        } finally {
            setIsLoading(false)
        }

    }, [getAllProducts]);

    useEffect(() => {
        getProductsList();
    }, [getProductsList])



    const changeProductHandle = useCallback(async (ev, value: IProduct | null) => {
        if (value) {
            try {
                setIsLoadingDetail(true)
                setSelectedProduct(value)
                const response = await getProductDetail(value.id)
                setProductDetail(response);
            } finally {
                setIsLoadingDetail(false)
            }
        }

    }, [getProductDetail])



    // const changeProductHandle = useCallback((products: IProduct[]) => {
    //     if (values.category) {
    //         onSubmit(values.category, products)
    //     }
    // }, [onSubmit, values.category])


    const changeCompositionHandle = useCallback((ev, id: string) => {
        const _value = productDetail?.complements.find(item => item.complementCategory.categoryId === id);
        if (_value) {
            setSelectedComplement?.(_value)
        }
    }, [productDetail?.complements]);

    const onSubmitHandle = useCallback(() => {

        if (selectedComplement) {
            onSubmit?.(selectedComplement)
        }
    }, [onSubmit, selectedComplement])

    const list = useMemo(() => <div>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedComplement?.complementCategory.categoryId}
            onChange={changeCompositionHandle}
        >
            {!productDetail?.complements.length && 'Nenhum complemento no produto selecionado'}
            {productDetail?.complements.map(item => (<FormControlLabel
                value={item.complementCategory.categoryId} control={<Radio />}
                label={item.complementCategory.description}
            />))}
        </RadioGroup>
    </div>, [changeCompositionHandle, productDetail?.complements, selectedComplement])

    return (
        <div id={styles.ExistComplementForm} >
            <div className={styles.container} >
                <Column>
                    {/* <ContainerLabel>
                        <Autocomplete
                            fullWidth
                            renderInput={(params) => <InputLabel label={"Selecione uma categoria:"} {...params} />}
                            options={categories ?? []}
                            getOptionLabel={(option) => option.description}
                            value={selectedCategory}
                            onChange={changeCategoryHandle}
                            size='small'
                        />
                    </ContainerLabel> */} 
                    <ContainerLabel flex={0}>
                        <Autocomplete
                            fullWidth
                            renderInput={(params) =>
                                <InputLabel 
                                    label={"Selecione um produto:"} {...params}
                                    placeholder={isLoading ? 'Carregando produtos ...' : 'Selecionar produto'}
                                    disabled={isLoading}
                                />
                            }
                            options={productList ?? []}
                            getOptionLabel={(option) => option.name}
                            value={selectedProduct}
                            onChange={changeProductHandle}
                            // size='small'
                        />
                    </ContainerLabel>

                    {/* <SelectProductPicker products={productList}
                        onSubmit={changeProductHandle} onClose={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                    /> */}
                    {isLoadingDetail ?
                        <>
                            <div style={{ display: 'flex', paddingTop: 16, gap: 8, flex: 1 }}>
                                <Skeleton variant='circular' width={20} height={20} />
                                <Skeleton variant='rectangular' height={20} style={{ display: 'flex', flex: 1 }} />
                            </div>
                            <div style={{ display: 'flex', gap: 8, flex: 1 }}>

                                <Skeleton variant='circular' width={20} height={20} />
                                <Skeleton variant='rectangular' height={20} style={{ display: 'flex', flex: 1 }} />
                            </div>
                            <div style={{ display: 'flex', gap: 8, flex: 1 }}>

                                <Skeleton variant='circular' width={20} height={20} />
                                <Skeleton variant='rectangular' height={20} style={{ display: 'flex', flex: 1 }} />
                            </div>
                        </>
                        :
                        list
                    }
                </Column>
                <ButtonsForm onCancel={onClose} onSubmit={onSubmitHandle} color='primary' width='100px'></ButtonsForm>
            </div>
        </div>
    )
}
export default ExistComplementForm