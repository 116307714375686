// BalanceForm.tsx
import React from "react";
import styles from "./BalanceForm.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";

interface Column<T> {
  key: keyof T;
  label: string;
}

interface TableProps<T> {
  columns: Column<T>[];
  data: T[] | undefined;
}

const BalanceForm = <T,>({ columns, data }: TableProps<T>) => {
  return (
    <div id={styles.BalanceForm}>
      <div className={styles.table}>
        <div className={styles.headerRow}>
          {columns.map((column) => (
            <div key={String(column.key)} className={styles.headerCell}>
              {column.label}
            </div>
          ))}
        </div>
        {data?.map((row, index) => (
          <div key={index} className={styles.row}>
            {columns.map((column) => {
              const cellValue = row[column.key as keyof T];
              return (
                <div key={String(column.key)} className={styles.cell}>
                  {column.key === "Type" && (row as any)["Type"] === 1
                    ? "Retenção por equipamento"
                    : column.key === "Type" && (row as any)["Type"] === 2
                    ? "Retenção por consignação"
                    : (column.key === "valorTotal" ||
                        column.key === "valorPago" ||
                        column.key === "Value") &&
                      typeof cellValue === "number"
                    ? moneyMaskNumber(cellValue)
                    : (column.key === "vencimento" || column.key === "Date") &&
                      typeof cellValue === "string"
                    ? Utils.toDate(cellValue)
                    : cellValue}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BalanceForm;
