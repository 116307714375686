import { FC, useCallback, useEffect, useMemo, useState } from "react";
import AccordionColaborador from "../components/accordion/AccordionColaborador";
import SidesheetCollaborator from "../components/sidesheetCollaborator/SidesheetCollaborator";
import Search from '../components/search/Search'
import Button from "components/ui/Button/Button";
import { AddCircle } from '@mui/icons-material'
import styles from './Colaboradores.module.scss'
import UseRolesList from "modules/cargos/presentation/hooks/UseRolesList";
import { MenuItem, TextField } from "@material-ui/core";
import { Pagination } from "@mui/material";
import { IEmployeesList } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresResponse";
import { IDeleteCollaboratorParams } from "modules/colaboradores/domain/dto/IDeleteCollaboratorParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IGetColaboradoresSearchParams } from "modules/colaboradores/domain/dto/IGetColaboradoresSearchParams";
import UseColaboradoresPage from "../hooks/UseColaboradoresPage";
import UseDimension from "components/dimension/UseDimension";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import SimpleTrialModal from "modules/plans/presentation/components/simpleTrialModal/SimpleTrialModal";

const ColaboradoresPage: FC = () => {
  const [openSidesheet, setOpenSidesheet] = useState<
    "isNew" | "isEditing" | "isDeleting" | null
  >(null);
  const [searchParams, setSearchParams] = useState<IGetColaboradoresSearchParams>({} as IGetColaboradoresSearchParams);
  const [pageSize, setPageSize] = useState<string>("10");
  const [expandedAccordion, setExpandedAccordion] = useState<string>("");
  const [editingItem, setEditingItem] = useState<IEmployeesList & { currentProfileId: string }>();
  const [deleteItem, setDeleteItem] = useState<IDeleteCollaboratorParams>();
  const [collaborators, setCollaborators] = useState<IEmployeesList[]>([]);
  const [cargoChecked, setCargoChecked] = useState<string | undefined>();
  const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);

  const { currentLocal } = useLocal();
  const { dimensions } = UseDimension();
  const { subscription } = usePlans();
  const { getRegisteredRolesList, currentPage: currentRolesPage, totalPages, registeredRolesList, nextPage, setCurrentPage } = UseRolesList();
  const {
    colaboradoresList,
    getCollaboratorsList,
    isLoading,
    currentPage: currentEmploeesPage,
    totalPages: totalEmploeesPages,
    nextCollaboratorPage,
    localDocument,
    getLocalMainData
  } = UseColaboradoresPage();

  useEffect(() => {
    if (currentLocal) {
      const params = {
        ownerId: currentLocal.id,
        employeeEmail: searchParams.employeeEmail,
        employeeName: searchParams.employeeName,
        profileIds: searchParams.profileId,
      };
      getRegisteredRolesList(params, currentRolesPage, Number(pageSize));
    }
  }, [
    currentLocal,
    searchParams,
    currentRolesPage,
    pageSize,
    getRegisteredRolesList,
  ]);

  const handleUpdateList = useCallback((profileId?: string) => {
    if (currentLocal && profileId) {
      const params = {
        ownerId: currentLocal.id,
        employeeEmail: searchParams.employeeEmail,
        employeeName: searchParams.employeeName,
        profileId: profileId,
      };
      getCollaboratorsList(0, params, 10);
    }
  }, [currentLocal, getCollaboratorsList, searchParams]);

  const rolesList = useMemo(() => registeredRolesList ?? [], [registeredRolesList]);

  const handleOnCloseSidesheet = useCallback((profileId?: string) => {
    handleUpdateList(profileId);
    setOpenSidesheet(null);
  }, [handleUpdateList]);

  useEffect(() => {
    if (currentLocal && expandedAccordion) {
      const params = {
        ownerId: currentLocal.id,
        employeeEmail: searchParams.employeeEmail,
        employeeName: searchParams.employeeName,
        profileId: expandedAccordion,
      };
      getCollaboratorsList(currentEmploeesPage, params, 10);
    }
  }, [expandedAccordion]);

  useEffect(() => {
    if (subscription?.isTrial) {
      getLocalMainData();
    }
  }, [getLocalMainData, subscription]);

  useEffect(() => {
    setCollaborators(colaboradoresList ?? []);
  }, [colaboradoresList]);

  const loadMore = (page: number) => {
    nextCollaboratorPage({
      employeeEmail: searchParams.employeeEmail,
      employeeName: searchParams.employeeName,
      profileId: expandedAccordion,
    }, page, 10);
  };

  return (
    <div id={styles.Colaboradores}>
      <div>
        <h1>Colaboradores</h1>
        <p style={{ fontSize: dimensions.width > 700 ? "16px" : "14px" }}>
          Cadastre todos os colaboradores do seu estabelecimento e delimite suas permissões.
        </p>
      </div>
      <Button
        onClick={() => {
          if (subscription?.isTrial && localDocument === "28302604000101") {
            return setOpenSimpleTrialModal(true);
          }
          setOpenSidesheet("isNew");
        }}
        className={styles.btnAdd}
        endIcon={<AddCircle />}
      >
        Adicionar Colaborador
      </Button>
      <Search
        setSearchParams={setSearchParams}
        setExpandedAccordions={setExpandedAccordion}
        setCurrentPage={setCurrentPage}
      />
      <div>
        {rolesList.length > 0
          ? (
            <>
              {rolesList.map((item) => {
                return (
                  <AccordionColaborador
                    key={item.Id}
                    role={item}
                    setOpenSidesheet={setOpenSidesheet}
                    setEditingItem={setEditingItem}
                    setDeleteItem={setDeleteItem}
                    expandedAccordion={expandedAccordion}
                    setExpandedAccordion={setExpandedAccordion}
                    collaborators={collaborators}
                    isLoading={isLoading}
                    currentEmploeesPage={currentEmploeesPage}
                    totalEmploeesPages={totalEmploeesPages}
                    loadMore={loadMore}
                    setCargoChecked={setCargoChecked}
                    subscription={subscription}
                    localDocument={localDocument}
                    setOpenSimpleTrialModal={setOpenSimpleTrialModal}
                  />
                );
              })}
              {!!totalPages && (
                <div className={styles.conteinerPagination}>
                  <div className={styles.boxMultiplePage}>
                    <span>Exibir</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      select
                      value={pageSize}
                      defaultValue={pageSize}
                      onChange={(ev) => setPageSize(ev.target.value)}
                    >
                      <MenuItem className={styles.textPage} value={10}>
                        10
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={20}>
                        20
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={50}>
                        50
                      </MenuItem>
                    </TextField>
                  </div>
                  <Pagination
                    variant="outlined"
                    className={styles.paginationNumber}
                    shape="rounded"
                    sx={{
                      ".MuiPaginationItem-root": {
                        borderColor: "#BDBDBD",
                        color: "#001537",
                      },
                      ".Mui-selected": {
                        fontWeight: "bold",
                        borderColor: "#001537",
                      },
                    }}
                    count={totalPages}
                    page={currentRolesPage}
                    onChange={(_, value) => {
                      nextPage({
                        ownerId: currentLocal?.id,
                        employeeEmail: searchParams.employeeEmail,
                        employeeName: searchParams.employeeName,
                        profileIds: searchParams.profileId,
                      }, value, Number(pageSize));
                    }}
                  />
                </div>
              )}
            </>
          )
          : (
            <div className={styles.emptyList}>
              <img src="/assets/img/empty-box.png" alt="" />
              {searchParams
                ? <span>Colaborador não encontrado.</span>
                : <span>Você ainda <b>não possui</b> colaboradores!</span>
              }
            </div>
          )}
      </div>

      {openSidesheet && (
        <SidesheetCollaborator
          open={openSidesheet}
          handleOnCloseSidesheet={handleOnCloseSidesheet}
          editingItem={editingItem}
          deleteItem={deleteItem}
          setExpandedAccordions={setExpandedAccordion}
          cargoChecked={cargoChecked}
        />
      )}

      <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />
    </div>
  );
};
export default ColaboradoresPage;
