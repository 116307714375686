import styles from "./MeepTicketsTableMobile.module.scss";
import { format } from "date-fns";
import { IGetOrganizerEventDataContent } from "modules/meepTickets/domain/dto/IGetOrganizerEventResponse";
import { handleStatus } from "../meepTicketsTable/MeepTicketsTable";

export interface IMeepTicketsTableMobileProps {
  data: IGetOrganizerEventDataContent[];
}

export const ticketsTitleHeaderMobile = ["Início", "Fim", "Local", "Status"];

const MeepTicketsTableMobile = ({ data }: IMeepTicketsTableMobileProps) => {
  return (
    <>
      <div id={styles.MeepTicketsTableMobile}>
        <div
          className={styles.container}
        >
          {data.map((item) => (
              <div
                key={item.id}
                className={styles.gridContent}
              >
                <div className={styles.gridTable}>
                  <span>{item.name}</span>
                  {ticketsTitleHeaderMobile.map((title) => (
                    <span key={title} style={{ color: "rgba(51, 51, 51, 1)" }}>
                      <b>{title}:</b>
                    </span>
                  ))}
                </div>
                <div className={styles.gridTable} style={{ textAlign: "end" }}>
                  <div
                    className={`${styles.container} ${styles.last}`}
                    onClick={() =>
                      window.open(`https://produtor.meep.com.br/evento/${item.id}/dashboard`, "_blank")
                    }
                  >
                    <img src="/assets/icon/open-in-new-icon.svg" alt=""/>
                  </div>
                  <span>
                  {format(new Date(item.startDate), "dd/MM/yyyy' 'HH':'mm")}
                  </span>
                  <span>
                  {format(new Date(item.endDate), "dd/MM/yyyy' 'HH':'mm")}
                  </span>
                  <span>
                    {item.eventsLocation?.cityNameLong} - {item.eventsLocation?.stateNameLong}
                  </span>
                  <span>
                    {handleStatus(item.status)}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default MeepTicketsTableMobile;
