import { UseAutomationForm } from "./UseAutomationForm";
import styles from './AutomationForm.module.scss';
import { Close } from '@material-ui/icons'
import { IAutomationFormStep } from "modules/automation/domain/interfaces/IAutomationFormStep";
import { TriggerForm } from "./TriggerForm/TriggerForm";
import TabView from "../tab/TabView";
import { ToolForm } from "./ToolForm/ToolForm";
import { TrackConfig } from "./TrackConfig/TrackConfig";
import { Icon, IconButton } from "@material-ui/core";
import { Success } from "./Success/Success";
import { IAutomationItem } from "../../interfaces/IAutomation";
import { DetailsForm } from "./DetailsForm/DetailsForm";

interface AutomationFormProps {
    closeStepForm: () => void
    getData: (params?: { pagination?: { page?: number, pageSize?: number } }) => void
    selectedItem?: IAutomationItem
}

export const AutomationForm = ({ closeStepForm, getData, selectedItem }: AutomationFormProps) => {
    const {
        formStep,
        form,
        surveys,
        loading,
        onChangeFormStep,
        handleBackButton,
        onChangeForm,
        handleNextButtonClick
    } = UseAutomationForm(closeStepForm, getData, selectedItem);

    return (
        <div id={styles.AutomationForm} >
            <div className={styles.header} >
                <div className={styles.containerTitle}>
                    {
                        (formStep !== IAutomationFormStep.DETAILS && formStep !== IAutomationFormStep.TOOL && formStep !== IAutomationFormStep.SUCCESS) &&
                        <IconButton onClick={handleBackButton} style={{ padding: 4, marginRight: 12 }}><Icon>navigate_before</Icon></IconButton>
                    }
                    {
                        (formStep === IAutomationFormStep.TRACK) ?
                            <h2><strong>Track.co</strong></h2> :
                            <h2>Nova <strong>automação</strong></h2>
                    }
                    <IconButton onClick={handleBackButton} style={{ padding: 4, marginRight: 12, marginLeft: 'auto' }}>
                        <Close onClick={closeStepForm} />
                    </IconButton>
                </div>

                {
                    (formStep === IAutomationFormStep.DETAILS || formStep === IAutomationFormStep.TRIGGER || formStep === IAutomationFormStep.TOOL) &&
                    <TabView
                        index={formStep}
                        scrollButton="on"
                        tabs={
                            [
                                { label: 'Detalhes', value: IAutomationFormStep.DETAILS },
                                { label: 'Gatilho', value: IAutomationFormStep.TRIGGER },
                                { label: 'Ferramenta', value: IAutomationFormStep.TOOL },
                            ]
                        }
                        onChangeTab={(tab, value) => onChangeFormStep(tab.value)}
                    />
                }
            </div>

            <div className={styles.container}>
                {
                    formStep === IAutomationFormStep.DETAILS &&
                    <DetailsForm form={form} onChange={onChangeForm} />
                }

                {
                    formStep === IAutomationFormStep.TRIGGER &&
                    <TriggerForm form={form} onChange={onChangeForm} />
                }

                {
                    formStep === IAutomationFormStep.TOOL &&
                    <ToolForm onClickCard={onChangeFormStep} />
                }

                {
                    formStep === IAutomationFormStep.TRACK &&
                    <TrackConfig form={form} onChange={onChangeForm} surveys={surveys} />
                }

                {
                    formStep === IAutomationFormStep.SUCCESS &&
                    <Success />
                }
            </div>

            <footer>
                {
                    formStep !== IAutomationFormStep.SUCCESS &&
                    <button onClick={handleBackButton} className={styles.cancel}>
                        {
                            formStep === IAutomationFormStep.TRIGGER ?
                                'Cancelar' : 'Voltar'
                        }
                    </button>
                }
                {
                    formStep !== IAutomationFormStep.TOOL &&
                    <button onClick={handleNextButtonClick} className={styles.submit} disabled={loading}>
                        {
                            formStep === IAutomationFormStep.TRACK ?
                                (loading ? 'Salvando...' : 'Salvar') :
                                formStep === IAutomationFormStep.SUCCESS ?
                                    'Fechar' :
                                    'Continuar'
                        }
                    </button>
                }
            </footer>
        </div>
    )
}