
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import styles from './ProductComplementItemForm.module.scss'
import { IComplementProductFormValue } from '../../interfaces/IComplementProductFormValue';
import { InputNumber } from '../../../ui/InputNumber';
import { Separator } from '../../../ui/Separator';
import { FormControlLabel, Icon, IconButton, Radio, RadioGroup, Button } from '@material-ui/core';
import { InputLabel } from '../../../ui/InputLabel';
import Row from '../../../ui/Row';
import { InputMoney } from '../../../ui/InputMoney';
import { InputImage } from '../../../ui/InputImage';
import Column from '../../../ui/Column';
import { CompositionProductType } from 'modules/catalog/domain/enums/CatalogProductEnums';
import InputMasked from 'components/inputMasked/InputMasked';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { SwitchLabel } from '../../../ui/SwitchLabel';
export interface IProductComplementItemFormProps {
    //propertys
    disabledClose?: boolean
    complementProductItem: IComplementProductFormValue;
    disabledEditProduct?: boolean;
    onChange: (complementProductItem: IComplementProductFormValue) => void
    onRemove: (complementProductItem: IComplementProductFormValue) => void
    error?: IproductComplementError
}

export interface IproductComplementError {
    name?: string;
    value?: string;
    type?: string;
    ncm?: string;
    cfop?: string;
}
const ProductComplementItemForm: FC<IProductComplementItemFormProps> = ({
    complementProductItem,
    onChange,
    onRemove,
    disabledEditProduct,
    disabledClose,
    error
}) => {
    const [hasFreeQuantity, setHasFreeQuantity] = useState(false);
    const [hasInitialQuantity, setHasInitialQuantity] = useState(false);
    const [hideAdvancedProperty, setHideAdvancedProperty] = useState(true);

    useEffect(() => {
        setHasFreeQuantity((complementProductItem.freeQuantity ?? 0) > 0);
    }, [complementProductItem.freeQuantity]);
    
    useEffect(() => {
        setHasInitialQuantity((complementProductItem.initialQuantity ?? 0) > 0);
    }, [complementProductItem.initialQuantity]);

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange(({ ...complementProductItem, [ev.target.name]: ev.target.value }));
    }, [complementProductItem, onChange]);

    const changeHandleSwitch = useCallback((name, ev: ChangeEvent<HTMLInputElement>) => {
        onChange(({ ...complementProductItem, [name]: ev.target.checked }));
    }, [complementProductItem, onChange]);

    const changeValueHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange(({ ...complementProductItem, [ev.target.name]: Number(ev.target.value) }));
    }, [complementProductItem, onChange]);

    const onChangeMaxHandle = useCallback((ev) => {
        onChange(({ ...complementProductItem, max: Number(ev.target.value) }));
    }, [complementProductItem, onChange]);

    const onChangeImageHandle = useCallback((value: string) => {
        onChange(({ ...complementProductItem, image: value }));
    }, [complementProductItem, onChange]);

    const onChangeMinHandle = useCallback((ev, value: number) => {
        var _value = value;
        if (value < 0) {
            _value = 0;
        }
        onChange(({ ...complementProductItem, min: _value }));
    }, [complementProductItem, onChange]);

    const onChangeQuantityHandle = useCallback((ev, value: number) => {
        var _value = value;

        if (value < 1) {
            _value = 1;
        }

        onChange(({ ...complementProductItem, quantity: _value }));
    }, [complementProductItem, onChange]);

    const onChangeHasFreeQuantityRequired = useCallback((_, value: string) => {
        const isFree = value === "1";
        onChange({
            ...complementProductItem,
            freeQuantity: isFree ? 1 : 0,
            isfree: isFree,
        });
        setHasFreeQuantity(isFree);        
    }, [complementProductItem, onChange])

    const onChangeHasInicialQuantityRequired = useCallback((_, value: string) => {
        if (value === "1") {
            onChange(({ ...complementProductItem, initialQuantity: 1 }));

            setHasInitialQuantity(true)
        } else {
            onChange(({ ...complementProductItem, initialQuantity: 0 }));
            setHasInitialQuantity(false);
        }
    }, [complementProductItem, onChange])

    const onChangeFreeQuantity = useCallback((ev,) => {
        onChange(({ ...complementProductItem, freeQuantity: Number(ev.target.value) }));
    }, [complementProductItem, onChange]);

    const onChangeInitialQuantity = useCallback((ev,) => {
        const quantity = Number(ev.target.value);
        onChange(({ ...complementProductItem, initialQuantity: quantity }));
        setHasInitialQuantity(quantity === 0);
    }, [complementProductItem, onChange]);

    const onRemoveHandle = useCallback(
        () => {
            onRemove(complementProductItem)
        },
        [complementProductItem, onRemove],
    )

    const onChangeComplementProductType = useCallback((ev, value: string) => {
        onChange(({ ...complementProductItem, type: Number(value) as CompositionProductType }));
    }, [complementProductItem, onChange])

    return (
        <div id={styles.ProductComplementItemForm} >
            <div className={styles.container} >
                {!disabledClose && <IconButton className={styles.close} size='small' onClick={onRemoveHandle}><Icon>close</Icon></IconButton>}
                <div className={styles.headerInputs}>
                    <Row style={{ gap: "1rem" }}>
                        <InputImage style={{ opacity: disabledEditProduct ? 0.5 : 1 }} disabled value={complementProductItem.imageUrl} onChange={onChangeImageHandle} size={134} />
                        <Column>
                            <InputLabel style={{ opacity: disabledEditProduct ? 0.5 : 1 }} disabled error={!!error?.name} helperText={error?.name} name={"name"} value={complementProductItem.name} label="Nome" onChange={changeHandle} gap={6} ></InputLabel>
                            <Row>
                                {/* <InputMoney name={"productValue"} value={complementProductItem.productValue} label="Valor Produto" onChange={changeValueHandle} ></InputMoney> */}
                                <InputMoney error={!!error?.value} helperText={error?.value} name={"value"} value={complementProductItem.value} label="Valor Complemento" onChange={changeValueHandle} gap={6}></InputMoney>
                            </Row>
                        </Column>
                    </Row>
                    <span>Dados fiscais</span>
                    <Row style={{ opacity: disabledEditProduct ? 0.5 : 1, alignItems: 'flex-start' }} >
                        <FormItemContainer label={'NCM'}>
                            <InputMasked error={!!error?.ncm} helperText={error?.ncm} disabled mask="9999.99.99" name={"ncm"} value={complementProductItem.ncm} onChange={changeHandle}></InputMasked>
                        </FormItemContainer>
                        <FormItemContainer label={'CEST'}>
                            <InputMasked disabled mask="99.999.99" name={"cest"} value={complementProductItem.cest} onChange={changeHandle} ></InputMasked>
                        </FormItemContainer>
                        <FormItemContainer label={'CFOP'}>
                            <InputMasked error={!!error?.cfop} helperText={error?.cfop} disabled mask="9.999" name={"cfop"} value={complementProductItem.cfop} onChange={changeHandle} ></InputMasked>
                        </FormItemContainer>
                    </Row>
                    {hideAdvancedProperty ? (
                        <Button
                            variant="text"
                            disabled={disabledEditProduct}
                            style={{ width: 200, padding: 4 }}
                            color="secondary"
                            endIcon={<KeyboardArrowDownRounded />}
                            onClick={() => setHideAdvancedProperty(false)}
                        >
                            Informações avançadas
                        </Button>
                    ) : (
                        <>
                            <Row>
                                <InputLabel
                                    disabled={disabledEditProduct}
                                    onChange={changeHandle}
                                    name={"internalCode"}
                                    value={complementProductItem.internalCode}
                                    label={"Código interno"}
                                />
                                <InputLabel
                                    disabled={disabledEditProduct}
                                    onChange={changeHandle}
                                    name="integrationCode"
                                    value={complementProductItem.integrationCode}
                                    label="Código de integração"
                                    type="number"
                                />

                            </Row>
                            <Row style={{ gap: '20px' }}>
                                <SwitchLabel
                                    disabled={disabledEditProduct}
                                    checked={complementProductItem.stockControl}
                                    label={"Gestão de estoque"}
                                    onChange={(e) => changeHandleSwitch('stockControl', e)} />
                                {complementProductItem.stockControl &&
                                    <InputLabel
                                        disabled={disabledEditProduct}
                                        onChange={changeHandle}
                                        name={"reservedQuantity"}
                                        value={complementProductItem.reservedQuantity}
                                        label={"Quantidade reservada"}
                                        type='number'
                                        style={{ width: '50%' }}
                                    />
                                }
                            </Row>
                            <Button
                                variant="text"
                                disabled={disabledEditProduct}
                                style={{ width: 200, padding: 4 }}
                                color="secondary"
                                endIcon={<KeyboardArrowUpRounded />}
                                onClick={() => setHideAdvancedProperty(true)}
                            >
                                Informações avançadas
                            </Button>
                        </>
                    )}

                </div>
                <div className={styles.column}>
                    <span><b>Quantas vezes</b> seu cliente pode selecionar esse produto?</span>
                    <RadioGroup
                        value={complementProductItem.type}
                        onChange={onChangeComplementProductType}
                    >
                        <FormControlLabel value={CompositionProductType.FIXO} control={<Radio />} label={<span className={styles.radioSpan}>Uma quantidade determinada</span>} style={{ height: "32px" }} />
                        <FormControlLabel value={CompositionProductType.VARIAVEL} control={<Radio />} label={<span className={styles.radioSpan}>Uma quantidade variada</span>} style={{ height: "32px" }} />
                    </RadioGroup>
                    {!!error?.type && <div className={styles.error}>{error?.type}</div>}
                    {complementProductItem.type === CompositionProductType.FIXO
                        ? <Row>
                            <InputNumber label={"Quantidade"} name="quantity" min={0} value={complementProductItem.quantity} onChange={onChangeQuantityHandle} styledSize noLabel></InputNumber>
                        </Row>
                        : <Row style={{ justifyContent: "space-around" }}>
                            <InputNumber label={"Mínimo"} name="min" min={0} value={complementProductItem.min} onChange={onChangeMinHandle} styledSize noLabel></InputNumber>
                            <InputNumber label={"Máximo"} name="max" min={complementProductItem.min} value={complementProductItem.max} onChange={onChangeMaxHandle} styledSize noLabel></InputNumber>
                        </Row>
                    }
                </div>
                <Separator />
                <div className={styles.column}>
                    <span>Seu cliente pode adicionar esse complemento <b>gratuitamente</b>?</span>
                    <RadioGroup
                        value={hasFreeQuantity ? 1 : 0}
                        onChange={onChangeHasFreeQuantityRequired}
                    >
                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.radioSpan}>Não</span>} style={{ height: "32px" }} />
                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.radioSpan}>Sim</span>} style={{ height: "32px" }} />
                    </RadioGroup>
                    {!!hasFreeQuantity && (
                        <div className={styles.row}>
                            <InputNumber min={1} disabled={!hasFreeQuantity} max={complementProductItem.max} name="freeQuantity" value={complementProductItem.freeQuantity} onChange={onChangeFreeQuantity} styledSize noLabel></InputNumber>
                            <span>Máximo de vezes que pode ser adicionado gratuitamente.</span>
                        </div>
                    )}
                </div>
                <Separator />
                <div className={styles.column}>
                    <span>Este complemento terá <b>quantidade inicial</b>?</span>
                    <RadioGroup
                        value={hasInitialQuantity ? 1 : 0}
                        onChange={onChangeHasInicialQuantityRequired}
                    >
                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.radioSpan}>Não</span>} style={{ height: "32px" }} />
                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.radioSpan}>Sim</span>} style={{ height: "32px" }} />
                    </RadioGroup>
                    {!!hasInitialQuantity && (
                        <div className={styles.row}>
                            <InputNumber min={0} disabled={!hasInitialQuantity} name="initialQuantity" value={complementProductItem.initialQuantity} onChange={onChangeInitialQuantity} styledSize noLabel></InputNumber>
                            <span>Quantidade que virá selecionada assim que o produto principal for selecionado.</span>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
export default ProductComplementItemForm
