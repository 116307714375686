import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import DigitalAccountApi from "services/api/digitalAccount/DigitalAccountApi";
import GetNextAntecipate from "modules/contaDigital/application/useCases/GetNextAntecipateUseCase";
import { useParams } from "react-router-dom";
import { NextAntecipateResponse } from "modules/contaDigital/models/dtos/NextAntecipateResponse";

export const UseAntecipatePage = () => {
  const accountService = DigitalAccountApi();
  const { currentLocal } = useLocal();
  const [listNextAntecipate, setListNextAntecipate] = useState<NextAntecipateResponse | undefined>();
  const { account: accountId } = useParams<{ account: string }>();

  const getNextAntecipate = useCallback(async () => {
    if (currentLocal) {
      const response = await GetNextAntecipate(
        accountService,
        currentLocal?.id,
        accountId
      );
      setListNextAntecipate(response);
    }
  }, [currentLocal]);

  useEffect(() => {
    getNextAntecipate();
  }, [currentLocal]);

  return {
    listNextAntecipate,
  };
};
