import { IGetPaymentLinkDetailsListResponse } from "modules/paymentLinkReport/domain/dto/IGetPaymentLinkDetailsListResponse";
import { api } from "../Api";
import { ISort } from "interfaces/ISort";
import { IGetPaymentLinkRecurrenceListResponse } from "modules/paymentLinkReport/domain/dto/IGetPaymentLinkRecurrenceListResponse";
import { IGetPaymentLinkRecurrenceParams } from "modules/paymentLinkReport/domain/dto/IGetPaymentLinkRecurrenceParams";
import { IGetProductsListItemResponse } from "modules/paymentLinkReport/domain/dto/IGetProductsListResponse";
import { IPaymentLinkRecurrenceApiService } from "modules/paymentLinkReport/domain/interfaces/IPaymentLinkRecurrenceApiService";

const PaymentLinkRecurrenceApi = (): IPaymentLinkRecurrenceApiService => {
  const getRecurrenceList = async (params?: IGetPaymentLinkRecurrenceParams, sort?: ISort): Promise<IGetPaymentLinkRecurrenceListResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }
    // const response = await api.get(`https://fa98159258eb.ngrok.app/Promptus.Meep.Server/api/mepaylink/recurrence-report`, {params: data});
    const response = await api.get("/mepaylink/recurrence-report", { params: data });
    return response.data;
  };

  const getAllProducts = async (localId: string): Promise<IGetProductsListItemResponse[]> => {
    var response = await api.get<{id: string, nome: string}[]>("Produto/BuscarTodosProdutosDoLocal/" + localId);

    return response.data.map<IGetProductsListItemResponse>(item => ({
        id: item.id,
        name: item.nome,    
    }));
  }

  const cancelPaymentLinkRecurrence = async (recurrenceId: string): Promise<any> => {
  
    const response = await api.delete<void>(`mepaylink/recurrence-report/${recurrenceId}`);
    return response.data
  }

  const getDetails = async (recurrenceId: string, localId: string): Promise<IGetPaymentLinkDetailsListResponse> => {

    const params = {
      localId: localId
    }

    const response = await api.get(`/mepaylink/recurrence-report/${recurrenceId}`, {params: params});
    // const response = await api.get(`https://fa98159258eb.ngrok.app/Promptus.Meep.Server/api/mepaylink/recurrence-report/${recurrenceId}`, {params: params});
    return response.data;
  };



  return { getRecurrenceList, getAllProducts, cancelPaymentLinkRecurrence, getDetails };
}

export default PaymentLinkRecurrenceApi;
