
import DeleteRole from "./deleteRole/DeleteRole";
import Sidesheet from "components/sidesheet/Sidesheet";
import { useCallback, useEffect, useState } from "react";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { IPostNewRoleRequest } from "modules/cargos/models/dtos/IPostNewRoleRequest";
import UseRolesList from "../../hooks/UseRolesList";
import { IProfiles } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import { IGetRegisteredRolesListParams } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams";

export interface ISidesheetRolesProps {
  open: "isNew" | "isEditing" | "isDeleting" | null;
  onCloseSideSheet: () => void;
  item?: IProfiles;
  params: IGetRegisteredRolesListParams;
}

export enum NewAndEditRoleStepEnum {
  name = 0,
  permissions = 1,
  success = 2,
}

const SidesheetRoles = ({ open, onCloseSideSheet, item, params }: ISidesheetRolesProps) => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState<IPostNewRoleRequest>(
    {} as IPostNewRoleRequest
  );

  const { deleteRole, isLoading, getRegisteredRolesList } = UseRolesList();
  const { currentLocal } = useLocal();
  const { user } = useUser();

  useEffect(() => {
    if (currentLocal) {
      if (item && open === "isEditing") {
        setValues({
          ...values,
          name: item.Name,
          permissionIds: item.PermissionIds,
          ownerId: currentLocal.id
        });
      } else {
        setValues({ ownerId: currentLocal.id } as IPostNewRoleRequest);
      }
    }
  }, []);

  const handleContinueDelete = useCallback(async () => {
    if (item && user) {
      const res = await deleteRole(item.Id);
      if (res === "ok") {
        setValues({} as IPostNewRoleRequest);
        getRegisteredRolesList({ ...params, ownerId: currentLocal?.id }, 1);
        return setStep(step + 1);
      }
    }
  }, [step, item, deleteRole, user, currentLocal, getRegisteredRolesList, params]);

  const onClose = () => {
    onCloseSideSheet()
    setStep(1)
  }

  return (
    <>
      {open === "isDeleting" && (
        <Sidesheet
          isLoading={isLoading}
          open={open}
          onClose={onClose}
          title={
            <h2>
              Excluir <b>cargo</b>
            </h2>
          }
          content={<DeleteRole />}
          currentStep={step}
          totalSteps={2}
          continueButton="Excluir"
          handleContinueButton={handleContinueDelete}
          cancelButton
          feedback={
            <SidesheetFeedback text="Cargo excluído com sucesso!" success />
          }
        />
      )}
    </>
  );
};

export default SidesheetRoles;
