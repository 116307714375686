import { Icon } from '@material-ui/core';
import styles from './Plans.module.scss';
import { useHistory } from 'react-router-dom';
import { PremiumModal } from '../../components/premiumModal/PremiumModal';
import { useState } from 'react';
import { UsePlans } from './UsePlans';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '@mui/material';
import { DowngradeRequestModal } from '../../components/downgradeRequestModal/DowngradeRequestModal';
import { DowngradeRequestSuccessModal } from '../../components/downgradeRequestSuccessModal/DowngradeRequestSuccessModal';
import { format } from 'date-fns';
import { DowngradePermissionModal } from '../../components/downgradePermissionModal/DowngradePermissionModal';

export const PlansPage = () => {
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);

    const {
        isPremium,
        premiumPlan,
        loading,
        downgradeRequest,
        downgradeRequestModalOpened,
        downgradeRequestSuccessModalOpened,
        saving,
        downgradePermissionModalOpened,
        setDowngradePermissionModalOpened,
        setDowngradeRequestModalOpened,
        setDowngradeRequestSuccessModalOpened,
        requestDowngrade
    } = UsePlans();

    const skeletons = Array.from({ length: 4 }, (_, index) => (
        <Skeleton
            key={index}
            variant="text"
            sx={{ fontSize: '40px', marginBottom: "10px", backgroundColor: "#ededed" }}
            width="100%"
        />
    ));

    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <div>
                    <div>
                        <Skeleton width="100%" height="150px" sx={{ backgroundColor: "#ededed" }} />
                        <Skeleton width="100%" height="150px" sx={{ backgroundColor: "#ededed" }} />
                    </div>
                    {skeletons}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Meu <b>plano</b></h1>
                <span className={styles.subtitle}>Informações sobre o plano contratado para utilização do Portal Meep.</span>

                {
                    !!downgradeRequest &&
                    <div className={styles.downgradeRequestContainer}>
                        <Icon className={styles.downgradeIcon}>access_time_outlined</Icon>
                        <span className={styles.downgradeText}>
                            Você solicitou o cancelamento do {downgradeRequest.planName} em {format(new Date(downgradeRequest.solicitationDate + 'Z'), 'dd/MM/yyyy HH:mm')}. <b>Aguarde o e-mail de confirmação!</b>
                        </span>
                    </div>
                }

                <div className={styles.plansContainer}>
                    {
                        loading ?
                            <>
                                <Skeleton variant="rectangular" height="235px" style={{ borderRadius: 8, flex: 1 }} />
                                <Skeleton variant="rectangular" height="235px" style={{ borderRadius: 8, flex: 1 }} />
                            </> :
                            <>
                                <div className={!isPremium ? styles.current : ''}>
                                    <span className={styles.planTitle}>Plano <b>Básico</b></span>

                                    <p>
                                        {
                                            !isPremium ?
                                                // "Você está usando o Plano Básico com funções limitadas. Mas o Portal Meep pode te oferecer muito mais! Faça o upgrade para aproveitar todas as funcionalidades disponíveis e tornar sua experiência ainda mais incrível!" :
                                                <>Você está usando o Plano Básico com funções limitadas, mas o Portal Meep pode te oferecer muito mais! <b>Seja um dos estabelecimentos piloto,</b> aproveite todas as funcionalidades disponíveis e tornar sua experiência ainda mais incrível!</> :
                                                "No Plano Básico não é possível usar as funcionalidades completas que garantem o sucesso da gestão do seu negócio no Portal Meep. Fazendo o downgrade você irá bloquear o uso das principais ferramentas estratégicas. "
                                        }
                                    </p>

                                    {/* {
                                        features.map(x => (
                                            <div className={styles.addonContainer}>
                                                <Icon style={{ fontSize: 15, fontWeight: 700 }}>add</Icon>
                                                <span>adicional de {x.purchaseDescription}</span>
                                            </div>
                                        ))
                                    } */}

                                    <button disabled={!!downgradeRequest} onClick={() => !isPremium ? history.push('/private/plans/history') : setDowngradeRequestModalOpened(true)}>
                                        <span className={styles.buttonText}>{isPremium ? 'Voltar para o básico' : 'Veja os detalhes'}</span>
                                        <Icon className={styles.buttonIcon}>chevron_right</Icon>
                                    </button>
                                </div>

                                <div className={isPremium ? styles.current : ''}>
                                    <div className={styles.contentPremium}>
                                        <div>
                                            <img src="/assets/icon/golden-crown.svg" alt="" />
                                            <span className={styles.planTitle}>Plano <b>Premium</b></span>
                                        </div>

                                        <p>
                                            {
                                                isPremium ?
                                                    "O seu Plano é perfeito para uma gestão completa do seu negócio. Com ele você garante mais dados, mais insights aprofundados e inúmeras maneiras de se conectar com o seu cliente." :
                                                    <>Pronto para impulsionar o seu negócio como nunca antes? Experimente o <b>Plano Premium da Meep</b> por 1 mês e desbloqueie uma série de ferramentas estratégicas essenciais que transformarão sua jornada empresarial.</>
                                            }
                                        </p>

                                        {/* {
                                        features.map(x => (
                                            <div className={styles.addonContainer}>
                                                <Icon style={{ fontSize: 15, fontWeight: 700 }}>add</Icon>
                                                <span>adicional de {x.purchaseDescription}</span>
                                            </div>
                                        ))
                                    } */}

                                        <button onClick={() => history.push(isPremium ? '/private/plans/history' : `/private/plans/purchase/${premiumPlan.id}`)}>
                                            <span className={styles.buttonText}>{isPremium ? 'Veja os detalhes' : 'Experimentar por 1 mês'}</span>
                                            <Icon className={styles.buttonIcon}>chevron_right</Icon>
                                        </button>
                                    </div>

                                    <div className={styles.tagPiloto}>
                                        {/* <img src="/assets/icon/golden-crown.svg" alt="" /> */}
                                        <span>Versão <br /><b>beta</b></span>
                                    </div>
                                </div>
                            </>
                    }
                </div>

                {/* <div className={styles.plus}>
                    <Icon className={styles.plusIcon}>add</Icon>
                    <span className={styles.plusText}>Aumente a quantidade de envio de pushs e e-mails</span>
                    <button>
                        <Icon style={{ fontSize: 15, fontWeight: 700 }}>add</Icon>
                        50.000 envios
                    </button>
                </div> */}
            </div>

            <div className={styles.upgradeContainer}>
                <div>
                    {
                        !isPremium ?
                            <h1>Por que vale a pena <span className={styles.highlight}>ser Premium?</span></h1> :
                            // <h1>Vantagens de ser um piloto do <b>Plano Premium</b></h1> :
                            <h1>Vantagens do seu <span className={styles.highlight}>Plano atual?</span></h1>
                    }
                    <span className={styles.subtitle}>Na versão Beta, você desfruta de ferramentas estratégicas essenciais para impulsionar o seu negócio:</span>

                    <div className={styles.benefitsContainer}>
                        <div>
                            <img src="/assets/icon/plans/crm.svg" alt="" />
                            <span>CRM</span>

                            <p><b>Informações centralizadas</b> do seu cliente de forma detalhada</p>
                            <p>Entenda o <b>perfil de consumo</b> do seu cliente a partir do histórico de visitas</p>
                            <p>Analise os dados obtidos a partir do CRM e <b>trace estratégias</b></p>
                        </div>
                        <div>
                            <img src="/assets/icon/plans/campaigns.svg" alt="" />
                            <span>Criação de campanhas</span>

                            <p>Crie campanhas <b>estratégicas direcionadas</b> ao público ideal</p>
                            <p>Estreite o relacionamento através de <b>comunicações personalizadas</b></p>
                            <p>Envie campanhas por <b>e-mail ou push</b>, por meio do App Meep</p>
                        </div>
                        <div>
                            <img src="/assets/icon/plans/fidelity.svg" alt="" />
                            <span>Programa de fidelidade</span>

                            <p>Atraia e retenha clientes com um programa de fidelidade <b>personalizado</b></p>
                            <p>Incentive a <b>recompra</b> dos seus clientes por meio de vantagens exclusivas</p>
                            <p>Aumente a <b>visibilidade</b> da sua marca com seu programa de fidelidade no App Meep</p>
                        </div>
                        <div>
                            <img src="/assets/icon/plans/email.svg" alt="" />
                            <span>Envio de e-mails</span>

                            <p><b>Construa o relacionamento</b> com seus clientes por meio de e-mails personalizados</p>
                            <p>Garanta um <b>relacionamento sólido</b> com comunicações relevantes para seu cliente</p>
                            <p><b>Compartilhe</b> promoções exclusivas e novidades de maneira rápida e eficaz</p>
                        </div>
                        <div>
                            <img src="/assets/icon/plans/push.svg" alt="" />
                            <span>Envio de push</span>

                            <p>Envie <b>pushs personalizados</b> para clientes específicos por meio do App Meep</p>
                            <p>Alcance seu cliente de forma rápida e assertiva <b>diretamente no telefone</b></p>
                            <p>Utilize pushs como <b>lembretes estratégicos</b> aos seus clientes</p>
                        </div>
                        <div>
                            <img src="/assets/icon/plans/survey.svg" alt="" />
                            <span>Pesquisa de satisfação</span>

                            <p>Envie pesquisas de acordo com <b>gatilhos específicos</b>, de forma automatizada</p>
                            <p>Obtenha <b>insights diretos dos clientes</b> e compreenda suas opiniões e necessidades</p>
                            <p>Acompanhe o <b>NPS</b> dos clientes que responderam sua pesquisa diretamente no <b>CRM</b></p>
                        </div>
                    </div>
                    {
                        !isPremium &&
                        <button onClick={() => setModalOpen(true)}>
                            Fazer upgrade
                        </button>
                    }
                </div>
            </div>

            <div className={styles.content}>
                <h1><b>Inovação estratégica</b> que vale a pena!</h1>
                <span className={styles.subtitle}>Veja como seu Plano Premium te faz vender mais e melhor</span>

                <div className={styles.comparisonContainer}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Básico</th>
                                <th>Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CRM completo</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Programa de fidelidade</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Criação de campanha</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Envio de push</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Envio de e-mails</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Pesquisa de satisfação</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Automação</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr>
                                <td>Integração Meep Tickets</td>
                                <td><Icon className={styles.close}>close</Icon></td>
                                <td><Icon className={styles.check}>check</Icon></td>
                            </tr>
                            <tr className={styles.footer}>
                                <td></td>
                                <td>Gratuito</td>
                                <td>
                                    <span className={styles.price}>R$ <span>{moneyMaskNumber(premiumPlan.price)}</span>/mês</span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {
                                        !isPremium && <button onClick={() => history.push('/private/plans/history')}>Plano atual</button>
                                    }
                                </td>
                                <td><button onClick={() => history.push(`/private/plans/${isPremium ? 'history' : `purchase/${premiumPlan.id}`}`)} className={styles.buy}>{!isPremium ? 'Contratar' : 'Plano atual'}</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* {
                !isPremium &&
                <div className={`${styles.pilotContainer} ${styles.content}`}>
                    <h1>Piloto <b>Plano Premium:</b></h1>

                    <p>Com o Plano Piloto Premium da Meep, você estará no controle total do crescimento do seu negócio. Maximizar o potencial, aprimorar a satisfação do cliente e alcançar resultados excepcionais é mais fácil do que nunca.</p>

                    <p><b>Inscreva-se</b> agora e seja um dos primeiros a experimentar as ferramentas que farão a diferença. Seu sucesso está a apenas um clique de distância!</p>

                    <button onClick={() => history.push(`/private/plans/purchase/${premiumPlan.id}`)}>Inscreva-se como piloto</button>
                </div>
            } */}

            <PremiumModal opened={modalOpen} onClose={() => setModalOpen(false)} />
            <DowngradeRequestModal loading={saving} open={downgradeRequestModalOpened} onClose={() => setDowngradeRequestModalOpened(false)} onSubmit={requestDowngrade} />
            <DowngradeRequestSuccessModal
                open={downgradeRequestSuccessModalOpened}
                onClose={() => {
                    setDowngradeRequestSuccessModalOpened(false);
                    window.location.reload();
                }}
            />
            <DowngradePermissionModal open={downgradePermissionModalOpened} onClose={() => setDowngradePermissionModalOpened(false)} />
        </div>
    )
}
