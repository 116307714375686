import styles from "./TableHeader.module.scss";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";

export interface ITableHeaderProps {
  params?: IPostSaleForDeviceParams;
  setParams?: React.Dispatch<React.SetStateAction<IPostSaleForDeviceParams>>;
}

const titleHeader = ["Nome", "Tipo", "Menus", "Planos", "", ""];

const TableHeader = () => {
  return (
    <div id={styles.TableHeader} className="div-to-capture">
      {titleHeader.map((item) => {
        return (
          <div 
            key={item}
            className={styles.container}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};
export default TableHeader;
