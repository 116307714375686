import { ISelect } from "interfaces/ISelect";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetDeviceListUseCase from "../../application/useCases/GetDeviceListUseCase";
import { IDeviceItem } from "../components/couponForm/components/equipamentForm/interfaces/IDeviceItem";

export interface IParamsClientDeviceList {
  types?: number[],

}


const UseDeviceList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentLocal } = useLocal();
  const [deviceList, setDeviceList] = useState<IDeviceItem[]>([]);
 

  const getDeviceList = useCallback(
    async (params?: number[]) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetDeviceListUseCase(serviceDiscountCoupons,
            currentLocal.id,
            params
          );
          setDeviceList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    deviceList,    
    isLoading,
    getDeviceList,
    setDeviceList,
  };
};

export default UseDeviceList;
