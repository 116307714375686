import { ImportExport } from "@mui/icons-material";
import { IHouseFlowFilter } from "modules/houseFlow/presentation/components/houseFlowFilter/components/interfaces/IHouseFlowFilter";
import { ParamsClientHouseFlow } from "modules/houseFlow/presentation/hooks/UseHouseFlowList";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IHouseFlowColumnsConfig } from "../interfaces/IColumnsConfig";
import styles from "./HouseFlowHeader.module.scss";

interface HouseFlowHeaderProps {
  columns: IHouseFlowColumnsConfig[],
  onClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onClickSelectAll?: (state: boolean) => void
  params: ParamsClientHouseFlow | undefined;
  setParams: React.Dispatch<React.SetStateAction<ParamsClientHouseFlow>>
  values: IHouseFlowFilter | undefined
  setValues: React.Dispatch<React.SetStateAction<IHouseFlowFilter | undefined>>
}


const HouseFlowHeader: FC<HouseFlowHeaderProps> = ({ columns, onClickMenu, onClickSelectAll, params, setParams, values, setValues }) => {


  const onClickOrderColumn = useCallback((orderBy: string) => {
    setParams(prev => ({ ...prev, filter: { ...values, sortType: orderBy, sortOrientation: prev?.filter?.sortOrientation === 'asc' ? 'desc' : 'asc' }, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }))

  }, [setParams, values]);


  const header = useMemo(() => columns.map((column, index) =>
    !column.hide && <div
      key={index}
      className={styles.row}
      style={column.style}
    >
      {
        //column.property === "cardTotalNet" || column.property === "cardTotalService"
        column.property === "cardNumber"
          ? <span className={styles.order}>{column.title} <ImportExport onClick={() => onClickOrderColumn(column.property)} /> </span>
          : column.property === "cardTotalNet"
            ? <span className={styles.order}>{column.title} <ImportExport onClick={() => onClickOrderColumn(column.property)} /> </span>
            : column.property === "cardOrders"
              ? <span className={styles.order}>{column.title} <ImportExport onClick={() => onClickOrderColumn(column.property)} /> </span>
              : column.title

      }
    </div>
  ), [columns, onClickOrderColumn])

  return (
    <div id={styles.HouseFlowHeaderContainer}>
      <div className={styles.container}>
        {/* <div className={styles.checkbox}>
          <Checkbox onChange={(_, value) => onClickSelectAll?.(value)} />
        </div> */}
        {header}
        <div className={styles.icon}></div>
        {/* <div
          className={styles.icon}
        >
          <IconButton onClick={onClickMenu}>
            <Icon>
              more_horiz
            </Icon>
          </IconButton>
        </div> */}
      </div>
    </div>
  );
};

export default HouseFlowHeader;
