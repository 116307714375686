import { IPostAddSmartPrinterBylocalClienteIdRequest } from "../../domain/dtos/IPostAddSmartPrinterBylocalClienteIdRequest";
import { ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

export const AddSmartPrinterByLocalClienteIdUseCase = async (smartPrintersApi: ISmartPrinterApiCB, request: IPostAddSmartPrinterBylocalClienteIdRequest, localClienteId: string) => {
    const _smartPrintersApi = smartPrintersApi();
    return await _smartPrintersApi.addSmartPrinterByLocalClienteId(request, localClienteId);

}


