import { OperatorRoles, OperatorRolesLabel } from "modules/operators/domain/models/OperatorRoles";

export interface IOperatorFormValue {
    id?: string,
    name: string;
    password: string;
    confirmPassword: string;
    privilegedAccess: boolean;
    roles: IOperatorRoleOption[];
    commission: number;
    useCashlessCardAsPassword: boolean;
    tag: string;
}



export interface IOperatorRoleOption {
    value: OperatorRoles
    label: string
}

export const OperatorRolesOptions: IOperatorRoleOption[] = [
    { value: OperatorRoles.ALL, label: OperatorRolesLabel(OperatorRoles.ALL) },
    { value: OperatorRoles.OPEN_SESSION, label: OperatorRolesLabel(OperatorRoles.OPEN_SESSION) },
    // { value: OperatorRoles.GROUP_CARDS, label: OperatorRolesLabel(OperatorRoles.GROUP_CARDS) },
    { value: OperatorRoles.OPEN_ORDER, label: OperatorRolesLabel(OperatorRoles.OPEN_ORDER) },
    { value: OperatorRoles.OPEN_NEW_SESSION, label: OperatorRolesLabel(OperatorRoles.OPEN_NEW_SESSION) },
    { value: OperatorRoles.ALLOW_CHANGE_MINIMUM_CONSUMPTION, label: OperatorRolesLabel(OperatorRoles.ALLOW_CHANGE_MINIMUM_CONSUMPTION) },
    // { value: OperatorRoles.APPROVE_CONSUMPTION_LIMIT, label: OperatorRolesLabel(OperatorRoles.APPROVE_CONSUMPTION_LIMIT) },
    { value: OperatorRoles.CANCEL_POS_ORDER, label: OperatorRolesLabel(OperatorRoles.CANCEL_POS_ORDER) },
    { value: OperatorRoles.CANCEL_SERVICE_FEE, label: OperatorRolesLabel(OperatorRoles.CANCEL_SERVICE_FEE) },
    // { value: OperatorRoles.POS_CONFIGURATION, label: OperatorRolesLabel(OperatorRoles.POS_CONFIGURATION) },
    { value: OperatorRoles.CONSULT_STATEMENT, label: OperatorRolesLabel(OperatorRoles.CONSULT_STATEMENT) },
    // { value: OperatorRoles.DEACTIVATE_CARD, label: OperatorRolesLabel(OperatorRoles.DEACTIVATE_CARD) },
    { value: OperatorRoles.CANCEL_PRODUCT, label: OperatorRolesLabel(OperatorRoles.CANCEL_PRODUCT) },
    { value: OperatorRoles.CLOSE_SESSION, label: OperatorRolesLabel(OperatorRoles.CLOSE_SESSION) },
    // { value: OperatorRoles.SHIFT_CARD, label: OperatorRolesLabel(OperatorRoles.SHIFT_CARD) },
    { value: OperatorRoles.REPORT_ON_EXIT, label: OperatorRolesLabel(OperatorRoles.REPORT_ON_EXIT) },
    { value: OperatorRoles.LOSS_PAYMENT, label: OperatorRolesLabel(OperatorRoles.LOSS_PAYMENT) },
    { value: OperatorRoles.PRODUCTION_PAYMENT, label: OperatorRolesLabel(OperatorRoles.PRODUCTION_PAYMENT) },
    { value: OperatorRoles.ADJUSTMENT_PAYMENT, label: OperatorRolesLabel(OperatorRoles.ADJUSTMENT_PAYMENT) },
    { value: OperatorRoles.ALLOW_DISCOUNT, label: OperatorRolesLabel(OperatorRoles.ALLOW_DISCOUNT) },
    { value: OperatorRoles.ALLOW_PAYMENT, label: OperatorRolesLabel(OperatorRoles.ALLOW_PAYMENT) },
    // { value: OperatorRoles.ALLOW_LINK_TAG, label: OperatorRolesLabel(OperatorRoles.ALLOW_LINK_TAG) },
    { value: OperatorRoles.ALLOW_EXIT_SESSION, label: OperatorRolesLabel(OperatorRoles.ALLOW_EXIT_SESSION) },
    { value: OperatorRoles.REPRINT_TICKET, label: OperatorRolesLabel(OperatorRoles.REPRINT_TICKET) },
    // { value: OperatorRoles.REPRINT_TICKET_TOTEM, label: OperatorRolesLabel(OperatorRoles.REPRINT_TICKET_TOTEM) },
    { value: OperatorRoles.CLOSING_REPORT, label: OperatorRolesLabel(OperatorRoles.CLOSING_REPORT) },
    { value: OperatorRoles.TRANSFER_CONSUMPTION, label: OperatorRolesLabel(OperatorRoles.TRANSFER_CONSUMPTION) },
    { value: OperatorRoles.TRANSFER_ORDER, label: OperatorRolesLabel(OperatorRoles.TRANSFER_ORDER) },
];

// var value = { "id": "58754dcf-4f37-444b-a5f8-52a196942358", "nome": "Deivid", "senha": null, "comissao": null, "papeis": ["AbrirCaixa", "AbrirComanda", "AbrirNovoCaixa", "AgruparCartoes", "AprovarLimiteConsumoCashless", "CancelarPedidoPOS", "CancelarTaxaServico", "ConfiguracaoPOS", "ConsultarExtrato", "DesativarCartao", "EstornarProduto", "FecharCaixa", "FichaVolante", "PagamentoAjuste", "PagamentoPerda", "PagamentoProducao", "PermitirDesconto", "PermitirPagamento", "PermitirSairCaixa", "PermitirVincularEtiqueta", "PoderAlterarMinimoConsumo", "ReimpressaoTicket", "ReimpressaoTicketTotem", "RelatorioAoSair", "RelatorioFechamento", "TransferirComanda", "TransferirConsumo"], "acessoPrivilegiado": false, "usarCartaoCashlessComoSenha": false, "tag": null }
