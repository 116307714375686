import { useCallback, useState } from 'react'
import styles from './FilterMainDash.module.scss'
import { CalendarToday, Close, FilterAlt, PointOfSale } from '@mui/icons-material'
import { MenuItem, Modal, Select, TextField } from '@mui/material'
import Button from 'components/ui/Button/Button'
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";

interface FilterMainDashProps {
    filterApplied: boolean
    setFilterApplied: React.Dispatch<React.SetStateAction<boolean>>
    period: string
    setPeriod: React.Dispatch<React.SetStateAction<string>>
    filter: string | undefined
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>
    cashiers?: {
        id: string
        name: string
    }[],
    submitFilter: () => void
}


const FilterMainDash = ({ submitFilter, filterApplied, setFilterApplied, period, setPeriod, filter, setFilter, cashiers }: FilterMainDashProps) => {
    const [open, setOpen] = useState(false)

    const changeHandleDate = useCallback((date: Date | null) => {
        const dateTimeInUTC = date?.toISOString();
        setFilter(dateTimeInUTC);
    }, [setFilter]);

    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = () => {
        submitFilter()
        setOpen(false)
        setFilterApplied(true)
    }

    const onChangePeriod = (e: string) => {
        const dateTimeInUTC = new Date().toISOString();
        if (e === 'day') {
            setFilter(dateTimeInUTC)
        } else {
            setFilter(cashiers && cashiers[0].id)
        }
        setPeriod(e)
    }

    return (
        <div id={styles.FilterMainDash}>
            <div
                className={filterApplied ? styles.buttonFilterActive : styles.buttonFilterNotActive}
                onClick={() => setOpen(true)}
            >
                <FilterAlt />
            </div>
            <Modal open={open} onClose={() => onClose()}>
                <div id={styles.Modal}>
                    <div className={styles.top}>
                        <p>Filtros</p>
                        <div className={styles.close} onClick={() => onClose()}>
                            <Close />
                        </div>
                    </div >
                    <div className={styles.content}>
                        <p className={styles.title}>Visualização da dashboard</p>
                        <div className={styles.box}>
                            <div
                                className={period === 'day' ? styles.boxPeriodActive : styles.boxPeriodNotActive}
                                onClick={() => onChangePeriod('day')}
                            >
                                <CalendarToday />
                                <p>Por dia</p>
                            </div>
                            <div
                                className={period === 'cashier' ? styles.boxPeriodActive : styles.boxPeriodNotActive}
                                onClick={() => onChangePeriod('cashier')}
                            >
                                <PointOfSale />
                                <p>Por caixa</p>
                            </div>
                        </div>
                        {period &&
                            <>
                                <p className={styles.title}>Selecione o {period === 'day' ? 'dia' : 'caixa'}:</p>
                                {period === 'day' ? (
                                    <div className={styles.input}>
                                        <span className={styles.required}>Dia</span>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                            <DatePicker
                                                value={filter}
                                                onChange={changeHandleDate}
                                                renderInput={(params) => <TextField {...params} />}
                                                maxDate={new Date()}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                ) : (
                                    <div className={styles.input}>
                                        <span className={styles.required}>Caixa</span>
                                        <Select
                                            name={'dataInicio'}
                                            onChange={(e) => setFilter(e.target.value)}
                                            fullWidth
                                            value={filter}
                                        >
                                            {cashiers?.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                    <div className={styles.bottom}>
                        <Button fullWidth={false} onClick={() => onSubmit()}>Mudar visualização</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FilterMainDash