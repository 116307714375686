import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { GetServiceScheduleByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetServiceScheduleByLocalIdUseCase'
import AddReserveScheduleUseCase from 'modules/schedule/application/useCases/reserveSchedule/AddReserveScheduleUseCase'
import { GetPlaceScheduleByServiceIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/GetPlaceScheduleByServiceIdUseCase'
import { GetServicesByLocalIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/GetServicesByLocalIdUseCase'
import { GetServiceValuesByServiceIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/GetServiceValuesByServiceIdUseCase'
import { ReserveScheduleApi } from 'services/api/schedule/reserve/ReserveScheduleApi'
import React, { useCallback, useEffect, useState } from 'react'
import { IPlaceScheduleForm } from '../../components/placeScheduleForm/interface/IPlaceScheduleForm'
import { IPlacesScheduleReserve } from '../../components/reserveScheduleForm/interfaces/IPlacesSchedules'
import { IProductServiceValues, ServiceValueItem } from '../../components/reserveScheduleForm/interfaces/IProductServiceValues'
import { IReserveScheduleForm } from '../../components/reserveScheduleForm/interfaces/IReserveScheduleForm'
import { IServiceItemReserve } from '../../components/reserveScheduleForm/interfaces/IServiceSchedule'
import ReserveScheduleForm from '../../components/reserveScheduleForm/ReserveScheduleForm'
import { useHistory, useParams } from 'react-router-dom'

export const UseAddReserveSchedulePage = () => {


    const [servicesValue, setServicesValue] = useState<ServiceValueItem[]>([])
    const [placeSchedule, setPlaceSchedule] = useState<IPlacesScheduleReserve[]>([])
    const [servicesSchedule, setServiceSchedules] = useState<IServiceItemReserve[]>([])

    const [_serviceId, setServiceId] = useState<string>();

    const { serviceId } = useParams<{ serviceId: string }>();

    const { currentLocal } = useLocal();

    const { showLoading, hideLoading, toast } = useUi();

    useEffect(() => {
        if (serviceId) {
            setServiceId(serviceId);
        }
    }, [serviceId]);


    const getDataByServiceId = useCallback((serviceId: string) => {
        const serviceValuesPromise = GetServiceValuesByServiceIdUseCase(serviceId, ReserveScheduleApi)
        const placeSchedulePromise = GetPlaceScheduleByServiceIdUseCase(serviceId, ReserveScheduleApi)
        showLoading()
        Promise.all([serviceValuesPromise, placeSchedulePromise]).then(([values, places]) => {
            setPlaceSchedule(places);
            setServicesValue(values);
        }).finally(() => {
            hideLoading()
        })
    }, []);

    const getServiceByLocalId = useCallback((localId: string) => {
        showLoading();
        GetServicesByLocalIdUseCase(localId, ReserveScheduleApi).then((response) => {
            setServiceSchedules(response);
        }).finally(() => {
            hideLoading();
        })
    }, [])
    useEffect(() => {
        if (currentLocal) {
            getServiceByLocalId(currentLocal.id);
        }
    }, [currentLocal])

    useEffect(() => {
        if (serviceId) {
            getDataByServiceId(serviceId);
        }
    }, [serviceId])

    const onChangeServiceId = useCallback((serviceId: string) => {
        setServiceId(serviceId);
    }, []);

    const onSubmitFormHandle = useCallback(
        (values: IReserveScheduleForm) => {
            showLoading();
            AddReserveScheduleUseCase(values, ReserveScheduleApi).then(() => {
                toast("Resevar Adicionada Com Sucesso", "success")
                //TODO: redirect to calendar
            }).finally(() => {
                hideLoading()
            })
        },
        [],
    )

    return (
        {
            servicesValue,
            placeSchedule,
            servicesSchedule,
            serviceId,
            onChangeServiceId,
            onSubmitFormHandle
        }
    )
}


