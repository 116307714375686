import React, { useCallback } from "react";
import styles from "./PaymentLinkReportPage.module.scss";
import { UsePaymentLinkReportPage } from "./UsePaymentLinkReportPage";
import { PaymentLinkRecurrenceList } from "./paymentLinkRecurrenceList/PaymentLinkRecurrenceList";
import { PaymentLinkRecurrenceFilter } from "../components/paymentLinkRecurrenceFilter/PaymentFilter";
import { moneyMaskNumber } from "services/utils/Money";

const PaymentLinkReportPage = () => {
  const {
    getPaymentLinkRecurrenceList,
    isLoading,
    onChangeFilterHandle,
    onChangePaginationHandle,
    params,
    paymentLinkRecurrenceList,
    setParams,
    productList,
  } = UsePaymentLinkReportPage();

  const reload = useCallback(() => {
    getPaymentLinkRecurrenceList({ ...params });
  }, [getPaymentLinkRecurrenceList, params]);

  return (
    <div id={styles.PaymentLinkReportPage}>
      <PaymentLinkRecurrenceFilter
        onSubmit={onChangeFilterHandle}
        productList={productList}
      />

      <div className={styles.total}>
        <p>Total</p>
        <span>{moneyMaskNumber(paymentLinkRecurrenceList?.total ?? 0)}</span>
      </div>

      <PaymentLinkRecurrenceList
        paymentLinkRecurrenceList={paymentLinkRecurrenceList}
        isLoading={isLoading}
        onChangePaginationHandle={onChangePaginationHandle}
        params={params}
        setParams={setParams}
        pagination={params?.pagination}
        reload={reload}
      />
    </div>
  );
};

export default PaymentLinkReportPage;
