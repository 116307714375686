
import styles from "./WhatsAppConfiguredAndConnectedStep.module.scss";
import DoneIcon from "@mui/icons-material/Done";
import SettingsIcon from "@mui/icons-material/Settings";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

export interface Props {
  status: boolean
}

const WhatsAppConfiguredAndConnectedStep = ({ status}: Props) => {
  return (
    <div className={styles.content}>
      <h1 className={styles.titleStatus}>
        Status: <h2>{status && "Conectado"}</h2>
      </h1>
      <div className={styles.container}>
        <div className={styles.conectedWhatsapp}>
          <DoneIcon
            sx={{
              fontSize: 60,
              color: "green",
              background: "white",
              borderRadius: "50%",
            }}
          />
          <p>Whatsapp</p>
          <h3>conectado</h3>
        </div>
        <div className={styles.contentAll}>
          <div>
            <p className={styles.text}>
            Pronto! Seu Whatsapp já está configurado! Por meio dele, será possível enviar campanhas e comunicações incríveis aos seus clientes no CRM Meep. Uma dica: opte por utilizar Whatsapp com chip pré-pago da sua operadora. Assim, caso seja necessário, será mais fácil substituir o número..
            </p>
          </div>

          {/* <div className={styles.contentButtons}>
            <button>
              <SettingsIcon fontSize="small" className={styles.button} />{" "}
              Desconectar
            </button>
            <button>
              <CachedIcon fontSize="small" className={styles.button} />{" "}
              Reiniciar
            </button>
            <button>
              <CloseIcon fontSize="small" className={styles.button} /> Cancelar
              assinatura
            </button>
            <button>
              <PriorityHighRoundedIcon
                fontSize="small"
                className={styles.button}
              />
              Ver detalhes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppConfiguredAndConnectedStep;
