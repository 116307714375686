import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { FC } from "react";
import { IDeviceItem } from "../deviceList/interfaces/IDeviceItem";
import styles from "./DeviceForm.module.scss";
import UseDeviceForm from "./UseDeviceForm";
import DeviceLicenseForm from "../deviceForms/deviceLicenseForm/DeviceLicenseForm";
import LinkForm from "../deviceForms/linkForm/LinkForm";
import DeviceManualForm from "../deviceForms/deviceManualForm/DeviceManualForm";
export interface IDeviceFormProps {
  deviceList?: IDeviceItem[];
  setSelectedDevices?: (devices: IDeviceItem[]) => void;
}
const DeviceForm: FC<IDeviceFormProps> = ({
  deviceList,
  setSelectedDevices,
}) => {
  const {
    currentStep,
    licenses,
    deviceProfileList,
    currentDeviceType,
    onChangeFormStep,
    onClickLicense,
    closeStepForm,
    checkLink,
    onClickGoToManualRegister,
    addDevice,
    onChangeDeviceProfileHandle,
  } = UseDeviceForm({ setSelectedDevices });

  return (
    <div id={styles.DeviceForm}>
      <div className={styles.container}>
        {currentStep === FormDeviceStep.LICENSE && (
          <DeviceLicenseForm
            licences={licenses}
            onClickItem={onClickLicense}
            onClose={closeStepForm}
          />
        )}
        {currentStep === FormDeviceStep.LINK && (
          <LinkForm
            code={"123456"}
            onClose={closeStepForm}
            onClickNext={checkLink}
            onClickBack={() => onChangeFormStep?.(FormDeviceStep.LICENSE)}
            onClickManual={onClickGoToManualRegister}
          />
        )}
        {/* {
                    (currentStep === FormDeviceStep.PROFILE || currentStep === FormDeviceStep.MULTIPLE) &&
                    <SetDeviceProfileForm
                        deviceProfileList={deviceProfileList}
                        defaultValue={undefined}
                        onClickClose={closeStepForm}
                        onClickNext={onChangeDeviceProfileHandle}
                        onClickBack={() => currentStep === FormDeviceStep.PROFILE ? onChangeFormStep?.(FormDeviceStep.LINK) : closeStepForm()}
                        currentStep={currentStep}
                        deviceList={deviceList}
                    />
                } */}
        {currentStep === FormDeviceStep.MANUAL && (
          <DeviceManualForm
            onClickClose={closeStepForm}
            onSubmit={addDevice}
            onClickBack={() => onChangeFormStep?.(FormDeviceStep.PROFILE)}
            defaultType={currentDeviceType}
          />
        )}
      </div>
    </div>
  );
};
export default DeviceForm;
