import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService";
import { api } from "../Api";
import { IGetFidelityPlanResponse } from "modules/fidelity/domain/dtos/IGetFidelityPlanResponse";
import { IGetFidelityPlanItemResponse } from "modules/fidelity/domain/dtos/IGetFidelityPlanItemResponse";
import { ICreateFidelityResponse } from "modules/fidelity/domain/dtos/ICreateFidelityResponse";
import { ICreateFidelityPlanRequest } from "modules/fidelity/domain/dtos/ICreateFidelityPlanRequest";
import { IUpdateFidelityRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityRequest";
import { IUpdateFidelityResponse } from "modules/fidelity/domain/dtos/IUpdateFidelityResponse";
import { GetFidelityBalanceResponse } from "modules/fidelity/domain/dtos/GetFidelityBalanceResponse";
import { GetCustomerSummaryResponse } from "modules/fidelity/domain/dtos/GetCustomerSummaryResponse";
import { fidelityUrl } from "Enviroment";
import { EventsDiscountsApi } from "../events/discounts/EventsDiscountsApi";
import { IGetProductListResponse } from "modules/fidelity/domain/dtos/IGetProductListResponse";
import { IUpdateFidelityProductsRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityProductsRequest copy";
import { IGetFidelityProductsItemResponse } from "modules/fidelity/domain/dtos/IGetFidelityProductsRequest";
import { EditCustomerPointsSummaryRequest } from "modules/fidelity/domain/dtos/EditCustomerPointsSummaryRequest";
import { IGetFidelityCustomersResponse } from "modules/fidelity/domain/dtos/IGetFidelityCustomersResponse";
import { IGetHistoryResponse } from "modules/plans/domain/dtos/IGetHistoryResponse";
import { ChangeHistoryTypeEnum } from "modules/fidelity/domain/interfaces/ChangeHistoryTypeEnum";

export const FidelityServiceApi = (): IFidelityService => {
  const baseUrl = fidelityUrl;

  const fidelityPlans = async (ownerId: string): Promise<IGetFidelityPlanItemResponse[]> => {
    const response = await api.get(`${baseUrl}/fidelity/owner/${ownerId}/hierarchy`);
    return response.data;
  }

  const getFidelityLevelById = async (id: string): Promise<IGetFidelityPlanResponse> => {
    const response = await api.get<IGetFidelityPlanResponse>(`${baseUrl}/fidelity/${id}`);
    return response.data;
  };

  const createFidelityLevel = async (ownerId: string, request: ICreateFidelityPlanRequest): Promise<ICreateFidelityResponse> => {
    const response = await api.post<ICreateFidelityResponse>(`${baseUrl}/fidelity/owner/${ownerId}`, request, {
      params: {
        disableError: true
      }
    });
    return response.data;
  }

  const updateFidelityLevel = async (ownerId: string, id: string, request: IUpdateFidelityRequest): Promise<IUpdateFidelityResponse> => {
    const response = await api.put<IUpdateFidelityResponse>(`${baseUrl}/fidelity/${id}`, request);
    return response.data;
  }

  const deleteFidelityPlan = async (id: string): Promise<void> => {
    return api.delete(`${baseUrl}/fidelity/${id}`);
  }

  const getFidelityBalance = async (type: 'points' | 'coins', customerDocument: string, ownerId: string, page: number, pageSize: number): Promise<GetFidelityBalanceResponse> => {
    const response = await api.get<GetFidelityBalanceResponse>(`${baseUrl}/fidelity-balance/${type}`, { params: { customerDocument, ownerId, page, pageSize } });
    return response.data;
  }

  const getCustomerSummary = async (customerDocument: string, ownerId: string): Promise<GetCustomerSummaryResponse> => {
    const response = await api.get<GetCustomerSummaryResponse>(`${baseUrl}/customers/details`, { params: { customerDocument, ownerId, disableError: true } });
    return response.data;
  }

  const editSummary = (customerDocument: string, ownerId: string, request: EditCustomerPointsSummaryRequest): Promise<void> => {
    return api.put(`${baseUrl}/customers/summaries`, {
      ownerId,
      customerDocument,
      ...request
    })
  }

  const getProductsLists = async (ownerId: string): Promise<IGetProductListResponse[]> => {
    const service = EventsDiscountsApi()
    const response = await service.getDiscountProductsLists(ownerId);
    return response;
  }

  const updateFidelityProducts = async (ownerId: string, fidelityId: string, request: IUpdateFidelityProductsRequest) => {
    await api.put(`${baseUrl}/fidelity-product/owner/${ownerId}/fidelity/${fidelityId}`, request);
  }

  const getFidelityProducts = async (fidelityId: string): Promise<IGetFidelityProductsItemResponse[]> => {
    const response = await api.get<IGetFidelityProductsItemResponse[]>(`${baseUrl}/fidelity-product/byFidelityId/${fidelityId}`);
    return response.data;
  }

  const checkCurrencyExchangeFidelityPlan = async (ownerId: string): Promise<boolean> => {
    const response = await api.get(`/Local/EnableCurrencyExchangeFidelityPlan/${ownerId}`);
    return response.data?.isEnabled || false;
  }

  const toggleCurrencyExchangeFidelityPlan = async (ownerId: string, isEnabled: boolean): Promise<void> => {
    return await api.post(`/Local/EnableCurrencyExchangeFidelityPlan`, { ownerId, isEnabled });
  }

  const getFidelityCustomers = async (fidelityPlanId: string, ownerId: string, page?: number, keyword?: string): Promise<IGetFidelityCustomersResponse> => {
    const response = await api.get<IGetFidelityCustomersResponse>(`${baseUrl}/fidelity/${fidelityPlanId}/customers`, { params: { keyword, page, pageSize: 20 } });
    return response.data;
  };

  const getHistory = async (ownerId: string, changeType: ChangeHistoryTypeEnum): Promise<IGetHistoryResponse[]> => {
    const response = await api.get(`${baseUrl}/customers/change-histories`, { params: { ownerId, changeHistoryType: changeType }});
    return response.data;

    // return [
    //   {
    //     "changeDate": "2023-11-09T14:22:56.329675",
    //     "changedBy": "Alterado por: Castilho Deivid (cargo: )",
    //     "changeDescription": "",
    //     "changeTitle": "Troca de moedas Habilitada"
    //   },
    //   {
    //     "changeDate": "2023-11-09T14:22:25.8106446",
    //     "changedBy": "Alterado por: Castilho Deivid (cargo: )",
    //     "changeDescription": "{\"Value\":1.00,\"NewValue\":10.0}",
    //     "changeTitle": "Alteração do valor da cotação do nivel Nível 1  "
    //   },
    //   {
    //     "changeDate": "2023-11-09T14:22:08.00972",
    //     "changedBy": "Alterado por: Castilho Deivid (cargo: )",
    //     "changeDescription": "",
    //     "changeTitle": "Troca de moedas Desabilitada"
    //   }
    // ]
  }

  return {
    getFidelityProducts,
    fidelityPlans,
    getFidelityLevelById,
    createFidelityLevel,
    updateFidelityLevel,
    deleteFidelityPlan,
    getFidelityBalance,
    getCustomerSummary,
    editSummary,
    getProductsLists,
    updateFidelityProducts,
    checkCurrencyExchangeFidelityPlan,
    toggleCurrencyExchangeFidelityPlan,
    getFidelityCustomers,
    getHistory
  };
};

