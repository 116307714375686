import { IGetDayPaymentsPerHourResponse } from "../../dtos/IGetDayPaymentsPerHourResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetDayPaymentsPerHourUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetDayPaymentsPerHourResponse> => {
  return await service.getDayPaymentsPerHour(localId, cashierId, day);
};

export default GetDayPaymentsPerHourUseCase;
