import { ICargosApiService } from "modules/cargos/models/interface/ICargosApiService";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const GetRolesListUseCase = async ( service: ICargosApiService, systemIdentifier: SystemIdentifierEnum ) => {
  const response = service.getRoles(systemIdentifier);
  return response;
};
export default GetRolesListUseCase;



