import { ISaveReadNotificationRequest } from "components/layout/domain/dto/ISaveReadNotificationRequest";
import { INotificationsService } from "components/layout/domain/interfaces/INotificationsService";

const SaveReadNotificationUseCase = async (
    service: INotificationsService,
    request: ISaveReadNotificationRequest
  ): Promise<any> => {
    const response = await service.saveReadNotification(request);
    return (response);
};
  
export default SaveReadNotificationUseCase;
