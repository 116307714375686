import Layout from "components/layout/presentation/Layout";
import AddColaboratorSchedulePage from "modules/schedule/presentation/pages/addColaboratorSchedule/AddColaboratorSchedulePage";
import AddPlaceSchedulePage from "modules/schedule/presentation/pages/addPlaceSchedule/AddPlaceSchedulePage";
import React, { FC } from "react";
import styles from "./AddColaborator.module.scss";

const AddColaborator: FC = () => {
  return (
    <Layout>
      <AddColaboratorSchedulePage />
    </Layout>
  );
};
export default AddColaborator;
