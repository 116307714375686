import { ISort } from "interfaces/ISort";
import { IGetPaymentLinkListResponse } from "modules/paymentLink/domain/dto/IGetPaymentLinkListResponse";
import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";
import { api } from "../Api";
import { IGetPaymentLinkParams } from "modules/paymentLink/domain/dto/IGetPaymentLinkParams";
import { IPostPaymentLinkRequest, IPostPaymentLinkResponse } from "modules/paymentLink/domain/dto/IPostPaymentLinkRequest";
import { IGetPaymentLinkByIdResponse } from "modules/paymentLink/domain/dto/IGetPaymentLinkByIdResponse";
import { IPutPaymentLinkRequest } from "modules/paymentLink/domain/dto/IPutPaymentLinkRequest";

const PaymentLinkService = (): IPaymentLinkService => {
  const getPaymentLinkList = async (
    _params?: IGetPaymentLinkParams,
    sort?: ISort
  ): Promise<IGetPaymentLinkListResponse> => {
    const params = {
      ..._params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    };

    const response = await api.get(`/mepaylink`, { params });
    return response.data;
  };
  const getPaymentLinkById = async (
    paymentLinkId: string,
  ): Promise<IGetPaymentLinkByIdResponse> => {

    const response = await api.get(`/mepaylink/GetLinkById/${paymentLinkId}`);
    return response.data;
  };

  const postPaymentLink = async (
    payload: IPostPaymentLinkRequest
  ): Promise<IPostPaymentLinkResponse> => {
    const result = await api.post<IPostPaymentLinkResponse>(
      `/mepaylink`,
      payload
    );
    return result.data;
  };

  const putPaymentLink = async (
    payload: IPutPaymentLinkRequest
  ): Promise<void> => {
    await api.put<IPutPaymentLinkRequest>(
      `/mepaylink`,
      payload
    );
  };

  const deletePaymentLink = async (
    id: string
  ): Promise<void> => {
    await api.delete(`/mepaylink/Delete/${id}`);
  };

  return {
    getPaymentLinkList,
    postPaymentLink,
    getPaymentLinkById,
    putPaymentLink,
    deletePaymentLink
  };
};

export default PaymentLinkService;
