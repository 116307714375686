import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import styles from "./PaymentLinkRecurrenceFilter.module.scss";
import Button from "components/ui/Button/Button";
import { IPaymentLinkRecurrencefilter } from "./interfaces/IPaymentLinkRecurrencefilter";
import { IProductItem } from "../../interfaces/IProductItem";
import { Autocomplete, Icon, MenuItem, TextField } from "@mui/material";

export interface IPermutationAccountFilterProps {
  onSubmit: (filter: IPaymentLinkRecurrencefilter) => void;
  productList: IProductItem[] | undefined;
}
export const PaymentLinkRecurrenceFilter: React.FC<
  IPermutationAccountFilterProps
> = ({ onSubmit, productList }) => {
  const [values, setValues] = useState<IPaymentLinkRecurrencefilter>();

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const valueRef = useRef<HTMLInputElement>(null);

  const onClickShowAdevancedFilterHandle = useCallback(() => {
    setShowAdvancedFilter((prev) => !prev);
  }, []);

  const changeHandleNameLink = useCallback((namelink: string) => {
    setValues((prev) => ({ ...prev, linkName: namelink }));
  }, []);

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;
      setValues((prev) => ({
        ...prev,
        value: Number(value),
      }));
    },
    []
  );
  const inputMoneyMask = useCallback((value?: string) => {
    if (!value) {
      return undefined;
    }
    value = value.replace(/\D/g, "");
    while (value.length < 3) {
      value = "0" + value;
    }
    value = value.replace(/^0+(?=\d{3,})/, "");

    let maskedValue = "R$ " + value.slice(0, -2) + "," + value.slice(-2);
    if (value === "000") {
      if (valueRef.current) {
        valueRef.current.value = "";
      }
      return undefined;
    }
    return maskedValue;
  }, []);

  const changeHandleDocument = useCallback((document: string) => {
    setValues((prev) => ({ ...prev, document: document }));
  }, []);

  const changeHandleStatus = useCallback((isActive: boolean) => {
    setValues((prev) => ({ ...prev, isActive: isActive }));
  }, []);

  const handleSelectChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const value = ev.target.value as string;
    changeHandleStatus(value === "ativo");
  };

  const handleChangeDate = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const expiration = ev.target.value;
      setValues((prev) => ({ ...prev, signatureDate: expiration }));
    },
    []
  );

  const handleAutocompleteProductsChange = useCallback(
    (_: any, value: IProductItem | null) => {
      setValues((prev) => ({ ...prev, productId: value?.id }));
    },
    []
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      values && onSubmit(values);
      setValues({ value: undefined, isActive: undefined });
    },
    [onSubmit, values]
  );
  return (
    <div id={styles.PaymentLinkRecurrenceFilter}>
      <div id={styles.HouseFlowFilter}>
        <form onSubmit={submitHandle}>
          <div className={styles.containerFilter}>
            <div className={styles.content}>
              <div className={styles.itemInfo}>
                <label>Nome do link</label>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  size="small"
                  placeholder="Insira o nome"
                  variant="outlined"
                  name={"name"}
                  onChange={(ev) => changeHandleNameLink(ev.target.value)}
                />
              </div>

              <div className={styles.itemInfo}>
                <label>Produto</label>
                <Autocomplete
                  options={productList ?? []}
                  getOptionLabel={(option) => option?.name || ""}
                  noOptionsText="Nenhuma opção encontrada"
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Selecione o produto"
                      size="small"
                    />
                  )}
                  onChange={handleAutocompleteProductsChange}
                />
              </div>
              <div className={styles.itemInfo}>
                <label>CPF/CNPJ</label>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  size="small"
                  type="text"
                  placeholder="4 últimos dígitos"
                  variant="outlined"
                  name="document"
                  onChange={(ev) => {
                    let value = ev.target.value.replace(/\D/g, "");
                    if (value.length > 4) {
                      value = value.slice(0, 4);
                    }
                    changeHandleDocument(value);
                  }}
                  onInput={(ev) => {
                    const input = ev.target as HTMLInputElement;
                    input.value = input.value.replace(/\D/g, "");
                  }}
                />
              </div>
              <div className={styles.itemInfo}>
                <label>Status</label>
                <TextField select onChange={handleSelectChange}>
                  <MenuItem value="ativo">Ativo</MenuItem>
                  <MenuItem value="cancelado">Cancelado</MenuItem>
                </TextField>
              </div>
            </div>
            <div className={styles.footer}>
              {!showAdvancedFilter && (
                <Button
                  variant={"contained"}
                  className={styles.button}
                  fullWidth
                  style={{ height: 40, borderRadius: 8, width: 110 }}
                  type="submit"
                >
                  Buscar
                </Button>
              )}
            </div>
          </div>
          <div className={styles.advancedFilterButton}>
            <div>
              <Button
                className={styles.buttonFilterAdvanced}
                onClick={onClickShowAdevancedFilterHandle}
                variant="text"
              >
                Filtros avançados
                {!showAdvancedFilter ? <Icon>sort</Icon> : <Icon>close</Icon>}
              </Button>
            </div>
          </div>
          {showAdvancedFilter && (
            <div className={styles.advancedFilter}>
              <div className={styles.itemAdvanced}>
                <label>Data de expiração</label>
                <TextField
                  InputProps={{
                    inputProps: {
                      style: {
                        color: values?.signatureDate
                          ? "black"
                          : "var(--Neutral-Gray-700, #A2A2A2)",
                      },
                    },
                  }}
                  size="small"
                  type="date"
                  variant="outlined"
                  name={"expiration"}
                  onChange={handleChangeDate}
                />
              </div>
              <div className={styles.itemAdvanced}>
                <label>Valor</label>
                <TextField
                  ref={valueRef}
                  fullWidth
                  inputProps={{ maxLength: 250, inputMode: "decimal" }}
                  size="small"
                  placeholder="Insira o valor"
                  variant="outlined"
                  value={inputMoneyMask(String(values?.value))}
                  name="value"
                  type="number"
                  onChange={handleChangeValue}
                />
              </div>
              <div className={styles.footer}>
                {showAdvancedFilter && (
                  <Button
                    variant={"contained"}
                    className={styles.button}
                    fullWidth
                    style={{ height: 40, borderRadius: 8, width: 110 }}
                    type="submit"
                  >
                    Buscar
                  </Button>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
