import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";
import { ITableList } from "modules/printQueue/presentation/interfaces/ITableList";

const GetTableListUseCase = async (service: IPrintQueueApiService, localClienteId: string):Promise<ITableList[]> => {


    const response = await service.getTableListByLocalClenteId(localClienteId);
    return response
}

export default GetTableListUseCase;



