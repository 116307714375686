import { Icon, IconButton, TextField } from '@material-ui/core';
import styles from './FidelityCustomers.module.scss';
import { IFidelityCustomers } from '../../interfaces/IFidelityCustomers';
import { Pagination } from '@material-ui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';

interface FidelityCustomersProps {
    onClose: () => void;
    getData: (page: number, keyword?: string) => void;
    data?: IFidelityCustomers;
    fidelityPlanName: string;
    loading: boolean;
    page: number;
}

export const FidelityCustomers = ({ onClose, getData, fidelityPlanName, loading, page, data }: FidelityCustomersProps) => {
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        getData(1);
    }, [getData]);

    const handleFilter = () => {
        getData(1, keyword);
    }

    const totalPages = useMemo(() => {
        let total = (data?.totalRegisters || 0) / 20;
        total = Math.floor(total);

        return total || 1;
    }, [data?.totalRegisters]);

    return (
        <div className={styles.container}>
            <header>
                <h1>
                    Clientes cadastrados no <span>nível {fidelityPlanName}</span>
                </h1>
                <IconButton onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </header>

            <div className={styles.content}>
                <div className={styles.filter}>
                    <label className={styles.required}>
                        Buscar cliente:
                    </label>
                    <div>
                        <TextField
                            onChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                            variant="outlined"
                            fullWidth
                            size='small'
                            className={styles.textField}
                            placeholder='Busque por nome ou CPF'
                        />

                        <button onClick={handleFilter} className={styles.filterButton}>
                            Buscar
                        </button>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Pontuação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td colSpan={3}>
                                        <div className={styles.loader}>
                                            {
                                                Array.from(Array(20).keys()).map(x => (
                                                    <Skeleton variant="rectangular" width="100%" height={24.5} className={styles.skeleton} />
                                                ))
                                            }
                                        </div>

                                    </td>
                                </tr>
                                :
                                !!data?.data.length ? data?.data.map(item => (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.document}</td>
                                        <td>{new Intl.NumberFormat("pt-BR").format(item.totalPoints || 0)} pts</td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={2}>
                                            <div className={styles.emptyTable}>
                                                <span>Esse plano não possui nenhum cliente!</span>
                                            </div>
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
                <Pagination
                    page={page}
                    color="primary"
                    className={styles.pagination}
                    count={totalPages}
                    onChange={(ev, page) => getData(page)}
                />
            </div>
        </div>
    )
}
