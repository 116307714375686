import React, { FC, useCallback, useEffect } from 'react'
import styles from './CreateOrUpdateSupplyPage.module.scss'
import SupplyForm from 'modules/meepErp/presentation/components/supply/supllyForm/SupplyForm'
import { getSupplyGroupListUseCase, searchNameSupplyGroupUseCase } from 'modules/meepErp/application/useCases/supply/supplyGroup/SupplyGroupUseCases'
import { getUnitUseCase } from 'modules/meepErp/application/useCases/Units/UnitUseCases'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase'
import CatalogService from 'services/api/catalog/CatalogService'
import GetAllProductsUseCase from 'modules/catalog/application/useCases/GetAllProductsUseCase'
import { UseLocalStore } from 'modules/local/presentation/store/UseLocalStore'
import { ISupplyFormValues } from 'modules/meepErp/presentation/components/supply/supllyForm/ISupplyFormValues'
import { SaveSupplyUseCase, UpdateSupplyUseCase, getCompositionsUseCase, getSuppliesListUseCase, getSupplyUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases'
import SupplyService from 'modules/meepErp/services/api/Supply/SupplyService'
import { useHistory, useParams } from 'react-router-dom'
import { ISupplyGroupListRequest } from 'modules/meepErp/presentation/components/supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import { IGetSupplyListResponse, IGetSupplyItemResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse'
import { queryClient } from 'services/api/Api';
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { IGetSupplyNameRequest } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'
import { IGetSupplyListRequest } from 'modules/meepErp/application/dtos/supply/IGetSupplyListRequest';
import GetSupplyStockListUseCase from 'modules/meepErp/application/useCases/supplyStock/GetSupplyStockListUseCase'
import { IGetSupplyStockListRequest, IGetSupplyStockListResponse, ISupplyStockItemResponse } from 'modules/meepErp/application/dtos/supplyStock/IGetSupplyStockListResponse'
export interface ICreateOrUpdateSupplyPageProps {
    //propertys
}
const CreateOrUpdateSupplyPage: FC<ICreateOrUpdateSupplyPageProps> = () => {
    const { id } = useParams<{ id?: string }>();
    const { currentLocal } = UseLocalStore();
    const { replace } = useHistory();
    const { updateRouters } = useBreadcumbs();
    const { showLoading, hideLoading } = useUi()

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Insumos",
                url: '/private/meeperp/supply'
            },
            {
                title: "Cadastro de insumos",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const GetSearchNameSupplyGroup = useCallback(async (request: IGetSupplyNameRequest) => {
        return await searchNameSupplyGroupUseCase(request);
    }, [])

    const getSupplyGroupListHandler = useCallback(async (request: ISupplyGroupListRequest) => {
        try {
            showLoading();
            return await getSupplyGroupListUseCase({ page: 1, pageSize: 20, ...request })
        } finally {
            hideLoading();
        }
    }, [])

    const getSupplyStockList = useCallback(async (request: IGetSupplyStockListRequest) => {
        try {
            showLoading();
            if (currentLocal) {
                return GetSupplyStockListUseCase(currentLocal.id, request)
            } else {
                return { items: [] as ISupplyStockItemResponse[] } as IGetSupplyStockListResponse
            }
        } finally {
            hideLoading();
        }
    }, [currentLocal])

    const { data: units } = useQuery(['getUnits'], () => {
        try {
            showLoading()
            const filter = {
                page: 1,
                pageSize: 40
            }
            return getUnitUseCase(filter);
        } finally {
            hideLoading();
        }
    }, {
        enabled: true
    })

    const { data: supplyLocals } = useQuery(['@localsStock'], () => getSupplyStorageLocalsUseCase({
        page: 1,
        pageSize: 50,
        ascending: true
    }),)

    const { data: products } = useQuery(['@products'], () => {
        try {
            showLoading();
            if (currentLocal) {
                const catalogService = CatalogService();
                return GetAllProductsUseCase(catalogService, currentLocal?.id);
            }
            return []
        } finally {
            hideLoading();
        }
    })


    const submitHandler = useCallback(async (values: ISupplyFormValues) => {
        try {
            showLoading();
            if (currentLocal) {
                if (id) {
                    await UpdateSupplyUseCase(currentLocal.id, id, values)
                } else {
                    await SaveSupplyUseCase(currentLocal.id, values)
                }
            }
        } finally {
            hideLoading();
        }

    }, [])

    const getCompositions = useCallback(async (supplyId: string) => {
        if (currentLocal) {
            const compositions = await getCompositionsUseCase(currentLocal.id, supplyId)
            return compositions ?? [];
        }
        return []
    }, [])

    const onSuccessfulSubmit = useCallback((data: any) => {
        queryClient.invalidateQueries(['@supply'])

        replace('/private/meeperp/supply')
    }, [replace])

    const submitForm = useMutation((values: ISupplyFormValues) => submitHandler(values), {

        onSuccess: onSuccessfulSubmit
    })

    const onClickSubmitForm = async (values: ISupplyFormValues) => {
        await submitForm.mutateAsync(values);
    }

    const getDefaultValue = useCallback(async (localId?: string, id?: string) => {
        try {
            showLoading();
            if (!id) return;
            if (!localId) throw new Error("Local não econtrado!");
            const response = await getSupplyUseCase(localId, id);
            return response;
        } finally {
            hideLoading();
        }
    }, [])

    const defaultValue = useQuery('@defaultSupply', () => getDefaultValue(currentLocal?.id, id), {
        enabled: !!id && !!currentLocal?.id,
    })

    return (
        <div id={styles.CreateOrUpdateSupplyPage} >
            <Back route='/private/meeperp/supply' margin />
            <div className={styles.container} >
                <SupplyForm
                    onSubmit={onClickSubmitForm}
                    isLoading={submitForm.isLoading}
                    disabled={submitForm.isLoading}
                    getSupplyGroupList={getSupplyGroupListHandler}
                    units={units}
                    storageLocation={supplyLocals}
                    products={products}
                    defaultValues={defaultValue.data}
                    GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                    getSupplyStockList={getSupplyStockList}
                    getCompositions={getCompositions}

                />
            </div>
        </div>
    )
}
export default CreateOrUpdateSupplyPage