import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './SupplyGroupListFilter.module.scss'
import { ISupplyGroupFilterValue } from './ISupplyGroupFilter'
import { useForm } from 'react-hook-form'
import { Container, FilterButtonContainer, InputContainer, Row } from 'modules/meepErp/presentation/components/ui/form/FormContainers'
import { Icon, MenuItem, TextField } from '@material-ui/core'
import Button from 'components/ui/Button/Button'
import { IGetSupplyItemNameResponse, IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'
import AutocompleteInput from 'modules/meepErp/presentation/components/autocomplete/AutocompleteInput'
export interface ISupplyGroupListFilterProps {
    defaultValues: ISupplyGroupFilterValue,
    submitFiler: (valuesList: ISupplyGroupFilterValue) => void
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>

}

/*
canUpdateInventory
code
name
*/
const SupplyGroupListFilter: FC<ISupplyGroupListFilterProps> = ({
    defaultValues,
    submitFiler,
    GetSearchNameSupplyGroup
}) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm<ISupplyGroupFilterValue>(
        {
            defaultValues
        }
    )
    const [searchNameSupplySelect, setSearchNameSupplySelect] = useState<IGetSupplyItemNameResponse>()
    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.orderBy;
        delete allFields.ascending;

        return Object.values(allFields).some(value => value !== undefined && value !== '' && value !== null);
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            code: '',
            name: '',
            canUpdateInventory: null,
        });
        submitFiler({
            code: '',
            name: '',
            canUpdateInventory: null,
        })
    }, [submitFiler, reset])


    const getSearchNameSupplyGroup = (request: IGetSupplyNameRequest) => {
        if (request.keyword.length > 0) {
            return GetSearchNameSupplyGroup(request);
        } else {
            return Promise.resolve({
                page: 1,
                pageSize: 0,
                totalPages: 1,
                items: []
            });
        }
    };

    return (<form onSubmit={handleSubmit(submitFiler)} >
        <Container >
            <Row className={styles.row}>
                <InputContainer label='Código'>
                    <TextField type="text" {...register('code')} placeholder="Código" />
                </InputContainer>
                <InputContainer label='Nome'>
                    {/* <TextField type="text" {...register('name')} placeholder="Nome" /> */}

                    <AutocompleteInput
                        getOptionLabel={(option) => `${option?.name}`}
                        textInput={searchNameSupplySelect?.name}
                        getList={(request) => getSearchNameSupplyGroup(request)}
                        onSelect={(value) => {
                            value && setValue("name", value.code)
                            value && setSearchNameSupplySelect(value);
                        }}
                        value={searchNameSupplySelect}
                    />

                </InputContainer>
                <InputContainer label='Atualiza estoque'>
                    <TextField
                        select
                        type="text" {...register('canUpdateInventory')}
                        value={watch('canUpdateInventory') ?? ''}
                    >
                        <MenuItem value={''}>Todos</MenuItem>
                        <MenuItem value={'true'}>Sim</MenuItem>
                        <MenuItem value={'false'}>Não</MenuItem>
                    </TextField>
                </InputContainer>

                <InputContainer  >
                    <FilterButtonContainer minWidth={100} >
                        {isAnyFieldFilled &&
                            <Button
                                onClick={onClickResetFilter}
                                variant="text"
                                color="primary"
                                startIcon={<Icon>close</Icon>}>
                                Limpar filtro
                            </Button>}
                        <Button color='secondary' type="submit" style={{ width: '76px', minWidth: '76px' }}>Buscar</Button>
                    </FilterButtonContainer>
                </InputContainer>
            </Row>
        </Container>
    </form>)
}
export default SupplyGroupListFilter