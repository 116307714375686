import React, { FC } from 'react'
import styles from './CreateProductDrawer.module.scss'
import { Icon } from '@material-ui/core'
import ContainerDrawer from '../ui/ContainerDrawer'
import { AddShoppingCartOutlined, AssignmentOutlined } from '@material-ui/icons'
export interface ICrateProductDrawerProps {
    //propertys
    onClickNew: () => void
    onClickExist: () => void
    onClose(): void
    catalogId?: string
}
const CreateProductDrawer: FC<ICrateProductDrawerProps> = ({ onClickNew, onClickExist, onClose, catalogId }) => {
    return (
        <div id={styles.CreateProductDrawer} >
            <ContainerDrawer title={<div>Novo <b>Produto</b></div>}
                onClose={onClose}
            >
                <div className={styles.container} >
                    <span>Selecione como você quer adicionar o seu produto:</span>
                    <button onClick={onClickNew}>
                        <AddShoppingCartOutlined />
                        <span>
                            <b>Criar um produto <span style={{ color: "rgba(149, 92, 255, 1)" }}>novo</span></b><br />
                            Crie um produto do zero
                        </span>
                    </button>
                    {(catalogId && catalogId !== 'undefined') &&
                        <button onClick={onClickExist}>
                            <AssignmentOutlined />
                            <span>
                                <b>Usar produto <span style={{ color: "rgba(149, 92, 255, 1)" }}>existente</span></b><br />
                                Use um produto de outra lista
                            </span>
                        </button>
                    }
                </div>
            </ContainerDrawer>
        </div>
    )
}
export default CreateProductDrawer