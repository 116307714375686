import { Pagination } from '@material-ui/lab'
import RankingList from 'modules/salesDashboard/presentation/components/rakingList/RankingList'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Search } from '@mui/icons-material';
import { FormControl, TextField, FormLabel, Button } from '@mui/material'
import Utils from 'services/utils/Utils'
import { ILastOrdersRankingData } from './ILastOrdersRankingData'
import styles from './LastOrdersRanking.module.scss'
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
export interface ILastOrdersRankingProps {
    //propertys
    data?: ILastOrdersRankingData,
    getData: (filter: ILastOrderFilter) => void
    isLoading: boolean
}

export interface ILastOrderFilter {
    page?: number, pageSize?: number
    sort?: { type?: string, orientation?: 'asc' | 'desc' }
    keyword?: string,
}

const PAGESIZE = 10;
const LastOrdersRanking: FC<ILastOrdersRankingProps> = ({ data, getData, isLoading: onLoading }) => {

    const [filter, setFilter] = useState<ILastOrderFilter>({ page: 0, pageSize: PAGESIZE })
    const [keyword, setKeyword] = useState('')

    const [orientation, setOrientation] = useState<'asc' | 'desc'>('asc')
    const {isMobile} = useUi();
    const getMore = useCallback((request?: { pagination?: { page?: number, pageSize?: number } }) => {
        setFilter(prev => ({ ...prev, page: request?.pagination?.page, pageSize: PAGESIZE }))
    }, [])

    const sort = useCallback((key: string) => {
        setFilter(prev => ({ ...prev, sort: { type: key, orientation: orientation } }))
        setOrientation(prev => prev === 'asc' ? 'desc' : 'asc')
    }, [orientation])


    useEffect(() => {
        getData({ ...filter, keyword })
    }, [filter, getData])

    const handleSearch = () => {
        setFilter(prev => ({ ...prev, page: 0 }));
    }

    return (
        <div id={styles.LastOrdersRanking} >
            <div className={styles.filter} >
                <div>

                    <FormControl fullWidth={isMobile ? true : false}>
                        <FormLabel className={styles.label}>Produto</FormLabel>
                        <TextField
                            placeholder="Selecione um produto"
                            fullWidth
                            className={styles.search}
                            InputProps={{
                                endAdornment: <Search style={{ color: "#5f5f5f" }} />
                            }}
                            size='small' name={'input'} onChange={(e) => setKeyword(e.target.value)} value={keyword}
                        />
                    </FormControl>

                </div>
                <div className={styles.containerButton}>

                    <Button
                        className={styles.filterButton}
                        fullWidth
                        variant='contained'
                        type='submit'
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </div>
            </div>
            {
                !data?.records.length ?
                    <div id={styles.GestListLive}>
                        <ErrorMessage type="warning" />
                    </div>
                    :
                    <RankingList
                        list={data?.records}
                        count={data?.totalRecords}
                        getMore={getMore}
                        isLoading={onLoading}
                        pagination={{ page: (filter?.page ?? 0) + 1, pageSize: filter.pageSize }}
                        colunms={{
                            imageUrl: {},
                            productName: { title: "Produto",/* onClickSort: sort*/ },
                            total: { title: "Valor", /*onClickSort: sort*/ },
                            customerName: { title: "Cliente", /*onClickSort: sort*/ },
                            operatorName: { title: "Operador", /*onClickSort: sort*/ },
                            deviceName: { title: "Origem", /*onClickSort: sort*/ },
                            createdDate: {
                                title: "Data", /*onClickSort: sort,*/ formatValue: (value) => Utils.toDateAndTime(`${value}Z`)
                            }
                        }}
                    />
            }
        </div>
    )
}
export default LastOrdersRanking