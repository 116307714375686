import Layout from 'components/layout/presentation/Layout';
import styles from './InvoicePage.module.scss';
import { TaxTable } from './TaxTable/TaxTable';
import { Modal } from '@material-ui/core';
import { WaitingEnvironment } from './components/WaitingEnvironment/WaitingEnvironment';
import SimpleTrialModal from 'modules/plans/presentation/components/simpleTrialModal/SimpleTrialModal';
import { UseInvoicePage } from './hooks/UseInvoicePage';

export const InvoicePage = () => {
  const {
    preLoading,
    company,
    loading,
    companyStatus,
    openSimpleTrialModal,
    setOpenSimpleTrialModal,
    handleCreateEnvironment
  } = UseInvoicePage();

  if (preLoading !== false) {
    return (
      <Layout>
        <div className={styles.noDataContainer}>
          <img src="/assets/icon/saas/loading.gif" alt="" />
        </div>
      </Layout>
    )
  }

  if (companyStatus !== 'created') {
    return (
      <Layout>
        {
          companyStatus === 'notCreated' ?
          <>
            <div className={styles.noDataContainer}>
              <div>
                <img src="/assets/icon/delete-confirmation.svg" alt="" />
                <span>Para ter acesso ao menu fiscal,{'\n'}<b>complete as informações.</b></span>
                <button onClick={handleCreateEnvironment} disabled={loading}>
                  Criar ambiente
                </button>
              </div>
            </div>

            <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />

            <Modal open={loading} className={styles.modal}>
              <div className={styles.modalContainer}>
                <img src="/assets/icon/saas/loading.gif" alt="" />
                <h1><strong>Aguarde, </strong>estamos preparando{'\n'}seu ambiente fiscal</h1>
                <p>Isso pode levar um tempo, aguarde...</p>
              </div>
            </Modal>
          </> :
          <WaitingEnvironment />
        }
      </Layout>
    )
  }

  return (
    <TaxTable company={company!} />
  )
}