import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useQuery } from "hooks/UseQuery";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProspectSaasUseCase } from "modules/saas/application/useCases/ProspectSaasUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { IProspect, ProspectStatus } from "../../interfaces/prospect/IProspect";
import { IAddressSaas } from "../../interfaces/address/IAddressSaas";
import { SaveAddressUseCase } from "modules/saas/application/useCases/SaveAddressUseCase";
import CheckoutTrialUseCase from "modules/saas/application/useCases/CheckoutTrialUseCase";
import UseProspect from "../../hooks/UseProspect";
import axios from "axios";
import CheckoutCieloUseCase from "modules/saas/application/useCases/CheckoutCieloUseCase";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import { UseFormClearErrors, UseFormGetValues, UseFormReset, UseFormSetError } from "react-hook-form";
import { IAddressSchema } from "modules/saas/domain/schema/addressSchema";
import { getParamsString } from "../../utils/params";

const service = SaasApi();

export const UseAddressPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState<IAddressSaas>();
    const [prospect, setProspect] = useState({} as IProspect);
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    let query = useQuery();
    const history = useHistory();
    const { toast, showLoading, hideLoading } = useUi();
    const { setProspectId, setKey, setEmail, setType, type } = UseProspect();
    const location = useLocation<{ type: string }>();

    const prospectId = query.get('prospectId');
    const key = query.get('key');

    useEffect(() => {
        if (!prospectId || !key) {
            history.push(`/public/saas/register?email=${prospect.email}`, + getParamsString(query));
        } else {
            setProspectId(prospectId);
            setKey(key);
        }
    }, [history, key, prospect.email, prospectId, query, setKey, setProspectId]);

    useEffect(() => {
        (async () => {
            try {
                showLoading();
                if (prospectId && key) {
                    const response = await ProspectSaasUseCase(service, prospectId, key);
                    if(response.status === ProspectStatus.Trial){
                        history.push(`/public/saas/trial?prospectId=${prospectId}&key=${key}`);
                    } else if (response.status === ProspectStatus.Plan) {
                        history.push(`/public/saas/success?prospectId=${prospectId}&key=${key}`);
                    }
                    setProspect(response);
                    setEmail(response.email);
                    setType(response.type);
                    setDefaultValues(response.establishment.address);

                    if (response.type === ProspectType.Cielo || response.type === ProspectType.CieloEvent || response.type === ProspectType.CieloEstablishment) {
                        setProgressBarColor("#00739E");
                        setBackgroundColorTop("#00AEEF");
                        setBackgroundColorBottom("#00AEEF");
                    } else {
                        setProgressBarColor("#865FDD");
                        setBackgroundColorTop("#6B41C9");
                        setBackgroundColorBottom("#865FDD");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    toast('A chave fornecida está inválida ou expirou.', 'error');
                    history.push("/public/saas/register", { type: location.state.type });
                } else {
                    toast('Ocorreu um erro ao fazer a busca dos dados. Tente novamente.', 'error');
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, history, key, location.state?.type, prospectId, query, setEmail, setType, showLoading, toast]);

    // const chckoutTrial = useCallback(async () => {
    //     try {
    //         setFormLoading(true);


    //         const prospectId = query.get('prospectId');
    //         const key = query.get('key');
    //         if (prospectId) {
    //             const service = SaasApi();
    //             await CheckoutTrialUseCase(service, prospectId!, key!);
    //             // history.push(`/public/saas/checkout?prospectId=${prospectId}&key=${key}`);
    //         }else{
    //             history.push('/');
    //         }
    //     } catch (error: any) {
    //         toast(error.message, 'error');
    //         // history.goBack();
    //     } finally {
    //         setLoading(false);
    //     }
    // }, [history, query, toast])


    // useEffect(() => {

    //     chckoutTrial();
    // }, [chckoutTrial])

    const handleSearchByCEP = useCallback(async (
        value: string,
        clearErrors: UseFormClearErrors<IAddressSchema>,
        reset: UseFormReset<IAddressSchema>,
        getValues: UseFormGetValues<IAddressSchema>,
        setError: UseFormSetError<IAddressSchema>
    ) => {
        const cep = value.replace(/\D/g, "");
        const validateCEP = /^[0-9]{8}$/;

        if(validateCEP.test(cep)) {
            try {
                showLoading();
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                
                if (!response.data.erro) {
                    clearErrors("zipCode");
                    reset({
                        ...getValues(),
                        street: response.data.logradouro,
                        neighborhood: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf,
                        country: "Brasil",
                    });
                } else {
                    setError("zipCode", { message: "CEP inválido!" });
                }
            } finally {
                hideLoading();
            }            
        } else {
            setError("zipCode", { message: "CEP inválido!" });
        }
    }, [hideLoading, showLoading]);

    const handleSubmit = useCallback(async (values: IAddressSchema) => {
        if (prospect.type === ProspectType.TrialDays) {
            setIsLoading(true);
        } else {
            showLoading();
        }
        setTimeout(async () => {
            try {
                await SaveAddressUseCase(service, values, prospectId!, key!);

                if(prospect.type === ProspectType.TrialDays){
                    await CheckoutTrialUseCase(service, prospectId!, key!);
                    return history.push(`/public/saas/trial?prospectId=${prospectId}&key=${key}`);
                }                
                if (prospect.type === ProspectType.Cielo) {
                    await CheckoutCieloUseCase(service, prospectId!, key!);
                    return history.push(`/public/saas/success?prospectId=${prospectId}&key=${key}&type=3`);
                }

                history.push(`/public/saas/payment?prospectId=${prospectId}&key=${key}`, { type: type });
            } catch {
                toast('Ocorreu um erro ao salvar os dados. Tente novamente.', 'error');
            } finally {
                setIsLoading(false);
                hideLoading();
            }
        }, 2000);
    }, [hideLoading, history, key, prospect.type, prospectId, showLoading, toast, type]);

    return {
        isLoading,
        defaultValues,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSearchByCEP,
        handleSubmit,
    }
}