import { ImportExport } from "@mui/icons-material";
import styles from "./TutorialHeader.module.scss";
import { useEffect, useState } from "react";
import { IGetTutoriaisParams } from "modules/tutoriais/domain/dto/IGetTutoriaisParams";

export interface ITutorialHeaderProps {
  params: IGetTutoriaisParams;
  setParams: React.Dispatch<React.SetStateAction<IGetTutoriaisParams>>;
}

const titleHeader = [
  {
    title: "Data publicação",
    value: "createdAt",
  },
  {
    title: "Título",
    value: "title",
  },
  {
    title: "Menu",
    value: "menuTitle",
  },
];

const TutorialHeader = ({ params, setParams }: ITutorialHeaderProps) => {
  const [ordemColumnDesc, setOrdemColumnDesc] = useState(false);
  const [orderByColumn, setOrderByColumn] = useState("");

  useEffect(() => {
    setParams({...params, ordemColumnDesc: ordemColumnDesc, orderByColumn: orderByColumn });
  }, [ordemColumnDesc, orderByColumn]);

  return (
    <div id={styles.TutorialHeader}>
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            <div 
              onClick={() => {
                setOrdemColumnDesc(!ordemColumnDesc);
                setOrderByColumn(item.value);
              }}
            >
              <ImportExport />
            </div>
          </div>          
        );
      })}
    </div>
  );
};
export default TutorialHeader;
