import { FC } from "react";
import Layout from "components/layout/presentation/Layout";
import ConfigCrmWhatsapp from "modules/crm/presentation/pages/configCrmWhatsapp/ConfigCrmWhatsapp";

const ConfigCampanha = () => {
  return (
    <Layout>
      <ConfigCrmWhatsapp />
    </Layout>
  );
};
export default ConfigCampanha;
