import styles from "./Wizard.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowBackIosNewRounded, CloseRounded } from "@mui/icons-material";
import { IGetWizardResponse } from "../domain/dto/IGetWizardResponse";
import WizardInfo from "./wizardInfo/WizardInfo";
import { UseWizard } from "./UseWizard";
import UseDimension from "components/dimension/UseDimension";
import { useLocal } from "modules/local/presentation/context/LocalContext";

interface IWizardProps {
  onClose: () => void;
  wizardContent: IGetWizardResponse;
}

const Wizard = ({ onClose, wizardContent }: IWizardProps) => {
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  const [step, setStep] = useState(1);
  const [openWizardInfo, setOpenWizardInfo] = useState(false);

  const { postWizardActivity } = UseWizard();
  const { isMobile } = UseDimension();
  const { currentLocal } = useLocal();

  useEffect(() => {
    setDisableNextButton(false);
    setDisablePreviousButton(false);
    if (step === wizardContent.pageWizardItems.length) {
      return setDisableNextButton(true);
    }
    if (step === 1) {
      return setDisablePreviousButton(true);
    }
  }, [step, wizardContent.pageWizardItems]);

  const currentContent = useMemo(
    () => wizardContent.pageWizardItems[step - 1],
    [step, wizardContent.pageWizardItems]
  );

  const handleCloseWizard = useCallback(async () => {
    if (currentLocal) {
      const response = await postWizardActivity({
        pageWizardId: wizardContent.id,
      });
      if (response === "ok") return setOpenWizardInfo(true);
    }
  }, [postWizardActivity, wizardContent.id, currentLocal]);

  return (
    <>
      {!openWizardInfo ? (
        <div id={styles.Wizard}>
          <div
            className={
              isMobile ? styles.dialogContentMobile : styles.dialogContent
            }
          >
            <div className={styles.dialogTitle}>
              <span>
                {currentContent.title}
                <span className={styles.newDialog}>Novo</span>
              </span>
              <div
                onClick={handleCloseWizard}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CloseRounded className={styles.closeIcon} />
              </div>
            </div>
            <div className={styles.dialogDescription}>
              <img
                src={
                  !!currentContent.imagePath
                    ? currentContent.imagePath
                    : "/assets/img/tutorial-default.png"
                }
                alt="Tutorial"
                width="50%"
              />
              <span>{currentContent.description}</span>
            </div>
            <div className={styles.dialogActions}>
              {wizardContent.fullTutorialUrl ? (
                <button
                  className={styles.seeFullTutorial}
                  onClick={() =>
                    window.open(wizardContent.fullTutorialUrl, "_blank")
                  }
                >
                  Ver tutorial completo
                </button>
              ) : (
                <div />
              )}
              <div className={styles.setepsContent}>
                {step - 1 >= 1 && (
                  <button
                    className={styles.setepsButton}
                    onClick={() => setStep(step - 1)}
                    disabled={disablePreviousButton}
                  >
                    <ArrowBackIosNewRounded className={styles.stepsIcon} />
                  </button>
                )}
                <span>
                  {step}
                  <b>/{wizardContent.pageWizardItems.length}</b>
                </span>
                {step !== wizardContent.pageWizardItems.length ? (
                  <button
                    className={styles.setepsButton}
                    style={{
                      transform: "rotate(180deg)",
                    }}
                    onClick={() => setStep(step + 1)}
                    disabled={disableNextButton}
                  >
                    <ArrowBackIosNewRounded className={styles.stepsIcon} />
                  </button>
                ) : (
                  <div style={{ width: "16px" }} />
                )}
              </div>
              <img
                src="/assets/icon/wizard-triangle.svg"
                alt=""
                width="20px"
                style={{
                  position: "absolute",
                  bottom: "-25px",
                  right: "-52px",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <WizardInfo onClose={onClose} />
      )}
    </>
  );
};

export default Wizard;
