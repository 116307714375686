import React, { useCallback, useState } from 'react'
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type';
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService';
import { OperationStringEnum } from '../components/operationFilter/OperationFilter';
import { ILastOrdersRankingData } from '../components/_lastOrdersRanking/ILastOrdersRankingData';
import GetLastOrderRankingUseCase from 'modules/customersDashboard/application/useCases/GetLastOrderRankingUseCase';
import { ILastOrderFilter } from '../components/_lastOrdersRanking/LastOrdersRanking';

const UseLastOrdersRanking = (filter: ICustomerDashboardFilter, operationFilter?: OperationStringEnum) => {

    const [data, setData] = useState<ILastOrdersRankingData>();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>()

    const getData = useCallback(async (_filter: ILastOrderFilter) => {
        try {
            // if (_filter.page === 0) {
            setIsLoading(true)
            // }
            const service = CustomersDashboardService();
            const response = await GetLastOrderRankingUseCase(service, { ...filter, ..._filter, operation: operationFilter });
            setData(response)
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [filter, operationFilter])

    return ({
        data,
        isLoading,
        error,
        getData,
    })
}

export default UseLastOrdersRanking