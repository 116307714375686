import { Button, CircularProgress } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import styles from './Filter.module.scss'
import { IFilterValue } from './IFilter';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IProduct } from './IProduct';
import { addDays, differenceInDays, endOfDay, format, parse, startOfDay, subDays } from 'date-fns';
import { ICashiers } from '../../interfaces/ICashiers';
import { IVendingDevice } from '../../interfaces/IVendingDevice';
import { IValidationDevice } from '../../interfaces/IValidationDevice';
import { IValidationOperator } from '../../interfaces/IValidationOperator';
import { ICategory } from '../../interfaces/ICategories';
import Input from 'components/ui/input/Input';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import { ITicketsValidation } from '../../interfaces/ITicketsValidation';

export interface IFilterProps {
    onSubmit: (value: IFilterValue) => void;
    onExport: () => void;
    defaultValues?: IFilterValue;
    isLoading?: boolean;
    cashiers: ICashiers[];
    products: IProduct[];
    vendingDevices: IVendingDevice[];
    validationDevices: IValidationDevice[];
    validationOperators: IValidationOperator[];
    categories: ICategory[];
    parentCategories: ICategory[];
    data?: ITicketsValidation
}

type FilterType = {value: 'cashier' | 'sale' | 'validation', description: string};

const filterTypes: Array<FilterType> = [{
        value: 'cashier',
        description: 'Caixa'
    }, {
        value: 'sale',
        description: 'Período de venda'
    }, {
        value: 'validation',
        description: 'Período de validação'
    }
]

const Filter: FC<IFilterProps> = ({ onSubmit, onExport, defaultValues, isLoading, cashiers, products, vendingDevices, validationDevices, validationOperators, categories, parentCategories, data }) => {
    const { toast } = useUi();

    const [selectedFilterType, setSelectedFilterType] = useState<FilterType | undefined>(filterTypes[0])
    const [values, setValues] = useState<IFilterValue>({
        productId: ''
    });
    const [showAdvanced, setShowAdvanced] = useState(false)

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues)
        }
    }, [defaultValues]);

    useEffect(() => {
        setValues(prev => ({ ...prev, cashierId: undefined, salesEndDate: undefined, salesStartDate: undefined, validationStartDate: undefined, validationEndDate: undefined }))
    }, [selectedFilterType])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const request = {
            ...values,
            salesStartDate: values.salesStartDate ? format(startOfDay(parse(values.salesStartDate.split('T')[0], 'yyyy-MM-dd', new Date())), "yyyy-MM-dd'T'HH:mm:ssXXX") : undefined,
            salesEndDate: values.salesEndDate ? format(endOfDay(parse(values.salesEndDate.split('T')[0], 'yyyy-MM-dd', new Date())), "yyyy-MM-dd'T'HH:mm:ssXXX") : undefined,
            validationStartDate: values.validationStartDate ? format(startOfDay(parse(values.validationStartDate.split('T')[0], 'yyyy-MM-dd', new Date())), "yyyy-MM-dd'T'HH:mm:ssXXX") : undefined,
            validationEndDate: values.validationEndDate ? format(endOfDay(parse(values.validationEndDate.split('T')[0], 'yyyy-MM-dd', new Date())), "yyyy-MM-dd'T'HH:mm:ssXXX") : undefined,
        };

        if (request.customerCPF && !(/^[0-9]{4}$/).test(request.customerCPF)){
            toast('O final do CPF deve conter 4 digitos numéricos.', "error");
            return;
        }

        if (request.minimumValue !== undefined && request.maximumValue !== undefined && request.minimumValue > request.maximumValue){
            toast('O valor máximo deve ser maior que o valor mínimo', "error");
            return;
        }

        if (selectedFilterType?.value === 'cashier') {
            if (!request.cashierId) {
                toast('Selecione um caixa para filtrar.', "error");
                return;
            } 
            
            onSubmit(request)
        } else if (selectedFilterType?.value === 'sale') {
            if (!request.salesStartDate || !request.salesEndDate) {
                toast('Selecione um período de venda para filtrar.', "error");
                return;
            }

            onSubmit(request)
        } else if (selectedFilterType?.value === 'validation') {
            if (!request.validationStartDate || !request.validationEndDate) {
                toast('Selecione um período de validação para filtrar.', "error");
                return;
            }

            onSubmit(request)
        }

    }, [onSubmit, selectedFilterType?.value, toast, values]);

    const handleChangeSalesStartDate = (salesStartDate: string) => {
        const startDate = parse(salesStartDate, 'yyyy-MM-dd', new Date());
        const endDate = values.salesEndDate ? parse(values.salesEndDate, 'yyyy-MM-dd', new Date()) : undefined;

        setValues(prev => ({ ...prev, salesStartDate }))

        if (endDate){
            if (differenceInDays(endDate, startDate) > 29)
                setValues(prev => ({ ...prev, salesEndDate: format(addDays(startDate, 29), 'yyyy-MM-dd') }))
            else if (differenceInDays(endDate, startDate) < 0)
                setValues(prev => ({ ...prev, salesEndDate: format(startDate, 'yyyy-MM-dd') }))
        }
    }

    const handleChangeSalesEndDate = (salesEndDate: string) => {
        const startDate = values.salesStartDate ? parse(values.salesStartDate, 'yyyy-MM-dd', new Date()) : undefined;
        const endDate = parse(salesEndDate, 'yyyy-MM-dd', new Date());

        setValues(prev => ({ ...prev, salesEndDate }))

        if (startDate){
            if (differenceInDays(endDate, startDate) > 29)
                setValues(prev => ({ ...prev, salesStartDate: format(subDays(endDate, 29), 'yyyy-MM-dd') }))
            else if (differenceInDays(endDate, startDate) < 0)
                setValues(prev => ({ ...prev, salesStartDate: format(endDate, 'yyyy-MM-dd') }))
        }
    }

    const handleChangeValidationStartDate = (validationStartDate: string) => {
        const startDate = parse(validationStartDate, 'yyyy-MM-dd', new Date());
        const endDate = values.validationEndDate ? parse(values.validationEndDate, 'yyyy-MM-dd', new Date()) : undefined;

        setValues(prev => ({ ...prev, validationStartDate }))

        if (endDate) {
            if (differenceInDays(endDate, startDate) > 29)
                setValues(prev => ({ ...prev, validationEndDate: format(addDays(startDate, 29), 'yyyy-MM-dd') }))
            else if (differenceInDays(endDate, startDate) < 0)
                setValues(prev => ({ ...prev, validationEndDate: format(startDate, 'yyyy-MM-dd') }))
        }
    }

    const handleChangeValidationEndDate = (validationEndDate: string) => {
        const startDate = values.validationStartDate ? parse(values.validationStartDate, 'yyyy-MM-dd', new Date()) : undefined;
        const endDate = parse(validationEndDate, 'yyyy-MM-dd', new Date());

        setValues(prev => ({ ...prev, validationEndDate }))

        if (startDate){
            if (differenceInDays(endDate, startDate) > 29)
                setValues(prev => ({ ...prev, validationStartDate: format(subDays(endDate, 29), 'yyyy-MM-dd') }))
            else if (differenceInDays(endDate, startDate) < 0)
                setValues(prev => ({ ...prev, validationStartDate: format(endDate, 'yyyy-MM-dd') }))
        }
    }

    return (
        <div id={styles.Filter} >
            <form onSubmit={submitHandle}>
                <div className={styles.simple}>
                    <div className={styles.filterItem}>
                        <Autocomplete
                            className={styles.input}
                            style={{ flex: 2, minWidth: 350 }}
                            size='small'
                            disableClearable
                            renderInput={(props) =>
                                <div className={styles.inputContainer}>
                                    <span>Filtrar por</span>
                                    <TextField
                                        maxRows={1}
                                        label={undefined}
                                        name={"selectedFilterType"}
                                        {...props}
                                    />
                                </div>
                            }
                            isOptionEqualToValue={(option, values) => option.value === values.value}
                            options={filterTypes}
                            getOptionLabel={(option) => option.description}
                            onChange={(ev, value) => setSelectedFilterType(value || undefined)}
                            value={filterTypes.find(x => x.value === selectedFilterType?.value)}

                        />
                    </div>
                    {
                        selectedFilterType?.value === 'cashier' &&
                            <div className={`${styles.filterItem} ${styles.cashier}`}>
                                <Autocomplete
                                    className={styles.input}
                                    style={{ flex: 2, minWidth: 350 }}
                                    size='small'
                                    renderInput={(props) =>
                                        <div className={styles.inputContainer}>
                                            <span>Caixa</span>
                                            <TextField
                                                maxRows={1}
                                                placeholder={'Filtrar por caixa'}
                                                name={"cashierId"}
                                                {...props}
                                            />
                                        </div>
                                    }
                                    renderOption={(props, option) => {
                                        return (
                                            <span {...props} key={option.sessaoId}>
                                                {option.sessaoNome}
                                            </span>
                                        );
                                    }}
                                    isOptionEqualToValue={(option, values) => option.sessaoId === values.sessaoId}
                                    options={cashiers}
                                    getOptionLabel={(option) => option.sessaoNome}
                                    onChange={(ev, value) => setValues(prev => ({ ...prev, cashierId: value?.sessaoId }))}
                                    value={cashiers.find(x => x.sessaoId === values.cashierId)}

                                />
                            </div>
                    }
                    {
                        selectedFilterType?.value === 'sale' &&
                            <>
                                <div className={styles.filterItem}>
                                    <label>Período venda - início</label>
                                    <TextField inputProps={{ placeholder: 'dd/mm/aaaa' }} size='small' name={'salesStartDate'} onChange={(e) => handleChangeSalesStartDate(e.target.value)} fullWidth value={values.salesStartDate ? values.salesStartDate.split('T')[0] : undefined} type="date" />
                                </div>
                                <div className={styles.filterItem}>
                                    <label>Período venda - fim</label>
                                    <TextField size='small' name={'salesEndDate'} onChange={(e) => handleChangeSalesEndDate(e.target.value)} fullWidth value={values.salesEndDate ? values.salesEndDate.split('T')[0] : undefined} type="date" />
                                </div>
                            </>
                    }
                    {
                        selectedFilterType?.value === 'validation' &&
                            <>
                                <div className={styles.filterItem}>
                                    <label>Período validação - início</label>
                                    <TextField size='small' name={'validationStartDate'} onChange={(e) => handleChangeValidationStartDate(e.target.value)} fullWidth value={values.validationStartDate ? values.validationStartDate.split('T')[0] : undefined} type="date" />
                                </div>
                                <div className={styles.filterItem}>
                                    <label>Período validação - início</label>
                                    <TextField size='small' name={'validationEndDate'} onChange={(e) => handleChangeValidationEndDate(e.target.value)} fullWidth value={values.validationEndDate ? values.validationEndDate.split('T')[0] : undefined} type="date" />
                                </div>
                            </>
                    }
                    {
                        !showAdvanced &&
                        <Button
                            className={styles.button}
                            variant={'contained'}
                            color={'primary'}
                            disabled={isLoading}
                            type={'submit'}

                        >
                            Buscar
                            {isLoading && <CircularProgress />}
                        </Button>
                    }
                </div>

                <div className={styles.controls}>
                    <button type="button" onClick={onExport} disabled={!data?.records.length}>
                        Exportar
                        <img className={styles.export} src="/assets/icon/export.svg" alt="" />
                    </button>
                    <button type="button" onClick={() => setShowAdvanced(prev => !prev)}>
                        Filtros avançados
                        {
                            showAdvanced ?
                                <img className={styles.closed} src="/assets/icon/advanced-filter-closed.svg" alt="" /> :
                                <img className={styles.opened} src="/assets/icon/advanced-filter.svg" alt="" />
                        }
                    </button>
                </div>

                {
                    showAdvanced &&
                    <div className={styles.advanced}>
                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Produto</span>
                                        <TextField
                                            maxRows={1}
                                            placeholder={'Selecione o produto'}
                                            name={"productId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.nome}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={products}
                                getOptionLabel={(option) => option.nome}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, productId: value?.id }))}
                                value={products.find(x => x.id === values.productId)}

                            />
                        </div>
                        <div className={styles.filterItem}>
                            <label>Nome do cliente</label>
                            <TextField size='small' name={'customerName'} onChange={(e) => setValues(prev => ({ ...prev, customerName: e.target.value }))} fullWidth value={values.customerName} />
                        </div>
                        <div className={styles.filterItem}>
                            <label>Final do CPF</label>
                            <TextField inputProps={{ maxLength: 4 }} size='small' name={'customerCPF'} onChange={(e) => setValues(prev => ({ ...prev, customerCPF: e.target.value }))} fullWidth value={values.customerCPF} />
                        </div>

                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Equipamento de validação</span>
                                        <TextField
                                            maxRows={1}
                                            placeholder={'Selecione o equipamento'}
                                            name={"validationDeviceId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.nome}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={validationDevices}
                                getOptionLabel={(option) => option.nome}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, validationDeviceId: value?.id }))}
                                value={validationDevices.find(x => x.id === values.validationDeviceId)}

                            />
                        </div>
                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Operador de validação</span>
                                        <TextField
                                            maxRows={1}
                                            placeholder={'Selecione o operador'}
                                            name={"validationOperatorId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.nome}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={validationOperators}
                                getOptionLabel={(option) => option.nome}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, validationOperatorId: value?.id }))}
                                value={validationOperators.find(x => x.id === values.validationOperatorId)}

                            />
                        </div>

                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Equipamento de venda</span>
                                        <TextField
                                            maxRows={1}
                                            placeholder={'Selecione o equipamento'}
                                            name={"vendingDeviceId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.nome}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={vendingDevices}
                                getOptionLabel={(option) => option.nome}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, vendingDeviceId: value?.id }))}
                                value={vendingDevices.find(x => x.id === values.vendingDeviceId)}

                            />
                        </div>
                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Categoria pai</span>
                                        <TextField
                                            maxRows={1}
                                            placeholder={'Selecione a categoria'}
                                            name={"parentCategoryId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.descricao}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={parentCategories}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, parentCategoryId: value?.id }))}
                                value={parentCategories?.find(x => x.id === values.parentCategoryId)}

                            />
                        </div>
                        <div className={styles.filterItem}>
                            <Autocomplete
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Categoria</span>
                                        <TextField
                                            className={styles.ssssssss}
                                            maxRows={1}
                                            placeholder={'Selecione a categoria'}
                                            name={"categoryId"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.descricao}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={categories}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(ev, value) => setValues(prev => ({ ...prev, categoryId: value?.id }))}
                                value={categories.find(x => x.id === values.categoryId)}

                            />
                        </div>

                        <div className={`${styles.filterItem} ${styles.smallColumn}`}>
                            <label>Valor</label>
                            <Input
                                name={'minimumValue'}
                                variant='outlined'
                                placeholder='Valor mínimo'
                                value={!values.minimumValue && values.minimumValue !== 0 ? '' : moneyMaskNumber(values.minimumValue)}
                                className={styles.input}
                                onChange={ev => {
                                    setValues(prev => ({ ...prev, minimumValue: moneyToFloat(ev.target.value) }))
                                }}
                            />
                            {
                                !values.minimumValue !== undefined && values.minimumValue !== null &&
                                    <button type='button' className={styles.clearValues} onClick={() => setValues(prev => ({ ...prev, minimumValue: undefined }))}>
                                        <img src="/assets/icon/advanced-filter-closed.svg" alt="" />
                                    </button>
                            }
                        </div>

                        <div className={`${styles.filterItem} ${styles.smallColumn} ${styles.labelLess}`}>
                            <Input
                                name={'maximumValue'}
                                variant='outlined'
                                placeholder='Valor máximo'
                                value={!values.maximumValue && values.maximumValue !== 0 ? '' : moneyMaskNumber(values.maximumValue)}
                                className={styles.input}
                                onChange={ev => {
                                    setValues(prev => ({ ...prev, maximumValue: prev.maximumValue === 0 && ev.target.value === 'R$0,0' ? undefined :moneyToFloat(ev.target.value) }))
                                }}
                            />
                            {
                                !values.minimumValue !== undefined && values.minimumValue !== null &&
                                    <button type='button' className={styles.clearValues} onClick={() => setValues(prev => ({ ...prev, maximumValue: undefined }))}>
                                        <img src="/assets/icon/advanced-filter-closed.svg" alt="" />
                                    </button>
                            }
                        </div>

                        <div className={`${styles.filterItem} ${styles.smallColumn} ${styles.labelLess}`}>
                            <Button
                                className={styles.button}
                                variant={'contained'}
                                color={'primary'}
                                disabled={isLoading}
                                type={'submit'}

                            >
                                Buscar
                                {isLoading && <CircularProgress />}
                            </Button>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}
export default Filter