import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CopyDeviceProfileUseCase from "../../../application/useCase/deviceProfile/CopyDeviceProfileUseCase";
import GetOperationListUseCase from "../../../application/useCase/deviceProfile/GetOperationListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { IFilterProfileDevice, IPaginationProfileDevice } from "../../components/deviceProfile/filterProfile/IFilterDeviceProfile";
import { IDeviceProfileItem } from "../../components/deviceProfile/profileList/interfaces/IProfileItem";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import { useHistory, useParams } from "react-router-dom";
import { IOperationItem } from "../../components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue";
import DeleteProfileUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/DeleteProfileUseCase";
import ChangeDeviceProfileStatusUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/ChangeDeviceProfileStatusUseCase";
import DeviceApi from "services/api/equipamento/DeviceApi";
import GetDeviceListUseCase from "modules/equipamento/application/useCases/GetDeviceListUseCase";
import GetProfileListPaginationUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/GetProfileListPaginationUseCase";
import { PerfilApi } from "services/api/perfil/PerfilApi";
import GetPerfilVinculo from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/GetPerfilUseCase";

const deviceProvileService = DeviceProfileApi();
const perfilService = PerfilApi();

const UseProfilePage = (isEventLevel: boolean = false, path: string = "/private/pdv/perfil") => {
  const [profileList, setProfileList] = useState<IDeviceProfileItem[]>();
  const [filteredProfileList, setFilteredProfileList] =
    useState<IDeviceProfileItem[]>();
  const [filterProfile, setFilterProfile] = useState<IFilterProfileDevice>({});
  const [operationList, setOperationList] = useState<IOperationItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [profileToDelete, setProfileToDelete] = useState<IDeviceProfileItem>();
  const [profileNameList, setProfileNameList] = useState<string[]>([]);
  const [perfilVinculo, setPerfilVinculo] = useState<undefined | number>()

  const { showLoading, hideLoading } = useUi();

  const { currentLocal } = useLocal();

  const { push } = useHistory();

  const { action } = useParams<{ action: string }>();
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (profileToDelete && !profileToDelete.devices && currentLocal?.id) {
      const getDevicesList = async (localId: string) => {
        try {
          const deviceApi = DeviceApi();

          const response = await GetDeviceListUseCase(localId, deviceApi);
          setProfileToDelete((prev) => {
            if (prev) {
              return {
                ...prev,
                devices: response,
              };
            }
          });
        } catch (err) {
          console.log({ err });
        }
      };
      getDevicesList(currentLocal.id);
    }
  }, [currentLocal?.id, profileToDelete]);

  const getProfileListOnChangeFilter = useCallback(
    async (filter: IFilterProfileDevice) => {
      try {
        if (currentLocal) {
          showLoading();
          const response = await GetProfileListPaginationUseCase(deviceProvileService, currentLocal.id, filterProfile);
          setProfileList(response.items);
          setTotalCount(response.totalCount);
          setFilteredProfileList(response.items);
          setProfileNameList(response.items.map(profile => profile.name));
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, filterProfile, hideLoading, showLoading]
  );

  const getAllOnOpenPage = useCallback(async () => {
    try {
      showLoading();

      if (currentLocal) {
        try {
          const responseOperation = await GetOperationListUseCase(
            deviceProvileService,
            currentLocal?.id
          );
          setOperationList(responseOperation);
        } catch {}
        
        let newFilter = filterProfile;

        if (isEventLevel) {
          newFilter = {
            ...filterProfile,
          }
        }

        const responseProfiles = await GetProfileListPaginationUseCase(
          deviceProvileService,
          currentLocal.id,
          newFilter,
        );

        setProfileList(responseProfiles.items);
        setTotalCount(responseProfiles.totalCount);
        setProfileNameList(responseProfiles.items.map(profile => profile.name));
        setFilteredProfileList(responseProfiles.items);
      }
    } finally {
      hideLoading();
    }
  }, [currentLocal, filterProfile, hideLoading, showLoading, isEventLevel]);

  const copyProfile = useCallback(
    async (id: string) => {
      try {
        if (currentLocal) {
          showLoading();
          await CopyDeviceProfileUseCase(
            deviceProvileService,
            currentLocal.id,
            id
          );
          getAllOnOpenPage();
        } else {
          console.error("local não selecionado");
        }
      } catch (error) {
      } finally {
        hideLoading();
      }
    },
    [currentLocal, getAllOnOpenPage, hideLoading, showLoading]
  );

  const onClickAddProfileHandle = () => {
    push(`${path}/adicionar`);
  };

  const onClickEditProfileHandle = (profileId: string) => {
    push(`${path}/editar/` + profileId);
  };

  const onClickChangeStatusHandle = useCallback(
    async (profile: IDeviceProfileItem, status: boolean) => {
      try {
        if (currentLocal) {
          showLoading();
          await ChangeDeviceProfileStatusUseCase(
            deviceProvileService,
            currentLocal.id,
            profile.id,
            status
          );
          getAllOnOpenPage();
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, getAllOnOpenPage, hideLoading, showLoading]
  );

  useEffect(() => {
    getAllOnOpenPage();
    return () => {};
  }, [getAllOnOpenPage]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredProfileList(
        profileList?.filter((x) =>
          x.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredProfileList(profileList);
    }
  }, [profileList, searchTerm]);

  const onClickProfileHandle = useCallback(
    (profileItem: IDeviceProfileItem) => {
      //open modal edit
    },
    []
  );

  const onAddProfileHandle = useCallback(() => {
    //open modal edit
  }, []);

  const onCopyProfileHandle = useCallback(
    (profileItem: IDeviceProfileItem) => {
      copyProfile(profileItem.id);
    },
    [copyProfile]
  );

  const onDeleteProfileHandle = useCallback(
    async (id: string) => {
      try {
        if (currentLocal) {
          showLoading();
          await DeleteProfileUseCase(deviceProvileService, currentLocal.id, id);
          getAllOnOpenPage();
          setProfileToDelete(undefined);
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
        push(`${path}`);
      }
    },
    [currentLocal, getAllOnOpenPage, hideLoading, path, push, showLoading]
  );

  const onChangeFilterHandle = useCallback(
    (filter: IFilterProfileDevice) => {
      setFilterProfile(filter);
      getProfileListOnChangeFilter(filter);
    },
    [getProfileListOnChangeFilter]
  );

  useEffect(() => {
    if(currentLocal){
    GetPerfilVinculo(perfilService, currentLocal.id,).then((response) => {
      setPerfilVinculo(response);
  })  
}
  }, [currentLocal]);

  const onChangePaginationHandle = useCallback((pagination: IPaginationProfileDevice) => {
    setFilterProfile((prev) => ({ ...prev, pagination: { offset: (pagination.offset ?? 0) * (pagination.limit ?? 50), limit: pagination.limit } }));
  }, [setFilterProfile]);

  return {
    profileList,
    filteredProfileList,
    filterProfile,
    operationList,
    searchTerm,
    setSearchTerm,
    onClickProfileHandle,
    onCopyProfileHandle,
    onChangeFilterHandle,
    onAddProfileHandle,
    action,
    onClickAddProfileHandle,
    onClickEditProfileHandle,
    onDeleteProfileHandle,
    onClickChangeStatusHandle,
    profileToDelete,
    setProfileToDelete,
    profileNameList,
    onChangePaginationHandle,
    totalCount,
    perfilVinculo
  };
};

export default UseProfilePage;
