import { IconButton, Switch } from '@material-ui/core'
import { AddCircle, Delete } from '@material-ui/icons'
import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { ICustomField } from 'modules/schedule/domain/models/ICustomField'
import React from 'react'
import styles from './CustomFieldCard.module.scss'
import { UseCustomFieldCard } from './UseCustomFieldCard'

interface ICustomFieldProps {
    values: ICustomField[],
    addQuantity: () => void,
    setValues: React.Dispatch<React.SetStateAction<ICustomField[]>>,
    onSubmit: (customField: ICustomField[]) => void
}

export const CustomFieldCard: React.FC<ICustomFieldProps> = ({ values, addQuantity, setValues, onSubmit }) => {

    const { submitHandle, changeHandle, handleChangeChecked } = UseCustomFieldCard(values, setValues, onSubmit);

    return (
        <div id={styles.CustomFieldCard}>

            <Button fullWidth={false} onClick={addQuantity}>
                Adicionar novo campo <AddCircle />
            </Button>

            {
                values.map((item, index) => (

                    <div className={styles.containerCard}>
                        <div className={styles.inputsHeader}>
                            {
                                index > 0 &&
                                <IconButton onClick={() => { }}>
                                    <Delete />
                                </IconButton>
                            }
                        </div>
                        <div className={styles.mainInputs} >
                            <form onSubmit={submitHandle}>
                                <FormItemContainer row>
                                    <FormItemContainer>
                                        <Input
                                            value={item.title}
                                            fullWidth
                                            name="title"
                                            onChange={changeHandle}
                                            label="Nome do campo"
                                            placeholder="Ex.: Voo, Hotel."
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <Input
                                            value={item.type}
                                            fullWidth
                                            name="type"
                                            onChange={changeHandle}
                                            label="Tipo do campo"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <div className={styles.containerSwitch}>
                                            <Switch checked={item.multiple} onChange={handleChangeChecked} />
                                            <p>Permitir marcar mais de uma opção</p>
                                        </div>
                                    </FormItemContainer>
                                </FormItemContainer>
                            </form>
                        </div>
                    </div>
                ))
            }
        </div >
    )
}
