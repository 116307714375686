import { IPerfilEventoBody, IPostPerfilEventoService } from "./IPerfilService";

const PostPerfilEvento = async (
  accountService: IPostPerfilEventoService,
  id: string,
  body: IPerfilEventoBody
): Promise<any> => {
  return await accountService.postAddPerfilEvento(id, body);
};

export default PostPerfilEvento;