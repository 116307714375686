import { AxiosInstance } from "axios";
import { IPostCashClosingParams } from "modules/cashClosing/domain/dto/IPostCashClosingParams";
import { IPostCashClosingResponse } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";

const AntiCorruptionCashClosingService = (api: AxiosInstance) => {
  const postCashClosingOldApi = async (params: IPostCashClosingParams): Promise<IPostCashClosingResponse> => {
    const response = await api.post<IPostCashClosingResponse>(`/Relatorio/FechamentoVenda`, params);

    return response.data;
  }

  return ({
    postCashClosingOldApi,
  })
}

export default AntiCorruptionCashClosingService;
