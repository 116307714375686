import styles from "./WizardBanner.module.scss";
import { CloseRounded } from "@mui/icons-material";

interface IWizardInfoProps {
  onClose: () => void;
  link: string;
}

const WizardBanner = ({ onClose, link }: IWizardInfoProps) => {
  return (
    <div id={styles.WizardBanner}>
      <div className={styles.content}>
        <img src="/assets/icon/open-book-emoji.svg" alt="" />
        <p>
          Em caso de dúvidas, acesse o tutorial da página{" "}
          <a href={link} target="_blank">clicando aqui!</a>
        </p>
      </div>
      <CloseRounded onClick={onClose}/>
    </div>
  );
};

export default WizardBanner;
