import React, { useCallback, useState } from 'react'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { DeleteReserveByDemandedScheduleIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/DeleteReserveByDemandedScheduleIdUseCase';
import { PatchCheckedByStaffByDemandedScheduleIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/PatchCheckedByStaffByDemandedScheduleIdUseCase';
import { ReserveScheduleApi } from 'services/api/schedule/reserve/ReserveScheduleApi';
import { ICheckedByStaff } from './interfaces/ICheckedByStaff';

export const UseReserveListCard = (refreshList: () => void) => {

    const [toggleModal, setToggleModal] = useState(false)
    const { showLoading, hideLoading, toast } = useUi()

    const handleModal = () => {
        setToggleModal(!toggleModal);
    };

    const removeReserve = async (demandedScheduleId: string) => {
        showLoading();
        try {
            await DeleteReserveByDemandedScheduleIdUseCase(demandedScheduleId, ReserveScheduleApi);
            toast("Reserva cancelada com sucesso!", "success");
            refreshList();
        } catch (error) {
            toast("Algo deu errado, tente novamente!", "error");
        } finally {
            hideLoading();
            setToggleModal(false)
        }
    }

    const handleChangeChecked = useCallback(async (checked: boolean, id: string) => {

        const newBoolean: ICheckedByStaff = {
            checkedByStaff: checked
        }

        showLoading();
        try {
            await PatchCheckedByStaffByDemandedScheduleIdUseCase(id, newBoolean, ReserveScheduleApi);
            // toast("Usuário conferido com sucesso!", "success");
            refreshList();
        } catch (error) {
            toast("Algo deu errado, tente novamente!", "error");
        } finally {
            hideLoading();
        }

    }, [hideLoading, refreshList, showLoading, toast]);

    return (
        {
            removeReserve,
            toggleModal,
            handleModal,
            handleChangeChecked
        }
    )
}
