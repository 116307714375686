import styles from './RemovedCard.module.scss';
import { Icon, MenuItem, Popover } from '@material-ui/core'
import { useState } from 'react';
import { dashboardContainerEnum } from 'modules/salesDashboard/presentation/interfaces/DashboardContainerEnum';

interface RemovedCardProps {
    removedCharts: string[];
    onRemoveChart: (chartId?: string) => void;
}

const cardNames = [{
    id: dashboardContainerEnum.RANKING_DEVICE,
    label: 'Vendas por dispositivos'
}, {
    id: dashboardContainerEnum.RANKING_CLIENTS,
    label: 'Top clientes'
}]

export const RemovedCard = ({ removedCharts, onRemoveChart }: RemovedCardProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getMenuItems = () => {
        return removedCharts?.map(x => (
            <MenuItem onClick={() => {
                onRemoveChart(x);
                handleClose();
            }}>
                {cardNames.find(y => y.id === x)?.label}
            </MenuItem>
        ))
    }

    return (
        <>
            <button className={styles.addChartButton}>
                <div onClick={handleClick}><Icon>add_circle_outline</Icon>Adicionar bloco</div>
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={styles.popover} >
                    <div className={styles.name} >
                    </div>
                </div>
                {getMenuItems()}
            </Popover>

        </>

    )
}