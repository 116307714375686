import { Button, Icon, IconButton } from '@material-ui/core';
import styles from './DeleteModal.module.scss';

interface DeleteMoralProps {
    customerCount: number;
    loading: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export const DeleteModal = ({ customerCount, loading, onClose, onDelete }: DeleteMoralProps) => {
    return (
        <div className={styles.container}>
            <header>
                <h1>Excluir nível de<b>&nbsp;fidelidade</b></h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton>
            </header>
            <div className={styles.alertBox}>
                <img src="/assets/img/alert.png" alt="" />
                <span>Deseja realmente excluir o <strong>nível de fidelidade?</strong></span>
            </div>

            <div className={styles.separator}>
                <div />
                <span>O que isso significa?</span>
                <div />
            </div>

            <div className={styles.customerCount}>
                <span>Existem <strong>{customerCount}</strong> clientes cadastrados nesse nível</span>
            </div>

            <ul className={styles.list}>
                <li>Ao excluir, seus clientes não verão mais esse nível de fidelidade no App Meep;</li>
                <li>Os clientes desse nível excluído serão incluídos no nível inferior, se existir;</li>
            </ul>

            <div className={styles.buttonContainer}>
                <div>
                    <Button className={styles.buttons} color='secondary' onClick={onClose} variant='outlined'>Fechar</Button>
                </div>
                <div>
                    <Button disabled={loading} onClick={onDelete} className={styles.buttons} type='button' variant='contained' color='secondary'>{
                        loading ? 'Deletando...' :
                        'Confirmar'
                    }</Button>
                </div>
            </div>
        </div>
    )
}