import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ReportProductsList.module.scss";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { Skeleton } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import ReportProductsHeader from "./components/ReportProductsHeader";
import { IReportProductsColumnsConfig } from "../../../interfaces/IColumnsConfig";
import {
  GEtSalesResponse,
  ProductsTypes,
  SortProductInterface,
} from "services/api/outputreport";

import ReportProductsItem from "./components/ReportProductsItem";

type ReportProductsListProps = {
  products?: GEtSalesResponse;
  isSuccess: boolean;
  isLoading: boolean;
  setSortProducts: (sortProducts: SortProductInterface) => void;
};

export const ReportProductsList: FC<ReportProductsListProps> = ({
  products,
  isSuccess,
  isLoading,
  setSortProducts,
}) => {
  const { dimensions } = UseDimension();

  const [colunms, setColunms] = useState<IReportProductsColumnsConfig[]>([
    {
      property: "ProductName",
      title: "Produto",
    },
    {
      property: "CategoryName",
      title: "Categoria",
    },
    {
      property: "UnityPrice",
      title: "Valor unitário",
    },
    {
      property: "Quantity",
      title: "Quantidade",
    },
    {
      property: "Total",
      title: "Total",
    },
  ]);

  const columnsLimit = useCallback(
    (_columns: IReportProductsColumnsConfig[], width: number) => {
      if (width > 0) {
        const calcMax = (width: number) => {
          if (width > 0 && width <= 600) {
            return 1;
          } else if (width > 600 && width <= 800) {
            return 2;
          } else if (width > 800 && width <= 1024) {
            return 3;
          } else {
            return 5;
          }
        };
        const max = calcMax(width);
        let count = 0;
        const newValue = _columns.map((item, index) => {
          if (!item.hide) {
            count++;
            if (count > max) {
              return { ...item, hide: true };
            } else {
              return item;
            }
          } else {
            return { ...item, hide: false };
          }
        });
        return newValue;
      } else {
        return _columns;
      }
    },
    []
  );

  useEffect(() => {
    setColunms((prev) => columnsLimit(prev, dimensions.width));
  }, [columnsLimit, dimensions.width]);

  const List = useMemo(
    () => (
      <div className={styles.list}>
        <ReportProductsHeader
          columns={colunms}
          setSortProducts={setSortProducts}
          filterName="products"
        />
        {isLoading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
          </>
        ) : (
          <>
            {products?.Sales.Products?.map((item: ProductsTypes, index) => {
              return (
                <ReportProductsItem
                  key={index}
                  userItemData={item}
                  columns={colunms}
                />
              );
            })}
          </>
        )}
      </div>
    ),
    [colunms, isLoading, products?.Sales.Products, setSortProducts]
  );

  return (
    <div id={styles.ReportProductsList}>
      {isSuccess && !products?.Sales.Products?.length && (
        <ErrorMessage type="warning" />
      )}

      {isSuccess && products?.Sales.Products?.length && (
        <>
          {List}
          {products?.Sales.ServiceFee !== 0 && (
            <div className={styles.row} style={{ flex: 1 }}>
              <span>Total de taxa de serviço</span>
              <span>R$ {products?.Sales.ServiceFee.toLocaleString()}</span>
            </div>
          )}
          {products?.Sales.Discounts !== 0 && (
            <div className={styles.row} style={{ flex: 1 }}>
              <span>Total de desconto</span>
              <span>R$ {products?.Sales.Discounts.toLocaleString()}</span>
            </div>
          )}
          {products?.Sales.TotalNet !== 0 && (
            <div className={styles.rowTotal} style={{ flex: 1 }}>
              <span>Total</span>
              <span>R$ {products?.Sales.TotalNet.toLocaleString()}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
