import styles from './WaitingEnvironment.module.scss';

export const WaitingEnvironment = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <img src="/assets/img/invoice/waiting.svg" alt="" />
                <h1>Ambiente criado.</h1>
                <p>Estamos finalizando a implementação fiscal, retorne em alguns minutos.</p>
            </div>
            
            <div className={styles.waterMark}>
                <div className={styles.skeleton} style={{ height: 30, width: 160 }} />
                <div className={styles.skeleton} style={{ height: 15, width: '50%' }} />
                <div className={styles.skeleton} style={{ height: 15, width: '50%' }} />
                <div className={styles.skeleton} style={{ height: 15, width: '50%' }} />
                <div className={styles.skeleton} style={{ height: 160, width: '100%' }} />
                <div className={styles.skeleton} style={{ height: 15, width: '100%' }} />
                <div className={styles.skeleton} style={{ height: 160, width: '100%' }} />
            </div>
        </div>
    )
}