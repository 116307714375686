import {
  IGetSupplyItemResponse,
  IGetSupplyListResponse,
} from "modules/meepErp/application/dtos/supply/IGetLSupplyListResponse";
import { IGetSupplyListRequest } from "modules/meepErp/application/dtos/supply/IGetSupplyListRequest";
import { IGetSupplyNameResponse } from "modules/meepErp/application/dtos/supply/IGetSupplyNameResponse";
import { v4 } from "uuid";

export const delayMock = async (time: number) => {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 3000);
  });
};

// export const getPaginatedSupplyMOCK = async (
//   request: IGetSupplyListRequest
// ): Promise<IGetSupplyListResponse> => {
//   await delayMock(3000);
//   return {
//     ...exampleSupplyList,
//     page: request.page,
//   };
// };

// export const getSupplyItemMOCK = async (
//   id: string
// ): Promise<IGetSupplyItemResponse> => {
//   await delayMock(3000);
//   return {
//     ...exampleSupplyItem,
//   };
// };

export const getSupplyNameMOCK = async (): Promise<
  IGetSupplyNameResponse[]
> => {
  await delayMock(3000);
  return exampleSupplyName;
};

// const exampleSupplyItem: IGetSupplyItemResponse = {
//   id: v4(),
//   code: 515151,
//   name: "Filé mignon",
//   supplyGroup: "Grupo de insumo",
//   minStock: 100,
//   maxStock: 150,
//   saleProduct: true,
//   isActive: true,
// };

const exampleSupplyName: IGetSupplyNameResponse[] = [
  {
    id: "1a2b3c4d-1234-5678-abcd-9876543210ef",
    description: "Filé mignon",
  },
  {
    id: "1a4b3c4d-1234-5678-abcd-9876543210ee",
    description: "Batata",
  },
];

// const exampleSupplyList: IGetSupplyListResponse = {
//   page: 1,
//   pageSize: 10,
//   totalPages: 30,
//   items: [
//     {
//       id: v4(),
//       code: 515151,
//       name: "Filé mignon",
//       supplyGroup: "Grupo de insumo",
//       minStock: 100,
//       maxStock: 150,
//       saleProduct: true,
//       isActive: true,
//     },
//     {
//       id: v4(),
//       code: 515151,
//       name: "Filé mignon",
//       supplyGroup: "Grupo de insumo",
//       minStock: 100,
//       maxStock: 150,
//       saleProduct: true,
//       isActive: true,
//     },
//     {
//       id: v4(),
//       code: 515151,
//       name: "Filé mignon",
//       supplyGroup: "Grupo de insumo",
//       minStock: 100,
//       maxStock: 150,
//       saleProduct: true,
//       isActive: true,
//     },
//   ],
// };
