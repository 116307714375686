import GetTerminalDevicesUseCase from 'modules/Terminal/application/useCases/GetTerminalDevicesUseCase';
import { DeviceType } from 'modules/equipamento/domain/enum/DeviceType';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback } from 'react'
import TerminalService from 'services/api/terminal/TerminalService';


const UseTerminal = () => {
    const { currentLocal } = useLocal();
    
    const getTerminalDevices = useCallback(
        async (type?: DeviceType) => {
            const service = TerminalService();
            if (currentLocal) {
                const response = await GetTerminalDevicesUseCase(service, currentLocal.id, type);
                return response;
            }
            return undefined;
        },
        [currentLocal]
    );
    return ({ getTerminalDevices })
}

export default UseTerminal