import React, { FC, useEffect } from 'react'
import styles from './LocalsForm.module.scss'
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { ButtonContainer, Container, InputContainer, Row } from '../../ui/form/FormContainers';
import { TextField } from '@mui/material';
import { ILocalsSchema } from './LocalsFormValidation';
import { EMPTY_LOCALS_VALUES, ILocalsValues } from './LocalsValues';
import { useHistory, useParams } from 'react-router-dom';
import SkeletonForm from '../../ui/form/skeletonForm/SkeletonForm';
import Button from 'components/ui/Button/Button';

export interface ILocalsFormProps {
    isLoading?: boolean
    onSubmit: (values: ILocalsValues) => Promise<void>
    defaultValue?: ILocalsValues
    disabled?: boolean
}
const LocalsForm: FC<ILocalsFormProps> = ({
    isLoading,
    onSubmit,
    defaultValue,
    disabled
}) => {
    const { push } = useHistory();
    const { id} = useParams<{ id?: string}>();

    const { register, handleSubmit, reset, formState: { errors } } = useForm<ILocalsValues>({
        resolver: zodResolver(ILocalsSchema),
        defaultValues: defaultValue ?? {
            name: "",
        }
    });

    useEffect(() => {
        if (defaultValue && id) {
            reset(defaultValue);
        }else {
            reset(EMPTY_LOCALS_VALUES)
        }
    }, [defaultValue, reset]);


    return (
        isLoading ? <SkeletonForm lines={1} /> :
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Container center >
                    <Row>
                        {defaultValue?.code && id &&
                            <InputContainer label='Código'>
                                <TextField
                                    value={defaultValue?.code}
                                    disabled
                                />
                            </InputContainer>
                        }
                        <InputContainer flex={3} label='Nome' requerid>
                            <TextField
                                {...register("name")}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                disabled={isLoading || disabled}
                            />
                        </InputContainer>
                    </Row>

                    <ButtonContainer >
                        <Button color='secondary' onClick={() => push('/private/meeperp/locals')} fullWidth={false} variant='outlined'>Cancelar</Button>
                        <Button type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                    </ButtonContainer>
                </Container>
            </form>
    )
}
export default LocalsForm