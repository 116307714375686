import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { IServiceScheduleForm } from '../serviceForm/interfaces/IServiceForm';
import { ICatagoryServiceScheduleForm } from './interfaces/ICatagoryServiceScheduleForm';

const UseServiceCategoryForm = (onSubmit: (values: ICatagoryServiceScheduleForm) => void, defaultValues?: ICatagoryServiceScheduleForm) => {
   
    const [values, setValues] = useState<ICatagoryServiceScheduleForm>(defaultValues ?? {
        name: "",
        categoryId: "",
        imageUrl: "",
        image: ""
    });

    const onChangeHandle = useCallback((name: string, value: string) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    const handleChangeSingleImage = (image: string) => {
        setValues((prev) => ({
          ...prev,
          imageUrl: image,
        }));
      };

    return ({ values, onChangeHandle, onSubmitHandle, handleChangeSingleImage })
}

export default UseServiceCategoryForm
