import { CircularProgress, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import React, { FC, useState } from "react";
import { IPromoters } from "../grantMultipleAccessForm/interfaces/IPromoters";
import styles from "./PromoterList.module.scss";

interface IPromoterListProps {
  promoters: IPromoters[] | null;
  onClickDelete?: (promoterId: string) => Promise<void>;
}

export const PromoterList: FC<IPromoterListProps> = ({ promoters, onClickDelete }) => {
  const { toast } = useUi();
  const [deleting, setDeleting] = useState("");

  const handleDelete = async (promoterId: string) => {
    if (onClickDelete) {
      setDeleting(promoterId);
      try {
        await onClickDelete(promoterId);
      } catch (error) {
        toast("Falha ao remover Promoter", "error");
      } finally {
        setDeleting("");
      }
    }
  };

  return (
    <div id={styles.PromoterList}>
      {promoters ? (
        promoters.map((item, key) => (
          <FormItemContainer key={key} className={styles.Promoter} row>
            <p>{item.name}</p>
            {onClickDelete && (
              <div className={styles.icon}>
                {deleting === item.id ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton onClick={() => handleDelete(item.id)}>
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            )}
          </FormItemContainer>
        ))
      ) : (
        <CircularProgress size={24} />
      )}
    </div>
  );
};
