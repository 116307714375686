import React, { FC } from 'react'
import styles from './SucessButton.module.scss'
import sucessIcon from './sucessIcon.svg'

export interface ISucessButtonProps {
    //propertys
    label: string | JSX.Element
}
const SucessButton: FC<ISucessButtonProps> = ({label}) => {
    return (
        <div id={styles.SucessButton} >
            <img
                width={60}
                height={60}
                src={sucessIcon}
                alt={"Icone de sucesso"}
            />
            <div>{label}</div>
        </div>
    )
}
export default SucessButton