import { IGetMonthResumeResponse } from "../../dtos/IGetMonthResumeResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetMonthResumeUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetMonthResumeResponse> => {
  return await service.getMonthResume(localId, cashierId, day);
};

export default GetMonthResumeUseCase;
