import { api } from "../Api";
import { ICustomerCategoryService } from "modules/customerCategories/domain/interfaces/ICustomerCategoryService";
import { IGetCustomerCategoriesResponse } from "modules/customerCategories/domain/dtos/IGetCustomerCategoriesResponse";
import { ICreateCustomerCategoryRequest } from "modules/customerCategories/domain/dtos/ICreateCustomerCategoryRequest";
import { IGetCategoryCustomersResponse } from "modules/customerCategories/domain/dtos/IGetCategoryCustomersResponse";

export const CustomerCategoryApi = (): ICustomerCategoryService => {

  const getCategories = async (localId: string, name?: string): Promise<IGetCustomerCategoriesResponse> => {
    const response = await api.get(`ClienteEstabelecimento/BuscarCategoriaClientePortal/${localId}`, { params: { nome: name } });
    return {
      localId: response.data.localId,
      categories: response.data.categorias.map((x: any) => ({
        id: x.id,
        name: x.nome,
        description: x.descricao,
        icon: x.icone,
        visible: x.visivel
      }))
    }
  }

  const createCategory = async (request: ICreateCustomerCategoryRequest): Promise<void> => {
    await api.post(`ClienteEstabelecimento/AdicionarCategoriaCliente`, {
      categoriaId: request.id,
      localId: request.localId,
      nome: request.name,
      descricao: request.description,
      icone: request.icon,
      visivel: request.visible,
    });
  }

  const deleteCategory = async (categoryId: string): Promise<void> => {
    await api.delete(`ClienteEstabelecimento/DeletarCategoria`, { params: { CategoriaId: categoryId } })
  }

  const getCategoryCustomers = async (localId: string, categoryId: string, page: number, name?: string, document?: string): Promise<IGetCategoryCustomersResponse> => {
    const response = await api.get(`ClienteEstabelecimento/BuscarClientesDoLocal`, { params: { LocalClienteId: localId, CategoriaId: categoryId, page, pageSize: 20, nome: name, cpf: document } });
    return {
      totalRecords: response.data.QuantidadeTotal,
      records: response.data.Registros.map((x: any) => ({
        id: x.id,
        name: x.nome,
        document: x.CPF,
        birthday: x.dataNascimento,
        email: x.email,
      }))
    }
  }

  const addCustomerInCategory = async (categoryId: string, personId: string, localId: string): Promise<void> => {
    await api.put(`ClienteEstabelecimento/InserirClienteNaCategoria`, {
      CategoriaId: categoryId,
      personId: personId,
      localId
    });
  };

  const removeCustomerFromCategory = async (categoryId: string, personId: string, localId: string): Promise<void> => {
    await api.put(`ClienteEstabelecimento/RemoverClienteDaCategoria`, {
      CategoriaId: categoryId,
      personId: personId,
      localId
    });
  };

  const removeCustomerFromCategoryById = async (categoryId: string, clientId: string, localId: string): Promise<void> => {
    await api.put(`ClienteEstabelecimento/RemoverDaCategoria`, {
      CategoriaId: categoryId,
      clienteEstabelecimentoId: clientId,
      localClienteId: localId
    });
  };

  return {
    getCategories,
    createCategory,
    deleteCategory,
    getCategoryCustomers,
    addCustomerInCategory,
    removeCustomerFromCategory,
    removeCustomerFromCategoryById
  };
};

