import styles from './Success.module.scss';

export const Success = () => {
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <img src="/assets/img/concluded.png" alt="" />
                <span><strong>Tudo certo!</strong> Sua automação foi criada e ativada com sucesso!</span>
            </div>
        </div>
    )
}