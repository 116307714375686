
import { ILocal } from "modules/dashboard/presentation/components/toggleLocal/ToggleLocal.types";
import { IGetSessionsListResponse } from "../../domain/dto/IGetSessionsListResponse";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";

const GetSessionsUseCase = async (service: IDiscountCouponsService, localId: string): Promise<IGetSessionsListResponse> => {
  const result = await service.getSessions(localId)

  return result;
}

export default GetSessionsUseCase;
