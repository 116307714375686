import { useEffect, useState } from "react";
import styles from "./SearchMonitoramento.module.scss";
import Button from "components/ui/Button/Button";
import { Autocomplete, TextField } from "@mui/material";
import UseDeviceProfile from "modules/equipamento/presentation/hooks/UseDeviceProfile";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import Utils from "services/utils/Utils";

const optionsBateria = [
  { label: "Baixa" },
  { label: "Média" },
  { label: "Alta" },
];

const optionsConexao = [
  { label: "Conectados ao Wi-Fi" },
  { label: "Conectados à rede móvel" },
  { label: "Sem conexão" },
];

const SearchMonitoramento = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setIsOptionsOpen(value.length >= 2);
  };

  const { getDeviceProfileList, deviceProfileList } = UseDeviceProfile();
  const { currentLocal } = useLocal();

  useEffect(() => {
    if (currentLocal) {
      getDeviceProfileList(currentLocal.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocal]);

  const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0
  );

  const endDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    23,
    59,
    59,
    999
  );

  return (
    <div id={styles.Search}>
      <div className={styles.item}>
        <label>PDV</label>
        <Autocomplete
          disablePortal
          open={isOptionsOpen}
          onInputChange={handleInputChange}
          options={deviceProfileList || []}
          noOptionsText="Nenhuma opção encontrada"
          getOptionLabel={(option) => option.description}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </div>
      <div className={styles.item}>
        <label>Bateria</label>
        <Autocomplete
          disablePortal
          options={optionsBateria}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </div>
      <div className={styles.item}>
        <label>Sincronização - início</label>
        <TextField
          size="small"
          fullWidth
          defaultValue={Utils.toInputDateString(startDate)}
          type="datetime-local"
        />
      </div>
      <div className={styles.item}>
        <label>Sincronização - fim</label>
        <TextField
          size="small"
          fullWidth
          defaultValue={Utils.toInputDateString(endDate)}
          type="datetime-local"
        />
      </div>
      <div className={styles.item}>
        <label>Conexão</label>
        <Autocomplete
          disablePortal
          options={optionsConexao}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </div>
      <Button className={styles.buttonSearch}>Buscar</Button>
    </div>
  );
};
export default SearchMonitoramento;
