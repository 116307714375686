import { IPostPayAccountService } from "../interfaces/IContaDigitalApiService";
import { PayAccountRequest } from "modules/contaDigital/models/dtos/PayAccountRequest";

const PostPayAccount = async (
  accountService: IPostPayAccountService,
  body: PayAccountRequest
): Promise<any> => {
  return await accountService.postPayAccount(body);
};

export default PostPayAccount;
