import { PartnerType } from "modules/meepErp/models/partner/PartnerType";


export interface IIndividualPartnerValues {
  code?: string;
  document: string;
  name: string;
  type: PartnerType;
  contacts: ContactValue[];
  address: AddressValue;
}

export interface AddressValue {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface ContactValue {
  name: string;
  phone: string;
  email: string;
}

export interface ISegmentItem {
  id: string;
  name: string;
}

export const EMPTY_PARTNER_VALUES: IIndividualPartnerValues = {
  code: '',
  name: '',
  document: '',
  type: PartnerType.Supplier,
  contacts: [
    {
      name: '',
      phone: '',
      email: ''
    }
  ],
  address: {
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: ''
  }
};

export interface IPartnerFormErrors {

  legalRepresentative?: {
    name?: string;
    document?: string;
  };
  trype?: string;
  contacts?: {
    name?: string;
    phone?: string;
    email?: string;
  }[];
  address?: {
    street?: string;
    number?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
}
