import { Checkbox, IconButton, Switch } from '@material-ui/core';
import { Cancel, Delete, Edit, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Modal } from 'components/ui/modal/Modal';
import { addHours, format } from 'date-fns';
import { IReservationList, IReserveItem, IState, IStateEnum } from 'modules/schedule/domain/models/IReserveList';
import React, { useState } from 'react'
import styles from './ReserveListCardMobile.module.scss';
import { UseReserveListCardMobile } from './UseReserveListCardMobile';


export interface IReserveListCardProps {
    value: IReserveItem;
    index?: number
    refreshList: () => void;
}

export const ReserveListCardMobile: React.FC<IReserveListCardProps> = ({ value, index, refreshList }) => {
    
    const [openCustomersDetails, setOpenCustomersDetails] = useState(false);
    const { removeReserve, handleModal, toggleModal, handleChangeChecked } = UseReserveListCardMobile(refreshList);

    return (
        <div id={styles.containerDetailsCustomer} key={index}>



            <div className={styles.customerContainer}>
                {
                    index !== 0 ?

                    <div style={{ borderTop: "0.85px solid #e0e0e0", marginBottom: '10px' }}></div>
                    :
                    <div style={{ borderTop: "transparent" }}></div>

                }
                <div className={styles.customer}>
                    <div className={styles.name}>
                        <p> Responsável: <span>{value.responsable.name}</span></p>
                        <IconButton>
                            <Cancel onClick={handleModal} />
                            {/* <Delete onClick={() => removeReserve(value.id)} /> */}
                        </IconButton>
                    </div>
                    <div className={styles.dateTour}>
                        <div>
                            Data do passeio: <span>{new Date(value.startAt).toLocaleDateString()}</span>
                        </div >
                    </div>
                    <div className={styles.netValue}> Valor: <span> {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(value.netValue)}</span></div>
                    <div className={styles.orderDate}>
                        <p> Data do pagamento: <span> {new Date(value.orderDate).toLocaleDateString()} </span></p>
                        <p> Horário do pagamento: <span>{new Date(value.orderDate).toLocaleTimeString().substr(0, 5)}</span></p>
                    </div>
                    <div className={styles.participantsQuantity}> Qtd. de Pessoas:<span> {value.participantsQuantity}</span></div>
                    {
                        <div className={styles.document}>Status: <span>{IStateEnum[Number(value.currentState.id)]}</span></div>
                    }
                    <div className={styles.formPayment}> Pagamento: <span>{value.formOfPayment.name}</span></div>

                    <div className={styles.buttonOptions}>
                        {/* <IconButton>
                            <Edit />
                        </IconButton> */}
                        <div className={styles.service}>Serviço: {value.service.name}

                            <IconButton onClick={() => setOpenCustomersDetails((prev) => !prev)}>
                                {!openCustomersDetails ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        !openCustomersDetails ? styles.customersDetailsOpen : styles.customersDetailsClose
                    }
                >
                    <div className={styles.containerDetailsCustomers}>
                        {/* <div className={styles.headerTitle}>
                            <div className={styles.titlePeople}><p>Pessoas na reserva</p></div>
                            <div className={styles.titleDocument}><p>Documento</p></div>
                            <div className={styles.titleDocument}><p>Hotel</p></div>
                            <div className={styles.titleAge}><p>Idade</p></div>
                            <div className={styles.titleAge}><p>Telefone</p></div>
                            <div className={styles.titleDocument}><p>Conferido</p></div>
                        </div> */}
                        {value.participants?.map((participant) => (
                            <div className={styles.containerCustomersList}>
                                <div className={styles.name}>{participant.name}</div>
                                <div className={styles.note}>Documento: {participant.document}</div>
                                <div className={styles.note}>Hotel: {participant.note}</div>
                                <div className={styles.note}>Telefone: {participant.phone}</div>
                                <div className={styles.note}> Data Nacimento:
                                    <span>{participant.age} anos</span>
                                    <span>{`(${format(addHours(new Date(participant.birthday), 3), 'dd/MM/yyyy')})`}</span>
                                </div>
                                <div className={styles.note}>
                                    Conferido: <Switch checked={participant.checkedByStaff} onChange={(_event: React.ChangeEvent<HTMLInputElement>) => handleChangeChecked(_event.target.checked, participant.serviceDemandedScheduledId)} />
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal toggleModal={handleModal} onConfirm={() => { removeReserve(value.id) }} open={toggleModal} title="Deseja cancelar está reserva?">
                <p>Tem certeza que deseja cancelar?</p>
            </Modal>
        </div>
    )
}
