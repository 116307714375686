import { AxiosInstance } from "axios"
import { ICustomPaymentResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getCustomPaymentList/ICustomPaymentResponse"

export interface FormaPagamentoCustomizado {
    id: string
    tipoPagamento: number
    descricao: string,
}

export const BuscarFormasPagamentosCustomizados = async (api: AxiosInstance, localId: string): Promise<ICustomPaymentResponse> => {
    const response = await api.get<FormaPagamentoCustomizado[]>(`/FormaPagamento/CustomPayments/${localId}`)
    return {
      items: response.data.map(item => ({
        id: item.id,
        name: item.descricao,
        type: item.tipoPagamento,
      }))
    };
}
