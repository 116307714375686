import Layout from "components/layout/presentation/Layout";
import { PurchaseEmailPage } from "./PurchaseEmailPage";

export const PurchaseEmail = () => {
  return (
    <Layout>
      <PurchaseEmailPage />
    </Layout>
  );
};
