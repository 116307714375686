import { Checkbox, Radio, Tab, Tabs } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Autocomplete, TextField } from '@mui/material'
import Button from 'components/ui/Button/Button'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import React from 'react'
import ProductSelector from '../productsSelector/ProductSelector'
import { IPrinterItem, IProductItem, ISmartPrinterItem, ITableItem, ITotemItem } from './interfaces/ISmartPrinterForm'
import styles from './SmartPrinterForm.module.scss'
import { UseSmartPrinterForm } from './UseSmartPrinterForm'
import SwipeableViews from 'react-swipeable-views';
import Input from 'components/ui/input/Input'

interface SmartPrinterFormProps {
    tableList: ITableItem[],
    totemList: ITotemItem[],
    printerList: IPrinterItem[],
    productList: IProductItem[],
    defaultValues?: ISmartPrinterItem,
    onSubmit: (values: ISmartPrinterItem) => void,
    onClose: () => void
}

export const SmartPrinterForm: React.FC<SmartPrinterFormProps> = ({ onClose, onSubmit, defaultValues, tableList, totemList, productList, printerList }) => {

    const { handleChange, onSubmitHandle, errors, values, onChangeHandle, onChangeHandleListPrinter, onChangeHandleListProduct, onChangeHandleListTable, onChangeHandleListTotem, index } = UseSmartPrinterForm(onSubmit, defaultValues);

    return (
        <div id={styles.SmartPrinterForm}>
            <div>
                <div className={styles.containerTitle}>
                    <h1>Impressão <span>Inteligente</span></h1>
                    <Close onClick={onClose} />
                </div>
                <div className={styles.container}>
                    {
                        index === 3 ?
                            ''
                            :
                            <Tabs
                                value={index}
                                onChange={(ev, value) => handleChange(value)}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Início" />
                                <Tab label="Impressora" />
                                <Tab label="Configuração" />
                            </Tabs>
                    }
                    <div>
                        <FormItemContainer >
                            <SwipeableViews
                                index={index}
                                onChangeIndex={(value) => handleChange(value)}
                            >
                                <>
                                    <p className={styles.textInfo}>Nesse momento, crie um setor para seu estabelecimento. Ele será utilizado para vincular à impressora, produtos e mesa no próximo passo.</p>
                                    <FormItemContainer label={"Nome do setor"} required>
                                        <Input
                                            name={"name"}
                                            value={values.name}
                                            placeholder="Ex.: Setor japones"
                                            onChange={ev => { onChangeHandle(ev.target.name, ev.target.value) }}
                                            required
                                            variant="outlined"
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </FormItemContainer>
                                </>
                                <>
                                    <p className={styles.textInfo}>Agora, vincule o setor criado a uma impressora utilizada no seu estabelecimento: </p>
                                    <FormItemContainer label={"Impressoras"} required>
                                        <Autocomplete
                                            // name={"printerList"}
                                            value={printerList.find(item => item.id === values.printerId)}
                                            options={printerList}
                                            onChange={(ev, value) => { onChangeHandleListPrinter(value as IPrinterItem) }}
                                            renderOption={(props, option, state) => (<li {...props}><Radio checked={state.selected} />{option.name}</li>)}
                                            className={styles.input}
                                            getOptionLabel={option => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant={"outlined"}
                                                    {...params}
                                                    error={!!errors.printerId}
                                                    helperText={errors.printerId}
                                                ></TextField>
                                            )}
                                        />
                                    </FormItemContainer>
                                </>
                                <>
                                    {/* <FormItemContainer label={"Produtos"}> */}
                                    <ProductSelector list={productList} onChange={onChangeHandleListProduct} defaultValues={productList.filter(item => defaultValues?.productsIds.includes(item.id))} ></ProductSelector>
                                    {/* </FormItemContainer> */}

                                    <FormItemContainer label={"Mesas (comandas)"}>
                                        <Autocomplete
                                            className={styles.input}
                                            value={tableList.filter(item => values.tablesIds.includes(item.id))}
                                            options={tableList}
                                            limitTags={3}
                                            style={{
                                                maxHeight: 400,
                                                overflowY: 'auto',
                                            }}
                                            onChange={(ev, value) => { onChangeHandleListTable(value as ITableItem[]) }}
                                            renderOption={(props, option, state) => (<li {...props}><Checkbox checked={state.selected} />{option.number}</li>)}
                                            getOptionLabel={option => option.number}
                                            multiple
                                            disableCloseOnSelect
                                            renderInput={(params) => (
                                                <TextField
                                                    variant={"outlined"}
                                                    {...params}
                                                    error={!!errors.tablesIds}
                                                    helperText={errors.tablesIds}

                                                ></TextField>
                                            )}
                                        />
                                    </FormItemContainer>

                                    <FormItemContainer label={"Totens"}>
                                        <Autocomplete
                                            // name={"printerList"}
                                            className={styles.input}
                                            value={totemList.filter(item => values.totemsIds?.includes(item.id))}
                                            options={totemList}
                                            limitTags={3}
                                            style={{
                                                maxHeight: 400,
                                                overflowY: 'auto',
                                            }}
                                            //options={productList.filter((item, index) => index < 20)}
                                            onChange={(ev, value) => { onChangeHandleListTotem(value as ITotemItem[]) }}
                                            renderOption={(props, option, state) => (<li {...props}><Checkbox checked={state.selected} />{option.name}</li>)}
                                            getOptionLabel={option => option.name}
                                            multiple
                                            disableCloseOnSelect
                                            // limitTags={3}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant={"outlined"}
                                                    {...params}
                                                    error={!!errors.totemsIds}
                                                    helperText={errors.totemsIds}

                                                ></TextField>
                                            )}
                                        />
                                    </FormItemContainer>
                                </>
                                <>
                                    <div className={styles.containerConcluded}>
                                        <img src={'/assets/img/concluded.png'} alt={'Concluído'} />
                                        <span>Pronto! Configuração de impressão criada com sucesso.</span>
                                    </div>
                                </>
                            </SwipeableViews>
                        </FormItemContainer>

                    </div>
                </div>
            </div>

            {
                index === 0 ?
                    <div className={styles.containerButton}>
                        <Button fullWidth={false} variant="outlined" onClick={onClose}>Cancelar </Button>
                        <Button fullWidth={false} onClick={() => handleChange(1)} >Continuar</Button>
                    </div>
                    :
                    index === 1 ?
                        <div className={styles.containerButton}>
                            <Button fullWidth={false} variant="outlined" onClick={() => handleChange(0)}>Voltar</Button>
                            <Button fullWidth={false} onClick={() => handleChange(2)}>Continuar</Button>
                        </div>
                        :
                        index === 2 ?
                            <div className={styles.containerButton}>
                                <Button fullWidth={false} variant="outlined" onClick={() => handleChange(1)}>Voltar</Button>
                                <Button fullWidth={false} onClick={onSubmitHandle}>{defaultValues ? "Atualizar" : "Salvar"}</Button>
                            </div>
                            :
                            <div className={styles.containerButton}>
                                <Button fullWidth={false} variant="outlined" onClick={onClose}>Concluir</Button>
                            </div>

            }


        </div >
    )
}
