import { FormControlLabel, MenuItem, Switch } from '@material-ui/core'
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './UsabilityForm.module.scss'
import { IUsabilityValues, ShortcutsEnum } from './IUsabilityValues';
import { homeScreenShortcutsOptions } from "./homeScreenShortcutsOptions";
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import Input from 'components/ui/input/Input';
import AutoComplete from 'components/ui/autoComplete/AutoComplete'
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';

export interface IUsabilityFormProps {
    defaultValue?: IUsabilityValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IUsabilityValues) => void
    onClickBack: (value: IUsabilityValues) => void
}

const UsabilityForm: React.FC<IUsabilityFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IUsabilityValues>(defaultValue ?? {} as IUsabilityValues);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeChecked = useCallback((property: string, checked) => {
        onModified(true);
        setValues(prev => ({ ...prev, [property]: checked }));
    }, [onModified]);

    const onChangeHomeScreenShortcuts = useCallback((homeScreenShortcuts: ShortcutsEnum[]) => {
        onModified(true);
        setValues(prev => ({ ...prev, homeScreenShortcuts }));
    }, [onModified]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    return (
        <form id={styles.UsabilityForm} onSubmit={submitHandle}>
            <span>
                Esses são parâmetros que serão utilizados na usabilidade dos equipamentos do seu perfil de venda.
            </span>
            <div className={styles.container}>
                <FormControlLabel
                    checked={values.homeScreenShortcutsEnabled}
                    onChange={(_, checked) => onChangeChecked("homeScreenShortcutsEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar atalhos na tela inicial"}
                />


                {values.homeScreenShortcutsEnabled && (
                    <div className={styles.more}>
                        <FormItemContainer label={"Escolher atalhos"}>
                            <AutoComplete
                                value={values.homeScreenShortcuts}
                                variant='outlined'
                                options={homeScreenShortcutsOptions}
                                getOptionLabel={(option: ShortcutsEnum) => ShortcutsEnum[option]}
                                multiple
                                filterSelectedOptions
                                limitTags={3}
                                disableCloseOnSelect
                                onChange={(_, value) => {
                                    onChangeHomeScreenShortcuts(value as ShortcutsEnum[])
                                }}
                            />
                        </FormItemContainer>
                    </div>
                )}

                <FormControlLabel
                    checked={values.isCategoryAllEnabled}
                    onChange={(_, checked) => onChangeChecked("isCategoryAllEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar categoria 'Todos'"}
                />
                <FormControlLabel
                    checked={values.isValidateBalanceAutomaticallyEnabled}
                    onChange={(_, checked) => onChangeChecked("isValidateBalanceAutomaticallyEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar validação de saldo automaticamente"}
                />
                <FormControlLabel
                    checked={values.isShowConfirmCashPaymentEnabled}
                    onChange={(_, checked) => onChangeChecked("isShowConfirmCashPaymentEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar confirmação de pagamento em dinheiro"}
                />
                <FormControlLabel
                    checked={values.isConsultExtractEnabled}
                    onChange={(_, checked) => onChangeChecked("isConsultExtractEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar consulta ao extrato"}
                />

                <FormControlLabel
                    checked={values.isCallPaymentAutomaticallyEnabled}
                    onChange={(_, checked) => onChangeChecked("isCallPaymentAutomaticallyEnabled", checked)}
                    control={<Switch color='secondary' />} 
                    label={
                        <p>
                            Abrir tela de pagamento automaticamente
                            <Tooltip title="Observação" text="Ao clicar no botão (Ver pedido), abrir tela de pagamento automaticamente" 
                            icon="info" />
                        </p>
                    }
                />

                <FormControlLabel
                    checked={values.isPaymentScreenAfterProductEnabled}
                    onChange={(_, checked) => onChangeChecked("isPaymentScreenAfterProductEnabled", checked)}
                    control={<Switch color='secondary' />} 
                    label={
                        <p>
                            Abrir confirmação de pedido após seleção de produto
                            <Tooltip title="Observação" text="Ao selecionar um produto, abrir tela de confirmação de pedido automaticamente" 
                            icon="info" />
                        </p>
                    }
                />

                <FormControlLabel
                    checked={values.isOpenVariableProductAutomaticallyEnabled}
                    onChange={(_, checked) => onChangeChecked("isOpenVariableProductAutomaticallyEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Abrir produto de valor variável automaticamente"}
                />
                
                
                
                
                <FormControlLabel
                    checked={values.isLastActionTakenEnabled}
                    onChange={(_, checked) => onChangeChecked("isLastActionTakenEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Abrir última ação realizada"}
                />
                <FormControlLabel
                    checked={values.openVideoAfterSale}
                    onChange={(_, checked) => onChangeChecked("openVideoAfterSale", checked)}
                    control={<Switch color='secondary' />} label={"Abrir vídeo após venda"}
                />
                <FormControlLabel
                    checked={values.readBarcodeWithRearCamera}
                    onChange={(_, checked) => onChangeChecked("readBarcodeWithRearCamera", checked)}
                    control={<Switch color='secondary' />} label={"Ler código de barras com a câmera traseira"}
                />
                <FormControlLabel
                    checked={values.isRequestOrderNoteEnabled}
                    onChange={(_, checked) => onChangeChecked("isRequestOrderNoteEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Solicitar observação do pedido"}
                />
                
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default UsabilityForm