import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
} from "react";
import styles from "../FidelityForm.module.scss";
import { Button, TextField } from "@material-ui/core";
import { IFidelityPlans } from "modules/fidelity/presentation/interfaces/IFidelityPlans";

export interface IFidelityNameFormValue {
  name: string;
  pointsRequired: number;
}

export interface IFidelityNameFormProps {
  //propertys
  defaultValue?: IFidelityNameFormValue;
  isFirst: boolean;
  onSubmit?: (value: IFidelityNameFormValue) => void;
  onClose: () => void;
  responseError?: string;
  fidelityId?: string;
  data: IFidelityPlans[];
}

const FidelityNameForm: FC<IFidelityNameFormProps> = ({
  onClose,
  onSubmit,
  defaultValue,
  isFirst,
  responseError,
  fidelityId,
  data
}) => {
  var [values, setValues] = React.useState<IFidelityNameFormValue>({
    name: "",
    pointsRequired: 0,
  });

  var [error, setError] = React.useState<{
    name?: string;
    pointsRequired?: string;
  }>({ pointsRequired: "" });

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onlyNumbers = false) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: !onlyNumbers ? ev.target.value : ev.target.value.replace(/\D+/g, '') }));
  }, []);

  const validate = useCallback(() => {
    if (!values.name || values.name === "" || values.name.length < 3) {
      setError({ name: "Insira um nome valido" });
      return false;
    }

    if (data.find(x => x.name.toLowerCase().trim() === values.name.toLowerCase().trim() && x.id !== fidelityId)) {
      setError({ name: "Já existe um plano de fidelidade com esse nome." });
      return false;
    }

    setError({});
    return true;
  }, [data, fidelityId, values.name]);

  const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (validate()) {
      onSubmit?.(values);
    }
  }, [onSubmit, validate, values]);

  useEffect(() => {
    if (defaultValue) {
      setValues({
        name: defaultValue.name,
        pointsRequired: defaultValue.pointsRequired,
      });
    }
  }, [defaultValue]);



  return (
    <form onSubmit={submitHandle}>
      <div className={styles.formContainer}  >
        <div className={styles.item}>
          <h4>
            Escolha o nome do seu nível de fidelidade. Ele ficará visível no aplicativo Meep para os seus clientes:
          </h4>
          <label htmlFor='name' className={styles.required}>
            Nome do nivel:
          </label>
          <TextField
            name='name'
            onChange={changeHandle}
            value={values.name}
            variant="outlined"
            fullWidth
            size='small'
            helperText={error.name}
            error={!!error.name}
            className={styles.textField}
            placeholder='Ex.: Ouro, Prata, Diamante'
            inputProps={{
              maxLength: 50
            }}
          />
        </div>

        <div className={styles.item}>
          <label htmlFor='pointsRequired' className={styles.required}>
            Pontos necessários para obter este nível:
          </label>
          <TextField
            name='pointsRequired'
            onChange={e => changeHandle(e, true)}
            value={values.pointsRequired}
            variant="outlined"
            size='small'
            fullWidth
            className={styles.textField}
            disabled={isFirst}
            error={!!error.pointsRequired}
          />
          {
            !!error.pointsRequired &&
            <span className={styles.error}>{error.pointsRequired}</span>
          }
          {
            isFirst &&
            <span className={styles.alert}>Os <b>pontos</b> são o que seu cliente deve conquistar para chegar <b>no nível de fidelidade.</b> Como este é o seu primeiro nível cadastrado, a pontuação inicial será 0.</span>
          }
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button className={styles.buttons} type='button' onClick={onClose} variant='outlined' color='secondary'>Cancelar</Button>
        <Button className={styles.buttons} type='submit' variant='contained' color='secondary'>Continuar</Button>
      </div>
    </form>
  )
}

export default FidelityNameForm
