
export interface ICrmEvent {
    id: string;
    name: string;
    startDate: string;
    endDate?: string;
    url?: string;
    bannerUrl?: string;
    thumbnailUrl?: string;
}

export interface ICrmEventListData {
    currentPage: number;
    totalPages: number;
    totalRegisters: number;
    data: ICrmEvent[];
}

export enum ICrmEventType {
    Event = 1,
    Cashier = 2
}

export enum ICrmEventStatus {
    Closed = 1,
    Scheduled = 2
}