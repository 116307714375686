import React, { FC } from "react";
import styles from "./Radio.module.scss";

import { Radio as RadioMaterial, RadioProps, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../styles/temas/MateriaUi";
export interface IRadioProps extends RadioProps {
  //propertys
}
const Radio: FC<IRadioProps> = (props) => {
  return <RadioMaterial {...props} />;
};
export default Radio;
