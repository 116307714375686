import { IPaginatedList } from 'models/api/IApi';
import { IPosPaidResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms * 1000));

const GetPosPaidListUseCase = async (
  eventsService: IEventsApi,
  localId: string,
  cashierId?: string,
  page?: number,
  pageSize?: number,
): Promise<IPaginatedList<IPosPaidResponse>> => {
  // await delay(2);
  return await eventsService.getPosPaidList(localId, cashierId, page, pageSize);
};

export default GetPosPaidListUseCase;
