import { INewCollaboratorRequest } from "modules/colaboradores/domain/dto/INewCollaboratorRequest";

export interface INewCollaboratorFormErrors {
  EmployeeEmail?: string;
  ProfileIds?: string;
  Nome?: string;
  Cpf?: string;
}

export const NewCollaboratorValidation = (
  formData: INewCollaboratorRequest,
  setErrors: React.Dispatch<React.SetStateAction<INewCollaboratorFormErrors>>,
  currentStep: number,
  success: boolean
) => {
  let _errors: INewCollaboratorFormErrors = {};
  let hasError = false;

  if (!formData.EmployeeEmail) {
    _errors = { ..._errors, EmployeeEmail: "Campo obrigatório" };
    hasError = true;
  }
  if (currentStep === 2 && formData.ProfileIds.length === 0) {
    _errors = { ..._errors, ProfileIds: "Campo obrigatório" };
    hasError = true;
  }

  if (currentStep === 2 && !success && !formData.Nome) {
    _errors = { ..._errors, Nome: "Campo obrigatório" };
    hasError = true;
  }

  if (currentStep === 2 && !success && !formData.Cpf) {
    _errors = { ..._errors, Cpf: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
