import { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './UnitFilter.module.scss'
import { Icon, TextField } from '@material-ui/core'
import { Container, FilterButtonContainer, InputContainer } from '../../../ui/form/FormContainers'
import Row from 'modules/catalog/presentation/componentes/ui/Row'
import { useForm } from 'react-hook-form'
import { IUnitFilterValue } from './IUnitFilter'
import Button from 'components/ui/Button/Button'

export interface IUnitFilterProps {
    defaultValue?: IUnitFilterValue
    onSubmit: (values: IUnitFilterValue) => void
}

const UnitFilter: FC<IUnitFilterProps> = ({ defaultValue, onSubmit }) => {
    const { register, handleSubmit, reset, watch } = useForm<IUnitFilterValue>({
        defaultValues: defaultValue ?? {
            symbol: "",
            name: "",
        }
    });

    useEffect(() => {

        reset(defaultValue);

    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: IUnitFilterValue) => {
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.ascending;
        delete allFields.sortBy;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            symbol: '',
            name: '',
        });
        onSubmit({
            symbol: '',
            name: '',
        })
    }, [onSubmit, reset])

    return (
        <Container>
            <form
                id={styles.UnitFilter}
                onSubmit={handleSubmit(onSubmitHandle)}
            >
                <Row className={styles.row}>
                    <InputContainer flex={2} label="Nome">
                        <TextField
                            {...register("name")}
                        />
                    </InputContainer>
                    <InputContainer label="Sigla">
                        <TextField
                            {...register("symbol")}
                        />
                    </InputContainer>
                    <InputContainer  >
                        <FilterButtonContainer minWidth={100} >
                            {isAnyFieldFilled &&
                                <Button
                                    onClick={onClickResetFilter}
                                    variant="text"
                                    color="primary"
                                    startIcon={<Icon>close</Icon>}>
                                    Limpar filtro
                                </Button>}
                            <Button color='secondary' type="submit" style={{width: '76px', minWidth: '76px'}} >Buscar</Button>
                        </FilterButtonContainer>
                    </InputContainer>
                </Row>
            </form >
        </Container>
    )
}
export default UnitFilter