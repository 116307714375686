import styles from "./MyAccountMobile.module.scss";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import {
  Close,
  Help,
  KeyboardArrowRight,
  Notifications,
  Settings,
  History,
} from "@material-ui/icons";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import UserButton from "../userButton/UserButton";
import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import { useMemo } from "react";

export interface IMyAccount {
  logout: () => void;
  onClose: () => void;
  home: boolean | undefined;
  onClickHelp: () => void;
  onClickSettings: () => void;
  onClickNotifications: () => void;
  notification?: IGetNotificationsByTypeResponse[];
}

export const MyAccountMobile = ({
  logout,
  onClose,
  home,
  onClickHelp,
  onClickSettings,
  onClickNotifications,
  notification,
}: IMyAccount) => {
  const { user } = useUser();
  const { currentLocal } = useLocal();
  const { push } = useHistory();

  const unreaddNotifications = useMemo(() => {
    const total = notification?.filter((item) => !item.MensagemLida);
    return total ? total.length : null;
  }, [notification]);

  return (
    <div id={styles.MyAccountMobile}>
      <div className={styles.containerTop}>
        <Close onClick={onClose} />
      </div>
      <div className={styles.user}>
        <UserButton />
        <div>
          <p className={styles.name}>{user?.name}</p>
          <p className={styles.email}>{user?.email}</p>
        </div>
      </div>
      {!home && (
        <Button
          fullWidth={true}
          variant="outlined"
          color={"primary"}
          className={styles.buttonLocal}
          onClick={() => push("/private")}
        >
          {currentLocal?.name} <KeyboardArrowRight />
        </Button>
      )}
      <div className={styles.item} onClick={onClickNotifications}>
        {unreaddNotifications ? (
          <div className={styles.bullet}>{unreaddNotifications}</div>
        ) : null}
        <Notifications />
        <p>Notificações</p>
      </div>
      <div className={styles.item} onClick={onClickHelp}>
        <Help />
        <p>Ajuda</p>
      </div>
      <div className={styles.item} onClick={onClickSettings}>
        <Settings />
        <p>Configurações</p>
      </div>
      <div
        className={styles.item}
        onClick={() =>
          process.env.REACT_APP_OLD_PORTAL_URL &&
          (window.location.href = process.env.REACT_APP_OLD_PORTAL_URL)
        }
      >
        <History />
        <p>Versão clásica</p>
      </div>

      <div className={styles.logout} onClick={logout}>
        <img src="/assets/icon/exit.svg" alt="Sair" />
        <p onClick={logout}>Sair</p>
      </div>
    </div>
  );
};
