import { useCallback, useState } from "react";
import {
  ISidesheetFormErrors,
  ISidesheetFormItem,
} from "./interfaces/ISidesheetForm";
import { SidesheetValidation } from "./validade/SidesheetValidation";

export const UseSidesheetNovidades = (
  onSubmit: (values: ISidesheetFormItem) => void
) => {
  const [values, setValues] = useState({
    id: "",
    Title: "",
    Description: "",
    Category: "",
    Link: "",
    img: "",
  });

  const [errors, setErrors] = useState<ISidesheetFormErrors>({});

  const onChangeHandle = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onSubmitHandle = useCallback(() => {
    if (SidesheetValidation(values, setErrors)) {
      onSubmit(values);      
    }
  }, [onSubmit, values]);

  return {
    values,
    setValues,
    errors,
    setErrors,
    onChangeHandle,
    onSubmitHandle,
  };
};
