import { FC, useCallback, useEffect, useState } from 'react';
import styles from './AddOrUpdatePartnerPage.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { CreateCorporatePartnerUseCase, GetCorporatePartnerUseCase, UpdateCorporatePartnerUseCase } from 'modules/meepErp/application/useCases/partners/PartnersUseCases';
import { GetSegmentListToSelectUseCase } from 'modules/meepErp/application/useCases/segments/SegmentUseCases';
import { ICorporatePartnerValues } from 'modules/meepErp/presentation/components/partners/partnerForm/corporatePartnerForm/ICorporatePartnerValues.types';
import CorporatePartnerForm from 'modules/meepErp/presentation/components/partners/partnerForm/corporatePartnerForm/CorporatePartnerForm';

import { queryClient } from 'services/api/Api';
import { GetDefaultPartnerResponse, PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

const AddOrUpdateCorporatePartnerPage: FC = () => {

    const { id } = useParams<{ id?: string; }>();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Parceiros",
                url: '/private/meeperp/partners/'
            },
            {
                title: "Cadastro de parceiros",
            },                              
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]); 
    //queries
    const sectors = useQuery(['sectors'], GetSegmentListToSelectUseCase);

    //corporate
    const getDefaultCorporateValues = useCallback(async () => {
        try {
            showLoading();
            if (id) {
                const values = await GetCorporatePartnerUseCase(id);
                return values;
            }
        } finally {
            hideLoading();
            queryClient.invalidateQueries(['@partners'])
        }
    }, [hideLoading, id, showLoading]);

    const onSubmitCorporate = useCallback(async (values: ICorporatePartnerValues) => {
        try {
            showLoading();
            if (id) {
                return await UpdateCorporatePartnerUseCase(id, values);
            } else {
                return await CreateCorporatePartnerUseCase(values);
            }
        } finally {
            hideLoading();
            queryClient.invalidateQueries(['@partners'])
        }
    }, [hideLoading, id, showLoading]);

    const onSucessfulSubmit = useCallback((data: GetDefaultPartnerResponse) => {
        if (id) {
            queryClient.setQueriesData<PartnerListResponse | undefined>(['@partners'], (previous) =>
            (previous ? {
                ...previous,
                items: previous?.items?.map(item => item.id === id ? data : item) ?? []
            } : undefined))
        } else {
            queryClient.setQueriesData<PartnerListResponse | undefined>(['@partners'], (previous) =>
            (previous ? {
                ...previous,
                items: [data, ...previous.items]
            } : undefined))
        }
        queryClient.invalidateQueries(['@partners'])
        replace('/private/meeperp/partners')
    }, [replace])

    const defaultCorporateValues = useQuery(['@defaultPartner', id], getDefaultCorporateValues, { enabled: !!id });
    const onSubmitCorporateForm = useMutation(onSubmitCorporate, {
        onSuccess: onSucessfulSubmit
    });

    const onChangeOriginType = useCallback((originType: PartnerOriginType) => {
        replace(`/private/meeperp/partners/addOrUpdate/${originType === PartnerOriginType.CORPORATE ? 'corporate' : 'individual'}/${id ?? ""}`)

    }, [id, replace])

    return (
        <div id={styles.AddOrUpdatePartnerPage}>
            <div className={styles.container}>
                <CorporatePartnerForm
                    onChangeOriginType={onChangeOriginType}
                    defaultValues={defaultCorporateValues.data}
                    segments={sectors.data ?? []}
                    isLoading={false}
                    onSubmit={onSubmitCorporateForm.mutateAsync}
                />
            </div>
        </div>
    );
};

export default AddOrUpdateCorporatePartnerPage;