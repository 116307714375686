import { IGetUseChildParams } from "modules/permutation/domain/dto/IGetUseChildParams";
import { IUseChildfilter } from "modules/permutation/presentation/interfaces/IUseChildfilter";

const ParseFilterUseChildRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IUseChildfilter) => {

  const request: IGetUseChildParams = {
    placeId: localId,
    name: filter?.name,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterUseChildRequestUseCase

