import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './SupplyFilter.module.scss'
import { Icon, MenuItem, TextField } from '@material-ui/core'
import { Container, FilterButtonContainer, InputContainer } from '../../../ui/form/FormContainers'
import Row from 'modules/catalog/presentation/componentes/ui/Row'
import { useForm } from 'react-hook-form'
import { ISupllyNameItemSelect, ISupplyFilterValue } from './ISupplyFilter'
import Sidesheet from 'components/sidesheet/Sidesheet'
import SelectSupplyParentGroup, { ISupplyGroupList, ISupplyGroupListRequest } from '../../suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import Button from 'components/ui/Button/Button'
import { IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'
export interface ISupplyFilterProps {
    defaultValue?: ISupplyFilterValue
    supplyName: ISupllyNameItemSelect[]
    onSubmit: (values: ISupplyFilterValue) => void
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
}

const SupplyFilter: FC<ISupplyFilterProps> = ({ defaultValue, onSubmit, supplyName, getSupplyGroupList, GetSearchNameSupplyGroup }) => {
    const [opensSupplyGroup, setOpensSupplyGroup] = useState<boolean>(false);
    const [supplyGroup, setSupplyGroup] = useState<{
        id: string;
        name: string;
        code: string;
    } | null>()
    const { register, handleSubmit, reset, watch, setValue, getValues } = useForm<ISupplyFilterValue>({
        defaultValues: defaultValue ?? {
            name: '',
        }
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        }
    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: ISupplyFilterValue) => {
        const { supplyGroup, ...rest } = values;
        const transformedValues = {
            ...rest,
            supplyGroupId: supplyGroup?.id
        }
        onSubmit(transformedValues);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.sortBy;
        delete allFields.ascending;

        return Object.values(allFields).some(value => value !== undefined && value !== '' && value !== null);
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            code: null,
            name: '',
            supplyGroup: null,
            isProduct: null,
            minStockRange: null,
            maxStockRange: null,
            supplyGroupId: '',
        });
        onSubmit({
            code: null,
            name: '',
            supplyGroup: null,
            isProduct: null,
            minStockRange: null,
            maxStockRange: null,
            supplyGroupId: ''
        })
        setSupplyGroup(null)
    }, [onSubmit, reset])

    return (
        <Container>
            <form
                id={styles.SupplyFilter}
                onSubmit={handleSubmit(onSubmitHandle)}
            >
                <Row className={styles.row}>
                    <InputContainer label="Código">
                        <TextField
                            {...register("code")}
                            type='number'
                        />
                    </InputContainer>
                    <InputContainer label="Nome">
                        <TextField
                            {...register("name")}
                            value={watch('name')}
                        />
                    </InputContainer>
                    <InputContainer label={"Grupo de insumo"}>
                        <TextField
                            onClick={() => setOpensSupplyGroup(true)}
                            value={supplyGroup?.name ?? ''}
                            placeholder='Selecione grupo de insumo'
                        />
                    </InputContainer>
                    <InputContainer label="Produto de venda">
                        <TextField
                            {...register("isProduct")}
                            select
                            value={watch('isProduct') ?? ''}
                            onChange={(e) => setValue('isProduct', e.target.value === "true" ? true : e.target.value === "false" ? false : null)}
                        >
                            <MenuItem value={""}>Selecione</MenuItem>
                            <MenuItem value={'true'}>Sim</MenuItem>
                            <MenuItem value={'false'}>Não</MenuItem>
                        </TextField>
                    </InputContainer>
                    <InputContainer label="Estoque mínimo (intervalo)" className={styles.stockContainer}>
                        <div className={styles.stock}>
                            <TextField
                                {...register("minStockRange")}
                                placeholder='de'
                                type='number'
                            />
                            <TextField
                                {...register("maxStockRange")}
                                placeholder='até'
                                type='number'
                            />
                        </div>
                    </InputContainer>
                    <InputContainer  >
                        <FilterButtonContainer minWidth={100} >
                            {isAnyFieldFilled &&
                                <Button
                                    onClick={onClickResetFilter}
                                    variant="text"
                                    color="primary"
                                    startIcon={<Icon>close</Icon>}>
                                    Limpar filtro
                                </Button>}
                            <Button color='secondary' type="submit" style={{ width: '76px', minWidth: '76px' }} onClick={() => setValue('supplyGroup', watch('supplyGroup'))}>Buscar</Button>
                        </FilterButtonContainer>
                    </InputContainer>
                </Row>

                <Sidesheet
                    open={opensSupplyGroup}
                    onClose={() => setOpensSupplyGroup(false)}
                    title={<h2>Selecionar <b>grupo pai</b></h2>}
                >
                    <div className={styles.sideSheet}>
                        <SelectSupplyParentGroup
                            getSupplyGroupList={getSupplyGroupList}
                            value={watch('supplyGroup')?.id}
                            onSelect={(item) => {
                                setValue('supplyGroup', item)
                                setOpensSupplyGroup(false)
                                setSupplyGroup(item)
                            }}
                            GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                        />
                    </div>
                </Sidesheet>
            </form >
        </Container>
    )
}
export default SupplyFilter