import { EnumValueType } from "../components/discountCouponFilter/interfaces/IDiscountCouponFilter";
import { IDevices } from "./IDiscountCouponItem";

export interface ICouponForm {
  couponId?: string;
  ownerId: string;
  code: string;
  description: string;
  valueType: EnumValueType;
  value: number;
  maxValue?: number;
  quantity: number;
  forFirstOrder: boolean;
  quantityPerUser: number;
  minOrderValue: number;
  maxOrderValue: number;
  startAt: string;
  finishAt: string;
  operations: EnumOperationType[];
  deviceTypes: number[];
  devices: IDevices[];
  isRequiredCustomerCPF?: boolean;
  customerCPFs?: string[];
}

export enum EnumOperationType {
  TICKET = 1,
  PREPAIDCASHLESS,
  POSTPAIDINDIVIDUAL,
  POSTPAIDSHARED,
}
