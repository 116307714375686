import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { OnBoardingSteps } from 'modules/onBoardingTutorials/domain/enums/OnBoardingSteps';
import UseOnboardingStore from 'modules/onBoardingTutorials/store/OnBoardingStore';
import { openTutorialCard, closeTutorialCard } from 'modules/onBoardingTutorials/store/TutorialCardStore';
import React, { FC, RefObject, useCallback } from 'react'
import OnBoardingService from 'services/api/onBoardingTutorial/OnBoardingService';
const UseDevicePosTutorials = () => {

    const {
        hasOnboardingStep,
        onFinishStep,
        onCloseStep
    } = UseOnboardingStore();

    const { currentLocal } = useLocal();



    const showAddDeviceTutorial = useCallback(
        async (divRef: RefObject<HTMLDivElement>) => {
            if (currentLocal === undefined) return;

            const onBoardingService = OnBoardingService();
            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.DevicePosAdd)


            if (hasOnBoardStep) return;


            openTutorialCard({
                element: divRef.current,
                options: {
                    title: `Você também pode adicionar novas máquinas`,
                    description: 'Adicione novas máquinas para serem usadas como PDV. Você pode lançar seus pedidos e efetuar pagamentos!',
                    arrowPosition: 'left-top',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    enabledBackdrop: true,
                    action: async () => {
                        // onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.DevicePosAdd,
                        })
                        closeTutorialCard();
                    },
                    onClose: () => {
                        onCloseStep({
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.DevicePosAdd,
                        })
                    },
                    buttonLabel: 'Entendi',
                }
            });
        }, [currentLocal, hasOnboardingStep, onCloseStep, onFinishStep])


    const showDeviceTutorial = useCallback(
        async (divRef: HTMLDivElement, addDeviceRef: RefObject<HTMLDivElement>) => {
            if (currentLocal === undefined) return;

            const onBoardingService = OnBoardingService();
            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.DevicePosConfig)


            if (hasOnBoardStep) return;


            openTutorialCard({
                element: divRef,
                options: {
                    title: `Aqui ficam suas \n máquinas de cartão`,
                    description: <>Na tela inicial da sua máquina, clique no botão <b>Validar</b> para liberar o aplicativo.</>,
                    arrowPosition: 'left-top',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    enabledBackdrop: true,
                    action: async () => {
                        // onClose: () => {
                        showAddDeviceTutorial(addDeviceRef);
                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.DevicePosConfig,
                        })
                    },
                    onClose: () => {
                        onCloseStep({
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.DevicePosConfig,
                        })
                    },
                    buttonLabel: 'Continuar',
                }
            });
        }, [currentLocal, hasOnboardingStep, onCloseStep, onFinishStep, showAddDeviceTutorial])



    return ({ showDeviceTutorial })
}

export default UseDevicePosTutorials