import { ISurveyService } from "modules/survey/domain/interfaces/ITrackConfigService"
import { EventSurvey } from "modules/survey/presentation/components/EventSurvey"
import { TrackSurveyWidget } from "modules/survey/presentation/components/TrackSurveyWidget"
import { EnumSurveySendMethod } from "modules/survey/presentation/interfaces/EnumSurveySendMethod";
import { ITrackSurvey } from "modules/survey/presentation/interfaces/ITrackSurveyList";

const GetCustomerSurveyUseCase = async (service: ISurveyService, ownerId: string, id: string): Promise<{ id: string, event: EventSurvey, trackSurvey: ITrackSurvey, sendMethod:EnumSurveySendMethod }> => {
    const response = await service.get(ownerId, id);

    return ({
        id: response.id,
        event: {
            type: response.sendEventType,
        },
        trackSurvey: {
            name: response.trackSurveyName,
            id: response.trackSurveyId,
        },
        sendMethod: response.sendMethod
    })
}
export default GetCustomerSurveyUseCase