import { IAverageTicket } from "modules/cashlessDashboard/presentation/components/averageTicket/interfaces/IAverageTicket";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetAverageTicketUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<IAverageTicket> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getAverageTicket(filterRequest);

  const result: IAverageTicket = {
    consumption: {
      ...response.consumption,
      malePercentage: response.consumption.average ? Number(((response.consumption.male / response.consumption.average) * 100).toFixed(2)) : 0,
      femalePercentage: response.consumption.average ? Number(((response.consumption.female / response.consumption.average) * 100).toFixed(2)) : 0,
      unknownPercentage: response.consumption.average ? Number(((response.consumption.unknown / response.consumption.average) * 100).toFixed(2)) : 0,
    },
    recharge: {
      ...response.recharge,
      malePercentage: response.recharge.average ? Number(((response.recharge.male / response.recharge.average) * 100).toFixed(2)) : 0,
      femalePercentage: response.recharge.average ? Number(((response.recharge.female / response.recharge.average) * 100).toFixed(2)) : 0,
      unknownPercentage: response.recharge.average ? Number(((response.recharge.unknown / response.recharge.average) * 100).toFixed(2)) : 0,
    }
  }

  return result;
}

export default GetAverageTicketUseCase
