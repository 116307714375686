import { ITicketService } from 'modules/events/application/interfaces/ITicketService';
import { ITicketForm } from 'modules/events/presentation/pages/manageEvent/components/tickets/components/ticketForm/ITicketForm';

const CreateTicketUseCase = async (ticketService: ITicketService, localId: string, eventId: string, ticketForm: ITicketForm): Promise<string> => {
  const payload = {
    ...ticketForm, 
    expireTime: ticketForm.hasExpireTime ? ticketForm.expireTime as number : null, 
    localClienteId: localId,
    maxQuantityPerUser: ticketForm.maxQuantityPerUser || 1,
    type: ticketForm.type || 15
  }
  
  return await ticketService.create(eventId, payload);
};

export default CreateTicketUseCase;
