import FallbackMainDash from '../fallbackMainDash/FallbackMainDash';
import styles from './ListProducts.module.scss'
import { format, isValid, parseISO } from 'date-fns';

interface ListProductsProps {
    data?: {
        records: {
            productName: string,
            quantity: number,
            total: number
        }[],
        period: string
    };
    isLoading: boolean
    filter?: string
}

const ListProducts = ({ data, isLoading, filter }: ListProductsProps) => {
    return (
        <div id={styles.ListProducts}>
            <div className={styles.top}>
                <div>
                    <p className={styles.title}>Produtos mais vendidos do {isValid(new Date(filter ?? "")) ? "dia" : "caixa"}</p>
                    <p className={styles.description}>Acompanhe os produtos e o valor mais vendidos</p>
                </div>
                <p className={styles.description}>{data && format(parseISO(data.period + "Z"), 'dd/MM/yyyy HH:mm')}</p>
            </div>
            {isLoading ? (
                <div className={styles.animatedLoading}></div>
            ) : (
                <div className={styles.content}>
                    {data && data.records.length > 0 ? (
                        <>
                            {data?.records.map(item => {
                                return (
                                    <div className={styles.box}>
                                        <div className={styles.product}>
                                            <div className={styles.icon}>
                                                <img src="/assets/icon/icon_restaurant.svg" alt="Icone Productos" />
                                            </div>
                                            <p className={styles.productName}>{item.productName}</p>
                                        </div>
                                        <p className={styles.quantity}>{item.quantity} vendas</p>
                                        <p className={styles.total}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <FallbackMainDash />
                    )}

                </div>
            )}

        </div>
    )
}

export default ListProducts