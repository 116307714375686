import { useHistory } from 'react-router-dom';
import styles from './TrialPage.module.scss';
import UseProspect from '../../hooks/UseProspect';
import { useEffect } from 'react';
import SaasSuccessLayout from '../SaasSuccessLayout';

export const TrialPage = () => {
    const history = useHistory();
    const { email } = UseProspect();

    useEffect(() => {
        setTimeout(() => {
            history.push(`/login?email=${email}`);
        }, 25000);
    }, []);

    return ( 
        <SaasSuccessLayout background="linear-gradient(270deg, #EEF0FB 0%, #E1C7FF 100%)" >
            <div id={styles.TrialPage}>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <span style={{ fontWeight: 900 }}>Parabéns! 🎉</span>
                            <span>
                                Você está experimentando a <b>revolução do seu negócio gratuitamente!</b>
                            </span>
                            <p>
                                Aguarde, estamos redirecionado você para o Portal Mee.
                            </p>
                            {/* <button onClick={() => history.push(`/login?email=${email}`)}>Acessar portal</button> */}
                        </div>
                        <div className={styles.img}>
                            <img src="/assets/gif/intro-saas.gif" alt="" />
                        </div>
                    </div>

                    {/* <div className={styles.tag}>
                        <img className={styles.plusMask1} src="/assets/icon/saas/trial/plus-mask1.svg" alt="" />

                        <div>
                            <span>Sabia que por mais <b>R$12,90 por mês</b> você adquire uma maquininha da Meep? <span>Aproveite e garanta a sua no Portal Meep!</span></span>
                        </div>

                        <img className={styles.hand} src="/assets/icon/saas/hand.svg" alt="" />
                        <img className={styles.plusMask2} src="/assets/icon/saas/trial/plus-mask2.svg" alt="" />
                    </div> */}
                </div>
            </div>
        </SaasSuccessLayout>
    )
}
