import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import { ITicketMedio } from "modules/dashboard/presentation/components/ticketMedio/ITicketMedio";
import IDashboardService from "../interfaces/IDashboardService";

const GetTicketMedioUseCase = async (dashboardService: IDashboardService, filter: IFilterRequest): Promise<ITicketMedio> => {
  const response = await dashboardService.getTicketMedio(filter);

  const itemsConcat = response.items.map(x => x[0]);
  const ticketMedioCalculated = itemsConcat.reduce((curr, next) => {
    curr.averageTicketPerCustomer += next?.averageTicketPerCustomer || 0;
    curr.averageTicketPerTable += next?.averageTicketPerTable || 0;
    curr.customers += next?.customers || 0;
    curr.tables += next?.tables || 0;
    curr.total += next?.total || 0;
    return curr;
  }, {
    averageTicketPerCustomer: 0,
    averageTicketPerTable: 0,
    customers: 0,
    tables: 0,
    total: 0,
  });


  /*
   totalTables: number,
    totalCustomers: number
    totalConsumption: number,
    totalAverageTicketPerTable: number,
    totalAverageTicketPerCustomer: number
  */
 //utiliza dados vindos da api caso nao venha utiliza dados calculado
  const newTicketMedio = {
    tables: response.totalTables ?? ticketMedioCalculated.tables,
    customers: response.totalCustomers ?? ticketMedioCalculated.customers,
    total: response.totalConsumption ?? ticketMedioCalculated.total,
    averageTicketPerTable: response.totalAverageTicketPerTable ?? ticketMedioCalculated.averageTicketPerTable,
    averageTicketPerCustomer: response.totalAverageTicketPerCustomer ?? ticketMedioCalculated.averageTicketPerCustomer,
  }

  return newTicketMedio;
}

export default GetTicketMedioUseCase;