import Layout from "components/layout/presentation/Layout";
import React, { FC } from "react";
import { Transactions } from "./integrationsPage";

const Transacoes: FC = () => {
  return (
    <Layout>
      <Transactions />
    </Layout>
  );
};
export default Transacoes;
