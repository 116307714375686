import { ICategories } from "modules/cashlessDashboard/presentation/components/categories/interfaces/ICategory";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetCategoriesUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<ICategories> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getCategories(filterRequest);

  return response;
}

export default GetCategoriesUseCase
