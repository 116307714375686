import { Drawer, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import styles from './EventLink.module.scss';
import { Autocomplete, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IOrganizerEvents } from 'modules/events/domain/models/IOrganizerEvents';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IEvents } from 'modules/events/domain/models/IEvents';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

interface IEventLinkProps {
    events: IOrganizerEvents[]
    open: boolean;
    onClose: (success?: boolean) => void;
    onSubmit: (eventId: string, ioticketsEventId: number, localId: string) => Promise<void>;
    className?: string;
    getData?: () => Promise<void>
    event: IEvents
};

export const EventLink: FC<IEventLinkProps> = ({ event, events, open, onClose, onSubmit, getData, className }) => {
    const [selectedEvent, setSelectedEvent] = useState<{ id: number, name: string} | null>(null)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { toast } = useUi();
    const { currentLocal } = useLocal();
    
    useEffect(() => {
        const ev = events.find(x => x.id === event.ioticketEventId)
        if (ev)
        setSelectedEvent(ev)
    }, [event.ioticketEventId, events])

    useEffect(() => {
        (async () => {
            if (open) {
                try {
                    setLoading(true)
                    await getData?.();
                } finally {
                    setLoading(false)
                }
            }
        })()

    }, [getData, open])

    const handleSubmit = async () => {
        if (!currentLocal) return;
        
        if (!selectedEvent) {
            toast('Selecione o evento da Meep Tickets para ser vinculado', 'error')
            return
        }

        await onSubmit(event.id, selectedEvent.id, currentLocal.id);
        setSuccess(true);
    }

    return (
        <Drawer anchor='right' open={open} onClose={() => onClose(success)}>
            <div id={styles.EventLink} className={className}>
                <header>
                    <p>Vincular <b>evento</b></p>
                    <IconButton onClick={() => onClose(success)}>
                        <CloseIcon />
                    </IconButton>
                </header>
                <div className={styles.body}>
                    {
                        !success ?
                        <>
                            <p className={styles.title}>Vínculo com evento Meep Tickets</p>

                            <Autocomplete
                                disabled={loading || !events.length || !!event.ioticketEventId}
                                className={styles.input}
                                style={{ flex: 2, minWidth: 350 }}
                                size='small'
                                renderInput={(props) =>
                                    <div className={styles.inputContainer}>
                                        <span>Evento Meep Tickets</span>
                                        <TextField
                                            maxRows={1}
                                            name={"events"}
                                            {...props}
                                        />
                                    </div>
                                }
                                renderOption={(props, option) => {
                                    return (
                                        <span {...props} key={option.id}>
                                            {option.name}
                                        </span>
                                    );
                                }}
                                isOptionEqualToValue={(option, values) => option.id === values.id}
                                options={events}
                                getOptionLabel={(option) => option.name}
                                onChange={(ev, value) => {
                                    setSelectedEvent(value)
                                }}
                                value={selectedEvent}
                            />
                        </> : 
                        <>
                            <div className={styles.successContainer}>
                                <img src="/assets/img/concluded.png" alt="" />
                                <p>Pronto! Seu evento foi vinculado com sucesso!</p>
                            </div>
                        </>
                    }
                </div>
                <div className={styles.footer}>
                    {
                        !success ? 
                        <>
                            <Button className={styles.button} variant='outlined' onClick={() => onClose(success)}>Cancelar</Button>
                            <Button disabled={loading || !!event.ioticketEventId} className={styles.button} variant='contained' onClick={handleSubmit}>
                            {!loading ? 'Vincular' : 
                                <img
                                    src="/assets/img/loader.gif"
                                    alt="loader"
                                    className={styles.loader}
                                />
                                }
                            </Button>
                        </> :
                        <Button className={styles.button} variant='contained' onClick={() => onClose(success)}>
                            Fechar
                        </Button>
                    }
                </div>
            </div>
        </Drawer>
    );
};