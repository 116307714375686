import React from "react";
import styles from "./LicensesModal.module.scss";
import { Close } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Slide } from "@material-ui/core";
import Button from "components/ui/Button/Button";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} style={{ backgroundColor: "transparent" }} />;
});

export interface ILicensesModalProps {
    open: boolean;
    onClose: () => void;
}

const LicensesModal = ({ open, onClose }: ILicensesModalProps) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            PaperProps={{
                style: { backgroundColor: 'transparent', boxShadow: 'none', minWidth: "fit-content" },
            }}
        >
            <div id={styles.LicensesModal}>
                <header>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </header>
                <DialogContent className={styles.modalContent}>
                    <div className={styles.contentInfo}>
                        <div className={styles.image}>
                            <img src="/assets/icon/saas/licenses-modal-bg.svg" alt="" className={styles.bg}/>
                            <img src="/assets/icon/saas/licenses-modal-pos.png" alt=""/>
                        </div>
                        {/* <div className={styles.info}>
                            <p><b>Você não tem mais licenças</b> de máquina disponível!</p>
                            <h1>Contrate A PARTIR DE R$29,90/MÊS</h1>
                            <p>Contrate licenças e tenha o App Mee disponível para uso na sua maquininha!</p>
                            <Button color="inherit" onClick={() => window.open("https://wa.me/5531971119818?text=Quero%20contratar%20mais%20licen%C3%A7as%20de%20maquininha!", "_blank")}>
                                Contratar licenças
                            </Button>
                        </div> */}
                        <div className={styles.info}>
                            <div className={styles.title}>
                                <p><b>Você não tem mais licenças</b> de<br/> máquina disponível!</p>
                                <h1>Contrate A PARTIR</h1>
                                <img src="/assets/icon/saas/modal/underline.svg" alt="" className={styles.saasunderline}  />
                                <h1>DE R$29,90/MÊS</h1>
                                <img src="/assets/icon/saas/modal/underline.svg" alt="" className={styles.saasunderline}  />
                            </div>
                            <p>Contrate licenças e tenha o App Mee<br/> disponível para uso na sua maquininha!</p>
                            <Button
                                onClick={() => window.open("https://wa.me/5531971119818?text=Quero%20contratar%20mais%20licen%C3%A7as%20de%20maquininha!", "_blank")}
                                style={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "rgba(149, 92, 255, 1)" }}
                            >
                                Contratar licenças
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
};
export default LicensesModal;
