import { ImportExport } from "@mui/icons-material";
import styles from "./ForDeviceHeader.module.scss";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";

export interface IForDeviceHeaderProps {
  params: IPostSaleForDeviceParams;
  setParams: React.Dispatch<React.SetStateAction<IPostSaleForDeviceParams>>;
}

const titleHeader = [
  {
    title: "Equipamento",
    value: "equipamentoNome",
  },
  {
    title: "Nº Série",
    value: "equipamentoNumeroSerie",
  },
  {
    title: "Cancelado",
    value: "cancelado",
  },
  {
    title: "Cashless",
    value: "valorCashless",
  },
  {
    title: "Débito",
    value: "valorDebito",
  },
  {
    title: "Débito (NT)",
    value: "valorDebitoNT",
  },
  {
    title: "Crédito",
    value: "valorCredito",
  },
  {
    title: "Crédito (NT)",
    value: "valorCreditoNT",
  },
  // {
  //   title: "PIX",
  //   value: "",
  // },
  {
    title: "Dinheiro",
    value: "valorDinheiro",
  },
  {
    title: "Desconto",
    value: "valorDesconto",
  },
  {
    title: "Voucher",
    value: "valorVoucher",
  },
  {
    title: "Outros",
    value: "valorOutro",
  },
  {
    title: "Em aberto",
    value: "valorEmAberto",
  },
  {
    title: "Suprimento",
    value: "suprimentos",
  },
  {
    title: "Sangria",
    value: "sangrias",
  },
  {
    title: "Tx. serviço",
    value: "taxaServico",
  },
  {
    title: "Total",
    value: "valorTotal",
  },
];

const ForDeviceHeader = ({ params, setParams }: IForDeviceHeaderProps) => {
  const [orderBy, setOrderBy] = useState({ Type: "equipamentoNome" } as {
    Orientation: "asc" | "desc",
    Type: string,
  });

  useEffect(() => {
    if (orderBy.Type && orderBy.Orientation) {
      setParams({
        ...params,
        Sort: {
          Orientation: orderBy.Orientation,
          Type: orderBy.Type,
        }
      });
    }
  }, [orderBy]);
  

  return (
    <div id={styles.TransactionsHeader} className="div-to-capture">
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            <IconButton
              disableRipple
              onClick={() => {
                setOrderBy({
                  Orientation: params.Sort?.Orientation === "asc" ? "desc" : "asc",
                  Type: item.value ?? "",
                });
              }}
            >
              <ImportExport />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};
export default ForDeviceHeader;
