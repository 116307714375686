import { ITIDValidation, ITIDValidationErrors } from "modules/aprovacaoTIDs/presentation/interfaces/ITIDValidation";

export const validate = (
  values: ITIDValidation,
  setErrors: React.Dispatch<React.SetStateAction<ITIDValidationErrors>>
) => {
  let _errors: ITIDValidationErrors = {};
  let hasError = false;

  if (!values.DeviceSerialNumber) {
    _errors = { ..._errors, DeviceSerialNumber: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};
