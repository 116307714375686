import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import InputImage from 'components/ui/inputImage/InputImage';
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';
import { Modal } from 'components/ui/modal/Modal';
import { IEventsForm } from 'modules/events/domain/models/IEvents';
import React, { FC } from 'react';
import { IMG } from 'services/utils/IMG';
import style from './EventsForm.module.scss';
import UseEventsForm from './UseEventsForm';

export interface IEventsFormProps {
  defaultEvent?: IEventsForm;
  handleSubmit: (event: IEventsForm) => void;
  handleCancel?: () => void;
}

const EventsForm: FC<IEventsFormProps> = ({
  handleSubmit,
  defaultEvent,
  handleCancel,
}) => {
  const {
    handleSubmitForm,
    setFormTouched,
    eventForm,
    handleChangeEvent,
    handleChangeImage,
    handleCancelForm,
    toggleModal,
    cleanForm,
    openModal,
    errors,
    attractionImage,
    attractionName,
    handleAttractionImage,
    handleAttractionName,
    handleAddAttraction,
    handleDeleteAttraction
  } = UseEventsForm(handleSubmit, defaultEvent, handleCancel);  

  return (
    <div id={style.EventsFormContainer}>
      <form
        className={style.eventForm}
        onSubmit={handleSubmitForm}
        onChange={() => setFormTouched(true)}>
        <h1>Informações do Evento</h1>
        <div className={style.place}>
          <Input
            // fullWidth={false}
            label="Nome do Evento"
            value={eventForm?.name || ''}
            name="name"
            onChange={evt => handleChangeEvent(evt)}
            error={!!errors.name}
            helperText={errors.name}
            inputProps={{
              maxLength: 50,
            }}
          />
          {/* <Input
                        fullWidth={false}
                        select
                        label="Local do Evento"
                        name="localId"
                        value={eventForm?.localId || ""}
                        onChange={(evt) => handleChangeEvent(evt)}
                    >
                        <MenuItem value={1}>Bar do Bola1</MenuItem>
                        <MenuItem value={2}>Bar do Bola2</MenuItem>
                        <MenuItem value={3}>Bar do Bola3</MenuItem>
                    </Input> */}
        </div>

        <div className={style.imageInput}>
          <InputSingleImage
            value={eventForm?.imageUrl}
            onChangeImage={handleChangeImage}
            name="imageUrl"
            error={!!errors.imageUrl}
            helperText={errors.imageUrl}
            accept={[IMG.JPEG, IMG.PNG]}
          />
          <ul>
            <li>
              Envie uma imagem nos formatos, PNG ou JPG.
              {/*  com o tamanho de 300x300px e máximo 1080x1080px */}
            </li>
            <li>A imagem será usada na página do seu evento.</li>
          </ul>
        </div>
        <div className={style.description}>
          <h1>Descrição</h1>
          <Input
            multiline
            rows={2}
            className={style.descriptionInput}
            name="description"
            value={eventForm?.description || ''}
            onChange={evt => handleChangeEvent(evt)}
            inputProps={{
              className: style.input,
              maxLength: 200,
            }}
          />
        </div>

        <div className={style.schedule}>
          <h1>Horário do Evento</h1>
          <div>
            <Input
              label="Data Início"
              type="datetime-local"
              fullWidth={false}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: eventForm?.endDateString || '9999-12-31T23:59'
              }}
              name="startDateString"
              value={eventForm?.startDateString || ''}
              onChange={evt => handleChangeEvent(evt)}
              error={!!errors.startDateString}
              helperText={errors.startDateString}
              my-date-format="DD/MM/YYYY hh:mm"
            />
            <Input
              fullWidth={false}
              label="Data Término"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: eventForm?.startDateString || '',
                max: "9999-12-31T23:59"
              }}
              name="endDateString"
              value={eventForm?.endDateString || ''}
              onChange={evt => handleChangeEvent(evt)}
              error={!!errors.endDateString}
              helperText={errors.endDateString}
            />
          </div>
        </div>

        <div className={style.schedule}>
          <h1>Período de Venda</h1>
          <div>
            <Input
              label="Início"
              type="datetime-local"
              fullWidth={false}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: eventForm?.endSaleDate || '9999-12-31T23:59'
              }}
              name="startSaleDate"
              value={eventForm?.startSaleDate || ''}
              onChange={evt => handleChangeEvent(evt)}
              error={!!errors.startSaleDate}
              helperText={errors.startSaleDate}
            />
            <Input
              fullWidth={false}
              label="Término"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: eventForm?.startSaleDate || '',
                max: "9999-12-31T23:59"
              }}
              name="endSaleDate"
              value={eventForm?.endSaleDate || ''}
              onChange={evt => handleChangeEvent(evt)}
              error={!!errors.endSaleDate}
              helperText={errors.endSaleDate}
            />
          </div>
        </div>

        <div className={style.attractions}>
          <h1>Atrações</h1>
          <div>
            <Input
              // multiline
              // rows={5}
              placeholder="Nome"
              fullWidth={false}
              value={attractionName || ''}
              onChange={evt => handleAttractionName(evt)}
              inputProps={{
                className: style.input,
                maxLength: 100,
              }}
            />
            <div>
              <div>
                <InputImage
                  values={attractionImage}
                  width={50}
                  height={50}
                  onChangeImage={props =>
                    handleAttractionImage(props[0].imagem ?? '')
                  }
                />
                <ul>
                  <li>Envie uma imagem nos formatos, PNG ou JPG.</li>
                  <li>A imagem será usada como capa da atração.</li>
                </ul>
              </div>
              <Button
                endIcon={<AddCircleIcon />}
                fullWidth={false}
                disabled={!attractionName}
                onClick={handleAddAttraction}>
                Adicionar atração
              </Button>
            </div>
          </div>
        </div>

        {eventForm?.attractions && (eventForm.attractions.length > 0) && (
          <div className={style.attractionsList}>
            <div>
              <p>Nome</p>
              <p>Imagem</p>
              <p>Excluir</p>
            </div>
            <div>
              {eventForm.attractions.map((att, key) => (
                <div key={key}>
                  <div><h3>{att.name}</h3></div>
                  <div>
                    {att.imageUrl ? (
                    <img src={att.imageUrl} alt="attraction_image" />
                    ) : (
                      <TheaterComedyIcon />
                    )}
                  </div>
                  <div><IconButton onClick={() => handleDeleteAttraction(att?.id ?? '')}><DeleteIcon /></IconButton></div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={style.action}>
          {/* <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={!!eventForm?.promotersCanAdd}
                                onChange={(evt, checked) =>
                                    handleChangeEvent(evt, checked)
                                }
                                name="promotersCanAdd"
                            />
                        }
                        label="Permitir que os Promotores adicionem a listas"
                    /> */}
          <Button
            fullWidth={false}
            variant="outlined"
            onClick={handleCancelForm}>
            Cancelar
          </Button>
          <Button fullWidth={false} type="submit">
            {defaultEvent ? 'Editar Evento' : 'Criar Evento'}
          </Button>
        </div>
      </form>
      <Modal
        toggleModal={toggleModal}
        onConfirm={cleanForm}
        open={openModal}
        title="Descartar alterações?">
        <p>Tem certeza que deseja cancelar?</p>
        <p>As alterações serão perdidas!</p>
      </Modal>
    </div>
  );
};
export default EventsForm;
