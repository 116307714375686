import Layout from "components/layout/presentation/Layout";
import AddGuestPage from "modules/events/presentation/pages/addGuest/AddGuestPage";
import React from "react";

const AddGuest = () => {
  return (
    <Layout>
      <AddGuestPage />
    </Layout>
  );
};

export default AddGuest;
