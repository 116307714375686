import { IPutEditChildRequest } from "modules/permutation/domain/dto/IPutEditChildRequest";
import { IEditChild } from "modules/permutation/presentation/interfaces/IEditChild";

const ParseEditChildRequestUseCase  = (
  request: IEditChild,
  localId: string
) => {
  const _request: IPutEditChildRequest = {
    placeId: localId,
    hasLimit: request.hasLimit,
    limit: request.limit
  };

  return _request;
};

export default ParseEditChildRequestUseCase;
