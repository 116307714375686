import { Pagination, Skeleton } from '@material-ui/lab'
import { format, parseISO } from 'date-fns'
import { ICustomerEventsListData } from 'modules/crm/presentation/intefaces/ICustomerEventData'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { moneyMask, moneyMaskNumber } from 'services/utils/Money'
import { CRMCustomerEventsColunmsPropertyType, ICrmCustomerEventsColumnsConfig } from '../interfaces/IColumnsConfig'
import styles from './CrmCustomerEventsList.module.scss'
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext'
import { FeatureTypeEnum } from 'modules/plans/presentation/interfaces/FeatureTypeEnum'
export interface ICrmCustomerEventsListProps {
    //propertys
    columnsConfig: ICrmCustomerEventsColumnsConfig[],
    data?: ICustomerEventsListData,
    onChangePage: (pagination: { page: number, pageSize: number }) => void,
    isLoading?: boolean;
}
const CrmCustomerEventsList: FC<ICrmCustomerEventsListProps> = ({ data, columnsConfig, onChangePage, isLoading }) => {
    const { checkPermission } = usePlans();

    const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

    const totalPage = useMemo(() => Math.ceil((data?.totalRegisters ?? 0) / pagination.pageSize), [data?.totalRegisters, pagination.pageSize]);

    const onChangePageHande = useCallback((page) => {
        setPagination(prev => ({ ...prev, page: page }))
    }, [])

    useEffect(() => {
        onChangePage(pagination)
    }, [onChangePage, pagination])

    const formatValues = useCallback((property: CRMCustomerEventsColunmsPropertyType, value: number | string) => {
        switch (property) {
            case `id`:
                return value;
            case `date`:
                return format(parseISO(value as string), 'dd/MM/yyyy');
            case `name`:
                return value;
            case `categoryName`:
                return value;
            case `ticketCategoryName`:
                return value;
            case `totalConsumed`:
                return moneyMaskNumber(Number(value) || 0);
            case `totalTicketPurchased`:
                return moneyMaskNumber(Number(value) || 0);
            default:
                return value
        }
    }, [])


    return (isLoading ? <div>Carregando eventos</div> :
        !data?.data.length && checkPermission(FeatureTypeEnum.CRM) ? <span className={styles.noEvents}>Nenhum evento registrado</span> : <div id={styles.CrmCustomerEventsList} >
            <div className={styles.header} >
                {columnsConfig.map((item) => !item.hide && <div className={styles.row}>{item.title}</div>)}
            </div>
            <div className={styles.list} >
                {
                    !checkPermission(FeatureTypeEnum.CRM) ?

                        [...Array(3)].map(x => (
                            <div className={styles.item}>
                                {
                                    [...Array(6)].map(y => (
                                        <div className={`${styles.row} ${styles.bodyRow}`}>
                                            <div style={{ height: 20, width: 100, backgroundColor: '#F5F5F5', top: 6, left: 5, position: 'relative', display: 'inline-flex' }} />
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        :
                        <>
                            {
                                isLoading ?

                                    [...Array(5)].map(item => <Skeleton variant='rect' height={36} ></Skeleton>)
                                    :
                                    (data?.data.map((eventItem) => <div className={styles.item}>
                                        {columnsConfig.map((item) => !item.hide && <div className={`${styles.row} ${styles.bodyRow}`}>{formatValues(item.property, eventItem[item.property])}</div>)}
                                    </div>))
                            }
                        </>
                }

            </div>
            <div className={styles.pagination} >
                <Pagination page={pagination.page} count={totalPage} onChange={(_, page) => onChangePageHande(page)}></Pagination>
            </div>
        </div>
    )
}
export default CrmCustomerEventsList