import { useUi } from "contexts/userInterface/UserInterfaceContext";
import ActivateTicketUseCase from "modules/events/application/useCases/events/tickets/ActivateTicketUseCase";
import CreateTicketUseCase from "modules/events/application/useCases/events/tickets/CreateTicketUseCase";
import DeactivateTicketUseCase from "modules/events/application/useCases/events/tickets/DeactivateTicketUseCase";
import DeleteTicketUseCase from "modules/events/application/useCases/events/tickets/DeleteTicketUseCase";
import UpdateTicketUseCase from "modules/events/application/useCases/events/tickets/UpdateTicketUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import TicketsApi from "services/api/events/tickets/TicketsApi";
import { ITicketForm } from "./components/ticketForm/ITicketForm";

const ticketsService = TicketsApi();

const UseTicket = (refreshEvent: () => Promise<void>) => {
  const { currentLocal } = useLocal();
  const [openForm, setOpenForm] = useState(false);
  const { toast, showLoading, hideLoading } = useUi();
  const { eventId } = useParams<{ eventId: string }>();
  const [selectedTicket, setSelectedTicket] = useState<ITicketForm>({} as ITicketForm);
  const [openDelete, setOpenDelete] = useState(false);


  const openFormModal = useCallback((ticket?: ITicketForm) => {
    setOpenForm(true);
    setSelectedTicket(ticket || {} as ITicketForm);
  }, []);

  const closeFormModal = useCallback(() => {
    setOpenForm(false);

  }, []);

  const handleSaveTicket = useCallback(async (ticket: ITicketForm) => {
    try {
      showLoading();
      ticket.genderRestriction = ticket.genderRestriction === 0 ? undefined : ticket.genderRestriction;
      if (ticket.id)
        await UpdateTicketUseCase(ticketsService, currentLocal!.id, eventId, ticket.id, ticket);
      else
        await CreateTicketUseCase(ticketsService, currentLocal!.id, eventId, ticket);

      toast("Ingresso salvo com sucesso!", "success");
      closeFormModal();
      refreshEvent();
    } finally {
      hideLoading();
    }
  }, [closeFormModal, currentLocal, eventId, hideLoading, refreshEvent, showLoading, toast]);

  const handleToggleTicketStatus = useCallback(async (ticketId: string, checked: boolean) => {
    try {
      showLoading();
      if (checked)
        await ActivateTicketUseCase(ticketsService, eventId, ticketId, currentLocal!.id);
      else
        await DeactivateTicketUseCase(ticketsService, eventId, ticketId, currentLocal!.id);
       return checked; 
    } catch (error: any) {
      toast(error?.response?.data, "error");
      return !checked;
    } finally {
      hideLoading();
    }
  }, [currentLocal, eventId, hideLoading, showLoading, refreshEvent]);

  const handleDeleteTicket = useCallback(async (ticketId: string) => {
    try {
      showLoading();
      await DeleteTicketUseCase(ticketsService, eventId, ticketId, currentLocal!.id);
      toast("Ingresso excluído com sucesso!", "success");
      setOpenDelete(false);
      refreshEvent();
    } finally {
      hideLoading();
    }
  }, [currentLocal, eventId, hideLoading, showLoading, refreshEvent]);

  return {
    openForm,
    selectedTicket,
    openDelete,
    setSelectedTicket,
    openFormModal,
    closeFormModal,
    handleSaveTicket,
    handleToggleTicketStatus,
    handleDeleteTicket
  };
};

export default UseTicket;
