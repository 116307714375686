import React, { FC, PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react'
import { IActionButtons, IMenuButton } from './Item/Item'
import styles from './List.module.scss'
import { IListItem } from './interfaces/IList'
import { CircularProgress, Icon } from '@material-ui/core'
import { DropResult, ResponderProvided } from 'react-beautiful-dnd'
import SkeletonList from './skeleton/SkeletonList'
import { ListHeader } from './ListHeader'
import { ListDnD } from './ListDnD'
import { FixedList } from './FixedList'
import { ListPagination } from './ListPagination'

export interface ColumnConfigsItem<T> {
    title?: string
    sortable?: boolean
    hide?: boolean
    hideTitle?: boolean
    width?: number | string
    onClickSort?: () => void
    renderRow?: (value: any, item: T, setItem: React.Dispatch<React.SetStateAction<T>>) => ReactElement | string | number
}
export type ColumnConfigs<T> = {
    [P in keyof T]?: ColumnConfigsItem<T>
} & {
    [key: string]: ColumnConfigsItem<T>
}

export interface ListProps<T extends IListItem> {
    /** Array of items to List */
    list?: T[],
    hideHeader?: boolean
    onClick?: (value: T) => void
    formatTitle?: (property: string) => string
    onClickSort?: (sortItem: ISort, sortArray: ISort[]) => void
    renderItem?: (item: T, ReactElement: ReactElement) => ReactElement
    columnsConfigs?: ColumnConfigs<T>
    pagination?: IPagination;
    actionsRender?: (item: T) => ReactElement;
    onChangePage?: (page: number, size: number) => void;
    exportButton?: boolean;
    actionButtons?: IActionButtons<T>[],
    enableDnd?: boolean,
    onChangeDnd?: (list: T[]) => void,
    menuButtons?: IMenuButton<T>[]
    loading?: boolean
    minWidth?: number
    showAccordion?: (item: T) => boolean
    renderAccordion?: (item: T) => ReactElement
    sub?: boolean,
    subLevel?: number,
    hidePagintion?: boolean,
    total?: boolean
    valueTotal?: string
}

export interface IListValues {
    hide?: boolean,
    selected?: boolean
}

export interface IPagination {
    page: number
    pageSize: number
    count: number
}

export enum SortDirection {
    ASC = "asc",
    DESC = "desc"
}

export interface ISort {
    property: string,
    direction: SortDirection,
    ascending?: boolean
}


export const List = <T extends IListItem,>({
    list,
    enableDnd,
    onClickSort,
    renderItem,
    columnsConfigs,
    pagination,
    onChangePage,
    actionsRender,
    actionButtons,
    onChangeDnd,
    menuButtons,
    loading,
    minWidth,
    renderAccordion,
    sub,
    hideHeader,
    showAccordion,
    subLevel,
    hidePagintion,
    total,
    valueTotal
}: ListProps<T>) => {

    const [listValues, setListValues] = useState<(T & IListValues)[]>(list ?? []);
    const [currentSortProperties, setCurrentSortProperties] = useState<ISort[]>([]);

    useEffect(() => {
        if (actionsRender) {
            setListValues([...list?.map(item => ({ ...item, actionsRender: actionsRender?.(item) })) ?? []])
        }
    }, [list, actionsRender]);

    useEffect(() => {
        if (list) {
            setListValues(list ?? [])
        }
    }, [list])

    //DND
    const reorder = useCallback((list: T[], startIndex: number, endIndex: number) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }, []);

    const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newList = reorder(
            list ?? [],
            result.source.index,
            result.destination.index
        );
        setListValues(newList);
        onChangeDnd?.(newList);

    }, [list, onChangeDnd, reorder])

    //SORT
    const toggleSort = (item: ISort) => ({ ...item, direction: item.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC, ascending: !item.ascending })

    const onClickSortHandler = useCallback((property: string) => {
        const sortProperty = currentSortProperties.find(x => x.property === property);
        if (sortProperty) {
            const newSortProperties = currentSortProperties.map(x => x.property === property ? toggleSort(x) : x)
            setCurrentSortProperties(newSortProperties)
            const sortItem = newSortProperties.find(x => x.property === property);
            if (sortItem) {
                onClickSort?.(sortItem, newSortProperties)
            }
        } else {
            const newSortProperties = [...currentSortProperties, { property, direction: SortDirection.DESC, ascending: false }]
            setCurrentSortProperties(newSortProperties)
            const sortItem = newSortProperties.find(x => x.property === property);
            if (sortItem) {
                onClickSort?.(sortItem, newSortProperties)
            }
        }
        const sortItem = currentSortProperties.find(x => x.property === property);
        if (sortItem) {
            onClickSort?.(sortItem, currentSortProperties)
        }
    }, [currentSortProperties, onClickSort])



    return (loading ?
        <SkeletonList />
        :
        !list?.length ?
            <div style={{ opacity: 0.5, justifyContent: 'center', alignItems: 'center', display: 'flex', padding: 32 }}>Nenhum item encontrado <Icon>alert</Icon></div>
            :
            <div id={styles.List} style={{ minWidth: minWidth, background: sub ? '#0001' : 'unset' }}>
                {!hideHeader && <ListHeader
                    enableDnd={enableDnd}
                    listValues={listValues}
                    columnsConfigs={columnsConfigs}
                    onClickSortHandler={onClickSortHandler}
                    actionButtons={actionButtons}
                    renderAccordion={!!renderAccordion}
                />}
                {
                    enableDnd ?
                        <ListDnD
                            onDragEnd={onDragEnd}
                            listValues={listValues}
                            renderAccordion={renderAccordion}
                            enableDnd={enableDnd}
                            columnsConfigs={columnsConfigs}
                            actionButtons={actionButtons}
                            menuButtons={menuButtons}
                            showAccordion={showAccordion}
                            sub={sub}
                            subLevel={subLevel}
                        />
                        :
                        <FixedList
                            listValues={listValues}
                            renderItem={renderItem}
                            renderAccordion={renderAccordion}
                            columnsConfigs={columnsConfigs}
                            actionButtons={actionButtons}
                            menuButtons={menuButtons}
                            showAccordion={showAccordion}
                            sub={sub}
                            subLevel={subLevel}
                        />
                }
                {total && 
                <div className={styles.itemTotal}>
                    <span className={styles.total}>Total</span>
                    <span className={styles.value}>{valueTotal}</span>
                    </div>
                    }
                {
                    pagination && !(hidePagintion && pagination.count <= 1) &&
                    <ListPagination pagination={pagination} onChangePage={onChangePage} />
                }
            </div >
    )
}

export default List




