import { IAddPromoterRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IAccessService } from '../../interfaces/IAccessService';

const AddPromoterToListUseCase = async (
  accessService: IAccessService,
  promoter: IAddPromoterRequest,
  localId: string,
): Promise<void> => {
  return await accessService.addPromoterToList(promoter, localId);
};

export default AddPromoterToListUseCase;
