import { IUseCaseParams } from "../domain/interfaces/IUseCaseParams";
import { IRequestParams } from "../domain/interfaces/RequestParams";

export const makeFilterRequest = ({
    filter,
    page,
    pageSize,
    sortField,
    sortOrientation,
    exportFormat,
}: IUseCaseParams) => {
    const filterRequest: IRequestParams = {
        cashierIds: filter.cashierIds.map(x => x.id),
        page,
        pageSize,
        exportFormat,
        shouldUseNewSchema: filter?.shouldUseNewSchema,
        ownerId: filter?.ownerId,
    }

    if (sortField) {
        filterRequest.sort = {
            type: sortField,
            orientation: sortOrientation || 'desc'
        }
    }

    return filterRequest;
}
