import { useCallback, useState } from "react";
import { IGetRolesResponse } from "modules/cargos/models/dtos/IGetRolesResponse";
import GetRolesListUseCase from "modules/cargos/application/useCases/getRolesListUseCase";
import CargosApi from "services/api/cargos/CargosApi";
import GetAlloedRolesUseCase from "modules/cargos/application/useCases/getAllowedRolesUseCase";
import { IGetAllowedRolesResponse } from "modules/cargos/models/dtos/IGetAllowedRolesResponse";
import PostNewRoleUseCase from "modules/cargos/application/useCases/postNewRoleUseCase";
import { IPostNewRoleRequest } from "modules/cargos/models/dtos/IPostNewRoleRequest";
import PutEditRoleUseCase from "modules/cargos/application/useCases/putEditRoleUseCase copy";
import { IPutEditRoleRequest } from "modules/cargos/models/dtos/IPutEditRoleRequest";
import GetRegisteredRolesListUseCase from "modules/cargos/application/useCases/getRegisteredRolesListUseCase";
import { IGetRegisteredRolesListParams } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams";
import { IProfiles } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteRoleUseCase from "modules/cargos/application/useCases/deleteRoleUseCase";
import GetAllRolesUseCase from "modules/cargos/application/useCases/getAllRolesUseCase";
import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";

const rolesApi = CargosApi();

const UseRolesList = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [rolesList, setRolesList] = useState<IGetRolesResponse[]>();
  const [allowedRoles, setAllowedRolesList] =
    useState<IGetAllowedRolesResponse>();
  const [registeredRolesList, setRegisteredRolesList] = 
    useState<IProfiles[]>();
    const [registeredRolesListSelect, setRegisteredRolesListSelect] = 
    useState<IProfiles[]>();
  const [allRolesList, setAllRolesList] =
    useState<IGetAllRolesResponse[]>();

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageRoles, setCurrentPageRoles] = useState(1);
  const [totalRegistersRoles, setTotalRegistersRoles] = useState(0);

  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getRolesList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await GetRolesListUseCase(rolesApi, currentLocal?.systemIdentifier ?? 0);
      setRolesList(response);
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal?.systemIdentifier]);

  const getAllowedRolesList = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const response = await GetAlloedRolesUseCase(rolesApi, id);
        setAllowedRolesList(response);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const getRegisteredRolesList = useCallback(
    async (params: IGetRegisteredRolesListParams, page: number, pageSize?: number) => {
      try {
        setIsLoading(true);
        const response = await GetRegisteredRolesListUseCase(rolesApi, page, pageSize, params);

        if (pageSize && pageSize >= 500) {
          return setRegisteredRolesListSelect(response.Profiles);
        }

        setRegisteredRolesList(response.Profiles);
        setTotalPages(response.PageCount);
        setCurrentPage(page);

        return response
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const postNewRole = useCallback(
    async (request: IPostNewRoleRequest) => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          await PostNewRoleUseCase(rolesApi, request);
          return "ok";
        } catch (error: any) {
          toast(error?.response?.data.message.includes("Já existe um profile com este nome") 
            ? "Cargo já cadastrado." 
            : error?.response?.data,
          "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [toast, currentLocal]
  );

  const putEditRole = useCallback(
    async (request: IPutEditRoleRequest) => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          await PutEditRoleUseCase(rolesApi, request);
          return "ok";
        } catch (error: any) {
          toast(error?.response?.data, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [toast, currentLocal]
  );

  const deleteRole = useCallback(
    async (profileId: string) => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          await DeleteRoleUseCase(rolesApi, profileId);
          return "ok";
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentLocal]
  );

  const getAllRolesList = useCallback(async (page?: number, pageSize?: number) => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetAllRolesUseCase(rolesApi, currentLocal.id, page, pageSize);
        setAllRolesList(response);
        setTotalRegistersRoles(response.length);
        setCurrentPageRoles(page ?? 1);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal]);

  const nextPage = (params: IGetRegisteredRolesListParams, page: number, pageSize?: number) => {
    if (currentPage <= totalPages) {
      getRegisteredRolesList(params, page, pageSize);
    }
  }

  return {
    rolesList,
    isLoading,
    getRolesList,
    allowedRoles,
    totalPages,
    currentPage,
    getAllowedRolesList,
    nextPage,
    registeredRolesList,
    getRegisteredRolesList,
    registeredRolesListSelect,
    postNewRole,
    putEditRole,
    deleteRole,
    allRolesList,
    currentPageRoles,
    totalRegistersRoles,
    getAllRolesList,
    setCurrentPage,
  };
};

export default UseRolesList;
