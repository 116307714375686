import { IPerfilBody, IPostPerfilService } from "./IPerfilService";

const PostPerfilVinculo = async (
  accountService: IPostPerfilService,
  id: string,
  body: IPerfilBody
): Promise<any> => {
  return await accountService.postPerfilVinculo(id, body);
};

export default PostPerfilVinculo;