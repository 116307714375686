import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { IPostIFoodMarketConfigRequest } from "modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigRequest";
import PostIFoodMarketConfigUseCase from "modules/config/integrationsConfig/application/useCases/PostIFoodMarketConfigUseCase";
import GetIFoodMarketConfigUseCase from "modules/config/integrationsConfig/application/useCases/GetIFoodMarketConfigUseCase";
import { IPostIFoodMarketConfigResponse } from "modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigResponse";

const service = IntegrationApi();

export const UseConfigIFoodMarket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getIFoodMarketStatus, setGetIFoodMarketStatus] = useState<IPostIFoodMarketConfigResponse>({} as IPostIFoodMarketConfigResponse);
  const [values, setValues] = useState<IPostIFoodMarketConfigRequest>({} as IPostIFoodMarketConfigRequest);
  const [hasWrongCredentials, setHasWrongCredentials] = useState(false);
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getStatusIfoodMarketConfiguration = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetIFoodMarketConfigUseCase(
          service,
          currentLocal?.id
        );
        setGetIFoodMarketStatus(response);
        if (response.isActive) {
          setValues({
            ...values,
            id: response.id,
            username: response.username,
            password: response.password,
            isActive: response.isActive,
          });
          setHasWrongCredentials(response.hasWrongCredentials);
        } else {
          setValues({} as IPostIFoodMarketConfigRequest);
        }
        return "ok";
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal]);

  const postIFoodMarketConfiguration = useCallback(async (request: IPostIFoodMarketConfigRequest) => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await PostIFoodMarketConfigUseCase(service, {...request, id: currentLocal.id});
        setValues(response);
        return "ok";
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, toast]);

  return {
    isLoading,
    getIFoodMarketStatus,
    values,
    hasWrongCredentials,
    setValues,
    getStatusIfoodMarketConfiguration,
    postIFoodMarketConfiguration
  };
};
