
import { IDetailsFormErrors } from "./IGuestListFormErros";

const MAX_CHARACTERS_PER_WORD = 25;

export const validate = (formData: IDetailsFormErrors, setErrors: React.Dispatch<React.SetStateAction<IDetailsFormErrors>>) => {
  let _errors: IDetailsFormErrors = {};
  let hasError = false;
  if (!formData.name) {
    _errors = { ..._errors, name: "Insira um nome para a lista" };
    hasError = true;
  }else{
    var nameSplit = formData.name.split(" ");
    for(const element of nameSplit){
      if(element.length > MAX_CHARACTERS_PER_WORD){
        _errors = { ..._errors, name: "Campo não deve conter palavras com mais de "+MAX_CHARACTERS_PER_WORD+" caracteres" };
        hasError = true;
      }
    }
  }
  if (!formData.description) {
    _errors = { ..._errors, description: "Insira uma descrição para a lista" };
    hasError = true;
  }else{
    var descriptionSplit = formData.description.split(" ");
    for(const element of descriptionSplit){
      if(element.length > MAX_CHARACTERS_PER_WORD){
        _errors = { ..._errors, description: "Campo não deve conter palavras com mais de "+MAX_CHARACTERS_PER_WORD+" caracteres" };
        hasError = true;
      }
    }
  }
  if (!formData.closingDateTime) {
    _errors = { ..._errors, closingDateTime: "Insira a data de fechamento da lista" };
    hasError = true;
  }


  setErrors(_errors);
  return !hasError;
};