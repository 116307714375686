import { IProductsService } from '../../../domain/interfaces/IProductsService';

const DeleteProductUseCase = async (
  productsService: IProductsService,
  productId: string,
): Promise<void> => {
  return await productsService.deleteProduct(productId);
};

export default DeleteProductUseCase;
