import { IAddressSaas } from "../address/IAddressSaas"

export interface IProspect {
  id: string,
  meepUserId: string,
  email: string,
  name: string,
  document: string,
  birthday: Date,
  originUrl: string,
  type: number,
  phoneNumber: string,
  status?: ProspectStatus,
  establishment: {
    name: string,
    document: string,
    segment: number
    address: IAddressSaas
  }
}


export enum ProspectStatus{
  Pending = 0,
  Trial = 1,
  Plan = 2
}