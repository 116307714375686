import { MenuItem, Pagination, Skeleton, TextField } from "@mui/material";
import UsePosSolicitationsPage from "./hooks/UsePosSolicitationsPage";
import styles from "./PosSolicitationsPage.module.scss";
import { useCallback, useEffect, useState } from "react";
import SolicitationsFilter from "./components/solicitationsFilter/SolicitationsFilter";
import SolicitationsHeader from "./components/solicitationsHeader/SolicitationsHeader";
import SolicitationItem from "./components/solicitationItem/SolicitationItem";
import UseDimension from "components/dimension/UseDimension";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const PosSolicitationsPage = () => {
    const {
        isLoading,
        isFetching,
        data,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages,
        filterParams,
        setFilterParams,
        refetch,
        putImportSolicitation,
        putApproveSolicitation,
        isLoadingApproval
    } = UsePosSolicitationsPage();
    const { dimensions } = UseDimension();
    const { toast } = useUi();

    const [openImportSidesheet, setOpenImportSidesheet] = useState("");
    const [openApproveSidesheet, setOpenApproveSidesheet] = useState("");

    useEffect(() => {
        setCurrentPage(1);
        refetch();
    }, [refetch, filterParams.column, filterParams.orientation, pageSize]);

    const handleImport = useCallback(async () => {
        const res = await putImportSolicitation(openImportSidesheet);
        if (res === "success") {
            setCurrentPage(1);
            refetch();
            setOpenImportSidesheet("");
            toast("Máquina importada com sucesso!", "success");
        }
    }, [openImportSidesheet, putImportSolicitation, refetch, setCurrentPage, toast]);

    const handleApprove = useCallback(async () => {
        const res = await putApproveSolicitation(openApproveSidesheet);
        if (res === "success") {
            setCurrentPage(1);
            refetch();
            setOpenApproveSidesheet("");
            toast("Máquina liberada com sucesso!", "success");
        }
    }, [openApproveSidesheet, putApproveSolicitation, refetch, setCurrentPage, toast]);

    const skeletons = Array.from({ length: 4 }, (_, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: '40px', marginBottom: "10px" }} />
    ));

    return (
        <div id={styles.PosSolicitationsPage}>
            <h1>Liberação de máquina</h1>
            <p>Esses são os estabelecimentos e as máquinas pendentes de liberação</p>

            <SolicitationsFilter filter={filterParams} setFilter={setFilterParams} refetch={refetch}/>

            {!isLoading && !isFetching && !data?.length && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    <img src="/assets/img/empty-box.png" alt="" />
                    <span><b>Não há dados</b> para exibição.</span>
                </div>
            )}

            {isLoading || isFetching
                ? (<div style={{ marginTop: "1rem" }}>
                    {skeletons}
                    </div>)
                : !!data?.length && (
                    <div>
                        {dimensions.width > 670 && <SolicitationsHeader filter={filterParams} setFilter={setFilterParams} />}                        
                        {data?.map((solicitation) => (
                            <SolicitationItem
                                key={solicitation.id}
                                item={solicitation}
                                setOpenImportSidesheet={setOpenImportSidesheet}
                                setOpenApproveSidesheet={setOpenApproveSidesheet}
                            />
                        ))}                    
                    </div>
            )}
            
            {!!totalPages && (
                <div className={styles.paginationContainer}>
                    <div className={styles.multiplePages}>
                        <span>Exibir</span>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={pageSize}
                            defaultValue={pageSize}
                            onChange={(ev) => setPageSize(Number(ev.target.value))}
                            disabled={isLoading || isFetching}
                        >
                            {[10, 20, 50].map((item) => (
                                <MenuItem key={item} className={styles.textPage} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            ".MuiPaginationItem-root": {
                                borderColor: "#BDBDBD",
                                color: "#001537",
                            },
                            ".Mui-selected": {
                                fontWeight: "bold",
                                borderColor: "#001537",
                            },
                        }}
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, value) => {
                            setCurrentPage(value);
                            refetch();
                        }}
                    />
                </div>
            )}

            <Sidesheet
                isLoading={isLoadingApproval}
                open={openImportSidesheet || openApproveSidesheet}
                onClose={() => {
                    setOpenImportSidesheet("");
                    setOpenApproveSidesheet("");
                }}
                title={<h1>{openImportSidesheet ? "Importar" : "Liberar"} <b>máquina</b></h1>}
                content={
                    <SidesheetFeedback text={`Deseja realmente ${openImportSidesheet ? "importar" : "liberar"} a máquina?`} />
                }
                continueButton="Continuar"
                handleContinueButton={openImportSidesheet ? handleImport : handleApprove}
                cancelButton
                customizedCancelButton="Voltar"
            />
        </div>
    );
}

export default PosSolicitationsPage;
