import { IUpdateEquipamentosBody, IUpdateEquipamentosService } from "./IPerfilService";

const UpdateEquipamentos = async (
  accountService: IUpdateEquipamentosService,
  id: string,
  body: IUpdateEquipamentosBody
): Promise<any> => {
  return await accountService.updateEquipamentos(id, body);
};

export default UpdateEquipamentos;