import { ITopProducts } from "modules/cashlessDashboard/presentation/components/topProducts/interfaces/ITopProducts";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetTopProductsUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<ITopProducts> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getTopProducts(filterRequest);

  response.records = response.records.map(x => ({ ...x, total: x.total || x.price * x.quantity}))

  return response;
}

export default GetTopProductsUseCase
