import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetSessionsUseCase from "../../application/useCases/GetSessionsUseCase";
import { IGetSessionsList } from "../interfaces/IGetSessionsList";



const UseSession = () => {

  const [isSessionLoading, setSessionIsLoading] = useState<boolean>();
  const [sessionItem, setSessionItem] = useState<IGetSessionsList>();
  const { currentLocal } = useLocal();

  const getSessionItem = useCallback(
    async () => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setSessionIsLoading(true);
        if (currentLocal) {
          const response = await GetSessionsUseCase(serviceDiscountCoupons,
            currentLocal.id,
          );

          setSessionItem(response);
          return response
        }
      } finally {
        setSessionIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    sessionItem,
    isSessionLoading,
    getSessionItem,
    setSessionItem,
  };
};

export default UseSession;
