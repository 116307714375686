
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IAddChild } from "../interfaces/IAddChild";
import { AddChildUseCase } from "modules/permutation/application/useCases/AddChildUseCase";

const UseAddChild = () => {

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successAddChild, setSuccessAddChild] = useState(false)
  

  const postAddPermutation = useCallback(
    async (accountId: string, values: IAddChild) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingAdd(true);
        if (currentLocal) {
          const response = await AddChildUseCase(servicePermutation,
            {...values},
            currentLocal.id,
            accountId
            );
            toast("Criado com sucesso", "success")        
            setSuccessAddChild(true)
          return response
        }
      }  catch {
        setSuccessAddChild(false)
      }
      
      finally {
        setIsLoadingAdd(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessAddChild,
    successAddChild,
    isLoadingAdd,
    postAddPermutation,   

  };
};

export default UseAddChild;
