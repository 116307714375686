import { IPaginatedList } from 'models/api/IApi';
import { IDiscountListResponse, IGetListDiscountsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const GetEventDiscountBaseLIstUseCase = async (
  baseListService: IDiscountBaseList,
  localId: string,
  eventId: string,
  page?: number,
  pageSize?: number,
): Promise<IPaginatedList<IDiscountListResponse>> => {
  return await baseListService.getEventDiscountBaseList(localId, eventId, page, pageSize);
};

export default GetEventDiscountBaseLIstUseCase;
