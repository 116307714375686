import { FC, useEffect } from 'react'
import styles from './AverageTicket.module.scss'
import { IAverageTicket } from './interfaces/IAverageTicket';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '../skeleton/Skeleton';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';

export interface IAverageTicketProps {
    getData: () => Promise<void>;
    loading: boolean;
    data?: IAverageTicket
    onGeneratingPDF?: boolean;
    triggerOperator: boolean;
    expiresAt: string | undefined;
    triggerCustomer: boolean;
    triggerDevices: boolean;
    triggerProducts: boolean;
    triggerCategories: boolean
}

const AverageTicket: FC<IAverageTicketProps> = ({ getData, triggerCategories, triggerProducts, loading, data, onGeneratingPDF, triggerOperator,
    expiresAt, triggerCustomer, triggerDevices }) => {
    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        if (data && expiresAt && new Date() > new Date(expiresAt))
            getData();
    }, [triggerCategories, triggerOperator, triggerCustomer, triggerDevices, triggerProducts]);

    if (loading) {
        return (
            <div id={styles.AverageTicket}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id={styles.AverageTicket}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.recharge.average)}</span>
                        <div>
                            <span>Valor médio de recarga</span>
                            {!onGeneratingPDF && <Tooltip title="Valor médio de recarga" text="Ticket médio da recarga no período. O ticket médio por sexo pode ser maior ou menor do geral pois o número de pessoas que é levado em conta é diferente." />}
                        </div>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Masculino:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.recharge.male)}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Feminino:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.recharge.female)}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Não informado:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.recharge.unknown)}</span>
                    </div>
                </div>

                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.consumption.average)}</span>
                        <div>
                            <span>Valor médio de consumo cashless</span>
                            {!onGeneratingPDF && <Tooltip title="Valor médio de consumo cashless" text="Ticket médio do consumo cashless no período. O ticket médio por sexo pode ser maior ou menor do geral pois o número de pessoas que é levado em conta é diferente." />}
                        </div>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Masculino:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.consumption.male)}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Feminino:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.consumption.female)}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Não informado:</span>
                        <span className={styles.value}>{moneyMaskNumber(data?.consumption.unknown)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AverageTicket



