import React, { FC, useEffect } from "react";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigCrmPush } from "./UseConfigCrmPush";
import { PushDetails } from "modules/addons/presentation/components/pushDetails/PushDetails";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

export interface IConfigCrmPushProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  showConnect?: boolean;
}

const ConfigCrmPush: FC<IConfigCrmPushProps> = ({
  open,
  setOpen,
  step,
  setStep,
  showConnect
}) => {
  const {
    getSignature,
    isLoadingSignature,
    signature,
    getPlans,
  } = UseConfigCrmPush();

  useEffect(() => {
    if (step === 1) {
      getPlans();
    }
  }, [getPlans, step]);

  useEffect(() => {
    getSignature();
  }, [getSignature]);

  return (
    <>
      <ContentBox
        loading={isLoadingSignature}
        status={signature?.isActive ? "enabled" : "disabled"}
        type={IntegrationTypeEnum.PUSH}
        premium={true}
        text="Ferramenta de envio de SMS para seus clientes, diretamente do CRM Meep"
        setOpen={setOpen}
        featureType={FeatureTypeEnum.PUSH}
        showConnect={showConnect}
      />
        <PushDetails open={open === IntegrationTypeEnum.PUSH} onClose={() => setOpen(null)} />
    </>
  );
};
export default ConfigCrmPush;
