import { IconButton } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import styles from "./ItemCard.module.scss";
import { ICategoriesItem, ICategoryProducts } from "../interfaces/ICategory";

interface IItemCardProps {
  item: ICategoriesItem;
  getSubItems: (categoryId: string) => Promise<ICategoryProducts>
}

export const ItemCard: FC<IItemCardProps> = ({
  item,
  getSubItems,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subItems, setSubItems] = useState<ICategoryProducts>({ records: [], totalRecords: 0 });

  const itemRef = React.createRef<HTMLDivElement>();

  const handleClick = useCallback(async (categoryId: string) => {
    if (showContent) {
      setShowContent(false);
    } else {
      setShowContent(true);
      setLoading(true);
      const response = await getSubItems(categoryId);
      setSubItems(response);
      setLoading(false);
    }
  },
    [showContent, getSubItems]
  );

  return (
    <div id={styles.ItemCard} ref={itemRef}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <p>{item.name}</p>
          <div className={styles.total}>
            <p>{moneyMaskNumber(item.total)}</p>
            {!showContent ? <KeyboardArrowDown onClick={() => handleClick(item.id)} /> : <KeyboardArrowUp onClick={() => handleClick(item.id)} />}
          </div>
        </div>
        {(showContent || loading) && (
          <div className={styles.content}>
            {
              loading ?
                <div className={styles.loading}>
                  <img src="/assets/img/loader.gif" alt="" />
                </div>
                :
                subItems?.records?.map((subItem, key) => (
                  <div key={key}>
                    <div>{subItem.quantity}</div>
                    <div>
                      <div>{subItem.name}</div>
                      <div>{moneyMaskNumber(subItem.total)}</div>
                    </div>
                  </div>
                ))

            }
          </div>
        )}
      </div>
    </div>
  );
};
