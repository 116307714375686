import { Skeleton } from "@material-ui/lab";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback, useEffect, useState } from "react";
import ContentBox from "../../components/_configCampaign/_contentBox/ContentBox";
import CrmConfigTermsStep from "../../components/_configCampaign/_crmConfigTermsStep/CrmConfigTermsStep";
import DisconnectedStep from "../../components/_configCampaign/_disconnectedStep/DisconnectedStep";
import WhatsAppConfiguredAndConnectedStep from "../../components/_configCampaign/_whatsAppConfiguredAndConnectedStep/WhatsAppConfiguredAndConnectedStep";
import UseWhatsappConfiguration from "../../hook/UseWhatsappConfiguration";

// export interface DataItens {
//   status: boolean;
//   email: string;
//   value: number;
//   type: "Email" | "WhatsApp";
//   step: number;
// }


enum CrmConfigWhatsAppStep {
  notConfigured = 0,
  thermAccepted = 1,
  qrCode = 2,
  configuredAndConnected = 3
}



const ConfigCrmWhatsapp = () => {

  const [whatWhatsappStep, setStep] = useState<CrmConfigWhatsAppStep>(0);

  const {
    whatsappStatus,
    isLoadingWhatsappStatus,
    getWhatsappStatus,
    getConfiguration,
    whatsappConfiguration,
    isLoadingConfiguration,
    whatsAppQrCode,
    isLoadingQrCode,
    getQrCode } = UseWhatsappConfiguration()

  const onHandleWhatsappContract = () => {

  };

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration])


  useEffect(() => {
    if (whatsappConfiguration?.isActive && whatsappStatus === false) {
      // if (whatsappConfiguration?.isActive) {
      setStep(CrmConfigWhatsAppStep.qrCode)
      getQrCode();
    }
    if (whatsappConfiguration?.isActive && whatsappStatus === true) {
      setStep(CrmConfigWhatsAppStep.configuredAndConnected)
    }
    if (!whatsappConfiguration?.isActive && whatsappStatus === false) {
      setStep(CrmConfigWhatsAppStep.notConfigured)
    }
  }, [getQrCode, whatsappConfiguration?.isActive, whatsappStatus])


  const reload = useCallback(async () => {
    await getQrCode();
  }, [getQrCode])


  return (
    <div>
      <ContentBox status={!!whatsappStatus} type={"Whatsapp"} >
        {
          isLoadingConfiguration || isLoadingWhatsappStatus || isLoadingQrCode ? 
            <Skeleton variant="rect" height={200} />
            :
            whatWhatsappStep === CrmConfigWhatsAppStep.notConfigured ? (
              <CrmConfigTermsStep onHandleContract={onHandleWhatsappContract} />
            ) : whatWhatsappStep === CrmConfigWhatsAppStep.thermAccepted ? (
              <DisconnectedStep />
            ) : whatWhatsappStep === CrmConfigWhatsAppStep.qrCode ? (
              <DisconnectedStep getQrCode={reload} qrCode={whatsAppQrCode} />
            ) : (
              <WhatsAppConfiguredAndConnectedStep status={false} />
            )
        }
      </ContentBox>
    </div>
  );
};

export default ConfigCrmWhatsapp;
