import { Drawer, Icon, TextField } from '@material-ui/core';
import styles from './TrackConfigModal.module.scss';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ITrackConfigForm } from 'modules/saas/presentation/interfaces/trackConfig/ITrackConfigForm';
import { ITrackConfigItem } from 'modules/saas/presentation/interfaces/trackConfig/ITrackConfig';
import { format } from 'date-fns';
import { MenuItem, Select } from '@mui/material';
import { TrackConfigStatusEnum } from 'modules/saas/domain/interfaces/TrackConfigStatusEnum';

interface PlanHistoryModalProps {
    open: boolean;
    form: ITrackConfigForm;
    trackConfig: ITrackConfigItem;
    setForm: Dispatch<SetStateAction<ITrackConfigForm>>;
    onClose: () => void;
    onSubmit: () => Promise<void>;
}

interface ErrorType {
    deviceNumber: string;
    trackingCode: string;
}

export const TrackConfigModal = ({ open, form, trackConfig, setForm, onClose, onSubmit }: PlanHistoryModalProps) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({} as ErrorType);

    const handleSubmit = async () => {
        setLoading(true);
        let hasError = false;

        if (!form.trackingCode.trim()) {
            hasError = true;
            setErrors(prev => ({ ...prev, trackingCode: 'Campo obrigatório.' }))
        }

        if (!hasError) await onSubmit();

        setLoading(false);
    }

    useEffect(() => {
        setForm(prev => ({ ...prev, status: trackConfig.status }))
    }, [setForm, trackConfig.status])

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Informação <b>maquininha</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    <div className={styles.addressContainer}>
                        <span>Endereço de entrega:</span>
                        <p>{`${trackConfig.address.street} ${trackConfig.address.number} - ${trackConfig.address.city}/${trackConfig.address.state} ${trackConfig.address.zipCode}`}</p>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label>Local</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={trackConfig.localName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label>Data de solicitação</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={format(new Date(trackConfig.solicitationDate), 'dd/MM/yyyy')}
                                disabled
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Número da maquininha</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={form.deviceNumber}
                                onChange={(event) => setForm(prev => ({ ...prev, deviceNumber: event.target.value }))}
                                helperText={errors.deviceNumber}
                                error={!!errors.deviceNumber}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Código ou link de rastreio</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={form.trackingCode}
                                onChange={(event) => setForm(prev => ({ ...prev, trackingCode: event.target.value }))}
                                helperText={errors.trackingCode}
                                error={!!errors.trackingCode}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Código ou link de rastreio</label>
                            <Select
                                value={form.status}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(ev) => setForm(prev => ({ ...prev, status: Number(ev.target.value) }))}
                            >
                                <MenuItem value={TrackConfigStatusEnum.PREPARING}>
                                    Em preparação
                                </MenuItem>
                                <MenuItem value={TrackConfigStatusEnum.SENT}>
                                    Enviado
                                </MenuItem>
                                <MenuItem value={TrackConfigStatusEnum.DELIVERED}>
                                    Entregue
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Cancelar</button>
                    <button onClick={handleSubmit} disabled={loading}>
                        {
                            loading ? 'Salvando...' : 'Salvar'
                        }
                    </button>
                </div>
            </div>
        </Drawer>
    )
}