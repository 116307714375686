import { IconButton } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import React, { FC } from 'react'
import styles from './BoxQuantity.module.scss'
export interface IProductCompositionItemProps {
    onChange: (sub?: boolean) => void
    values?: number;
}
const BoxQuantity: FC<IProductCompositionItemProps> = ({ onChange, values }) => {

    return (
        <div id={styles.BoxQuantity} >
            {
                <div className={styles.container} >

                    <IconButton
                        className={styles.buttonIcons}
                        onClick={() => onChange(true)}
                    >
                        <RemoveCircleOutline />
                    </IconButton>
                    <div className={styles.totalsItem}>
                        {values || 1}
                    </div>
                    <IconButton

                        className={styles.buttonIcons}
                        onClick={() => onChange()}
                    >
                        <AddCircleOutline />
                    </IconButton>

                </div>
            }

        </div >
    )
}
export default BoxQuantity