import React, { FC } from 'react'
import styles from './ProductFormSkeleton.module.scss'
import { Skeleton } from '@mui/material'

const ProductFormSkeleton: FC = () => {

    const input = <div className={styles.container} >
        <Skeleton variant={'text'} width={'40%'} />
        <Skeleton variant={'rectangular'} width={'100%'} height={40} />
    </div>
    const detail = <div className={styles.detail} >
        <div className={styles.image} >
            <Skeleton variant={'rectangular'} width={200} height={200} />
        </div>
        <div className={styles.data} >
            {input}
            {input}
            {input}
        </div>
    </div>
    return (
        <div id={styles.ProductFormSkeleton} >
            {input}
            {input}
            {detail}
            {input}
            <Skeleton variant={'rectangular'} width={'100%'} height={400} />
        </div>
    )
}
export default ProductFormSkeleton