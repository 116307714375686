export interface PatchUpdateTerminalConfigRequest {
  profileId?: string;
  profileName?: string;
  terminal?: {
      tableMode: TerminalTableMode;
      operatorMode: TerminalOperatorMode;
  };
  printer?: {
    receiptPrinterSectorId?: string;
  };
  productListId?: string;
  operationMethods: OperationMethods[];
}

export enum TerminalTableMode {
  TABLE = 1,
  INPUT = 2
}

export enum TerminalOperatorMode {
  ONE = 1,
  MANNY = 2
}

export enum OperationMethods {
  TICKET = 1,
  CASHLESS = 2,
  ORDERPAID = 4
}
