import { IDiscountListResponse } from "modules/events/domain/dto/IEventsResponse";
import { IDiscountListForm } from "../IDiscountListForm";
import { IDiscountListFormErrors } from "./IDiscountListFormErrors";

export const validate = (formData: IDiscountListForm, discountsBaseList: IDiscountListResponse[], setErrors: React.Dispatch<React.SetStateAction<IDiscountListFormErrors>>) => {
  let _errors = {} as IDiscountListFormErrors;
  let hasError = false;
  if (!formData.name) {
    _errors = { ..._errors, name: "Insira um nome para a lista" };
    hasError = true;
  }else if(discountsBaseList.find(el => (el.name == formData.name))){
    _errors = { ..._errors, name: "Já existe uma lista com este nome" };
    hasError = true;
  }
  if (!formData.description) {
    _errors = { ..._errors, description: "Insira uma descrição para a lista" };
    hasError = true;
  }
  setErrors(_errors);
  return !hasError;
}