import { FC, useCallback, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { GraphLoading } from "components/ui/graphLoading/GraphLoading";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styles from "./AverageTicketBarByTimeChart.module.scss";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { TypeOfConsumption } from "./TypeOfConsumptionEnum";
import { IAverageTicketConsumeData } from "../averageTicketConsume/AverageTicketConsume";

export interface IAveragetTicketPerHourFilter {
  type: TypeOfConsumption
}

export interface IAverageTicketBarChartProps {
  //propertys
  data?: IAverageTicketConsumeData;
  isLoading?: boolean;
  getData?: (filter: IAveragetTicketPerHourFilter) => void;
}

const AverageTicketBarByTimeChart: FC<IAverageTicketBarChartProps> = ({
  data,
  isLoading,
  getData
}) => {
  const [type, setVariant] = useState<keyof IAverageTicketConsumeData>("products");

  const onChangeVariant = useCallback((type: keyof IAverageTicketConsumeData) => {
    setVariant(type);
  }, []);

   return (
    <div id={styles.BarChart}>
      {isLoading ?
        <div className={styles.loading}>
          <GraphLoading />
        </div> :
        <>
          <div className={styles.filter}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={"products"}
              name="radio-buttons-group"
              row
              value={type}
              onChange={(_, value) => onChangeVariant(value as keyof IAverageTicketConsumeData)}
            >
              <FormControlLabel checked={type === "products"} label="Consumo" control={<Radio />} value="products" />
              <FormControlLabel checked={type === "tickets"} label="Ingressos" control={<Radio />} value="tickets" />
            </RadioGroup>
          </div>
          <div className={styles.chart}>
            {(!data?.[type].averageConsumptionPerHour.length) ? (
              <div id={styles.BarChart}>
                <ErrorMessage type="warning" />
              </div>
            ) : (
              data && (
                <ResponsiveContainer width={"100%"} height={300}>
                  <ComposedChart
                    data={data[type].averageConsumptionPerHour.map((item) => {
                      const totalFemale = item.female > 0 ? 1 : 0;
                      const totalMale = item.male > 0 ? 1 : 0;
                      const totalUnknown = item.unknown > 0 ? 1 : 0;
                      const totalItems = totalFemale + totalMale + totalUnknown;

                      return ({
                        Hora: new Date(`${item.time}Z`).toLocaleTimeString([], {
                          timeStyle: "short",
                          hour12: false
                        }),
                        Feminino: item.female.toFixed(2),
                        Masculino: item.male.toFixed(2),
                        Desconhecido: item.unknown.toFixed(2),
                        Média: Math.floor((item.female + item.male + item.unknown) / (totalItems == 0 ? 1 : totalItems)),
                      });
                    })}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis
                      dataKey="Hora"
                      label={{
                        value: "Horário",
                        position: "insideBottomRight",
                        dy: 10,
                        fill: "var(--text)",
                      }}
                    />
                    <YAxis
                      label={{
                        value: "Reais (R$)",
                        position: "insideTopLeft",
                        dy: -40,
                        dx: -15,
                        fill: "var(--text)",
                      }}
                    />
                    <Tooltip />
                    <Legend
                      pointsAtX={150}
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{ paddingBottom: 15 }}
                    />
                    <Bar dataKey="Masculino" stackId="a" fill="#4D13B7" />
                    <Bar dataKey="Feminino" stackId="b" fill="#955CFF" />
                    <Bar dataKey="Desconhecido" name="Não informado" stackId="c" fill="#A2A2A2" />
                    <Line type="monotone" dataKey="Média" stroke="#0006" />
                  </ComposedChart>
                </ResponsiveContainer>
              )
            )}
          </div>
        </>}
    </div>
  );
};
export default AverageTicketBarByTimeChart;
