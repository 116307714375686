import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { format } from 'date-fns/esm';
import { DeleteUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/DeleteUnavailabilityByServiceIdUseCase';
import { GetAllDemandedServicesByPlaceId } from 'modules/schedule/application/useCases/placeSchedule/GetAllDemandedServicesByPlaceIdUseCase';
import { GetUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetUnavailabilityByServiceIdUseCase';
import { PostAddUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostAddUnavailabilityByServiceIdUseCase';
import { PutEditUnavailabilityByServiceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PutEditUnavailabilityByServiceIdUseCase';
import { IAddSpecialDays, ISpecialDays } from 'modules/schedule/domain/models/ISpecialDays';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import PlacesScheduleApi from 'services/api/schedule/places/PlacesScheduleApi';
import { v4 } from 'uuid';

const UseAddSpecialDaysForm = (handleSpecialDays?: (values: IAddSpecialDays[]) => void) => {


    const [values, setValues] = useState<ISpecialDays[]>([]);
    const [specialDays, setSpecialDays] = useState<ISpecialDays[]>([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedStart, setSelectedStart] = useState<string>('');
    const [selectedEnd, setSelectedEnd] = useState<string>('');
    const [timeStart, setTimeStart] = useState<string>('');
    const [timeEnd, setTimeEnd] = useState<string>('');
    const [isOpenChecked, setIsOpenChecked] = useState<boolean>(false);
    const [amountOccupancy, setAmountOccupancy] = useState<number>(0)
    const [idSpecialDays, setIdSpecialDays] = useState<string>('')

    const [toggleModalUnavailability, setToggleModalUnavailability] = useState(false);
    const [toggleHaveReservationModal, setToggleHaveReservationModal] = useState(false);
    const { showLoading, hideLoading, toast } = useUi();
    
    const { placeId } = useParams<{ placeId: string }>();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const RouterPrefix = "/private/agendamento/list/"

        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Passeios',
                url: RouterPrefix + 'lugares'
            },
            {
                title: 'Adicionar passeio',
            },
        ])
    }, [updateRouters]);

    const onClickRemoveUnavailability = useCallback((id: string) => {
        const deleteSpecialDate = values.filter(item => item.id !== id);
        setValues(deleteSpecialDate)
    }, [values]);

    const changeAmountOccupancyHandle = (value: string) => {
        setAmountOccupancy(Number.parseFloat(value) | 0);
    }

    const handleChangeChecked = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsOpenChecked(checked);
    };

    const handleOpenModalEdit = useCallback((item: ISpecialDays) => {
        setSelectedStart(item.start.split('T')[0]);
        setSelectedEnd(item.end.split('T')[0]);
        setTimeEnd(item.opened?.end || item.closed?.end || '');
        setTimeStart(item.opened?.start || item.closed?.start || '');
        setAmountOccupancy(item.opened?.maxOccupancy || 0);
        setIsOpenChecked(!!item.opened);
        setIsEdit(true);
        setToggleModalUnavailability(!toggleModalUnavailability);
        item.id && setIdSpecialDays(item.id)


    }, [toggleModalUnavailability]);


    const handleModal = () => {

        setToggleModalUnavailability(!toggleModalUnavailability)
        setIsEdit(false);
        setSelectedEnd('');
        setSelectedStart('');
        setTimeStart('');
        setTimeEnd('');
        setAmountOccupancy(0);
        setIsOpenChecked(false)


    }

    const handleModalHaveReservation = useCallback(() => {

        setToggleHaveReservationModal(!toggleHaveReservationModal);

    }, [toggleHaveReservationModal]);


    const haveReservationThisDay = useCallback(async () => {

        if (isEdit) {

            const editSpecialDays: IAddSpecialDays = {
                id: idSpecialDays,
                start: selectedStart,
                end: selectedEnd,
                opened: isOpenChecked ? {
                    maxOccupancy: amountOccupancy,
                    start: timeStart,
                    end: timeEnd
                } : undefined,
                closed: !isOpenChecked ? {
                    start: timeStart,
                    end: timeEnd
                } : undefined
            }

            setValues(prev => [...prev.filter(item => item.id !== idSpecialDays), editSpecialDays]);
            handleSpecialDays && handleSpecialDays([...values.filter(item => item.id !== idSpecialDays), editSpecialDays]);
            setToggleModalUnavailability(false);

        } else {
            const addSpecialDays: IAddSpecialDays = {
                id: v4(),
                start: selectedStart,
                end: selectedEnd,
                opened: isOpenChecked ? {
                    maxOccupancy: amountOccupancy,
                    start: timeStart,
                    end: timeEnd
                } : undefined,
                closed: !isOpenChecked ? {
                    start: timeStart,
                    end: timeEnd
                } : undefined
            }
            handleSpecialDays && handleSpecialDays([...values, addSpecialDays]);
            setValues(prev => [...prev, addSpecialDays]);
            setToggleModalUnavailability(false);
        }



    }, [amountOccupancy, handleSpecialDays, isOpenChecked, selectedEnd, selectedStart, timeEnd, timeStart]);

    return (
        {
            values,
            selectedStart,
            selectedEnd,
            setSelectedStart,
            setSelectedEnd,
            toggleModalUnavailability,
            onClickRemoveUnavailability,
            handleModal,
            // handleOpenModalEdit,
            isEdit,
            specialDays,
            haveReservationThisDay,
            toggleHaveReservationModal,
            handleModalHaveReservation,
            //UnavailabilityHandle,
            handleOpenModalEdit,
            timeStart,
            setTimeStart,
            timeEnd,
            setTimeEnd,
            isOpenChecked,
            setIsOpenChecked,
            amountOccupancy,
            setAmountOccupancy,
            changeAmountOccupancyHandle,
            handleChangeChecked,

        }
    )
}

export default UseAddSpecialDaysForm
