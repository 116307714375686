import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
} from "@material-ui/core";
import { Dialog } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { IKDSItem, IKDSItemStep, KDSStatusStep } from "./IKDSItem";
import styles from "./KDSItem.module.scss";
import {
  Moped,
  Close,
  TableBar,
  Check,
} from "@mui/icons-material";
import KDSSideSheetOrder from "../kDSSideSheetOrder/KDSSideSheetOrder";
import KDSTimer from "../KDSTimer/KDSTimer";
import { IIssueNFCe } from "../../interfaces/IIssueNFE";
import { INFCeResponse } from "modules/kds/domain/dto/nfe/NFCeResponse";
import { moneyMaskNumber } from "services/utils/Money";
import { KeyboardArrowRight, Person } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";

export enum KDSStepAction {
  Accept = `acceptOrder`,
  Refuse = `refuseOrder`,
  Start = `startOrder`,
  Transport = `deliverOrder`,
  Refund = `returnedOrder`,
  Conclude = `receivedOrder`,
}

export interface ISaasKDSItemProps {
  kdsItem: IKDSItem;
  onClickAction?: (
    action: KDSStepAction,
    item: IKDSItem
  ) => Promise<IKDSItemStep>;
  onClickPrint?: (item: IKDSItem) => Promise<void>;
  postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>;
  issueNFCe: INFCeResponse[];
  summaryView: boolean;
  setSummaryView: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaasKDSItem: FC<ISaasKDSItemProps> = ({
  kdsItem,
  onClickAction,
  onClickPrint,
  postIssueNfe,
  issueNFCe,
  summaryView,
}) => {
  const [item, setItem] = useState<IKDSItem>(kdsItem);

  const [isLoading, setIsLoading] = useState(false);

  const [openAlertReceivedOrder, setOpenAlertReceivedOrder] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const { hasAccessRole } = useAuth();

  const [refuseModal, setRefuseModal] = useState(false);

  const [giveBackModal, setGiveBackModal] = useState(false);

  const [sidesheetStep, setSidesheetStep] = useState(1);

  const onClickActionHandle = useCallback(
    async (action: KDSStepAction, item: IKDSItem) => {
      if (onClickAction) {
        try {
          setIsLoading(true);
          const response: IKDSItemStep = await onClickAction(action, item);
          setItem((prev) => ({ ...prev, currentStep: response }));
        } finally {
          setRefuseModal(false);
          setGiveBackModal(false);
          setIsLoading(false);
          setSidesheetStep(1);
        }
      }
    },
    [onClickAction]
  );

  const closeAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(false);
  }, []);

  const openAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(true);
  }, []);

  const confirmAlertReceivedHandle = useCallback(() => {
    onClickActionHandle?.(KDSStepAction.Conclude, item);
    setOpenAlertReceivedOrder(false);
  }, [item, onClickActionHandle]);

  const closeRefuseModal = useCallback(() => {
    setRefuseModal(false);
    postIssueNfe({ pedidoId: "", documento: "" });
  }, [postIssueNfe]);

  const openRefuseModal = useCallback(() => {
    setRefuseModal(true);
  }, []);

  const closeGiveBackModal = useCallback(() => {
    setGiveBackModal(false);
  }, []);

  const openGiveBackModal = useCallback(() => {
    setGiveBackModal(true);
  }, []);

  const openDrawerOrder = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  // const closeDrawerOrder = useCallback(() => {
  //   setOpenDrawer(false);
  // }, []);

  const onClickPrintHandle = useCallback(async () => {
    try {
      setIsLoading(true);
      await onClickPrint?.(kdsItem);
    } finally {
      setIsLoading(false);
    }
  }, [kdsItem, onClickPrint]);

  const colorsStatus = (nameStep: IKDSItemStep) => {
    switch (nameStep.name) {
      case KDSStatusStep.PENDING:
        return `#ED0029`;
      case KDSStatusStep.ACCEPTED:
        return `#6C2EE0`;
      case KDSStatusStep.FINISHED:
        return `#399C54`;
      case KDSStatusStep.PREPARING:
        return `#FF5C78`;
      case KDSStatusStep.TRANSPORT:
        return `#F44336`;
      case KDSStatusStep.DELIVERED:
        return `#399C54`;
      case KDSStatusStep.REFUSED:
        return `#BDBDBD`;
      default:
        return `#333333`;
    }
  };

  const buttons = useMemo(() => {
    if (item.currentStep.name)
      switch (item.currentStep.name) {
        case KDSStatusStep.PENDING:
          return (
            <div className={styles.actions}>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={openRefuseModal}
                className={styles.refuse}
              >
                Recusar
              </Button>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Accept, item)
                }
              >
                Aceitar pedido
              </Button>
            </div>
          );
        case KDSStatusStep.ACCEPTED:
          return (
            <div className={styles.actions}>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={() => onClickActionHandle?.(KDSStepAction.Start, item)}
              >
                Iniciar
              </Button>
            </div>
          );
        case KDSStatusStep.PREPARING:
          return (
            <div className={styles.actions}>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Transport, item)
                }
              >
                Concluir Pedido <Check style={{ marginLeft: 10 }} />
              </Button>
            </div>
          );
        case KDSStatusStep.TRANSPORT:
          return (
            <div className={styles.actions}>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={openGiveBackModal}
                className={styles.refuse}
              >
                Devolver
              </Button>
              <Button
                variant="text"
                disabled={isLoading}
                onClick={openAlertReceivedHandle}
              >
                Finalizar
              </Button>
            </div>
          );
        default:
          return <div />;
      }
  }, [
    isLoading,
    item,
    onClickActionHandle,
    openAlertReceivedHandle,
    openGiveBackModal,
    openRefuseModal,
  ]);

  // const distanceFromNow = useMemo(
  //   () => formatDistanceToNow(new Date(item.createdAt), { locale: ptBR }),
  //   [item]
  // );

  // const handleOpenWhatsapp = (phone: string) => {
  //   window.open(`https://api.whatsapp.com/send?phone=55${phone}`);
  // };

  const itemContent = useMemo(
    () => (
      <div className={styles.container}>
        <div
          className={styles.header}
          style={{ backgroundColor: colorsStatus(item?.currentStep) }}
        >
          <div className={styles.titleStatus}>
            <h2 className={styles.title}>{item.code}</h2>
            {item.currentStep.name !== "Entregue" &&
              item.currentStep.name !== "Recusado" &&
              item.currentStep.name !== "Devolvido" ? (
              <KDSTimer startDate={new Date(item.createdAt)} />
            ) : (
              ""
            )}
          </div>
          <div className={styles.timer}>
            <span>{item.currentStep.name}</span>
            <span>{new Date(item.createdAt).toLocaleString()}</span>
          </div>
        </div>
        <div className={styles.orderType}>
          {item.orderOrigin === 2 && <img src="/assets/img/ifood.png" alt="Logo ifood" />}
          {item.orderOrigin !== 2 && (
            item.table ? (
              <div className={styles.flexColumn}>
                <div className={styles.flex}>
                  <TableBar fontSize="small" />
                  <span>Mesa {item.table}</span>
                </div>
                <span style={{ fontSize: "14px" }}>{item.customer?.name}</span>
              </div>
            ) : (
              <div className={styles.flex}>
                <Person fontSize="small" />
                <span>Balcão</span>
              </div>
            )
          )}
          <IconButton onClick={() => openDrawerOrder()}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
        <div className={styles.orderContent}>
          {!item.observation && (
            <span className={styles.observation}>{item.observation}</span>
          )}

          <div className={styles.orderList}>
            {item.order.items.map((orderItem, index) => {
              let showAdicionaisTag = true;
              if (
                orderItem.compositions &&
                orderItem.compositions.length === 1 &&
                orderItem.compositions[0].description === "Observação"
              ) {
                showAdicionaisTag = false;
              }
              return (
                <div key={index} className={styles.orderItem}>
                  <span className={styles.categoryItem}>
                    {orderItem.nameCategory}
                  </span>
                  <div className={styles.top}>
                    <span>
                      {orderItem.quantity}x {orderItem.name}
                    </span>
                    {hasAccessRole("KDSVisualizarValores") && (
                      <span className={styles.value}>
                        {moneyMaskNumber(orderItem.value)}
                      </span>
                    )}
                  </div>
                  {orderItem.compositions
                    ?.filter((item) => !item.options.length)
                    .map(
                      (compositionItem, index) =>
                        !!compositionItem.description && (
                          <div className={styles.compositionItem} key={index}>
                            <span>{compositionItem.description}</span>
                          </div>
                        )
                    )}
                  {!!orderItem.compositions?.length && (
                    <div className={styles.compositions}>
                      {orderItem.compositions.map((compositionItem, index) => (
                        <>
                          {index === 0 && showAdicionaisTag && (
                            <p className={styles.additional}>Adicionais:</p>
                          )}
                          <span>{compositionItem.description}</span>
                          {compositionItem.options.length > 0 && (
                            <div key={index}>
                              {compositionItem.options.map((option, index) => (
                                <>
                                  <div />
                                  <span key={index}>{option}</span>
                                </>
                              ))}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {hasAccessRole("KDSVisualizarValores") && (
          <div className={styles.total}>
            <label>Valor total</label>
            <span>{moneyMaskNumber(item.amount)}</span>
          </div>
        )}
        {!!buttons ? <div className={styles.footer}>{buttons}</div> : <div />}
      </div>
    ),
    [buttons, hasAccessRole, item, openDrawerOrder]
  );

  return (
    <>
      <div id={styles.SaasKDSItem}>
        {itemContent}
        <Dialog
          open={openAlertReceivedOrder}
          onClose={closeAlertReceivedHandle}
        >
          <DialogTitle>Confirmar entrega do pedido</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ao confirmar essa ação não pode ser desfeita
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertReceivedHandle}>Cancelar</Button>
            <Button onClick={confirmAlertReceivedHandle}>Confirmar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <div id={styles.drawer}>
          <KDSSideSheetOrder
            colorsStatus={colorsStatus}
            kdsItem={item}
            postIssueNfe={postIssueNfe}
            onClickActionHandle={onClickActionHandle}
            onClickPrintHandle={onClickPrintHandle}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            openAlertReceivedHandle={openAlertReceivedHandle}
            isLoading={isLoading}
            openRefuseModal={openRefuseModal}
            openGiveBackModal={openGiveBackModal}
            issueNFCe={issueNFCe}
          />
        </div>
      </Drawer>

      <Sidesheet
        isLoading={isLoading}
        open={giveBackModal}
        onClose={() => {
          setGiveBackModal(false);
          setSidesheetStep(1);
        }}
        title={<h2>Devolver <b>pedido</b></h2>}
        content={<SidesheetFeedback text={`Deseja realmente devolver o pedido ${item.code}?`} />}
        currentStep={sidesheetStep}
        totalSteps={2}
        continueButton="Devolver pedido"
        handleContinueButton={() => onClickActionHandle?.(KDSStepAction.Refund, item)}
        cancelButton
        feedback={
            <SidesheetFeedback text="Pedido devolvido com sucesso!" success />
        }
      />

      <Sidesheet
        isLoading={isLoading}
        open={refuseModal}
        onClose={() => {
          setRefuseModal(false);
          setSidesheetStep(1);
        }}
        title={<h2>Recusar <b>pedido</b></h2>}
        content={<SidesheetFeedback text={`Deseja realmente recusar o pedido ${item.code}?`} />}
        currentStep={sidesheetStep}
        totalSteps={2}
        continueButton="Recusar pedido"
        handleContinueButton={() => onClickActionHandle?.(KDSStepAction.Refuse, item)}
        cancelButton
        feedback={
            <SidesheetFeedback text="Pedido recusado com sucesso!" success />
        }
      />
    </>
  );
};
export default SaasKDSItem;
