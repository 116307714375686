import { IInvoiceService } from "modules/invoice/domain/interfaces/IInvoiceService";
import { ITaxForm } from "modules/invoice/presentation/interfaces/ITaxForm";

export const CreateTaxUseCase = async (service: IInvoiceService, localId: string, request: ITaxForm): Promise<void> => {
  if (request.cofins?.taxSituationId && !request.cofins?.ehPorAlicota && !request.cofins?.aliquotaValor) {
    request.cofins.aliquotaValor = 0;
  }
  if (request.pis?.taxSituationId && !request.pis?.ehPorAlicota && !request.pis?.aliquotaValor) {
    request.pis.aliquotaValor = 0;
  }

  if (request.cofins && !Object.keys(request.cofins).length) {
    request.cofins = null;
  }

  if (request.pis && !Object.keys(request.pis).length) {
    request.pis = null;
  }

  if (request.ipi && !Object.keys(request.ipi).length) {
    request.ipi = null;
  }

  if (request.icms && !Object.keys(request.icms).length) {
    request.icms = null;
  }

  request.cest = request.cest || null;
  request.additionalInformation = request.additionalInformation || null;

  return await service.createTax(localId, request);
}
