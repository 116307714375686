import create from "zustand";

interface ISelectParentId {
  parentId: string;
  setParentId: (parentId: string) => void;
  parentTitle: string;
  setParentTitle: (parentTitle: string) => void;
};

const UseSelectParentId = create<ISelectParentId>(
    (set) => ({
        parentId: "",
        setParentId: (selectParentId) => {
          set(() => ({
            parentId: selectParentId,
          }));
        },
        parentTitle: "",
        setParentTitle: (selectParentTitle) => {
          set(() => ({
            parentTitle: selectParentTitle,
          }));
        },
    })
);

export default UseSelectParentId;
