import Layout from "components/layout/presentation/Layout"
import { useEffect, useState } from "react";
import { OnlineSolutionsAppMeep } from "../onlineSolutionsAppMeep/OnlineSolutionsAppMeep";
import { OnlineSolutionsParams } from "../onlineSolutionsParams/OnlineSolutionsParams";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";

export const OnlineSolutionsPage = () => {
    const { updateRouters } = useBreadcumbs();
    const [step, setStep] = useState<number>(0);
    const { currentLocal } = useLocal();
    const [changeDates, setChangeDates] = useState(false);

    useEffect(() => {
        // if (currentLocal) {
        //     try {

        //         const showFullOnlyneSolutions = localStorage.getItem('@showFullOnlyneSolutions');
        //         const showFullOnlyneSolutionsObj: string[] = JSON.parse(showFullOnlyneSolutions ?? '[]');

        //         if (!!showFullOnlyneSolutionsObj.find(x => x === currentLocal.id))
        //             setStep(2);
        //         else
        //             setStep(1)
        //     } catch (err) {
        //         console.log(err);
        //     }
        // }
    }, [currentLocal]);

    useEffect(() => {
        updateRouters([{title: "Soluções Online"}]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const handleOnChangeDates = () => {
        setStep(1);
        setChangeDates(true);
    }

    if (step === undefined) {
        return <></>;
    }

    return (
        <Layout>
            <OnlineSolutionsParams />

            {/* {
                // step === 0 ?
                //     <OnlineSolutionsTypes setStep={setStep} /> :
                    step === 1 ?
                        <OnlineSolutionsAppMeep changeDates={changeDates} setChangeDates={setChangeDates} onBack={() => setStep(0)} onNext={() => setStep(2)} /> :
                        
            } */}
        </Layout>
    )
}