import { IndicatorValue } from "../indicatorValue/indicatorValue";
import styles from "./productionInfo.module.scss";
import { FC, useEffect, useState } from "react";
import { Skeleton } from "../skeleton/Skeleton";
import { ProductionInfoResponse } from "modules/cashlessDashboard/domain/dto/productionInfo/getProductionInfoResponse";
import { moneyMaskNumber } from "services/utils/Money";

type ProductionInfoType = {
  getProductionData: any;
  setCachedAt: any;
  setExpireAt: any;
};

const ProductionInfo: FC<ProductionInfoType> = ({
  getProductionData,
  setCachedAt,
  setExpireAt,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<ProductionInfoResponse>();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getProductionData();

        setExpireAt(response.cacheInfo.expiresAt);
        setCachedAt(response.cacheInfo.cachedAt);

        setData(response);
        setLoading(false);
      } catch (error) {}
    })();
  }, [getProductionData, setCachedAt, setExpireAt]);

  if (loading) {
    return (
      <div id={styles.RechargePaymentMethods}>
        <div className={styles.indicatorRow}>
          <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />

          <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />

          <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.indicatorRow}>
      <IndicatorValue
        label="Recarregado"
        value={moneyMaskNumber(data?.rechargedTotalNet)}
      />
      <IndicatorValue
        label="Consumido cashless"
        value={moneyMaskNumber(data?.consumptionTotalNet)}
      />
      <IndicatorValue
        label="Venda de fichas"
        value={moneyMaskNumber(data?.tikectsTotalNet)}
      />
    </div>
  );
};

export { ProductionInfo };
