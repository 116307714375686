import { DeleteOutlined, DragIndicator, EditOutlined, KeyboardArrowDownRounded } from "@material-ui/icons";
import styles from "./MenuContainer.module.scss";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { Icon, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import { IGetMenuListResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuListResponse";
import UseSelectParentId from "modules/admin/menuConfig/presentation/hooks/UseSelectParentId";
import { IGetMenuList } from "../../AddMenuSidesheet";
import { UseMenuConfigPage } from "modules/admin/menuConfig/presentation/hooks/UseMenuConfigPage";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";

interface IMenuContainerProps {
    parentItem: IGetMenuList;
    item: IGetMenuListResponse;
    index: number;
    setAddMenuSidesheetStep: React.Dispatch<React.SetStateAction<"menuPai" | "menuConfig" | "menuFilho" | "menuNeto" | "deleteMenu">>;
    isMenuPai?: boolean;
    onDragEnd: (result: DropResult, currentItem: IGetMenuList) => void;
    setEditMenuFormValues: React.Dispatch<React.SetStateAction<IPutEditMenuPayload>>;
    handleOpenDeleteMenuSidesheet: (menuId: string) => Promise<void>;
}
const MenuContainer = ({
    parentItem,
    item,
    index,
    setAddMenuSidesheetStep,
    isMenuPai,
    onDragEnd,
    setEditMenuFormValues,
    handleOpenDeleteMenuSidesheet,
}: IMenuContainerProps) => {
    const [showMoreInfo, setShowMoreInfo] = useState<string[]>([]);

    const { setParentId, setParentTitle } = UseSelectParentId();
    const { getMenuById } = UseMenuConfigPage();

    const handleShowMoreInfo = useCallback(async (systemId: string) => {
        if (showMoreInfo.includes(systemId)) {
            const filterInfo = showMoreInfo.filter((item) => item !== systemId);
            return setShowMoreInfo(filterInfo);
        }
        setShowMoreInfo([...showMoreInfo, systemId])
    }, [showMoreInfo]);

    const handleAddMenu = useCallback((type: "menuPai" | "menuConfig" | "menuFilho" | "menuNeto" | "deleteMenu", menuId: string, menuTitle: string) => {
        setAddMenuSidesheetStep(type);
        setParentId(menuId);
        setParentTitle(menuTitle);
    }, [setAddMenuSidesheetStep, setParentId, setParentTitle]);

    const handleEditMenu = useCallback(async (menuId: string) => {
        if (isMenuPai) {
            setAddMenuSidesheetStep("menuPai");
        }
        if (item.level === 1) {
            setAddMenuSidesheetStep("menuFilho");
        }
        if (item.level === 2) {
            setAddMenuSidesheetStep("menuNeto");
        }
        const menuToEdit = await getMenuById(menuId);
        if (menuToEdit) {
            setEditMenuFormValues(menuToEdit);
        }
    }, [getMenuById, isMenuPai, item.level, setAddMenuSidesheetStep, setEditMenuFormValues]);

    if (!item?.id) {
        return <div />;
    }
    return (
        <Draggable
            draggableId={item.id}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div id={styles.MenuContainer}>
                        <div className={styles.content}>
                            <div className={styles.titleAndButtons}>
                                <div className={`${styles.title} ${isMenuPai && styles.menuPai}`}>
                                    <DragIndicator />
                                    {item.icon && <Icon className={styles.icon} fontSize="small">{item.icon}</Icon>}
                                    <span>{item.title}</span>
                                </div>
                                <div>
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleEditMenu(item.id)}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleOpenDeleteMenuSidesheet(item.id)}
                                    >
                                        <DeleteOutlined />
                                    </IconButton>
                                    {!!item.submenus && !!item.submenus.length 
                                        ? (
                                            <IconButton
                                                disableRipple
                                                onClick={() => handleShowMoreInfo(item.id)}
                                                style={{ transform: showMoreInfo.includes(item.id) ? "rotate(-180deg)" : "rotate(0deg)" }}
                                            >
                                                <KeyboardArrowDownRounded />
                                            </IconButton>
                                        ) : (<div style={{ width: "40px" }}/>)
                                    }
                                </div>
                            </div>
                            {(isMenuPai || (!!item.level && item.level === 1)) && (
                                <span
                                    className={styles.addMenuButton}
                                    onClick={() => handleAddMenu(isMenuPai ? "menuFilho" : "menuNeto", item.id, item.title)}
                                >
                                    {isMenuPai ? "+ Adicionar menu filho" : "+ Adicionar menu neto"}
                                </span>
                            )}
                        </div>
                        {showMoreInfo.includes(item.id) && (
                            <DragDropContext onDragEnd={(e) => onDragEnd(e, parentItem)}>
                                <Droppable droppableId="droppable" type="ITEM" direction="vertical">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps} className={styles.submenuContainer}>
                                            {!!item.submenus.length && item.submenus.map((submenu, submenuIndex) => (
                                                <div key={submenu?.id}>
                                                    <MenuContainer
                                                        parentItem={parentItem}
                                                        item={submenu}
                                                        index={submenuIndex}
                                                        onDragEnd={(e) => onDragEnd(e, parentItem)}
                                                        setAddMenuSidesheetStep={setAddMenuSidesheetStep}
                                                        handleOpenDeleteMenuSidesheet={handleOpenDeleteMenuSidesheet}
                                                        setEditMenuFormValues={setEditMenuFormValues}
                                                    />
                                                    {provided.placeholder}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default MenuContainer;