import { Drawer } from "@material-ui/core";
import GetCurrentEmailSignatureUseCase from "modules/crm/application/useCases/plans/GetCurrentEmailSignatureUseCase";
import { useSelectCrmCustomer } from "modules/crm/presentation/context/SelectCustomerContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrmServiceApi from "services/api/crm/CrmApi";
import { IDropdown } from "../../../interfaces/ICrmFilterValue";
import DrawerCampaignCreate from "../DrawerCampaignCreate/DrawerCampaignCreate";
import styles from "./CampaignComponent.module.scss";
import Button from "components/ui/Button/Button";

export interface Props {
  events: IDropdown[];
  campaigns: IDropdown[];
  onClickCreateCampaignWithSelectedCostumers?: () => void,
  onClickCreateCampaignByFilter?: () => void,
  onClickCreateCampaignByEvent?: (event: IDropdown[]) => void,
  onClickCreateCampaignByCampaign?: (campaign: IDropdown) => void,
}

const CampaignComponent = ({ events, campaigns, onClickCreateCampaignWithSelectedCostumers, onClickCreateCampaignByFilter, onClickCreateCampaignByEvent, onClickCreateCampaignByCampaign }: Props) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { customerQuantity } = useSelectCrmCustomer();
  const { currentLocal } = useLocal();
  const [hasSignature, setHasSignature] = useState(false)
  const history = useHistory()

  const onClickCreateCampaign = useCallback(() => {
    if (customerQuantity) {
      onClickCreateCampaignWithSelectedCostumers?.()
    } else {
      setOpenDrawer(true);
    }
  }, [customerQuantity, onClickCreateCampaignWithSelectedCostumers])

  const onCloseHandle = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  useEffect(() => {
    (async () => {
      const service = CrmServiceApi();
      const signatures = await GetCurrentEmailSignatureUseCase(service, currentLocal!.id)
      setHasSignature(signatures?.isActive || false)
    })()
  }, [currentLocal])

  return (
    <div className={styles.Container}>
      {!!customerQuantity ?
        <p>
          {
            hasSignature ?
              <><strong>{customerQuantity}</strong> clientes selecionados utilize-os para criar <strong>campanhas incríveis!</strong> </> :
              <><strong>Contrate</strong> nosso CRM para enviar mensagens por <strong>email</strong> e <strong>Whatsapp!</strong></>
          }
          {/* <strong>9764</strong> clientes selecionados utilize-os para criar <strong>campanhas incríveis!</strong> */}
        </p>
        :
        <p>
          {/* {
            hasSignature ?
              <>Faça as configurações e crie <strong>campanhas incríveis</strong></> :
              <><strong>Contrate</strong> nosso CRM para enviar mensagens por <strong>email</strong> e <strong>Whatsapp!</strong></>
            } */}
           {currentLocal?.systemIdentifier === 1 ? "Faça as configurações e crie" : "Utilize o CRM para criar"} <strong>campanhas incríveis!</strong>
        </p>
      }
      {/* <button
        className={styles.ButtonHire}
        onClick={() => history.push('/private/configuracao/integrations')}
      >
        Contratar
      </button> */}
      <Button
        onClick={onClickCreateCampaign}
        endIcon={
          <img
            src="/assets/img/crown.png"
            alt="crown"
          />
        }
      >
        Criar campanha
      </Button>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <DrawerCampaignCreate
          events={events}
          campaigns={campaigns}
          onClickCreatCampaignByFilter={onClickCreateCampaignByFilter ?? onCloseHandle}
          onClickCreateCampaignByEvent={onClickCreateCampaignByEvent ?? onCloseHandle}
          onClickCreateCampaignByCampaign={onClickCreateCampaignByCampaign ?? onCloseHandle}
          onClose={() => setOpenDrawer(false)}
        />
      </Drawer>
    </div>
  );
};

export default CampaignComponent;
