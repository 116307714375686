import { useCallback, useEffect, useState } from "react"
import { GetFidelityPlansUseCase } from "../application/useCases/GetFidelityPlansUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IFidelityPlans } from "./interfaces/IFidelityPlans";
import { FidelityServiceApi } from "services/api/fidelity/FidelityApi";
import DeleteFidelityPlanUseCase from "../application/useCases/DeleteFidelityPlanUseCase";
import { CheckCurrencyExchangeFidelityPlanUseCase } from "../application/useCases/CheckCurrencyExchangeFidelityPlanUseCase";
import { ToggleCurrencyExchangeFidelityPlanUseCase } from "../application/useCases/ToggleCurrencyExchangeFidelityPlanUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetFidelityCustomersUseCase } from "../application/useCases/GetFidelityCustomersUseCase";
import { IFidelityCustomers } from "./interfaces/IFidelityCustomers";

const service = FidelityServiceApi();

export const UseFidelityPage = () => {
  const [fidelityList, setFidelityList] = useState<IFidelityPlans[]>([]);
  const [loading, setLoading] = useState(true);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<IFidelityPlans>();
  const [exchangeEnabled, setExchangeEnabled] = useState<boolean>();
  const [toggleExchangeModalOpened, setToggleExchangeModalOpened] = useState(false);
  const [savingExchange, setSavingExchange] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IFidelityPlans>();
  const [listCustomersOpen, setListCustomersOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fidelityCustomers, setFidelityCustomers] = useState<IFidelityCustomers>();

  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getData = useCallback(async () => {
    try {
      if (currentLocal) {
        setLoading(true);
        const response = await GetFidelityPlansUseCase(service, currentLocal?.id);
        setFidelityList(response);
      }
    } finally {
      setLoading(false);
    }
  }, [currentLocal]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    (async () => {
      try {
        const response = await CheckCurrencyExchangeFidelityPlanUseCase(service, currentLocal!.id);
        setExchangeEnabled(response);
      } catch { }
    })()
  }, [currentLocal]);

  const getFidelityCustomers = useCallback(async (page: number, keyword?: string) => {
    try {
      setCustomersLoading(true);
      const response = await GetFidelityCustomersUseCase(service, selectedItem!.id, currentLocal!.id, page, keyword);
      setFidelityCustomers(response);
      setCurrentPage(page || 0);
    } finally {
      setCustomersLoading(false);
    }
  }, [currentLocal, selectedItem])

  const deleteFidelityPlan = async () => {
    try {
      if (itemToDelete) {
        setDeleteLoading(true);
        await DeleteFidelityPlanUseCase(service, itemToDelete.id)
        setItemToDelete(undefined);
        getData();
      }
    } finally {
      setDeleteLoading(false);
    }
  }

  const handleToggleExchange = async (enabled: boolean) => {
    setSavingExchange(true);
    try {
      await ToggleCurrencyExchangeFidelityPlanUseCase(service, currentLocal!.id, enabled);
      setToggleExchangeModalOpened(false);
      setExchangeEnabled(!exchangeEnabled);
    } catch {
      toast('Ocorreu um erro ao salvar a informação. Tente novamente.', 'error');
    } finally {
      setSavingExchange(false);
    }
  }

  const handleClickShowCustomers = (fidelityPlan: IFidelityPlans) => {
    setSelectedItem(fidelityPlan);
    setListCustomersOpen(true);
  }

  return {
    fidelityList,
    loading,
    itemToDelete,
    deleteLoading,
    exchangeEnabled,
    toggleExchangeModalOpened,
    savingExchange,
    selectedItem,
    listCustomersOpen,
    customersLoading,
    fidelityCustomers,
    currentPage,
    setItemToDelete,
    getData,
    deleteFidelityPlan,
    setToggleExchangeModalOpened,
    handleToggleExchange,
    setSelectedItem,
    setListCustomersOpen,
    handleClickShowCustomers,
    getFidelityCustomers
  }
}
