import Layout from "components/layout/presentation/Layout";
import styles from "./ERPLandingPage.module.scss";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import { useEffect, useState } from "react";
import ConfigOmie from "modules/config/integrationsConfig/presentation/pages/configOmie/ConfigOmie";
import ConfigSankhya from "modules/config/integrationsConfig/presentation/pages/configSankhya/ConfigSankhya";
import ContentBox from "modules/config/integrationsConfig/presentation/components/_configCampaign/contentBox/ContentBox";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

export const ERPLandingPage = () => {
  const [step, setStep] = useState(1);
  const [modalOpened, setModalOpened] = useState<IntegrationTypeEnum | null>(
    null
  );
  const [existPayment, setExistPayment] = useState(false);
  const [creditCard, setCreditCard] = useState(false);

  const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
        {
            title: "Sistema ERP",
        },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.titleContainer}>
              <img src="/assets/icon/erp.svg" alt="" />
              <h1>
                Sistema <span>ERP</span>
              </h1>
            </div>

            <p className={styles.erpDescription}>
              Transforme a tomada de decisões do seu negócio com o Sistema ERP (Sistema de Planejamento de Recursos Empresariais). 
              Tenha uma ferramenta que aprimore a eficiência operacional, a comunicação interna e te ajuda em decisões assertivas baseadas em dados. 
              <br />
              Descubra mais agora mesmo!
            </p>
          </div>
          <div className={styles.column}>
            <img src="/assets/img/erp-landing-1.png" alt="" />
          </div>
        </div>

        <div className={styles.partners}>
          <div>
            <span className={styles.partnersTitle}>
              Nossos parceiros disponíveis:
            </span>

            <div>
              <ConfigOmie
                open={modalOpened}
                setOpen={setModalOpened}
                step={step}
                setStep={setStep}
              />

              <ContentBox
                status="unavailable"
                type={IntegrationTypeEnum.EVEREST}
                text="Integração para gestão de negócios e ERP em diversos serviços"
                setOpen={setModalOpened}
                getMoreRef="/private/everest/detail"
                showConnect={false}
              />

              <ConfigSankhya
                open={modalOpened}
                setOpen={setModalOpened}
                step={step}
                setStep={setStep}
                existPayment={existPayment}
                setExistPayment={setExistPayment}
                creditCard={creditCard}
                setCreditCard={setCreditCard}
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <img src="/assets/img/erp-landing-2.png" alt="" />
          </div>
          <div className={styles.column}>
            <span className={styles.subtitle}>O que é?</span>
            <p className={styles.whatIsIt}>
              O ERP abrange várias áreas de uma organização, incluindo finanças, contabilidade, recursos humanos, 
              cadeia de suprimentos, manufatura, vendas, compras e muito mais. Ele reúne dados e processos que 
              costumavam ser executados separadamente em diferentes sistemas ou departamentos, 
              permitindo que todas as áreas trabalhem com as mesmas informações atualizadas.
            </p>
          </div>
        </div>

        {/* <div className={styles.row}>
          <div className={styles.column}>
            <span className={styles.subtitle}>Como funciona?</span>
            <p className={styles.howItWorks}>
              Lorem ipsum dolor sit amet consectetur. In cursus quis nisl velit
              morbi dolor. Malesuada ac placerat luctus elementum lorem elit
              dictum accumsan elementum. Ultricies varius purus blandit amet
              cursus. Commodo interdum est sem a massa. Non porttitor
              consectetur malesuada aliquet. Dignissim sagittis diam cursus nunc
              augue viverra. Enim maecenas nulla condimentum nunc dictum
              lobortis. Non eget cras leo sagittis mi diam viverra. Lorem ipsum
              dolor sit amet consectetur. In cursus quis nisl velit morbi dolor.
            </p>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};
