import { IGetHouseFlowDetailsItemResponse } from "modules/houseFlow/domain/dto/IGetHouseFlowDetailsItemResponse"
import { IHouseFlowService } from "modules/houseFlow/domain/interfaces/IHouseFlowService"



const GetHouseFlowDetailsItemUseCase = async (service: IHouseFlowService, localId: string, cardId?: string, pagination?: { page?: number, pageSize?: number }): Promise<IGetHouseFlowDetailsItemResponse> => {
   
    const response = await service.getHouseFlowDetailsItem(localId, cardId, pagination)
    return (response)
}
export default GetHouseFlowDetailsItemUseCase