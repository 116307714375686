import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddPromoterToListUseCase from "modules/events/application/useCases/access/AddPromoterToListUseCase";
import DeleletePromoterFromListUseCase from "modules/events/application/useCases/access/DeleletePromoterFromListUseCase";
import GetPromotersFromListUseCase from "modules/events/application/useCases/access/GetPromotersFromListUseCase";
import GetPromotersFromLocalUseCase from "modules/events/application/useCases/access/GetPromotersFromLocalUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { AccessApi } from "services/api/events/access/AccessApi";
import { IPromoters } from "./interfaces/IPromoters";

const accessService = AccessApi();
interface IUseGrantAccessForm {
  addPromoter: () => Promise<void>;
  deletePromoter: (promoterId: string) => Promise<void>;
  promoterWithAccess: IPromoters[] | null;
  promotersFromPlace: IPromoters[];
  handleChangePromoter: (promoter: IPromoters) => void;
  adding: boolean;
}

const UseGrantAccessForm = (listId: string | undefined): IUseGrantAccessForm => {
  const { currentLocal } = useLocal();
  const [localId, setLocalId] = useState<string>();
  const { toast } = useUi();
  const [adding, setAdding] = useState(false);
  const [promoterWithAccess, setPromoterWithAccess] = useState<IPromoters[] | null>(null);
  const [promotersFromPlace, setPromotersFromPlace] = useState<IPromoters[]>([]);
  const [promoterToAdd, setPromoterToAdd] = useState<IPromoters>({} as IPromoters);

  useEffect(() => {
    if (currentLocal?.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  useEffect(() => {
    const getPromoterWithAccess = async (list: string) => {
      if (!currentLocal) return;
      const result = await GetPromotersFromListUseCase(accessService, list, currentLocal.id);
      setPromoterWithAccess(result);
    };
    if (listId && listId.length) {
      getPromoterWithAccess(listId);
    } else {
      setPromoterWithAccess(null);
    }
  }, [listId, currentLocal]);

  useEffect(() => {
    const getLocalPromoters = async (localId: string) => {
      try {
        const result = await GetPromotersFromLocalUseCase(accessService, localId);
        setPromotersFromPlace(result);
      } catch (error) {
      }
    };

    localId && getLocalPromoters(localId);
  }, [localId]);

  const addPromoter = useCallback(async () => {
    if (listId && promoterToAdd?.id && currentLocal) {
      setAdding(true);
      try {
        await AddPromoterToListUseCase(accessService, { peopleListId: listId, cpf: promoterToAdd.cpf }, currentLocal.id);
        setPromoterWithAccess(promoterWithAccess ? [...promoterWithAccess, promoterToAdd] : [promoterToAdd]);
        toast("Promoter adicionado com sucesso.", "success");
      } catch (error) {
        toast("Não foi possível adicionar o promoter.", "error");
      } finally {
        setAdding(false);
        setPromoterToAdd({} as IPromoters);
      }
    } else {
      toast("Selecione um Promoter", "error");
    }
  }, [listId, promoterToAdd, promoterWithAccess, toast, currentLocal]);

  const deletePromoter = useCallback(
    async (promoterId: string) => {
      if (listId && currentLocal) {
        try {
          await DeleletePromoterFromListUseCase(accessService, listId, promoterId, currentLocal.id);
          promoterWithAccess && setPromoterWithAccess(promoterWithAccess?.filter((item) => item.id !== promoterId));
          toast("Promoter excluído com sucesso.", "success");
        } catch (error) {
          toast("Não foi possível excluir o promoter.", "error");
        }
      }
    },
    [listId, promoterWithAccess, toast, currentLocal]
  );

  const handleChangePromoter = useCallback((promoter: IPromoters) => {
    setPromoterToAdd(promoter);
  }, []);

  return {
    addPromoter,
    deletePromoter,
    promoterWithAccess,
    promotersFromPlace,
    handleChangePromoter,
    adding,
  };
};

export default UseGrantAccessForm;
