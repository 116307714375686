import { Icon } from '@material-ui/core';
import styles from './ConfigBI.module.scss';
import { DeleteBIModal } from '../../components/deleteBImodal/DeleteBIModal';
import { ConfigBIModal } from '../../components/configBImodal/ConfigBIModal';
import { UseConfigBI } from './UseConfigBI';
import { Pagination, Skeleton } from '@mui/material';
import { BIHistoryModal } from '../../components/biHistoryModal/BIHistoryModal';

export const ConfigBIPage = () => {
    const {
        handleRemovePowerBIConfig,
        setOwnerSelected,
        handleSavePowerBIConfig,
        setConfigModalOpened,
        setConfigSelected,
        getData,
        setHistoryModalOpened,
        setDeleteModalOpened,
        deleteModalOpened, 
        historyModalOpened,
        configSelected,
        configModalOpened,
        ownerSelected,
        saving,
        data,
        loading,
        currentPage,
        biSolicitations
    } = UseConfigBI();

    return (
        <div className={styles.container}>
            <h1>BI</h1>
            <span className={styles.subtitle}>Esses são os estabelecimentos que utilizam o PowerBI personalizado da Meep</span>

            <button onClick={() => setConfigModalOpened(true)}>
                <label>Adicionar BI</label>
                <Icon>add_circle</Icon>
            </button>

            {
                !!biSolicitations &&
                <div className={styles.biSolicitationContainer}>
                    <Icon className={styles.icon}>info_outlined</Icon>
                    <span className={styles.text}>
                        {biSolicitations > 1 ? 'Existem ' : 'Existe '}
                        <b>{biSolicitations}
                            {biSolicitations > 1 ? ' solicitações ' : ' solicitação '}
                            de Power BI</b> para
                        {biSolicitations > 1 ? ' serem configuradas' : ' ser configurada'}
                    </span>
                </div>
            }

            <div className={styles.tableHeader}>
                <div>
                    <span>Local</span>
                    <span>Data da contratação</span>
                    <span>Histórico</span>
                    <span>Status do painel</span>
                    <div></div>
                </div>
            </div>
            <div className={styles.tableBody}>
                {
                    loading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                        </> :
                        <>
                            {
                                data?.data.map(x => (
                                    <div>
                                        <span>{x.localName}</span>
                                        <span>11/10/2023 11:55</span>
                                        <span>
                                            <button onClick={() => {
                                                setHistoryModalOpened(true);
                                                setOwnerSelected(x.localId);
                                            }}>
                                                Acessar
                                            </button>
                                        </span>
                                        <span className={styles.status}>
                                            <div className={!!x.powerBIFeature?.length ? styles.active : styles.inactive} />
                                            <span>
                                                {!!x.powerBIFeature?.length ? 'Configurado' : 'Não configurado'}
                                            </span>
                                        </span>
                                        <div className={styles.actionButtons}>
                                            <button onClick={() => { setConfigSelected(x); setConfigModalOpened(true) }}><img src='/assets/icon/edit_outlined.svg' alt="" style={{ height: 30, width: 20 }} /></button>
                                            <button onClick={() => { setDeleteModalOpened(true); setOwnerSelected(x.localId); }}><Icon style={{ height: 30, fontSize: 24, marginLeft: 8, position: 'relative', top: -2 }}>delete_outlined</Icon></button>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                }
            </div>
            <Pagination
                variant="outlined"
                shape="rounded"
                style={{ alignSelf: 'flex-end' }}
                sx={{
                    '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                    },
                    '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                    },
                }}
                className={styles.contentPagination}
                page={currentPage}
                count={data?.totalPages}
                onChange={(ev, page) => getData(page)}
            />
            {
                !!ownerSelected && <BIHistoryModal open={historyModalOpened} onClose={() => { setOwnerSelected(undefined); setHistoryModalOpened(false); }} ownerId={ownerSelected} />
            }
            <DeleteBIModal open={deleteModalOpened} onClose={() => { setDeleteModalOpened(false); setOwnerSelected(undefined); }} onSubmit={handleRemovePowerBIConfig} loading={saving} />
            <ConfigBIModal open={configModalOpened} onClose={() => { setConfigModalOpened(false); setConfigSelected(undefined); }} onSubmit={handleSavePowerBIConfig} loading={saving} defaultValues={configSelected} />
        </div>
    )
}