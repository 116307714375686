import React, { FC } from 'react'
import { CampaignChannel } from '../../intefaces/ICampaign'
import styles from './QuantityChannelAlert.module.scss'
export interface IQuantityChannelAlertProps {
    //propertys
    selectedChannel: CampaignChannel
    totalCustomer: number;
    quantityCustomersWithPhonenumber: number,
    quantityCustomersWithEmail: number
}
const QuantityChannelAlert: FC<IQuantityChannelAlertProps> = ({
    totalCustomer,
    quantityCustomersWithPhonenumber,
    quantityCustomersWithEmail,
    selectedChannel
}) => {
    return (
        <div id={styles.QuantityChannelAlert} >
            <div className={styles.icon} >
                <img src="/assets/icon/alert-triangle.svg" alt="" />
            </div>
            <div className={styles.text} >
                Dos {totalCustomer} destinatários, <b>{selectedChannel === CampaignChannel.email ? quantityCustomersWithEmail : quantityCustomersWithPhonenumber} possuem {selectedChannel === CampaignChannel.email ? "e-mail" : "whatsapp"}.</b>
            </div>
        </div>
    )
}
export default QuantityChannelAlert