import { Button, Icon, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import AutoComplete from 'components/ui/autoComplete/AutoComplete'
import Input from 'components/ui/input/Input'
import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './CustomerFilter.module.scss'
export interface ICustomerFilterProps {
    //propertys
    locals: { name: string, id: string }[]
    events: { name: string, id: string }[]
    onChange?: (filter: ICustomerFilter) => void
    defaultValues: ICustomerFilter
}

export interface ICustomerFilter {
    start: string,
    end: string,
    locals: { id: string, name: string }[]
    events: { id: string, name: string }[]
}

const CustomerFilter: FC<ICustomerFilterProps> = ({
    locals,
    events,
    onChange,
    defaultValues
}) => {

    const [values, setValues] = useState(defaultValues)

    const changeStartHandle = useCallback((start: string) => {
        setValues({ ...values, start });
    }, [values]);
    const changeEndHandle = useCallback((end: string) => {
        setValues({ ...values, end });
    }, [values]);
    const changeLocalsHandle = useCallback((locals: { id: string, name: string }[]) => {
        setValues({ ...values, locals });
    }, [values]);
    const changeEventsHandle = useCallback((events: { id: string, name: string }[]) => {
        setValues({ ...values, events });
    }, [values]);


    useEffect(() => {
        onChange?.(values)
    }, [values, onChange])


    useEffect(() => {
        setValues(defaultValues)    
    }, [defaultValues])

    return (
        <div id={styles.CustomerFilter} >
            <div className={styles.container} >
                <div className={styles.item} style={{ flex: 0 }} >
                    <TextField
                        label="inicio"
                        type={"date"}
                        name={"start"}
                        value={values.start}
                        fullWidth={false}
                        onChange={ev => changeStartHandle(ev.target.value)}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                <div className={styles.item} style={{ flex: 0 }} >
                    <TextField
                        label="fim"
                        name={"end"}
                        fullWidth={false}
                        value={values.end}
                        onChange={ev => changeEndHandle(ev.target.value)}
                        InputLabelProps={{
                            shrink: true
                        }}
                        type={"date"}
                    />
                </div>
                <div className={styles.item} >
                    <AutoComplete
                        getOptionLabel={item => item.name}
                        multiple
                        value={values.locals}
                        onChange={(ev, _values) => changeLocalsHandle(_values as { name: string, id: string }[])}
                        label={"Estabelecimento"}
                        options={locals}
                    />
                </div>
                <div className={styles.item} >
                    <AutoComplete
                        getOptionLabel={item => item.name}
                        value={values.events}
                        onChange={(ev, _values) => changeEventsHandle(_values as { name: string, id: string }[])}
                        multiple

                        label={"Evento"}
                        options={events} />
                </div>
                <div className={styles.item} style={{ flex: 0 }}>
                    {/* <div className={styles.item} style={{flex: 0}}>
                    <Button size={"small"} variant={"outlined"} ><Icon>vertical_align_bottom</Icon>Exportar</Button>
                </div>
                <div className={styles.item} style={{flex: 0}}>
                    <Button size={"small"} variant={"outlined"} ><Icon>vertical_align_top</Icon>Importar</Button>
                </div> */}
                </div>
            </div>
        </div>
    )
}
export default CustomerFilter