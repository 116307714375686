import { IClientScheduleApi } from "modules/schedule/application/interfaces/api/IClientScheduleApi";
import { IGetDataFromCpfResponse } from "modules/schedule/domain/dto/clientSchedule/IGetDataFromCpfResponse";
import { api } from "../../Api"

const ClientsScheduleApi = (): IClientScheduleApi => {
    const getDataFromCpf = async (localId: string, cpf: string): Promise<IGetDataFromCpfResponse> => {
        const response = await api.get<IGetDataFromCpfResponse>(`/Schedule/${localId}/Client/Cpf/${cpf}`);
        return response.data;
    }

    return ({ getDataFromCpf });
}

export default ClientsScheduleApi;
