import Layout from "components/layout/presentation/Layout";
import ServicesPage from "./ServicesPage";

const Servicos = () => {
  return (
    <Layout>
      <ServicesPage />
    </Layout>
  );
};

export default Servicos;
