import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Switch, Tab, Tabs } from '@material-ui/core'
import { AddCircle, Filter } from '@material-ui/icons'
import List from 'modules/customers/presentation/components/list/List'
import { FC } from 'react'
import { ICatagoryServiceScheduleForm } from '../../components/serviceCategoryForm/interfaces/ICatagoryServiceScheduleForm'
import ServiceCategoryForm from '../../components/serviceCategoryForm/ServiceCategoryForm'
import { ListLinks } from './components/listLinks/ListLinks'
import styles from './ServiceCategoriesPage.module.scss'
import { UseServiceCateoriesPage } from './UseServiceCategoriesPage'
export interface IServiceCategoriesPageProps {
    //propertys
}
const ServiceCategoriesPage: FC<IServiceCategoriesPageProps> = () => {

    const {
        mainCategories,
        onChangeOrderMainCategory,
        categories,
        onChangeOrderCategory,
        setFilter,
        enableReorder,
        selectedDelete,
        selectedEdit,
        openAdd,
        filter,
        onClickAddButtonHandle,
        onClickCloseAddHandle,
        openEditModal,
        onClickCloseEditHandle,
        openDeleteModal,
        onClickCloseDeleteModal,
        setEnableReorder,
        onDeleteCategporyHandle,
        onEditCategoryHandle,
        onAddCategoryHandle,
        openBondsModal,
        onClickCloseListLinks,
        openBonds,
        selectedBond

    } = UseServiceCateoriesPage();





    return (
        <div id={styles.ServiceCategoriesPage} >
            <h2>Categorias de Serviços</h2>
            <div className={styles.header}>
                <div className={styles.tabContainer}>
                    <Tabs
                        classes={{
                            indicator: styles.indicator,
                        }}
                        value={filter.main ? 0 : 1}
                        onChange={(ev, index) => setFilter({ main: index === 0 ? true : false })}
                    >
                        <Tab label="Principal" value={0} />
                        <Tab label="Sub-categorias" value={1} />
                    </Tabs>
                </div>
                <div className={styles.orderSwitchContainer}>
                    <Switch checked={enableReorder} onChange={(ev, value) => setEnableReorder(value)} /> Alterar ordem
                </div>
                <div className={styles.addButtonContainer}>
                    <Button variant={'contained'} color={'primary'} onClick={onClickAddButtonHandle}>Adicionar <AddCircle /></Button>
                </div>
            </div>
            <>
                <div className={styles.container} >
                    {
                        !categories ? "Carregando" :
                            <List
                                columnsConfigs={
                                    {
                                        imageUrl: { text: "" },
                                        name: { text: "Nome" },
                                    }
                                }
                                list={categories}
                                enableDnd={enableReorder}
                                onChangeDnd={onChangeOrderCategory}
                                menuButtons={[
                                    { label: "Editar", icon: "edit", onClick: (item) => { openEditModal(item.id) } },
                                    { label: "Excluir", icon: "close", onClick: (item) => { openDeleteModal(item) } },
                                    { label: "Vínculos", icon: "listAlt", onClick: (item) => { openBondsModal(item) } },
                                ]}
                            />}
                </div>
            </>


            {/* <Dialog open={openBonds} onClose={onClickCloseListLinks}>

                <ListLinks
                    value={mainCategories?.map(item => item.subCategories.map(item => ({id: item.id, name: item.name}))) ?? []}
                />
            </Dialog> */}


            <ListLinks value={selectedBond} open={openBonds} filter={filter.main} onClose={onClickCloseListLinks} />



            <Dialog open={openAdd} onClose={onClickCloseAddHandle}>
                <ServiceCategoryForm
                    onSubmit={onAddCategoryHandle}
                    onClickClose={onClickCloseAddHandle}
                    serviceMainCategories={mainCategories?.map(item => ({ id: item.id, description: item.name })) ?? []}
                />
            </Dialog>
            <Dialog open={!!selectedEdit} onClose={onClickCloseEditHandle}>
                <ServiceCategoryForm
                    defaultValues={selectedEdit}
                    onSubmit={onEditCategoryHandle}
                    onClickClose={onClickCloseEditHandle}
                    serviceMainCategories={mainCategories?.map(item => ({ id: item.id, description: item.name })) ?? []}
                />
            </Dialog>
            <Dialog open={!!selectedDelete} onClose={onClickCloseDeleteModal}>
                <DialogTitle>Excluir Categoria</DialogTitle>
                <DialogContent> Você tem certeza que deseja excluir a categoria {selectedDelete?.name}</DialogContent>
                <DialogActions className={styles.containerButtonDelete}>
                    <Button onClick={onClickCloseDeleteModal} variant="outlined">Cancelar</Button>
                    <Button onClick={onDeleteCategporyHandle} variant={'contained'}>Excluir</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default ServiceCategoriesPage