import { FC, useState } from "react";
import styles from "./PendingPosItem.module.scss";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Icon } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { format } from "date-fns";
import { IGetPendingExternalPosResponse, PendingExternalPosStatusEnum } from "modules/salesPos/domain/dto/IGetPendingExternalPosResponse";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import Button from "components/ui/Button/Button";

export interface IPendingPosItemProps {
    pos: IGetPendingExternalPosResponse;
    handleCompleteSolicitation: (solicitationId: string) => Promise<void>;
    isLoading: boolean;
}

const useStyles = makeStyles({
    root: {
        '&.MuiAccordion-root': {
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            "&::before": {
                display: "none",
            },
        },
        '&.MuiAccordion-root:first-of-type': {
            borderRadius: "10px",
        },
        '&.MuiAccordion-root:last-of-type': {
            borderRadius: "10px",
        },
    },
});

const PendingPosItem: FC<IPendingPosItemProps> = ({ pos, handleCompleteSolicitation, isLoading }) => {
    const [expand, setExpand] = useState(false);
    const accordionStyle = useStyles();

    return (
        <Accordion
            elevation={0}
            disableGutters
            expanded={expand}
            className={accordionStyle.root}
        >
            <AccordionSummary style={{ cursor: "default" }}>
                <div id={styles.PendingPosItem}>
                    <div className={styles.header}>
                        <div className={styles.start}>
                            <div className={styles.iconContainer} style={{ backgroundColor: pos.status === PendingExternalPosStatusEnum.Approved ? "#4DCE70" : "" }}>
                                <Icon className={styles.icon}>computer</Icon>
                            </div>
                            <span>{pos.nickname}</span>
                        </div>
                        <div className={styles.end}>
                            <span style={ pos.status === PendingExternalPosStatusEnum.Approved ? { backgroundColor: "#4DCE70", color: "#FFF" } : {} }>
                                {pos.status === PendingExternalPosStatusEnum.Approved ? "Pronto para download!" : "Aguardando liberação"}
                            </span>
                            <button onClick={() => setExpand(!expand)}>
                                {expand ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                    </div>
                    <div className={styles.operatorAndDevice}>
                        <div className={styles.posInfo}>
                            <span>Maquininha criada em: {format(new Date(pos.createdAt + "Z"), "dd/MM/yyyy' 'HH':'mm")}</span>
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.pendingPosContent}>
                    {pos.status === PendingExternalPosStatusEnum.Approved ? (
                        <>
                            {pos.acquirerType === AcquirerTypeEnum.Stone && (
                                <>
                                    <img src="/assets/icon/saas/stone-imported.png" alt=""/>
                                    <p>Para fazer o download siga o passo a passo:</p>
                                    <p>Vá em Ajustes {">"} Dispositivo {">"} Verificar atualizações.<br/> O download iniciará automaticamente.</p>
                                </>
                            )}
                            {pos.acquirerType === AcquirerTypeEnum.PagSeguro && (
                                <>
                                    <img src="/assets/icon/saas/pagseguro-imported.png" alt=""/>
                                    <p>Para fazer o download siga o passo a passo:</p>
                                    <p>Vá em Ajustes {">"} Dispositivo {">"} Verificar atualizações.<br/> O download iniciará automaticamente.</p>
                                </>
                            )}
                            {pos.acquirerType === AcquirerTypeEnum.GetNet && (
                                <>
                                    <img src="/assets/icon/saas/getnet-imported.png" alt=""/>
                                    <p>Para fazer o download siga o passo a passo:</p>
                                    <p>Vá em Ajustes {">"} Dispositivo {">"} Verificar atualizações.<br/> O download iniciará automaticamente.</p>
                                </>
                            )}
                            <p>Após isso, abra o aplicativo {"'Meep'"}.<br/> Clique no botão abaixo após abrir:</p>
                            <Button onClick={() => handleCompleteSolicitation(pos.id)}>
                                {isLoading ? <CircularProgress  size={16} color="inherit" /> : "Já abri o aplicativo!"}
                            </Button>
                            <span>Dúvidas? Chame nosso suporte no ícone!</span>
                        </>
                    ) : (
                        <>
                            <img src="/assets/img/clock.svg" alt=""/>
                            <p>Não se preocupe! Sua maquininha está em processo de liberação. Isso pode levar <b>até 2 dias úteis.</b></p>
                            <span>Dúvidas? Chame nosso suporte no ícone!</span>
                        </>
                    )}
                </div>
            </AccordionDetails>
        </Accordion >
    )
}
export default PendingPosItem