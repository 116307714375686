import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import styles from './ObservationForm.module.scss'
import { Button, Icon, IconButton, MenuItem } from '@material-ui/core'
import { InputLabel } from '../ui/InputLabel'
import { EnumObservationType } from 'modules/catalog/domain/enums/CatalogProductEnums'
import { v4 } from 'uuid'
import { IObservationProductFormValue } from './ObservationForm'



export interface IObservationFormProps {
    //propertys
    values: IObservationProductFormValue;
    onRemove?: (id: string) => void
    onChange: (values: IObservationProductFormValue) => void

}
const ObservationItemForm: FC<IObservationFormProps> = ({ values, onChange, onRemove }) => {



    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...values, [ev.target.name]: ev.target.value });
    }, [onChange, values]);




    const addOption = useCallback(() => {
        onChange({ ...values, options: [...values.options, { optionId: v4(), description: "" }] })
    }, [onChange, values])
    const removeOption = useCallback((id: string) => {
        onChange({ ...values, options: [...values.options.filter(item => item.optionId !== id)] })
    }, [onChange, values])
    const onChangeOption = useCallback((id: string, value: string) => {
        onChange({ ...values, options: values.options.map(item => item.optionId === id ? { ...item, description: value } : item) })
    }, [onChange, values])



    return (
        <div id={styles.ObservationItemForm} >
            {onRemove && <IconButton className={styles.closeButton} onClick={() => onRemove?.(values?.observationId)}><Icon>close</Icon></IconButton>}
            <div className={styles.container}>
                <InputLabel label="Descrição" name={"description"} value={values.description} onChange={changeHandle}></InputLabel>
                <InputLabel select label="Tipo" name={"type"} value={values.type} onChange={changeHandle}>
                    <MenuItem value={EnumObservationType.EXCLUSIVO}>Pode selecionar apenas um</MenuItem>
                    <MenuItem value={EnumObservationType.INCLUSIVO}>Pode selecionar vários</MenuItem>
                </InputLabel>
                <div className={styles.optionList}>
                    {values.options.map((item, index, array) => <div className={styles.option}>
                        {/* <b>Opção {index + 1}</b> */}
                        <div className={styles.row}>
                            <InputLabel
                                label={`Opção `+ (index + 1)}
                                name={"description"}
                                value={item.description}
                                onChange={(ev) => onChangeOption(item.optionId, ev.target.value)}
                            />
                            {array.length > 1 && <IconButton onClick={() => removeOption(item.optionId)}><Icon>close</Icon></IconButton>}
                        </div>
                    </div>)}
                </div>
                <Button style={{ width: 100 }} variant="outlined" onClick={addOption}><Icon>add</Icon>Opcão</Button>
            </div>
        </div>
    )
}
export default ObservationItemForm