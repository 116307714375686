import { FC, useCallback, useRef, useState } from "react";
import styles from "./CashCancellationPage.module.scss";
import FilterCashCancellation from "./components/filterCashCancellation/FilterCashCancellation";
import { IPostCashCancellationParams } from "../domain/dto/IPostCashCancellationParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { UseCashCancellationPage } from "./UseCashCancellationPage";
import AccordionCancellation from "./components/accordionCancellation/AccordionCancellation";
import { formatarValor } from "modules/contaDigital/presentation/pages/utils";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { Skeleton } from "components/skeleton/Skeleton";
import HeaderTitle from "./components/headerTitle/HeaderTitle";
import FooterTotal from "./components/footerTotal/FooterTotal";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const CashCancellationPage: FC = () => {
  const { currentLocal } = useLocal();
  const initialFilters = {
    dataInicio: null,
    dataFim: null,
    sessaoId: null,
    TiposDispositivos: [0],
    profileIds: null,
    TipoCancelamento: null,
    localClienteId: currentLocal?.id,
  };
  const [filters, setFilters] =
    useState<IPostCashCancellationParams>(initialFilters);
  const [openSidesheet, setOpenSidesheet] = useState<null | string>(null);
  const [step, setStep] = useState(1);
  const [optionDownload, setOptionDownload] = useState("xlsx");
  const { postCancellation, cancellationList } =
    UseCashCancellationPage();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true)
    const body = {
      ...filters,
      TiposDispositivos: filters.TiposDispositivos?.includes(0)
        ? null
        : filters.TiposDispositivos,
        shouldUseNewSchema: SystemIdentifierEnum.Saas,
    };
    postCancellation(body).then(() => {
      setLoading(false)
    })
  };

  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = useCallback(async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF({ orientation: "landscape", format: [800, 600] });
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = 1260;
    const padding = 20;

    const elements = pdfRef.current.querySelectorAll(".div-to-capture");
    const divs = Array.from(elements).filter(
      (element) => element instanceof HTMLElement
    );

    let currentHeight = 0;
    let divsByPage: Element[] = [];
    let pages: Element[] = [];

    for (const div of divs) {
      const { height } = div.getBoundingClientRect();

      if (currentHeight + height < pageHeight) {
        currentHeight += height;
        divsByPage.push(div);
      } else {
        const uniqueDiv = document.createElement("div");

        divsByPage.forEach((element) => {
          uniqueDiv.appendChild(element.cloneNode(true));
        });

        pages.push(uniqueDiv);
        currentHeight = height;
        divsByPage = [div];
      }
    }

    if (divsByPage.length) {
      const uniqueDiv = document.createElement("div");

      divsByPage.forEach((element) => {
        uniqueDiv.appendChild(element.cloneNode(true));
      });

      pages.push(uniqueDiv);
    }

    for (let i = 0; i < pages.length; i++) {
      const tempDiv = pages[i] as HTMLElement;
      tempDiv.style.width = "1915px";

      if (i === pages.length - 1) {
        const remainingHeight = pageHeight - currentHeight;
        tempDiv.style.height = `${remainingHeight}px`;
      }

      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        imgWidth - 2 * padding,
        imgHeight - 2 * padding
      );

      if (i !== pages.length - 1) {
        pdf.addPage();
      }

      document.body.removeChild(tempDiv);
    }

    const fileName = "relatorio_de_cancelamentos.pdf";
    pdf.save(fileName);
    setStep(2)
  }, [step]);

  const downloadXlsx = () => {
    if (cancellationList) {
      const produtosData = cancellationList?.Equipamentos.flatMap(
        (equipamento) =>
          equipamento.Produtos.map((produto, index) => ({
            Equipamento: index === 0 ? equipamento.Equipamento : "",
            Quantidade: index === 0 ? equipamento.Quantidade : "",
            ValorTotal: index === 0 ? equipamento.ValorTotal : 0, 
            Produto: produto.Produto,
            Observacao: produto.ObservacaoCancelamento,
            TipoCancelamento: produto.TipoCancelamento,
            Pagamento: produto.NomeTipoPagamento,
            OperadorVenda: produto.OperadorVenda,
            DataVenda: format(new Date(produto.DataVenda), "dd/MM/yy HH:mm"),
            OperadorCancelamento: produto.OperadorCancelamento,
            DataCancelamento: format(new Date(produto.DataCancelamento), "dd/MM/yy HH:mm"),
            Valor: produto.ValorUnitario, 
            QuantidadeProduto: produto.Quantidade,
            Total: produto.ValorTotal, 
          }))
      );
  
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(produtosData);
      const currencyFormat = '"R$" #,##0.00';
      const currencyColumns = ['C', 'L', 'N']; 
      currencyColumns.forEach(col => {
        for (let i = 2; i <= produtosData.length + 1; i++) {
          const cellRef = `${col}${i}`;
          if (worksheet[cellRef]) {
            worksheet[cellRef].z = currencyFormat; 
          }
        }
      });
  
      XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório de cancelamentos");
  
      const blob = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      const blobUrl = URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
  
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "relatorio_de_cancelamentos.xlsx");
      document.body.appendChild(link);
  
      link.click();
      document.body.removeChild(link);
  
      setStep(2);
    }
  };
  

  const onDownload = () => {
    if (optionDownload === "xlsx") {
      downloadXlsx();
    } else generatePDF();
  };

  const handleSubmitOrder = (name: string, value: boolean) => {
    const filtersSort = {
      ...filters,
      TiposDispositivos: filters.TiposDispositivos?.includes(0)
        ? null
        : filters.TiposDispositivos,
      Sort: {
        Type: name,
        Orientation: value ? "asc" : "desc",
      },
    };
    postCancellation(filtersSort);
  };

  return (
    <div id={styles.CashCancellationPage} ref={pdfRef}>
      <FilterCashCancellation
        setFilters={setFilters}
        filters={filters}
        handleSubmit={() => handleSubmit()}
        setOpenSidesheet={setOpenSidesheet}
      />
      {loading && (
        <div className={styles.skeleton}>
          <Skeleton items={5} cardStyle={styles.cardsSkeleton} />
        </div>
      )}
      {cancellationList && !loading && (
        <>
          {cancellationList?.Equipamentos.length > 0 ? (
            <div
              className={styles.table}
            >
                <div className="div-to-capture">
                <HeaderTitle/>
              </div>
              {cancellationList?.Equipamentos.map((item, index) => {
                return (
                  <div className="div-to-capture">
                    <AccordionCancellation
                      index={index}
                      item={item}
                      handleSubmit={handleSubmitOrder}

                    />
                  </div>
                );
              })}
              <div className="div-to-capture">
               <FooterTotal cancellationList={cancellationList}/>
              </div>
            </div>
          ) : (
            <div className={styles.notFound}>
              <img
                src="/assets/img/not-found.png"
                alt="Nenhuma informação encontrada!"
              />
              <p>Nenhuma informação encontrada!</p>
              <p>Filtre um período ou um caixa.</p>
            </div>
          )}
        </>
      )
      }

      <Sidesheet
        open={openSidesheet}
        onClose={() => {
          setOpenSidesheet(null);
          setStep(1);
        }}
        title={<h1>Exportar</h1>}
        content={
          <div>
            <p>Selecione abaixo uma opção para exportar o relatório</p>
            <RadioGroup
              onChange={(_, value) => setOptionDownload(value)}
              value={optionDownload}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              <div className={styles.radioItem}>
                <FormControlLabel
                  value={`xlsx`}
                  control={<Radio />}
                  label="XLSX"
                />
              </div>
              <div className={styles.radioItem}>
                <FormControlLabel
                  value={`pdf`}
                  control={<Radio />}
                  label="PDF"
                />
              </div>
            </RadioGroup>
          </div>
        }
        cancelButton
        continueButton={"Exportar"}
        handleContinueButton={onDownload}
        currentStep={step}
        totalSteps={2}
        feedback={
          <SidesheetFeedback text="Relatório exportado com sucesso!" success />
        }
      />
    </div >
  );
};

export default CashCancellationPage;
