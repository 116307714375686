import React, { useCallback, useEffect, useState } from 'react'
import { IDiscountCouponFilter } from './components/discountCouponFilter/interfaces/IDiscountCouponFilter';
import UseAddCoupon from './hooks/UseAddCoupon';
import UseDiscountCouponsList, { IParamsClientDiscountCouponsList } from './hooks/UseDiscountCouponsList';
import { ICouponForm } from './interfaces/ICouponForm';

export const UseDiscountCouponsPage = () => {

    const { getDiscountCouponsList, discountCouponsList, isLoading } = UseDiscountCouponsList();


    const [params, setParams] = useState<IParamsClientDiscountCouponsList>({
        sort: { orientation: 'asc', type: 'code' }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IDiscountCouponFilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getDiscountCouponsList({ ...params });
    }, [getDiscountCouponsList, params]);


    return (
        {
            discountCouponsList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getDiscountCouponsList
        }
    )
}
