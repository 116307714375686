import { IGetTIDsListResponse } from "modules/tidsAdicionais/domain/dto/IGetTIDsListResponse";
import { api } from "../Api";
import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";
import { IPostAddTIDsRequest } from "modules/tidsAdicionais/domain/dto/IPostAddTIDsRequest";
import { IPostValidationTIDsRequest } from "modules/aprovacaoTIDs/domain/dto/IPostValidationTIDsRequest";

const ComplienceService = (): IComplienceService => {
  const getLatestAdditionalTids = async (): Promise<IGetTIDsListResponse[]> => {
    const response = await api.get(
      "/MPPagamentosCompliance/GetLatestAdditionalTids"
    );
    return response.data;
  };

  const createAdditionalTids = async (
    payload: IPostAddTIDsRequest
  ): Promise<void> => {
    const result = await api.post<void>(
      `/MPPagamentosCompliance/CreateAdditionalTids`,
      payload
    );
    return result.data;
  };

  const validateAdditionalTids = async (
    payload: IPostValidationTIDsRequest
  ): Promise<void> => {
    const result = await api.post<void>(
      `/MPPagamentosCompliance/ValidateAdditionalTids`,
      payload
    );
    return result.data;
  };

  const cancelTIDsSolicitation = async (
    id: string
  ): Promise<void> => {
    const result = await api.post<void>(
      `/MPPagamentosCompliance/CancelAdditionalTids/${id}`,
    );
    return result.data;
  };

  const approveTIDsSolicitation = async (
    id: string
  ): Promise<void> => {
    const result = await api.post<void>(
      `/MPPagamentosCompliance/ApproveAdditionalTids/${id}`,
    );
    return result.data;
  };

  const rejectTIDsSolicitation = async (
    id: string
  ): Promise<void> => {
    const result = await api.post<void>(
      `/MPPagamentosCompliance/RejectAdditionalTids/${id}`,
    );
    return result.data;
  };

  return {
    createAdditionalTids,
    getLatestAdditionalTids,
    cancelTIDsSolicitation,
    validateAdditionalTids,
    approveTIDsSolicitation,
    rejectTIDsSolicitation
  };
};

export default ComplienceService;
