import { IPostNewPrinterRequest } from "../../domain/dtos/IPostNewPrinterRequest";
import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const PostNewPrinterUseCase = async (smartPrintersApi: ISmartPrinterApiCB, request: IPostNewPrinterRequest):Promise<void> => {
  const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
  const response = await _smartPrintersApi.postNewPrinter(request);
  return response
}

export default PostNewPrinterUseCase;
