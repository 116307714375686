import { useCallback, useEffect, useState } from "react";
import UsePaymentLinkReportList, { IParamsPaymentLinkList } from "../hooks/UsePaymentLinkReportList";
import { IPaymentLinkRecurrencefilter } from "../components/paymentLinkRecurrenceFilter/interfaces/IPaymentLinkRecurrencefilter";
import UsePaymentLinkProductsList from "../hooks/UsePaymentLinkProductsList";


export const UsePaymentLinkReportPage = () => {

    const { getPaymentLinkRecurrenceList, paymentLinkRecurrenceList, isLoading } = UsePaymentLinkReportList();

    const {getPaymentLinkProductsList, productList} = UsePaymentLinkProductsList();



    const [params, setParams] = useState<IParamsPaymentLinkList>({
        sort: { orientation: 'asc', type: 'description' }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IPaymentLinkRecurrencefilter) => {
        setParams((prev) => ({ ...prev, filter: {...filter, value: filter.value === 0 ? undefined : filter.value}, pagination: { page: 0, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getPaymentLinkRecurrenceList({ ...params });
    }, [getPaymentLinkRecurrenceList, params]);

    useEffect(() => {
        getPaymentLinkProductsList();
    }, [getPaymentLinkProductsList]);


    return (
        {
            paymentLinkRecurrenceList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getPaymentLinkRecurrenceList,
            productList
        }
    )
}
