import styles from "components/sidesheet/Sidesheet.module.scss";
import stylesColaborador from "./NewColaborador.module.scss";
import Input from "components/ui/input/Input";
import { Autocomplete, TextField } from "@mui/material";
import { INewCollaboratorRequest } from "modules/colaboradores/domain/dto/INewCollaboratorRequest";
import { INewCollaboratorFormErrors } from "../validade/NewCollaboratorValidation";
import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";
import { useEffect, useMemo } from "react";
import Checkbox from 'components/ui/checkbox/Checkbox';
import { Search } from "@mui/icons-material";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { formatCPF } from "modules/config/discountCoupons/presentation/components/couponForm/components/segmentForm/segmentForm";

export interface INewCollaboratorProps {
  open: "isNew" | "isEditing" | "isDeleting" | null;
  values: INewCollaboratorRequest;
  setValues: React.Dispatch<React.SetStateAction<INewCollaboratorRequest>>;
  errors: INewCollaboratorFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<INewCollaboratorFormErrors>>;
  allRolesList: IGetAllRolesResponse[];
  step: number
  success: boolean
  onSearch: (e: string) => void
  cargoChecked?: string
}

const NewCollaborator = ({ cargoChecked, open, values, setValues, errors, setErrors, allRolesList, step, success, onSearch }: INewCollaboratorProps) => {
  const rolesList = useMemo(() => allRolesList ?? [], [allRolesList]);
  useEffect(() => {
    if(cargoChecked){
      setValues(prevValues => ({
        ...prevValues,
        ProfileIds: [...prevValues.ProfileIds, cargoChecked],
      }));
    }

  },[cargoChecked])

  return (
    <>
      {step === 1 && (
        <div>
          <label htmlFor="email" className={styles.required}>
            Email
          </label>
          <Input
            name="email"
            variant={"outlined"}
            placeholder="E-mail"
            value={values.EmployeeEmail ?? ""}
            onChange={(ev) => {
              setErrors({ ...errors, EmployeeEmail: "" });
              setValues({ ...values, EmployeeEmail: ev.target.value });
            }}
            error={!!errors.EmployeeEmail}
            helperText={errors.EmployeeEmail}
          />
        </div>
      )}

      {step === 2 && success && (
        <>
          <div className={stylesColaborador.inputDisabled}>
            <label htmlFor="email" className={styles.required}>
              Email
            </label>
            <Input
              name="email"
              variant={"outlined"}
              placeholder="E-mail"
              value={values.EmployeeEmail ?? ""}
              disabled={true}
            />
          </div>
          <div>
            <label htmlFor="role" >
              Selecione os cargos do seu colaborador
            </label>
            <Input
              variant={"outlined"}
              placeholder="Pesquisar por cargo"
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                endAdornment: <Search fontSize="small" />,
              }}
            />
            {rolesList.map(item => {
              return (
                <div className={stylesColaborador.optionCheckbox}>
                  <Checkbox
                    label={item.Name}
                    checked={values.ProfileIds.includes(item.Id) ? true : false}
                    onChange={(_ev, checked) => {
                      const { Id } = item;
                      if (checked) {
                        setValues(prevValues => ({
                          ...prevValues,
                          ProfileIds: [...prevValues.ProfileIds, Id],
                        }));
                      } else {
                        setValues(prevValues => ({
                          ...prevValues,
                          ProfileIds: prevValues.ProfileIds.filter(profileId => profileId !== Id),
                        }));
                      }
                    }}
                  />
                </div>
              )
            })}

          </div>
        </>
      )}

      {step === 2 && !success && (
        <>
          <SidesheetFeedback
            text="Este colaborador ainda não é usuário Meep.
              Realize o cadastro."
          />
          <div className={stylesColaborador.inputDisabled}>
            <label htmlFor="email" className={styles.required}>
              Email
            </label>
            <Input
              name="email"
              variant={"outlined"}
              placeholder="E-mail"
              value={values.EmployeeEmail ?? ""}
              disabled={true}
            />
            <span className={stylesColaborador.info}>Para acessar, o usuário receberá um e-mail com uma senha aleatória.</span>
          </div>
          <div>
            <label htmlFor="role" className={styles.required} >
              Nome
            </label>
            <Input
              name='nome'
              variant={"outlined"}
              placeholder="Nome e sobrenome"
              value={values.Nome ?? ""}
              onChange={(ev) => {
                setErrors({ ...errors, Nome: "" });
                setValues({ ...values, Nome: ev.target.value });
              }}
              error={!!errors.Nome}
              helperText={errors.Nome}
            />
            </div>
            <div>
              <label htmlFor="role" className={styles.required} >
                CPF
              </label>
              <Input
                name='cpf'
                variant={"outlined"}
                placeholder="CPF"
                value={values.Cpf && (formatCPF(values.Cpf) ?? "")}
                onChange={(ev) => {
                  setErrors({ ...errors, Cpf: "" });
                  setValues({ ...values, Cpf: ev.target.value });
                }}
                error={!!errors.Cpf}
                helperText={errors.Cpf}
                inputProps={{ maxLength: 11 }}
              />
            </div>
            <div>
              <label htmlFor="role" className={styles.required}>
                Cargo
              </label>
              <Autocomplete
                disablePortal
                options={rolesList}
                getOptionLabel={option => option.Name}
                noOptionsText="Nenhuma opção encontrada"
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Filtrar pelo cargo"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={!!errors.ProfileIds}
                    helperText={errors.ProfileIds}
                  />
                )}
                onChange={(_ev, autocompleteValues) => {
                  setErrors({ ...errors, ProfileIds: "" });
                  const rolesIds = autocompleteValues.map((it) => it.Id);
                  setValues({ ...values, ProfileIds: rolesIds });
                }}
              />
            </div>
        </>
      )}
    </>
  );
};

export default NewCollaborator;
