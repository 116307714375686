import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const DoPreCheckinUseCase = async (
  guestsService: IEventsGuestsApi,
  guestId: string,
  localId: string,
): Promise<void> => {
  return await guestsService.doPreCheckin(guestId, localId);
};

export default DoPreCheckinUseCase;
