import { useEffect, useState } from "react"
import { ICustomerInvoices } from "../../interfaces/ICustomerInvoices"
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetCustomerInvoicesUseCase } from "modules/plans/application/GetCustomerInvoicesUseCase";
import PlansApi from "services/api/plans/PlansApi";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const service = PlansApi();

export const UsePlanPaymentHistory = () => {
    const [customerInvoices, setCustomerInvoices] = useState<ICustomerInvoices[]>([]);
    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    const response = await GetCustomerInvoicesUseCase(service, currentLocal.id);
                    setCustomerInvoices(response);
                } catch {
                    toast('Ocorreu um erro ao buscar o histórico do local. Tente novamente', 'error');
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [currentLocal, toast]);
    
    return {
        customerInvoices,
        loading,
    }
}