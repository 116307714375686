import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Input from 'components/ui/input/Input';
import React, { useCallback, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money';
import Utils from 'services/utils/Utils';
import { IPendingItems } from '../../../../ICloseEvent';
import { IClosePedingFormErros } from '../../UseSystemTypeResume';
import styles from './CardSettlePending.module.scss';

interface ICardSettlePendingProps {
    pendingItems: IPendingItems;    
    onChangePedingCard: (id: string, text: string) => void;
    errors: IClosePedingFormErros[];
}


export const CardSettlePending: React.FC<ICardSettlePendingProps> = ({ pendingItems, onChangePedingCard, errors }) => {


    const [onDropDown, setOnDropDown] = useState<boolean>();

    const openObservation = () => {
        setOnDropDown(!onDropDown)
    }


    return (
        <div id={styles.CardSettlePending}>
            <div>
                <h3>{pendingItems.number}</h3>
                <h3>{moneyMaskNumber(pendingItems.amount)}</h3>
                <h3>{Utils.toDateAndTime(`${pendingItems.activatedIn}Z`)}</h3>
                <div onClick={openObservation}>
                    {
                        onDropDown ?
                            (
                                <KeyboardArrowUp />
                                ) : (
                                <KeyboardArrowDown />
                            )
                    }
                </div>
            </div>
            <div
                className={
                    onDropDown ? styles.showCard : styles.hideCard
                }
            >
                <span>Observação*</span>
                <Input
                    variant='outlined'
                    placeholder='Insira aqui sua observação'
                    value={pendingItems.observation}
                    required
                    onChange={(ev) => onChangePedingCard(pendingItems.historicoAssociacaoId, ev.target.value)}
                    inputProps={{ maxLength: 100 }}
                    error={!!errors.find(item => item.historicoAssociacaoId === pendingItems.historicoAssociacaoId)?.observation}
                    helperText={errors.find(item => item.historicoAssociacaoId === pendingItems.historicoAssociacaoId)?.observation}
                />
            </div>
        </div>
    )
}
