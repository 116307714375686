import { IGetRecoverDiscountCouponResponse } from "../../domain/dto/IGetRecoverDiscountCouponResponse";
import { IGetReportOrderResponse } from "../../domain/dto/IGetReportOrderResponse";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";


const GetReportOrderUseCase = async (service: IDiscountCouponsService, localId: string, orderId: string, pagination?: { page?: number, pageSize?: number }): Promise<IGetReportOrderResponse> => {

    const response = await service.getReportOrder(localId, orderId, pagination)
    return (response)
}
export default GetReportOrderUseCase