import { IGetYearResumePerMonthResponse } from "../../dtos/IGetYearResumePerMonthResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetYearResumePerMonthUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetYearResumePerMonthResponse> => {
  return await service.getYearResumePerMonth(localId, cashierId, day);
};

export default GetYearResumePerMonthUseCase;
