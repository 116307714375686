import { Icon } from "@mui/material";
import styles from "./TableItem.module.scss";
import { ISystemMenus } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigResponse";

interface ITableItemProps {
  item: ISystemMenus;
}

const TableItem = ({ item }: ITableItemProps) => {
  return (
    <div id={styles.TableItem} >
      <div>
        {<Icon fontSize="medium">{item.icon}</Icon>}
        <span>{item.title}</span>
      </div>
      <span>Menu pai</span>
    </div>
  );
};
export default TableItem;
