import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './CatalogForm.module.scss'
import ButtonsForm from '../ui/ButtonsForm'
import { InputLabel } from '../ui/InputLabel'
import ProductItemFormToCatalogList, { ICatalogListProductItemFormValue } from './productItemFormToCatalogList/_ProductItemFormToCatalogList'
import DnDList from '../ui/DnDList'
import Column from '../ui/Column'
import { IProductItem } from '../categoryAndProducts/productList/IProductItem'
import SelectProductPicker from './selectProduct/SelectProductPicker'
import { useHistory, useParams } from 'react-router-dom'
import UseQueryParams from '../../hooks/UseQueryParams'
import { TextField } from '@mui/material'
import { PercentOutlined } from '@mui/icons-material'
import Button from 'components/ui/Button/Button'
import { Icon } from '@material-ui/core'
import TabView from 'components/tab/TabView'



export interface ICatalogFormValue {
    id?: string,
    description: string
    showInApp: boolean
    showInWebApp: boolean
    products: ICatalogListProductItemFormValue[];

}
export interface ICatalogListFormProps {
    //propertys
    defaultValues?: ICatalogFormValue
    products: IProductItem[]
    onClose: () => void
    onSubmit: (values: ICatalogFormValue) => Promise<void>
    isLoadingProducts: boolean
    onChangeStep: (step: "detail" | "discount" | "products") => void
}
const CatalogForm: FC<ICatalogListFormProps> = ({ defaultValues, onClose, onSubmit, products, isLoadingProducts, onChangeStep }) => {

    const [step, setStep] = useState<"detail" | "discount" | "products">("detail");
    const [direct, setDirect] = useState(false);
    const { catalogId } = useParams<{ catalogId?: string }>();
    const { push } = useHistory();

    const queryParams = UseQueryParams()

    const [values, setValues] = useState<ICatalogFormValue>({
        description: "",
        showInApp: false,
        showInWebApp: false,
        products: [],
    });

    const [discountInPercentToAll, setDiscountInPercentInAll] = useState<number | null>(null);



    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues)
        }
    }, [defaultValues])

    useEffect(() => {
        const step = queryParams.get("step") as "detail" | "discount" | "products" ?? "detail"
        const direct = Boolean(queryParams.get("direct")) ?? false;
        onChangeStep(step);
        setDirect(direct);
        setStep(step)
    }, [onChangeStep, queryParams])


    const submitHandle = useCallback(async (ev?: FormEvent<HTMLFormElement>) => {
        ev?.preventDefault();
        await onSubmit(values);
    }, [onSubmit, values])

    const onClickRemoveHandler = useCallback((values: ICatalogListProductItemFormValue) => {
        setValues(prev => ({ ...prev, products: prev.products.filter(item => item.id !== values.id) }))
    }, [])
    const onChangeProductHandler = useCallback((productValue: ICatalogListProductItemFormValue) => {
        setValues(prev => ({ ...prev, products: prev.products.map(item => item.id === productValue.id ? productValue : item) }))
    }, [])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const renderItem = useCallback((item: ICatalogListProductItemFormValue) => (<ProductItemFormToCatalogList
        values={item}
        onChangeValues={onChangeProductHandler}
        onClickRemove={onClickRemoveHandler} />),
        [onChangeProductHandler, onClickRemoveHandler]
    )


    const onChangeOrder = (values: ICatalogListProductItemFormValue[]) => {
        setValues(prev => ({ ...prev, products: values }))
    }


    const changeStep = useCallback((step: "detail" | "discount" | "products") => {
        onChangeStep(step)
        push(`/private/catalog/${catalogId}?action=${defaultValues ? "editCatalog" : "addCatalog"}&step=${step}`)
    }, [catalogId, defaultValues, onChangeStep, push])


    const onCloseProoductPickerHandler = useCallback(() => {
        // direct ? onClose() : changeStep("discount");
        direct ? onClose() : changeStep("detail");
    }, [changeStep, direct, onClose])

    const onSubmitProductSelect = useCallback((products: IProductItem[]) => {
        var _values = {
            ...values,
            products: products.map<ICatalogListProductItemFormValue>(product => ({
                id: product.id,
                name: product.name,
                imageUrl: product.imageUrl,
                value: product.value,
            }))
        }
        onSubmit(_values);
    }, [onSubmit, values])


    const onChangeDiscountInPercentInAllHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        let _valueInPercent = Number(ev.target.value);
        _valueInPercent = _valueInPercent > 100 ? 100 : (_valueInPercent < 0 ? 0 : _valueInPercent);
        setDiscountInPercentInAll(_valueInPercent);
    }, []);

    const onClickDiscountInPercentInAll = useCallback(() => {
        if (discountInPercentToAll) {
            setValues(prev => ({
                ...prev,
                products: prev.products.map<ICatalogListProductItemFormValue>(product => ({
                    ...product,
                    discount: discountInPercentToAll
                }))
            }))
            setDiscountInPercentInAll(null);
        }
    }, [discountInPercentToAll])

    const onClickRemoveAllDiscountInPercentIn = useCallback(() => {
        setValues(prev => ({
            ...prev,
            products: prev.products.map<ICatalogListProductItemFormValue>(product => ({
                ...product,
                discount: undefined
            }))
        }))
        setDiscountInPercentInAll(null);

    }, [])



    const onSubmitDetail = useCallback((ev?: FormEvent<HTMLFormElement>) => {
        ev?.preventDefault();
        changeStep('products')
    }, [changeStep])

    const handleTabIndex = useMemo(() => {
        if (step === "detail") return 0;
        if (step === "products") return 1;
    }, [step]);

    return (
        <>

            <div className={styles.tabsContainer}>
                {/* {!direct && <Tabs value={step} disabled style={{ marginBottom: "1rem", paddingBottom: "16px" }} >
                    <Tab value={"detail"} label={"Detalhes"}></Tab> */}
                    {/* <Tab value={"discount"} label={"Desconto"}></Tab> */}
                    {/* <Tab value={"products"} label={"Produtos"}></Tab>
                </Tabs>} */}
                {!direct && (
                    <TabView
                            index={handleTabIndex}
                            tabs={[
                            {
                                label: "Detalhes",
                                value: "detail",
                            },
                            {
                                label: "Produtos",
                                value: "products",
                            },
                            ]}
                            // onChangeTab={(tab) => setCurrentStep(tab.value + 1)}
                        />
                )}
            </div>
            {step === "detail" &&
                <form id={styles.CatalogForm} onSubmit={onSubmitDetail}>
                    <Column>
                        <InputLabel
                            required
                            inputProps={{ maxLength: 32 }}
                            label={"Nome do cardápio"}
                            name="description"
                            value={values?.description}
                            onChange={changeHandle}
                            gap={0}
                            placeholder="Ex.: Cardápio de almoço"
                            styledSize
                        />
                        <div className={styles.buttonContainer}>
                            <ButtonsForm submitLabel={defaultValues ? "Salvar" : "Criar"} onCancel={onClose} color='primary' width="100px" ></ButtonsForm>
                        </div>
                        {/* <div className={styles.scrollview}> */}
                    </Column>
                </form>
            }
            {
                step === "discount" &&
                <form id={styles.CatalogForm} onSubmit={submitHandle}>
                    <Column>
                        <span>Digite a porcentagem de desconto que deseja aplicar a todos os produtos do seu cardápio. Se preferir, aplique em cada produto ou diretamente em valor.</span>
                        {values.products.length > 1 && 
                            <Column>
                                <div className={styles.headerInput}>
                                    <div className={styles.percent}>
                                        <PercentOutlined fontSize='small' />
                                    </div>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        type='number'
                                        fullWidth
                                        value={discountInPercentToAll}
                                        onChange={onChangeDiscountInPercentInAllHandle}
                                    />
                                </div>
                                <Button color='primary' fullWidth onClick={onClickDiscountInPercentInAll} variant='outlined' type='button'>Aplicar desconto em todos</Button>
                                <Button color='primary' fullWidth onClick={onClickRemoveAllDiscountInPercentIn} variant='text' type='button'>Remover todos os descontos</Button>
                            </Column>
                        }
                        {!values?.products.length ? <div className={styles.noProducts}>
                            <Icon>error</Icon>
                            nenhum produto adicionado
                        </div> : <DnDList
                            isDragInDropDisabled
                            list={values?.products ?? []}
                            renderItem={(item) => renderItem(item)}
                            onChangeOrder={onChangeOrder} />}
                        {/* </div> */}
                        <div className={styles.buttonContainer} style={{ padding: "16px 0 32px"}}>
                            {/* <Button variant='outlined' color='primary' onClick={() => changeStep("products")}>
                                Adicionar Produto
                            </Button> */}
                            <ButtonsForm submitLabel='Salvar' onCancel={onClose} color='primary' width="100px"></ButtonsForm>
                        </div>
                    </Column>
                </form >
            }

            {
                step === "products" &&
                <SelectProductPicker
                    isLoading={isLoadingProducts}
                    onClose={onCloseProoductPickerHandler}
                    products={products}
                    defautlValue={values.products}
                    onSubmit={onSubmitProductSelect}
                />
            }
            {/* <Drawer anchor='right' open={step === "products"} onClose={onCloseProoductPickerHandler} hideBackdrop={direct}>
                <ContainerDrawer title={<div>Selecionar<b> produto</b></div>} onClose={onCloseProoductPickerHandler}>
                    <SelectProductPicker
                        isLoading={isLoadingProducts}
                        onClose={onCloseProoductPickerHandler}
                        products={products}
                        defautlValue={values.products}
                        onSubmit={onSubmitProductSelect}
                    />
                </ContainerDrawer>
            </Drawer> */}
        </>
    )
}
export default CatalogForm