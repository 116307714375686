import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { ExpandMore, ImportExport } from "@mui/icons-material";
import styles from "./AccordionCancellation.module.scss";
import { useState } from "react";
import { ICashCancellationEquipamento } from "modules/cashCancellation/domain/dto/IPostCashCancellationResponse";
import { formatarValor } from "modules/contaDigital/presentation/pages/utils";

interface IAccordionItem {
  index: number;
  item: ICashCancellationEquipamento;
  handleSubmit: (name: string, value: boolean) => void;
}

type ValuesType = {
  [key: string]: boolean | undefined;
};

const titleHeader = [
  {
    label: "Produto",
    value: "produto",
  },
  {
    label: "Observação",
    value: "observacaocancelamento",
  },
  {
    label: "Tipo de cancelamento",
    value: "tipocancelamento",
  },
  {
    label: "Pagamento",
    value: "tipopagamento",
  },
  {
    label: "Venda",
    value: "datavenda",
  },
  {
    label: "Cancelamento",
    value: "datacancelamento",
  },
  {
    label: "Valor",
    value: "valorunitario",
  },
  {
    label: "Quantidade",
    value: "quantidade",
  },
  {
    label: "Total",
    value: "valortotal",
  },
];



const AccordionCancellation = ({
  index,
  item,
  handleSubmit,
}: IAccordionItem) => {
  const [expanded, setExpanded] = useState<number | null>(null);
  const [valuesOrder, setValuesOrder] = useState<ValuesType>({});

  const useStyles = makeStyles(() => ({
    accordion: {
      border: "1px solid #E0E0E0",
      boxShadow: "none",
      borderRadius: "16px !important",
    },
    accordionSumary: {
      margin: "0 7px 0 0",
      color: "#5f5f5f",
      padding: "0",
    },
    accordionDetails: {
      padding: "0 23px 16px",
      flexDirection: "column",
    },
    customTooltip: {
      backgroundColor: "white",
      color: "#5F5F5F",
      boxShadow: "0px 4px 12px 0px #0000001A",
    },
  }));

  const stylesMui = useStyles();

  const handleAccordionClick = (index: number) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  const handleImportExportClick = (
    item: string,
    value: boolean | undefined
  ) => {
    const newValue = !value;
    setValuesOrder((prevValues) => ({ ...prevValues, [item]: newValue }));
    handleSubmit(item, newValue);
  };

  return (
    <div id={styles.AccordionItem}>
      <Accordion expanded={expanded === index} className={stylesMui.accordion}>
        <AccordionSummary
          className={stylesMui.accordionSumary}
          expandIcon={
            <ExpandMore onClick={() => handleAccordionClick(index)} />
          }
        >
          <div className={styles.containerTitle}>
            <span>{item.Equipamento}</span>
            <span> {item.Quantidade}</span>
            <span>{formatarValor(item.ValorTotal)}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className={stylesMui.accordionDetails}>
          <div className={styles.details}>
            <div className={styles.table}>
              <div className={styles.tableHeader}>
                {titleHeader.map((item, index) => {
                  return (
                    <div key={index} className={styles.container}>
                      {item.label}
                      <ImportExport
                        onClick={() =>
                          handleImportExportClick(item.value, valuesOrder[item.value])
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <div className={styles.tableBody}>
                {item.Produtos.map((produto) => {
                  return (
                    <div className={styles.lineBody}>
                      <div className={styles.item}>{produto.Produto}</div>
                      <div className={styles.item}>
                        {produto.ObservacaoCancelamento}
                      </div>
                      <div className={styles.item}>
                        {produto.TipoCancelamento}
                      </div>
                      <div className={styles.item}>
                        {produto.NomeTipoPagamento}
                      </div>
                      <div className={styles.item}>
                        <b>Operador: </b>
                        {produto.OperadorVenda}
                        <br />
                        <b>Data: </b>
                        {format(new Date(produto.DataVenda), "dd/MM/yy HH:mm")}
                      </div>
                      <div className={styles.item}>
                        <b>Operador: </b>
                        {produto.OperadorCancelamento}
                        <br />
                        <b>Data: </b>
                        {format(
                          new Date(produto.DataCancelamento),
                          "dd/MM/yy HH:mm"
                        )}
                      </div>
                      <div className={styles.item}>
                        {formatarValor(produto.ValorUnitario)}
                      </div>
                      <div className={styles.item}>{produto.Quantidade}</div>
                      <div className={styles.item}>
                        {formatarValor(produto.ValorTotal)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionCancellation;
