import { IconButton } from "@mui/material";
import styles from "./TutorialNotification.module.scss";
import { Close } from "@material-ui/icons";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";

interface ITutorialNotificationProps {
    tutorial: ITutoriaisRecords;
    onClose: () => void;
}

const TutorialNotification = ({ tutorial, onClose }: ITutorialNotificationProps) => {
    return (
        <div id={styles.TutorialNotification}>
            <img src="/assets/icon/open-book-emoji.svg" alt="" style={{ width: "21px" }} />
            <span>
                Em caso de dúvidas, acesse mais informações {" "}
                <a href={tutorial.fullTutorialUrl ? tutorial.fullTutorialUrl : "/private/tutoriais"} target="_blank" rel="noreferrer">
                    clicando aqui!
                </a>
            </span>
            <IconButton size="small" onClick={onClose}><Close fontSize="small" /></IconButton>
        </div>
    )
}

export default TutorialNotification;