import { IComplementProductResponse, IGetCatalogProductResponse, IObservationProductResponse, IOpcoesResponse, IProductComplementCategoryResponse, IProductComplementResponse } from "modules/catalog/domain/dtos/IGetCatalogProductResponse"
import { ICompositionModel, ProdutoModel } from "./produto/produtoModel"
import { v4 } from "uuid"
import { ProdutoItem, ProdutoItemFiltro } from "./produto/ProdutoLista"
import { Categoria } from "./produto/Categoria"
import AntiCorruptionCatalogService from "./AntiCorruptionCatalogService"
import { Api, api } from "services/api/Api"

type ProductWithCategoryId = ICompositionModel & { categoriaId: string }


const ProdutoToProduct = async (localId: string, produto: ProdutoModel,  categorias: Categoria[]): Promise<IGetCatalogProductResponse> => {

    const compositionsWithCategoryIdPromise = produto.composicao.map<Promise<ProductWithCategoryId>>(async (composicao: ICompositionModel) => {
        var product = await AntiCorruptionCatalogService(api).GetProduto(localId, composicao.produtoId)

        var response: ProductWithCategoryId = {
            ...composicao,
            categoriaId: product.categoryId ?? "",
        }
        return response;
    })

    var compositionsWithCategoryId = await Promise.all(compositionsWithCategoryIdPromise)

    const groupedByCategoryId: { [key: string]: ProductWithCategoryId[] } = {};

    compositionsWithCategoryId?.forEach(item => {
        const { categoriaId }: { categoriaId: string } = item;
        if (!groupedByCategoryId[categoriaId]) {
            groupedByCategoryId[categoriaId] = [];
        }
        groupedByCategoryId[categoriaId].push(item);
    });

    const complement = Object.keys(groupedByCategoryId).map<IProductComplementResponse>((categoriaId) => {

        const categoria = categorias.find(item => item.id === categoriaId)!;
        const produtos = groupedByCategoryId[categoriaId].map<IComplementProductResponse>(additionalProduct => ({
            //Complement
            id: additionalProduct.id,
            value: additionalProduct.produtoValor,
            type: additionalProduct.tipo,
            quantity: additionalProduct.quantidade,
            min: additionalProduct.minimo,
            max: additionalProduct.maximo,
            freeQuantity: additionalProduct.quantidadeAdicionaisGratuito,
            //product
            name: additionalProduct.produtoNome,
            productValue: additionalProduct.produtoValor,
            productId: additionalProduct.produtoId,
            imageUrl: `${process.env.REACT_APP_URL_IMAGEM}${localId}/${additionalProduct.produtoId}.jpg?v=4`,
            ncm: "",
            cfop: "",
            cest: "",
        }))
        const response: IProductComplementResponse = {
            complementCategory: {
                id: categoria?.id,
                categoryId: categoria?.id, //Edit ProductCategory
                description: categoria?.descricao,
                min: categoria?.minimoComposicaoProduto,
                max: categoria?.maximoComposicaoProduto,
                freeQuantity: 0,
            },
            complementProducts: produtos
        }
        return response;
    });

    const observations = produto.observacoes?.map<IObservationProductResponse>((observacao) => ({
        id: v4(), // original name: id
        description: observacao.descricao, // original name: descricao
        type: observacao.tipo,// original name: tipo
        isReserved: observacao.ehDeReserva, // original name: ehDeReserva
        options: observacao.opcoes.map<IOpcoesResponse>(opcao => ({
            id: opcao.id, // original name: id
            description: opcao.descricao, // original name: descricao
            optionId: v4(),
        })),
        observationId: v4(),
    }))

    const product: IGetCatalogProductResponse = {
        id: produto.id,
        categoryId: produto.categoriaLocalClienteId,


        //detail
        name: produto.nome,
        productInformation: produto.informacaoProduto,
        type: produto.tipo,
        value: produto.valor,
        enabled: produto.ativado,

        // image: "",
        imageUrl: `${process.env.REACT_APP_URL_IMAGEM}${localId}/${produto.id}.jpg?v=4`,


        //indetification
        internalCode: produto.codigoInterno,
        integrationCode: produto.codigoIntegracao,
        barcode: produto.codigoBarra,


        //advance
        defaultPrinterId: produto.impressoraPadraoId,
        genderRestriction: produto.restricaoPorSexo,
        expireTime: produto.expireTime,
        maxQuantityPerUser: produto.quantidadeMaximaPorUsuario,

        tare: produto.tara,

        dontShowInKDS: produto.dontShowInKDS,
        adultHood: produto.adultHood,
        serviceFeeExemption: produto.isencaoTaxaServico,


        // featuredProduct: false,
        //invoice
        cest: produto.cest,
        cfop: produto.cfop,
        ncm: produto.ncm,


        //Additionals And Composition
        productComposition: produto.produtoComposicao,
        freeAdditionalQuantity: produto.quantidadeAdicionaisGratuito,
        observations: observations ?? [],
        complements: complement ?? [],
        sectorPrinterIds: []
    }

    return (product)
}
export default ProdutoToProduct

