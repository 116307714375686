import { IPaymentlinkProductErrors, IPaymentProductForms } from "modules/paymentLink/presentation/interfaces/IPaymentProductForms";
import {
} from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";

export const validateProductForm = (
  formData: IPaymentProductForms,
  setErrors: React.Dispatch<React.SetStateAction<IPaymentlinkProductErrors>>
) => {
  let _errors: IPaymentlinkProductErrors = {};
  let hasError = false;
  if (!formData?.productId?.trim()) {
    _errors = { ..._errors, product: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.price) {
    _errors = { ..._errors, price: "Campo obrigatório" };
    hasError = true;
  }
  if (Number(formData.price) === 0 && !formData.isVariable) {
    _errors = { ..._errors, price: "Insira um valor maior que zero" };
    hasError = true;
  } 

  setErrors(_errors);
  return !hasError;
};
