import { FC, useCallback, useEffect, useMemo, useState } from "react";
import CargosHeader from "../components/cargosHeader/CargosHeader";
import CargosItem from "../components/cargosItem.tsx/CargosItem";
import styles from "./CargosPage.module.scss";
import { MenuItem, Pagination, TextField } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import CargosItemMobile from "../components/cargosItemMobile/CargosItemMobile";
import SearchCargos from "../components/search/SearchCargos";
import Button from "components/ui/Button/Button";
import { AddCircle } from "@mui/icons-material";
import UseRolesList from "../hooks/UseRolesList";
import { IProfiles } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListResponse";
import { IGetRegisteredRolesListParams } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import UseRegisteredRolesList from "../hooks/UseRegisteredRolesList";
import SidesheetRoles from "../components/sidesheetCargos/SidesheetRoles";
import NesteRoles, { INestedFormValues as INestedRolesFormValues } from "../components/nestedRoles/NestedRoles";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const ColaboradoresPage: FC = () => {
  const { currentLocal } = useLocal();
  const [openSidesheet, setOpenSidesheet] = useState<
    "isNew" | "isEditing" | "isDeleting" | null
  >(null);
  const [editAndDeleteItem, setEditAndDeleteItem] = useState<IProfiles>();
  const [getParams, setGetParams] = useState<IGetRegisteredRolesListParams>(
    { ordemColumnDesc: false, orderByColumn: "Name" } as IGetRegisteredRolesListParams);
  const [pageSize, setPageSize] = useState<string>("10");
  const [hasChangePage, setHasChangePage] = useState(false);

  const { showLoading, hideLoading } = useUi();

  const { dimensions } = UseDimension();

  const {
    registeredRolesList,
    getRegisteredRolesList,
    totalPages,
    currentPage,
    nextPage,
    getAllRolesList,
    allRolesList,
    getRolesList,
    rolesList,
    postNewRole,
    putEditRole
  } = UseRolesList();
  const { setRegisteredRolesList } = UseRegisteredRolesList();

  useEffect(() => {
    if (currentLocal && !hasChangePage) {
      getRegisteredRolesList({ ...getParams, ownerId: currentLocal?.id }, currentPage, Number(pageSize));
    }
  }, [getParams, getRegisteredRolesList, currentLocal, currentPage, pageSize, hasChangePage]);

  const registeredList = useMemo(() => registeredRolesList ?? [], [registeredRolesList]);

  const onCloseSideSheet = useCallback(() => {
    setOpenSidesheet(null);
    setEditAndDeleteItem(undefined)
    getRegisteredRolesList({ ...getParams, ownerId: currentLocal?.id }, 1);
    getAllRolesList();
  }, [currentLocal, getParams, getRegisteredRolesList, getAllRolesList]);

  useEffect(() => {
    if (allRolesList) {
      setRegisteredRolesList(allRolesList);
    }
  }, [allRolesList, setRegisteredRolesList]);


  useEffect(() => {
    getRolesList();
  }, [getRolesList]);

  const onSubmit = useCallback(async (values: INestedRolesFormValues) => {
    showLoading();
    try {
      if (currentLocal) {
        let res: string | undefined = undefined;
        if (editAndDeleteItem) {
          const response = await putEditRole({ ...values, ownerId: currentLocal?.id, id: editAndDeleteItem?.Id });
          res = response;
        } else {
          const response = await postNewRole({ ...values, ownerId: currentLocal?.id });
          res = response;
        }
        if (res === "ok") {
          onCloseSideSheet();
        }
      }
    } finally {
      hideLoading();
    }
  }, [currentLocal, editAndDeleteItem, hideLoading, onCloseSideSheet, postNewRole, putEditRole, showLoading]);

  return (
    <div id={styles.Cargos}>
      <div>
        <h1>Cargos</h1>
        <p style={{ fontSize: dimensions.width > 700 ? "16px" : "14px" }}>
          Agrupe suas permissões e facilite o acesso dos seus colaboradores.
        </p>
      </div>
      <Button
        className={styles.btnAdd}
        endIcon={<AddCircle />}
        onClick={() => setOpenSidesheet("isNew")}
      >
        Adicionar Cargo
      </Button>
      <SearchCargos getParams={getParams} setGetParams={setGetParams} />
      <div>
        {registeredList && registeredList.length > 0
          ? (
            <>
              {dimensions.width > 700 && <CargosHeader getParams={getParams} setGetParams={setGetParams} />}
              {registeredList.map((item) => {
                return (
                  <>
                    {dimensions.width > 700 ? (
                      <CargosItem
                        item={item}
                        setOpenSidesheet={setOpenSidesheet}
                        setEditAndDeleteItem={setEditAndDeleteItem}
                      />
                    ) : (
                      <CargosItemMobile
                        item={item}
                        setOpenSidesheet={setOpenSidesheet}
                        setEditAndDeleteItem={setEditAndDeleteItem}
                      />
                    )}
                  </>
                );
              })}
              {!!totalPages && (
                <div className={styles.conteinerPagination}>
                  <div className={styles.boxMultiplePage}>
                    <span>Exibir</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      select
                      value={pageSize}
                      defaultValue={pageSize}
                      onChange={(ev) => setPageSize(ev.target.value)}
                    >
                      <MenuItem className={styles.textPage} value={10}>
                        10
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={20}>
                        20
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={50}>
                        50
                      </MenuItem>
                    </TextField>
                  </div>
                  {totalPages > 0 && (
                    <Pagination
                      variant="outlined"
                      className={styles.paginationNumber}
                      shape="rounded"
                      sx={{
                        ".MuiPaginationItem-root": {
                          borderColor: "#BDBDBD",
                          color: "#001537",
                        },
                        ".Mui-selected": {
                          fontWeight: "bold",
                          borderColor: "#001537",
                        },
                      }}
                      count={totalPages}
                      page={currentPage}
                      onChange={(_, value) => {
                        setHasChangePage(true);
                        nextPage({ ...getParams, ownerId: currentLocal?.id }, value, Number(pageSize));
                      }}
                    />
                  )}
                </div>
              )}
            </>
          )
          : (
            <div className={styles.emptyList}>
              <img src="/assets/img/empty-box.png" alt="" />
              <span>Você ainda <b>não possui</b> nenhum cargo configurado!</span>
            </div>
          )
        }
      </div>

      {/* {openSidesheet && ( */}
        <SidesheetRoles
          open={openSidesheet}
          onCloseSideSheet={onCloseSideSheet}
          item={editAndDeleteItem}
          params={getParams}
        />
      {/* )} */}
      <NesteRoles
        open={openSidesheet === "isNew" || openSidesheet === "isEditing"}
        onClose={onCloseSideSheet}
        menuRoles={rolesList ?? []}
        defaultValues={editAndDeleteItem ? { name: editAndDeleteItem?.Name, permissionIds: editAndDeleteItem?.PermissionIds } : undefined}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default ColaboradoresPage;
