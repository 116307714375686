import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IPrintQueueList } from "../interfaces/IPrintQueueList";
import GetPrintQueueUseCase from "modules/printQueue/application/useCases/GetPrintQueueUseCase";
import PrintQueueApi from "services/api/printQueue/PrintQueueApi";
import { IPrintQueueFilter } from "../components/printQueueFilter/interfaces/IPrintQueueFilter";

export interface IParamsPrintQueueList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  filter?: IPrintQueueFilter
}

const UsePrintQueueList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [printQueueList, setPrintQueueList] = useState<IPrintQueueList>()
  const { currentLocal } = useLocal();

  const getPrintQueueList = useCallback(
    async (params?: IParamsPrintQueueList) => {
      try {
        const service = PrintQueueApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPrintQueueUseCase(service,
            currentLocal.id,
            params?.pagination ?? { page: 1, pageSize: 20 },
            params?.filter,
          );

          setPrintQueueList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    printQueueList,
    isLoading,
    getPrintQueueList,
    setPrintQueueList,
  };
};

export default UsePrintQueueList;
