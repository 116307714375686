import { useQuery } from "hooks/UseQuery";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthApi } from "services/api/auth/AuthApi";
import { AuthenticateSaasUseCase } from "modules/saas/application/useCases/AuthenticateSaasUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ILoginSchema } from "modules/saas/domain/schema/loginSchema";

const authApi = AuthApi();
const service = SaasApi();

export const UseLoginPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    const query = useQuery();
    const history = useHistory();
    const { toast } = useUi();
    const location = useLocation<{ type: string }>();

    useEffect(() => {
        query.get("email") && setEmail(query.get("email") as string);
        query.get("type") && setType(query.get("type") as string ?? location.state?.type);
    }, [location.state?.type, query]);

    useEffect(() => {
        if (type === "3" || type === "6" || type === "7") {
            setProgressBarColor("#00739E");
            setBackgroundColorTop("#00AEEF");
            setBackgroundColorBottom("#00AEEF");
        } else {
            setProgressBarColor("#865FDD");
            setBackgroundColorTop("#6B41C9");
            setBackgroundColorBottom("#865FDD");
        }
    }, [type]);

    const handleForgetPassword = useCallback(() => {
        history.push(`/public/saas/forget-password?type=${type}&email=${email}`, { type: type });
    }, [email, history, type]);

    const handleSubmit = useCallback(async (values: ILoginSchema) => {
        const imei = query.get("imei");
        const serialNumber = query.get("serialNumber");

        try {
            setIsLoading(true);

            const auth = await authApi.login(values.email, values.password);
            const response = await AuthenticateSaasUseCase(service, {
                email: values.email,
                type: Number(type ?? 0),
                externalDevice: (imei && serialNumber && imei !== '' && serialNumber !== '') ? {
                    imei: imei ,
                    serialNumber: serialNumber,
                } : undefined,
            }, auth.access_token);

            history.push(`/public/saas/profile?prospectId=${response.prospectId}&key=${response.key}&userMeepId=${response.userMeepId}`);
        } catch {
            toast('E-mail ou senha inválidos, tente novamente!', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [history, query, toast, type]);

    return {
        isLoading,
        type,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit,
        handleForgetPassword,
    }
}