import { Button, MenuItem } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { IProductFilterValue } from "./IProductFilterValue";
import styles from "./ProductFilter.module.scss";
import UseProductPage from "../../UseProductPage";
import { IProductFilter } from "../../interfaces/IProductFilter";

export interface IProductFilterProps {
  setFilterExport: React.Dispatch<React.SetStateAction<IProductFilter | undefined>>
}

const ProductFilter: FC<IProductFilterProps> = ({ setFilterExport }) => {
  const { onChangeFilter, categoriesList, filter } = UseProductPage();

  const [values, setValues] = useState<IProductFilterValue>(
    filter ?? {
      keyword: "",
      categories: [],
    }
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onChangeFilter(values);
    },
    [onChangeFilter, values]
  );

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
      page: 0,
    }));
    setFilterExport((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
      page: 0,
    }));


  }, [setFilterExport]);

  const changeCategoriesHandle = useCallback(
    (values: { name: string; id: string }[]) => {
      setValues((prev) => ({ ...prev, categories: values, page: 0 }));
      setFilterExport((prev) => ({ ...prev, categories: values, page: 0 }));
    },
    [setFilterExport]
  );

  useEffect(() => {
    if (filter) {
      setValues(filter);
    }
  }, [filter]);

  return (
    <form id={styles.ProductFilter} onSubmit={submitHandle}>
      <div className={styles.container}>
        <TextField
          fullWidth
          label="Buscar"
          size="small"
          name="keyword"
          value={values.keyword}
          onChange={changeHandle}
        />
        <Autocomplete
          fullWidth
          size="small"
          loading={!categoriesList}
          getOptionLabel={(option) => option.name}
          multiple
          value={values.categories}
          onChange={(ev, values) => changeCategoriesHandle(values)}
          renderInput={(params) => (
            <TextField {...params} label={`Categorias`} />
          )}
          options={(categoriesList as { name: string; id: string }[]) ?? []}
        />
        <TextField
          fullWidth
          size="small"
          name="productType"
          value={values.productType}
          select
          onChange={changeHandle}
        >
          <MenuItem value="">Filtre por tipo</MenuItem>
          <MenuItem value={0}>Produto</MenuItem>
          <MenuItem value={1}>Adicionais</MenuItem>
        </TextField>
        <div className={styles.containerButton}>
          <Button variant="contained" fullWidth className={styles.filter} type="submit">
            Filtrar
          </Button>
        </div>
      </div>
    </form>
  );
};
export default ProductFilter;
