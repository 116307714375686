import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const GetTIDsListUseCase = async (
  service: IComplienceService,
) => {
  const response = await service.getLatestAdditionalTids();
  return response;
};

export default GetTIDsListUseCase;
