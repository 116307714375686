import React, { FC, useEffect, useState } from "react";
import styles from "../SimpleRegister/SimpleRegister.module.scss";
import { CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { StepEnum, UseSimpleLoginPage } from "./UseSimpleLoginPage";
import { useForm } from "react-hook-form";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { ISimpleLoginSchema, simpleLoginSchema, ICompleteLocalSchema, completeLocalSchema } from "modules/saas/domain/schema/simpleLoginSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "hooks/UseQuery";
import SaasPublicLayout from "../SaasPublicLayout";
import ShowPassword from "../../components/showPassword/ShowPassword";

export interface ISimpleLoginPageProps {
    //propertys
}

const SimpleLoginPage: FC<ISimpleLoginPageProps> = () => {
    const { dimensions } = UseDimension();
    const query = useQuery();
    const {
        segments,
        step,
        isLoading,
        handleLogin,
        handleCreateLocal,
        handleForgetPassword
    } = UseSimpleLoginPage();

    const [showPassword, setShowPassword] = useState(false);

    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorsLogin },
        setValue,
    } = useForm<ISimpleLoginSchema>({
        resolver: zodResolver(simpleLoginSchema)
    });

    const {
        register: registerLocal,
        handleSubmit: handleSubmitLocal,
        formState: { errors: errorsLocal },
        getValues
    } = useForm<ICompleteLocalSchema>({
        resolver: zodResolver(completeLocalSchema)
    });

    useEffect(() => {
        const email = query.get("email");
        if (email) setValue("email", email);
    }, [query, setValue]);

    return (
        <SaasPublicLayout backgroundColorTop="#6B41C9" backgroundColorBottom="#865FDD" terms={step === StepEnum.email}>
            <div className={styles.content}>
                <img src="/assets/icon/restaurant-icon.svg" alt=""/>

                {step === StepEnum.email && (
                    <>
                        <h1>Bem-vindo, usuário Mee!</h1>
                        <h1 style={{ marginBottom: "10px" }}>Use seu email e senha para continuar:</h1>
                        <form
                            onSubmit={handleSubmitLogin(handleLogin)}
                        >
                            <div
                                className={styles.row}
                                style={{
                                    flexDirection: dimensions.width < 601 ? "column" : "row",
                                    marginBottom: "10px"
                                }}
                            >
                                <div className={styles.column}>
                                    <label htmlFor="email" className={styles.required}>E-mail</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        {...registerLogin("email")}
                                        helperText={errorsLogin.email?.message}
                                        error={!!errorsLogin.email}
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className={`${styles.column} ${styles.inputPass}`}>
                                    <label htmlFor="password" className={styles.required}>Senha</label>
                                    <TextField
                                        variant="outlined"
                                        type={showPassword ? "text" : "password"}
                                        fullWidth
                                        size="small"
                                        {...registerLogin("password")}
                                        helperText={errorsLogin.password?.message}
                                        error={!!errorsLogin.password}
                                        disabled={isLoading}
                                    />
                                    <ShowPassword showPassword={showPassword} setShowPassword={setShowPassword} />
                                </div>
                            </div>

                            <Button type="submit" color="secondary">
                                {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                            </Button>
                            <Button
                                type="button"
                                color="secondary"
                                variant="text"
                                onClick={handleForgetPassword}
                            >
                                Esqueci minha senha
                            </Button>
                        </form>
                    </>
                )}
                {step === StepEnum.local && (
                    <>
                        <h1>Vamos criar o seu estabelecimento</h1>
                        <form
                            onSubmit={handleSubmitLocal(handleCreateLocal)}
                        >
                            <div className={styles.column}>
                                <label htmlFor="establishmentName" className={styles.required}>Nome do seu negócio</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    {...registerLocal("establishmentName")}
                                    helperText={errorsLocal.establishmentName?.message}
                                    error={!!errorsLocal.establishmentName}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className={styles.column}>
                                <label htmlFor="segment">Qual o seu segmento?</label>
                                <Select
                                    style={{ width: "100%" }}
                                    size="small"
                                    placeholder="Passo 01"
                                    {...registerLocal("segment")}
                                    defaultValue={getValues("segment")}
                                    disabled={isLoading}
                                >
                                    {
                                        segments.map(item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.description}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>

                            <Button type="submit" color="secondary">
                                {isLoading ? <CircularProgress size={16} color="inherit" /> : "Iniciar teste grátis"}
                            </Button>
                        </form>
                    </>
                )}
            </div>
        </SaasPublicLayout>
    )
}
export default SimpleLoginPage;