import { IGetHouseFlowListResponse } from "modules/houseFlow/domain/dto/IGetHouseFlowListResponse"
import { IHouseFlowService } from "modules/houseFlow/domain/interfaces/IHouseFlowService"
import { IHouseFlowFilter } from "modules/houseFlow/presentation/components/houseFlowFilter/components/interfaces/IHouseFlowFilter";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";


const GetHouseFlowUseCase = async (service: IHouseFlowService, localId: string, cardId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IHouseFlowFilter): Promise<IGetHouseFlowListResponse> => {


    const request = ParseFilterToRequestUseCase(cardId, filter, pagination);
    const response = await service.getHouseFlow(localId, request)
    return (response)
}
export default GetHouseFlowUseCase