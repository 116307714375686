import { IEditCouponRequest } from "../../domain/dto/IEditCouponRequest ";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";
import ParseEditCouponRequestUseCase from "./ParseEditCouponRequestUseCase ";


export const EditCouponUseCase = async (service: IDiscountCouponsService, request: IEditCouponRequest, localId: string, couponId: string) => {

    const parse = ParseEditCouponRequestUseCase(request, localId, couponId);
    const _request = await service.putEditCoupon(parse);
    return _request;
}


