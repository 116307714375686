import { IForgotPasswordApi } from "modules/auth/application/interfaces/_api/IForgotPasswordApi";
import { IForgotPasswordRequest } from "modules/auth/models/dto/IForgotPasswordRequest";
import { api } from "../Api";


export const ForgotPasswordApi = (): IForgotPasswordApi => {
    const postForgotPassword = async (cpfOuEmail: string, redirectTo?: string): Promise<IForgotPasswordRequest> => {
        const result = await api.post(`Account/SolicitarRecuperacaoSenha`, { cpfOuEmail: cpfOuEmail, redirectTo }, { params: { disableError: true } });
        return result.data;
    };

    return {
        postForgotPassword
    };
};
