import Utils from "services/utils/Utils";
import { ICouponForm } from "../../../interfaces/ICouponForm";
import { ICouponFormErros } from "../../../interfaces/ICouponFormErrors";
import { EnumValueType } from "../../discountCouponFilter/interfaces/IDiscountCouponFilter";



export const validate = (formData: ICouponForm, setErrors: React.Dispatch<React.SetStateAction<ICouponFormErros>>) => {
  let _errors: ICouponFormErros = {};
  let hasError = false;
  if (!formData.code.trim()) {
    _errors = { ..._errors, code: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.description.trim()) {
    _errors = { ..._errors, description: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.quantity <= 0) {
    _errors = { ..._errors, quantity: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.value <= 0) {
    _errors = { ..._errors, value: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.minOrderValue <= 0) {
    _errors = { ..._errors, minOrderValue: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.value <= 0) {
    _errors = { ..._errors, value: "Campo obrigatório" };
    hasError = true;
  }
  if ((formData.valueType === EnumValueType.PERCENT) && (formData?.maxValue ?? 0) <= 0) {
    _errors = { ..._errors, maxValue: "Campo obrigatório" };
    hasError = true;
  }
  if ((formData.valueType === EnumValueType.ABSOLUTE) && formData.value > formData.maxOrderValue) {
    _errors = { ..._errors, maxOrderValue: "Valor do pedido menor que o valor do desconto" };
    hasError = true;
  }
  if ((formData.valueType === EnumValueType.PERCENT) && (formData?.maxValue ?? 0) > formData.maxOrderValue) {
    _errors = { ..._errors, maxOrderValue: "valor máximo de desconto maior que o valor máximo do pedido" };
    hasError = true;
  }
  if (!formData.startAt) {
    _errors = { ..._errors, startAt: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.finishAt) {
    _errors = { ..._errors, finishAt: "Campo obrigatório" };
    hasError = true;
  }
  if (
    formData.startAt &&
    formData.finishAt &&
    (!Utils.dateValidation(formData.finishAt, ">", formData.startAt) && !Utils.dateValidation(formData.startAt, "=", formData.finishAt))
  ) {
    _errors = { ..._errors, finishAt: "Data final deve ser maior que a inicial" };
    hasError = true;
  }
  if (
    formData.startAt &&
    formData.finishAt &&
    (!Utils.dateValidation(formData.startAt, "<", formData.finishAt) && !Utils.dateValidation(formData.startAt, "=", formData.finishAt))
  ) {
    _errors = { ..._errors, startAt: "Data inicial deve ser menor que a final" };
    hasError = true;
  }  
  setErrors(_errors);
  return !hasError;
};
