import { createContext, FC, useCallback, useContext, useState } from "react";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { IError } from "../../interfaces/IError";


interface IErrorContext {
  setOnError: (error: IError) => void;
  clearContainerError: (containerId: dashboardContainerEnum) => void;
  setManyOnError: (error: Omit<IError, 'containerId'>, containerIds: dashboardContainerEnum[]) => void;
  clearManyContainerError: (containerIds: dashboardContainerEnum[]) => void;
  clearErrors: () => void;
  error?: IError[];
}
const ErrorContext = createContext<IErrorContext>({} as IErrorContext);

export const ErrorConsumer = ErrorContext.Consumer
export const useError = () => {
  const context = useContext(ErrorContext);
  return context;
};

export const ErrorProvider: FC = ({ children }) => {
  const [error, setError] = useState([] as IError[]);

  const setOnError = useCallback((containerError: IError) => {
    setError(prev => [...prev.filter(x => x.containerId !== containerError.containerId), containerError]);
  }, []);

  const clearContainerError = useCallback((containerId: dashboardContainerEnum) => {
    setError(prev => [...prev.filter(x => x.containerId !== containerId)]);
  }, []);

  const setManyOnError = useCallback((containerError: Omit<IError, 'containerId'>, containerIds: dashboardContainerEnum[]) => {
    const errors: IError[] = [];

    containerIds.forEach(x => {
      errors.push({ ...containerError, containerId: x })
    })

    setError(prev => [...prev.filter(x => !containerIds.includes(x.containerId)), ...errors]);
  }, []);

  const clearManyContainerError = useCallback((containerIds: dashboardContainerEnum[]) => {
    setError(prev => [...prev.filter(x => !containerIds.includes(x.containerId))]);
  }, []);

  const clearErrors = () => {
    setError([]);
  }

  return (
    <ErrorContext.Provider value={{ setOnError, clearContainerError, setManyOnError, clearManyContainerError, clearErrors, error }}>
      <>{children}</>
    </ErrorContext.Provider>
  );
};
