import { Drawer } from '@material-ui/core';
import { FC } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons';
import { Close } from '@material-ui/icons';

import styles from './ConfirmAlert.module.scss';
import AlertButton from '../../../alertButton/AlertButton';

export interface IConfirmAlertProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}
const ConfirmAlert: FC<IConfirmAlertProps> = ({ open, onClose, onConfirm }) => {
    return (
        <Drawer anchor='right' open={open} BackdropProps={{ invisible: true }}>
            <div className={styles.container}>
                <div className={styles.containerTitle}>
                    <h2>Alterar <span>Perfil de Venda</span></h2>
                    <Close onClick={onClose} />
                </div>
                
                <AlertButton type="warnning" label={`Suas alterações serão perdidas caso você saia. Deseja sair mesmo assim?`} />
                
                <FooterButtons 
                    onClickBack={onClose}
                    onClickNext={() => onConfirm()} 
                    labelBack={'Cancelar'} 
                    labelNext={'Sair sem salvar'} 
                />
            </div>
        </Drawer>
    )
}
export default ConfirmAlert