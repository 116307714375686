import { ISaqueFormErrors, ISaqueFormItem } from "../interfaces/ISaqueForm";

export const validate = (formData: ISaqueFormItem, setErrors: React.Dispatch<React.SetStateAction<ISaqueFormErrors>>) => {
  let _errors: ISaqueFormErrors = {};
  let hasError = false;

  if (!formData.valor) {
    _errors = { ..._errors, valor: "Campo valor do saque obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
