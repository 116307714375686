import { AxiosInstance } from "axios"
import { IGetDeviceCatalogListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceCatalogList/IGetDeviceCatalogListResponse"

export interface ListaDeProdutoItem {
    descricao: string
    ehListaPadrao: Boolean
    listaProdutoId: string,
}

export const BuscaListaDeProdutos = async (api: AxiosInstance, localId: string): Promise<IGetDeviceCatalogListResponse> => {
    const response = await api.get<ListaDeProdutoItem[]>("/Proprietario/BuscarListasProdutosDoLocal/" + localId)
    return { items: response.data.map(item => ({ id: item.listaProdutoId, name: item.descricao })) }
}
