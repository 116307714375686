import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './Supply.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import UseQueryState from 'modules/meepErp/presentation/hook/UseQueryFilter';
import { queryClient } from 'services/api/Api';
import DeleteErp from 'modules/meepErp/presentation/components/ui/delete/DeleteErp';
import SupplyService from 'modules/meepErp/services/api/Supply/SupplyService';
import { ISupplyFilterValue } from '../../components/supply/supplyList/supplyFilter/ISupplyFilter';
import SupplyList from '../../components/supply/supplyList/SupplyList';
import { IGetSupplyItemResponse, IGetSupplyListResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { Add } from '@material-ui/icons';
import { getSupplyGroupListUseCase, searchNameSupplyGroupUseCase } from 'modules/meepErp/application/useCases/supply/supplyGroup/SupplyGroupUseCases';
import { getSuppliesListUseCase, getSupplyCostsUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases';
import { ISupplyGroupListRequest } from '../../components/supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { tabsRegister } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { IGetSupplyNameRequest } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IGetSupplyCostsResponse } from 'modules/meepErp/application/dtos/supply/IGetSupplyCostsResponse';

const SupplyPage: FC = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<ISupplyFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();
    const { showLoading, hideLoading } = useUi()
    const [itemSupply, setItemSupply] = useState<IGetSupplyCostsResponse[]>()
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Insumos",
            },                  
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  

    //useCases
    const GetSearchNameSupplyGroup = useCallback(async (request: IGetSupplyNameRequest) => {
        return await searchNameSupplyGroupUseCase(request);
    }, [])

    const getSupplyGroupListHandler = useCallback(async (request: ISupplyGroupListRequest) => {
        return await getSupplyGroupListUseCase({ page: 1, pageSize: 20, ...request })
    }, [])

    const getSupplyNameUseCase = async (localId: string) => {
        return SupplyService().getSupplyNameList(localId);
    }

    const onDeleteSupplyUseCase = async (localId: string, id: string) => {
        const service = SupplyService();
        return await service.deleteSupply(localId, id);
    }

    const updateIsActive = useCallback(async (id: string, isActive: boolean, value: IGetSupplyItemResponse) => {
        const service = SupplyService();
        if (isActive) {
            await service.enableSupply(currentLocal?.id!, id)
        } else {
            await service.disableSupply(currentLocal?.id!, id);
        }
        return { ...value, isActive }
    }, [currentLocal?.id])

    //ReactiQuery

    const supplyName = useQuery(['@supplyName', currentLocal?.id], () => getSupplyNameUseCase(currentLocal?.id!), {
        enabled: !!currentLocal?.id,
    })
    const supply = useQuery(['@supply', currentLocal?.id, filter], () => getSuppliesListUseCase(currentLocal?.id!, filter), {
        enabled: !!currentLocal?.id,
    })

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetSupplyListResponse | undefined>(['@supply'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteOperationMutation = useMutation((id: string) => onDeleteSupplyUseCase(currentLocal?.id!, id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteOperationMutation.mutateAsync(id);
    }, [onDeleteOperationMutation])

    const onSuccessUpdateIsActive = (value: IGetSupplyItemResponse, { id, isActive }: { id: string; isActive: boolean }) => {
        queryClient.setQueriesData<IGetSupplyListResponse | undefined>(
            ['@supply'],
            (previous) =>
                previous
                    ? {
                        ...previous,
                        items: previous.items.map((item) =>
                            item.id === id ? { ...item, isActive } : item
                        ) ?? []
                    }
                    : undefined
        );
    };

    const onUpdateIsActiveMutation = useMutation(
        ({ id, isActive, value }: { id: string; isActive: boolean; value: IGetSupplyItemResponse }) =>
            updateIsActive(id, isActive, value),
        {
            onSuccess: onSuccessUpdateIsActive
        }
    );

    const onConfirmEnable = useCallback(async (id: string, isActive: boolean, value: IGetSupplyItemResponse) => {
        return await onUpdateIsActiveMutation.mutateAsync({ id, isActive, value });

    }, [onUpdateIsActiveMutation]);


    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/supply/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/supply/update/${id}`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    const getSupplyCosts = useCallback(async (id?: string) => {
        try {
            showLoading();
            if (!id) return;
            if (!currentLocal) throw new Error("Local não econtrado!");
            const response = await getSupplyCostsUseCase(currentLocal?.id, id);
            setItemSupply(response)
          
        } finally {
            hideLoading();
        }
    }, [])

    return (
        <div id={styles.Supply}>
            <TabsErp data={tabsRegister}/>
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar insumo
                    </Button>
                </div>
            }
            <SupplyList
                filter={filter}
                onChangeFilter={updateFilter}
                supplyName={supplyName.data ?? []}
                supply={supply.data}
                isLoading={supply.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
                onClickEnable={onConfirmEnable}
                getSupplyGroupList={getSupplyGroupListHandler}
                GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                getSupplyCosts={getSupplyCosts}
                setItemSupply={setItemSupply}
                itemSupply={itemSupply}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'insumo'}
                text={'Deseja realmente excluir o insumo selecionado?'}
                textFeedback={'Insumo excluído com sucesso!'}
                auxiliaryText='Ao excluir um insumo todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
            />
        </div>
    )
}
export default SupplyPage