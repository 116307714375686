import Layout from 'components/layout/presentation/Layout'
import SaasPaymentSuccessPage from 'modules/saasPaymentSuccess/presentation/SaasPaymentSuccessPage'

export const SaasPaymentSuccess = () => {
    return (
        <Layout disabledMenu>
            <SaasPaymentSuccessPage />
        </Layout>
    )
}
