import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const LinkPeopleListToBaseListUseCase = async (
  baseListService: IDiscountBaseList,
  baseListId: string | null,
  peopleListId: string,
  localId: string,
): Promise<void> => {
  return await baseListService.linkDiscountBaseList(baseListId, peopleListId, localId);
};

export default LinkPeopleListToBaseListUseCase;
