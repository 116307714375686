import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useQuery } from 'hooks/UseQuery';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { DeleteServiceCategoryUseCase } from 'modules/schedule/application/useCases/serviceSchedule/DeleteServiceCategoryUseCase';
import { GetAllServiceCategoriesListByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetAllServiceCategoriesListByLocalIdUseCase';
import { GetMainServiceCategoriesByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetMainServiceCategoriesByLocalIdUseCase';
import { GetMainServiceCategoriesListByLocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetMainServiceCategoriesListByLocalIdUseCase';
import SaveReorderScheduleCategoriesUseCase from 'modules/schedule/application/useCases/serviceSchedule/SaveReorderScheduleCategoriesUseCase';
import { SaveServiceCategoryUseCase } from 'modules/schedule/application/useCases/serviceSchedule/SaveServiceCategoryUseCase';
import { UpdateServiceCategoryUseCase } from 'modules/schedule/application/useCases/serviceSchedule/UpdateServiceCategoryUseCase copy';
import React, { createContext, FC, useState, useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ServicesScheduleApi } from 'services/api/schedule/service/ServiceScheduleApi';
import { ICatagoryServiceScheduleForm } from '../../components/serviceCategoryForm/interfaces/ICatagoryServiceScheduleForm';
import { IServiceCategoryItem } from './interface/IMainCategories';

const RouterPrefix = "/private/agendamento/list/"

export const UseServiceCateoriesPage = () => {

    const [mainCategories, setMainCategories] = useState<IServiceCategoryItem[]>();
    const [categories, setCategories] = useState<IServiceCategoryItem[]>();
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading, toast } = useUi();
    const [filter, setFilter] = useState({ main: true })
    const [enableReorder, setEnableReorder] = useState(false)
    const [selectedDelete, setSelectedDelete] = useState<IServiceCategoryItem>();
    const [selectedEdit, setSelectedEdit] = useState<ICatagoryServiceScheduleForm>();
    const [openAdd, setOpenAdd] = useState(false);
    const [openBonds, setOpenBonds] = useState(false)
    const [selectedBond, setSelectedBond] = useState<IServiceCategoryItem>()

    const query = useQuery();
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateRouters } = useBreadcumbs();
    const { tab } = useParams<{ tab: string }>();

    const onChangeOrderMainCategory = useCallback(
        (newValues: IServiceCategoryItem[]) => {
            const serviceScheduleApi = ServicesScheduleApi();
            SaveReorderScheduleCategoriesUseCase(serviceScheduleApi, newValues);
        },
        [],
    )

    const onChangeOrderCategory = useCallback(
        (newValues: IServiceCategoryItem[]) => {
            const serviceScheduleApi = ServicesScheduleApi();
            SaveReorderScheduleCategoriesUseCase(serviceScheduleApi, newValues);
        },
        [],
    )

    const getDegaultValueToEdit = useCallback((id: string) => {
        const category = categories?.find(item => item.id === id);
        if (category) {
            const categoryForm: ICatagoryServiceScheduleForm = {
                name: category.name,
                categoryId: "", //categoriaPai
                imageUrl: category.imageUrl,
            }
            setSelectedEdit(categoryForm)
        }
    }, [categories])

    const onClickAddButtonHandle = useCallback(() => {
        history.push(tab + "?openAdd=true")
    }, [path, history])

    const onClickCloseAddHandle = useCallback(() => {
        history.push(tab);
    }, [history, path])

    const onClickCloseListLinks = useCallback(() => {
        setOpenBonds(false);
    }, [])

    const openEditModal = useCallback((id: string) => {
        history.push(tab + "?categoryToEditId=" + id);
    }, [history, path]);

    const openBondsModal = useCallback((item: IServiceCategoryItem) => {

        setSelectedBond(item);
        setOpenBonds(true);

    }, []);

    const onClickCloseEditHandle = useCallback(() => {
        history.push(tab);
    }, [history, path])

    const openDeleteModal = useCallback((item: IServiceCategoryItem) => {
        setSelectedDelete(item)
    }, [])

    const onClickCloseDeleteModal = useCallback(() => {
        setSelectedDelete(undefined)
    }, [])

    const onEditCategoryHandle = useCallback(async (values: ICatagoryServiceScheduleForm) => {
        const serviceApi = ServicesScheduleApi;
        try {
            showLoading();
            const categoryToEditId = query.get("categoryToEditId")
            if (currentLocal && categoryToEditId) {

                const request = {
                    id: categoryToEditId,
                    localId: currentLocal?.id,
                    name: values.name,
                    categoryId: values.categoryId,
                    image: values.image ?? values.imageUrl ?? "",
                }
                await UpdateServiceCategoryUseCase(request, serviceApi);
                onClickCloseEditHandle()

            }
            toast("Categoria editada com sucesso", "success");

        } catch (error) {
        }
        finally {
            hideLoading()
            getCategory();

        }
    }, [currentLocal, hideLoading, onClickCloseEditHandle, query, showLoading])



    const onAddCategoryHandle = useCallback(async (values: ICatagoryServiceScheduleForm) => {
        const serviceApi = ServicesScheduleApi;
        try {
            showLoading();
            if (currentLocal) {
                const request = {
                    localId: currentLocal?.id,
                    name: values.name,
                    categoryId: values.categoryId,
                    image: values.imageUrl ?? "",
                }

                await SaveServiceCategoryUseCase(request, serviceApi);
                onClickCloseAddHandle()
            }
            toast("Categoria criada com sucesso", "success");

        } catch (error) {


        }
        finally {
            hideLoading()
            getCategory();
        }
    }, [currentLocal, hideLoading, onClickCloseAddHandle, showLoading, filter])

    const onDeleteCategporyHandle = useCallback(async () => {
        const serviceApi = ServicesScheduleApi;
        try {
            showLoading();
            if (selectedDelete) {
                await DeleteServiceCategoryUseCase(selectedDelete.id, serviceApi)
                onClickCloseDeleteModal()
                toast("Categoria excluída com sucesso", "success");

            }

        } finally {
            getCategory();
            hideLoading();
        };
    }, [hideLoading, onClickCloseDeleteModal, selectedDelete, showLoading])

    const getCategory = useCallback(async () => {
        if (currentLocal) {
            const serviceScheduleApi = ServicesScheduleApi();
            try {
                showLoading();
                if (filter.main) {
                    const categories = await GetMainServiceCategoriesListByLocalIdUseCase(currentLocal.id, serviceScheduleApi);
                    setCategories(categories);
                    setMainCategories(categories);

                } else {
                    const categories = await GetAllServiceCategoriesListByLocalIdUseCase(currentLocal.id, serviceScheduleApi);
                    setCategories(categories);
                }
            } finally {
                hideLoading();
            }
        }
    }, [currentLocal, filter.main, hideLoading, showLoading]);


    useEffect(() => {
        if (currentLocal) {
            const serviceScheduleApi = ServicesScheduleApi();

            const getCategory = async () => {
                try {
                    showLoading();
                    if (filter.main) {
                        const categories = await GetMainServiceCategoriesListByLocalIdUseCase(currentLocal.id, serviceScheduleApi);
                        setCategories(categories);
                        setMainCategories(categories);
                    } else {
                        const categories = await GetAllServiceCategoriesListByLocalIdUseCase(currentLocal.id, serviceScheduleApi);
                        setCategories(categories);
                    }
                } finally {
                    hideLoading();
                }
            }
            getCategory();
        }
        return () => {
        }
    }, [currentLocal, filter, hideLoading, showLoading])

    useEffect(() => {
        if (query.has('openAdd')) {
            setOpenAdd(true);
        } else {
            setOpenAdd(false);
        }
        const _selectedEditQuery = query.get('categoryToEditId');
        if (_selectedEditQuery) {
            getDegaultValueToEdit(_selectedEditQuery)
        } else {
            setSelectedEdit(undefined);
        }
    }, [getDegaultValueToEdit, query])

    useEffect(() => {
        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Categorias de Serviços',
                url: RouterPrefix + tab
            }
        ])
    }, [updateRouters]);

    return ({
        mainCategories,
        onChangeOrderMainCategory,
        categories,
        onChangeOrderCategory,
        setFilter,
        enableReorder,
        selectedDelete,
        selectedEdit,
        openAdd,
        getDegaultValueToEdit,
        onClickAddButtonHandle,
        onClickCloseAddHandle,
        openEditModal,
        onClickCloseEditHandle,
        openDeleteModal,
        onClickCloseDeleteModal,
        setEnableReorder,
        onAddCategoryHandle,
        onEditCategoryHandle,
        onDeleteCategporyHandle,
        filter,
        openBondsModal,
        openBonds,
        onClickCloseListLinks,
        selectedBond
    });
};
