import Layout from "components/layout/presentation/Layout";
import PageHeader from "components/ui/pageHeader/PageHeader";
import AddGuestListPage from "modules/events/presentation/pages/addGuestList/AddGuestListPage";
import React from "react";

const AddGuestList = () => {
  return (
    <Layout>
      <AddGuestListPage />
    </Layout>
  );
};

export default AddGuestList;
