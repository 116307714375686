import { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './LocalsFilter.module.scss'
import {  Icon, TextField } from '@material-ui/core'
import { Container, FilterButtonContainer, InputContainer } from '../../../ui/form/FormContainers'
import Row from 'modules/catalog/presentation/componentes/ui/Row'
import { useForm } from 'react-hook-form'
import { ILocalsFilterValue } from './ILocalsFilter'
import Button from 'components/ui/Button/Button'

export interface ILocalsFilterProps {
    defaultValue?: ILocalsFilterValue
    onSubmit: (values: ILocalsFilterValue) => void
}

const LocalsFilter: FC<ILocalsFilterProps> = ({ defaultValue, onSubmit }) => {
    const { register, handleSubmit, reset, watch } = useForm<ILocalsFilterValue>({
        defaultValues: defaultValue ?? {
            code: "",
            name: "",
        }
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        }
    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: ILocalsFilterValue) => {
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.ascending;
        delete allFields.sortBy;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            code: '',
            name: '',
        });
        onSubmit({
            code: '',
            name: '',
        })
    }, [onSubmit, reset])


    return (
        <Container>
            <form
                id={styles.LocalsFilter}
                onSubmit={handleSubmit(onSubmitHandle)}
            >
                <Row className={styles.row}>
                    <InputContainer label="Código">
                        <TextField
                            {...register("code")}
                        />
                    </InputContainer>
                    <InputContainer flex={2} label="Nome">
                        <TextField
                            {...register("name")}
                        />
                    </InputContainer>
                    <InputContainer  >
                    <FilterButtonContainer minWidth={100} >
                        {isAnyFieldFilled &&
                            <Button
                                onClick={onClickResetFilter}
                                variant="text"
                                color="primary"
                                startIcon={<Icon>close</Icon>}>
                                Limpar filtro
                            </Button>}
                            <Button color='secondary' type="submit" style={{width: '76px', minWidth: '76px'}}>Buscar</Button>
                    </FilterButtonContainer>
                </InputContainer>
                </Row>
            </form >
        </Container>
    )
}
export default LocalsFilter