import styles from './FinancialInvoicesPage.module.scss';
import { UseFinancialInvoicesPage } from './UseFinancialInvoicesPage';
import { FinancialInvoicesList } from './financialInvoicesPageList/FinancialInvoicesList';

const FinancialInvoicesPage = () => {
    const { financialInvoicesList, isLoading } = UseFinancialInvoicesPage();

  return (
    <div id={styles.FinancialInvoicesPage}>
        <FinancialInvoicesList
            financialInvoicesList={financialInvoicesList}
            isLoading={isLoading}
        />    
    </div>
  );
};

export default FinancialInvoicesPage;
