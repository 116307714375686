import React from "react";
import Layout from "components/layout/presentation/Layout";
import { ManageEventPage } from "modules/events/presentation/pages/manageEvent/ManageEventPage";

function Manage() {
  return (
    <Layout>
      <ManageEventPage />
    </Layout>
  );
}

export default Manage;
