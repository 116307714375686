import { IOperatorItemResponse } from "modules/operators/application/dtos/GetOperatorListResponse"
import { SaveOpertaorRequest } from "modules/operators/application/dtos/SaveOpertaorRequest"
import { IOperatorService } from "modules/operators/domain/interfaces/IOperatorService"
import OperatorAnticorruption from "./OperatorAnticorruption"
import { api } from "../Api"
import { IGetOperadorByName } from "modules/operators/application/dtos/IGetOperadorByName"

const OperatorApi = (): IOperatorService => {
    const getOperatorList = async (localId: string): Promise<IOperatorItemResponse[]> => {
        return await OperatorAnticorruption(api).getOperatorList(localId);
    }
    const addOperator = async (localId: string, operator: SaveOpertaorRequest): Promise<void> => {
        return await OperatorAnticorruption(api).addOperator(localId, operator)
    }
    const updateOperator = async (localId: string, operator: SaveOpertaorRequest): Promise<void> => {
        return await OperatorAnticorruption(api).editOperator(localId, operator)
    }
    const deleteOperator = async (localId: string, id: string): Promise<void> => {
        return await OperatorAnticorruption(api).deleteOperator(localId, id);
    }
    const getOperatorByName = async (localId: string, operator: string): Promise<IGetOperadorByName[]> => {
        return await OperatorAnticorruption(api).getOperatorByName(localId, operator);
    }
    return ({
      getOperatorList,
      addOperator,
      updateOperator,
      deleteOperator,
      getOperatorByName
    })
}
export default OperatorApi
