import { Icon } from '@material-ui/core';
import styles from './TrackConfig.module.scss';
import { Pagination, Skeleton, TextField } from '@mui/material';
import { format } from 'date-fns';
import { UseTrackConfig } from './UseTrackConfig';
import { TrackConfigStatusEnum } from 'modules/saas/domain/interfaces/TrackConfigStatusEnum';
import { TrackConfigModal } from './trackConfigModal/TrackConfigModal';


export const TrackConfigPage = () => {
    const {
        selectedConfig,
        config,
        currentPage,
        loading,
        filter,
        form,
        modalOpened, 
        setModalOpened,
        setForm,
        setFilter,
        setSelectedConfig,
        getData,
        handleSubmit,
    } = UseTrackConfig();

    return (
        <div className={styles.container}>
            <h1>Status Maquininha</h1>
            <span className={styles.subtitle}>Atualize o status da maquininha dos estabelecimentos para que o cliente posso acompanhar.</span>

            <div className={styles.filterContainer}>
                <div>
                    <label>Buscar por estabelecimento</label>
                    <TextField
                        className={styles.textField}
                        placeholder='Digite o nome ou CNPJ'
                        size='small'
                        autoComplete='one-time-code'
                        value={filter.keyword}
                        onChange={e => setFilter(prev => ({ ...prev, keyword: e.target.value }))}
                    />
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={() => getData(0, filter)}>Buscar</button>
                    <button
                        onClick={() => {
                            setFilter({ keyword: '' });
                            getData(0);
                        }}
                    >
                        Limpar
                    </button>
                </div>
            </div>

            <div className={styles.tableHeader}>
                <div>
                    <span>Local</span>
                    <span>Data da solicitação</span>
                    <span>Código de rastreio</span>
                    <span>Status</span>
                    <button><Icon>chevron_right</Icon></button>
                </div>
            </div>
            <div className={styles.tableBody}>
                {
                    loading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                        </> :
                        <>
                            {
                                config?.data.map(x => (
                                    <div>
                                        <span>{x.localName}</span>
                                        <span>{x.solicitationDate ? format(new Date(x.solicitationDate), 'dd/MM/yyyy HH:mm') : '-'}</span>
                                        <span>{x.trackingCode}</span>
                                        <span>
                                            <div className={x.status === TrackConfigStatusEnum.PREPARING ? styles.preparing : x.status === TrackConfigStatusEnum.SENT ? styles.sent : styles.delivered} />
                                            {x.status === TrackConfigStatusEnum.PREPARING ? "Em preparação" : x.status === TrackConfigStatusEnum.SENT ? "Enviado" : "Entregue"}
                                        </span>
                                        <button onClick={() => { setSelectedConfig(x); setModalOpened(true) }}><Icon>chevron_right</Icon></button>
                                    </div>

                                ))
                            }
                        </>
                }

            </div>
            <Pagination
                variant="outlined"
                shape="rounded"
                style={{ alignSelf: 'flex-end' }}
                sx={{
                    '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                    },
                    '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                    },
                }}
                className={styles.contentPagination}
                page={currentPage}
                count={config?.totalPages}
                onChange={(ev, page) => getData(page)}
            />

            {
                !!selectedConfig &&
                    <TrackConfigModal open={modalOpened} onClose={() => setModalOpened(false)} trackConfig={selectedConfig} form={form} setForm={setForm} onSubmit={handleSubmit} />
            }
        </div>
    )
}