export const capitalize = (str?: string): string => {
    if (!str) return '';

    return str.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}

export const removeAccentsAndSpace = (str?: string): string => {
    if (!str) return '';

    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/((^[a-z])|[^a-zA-Z]+(.))/g, (m, chr) => chr.trim().toUpperCase());
}
