import { TextField } from "@mui/material";
import styles from "./MenuConfig.module.scss";
import Button from "components/ui/Button/Button";
import { AddCircleOutlined } from "@material-ui/icons";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useCallback, useEffect, useState } from "react";
import MenuContainer from "./menuContainer/MenuContainer";
import { IGetSystemConfigByIdResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigByIdResponse";
import { selectPositionTypes } from "../menuPai/MenuPai";
import { IMenuOrder } from "modules/admin/menuConfig/domain/dto/IPutMenuOrder";
import { IGetMenuList } from "../AddMenuSidesheet";
import { IGetMenuListResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuListResponse";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";

interface IMenuConfigProps {
    setAddMenuSidesheetStep: React.Dispatch<React.SetStateAction<"menuConfig" | "menuPai" | "menuFilho" | "menuNeto" | "deleteMenu">>;
    systemConfig: IGetSystemConfigByIdResponse;
    defaultMenuList: IGetMenuList[];
    handleOpenDeleteMenuSidesheet: (menuId: string) => Promise<void>;
    setEditMenuFormValues: React.Dispatch<React.SetStateAction<IPutEditMenuPayload>>;
    setMenuOrder: React.Dispatch<React.SetStateAction<IMenuOrder[]>>;
}

const MenuConfig = ({ setAddMenuSidesheetStep, systemConfig, defaultMenuList, handleOpenDeleteMenuSidesheet, setEditMenuFormValues, setMenuOrder }: IMenuConfigProps) => {
    const [items, setItems] = useState<IGetMenuList[]>([]);

    useEffect(() => {
        setItems(defaultMenuList);
    }, [defaultMenuList]);

    const getMenuOrder = useCallback((menuList: IGetMenuList[]) => {
        const result: { menuId: string; order: number }[] = [];

        menuList.forEach((item) => {
            item.menus.forEach((menu, menuIndex) => {
                const stack: { menu: IGetMenuListResponse; index: number }[] = [{ menu, index: menuIndex }];

                while (stack.length > 0) {
                    const current = stack.pop();

                    if (current) {
                        result.push({ menuId: current.menu.id, order: current.index + 1 });

                        current.menu.submenus.forEach((submenu: IGetMenuListResponse, submenuIndex: number) => {
                            stack.push({ menu: submenu, index: submenuIndex });
                        });
                    }
                }
            });
        });
        return result;
    }, []);

    const getItem = useCallback(
        (id: string, arr: IGetMenuListResponse[]): [IGetMenuListResponse | null, number | null, number?, number?] => {
            for (let i = 0; i < arr?.length; i++) {
                if (arr[i].id === id) {                
                    return [arr[i], i];
                }
                if (arr[i].submenus.length) {
                    const [item, index] = getItem(id, arr[i].submenus);
                    if (item) {
                        for (let j = 0; j < arr.length; j++) {
                            const [subitem, subindex] = getItem(id, arr[i].submenus[j]?.submenus);
                            if (subitem) return [subitem, subindex, i, j];
                        }
                        return [item, index, i];
                    }
                }
            }
            return [null, null];
        }, []
    );

    const onDragEnd = useCallback((result: DropResult, currentItem: IGetMenuList) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const { destination } = result;
        let updatedItems = [...currentItem.menus];

        const [draggedItem, sourceIndex, parentIndex, sonIndex] = getItem(
            result.draggableId,
            updatedItems
        );

        if (sourceIndex !== null) {
            if (parentIndex !== undefined && sonIndex !== undefined) {
                const [removed] = updatedItems[parentIndex].submenus[sonIndex].submenus.splice(
                    sourceIndex,
                    1
                );
                updatedItems[parentIndex].submenus[sonIndex].submenus.splice(destination.index, 0, removed);
            } else if (parentIndex !== undefined) {
                const [removed] = updatedItems[parentIndex].submenus.splice(
                    sourceIndex,
                    1
                );
                updatedItems[parentIndex].submenus.splice(destination.index, 0, removed);
            } else {
                const [removed] = updatedItems.splice(sourceIndex, 1);
                updatedItems.splice(destination.index, 0, removed);            
            }
        }

        const updatedList = items.map((item) => {
            if (item.positionType === currentItem.positionType) {
                return {...item, menus: updatedItems};
            }
            return item;
        });

        const menuOrderList = getMenuOrder(updatedList);
        setItems(updatedList);
        setMenuOrder(menuOrderList);
    }, [getItem, getMenuOrder, items, setMenuOrder]);

    const handleMenuPositionType = useCallback((positionType: number) => {
        const findType = selectPositionTypes.find((type) => type.value === positionType)?.title;
        return findType ?? "";
    }, []);

    return (
        <div id={styles.MenuConfig}>
            <div className={styles.menuName}>
                <label htmlFor="name" className={styles.required}>
                    Identificador de sistema
                </label>
                <TextField
                    name="name"
                    variant="outlined"
                    size="small"
                    value={systemConfig.name}
                    disabled
                />
            </div>

            <h1>Listagem Menus</h1>
            <Button variant="outlined" color="secondary" endIcon={<AddCircleOutlined />} onClick={() => setAddMenuSidesheetStep("menuPai")}>Criar menu principal</Button>

            {items.map((menu) => (
                <div key={menu.positionType} className={styles.menus}>
                    <span className={styles.menuType}>{handleMenuPositionType(menu.positionType)}</span>
                    <DragDropContext onDragEnd={(e) => onDragEnd(e, menu)}>
                        <Droppable droppableId="droppable" type="ITEM" direction="vertical">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className={styles.menuContainer}>
                                    {menu.menus.map((item, itemIndex) => (
                                        <div className={styles.menuItem} key={item.id}>
                                            <MenuContainer
                                                parentItem={menu}
                                                item={item}
                                                index={itemIndex}
                                                isMenuPai
                                                setAddMenuSidesheetStep={setAddMenuSidesheetStep}
                                                onDragEnd={(e) => onDragEnd(e, menu)}
                                                handleOpenDeleteMenuSidesheet={handleOpenDeleteMenuSidesheet}
                                                setEditMenuFormValues={setEditMenuFormValues}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            ))}
        </div>
    );
}

export default MenuConfig;