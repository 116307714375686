import { IGetPaymentHistoryResponse } from "../domain/dtos/IGetPaymentHistoryResponse";
import { IPlansService } from "../domain/interfaces/IPlansService";

export const GetPaymentHistoryUseCase = async (
  service: IPlansService,
  ownerId: string,
  page?: number,
  pageSize?: number
): Promise<IGetPaymentHistoryResponse> => {
  return await service.getPaymentHistory(ownerId, page ?? 1, pageSize ?? 10);
}
