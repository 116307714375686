import styles from './ApproveDrawer.module.scss';
import { ICampaignItem } from "modules/crm/presentation/intefaces/ICampaign";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { format } from "date-fns";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Sidesheet from "components/sidesheet/Sidesheet";
import { MenuItem, TextField } from '@mui/material';

interface ApproveDrawerProps {
    open: boolean;
    campaign?: ICampaignItem;
    loading?: boolean;
    reproving: boolean;
    reason: string;
    setReason: (reason: string) => void;
    setReproving: (reproving: boolean) => void;
    onClose: () => void;
    onApprove: (campaignId: string) => void;
    onReprove: (campaignId: string, reason: string) => void;
}

const reasons = [
    'Desrespeita as diretrizes da Meep',
    'Conteúdo explícito',
    'Contém dados de contato',
    'Imagem incompatível',
]

export const ApproveDrawer = ({ open, campaign, loading, reproving, reason, setReason, setReproving, onClose, onApprove, onReprove }: ApproveDrawerProps) => {
    const { toast } = useUi();

    const handleReprove = () => {
        if (!reason) {
            toast('Informe o motivo da reprovação', 'error');
        } else {
            onReprove(campaign!.id, reason)
        }
    }

    return (
        <Sidesheet
            isLoading={loading}
            open={open}
            onClose={onClose}
            title={reproving
                ? <h1>Reprovação</h1>
                : <h1>Aprovação <span>de push</span></h1>
            }
            arrowBack={reproving}
            handleArrowBack={() => setReproving(false)}
            content={
                <div className={styles.container}>
                    <div>
                        {reproving 
                            ? <>
                                <FormItemContainer label={"Motivo da reprovação"} required className={styles.formItemContainer}>
                                    <TextField
                                        size="small"
                                        placeholder="Selecione o motivo"
                                        select
                                        variant='outlined'
                                        name={'reason'}
                                        value={reason}
                                        className="reason-select"
                                        onChange={(e) => setReason(e.target.value)}
                                    >
                                        {
                                            reasons.map(x => (
                                                <MenuItem value={x}>{x}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormItemContainer>
                            </> : <>
                                <div className={styles.row}>
                                    <span>Estabelecimento:</span>
                                    <span>{campaign?.ownerName}</span>
                                </div>
                                <div className={styles.row}>
                                    <span>Data de envio:</span>
                                    <span>{campaign?.deliveryTime ? format(new Date(campaign!.deliveryTime + "Z"), 'dd/MM/yyyy - HH:mm') : ''}</span>
                                </div>
                                <div className={styles.row}>
                                    <span>Destinatários:</span>
                                    <span>{campaign?.totalCustomers}</span>
                                </div>
                                <div className={styles.row}>
                                    <span>Título:</span>
                                    <span>{campaign?.subject}</span>    
                                </div>
                                <div className={styles.row}>
                                    <span>Mensagem:</span>
                                    <span>{campaign?.messageBody}</span>
                                </div>
                            </>
                        }
                    </div>
                </div>                
            }
            cancelButton
            customizedCancelButton={reproving ? "Voltar" : "Reprovar"}
            handleCustomizedCancelButton={() => reproving ? setReproving(false) : setReproving(true)}
            continueButton={reproving ? "Reprovar" : "Aprovar"}
            handleContinueButton={() => reproving ? handleReprove : onApprove(campaign!.id)}
        />
    )
}