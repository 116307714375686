import { IGetSessionsListResponse } from "modules/dashboard/domain/dto/getSessions/IGetSessionsListResponse";
import { ILocal } from "modules/dashboard/presentation/components/toggleLocal/ToggleLocal.types";
import IDashboardService from "../interfaces/IDashboardService";

const GetSessionsUseCase = async (dashboardService: IDashboardService, locals: ILocal[]): Promise<IGetSessionsListResponse> => {
  const promises: Promise<IGetSessionsListResponse>[] = [];
  const sortedLocals = locals.sort((a,b) => a.place < b.place ? -1 : 1);
  sortedLocals.map(x => x.id).forEach(x => promises.push(dashboardService.getSessions(x)));

  const responses = await Promise.all(promises);
  const result: IGetSessionsListResponse = {
    items: [],
    totalCount: 0
  };
  
  responses.forEach(x => {
    result.items.push(...x.items.map(x => ({ ...x, name: (sortedLocals.length > 1 ? sortedLocals.find(y => y.id === x.localId)?.place + ': ' : '') + x.name })));
    result.totalCount += x.items.length
  })

  // result.items = result.items.sort((a, b) => a.name < b.name ? -1 : 1);

  return result;
}

export default GetSessionsUseCase;
