import React, { ReactElement } from 'react';
import Item, { IActionButtons, IMenuButton } from './Item/Item';
import styles from './List.module.scss';
import { IListItem } from './interfaces/IList';
import { IListValues, ColumnConfigs, ColumnConfigsItem } from './List';

interface IFixedListProps<T extends IListItem> {
    listValues: (T & IListValues)[];
    renderItem: ((item: T, ReactElement: ReactElement) => ReactElement) | undefined;
    renderAccordion: ((item: T) => ReactElement) | undefined;
    columnsConfigs?: ColumnConfigs<T>
    actionButtons: IActionButtons<T>[] | undefined;
    menuButtons: IMenuButton<T>[] | undefined;
    sub?: boolean
    showAccordion?: (item: T) => boolean
    subLevel?: number
}

export const FixedList = <T extends IListItem>({
    listValues, renderItem, renderAccordion, columnsConfigs, actionButtons, menuButtons, sub, showAccordion, subLevel

}: IFixedListProps<T>) => {
    return <div className={styles.list}>
        {listValues?.map((item, index) => (
            renderItem ?
                renderItem(item,
                    <Item
                        renderAccordion={renderAccordion}
                        showAccordion={showAccordion}
                        key={item.id + index}
                        item={item}
                        columnsConfigs={columnsConfigs}
                        actionButtons={actionButtons}
                        sub={sub}
                        subLevel={subLevel}
                    />)
                :
                <Item
                    renderAccordion={renderAccordion}
                    showAccordion={showAccordion}
                    key={item.id}
                    item={item}
                    columnsConfigs={columnsConfigs}
                    actionButtons={actionButtons}
                    menuButtons={menuButtons}
                    sub={sub}
                    subLevel={subLevel}
                />
        ))}
    </div>;
};
