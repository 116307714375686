import AddPosFragment from "modules/salesPos/presentation/fragment/addPosFragment/AddPosFragment";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import { useHistory } from "react-router-dom";
import { UseConfigGetnet } from "./UseConfigGetnet";
import { useMemo } from "react";
import styles from "../configCieloSmart/ConfigCieloSmart.module.scss";
import { Button } from "@mui/material";
import { AddCircle } from "@material-ui/icons";
import Sidesheet from "components/sidesheet/Sidesheet";
import PosList from "../configCieloSmart/posList/PosList";

export interface IConfigGetnetProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
}

const ConfigGetnet = ({ open, setOpen }: IConfigGetnetProps) => {
  const history = useHistory();
  const { data, isLoading, refetch } = UseConfigGetnet();

  const handleStatus = useMemo(() => data?.length ? "enabled" : "unavailable", [data]);

  return (
    <>
      <ContentBox
        loading={isLoading}
        status={handleStatus}
        type={IntegrationTypeEnum.GETNET}
        text="Utilize o sistema Mee como PDV na sua máquina GetNet: lance pedidos, imprima na cozinha e receba o pagamento pela adquirência!"
        setOpen={setOpen}
      />
      {open && open === "Getnet" && handleStatus === "unavailable" && (
        <AddPosFragment
          open={!!open}
          onClose={() => {
            setOpen(null);
            refetch();
          }}
          fromIntegrations={AcquirerTypeEnum.GetNet}
        />
      )}
      {open && open === "Getnet" && handleStatus === "enabled" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={<h2>Integração - <b>Getnet</b></h2>}
          content={
            <div id={styles.ConfigSidesheet}>
              <Button variant="outlined" endIcon={<AddCircle />} fullWidth onClick={() => history.push("/private/vendas/pos")}>Nova máquina</Button>
              <div className={styles.info}>
                <img src="/assets/icon/saas/saas-pos-pink.svg" alt=""/>
                <p>Essas são suas máquinas Getnet que estão integradas com o PDV Mee. Você pode criar novas máquinas para lançar pedidos, acompanhar extrato e muito mais.</p>
              </div>
              <PosList data={data}/>
            </div>
          }
          currentStep={1}
          totalSteps={1}
          continueButton="Fechar"
          handleContinueButton={() => setOpen(null)}
        />
      )}
    </>
  );
};

export default ConfigGetnet;
