import { IGetDataFromCpfResponse } from "modules/schedule/domain/dto/clientSchedule/IGetDataFromCpfResponse";
import { ClientScheduleApiFunction } from "../../interfaces/api/IClientScheduleApi";

export const GetDataFromCpfUseCase = async (localId: string, cpf: string, clientScheduleApi: ClientScheduleApiFunction): Promise<IGetDataFromCpfResponse> => {
    const _clientScheduleApi = clientScheduleApi();
    const response = await _clientScheduleApi.getDataFromCpf(localId, cpf);
    return response;
}


