import React, { useCallback, useState } from "react";
import { IDiscountListForm, IDiscountProductListForm } from "./interfaces/IDiscountProductListForm";

const UseDiscountForm = (
  value: IDiscountListForm[],
  onChange: (value: IDiscountListForm[]) => void,
  onDeleteItem?: (itemId: string) => void
) => {
  const [openModalAdd, setOpenModalAdd] = useState(false);

  const onClickDeleteHandle = useCallback(
    (item: IDiscountProductListForm, index: number) => {
      onChange(value.filter((_, _index) => _index !== index));
      onDeleteItem && onDeleteItem(item.id);
    },
    [value]
  );
  const onClickAddHandle = useCallback(() => {
    setOpenModalAdd(true);
  }, []);
  const onClickCloseHandle = useCallback(() => {
    setOpenModalAdd(false);
  }, []);

  const onSubmitAddHandle = useCallback(
    (newValue: IDiscountListForm[]) => {
      // const oldValue = value.filter((item) => !newValue.find((_item) => _item.product.id === item.product.id));
      onChange([...value, ...newValue]);
      onClickCloseHandle();
    },
    [value]
  );

  return {
    openModalAdd,
    onClickDeleteHandle,
    onClickAddHandle,
    onSubmitAddHandle,
    onClickCloseHandle,
  };
};

export default UseDiscountForm;
