import { IGetNextAntecipateService } from "../interfaces/IContaDigitalApiService";

const GetNextAntecipate = async (
  accountService: IGetNextAntecipateService,
  ownerId: string,
  digitalAccountId: string
): Promise<any> => {
  return await accountService.getNextAntecipate(ownerId, digitalAccountId);
};

export default GetNextAntecipate;
