import { ImportExport } from "@mui/icons-material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ReportProductsItem.module.scss";
import { IReportProductsColumnsConfig } from "pages/private/relatorio/components/interfaces/IColumnsConfig";
import { SortProductInterface } from "services/api/outputreport";

interface IReportProductsHeaderProps {
  columns: IReportProductsColumnsConfig[];
  setSortProducts: (sortProducts: any) => void;
  filterName: string;
}

const ReportProductsHeader: FC<IReportProductsHeaderProps> = ({
  columns,
  setSortProducts,
  filterName,
}) => {
  const [orientation, setOrientation] = useState("ASC");

  const columnName = columns.map((item) => item.property);

  const handleChangeOrder = (columnName: string) => {
    if (filterName === "products") {
      setSortProducts({
        "sortProducts.Type": columnName,
        "sortProducts.Orientation": orientation === "ASC" ? "DESC" : "ASC",
      });
    }

    if (filterName === "payments") {
      setSortProducts({
        "sortPayments.Type": columnName,
        "sortPayments.Orientation": orientation === "ASC" ? "DESC" : "ASC",
      });
    }

    setOrientation(orientation === "ASC" ? "DESC" : "ASC");
  };

  const header = useMemo(
    () =>
      columns.map(
        (column, index) =>
          !column.hide && (
            <div key={index} className={styles.row} style={column.style}>
              <span className={styles.order}>
                {column.title}{" "}
                <ImportExport
                  onClick={() => handleChangeOrder(columnName[index])}
                />{" "}
              </span>
            </div>
          )
      ),
    [columnName, columns]
  );

  return (
    <div id={styles.ReportProductsHeader}>
      <div className={styles.container}>
        {header}
        <div className={styles.icon}></div>
      </div>
    </div>
  );
};

export default ReportProductsHeader;
