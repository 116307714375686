import { CircularProgress, Icon, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { FC, useCallback, useState } from 'react'
import "./GoogleAutoComplete.css"
import { ConvertDetailToAddress } from './_ConvertToAddress';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { IAddress } from './interfaces/IAddress';

interface substring {

    length: number,
    offset: number,
    value: string

}

interface structured_formatting {
    main_text: string,
    main_text_matched_substrings: substring[],
    secondary_text: string
}

export interface IPredication {
    description: string,
    distance_meters: number,
    id: string,
    matched_substrings: substring[],
    place_id: string,
    reference: string,
    terms: substring[],
    types: string[],
    structured_formatting: structured_formatting
}

interface IGoogleAutoCompleteProps {
    onSelect: (address: IAddress) => void;
    onReset?: () => void
    error?: boolean;
    helperText?: string;
    placeholder?: string;
}

export const GoogleAutoComplete: FC<IGoogleAutoCompleteProps> = ({ error, helperText, onSelect, onReset, placeholder }) => {
    const [address, setaddress] = useState("");

    const onChangeAddress = useCallback((address: string) => {
        setaddress(address);
        // onReset && onReset();
    }, []);

    const getDetails = useCallback(async (address: string) => {
        geocodeByAddress(address).then(
            async (response: google.maps.GeocoderResult[]) => {
                const details = !!response.length && ConvertDetailToAddress(response[0]);
                if (details) {
                    onSelect(details)
                }
            }
        )
    }, [onSelect])

    const onSelectAddress = useCallback((address: string, placeId: string) => {
        setaddress(address);
        getDetails(address)
    }, [getDetails])

    const onResetAddress = useCallback(() => {
        setaddress("");
        onReset && onReset();
    }, [onReset])

    return (

        <div className="container-auto-complete-google">
            <PlacesAutocomplete
                value={address}
                onChange={onChangeAddress}
                onSelect={onSelectAddress}
                searchOptions={{ types: ["address"] }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>

                        <div className="input-container-auto-complete-google">
                            <TextField
                                variant="outlined"
                                size='small'
                                fullWidth
                                placeholder={placeholder ??"Rua, bairro, cidade ..."}
                                autoComplete='off'
                                error={error}
                                helperText={helperText}
                                InputProps={{
                                    ...getInputProps(),
                                    // startAdornment: (
                                    //     <InputAdornment position="start">
                                    //         <Icon color="primary" >place</Icon>
                                    //     </InputAdornment>
                                    // ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {loading ? <CircularProgress color="primary" size={26} ></CircularProgress>
                                                : !address ?
                                                    <Icon>search</Icon>
                                                    :
                                                    <IconButton
                                                        size="small"
                                                        onClick={onResetAddress}
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>}
                                        </InputAdornment>
                                    )
                                }}

                            />
                        </div>
                        {/* <Button onClick={getCurrentLocation}>Localizacao Atual</Button> */}
                        {
                            address && !!suggestions.length &&
                            <div className="select-container-auto-complete-google">
                                {suggestions.map((suggestion) => {
                                    const style = suggestion.active
                                        ? { backgroundColor: '#8885', cursor: 'pointer' }
                                        : { cursor: 'pointer' };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, { style })}
                                            className="item-auto-complete-google">
                                            <Icon className="icon-auto-complete-google">place</Icon>
                                            <div className="content-auto-complete-google">
                                                <div className="main-text-auto-complete-google">{suggestion.description}</div>
                                                <div className="secondary-text-auto-complete-google"></div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        }
                    </>
                )}
            </PlacesAutocomplete>
        </div >
    )
}
