import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService"
import { CategoryRankingValue } from "../../presentation/components/categoryRanking/CategoryRankingValue"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetRankingCategoryUseCase = async (service: ISalesDashboardService, { sortField = 'total', ...params }: IUseCaseParams, parentCategoryId?:string): Promise<CategoryRankingValue> => {
    const request = makeFilterRequest({ sortField, ...params })

    return await service.getTopParentCategories(request, parentCategoryId);
}
export default GetRankingCategoryUseCase