import React from "react";
import styles from "./CieloModal.module.scss";
import { TransitionProps } from "@material-ui/core/transitions";
import { Dialog, DialogContent } from "@mui/material";
import { Slide } from "@material-ui/core";
import Button from "components/ui/Button/Button";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} style={{ backgroundColor: "transparent" }} />;
});

export interface ICieloModalProps {
    open: boolean;
    onClose: () => void;
    onClick?: () => void;
}

const CieloModal = ({ open, onClose, onClick }: ICieloModalProps) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            PaperProps={{
                style: { backgroundColor: 'transparent', boxShadow: 'none' },
            }}
        >
            <div id={styles.CieloModal}>
                <img src="/assets/icon/saas/cielo-modal-bg.png" alt="" className={styles.modalBg}/>
                <img src="/assets/icon/saas/pos-modal-bg-2.svg" alt="" className={styles.modalBgBottom}/>
                <DialogContent className={styles.modalContent}>
                    <img src="/assets/icon/saas/cielo-meep.svg" alt=""/>
                    <div className={styles.info}>
                        <h1>Bem-vindo, cliente Cielo! 🎉🥳</h1>
                        <p>Somos o sistema completo de Gestão para controlar suas vendas em parceria com a Cielo!</p>
                        <Button onClick={onClick}>Começar o tour</Button>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    )
}

export default CieloModal;