import GetEventsList from "modules/crm/application/useCases/getEventsListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import { ICrmEventListData, ICrmEventStatus, ICrmEventType } from "../intefaces/ICrmEventData";

const UseCrmEventList = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [eventsList, setEvents] = useState<ICrmEventListData>();
  const { currentLocal } = useLocal();

  const getEventsList = useCallback(async (type?: ICrmEventType, status?: ICrmEventStatus) => {
    try {
      const serviceCrm = CrmServiceApi();
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetEventsList(serviceCrm, currentLocal.id, '', type, status);
        setEvents(response);
        return response
      }
    } finally {
      setIsLoading(false);
    }
  },
    [currentLocal]
  );

  return {
    eventsList,
    isLoading,
    getEventsList,
  };
};

export default UseCrmEventList;
