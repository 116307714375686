import Layout from "components/layout/presentation/Layout";
import { PlanPaymentHistoryPage } from "./PlanPaymentHistoryPage";

export const PlanPaymentHistory = () => {
  return (
    <Layout>
      <PlanPaymentHistoryPage />
    </Layout>
  );
};
