import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import UseDashboardPage from '../UseDashboardPage'
import { Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import styles from './DashboardFichas.module.scss'
import Button from 'components/ui/Button/Button'

const DashboardFichas = () => {
    const [open, setOpen] = useState(false)
    const { delayedInvoices } = UseDashboardPage()

    useEffect(() => {
        if (delayedInvoices) {
            setOpen(delayedInvoices.ExisteFaturaAtrasada)
        }
    }, [delayedInvoices])

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className={styles.modalContainer}>
                    <h2>Você possui boletos atrasados!</h2>
                    <p>Realize o pagamento e evite o bloqueio do seu portal. Caso o pagamento já tenha sido efetuado, favor desconsiderar essa mensagem.</p>
                    <Button onClick={() => setOpen(false)}>Fechar</Button>
                </div>
            </Modal>
            <IframePage src={'/dashboard'} />
        </>
    )
}

export default DashboardFichas