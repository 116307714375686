import { IPostFacebookConfigRequest } from "modules/config/integrationsConfig/domain/dto/FacebookConfig/IPostFacebookConfigRequest";

export interface IFacebookFormErrors {
  facebookId?: string;
}

export const FacebookValidation = (formData: IPostFacebookConfigRequest, setErrors: React.Dispatch<React.SetStateAction<IFacebookFormErrors>>) => {
  let _errors: IFacebookFormErrors = {};
  let hasError = false;

  if (!formData.facebookId) {
    _errors = { ..._errors, facebookId: "Campo Pixel é obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
