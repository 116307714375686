import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Icon, IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CampaignChannel, CampaignStatus } from "modules/crm/presentation/intefaces/ICampaign";
import { ICampaign } from "modules/crm/presentation/intefaces/ICampaignMessageTotals";
import { useState } from "react";
import styles from "./_HeaderInfo.module.scss";
import { format } from "date-fns";

export interface Props {
  data?: ICampaign;
  totalCostumers?: number
  totalFail?: number
  onClickStop?: () => void
  isLoading?: boolean
  survey?: boolean
}

const CrmHeaderInfo = ({ data, totalCostumers, totalFail, onClickStop, isLoading }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false)
  return (isLoading ?
    <Skeleton style={{ marginTop: 12 }} variant="rect" height={150} />
    :
    <div className={styles.container}>

      <header>
        <div className={styles.row}>
          <div className={styles.column}>
            <img
              src="/assets/img/event.png"
              alt="event"
              className={styles.imgEvent}
            />
            <h1 className={styles.titleEvent}>
              {data?.name + " "}
              {data?.event?.name}
              {/* <IconButton onClick={() => setOpenPreview(true)}><Icon>preview</Icon></IconButton> */}
            </h1>
          </div>

        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <img src="/assets/icon/icon-calendar.png" alt="calendario" className={styles.imgRow} />
            <p className={styles.text}>
              Data de envio: <strong>{data?.sendedAt && format(new Date(data?.createdAt), 'dd/MM/yyyy HH:mm')}</strong>
            </p>
          </div>

          <div className={styles.column}>
            <Icon className={styles.icon}>mail_outlined</Icon>
            <p className={styles.text}>
              Forma de envio: <strong>{data?.channel === CampaignChannel.whatsapp ? "Whatsapp" : data?.channel === CampaignChannel.email ? "Email" : "outro"}</strong>
            </p>
          </div>

          <div className={styles.column}>
            <img src="/assets/img/people.png" alt="people" className={styles.imgRow} />
            <p className={styles.text}>
              Destinatários: <strong>{totalCostumers}</strong>
            </p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <img src="/assets/icon/campaigns/subject.svg" alt="people" className={styles.imgRow} style={{ width: 17 }} />
            <p className={styles.text}>
              Assunto: <strong></strong>
            </p>
          </div>

          <div className={styles.column}>
            <img src="/assets/icon/campaigns/sender.svg" alt="people" className={styles.imgRow} style={{ height: 22, position: 'relative', left: -1, marginRight: -1 }} />
            <p className={styles.text}>
              Remetente: <strong></strong>
            </p>
          </div>

          <div className={styles.column}>
            <img src="/assets/icon/campaigns/discarted.svg" alt="people" className={styles.imgRow} style={{ height: 20, position: 'relative', marginRight: 5 }} />
            <p className={styles.text}>
              Descartados: <strong>{totalFail}</strong>
            </p>
          </div>
        </div>
      </header>


      {
        data?.status === CampaignStatus.Running && onClickStop &&
        <div className={styles.content}>
          <div className={styles.contentAll}>
            <div className={styles.contentInfo}>
              <Button onClick={() => setDialogOpen(true)} variant={'outlined'} color={"primary"}><Icon>cancel</Icon>Interromper envio</Button>
            </div>
          </div>
        </div>
      }
      
      <Dialog open={dialogOpen}>
        <DialogTitle>Interroper envio</DialogTitle>
        <DialogContent>
          <DialogContentText>As mensagens que ja foram enviadas não poderão ser canceladas</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant={'outlined'} color={"primary"}>voltar</Button>
          <Button onClick={onClickStop} variant={'contained'} color={"primary"}><Icon>cancel</Icon>Interroper envio</Button>
        </DialogActions>
      </Dialog>

      <Drawer open={openPreview} onClose={() => setOpenPreview(false)}>
        {!!data?.messageBody &&
          <iframe
            style={{ width: "100vw", maxWidth: 1024, height: "100vh" }}
            title={"preview"}
            srcDoc={
              data?.messageBody
                .replace("<a href='[url]' target='_blank'>", "")
                .replace("<a href='[url]' target='_blank'>", "")
                .replace("</a>", "")
                .replace("</a>", "")
            }
          />}
      </Drawer>
    </div>
  );
};

export default CrmHeaderInfo;
