
import React, { FC, ForwardedRef, PropsWithoutRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './ProductPickerItem.module.scss'
import { moneyMask } from 'services/utils/Money'
import { Checkbox } from '@mui/material'
import { IProductItem } from '../../categoryAndProducts/productList/IProductItem'
import UseOnScreen from '../../categoryAndProducts/onScreen/UseOnScreen'
import { IProductItemToSelect } from './SelectProductPicker'
export interface IProductPickerItemProps {
    //propertys
    productItem: IProductItem,
    checked?: boolean;
    onCheckProduct?: (product: IProductItem, value: boolean) => void
    disabled?: boolean
    selectedProducts: IProductItemToSelect[]
}

export interface ProductItemRef {
    checkProduct: (value: boolean) => void
}

const ProductPickerItem = ({
    productItem,
    onCheckProduct,
    checked,
    disabled,
    selectedProducts
}: PropsWithoutRef<IProductPickerItemProps>, ref: ForwardedRef<ProductItemRef>) => {
    const [isChecked, setIsChecked] = useState(checked ?? false);

    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked)
        }
    }, [checked]);

    const onCheckedProductHandel = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);
            onCheckProduct?.(productItem, checked);
        },
        [onCheckProduct, productItem],
    )

    useEffect(() => {
        setIsChecked(!!productItem.checked)
    }, [productItem.checked]);

    useImperativeHandle(
        ref,
        () => ({
            checkProduct: onCheckedProductHandel
        }),
        [onCheckedProductHandel],
    )

    const imageRef = useRef<HTMLDivElement>(null);
    const isVisible = UseOnScreen(imageRef);

    return (
        <div ref={imageRef} id={styles.ProductPickerItem} style={{ opacity: disabled ? 0.5 : 1 }} >
            <div className={styles.checkButton} >
                {onCheckProduct && <Checkbox
                    disabled={disabled}
                    style={{ height: 40 }}
                    checked={selectedProducts.find(p => p.id === productItem.id) ? true : isChecked}
                    onChange={(_, _checked) => {
                        onCheckedProductHandel?.(_checked);
                    }}
                />}
            </div>
            {isVisible && <>
                {

                    <img

                        // onLoad={({ currentTarget }) => {
                        //     setimageError(true);
                        //     console.log("Load")
                        //     currentTarget.src = "/assets/icon/image_placeholder.svg";
                        // }}
                        onError={({ currentTarget }) => {
                            currentTarget.src = "/assets/icon/image_placeholder.svg";
                        }}
                        alt='Imagem produto'
                        src={productItem.image && productItem.image !== "" ? productItem.image : (productItem.imageUrl && productItem.imageUrl !== "" ? productItem.imageUrl : "/assets/icon/image_placeholder.svg")}
                        // src={"/assets/icon/image_placeholder.svg"}
                        className={styles.image}
                    />
                }
                {/* image:{productItem.image !== "" ?? productItem.imageUrl} */}
                <div className={styles.descriptions} >
                    <div className={styles.name} >
                        {productItem.name}
                    </div>
                    {<div className={styles.category} >
                        Categoria: <b>{productItem.category?.name}</b>
                    </div>}
                    {<div className={styles.value} >
                        {moneyMask(productItem.value.toFixed(2))}
                    </div>}
                    {/* <div className={styles.description} >
                        {productItem.description}
                    </div> */}
                </div>
                {/* <div className={styles.fiscal}>
                    {productItem.ncm && <div className={styles.item}>
                        <label >NCM: </label>
                        <div>{productItem.ncm}</div>
                    </div>}
                    {productItem.cest && <div className={styles.item}>
                        <label >CEST: </label>
                        <div>{productItem.cest}</div>
                    </div>}
                    {productItem.cfop && <div className={styles.item}>
                        <label >CFOP: </label>
                        <div>{productItem.cfop}</div>
                    </div>}
                </div>
                <div className={styles.printer}>
                    {productItem?.defaultPrinter?.name && <>
                        <Icon>printer</Icon>
                        {productItem?.defaultPrinter?.name}</>
                    }
                </div> */}


            </>
            }
        </div>
    )
}
export default forwardRef<ProductItemRef, IProductPickerItemProps>(ProductPickerItem)