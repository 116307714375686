import { Icon, IconButton } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './FormHeader.module.scss'
export interface IFormHeaderProps {

    //propertys
    onClickClose?: () => void
    onClickBack?: () => void
}
const FormHeader: FC<IFormHeaderProps> = ({ children, onClickClose, onClickBack }) => {
    return (
        <div id={styles.FormHeader} >
            <div className={styles.back}>
                {onClickBack && <IconButton onClick={onClickBack}><Icon>chevron_left</Icon></IconButton>}
            </div>
            <div className={styles.containerTitle}>
                <h2> {children}</h2>
            </div>
            <div className={styles.close}>
                {onClickClose && <IconButton onClick={onClickClose}><Icon>close</Icon></IconButton>}
            </div>
        </div>
    )
}
export default FormHeader
