
import { Autocomplete, TextField } from '@mui/material'
import styles from './SearchCargos.module.scss'
import Button from 'components/ui/Button/Button'
import { IGetRegisteredRolesListParams } from 'modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams';
import { useEffect, useMemo, useState } from 'react';
import UseRolesList from '../../hooks/UseRolesList';
import UseRegisteredRolesList from '../../hooks/UseRegisteredRolesList';

export interface ISearchCargosProps {
    getParams: IGetRegisteredRolesListParams;
    setGetParams: React.Dispatch<React.SetStateAction<IGetRegisteredRolesListParams>>;
}

const SearchCargos = ({ getParams, setGetParams }: ISearchCargosProps) => {
    const [searchValue, setSearchValue] = useState("");

    const { isLoading } = UseRolesList();
    const { getAllRolesList, allRolesList } = UseRolesList();
    const { registeredRolesList, setRegisteredRolesList } = UseRegisteredRolesList();
  
    useEffect(() => {
        getAllRolesList();
    }, [getAllRolesList]);

    useEffect(() => {
        if (allRolesList) {
            setRegisteredRolesList(allRolesList);
        }
    }, [allRolesList]);

    const rolesOptions = useMemo(() => registeredRolesList ?? [], [registeredRolesList]);

    const handleSearch = () => {
        if (searchValue) {
            return setGetParams({...getParams, profileIds: searchValue});
        }
        return setGetParams({ 
            ordemColumnDesc: getParams.ordemColumnDesc,
            orderByColumn: getParams.orderByColumn,
            ownerId: getParams.ownerId
        });
    };

    return (
        <div id={styles.Search}>
            <div className={styles.item}>
                <label>Cargo</label>
                <Autocomplete
                    disablePortal
                    options={rolesOptions}
                    getOptionLabel={option => option.Name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Filtrar pelo cargo"
                            InputProps={{
                                style: { padding: '0 20px 0 10px' },
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, autocompleteValues) => {
                        const rolesIds = autocompleteValues.map((it) => it.Id);
                        setSearchValue(rolesIds.join(","));
                    }}
                />
            </div>
            <Button className={styles.buttonSearch} onClick={handleSearch} disabled={isLoading}>Buscar</Button>
        </div>
    )
}
export default SearchCargos;
