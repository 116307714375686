import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetEventDiscountBaseLIstUseCase from "modules/events/application/useCases/baseList/GetEventDiscountBaseLIstUseCase";
import ListTicketUseCase from "modules/events/application/useCases/events/tickets/ListTicketUseCase";
import { IDiscountListResponse } from "modules/events/domain/dto/IEventsResponse";
import { IDiscountList } from "modules/events/presentation/components/discountsListForm/interfaces/DiscountListForm.types";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import TicketsApi from "services/api/events/tickets/TicketsApi";
import { ITicketForm } from "../../../tickets/components/ticketForm/ITicketForm";
import { IDiscountListForm, DiscountListFormStep } from "./IDiscountListForm";
import { validate } from "./validation/DiscountListFormValidation";
import { IDiscountListFormErrors } from "./validation/IDiscountListFormErrors";

const ticketService = TicketsApi();
const baseListService = DiscountBaseListApi();

const UseDiscountListForm = (discountList: IDiscountListForm, closeEditModal: () => void, handleFormDiscountList: (discountList: IDiscountListForm) => void) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [discountListForm, setDiscountListForm] = useState<IDiscountListForm>({ ...discountList });
  const [errors, setErrors] = useState<IDiscountListFormErrors>({} as IDiscountListFormErrors);
  const { showLoading, hideLoading } = useUi();
  const [formStep, setFormStep] = useState(DiscountListFormStep.BASE);
  const { currentLocal } = useLocal();
  const [productList, setProductList] = useState<ITicketForm[]>();
  const [discountsBaseList, setdiscountsBaseList] = useState<IDiscountListResponse[]>([]);

  const handleChangeDiscountListFormValue = useCallback((prop: string, value: any) => {
    setDiscountListForm(prev => ({
      ...prev,
      [prop]: value
    }));
  }, []);

  useEffect(() => {
    if (currentLocal) {
      getEventDiscountBaseList(currentLocal.id);
    }
  }, []);

  const getProductsUseCase = useCallback(async (discountList: IDiscountListResponse[]) => {
    showLoading();
    try {
      const response = await ListTicketUseCase(ticketService, eventId);
      setProductList(response.records);
    } finally {
      hideLoading();
    }
  }, [currentLocal, hideLoading, showLoading]);

  const getEventDiscountBaseList = useCallback(async (localId: string) => {
    const getEventDiscount = await GetEventDiscountBaseLIstUseCase(
      baseListService,
      localId,
      eventId
    );
    setdiscountsBaseList(getEventDiscount.records);
    getProductsUseCase(getEventDiscount.records);
  }, []);

  const handleAddDiscount = useCallback((newDiscounts: IDiscountList[]) => {
    setDiscountListForm(prev => ({ ...prev, discounts: prev.discounts ? [...prev.discounts, ...newDiscounts] : [...newDiscounts] }))
  }, []);

  const submitForm = useCallback(async () => {
    if (validate(discountListForm, discountsBaseList, setErrors)) {
      showLoading()

      handleFormDiscountList(discountListForm);
      hideLoading()
    }
  }, [handleFormDiscountList, hideLoading, showLoading, discountListForm, discountsBaseList]);

  const validateStep = useCallback(() => {
    switch (formStep) {
      case DiscountListFormStep.BASE:
        return validate(discountListForm, discountsBaseList, setErrors);
      case DiscountListFormStep.CONFIG:
        return validate(discountListForm, discountsBaseList, setErrors);
      default:
        return false;
    }
  }, [formStep, discountListForm, discountsBaseList]);

  const handleFormStep = useCallback((nextStep: DiscountListFormStep) => {
    validateStep() && setFormStep(nextStep);
  },
    [validateStep]
  );

  const handleRemoveDiscount = useCallback((index: number) => {
    const discounts = discountListForm.discounts;
    discounts.splice(index, 1);
    setDiscountListForm(prev => ({ ...prev, discounts: prev.discounts.length === 1 ? [] : discounts }))
  }, [discountListForm.discounts]);

  return {
    discountListForm,
    errors,
    formStep,
    productList,
    handleFormStep,
    handleChangeDiscountListFormValue,
    handleAddDiscount,
    handleRemoveDiscount,
    submitForm,
  };
};

export default UseDiscountListForm;
