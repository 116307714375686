import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./PrinterSectorConfig.module.scss";
import { Icon, Radio, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete, FormControlLabel } from "@mui/material";
import ProductSelector from "../productsSelector/ProductSelector";
import SwipeableViews from "react-swipeable-views";
import TabView from "components/tab/TabView";
import { InfoOutlined } from "@material-ui/icons";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import Button from "components/ui/Button/Button";
import TableForm from "./tableForm/TablePrinterForm";
import Sidesheet from "components/sidesheet/Sidesheet";
import { ITableItem, ITotemItem } from "../smartPrinterIForm/interfaces/ISmartPrinterForm";
import { toast } from "react-toastify";
export enum PrinterTypeEnum {
    order = 1,
    receipt
}

export interface IPrinter {
    id: string,
    name: string,
}
export interface IDeviceProfile {
    id: string,
    name: string,

}
export interface IProductItem {
    id: string,
    name: string,
    category?: string
}

export interface ISmartPrinterValue {
    id: string,
    name: string,
    ownerId: string,
    printerId: string,
    productsIds: string[],
    printerName: string,

    deviceProfilesIds?: string[],
    type?: PrinterTypeEnum,
    tablesIds: string[],
    totemsIds: string[],
}

export interface ISmartPrinterErrors {
    name?: string,
    printerId?: string,
    printerName?: string,
    printerType?: PrinterTypeEnum,
}

enum StepEnum {
    PRINTER = 0,
    PRINTER_TYPE = 1,
    SUCCESS = 2
}

export interface IPrinterSectorConfigProps {
    defaultValues?: ISmartPrinterValue,
    getPrinters: () => Promise<IPrinter[]>
    getTables: () => Promise<ITableItem[]>
    getTotems: () => Promise<ITotemItem[]>
    getProducts: () => Promise<IProductItem[] | undefined>
    onSubmmit: (printer: ISmartPrinterValue) => Promise<void>
    getDeviceProfiles: () => Promise<IDeviceProfile[]>
    onClose: () => void
    isSaas?: boolean

}

const PrinterSectorConfigForm: FC<IPrinterSectorConfigProps> = ({ getPrinters, defaultValues, onSubmmit, getProducts, getDeviceProfiles, onClose, getTables, getTotems, isSaas }) => {

    const [printers, setPrinters] = useState<IPrinter[]>([]);
    const [values, setValues] = useState<ISmartPrinterValue>(defaultValues ?? {} as ISmartPrinterValue);
    const [selectedPrinter, setSelectedPrinter] = useState<IPrinter | null>(null);
    const [products, setProducts] = useState<IProductItem[]>();
    const [selectedProducts, setSelectedProducts] = useState<IProductItem[]>();
    const [deviceProfiles, setDeviceProfiles] = useState<IDeviceProfile[]>();
    const [selectedDeviceProfiles, setSelectedDeviceProfiles] = useState<IDeviceProfile[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [erros, setErros] = useState<ISmartPrinterErrors>({});
    const [edited, setEdited] = useState(false);

    const [tables, setTables] = useState<ITableItem[]>();
    const [totems, setTotems] = useState<ITotemItem[]>();

    const [step, setStep] = useState<StepEnum>(StepEnum.PRINTER);

    const [disabledChangeType, setDisabledChangeType] = useState(false);
    const [saved, setSaved] = useState(false);

    const [openEditTable, setOpenEditTable] = useState(false);
    const [openEditTotem, setOpenEditTotem] = useState(false);

    useEffect(() => {
        if (defaultValues) {
            setSelectedPrinter(printers.find((printer) => printer.id === defaultValues.printerId) ?? null)
            setSelectedProducts(products?.filter((product) => defaultValues.productsIds.includes(product.id)) ?? [])
            setSelectedDeviceProfiles(deviceProfiles?.filter((deviceProfile) => defaultValues.deviceProfilesIds?.includes(deviceProfile.id) ?? []) ?? [])
            setDisabledChangeType(true)
            const _printerType = defaultValues.type ?? PrinterTypeEnum.order;
            setValues({ ...defaultValues, type: _printerType });
        }
    }, [defaultValues, deviceProfiles, printers, products]);



    useEffect(() => {
        getPrinters().then((response) => {
            setPrinters(response)
        })
    }, [getPrinters]);

    useEffect(() => {
        if (!products && values.type === PrinterTypeEnum.order)
            getProducts().then((response) => {
                setProducts(response?.filter((product)=> !!product.category));
            })
    }, [getProducts, products, values.type]);

    useEffect(() => {
        if (!deviceProfiles && values.type === PrinterTypeEnum.receipt)
            getDeviceProfiles().then((response) => {
                setDeviceProfiles(response);
            })
    }, [deviceProfiles, getDeviceProfiles, getProducts, products, values.type]);

    const changeSelectedPrinter = useCallback((printer: IPrinter | null) => {
        setSelectedPrinter(printer);
        setEdited(true);
    }, []);

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setErros((prev) => ({ ...prev, [ev.target.name]: undefined }));
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
        setEdited(true);
    }, []);

    const onChangePrinterType = useCallback((type: PrinterTypeEnum) => {
        setValues((prev) => ({ ...prev, type: type }))
        setEdited(true);
    }, [])

    const onChangeProducts = useCallback((products: IProductItem[]) => {
        setSelectedProducts(products);
        setEdited(true);
    }, [])
    const onChangeDeviceProfiles = useCallback((deviceProfiles: IDeviceProfile[]) => {
        setSelectedDeviceProfiles(deviceProfiles);
        setEdited(true);
    }, [])



    const validateStep = useCallback(() => {
        setErros({});
        if (!values.name) {
            setStep(StepEnum.PRINTER)
            setErros((prev) => ({ ...prev, name: "Campo obrigatório" }))
            return false;
        }
        if (!selectedPrinter) {
            setStep(StepEnum.PRINTER)
            setErros((prev) => ({ ...prev, printerId: "Campo obrigatório" }))
            return false;
        }

        if (values.type === PrinterTypeEnum.order) {
            setStep(StepEnum.PRINTER_TYPE)
            if (!selectedProducts?.length) {
                toast.error("Você precisa selecionar pelo menos um produto!");
                return false;
            }
        }
        if (values.type === PrinterTypeEnum.receipt) {
            setStep(StepEnum.PRINTER_TYPE)
            if (!selectedDeviceProfiles?.length) {
                toast.error("Você precisa selecionar pelo menos um equipamento!");
                return false;
            }
        }

        setErros({});
        return true;
    }, [selectedDeviceProfiles, selectedPrinter, selectedProducts, values])


    const onSubmitHandler = useCallback(async (values: ISmartPrinterValue) => {
        try {
            setIsLoading(true);
            if (!validateStep()) {
                return;
            }
            await onSubmmit({
                ...values,
                printerId: selectedPrinter?.id ?? "",
                productsIds: selectedProducts?.map((product) => product.id) ?? [],
                deviceProfilesIds: selectedDeviceProfiles?.map((deviceProfile) => deviceProfile.id) ?? [],
                totemsIds: values.totemsIds ?? [],
                tablesIds: values.tablesIds ?? [],
            })
            setEdited(false);
            setSaved(true);
            setStep(StepEnum.SUCCESS);
        } finally {
            setIsLoading(false);
        }
    }, [onSubmmit, selectedDeviceProfiles, selectedPrinter?.id, selectedProducts, validateStep])

    const onClickSubmit = useCallback(async () => {
        onSubmitHandler(values);
    }, [onSubmitHandler, values]);

    const onSubmitTableForm = useCallback(async (tablesIds: string[]) => {
        await onSubmitHandler({ ...values, tablesIds });
    }, [onSubmitHandler, values])
    const onSubmitTotemForm = useCallback(async (totemsIds: string[]) => {
        await onSubmitHandler({ ...values, totemsIds });
    }, [onSubmitHandler, values])


    const handleShowTabView = useMemo(() => {
        return (
            <TabView
                index={step}
                tabs={[
                    {
                        label: "Impressora",
                        value: StepEnum.PRINTER,
                    },
                    {
                        label: "Utilização",
                        value: StepEnum.PRINTER_TYPE,
                    },
                    {
                        label: "Concluir",
                        value: StepEnum.SUCCESS,
                        disabled: edited || values.type === PrinterTypeEnum.receipt || isSaas
                    },
                ]}
                onChangeTab={(tab) => {
                    if (validateStep()) {
                        setStep(tab.value ?? 0);
                    }
                }}
            />
        );
    }, [edited, isSaas, step, validateStep, values.type]);


    const nextProps = useMemo(() => {
        if (step === StepEnum.PRINTER) {
            return {
                onClick: () => validateStep() ? setStep(StepEnum.PRINTER_TYPE) : null,
                children: "Continuar"
            };
        }
        if (step === StepEnum.PRINTER_TYPE) {
            return {
                onClick: () => edited ? onClickSubmit() :values.type !== PrinterTypeEnum.receipt && !isSaas  ? setStep(StepEnum.SUCCESS) : onClose(),
                children: edited ? "Salvar" : values.type !== PrinterTypeEnum.receipt && !isSaas ? "Continuar" : "Fechar",
                disabled: step === StepEnum.PRINTER_TYPE && !values.type
            };
        }
        if (step === StepEnum.SUCCESS) {
            return {
                onClick: () => onClose(),
                children: "Fechar"
            };
        }
        return undefined;
    }, [step, edited, validateStep, values.type, isSaas, onClickSubmit, onClose]);

    const backProps = useMemo(() => {
        if (step === StepEnum.PRINTER) {
            return {
                onClick: () => onClose(),
                children: "Cancelar"
            };
        }
        if (step === StepEnum.PRINTER_TYPE) {
            return {
                onClick: () => setStep(StepEnum.PRINTER),
                children: "Voltar"
            };
        }
        if (step === StepEnum.SUCCESS) {
            return {
                onClick: () => setStep(StepEnum.PRINTER_TYPE),
                children: "Voltar"
            }
        }
        return undefined;
    }, [onClose, step]);

    //onFinish
    useEffect(() => {
        if (!tables && openEditTable) {
            getTables().then((response) => {
                setTables(response);
            });
        }
    }, [getTables, openEditTable, tables]);

    useEffect(() => {
        if (!totems && openEditTotem) {
            getTotems().then((response) => {
                setTotems(response);
            });
        }
    }, [getTotems, openEditTotem, totems]);

    //Form
    useEffect(() => {
        if (!tables && step === StepEnum.PRINTER_TYPE && values.type === PrinterTypeEnum.order) {
            getTables().then((response) => {
                setTables(response);
            });
        }
    }, [getTables, openEditTable, step, tables, values.type]);

    useEffect(() => {
        if (!totems && step === StepEnum.PRINTER_TYPE && values.type === PrinterTypeEnum.order) {
            getTotems().then((response) => {
                setTotems(response);
            });
        }
    }, [getTotems, openEditTotem, step, totems, values.type]);

    // const onChangeTableAndTotems = useCallback((values: { tablesIds: string[], totemsIds: string[] }) => {
    //     setValues((prev) => ({ ...prev, tablesIds: values.tablesIds, totemsIds: values.totemsIds }));
    //     setEdited(true);
    // }, []);

    return (
        <div id={styles.PrinterSectorConfigForm} >
            <div className={styles.container}>
                {handleShowTabView}
                <SwipeableViews
                    index={step}
                // onChangeIndex={(_, value) => setStep(value)}
                >
                    <div className={styles.step}>
                        <div className={styles.item}>
                            <label htmlFor="name" className={styles.required}>Nome</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="name"
                                size="small"
                                value={values.name}
                                onChange={changeHandle}
                                error={!!erros?.name}
                                helperText={erros?.name}
                            />
                        </div>
                        <div className={styles.item}>
                            <div className={styles.itemLabel}>
                                <label htmlFor="printer" className={styles.required}>Selecione a impressora</label>
                                <Tooltip title="Selecione a impressora que será utilizada para imprimir os pedidos" ><InfoOutlined /></Tooltip>
                            </div>
                            <Autocomplete
                                fullWidth
                                componentName="printer"
                                options={printers}
                                value={selectedPrinter}
                                size="small"
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        error={!!erros?.printerId}
                                        helperText={erros?.printerId}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { padding: "2px 35px 4px 10px" },
                                        }}
                                    />
                                )}
                                onChange={(_, value) => changeSelectedPrinter(value)}
                            />
                            <a target="_blank" href="https://www.youtube.com/@MeepConnect" rel="noreferrer" className={styles.newPrinter}>Não encontrei minha impressora, e agora?</a>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.instruction}>
                                <img src="/assets/icon/saas/icon-idea.svg" alt="" />
                                <span>Sabia que você pode configurar a impressão para diferentes setores da sua cozinha? <a target="_blank" href="https://www.youtube.com/@MeepConnect" rel="noreferrer">Entenda como!</a></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step} >
                        <div className={styles.checkboxContainer}>
                            <FormControlLabel
                                checked={values.type === PrinterTypeEnum.order}
                                control={<Radio color="primary" />}
                                value={PrinterTypeEnum.order}
                                disabled={disabledChangeType || isLoading}
                                label={<p>Imprimir pedidos</p>}
                                labelPlacement="end"
                                onChange={(ev) => onChangePrinterType(PrinterTypeEnum.order)}
                            />
                            <span className={styles.boxDetail}>Os produtos selecionados serão impressos nessa impressora</span>
                            {
                                values.type === PrinterTypeEnum.order &&
                                <div className={styles.productButton}>
                                    {
                                        products &&
                                        <ProductSelector
                                            list={products}
                                            onChange={onChangeProducts}
                                            defaultValues={selectedProducts}
                                            title="Impressão de produtos"
                                            instructions='Isso significa que esses produtos serão enviados para impressão, após a realização dos pedidos.'
                                            buttonLabel={
                                                <div className={styles.selectButton}>
                                                    <div>
                                                        <img src="/assets/icon/printer-products.svg" alt="" />
                                                        <b>Selecione os produtos</b>
                                                    </div>
                                                    {!!selectedProducts?.length &&
                                                        <span>{selectedProducts?.length ?? 0} produtos selecionados</span>
                                                    }
                                                </div>
                                            }
                                        />
                                    }
                                    {/* <TableAndTotemForm
                                        tableList={tables ?? []}
                                        totemList={totems ?? []}
                                        values={{
                                            tablesIds: values.tablesIds ?? [],
                                            totemsIds: values.totemsIds ?? [],
                                        }}
                                        onChange={onChangeTableAndTotems}
                                    /> */}
                                </div>
                            }
                        </div>
                        <div className={styles.checkboxContainer}>
                            <FormControlLabel
                                checked={values.type === PrinterTypeEnum.receipt}
                                control={<Radio color="primary" />}
                                value={PrinterTypeEnum.receipt}
                                disabled={disabledChangeType || isLoading}
                                label={<p>Imprimir cupom fiscal, extrato da conta e ficha</p>}
                                labelPlacement="end"
                                onChange={(ev) => onChangePrinterType(PrinterTypeEnum.receipt)}
                            />
                            <span className={styles.boxDetail}>Imprima além dos pedidos na cozinha</span>
                            {
                                values.type === PrinterTypeEnum.receipt &&
                                <div className={styles.productButton}>
                                    {
                                        deviceProfiles &&
                                        <ProductSelector
                                            list={deviceProfiles}
                                            onChange={onChangeDeviceProfiles}
                                            defaultValues={selectedDeviceProfiles}
                                            title="Seleção de dispositivos"
                                            instructions='Isso significa que quando o pedido é feito por meio dos equipamentos selecionados, o extrato da mesa e os recibos correspondentes serão enviados para impressão.'
                                            buttonLabel={
                                                <div className={styles.selectButton}>
                                                    <div>
                                                        <img src="/assets/icon/printer-devices.svg" alt="" />
                                                        <b>Selecione os equipamentos</b>
                                                    </div>
                                                    {!!selectedDeviceProfiles?.length &&
                                                        <span>{selectedDeviceProfiles?.length ?? 0} dispositivos selecionados</span>
                                                    }
                                                </div>
                                            }
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.finish}>
                        {saved && <div className={styles.item}>
                            <SidesheetFeedback text={<span><b>Tudo certo!</b> Sua impressão foi salva com sucesso!</span>} success />
                        </div>}
                        {
                            values.type !== PrinterTypeEnum.receipt && !isSaas && <>
                                <div className={styles.item}>
                                    <button onClick={() => setOpenEditTable(true)}>
                                        <Icon>settings</Icon><span>Imprimir de acordo com mesa</span><div><Icon>chevron_right</Icon></div>
                                    </button>
                                </div>
                                <div className={styles.item}>
                                    <button onClick={() => setOpenEditTotem(true)}>
                                        <Icon>phone_android</Icon><span>Imprimir de acordo com totem</span><div><Icon>chevron_right</Icon></div>
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                    {/* <SidesheetFeedback text={<span><b>Tudo certo!</b> Sua impressão foi criada com sucesso!</span>} success /> */}
                </SwipeableViews>
            </div>
            <div className={styles.buttonContainer}>
                {backProps && <Button variant="outlined" {...backProps} />}
                {nextProps && <Button variant="contained" {...nextProps} />}
            </div>
            <Sidesheet
                open={openEditTable}
                onClose={() => setOpenEditTable(false)}
                title={<h2>Mesas</h2>}>
                {
                    defaultValues && <TableForm
                        label={"Mesas"}
                        description="Isso implica que qualquer pedido feito nessas mesas será enviado para impressão."
                        tableList={tables?.map(item => ({ id: item.id, name: item.number })) ?? []}
                        defaultValue={defaultValues.tablesIds}
                        onClose={() => setOpenEditTable(false)}
                        onSubmit={onSubmitTableForm}
                    />
                }
            </Sidesheet>
            <Sidesheet
                open={openEditTotem}
                onClose={() => setOpenEditTotem(false)}
                title={<h2>Totem</h2>}>
                {
                    defaultValues && <TableForm
                        label={"Totens"}
                        description="Isso implica que qualquer pedido feito nesses totens será enviado para impressão."
                        tableList={totems ?? []}
                        defaultValue={defaultValues.totemsIds}
                        onClose={() => setOpenEditTotem(false)}
                        onSubmit={onSubmitTotemForm}
                    />
                }
            </Sidesheet>
        </div>
    )
}

export default PrinterSectorConfigForm;
