import { Icon, IconButton } from '@material-ui/core';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useError } from '../../contexts/error/ErrorContext';
import { ErrorMessage } from '../../../../../components/ui/errorMessage/ErrorMessage';
import UseIsVisible from '../isVisble/UseIsVisible'
import styles from './DashboarContainer.module.scss'

export interface IDashboarContainerProps {
    title?: string;
    tooltip?: string;
    id?: string;
    removedCharts?: string[];
    onAddChart?: (chartId?: string) => void;
    onRemoveChart?: (chartId?: string) => void;
    children: ReactNode;
    isChartContainer?: boolean;
    borderless?: boolean;
    removable?: boolean;
    span?: 1 | 2 | 3 | 4 | 5 | 6,
    width?: number | string;
    minHeight?: number,
    hideIcons?: boolean
}

const DashboarContainer: FC<IDashboarContainerProps> = ({
    title,
    id,
    children,
    tooltip,
    removedCharts,
    onRemoveChart,
    onAddChart,
    borderless = false,
    isChartContainer = true,
    removable = false,
    span = 6,
    minHeight,
    hideIcons
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const { isVisible } = UseIsVisible(containerRef, true);
    const [blur, setBlur] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { error } = useError();

    const wasRemoved = useMemo(() => {
        if (removable && id && removedCharts)
            return removedCharts.includes(id);
        return false;
    }, [id, removable, removedCharts]);

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width } = window;
            if (width < 800) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddChart = () => {
        onAddChart?.(id);
    };

    const hasError = () => {
        const containerError = error?.find(x => x.containerId === id);
        return containerError;
    }

    const getError = () => {
        const containerError = hasError();
        if (containerError) {
            return <ErrorMessage type={containerError.type} />
        }

        return null;
    }

    if (wasRemoved) return null;

    return (
        <div style={{ minHeight, gridColumn: `span ${isMobile ? 6 : span}` }} id={styles.DashboarContainer} ref={containerRef}>
            <>
                {
                    isChartContainer &&
                    <div className={styles.header} >
                        <h2>{title}</h2>
                        {!hideIcons && <>
                            <Tooltip title={title || ''} text={tooltip || ''} />

                            <>
                                <IconButton onClick={() => { setBlur(prev => !prev) }}>
                                    {
                                        blur ?
                                            <Icon>visibility_off</Icon> :
                                            <Icon>visibility_on</Icon>
                                    }
                                </IconButton>
                                {
                                    removable && <IconButton onClick={handleAddChart}><Icon>do_disturb_alt</Icon></IconButton>
                                }
                            </>
                        </>
                        }
                    </div>
                }
                <div className={`${styles.content} ${(!isChartContainer || borderless) && !hasError() ? styles.borderless : ''}`}>
                    <div className={`${styles.childContainer} ${blur ? styles.blur : ''}`}>
                        {isVisible && (getError() || children)}
                    </div>
                </div>
            </>
        </div>
    )
}
export default DashboarContainer