import { Icon, Switch } from '@material-ui/core';
import styles from './ConfigOnlinePayment.module.scss';
import { Pagination, Skeleton, TextField } from '@mui/material';
import { format } from 'date-fns';
import { UseConfigOnlinePayment } from './UseConfigOnlinePayment';
import { OnlinePaymentHistoryModal } from '../../components/onlinePaymentHistoryModal/OnlinePaymentHistoryModal';
import { ChangeOnlinePaymentModal } from '../../components/changeOnlinePaymentModal/ChangeOnlinePaymentModal';

export const ConfigOnlinePaymentPage = () => {
    const {
        selectedLocalOnlinePayment,
        historyModalOpened,
        localOnlinePayment,
        localOnlinePaymentSolicitations,
        currentPage,
        loading,
        filter,
        saving,
        changeOnlinePaymentStatusModalOpen, 
        setChangeOnlinePaymentStatusModalOpen,
        setFilter,
        setHistoryModalOpened,
        setSelectedLocalOnlinePayment,
        getData,
        handleChangeOnlinePayment,
    } = UseConfigOnlinePayment();

    return (
        <div className={styles.container}>
            <h1>Pagamentos online</h1>
            <span className={styles.subtitle}>Esses são os estabelecimentos que solicitaram pagamento online</span>

            {
                !!localOnlinePaymentSolicitations &&
                <div className={styles.downgradeContainer}>
                    <Icon className={styles.icon}>info_outlined</Icon>
                    <span className={styles.text}>
                        {localOnlinePaymentSolicitations > 1 ? 'Existem ' : 'Existe '} 
                        <b>{localOnlinePaymentSolicitations} 
                        {localOnlinePaymentSolicitations > 1 ? ' solicitações ' : ' solicitação '} 
                        de pagamento online</b> para 
                        {localOnlinePaymentSolicitations > 1 ? ' serem analisadas' : ' ser analisada'}
                    </span>
                </div>
            }

            <div className={styles.filterContainer}>
                <div>
                    <label>Buscar por estabelecimento</label>
                    <TextField
                        className={styles.textField}
                        placeholder='Digite o nome ou CNPJ'
                        size='small'
                        value={filter.keyword}
                        onChange={e => setFilter(prev => ({ ...prev, keyword: e.target.value }))}
                    />
                </div>
                <div className={styles.switchContainer}>
                    <Switch checked={filter.hasOnlinePaymentSolicitation} onChange={() => setFilter(prev => ({ ...prev, hasOnlinePaymentSolicitation: !prev.hasOnlinePaymentSolicitation }))} />
                    <span className={styles.text}>Solicitações de pagamento online</span>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={() => getData(0, filter)}>Buscar</button>
                    <button
                        onClick={() => {
                            setFilter({ keyword: '', hasOnlinePaymentSolicitation: false });
                            getData(0);
                        }}
                    >
                        Limpar
                    </button>
                </div>
            </div>

            <div className={styles.tableHeader}>
                <div>
                    <span>Local</span>
                    <span>Data da solicitação</span>
                    <span>Histórico</span>
                    <span>Operações</span>
                    <span>Status</span>
                    <button><Icon>chevron_right</Icon></button>
                </div>
            </div>
            <div className={styles.tableBody}>
                {
                    loading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                        </> :
                        <>
                            {
                                localOnlinePayment?.data.map(x => (
                                    <div>
                                        <span>{x.localName}</span>
                                        <span>{x.solicitationDate ? format(new Date(x.solicitationDate + 'Z'), 'dd/MM/yyyy HH:mm') : '-'}</span>
                                        <span><button onClick={() => { setHistoryModalOpened(true); setSelectedLocalOnlinePayment(x); }}>Acessar</button></span>
                                        <span>{x.operations?.length ? x.operations?.map(y => <span style={{ display: 'block' }}>{y}</span>) : '-'}</span>
                                        <span>
                                            <div className={x.isEnabled ? styles.enabled : styles.disabled} />
                                            {x.isEnabled ? 'Habilitado' : 'Desabilitado'}
                                        </span>
                                        <button onClick={() => { setChangeOnlinePaymentStatusModalOpen(true); setSelectedLocalOnlinePayment(x) }}><Icon>chevron_right</Icon></button>
                                    </div>

                                ))
                            }
                        </>
                }

            </div>
            <Pagination
                variant="outlined"
                shape="rounded"
                style={{ alignSelf: 'flex-end' }}
                sx={{
                    '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                    },
                    '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                    },
                }}
                className={styles.contentPagination}
                page={currentPage}
                count={localOnlinePayment?.totalPages}
                onChange={(ev, page) => getData(page, filter)}
            />

            {
                !!selectedLocalOnlinePayment &&
                <>
                    <OnlinePaymentHistoryModal open={historyModalOpened} onClose={() => setHistoryModalOpened(false)} localOnlinePayment={selectedLocalOnlinePayment} />
                    <ChangeOnlinePaymentModal loading={saving} open={changeOnlinePaymentStatusModalOpen} onClose={() => setChangeOnlinePaymentStatusModalOpen(false)} onSubmit={handleChangeOnlinePayment} enabled={selectedLocalOnlinePayment.isEnabled} />
                </>
            }
        </div>
    )
}