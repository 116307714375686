import { IGetAllMovementsResponse } from "modules/generalDashboard/domain/dto/IGetAllMovementsResponse";
import { api, apiDashGeneral } from "../Api";
import { IGetMyAccountResponse } from "modules/generalDashboard/domain/dto/IGetMyAccountResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IGetPosPaidResponse } from "modules/generalDashboard/domain/dto/IGetPosPaidResponse";
import { IGetCashlessResponse } from "modules/generalDashboard/domain/dto/IGetCashlessResponse";
import { IGetPreRechargeResponse } from "modules/generalDashboard/domain/dto/IGetPreRechargeResponse";
import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";
import { IGetTurnoverTableResponse } from "modules/generalDashboard/domain/dto/IGetTurnoverTableResponse";
import { IGetRankingCustomersResponse } from "modules/generalDashboard/domain/dto/IGetRankingCustomersResponse";
import { IGetSalesCategoriesResponse } from "modules/generalDashboard/domain/dto/IGetSalesCategoriesResponse";
import { IGetRankingOperatorsResponse } from "modules/generalDashboard/domain/dto/IGetRankingOperatorsResponse";
import { IGetRankingProductsResponse } from "modules/generalDashboard/domain/dto/IGetRankingProductsResponse";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";
import { IParamsUseCashiersEvents } from "modules/generalDashboard/presentation/hooks/UseCashiersEvents";
import { ICashierEventListResponse } from "modules/generalDashboard/domain/dto/ICashierEventResponse";
import { IGetFinancialInformationResponse } from "modules/generalDashboard/domain/dto/IGetFinancialInformationResponse";
import { IGetTicketResponse } from "modules/generalDashboard/domain/dto/IGetTicketResponse";
import qs from "qs";

const GeneralDashboardApi = (): IGeneralDashboardApiService => {
  const getMyAccount = async (localId: string): Promise<IGetMyAccountResponse> => {
    const response = await api.get(`ContaDigital/BuscarSaldoAtual/${localId}`);
    return response.data;
  };

  const getEventCashier = async (localId: string, request?: IParamsUseCashiersEvents, filter?: IFilterValue): Promise<ICashierEventListResponse> => {

    const response = await apiDashGeneral.get(`local/${localId}/general/cashiers`);
    return response.data;
  };

  const getAllMovements = async (localId: string, filter?: IFilterValue): Promise<IGetAllMovementsResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/movements?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getPosPaid = async (localId: string, filter?: IFilterValue): Promise<IGetPosPaidResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/pospaid?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getCashless = async (localId: string, filter?: IFilterValue): Promise<IGetCashlessResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/cashless?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getPreRecharge = async (localId: string, filter?: IFilterValue): Promise<IGetPreRechargeResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/pre-recharger?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getAverageTicket = async (localId: string, filter?: IFilterValue): Promise<IGetAverageTicketResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/average-ticket?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getTicket = async (localId: string, filter?: IFilterValue): Promise<IGetTicketResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/ticket?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getFinancialInformation = async (localId: string, filter?: IFilterValue): Promise<IGetFinancialInformationResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/financial-information?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getTurnoverTable = async (localId: string, sortByBable?: boolean, pagination?: { page?: number, pageSize?: number }, filter?: IFilterValue): Promise<IGetTurnoverTableResponse> => {
    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate,
      page: pagination?.page,
      pageSize: pagination?.pageSize,
      sortByTable: sortByBable,
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/table-rotation?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getRankingProducts = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingProductsResponse> => {

    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/top-products?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getRankingOperators = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingOperatorsResponse> => {

    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/top-operators?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };
  const getRankingCustomers = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingCustomersResponse> => {

    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/top-customers?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getSalesCategories = async (localId: string,  filter?: IFilterValue): Promise<IGetSalesCategoriesResponse> => {

    const params = {
      CashierIds: filter?.cashierIds,
      StartDate: filter?.startDate,
      EndDate: filter?.endDate
    }

    const response = await apiDashGeneral.get(`local/${localId}/general/top-categories?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  return {
    getMyAccount,
    getFinancialInformation,
    getAllMovements,
    getPosPaid,
    getCashless,
    getPreRecharge,
    getAverageTicket,
    getTurnoverTable,
    getTicket,
    getRankingCustomers,
    getRankingOperators,
    getRankingProducts,
    getSalesCategories,
    getEventCashier,
  };
}

export default GeneralDashboardApi;
