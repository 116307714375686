import React, { useState, useRef, useMemo, FC, ChangeEvent, useEffect } from 'react';
import JoditEditor from "jodit-react";
import { fabClasses } from '@mui/material';

interface IEditorTextProps {
    text: string;
    onChangeDescription: (value: string) => void

}

export const EditorText: FC<IEditorTextProps> = ({ text, onChangeDescription }) => {

    const editor = useRef(null)


    // const [testeDescription, setTesteDescription] = useState('')

    // const handleTeste = (value: string) => {

    //     setTesteDescription(value)
    // }

    // useEffect(() => {
    // }, [testeDescription])



    const config = useMemo(() => ({
        readonly: false,
        buttons: 'bold, underline, italic, |, ul, |, undo,redo',
        buttonsMD: 'bold, underline, italic, |, ul, |, undo,redo',
        buttonsSM: 'bold, underline, italic, |, ul',
        buttonsXS: 'bold, underline, italic, |, ul',
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        limitChars: 600,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        defaultActionOnPaste: "insert_only_text",
        processPasteFromWord: false,
        spellcheck: true,
        language: 'pt-BR',
        i18n: 'pt-BR',
    }), [])

    return (
        <JoditEditor
            ref={editor}
            value={text}
            config={config}
            //tabIndex={1} // tabIndex of textarea
            //onBlur={} // preferred to use only this option to update the content for performance reasons
            // onChange={(ev: any) => onChangeHandle(ev.target.value)}
            onChange={(value) => onChangeDescription(value)}
        />
    )
}
