import { IUpdateUserRequest } from "modules/plans/domain/dtos/IUpdateUserRequest";
import { api } from "../Api";
import { ISimpleSaasService } from "modules/plans/domain/interfaces/ISimpleSaasService";
import { IUpdateLocalRequest } from "modules/plans/domain/dtos/IUpdateLocalRequest";
import { plansUrl } from "Enviroment";

const plansBaseUrl = plansUrl;

const SimpleSaasApi = (): ISimpleSaasService => {
    const updateUser = async (request: IUpdateUserRequest): Promise<void> => {
        const response = await api.put(`/saas/user`, request);
        return response.data;
    }

    const updateLocal = async (request: IUpdateLocalRequest): Promise<void> => {
        const response = await api.put(`/saas/local`, request);
        return response.data;
    }

    const updateBaseUser = async (): Promise<void> => {
        await api.put(`${plansBaseUrl}/users`, { params: { disableError: true } });
    };

    const updateBaseLocal = async (localId: string): Promise<void> => {
        await api.put(`${plansBaseUrl}/locals/${localId}`, { params: { disableError: true } });
    };

  return { updateUser, updateLocal, updateBaseUser, updateBaseLocal };
}

export default SimpleSaasApi;
