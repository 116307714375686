import React, { FC } from "react";
import styles from "./Checkbox.module.scss";
import { Checkbox as CheckboxMaterial, CheckboxProps, FormControlLabel, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../styles/temas/MateriaUi";
export interface ICheckboxProps extends CheckboxProps {
  //propertys
  label?: string;
  labelPlacement?: "end" | "start" | "top" | "bottom"
}
const Checkbox: FC<ICheckboxProps> = ({label, labelPlacement = 'end', ...props}) => {
  return (
    <FormControlLabel
      id={styles.Checkbox}
      classes={{ label: styles.label }}
      control={<CheckboxMaterial classes={{ checked: styles.checked }} {...props}></CheckboxMaterial>}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
export default Checkbox;
