import { FC } from "react";
import styles from "../SimpleRegister/SimpleRegister.module.scss";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { Instagram, YouTube } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseSimpleForgetPassword } from "./UseSimpleForgetPassword";
import { ISimpleForgetPassSchema, simpleForgetPassSchema } from "modules/saas/domain/schema/simpleForgetPassSchema";
import { useHistory } from "react-router-dom";

export interface ISimpleForgetPasswordProps {
    //propertys
}

const SimpleForgetPassword: FC<ISimpleForgetPasswordProps> = () => {
    const { dimensions } = UseDimension();
    const { push } = useHistory();
    const { isLoading, handleRecoverPass } = UseSimpleForgetPassword();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ISimpleForgetPassSchema>({
        resolver: zodResolver(simpleForgetPassSchema)
    });

    return (
        <div id={styles.SimpleRegister}>
            <img src="/assets/img/logo-mee-white.svg" alt="Logo"/>

            <div className={styles.container}>
                <div className={styles.content}>
                    <img src="/assets/icon/restaurant-icon.svg" alt=""/>
                    <h1 style={{ marginBottom: "10px" }}>Use seu email e senha para continuar:</h1>
                    <form
                        onSubmit={handleSubmit(handleRecoverPass)}
                    >
                        <div
                            className={styles.row}
                            style={{
                                flexDirection: dimensions.width < 601 ? "column" : "row",
                                marginBottom: "10px"
                            }}
                        >
                            <div className={styles.column}>
                                <label htmlFor="email" className={styles.required}>E-mail ou CPF</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    placeholder="Digite seu email ou CPF"
                                    {...register("emailOrDocument")}
                                    helperText={errors.emailOrDocument?.message}
                                    error={!!errors.emailOrDocument}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <Button type="submit" color="secondary">
                            {isLoading ? <CircularProgress size={16} color="inherit" /> : "Recuperar"}
                        </Button>
                        <Button
                            type="button"
                            color="secondary"
                            variant="text"
                            onClick={() => push(`/public/saas/simple-login`)}
                        >
                            Voltar
                        </Button>
                    </form>                    
                </div>
            </div>

            <div className={styles.footer}>
                <div className={styles.socialMedia}>
                    <IconButton onClick={() => window.open("https://www.instagram.com/meebymeep?igsh=MWd4eDI3dmU5bzRueg==", "_blank")}><Instagram /></IconButton>
                    <IconButton onClick={() => window.open("https://www.youtube.com/@Meebymeep/videos", "_blank")}><YouTube /></IconButton>
                </div>
                <p>Feito com 💜 para o seu negócio!</p>
                <span>Rua da Bahia, 888 - Centro | Belo Horizonte/MG</span>
            </div>
        </div>
    )
}
export default SimpleForgetPassword;