import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './PartnersPage.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IPartnerFilterValue } from 'modules/meepErp/presentation/components/partners/partnersList/partnerFilter/IPartnerFilter';
import PartnerList from 'modules/meepErp/presentation/components/partners/partnersList/PartnerList';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import UseQueryState from '../../hook/UseQueryFilter';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'services/api/Api';
import DeleteErp from '../../components/ui/delete/DeleteErp';
import { PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import { getPartnersListUseCase, onDeleteLocalsUseCase } from '../../../application/useCases/partners/PartnersListUseCase';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { tabsRegister } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
export interface IPartnerProps {
    //propertys
}
const PartnersPage: FC<IPartnerProps> = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<IPartnerFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Parceiros",
            },                              
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]); 

    //ReactiQuery

    const partner = useQuery(['@partners', currentLocal?.id, filter], () => getPartnersListUseCase(currentLocal?.id!, filter), {
        enabled: !!currentLocal?.id,
    })

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<PartnerListResponse | undefined>(['@partners'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteLocalsMutation = useMutation((id: string) => onDeleteLocalsUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteLocalsMutation.mutateAsync(id);
    }, [onDeleteLocalsMutation])

    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/partners/addOrUpdate/corporate/');
    }, [push])

    const onClickEdit = useCallback(async (id: string, originType: PartnerOriginType) => {
        if (originType === PartnerOriginType.CORPORATE) {
            push(`/private/meeperp/partners/addOrUpdate/corporate/${id}`);
        } else {
            push(`/private/meeperp/partners/addOrUpdate/individual/${id}`);
        }
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])


    return (
        <div id={styles.Partner} >
            <TabsErp data={tabsRegister} />
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar parceiros
                    </Button>
                </div>
            }
            <PartnerList
                filter={filter}
                onChangeFilter={updateFilter}
                partners={partner.data}
                isLoading={partner.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={async () => {
                    await onConfirmDelete(itemToDelete!);
                }}
                title={'Excluir Parceiro'}
                text={'Tem certeza que deseja excluir este parceiro?'}
                textFeedback={'Parceiro excluido com sucesso.'}
                auxiliaryText='Ao excluir um parceiro todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
            />
        </div>
    )
}
export default PartnersPage


