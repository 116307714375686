import React, { useCallback, useMemo, useState } from 'react'
import styles from './PermutationAccountItemMobile.module.scss'
import { IPermutationAccountList, IRecordsItem } from 'modules/permutation/presentation/interfaces/IPermutationAccountList';
import { IParamsPemutationAccountList } from 'modules/permutation/presentation/hooks/UsePermutationAccountList';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import CPF from 'services/utils/CPF';
import CNPJ from 'services/utils/CNPJ';
import { moneyMaskNumber } from 'services/utils/Money';
import { IGetPermutionItemResponse } from 'modules/permutation/domain/dto/IGetPermutionItemResponse';
import Sidesheet from 'components/sidesheet/Sidesheet';
import UseDeletePermutationAccount from 'modules/permutation/presentation/hooks/UseDeletePermutationAccount';
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback';
import DeleteValidation from '../permutationAccountListContainer/deleteValidation/DeleteValidation';

interface IPermutationAccountListMobileProps {
    userItemData: IRecordsItem;
    permutationAccountList: IPermutationAccountList;
    reload: () => void;
    params: IParamsPemutationAccountList;
    getPermutationAccountItem: (accountId: string) => Promise<IGetPermutionItemResponse | undefined>
    setOpenSidesheet: React.Dispatch<React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>>
}

export const PermutationAccountItemMobile: React.FC<IPermutationAccountListMobileProps> = ({ setOpenSidesheet, getPermutationAccountItem, reload, userItemData }) => {
    const [step, setStep] = useState(1);
    const { deletePermutation, isLoadingDelete } = UseDeletePermutationAccount();
    const [openDrownDelete, setOpenDrownDelete] = useState(false)

    const formatDocument = useCallback((document: string) => {
        if (CPF.isValid(document))
            return CPF.cpfMask(document);
        else
            return CNPJ.mask(document);
    }, []);

    const openDrownEdit = useCallback(
        (accountId: string) => {
            setOpenSidesheet("isEditing");
            getPermutationAccountItem(accountId);
        },
        [getPermutationAccountItem, setOpenSidesheet]
    );

    const onClose = useCallback(() => {
        if (step === 2) {
            reload();
        }
        setStep(1)
        setOpenDrownDelete(false)
    }, [reload, step])

    const continueOrSave = useCallback(async () => {
        if (step === 1 && userItemData.hasOpenMeepAccount) {
            onClose();
        } else {
            const res = await deletePermutation(userItemData.id);
            if (res === "ok") {
                return setStep(step + 1);
            }
        }
    }, [deletePermutation, onClose, step, userItemData.hasOpenMeepAccount, userItemData.id])

    const closedOrDeleteButton = useCallback(() => {
        if (step === 1 && userItemData.hasOpenMeepAccount) {
            return "Fechar"
        } else {
            return "Excluír"
        }
    }, [step, userItemData.hasOpenMeepAccount])

    const handleContent = useCallback(() => {
        let highlightText = `${userItemData.name}?`;
        let message = `Deseja realmente excluir a permuta`;
    
        if (step === 1 && userItemData.hasOpenMeepAccount) {
          highlightText = "";
          message = "Existe cashless virtual ativo vinculado a permuta, não é possível excluir.";
        }
        
        return <DeleteValidation text={message} highlightText={highlightText} />
    }, [step, userItemData.hasOpenMeepAccount, userItemData.name])



    const openDrowDelete = useCallback(
        () => {
            setOpenDrownDelete(true);
        },
        []
    );

    const List = useMemo(() => (
        <div className={styles.containerItem}>
            <div className={styles.top}>
                <span>{userItemData.name}</span>
                <div className={styles.icon}>
                    {
                        <>
                            <EditOutlined onClick={() => openDrownEdit(userItemData.id)} />
                            <DeleteOutline onClick={openDrowDelete} />
                        </>
                    }
                </div>
            </div>
            <div className={styles.mid}>
                <span>CNPJ:</span>
                <span>{formatDocument(userItemData.document)}</span>
            </div>
            <div className={styles.footer}>
                <span>Saldo da permuta:</span>
                <span>{moneyMaskNumber(userItemData.balance)}</span>
            </div>
        </div>
    ), [formatDocument, openDrowDelete, openDrownEdit, userItemData.balance, userItemData.document, userItemData.id, userItemData.name]);

    return (
        <div id={styles.PermutationAccountListMobile}>
            {List}
            <Sidesheet
                isLoading={isLoadingDelete}
                open={openDrownDelete}
                onClose={onClose}
                title={
                    <h2>
                        Excluir <b>permuta</b>
                    </h2>
                }
                content={handleContent()}
                currentStep={step}
                totalSteps={2}
                handleContinueButton={continueOrSave}
                continueButton={closedOrDeleteButton()}
                cancelButton={!userItemData.hasOpenMeepAccount}
                feedback={
                    <SidesheetFeedback text="Permuta excluída com sucesso!" success />
                }
            />
        </div>
    )
}
