import React from "react";
import Layout from "components/layout/presentation/Layout";
import { CloseEventPage } from "modules/events/presentation/pages/closeEvent/CloseEventPage";

function CloseEvent() {
  return (
    <Layout>
      <CloseEventPage />
    </Layout>
  );
}

export default CloseEvent;
