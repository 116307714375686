import styles from "./Stepe.module.scss";

interface IStepe {
  stepe: number;
  type: string
}

const Stepe = ({ stepe, type }: IStepe) => {
  const array = Array(4).fill("");

  const classBox = (index : number) => {
    if (stepe === index + 1){
      return styles.boxActive
    } else if (stepe > index + 1){
      return styles.boxBack
    } else {
      return styles.box
    }
  }
 
  return (
    <div id={styles.Stepe}>
      {array.map((item, index: number) => {
        return (
          <div className={classBox(index)}>
            {index + 1} 
            {stepe === index + 1 && ` ${type}`}
          </div>
        );
      })}
    </div>
  );
};

export default Stepe;
