import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { useState } from "react";
import styles from "./ChangeStoreModal.module.scss";
import { IStoreItem } from "./IStore";

interface ModalProps {
  open: boolean;
  title: string;
  stores: IStoreItem[];
  onClose: () => void;
  onConfirm: (storeId: string) => void;
}

export const ChangeStoreModal = ({
  open,
  title,
  stores,
  onClose,
  onConfirm,
}: ModalProps) => {
  const [selectedStore, setSelectedStore] = useState<IStoreItem>();
  const [error, setError] = useState("");

  const handleValidate = () => {
    setError("");
    if (!selectedStore) {
      setError("Seleciona a loja");
      return;
    }
    onConfirm(selectedStore.id);
  };

  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.form}>
          <FormItemContainer label="Loja" className={styles.formContainer}>
            <AutoComplete
              variant="outlined"
              placeholder="Selecione uma loja"
              options={stores}
              getOptionLabel={(option) => option.name}
              onChange={(_evt, item) => setSelectedStore(item as IStoreItem)}
              error={!!error}
              helperText={error}
            />
          </FormItemContainer>
        </div>
        <div className={styles.buttonsContainer}>
          <Button variant="outlined" fullWidth={false} onClick={onClose}>
            Cancelar
          </Button>
          <Button fullWidth={false} onClick={handleValidate}>
            Salvar
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
