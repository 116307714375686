import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./VendaOnline.module.scss";
import {
  PhotoCamera,
  Language,
  Facebook,
  Instagram,
  WhatsApp,
  Info,
  AccessTime,
} from "@material-ui/icons";
import { IAvailableDay, IGestaoVendas } from "../../pages/interfaces/IGestaoVendas";
import Input from "components/ui/input/Input";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import { InputAdornment, MenuItem, Select, Tooltip } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import SidesheetHorario from "../sidesheetHorario/SidesheetHorario";
import { getWeekDayFromNumber } from "services/utils/Date";
import { AddressModal } from "../address/AddressModal";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";
import { FeaturedMediaEnum } from "../../pages/interfaces/FeaturedMediaEnum";
import { format, isValid } from "date-fns";
import Resizer from 'react-image-file-resizer';

export interface IFormErrors {
  logo?: string;
  description?: string;
  catalogUrl?: string;
}

interface IVendaOnline {
  values: IGestaoVendas;
  onChangeHandle: (name: string, value: any) => void;
  handleChangeDate: (days: IAvailableDay[]) => void;
  showHeader?: boolean;
  dateItens: IAvailableDay[];
  address?: IAddress;
  handleSaveAddress: (_address: IAddress) => void;
  loading: boolean;
  preloading?: boolean;
  changeDates?: boolean;
  setChangeDates?: (value: boolean) => void;
  errors: IFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<IFormErrors>>;
}

export const VendaOnlineAppMeep = ({
  values,
  onChangeHandle,
  handleChangeDate,
  showHeader = true,
  dateItens,
  address,
  handleSaveAddress,
  loading,
  preloading,
  changeDates,
  setChangeDates,
  errors,
  setErrors
}: IVendaOnline) => {
  const [openSidesheet, setOpenSidesheet] = useState(false);

  const { isMobile, dimensions } = UseDimension();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [days, setDays] = useState<number[]>([]);

  const [isValidLogo, setIsValidLogo] = useState<boolean | null>(null);
  const [isValidBanner, setIsValidBanner] = useState<boolean | null>(null);

  useEffect(() => {
    const logo = new Image();
    logo.src = values.logo ?? "";

    const banner = new Image();
    banner.src = values.banner ?? "";

    logo.onload = () => {
      setIsValidLogo(true);
    };
    banner.onload = () => {
      setIsValidBanner(true);
    };

    logo.onerror = () => {
      setIsValidLogo(false);
    };
    banner.onerror = () => {
      setIsValidBanner(false);
    };
  }, [values.banner, values.logo]);

  const fileToBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const resizeFile = (file: File): Promise<string | Blob | File | ProgressEvent<FileReader>> => new Promise((resolve) => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const onChangeHandleImg = async (ev: ChangeEvent<HTMLInputElement>) => {
    // const files = ev.currentTarget.files;
    // // eslint-disable-next-line prefer-const
    // let images: string[] = [];
    // if (files?.length) {
    //   for (let i = 0; i < files.length; i++) {
    //     const _file = files?.item(i);

    //     const base64 = _file && (await fileToBase64(_file));
    //     images.push(String(base64));
    //   }
    // }

    const fileObj = ev.target.files && ev.target.files[0];
    if (!fileObj) {
      return;
    }
    const image = await resizeFile(fileObj);

    onChangeHandle(ev.target.name, image);
  };

  useEffect(() => {
    if (changeDates) setOpenSidesheet(changeDates ? true : false);
  }, [changeDates]);

  const refInput = useRef<HTMLInputElement>(null);

  const refInputCapa = useRef<HTMLInputElement>(null);

  const onClick = () => {
    setErrors(prev => ({...prev, logo: undefined }));
    refInput.current?.click();
  };

  const onClickCapa = () => {
    refInputCapa.current?.click();
  };

  useEffect(() => {
    if (!days.length) {
      [0, 1, 2, 3, 4, 5, 6].forEach(x => {
        setDays(prev => [...prev, x])
      });
    }
  }, [dateItens, days.length]);

  const formatHour = (hour: string) => {
    if (isValid(new Date(hour))) {
      return format(new Date(hour), 'HH:mm');
    } else if (hour.length > 5) {
      return hour.slice(0, 5);
    }

    return hour;
  }

  const getAddressDescription = () => {
    if (address && (address.CEP !== "30160-011" || (address.CEP === "30160-011" && address.complement !== "5º andar"))) {
      return `${address!.street}${address!.number ? ', ' + address!.number : ''} - ${address!.neighborhood}, ${address!.city} - ${address!.state}`;
    }
    return "-";
  }

  return (
    <div className={styles.perfil} style={!showHeader ? { border: 0, marginTop: 0 } : {}}>
      {
        showHeader &&
        <>
          <p className={styles.title}>
            <span>Configure o perfil</span> do seu estabelecimento para o App:
          </p>
          <p className={styles.text}>
            Seja descoberto por muitas pessoas! Potencialize suas vendas e
            otimize sua operação!
          </p>
        </>
      }
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.formImg}>
            <div>
              <input
                name="logo"
                ref={refInput}
                id="input-file"
                accept=".jpg, .jpeg, .png"
                type="file"
                style={{ display: "none" }}
                onChange={onChangeHandleImg}
              />
              {isValidLogo ? (
                <div className={styles.boxImg} onClick={onClick}>
                  <img src={values.logo} alt="upload_img" />
                </div>
              ) : (
                <div className={styles.uploadImg} onClick={onClick} style={errors?.logo ? { border: "1px solid #f44336" } : {}}>
                  <PhotoCamera />
                  <p style={errors?.logo ? { color: "#f44336" } : {}}>
                    Selecione uma foto do seu logo{" "}
                    <span style={{ color: "#f44336" }}>*</span>
                  </p>
                </div>
              )}
            </div>
            <div className={styles.inputEstabelecimento}>
              <div className={styles.titleEstabelecimento}>
                <p className={styles.titleInput}>Nome do estabelecimento</p>
                <Tooltip
                  title="Nome do estabelecimento não pode ser alterado. Em caso de dúvidas, entre em contato com nosso suporte."
                  arrow
                >
                  <Info />
                </Tooltip>
              </div>
              <Input
                name={"estabelecimento"}
                value={!preloading ? values.localName : 'Carregando...'}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                required
                variant="outlined"
                disabled
              />
            </div>
          </div>
          <p className={styles.titleInput}>
            Descrição do local <span style={{ color: "#f44336" }}>*</span>
          </p>
          <InputTextArea
            name={"description"}
            value={values.description}
            variant="outlined"
            rows={3}
            multiline
            required
            onChange={(ev) => {
              setErrors(prev => ({...prev, description: undefined }));
              onChangeHandle(ev.target.name, ev.target.value);
            }}
            placeholder="Escreva aqui uma breve descrição do seu estabelecimento"
            error={!!errors?.description}
            helperText={errors?.description}
          />
          <p className={styles.titleInput}>
            Endereço <span style={{ color: "#f44336" }}>*</span>
          </p>
          {
            !!address &&
            <div className={styles.currentAddress}>
              <span>Atual: </span>
              <span>{getAddressDescription()}</span>
            </div>
          }
          <button className={styles.addressConfig} onClick={() => setShowAddressModal(true)}>
            Configurar endereço
          </button>


          <p className={styles.titleInput}>
            URL do estabelecimento <span style={{ color: "#f44336" }}>*</span>
          </p>
          <div className={styles.inputCatalogUrl}>
            <p style={{ marginBottom: errors?.catalogUrl ? "18px" : "" }}>https://mepay.meep.cloud/</p>
            <Input
              name={"catalogUrl"}
              value={values.catalogUrl}
              onChange={(ev) => {
                setErrors(prev => ({...prev, catalogUrl: undefined }));
                onChangeHandle(ev.target.name, ev.target.value);
              }}
              required
              variant="outlined"
              error={!!errors?.catalogUrl}
              helperText={<p style={{ fontSize: "12px", color: "#f44336" }}>{errors?.catalogUrl}</p>}
            />
          </div>


          {/* <GoogleAutoComplete onSelect={(val) => onChangeHandle("endereco", val.description) } /> */}
          <p className={styles.titleInput}>Capa</p>
          <p className={styles.descriptionImg}>
            (Tamanho 375 x 234 pixels)
          </p>
          <div>
            <input
              name="banner"
              ref={refInputCapa}
              id="input-file"
              accept=".jpg, .jpeg, .png"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeHandleImg}
            />
            {isValidBanner ? (
              <div className={styles.boxImgCapa} onClick={onClickCapa}>
                <img src={values.banner} alt="upload_img" />
              </div>
            ) : (
              <div className={styles.uploadImgCapa} onClick={onClickCapa}>
                <PhotoCamera />
                <p>Escolha uma foto para a capa do seu estabelecimento</p>
              </div>
            )}
          </div>
          <p className={styles.titleInput}>Site</p>
          <Input
            name={"siteUrl"}
            value={values.siteUrl}
            onChange={(ev) => {
              onChangeHandle(ev.target.name, ev.target.value);
            }}
            required
            variant="outlined"
            placeholder="www.seulocal.com.br"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Language color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.redeSocialContent}>
            <div>
              <p className={styles.titleInput}>Facebook</p>
              <Input
                name={"facebook"}
                value={values.facebook}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                required
                variant="outlined"
                placeholder="/Seulocal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Facebook color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <p className={styles.titleInput}>Instagram</p>
              <Input
                name={"instagram"}
                value={values.instagram}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                required
                variant="outlined"
                placeholder="@seulocal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Instagram color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <p className={styles.titleInput}>WhatsApp</p>
              <Input
                name={"whatsapp"}
                value={values.whatsapp}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                variant="outlined"
                placeholder="(xx) xxxxx-xxxx"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsApp color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={styles.redeSocialContent}>
            <div className={styles.select}>
              <p className={styles.titleInput}>Destacar informação</p>
              {
                !preloading &&
                <Select
                  name="featuredMedia"
                  size="small"
                  value={values.featuredMedia}
                  onChange={(ev) => {
                    onChangeHandle(ev.target.name, ev.target.value);
                  }}
                >
                  <MenuItem value={" "} style={{ display: "none" }}>
                    Selecione abaixo
                  </MenuItem>
                  <MenuItem value={FeaturedMediaEnum.Instagram}>
                    <Instagram
                      color="secondary"
                      style={{ marginRight: "10px", marginBottom: "-7px" }}
                    />
                    Instagram
                  </MenuItem>
                  <MenuItem value={FeaturedMediaEnum.WhatsApp}>
                    <WhatsApp
                      color="secondary"
                      style={{ marginRight: "10px", marginBottom: "-7px" }}
                    />
                    WhatsApp
                  </MenuItem>
                  <MenuItem value={FeaturedMediaEnum.Site}>
                    <Language
                      color="secondary"
                      style={{ marginRight: "10px", marginBottom: "-7px" }}
                    />
                    Site
                  </MenuItem>
                  <MenuItem value={FeaturedMediaEnum.Facebook}>
                    <Facebook
                      color="secondary"
                      style={{ marginRight: "10px", marginBottom: "-7px" }}
                    />
                    Facebook
                  </MenuItem>
                </Select>
              }
            </div>
            <div>
              <p className={styles.titleInput}>Escolha a cor principal do seu estabelecimento</p>
              <Input
                name={"color"}
                value={values.color}
                onChange={(ev) => {
                  onChangeHandle(ev.target.name, ev.target.value);
                }}
                variant="outlined"
                type="color"
                className={styles.colorInput}
              />
            </div>
          </div>

          <p className={styles.titleInput}>Horário de funcionamento:</p>

          {days.map((item, index) => {
            if (!dateItens?.find(x => x.checked && x.dayOfWeek === item))
              return <></>;

            return (
              <div className={styles.sectionHours} key={item}>
                <div className={styles.containerHour}>
                  <p>{getWeekDayFromNumber(item)}</p>
                </div>
                {dimensions.width <= 700 && (
                  <div className={styles.containerHour}>
                    {
                      dateItens?.filter(x => x.checked && x.dayOfWeek === item).map(x => (
                        <div className={styles.hours} key={x.id}>
                          <div className={styles.contentHour}>
                            <div className={styles.boxHour}>
                              <AccessTime />
                              {formatHour(x.startHour)}
                            </div>
                            <span>às</span>
                            <div className={styles.boxHour}>
                              <AccessTime />
                              {formatHour(x.endHour)}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )}
                {dimensions.width > 700 && (
                  <div className={styles.containerHour}>
                    {
                      dateItens?.filter(x => x.checked && x.dayOfWeek === item).map(x => (
                        <div className={styles.hours} key={x.id}>
                          <div className={styles.contentHour}>
                            <div className={styles.boxHour}>
                              <AccessTime />
                              {formatHour(x.startHour)}
                            </div>
                            <span>às</span>
                            <div className={styles.boxHour}>
                              <AccessTime />
                              {formatHour(x.endHour)}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )}
              </div>
            );
          })}
          <button
            className={styles.button}
            onClick={() => setOpenSidesheet(true)}
          >
            Configurar horário de funcionamento
          </button>
        </div>
        {!isMobile && (
          <img
            src="/assets/img/gestao_vendas/iphone.png"
            alt="mockup"
            style={{ marginTop: "40px" }}
          />
        )}
      </div>
      {
        !preloading &&
        <SidesheetHorario
          openSidesheet={openSidesheet}
          setOpenSidesheet={setOpenSidesheet}
          dateItens={dateItens}
          handleChangeDate={handleChangeDate}
        />
      }
      <AddressModal
        open={showAddressModal}
        onClose={() => setShowAddressModal(false)}
        onSubmit={handleSaveAddress}
        loading={loading}
        currentAddress={address}
      />
    </div>
  )
}