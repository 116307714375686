import { useCallback, useMemo } from 'react';
import styles from './PremiumHint.module.scss';
import UseOpenSaasModal from '../../hooks/UseOpenSaasModal';
import Button from 'components/ui/Button/Button';
import { useHistory } from 'react-router-dom';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

export const PremiumHint = () => {
    const { currentLocal } = useLocal();
    const { setOpenSaasModal } = UseOpenSaasModal();
    const history = useHistory();

    const isSaas = useMemo(() => currentLocal?.systemIdentifier === 1, [currentLocal]);

    const handleUpgradeClick = useCallback(() => {
        if (isSaas) {
            return setOpenSaasModal(true);
        }
        return history.push('/private/plans')
    }, [history, isSaas, setOpenSaasModal]);

    return (
        <div className={styles.container}>
            <img src="/assets/icon/golden-crown.svg" alt="" />
            <span>Esta é uma funcionalidade do <b>Plano {isSaas ? "Avançado" : "Premium"}</b></span>
            <Button variant="outlined" onClick={handleUpgradeClick}>Fazer upgrade</Button>
        </div>
    )
}