import styles from "components/sidesheet/Sidesheet.module.scss";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import { IPostFacebookConfigRequest } from "modules/config/integrationsConfig/domain/dto/FacebookConfig/IPostFacebookConfigRequest";
import { IFacebookFormErrors } from "../../../pages/configFacebook/validade/FacebookValidation";

export interface IConfigContentFacebookProps {
  step: number;
  values: IPostFacebookConfigRequest;
  setValues: React.Dispatch<React.SetStateAction<IPostFacebookConfigRequest>>;
  errors: IFacebookFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<IFacebookFormErrors>>;
}

const ConfigContentFacebook = ({
  step,
  values,
  setValues,
  errors,
  setErrors,
}: IConfigContentFacebookProps) => {
  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="facebookPixel-id" className={styles.required}>
              Número de identificação do Pixel
            </label>
            <InputTextArea
              name="facebookPixel-id"
              variant={"outlined"}
              value={values?.facebookId}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  facebookId: ev.target.value,
                }));
                setErrors((prev) => ({ ...prev, facebookId: "" }));
              }}
              rows={4}
              multiline
              error={!!errors.facebookId}
              helperText={errors.facebookId}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentFacebook;
