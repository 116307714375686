import { AddCircle } from "@material-ui/icons";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import React, { FC, useEffect } from "react";
import { ManageTitle } from "../manageTitle/ManageTitle";
import { TicketCard } from "./components/ticketCard/TicketCard";
import { ITicketForm } from "./components/ticketForm/ITicketForm";
import { TicketForm } from "./components/ticketForm/TicketForm";
import styles from "./Tickets.module.scss";
import UseTicket from "./UseTicket";

interface ITicketsProps {
  eventName: string;
  tickets: ITicketForm[];
  refreshEvent: () => Promise<void>;
}

export const Tickets: FC<ITicketsProps> = ({
  eventName,
  tickets,
  refreshEvent,
}) => {
  const {
    openForm,
    selectedTicket,
    openFormModal,
    closeFormModal,
    handleSaveTicket,
    handleToggleTicketStatus,
    handleDeleteTicket,
  } = UseTicket(refreshEvent);

  return (
    <div id={styles.Tickets}>
      <ManageTitle
        title="Ingressos"
        subTitle="Esses são seus ingressos. Você pode ativá-los ou desativalos."
      />

      <Button
        fullWidth={false}
        onClick={() => openFormModal()}
        className={styles.addTicketButton}
      >
        Adicionar ingresso
        <AddCircle />
      </Button>

      {tickets.length > 0 ? (
        <div>
          <div className={styles.header}>
            <h3>Nome</h3>
            <h3>Valor</h3>
            <h3>Status</h3>
            <div />
          </div>
          <div className={styles.ticketList}>
            {tickets.map((ticket, key) => (
              <TicketCard
                ticket={ticket}
                key={key}
                openFormModal={openFormModal}
                onToggleTicketStatus={handleToggleTicketStatus}
                onDeleteTicket={handleDeleteTicket}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.empty}>
          <h3>Nenhum ingresso encontrado!</h3>
          <p>
            Cadastre um produto do tipo ingresso e vincule ao evento desejado
            <br />
            para poder utiliza-lo como ingresso do evento.
          </p>
        </div>
      )}
      <RightDrawer
        title={
          selectedTicket.id ? (
            <span>
              Editar <strong>ingresso</strong>
            </span>
          ) : (
            <span>
              Adicionar <strong>ingresso</strong>
            </span>
          )
        }
        open={openForm}
        onClose={closeFormModal}
        className={styles.modal}
      >
        <TicketForm
          closeFormModal={closeFormModal}
          handleFormTicket={handleSaveTicket}
          ticket={selectedTicket}
          eventName={eventName}
        />
      </RightDrawer>
    </div>
  );
};
