import React, { FC } from 'react';
import styles from './CloseEvent.module.scss';
import warningImg from '../../../../../../../../res/assets/images/warning.png';
import Button from 'components/ui/Button/Button';
import UseCloseEvent from './UseCloseEvent';
import { ICloseEvent } from '../../../ICloseEvent';

interface ICloseEventProps {
  closeModal: () => void
  financialPending: boolean;
  isClosed: React.Dispatch<React.SetStateAction<boolean>>
}

export const CloseEvent: FC<ICloseEventProps> = ({ closeModal,isClosed, financialPending }) => {

  const { handleCloseEvent } = UseCloseEvent(closeModal,isClosed);

  return (
    <div id={styles.CloseEvent}>
      <div>
        <img src={warningImg} alt="warning_image" />
        <p>{financialPending ? "Existem irregularidades no seu fechamento!":"Lembre-se: essa ação não poderá ser desfeita."}</p>
      </div>
        <p>{financialPending ? "É necessário realizar as regularizações do seu evento para fechá-lo.":"Ao fechar seu evento o caixa vinculado não é fechado. Lembre-se de fechá-lo ao encerrar sua operação!"}</p>
      <div>
      
      
      <Button
          fullWidth={false}
          onClick={closeModal}
          color="primary"
          variant="outlined">
          {financialPending ? "Voltar" : "Cancelar"} 
        </Button>
        { !financialPending && (
          <Button
            fullWidth={false}
            onClick={handleCloseEvent}
            color="primary"
            variant="contained">
            Fechar evento
          </Button>
        )}
        
      </div>
    </div>
  );
};
