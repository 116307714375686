import { MenuItem, TextField } from '@material-ui/core'
import Autocomplete from 'components/ui/autoComplete/AutoComplete'
import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import InputTextArea from 'components/ui/inputTextArea/InputTextArea'
import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import React, { FC, useEffect } from 'react'
import AvailableDaysForm from '../availableDaysForm/AvailableDaysForm'
import SpecialDaysForm from '../unavailabilityForm/SpecialDaysForm'
import { IPlaceItemScheduleForm } from './interface/IPlaceItemScheduleForm'
import styles from './PlaceItemScheduleForm.module.scss'
import UsePlaceForm from './UsePlaceItemScheduleForm'
export interface IPlaceFormProps {
    orderPadsList: ISelect[];
    servicesList: ISelect[];
    defaultValues?: IPlaceItemScheduleForm;
    onSubmit: (values: IPlaceItemScheduleForm) => void;
    reloadPageEdit: () => void;
}

const PlaceItemScheduleForm: FC<IPlaceFormProps> = ({ defaultValues, onSubmit, orderPadsList: orderPadsList, servicesList, reloadPageEdit }) => {
    const { changeHandle, removeUuid, values, changeOrderPadSelectHandle, errors, changeServiceSelectHandle, onSubmitHandle, selectedOrderPads: selectedOrderPads, selectedServices, changeAvalableDaysHandle, minimumTimeType, onChangeMinimumDayTypeHandle, minimumLeadTime, onChangeMinimalLeadHandle } = UsePlaceForm(servicesList, onSubmit, defaultValues)


    return (
        <div id={styles.PlaceItemScheduleForm} >
            <form onSubmit={onSubmitHandle}>
                <FormItemContainer>
                    <div className={styles.containerHelp}>
                        <h2>Informações do Passeio</h2>
                        <TooltipInfo
                            title={"Informações do Passeio"}
                            paragraph={"Preencha as informações do seu passeio, como: lotação máxima, serviços e comandas."}
                        />
                    </div>
                    <FormItemContainer label={"Nome"}>
                        <Input
                            value={removeUuid(values.name)}
                            name={"name"}
                            onChange={changeHandle}
                            placeholder="Ex.: Nome de identificação"
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </FormItemContainer>
                    <FormItemContainer label={"Quantidade Maxima de Pessoas"}>
                        <Input
                            value={values.maxQuantity}
                            type="number"
                            name={"maxQuantity"}
                            onChange={changeHandle}
                            placeholder="Ex.: 130"
                            error={!!errors.maxQuantity}
                            helperText={errors.maxQuantity}
                        />
                    </FormItemContainer>
                    {/* <FormItemContainer label={"Comandas"}>
                        <Autocomplete
                            variant="standard"
                            value={selectedOrderPads}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) => option.id === value.id}
                            options={orderPadsList}
                            name="orderPadsIds"
                            required
                            onChange={(ev, values) => changeOrderPadSelectHandle(values as ISelect)}
                        />
                    </FormItemContainer> */}
                    <FormItemContainer label={"Serviços"}>
                        <Autocomplete
                            variant="standard"
                            multiple
                            value={selectedServices}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) => option.id === value.id}
                            options={servicesList}
                            name="servicesScheduleIds"
                            error={!!errors.selectedServices}
                            helperText={errors.selectedServices}
                            onChange={(ev, values) => changeServiceSelectHandle(values as ISelect[])}
                        />
                    </FormItemContainer>
                    {/* <FormItemContainer label={"Descrição"}>
                        <InputTextArea value={values.description} variant="outlined" rows={4} multiline required name={"description"} onChange={changeHandle} placeholder="Descrição do passeio" />
                    </FormItemContainer> */}
                    <FormItemContainer>
                        <div className={styles.containerAvailability}>
                            <div className={styles.containerHelp}>
                                <h2>Disponibilidade</h2>
                                <TooltipInfo
                                    title={"Disponibilidade"}
                                    paragraph={"Tempo para agendamento: Quanto tempo será possível agendar antes do próximo passeio/transfer/jantar. Tempo de disponibilidade: Quanto tempo será possível agendar com antecedência."}
                                />
                            </div>
                            <FormItemContainer row>
                                <FormItemContainer row>
                                    <FormItemContainer label={"Tempo para agendamento"}>
                                        <Input
                                            value={minimumLeadTime}
                                            type="number"
                                            name={"minimumLeadTime"}
                                            onChange={ev => onChangeMinimalLeadHandle(Number(ev.target.value))}
                                            placeholder="Ex.: 30"
                                            inputProps={
                                                {
                                                    inputMode: "numeric",
                                                    min: 0
                                                }
                                            }
                                        />
                                    </FormItemContainer>
                                    <FormItemContainer label={"Tipo"}>
                                        <TextField
                                            value={minimumTimeType}
                                            select
                                            style={{ width: "100px" }}
                                            onChange={(ev) => { onChangeMinimumDayTypeHandle(ev.target.value as "days" | "hours" | "minutes") }}
                                        >
                                            <MenuItem value={"minutes"} >Minutos</MenuItem>
                                            <MenuItem value={"hours"} >Horas</MenuItem>
                                            <MenuItem value={"days"} >Dias</MenuItem>
                                        </TextField>
                                    </FormItemContainer>
                                </FormItemContainer>
                                <FormItemContainer label={"Tempo de disponibilidade(dias)"}>
                                    <Input
                                        value={values.maximumLeadTimeInDays}
                                        required type="number"
                                        name={"maximumLeadTimeInDays"}
                                        onChange={changeHandle}
                                        placeholder="Ex.: 360"
                                        inputProps={
                                            {
                                                inputMode: "numeric",
                                                min: 0
                                            }
                                        }
                                    />
                                </FormItemContainer>
                            </FormItemContainer>
                        </div>
                    </FormItemContainer>
                    {/*avalableDays*/}
                    <FormItemContainer>
                        <SpecialDaysForm specialDays={values.specialDays} reloadPageEdit={reloadPageEdit}></SpecialDaysForm>
                    </FormItemContainer>
                    <FormItemContainer>
                        <AvailableDaysForm hasError={errors} values={values.availableDays} onChange={changeAvalableDaysHandle}></AvailableDaysForm>
                    </FormItemContainer>
                    <div className={styles.buttonSave} >
                        <Button
                            fullWidth={false}
                            type="submit">Salvar
                        </Button>
                    </div>
                </FormItemContainer>
            </form >
        </div >
    )
}
export default PlaceItemScheduleForm