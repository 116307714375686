import {
  IGetSegmentItemResponse,
  IGetSegmentListResponse,
} from "modules/meepErp/application/dtos/segment/IGetLSegmentListResponse";
import { ISegmentService } from "modules/meepErp/application/interfaces/_segment/ISegmentService";
import { IGetSegmentListRequest } from "modules/meepErp/application/dtos/segment/IGetSegmentListRequest";
import {
  ISaveSegmentRequest,
  IUpdateSegmentRequest,
} from "modules/meepErp/application/dtos/segment/ISaveSegmentRequest";
import { Api } from "services/api/Api";
import { ISegmentResponse } from "modules/meepErp/application/dtos/segment/ISegmentResponse";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { erpUrl } from "Enviroment";

const BASE_URL = erpUrl;

//SECTOR
const SegmentService = (): ISegmentService => {
  const api = Api();

  const getSegmentList = async (
    request: IGetSegmentListRequest
  ): Promise<IGetSegmentListResponse> => {
    const response = await api.get<IGetSegmentListResponse>(
      `${BASE_URL}/api/Inventory/SectorTypes`,
      { params: request }
    );
    return response.data;
  };

  const getSegmentItem = async (
    id: string
  ): Promise<IGetSegmentItemResponse> => {
    const response = await api.get<IGetSegmentItemResponse>(
      `${BASE_URL}/api/Inventory/SectorTypes/${id}`
    );
    return response.data;
  };

  const deleteSegment = async (id: string): Promise<void> => {
    await api.delete(`${BASE_URL}/api/Inventory/SectorTypes/${id}`);
  };

  const createSegment = async (
    data: ISaveSegmentRequest
  ): Promise<ISegmentResponse> => {
    const response = await api.post<ISegmentResponse>(
      `${BASE_URL}/api/Inventory/SectorTypes`,
      data
    );
    return response.data;
  };

  const updateSegment = async (
    data: IUpdateSegmentRequest
  ): Promise<ISegmentResponse> => {
    const response = await api.put<ISegmentResponse>(
      `${BASE_URL}/api/Inventory/SectorTypes/${data.id}`,
      data
    );
    return response.data;
  };

  const enableSegment = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/SectorTypes/${id}/Enable`);
    return response.data
  };

  const disableSegment = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/SectorTypes/${id}/Disable`);
    return response.data
  };

  return {
    getSegmentList,
    getSegmentItem,
    deleteSegment,
    createSegment,
    updateSegment,
    enableSegment,
    disableSegment
  };
};
export default SegmentService;
