
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { IAvalableDaysPlaceForm } from './interface/IAvalableDaysPlaceScheduleForm';
import { IPlaceItemScheduleForm, ISpecialDays } from './interface/IPlaceItemScheduleForm'
import { validate as uuidValidate } from 'uuid';
import { validate } from './validation/PlaceFormValidation';
import { IPlaceFormErros } from './interface/IPlaceFormErros';

const UsePlaceItemScheduleForm = (servicesList: ISelect[], onSubmit: (value: IPlaceItemScheduleForm) => void, defaultValues?: IPlaceItemScheduleForm) => {

    const [values, setValues] = useState<IPlaceItemScheduleForm>(
        defaultValues ?? {
            localId: "",
            id: "",
            name: "",
            description: "",
            minimumLeadTimeInMinutes: 0,
            // minimumLeadTimeInDays: 0,
            maximumLeadTimeInDays: 0,
            orderPadId: "",
            orderPadNumber: "",
            maxQuantity: 0,
            servicesScheduleIds: [],
            availableDays: [] as IAvalableDaysPlaceForm[],
            specialDays: [] as ISpecialDays[]
        })

    const [selectedOrderPads, setSelectedOrderPads] = useState<ISelect>({ description: defaultValues?.orderPadNumber ?? "", id: defaultValues?.orderPadId ?? "" });
    const [selectedServices, setSelectedServices] = useState<ISelect[]>([]);

    const [minimumLeadTime, setMinimumLeadTime] = useState(0)
    const [minimumTimeType, setMinimumTimeType] = useState<"days" | "hours" | "minutes">("minutes")
    const [errors, setErrors] = useState<IPlaceFormErros>({});



    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
            setMinimumLeadTime(defaultValues.minimumLeadTimeInMinutes)
            setMinimumTimeType("minutes");
            setSelectedOrderPads({ description: defaultValues?.orderPadNumber, id: defaultValues?.orderPadId });
            setSelectedServices(defaultValues.servicesScheduleIds.map(item => ({ description: servicesList.find(_item => (_item.id === item))?.description ?? "", id: item })))
        }
    }, [defaultValues, servicesList])


    const removeUuid = (name: string) => {
        const nameArray = name?.split(" ")
        const findUuid = nameArray?.find(uuid => uuidValidate(uuid))
        const nameFind = nameArray?.filter(item => item !== findUuid).join(" ")
        return nameFind
    }


    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeOrderPadSelectHandle = useCallback((values: ISelect) => {
        setSelectedOrderPads(values);
    }, []);

    const changeServiceSelectHandle = useCallback((values: ISelect[]) => {
        setSelectedServices(values);
    }, []);

    const changeAvalableDaysHandle = useCallback((values: IAvalableDaysPlaceForm[]) => {
        setValues((prev) => ({ ...prev, availableDays: values }));
    }, [])


    const onChangeMinimalLeadHandle = useCallback((value: number) => {

        setMinimumLeadTime((prev) => (value));

    }, []);

    const onChangeMinimumDayTypeHandle = useCallback((value: "days" | "hours" | "minutes") => {
        setMinimumTimeType(value)
    }, []);


    const calcMinimalLeadTimeInMinutes = useCallback(() => {

        let valueMinimalLeadTimeInMinutes = minimumLeadTime
        if (minimumTimeType === "hours") {
            valueMinimalLeadTimeInMinutes = minimumLeadTime * 60
        }
        if (minimumTimeType === "days") {
            valueMinimalLeadTimeInMinutes = minimumLeadTime * 60 * 24
        }

        return valueMinimalLeadTimeInMinutes
    }, [minimumLeadTime, minimumTimeType])




    const onSubmitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (selectedOrderPads) {

            const newValues: IPlaceItemScheduleForm = {
                ...values,
                orderPadId: selectedOrderPads.id,
                orderPadNumber: selectedOrderPads.description,
                servicesScheduleIds: selectedServices.map(item => item.id),
                minimumLeadTimeInMinutes: calcMinimalLeadTimeInMinutes()
            }
            if (validate(values, setErrors, selectedServices)) {

                onSubmit(newValues);
            }
        }
    }, [selectedOrderPads, values, selectedServices, calcMinimalLeadTimeInMinutes, onSubmit]);

    return (
        {
            values,
            changeHandle,
            changeOrderPadSelectHandle,
            changeServiceSelectHandle,
            onSubmitHandle,
            selectedOrderPads,
            selectedServices,
            changeAvalableDaysHandle,
            onChangeMinimalLeadHandle,
            onChangeMinimumDayTypeHandle,
            calcMinimalLeadTimeInMinutes,
            minimumLeadTime,
            minimumTimeType,
            removeUuid,
            errors
        }
    )
}

export default UsePlaceItemScheduleForm
