import { IEditUsabilityRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editUsability/IEditUsabilityRequest";
import { IDeviceProfileService } from "modules/config/deviceConfig/domain/interface/IDeviceProfileService"
import { IUsabilityValues } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/usabilityForm/IUsabilityValues";

const EditUsabilityUseCase = async (service: IDeviceProfileService, localId: string, profileId: string, values: IUsabilityValues): Promise<void> => {
    const parsedValues: IEditUsabilityRequest = {
        ...values,
        homeScreenShortcuts: values?.homeScreenShortcuts?.join(';')
    }
    const response = await service.editUsability(localId, profileId, parsedValues);
    return (response)
}
export default EditUsabilityUseCase