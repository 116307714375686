import React from "react";
import style from "./TabProps.module.scss";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: string | number;
    value: string | number;
    className?: string;
}

export const TabPanel: React.FC<ITabPanelProps> = ({
    index,
    value,
    children,
    className
}) => {
    return (
        <div
            className={className}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ height: "100%" }}
        >
            {value === index && (
                <div className={style.tabProps}>{children}</div>
            )}
        </div>
    );
};


