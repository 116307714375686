import { useEffect, useMemo } from "react";
import { UseTransactionReportPage } from "../../hooks/UseTransactionReportPage";
import styles from "./DetailsSidesheet.module.scss";
import { format } from "date-fns";
import { IGetTransactionDetailsResponse } from "modules/transactionReport/domain/dto/IGetTransactionDetailsResponse";
import { Divider, Skeleton } from "@mui/material";

interface IDetailsSidesheetProps {
    transactionId: string;
}

const DetailsSidesheet = ({ transactionId }: IDetailsSidesheetProps) => {
    const { getTransactionDetails, transactionDetails, isLoading } = UseTransactionReportPage();

    useEffect(() => {
        getTransactionDetails(transactionId);
    }, []);

    const transaction = useMemo(() => transactionDetails ?? {} as IGetTransactionDetailsResponse, [transactionDetails]);

    const skeletons = Array.from({ length: 13 }, (_, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: '1rem' }} />
    ));

    return (
        <div id={styles.DetailsSidesheet}>
            {isLoading && skeletons}
            {!isLoading && transaction.Id && (
                <>
                    <div className={styles.item}>
                        <span className={styles.title}>Data/hora da transação:</span>
                        <span>{format(new Date(transaction.TransactionDate + "Z"), "dd/MM/yyyy' - 'HH:mm")}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>ID da transação:</span>
                        <span>{transaction.Id ?? "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>ID do pedido:</span>
                        <span>{transaction.OrderId ?? "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Código da adquirente:</span>
                        <span>{transaction.AcquirerCode ?? "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Nome da adquirente:</span>
                        <span>{transaction.AcquirerName ?? "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Conta bancária:</span>
                        <span>{transaction.BankAccount ?? "-"}</span>
                    </div>
                    <Divider />
                    <div className={styles.item}>
                        <span className={styles.title}>Parcelas:</span>
                        <span>{transaction.Installments}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Data de recebimento:</span>
                        <span>{format(new Date(transaction.ReceiptDate + "Z"), "dd/MM/yyyy")}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Percentual da taxa:</span>
                        <span>{transaction.PercentFee ? `${transaction.PercentFee}%` : "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Valor da taxa:</span>
                        <span>{transaction.FeeValue ? `R$ ${transaction.FeeValue.toString().replace(".", ",")}` : "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Valor bruto:</span>
                        <span>{transaction.GrossValue ? `R$ ${transaction.GrossValue.toString().replace(".", ",")}` : "-"}</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.title}>Valor líquido:</span>
                        <span>{transaction.NetValue ? `R$ ${transaction.NetValue.toString().replace(".", ",")}` : "-"}</span>
                    </div>
                    <Divider />
                    <div className={styles.item}>
                        <span className={styles.title}>Taxa de antecipação:</span>
                        <span>{transaction.AdvanceFeePercentage ? `${transaction.AdvanceFeePercentage}%` : "-"}</span>
                    </div>
                </>
            )}
        </div>
    )
}

export default DetailsSidesheet;