import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styles from "./ContentBox.module.scss";
import React, { FC, useState } from "react";

export interface Props {
  children?: JSX.Element;
  type?: "Whatsapp" | "Email";
  status?: boolean
}

const ContentBox:FC<Props> = ({ children, status, type }: Props) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <div className={status ? styles.statusTrue : styles.statusFalse}>
        {status ? "Configurado" : "Não configurado"}
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentEventImg}>
            {type === "Email" ? (
              <MailOutlineIcon className={styles.mailIcon} />
            ) : (
              <WhatsAppIcon className={styles.whatsappIcon} />
            )}
          </div>
          <div className={styles.contentAll}>
            <h1 className={styles.titleEvent}>{type}</h1>
            <div className={styles.contentInfo}>
              <div className={styles.info}>
                <p className={styles.text}>
                  {type === "Email"
                    ? "Ferramenta de envio de emails para seus clientes, diretamente do CRM Meep"
                    : "Ferramenta de envio de mensagens via Whatsapp para seus clientes, diretamente do CRM Meep"}
                </p>
                {!open ? (
                  <KeyboardArrowDownIcon
                    className={styles.arrowIcon}
                    onClick={() => setOpen(!open)}
                  />
                ) : (
                  <ExpandLessIcon
                    className={styles.arrowIcon}
                    onClick={() => setOpen(!open)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {open && <div className={styles.steps}>{children}</div>}
      </div>
    </>
  );
};

export default ContentBox;
