import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import ParseAddPermutationAccountRequestUseCase from "./ParseAddPermutationAccountRequestUseCase ";
import { IAddPermutationAccount } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";


export const AddPermutationAccountUseCase = async (service: IPermutationApiService, request: IAddPermutationAccount, localId: string): Promise<void> => {

    const parse = ParseAddPermutationAccountRequestUseCase(request, localId);
    const _request = await service.postAddPermutationAccount(parse);
    return _request;
}


