import { erpUrl } from "Enviroment";
import { IGetSupplyStockListRequest, IGetSupplyStockListResponse, ISupplyStockTotalResponse } from "modules/meepErp/application/dtos/supplyStock/IGetSupplyStockListResponse"
import { ISupplyStockService } from "modules/meepErp/application/interfaces/supplyStock/ISupplyStockService"
import { Api } from "services/api/Api";


const BaseUrl = erpUrl + '/api';
const SupplyStockService = (): ISupplyStockService => {
  const api = Api();
  const getSupplyStockList = async (request: IGetSupplyStockListRequest) => {
    const response = await api.get<IGetSupplyStockListResponse>(`${BaseUrl}/Inventory/supplyStocks`, { params: request })
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return response.data;
  }

  const getSupplyStockTotal = async () => {
    const response = await api.get<ISupplyStockTotalResponse>(`${BaseUrl}/Inventory/supplyStocks/total-cost`)
    console.log(response.data, 'response initial')
    return response.data;
  }

  return {
    getSupplyStockList,
    getSupplyStockTotal
  }
}
export default SupplyStockService
