import { IOnBoardService } from "modules/onBoardingTutorials/domain/Interfaces/IOnBoardService"
import { api } from "../Api"
import { IOnBoardingItem, IOnBoardingItemRequest } from "modules/onBoardingTutorials/domain/models/IOnBoardingItem"
import PlansApi from "../plans/PlansApi"
import { plansUrl } from "Enviroment"


const PLANS_API = plansUrl;

const OnBoardingService = (): IOnBoardService => {
    const getOnBoarding = async (localId: string): Promise<IOnBoardingItem[]> => {
        const response = await api.get(`${PLANS_API}/onboardings/`, { params: { localId } })
        return response.data
    }
    const setOnBoarding = async (onBoarding: IOnBoardingItemRequest): Promise<void> => {
        await api.post(`${PLANS_API}/onboardings`, onBoarding)
    }
    const resetOnBoarding = async (localId: string): Promise<void> => {
        await api.delete(`${PLANS_API}/onboardings/restart/${localId}`)
    }
    return ({
        getOnBoarding,
        setOnBoarding,
        resetOnBoarding
    })
}
export default OnBoardingService