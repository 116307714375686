import UseDimension from "components/dimension/UseDimension";
import styles from "../Sidesheet.module.scss";
import { ReactElement } from "react";

interface ISidesheetFeedbackProps {
  text: string | ReactElement;
  success?: boolean;
  helperText?: ReactElement | string;
}

const SidesheetFeedback = ({
  text,
  success,
  helperText,
}: ISidesheetFeedbackProps) => {
  const { isMobile } = UseDimension();

  return (
    <>
      <div
        className={`${styles.successContent} ${
          isMobile && styles.successContentMobile
        }`}
      >
        <img
          src={
            success
              ? "/assets/icon/check-icon-integration.svg"
              : "/assets/icon/warning-icon.svg"
          }
          alt={success ? "Sucesso" : "Aviso"}
        />
        <div className={styles.successText}>
          <span>{text}</span>
        </div>
      </div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </>
  );
};

export default SidesheetFeedback;
