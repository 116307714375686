import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage';
import React, { FC, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styles from './AddProductPage.module.scss'
export interface IAddProductPageProps {
    //propertys
}
const AddProductPage: FC<IAddProductPageProps> = () => {

    // const { productId } = useParams<{ productId: string }>();
    const { push } = useHistory()
    const { toast } = useUi()
    // const { path } = useRouteMatch()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            { label: "Cadastro", title: "Produtos", url: '/private/cadastros/produtos' },
            { title: "Adicionar produto" },
        ])
    }, [updateRouters])

    
    useEffect(() => {

        const eventCB = (ev: MessageEvent<any>) => {
            if (ev.data.status === "success") {
                toast("Produto salvo com sucesso", "success");
                push("/private/products")
            } else if (ev.data.action === "PRODUCT_BACK") {
                push("/private/products/lista")
            }
        }

        window.addEventListener("message", eventCB)

        return () => {
            window.removeEventListener("message", eventCB)
        }
    }, [push, toast])

    return (
        <div id={styles.AddProductPage} >
            <IframePage src={'/produtos/produto/cadastro/'} />
        </div>
    )

}
export default AddProductPage