import {
  TextField,
} from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AutocompleteInput from "modules/meepErp/presentation/components/autocomplete/AutocompleteInput";
import { IProductListFilter } from "modules/paymentLink/application/useCases/GetSimpleProductsListUseCase";
import {
  IPaymentLinkValuesForm,
  IPaymentlinkValuesErrors,
} from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";
import {
  IGetSimpleProductsListResponse,
  IProductSimpleItemResponse,
} from "modules/products/domain/dto/IGetProductsListResponse";
import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import styles from "./ProductForm.module.scss";
import { IPaymentlinkProductErrors } from "modules/paymentLink/presentation/interfaces/IPaymentProductForms";

function formatValue(value: string) {
  let numberValue = parseFloat(value.replace(",", "."));
  const result = numberValue.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return result;
}

export interface IProductForm {
  handleChangeValues: (name: string, value: string | boolean | number) => void;
  errors: IPaymentlinkProductErrors;
  valuesFormValues?: IPaymentLinkValuesForm;
  getProductsList: (
    request: IProductListFilter
  ) => Promise<IGetSimpleProductsListResponse>;
}

const ProductForm: FC<IProductForm> = ({
  errors,
  handleChangeValues,
  valuesFormValues,
  getProductsList,
}) => {
  const valueRef = useRef<HTMLInputElement>(null);

  const { isMobile } = useUi();

  const [values, setValues] = useState<IPaymentLinkValuesForm>(
    valuesFormValues
      ? {
          ...valuesFormValues,
          price: valuesFormValues.price
            ? formatValue(valuesFormValues.price)
            : "",
          productName: valuesFormValues.productName,
        }
      : { productId: "", price: "" }
  );

  const [searchProduct, setSearchProduct] =
    useState<IProductSimpleItemResponse>({
      id: valuesFormValues?.productId ?? "",
      name: valuesFormValues?.productName ?? "",
      value: Number(valuesFormValues?.price ?? "0"),
      isVariable: valuesFormValues?.isVariable,
    });

  const handleProductChange = useCallback(
    (product?: IProductSimpleItemResponse) => {
      if (product) {
        setSearchProduct(product);
        setValues((prev) => ({
          ...prev,
          productId: product.id,
          price: product.value?.toFixed(2),
          productName: product.name,
          isVariable: product.isVariable,
        }));
        handleChangeValues("productId", product.id);
        handleChangeValues("productName", product.name);
        handleChangeValues(
          "price",
          product.isVariable ? "0" : product.value?.toFixed(2)
        );
        handleChangeValues("isVariable", !!product?.isVariable);
      }
    },
    [handleChangeValues]
  );

  const inputMoneyMask = useCallback((value?: string) => {
    if (!value) {
      return undefined;
    }
    value = value.replace(/\D/g, "");
    while (value.length < 3) {
      value = "0" + value;
    }
    value = value.replace(/^0+(?=\d{3,})/, "");

    let maskedValue = "R$ " + value.slice(0, -2) + "," + value.slice(-2);
    if (value === "000") {
      if (valueRef.current) {
        valueRef.current.value = "";
      }
      return undefined;
    }
    return maskedValue;
  }, []);

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;
      handleChangeValues("price", value);
      setValues((prev) => ({
        ...prev,
        price: Number(value) === 0 ? "" : value,
      }));
    },
    [handleChangeValues]
  );
 

  return (
    <div id={styles.ValuesForm}>
      <div
        className={styles.productValueContainer}
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        <div className={styles.product}>
          <label>
            Selecione o produto<span> *</span>
          </label>
          <AutocompleteInput
            getOptionLabel={(option) => option?.name}
            textInput={searchProduct.name}
            getList={(request) =>
              getProductsList({ ...request, name: request.keyword })
            }
            onSelect={handleProductChange}
            value={
              {
                id: searchProduct.id,
                name: searchProduct.name,
                value: searchProduct.value,
                isVariable: searchProduct.isVariable,
              } as IProductSimpleItemResponse
            }
            error={!!errors?.product}
            helperText={errors?.product}
          />
        </div>
        <div className={styles.value}>
          <label>
            Valor<span> *</span>
          </label>
          <TextField
            inputRef={valueRef}
            size="small"
            placeholder={"R$ 0,00"}
            value={
              searchProduct.isVariable
                ? "Variável"
                : inputMoneyMask(values?.price)
            }
            disabled={searchProduct.isVariable}
            variant="outlined"
            name={"value"}
            error={!!errors?.price}
            helperText={errors?.price}
            onChange={handleChangeValue}
          />
        </div>
      </div>      
    </div>
  );
};

export default ProductForm;
