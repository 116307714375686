import { IAddDeviceProfileRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/addDeviceProfile/IAddDeviceProfileRequest";
import { IAddDeviceProfileResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/addDeviceProfile/IAddDeviceProfileResponse";
import { IEditPayemntConfigRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDevicePaymentMethods/IEditPayemntConfigRequest";
import { IEditPayemntConfigResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDevicePaymentMethods/IEditPayemntConfigResponse";
import { IEditDeviceOperationRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceOperation/IEditDeviceOperationRequest";
import { ICopyDeviceProfileByIdResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/ICopyDeviceProfileByIdResponse";
import { IGetOperationListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceOperatorList/IGetOperationListResponse";
import { IDeviceProfileService } from "modules/config/deviceConfig/domain/interface/IDeviceProfileService";
import { IEditDeviceOpertorsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceOperators/IEditDeviceOpertorsRequest";
import { IEditDeviceEnvironmentRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceEnvironment/EditDeviceAmbientRequest";
import { EditDeviceInvoiceRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceInvoice/EditDeviceInvoiceRequest";
import { IEditDeviceOperatorsResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceOperators/IEditDeviceOperatorsResponse";
import { IEditDeviceEnvironmentResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceEnvironment/IEditDeviceEnvironmentResponse";
import { EditDeviceInvoiceResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceInvoice/EditDeviceInvoiceResponse";
import { IEditDeviceOperationResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceOperation/IEditDeviceOperationResposne";
import { IGetDeviceProfileResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceProfileById/IGetDeviceProfileByIdResponse";
import { IFilterDeviceProfileRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceProfile/IFilterDeviceProfileRequest";
import { IGetDeviceProfileListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceProfile/IGetDeviceProfileListResponse";
import { IGetDeviceCatalogListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceCatalogList/IGetDeviceCatalogListResponse";
import { IEditDeviceCatalogRequest, IEditDeviceCatalogResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceCatalog/IEditDeviceCatalogRequest";
import { IGetPaymentMethodsResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getPaymentMethods/IGetPaymentMethodsResponse";
import { IGetOperatorListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/IGetOperatorListResponse";
import { IEditMassiveDeviceProfileRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editMassiveDeviceProfile/IEditMassiveDeviceProfileRequest";
import { IEditMassiveDeviceProfileResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editMassiveDeviceProfile/IEditMassiveDeviceProfileResponse";
import { GetOperadores } from "./antiCorruption/BuscarOperadores";
import { BuscaListaDeProdutos } from "./antiCorruption/BuscarListaDeProdutos";
import { SystemType } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue";
import { PaymentType } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/devicePaymentForm/interfaces/IDevicePaymentValue";
import { IEditOperationParamsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editOperationParams/IEditOperationParamsRequest";
import { IEditOperationParamsResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editOperationParams/IEditOperationParamsResponse";
import { IEditDeviceCustomerIdentificationRequest, IEditDeviceCustomerIdentificationResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceCustomerIdentification/EditDeviceCustomerIdentification";
import { IEditGeneralParamsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editGeneralParams/IEditGeneralParamsRequest";
import { IEditUsabilityRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editUsability/IEditUsabilityRequest";
import { IEditUserInterfaceRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editUserInterface/IEditUserInterfaceRequest";
import { IEditPrinterParamsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editPrinterParams/IEditPrinterParamsRequest";
import { deviceConfigApi } from "../../equipamento/DeviceConfigApi";
import { IEditProductsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editProducts/IEditProductsRequest";
import { IEditPagSeguroParamsRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editPagSeguroParams/IEditPagSeguroParamsRequest";
import { ITerminalValues } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/terminalForm/ITerminalValues";
import { api } from "services/api/Api";
import { ICustomPaymentResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getCustomPaymentList/ICustomPaymentResponse";
import { BuscarFormasPagamentosCustomizados } from "./antiCorruption/BuscarFormasPagamentosCustomizados";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const DeviceProfileApi = (): IDeviceProfileService => {

    const getDeviceProfileList = async (localId: string, filter: IFilterDeviceProfileRequest): Promise<IGetDeviceProfileListResponse> => {
        const response = await deviceConfigApi.get<IGetDeviceProfileListResponse>( "/locals/" + localId + "/profiles", { params: filter })
        return response.data
        // return Promise.resolve(deviceProfileListMock)
    }

    const getDeviceProfileById = async (localId: string, profileId: string): Promise<IGetDeviceProfileResponse> => {
        const response = await deviceConfigApi.get<IGetDeviceProfileResponse>( "/locals/" + localId + "/profiles/" + profileId)
        return response.data
    }

    const editDeviceProfile = async (localId: string, profileId: string, values: IAddDeviceProfileRequest): Promise<IAddDeviceProfileResponse> => {
        const response = await deviceConfigApi.put<IGetDeviceProfileResponse>( "/locals/" + localId + "/profiles/" + profileId, values);
        return response.data
    }

    const editTerminal = async ( localId: string, profileId: string, body: ITerminalValues): Promise<any> => {
        const response = await deviceConfigApi.put( `locals/${localId}/profiles/${profileId}/terminal`, body);
        return response.data
    }

    const addDeviceProfile = async (localId: string, profileId: string, values: IAddDeviceProfileRequest): Promise<IAddDeviceProfileResponse> => {
        const response = await deviceConfigApi.post<IGetDeviceProfileResponse>( "/locals/" + localId + "/profiles/", values);
        return response.data
    }

    const copyDeviceProfileById = async (localId: string, profileId: string): Promise<ICopyDeviceProfileByIdResponse> => {
        const response = await deviceConfigApi.post<IGetDeviceProfileResponse>( `/locals/${localId}/profiles/${profileId}/duplicate`);
        return response.data
    }

    const deleteProfileById = async (localId: string, profileId: string): Promise<void> => {
        await deviceConfigApi.delete("/locals/" + localId +"/profiles/" + profileId);
    }

    const editDeviceOperation = async (locaId: string, profileId: string, values: IEditDeviceOperationRequest): Promise<IEditDeviceOperationResponse> => {
        const response = await deviceConfigApi.put<IGetDeviceProfileResponse>( "/locals/" + locaId + "/profiles/" + profileId + "/operation/methods", values);
        return response.data
    }

    const editDevicePayment = async (localId: string, profileId: string, values: IEditPayemntConfigRequest): Promise<IEditPayemntConfigResponse> => {
        const response = await deviceConfigApi.put<IEditPayemntConfigResponse>( "/locals/" + localId + "/profiles/" + profileId + "/payment", values);
        return response.data
    }

    const editGeneralParams = async (localId: string, profileId: string, values: IEditGeneralParamsRequest): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>( "/locals/" + localId + "/profiles/" + profileId + "/general", values);
    }

    const enable = async (localId: string, profileId: string): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>("/locals/" + localId + "/profiles/" + profileId + "/enable");
    }

    const disable = async (localId: string, profileId: string): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>("/locals/" + localId + "/profiles/" + profileId + "/disable");
    }

    const editUsability = async (localId: string, profileId: string, values: IEditUsabilityRequest): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>( "/locals/" + localId + "/profiles/" + profileId + "/usability", values);
    }

    const editUserInterface = async (localId: string, profileId: string, values: IEditUserInterfaceRequest): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>( "/locals/" + localId + "/profiles/" + profileId + "/user-interface", values);
    }

    const editPrinterParams = async (localId: string, profileId: string, values: IEditPrinterParamsRequest): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>("/locals/" + localId + "/profiles/" + profileId + "/printer", values);
    }

    const editPagSeguroParams = async (localId: string, profileId: string, values: IEditPagSeguroParamsRequest): Promise<void> => {
        await deviceConfigApi.put<IEditPayemntConfigResponse>("/locals/" + localId + "/profiles/" + profileId + "/pagseguro-acquirer-configuration", values);
    }

    const editDeviceOperators = async (localId: string, profileId: string, values: IEditDeviceOpertorsRequest): Promise<IEditDeviceOperatorsResponse> => {
        const response = await deviceConfigApi.put<IEditDeviceOperatorsResponse>( "/locals/" + localId + "/profiles/" + profileId + "/operators", values);
        return response.data
    }

    const editDeviceEnvironments = async (localId: string, profileId: string, values: IEditDeviceEnvironmentRequest): Promise<IEditDeviceEnvironmentResponse> => {
        const response = await deviceConfigApi.put<IEditDeviceEnvironmentResponse>( "/locals/" + localId + "/profiles/" + profileId + "/environments", values);
        return response.data
    }

    const editDeviceCatalog = async (localId: string, profileId: string, values: IEditDeviceCatalogRequest): Promise<IEditDeviceCatalogResponse> => {
        const response = await deviceConfigApi.put<IEditDeviceCatalogResponse>( "/locals/" + localId + "/profiles/" + profileId + "/catalogs", values);
        return response.data
    }

    const editOperationParams = async (localId: string, profileId: string, values: IEditOperationParamsRequest): Promise<IEditOperationParamsResponse> => {
        const response = await deviceConfigApi.put<{id: string}>(`/locals/${localId}/profiles/${profileId}/operation`, values);
        return response.data;
    }

    const editDeviceInvoice = async (localId: string, profileId: string, values: EditDeviceInvoiceRequest): Promise<void> => {
        await deviceConfigApi.put<EditDeviceInvoiceResponse>(`/locals/${localId}/profiles/${profileId}/invoice`, values)
    }

    const getDeviceCatalogListByLocalId = async (localId: string): Promise<IGetDeviceCatalogListResponse> => {
        const response = await BuscaListaDeProdutos(deviceConfigApi, localId)
        return response
    }

    const getOperationListByLocalId = async (localId: string, systemIdentifier?: SystemIdentifierEnum): Promise<IGetOperationListResponse> => {
        const response = {
            items: systemIdentifier && systemIdentifier === SystemIdentifierEnum.Saas ? [
              {
                  type: SystemType.TICKET,
                  name: "Pré-pago Ficha"
              },
              {
                  type: SystemType.POST_ACCOUNT,
                  name: "Pós-pago Comanda"
              },
          ] : [
                {
                    type: SystemType.TICKET,
                    name: "Pré-pago Ficha"
                },
                {
                    type: SystemType.PRE_CASHLESS,
                    name: "Pré-pago Cashless"
                },
                {
                    type: SystemType.POST_ACCOUNT,
                    name: "Pós-pago Comanda"
                },
            ]
        }

        return response
    }

    const editDeviceProducts = async (localId: string, profileId: string, values: IEditProductsRequest): Promise<void> => {
        await deviceConfigApi.put(`/locals/${localId}/profiles/${profileId}/products`, values)
    }

    const getPaymentMethodListByLocalId = async (localId: string): Promise<IGetPaymentMethodsResponse> => {
        const response = {
            items: [
                {
                    type: PaymentType.MONEY,
                    name: 'Dinheiro'
                },
                {
                    type: PaymentType.PIX,
                    name: 'Pix'
                },
                {
                    type: PaymentType.DEBIT,
                    name: 'Débito'
                },
                {
                    type: PaymentType.CREDIT,
                    name: 'Crédito'
                },
                {
                    type: PaymentType.VOUCHER,
                    name: 'Voucher'
                },
                {
                    type: PaymentType.PRODUCAO,
                    name: 'Produção'
                },
                {
                    type: PaymentType.DELIVERY,
                    name: 'Delivery'
                },
                {
                    type: PaymentType.PENDING,
                    name: 'Pendente'
                },
                {
                    type: PaymentType.CHEQUE,
                    name: 'Cheque'
                },
                {
                    type: PaymentType.CASHBACK,
                    name: 'Cashback'
                },
                {
                    type: PaymentType.AJUSTE,
                    name: 'Ajuste'
                },
                {
                    type: PaymentType.PERDA,
                    name: 'Perda'
                },
            ]
        }
        return response
    }

    const getCustomPaymentMethodListByLocalId = async (localId: string): Promise<ICustomPaymentResponse> => {
      const response = await BuscarFormasPagamentosCustomizados(api, localId)
      return response
    }

    const getOperatorListByLocalId = async (localId: string): Promise<IGetOperatorListResponse> => {
        const response = await GetOperadores(deviceConfigApi, localId)
        return response
    }

    const editMassiveDeviceProfile = async (localId: string, profileId: string, values: IEditMassiveDeviceProfileRequest): Promise<IEditMassiveDeviceProfileResponse> => {
        const response = await deviceConfigApi.put<IEditMassiveDeviceProfileResponse>( "/locals/" + localId + "/profiles/" + profileId + "/devices", values);
        return response.data
    }

    const editDeviceCustomerIdentification = async (localId: string, profileId: string, values: IEditDeviceCustomerIdentificationRequest): Promise<IEditDeviceCustomerIdentificationResponse> => {
        const response = await deviceConfigApi.put<IEditDeviceCustomerIdentificationResponse>( "/locals/" + localId + "/profiles/" + profileId + "/customer-identification", values);
        return response.data
    }


    return {
        getOperationListByLocalId,
        getDeviceCatalogListByLocalId,

        getDeviceProfileList,
        copyDeviceProfileById,
        deleteProfileById,

        getPaymentMethodListByLocalId,
        getCustomPaymentMethodListByLocalId,
        getOperatorListByLocalId,
        getDeviceProfileById,

        addDeviceProfile,
        editDeviceProfile,
        editDeviceOperation,
        editDevicePayment,
        editDeviceOperators,
        editDeviceEnvironments,
        editDeviceInvoice,
        editDeviceCatalog,
        editOperationParams,
        editGeneralParams,
        editUsability,
        editUserInterface,
        editPrinterParams,
        editPagSeguroParams,
        editMassiveDeviceProfile,
        editDeviceCustomerIdentification,
        enable,
        disable,
        editDeviceProducts,
        editTerminal
    }

}

export default DeviceProfileApi

