import { PartnerOriginType } from "modules/meepErp/models/partner/PartnerOriginType";
import { PartnerType } from "modules/meepErp/models/partner/PartnerType";

export interface ICorporatePartnerValues {
    code: string;
    partnerOriginType: PartnerOriginType;
    document: string;
    name: string;
    tradingName: string;
    stateRegistration: string;
    municipalRegistration: string;
    sectorTypeId: string | null;
    legalRepresentative: {
      name?: string,
      document?: string
    } | null,
    type: PartnerType;
    contacts: ContactValue[];
    address: AddressValue;
}

export interface AddressValue {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface ContactValue {
    name: string;
    phone: string;
    email: string;
}

export interface ISegmentItem {
    id: string;
    name: string;
}

export const EMPTY_PARTNER_VALUES: ICorporatePartnerValues = {
    code: '',
    name: '',
    partnerOriginType: PartnerOriginType.CORPORATE,
    document: '',
    //CNPJ
    tradingName: '',
    stateRegistration: '',
    municipalRegistration: '',
    sectorTypeId: '',
    legalRepresentative: {
        name: '',
        document: ''
    },
    /////////////
    type: PartnerType.Supplier,
    contacts: [
        {
            name: '',
            phone: '',
            email: ''
        }
    ],
    address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        zipCode: ''
    }
};

export interface IPartnerFormErrors {
    code?: string;
    corporateName?: string;
    tradeName?: string;
    cnpj?: string;
    stateRegistration?: string;
    municipalRegistration?: string;
    segment?: string;
    legalRepresentative?: {
        name?: string;
        cpf?: string;
    };
    partnerType?: string;
    contacts?: {
        name?: string;
        phone?: string;
        email?: string;
    }[];
    address?: {
        street?: string;
        number?: string;
        neighborhood?: string;
        city?: string;
        state?: string;
        zipCode?: string;
    };
}
