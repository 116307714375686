import { FormControlLabel, Switch } from '@material-ui/core'
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import FooterButtons from '../../../footerButtons/FooterButtons'
import { IDeviceInvoiceValue } from './interfaces/InvoiceValues'
import styles from './InvoiceForm.module.scss'
export interface IInvoiceFormProps {
    //propertys
    defaultValues?: IDeviceInvoiceValue;
    modified: boolean;
    onModified: (modified: boolean) => void;
    onSubmit?: (values: IDeviceInvoiceValue) => void
    onClickBack?: (values: IDeviceInvoiceValue) => void
}
const InvoiceForm: FC<IInvoiceFormProps> = ({ defaultValues, modified, onModified, onClickBack, onSubmit }) => {
    const [values, setValues] = useState<IDeviceInvoiceValue>(
        {
            emitEnabled: false,
            manualEmitOnCreditEnabled: false,
            manualEmitOnDebitEnabled: false,
            manualEmitOnCashEnabled: false,
            manualEmitOnMixedEnabled: false,
            splitEnabled: false,
            printEnabled: false,
            sendSMSInvoiceEnabled: false,
        }
    )
    const onChangeFlags = useCallback((
        property: "emitEnabled" |
            "manualEmitOnCreditEnabled" |
            "manualEmitOnDebitEnabled" |
            "manualEmitOnCashEnabled" |
            "manualEmitOnMixedEnabled" |
            "splitEnabled" |
            "printEnabled" |
            "sendSMSInvoiceEnabled",
        checked) => {
        onModified(true);
        setValues(prev => ({ ...prev, [property]: checked }))
    }, [onModified])

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
        }
    }, [defaultValues]);


    // useEffect(() => { onChange?.(values) }, [values])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit?.(values)
    }, [onSubmit, values])


    return (
        <form id={styles.InvoiceForm} onSubmit={submitHandle} >
            <div className={styles.container} >
                <FormControlLabel
                    checked={values.emitEnabled}
                    onChange={(_, checked) => { onChangeFlags("emitEnabled", checked) }}
                    control={<Switch color='secondary' />}
                    label={"Emitir Nota fiscal"}
                />
                {values.emitEnabled && (
                    <div className={styles.subContainer}>
                        <FormControlLabel
                            checked={values.manualEmitOnCreditEnabled}
                            onChange={(_, checked) => { onChangeFlags("manualEmitOnCreditEnabled", checked) }}
                            control={<Switch color='secondary' />}
                            label={<p>Emissão manual para pagamento no <b>Crédito</b></p>}
                        />
                        <FormControlLabel
                            checked={values.manualEmitOnDebitEnabled}
                            onChange={(_, checked) => { onChangeFlags("manualEmitOnDebitEnabled", checked) }}
                            control={<Switch color='secondary' />}
                            label={<p>Emissão manual para pagamento no <b>Débito</b></p>}
                        />
                        <FormControlLabel
                            checked={values.manualEmitOnCashEnabled}
                            onChange={(_, checked) => { onChangeFlags("manualEmitOnCashEnabled", checked) }}
                            control={<Switch color='secondary' />}
                            label={<p>Emissão manual para pagamento no <b>Dinheiro</b></p>}
                        />
                        <FormControlLabel
                            checked={values.manualEmitOnMixedEnabled}
                            onChange={(_, checked) => { onChangeFlags("manualEmitOnMixedEnabled", checked) }}
                            control={<Switch color='secondary' />}
                            label={<p>Emissão manual para pagamento <b>Misto</b></p>}
                        />
                        {/* <FormControlLabel
                            checked={values.splitEnabled}
                            onChange={(_, checked) => onChangeFlags("splitEnabled", checked)}
                            control={<Switch color='secondary' />}
                            label={"Habilitar Split Fiscal"}
                        /> */}
                        <FormControlLabel
                            checked={values.printEnabled}
                            onChange={(_, checked) => onChangeFlags("printEnabled", checked)}
                            control={<Switch color='secondary' />}
                            label={"Imprimir Nota Fiscal"}
                        />
                        <FormControlLabel
                            checked={values.sendSMSInvoiceEnabled}
                            onChange={(_, checked) => onChangeFlags("sendSMSInvoiceEnabled", checked)}
                            control={<Switch color='secondary' />}
                            label={"Enviar nota fiscal por SMS"}
                        />
                    </div>
                )}
            </div>
            <FooterButtons
                nextType='submit'
                onClickBack={() => onClickBack?.(values)}
                labelNext={"Salvar"}
            />
        </form>
    )
}
export default InvoiceForm