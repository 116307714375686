import { IGetLinkCardsParams } from "modules/linkCards/domain/dto/IGetLinkCardsParams";
import { ILinkCardsFilter } from "modules/linkCards/presentation/components/linkCardsFilter/interfaces/ILinkCardsFilter";



const ParseFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: ILinkCardsFilter) => {

  const request: IGetLinkCardsParams = {
    localId: localId,
    tag: filter?.tag,
    createdAt: filter?.createdAt,
    createdBy: filter?.createdBy,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterToRequestUseCase

