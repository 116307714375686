import { IGetDataParams } from 'modules/salesDashboard/presentation/interfaces/IGetDataParams';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 } from 'uuid';
import { Skeleton } from '../../skeleton/Skeleton'
import styles from './SubItemsProductsCategory.module.scss'

export interface IRankinProductCategory {
    imageUrl?: string;
    placeId: string;
    color: string;
    id: string;
    name: string;
    total: number;
}

export interface IProductRankingCategoryData {
    records: IRankinProductCategory[];
    totalRecords: number;
}

export interface ISubItemsProductsProps {
    productId: string,
    getSubItemsData?: (params: IGetDataParams, subCategoryId?: string) => Promise<IProductRankingCategoryData>
}

const SubItemsProductCategory: FC<ISubItemsProductsProps> = ({ productId, getSubItemsData }) => {
    const [subItems, setSubItems] = useState<IProductRankingCategoryData | undefined>();
    const [subProductCategory, setSubProductCategory] = useState<IRankinProductCategory[] | undefined>();
    const [params, setParams] = useState<IGetDataParams>()
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(0)


    useEffect(() => {
        if (getSubItemsData) {
            getSubItemsData({ ...params }, productId).then(response => {
                if (params?.page) {

                    setSubProductCategory(prev => ([...(prev ?? []), ...response.records]));
                } else {
                    setSubProductCategory([...response.records]);

                }

                setSubItems(response);

            }).finally(() => {
                setLoading(false);
            })
        }
    }, [getSubItemsData, params, productId])


    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        setParams({ page: currentPage + 1, pageSize: 20 });

    }, [currentPage]);


    const randonId = useMemo(() => v4(), [])

    return (
        <div id={randonId} className={styles.container}>
            <InfiniteScroll
                next={() => {
                    getMore?.();
                }}
                hasMore={subItems?.totalRecords ? subItems.records.length < subItems.totalRecords : false}
                loader={<Skeleton items={3} cardStyle={styles.skeletonCard} />}
                dataLength={subItems?.records.length ?? 0}
                scrollableTarget={randonId}
            >
                <div className={styles.listContainer}>
                    {
                        !subProductCategory?.length && !loading ? <span className={styles.noData}>Não foram encontrados produtos nesta categoria!</span> :
                            subProductCategory?.map((subitem) =>
                                <div className={styles.subItem}>
                                    <div className={styles.icon} />
                                    <label>{subitem.name}</label>
                                    <label>R${subitem.total.toFixed(2)}</label>
                                </div>
                            )
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}
export default SubItemsProductCategory