import { Button } from '@material-ui/core';
import { addMonths, format } from 'date-fns';
import { ITotalReport } from 'modules/crm/presentation/intefaces/TotalReport';
import { FC } from 'react'
import styles from './EmailQuantityAlert.module.scss'

export interface IEmailQuantityAlertProps {
    totalReport?: ITotalReport
    onClose: () => void
    planName?: string
}

const EmailQuantityAlert: FC<IEmailQuantityAlertProps> = ({ totalReport, onClose, planName }) => {
    return (
        <div id={styles.EmailQuantityAlert} >
            <h1>
                Limite de envios
            </h1>

            <div className={styles.alertContainer}>
                <img src="/assets/img/alert.png" alt="" />
                <p>Você não possui envios disponíveis de emails para essa campanha! Aumente seu plano mensal ou reduza a quantidade de destinatários.</p>
            </div>
            
            <div className={styles.container}>
                <strong>Resumo do seu plano:</strong>

                <div>
                    <span>Plano: <strong>{planName}</strong></span>
                    <span>Quantidade por mês: <strong>{new Intl.NumberFormat("pt-BR").format(totalReport?.report.totalPlan || 0)}</strong></span>
                    <span>Quantidade de emails enviados: <strong>{new Intl.NumberFormat("pt-BR").format(totalReport?.report.totalSent || 0)}</strong></span>
                    <span>Renova em: <strong>01/{format(addMonths(new Date(), 1), 'MM/yyyy')} 00:00</strong></span>
                </div>
            </div>

            <Button className={styles.backButton} onClick={onClose} variant='contained'>Fechar</Button>
        </div>
    )
}

export default EmailQuantityAlert