import { IlocalFilter } from '../../../filter/IFilter';
import { Icon } from '@material-ui/core';
import { Skeleton } from '../../../skeleton/Skeleton';
import { ITurnoverTable } from '../../interfaces/ITurnoverTable';
import styles from './TurnoverTableItem.module.scss';
import { UseTurnoverTableItem } from './UseTurnoverTableItem';
import { IGetDataParams } from 'modules/salesDashboard/presentation/interfaces/IGetDataParams';
import { ErrorMessage } from '../../../../../../../components/ui/errorMessage/ErrorMessage';

interface TurnoverTableItemProps {
    local: IlocalFilter;
    getData: ({ localId, page, pageSize, sortField, sortOrientation }: IGetDataParams) => Promise<ITurnoverTable>;
}

const TurnoverTableItem = ({ local, getData }: TurnoverTableItemProps) => {
    const {
        DEFAULT_PAGE_SIZE,
        loading,
        sorting,
        data,
        hasNext,
        loaderRef,
        scrollViewRef,
        error,
        handleScroll,
        handleSort,
    } = UseTurnoverTableItem(local, getData);

    if (loading) {
        return <Skeleton items={1} cardStyle={styles.skeletonRootCardStyle} />;
    }

    if (error || !data.records.length) {
        return <div></div>
    }

    return (
        <div id={styles.TurnoverTableItem}>
            <div className={styles.header}>
                <span>Média de giro: <span>{data?.average}</span></span>
                <button onClick={() => handleSort('number')}>Ordenar mesas <Icon>swap_vert</Icon></button>
                <button onClick={() => handleSort('turn')}>Ordenar giro <Icon>swap_vert</Icon></button>
            </div>
            <div className={styles.cardsContainer} ref={scrollViewRef} onScroll={handleScroll}>
                {
                    (sorting) ? <Skeleton
                        items={DEFAULT_PAGE_SIZE}
                        containerStyle={styles.skeletonContainerStyle}
                        cardStyle={styles.skeletonCardStyle}
                    /> : (
                        <>
                            {
                                data?.records.map(item => (
                                    <div key={item.number} className={styles.card} style={{ borderColor: data.color }}>
                                        <div className={styles.header} style={{ color: data.color }}>
                                            {item.number}
                                        </div>
                                        <div className={styles.footer}>
                                            <span>Giro</span>
                                            <span>{item.turn}</span>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                hasNext &&
                                <Skeleton
                                    ref={loaderRef}
                                    items={2}
                                    containerStyle={styles.skeletonContainerStyle}
                                    cardStyle={styles.skeletonCardStyle}
                                />
                            }
                        </>
                    )
                }


            </div>
        </div>
    )
}

export { TurnoverTableItem }