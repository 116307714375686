import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { SaasSmartPrinterPage } from "modules/config/smartPrinterConfig/presentation/pages/SaasSmartPrinterPage";
import { SmartPrinterPage } from "modules/config/smartPrinterConfig/presentation/pages/SmartPrinterPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useEffect } from "react";

const SmartPrinter = () => {
  const { updateRouters } = useBreadcumbs();
  const { currentLocal } = useLocal();
  useEffect(() => {
    updateRouters([
      {
        title: "PDV",
      },
      {
        label: "PDV",
        title: "Impressoras inteligentes",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout limitedWidth>
      <SaasSmartPrinterPage />
    </Layout>
  );

  // return (
  //   <Layout>
  //     {
  //       currentLocal?.systemIdentifier === 1 ?
  //         <SaasSmartPrinterPage /> 
  //         :
  //         <SmartPrinterPage />
  //     }
  //   </Layout>
  // );
  // return (
  //   <Layout>

  //     <SaasSmartPrinterPage />

  //     <SmartPrinterPage />

  //   </Layout>
  // );
};

export default SmartPrinter;
