import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import Button from "components/ui/Button/Button";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import style from "./DiscountsList.module.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface IDiscountsListProps {
  discountList: IDiscounts[];
  onClickEditDiscounts: (discountsList: IDiscounts[], listId: string) => void;
  listId: string;
  adminAccess?: boolean;
}

const DiscountsList: FC<IDiscountsListProps> = ({ discountList, onClickEditDiscounts, listId, adminAccess }) => {
  const discountsString = discountList.reduce((discountList, currentDiscount, index, array) => {
    switch (currentDiscount.discountType) {
      case "Price":
        discountList += `${moneyMaskNumber(currentDiscount.priceDiscount?.price || 0)} de desconto: ${
          currentDiscount.productName
        }`;
        break;
      case "Percentual":
        discountList += `${currentDiscount.percentualDiscount?.percentual.toFixed(0)}% de desconto: ${
          currentDiscount.productName
        }`;
        break;
      default:
        break;
    }
    discountList += index < array.length ? "; " : "";
    return discountList;
  }, "");

  return (
    <div id={style.DiscountsList}>
      {discountList && (
        <div className={style.listContainer}>
          <div className={style.discountList}>
            <h5>Descontos:</h5><p>{` ${discountList.length > 0 ? discountsString : " Nenhum desconto cadastrado!"}`}</p>
          </div>
        </div>
      )}
      {discountList && discountList?.length > 0 && adminAccess && (
        <IconButton className={style.showMore} onClick={() => onClickEditDiscounts(discountList, listId)}>
          <ChevronRightIcon />
        </IconButton>
        // <Button
        //   className={style.showMore}
        //   fullWidth={false}
        //   variant="text"
        //   onClick={() => onClickEditDiscounts(discountList, listId)}
        // >
        //   <u>Ver todos</u>
        // </Button>
      )}
    </div>
  );
};

export default DiscountsList;
