
import InvetoryService from "modules/meepErp/services/api/inventory/InvetoryService"
import { ICreateInventoryRequest, IGetInventoryRequest, IUpdateInventorSupplyBalanceRequest } from "../../dtos/invetory/InvetoryOperationDto";

export const CreateInventoryUseCase = async (localId: string, request: ICreateInventoryRequest) => {
  const response = await InvetoryService().createInvetory(localId, request);
  return response;
}

export const GetInventoryUseCase = async (localId: string, id: string, request: IGetInventoryRequest) => {
  const response = await InvetoryService().getInventory(localId, id, request);
  return response;
}

export const updateInventorySupplyCountedQuantity = async (localId: string, id: string, request: IUpdateInventorSupplyBalanceRequest) => {
  const response = await InvetoryService().updateInventorySupplyCountedQuantity(localId, id, request);
  return response;
}

export const DeleteInventoryUseCase = async (localId: string,id: string) => {
  await InvetoryService().deleteInventory(localId, id);
}

export const AddMoreSupplyInventoryUseCase = async (localId: string, inventoryId: string, request: ICreateInventoryRequest) => {
  const response = await InvetoryService().addMoreSupplyInvetory(localId, inventoryId, request);
  return response;
}


export const DeleteInventorySupplyUseCase = async (localId: string, id: string) => {
  await InvetoryService().deleteInventorySupply(localId, id);
}


export const FinishInventoryUseCase = async (localId: string, id: string) => {
  await InvetoryService().finishInventory(localId, id);
}
