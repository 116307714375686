import { ExportFormat } from "modules/cashlessDashboard/domain/interfaces/RequestParams";
import { IOriginType } from "modules/cashlessDashboard/presentation/components/rechargeOrigin/interfaces/IOriginType";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const ExportTopOperatorsUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams, type: IOriginType): Promise<void> => {
  const filterRequest = makeFilterRequest({ ...params, exportFormat: ExportFormat.EXCEL });
  await service.getTopOperators(filterRequest, type, params.localId);
}

export default ExportTopOperatorsUseCase
