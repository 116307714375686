
import React, { FC } from 'react'
import styles from './CustomerForm.module.scss'
import { ICustomerForm, IValuesItemCustomerForm } from './interfaces/ICustomerForm'
import { UseCustomerForm } from './UseCustomerForm'
import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import Input from 'components/ui/input/Input';
import Button from 'components/ui/Button/Button';
import { ArrowBackIos } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
export interface ICustomerFormProps {
    valuesCustomer: IValuesItemCustomerForm[];
    onSubmit: (customer: ICustomerForm) => void;
    defaultValue?: ICustomerForm;
    toggleModal: () => void,
}
const CustomerForm: FC<ICustomerFormProps> = ({ valuesCustomer, onSubmit, defaultValue, toggleModal }) => {
    const { values, changeHandle, changeCustomerValueHandle, submitHandle } = UseCustomerForm(onSubmit, valuesCustomer, defaultValue);   
    return (

        <div id={styles.CustomerForm} >
            <div className={styles.container} >
                <h2><ArrowBackIos onClick={toggleModal} /> Novo <span>Cliente</span> </h2>
                <form onSubmit={submitHandle}>
                    <FormItemContainer>
                        <FormItemContainer label={"Valor"}>
                            <Input
                                name="value"
                                onChange={(ev) => changeCustomerValueHandle(ev.target.value)}
                                value={values.value?.listId}
                                select
                                required
                            // label="seviceCategoryId"description
                            >
                                {
                                    valuesCustomer?.map((item) => (
                                        <MenuItem key={item.listId} value={item.listId}>{item.value}</MenuItem>
                                    ))
                                }
                            </Input>
                        </FormItemContainer>
                        <FormItemContainer label={"Nome"}>
                            <Input required name={"name"} onChange={changeHandle} />
                        </FormItemContainer>
                        <FormItemContainer label={"Documento"}>
                            <Input required name={"document"} onChange={changeHandle} />
                        </FormItemContainer>
                        <FormItemContainer label={"Data de nacimento"}>
                            <Input required type={"date"} name={"birthDate"} onChange={changeHandle} />
                        </FormItemContainer>
                        <div className={styles.containerButton}>
                            <Button fullWidth={false} type="submit">Salvar</Button>
                            <Button fullWidth={false} variant="outlined" onClick={toggleModal}>Cancelar</Button>
                        </div>
                    </FormItemContainer>
                </form>
            </div>
        </div>
    )
}
export default CustomerForm