import { Icon } from "@material-ui/core"
import styles from './AddressModal.module.scss';
import { GoogleAutoComplete } from "components/googleAutocomplete/GoogleAutoComplete";
import { useState } from "react";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";
import Input from "components/ui/input/Input";
import Sidesheet from "components/sidesheet/Sidesheet";
import InputMasked from "components/inputMasked/InputMasked";

interface AddressModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (address: IAddress) => void;
    loading: boolean;
    currentAddress?: IAddress;
}

export const AddressModal = ({ onClose, onSubmit, loading, currentAddress, open }: AddressModalProps) => {
    const [address, setAddress] = useState<IAddress>();
    const [showNumber, setShowNumber] = useState(false);
    const [numberError, setNumberError] = useState('');
    const [cepError, setCepError] = useState('');
    const [addressError, setAddressError] = useState('');

    const handleSubmit = () => {
        setAddressError('');
        setNumberError('');
        setCepError('');

        if (!address) {
            setAddressError('O endereço é obrigatório.');
            return false;
        }

        if (showNumber && (!address.number || !address.CEP)) {
            if (!address.number) {
                setNumberError('O número é obrigatório.');
            }

            const cep = address.CEP ? address.CEP.replace(/\D/g, '') : '';
            if (!address.CEP || cep.length < 8) {
                setCepError('O CEP é obrigatório.');
            }

            return false;
        }

        const payload = { ...address, CEP: address.CEP?.replace(/\D/g, '')}
        onSubmit(payload);
        setShowNumber(false);
        onClose();
    }

    const getAddressDescription = () => {
        if (currentAddress && (currentAddress.CEP !== "30160-011" || (currentAddress.CEP === "30160-011" && currentAddress.complement !== "5º andar"))) {
            const state = currentAddress.state ? currentAddress.state : currentAddress.uf;
            return `${currentAddress!.street}${currentAddress!.number ? ', ' + currentAddress!.number : ''} - ${currentAddress!.neighborhood}, ${currentAddress!.city} - ${state}`;
        }
        return "-";
    }

    const handleChangeAddress = (val: IAddress) => {
        setAddress(val);
        if (!val.number) {
            setShowNumber(true);
        }
    }

    return (
        <Sidesheet
            open={open}
            onClose={onClose}
            title={<h2>Endereço</h2>}
            content={
                <div className={styles.container}>
                    {
                        !!currentAddress &&
                        <div className={styles.currentAddress}>
                            <span>Endereço atual:</span>
                            <span>{getAddressDescription()}</span>
                        </div>
                    }

                    <label className={styles.titleInput}>
                        Digite o endereço
                    </label>
                    <GoogleAutoComplete onSelect={handleChangeAddress} />
                    {!!addressError && <span className={styles.addressError}>{addressError}</span>}

                    {
                        showNumber &&
                        <>
                            <label className={styles.titleInput}>
                                Digite o CEP
                            </label>
                            <InputMasked
                                name="CEP"
                                variant="outlined"
                                fullWidth
                                mask="99999-999"
                                value={address?.CEP}
                                onChange={(ev: any) => setAddress(prev => ({ ...prev!, CEP: ev.target.value}))}
                            />
                            {!!cepError && <span className={styles.cepError}>{cepError}</span>}

                            <label className={styles.titleInput}>
                                Digite o número
                            </label>
                            <Input
                                value={address?.number}
                                onChange={(ev) => setAddress(prev => ({ ...prev!, number: ev.target.value}))}
                                variant="outlined"
                            />
                            {!!numberError && <span className={styles.numberError}>{numberError}</span>}

                            <label className={styles.titleInput}>
                                Digite o complemento
                            </label>
                            <Input
                                value={address?.complement}
                                onChange={(ev) => setAddress(prev => ({ ...prev!, complement: ev.target.value}))}
                                variant="outlined"
                            />
                        </>
                    }

                    <footer>
                        <Icon>info_outlined</Icon>
                        <p>O endereço aparecerá no perfil do seu estabelecimento</p>
                    </footer>
                </div>
            }
            isLoading={loading}
            currentStep={1}
            totalSteps={1}
            continueButton="Continuar"
            handleContinueButton={handleSubmit}
            cancelButton
        />
    )
}