import { ICloseEventResponse } from 'modules/events/domain/dto/ICloseEventDetailsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

const GetCloseEventDetailsUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  localId: string,
): Promise<ICloseEventResponse> => {
  return await eventsService.getClosingEventDetails(eventId, localId);
};

export default GetCloseEventDetailsUseCase;
