import { IConfirmationSmsService } from "../interfaces/IContaDigitalApiService";

const PostConfirmationSms = async (
  accountService: IConfirmationSmsService,
  ownerId: string,
  digitalAccountId: string
): Promise<any> => {
  return await accountService.sendConfirmationSms(ownerId, digitalAccountId);
};

export default PostConfirmationSms;
