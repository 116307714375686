import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { CreateTrackConfigUseCase } from "modules/survey/application/useCases/CreateTrackConfigUseCase";
import { GetTrackConfigUseCase } from "modules/survey/application/useCases/GetTrackConfigUseCase";
import { UpdateTrackConfigUseCase } from "modules/survey/application/useCases/UpdateTrackConfigUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import SurveyApi from "services/api/tracksales/SurveyApi";
import { ITrackConfiguration } from "../../interfaces/ITrackConfiguration";

const service = SurveyApi();

export const UseConfigTrack = () => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<ITrackConfiguration>();
  const [values, setValues] = useState<{
    organizationId: string;
    token: string;
  }>({} as { organizationId: string; token: string });
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const createConfig = useCallback(async () => {
    try {
      setLoading(true);
      if (currentLocal) {
        const body: ITrackConfiguration = {
          enabled: true,
          token: values.token,
          organizationId: values.organizationId,
          ownerId: currentLocal.id,
        };
        const response = await CreateTrackConfigUseCase(
          service,
          body,
          currentLocal.id
        );
        setConfig(response);
        setValues({
          organizationId: response.organizationId,
          token: response.token,
        });
        return "ok";
      }
    } catch {
      toast("Não foi possível criar a configuração. Tente novamente", "error");
    } finally {
      setLoading(false);
    }
  }, [currentLocal, toast, values.organizationId, values.token]);

  const updateConfig = useCallback(async () => {
    try {
      setLoading(true);
      if (currentLocal) {
        const body = {
          id: config!.id!,
          ownerId: config!.ownerId,
          enabled: config!.enabled,
          createdAt: config!.createdAt,
          token: values.token,
          organizationId: values.organizationId,
        };

        const response = await UpdateTrackConfigUseCase(
          service,
          body,
          currentLocal.id
        );
        setConfig(response);
        setValues({
          organizationId: response.organizationId,
          token: response.token,
        });
        return "ok";
      }
    } catch {
      toast("Não foi possível salvar a configuração. Tente novamente", "error");
    } finally {
      setLoading(false);
    }
  }, [config, currentLocal, toast, values.organizationId, values.token]);

  const saveConfig = useCallback(() => {
    if (!values?.organizationId || !values.token) {
      //   toast("Todos os campos são obrigatórios", "error");
      return;
    }

    if (config?.id) return updateConfig();
    else return createConfig();
  }, [
    config?.id,
    createConfig,
    updateConfig,
    values?.organizationId,
    values.token,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (currentLocal) {
          const response = await GetTrackConfigUseCase(
            service,
            currentLocal?.id
          );
          setConfig(response);
          setValues({
            organizationId: response.organizationId,
            token: response.token,
          });
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentLocal]);

  return {
    loading,
    config,
    values,
    setValues,
    saveConfig,
  };
};
