import UseDimension from "components/dimension/UseDimension";
import styles from "./ConfigMeepTickets.module.scss";
import { UseConfigMeepTickets } from "../../../pages/configMeepTickets/UseConfigMeepTickets";
import Input from "components/ui/input/Input";
import { useEffect, useState } from "react";

const ConfigContentMeepTickets = () => {
  const [values, setValues] = useState<{
    organizerId: number;
    organizerName: string;
    address: string;
  }>(
    {} as {
      organizerId: number;
      organizerName: string;
      address: string;
    }
  );

  const { isMobile } = UseDimension();
  const { organizer, loading, integrated, localMainData } = UseConfigMeepTickets();

  useEffect(() => {
    if (organizer) {
      setValues({
        organizerId: organizer.id,
        organizerName: organizer.name,
        address: organizer.fullAddress,
      });
    }
  }, [organizer]);

  if (loading) {
    return <div />;
  }
  return (
    <>
      {!organizer && !integrated ? (
        <div className={styles.noMeepTickets}>
          <div>
            <img
              src="/assets/icon/ticket.svg"
              alt="Sem Meep Tickets"
              width={53}
            />
            <span style={{ padding: isMobile ? "12" : "" }}>
              Você não possui organizador Meep Tickets para vincular!
            </span>
          </div>
          <div>
            <p>Para integrar, insira seu CNPJ no Portal Meep Tickets<br />na guia 'Organizador':</p>
            <span>CNPJ: <b>{localMainData?.document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</b></span>
          </div>
        </div>
      ) : (
        <div className={styles.integrationMeepTickets}>
          <span style={{ padding: "0" }}>
            Para integrar, basta confirmar o vínculo da organização encontrada
            na Meep Tickets com o seu CNPJ:
          </span>
          <div>
            <label htmlFor="organizer-id">ID do organizador</label>
            <Input
              name="organizer-id"
              variant={"outlined"}
              placeholder="ID da empresa"
              value={values?.organizerId}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  organizerId: Number(ev.target.value),
                }));
              }}
            />
          </div>
          <div>
            <label htmlFor="organizer-name">Nome do organizador</label>
            <Input
              name="organizer-name"
              variant={"outlined"}
              placeholder="ID da empresa"
              value={values?.organizerName}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  organizerName: ev.target.value,
                }));
              }}
            />
          </div>
          <div>
            <label htmlFor="address">Endereço</label>
            <Input
              name="address"
              variant={"outlined"}
              placeholder="ID da empresa"
              value={values?.address}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  address: ev.target.value,
                }));
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigContentMeepTickets;
