import Button from 'components/ui/Button/Button'
import { AddCircle } from '@material-ui/icons'
import React from 'react'
import { SchedulePlaceCard } from '../../components/schedulePlaceCard/SchedulePlaceCard'
import styles from './PlaceScheduleListPage.module.scss'
import { UsePlaceScheduleListPage } from './UsePlaceScheduleListPage'

export const PlaceScheduleListPage = () => {

    const { values, goAddPlaceSchedule, getPlaceList } = UsePlaceScheduleListPage();

    return (
        <div id={styles.PlaceScheduleListPage}>
            <Button fullWidth={false} className={styles.additionalSchedule} onClick={goAddPlaceSchedule}>
                Adicionar passeio <AddCircle />
            </Button>

            <div className={styles.containerPlaceCard}>
                {
                    values?.map((item, index) => (

                        <SchedulePlaceCard key={index} value={item} getPlaceList={getPlaceList} />

                    ))
                }
            </div>
        </div>
    )
}
