import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import TableApi from "services/api/table/TableApi";
import { ITableItem } from "../components/tableList/TableList";
import GetTablesUseCase, { AddTableUseCase, ChangeStatusTableUseCase, DeleteTableUseCase, EditTableUseCase } from "modules/table/application/useCases/TablesUseCase";
import { ITableFormValue } from "../components/tableForm/TablerForm";
import { GestaoVendasApi } from "services/api/gestaoVendas/gestaoVendasApi";
import PostMesas from "modules/gestaoVendas/application/useCases/PostMesasUseCase";

const tableService = TableApi();
const gestaoService = GestaoVendasApi();

export const UseTableFragment = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableList, setTableList] = useState<ITableItem[]>([]);

    const { currentLocal } = useLocal();

    const getTables = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                const response = await GetTablesUseCase(tableService, currentLocal.id);
                setTableList(response);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const onSubmitAdd = useCallback(async (value: ITableFormValue) => {
        if (currentLocal) {
            try {
                setIsLoading(true)
                await AddTableUseCase(tableService, currentLocal.id, value);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const onSubmitEdit = useCallback(async (value: ITableFormValue) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await EditTableUseCase(tableService, currentLocal.id, value);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal])

    const onSubmitDelete = useCallback(async (selectedTableToDelete: ITableItem) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await DeleteTableUseCase(tableService, currentLocal.id, selectedTableToDelete.id);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);
    
    const onChangeStatus = useCallback(async (table: ITableItem, checked: boolean) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await ChangeStatusTableUseCase(tableService, currentLocal.id, table, checked ? 1 : 0);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);
    
    const createMoreThanOneTable = useCallback(async (lastTable: number, firstTable?: number) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await PostMesas(gestaoService, {
                    LocalClienteId: currentLocal.id,
                    Inicio: firstTable ?? 1,
                    Fim: lastTable,
                });                
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    return {
        isLoading,
        tableList,
        getTables,
        onSubmitAdd,
        onSubmitEdit,
        onSubmitDelete,
        onChangeStatus,
        createMoreThanOneTable
    }
}
