import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import CashlessDashboardPageContainer from "modules/cashlessDashboard/presentation/CashlessDashboardPageContainer";
import CustomersDashboardPage from "modules/customersDashboard/presentation/CustomersDashboardPage";
import SalesDashboardPageContainer from "modules/salesDashboard/presentation/SalesDashboardPageContainer";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TabPage from "./tabPage/TabPage";
import NotificationsModal from "modules/dashboard/presentation/components/notificationsModal/NotificationsModal";
import UseLayout from "components/layout/presentation/UseLayout";
import DashboardFichas from "modules/dashboard/presentation/pages/fichas/DashboardFichas";
import UseManageEventPage from "modules/events/presentation/pages/manageEvent/UseManageEventPage";
import GeneralDashboardPage from "modules/generalDashboard/presentation/pages/GeneralDashboardPage";
export interface IDashboardPageProps {
  //propertys
}
const DashboardPage: FC<IDashboardPageProps> = () => {
  const { updateRouters } = useBreadcumbs();
  const { getNotificationsByType, requiredNotifications, showModal } =
    UseLayout();
  const [openModal, setOpenModal] = useState(true);

  const requiredNotificationType = "1";

  const { tab, id, eventId } = useParams<{ tab: string; id: string; eventId: string }>();
  const {event} = UseManageEventPage()

  const getTitle = useCallback(() => {
    switch (tab) {
      case "Sales":
        return "Pós-pago";
      case "Customer":
        return "Clientes";
      case "Cashless":
        return "Cashless";
      case "general":
        return "Geral";
      default:
        return tab[0].toUpperCase() + tab.slice(1);
    }
  }, [tab]);

  useEffect(() => {
    if (eventId) {
      updateRouters([
        {
          title: "Evento/Caixa",
          url: "/private/eventos/list",
        },
        {
          title: event?.name
        },
        {
          title: 'Dashboard'
        },
        {
          label: "Dashboard",
          title: getTitle(),
        },
      ]);
    } else {
      updateRouters([
        {
          title: "Dashboard",
        },
        {
          label: "Dashboard",
          title: getTitle(),
        },
      ]);
    }

    return () => {
      updateRouters([]);
    };
  }, [getTitle, eventId, tab, updateRouters, event]);

  const hasNotificationModal = useMemo(
    () =>
      requiredNotifications &&
      requiredNotifications.length > 0 &&
      showModal !== "false",
    [requiredNotifications, showModal]
  );

  const notification = useMemo(
    () =>
      requiredNotifications && requiredNotifications.length > 0
        ? requiredNotifications.filter(
            (notification) => !notification.MensagemLida
          )
        : [],
    [requiredNotifications]
  );

  useEffect(() => {
    getNotificationsByType(requiredNotificationType);
  }, []);

  return (
    <>
      <TabPage
        intialTab={id ? "Sales" : undefined}
        hideTabs
        tabsPages={[
          {
            label: "Pós-pago",
            tab: "Sales",
            roles: "DashboardPosPago",
            component: <SalesDashboardPageContainer />,
          },
          {
            label: "Clientes",
            tab: "Customer",
            roles: "DashboardEventos",
            component: <CustomersDashboardPage />,
          },
          {
            label: "Cashless",
            tab: "Cashless",
            roles: "DashboardCashless",
            component: <CashlessDashboardPageContainer />,
          },
          {
            label: "Fichas",
            tab: "fichas",
            roles: "DashboardPortal",
            component: <DashboardFichas />,
          },
          {
            label: "Geral",
            tab: "general",
            roles: "DashboardPortal",
            component: <GeneralDashboardPage />,
          },
        ]}
        routerPrefix={"/private/dashboard"}
      />
      {hasNotificationModal && notification.length > 0 && (
        <NotificationsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          notification={notification[0]}
        />
      )}
    </>
  );
};
export default DashboardPage;
