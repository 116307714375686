import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage';
import TabPage from 'pages/private/dashboard/tabPage/TabPage';
import React, { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';


interface ITransactionMenu {
    label: string;
    tab: string;
    url?: string;
    papel?: string;
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        papel: string;
    }[]
}


export const Transactions = () => {

    const { tab, subTab } = useParams<{ tab: string, subTab: string }>()
    const { updateRouters } = useBreadcumbs();
    const location = useLocation();

    useEffect(() => {
        const currentTab = transactionsTabs.find(item => tab === item.tab)
        const routers = [
            {
                label: currentTab?.label,
                title: "Transações",
            },
            {
                lable: "Transações",
                title: currentTab?.label,
            }
        ]

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.label : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.label
            })
        }

        updateRouters(routers)
        return () => {
            updateRouters([])
        }
    }, [subTab, tab, updateRouters]);

    return (
        <>
            {<IframePage src={location.pathname.slice(8)} />}
        </>
    )
}

const transactionsTabs: ITransactionMenu[] = [
    {
        label: "Estorno de transações",
        tab: `estornoDeTransacoes`,
        url: `/transacoes/estornoDeTransacoes`,
        papel: "TransacoesEstorno",
    },
    {
        label: "Antecipação de recebíveis",
        tab: `antecipacaoDeRecebiveis`,
        url: `/transacoes/antecipacaoDeRecebiveis`,
        papel: "Antecipacao",
    },
    {
        label: "Split",
        tab: `split`,
        url: `/transacoes/split`,
        papel: "Split",
    },
]
