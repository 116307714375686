import { useMemo } from 'react';
import styles from './Onboarding.module.scss';
import { UseOnboarding } from './UseOnboarding';
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext';
import { DesktopWindowsOutlined } from '@material-ui/icons';

export const OnboardingPage = () => {
    const { isMenuCompleted, isTeamCompleted, isSalesCompleted, history, handleStart, handleDoItLater } = UseOnboarding();
    const { subscription } = usePlans();

    const isSaas = useMemo(
        () => subscription?.category && subscription.category.name === "saas", [subscription]
    );
    
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <h1>Você está no <b>Portal Meep!</b></h1>
                <div className={styles.betaContainer}>
                    {!isSaas && (
                        <>
                            <h3>Bem vindo à nossa VERSÃO <b>BETA</b></h3>
                            <img src="/assets/icon/logo-meep-onboarding.svg" alt="" />
                        </>
                    )}
                </div>
                <span className={styles.subtitle}>Aqui você consegue acompanhar e configurar as funcionalidades do seu estabelecimento. Para que sua experiência seja ainda mais incrível, configure o portal do seu jeito!</span>

                <div className={styles.separator} />

                <h3>Primeiros passos</h3>
                <span className={styles.description}>
                    Como esta é a sua primeira vez no Portal Meep, é necessário fazer algumas
                    configurações básicas. Para facilitar separamos em <span>etapas:</span>
                </span>

                <button 
                    className={`${styles.actionButtons} ${isTeamCompleted ? styles.checked : ''}`} 
                    onClick={() => {
                        if (!isTeamCompleted) history.push('/private/equipe/cargos');
                    }}
                >
                    <img src={`/assets/icon/onboarding-team${isTeamCompleted ? '-checked' : ''}.svg`} alt="" />
                    <div>
                        <span className={styles.buttonTitle}>Criação de uma equipe</span>
                        <span className={styles.buttonDescription}>Crie cargos e colaboradores</span>
                    </div>
                </button>

                <button 
                    className={`${styles.actionButtons} ${isMenuCompleted ? styles.checked : ''}`} 
                    onClick={() => {
                        if (!isMenuCompleted) history.push('/private/catalog');
                    }}
                >
                    <img src={`/assets/icon/onboarding-menu${isMenuCompleted ? '-checked' : ''}.svg`} alt="" />
                    <div>
                        <span className={styles.buttonTitle}>Cadastrar um cardápio</span>
                        <span className={styles.buttonDescription}>Crie categorias e produtos</span>
                    </div>
                </button>

                <button 
                    className={`${styles.actionButtons} ${isSalesCompleted ? styles.checked : ''}`} 
                    onClick={() => {
                        if (!isSalesCompleted) history.push('/private/gestaoVendas');
                    }}
                >
                    <img src={`/assets/icon/onboarding-sales${isSalesCompleted ? '-checked' : ''}.svg`} alt="" />
                    <div>
                        <span className={styles.buttonTitle}>Configurar suas vendas</span>
                        <span className={styles.buttonDescription}>Configure como quer vender</span>
                    </div>
                </button>

                {isSaas && (
                    <button className={`${styles.actionButtons} ${isSalesCompleted ? styles.checked : ''}`} onClick={() => history.push('/private/terminal')}>
                        <DesktopWindowsOutlined style={{ color: isSalesCompleted ? "#BDBDBD" : "#955CFF" }} />
                        <div>
                            <span className={styles.buttonTitle}>Gerencie seus terminais</span>
                            <span className={styles.buttonDescription}>Tenha acesso a todos os terminais de venda e altere configurações</span>
                        </div>
                    </button>
                )}

                <div className={styles.buttonsContainer}>
                    <button onClick={handleStart}>Começar</button>
                    <button onClick={handleDoItLater}>Fazer depois</button>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.imgContainer}>
                    <img src="/assets/img/onboarding-img.png" alt="" />
                </div>
            </div>
        </div>
    )
}
