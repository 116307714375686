import { IOperatorService } from "modules/operators/domain/interfaces/IOperatorService";
import { SaveOpertaorRequest } from "../dtos/SaveOpertaorRequest";
import { IOperatorFormValue } from "modules/operators/presentation/components/operatorForm/IOperatorValue";

const EditOperatorUseCase = (service: IOperatorService, localId: string,operatorId: string, operator: IOperatorFormValue): Promise<void> => {

    const request = {
        id: operatorId,
        name: operator.name,
        password: operator.password,
        privilegedAccess: operator.privilegedAccess,
        roles: operator.roles.map((role) => role.value),
        commission: operator.commission,
        useCashlessCardAsPassword: operator.useCashlessCardAsPassword,
        tag: operator.tag
    } as SaveOpertaorRequest;

    return service.updateOperator(localId, request);
};

export default EditOperatorUseCase;