import GetOperacoes from "modules/gestaoVendas/application/useCases/GetOperacoesUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CheckProductListUseCase } from "modules/onboarding/application/useCases/CheckProductListUseCase";
import { CheckTeamUseCase } from "modules/onboarding/application/useCases/CheckTeamUseCase";
import { useEffect, useState } from "react";
import { GestaoVendasApi } from "services/api/gestaoVendas/gestaoVendasApi";
import { OnboardingApi } from "services/api/onboarding/OnboardingApi";
import { ICheckOnboarding } from "../../interfaces/ICheckOnboarding";
import { IReponseOperacoes } from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";

const service = OnboardingApi();
const gestaoService = GestaoVendasApi();

export const UseOnboardingCard = () => {
    const [isMenuCompleted, setIsMenuCompleted] = useState(false);
    const [isTeamCompleted, setIsTeamCompleted] = useState(false);
    const [isSalesCompleted, setIsSalesCompleted] = useState(false);
    const [loading, setLoading] = useState(true);

    const { currentLocal } = useLocal();

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    const promises = [
                        CheckProductListUseCase(service, currentLocal.id),
                        CheckTeamUseCase(service, currentLocal.id),
                        GetOperacoes(gestaoService, currentLocal.id),
                    ];

                    const response = await Promise.all(promises);

                    setIsMenuCompleted((response[0] as ICheckOnboarding).isCompleted);
                    setIsTeamCompleted((response[1] as ICheckOnboarding).isCompleted);
                    setIsSalesCompleted(((response[2] as IReponseOperacoes)?.Id ?? '') !== '');
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [currentLocal]);

    return {
        isMenuCompleted,
        isTeamCompleted,
        isSalesCompleted,
        loading
    }
}
