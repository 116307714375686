import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService"
import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import { IRankingDevice } from "modules/salesDashboard/presentation/components/rankingDevices/IRankingDevices"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetRankingDeviceSalesUseCase = async (service: ISalesDashboardService, { sortField = 'totalConsumption', ...params }: IUseCaseParams): Promise<IRankingDevice> => {
    const filterRequest = makeFilterRequest({ sortField, ...params });

    return await service.getDeviceSales(filterRequest);
}
export default GetRankingDeviceSalesUseCase