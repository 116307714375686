import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './SupplyGroupList.module.scss'
import { ISupplyGroupFilterValue } from './supplyGroupListFilter/ISupplyGroupFilter'
import { IGetSupplyGroupListResponse, IGetSupplyGroupItemResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyGroupListDtos'
import SupplyGroupListFilter from './supplyGroupListFilter/SupplyGroupListFilter'
import List, { ColumnConfigs, ISort } from '../../../ui/list/List'
import { useQuery } from 'react-query'
import DefaultActionButtonBuilder from '../../../ui/defaultListActionButtons/DefaultListActionButtons'
import { Skeleton } from '@mui/material'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp'
import { IGetSupplyItemNameResponse, IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'
import { IAutocompletePaginatedListResponse } from '../../../autocomplete/AutocompleteInput'
export interface ISupplyGroupListProps {
    getSupplyGroupList: (filter: ISupplyGroupFilterValue) => Promise<IGetSupplyGroupListResponse>
    onEdit: (id: string) => void,
    onClickEnable: (id: string, isEnabled: boolean, item: IGetSupplyGroupItemResponse) => Promise<IGetSupplyGroupItemResponse>,
    onDelete: (id: string) => void,
    filter: ISupplyGroupFilterValue,
    onChangeFilter: (valuesList: ISupplyGroupFilterValue) => void,
    isLoading?: boolean
    subList?: boolean
    subLevel?: number
    hidenPagination?: boolean
}

export interface ISupplyGroupListFirstProps extends ISupplyGroupListProps{
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
}

const SupplyGroupList: FC<ISupplyGroupListFirstProps> = ({
    getSupplyGroupList,
    onDelete,
    onEdit,
    onClickEnable,
    filter,
    onChangeFilter,
    isLoading,
    GetSearchNameSupplyGroup
}) => {

    const submitFilter = useCallback((values: ISupplyGroupFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    return (
        <div id={styles.SupplyGroupList} >
            <SupplyGroupListFilter
                defaultValues={filter}
                submitFiler={submitFilter}
                GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
            />

            <SupplyGroupRecusiveList
                getSupplyGroupList={getSupplyGroupList}
                onDelete={onDelete}
                onEdit={onEdit}
                onClickEnable={onClickEnable}
                filter={filter}
                onChangeFilter={onChangeFilter}
                // isLoading={isLoading}
                subLevel={0}
            />
        </div>
    )
}
export default SupplyGroupList



const SupplyGroupRecusiveList: FC<ISupplyGroupListProps> = ({
    onDelete,
    onEdit,
    onClickEnable,
    filter,
    onChangeFilter,
    getSupplyGroupList,
    subList,
    subLevel = 0,
    hidenPagination
}) => {
    const { hasAccessRole } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [supplyGroupList, setSupplyGroupList] = useState<IGetSupplyGroupListResponse>()

    const getSupplyList = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await getSupplyGroupList(filter)
            setSupplyGroupList(response)
        } finally {
            setIsLoading(false)

        }
    }, [filter])

    useEffect(() => {
        getSupplyList()
    },[getSupplyList])

    const actionButtons = DefaultActionButtonBuilder<IGetSupplyGroupItemResponse>({
        onDelete,
        onEdit
    })

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ orderBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])


    const columnsConfig: ColumnConfigs<IGetSupplyGroupItemResponse> = {
        code: { title: "Código", sortable: true },
        name: { title: "Nome", sortable: true },
        canUpdateInventory: { width: 200, title: "Atualizar Estoque", renderRow: (value: boolean) => value ? "Sim" : "Não", sortable: true },
        quantitySubGroups: { width: 200, title: "Quantidade sub-grupos", sortable: true }
    }

    const pagination = useMemo(() => {
        return {
            page: supplyGroupList?.page ?? 1,
            pageSize: supplyGroupList?.pageSize ?? 10,
            count: supplyGroupList?.totalPages ?? 0
        }
    }, [supplyGroupList?.page, supplyGroupList?.pageSize, supplyGroupList?.totalPages])

    return (
        <List
            loading={isLoading}
            sub={subList}
            hideHeader={subList}
            list={supplyGroupList?.items}
            actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
            columnsConfigs={columnsConfig}
            pagination={pagination}
            onChangePage={onChangePage}
            onClickSort={onClickSort}
            showAccordion={(item) => item.hasChildren}
            subLevel={1 + subLevel}
            minWidth={700}
            hidePagintion={hidenPagination}
            renderAccordion={(item) => (
                <SupplyGroupRecusiveList
                    subList={true}
                    getSupplyGroupList={getSupplyGroupList}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onClickEnable={onClickEnable}
                    filter={{ parentId: item.id }}
                    onChangeFilter={onChangeFilter}
                    isLoading={isLoading}
                    subLevel={1 + subLevel}
                    hidenPagination
                />
            )}
        />
    )
}