import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './PrintQueueList.module.scss'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IPrintQueueItem, IPrintQueueList } from '../../interfaces/IPrintQueueList';
import { IParamsPrintQueueList } from '../../hooks/UsePrintQueueList';
import { IPrintQueueColumnsConfig } from './interfaces/IColumnsConfig';
import PrintQueueItem from './printQueueListContainer/PrintQueueItem';
import PrintQueueHeader from './printQueueListContainer/PrintQueueHeader';


export interface IPermutationAccountListProps {
    printQueueList: IPrintQueueList | undefined
    isLoading: boolean | undefined;
    onChangePaginationHandle: (pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    }) => void;
    params:  IParamsPrintQueueList | undefined,
    setParams: React.Dispatch<React.SetStateAction<IParamsPrintQueueList | undefined>>
    pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    } | undefined;
}

export const PrintQueueList: React.FC<IPermutationAccountListProps> = ({ isLoading, onChangePaginationHandle, params, setParams, pagination, printQueueList }) => {

    const { dimensions } = UseDimension();
    const { isMobile } = useUi();

    const [colunms, setColunms] = useState<IPrintQueueColumnsConfig[]>([
        {
            property: "printerName",
            title: "Impressora",
        },
        {
            property: "createdAt",
            title: "Horário",
        },
        {
            property: "order", 
            title: "Saldo atual",
            accessor: (item) => item.order.amount, 
        },
        {
            property: "status",
            title: "Status",
        },
    ]);

    const columnsLimit = useCallback((_columns: IPrintQueueColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 5
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])


    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, printQueueList])


    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, page: page, pageSize });
        },
        [onChangePaginationHandle, pagination]
    );



    const List = useMemo(
        () => (
            <div className={styles.list}>
                {
                    !isMobile &&
                    <PrintQueueHeader
                        columns={colunms}
                    />

                }
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    printQueueList?.data.map((item: IPrintQueueItem, index) => {
                        return (
                            
                                <PrintQueueItem
                                    key={index}
                                    userItemData={item}
                                    columns={colunms}

                                />
                        );
                    })
                )}
            </div>
        ),
        [isMobile, colunms, isLoading, printQueueList]
    );

    const countPage = useMemo(() => Math.ceil((printQueueList?.totalRegisters ?? 1) / (pagination?.pageSize ?? 20)), [printQueueList?.totalRegisters, pagination?.pageSize])



    return (
        <div id={styles.PrintQueueList}>
            {
                !isLoading && !printQueueList?.data.length
                    ?
                    (<div className={styles.emptyList}>
                        <img src="/assets/img/empty-box.png" alt="" />

                        <span>Seu estabelecimento ainda <b>não possui
                            nenhuma</b> permuta configurada.</span>
                    </div>)
                    : (
                        <>
                            {List}
                            <div className={styles.conteinerPagination}>
                                <div className={styles.boxMultiplePage}>
                                    <span>Exibir</span>
                                    <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                        <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                        <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                        <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                    </TextField>
                                </div>
                                <Pagination
                                    variant="outlined"
                                    className={styles.paginationNumber}
                                    shape="rounded"
                                    count={countPage}
                                    sx={{
                                        '.MuiPaginationItem-root': {
                                            borderColor: '#BDBDBD',
                                            color: '#001537',
                                        },
                                        '.Mui-selected': {
                                            fontWeight: 'bold',
                                            borderColor: '#001537',
                                        },
                                    }}
                                    onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                    page={((pagination?.page ? pagination?.page + 1 : 1))}
                                />
                            </div>
                        </>
                    )
            }
        </div>
    )
}
