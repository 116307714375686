import { Checkbox } from "@material-ui/core";
import styles from "components/sidesheet/Sidesheet.module.scss";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import { UseConfigCrmEmail } from "../../../pages/configCrmEmail/UseConfigCrmEmail";

export interface IConfigContentEmailProps {
  step: number;
}

const ConfigContentEmail = ({ step }: IConfigContentEmailProps) => {
  const { signature } = UseConfigCrmEmail();
  const { user } = useUser();

  return (
    <>
      {step === 1 && (
        <>
          <span className={styles.textContent}>
            Você irá receber um e-mail em <b>{user?.email}</b> para que seja
            realizada a confirmação da contratação da ferramenta.
          </span>
          <span className={styles.textContent}>
            Tal contratação irá gerar uma cobrança de R$199,90, mensal e um
            aditivo no seu contrato junto à Meep.
          </span>
          <div className={styles.checkboxContent}>
            <Checkbox defaultChecked />
            <label
              htmlFor="trackco-id"
              className={`${styles.textContent} ${styles.labelCheckbox}`}
            >
              Concordo com nossos <a>termos de uso</a> e com a cobrança mensal
              de R$199,90 para enviar campanhas via Whatsapp.
            </label>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <span className={styles.textContent}>
            <b>Confirme a contratação via email!</b>
          </span>
          <span className={styles.textContent}>
            Após a confirmação da contratação pelo e-mail{" "}
            <b>tiago@clubechalezinho.com.br</b>, é necessário{" "}
            <b>aguardar até 8h</b> para que o envio de campanhas via Whatsapp
            seja liberado.
          </span>
          <span className={styles.textContent}>
            Não se preocupe: você será notificado via e-mail quando estiver tudo
            pronto!
          </span>
        </>
      )}
      {step === 3 && (
        <>
          <span
            className={styles.textContent}
            style={{ color: "rgba(51, 51, 51, 1)" }}
          >
            <b>Faça a leitura do QR Corde</b>
          </span>
          <span className={styles.textContent}>
            Realize a leitura do QR Code no aplicativo Whatsapp do telefone que
            deseja enviar mensagens. Recomendamos o uso de um chip pré-pago por
            não possui nenhum vínculo fixo com operadoras.
          </span>
          <span className={styles.textContent}>
            Status:{" "}
            <span
              style={{
                color: signature?.isActive
                  ? "rgba(57, 156, 84, 1)"
                  : "rgba(244, 67, 54, 1)",
              }}
            >
              <b>{signature?.isActive ? "Conectado" : "Desconectado"}</b>
            </span>
          </span>
        </>
      )}
    </>
  );
};

export default ConfigContentEmail;
