import { Icon } from '@material-ui/core';
import styles from './PlanPaymentHistory.module.scss';
import { useHistory } from 'react-router-dom';
import { UsePlanPaymentHistory } from './UsePlanPaymentHistory';
import { format, parse } from 'date-fns';
import { moneyMaskNumber } from 'services/utils/Money';
import { CustomerInvoicesStatusEnum } from '../../interfaces/CustomerInvoicesStatusEnum';
import { Skeleton } from '@mui/material';

export const PlanPaymentHistoryPage = () => {
    const history = useHistory();

    const { customerInvoices, loading } = UsePlanPaymentHistory();

    const getClassName = (status: CustomerInvoicesStatusEnum) => {
        switch (status) {
            case CustomerInvoicesStatusEnum.CANCELED:
                return styles.refused;
            case CustomerInvoicesStatusEnum.PAID:
                return styles.paid;
            case CustomerInvoicesStatusEnum.PENDING:
                return styles.waiting;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.backContainer}>
                <button onClick={() => history.goBack()}>
                    <Icon>chevron_left</Icon>
                </button>
                <span>Detalhes</span>
            </div>

            {/* <header>
                <span>Plano <b>Básico</b></span>
                <div>
                    <div>
                        <span>Status do plano:</span>
                        <div className={styles.status}>Ativo</div>
                    </div>
                    <div>
                        <span>Data de início: <b>11/02/2023</b></span>
                    </div>
                    <div>
                        <span>Próxima cobrança: <b>11/09/2023</b></span>
                    </div>
                </div>
            </header> */}

            {
                loading ?
                    <>
                        <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} />
                        <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} />
                        <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} />
                    </> :
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Vencimento</th>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerInvoices.map(x => (
                                        <tr>
                                            <td>{x.dueDate ? format(parse(x.dueDate, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy') : '-'}</td>
                                            <td>{x.description}</td>
                                            <td>{moneyMaskNumber(x.value)}</td>
                                            <td>
                                                <div>
                                                    <div className={getClassName(x.status)} />
                                                    <span>{x.status}</span>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    (x.billetUrl || x.invoiceUrl) && (
                                                        <a href={x.invoiceUrl || x.billetUrl}>
                                                            <span className={styles.btnText}>{x.invoiceUrl ? 'Baixar NF' : 'Boleto'}</span>
                                                            <Icon className={styles.btnIcon}>download</Icon>
                                                        </a>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                        <div className={styles.mobileTable}>
                            {
                                customerInvoices.map(x => (
                                    <div>
                                        <div>
                                            <b>{x.dueDate ? format(parse(x.dueDate, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy') : '-'}</b>
                                            <div className={styles.statusContainer}>
                                                <div className={getClassName(x.status)} />
                                                <span>{x.status}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span>{x.description}</span>
                                            <span>{moneyMaskNumber(x.value)}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
            }

        </div>
    )
}