import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import { ISalesBar } from "modules/salesDashboard/presentation/components/salesBarChart/interfaces/SalesBarData"
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetSalesBarChartUseCase = async (service: ISalesDashboardService, params: IUseCaseParams): Promise<ISalesBar> => {
    const filterRequest = makeFilterRequest(params);
    
    return await service.getSalesBarChart(filterRequest);
}
export default GetSalesBarChartUseCase