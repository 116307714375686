import { useState } from 'react';
import { ClickAwayListener, Icon, Popper, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';

import styles from './OrderItem.module.scss';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { RightDrawer } from 'components/layout/presentation/rightDrawer/RightDrawer';
import { moneyMaskNumber } from 'services/utils/Money';
import { IPaginatedOrdersItem } from 'modules/order/presentation/interfaces/IPaginatedOrders';
import { GetStatusDetailsUseCase } from 'modules/invoice/application/useCases/GetStatusDetailsUseCase';

interface OrderItemProps {
    item: IPaginatedOrdersItem;
    triggerActionFn: (type: string, invoiceId: string) => void;
}

const service = InvoiceApi();

export const OrderItem = ({ item, triggerActionFn }: OrderItemProps) => {
    const { currentLocal } = useLocal();
    const [loading, setLoading] = useState(false);
    const [detailsOpened, setDetailsOpened] = useState(false);
    const [showStatusDetails, setShowStatusDetails] = useState(false);
    const [showTransactions, setShowTransactions] = useState(false);
    const [statusDetailsMessage, setStatusDetailsMessage] = useState('');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event?: React.MouseEvent<HTMLElement>, show?: boolean) => {
        if (show) {
            setAnchorEl(event!.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const getStatusDetails = async () => {
        setStatusDetailsMessage('');
        try {
            setLoading(true);
            setShowStatusDetails(true);
            const response = await GetStatusDetailsUseCase(service, currentLocal!.id, item.ThirdPartyInvoiceId);
            setStatusDetailsMessage(response);
        } catch(e) {
            setShowStatusDetails(false);
        } finally {
            setLoading(false);
        }
    }

    const mountPopover = () => {
        return (
            <div className={styles.popoverContainer}>
                <button aria-describedby={id} type="button" onClick={ev => handleClick(ev, true)}>
                    <Icon>more_vert</Icon>
                </button>
                {
                    open &&
                    <ClickAwayListener onClickAway={() => handleClick(undefined, false)}>
                        <Popper keepMounted={false} id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                            <div className={styles.popover}>
                                <button onClick={() => triggerActionFn('RESEND', item.ThirdPartyInvoiceId)}>
                                    <Icon className={styles.icon}>edit</Icon>
                                    <p>Reemitir NFC-e</p>
                                </button>
                                <button onClick={() => triggerActionFn('UPDATE', item.ThirdPartyInvoiceId)}>
                                    <Icon className={styles.icon}>science</Icon>
                                    <p>Atualizar e reemitir NFC-e</p>
                                </button>
                                <button onClick={() => triggerActionFn('SEND_EMAIL', item.ThirdPartyInvoiceId)}>
                                    <Icon className={styles.icon}>science</Icon>
                                    <p>Enviar por e-mail</p>
                                </button>
                                <button onClick={() => triggerActionFn('CANCEL', item.ThirdPartyInvoiceId)}>
                                    <Icon className={styles.icon}>delete</Icon>
                                    <p>Cancelar NFC-e</p>
                                </button>
                            </div>
                        </Popper>
                    </ClickAwayListener>
                }
            </div>
        )
    }

    const mountStatus = (statusNfce: string) => {
        if (['EMITIDA OFFLINE', 'VALIDADA', 'EMITIDA EM CONTIGENCIA', 'EMITIDA ASSINCRONO'].includes(statusNfce.toUpperCase())) {
            return <div className={styles.statusWithDetails} onClick={getStatusDetails}>
                <Tooltip title="Emitida parcialmente"><Icon style={{ color: '#FFB300' }}>report_problem</Icon></Tooltip>
                <span>Ver</span>
            </div>
        }

        if (['EM_DIGITACAO', 'ASSINADA', 'EM_PROCESSAMENTO_NA_SEFAZ', 'REJEITADA', 'CANCELADA', 'EMITIDA COM FALHA'].includes(statusNfce.toUpperCase())) {
            return <div className={styles.statusWithDetails} onClick={getStatusDetails}>
            <Tooltip title="Cancelada"><Icon style={{ color: '#F44336' }}>cancel</Icon></Tooltip>
            <span>Ver</span>
        </div>
        }

        if (statusNfce.toUpperCase() === 'AUTORIZADA') {
            return <Tooltip title="Autorizada"><Icon style={{ color: '#399C54' }}>check_circle</Icon></Tooltip>
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <span className={styles.mobileLabel}>Número da nota</span>
                    <span className={styles.invoiceNumber}>{item.InvoiceNumber}</span>
                </div>

                <div className={styles.column}>
                    <span className={styles.mobileLabel}>Data da compra</span>
                    {format(new Date(item.OrderDate), 'dd/MM/yyyy')}
                </div>

                <div className={styles.column}>
                    <span className={styles.mobileLabel}>Data da emissão</span>
                    {item.DateNfce ? format(new Date(item.DateNfce), 'dd/MM/yyyy') : '---'}
                </div>

                <div className={styles.column}>
                    <span className={styles.mobileLabel}>Status NFC-e</span>
                    {item.StatusNfce ? mountStatus(item.StatusNfce) : '---'}
                </div>

                <div className={styles.column}>
                    <span className={styles.mobileLabel}>Valor</span>
                    {moneyMaskNumber(item.TotalValue)}
                </div>

                <div className={styles.column}>
                    <span className={styles.mobileLabel}>ID do pedido</span>
                    {moneyMaskNumber(item.TotalDiscount || 0)}
                </div>

                <div className={`${styles.column} ${styles.products}`}>
                    <button className={styles.productsButton} onClick={() => setDetailsOpened(true)}>Detalhes</button>
                </div>

                <div className={`${styles.column} ${styles.fixed}`}>
                    {item.ThirdPartyInvoiceId && mountPopover()}
                </div>
            </div>

            <RightDrawer titleBold='Detalhes' open={detailsOpened} onClose={() => setDetailsOpened(false)}>
                <div className={styles.sideSheetContainer}>
                    <div className={styles.row}>
                        <div>
                            <label>Chave de acesso:</label><span>{item.AccessKey}</span>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div>
                            <label>Chave da integração:</label><span>{item.ThirdPartyInvoiceId || '---'}</span>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div>
                            <label>ID do pedido:</label><span>{item.Id}</span>
                        </div>
                        {/* <div>
                            <label>ID da transação:</label><span>xxx</span>
                        </div> */}
                    </div>

                    <div className={styles.row}>
                        <div>
                            <label>Data da compra:</label><span>{format(new Date(item.OrderDate), 'dd/MM/yyyy')}</span>
                        </div>
                        <div>
                            <label>Data da emissão:</label><span>{item.DateNfce ? format(new Date(item.DateNfce), 'dd/MM/yyyy') : '---'}</span>
                        </div>
                    </div>

                    <div className={styles.transactionBox}>
                        <div className={styles.transactionHeader} onClick={() => setShowTransactions(prev => !prev)}>
                            <div className={styles.transactionBoxHeader}>Transações ({item?.Payments?.length || 0})</div>

                            <div>
                                <Icon className={styles.chevron}>{showTransactions ? 'expand_less' : 'expand_more'}</Icon>
                            </div>
                        </div>

                        <div className={styles.transactionBoxContent} data-open={showTransactions}>
                            {item.Payments && item.Payments.map(payment => (
                                <div className={styles.transactionLine}>
                                    <div>ID da transação: <b>{payment.Id}</b></div>
                                    <div>Forma de pagamento: <b>{payment.FormOfPayment}</b></div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.productsContent}>
                        {
                            item.Products.map(product => (
                                <div className={styles.product}>
                                    <div className={styles.detailsItem}>
                                        <div>
                                            <span>{product.Quantity}x</span>&nbsp;
                                            <span>{product.Name}</span>
                                        </div>

                                        <span>{moneyMaskNumber((product.Quantity * product.Value))}</span>
                                    </div>


                                    {/* <div className={styles.detailsItem}>
                                        <span>Taxas</span>
                                        <span>{moneyMaskNumber(0)}</span>
                                    </div> */}

                                    {/* <div className={styles.detailsItem}>
                                        <span>Outras despesas</span>
                                        <span>{moneyMaskNumber(0)}</span>
                                    </div> */}

                                    <div className={styles.detailsItem}>
                                        <span>Desconto</span>
                                        <span>({moneyMaskNumber(product.DiscountValue)})</span>
                                    </div>

                                    <div className={styles.detailsItem}>
                                        <span>Total do produto</span>
                                        <span className={styles.textBigger}>{moneyMaskNumber((product.Quantity * product.Value) - product.DiscountValue)}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className={styles.bottomResumeContent}>
                        <div className={styles.detailsItem}>
                            <span>Total de desconto</span>
                            <span>{moneyMaskNumber(item.TotalDiscount || 0)}</span>
                        </div>
                        <div className={styles.detailsItem}>
                            <span><b>Valor final:</b></span>
                            <span className={styles.textBigger}><b>{moneyMaskNumber(item.TotalValue)}</b></span>
                        </div>
                        {/* <div className={styles.detailsItem}>
                            <span>Forma de pagamento:</span>
                            <span><b>Crédito</b></span>
                        </div> */}
                    </div>

                    <div className={styles.sideSheetButtonsContainer}>
                        <button onClick={() => setDetailsOpened(false)}>Fechar</button>
                    </div>
                </div>
            </RightDrawer>

            {(item.StatusNfce.toUpperCase() !== 'AUTORIZADA') && (
                <RightDrawer title="Status" titleBold='NFC-e' open={showStatusDetails} onClose={() => setShowStatusDetails(false)}>
                    <div className={styles.sideSheetContainer}>
                        {loading ? <img src="/assets/icon/saas/loading.gif" alt="loading" className={styles.loadingBox} /> : (
                            <div>
                                <div className={styles.statusDetailsHeader}>
                                    <Icon className={styles.statusDetailsHeaderIcon}>info_outline</Icon>
                                    <span><b>Mensagem sobre o status</b></span>
                                </div>

                                <p>{statusDetailsMessage}</p>
                            </div>
                        )}

                        <div className={styles.sideSheetButtonsContainer}>
                            <button onClick={() => setShowStatusDetails(false)}>Fechar</button>
                        </div>
                    </div>
                </RightDrawer>
            )}
        </div>
    )
}

