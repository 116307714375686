import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import Layout from 'components/layout/presentation/Layout'
import SaleNowPage from 'modules/Terminal/presentation/SaleNowPage';
import TerminalPage from 'modules/Terminal/presentation/TerminalPage'
import SalesPosPage from 'modules/salesPos/presentation/SalesPosPage';
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom';

interface ISaleWaysTabs {
    label: string;
    tab: string;
    url: string;
    component: React.ReactNode;
};

export const SaasSaleWays = () => {
    const location = useLocation();
    const { updateRouters } = useBreadcumbs();

    const currentTab = useMemo(() => saleWaysTabs.find((item) => location.pathname.includes(item.tab)), [location.pathname]);

    useEffect(() => {
        updateRouters(currentTab?.tab !== "saleNow"
            ? [
                {
                    label: "Formas de vender",
                    title: "Formas de vender",
                },
                {
                    label: "Formas de vender",
                    title: currentTab?.label,
                }
            ] : [
                {
                    label: "Formas de vender",
                    title: currentTab?.label,
                }
            ]
        );
        return () => {
            updateRouters([]);
        };
    }, [currentTab, updateRouters]);

    return (
        <Layout limitedWidth>
            {currentTab?.component}
        </Layout>
      );
}

const saleWaysTabs: ISaleWaysTabs[] = [
    {
        label: "Vender agora",
        tab: "saleNow",
        url: "/vendas/saleNow",
        component: <SaleNowPage />
    },
    {
        label: "Terminal online",
        tab: "terminal",
        url: "/vendas/terminal",
        component: <TerminalPage />
    },
    {
        label: "Máquina de cartão",
        tab: "pos",
        url: "/vendas/pos",
        component: <SalesPosPage />
    }
];
