import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { CropEasyHandler } from 'components/ui/resizeImage/CropEasy';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ISelect } from '../colaboratorScheduleForm/ColaboratorScheduleForm';
import { EnumDurationType, IDescriptionForm, IOtherValuesForm, IServiceScheduleForm } from './interfaces/IServiceForm'
import { IServiceFormErros } from './interfaces/IServiceFormErros';
import { validate } from './validation/ServiceFormValidation';

const ORIENTATION_TO_ANGLE: any = {
    '3': 180,
    '6': 90,
    '8': -90,
}

export const UseServiceForm = (onSubmit: (values: IServiceScheduleForm) => void, serviceCategories: ISelect[], defaultValues?: IServiceScheduleForm) => {

    const [values, setValues] = useState<IServiceScheduleForm>(defaultValues ?? {
        name: "",
        image: "",
        imageUrl: "",
        description: "",
        moreDescriptions: [] as IDescriptionForm[],
        durationType: EnumDurationType.Minutos,
        value: 0,
        duration: 60,
        productId: "",
        showcaseImagesUrls: [],
        serviceCategoryId: "",
        serviceSubCategoryId: "",
        serviceCategoryName: "",
        otherValues: [] as IOtherValuesForm[]
    } as IServiceScheduleForm)
    const [errors, setErrors] = useState<IServiceFormErros>({});
    const [showcaseImages, setShowcaseImages] = useState<{ imagem?: string }[]>([])
    const [openCrop, setOpenCrop] = useState(false)
    const [imageUrl, setImageUrl] = useState<string>('')
    const [imageUrlList, setImageUrlList] = useState<string[]>([])
    const [imageMultipleUrl, setImageMultipleUrl] = useState<{ imagemUrl?: string, imagem?: string }[]>([])
    const [serviceSubCategories, setServiceSubCategories] = useState<ISelect[]>([]);

    const { toast } = useUi();
    const cropEasyRef = useRef<CropEasyHandler>(null)

    const { updateRouters } = useBreadcumbs()

    useEffect(() => {
        if (defaultValues) {
            const category = serviceCategories.find(x => x.id === defaultValues.serviceCategoryId || x.subItems?.find(y => y.id === defaultValues.serviceCategoryId))
            const subCategory = category?.subItems?.find(x => x.id === defaultValues.serviceCategoryId)
            
            setValues({ ...defaultValues, serviceCategoryId: category?.id || defaultValues.serviceCategoryId, serviceSubCategoryId: subCategory?.id });
            setShowcaseImages(defaultValues.showcaseImagesUrls?.map((item) => ({ imagem: item })) ?? []);
        }
    }, [defaultValues, serviceCategories])

    useEffect(() => {
        if (values.serviceCategoryId) {
            const category = serviceCategories.find(x => x.id === values.serviceCategoryId);
            if (category) {
                setServiceSubCategories(category.subItems || [])
            }
        } else {
            setServiceSubCategories([])
        }
    }, [serviceCategories, values.serviceCategoryId])

    useEffect(() => {
        const RouterPrefix = "/private/agendamento/list/"

        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Serviços',
                url: RouterPrefix+'servicos'
            },
            {
                title: 'Adicionar serviço',
            },
        ])
    }, [updateRouters]);

    const onFileChangeSingleImage = async (image: string) => {

        try {
            const base64 = await cropEasyRef.current?.getCrop(image);
            if (base64) {
                setValues(prev => ({ ...prev, imageUrl: base64 }))
            }
        } catch (error: any) {
            toast(error.message, "error")
        }

    }

    const onFileChangeMultipleImage = async (images: { imagemUrl?: string, imagem?: string }[]) => {

        if (images.length > 0) {

            for (const image of images) {
                try {
                    if (image.imagem) {

                        const base64 = await cropEasyRef.current?.getCrop(image.imagem)
                        if (base64) {

                            setShowcaseImages(prev => ([...prev, { imagem: base64 }]))
                        }
                    }
                } catch (error: any) {
                    toast(error.message, "error")

                }
            }


        }

    }



    const handleImage = useCallback((image: string) => {
        setValues(prev => ({ ...prev, imageUrl: image }))
    }, [])

    const onSubmitHandle = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            const showCases = showcaseImages.map((item) => item.imagem ?? '').filter(item => item !== '')
            const newValues: IServiceScheduleForm = {
                ...values,
                showcaseImagesUrls: showCases
            }

            if (validate(values, setErrors, showCases)) {

                onSubmit(newValues)
            }
        },
        [showcaseImages, values, onSubmit],
    )

    const onChangeHandle = useCallback(
        (name: string, value: string) => {
            setValues(prev => ({ ...prev, [name]: value }))
        }, [])

    const onChangeEditorText = useCallback((moreDescriptions: IDescriptionForm[]) => {

        setValues(prev => ({ ...prev, moreDescriptions }))

    }, [])
    const onChangeOtherValues = useCallback((otherValues: IOtherValuesForm[]) => {

        setValues(prev => ({ ...prev, otherValues }))

    }, [])

    const onChangeImageHandle = useCallback((images: { imagemUrl?: string, imagem?: string }[]) => {
        if (images.length) {
            setValues(prev => ({ ...prev, imageUrl: images[0].imagemUrl, image: images[0].imagem }))
        }
    }, [])

    const handleChangeSingleImage = (image: string) => {
        setValues((prev) => ({
            ...prev,
            imageUrl: image,
        }));
    };

    const onChangeMultipleImagesHandle = useCallback((values: { imagemUrl?: string, imagem?: string }[]) => {
        setShowcaseImages(prev => ([...prev, ...values]));
    }, [])

    const onDeleteMultipleImagesHandle = useCallback((values: { imagemUrl?: string, imagem?: string }[]) => {
        setShowcaseImages(values);
    }, [])

    const onChangeOtherValuesHandle = useCallback((otherValues: IOtherValuesForm[]) => {
        setValues(prev => ({ ...prev, otherValues: otherValues }))
    }, [])

    return (
        {
            values,
            setValues,
            onChangeHandle,
            onChangeOtherValues,
            onSubmitHandle,
            onChangeImageHandle,
            onChangeOtherValuesHandle,
            onChangeMultipleImagesHandle,
            onDeleteMultipleImagesHandle,
            showcaseImages,
            handleChangeSingleImage,
            onChangeEditorText,
            errors,
            openCrop,
            setOpenCrop,
            onFileChangeSingleImage,
            imageUrl,
            onFileChangeMultipleImage,
            imageMultipleUrl,
            handleImage,
            cropEasyRef,
            serviceSubCategories
        }
    )
}
