import { Icon, Switch } from '@material-ui/core';
import styles from './ConfigPlans.module.scss';
import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import { UseConfigPlans } from './UseConfigPlans';
import { PlanHistoryModal } from '../../components/planHistoryModal/PlanHistoryModal';
import { ChangePlanModal } from '../../components/changePlanModal/ChangePlanModal';
import { format } from 'date-fns';
import { ChangePlanSuccessModal } from '../../components/changePlanSuccessModal/ChangePlanSuccessModal';

export const ConfigPlansPage = () => {
    const {
        plans,
        historyModalOpened,
        selectedLocalPlan,
        editModalOpened,
        localPlans,
        downgradeSolicitations,
        saving,
        currentPage,
        loading,
        filter,
        successModalOpened,
        setSuccessModalOpened,
        setFilter,
        setEditModalOpened,
        setHistoryModalOpened,
        setSelectedLocalPlan,
        handleChangePlan,
        getData,
    } = UseConfigPlans();

    return (
        <div className={styles.container}>
            <h1>Planos</h1>
            <span className={styles.subtitle}>Esses são os estabelecimentos e seus respectivos planos no Portal Meep.</span>

            {
                !!downgradeSolicitations.length &&
                <div className={styles.downgradeContainer}>
                    <Icon className={styles.icon}>info_outlined</Icon>
                    <span className={styles.text}>
                        {downgradeSolicitations.length > 1 ? 'Existem ' : 'Existe '} 
                        <b>{downgradeSolicitations.length} 
                        {downgradeSolicitations.length > 1 ? ' solicitações ' : ' solicitação '} 
                        de downgrade</b> para 
                        {downgradeSolicitations.length > 1 ? ' serem realizadas' : ' ser realizada'}
                    </span>
                </div>
            }

            <div className={styles.filterContainer}>
                <div>
                    <label>Filtrar por plano</label>
                    <TextField
                        size={'small'}
                        select
                        variant='outlined'
                        placeholder='Selecione o plano'
                        value={filter.planId}
                        onChange={e => setFilter(prev => ({ ...prev, planId: e.target.value }))}
                    >
                        <MenuItem value=''>Selecione o plano</MenuItem>
                        {
                            plans?.map(x => (
                                <MenuItem value={x.id}>{x.name}</MenuItem>
                            ))
                        }
                    </TextField>
                </div>
                <div>
                    <label>Buscar por estabelecimento</label>
                    <TextField
                        className={styles.textField}
                        placeholder='Digite o nome ou CNPJ'
                        size='small'
                        value={filter.keyword}
                        onChange={e => setFilter(prev => ({ ...prev, keyword: e.target.value }))}
                    />
                </div>
                <div className={styles.switchContainer}>
                    <Switch checked={filter.hasDowngradeSolicitation} onChange={() => setFilter(prev => ({ ...prev, hasDowngradeSolicitation: !prev.hasDowngradeSolicitation }))} />
                    <span className={styles.text}>Solicitações de downgrade</span>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={() => getData(0, filter)}>Buscar</button>
                    <button
                        onClick={() => {
                            setFilter({ planId: undefined, keyword: '', hasDowngradeSolicitation: false });
                            getData(0);
                        }}
                    >
                        Limpar
                    </button>
                </div>
            </div>

            <div className={styles.tableHeader}>
                <div>
                    <span>Local</span>
                    <span>Plano atual</span>
                    <span>Data da assinatura</span>
                    <span>Solicitação de downgrade</span>
                    <span>Histórico</span>
                    <button><Icon>chevron_right</Icon></button>
                </div>
            </div>
            <div className={styles.tableBody}>
                {
                    loading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                        </> :
                        <>
                            {
                                localPlans?.data.map(x => (
                                    <div>
                                        <span>{x.ownerName}</span>
                                        <span>{x.actualPlanName}</span>
                                        <span>{format(new Date(x.lastPurchaseDate + 'Z'), 'dd/MM/yyyy HH:mm')}</span>
                                        <span>{x.downgradeSubscriptions?.planName || '-'}</span>
                                        <span><button onClick={() => { setHistoryModalOpened(true); setSelectedLocalPlan(x); }}>Acessar</button></span>
                                        <button onClick={() => { setEditModalOpened(true); setSelectedLocalPlan(x) }}><Icon>chevron_right</Icon></button>
                                    </div>

                                ))
                            }
                        </>
                }

            </div>
            <Pagination
                variant="outlined"
                shape="rounded"
                style={{ alignSelf: 'flex-end' }}
                sx={{
                    '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                    },
                    '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                    },
                }}
                className={styles.contentPagination}
                page={currentPage}
                count={localPlans?.totalPages}
                onChange={(ev, page) => getData(page)}
            />

            {
                !!selectedLocalPlan &&
                <>
                    <PlanHistoryModal open={historyModalOpened} onClose={() => setHistoryModalOpened(false)} localPlan={selectedLocalPlan} />
                    <ChangePlanModal open={editModalOpened} onClose={() => setEditModalOpened(false)} localPlan={selectedLocalPlan} isLoading={saving} plans={plans} onSubmit={handleChangePlan} />
                    <ChangePlanSuccessModal
                        open={successModalOpened}
                        onClose={() => {
                            setSuccessModalOpened(false);
                            setSelectedLocalPlan(undefined);
                            getData();
                        }}
                    />
                </>
            }
        </div>
    )
}