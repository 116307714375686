import { Button } from "@material-ui/core";
import styles from './TabsErp.module.scss'
import { useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

interface TabsErpProps {
    data: {
        label: string,
        path: string
    }[]
}

const TabsErp = ({data}: TabsErpProps) => {
    const { push } = useHistory();
    const location = useLocation();
    const path = location.pathname.split('/private/meeperp/')[1] || '';

    const handleChangeTab = useCallback(
        (newValue: string) => {
            push('/private/meeperp' + "/" + newValue);
        },
        [push,]
    );
    
    return (
        <div id={styles.TabsErp}>
            <div className={styles.container}>
                {data.map((item, index) => {
                    return (
                        <Button
                            key={item.label + index}
                            className={`${styles.button} ${path === item.path || path === `${item.path}/`  ? styles.selectedButton : ""
                                }`}
                            variant={path === item.path || path === `${item.path}/` ? "contained" : "outlined"}
                            onClick={() => handleChangeTab(item.path)}
                        >
                            {item.label}
                        </Button>
                    )
                }

                )}
            </div>
        </div>
    )
}

export default TabsErp