import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { FC, useCallback, useState } from 'react'
import styles from './OperationFilter.module.scss'

export enum OperationStringEnum {
    all = 'all',
    cashless = 'cashless',
    individual = 'individual',
    ticket = 'ficha',
    table = 'mesa'
}

export interface IOperationFilterProps {
    //propertys
    onChange: (value: OperationStringEnum) => void
}
const OperationFilter: FC<IOperationFilterProps> = ({ onChange }) => {

    const onChangeOperation = useCallback((value: OperationStringEnum) => {
        onChange(value)
    }, [onChange])


    return (
        <div id={styles.OperationFilter} >
            <RadioGroup
                defaultValue="all"
                name="radio-buttons-group"
                row
                onChange={(_, value) => onChangeOperation(value as OperationStringEnum)}
            >
                <FormControlLabel label="Todos" control={<Radio />} value={OperationStringEnum.all} />
                <FormControlLabel label="Cashless" control={<Radio />} value={OperationStringEnum.cashless} />
                <FormControlLabel label="Comanda Individual" control={<Radio />} value={OperationStringEnum.individual} />
                <FormControlLabel label="Ficha" control={<Radio />} value={OperationStringEnum.ticket} />
                <FormControlLabel label="Mesa" control={<Radio />} value={OperationStringEnum.table} />
            </RadioGroup>
        </div>
    )
}
export default OperationFilter