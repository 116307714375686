import { IOnlinePaymentService } from "modules/onlinePayment/domain/interfaces/IOnlinePaymentService"
import { api } from "../Api";
import { IGetOnlinePaymentHistoryResponse } from "modules/onlinePayment/domain/dtos/IGetOnlinePaymentHistoryResponse";
import { IGetLocalOnlinePaymentResponse } from "modules/onlinePayment/domain/dtos/IGetLocalOnlinePaymentResponse";
import { IGetOnlinePaymentSolicitationResponse } from "modules/onlinePayment/domain/dtos/IGetOnlinePaymentSolicitationResponse";
import { plansUrl } from "Enviroment";
import { IGetOnlinePaymentStatusUseCase } from "modules/onlinePayment/domain/dtos/IGetOnlinePaymentStatusUseCase";

const baseUrl = plansUrl;

export const OnlinePaymentApi = (): IOnlinePaymentService => {
  const getLocalOnlinePayment = async (page: number, keyword?: string, hasOnlinePaymentSolicitation?: boolean): Promise<IGetLocalOnlinePaymentResponse> => {
    const response = await api.get(`${baseUrl}/payments/solicitations`, { params: { keyword, hasOnlinePaymentSolicitation, page } });
    return response.data;

    // return {
    //   currentPage: 1,
    //   totalPages: 2,
    //   totalRegisters: 25,
    //   data: [
    //     {
    //       ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //       ownerName: 'Clube Chalezinho',
    //       solicitationDate: new Date().toISOString(),
    //       operations: ['Pré-pago cashless', 'Pré-págo ficha'],
    //       isEnabled: true,
    //     },
    //     {
    //       ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //       ownerName: 'Clube Chalezinho',
    //       solicitationDate: new Date().toISOString(),
    //       operations: ['Pré-pago cashless', 'Pré-págo ficha'],
    //       isEnabled: false,
    //     },
    //     {
    //       ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //       ownerName: 'Clube Chalezinho',
    //       solicitationDate: new Date().toISOString(),
    //       operations: ['Pré-pago cashless', 'Pré-págo ficha'],
    //       isEnabled: true,
    //     },
    //     {
    //       ownerId: '49953f8a-8a69-4a82-bf3e-8f18396313bb',
    //       ownerName: 'Clube Chalezinho',
    //       solicitationDate: new Date().toISOString(),
    //       operations: ['Pré-pago cashless', 'Pré-págo ficha'],
    //       isEnabled: false,
    //     },
    //   ]
    // }
  }

  const postLocalOnlinePayment = async (localId: string): Promise<any> => {
    const response = await api.post(`${baseUrl}/payments/solicitations`, {
      ownerId: localId
    });
    return response.data;
  }

  const getOnlinePaymentStatus = async (localId: string): Promise<IGetOnlinePaymentStatusUseCase> => {
    const response = await api.get(`${baseUrl}/payments/owner/${localId}/status`);
    return response.data;
  }

  const getLocalOnlinePaymentHistory = async (ownerId?: string): Promise<IGetOnlinePaymentHistoryResponse[]> => {
    const response = await api.get(`${baseUrl}/payments/owner/${ownerId}/change-histories`);
    return response.data;

    // return [
    //   {
    //     changeDate: new Date().toISOString(),
    //     changeDescription: 'Solicitou pagamento online (cargo: Supervisor)',
    //     changeTitle: 'Solicitação de pagamento online',
    //     changedBy: 'Ramon Campos'
    //   },
    //   {
    //     changeDate: new Date().toISOString(),
    //     changeDescription: 'Pagamento online habilitado via Admin',
    //     changeTitle: 'Pagamento online habilitado',
    //     changedBy: 'Gabriel Esteves'
    //   },
    //   {
    //     changeDate: new Date().toISOString(),
    //     changeDescription: 'Pagamento online desabilitado (cargo: Supervisor)',
    //     changeTitle: 'Pagamento online desabilitado',
    //     changedBy: 'Isabela Alcântara'
    //   },
    // ]
  }

  const getOnlinePaymentSolicitations = async (): Promise<IGetOnlinePaymentSolicitationResponse[]> => {
    // const response = await api.get(`${baseUrl}/subscriptions/downgrade`, { params: { ownerId } });
    // return response.data;

    return [
      {
        id: '',
        localId: '',
        localName: '',
        userId: '',
        userName: '',
        solicitationDate: '',
      }
    ]
  }

  const enableOnlinePaymentStatus = async (ownerId: string): Promise<void> => {
    return await api.post(`${baseUrl}/payments/enableOnlinePaymentStatus`, { ownerId });
  }

  const disableOnlinePaymentStatus = async (ownerId: string): Promise<void> => {
    return await api.post(`${baseUrl}/payments/disableOnlinePaymentStatus`, { ownerId });
  }

  return {
    getLocalOnlinePayment,
    postLocalOnlinePayment,
    getOnlinePaymentStatus,
    getLocalOnlinePaymentHistory,
    getOnlinePaymentSolicitations,
    enableOnlinePaymentStatus,
    disableOnlinePaymentStatus
  }
}
