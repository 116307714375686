import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";
import { IPrinterItem } from "modules/printQueue/presentation/interfaces/IPrinterItem";
const GetPrinterListUseCase = async (smartPrintersApi: IPrintQueueApiService, localClienteId: string):Promise<IPrinterItem[]> => {

    const response = await smartPrintersApi.getPrinterListByLocalClenteId(localClienteId);
    return response
}

export default GetPrinterListUseCase;



