
export interface IPaymentLinkInfo {
  name?: string;
  description?: string;
  expiration?: string;
  image?: string;
}

export enum PaymentTypeEnum {
  CREDIT = 1,
  TICKET = 9,
  PIX = 18,
}

export interface IPaymentLinkValues {
  productId?: string;
  price?: number;
  paymentType?: PaymentTypeEnum;
  installments?: number;
  installmentsTax?: number;
}
