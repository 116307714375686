import { Button, TextField } from "@material-ui/core";
import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { ILinkFilter } from "../../interfaces/ILinkFilter";
import styles from "./LinkFilter.module.scss";

interface ILinkFilterProps {
  onSubmit: (values: ILinkFilter | null) => void;
}

const LinkFilter: FC<ILinkFilterProps> = ({ onSubmit }) => {
  const valueRef = useRef<HTMLInputElement>(null);

  const [filterValues, setFilterValues] = useState<ILinkFilter | null>(null);

  const handleChangeName = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = ev.target.value;
      setFilterValues((prev) => ({ ...prev, name }));
    },
    []
  );

  const handleChangeDate = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const expiration = ev.target.value;
      setFilterValues((prev) => ({ ...prev, expiration }));
    },
    []
  );

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;
      value = value.replace(/,/g, ".");
      setFilterValues((prev) => ({
        ...prev,
        price: Number(value) === 0 ? undefined : value,
      }));
    },
    []
  );

  const inputMoneyMask = useCallback((value?: string) => {
    if (!value) {
      return undefined;
    }
    value = value.replace(/\D/g, "");
    while (value.length < 3) {
      value = "0" + value;
    }
    value = value.replace(/^0+(?=\d{3,})/, "");

    let maskedValue = "R$ " + value.slice(0, -2) + "," + value.slice(-2);
    if (value === "000") {
      if (valueRef.current) {
        valueRef.current.value = "";
      }
      return undefined;
    }
    return maskedValue;
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(filterValues);
    },
    [filterValues, onSubmit]
  );

  return (
    <div id={styles.LinkFilter}>
      <form onSubmit={submitHandle}>
        <div className={styles.containerFilter}>
          <div className={styles.content}>
            <div className={styles.name}>
              <label>Nome</label>
              <TextField
                fullWidth
                inputProps={{ maxLength: 250 }}
                size="small"
                placeholder="Insira o nome"
                variant="outlined"
                name={"name"}
                onChange={handleChangeName}
              />
            </div>
            <div className={styles.innerContent}>
              <div className={styles.value}>
                <label>Valor</label>
                <TextField
                  inputRef={valueRef}
                  size="small"
                  placeholder={"R$ 0,00"}
                  value={inputMoneyMask(filterValues?.price)}
                  variant="outlined"
                  name={"value"}
                  onChange={handleChangeValue}
                />
              </div>
              <div className={styles.date}>
                <label>Data de expiração</label>
                <TextField
                  InputProps={{
                    inputProps: {
                      style: {
                        color: filterValues?.expiration
                          ? "black"
                          : "var(--Neutral-Gray-700, #A2A2A2)",
                      },
                    },
                  }}
                  size="small"
                  type="date"
                  variant="outlined"
                  name={"expiration"}
                  onChange={handleChangeDate}
                />
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              variant={"contained"}
              className={styles.button}
              fullWidth
              type="submit"
            >
              Buscar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LinkFilter;
