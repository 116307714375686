
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import { EditCouponUseCase } from "../../application/useCases/EditCouponUseCase";
import { ICouponForm } from "../interfaces/ICouponForm";



const UseEditCoupon = (onClose: () => void,) => {

  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successEdit, setSuccessEdit] = useState(false)

  const putEditCoupon = useCallback(
    async (values: ICouponForm, couponId: string) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoadingEdit(true);

        if (currentLocal) {
          const response = await EditCouponUseCase(serviceDiscountCoupons,
            {...values, devices: values.devices.map(item => item.deviceId)},
            currentLocal.id,
            couponId,
          );
          toast("Cupom editado com sucesso", "success")
          setSuccessEdit(true); 
          return response
        }
      } catch {
        setSuccessEdit(false);

      }
      finally {
        setIsLoadingEdit(false);
      }
    },
    [currentLocal, toast]
  );

  return {
    isLoadingEdit,
    putEditCoupon,
    successEdit,
    setSuccessEdit
  };
};

export default UseEditCoupon;
