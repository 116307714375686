import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from "react";
import TIDValidationForm from "../forms/tidValidation/TIDValidationForm";
import { UseAprovacaoTIDsSideSheet } from "./UseAprovacaoTIDsSideSheet";
import DeleteValidation from "components/deleteValidation/DeleteValidation";

export type SideSheetType = "VALIDATE" | "APPROVE" | "REJECT";

export interface AprovacaoTIDsSideSheetHandles {
  openSideSheet: (TIDsAdicionaisId: string, type: SideSheetType) => void;
}

export interface IAprovacaoTIDsSideSheet {
  getTIDsList: () => Promise<void>;
}

const AprovacaoTIDsSideSheet: ForwardRefRenderFunction<
  AprovacaoTIDsSideSheetHandles,
  IAprovacaoTIDsSideSheet
> = ({ getTIDsList }, ref) => {
  const {
    isOpen,
    openSideSheet,
    onClose,
    isLoading,
    handleSave,
    handleChangeForm,
    values,
    errors,
    type,
  } = UseAprovacaoTIDsSideSheet(getTIDsList);

  useImperativeHandle(ref, () => ({
    openSideSheet,
  }));

  const handleContent = useCallback(() => {
    return type === "VALIDATE" ? (
      <TIDValidationForm
        handleChangeForm={handleChangeForm}
        values={values}
        errors={errors}
      />
    ) : type === "APPROVE" ? (
      <div style={{ marginTop: 16 }}>
        <DeleteValidation
          text="Deseja realmente aprovar esta solicitação?"
          highlightText="Essa ação não poderá ser desfeita!"
        />
      </div>
    ) : (
      <div style={{ marginTop: 16 }}>
        <DeleteValidation
          text="Deseja realmente rejeitar esta solicitação?"
          highlightText="Essa ação não poderá ser desfeita!"
        />
      </div>
    );
  }, [errors, handleChangeForm, type, values]);

  return (
    <Sidesheet
      open={isOpen}
      onClose={isLoading ? () => {} : onClose}
      isLoading={isLoading}
      title={
        <h2>
          {`${
            type === "VALIDATE"
              ? "Validar "
              : type === "APPROVE"
              ? "Aprovar "
              : "Rejeitar "
          }`}{" "}
          <b>TIDs</b>
        </h2>
      }
      content={handleContent()}
      continueButton={`${
        type === "VALIDATE"
          ? "Validar "
          : type === "APPROVE"
          ? "Aprovar "
          : "Rejeitar "
      }`}
      handleContinueButton={isLoading ? () => {} : handleSave}
      customizedCancelButton={"Cancelar"}
      cancelButton={true}
      notTotalHeight
      feedback={
        <SidesheetFeedback text={`TIDs cadastrados com sucesso!`} success />
      }
    />
  );
};

export default forwardRef(AprovacaoTIDsSideSheet);
