import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigTrack } from "./UseConfigTrack";
import Sidesheet from "components/sidesheet/Sidesheet";
import ConfigContentTrackco from "../../components/_configCampaign/configContentTrackco/ConfigContentTrackco";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useMemo, useState } from "react";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

export interface IConfigTracklProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigTrack = ({ open, setOpen, step, setStep }: IConfigTracklProps) => {
  const [error, setError] = useState({ organizationId: "", token: "" });
  const { loading, config, saveConfig, values, setValues } = UseConfigTrack();

  const handleConnect = async () => {
    setError({ organizationId: "", token: "" });
    if (!values.organizationId.trim() && !values.token.trim()) {
      return setError({
        ...error,
        organizationId: "Campo obrigatório!",
        token: "Campo obrigatório!",
      });
    }
    if (!values.organizationId.trim()) {
      return setError({ ...error, organizationId: "Campo obrigatório!" });
    }
    if (!values.token.trim()) {
      return setError({ ...error, token: "Campo obrigatório!" });
    }
    const response = await saveConfig();
    if (response === "ok") setStep(step + 1);
  };

  const handleStatus = useMemo(
    () => (config ? "enabled" : "disabled"),
    [config]
  );

  return (
    <>
      <ContentBox
        loading={loading}
        status={handleStatus}
        type={IntegrationTypeEnum.TRACK}
        text="Ferramenta de pesquisa de satisfação do cliente."
        setOpen={setOpen}
        premium={true}
        getMoreRef="/private/track"
        featureType={FeatureTypeEnum.SURVEY}
      />
      {/* <div className={styles.container}>
          <h1>Insira seu token</h1>

          <p>
            Insira seu token e o ID da organização disponibilizado na ferramenta
            do Tracksales e clique em “Vincular”. Depois configure suas
            pesquisas.
          </p>

          {isEditing || !config ? (
            <div className={styles.row}>
              <div>
                <label>Organização ID</label>
                <Input
                  variant={"outlined"}
                  value={values?.organizationId}
                  onChange={(ev) =>
                    setValues((prev) => ({
                      ...prev,
                      organizationId: ev.target.value,
                    }))
                  }
                  className={styles.input}
                />
              </div>
              <div>
                <label>Token</label>
                <Input
                  variant={"outlined"}
                  value={values?.token}
                  onChange={(ev) =>
                    setValues((prev) => ({ ...prev, token: ev.target.value }))
                  }
                />
              </div>
              <Button
                className={styles.button}
                variant="outlined"
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  saveConfig();
                }}
              >
                {isEditing ? "Salvar" : "Vincular"}
              </Button>
              {isEditing && (
                <Button
                  className={styles.cancelButton}
                  variant="text"
                  type="button"
                  onClick={() => setIsEditing(false)}
                >
                  Cancelar
                </Button>
              )}
            </div>
          ) : (
            <div className={styles.row}>
              <div className={styles.column}>
                <label>Id da Organização</label>
                <span className={styles.dataInfo}>{config.organizationId}</span>
              </div>
              <div className={styles.column}>
                <label>Token</label>
                <span className={styles.dataInfo}>{config.token}</span>
              </div>
              <div className={styles.actionColumn}>
                <button
                  className={styles.editButton}
                  onClick={() => setIsEditing(true)}
                >
                  <Icon className={styles.icon}>edit</Icon>
                  <span>Editar informações</span>
                </button>
              </div>
              <div
                className={styles.actionColumn}
                style={{ marginLeft: "auto" }}
              >
                <div className={styles.success}>
                  <img src="/assets/icon/check.svg" alt="" />
                  <p>Vinculado com sucesso!</p>
                </div>
              </div>
              <div className={styles.actionColumn}>
                <button
                  className={styles.configButton}
                  onClick={() => push("/private/track")}
                >
                  <img src="/assets/icon/config-track.svg" alt="" />
                  <span>Configurar Track.co</span>
                </button>
              </div>
            </div>
          )}
        </div> */}
      {/* {open && open === "Track.co" && (
        <ConfigContentBox open={open} setOpen={setOpen} />
      )} */}
      {open && open === "Track.co" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            <ConfigContentTrackco
              step={step}
              values={values}
              setValues={setValues}
              error={error}
              setError={setError}
            />
          }
          cancelButton
          continueButton="Concluir"
          handleContinueButton={handleConnect}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração realizada com sucesso!"
              success
            />
          }
          isLoading={loading}
        />
      )}
    </>
  );
};

export default ConfigTrack;
