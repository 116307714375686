import { EditCustomerSummaryUseCase } from "modules/fidelity/application/useCases/EditCustomerSummaryUseCase";
import { GetCustomerSummaryUseCase } from "modules/fidelity/application/useCases/GetCustomerSummaryUseCase";
import { IFidelitySummary } from "modules/fidelity/presentation/interfaces/IFidelitySummary";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useEffect, useState } from "react";
import { FidelityServiceApi } from "services/api/fidelity/FidelityApi";
import { FidelitySummaryStep } from "./FidelityEditSummary";
import { ISummaryPointsFormValue } from "./pointsForm/FidelityPointsForm";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const service = FidelityServiceApi();

export type valuesType = { points: number | undefined, coins: number | undefined, reason: string }

export const UseFidelityEditSummary = (customerDocument: string, onClose: () => void, onFinish: () => void) => {
    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const [step, setStep] = useState(FidelitySummaryStep.points);
    const [customerData, setCustomerData] = useState<IFidelitySummary>();
    const [preLoading, setPreLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [confirmType, setConfirmType] = useState<'coins' | 'points'>();
    const [values, setValues] = useState<ISummaryPointsFormValue>();

    useEffect(() => {
        (async () => {
          if (currentLocal) {
            try {
              const response = await GetCustomerSummaryUseCase(service, customerDocument, currentLocal.id);
              setCustomerData(response);
            } catch (error: any) {
                toast(error.response.data.errorMessage, "error");
            } finally {
              setPreLoading(false);
            }
          }
        })();
    }, [currentLocal, customerDocument, toast]);

    const onSubmitFormHandle = (values: ISummaryPointsFormValue) => {
        setValues(values)
        setConfirmType(step === FidelitySummaryStep.coins ? 'coins' : 'points')
    }

    const confirmSubmit = async () => {
        try {
            setLoading(true);

            if (currentLocal && values && values?.points) {
                if (step === FidelitySummaryStep.points) {

                    await EditCustomerSummaryUseCase(
                        service,
                        customerDocument,
                        currentLocal.id,
                        values.balanceType,
                        values?.points,
                        0,
                        values.reason
                    );
                    onClose()
                    onFinish()
                }
                setConfirmType(undefined);

            }
        } finally {
            setLoading(false);
        }
    }

    return {
        preLoading,
        loading,
        customerData,
        values,
        confirmType,
        step,
        setValues,
        setStep,
        setConfirmType,
        onSubmitFormHandle,
        confirmSubmit
    }
}
