import React, { FC, useCallback, useMemo } from 'react'
import styles from './TablePrinterForm.module.scss'
import { Autocomplete, Checkbox, Icon, TextField } from '@mui/material'
import Button from 'components/ui/Button/Button'

export interface ISelectItem {
    id: string,
    name: string
}

export interface ITableFormProps {
    tableList: ISelectItem[],
    defaultValue: string[],
    onClose: () => void
    onSubmit: (values: string[]) => Promise<void>
    description?: string,
    label: string

}
const TableForm: FC<ITableFormProps> = ({ tableList, defaultValue, onClose, onSubmit, description, label }) => {
    const [values, setValues] = React.useState<string[]>(defaultValue);
    const [isLoading, setIsLoading] = React.useState(false);

    const optionsList = useMemo(() => [{ id: "-1", name: "Selecionar todas" }, ...tableList], [tableList]);

    const onChangeHandleListTable = useCallback((value: ISelectItem[]) => {
        if (value.find((item) => item.name === "Selecionar todas")) {
            return setValues(optionsList.map(item => item.id));
        }
        setValues(value.map(item => item.id));
    }, [optionsList]);

    const onSubmitHandle = useCallback(async () => {
        let selectedTables = values;
        const findSelectAllId = values.find(item => item === "-1");

        if (findSelectAllId) {
            selectedTables = values.filter(item => item !== "-1");
        }
        
        try {
            setIsLoading(true);
            await onSubmit(selectedTables);
            onClose();
        } finally {
            setIsLoading(false);
        }
    }, [onClose, onSubmit, values]);


    return (
        <div
            id={styles.PrinterForm}
        >
            {description && <div className={styles.description}><Icon>info</Icon><div>{description}</div></div>}
            <div className={styles.container} >
                <label>{label}</label>
                <Autocomplete
                    limitTags={3}
                    multiple
                    size="small"
                    disableCloseOnSelect
                    className={styles.input}
                    style={{
                        maxHeight: 400,
                        overflowY: 'auto',
                    }}
                    getOptionLabel={option => option.name}
                    value={optionsList.filter(item => values.includes(item.id))}
                    options={optionsList}
                    onChange={(ev, value) => onChangeHandleListTable(value as ISelectItem[])}
                    renderOption={(props, option, state) => (<li {...props}><Checkbox checked={state.selected} />{option.name}</li>)}
                    renderInput={(params) => (
                        <TextField
                            variant={"outlined"}
                            {...params}

                        />
                    )}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button variant="outlined" onClick={onClose} >Voltar</Button>
                <Button disabled={isLoading} onClick={onSubmitHandle} >Salvar</Button>
            </div>
        </div>
    )
}
export default TableForm