import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import PaymentLinkPage from "modules/paymentLink/presentation/pages/PaymentLinkPage";
import TabPage from "../dashboard/tabPage/TabPage";
import { FC, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import PaymentLinkReportPage from "modules/paymentLinkReport/presentation/pages/PaymentLinkReportPage";

interface IFinanceiroMenu {
  label: string;
  tab: string;
  papel?: string;
  component?: React.ReactNode;
  subItems?: {
    label: string;
    tab: string;
    papel: string;
    component?: React.ReactNode;
  }[];
}

const PaymentLink: FC = () => {
  const { tab, subTab } = useParams<{ tab: string; subTab: string }>();
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    const currentTab = linkPagamentoTabs.find((item) => tab === item.tab);

    const routers =  [
      { label: "Financeiro", title: "Financeiro" },
      { label: "Financeiro", title: "Link de pagamento" },
      { label: "Financeiro", title: currentTab?.label },
    ];

    updateRouters(routers);
    return () => {
      updateRouters([]);
    };
  }, [subTab, tab, updateRouters]);

  const subTabComponent = useCallback((tabItem: IFinanceiroMenu) =>
    !!tabItem.subItems?.length ? (
      <TabPage
        routerPrefix={`/private/financeiro/link-pagamento/${tabItem.tab}`}
        subTabEnabled
        hideTabs
        tabsPages={tabItem.subItems!.map((subTabItem) => ({
          label: subTabItem.label,
          tab: subTabItem.tab,
          component: subTabItem.component,
        }))}
      />
    ) : tabItem.component, []);

  return (
    <Layout>  
      <TabPage
        routerPrefix="/private/financeiro/link-pagamento"
        hideTabs
        tabsPages={linkPagamentoTabs.map((tabItem) => ({
          label: tabItem.label,
          tab: tabItem.tab,
          component: subTabComponent(tabItem),
        }))}
      />
    </Layout>
  );
};

export default PaymentLink;

const linkPagamentoTabs: IFinanceiroMenu[] = [
  {
    label: "Meus links",
    tab: "meus-links",
    component: <PaymentLinkPage />,
    papel: ''
  },
  {
    label: "Relatório de recorrências",
    tab: "relatorio-recorrencia",
    component: <PaymentLinkReportPage/>,
    papel: ''
  },
];
