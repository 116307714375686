import React from "react";
import DiscountListForm from "../../components/discountsListForm/DiscountListForm";
import UseAddDiscounts from "./UseAddDiscounts";

export interface IAddDiscountProps {
  onClickCloseHandle: () => void;
  getDiscountsList: () => Promise<void>;
}

const AddDiscounts: React.FC<IAddDiscountProps> = ({ onClickCloseHandle, getDiscountsList }) => {
  const { handleSubmit } = UseAddDiscounts(
    () => {
      getDiscountsList();
      onClickCloseHandle();
    }
  );

  return (

    <DiscountListForm handleSubmit={handleSubmit} onClickCloseHandle={onClickCloseHandle} />

  );
};

export default AddDiscounts;
