export interface IGetPendingExternalPosResponse {
  id: string;
  nickname: string;
  acquirerType: number;
  integrationCode: string;
  serialNumber: string;
  status: PendingExternalPosStatusEnum;
  createdAt: string;
}

export enum PendingExternalPosStatusEnum {
  Pending = 1,
  Imported = 2,
  Approved = 3,
  Completed = 4
}
