import { forwardRef } from 'react';
import styles from './Skeleton.module.scss';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    items?: number;
    containerStyle?: string;
    cardStyle?: string;
    itemStyle?: string;
}

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({ items = 5, containerStyle, cardStyle, itemStyle, ...rest }, ref) => {

    const drawItems = () => {
        const contents = []
        for (let index = 0; index < items; index++) {
            contents.push(
                <div key={index} className={`${styles.animatedCard} ${cardStyle || ''}`}>
                    <div className={`${itemStyle || ''} ${styles.animatedItem}`} />
                </div>
            )
        }

        return contents;
    }

    return (
        <div ref={ref} {...rest} id={styles.Skeleton} className={containerStyle || ''}>
            {drawItems()}
        </div>
    )
})

export { Skeleton };