import { useState } from 'react';
import { IconButton, Modal } from '@material-ui/core';
import { Close, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';

import styles from './WizardInfoModal.module.scss';

interface WizardInfoModalProps {
    isOpen: boolean;
    content: WizardInfoModalContent[];
    closeFn: () => void
}

interface WizardInfoModalContent {
    image: string;
    title: string;
    description: string;
}

const WizardInfoModal = ({ isOpen, content = [], closeFn }: WizardInfoModalProps) => {
    const [currentView, setCurrentView] = useState(0);

    const goForward = () => {
        if (currentView < content.length) {
            setCurrentView(prev => prev + 1);
        }
    }

    const goBackward = () => {
        if (currentView > 0) {
            setCurrentView(prev => prev - 1);
        }
    }

    const setViewByThumb = (viewPosition: number) => {
        setCurrentView(viewPosition);
    }

    const closeWizardInfoModal = () => {
        setCurrentView(0);
        closeFn();
    }
    
    return (
        <Modal open={isOpen}>
            <div className={styles.wizardInfoModalWrapper}>
                <div className={styles.wizardInfoModal} style={{ backgroundImage: 'url(/assets/icon/saas/pos-modal-bg-2.svg)'}}>
                    <IconButton className={styles.wizardInfoModalClose} onClick={closeWizardInfoModal}>
                        <Close />
                    </IconButton>

                    <div className={styles.wizardInfoModalContentWrapper}>
                        <div>
                            <IconButton className={styles.wizardInfoModalControl} data-disabled={currentView === 0} onClick={goBackward}>
                                <KeyboardArrowLeftOutlined />
                            </IconButton>
                        </div>

                        <div className={styles.wizardInfoModalContent}>
                            <div className={styles.wizardInfoModalContentImageBox} style={{ backgroundImage: 'url(/assets/icon/saas/pos-modal-bg.svg)'}}>
                                <img className={styles.wizardInfoModalContentImage} src={content[currentView].image} alt={content[currentView].title} />
                            </div>

                            <div className={styles.wizardInfoModalContentTexts}>
                                <p className={styles.wizardInfoModalContentTitle}>{content[currentView].title}</p>
                                <p className={styles.wizardInfoModalContentDescription}>{content[currentView].description}</p>
                            </div>
                        </div>

                        <div>
                            <IconButton className={styles.wizardInfoModalControl} data-disabled={currentView === content.length - 1} onClick={goForward}>
                                <KeyboardArrowRightOutlined />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.wizardInfoModalThumbs}>
                        {content.map((thumb, index) => <div className={styles.wizardInfoModalThumb} data-active={currentView === index} onClick={() => setViewByThumb(index)}></div>)}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default WizardInfoModal;