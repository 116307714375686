import Header from "../header/Header";
import styles from "./AccordionContent.module.scss";
import { format } from "date-fns";
import { CircularProgress } from "@material-ui/core";
import { IGetContractResponse } from "modules/equipamento/domain/dto/IGetContractResponse";
import { IGetEquipmentsResponse } from "modules/equipamento/domain/dto/IGetEquipmentsResponse";

export interface IAccordionContentProps {
  contractTitle: string[];
  contractLicenses?: IGetContractResponse[];
  equipmentsTitle: string[];
  equipments?: IGetEquipmentsResponse;
}

const AccordionContent = ({
  contractTitle,
  contractLicenses,
  equipmentsTitle,
  equipments,
}: IAccordionContentProps) => {
  return (
    <>
      <div className={styles.accordionContainer}>
        <div>
          <Header
            titleHeader={contractTitle}
            gridTemplateColumns="2fr 1fr 1fr"
          />
          <div className={styles.licensesContainer}>
            {!contractLicenses && (
              <div className={styles.flexCenter}>
                <CircularProgress size={16} color="inherit" />
              </div>
            )}
            {contractLicenses?.map((contract) => (
              <div className={styles.contractsGrid} key={contract.Numero}>
                <span>{contract.Numero}</span>
                <span>
                  {format(new Date(contract.DataInicio), "dd/MM/yyyy")}
                </span>
                <span>{format(new Date(contract.DataFim), "dd/MM/yyyy")}</span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Header
            titleHeader={equipmentsTitle}
            gridTemplateColumns="1fr 1fr 1fr 1fr"
          />
          <div className={styles.licensesContainer}>
            {!equipments && (
              <div className={styles.flexCenter}>
                <CircularProgress size={16} color="inherit" />
              </div>
            )}
            {equipments?.Records?.map((equipment) => (
              <div
                className={`${styles.contractsGrid} ${styles.equipmentsGrid}`}
                key={equipment.Licenses}
              >
                <span>{equipment.DeviceTypeName}</span>
                <span>{equipment.Licenses}</span>
                <span>{equipment.Used}</span>
                <span>{equipment.Available}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionContent;
