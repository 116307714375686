import { MenuItem, TextField } from '@material-ui/core'
import Autocomplete from 'components/ui/autoComplete/AutoComplete'
import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import InputTextArea from 'components/ui/inputTextArea/InputTextArea'
import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import { ISpecialDays } from 'modules/schedule/domain/dto/placeSchedule/IAddPlaceScheduleRequest'
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import { IAddSpecialDays } from 'modules/schedule/domain/models/ISpecialDays'
import React, { FC } from 'react'
import AddSpecialDaysForm from '../addSpecialDays/AddSpecialDaysForm'
import AvailableDaysForm from '../availableDaysForm/AvailableDaysForm'
import { IPlaceScheduleForm } from './interface/IPlaceScheduleForm'
import styles from './PlaceForm.module.scss'
import UsePlaceForm from './UsePlaceForm'
export interface IPlaceFormProps {
    //propertys
    orderPadsList: ISelect[];
    servicesList: ISelect[];
    defaultValues?: IPlaceScheduleForm;
    onSubmit: (values: IPlaceScheduleForm) => void
    handleSpecialDays: (values: IAddSpecialDays[]) => void
}

const PlaceForm: FC<IPlaceFormProps> = ({ defaultValues, handleSpecialDays, onSubmit, orderPadsList: orderPadsList, servicesList }) => {
    const { changeHandle, values, changeSelectHandle, onSubmitHandle, errors, selectedOrderPads: selectedOrderPads, selectedServices, changeAvalableDaysHandle, onChangeMinimalLeadHandle, onChangeMinimumDayTypeHandle, minimumTimeType, minimumLeadTime } = UsePlaceForm(onSubmit, defaultValues)
    return (
        <div id={styles.PlaceForm} >
            <form onSubmit={onSubmitHandle}>
                <FormItemContainer>
                    <div className={styles.containerHelp}>
                        <h2>Informações do passeio</h2>
                        <TooltipInfo
                            title={"Informações do passeio"}
                            paragraph={"Preencha as informações do seu passeio, como: lotação máxima, serviços e comandas."}
                        />
                    </div>
                    <FormItemContainer label={"Prefixo"}>
                        <Input
                            value={values.prefix}
                            name={"prefix"}
                            onChange={changeHandle}
                            placeholder="Ex.: Nome de identificação"
                            error={!!errors.prefix}
                            helperText={errors.prefix}
                        />
                    </FormItemContainer>
                    <FormItemContainer label={"Quantidade Maxima de Pessoas"}>
                        <Input
                            value={values.maxQuantity}
                            type="number"
                            name={"maxQuantity"}
                            onChange={changeHandle}
                            placeholder="Ex.: 130"
                            error={!!errors.maxQuantity}
                            helperText={errors.maxQuantity}
                        />
                    </FormItemContainer>
                    {/* <FormItemContainer label={"Comandas"}>
                        <Autocomplete
                            variant="standard"
                            multiple
                            value={selectedOrderPads}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.description}
                            options={orderPadsList}
                            name="orderPadsIds"
                            required={selectedOrderPads.length < 1}
                            onChange={(ev, values) => changeSelectHandle("orderPadsIds", values as ISelect[])}
                        />
                    </FormItemContainer> */}
                    <FormItemContainer label={"Serviços"}>
                        <Autocomplete
                            variant="standard"
                            multiple
                            value={selectedServices}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.description}
                            options={servicesList}
                            error={!!errors.selectedServices}
                            helperText={errors.selectedServices}
                            name="servicesScheduleIds"
                            onChange={(ev, values) => changeSelectHandle("servicesScheduleIds", values as ISelect[])} />
                    </FormItemContainer>
                    {/* <FormItemContainer label={"Descrição"}>
                        <InputTextArea variant="outlined" value={values.description} rows={4} multiline required name={"description"} onChange={changeHandle} placeholder="Descrição do passeio" />
                    </FormItemContainer> */}
                    <FormItemContainer>
                        <div className={styles.containerAvailability}>
                            <div className={styles.containerHelp}>
                                <h2>Disponibilidade</h2>
                                <TooltipInfo
                                    title={"Disponibilidade"}
                                    paragraph={"Tempo para agendamento: Quanto tempo será possível agendar antes do próximo passeio/transfer/jantar. Tempo de disponibilidade: Quanto tempo será possível agendar com antecedência."}
                                />
                            </div>
                            <FormItemContainer row>
                                <FormItemContainer row >
                                    <FormItemContainer label={"Tempo para agendamento"}>
                                        <Input
                                            value={minimumLeadTime}                                            
                                            type="number"
                                            name={"minimumLeadTime"}
                                            onChange={(ev) => onChangeMinimalLeadHandle(Number(ev.target.value))}
                                            placeholder="Ex.: 30"
                                            // error={!!errors.minimumLeadTime}
                                            // helperText={errors.minimumLeadTime}
                                            inputProps={
                                                {
                                                    inputMode: "numeric",
                                                    min: 0
                                                }
                                            }
                                        />
                                    </FormItemContainer>
                                    <FormItemContainer label={"Tipo"}>
                                        <TextField select
                                            value={minimumTimeType}
                                            style={{ width: "100px" }}
                                            onChange={(ev) => { onChangeMinimumDayTypeHandle(ev.target.value as "days" | "hours" | "minutes") }}
                                        >
                                            <MenuItem value={"minuntes"} >Minutos</MenuItem>
                                            <MenuItem value={"hours"} >Horas</MenuItem>
                                            <MenuItem value={"days"} >Dias</MenuItem>
                                        </TextField>
                                    </FormItemContainer>
                                </FormItemContainer>


                                <FormItemContainer label={"Tempo de disponibilidade(dias)"}>
                                    <Input
                                        value={values.maximumLeadTimeInDays}
                                        required
                                        type="number"
                                        name={"maximumLeadTimeInDays"}
                                        onChange={changeHandle}
                                        placeholder="Ex.: 360"
                                        // error={!!errors.maximumLeadTimeInDays}
                                        // helperText={errors.maximumLeadTimeInDays}
                                        inputProps={
                                            {
                                                inputMode: "numeric",
                                                min: 0
                                            }
                                        }
                                    />
                                </FormItemContainer>
                            </FormItemContainer>
                        </div>
                    </FormItemContainer>
                    {/*avalableDays*/}
                    <FormItemContainer>
                        <AddSpecialDaysForm handleSpecialDays={handleSpecialDays}></AddSpecialDaysForm>
                    </FormItemContainer>
                    <FormItemContainer>
                        <AvailableDaysForm values={values.availableDays} hasError={errors} onChange={changeAvalableDaysHandle}></AvailableDaysForm>
                    </FormItemContainer>
                    <div className={styles.buttonSave} >
                        <Button
                            fullWidth={false}
                            type="submit">Salvar
                        </Button>
                    </div>
                </FormItemContainer>
            </form >
        </div >
    )
}
export default PlaceForm