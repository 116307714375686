import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IEventsForm } from "modules/events/domain/models/IEvents";
import { IEventsRequest } from "modules/events/domain/dto/IEventsRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";
import CreateEventUseCase from "modules/events/application/useCases/events/CreateEventUseCase";

interface IUseGuestList {
  handleSubmit: (newEvent: IEventsForm) => Promise<void>;
  handleCancel: () => void;
}

export const UseNewEventsPage = (): IUseGuestList => {
  const { replace } = useHistory();
  const { toast, hideLoading, showLoading } = useUi();
  const { currentLocal } = useLocal();

  const handleCancel = useCallback( () => {
    replace("/private/eventos/list");
  }, [replace]);

  const handleSubmit = useCallback(
    async (newEvent: IEventsForm) => {
      const eventsService = EventsApi();
      if (currentLocal) {
        showLoading();
        try {
          const eventRequest: IEventsRequest = {
            name: newEvent.name,
            // localName: newEvent.localName,
            // localId: newEvent.localId,
            openingDates: [
              {
                startDate: new Date(newEvent.startDateString).toISOString(),
                endDate: new Date(newEvent.endDateString).toISOString(),
              },
            ],
            description: newEvent?.description,
            availableForSalesStart: new Date(newEvent.startSaleDate).toISOString(),
            availableForSalesEnd: new Date(newEvent.endSaleDate).toISOString(),
            attractions: newEvent?.attractions,
            imageUrl: newEvent.imageUrl,
            latitude: newEvent?.latitude,
            longitude: newEvent?.longitude,
            address: newEvent?.address,
            // promotersCanAdd: eventForm.promotersCanAdd,
            localId: currentLocal.id,
          };

          await CreateEventUseCase(eventsService, eventRequest);
          toast("Evento criado com sucesso!", "success");
          handleCancel();
        } catch (error) {
          // toast("Erro ao cadastrar evento", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [currentLocal, handleCancel, hideLoading, showLoading, toast]
  );

  return {
    handleSubmit,
    handleCancel,
  };
};
