import styles from "./ContentCard.module.scss";

export interface IContentCardProps {
  img?: string;
  title: string;
  description: string;
  link: string;
}

const ContentCard = ({ img, title, description, link }: IContentCardProps) => {
  return (
    <div className={styles.contentCardContainer}>
      <img src={img ?? "/assets/img/example-tutorial.png"} alt="" />
      <h4>{title}</h4>
      <span>{description}</span>
      <a href={link}>
        <span>Ler mais...</span>
      </a>
    </div>
  );
};

export default ContentCard;
