import { FC, useCallback, useEffect, useState } from 'react';
import { AddOutlined } from '@material-ui/icons';
import { Icon } from '@material-ui/core';

import styles from './SaasPlanList.module.scss';
import SaasPlanCard from './SassPlanCard';
import RenderIf from 'components/renderIf/RenderIf';
import { ISaasPlan, ISaasPlanViewInfo } from './ISaasPlan';
import { SubscriptionType } from 'modules/saas/domain/interfaces/SubscriptionType';
import { IPlansItem } from 'modules/saas/presentation/interfaces/plans/IPlans';


const plansModel: ISaasPlanViewInfo[] = [
    //{
    //    id: '',
    //    name: 'Freemium',
    //    features: [
    //        'Cadastro de produtos',
    //        'Calculadora iFood',
    //        'Cardápio digital '
    //    ],
    //    cieloFeatures: [],
    //    price: 0,
    //    planRaw: null
    //},
    {
        name: 'Básico',
        features: [
            '1 licença de terminal de venda',
            '1 licença de máquina de cartão',
            'Dashboard detalhada',
            'Gestor de pedidos',
            'Terminal de vendas web',
            'Suporte via chat',
            'Relatório de vendas',
            'Integração fiscal'
        ],
        cieloFeatures: [
            '1 licença de maquininha',
            'Gestor de pedidos',
            'Dashboard detalhada',
            'Relatório de vendas',
            'Integração fiscal'
        ],
        //planRaw: null
    },
    {
        name: 'Intermediário',
        features: [
            '3 licenças de terminais de venda',
            '3 licenças de máquinas de cartão',
            'Delivery próprio',
            'Impressão remota na cozinha',
            'Integração iFood',
            'Pixel do Facebook',
            'App garçom (limitado)',
            '+ funcionalidades do básico'
        ],
        cieloFeatures: [
            '3 licenças de maquininha',
            'Impressão na cozinha',
            'integração iFood',
            'App para garçom',
            '+ plano anterior'
        ],
        //planRaw: null
    },
    {
        name: 'Avançado',
        features: [
            '5 licenças de terminais de venda',
            '5 licenças de máquinas de cartão',
            'CRM',
            'Programa de fidelidade',
            'Estoque',
            'Ficha técnica',
            'Financeiro (em breve)',
            'Contas a pagar',
            '+ funcionalidades do intermediário'
        ],
        cieloFeatures: [
            'CRM e campanhas',
            'Planos de fidelidade',
            'Estoque e ficha técnica',
            '+ plano anterior'
        ],
        //planRaw: null
    }
]

const SaasPlanList:FC<{plans: IPlansItem[];
selectedMode: SubscriptionType;
selectedPlan?: IPlansItem; 
hasBalance?: boolean;
isCieloCustomer?: boolean;
showAnnualOption?: boolean;
selectModeFn: (mode: SubscriptionType) => void;
selectPlanFn: (plan?: IPlansItem) => void;
}> = ({ 
    plans = [], 
    selectedMode,
     selectedPlan, 
     hasBalance = false, 
     isCieloCustomer = false, 
     showAnnualOption = true,
     selectModeFn, 
     selectPlanFn 
    }) => {
    const [displayPlans, setDisplayPlans] = useState<ISaasPlan[]>();

    useEffect(() => {
        console.log(plans);
       
        let plansView:ISaasPlan[] = []; 
        
        plansModel.forEach((planView) => {
            const plan = plans.find(item => item.name === planView.name && item.subscriptionType === selectedMode);
            if (plan) {
                plansView.push({
                    ...planView,
                    plan
                });
            }
        },[plans, selectedMode]);

        setDisplayPlans(plansView);

        if(!selectedPlan){
            selectPlanFn(plans.filter(item => item.subscriptionType === selectedMode)[1]);
        }
       
        //plans.forEach((plan) => {
        //    const price = plan.price;
        //    setDisplayPlans(prev => {
        //        if (isCieloCustomer) {
        //            prev = prev.filter(item => item.name !== 'Freemium');
        //        }

        //        return prev.map(item => {
        //            if (item.name === plan.name) {
        //                return {
        //                    ...item,
        //                    id: plan.id,
        //                    price: price,
        //                    planRaw: plan
        //                }
        //            }
        //            return item;
        //        });
        //    });
        //});
    }, [plans, isCieloCustomer, selectedMode]);

    const setSelectedPlanMode = useCallback((mode: SubscriptionType) => {
        selectModeFn(mode);
        //console.log(displayPlans);
        //Troca o modo mas mantem o plano do mesmo index
        selectPlanFn(
            plans?.find(item => item.subscriptionType === mode && item.index === selectedPlan?.index)
        );
    },[plans, selectModeFn, selectPlanFn, selectedPlan?.index]);
    

    return (
        <>
            <div className={styles.saasPlanModeList}>
                <RenderIf condition={showAnnualOption}>
                    <span
                        className={styles.saasPlanMode}
                        data-active={selectedMode === SubscriptionType.ANNUAL}
                        onClick={() => setSelectedPlanMode(SubscriptionType.ANNUAL)}>Anual</span>
                </RenderIf>

                <span
                    className={styles.saasPlanMode}
                    data-active={selectedMode === SubscriptionType.MONTHLY}
                    onClick={() => setSelectedPlanMode(SubscriptionType.MONTHLY)}>Mensal</span>
            </div>

            <div className={styles.saasPlanList}>
                {displayPlans?.map((plan: ISaasPlan) => (
                    <SaasPlanCard
                        key={plan.name}
                        planView={plan}
                        isCieloCustomer={isCieloCustomer}
                        currentPlanMode={selectedMode}
                        currentSelectedPlan={displayPlans.find(item => item.plan.id === selectedPlan?.id)}
                        isCurrentPlan={false}
                        selectFn={(selectedPlan: ISaasPlan) => 
                            selectPlanFn(selectedPlan.plan)
                        }
                    />
                ))}
            </div>

            <RenderIf condition={hasBalance}>
                <div className={styles.balanceWrapper}>
                    <div className={styles.balanceContainer}>
                        <div className={styles.images}>
                            <img src="/assets/icon/saas/machine.svg" alt="" className={styles.POSimg} />
                            <AddOutlined style={{ color: "#8D4FFF" }}/>
                            <img src="/assets/icon/saas/balance.png" alt="" className={styles.POSimg} />
                        </div>

                        <div className={styles.collumn}>
                            <span className={styles.title}>
                                <b><span style={{ color: "#8D4FFF" }}>Máquina + balança:</span> R$29,00 por mês</b>
                            </span>
                            <p>Taxas:</p>
                            <p>Débito: 1,5% (receba no dia seguinte)</p>
                            <p>Crédito: 3% (receba no dia seguinte)</p>
                            <p style={{ marginTop: "10px" }}>Balança:</p>
                            <p>Balança Comercial Digital Prix 3 Fit 15kg 100v/240v Cor Preto</p>
                            <a className={styles.details} href='/public/saas/fees' target='_blank' style={{ marginTop: "10px" }}>
                                <p>Ver detalhes</p>
                                <Icon>open_in_new</Icon>
                            </a>
                        </div>
                    </div>
                </div>
            </RenderIf>
        </>
    );
}

export default SaasPlanList;