
import { Pagination } from '@material-ui/lab'
import { IGetHouseFlowDetailsItemResponse } from 'modules/houseFlow/domain/dto/IGetHouseFlowDetailsItemResponse'
import { DetailsItemParamsClientHouseFlow } from 'modules/houseFlow/presentation/hooks/UseHouseFlowDetails'
import UseHouseFlowProductList, { ProdudutsParamsClientHouseFlow } from 'modules/houseFlow/presentation/hooks/UseHouseFlowProductList'
import { IDetailsItem, IHouseFlowDetails } from 'modules/houseFlow/presentation/interfaces/IHouseFlowDetails'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { v4 } from 'uuid'
import ConsumptionItem from '../consumptionItem/ConsumptionItem'
import { Skeleton } from '../skeleton/Skeleton'
import styles from './ConsumptionList.module.scss'


export interface IConsumptionListProps {
    consumptionList: IDetailsItem[] | undefined;
    params: DetailsItemParamsClientHouseFlow | undefined;
    getMore?: (pagination?: { page?: number, pageSize?: number }) => void;
    isLoading: boolean;
    count: number;
}

const ConsumptionList: React.FC<IConsumptionListProps> = ({ consumptionList, params, getMore, isLoading = true, count }) => {


    const list = useMemo(() => (
        consumptionList?.map((item, index) => (
            <ConsumptionItem
                key={index}
                item={item}
            />
        ))), [consumptionList])



    return (
        <div id={styles.ConsumptionList}>
            <div className={styles.container} >
                {
                    !consumptionList?.length ?
                        <section className={styles.wrapper}>
                            <div>Nenhum resultado</div>
                        </section>
                        :
                        <InfiniteScroll
                            next={() => {
                                getMore?.();
                            }}
                            hasMore={count ? consumptionList?.length < count : false}
                            loader={<Skeleton items={6} />}
                            dataLength={consumptionList?.length ?? 0}
                            scrollableTarget={styles.ConsumptionList}
                        >
                            <div className={styles.list}>
                                {list}
                            </div>
                        </InfiniteScroll>
                }
            </div>

        </div>
    )
}

export default ConsumptionList