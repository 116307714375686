import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const DoStraightCheckinUseCase = async (
  guestsService: IEventsGuestsApi,
  guestId: string,
  localId: string,
): Promise<void> => {
  return await guestsService.doStraightCheckin(guestId, localId);
};

export default DoStraightCheckinUseCase;
