import React, { FC } from "react";

import { Modal } from "components/ui/modal/Modal";
import { AddGuestsForm } from "../../components/AddGuestsForm/AddGuestsForm";
import { MigrateGuestList } from "../../components/migrateGuestList/MigrateGuestList";
import { UseAddGuestPage } from "./UseAddGuestPage";
import ListAlertGuests from "../../components/ListAlertGuests/ListAlertGuests";

const AddGuestPage: FC = () => {
  const { handleAddGuest, guestList, showMigrateModal, toggleMigrateModal, guestsToMigrate, listGuestFailed,
    setListGuestFailed, listCpf, setListCpf, showListAlertModal, setShowListAlertModal,hasRoleTransferCpf } =
    UseAddGuestPage();

  return (
    <div>
      <AddGuestsForm
        handleSubmit={handleAddGuest}
        listName={guestList?.name}
        listCpf={listCpf}
        setListCpf={setListCpf}
        eventName={guestList?.eventName}
        eventId={guestList?.eventId}
      />

      <ListAlertGuests
        listGuestFailed={listGuestFailed}
        setListGuestFailed={setListGuestFailed}
        listCpf={listCpf}
        open={showListAlertModal}
        toggleModal={setShowListAlertModal}
        handleSubmit={handleAddGuest} />

      <Modal
        title=""
        open={showMigrateModal}
        toggleModal={toggleMigrateModal}
        keepMounted={false}
        showButtons={false}
      >
        <MigrateGuestList listName={guestList?.name} hasPermissionToMigrate={hasRoleTransferCpf} guestsToMigrate={guestsToMigrate} toggleModal={toggleMigrateModal} />
      </Modal>
    </div>
  );
};
export default AddGuestPage;
