import DeleteCollaborator from "./deleteCollaborator/DeleteCollaborator";
import Sidesheet from "components/sidesheet/Sidesheet";
import { useCallback, useEffect, useState } from "react";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UseColaboradoresPage from "../../hooks/UseColaboradoresPage";
import { INewCollaboratorRequest } from "modules/colaboradores/domain/dto/INewCollaboratorRequest";
import { INewCollaboratorFormErrors, NewCollaboratorValidation } from "./validade/NewCollaboratorValidation";
import UseRolesList from "modules/cargos/presentation/hooks/UseRolesList";
import { IEmployeesList } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresResponse";
import { IEditCollaboratorRequest } from "modules/colaboradores/domain/dto/IEditCollaboratorRequest";
import { EditCollaboratorValidation, IEditCollaboratorFormErrors } from "./validade/EditCollaboratorValidation ";
import EditCollaborator from "./editCollaborator/EditCollaborator";
import NewCollaborator from "./newCollaborator/NewCollaborator";
import { IDeleteCollaboratorParams } from "modules/colaboradores/domain/dto/IDeleteCollaboratorParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

export interface ISidesheetCollaboratorProps {
  open: "isNew" | "isEditing" | "isDeleting" | null;
  handleOnCloseSidesheet: (profileId?: string) => void;
  editingItem?: IEmployeesList & { currentProfileId: string };
  deleteItem?: IDeleteCollaboratorParams;
  setExpandedAccordions: React.Dispatch<React.SetStateAction<string>>;
  cargoChecked?: string
}

const SidesheetCollaborator = ({
  open,
  handleOnCloseSidesheet,
  editingItem,
  deleteItem,
  setExpandedAccordions,
  cargoChecked
}: ISidesheetCollaboratorProps) => {
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [valuesNew, setValuesNew] = useState<INewCollaboratorRequest>({ ProfileIds: [] } as INewCollaboratorRequest);
  const [valuesEdit, setValuesEdit] = useState<IEditCollaboratorRequest>({} as IEditCollaboratorRequest);

  const [errorsNew, setErrorsNew] = useState<INewCollaboratorFormErrors>({});
  const [errorsEdit, setErrorsEdit] = useState<IEditCollaboratorFormErrors>({});

  const { postNewCollaborator, putEditCollaborator, deleteCollaborator, postRegisterPortal, getNewCollaborator } = UseColaboradoresPage();
  const { getRegisteredRolesList, registeredRolesListSelect } = UseRolesList();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  useEffect(() => {
    if (currentLocal) {
      getRegisteredRolesList({ ownerId: currentLocal?.id }, 1, 500)
    }
  }, [currentLocal, getRegisteredRolesList]);

  const handleIsNew = useCallback(async () => {
    if (valuesNew.EmployeeEmail) {
      const res = await getNewCollaborator(valuesNew.EmployeeEmail)
      if (res) {
        setSuccess(true);
        setStep(step + 1)
      } else {
        setSuccess(false);
        setStep(step + 1)
      }
    }
  }, [getNewCollaborator, step, valuesNew]);

  const onSearch = (filter: string) => {
    if (currentLocal) {
      if (filter.length > 0) {
        getRegisteredRolesList({ ownerId: currentLocal.id, profileNameKeyword: filter }, 1, 500)
      } else {
        getRegisteredRolesList({ ownerId: currentLocal.id }, 1, 500)
      }
    }
  }

  const handleIsNewStepTwo = useCallback(async () => {
    const res = await postNewCollaborator(valuesNew);
    if (res === "ok") {
      setStep(3)
    }
    if (res === 'included') {
      toast("Usuário já está incluído no cargo.", "error")
    }
  }, [postNewCollaborator, toast, valuesNew]);

  const handleIsNewStepNotSucess = useCallback(async () => {
    const body = {
      email: valuesNew.EmployeeEmail,
      cpf: valuesNew.Cpf,
      roles: valuesNew.ProfileIds,
      nome: valuesNew.Nome
    }
    await postRegisterPortal(body).then(() => {
      setStep(3)
    });

  }, [postRegisterPortal, valuesNew]);


  const handleContinueButton = useCallback(async () => {
    if (open === "isNew" && step === 1 && NewCollaboratorValidation(valuesNew, setErrorsNew, step, success)) {
      return handleIsNew();
    }

    if (open === "isNew" && step === 2 && success && NewCollaboratorValidation(valuesNew, setErrorsNew, step, success)) {
      return handleIsNewStepTwo();
    }

    if (open === "isNew" && step === 2 && !success && NewCollaboratorValidation(valuesNew, setErrorsNew, step, success)) {
      return handleIsNewStepNotSucess();
    }

    if (open === "isEditing" && EditCollaboratorValidation(valuesEdit, setErrorsEdit)) {
      const res = await putEditCollaborator(valuesEdit);
      if (res === "ok") {
        setSuccess(true);
        setStep(2)
      }
    }
    if (open === "isDeleting" && deleteItem) {
      const res = await deleteCollaborator(deleteItem);
      if (res === "ok") {
        setSuccess(true);
        setStep(2)
      }
    }
  }, [
    open,
    step,
    valuesNew,
    success,
    valuesEdit,
    deleteItem,
    handleIsNew,
    handleIsNewStepTwo,
    handleIsNewStepNotSucess,
    putEditCollaborator,
    deleteCollaborator
  ]);

  useEffect(() => {
    if (editingItem) {
      setValuesEdit({ ...valuesEdit, EmployeeId: editingItem.Id });
    }
  }, [editingItem]);


  return (
    <>
      {(open === "isNew" || open === "isEditing") && (
        <Sidesheet
          open={open}
          onClose={() => handleOnCloseSidesheet(editingItem?.currentProfileId ?? "")}
          title={
            <h2>
              {open === "isNew" ? "Novo" : "Editar"} <b>colaborador</b>
            </h2>
          }
          content={
            open === "isNew"
              ? <NewCollaborator
                open={open}
                values={valuesNew}
                setValues={setValuesNew}
                errors={errorsNew}
                setErrors={setErrorsNew}
                allRolesList={registeredRolesListSelect ?? []}
                step={step}
                success={success}
                onSearch={onSearch}
                cargoChecked={cargoChecked}
              />
              : <EditCollaborator
                values={valuesEdit}
                setValues={setValuesEdit}
                errors={errorsEdit}
                setErrors={setErrorsEdit}
                allRolesList={registeredRolesListSelect ?? []}
              />
          }
          currentStep={step}
          totalSteps={open === 'isNew' ? 3 : 2}
          continueButton={step === 1 ? 'Continuar' : 'Concluir'}
          handleContinueButton={handleContinueButton}
          cancelButton
          notTotalHeight
          feedback={
            <SidesheetFeedback
              text="Colaborador adicionado com sucesso!"
              success
            />
          }
        />
      )}
      {open === "isDeleting" && (
        <Sidesheet
          open={open}
          onClose={() => handleOnCloseSidesheet(deleteItem?.profileId ?? "")}
          title={
            <h2>
              Excluir <b>colaborador</b>
            </h2>
          }
          content={<DeleteCollaborator />}
          currentStep={step}
          totalSteps={2}
          continueButton="Excluir"
          handleContinueButton={handleContinueButton}
          cancelButton
          feedback={
            <SidesheetFeedback
              text="Colaborador excluído com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default SidesheetCollaborator;
