import Button from "components/ui/Button/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Close } from "@material-ui/icons";
import React from "react";
import styles from "./Modal.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  toggleModal: () => void;
  onConfirm?: () => void;
  title: string;
  cancelTitle?: string;
  confirmTitle?: string;
  centerTitle?: boolean;
  showButtons?: boolean;
  keepMounted?: boolean;
  doNotClose?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  title,
  open,
  toggleModal,
  onConfirm,
  cancelTitle = "Cancelar",
  confirmTitle = "Confirmar",
  showButtons = true,
  centerTitle,
  children,
  keepMounted = true,
  doNotClose,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      onClose={doNotClose ?? toggleModal}
      classes={{
        paper: styles.paper,
        // root: styles.zindex//TODO: DESCOBRIR O POR QUE DESSE ZINDEX
      }}
      {...props}
    >
      <div id={styles.ModalContainer}>
        <header>
          {centerTitle && <div />}
          <h1>{title}</h1>
          <IconButton onClick={toggleModal}>
            <Close />
          </IconButton>
        </header>
        <DialogContent className={styles.modalContent}>
          {children}
        </DialogContent>
        {showButtons && (
          <DialogActions className={styles.modalActions}>
            <Button
              fullWidth={false}
              onClick={toggleModal}
              color="primary"
              variant="outlined"
            >
              {cancelTitle}
            </Button>
            {onConfirm && (
              <Button fullWidth={false} onClick={onConfirm} color="primary">
                {confirmTitle}
              </Button>
            )}
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};
