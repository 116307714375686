import { IGetWizardParams } from "components/wizard/domain/dto/IGetWizardParams";
import { IGetWizardResponse } from "components/wizard/domain/dto/IGetWizardResponse";
import { IWizardActivityRequest } from "components/wizard/domain/dto/IWizardActivityRequest";
import { IWizardService } from "components/wizard/domain/interfaces/IWizardService";
import { api } from 'services/api/Api';

const baseUrl = process.env.REACT_APP_DYNAMICMENU_URL;

const WizardApi = (): IWizardService => {
  const localCurrent = localStorage.getItem('@localCurrent');
  const ownerData = localCurrent && JSON.parse(localCurrent);

  const getWizard = async (params: IGetWizardParams): Promise<IGetWizardResponse> => {
    const respomse = await api.get<IGetWizardResponse>(`${baseUrl}/PageWizard/${params.menuId}`, {
      params: {
        IsPageWizardCrud: params.IsPageWizardCrud,
        page: params.page,
        pageSize: params.pageSize,
      }
    });
    return respomse.data;
  }

  const postWizardActivity = async (request: IWizardActivityRequest): Promise<any> => {
    const respomse = await api.post<any>(`${baseUrl}/PageWizard/activity/${request.pageWizardId}`, null, {
      headers: {
        'User': ownerData?.id
      }
    });
    return respomse.data;
  }

  return { getWizard, postWizardActivity };
}

export default WizardApi;
