import CPF from "services/utils/CPF";
import { z } from "zod";
import { PartnerType } from "modules/meepErp/models/partner/PartnerType";
import { PartnerOriginType } from "modules/meepErp/models/partner/PartnerOriginType";

export const IndividualPartnerValidatorSchema = z.object({
    // code: z.string(),
    name: z.string().min(3, { message: "O nome deve ter no mínimo 3 caracteres" }),
    document: z.string().refine((value) =>  CPF.isValid(value), { message: "CPF inválido" }),
    type: z.nativeEnum(PartnerType),
    contacts: z.array(
        z.object({
            name: z.string(),
            phone: z.string(),
            email: z.string(),
        })
    ),
    address: z.object({
        street: z.string(),
        number: z.string(),
        neighborhood: z.string(),
        city: z.string(),
        state: z.string(),
        zipCode: z.string(),
    }),
})