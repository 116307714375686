import { Icon, IconButton } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { IRankingItem } from './RankingList';
import styles from './RankingList.module.scss';

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)

export interface IRankingListItemProps<T extends IRankingItem> {
    item: T
    onClick?: (value: T) => void
    colunms: { [key: string]: { title?: string, formatValue?: (value: any) => string, hide?: boolean } }
    formatTitle?: (property: string) => string
    accordeon?: (item: T) => ReactNode
    getColorItem?: (id?: string) => string
    color?: string;
    clickable?: boolean;
}
const RankingListItem = <T extends IRankingItem,>({ item, onClick, colunms, formatTitle, color, accordeon, getColorItem, clickable }: IRankingListItemProps<T>) => {
    const [openAcordion, setOpenAcordion] = useState(false);

    const moneyValues = [
        'averageConsuption',
        'value',
        'total',
        'totalNet',
        'canceledTotalNet',
        'lastCanceledTotalNet',
        'unityPrice',
        'totalCharged',
    ]

    return (
        <div key={item.id} 
            className={`${styles.item} ${clickable ? styles.itemClickable : ''} ${item.isCancelled ? styles.itemCancelled : ''} `} 
            title={`${item.isCancelled ? "Item cancelado" : ""}`}
            onClick={() => onClick?.(item)} 
            style={{ borderColor: color ?? getColorItem?.(item.localId) }}>
            <div className={styles.itemContent}>
                {
                    colunms && Object.keys(colunms).map((key, index) => {
                        if (!colunms[key].hide) {
                            if (key === "imgUrl" || key === 'imageUrl') {
                                return (
                                <span key={key} className={styles.img}>
                                    <div className={styles.image}>
                                        {item[key] && 
                                        <img 
                                            src={item[key]} 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src="/assets/icon/image_placeholder.svg";
                                            }} 
                                            alt=""
                                        />}
                                        </div>
                                    </span>)
                            }
                           
                            if (key === "name" || key === "consumer") {
                                return (<span key={key} className={styles.name}>{colunms[key].formatValue ? colunms[key].formatValue?.(item[key]) : item[key]}</span>)
                            }
                            if (moneyValues.filter((item) => item === key).length > 0) {
                                return (<span key={key}>{formatCurrency(item?.[key] ?? 0)}</span>)
                            }
                            if(key === "cancelOperatorName"){
                                return (<span key={key}>{item?.[key]} <br /> {new Date(item?.['cancelDate']).toLocaleString()}</span>)
                            }
                            if(key === "saleOperatorName"){
                                return (<span key={key}>{item?.[key]} <br /> {new Date(item?.['saleDate']).toLocaleString()}</span>)
                            }
                            if (item.hasOwnProperty(key) && key !== `id`) {
                                return (<span key={key} className={key === 'quantity' ? styles.quantity : ''}>{colunms[key].formatValue ? colunms[key].formatValue?.(item[key]) : item[key]}</span>)
                            }
                        }
                        return null
                    })
                }
                {
                    accordeon && <div className={styles.accordeonButton}>
                        <IconButton onClick={() => setOpenAcordion(prev => !prev)}><Icon>expand_more</Icon></IconButton>
                    </div>
                }
            </div>
            {accordeon && openAcordion &&
                // <div className={styles.itemAccordeon}>
                accordeon?.(item)
                // </div>
            }
        </div>
    );
};
export default RankingListItem;
