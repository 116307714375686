import { useUi } from "contexts/userInterface/UserInterfaceContext";
import SetDeviceProfileUseCase from "modules/equipamento/application/useCases/setDeviceProfile/SetDeviceProfileUseCase";
import EditMassiveDeviceProfileUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/EditMassiveDeviceProfileUseCase";
import GetProfileListUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/GetProfileListUseCase";
import { IDeviceProfile } from "modules/equipamento/domain/dto/getDeviceById/IGetDeviceByIdResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DeviceApi from "services/api/equipamento/DeviceApi";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import { IMassiveDeviceProfileValue } from "../components/deviceProfileForm/IMassiveDeviceProfileValue";

const UseDeviceProfile = () => {
  const [deviceProfileList, setDeviceProfileList] =
    useState<IDeviceProfile[]>();
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const setDeviceProfile = useCallback(
    async (profileId: string, deviceId: string) => {
      const deviceService = DeviceApi();
      try {
        showLoading();
        await SetDeviceProfileUseCase(deviceService, profileId, deviceId); //TODO:Rever api
        // setDeviceProfileList(response);
      } finally {
        hideLoading();
      }
    },
    [hideLoading, showLoading]
  );

  const getDeviceProfileList = useCallback(
    async (localId: string) => {
      const profileService = DeviceProfileApi();
      try {
        showLoading();
        if (currentLocal) {
          const response = await GetProfileListUseCase(
            profileService,
            currentLocal.id,
            {}
          ); //TODO:Rever api
          setDeviceProfileList(response);
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [showLoading, currentLocal, hideLoading]
  );

  const getDefaultDeviceProfile = useCallback(
    async (deviceId: string) => {
      try {
        showLoading();
        throw "Não implementado";
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading]
  );

  const onChangeDeviceProfile = useCallback(
    async (profileId: string, devices: IMassiveDeviceProfileValue[]) => {
      try {
        if (currentLocal) {
          showLoading();
          const profileService = DeviceProfileApi();
          await EditMassiveDeviceProfileUseCase(
            profileId,
            currentLocal.id,
            profileService,
            devices
          );
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, hideLoading, showLoading]
  );

  return {
    deviceProfileList,
    getDeviceProfileList,
    getDefaultDeviceProfile,
    setDeviceProfile,
    onChangeDeviceProfile,
  };
};

export default UseDeviceProfile;
