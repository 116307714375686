import { AxiosInstance, AxiosResponse } from "axios";

export interface IPagination {
  skip: number;
  take: number;
}

export interface IResponse<T = void> {
  status: number;
  data: T;
}

export interface IList<T = any> {
  items: T[]
}

export interface IPaginatedList<T = any> {
  records: T[];
  totalRecords: number;
}

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type IApi = AxiosInstance
