import { ISort } from 'interfaces/ISort';
import { IAddCouponRequest } from 'modules/config/discountCoupons/domain/dto/IAddCouponRequest';
import { IFilterDeviceRequest } from 'modules/config/discountCoupons/domain/dto/IFilterDeviceRequest';
import { IGetDeviceListResponse } from 'modules/config/discountCoupons/domain/dto/IGetDeviceListResponse';
import { IGetDiscountCouponFilterRequest } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponFilterRequest';
import { IGetDiscountCouponsResponse } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse';
import { IDiscountCouponsService } from 'modules/config/discountCoupons/domain/interfaces/IDiscountCouponsService'
import { mock } from 'modules/config/discountCoupons/presentation/DiscountCouponsMock';
import { api } from '../Api';
import { deviceConfigApiUrl } from "Enviroment";
import axios from 'axios';
import { IGetRecoverDiscountCouponResponse } from 'modules/config/discountCoupons/domain/dto/IGetRecoverDiscountCouponResponse';
import { IEditCouponRequest } from 'modules/config/discountCoupons/domain/dto/IEditCouponRequest ';
import { IAddCouponResponse } from 'modules/config/discountCoupons/domain/dto/IAddCouponResponse';
import { IGetDiscountCouponsReportResponse } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsReportResponse';
import { IGetDiscountCouponsReportFilterRequest } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsReportFilterRequest';
import { IGetSessionsListResponse } from 'modules/config/discountCoupons/domain/dto/IGetSessionsListResponse';
import { getBuscarSessoes } from './antiCorruption/BuscarSessoes';
import { IGetReportOrderResponse } from 'modules/config/discountCoupons/domain/dto/IGetReportOrderResponse';

const baseURL = deviceConfigApiUrl;

export const deviceConfigApi = axios.create({
    baseURL,
});

const DiscountCouponsApi = (): IDiscountCouponsService => {

    const getDiscountCoupons = async (request: IGetDiscountCouponFilterRequest, sort?: ISort): Promise<IGetDiscountCouponsResponse> => {
        const params = {
            ...request,
            "sort.Orientation": sort?.orientation,
            "sort.Type": sort?.type,
        }

        const response = await api.get<IGetDiscountCouponsResponse>(`Coupon`, { params: params });
        return response.data;
        // const response = await mock;
        // return response;
    };
    const getDiscountCouponsReport = async (request: IGetDiscountCouponsReportFilterRequest, sort?: ISort): Promise<IGetDiscountCouponsReportResponse> => {
        const params = {
            ...request,
            "sort.Orientation": sort?.orientation,
            "sort.Type": sort?.type,
        }

        const response = await api.get<IGetDiscountCouponsReportResponse>(`Relatorio/GetCouponsReport`, { params: params });
        return response.data;
        // const response = await mock;
        // return response;
    };

    const postAddCoupon = async (request: IAddCouponRequest): Promise<IAddCouponResponse> => {
        const response = await api.post<IAddCouponResponse>(`Coupon`, request);
        return response.data
    }

    const putEditCoupon = async (request: IEditCouponRequest): Promise<void> => {
        const response = await api.put(`Coupon`, request);
        return response.data
    }
    const deleteDiscountCoupon = async (couponId: string, localId: string): Promise<void> => {
        const params = {
            ownerId: localId,
            couponId: couponId
        }
        const response = await api.delete(`Coupon`, { data: params });
        return response.data
    }

    const getDeviceList = async (localId: string, deviceFilter?: number[]): Promise<IGetDeviceListResponse> => {
        const response = await deviceConfigApi.get<IGetDeviceListResponse>("/locals/" + localId + "/devices", {
            params: {
                types: deviceFilter
            },
            paramsSerializer: params => Object.entries(params).map(([key, value], i) => Array.isArray(value) ? `${key}=${value.join('&' + key + '=')}` : `${key}=${value}`).join('&')

        },);

        return response.data
    }

    const getDiscountCouponItem = async (localId: string, couponId?: string): Promise<IGetRecoverDiscountCouponResponse> => {
        const params = {
            ownerId: localId
        }
        const response = await api.get<IGetRecoverDiscountCouponResponse>(`Coupon/${couponId}`, { params: params });
        return response.data;
    }
    const getReportOrder = async (localId: string, orderId: string, pagination?: { page?: number, pageSize?: number }): Promise<IGetReportOrderResponse> => {
        const params = {
            ownerId: localId,
            orderId: orderId,
            pageSize: pagination?.pageSize,
            page: pagination?.page,
        }
        const response = await api.get<IGetReportOrderResponse>(`Relatorio/GetCouponOrderReport/`, { params: params });
        return response.data;
    }

    const getSessions = async (localId: string): Promise<IGetSessionsListResponse> => {
        const response = await getBuscarSessoes(api, localId);
        return response;
    };


    return {
        getDiscountCoupons,
        postAddCoupon,
        getDeviceList,
        getDiscountCouponItem,
        putEditCoupon,
        deleteDiscountCoupon,
        getDiscountCouponsReport,
        getSessions,
        getReportOrder
    }

}
export default DiscountCouponsApi