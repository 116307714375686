
export interface IDeviceNetwork {
    type: IDeviceNetworkType,
    name: string,
    signal: number,
}
export interface IDeviceItem {
    id: string,
    name: string,
    identifier: string,
    profile?: IDeviceProfileItem,
    type: IDeviceType,
    status?: {
        battery: number,
        network: IDeviceNetwork,
    }
}
export interface IDeviceProfileItem {
    id: string,
    name: string,
    isDefault: boolean,
}

export interface IDeviceType {
    value: EnumDeviceType;
    name: string;
}

export interface IDeviceNetworkType {
    value: EnumDeviceType;
    name: string;
}

export enum EnumDeviceType {
    APP = 1,
    TOTEM = 2,
    POS = 3,
    POSPLUS = 4,
    WEBAPP = 11,
}

export const deviteTypeList = [
    {
        id: EnumDeviceType.APP,
        name: 'APP',
    },
    {
        id: EnumDeviceType.TOTEM,
        name: 'Totem',
    },
    {
        id: EnumDeviceType.POS,
        name: 'POS',
    },
    {
        id: EnumDeviceType.WEBAPP,
        name: 'Webapp',
    },
    {
        id: EnumDeviceType.POSPLUS,
        name: 'POS+',
    },
]