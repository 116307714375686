import { IEditEventListRequest, IEventsListRequest, IGuestRequest, IMigrateGuestsRequest, IUpdateGuestListLinkRequest } from "modules/events/domain/dto/IEventsRequest";
import { IEventPeopleListResponse, IEventsGuestListResponse, IGuestListToMigrationResponse, IMigratedGuestsResponse, IPeopleListResponse } from "modules/events/domain/dto/IEventsResponse";
import { api } from "../../Api";
import { IEventsGuestsApi } from "../../../../modules/events/application/interfaces/IGuestsService";
import { IEventGuestFilterRequest } from "modules/events/domain/dto/IEventGuestFilterRequest";

export const EventsGuestsApi = (): IEventsGuestsApi => {

  const getPeopleOnList = async (guestListId: string, localId: string): Promise<IPeopleListResponse> => {
    const result = await api.get(`/SuperApp/Event/FindPeopleOnPeopleList?peopleListId=${guestListId}&localId=${localId}`);
    return result.data;
  };

  const getPeopleOnEvent = async (eventId: string, localId: string, filter?: IEventGuestFilterRequest, pagination?: { page?: number, pageSize?: number }): Promise<IEventPeopleListResponse> => {
    const params = {
      cpf: filter?.cpf,
      name: filter?.name,
      listId: filter?.listNameId,
      promoter: filter?.promoterId,
      pageSize: pagination?.pageSize,
      page: pagination?.page,
    }
    const result = await api.get(`/SuperApp/Event/FindPeopleOnEvent?eventId=${eventId}&localId=${localId}`, { params: params });
    return result.data;
  };

  const createGuestList = async (list: IEventsListRequest, localId: string): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/AddPeopleListToEvent?localId=${localId}`, { ...list });
    return result.data;
  };

  const addGuestsToList = async (guestList: IGuestRequest, localId: string): Promise<IGuestListToMigrationResponse> => {
    const config = { params: { disableError: true } };
    const result = await api.post(`/SuperApp/Event/AddPersonToPeopleList?localId=${localId}`, { ...guestList }, config);
    return result.data;
  };

  const migrateGuestList = async (guestList: IMigrateGuestsRequest, listId: string, localId: string): Promise<IMigratedGuestsResponse> => {
    const result = await api.post(`SuperApp/Event/PeopleList/${listId}/MigrateTo?localId=${localId}`, { ...guestList });
    return result.data;
  };

  const getGuestLists = async (eventId: string, localId: string): Promise<IEventsGuestListResponse[]> => {
    const result = await api.get(`/SuperApp/Event/FindEventPeopleLists?eventId=${eventId}&localId=${localId}`);
    return result.data;
  };

  const editGuestList = async (eventListId: string, eventListDetails: IEditEventListRequest, localId: string): Promise<void> => {
    const body = {
      name: eventListDetails.name,
      description: eventListDetails.description,
      closingDateTime: eventListDetails.closingDateTime,
      isActive: eventListDetails.isActive,
      mainPromoterId: eventListDetails.mainPromoterId,
      discountLimit: eventListDetails.discountLimit,
      baseListId: eventListDetails.baseListId,
      copyConfiguration: eventListDetails.copyConfiguration,
      copyGuests: eventListDetails.copyGuests,
      validUntil: eventListDetails.validUntil ?? '',
      shareEnabled: eventListDetails.shareEnabled,
      shareDescription: eventListDetails.shareDescription,
    };
    await api.put(`/SuperApp/Event/UpdatePeopleList/${eventListId}?localId=${localId}`, body);
  };

  const deleteGuestFromList = async (itemId: string, localId: string): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeletePersonPeopleList/${itemId}?localId=${localId}`);
  };

  const deleteGuestList = async (listId: string, localId: string): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeletePeopleList/${listId}?localId=${localId}`);
  };

  const doPreCheckin = async (guestId: string, localId: string): Promise<void> => {
    await api.post(`/SuperApp/Event/PeopleList/Persons/${guestId}/SetPreCheckinStatus/Waiting?localId=${localId}`);
  };

  const doStraightCheckin = async (guestId: string, localId: string): Promise<void> => {
    const payload = {
      personId: guestId,
      ownerId: localId
    }
    await api.post(`/SuperApp/Event/PeopleList/Persons/Checkin`, payload);
  };

  const updateGuestListLink = async (request: IUpdateGuestListLinkRequest, localId: string): Promise<void> => {
    await api.put(`/SuperApp/Event/UpdatePeopleListLink?localId=${localId}`, request, { params: { disableError: true } });
  };

  const deleteGuestListLink = async (listId: string, localId: string): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeletePeopleListLink/${listId}?localId=${localId}`);
  };

  return {
    getPeopleOnList,
    createGuestList,
    getGuestLists,
    addGuestsToList,
    getPeopleOnEvent,
    editGuestList,
    deleteGuestFromList,
    migrateGuestList,
    doPreCheckin,
    doStraightCheckin,
    deleteGuestList,
    updateGuestListLink,
    deleteGuestListLink
  };
};
