import React, { useCallback, useState } from 'react'
import { IAttributeOptionEnum, ICustomField } from 'modules/schedule/domain/models/ICustomField'


export const UseCustomFieldPage = () => {
    const [values, setValues] = useState<ICustomField[]>(
        [
            {
                title: '',
                type: null,
                multiple: false,
            },
        ]
    )


    const addQuantity = useCallback(() => {
        const newCustomer: ICustomField = {
            title: '',
            type: null,
            multiple: false,
        };
        setValues((prev) => [...prev, newCustomer]);
    }, []);

    const onSubmit = () => {
        
    }

    return (
        {
            values,
            onSubmit,
            addQuantity,
            setValues      
        }
    )
}
