import { FC, useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import { Autocomplete, TextField } from "@mui/material";
import { Button, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import {
  CashiersTypesCollection,
  UseAdvanceFilterList,
  UseListCashiers,
} from "services/api/outputreport";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdvancedFilterItemTypes } from "services/api/outputreport/types/GetAdvancedFilterLists.types";
import { FormatCashierDate } from "../../utils";
import { RadioOption } from "components/radioOption/radioOption";
import { CashierSideSheet } from "../cashiersSideSheet/cashiersSideSheet";

export type FilterFieldsTypes = {
  cashierIds: CashiersTypesCollection;
  startDate?: Date;
  listType?: string;
  endDate?: Date;
  productId: AdvancedFilterItemTypes[];
  categories: AdvancedFilterItemTypes[];
  operators: AdvancedFilterItemTypes[];
  equipaments: AdvancedFilterItemTypes[];
  systems: AdvancedFilterItemTypes[];
};

const filterTypes = [
  {
    value: "cashier",
    description: "Caixa",
  },
  {
    value: "period",
    description: "Período",
  },
];

const getSelectedCountLabel = (count: number, fieldName: string) => {
  return `${count} ${fieldName}${count > 1 ? "s" : ""} selecionado${
    count > 1 ? "s" : ""
  }`;
};

type FilterProps = {
  fetchData: (data: any) => any;
  setListType?: (type?: string) => any;
  setOpenExportSideSheet: (open: boolean) => any;
  openExportSideSheet: boolean;
};

const Filter: FC<FilterProps> = ({
  fetchData,
  setListType,
  setOpenExportSideSheet,
  openExportSideSheet,
}) => {
  const { toast } = useUi();

  const { currentLocal } = useLocal();

  const [filterType, setFilterType] = useState("cashier");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [endMaxDate, setEndMaxDate] = useState<Date>(new Date());
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [openCashierSideSheet, setOpenCashierSideSheet] = useState(false);

  const { register, handleSubmit, watch, setValue } =
    useForm<FilterFieldsTypes>();

  const {
    cashierIds,
    startDate,
    endDate,
    productId,
    categories,
    operators,
    equipaments,
    systems,
    listType,
  } = watch();

  useEffect(() => {
    setListType?.(listType);
  }, [listType, setListType]);

  const useListProps =
    filterType === "period"
      ? {
          localId: currentLocal?.id as string,
          startDate,
          endDate,
        }
      : {
          localId: currentLocal?.id as string,
        };

  const { data: cashiers } = UseListCashiers(useListProps);

  const { data: advancedFilters, isFetching } = UseAdvanceFilterList({
    ...useListProps,
    cashierIds: cashierIds?.map((item) => item.Id),
  });

  const submitFilter = (data: FilterFieldsTypes) => {
    const {
      cashierIds: selectedCashiers,
      categories,
      productId,
      operators,
      systems,
      equipaments,
      ...rest
    } = data;

    const cashierIds = selectedCashiers
      ? selectedCashiers.map((item) => item.Id)
      : [];
    const categoryIds = categories ? categories.map((item) => item.Id) : [];
    const productIds = productId ? productId.map((item) => item.Id) : [];
    const operatorIds = operators ? operators.map((item) => item.Id) : [];
    const equipamentIds = equipaments ? equipaments.map((item) => item.Id) : [];
    const systemIds = systems ? systems.map((item) => item.Id) : [];

    if (filterType !== "period") {
      rest.startDate = undefined;
      rest.endDate = undefined;
    } else {
      if (rest.startDate) {
        const startDate = rest.startDate;

        startDate.setHours(startDate.getHours() + 3);

        rest.startDate = startDate;
      }

      if (rest.endDate) {
        const endDate = rest.endDate;

        endDate.setHours(endDate.getHours() + 3);

        rest.endDate = endDate;
      }
    }

    fetchData({
      cashierIds,
      categoryIds,
      productsIds: productIds,
      operatorIds,
      deviceIds: equipamentIds,
      SystemTypeIds: systemIds,
      ...rest,
    });
  };

  useEffect(() => {
    setValue("startDate", new Date());
    const date = new Date();
    date.setDate(date.getDate() + 31);
    setValue("endDate", new Date());
    setValue("cashierIds", []);
    setValue("listType", "product");
  }, [setValue]);

  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + 31);
      setEndMaxDate(date);
    }
  }, [startDate]);

  return (
    <div id={styles.Filter}>
      <form onSubmit={handleSubmit(submitFilter)}>
        <div className={styles.simple}>
          <div className={styles.filterItem}>
            <Autocomplete
              className={styles.input}
              style={{ flex: 2, minWidth: 350 }}
              size="small"
              disableClearable
              renderInput={(props) => (
                <div className={styles.inputContainer}>
                  <span>Filtrar por</span>
                  <TextField
                    maxRows={1}
                    label={undefined}
                    name="filterType"
                    {...props}
                  />
                </div>
              )}
              isOptionEqualToValue={(option, values) =>
                option.value === values.value
              }
              options={filterTypes}
              getOptionLabel={(option) => option.description}
              onChange={(ev, value) => setFilterType(value.value)}
              value={filterTypes.find((x) => x.value === filterType)}
            />
          </div>
          {filterType === "cashier" && (
            <div className={`${styles.filterItem} ${styles.cashier}`}>
              <Autocomplete
                className={styles.input}
                style={{ flex: 2, minWidth: 350 }}
                size="small"
                multiple={true}
                renderTags={() =>
                  cashierIds.length > 0
                    ? getSelectedCountLabel(cashierIds.length, "caixa")
                    : ""
                }
                renderInput={(props) => (
                  <div className={styles.inputContainer}>
                    <span>Selecione os caixas (máximo 30):</span>
                    <TextField
                      maxRows={1}
                      onBlur={() => setShowPlaceholder(true)}
                      onChange={() => setShowPlaceholder(false)}
                      {...props}
                    />
                  </div>
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option?.Id}>
                      {FormatCashierDate({
                        startDate: option?.StartDate,
                        endDate: option?.EndDate,
                      })}
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, values) =>
                  option.Id === values.Id
                }
                {...register("cashierIds")}
                options={cashiers ? cashiers : []}
                getOptionLabel={(option) => option.StartDate}
                onChange={(ev, value) => {
                  setShowPlaceholder(true);

                  if (cashierIds.length === 30) {
                    toast("Selecione no máximo 30 caixas", "error");
                  } else {
                    setValue("cashierIds", value);
                  }
                }}
                value={cashierIds || []}
              />
            </div>
          )}

          {filterType === "period" && (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                value={startDate}
                disableFuture
                {...register("startDate")}
                minDate={new Date("2017-01-01")}
                onChange={(newValue) => {
                  if (newValue) setValue("startDate", newValue);
                }}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <span>Período - ínicio</span>

                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />

              <DesktopDatePicker
                value={endDate}
                {...register("endDate")}
                minDate={startDate}
                maxDate={endMaxDate}
                onChange={(newValue) => {
                  if (newValue) setValue("endDate", newValue);
                }}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <span>Período - fim (Máximo 31 dias)</span>

                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
            </LocalizationProvider>
          )}
          {/* Alterar o false fixo quando tivermos o endpoint da listagem */}
          {!showAdvanced && (
            <Button
              className={styles.button}
              variant={"contained"}
              color={"primary"}
              disabled={false}
              type={"submit"}
            >
              {false ? <CircularProgress /> : "Buscar"}
            </Button>
          )}
        </div>
        <div className={styles.controls}>
          {filterType === "period" &&
            cashiers?.length !== undefined &&
            cashiers?.length > 0 && (
              <button
                type="button"
                onClick={() => setOpenCashierSideSheet(true)}
                style={{ flex: 1 }}
                // disabled={!data?.records.length}
              >
                Caixas do período filtrado
                <img
                  className={styles.export}
                  src="/assets/icon/arrow-right.png"
                  alt=""
                />
              </button>
            )}
          <button
            type="button"
            onClick={() => setOpenExportSideSheet(!openExportSideSheet)}
            // disabled={!data?.records.length}
          >
            Exportar
            <img
              className={styles.export}
              src="/assets/icon/export.svg"
              alt=""
            />
          </button>
          <button
            type="button"
            onClick={() => setShowAdvanced((prev) => !prev)}
          >
            Filtros avançados
            {showAdvanced ? (
              <img
                className={styles.closed}
                src="/assets/icon/advanced-filter-closed.svg"
                alt=""
              />
            ) : (
              <img
                className={styles.opened}
                src="/assets/icon/advanced-filter.svg"
                alt=""
              />
            )}
          </button>
        </div>
        {showAdvanced && (
          <>
            {isFetching ? (
              <div className={styles.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles.advanced}>
                <div className={styles.filterItem}>
                  <Autocomplete
                    className={styles.input}
                    style={{ flex: 2, minWidth: 350 }}
                    size="small"
                    multiple={true}
                    renderInput={(props) => (
                      <div className={styles.inputContainer}>
                        <span>Produto:</span>
                        <TextField
                          maxRows={1}
                          onBlur={() => setShowPlaceholder(true)}
                          onChange={() => setShowPlaceholder(false)}
                          {...props}
                        />
                      </div>
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.Id}>
                          {option.Name}
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option, values) =>
                      option.Id === values.Id
                    }
                    {...register("productId")}
                    options={
                      advancedFilters?.Products ? advancedFilters?.Products : []
                    }
                    getOptionLabel={(option) => option.Name}
                    onChange={(ev, value) => {
                      setShowPlaceholder(true);

                      if (cashierIds.length === 30) {
                        toast("Selecione no máximo 30 caixas", "error");
                      } else {
                        setValue("productId", value);
                      }
                    }}
                    value={productId || []}
                  />
                </div>

                <div className={styles.filterItem}>
                  <Autocomplete
                    className={styles.input}
                    style={{ flex: 2, minWidth: 350 }}
                    size="small"
                    multiple={true}
                    renderInput={(props) => (
                      <div className={styles.inputContainer}>
                        <span>Categoria</span>
                        <TextField
                          maxRows={1}
                          placeholder={"Selecione a categoria"}
                          name={"categories"}
                          {...props}
                        />
                      </div>
                    )}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} key={option.Id}>
                          {option.Name}
                        </span>
                      );
                    }}
                    isOptionEqualToValue={(option, values) =>
                      option.Id === values.Id
                    }
                    options={advancedFilters?.Categories || []}
                    getOptionLabel={(option) => option.Name}
                    onChange={(ev, value: any) => setValue("categories", value)}
                    value={categories || []}
                  />
                </div>
                <div className={styles.filterItem}>
                  <Autocomplete
                    className={styles.input}
                    style={{ flex: 2, minWidth: 350 }}
                    size="small"
                    multiple={true}
                    renderInput={(props) => (
                      <div className={styles.inputContainer}>
                        <span>Operador</span>
                        <TextField
                          maxRows={1}
                          placeholder={"Selecione o operador"}
                          name={"operators"}
                          {...props}
                        />
                      </div>
                    )}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} key={option.Id}>
                          {option.Name}
                        </span>
                      );
                    }}
                    isOptionEqualToValue={(option, values) =>
                      option.Id === values.Id
                    }
                    options={advancedFilters?.Operators || []}
                    getOptionLabel={(option) => option.Name}
                    onChange={(ev, value) => setValue("operators", value)}
                    value={operators || []}
                  />
                </div>
                <div className={styles.filterItem}>
                  <Autocomplete
                    className={styles.input}
                    style={{ flex: 2, minWidth: 350 }}
                    size="small"
                    multiple={true}
                    renderInput={(props) => (
                      <div className={styles.inputContainer}>
                        <span>Dispositivo</span>
                        <TextField
                          maxRows={1}
                          placeholder={"Selecione o dispositivo"}
                          name={"equipaments"}
                          {...props}
                        />
                      </div>
                    )}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} key={option.Id}>
                          {option.Name}
                        </span>
                      );
                    }}
                    isOptionEqualToValue={(option, values) =>
                      option.Id === values.Id
                    }
                    options={advancedFilters?.Equipaments || []}
                    getOptionLabel={(option) => option.Name}
                    onChange={(ev, value) => setValue("equipaments", value)}
                    value={equipaments || []}
                  />
                </div>
                <div className={styles.filterItem}>
                  <Autocomplete
                    className={styles.input}
                    style={{ flex: 2, minWidth: 350 }}
                    size="small"
                    multiple={true}
                    renderInput={(props) => (
                      <div className={styles.inputContainer}>
                        <span>Tipo de sistema</span>
                        <TextField
                          maxRows={1}
                          placeholder={"Selecione o tipo de sistema"}
                          name={"systems"}
                          {...props}
                        />
                      </div>
                    )}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} key={option.Id}>
                          {option.Name}
                        </span>
                      );
                    }}
                    isOptionEqualToValue={(option, values) =>
                      option.Id === values.Id
                    }
                    options={advancedFilters?.Systems || []}
                    getOptionLabel={(option) => option.Name}
                    onChange={(ev, value) => setValue("systems", value)}
                    value={systems || []}
                  />
                </div>

                <div
                  className={`${styles.filterItem} ${styles.smallColumn} ${styles.labelLess}`}
                >
                  <Button
                    className={styles.button}
                    variant={"contained"}
                    color={"primary"}
                    disabled={false}
                    type={"submit"}
                  >
                    Buscar
                    {false && <CircularProgress />}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <h5>Selecione o tipo</h5>
        <div className={styles.switchRow}>
          <RadioOption
            label="Produto"
            InputProps={{ ...register("listType"), value: "product" }}
          />
          <RadioOption
            label="Forma de pagamento"
            InputProps={{ ...register("listType"), value: "payment" }}
          />
        </div>
      </form>
      <CashierSideSheet
        open={openCashierSideSheet}
        onClose={() => setOpenCashierSideSheet(false)}
        cashiers={cashiers}
      />
    </div>
  );
};

export { Filter };
