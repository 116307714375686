import Sidesheet from "components/sidesheet/Sidesheet";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseMenuConfigPage } from "../../hooks/UseMenuConfigPage";
import { IGetSystemConfigByIdResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigByIdResponse";
import MenuConfig from "./menuConfig/MenuConfig";
import MenuPai from "./menuPai/MenuPai";
import { IMenuPaiFormErrors, MenuPaiValidation } from "./menuPai/MenuPaiValidation";
import MenuFilho from "./menuFilho/MenuFilho";
import MenuNeto from "./menuNeto/MenuNeto";
import { IPostCreateMenuPayload } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";
import { IGetMenuListResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuListResponse";
import { IMenuOrder } from "modules/admin/menuConfig/domain/dto/IPutMenuOrder";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface IAddMenuSidesheetProps {
    openMenuSidesheet: boolean;
    setOpenMenuSidesheet: React.Dispatch<React.SetStateAction<boolean>>;
    systemConfig: IGetSystemConfigByIdResponse;
    getSystemConfigList: () => Promise<any>;
}

export interface IGetMenuList {
    positionType: number;
    menus: IGetMenuListResponse[];
}

const AddMenuSidesheet = ({ openMenuSidesheet, setOpenMenuSidesheet, systemConfig, getSystemConfigList }: IAddMenuSidesheetProps) => {
    const [addMenuSidesheetStep, setAddMenuSidesheetStep] = useState<"menuConfig" | "menuPai" | "menuFilho" | "menuNeto" | "deleteMenu">("menuConfig");
    const [currentStep, setCurrentStep] = useState(1);

    const [menuFormValues, setMenuFormValues] = useState<IPostCreateMenuPayload>({} as IPostCreateMenuPayload);
    const [menuPaiErrors, setMenuPaiErrors] = useState<IMenuPaiFormErrors>({});
    const [menuFilhoErrors, setMenuFilhoErrors] = useState<string>();
    const [menuNetoErrors, setMenuNetoErrors] = useState<string>();
    const [editMenuFormValues, setEditMenuFormValues] = useState<IPutEditMenuPayload>({} as IPutEditMenuPayload);
    const [menuOrder, setMenuOrder] = useState<IMenuOrder[]>([]);
    const [deleteMenuId, setDeleteMenuId] = useState("");
    
    const { plans, getPlans } = usePlans();

    const [defaultPlans, setDefaultPlans] = useState<IPlans[]>([]);

    const { toast } = useUi();
    const {
        getMenulist,
        menuList: menuListData,
        putMenuOrder,
        deleteMenu,
        isLoading,
        putEditMenu,
        postCreateMenu,
        getUpdatePermission
    } = UseMenuConfigPage();
    
    useEffect(() => {
        getMenulist(systemConfig.id);
        getPlans();
    }, [getMenulist, getPlans, systemConfig.id]);

    useEffect(() => {
        setMenuFormValues({...menuFormValues, menuConfigurationId: systemConfig.id });
    }, [getMenulist, getPlans, systemConfig.id]);

    const handleMenuList = useCallback((menuList: IGetMenuListResponse[][]) => {
        let formatedMenuList: IGetMenuList[] = [];

        if (menuList.length) {
            for (let menu of menuList) {
                formatedMenuList.push({
                    positionType: menu[0].positionType,
                    menus: menu,
                });
            }
        }

        return formatedMenuList;
    }, []);

    const menuList = useMemo(() => handleMenuList(menuListData ?? []), [menuListData, handleMenuList]);

    useEffect(() => {
        if (!!systemConfig.plans && systemConfig.plans.length && plans) {
            const findPlansById = systemConfig.plans.map((item) => {
                const findPlan = plans.find((plan) => plan.id === item.planId);
                if (findPlan) return findPlan;
                return undefined;
            });

            const plansById: IPlans[] = findPlansById.filter((item): item is IPlans => item !== undefined);
            setDefaultPlans(plansById);
        }
    }, [systemConfig, plans]);

    const onCloseAddSystemSidesheet = useCallback(() => {
        setOpenMenuSidesheet(false);
        getSystemConfigList();
    }, [getSystemConfigList, setOpenMenuSidesheet]);

    const handleOpenDeleteMenuSidesheet = useCallback(async (menuId: string) => {
        setAddMenuSidesheetStep("deleteMenu");
        setDeleteMenuId(menuId);
    }, []);

    const handleContinueDeleteMenu = useCallback(async () => {
        const res = await deleteMenu(deleteMenuId);
        if (res === "ok") {
            setCurrentStep(currentStep + 1);
            getUpdatePermission();
        }
    }, [deleteMenu, deleteMenuId, currentStep, getUpdatePermission]);

    const handleTitleByStep = useCallback(() => {
        if (addMenuSidesheetStep === "menuPai") {
            return (<h2>Novo <b>menu pai</b></h2>);
        }
        if (addMenuSidesheetStep === "menuFilho") {
            return (<h2>Novo <b>menu filho</b></h2>);
        }
        if (addMenuSidesheetStep === "menuNeto") {
            return (<h2>Novo <b>menu neto</b></h2>);
        }
        if (addMenuSidesheetStep === "deleteMenu") {
            return (<h2>Excluir <b>menu</b></h2>);
        }
        return (<h2>Configuração <b>menu</b></h2>);
    }, [addMenuSidesheetStep]);

    const handleContentByStep = useCallback(() => {
        if (addMenuSidesheetStep === "menuPai") {
            return (
                <MenuPai
                    menuPaiValues={menuFormValues}
                    setMenuPaiValues={setMenuFormValues}
                    errors={menuPaiErrors}
                    setErrors={setMenuPaiErrors}
                    menuPaiEditItem={editMenuFormValues}
                    defaultPlans={defaultPlans}
                    menuList={menuList}
                />
            );
        }
        if (addMenuSidesheetStep === "menuFilho") {
            return (
                <MenuFilho
                    menuFilhoValues={menuFormValues}
                    setMenuFilhoValues={setMenuFormValues}
                    errors={menuFilhoErrors}
                    setErrors={setMenuFilhoErrors}
                    menuFilhoEditItem={editMenuFormValues}
                    defaultPlans={defaultPlans}
                />
            );
        }
        if (addMenuSidesheetStep === "menuNeto") {
            return (
                <MenuNeto
                    menuNetoValues={menuFormValues}
                    setMenuNetoValues={setMenuFormValues}
                    errors={menuNetoErrors}
                    setErrors={setMenuNetoErrors}
                    menuNetoEditItem={editMenuFormValues}
                    defaultPlans={defaultPlans}
                    menuList={menuList}
                />
            );
        }
        if (addMenuSidesheetStep === "deleteMenu") {
            return (<SidesheetFeedback text="Essa ação não poderá ser desfeita!" />);
        }
        return (
            <MenuConfig
                setAddMenuSidesheetStep={setAddMenuSidesheetStep}
                systemConfig={systemConfig}
                defaultMenuList={menuList}
                setMenuOrder={setMenuOrder}
                handleOpenDeleteMenuSidesheet={handleOpenDeleteMenuSidesheet}
                setEditMenuFormValues={setEditMenuFormValues}
            />
        );
    }, [
        addMenuSidesheetStep,
        systemConfig,
        menuList,
        menuFormValues,
        menuPaiErrors,
        editMenuFormValues,
        defaultPlans,
        menuFilhoErrors,
        menuNetoErrors,
        handleOpenDeleteMenuSidesheet
    ]);

    const handleContinueButtonText = useCallback(() => {
        if (addMenuSidesheetStep === "menuConfig" || editMenuFormValues.title) {
            return "Atualizar";
        }
        if (addMenuSidesheetStep === "deleteMenu") {
            return "Excluir";
        }
        return "Criar";
    }, [addMenuSidesheetStep, editMenuFormValues.title]);

    const handleMenuConfig = useCallback(async () => {
        if (addMenuSidesheetStep === "deleteMenu") {
            return handleContinueDeleteMenu();
        }
        if (addMenuSidesheetStep === "menuPai" && !MenuPaiValidation(menuFormValues, setMenuPaiErrors)) return;

        if (addMenuSidesheetStep === "menuFilho" && !menuFormValues.title) {
            return setMenuFilhoErrors("Campo obrigatório");
        }
        if (addMenuSidesheetStep === "menuNeto" && !menuFormValues.title) {
            return setMenuNetoErrors("Campo obrigatório");
        }
        let res = "";
        if (editMenuFormValues.id) {
            res = await putEditMenu({
                id: editMenuFormValues.id,
                title: menuFormValues.title,
                description: menuFormValues.description,
                role: menuFormValues.role,
                icon: menuFormValues.icon,
                route: menuFormValues.route,
                menuConfigurationId: menuFormValues.menuConfigurationId,
                positionType: menuFormValues.positionType,
                parentId: editMenuFormValues.parentId,
                planIds: menuFormValues.planIds,
                type: menuFormValues.type,
            });
        } else {
            res = await postCreateMenu(menuFormValues);
        }
        if (res === "ok") {
            toast(`Menu ${editMenuFormValues.id ? "atualizado" : "criado"} com sucesso!`, "success");
            getUpdatePermission(); //TODO verificar se é necessário chamar para atualizar o menu
            return onCloseAddSystemSidesheet();
        }
    }, [
        addMenuSidesheetStep,
        editMenuFormValues.id,
        handleContinueDeleteMenu,
        menuFormValues,
        onCloseAddSystemSidesheet,
        postCreateMenu,
        putEditMenu,
        toast,
        getUpdatePermission,
    ]);

    const handleSubmit = useCallback(async () => {
        if (addMenuSidesheetStep !== "menuConfig") {
            return handleMenuConfig();
        }
        if (menuOrder.length) {
            putMenuOrder({ orderMenus: menuOrder });
        }
        return onCloseAddSystemSidesheet();
    }, [addMenuSidesheetStep, handleMenuConfig, menuOrder, onCloseAddSystemSidesheet, putMenuOrder]);

    return (
        <Sidesheet
            isLoading={isLoading}
            open={openMenuSidesheet}
            onClose={onCloseAddSystemSidesheet}
            title={handleTitleByStep()}
            content={handleContentByStep()}
            currentStep={currentStep}
            totalSteps={addMenuSidesheetStep === "deleteMenu" ? 2 : 1}
            continueButton={handleContinueButtonText()}
            handleContinueButton={handleSubmit}
            cancelButton={currentStep === 1}
            notTotalHeight
            feedback={currentStep === 2
                ? <SidesheetFeedback text="Menu excluído com sucesso!" success />
                : undefined
            }
        />
    );
}

export default AddMenuSidesheet;