import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import ConfigContentIFood from "../../components/_configCampaign/configContentIFood/ConfigContentIFood";
import { UseConfigIFood } from "./UseConfigIFood";
import { useMemo, useState } from "react";

export interface IConfigIFoodProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigIFood = ({ open, setOpen, step, setStep }: IConfigIFoodProps) => {
  const [error, setError] = useState<string | null>(null);
  const { getIFoodStatus, isLoading, values, setValues, saveConfig } =
    UseConfigIFood();

  const handleContinueButton = async () => {
    setError(null);
    if (!values.merchantId.trim()) {
      return setError("Campo obrigatório!");
    }
    const response = await saveConfig();
    if (response === "ok") setStep(step + 1);
  };

  const handleStatus = useMemo(
    () => (!!getIFoodStatus?.merchantId ? "enabled" : "disabled"),
    [getIFoodStatus]
  );

  return (
    <>
      <ContentBox
        status={handleStatus}
        type={IntegrationTypeEnum.IFOOD}
        text="Receba seus pedidos através do portal Meep com nossa integração com seu painel iFood"
        setOpen={setOpen}
        loading={isLoading}
      />
      {open && open === "iFood" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            <ConfigContentIFood
              step={step}
              values={values}
              setValues={setValues}
              error={error}
              setError={setError}
            />
          }
          cancelButton
          continueButton="Concluir"
          handleContinueButton={handleContinueButton}
          isLoading={isLoading}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração realizada com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigIFood;
