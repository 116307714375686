import { variedColors } from 'components/graphs/utils/colors';
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { Skeleton } from 'modules/salesDashboard/presentation/components/skeleton/Skeleton';
import { FC, useEffect } from 'react'
import { Cell, Pie, PieChart } from 'recharts';
import styles from './PieVouchersChart.module.scss'

export type IPieChartDataItem = {
    id?: string;
    name: string;
    total: number;
};

export interface IPieVouchersChartProps {
    getVouchersData: () => void;
    vouchersData?: IPieChartDataItem[],
    isLoading: boolean
}

const PieVouchersChart: FC<IPieVouchersChartProps> = ({ vouchersData, isLoading, getVouchersData }) => {
    useEffect(() => {
        getVouchersData();
    }, [getVouchersData])

    if (isLoading) return (
        <div className={styles.skeletonContainer}>
            <Skeleton items={1} containerStyle={styles.skeletonPieContentStyle} cardStyle={styles.skeletonPieCardStyle} />
            <Skeleton items={4} containerStyle={styles.skeletonContentStyle} cardStyle={styles.skeletonCardStyle} />
        </div>
    )

    if (!vouchersData?.length) {
        return <div id={styles.PieVouchersChart}>
            <ErrorMessage type="warning" />
        </div>
    }

    return (
        <div id={styles.PieVouchersChart} >
            <div className={styles.pie} >
                <PieChart width={200} height={200} >
                    <Pie
                        data={vouchersData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total"
                    >
                        {vouchersData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={variedColors[index]} />
                        ))}
                    </Pie>
                    {/* <Legend /> */}
                </PieChart>
            </div>
            <div className={styles.legend}>
                {vouchersData?.map((entry, index) => (
                    <div key={index} className={styles.item} >
                        <div
                            style={{
                                width: 20,
                                minWidth: 20,
                                borderRadius: 2,
                                height: 20,
                                backgroundColor: variedColors[index]
                            }}>
                        </div>
                        <label>{entry.name}</label>
                        <label>{formatCurrency(entry.total)}</label>
                    </div>
                ))}
                <div className={styles.total}>
                    Total: {formatCurrency(vouchersData.reduce((acc, next) => acc + next.total, 0))}
                </div>
            </div>
        </div >
    )
}
export default PieVouchersChart

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)
