import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IEditChild } from "modules/permutation/presentation/interfaces/IEditChild";
import ParseEditChildRequestUseCase from "./ParseEditChildRequestUseCase";


export const EditChildUseCase = async (service: IPermutationApiService, request: IEditChild, localId: string, accountId: string, childrenId: string): Promise<void> => {

  const parse = ParseEditChildRequestUseCase(request, localId);
  const _request = await service.putEditChild(parse, accountId, childrenId);
  return _request;
}


