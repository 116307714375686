import { useCallback, useState } from "react";
import {
  ISidesheetFormErrors,
  ISidesheetFormItem,
} from "./interfaces/ISidesheetForm";
import { SidesheetValidation } from "./validade/SidesheetValidation";

export const UseSidesheetMonitoramento = (
  onSubmit: (values: ISidesheetFormItem) => void,
  defaultValues?: ISidesheetFormItem
) => {
  const [values, setValues] = useState(
    defaultValues ?? {
      titulo: "",
      mensagem: "",
    }
  );

  const [errors, setErrors] = useState<ISidesheetFormErrors>({});
  const [step, setStep] = useState(1);

  const onChangeHandle = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onSubmitHandle = useCallback(() => {
    if (SidesheetValidation(values, setErrors)) {
      onSubmit(values);
      setStep(step + 1);
      setValues({
        titulo: "",
        mensagem: "",
      });
    }
  }, [onSubmit, values]);

  return {
    values,
    setValues,
    errors,
    setErrors,
    step,
    setStep,
    onChangeHandle,
    onSubmitHandle,
  };
};
