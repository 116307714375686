import React, { FC, FormEvent, useCallback, useState } from 'react'
import styles from './TablerForm.module.scss'
import { CircularProgress, Divider, IconButton, Switch, TextField } from '@mui/material';
import Button from 'components/ui/Button/Button';
import { Close } from '@material-ui/icons';


export interface ITableFormValue {
    id?: string;
    number: string;
    status: number;
}

export interface ITablerFormProps {
    //propertys
    defaultValue?: ITableFormValue;
    onSubmit: (value: ITableFormValue) => void;
    isLoading?: boolean;
    handleGoBack: () => void;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onSubmitAddMoreTable?: (firstTable: number, lastTable: number) => Promise<void>;
}
const TableForm: FC<ITablerFormProps> = ({ defaultValue, onSubmit, isLoading, handleGoBack, error, setError, onSubmitAddMoreTable }) => {
    const [values, setValues] = useState<ITableFormValue>(defaultValue || { number: '', status: 1 });
    const [createMoreTable, setCreateMoreTable] = useState(false);
    const [moreTableValues, setMoreTableValues] = useState({ initial: "", final: "" });
    const [moreTableErrors, setMoreTableErrors] = useState("");

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (createMoreTable && onSubmitAddMoreTable) {
          if (!moreTableValues.initial) {
            setMoreTableValues({...moreTableValues, initial: "1" });
          }
          if (!moreTableValues.final) {
            return setMoreTableErrors(moreTableValues.final ? "" : "Número final obrigatório");
          }
          return onSubmitAddMoreTable(Number(moreTableValues.initial), Number(moreTableValues.final));
        }

        if (!values.number) {
            return setError("Número da mesa é obrigatório");
        }

        onSubmit(values)
    }, [createMoreTable, moreTableValues, onSubmit, onSubmitAddMoreTable, setError, values]);

    return (
      <div id={styles.TablerForm}>
        <form className={styles.container} onSubmit={submitHandle}>
          <div className={styles.form}>
            <div className={styles.column}>
                <label htmlFor="number">Número da mesa</label>
                <TextField
                  variant="outlined"
                  name="number"
                  size="small"
                  value={values.number}
                  onChange={(ev) => {
                    setError("");
                    onChange(ev);
                  }}
                  helperText={error}
                  error={!!error}
                  type="number"
                  disabled={createMoreTable}
                />
            </div>
            <div className={styles.row}>
                <label htmlFor="status">Status</label>
                <Switch
                  name="status"
                  checked={values.status === 1}
                  onChange={(_, checked) => 
                    setValues({ ...values, status: checked ? 1 : 0 })
                  }
                  disabled={createMoreTable}
                />
            </div>
            {!defaultValue && (
              <>
                <Divider />
                {!createMoreTable 
                  ? <p className={styles.moreTableBtn} onClick={() => setCreateMoreTable(true)}>Criar mesas em massa</p>
                  : (
                    <div className={styles.moreTableContainer}>
                      <div className={styles.moreTableTop}>
                        <p className={styles.moreTableBtn}>Criar mesas em massa</p>
                        <IconButton size="small" onClick={() => setCreateMoreTable(false)}>
                          <Close fontSize="small" />
                        </IconButton>
                      </div>
                      <div className={styles.row} style={{ justifyContent: "start", gap: "16px", alignItems: "start" }}>
                        <div className={styles.column}>
                          <label htmlFor="initial-number">Número inicial</label>
                          <TextField
                            variant="outlined"
                            name="initial-number"
                            size="small"
                            value={moreTableValues.initial}
                            onChange={(ev) => setMoreTableValues({...moreTableValues, initial: ev.target.value })}
                            type="number"
                            />
                        </div>
                        <div className={styles.column}>
                          <label htmlFor="final-number">Número final</label>
                          <TextField
                            variant="outlined"
                            name="final-number"
                            size="small"
                            value={moreTableValues.final}
                            onChange={(ev) => {
                              setMoreTableErrors("");
                              setMoreTableValues({...moreTableValues, final: ev.target.value });
                            }}
                            type="number"
                            helperText={moreTableErrors}
                            error={!!moreTableErrors}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
            )}
          </div>
          <div className={styles.footerButtons}>
            <Button variant="outlined" color="primary" onClick={handleGoBack}>
              Voltar
            </Button>
            <Button disabled={isLoading} color="primary" type="submit">
              {isLoading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Salvar"
              )}
            </Button>
          </div>
        </form>
      </div>
    );
}
export default TableForm