export enum OperatorRoles {
    CLOSE_SESSION = "FecharCaixa",
    POS_CONFIGURATION = "ConfiguracaoPOS",
    DEACTIVATE_CARD = "DesativarCartao",
    ALLOW_PAYMENT = "PermitirPagamento",
    GROUP_CARDS = "AgruparCartoes",
    CONSULT_STATEMENT = "ConsultarExtrato",
    OPEN_SESSION = "AbrirCaixa",
    APPROVE_CONSUMPTION_LIMIT = "AprovarLimiteConsumoCashless",
    CANCEL_POS_ORDER = "CancelarPedidoPOS",
    ALLOW_CHANGE_MINIMUM_CONSUMPTION = "PoderAlterarMinimoConsumo",
    ALLOW_LINK_TAG = "PermitirVincularEtiqueta",
    LOSS_PAYMENT = "PagamentoPerda",
    ALLOW_EXIT_SESSION = "PermitirSairCaixa",
    SHIFT_CARD = "FichaVolante",
    REPORT_ON_EXIT = "RelatorioAoSair",
    ALLOW_DISCOUNT = "PermitirDesconto",
    REPRINT_TICKET = "ReimpressaoTicket",
    TRANSFER_CONSUMPTION = "TransferirConsumo",
    OPEN_ORDER = "AbrirComanda",
    ADJUSTMENT_PAYMENT = "PagamentoAjuste",
    REPRINT_TICKET_TOTEM = "ReimpressaoTicketTotem",
    CANCEL_PRODUCT = "EstornarProduto",
    OPEN_NEW_SESSION = "AbrirNovoCaixa",
    CANCEL_SERVICE_FEE = "CancelarTaxaServico",
    CLOSING_REPORT = "RelatorioFechamento",
    TRANSFER_ORDER = "TransferirComanda",
    PRODUCTION_PAYMENT = "PagamentoProducao",
    ALL = "SelecionarTodas"
}

export const OperatorRolesLabel = (role: OperatorRoles ): string => {
    switch (role) {
      case OperatorRoles.ALL:
          return "Selecionar todas as permissões";
      case OperatorRoles.OPEN_SESSION:
          return "Abrir caixa";
      case OperatorRoles.GROUP_CARDS:
          return "Agrupar cartões";
      case OperatorRoles.OPEN_ORDER:
          return "Abrir Comanda";
      case OperatorRoles.OPEN_NEW_SESSION:
          return "Abrir Novo Caixa";
      case OperatorRoles.ALLOW_CHANGE_MINIMUM_CONSUMPTION:
          return "Alterar consumo mínimo";
      case OperatorRoles.APPROVE_CONSUMPTION_LIMIT:
          return "Aprovar Limite Consumo Cashless";
      case OperatorRoles.CANCEL_POS_ORDER:
          return "Cancelar pedido";
      case OperatorRoles.CANCEL_SERVICE_FEE:
          return "Cancelar taxa de serviço";
      case OperatorRoles.POS_CONFIGURATION:
          return "Configuração POS";
      case OperatorRoles.CONSULT_STATEMENT:
          return "Consultar extrato";
      case OperatorRoles.DEACTIVATE_CARD:
          return "Desativar Cartão";
      case OperatorRoles.CANCEL_PRODUCT:
          return "Estornar produto";
      case OperatorRoles.CLOSE_SESSION:
          return "Fechar caixa";
      case OperatorRoles.SHIFT_CARD:
          return "Ficha Volante";
      case OperatorRoles.REPORT_ON_EXIT:
          return "Obter relatório ao sair do caixa";
      case OperatorRoles.LOSS_PAYMENT:
          return "Pagar como perda";
      case OperatorRoles.PRODUCTION_PAYMENT:
          return "Pagar como produção";
      case OperatorRoles.ADJUSTMENT_PAYMENT:
          return "Pagamento Ajuste";
      case OperatorRoles.ALLOW_DISCOUNT:
          return "Permitir desconto";
      case OperatorRoles.ALLOW_PAYMENT:
          return "Permitir pagamento";
      case OperatorRoles.ALLOW_LINK_TAG:
          return "Permitir Vincular Etiqueta";
      case OperatorRoles.ALLOW_EXIT_SESSION:
          return "Permitir sair do caixa";
      case OperatorRoles.REPRINT_TICKET:
          return "Reimprimir ficha";
      case OperatorRoles.REPRINT_TICKET_TOTEM:
          return "Reimpressão Ticket Totem";
      case OperatorRoles.CLOSING_REPORT:
          return "Relatório Fechamento";
      case OperatorRoles.TRANSFER_CONSUMPTION:
          return "Transferir consumo";
      case OperatorRoles.TRANSFER_ORDER:
          return "Transferir mesa";
    }
}





// export const stringToOperatorRole = (role: string): OperatorRoles | undefined => {
//     return Object.values(OperatorRoles).find((value) => value === role);
// };

// export const stringRolesToEnum = (array: string[]): OperatorRoles[] => {
//     const enumValues = Object.values(OperatorRoles);
//     const validEnumValues: OperatorRoles[] = [];
//     array.forEach((value) => {
//         const enumValue = enumValues.find((enumVal) => enumVal === value);
//         if (enumValue) {
//             validEnumValues.push(enumValue);
//         }
//     });
//     return validEnumValues;
// };



// "FecharCaixa"
// "ConfiguracaoPOS"
// "DesativarCartao"
// "PermitirPagamento"
// "AgruparCartoes"
// "ConsultarExtrato"
// "AbrirCaixa"
// "AprovarLimiteConsumoCashless"
// "CancelarPedidoPOS"
// "PoderAlterarMinimoConsumo"
// "PermitirVincularEtiqueta"
// "PagamentoPerda"
// "PermitirSairCaixa"
// "FichaVolante"
// "RelatorioAoSair"
// "PermitirDesconto"
// "ReimpressaoTicket"
// "TransferirConsumo"
// "AbrirComanda"
// "PagamentoAjuste"
// "ReimpressaoTicketTotem"
// "EstornarProduto"
// "AbrirNovoCaixa"
// "CancelarTaxaServico"
// "RelatorioFechamento"
// "TransferirComanda"
// "PagamentoProducao"
