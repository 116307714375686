import { create } from "zustand";

interface IOpenSaasModal {
  openSaasModal: boolean;
  setOpenSaasModal: (open: boolean) => void;
};

const UseOpenSaasModal = create<IOpenSaasModal>(
    (set) => ({
        openSaasModal: false,
        setOpenSaasModal: (open) => {
        set(() => ({
            openSaasModal: open,
        }));
      },
    })
);

export default UseOpenSaasModal;
