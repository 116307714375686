import Layout from "components/layout/presentation/Layout";
import AddPlaceSchedulePage from "modules/schedule/presentation/pages/addPlaceSchedule/AddPlaceSchedulePage";
import React from "react";

const AddPlaceSchedule = () => {
  return (
    <Layout>
      <AddPlaceSchedulePage />
    </Layout>
  );
};

export default AddPlaceSchedule;
