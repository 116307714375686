import styles from "./Segmento.module.scss";

interface ISegmento {
  segmentos: {
    nome: string,
    img: string,
    imgActive: string,
    id: number,
  }[]
  setCheckedItem: (checked: number) => void,
  checkedItem: number | undefined
}

const Segmento = ({segmentos, setCheckedItem, checkedItem}: ISegmento) => {
  return (
    <div id={styles.Segmento}>
      <p className={styles.title}>
        Qual é o <span>segmento</span> do seu estabelecimento?
      </p>
      <p className={styles.text}>
        Selecione a alternativa que mais representa o seu negócio:
      </p>
      <div className={styles.contentCard}>
        {segmentos.map((item, index) => {
          return (
            <div className={checkedItem === item.id ? styles.cardActive : styles.card} onClick={() => setCheckedItem(item.id)}>
              <div className={styles.box}>
                <img src={checkedItem === item.id ? item.imgActive :item.img} alt="icon" />
                <p>{item.nome}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Segmento;
