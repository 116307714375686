import { FC, useMemo } from "react";
import { IGetPaymentLinkListParams } from "../../interfaces/IGetPaymentLinkListParams";
import { IPaymentLinkColumns } from "../../interfaces/IPaymentLinkColumns";
import { IPaymentLInkList } from "../../interfaces/IPaymentLInkList";
import PaymentLinkItem from "../paymentLinkItem/PaymentLinkItem";
import styles from "./PaymentLinkList.module.scss";

export interface IPaymentLinkListProps {
  paymentLinkList: IPaymentLInkList;
  columns: IPaymentLinkColumns[];
  openSidesheet: (id?: string) => void;
  getPaymentLinkList: (params?: IGetPaymentLinkListParams) => Promise<void>;
}

const PaymentLinkList: FC<IPaymentLinkListProps> = ({
  paymentLinkList,
  columns,
  openSidesheet,
  getPaymentLinkList,
}) => {
  const renderItems = useMemo(
    () =>
      paymentLinkList &&
      paymentLinkList.records.map((it) => (
        <PaymentLinkItem
          key={it.id}
          columns={columns}
          paymentLink={it}
          openSidesheet={openSidesheet}
          getPaymentLinkList={getPaymentLinkList}
        />
      )),
    [columns, paymentLinkList, openSidesheet, getPaymentLinkList]
  );

  return <div id={styles.PaymentLinkList}>{renderItems}</div>;
};

export default PaymentLinkList;
