import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DigitalAccountApi from "services/api/digitalAccount/DigitalAccountApi";
import PostSearchAnticipate from "modules/contaDigital/application/useCases/PostSearchAnticipateUseCase";
import { SearchAnticipateResponse } from "modules/contaDigital/models/dtos/SearchAnticipateRequest";
import PostConfirmAnticipate from "modules/contaDigital/application/useCases/PostConfirmAntecipateUseCase";
import PostSimulateAnticipate from "modules/contaDigital/application/useCases/PostSimulateAntecipateUseCase";
import { SimulateAntecipateResponse } from "modules/contaDigital/models/dtos/SimulateAntecipateRequest";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

export const UseAntecipateReceivedPage = () => {
  const accountService = DigitalAccountApi();
  const { currentLocal } = useLocal();
  const [listAntecipate, setListAntecipate] = useState<
    SearchAnticipateResponse[] | undefined
  >();
  const [loading, setLoading] = useState(false)
  const [simulate, setSimulate] = useState<SimulateAntecipateResponse | undefined>();

  const { toast } = useUi();

  const postSearchAnticipate = useCallback(
    async (dataInicio, dataFim) => {
      setLoading(true)
      const body = {
        LocalClienteId: currentLocal?.id,
        dataInicio,
        dataFim,
      };
      await PostSearchAnticipate(accountService, body).then((response) => {
        setListAntecipate(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast(error.response?.data.message, "error");
        }
      });;
      ;
    },
    [currentLocal]
  );

  const postConfirmAnticipate = useCallback(
    async (dadoBancarioId, senha, valorRequisitado) => {
      const body = {
        LocalClienteId: currentLocal?.id,
        dadoBancarioId,
        senha,
        valorRequisitado
      };
      await PostConfirmAnticipate(accountService, body)
    },
    [currentLocal]
  );

  const postSimulateAnticipate = useCallback(
    async (dadoBancarioId, valorRequisitado) => {
      const body = {
        LocalClienteId: currentLocal?.id,
        dadoBancarioId,
        valorRequisitado
      };
      const response = await PostSimulateAnticipate(accountService, body)
      setSimulate(response)
    },
    [currentLocal]
  );

  return {
    postSearchAnticipate,
    listAntecipate,
    postConfirmAnticipate,
    postSimulateAnticipate,
    simulate,
    loading
  };
};
