import { ISort } from "interfaces/ISort";
import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";
import { IPermutationAccountfilter } from "modules/permutation/presentation/components/permutationFilter/interfaces/IPermutationAccountfilter";
import { IGetPermutationAccountResponse } from "modules/permutation/domain/dto/IGetPermutationAccountResponse";


const GetPermutationUseCase = async (service: IPermutationApiService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IPermutationAccountfilter): Promise<IGetPermutationAccountResponse> => {
   
    const request =  ParseFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getAccounts(request, sort)
    return (response)
}
export default GetPermutationUseCase