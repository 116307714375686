import { Button, Icon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CampaignStatus } from "modules/crm/presentation/intefaces/ICampaign";
import React, { useMemo } from "react";
import BoxInfo from "./BoxInfo/BoxInfo";
import styles from "./Funnel.module.scss";
import FunnelItem from "./funnelItem/FunnelItem";



export interface ICrmFunnelItem {
  title: string;
  value?: number;
  background?: string;
  width?: number;
  hide?: boolean
}


export interface CrmReportFunnelProps {
  total: number;
  data: ICrmFunnelItem[];
  isLoading: boolean
  totalFail: number
  campaignStatus?: CampaignStatus
}
const Funnel = ({ data, total, isLoading, totalFail, campaignStatus }: CrmReportFunnelProps) => {

  const funil = [
    { background: "#8D4FFF", width: 562 },
    { background: "#6C2EE0", width: 462 },
    { background: "#4D13B7", width: 362 },
    { background: "#001537", width: 262 },
  ]

  const calcPercentage = (value: number, total: number) => {
    if (total === 0) {
      return "0"
    }
    if (value === 0) {
      return "0"
    }
    return (value / total * 100).toFixed()
  }

  return (
    isLoading ?
      <Skeleton variant="rect" height={150} />
      :
      <div className={styles.container}>
        <div className={styles.contentBoxInfo}>
          {
            data.map((item, index) => (
              !item.hide &&
              <BoxInfo
                background={item.background}
                title={item.title}
                percentage={calcPercentage(item?.value ?? 0, total)}
                total={item.value} 
                isLast={index === data.length - 1}
              />
            ))
          }
          {/* {!!totalFail && campaignStatus === CampaignStatus.Sent && <BoxInfo
            background={"#fdd"}
            title={"Não enviados"}
            percentage={calcPercentage(totalFail, total)}
            total={totalFail} >

            {<Button size="small" onClick={onClickResend} variant={'outlined'} color={"primary"}>Enviar novamente <Icon fontSize="small">send</Icon></Button>}
          </BoxInfo>} */}
        </div>
        <div className={styles.contentFunnel}>
          {data.map((item, index) => {
            return (
              // item?.value && item?.value > 0 &&
              <FunnelItem
                title={item.title}
                value={item.value}
                background={funil[index].background}
                width={funil[index].width}
              />
            );
          })}
        </div>
      </div>
  );
};
export default Funnel;
