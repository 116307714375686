import { Modal } from "components/ui/modal/Modal";
import UseEnableDisableAllModal from "../../UseEnableDisableAllModal";
import UseProductPage from "../../UseProductPage";
import styles from "./_EnableDisableAllModal.module.scss";

const EnableDisableAllModal = () => {
  const { openEDAModal, toggleEDAModal, actualAction } =
    UseEnableDisableAllModal();
  const { onEnableDisableAll } = UseProductPage();

  const action = actualAction ? "ativar" : "desativar";

  const captalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

  const handleEnableDisableAll = () => {
    onEnableDisableAll(actualAction);
  };

  return (
    <Modal
      open={openEDAModal}
      title={`${captalizeFirstLetter(action)} produtos`}
      toggleModal={toggleEDAModal}
      showButtons={true}
      onConfirm={() => handleEnableDisableAll()}
      keepMounted={false}
      id={styles.enableDisableAllModal}
    >
      <div className={styles.container}>
        <p>Você realmente deseja {action} todos os produtos?</p>
      </div>
    </Modal>
  );
};

export { EnableDisableAllModal };
