
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { IAvalableDaysPlaceForm } from './interface/IAvalableDaysPlaceScheduleForm';
import { IPlaceFormErros } from './interface/IPlaceFormErros';
import { IPlaceScheduleForm } from './interface/IPlaceScheduleForm'
import { validate } from './validation/PlaceFormValidation';

const UsePlaceForm = (onSubmit: (value: IPlaceScheduleForm) => void, defaultValues?: IPlaceScheduleForm) => {

    const [values, setValues] = useState<IPlaceScheduleForm>({
        prefix: "",
        maxQuantity: 1,
        description: "",
        // minimumLeadTimeInDays: 0,
        minimumLeadTimeInMinutes: 0,
        maximumLeadTimeInDays: 0,
        // orderPadsIds: [],
        servicesScheduleIds: [],
        availableDays: [],
        specialDays:
            [{
                start: "",
                end: "",
                opened: {
                    maxOccupancy: 0,
                    start: "",
                    end: ""
                },
                closed: {
                    start: "",
                    end: ""
                }
            }],

    })

    const [selectedOrderPads, setSelectedOrderPads] = useState<ISelect[]>([]);
    const [selectedServices, setSelectedServices] = useState<ISelect[]>([]);
    const [errors, setErrors] = useState<IPlaceFormErros>({});


    const [minimumLeadTime, setMinimumLeadTime] = useState(0)
    const [minimumTimeType, setMinimumTimeType] = useState<"days" | "hours" | "minutes">("days")

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues)
        }
    }, [defaultValues])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const onChangeMinimalLeadHandle = useCallback((value: number) => {

        setMinimumLeadTime((prev) => (value));

    }, []);

    const onChangeMinimumDayTypeHandle = useCallback((value: "days" | "hours" | "minutes") => {
        setMinimumTimeType(value)
    }, []);

    const changeSelectHandle = useCallback((name: string, values: ISelect[]) => {
        if (name === "orderPadsIds") {
            setSelectedOrderPads(values);
            setValues((prev) => ({ ...prev, [name]: values.map(item => item.id) }));
        } else if (name === "servicesScheduleIds") {
            setSelectedServices(values);
            setValues((prev) => ({ ...prev, [name]: values.map(item => item.id) }));
        }
    }, []);

    const changeAvalableDaysHandle = useCallback((values: IAvalableDaysPlaceForm[]) => {
        setValues((prev) => ({ ...prev, availableDays: values }));
    }, [])


    const calcMinimalLeadTimeInMinutes = useCallback(() => {

        let valueMinimalLeadTimeInMinutes = minimumLeadTime
        if (minimumTimeType === "hours") {
            valueMinimalLeadTimeInMinutes = minimumLeadTime * 60
        }
        if (minimumTimeType === "days") {
            valueMinimalLeadTimeInMinutes = minimumLeadTime * 60 * 24
        }

        return valueMinimalLeadTimeInMinutes
    }, [minimumLeadTime, minimumTimeType])


    const onSubmitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (validate(values, setErrors, selectedServices, minimumLeadTime, )) {
            onSubmit({
                ...values,
                minimumLeadTimeInMinutes: calcMinimalLeadTimeInMinutes()
            });
        }

    }, [calcMinimalLeadTimeInMinutes, minimumLeadTime, onSubmit, selectedServices, values]);

    return (
        {
            values,
            changeHandle,
            changeSelectHandle,
            onSubmitHandle,
            selectedOrderPads: selectedOrderPads,
            selectedServices,
            changeAvalableDaysHandle,
            onChangeMinimumDayTypeHandle,
            onChangeMinimalLeadHandle,
            minimumTimeType,
            minimumLeadTime,
            errors
        }
    )
}

export default UsePlaceForm
