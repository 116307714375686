import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CreateCashlessVoucherUseCase from "modules/events/application/useCases/voucher/CreateCashlessVoucherUseCase";
import GetCashlessVoucherListUseCase from "modules/events/application/useCases/voucher/GetCashlessVoucherListUseCase";
import GetLocaLConfigurationUseCase from "modules/events/application/useCases/voucher/GetLocalConfigurationUseCase";
import { ICashlessConsummationForm } from "modules/events/presentation/components/cashlessConsummationForm/interfaces/ICashlessConsummationForm";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { CashlessVoucherApi } from "services/api/events/voucher/CashlessVoucherApi";
import LocalService from "services/api/local/LocalService";
import { ICashlessConsummation } from "./ICashlessConsummation";
import { ILocalConfiguration } from "./interfaces/ILocalConfiguration";

const UseCashlessConsummation = (cashierId?: string) => {
  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
  const [cashlessList, setCashlessList] = useState<ICashlessConsummation[]>([]);
  const cashlessVoucherService = CashlessVoucherApi();
  const { currentLocal } = useLocal();
  const { toast, hideLoading, showLoading } = useUi();
  const [loadingCashlessList, setLoadingCashlessList] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [localId, setLocalId] = useState<string>();
  const [localConfiguration, setLocalConfiguration] = useState<ILocalConfiguration>();
  const localService = LocalService();
  

  const handleOpenBalanceModal = useCallback(() => {
    setShowAddBalanceModal(true);
  }, []);

  const handleCloseBalanceModal = useCallback(() => {
    setShowAddBalanceModal(false);
  }, []);

  useEffect(() => {
    if(currentLocal?.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal])
  
  
  const getLocalConfigurations = useCallback( async () => {
    try {
      if(currentLocal?.id){
        const result = await GetLocaLConfigurationUseCase(localService, currentLocal?.id);
        setLocalConfiguration(result);
      }
    }catch (error) {
    }
  },[]);

  useEffect(() => {
    getLocalConfigurations()
  }, [])
  
  const getCashlessList = useCallback(
    async (page: number = 0) => {
      if (localId && cashierId) {
        setLoadingCashlessList(true);
        try {
          const result = await GetCashlessVoucherListUseCase(cashlessVoucherService, localId, cashierId, page);
          if (page === 0) {
            setCashlessList(result.records);
            setTotalRecords(result.totalRecords)
          } else if (page > 0) {
            cashlessList && setCashlessList(cashlessList.concat(result.records));
          }
        } catch (error) {
        } finally {
          setLoadingCashlessList(false);
        }
      } else {
        return Promise.reject(new Error('Paramêtros inválidos!'))
      }
    },
    [cashierId, cashlessList, cashlessVoucherService, localId]
  );

  const handleAddCashlessConsummation = useCallback(async (cashlessValues: ICashlessConsummationForm) => {
    if (localId && cashierId) {
      try {
        showLoading()
        await CreateCashlessVoucherUseCase(cashlessVoucherService, { ...cashlessValues, localId, cashierId });
        getCashlessList();
        toast("Saldo criado com sucesso!", "success");
        handleCloseBalanceModal();
      } catch (error) {
      } finally {
        hideLoading();
      }
    }
  }, [cashierId, cashlessVoucherService, localId, getCashlessList, handleCloseBalanceModal, hideLoading, showLoading, toast]);

  return {
    showAddBalanceModal,
    handleOpenBalanceModal,
    handleCloseBalanceModal,
    cashlessList,
    handleAddCashlessConsummation,
    loadingCashlessList,
    getCashlessList,
    totalRecords,
    localId,
    localConfiguration
  };
};

export default UseCashlessConsummation;
