import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import GrantAccessForm from "modules/events/presentation/components/grantAccessForm/GrantAccessForm";
import React from "react";
import { IEventsGuestList } from "../../interfaces/IEventGuestsListsPage";
import GuestListForm from "../GuestListForm/GuestListForm";
import { GuestsListCard } from "./components/guestsListCard/GuestsListCard";
import styles from "./GuestsLists.module.scss";
import UseGuestLists from "./UseGuestLists";
import Button from "components/ui/Button/Button";
import warningImg from "res/assets/images/warning.png";

interface IAllEventListTabProps {
  adminAccess: boolean;
  refreshList: () => Promise<void>;
  data?: IEventsGuestList[];
}

export const GuestsLists: React.FC<IAllEventListTabProps> = ({
  adminAccess,
  refreshList,
  data,
}) => {
  const {
    discountsList,
    editListId,
    guestListToEdit,
    onClickCancelEditGuestList,
    onClickEditGuestList,
    onClickGoToGuestList,
    onClickDeleteGuestList,
    onClickGrantAccess,
    showAccessModal,
    showEditListModal,
    submitEditGuestList,
    closeAccessModal,
    closeEditListModal,
    closeDeleteModal,
    showDeleteModal,
    deleteGuestList,
    deleteListId,
  } = UseGuestLists(refreshList);

  return (
    <div id={styles.AllEventLists}>
      <div className={styles.containerCard}>
        {data?.map((eventList, index) => (
          <GuestsListCard
            onClickGrantAccess={onClickGrantAccess}
            key={index}
            eventList={eventList}
            onClickGoToGuestList={onClickGoToGuestList}
            onClickDeleteGuestList={onClickDeleteGuestList}
            onClickEditGuestList={onClickEditGuestList}
            baseList={discountsList}
            refreshEventLists={refreshList}
            adminAccess={adminAccess}
          />
        ))}
      </div>

      <RightDrawer
        title="Excluir "
        titleBold="Lista?"
        open={showDeleteModal}
        onClose={closeDeleteModal}
      >
        <div id={styles.deleteModal}>
          <div>
            <img src={warningImg} alt="warning_image" />
            <p>Deseja realmente excluir a lista de convidados?</p>
          </div>
          <div>
            <Button
              fullWidth={false}
              onClick={closeDeleteModal}
              color="primary"
              variant="outlined"
              className={styles.outlined}
            >
              Voltar
            </Button>
            <Button
              fullWidth={false}
              onClick={() => deleteGuestList(deleteListId)}
              color="primary"
              variant="contained"
              className={styles.contained}
            >
              Excluir
            </Button>
          </div>
        </div>
      </RightDrawer>

      <RightDrawer
        title={
          <p>
            Editar <b>lista de convidados</b>
          </p>
        }
        open={showEditListModal}
        onClose={closeEditListModal}
        className={styles.modal}
      >
        <GuestListForm
          guestList={guestListToEdit}
          handleSubmit={submitEditGuestList}
          onCancel={onClickCancelEditGuestList}
          adminAccess={adminAccess}
        />
      </RightDrawer>

      <RightDrawer
        title={
          <p>
            Promoters <b>com acesso a lista</b>
          </p>
        }
        open={showAccessModal}
        onClose={closeAccessModal}
        className={styles.modal}
      >
        <GrantAccessForm listId={editListId} onClose={closeAccessModal} />
      </RightDrawer>
    </div>
  );
};
