import { ISurveyService } from "modules/survey/domain/interfaces/ITrackConfigService"
import { EventSurvey } from "modules/survey/presentation/components/EventSurvey"
import { TrackSurveyWidget } from "modules/survey/presentation/components/TrackSurveyWidget"
import { ITrackSurvey } from "modules/survey/presentation/interfaces/ITrackSurveyList";
import { EnumSurveySendMethod } from "modules/survey/presentation/interfaces/EnumSurveySendMethod";

const UpdateCustomerSurveyUseCase = async (service: ISurveyService, ownerId: string, id: string, eventSurvey: EventSurvey, trackSurvey: ITrackSurvey, sendMethod: EnumSurveySendMethod) => {
        const response = await service.update({
                id,
                sendEventType: eventSurvey.type,
                ownerId,
                trackSurveyId: trackSurvey.id,
                trackSurveyName: trackSurvey.name,
                enabled: true,
                sendMethod: sendMethod
        });
        return (response)
}
export default UpdateCustomerSurveyUseCase