import { Radio, TextField } from "@material-ui/core";
import styles from "./ChangePaymentMethod.module.scss";
import InputMasked from "components/inputMasked/InputMasked";
import Layout from "components/layout/presentation/Layout";
import { KeyboardArrowLeftOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import CardItem from "./components/cardItem/CardItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import { INewCardFormErrors, UseChangePaymentMethod } from "./hooks/UseChangePaymentMethod";
import Button from "components/ui/Button/Button";
import { Add, DeleteOutline } from "@material-ui/icons";
import { CircularProgress, IconButton, Skeleton } from "@mui/material";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UseDimension from "components/dimension/UseDimension";

export const ChangePaymentMethod = () => {
    const history = useHistory();
    const { dimensions } = UseDimension();
    const {
        isLoading,
        cardsList,
        newCardForm,
        setNewCardForm,
        handleSearchByCEP,
        errors,
        setErrors,
        getCardsList,
        creditCardExpiration,
        handleCreditCardExpiration,
        clearForm,
        handleDeleteCard: handleDelete,
        handleAddCard,
        handleEditCard,
        defaultCard,
    } = UseChangePaymentMethod();

    const [onAddNewCard, setOnAddNewCard] = useState(false);
    const [isCreatingNewCard, setIsCreatingNewCard] = useState(false);
    const [newDefaultCard, setNewDefaultCard] = useState(defaultCard);
    const [openDeletCard, setOpenDeletCard] = useState("");
    const [deletCardStep, setDeletCardStep] = useState(1);

    useEffect(() => {
        getCardsList();
    }, [getCardsList]);
    
    const goBack = () => {
        return history.push("/private/plans");
    }
    
    const handleDeleteCard = useCallback(async () => {
        const res = await handleDelete(openDeletCard);
        if (res === "success") {
            getCardsList();
            setDeletCardStep(deletCardStep + 1);
        }
    }, [deletCardStep, getCardsList, handleDelete, openDeletCard]);

    const handleSubmitCard = useCallback(async () => {
        let res: "success" | undefined;
        if (newDefaultCard && !isCreatingNewCard) {
            res = await handleEditCard(newDefaultCard);
        } else {
            res = await handleAddCard();
        }
        if (res === "success") {
            getCardsList();
            if (isCreatingNewCard) {
                setErrors({} as INewCardFormErrors);
                clearForm();
                setOnAddNewCard(false);
                setIsCreatingNewCard(false);
            }
        }
    }, [clearForm, getCardsList, handleAddCard, handleEditCard, newDefaultCard, isCreatingNewCard, setErrors]);

    const skeletons = Array.from({ length: 2 }, (_, index) => (
        <Skeleton
            key={index}
            variant="text"
            sx={{ marginBottom: "10px", backgroundColor: "#ededed" }}
            height={100}
            width="100%"
        />
    ));

    const list = useMemo(() => cardsList?.map((card) => (
        <CardItem
            card={card}
            key={card.subscriptionCardId}
            isCreatingNewCard={isCreatingNewCard}
            newDefaultCard={newDefaultCard}
            setNewDefaultCard={setNewDefaultCard}
            setOpenDeletCard={setOpenDeletCard}
            isLoading={isLoading}
        />
    )), [cardsList, isCreatingNewCard, newDefaultCard, isLoading]);

    return (
        <Layout disabledMenu>
            <div className={styles.container}>
                <div className={styles.backContainer} onClick={goBack}>
                    <KeyboardArrowLeftOutlined className={styles.icon} />
                    {dimensions.width > 650 && <span>Voltar</span>}
                </div>

                <div className={styles.content}>
                    <h1>Dados de <b>pagamento</b></h1>
                    <p>A alteração dos dados de pagamento não implica em nenhuma cobrança adicional, upgrade ou mudança na assinatura.</p>

                    {isLoading 
                        ? (
                            <div>
                                {skeletons}
                            </div>
                        ) : list
                    }                    

                    <div className={`${styles.card} ${styles.transition}`} style={{ opacity: !onAddNewCard ? 0 : 1 }}>
                        <div className={styles.header}>
                            <div className={styles.cardName}>
                                <Radio className={styles.radio} checked={isCreatingNewCard} />
                                <p>Cartão de crédito</p>
                            </div>
                            <IconButton
                                size="small"
                                disableFocusRipple
                                onClick={() => {
                                    setErrors({} as INewCardFormErrors);
                                    clearForm();
                                    setOnAddNewCard(false);
                                    setIsCreatingNewCard(false);
                                }}
                            >
                                <DeleteOutline style={{ color: "#5F5F5F" }}/>
                            </IconButton>
                        </div>

                        <div className={styles.row} style={{ flexDirection: "row", gap: "8px" }}>
                            <img src="/assets/icon/saas/cards/visa.svg" alt="" />
                            <img src="/assets/icon/saas/cards/master.svg" alt="" />
                            <img src="/assets/icon/saas/cards/amex.svg" alt="" />
                            <img src="/assets/icon/saas/cards/apple.svg" alt="" />
                            <img src="/assets/icon/saas/cards/google.svg" alt="" />
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="cardNumber" className={styles.required}>Número do cartão</label>
                                <InputMasked
                                    variant="outlined"
                                    mask="9999 9999 9999 9999"
                                    placeholder="9999 9999 9999 9999"
                                    fullWidth
                                    name="cardNumber"
                                    value={newCardForm.card?.cardNumber}
                                    onChange={(e:any) => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIsCreatingNewCard(true);
                                        }
                                        if (!e.target.value) {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, cardNumber: undefined }));
                                        setNewCardForm(prev => ({ ...prev, card: { ...prev.card, cardNumber: e.target.value.replace(/\s+/g, "") } }));
                                    }}
                                    error={!!errors.cardNumber}
                                    helperText={errors.cardNumber}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="holderName" className={styles.required}>Nome no cartão</label>
                                <TextField
                                    variant="outlined"
                                    placeholder="Digite igual no cartão"
                                    fullWidth
                                    size="small"
                                    name="holderName"
                                    value={newCardForm.card?.holderName}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setIsCreatingNewCard(true);
                                        } else {
                                            setIsCreatingNewCard(false);
                                        }
                                        if (!/\d/.test(e.target.value)) {
                                            setErrors(prev => ({ ...prev, holderName: undefined }));
                                            setNewCardForm(prev => ({
                                                ...prev,
                                                payer: { ...prev.payer, name: e.target.value },
                                                card: { ...prev.card, holderName: e.target.value },
                                            }));
                                        }
                                    }}
                                    error={!!errors.holderName}
                                    helperText={errors.holderName}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="document" className={styles.required}>CPF</label>
                                <InputMasked
                                    name="document"
                                    variant="outlined"
                                    fullWidth
                                    mask="999.999.999-99"
                                    placeholder="Digite o CPF do titular"
                                    value={newCardForm.payer?.document}
                                    onChange={(e: any) => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIsCreatingNewCard(true);
                                        }
                                        if (!e.target.value) {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, document: undefined }));
                                        setNewCardForm(prev => ({ ...prev, payer: { ...prev.payer, document: e.target.value.replace(/[^\d]+/g,'') } }));
                                    }}
                                    error={!!errors.document}
                                    helperText={errors.document}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="expirationDate" className={styles.required}>Validade</label>
                                <InputMasked
                                    name="expirationDate"
                                    variant="outlined"
                                    fullWidth
                                    mask="99/99"
                                    placeholder="MM/AA"
                                    value={creditCardExpiration}
                                    onChange={(e: any) => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIsCreatingNewCard(true);
                                        }
                                        if (!e.target.value) {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, expirationDate: undefined }));
                                        handleCreditCardExpiration(e.target.value);
                                    }}
                                    error={!!errors.expirationDate}
                                    helperText={errors.expirationDate}
                                />
                            </div>
                            <div className={styles.column}>
                                <label htmlFor="securityCode" className={styles.required}>Código de segurança</label>
                                <InputMasked
                                    name="securityCode"
                                    variant="outlined"
                                    fullWidth
                                    mask="999"
                                    placeholder="000"
                                    value={newCardForm.card?.securityCode}
                                    onChange={(e: any) => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIsCreatingNewCard(true);
                                        }
                                        if (!e.target.value) {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, securityCode: undefined }));
                                        setNewCardForm(prev => ({ ...prev, card: { ...prev.card, securityCode: e.target.value } }));
                                    }}
                                    error={!!errors.securityCode}
                                    helperText={errors.securityCode}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="cep" className={styles.required}>CEP</label>
                                <InputMasked
                                    name="cep"
                                    variant='outlined'
                                    fullWidth
                                    mask="99999-999"
                                    value={newCardForm.payer?.address.zipCode}
                                    onChange={(e: any) => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIsCreatingNewCard(true);
                                        }
                                        if (!e.target.value) {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, zipCode: undefined }));
                                        setNewCardForm(prev => ({ ...prev, payer: { ...prev.payer, address: { ...prev.payer?.address, zipCode: e.target.value } } }));
                                    }}
                                    helperText={errors.zipCode}
                                    error={!!errors.zipCode}
                                    type="tel"
                                    onBlur={(ev) => handleSearchByCEP(ev.target.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="street" className={styles.required}>Rua</label>
                                <TextField
                                    name="street"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={newCardForm.payer?.address.street}
                                    onChange={(e: any) => {
                                        if (e.target.value) {
                                            setIsCreatingNewCard(true);
                                        } else {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, street: undefined }));
                                        setNewCardForm(prev => ({ ...prev, payer: { ...prev.payer, address: { ...prev.payer?.address, street: e.target.value } } }));
                                    }}
                                    helperText={errors.street}
                                    error={!!errors.street}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="number" className={styles.required}>Número</label>
                                <TextField
                                    name="number"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={newCardForm.payer?.address.number}
                                    onChange={(e: any) => {
                                        if (e.target.value) {
                                            setIsCreatingNewCard(true);
                                        } else {
                                            setIsCreatingNewCard(false);
                                        }
                                        setErrors(prev => ({ ...prev, number: undefined }));
                                        setNewCardForm(prev => ({ ...prev, payer: { ...prev.payer, address: { ...prev.payer?.address, number: e.target.value } } }));
                                    }}
                                    helperText={errors.number}
                                    error={!!errors.number}
                                    type="tel"
                                />
                            </div>
                            <div className={styles.column}>
                                <label htmlFor="city" className={styles.required}>Cidade</label>
                                <TextField
                                    name="city"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={newCardForm.payer?.address.city}
                                    onChange={(e: any) => {
                                        if (!/\d/.test(e.target.value)) {
                                            if (e.target.value) {
                                                setIsCreatingNewCard(true);
                                            } else {
                                                setIsCreatingNewCard(false);
                                            }
                                            setErrors(prev => ({ ...prev, city: undefined }));
                                            setNewCardForm(prev => ({ ...prev, payer: { ...prev.payer, address: { ...prev.payer?.address, city: e.target.value } } }));
                                        }
                                    }}
                                    helperText={errors.city}
                                    error={!!errors.city}
                                />
                            </div>                            
                        </div>
                    </div>

                    <div className={styles.footer} style={{ marginTop: !onAddNewCard ? -690 : 0 }}>
                        <Button
                            variant="text"
                            startIcon={<Add />}
                            style={{ width: "fit-content" }}
                            onClick={() => setOnAddNewCard(true)}
                            disabled={onAddNewCard || isLoading}
                        >
                            Adicionar forma de pagamento
                        </Button>

                        <p className={styles.footerInfo} style={{ textAlign: "start" }}>
                            Pelo presente, você autoriza a Mee a realizar a cobrança todo mês automaticamente até que a assinatura seja cancelada.<br/>
                            Termos e condições disponíveis{" "}
                            <a href="https://975d0d01-7cfd-477b-86b2-035f8412c27c.usrfiles.com/ugd/975d0d_aa0583717f694182961ba85f3412d0bb.pdf" target="_blank" rel="noreferrer">aqui</a>.
                        </p>
                        
                        <Button color="secondary" onClick={handleSubmitCard} disabled={isLoading || (newDefaultCard === defaultCard && !isCreatingNewCard)}>
                            {isLoading ? <CircularProgress size={16} color="inherit" /> : "Mudar forma de pagamento"}
                        </Button>
                    </div>
                </div>
            </div>

            <Sidesheet
                isLoading={isLoading}
                open={openDeletCard}
                onClose={() => {
                    setOpenDeletCard("");
                    setDeletCardStep(1);
                }}
                title={<h2>Excluir <b>cartão</b></h2>}
                content={<SidesheetFeedback text="Essa ação não poderá ser desfeita!" />}
                currentStep={deletCardStep}
                totalSteps={2}
                continueButton="Excluir"
                handleContinueButton={handleDeleteCard}
                cancelButton
                feedback={
                    <SidesheetFeedback text="Cartão excluído com sucesso!" success />
                }
            />
        </Layout>
    )
}