import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Drawer, IconButton } from "@material-ui/core";
import styles from "./HouseFlowHeader.module.scss";
import { HouseFlowColunmsPropertyType, IHouseFlowColumnsConfig } from "../interfaces/IColumnsConfig";
import { EnumCardStatus, IHouseFlow, IOrderPadsItem } from "modules/houseFlow/presentation/interfaces/IHouseFlow";
import { KeyboardArrowRight } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import HouseFLowSideSheetDetails from "modules/houseFlow/presentation/components/houseFLowSideSheetDetails/HouseFLowSideSheetDetails";
import UseHouseFlowDetailsItem from "modules/houseFlow/presentation/hooks/UseHouseFlowDetails";
import { IDetailsItem, IHouseFlowDetails } from "modules/houseFlow/presentation/interfaces/IHouseFlowDetails";

interface Props {
  columns: IHouseFlowColumnsConfig[]
  userItemData: IOrderPadsItem;
  houseFlowList: IHouseFlow
  
}

const HouseFlowItem: React.FC<Props> = ({ userItemData, columns, houseFlowList }) => {

  const [selectedItem, setSelectedItem] = useState<boolean>(false)

  const openDrown = useCallback(() => {
    setSelectedItem(true)
  }, []);
  const closeDrown = useCallback(() => {
    setSelectedItem(false)
  }, []);

  const formatValues = useCallback((property: HouseFlowColunmsPropertyType): ReactNode => {
    if (userItemData.hasOwnProperty("cardId")) {
      switch (property) {
        //formatar status  
        case 'cardStatus':
          if (userItemData.cardStatus === EnumCardStatus.AVAILABLE) {
            return <div className={styles.available}></div>
          } else if (userItemData.cardStatus === EnumCardStatus.IDLE) {
            return <div className={styles.idle}></div>
          } else {
            return <div className={styles.inUse}></div>
          }

        case 'cardTotalNet':
          return moneyMaskNumber(userItemData?.cardTotalNet)

        case 'customerCPF':
          if (userItemData?.customerCPF) {
            return ("***" + userItemData?.customerCPF)
          } else {
            return "-"
          }

        default:
          return userItemData?.[property] ?? '-'
      }
    }
  }, [userItemData])

  const List = useMemo(() => columns.map((colunm, index) => {
    return (!colunm.hide && <div
      key={index}
      className={styles.row}
      style={colunm.style ?? { flex: 1 }}
    >
      {formatValues(colunm.property)}
    </div>)
  }), [columns, formatValues])

  return (
    <>
      <div id={styles.CrmItemContainer}>
        <div className={styles.container}>
          {List}
          <div
            className={styles.icon}
          >
            <IconButton onClick={openDrown}>
              <KeyboardArrowRight />
            </IconButton>
          </div>
        </div>

      </div>
      <Drawer open={selectedItem} anchor="right" onClose={closeDrown}>
        <div id={styles.drawer}>

          <HouseFLowSideSheetDetails
            houseFlowList={houseFlowList}
            item={userItemData}
            closeDrown={closeDrown}
          />


        </div>
      </Drawer>
    </>
  );
};

export default HouseFlowItem;

