import { format } from "date-fns";
import styles from "./TableMobile.module.scss";
import { SearchAnticipateResponse } from "modules/contaDigital/models/dtos/SearchAnticipateRequest";
import { formatarValor } from "../../pages/utils";

interface ITableMobile {
  item: SearchAnticipateResponse; 
}

const TableMobile= ({
  item,
}: ITableMobile) => {
  return (
    <>
      <div id={styles.TableMobile}>
        <div className={styles.item}>
          <span className={styles.title}>Conta bancária:</span>
          <span className={styles.text}>{item.contaBancaria}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Solicitação:</span>
          <span className={styles.text}>{format(new Date(item.dataSolicitacao), 'dd/MM/yy')}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Solicitado por:</span>
          <span className={styles.text}>{item.usuarioMeepNome}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Recebimento:</span>
          <span className={styles.text}>{format(new Date(item.dataRecebimento), 'dd/MM/yy')}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Status:</span>
          <span className={styles.text}>{item.statusDescricao}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Tx. de antecipação:</span>
          <span className={styles.text}>{formatarValor(item.taxaAntecipacao)}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Tx. de processamento:</span>
          <span className={styles.text}>{formatarValor(item.taxaProcessamento)}</span>
          </div>
          <div className={styles.item}>
          <span className={styles.title}>Valor solicitado:</span>
          <span className={styles.text}>{formatarValor(item.valorSolicitado)}</span>
          </div>       
      </div>
    </>
  );
};
export default TableMobile;
