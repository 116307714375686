import Layout from "components/layout/presentation/Layout";
import DonationsPage from "./DonationsPage";

const Doacoes = () => {
  return (
    <Layout>
      <DonationsPage />
    </Layout>
  );
};

export default Doacoes;
