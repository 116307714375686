import { AxiosInstance } from "axios"
import { IGetImpressoraPadraoResponse } from "modules/products/domain/dto/IGetImpressoraPadraoResponse"

const ObterImpressoras = async (api: AxiosInstance, localId: string): Promise<IGetImpressoraPadraoResponse> => {
    const response = await api.get<{ Nome: string, Id: string }[]>("/impressoras?localClienteId=" + localId)
    
    return {
        records: response.data.map(x => ({ name: x.Nome, id: x.Id })),
        totalRecords: response.data.length
    }
}
export default ObterImpressoras