import { z } from "zod";

export const loginSchema = z.object({
  email: z.string()
    .min(1, { message: "Campo obrigatório!" })
    .email("E-mail inválido!"),
  password: z.string()
    .min(6, { message: "A senha deve conter ao menos 6 caracteres." }),
});

export type ILoginSchema = z.infer<typeof loginSchema>;
