import React, { FC, useCallback, useEffect, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { ITopProducts } from './interfaces/ITopProducts'

export interface ITopProductsProps {
    getTopProducts?: ({ page }: IGetDataParams) => Promise<ITopProducts>
}

const TopProducts: FC<ITopProductsProps> = ({ getTopProducts }) => {
    const [topProductsData, setTopProductsData] = useState<ITopProducts>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    const [sortField, setSortField] = useState<string>('total')
    const [sortOrientation, setSortOrientation] = useState<'asc' | 'desc'>('desc')
    
    const getData = useCallback(async (page?: number) => {
        if (getTopProducts) {
            clearContainerError(dashboardContainerEnum.TOP_PRODUCTS);
            try {
                !page && setOnLoading(true);
                const response = await getTopProducts?.({ page, sortField, sortOrientation });
                if (page) {
                    setTopProductsData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
                } else {
                    setTopProductsData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
                }

                if (!response.records.length)
                    setOnError({
                        type: 'warning',
                        containerId: dashboardContainerEnum.TOP_PRODUCTS
                    })
            } catch (error: any) {
                setOnError({
                    type: 'error',
                    containerId: dashboardContainerEnum.TOP_PRODUCTS
                })
            } finally {
                setOnLoading(false);
            }
        }
    }, [getTopProducts, clearContainerError, sortField, sortOrientation, setOnError]);

    useEffect(() => {
        getData(0);
    }, [getData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    const onClickSortHandle = useCallback((property: string) => {
        setSortField(property);
        setSortOrientation(prev => prev === 'asc' ? 'desc' : 'asc')
        setCurrentPage(0);
    }, [])

    return (
        <div>
            <RankingList
                list={topProductsData?.records}
                count={topProductsData?.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    name: { title: "Nome" },
                    price: { title: "Preço", formatValue: (value) => moneyMaskNumber(value), onClickSort: onClickSortHandle },
                    quantity: { title: "Qtd", onClickSort: onClickSortHandle, centered: true },
                    total: { title: "Total", formatValue: (value) => moneyMaskNumber(value), onClickSort: onClickSortHandle },
                }}
            />
        </div>
    )
}
export default TopProducts