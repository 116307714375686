import { Drawer, Icon, Switch, TextField } from '@material-ui/core';
import styles from './PublicLinkModal.module.scss';
import { useEffect, useState } from 'react';
import { IUpdateGuestListLinkRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IEventsGuestListLink } from 'modules/events/presentation/pages/eventGuestsLists/interfaces/IEventGuestsListsPage';

interface PublicLinkModalProps {
    open: boolean;
    loading: boolean;
    eventName: string;
    defaultValues?: IEventsGuestListLink;
    onClose: () => void;
    onSubmit: (values: IUpdateGuestListLinkRequest) => void;
}

export const PublicLinkModal = ({ open, loading, eventName, defaultValues, onClose, onSubmit }: PublicLinkModalProps) => {
    const [enabled, setEnabled] = useState(true);
    const [values, setValues] = useState<IUpdateGuestListLinkRequest>({} as IUpdateGuestListLinkRequest);
    const [hasError, setHasError] = useState(false);

    const handleSubmit = () => {
        if (!values.linkDescription) {
            setHasError(true);
            return;
        }

        onSubmit({ ...values, enable: enabled });
    }

    useEffect(() => {
        if (defaultValues) {
            setValues({
                linkName: defaultValues.name,
                linkDescription: defaultValues.description,
                enable: defaultValues.enabled,
                maxPeoplesOnList: defaultValues.maxPeopleList,
                visibleInApp: defaultValues.visibleInApp
            });
            setEnabled(defaultValues.enabled)
        }
    }, [defaultValues])

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Editar link para <b>lista de convidados</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    <div className={styles.checkboxContainer}>
                        <Switch checked={enabled} color="primary" className={styles.checkbox} onChange={() => setEnabled(prev => !prev)} />
                        <span className={styles.text}>Habilitado</span>
                    </div>
                    <TextField
                        disabled
                        value={eventName}
                        size="small"
                        variant='outlined'
                        fullWidth
                    />

                    <label>Descrição da lista de convidados</label>
                    <TextField
                        value={values?.linkDescription}
                        onChange={(ev) => setValues(prev => ({ ...prev, linkDescription: ev.target.value }))}
                        size="small"
                        variant='outlined'
                        fullWidth
                        multiline
                        minRows={5}
                        error={hasError}
                        helperText={"Campo obrigatório"}
                    />

                    <label>Quantidade máxima de CPF pelo link</label>
                    <TextField
                        value={values?.maxPeoplesOnList}
                        onChange={(ev) => setValues(prev => ({ ...prev, maxPeoplesOnList: Number(ev.target.value) }))}
                        size="small"
                        variant='outlined'
                        fullWidth
                    />
                    <span className={styles.description}>
                        Quantidade máxima de convidados que podem ser adicionados por esse link.
                    </span>

                    <div className={styles.checkboxContainer}>
                        <Switch checked={values.visibleInApp} color="primary" className={styles.checkbox} onChange={() => setValues(prev => ({ ...prev, visibleInApp: !prev.visibleInApp }))} />
                        <span className={styles.text}>Exibir lista no aplicativo Meep</span>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Cancelar</button>
                    <button onClick={handleSubmit} disabled={loading}>
                        {
                            loading ? 'Salvando...' : 'Salvar'
                        }
                    </button>
                </div>
            </div>
        </Drawer>
    )
}