import React, { FC } from 'react'
import styles from './ChangePasswordPage.module.scss'
import { TextField } from '@material-ui/core'
import { UseChangePasswordPage } from './UseChangePasswordPage'
import SaasPublicLayout from '../SaasPublicLayout'

export interface IRegisterPageProps {
    //propertys
}

const ChangePasswordPage: FC<IRegisterPageProps> = () => {
    const {
        formLoading,
        password,
        confirmPassword,
        errors,
        setPassword,
        setConfirmPassword,
        handleSubmit,
    } = UseChangePasswordPage();

    return (
        <SaasPublicLayout step={1} totalSteps={6} backgroundColorTop="#6B41C9" backgroundColorBottom="#865FDD">
            <div className={styles.container}>
                <div className={styles.left}>
                    <h1>Alterar <b>senha</b></h1>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Nova senha</label>
                            <TextField
                                variant="outlined"
                                placeholder='Digite sua nova senha'
                                fullWidth
                                size='small'
                                type='password'
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                error={!!errors.password}
                                helperText={errors.password}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label className={styles.required}>Confirmar senha</label>
                            <TextField
                                variant="outlined"
                                placeholder='Digite a confirmação da senha'
                                fullWidth
                                size='small'
                                type='password'
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                            />
                        </div>
                    </div>

                    <button disabled={!password || !confirmPassword || formLoading} className={styles.continueButton} onClick={handleSubmit}>
                        {
                            formLoading ? 'Carregando...' : 'Confirmar'
                        }
                    </button>
                </div>

                {/* <div className={styles.right}>
                    <img src="/assets/icon/saas/form-side-image.png" alt="" />
                </div> */}
            </div>
        </SaasPublicLayout>
    )
}
export default ChangePasswordPage