import { Close } from '@material-ui/icons'
import { Dialog } from '@mui/material'
import { GetProductValuesByServiceIdResponseDTO } from 'modules/schedule/domain/dto/ReserveSchedule/IGetProductValuesByServiceIdResponse'
import React, { FC, useState } from 'react'
import { IServiceCategoryItem, ISubCategoriesItemsResponse } from '../../interface/IMainCategories'
import styles from './ListLinks.module.scss'

interface IListLinksProps {
    value?: IServiceCategoryItem,
    open: boolean,
    onClose: () => void,
    filter: boolean
}

export const ListLinks: FC<IListLinksProps> = ({ value, open, onClose, filter }) => {



    return (
        <Dialog open={open} >
            <div id={styles.ListLinks}>
                <div className={styles.containerModal}>

                    <div className={styles.containerTitle}>
                        <h1>Vínculos</h1>
                        <Close onClick={onClose} />
                    </div>

                    {
                        value &&


                        <div className={styles.containerMain}>
                            {
                                filter ?
                                    <>
                                        <p>Sub-categorias vículadas: </p>
                                        {


                                            value.subCategories.length ? value.subCategories.map((item, index) =>
                                            (
                                                <div className={styles.modalBonds} key={index}>
                                                    <p>{item.name}</p>
                                                </div>
                                            )
                                            )
                                                :
                                                <span>Não existem sub-categorias vínculadas a está categoria</span>
                                        }

                                    </>
                                    :
                                    ""
                            }
                            <p>Serviços vículados: </p>
                            {
                                value.services.length ? value.services.map((item, index) =>
                                (
                                    <div className={styles.modalBonds} key={index}>
                                        <p>{item.name}</p>
                                    </div>
                                )
                                )
                                    :
                                    <span> Não existem serviços vínculados a está categoria </span>

                            }
                        </div>
                    }
                </div>

            </div>
        </Dialog>
    )
}
