import React, { FC, useRef } from "react";
import styles from "./CloseEventPage.module.scss";
import { CloseHeader } from "./closeHeader/CloseHeader";
import { Details } from "./components/details/Details";
import { ProductConsumption } from "./components/productConsumption/ProductConsumption";
import { Resume } from "./components/resume/Resume";
import { SystemTypeResum } from "./components/systemTypeResum/SystemTypeResum";
import { Tickets } from "./components/tickets/Tickets";
import UseCloseEventPage from "./UseCloseEventPage";

interface ICloseEventPageProps {}

export const CloseEventPage: FC<ICloseEventPageProps> = ({}) => {
  const {
    event,
    handleIsExport,
    handleShowDetails,
    handleShowResume,
    handleShowSystemType,
    handleShowTickets,
    isExport,
    showDetails,
    showResume,
    showSystemType,
    showTickets,
    breakDetails,
    breakSystemType,
    breakTickets,
    showProductConsumption,
    getEvent,
    renderPercentage
  } = UseCloseEventPage();

  const componentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div id={styles.CloseEventPage}>
      {event && (
        <div ref={componentRef}>
          <CloseHeader
            event={event}
            componentRef={componentRef}
            handleShowButtons={handleIsExport}
            handleShowDetails={handleShowDetails}
            handleShowResume={handleShowResume}
            handleShowSystemType={handleShowSystemType}
            handleShowTickets={handleShowTickets}
            isExport={isExport}
          />
          {showResume && <Resume event={event} isExport={isExport} renderPercentage={renderPercentage} />}
          {showProductConsumption && (
            <ProductConsumption event={event} isExport={isExport} renderPercentage={renderPercentage} />
          )}
          {showTickets && (
            <Tickets
              event={event}
              isExport={isExport}
              pageBreak={breakTickets}
              renderPercentage={renderPercentage}
            />
          )}
          {showSystemType && (
            <SystemTypeResum
              event={event}
              isExport={isExport}
              breakSystemType={breakSystemType}
              refreshEvent={getEvent}
            />
          )}
          {showDetails && (
            <Details
              event={event}
              isExport={isExport}
              pageBreak={breakDetails}
            />
          )}
        </div>
      )}
    </div>
  );
};
