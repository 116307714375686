import { AxiosInstance } from "axios"
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";

const ObterSaldoContaDigital = async (api: AxiosInstance, ownerId: string, digitalAccountId: string): Promise<DigitalAccountBalanceResponse> => {
    const response = await api.get<SaldoContaDigital>(`/ContaDigital/BuscarSaldoDaContaBancaria/${ownerId}/${digitalAccountId}`, { params: { disableError: true }});

    return {
        balanceResult: {
            balanceAvailable: response.data.saldoResult.SaldoDisponivel,
            balanceToRelease: response.data.saldoResult.SaldoALiberar,
            withheldBalance: response.data.saldoResult.SaldoRetido,
            totalBalanceRetained: response.data.saldoResult.SaldoTotalAReter,
            tedFee: response.data.saldoResult.TaxaTED,
            thirdPartyFeePercentage: response.data.saldoResult.PercentualTaxaTEDTerceiros,
            date: response.data.saldoResult.DataSaldo,
        },
        receiptForm: {
            intervalId: response.data.formaRecebimento.IntervaloId,
            intervalDescription: response.data.formaRecebimento.Intervalo,
            day: response.data.formaRecebimento.Dia,
        },
    };
};

export default ObterSaldoContaDigital;

export interface SaldoContaDigital {
    saldoResult: SaldoResult
    formaRecebimento: FormaRecebimento
}

export interface SaldoResult {
    SaldoDisponivel: number
    SaldoALiberar: number
    SaldoRetido: number
    SaldoTotalAReter: number
    TaxaTED: number
    PercentualTaxaTEDTerceiros: number
    DataSaldo: string
}

export interface FormaRecebimento {
    IntervaloId: number
    Intervalo: string
    Dia: string
}
