import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { ICrmFilterValue } from '../components/crmFilterForm/interfaces/ICrmFilterValue';
import { ICrmCustomerData } from '../intefaces/CrmUserData';

interface ISelectCustomerContext {
    selectedCustomer: ICrmCustomerData[]
    selectedException: ICrmCustomerData[]
    filteredCustomer?: ICrmFilterValue
    customerQuantity: number
    selectedAll: boolean;
    selectAll: () => void
    unSelectAll: () => void
    onChangeFilter: (filter: ICrmFilterValue, total: number) => void
    isChecked: (customer: ICrmCustomerData) => boolean
    onCheckItem: (customerItem: ICrmCustomerData, checked: boolean) => void
}

const SelectCrmCustomerContext = createContext<ISelectCustomerContext>({} as ISelectCustomerContext);

export const SelectCrmCustomerProvider: FC<{ currentFilter?: ICrmFilterValue }> = ({ children, currentFilter }) => {


    const [selectedCustomer, setSelectedCustomer] = useState<ICrmCustomerData[]>([]);
    const [selectedException, setSelectedException] = useState<ICrmCustomerData[]>([]);
    const [filteredCustomer, setFilteredCustomer] = useState<ICrmFilterValue>();
    const [totalCountFiltered, setTotalCountFiltered] = useState<number>(0);

    const [selectedAll, setSelectedAll] = useState(false);

    const selectAll = useCallback(() => {
        setSelectedCustomer([]);
        setSelectedAll(true);
    }, [])
    const unSelectAll = useCallback(() => {
        setSelectedException([])
        setSelectedAll(false);
    }, [])


    const onChangeFilter = useCallback((filter: ICrmFilterValue, total: number) => {
        setTotalCountFiltered(total)
        setFilteredCustomer(filter)
    }, []);

    const selectCustomer = useCallback((customer: ICrmCustomerData) => {
        setSelectedCustomer(prev => [...prev, customer])
    }, []);

    const unselectCustomer = useCallback((customer: ICrmCustomerData) => {
        setSelectedException([]);
        setSelectedCustomer(prev => prev.filter(item => item.id !== customer.id))
    }, []);

    const selectExeptionCustomer = useCallback((customer: ICrmCustomerData) => {
        setSelectedCustomer([])
        setSelectedException(prev => [...prev, customer])
    }, []);

    const unselectExeptionCustomer = useCallback((customer: ICrmCustomerData) => {
        setSelectedCustomer([])
        setSelectedException(prev => prev.filter(item => item.id !== customer.id))
    }, []);

    const isChecked = useCallback((customer: ICrmCustomerData) => {
        if (selectedAll) {
            return !selectedException.find(item => item.id === customer.id);
        } else {
            return !!selectedCustomer.find(item => item.id === customer.id);
        }
    }, [selectedAll, selectedCustomer, selectedException])

    const customerQuantity = useMemo(() => {
        if (selectedAll) {
            return (totalCountFiltered - selectedException.length)
        } else {
            return selectedCustomer.length
        }
    }, [selectedAll, selectedCustomer.length, selectedException.length, totalCountFiltered])


    const onCheckItem = useCallback((customerItem: ICrmCustomerData, checked: boolean) => {
        if (selectedAll) {
            if (checked) {
                unselectExeptionCustomer(customerItem);
            } else {
                selectExeptionCustomer(customerItem);
            }
        } else {
            if (checked) {
                selectCustomer(customerItem);
            } else {
                unselectCustomer(customerItem)
            }
        }
    }, [selectCustomer, selectExeptionCustomer, selectedAll, unselectCustomer, unselectExeptionCustomer])


    return (
        <SelectCrmCustomerContext.Provider value={{
            selectedCustomer,
            selectedException,
            filteredCustomer,
            customerQuantity,
            selectedAll,
            selectAll,
            unSelectAll,
            onChangeFilter,
            isChecked,
            onCheckItem
        }}>
            <>{children}</>
        </SelectCrmCustomerContext.Provider>
    );
};


export const useSelectCrmCustomer = () => {
    const context = useContext(SelectCrmCustomerContext);
    return context;
};