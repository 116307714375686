import { Skeleton } from '@material-ui/lab'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { FC, useEffect, useMemo } from 'react'
import LocalFilter from './components/localFilter/LocalFilter'
import LocalCardItem from './components/localItem/LocalItem'
import styles from './LocalPage.module.scss'
import UseLocalPage from './UseLocalPage'
import { Pagination } from '@mui/material'
export interface ILocalPageProps {
    //propertys
}

const LocalPage: FC<ILocalPageProps> = () => {
    const { isLoading, locals, onClickItemHandle, filter, onChangePage, onSubmitFilterHandle } = UseLocalPage();

    const countPage = useMemo(() => {
        return Math.ceil((locals?.count ?? 1) / (filter.pageSize ?? 1))
    }, [filter.pageSize, locals?.count])

    const loading = useMemo(() => (<>
        <div className={styles.list}>
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
            <Skeleton className={styles.loading} height={130} />
        </div>
    </>), [])

    const { clearBreadCumbs } = useBreadcumbs();

    useEffect(() => {
        clearBreadCumbs();
    }, [clearBreadCumbs]);

    const localList = useMemo(() => (<div className={styles.list}>
        {locals?.items?.map((item, index) => <LocalCardItem key={index} item={{ ...item }} onClick={onClickItemHandle} />)}
    </div>), [locals?.items, onClickItemHandle]);

    return (
        <div id={styles.LocalPage} >
            {!isLoading &&
                <>
                    <div className={styles.header}>
                        <h2>Locais</h2>  <LocalFilter onSubmit={onSubmitFilterHandle} />
                    </div>
                    <div className={styles.container} >
                        {!!locals?.items.length ?
                            <>
                                {localList}
                                {filter && locals?.count > 12 &&
                                    <Pagination
                                        className={styles.pagination}
                                        count={countPage}
                                        page={filter.page}
                                        onChange={(_, page) => onChangePage(page)}
                                        variant="outlined"
                                        shape="rounded"
                                        style={{ alignSelf: 'flex-end' }}
                                        sx={{
                                            '.MuiPaginationItem-root': {
                                                borderColor: '#BDBDBD',
                                                color: '#001537',
                                            },
                                            '.Mui-selected': {
                                                fontWeight: 'bold',
                                                borderColor: '#001537',
                                            },
                                        }}
                                    />
                                }
                            </>
                            :
                            <div className={styles.containerNotFound}>
                                <img src={'/assets/img/notfound.png'} alt="Nenhum local encontrado!" />
                                <p className={styles.text}>Nenhum local encontrado!</p>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}
export default LocalPage;