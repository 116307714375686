import { ICollaboratorApi } from "modules/schedule/application/interfaces/api/ICollaboratorApi"
import { AddCollaboratorScheduleRequestDto } from "modules/schedule/domain/dto/collaboratorSchedule/AddCollaboratorScheduleDto"
import { EditCollaboratorScheduleDto } from "modules/schedule/domain/dto/collaboratorSchedule/EditCollaboratorScheduleDto"
import { GetCollaboratorScheduleDto } from "modules/schedule/domain/dto/collaboratorSchedule/GetCollaboratorScheduleDto"
import { GetCollaboratorsScheduleResponseDto } from "modules/schedule/domain/dto/collaboratorSchedule/GetCollaboratorsScheduleDto"
import { GetOperatorsResponseDto } from "modules/schedule/domain/dto/collaboratorSchedule/GetOperatorsResponseDto"
import { IGetServiceScheduleByLocalIdResponseDTO } from "modules/schedule/domain/dto/placeSchedule/IGetServiceScheduleByLocalIdResponse"
import { api, Api } from "services/api/Api"

const ColaboratorScheduleApi = (): ICollaboratorApi => {


    const getServicesScheduleIdsByLocalId = async (localId: string): Promise<IGetServiceScheduleByLocalIdResponseDTO> => {
        const response = await api.get<IGetServiceScheduleByLocalIdResponseDTO>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: true } });
        return response.data;
    }

    const getOperators = async (localId: string): Promise<GetOperatorsResponseDto[]> => {
        // const response = await api.get<{ id: string, nome: string }[]>(`/collaboratorSchedule/GetCollaboratorsByLocalClientId/${localId}`)
        const response = await api.get<{ id: string, nome: string }[]>(`/Proprietario/BuscarOperadoresDoLocal/${localId}`)
        return response.data.map((item) => ({ id: item.id, name: item.nome }))
    }
    const getCollaborator = async (id: string): Promise<GetCollaboratorScheduleDto> => {
        const response = await api.get<GetCollaboratorScheduleDto>(`collaboratorSchedule/ById/${id}`);
        return response.data
    }

    const getCollaboratorsByLocalId = async (localId: string): Promise<GetCollaboratorsScheduleResponseDto> => {
        const response = await api.get<GetCollaboratorsScheduleResponseDto>(`/collaboratorSchedule/GetCollaboratorsScheduleByFilter/${localId}`);
        return response.data
    }
    const addCollaborator = async (request: AddCollaboratorScheduleRequestDto): Promise<void> => {
        const response = await api.post<void>(`/collaboratorSchedule`, request);
        return response.data
    }
    const editColaborator = async (request: EditCollaboratorScheduleDto): Promise<void> => {
        const response = await api.put<void>(`/collaboratorSchedule`, request);
        return response.data
    }
    const deleteColaborator = async (id: string): Promise<void> => {
        const response = await api.delete<void>(`/collaboratorSchedule/${id}`);
        return response.data
    }

    return ({
        getOperators,
        addCollaborator,
        getServicesScheduleIdsByLocalId,
        editColaborator,
        getCollaborator,
        getCollaboratorsByLocalId,
        deleteColaborator
    })
}
export default ColaboratorScheduleApi