import { ICrmService } from "modules/crm/models/interface/ICrmService";

const PostSaveFilter = async (
  service: ICrmService,
  ownerId: string,
  name: string,
  body: string,
) => {
  const response = service.saveFilterClients({ name, body, ownerId });

  return response;
};

export default PostSaveFilter;
