import { useCallback, useMemo } from "react";
import { Pagination } from "@material-ui/lab";
import { Skeleton } from "@mui/material";
import { IconButton } from "@material-ui/core";
import styles from './PushApprovalPage.module.scss'
import { UsePushApprovalPage } from "./UsePushApprovalPage";
import { CampaignStatus } from "modules/crm/presentation/intefaces/ICampaign";
import { format } from "date-fns";
import { ApproveDrawer } from "../components/approveDrawer/ApproveDrawer";
import UseDimension from "components/dimension/UseDimension";
import { NavigateNext } from "@material-ui/icons";

export const PushApprovalPage = () => {
    const {
        getData,
        selectedCampaign,
        campaignsList,
        isLoading,
        approvalLoading,
        filter,
        page,
        reproving,
        reason,
        setReason,
        setReproving,
        setPage,
        handleSelectCampaign,
        handleApprove,
        handleReprove,
        handleFilter,
    } = UsePushApprovalPage();
    const { dimensions } = UseDimension();

    const getDataHandle = useCallback((_page: number) => {
        setPage(_page)
        getData({ pagination: { page: _page }, _filter: filter })
    }, [filter, getData, setPage])

    const getStatusText = (status: CampaignStatus) => {
        switch (status) {
            case CampaignStatus.Approved:
                return 'Aprovado';
            case CampaignStatus.Reproved:
                return 'Recusado';
            case CampaignStatus.Pending:
                return 'Aguardando aprovação';
            case CampaignStatus.Sent:
                return 'Enviado';
            default:
                return '-';
        }
    }

    const titleHeader = ["Estabelecimento", "Campanha", "Data de envio", "Status", "Destinatários"];

    const List = useMemo(() => {
        return (!isLoading
            ? (!!campaignsList?.data.length 
                ? campaignsList?.data.map((item) => {
                    return (
                        <>
                            {dimensions.width > 650 
                                ? (
                                    <div className={styles.item} key={item.id}>
                                        <span>{item.ownerName}</span>
                                        <span>{item.name}</span>
                                        <span>{item.deliveryTime ? format(new Date(item.deliveryTime + "Z"), 'dd/MM/yyyy - HH:mm') : "Não enviado"}</span>
                                        <span>{getStatusText(item.status)}</span>
                                        <span>{item.totalCustomers}</span>
                                        <span>
                                            {
                                                (item.status === CampaignStatus.Approved || item.status === CampaignStatus.Pending || item.status === CampaignStatus.Reproved) &&
                                                <IconButton size="small" onClick={() => handleSelectCampaign(item)}><NavigateNext /></IconButton>
                                            }
                                        </span>
                                    </div>
                                )
                                : (
                                    <div className={styles.itemMobile} key={item.id}>
                                        <div className={styles.row}>
                                            <span className={styles.title}>Estabelecimento</span>
                                            <div>
                                                <span>{item.ownerName}</span>
                                                {
                                                    (item.status === CampaignStatus.Approved || item.status === CampaignStatus.Pending || item.status === CampaignStatus.Reproved) &&
                                                    <IconButton size="small" onClick={() => handleSelectCampaign(item)}><NavigateNext fontSize="small" /></IconButton>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <span className={styles.title}>Campanha</span>
                                            <span>{item.name}</span>
                                        </div>
                                        <div className={styles.row}>
                                            <span className={styles.title}>Data de envio</span>
                                            <span>{item.deliveryTime ? format(new Date(item.deliveryTime + "Z"), 'dd/MM/yyyy - HH:mm') : "Não enviado"}</span>
                                        </div>
                                        <div className={styles.row}>
                                            <span className={styles.title}>Status</span>
                                            <span>{getStatusText(item.status)}</span>
                                        </div>
                                        <div className={styles.row}>
                                            <span className={styles.title}>Destinatários</span>
                                            <span>{item.totalCustomers}</span>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    );
                }) : (
                    <div className={styles.noData}>
                        <span>Nenhum dado encontrado</span>
                    </div>
                )
            ) : (
                <>
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </>
            )
        );
    }, [campaignsList?.data, dimensions.width, handleSelectCampaign, isLoading]);

    return (
        <div id={styles.PushApprovalPage}>
            <div className={styles.filterContainer}>
                <label>Status</label>

                <div>
                    <button disabled={isLoading} onClick={() => handleFilter(CampaignStatus.Pending)} className={filter.find(x => x === CampaignStatus.Pending) ? styles.selected : ''}>Aguardando aprovação</button>
                    <button disabled={isLoading} onClick={() => handleFilter(CampaignStatus.Sent)} className={filter.find(x => x === CampaignStatus.Sent) ? styles.selected : ''}>Enviado</button>
                    <button disabled={isLoading} onClick={() => handleFilter(CampaignStatus.Reproved)} className={filter.find(x => x === CampaignStatus.Reproved) ? styles.selected : ''}>Recusado</button>
                    <button disabled={isLoading} onClick={() => handleFilter(CampaignStatus.Approved)} className={filter.find(x => x === CampaignStatus.Approved) ? styles.selected : ''}>Aprovado</button>
                </div>
            </div>
            <div className={styles.table}>
                {dimensions.width > 650 && 
                    <div className={styles.header}>
                        {titleHeader.map((item) => (<div>{item}</div>))}
                    </div>
                }
                {List}
            </div>

            <Pagination
                page={page}
                color="primary"
                className={styles.pagination}
                defaultPage={campaignsList?.currentPage}
                count={campaignsList?.totalPages}
                onChange={(ev, page) => getDataHandle(page)}
            />

            <ApproveDrawer
                open={!!selectedCampaign}
                onClose={() => handleSelectCampaign(undefined)}
                onApprove={(campaignId) => handleApprove(campaignId)}
                onReprove={(campaignId, reason) => handleReprove(campaignId, reason)}
                campaign={selectedCampaign}
                loading={approvalLoading}
                reproving={reproving}
                reason={reason}
                setReason={setReason}
                setReproving={setReproving}
            />
        </div>
    );
}
