import { Icon } from "@material-ui/core";
import React, { FC } from "react";
import InputSearch from "../inputSearch/InputSearch";
import styles from "./PageHeader.module.scss";

interface IPageHeaderProps {
  title?: string;
  onSearchChange?: (value: string | number) => void;
  autoSearch?: boolean;
  onFilterClick?: () => void;
  childrenStyle?: string;
}

const PageHeader: FC<IPageHeaderProps> = ({
  title,
  autoSearch,
  onSearchChange,
  onFilterClick,
  children,
  childrenStyle
}) => {
  return (
    <div id={styles.PageHeader}>
      <div className={styles.container}>
        <h1>{title ?? ""}</h1>
        <div className={styles.searchContainer}>
          {onSearchChange && (
            <InputSearch
              onChange={onSearchChange}
              className={styles.search}
              autoSearch={autoSearch}
            />
          )}
          {onFilterClick && (
            <button className={styles.filterButton} onClick={onFilterClick}>
              <p>Filtro</p>
              <Icon className={styles.filterIcon}>sort</Icon>
            </button>
          )}
          <div className={childrenStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
