import { format } from "date-fns";
import styles from "./TableItem.module.scss";
import { SearchAnticipateResponse } from "modules/contaDigital/models/dtos/SearchAnticipateRequest";
import { formatarValor } from "../../pages/utils";

interface ITableItem {
  item: SearchAnticipateResponse; 
}

const TableItem = ({
  item,
}: ITableItem) => {
  return (
    <>
      <div id={styles.TableItem}>
        <span>{item.contaBancaria}</span>
        <span>{format(new Date(item.dataSolicitacao), 'dd/MM/yy')}</span>
        <span>{item.usuarioMeepNome}</span>
        <span>{format(new Date(item.dataRecebimento), 'dd/MM/yy')}</span>
        <span>{item.statusDescricao}</span>
        <span>{formatarValor(item.taxaAntecipacao)}</span>
        <span>{formatarValor(item.taxaProcessamento)}</span>
        <span>{formatarValor(item.valorSolicitado)}</span>
      </div>
    </>
  );
};
export default TableItem;
