import { IPostMobiConfigRequest } from "modules/config/integrationsConfig/domain/dto/MobiConfig/IPostMobiConfigRequest";

export interface IMobiFormErrors {
	UsuarioNFCe?: string;
	SenhaNFCe?: string;
}

export const MobiValidation = (formData: IPostMobiConfigRequest, setErrors: React.Dispatch<React.SetStateAction<IMobiFormErrors>>) => {
  let _errors: IMobiFormErrors = {};
  let hasError = false;

  if (!formData.UsuarioNFCe) {
    _errors = { ..._errors, UsuarioNFCe: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SenhaNFCe) {
    _errors = { ..._errors, SenhaNFCe: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
