import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import AntecipatePage from "modules/contaDigital/presentation/pages/antecipate/AntecipatePage";
import { FC, useEffect } from "react";

const Antecipate: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Conta Digital",
      },
      {
        label: "Conta Digital - Antecipar",
        title: "Antecipar",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <AntecipatePage />
    </Layout>
  );
};
export default Antecipate;
