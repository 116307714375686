import { Button, Switch } from "@mui/material";
import {
  EnumOperationType,
  ICouponForm,
} from "modules/config/discountCoupons/presentation/interfaces/ICouponForm";
import React, { useCallback } from "react";
import { EnumCouponStep } from "../../CouponForm";
import styles from "./OperationForm.module.scss";

export interface IOperationFormProps {
  values: ICouponForm;
  changeHandleChecked: (value: EnumOperationType) => void;
  onChangeFormStep: (value: EnumCouponStep) => void;
}

export const OperationForm: React.FC<IOperationFormProps> = ({
  values,
  changeHandleChecked,
  onChangeFormStep,
}) => {
  return (
    <div id={styles.OperationForm}>
      <div className={styles.content}>
        <span>
          Em qual operação você deseja que seu cupom de desconto funcione?
        </span>
        <div className={styles.item}>
          <Switch
            color="secondary"
            checked={
              !!values.operations.find(
                (item) => item === EnumOperationType.TICKET
              )
            }
            name="operations"
            onChange={(ev) => changeHandleChecked(EnumOperationType.TICKET)}
          />{" "}
          Ficha
        </div>
        <div className={styles.itemPrePaid}>
          <div>
            <Switch
              color="secondary"
              checked={
                !!values.operations.find(
                  (item) => item === EnumOperationType.PREPAIDCASHLESS
                )
              }
              name="operations"
              onChange={(ev) =>
                changeHandleChecked(EnumOperationType.PREPAIDCASHLESS)
              }
            />{" "}
            Pré-pago cashless
          </div>
          <span>Na operação pré-pago o cupom é aplicado na recarga.</span>
        </div>
        <div className={styles.item}>
          <Switch
            color="secondary"
            checked={
              !!values.operations.find(
                (item) => item === EnumOperationType.POSTPAIDINDIVIDUAL
              )
            }
            name="operations"
            onChange={(ev) =>
              changeHandleChecked(EnumOperationType.POSTPAIDINDIVIDUAL)
            }
          />{" "}
          Pós-pago individual
        </div>
        <div className={styles.item}>
          <Switch
            color="secondary"
            checked={
              !!values.operations.find(
                (item) => item === EnumOperationType.POSTPAIDSHARED
              )
            }
            name="operations"
            onChange={(ev) =>
              changeHandleChecked(EnumOperationType.POSTPAIDSHARED)
            }
          />{" "}
          Pós-pago compartilhado
        </div>
      </div>

      <div className={styles.containerButton}>
        <Button
          variant="outlined"
          className={styles.outlined}
          onClick={() => onChangeFormStep(EnumCouponStep.SEGMENTACAO)}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          className={styles.contained}
          onClick={() => onChangeFormStep(EnumCouponStep.EQUIPAMENTOS)}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};
