import { ITicketService } from 'modules/events/application/interfaces/ITicketService';
import { ICreateTicketRequest, IUpdateTicketRequest } from 'modules/events/domain/dto/IEventsRequest';
import { ITicketListResponse, ITicketResponse } from 'modules/events/domain/dto/IEventsResponse';
import { api } from 'services/api/Api';

const TicketsApi = (): ITicketService => {
  const list = async (eventId: string, page?: number, pageSize?: number): Promise<ITicketListResponse> => {
    let query = `?page=${page || '0'}`;
    query += `&pageSize=${pageSize || '20'}`;
    const result = await api.get(`SuperApp/Event/${eventId}/Tickets${query}`);
    return result.data;
  };

  const get = async (eventId: string, ticketId: string): Promise<ITicketResponse> => {
    const result = await api.get(`SuperApp/Event/${eventId}/Ticket/${ticketId}`);
    return result.data;
  };

  const create = async (eventId: string, ticket: ICreateTicketRequest): Promise<string> => {
    const result = await api.post(`/SuperApp/Event/${eventId}/ticket`, ticket);
    return result.data;
  };

  const update = async (eventId: string, ticketId: string, ticket: IUpdateTicketRequest): Promise<string> => {
    const result = await api.put(`/SuperApp/Event/${eventId}/ticket/${ticketId}`, ticket);
    return result.data;
  };

  const deleteTicket = async (eventId: string, ticketId: string, localId: string): Promise<void> => {
    const result = await api.delete(`/SuperApp/Event/${eventId}/ticket/${ticketId}?LocalClienteId=${localId}`);
    return result.data;
  };

  const activate = async (eventId: string, ticketId: string, localId: string): Promise<void> => {
    const result = await api.put(`/SuperApp/Event/${eventId}/ticket/${ticketId}/Activate?LocalClienteId=${localId}`,{},{ params: { disableError: true }});
    return result.data;
  };
  
  const deactivate = async (eventId: string, ticketId: string, localId: string): Promise<void> => {
    const result = await api.put(`/SuperApp/Event/${eventId}/ticket/${ticketId}/Deactivate?LocalClienteId=${localId}`,{},{ params: { disableError: true }});
    return result.data;
  };

  return {
    list, 
    get, 
    create, 
    update,
    deleteTicket,
    activate,
    deactivate,
  };
};

export default TicketsApi;
