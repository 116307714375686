import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TabPage from '../dashboard/tabPage/TabPage'

export interface IDonationsPageProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    value?: string,
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        role: string;
    }[]
}

const DonationsPage: FC<IDonationsPageProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const currentTab = menuItems.find(item => tab === item.tab);
        const routers = [{
            label: "Doações",
            title: currentTab?.title
        }];

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.title : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.title
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters]);

    const menuItems:IMenuItem[] = [
        {
            title: "Eventos",
            value: "/doacoes/eventos",
            tab: "eventos",
            role: ""
        },
        {
            title: "Instituições",
            value: "/doacoes/instituicoes",
            tab: "instituicoes",
            role: ""
        },
        {
            title: "Links",
            value: "/doacoes/links",
            tab: "links",
            role: ""
        },
        {
            title: "Relatório de doadores",
            value: "/doacoes/relatorio",
            tab: "relatorio",
            role: ""
        },
        {
            title: "Relatório de doações",
            value: "/doacoes/relatoriodedoacoes",
            tab: "relatoriodedoacoes",
            role: ""
        }
    ];

    const subTabComponent = useCallback((tabItem: IMenuItem) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/doacoes/" + tabItem.tab}
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.role,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        /> : tabItem.component || <IframePage src={tabItem.value!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/doacoes'}
            tabsPages={menuItems.map((tabItem) => (
                {
                    label: tabItem.title,
                    tab: tabItem.tab,
                    roles: tabItem.role,
                    component: subTabComponent(tabItem)
                }
            ))} />
    );
}
export default DonationsPage;
