import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const DeleteDiscountUseCase = async (
  baseListService: IDiscountBaseList,
  discountId: string,
  localId: string,
): Promise<void> => {
  return await baseListService.deleteDiscount(discountId, localId);
};

export default DeleteDiscountUseCase;
