import UseDimension from 'components/dimension/UseDimension'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IHouseFlow, IOrderPadsItem } from '../../interfaces/IHouseFlow'
import { IHouseFlowColumnsConfig } from './interfaces/IColumnsConfig'
import styles from './HouseFlowList.module.scss'
import CrmHeader from './houseFlowContainer/HouseFlowHeader'
import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material'
import HouseFlowItem from './houseFlowContainer/HouseFlowItem'
import { ParamsClientHouseFlow } from '../../hooks/UseHouseFlowList'
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage'
import { IHouseFlowFilter } from '../../components/houseFlowFilter/components/interfaces/IHouseFlowFilter'

export interface IHouseFlowListProps {
    houseFlowList: IHouseFlow | undefined;
    isLoading: boolean | undefined;
    pagination?: { page?: number; pageSize?: number };
    onChangePagination: (value: { page?: number; pageSize?: number }) => void;
    params: ParamsClientHouseFlow | undefined;
    setParams: React.Dispatch<React.SetStateAction<ParamsClientHouseFlow>>
    values: IHouseFlowFilter | undefined
    setValues: React.Dispatch<React.SetStateAction<IHouseFlowFilter | undefined>>
}

export const HouseFlowList: React.FC<IHouseFlowListProps> = ({ houseFlowList, isLoading, pagination, onChangePagination, params, setParams, values, setValues }) => {

    const { dimensions } = UseDimension();    

    const [colunms, setColunms] = useState<IHouseFlowColumnsConfig[]>([
        {
            property: "cardStatus",
            title: "",
            style: { maxWidth: 12 }
        },
        {
            property: "cardNumber",
            title: "Comanda",
        },
        {
            property: "customerName",
            title: "Cliente",

        },
        {
            property: "customerCPF",
            title: "CPF",
        },
        {
            property: "cardOrders",
            title: "Quantidade de pedidos",

        },
        {
            property: "cardTotalNet",
            title: "Valor dos pedidos",
            style: {margin: "0 25px"}
        },
    ]);

    const columnsLimit = useCallback((_columns: IHouseFlowColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width <= 600) {
                    return 2
                } else if (width <= 800) {
                    return 3
                } else if (width <= 1024) {
                    return 5
                } else {
                    return 6
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return item;
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])

    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, houseFlowList])

    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePagination({ ...pagination, page: page - 1, pageSize });
        },
        [onChangePagination, pagination]
    );

    const List = useMemo(
        () => (
            <div className={styles.list}>                
                <CrmHeader
                    columns={colunms}
                    params={params}
                    setParams={setParams}
                    values={values}
                    setValues={setValues}
                />
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    houseFlowList?.records?.map((item: IOrderPadsItem, index) => {
                        return (
                            <HouseFlowItem
                                key={index}
                                userItemData={item}
                                columns={colunms}    
                                houseFlowList={houseFlowList}                          
                            />
                        );
                    })
                )}
            </div>
        ),
        [colunms, houseFlowList, isLoading, params, setParams, setValues, values]
    );

    const countPage = useMemo(() => Math.ceil((houseFlowList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [houseFlowList?.totalRecords, pagination?.pageSize])

    return (
        <div className={styles.container}>
            {
                !isLoading && !houseFlowList?.records?.length 
                ? <ErrorMessage type="warning" /> 
                : (
                    <>
                        {List}
                        <div className={styles.conteinerPagination}>
                            <div className={styles.boxMultiplePage}>
                                <span>Exibir</span>
                                <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                    <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                    <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                    <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                </TextField>
                            </div>
                            <Pagination
                                variant="outlined"
                                className={styles.paginationNumber}
                                shape="rounded"
                                count={countPage}
                                sx={{
                                    '.MuiPaginationItem-root': {
                                        borderColor: '#001537',
                                        color: '#001537',
                                    },
                                    '.Mui-selected': {
                                        fontWeight: 'bold',
                                    },
                                }}
                                // onChange={(_, value) => onChangePageHandle(value) }
                                onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                page={((pagination?.page ? pagination?.page + 1 : 1))}
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}
