import { IPosSolicitationsService } from "../domain/interface/IPosSolicitationsService";
import { IGetPosSolicitations } from "../domain/dto/IGetPosSolicitations";
import { IFilterParams } from "../presentation/interface/IFilterParams";

const GetExternalPosSolicitationsUseCase =
  async ( service: IPosSolicitationsService, filterParams: IFilterParams ): Promise<IGetPosSolicitations> => {
    const response = service.getExternalPosSolicitations({ ...filterParams, page: filterParams.page ?? 1, pageSize: filterParams.pageSize ?? 10 });
    return response;
};

export default GetExternalPosSolicitationsUseCase;
