import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { FC } from 'react';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import styles from './BaseForm.module.scss';
import Input from 'components/ui/input/Input';
import { ITicketForm, ITicketFormErrors } from '../../ITicketForm';
import { FormControlLabel, Radio } from '@material-ui/core';
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';
import { IMG } from 'services/utils/IMG';
import InputMasked from 'components/inputMasked/InputMasked';

interface ITicketFormProps {
    errors: ITicketFormErrors;
    ticket: ITicketForm;
    eventName: string;
    onChangeTicketFormValue: (prop: string, value: string | number) => void;
}

export const TicketBaseForm: FC<ITicketFormProps> = ({
    errors,
    ticket,
    eventName,
    onChangeTicketFormValue
}) => {

    return (
        <div id={styles.BaseForm}>
            <span className={styles.eventName}>Evento: <span>{eventName}</span></span>


            <FormItemContainer label={'Sexo do ingresso'} className={`${styles.formItem} ${styles.radioContainer}`}>
                <FormControlLabel
                    value={ticket.genderRestriction}
                    control={<Radio />}
                    label={'Masculino'}
                    checked={ticket.genderRestriction === 1}
                    onChange={(ev, checked) => {
                        if (checked) {
                            onChangeTicketFormValue('genderRestriction', 1)
                        }
                    }}
                />
                <FormControlLabel
                    value={ticket.genderRestriction}
                    control={<Radio />}
                    label={'Feminino'}
                    checked={ticket.genderRestriction === 2}
                    onChange={(ev, checked) => {
                        if (checked) {
                            onChangeTicketFormValue('genderRestriction', 2)
                        }
                    }}
                />
                <FormControlLabel
                    value={ticket.genderRestriction}
                    control={<Radio />}
                    label={'Não informado'}
                    checked={ticket.genderRestriction === 0 || ticket.genderRestriction === null}
                    onChange={(ev, checked) => {
                        if (checked) {
                            onChangeTicketFormValue('genderRestriction', 0)
                        }
                    }}
                />
            </FormItemContainer>


            <FormItemContainer label={'Nome do ingresso'} className={styles.formItem}>
                <Input
                    name="nome"
                    variant='outlined'
                    value={ticket.name}
                    className={styles.input}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                    onChange={evt => onChangeTicketFormValue('name', evt.target.value)}
                    error={!!errors.name}
                />
            </FormItemContainer>
            <FormItemContainer label={'Valor'} className={styles.formItem}>
                <Input
                    name={'valor'}
                    variant='outlined'
                    value={moneyMaskNumber(ticket.price)}
                    className={styles.input}
                    helperText={errors.price}
                    required
                    onChange={ev => {
                        onChangeTicketFormValue('price', moneyToFloat(ev.target.value))
                    }
                    }
                    error={!!errors.price}
                />
            </FormItemContainer>
            <FormItemContainer label={'NCM'} className={styles.formItem}>
                <InputMasked
                    name={'ncm'}
                    variant='outlined'
                    mask="9999.99.99"
                    value={ticket.ncm}
                    className={styles.input}
                    helperText={errors.ncm}
                    error={!!errors.ncm}
                    onChange={(ev: any) => {
                        onChangeTicketFormValue('ncm', ev.target.value)
                    }

                    }
                />
            </FormItemContainer>
            <FormItemContainer label={'CEST'} className={styles.formItem}>
                <InputMasked
                    name={'cest'}
                    variant='outlined'
                    mask="99.999.99"
                    value={ticket.cest}
                    className={styles.input}
                    onChange={(ev: any) => {
                        onChangeTicketFormValue('cest', ev.target.value)
                    }
                    }
                />
            </FormItemContainer>
            <FormItemContainer label={'CFOP'} className={styles.formItem}>
                <InputMasked
                    name={'cfop'}
                    variant='outlined'
                    mask="9.999"
                    value={ticket.cfop}
                    helperText={errors.cfop}
                    error={!!errors.cfop}
                    className={styles.input}
                    onChange={(ev: any) => {
                        onChangeTicketFormValue('cfop', ev.target.value)
                    }
                    }

                />
            </FormItemContainer>

            <FormItemContainer label={'Imagem do ingresso'} className={styles.formItem}>
                <div className={styles.imageInput}>
                    <InputSingleImage
                        value={ticket.imageUrl}
                        onChangeImage={(url) => onChangeTicketFormValue('imageUrl', url)}
                        name="imageUrl"
                        error={!!errors.imageUrl}
                        helperText={errors.imageUrl}
                        accept={[IMG.JPEG, IMG.PNG]}
                    />
                    <ul>
                        <li>
                            Máximo 5mb
                        </li>
                    </ul>
                </div>
            </FormItemContainer>
        </div>
    );
};
