import { KeyboardArrowRight } from '@mui/icons-material'
import { Button, Drawer } from '@mui/material'
import UseDeviceList from 'modules/config/discountCoupons/presentation/hooks/UseDeviceList'
import { ICouponForm } from 'modules/config/discountCoupons/presentation/interfaces/ICouponForm'
import React, { useCallback, useEffect, useState } from 'react'
import { EnumCouponStep } from '../../CouponForm'
import { DeviceItem } from './deviceItem/DeviceItem'
import styles from './EquipamentForm.module.scss'


export interface IEquipamentFormProps {
    values: ICouponForm
    setValues: React.Dispatch<React.SetStateAction<ICouponForm>>
    onSubmitAddCoupon: (values: ICouponForm) => void
    onChangeFormStep: (value: EnumCouponStep) => void
    isLoading: boolean | undefined
    isEdit: boolean   
}


export const EquipamentForm: React.FC<IEquipamentFormProps> = ({ values, setValues, onSubmitAddCoupon, onChangeFormStep, isLoading, isEdit, }) => {



    const [sideSheetDevices, setSideSheetDevices] = useState(false);

    const openSideSheetDevice = useCallback(() => {
        setSideSheetDevices(true)
    }, [])
    const closeSideSheetDevice = useCallback(() => {
        setSideSheetDevices(false)
    }, [])

    const onSubmit = useCallback(() => {

        onSubmitAddCoupon?.(values);

    }, [onSubmitAddCoupon, values])

    return (
        <div id={styles.EquipamentForm}>
            <div className={styles.content}>

                <span>Selecione quais equipamentos irão gerar o cupom para seus clientes e em quais eles poderão utilizar.</span>
                <div className={styles.card} onClick={openSideSheetDevice}>
                    <img src="/assets/icon/device-icon-card.svg" alt="Ícone equipamento" />  <span> Equipamentos para <b>uso do cupom </b>({values.devices.length ?? 0})</span>  <KeyboardArrowRight />
                </div>
            </div>


            <div className={styles.containerButton}>
                <Button variant="outlined" className={styles.outlined} onClick={() => onChangeFormStep(EnumCouponStep.OPERACAO)}>Voltar</Button>
                <Button variant="contained" className={styles.contained} onClick={onSubmit}>{isEdit ? "Editar" : "Criar"}</Button>
            </div>

            <Drawer open={sideSheetDevices} anchor="right" onClose={closeSideSheetDevice} disableEnforceFocus style={{zIndex: 1400}}>
                <div id={styles.drawer}>
                    <DeviceItem
                        closeSideSheetDevice={closeSideSheetDevice}
                        setValues={setValues}
                        values={values}
                        onSubmitAddCoupon={onSubmitAddCoupon}
                        onChangeFormStep={onChangeFormStep}
                        isLoading={isLoading} 
                        isEdit={isEdit}                       
                    />
                </div>
            </Drawer>


        </div>
    )
}
