import React, { ChangeEvent, FC, useCallback } from 'react'
import styles from '../FidelityProductsForm.module.scss'
import { IProductFidelity } from '../FidelityProductsForm'
import { Icon, IconButton, Switch, TextField } from '@material-ui/core'
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money'
export interface IFidelityProductItemFormProps {
    fidelityProductItem: IProductFidelity,
    onChange: (fidelityProductItem: IProductFidelity) => void
    onDelete: (fidelityProductItem: IProductFidelity) => void
}
const FidelityProductItemForm: FC<IFidelityProductItemFormProps> = ({ fidelityProductItem, onChange, onDelete }) => {


    const onChangeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...fidelityProductItem, coinsPrice: Number(ev.target.value) })
    }, [fidelityProductItem, onChange]);

    const onChangeStatusHandle = useCallback((ev: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChange({ ...fidelityProductItem, isActive: checked })
    }, [fidelityProductItem, onChange]);

    const onDeleteHandle = useCallback(() => {
        onDelete(fidelityProductItem);
    }, [fidelityProductItem, onDelete])

    return (
        <div className={styles.productItem} >
            <img src={fidelityProductItem.imageUrl} alt={"imagem do produto"} />
            <div className={styles.name}>
                {fidelityProductItem.name}
            </div>
            <div className={styles.price}>
                {moneyMaskNumber(fidelityProductItem.price)}
            </div>
            <div className={styles.coinsPrice}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    value={fidelityProductItem.coinsPrice}
                    onChange={onChangeHandle}
                ></TextField>
            </div>
            <div className={styles.status}>
                <Switch
                    checked={fidelityProductItem.isActive}
                    onChange={onChangeStatusHandle}
                />
            </div>
            <div>
                <IconButton size='small' onClick={onDeleteHandle} ><Icon>close</Icon></IconButton>
            </div>
        </div>
    )
}
export default FidelityProductItemForm