import { IGetPrintersResponse } from "../../domain/dtos/IGetPrintersResponse";
import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetPrintersUseCase = async (smartPrintersApi: ISmartPrinterApiCB, localId: string):Promise<IGetPrintersResponse[]> => {
  const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
  const response = await _smartPrintersApi.getPrinters(localId);
  return response
}

export default GetPrintersUseCase;
