import styles from "./Card.module.scss";

interface CardProps {
    icon: string,
    text: string,
    action?: () => void;
}

const Card = ({ icon, text, action }: CardProps) => {

    return (
        <div className={styles.container} onClick={action}>
            <div className={styles.content}>
                <img src={icon} alt={text} />
            </div>
            <p className={styles.text}>{text}</p>
        </div>
    );
};
export default Card;