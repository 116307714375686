import React, { FC } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import styles from './DeviceTable.module.scss'
export interface IDeviceTableProps {
    //propertys
    isLoading?: boolean
    total?: number
    data?: {
        label: string,
        total: number,
    }[]
}
const DeviceOriginTable: FC<IDeviceTableProps> = ({ total, data, isLoading }) => {
    return (
        <div id={styles.DeviceTable} >
            <h4>Origem Consumo</h4>
            <div className={styles.container} >
                {
                    data?.map(item => <div className={styles.item}>
                        <label>{item.label}</label>
                        <span>{moneyMaskNumber(item.total)} ({(total && ((item.total ?? 0) / (total) * 100).toFixed(2))})%</span>
                    </div>)
                }
            </div>
        </div>
    )
}
export default DeviceOriginTable