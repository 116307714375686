import { faker } from "@faker-js/faker";
import { IGetAverageTicketResponse } from "modules/cashlessDashboard/domain/dto/averageTicket/IGetAverageTicketResponse";
import { IGetCanceledResponse } from "modules/cashlessDashboard/domain/dto/canceled/IGetCanceledResponse";
import { IGetCardsActivationResponse } from "modules/cashlessDashboard/domain/dto/cardsActivation/IGetCardsActivationResponse";
import {
  IGetCategoriesResponse,
  IGetCategoryProductsResponse,
} from "modules/cashlessDashboard/domain/dto/categories/IGetCategoriesResponse";
import { IGetFinancialDataResponse } from "modules/cashlessDashboard/domain/dto/financialData/IGetFinancialDataResponse";
import { IGetRechargeOriginResponse } from "modules/cashlessDashboard/domain/dto/rechargeOrigin/IGetRechargeOriginResponse";
import { IGetRechargePaymentMethodsDataResponse } from "modules/cashlessDashboard/domain/dto/rechargePaymentMethodsData/IGetRechargePaymentMethodsDataResponse";
import { IGetTopCustomersResponse } from "modules/cashlessDashboard/domain/dto/topCustomers/IGetTopCustomersResponse";
import { IGetTopDevicesResponse } from "modules/cashlessDashboard/domain/dto/topDevices/IGetTopDevicesResponse";
import { IGetTopOperatorsResponse } from "modules/cashlessDashboard/domain/dto/topOperators/IGetTopOperatorsResponse";
import { IGetTopProductsResponse } from "modules/cashlessDashboard/domain/dto/topProducts/IGetTopProductsResponse";
import { IRequestParams } from "modules/cashlessDashboard/domain/interfaces/RequestParams";

export enum CashhlessDashboardMockTypes {
  GET_FINANCIAL_DATA = "getFinancialData",
  GET_RECHARGE_PAYMENT_DATA = "getRechargePaymentMethodsData",
  GET_RECHARGE_ORIGIN = "getRechargeOrigin",
  GET_AVERAGE_TICKET = "getAverageTicket",
  GET_CANCELED = "getCanceled",
  GET_CARDS_ACTIVATION = "getCardsActivation",
  GET_TOP_OPERATORS = "getTopOperators",
  GET_TOP_CUSTOMERS = "getTopCustomers",
  GET_TOP_DEVICES = "getTopDevices",
  GET_TOP_PRODUCTS = "getTopProducts",
  GET_CATEGORIES = "getCategories",
  GET_CATEGORY_PRODUCTS = "getCategoryProducts",
}

const requestMockDelay = 1500; // 1 segundo e meio
const wait = () => new Promise((res) => setTimeout(res, requestMockDelay));

export const CashlessDashboardMock = () => {
  const makeMock = async <T>(mockCallback: () => T): Promise<T> => {
    await wait();

    const result = mockCallback();
    return result;
  };

  const getFinancialData = (
    request: IRequestParams
  ): IGetFinancialDataResponse => {
    const result: IGetFinancialDataResponse = {
      totalReloaded: Number(faker.random.numeric(5)),
      totalConsumed: Number(faker.random.numeric(4)),
      totalInvoiced: Number(faker.random.numeric(4)),
      totalResidual: Number(faker.random.numeric(4)),
      totalReturned: Number(faker.random.numeric(4)),
      totalDiscount: Number(faker.random.numeric(4)),
      serviceTax: Number(faker.random.numeric(4)),
    };

    return result;
  };

  const getRechargePaymentMethodsData = (
    request: IRequestParams
  ): IGetRechargePaymentMethodsDataResponse => {
    const result: IGetRechargePaymentMethodsDataResponse = {
      traded: [
        {
          name: "PIX",
          total: Number(faker.random.numeric(5)),
        },
        {
          name: "Débito",
          total: Number(faker.random.numeric(5)),
        },
        {
          name: "Crédito",
          total: Number(faker.random.numeric(5)),
        },
        {
          name: "Outros",
          total: Number(faker.random.numeric(5)),
        },
      ],
      nonTraded: [
        {
          name: "Dinheiro",
          total: Number(faker.random.numeric(5)),
        },
        {
          name: "Outros",
          total: Number(faker.random.numeric(5)),
        },
      ],
      totalProduction: Number(faker.random.numeric(5)),
    };
    return result;
  };

  const getRechargeOrigin = (
    request: IRequestParams
  ): IGetRechargeOriginResponse => {
    const result: IGetRechargeOriginResponse = {
      totalRecharged: {
        app: Number(faker.random.numeric(4)),
        totem: Number(faker.random.numeric(4)),
        pos: Number(faker.random.numeric(4)),
        posPlus: Number(faker.random.numeric(4)),
        other: Number(faker.random.numeric(4)),
      },
      totalConsumed: {
        app: Number(faker.random.numeric(4)),
        totem: Number(faker.random.numeric(4)),
        pos: Number(faker.random.numeric(4)),
        posPlus: Number(faker.random.numeric(4)),
        other: Number(faker.random.numeric(4)),
      },
    };

    return result;
  };

  const getAverageTicket = (
    request: IRequestParams
  ): IGetAverageTicketResponse => {
    const result: IGetAverageTicketResponse = {
      consumption: {
        average: Number(faker.random.numeric(4)),
        male: Number(faker.random.numeric(4)),
        female: Number(faker.random.numeric(4)),
        unknown: Number(faker.random.numeric(4)),
        cashiers: [
          {
            endDate: "2022-12-07T14:44:31.9381752+00:00",
            startDate: "2022-11-24T14:27:19.443",
            id: "e8ed0d1a-fb59-4785-a392-956df2b0ccb2",
            perHour: [
              {
                date: "2022-11-24T14:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T15:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T16:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T17:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
            ],
          },
        ],
      },
      recharge: {
        average: Number(faker.random.numeric(4)),
        male: Number(faker.random.numeric(4)),
        female: Number(faker.random.numeric(4)),
        unknown: Number(faker.random.numeric(4)),
        cashiers: [
          {
            endDate: "2022-12-07T14:44:31.9381752+00:00",
            startDate: "2022-11-24T14:27:19.443",
            id: "e8ed0d1a-fb59-4785-a392-956df2b0ccb2",
            perHour: [
              {
                date: "2022-11-24T14:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T15:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T16:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T17:00:00",
                average: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
            ],
          },
        ],
      },
    };

    return result;
  };

  const getCanceled = (request: IRequestParams): IGetCanceledResponse => {
    const result: IGetCanceledResponse = {
      consumption: {
        serviceTax: Number(faker.random.numeric(3)),
        total: Number(faker.random.numeric(3)),
        male: Number(faker.random.numeric(3)),
        female: Number(faker.random.numeric(3)),
        unknown: Number(faker.random.numeric(3)),
      },
      recharge: {
        serviceTax: Number(faker.random.numeric(3)),
        total: Number(faker.random.numeric(3)),
        male: Number(faker.random.numeric(3)),
        female: Number(faker.random.numeric(3)),
        unknown: Number(faker.random.numeric(3)),
      },
    };

    return result;
  };

  const getCardsActivation = (
    request: IRequestParams
  ): IGetCardsActivationResponse => {
    const result: IGetCardsActivationResponse = {
      activated: {
        value: Number(faker.random.numeric(4)),
        total: Number(faker.random.numeric(4)),
        male: Number(faker.random.numeric(4)),
        female: Number(faker.random.numeric(4)),
        unknown: Number(faker.random.numeric(4)),
        cashiers: [
          {
            endDate: "2022-12-07T14:44:31.9381752+00:00",
            startDate: "2022-11-24T14:27:19.443",
            id: "e8ed0d1a-fb59-4785-a392-956df2b0ccb2",
            perHour: [
              {
                date: "2022-11-24T14:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T15:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T16:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T17:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
            ],
          },
        ],
      },
      deactivated: {
        value: Number(faker.random.numeric(4)),
        total: Number(faker.random.numeric(4)),
        male: Number(faker.random.numeric(4)),
        female: Number(faker.random.numeric(4)),
        unknown: Number(faker.random.numeric(4)),
        cashiers: [
          {
            endDate: "2022-12-07T14:44:31.9381752+00:00",
            startDate: "2022-11-24T14:27:19.443",
            id: "e8ed0d1a-fb59-4785-a392-956df2b0ccb2",
            perHour: [
              {
                date: "2022-11-24T14:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T15:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T16:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
              {
                date: "2022-11-24T17:00:00",
                total: Number(faker.random.numeric(3)),
                male: Number(faker.random.numeric(3)),
                female: Number(faker.random.numeric(3)),
                unknown: Number(faker.random.numeric(3)),
              },
            ],
          },
        ],
      },
      remainsOpen: 0,
      deactivatedOherPeriod: 0,
      deactivatedInThePeriod: 0,
    };

    return result;
  };

  const getTopOperators = (
    request: IRequestParams
  ): IGetTopOperatorsResponse => {
    const totalRecords = 50;

    const result: IGetTopOperatorsResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            id: faker.datatype.uuid(),
            total: Number(faker.random.numeric(4)),
            name: faker.name.fullName(),
          });
        }
        return arr;
      })(),
    };

    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  const getTopCustomers = (
    request: IRequestParams
  ): IGetTopCustomersResponse => {
    const totalRecords = 50;

    const result: IGetTopCustomersResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            id: faker.datatype.uuid(),
            total: Number(faker.random.numeric(4)),
            name: faker.name.fullName(),
            imageUrl: "https://i.pravatar.cc/300",
          });
        }
        return arr;
      })(),
    };
    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  const getTopDevices = (request: IRequestParams): IGetTopDevicesResponse => {
    const totalRecords = 50;

    const result: IGetTopDevicesResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            id: faker.datatype.uuid(),
            total: Number(faker.random.numeric(4)),
            name: faker.name.fullName(),
          });
        }
        return arr;
      })(),
    };
    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  const getTopProducts = (request: IRequestParams): IGetTopProductsResponse => {
    const totalRecords = 50;

    const result: IGetTopProductsResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            id: faker.datatype.uuid(),
            quantity: Number(faker.random.numeric(2)),
            price: Number(faker.random.numeric(4)),
            name: faker.name.fullName(),
            imageUrl: "https://i.pravatar.cc/300",
            total: Number(faker.random.numeric(4)),
          });
        }
        return arr;
      })(),
    };
    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  const getCategories = (request: IRequestParams): IGetCategoriesResponse => {
    const totalRecords = 15;

    const result: IGetCategoriesResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            id: faker.datatype.uuid(),
            total: Number(faker.random.numeric(3)),
            name: faker.name.fullName(),
          });
        }
        return arr;
      })(),
    };
    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  const getCategoryProducts = (
    request: IRequestParams
  ): IGetCategoryProductsResponse => {
    const totalRecords = 6;

    const result: IGetCategoryProductsResponse = {
      totalRecords,
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            categoryId: faker.datatype.uuid(),
            id: faker.datatype.uuid(),
            name: faker.name.fullName(),
            total: Number(faker.random.numeric(3)),
            quantity: Number(faker.random.numeric(2)),
          });
        }
        return arr;
      })(),
    };
    result.records = result.records.slice(request.page, request.pageSize);

    return result;
  };

  return {
    makeMock,
    getFinancialData,
    getRechargePaymentMethodsData,
    getRechargeOrigin,
    getAverageTicket,
    getCanceled,
    getCardsActivation,
    getTopOperators,
    getTopCustomers,
    getTopDevices,
    getTopProducts,
    getCategories,
    getCategoryProducts,
  };
};
