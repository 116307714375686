import { ICrmService } from "modules/crm/models/interface/ICrmService";

const deleteFilter = async (
  service: ICrmService,
  id: string,
) => {
  const response = service.deleteFilter(id);

  return response;
};

export default deleteFilter;
