import GetGestListUseCase from "modules/customersDashboard/application/useCases/GetGestListUseCase";
import { ICustomerDashboardFilter } from "modules/customersDashboard/domain/dto/FilterRequest.type";
import { useCallback, useState } from "react";
import CustomersDashboardService from "services/api/customersDashboard/CustomersDashboardService";
import { IGuestListData } from "../components/guestList/IGuestItem";


export const UseGuestList = (filter: ICustomerDashboardFilter) => {
    const [data, setData] = useState<IGuestListData>();
    const [isLoading, setIsLoading] = useState(false);

    const getData = useCallback(async (_filter?: { keyword?: string, page?: number, pageSize?: number, guestsFilter?: string, sortType?: string, sortOrientation?: string }) => {
        try {
            setIsLoading(true)
            const dashboardLiveService = CustomersDashboardService();
            const response = await GetGestListUseCase(dashboardLiveService, { ...filter, ..._filter, sort: { type: _filter?.sortType, orientation: _filter?.sortOrientation || 'asc' } })

            setData(response)
            //infintyscroll
            // if (page > 0) {
            //     setData(prev => ({ records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }))
            // } else {
            //     setData(response)
            // }
        } finally {
            setIsLoading(false)
        }
    }, [filter])

    return ({
        data,
        isLoading,
        getData
    });
};
