import { parse } from "date-fns";
import { ISaasService } from "modules/saas/domain/interfaces/ISaasService";
import { IProfileSaasForm } from "modules/saas/presentation/interfaces/profile/IProfileSaasForm";

export const SaveProfileUseCase = async (service: ISaasService, request: IProfileSaasForm, prospectId: string, key: string): Promise<void> => {
    const req = {
      ...request,
      birthday: parse(request.birthday, 'dd/MM/yyyy', new Date())
    }

    return service.saveProfile(req, prospectId, key);
}
