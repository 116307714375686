import { useState, useEffect } from "react";
import Stepe from "../components/step/Stepe";
import styles from "./GestaoVendasPage.module.scss";
import Segmento from "../components/segmento/Segmento";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import Operacao from "../components/operacao/Operacao";
import { segmentos, operacoes, equipamentos, aplicativo } from "./uteis";
import Equipamento from "../components/equipamento/Equipamento";
import VendaOnline from "../components/vendaOnline/VendaOnline";
import { UseGestaoVendas } from "../hooks/UseGestaoVendas";
import { Modal } from "@mui/material";
import ModalGestaoVendas from "../components/modal/ModalGestaoVendas";
import Review from "../components/review/Review";
import { UseGetEquipments } from "modules/equipamento/presentation/hooks/UseGetEquipments";
import { Close } from "@mui/icons-material";
import ModalPOS from "../components/modalPOS/ModalPOS";
import { useLocal } from "modules/local/presentation/context/LocalContext";


const GestaoVendasPage = () => {
  const [type, setType] = useState("Segmento");
  const [banner, setBanner] = useState(true)
  const [trigger, setTrigger] = useState(true)
  const [checkedSegmento, setCheckedSegmento] = useState<number | undefined>();
  const [openModalPOS, setOpenModalPOS] = useState(false);
  const [checkedOperacoes, setCheckedOperacoes] = useState(
    Array(operacoes.length).fill(false)
  );
  const [checkedVenda, setCheckedVenda] = useState(
    Array(aplicativo.length).fill(false)
  );

  const { dimensions } = UseDimension();

  const { currentLocal } = useLocal();

  const {
    postMesas,
    values,
    onChangeHandle,
    // setDateItens,
    handleChangeDate,
    dateItens,
    onSubmitSegmentos,
    responseConfigLocal,
    responseMesas,
    responseOperacoes,
    postOperacoes,
    operadorList,
    onSubmit,
    address,
    handleSaveAddress,
    loadingAddress,
    stepe,
    setStepe,
    openModal,
    setOpenModal,
    getProfileConfiguration,
    getLocalAddress,
    getSegmentos,
    getMesas,
    getOperacoes,
    getOperador,
    setValues,
    errors,
    setErrors
  } = UseGestaoVendas(true);

  useEffect(() => {
    getProfileConfiguration();
    getLocalAddress();
    getSegmentos();
    getMesas();
    getOperacoes();
    getOperador();
  }, [getLocalAddress, getMesas, getOperacoes, getOperador, getProfileConfiguration, getSegmentos]);

  useEffect(() => {
    if (currentLocal) {
      onChangeHandle("estabelecimento", currentLocal.name);
    }
  }, [currentLocal, onChangeHandle]);

  const { equipments } = UseGetEquipments();

  useEffect(() => {
    setValues(prev => ({ ...prev, localId: currentLocal?.id, localName: currentLocal?.name }))
  }, [currentLocal?.id, currentLocal?.name, setValues]);


  useEffect(() => {
    if (responseOperacoes) {
      setCheckedOperacoes([
        responseOperacoes.Mesa,
        responseOperacoes.ComandaIndividual,
        responseOperacoes.PrePagoFicha,
        responseOperacoes.PrePagoCashless,
      ]);
    }
  }, [responseOperacoes]);

  useEffect(() => {
    if (stepe === 1) {
      setType("Segmento");
    } else if (stepe === 2) {
      setType("Operação");
    } else if (stepe === 3) {
      setType("Equipamento");
    } else if (stepe === 4) {
      setType("Venda on-line");
    }

    const gestaoStorage = localStorage.getItem("@gestao")
    if (stepe === 5) {
      localStorage.setItem('@gestao', 'true')
      setTrigger(true)
    }
    if (gestaoStorage && trigger) {
      setStepe(5)
    }
  }, [setStepe, stepe, trigger]);

  const conteudo = () => {
    switch (stepe) {
      case 1:
        return (
          <Segmento
            segmentos={segmentos}
            setCheckedItem={setCheckedSegmento}
            checkedItem={checkedSegmento}
          />
        );
      case 2:
        return (
          <Operacao
            operacoes={operacoes}
            checkedItem={checkedOperacoes}
            setCheckedItem={setCheckedOperacoes}
            responseMesas={responseMesas}
            postMesas={postMesas}
            identificadorSistema={responseConfigLocal?.identificadorSistema}
          />
        );
      case 3:
        return (
          <Equipamento
            equipamento={equipamentos}
            getEquipamentos={equipments}
            operadorList={operadorList}
            identificadorSistema={responseConfigLocal?.identificadorSistema}
            setOpenModal={setOpenModalPOS}
          />
        );
      case 4:
        return (
          <VendaOnline
            aplicativo={aplicativo}
            checkedItem={checkedVenda}
            setCheckedItem={setCheckedVenda}
            values={values}
            onChangeHandle={onChangeHandle}
            handleChangeDate={handleChangeDate}
            dateItens={dateItens}
            address={address}
            handleSaveAddress={handleSaveAddress}
            loadingAddress={loadingAddress}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 5:
        return (
          <Review
            values={values}
            setStepe={setStepe}
            getSegmento={responseConfigLocal?.tipoLocalId}
            responseMesas={responseMesas}
            responseOperacoes={responseOperacoes}
            operadorList={operadorList}
            setTrigger={setTrigger}
          />
        );
    }
  };

  const disabled = () => {
    if (stepe === 1 && checkedSegmento === undefined) {
      return true;
    } else if (stepe === 2 && !checkedOperacoes.some((item) => item === true)) {
      return true;
    }
  };

  useEffect(() => {
    if (responseConfigLocal) {
      setCheckedSegmento(responseConfigLocal.tipoLocalId);
    }
  }, [responseConfigLocal]);

  return (
    <div id={styles.GestaVendas}>
      {stepe === 1 && banner && (
        <div className={styles.boxImgBanner}>
          <div className={styles.close} onClick={() => setBanner(false)}><Close /></div>
          <img
            className={styles.banner}
            src={
              dimensions.width <= 550
                ? "/assets/img/gestao_vendas/banner_gestao_vendas_mobile.png"
                : "/assets/img/gestao_vendas/banner_gestao_vendas.png"
            }
            alt="gestão de vendas"
          />
        </div>
      )}
      {stepe < 5 && <Stepe stepe={stepe} type={type} />}
      {conteudo()}
      <div className={styles.footer}>
        {stepe > 1 && stepe < 5 && (
          <Button
            className={styles.buttonBack}
            onClick={() => setStepe(stepe - 1)}
            style={{ width: dimensions.width < 750 ? "100%" : "94px" }}
          >
            Voltar
          </Button>
        )}
        {stepe < 5 && (
          <Button
            className={styles.buttonContinue}
            onClick={() => {
              if (stepe < 4) setStepe(stepe + 1);
              else onSubmit(values, false, checkedVenda);

              if (stepe === 2) {
                postOperacoes(
                  checkedOperacoes[1],
                  checkedOperacoes[0],
                  checkedOperacoes[2],
                  checkedOperacoes[3]
                );
              }
              if (stepe === 1) {
                onSubmitSegmentos({
                  TipoLocalId: checkedSegmento,
                });
              }
            }}
            disabled={disabled()}
            style={{ width: dimensions.width < 750 ? "100%" : "94px" }}
          >
            Continuar
          </Button>
        )}
      </div>
      <Modal open={openModalPOS} onClose={() => setOpenModalPOS(false)}>
        <ModalPOS setOpenModal={setOpenModalPOS} />
      </Modal>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalGestaoVendas setOpenModal={setOpenModal} />
      </Modal>
    </div>
  );
};

export default GestaoVendasPage;
