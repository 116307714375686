import { Button, Drawer, Icon, IconButton } from '@material-ui/core';
import styles from './FidelityEditSummary.module.scss';
import { UseFidelityEditSummary } from './UseFidelityEditSummary';
import { Skeleton, TextField } from '@mui/material';
import { ConfirmModal } from './confirmModal/ConfirmModal';
import TabView from 'components/tab/TabView';
import { useCallback } from 'react';
import FidelityPointsForm from './pointsForm/FidelityPointsForm';

interface FidelityEditSummaryProps {
    onClose: () => void;
    onFinish: () => void;
    customerDocument: string;
    customerName: string;
}

export enum FidelitySummaryStep {
    points = 0,
    coins = 1,
}

export const FidelityEditSummary = ({ onClose, onFinish, customerDocument, customerName }: FidelityEditSummaryProps) => {
    const {
        preLoading,
        loading,
        customerData,
        values,
        confirmType,
        step,
        setStep,
        setConfirmType,
        onSubmitFormHandle,
        confirmSubmit
    } = UseFidelityEditSummary(customerDocument, onClose, onFinish);

    return (
        <div className={styles.container}>
            <header>
                <h1>Alteração de <b>ponto e moeda</b></h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton>
            </header>

            <TabView
                index={step || FidelitySummaryStep.points}
                tabs={
                    [
                        { label: 'Pontos', value: FidelitySummaryStep.points },
                        // { label: 'Moedas', value: FidelitySummaryStep.coins },
                    ]
                }
                onChangeTab={(_, index) => {
                    setStep(index);
                }}
            />

            {
                preLoading ?
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} style={{ marginTop: 24, marginBottom: 16, borderRadius: 8 }} />
                        <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                        <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                        <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                        <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                    </> :
                    step === FidelitySummaryStep.points &&
                    <FidelityPointsForm
                        customerData={customerData}
                        onSubmit={onSubmitFormHandle}
                    />
            }

            <Drawer open={!!confirmType} onClose={() => setConfirmType(undefined)} anchor="right" className={styles.drawer}>
                {
                    !!confirmType && values && customerData &&
                    <ConfirmModal
                        type={confirmType}
                        balanceType={values?.balanceType}
                        customerName={customerName}
                        values={values}
                        data={customerData}
                        loading={loading}
                        onClose={() => setConfirmType(undefined)}
                        onConfirm={confirmSubmit}
                    />
                }
            </Drawer>
        </div>
    )
}