import { IFilterDeviceProfileRequest } from "../../../domain/dto/deviceProfile/getDeviceProfile/IFilterDeviceProfileRequest";
import { IDeviceProfileService } from "../../../domain/interface/IDeviceProfileService";
import { IFilterProfileDevice } from "../../../presentation/components/deviceProfile/filterProfile/IFilterDeviceProfile";
import { IDeviceProfileItem } from "../../../presentation/components/deviceProfile/profileList/interfaces/IProfileItem";

const GetProfileListUseCase = async (profileService: IDeviceProfileService, localId: string, profileFilter?: IFilterProfileDevice, deviceFilter?: IFilterDeviceProfileRequest): Promise<IDeviceProfileItem[]> => {
    const filter: IFilterDeviceProfileRequest = { ...deviceFilter, operationType: profileFilter?.operation?.id, limit: deviceFilter?.limit ?? 200 }
    const reponse = await profileService.getDeviceProfileList(localId, filter);

    return reponse.items.map(item => ({
        id: item.id,
        name: item.name,
        description: item.description,
        isDefault: item.isDefault,
        enabled: item.isEnabled,
        operationsMethods: item.operation.methods.map(method => ({ name: method.name, type: method.type, enabled: method.enabled })),
        catalogs: item.catalogs,
        devices: item.devices,
        paymentMethods: item.payment.methods.map(method => ({ name: method.name, type: method.type, enabled: method.enabled })),
    }));
}

export default GetProfileListUseCase
