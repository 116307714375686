import { IGetHouseFlowListResponse } from 'modules/houseFlow/domain/dto/IGetHouseFlowListResponse';
import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { IHouseFlowFilter } from '../components/houseFlowFilter/components/interfaces/IHouseFlowFilter';
import UseHouseFlowList, { ParamsClientHouseFlow } from '../hooks/UseHouseFlowList';
import { IHouseFlow } from '../interfaces/IHouseFlow';

export interface IHouseFlowListContext {
    inUse: number;
    available: number;
    idle: number;
    houseFlowList: IHouseFlow | undefined;
    isLoading: boolean | undefined;
    getHouseFlowList: (params?: ParamsClientHouseFlow | undefined) => Promise<IGetHouseFlowListResponse | undefined>;
    setParams: React.Dispatch<React.SetStateAction<ParamsClientHouseFlow>>;
    params: ParamsClientHouseFlow;
}

const HouseFlowListContext = createContext<IHouseFlowListContext>({} as IHouseFlowListContext)

export const HouseFlowListProvider: FC = ({ children }) => {

    const { inUse, available, idle, getHouseFlowList, houseFlowList, isLoading, } = UseHouseFlowList();

    
  const [params, setParams] = useState<ParamsClientHouseFlow>({
    filter: { sortOrientation: 'asc', sortType: 'cardNumber' }
  }); 


  useEffect(() => {
    getHouseFlowList({ ...params });
  }, [getHouseFlowList, params]);


    return (
        <HouseFlowListContext.Provider value={{ inUse, available, idle, houseFlowList, isLoading, getHouseFlowList, setParams, params }}>
            {children}
        </HouseFlowListContext.Provider>
    )
}

export const useHouseFlowList = () => {
    const context = useContext(HouseFlowListContext);
    return context;
};
