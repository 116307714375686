import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import CreateBaseListUseCase from "modules/events/application/useCases/baseList/CreateBaseListUseCase";
import GetEventDiscountBaseLIstUseCase from 'modules/events/application/useCases/baseList/GetEventDiscountBaseLIstUseCase';
import { IDiscountListResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEvents } from 'modules/events/domain/models/IEvents';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useEffect, useState } from 'react';
import DiscountBaseListApi from 'services/api/events/discountsList/DiscountBaseListApi';
import { IDiscountListForm } from './components/discountListForm/IDiscountListForm';

const UseDiscountListDiscount = (event: IEvents, resetRefreshDiscountList:()=>void, refreshEvent: () => Promise<void>) => {
  const baseListService = DiscountBaseListApi();
  const { currentLocal } = useLocal();
  const [loading, setLoading] = useState(false);
  const [discountsList, setdiscountsList] = useState<IDiscountListResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [localId, setLocalId] = useState<string>();
  const { toast, showLoading, hideLoading } = useUi();
  const [openForm, setOpenForm] = useState(false);
  const [selectedDiscountList, setSelectedDiscountList] = useState<IDiscountListForm>({} as IDiscountListForm);

  useEffect(() => {
    if (currentLocal?.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  const openFormModal = useCallback((discountList?: IDiscountListForm) => {
    setOpenForm(true);
    setSelectedDiscountList(discountList || {} as IDiscountListForm);
  }, []);

  const closeFormModal = useCallback(() => {
    setOpenForm(false);
  }, []);

  const getEventDiscountBaseList = useCallback(
    async (page: number = 0) => {
      if (localId && event.id) {
        setLoading(true)
        try {
          const result = await GetEventDiscountBaseLIstUseCase(
            baseListService,
            localId,
            event.id,
            page
          );
          resetRefreshDiscountList()
          if (page === 0) {
            setdiscountsList(result.records);
            setTotalRecords(result.totalRecords);
          } else if (page > 0) {
            discountsList && setdiscountsList(discountsList.concat(result.records));
          }
        } catch (error) {
          // console.error(error);
        } finally {
          setLoading(false)
        }
      } else {
        return Promise.reject(new Error('Paramêtros inválidos!'))
      }
    },
    [localId, event, baseListService, resetRefreshDiscountList, discountsList]
  );  

  const handleSaveDiscountList = useCallback(async (discountList: IDiscountListForm) => {
    try {
      showLoading();

      // if (discountList.id)
      //   await UpdateDiscountListUseCase(discountListsService, currentLocal!.id, eventId, discountList.id, discountList);
      // else
      //   await CreateDiscountListUseCase(discountListsService, currentLocal!.id, eventId, discountList);

      await CreateBaseListUseCase(baseListService, { ...discountList, localId: localId! });

      toast("Ingresso salvo com sucesso!", "success");
      closeFormModal();
      refreshEvent();
    } finally {
      hideLoading();
    }
  }, [baseListService, closeFormModal, hideLoading, localId, refreshEvent, showLoading, toast]);

  return {
    discountsList,
    loading,
    totalRecords,
    localId,
    openForm,
    selectedDiscountList,
    getEventDiscountBaseList,
    openFormModal,
    closeFormModal,
    handleSaveDiscountList
  };
};

export default UseDiscountListDiscount;
