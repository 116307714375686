
export enum EnumProductType {
    PRODUTO = 1,
    RECARGA = 2,
    VARIAVEL = 3,
    PROMOCAO = 4,
    ATIVACAO_CASHLESS_PRE_PAGO = 5,
    DESATIVACAO_CASHLESS_PRE_PAGO = 6,
    ATIVACAO_CASHLESS_POS_PAGO = 7,
    DESATIVACAO_CASHLESS_POS_PAGO = 8,
    ATIVACAO_POS_PAGO_COMANDA = 9,
    DESATIVACAO_POS_PAGO_COMANDA = 10,
    TAXA_DE_SERVICO = 11,
    KILO = 12,
    AJUSTE = 13,
    TAXA_DE_ENTREGA = 14,
    INGRESSO_COM_NOME_NA_LISTA = 15,
    INGRESSO_AVULSO = 16
  }
  
  export enum EnumGender {
    SEM_RESTRICOES = 0,
    MASCULINO = 1,
    FEMININO = 2
  }
  
  export enum CompositionProductType {
    FIXO = 1,
    VARIAVEL = 2,
    COMPOSICAO = 3
  }

  export enum EnumObservationType {
    EXCLUSIVO = 1,
    INCLUSIVO = 2,
  }
  

  