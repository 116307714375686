import axios from "axios";
import { IPaginatedList } from "models/api/IApi";
import { ICashlessVoucerApi } from "modules/events/application/interfaces/ICashlessVoucherService";
import {
  ICashlessVoucherRequest,
  ICashlessVoucherResponse,
  IPaymentMethod,
  IPermutationAccountResponse,
  IRechargeVoucherRequest,
} from "modules/events/domain/dto/IEventsResponse";
import { api } from "services/api/Api";
import EventsMock from "../EventsMock";

export const CashlessVoucherApi = (): ICashlessVoucerApi => {
  // const { cashlessList } = EventsMock();

  const getCashlessVoucherList = async (
    localId: string,
    cashierId?: string,
    page?: number,
    pageSize?: number
  ): Promise<IPaginatedList<ICashlessVoucherResponse>> => {
    let query = `?localId=${localId}`;
    query += cashierId ? `&cashierId=${cashierId}` : "";
    query += page ? `&page=${page}` : "&page=0";
    query += pageSize ? `&pageSize=${pageSize}` : "";
    const result = await api.get(`/SuperApp/Event/GetVouchers${query}`);
    // const result = await api.get(`http://2fa0-149-19-206-44.ngrok.io/Promptus.Meep.Server/api/SuperApp/Event/GetVouchers${query}`);
    return result.data;
    // return Promise.resolve(cashlessList);
  };

  const createCashlessVoucher = async (cashlessVoucher: ICashlessVoucherRequest): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/CreateBalance`, cashlessVoucher);
    // const result = await api.post(`http://2fa0-149-19-206-44.ngrok.io/Promptus.Meep.Server/api/SuperApp/Event/CreateBalance`, cashlessVoucher);
    return result.data;
    // return Promise.resolve();
  };

  const getPaymentMethod = async (localId: string): Promise<IPaymentMethod[]> => {
    const result = await api.get(`/SuperApp/Event/GetPaymentMethod?localId=${localId}`);
    // const result = await api.get(`http://2fa0-149-19-206-44.ngrok.io/Promptus.Meep.Server/api/SuperApp/Event/GetPaymentMethod?localId=${localId}`);
    return result.data;
    // return Promise.resolve([]);
  };

  const getPermutationAccount = async (document: string, localId: string, accountId: string): Promise<IPermutationAccountResponse> => {
    const result = await api.get(`/permutation/account/document/${document}?placeId=${localId}&meepAccountId=${accountId}`);
    return result.data;
  };

  const rechargeVoucher = async (cashlessVoucher: IRechargeVoucherRequest): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/Recharge`, cashlessVoucher);
    // const result = await api.post(`http://2fa0-149-19-206-44.ngrok.io/Promptus.Meep.Server/api/SuperApp/Event/Recharge`, cashlessVoucher);
    // return Promise.resolve();
  };

  return {
    createCashlessVoucher,
    getCashlessVoucherList,
    getPaymentMethod,
    rechargeVoucher,
    getPermutationAccount
  };
};
