import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";


export const DeletePermutationAccountUseCase = async (service: IPermutationApiService, accountId: string, localId: string): Promise<any> => {

    const _request = await service.deletePemutationAccount(accountId, localId);
    return _request;
}


