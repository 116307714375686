import { Divider } from "@mui/material";
import styles from "./CieloEventContainer.module.scss";
import { CheckCircleOutlined } from "@material-ui/icons";

const CieloEventContainer = () => {
    const features = [
        "Venda e impressão de fichas",
        "Cadastro de produtos",
        "Cupom fiscal",
        "Operadores ilimitados",
        "Dashboard em tempo real",
        "Cardápio online",
        "Relatórios após o evento",
    ];

    return (
        <div id={styles.CieloEventContainer}>
            <h1>Eventos</h1>
            <Divider />
            <div className={styles.features}>
                {features.map((item) => (
                    <div key={item}>
                        <CheckCircleOutlined fontSize="small" />
                        <span>{item}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CieloEventContainer;