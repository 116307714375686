import { IKDSLocalStorage } from "modules/kds/domain/interfaces/IKDSLocalStorage"


const key = "@KDS"
const KDSLocalStorage = (): IKDSLocalStorage => {
    const saveLastOrderId = (id: string) => {
        localStorage.setItem(key + "-lastOrder", id)
    }
    const getLastOrderId = (): string => {
        return localStorage.getItem(key + "-lastOrder") ?? ""
    }
    return ({
        saveLastOrderId,
        getLastOrderId
    })
}
export default KDSLocalStorage