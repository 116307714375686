import { IRechargeOrigin } from "modules/cashlessDashboard/presentation/components/rechargeOrigin/interfaces/IRechargeOrigin";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetRechargeOriginUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<IRechargeOrigin> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getRechargeOrigin(filterRequest);

  const consumedTotal = response.totalConsumed.app + response.totalConsumed.totem + response.totalConsumed.pos + response.totalConsumed.posPlus + response.totalConsumed.other;
  const rechargedTotal = response.totalRecharged.app + response.totalRecharged.totem + response.totalRecharged.pos + response.totalRecharged.posPlus + response.totalRecharged.other;

  const result: IRechargeOrigin = {
    totalConsumed: {
      ...response.totalConsumed,
      total: consumedTotal,
      appPercentage: consumedTotal ? Number(((response.totalConsumed.app / consumedTotal) * 100).toFixed(2)) : 0,
      totemPercentage: consumedTotal ? Number(((response.totalConsumed.totem / consumedTotal) * 100).toFixed(2)) : 0,
      posPercentage: consumedTotal ? Number(((response.totalConsumed.pos / consumedTotal) * 100).toFixed(2)) : 0,
      posPlusPercentage: consumedTotal ? Number(((response.totalConsumed.posPlus / consumedTotal) * 100).toFixed(2)) : 0,
      otherPercentage: consumedTotal ? Number(((response.totalConsumed.other / consumedTotal) * 100).toFixed(2)) : 0,
    },
    totalRecharged: {
      ...response.totalRecharged,
      total: rechargedTotal,
      appPercentage: rechargedTotal ? Number(((response.totalRecharged.app / rechargedTotal) * 100).toFixed(2)) : 0,
      totemPercentage: rechargedTotal ? Number(((response.totalRecharged.totem / rechargedTotal) * 100).toFixed(2)) : 0,
      posPercentage: rechargedTotal ? Number(((response.totalRecharged.pos / rechargedTotal) * 100).toFixed(2)) : 0,
      posPlusPercentage: rechargedTotal ? Number(((response.totalRecharged.posPlus / rechargedTotal) * 100).toFixed(2)) : 0,
      otherPercentage: rechargedTotal ? Number(((response.totalRecharged.other / rechargedTotal) * 100).toFixed(2)) : 0,
    }
  }

  return result;
}

export default GetRechargeOriginUseCase
