import React, { ReactNode, useCallback, useMemo } from "react";
import styles from "./ReportProductsItem.module.scss";
import { moneyMaskNumber } from "services/utils/Money";

interface Props {
  columns: any[];
  userItemData: any;
}

const ReportPaymentsItem: React.FC<Props> = ({ userItemData, columns }) => {
  const formatValues = useCallback(
    (property): ReactNode => {
      switch (property) {
        case "TotalPaid":
          return moneyMaskNumber(userItemData?.TotalPaid);
        default:
          return userItemData?.[property] ?? "-";
      }
    },
    [userItemData]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span>{formatValues(colunm.property)}</span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  return (
    <div id={styles.ReportProductsItem}>
      <div className={styles.containerItem}>
        <div className={styles.content}>{List}</div>
      </div>
    </div>
  );
};

export default ReportPaymentsItem;
