import PageHeader from "components/ui/pageHeader/PageHeader";
import Layout from "components/layout/presentation/Layout";
import DiscountsListPage from "modules/events/presentation/pages/discountsList/DiscountsListPage";

const Discounts = () => {
  return (
    <Layout>
      <PageHeader title="Listas de Descontos" />
      <DiscountsListPage />
    </Layout>
  );
};

export default Discounts;
