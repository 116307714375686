import { ICrmEvent } from "../../intefaces/ICrmEventData";

export const messageBodyEmail = (messageBody: string, preview: boolean, event?: ICrmEvent) => {
    const response = `<div style='background-color:#eee;padding:16px'>
    <div
        style='border-radius: 16px; box-shadow: 2px 2px 6px -2px #0005; background-color: #fff; padding: 16px;'>
        ${!!event?.url ? `<div style='border-radius: 8px;background-color: #fff;'>
            <a href='${preview ? event?.url : '[url]'}' target='_blank'><img src='${event?.bannerUrl}' style='width: 100%;max-height: 300px; border-radius: 8px;object-fit: contain;' /><a>
        </div>`: ""}
        <div style='padding: 32px; gap: 16px;'>
            ${!!event ? `<div className={styles.eventInfo}>
                <h1 style='font-size: large;text-align: left;'>${event?.name}</h1>
                <div className={styles.eventInfoItem}>
                    <label>Data:</label><span>${event?.startDate && new Date(event.startDate).toLocaleString()}</span>
                </div>
            </div>`: ``}
            <content>
                ${messageBody}
            </content>
            ${!!event ? `<a href='${preview ? event?.url : '[url]'}' target='_blank'>
                <button style="background-color: #4D13B7; width: 100%; cursor: pointer; outline: none; padding: 8px; font-size: large; border: none; border-radius: 8px ;color: #fff" src="${event?.url}">Comprar ingressos</button>
            </a>`: ``}
        </div >
    </div >
    </div > `

    // NOVO LAYOUT
    // const response = `
    //     <div style='background-color:#F3EDFF;padding:215px 134px;width: 1438px;'>
    //         <div style='border-radius: 8px; background-color: #fff;'>
    //         ${!!event?.url ? 
    //             `<div style='border-radius: 8px;background-color: #fff;'>
    //                 <a href='${preview ? event?.url : '[url]'}' target='_blank'><img src='${event?.bannerUrl}' style='width: 100%;max-height: 344px; border-radius: 8px;object-fit: cover;'></a>
    //             </div>` : ""}
    //             <div style='padding: 54px 59px 85px;;'>
    //                 <div className={styles.eventInfo}>
    //                     <h1 style='font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;font-weight: 700;font-size: 24px;'>${event?.name}</h1>
    //                     <div style="font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;">
    //                         <label>Data: </label><span style="font-weight: 600;">${event?.startDate && new Date(event.startDate).toLocaleString()}</span>
    //                     </div>
    //                     <div style="font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;margin: 10px 0 54px;">
    //                         <label>Local: </label><span style="font-weight: 600;">Mineirão, BH/MG</span>
    //                     </div>
    //                 </div>
    //                 <content style="font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;font-size: 24px; color: #5F5F5F;">
    //                     <span style="font-size: 24px; color: #5F5F5F; margin: 0 16px;">Olá Ramon,</span> <br/><br/>

    //                     <div style="font-size: 24px; color: #5F5F5F; margin: 0 16px;">
    //                         ${messageBody}
    //                     </div>

    //                     <br/><span style="font-size: 24px; color: #5F5F5F; margin: 0 16px;">Até mais.</span>
    //                 </content>
    //                 <a href='#' target='_blank' style="font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;">
    //                     <button style="margin-top: 31px; height: 60px; background-color: #4D13B7; width: 100%; cursor: pointer; outline: none; padding: 0px 16px; font-weight: 700; font-size: 20px; border: none; border-radius: 8px ;color: #fff" src="${event?.url}">Comprar ingressos</button>
    //                 </a>
    //             </div>

    //         </div>
    //         <div style="font-family: sans-serif; font-weight: 400; font-size: 20px; line-height: 150%;color: #333333;padding: 36px 33px; background: #FFF; margin-top: 48px;border-radius: 8px;">
    //             <span style="font-size: 18px; color: #5F5F5F">Esse email foi enviado de <span style="font-weight: 600;font-size: 18px;">Ana Luisa Araujo</span> para <span style="font-weight: 600;font-size: 18px; color: #4D13B7">isabela.alcantara@meep.com.br</span> por meio do evento <span>Show do Clayton e Romário.</span></span>
    //             <div style="background: #DFDFDF; height: 1px; width: 100%; margin: 32px 0"></div>
    //             <span style="font-size: 18px; color: #5F5F5F">A Meep não tem quaisquer responsabilidades sobre o conteúdo do email, sendo inteira e exclusivamente do produtor.</span>
    //         </div>
    //     </div>
    // `;

    return response
}