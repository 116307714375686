import { IPostAddTIDsRequest } from "modules/tidsAdicionais/domain/dto/IPostAddTIDsRequest";
import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const CreateTIDsUseCase = async (
  service: IComplienceService,
  payload: IPostAddTIDsRequest,
) => {
  const response = await service.createAdditionalTids(payload);
  return response;
};

export default CreateTIDsUseCase;
