import { Drawer, Switch, TextField } from '@material-ui/core';
import styles from './CustomerCategories.module.scss';
import { CategoryForm } from '../components/categoryForm/CategoryForm';
import { CategoryCustomers } from '../components/categoryCustomers/CategoryCustomers';
import { DeleteModal } from '../components/deleteModal/DeleteModal';
import { UseCustomerCategories } from './UseCustomerCategories';
import { Skeleton } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import { AddCircle, DeleteOutlineOutlined } from '@material-ui/icons';

const CustomerCategoriesPage = () => {
    const {
        preLoading,
        data,
        filteredData,
        formOpened,
        categoryCustomersOpened,
        deleteOpened,
        selectedCategory,
        loading,
        categoryCustomers,
        currentPage,
        name,
        setName,
        setSelectedCategory,
        setDeleteOpened,
        setFormOpened,
        setCategoryCustomersOpened,
        handleCreateCategory,
        handleDeleteCategory,
        getCategoryCustomers,
        handleToggleIsVisible,
        removeCustomerFromCategory,
    } = UseCustomerCategories();

    const { dimensions } = UseDimension();

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Categoria de clientes</h1>
            <p className={styles.subtitle}>Crie categorias personalizadas e vincule seus clientes.</p>

            <button className={styles.addButton} onClick={() => setFormOpened(true)}>
                Nova Categoria
                <AddCircle />
            </button>

            <div className={styles.filterContainer}>
                <label>Nome da categoria</label>
                <div>
                    <TextField
                        placeholder='Nome da categoria'
                        size='small'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant='outlined'
                        className={styles.textField}
                    />

                    {/* <button>Buscar</button> */}
                </div>
            </div>

            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    <div>Nome da Categoria</div>
                    <div>Ícone</div>
                    <div>Lista de convidado</div>
                    <div></div>
                </div>

                {
                    preLoading ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={144} className={styles.skeleton} />
                            <Skeleton variant="rectangular" width="100%" height={144} className={styles.skeleton} />
                            <Skeleton variant="rectangular" width="100%" height={144} className={styles.skeleton} />
                        </> :
                        filteredData.length ? filteredData.map(item => (
                            <div className={styles.card}>
                                <div className={styles.cardContent}>
                                    <div>
                                        <div>{item.name}</div>
                                        <div><img src={`/assets/icon/customerCategory/purple/${item.icon}.png`} alt="" /></div>
                                        <div><Switch checked={item.visible} color='secondary' onChange={() => handleToggleIsVisible(item.id)} /></div>
                                        {dimensions.width >= 650 && (
                                            <div className={styles.actions}>
                                                <button onClick={() => { setSelectedCategory(item); setCategoryCustomersOpened(true); }}><img src="/assets/icon/users_list_outlined.svg" alt="" /></button>
                                                <button onClick={() => { setSelectedCategory(item); setFormOpened(true); }}><img src="/assets/icon/edit_outlined.svg" alt="" /></button>
                                                <button onClick={() => { setSelectedCategory(item); setDeleteOpened(true); }}><DeleteOutlineOutlined /></button>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <span>Descrição: {item.description}</span>
                                        {dimensions.width < 650 && (
                                            <div className={styles.actions}>
                                                <button onClick={() => { setSelectedCategory(item); setCategoryCustomersOpened(true); }}><img src="/assets/icon/users_list_outlined.svg" alt="" /></button>
                                                <button onClick={() => { setSelectedCategory(item); setFormOpened(true); }}><img src="/assets/icon/edit_outlined.svg" alt="" /></button>
                                                <button onClick={() => { setSelectedCategory(item); setDeleteOpened(true); }}><DeleteOutlineOutlined /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )) :
                            <div className={styles.emptyTable}>
                                <img src="/assets/icon/empty-box.svg" alt="" />
                                <p>Parece que você ainda não tem <br />nenhuma categoria de clientes criada</p>
                                <button onClick={() => setFormOpened(true)}>Nova categoria</button>
                            </div>
                }
            </div>

            <Drawer open={formOpened} anchor="right">
                <CategoryForm onClose={() => { setFormOpened(false); setSelectedCategory(undefined) }} onSubmit={handleCreateCategory} defaultValues={selectedCategory} loading={loading} data={data} />
            </Drawer>

            <Drawer open={categoryCustomersOpened} anchor="right">
                <CategoryCustomers
                    loading={loading}
                    onClose={() => setCategoryCustomersOpened(false)}
                    categoryName={selectedCategory?.name?? 'Categoria'}
                    getData={getCategoryCustomers}
                    data={categoryCustomers}
                    page={currentPage}
                    onDelete={removeCustomerFromCategory}
                />
            </Drawer>

            <Drawer open={deleteOpened} anchor="right">
                <DeleteModal loading={loading} onClose={() => setDeleteOpened(false)} onDelete={handleDeleteCategory} />
            </Drawer>
        </div>
    )
}

export default CustomerCategoriesPage
