import styles from "components/sidesheet/Sidesheet.module.scss";
import { useState } from "react";
import Input from "components/ui/input/Input";

export interface IConfigContentOmieProps {
  step: number;
}

export enum SankhyaIntegrationStep {
  integration = 0,
  payment = 1,
  success = 2,
}

const ConfigContentOmie = ({ step }: IConfigContentOmieProps) => {
  const [values, setValues] = useState<{
    serverRoute: string;
    appKey: string;
    appSecret: string;
    clientCode: string;
    accountCode: string;
    categoryCode: string;
    productCode: string;
  }>(
    {} as {
      serverRoute: string;
      appKey: string;
      appSecret: string;
      clientCode: string;
      accountCode: string;
      categoryCode: string;
      productCode: string;
    }
  );

  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="server-route" className={styles.required}>
              Rota do servidor
            </label>
            <Input
              name="server-route"
              variant={"outlined"}
              placeholder="Rota do servidor"
              value={values?.serverRoute}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  serverRoute: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="app-key" className={styles.required}>
              App key
            </label>
            <Input
              name="app-key"
              variant={"outlined"}
              placeholder="App key"
              value={values?.appKey}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  appKey: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="app-secret" className={styles.required}>
              App secret
            </label>
            <Input
              name="app-secret"
              variant={"outlined"}
              placeholder="App secret"
              value={values?.appSecret}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  appSecret: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="client-code" className={styles.required}>
              Código do cliente
            </label>
            <Input
              name="client-code"
              variant={"outlined"}
              placeholder="Código do cliente"
              value={values?.clientCode}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  clientCode: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="account-code" className={styles.required}>
              Código conta corrent
            </label>
            <Input
              name="account-code"
              variant={"outlined"}
              placeholder="Código conta corrent"
              value={values?.accountCode}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  accountCode: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="category-code" className={styles.required}>
              Código da categoria
            </label>
            <Input
              name="category-code"
              variant={"outlined"}
              placeholder="Código da categoria"
              value={values?.categoryCode}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  categoryCode: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="product-code" className={styles.required}>
              Código produto família
            </label>
            <Input
              name="product-code"
              variant={"outlined"}
              placeholder="Código produto família"
              value={values?.productCode}
              onChange={(ev) => {
                //   setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  productCode: ev.target.value,
                }));
              }}
              // error={!!error.organizationId}
              // helperText={error.organizationId}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentOmie;
