import { ITerminalService } from "modules/Terminal/application/interface/ITerminalService"
import { INotification } from "modules/notification/domain/INotification"
import { NotificationType } from "modules/notification/domain/TypeNotification";
import { INotificationServices } from "modules/notification/domain/interfaces/INotificationServices"

const GetNotificationsUseCase = async (notificationService: INotificationServices, terminalService: ITerminalService, localId: string): Promise<INotification[]> => {

    const solicitations = terminalService.getNavigatorSolicitations(localId);
    const notifications = notificationService.getNotifications();

    const [solicitationsResponse, notificationsResponse] = await Promise.all([solicitations, notifications]);

    const notificationResult = notificationsResponse.map((notification) => {
        return {
            id: notification.id,
            startDate: notification.startDate,
            description: notification.description,
            title: notification.title,
            read: notification.read,
            type: notification.type
        }
    })
    const solicitationResult = solicitationsResponse.items.map((solicitation) => {
        return {
            id: solicitation.id,
            startDate: solicitation.createdAt,
            description: `O dispositivo ${solicitation.navigatorName} quer acessar ao terminal para lançar pedidos.`,
            title: "Terminal " + solicitation.device.name,
            type: NotificationType.TERMINAL_SOLICITATION
        }
    })

    const sortedResult = [...notificationResult, ...solicitationResult].sort((a, b) => {
        return a.startDate > b.startDate ? -1 : 1;
    })

    return sortedResult
}
export default GetNotificationsUseCase


