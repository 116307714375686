import { Benefits } from "modules/fidelity/domain/dtos/Benefits";
import { IUpdateFidelityRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityRequest";
import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService"
import { IFidelityNameFormValue } from "modules/fidelity/presentation/components/forms/name/FidelityNameForm";

const UpdateFidelityLevelBenefitsUseCase = async (service: IFidelityService, ownerId: string, fidelityId: string, values: Benefits[]): Promise<IFidelityNameFormValue> => {
    const request: IUpdateFidelityRequest = {
        benefits: values
    }
    var response = await service.updateFidelityLevel(ownerId, fidelityId, request);
    return response
}

export default UpdateFidelityLevelBenefitsUseCase