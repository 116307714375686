import { useCallback, useState } from "react";
import { IGetWizardResponse } from "../domain/dto/IGetWizardResponse";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import WizardApi from "services/api/wizard/WizardApi";
import GetWizardUseCase from "../application/GetWizardUseCase";
import { IWizardActivityRequest } from "../domain/dto/IWizardActivityRequest";
import PostWizardActivityUseCase from "../application/PostWizardActivityUseCase";
import { IGetWizardParams } from "../domain/dto/IGetWizardParams";
import { useLocal } from "modules/local/presentation/context/LocalContext";

const service = WizardApi();

export const UseWizard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [wizard, settWizard] = useState<IGetWizardResponse>();

  const { toast } = useUi();
  const { currentLocal } = useLocal()

  const getWizard = useCallback(async (menuId: string, params?: IGetWizardParams) => {
    if(currentLocal){
    try {
      setIsLoading(true);
      const response = await GetWizardUseCase(service, {
        menuId: menuId,
        IsPageWizardCrud: params?.IsPageWizardCrud,
        page: params?.page,
        pageSize: params?.pageSize,
      });
      settWizard(response);
    } finally {
      setIsLoading(false);
    }
  }
  }, [currentLocal]);

  const postWizardActivity = useCallback(
    async (request: IWizardActivityRequest) => {
      try {
        setIsLoading(true);
        await PostWizardActivityUseCase(service, request);
        return "ok";
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [toast]
  );

  return {
    isLoading,
    wizard,
    getWizard,
    postWizardActivity,
  };
};
