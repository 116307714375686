export enum ProspectType {
  Unknown = 0,
  TrialDays = 1,
  Plan = 2,
  Cielo = 3,
  Balance = 4,
  BasicRegistration = 5,
  CieloEvent = 6,
  CieloEstablishment = 7
}
