import styles from "./Info.module.scss";
import { Error, IndeterminateCheckBoxOutlined } from "@mui/icons-material";

interface Props {
  equipamento: [] | {
    value: string
  }[];
  identificadorSistema?: number;
}

const InfoComponent = ({ equipamento, identificadorSistema }: Props) => {
  return (
    <div id={styles.Info}>
      <Error />
      <div>
        Não existe licença disponível para a {equipamento.map((item, index) => {
          return(
            <>
            <b>{item.value}</b>
            {index + 1 < equipamento.length  && ", "}
            </>
          )
        })}.{" "}
        {identificadorSistema === 1 
          ? (
            <>
              Entre em <span style={{ color: "#32008E" }}>contato pelo chat</span> para solicitar!
            </>
        ) : (
          <>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=5531999946706&text=Ol%C3%A1!%20Vim%20do%20site!%20Quero%20deixar%20meu%20neg%C3%B3cio%20ainda%20mais%20incr%C3%ADvel!%20Posso%20falar%20sobre%20meu%20neg%C3%B3cio%3F"
            >
              Clique aqui
            </a>
            {" "}para solicitar agora mesmo.
          </>
        )}
      </div>
    </div>
  );
};

export default InfoComponent;
