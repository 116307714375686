export interface ICreateDefferedPromise<T> {
    promise: Promise<T>;
    resolver: (value: T) => void;
    rejecter: (error: any) => void;
  }
  
  export const createDefferedPromise = (): ICreateDefferedPromise<any> => {
    let resolver: (value: any) => void = () => {};
    let rejecter: (error: any) => void = () => {};
  
    const promise = new Promise<any>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });
  
    return {
      promise,
      resolver,
      rejecter,
    };
  };