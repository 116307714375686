import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { IPlacesScheduleReserve } from './interfaces/IPlacesSchedules';
import { ICustomerReserveServiceForm as ICustomerReserveServiceForm, IReserveScheduleForm } from './interfaces/IReserveScheduleForm'
import { IServiceItemReserve } from './interfaces/IServiceSchedule';

export const UseReserveScheduleForm = (
    onChangeServiceId: (serviceId: string) => void,
    services: IServiceItemReserve[],
    onSubmit: (values: IReserveScheduleForm) => void,
    defaultServiceId?: string,
    defaultValues?: IReserveScheduleForm
) => {

    const [values, setValues] = useState<IReserveScheduleForm>({} as IReserveScheduleForm);

    const [customers, setCustomers] = useState<ICustomerReserveServiceForm[]>([])

    const [openModalClient, setOpenModalClient] = useState(false);

    const onChangeServiceIdHandle = useCallback((value: IServiceItemReserve) => {
        onChangeServiceId(value.id);
    }, [onChangeServiceId]);


    const onChangeServiceHandle = useCallback((value: IServiceItemReserve) => {
        setValues(prev => ({ ...prev, service: value }));
        onChangeServiceId(value.id);
    }, [onChangeServiceId]);

    const onChangePlacesScheduleHandle = useCallback((value: IPlacesScheduleReserve) => {
        setValues(prev => ({ ...prev, placeSchedule: value }));
    }, []);

    const onChangeDateHandle = useCallback((value: string) => {
        setValues(prev => ({ ...prev, date: value }));
    }, []);

    const onAddChangeCustomerHandle = useCallback((value: ICustomerReserveServiceForm) => {
        setCustomers(prev => ([...prev, value]));
        setOpenModalClient(false);
    }, []);

    const removeFormByIndex = useCallback(
        (index: number) => {

            setCustomers(prev => prev.filter((item, _index) => _index !== index))

        },
        [],
    )



    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const object = {
            ...values,
            customers
        }
        if (customers.length < 1) {
            toast.error("Necessário adicionar ao menos um cliente.")
        } else {
            onSubmit(object);
        }
    }, [values, customers, onSubmit])

    const toggleModalReserve = () => {
        setOpenModalClient(!openModalClient)
    }

    useEffect(() => {
        if (defaultServiceId) {
            const newValue = services.find(item => item.id === defaultServiceId);
            if (newValue) {
                onChangeServiceHandle(newValue)
            }
        }
    }, [onChangeServiceHandle, defaultServiceId, services])


    return (
        {
            values,
            onAddChangeCustomerHandle,
            customers,
            onChangeDateHandle,
            onChangeServiceHandle,
            onChangeServiceIdHandle,
            onChangePlacesScheduleHandle,
            openModalClient,
            setOpenModalClient,
            submitHandle,
            toggleModalReserve,
            removeFormByIndex
        }
    )
}

    // const addNewForm = useCallback(
    //     () => {
    //         setCustomerValues(prev => [...prev])
    //     },
    //     [],
    // )

    // const removeFormByIndex = useCallback(
    //     (index: number) => {
    //         if (customerValues.length > 1) {
    //             setCustomerValues(prev => prev.filter((item, _index) => _index !== index))
    //         } else {
    //             setCustomerValues([emptyValues]);
    //         }
    //     },
    //     [],
    // )

    // const onChangeFormItem = useCallback(
    //     (index: number, name: string, value: string) => {
    //         setCustomerValues(prev => updateValuesFormUseCase(prev, index, name, value))
    //     },
    //     [],
    // )

// const updateValuesFormUseCase = (prev: IServiceDemandedForm[], index: number, name: string, value: string) => {
//     const newValue = prev.map((item, _index) => {
//         if (index === _index) {
//             if (name.includes("client.")) {
//                 return { ...item, client: { ...item.client, [name.replace("client.", '')]: value } }
//             } else {
//                 return { ...item, [name]: value }
//             }
//         } else {
//             return item;
//         }
//     })

//     return newValue
// }