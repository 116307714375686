import CPF from "services/utils/CPF";
import { ICashlessConsummationForm, ICashlessConsummationValidationForm } from "../interfaces/ICashlessConsummationForm";

export const validate = (formData: ICashlessConsummationForm, setErrors: React.Dispatch<React.SetStateAction<ICashlessConsummationValidationForm>>) => {
  let _errors: ICashlessConsummationValidationForm = {};
  let hasError = false;
  if (!formData.name) {
    _errors = { ..._errors, name: "Insira o nome de quem receberá o voucher" };
    hasError = true;
  }
  if (!formData.cpf) {
    _errors = { ..._errors, cpf: "Insira o CPF de quem receberá o voucher" };
    hasError = true;
  }
  if (formData.cpf && !CPF.isValid(formData.cpf)) {
    _errors = { ..._errors, cpf: "CPF inválido!" };
    hasError = true;
  }
  // if (!formData.motive) {
  //   _errors = { ..._errors, motive: "Insira a descrição/motivo do voucher" };
  //   hasError = true;
  // }
  if (!formData.paymentType) {
    _errors = { ..._errors, paymentType: "Selecione o tipo de pagamento" };
    hasError = true;
  }
  // if (!formData.initialBalance) {
  //   _errors = { ..._errors, initialBalance: "Insira o valor do voucher" };
  //   hasError = true;
  // }
  setErrors(_errors);
  return !hasError;
};
