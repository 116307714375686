import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetPaymentHistoryUseCase } from "modules/plans/application/GetPaymentHistoryUseCase";
import { IPaymentHistoryData } from "modules/plans/domain/dtos/IGetPaymentHistoryResponse";
import { useCallback, useState, useEffect } from "react";
import PlansApi from "services/api/plans/PlansApi";
import { GetPlansUseCase } from "modules/saas/application/useCases/GetPlansUseCase";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import { IPlansItem } from "modules/saas/presentation/interfaces/plans/IPlans";
import { SaasApi } from "services/api/saas/SaasApi";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetLocalSubscriptionIdUseCase } from "modules/saasPayment/application/GetLocalSubscriptionIdUseCase";
import { SaasPaymentsApi } from "services/api/saasPayment/SaasPaymentsApi";
import { GetAllCardsUseCase } from "modules/saasPayment/application/GetAllCardsUseCase";
import { IGetAllCardsResponse } from "modules/saasPayment/domain/dto/IGetAllCardsResponse";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";
import IntegrationApi from "services/api/config/integration/IntegrationApi";

const service = PlansApi();
const saasService = SaasApi();
const saasPaymentService = SaasPaymentsApi();
const integrationService = IntegrationApi();

export const UseSaasPlanPage = () => {
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();

    const [selectedType, setSelectedType] = useState<SubscriptionType>(SubscriptionType.ANNUAL);
    const [selectedPlan, setSelectedPlan] = useState<IPlansItem>();
    const [plans, setPlans] = useState<IPlansItem[]>([]);

    const [history, setHistory] = useState<IPaymentHistoryData[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [defaultCard, setDefaultCard] = useState<IGetAllCardsResponse>();

    const [localDocument, setLocalDocument] = useState("");




    const getPlans = useCallback((selectedType: any, loadDefault = false) => {
        (async () => {
            try {
                showLoading();
                const params = `categoryId=1&subscriptionType=4&subscriptionType=${selectedType}`;
                const response = await GetPlansUseCase(saasService, params);
                setPlans(response);

                if (loadDefault && response.length !== 0) {
                    setSelectedPlan(response.find(plan => plan.index === 2));
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, showLoading]);


    useEffect(() => {
        getPlans(selectedType)
    }, [getPlans, selectedType]);

    useEffect(() => {
        getPlans(selectedType, true);
    }, [getPlans, selectedType]);

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    const subscriptionCard = await GetLocalSubscriptionIdUseCase(saasPaymentService, currentLocal?.id);

                    if (subscriptionCard) {
                        const cards = await GetAllCardsUseCase(saasPaymentService, subscriptionCard.id);
                        setDefaultCard(cards.find(card => card.isDefault));
                    }
                } finally {
                    hideLoading();
                }
            }
        })();
    }, [currentLocal, hideLoading, setDefaultCard, showLoading]);

    const getHistory = useCallback(async (page: number, pageSize?: number) => {
        if (currentLocal) {
            try {
                showLoading();
                const response = await GetPaymentHistoryUseCase(service, currentLocal.id, page, pageSize);

                setHistory(response.data);
                setTotalPages(response.totalPages);
                setCurrentPage(page);
            } finally {
                hideLoading();
            }
        }
    }, [currentLocal, hideLoading, showLoading]);

    const nextPage = (page: number, pageSize?: number) => {
        if (currentPage <= totalPages) {
          getHistory(page, pageSize);
        }
    }

    const getLocalMainData = useCallback(async () => {
          if (currentLocal) {
            const response = await GetLocalMainDataUseCase(integrationService, currentLocal.id);
            setLocalDocument(response.document);
          }
    }, [currentLocal]);

    return {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        history,
        totalPages,
        currentPage,
        getHistory,
        nextPage,
        defaultCard,
        getLocalMainData,
        localDocument,
    }
}