import { Icon } from '@material-ui/core'
import { FC, Fragment, memo } from 'react'
import { Link } from 'react-router-dom'
import { useBreadcumbs } from './BreadcumbsContext'
import { KeyboardArrowRight } from '@mui/icons-material'
import styles from './Breadcumbs.module.scss'

const Breadcumbs: FC = memo(() => {
    const { placesUrl, routers } = useBreadcumbs();

    return (
        <div id={styles.Breadcumbs} >
            <div className={styles.container} >
                <Link to={placesUrl} className={styles.link} title="Voltar para página inicial">
                    <div className={styles.home}>
                        <Icon>home</Icon>
                    </div>
                </Link>
                <KeyboardArrowRight />
                {routers?.map((routerItem, index) =>
                    <Fragment key={`item-${index}`}>
                        <Link className={styles.link} to={routerItem.url ?? '#'} title={`Ir para ${routerItem.title}`}>
                            <div className={`${styles.item} ${index === routers.length - 1 ? styles.last : styles.middle}`}>
                                <span>
                                    {routerItem.title}
                                </span>
                            </div>
                        </Link>
                        {index + 1 < routers.length && <KeyboardArrowRight />}
                    </Fragment>
                )}
            </div>
        </div>
    )
})
export default Breadcumbs;
