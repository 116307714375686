import React, { FC } from 'react'
import styles from './IconPicker.module.scss'
import { Icon, Popover } from '@material-ui/core'
import { Icons } from './Icons'
import { ICustomerCategory } from '../../interfaces/ICustomerCategories';

export interface IIcon { id: string }
export interface IPicker {
    onChange: (value: IIcon) => void,
    value?: IIcon;
    data: ICustomerCategory[];
}
export const PickerIcon: FC<IPicker> = ({ onChange, value, data }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const filteredIcons = Icons.filter(x => !data.map(y => y.icon).includes(x.id));

    const onChangeHandle = (item: IIcon) => {
        onChange(item)
        handleClose()
    }

    return (
        <div id={styles.IconPicker} >
            <button type='button' className={styles.container} onClick={handleClick}>
                {value?.id ? <img src={"/assets/icon/customerCategory/purple/" + value?.id + ".png"} alt={value?.id} className={styles.icon} /> : <div />}
                <Icon className={styles.arrowIcon} >expand_more</Icon>
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={styles.popover}>
                    <div className={styles.category}>
                        {filteredIcons.map((item, index) => (
                            <img onClick={onChangeHandle.bind(this, item)} width={16} height={16} key={index} src={"/assets/icon/customerCategory/purple/" + item.id + ".png"} alt={"Icone" + item.id} className={styles.item} />
                        ))}
                    </div>
                </div>

            </Popover>
        </div>
    )
}


