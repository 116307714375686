import { AxiosInstance } from "axios";
import { IUserInfoResponse } from "modules/auth/models/dto/IGetUserInfo";
interface UserInfo {
  configuracaoUsuarioMeep: string;
  locais: string;
  roles: string;
  userName: string;
  usuarioMeep: string;
}

interface IUserInfoLocalItem {
  localId: string;
  nomeLocal: string;
}

export const BuscarInformacaoDeUsuario = async (
  api: AxiosInstance,
  token: string
): Promise<IUserInfoResponse> => {
  const response = await api.get<UserInfo>("/account/userinfo", {
    headers: { Authorization: `bearer ${token}` },
  });
  try {
    const _locals: IUserInfoLocalItem[] = JSON.parse(response.data.locais);
    return {
      locals: _locals.map((localItem) => ({
        id: localItem.localId,
        name: localItem.nomeLocal,
      })),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};
