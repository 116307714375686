import { useEffect, useMemo, useState } from "react";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { IGradientTable } from "./IGradientTable.types";

interface IUseGradientTable {
  columnWidth: number;
  totalRow: number[][];
  higherValue: number[];
  columnLegendsWidth: number;
  data: IGradientTable;
  loading: boolean;
}

const UseGradientTable = (getData: () => Promise<IGradientTable>, hideTotal?: boolean): IUseGradientTable => {
  const [data, setData] = useState<IGradientTable>({} as IGradientTable);
  const [loading, setLoading] = useState(true);
  const { setOnError, clearContainerError } = useError();

  useEffect(() => {
    setLoading(true);
    clearContainerError(dashboardContainerEnum.GRADIENT_TABLE);

    (async () => {
      try {
        const response = await getData();
        setData(response);
        if (!response.paymentForm.length) {
          setOnError({
            type: 'warning',
            containerId: dashboardContainerEnum.GRADIENT_TABLE
          })
        }
      } catch {
        setOnError({
          type: 'error',
          containerId: dashboardContainerEnum.GRADIENT_TABLE
        })
      } finally {
        setLoading(false);
      }
    })();
  }, [clearContainerError, getData, setOnError])

  const { columnWidth, totalRow, higherValue, columnLegendsWidth } = useMemo(() => {
    let columnWidth = 0;
    let totalRow = [[0]];
    let higherValue = [0];
    let columnLegendsWidth = 0;

    if (Object.keys(data).length > 0) {


      columnWidth = 1 / data.paymentForm?.length;

      totalRow = data.amount?.reduce(
        (rows: number[][], currentRows) =>
          currentRows.map((rowItem, rowIndex) =>
            rowItem.map((item, index) => (rows[rowIndex] && rows[rowIndex][index] ? rows[rowIndex][index] + item : item))
          ),
        []
      );

      columnLegendsWidth = columnWidth * totalRow[0]?.length;
      // const totalWidth = columnLegendsWidth / totalRow.length;

      higherValue = data.amount?.reduce((higherValues: number[], currentRow) => {
        const rowValue = currentRow.reduce((columnHigherValue: number[], columnsCurrentValue) => {
          columnsCurrentValue.forEach((item, index) => {
            if (!columnHigherValue[index]) {
              columnHigherValue[index] = item;
            } else if (columnHigherValue[index] <= item) {
              columnHigherValue[index] = item;
            }
          });
          return columnHigherValue;
        }, []);
        return rowValue.map((item, index) => {
          if (!higherValues[index]) {
            return item;
          } else {
            return item >= higherValues[index] ? item : higherValues[index]
          }
        })
      }, []);
    }

    return {
      columnWidth,
      totalRow,
      higherValue,
      columnLegendsWidth
    };
  }, [data]);

  return {
    columnWidth,
    higherValue,
    totalRow,
    columnLegendsWidth,
    data,
    loading,
  };
};

export default UseGradientTable;
