import { FC, useCallback, useEffect } from 'react'
import styles from './StockOperationAddOrUpdatePage.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import StockOperationsService from 'modules/meepErp/services/api/stockOperations/StockOperationsService'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { UseLocalStore } from 'modules/local/presentation/store/UseLocalStore'
import { IGetStockOperationListResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse'
import { IGetStockOperationResponse } from 'modules/meepErp/application/dtos/stockOperations/IStockOperationResponse'
import { IStockOperationValues } from 'modules/meepErp/presentation/components/stockOperations/stockOperationForm/StockOperationValues'
import StockOperationForm from 'modules/meepErp/presentation/components/stockOperations/stockOperationForm/StockOperationForm'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'

const StockOperationAddOrUpdatePage: FC = () => {

    const [currentLocal] = UseLocalStore(state => [state.currentLocal])
    const { id } = useParams<{ id?: string }>();
    const queryClient = useQueryClient();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Operações",
                url: '/private/meeperp/inventory/operations'
            }, 
            {
                title: "Cadastro de operações",
            },                            
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  

    const submitHandler = useCallback(async (values: IStockOperationValues, id?: string) => {
        if (id) {
            const service = StockOperationsService();
            return await service.updateInventoryOperation({ ...values, id: id! });
        } else {
            const service = StockOperationsService();
            return await service.createInventoryOperation({ ...values, isActive: true });
        }
    }, [])

    const getDefaultValue = useCallback(async (localId?: string, id?: string) => {
        try {
            showLoading();
            if (!id) return;
            if (!localId) throw new Error("Local não econtrado!");
            const response = await StockOperationsService().getInventoryOperation(localId, id);
            return response;
        } catch {
            console.log('error')
        } finally {
            hideLoading();

        }
    }, [])

    const getOpeartionTypes = useCallback(async () => {
        try {
            showLoading();
            const service = StockOperationsService();
            const response = service.getOperationTypes()
            return response;
        } catch {
            console.log('error')
        } finally {
            hideLoading();

        }
    }, [])


    //REACT QUERY
    const opertaionType = useQuery('@operationTypes', getOpeartionTypes);
    const defaultValue = useQuery(['@inventoryOperation', currentLocal?.id, id], () => getDefaultValue(currentLocal?.id, id), {
        enabled: !!id && !!currentLocal?.id,
        staleTime: 0
    })

    const onSuccessfulSubmit = useCallback((data: IGetStockOperationResponse) => {
        try {
            showLoading()
            if (id) {
                queryClient.setQueriesData<IGetStockOperationListResponse | undefined>(['@inventoryOperations'], (previous) =>
                (previous ? {
                    ...previous,
                    items: previous.items.map(item => item.id === id ? { ...data, typeId: data.type } : item) ?? []
                } : undefined))
            } else {
                queryClient.setQueriesData<IGetStockOperationListResponse | undefined>(['@inventoryOperations'], (previous) =>
                (previous ? {
                    ...previous,
                    items: [{ ...data, typeId: data.type, isActive: true }, ...previous.items]
                } : undefined))
            }

            replace('/private/meeperp/operations/')
        } catch {
            console.log('error')
        } finally {
            hideLoading();

        }
    }, [id, queryClient, replace])

    const submitForm = useMutation((values: IStockOperationValues) => submitHandler(values, id), {
        onSuccess: onSuccessfulSubmit
    })

    const onClickSubmitForm = async (values: IStockOperationValues) => {
        await submitForm.mutateAsync(values);
    }

    return (
        <div id={styles.InvetoryOperationAddOrUpdatePage} >
            <div className={styles.container} >
                <StockOperationForm
                    isLoading={submitForm.isLoading || defaultValue.isLoading}
                    disabled={submitForm.isLoading || defaultValue.isLoading}
                    defaultValue={defaultValue.data}
                    onSubmit={onClickSubmitForm}
                    operationTypes={opertaionType.data}
                />
            </div>
        </div>
    )
}
export default StockOperationAddOrUpdatePage