import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import LinkCardsPage from "modules/linkCards/presentation/pages/LinkCardsPage";

const LinkCards: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Mapa de operações",
      },
      {
        label: "Mapa de operações",
        title: "Pré-ativação de cartões",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <LinkCardsPage />
    </Layout>
  );
};
export default LinkCards;
