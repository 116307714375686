import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid';
import { ISmartPrinterFormErros } from '../validation/interface/ISmartPrinterFormErros';
import { validate } from '../validation/SmartPrinterFormValidation';
import { IPrinterItem, IProductItem, ISmartPrinter, ISmartPrinterItem, ITableItem, ITotemItem } from './interfaces/ISmartPrinterForm';

export const UseSmartPrinterForm = (onSubmit: (values: ISmartPrinterItem) => void, defaultValues?: ISmartPrinterItem) => {

    const [values, setValues] = useState<ISmartPrinterItem>(defaultValues ?? {
        id: '',
        name: '',
        ownerId: '',
        printerId: '',
        productsIds: [],
        tablesIds: [],
        totemsIds: [],
        printerName: ''
    })

    const [index, setIndex] = useState(0)
    const [errors, setErrors] = useState<ISmartPrinterFormErros>({});

    useEffect(() => {
        defaultValues && setValues(defaultValues)
    }, [defaultValues]);

    const onChangeHandleListPrinter = useCallback((printerValue: IPrinterItem | null) => {
        setValues(prev => ({ ...prev, printerId: printerValue?.id ?? '' }))
    }, []);

    const onChangeHandleListProduct = useCallback((productValues: IProductItem[]) => {
        setValues(prev => ({ ...prev, productsIds: productValues.map(item => item.id) }))
    }, []);

    const onChangeHandleListTable = useCallback((tableValues: ITableItem[]) => {
        setValues(prev => ({ ...prev, tablesIds: tableValues.map(item => item.id) }))
    }, []);

    const onChangeHandleListTotem = useCallback((totemValues: ITotemItem[]) => {
        setValues(prev => ({ ...prev, totemsIds: totemValues.map(item => item.id) }))
    }, []);

    const onChangeHandle = useCallback((name: string, value: string) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback(() => {
        if (validate(values, setErrors, setIndex)) {
            onSubmit(values);
            setIndex(3);
        }
    }, [onSubmit, values]);

    const handleChange = useCallback((value: number) => {
        setIndex(value)
    }, []); 

    return (
        {
            onSubmitHandle,
            onChangeHandle,
            values,
            onChangeHandleListPrinter,
            onChangeHandleListProduct,
            onChangeHandleListTable,
            onChangeHandleListTotem,
            handleChange,
            index,
            setIndex,
            errors
        }
    )
}
