import { Button, CircularProgress, Icon } from '@material-ui/core';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import AddCrmEventUseCase from 'modules/crm/application/useCases/AddCrmEventUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { ChangeEvent, FC, FormEvent, useCallback, useState } from 'react'
import CrmServiceApi from 'services/api/crm/CrmApi';
import IoticketService from 'services/api/ioticket/IoticketService';
import { ICrmEvent } from '../../intefaces/ICrmEventData';
import { InputContainer } from '../../components/_inputContainer/InputContainer'
import { InputCRM } from '../../components/_inputCRMFilter/InputCRM'
import styles from './CrmImportEventForm.module.scss'

interface ITicketEvent {
    name: string;
    banner: string;
    thumbnail: string;
    id: number;
    fullAddress: string;
    startDate: string;
    endDate: string;
    url: string
}

export interface ICrmEventValue {
    name: string,
    category: {
        name: string
    },
    startDate: string,
    endDate: string,
    url: string,
    bannerUrl: string,
    thumbnailUrl: string,
}

export interface ICrmEventFormProps {
    //propertys
    onAddEvent: (event: ICrmEvent) => void
}

const CrmImportEventForm: FC<ICrmEventFormProps> = ({ onAddEvent }) => {
    const [values, setValues] = useState({ url: "" });

    const [eventTicket, seteventTicket] = useState<ITicketEvent>()
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi()
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const validate = useCallback(
        () => {


        },
        [],
    )

    const getEventTicket = useCallback(async () => {
        const splitedUrl = values.url.split("/")
        const url = splitedUrl[splitedUrl.length - 1]
        try {
            setIsLoadingImport(true)
            const service = IoticketService();
            const response = await service.getEventByUrl(url);
            seteventTicket(response)
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoadingImport(false)
        }
    }, [values.url])

    const importMeepTicketDataHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        getEventTicket();
    }, [getEventTicket]);

    const onClickAddHandle = useCallback(async () => {
        try {
            showLoading()
            if (currentLocal && eventTicket) {
                const service = CrmServiceApi();
                const event: ICrmEventValue = {
                    name: eventTicket?.name,
                    category: {
                        name: ""
                    },
                    startDate: eventTicket?.startDate,
                    endDate: eventTicket?.endDate,
                    url: values.url,
                    bannerUrl: eventTicket?.banner,
                    thumbnailUrl: eventTicket?.thumbnail,
                }

                const response = await AddCrmEventUseCase(service, currentLocal.id, event)
                onAddEvent(response);
            }
        } finally {
            hideLoading();
        }

    }, [currentLocal, eventTicket, hideLoading, onAddEvent, showLoading, values.url])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    return (
        <div id={styles.CrmEventForm} >
            <h1>Importar evento</h1>
            <form className={styles.container} onSubmit={importMeepTicketDataHandle} >
                <InputContainer row>
                    <InputContainer flex={1} >
                        <InputCRM size='small' name={"url"} value={values.url} disabled={isLoadingImport} onChange={changeHandle} label={"URL Meep ticket"}></InputCRM>
                    </InputContainer>
                    <InputContainer flex={0} style={{ justifyContent: "flex-end" }} >
                        <Button fullWidth variant='contained' color={"primary"} type="submit" disabled={isLoadingImport}>{isLoadingImport && <CircularProgress size={12} />}Importar</Button>
                    </InputContainer>
                </InputContainer>
            </form>
            <InputContainer>
                <InputContainer>
                    {eventTicket &&
                        <div className={styles.eventPreview}>
                            <img src={eventTicket.thumbnail} alt={"Imagem do evento"} />
                            <h2>{eventTicket.name}</h2>
                            <p>{new Date(eventTicket.startDate).toISOString()}</p>
                            <p>{eventTicket.fullAddress}</p>
                        </div>
                    }
                </InputContainer>
                <InputContainer>
                    <Button variant='contained' color={"primary"} onClick={onClickAddHandle} disabled={!eventTicket}><Icon>add</Icon>Adicionar evento</Button>
                </InputContainer>
            </InputContainer>
        </div>
    )
}
export default CrmImportEventForm