import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { RemovePowerBIConfigUseCase } from "modules/bi/application/useCases/RemovePowerBIConfigUseCase";
import { useCallback, useEffect, useState } from "react"
import PlansApi from "services/api/plans/PlansApi";
import { IPowerBI, IPowerBIRequest } from "../../interfaces/IPowerBI";
import { SavePowerBIConfigUseCase } from "modules/bi/application/useCases/SavePowerBIConfigUseCase";
import { IPowerBIConfig, IPowerBIConfigItem } from "../../interfaces/IPowerBIConfig";
import { GetPowerBIConfigUseCase } from "modules/bi/application/useCases/GetPowerBIConfigUseCase";

const service = PlansApi();

export const UseConfigBI = () => {
  const [ownerSelected, setOwnerSelected] = useState<string>();
  const [configSelected, setConfigSelected] = useState<IPowerBIConfigItem>();
  const [saving, setSaving] = useState(false);
  const [configModalOpened, setConfigModalOpened] = useState(false);
  const [data, setData] = useState<IPowerBIConfig>();
  const [loading, setLoading] = useState(true);
  const [historyModalOpened, setHistoryModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [biSolicitations, setBiSolicitations] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);

  const { toast } = useUi();

  const user = JSON.parse(localStorage.getItem("@token") ?? "").user as string;
  const userDocument = JSON.parse(user).cpf as string;

  const getData = useCallback(async (page?: number) => {
    try {
      setLoading(true);
      const response = await GetPowerBIConfigUseCase(service, undefined, page ?? 1);
      setData(response);
      setCurrentPage(page ?? 1);
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const response = await GetPowerBIConfigUseCase(service, undefined, 1, true);
        setBiSolicitations(response.totalRegisters);
      } catch {}
    })()
  }, [])

  useEffect(() => {
    getData();
  }, [getData])

  const handleRemovePowerBIConfig = async () => {
    if (ownerSelected) {
      try {
        setSaving(true);
        await RemovePowerBIConfigUseCase(service, ownerSelected);
        setOwnerSelected(undefined);
        getData();
      } catch {
        toast('Ocorreu um erro ao remover a configuração do local selecionado. Tente novamente!', 'error');
      } finally {
        setSaving(false);
      }
    }
  }

  const handleSavePowerBIConfig = async (ownerId: string, data: IPowerBI[]) => {
    const parseData = data.map((item) => ({
      ...item,
      userDocument: userDocument,
    } as IPowerBIRequest));

      try {
        setSaving(true);
        await SavePowerBIConfigUseCase(service, ownerId, parseData);
        setConfigModalOpened(false);
        getData();
      } catch {
        toast('Ocorreu um erro ao salvar a configuração. Tente novamente!', 'error');
      } finally {
        setSaving(false);
      }
  }

  return {
    handleRemovePowerBIConfig,
    setOwnerSelected,
    handleSavePowerBIConfig,
    setConfigModalOpened,
    setConfigSelected,
    getData,
    setHistoryModalOpened,
    setDeleteModalOpened,
    deleteModalOpened,
    historyModalOpened,
    configSelected,
    configModalOpened,
    ownerSelected,
    saving,
    data,
    loading,
    currentPage,
    biSolicitations
  }
}
