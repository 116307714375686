import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditGeneralParamsUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/generalParams/EditGeneralParamsUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IDeviceProfile } from '../../interfaces/IDeviceProfile';

const UseGeneralParams = (path: string = "/private/pdv/perfil") => {
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal()

    const editGeneralParams = useCallback(async (profileId: string, values: IDeviceProfile) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                await EditGeneralParamsUseCase(deviceProfileService, currentLocal.id, profileId, values);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);

            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        editGeneralParams,
    })
}

export default UseGeneralParams