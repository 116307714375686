import PageHeader from "components/ui/pageHeader/PageHeader";
import NewEventsPage from "modules/events/presentation/pages/newEvents/NewEventsPage";
import Layout from "components/layout/presentation/Layout";

const AddEvents = () => {
  return (
    <Layout>
      <PageHeader title="Adicionar Evento" />
      <NewEventsPage />
    </Layout>
  );
};

export default AddEvents;
