import { GetCollaboratorsScheduleResponseDto } from "modules/schedule/domain/dto/collaboratorSchedule/GetCollaboratorsScheduleDto";
import { ICollaboratorScheduleItem } from "modules/schedule/presentation/pages/collaboratorScheduleList/ICollaboratorList";
const GetCollaboratorsByLocalIdUseCase = async (
    service: { getCollaboratorsByLocalId: (collaboratorId: string) => Promise<GetCollaboratorsScheduleResponseDto> },
    localId: string
): Promise<ICollaboratorScheduleItem[]> => {

    const response = await service.getCollaboratorsByLocalId(localId);
    return (response.items)
}
export default GetCollaboratorsByLocalIdUseCase