import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './SaleNowPage.module.scss'
import { UseTerminalPage } from './hooks/UseTerminalPage';
import { IDeviceTerminalItemResponse } from '../application/dtos/GetDevicesTerminalResponse';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { terminaUrl } from 'Enviroment';

export interface ISaleNowPageProps {
    //propertys
}
const SaleNowPage: FC<ISaleNowPageProps> = () => {
    const { devices: terminalDevices, getAdminOperator } = UseTerminalPage();
    const { currentLocal } = useLocal();

    const iframeRef = useMemo(() => React.createRef<HTMLIFrameElement>(), []);

    const currentQrcodeLink = useCallback((terminalDevice: IDeviceTerminalItemResponse) => `${terminaUrl ?? "http://terminal.meep.cloud"}/iframe/${currentLocal?.id}/${terminalDevice.device.id}`, [currentLocal]);

    const onFrameOperatorSolicitation = useCallback(async () => {
        const response = await getAdminOperator();
        iframeRef?.current?.contentWindow?.postMessage(JSON.stringify({ action: 'setOperator', id: response?.id }), '*');
    }, [getAdminOperator, iframeRef]);

    const onLoadIframe = useCallback(() => {
        if (!iframeRef.current) return;
        const timer = setTimeout(async () => {
            const response = await getAdminOperator();
            iframeRef?.current?.contentWindow?.postMessage(JSON.stringify({ action: 'approve', operatorId: response?.id }), '*');
        }, 500);
        return () => clearTimeout(timer);
    }, [getAdminOperator, iframeRef]);


    // const onMessage = useCallback((event: MessageEvent) => {
    //     // { action: "approve-terminal", solicitationId: solicitationId, deviceId, localId }
    //     const { action, solicitationId, deviceId, localId } = event.data;
    //     if (action === "approve-terminal") {
    //         const service = TerminalService();
    //         PostApproveSolicitationUseCase(service, solicitationId, deviceId);
    //     }
    // },[]);
    const onMessage = useCallback((event: MessageEvent) => {
        // { action: "approve-terminal", solicitationId: solicitationId, deviceId, localId }
        // if(event.origin !== terminaUrl) return;
        console.log("RECEIVED", event.data);
        try {
            const action = JSON.parse(event.data).action;
            if (action === "approve-terminal") {
                onLoadIframe();
            } else if (action === "getOperator") {
                onFrameOperatorSolicitation();
            }

        } catch {

        }
    }, [onFrameOperatorSolicitation, onLoadIframe]);

    useEffect(() => {
        window.addEventListener("message", onMessage);
        return () => window.removeEventListener("message", onMessage);
    }, [onMessage]);

    return (
        <div id={styles.SaleNowPage} >
            {/* {<Button onClick={onLoadIframe}> Aprovar terminal </Button>} */}
            <div className={styles.container} >
                {
                    !!terminalDevices?.length &&
                    <iframe ref={iframeRef} allowFullScreen src={currentQrcodeLink(terminalDevices[0])} title="SaleNow" />
                }
            </div>
        </div>
    )
}
export default SaleNowPage