import React, { FormEvent, useCallback, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import { ISignupRequest } from "modules/auth/models/dto/ISignupRequest";
import { SignupApi } from "services/api/auth/SignupApi";
import { ISignupFormErrors, SignupValidation } from "../validate/SignupValidation";
import PostSignupUseCase from "modules/auth/application/useCases/PostSignupUseCase";

export interface ISignupValues {
  confirmarSenha: string;
  cpf: string;
  email: string;
  nome: string;
  senha: string;
}

const signupApi = SignupApi();

export const UseSignupPage = () => {
  const { showLoading, hideLoading, toast } = useUi();
  const { push } = useHistory();

  const [values, setValues] = useState<ISignupValues>({} as ISignupValues);
  const [errors, setErrors] = useState<ISignupFormErrors>({});

  const onClickSignup = useCallback(
    async (values: ISignupRequest) => {
      if (SignupValidation(values, setErrors)) {
        try {
          showLoading();
          await PostSignupUseCase(signupApi, values);
          toast("Cadastro realizado com sucesso!", "success");
          push("/login");
        } catch (error: any) {
          if (error.response.data.errors[0].messages[0]) {
              return toast(error.response.data.errors[0].messages[0], "error");
          }
        } finally {
          hideLoading();
        }
      }
    },
    [hideLoading, showLoading, toast, push]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onClickSignup(values);
    },
    [values, onClickSignup]
  );

  const goBackLogin = useCallback(() => {
    push("/login");
  }, [push]);

  const changeHandle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (ev.target.name === "cpf") {
        return setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value.replace(/\D/g, '') }));
      }
      setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    },
    []
  );

  return {
    onClickSignup,
    values,
    submitHandle,
    changeHandle,
    goBackLogin,
    errors,
    setErrors,
  };
};
