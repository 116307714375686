import { Radio } from "@material-ui/core";
import { IDeviceProfile } from "modules/equipamento/domain/dto/getDeviceById/IGetDeviceByIdResponse";
import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { FC, useCallback, useEffect, useState } from "react";
import { IDeviceItem } from "../deviceList/interfaces/IDeviceItem";
import SucessButton from "../../../../config/deviceConfig/presentation/components/sucessButton/SucessButton";
import styles from "./SetDeviceProfileForm.module.scss";
import "./SetDeviceProfileForm.css";
import FormContainer from "../formContainer/FormContainer";

export interface IDeviceProfileFormProps {
  //propertys
  deviceProfileList?: IDeviceProfile[];
  defaultValue?: string;
  onClickClose: () => void;
  onClickNext: (
    profileId: string,
    selectedDevices: (IDeviceItem & { enabled: boolean })[]
  ) => void;
  onClickBack: () => void;
  sucessAlert?: boolean;
  currentStep?: Number;
  // deviceList?: IDeviceItem[];
  selectedDevice?: IDeviceItem[];
}
const SetDeviceProfileForm: FC<IDeviceProfileFormProps> = ({
  selectedDevice,
  deviceProfileList,
  sucessAlert,
  defaultValue,
  currentStep,
  onClickClose,
  onClickNext,
  onClickBack,
}) => {
  const [selectedProfileId, setSelectedProfileId] = useState(
    defaultValue || !!deviceProfileList?.length
      ? deviceProfileList?.[0].id
      : undefined
  );
  const [modified, setModified] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setSelectedProfileId(defaultValue);
    } else {
      const defaultValue = deviceProfileList?.find((item) => item.isDefault);
      if (defaultValue) {
        setSelectedProfileId(defaultValue.id);
      }
    }
  }, [defaultValue, deviceProfileList]);

  const onClickCheck = useCallback((id: string) => {
    setModified(true);
    setSelectedProfileId(id);
  }, []);

  const isMassive = currentStep !== FormDeviceStep.PROFILE; //rever

  return (
    <div id={styles.DeviceProfileForm}>
      <FormContainer
        onClickClose={onClickClose}
        // onClickBack={onClickBack}
        onClickNext={() =>
          onClickNext(
            selectedProfileId!,
            selectedDevice?.map((item) => ({ ...item, enabled: true }))!
          )
        }
        title={
          <>
            Vincular <b>PDV</b>
          </>
        }
        disabledNext={!selectedDevice?.length}
        footerProps={{
          labelNext: "Concluir",
          labelBack: !isMassive ? "Voltar" : "Cancelar",
        }}
      >
        <div className={styles.container}>
          {sucessAlert && (
            <SucessButton label="Equipamento adicionado com sucesso"></SucessButton>
          )}
          <span>
            Selecione em qual Perfil de Venda você deseja vincular seus
            equipamentos.
          </span>

          {!!deviceProfileList?.length ? (
            <>
              {deviceProfileList.map((item) => (
                <div
                  key={item.id}
                  onClick={() => onClickCheck(item.id)}
                  className={styles.button}
                >
                  <Radio
                    onChange={(_, checked) => {
                      onClickCheck(item.id);
                    }}
                    checked={item.id === selectedProfileId}
                  />
                  <label>{item.name}</label>
                  {item.isDefault && (
                    <span className={styles.defaultProfile}>
                      (Perfil de venda padrão)
                    </span>
                  )}
                </div>
              ))}
              <div className={styles.selectedDevices}>
                <span>Equipamentos selecionados:</span>
                <div>
                  {selectedDevice?.map((device) => (
                    <div key={device.id}>
                      {device.name || "SEM TAG"} ({device.type.name})
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div>Nenhum perfil registrado</div>
          )}
        </div>
      </FormContainer>
    </div>
  );
};
export default SetDeviceProfileForm;
