import { useState } from 'react';
import { IPaginatedTaxItem } from '../../interfaces/IPaginatedTax';
import { ITaxProducts } from '../../interfaces/ITaxProducts';
import styles from './TaxItem.module.scss';
import { ClickAwayListener, Icon, Popper } from '@material-ui/core';
import { GetTaxProductsUseCase } from 'modules/invoice/application/useCases/GetTaxProductsUseCase';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useHistory } from 'react-router-dom';
import { RightDrawer } from 'components/layout/presentation/rightDrawer/RightDrawer';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { TestNfceUseCase } from 'modules/invoice/application/useCases/TestNfceUseCase';
import { TestNfeUseCase } from 'modules/invoice/application/useCases/TestNfeUseCase';
import { ExpandLess, ExpandMore, MoreVert } from '@material-ui/icons';
import UseDimension from 'components/dimension/UseDimension';

interface TaxItemProps {
    item: IPaginatedTaxItem;
    onDelete: (id: number) => Promise<void>;
}

const service = InvoiceApi();

export const TaxItem = ({ item, onDelete }: TaxItemProps) => {
    const [products, setProducts] = useState<ITaxProducts[]>([]);
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const history = useHistory();
    const { toast } = useUi();

    const getData = async () => {
        try {
            setLoading(true);
            const response = await GetTaxProductsUseCase(service, currentLocal!.id, item.ncm, item.cfop);
            setProducts(response);
        } finally {
            setLoading(false);
        }
    }

    const handleExpand = () => {
        setOpened(prev => !prev);
        if (!opened) {
            getData();
        }
    }

    const handleClick = (event?: React.MouseEvent<HTMLElement>, show?: boolean) => {
        if (show)
            setAnchorEl(event!.currentTarget);
        else
            setAnchorEl(null);
    };

    const deleteTax = async () => {
        try {
            setFormLoading(true);
            await onDelete(item.id);
            setDeleteConfirmationModalOpened(false);
        } catch {
            toast('Ocorreu um erro ao deletar o registro.', 'error');
        } finally {
            setFormLoading(false);
        }
    }

    const testNFCe = async () => {
        try {
            setFormLoading(true);
            await TestNfceUseCase(service, currentLocal!.id, item.id.toString());
            toast('Teste de NFCe realizado com sucesso.', 'success');
        } catch {
            toast('Ocorreu um erro ao realizar o teste.', 'error');
        } finally {
            setFormLoading(false);
        }
    }

    const testNFe = async () => {
        try {
            setFormLoading(true);
            await TestNfeUseCase(service, currentLocal!.id, item.id.toString());
            toast('Teste de NFe realizado com sucesso.', 'success');
        } catch {
            toast('Ocorreu um erro ao realizar o teste.', 'error');
        } finally {
            setFormLoading(false);
        }
    }

    const mountPopover = () => {
        return (
            <div className={styles.popoverContainer}>
                <button aria-describedby={id} type="button" onClick={ev => handleClick(ev, true)}>
                    <MoreVert />
                </button>
                {
                    open &&
                    <ClickAwayListener onClickAway={() => handleClick(undefined, false)}>
                        <Popper keepMounted={false} id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                            <div className={styles.popover}>
                                <button disabled={formLoading} onClick={() => history.push(`/private/invoice/tax/edit/${item.id}`)}>
                                    <Icon className={styles.icon}>edit</Icon>
                                    <p>Editar</p>
                                </button>
                                <button disabled={formLoading} onClick={testNFe}>
                                    <Icon className={styles.icon}>science</Icon>
                                    <p>Testar emissão de NF-e (Homologação)</p>
                                </button>
                                <button disabled={formLoading} onClick={testNFCe}>
                                    <Icon className={styles.icon}>science</Icon>
                                    <p>Testar emissão de NFC-e (Homologação)</p>
                                </button>
                                <button disabled={formLoading} onClick={() => {
                                    setDeleteConfirmationModalOpened(true);
                                    setAnchorEl(null);
                                }}>
                                    <Icon className={styles.icon}>delete</Icon>
                                    <p>Excluir</p>
                                </button>
                            </div>
                        </Popper>
                    </ClickAwayListener>
                }
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {dimensions.width > 600 
                ? (
                    <div className={styles.row}>
                        <div className={styles.column}>{item.ncm}</div>
                        <div className={styles.column}>{item.cest}</div>
                        <div className={styles.column}>{item.cfop}</div>
                        <div className={styles.column}>{item.sourceId}</div>
                        <div className={styles.column}>{item.icmsAliquota}</div>
                        <div className={styles.column}>{item.pisTaxSituationId}</div>
                        <div className={styles.column}>{item.pisAliquotaPercentual}</div>
                        <div className={styles.column}>{item.cofinsTaxSituationId}</div>
                        <div className={styles.column}>{item.cofinsAliquotaPercentual}</div>
                        <div className={styles.column}>{item.ipiTaxSituationId}</div>
                        <div className={styles.column}>{item.ipiAliquotaPercentual}</div>
                        <div className={styles.column}>{item.state}</div>
                        <div className={`${styles.column} ${styles.fixed}`}>
                            <button onClick={handleExpand}>
                                {opened ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                        <div className={`${styles.column} ${styles.fixed}`}>
                            {mountPopover()}
                        </div>
                    </div>
                ) : (
                    <div className={styles.contentMobile}>
                        <div>
                            <div/>
                            {mountPopover()}
                        </div>
                        <div>
                            <span className={styles.title}>NCM</span>
                            <span>{item.ncm}</span>
                        </div>
                        <div>
                            <span className={styles.title}>CEST</span>
                            <span>{item.cest}</span>
                        </div>
                        <div>
                            <span className={styles.title}>CFOP</span>
                            <span>{item.cfop}</span>
                        </div>
                        <div>
                            <span className={styles.title}>Origem</span>
                            <span>{item.sourceId}</span>
                        </div>
                        <div>
                            <span className={styles.title}>ICMS</span>
                            <span>{item.icmsAliquota}</span>
                        </div>
                        <div>
                            <span className={styles.title}>CST PIS</span>
                            <span>{item.pisTaxSituationId}</span>
                        </div>
                        <div>
                            <span className={styles.title}>Alíquota PIS</span>
                            <span>{item.pisAliquotaPercentual}</span>
                        </div>
                        <div>
                            <span className={styles.title}>CST COFINS</span>
                            <span>{item.cofinsTaxSituationId}</span>
                        </div>
                        <div>
                            <span className={styles.title}>Alíquota COFINS</span>
                            <span>{item.cofinsAliquotaPercentual}</span>
                        </div>
                        <div>
                            <span className={styles.title}>CST IPI</span>
                            <span>{item.ipiTaxSituationId}</span>
                        </div>
                        <div>
                            <span className={styles.title}>Alíquota IPI</span>
                            <span>{item.ipiAliquotaPercentual}</span>
                        </div>
                        <div>
                            <span className={styles.title}>UF</span>
                            <span>{item.state}</span>
                        </div>
                        <div>
                            <div/>
                            <button onClick={handleExpand}>
                                {opened ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                    </div>
                )
            }
            {
                opened && (
                    <div className={styles.content}>
                        {loading ? <img src="/assets/icon/saas/loading.gif" alt="" className={styles.loader} /> :
                            products.length === 0 ? <span className={styles.noData}>Nenhum produto encontrado</span> :
                                products.map(product => (
                                    <span key={product.id}>{product.name}</span>
                                ))
                        }
                    </div>
                )
            }

            <RightDrawer titleBold='Excluir' open={deleteConfirmationModalOpened} onClose={() => setDeleteConfirmationModalOpened(false)}>
                <div className={styles.deleteContainer}>
                    <div className={styles.deleteContent}>
                        <p>Deseja realmente excluir este registro?</p>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <button onClick={() => setDeleteConfirmationModalOpened(false)}>Cancelar</button>
                        <button disabled={formLoading} onClick={deleteTax}>
                            {
                                formLoading ? 'Excluindo...' : 'Excluir'
                            }
                        </button>
                    </div>
                </div>
            </RightDrawer>
        </div>
    )
}