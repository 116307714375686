

export enum NotificationType {
    DEFAULT = 1,
    MANDATORY = 2,
    BANNER = 3,
    TERMINAL_SOLICITATION = 5


}
