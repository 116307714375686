import { IGetPrintQueueResponse } from "modules/printQueue/domain/dto/IGetPrintQueueResponse";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";
import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";
import { IPrintQueueFilter } from "modules/printQueue/presentation/components/printQueueFilter/interfaces/IPrintQueueFilter";


const GetPrintQueueUseCase = async (service: IPrintQueueApiService, localId: string, pagination?: { page?: number, pageSize?: number }, filter?: IPrintQueueFilter): Promise<IGetPrintQueueResponse> => {
   
    const request =  ParseFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getPrintQueue(request)
    return (response)
}
export default GetPrintQueueUseCase