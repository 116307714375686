import { IBodyOperacoes, IPostOperacoesService } from "../interfaces/IGestaoVendasService";

const PostOperacoes = async (
  accountService: IPostOperacoesService,
  id: string,
  body: IBodyOperacoes
): Promise<void> => {
  return await accountService.postOperacao(id, body);
};

export default PostOperacoes;