import qs from "qs";
import { api } from "../Api";
import { IAuthApi } from "modules/auth/application/interfaces/_api/IAuthApi";
import { IAuthResponse } from "modules/auth/models/dto/IAuthResponse";
import { IAuthRequest } from "modules/auth/models/dto/IAuthRequest";
import axios from "axios";
import { IUserInfoResponse } from "modules/auth/models/dto/IGetUserInfo";
import { BuscarInformacaoDeUsuario } from "./antiCorruption/BuscarInformacaoDeUsuario";

type IAuthMeepResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
  locais: string;
  usuarioMeep: string;
  roles: string;
  RequiredMFA?: boolean;
};

type ILocalAuthMeepResponse = {
  nomeLocal: string;
  localId: string;
};
type IUsuarioAuthMeepResponse = {
  id: string;
  dataNascimento: string;
  sexo: string;
  cpf: string;
  nome: string;
  email: string;
  dataCriacao: string;
  facebookId: string;
  pin: string;
  RG: string;
  pinBloqueado: string;
  codigoUnico: string;
  endereco: [
    {
      usuarioMeepId: string;
      enderecoId: string;
      descricao: string;
      CEP: string;
      logradouro: string;
      numero: string;
      complemento: string;
      bairro: string;
      cidadeId: string;
      cidadeNome: string;
      estadoUF: string;
    }
  ];
  ddd: string;
  telefone: string;
};

export const AuthApi = (): IAuthApi => {

  const AuthMeepToAuth = (
    authMeepResponse: IAuthMeepResponse
  ): IAuthResponse => {
    const locaisJson = (authMeepResponse.locais ? JSON.parse(authMeepResponse.locais) : []) as ILocalAuthMeepResponse[];
    const usuarioJson = (authMeepResponse.usuarioMeep ? JSON.parse(authMeepResponse.usuarioMeep) : {}) as IUsuarioAuthMeepResponse;

    const newResponse: IAuthResponse = {
      access_token: authMeepResponse.access_token,
      requiredMfa: authMeepResponse.RequiredMFA,
      expires_in: authMeepResponse.expires_in,
      token_type: authMeepResponse.token_type,
      locals: locaisJson.map((item) => ({
        id: item.localId,
        name: item.nomeLocal,
      })),
      user: {
        id: usuarioJson.id,
        name: usuarioJson.nome,
        email: usuarioJson.email,
        birthday: usuarioJson.dataNascimento,
        document: usuarioJson.cpf,
        phoneNumber: `${usuarioJson.ddd ?? ""}${usuarioJson.telefone ?? ""}`,
     },
      roles: authMeepResponse.roles?.split(";"),
    };

    return newResponse;
  };

  const login = async (
    userName: string,
    password: string,
  ): Promise<IAuthResponse> => {
    const formData = qs.stringify({
      userName: userName,
      password: password,
      grant_type: "password",
    } as IAuthRequest);
    const response = await axios.post<IAuthMeepResponse>(
      process.env.REACT_APP_API_URL?.replace(/api$/, "token") ?? "",
      formData
    );
    localStorage.setItem("@token", JSON.stringify(response.data))
    localStorage.setItem('@menuOpened', String(true));
    return AuthMeepToAuth(response.data);
  };

  const loginMfa = async (
    userName: string,
    password: string,
  ): Promise<IAuthResponse> => {
    const response = await axios.post<IAuthMeepResponse>(
      `${process.env.REACT_APP_API_URL}/mfa/login`,
      { userName, password }
    );

    const auth = AuthMeepToAuth(response.data);
    if (!auth.requiredMfa) {
      localStorage.setItem("@token", JSON.stringify(response.data))
      localStorage.setItem('@menuOpened', String(true));
    }
    return auth;
  };

  const validateMfaCode = async (
    userName: string,
    code: string,
  ): Promise<IAuthResponse> => {
    const response = await axios.post<IAuthMeepResponse>(
      `${process.env.REACT_APP_API_URL}/mfa/validate`,
      { userName, code }
    );
    localStorage.setItem("@token", JSON.stringify(response.data))
    localStorage.setItem('@menuOpened', String(true));
    return AuthMeepToAuth(response.data);
  };

  const getUserInfo = async (token: string): Promise<IUserInfoResponse> => {
    const response = await BuscarInformacaoDeUsuario(api, token);
    return response;
  };

  const resendMfaCode = async (username: string): Promise<void> => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/mfa/sendcodetoemail`,
        { username }
      );
    } catch (error) {
      console.error("Erro ao reenviar o código MFA:", error);
      throw error;
    }
  };

  return {
    login,
    loginMfa,
    getUserInfo,
    validateMfaCode,
    resendMfaCode,
  };
};
