import { CalendarToday, PointOfSale } from '@mui/icons-material'
import styles from './TitlePeriod.module.scss'
import { format, parseISO } from 'date-fns'
import { IGetSessionsItemResponse } from 'modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse'

interface TitlePeriodProps {
    text?: string
    period: string
    cashiers?: IGetSessionsItemResponse
}

const TitlePeriod = ({ text, period, cashiers }: TitlePeriodProps) => {
    return (
        <div id={styles.TitlePeriod}>
            {period === 'day' ? (
                <>
                    <div className={styles.icon}> <CalendarToday /></div>
                    {text && format(parseISO(text), 'dd/MM/yyyy HH:mm')}
                </>
            ) : (
                <>
                    <div className={styles.icon}> <PointOfSale /></div>
                    {cashiers?.name}
                </>
            )}

        </div>
    )
}

export default TitlePeriod