import AutoComplete from "components/ui/autoComplete/AutoComplete";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { IPromoters } from "modules/events/presentation/components/guestListForm/interfaces/IPromoters";
import React, { FC } from "react";
import { IPromotersFormErrors } from "../../validation/IGuestListFormErros";
import styles from "./PromotersForm.module.scss";

interface IPromotersFormProps {
  mainPromoter?: IPromoters;
  promotersList: IPromoters[];
  handleChangeMainPromoter: (promoter: IPromoters) => void;
  errors: IPromotersFormErrors;
  promotersWithAccess: IPromoters[]
  changePromotersHandle: (promoters: IPromoters[]) => void
}

export const PromotersForm: FC<IPromotersFormProps> = ({
  handleChangeMainPromoter,
  mainPromoter,
  promotersList,
  errors,
  promotersWithAccess,
  changePromotersHandle
}) => {
  return (
    <form id={styles.PromotersForm}>
      <FormItemContainer label="Promoter responsável">
        <AutoComplete
          variant="outlined"
          value={mainPromoter}
          placeholder="Selecione o promoter responsável"
          options={promotersList}
          getOptionLabel={(option) => option.name}
          onChange={(_evt, item) => handleChangeMainPromoter(item as IPromoters)}
          error={!!errors.mainPromoter}
          helperText={errors.mainPromoter}
          />
      </FormItemContainer>
      {/* <FormItemContainer label="Promoters com permissão">
        <AutoComplete
          className={styles.multiple}
          variant="outlined"
          filterSelectedOptions
          multiple
          limitTags={2}
          placeholder={promotersWithAccess.length > 0 ? undefined: "Selecione os promoters"}
          disableCloseOnSelect
          noOptionsText="Sem opções"
          options={promotersList}
          value={promotersWithAccess}
          getOptionLabel={(option) => option.name}
          onChange={(_evt, item) => changePromotersHandle(item as IPromoters[])}
        />
      </FormItemContainer> */}
    </form>
  );
};
