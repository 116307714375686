import { AddCircle } from "@mui/icons-material";
import { MenuItem, Pagination, TextField } from "@mui/material";
import Button from "components/ui/Button/Button";
import { FC } from "react";
import LinkFilter from "../components/linkFilter/LinkFilter";
import PaymentLinkHeader from "../components/paymentLinkHeader/PaymentLinkHeader";
import PaymentLinkList from "../components/paymentLinkList/PaymentLinkList";
import PaymentLinkSideSheet from "../components/paymentLinkSideSheet/PaymentLinkSideSheet";
import styles from "./PaymentLinkPage.module.scss";
import { UsePaymentLinkPage } from "./UsePaymentLinkPage";

const PaymentLinkPage: FC = () => {
  
  const {
    handleFilter,
    handlePagination,
    isLoading,
    openSidesheet,
    paymentLinkList,
    handleOrderBy,
    columns,
    params,
    countPage,
    loadAnimation,
    sideSheetRef,
    getPaymentLinkList
  } = UsePaymentLinkPage();

  return (
    <div id={styles.PaymentLinkPage}>
      <div className={styles.buttonAddLink}>
        <Button onClick={() => openSidesheet()}>
          Adicionar link
          <AddCircle />
        </Button>
      </div>
      <LinkFilter onSubmit={handleFilter} />

      {isLoading ? (
        <>{loadAnimation}</>
      ) : !paymentLinkList ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            <b>Falha ao obter links</b> de pagamento.
          </span>
          <span>Tente novamente!</span>
        </div>
      ) : paymentLinkList.records.length === 0 ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            Você não possui <b>link de pagamento</b> cadastrado!
          </span>
        </div>
      ) : (
        <>
          <PaymentLinkHeader
            handleFilterOrder={handleOrderBy}
            columns={columns}
          />
          <PaymentLinkList
            paymentLinkList={paymentLinkList}
            columns={columns}
            openSidesheet={openSidesheet}
            getPaymentLinkList={getPaymentLinkList}
          />
          <div className={styles.conteinerPagination}>
            <div className={styles.boxMultiplePage}>
              <span>Exibir</span>
              <TextField
                variant="outlined"
                size="small"
                select
                value={params.pagination?.pageSize}
                onChange={(ev) => handlePagination({page: 0, pageSize: Number(ev.target.value)})}
              >
                <MenuItem className={styles.textPage} value={10}>
                  10
                </MenuItem>
                <MenuItem className={styles.textPage} value={20}>
                  20
                </MenuItem>
                <MenuItem className={styles.textPage} value={50}>
                  50
                </MenuItem>
              </TextField>
            </div>
            <Pagination
              variant="outlined"
              className={styles.paginationNumber}
              shape="rounded"
              count={countPage}
              sx={{
                ".MuiPaginationItem-root": {
                  borderColor: "#BDBDBD",
                  color: "#001537",
                },
                ".Mui-selected": {
                  fontWeight: "bold",
                  borderColor: "#001537",
                },
              }}
              onChange={(_, page) =>
                handlePagination({page: page - 1, pageSize: params.pagination?.pageSize})
              }
              page={params.pagination?.page ? params.pagination?.page + 1 : 1}
            />
          </div>
        </>
      )}

      <PaymentLinkSideSheet ref={sideSheetRef} getPaymentLinkList={getPaymentLinkList}/>
    </div>
  );
};

export default PaymentLinkPage;
