import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import Layout from 'components/layout/presentation/Layout'
import SaasHomePage from 'modules/saasHome/presentation/SaasHomePage'
import { useEffect } from 'react';

export const SaasHome = () => {
    const { updateRouters } = useBreadcumbs();
    useEffect(() => {
        updateRouters([
            { title: "Início" },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

    return (
        <Layout limitedWidth>
            <SaasHomePage />
        </Layout>
    )
}
