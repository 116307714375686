import styles from './Settings.module.scss'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Close } from '@mui/icons-material'
import { UseMenuList } from '../menu/UseMenuList';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { IGetMenuResponse } from 'components/layout/domain/dto/IGetMenuResponse';

interface SettingsProps {
  onClose: () => void
  isMobile?: boolean
}

export const Settings = ({ onClose, isMobile }: SettingsProps) => {

  const { push } = useHistory();
  const { menuSettings } = UseMenuList();
  const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
  const location = useLocation();

  const [selected, setSelected] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  const onClickRoute = (route?: string) => {
    if (route) {
      push(route.trim());
      onClose();
    }
  };

  useEffect(() => {
    const currentMenuType = menuSettings.find(item => 
      item.submenus.find((it) => 
        it.submenus.length
          ? it.submenus.find((subIt) => subIt.route?.trim() === location.pathname)
          : it.route?.trim() === location.pathname
      )
    );
    setSelected(currentMenuType?.id ?? "");

    const currentTab = currentMenuType?.submenus.find(item =>
      item.submenus.length
        ? item.submenus.find((subIt) => subIt.route?.trim() === location.pathname)
        : item.route?.trim() === location.pathname
    );    

    if (currentTab?.submenus?.length) {
      setSelectedSubMenu(currentTab.submenus.find((item) => item.route?.trim() === location.pathname)?.id ?? "");
    }
    setSelectedMenu(currentTab?.id ?? "");
  }, [subTab, tab, location, menuSettings]);

  const menuSettingsList = useMemo(() => {
    const items = menuSettings.reduce((unique, o) => {
        if(!unique.some(obj => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
    }, [] as IGetMenuResponse[]);
    return items.filter((mock) => mock.route || mock.submenus.length) ?? [];
  }, [menuSettings]);

  return (
    <div id={styles.Settings}>
      {isMobile &&
        <div className={styles.containerTitle}>
          <h1>Configurações</h1>
          <Close onClick={onClose} />
        </div>
      }
      {menuSettingsList.map((item, index) => (
        <Fragment key={item.id}>
          <div 
            key={item.id}
            className={styles.link}
            onClick={() => onClickRoute(item.route)}
            style={{ marginTop: index === 0 ? "0" : "" }}
          >
            {selected === item.id ? <b>{item.title}</b> : item.title}
          </div>
          {item.submenus.length > 0 && item.submenus.map((subItem) => (
            <Fragment key={subItem.id}>
              <div 
                key={subItem.id}
                className={styles.subLink}
                onClick={() => onClickRoute(subItem.route)}
              >
                {selectedMenu === subItem.id ? <b>{subItem.title}</b> : subItem.title}
              </div>
              {subItem.submenus.length > 0 && subItem.submenus.map((it) => (
                <div 
                  key={it.id}
                  className={styles.subSubLink}
                  onClick={() => onClickRoute(it.route)}
                >
                  {selectedSubMenu === it.id ? <b>{it.title}</b> : it.title}
                </div>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  )
}
