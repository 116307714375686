import { z } from "zod";
import { IStockOperationValues } from "./StockOperationValues";
import { SupplyStockMovementType } from "modules/meepErp/models/stockMovement/_SupplyStockMovementType";


export const IInventoryOperationSchema = z.object({
    name: z.string({ message: "Nome é obrigatório" }).min(3, { message: "Nome deve ter no mínimo 3 caracteres" }),
    type: z.string({ message: "Tipo é obrigatório" }).min(1, { message: "Tipo é obrigatório" }),
    canUpdateInventory: z.boolean().optional(),
    inventoryMovementType: z.nativeEnum(SupplyStockMovementType).nullable().optional(),
    canUpdateInventoryCost: z.boolean().optional(),
}).superRefine((data, ctx) => {
    if (data.canUpdateInventory && !data.inventoryMovementType) {
        return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["InventoryMovementType"],
            message: "Tipo de Movimentação de Estoque é obrigatório"
        })
    }
});

// z.nativeEnum(InventoryMovementType, { message: "Tipo de Movimentação de Estoque é obrigatório" }).optional(),