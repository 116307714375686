import React, { useCallback, useEffect, useState } from 'react'
import { IHouseFlowFilter } from '../components/houseFlowFilter/components/interfaces/IHouseFlowFilter';
import { useHouseFlowList } from '../context/HouseFlowListContext';


export const UseHouseFlowPage = () => {

  const { houseFlowList, isLoading, setParams, params } = useHouseFlowList();

  const onChangeFilterHandle = useCallback((filter: IHouseFlowFilter) => {
    setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }));
  }, [setParams]);

  const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
    setParams((prev) => ({ ...prev, pagination }));
  }, [setParams]);

  const [values, setValues] = useState<IHouseFlowFilter>();

  return ({
    houseFlowList,
    isLoading,
    onChangePaginationHandle,
    params,
    setParams,
    onChangeFilterHandle,
    values,
    setValues
  });
}
