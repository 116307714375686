import { useEffect } from "react";
import UseFinancialInvoicesList from "../hooks/UseFinancialInvoicesList";

export const UseFinancialInvoicesPage = () => {

    const { getFinancialInvoiceList, financialInvoicesList, isLoading } = UseFinancialInvoicesList();

    useEffect(() => {
        getFinancialInvoiceList();
    }, [getFinancialInvoiceList]);   

    return (
        {
            financialInvoicesList,
            isLoading,
            getFinancialInvoiceList
        }
    )
}
