export interface IHouseFlowFilter {      
    sortType?: string,
    sortOrientation?: 'asc' | 'desc',   
    localId?: string,
    cardNumber?: string,
    cardId?: string,
    customerName?: string,
    customerCPF?: string,
    cardStatus?: EnumCardStatus
}

export enum EnumCardStatus {    
    INUSE = 1,
    IDLE,
    AVAILABLE
}