import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from './TransactionChart.module.scss';
import Utils from 'services/utils/Utils';
import { IFinancialMovements } from '../../interfaces/IAllMovements';

interface Props {
  transactions: IFinancialMovements[] | undefined;
}

const TransactionChart: React.FC<Props> = ({ transactions }) => { 
  const [selectedLine, setSelectedLine] = useState(null);
  
  const data = transactions?.map(transaction => ({
    date: Utils.toDateAndTime(transaction.date + "Z"),
    Crédito: transaction.credit,
    Débito: transaction.debit,   
    Dinheiro: transaction.money,
    PIX: transaction.pix,
    Voucher: transaction.voucher,
    Outros: transaction.other,
  }));

  const handleLineClick = (dataKey: any) => {
    setSelectedLine(selectedLine === dataKey ? null : dataKey);
  };

  return (
    <div id={styles.TransactionChart}>
      <div className={styles.top}>
        <p>Gráfico de movimentações</p>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend  verticalAlign="top" align="left" wrapperStyle={{top: 0}} />
          <Line 
            type="monotone" 
            dataKey="Crédito"
            stroke="#DE9C00" 
            strokeWidth={selectedLine === "credito" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "credito" ? 0.2 : 1}
            onClick={() => handleLineClick("credito")}
          />
          <Line 
            type="monotone" 
            dataKey="Débito"
            stroke="#955CFF" 
            strokeWidth={selectedLine === "debito" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "debito" ? 0.2 : 1}
            onClick={() => handleLineClick("debito")}
          />
          <Line 
            type="monotone" 
            dataKey="Dinheiro"
            stroke="#FF00A6" 
            strokeWidth={selectedLine === "dinheiro" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "dinheiro" ? 0.2 : 1}
            onClick={() => handleLineClick("dinheiro")}
          />
          <Line 
            type="monotone" 
            dataKey="PIX"
            stroke="#399C54" 
            strokeWidth={selectedLine === "pix" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "pix" ? 0.2 : 1}
            onClick={() => handleLineClick("pix")}
          />
          <Line 
            type="monotone" 
            dataKey="Voucher"
            stroke="#001537" 
            strokeWidth={selectedLine === "voucher" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "voucher" ? 0.2 : 1}
            onClick={() => handleLineClick("voucher")}
          />
          <Line             
            type="monotone" 
            dataKey="Outros"
            stroke="#32008E" 
            strokeWidth={selectedLine === "outros" ? 5 : 3}
            opacity={selectedLine && selectedLine !== "outros" ? 0.2 : 1}
            onClick={() => handleLineClick("outros")}
            
          />
        </LineChart>
      </ResponsiveContainer>
      <p className={styles.obs}><b>Obs.:</b> O valor de venda cashless não entra no total, uma vez que já está presente na venda de recarga/ativação do cashless nas formas Crédito/Débito/Dinheiro</p>
    </div>
  );
};

export default TransactionChart;