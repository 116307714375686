import { useEffect, useState } from "react";

import Layout from "components/layout/presentation/Layout";
import styles from './ToolsPage.module.scss';
import BaitFirstLoginModal from "../../components/baitFirstLoginModal/BaitFirstLoginModal";
import RenderIf from "components/renderIf/RenderIf";
import { SimpleBanner } from "components/widgets";
import ToolLinkCard from "../../components/toolLinkCard/ToolLinkCard";

const toolCards = [
    {
        type: 'Finanças',
        iconUrl: '/assets/icon/calculator-sparkling.svg',
        title: 'Calculadora iFood',
        description: 'Calcule o melhor preço de venda para seu produto já calculando o valor da taxa do iFood.',
        linkUrl: '/private/ferramentas/calculadora-ifood',
    }, {
        type: 'Finanças',
        iconUrl: '/assets/icon/coil-sparkling.svg',
        title: 'Calculadora de bobinas',
        description: 'Calcule a quantidade média de bobinas necessária para operar no seu negócio',
        linkUrl: '/private/ferramentas/calculadora-bobina',
    }, {
        type: 'Funcionalidade',
        iconUrl: '/assets/icon/menu-sparkling.svg',
        title: 'Cardápio digital',
        description: 'Permita que seus clientes vejam seu cardápio digital, façam pedidos e paguem online',
        linkUrl: '/private/solucoesOnline',
    }
]

const ToolsPage = () => {
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    useEffect(() => {
        const baitFirstLogin = localStorage.getItem('@baitFirstLogin');
        if (baitFirstLogin !== null) {
            setShowWelcomeModal(true);
            localStorage.removeItem('@baitFirstLogin');
        }
    }, []);

    return (
        <Layout limitedWidth>
            <SimpleBanner
                title="Ferramentas para o seu negócio"
                description="Acesse de forma rápida ferramentas que irão facilitar sua operação"
                imageUrl="/assets/icon/hammer.svg"
                linkText="Qual ferramenta faz sentido para mim?"
            />

            <div className={styles.toolsCardGrid}>
                {toolCards.map(card => (
                    <ToolLinkCard
                        key={card.title}
                        type={card.type}
                        iconUrl={card.iconUrl}
                        title={card.title}
                        description={card.description}
                        linkUrl={card.linkUrl}
                    />
                ))}
            </div>

            <RenderIf condition={showWelcomeModal}>
                <BaitFirstLoginModal closeFn={() => setShowWelcomeModal(false)} />
            </RenderIf>
        </Layout>
    );
}

export default ToolsPage;