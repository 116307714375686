
import { IUseCaseParams } from "../domain/interfaces/IUseCaseParams";
import { IRequestParams } from "../domain/interfaces/RequestParams";

export const makeFilterRequest = ({
    filter,
    page = 0,
    pageSize = 20,
    sortField,
    sortOrientation,
    exportFormat,
    localId
}: IUseCaseParams) => {
    const filterRequest: IRequestParams = {
        ...filter,
        page,
        pageSize,
        exportFormat,
        localId
    }

    filterRequest.sort = {
        type: sortField || 'productName',
        orientation: sortOrientation || 'desc'
    }

    return filterRequest;
}
