import { FC } from 'react'
import { ITurnoverTable } from './interfaces/ITurnoverTable';
import styles from './TurnoverTable.module.scss'
import { IlocalFilter } from '../filter/IFilter';
import { TurnoverTableItem } from './components/turnoverTableItem/TurnoverTableItem';
import { IGetDataParams } from '../../interfaces/IGetDataParams';

export interface ITurnoverTableProps {
    getData: ({ localId, page, pageSize, sortField, sortOrientation }: IGetDataParams) => Promise<ITurnoverTable>;
    selectedLocals: IlocalFilter[];
}

const TurnoverTable: FC<ITurnoverTableProps> = ({ getData, selectedLocals }) => {
    return (
        <div id={styles.TurnoverTable}>
            <div className={styles.container}>
                {selectedLocals.map(x => <TurnoverTableItem key={x.id} local={x} getData={getData} />)}
            </div>
        </div>
    )
}
export default TurnoverTable



