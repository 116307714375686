import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import DevicePage from "modules/equipamento/presentation/pages/DevicePage";

const Equipamento: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "PDV",
      },
      {
        label: "PDV",
        title: "Equipamento",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <DevicePage />
    </Layout>
  );
};
export default Equipamento;
