import { FC } from "react";
import styles from "./BoxInfo.module.scss";

export interface Props {
  title?: string;
  percentage?: string;
  total?: number;
  background?: string;
  color?: string;
  isLast?: boolean;
}

const BoxInfo: FC<Props> = ({ title, percentage, total, background, color, children, isLast }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title} style={ isLast ? { color: '#32008E' } : {}}>
          {title}
        </h1>
        <p className={styles.percentage} style={ isLast ? { color: '#32008E' } : {}}>
          {percentage}%
        </p>
        {/* <p className={styles.total}>
          {total}
        </p> */}
        {children}
      </div>
    </div>
  );
};

export default BoxInfo;
