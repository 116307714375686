import { IPerfilUpdateBody, IUpdatePerfilEventoService } from "./IPerfilService";

const UpdateAddPerfilEvento = async (
  accountService: IUpdatePerfilEventoService,
  id: string,
  body: IPerfilUpdateBody
): Promise<any> => {
  return await accountService.updateAddPerfilEvento(id, body);
};

export default UpdateAddPerfilEvento;