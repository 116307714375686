import { IGetDataParams } from 'modules/salesDashboard/presentation/interfaces/IGetDataParams';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IlocalFilter } from '../../../filter/IFilter';
import { ITurnoverTable } from '../../interfaces/ITurnoverTable';

const DEFAULT_PAGE_SIZE = 25;

const UseTurnoverTableItem = (local: IlocalFilter, getData: ({ localId, page, pageSize, sortField, sortOrientation }: IGetDataParams) => Promise<ITurnoverTable>) => {
    const [data, setData] = useState<ITurnoverTable>({} as ITurnoverTable);
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState(false);
    const [infiniteLoading, setInfiniteLoading] = useState(false);
    const loaderRef = useRef<HTMLDivElement>(null);
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const [hasNext, setHasNext] = useState(false);
    const [error, setError] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [sortOrientation, setSortOrientation] = useState('desc');
    const [sortField, setSortField] = useState('turn')

    const handleScroll = (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
        handleLoadDataByInifityScroll(ev.currentTarget);
    }

    const handleSort = (type: string) => {
        setSorting(true);
        setCurrentPage(0);
        let orientation = sortOrientation;
        let sortType = sortField;
        
        if (sortField === type) {
            orientation = sortOrientation === 'asc' ? 'desc' : 'asc';
            setSortOrientation(orientation);
        } else {
            sortType = type;
            setSortField(sortType);
        }

        fetchData(0, orientation, sortType);
    }

    const fetchData = useCallback((page, sortOrientation = 'desc', sortField = 'turn') => {
        setInfiniteLoading(true);

        (async () => {
            try {
                const response = await getData({ localId: local.id, page, pageSize: DEFAULT_PAGE_SIZE, sortOrientation, sortField });
                setData(prev => ({ ...response, records: !!page ? [...prev?.records || [], ...response.records] : [...response.records] }));
            } catch (err: any) {
                setError(err.message);
            } finally {
                setInfiniteLoading(false);
                setLoading(false);
                setSorting(false);
            }
        })();
    }, [getData, local.id])

    const handleLoadDataByInifityScroll = useCallback((currentTarget: HTMLDivElement) => {
        const treshold = 400;
        const loaderLeft = loaderRef.current?.getBoundingClientRect().left || 0;
        const offsetWidth = currentTarget.offsetWidth;

        if (loaderLeft - treshold < offsetWidth && !infiniteLoading && hasNext) {
            fetchData(currentPage + 1, sortOrientation, sortField);
            setCurrentPage(currentPage + 1);
        }
    }, [currentPage, fetchData, hasNext, infiniteLoading, sortField, sortOrientation])

    useEffect(() => {
        setHasNext(data.totalRecords > data.records?.length);
    }, [data]);

    useEffect(() => {
        fetchData(0);
    }, [fetchData]);

    return {
        DEFAULT_PAGE_SIZE,
        loading,
        sorting,
        data,
        hasNext,
        loaderRef,
        scrollViewRef,
        error,
        handleScroll,
        handleSort
    }
}

export { UseTurnoverTableItem }