import { subMinutes } from "date-fns";
import GetBankDetail from "modules/contaDigital/application/useCases/BankDetailUseCase";
import { BankDetailDepositResponse } from "modules/contaDigital/models/dtos/BankDetailDepositResponse";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetBuscarSaldoAtualUseCase from "modules/mainDashboard/domain/application/useCases/GetBuscarSaldoAtualUseCase";
import GetDayPaymentsPerHourUseCase from "modules/mainDashboard/domain/application/useCases/GetDayPaymentsPerHourUseCase";
import GetDayPaymentsUseCase from "modules/mainDashboard/domain/application/useCases/GetDayPaymentsUseCase";
import GetDayResumeUseCase from "modules/mainDashboard/domain/application/useCases/GetDayResumeUseCase";
import GetDayTopProductsUseCase from "modules/mainDashboard/domain/application/useCases/GetDayTopProductsUseCase";
import GetMonthResumePerDayUseCase from "modules/mainDashboard/domain/application/useCases/GetMonthResumePerDayUseCase";
import GetMonthResumeUseCase from "modules/mainDashboard/domain/application/useCases/GetMonthResumeUseCase";
import GetYearResumePerMonthUseCase from "modules/mainDashboard/domain/application/useCases/GetYearResumePerMonthUseCase";
import { IGetBuscarSaldoAtualResponse } from "modules/mainDashboard/domain/dtos/IGetBuscarSaldoAtualResponse";
import { IGetDayPaymentsPerHourResponse } from "modules/mainDashboard/domain/dtos/IGetDayPaymentsPerHourResponse";
import { IGetDayPaymentsResponse } from "modules/mainDashboard/domain/dtos/IGetDayPaymentsResponse";
import { IGetDayResumeResponse } from "modules/mainDashboard/domain/dtos/IGetDayResumeResponse";
import { IGetDayTopProductsResponse } from "modules/mainDashboard/domain/dtos/IGetDayTopProductsResponse";
import { IGetMonthResumePerDayResponse } from "modules/mainDashboard/domain/dtos/IGetMonthResumePerDayResponse";
import { IGetMonthResumeResponse } from "modules/mainDashboard/domain/dtos/IGetMonthResumeResponse";
import { IGetYearResumePerMonthResponse } from "modules/mainDashboard/domain/dtos/IGetYearResumePerMonthResponse";
import GetSessionsUseCase from "modules/salesDashboard/application/useCases/GetSessionsUseCase";
import { IGetSessionsListResponse } from "modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse";
import { useCallback, useEffect, useState } from "react";
import DigitalAccountApi from "services/api/digitalAccount/DigitalAccountApi";
import { MainDashboardApi } from "services/api/mainDashboard/MainDashboardApi";
import SalesDashboardService from "services/api/salesDashboard/SalesDashboardService";

const accountService = DigitalAccountApi();
const mainDashboardService = MainDashboardApi();
const salesDashboardService = SalesDashboardService();

const UseMainDashboard = () => {
    const { currentLocal } = useLocal();
    const [dayResume, setDayResume] = useState<IGetDayResumeResponse | undefined>()
    const [cashiers, setCashiers] = useState<IGetSessionsListResponse | undefined>(undefined)
    const [topProducts, setTopProducts] = useState<IGetDayTopProductsResponse | undefined>()
    const [dayPayments, setDayPayments] = useState<IGetDayPaymentsResponse | undefined>()
    const [monthResume, setMonthResume] = useState<IGetMonthResumeResponse | undefined>()
    const [monthResumePerDay, setMonthResumePerDay] = useState<IGetMonthResumePerDayResponse | undefined>()
    const [yearResumePerMonth, setYearResumePerMonth] = useState<IGetYearResumePerMonthResponse | undefined>()
    const [saldoAtual, setSaldoAtual] = useState<IGetBuscarSaldoAtualResponse | undefined>()
    const [period, setPeriod] = useState('day')
    const [filter, setFilter] = useState<string | undefined>(new Date().toISOString())
    const [dayPaymentsPerHour, setDayPaymentsPerHour] = useState<IGetDayPaymentsPerHourResponse | undefined>()
    const [loadingYearResumePerMonth, setLoadingYearResumePerMonth] = useState(true)
    const [loadingTopProducts, setLoadingTopProducts] = useState(true)
    const [loadingDayPayments, setLoadingDayPayments] = useState(true)
    const [loadingDayPaymentsPerHour, setLoadingDayPaymentsPerHour] = useState(true)
    const [loadingMonthResumePerDay, setLoadingMonthResumePerDay] = useState(true)
    const [bankDetail, setBankDetail] = useState<
        BankDetailDepositResponse[] | undefined
    >();

    const getDayTopProducts = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            try {
                const result = await GetDayTopProductsUseCase(mainDashboardService, currentLocal.id, cashierId, day);
                setTopProducts(result);
            } finally {
                setLoadingTopProducts(false);
            }
        }
    }, [currentLocal]);

    const getDayPayments = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            try {
                setLoadingDayPayments(true)
                const result = await GetDayPaymentsUseCase(mainDashboardService, currentLocal.id, cashierId, day);
                setDayPayments(result);
            } finally {
                setLoadingDayPayments(false)
            }
        }
    }, [currentLocal]);

    const getDayPaymentsPerHour = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            try {
                setLoadingDayPaymentsPerHour(true)
                const result = await GetDayPaymentsPerHourUseCase(mainDashboardService, currentLocal.id, cashierId, day);
                setDayPaymentsPerHour(result);
            } finally {
                setLoadingDayPaymentsPerHour(false)
            }

        }
    }, [currentLocal]);

    const getDayResume = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            await GetDayResumeUseCase(mainDashboardService, currentLocal.id, cashierId, day)
                .then((response) => {
                    setDayResume(response);
                    getDayTopProducts(cashierId, day)
                    getDayPayments(cashierId, day)
                    getDayPaymentsPerHour(cashierId, day)
                })
        }
    }, [currentLocal, getDayPayments, getDayPaymentsPerHour, getDayTopProducts]);

    const getMonthResumePerDay = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            try {
                setLoadingMonthResumePerDay(true)
                const result = await GetMonthResumePerDayUseCase(mainDashboardService, currentLocal.id, cashierId, day);
                setMonthResumePerDay(result);
            } finally {
                setLoadingMonthResumePerDay(false)
            }

        }
    }, [currentLocal]);

    const getMonthResume = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            await GetMonthResumeUseCase(mainDashboardService, currentLocal.id, cashierId, day)
                .then((response) => {
                    setMonthResume(response);
                    getMonthResumePerDay(cashierId, day)
                })
        }
    }, [currentLocal, getMonthResumePerDay]);

    const getYearResumePerMonth = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            try {
                setLoadingYearResumePerMonth(true)
                const result = await GetYearResumePerMonthUseCase(mainDashboardService, currentLocal.id, cashierId, day);
                setYearResumePerMonth(result);
            } finally {
                setLoadingYearResumePerMonth(false);
            }
        }
    }, [currentLocal]);

    const getCashiers = useCallback(async (cashierId?: string, day?: string) => {
        if (currentLocal) {
            const result = await GetSessionsUseCase(salesDashboardService, currentLocal,);
            setCashiers(result);
        }
    }, [currentLocal]);

    const getCurrentBalance = useCallback(async () => {
        if (currentLocal) {
            const result = await GetBuscarSaldoAtualUseCase(mainDashboardService, currentLocal.id,);
            setSaldoAtual(result);
        }
    }, [currentLocal]);

    const submitFilter = () => {
        if (period === 'day') {
            getDayResume(undefined, filter)
            getMonthResume(undefined, filter)
            getYearResumePerMonth(undefined, filter)
        } else {
            getDayResume(filter)
            getMonthResume(filter)
            getYearResumePerMonth(filter)
        }
    }

    const getBankDetail = useCallback(async () => {
        if (currentLocal) {
          const response = await GetBankDetail(accountService, currentLocal.id);
          setBankDetail(response);
        }
    }, [currentLocal]);

    useEffect(() => {
        getCashiers();
        getBankDetail();
    }, [getBankDetail, getCashiers]);

    const getResumeByDay = useCallback(() => {
        const curretDate = subMinutes(new Date(), 1).toISOString();
        getDayResume(undefined, curretDate);
        getMonthResume(undefined, curretDate);
        getYearResumePerMonth(undefined, curretDate);
    }, [getDayResume, getMonthResume, getYearResumePerMonth]);

    const getResumeByCashier = useCallback((cashierId: string) => {
        getDayResume(cashierId);
        getMonthResume(cashierId);
        getYearResumePerMonth(cashierId);
    }, [getDayResume, getMonthResume, getYearResumePerMonth]);

    useEffect(() => {
        if (cashiers) {
            if (currentLocal?.systemIdentifier === SystemIdentifierEnum.General && (cashiers?.items?.length ?? 0) > 0) { 
                getResumeByCashier(cashiers?.items[0]?.id);
            } else {
                getResumeByDay();
            }
            getCurrentBalance();
        }
    }, [cashiers, currentLocal, getCurrentBalance, getResumeByCashier, getResumeByDay]);

    return {
        dayResume,
        cashiers,
        topProducts,
        dayPayments,
        monthResume,
        monthResumePerDay,
        yearResumePerMonth,
        saldoAtual,
        submitFilter,
        period,
        setPeriod,
        filter,
        setFilter,
        dayPaymentsPerHour,
        loadingYearResumePerMonth,
        loadingTopProducts,
        loadingDayPayments,
        loadingDayPaymentsPerHour,
        loadingMonthResumePerDay,
        bankDetail
    };
}

export default UseMainDashboard
