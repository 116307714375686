import React, { ChangeEvent, FC, useCallback } from "react";
import styles from "../FidelityForm.module.scss";
import { Icon, TextField } from "@material-ui/core";
import { moneyMaskNumber, moneyToFloat } from "services/utils/Money";

export interface FidelityRules {
  type: number;
  target: number;
  value: number;
}

export interface IPointRulesProps {
  //propertys
  onChange: (values: FidelityRules) => void;
  values: FidelityRules;
  targetLabel: string;
  pointsLabel: string;
  targetPlaceholder?: string;
  pointsPlaceholder?: string;
  targetValueFixed?: number;
  moneyMask?: boolean;
  type?: React.HTMLInputTypeAttribute;
}
const PointTarget: FC<IPointRulesProps> = ({
  onChange,
  values,
  targetLabel,
  pointsLabel,
  targetPlaceholder,
  pointsPlaceholder,
  targetValueFixed,
  moneyMask,
  type,
}) => {
  const getValue = () => {
    if (moneyMask) {
      return moneyMaskNumber(targetValueFixed ?? values.target ?? "");
    }
    return targetValueFixed ?? values.target ?? "";
  };

  const changeHandleMoney = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (moneyMask) {
        onChange({
          ...values,
          [ev.target.name]: moneyToFloat(ev.target.value),
        });
        return;
      }
      onChange({ ...values, [ev.target.name]: Number(ev.target.value) });
    },
    [onChange, values, moneyMask]
  );

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...values, [ev.target.name]: Number(ev.target.value.replace(/\D+/g, '')) });
  }, [onChange, values]);

  return (
    <div className={styles.row} style={{ alignItems: "flex-end" }}>
      <div className={styles.item}>
        <label htmlFor="target">{targetLabel}</label>
        <TextField
          name="target"
          type={type ?? "text"}
          onChange={changeHandleMoney}
          value={getValue()}
          variant="outlined"
          fullWidth
          size="small"
          className={styles.textField}
          placeholder={targetPlaceholder}
          disabled={!!targetValueFixed}
        />
      </div>
      <div className={styles.separatorRowArrow}>
        <Icon>east</Icon>
      </div>
      <div className={styles.item}>
        <label htmlFor="points">{pointsLabel}</label>
        <TextField
          name="value"
          type={type ?? "text"}
          onChange={changeHandle}
          value={values.value ?? ""}
          variant="outlined"
          fullWidth
          size="small"
          className={styles.textField}
          placeholder={pointsPlaceholder}
        />
      </div>
    </div>
  );
};

export default PointTarget;
