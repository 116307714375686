import { IOriginType } from "modules/cashlessDashboard/presentation/components/rechargeOrigin/interfaces/IOriginType";
import { ITopCustomers } from "modules/cashlessDashboard/presentation/components/topCustomers/interfaces/ITopCustomers";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetTopCustomersUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams, type: IOriginType): Promise<ITopCustomers> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getTopCustomers(filterRequest, type);

  return response;
}

export default GetTopCustomersUseCase
