import { Button, Icon, IconButton } from '@material-ui/core';
import styles from './DeleteModal.module.scss';

interface DeleteMoralProps {
    loading: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export const DeleteModal = ({ loading, onClose, onDelete }: DeleteMoralProps) => {
    return (
        <div className={styles.container}>
            <header>
                <h1>Excluir <b>&nbsp;categoria</b></h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton>
            </header>
            <div className={styles.alertBox}>
                <img src="/assets/img/alert.png" alt="" />
                <span>Deseja realmente excluir a <strong>categoria selecionada?</strong></span>
            </div>

            <div className={styles.buttonContainer}>
                <div>
                    <Button className={styles.buttons} color='secondary' onClick={onClose} variant='outlined'>Fechar</Button>
                </div>
                <div>
                    <Button disabled={loading} onClick={onDelete} className={styles.buttons} type='button' variant='contained' color='secondary'>{
                        loading ? 'Deletando...' :
                        'Confirmar'
                    }</Button>
                </div>
            </div>
        </div>
    )
}