import React, { FC, useEffect } from 'react'
import { ButtonContainer, Container, InputContainer, Row } from '../../../ui/form/FormContainers'
import { useForm } from 'react-hook-form'
import { FormControlLabel, Icon, MenuItem, Switch, TextField } from '@material-ui/core';
import { SupplyGroupValidateSchema } from './SupplyGroupValidateSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import SelectSupplyParentGroup, { ISupplyGroupList, ISupplyGroupListRequest } from './selectSupplyParentGroup/SelectSupplyParentGroup';
import Sidesheet from 'components/sidesheet/Sidesheet';
import { FormHelperText } from '@mui/material';
import styles from './SupplyGroupForm.module.scss'
import Back from '../../../ui/back/Back';
import { useHistory } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import { IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos';

export interface ISupplyGroupValues {
    code: string;
    name: string;
    canUpdateInventory: boolean;
    parentId?: string;
    parent?: {
        id: string;
        name: string;
        code: string;
    };
    isSubGroup?: boolean;
}
export interface ISupplyGroupFormProps {
    //propertys
    isLoading?: boolean;
    disabled?: boolean;
    defaultValues?: ISupplyGroupValues;
    onSubmit: (values: ISupplyGroupValues) => Promise<any>;
    getSupplyGroupList?: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
}
const SupplyGroupForm: FC<ISupplyGroupFormProps> = ({
    isLoading,
    disabled,
    defaultValues,
    onSubmit,
    getSupplyGroupList,
    GetSearchNameSupplyGroup,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch
    } = useForm<ISupplyGroupValues>({
        defaultValues: defaultValues ?? {
            code: '',
            name: '',
            canUpdateInventory: false,
            parentId: undefined,
            parent: undefined,
            isSubGroup: false
        },
        resolver: zodResolver(SupplyGroupValidateSchema)
    });
    const { push } = useHistory()
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [isSubGroup, setIsSubGroup ] = React.useState(false)

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                canUpdateInventory: defaultValues.canUpdateInventory,
            })
            if (defaultValues.parentId) {
                setValue('isSubGroup', true)
                setIsSubGroup(true)
            }
        }
    }, [defaultValues, reset, setValue]);

    useEffect(() => {
        console.log(errors);
    }, [errors]);


    return (
        <form onSubmit={handleSubmit(onSubmit)} id={styles.SupplyGroupForm}>
            <Container >
                <Back route='/private/meeperp/supply/supplyGroups' margin />
                <Container subContainer title={"1. Dados gerais"} >
                    <Row>
                        {
                            defaultValues?.code &&
                            <InputContainer label="Código">
                                <TextField
                                    value={defaultValues?.code}
                                    disabled
                                />
                            </InputContainer>
                        }
                        <InputContainer flex={3} label="Nome" requerid>
                            <TextField
                                {...register('name')}
                                disabled={disabled}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer flex={0} label="Atualiza estoque" requerid>
                            <TextField
                                disabled={isLoading || disabled}
                                select
                                error={!!errors.canUpdateInventory}
                                helperText={errors.canUpdateInventory?.message}
                                value={watch('canUpdateInventory') ? 'yes' : 'no'}
                                defaultValue={watch('canUpdateInventory') ? 'yes' : 'no'}
                                onChange={(e) => setValue('canUpdateInventory', e.target.value === 'yes' ? true : false)}
                            >
                                <MenuItem value='yes'>Sim</MenuItem>
                                <MenuItem value='no'>Não</MenuItem>
                            </TextField>
                        </InputContainer>
                    </Row>
                </Container>
                <Container subContainer title='2. Sub grupo'>
                    <Row>
                        <InputContainer>
                            <FormControlLabel
                                disabled={isLoading || disabled}
                                {...register("isSubGroup")}
                                checked={isSubGroup}
                                onChange={(_, checked) => {
                                    setValue('isSubGroup', checked);
                                    setValue('parentId', undefined);
                                    setValue('parent', undefined);
                                    setIsSubGroup(checked)
                                }}
                                control={<Switch />}
                                label="É um sub grupo?"
                            />
                        </InputContainer>
                    </Row>
                    {/* {watch("isSubGroup") ? <div>É um subgrupo</div> : <div>Não é um subgrupo</div>} */}
                    {
                        (watch("isSubGroup") || isSubGroup) &&
                        <Row>
                            <InputContainer>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        setOpenDrawer(true)
                                    }}
                                    fullWidth={false}>
                                    {watch('parent')?.name ?? "Selecionar grupo pai"}
                                </Button>
                                {/* <TextField variant='outlined'
                                    onClick={() => setOpenDrawer(true)}
                                    value={watch('parent')?.name ?? "Selecionar grupo pai"}
                                >
                                </TextField> */}
                                <FormHelperText error={!!errors.parentId} >{errors.parentId?.message}</FormHelperText>
                            </InputContainer>
                        </Row>
                    }
                    <Sidesheet
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        title={<h2>Selecionar <b>grupo pai</b></h2>}
                    >
                        <div className={styles.sideSheet}>
                            <SelectSupplyParentGroup
                                getSupplyGroupList={getSupplyGroupList}
                                GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                                value={watch('parentId')}
                                onSelect={(item) => {
                                    setValue('parentId', item.id)
                                    setValue('parent', item)
                                    setOpenDrawer(false)
                                }}
                              
                            />
                        </div>
                    </Sidesheet>
                </Container>
                <ButtonContainer>
                    <Button color='secondary' disabled={isLoading || disabled} onClick={() => push('/private/meeperp/supply/supplyGroups/')} fullWidth={false} variant='outlined'>Cancelar</Button>
                    <Button disabled={isLoading || disabled} type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                </ButtonContainer>
            </Container>
        </form >
    )
}
export default SupplyGroupForm