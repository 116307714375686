import { useCallback, useEffect, useState } from "react"
import { IAutomation, IAutomationItem } from "./interfaces/IAutomation"
import AutomationService from "services/api/automation/AutomationService";
import { GetAutomationsUseCase } from "../application/useCases/GetAutomationsUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { EnableAutomationUseCase } from "../application/useCases/EnableAutomationUseCase";
import { DisableAutomationUseCase } from "../application/useCases/DisableAutomationUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const service = AutomationService();

export const UseAutomationPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [data, setData] = useState<IAutomation>();
  const [selectedAutomation, setSelectedAutomation] = useState<IAutomationItem>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getData = useCallback(async (params?: { pagination?: { page?: number, pageSize?: number } }) => {
    try {
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetAutomationsUseCase(service, currentLocal.id, { ...params?.pagination });
        setData(response);
        return response
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  const getDataHandle = useCallback((page: number) => {
    getData({ pagination: { page, pageSize: 10 } })
  }, [getData])

  useEffect(() => {
    getData()
  }, [getData])

  const handleCloseForm = () => {
    setIsDrawerOpened(false);
    setSelectedAutomation(undefined);
  }

  const handleToggleStatus = async (enabled: boolean, automationId: string) => {
    try {
      setData(prev => prev ? ({ ...prev, data: prev.data.map(x => x.id === automationId ? { ...x, enabled } : x) }) : undefined)
      if (enabled)
      await EnableAutomationUseCase(service, currentLocal!.id, automationId);
      else
      await DisableAutomationUseCase(service, currentLocal!.id, automationId);
    } catch {
      setData(prev => prev ? ({ ...prev, data: prev.data.map(x => x.id === automationId ? { ...x, enabled: !enabled } : x) }) : undefined)
      toast('Já existe uma automação com esse tipo e gatilho ativa', 'error');
    }
  }

  const handleDelete = (automation: IAutomationItem) => {
    setSelectedAutomation(automation);
    setIsDrawerOpened(true);
  }

  return {
    isLoading,
    data,
    isDrawerOpened,
    selectedAutomation,
    getData,
    getDataHandle,
    handleCloseForm,
    setIsDrawerOpened,
    handleToggleStatus,
    handleDelete
  }
}