import { Button, Icon, MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { IParamsClientDiscountCouponsList } from '../../hooks/UseDiscountCouponsList';
import styles from "./DiscountCouponFilter.module.scss";
import { EnumValueType, IDiscountCouponFilter } from './interfaces/IDiscountCouponFilter';

export interface IDiscountCouponFilterProps {
  onSubmit: (filter: IDiscountCouponFilter) => void;
  setParams: React.Dispatch<React.SetStateAction<IParamsClientDiscountCouponsList>>
  params: IParamsClientDiscountCouponsList | undefined;

}

export const DiscountCouponFilter: React.FC<IDiscountCouponFilterProps> = ({
  onSubmit,
  params,
  setParams,
}) => {

  const [values, setValues] = useState<IDiscountCouponFilter>();

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [isPorcent, setIsPorcent] = useState(false);

  const changeHandleTag = useCallback((code: string) => {
    setValues((prev) => ({ ...prev, code: code }));
  }, []);
  const changeHandleValueMin = useCallback((valueMin: string) => {
    setValues((prev) => ({ ...prev, valueMin: Number(valueMin) === 0 ? undefined : Number(valueMin) }));
  }, []);
  const changeHandleValueMax = useCallback((valueMax: string) => {
    setValues((prev) => ({ ...prev, valueMax: Number(valueMax) === 0 ? undefined : Number(valueMax) }));
  }, []);


  const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  const onClickShowAdevancedFilterHandle = useCallback(() => {
    setShowAdvancedFilter(prev => !prev);
  }, []);


  const changeHandleValueType = useCallback((valueType: string) => {
    const type = Number(valueType);
    if (type === 2) {
      setIsPorcent(true)
    } else {
      setIsPorcent(false)
    }
    setValues((prev) => ({ ...prev, valueType: type === 0 ? undefined : type }))
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const createdStart = new Date(values?.createdStartdAt ?? '');
      const createdEnd = new Date(values?.createdEndAt ?? '');
      const startAt = new Date(values?.startAt ?? '');
      const endAt = new Date(values?.finishAt ?? '');
      values && onSubmit({
        ...values,
        createdStartdAt: values.createdStartdAt ? new Date(createdStart.getFullYear(), createdStart.getMonth(), createdStart.getDate() + 1, 0, 0, 0, 0).toISOString() : undefined,
        createdEndAt: values.createdEndAt ? new Date(createdEnd.getFullYear(), createdEnd.getMonth(), createdEnd.getDate() + 1, 23, 59, 59).toISOString() : undefined,
        startAt: values.startAt ? new Date(startAt.getFullYear(), startAt.getMonth(), startAt.getDate() + 1, 0, 0, 0, 0).toISOString() : undefined,
        finishAt: values.finishAt ? new Date(endAt.getFullYear(), endAt.getMonth(), endAt.getDate() + 1, 23, 59, 59).toISOString() : undefined,
      });

    },
    [onSubmit, values]
  );

  return (
    <div id={styles.HouseFlowFilter}>
      <form onSubmit={submitHandle}>

        <div className={styles.containerFilter}>
          <div className={styles.content}>
            <div className={styles.itemCoupon}>
              <label>Cupom</label>
              <TextField fullWidth inputProps={{ maxLength: 50 }} size='small' placeholder='Filtrar pelo nome do cupom' variant='outlined' name={'cardNumber'} onChange={(ev) => changeHandleTag(ev.target.value.trim())} />
            </div>
            <div className={styles.item}>
              <label>Criação - início</label>
              <TextField size='small' type="date" fullWidth variant='outlined' name='createdStartdAt' value={values?.createdStartdAt} onChange={changeHandleDate} />
            </div>
            <div className={styles.item}>
              <label>Criação - fim</label>
              <TextField type="date" size='small' fullWidth variant='outlined' name='createdEndAt' value={values?.createdEndAt} onChange={changeHandleDate} />
            </div>
          </div>
          <div className={styles.containerButton}>
            {!showAdvancedFilter && <Button variant={'contained'} className={styles.button} fullWidth style={{ height: 40, borderRadius: 8, width: 110 }} type='submit' >Buscar</Button>}
          </div>
        </div>
        <div className={styles.advancedFilterButton}>
          <Button className={styles.buttonFilterAdvanced} onClick={onClickShowAdevancedFilterHandle}>Filtros avançados<Icon>sort</Icon></Button>
        </div>

        {
          showAdvancedFilter &&
          <div className={styles.advancedFilter}>
            <div className={styles.content}>
              <div className={styles.item}>
                <label>Valor</label>
                <TextField select inputProps={{ maxLength: 20 }} size='small' variant='outlined' name="valueType" defaultValue={0} placeholder="Selecione o tipo" onChange={(ev) => changeHandleValueType(ev.target.value)} >
                  <MenuItem value={0}>Selecione o tipo</MenuItem>
                  <MenuItem value={EnumValueType.ABSOLUTE}>R$</MenuItem>
                  <MenuItem value={EnumValueType.PERCENT}>%</MenuItem>
                </TextField>
              </div>
              <div className={styles.item}>
                {
                  !isPorcent ?
                    <TextField inputProps={{ maxLength: 3 }} size='small' type="number" placeholder={'% mínima'} variant='outlined' name={'valueMin'} onChange={(ev) => changeHandleValueMin(ev.target.value)} />
                    :
                    <TextField inputProps={{ maxLength: 20 }} size='small' type="number" placeholder={"Valor mínimo"} variant='outlined' name={'valueMin'} onChange={(ev) => changeHandleValueMin(ev.target.value)} />

                }
              </div>
              <p>e</p>
              <div className={styles.item}>
                {
                  !isPorcent ?
                    <TextField inputProps={{ maxLength: 3 }} size='small' type="number" placeholder={'% máximo'} variant='outlined' name={'valueMax'} onChange={(ev) => changeHandleValueMax(ev.target.value)} />
                    :
                    <TextField inputProps={{ maxLength: 20 }} size='small' type="number" placeholder={"Valor máximo"} variant='outlined' name={'valueMax'} onChange={(ev) => changeHandleValueMax(ev.target.value)} />
                }
              </div>
              <div className={styles.item}>
                <label>Expiração - início</label>
                <TextField size='small' type="date" variant='outlined' name='startAt' value={values?.startAt} onChange={changeHandleDate} />
              </div>
              <div className={styles.item}>
                <label>Expiração - fim</label>
                <TextField type="date" size='small' variant='outlined' name='finishAt' value={values?.finishAt} onChange={changeHandleDate} />
              </div>
            </div>
            <div className={styles.footer}>
              <Button variant={'contained'} className={styles.button} fullWidth style={{ height: 40, borderRadius: 8, width: 110 }} type='submit' >Buscar</Button>
            </div>

          </div>
        }
      </form>
    </div>
  )
}
