import { Button } from '@material-ui/core';
import { FC } from 'react';
import styles from './DiscountListForm.module.scss';
import UseDiscountListForm from './UseDiscountListForm';
import TabNavigation from 'components/ui/tabNavigation/TabNavigation';
import { IDiscountListForm, DiscountListFormStep } from './IDiscountListForm';
import { ConfigForm } from './forms/configForm/ConfigForm';
import { DiscountListBaseForm } from './forms/baseForm/BaseForm';

interface IDiscountListFormProps {
  discountList: IDiscountListForm;
  closeFormModal: () => void;
  handleFormDiscountList: (discountList: IDiscountListForm) => void;
}

export const DiscountListForm: FC<IDiscountListFormProps> = ({
  discountList,
  closeFormModal,
  handleFormDiscountList,
}) => {
  const {
    discountListForm,
    errors,
    formStep,
    productList,
    handleFormStep,
    handleChangeDiscountListFormValue,
    handleAddDiscount,
    handleRemoveDiscount,
    submitForm,
  } = UseDiscountListForm(discountList, closeFormModal, handleFormDiscountList);

  return (
    <div id={styles.DiscountListForm}>
      <TabNavigation
        tabs={[
          {
            title: "Informações",
            component: (
              <DiscountListBaseForm 
                errors={errors}
                discountList={discountListForm}
                onChangeDiscountListFormValue={handleChangeDiscountListFormValue}
              />
            ),
          },
          {
            title: "Descontos",
            component: (
              <ConfigForm 
                errors={errors}
                discountList={discountListForm}
                productList={productList}
                onChangeDiscountListFormValue={handleChangeDiscountListFormValue}
                onAddDiscount={handleAddDiscount}
                onRemoveDiscount={handleRemoveDiscount}
              />
            ),
          },
        ]}
        selectedTab={formStep}
        handleTabChange={handleFormStep}
        bodyClassName={styles.tabContent}
      />
      <div className={styles.actions}>
        <Button
          fullWidth={false}
          onClick={closeFormModal} 
          className={styles.cancel}        
          variant="outlined">
          Cancelar
        </Button>
        <Button
          fullWidth={false}
          onClick={formStep === DiscountListFormStep.CONFIG ? submitForm : () => handleFormStep(DiscountListFormStep.CONFIG)}          
          variant="contained"
          className={styles.confirm}
          >
          {formStep === DiscountListFormStep.CONFIG ? 'Concluir' : 'Continuar'}
        </Button>
      </div>
    </div>
  );
};
