import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import styles from "./DeleteProductsModal.module.scss";

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteProductsModal = ({
  open,
  title,
  onClose,
  onConfirm,
}: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.warning}>
          <img src="/assets/icon/delete-confirmation.svg" alt="" />
          <span>Deseja excluir os produtos selecionados?</span>
        </div>
        <span>Lembre-se, essa ação não poderá ser desfeita!</span>
        <div className={styles.buttonsContainer}>
          <Button variant="outlined" fullWidth={false} onClick={onClose}>
            Cancelar
          </Button>
          <Button fullWidth={false} onClick={onConfirm}>
            Excluir
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
