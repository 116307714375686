import { IAddPermutationAccount } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";
import { IPermutationFormErros } from "modules/permutation/presentation/interfaces/IPermutationFormErros";
import CNPJ from "services/utils/CNPJ";
import CPF from "services/utils/CPF";


export enum DocumentType {
  "CPF" = 1,
  "CNPJ" = 2
}

export const validatePermutationForm = (formData: IAddPermutationAccount, setErrors: React.Dispatch<React.SetStateAction<IPermutationFormErros>>, optionDocument: DocumentType) => {
  let _errors: IPermutationFormErros = {};
  let hasError = false;
  if (!formData.name.trim()) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.document) {
    _errors = { ..._errors, document: "Campo obrigatório" };
    hasError = true;
  }

  if(optionDocument === DocumentType.CPF) {

    if (formData.document && !CPF.isValid(formData.document)) {
      _errors = { ..._errors, document: "CPF inválido" };
      hasError = true;
    }
  } else {
    if (formData.document && !CNPJ.isValid(formData.document)) {
      _errors = { ..._errors, document: "CNPJ inválido" };
      hasError = true;
    }
  }


  setErrors(_errors);
  return !hasError;
};
