import { FC } from 'react'
import { ICheckinTableData } from '../checkinTable/ICheckinTable';
import GenderTable from '../genderTable/GenderTable';
import styles from './PermanencyTable.module.scss'

export interface IPermanencyTableProps {
    data?: ICheckinTableData;
    isLoading?: boolean;
    error?: string;
}
const PermanencyTable: FC<IPermanencyTableProps> = ({
    data,
    isLoading,
    error,
}) => {

    return (
        <div id={styles.PermanencyTable} >
            <GenderTable
                isLoading={isLoading}
                data={
                    {
                        icon: "schedule",
                        value: data?.averageCustomerTime?.average,
                        valueLabel: `Tempo médio de permanência`,
                        male: data?.averageCustomerTime?.male,
                        female: data?.averageCustomerTime?.female,
                        unknown: data?.averageCustomerTime?.unknown,
                    }
                } />
        </div>
    )
}
export default PermanencyTable