import { ThemeContext } from '@mui/styled-engine';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetOperatorsUseCase from 'modules/schedule/application/useCases/collaboratorSchedule/GetOperatorsUseCase';
import { GetServiceScheduleByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetServiceScheduleByLocalIdUseCase';
import { GetServicesByLocalIdUseCase } from 'modules/schedule/application/useCases/reserveSchedule/GetServicesByLocalIdUseCase';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { createContext, FC, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import ColaboratorScheduleApi from 'services/api/schedule/colaborator/ColaboratorScheduleApi';
import AddCollaboratorUseCase from '../../../application/useCases/collaboratorSchedule/AddCollaboratorUseCase'
import { OperatorSelect } from '../../components/colaboratorScheduleForm/ColaboratorScheduleForm';
import { ICollaboratorScheduleFormValue } from '../../components/colaboratorScheduleForm/ColaboratorScheduleFormValue';

export const UseAddColaboratorSchedulePage = () => {


  const { currentLocal } = useLocal();
  const { showLoading, hideLoading } = useUi();

  const [operators, setOperators] = useState<OperatorSelect[]>();

  const [services, setServices] = useState<ISelect[]>()

  const { push } = useHistory();

  const onSubmitFormHandle = useCallback((values: ICollaboratorScheduleFormValue) => {
    if (currentLocal) {
      showLoading()
      const colalboratorService = ColaboratorScheduleApi();
      AddCollaboratorUseCase(colalboratorService, currentLocal.id, { ...values, minimumLeadTimeInDays: 0, maximumLeadTimeInDays: 365 })
        .then(() => {
          push('/private/agendamento/list/colaborador');
        })
        .finally(() => {
          hideLoading();
        })
    }
  }, [currentLocal, hideLoading, push, showLoading]);


  const getServices = useCallback(() => {
    if (currentLocal) {
      showLoading()
      const service = ColaboratorScheduleApi;
      GetServiceScheduleByLocalIdUseCase(currentLocal?.id, service)
        .then(reponse => setServices(reponse))
        .finally(() => { hideLoading() })

    }
  }, [currentLocal, hideLoading, showLoading])

  const getOperator = useCallback(() => {
    if (currentLocal) {
      showLoading()
      const service = ColaboratorScheduleApi();
      GetOperatorsUseCase(service, currentLocal.id)
        .then(reponse => setOperators(reponse))
        .finally(() => { hideLoading() })

    }
  }, [currentLocal, hideLoading, showLoading])


  useEffect(() => {
    getServices()
    getOperator()

    return () => {

    }
  }, [getOperator, getServices])


  return ({
    onSubmitFormHandle,
    operators,
    services
  });
};
