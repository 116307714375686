
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext"
import { useUi } from "contexts/userInterface/UserInterfaceContext"
import { useLocal } from "modules/local/presentation/context/LocalContext"
import { GetReservationListUseCase } from "modules/schedule/application/useCases/reserveSchedule/GetReservationListUseCase"
import { IReservationList, IReserveFilter, IState, IStateEnum } from "modules/schedule/domain/models/IReserveList"
import { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom"
import { ReserveScheduleApi } from "services/api/schedule/reserve/ReserveScheduleApi"

const RouterPrefix = "/private/agendamento/list/"

export const UseReserveListPage = () => {
    const date = new Date(new Date().setDate(new Date().getDate() - 30));

    const [values, setValues] = useState<IReservationList>();
    const [filterOnSubmit, setFilterOnSubmit] = useState<IReserveFilter>({
        keyword: '',
        start: new Date(date.setHours(-3, 0, 0, 0)).toLocaleDateString('en-US'),
        end: new Date(new Date().setHours(20, 59, 59, 999)).toLocaleDateString('en-US'),
        status: [IStateEnum.Concluída, IStateEnum.Cancelada, IStateEnum.Pendente]
    } as IReserveFilter);   

    const [initialDate, setInitialDate] = useState<string>("");
    const [finalDate, setFinalDate] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [state, setState] = useState<IStateEnum[]>([])
    const [selectedState, setSelectedState] = useState<IStateEnum>()


    const { showLoading, hideLoading, toast } = useUi()
    const [pageSize, setPageSize] = useState<number>(20)

    const query = new URLSearchParams(useLocation().search);

    const [page, setPage] = useState<number>(0);


    const { push } = useHistory();
    const { url } = useRouteMatch();
    const { currentLocal } = useLocal();
    const { updateRouters } = useBreadcumbs();
    const { tab } = useParams<{ tab: string }>();

    const onFilter = () => {
        const newValue: IReserveFilter = {
            start: initialDate,
            end: finalDate,
            keyword: keyword,
            status: state
        }
        setFilterOnSubmit(newValue)
        if (currentLocal) {
            getReservation(page, currentLocal.id, newValue.start, newValue.end, newValue.keyword, newValue.status)
        }
    };

    const getReservation = useCallback(
        (_page: number, localId: string, start: string, end: string, keyword: string, status: IStateEnum[]) => {
            showLoading()
            GetReservationListUseCase(localId, _page, pageSize, start, end, keyword, status, ReserveScheduleApi).then((response) => {
                setValues(response);
            }).finally(() => {
                hideLoading();
            })
        },
        [filterOnSubmit.end, filterOnSubmit.start, hideLoading, page, pageSize, showLoading],
    )

    useEffect(() => {
        if (currentLocal) {
            getReservation(page, currentLocal.id, filterOnSubmit.start, filterOnSubmit.end, filterOnSubmit.keyword, filterOnSubmit.status);
        }
    }, [currentLocal, page]);

    useEffect(() => {
        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Reservas',
                url: RouterPrefix + tab
            }
        ])
    }, [updateRouters]);

    const refreshReserveList = () => {
        if (currentLocal) {
            getReservation(page, currentLocal.id, filterOnSubmit.start, filterOnSubmit.end, filterOnSubmit.keyword, filterOnSubmit.status);
        }
    }

    // const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement>) => {       
    //     setFilterReserve((prevState) => ({
    //       ...prevState,
    //       [event.target.name]: event.target.value
    //     }));
    //   };

    const changeStateHandle = useCallback((values: IStateEnum[]) => {
        setState(values);
    }, [])

    const handleInitialDateFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInitialDate(event.target.value)
    }

    const handleFinalDateFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFinalDate(event.target.value)
    }

    const handleKeywordFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setKeyword(event.target.value)
    };

    useEffect(() => {
        const newPage = query.get("page");
        if (newPage) {
            setPage(Number(newPage));
        }
    }, [query]);

    const changePage = useCallback((_page: number) => {
        push(url + "?page=" + _page)
    }, [push, url]);

    return (
        {
            values,
            setValues,
            handleInitialDateFilter,
            handleFinalDateFilter,
            handleKeywordFilter,
            initialDate,
            finalDate,
            onFilter,
            keyword,
            page,
            changePage,
            pageSize,
            setPageSize,
            getReservation,
            refreshReserveList,
            state,
            changeStateHandle,
            selectedState
        }
    )
}
