import { ITransactionReportService } from "modules/transactionReport/domain/interface/ITransactionReportService";
import { api } from "../Api";
import { IGetTransactionsReportResponse } from "modules/transactionReport/domain/dto/IGetTransactionsReportResponse";
import { IGetTransactionsResponse } from "modules/transactionReport/domain/dto/IGetTransactionsResponse";
import { IGetTransactionsParams } from "modules/transactionReport/domain/dto/IGetTransactionsParams";
import { IGetTransactionDetailsResponse } from "modules/transactionReport/domain/dto/IGetTransactionDetailsResponse";
import { IPostTransactionsExportParams } from "modules/transactionReport/domain/dto/IPostTransactionsExportParams";

const TransactionReportApi = (): ITransactionReportService => {
  const getTransactions = async (params: IGetTransactionsParams): Promise<IGetTransactionsResponse> => {
    const respomse = await api.get<IGetTransactionsResponse>(`/transaction/transactions/`, { params: params });
    return respomse.data;
  }

  const getTransactionsReport = async (localId: string): Promise<IGetTransactionsReportResponse> => {
      const respomse = await api.get<IGetTransactionsReportResponse>(`/transaction/report/`, { params: {
        localId: localId,
      }});
      return respomse.data;
  }

  const getTransactionDetails = async (localId: string, transactionId: string): Promise<IGetTransactionDetailsResponse> => {
      const respomse = await api.get<IGetTransactionDetailsResponse>(`/transaction/detail/`, { params: {
        localId: localId,
        transactionId: transactionId,
      }});
      return respomse.data;
  }

  const postTransactionsExport = async (params: IPostTransactionsExportParams): Promise<any> => {
      const respomse = await api.get<any>(`/transaction/export`, { params: params });
      return respomse.status;
  }

  return { getTransactions, getTransactionsReport, getTransactionDetails, postTransactionsExport };
}

export default TransactionReportApi;
