import React, { ReactElement } from 'react';
import Item, { IActionButtons, IMenuButton } from './Item/Item';
import styles from './List.module.scss';
import { IListItem } from './interfaces/IList';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { IListValues, ColumnConfigs, ColumnConfigsItem } from './List';

interface IListDnDProps<T extends IListItem> {
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    listValues: (T & IListValues)[];
    renderAccordion?: ((item: T) => ReactElement) ;
    enableDnd: boolean;
    columnsConfigs?: ColumnConfigs<T>
    actionButtons: IActionButtons<T>[] | undefined;
    menuButtons: IMenuButton<T>[] | undefined;
    showAccordion?: (item: T) => boolean
    sub?: boolean
    subLevel?: number
}
export const ListDnD = <T extends IListItem>({
    onDragEnd, listValues, renderAccordion, enableDnd, columnsConfigs, actionButtons, menuButtons, sub, subLevel

}: IListDnDProps<T>) => {
    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
            {(providedDroppable: DroppableProvided, snapshot) => (
                <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                    className={styles.list}
                >
                    {listValues?.map((item, index) => (

                        <Draggable draggableId={item.id + index} index={index}
                        >
                            {(provided: DraggableProvided, snapshot) => (
                                <div
                                    key={item.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <Item
                                        renderAccordion={renderAccordion}
                                        style={snapshot.isDragging ? { backgroundColor: '#eee', boxShadow: "3px 3px 6px -3px #0005", transition: "all 0.5s" } : {}}
                                        item={item}
                                        key={item.id}
                                        enableDnd={enableDnd}
                                        columnsConfigs={columnsConfigs}
                                        actionButtons={actionButtons}
                                        menuButtons={menuButtons} 
                                        sub={sub}
                                        />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>;
};
