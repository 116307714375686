import React, { useCallback, useMemo, useState } from "react";
import styles from "./PrintQueue.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IPrintQueueColumnsConfig, PrintQueueColunmsPropertyType } from "../interfaces/IColumnsConfig";
import { IPrintQueueItem, PrintingStatus } from "modules/printQueue/presentation/interfaces/IPrintQueueList";
import Utils from "services/utils/Utils";
import { KeyboardArrowRight } from "@mui/icons-material";
import Sidesheet from "components/sidesheet/Sidesheet";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@material-ui/icons";

interface Props {
  columns: IPrintQueueColumnsConfig[];
  userItemData: IPrintQueueItem;
}

const PrintQueueItem: React.FC<Props> = ({
  userItemData,
  columns,
}) => {

  const [openDrownDetails, setOpenDrownDetails] = useState(false)

  const openDetails = useCallback(
    () => {
      setOpenDrownDetails(true);
    }, []);

  const onClose = useCallback(() => {
    setOpenDrownDetails(false)
  }, [])

  const formatValues = useCallback(
    (property: PrintQueueColunmsPropertyType): string | JSX.Element => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {
          case "printerName":
            return userItemData?.printerName
              ? userItemData?.printerName
              : "-";
          case "createdAt":
            return userItemData?.createdAt
              ? Utils.toDateAndTime(userItemData?.createdAt + "z")
              : "-";
          case "order":
            return userItemData?.order.amount
              ? moneyMaskNumber(userItemData?.order.amount)
              : "-";
          case "status":
            return userItemData?.status === PrintingStatus.Printed ? (
              <div className={styles.printed}>Impressão finalizada</div>
            ) : userItemData?.status === PrintingStatus.InQueue ? (
              <div className={styles.inQueue}>Na fila</div>
            ) : userItemData?.status === PrintingStatus.Error ? (
              <div className={styles.error}>
                Erro <Tooltip title={userItemData?.message}>
                        <InfoOutlined style={{ color: "#F44336", width: '15px', display: 'flex' }} />
                      </Tooltip>
              </div>
            ) : (
              "-"
            );
          default:
            return String(userItemData?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [userItemData]
  );
  
  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        const value = formatValues(colunm.property);
        const plainText = typeof value === "string" ? value : "";

        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              
              <span title={plainText}>
                {value}
              </span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  const handleContent = useCallback(() => {

    return (
      <div className={styles.containerDetails}>
        <div className={styles.header}>
          <div className={styles.left}>
            <p>Cliente: <span>{userItemData.printOrder.customer ? userItemData.printOrder.customer : "Não informado"}</span></p>
            <p>Impressora: <span>{userItemData.printOrder.pointOfSale ? userItemData.printOrder.pointOfSale  : "Não informado"}</span></p>
          </div>         
          <div className={styles.rigth}>
            <p>Pedido: <span>{userItemData.printOrder.number ? userItemData.printOrder.number : "Não informado"}</span></p>
            <p>Mesa: <span>{userItemData.printOrder.table ? userItemData.printOrder.table : "Não informado"}</span></p>
          </div>
        </div>
        <div  className={styles.separator}></div>
        <div>
          {userItemData.printOrder.details.map((item, index) => (
            <div key={index} className={styles.item}>
              <p>{item.quantity}x {item.productName}</p>
            </div>
          ))}
        </div>
        
        {userItemData.printOrder.note && (
          <div className={styles.note}>
            <p>Observação: {userItemData.printOrder.note}</p>
          </div>
        )}
      </div>
    );
  }, [userItemData]);

  return (
    <>
      <div id={styles.PrintQueueHeader}>
        <div className={styles.container}>
          {List}
          <div className={styles.icon}>
            <KeyboardArrowRight onClick={openDetails}/>
          </div>
        </div>
      </div>     
      <Sidesheet        
        open={openDrownDetails}
        onClose={onClose}
        title={
          <h2>
            Detalhes
          </h2>
        }
        content={handleContent()}
        totalSteps={2}
        continueButton={"Fechar"}        
      /> 
    </>
  );
};

export default PrintQueueItem;
