import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useQuery } from "hooks/UseQuery";
import { ResetPasswordUseCase } from "modules/saas/application/useCases/ResetPasswordUseCase";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SaasApi } from "services/api/saas/SaasApi";

interface ErrorType {
    password: string;
    confirmPassword: string;
}

const service = SaasApi();

export const UseChangePasswordPage = () => {
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({} as ErrorType);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const query = useQuery();
    const { toast } = useUi();
    const history = useHistory();
    
    useEffect(() => {
        const token = query.get('token');
        const email = query.get('email');
        const cpf = query.get('cpf');

        if (!token || (!email && !cpf)) {
            toast('URL inválida.', 'error');
            history.push('/public/saas/register' + email ? `?email=${email}` : '');
        }

    }, [history, query, toast]);

    const validate = async () => {
        let valid = true;
        setErrors({} as ErrorType);

        if (!password) {
            setErrors(prev => ({ ...prev, password: 'A nova senha é obrigatória' }))
            valid = false;
        }

        if (!confirmPassword) {
            setErrors(prev => ({ ...prev, password: 'A confirmação da senha é obrigatória' }))
            valid = false;
        }

        return valid;
    }

    const handleSubmit = async () => {
        const isValid = await validate();
        if (!isValid) return;

        (async () => {
            try {
                setFormLoading(true);

                const token = query.get('token');
                const email = query.get('email');
                const cpf = query.get('cpf');

                await ResetPasswordUseCase(service, {
                    token: encodeURIComponent(token!).replace(/'/g,"%27").replace(/"/g,"%22").replace(/%20/g, '%2B'),
                    documentOrEmail: email || cpf!,
                    newPassword: password,
                    confirmNewPassword: confirmPassword
                });

                history.push(`/public/saas/login?email=${email}`);
            } catch {
                toast('Ocorreu um erro ao tentar realizar o login. Tente novamente.', 'error');
            } finally {
                setFormLoading(false);
            }
        })();
    }

    return {
        formLoading,
        password,
        confirmPassword,
        errors,
        setPassword,
        setConfirmPassword,
        handleSubmit,
    }
}
