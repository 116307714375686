import { Icon } from '@material-ui/core';
import styles from './ImportCsvModal.module.scss';
import { ChangeEvent, useRef, useState } from 'react';

interface ImportCsvModalProps {
    onSave: (csv: string) => void;
    onClose: () => void;
    loading: boolean;
    url: string;
    errors: string[];
}

export const ImportCsvModal = ({ onClose, onSave, loading, url, errors }: ImportCsvModalProps) => {
    const [csv, setCsv] = useState('');
    const [file, setFile] = useState<File>();
    const fileRef = useRef<HTMLInputElement>(null);

    function csvToString(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                resolve(reader.result as string);
            }
            reader.onerror = error => {
                reject(error);
            }
        });
    }

    const handleSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;
        if (!files) return;

        setFile(files[0]);
        const csv = await csvToString(files[0]);
        setCsv(csv);
    }

    const handleSubmit = () => {
        onSave(csv);
    }

    return (
        <div className={styles.container}>
            <h1><span>3.1</span> <b>Importar</b> tabela fiscal</h1>

            <p>Para baixar o arquivo modelo e editar, <a href={url} target='_blank' rel="noreferrer">clique aqui</a></p>

            <span>Importar tabela</span>
            <input type="file" accept='.csv' style={{ display: 'none' }} id="file" onChange={handleSelectFile} ref={fileRef} />
            <label htmlFor='file' className={styles.uploadButton}>
                <span>Fazer upload</span>
                <Icon className={styles.icon}>upload</Icon>
            </label>

            {
                !!file && (
                    <>
                        <span>1 arquivo enviado:</span>
                        <div className={styles.selectedFile}>
                            <span>{file.name}</span>
                            <button onClick={() => {
                                setFile(undefined);
                                if (fileRef.current) {
                                    fileRef.current.value = '';
                                }
                                setCsv('');
                            }}>
                                <Icon>close</Icon>
                            </button>
                        </div>
                    </>
                )
            }

            {
                !!errors.length &&
                <div className={styles.errors}>
                    <span>O arquivo contém os seguintes erros:</span>
                    {
                        errors.map(x => (
                            <p>{x}</p>
                        ))
                    }
                </div>
            }

            <div className={styles.buttonsContainer}>
                <button onClick={onClose}>
                    Cancelar
                </button>
                <button onClick={handleSubmit} disabled={loading || !file}>
                    {
                        loading ? 'Enviando...' : 'Concluir'
                    }
                </button>
            </div>
        </div>
    )
}