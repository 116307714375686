import { TextSnippetOutlined } from '@mui/icons-material'
import styles from './FallbackMainDash.module.scss'

const FallbackMainDash = () => {
    return (
        <div id={styles.FallbackMainDash}>
            <TextSnippetOutlined />
            <p>Parece que você ainda <b>não possui <br />dados</b> para serem exibidos aqui </p>
        </div>
    )
}

export default FallbackMainDash