import { Icon, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "components/ui/Button/Button";
import { Modal } from "components/ui/modal/Modal";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import { IListObj } from "modules/events/presentation/utils/utils";
import React, { FC, useEffect } from "react";
import DiscountProductForm from "./components/form/DiscountProductForm";
import DiscountProductList from "./components/list/DiscountProductList";
import styles from "./DiscountListEditForm.module.scss";
import { IDiscountProductListForm } from "./interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "./interfaces/IProductList";
import UseDiscountListEditForm from "./UseDiscountListEditForm";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";

export interface IDiscountListEditFormProps {
  baseListId: string;
  values: IDiscounts[];
  productList: IProductItemToDiscount[];
  onDeleteItem: (itemId: string) => Promise<void>;
  onAddItem: (
    newValue: IDiscountProductListForm,
    baseListId: string
  ) => Promise<any>;
  adminAccess?: boolean;
  handleEditDiscount: (discount: IDiscountProductListForm) => Promise<void>;
}
const DiscountListEditForm: FC<IDiscountListEditFormProps> = ({
  baseListId,
  values,
  productList,
  onDeleteItem,
  onAddItem,
  adminAccess,
  handleEditDiscount,
}) => {
  const {
    openModalAdd,
    onClickDeleteHandle,
    onClickAddHandle,
    onSubmitDiscountHandle,
    onClickCloseHandle,
    deleting,
    onClickEditHandle,
    discountToEdit,
    discountsFilterList,
    filterByProduct,
    filteredDiscounts,
  } = UseDiscountListEditForm(
    baseListId,
    values,
    onDeleteItem,
    onAddItem,
    handleEditDiscount
  );
  return (
    <div id={styles.DiscountListEditForm}>
      <div className={styles.content}>
        <Autocomplete
          classes={{ popper: styles.popper }}
          options={discountsFilterList}
          size="small"
          getOptionLabel={(option) => option.name}
          onChange={(_evt, value: IListObj | null) => filterByProduct(value)}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              label="Filtrar"
              color={"secondary"}
              {...params}
            />
          )}
        />

        <div className={styles.container}>
          <DiscountProductList
            discountsList={filteredDiscounts}
            onPressDelete={onClickDeleteHandle}
            deleting={deleting}
            adminAccess={adminAccess}
            onPressEdit={onClickEditHandle}
          />
        </div>
        <div className={styles.buttonContainer}>
          {adminAccess && (
            <Button
              fullWidth={false}
              variant={"text"}
              onClick={onClickAddHandle}
            >
              <Icon>add</Icon>
              <u>Adicionar desconto</u>
            </Button>
          )}
        </div>
      </div>
      <RightDrawer
        open={openModalAdd}
        title={discountToEdit ? "Editar" : "Adicionar"}
        onClose={onClickCloseHandle}
      >
        <div className={styles.editDiscount}>
          <DiscountProductForm
            productList={productList}
            discountsList={filteredDiscounts}
            onSubmit={onSubmitDiscountHandle}
            onClose={onClickCloseHandle}
            defaultValue={discountToEdit}
          />
        </div>
      </RightDrawer>
    </div>
  );
};
export default DiscountListEditForm;
