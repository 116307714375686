import { FC } from "react";
import Layout from "components/layout/presentation/Layout";
import TutorialPage from "modules/tutoriais/presentation/pages/TutorialPage";

const Tutoriais: FC = () => {
  return (
    <Layout disabledMenu>
      <TutorialPage />
    </Layout>
  );
};
export default Tutoriais;
