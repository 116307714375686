import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./PrintersFragment.module.scss";
import { AddCircle, LocalPrintshopOutlined } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import { IGetPrintersResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetPrintersResponse";
import SimplePrintersList from "./printersList/SimplePrintersList";
import { IPostNewPrinterRequest } from "modules/config/smartPrinterConfig/domain/dtos/IPostNewPrinterRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CircularProgress, TextField } from "@mui/material";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { UsePrintersFragment } from "./UsePrintersFragment";

enum StepEnum {
    PRINTER_LIST = 0,
    NEW_PRINTER = 1,
    DELETE_PRINTER = 2,
}

export interface IPrintersFragmentProps {
    onClose: () => void;
}

const PrintersFragment = ({ onClose }: IPrintersFragmentProps) => {
    const {
        simplePrintersLoading: isLoading,
        getSimplePrintersList: getPrinters,
        postNewSimplePrinter,
        deleteSimplePrinter
    } = UsePrintersFragment();
    const { currentLocal } = useLocal();
    const [step, setStep] = useState<StepEnum>(StepEnum.PRINTER_LIST);
    
    const [printersList, setPrintersList] = useState<IGetPrintersResponse[]>();
    const [newPrinterValue, setNewPrinterValue] = useState("");
    const [isEditing, setIsEditing] = useState<IGetPrintersResponse>();
    const [isDeleting, setIsDeleting] = useState<string>();
    const [error, setError] = useState("");

    const updatePrintersList = useCallback(() => {
        getPrinters().then((response) => {
            setPrintersList(response)
        })
    }, [getPrinters]);

    useEffect(() => {
        updatePrintersList();
    }, [updatePrintersList]);

    useEffect(() => {
        if (isEditing) {
            setNewPrinterValue(isEditing.Nome);
            setStep(StepEnum.NEW_PRINTER);
        }
    }, [isEditing]);

    useEffect(() => {
        if (isDeleting) {
            setStep(StepEnum.DELETE_PRINTER);
        }
    }, [isDeleting]);

    const handleSubmit = useCallback(async (values: IPostNewPrinterRequest) => {
        if (!newPrinterValue) {
            return setError("Campo obrigatório.");
        }
        try {
            await postNewSimplePrinter(values);
            updatePrintersList();
            setNewPrinterValue("");
            setIsEditing(undefined);
            return setStep(StepEnum.PRINTER_LIST);
        } catch (error) {
            return console.error(error);
        }
    }, [newPrinterValue, postNewSimplePrinter, updatePrintersList]);

    const handleDelete = useCallback(async () => {
        if (isDeleting) {
            try {
                await deleteSimplePrinter(isDeleting);
                updatePrintersList();
                setIsDeleting(undefined);
                return setStep(StepEnum.PRINTER_LIST);
            } catch (error) {
                return console.error(error);
            }
        }
    }, [deleteSimplePrinter, isDeleting, updatePrintersList]);

    const nextProps = useMemo(() => {
        if (step === StepEnum.PRINTER_LIST) {
            return undefined;
        }
        if (step === StepEnum.NEW_PRINTER) {
            if (isEditing) {
                return {
                    onClick: () => handleSubmit({...isEditing, Nome: newPrinterValue, localClienteId: currentLocal?.id ?? "" }),
                    children: isLoading ? <CircularProgress size={16} color="inherit" /> : "Salvar",
                    disable: !newPrinterValue,
                };
            }
            return {
                onClick: () => handleSubmit({ Nome: newPrinterValue, localClienteId: currentLocal?.id ?? "" }),
                children: isLoading ? <CircularProgress size={16} color="inherit" /> : "Salvar",
                disable: !newPrinterValue,
            };
        }
        if (step === StepEnum.DELETE_PRINTER) {
            return {
                onClick: handleDelete,
                children: isLoading ? <CircularProgress size={16} color="inherit" /> : "Excluir"
            };
        }
        return undefined;
    }, [step, isEditing, isLoading, newPrinterValue, handleSubmit, currentLocal?.id, handleDelete]);

    const backProps = useMemo(() => {
        if (step === StepEnum.PRINTER_LIST) {
            return {
                onClick: () => onClose(),
                children: "Cancelar"
            };
        }
        if (step === StepEnum.NEW_PRINTER || step === StepEnum.DELETE_PRINTER) {
            return {
                onClick: () => {
                    setNewPrinterValue("");
                    setIsEditing(undefined);
                    setIsDeleting(undefined);
                    setStep(StepEnum.PRINTER_LIST);
                },
                children: "Voltar"
            };
        }
        return undefined;
    }, [onClose, step]);

    return (
        <div id={styles.PrintersFragment} >
            <div className={styles.container}>
                {step === StepEnum.PRINTER_LIST && (
                    <div className={styles.step}>
                        <Button variant="outlined" color='primary' onClick={() => setStep(StepEnum.NEW_PRINTER)} endIcon={<AddCircle />}>Nova impressora</Button>
                        <div className={styles.description} >
                            <LocalPrintshopOutlined fontSize="large" />
                            <p>Operadores são quem irão utilizar o seu terminal de vendas. Você pode limitar as permissões de cada um.</p>
                        </div>
                        <SimplePrintersList
                            simplePrintersList={printersList ?? []}
                            onClickEdit={setIsEditing}
                            onClickDelete={setIsDeleting}
                        />
                    </div>
                )}
                {step === StepEnum.NEW_PRINTER && (
                    <div className={styles.step}>
                        <div className={styles.column}>
                            <label htmlFor="name" className={styles.required}>
                                Nome da impressora
                            </label>
                            <TextField
                                variant="outlined"
                                name="name"
                                size="small"
                                value={newPrinterValue}
                                onChange={(ev) => {
                                    setError("");
                                    setNewPrinterValue(ev.target.value);
                                }}
                                error={!!error}
                                helperText={error}
                            />
                        </div>
                    </div>
                )}
                {step === StepEnum.DELETE_PRINTER && (
                    <div className={styles.step}>
                        <SidesheetFeedback text="Esta impressora pode estar vinculada a produtos, ao excluir você irá desvincular todos os produtos dela. Deseja realmente excluir esta impressora? Esta operação não poderá ser desfeita" />
                    </div>
                )}
            </div>
            <div className={styles.buttonContainer}>
                {backProps && <Button variant="outlined" {...backProps} />}
                {nextProps && <Button variant="contained" {...nextProps} />}
            </div>
        </div>
    )
}

export default PrintersFragment;
