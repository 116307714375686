import { useCallback, useState } from "react";
import PosSolicitationsApi from "services/api/posSolicitations/PosSolicitationsApi";
import { IPosSolicitationsData } from "../../domain/dto/IGetPosSolicitations";
import { IFilterParams } from "../interface/IFilterParams";
import { useQuery } from "react-query";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetExternalPosSolicitationsUseCase from "../../application/getExternalPosSolicitationsUseCase";
import PutApproveExternalPosUseCase from "../../application/PutApproveExternalPosUseCase";
import PutImportExternalPosUseCase from "../../application/PutImportExternalPosUseCase";

const service = PosSolicitationsApi();

const UsePosSolicitationsPage = () => {
    const { toast } = useUi();

    const [isLoadingApproval, setIsLoadingApproval] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [filterParams, setFilterParams] = useState<IFilterParams>({} as IFilterParams);

    const getPosSolicitations = useCallback(
        async (page: number, pageSize?: number) => {
            try {
                const response = await GetExternalPosSolicitationsUseCase(service, { ...filterParams, page, pageSize });

                setCurrentPage(response.currentPage);
                setTotalPages(response.totalPages);

                return response.data;
            } catch (error) {
                console.error(error);
            }
        },
        [filterParams]
    );

    const { data, isLoading, refetch, isFetching } = useQuery<IPosSolicitationsData[] | undefined>(
        [`@PosSolicitations`, currentPage, pageSize], () => {
            return getPosSolicitations(currentPage, pageSize);
        },
        { retry: false }
    )

    const putImportSolicitation = useCallback(async (solicitationId: string) => {
        try {
            setIsLoadingApproval(true);
            await PutImportExternalPosUseCase(service, solicitationId);
            return "success";
        } catch (error) {
            toast("Erro ao importar POS. Tente novamente!", "error");
        } finally {
            setIsLoadingApproval(false);
        }
    }, [toast]);

    const putApproveSolicitation = useCallback(async (solicitationId: string) => {
        try {
            setIsLoadingApproval(true);
            await PutApproveExternalPosUseCase(service, solicitationId);
            return "success";
        } catch (error) {
            toast("Erro ao aprovar solicitação. Tente novamente!", "error");
        } finally {
            setIsLoadingApproval(false);
        }
    }, [toast]);

    return {
        isLoading,
        isFetching,
        data,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPages,
        filterParams,
        setFilterParams,
        refetch,
        putImportSolicitation,
        putApproveSolicitation,
        isLoadingApproval
    }
}

export default UsePosSolicitationsPage;