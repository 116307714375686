import React, { FC } from 'react'
import styles from '../List.module.scss'
import { Skeleton } from '@mui/material'
export interface ISkeletonListProps {
    //propertys
    pageSizes?: number[]
}
const SkeletonList: FC<ISkeletonListProps> = () => {
    return (
        <div id={styles.List} >
            {/* <div className={styles.header}>
                <Skeleton variant='rectangular' style={{ width: '100%', height: 40}}></Skeleton>
            </div> */}
            <div className={styles.list} style={{ gap: 5 }} >
                <Skeleton variant='rectangular' style={{ width: '100%', height: 56 }}></Skeleton>
                <Skeleton variant='rectangular' style={{ width: '100%', height: 56 }}></Skeleton>
                <Skeleton variant='rectangular' style={{ width: '100%', height: 56 }}></Skeleton>
            </div>
        </div>
    )
}
export default SkeletonList