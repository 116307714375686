export enum PeriodEnum {
  LAST_DAY = 1,
  LAST_WEEK = 2,
  LAST_MONTH = 3,
  LAST_SESSION = 4,
  LAST_HOUR = 5,
  INTERVAL = 6,
  SESSION = 7,
}

export interface IlocalFilter {
  id: string;
  name: string;
  color?: string;
}

export interface ISession {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

export interface IFilterValue {
  startDate?: string,
  endDate?: string,
  period?: PeriodEnum,
  sessions?: ISession[],
  locals: IlocalFilter[]
}