import { Icon } from '@material-ui/core';
import styles from './HistoryModal.module.scss';
import { useEffect, useState } from 'react';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { FidelityServiceApi } from 'services/api/fidelity/FidelityApi';
import { GetHistoryUseCase } from 'modules/fidelity/application/useCases/GetHistoryUseCase';
import { IHistory } from 'modules/plans/presentation/interfaces/IHistory';
import Sidesheet from 'components/sidesheet/Sidesheet';

interface HistoryModalProps {
    open: boolean;
    ownerId: string;
    onClose: () => void;
}

const service = FidelityServiceApi();

export const HistoryModal = ({ open, ownerId, onClose }: HistoryModalProps) => {
    const { toast } = useUi();

    const [history, setHistory] = useState<IHistory[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await GetHistoryUseCase(service, ownerId);
                setHistory(response);
            } catch {
                toast('Ocorreu um erro ao buscar o histórico desse local', 'error');
            } finally {
                setLoading(false);
            }
        })();
    }, [ownerId, toast]);

    return (
        <Sidesheet
            open={open}
            onClose={onClose}
            title={
                <h2>
                    Histórico de <b>alterações</b>
                </h2>
            }
            content={
                <div className={styles.content}>
                    {
                        loading ?
                            <>
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            </> :
                            history.map(x => (
                                <div className={styles.item}>
                                    <div className={styles.icon}><img src="/assets/icon/fidelity/history_icon.svg" alt="" /></div>
                                    <div className={styles.itemContent}>
                                        <div className={styles.itemHeader}>
                                            <h3>{x.changeTitle}</h3>
                                            <span>{format(new Date(x.changeDate + 'Z'), 'dd/MM/yyyy HH:mm')}</span>
                                        </div>
                                        <span className={styles.changedBy}>{x.changedBy}</span>
                                        {
                                            x.exchange && (
                                                <div className={styles.descriptionContainer}>
                                                    <div>
                                                        <span>Cada: <b>1 moeda</b></span>
                                                        <Icon className={styles.chevron}>chevron_right</Icon>
                                                        <span>Equivale a: <b>{new Intl.NumberFormat("pt-BR", {style: "currency",currency: "BRL"}).format(x.exchange.value || 0)}</b></span>
                                                    </div>
                                                    <div>
                                                        <span>Cada: <b>1 moeda</b></span>
                                                        <Icon className={styles.chevron}>chevron_right</Icon>
                                                        <span>Equivale a: <b>{new Intl.NumberFormat("pt-BR", {style: "currency",currency: "BRL"}).format(x.exchange.newValue || 0)}</b></span>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            ))
                    }
                </div>
            }
            currentStep={1}
            totalSteps={1}
            continueButton="Fechar"
            handleContinueButton={onClose}
        />
    )
}