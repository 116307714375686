import { useEffect } from "react";
import { MenuItem, Select, TextField } from "@mui/material";
import styles from "../AddSystemSidesheet.module.scss";
import { IPostSystemConfigPayload } from "modules/admin/menuConfig/domain/dto/IPostSystemConfigPayload";
import { UseMenuConfigPage } from "../../../hooks/UseMenuConfigPage";
import { FormHelperText } from "@material-ui/core";
import { INameFormErrors } from "./NameValidation";

interface INameStepProps {
    addSystemConfigValues: IPostSystemConfigPayload;
    setAddSystemConfigValues: React.Dispatch<React.SetStateAction<IPostSystemConfigPayload>>;
    errors: INameFormErrors
    setErrors: React.Dispatch<React.SetStateAction<INameFormErrors>>;
}

const NameStep = ({ addSystemConfigValues, setAddSystemConfigValues, errors, setErrors }: INameStepProps) => {
    const { systemIdsList, getSystemIds } = UseMenuConfigPage();

    useEffect(() => {
        getSystemIds();
    }, [getSystemIds]);

    return (
        <div id={styles.NameStep}>
            <div className={styles.input}>
                <label htmlFor="name" className={styles.required}>
                    Nome
                </label>
                <TextField
                    name="name"
                    variant="outlined"
                    size="small"
                    value={addSystemConfigValues.name}
                    onChange={(ev) => {
                        setAddSystemConfigValues({...addSystemConfigValues, name: ev.target.value });
                        setErrors((prev) => ({ ...prev, name: undefined }));
                    }}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{
                        '& input': {
                            height: "40px",
                            boxSizing: "border-box"
                        },
                    }}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="system" className={styles.required}>
                    Identificador de sistema
                </label>
                <Select
                    name="system"
                    variant="outlined"
                    size="small"
                    value={addSystemConfigValues.systemIdentifier}
                    onChange={(ev) => {
                        setAddSystemConfigValues({...addSystemConfigValues, systemIdentifier: Number(ev.target.value) });
                        setErrors((prev) => ({ ...prev, systemIdentifier: undefined }));
                    }}
                    error={!!errors.systemIdentifier}
                    sx={{ boxSizing: "border-box !important" }}
                >
                    {systemIdsList.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ margin: "4px 14px 0", color: "#f44336" }}>{errors.systemIdentifier}</FormHelperText>
            </div>
        </div>
    );
}

export default NameStep;