import React from "react";
import Layout from "components/layout/presentation/Layout";
import { DiscountCouponsPage } from "modules/config/discountCoupons/presentation/DiscountCouponsPage";

const DiscountCoupons = () => {
  return (
    <Layout>
      <DiscountCouponsPage />
    </Layout>
  );
};

export default DiscountCoupons;
