import { useMemo } from "react";
import styles from "./SimplePrintersList.module.scss";
import { IconButton } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { IGetPrintersResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetPrintersResponse";

export interface ISimplePrintersListProps {
    simplePrintersList: IGetPrintersResponse[];
    onClickEdit: (printer: IGetPrintersResponse) => void;
    onClickDelete: (printerId: string) => void;
    isLoading?: boolean;
}
const SimplePrintersList = ({ simplePrintersList, onClickEdit, onClickDelete, isLoading }: ISimplePrintersListProps) => {
    const list = useMemo(() => <div className={styles.listContainer}>
        {
            simplePrintersList.map((printer, index) => (
                <>
                    <div key={index} className={styles.printerItem} >
                        <div className={styles.name} >{printer.Nome}</div>
                        <div className={styles.actions} >
                            <IconButton onClick={() => onClickEdit(printer)} disableRipple ><EditOutlined /></IconButton>
                            <IconButton onClick={() => onClickDelete(printer.Id)} ><DeleteOutlined /></IconButton>
                        </div>
                    </div>
                    {index !== simplePrintersList.length -1 && <Divider />}
                </>
            ))
        }
    </div>, [simplePrintersList, onClickEdit, onClickDelete]);

    const skeleton = useMemo(() => (
        <div className={styles.listContainer}>
            {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} width="100%" height={50} />
            ))}
        </ div>
    ), []);

    return (
        <div id={styles.SimplePrintersList} >
            <div className={styles.container} >
                {isLoading ? skeleton : list}
            </div>
        </div>
    )
}
export default SimplePrintersList