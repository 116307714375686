import { IGetOperatorByLocalIdResponse } from "../domain/dto/IGetOperatorByLocalIdResponse";
import { ISaleForProductReportService } from "../domain/interface/ISaleForProductReportService";


const GetOperatorByLocalIdUseCase = async (service: ISaleForProductReportService, localId: string): Promise<IGetOperatorByLocalIdResponse[]> => {
  const response = service.getOperatorByLocalId(localId);
  return response;
};

export default GetOperatorByLocalIdUseCase;
