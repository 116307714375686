import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import MigrateGuestsUseCase from 'modules/events/application/useCases/guests/MigrateGuestsUseCase';
import { IMigrateGuestsRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IGuestAlreadyInList } from 'modules/events/domain/models/IEvents';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EventsGuestsApi } from 'services/api/events/guests/EventsGuestsApi';

const UseMigrateGuestList = (
  guestsToMigrate: IGuestAlreadyInList[] | undefined,
  toggleModal: () => void,
  listName: string | undefined,
) => {
  const { listId } = useParams<{ listId: string }>();
  const { goBack } = useHistory();
  const { currentLocal } = useLocal();
  const { toast, hideLoading, showLoading } = useUi();
  const guestService = EventsGuestsApi();
  const [guestList, setGuestList] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const totalGuestList = useMemo(
    () => guestsToMigrate?.map(item => item.cpf) || [],
    [guestsToMigrate],
  );

  useEffect(() => {
    if (guestList.length === totalGuestList?.length) {
      !checkAll && setCheckAll(true);
    } else {
      checkAll && setCheckAll(false);
    }
  }, [checkAll, guestList, totalGuestList]);

  const handleCheckAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      setCheckAll(true);
      setGuestList(totalGuestList);
    } else {
      setCheckAll(false);
      setGuestList([]);
    }
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const cpf = event.target.value;
    if (checked) {
      setGuestList(prev => [...prev, cpf]);
    } else {
      setGuestList(prev => prev.filter(item => item !== cpf));
    }
  };

  const isChecked = useCallback(
    (cpf: string, guestList: string[]) => {
      return !!guestList.find(item => item === cpf);
    },
    [],
  );

  const handleConfirmMigration = async (cpfList: string[]) => {
    if (cpfList.length > 0 && currentLocal) {
      showLoading();
      try {
        const guestListToMigrate: IMigrateGuestsRequest = {
          cpfs: cpfList
        }
        const result = await MigrateGuestsUseCase(guestService, guestListToMigrate, listId, currentLocal.id);
        toast('Convidados adicionados com sucesso!', 'success');
      } catch (error) {
        toast('Falha ao adicionar convidados, tente novamente mais tarde', 'error')
        console.error(error)
      } finally {
        hideLoading();
      }
    }

    goBack();
    toggleModal();
  };

  return {
    guestList,
    checkAll,
    isChecked,
    handleCheck,
    handleCheckAll,
    handleConfirmMigration,
  };
};

export default UseMigrateGuestList;
