import GetPaymentMethodUseCase from 'modules/events/application/useCases/voucher/GetPaymentMethodUseCase';
import { IPaymentMethod, IPermutationAccountResponse, IRecordPermutationAccount } from 'modules/events/domain/dto/IEventsResponse';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { GetDataFromCpfUseCase } from 'modules/schedule/application/useCases/clientSchedule/GetDataFromCpfUseCase';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CashlessVoucherApi } from 'services/api/events/voucher/CashlessVoucherApi';
import ClientsScheduleApi from 'services/api/schedule/client/ClientsScheduleApi';
import { capitalize } from 'services/utils/String';
import { ICashlessConsummationForm, ICashlessConsummationValidationForm } from './interfaces/ICashlessConsummationForm';
import { validate } from './validation/CashlessConsummationValidation';
import GetPermutationAccountUseCase from 'modules/events/application/useCases/voucher/GetPermutationAccountUseCase';

const UseCashlessConsummationForm = (onSubmit: (cashlessValues: ICashlessConsummationForm) => Promise<void>, defaultVoucher?: ICashlessConsummationForm) => {
  const [isCpfLoaded, setIsCpfLoaded] = useState(false);
  const [cpfLoading, setCpfLoading] = useState(false);
  const [values, setValues] = useState<ICashlessConsummationForm>(defaultVoucher ?? {} as ICashlessConsummationForm)
  const [voucherValue, setVoucherValue] = useState(0)
  const [errors, setErrors] = useState<ICashlessConsummationValidationForm>({})
  const { currentLocal } = useLocal();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([] as IPaymentMethod[])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod>({} as IPaymentMethod);
  const [permutationAccount, setPermutationAccount] = useState<IPermutationAccountResponse>()
  const [permutationAccountSelected, setPermutationAccountSelected] = useState<IRecordPermutationAccount>()
  const [permutationAccountFetched, setPermutationAccountFetched] = useState(false);
  const paymentTypeId = useRef<number | null>(null);
  const permutationTypeId = useRef<boolean>(false);

  useEffect(() => {
    if (currentLocal?.id) {
      const getPaymentMethod = async () => {
        const cashlessVoucherApi = CashlessVoucherApi();
        try {
          const result = await GetPaymentMethodUseCase(cashlessVoucherApi, currentLocal.id);
          setPaymentMethods(result);
        } catch (error) {
          // console.error(error);
        }
      }
      getPaymentMethod()
    }
  }, [currentLocal?.id]);

  const getPermutationAccount = useCallback(async () => {
    if (currentLocal?.id) {
      const cashlessVoucherApi = CashlessVoucherApi();
      try {
        const result = await GetPermutationAccountUseCase(cashlessVoucherApi, values.cpf, currentLocal.id, values?.accountId ?? '');
        setPermutationAccount(result);
        if(!!defaultVoucher && !!result.records.length) {
          setValues(prev => ({
            ...prev,
            permutationAccountId: result.records[0].id 
          }))
        }
        permutationTypeId.current = result.records[0].hasOpenMeepAccount;
        // setPermutationAccountSelected(result.records[0])
      } catch (error) {
        // console.error(error);
      }
    }
  }, [currentLocal, defaultVoucher, values?.accountId, values.cpf]);

  useEffect(() => {
    if (!!defaultVoucher) {
      if (values.cpf && !permutationAccountFetched) {
        getPermutationAccount();
        setPermutationAccountFetched(true);
      }
    } else {
      if (values.cpf && values.paymentType === 21 && !permutationAccountFetched) {
        getPermutationAccount();
        setPermutationAccountFetched(true);
      }
    }

  }, [getPermutationAccount, values.cpf, values.paymentType, permutationAccountFetched, defaultVoucher, paymentMethods]);

  useEffect(() => {
    if (!!defaultVoucher) {
      const matchedPaymentMethod = paymentMethods.find(
        (method) => method.id === defaultVoucher.lastPaymentTypeId
      );

      if (matchedPaymentMethod) {
        setSelectedPaymentMethod(matchedPaymentMethod);
        paymentTypeId.current = matchedPaymentMethod.id;
        setValues((prev) => ({
          ...prev,
          paymentType: matchedPaymentMethod.id,
        }));
      }
    }
  }, [defaultVoucher, paymentMethods, selectedPaymentMethod])


  useEffect(() => {
    setPermutationAccountFetched(false);
  }, [values.cpf]);

  const loadCpf = useCallback((cpf: string) => {
    (async () => {
      try {
        setCpfLoading(true);
        if (currentLocal?.id) {
          const data = await GetDataFromCpfUseCase(currentLocal.id, cpf, ClientsScheduleApi);

          setIsCpfLoaded(true)
          setValues(prev => ({
            ...prev,
            name: capitalize(data.name)
          }))
        }
      } catch {
        setIsCpfLoaded(false)
      } finally {
        setCpfLoading(false);
      }
    })();
  }, [currentLocal]);

  const handleAddValue = () => {
    setVoucherValue(prev => prev + 50)
  }

  const handleReduceValue = () => {
    setVoucherValue(prev => prev - 50 < 0 ? 0 : prev - 50)
  }

  const handleSubmitForm = async (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (validate(values, setErrors)) {
      await onSubmit({ ...values, balance: voucherValue });
    }
  };

  const handleChangeCpf = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const cpf = evt.target.value.replace(/\D/g, '');
    setValues(prev => ({
      ...prev,
      cpf,
    }))

    if (cpf.length === 11) loadCpf(cpf);
  }

  const handleChangeValues = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues(prev => ({
      ...prev,
      [evt.target.name]: evt.target.value
    }))
  }


  const balancePermutation = () => {
    const filter = permutationAccount?.records.find(item => item.id === values.permutationAccountId);
    if (filter !== undefined) {
      if (filter.availableBalance > 0) {
        if (values?.initialBalance > 0) {
          return filter.availableBalance - values?.initialBalance;
        } else {
          return filter.availableBalance;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const HandleChangeDiscountValue = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    setValues(prev => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    setVoucherValue(Number(evt.target.value))

  }

  const handleChangePaymentType = (paymentType: IPaymentMethod) => {
    if (paymentType) {
      paymentTypeId.current = paymentType.id;
      setValues((prev) => ({
        ...prev,
        paymentType: paymentType.id,
      }));
      setSelectedPaymentMethod(paymentType);
    }
  };

  const handleChangePermutation = (permutation: IRecordPermutationAccount) => {
    setValues(prev => ({
      ...prev,
      permutationAccountId: permutation.id
    }))
  }

  return {
    values,
    errors,
    paymentMethods,
    voucherValue,
    isCpfLoaded,
    cpfLoading,
    handleSubmitForm,
    handleChangeValues,
    HandleChangeDiscountValue,
    handleChangePaymentType,
    handleAddValue,
    handleReduceValue,
    handleChangeCpf,
    permutationAccount,
    handleChangePermutation,
    paymentTypeId,
    balancePermutation,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    permutationAccountSelected,
    permutationTypeId
  }
}

export default UseCashlessConsummationForm;