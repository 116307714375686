import { IPaymentLinkRecurrenceApiService } from "modules/paymentLinkReport/domain/interfaces/IPaymentLinkRecurrenceApiService";


export const CancelPermutationAccountUseCase = async (service: IPaymentLinkRecurrenceApiService, recurrenceId: string): Promise<any> => {

    const _request = await service.cancelPaymentLinkRecurrence(recurrenceId);
    return _request;
}


