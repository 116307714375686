import { ISort } from "interfaces/ISort";
import GetHouseFlowUseCase from "modules/houseFlow/application/useCases/GetHouseFlowUseCase";
import { EnumCardStatus } from "modules/houseFlow/domain/dto/IGetHouseFlowListResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import HouseFlowApi from "services/api/houseFlow/HouseFlowApi";
import GetDiscountCouponsUseCase from "../../application/useCases/GetDiscountCouponsUseCase";
import { IDiscountCouponFilter } from "../components/discountCouponFilter/interfaces/IDiscountCouponFilter";
import { IDiscountCouponsList } from "../interfaces/IDiscountCouponsList";

export interface IParamsClientDiscountCouponsList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: IDiscountCouponFilter
}

const UseDiscountCouponsList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [discountCouponsList, setDiscountCouponsList] = useState<IDiscountCouponsList>();
  const { currentLocal } = useLocal();

  const getDiscountCouponsList = useCallback(
    async (params?: IParamsClientDiscountCouponsList) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetDiscountCouponsUseCase(serviceDiscountCoupons,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );
          
          setDiscountCouponsList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    discountCouponsList,
    isLoading,
    getDiscountCouponsList,
    setDiscountCouponsList,
  };
};

export default UseDiscountCouponsList;
