import { useEffect, useState } from 'react'
import styles from './MainDashboard.module.scss'
import UseDimension from 'components/dimension/UseDimension'
import { SystemIdentifierEnum } from 'modules/gestaoVendas/domain/enum/GetSegmentosEnum'
import { format, parseISO } from 'date-fns';
import { IGetSessionsItemResponse } from 'modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse'
import BoxDigitalAccount from '../components/boxDigitalAccount/BoxDigitalAccount'
import TitlePeriod from '../components/titlePeriod/TitlePeriod'
import FilterMainDash from '../components/filterMainDash/FilterMainDash'
import SalesPerMounth from '../components/salesPerMounth/SalesPerMounth'
import InfoTicket from '../components/infoTicket/InfoTicket'
import ListProducts from '../components/listProducts/ListProducts'
import PaymentChart from '../components/paymentChart/PaymentChart'
import HourSales from '../components/hourSales/HourSales'
import SalesPerDay from '../components/salesPerDay/SalesPerDay'
import UseMainDashboard from './UseMainDashboard'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

const MainDashboardPage = () => {
    const [filterApplied, setFilterApplied] = useState(false)
    const [cashierSelect, setCashierSelect] = useState<IGetSessionsItemResponse | undefined>()
    const { dimensions } = UseDimension()
    const { currentLocal } = useLocal();
    const {
        saldoAtual,
        dayPayments,
        dayResume,
        cashiers,
        topProducts,
        monthResume,
        monthResumePerDay,
        yearResumePerMonth,
        submitFilter,
        period,
        setPeriod,
        filter,
        setFilter,
        dayPaymentsPerHour,
        loadingYearResumePerMonth,
        loadingTopProducts,
        loadingDayPayments,
        loadingDayPaymentsPerHour,
        loadingMonthResumePerDay,
        bankDetail
    } = UseMainDashboard()

    useEffect(() => {
        if (period === 'cashier') {
            const findCashier = cashiers?.items.find(item => item.id === filter)
            setCashierSelect(findCashier)
        }

    }, [period, filter, cashiers?.items])

    return (
        <div id={styles.MainDashboard}>
            {(currentLocal?.systemIdentifier === SystemIdentifierEnum.General || (currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && bankDetail?.length)) ?
                <BoxDigitalAccount data={saldoAtual} /> : null
            }
            <div className={styles.boxTitleFilter} >
                {filterApplied ? (
                    <TitlePeriod text={filter} period={period} cashiers={cashierSelect} />
                ) : (
                    <p className={styles.update}>Atualizado em:  {dayResume && format(parseISO(dayResume.cacheInfo.cachedAt), 'dd/MM/yyyy HH:mm')}</p>
                )}
                <FilterMainDash
                    filterApplied={filterApplied}
                    setFilterApplied={setFilterApplied}
                    period={period}
                    setPeriod={setPeriod}
                    filter={filter}
                    setFilter={setFilter}
                    cashiers={cashiers?.items}
                    submitFilter={submitFilter}
                />
            </div>
            <SalesPerMounth data={yearResumePerMonth} isLoading={loadingYearResumePerMonth} />
            {dimensions.width > 800 && <InfoTicket period={period} monthResume={monthResume} dayResume={dayResume} />}
            <div className={styles.box}>
                <ListProducts data={topProducts} isLoading={loadingTopProducts} filter={filter} />
                <PaymentChart data={dayPayments} isLoading={loadingDayPayments} filter={filter} />
            </div>
            <HourSales data={dayPaymentsPerHour} isLoading={loadingDayPaymentsPerHour} filter={filter} />
            <SalesPerDay data={monthResumePerDay} isLoading={loadingMonthResumePerDay} />
        </div>
    )
}

export default MainDashboardPage