import { NotificationProps as INotificationItem } from "components/layout/domain/dto/INotificationProps";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { NotificationProps } from "components/layout/domain/dto/INotificationProps";
import { INotificationsService } from "../../domain/interfaces/INotificationsService";

const GetNotifications = async (
  notificationsService: INotificationsService,
  token: string | null,
  systemIdentifier: SystemIdentifierEnum
): Promise<NotificationProps[]> => {
  return await notificationsService.getNotifications(token, systemIdentifier);
};

export default GetNotifications;
