import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { watch } from 'fs';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import React, { FC } from 'react'
import { Row, InputContainer } from '../../../ui/form/FormContainers';
export interface ISelectOriginTypeFormProps {
    //propertys
    disabled?: boolean;
    originType: PartnerOriginType;
    onChange: (originType: PartnerOriginType) => void;
}
const SelectOriginTypeForm: FC<ISelectOriginTypeFormProps> = ({
    originType,
    onChange,
    disabled
}) => {
    return (
        <Row>
            <InputContainer label={"Tipo de identificação"}>
                <RadioGroup
                    row
                    onChange={(e) => {
                        onChange(Number(e.target.value));
                    }}
                    value={originType}
                >                                     
                    <FormControlLabel disabled={disabled} value={PartnerOriginType.INDIVIDUAL} control={<Radio />} label="CPF" />
                    <FormControlLabel disabled={disabled} value={PartnerOriginType.CORPORATE} control={<Radio />} label="CNPJ" />
                </RadioGroup>
            </InputContainer>
        </Row >
    )
}
export default SelectOriginTypeForm