
import { FC } from 'react'
import { useLocalResume } from '../../contexts/localResume/LocalResumeContext'
import { Skeleton } from '../skeleton/Skeleton'
import styles from './CardValues.module.scss'

export interface ICardValuesProps {}

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)


const CardValues: FC<ICardValuesProps> = () => {
    const { localResume, loading } = useLocalResume();

    return (
        !localResume || loading ? <Skeleton items={3} containerStyle={styles.skeletonContainer} cardStyle={styles.skeletonItem} />
            :
            <div id={styles.CardValues} >
                <div className={styles.container} >
                    <div className={styles.card} >
                        <h3>Recebido</h3>
                        <div className={styles.item}>
                            <label>Consumo pós-pago</label>
                            <span>{formatCurrency(localResume.received?.customerConsumption ?? 0)}</span>
                        </div>
                        <div className={styles.item}>
                            <label>Taxa de serviço</label>
                            <span>{formatCurrency(localResume.received?.serviceFee)}</span>
                        </div>
                        {
                            !!localResume.received?.counterSales &&
                                <div className={styles.item}>
                                    <label>Vendas balcão</label>
                                    <span>{formatCurrency(localResume.received?.counterSales)}</span>
                                </div>
                        }
                        {
                            !!localResume.received?.discounts &&
                                <div className={styles.item}>
                                    <label>Descontos</label>
                                    <span>{formatCurrency(localResume.received?.discounts)}</span>
                                </div>
                        }
                        <div className={styles.item}>
                            <label>Valor faturado</label>
                            <span>{formatCurrency(localResume.received?.received)}</span>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.flexStart}`} >
                        <h3>Valor</h3>
                        <div className={styles.columnsContainer}>
                            <div className={styles.item}>
                                <label>Consumido</label>
                                <span>{formatCurrency(localResume.values?.consumed)}</span>
                            </div>
                            <div className={styles.item}>
                                <label>Em aberto</label>
                                <span>{formatCurrency(localResume.values?.opened)}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.flexStart}`} >
                        <h3>Ticket médio consumo</h3>
                        <div className={styles.columnsContainer}>
                            <div className={styles.item}>
                                <label>Por mesa</label>

                                <span>{localResume.averageTickets?.tables} mesas</span>
                                <span>{formatCurrency(localResume.averageTickets?.consumedTable)}</span>

                            </div>
                            <div className={styles.item}>
                                <label>Por pessoa</label>

                                <span>{localResume.averageTickets?.customers} pessoas</span>
                                <span>{formatCurrency(localResume.averageTickets?.consumedCustomers)}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default CardValues