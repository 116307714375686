import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./CrmFilterForm.module.scss";
import { Button, CircularProgress, Drawer, TextField } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import {
  FilterOperatorType,
  ICrmFilterValue,
  ICrmFormOperatorValue,
  IDropdown,
} from "./interfaces/ICrmFilterValue";
import { ISavedFilter } from "./interfaces/ISavedFilter";
import { InputContainer } from "../_inputContainer/InputContainer";
import { InputCRM } from "../_inputCRMFilter/InputCRM";
import SelectOperatorCRMFilter from "./components/_selectOperator/SelectOperatorCRMFilter";
import { EnumGender } from "../../intefaces/GenderEnum";
import { InputGender } from "./components/_inputGender/InputGender";
import SavedFilterList from "./components/savedFilters/_SavedFilterList";
import { Icon, IconButton, Checkbox } from "@material-ui/core";
import { DateRangePicker } from "./components/dateRangerPicker/DateRangePicker";
import { useQuery } from "hooks/UseQuery";
import { getHealthIcon } from "../crmList/crmUserContainer/CrmUserItem";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFidelityPlans } from "modules/fidelity/presentation/interfaces/IFidelityPlans";
import { VerticalAlignBottomOutlined } from "@material-ui/icons";

export interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export interface ICrmFilterFormProps {
  isLoading?: boolean;
  events: IDropdown[];
  eventCategories: IDropdown[];
  ticketsCategories: IDropdown[];
  fidelityPlanIds?: IDropdown[];
  fidelityList?: IFidelityPlans[];
  savedFilters: ISavedFilter[];
  onSubmit: (value: ICrmFilterValue) => void;
  onSaveFilter: (values: {
    name: string;
    filterString: string;
  }) => Promise<void>;
  onClickDeleteFilter: (savedfilter: ISavedFilter) => Promise<void>;
  setOpenSidesheetDownload: React.Dispatch<React.SetStateAction<string | null>>;
}
const CrmFilterForm = ({
  onSubmit,
  events,
  eventCategories,
  fidelityList,
  fidelityPlanIds,
  savedFilters,
  onSaveFilter,
  isLoading,
  onClickDeleteFilter,
  setOpenSidesheetDownload,
}: ICrmFilterFormProps) => {
  const [values, setValues] = useState<ICrmFilterValue>({
    keyword: "",
    events: undefined,
    gender: undefined,
    eventCategories: undefined,
    frequency: undefined,
    ticketCategories: undefined,
    age: undefined,
    averageTicketConsuption: undefined,
    totalConsuptionValue: undefined,
    averageTicketEventTicket: undefined,
    totalPurchasedTicketsValue: undefined,
    eventPeriod: undefined,
    birthday: undefined,
    hasMeepApp: false,
    points: undefined,
    fidelityLevel: undefined,
    coins: undefined,
  });

  const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
  const [openAdvance, setOpenAdvance] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");

  const query = useQuery();
  const { checkPermission } = usePlans();

  useEffect(() => {
    const keyword = query.get("keyword");
    if (keyword) {
      setValues((prev) => ({ ...prev, keyword: keyword }));
    }
  }, [query]);

  const handleClearFilter = () => {
    setValues({
      keyword: "",
      events: undefined,
      gender: undefined,
      eventCategories: undefined,
      frequency: undefined,
      ticketCategories: undefined,
      age: undefined,
      averageTicketConsuption: undefined,
      totalConsuptionValue: undefined,
      averageTicketEventTicket: undefined,
      totalPurchasedTicketsValue: undefined,
      eventPeriod: undefined,
      birthday: undefined,
      hasMeepApp: false,
    });
  };

  const onClickSavedFilter = useCallback((savedfilter: ISavedFilter) => {
    const ConvertData = (body: string) => {
      const parseFilter = JSON.parse(body) as ICrmFilterValue;

      const objectFilter: ICrmFilterValue = {
        ...parseFilter,
        birthday: parseFilter.birthday
          ? {
            startValue: new Date(String(parseFilter.birthday.startValue)),
            endValue: new Date(String(parseFilter.birthday.endValue)),
          }
          : undefined,
        eventPeriod: parseFilter.eventPeriod
          ? {
            startValue: new Date(String(parseFilter.eventPeriod.startValue)),
            endValue: new Date(String(parseFilter.eventPeriod.endValue)),
          }
          : undefined,
      };

      return objectFilter;
    };

    setValues(ConvertData(savedfilter.body));
    setOpenAdvance(true);
  }, []);

  const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmit(values);
  }, [onSubmit, values]);

  const onSaveFilterHandle = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (isLoading) return;
      try {
        await onSaveFilter({
          name: newFilterName,
          filterString: JSON.stringify(values),
        });
        setNewFilterName("");
        setOpenSaveFilter(false);
      } catch { }
    },
    [isLoading, newFilterName, onSaveFilter, values]
  );

  const onChangeKeyword = useCallback((keyword: string) => {
    setValues((prev) => ({ ...prev, keyword }));
  }, []);

  const onChangeEvent = useCallback((value?: IDropdown[] | null) => {
    if (value) {
      setValues((prev) => ({ ...prev, events: value }));
    }
  }, []);

  const onChangeEventCategory = useCallback((value?: IDropdown[] | null) => {
    if (value) {
      setValues((prev) => ({ ...prev, eventCategories: value }));
    }
  }, []);

  const onChangePointsHandle = useCallback((_value: ICrmFormOperatorValue) => {
    setValues((prev) => ({
      ...prev,
      points: {
        filterType: Number(_value?.filterType),
        startValue:
          _value?.startValue !== undefined && _value?.startValue !== null
            ? Number(_value?.startValue)
            : undefined,
        endValue:
          _value?.endValue !== undefined && _value?.endValue !== null
            ? Number(_value?.endValue)
            : undefined,
      },
    }));
  }, []);

  const onChangeFidelityLevel = useCallback((value?: IDropdown | null) => {
    if (value) {
      setValues((prev) => ({ ...prev, fidelityLevel: value }));
      let min = undefined;
      let max = undefined;

      fidelityList?.sort((a, b) => a.pointsRequired < b.pointsRequired ? -1 : 1).forEach((x, index) => {
        if (value.id === x.id) {
          min = x.pointsRequired || 0;
          if (index < fidelityList.length - 1) max = fidelityList[index + 1].pointsRequired;
        }
      });

      onChangePointsHandle({
        filterType: max ? FilterOperatorType.BETWEEN : FilterOperatorType.GREATER_THAN,
        startValue: String(min),
        endValue: String(max),
      })
    }
  }, [fidelityList, onChangePointsHandle]);

  const onChangeAgeHandle = useCallback((value: ICrmFormOperatorValue) => {
    setValues((prev) => ({
      ...prev,
      age: {
        filterType: value.filterType,
        startAge: value.startValue ? Number(value.startValue || 0) : 0,
        endAge: value.endValue ? Number(value.endValue || 0) : 0,
      },
    }));
  }, []);

  const onChangeGenderHandle = useCallback((value?: EnumGender) => {
    setValues((prev) => ({ ...prev, gender: value }));
  }, []);

  const onChangeFrequencyHandle = useCallback(
    (_value?: ICrmFormOperatorValue) => {
      setValues((prev) => ({
        ...prev,
        frequency: {
          filterType: Number(_value?.filterType),
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    []
  );

  const onChangeHaveApp = useCallback((value?: boolean) => {
    setValues((prev) => ({ ...prev, hasMeepApp: value }));
  }, []);

  const onChangeHasPhoneNumber = useCallback((value?: boolean) => {
    setValues((prev) => ({ ...prev, hasPhoneNumber: value }));
  }, []);
  const onChangeHasEmail = useCallback((value?: boolean) => {
    setValues((prev) => ({ ...prev, hasEmail: value }));
  }, []);

  const onChangeAverageTicketConsumptionHandle = useCallback(
    (_value?: ICrmFormOperatorValue) => {
      setValues((prev) => ({
        ...prev,
        averageTicketConsuption: {
          filterType: Number(_value?.filterType),
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    []
  );

  const onChangeAverageTicketEventTicketHandle = useCallback(
    (_value?: ICrmFormOperatorValue) => {
      setValues((prev) => ({
        ...prev,
        averageTicketEventTicket: {
          filterType: Number(_value?.filterType),
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    []
  );

  const onChangeOnChangeTotalConsuptionValueHandle = useCallback(
    (_value?: ICrmFormOperatorValue) => {
      setValues((prev) => ({
        ...prev,
        totalConsuptionValue: {
          filterType: Number(_value?.filterType),
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    []
  );

  const onChangeOnChangeTotalPurchasedTicketsValueHandle = useCallback(
    (_value?: ICrmFormOperatorValue) => {
      setValues((prev) => ({
        ...prev,
        totalPurchasedTicketsValue: {
          filterType: Number(_value?.filterType),
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    []
  );

  const onChangeSurveyHealthHandle = useCallback(
    (_value: ICrmFormOperatorValue<Number>) => {
      if (!checkPermission(FeatureTypeEnum.CRM)) return;

      setValues((prev) => ({
        ...prev,
        surveyHealth: {
          filterType: _value?.filterType,
          startValue:
            _value?.startValue !== undefined && _value?.startValue !== null
              ? Number(_value?.startValue)
              : undefined,
          endValue:
            _value?.endValue !== undefined && _value?.endValue !== null
              ? Number(_value?.endValue)
              : undefined,
        },
      }));
    },
    [checkPermission]
  );

  const onChangeBirthdayHandle = useCallback(
    (_values?: [Date, Date] | [Date]) => {
      if (_values) {
        const _startDate = _values[0];
        const _endDate = _values[1] || undefined;

        setValues((prevState) => ({
          ...prevState,
          birthday: {
            startValue: _startDate,
            endValue: _endDate,
          },
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          birthday: undefined,
        }));
      }
    },
    []
  );

  const onChangeEventPeriodHandle = useCallback((_values?: Date[]) => {
    if (_values) {
      const _startDate = _values[0];
      const _endDate = _values[1] || undefined;

      setValues((prevState) => ({
        ...prevState,
        eventPeriod: {
          startValue: _startDate,
          endValue: _endDate,
        },
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        eventPeriod: undefined,
      }));
    }
  }, []);

  const onChangeCoinsHandle = useCallback((_value: ICrmFormOperatorValue) => {
    setValues((prev) => ({
      ...prev,
      coins: {
        filterType: Number(_value?.filterType),
        startValue:
          _value?.startValue !== undefined && _value?.startValue !== null
            ? Number(_value?.startValue)
            : undefined,
        endValue:
          _value?.endValue !== undefined && _value?.endValue !== null
            ? Number(_value?.endValue)
            : undefined,
      },
    }));
  }, []);

  const getOperatorCRMFilterValue = (value: number | undefined) => {
    return value !== undefined && value !== null ? String(value) : undefined;
  };

  const distinctEvents = useMemo(() => {
    const distinctArray = events.filter((value, index, self) => {
      return self.findIndex((item) => item.name === value.name) === index;
    });
    return distinctArray;
  }, [events]);

  return (
    <>
      <div id={styles.crm}>
        <form onSubmit={submitHandle}>
          <div className={styles.row}>
              <InputContainer>
                <Autocomplete
                  options={distinctEvents}
                  onChange={(_, value) => onChangeEvent(value)}
                  value={values.events}
                  multiple
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <InputCRM
                      label={"Evento"}
                      {...params}
                      placeholder="Selecione o evento"
                    />
                  )}
                />
              </InputContainer>
              <InputContainer>
                <InputCRM
                  label="Buscar por cliente"
                  name="keyword"
                  placeholder="Busque por nome ou CPF"
                  value={values.keyword}
                  onChangeValue={onChangeKeyword}
                />
              </InputContainer>
          </div>
          <div className={styles.row}>
            <InputContainer className={styles.inputContainer} style={{ height: 'fit-content' }}>
              <DateRangePicker
                label={"Aniversariante"}
                placeholder={"Data início e data fim"}
                onChange={onChangeBirthdayHandle}
                showPremiumCrown
                selectRange
                premium
                value={
                  values.birthday?.startValue && values.birthday?.endValue
                    ? `${values.birthday.startValue.toLocaleDateString(
                      "pt-BR"
                    )} a ${values.birthday.endValue.toLocaleDateString(
                      "pt-BR"
                    )}`
                    : ""
                }
                valueCalendar={[
                  values.birthday?.startValue || null,
                  values.birthday?.endValue || null,
                ]}
              />
              {!!values.birthday && (
                <button
                  className={styles.singleFieldClearButton}
                  onClick={() =>
                    setValues((prev) => ({ ...prev, birthday: undefined }))
                  }
                >
                  <Icon fontSize="small" className={styles.clearIcon}>
                    close
                  </Icon>
                </button>
              )}
            </InputContainer>
            <InputContainer className={styles.genderRow}>
              <InputGender
                value={values.gender}
                onChange={onChangeGenderHandle}
              />
            </InputContainer>
          </div>
          <div className={styles.buttonsContainer}>
            {!openAdvance && (
              <>
              <div>
                <Button
                  onClick={handleClearFilter}
                  className={styles.clearButton}
                  variant="text"
                >
                  Limpar
                </Button>
                <Button
                  onClick={() => setOpenSaveFilter(true)}
                  className={styles.button}
                  variant="outlined"
                >
                  Salvar filtro
                </Button>
              </div>
                <Button
                  className={styles.searchButtonFilter}
                  variant={"contained"}
                  type={"submit"}
                >
                  Buscar
                </Button>
              </>
            )}
          </div>
          <div className={[styles.row, styles.filters].join(" ")}>
            <SavedFilterList
              savedFilters={savedFilters}
              onClickDeleteFilters={onClickDeleteFilter}
              onClickSavedFilter={onClickSavedFilter}
              isLoading={isLoading}
            />
            <div className={styles.actionButtons}>
              <div className={styles.exportButton} onClick={() => setOpenSidesheetDownload('true')}>
                <span>Exportar</span>
                <VerticalAlignBottomOutlined />
              </div>
              <Button
                className={styles.buttonAdvancedFilter}
                variant="text"
                onClick={() => {
                  setOpenAdvance((prev) => !prev);
                }}
              >
                {
                  !checkPermission(FeatureTypeEnum.CRM) &&
                  <img src="/assets/icon/golden-crown-label.svg" alt="" />
                }
                Filtros Avançados
                <Icon className={styles.icon}>
                  {openAdvance ? "close" : "sort"}
                </Icon>
              </Button>
            </div>
          </div>

          {/* Filtro Avançado */}
          <InputContainer>
            {openAdvance && (
              <>
                <InputContainer row>
                  <SelectOperatorCRMFilter
                    label={"Idade"}
                    startLabel="Digite a idade"
                    premium
                    showPremiumCrown={false}
                    values={{
                      filterType: values.age?.filterType,
                      startValue: String(values.age?.startAge),
                      endValue: String(values.age?.endAge),
                    }}
                    type={"number"}
                    onChange={(value: ICrmFormOperatorValue) => {
                      onChangeAgeHandle(value);
                    }}
                    onClear={() =>
                      setValues((prev) => ({ ...prev, age: undefined }))
                    }
                  />
                  <SelectOperatorCRMFilter
                    label={"Frequência"}
                    type={"number"}
                    startLabel="Digite a frequência mínima"
                    endLabel="Digite a frequência máxima"
                    premium
                    showPremiumCrown={false}
                    values={{
                      filterType: values.frequency?.filterType,
                      startValue: String(values.frequency?.startValue),
                      endValue: String(values.frequency?.endValue || 0),
                    }}
                    onChange={(value) => onChangeFrequencyHandle(value)}
                    onClear={() =>
                      setValues((prev) => ({ ...prev, frequency: undefined }))
                    }
                  />
                </InputContainer>
                <InputContainer row>
                  <InputContainer>
                    <Autocomplete
                      options={fidelityPlanIds ?? []}
                      onChange={(_, value) => onChangeFidelityLevel(value)}
                      value={values.fidelityLevel}
                      getOptionLabel={(option) => option.name}
                      disabled={!checkPermission(FeatureTypeEnum.CRM)}
                      renderInput={(params) => (
                        <InputCRM
                          label={"Nível de fidelidade"}
                          placeholder="Selecione o nível"
                          premium
                          showPremiumCrown={false}
                          {...params}
                        />
                      )}
                    />
                  </InputContainer>
                </InputContainer>
                <InputContainer row>
                  <SelectOperatorCRMFilter
                    label={"Pontuação no programa de fidelidade"}
                    startLabel="Digite a pontuação"
                    premium
                    showPremiumCrown={false}
                    values={{
                      filterType: values.points?.filterType,
                      startValue: String(values.points?.startValue),
                      endValue: String(values.points?.endValue),
                    }}
                    type={"number"}
                    onChange={(value: ICrmFormOperatorValue) => {
                      onChangePointsHandle(value);
                    }}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        points: undefined,
                      }))
                    }
                  />
                  <SelectOperatorCRMFilter
                    label={"Moedas no programa de fidelidade"}
                    startLabel="Digite a quantidade de moedas"
                    premium
                    showPremiumCrown={false}
                    values={{
                      filterType: values.coins?.filterType,
                      startValue: String(values.coins?.startValue),
                      endValue: String(values.coins?.endValue),
                    }}
                    type={"number"}
                    onChange={(value: ICrmFormOperatorValue) => {
                      onChangeCoinsHandle(value);
                    }}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        coins: undefined,
                      }))
                    }
                  />
                </InputContainer>
                <InputContainer row>
                  <SelectOperatorCRMFilter
                    label={"Ticket médio - Consumo"}
                    startLabel="Digite o ticket mínimo"
                    endLabel="Digite o ticket máximo"
                    premium
                    showPremiumCrown={false}
                    moneyMask
                    values={{
                      filterType: values.averageTicketConsuption?.filterType,
                      startValue: getOperatorCRMFilterValue(
                        values.averageTicketConsuption?.startValue
                      ),
                      endValue: getOperatorCRMFilterValue(
                        values.averageTicketConsuption?.endValue
                      ),
                    }}
                    onChange={(value: ICrmFormOperatorValue) => {
                      onChangeAverageTicketConsumptionHandle(value);
                    }}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        averageTicketConsuption: undefined,
                      }))
                    }
                  />
                  <SelectOperatorCRMFilter
                    label={"Ticket médio - Ingressos"}
                    startLabel="Digite o ticket mínimo"
                    endLabel="Digite o ticket máximo"
                    premium
                    showPremiumCrown={false}
                    moneyMask
                    values={{
                      filterType: values.averageTicketEventTicket?.filterType,
                      startValue: getOperatorCRMFilterValue(
                        values.averageTicketEventTicket?.startValue
                      ),
                      endValue: getOperatorCRMFilterValue(
                        values.averageTicketEventTicket?.endValue
                      ),
                    }}
                    onChange={(value: ICrmFormOperatorValue) => {
                      onChangeAverageTicketEventTicketHandle(value);
                    }}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        averageTicketEventTicket: undefined,
                      }))
                    }
                  />
                </InputContainer>
                <InputContainer row>
                  <SelectOperatorCRMFilter
                    label="Valor Total - Consumo"
                    startLabel="Digite o valor mínimo"
                    endLabel="Digite o valor máximo"
                    premium
                    showPremiumCrown={false}
                    moneyMask
                    values={{
                      filterType: values.totalConsuptionValue?.filterType,
                      startValue: getOperatorCRMFilterValue(
                        values.totalConsuptionValue?.startValue
                      ),
                      endValue: getOperatorCRMFilterValue(
                        values.totalConsuptionValue?.endValue
                      ),
                    }}
                    onChange={onChangeOnChangeTotalConsuptionValueHandle}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        totalConsuptionValue: undefined,
                      }))
                    }
                  />
                  <SelectOperatorCRMFilter
                    label="Valor Total - Ingresso"
                    startLabel="Digite o valor mínimo"
                    endLabel="Digite o valor máximo"
                    premium
                    showPremiumCrown={false}
                    moneyMask
                    values={{
                      filterType: values.totalPurchasedTicketsValue?.filterType,
                      startValue: getOperatorCRMFilterValue(
                        values.totalPurchasedTicketsValue?.startValue
                      ),
                      endValue: getOperatorCRMFilterValue(
                        values.totalPurchasedTicketsValue?.endValue
                      ),
                    }}
                    onChange={onChangeOnChangeTotalPurchasedTicketsValueHandle}
                    onClear={() =>
                      setValues((prev) => ({
                        ...prev,
                        totalPurchasedTicketsValue: undefined,
                      }))
                    }
                  />
                </InputContainer>
                <InputContainer row>
                  <InputContainer>
                    <Autocomplete
                      options={eventCategories}
                      onChange={(_, value) => onChangeEventCategory(value)}
                      value={values.eventCategories}
                      multiple
                      getOptionLabel={(option) => option.name}
                      disabled={!checkPermission(FeatureTypeEnum.CRM)}
                      renderInput={(params) => (
                        <InputCRM
                          label={"Categoria do evento"}
                          placeholder="Selecione a categoria"
                          premium
                          showPremiumCrown={false}
                          {...params}
                        />
                      )}
                    />
                  </InputContainer>
                  <InputContainer
                    style={{ position: "relative" }}
                    className={styles.inputContainer}
                  >
                    <DateRangePicker
                      label={"Período do evento"}
                      placeholder={"Data inicio e data fim"}
                      onChange={onChangeEventPeriodHandle}
                      premium
                      showPremiumCrown={false}
                      selectRange
                      value={
                        values.eventPeriod?.startValue &&
                          values.eventPeriod?.endValue
                          ? `${values.eventPeriod.startValue.toLocaleDateString(
                            "pt-BR"
                          )} a ${values.eventPeriod.endValue.toLocaleDateString(
                            "pt-BR"
                          )}`
                          : ""
                      }
                      valueCalendar={[
                        values.eventPeriod?.startValue || null,
                        values.eventPeriod?.endValue || null,
                      ]}
                    />
                    {!!values.eventPeriod && (
                      <button
                        className={styles.singleFieldClearButton}
                        onClick={() =>
                          setValues((prev) => ({
                            ...prev,
                            eventPeriod: undefined,
                          }))
                        }
                      >
                        <Icon fontSize="small" className={styles.clearIcon}>
                          close
                        </Icon>
                      </button>
                    )}
                  </InputContainer>
                </InputContainer>
                <InputContainer row>
                  <div className={styles.checkboxContainer}>
                    <div>
                      <Checkbox
                        checked={values.hasMeepApp || false}
                        onChange={(_, value) => onChangeHaveApp(value)}
                        disabled={!checkPermission(FeatureTypeEnum.CRM)}
                      />
                      <p style={{ color: !checkPermission(FeatureTypeEnum.CRM) ? '#A2A2A2' : '#5F5F5F' }}>Possui app Meep?</p>
                    </div>

                    <div>
                      <Checkbox
                        checked={values.hasPhoneNumber || false}
                        onChange={(_, value) => onChangeHasPhoneNumber(value)}
                        disabled={!checkPermission(FeatureTypeEnum.CRM)}
                      />
                      <p style={{ color: !checkPermission(FeatureTypeEnum.CRM) ? '#A2A2A2' : '#5F5F5F' }}>Possui Celular?</p>
                    </div>

                    <div>
                      <Checkbox
                        checked={values.hasEmail || false}
                        onChange={(_, value) => onChangeHasEmail(value)}
                        disabled={!checkPermission(FeatureTypeEnum.CRM)}
                      />
                      <p style={{ color: !checkPermission(FeatureTypeEnum.CRM) ? '#A2A2A2' : '#5F5F5F' }}>Possui E-mail?</p>
                    </div>
                  </div>
                  <InputContainer row>
                    <div className={styles.npsContainer}>
                      <div
                        style={{
                          cursor: checkPermission(FeatureTypeEnum.CRM) ? 'pointer' : 'default',
                          borderColor:
                            values.surveyHealth?.endValue === 32
                              ? "#000"
                              : "#dfdfdf",
                        }}
                        onClick={() =>
                          values.surveyHealth?.endValue === 32
                            ? onChangeSurveyHealthHandle({})
                            : onChangeSurveyHealthHandle({
                              filterType: FilterOperatorType.BETWEEN,
                              startValue: 0,
                              endValue: 32,
                            })
                        }
                      >
                        {getHealthIcon(1, checkPermission(FeatureTypeEnum.CRM))}Ruim
                      </div>
                      <div
                        style={{
                          cursor: checkPermission(FeatureTypeEnum.CRM) ? 'pointer' : 'default',
                          borderColor:
                            values.surveyHealth?.endValue === 65
                              ? "#000"
                              : "#dfdfdf",
                        }}
                        onClick={() =>
                          values.surveyHealth?.endValue === 65
                            ? onChangeSurveyHealthHandle({})
                            : onChangeSurveyHealthHandle({
                              filterType: FilterOperatorType.BETWEEN,
                              startValue: 33,
                              endValue: 65,
                            })
                        }
                      >
                        {getHealthIcon(50, checkPermission(FeatureTypeEnum.CRM))}Média
                      </div>
                      <div
                        style={{
                          cursor: checkPermission(FeatureTypeEnum.CRM) ? 'pointer' : 'default',
                          borderColor:
                            values.surveyHealth?.endValue === 100
                              ? "#000"
                              : "#dfdfdf",
                        }}
                        onClick={() =>
                          values.surveyHealth?.endValue === 100
                            ? onChangeSurveyHealthHandle({})
                            : onChangeSurveyHealthHandle({
                              filterType: FilterOperatorType.BETWEEN,
                              startValue: 66,
                              endValue: 100,
                            })
                        }
                      >
                        {getHealthIcon(100, checkPermission(FeatureTypeEnum.CRM))}Boa
                      </div>
                    </div>
                  </InputContainer>
                  <Button
                    onClick={handleClearFilter}
                    className={styles.clearButton}
                    variant="text"
                    style={{ marginTop: 5 }}
                  >
                    Limpar
                  </Button>
                  <Button
                    onClick={() => setOpenSaveFilter(true)}
                    className={styles.button}
                    variant="outlined"
                    disabled={!checkPermission(FeatureTypeEnum.CRM)}
                  >
                    Salvar filtro
                  </Button>
                  <Button
                    className={styles.searchButtonFilter}
                    variant={"contained"}
                    type={"submit"}
                  >
                    Buscar
                  </Button>
                </InputContainer>
              </>
            )}
          </InputContainer>
        </form>
      </div>
      <Drawer
        anchor="right"
        open={openSaveFilter}
        onClose={() => setOpenSaveFilter(false)}
      >
        <form id={styles.drawerSaveFilter} onSubmit={onSaveFilterHandle}>
          <div className={styles.headerDrawer}>
            <h1 className={styles.saveFilterButton}>Salvar filtro <b>favorito</b></h1>
            <IconButton onClick={() => setOpenSaveFilter(false)}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div>
            <h2 className={styles.helpTitle}>Como funciona ?</h2>
            <p className={styles.helperText}>
              Os campos preenchidos anteriormente serão salvos para que, na
              próxima vez, seja muito mais fácil filtrar.
            </p>
          </div>
          <div className={styles.contentNameFilter}>
            <h3 className={styles.whatsNameFilter}>
              Qual o nome do seu filtro personalizado?
            </h3>
            <label>Nome</label>
            <TextField
              size="small"
              variant="outlined"
              className={styles.textInput}
              placeholder="Digite o nome do filtro favorito"
              sx={{ width: "100%" }}
              onChange={(e) => setNewFilterName(e.target.value)}
            />
          </div>
          <div className={styles.contentSaveFilterButton}>
            <Button variant="outlined" className={styles.buttonCancel}>
              Cancelar
            </Button>
            <Button
              disabled={!newFilterName}
              variant={"contained"}
              className={styles.searchButton}
              type={"submit"}
            >
              {isLoading ? <CircularProgress size={16} color="inherit" /> : "Salvar"}
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  );
};
export default CrmFilterForm;
