import { ChangeEvent, useCallback, useState } from "react";
import ComplienceService from "services/api/complience/ComplienceService";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { validate } from "../forms/tidValidation/TIDValidationValidation";
import {
  ITIDValidation,
  ITIDValidationErrors,
} from "../../interfaces/ITIDValidation";
import ValidateTIDsUseCase from "modules/aprovacaoTIDs/application/useCases/ValidateTIDsUseCase";
import { SideSheetType } from "./AprovacaoTIDsSideSheet";
import ApproveTIDsSolicitationUseCase from "modules/aprovacaoTIDs/application/useCases/ApproveTIDsSolicitationUseCase";
import RejectTIDsSolicitationUseCase from "modules/aprovacaoTIDs/application/useCases/RejectTIDsSolicitationUseCase";

export const UseAprovacaoTIDsSideSheet = (getTIDsList: () => Promise<void>) => {
  const { toast } = useUi();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState<SideSheetType>("APPROVE");

  const [values, setValues] = useState<ITIDValidation>({
    AdditionalTidsId: "",
    DeviceSerialNumber: "",
  });

  const [errors, setErrors] = useState<ITIDValidationErrors>({
    DeviceSerialNumber: "",
  });

  const handleChangeForm = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = ev.target.name;
      const value = ev.target.value;
      setValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const openSideSheet = useCallback(
    async (tidId: string, type: SideSheetType) => {
      setType(type);
      setValues((prev) => ({ ...prev, AdditionalTidsId: tidId }));
      setIsOpen(true);
    },
    []
  );

  const onClose = useCallback(() => {
    setValues({
      AdditionalTidsId: "",
      DeviceSerialNumber: "",
    });
    setErrors({
      DeviceSerialNumber: "",
    });
    setIsOpen(false);
  }, []);

  const handleSave = useCallback(async () => {
    const service = ComplienceService();
    setIsLoading(true);
    switch (type) {
      case "VALIDATE":
        if (validate(values, setErrors)) {
          try {
            await ValidateTIDsUseCase(service, values);
            toast("Validação TIDs concluída com sucesso", "success");
            getTIDsList();
            onClose();
          } finally {
            setIsLoading(false);
          }
        }
        break;
      case "APPROVE":
        try {
          await ApproveTIDsSolicitationUseCase(
            service,
            values.AdditionalTidsId
          );
          toast("Aprovação de TIDs concluída com sucesso", "success");
          getTIDsList();
          onClose();
        } finally {
          setIsLoading(false);
        }
        break;
      case "REJECT":
        try {
          await RejectTIDsSolicitationUseCase(
            service,
            values.AdditionalTidsId
          );
          toast("Aprovação de TIDs rejeitada com sucesso", "success");
          getTIDsList();
          onClose();
        } finally {
          setIsLoading(false);
        }
        break;
    }
  }, [getTIDsList, onClose, toast, type, values]);

  return {
    isOpen,
    openSideSheet,
    onClose,
    isLoading,
    handleSave,
    values,
    handleChangeForm,
    errors,
    type,
  };
};
