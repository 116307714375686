import { ISelect } from "modules/schedule/domain/models/IDropDown";
import { IPlaceFormErros } from "../interface/IPlaceFormErros";
import { IPlaceItemScheduleForm } from "../interface/IPlaceItemScheduleForm";

const MAX_CHARACTERS_PER_WORD = 25;

export const validate = (formData: IPlaceItemScheduleForm, setErrors: React.Dispatch<React.SetStateAction<IPlaceFormErros>>, selectedServices: ISelect[]) => {
  let _errors: IPlaceFormErros = {};
  let hasError = false;
  if (!formData.name) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }else{
    var nameSplit = formData.name.split(" ");
    var guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    for(const element of nameSplit){
      if (guidPattern.test(element) === false) {
        if(element.length > MAX_CHARACTERS_PER_WORD){
          _errors = { ..._errors, name: "Campo não deve conter palavras com mais de "+MAX_CHARACTERS_PER_WORD+" caracteres" };
          hasError = true;
        }
      }
    }
  }
  if (!formData.maxQuantity) {
    _errors = { ..._errors, maxQuantity: "Campo obrigatório" };
    hasError = true;
  }else if (formData.maxQuantity <= 0) {
    _errors = { ..._errors, maxQuantity: "A quantidade deve ser maior que zero" };
    hasError = true;
  }
  // if (!minimumLeadTime) {
  //   _errors = { ..._errors, minimumLeadTime: "Campo obrigatório" };
  //   hasError = true;
  // }
  if (!formData.maximumLeadTimeInDays) {
    _errors = { ..._errors, maximumLeadTimeInDays: "Campo obrigatório" };
    hasError = true;
  }
  if (formData.availableDays.length < 1) {
    _errors = { ..._errors, availableDays: "Campo obrigatório" };
    hasError = true;
  }
  if (selectedServices.length < 1) {
    _errors = { ..._errors, selectedServices: "Campo obrigatório" };
    hasError = true;
  } 
  setErrors(_errors);
  return !hasError;
};
