import { IPostCashCancellationResponse } from "modules/cashCancellation/domain/dto/IPostCashCancellationResponse";
import { api } from "../Api";
import { IPostCashCancellationParams } from "modules/cashCancellation/domain/dto/IPostCashCancellationParams";

export const CashCancellationApi = (): any => {

  const postCancellation = async (body: IPostCashCancellationParams): Promise<IPostCashCancellationResponse> => {
    const result = await api.post(`/Relatorio/Cancelamentos`, body);
    return result.data;
  }; 

  return {
    postCancellation
  };
};
