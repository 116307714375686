import { CSSProperties, useCallback, useEffect, useState } from "react";
import styles from "./Timer.module.scss";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface ITimerProps {
    initialTime: number;
    handleNewCode: () => Promise<void>;
    style?: CSSProperties;
    btnColor?: string;
  }
  
  const Timer = ({ initialTime, handleNewCode, style, btnColor }: ITimerProps) => {
    const { toast } = useUi();

    const [time, setTime] = useState(initialTime);
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
      let intervalId: NodeJS.Timeout;
  
      if (time > 0) {
        intervalId = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);
      }
  
      return () => {
        clearInterval(intervalId);
      };
    }, [time]);

    const handleNewCodeAndResetTimer = useCallback(async () => {
      try {
        setIsLoading(true);
        await handleNewCode();
        setTime(initialTime);
      } catch (error) {
        toast('Ocorreu um erro ao validar o código. Tente novamente.', 'error');
      } finally {
        setIsLoading(false);
      }
    }, [handleNewCode, initialTime, toast]);
  
    const formatTime = (seconds: number): string => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
  
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  
      return `${formattedMinutes}:${formattedSeconds}`;
    };
  
    return (
      <div id={styles.Timer} style={style}>
        {time > 0 
          ? <span>Seu código expira em: {formatTime(time)}</span>
          : <button onClick={handleNewCodeAndResetTimer} disabled={isLoading} style={{ color: btnColor }}>Reenviar código</button>
        }
      </div>
    );
  };
  
  export default Timer;