import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import GetCloseEventDetailsUseCase from "modules/events/application/useCases/events/GetCloseEventDetailsUseCase";
import { EventsApi } from "services/api/events/events/EventsApi";
import { ICloseEvent } from "./ICloseEvent";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { useLocal } from "modules/local/presentation/context/LocalContext";

const eventsService = EventsApi();

const UseCloseEventPage = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<ICloseEvent>();
  const { currentLocal } = useLocal();

  const [isExport, setIsExport] = useState(false);
  const [showResume, setShowResume] = useState(true);
  const [showProductConsumption, setShowProductConsumption] = useState(true);
  const [showTickets, setShowTickets] = useState(true);
  const [showSystemType, setShowSystemType] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  const { showLoading, hideLoading } = useUi();

  const [breakSystemType, setBreakSystemType] = useState<
    "last" | "second" | null
  >(null);
  const [breakTickets, setBreakTickets] = useState(false);
  const [breakDetails, setBreakDetails] = useState(false);

  const { updateRouters } = useBreadcumbs()

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Evento/Caixa', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.GERENCIA, title: event?.eventName ?? "Gerenciar", label: event?.eventName ? "Gerenciar" : '', url: `/private/event/eventos/gerenciar/${eventId}` },
      { id: BreadcrumbsEnum.FECHAMENTO, title: "Fechamento", url: `/private/event/eventos/fechamento/${eventId}` },
    ])
  }, [event, eventId, updateRouters])

  useEffect(() => {
    if (
      showResume &&
      showTickets &&
      showSystemType &&
      showDetails &&
      showProductConsumption &&
      isExport
    ) {
      setBreakDetails(true);
    } else if (
      !showResume &&
      !showTickets &&
      showSystemType &&
      showDetails &&
      showProductConsumption &&
      isExport
    ) {
      setBreakDetails(true);
    } else {
      setBreakDetails(false);
    }

    if (
      !showResume &&
      showTickets &&
      showSystemType &&
      showProductConsumption &&
      isExport
    ) {
      setBreakSystemType("second");
    } else if (showResume && !showTickets && isExport) {
      setBreakSystemType("last");
    } else {
      setBreakSystemType(null);
    }

    if (showResume && showTickets && showProductConsumption && isExport) {
      setBreakTickets(true);
    } else {
      setBreakTickets(false);
    }
  }, [
    isExport,
    showDetails,
    showProductConsumption,
    showResume,
    showSystemType,
    showTickets,
  ]);

  const { toast } = useUi();

  const handleIsExport = (isExport: boolean) => {
    setIsExport(isExport);
  };

  const handleShowResume = (showResume: boolean) => {
    setShowResume(showResume);
    setShowProductConsumption(showResume);
  };

  const handleShowTickets = (showTickets: boolean) => {
    setShowTickets(showTickets);
  };

  const handleShowSystemType = (showSystemType: boolean) => {
    setShowSystemType(showSystemType);
  };

  const handleShowDetails = (showDetails: boolean) => {
    setShowDetails(showDetails);
  };

  const handleShowProductConsumption = (showProductConsumption: boolean) => {
    setShowProductConsumption(showProductConsumption);
  };

  const getEvent = useCallback(async () => {
    if (!currentLocal) return;

    try {
      showLoading()
      const result = await GetCloseEventDetailsUseCase(eventsService, eventId, currentLocal.id);
      setEvent(result);
    } catch (error: any) {
      toast(error?.response?.data, "error");
    }
    finally {
      hideLoading();
    }
  }, [currentLocal, eventId, hideLoading, showLoading, toast]);

  const renderPercentage = (amount: number, total: number) => {
    let percent = (amount / total) * 100;
    if (!percent) return "0";
    else return percent.toFixed(2);
  };

  useEffect(() => {
    getEvent().then();
  }, [getEvent]);

  return {
    event,
    isExport,
    showResume,
    showTickets,
    showSystemType,
    showDetails,
    showProductConsumption,
    handleIsExport,
    handleShowResume,
    handleShowTickets,
    handleShowSystemType,
    handleShowDetails,
    handleShowProductConsumption,
    breakSystemType,
    breakTickets,
    breakDetails,
    getEvent,
    renderPercentage,
  };
};

export default UseCloseEventPage;
