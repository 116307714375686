import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PrintQueueApi from "services/api/printQueue/PrintQueueApi";
import { IOperatorList } from "../interfaces/IOperatorList";
import GetOperatorListUseCase from "modules/printQueue/application/useCases/GetOperatorListUseCase";


const UseOperatorList = () => {

  const [isLoadingOperator, setIsLoadingOperator] = useState<boolean>();
  const [operatorList, setOperatorList] = useState<IOperatorList[]>([])
  const { currentLocal } = useLocal();

  const getOperatorList = useCallback(
    async () => {
      try {
        const service = PrintQueueApi();
        setIsLoadingOperator(true);
        if (currentLocal) {
          const response = await GetOperatorListUseCase(service,
            currentLocal.id
          );

          setOperatorList(([...response]));

          return response
        }
      } finally {
        setIsLoadingOperator(false);
      }
    },
    [currentLocal]
  );


  return {
    operatorList,
    isLoadingOperator,
    getOperatorList,
    setOperatorList,
  };
};

export default UseOperatorList;
