import { ICustomField } from 'modules/schedule/domain/models/ICustomField';
import React, { ChangeEvent, FormEvent, useCallback } from 'react'


export const UseCustomFieldCard = (values: ICustomField[], setValues: React.Dispatch<React.SetStateAction<ICustomField[]>>, onSubmit: (customer: ICustomField[]) => void,) => {


    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [values])

    const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
      };


    return (
        {
            submitHandle,
            changeHandle,
            handleChangeChecked
        }
    )
}
