import { ISort } from "interfaces/ISort";
import { IGetPaymentLinkListResponse } from "modules/paymentLink/domain/dto/IGetPaymentLinkListResponse";
import { IGetPaymentLinkParams } from "modules/paymentLink/domain/dto/IGetPaymentLinkParams";
import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";
import { ILinkFilter } from "modules/paymentLink/presentation/interfaces/ILinkFilter";
import { IPaymentLInkList } from "modules/paymentLink/presentation/interfaces/IPaymentLInkList";


const GetPaymentLinkUseCase = async (
    service: IPaymentLinkService,
    localId: string,
    sort?: ISort,
    pagination?: { page?: number, pageSize?: number },
    filter?: ILinkFilter | null
) => {
    const params: IGetPaymentLinkParams = {
        localId: localId,
        name: filter?.name,
        value: filter?.price,
        expiration: filter?.expiration,
        page: pagination?.page,
        pageSize: pagination?.pageSize,
    }

    const response = await service.getPaymentLinkList(params, sort);
    return convertResponse(response);
}

export default GetPaymentLinkUseCase;

const convertResponse = (res: IGetPaymentLinkListResponse): IPaymentLInkList => {
  const converted: IPaymentLInkList = {
    ...res,
    records: res.records.map(it => ({...it, price: it.value}))
  }

  return converted;
}
