import React, { FormEvent, useCallback, useState } from 'react';
import styles from './PrintQueueFilter.module.scss';
import Button from 'components/ui/Button/Button';
import { IPrintQueueFilter } from './interfaces/IPrintQueueFilter';
import { Autocomplete, Icon, TextField } from '@mui/material';
import { IGetEquipments } from '../../interfaces/IGetEquipments';
import { IPrinterItem } from '../../interfaces/IPrinterItem';
import { PrintingStatus } from '../../interfaces/IPrintQueueList';
import StatusFilter from './components/statusFilter/StatusFilter';
import { ITableList } from '../../interfaces/ITableList';
import { IOperatorList } from '../../interfaces/IOperatorList';

export interface IPermutationAccountFilterProps {
  onSubmit: (filter: IPrintQueueFilter) => void;
  equipamentPrintQueueList: IGetEquipments[] | undefined;
  printerList: IPrinterItem[] | undefined;
  tableList: ITableList[];
  operatorList: IOperatorList[]
}

export const PrintQueueFilter: React.FC<IPermutationAccountFilterProps> = ({ onSubmit, operatorList, tableList, equipamentPrintQueueList, printerList }) => {
  const [values, setValues] = useState<Omit<IPrintQueueFilter, 'status'>>();
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([PrintingStatus.Received]);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const onClickShowAdevancedFilterHandle = useCallback(() => {
    setShowAdvancedFilter((prev) => !prev);
  }, []);

  const handleStatusChange = useCallback((status: keyof typeof PrintingStatus) => {
    const statusValue = PrintingStatus[status];
    
    setSelectedStatuses(prevStatuses => {
      const specificStatus = [
        PrintingStatus.InQueue,
        PrintingStatus.Printed,
        PrintingStatus.Error
      ];
  
      let updatedStatus: number[] = [];
  
      if (status === 'Received') {
        updatedStatus = [PrintingStatus.Received];
      } else {
        const isStatusSelected = prevStatuses.includes(statusValue);
        if (isStatusSelected) {
          updatedStatus = prevStatuses.filter(s => s !== statusValue);
        } else {
          updatedStatus = [...prevStatuses, statusValue];
        }
  
        const areAllSelected = specificStatus.every(s => updatedStatus.includes(s));
        if (areAllSelected) {
          updatedStatus = specificStatus;
        } else {
          updatedStatus = updatedStatus.filter(s => s !== PrintingStatus.Received);
        }
      }
  
      return updatedStatus;
    });
  }, []);  
  
  const changeHandleValueName = useCallback((value: string) => {
    const inputValue = Number(value);
    setValues((prev) => ({ ...prev, value: inputValue > 0 ? inputValue : undefined }));
  }, []);

  const changeHandleOrder = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, orderId: value }));
  }, []);

  const changeHandleCustomer = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, customer: value }));
  }, []);

  const handleAutocompleteDeviceChange = useCallback((_: any, value: IGetEquipments | null) => {
    setValues(prev => ({ ...prev, deviceId: value?.id }));
  }, []);

  const handleAutocompleteOperatorChange = useCallback((_: any, value: IOperatorList | null) => {
    setValues(prev => ({ ...prev, operatorId: value?.id }));
  }, []);

  const handleAutocompleteTableChange = useCallback((_: any, value: ITableList | null) => {
    setValues(prev => ({ ...prev, tableId: value?.id }));
  }, []);

  const handleAutocompletePrinterChange = useCallback((_: any, value: IPrinterItem | null) => {
    setValues(prev => ({ ...prev, printer: value?.name }));
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const filter: IPrintQueueFilter = { 
        ...values, 
        status: selectedStatuses.includes(PrintingStatus.Received) ? undefined : selectedStatuses
      };
      onSubmit(filter);
    },
    [onSubmit, values, selectedStatuses]
  );

  return (
    <div id={styles.PrintQueueFilter}>
      <form onSubmit={submitHandle}>
        <div className={styles.containerFilter}>
          <div className={styles.itemInfo}>
            <label>Status</label>
            <StatusFilter selectedStatuses={selectedStatuses} onStatusChange={handleStatusChange} />
          </div>
          <div className={styles.content}>
            <div className={styles.itemInfo}>
              <label>Impressora</label>
              <Autocomplete
                options={printerList ?? []}
                getOptionLabel={option => option?.name || ''}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                    <TextField {...params} placeholder="Selecione a impressora" />
                )}
                onChange={handleAutocompletePrinterChange}
              />
            </div>
            <div className={styles.itemInfo}>
              <label>Equipamentos</label>
              <Autocomplete
                options={equipamentPrintQueueList ?? []}
                getOptionLabel={option => option?.nome || ''}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                    <TextField {...params} placeholder="Selecione o equipamento" />
                )}
                onChange={handleAutocompleteDeviceChange}
              />
            </div>
            <div className={styles.itemInfo}>
              <label>Valor</label>
              <TextField fullWidth placeholder='Insira o valor' type='number' inputProps={{step: .01}} onChange={(ev) => changeHandleValueName(ev.target.value)} />
            </div>
          </div>
          <div className={styles.footer}>
            {!showAdvancedFilter && (
              <Button
                variant={"contained"}
                className={
                  styles.button
                }
                fullWidth
                style={{ height: 40, borderRadius: 8, width: 110 }}
                type="submit"
              >
                Buscar
              </Button>
            )}
          </div>
        </div>
        <div className={styles.advancedFilterButton}>
          <div>
            <Button
              className={styles.buttonFilterAdvanced}
              onClick={onClickShowAdevancedFilterHandle}
              variant='text'
              >
              Filtros avançados
              {!showAdvancedFilter ? <Icon>sort</Icon> : <Icon>close</Icon>}
            </Button>
          </div>
        </div>
        {showAdvancedFilter && (
          <div className={styles.advancedFilter}>
            <div className={styles.itemAdvanced}>
              <label>Comanda ou mesa</label>
              <Autocomplete
                options={tableList ?? []}
                getOptionLabel={option => option?.number || ''}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                    <TextField {...params} placeholder="Selecione a mesa  " />
                )}
                onChange={handleAutocompleteTableChange}
              />
            </div>
            <div className={styles.itemAdvanced}>
              <label>Cliente</label>
              <TextField fullWidth placeholder='Insira o cliente' onChange={(ev) => changeHandleCustomer(ev.target.value)} />
            </div>
            <div className={styles.itemAdvanced}>
              <label>Operador</label>
              <Autocomplete
                options={operatorList ?? []}
                getOptionLabel={option => option?.name || ''}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                    <TextField {...params} placeholder="Selecione o operador" />
                )}
                onChange={handleAutocompleteOperatorChange}
              />
            </div>
            <div className={styles.itemAdvanced}>
              <label>Número do pedido</label>
              <TextField fullWidth placeholder='Insira o número do pedido' type='number' onChange={(ev) => changeHandleOrder(ev.target.value)} />
            </div>
            <div className={styles.footer}>
            {showAdvancedFilter && (
              <Button
                variant={"contained"}
                className={
                  styles.button
                }
                fullWidth
                style={{ height: 40, borderRadius: 8, width: 110 }}
                type="submit"
              >
                Buscar
              </Button>
            )}
          </div>
          </div>
        )}
      </form>
    </div>
  );
};
