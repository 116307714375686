import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { IGetContractResponse } from "modules/equipamento/domain/dto/IGetContractResponse";
import GetContractsUseCase from "modules/equipamento/application/useCases/GetContractUseCase";
import EquipamentoApi from "services/api/equipamento/DeviceApi";

const service = EquipamentoApi();

export const UseGetContract = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [contractLicenses, setContractLicenses] =
    useState<IGetContractResponse[]>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getContractLicenses = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetContractsUseCase(service, currentLocal?.id);
        setContractLicenses(response);
      } catch (error) {
        toast("Ocorreu um erro. Tente de novo.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, toast]);

  useEffect(() => {
    getContractLicenses();
  }, [getContractLicenses]);

  return {
    isLoading,
    contractLicenses,
    getContractLicenses,
  };
};
