import React from "react";
import styles from "./PaymentLinkDetails.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import {
  IPaymentLinkDatailsList,
  PaymentTransactionStatusEnum,
} from "../../interfaces/IPaymentLinkDatailsList";
import Utils from "services/utils/Utils";

interface PaymentLinkDetailsProps {
  paymentLinkDetailsList: IPaymentLinkDatailsList | undefined;
  isLoading: boolean | undefined;
}

const PaymentLinkDetails: React.FC<PaymentLinkDetailsProps> = ({
  paymentLinkDetailsList,
  isLoading,
}) => {
  if (!paymentLinkDetailsList) return <>Resultado não encontrado.</>;

  const { customer, billings } = paymentLinkDetailsList;

  const getStatusLabel = (status: PaymentTransactionStatusEnum) => {
    switch (status) {
      case PaymentTransactionStatusEnum.Captured:
        return { label: "Aprovada", style: styles.statusApproved };
      case PaymentTransactionStatusEnum.Authorized:
      case PaymentTransactionStatusEnum.NotAuthorized:
      case PaymentTransactionStatusEnum.Cancelled:
      case PaymentTransactionStatusEnum.PartialVoid:
      case PaymentTransactionStatusEnum.ErrorOnVoiding:
      case PaymentTransactionStatusEnum.WithError:
      case PaymentTransactionStatusEnum.Failed:
      case PaymentTransactionStatusEnum.Pending:
      case PaymentTransactionStatusEnum.Created:
      case PaymentTransactionStatusEnum.WaitingCancellation:
      case PaymentTransactionStatusEnum.Refunded:
      case PaymentTransactionStatusEnum.RefundRequested:
      case PaymentTransactionStatusEnum.PartialRefunded:
      default:
        return { label: "Negada", style: styles.statusDenied };
    }
  };

  return (
    <div id={styles.PaymentLinkDetails}>
      <div className={styles.customerInfo}>
        <div>
          <p>{customer?.name}</p>
          <p>
            CPF: <b>{customer?.document}</b>
          </p>
        </div>
        <div>
          <p>{customer?.phone ?? ""}</p>
          <p>{customer?.email ?? ""}</p>
        </div>
        <div>
          <p>
            {customer.cardBrand ? customer.cardBrand : "Final cartão"} •{" "}
            <b>{customer?.cardLastDigits ?? ""}</b>
          </p>
          <p>
            Cobrança • <b> {customer?.billingDay ?? ""} </b>
          </p>
        </div>
      </div>

      <div className={styles.billingList}>
        <p>Cobranças</p>
        {billings?.map((billing, index) => {
          const statusInfo = getStatusLabel(billing.status);
          return (
            <div key={index} className={styles.billingItem}>
              <div>
                <p>Data</p>
                <b>{Utils.toDate(billing.date + "Z")}</b>
              </div>
              <div>
                <p>Valor</p>
                <p>{moneyMaskNumber(billing.amount ?? 0)}</p>
              </div>
              <div>
                <p>Status</p>
                <span className={statusInfo.style}>{statusInfo.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentLinkDetails;
