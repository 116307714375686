import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./DiscountCouponsReportItem.module.scss";
import { IDiscountCouponsReport, IReportItem } from "modules/config/discountCoupons/presentation/interfaces/IDiscountCouponsReport";
import { DiscountCouponsReportColunmsPropertyType, IDiscountCouponsReportColumnsConfig } from "../../interfaces/IColumnsConfig";
import { Button } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";
import { Drawer } from "@mui/material";
import { OrderItem } from "./components/orderItem/OrderItem";
import UseReportOrder from "modules/config/discountCoupons/presentation/hooks/UseReportOrder";

interface Props {
  columns: IDiscountCouponsReportColumnsConfig[]
  userItemData: IReportItem;
  discountCouponsReport: IDiscountCouponsReport
  // params: IParamsClientDiscountCouponsReport
}

const DiscountCouponsReportItem: React.FC<Props> = ({ userItemData, columns, discountCouponsReport }) => {

  const [openDropDown, setOpenDropDown] = useState<boolean>();
  const [sideSheetOrder, setSideSheetOrder] = useState(false)
  const { getReportOrder, reportOrder, count, recordOrder, isLoading } = UseReportOrder();

  const openSideSheetOrder = (id: string) => {
    getReportOrder({ orderId: id })
    setSideSheetOrder(true)
  }

  const closeSideSheetOrder = () => {
    setSideSheetOrder(false)
  }


  const formatValues = useCallback((property: DiscountCouponsReportColunmsPropertyType): ReactNode => {
    if (userItemData.hasOwnProperty("couponId")) {
      switch (property) {
        //formatar status  
        case 'value':
          return moneyMaskNumber(userItemData?.value)

        case 'customerCPF':
          if (userItemData?.customerCPF) {
            return ("***" + userItemData?.customerCPF)
          } else {
            return "-"
          }

        case 'couponUsedAt':
          return userItemData?.couponUsedAt ? Utils.toDateAndTime(userItemData?.couponUsedAt).substring(0, 8) : "-"

        default:
          return userItemData?.[property] ?? '-'
      }
    }
  }, [userItemData])

  const List = useMemo(() => columns.map((colunm, index) => {
    return (!colunm.hide && <div
      key={index}
      className={styles.row}
      style={colunm.style ?? { flex: 1 }}
    >
      <span>{formatValues(colunm.property)}</span>
    </div>)
  }), [columns, formatValues])

  return (
    <>
      <div id={styles.DiscountCouponsReportItem}>
        <div className={styles.containerItem}>
          <div className={styles.content}>
            {List}
            <div
              className={styles.icon}
            >
              <Button variant="text" onClick={() => openSideSheetOrder(userItemData.orderId)}><u>Ver pedido</u></Button>

              {
                openDropDown ?
                  <KeyboardArrowUp onClick={() => setOpenDropDown(prev => !prev)} />
                  :
                  <KeyboardArrowDown onClick={() => setOpenDropDown(prev => !prev)} />
              }

            </div>
          </div>
          {openDropDown &&
            <>
              <div className={styles.border}></div>
              <div
                className={styles.contentDropDown}
                style={{ flex: 1 }}
              >
                <span>Equipamento de utilização</span>
                <span>{userItemData.deviceName}</span>
              </div>
            </>
          }

        </div>
      </div>
      <Drawer open={sideSheetOrder} anchor="right" onClose={closeSideSheetOrder}>
        <div id={styles.drawer}>
          <OrderItem
            onClose={closeSideSheetOrder}
            reportOrder={reportOrder}
            count={count}
            isLoading={isLoading}
            getReportOrder={getReportOrder}
            recordOrder={recordOrder}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DiscountCouponsReportItem;

