import { GetDevicesTerminalResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse"
import { ITerminalService } from "modules/Terminal/application/interface/ITerminalService"
import { deviceConfigApi } from "../equipamento/DeviceConfigApi"
import { PatchUpdateTerminalConfigRequest } from "modules/Terminal/application/dtos/PatchUpdateTerminalConfigRequest";
import { PatchUpdateTerminalConfigResponse } from "modules/Terminal/application/dtos/PatchUpdateTerminalConfigResponse";
import { GetNavigatorSolicitationsResponse } from "modules/Terminal/application/dtos/GetNavigatorSolicitationsResponse";
import { PostApproveSolicitationResponse } from "modules/Terminal/application/dtos/PostApproveSolicitationResponse";
import { PostApproveSolicitationRequest } from "modules/Terminal/application/dtos/PostApproveSolicitationRequest";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { IGetLicensesResponse } from "modules/Terminal/application/dtos/IGetLicensesResponse";
import { api } from "../Api";
import { plansUrl } from "Enviroment";

const apiDevice = deviceConfigApi;
const baseUrl = plansUrl;

const TerminalService = (): ITerminalService => {
  //{{baseUrl}}/api/locals/{{localId}}/terminal/getAllDevicesWithTerminalProfile

  const getTerminalDevices = async (localId: string, deviceType?: DeviceType, acquirerType?: string): Promise<GetDevicesTerminalResponse> => {
    const response = await apiDevice.get<GetDevicesTerminalResponse>(`/locals/${localId}/terminal/getAllDevicesWithTerminalProfile`, {
      params: { type: deviceType, acquirerType: acquirerType }
    });
    return response.data;
  }

  const patchUpdateTerminalConfig = async (localId: string, request: PatchUpdateTerminalConfigRequest): Promise<PatchUpdateTerminalConfigResponse> => {
    const response = await apiDevice.patch<PatchUpdateTerminalConfigResponse>(`/locals/${localId}/terminal/updateTerminalConfig`, {...request, localId: localId });
    return response.data;
  }

  const getNavigatorSolicitations = async (localId: string, deviceId?: string): Promise<GetNavigatorSolicitationsResponse> => {
    const response = await apiDevice.get<GetNavigatorSolicitationsResponse>(`/locals/${localId}/terminal/navigatorSolicitations`, { params: { deviceId: deviceId } });
    return response.data;
  }

  const postApproveSolicitation = async (localId: string, request: PostApproveSolicitationRequest): Promise<PostApproveSolicitationResponse> => {
    const response = await apiDevice.post<PostApproveSolicitationResponse>(`/locals/${localId}/terminal/approve`, request);
    return response.data;
  }

  const postRefuseSolicitation = async (localId: string, request: PostApproveSolicitationRequest): Promise<PostApproveSolicitationResponse> => {
    const response = await apiDevice.post<PostApproveSolicitationResponse>(`/locals/${localId}/terminal/refuse`, request);
    return response.data;
  }

  const postRefuseAllSolicitations = async (localId: string, deviceId: string): Promise<void> => {
    const response = await apiDevice.post<void>(`/locals/${localId}/terminal/refuse/all`, { deviceId: deviceId });
    return response.data;
  }

  const postCloseSession = async (localId: string, deviceId: string): Promise<void> => {
    const response = await apiDevice.post<void>(`/locals/${localId}/terminal/closeSession`, { deviceId: deviceId });
    return response.data;
  }

  const getLicenses = async (localId: string): Promise<IGetLicensesResponse[]> => {
    const response = await api.get<IGetLicensesResponse[]>(`${baseUrl}/subscriptions/saas/devices/get-total-licenses`, { params: { ownerId: localId, disableError: true }});
    return response.data;
  }

  return {
    getTerminalDevices,
    patchUpdateTerminalConfig,
    getNavigatorSolicitations,
    postApproveSolicitation,
    postRefuseSolicitation,
    postRefuseAllSolicitations,
    postCloseSession,
    getLicenses
  }
}

export default TerminalService
