import AddPosFragment from "modules/salesPos/presentation/fragment/addPosFragment/AddPosFragment";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigCielo } from "./UseConfigCielo";
import { useMemo } from "react";
import styles from "../configCieloSmart/ConfigCieloSmart.module.scss";
import { useHistory } from "react-router-dom";
import { AddCircle, EditOutlined } from "@material-ui/icons";
import { Divider, IconButton } from "@mui/material";
import Sidesheet from "components/sidesheet/Sidesheet";
import Button from "components/ui/Button/Button";

export interface IConfigCieloProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
}

const ConfigCielo = ({ open, setOpen }: IConfigCieloProps) => {
  const history = useHistory();
  const { data, isLoading, refetch, terminalsList } = UseConfigCielo();

  const handleStatus = useMemo(() => data?.length ? "enabled" : "unavailable", [data]);

  const posList = useMemo(() => data?.map((posDevice, index) => (
    <div key={posDevice.id}className={styles.listItem}>
      <div>
        <span>{posDevice.name}</span>
        <IconButton disableTouchRipple onClick={() => history.push("/private/vendas/pos")}><EditOutlined /></IconButton>
      </div>
      {index < data.length - 1 && <Divider />}
    </div>
  )), [history, data]);
  
  return (
    <>
      <ContentBox
        status={handleStatus}
        loading={isLoading}
        type={IntegrationTypeEnum.CIELO}
        text="Use sua máquina Cielo integrada ao Terminal Online. Assim, você lança pedidos pelo Terminal e efetua a cobrança pela máquina."
        setOpen={setOpen}
      />
      {open && open === "Modo Integrado" && handleStatus === "unavailable" && (
        <AddPosFragment
          open={!!open}
          onClose={() => {
            setOpen(null);
            refetch();
          }}
          fromIntegrations="cieloSimple"
          terminalsList={terminalsList}
        />
      )}
      {open && open === "Modo Integrado" && handleStatus === "enabled" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={<h2>Integração - <b>Cielo</b></h2>}
          content={
            <div id={styles.ConfigSidesheet}>
              <Button variant="outlined" endIcon={<AddCircle />} fullWidth onClick={() => history.push("/private/vendas/pos")}>Nova máquina</Button>
              <div className={styles.info}>
                <img src="/assets/icon/saas/saas-pos-pink.svg" alt=""/>
                <p>Essas são suas máquinas Cielo que estão integradas com o PDV Mee. Você pode criar novas máquinas para lançar pedidos, acompanhar extrato e muito mais.</p>
              </div>
              {posList}
            </div>
          }
          currentStep={1}
          totalSteps={1}
          continueButton="Fechar"
          handleContinueButton={() => setOpen(null)}
        />
      )}
    </>
  );
};

export default ConfigCielo;
