import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import EditGuestListUseCase from "modules/events/application/useCases/guests/EditGuestListUseCase";
import { IEditEventListRequest } from "modules/events/domain/dto/IEventsRequest";
import { IBaseList } from "modules/events/domain/dto/IEventsResponse";
import { IGuestListForm } from "modules/events/presentation/components/guestListForm/interfaces/GuestListForm.types";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { IEventsGuestList } from "../../interfaces/IEventGuestsListsPage";
import DeleteGuestListUseCase from "modules/events/application/useCases/guests/DeleteGuestListUseCase";

const guestService = EventsGuestsApi();
const baseListService = DiscountBaseListApi();

const UseGuestLists = (refreshList: () => Promise<void>) => {
  const { currentLocal } = useLocal();
  const { hideLoading, showLoading, toast } = useUi();
  const { push } = useHistory();
  const [localId, setLocalId] = useState<string>();
  const [showEditListModal, setShowEditListModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [guestListToEdit, setGuestListToEdit] = useState<IGuestListForm | null>(null);
  const [editListId, setEditListId] = useState<string>();
  const [deleteListId, setDeleteListId] = useState<string>("");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [discountsList, setDiscountsList] = useState<IBaseList[]>([]);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>();

  useEffect(() => {
    if (currentLocal && currentLocal.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  useEffect(() => {
    if (!showAccessModal) {
      setEditListId("");
    }
  }, [showAccessModal]);

  useEffect(() => {
    const getDiscountsList = async () => {
      showLoading();
      try {
        if (localId) {
          const response = await GetBaseLIstUseCase(baseListService, localId);
          setDiscountsList(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoading();
      }
    };
    getDiscountsList();
  }, [hideLoading, localId, showLoading]);

  const openAccessModal = useCallback(() => {
    setShowAccessModal(true);
  }, []);

  const closeAccessModal = useCallback(() => {
    setShowAccessModal(false);
  }, []);

  const onClickGrantAccess = useCallback(
    (listId: string) => {
      setEditListId(listId);
      openAccessModal();
    },
    [openAccessModal]
  );

  const openEditListModal = useCallback(() => {
    setShowEditListModal(true);
  }, []);

  const closeEditListModal = useCallback(() => {
    setShowEditListModal(false);
  }, []);


  const onClickEditGuestList = useCallback((eventList: IEventsGuestList) => {
    setGuestListToEdit(eventList);
    openEditListModal();
  },
    [openEditListModal]
  );

  const openDeleteListModal = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
    setDeleteListId("");
  }, []);

  const onClickDeleteGuestList = useCallback((listId: string) => {
    setDeleteListId(listId);
    openDeleteListModal();

  }, [openDeleteListModal]);

  const onClickCancelEditGuestList = useCallback(() => {
    closeEditListModal();
    setGuestListToEdit(null);
  }, [closeEditListModal]);

  const deleteGuestList = useCallback(
    async (listId: string) => {
      try {
        setIsLoadingDelete(true);
        if (currentLocal) {
          const response = await DeleteGuestListUseCase(guestService,
            listId,
            currentLocal.id,
          );
          refreshList();
          toast("Lista deletada com sucesso", "success")
          closeDeleteModal();
          return response
        }
      } finally {
        setIsLoadingDelete(false);
      }
    },
    [closeDeleteModal, refreshList, currentLocal, toast]
  );

  const submitEditGuestList = useCallback(async (guestListForm: IGuestListForm) => {
    if (guestListToEdit && guestListToEdit?.id && localId) {  
      showLoading();
      try {
        const guestListRequest: IEditEventListRequest = {
          closingDateTime: new Date(guestListForm.closingDateTime).toISOString(),
          description: guestListForm.description,
          isActive: guestListForm.isActive,
          name: guestListForm.name,
          baseListId: guestListForm?.baseList?.id,
          discountLimit: guestListForm.discountLimit,
          mainPromoterId: guestListForm.mainPromoter.id,
          promotersIdsWithAccess: guestListForm.promotersWithAccess?.map(it => it.id),
          useDiscountLimit: !!guestListForm.useDiscountLimit,
          copyConfiguration: guestListForm.copyConfiguration,
          copyGuests: guestListForm.copyGuests,
          validUntil: guestListForm.validUntil,
          shareEnabled: guestListForm.shareEnabled,
          shareDescription: guestListForm.shareDescription,
        }
        await EditGuestListUseCase(guestService, guestListToEdit.id, guestListRequest, localId);
        setGuestListToEdit(null);
        closeEditListModal();
        toast("Lista editada com sucesso!", "success");
        refreshList();
      } catch (error) {
        console.error(error);
        // toast("Falha ao editar lista!", "error");
      } finally {
        hideLoading();
      }
    }
  },
    [closeEditListModal, guestListToEdit, hideLoading, localId, refreshList, showLoading, toast]
  );

  const onClickGoToGuestList = useCallback(
    (guestListId: string) => {
      push(`/private/event/eventos/listas/convidados/${guestListId}`);
    },
    [push]
  );


  return {
    discountsList,
    editListId,
    guestListToEdit,
    onClickCancelEditGuestList,
    onClickEditGuestList,
    onClickGoToGuestList,
    onClickGrantAccess,
    onClickDeleteGuestList,
    showAccessModal,
    showEditListModal,
    submitEditGuestList,
    closeAccessModal,
    closeEditListModal,
    showDeleteModal,
    closeDeleteModal,
    deleteGuestList,
    deleteListId,
  };
};

export default UseGuestLists;
