import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { OnBoardingSteps } from 'modules/onBoardingTutorials/domain/enums/OnBoardingSteps';
import UseOnboardingStore from 'modules/onBoardingTutorials/store/OnBoardingStore';
import { openTutorialCard, closeTutorialCard } from 'modules/onBoardingTutorials/store/TutorialCardStore';
import React, { FC, RefObject, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import OnBoardingService from 'services/api/onBoardingTutorial/OnBoardingService';
const UseKdsPageTutorials = () => {
    const { onFinishStep, getOnBoarding, hasOnboardingStep } = UseOnboardingStore();
    const { currentLocal } = useLocal();
    const { push } = useHistory();



    const showOrderKDSFinishTutorial = useCallback(
        async (divRef: RefObject<HTMLDivElement>) => {
            if (currentLocal === undefined) return;

            const onBoardingService = OnBoardingService();
            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.FinishTutorials)


            if (hasOnBoardStep) return;


            openTutorialCard({
                element: divRef.current,
                options: {
                    title: `Pronto! Você está preparado para usar!`,
                    description: <div>Ficou com dúvidas? Para mais informações, <a>clique aqui.</a></div>,
                    arrowPosition: 'left-top',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    enabledConffeti: true,
                    action: async () => {
                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.FinishTutorials,
                        })
                        closeTutorialCard();
                    },
                    buttonLabel: 'Fechar',
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.FinishTutorials,
                        })
                        closeTutorialCard();
                    }

                }
            });
        }, [currentLocal, hasOnboardingStep, onFinishStep])


    const showOrderKDSTutorial = useCallback(
        async (divRef: RefObject<HTMLDivElement>) => {
            if (currentLocal === undefined) return;

            const onBoardingService = OnBoardingService();
            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.OrdersPlacedHere)


            if (hasOnBoardStep) return;


            openTutorialCard({
                element: divRef.current,
                options: {
                    title: `Aqui ficam seus pedidos`,
                    description: 'Acompanhe os itens pedidos e otimize a produção da sua cozinha!',
                    arrowPosition: 'left-top',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    enabledBackdrop: true,
                    action: async () => {
                        showOrderKDSFinishTutorial(divRef);
                        // closeTutorialCard();
                        onFinishStep(onBoardingService, {
                            localId: currentLocal?.id ?? '',
                            step: OnBoardingSteps.OrdersPlacedHere,
                        })
                    },
                    buttonLabel: 'Entendi',
                    onClose: () => {
                        // onFinishStep(onBoardingService, {
                        //     localId: currentLocal?.id ?? '',
                        //     step: OnBoardingSteps.OrdersPlacedHere,
                        // })
                        closeTutorialCard();
                    }

                }
            });
        }, [currentLocal, hasOnboardingStep, onFinishStep, showOrderKDSFinishTutorial])




    useEffect(() => {
        return () => {
            closeTutorialCard();
        }
    }, [])

    return ({ showOrderKDSTutorial })
}


export default UseKdsPageTutorials