import React, { FC } from 'react'
import PlaceForm from '../../components/placeScheduleForm/PlaceForm'
import styles from './AddPlaceSchedulePage.module.scss'
import UseAddPlaceSchedulePage from './UseAddPlaceSchedulePage';
export interface IAddPlaceSchedulePageProps {
    //propertys
}
const AddPlaceSchedulePage: FC<IAddPlaceSchedulePageProps> = () => {
    const { orderPads, services, onAddPlaceHandle, handleSpecialDays  } = UseAddPlaceSchedulePage();
    return (
        <div id={styles.AddPlaceSchedulePage} >
            <div className={styles.container} >
                <PlaceForm orderPadsList={orderPads} servicesList={services} onSubmit={onAddPlaceHandle} handleSpecialDays={handleSpecialDays} ></PlaceForm>
            </div>
        </div>
    )
}
export default AddPlaceSchedulePage