import UseDimension from "components/dimension/UseDimension";
import styles from "./FeedBackAddLinkCards.module.scss";
import { ReactElement } from "react";
import { IAddLinkCards } from "modules/linkCards/presentation/interfaces/IAddLinkCards";
import { IDeleteLinkCards } from "modules/linkCards/presentation/interfaces/IDeleteLinkCards";

interface IFeedBackAddLinkCardsProps {
  text: string | ReactElement;
  success?: boolean;
  helperText?: ReactElement | string;
  addLinkCards?: IAddLinkCards | undefined;  
  deleteData?: IDeleteLinkCards | undefined;
}

const FeedBackAddLinkCards = ({
  text,
  success,
  helperText,
  addLinkCards,
  deleteData
}: IFeedBackAddLinkCardsProps) => {
  const { isMobile } = UseDimension();

  // Verifica se estamos lidando com addLinkCards ou deleteData
  const hasErrors =( addLinkCards?.cardsWithError && addLinkCards?.cardsWithError.length > 0) || (deleteData?.cardsWithError.length && deleteData?.cardsWithError.length > 0);
  const errorCards = addLinkCards ? addLinkCards.cardsWithError : deleteData?.cardsWithError;
  const hasSuccess = addLinkCards ? addLinkCards.linkedCards : deleteData?.deletedCards;

  return (
    <div id={styles.FeedBackAddLinkCards}>
      <div
        className={`${styles.successContent} ${
          isMobile && styles.successContentMobile
        }`}
      >
        <img
          src={
            success
              ? "/assets/icon/check-icon-integration.svg"
              : "/assets/icon/warning-icon.svg"
          }
          alt={success ? "Sucesso" : "Aviso"}
        />
        <div className={styles.successText}>
          <span>{text}</span>
        </div>
      </div>
      {helperText && hasSuccess && hasSuccess.length > 0 && (
        <div className={styles.helperText}>
          <div><img src="/assets/icon/check.png" alt="Check"/></div>
          <span>{helperText}</span>
        </div>
      )}

      <div className={styles.separator}></div>
      {hasErrors && (
        <div className={styles.contentError}>
          <p>Os seguintes cartões <b>estão com erro:</b></p>
          <ul>
            {errorCards?.map((item, index) => (
              <li key={index}>{item.tag}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FeedBackAddLinkCards;
