import Skeleton from '@mui/material/Skeleton';
import React, { FC } from 'react';
import styles from './ManageEventSkeleton.module.scss';

interface IManageEventSkeletonProps {
};

export const ManageEventSkeleton: FC<IManageEventSkeletonProps> = ({}) => {
  return (
    <div id={styles.ManageEventSkeleton}>
      <div>
        <Skeleton height={40} width={250} />
        <div />
        <Skeleton width={300} />
        <div />
        <Skeleton width={200} />
        <Skeleton width={250} />
        <div />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <div>
        <Skeleton height={30} />
        <Skeleton variant="rectangular" />
      </div>
    </div>
  );
};