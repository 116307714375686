import styles from "components/sidesheet/Sidesheet.module.scss";
import { Dispatch, SetStateAction } from "react";
import Input from "components/ui/input/Input";

export interface IConfigContentTrackcoProps {
  step: number;
  values: IValues;
  setValues: Dispatch<SetStateAction<IValues>>;
  error: {
    organizationId: string;
    token: string;
  };
  setError: Dispatch<
    SetStateAction<{
      organizationId: string;
      token: string;
    }>
  >;
}

interface IValues {
  organizationId: string;
  token: string;
}

const ConfigContentTrackco = ({
  step,
  values,
  setValues,
  error,
  setError,
}: IConfigContentTrackcoProps) => {
  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="trackco-id" className={styles.required}>
              ID da organização
            </label>
            <Input
              name="trackco-id"
              variant={"outlined"}
              value={values?.organizationId}
              onChange={(ev) => {
                setError({ ...error, organizationId: "" });
                setValues((prev) => ({
                  ...prev,
                  organizationId: ev.target.value,
                }));
              }}
              error={!!error.organizationId}
              helperText={error.organizationId}
            />
          </div>
          <div>
            <label htmlFor="trackco-token" className={styles.required}>
              Token
            </label>
            <Input
              name="trackco-token"
              variant={"outlined"}
              value={values?.token}
              onChange={(ev) => {
                setError({ ...error, token: "" });
                setValues((prev) => ({ ...prev, token: ev.target.value }));
              }}
              error={!!error.token}
              helperText={error.token}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentTrackco;
