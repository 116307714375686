import { request } from "services/api/Api";
import { GEtSalesResponse, GetSalesProps } from "../types";

const GetSales = async (props: GetSalesProps) => {
    return await request<GEtSalesResponse>({
        url: "/Reports/Sales",
        params: {
            ...props,
        },
    });
};

export { GetSales };
