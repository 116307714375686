import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { useCallback, useState, KeyboardEvent, useEffect } from 'react'
import { v4 } from 'uuid'
import { IDescriptionForm } from '../../interfaces/IServiceForm'

const UseDescriptionForm = (values: IDescriptionForm[], onChange: (values: IDescriptionForm[]) => void) => {

    const [newValue, setNewValue] = useState<IDescriptionForm>({
        id: v4(),
        title: "",
        description: "",
    });
    const [isEdit, setIsEdit] = useState(false);


    const onChangeDescription = useCallback(
        (name: string, value: string) => {
            setNewValue(prev => ({ ...prev, [name]: value }))
        }, [])


    const onAddHandle = useCallback(
        () => {

            onChange([...values, newValue])
            setNewValue({
                id: v4(),
                title: "",
                description: "",
            })
            setIsEdit(false);

        },
        [newValue, onChange, values],
    )

    const onRemoveHandle = useCallback(
        (value: IDescriptionForm) => {
            onChange(values.filter(item => item.id !== value.id))
        },
        [onChange, values],
    )

    const onEditDescription = useCallback((id: string) => {
        const item = values.find(_item => _item.id === id);
        item?.title && item?.title && setNewValue(prev => ({ ...prev, title: item?.title, description: item?.description }));

        onChange(values.filter(item => item.id !== id));
        setIsEdit(true);
    }, [onChange, values]);


    return (
        {
            onAddHandle,
            onRemoveHandle,
            newValue,
            onChangeDescription,
            isEdit,
            setNewValue,
            onEditDescription
        }
    )
}

export default UseDescriptionForm
