import { StatusBox } from "components/ui/StatusBox/StatusBox";
import styles from "../../ImportDrawer.module.scss";

import Button from "components/ui/Button/Button";
import { FC } from "react";
import { ErrorViewProps } from "./ErrorView.types";

const ErrorView: FC<ErrorViewProps> = ({ onClose }) => {
  return (
    <>
      <div className={styles.content}>
        <StatusBox type="warning" message="Tente importar novamente!" />
      </div>
      <div className={styles.close}>
        <Button fullWidth={false} onClick={() => onClose()}>
          Fechar
        </Button>
      </div>
    </>
  );
};

export { ErrorView };
