import styles from './OnlineSale.module.scss';
import { ExpandLessRounded, InfoOutlined, LaunchRounded } from '@material-ui/icons';
import UseDimension from 'components/dimension/UseDimension';
import { UseGestaoVendas } from '../../hooks/UseGestaoVendas';
import { MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material';
import UseConfigValues from '../../hooks/UseConfigValues';
import { useEffect } from 'react';

export interface IOnlineSaleProps {
    isOnlineSolutionsVisible: boolean;
    setIsOnlineSolutionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    catalogId?: string;
    catalogUrl?: string;
}

const OnlineSale = ({ isOnlineSolutionsVisible, setIsOnlineSolutionsVisible, catalogId, catalogUrl }: IOnlineSaleProps) => {
    const { dimensions } = UseDimension();
    const { onConfigChangeHandle, getConfigData, getCatalogList, catalogList, dataConfig } = UseGestaoVendas(true);
    const { configValues } = UseConfigValues();

    useEffect(() => {
        getConfigData();
        getCatalogList();
    }, [getCatalogList, getConfigData]);

    const handleOpenCatalog = () => {
        if (catalogUrl && catalogId)
            window.open(`https://mepay.meep.cloud/${catalogUrl}?listId=${catalogId}`, '_blank')?.focus();
    }


    return (
        <div id={styles.OnlineSale}>
            <div className={styles.header}>
                <div>
                    <h1>Venda online</h1>
                    <button className={styles.headerClick} onClick={handleOpenCatalog}>
                        <LaunchRounded style={{ color: "rgba(149, 92, 255, 1)" }}/>
                    </button>
                </div> 
                <div
                    onClick={() => setIsOnlineSolutionsVisible(!isOnlineSolutionsVisible)}
                    style={{
                        transform: isOnlineSolutionsVisible ? "rotate(0deg)" : "rotate(180deg)",
                    }}
                >
                    <ExpandLessRounded style={{ width: "35px", height: "35px" }} />
                </div>
            </div>

            {isOnlineSolutionsVisible && (
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span className={styles.text}>Permitir consumir online</span>
                        </div>
                        <Switch
                            checked={configValues.enableShoppingCartOnVirtualMenu}
                            name="enableShoppingCartOnVirtualMenu"
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente adicione itens no carrinho de compra.</p>
                    <div className={styles.row}>
                        <div className={styles.text}>
                            <span className={styles.text}>Habilitar pagamento online</span>
                            {!dataConfig?.allowOnlinePayment && (
                                <Tooltip title="Solicite pagamento online.">
                                    <InfoOutlined style={{ color: "rgba(51, 51, 51, 1)" }} />
                                </Tooltip>
                            )}
                        </div>
                        <Switch
                            checked={configValues.allowOnlinePaymentWeb}
                            name="allowOnlinePaymentWeb"
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                            disabled={!dataConfig?.allowOnlinePayment}
                        />
                    </div>
                    <p className={styles.hint}>Permite que o cliente use a forma de pagamento crédito para pagar pelos produtos.</p>
                    <div className={styles.column} style={dimensions.width < 750 ? { width: "100%" } : { width: "50%" }}>
                        <label className={styles.required}>
                            Cardápio
                        </label>
                        <Select
                            variant='outlined'
                            style={{ margin: 0 }}
                            size="small"
                            placeholder="Selecione um cardápio"
                            name="catalogWebId"
                            onChange={(ev) => {
                                if (ev.target.name) {
                                    const newValue = ev.target.value as string;
                                    onConfigChangeHandle(ev.target.name, newValue);
                                }
                            }}
                            value={configValues?.catalogWebId ?? null}
                        >
                            {catalogList.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.text}>Habilitar taxa de serviço</span>
                        <Switch
                            checked={configValues.enableServiceTax}
                            name="enableServiceTax"
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                        />
                    </div>
                    <p className={styles.hint}>Porcentagem paga pelos clientes sob o valor do pedido.</p>
                    <div className={styles.column} style={dimensions.width < 750 ? { width: "100%" } : { width: "50%" }}>
                        <label className={styles.required}>
                            Taxa de serviço
                        </label>
                        <TextField
                            variant='outlined'
                            size="small"
                            value={`${!!configValues.serviceTaxPercentage ? configValues.serviceTaxPercentage : 0}%`}
                            name="serviceTaxPercentage"
                            onChange={(ev) => onConfigChangeHandle(ev.target.name, Number(ev.target.value.match(/\d+/g)?.join("")))}
                            disabled={!configValues.enableServiceTax}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OnlineSale;