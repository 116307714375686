import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpandMore,
  EditOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import styles from "./AccordionColaborador.module.scss";
import { useCallback } from "react";
import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";
import { IEmployeesList } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresResponse";
import { IDeleteCollaboratorParams } from "modules/colaboradores/domain/dto/IDeleteCollaboratorParams";
import { Skeleton } from "components/skeleton/Skeleton";
import { Pagination } from "@mui/material";
import { ISubscription } from "modules/plans/presentation/interfaces/ISubscription";

interface IAccordion {
  role: IGetAllRolesResponse;
  setOpenSidesheet: React.Dispatch<
    React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>>;
  setEditingItem: React.Dispatch<React.SetStateAction<(IEmployeesList & { currentProfileId: string }) | undefined>>;
  setDeleteItem: React.Dispatch<React.SetStateAction<IDeleteCollaboratorParams | undefined>>;
  expandedAccordion: string;
  setExpandedAccordion: React.Dispatch<React.SetStateAction<string>>;
  collaborators: IEmployeesList[];
  isLoading: boolean;
  currentEmploeesPage: number;
  totalEmploeesPages: number;
  loadMore: (page: number) => void;
  setCargoChecked: React.Dispatch<React.SetStateAction<string | undefined>>;
  subscription?: ISubscription;
  localDocument: string;
  setOpenSimpleTrialModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccordionColaborador = ({
  role,
  setOpenSidesheet,
  setEditingItem,
  setDeleteItem,
  expandedAccordion,
  setExpandedAccordion,
  collaborators,
  isLoading,
  currentEmploeesPage,
  totalEmploeesPages,
  loadMore,
  setCargoChecked,
  subscription,
  localDocument,
  setOpenSimpleTrialModal,
}: IAccordion) => {
  const useStyles = makeStyles(() => ({
    accordion: {
      border: "1px solid #E0E0E0",
      boxShadow: "none",
      borderRadius: "16px !important",
    },
    accordionSumary: {
      margin: "5px 7px",
      color: "#5f5f5f",
      fontWeight: "bold",
      fontSize: "18px",
    },
    accordionDetails: {
      padding: "0 23px 16px",
      flexDirection: "column",
    },
  }));

  const stylesMui = useStyles();

  const handleOpenAccordion = useCallback(async (value: string) => {
    if (value === expandedAccordion) {
      return setExpandedAccordion("");
    }
    setExpandedAccordion(value);
  }, [
    expandedAccordion,
    setExpandedAccordion,
  ]);

  return (
    <div id={styles.AccordionColaborador}>
      <Accordion className={stylesMui.accordion} expanded={expandedAccordion.includes(role.Id)} >
        <AccordionSummary
          className={stylesMui.accordionSumary}
          expandIcon={<ExpandMore />}
          onClick={() => handleOpenAccordion(role.Id)}
        >
          {role.Name}
        </AccordionSummary>
        <AccordionDetails className={stylesMui.accordionDetails}>
          {isLoading && <Skeleton items={3} />}
          {!isLoading && collaborators.length > 0 ?
            collaborators.map((item) => {
              return (
                <>
                  <div className={styles.box} key={item.Id}>
                    <div className={styles.content}>
                      <p>{item.Name}</p>
                      <p>{item.Email}</p>
                    </div>
                    <div className={styles.icons}>
                      <EditOutlined
                        className={styles.edit}
                        onClick={() => {
                          setEditingItem({ ...item, currentProfileId: role.Id });
                          setOpenSidesheet("isEditing");
                        }}
                      />
                      <DeleteOutlineOutlined
                        onClick={() => {
                          setDeleteItem({ employeeId: item.Id, profileId: role.Id });
                          setOpenSidesheet("isDeleting");
                        }}
                      />
                    </div>
                  </div>
                  <Button 
                  color={"secondary"} 
                  className={styles.addCollaboratorBtn} 
                  variant='text' 
                  onClick={() => {
                    if (subscription?.isTrial && localDocument === "28302604000101") {
                      return setOpenSimpleTrialModal(true);
                    }
                    setOpenSidesheet("isNew")
                    setCargoChecked(role.Id)
                  }}
                  >
                    <Icon fontSize={'small'}>add</Icon>
                    Adicionar colaborador
                  </Button>
                </>
              );
            }) : (
              !isLoading &&
              <Button
                color={"secondary"}
                className={styles.addCollaboratorBtn}
                variant='text'
                onClick={() => {
                  if (subscription?.isTrial && localDocument === "28302604000101") {
                    return setOpenSimpleTrialModal(true);
                  }
                  setOpenSidesheet("isNew")
                  setCargoChecked(role.Id)
                }}
              >
                <Icon fontSize={'small'}>add</Icon>
                Adicionar colaborador
              </Button>
            )}
          {totalEmploeesPages > 1 && (
            <div
              style={{ display: "flex", justifyContent: "flex-end", marginTop: isLoading ? "15px" : "" }}
            >
              <Pagination
                variant="outlined"
                className={styles.paginationNumber}
                shape="rounded"
                sx={{
                  ".MuiPaginationItem-root": {
                    borderColor: "#BDBDBD",
                    color: "#001537",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    borderColor: "#001537",
                  },
                }}
                count={totalEmploeesPages}
                page={currentEmploeesPage + 1}
                onChange={(_, value) => loadMore(value - 1)}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionColaborador;
