import React, { FC, ReactNode } from 'react';
import styles from './ui.module.scss';

//container
interface ContainerLabelProps {
    flex?: number;
    label?: ReactNode;
    maxWidth?: string | number;
    children: ReactNode;
    row?: boolean;
    color?: string;
    gap?: string | number;
    alignItems?: string;
}
export const ContainerLabel: FC<ContainerLabelProps> = (props) => {
    return (
        <div className={styles.item} style={{ maxWidth: props.maxWidth, gap: props.gap, flex: props.flex }}>
            <label style={{ color: props.color ?? ""}}>{props.label}</label>
            <div className={styles.itemChild} style={{ alignItems: props.alignItems }}>
                {props.children}
            </div>
        </div>);
};
