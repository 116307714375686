import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import React from 'react';
import Utils from 'services/utils/Utils';
import { HouseFlowFilter } from '../components/houseFlowFilter/houseFlowFilter';
import { HouseFlowList } from './houseFlowList/HouseFlowList';
import styles from './HouseFlowPage.module.scss';
import { UseHouseFlowPage } from './UseHouseFlowPage';

export const HouseFlowPage = () => {

  const { houseFlowList, isLoading, onChangePaginationHandle, params, setParams, onChangeFilterHandle, values, setValues } = UseHouseFlowPage();

  return (
    <div id={styles.HouseFlowPage}>
      {houseFlowList?.cashierOpenDate && (
        <p className={styles.cashierDate}>Caixa aberto: {Utils.toDate(houseFlowList?.cashierOpenDate)}</p>
        )}
      {houseFlowList?.cashierOpenDate == null 
        ? <ErrorMessage type='warning' customMessage='É necessário que o estabelecimento possua caixa aberto para a exibição de informações' />
        : (
          <>
            <HouseFlowFilter
              onSubmit={onChangeFilterHandle}
              setParams={setParams}
              params={params}
              values={values}
              setValues={setValues}
              
              />
            <HouseFlowList
              houseFlowList={houseFlowList}
              isLoading={isLoading}
              pagination={params?.pagination}
              onChangePagination={onChangePaginationHandle}
              params={params}
              setParams={setParams}
              values={values}
              setValues={setValues}
            />
          </>
        )
      }
    </div>
  )
}
