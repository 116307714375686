import { useHistory } from 'react-router-dom';
import styles from './TrackPage.module.scss';
import SaasPublicLayout from '../SaasPublicLayout';

export const SaasTrackPage = () => {
    const history = useHistory();
    
    return (
        <SaasPublicLayout step={6} totalSteps={6} backgroundColorTop="#6B41C9" backgroundColorBottom="#865FDD">
            <>
                <img src="/assets/icon/saas/trial-bg.svg" alt="" className={styles.imageBg}/>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div>
                            <img src="/assets/icon/saas/track/pos.svg" alt="" />
                        </div>

                        <div>
                            <span>Pronto, agora é só <b>aguardar a maquininha</b> chegar até você!</span>

                            <div className={styles.track}>
                                <div>
                                    <img src="/assets/icon/saas/track/preparing.svg" alt="" />
                                </div>
                                <div className={styles.separator} />
                                <div>
                                    <img src="/assets/icon/saas/track/ontheway.svg" alt="" />
                                </div>
                                <div className={styles.separator} />
                                <div>
                                    <img src="/assets/icon/saas/track/delivered.svg" alt="" />
                                </div>
                            </div>

                            <p>
                                Enquanto isso, você pode acessar ao Portal Meep e explorar muito mais.
                            </p>

                            <button onClick={() => history.push('/')}>Acessar portal</button>
                        </div>
                    </div>
                </div>
            </>
        </SaasPublicLayout>
    )
}