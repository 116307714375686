import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import ChatIcon from "@material-ui/icons/Chat";

import styles from './ModalStatusIfood.module.scss';
import { Validation } from '../../interfaces/IIfoodStatus';
import { Close, AccessTime, CheckCircle } from '@mui/icons-material';

export interface IModalStatusIfoodProps {
  validations: Validation[];
  handleCloseModal: () => void;
}


const ModalStatusIfood: React.FC<IModalStatusIfoodProps> = ({ validations, handleCloseModal }) => {

  return (
    <div id={styles.ModalStatusIfood}>
      <div className={styles.conteinerTitle}>
        <div className={styles.modalTitle}>Status da Loja</div>
        <div className={styles.closeModal} onClick={handleCloseModal}><Close fontSize="small" style={{ cursor: 'pointer' }} /></div>
      </div>
      {validations.map(validation =>
        <>
          {validation.state.toUpperCase() === "CLOSED" ? (
            <div className={styles.modalStatus}>
              <div className={styles.statusItem}>
                <span className={styles.statusIcon}><AccessTime fontSize="small" /></span>
                <span className={styles.statusTitle}>{validation.message.title}</span>
              </div>
              {
                validation.message.subtitle &&
                <div className={styles.statusItem}>
                  <span className={styles.statusSubtitle}>{validation.message.subtitle}</span>
                </div>
              }
              <div className={styles.statusItem}>
                <span className={styles.statusDescription}>{validation.message.description}</span>
              </div>
            </div>
          ) : (
            <div className={styles.statusText}>
              <div className={styles.statusTextHeader}>
                <CheckCircle fontSize="small" style={{ color: 'green' }} />
                <span className={styles.statusTextTitle}>{validation.message.title}</span>
              </div>
              {
                validation.message.subtitle &&
                <span className={styles.statusTextSubtitle}>{validation.message.subtitle}</span>
              }
            </div>
          )
          }
        </>
      )}
    </div>
  );
}

export default ModalStatusIfood;
