import { IEditDiscountsRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const EditBaseListDiscountUseCase = async (
  baseListService: IDiscountBaseList,
  discount: IEditDiscountsRequest,
  localId: string,
): Promise<void> => {
  return await baseListService.editBaseListDiscount(discount, localId);
};

export default EditBaseListDiscountUseCase;
