import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddDeviceManualUseCase from "modules/equipamento/application/useCases/addDeviceManual/AddDeviceManualUseCase";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import DeviceApi from "services/api/equipamento/DeviceApi";
import { DeviceValue } from "../components/deviceForms/deviceManualForm/interfaces/DeviceValue";

const UseDeviceManual = (localId?: string, deviceType?: DeviceType) => {
  const { showLoading, hideLoading } = useUi();
  const { push } = useHistory();
  const addDevice = useCallback(
    async (values: DeviceValue) => {
      try {
        if (localId) {
          showLoading();
          const service = DeviceApi();
          const response = await AddDeviceManualUseCase(
            service,
            localId,
            values
          );
          push(
            `/private/configuracao/pdv/lista/adicionar/${FormDeviceStep.PROFILE}/${deviceType}/${response.id}`
          );
        } else {
          console.error("Nenhum local selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [deviceType, hideLoading, localId, push, showLoading]
  );

  return {
    addDevice,
  };
};
export default UseDeviceManual;
