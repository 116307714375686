import { IGetMonthResumePerDayResponse } from "../../dtos/IGetMonthResumePerDayResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetMonthResumePerDayUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetMonthResumePerDayResponse> => {
  return await service.getMonthResumePerDay(localId, cashierId, day);
};

export default GetMonthResumePerDayUseCase;
