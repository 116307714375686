import { Dialog, Drawer, Icon, IconButton } from '@material-ui/core'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import GetCurrentEmailSignatureUseCase from 'modules/crm/application/useCases/plans/GetCurrentEmailSignatureUseCase'
import GetPlansUseCase from 'modules/crm/application/useCases/plans/GetPlansUseCase'
import GetTotalReportUseCase from 'modules/crm/application/useCases/plans/GetTotalReportUseCase'
import SaveCampaignUseCase from 'modules/crm/application/useCases/SaveCampaignUseCase'
import SendCampaignUseCase from 'modules/crm/application/useCases/SendCampaignUseCase'
import SendMessagePreviewUseCase from 'modules/crm/application/useCases/SendMessagePreviewUseCase'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CrmServiceApi from 'services/api/crm/CrmApi'
import CrmCampaignForm from '../../components/crmCampaignForm/CrmCampaignForm'
import ConfirmationSend from '../../components/crmList/confirmationSend/ConfirmationSend'
import EmailQuantityAlert from '../../components/crmList/emailQuantityAlert/EmailQuantityAlert'
import CrmImportEventForm from '../../fragment/crmImportEventForm/CrmImportEventForm'
import UseCampaign from '../../hook/UseCampaign'
import UseCrmEventList from '../../hook/UseCrmEventList'
import UseWhatsappConfiguration from '../../hook/UseWhatsappConfiguration'
import { CampaignChannel, ICampaignFormValue } from '../../intefaces/ICampaign'
import { ICrmEvent, ICrmEventStatus, ICrmEventType } from '../../intefaces/ICrmEventData'
import { ITotalReport } from '../../intefaces/TotalReport'
import { ICrmPlan, ICrmSignature } from '../configCrmEmail/ConfigCrmEmail'
import styles from './CrmCampaignEditPage.module.scss'
import { format } from 'date-fns'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import UseSurveyList from 'modules/automation/presentation/hooks/UseSurveyList'
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext'
import { FeatureTypeEnum } from 'modules/plans/presentation/interfaces/FeatureTypeEnum'
import { PremiumHint } from 'modules/plans/presentation/components/premiumHint/PremiumHint'
import { ChannelEnum } from '../../interfaces/ChannelEnum'
export interface ICrmCampaignPageProps {
  //propertys
}
const CrmCampaignEditPage: FC<ICrmCampaignPageProps> = () => {
  const [importEventOpen, setImportEventOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openEmailQuantityAlert, setOpenEmailQuantityAlert] = useState(false)
  const [totalReport, setTotalReport] = useState<ITotalReport>();
  const { eventsList, getEventsList, isLoading: loadingEventList } = UseCrmEventList();
  const { campaign, getCampaignById, isLoading } = UseCampaign()
  const { campaignId } = useParams<{ campaignId: string }>()
  const { whatsappStatus: isOnlineWhatsapp } = UseWhatsappConfiguration()
  const { showLoading, hideLoading, toast } = useUi();
  const { currentLocal } = useLocal();
  const { replace, goBack, push } = useHistory()
  const [signature, setSignature] = useState<ICrmSignature>()
  const { addRouter, updateRouters } = useBreadcumbs();
  const { surveys, getSurveys, isLoadingSurvey } = UseSurveyList(currentLocal)
  const { checkPermission } = usePlans();
  const [plans, setPlans] = useState<ICrmPlan[]>();

  useEffect(() => {
    (async () => {
      const service = CrmServiceApi();
      const response = await GetPlansUseCase(service);
      setPlans(response);
    })();
  }, []);

  useEffect(() => {
    getSurveys();
  }, [getSurveys]);

  useEffect(() => {
    updateRouters([
      {
        label: "CRM",
        title: "Campanhas",
        url: "/private/crm/campaign/",
      },
      {
        label: "Editar",
        title: "Campanha",
      },
    ]);
  }, [addRouter, updateRouters]);

  useEffect(() => {
    getCampaignById(campaignId);
  }, [campaignId, getCampaignById]);

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [hideLoading, isLoading, showLoading]);

  useEffect(() => {
    if (loadingEventList) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [hideLoading, loadingEventList, showLoading]);

  useEffect(() => {
    if (isLoadingSurvey) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [hideLoading, isLoadingSurvey, showLoading]);

  useEffect(() => {
    (async () => {
      const service = CrmServiceApi();
      const response = await GetTotalReportUseCase(service, currentLocal!.id, ChannelEnum.Email);
      if (response) setTotalReport(response[0]);
    })();
  }, [currentLocal]);

  useEffect(() => {
    (async () => {
      const service = CrmServiceApi();
      const plan = await GetCurrentEmailSignatureUseCase(
        service,
        currentLocal!.id
      );
      setSignature(plan);
    })();
  }, [currentLocal]);

  useEffect(() => {
    (async () => {
      const service = CrmServiceApi();
      const plan = await GetCurrentEmailSignatureUseCase(service, currentLocal!.id)
      setSignature(plan)
    })()
  }, [checkPermission, currentLocal])

  useEffect(() => {
    getEventsList(ICrmEventType.Event, ICrmEventStatus.Scheduled);
  }, [getEventsList]);

  const onAddEventHandle = useCallback(
    (event: ICrmEvent) => {
      getEventsList(ICrmEventType.Event);
      setImportEventOpen(false);
    },
    [getEventsList]
  );

  const onClickSaveCampaign = useCallback(
    async (values: ICampaignFormValue) => {
      try {
        showLoading();
        const service = CrmServiceApi();
        await SaveCampaignUseCase(service, campaignId, values);
        replace("/private/crm/campaign");
      } finally {
        hideLoading();
      }
    },
    [campaignId, hideLoading, replace, showLoading]
  );

  const onClickSendCampaign = useCallback(
    async (goToList?: boolean) => {
      try {
        showLoading();
        const service = CrmServiceApi();
        // await SaveCampaignUseCase(service, campaignId, values);
        // setOpenConfirmation(true);
        await SendCampaignUseCase(service, campaignId);
        if (!goToList) {
          replace(`/private/crm/campaign/report/${campaignId}`);
        } else {
          replace(`/private/crm/campaign/`);
        }
      } finally {
        hideLoading();
      }
    },
    [campaignId, hideLoading, replace, showLoading]
  );

  const onClickSaveAndSendCampaign = useCallback(
    async (values: ICampaignFormValue) => {
      try {
        showLoading();

        const service = CrmServiceApi();
        await SaveCampaignUseCase(service, campaignId, values);

        if (values?.channel === CampaignChannel.push && campaign) {
          await onClickSendCampaign(true);
        } else {
          if (
            campaign &&
            totalReport &&
            campaign?.totalCustomersEmail > totalReport?.report.totalAvailable
          ) {
            setOpenEmailQuantityAlert(true);
          } else {
            setOpenConfirmation(true);
          }
        }

        // await SendCampaignUseCase(service, campaignId);
        // replace("/private/crm/campaign")
      } finally {
        hideLoading();
      }
    },
    [
      campaign,
      campaignId,
      hideLoading,
      onClickSendCampaign,
      showLoading,
      totalReport,
    ]
  );

  const onClickSendTest = useCallback(
    async (emails: string[]) => {
      try {
        showLoading();
        const service = CrmServiceApi();
        await SendMessagePreviewUseCase(service, campaignId, emails);
        toast("Teste enviado com sucesso, verifique seu email", "success");
      } finally {
        hideLoading();
      }
    },
    [campaignId, hideLoading, showLoading, toast]
  );

  const defaultValues: ICampaignFormValue | undefined = useMemo(
    () =>
      campaign && {
        name: campaign.name,
        channel: campaign.channel || CampaignChannel.email,
        template: campaign.template,
        whatsapp: {
          event: eventsList?.data.find((item) => item.id === campaign.eventId),
          message: campaign.messageBody,
        },
        email: {
          event: eventsList?.data.find((item) => item.id === campaign.eventId),
          messageBody: campaign.messageBody,
          replyTo: campaign.replyTo,
          subject: campaign.subject,
          promoter: campaign.promoter,
          survey: surveys?.find((x) => x.id === campaign.surveyId),
        },
        push:
          campaign.channel === CampaignChannel.push
            ? {
              date: campaign.deliveryTime
                ? format(new Date(campaign.deliveryTime + "Z"), "yyyy-MM-dd")
                : "",
              time: campaign.deliveryTime
                ? format(new Date(campaign.deliveryTime + "Z"), "HH:mm")
                : "",
              imageUrl: campaign.imageUrl,
              messageBody: campaign.messageBody,
              subject: campaign.subject,
            }
            : undefined,
      },
    [campaign, eventsList?.data, surveys]
  );

  return (
    <div id={styles.CrmCampaignEditPage}>
      <div className={styles.header}>
        <IconButton onClick={() => goBack()}><Icon>chevron_left</Icon></IconButton>
        <span>Voltar</span>
        {/* {
          !signature?.isActive &&
          <button
            className={styles.buttonHire}
            onClick={() => push('/private/configuracao/integrations')}
          >
            Contratar
          </button>
        } */}
      </div>
      {!checkPermission(FeatureTypeEnum.CRM) && <PremiumHint />}
      <div className={styles.container}>
        {
          campaign && <CrmCampaignForm
            campaingValues={campaign}
            surveys={surveys}
            defaultValues={defaultValues}
            onClickImport={() => setImportEventOpen(true)}
            events={eventsList?.data ?? []}
            onClickSave={onClickSaveCampaign}
            onClickSaveAndSend={onClickSaveAndSendCampaign}
            whatsappStatus={isOnlineWhatsapp}
            signature={signature}
          />
        }
      </div>
      <Drawer
        anchor='right'
        open={importEventOpen}
        onClose={() => setImportEventOpen(false)}
      >
        <CrmImportEventForm onAddEvent={onAddEventHandle} />
      </Drawer>
      <Drawer anchor='right' open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        {campaign && <ConfirmationSend
          campaign={campaign}
          onClose={() => setOpenConfirmation(false)}
          onClickSendCampaign={onClickSendCampaign}
          onClickSendPreview={onClickSendTest}
        />}
      </Drawer>
      <Drawer anchor='right' open={openEmailQuantityAlert} onClose={() => setOpenEmailQuantityAlert(false)}>
        <EmailQuantityAlert
          totalReport={totalReport}
          onClose={() => setOpenEmailQuantityAlert(false)}
          planName={signature?.plan.name}
        />
      </Drawer>
    </div>
  );
};
export default CrmCampaignEditPage;
