import { ExportFormat } from "modules/cashlessDashboard/domain/interfaces/RequestParams";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const ExportTopProductsUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<void> => {
  const filterRequest = makeFilterRequest({ ...params, sortField: 'total', exportFormat: ExportFormat.EXCEL });
  await service.getTopProducts(filterRequest, params.localId);
}

export default ExportTopProductsUseCase
