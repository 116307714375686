import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PaymentLinkRecurrenceApi from "services/api/paymentLinkRecurrence/PaymentLinkRecurrenceApi";
import { IPaymentLinkRecurrenceList } from "../interfaces/IPaymentLinkRecurrenceList";
import { IPaymentLinkRecurrencefilter } from "../components/paymentLinkRecurrenceFilter/interfaces/IPaymentLinkRecurrencefilter";
import GetPaymentLinkRecurrenceUseCase from "modules/paymentLinkReport/application/useCases/GetPaymentLinkRecurrenceUseCase";

export interface IParamsPaymentLinkList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: IPaymentLinkRecurrencefilter
}

const UsePaymentLinkReportList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [paymentLinkRecurrenceList, setPaymentLinkRecurrenceList] = useState<IPaymentLinkRecurrenceList>()
  const { currentLocal } = useLocal();

  const getPaymentLinkRecurrenceList = useCallback(
    async (params?: IParamsPaymentLinkList) => {
      try {
        const servicePermutation = PaymentLinkRecurrenceApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPaymentLinkRecurrenceUseCase(servicePermutation,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );

          setPaymentLinkRecurrenceList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    paymentLinkRecurrenceList,
    isLoading,
    getPaymentLinkRecurrenceList,
    setPaymentLinkRecurrenceList,
  };
};

export default UsePaymentLinkReportList;
