import { useState } from 'react';
import styles from './SimpleBanner.module.scss';

interface SimpleBannerProps {
    title: string;
    description: string;
    linkText?: string;
    imageUrl: string;
    showClose?: boolean;
    clickLinkFn?: () => void;
}

const SimpleBanner = ({ title, description, linkText, imageUrl, showClose = true, clickLinkFn }: SimpleBannerProps) => {
    const [showBanner, setShowBanner] = useState(true);

    return (
        <div className={styles.simpleBanner} data-show={showBanner}>
            <div className={styles.simpleBannerTexts}>
                <p className={styles.simpleBannerTitle}>{title}</p>
                <p className={styles.simpleBannerDescription}>{description}</p>
                {linkText && <p className={styles.simpleBannerLink} onClick={clickLinkFn}>{linkText}</p>}
            </div>

            <div className={styles.simpleBannerImageBox}>
                <img className={styles.simpleBannerImage} src={imageUrl} alt={title} />
            </div>

            {showClose && <div className={styles.simpleBannerClose} onClick={() => setShowBanner(false)}>✕</div>}
        </div>
    );
}

export default SimpleBanner;
