import { IGetContractResponse } from 'modules/equipamento/domain/dto/IGetContractResponse';
import { IGetKitsEquipmentsParamsRequest } from 'modules/equipamento/domain/dto/IGetKitsEquipmentsParamsRequest';
import { IGetEquipmentsResponse } from 'modules/equipamento/domain/dto/IGetEquipmentsResponse';
import { IDeviceService } from 'modules/equipamento/domain/interface/IDeviceService';
import { api } from 'services/api/Api';
import { IGetKitsEquipmentsResponse } from 'modules/equipamento/domain/dto/IGetKitsEquipmentsResponse';
import { IGetEquipmentsTypesResponse } from 'modules/equipamento/domain/dto/IGetEquipmentsTypesResponse';
import { IGetDeviceLicenseListResponse } from 'modules/equipamento/domain/dto/IGetDeviceLicenseListResponse';
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { v4 } from "uuid";
import { GetLinkCodeRequest } from 'modules/equipamento/domain/dto/getLinkCode/GetLinkCodeRequest';
import { GetLinkCodeResponse } from 'modules/equipamento/domain/dto/getLinkCode/GetLinkCodeResponse';
import { ICheckLinkCodeResponse } from 'modules/equipamento/domain/dto/checkLinkCode/ICheckLinkCodeResponse';
import { IFilterDeviceRequest } from 'modules/equipamento/domain/dto/getDevice/IFilterDeviceRequest';
import { IGetDeviceStatusListResponse } from 'modules/equipamento/domain/dto/getDevice/IGetDeviceStatusListResponse';
import { deviceConfigApi } from './DeviceConfigApi';
import { IGetDeviceListResponse } from 'modules/equipamento/domain/dto/getDevice/IGetDeviceListResponse';
import { IEditDeviceRequest } from 'modules/equipamento/domain/dto/editDeviceLicense/IEditDeviceRequest';
import { IEditDeviceResponse } from 'modules/equipamento/domain/dto/editDeviceLicense/IEditDeviceResponse';
import { IAddDeviceRequest } from 'modules/equipamento/domain/dto/addDevice/IAddDeviceRequest';
import { IAddDeviceResponse } from 'modules/equipamento/domain/dto/addDevice/IAddDeviceResponse';
import { ISetDeviceProfileRequest } from 'modules/equipamento/domain/dto/setDeviceProfile/ISetDeviceProfileRequest';
import { ISetDeviceProfileResponse } from 'modules/equipamento/domain/dto/setDeviceProfile/ISetDeviceProfileResponse';
import { IAddDeviceManualRequest, IAddDeviceManualResponse } from 'modules/equipamento/domain/dto/addDeviceManual/IAddDeviceManual';

const DeviceApi = (): IDeviceService => {
    const getDeviceLicenseList = async (
        localID: string
      ): Promise<IGetDeviceLicenseListResponse> => {
        return {
          items: [
            {
              id: v4(),
              type: {
                value: DeviceType.POS,
                name: "Maquininha",
              },
              total: 30,
              totalUsed: 4,
            },
            {
              id: v4(),
              type: {
                value: DeviceType.TOTEM,
                name: "Totem",
              },
              total: 5,
              totalUsed: 2,
            },
            {
              id: v4(),
              type: {
                value: DeviceType.TABLET,
                name: "Tablet",
              },
              total: 20,
              totalUsed: 4,
            },
          ],
        };
      };

      const getDeviceCode = async (
        localId: string,
        request: GetLinkCodeRequest
      ): Promise<GetLinkCodeResponse> => {
        return { code: "123456" };
      };

      const checkDeviceCode = async (
        code: string
      ): Promise<ICheckLinkCodeResponse> => {
        return {
          linked: true,
          deviceId: v4(),
          expired: false,
        };
      };

      const GetDeviceWithStatusAndProfileListUseCase = async (
        filter: IFilterDeviceRequest
      ) => {
        const response = await deviceConfigApi.get<IGetDeviceStatusListResponse>(
          "/devices",
          { params: filter }
        );
        return response.data;
      };

      const GetDeviceListUseCase = async (
        localId: string,
        filter: IFilterDeviceRequest
      ): Promise<IGetDeviceListResponse> => {
        const response = await deviceConfigApi.get<IGetDeviceListResponse>(
          "/locals/" + localId + "/devices",
          {
            params: {
              ...filter,
              limit: 1300,
            },
            paramsSerializer: params => Object.entries(params).map(([key, value], i) => {
             if ((Array.isArray(value) && Array(value).length == 0) || value == '' || value === undefined) return;

             return Array.isArray(value)
              ? `${key}=${value.join('&' + key + '=')}`
              : `${key}=${value}`;
            }).join('&')
          }
        );
        return response.data;
      };

      const CopyDeviceUseCase = (deviceId: string) => {
        throw "Nao implementado device api";
      };

      const editDevice = async (
        profileId: string,
        values: IEditDeviceRequest
      ): Promise<IEditDeviceResponse> => {
        const response = await deviceConfigApi.put<IEditDeviceResponse>(
          "/devices/" + profileId,
          values
        );
        return response.data;
      };

      const addDevice = async (
        values: IAddDeviceRequest
      ): Promise<IAddDeviceResponse> => {
        const response = await deviceConfigApi.post<IAddDeviceResponse>(
          "/devices/",
          values
        );
        return response.data;
      };
      const setDeviceProfile = async (
        deviceProfileId: string,
        request: ISetDeviceProfileRequest
      ): Promise<ISetDeviceProfileResponse> => {
        throw "not implement";
      };
      const addDeviceManual = async (
        values: IAddDeviceManualRequest
      ): Promise<IAddDeviceManualResponse> => {
        throw "not implement";
      };

    const getContract = async (localId: string): Promise<IGetContractResponse[]> => {
        const response = await api.get<IGetContractResponse[]>(`/Contrato/Buscar/${localId}`);
        return (response.data);
    }

    const getEquipments = async (localId: string): Promise<IGetEquipmentsResponse> => {
        const response = await api.get<IGetEquipmentsResponse>(`/Contrato/Buscar/Equipamentos/${localId}`);
        return (response.data);
    }

    const getKitsEquipments = async (localId: string, request?: IGetKitsEquipmentsParamsRequest): Promise<IGetKitsEquipmentsResponse> => {
        const response = await api.get<IGetKitsEquipmentsResponse>(`/Equipamento/BuscarKitsEEquipamentosV2/${localId}`, { params: request });
        return (response.data);
    }

    const getEquipmentsTypes = async (localId: string): Promise<IGetEquipmentsTypesResponse> => {
        const respomse = await api.get<IGetEquipmentsTypesResponse>("/Equipamento/BuscarTiposEquipamentos");
        return respomse.data;
    }

    return {
        GetDeviceWithStatusAndProfileListUseCase,
        GetDeviceList: GetDeviceListUseCase,
        CopyDeviceUseCase,
        getDeviceLicenseList,
        getDeviceCode,
        checkDeviceCode,
        setDeviceProfile,
        addDeviceManual,
        addDevice,
        editDevice,
        getContract,
        getEquipments,
        getKitsEquipments,
        getEquipmentsTypes,
    }
}

export default DeviceApi;
