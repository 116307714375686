import styles from "./DrawerCampaignCreate.module.scss";
import { Icon } from "@material-ui/core";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IDropdown } from "../../../interfaces/ICrmFilterValue";
import { Autocomplete } from "@material-ui/lab";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
} from "@material-ui/core";

export interface Props {
  events?: IDropdown[];
  campaigns?: IDropdown[];
  onClose: () => void;
  onClickCreatCampaignByFilter?: () => void;
  onClickCreateCampaignByEvent?: (event: IDropdown[]) => void;
  onClickCreateCampaignByCampaign?: (campaign: IDropdown) => void;
}

const DrawerCampaignCreate = ({
  events,
  campaigns,
  onClose,
  onClickCreatCampaignByFilter,
  onClickCreateCampaignByEvent,
  onClickCreateCampaignByCampaign,
}: Props) => {
  const [openDestiny, setopenDestiny] = useState<boolean>(false);
  const [select, setSelect] = useState<number>(0);

  const [selectedEvent, setSelectedEvent] = useState<IDropdown[]>();
  const [selectedCampaign, setSelectedCampaign] = useState<IDropdown | null>(null);

  useEffect(() => {
    if (select === 1) {
      setSelectedCampaign(null);
    } else if (select === 2) {
      setSelectedEvent([]);
    } else {
      setSelectedCampaign(null);
      setSelectedEvent([]);
    }
  }, [select]);

  useEffect(() => {
    if (!openDestiny) {
      setSelect(0);
    }
  }, [openDestiny]);

  const validate = useMemo(() => {
    if (select === 1) {
      return !!selectedEvent;
    } else if (select === 2) {
      return !!selectedCampaign;
    } else {
      return true;
    }
  }, [select, selectedCampaign, selectedEvent]);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (select === 1) {
        if (selectedEvent) {
          onClickCreateCampaignByEvent?.(selectedEvent);
        }
      } else if (select === 2) {
        if (selectedCampaign) {
          onClickCreateCampaignByCampaign?.(selectedCampaign);
        }
      } else if (select === 2) {
        if (selectedCampaign) {
          onClickCreateCampaignByCampaign?.(selectedCampaign);
        }
      }
    },
    [
      onClickCreateCampaignByCampaign,
      onClickCreateCampaignByEvent,
      select,
      selectedCampaign,
      selectedEvent,
    ]
  );

  return (<form className={styles.form} onSubmit={submitHandle}>
    {!openDestiny ?
      <>
        <div>
          <div className={styles.contentTop}>
            <h1 className={styles.titleCreateCampaign}>Criar campanha</h1>
            <Icon className={styles.iconClose} onClick={onClose}>
              close
            </Icon>
          </div>
          <p className={styles.selectCampaign}>
            Primeiro, selecione
            <strong> quem serão os destinatários da sua campanha:</strong>
          </p>
        </div>
        <div
          className={styles.destination}
          onClick={onClickCreatCampaignByFilter}
        >
          <div className={styles.specifyDestiny}>
            <img
              src="/assets/icon/bag-icon.png"
              alt="bag"
              className={styles.icon}
            />
            <h1 className={styles.titleOption}>
              Clientes e destinatários específicos
            </h1>
          </div>
          <p className={styles.detail}>
            Para isso, filtre e selecione os clientes que deseja. Depois disso,
            basta clicar em ‘Criar campanha’
          </p>
        </div>
        <p style={{ margin: "0 auto" }}>ou</p>
        <div
          className={styles.destination}
          onClick={() => {
            setopenDestiny(!openDestiny);
          }}
        >
          <div className={styles.specifyDestiny}>
            <img
              src="/assets/icon/icon-location.png"
              alt="location"
              className={styles.icon}
            />
            <h1 className={styles.titleOption}>
              Clientes de eventos/campanhas anteriores
            </h1>
          </div>
          <p className={styles.detail}>
            Selecione um evento ou campanha anterior e reaproveite a listagem de
            clientes já existente
          </p>
        </div>
      </>
      :
      <>
        <div>
          <div
            className={styles.contentBack}
            onClick={() => setopenDestiny(!openDestiny)}
          >
            <Icon>arrow_back_ios</Icon>
            <h1 className={styles.titleBack}>Voltar</h1>
          </div>
          <p className={styles.chooseDestiny}>
            Escolha quem são os seus destinatários
          </p>
          <p className={styles.description}>
            É possível escolher tanto a listagem de clientes que participaram de
            eventos anteriores quanto aqueles que já participaram de alguma
            campanha.
          </p>
        </div>
        <div className={styles.contentSelect}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              className={styles.radioContent}
              onChange={(ev) => setSelect(Number(ev.target.value))}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                className={styles.span}
                label={"Clientes de evento anterior"}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                className={styles.span}
                label={"Clientes de campanha anterior"}
              />
            </RadioGroup>
          </FormControl>
          {select !== 0 && (
            <>
                <Autocomplete
                  style={{ display: select === 1 ? 'block' : 'none'}}
                  value={selectedEvent}
                  options={events || []}
                  multiple
                  onChange={(ev, value) => setSelectedEvent(value)}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <>
                      <MuiTextField
                        {...params}
                        label="Selecione o evento"
                        variant="outlined"
                      />
                    </>
                  )}
                />
                <Autocomplete
                  style={{ display: select === 2 ? 'block' : 'none'}}
                  options={campaigns || []}
                  value={selectedCampaign}
                  onChange={(ev, value) =>
                    setSelectedCampaign(value || null)
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <>
                      <MuiTextField
                        label="Selecione a campanha"
                        variant="outlined"
                        {...params}
                      />
                    </>
                  )}
                />
            </>
          )}
        </div>
        <div className={styles.contentButtons}>
          <button
            className={styles.buttonBack}
            onClick={() => setopenDestiny(!openDestiny)}
          >
            Voltar
          </button>
          <button className={styles.button} disabled={!validate}>
            Continuar
          </button>
        </div>
      </>
    }
  </form>
  )
};

export default DrawerCampaignCreate;
