import {
  IPaymentLinkValuesForm,
  IPaymentlinkValuesErrors,
} from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";

export const validateValuesForm = (
  formData: IPaymentLinkValuesForm,
  setErrors: React.Dispatch<React.SetStateAction<IPaymentlinkValuesErrors>>
) => {
  let _errors: IPaymentlinkValuesErrors = {};
  let hasError = false;  
  if (!formData.credit && !formData.ticket && !formData.pix) {
    _errors = {
      ..._errors,
      paymentForm: "Selecione ao menos um meio de pagamento",
    };
    hasError = true;
  }
  if (formData.isSinglePayment && (formData.isSinglePayment && (formData.credit && !formData.installments))) {
    _errors = { ..._errors, installments: "Selecione o numero de parcelas" };
    hasError = true;
  }
  if (formData.isSinglePayment && (formData.credit && Number(formData.installments) === 0)) {
    _errors = { ..._errors, installments: "Insira um valor maior que zero" };
    hasError = true;
  }
  // if (formData.credit && Number(formData.tax) === 0) {
  //   _errors = { ..._errors, tax: "Insira a taxa de juros" };
  //   hasError = true;
  // }

  setErrors(_errors);
  return !hasError;
};
