import { FC, useCallback, useState } from "react";
import styles from "./FilterDeviceProfile.module.scss";
import { IFilterProfileDevice } from "./IFilterDeviceProfile";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import { IOperationSelect } from "../profileForms/deviceSystemForm/interfaces/IDeviceSystemValue";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import UseDimension from "components/dimension/UseDimension";
import Button from "components/ui/Button/Button";
export interface IFilterDeviceProfileProps {
  operationList: IOperationSelect[];
  value: IFilterProfileDevice;
  onChange: (value: IFilterProfileDevice) => void;
}

const FilterDeviceProfile: FC<IFilterDeviceProfileProps> = ({
  operationList,
  value,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState<IOperationSelect>();
  const onChangeSystemTypeHandle = useCallback(
    (property: "operation" | "menu", selectValue: IOperationSelect) => {
      onChange({ ...value, [property]: selectValue });
    },
    [value, onChange]
  );

  const { dimensions } = UseDimension();

  return (
    <div id={styles.FilterDeviceProfile}>
      <FormItemContainer row minWidth={dimensions.width > 900 ? 300 : 200}>
        <FormItemContainer label={"Operação"}>
          <AutoComplete
            variant="outlined"
            options={operationList}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => {
              setSelectValue(value as IOperationSelect);
            }}
          />
        </FormItemContainer>
      </FormItemContainer>
      <Button
        onClick={() =>
          onChangeSystemTypeHandle("operation", selectValue as IOperationSelect)
        }
        className={styles.buttonSearch}
      >
        Buscar
      </Button>
    </div>
  );
};
export default FilterDeviceProfile;
