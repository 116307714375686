import { IRankingClients } from "modules/salesDashboard/presentation/components/rakingClients/IRankingClients";
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "./MakeFilterRequest";

const GetRankingClientsUseCase = async (service: ISalesDashboardService, { sortField = 'totalConsumption', ...params }: IUseCaseParams): Promise<IRankingClients> => {
  const filterRequest = makeFilterRequest({ sortField, ...params });

  return await service.getRankingClients(filterRequest);
}

export default GetRankingClientsUseCase


