import { Switch, TextField } from '@material-ui/core'
import styles from './SuccessPage.module.scss';
import { useEffect, useState } from 'react';
import { IAddressSaas } from '../../interfaces/address/IAddressSaas';
import { IAddress } from 'components/googleAutocomplete/interfaces/IAddress';
import { GoogleAutoComplete } from 'components/googleAutocomplete/GoogleAutoComplete';
import { useQuery } from 'hooks/UseQuery';
import { useHistory } from 'react-router-dom';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IProspect } from '../../interfaces/prospect/IProspect';
import { ProspectSaasUseCase } from 'modules/saas/application/useCases/ProspectSaasUseCase';
import { SaasApi } from 'services/api/saas/SaasApi';
import { SaveDeliveryAddressUseCase } from 'modules/saas/application/useCases/SaveDeliveryAddressUseCase';
import SaasSuccessLayout from '../SaasSuccessLayout';

interface ErrorType {
    address: string;
    number: string;
}

const service = SaasApi();

export const SuccessPage = () => {
    const [useSameAddress, setUseSameAddress] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<IAddressSaas>();
    const [errors, setErrors] = useState({} as ErrorType);
    const [prospect, setProspect] = useState({} as IProspect);

    const query = useQuery();
    const history = useHistory();
    const { toast } = useUi();

    const prospectId = query.get('prospectId');
    const key = query.get('key');

    useEffect(() => {
        if (!prospectId || !key) {
            history.push('/public/saas/register');
            toast('A url está incompleta. Refaça o processo.', 'error');
        }
    }, [history, key, prospectId, query, toast]);

    useEffect(() => {
        (async () => {
            try {
                if (prospectId && key) {
                    const response = await ProspectSaasUseCase(service, prospectId, key);
                    setProspect(response);
                }
            } catch (err: any) {
                if (err?.response?.status === 401) {
                    toast('A chave fornecida está inválida ou expirou.', 'error');
                    history.push(`/public/saas/register`);
                } else {
                    toast('Ocorreu um erro ao fazer a busca dos dados. Tente novamente.', 'error');
                }
            } 
        })();
    }, [history, key, prospectId, query, toast]);

    const handleChangeAddress = (val: IAddress) => {
        setForm({ ...val, zipCode: val.CEP! });
    }

    const validate = async () => {
        let valid = true;
        setErrors({} as ErrorType);

        if (!form?.street) {
            setErrors(prev => ({ ...prev, address: 'O endereço é obrigatório' }))
            valid = false;
        }

        if (!form?.number) {
            setErrors(prev => ({ ...prev, number: 'O número é obrigatório' }))
            valid = false;
        }

        return valid;
    }

    const handleSubmit = async () => {
        const isValid = await validate();
        if (!isValid) return;

        try {
            setLoading(true);

            let formData = form;

            formData = form;

            if (useSameAddress) {
                formData = prospect.establishment.address
            }

            await SaveDeliveryAddressUseCase(service, formData!, prospectId!, key!);
            history.push('/public/saas/track');
        } finally {
            setLoading(false);
        }
    }

    return (
        <SaasSuccessLayout>
            <>
                <img src="/assets/icon/saas/trial-bg.svg" alt="" className={styles.imageBg} />
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div>
                            <img src="/assets/icon/saas/success.gif" alt="" />
                        </div>

                        <div>
                            <h1>Pagamento <b>realizado</b><br /><b>com sucesso!</b></h1>

                            <p>Agora você está pronto para aproveitar tudo que a Meep tem a oferecer!</p>

                            <span className={styles.addressTitle}>Preencha o endereço para entrega da sua <span>maquininha</span></span>

                            <div className={styles.useSameAddress}>
                                <Switch value={useSameAddress} onChange={() => setUseSameAddress(!useSameAddress)} />
                                <p>Usar o mesmo endereço cadastrado do estabelecimento</p>
                            </div>

                            
                                <div className={styles.addressContainer} style={{ opacity: useSameAddress ? 0 : 1 }}>
                                    <div className={styles.formRow} style={{ marginBottom: 0 }}>
                                        <div className={styles.column}>
                                            <label className={styles.required}>Endereço</label>
                                            <GoogleAutoComplete
                                                onSelect={handleChangeAddress}
                                                onReset={() => setForm(undefined)}
                                                helperText={errors.address}
                                                error={!!errors.address}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>
                                        <div className={styles.column}>
                                            <label className={styles.required}>Número</label>
                                            <TextField
                                                value={form?.number}
                                                onChange={(ev) => setForm(prev => ({ ...prev!, number: ev.target.value }))}
                                                variant="outlined"
                                                fullWidth
                                                size='small'
                                                helperText={errors.number}
                                                error={!!errors.number}
                                            />
                                        </div>
                                        <div className={styles.column}>
                                            <label>Complemento</label>
                                            <TextField
                                                value={form?.complement}
                                                onChange={(ev) => setForm(prev => ({ ...prev!, complement: ev.target.value }))}
                                                variant="outlined"
                                                fullWidth
                                                size='small'
                                            />
                                        </div>
                                    </div>
                                </div>
                            

                            <footer style={{ marginTop: useSameAddress ? -170 : 0 }}>
                                <span>Entrega de 2 a 10 dias úteis</span>
                                <p>FRETE GRÁTIS</p>
                            </footer>


                            <button onClick={handleSubmit} disabled={loading}>
                                {
                                    loading ? 'Enviando...' : 'Enviar'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </SaasSuccessLayout>
    )
}