import { IGetPosSolicitations } from "modules/admin/posSolicitations/domain/dto/IGetPosSolicitations";
import { IPosSolicitationsService } from "modules/admin/posSolicitations/domain/interface/IPosSolicitationsService";
import { IFilterParams } from "modules/admin/posSolicitations/presentation/interface/IFilterParams";
import { api } from "../Api";
import { plansUrl } from "Enviroment";

const baseUrl = plansUrl;

const PosSolicitationsApi = (): IPosSolicitationsService => {
  const getExternalPosSolicitations = async (filterParams: IFilterParams): Promise<IGetPosSolicitations> => {
    const response = await api.get<IGetPosSolicitations>(`${baseUrl}/devices/solicitations`, {
      params: {
        page: filterParams.page,
        pageSize: filterParams.pageSize,
        keyword: filterParams.keyword,
        acquirerType: filterParams.acquirerType,
        [`sort.property`]: filterParams.column,
        [`sort.orientation`]: filterParams.orientation,
      }
    });
    return response.data;
  }

  const putImportExternalPos = async (solicitationId: string): Promise<void> => {
    const response = await api.put<void>(`${baseUrl}/devices/solicitations/${solicitationId}/import`,
      { params: { disableError: true } }
    );
    return response.data;
  }

  const putApproveExternalPos = async (solicitationId: string): Promise<void> => {
    const response = await api.put<void>(`${baseUrl}/devices/solicitations/${solicitationId}/approve`,
      { params: { disableError: true } }
    );
    return response.data;
  }

  return { getExternalPosSolicitations, putImportExternalPos, putApproveExternalPos };
}

export default PosSolicitationsApi;
