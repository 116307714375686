import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import React, { FC } from 'react'
import ColaboratorScheduleForm from '../../components/colaboratorScheduleForm/ColaboratorScheduleForm'
import { ICollaboratorScheduleFormValue } from '../../components/colaboratorScheduleForm/ColaboratorScheduleFormValue'
import styles from './EditColaboratorSchedulePage.module.scss'
import { UseEditColaboratorSchedulePage } from './UseEditColaboratorSchedulePage'
export interface IColaboratorSchedulePageProps {
    //propertys
}
const EditColaboratorSchedulePage: FC<IColaboratorSchedulePageProps> = () => {
    const { toast } = useUi();
    const {
        onSubmitFormHandle,
        operators,
        services,
        defaultCollaborator
    } = UseEditColaboratorSchedulePage();
    return (
        <div id={styles.ColaboratorSchedulePage} >
            <div className={styles.container} >
                {
                    operators &&
                    services &&
                    <ColaboratorScheduleForm
                        onSubmit={(value: ICollaboratorScheduleFormValue) => { onSubmitFormHandle (value) 
                        toast("Colaborador atualizado com sucesso!", "success") }}
                        operators={operators}
                        services={services}
                        defaultValues={defaultCollaborator}
                    />}
            </div>
        </div>
    )
}
export default EditColaboratorSchedulePage