import { FC, useMemo } from "react";
import { Help } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import {
  IDeviceItem,
  IDeviceNetwork,
  IDeviceType,
} from "./interfaces/IDeviceItem";
import styles from "./DeviceList.module.scss";
import "./DeviceItem.css";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";

interface DeviceItemProps {
  deviceItem: IDeviceItem;
  selectedDevices: IDeviceItem[];
  onClickCopyItem: (deviceItem: IDeviceItem) => void;
  onClickItem: (deviceItem: IDeviceItem) => void;
  onSelectItem: (deviceItem: IDeviceItem) => void;
  perfilVinculo?: number
}

const deviceType = [
  {
    id: DeviceType.POS,
    name: "POS",
  },
  {
    id: DeviceType.TOTEM,
    name: "Totem",
  },
  {
    id: DeviceType.TABLET,
    name: "Tablet",
  },
  {
    id: DeviceType.CHECK,
    name: "MeepCheck",
  },
];

const DeviceItem: FC<DeviceItemProps> = ({
  deviceItem,
  selectedDevices,
  onClickCopyItem,
  onClickItem,
  onSelectItem,
  perfilVinculo
}) => {
  const getDeviceTypeImage = (deviceType: IDeviceType) => {
    switch (deviceType.value) {
      case DeviceType.POS:
        return "/assets/icon/device-pos.svg";
      case DeviceType.TOTEM:
        return "/assets/icon/device-kiosk.svg";
      case DeviceType.TABLET:
        return "/assets/icon/device-tablet.svg";
      case DeviceType.CHECK:
        return "/assets/icon/device-check.svg";
      default:
        return "/assets/icon/device-tablet.svg";
    }
  };

  const getSignalImage = (deviceNetwork: IDeviceNetwork) => {
    if (deviceNetwork.signal <= 33) {
      return "/assets/icon/wifi-low.svg";
    } else if (deviceNetwork.signal <= 66) {
      return "/assets/icon/wifi-medium.svg";
    }
    return "/assets/icon/wifi-full.svg";
  };

  const getBatteryImage = (deviceBattery: number) => {
    if (deviceBattery <= 33) {
      return "/assets/icon/battery-low.svg";
    } else if (deviceBattery <= 66) {
      return "/assets/icon/battery-medium.svg";
    }
    return "/assets/icon/battery-full.svg";
  };

  const getChecked = () => {
    return !!selectedDevices.find((x) => x.id === deviceItem.id);
  };

  const getTypeName = useMemo(
    () =>
      deviceItem.type.name ||
      deviceType.find((x) => x.id === deviceItem.type.value)?.name,
    [deviceItem]
  );

  return (
    <div id={styles.deviceItem}>
      <div className={`${styles.row} ${styles.header}`}>
        <img
          src={getDeviceTypeImage(deviceItem.type)}
          alt="Tipo do equipamento"
        />
        <span>{getTypeName}</span>
        {perfilVinculo !== 2 &&
        <div
          className={`${styles.checkbox} ${getChecked() ? styles.checked : ""}`}
        >
          
          <span className={styles.label}>Selecionar</span>
         
          <Checkbox
            className="device-item-checkbox"
            checked={getChecked()}
            onChange={() => onSelectItem(deviceItem)}
          />
        </div>
        }
      </div>

      <div className={`${styles.row} ${styles.name}`}>
        <span>{deviceItem.name}</span>
      </div>

      <div className={`${styles.row} ${styles.identifier}`}>
        <span>ID: {deviceItem.identifier}</span>
      </div>
      <div className={`${styles.row} ${styles.profile}`}>
        {deviceItem.profile ? (
          <span>PDV: {deviceItem.profile?.name}</span>
        ) : (
          <span>Sem perfil de venda</span>
        )}
      </div>

      {deviceItem.status && (
        <>
          {deviceItem.type.value !== DeviceType.POS && (
            <div className={`${styles.row} ${styles.battery}`}>
              <img src={getBatteryImage(deviceItem.status.battery)} alt="" />
              <span>{deviceItem.status.battery}%</span>
            </div>
          )}

          <div className={`${styles.row} ${styles.network}`}>
            <img src={getSignalImage(deviceItem.status.network)} alt="" />
            <span>Netvirtua265-2.5g</span>
          </div>

          <div className={`${styles.row} ${styles.alert}`}>
            <Help />
            <span>Existem atualizações pendentes!</span>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceItem;
