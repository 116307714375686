export enum HubStatusEnum {
  Unknown = 0,
  Installed = 1,
  Disconnected = 2,
  Connected = 3
}

export interface IGetHubByOwnerIdResponse {
  key: string;
  enabled: boolean;
  status: HubStatusEnum;
}
