import { FC, useState } from "react";
import styles from "./SimplePosItem.module.scss";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { format } from "date-fns";
import { ISimplePos } from "modules/salesPos/domain/dto/IGetSimplePosResponse";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import SimplePosConfig from "../simplePosConfig/SimplePosConfig";

export interface INavigatorItem {
    id: string;
    localId: string;
    deviceId: string;
    navigatorSessionId: string;
    navigatorName: string;
    deviceName: string;
}

export interface ISimplePosItemProps {
    pos: ISimplePos;
    terminalsList?: IDeviceTerminalItemResponse[];
    getPosList: () => void;
}

const useStyles = makeStyles({
    root: {
        '&.MuiAccordion-root': {
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            "&::before": {
                display: "none",
            },
        },
        '&.MuiAccordion-root:first-of-type': {
            borderRadius: "10px",
        },
        '&.MuiAccordion-root:last-of-type': {
            borderRadius: "10px",
        },
    },
});

const SimplePosItem: FC<ISimplePosItemProps> = ({
    pos,
    terminalsList,
    getPosList,
}) => {
    const [expand, setExpand] = useState(false);
    const accordionStyle = useStyles();

    return (
        <Accordion
            elevation={0}
            disableGutters
            expanded={expand}
            className={accordionStyle.root}
        >
            <AccordionSummary style={{ cursor: "default" }}>
                <div id={styles.SimplePosItem}>
                    <div className={styles.header}>
                        <div className={styles.start}>
                            <div className={styles.iconContainer}>
                                <img src="/assets/icon/saas/pos-saas.svg" alt="POS" />
                            </div>
                            <span>{pos.name}</span>
                        </div>
                        <div className={styles.end}>
                            <span>POS Integrada</span>
                            <button onClick={() => setExpand(!expand)}>
                                {expand ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                    </div>
                    <div className={styles.operatorAndDevice}>
                        <div className={styles.posInfo}>
                            <span>Maquininha criada em: {format(new Date(pos.createdAt), "dd/MM/yyyy' 'HH':'mm")}</span>
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <SimplePosConfig
                    posId={pos.id}
                    terminalId={pos.deviceId}
                    terminalsList={terminalsList}
                    getPosList={getPosList}
                />
            </AccordionDetails>
        </Accordion >
    )
}
export default SimplePosItem