import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import { FC, useState } from "react";
import styles from "./AuthPage.module.scss";
import { UseAuthPage } from "./UseAuthPage";
import UseDimension from "components/dimension/UseDimension";
import ShowPassword from "../components/showPassword/ShowPassword";
import SaasLayoutAuth from "../components/saasLayoutAuth/SaasLayoutAuth";

export interface ISaasAuthPageProps {
  //propertys
}

const SaasAuthPage: FC<ISaasAuthPageProps> = () => {
  const {
    values,
    submitHandle,
    changeHandle,
    onClickForgotPassword,
    errors,
    setErrors,
  } = UseAuthPage();

  const [showPassword, setShowPassword] = useState(false);
  const { isMobile } = UseDimension();

  return (
    <SaasLayoutAuth>
      <div id={styles.AuthPage} style={{ marginLeft: "100px" }}>
        <div className={styles.container}>
          {isMobile && (
            <img
              src="/assets/img/LogoMee.svg"
              alt=""
              className={styles.logoMobile}
            />
          )}
          <div style={{ textAlign: isMobile ? "center" : "start" }}>
            <h1 style={{ color: "#5F5F5F", fontSize: "20px" }}>
              <b>Bem-vindo(a)</b> à sua nova experiência
            </h1>
          </div>
          <form className={styles.form} onSubmit={(ev) => submitHandle(ev)}>
            <div className={styles.input}>
              <label htmlFor="userName" className={styles.required}>
                E-mail
              </label>
              <Input
                name={"userName"}
                variant={"outlined"}
                value={values.userName}
                placeholder="email@email.com"
                onChange={(ev) => {
                  changeHandle(ev);
                  setErrors((prev) => ({ ...prev, userName: undefined }));
                }}
                error={!!errors.userName}
                helperText={errors.userName}
              />
            </div>
            <div className={styles.input}>
              <label htmlFor="password" className={styles.required}>
                Senha
              </label>
              <Input
                name={"password"}
                variant={"outlined"}
                value={values.password}
                placeholder="******"
                type={showPassword ? "text" : "password"}
                onChange={(ev) => {
                  changeHandle(ev);
                  setErrors((prev) => ({ ...prev, password: undefined }));
                }}
                error={!!errors.password}
                helperText={
                  <span className={styles.helperText}>{errors.password}</span>
                }
              />
              <ShowPassword
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            </div>
            <span
              className={`${styles.forgotPassword} ${
                isMobile && styles.forgotPasswordMobile
              }`}
              onClick={onClickForgotPassword}
            >
              Esqueci minha senha
            </span>
            <div className={styles.buttons}>             
                <Button color="secondary" type="submit">Login</Button>         
            </div>
          </form>
        </div>
      </div>
    </SaasLayoutAuth>
  );
};

export default SaasAuthPage;
