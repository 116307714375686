// Balance.tsx
import styles from "./Balance.module.scss";

interface BalanceProps {
    title: string;
    value: string;
    available?: boolean;
    last?: boolean;
    selected?: boolean;
    blocked?: boolean;
    retained?: boolean;
    release?: boolean;
}

const Balance = ({ title, value, available, last, selected, blocked, retained, release }: BalanceProps) => {
    let valueClass = styles.value; // Classe padrão

    if (available) {
        valueClass = styles.value_available;
    } else if (blocked) {
        valueClass = styles.value_blocked;
    } else if (retained) {
        valueClass = styles.value_retained;
    } else if (release) {
        valueClass = styles.value_release;
    }

    return (
        <div className={`${!last ? styles.container : ''} ${selected ? styles.selected : ''}`}>
            <p className={styles.title}>{title}</p>
            <p className={valueClass}>{value}</p>
        </div>
    );
};

export default Balance;
