import { FC } from "react";
import styles from "./EventDevices.module.scss";
import { useHistory } from 'react-router-dom';

const EventDevices: FC = () => {
  const history = useHistory();

  return (
    <div id={styles.EventDevices}>     
      <p>Você configurou seu estabelecimento para trabalhar como evento. <b>Para vincular seu equipamento, acesse o menu de evento</b></p>
      <button
        onClick={() => history.push('/private/eventos/list/eventos')}
        className={styles.changeButton}
      >
        <b>Ir para o menu de eventos</b>
      </button>
    </div>
  );
};

export default EventDevices;
