import { ISmartPrinterApi } from "modules/config/smartPrinterConfig/application/api/ISmartPrinterApi";
import { IGetPrinterListByLocalClienteIdResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetPrinterListByLocalClienteIdResponse";
import { IGetPrintersResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetPrintersResponse";
import { IGetProductListByLocalClienteIdResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetProductListByLocalClienteIdResponse";
import { IGetSmartPrinterByIdResponse, IGetSmartPrinterByLocalClienteIdItemResponse, IGetSmartPrinterByLocalClienteIdResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetSmartPrinterByLocalClienteIdResponse";
import { IGetTableListByLocalClienteIdResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetTableListByLocalClienteIdResponse";
import { IGetTotemListByLocalClienteIdResponse } from "modules/config/smartPrinterConfig/domain/dtos/IGetTotemListByLocalClienteIdResponse";
import { IPostAddSmartPrinterBylocalClienteIdRequest } from "modules/config/smartPrinterConfig/domain/dtos/IPostAddSmartPrinterBylocalClienteIdRequest";
import { IPostNewPrinterRequest } from "modules/config/smartPrinterConfig/domain/dtos/IPostNewPrinterRequest";
import { IPutEditSmartPrinterBylocalClienteIdRequest } from "modules/config/smartPrinterConfig/domain/dtos/IPutEditSmartPrinterBylocalClienteIdRequest";
import { api } from "services/api/Api";


const PrinterApi = (): ISmartPrinterApi => {

    const getSmartPrinterByLocalClenteId = async (localClienteId: string): Promise<IGetSmartPrinterByLocalClienteIdResponse> => {
        const response = await api.get<IGetSmartPrinterByLocalClienteIdResponse>(`/PrintingSector?localClienteId=${localClienteId}`);
        return (response.data);
    }
    const getSmartPrinterById = async (id: string): Promise<IGetSmartPrinterByIdResponse> => {
        const response = await api.get<IGetSmartPrinterByIdResponse>(`/PrintingSector/${id}`);
        return (response.data);
    }


    const getPrinterListByLocalClenteId = async (localClienteId: string): Promise<IGetPrinterListByLocalClienteIdResponse[]> => {
        const response = await api.get<IGetPrinterListByLocalClienteIdResponse[]>(`/PrintingSector/Printers?localClienteId=${localClienteId}`);
        return (response.data);
    }


    const getProductListByLocalClenteId = async (localClienteId: string): Promise<IGetProductListByLocalClienteIdResponse[]> => {
        const response = await api.get<IGetProductListByLocalClienteIdResponse[]>(`/PrintingSector/Products?localClienteId=${localClienteId}`);
        return (response.data);
    }

    const getTableListByLocalClenteId = async (localClienteId: string): Promise<IGetTableListByLocalClienteIdResponse[]> => {
        const response = await api.get<IGetTableListByLocalClienteIdResponse[]>(`/PrintingSector/Tables?localClienteId=${localClienteId}`);
        return (response.data);
    }

    const getTotemListByLocalClenteId = async (localClienteId: string): Promise<IGetTotemListByLocalClienteIdResponse[]> => {
        const response = await api.get<IGetTotemListByLocalClienteIdResponse[]>(`/PrintingSector/Totems?localClienteId=${localClienteId}`);
        return (response.data);
    }

    const deleteSmartPrinterById = async (id: string): Promise<void> => {
        await api.delete(`/PrintingSector/${id}`);
    }

    const addSmartPrinterByLocalClienteId = async (request: IPostAddSmartPrinterBylocalClienteIdRequest, localClienteId: string): Promise<IGetSmartPrinterByIdResponse> => {
        const response = await api.post<IGetSmartPrinterByIdResponse>(`/PrintingSector?localClienteId=${localClienteId}`, request);
        return response.data;
    }

    const editSmartPrinterByLocalClienteId = async (request: IPutEditSmartPrinterBylocalClienteIdRequest, localClienteId: string): Promise<IGetSmartPrinterByIdResponse> => {
        const response = await api.put<IGetSmartPrinterByIdResponse>(`/PrintingSector/${localClienteId}`, request);
        return response.data;
    }

    const getPrinters = async (localId: string): Promise<IGetPrintersResponse[]> => {
        const response = await api.get<IGetPrintersResponse[]>(`/Impressoras`, { params: { localClienteId: localId }});
        return response.data;
    }

    const postNewPrinter = async (request: IPostNewPrinterRequest): Promise<void> => {
        const response = await api.post<void>(`/Impressora`, request);
        return response.data;
    }

    const deletePrinter = async (printerId: string): Promise<void> => {
        const response = await api.delete<void>(`/Impressora/${printerId}`);
        return response.data;
    }

    return {
        getSmartPrinterByLocalClenteId,
        getSmartPrinterById,
        getPrinterListByLocalClenteId,
        getProductListByLocalClenteId,
        getTableListByLocalClenteId,
        getTotemListByLocalClenteId,
        deleteSmartPrinterById,
        addSmartPrinterByLocalClienteId,
        editSmartPrinterByLocalClienteId,
        getPrinters,
        postNewPrinter,
        deletePrinter
    }


}


export default PrinterApi


