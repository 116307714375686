import { IGetOrganizerEventParams } from "modules/meepTickets/domain/dto/IGetOrganizerEventParams";
import { IGetOrganizerEventResponse } from "modules/meepTickets/domain/dto/IGetOrganizerEventResponse";
import { IMeepTicketsService } from "modules/meepTickets/domain/interface/IMeepTicketsService";

const GetOrganizerEventUseCase = async (
  service: IMeepTicketsService,
  localId: string,
  page?: number,
  pageSize?: number,
  params?: IGetOrganizerEventParams,
  ): Promise<IGetOrganizerEventResponse> => {
    const response = service.getOrganizerEvent(localId, page || 0, pageSize || 10, params);
    return response;
};
export default GetOrganizerEventUseCase;
