import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { InputLabel } from './InputLabel';
import { Icon, IconButton } from '@material-ui/core';
import { ContainerLabel } from './ContainerLabel';

import styles from './ui.module.scss'
import { TextFieldProps } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';


export interface InputNumberProps extends Omit<TextFieldProps, keyof { onChange: any; value: number; }> {
    flex?: number;
    maxWidth?: number | string;
    onChange?: (ev: ChangeEvent<HTMLInputElement>, value: number) => void;
    value?: number;
    min?: number;
    max?: number;
    styledSize?: boolean;
    noLabel?:boolean;
}
export const InputNumber: FC<InputNumberProps> = (props) => {

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value.replace(/[^0-9]/g, "");
        const target = ev.target;
        const newEv: ChangeEvent<HTMLInputElement> = { ...ev, target: { ...target, name: target.name, value: value.toString() } };
        props.onChange?.(newEv, Number(value));
    }, [props]);

    const addHandle = useCallback(() => {
        let value = (Number(props.value) ?? 0) + 1;
        if (props.max && value > (props.max)) {
            value = (props.max ?? 0)
        }
        const newEv: ChangeEvent<HTMLInputElement> = { target: { name: props.name, value: value?.toString() } } as ChangeEvent<HTMLInputElement>;
        props.onChange?.(newEv, Number(value));
    }, [props]);

    const removeHandle = useCallback(() => {
        let value = (props.value ?? 0) - 1;
        if (value < (props.min ?? 0)) {
            value = (props.min ?? 0)
        }
        const newEv: ChangeEvent<HTMLInputElement> = { target: { name: props.name, value: value?.toString() } } as ChangeEvent<HTMLInputElement>;
        props.onChange?.(newEv, Number(value));
    }, [props]);

    useEffect(() => {
        if (props.min && props.value) {
            if (props.value < props.min) {
                const newEv: ChangeEvent<HTMLInputElement> = { target: { name: props.name, value: props.min?.toString() } } as ChangeEvent<HTMLInputElement>;
                props.onChange?.(newEv, props.min);
            }
        }
        if (props.max && props.value) {
            if (props.value > props.max) {
                const newEv: ChangeEvent<HTMLInputElement> = { target: { name: props.name, value: props.max?.toString() } } as ChangeEvent<HTMLInputElement>;
                props.onChange?.(newEv, props.max);
            }
        }
    }, [props])


    return <div id={styles.inputNumber}>
        <ContainerLabel maxWidth={200} label={props.label}>
            <div className={styles.counter}>
                <IconButton className={styles.button} disabled={(props.value === props.min) || props.disabled} size='small' color='secondary' onClick={removeHandle}><RemoveCircleOutline /></IconButton>
                <InputLabel
                    {...props}
                    label={undefined}
                    type='number'
                    maxWidth={undefined}
                    inputProps={{ min: 0, type: 'number', inputMode: 'numeric' }}
                    value={props?.value}
                    onChange={changeHandle}
                    style={{ ...props.style }}
                    styledSize={props.styledSize}
                    noLabel={props.noLabel}
                    counter
                />
                <IconButton className={styles.button} size='small' color='secondary' disabled={props.disabled} onClick={addHandle}><AddCircleOutline /></IconButton>
            </div>
        </ContainerLabel>
    </div>
};
