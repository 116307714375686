import { Checkbox } from "@mui/material";
import styles from "./PaymentTermsStep.module.scss";

export interface IPaymentTermsStepProps {
    acceptedTerms: boolean;
    setAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
}

const paymentTypes = [
    "/assets/img/visa-card.png",
    "/assets/img/master-card.png",
    "/assets/img/american-express.png",
]

const PaymentTermsStep = ({ acceptedTerms, setAcceptedTerms }: IPaymentTermsStepProps) => {
    return (
        <div id={styles.PaymentTermsStep}>
            <p className={styles.required} onClick={() => setAcceptedTerms(!acceptedTerms)}>Enviar solicitação para aprovação do pagamento online</p>
            <span>Habilite e gerencie suas configurações de processamento de pagamentos para começar a aceitar cartões de crédito e débito.</span>
            <div className={styles.paymentTypes}>
                {paymentTypes.map((item) => (
                    <img src={item} alt="" key={item} />
                ))}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms((prev) => !prev)}
                />
                <label className={styles.required}>
                    Ao continuar, você declara que concorda com os <a href="#">termos de uso</a>
                </label>
            </div>
        </div>
    )
};

export default PaymentTermsStep;