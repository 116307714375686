import Layout from "components/layout/presentation/Layout";
import MeepFoodPage from "./MeepFoodPage";

const MeepFood = () => {
  return (
    <Layout>
      <MeepFoodPage />
    </Layout>
  );
};

export default MeepFood;
