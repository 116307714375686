import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrmCampaignList from "../../components/crmCampaignList/CrmCampaignList";
import UseCampaignsList from "../../hook/UseCampaigns";
import UseCrmEventList from "../../hook/UseCrmEventList";
import { CampaignStatus, ICampaignItem } from "../../intefaces/ICampaign";
import styles from "./CrmCampaignPage.module.scss";
import { PremiumHint } from "modules/plans/presentation/components/premiumHint/PremiumHint";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { PremiumModal } from "modules/plans/presentation/components/premiumModal/PremiumModal";
import UseOpenSaasModal from "modules/plans/presentation/hooks/UseOpenSaasModal";
import Button from "components/ui/Button/Button";
import { Drawer } from "@mui/material";
import DrawerCampaignCreate from "../../components/crmFilterForm/components/Campaign/DrawerCampaignCreate/DrawerCampaignCreate";
export interface ICrmCampaignPageProps {
  //propertys
}
const CrmCampaignPage: FC<ICrmCampaignPageProps> = () => {
  const { getCampaignsList, campaignsList, isLoadingCampaignList } = UseCampaignsList();

  const { eventsList, getEventsList: getEvents } = UseCrmEventList();

  const { push } = useHistory()
  const { checkPermission } = usePlans();
  const { openSaasModal, setOpenSaasModal } = UseOpenSaasModal();

  const [openDrawer, setOpenDrawer] = useState(false);


  // const { whatsappStatus,isLoadingWhatsappStatus } = UseWhatsappConfiguration();
  
  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    getCampaignsList({ pagination: { page: 1, pageSize: 10 }});
  }, [getCampaignsList]);

  const onClickItemHandle = useCallback((item: ICampaignItem) => {
    if (item.status === CampaignStatus.Draft || item.status === CampaignStatus.Reproved || item.status === CampaignStatus.Pending) {
      push("/private/crm/campaign/edit/" + item.id)
    } else {
      push("/private/crm/campaign/report/" + item.id)
    }
  }, [push]);

  return (
    <div id={styles.CrmCampaignPage}>
      {/* <StatusWhatsapp absolute isLoadingWhatsappStatus={isLoadingWhatsappStatus} whatsappStatus={whatsappStatus} /> */}
      <div className={styles.container}>
        {/* <CreateCampaignComponent
          campaigns={campaignsList?.data ?? []}
          events={eventsList ? eventsList.data : []}
        /> */}
        {!checkPermission(FeatureTypeEnum.CRM) && <PremiumHint />}
        <header>
          <h1>Campanhas</h1>
          <p>Crie campanhas estratégicas e acompanhe seus resultados.</p>
          <Button
            onClick={() => setOpenDrawer(true)}
            endIcon={
              <img
                src="/assets/img/crown.png"
                alt="crown"
              />
            }
          >
            Criar campanha
          </Button>
        </header>
        <CrmCampaignList
          getData={getCampaignsList}
          campaignsData={campaignsList}
          isLoading={isLoadingCampaignList}
          onClickItem={onClickItemHandle}
        />
      </div>

      <PremiumModal opened={openSaasModal} onClose={() => setOpenSaasModal(false)} />

      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <DrawerCampaignCreate
          events={eventsList?.data ?? []}
          campaigns={campaignsList?.data ?? []}
          onClickCreatCampaignByFilter={() => setOpenDrawer(false)}
          onClose={() => setOpenDrawer(false)}
        />
      </Drawer>
    </div>
  );
};
export default CrmCampaignPage;
