import styles from './PrintQueuePage.module.scss'
import { UsePrintQueuePage } from './UsePrintQueuePage';
import { PrintQueueList } from './printQueueList/PrintQueueList';
import { PrintQueueFilter } from '../components/printQueueFilter/PrintQueueFilter';
import { Info } from '@mui/icons-material';

const PrintQueue = () => {

  const { 
    operatorList, 
    tableList, 
    printerList, 
    equipamentPrintQueueList, 
    isLoading, 
    onChangeFilterHandle, 
    onChangePaginationHandle, 
    params, 
    printQueueList, 
    setParams 
  } = UsePrintQueuePage();

  return (
    <div id={styles.PrintQueuePage}>

        <div className={styles.info}>
            <Info />
            <p>A fila de impressão é utilizada para monitorar as impressões realizadas por meio do <b> Meephub</b>.</p>
        </div>

        <PrintQueueFilter  
          equipamentPrintQueueList={equipamentPrintQueueList}
          printerList={printerList}
          onSubmit={onChangeFilterHandle}
          tableList={tableList}
          operatorList={operatorList}
        />

        <PrintQueueList
          printQueueList={printQueueList}
          isLoading={isLoading}
          onChangePaginationHandle={onChangePaginationHandle}
          params={params}
          setParams={setParams}
          pagination={params?.pagination}
        />
    </div>
  )
}

export default PrintQueue
