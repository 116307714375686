import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CreateGuestListUseCase from "modules/events/application/useCases/guests/CreateGuestListUseCase";
import { IGuestListForm } from "modules/events/presentation/components/guestListForm/interfaces/GuestListForm.types";
import { useHistory, useParams } from "react-router-dom";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";

interface IUseAddGuestList {
  handleAddList: (list: IGuestListForm) => Promise<void>;
  handleCancel: () => void;
}

export const UseAddGuestListPage = (): IUseAddGuestList => {
  const { eventId } = useParams<{ eventId: string }>();
  const { goBack } = useHistory();
  const { toast, showLoading, hideLoading } = useUi();
  const guestService = EventsGuestsApi();

  const handleAddList = async (list: IGuestListForm) => {
    showLoading();
    try {
      // await CreateGuestListUseCase(guestService, { ...list, eventId: eventId });
      goBack();
      toast("Lista criada com sucesso!", "success");
    } finally {
      hideLoading();
    }
  };

  const handleCancel = () => {
    goBack();
  };

  return {
    handleAddList,
    handleCancel,
  };
};
