import React, { FC, useCallback, useEffect } from "react"
import styles from "./TableFragment.module.scss"
import TableList, { ITableItem } from "./components/tableList/TableList";
import TableForm, { ITableFormValue } from "./components/tableForm/TablerForm";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { UseTableFragment } from "./hooks/UseTableFragment";
import { CircularProgress } from "@mui/material";
import Button from "components/ui/Button/Button";
import { TableBar } from "@mui/icons-material";
import { AddCircle } from "@material-ui/icons";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

export interface ITableFragmentProps {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    openAddTable: boolean;
    setOpenAddTable: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTableToEdit?: ITableFormValue;
    setSelectedTableToEdit: React.Dispatch<React.SetStateAction<ITableFormValue | undefined>>;
    selectedTableToDelete?: ITableItem;
    setSelectedTableToDelete: React.Dispatch<React.SetStateAction<ITableItem | undefined>>;
    setOpenTable: (value: React.SetStateAction<boolean | "add">) => void;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onClose: () => void;
}

const TableFragment: FC<ITableFragmentProps> = ({
    setTitle,
    openAddTable,
    setOpenAddTable,
    selectedTableToEdit,
    setSelectedTableToEdit,
    selectedTableToDelete,
    setSelectedTableToDelete,
    setOpenTable,
    error,
    setError,
    onClose
}) => {
    const {
      isLoading,
      getTables,
      tableList,
      onSubmitAdd,
      onSubmitEdit,
      onSubmitDelete,
      onChangeStatus,
      createMoreThanOneTable,
    } = UseTableFragment();

    const { toast } = useUi();

    useEffect(() => {
        getTables();
    }, [getTables]);

    const onClickAdd = () => {
        setOpenAddTable(true);
    }

    const tableItemToValue = (table: ITableItem): ITableFormValue => {
        return {
            id: table.id,
            number: table.number,
            status: table.status
        }
    }

    const onClickEdit = (table: ITableItem) => {
        setSelectedTableToEdit(tableItemToValue(table))
    }

    const onClickDelete = (table: ITableItem) => {
        setSelectedTableToDelete(table)
    }

    const onSubmitAddTable = useCallback(async (value: ITableFormValue) => {
        try {
          await onSubmitAdd(value);
          setOpenAddTable(false);
          getTables();
        } catch (error: any) {
          if (error.response?.data.includes("já existe")) {
            setError("Já existe uma mesa com esse número.");
          }
        }
    }, [getTables, onSubmitAdd, setError, setOpenAddTable]);

    const onSubmitAddMoreTable = useCallback(async (firstTable: number, lastTable: number) => {
      try {
        await createMoreThanOneTable(lastTable, firstTable);
        setOpenAddTable(false);
        getTables();
      } catch (error: any) {
        if (error.response?.data.includes("já existe")) {
          setError("Ocorreu um erro. Tente novamente.");
        }
      }
  }, [createMoreThanOneTable, getTables, setError, setOpenAddTable]);
    
    const onSubmitEditTable = useCallback(async (value: ITableFormValue) => {
        if (selectedTableToEdit) {
            try {
                await onSubmitEdit(value);
                setSelectedTableToEdit(undefined);
                getTables();
            } catch (error: any) {
                if (error.response?.data.includes("já existe")) {
                    setError("Já existe uma mesa com esse número.");
                }
            }
        }
    }, [getTables, onSubmitEdit, selectedTableToEdit, setError, setSelectedTableToEdit])

    const onSubmitDeleteTable = useCallback(async () => {
        if (selectedTableToDelete) {
            try {
                await onSubmitDelete(selectedTableToDelete);
                setSelectedTableToDelete(undefined);
                getTables();
              } catch (error: any) {
                toast(error?.response?.data.includes("Não é possivel excluir uma comanda com histórico de registros ou transações") 
                  ? "Não é possível excluir uma mesa com consumo em aberto." 
                  : error?.response?.data,
                "error");
            }
        }
    }, [getTables, onSubmitDelete, selectedTableToDelete, setSelectedTableToDelete, toast]);
    
    const onChangeTableStatus = useCallback(async (table: ITableItem, checked: boolean) => {
        try {
            await onChangeStatus(table, checked);
            getTables();
        } finally {}
    }, [getTables, onChangeStatus]);

    const handleGoBack = useCallback(() => {
      setTitle("Mesas");
      setError("");
      setOpenAddTable(false);
      setSelectedTableToEdit(undefined);
      setSelectedTableToDelete(undefined);
    }, [setError, setOpenAddTable, setSelectedTableToDelete, setSelectedTableToEdit, setTitle]);


    if (openAddTable) {
        setTitle("Nova mesa");
        return (
          <TableForm
            isLoading={isLoading}
            onSubmit={onSubmitAddTable}
            handleGoBack={handleGoBack}
            error={error}
            setError={setError}
            onSubmitAddMoreTable={onSubmitAddMoreTable}
          />
        );
    }

    if (!!selectedTableToEdit) {
        setTitle("Editar mesa");
        return (
          <TableForm
            isLoading={isLoading}
            defaultValue={selectedTableToEdit}
            onSubmit={onSubmitEditTable}
            handleGoBack={handleGoBack}
            error={error}
            setError={setError}
          />
        );
    }

    if (!!selectedTableToDelete) {
        setTitle("Excluir mesa");
        return (
            <div className={styles.deleteTable}>
                <SidesheetFeedback
                    text={<span>Deseja realmente excluir a mesa?{<br />}Essa ação não poderá ser desfeita.</span>}
                />
                <div className={styles.footerButtons}>
                    <Button variant="outlined" onClick={handleGoBack}>Voltar</Button>
                    <Button disabled={isLoading} onClick={onSubmitDeleteTable}>
                      {isLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        "Excluir"
                      )}
                    </Button>
                </div>
            </div>
        );
    }

    return (
      <div id={styles.TableFragment}>
        <div className={styles.content}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClickAdd}
            startIcon={<AddCircle />}
          >
            Nova mesa
          </Button>
          <div className={styles.description}>
            <TableBar fontSize="large" />
            <p>
              Essas são as mesas do seu estabelecimento. Elas estarão estarão visíveis no seu terminal de vendas para lançar pedidos.
            </p>
          </div>
          <TableList
            isLoading={isLoading}
            tableList={tableList}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            onChageStatus={onChangeTableStatus}
          />
        </div>
        <div className={styles.footerButtons}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          {/* <Button disabled={isLoading} onClick={() => setOpenTable(false)}>
            {isLoading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              "Salvar"
            )}
          </Button> */}
        </div>
      </div>
    );


}
export default TableFragment;
