import { IPostNewWizard } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";

export interface ITutorialFormErrors {
  Title?: string;
  Description?: string;
  ImagePath?: string;
  MenuId?: string;
  PageWizardItems: (string | undefined)[];
  SystemIdentifier?: string;
}

export const TutorialValidation = (formData: IPostNewWizard, setErrors: React.Dispatch<React.SetStateAction<ITutorialFormErrors>>) => {
  const PageWizardItems: (string | undefined)[] = [];

  if (formData.PageWizardItems && formData.PageWizardItems.length > 0) {
    formData.PageWizardItems.forEach(() => {
      PageWizardItems.push(undefined);
    });
  }

  let _errors: ITutorialFormErrors = { PageWizardItems: PageWizardItems };
  let hasError = false;

  if (!formData.Title) {
    _errors = { ..._errors, Title: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.Description) {
    _errors = { ..._errors, Description: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.ImagePath) {
    _errors = { ..._errors, ImagePath: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.MenuId) {
    _errors = { ..._errors, MenuId: "Campo obrigatório" };
  }
  if (!formData.SystemIdentifier) {
    _errors = { ..._errors, SystemIdentifier: "Campo obrigatório" };
  }
  if (formData.PageWizardItems && formData.PageWizardItems.length > 0) {
    formData.PageWizardItems.forEach((item, index) => {
      if (!item.Title) {
        PageWizardItems[index] = "Campo obrigatório";
      }
    });
    const pageWizardItemsErrors = PageWizardItems.find((it) => it === "Campo obrigatório");
    if (pageWizardItemsErrors) {
      _errors = { ..._errors, PageWizardItems: PageWizardItems };
      hasError = true;
    }
  }

  setErrors(_errors);

  return !hasError;
};
