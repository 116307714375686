import { format } from 'date-fns';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { ICustomersService } from 'modules/customers/application/interfaces/ICustomersService';
import GetCustomersListUseCase from 'modules/customers/application/useCases/GetCustomersListUseCase';
import GetEventsListUseCase from 'modules/customers/application/useCases/GetEventsListUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { createContext, FC, useState, useCallback, useEffect } from 'react';
import { v4 } from 'uuid';
import { IGetCustomersRequest } from "../../domain/dtos/IGetCustomersRequest";
import { ICustomerFilter } from '../interfaces/ICustomerFilter';
import { ICustomerItem } from '../interfaces/ICustomerItem';


export const UseCustomersPage = () => {
    const [customersData, setCustomersData] = useState<ICustomerItem[]>();
    const { locals, currentLocal } = useLocal();
    const [events, setEvents] = useState<{ name: string, id: string }[]>([]);
    const [filter, setFilter] = useState<ICustomerFilter>({
        locals: [],
        events: [],
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd"),
    });
    const [pagination, setPagination] = useState<{ page: number, size: number }>({ page: 1, size: 10 });

    const [length, setLength] = useState<number>();

    useEffect(() => {
        setFilter({
            locals: currentLocal?[currentLocal]: [],
            events: [],
            start: format(new Date(), "yyyy-MM-dd"),
            end: format(new Date(), "yyyy-MM-dd"),
        });
    }, [currentLocal])

    const onChangeFilter = useCallback((_filter: ICustomerFilter) => {
        setFilter(_filter);
    }, [])

    useEffect(() => {
        const customerService = {} as ICustomersService
        GetCustomersListUseCase(customerService, filter, pagination).then(response => {
            setCustomersData(response.customers);
            setLength(response.length);
        })
    }, [filter, pagination])

    useEffect(() => {
        const customerService = {} as ICustomersService
        GetEventsListUseCase(customerService, filter.locals).then(response => {
            setEvents(response)
        })
    }, [filter]) 

    return (
        {
            customersData,
            locals,
            events,
            pagination,
            setPagination,
            length,
            filter,
            onChangeFilter
        }
    );
};









