import { IGetExportProductListResponse } from "modules/products/domain/dto/IGetExportProductListResponse";
import { IProductsService } from "modules/products/domain/interfaces/IProductsService"
import { IExportProductListRequest } from "modules/products/domain/models/product/IExportProductList";

const GetExportProductListUseCase = async (service: IProductsService, localId: string, filter: IExportProductListRequest): Promise<IGetExportProductListResponse> => {
    const response = await service.getExportProductList(
        localId,
        {
            categoriesIds: filter.categoriesIds,
            keyword: filter.keyword,
            extension: filter.extension,
        });
    return response
}
export default GetExportProductListUseCase