import GetTotalReportUseCase from "modules/crm/application/useCases/plans/GetTotalReportUseCase";
import { ITotalReport } from "modules/crm/presentation/intefaces/TotalReport";
import { ChannelEnum } from "modules/crm/presentation/interfaces/ChannelEnum";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetSubscriptionUseCase } from "modules/plans/application/GetSubscriptionUseCase";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFeatures } from "modules/plans/presentation/interfaces/IFeatures";
import { useEffect, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import PlansApi from "services/api/plans/PlansApi";

const service = CrmServiceApi();
const plansService = PlansApi();

export const UsePushDetails = () => {
    const [data, setData] = useState<ITotalReport>();
    const [loading, setLoading] = useState(true);
    const [featurePush, setFeaturePush] = useState<IFeatures>();

    const { currentLocal } = useLocal();

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    setLoading(true);

                    const response = await GetTotalReportUseCase(service, currentLocal.id, ChannelEnum.Push);

                    if (response) setData(response[0]);
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [currentLocal]);

    useEffect(() => {
        (async () => {
          if (currentLocal === null) return;
          const subscription = await GetSubscriptionUseCase(plansService, currentLocal!.id);
          const pushFeature = subscription?.additionalFeatures?.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH);
          setFeaturePush(pushFeature);
        })();
    }, [currentLocal])

    return {
        loading,
        data,
        featurePush
    }
}
