import { IGuestRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IGuestListToMigrationResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const AddGuestsToListUseCase = async (
  guestsService: IEventsGuestsApi,
  guests: IGuestRequest,
  localId: string,
): Promise<IGuestListToMigrationResponse> => {
  return await guestsService.addGuestsToList(guests, localId);
};

export default AddGuestsToListUseCase;
