import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import React, { FC, forwardRef, RefAttributes } from "react";
import Utils from "services/utils/Utils";
import { ICloseEvent } from "../ICloseEvent";
import styles from "./CloseHeader.module.scss";
import { CloseEvent } from "./components/closeEvent/CloseEvent";
import { Export } from "./components/export/Export";
import UseCloseHeader from "./UseCloseHeader";
import { Download } from "@mui/icons-material";

interface ICloseHeaderProps {
  event: ICloseEvent;
  componentRef: React.MutableRefObject<HTMLDivElement | null>;
  handleShowButtons: (show: boolean) => void;
  handleShowDetails: (show: boolean) => void;
  handleShowResume: (show: boolean) => void;
  handleShowSystemType: (show: boolean) => void;
  handleShowTickets: (show: boolean) => void;
  isExport?: boolean;
}

export const CloseHeader: FC<
  ICloseHeaderProps & RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, ICloseHeaderProps>(
  (
    {
      event,
      componentRef,
      isExport,
      handleShowButtons,
      handleShowDetails,
      handleShowResume,
      handleShowSystemType,
      handleShowTickets,
    },
    ref
  ) => {
    const {
      openEdit,
      closeEventModal,
      openEditModal,
      closeExportModal,
      openExport,
      openExportModal,
      isClosed,
      setIsClosed,
    } = UseCloseHeader(
      handleShowButtons,
      handleShowDetails,
      handleShowResume,
      handleShowSystemType,
      handleShowTickets
    );

    return (
      <div id={styles.ManageHeader} ref={ref}>
        <div className={styles.headerTitle}>
          <h1>{event.eventName}</h1>
          <div>
            {!isExport && (
              <Button
                variant="text"
                fullWidth={false}
                onClick={openExportModal}
                endIcon={<Download />}
                color="default"
              >
                Exportar
              </Button>
            )}
            {(event.eventClosed || isClosed) && (
              <div className={styles.closedEventheader}>Evento fechado</div>
            )}

            {!event.eventClosed && !isClosed && (
              <div className={styles.closeEventbutton}>
                <Button fullWidth={false} onClick={openEditModal}>
                  Fechar evento
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.headerBody}>
          <p>{`Data do caixa: ${
            event.eventEndDate ? Utils.toDate(new Date(event.eventEndDate)) : ""
          }`}</p>
        </div>

        <RightDrawer
          title="Fechar evento"
          open={openEdit}
          onClose={closeEventModal}
        >
          <CloseEvent
            financialPending={event.eventFinancialPending}
            closeModal={closeEventModal}
            isClosed={setIsClosed}
          />
        </RightDrawer>
        <RightDrawer
          title="Exportar em PDF"
          open={openExport}
          onClose={closeExportModal}
        >
          <Export
            closeExportModal={closeExportModal}
            componentRef={componentRef}
            handleShowDetails={handleShowDetails}
            handleShowResume={handleShowResume}
            handleShowSystemType={handleShowSystemType}
            handleShowTickets={handleShowTickets}
          />
        </RightDrawer>
      </div>
    );
  }
);
