import { Skeleton } from '@mui/material';
import React, { FC, memo } from 'react';
import { IGenreValues, IGuestListItem } from '../IGuestItem';
import styles from './GestListItem.module.scss';

export interface IGestListItemProps {
    gestItem: IGuestListItem | null;
    loading?: boolean;
}
const GestListItem: FC<IGestListItemProps> = memo(({ gestItem, loading }) => {
    if (loading) {
        return (
            <div id={styles.GestList}>
                <div className={styles.colunm}>
                    <Skeleton variant='text' />
                </div>
                <div className={styles.colunm}>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                </div>
                <div className={styles.colunm}>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                </div>
                <div className={styles.colunm}>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                </div>
                <div className={styles.colunm}>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                </div>
            </div>
        )
    }

    const GenerateColumn = (label: string, item: IGenreValues, format?: boolean, title?: string) => {
        if (item)
            return (
                <div className={styles.colunm}>
                    <div className={styles.row}>
                        <span> Masculino</span> <span>{format ? formatCurrency(item?.male) : item?.male}</span>
                    </div>
                    <div className={styles.row}>
                        <span> Feminino</span> <span>{format ? formatCurrency(item?.female) : item?.female}</span>
                    </div>
                    <div className={styles.row}>
                        <span> Não informado</span> <span>{format ? formatCurrency(item?.unknown) : item?.unknown}</span>
                    </div>
                    {item.average ?
                        <div className={styles.total}>
                            <span> {title ?? "Total"} </span> <span>{format ? formatCurrency(item.average) : (item.average)}</span>
                        </div>
                        :
                        <div className={styles.total}>
                            <span> {title ?? "Total"} </span> <span>{format ? formatCurrency(item?.female + item?.male + item?.unknown) : (item?.female + item?.male + item?.unknown)}</span>
                        </div>
                        }
                </div>
            )
        return null
    }

    if (gestItem)
        return (

            <div id={`${styles.GestList}`} className={`${!gestItem?.isActive ? styles.inactive : ''}`}>
                <div className={styles.colunm}>
                    {gestItem?.name}
                    {!gestItem?.isActive && (
                        <div className={styles.inactiveTag}><div /> Inativa</div>
                    )}
                </div>
                {GenerateColumn('Convidados', gestItem.quantity)}
                {GenerateColumn('Vendas', gestItem.sold)}
                {GenerateColumn('Check-in', gestItem.checkin)}
                {GenerateColumn('Ticket Médio', gestItem.averageTicket, true, "Média")}
            </div>
        );

    return <></>
});

export default GestListItem;

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value);
