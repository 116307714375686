import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import RemoveSelectedLocalsUseCase from 'modules/dashboard/application/useCases/RemoveSelectedLocalsUseCase';
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import LocalService from 'services/api/local/LocalService';
import { DashboardSelectedLocals } from 'services/repository/dashboard/DashboardSelectedLocals';
import GetLocalsListUseCase from '../application/useCases/GetLocalsListUseCase';
import { useLocal } from './context/LocalContext';
import { ILocalList, ILocalItem } from './interfaces/ILocalResume';
import { OnboardingApi } from 'services/api/onboarding/OnboardingApi';
import { CheckProductListUseCase } from 'modules/onboarding/application/useCases/CheckProductListUseCase';
import { CheckTeamUseCase } from 'modules/onboarding/application/useCases/CheckTeamUseCase';
import { useUser } from 'modules/user/domains/presentation/contexts/UserContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { api } from 'services/api/Api';
import { ICheckOnboarding } from 'modules/onboarding/presentation/interfaces/ICheckOnboarding';
import { IReponseOperacoes } from 'modules/gestaoVendas/application/interfaces/IGestaoVendasService';
import GetOperacoes from 'modules/gestaoVendas/application/useCases/GetOperacoesUseCase';
import { GestaoVendasApi } from 'services/api/gestaoVendas/gestaoVendasApi';

export interface ILocalFilter {
    page: number,
    pageSize: number,
    search?: string
}
const service = OnboardingApi();
const gestaoService = GestaoVendasApi();

const UseLocalPage = () => {
    const { updateChangeCurrentLocal } = useLocal()

    const [filter, setFilter] = useState<ILocalFilter>({ page: 1, pageSize: 12, search: '' });
    const [locals, setLocals] = useState<ILocalList>();
    const [isLoading, setIsLoading] = useState(false);

    const { showLoading, hideLoading } = useUi();
    const { isAuth } = useAuth()
    const { user } = useUser();
    const { push } = useHistory();

    const onChangePage = useCallback((page: number) => {
        setFilter(prev => ({ ...prev, page }))
    }, []);

    const onClickItemHandle = useCallback(async (item: ILocalItem) => {
        try {
            updateChangeCurrentLocal(item);
            api.defaults.headers["X-Owner-Id"] = item.id;
            RemoveSelectedLocalsUseCase(DashboardSelectedLocals);
            const promises = [
                CheckProductListUseCase(service, item.id),
                CheckTeamUseCase(service, item.id),
                GetOperacoes(gestaoService, item.id),
            ];

            const response = await Promise.all(promises);

            const showOnboardingData = localStorage.getItem('@showOnboarding');

            const showOnboardingObj: { localId: string, userId: string, status: string }[] = JSON.parse(showOnboardingData ?? '[]');

            if (item.systemIdentifier === 1) {
                push("/");
                return;
            }

            if (((response[0] as ICheckOnboarding).isCompleted && 
                 (response[1] as ICheckOnboarding).isCompleted && 
                 ((response[2] as IReponseOperacoes).Id ?? '') !== '') || showOnboardingObj.find(storageItem => item.id === storageItem.localId && storageItem.userId === user?.id)?.status) {
                localStorage.setItem('@showOnboarding', JSON.stringify([...showOnboardingObj, { localId: item.id, userId: user?.id, status: true }]));
                
                push("/private/dashboard/general");
            } else {
                localStorage.setItem('@showOnboarding', JSON.stringify([...showOnboardingObj, { localId: item.id, userId: user?.id, status: false }]));
                push('/private/onboarding');
            }
        } catch (err) {
            push("/");
        }
    }, [push, updateChangeCurrentLocal, user?.id])

    const getLocals = useCallback(
        async (_filter: ILocalFilter) => {
            try {
                showLoading()
                setIsLoading(true);
                const service = LocalService();
                const response = await GetLocalsListUseCase(service, _filter);
                if (response.items.length === 1 && _filter.search === '') {
                    await onClickItemHandle(response.items[0]);
                    return;
                }
                setLocals(response);


            } finally {
                hideLoading();
                setIsLoading(false);
            }
        },
        [hideLoading, onClickItemHandle, showLoading],
    );

    useEffect(() => {
        if (isAuth) {
            getLocals(filter);
        }
    }, [filter, getLocals, isAuth])


    const onSubmitFilterHandle = useCallback((values: { search: string }) => {
        setFilter(prev => ({ ...prev, search: values.search, page: 1 }))
    }, [])

    return ({ isLoading, locals, onClickItemHandle, filter, setFilter, onChangePage, onSubmitFilterHandle })
}

export default UseLocalPage