import { request } from "services/api/Api";

import { GetExportSalesReportProps } from "../types/GetExportSalesReport.types";

const GetExportSalesReport = async (props: GetExportSalesReportProps) => {
  return await request<any>({
    url: "Reports/Sales/Export",
    params: {
      ...props,
    },
  });
};

export { GetExportSalesReport };
