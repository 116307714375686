import { IServiceItemReserve } from "../../../presentation/components/reserveScheduleForm/interfaces/IServiceSchedule";
import { IReserveScheduleApiCB } from "../../interfaces/api/IReserveScheduleApi";

export const GetServicesByLocalIdUseCase = async (localId: string, serviceScheduleApi: IReserveScheduleApiCB): Promise<IServiceItemReserve[]> => {
    const response = await serviceScheduleApi().getServicesByLocalId(localId);
    return response.items.flatMap(item => item.services).map((item) => ({
        id: item.id,
        name: item.name,
        productId: item.productId,
        categoryName: "",
    }))
}
