import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "components/ui/Button/Button";
import styles from "./MenuConfigPage.module.scss";
import { AddCircleOutlined, DeleteOutlined, EditOutlined, KeyboardArrowDownRounded, Settings } from "@material-ui/icons";
import TableHeader from "../components/tableHeader/TableHeader";
import { IconButton } from "@mui/material";
import TableItem from "../components/tableItem/TableItem";
import AddSystemSidesheet from "../components/addSystemSidesheet/AddSystemSidesheet";
import { UseMenuConfigPage } from "../hooks/UseMenuConfigPage";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import AddMenuSidesheet from "../components/addMenuSidesheet/AddMenuSidesheet";
import { IGetSystemConfigByIdResponse } from "../../domain/dto/IGetSystemConfigByIdResponse";

const MenuConfigPage = () => {
    const [showMoreInfo, setShowMoreInfo] = useState<string[]>([]);
    const [openAddSystemSidesheet, setOpenAddSystemSidesheet] = useState<"isNew" | "isEditing" | null>(null);
    const [openDeleteSidesheet, setOpenDeleteSidesheet] = useState(false);
    const [deleteConfigStep, setDeleteConfigStep] = useState(1);
    const [deleteSystemItem, setDeleteSystemItem] = useState("");
    const [openMenuConfigSidesheet, setOpenMenuConfigSidesheet] = useState(false);

    const {
        isLoading,
        systemConfigList,
        getSystemConfigList,
        systemConfigById,
        getSystemConfigById,
        deleteSystemConfig,
    } = UseMenuConfigPage();

    useEffect(() => {
        getSystemConfigList();
    }, [getSystemConfigList]);

    const systemConfigListData = useMemo(() => systemConfigList ?? [], [systemConfigList]);
    const systemConfigByIdData = useMemo(() => systemConfigById ?? {} as IGetSystemConfigByIdResponse, [systemConfigById]);

    const handleShowMoreInfo = useCallback(async (systemId: string) => {
        if (showMoreInfo.includes(systemId)) {
            const filterInfo = showMoreInfo.filter((item) => item !== systemId);
            return setShowMoreInfo(filterInfo);
        }
        setShowMoreInfo([...showMoreInfo, systemId])
    }, [showMoreInfo]);

    const handleOpenEditSystem = useCallback(async (systemId: string) => {
        await getSystemConfigById(systemId);
        setOpenAddSystemSidesheet("isEditing");
    }, [getSystemConfigById]);

    const handleDeleteSystem = useCallback(async (systemId: string) => {
        setOpenDeleteSidesheet(true);
        setDeleteSystemItem(systemId);
    }, []);

    const handleContinueDelete = useCallback(async () => {
        const res = await deleteSystemConfig(deleteSystemItem);
        if (res === "ok") {
            setDeleteConfigStep(deleteConfigStep + 1);
            getSystemConfigList();
        }
    }, [deleteConfigStep, deleteSystemConfig, deleteSystemItem, getSystemConfigList]);

    const handleSystemConfig = useCallback(async (systemId: string) => {
        await getSystemConfigById(systemId);
        setOpenMenuConfigSidesheet(true);
    }, [getSystemConfigById]);

    return (
        <div id={styles.MenuConfigPage}>
            <div className={styles.pageTitle}>
                <h1>Portais Meep</h1>
                <span>Configure o portal com os menus que deseja e associe planos aos portais criados.</span>
            </div>
            <Button
                className={styles.addConfigBtn}
                endIcon={<AddCircleOutlined />}
                onClick={() => setOpenAddSystemSidesheet("isNew")}
            >
                Criar configurações de sistema
            </Button>

            <div>
                <TableHeader />
                <div className={styles.content}>
                    {!!systemConfigListData.length && systemConfigListData.map((system) => (
                        <div className={styles.accordion} key={system.id}>
                            <div className={styles.accordionHeader}>
                                <span>{system.name}</span>
                                <span>{system.identifier}</span>
                                <span>{system.menuQuantity}</span>
                                <span>{system.plansQuantity}</span>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    endIcon={system.menus.length ? <Settings /> : <AddCircleOutlined />}
                                    onClick={() => handleSystemConfig(system.id)}
                                >
                                    {system.menus.length ? "Gerenciar menus" : "Adicionar menus"}
                                </Button>
                                <div>
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleOpenEditSystem(system.id)}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleDeleteSystem(system.id)}
                                    >
                                        <DeleteOutlined />
                                    </IconButton>
                                    <IconButton
                                        disableRipple
                                        onClick={() => handleShowMoreInfo(system.id)}
                                        style={{ transform: showMoreInfo.includes(system.id) ? "rotate(-180deg)" : "rotate(0deg)" }}
                                    >
                                        <KeyboardArrowDownRounded />
                                    </IconButton>
                                </div>
                            </div>
                            {showMoreInfo.includes(system.id) && !!system.menus && system.menus.map((item) => (
                                <TableItem item={item} key={item.icon} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {openAddSystemSidesheet && (
                <AddSystemSidesheet
                    openAddSystemSidesheet={openAddSystemSidesheet}
                    setOpenAddSystemSidesheet={setOpenAddSystemSidesheet}
                    editItem={systemConfigByIdData}
                    getSystemConfigList={getSystemConfigList}
                />
            )}

            {openDeleteSidesheet && (
                <Sidesheet
                    isLoading={isLoading}
                    open={openDeleteSidesheet}
                    onClose={() => setOpenDeleteSidesheet(false)}
                    title={
                        <h2>
                            Excluir configuração de <b>sistema</b>
                        </h2>
                    }
                    content={<SidesheetFeedback text="Essa ação não poderá ser desfeita!" />}
                    currentStep={deleteConfigStep}
                    totalSteps={2}
                    continueButton="Excluir"
                    handleContinueButton={handleContinueDelete}
                    cancelButton
                    feedback={
                        <SidesheetFeedback text="Sistema excluído com sucesso!" success />
                    }
                />
            )}

            {openMenuConfigSidesheet && (
                <AddMenuSidesheet
                    openMenuSidesheet={openMenuConfigSidesheet}
                    setOpenMenuSidesheet={setOpenMenuConfigSidesheet}
                    systemConfig={systemConfigByIdData}
                    getSystemConfigList={getSystemConfigList}
                />
            )}
        </div>
    )
}

export default MenuConfigPage;