import Layout from "components/layout/presentation/Layout";
import { AddServicePage } from "modules/schedule/presentation/pages/addService/_AddServicePage";
import React, { FC } from "react";

const AddServicesSchedule: FC = () => {
  return (
    <Layout>
      <AddServicePage />
    </Layout>
  );
};
export default AddServicesSchedule;
