import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import CatalogForm, { ICatalogFormValue } from '../../componentes/catalogListForm/CatalogForm';
import ContainerDrawer from '../../componentes/ui/ContainerDrawer';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetAllProductsUseCase from 'modules/catalog/application/useCases/GetAllProductsUseCase';
import { IProductItem } from '../../componentes/categoryAndProducts/productList/IProductItem';
import UseQueryParams from '../../hooks/UseQueryParams';
import GetCatalogByIdUseCase from 'modules/catalog/application/useCases/GetCatalogByIdUseCase';
import { useHistory, useParams } from 'react-router-dom';
import AddCatalogUseCase from 'modules/catalog/application/useCases/AddCatalogUseCase';
import EditCatalogUseCase from 'modules/catalog/application/useCases/EditCatalogUseCase';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import CatalogService from 'services/api/catalog/CatalogService';
export interface ICatalogListFragmentProps {
    //propertys
    onClose: () => void;
    reloadCatalogList: () => void

}

const service = CatalogService();
const CatalogFormFragment: FC<ICatalogListFragmentProps> = ({ onClose, reloadCatalogList }) => {


    const [defaultValues, setDefaultValues] = useState<ICatalogFormValue>();
    const { currentLocal } = useLocal();
    const [products, setProducts] = useState<IProductItem[]>([]);

    const quaeryParams = UseQueryParams();
    const action = useMemo(() => quaeryParams.get("action"), [quaeryParams]);
    const [title, setTitle] = useState(<div>Editar <b>Cardapio</b></div>);


    const { showLoading, hideLoading, toast } = useUi();
    const { catalogId } = useParams<{ catalogId: string }>();
    const { push } = useHistory();
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);

    const submitHandle = useCallback(async (values: ICatalogFormValue) => {
        if (currentLocal) {
            try {
                showLoading();
                if (action === "editCatalog" && catalogId) {
                    await EditCatalogUseCase(service, currentLocal.id, catalogId, values);

                } else {
                    await AddCatalogUseCase(service, currentLocal.id, values);
                }
                push(`/private/catalog/${catalogId}`);
                reloadCatalogList();
                toast("Cardápio adicionado com sucesso!", "success");
            } finally {
                hideLoading();
            }
        }
    }, [action, catalogId, currentLocal, hideLoading, push, reloadCatalogList, showLoading, toast])

    const getProducts = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoadingProducts(true);
                const response = await GetAllProductsUseCase(service, currentLocal.id);
                setProducts(response);
            } finally {
                setIsLoadingProducts(false);
            }
        }
    }, [currentLocal])

    const getDefault = useCallback(async (catalogId: string) => {
        if (currentLocal) {
            try {
                if (!defaultValues) {
                    showLoading()
                    const response = await GetCatalogByIdUseCase(service, currentLocal.id, catalogId);
                    setDefaultValues(response);
                }
            } finally {
                hideLoading();
            }
        }
    }, [currentLocal, defaultValues, hideLoading, showLoading])


    useEffect(() => {
        getProducts();
    }, [getProducts])

    useEffect(() => {
        if (action === "editCatalog" && catalogId) {
            getDefault(catalogId);
        }
    }, [action, catalogId, getDefault, quaeryParams])

    const onChangeStepHnadle = useCallback((step: "products" | "detail" | "discount") => {
        switch (step) {
            case "detail":
                setTitle(<div>Editar <b>cardápio</b></div>)
                break;
            case "discount":
                setTitle(<div>Aplicar <b> desconto</b></div>)
                break;
            case "products":
                setTitle(<div>Selecionar <b> produtos</b></div>)
                break;
        }
    }, []);
    
    return (
        <ContainerDrawer
            onClose={onClose}
            title={<div>{action === "editCatalog" && catalogId ? title : <div>Novo <b> cardápio</b></div>}</div>}>
            <CatalogForm
                isLoadingProducts={isLoadingProducts}
                onClose={onClose}
                defaultValues={defaultValues}
                onSubmit={submitHandle}
                products={products}
                onChangeStep={onChangeStepHnadle}
            />
        </ContainerDrawer>
    )
}
export default CatalogFormFragment