import { z } from "zod"


export interface IInventoryFormValue {
    description: string,
    supplyId?: string,
    supplyGroupId?: string
    storageLocationId?: string
    includeAllSupplies?: boolean;
    filterType?: 'supply' | 'supplyGroup' | 'supplyStorageLocals' | 'all';
}

export const InventorySchema = z.object({
    id: z.string().optional(),
    description: z.string(),
    supplyId: z.string().optional(),
    supplyGroupId: z.string().optional(),
    storageLocationId: z.string().optional(),
    filterType: z.string().optional(),
    includeAllSupplies: z.boolean().optional()
}).superRefine((data, ctx) => {
    if (data.filterType === "supply") {
        if (!data.supplyId) {
            return ctx.addIssue({
                code: "custom",
                path: ["supplyId"],
                message: "Insumo é obrigatório"
            })
        }
    } else if (data.filterType === "supplyGroup") {
        if (!data.supplyGroupId) {
            return ctx.addIssue({
                code: "custom",
                path: ["supplyGroupId"],
                message: "Grupo de insumo é obrigatório"
            })
        }
    } else if (data.filterType === "supplyStorageLocals") {
        if (!data.storageLocationId) {
            return ctx.addIssue({
                code: "custom",
                path: ["storageLocationId"],
                message: "Local de estoque é obrigatório"
            })
        }
    }
    return data;
});