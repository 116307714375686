import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { DiscountCouponsPage } from "modules/config/discountCoupons/presentation/DiscountCouponsPage";
import { SmartPrinterPage } from "modules/config/smartPrinterConfig/presentation/pages/SmartPrinterPage";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import React, { FC, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabPage from "../dashboard/tabPage/TabPage";

export interface IConfigurationPageProps {
  //propertys
}

export interface IMenuItem {
  title: string;
  tab: string;
  role?: string;
  value?: string;
  component?: React.ReactNode;
  subItems?: {
    label: string;
    tab: string;
    url?: string;
    role: string;
    component?: React.ReactNode;
  }[];
}

const ConfigurationPage: FC<IConfigurationPageProps> = () => {
  const { tab, subTab } = useParams<{ tab: string; subTab: string }>();
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    const currentTab = menuItems.find((item) => tab === item.tab);
    const routers = [
      {
        title: "Configurações",
      },
      {
        label: "Configurações",
        title: currentTab?.title,
      },
    ];

    if (currentTab?.subItems?.length) {
      routers.push({
        label: currentTab?.subItems?.length ? currentTab?.title : "",
        title:
          currentTab?.subItems?.find((item) => item.tab === subTab)?.label ||
          currentTab?.title,
      });
    }

    updateRouters(routers);
    return () => {
      updateRouters([]);
    };
  }, [subTab, tab, updateRouters]);

  const menuItems: IMenuItem[] = [
    {
      title: "Impressão inteligente",
      tab: "Impressora",
      component: <SmartPrinterPage />,
    },
    {
      title: "Cupons de desconto",
      tab: "Cupons",
      component: <DiscountCouponsPage />,
      role: "CupomDesconto",
    },
    {
      title: "Minhas configurações",
      value: "/configuracoes/minhasConfiguracoes",
      tab: "minhasConfiguracoes",
      role: "MinhasConfiguracoesPortal",
    },
    {
      title: "Contas bancárias",
      value: "/configuracoes/contasBancarias",
      tab: "contasBancarias",
      role: "ContasBancariasPortal",
    },
    {
      title: "Direcionamento de transações",
      value: "/configuracoes/direcionamentoDeTransacoes",
      tab: "direcionamentoDeTransacoes",
      role: "DirecionamentoTransacoesPortal",
    },
    {
      title: "Acessos",
      value: "/configuracoes/acessos",
      tab: "acessos",
      role: "AcessosClientePortal",
    },
    {
      title: "Agendamento consumo mínimo",
      value: "/configuracoes/agendamentoConsumoMinimo",
      tab: "agendamentoConsumoMinimo",
      role: "AgendamentoConsumoMinimo",
    },
  ];

  const subTabComponent = useCallback(
    (tabItem: IMenuItem) =>
      !!tabItem.subItems?.length ? (
        <TabPage
          routerPrefix={"/private/configuracao/" + tabItem.tab}
          subTabEnabled
          tabsPages={tabItem.subItems?.map((subTabItem) => ({
            label: subTabItem.label,
            tab: subTabItem.tab,
            roles: subTabItem.role,
            component: (
              <>
                {subTabItem.url ? (
                  <IframePage src={subTabItem.url!} />
                ) : (
                  subTabItem.component
                )}
              </>
            ),
          }))}
        />
      ) : (
        tabItem.component || <IframePage src={tabItem.value!} />
      ),
    []
  );

  return (
    <TabPage
      routerPrefix={"/private/configuracao"}
      tabsPages={menuItems.map((tabItem) => ({
        label: tabItem.title,
        tab: tabItem.tab,
        roles: tabItem.role,
        component: subTabComponent(tabItem),
      }))}
      hideTabs
    />
  );
};
export default ConfigurationPage;
