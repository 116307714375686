import Button from "components/ui/Button/Button";
import styles from "./SaasBlockPage.module.scss";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";

const SaasBlockPage = () => {
    const history = useHistory();

    const skeletons = (
        <div className={styles.divSkeletons}>
            <div className={styles.squareSkeleton}>
                <Skeleton
                    variant="rectangular"
                    width="50%"
                    height={160}
                    sx={{backgroundColor: "#FCFCFC"}}
                    animation={false}
                />
                <div className={styles.skeletons}>
                    {Array.from({ length: 3 }, (_, index) => (
                        <Skeleton
                            key={index}
                            variant={index === 0 ? "rectangular" : "text"}
                            sx={{backgroundColor: "#FCFCFC" }}
                            animation={false}
                            width="100%"
                            height={index === 0 ? 60 : 30}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.skeletons}>
                {Array.from({ length: 3 }, (_, index) => (
                    <Skeleton
                        key={index}
                        variant={index === 0 ? "rectangular" : "text"}
                        sx={{backgroundColor: "#FCFCFC" }}
                        animation={false}
                        width="100%"
                        height={index === 0 ? 60 : 30}
                    />
                ))}
            </div>
            <div className={styles.skeletons}>
                {Array.from({ length: 2 }, (_, index) => (
                    <Skeleton
                        key={index}
                        variant={index === 0 ? "rectangular" : "text"}
                        sx={{backgroundColor: "#FCFCFC" }}
                        animation={false}
                        width="100%"
                        height={index === 0 ? 160 : 64}
                    />
                ))}
            </div>
        </div>
    );

    const navigate = () => {
        return history.push('/private/saas/mudar-forma-pagamento');
    }

    return (
        <div id={styles.SaasBlockPage}>
            {skeletons}
            <div className={styles.info}>
                <img src="/assets/img/advanced-saas.png" alt="" />
                <p>Para continuar utilizando o Portal Meep, <b>atualize seus dados de pagamento</b></p>
                <Button onClick={navigate}>Atualizar dados</Button>
            </div>
        </div>
    )
}

export default SaasBlockPage;