export interface IReservationList {
    details: IReserveItem[]
    count: number,
}
export interface IReserveItem {
    id: string,
    participants: IParticipantsReserve[],
    participantsQuantity: number,
    responsable: IResponsableReserve,
    reservedObject: IReservedObject,
    service: IServiceItemReserve,
    startAt: string,
    finishAt: string,
    duration: number,
    currentState: ICurrentState,
    formOfPayment: IFormOfPayment,
    netValue: number,
    orderDate: string
}
export interface IParticipantsReserve {
    id: string,
    name: string,
    birthday: string,
    document: string,
    age: number,
    note: string,
    checkedByStaff: boolean,
    phone: string,
    serviceDemandedScheduledId: string
}

export interface IResponsableReserve {
    id: string,
    name: string,
    birthday: string,
    document: string,
    age: number
}
export interface IServiceItemReserve {
    id: string,
    name: string
}
export interface IReservedObject {
    id: string,
    name: string
}
export interface ICurrentState {
    id: string,
    name: string
}
export interface IFormOfPayment {
    id: string,
    name: string
}


export interface IReserveFilter {
    keyword: string,
    start: string,
    end: string,
    status: IStateEnum[]
}

export interface IState {
    id: string,
    name: string,   
}

export enum IStateEnum {
    "Pendente" = 1,
    "Concluída" = 2,
    "Cancelada" = 3
}

