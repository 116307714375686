import { sub } from "date-fns";
import { z } from "zod";

export const SupplyGroupValidateSchema = z.object({
  name: z.string().min(3, 'Nome deve ter no mínimo 3 caracteres').max(100, 'Nome deve ter no máximo 100 caracteres'),
  isSubGroup: z.boolean().optional(),
  parentId: z.string().nullable().optional(),
  canUpdateInventory: z.boolean().optional(),
})
.superRefine((data, ctx) => {
  console.log(data)
  if (data.isSubGroup) {
    if (!data.parentId) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Subgrupo deve ter um grupo pai',
        path: ['parentId'],
      });
    }
  }
  return data
});
