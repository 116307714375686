import { ICreateDiscountListRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const CreateBaseListUseCase = async (
  baseListService: IDiscountBaseList,
  baseList: ICreateDiscountListRequest
): Promise<void> => {
  return await baseListService.createBaseList(baseList);
};

export default CreateBaseListUseCase;
