import { Icon } from "@material-ui/core";
import Button from "components/ui/Button/Button";
import { Modal } from "components/ui/modal/Modal";
import React, { FC } from "react";
import DiscountProductForm from "./components/form/DiscountProductForm";
import DiscountProductList from "./components/list/DiscountProductList";
import styles from "./DiscountForm.module.scss";
import { IDiscountListForm } from "./interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "./interfaces/IProductList";
import UseDiscountForm from "./UseDiscountForm";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import { IDiscountListFormErros } from "../discountsListForm/validation/interface/IDiscountListFormValidationErros";

interface IDiscountFormProps {
  value: IDiscountListForm[];
  onChange: (value: IDiscountListForm[]) => void;
  productList: IProductItemToDiscount[];
  subForm?: boolean;
  onDeleteItem?: (itemId: string) => void;
  errors: IDiscountListFormErros;
}
const DiscountForm: FC<IDiscountFormProps> = ({
  value,
  onChange,
  productList,
  subForm,
  onDeleteItem,
  errors,
}) => {
  const {
    openModalAdd,
    onClickDeleteHandle,
    onClickAddHandle,
    onSubmitAddHandle,
    onClickCloseHandle,
  } = UseDiscountForm(value, onChange, onDeleteItem);
  return (
    <div id={styles.DiscountProductListForm}>
      <div className={styles.container}>
        <DiscountProductList
          discountsList={value}
          onPressDelete={onClickDeleteHandle}
        />
      </div>
      {!!errors.discounts && (
        <span className={styles.textError}>{errors.discounts}</span>
      )}
      {/* <Button fullWidth={false} variant={"text"} onClick={onClickAddHandle}>
        Adicionar desconto<Icon>add</Icon>
      </Button>
      <RightDrawer open={openModalAdd} title="Adicionar" onClose={onClickCloseHandle}>
        <DiscountProductForm productList={productList} discountsList={value} onSubmit={onSubmitAddHandle} />
      </RightDrawer> */}
    </div>
  );
};
export default DiscountForm;
