import ProfileDeviceList from "../../components/deviceProfile/profileList/ProfileDeviceList";
import UseProfilePage from "../profile/UseProfilePage";
import styles from "./DeviceProfileEventoPage.module.scss";
import "../profile/DeviceProfilePage.css";
import { Drawer, InputAdornment, TextField } from "@mui/material";
import { Search } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import DeleteProfileAlert from "../../components/deviceProfile/profileList/deleteAlert/DeleteAlert";
import SidesheetProfile from "../../components/deviceProfile/sidesheetProfile/SidesheetProfile";
import { useCallback, useEffect, useState } from "react";
import DeviceToProfileForm from "../../components/deviceProfile/profileForms/deviceProfileForm/DeviceToProfileForm";
import UseDeviceToProfile from "../../hooks/deviceProfile/UseDeviceToProfile";
import {
  IDeviceEventValue,
} from "../../components/deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { Close } from "@material-ui/icons";
import UseDeviceProfileForm from "../../fragment/deviceProfile/UseDeviceProfileForm";
import UseProfileEventoPage from "./UseProfileEventoPage";
import { IDeviceProfileItem } from "../../components/deviceProfile/profileList/interfaces/IProfileItem";

const DeviceProfileEventoPage = () => {
  const {
    searchTerm,
    filteredProfileList,
    setSearchTerm,
    profileToDelete,
    setProfileToDelete,
    filterProfile,
    totalCount,
    onChangePaginationHandle,
    onChangeFilterHandle,
    perfilVinculo,
  } = UseProfilePage(true);

  const { getMoreDevices, deviceList, getDevicesList } =
    UseDeviceProfileForm(onChangeFilterHandle, perfilVinculo);

  const { editDevices } = UseDeviceToProfile();
  const [openSidesheet, setOpenSidesheet] = useState<string | null>(null);
  const [openEquipamentos, setOpenEquipamentos] = useState(false);
  const [id, setId] = useState("");
  const [perfisList, setPerfisList] = useState<IDeviceProfileItem[]>();
  const { currentLocal } = useLocal();
  const {
    postAddPerfilEvento,
    perfilEvento,
    onSearch,
    updateAddPerfilEvento,
    deletePerfilEvento,
    updateEquipamentos,
    devices,
    getEquipamentos,
    disponibilidade,
    onChangePaginationHandleEvent,
    filterProfileEvent
  } = UseProfileEventoPage();

  useEffect(() => {
    const removePerfilAdd = filteredProfileList?.filter(
      (itemA) => !perfilEvento?.bond?.some((itemB) => itemB.id === itemA.id)
    );

    const initialChecked = removePerfilAdd?.map((objeto) => ({
      ...objeto,
      checked: false,
    }));
    setPerfisList(initialChecked);
  }, [filteredProfileList, perfilEvento]);

  const onSubmit = useCallback(
    async (values: IDeviceEventValue) => {
      await updateEquipamentos(values, id);
      setOpenEquipamentos(false);
    },
    [editDevices, id]
  );

  useEffect(() => {
    if (currentLocal) {
      getDevicesList(currentLocal.id);
    }
  }, [currentLocal]);

  const postPerfis = () => {
    const filterActive = perfisList?.filter((item) => item.checked);
    const profile = filterActive?.map((item) => ({
      profileId: item.id,
      profileName: item.name,
    }));
    if (perfilEvento && perfilEvento?.bond.length < 1) {
      postAddPerfilEvento(profile);
    } else {
      updateAddPerfilEvento(profile);
    }
  };

  return (
    <div id={styles.DeviceProfileEventoPage}>
      {filteredProfileList && (
        <>
          <div className={styles.header}>
            {perfilVinculo === 2 && (
              <Button
                fullWidth={false}
                className={styles.addButton}
                onClick={() => setOpenSidesheet("true")}
              >
                Vincular Perfil de venda
              </Button>
            )}
          </div>
          <div className={styles.header}>
            <TextField
              className="device-profile-header-search"
              variant="outlined"
              label="Perquisar Perfil"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                onSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <ProfileDeviceList
            profileList={filteredProfileList}
            profileIdEventos={perfilEvento && perfilEvento.bond}
            perfilVinculo={perfilVinculo}
            onClickItem={(profile) => {
              setOpenEquipamentos(true);
              if (perfilVinculo === 2) {
                getEquipamentos(profile.id);
              }
              setId(profile.id);
              if (currentLocal) {
                getDevicesList(currentLocal.id);
              }
            }}
            onClickDeleteItem={(value) => setProfileToDelete(value)}
            nivelEvento={true}
            onChangePaginationHandle={perfilVinculo === 1 ? onChangePaginationHandle : onChangePaginationHandleEvent}
            pagination={perfilVinculo === 1 ? filterProfile.pagination : filterProfileEvent.pagination}
            totalCount={(perfilVinculo === 1 ? totalCount : perfilEvento &&  perfilEvento.totalCount) || 0}
          />
          <DeleteProfileAlert
            profile={profileToDelete}
            onClose={function (): void {
              setProfileToDelete(undefined);
            }}
            onConfirm={(profile) => {
              deletePerfilEvento(profileToDelete!.id);
              setProfileToDelete(undefined);
            }}
          />
        </>
      )}
      <SidesheetProfile
        openSidesheet={openSidesheet}
        setOpenSidesheet={setOpenSidesheet}
        profileList={perfisList}
        setProfileList={setPerfisList}
        postPerfis={() => postPerfis()}
        pagination={filterProfile.pagination}
        totalCount={totalCount}
        onChangePaginationHandle={onChangePaginationHandle}
      />

      <Drawer open={openEquipamentos} anchor="right">
        <div id={styles.DeviceProfileForm}>
          <div className={styles.header}>
            {perfilVinculo === 1 ? (
              <h2>
                Equipamentos <span>vinculados</span>
              </h2>
            ) : (
              <h2>
                Vincular <span>Equipamentos</span>
              </h2>
            )}
            <Close onClick={() => setOpenEquipamentos(false)} />
          </div>
          <div className={styles.container}>
            <DeviceToProfileForm
              deviceList={deviceList}
              deviceListEvent={devices}
              currentProfileId={id}
              onClickBack={() => setOpenEquipamentos(false)}
              onUpdateDevices={onSubmit}
              getMoreDevices={getMoreDevices}
              nivelEvento
              perfilVinculo={perfilVinculo}
              getEquipamentos={getEquipamentos}
              disponibilidade={disponibilidade}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DeviceProfileEventoPage;
