import UseDimension from "components/dimension/UseDimension";
import styles from "./ModalPOS.module.scss";
import { TaskAlt, Close } from "@mui/icons-material";
import Button from "components/ui/Button/Button";
interface IModalGestaoVendas {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalPOS = ({ setOpenModal }: IModalGestaoVendas) => {
  const { dimensions } = UseDimension()
  return (
    <div id={styles.ModalPOS}>
      <div className={styles.container}>
        {dimensions.width > 800 && <img className={styles.imgTop} src={"/assets/img/gestao_vendas/img-top-modalpos.png"} alt="imagem modal" />}
        <img className={styles.banner} src={"/assets/img/gestao_vendas/banner-modalpos.png"} alt="imagem modal" />
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Você já está usando
              todos os terminais disponíveis no seu plano! </h1>
            <div className={styles.tarja}></div>
          </div>
          <p>
            Para adicionar novos, é necessário mudar de plano. Entre em contato pelo chat para solicitar
          </p>
          <Button className={styles.btn} onClick={() => setOpenModal(false)}>Conversar no chat</Button>
        </div>
        {dimensions.width > 800 && <img className={styles.imgFooter} src={"/assets/img/gestao_vendas/img-canto-modalpos.png"} alt="imagem modal" />}
        <Close className={styles.close} onClick={() => setOpenModal(false)} />
      </div>
    </div>
  );
};

export default ModalPOS;
