import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetDiscountCouponItemUseCase from "../../application/useCases/GetDiscountCouponItemUseCase ";
import { IDiscountCouponItem } from "../interfaces/IDiscountCouponItem";



const UseDiscountCouponsItemAfterCreation= () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [discountCouponsItemAfterCreation, setDiscountCouponsItemAfterCreation] = useState<IDiscountCouponItem>();
  const { currentLocal } = useLocal();

  const getDiscountCouponItemAfterCreation = useCallback(
    async (couponId: string) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetDiscountCouponItemUseCase(serviceDiscountCoupons,
            currentLocal.id,
            couponId
          );

          setDiscountCouponsItemAfterCreation(response);
          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    discountCouponsItemAfterCreation,
    isLoading,
    getDiscountCouponItemAfterCreation,
    setDiscountCouponsItemAfterCreation,
  };
};

export default UseDiscountCouponsItemAfterCreation;
