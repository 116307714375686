import { api } from "../Api"
import { IMeepFeeApiService } from "modules/meepFee/application/interfaces/IMeepFeeApiService"
import { IMeepFeeResponse } from "modules/meepFee/domain/dto/IMeepFeeResponse"

export const MeepFeeApiService = (): IMeepFeeApiService => {
    const getMeepFee = async (ownerId: string): Promise<IMeepFeeResponse[]> => {
        const response = await api.get(`/InformacoesFinanceirasLocal/${ownerId}`);
        return response?.data?.Taxas ?? [];
    }

    return {
      getMeepFee,
    };
}
