import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { ICampaignMessagesData } from "modules/crm/presentation/intefaces/CampaignMessage"

const GetCampaignMessagesUseCase = (service: ICrmService, campaignId: string, request: {hasFailed?: boolean, pagination: { page?: number, pageSize?: number } }): Promise<ICampaignMessagesData> => {
    const _request = {
        hasFailed: request.hasFailed,
        ...request.pagination
    }
    const response = service.getCampaignMessages(campaignId, _request)
    return (response)
}
export default GetCampaignMessagesUseCase