import React, { FC } from "react";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import styles from "./ShareListForm.module.scss";
import { Switch } from "@mui/material";
import { IShareListFormErrors } from "../../validation/IGuestListFormErros";

interface IShareListFormProps {
    errors: IShareListFormErrors;
    handleChangeShareUrl: (value: string) => void;
    handleChangeShareDescription: (value: string) => void;
    handleChangeShareEnabled: (value: boolean) => void;
    shareEnabled?: boolean;
    shareUrl?: string;
    shareDescription?: string;
}

export const ShareListForm: FC<IShareListFormProps> = ({
    errors,
    handleChangeShareUrl,
    handleChangeShareDescription,
    handleChangeShareEnabled,
    shareEnabled,
    shareUrl,
    shareDescription,
}) => {
    return (
        <form id={styles.ShareListForm}>
            <Switch color='secondary' checked={shareEnabled} onChange={(_, checked) => handleChangeShareEnabled(checked)} /> Gerar URL lista de convidado

            <FormItemContainer>
                <Input
                    variant="outlined"
                    fullWidth
                    value={shareUrl}
                    disabled
                    error={!!errors.shareDescription}
                    helperText={errors.shareDescription}
                />
            </FormItemContainer>

            <FormItemContainer label="Descrição que aparecerá na URL">
                <Input
                    variant="outlined"
                    fullWidth
                    placeholder="Entrada até 18h"
                    value={shareDescription}
                    onChange={(ev) => handleChangeShareDescription(ev.target.value)}
                    error={!!errors.shareDescription}
                    helperText={errors.shareDescription}
                />
            </FormItemContainer>
        </form>
    );
};
