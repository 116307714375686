import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import GetPermutationItemUseCase from "modules/permutation/application/useCases/GetPermutationItemUseCase";
import { IPermutionItem } from "../interfaces/IPermutionItem";

const UsePermutationAccountList = () => {

  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>();
  const [permutationAccountItem, setPermutationAccountItem] = useState<IPermutionItem>()
  const { currentLocal } = useLocal();

  const getPermutationAccountItem= useCallback(
    async (accountId: string) => {
      try {
        const servicePermutation = PermutationApi();
        setIsLoadingEdit(true);
        if (currentLocal) {
          const response = await GetPermutationItemUseCase(servicePermutation,
            currentLocal.id,
            accountId
          );

          setPermutationAccountItem(response);

          return response
        }
      } finally {
        setIsLoadingEdit(false);
      }
    },
    [currentLocal]
  );


  return {
    permutationAccountItem,
    isLoadingEdit,
    getPermutationAccountItem,
    setPermutationAccountItem,
  };
};

export default UsePermutationAccountList;
