import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './InvoicePage.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IUnitFilterValue } from '../../components/unit/unitList/unitFilter/IUnitFilter';
import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import UseQueryState from '../../hook/UseQueryFilter';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'services/api/Api';
import DeleteErp from '../../components/ui/delete/DeleteErp';
import { IGetInvoiceListResponse } from 'modules/meepErp/application/dtos/invoice/IGetInvoiceListResponse';
import { getInvoiceListUseCase, onDeleteInvoiceUseCase } from 'modules/meepErp/application/useCases/invoice/InvoiceUseCases';
import InvoiceList from '../../components/invoices/invoiceList/InvoiceList';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import { getStockOperationsUseCase } from 'modules/meepErp/application/useCases/operations/StockOperationsUseCase';
import { IGetStockOperationListResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse';


const InvoicePage: FC = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<IUnitFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const [operationsList, setOperationsList] = useState<IGetStockOperationListResponse | undefined>()
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Central de lançamentos",
            },  
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  

    const getOperationsList = useCallback(async () => {
        if (!currentLocal) throw new Error("Local não econtrado!");
        const response = await getStockOperationsUseCase(currentLocal.id, { page: 1, pageSize: 30 })

        setOperationsList(response)
    }, [currentLocal])

    useEffect(() => {
        getOperationsList()
    }, [])

    //ReactiQuery

    const invoice = useQuery(['@invoice', currentLocal?.id, filter], () => getInvoiceListUseCase(filter), {
        enabled: !!currentLocal?.id,
    })

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetInvoiceListResponse | undefined>(['@invoice'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteLocalsMutation = useMutation((id: string) => onDeleteInvoiceUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteLocalsMutation.mutateAsync(id);
    }, [onDeleteLocalsMutation])

    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/invoices/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/invoices/update/${id}/edit`);
    }, [push])

    const onClickView = useCallback(async (id: string) => {
        push(`/private/meeperp/invoices/update/${id}/view`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    return (
        <div id={styles.InvoicePage} >
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CENTRAL_LANCAMNETO_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar nota
                    </Button>
                </div>
            }
            <InvoiceList
                filter={filter}
                onChangeFilter={updateFilter}
                invoice={invoice.data}
                isLoading={invoice.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
                operations={operationsList}
                onView={onClickView}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'nota'}
                text={'Deseja realmente excluir a nota?'}
                textFeedback={'Ao excluir um lançamento toda movimentação realizada será desfeita e impactará na posição de estoque dos insumos incluídos na nota.'}
            />
        </div>
    )
}

export default InvoicePage

