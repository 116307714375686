import { Drawer, Icon } from '@material-ui/core';
import styles from './PlanHistoryModal.module.scss';
import { ILocalPlansItem } from '../../interfaces/ILocalPlans';
import { IHistory } from '../../interfaces/IHistory';
import { useEffect, useState } from 'react';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { GetHistoryUseCase } from 'modules/plans/application/GetHistoryUseCase';
import PlansApi from 'services/api/plans/PlansApi';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';

interface PlanHistoryModalProps {
    open: boolean;
    localPlan: ILocalPlansItem;
    onClose: () => void;
}

const service = PlansApi();

export const PlanHistoryModal = ({ open, localPlan, onClose }: PlanHistoryModalProps) => {
    const { toast } = useUi();

    const [history, setHistory] = useState<IHistory[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await GetHistoryUseCase(service, localPlan.ownerId);
                setHistory(response);
            } catch {
                toast('Ocorreu um erro ao buscar o histórico de transações desse local', 'error');
            } finally {
                setLoading(false);
            }
        })();
    }, [localPlan.ownerId, toast]);

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Histórico de <b>Planos</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    {
                        loading ?
                            <>
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            </> :
                            history.map(x => (
                                <div className={styles.row}>
                                    <div><Icon>star_outlined</Icon></div>
                                    <span>{x.changeDescription} em {format(new Date(x.changeDate + 'Z'), 'dd/MM/yyyy HH:mm')} {x.changedBy ? `por ${x.changedBy}` : ''}</span>
                                </div>
                            ))
                    }
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}