export const comparisonData: {
  title: string;
  basic: boolean | string;
  intermediary: boolean | string;
  advanced: boolean | string;
}[] = [
  {
    title: "Terminais de venda",
    basic: "1",
    intermediary: "3",
    advanced: "5",
  },
  {
    title: "Gestor de pedidos completo",
    basic: true,
    intermediary: true,
    advanced: true,
  },
  {
    title: "Cardápio em QR Code",
    basic: true,
    intermediary: true,
    advanced: true,
  },
  {
    title: "Dashboard e relatórios",
    basic: true,
    intermediary: true,
    advanced: true,
  },
  {
    title: "CRM dos clientes",
    basic: false,
    intermediary: false,
    advanced: true,
  },
  {
    title: "Campanhas de Marketing",
    basic: false,
    intermediary: false,
    advanced: true,
  },
  {
    title: "Programa de fidelidade",
    basic: false,
    intermediary: false,
    advanced: true,
  },
];
