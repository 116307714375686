import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { ICrmEventValue } from "modules/crm/presentation/fragment/crmImportEventForm/CrmImportEventForm"
import { ICrmEvent, } from "modules/crm/presentation/intefaces/ICrmEventData"

const AddCrmEventUseCase = (service: ICrmService, ownerId: string, event: ICrmEventValue) => {


    const response = service.addEvent({ ownerId, ...event });
    return (response);
}
export default AddCrmEventUseCase