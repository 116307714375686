import Validate from "services/utils/Validate";
import { ITicketForm, ITicketFormErrors } from "../ITicketForm";

export const validate = (formData: ITicketForm, setErrors: React.Dispatch<React.SetStateAction<ITicketFormErrors>>, gerarNFCE?: boolean, isEdit?: boolean) => {
  let _errors = {} as ITicketFormErrors;
  let hasError = false;
  if (gerarNFCE && isEdit) {
    if (Validate.isNullOrZero(formData.ncm)) {
      _errors = { ..._errors, ncm: "Insira o CNM para continuar" };
      hasError = true;
    }
    if (Validate.isNullOrZero(formData.cfop)) {
      _errors = { ..._errors, cfop: "Insira o CFOP para continuar" };
      hasError = true;
    }
  }
  if (!formData.name) {
    _errors = { ..._errors, name: "Insira um nome para o ingresso" };
    hasError = true;
  }
  if (!formData.price) {
    _errors = { ..._errors, price: "Insira um valor para o ingresso" };
    hasError = true;
  }
  setErrors(_errors);
  return !hasError;
}