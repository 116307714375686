import { SaveLeadsUseCase } from "modules/saas/application/useCases/SaveLeadsUseCase";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SaasApi } from "services/api/saas/SaasApi";
import { ILeads } from "../../interfaces/leads/ILeads";
import { ILeadUser } from "../../interfaces/leads/ILeadUser";
import { IRegisterSaas } from "../../interfaces/register/IRegisterSaas";
import { useQuery } from "hooks/UseQuery";
import { getParamsObject, getParamsString } from "../../utils/params";
import { CheckEmailUseCase } from "modules/saas/application/useCases/CheckEmailUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { RegisterSaasUseCase } from "modules/saas/application/useCases/RegisterSaasUseCase";
import { IRegisterSchema } from "modules/saas/domain/schema/registerSchema";

const service = SaasApi();

export const UseRegisterPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [leads, setLeads] = useState<ILeads>();
    const [user, setUser] = useState<ILeadUser>();
    const [defaultValues, setDefaultValues] = useState<IRegisterSaas>({} as IRegisterSaas);
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    let query = useQuery();
    const history = useHistory();
    const { toast, showLoading, hideLoading } = useUi();
    const location = useLocation<{ type: string, cieloSystem?: string }>();

    const handleAccessAccount = useCallback((email?: string) => {
        history.push(`/public/saas/login?${email ? `email=${email}&` : ""}` + getParamsString(query), { type: location.state?.type });
    }, [history, location.state?.type, query]);

    const handleAccessPortal = useCallback((email?: string) => {
        history.push(`/login?${email ? `email=${email}&` : ""}` + getParamsString(query));
    }, [history, query]);

    useEffect(() => {
        if (!leads)
            setLeads(getParamsObject(query));
    }, [leads, query]);

    useEffect(() => {
        const _type = query.get("type") ?? location.state?.type;
        const _imei = query.get("imei");
        const _serialNumber = query.get("serialNumber") ?? query.get("serial");

        if ((_type === "3" || (!!_imei && _serialNumber)) && !location.state?.cieloSystem) {
            return history.push("/public/saas/registerCielo?" + getParamsString(query));
        }

        if (_type === "3" ||_type === "6" || _type === "7") {
            setProgressBarColor("#00739E");
            setBackgroundColorTop("#00AEEF");
            setBackgroundColorBottom("#00AEEF");
        } else {
            setProgressBarColor("#865FDD");
            setBackgroundColorTop("#6B41C9");
            setBackgroundColorBottom("#865FDD");
        }

        setDefaultValues(prev => ({
            ...prev,
            email: query.get("email") ?? "",
            name: query.get("name") ?? "",
            phoneNumber: query.get("phoneNumber") ?? "",
            type: !!Number(_type) ? Number(_type) : undefined,
            externalDevice: _imei && _serialNumber ? {
                imei: _imei ?? "",
                serialNumber: _serialNumber ?? "",
            } : undefined
        }));
    }, [hideLoading, history, location, query, showLoading]);

    useEffect(() => {
        (async () => {
            if (!user && !!leads && leads.email) {
                try {
                    const response = await SaveLeadsUseCase(service, leads);
                    setUser(response);
                } finally {}
            }
        })();
    }, [leads, user]);

    useEffect(() => {
        const email = query.get("email");
        (async () => {
            try {
                    showLoading();
                    if (email) {
                        const exist = await CheckEmailUseCase(service, email);
                        if (exist.isOwner) {
                            return handleAccessPortal();
                        }
                        if (exist.exists) {
                            return handleAccessAccount();
                        }
                    }
                    if (
                        (defaultValues.type === 7 || defaultValues.type === 6 || defaultValues.type === 3 ||
                            (!!defaultValues.externalDevice?.imei && defaultValues.externalDevice.serialNumber)) &&
                        !location.state?.cieloSystem
                    ) {
                        return history.push("/public/saas/registerCielo?" + getParamsString(query));
                    }
                } finally {
                    hideLoading();
                }
            }
        )();
    }, [defaultValues, handleAccessAccount, handleAccessPortal, hideLoading, history, location, query, showLoading]);

    const handleSubmit = async (formValues: IRegisterSchema) => {
        try {
            setIsLoading(true);
            let formData = defaultValues;

            formData = {
                ...defaultValues,
                email: formValues.email,
                password: formValues.password,
            };

            const alredyRegistered = await CheckEmailUseCase(service, formData.email);
            if (alredyRegistered.isOwner) {
                toast("Esse email já está sendo usado! Acesse sua conta.", "info");
                return handleAccessPortal(formData.email);
            }
            if (alredyRegistered.exists) {
                toast("Esse email já está sendo usado! Acesse sua conta.", "info");
                return handleAccessAccount(formData.email);
            }

            await RegisterSaasUseCase(service, formData);

            history.push(`/public/saas/code?email=${formData.email}&type=${formData.type}`, { type: formData.type });
        } catch {
            toast('Ocorreu um erro ao se registrar. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        defaultValues,
        handleSubmit,
    }
}