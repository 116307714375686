
import { IParamsHouseFlowFilterRequest } from "modules/houseFlow/domain/dto/IHouseFlowFilterRequest";
import { IHouseFlowFilter } from "modules/houseFlow/presentation/components/houseFlowFilter/components/interfaces/IHouseFlowFilter";
const ParseFilterToRequestUseCase = (cardId?: string, filter?: IHouseFlowFilter, pagination?: { page?: number | undefined; pageSize?: number | undefined; }) => {

  const request: IParamsHouseFlowFilterRequest = {   
    cardId: cardId,
    cardStatus: filter?.cardStatus,
    cardNumber: filter?.cardNumber,
    customerCPF: filter?.customerCPF,
    customerName: filter?.customerName,
    "sort.Orientation": filter?.sortOrientation,
    "sort.Type": filter?.sortType,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterToRequestUseCase
