import { IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Modal } from "components/ui/modal/Modal";
import React from "react";
import { IDiscountsList } from "../../interfaces/IDiscountsList";
import DiscountListEditForm from "../discountListEditForm/DiscountListEditForm";
import DiscountsList from "./components/DiscountsList";
import styles from "./ListCard.module.scss";
import UseListCard from "./UseListCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import warningImg from "../../../../../../../res/assets/images/warning.png";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";

interface IListCardProps {
  discountList: IDiscountsList;
  refreshList: () => Promise<void>;
  adminAccess?: boolean;
}

export const ListCard: React.FC<IListCardProps> = ({
  discountList,
  refreshList,
  adminAccess,
}) => {
  const {
    deleteList,
    onClickEditDiscounts,
    toggleEditDiscountsModal,
    showEditDiscountsModal,
    productList,
    onDeleteDiscount,
    onAddDiscount,
    discountsToEdit,
    handleEditDiscount,
    toggleDeleteDiscountsModal,
    deleteConfirmationModal,
  } = UseListCard(refreshList);

  return (
    <div id={styles.EventListCard}>
      <div className={styles.container}>
        <div className={styles.textEventList}>
          <div className={styles.nameList}>
            <h2>{discountList.name}</h2>
            {adminAccess && (
              <div>
                <IconButton
                  onClick={() =>
                    onClickEditDiscounts(
                      discountList.discounts,
                      discountList.id
                    )
                  }
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton onClick={toggleDeleteDiscountsModal}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={styles.nameList}>
            <p>{discountList.description}</p>
          </div>

          <DiscountsList
            discountList={discountList.discounts}
            onClickEditDiscounts={onClickEditDiscounts}
            adminAccess={adminAccess}
            listId={discountList.id}
          />
        </div>
      </div>
      <RightDrawer
        onClose={toggleEditDiscountsModal}
        open={showEditDiscountsModal}
        title="Descontos"
      >
        <div className={styles.modalEditDiscount}>
          {productList && discountsToEdit && (
            <DiscountListEditForm
              baseListId={discountList.id}
              values={discountsToEdit}
              productList={productList}
              onDeleteItem={onDeleteDiscount}
              onAddItem={onAddDiscount}
              adminAccess={adminAccess}
              handleEditDiscount={handleEditDiscount}
            />
          )}

          <div className={styles.button}>
            <Button fullWidth={false} onClick={toggleEditDiscountsModal}>
              Confirmar
            </Button>
          </div>
        </div>
      </RightDrawer>
      <RightDrawer
        onClose={toggleDeleteDiscountsModal}
        open={deleteConfirmationModal}
        title="Excluir lista?"
      >
        <div className={styles.containerDelete}>
          <div className={styles.delete}>
            <img src={warningImg} alt="warning_image" />
            <p>Lembre-se: essa ação não poderá ser desfeita!</p>
          </div>
          <div className={styles.button}>
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={toggleDeleteDiscountsModal}
            >
              Cancelar
            </Button>
            <Button
              fullWidth={false}
              onClick={() => deleteList(discountList.id)}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </RightDrawer>
    </div>
  );
};
