import React, { useEffect, useMemo } from "react";
import Layout from "components/layout/presentation/Layout";
import { HouseFlowPage } from "modules/houseFlow/presentation/pages/HouseFlowPage";
import TabPage from "../dashboard/tabPage/TabPage";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { useParams } from "react-router-dom";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import { HouseFlowListProvider } from "modules/houseFlow/presentation/context/HouseFlowListContext";
import UseManageEventPage from "modules/events/presentation/pages/manageEvent/UseManageEventPage";

interface IHouseFlowMenu {
  label: string;
  tab: string;
  url?: string;
  roles?: string;
  component?: React.ReactNode;
}

const HouseFlow = () => {
  const { updateRouters } = useBreadcumbs();
  const { tab } = useParams<{ tab: string, eventId: string }>();
  const { event } = UseManageEventPage()

  const houseFlowTabs: IHouseFlowMenu[] = useMemo(() => [
    {
      label: "Mapa de comandas",
      tab: "pospaid",
      roles: "FluxoComandasPortal",
      component: <IframePage src={"/posPago/fluxoDeComandas"} />,
    },
    {
      label: "Gestor de mesas",
      tab: "prepaidtable",
      roles: "GerenciadorComandasPrePago",
      component: <HouseFlowPage />,
    },
  ], []);

  useEffect(() => {
    const currentTab = houseFlowTabs.find((item) => tab === item.tab);
    if (currentTab?.tab === "pospaid") {
      updateRouters([
        {
          title: 'Mapa de operação'
        },
        {
          label: "Mapa de comandas",
          title: currentTab?.label,
        },
      ]);  
    } else {
      updateRouters([
        {
          title: "Evento/Caixa",
          url: "/private/eventos/list",
        },
        {
          title: event?.name
        },
        {
          title: 'Mesas do local'
        },
        {
          label: "Mesas do local",
          title: currentTab?.label,
        },
      ]);      
    }
    return () => {
      updateRouters([]);
    };
  }, [tab, updateRouters, event, houseFlowTabs]);

  return (
    <Layout>
      <HouseFlowListProvider>
        <TabPage
          intialTab="prepaid"
          routerPrefix={"/private/event/fluxoCasa"}
          hideTabs
          tabsPages={houseFlowTabs.map((menu) => ({
            label: menu.label,
            tab: menu.tab,
            roles: menu.roles,
            component: menu.component,
          }))}
        ></TabPage>
      </HouseFlowListProvider>
    </Layout>
  );
};

export default HouseFlow;
