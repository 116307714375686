import { Button, CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import TabView from "modules/config/deviceConfig/presentation/components/tab/TabView";
import { IGetDiscountCouponsResponse } from "modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse";
import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import UseAddCoupon from "../../hooks/UseAddCoupon";
import { IParamsClientDiscountCouponsList } from "../../hooks/UseDiscountCouponsList";
import UseEditCoupon from "../../hooks/UseEditCoupon";
import { ICouponForm } from "../../interfaces/ICouponForm";
import { ICouponFormErros } from "../../interfaces/ICouponFormErrors";
import { IDiscountCouponItem } from "../../interfaces/IDiscountCouponItem";
import { DetailsForm } from "./components/detailsForm/DetailsForm";
import { EquipamentForm } from "./components/equipamentForm/EquipamentForm";
import { OperationForm } from "./components/operationForm/OperationForm";
import styles from "./CouponForm.module.scss";
import { validate } from "./validation/CouponFormValidation";
import successImg from "res/assets/images/success.png";
import UseDiscountCouponsItemAfterCreation from "../../hooks/UseDiscountCouponsItemAfterCreation";
import { DownloadCoupon } from "../downloadCoupon/DownloadCoupon";
import { Drawer } from "@mui/material";
import { KeyboardArrowRight, VerticalAlignBottom } from "@mui/icons-material";
import Utils from "services/utils/Utils";
import { SegmentForm, formatCPF } from "./components/segmentForm/segmentForm";

export interface ICounponFormProps {
  defaultValues?: IDiscountCouponItem;
  onClose: () => void;
  refrash: (
    params?: IParamsClientDiscountCouponsList | undefined
  ) => Promise<IGetDiscountCouponsResponse | undefined>;
}

export enum EnumCouponStep {
  DETALHES = 0,
  SEGMENTACAO,
  OPERACAO,
  EQUIPAMENTOS,
}

export const CouponForm: FC<ICounponFormProps> = ({
  defaultValues,
  onClose,
  refrash,
}) => {
  const [cpfs, setCpfs] = useState<string[]>([]);
  const [values, setValues] = useState<ICouponForm>({
    ownerId: "",
    code: "",
    description: "",
    valueType: 1,
    value: 0,
    maxValue: 0,
    quantity: 1,
    forFirstOrder: false,
    quantityPerUser: 1,
    minOrderValue: 0,
    maxOrderValue: 0,
    startAt: "",
    finishAt: "",
    operations: [],
    deviceTypes: [],
    devices: [],
    isRequiredCustomerCPF: false,
    customerCPFs: [],
  });
  const [errors, setErrors] = useState<ICouponFormErros>({});
  const [step, setStep] = useState<EnumCouponStep>(EnumCouponStep.DETALHES);
  const {
    isLoadingAdd,
    postAddCoupon,
    successAdd,
    setSuccessAdd,
    couponIdResponse,
  } = UseAddCoupon(onClose);
  const { putEditCoupon, isLoadingEdit, successEdit, setSuccessEdit } =
    UseEditCoupon(onClose);
  const {
    getDiscountCouponItemAfterCreation,
    discountCouponsItemAfterCreation,
    isLoading,
  } = UseDiscountCouponsItemAfterCreation();
  const [isEdit, setIsEdit] = useState(false);
  const [isPorcent, setIsPorcent] = useState(false);
  const [isRequiredCPF, setIsRequiredCPF] = useState(false);

  const [openSideSheetDownloadCoupon, setOpenSideSheetDownloadCoupon] =
    useState<boolean>(false);

  const openDownloadCoupon = useCallback(() => {
    if (isEdit) {
      defaultValues &&
        getDiscountCouponItemAfterCreation(defaultValues.couponId);
    } else {
      couponIdResponse &&
        getDiscountCouponItemAfterCreation(couponIdResponse.value.couponId);
    }

    setOpenSideSheetDownloadCoupon(true);
  }, [
    couponIdResponse,
    defaultValues,
    getDiscountCouponItemAfterCreation,
    isEdit,
  ]);

  const closeDownloadCoupon = useCallback(() => {
    setOpenSideSheetDownloadCoupon(false);
  }, []);

  const { toast } = useUi();

  const onCloseDropDownSuccess = useCallback(() => {
    onClose();
    setSuccessAdd(false);
    setSuccessEdit(false);
    refrash({
      pagination: {
        page: 0,
        pageSize: 20,
      },
      sort: {
        orientation: "asc",
        type: "code",
      },
    });
  }, [onClose, refrash, setSuccessAdd, setSuccessEdit]);

  useEffect(() => {
    if (defaultValues) {
      setIsEdit(true);
      setValues({
        ...defaultValues,
        startAt: Utils.toInputDateString(defaultValues.startAt),
        finishAt: Utils.toInputDateString(defaultValues.finishAt),
      });
      if (defaultValues?.customerCPFs) {
        const formatedCpfs = defaultValues?.customerCPFs.map((item) =>
          formatCPF(item)
        );
        setCpfs(formatedCpfs);
      }
      if (defaultValues?.isRequiredCustomerCPF) {
        setIsRequiredCPF(defaultValues?.isRequiredCustomerCPF);
      }
    }
  }, [defaultValues]);

  const onChangeFormStep = useCallback(
    (value: EnumCouponStep) => {
      if (validate(values, setErrors)) {
        setStep(value);
      } else {
        toast("Campo(s) obrigatório(s) não preenchido ou inválido", "error");
      }
    },
    [toast, values]
  );

  const changeHandle = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const changeHandleNumber = useCallback((name: string, value: any) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const changeHandleNumberAbsolute = useCallback(
    (name: string, value: string) => {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (Number(value) <= 100) {
          setValues((prev) => ({ ...prev, [name]: value }));
        }
      }
    },
    []
  );

  const changeHandleChecked = useCallback((name: string, checked: boolean) => {
    setValues((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const changeHandleCheckedOperation = useCallback(
    (value: number) => {
      const newArray: number[] = [...values.operations];

      if (newArray.includes(value)) {
        newArray.splice(newArray.indexOf(value), 1);
      } else {
        newArray.push(value);
      }

      setValues((prev) => ({ ...prev, operations: newArray }));
    },
    [values.operations]
  );

  const onSubmitAddCoupon = useCallback(
    async (values: ICouponForm) => {
      if (isEdit && defaultValues) {
        const { isRequiredCustomerCPF, customerCPFs, ...rest } = values;
        
        await putEditCoupon(
          {
            ...rest,

            code: values.code.trim(),
            startAt: new Date(values.startAt).toISOString(),
            finishAt: new Date(values.finishAt).toISOString(),
            isRequiredCustomerCPF: isRequiredCPF,
            customerCPFs: cpfs,
          },
          defaultValues.couponId
        );
      } else {
        await postAddCoupon({
          ...values,
          customerCPFs: cpfs,
          isRequiredCustomerCPF: isRequiredCPF,
          startAt: new Date(values.startAt).toISOString(),
          finishAt: new Date(values.finishAt).toISOString(),
        });
      }
    },
    [cpfs, defaultValues, isEdit, isRequiredCPF, postAddCoupon, putEditCoupon]
  );

  return (
    <div id={styles.CouponForm}>
      {successAdd || successEdit ? (
        <div id={styles.successModal}>
          <div className={styles.main}>
            <div className={styles.info}>
              <img src={successImg} alt="ícone de sucesso" />
              {isEdit ? (
                <p>Pronto! Você editou um cupom de desconto.</p>
              ) : (
                <p>Pronto! Você criou um novo cupom de desconto.</p>
              )}
            </div>

            <div className={styles.qrcode} onClick={openDownloadCoupon}>
              <span>
                {" "}
                <VerticalAlignBottom /> QR Code do cupom
              </span>
              <KeyboardArrowRight />
            </div>
          </div>

          <div>
            <Button
              fullWidth={false}
              onClick={onCloseDropDownSuccess}
              color="primary"
              variant="contained"
              className={styles.contained}
            >
              Fechar
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div>
            {(isLoadingAdd || isLoadingEdit) && (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            )}
          </div>
          <div className={styles.tab}>
            <TabView
              index={step}
              tabs={[
                { label: "Detalhes", value: EnumCouponStep.DETALHES },
                { label: "Segmentação", value: EnumCouponStep.SEGMENTACAO },
                { label: "Operação", value: EnumCouponStep.OPERACAO },
                { label: "Equipamentos", value: EnumCouponStep.EQUIPAMENTOS },
              ]}
              onChangeTab={(tab, value) => onChangeFormStep?.(value)}
            />
          </div>
          <div className={styles.container}>
            {step === EnumCouponStep.DETALHES && (
              <div className={styles.content}>
                <DetailsForm
                  values={values}
                  changeHandle={changeHandle}
                  setValues={setValues}
                  changeHandleChecked={changeHandleChecked}
                  onChangeFormStep={onChangeFormStep}
                  onClose={onClose}
                  errors={errors}
                  setErrors={setErrors}
                  changeHandleNumber={changeHandleNumber}
                  isPorcent={isPorcent}
                  changeHandleNumberAbsolute={changeHandleNumberAbsolute}
                  setIsPorcent={setIsPorcent}
                />
              </div>
            )}
            {step === EnumCouponStep.SEGMENTACAO && (
              <div className={styles.content}>
                <SegmentForm
                  onChangeFormStep={onChangeFormStep}
                  cpfs={cpfs}
                  setCpfs={setCpfs}
                  isRequiredCPF={isRequiredCPF}
                  setIsRequiredCPF={setIsRequiredCPF}
                />
              </div>
            )}
            {step === EnumCouponStep.OPERACAO && (
              <div className={styles.content}>
                <OperationForm
                  values={values}
                  changeHandleChecked={changeHandleCheckedOperation}
                  onChangeFormStep={onChangeFormStep}
                />
              </div>
            )}

            {step === EnumCouponStep.EQUIPAMENTOS && (
              <div className={styles.content}>
                <EquipamentForm
                  values={values}
                  setValues={setValues}
                  onSubmitAddCoupon={onSubmitAddCoupon}
                  onChangeFormStep={onChangeFormStep}
                  isLoading={isLoadingAdd}
                  isEdit={isEdit}
                />
              </div>
            )}
          </div>
        </>
      )}

      <Drawer
        open={openSideSheetDownloadCoupon}
        anchor="right"
        onClose={closeDownloadCoupon}
        style={{ zIndex: 9999 }}
      >
        <div id={styles.drawer}>
          <DownloadCoupon
            item={discountCouponsItemAfterCreation}
            closeDownloadCoupon={closeDownloadCoupon}
            isLoading={isLoading}
          />
        </div>
      </Drawer>
    </div>
  );
};
