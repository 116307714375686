import { FC, useCallback, useMemo } from "react";
import { IDeviceItem } from "./interfaces/IDeviceItem";
import { Pagination } from '@mui/material';
import DeviceItem from "./DeviceItem";
import styles from "./DeviceList.module.scss";
import { IPaginationProfileDevice } from '../filterDevice/IFilterDevice';

interface DeviceListProps {
  deviceList: IDeviceItem[];
  selectedDevices: IDeviceItem[];
  onClickCopyItem: (item: IDeviceItem) => void;
  onClickItem: (item: IDeviceItem) => void;
  onSelectItem: (item: IDeviceItem) => void;
  onChangePaginationHandle: (pagination: IPaginationProfileDevice) => void;
  pagination?: IPaginationProfileDevice;
  totalCount: number;
  perfilVinculo?: number
}

const DeviceList: FC<DeviceListProps> = ({
  deviceList,
  selectedDevices,
  onClickItem,
  onClickCopyItem,
  onSelectItem,
  onChangePaginationHandle,
  pagination,
  totalCount,
  perfilVinculo
}) => {
  const List = useMemo(
    () => (
      <div className={styles.listContainer}>
        {deviceList.map((deviceItem: any) => (
          <DeviceItem
            key={deviceItem.id}
            deviceItem={deviceItem}
            selectedDevices={selectedDevices}
            onClickCopyItem={onClickCopyItem}
            onClickItem={onClickItem}
            onSelectItem={onSelectItem}
            perfilVinculo={perfilVinculo}
          />
        ))}
      </div>
    ),
    [deviceList, onClickCopyItem, onClickItem, onSelectItem, selectedDevices]
  );

  const onChangePageHandle = useCallback(
    (page: number, pageSize?: number) => {
        onChangePaginationHandle({ ...pagination, offset: page - 1, limit: pageSize });
    },
    [onChangePaginationHandle, pagination]
  );

  return (
      <div id={styles.profileList}>
          {List}

          {totalCount > 50 && <div className={styles.containerPagination}>
              <Pagination
                  variant="outlined"
                  className={styles.paginationNumber}
                  shape="rounded"
                  count={totalCount / 50}
                  sx={{
                      '.MuiPaginationItem-root': {
                          borderColor: '#BDBDBD',
                          color: '#001537',
                      },
                      '.Mui-selected': {
                          fontWeight: 'bold',
                          borderColor: '#001537',
                      },
                  }}
                  onChange={(_, page) => onChangePageHandle?.(page, 50)}
                  page={((pagination?.offset ? (pagination?.offset / 50) + 1 : 1))}
              />
          </div>}
      </div>
    );
};

export default DeviceList;
