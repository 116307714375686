import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import styles from '../FidelityEditSummary.module.scss';
import { IFidelitySummary, IOwnerFidelityPlans } from 'modules/fidelity/presentation/interfaces/IFidelitySummary'
import { Button, Icon, TextField } from '@material-ui/core';
import { BalanceType } from 'modules/fidelity/presentation/interfaces/IFidelityBalance';

export interface ISummaryPointsFormValue {
    balanceType: BalanceType,
    points?: number,
    reason: string,
    coins?: number,
}

export interface IFidelityPointsFormProps {
    //propertys
    customerData?: IFidelitySummary;
    onSubmit: (values: any) => void;
    isLoading?: boolean
}



const FidelityPointsForm: FC<IFidelityPointsFormProps> = ({ customerData, isLoading, onSubmit }) => {

    var [selectedPlan, setSelectedPlan] = useState<IOwnerFidelityPlans>();

    const [values, setValues] = useState<ISummaryPointsFormValue>({
        balanceType: BalanceType.ADD_BALANCE,
        points: 0,
        reason: "",
    });

    const [errors, setErrors] = useState<{
        balanceType?: string;
        points?: string;
        reason?: string
    }>({})

    const changePointsHandle = useCallback((value: string) => {
        setValues((prev) => ({ ...prev, points: Number(value) }));
    }, []);
    const changeBalanceTypeHandle = useCallback((type: BalanceType) => {
        setValues((prev) => ({ ...prev, balanceType: type }));
    }, []);
    const changeReasonHandle = useCallback((reason: string) => {
        setValues(prev => ({ ...prev, reason: reason }))
    }, [])

    useEffect(() => {
        const _selectedPlan = customerData?.ownerFidelityPlans.find((item, index, array) => {
            const presumePoints = (customerData.summaries.pointsSummary) + ((values?.points || 0) * (values.balanceType === BalanceType.ADD_BALANCE ? 1 : -1));
            const nextPlan = array[index + 1]
            if ((item.pointsRequired <= presumePoints && (!nextPlan || nextPlan.pointsRequired > presumePoints))) {
                return true;
            } else {
                return false
            }

        })
        if (_selectedPlan) {
            setSelectedPlan(_selectedPlan);
        }
    }, [customerData?.ownerFidelityPlans, customerData?.summaries.pointsSummary, values.balanceType, values?.points])


    const onclickPlanHandle = useCallback((plan: IOwnerFidelityPlans) => {
        const diff = plan.pointsRequired - (customerData?.summaries.pointsSummary ?? 0);
        let _type = BalanceType.ADD_BALANCE
        if (diff < 0)
            _type = BalanceType.SUB_BALANCE

        setValues(prev => ({ ...prev, balanceType: _type, points: Math.abs(diff) }))
    }, [customerData?.summaries.pointsSummary])


    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        var hasError = false;
        if (!values.points) {
            setErrors(prev => ({ ...prev, points: 'Preencha um valor válido.' }))
            hasError = true;
        }

        if (values.points && !/^(|-?\d+)$/.test(values.points.toString())) {
            setErrors(prev => ({ ...prev, points: 'Valor inválido.' }))
            hasError = true;
        }

        if (!values.reason) {
            setErrors(prev => ({ ...prev, reason: 'Preencha um motivo.' }))
            hasError = true;
        }
        if (hasError) {
            return
        }
        onSubmit(values)
    }, [onSubmit, values]);

    return (
        <form onSubmit={submitHandle}>
            <div className={`${styles.row} ${styles.mt24}`}>
                <span className={styles.label}>Nível atual: <strong> {customerData?.actualFidelityPlan.name} </strong><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span></span>
                <span className={styles.label}> Quantidade de pontos atual: <strong> {customerData?.summaries.pointsSummary}</strong></span>
            </div>

            <span className={`${styles.rootLabel} ${styles.mt16}`}>Clique para alterar o nível e chegar na quantidade mínima de pontos do nível selecionado</span>

            <div className={styles.plansContainer}>
                {
                    customerData?.ownerFidelityPlans.map(item => (
                        (item.pointsRequired !== undefined && item.pointsRequired !== null) && <button
                            key={item.id}
                            onClick={() => onclickPlanHandle(item)}
                            type='button'
                            className={selectedPlan?.id === item.id ? styles.selected : ''}>
                            {item.name}
                        </button>
                    ))
                }
            </div>

            <span className={styles.rootLabel}>Ou digite quantos pontos você deseja adicionar:</span>

            <div className={styles.pointsContainer}>
                <div>
                    <div>
                        <button type="button" onClick={() => changeBalanceTypeHandle(BalanceType.ADD_BALANCE)} className={`${values.balanceType === BalanceType.ADD_BALANCE ? styles.selected : ''}`}>Adicionar <div><Icon>add</Icon></div></button>
                        <button type="button" onClick={() => changeBalanceTypeHandle(BalanceType.SUB_BALANCE)} className={`${values.balanceType === BalanceType.SUB_BALANCE ? styles.selected : ''}`}>Excluir <div><Icon>remove</Icon></div></button>
                    </div>
                    <span className={styles.error}>{errors.balanceType}</span>
                </div>

                <div>
                    <TextField
                        placeholder='0'
                        inputProps={{ maxLength: 10 }}
                        size='small'
                        value={values.points}
                        onChange={(e) => {
                            changePointsHandle(e.target.value);
                        }}
                        fullWidth
                        error={!!errors.points}
                    />
                    <span className={styles.error}>{errors.points}</span>
                </div>
            </div>

            <label>Motivo <span>*</span></label>
            <TextField
                placeholder='Digite o motivo da alteração'
                size='small'
                value={values.reason}
                onChange={(e) => {
                    changeReasonHandle(e.target.value);
                }}
                fullWidth
                error={!!errors.reason}
            />
            <span className={styles.error}>{errors.reason}</span>

            <div className={styles.buttonContainer}>
                <Button className={styles.buttons} disabled={isLoading} type='submit' variant='contained' color='secondary'>
                    {isLoading ? 'Salvando...' : 'Salvar'}
                </Button>
            </div>
        </form>)
}
export default FidelityPointsForm