import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import FormContainer from "../../formContainer/FormContainer";
import styles from "./DeviceManualForm.module.scss";
import { DeviceValue } from "./interfaces/DeviceValue";
import { MenuItem } from "@material-ui/core";

export interface IDeviceManualFormProps {
  //propertys
  defaultValue?: DeviceValue;
  defaultType?: DeviceType;
  onSubmit: (value: DeviceValue) => void;
  onClickBack: () => void;
  onClickClose: () => void;
}

const DeviceManualForm: FC<IDeviceManualFormProps> = ({
  defaultValue,
  onSubmit,
  defaultType,
  onClickBack,
  onClickClose,
}) => {
  const [values, setValues] = useState<DeviceValue>(
    defaultValue ?? {
      name: "",
      macAddress: "",
      serialNumber: "",
      type: defaultType ?? DeviceType.POS,
    }
  );

  useEffect(() => {
    if (defaultValue) {
      setValues(defaultValue);
    }
    if (defaultType) {
      setValues((prev) => ({ ...prev, defaultType: defaultType }));
    }
  }, [defaultType, defaultValue]);

  const onChangeHandle = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    },
    []
  );

  const onSubmitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(values);
    },
    [onSubmit, values]
  );

  return (
    <div id={styles.DeviceManualForm}>
      <div className={styles.container}>
        <form onSubmit={onSubmitHandle}>
          <FormContainer
            title={
              <div>
                Adicionar <b>Dispositivo</b>
              </div>
            }
            onClickClose={onClickClose}
            footerProps={{
              nextType: "submit",
              onClickBack: onClickBack,
            }}
          >
            <FormItemContainer>
              <FormItemContainer label={"Tipo de Dispositivo"}>
                <Input
                  onChange={(ev) => onChangeHandle(ev)}
                  name={"type"}
                  disabled={!!defaultType}
                  value={String(values.type)}
                  select
                >
                  <MenuItem value={DeviceType.POS}>POS</MenuItem>
                  <MenuItem value={DeviceType.TOTEM}>Totem</MenuItem>
                  <MenuItem value={DeviceType.TABLET}>Tablet</MenuItem>
                  <MenuItem value={DeviceType.CHECK}>Validador</MenuItem>
                </Input>
              </FormItemContainer>
              <FormItemContainer label={"Nome"}>
                <Input
                  onChange={(ev) => onChangeHandle(ev)}
                  name={"name"}
                  value={values.name}
                />
              </FormItemContainer>
              <FormItemContainer label={"Serial"}>
                <Input
                  onChange={(ev) => onChangeHandle(ev)}
                  name={"serialNumber"}
                  value={values.serialNumber}
                />
              </FormItemContainer>
              <FormItemContainer label={"MAC-Address"}>
                <Input
                  name={"macAddress"}
                  value={values.macAddress}
                  onChange={onChangeHandle}
                />
                {/* <InputMask name={"macAddress"} onChange={onChangeHandle} value={values.macAddress} > */}
                {/* {
                                        (inputProps: React.InputHTMLAttributes<HTMLInputElement>) => <Input inputProps={{ ...inputProps }} />
                                    } */}
              </FormItemContainer>
            </FormItemContainer>
          </FormContainer>
        </form>
      </div>
    </div>
  );
};
export default DeviceManualForm;
