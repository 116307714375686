import React, { FC, useEffect, useState } from 'react'
import styles from './External.module.scss'
import UseQueryParams from 'modules/catalog/presentation/hooks/UseQueryParams'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage';
export interface IExternalProps {
    //propertys
}
const External: FC<IExternalProps> = () => {

    const params = UseQueryParams();
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        const _url = params.get('url');
        if (_url) {

            setUrl(_url);
        }
    }, [params])

    return (
        url ?
            <IframePage fullScreen fullSrc={url} />
            : null
    )
}
export default External
