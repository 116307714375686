import { Button, Icon, IconButton } from "@material-ui/core";
import Layout from "components/layout/presentation/Layout";
import React, { FC, useCallback, useState } from "react";
import { v4 } from "uuid";
import CustomerFilter from "../components/filter/CustomerFilter";
import List, { ColumnConfigs } from "../components/list/List";
import styles from "./CustomersPage.module.scss";
import { CSVLink } from "react-csv";
import { key } from "localforage";
import { UseCustomersPage } from "./UseCustomersPage";
export interface ICustomerPageProps {
  //propertys
}
const CustomersPage: FC<ICustomerPageProps> = () => {
  const {
    customersData,
    locals,
    events,
    pagination,
    length,
    setPagination,
    filter,
    onChangeFilter,
  } = UseCustomersPage();

  const configColumns: ColumnConfigs = {
    imageUrl: { text: "Imagem", hideTitle: true, hide: true },
    name: { text: "Nome" },
    phone: { text: "Celular" },
    email: { text: "E-mail" },
    event: { text: "Evento" },
    countVisit: { text: "Visitas", width: 80 },
    lastVisit: { text: "Última Visita", width: 130 },
    averageConsumer: { text: "Consumo Médio", width: 130 },
    totalConsumer: { text: "Consumo Total", width: 130 },
  };

  const generateColunsHeaderCSV = useCallback(
    (configColumns: ColumnConfigs) => {
      return Object.keys(configColumns).map((key) => ({
        label: configColumns[key].text ?? "",
        key: key,
      }));
    },
    []
  );

  return (
    <div id={styles.CustomerPage}>
      <h2> Clientes </h2>
      <div className={styles.container}>
        <div
          className={styles.item}
          style={{
            flex: 0,
            display: "flex",
            padding: "0 16px",
            justifyContent: "flex-end",
          }}
        >
          {customersData && (
            <CSVLink
              filename={"Lista de Clientes"}
              data={customersData}
              headers={generateColunsHeaderCSV(configColumns)}
            >
              <Button size={"small"} variant={"outlined"}>
                <Icon>vertical_align_bottom</Icon>Exportar
              </Button>
            </CSVLink>
          )}
        </div>
        {locals && events && (
          <CustomerFilter
            events={events}
            locals={locals}
            defaultValues={filter}
            onChange={onChangeFilter}
          />
        )}
        <List
          list={customersData ?? []}
          onClickSort={(property) => {}}
          onClick={(value) => {}}
          columnsConfigs={configColumns}
          actionButtons={[
            { icon: "add", onClick: (item) => {} },
            { icon: "edit", onClick: (item) => {} },
            { icon: "close", onClick: (item) => {} },
          ]}
          onChangePage={(page, size) => setPagination({ page, size })}
          pagination={{
            page: pagination.page,
            pageSize: pagination.size,
            count: length ?? 0,
          }}
        />
      </div>
    </div>
  );
};

export default CustomersPage;
