import { Drawer, Icon } from '@material-ui/core';
import styles from './DowngradeRequestSuccessModal.module.scss';

interface DowngradeRequestSuccessModalProps {
    open: boolean;
    onClose: () => void;
}

export const DowngradeRequestSuccessModal = ({ open, onClose }: DowngradeRequestSuccessModalProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Voltar para o <b>Plano Básico</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>

                <div className={styles.content}>

                    <div className={styles.successContainer}>
                        <img src="/assets/img/concluded.png" alt="" />
                        
                            <span>Pronto! Sua <b>solicitação do cancelamento</b> do Plano Premium foi enviada.</span>
                    </div>
                    
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}