import { AxiosInstance } from "axios"
import { BankDetailDepositResponse } from "modules/contaDigital/models/dtos/BankDetailDepositResponse";

const ObterDadosBancariosDeposito = async (api: AxiosInstance, ownerId: string): Promise<BankDetailDepositResponse[]> => {
    const response = await api.get<DadoBancarioDeposito[]>(`/Local/BuscarDadosBancariosParaDepositoDoLocal/${ownerId}`);

    return response.data.map(bank => (
        {
            id: bank.id,
            description: bank.descricao,
            bank: bank.banco,
            branch: bank.agencia,
            account: bank.contaCorrente,
            document: bank.documento,
            name: bank.nome,
            branchNumber: bank.agenciaNumero,
            branchDigit: bank.agenciaDigito,
            accountNumber: bank.contaNumero,
            accountDigit: bank.contaDigito,
        }
    ));
};

export default ObterDadosBancariosDeposito;

export interface DadoBancarioDeposito {
    id: string
    descricao: string
    banco: string
    agencia: string
    contaCorrente: string
    documento: string
    nome: string
    agenciaNumero: string
    agenciaDigito: string
    contaNumero: string
    contaDigito: string
}