import styles from "../../ImportDrawer.module.scss";
import Button from "components/ui/Button/Button";
import { FC, useState } from "react";
import { ProductsApi } from "services/api/products/ProductsApi";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { ImportViewProps } from "./importView.types";

const ImportView: FC<ImportViewProps> = ({ setNavigation, onClose }) => {
  const { currentLocal } = useLocal();
  const serviceProduct = ProductsApi();

  const localId = currentLocal?.id;

  const submitProductsImport = async () => {
    await serviceProduct
      .ImportProduct({
        localId: localId as string,
        conteudo: conteudo as string,
      })
      .then(() => {
        setNavigation("sucess");
      })
      .catch(() => {
        setNavigation("error");
      });
  };

  const [conteudo, setConteudo] = useState<string>();

  return (
    <>
      <div className={styles.content}>
        <p>Insira cada produto em uma linha com o formato a seguir:</p>
        <p>
          Nome Categoria;Nome Produto;Valor;NCM;CEST;CFOP;Código de
          Barras;Código interno;Id do produto;Código integração
        </p>
        <textarea
          placeholder="Ex.:
Refrigerantes;Coca-cola;5,00;01.002.00;2202.10.00;5.102;7894900701159;312;b641a0db-b16d-4f82-b1fc-391f2a81181c
Refrigerantes;Guaraná;4,00;2202.10.00;5.405
Sucos;Limonada;8,00;12"
          onChange={(e) => setConteudo(e.target.value)}
        ></textarea>
      </div>
      <div className={styles.actionsRow}>
        <Button fullWidth={false} variant="outlined" onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button fullWidth={false} onClick={() => submitProductsImport()}>
          Continuar
        </Button>
      </div>
    </>
  );
};

export { ImportView };
