import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetDiscountCouponItemUseCase from "../../application/useCases/GetDiscountCouponItemUseCase ";
import { IDiscountCouponItem } from "../interfaces/IDiscountCouponItem";



const UseDiscountCouponsItem = () => {

  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>();
  const [discountCouponsItem, setDiscountCouponsItem] = useState<IDiscountCouponItem>();
  const { currentLocal } = useLocal();

  const getDiscountCouponItem = useCallback(
    async (couponId: string) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoadingEdit(true);
        if (currentLocal) {
          const response = await GetDiscountCouponItemUseCase(serviceDiscountCoupons,
            currentLocal.id,
            couponId
          );

          setDiscountCouponsItem(response);
          return response
        }
      } finally {
        setIsLoadingEdit(false);
      }
    },
    [currentLocal]
  );


  return {
    discountCouponsItem,
    isLoadingEdit,
    getDiscountCouponItem,
    setDiscountCouponsItem,
  };
};

export default UseDiscountCouponsItem;
