import { FC, useCallback } from "react";
import { ISelect } from "../../../../config/deviceConfig/presentation/interfaces/ISelect";
import styles from "./FilterDevice.module.scss";
import "./FilterDevice.css";
import { IFilterDevice } from "./IFilterDevice";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { TabFilter } from "components/ui/tabFilter/TabFilter";
// import Button from 'components/ui/Button/Button';
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import UseDevicePage from "../../pages/UseDevicePage";
import UseDimension from "components/dimension/UseDimension";

export interface IFilterDeviceProps {
  value: IFilterDevice;
  onChange: (value: IFilterDevice) => void;
  onFilter: () => void;
}

const deviceTypeFilter = [
  {
    id: DeviceType.POS,
    name: "POS",
  },
  {
    id: DeviceType.TOTEM,
    name: "Totem",
  },
  {
    id: DeviceType.TABLET,
    name: "Tablet",
  },
  {
    id: DeviceType.CHECK,
    name: "MeepCheck",
  },
  {
    id: DeviceType.TERMINAL,
    name: 'Terminal',
},
];

const FilterDevice: FC<IFilterDeviceProps> = ({
  value,
  onChange,
  onFilter,
}) => {
  const {
    profileList,
  } = UseDevicePage();
  const { dimensions } = UseDimension();

  const onChangeProfile = useCallback(
    (selectedValues: ISelect[]) => {
      onChange({
        ...value,
        profiles: selectedValues.map((x) => ({ name: x.name, id: x.id })),
      });
    },
    [onChange, value]
  );

  const onChangeDeviceHandler = useCallback(
    (item) => {
      const selectedItem = value.types?.find((x) => x.id === item.id);
      let arr: ISelect[] = [];

      if (selectedItem) {
        arr = value.types?.filter((x) => x.id !== item.id) || [];
      } else {
        arr = [...(value.types || []), item];
      }

      onChange({ ...value, types: arr });
    },
    [onChange, value]
  );

  return (
    <div id={styles.FilterDevice}>
      <FormItemContainer 
        label={"Equipamento"}
        className={styles.formLabel}
        padding={0}
        labelMargin="0 0 8px 0"
        width="100%"
      >
        <TabFilter
          items={deviceTypeFilter}
          value="id"
          label="name"
          selectedItems={value.types?.map((x) => x.id) || []}
          onChange={onChangeDeviceHandler}
        />
      </FormItemContainer>
      <FormItemContainer
        label={"Perfil"}
        className={`${styles.formLabel} ${styles.profileFilter}`}
        minWidth={dimensions.width > 530 ? 300 : undefined}
        maxWidth={dimensions.width > 750 ? 300 : undefined}
        padding={0}
        labelMargin="0 0 8px 0"
        width={dimensions.width < 530 ? "100%" : ""}
      >
        <AutoComplete
          className="autocomplete-filter-profile"
          disabled={!profileList?.length}
          variant="outlined"
          placeholder={!value.profiles?.length ? "Filtrar perfil" : ""}
          multiple
          options={profileList || []}
          getOptionLabel={(option) => option.name}
          onChange={(_, val) => {
            onChangeProfile(val as ISelect[]);
          }}
        />
      </FormItemContainer>
    </div>
  );
};
export default FilterDevice;
