import React, { useCallback, useMemo, useState } from 'react'
import styles from './LinkCardsItemMobile.module.scss'
import { DeleteOutline } from '@mui/icons-material';
import Sidesheet from 'components/sidesheet/Sidesheet';
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback';
import { ILinkCardsList, ILinkCardItem } from '../../../../interfaces/ILinkCardsList';
import { IParamsLinkCardsList } from 'modules/linkCards/presentation/hooks/UseLinkCardsList';
import Utils from 'services/utils/Utils';
import UseDeleteLinkCardsItem from 'modules/linkCards/presentation/hooks/UseDeleteLinkCardsItem';
import DeleteValidation from '../../../../../../../components/deleteValidation/DeleteValidation';

interface ILinkCardsItemMobileMobileProps {
    userItemData: ILinkCardItem;
    linkCardsList: ILinkCardsList;
    reload: () => void;
    params: IParamsLinkCardsList;
    setOpenSidesheet: React.Dispatch<React.SetStateAction<"add" | null>>
}

export const LinkCardsItemMobile: React.FC<ILinkCardsItemMobileMobileProps> = ({ setOpenSidesheet,  reload, userItemData }) => {
    const [step, setStep] = useState(1);
    const [openDrownDelete, setOpenDrownDelete] = useState(false)

    const { deleteLinkCardsItem, isLoadingDelete, successDelete } = UseDeleteLinkCardsItem();

    const onClose = useCallback(() => {
      if(step === 2) {
        reload();
      }
      setStep(1)
      setOpenDrownDelete(false)
    }, [reload, step])
   
    const continueOrSave = useCallback(async () => {  
        const res = await deleteLinkCardsItem(userItemData.tag);
        if (successDelete.current) {  
          return setStep(step + 1);
        }
      
    }, [deleteLinkCardsItem, step, successDelete, userItemData.tag])
  
    const closedOrDeleteButton = useCallback(() => {
      if (step === 1) {
        return "Excluír"
      } else {
        return "Fechar"
      }
    }, [step])

    const handleContent = useCallback(() => {
        let highlightText = `${userItemData.tag}?`;
        let message = `Deseja realmente excluír`;    
 
        return <DeleteValidation text={message} highlightText={highlightText} />
    }, [userItemData.tag])



    const openDrowDelete = useCallback(
        () => {
            setOpenDrownDelete(true);
        },
        []
    );

    const List = useMemo(() => (
        <div className={styles.containerItem}>
            <div className={styles.top}>
                <span>{userItemData.tag}</span>
                <div className={styles.icon}>
                    {
                        <>
                            <DeleteOutline onClick={openDrowDelete} />
                        </>
                    }
                </div>
            </div>
            <div className={styles.mid}>
                <span>Tag:</span>
                <span>{(userItemData.tag)}</span>
            </div>
            <div className={styles.mid}>
                <span>Vinculado por:</span>
                <span>{(userItemData.createdBy)}</span>
            </div>
            <div className={styles.footer}>
                <span>Vinculado em:</span>
                <span>{Utils.toDate(userItemData.createdAt)}</span>
            </div>
        </div>
    ), [openDrowDelete, userItemData.createdAt, userItemData.createdBy, userItemData.tag]);

    return (
        <div id={styles.PermutationAccountListMobile}>
            {List}
            <Sidesheet
                isLoading={isLoadingDelete}
                open={openDrownDelete}
                onClose={onClose}
                title={
                    <h2>
                        Excluir <b>vínculo</b>
                    </h2>
                }
                content={handleContent()}
                currentStep={step}
                totalSteps={2}
                handleContinueButton={continueOrSave}
                continueButton={closedOrDeleteButton()}
                feedback={
                    <SidesheetFeedback text="Vínculo excluído com sucesso!" success />
                }
            />
        </div>
    )
}
