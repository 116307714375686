import { isAfter, isValid, parse } from "date-fns";
import CPF from "services/utils/CPF";
import { z } from "zod";

const validateSequence = (input: string): boolean => {
  const regex = /^(\d)\1*$/;
  return !regex.test(input);
};

export const profileSchema = z.object({
  name: z.string()
    .min(1, { message: "Campo obrigatório!" })
    .refine((input) => input.trim().split(' ').length > 1, {
      message: "Informe o sobrenome.",
    }),
  phoneNumber: z.string()
    .min(11, "Campo obrigatório!")
    .refine((input) => validateSequence(input), { message: "Telefone inválido!" }),
  birthday: z.string()
    .min(8, { message: "Campo obrigatório!" })
    .refine((input) => {
      const parseDate = parse(input, "dd/MM/yyyy", new Date());
      const isValidDate = isValid(parseDate);
      const isAfterDate = !isAfter(parseDate, new Date());
      const year = input.slice(-4);
      const cleanedInput = input.replace(/\D/g, "");

      return isValidDate && year >= "1900" && isAfterDate && cleanedInput.length === 8;
    }, { message: "Data inválida." }
  ),
  document: z.string()
    .min(11, "Campo obrigatório!")
    .refine((input) => validateSequence(input) && CPF.isValid(input), { message: "CPF inválido!" }),
});

export type IProfileSchema = z.infer<typeof profileSchema>;
