import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { CheckDeviceLinkCodeUseCase } from "modules/equipamento/application/useCases/deviceLink/CheckDeviceLinkCodeUseCase";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceApi from "services/api/equipamento/DeviceApi";
import GetDeviceLinkCodeUseCase from "modules/equipamento/application/useCases/deviceLink/GetDeviceLinkCodeUseCase";

const UseDeviceLink = (deviceType?: DeviceType, localId?: string) => {
  const [code, setCode] = useState<string>();
  const { showLoading, hideLoading, toast } = useUi();
  const { push } = useHistory();

  const [linked, setLinked] = useState({
    linked: false,
    deviceId: "",
    expired: false,
  });
  const getCode = useCallback(async () => {
    try {
      if (localId && deviceType) {
        showLoading();
        const service = DeviceApi();
        const response = await GetDeviceLinkCodeUseCase(
          service,
          localId,
          deviceType
        );
        setCode(response.code);
      } else {
        console.error("Local não selecionado");
      }
    } finally {
      hideLoading();
    }
  }, [deviceType, hideLoading, localId, showLoading]);

  const checkLink = useCallback(async () => {
    try {
      showLoading();
      const service = DeviceApi();
      if (code) {
        const response = await CheckDeviceLinkCodeUseCase(service, code);
        setLinked(response);
      }
    } finally {
      hideLoading();
    }
  }, [code, hideLoading, showLoading]);

  useEffect(() => {
    if (linked.expired) {
      getCode();
      toast("Codigo expirado favor tentar novamente", "error");
    } else {
      if (linked.linked) {
        push(
          `/private/configuracao/pdv/lista/adicionar/${FormDeviceStep.PROFILE}/${deviceType}/${linked.deviceId}`
        );
      }
    }
  }, [
    deviceType,
    getCode,
    linked.deviceId,
    linked.expired,
    linked.linked,
    push,
    toast,
  ]);

  return {
    getCode,
    checkLink,
    linked,
    code,
  };
};

export default UseDeviceLink;
