import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GetCampaignsUseCase from "modules/crm/application/useCases/GetCampaignsUseCase";
import { ICampaignItem, ICampaignData, ICampaignValues } from "../intefaces/ICampaign";
import CrmServiceApi from "services/api/crm/CrmApi";
import GetCampaignByIdUseCase from "modules/crm/application/useCases/GetCampaignByIdUseCase";

const UseCampaign = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [campaign, setCampaign] = useState<ICampaignValues>();

  const getCampaignById = useCallback(async (campaignId: string) => {
    try {
      const service = CrmServiceApi();
      setIsLoading(true);
      const response = await GetCampaignByIdUseCase(service, campaignId);
      setCampaign(response);
      return response
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    campaign,
    isLoading,
    getCampaignById
  };
};

export default UseCampaign;
