import { InfoOutlined } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import styles from "./PaymentFormsStep.module.scss";

const PaymentFormsStep = () => {
    return (
        <div id={styles.PaymentFormsStep}>
            <p className={styles.required}>Configure o seu pagamento online</p>
            <span>Habilite e gerencie suas configurações de processamento de pagamentos para começar a aceitar cartões de crédito, débito e PIX.</span>
            <div className={styles.card}>
                <div>
                    <p>Cartão de Crédito</p>
                    <Tooltip title="">
                        <InfoOutlined />
                    </Tooltip>
                </div>
                <span>3,5% sob o pedido</span>
            </div>
            <div className={styles.card} style={{ flexDirection: "row" }}>
                <div>
                    <p>PIX</p>
                    <Tooltip title="">
                        <InfoOutlined />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
};

export default PaymentFormsStep;