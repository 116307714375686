import { IMenuConfigService } from "modules/admin/menuConfig/domain/interface/IMenuConfigService";
import { api } from "../Api";
import { IPostSystemConfigPayload } from "modules/admin/menuConfig/domain/dto/IPostSystemConfigPayload";
import { IGetSystemConfigResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigResponse";
import { IGetSystemIdsResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemIdsResponse";
import { IGetSystemConfigByIdResponse } from "modules/admin/menuConfig/domain/dto/IGetSystemConfigByIdResponse";
import { IPostCreateMenuPayload } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";
import { IPutMenuOrder } from "modules/admin/menuConfig/domain/dto/IPutMenuOrder";
import { IGetMenuListResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuListResponse";
import { IGetMenuByIdResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuByIdResponse";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const baseUrl = process.env.REACT_APP_DYNAMICMENU_URL;

const MenuConfigApi = (): IMenuConfigService => {
  const getSystemConfigList = async (): Promise<IGetSystemConfigResponse[]> => {
    const respomse = await api.get<IGetSystemConfigResponse[]>(`${baseUrl}/MenuConfiguration`);
    return respomse.data;
  }

  const getSystemConfigById = async (systemConfigId: string): Promise<IGetSystemConfigByIdResponse> => {
    const respomse = await api.get<IGetSystemConfigByIdResponse>(`${baseUrl}/MenuConfiguration/${systemConfigId}`);
    return respomse.data;
  }

  const postSystemConfig = async (payload: IPostSystemConfigPayload): Promise<void> => {
    const respomse = await api.post<void>(`${baseUrl}/MenuConfiguration`, payload);
    return respomse.data;
  }

  const putSystemConfig = async (systemConfigId: string, payload: IPostSystemConfigPayload): Promise<void> => {
    const respomse = await api.put<void>(`${baseUrl}/MenuConfiguration/${systemConfigId}`, payload);
    return respomse.data;
  }

  const deleteSystemConfig = async (systemConfigId: string): Promise<void> => {
    const respomse = await api.delete<void>(`${baseUrl}/MenuConfiguration/${systemConfigId}`);
    return respomse.data;
  }

  const getSystemIds = async (): Promise<IGetSystemIdsResponse[]> => {
    const respomse = await api.get<IGetSystemIdsResponse[]>(`${baseUrl}/MenuConfiguration/SystemIdentifiers`);
    return respomse.data;
  }

  const postCreateMenu = async (payload: IPostCreateMenuPayload): Promise<void> => {
    const respomse = await api.post<void>(`${baseUrl}/Menu`, payload);
    return respomse.data;
  }

  const putEditMenu = async (payload: IPutEditMenuPayload): Promise<void> => {
    const respomse = await api.put<void>(`${baseUrl}/Menu`, payload);
    return respomse.data;
  }

  const deleteMenu = async (menuId: string): Promise<void> => {
    const respomse = await api.delete<void>(`${baseUrl}/Menu`, { params: { menuId } });
    return respomse.data;
  }

  const putMenuOrder = async (payload: IPutMenuOrder): Promise<void> => {
    const respomse = await api.put<void>(`${baseUrl}/Menu/ChangeMenuOrder`, payload);
    return respomse.data;
  }

  const getMenuList = async (systemConfigId: string): Promise<IGetMenuListResponse[][]> => {
    const respomse = await api.get<IGetMenuListResponse[][]>(`${baseUrl}/Menu/configuration/${systemConfigId}`);
    return respomse.data;
  }

  const getMenuById = async (menuId: string): Promise<IGetMenuByIdResponse> => {
    const respomse = await api.get<IGetMenuByIdResponse>(`${baseUrl}/Menu/${menuId}/detail`);
    return respomse.data;
  }

  const getUpdatePermission = async (systemIdentifier: SystemIdentifierEnum): Promise<void> => {
    const respomse = await api.get<void>(`${baseUrl}/Menu/UpdateMenusPermission`, { params: { systemIdentifier } });
    return respomse.data;
  }

  return {
    getSystemConfigList,
    getSystemConfigById,
    postSystemConfig,
    putSystemConfig,
    deleteSystemConfig,
    getSystemIds,
    postCreateMenu,
    putEditMenu,
    deleteMenu,
    putMenuOrder,
    getMenuList,
    getMenuById,
    getUpdatePermission,
  };
}

export default MenuConfigApi;
