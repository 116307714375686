import { IPaginatedList } from 'models/api/IApi';
import { ICashlessVoucherResponse } from 'modules/events/domain/dto/IEventsResponse';
import { ICashlessVoucerApi } from '../../interfaces/ICashlessVoucherService';

// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms * 1000));

const GetCashlessVoucherListUseCase = async (
  cashlessVoucherService: ICashlessVoucerApi,
  localId: string,
  cashierId?: string,
  page?: number,
  pageSize?: number,
): Promise<IPaginatedList<ICashlessVoucherResponse>> => {
  // await delay(2);
  return await cashlessVoucherService.getCashlessVoucherList(localId, cashierId, page, pageSize);
};

export default GetCashlessVoucherListUseCase;