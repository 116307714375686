import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import getTicketCategories from "modules/crm/application/useCases/getTicketCategories";
import { IGetCrmEventsResponse } from "modules/crm/models/dtos/IGetCrmEventsResponse";

const UseGetTicketCategories = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [ticketCategories, setTicketCategories] = useState<IGetCrmEventsResponse>();
  const { currentLocal } = useLocal();

  const getTicketCategoriesData = useCallback(async () => {
    try {
      const serviceCrm = CrmServiceApi();
      setIsLoading(true);
      if (currentLocal) {
        const response = await getTicketCategories(
          serviceCrm,
          currentLocal.id,
          ""
        );
        setTicketCategories(response);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  return {
    ticketCategories,
    isLoading,
    getTicketCategoriesData,
  };
};

export default UseGetTicketCategories;
