import SupplyStockService from "modules/meepErp/services/api/supplyStock/SupplyStockService";
import { IGetSupplyStockListRequest } from "../../dtos/supplyStock/IGetSupplyStockListResponse"

const GetSupplyStockListUseCase = (localId: string,request: IGetSupplyStockListRequest) => {
    const supplyStockService = SupplyStockService();
    const filters = {
        ...request,
        supplyName: request.name
    }
    return supplyStockService.getSupplyStockList(filters);
}

export const GetSupplyStockTotalUseCase = () => {
    const supplyStockService = SupplyStockService();
    return supplyStockService.getSupplyStockTotal();
}

export default GetSupplyStockListUseCase

