import { IDeleteLinkCardsResponse } from "modules/linkCards/domain/dto/IDeleteLinkCardsResponse";
import { ILinkCardsApiService } from "modules/linkCards/domain/interfaces/ILinkCardsApiService";


export const DeleteLinkCardsItemUseCase = async (service: ILinkCardsApiService, tags: string, localId: string): Promise<IDeleteLinkCardsResponse> => {

    const request = {
        localId: localId,
        tags: tags,
    }

    const response = await service.deleteLinkCardsItem(request);
    return response;


}


