import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const CancelTIDsSolicitationUseCase = async (
  service: IComplienceService,
  id: string,
) => {
  const response = await service.cancelTIDsSolicitation(id);
  return response;
};

export default CancelTIDsSolicitationUseCase;
