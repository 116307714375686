import { AddCircle, PhotoCamera } from "@mui/icons-material";
import { IPostPageWizardItems, IPostNewWizard } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./NewAndEditTutorial.module.scss";
import { ITutorialFormErrors } from "./validade/TutorialValidation";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Input from "components/ui/input/Input";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import { Autocomplete, TextField } from "@mui/material";
import Button from "components/ui/Button/Button";
import TutorialStep from "./tutorialStep/TutorialStep";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const systemIdentifierOptions = [
  {
    system: "Geral",
    value: SystemIdentifierEnum.General
  },
  {
    system: "SAAS",
    value: SystemIdentifierEnum.Saas
  }
];

export interface INewAndEditTutorialProps {
  values: IPostNewWizard;
  setValues: React.Dispatch<React.SetStateAction<IPostNewWizard>>;
  stepsValues: IPostPageWizardItems[];
  setStepsValues: React.Dispatch<React.SetStateAction<IPostPageWizardItems[]>>;
  errors: ITutorialFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<ITutorialFormErrors>>;
  menuOptions: {
      id: string;
      title: string;
  }[];
}

const NewAndEditTutorial = ({
  values,
  setValues,
  stepsValues,
  setStepsValues,
  errors,
  setErrors,
  menuOptions
}: INewAndEditTutorialProps) => {
  const [editMenu, setEditMenu] = useState({
      id: "",
      title: "",
  });
  const refInput = useRef<HTMLInputElement>(null);

  const handleAddStep = () => {
    const newStep: IPostPageWizardItems = {
      Title: "",
      ImagePath: "",
      Order: 1,
      Description: "",
    };
    setValues(prev => ({
      ...prev,
      PageWizardItems: prev.PageWizardItems ? [...prev.PageWizardItems, newStep] : [newStep],
    }));
    setStepsValues(prev => prev ? [...prev, newStep] : [newStep]);
  };

  const handleRemoveStep = (index: number) => {
    const updatedSteps = [...stepsValues];

    updatedSteps.splice(index, 1);

    setValues(prev => ({
      ...prev,
      PageWizardItems: updatedSteps,
    }));
    setStepsValues(updatedSteps);
  };

  const onClick = () => {
    refInput.current?.click();
  };

  const fileToBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const onChangeHandleImg = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;
    let images: string[] = [];
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const _file = files?.item(i);

        const base64 = _file && (await fileToBase64(_file));
        images.push(String(base64));
      }
    }
    setErrors((prev) => ({ ...prev, ImagePath: undefined }));
    setValues({...values, ImagePath: images[0]});
  };

  const onChangeHandle = useCallback((index: number, name: string, value: string | number) => {
    const newStepsValues = [...stepsValues];
    const newValues = {...values};

    newStepsValues[index] = { ...newStepsValues[index], [name]: value };
    newValues.PageWizardItems[index] = { ...newValues.PageWizardItems[index], [name]: value };

    setStepsValues(newStepsValues);
    setValues(newValues);
  }, [setValues, values, setStepsValues, stepsValues]);

  useEffect(() => {
    const currentMenu = menuOptions.find(it => it.id === values.MenuId);
    if (values.MenuId && currentMenu) {
      setEditMenu(currentMenu);
    }
  }, [values.MenuId, menuOptions]);  

  return (
    <div id={styles.NewAndEditTutorial}>
      <div className={styles.contentImg}>
        <input
          name="ImagePath"
          ref={refInput}
          id="input-file"
          accept=".jpg, .jpeg, .png"
          type="file"
          style={{ display: "none" }}
          onChange={onChangeHandleImg}
        />
        {values.ImagePath ? (
          <div className={styles.boxImg} onClick={onClick}>
            <img src={values.ImagePath} alt="upload_img" />
          </div>
        ) : (
          <div
            className={
              errors.ImagePath ? styles.uploadImgError : styles.uploadImg
            }
            onClick={onClick}
          >
            <PhotoCamera />
            <p>Adicionar foto para o tutorial</p>
          </div>
        )}
        <ul>
          <li>
            Envie uma imagem nos formatos, PNG ou JPG com o tamanho mínimo
            de 300x300px e máximo 1080x1080px.
          </li>
          <li>A imagem será usada na página do seu evento.</li>
        </ul>
      </div>
      <p className={styles.errorImg}>{errors.ImagePath}</p>
      <FormItemContainer label={"Título"} required>
        <Input
          name={"Title"}
          value={values.Title}
          onChange={(ev) => {
            setErrors((prev) => ({ ...prev, Title: undefined }));
            setValues({...values, Title: ev.target.value});
          }}
          required
          variant="outlined"
          error={!!errors.Title}
          helperText={errors.Title}
        />
      </FormItemContainer>
      <FormItemContainer label={"Descrição"} required>
        <InputTextArea
          name={"Description"}
          value={values.Description}
          variant="outlined"
          rows={4}
          multiline
          required
          onChange={(ev) => {
            setErrors((prev) => ({ ...prev, Description: undefined }));
            setValues({...values, Description: ev.target.value});
          }}
          error={!!errors.Description}
          helperText={errors.Description ? errors.Description : <span className={styles.caracteres}>Max.: 300 caracteres</span>}
          placeholder="Insira aqui seu texto..."
        />
      </FormItemContainer>
      <FormItemContainer label={"Menu"} required>
        <Autocomplete
          disablePortal
          options={menuOptions}
          getOptionLabel={option => option.title}
          noOptionsText="Nenhuma opção encontrada"
          value={editMenu}
          renderInput={(params) => (
            <TextField
              {...params}
              value={editMenu.title}
              InputProps={{
                  style: { padding: '0 20px 0 10px' },
                  ...params.InputProps,
              }}
              error={!!errors.MenuId}
              helperText={errors.MenuId}
            />
          )}
          onChange={(_ev, value) => {
            setErrors((prev) => ({ ...prev, MenuId: undefined }));
            setValues({...values, MenuId: value?.id ?? ""});
          }}
        />
      </FormItemContainer>
      <FormItemContainer label={"Identificador de sistema"} required>
        <Autocomplete
          disablePortal
          options={systemIdentifierOptions}
          getOptionLabel={option => option.system}
          noOptionsText="Nenhuma opção encontrada"
          value={systemIdentifierOptions.find((system) => system.value === values.SystemIdentifier)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={values.SystemIdentifier}
              InputProps={{
                  style: { padding: '0 20px 0 10px' },
                  ...params.InputProps,
              }}
              error={!!errors.MenuId}
              helperText={errors.MenuId}
            />
          )}
          onChange={(_ev, value) => {
            if (value) {
              setErrors((prev) => ({ ...prev, SystemIdentifier: undefined }));
              setValues({...values, SystemIdentifier: value.value});
            }
          }}
        />
      </FormItemContainer>
      <FormItemContainer label={"Link"}>
        <Input
          name={"FullTutorialUrl"}
          value={values.FullTutorialUrl}
          onChange={(ev) => {
            setErrors((prev) => ({ ...prev, FullTutorialUrl: undefined }));
            setValues({...values, FullTutorialUrl: ev.target.value});
          }}
          variant="outlined"
          placeholder="https://"
        />
      </FormItemContainer>
      <span className={styles.middleSpan}>Crie seu passo a passo:</span>
      <Button
        className={styles.btnAdd}
        endIcon={<AddCircle />}
        onClick={handleAddStep}
        variant="outlined"
      >
        Adicionar
      </Button>
      <div className={styles.stepsContent}>
        {!!values.PageWizardItems && !!values.PageWizardItems.length && values.PageWizardItems.map((step, index) => (
          <TutorialStep
            key={index}
            index={index}
            totalSteps={values.PageWizardItems.length}
            values={stepsValues}
            onChangeHandle={onChangeHandle}
            errors={errors}
            setErrors={setErrors}
            handleRemoveStep={handleRemoveStep}
          />
        ))}
      </div>
    </div>
  );
};

export default NewAndEditTutorial;
