import { IGetPlaceScheduleListResponseByLocalIdDTO, IGetPlaceScheduleListResponseDTO } from "modules/schedule/domain/dto/placeSchedule/IGetPlaceScheduleListResponseByLocalIdDTO";
import { ISchedulePlaceCard } from "modules/schedule/presentation/components/schedulePlaceCard/interfaces/ISchedulePlaceCard";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const GetPlaceScheduleListUseCase = async (localId: string, placeScheduleApi: PlaceScheduleApiFunction): Promise<ISchedulePlaceCard[]> => {
    const _placeScheduleApi = placeScheduleApi();
    const response = await _placeScheduleApi.getPlaceScheduleListIdsByLocalId(localId);
    return response.items
    // return valuesMock;
}


// const valuesMock: ISchedulePlaceCard[] = [
//     {
//         id: "001",
//         name: "Mesa001",
//         maxQuantity: 10,
//         avalableDays: [
//             {
//                 id: "001",
//                 dayOfWeek: 2,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },
//             {
//                 //    id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 id: "0014",
//                 dayOfWeek: 2,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },

//         ],
//         servicesSchedule: [
//             {
//                 name: "Aula karate",
//                 value: 50
//             },
//             {
//                 name: "Aula sumo",
//                 value: 50
//             },
//             {
//                 name: "Aula skate",
//                 value: 50
//             },
//         ],
//     },
//     {
//         id: "002",
//         name: "Mesa002",
//         maxQuantity: 20,
//         avalableDays: [
//             {
//                 id: "002",
//                 dayOfWeek: 3,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },
//             {
//                 //    id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 id: "0016",
//                 dayOfWeek: 4,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },

//         ],
//         servicesSchedule: [
//             {
//                 name: "Aula karate",
//                 value: 50
//             },
//             {
//                 name: "Aula sumo",
//                 value: 50
//             },
//             {
//                 name: "Aula skate",
//                 value: 50
//             },
//         ],
//     },
//     {
//         id: "003",
//         maxQuantity: 30,
//         name: "Mesa002",
//         avalableDays: [
//             {
//                 id: "007",
//                 dayOfWeek: 5,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },
//             {
//                 //    id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 id: "0019",
//                 dayOfWeek: 1,
//                 startHour: "06:00",
//                 endHour: "18:00"
//             },

//         ],
//         servicesSchedule: [
//             {
//                 name: "Aula karate",
//                 value: 50
//             },
//             {
//                 name: "Aula sumo",
//                 value: 50
//             },
//             {
//                 name: "Aula skate",
//                 value: 50
//             },
//         ],
//     },

// ]