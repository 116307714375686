const PaymentLastStep = () => {
    return (
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "16px",
                lineHeight: "24px",
                color: "rgba(51, 51, 51, 1)",
            }}
        >
            <img
                src="/assets/img/online-payment-sidesheet.png"
                alt=""
                style={{ marginBottom: "1.5rem" }}
            />
            <p>Sua <b>solicitação de pagamento online</b> foi enviada com sucesso! Iremos analisá-la e entraremos em contato. Após isso, seus clientes poderão usar o <b>App Meep e Venda Online</b> para pagar pelos pedidos de forma muito mais prática.</p>
        </div>
    )
};

export default PaymentLastStep;