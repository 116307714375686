import { subMinutes } from "date-fns";
import { IGetBuscarSaldoAtualResponse } from "modules/mainDashboard/domain/dtos/IGetBuscarSaldoAtualResponse";
import { IGetDayPaymentsPerHourResponse } from "modules/mainDashboard/domain/dtos/IGetDayPaymentsPerHourResponse";
import { IGetDayPaymentsResponse } from "modules/mainDashboard/domain/dtos/IGetDayPaymentsResponse";
import { IGetDayResumeResponse } from "modules/mainDashboard/domain/dtos/IGetDayResumeResponse";
import { IGetDayTopProductsResponse } from "modules/mainDashboard/domain/dtos/IGetDayTopProductsResponse";
import { IGetMonthResumePerDayResponse } from "modules/mainDashboard/domain/dtos/IGetMonthResumePerDayResponse";
import { IGetMonthResumeResponse } from "modules/mainDashboard/domain/dtos/IGetMonthResumeResponse";
import { IGetYearResumePerMonthResponse } from "modules/mainDashboard/domain/dtos/IGetYearResumePerMonthResponse";
import { IMainDashoboardService } from "modules/mainDashboard/domain/interfaces/IMainDashoboardService";
import { api } from "services/api/Api";

export const MainDashboardApi = (): IMainDashoboardService => {
  const getDayResume = async (localId: string, cashierId?: string, day?: string): Promise<IGetDayResumeResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetDayResumeResponse>(`/dashboard/saas/dayResume?placeId=${localId}${filter}`);
    return result.data;
  };

  const getDayTopProducts = async (localId: string, cashierId?: string, day?: string): Promise<IGetDayTopProductsResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetDayTopProductsResponse>(`/dashboard/saas/dayTopProducts?placeId=${localId}${filter}`);
    return result.data;
  };

  const getDayPayments = async (localId: string, cashierId?: string, day?: string): Promise<IGetDayPaymentsResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetDayPaymentsResponse>(`/dashboard/saas/dayPayments?placeId=${localId}${filter}`);
    return result.data;
  };

  const getDayPaymentsPerHour = async (localId: string, cashierId?: string, day?: string): Promise<IGetDayPaymentsPerHourResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetDayPaymentsPerHourResponse>(`/dashboard/saas/dayPaymentsPerHour?placeId=${localId}${filter}`);
    return result.data;
  };

  const getMonthResume = async (localId: string, cashierId?: string, day?: string): Promise<IGetMonthResumeResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetMonthResumeResponse>(`/dashboard/saas/monthResume?placeId=${localId}${filter}`);
    return result.data;
  };

  const getMonthResumePerDay = async (localId: string, cashierId?: string, day?: string): Promise<IGetMonthResumePerDayResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetMonthResumePerDayResponse>(`/dashboard/saas/monthResumePerDay?placeId=${localId}${filter}`);
    return result.data;
  };

  const getYearResumePerMonth = async (localId: string, cashierId?: string, day?: string): Promise<IGetYearResumePerMonthResponse> => {
    const filter = buildFilter(cashierId, day);
    const result = await api.get<IGetYearResumePerMonthResponse>(`/dashboard/saas/yearResumePerMonth?placeId=${localId}${filter}`);
    return result.data;
  };

  const getBuscarSaldoAtual = async (localId: string): Promise<IGetBuscarSaldoAtualResponse> => {
    const result = await api.get<IGetBuscarSaldoAtualResponse>(`/ContaDigital/BuscarSaldoAtual/${localId}`);
    return result.data;
  };

  const buildFilter = (cashierId?: string, day?: string) => {
    let filter = "";
    if (!cashierId && !day) {
      day = subMinutes(new Date(), 1).toISOString();
    }
    
    if (cashierId !== undefined)
      filter = `&cashierId=${cashierId}`;
    if (day !== undefined)
      filter = `&day=${day}`;

    return filter;
  }

  return {
    getDayResume,
    getDayTopProducts,
    getDayPayments,
    getMonthResume,
    getMonthResumePerDay,
    getYearResumePerMonth,
    getBuscarSaldoAtual,
    getDayPaymentsPerHour
  };
};


const buildFilter = (cashierId?: string, day?: string) => {
  let filter = "";
  if (cashierId === undefined && day === undefined) {
    day = new Date().toISOString();
  }

  if (cashierId !== undefined)
    filter = `&cashierId=${cashierId}`;
  if (day !== undefined)
    filter = `&day=${day}`;

  return filter;
}