import { Button } from "@material-ui/core";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Switch, TextField } from "@mui/material";
import Input from "components/ui/input/Input";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ICouponForm } from "modules/config/discountCoupons/presentation/interfaces/ICouponForm";
import { ICouponFormErros } from "modules/config/discountCoupons/presentation/interfaces/ICouponFormErrors";
import React, { useCallback, useEffect, useState } from "react";
import { EnumValueType } from "../../../discountCouponFilter/interfaces/IDiscountCouponFilter";
import { EnumCouponStep } from "../../CouponForm";
import { validate } from "../../validation/CouponFormValidation";
import BoxQuantity from "../boxQuantity/BoxQuantity";
import styles from "./DetailsForm.module.scss";
import { moneyMask, moneyMaskNumber, moneyToFloat } from "services/utils/Money";

export interface IDetailsFormProps {
  values: ICouponForm;
  changeHandle: (name: string, value: string) => void;
  changeHandleNumber: (name: string, value: number | string) => void;
  changeHandleNumberAbsolute: (name: string, value: string) => void;
  setValues: React.Dispatch<React.SetStateAction<ICouponForm>>;
  changeHandleChecked: (name: string, checked: boolean) => void;
  onChangeFormStep: (value: EnumCouponStep) => void;
  onClose: () => void;
  errors: ICouponFormErros;
  setErrors: React.Dispatch<React.SetStateAction<ICouponFormErros>>;
  setIsPorcent: React.Dispatch<React.SetStateAction<boolean>>;
  isPorcent: boolean;
}

export const DetailsForm: React.FC<IDetailsFormProps> = ({
  values,
  changeHandle,
  setValues,
  changeHandleChecked,
  onChangeFormStep,
  onClose,
  errors,
  setErrors,
  changeHandleNumber,
  setIsPorcent,
  isPorcent,
  changeHandleNumberAbsolute,
}) => {
  const [dropDownInfo, setDropDownInfo] = useState(false);
  const [dropDownUtilization, setDropDownUtilization] = useState(false);
  const [dropDownEquipament, setDropDownEquipament] = useState(false);
  const [limitQuantityUser, setLimitQuantityUser] = useState(false);

  const { toast } = useUi();

  useEffect(() => {
    if (values.valueType === EnumValueType.ABSOLUTE) {
      setIsPorcent(true);
    } else {
      setIsPorcent(false);
    }
  }, [setIsPorcent, values.valueType]);

  useEffect(() => {
    if (values.valueType === EnumValueType.PERCENT && values.value > 100) {
      setValues((prev) => ({ ...prev, value: 100 }));
    }
  }, [setValues, values.value, values.valueType]);

  const onClickPercent = useCallback(
    (_valueType: EnumValueType) => {
      if (_valueType === EnumValueType.ABSOLUTE) {
        setValues((prev) => ({
          ...prev,
          valueType: _valueType,
          maxValue: undefined,
        }));
        setIsPorcent(true);
      } else {
        setValues((prev) => ({ ...prev, valueType: _valueType }));
        setIsPorcent(false);
      }
    },
    [setIsPorcent, setValues]
  );

  const onClickDropDownInfo = useCallback(() => {
    setDropDownInfo(!dropDownInfo);
  }, [dropDownInfo]);
  const onClickDropDownUtilization = useCallback(() => {
    setDropDownUtilization(!dropDownUtilization);
  }, [dropDownUtilization]);
  const onClickDropDownEquipament = useCallback(() => {
    setDropDownEquipament(!dropDownEquipament);
  }, [dropDownEquipament]);

  const onChangeLimitQuantityUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimitQuantityUser(event.target.checked);
    if (limitQuantityUser) {
      setValues((prev) => ({ ...prev, quantityPerUser: 0 }));
    } else {
      setValues((prev) => ({ ...prev, quantityPerUser: 1 }));
    }
  };

  const handleChangeMaxQuantityPerUser = useCallback(
    (sub?: boolean) => {
      let newValue = !sub
        ? (values.quantityPerUser || 1) + 1
        : (values.quantityPerUser || 1) - 1;
      if (newValue < 1) newValue = 1;

      setValues((prev) => ({
        ...prev,
        quantityPerUser: newValue ?? 1,
      }));
    },
    [setValues, values.quantityPerUser]
  );

  const handleChangeMaxQuantityUse = useCallback(
    (sub?: boolean) => {
      let newValue = !sub
        ? (values.quantity || 1) + 1
        : (values.quantity || 1) - 1;
      if (newValue < 1) newValue = 1;
      setValues((prev) => ({
        ...prev,
        quantity: newValue ?? 1,
      }));
    },
    [setValues, values.quantity]
  );

  const handleChangeStartDate = (startDate: string) => {
    setValues((prev) => ({ ...prev, startAt: startDate }));
  };

  const handleChangeEndDate = useCallback(
    (endDate: string) => {
      setValues((prev) => ({ ...prev, finishAt: endDate }));
    },
    [setValues]
  );

  useEffect(() => {
    if (values.quantityPerUser > 0) {
      setLimitQuantityUser(true);
    }
  }, [values.quantityPerUser]);

  const onChangeNextStep = () => {
    if (validate(values, setErrors)) {
      onChangeFormStep(EnumCouponStep.SEGMENTACAO);
    } else {
      toast("Campo(s) obrigatório(s) não preenchido", "error");
    }
  };

  return (
    <div id={styles.DetailsForm}>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.title} onClick={onClickDropDownInfo}>
            <span>Informações do cupom</span>
            <KeyboardArrowDown />
          </div>

          {dropDownInfo && (
            <div className={styles.content}>
              <div className={styles.item}>
                <label>Código</label>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  placeholder="Insira o código do cupom de desconto"
                  variant="outlined"
                  name="code"
                  value={values.code}
                  onChange={(ev) =>
                    changeHandle(ev.target.name, ev.target.value)
                  }
                  error={!!errors.code}
                  helperText={errors.code}
                />
              </div>
              <div className={styles.item}>
                <label>Descrição</label>
                <Input
                  multiline
                  fullWidth
                  rows={3}
                  inputProps={{ maxLength: 200 }}
                  variant="outlined"
                  placeholder="Insira uma descrição para seu cupom"
                  name="description"
                  value={values.description}
                  onChange={(ev) =>
                    changeHandle(ev.target.name, ev.target.value)
                  }
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <span>Max. 200 caracteres</span>
              </div>
              <div className={styles.itemValueType}>
                <div className={styles.boxValueType}>
                  <p
                    onClick={() => onClickPercent(EnumValueType.ABSOLUTE)}
                    className={!isPorcent ? styles.absolute : styles.selected}
                  >
                    R$
                  </p>
                  <p
                    onClick={() => onClickPercent(EnumValueType.PERCENT)}
                    className={isPorcent ? styles.percent : styles.selected}
                  >
                    %
                  </p>
                </div>
                {!isPorcent ? (
                  <TextField
                    inputProps={{ maxLength: 3 }}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder={"%"}
                    variant="outlined"
                    name="value"
                    value={values.value}
                    onChange={(ev) =>
                      changeHandleNumberAbsolute(
                        ev.target.name,
                        ev.target.value
                      )
                    }
                    error={!!errors.value}
                    helperText={errors.value}
                  />
                ) : (
                  <TextField
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    size="small"
                    placeholder={"R$"}
                    variant="outlined"
                    name="value"
                    value={moneyMaskNumber(values.value)}
                    onChange={(ev) =>
                      changeHandleNumber(
                        ev.target.name,
                        moneyToFloat(ev.target.value)
                      )
                    }
                    error={!!errors.value}
                    helperText={errors.value}
                  />
                )}
              </div>
              {!isPorcent && (
                <div className={styles.item}>
                  <label>Valor máximo de desconto</label>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    placeholder="R$0,00"
                    variant="outlined"
                    name="maxValue"
                    value={moneyMaskNumber(values.maxValue)}
                    onChange={(ev) =>
                      changeHandleNumber(
                        ev.target.name,
                        moneyToFloat(ev.target.value)
                      )
                    }
                    error={!!errors.maxValue}
                    helperText={errors.maxValue}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className={styles.utilization}>
          <div className={styles.title} onClick={onClickDropDownUtilization}>
            <span>Utilização do cupom</span>
            <KeyboardArrowDown />
          </div>
          {dropDownUtilization && (
            <div className={styles.content}>
              <div className={styles.header}>
                <span>Quantidade máxima de utilização</span>
                <BoxQuantity
                  values={values.quantity}
                  onChange={handleChangeMaxQuantityUse}
                />
                {!!errors.quantity && (
                  <span className={styles.errors}>{errors.quantity}</span>
                )}
              </div>

              <div className={styles.main}>
                <Switch
                  color="secondary"
                  checked={values.forFirstOrder}
                  name="forFirstOrder"
                  onChange={(ev) =>
                    changeHandleChecked(ev.target.name, ev.target.checked)
                  }
                />{" "}
                Válido somente na primeira compra
              </div>

              <div className={styles.footer}>
                <Switch
                  color="secondary"
                  checked={limitQuantityUser}
                  onChange={onChangeLimitQuantityUser}
                />{" "}
                Limitar quantidade por usuário Meep
                {limitQuantityUser && (
                  <BoxQuantity
                    values={values.quantityPerUser}
                    onChange={handleChangeMaxQuantityPerUser}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.value}>
          <div className={styles.title} onClick={onClickDropDownEquipament}>
            <span>Valor e expiração</span>
            <KeyboardArrowDown />
          </div>
          {dropDownEquipament && (
            <div className={styles.content}>
              <div className={styles.itemValidate}>
                <div className={styles.item}>
                  <label>Valor mínimo do pedido</label>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="minOrderValue"
                    value={moneyMaskNumber(values.minOrderValue)}
                    onChange={(ev) =>
                      changeHandleNumber(
                        ev.target.name,
                        moneyToFloat(ev.target.value)
                      )
                    }
                    error={!!errors.minOrderValue}
                    helperText={errors.minOrderValue}
                  />
                </div>
                <div className={styles.item}>
                  <label>Valor máximo do pedido</label>
                  <TextField
                    fullWidth
                    inputProps={{ min: 0 }}
                    size="small"
                    placeholder="R$0,00"
                    variant="outlined"
                    name="maxOrderValue"
                    value={moneyMaskNumber(values.maxOrderValue)}
                    onChange={(ev) =>
                      changeHandleNumber(
                        ev.target.name,
                        moneyToFloat(ev.target.value)
                      )
                    }
                    error={!!errors.maxOrderValue}
                    helperText={errors.maxOrderValue}
                  />
                </div>
              </div>
              <div className={styles.itemValidate}>
                <div className={styles.item}>
                  <label>Validade do cupom início</label>
                  <TextField
                    type="datetime-local"
                    size="small"
                    variant="outlined"
                    name="startAt"
                    value={values.startAt}
                    onChange={(e) => {
                      handleChangeStartDate(e.target.value);
                    }}
                    error={!!errors.startAt}
                    helperText={errors.startAt}
                  />
                </div>
                <div className={styles.item}>
                  <label>Validade do cupom fim</label>
                  <TextField
                    type="datetime-local"
                    size="small"
                    variant="outlined"
                    name="finishAt"
                    value={values.finishAt}
                    onChange={(e) => {
                      handleChangeEndDate(e.target.value);
                    }}
                    error={!!errors.finishAt}
                    helperText={errors.finishAt}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.containerButton}>
        <Button
          variant="outlined"
          className={styles.outlined}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          className={styles.contained}
          onClick={onChangeNextStep}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};
