import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { FC } from 'react';
import styles from './BaseForm.module.scss';
import Input from 'components/ui/input/Input';
import { IDiscountListForm } from '../../IDiscountListForm';
import { IDiscountListFormErrors } from '../../validation/IDiscountListFormErrors';

interface IDiscountListFormProps {
    errors: IDiscountListFormErrors;
    discountList: IDiscountListForm;
    onChangeDiscountListFormValue: (prop: string, value: string | number) => void;
}

export const DiscountListBaseForm: FC<IDiscountListFormProps> = ({
    errors,
    discountList,
    onChangeDiscountListFormValue
}) => {

    return (
        <div id={styles.BaseForm}>
            <FormItemContainer label={'Nome'} className={styles.formItem}>
                <Input
                    name="name"
                    variant='outlined'
                    value={discountList.name}
                    className={styles.input}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                    onChange={evt => onChangeDiscountListFormValue('name', evt.target.value)}
                    error={!!errors.name}
                    placeholder="Digite o nome da lista"
                />
            </FormItemContainer>

            <FormItemContainer label={'Descrição'} className={styles.formItem}>
                <Input
                    name="description"
                    variant='outlined'
                    value={discountList.description}
                    className={styles.input}
                    helperText={errors.description}
                    onChange={evt => onChangeDiscountListFormValue('description', evt.target.value)}
                    error={!!errors.description}
                    placeholder="Insira a descrição da lista"
                />
            </FormItemContainer>
        </div>
    );
};
