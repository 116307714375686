import AutoComplete from "components/ui/autoComplete/AutoComplete";
import { IDeviceProfileProducts } from "modules/config/deviceConfig/presentation/interfaces/IDeviceProfile";
import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import FooterButtons from "../../../footerButtons/FooterButtons";
import { IProductList } from "./IProducts";
import styles from "./ProductsForm.module.scss";

interface IProductsFormProps {
  defaultValue?: IDeviceProfileProducts;
  onSubmit: (values: IDeviceProfileProducts) => void;
  onClickBack: () => void;
  onModified: (modified: boolean) => void;
  productList?: IProductList[];
}

const ProductsForm: FC<IProductsFormProps> = ({
  onClickBack,
  defaultValue,
  onModified,
  onSubmit,
  productList = [],
}) => {
  const [values, setValues] = useState<IDeviceProfileProducts>(defaultValue ?? { productListId: null });
  const [selectedList, setSelectedList] = useState<IProductList>({ id: '', name: '' })

  useEffect(() => {
    if (productList.length > 0 && values.productListId) {
      const selected = productList.find((it) => it.id === values.productListId);
      setSelectedList(selected ?? { id: '', name: '' })
    }
  }, [productList, values.productListId])

  const handleChandProductList = useCallback(
    (property: string, value: string) => {
      setValues((prev) => ({
        ...prev,
        [property]: value,
      }));
      onModified(true);
    },
    [onModified]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(values);
    },
    [onSubmit, values]
  );

  return (
    <form id={styles.ProductsForm} onSubmit={submitHandle}>
      <span>
        Selecione o cardápio que será utilizada neste PDV. Caso o cardápio não exista ou não seja selecionada,
        será utilizada o cardápio cadastrado no portal antigo.
      </span>
      <div className={styles.container}>
        <span className={styles.label}>Cardápio</span>
        <AutoComplete
          className="autocomplete-filter-profile"
          variant="outlined"
          value={selectedList}
          placeholder="Selecione um cardápio"
          options={productList}
          getOptionLabel={(option) => option.name}
          onChange={(_, val: any) => {
            handleChandProductList("productListId", val.id);
          }}
        />
      </div>
      <FooterButtons nextType="submit" onClickBack={() => onClickBack()} labelNext="Salvar e continuar" />
    </form>
  );
};

export default ProductsForm;
