import { IDetailsRetainedResponse } from "modules/contaDigital/models/dtos/IDetailsRetainedResponse";
import { IDigitalAccountApi } from "modules/contaDigital/presentation/pages/interface/IDigitalAccountApi";

const GetDetailsRetainedUseCase = async (
  accountService: IDigitalAccountApi,
  localId: string
): Promise<IDetailsRetainedResponse[]> => {
  return await accountService.getDetailsRetained(localId);
};

export default GetDetailsRetainedUseCase;