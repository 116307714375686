import { ITableItem } from "../../presentation/components/smartPrinterIForm/interfaces/ISmartPrinterForm";
import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetTableListUseCase = async (smartPrintersApi: ISmartPrinterApiCB, localClienteId: string):Promise<ITableItem[]> => {

    const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
    const response = await _smartPrintersApi.getTableListByLocalClenteId(localClienteId);
    return response
}

export default GetTableListUseCase;



