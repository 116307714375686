import React, { FC } from 'react'
import { IActionButtons } from '../list/Item/Item'
import { Icon } from '@material-ui/core'

/**
 * Builder padrão de botoes de acao com botoes de ativar/desativar, editar e excluir utiizado na maioria das listas do sistema
 * @param 
 * @returns 
 */
const DefaultActionButtonBuilder = <T extends { id: string, isActive?: boolean },>({ onClickEnable, onEdit, onDelete }: {
    onClickEnable?: (id: string, isActive: boolean, value: T) => Promise<T> | Promise<void> | void | T | any,
    onEdit: (id: string, value: T) => Promise<T> | Promise<void> | void | T | any,
    onDelete: (id: string, value: T) => Promise<T> | Promise<void> | void | T | any
}) => {
    let actionButtons: IActionButtons<T>[] = []
    onClickEnable && actionButtons.push(
        {
            tooltip: "Ativar/Desativar",
            iconRender: (item: T) => item.isActive ? <Icon style={{color: '#001537'}}>visibility</Icon> : <Icon style={{color: '#001537'}}>visibility_off</Icon>,
            showLoading: true,
            onClick: (value) => onClickEnable(value.id, !value.isActive, value)
        })
    actionButtons.push({
        tooltip: "Editar",
        icon: "edit",
        showLoading: true,
        onClick: (value) => onEdit(value.id, value)
    })
    actionButtons.push({
        tooltip: "Excluir",
        icon: "delete",
        showLoading: true,
        onClick: (value) => onDelete(value.id, value)
    })


    return (actionButtons)
}

export default DefaultActionButtonBuilder