import { FC } from 'react'
import styles from './TicketsReportPage.module.scss'
import UseTicketsReportPage from './UseTicketsReportPage'
import Filter from './components/filter/Filter'
import { ProductList } from './components/productList/ProductList'

const TicketsReportPage: FC = () => {
    const {
        filter,
        cashiers,
        products,
        vendingDevices,
        validationDevices,
        validationOperators,
        categories,
        parentCategories,
        data,
        loading,
        getData,
        getDataDetailed,
        onChangeFilterHandle,
        exportReport
    } = UseTicketsReportPage();

    return (
        <div id={styles.TicketsReportPage}>
            <Filter
                onSubmit={(value) => onChangeFilterHandle(value)}
                onExport={exportReport}
                defaultValues={filter}
                cashiers={cashiers}
                products={products}
                vendingDevices={vendingDevices}
                validationDevices={validationDevices}
                validationOperators={validationOperators}
                categories={categories}
                parentCategories={parentCategories}
                data={data}
            />

            <ProductList data={data} loading={loading} getData={getData} getSubitemData={getDataDetailed} />
        </div>
    )
}

export default TicketsReportPage