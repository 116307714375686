import { ITerminalValues } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/terminalForm/ITerminalValues";
import { IDeviceProfileService } from "../../../domain/interface/IDeviceProfileService"

const EditTerminalUseCase = async (
    deviceProfileService: IDeviceProfileService, 
    localId: string, 
    profileId: string, 
    body: ITerminalValues
    ): Promise<any> => {
    const response = await deviceProfileService.editTerminal(localId, profileId, body);
    return response
}
export default EditTerminalUseCase