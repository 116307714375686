
import PartnerService from 'modules/meepErp/services/api/Partner/PartnerService';
import { GetDefaultCorporatePartnerResponse, GetDefaultIndividualPartnerResponse, GetDefaultPartnerResponse, PartnerListResponse, SaveCorporatePartnerRequest, SaveIndividualPartnerRequest } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import { ICorporatePartnerValues } from 'modules/meepErp/presentation/components/partners/partnerForm/corporatePartnerForm/ICorporatePartnerValues.types';
import { IIndividualPartnerValues } from 'modules/meepErp/presentation/components/partners/partnerForm/individualParterForm/IIndividualPartnerValues.types';

export const CreateCorporatePartnerUseCase = async (values: ICorporatePartnerValues): Promise<GetDefaultIndividualPartnerResponse> => {
    const service = PartnerService();
    const response = await service.createCorporatePartner(CorporatePartnerValuesToDataUseCase({...values, document: values.document.replace(/\D/g, '')}));
    return response;
};
export const CreateIndividualPartnerUseCase = async (values: IIndividualPartnerValues): Promise<GetDefaultIndividualPartnerResponse> => {
    const service = PartnerService();
    const response = await service.createIndividualPartner(IndividualPartnerValuesToDataUseCase({...values, document: values.document.replace(/\D/g, '')}));
    return response;
}

export const UpdateIndividualPartnerUseCase = async (id: string, values: IIndividualPartnerValues): Promise<GetDefaultCorporatePartnerResponse> => {
    const service = PartnerService();
    const response = await service.updateIndividualPartner(id, IndividualPartnerValuesToDataUseCase({...values, document: values.document.replace(/\D/g, '')}));
    return response;
};
export const UpdateCorporatePartnerUseCase = async (id: string, values: ICorporatePartnerValues): Promise<GetDefaultIndividualPartnerResponse> => {
    const service = PartnerService();
    const response = await service.updateCorporatePartner(id, (CorporatePartnerValuesToDataUseCase({...values, document: values.document.replace(/\D/g, '')})));
    return response;
};

export const GetCorporatePartnerUseCase = async (id: string): Promise<ICorporatePartnerValues> => {
    const service = PartnerService();
    const response = await service.getCorporatePartner(id);
    return CorporatePartnerDataToValuesUseCase(response, );
};
export const GetIndividualPartnerUseCase = async (id: string): Promise<IIndividualPartnerValues> => {
    const service = PartnerService();
    const response = await service.getIndividualPartner(id);
    return IndividualPartnerDataToValuesUseCase(response);
};



export const CorporatePartnerValuesToDataUseCase = (values: ICorporatePartnerValues): SaveCorporatePartnerRequest => {
    return {
        name: values.name,
        type: values.type,
        document: values.document,
        contacts: values.contacts,
        address: values.address,
        corporateName: values.tradingName,
        tradingName: values.tradingName,
        stateRegistration: values.stateRegistration,
        municipalRegistration: values.municipalRegistration,
        sectorTypeId: values.sectorTypeId!,
        legalRepresentative: values.legalRepresentative?.name || '',
        legalDocument: values.legalRepresentative?.document || ''
    }
}
export const IndividualPartnerValuesToDataUseCase = (values: IIndividualPartnerValues): SaveIndividualPartnerRequest => {
    return {
        name: values.name,
        type: values.type,
        document: values.document,
        contacts: values.contacts,
        address: values.address,
    }
}

export const CorporatePartnerDataToValuesUseCase = (data: GetDefaultCorporatePartnerResponse): ICorporatePartnerValues => {
    return {
        code: data.code,
        partnerOriginType: PartnerOriginType.CORPORATE,
        sectorTypeId: data.sectorTypeId,
        document: data.document,
        name: data.name,
        tradingName: data.tradingName,
        stateRegistration: data.stateRegistration,
        municipalRegistration: data.municipalRegistration,
        legalRepresentative: {
            name: data.legalRepresentative,
            document: data.legalDocument
        },
        type: data.type,
        contacts: data.contacts,
        address: data.address
    };
}
export const IndividualPartnerDataToValuesUseCase = (data: GetDefaultIndividualPartnerResponse): IIndividualPartnerValues => {
    return {
        code: data.code,
        document: data.document,
        name: data.name,
        type: data.type,
        contacts: data.contacts,
        address: data.address
    };
}

// export const DefaultPartnerToItemListUseCase = (previous: PartnerListResponse, data: GetDefaultPartnerResponse, id: string) => ({
//     ...previous,
//     items: previous.items.map(item => item.id === id ? {
//         ...data,
//         id: data.id,
//         code: data.code,
//         name: data.name,
//         type: data.type,
//         document: data.document,
//         origin: PartnerOriginType.INDIVIDUAL

//     } : item) ?? []
// })
