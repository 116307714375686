import PlaceSchedule from "pages/private/agendamento/lugares";
import Login from "pages/login";
import Services from "pages/private/agendamento/servicos";
import AddServicesSchedule from "pages/private/agendamento/servicos/adicionar";
import React, { FC, useEffect } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import AddPlaceSchedule from "pages/private/agendamento/lugares/adicionar";
import Events from "pages/private/eventos";
import AddEvents from "pages/private/eventos/adicionar";
import AddGuestList from "pages/private/eventos/listas/adicionar";
import GuestList from "pages/private/eventos/listas/convidados";
import AddGuest from "pages/private/eventos/listas/convidados/adicionar";
import AddReserveSchedule from "pages/private/agendamento/reserva/adicionar";
import EventPeopleList from "pages/private/eventos/convidados";
import EditEvents from "pages/private/eventos/editar";
import EventGuestLists from "pages/private/eventos/listas";
import EditServicesSchedule from "pages/private/agendamento/servicos/editar";
import FormPlaceSchedule from "pages/private/agendamento/lugares/editar";
import Discounts from "pages/private/eventos/descontos";
import AddDiscountsPage from "pages/private/eventos/descontos/adicionar";
import Dashboard from "pages/private/dashboard";
import ReserveList from "pages/private/agendamento/listas/reserva";
import Customers from "pages/private/customers";
import ServiceCategories from "pages/private/agendamento/servicos/categorias";
import CustomField from "pages/private/agendamento/lugares/customizarCampos";
import Manage from "pages/private/eventos/gerenciar";
import CloseEvent from "pages/private/eventos/fechamento";
import SmartPrinter from "pages/private/config/impressoraInteligente";
import { Collaborators } from "pages/private/agendamento/colaboradores/lista";
import AddColaborator from "pages/private/agendamento/colaboradores/adicionar";
import EditColaborator from "pages/private/agendamento/colaboradores/editar";
import { UserProvider } from "modules/user/domains/presentation/contexts/UserContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { AuthProvider } from "modules/auth/presentation/context/AuthContext";
import { BreadcumbsProvider } from "components/breadcumbs/BreadcumbsContext";
import Local from "pages/private/local";
import Painel from "pages/private/Painel";
import Products from "pages/private/produtos";
import { EditProduct } from "pages/private/produtos/editar";
import Cadastros from "pages/private/cadastros";
import { AdicionarProduto } from "pages/private/produtos/adicionar";
import Cartoes from "pages/private/cartoes";
import Relatorio from "pages/private/relatorio";
import { TipsProvider } from "components/breadcumbs/TipsContext";
import Agendamento from "pages/private/agendamento";
import { Crm } from "pages/private/crm";
import { Report } from "pages/private/crm/_report";
import { CrmCampaignEdit } from "pages/private/crm/campaign/edit";
import ConfigCampanha from "pages/private/crm/configuracoesCampanha";
import HouseFlow from "pages/private/houseFlow";
import DiscountCoupons from "pages/private/config/cuponsDesconto";
import Integracao from "pages/private/integracoes";
import Faturas from "pages/private/faturas";
import Transacoes from "pages/private/transacoes";
import PosPago from "pages/private/posPago";
import Estoque from "pages/private/estoque";
import Servicos from "pages/private/servicos";
import Cashless from "pages/private/cashlees";
import Doacoes from "pages/private/doacoes";
import Configuracao from "pages/private/config";
import MeepFood from "pages/private/meepFood";
import Admin from "pages/private/admin";
import { Automations } from "pages/private/automation";
import Colaboradores from "pages/private/colaboradores";
import { FidelityPageContainer } from "modules/fidelity/presentation/FidelityPageContainer";
import CustomerCategories from "modules/customerCategories/presentation/pages/CustomerCategories";
import Cargos from "pages/private/cargos";
import Monitoramento from "pages/private/monitoramento";
import Device from "pages/private/device";
import MeepTickets from "pages/private/meepTickets";
import { SurveyLandingPage } from "modules/survey/presentation/pages/landing/SurveyLandingPage";
import { TrackDetail } from "modules/survey/presentation/pages/trackDetail/TrackDetail";
import Novidades from "pages/private/novidades";
import Tutoriais from "pages/private/tutoriais";
import { TrackPage } from "modules/survey/presentation/pages/track/Track";
import { ERPLandingPage } from "modules/erp/presentation/pages/landing/ERPLandingPage";
import { OmieDetail } from "modules/erp/presentation/pages/omieDetail/OmieDetail";
import AdminNovidades from "pages/private/adminNovidades";
import Signup from "modules/auth/presentation/page/signup/Signup";
import { SankhyaDetail } from "modules/erp/presentation/pages/sankhyaDetail/SankhyaDetail";
import { EverestDetail } from "modules/erp/presentation/pages/everestDetail/EverestDetail";
import { Plans } from "modules/plans/presentation/pages/plans/Plans";
import { PurchasePlan } from "modules/plans/presentation/pages/purchasePlan/PurchasePlan";
import { PlanPaymentHistory } from "modules/plans/presentation/pages/planPaymentHistory/PlanPaymentHistory";
import Perfil from "pages/private/perfil";
import PerfilEventos from "pages/private/perfil/eventos";
import { PurchaseBi } from "modules/bi/presentation/pages/purchaseBi/PurchaseBi";
import { PlansProvider } from "modules/plans/presentation/hooks/PlansContext";
import { PurchasePush } from "modules/addons/presentation/pages/purchasePush/PurchasePush";
import { PurchaseEmail } from "modules/addons/presentation/pages/purchaseEmail/PurchaseEmail";
import { AutomationPage } from "modules/automation/presentation/AutomationPage";
import { MenuProvider } from "components/layout/presentation/menu/context/MenuContext";
import GestaoVendas from "pages/private/gestoaVendas";
import { ConfigBI } from "modules/bi/presentation/pages/config/ConfigBI";
import { Onboarding } from "modules/onboarding/presentation/pages/Onboarding";
import { BIPageContainer } from "modules/bi/presentation/pages/BiPageContainer";
import { ConfigPlans } from "modules/plans/presentation/pages/config/ConfigPlans";
import Catalog from "pages/private/catalog";
import { AddProduct } from "pages/private/catalog/product/addOrUpdate";
import External from "pages/private/external/External";
import CadastrarTutorial from "pages/private/cadastrarTutorial";
import { ConfigOnlinePayment } from "modules/onlinePayment/presentation/pages/config/ConfigOnlinePayment";
import AccessDeniedPage from "pages/accessDenied/AccessDeniedPage";
import { PublicGuestList } from "public/modules/guestList/PublicGuestList";
import LimitarAcesso from "pages/private/limitarAcesso";
import ContaDigital from "pages/private/contaDigital";
import Extrato from "pages/private/contaDigital/extrato";
import { PaymentPage } from "modules/saas/presentation/pages/Payment/PaymentPage";
import LoginPage from "modules/saas/presentation/pages/Login/LoginPage";
import ForgetPasswordPage from "modules/saas/presentation/pages/ForgetPassword/ForgetPasswordPage";
import ChangePasswordPage from "modules/saas/presentation/pages/ChangePassword/ChangePasswordPage";
import CodePage from "modules/saas/presentation/pages/Code/CodePage";
import ProfilePage from "modules/saas/presentation/pages/Profile/ProfilePage";
import LocalPage from "modules/saas/presentation/pages/Local/LocalPage";
import AddressPage from "modules/saas/presentation/pages/Address/AddressPage";
import { TrialPage } from "modules/saas/presentation/pages/Trial/TrialPage";
import { FeesPage } from "modules/saas/presentation/pages/Fees/FeesPage";
import { SaasTrackPage } from "modules/saas/presentation/pages/Track/TrackPage";
import { SuccessPage } from "modules/saas/presentation/pages/Success/SuccessPage";
import AntecipateReceived from "pages/private/contaDigital/antecipateReceived";
import Antecipate from "pages/private/contaDigital/antecipate";
import { ChangePaymentMethod } from "modules/saasPayment/presentation/ChangePaymentMethod";
import { BasicSuccessPage } from "modules/saas/presentation/pages/BasicSuccess/BasicSuccessPage";
import { TrackingConfig } from "modules/saas/presentation/pages/trackConfig/TrackConfig";
import { Terminal } from "pages/private/terminal";
import { SaasPayment } from "pages/private/saasPayment";
import { SaasPaymentSuccess } from "pages/private/saasPaymentSuccess";
import { Posts } from "modules/posts/presentation/pages/Posts/Posts";
import { SaleNow } from "pages/private/terminal/venderAgora";
import { SaasHome } from "pages/private/saasHome";
import Permutation from "pages/private/permutation";
import { InvoicePage } from "modules/invoice/presentation/InvoicePage";
import { InvoiceForm } from "modules/invoice/presentation/InvoiceForm/InvoiceForm";
import { SaasSaleWays } from "pages/private/saasSaleWays";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import Layout from "components/layout/presentation/Layout";
import { PublicInvoicePage } from "public/modules/invoice/PublicInvoicePage";
import { TaxForm } from "modules/invoice/presentation/TaxForm/TaxForm";
import { SaasChangePlan } from "pages/private/saasChangePlan";
import MainDashboard from "pages/private/mainDashboard";
import { ErpRoutes } from "modules/meepErp/presentation/ErpRoutes";
import { Orders } from "modules/order/presentation/pages/Orders/Orders";
import DigitalMenuConfigPage from "modules/digitalMenu/presentation/pages/DigitalMenuConfigPage";
import { SimpleSuccessPage } from "modules/saas/presentation/pages/SimpleSuccess/SimpleSuccessPage";
import SaasRegister from "pages/saasRegister";
import SimpleLoginPage from "modules/saas/presentation/pages/simpleLogin/SimpleLoginPage";
import ToolsPage from "modules/tools/presentation/pages/tools/ToolsPage";
import IfoodCalculatorPage from "modules/tools/presentation/pages/ifoodCalculator/IfoodCalculatorPage";
import CoilCalculatorPage from "modules/tools/presentation/pages/coilCalculator/CoilCalculatorPage";
import PaymentLink from "pages/private/paymentLink";
import Donations from "pages/private/donations";
import CieloSystemPage from "modules/saas/presentation/pages/CieloSystem/CieloSystemPage";
import { CieloPosTable } from "modules/saas/presentation/pages/CieloPosTable/CieloPosTable";
import ForgotPassword from "pages/forgotPassword";
import PlanLimitationWarning from "modules/saas/presentation/pages/PlanLimitationWarning/PlanLimitationWarning";
import SimpleForgetPassword from "modules/saas/presentation/pages/simpleForgetPassword/SimpleForgetPassword";
import MeepFee from "pages/private/meepFee";
import DigitalMenu from "pages/private/digitalMenu";
import LinkCards from "pages/private/linkCards";
import PrintQueue from "pages/private/printQueue";

export interface IRoutesProps {
  //propertys
}
const Routes: FC<IRoutesProps> = () => {
  const Home = () => {
    const { replace } = useHistory()
    const { currentLocal } = useLocal()

    useEffect(() => {
      if (localStorage.getItem("@token") === null) {
        replace("/login")
      }
    }, [replace])

    return ((
      currentLocal ?
        (
          currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas ?
            <Redirect to={`/private/saas/home`} />
            :
            <Redirect to={'/private/dashboard/general'} />
        )
        :
        <div>Verificando Local</div>
    ))
  }

  const notFound = () => (
    <Layout>
      <h2>Página não encontrada</h2>
      <Link to={"/"}>Voltar para o inicio</Link>
    </Layout>
  )

  return (
    <Switch>
      <Route exact path={"/"}>
        {
          <Redirect to={"/private/home"} />
        }
      </Route>
      <Route exact path={"/painel"} component={Painel} />
      <Route exact path={"/login"}>
        <Login />
      </Route>
      <Route exact path={"/signin"}>
        <Login />
      </Route>
      <Route exact path={"/public/guestList/:id"}>
        <PublicGuestList />
      </Route>
      <Route exact path={"/public/invoice/:localId"} component={PublicInvoicePage} />
      <Route exact path={"/login/esqueciSenha"} component={ForgotPassword} />
      <Route exact path={"/login/cadastro"} component={Signup} />
      <Route exact path="/public/lista-de-convidados/:id" component={PublicGuestList} />
      <Route exact path="/public/saas/">
        <Redirect to="/public/saas/register" />
      </Route>
      <Route path="/public/saas/">
        <Route exact path="/public/saas/register" component={SaasRegister} />
        <Route exact path="/public/saas/registerCielo" component={CieloSystemPage} />
        <Route exact path="/public/saas/login" component={LoginPage} />
        <Route exact path="/public/saas/simple-login" component={SimpleLoginPage} />
        <Route exact path="/public/saas/forget-password" component={ForgetPasswordPage} />
        <Route exact path="/public/saas/simple-forget-password" component={SimpleForgetPassword} />
        <Route exact path="/public/saas/change-password" component={ChangePasswordPage} />
        <Route exact path="/public/saas/code" component={CodePage} />
        <Route exact path="/public/saas/profile" component={ProfilePage} />
        <Route exact path="/public/saas/local" component={LocalPage} />
        <Route exact path="/public/saas/address" component={AddressPage} />
        <Route exact path="/public/saas/payment" component={PaymentPage} />

        <Route exact path="/public/saas/trial" component={TrialPage} />
        <Route exact path="/public/saas/fees" component={FeesPage} />
        <Route exact path="/public/saas/cieloPrices" component={CieloPosTable} />
        <Route exact path="/public/saas/track" component={SaasTrackPage} />
        <Route exact path="/public/saas/success-pos" component={SuccessPage} />
        <Route exact path="/public/saas/success" component={BasicSuccessPage} />
        <Route exact path="/public/saas/simple-success" component={SimpleSuccessPage} />
      </Route>
      <Route
        exact
        path="/public/lista-de-convidados/:id"
        component={PublicGuestList}
      />
      <AuthProvider>
        {/* <LocalProvider> */}
        <UserProvider>
          <MenuProvider>
            <PlansProvider>
              <BreadcumbsProvider homeUrl={"/"} placesUrl={"/private/"}>
                <TipsProvider>
                  <Route exact path={"/private*"}>
                    <Switch>
                      <Route exact path="/private/" component={Local} />
                      <Route exact path="/private/home" component={Home} />

                      <Route
                        exact
                        path={"/private/tutoriais"}
                        component={Tutoriais}
                      />
                      <Route
                        exact
                        path={"/private/cadastrarTutorial"}
                        component={CadastrarTutorial}
                      />

                      <Route
                        exact
                        path={"/private/meepFood/:tab?/:subTab?"}
                        component={MeepFood}
                      />
                      <Route
                        exact
                        path={"/private/event/meepFood/:tab?/:eventId"}
                        component={MeepFood}
                      />

                      <Route
                        exact
                        path={"/private/gestaoVendas/"}
                        component={GestaoVendas}
                      />
                      <Route
                        exact
                        path={"/private/solucoesOnline/"}
                        component={DigitalMenu}
                      />
                      <Route
                        exact
                        path={"/private/configuracaoCardapioDigital/"}
                        component={DigitalMenuConfigPage}
                      />
                      <Route path="/private/dashboard">
                        {/* <Route exact path={"/private/dashboard/sales"} component={SalesDashboard} />
                                      <Route exact path={"/private/dashboard/vendas/:sessionId"} component={Dashboard} />
                                      <Route exact path={"/private/dashboard/aovivo"} component={Live} />
                                      <Route exact path={"/private/dashboard/customers/:eventId?"} component={CustomersDashboard} /> */}
                        <Route
                          exact
                          path={"/private/dashboard/:tab?/:id?"}
                          component={Dashboard}
                        />
                      </Route>
                      <Route
                        exact
                        path={"/private/event/dashboard/:tab?/:id?/:eventId"}
                        component={Dashboard}
                      />
                      <Route
                        path="/private/customers/categories"
                        exact
                        component={CustomerCategories}
                      />
                      <Route path="/private/customers/">
                        <Route
                          exact
                          path={"/private/customers/"}
                          component={Customers}
                        />
                      </Route>

                      <Route
                        exact
                        path={"/private/financeiro/link-pagamento/:tab?"}
                        component={PaymentLink}
                      />

                      <Route
                        exact
                        path={"/private/financeiro/taxas"}
                        component={MeepFee}
                      />

                      <Route
                        exact
                        path={"/private/financial/permutation"}
                        component={Permutation}
                      />  

                      <Route
                        exact
                        path={"/private/mapa-operacoes/pre-ativacao-cartoes"}
                        component={LinkCards}
                      />

                      <Route
                        exact
                        path={"/private/equipe/colaboradores/"}
                        component={Colaboradores}
                      />
                      <Route
                        exact
                        path={"/private/equipe/cargos/"}
                        component={Cargos}
                      />

                      <Route
                        exact
                        path={"/private/pdv/fila-impressao"}
                        component={PrintQueue}
                      />

                      <Route
                        exact
                        path={"/private/pdv/monitoramento/"}
                        component={Monitoramento}
                      />
                      <Route
                        exact
                        path={"/private/event/pdv/monitoramento/:eventId"}
                        component={Monitoramento}
                      />
                      <Route
                        exact
                        path={"/private/novidades/"}
                        component={Novidades}
                      />
                      <Route
                        exact
                        path={"/private/pdv/equipamento/:action?/"}
                        component={Device}
                      />
                      <Route
                        exact
                        path={
                          "/private/event/pdv/equipamento/:action?/:eventId"
                        }
                        component={Device}
                      />
                      <Route
                        exact
                        path={"/private/vendas/saleNow"}
                        component={SaasSaleWays}
                      />
                      <Route
                        exact
                        path={"/private/vendas/terminal"}
                        component={SaasSaleWays}
                      />
                      <Route
                        exact
                        path={"/private/vendas/pos/:action?/:id?/:step?/:deviceType?"}
                        component={SaasSaleWays}
                      />
                      <Route
                        exact
                        path={
                          "/private/terminal"
                        }
                        component={Terminal}
                      />
                      <Route
                        exact
                        path={
                          "/private/terminal/SaleNow"
                        }
                        component={SaleNow}
                      />
                      <Route
                        exact
                        path={"/private/tickets/"}
                        component={MeepTickets}
                      />
                      <Route
                        exact
                        path={"/private/fidelity/:step?/:fidelityId?"}
                        component={FidelityPageContainer}
                      />

                      <Route
                        exact
                        path={"/private/saas/home"}
                        component={SaasHome}
                      />

                      <Route
                        exact
                        path={"/private/saas/mudar-plano"}
                        component={SaasChangePlan}
                      />

                      <Route
                        exact
                        path={"/private/saas/rastreamento/config"}
                        component={TrackingConfig}
                      />
                      <Route
                        exact
                        path={"/private/saas/payment"}
                        component={SaasPayment}
                      />
                      <Route
                        exact
                        path={"/private/saas/payment/success"}
                        component={SaasPaymentSuccess}
                      />
                      <Route
                        exact
                        path={"/private/saas/home"}
                        component={SaasHome}
                      />
                      <Route
                        exact
                        path={"/private/saas/mudar-forma-pagamento"}
                        component={ChangePaymentMethod}
                      />
                      <Route
                        exact
                        path={"/private/saas/rastreamento/config"}
                        component={TrackingConfig}
                      />
                      <Route
                        exact
                        path={"/private/saas/acesso-limitado"}
                        component={PlanLimitationWarning}
                      />


                      <Route path="/private/descontos/">
                        <Route
                          exact
                          path={"/private/descontos/"}
                          component={Discounts}
                        />

                        <Route
                          exact
                          path={"/private/descontos/adicionarLista"}
                          component={AddDiscountsPage}
                        />
                      </Route>
                      <Route path={"/private/eventos"}>
                        <Route
                          exact
                          path={"/private/eventos/list/:tab?"}
                          component={Events}
                        />
                        <Route
                          exact
                          path={"/private/eventos/adicionar/"}
                          component={AddEvents}
                        />
                      </Route>

                      <Route path={"/private/event/eventos"}>
                        <Route
                          exact
                          path={"/private/event/eventos/gerenciar/:eventId"}
                          component={Manage}
                        />
                        <Route
                          exact
                          path={"/private/event/eventos/fechamento/:eventId"}
                          component={CloseEvent}
                        />
                        <Route
                          exact
                          path={"/private/event/eventos/editar/:eventId"}
                          component={EditEvents}
                        />
                        <Route
                          exact
                          path={"/private/event/eventos/convidados/:eventId"}
                          component={EventPeopleList}
                        />
                        <Route
                          exact
                          path={"/private/event/eventos/listas/:eventId"}
                          component={EventGuestLists}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/eventos/listas/adicionar/:eventId"
                          }
                          component={AddGuestList}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/eventos/listas/editar/:eventListId"
                          }
                          component={AddGuestList}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/eventos/listas/convidados/:guestListId"
                          }
                          component={GuestList}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/eventos/listas/convidados/lista/adicionar/:listId"
                          }
                          component={AddGuest}
                        />
                      </Route>
                      <Route path={"/private/agendamento/"}>
                        <Route
                          exact
                          path={"/private/agendamento/servicos/"}
                          component={Services}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/servicos/adicionar"}
                          component={AddServicesSchedule}
                        />
                        <Route
                          exact
                          path={
                            "/private/agendamento/servicos/editar/:serviceId"
                          }
                          component={EditServicesSchedule}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/servicos/categorias/"}
                          component={ServiceCategories}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/lugares/"}
                          component={PlaceSchedule}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/lugares/adicionar"}
                          component={AddPlaceSchedule}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/lugares/editar/:placeId"}
                          component={FormPlaceSchedule}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/reservas"}
                          component={ReserveList}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/reserva/adicionar"}
                          component={AddReserveSchedule}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/colaborador/adicionar"}
                          component={AddColaborator}
                        />
                        <Route
                          exact
                          path={
                            "/private/agendamento/colaborador/editar/:collaboratorId"
                          }
                          component={EditColaborator}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/colaborador/lista"}
                          component={Collaborators}
                        />
                        <Route
                          exact
                          path={"/private/agendamento/list/:tab?"}
                          component={Agendamento}
                        />
                        {/* <Route exact path={"/private/agendamento/:tab?"} component={Agendamento} /> */}
                      </Route>

                      <Route
                        exact
                        path={"/private/agendamento/lugares/customizarCampos"}
                      >
                        <CustomField />
                      </Route>

                      <Route
                        exact
                        path={"/private/event/fluxoCasa/:tab?/:eventId"}
                        component={HouseFlow}
                      />

                      <Route
                        exact
                        path={"/private/fluxoCasa/:tab?"}
                        component={HouseFlow}
                      />

                      <Route exact path={"/private/cuponsDesconto"}>
                        <DiscountCoupons />
                      </Route>

                      <Route path={"/private/products"}>
                        <Route
                          exact
                          path={"/private/products/:tab?/:subTab?"}
                          component={Products}
                        />
                        <Route
                          exact
                          path={"/private/products/edit/product/:productId"}
                          component={EditProduct}
                        />
                        <Route
                          exact
                          path={"/private/products/add/product/item"}
                          component={AdicionarProduto}
                        />
                      </Route>
                      <Route
                        exact
                        path={"/private/cadastros/:tab?/:subTab?"}
                        component={Cadastros}
                      />
                      <Route
                        exact
                        path={"/private/produtos/limitarAcesso"}
                        component={LimitarAcesso}
                      />
                      <Route
                        exact
                        path={
                          "/private/event/cadastros/:tab?/:subTab?/:eventId"
                        }
                        component={Cadastros}
                      />
                      <Route
                        exact
                        path={"/private/relatorios/:tab?/:subTab?"}
                        component={Relatorio}
                      />
                      <Route
                        exact
                        path={
                          "/private/event/relatorios/:tab?/:subTab?/:eventId"
                        }
                        component={Relatorio}
                      />
                      <Route
                        exact
                        path={"/private/cashless/relatorios/:tab?"}
                        component={Relatorio}
                      />
                      <Route
                        exact
                        path={"/private/financeiro/faturas"}
                        component={Faturas}
                      />
                      <Route
                        exact
                        path={"/private/estoque/:tab?/:subTab?"}
                        component={Estoque}
                      />
                      <Route
                        exact
                        path={"/private/servicos/:tab?/:subTab?"}
                        component={Servicos}
                      />

                      <Route
                        exact
                        path={"/private/doacoes/:tab?/:subTab?"}
                        component={Doacoes}
                      />
                      <Route
                        exact
                        path={"/private/contaDigital/:type?"}
                        component={ContaDigital}
                      />
                      <Route
                        exact
                        path={"/private/contaDigital/:type?/extrato/:account?"}
                        component={Extrato}
                      />
                      <Route
                        exact
                        path={"/private/contaDigital/:type?/antecipate-received/:account?"}
                        component={AntecipateReceived}
                      />
                      <Route
                        exact
                        path={"/private/donate"}
                        component={Donations}
                      />
                      <Route
                        exact
                        path={"/private/contaDigital/:type?/antecipate/:account?"}
                        component={Antecipate}
                      />
                      <Route
                        exact
                        path={"/private/cartoes/:tab?"}
                        component={Cartoes}
                      />
                      <Route
                        exact
                        path={"/private/event/cartoes/:tab?/:eventId"}
                        component={Cartoes}
                      />
                      <Route
                        exact
                        path={
                          "/private/configuracao/:tab?/:subTab?/:action?/:id?/:step?/:deviceType?"
                        }
                        component={Configuracao}
                      />
                      <Route
                        exact
                        path={
                          "/private/configuracoes/:tab?/:subTab?/:action?/:id?/:step?/:deviceType?"
                        }
                        component={Configuracao}
                      />

                      <Route
                        exact
                        path={
                          "/private/pdv/perfil/:action?/:id?/:step?/:deviceType?"
                        }
                        component={Perfil}
                      />
                      <Route
                        exact
                        path={
                          "/private/pdv/perfil/:action?/:id?/:step?/:deviceType?"
                        }
                        component={Perfil}
                      />
                      <Route
                        exact
                        path={"/private/pdv/evento/perfil"}
                        component={PerfilEventos}
                      />

                      <Route exact path={"/private/pdv/impressoras-inteligentes"}>
                        <SmartPrinter />
                      </Route>

                      <Route
                        exact
                        path={"/private/event/pdv/perfil/:eventId"}
                        component={PerfilEventos}
                      />

                      <Route
                        exact
                        path={"/private/admin/:tab?/:subTab?"}
                        component={Admin}
                      />
                      <Route
                        exact
                        path={"/private/admin-novidades"}
                        component={AdminNovidades}
                      />

                      <Route
                        exact
                        path={"/private/configuracoes/:tab?/:subTab?"}
                        component={Admin}
                      />

                      <Route
                        exact
                        path={"/private/automatizacao/:tab?"}
                        component={Automations}
                      />

                      <Route
                        exact
                        path={"/private/automation"}
                        component={AutomationPage}
                      />

                      <Route
                        exact
                        path={"/private/invoice"}
                        component={InvoicePage}
                      />

                      <Route
                        exact
                        path={"/private/invoice/form"}
                        component={InvoiceForm}
                      />

                      <Route
                        exact
                        path={"/private/invoice/tax/create"}
                        component={TaxForm}
                      />

                      <Route
                        exact
                        path={"/private/invoice/tax/edit/:id"}
                        component={TaxForm}
                      />

                      <Route
                        exact
                        path={"/private/orders"}
                        component={Orders}
                      />

                      <Route path={"/private/catalog"}>
                        <Route
                          exact
                          path={"/private/catalog/:catalogId?"}
                          component={Catalog}
                        />
                        {/* <Route
                              exact
                              path={"/private/catalog/:catalogId/product/:action/:id"}
                              component={AddProduct}
                            /> */}
                        <Route
                          exact
                          path={"/private/catalog/product/:action"}
                          component={AddProduct}
                        />
                      </Route>
                      {/* <Route exact path={"/private/plan"} component={PlanPage} /> */}
                      <Route
                        exact
                        path={"/private/transacoes/:tab?/:subTab?"}
                        component={Transacoes}
                      />
                      <Route
                        exact
                        path={"/private/integrations/:tab?/:subTab?"}
                        component={Integracao}
                      />
                      <Route
                        exact
                        path={"/private/posPago/:tab?/:subTab?"}
                        component={PosPago}
                      />
                      <Route
                        exact
                        path={"/private/event/cashless/:tab?/:subTab?/:eventId"}
                        component={Cashless}
                      />
                      <Route
                        exact
                        path={"/private/cashless/:tab?/:subTab?"}
                        component={Cashless}
                      />

                      <Route
                        exact
                        path={"/private/crm/:tab?"}
                        component={Crm}
                      />
                      <Route
                        exact
                        path={"/private/crm/campaign/edit/:campaignId"}
                        component={CrmCampaignEdit}
                      />
                      <Route
                        exact
                        path={"/private/crm/campaign/report/:campaignId"}
                        component={Report}
                      />
                      <Route
                        exact
                        path={"/private/crm/campaign/config"}
                        component={ConfigCampanha}
                      />

                      <Route
                        exact
                        path={"/private/survey"}
                        component={SurveyLandingPage}
                      />

                      <Route
                        exact
                        path={"/private/track"}
                        component={TrackDetail}
                      />

                      <Route
                        exact
                        path={"/private/track/:action?/:id?"}
                        component={TrackPage}
                      />

                      <Route
                        exact
                        path={"/private/omie/detail"}
                        component={OmieDetail}
                      />
                      <Route
                        exact
                        path={"/private/sankhya/detail"}
                        component={SankhyaDetail}
                      />
                      <Route
                        exact
                        path={"/private/everest/detail"}
                        component={EverestDetail}
                      />

                      <Route exact path={"/private/plans"} component={Plans} />
                      <Route
                        exact
                        path={"/private/plans/purchase/:id"}
                        component={PurchasePlan}
                      />
                      <Route
                        exact
                        path={"/private/plans/history"}
                        component={PlanPaymentHistory}
                      />

                      <Route
                        exact
                        path={"/private/plans/config"}
                        component={ConfigPlans}
                      />

                      <Route
                        exact
                        path={"/private/onlinePayment/config"}
                        component={ConfigOnlinePayment}
                      />

                      <Route
                        exact
                        path={"/private/push/purchase"}
                        component={PurchasePush}
                      />
                      <Route
                        exact
                        path={"/private/email/purchase"}
                        component={PurchaseEmail}
                      />

                      <Route
                        exact
                        path={"/private/erp"}
                        component={ERPLandingPage}
                      />

                      <Route
                        exact
                        path={"/private/bi/configure"}
                        component={ConfigBI}
                      />
                      <Route
                        exact
                        path={"/private/bi/purchase"}
                        component={PurchaseBi}
                      />
                      <Route
                        exact
                        path={"/private/bi/:id?"}
                        component={BIPageContainer}
                      />

                      <Route
                        exact
                        path={"/private/ferramentas"}
                        component={ToolsPage}
                      />

                      <Route
                        exact
                        path={"/private/ferramentas/calculadora-ifood"}
                        component={IfoodCalculatorPage}
                      />

                      <Route
                        exact
                        path={"/private/ferramentas/calculadora-bobina"}
                        component={CoilCalculatorPage}
                      />

                      <Route
                        exact
                        path={"/private/onboarding"}
                        component={Onboarding}
                      />

                      <Route path={"/private/catalog"}>
                        <Route
                          exact
                          path={"/private/catalog/:catalogId?"}
                          component={Catalog}
                        />
                        {/* <Route
                            exact
                            path={"/private/catalog/:catalogId/product/:action/:id"}
                            component={AddProduct}
                          /> */}
                        <Route
                          exact
                          path={"/private/catalog/product/:action"}
                          component={AddProduct}
                        />
                      </Route>
                      <Route path="/private/meeperp" component={ErpRoutes} />
                      <Route
                        exact
                        path={"/private/external"}
                        component={External}
                      />
                      <Route
                        exact
                        path={"/private/access-denied"}
                        component={AccessDeniedPage}
                      />
                      <Route
                        exact
                        path={"/private/posts"}
                        component={Posts}
                      />
                      <Route
                        exact
                        path={"/private/main"}
                        component={MainDashboard}
                      />
                      <Route
                        exact
                        path={"*"}
                        component={notFound}
                      />
                    </Switch>
                  </Route>
                </TipsProvider>
              </BreadcumbsProvider>
            </PlansProvider>
          </MenuProvider>
        </UserProvider>
        {/* </LocalProvider> */}
      </AuthProvider>
    </Switch>
  );
};
export default Routes;