import { Button, Icon, IconButton } from '@material-ui/core';
import styles from './FidelityHistory.module.scss';
import TabView from 'components/tab/TabView';
import { UseFidelityHistory, historyStep } from './UseFidelityHistory';
import { format } from 'date-fns';
import { Pagination, Skeleton } from "@mui/material";
import { BalanceType } from 'modules/fidelity/presentation/interfaces/IFidelityBalance';

interface FidelityHistoryProps {
    onClose: () => void;
    customerDocument: string;
}

export const FidelityHistory = ({ onClose, customerDocument }: FidelityHistoryProps) => {
    const {
        loading,
        currentPage,
        totalPages,
        data,
        step,
        setStep,
        getMore
    } = UseFidelityHistory(customerDocument);

    return (
        <div className={styles.container}>
            <header>
                <h1>Histórico de <b>fidelidade</b></h1> <IconButton onClick={onClose}><Icon >close</Icon></IconButton>
            </header>
            <TabView
                index={step}
                onChangeTab={(tab) => setStep(tab.value)}
                tabs={
                    [
                        { label: 'Pontos', value: historyStep.POINTS },
                        { label: 'Moedas', value: historyStep.COINS },
                    ]
                }
            />

            <div className={styles.table}>
                {
                    (loading) ?
                        <>
                            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginTop: 24, marginBottom: 16, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: 16, borderRadius: 8 }} />
                        </> :
                        !data.length ?
                            <div className={styles.emptyList}>
                                <span>Esse cliente não possui dados para serem exibidos.</span>
                            </div> :
                            data.map((x, index) => (
                                <>
                                    <span className={styles.title}>{x.title}</span>
                                    {x.data.map(item => (
                                        <div className={styles.row}>
                                            <div className={styles.circle}>
                                                {
                                                    item.balanceType === BalanceType.ADD_BALANCE ?
                                                        <Icon className={styles.icon}>star_border</Icon> :
                                                        item.balanceType === BalanceType.SUB_BALANCE ?
                                                            <Icon className={styles.icon}>remove</Icon> :
                                                            <Icon className={styles.icon}>replay</Icon>
                                                }
                                            </div>
                                            <span className={styles.name}>{item.details}</span>
                                            <span className={styles.hour}>{format(new Date(item.date + 'Z'), 'HH:mm')}</span>
                                        </div>
                                    ))}
                                    {
                                        index < data.length - 1 &&
                                        <div className={styles.separator} />
                                    }
                                </>
                            ))
                }
            </div>
            <Pagination
                variant="outlined"
                shape="rounded"
                style={{ alignSelf: 'flex-end' }}
                sx={{
                    '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                    },
                    '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                    },
                }}
                className={styles.contentPagination}
                count={totalPages}
                page={currentPage}
                onChange={(_, value) => getMore(value)}
            />

            <div className={styles.buttonContainer}>
                <div>
                    <Button onClick={onClose} className={styles.buttons} type='button' variant='contained' color='secondary'>
                        Fechar
                    </Button>
                </div>
            </div>
        </div>
    )
}