import React, { useEffect, useState } from 'react';
import styles from './ValidateAccessCode.module.scss'; 
import UseDimension from "components/dimension/UseDimension";
import { AccessTimeRounded, InfoOutlined } from '@mui/icons-material';
import Button from 'components/ui/Button/Button';
import { IAuthPageFormErrors } from '../validate/AuthPageValidation';

interface ValidateAccessCodeProps {
  validadeMfaCodeHandle: (code: string) => Promise<void>;
  resendMfaCodeHandle: () => Promise<void>;
  onBack: () => void;
  errors: IAuthPageFormErrors;
}
const timerInSeconds = 60;

const ValidateAccessCode: React.FC<ValidateAccessCodeProps> = ({
  validadeMfaCodeHandle, 
  resendMfaCodeHandle, 
  onBack,
  errors,
}) => {
  const { isMobile } = UseDimension();
  const [mfaCode, setMfaCode] = useState<string[]>(["", "", "", "", "", ""]);
  const [counter, setCounter] = useState(timerInSeconds);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleInputChange = (index: number, value: string) => {
    if (value === "" || value.length === 1) {  
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);
      if (value && index < 5) {
        document.getElementById(`input-${index + 1}`)?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && !mfaCode[index]) {
      if (index > 0) {
        document.getElementById(`input-${index - 1}`)?.focus();
      }
    }
  };

  return (
    <div id={styles.containerValidateCode}>
      <div className={styles.description} style={{ textAlign: isMobile ? "center" : "start" }}>
        <h4>Insira o <b>código de acesso</b> enviado para seu e-mail</h4>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <InfoOutlined style={{ marginRight: "8px", color:"rgba(149, 92, 255, 1)" }} /> 
          <span>Por segurança, um código de acesso será enviado por e-mail sempre que acessar o portal.</span>
        </div>        
      </div>
      <div className={styles.inputs}>
        {mfaCode.map((value, index) => (
          <input 
            key={index}
            id={`input-${index}`}
            type="text"
            maxLength={1}
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={styles.inputBox}
            autoFocus={index === 0}  
          />
        ))}
      </div>
      
      {errors.code && <p className={styles.error}>{errors.code}</p>}

      <div className={styles.buttons}>
        <div className={styles.resendCode}>
          <AccessTimeRounded />
          <span>00:{counter < 10 ? `0${counter}` : counter}</span>
          <div onClick={() => counter === 0 ? resendMfaCodeHandle() : null} className={counter === 0 ? "" : styles.disabled}>Reenviar código de confirmação</div>
        </div>
        <Button type="button" onClick={() => {
          validadeMfaCodeHandle(mfaCode.join(""))
        } }>Confirmar</Button>
        <p onClick={onBack} className={styles.backButton}>Voltar</p>
      </div>
    </div>
  );
};

export default ValidateAccessCode;
