import { ComposedChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, CartesianGrid } from 'recharts';
import styles from './HourSales.module.scss'
import UseDimension from 'components/dimension/UseDimension';
import { format, isValid, parseISO } from 'date-fns';

interface CustomTooltipProps {
    active?: boolean
    payload?: any[]
    label?: string
}

interface HourSalesProps {
    data?: {
        records: {
            hour: number
            total: number
        }[],
        period: string
    };
    isLoading: boolean
    filter?: string
}

const getFormattedHour = (hour?: string): string => {
    const currentDate = new Date();
    const newDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), parseInt(hour ?? "0")));
    return newDate.getHours().toString();
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {

        return (
            <div className={styles.tooltip} >
                <p><b>{getFormattedHour(label)}h</b></p>
                <p>{payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
        );
    }

    return null;
};

const HourSales = ({ data, isLoading, filter }: HourSalesProps) => {
    const { isMobile } = UseDimension()
    return (
        <div id={styles.HourSales}>
            <div className={styles.top}>
                <div>
                    <p className={styles.title}>Horários com mais vendas durante o {isValid(new Date(filter ?? "")) ? "dia" : "caixa"}</p>
                    <p className={styles.description}>Acompanhe os horários com mais vendas no seu estabelecimento</p>
                </div>
                <p className={styles.description}>{data && format(parseISO(data.period + "Z"), 'dd/MM/yyyy HH:mm')}</p>
            </div>
            {isLoading ? (
                <div className={styles.animatedLoading}></div>
            ) : (
                <ResponsiveContainer width="100%" height={isMobile ? 800 : 400}>
                    <ComposedChart
                        barGap={4}
                        data={data?.records}
                        margin={{
                            top: 30,
                            bottom: 20,
                        }}
                        layout={isMobile ? 'vertical' : 'horizontal'}
                    >
                        {!isMobile && <CartesianGrid stroke="#f5f5f5" vertical={false} />}
                        {isMobile ? (
                            <>
                                <XAxis
                                    type="number"
                                    tickLine={false}
                                    tickSize={8}
                                    tickFormatter={(value) => (new Intl.NumberFormat("pt-BR").format(value))}
                                />
                                <YAxis dataKey="hour" type='category' tickLine={false} />
                            </>
                        ) : (
                            <>
                                <XAxis dataKey="hour" tickFormatter={(hour) => getFormattedHour(hour)} tickLine={false} type='category' />
                                <YAxis
                                    tickLine={false}
                                    tickSize={8}
                                    tickFormatter={(value) => (new Intl.NumberFormat("pt-BR").format(value))}
                                />
                            </>
                        )}
                        <Tooltip
                            content={({ active, payload, label }) =>
                                <CustomTooltip active={active} payload={payload} label={label} />}
                        />
                        <Bar
                            dataKey="total"
                            barSize={20}
                            fill="url(#colorUv)"
                            stroke="#955CFF"
                            strokeWidth={1}
                            className={styles.bar}
                        />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#955CFF" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#955CFF" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                    </ComposedChart>
                </ResponsiveContainer>
            )}

        </div>
    );
};

export default HourSales;
