import Layout from "components/layout/presentation/Layout";
import AddProductPage from "modules/products/presentation/pages/add/AddProductPage";

export const AdicionarProduto = () => {
  return (
    <Layout>
      <AddProductPage />
    </Layout>
  );
};
