import { useCallback, useState } from "react";
import { ICrmPlan, ICrmSignature } from "./ConfigCrmEmail";
import CrmServiceApi from "services/api/crm/CrmApi";
import GetPlansUseCase from "modules/crm/application/useCases/plans/GetPlansUseCase";
import GetCurrentEmailSignatureUseCase from "modules/crm/application/useCases/plans/GetCurrentEmailSignatureUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { ITotalReport } from "modules/crm/presentation/intefaces/TotalReport";
import GetTotalReportUseCase from "modules/crm/application/useCases/plans/GetTotalReportUseCase";
import SaveSignatureUseCase from "modules/crm/application/useCases/plans/SaveSignatureUseCase";
import { CampaignChannel } from "../../interfaces/ICampaign";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import { ChannelEnum } from "modules/crm/presentation/interfaces/ChannelEnum";

export const UseConfigCrmEmail = () => {
  const { currentLocal } = useLocal();
  const { user } = useUser();

  const [plans, setPlans] = useState<ICrmPlan[]>();
  const [selectedPlan, setSelectedPlans] = useState<ICrmPlan>();
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);

  const [signature, setSignature] = useState<ICrmSignature>();
  const [isLoadingSignature, setIsLoadingSignature] = useState(true);

  const getPlans = useCallback(async () => {
    try {
      setIsLoadingPlans(true);
      const service = CrmServiceApi();
      const response = await GetPlansUseCase(service);
      setPlans(response);
      setSelectedPlans(response.find((plan) => plan.price === 199));
    } finally {
      setIsLoadingPlans(false);
    }
  }, []);

  const getSignature = useCallback(async () => {
    try {
      if (currentLocal) {
        setIsLoadingSignature(true);
        const service = CrmServiceApi();
  
        const promises: [
          Promise<ICrmSignature | undefined>,
          Promise<ITotalReport[]>
        ] = [
          GetCurrentEmailSignatureUseCase(service, currentLocal.id),
          GetTotalReportUseCase(service, currentLocal.id, ChannelEnum.Email),
        ];
  
        const response = await Promise.all(promises);
  
        setSignature(response[0]);
      }
    } finally {
      setIsLoadingSignature(false);
    }
  }, [currentLocal]);

  const onClickAllowPlanHandle = useCallback(async () => {
    if (selectedPlan) {
      try {
        setIsLoadingSignature(true);
        const service = CrmServiceApi();
        const response = await SaveSignatureUseCase({
          service: service,
          ownerId: currentLocal!.id,
          ownerName: currentLocal!.name,
          plan: selectedPlan,
          channel: CampaignChannel.email,
          userId: user!.id,
          userName: user!.name,
        });

        setSignature(response);
      } finally {
        setIsLoadingSignature(false);
      }
    }
  }, [currentLocal, selectedPlan, user]);

  return {
    plans,
    isLoadingPlans,
    onClickAllowPlanHandle,
    signature,
    isLoadingSignature,
    getPlans,
    getSignature,
  };
};
