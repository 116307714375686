import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ILocalResumeItem } from '../localResume/ILocalResume';
import styles from './StickyLegend.module.scss'

export interface IStickyLegendProps {
    data: ILocalResumeItem[];
}

const LocalResume: FC<IStickyLegendProps> = ({ data }) => {
    const stickyRef = useRef<HTMLDivElement>(null);
    const [stickyTop, setStickyTop] = useState(0);

    const handleScroll = useCallback(() => {
        if (stickyRef.current) {
            if (window.scrollY >= stickyTop) {
                stickyRef.current.className = styles.sticky;
            } else if (stickyRef.current.className === styles.sticky) {
                stickyRef.current.className = '';
            }
        }
    }, [stickyTop]);

    useLayoutEffect(() => {
        if (stickyRef.current) {
            setStickyTop(window.scrollY + stickyRef.current.getBoundingClientRect().top);
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll, stickyTop]);

    useEffect(() => {
        if (stickyTop)
            handleScroll();
    }, [handleScroll, stickyTop]);

    return (
        <div id={styles.StickyLegend}>
            <div id={styles.container} ref={stickyRef}>

                {
                    data?.map(x => (
                        <div key={x.placeId}>
                            <div className={styles.bullet} style={{ backgroundColor: x.color }} />
                            <span>{x.name}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default LocalResume