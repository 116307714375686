import { ICrmService } from "modules/crm/models/interface/ICrmService";
import { ICrmEventListData, ICrmEventStatus, ICrmEventType } from "modules/crm/presentation/intefaces/ICrmEventData";

const GetEventsList = async (
  service: ICrmService,
  OwnerId: string,
  Name: string,
  type?: ICrmEventType,
  status?: ICrmEventStatus
):Promise<ICrmEventListData> => {
  const response = service.getEventsList({ ownerId: OwnerId, name: Name, status, type });
  return response;
};

export default GetEventsList;
  