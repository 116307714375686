import { ComposedChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, CartesianGrid } from 'recharts';
import styles from './SalesPerMounth.module.scss'
import UseDimension from 'components/dimension/UseDimension';
import { formatMonthNumberFromString } from '../utils';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface SalesPerMounthProps {
    data?: {
        records: {
            month: number
            total: number
        }[]
    };
    isLoading: boolean
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip} >
                <p><b>{label}</b></p>
                <p>{payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
        );
    }

    return null;
};

const SalesPerMounth = ({ data, isLoading }: SalesPerMounthProps) => {
    const { isMobile } = UseDimension()
    const responseFormat = {
        records: data?.records.map(item => ({
            month: formatMonthNumberFromString(item.month),
            total: item.total
        }))
    }
    return (
        <div id={styles.SalesPerMounth}>
            <p className={styles.title}>Vendas durante o ano</p>
            <p className={styles.description}>Compare suas vendas entre os meses do ano</p>
            {isLoading ? (
            <div className={styles.animatedLoading}></div>
            ):(
                <ResponsiveContainer width="100%" height={isMobile ? 600 : 400}>
                <ComposedChart
                    barGap={4}
                    data={responseFormat?.records}
                    margin={{
                        top: 30,
                        bottom: 20,
                    }}
                    layout={isMobile ? 'vertical' : 'horizontal'}
                >
                    {!isMobile && <CartesianGrid stroke="#f5f5f5" vertical={false} />}
                    {isMobile ? (
                        <>
                            <XAxis
                                type="number"
                                tickLine={false}
                                tickSize={8}
                                tickFormatter={(value) => (new Intl.NumberFormat("pt-BR").format(value))}
                            />
                            <YAxis dataKey="month" type='category' tickLine={false} />
                        </>
                    ) : (
                        <>
                            <XAxis dataKey="month" tickLine={false} type='category' />
                            <YAxis
                                tickLine={false}
                                tickSize={8}
                                tickFormatter={(value) => (new Intl.NumberFormat("pt-BR").format(value))}
                            />
                        </>
                    )}
                    <Tooltip
                        content={({ active, payload, label }) =>
                            <CustomTooltip active={active} payload={payload} label={label} />}
                    />
                    <Bar
                        dataKey="total"
                        barSize={20}
                        fill={"#DFCDFF"}
                        radius={10}
                        className={styles.bar}
                    />

                </ComposedChart>
            </ResponsiveContainer>
            )}



        </div>
    );
};

export default SalesPerMounth;
