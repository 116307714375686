import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService";
import { IAccessProductValues} from "modules/catalog/presentation/componentes/productActions/accessProductForm/AccessProductForm";

const GetLimitProductAccessUseCase = async (catalogService: ICatalogService, productId: string): Promise<IAccessProductValues> => {
    const response = await catalogService.getLimitProductAccess(productId);
    return {
        accessUsers: response
    };
};

export default GetLimitProductAccessUseCase