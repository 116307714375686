  import { useUi } from "contexts/userInterface/UserInterfaceContext"
import { useLocal } from "modules/local/presentation/context/LocalContext"
import { GetPlaceScheduleListUseCase } from "modules/schedule/application/useCases/placeSchedule/GetPlaceScheduleListByLocalIdUseCase"
import PlacesScheduleApi from "services/api/schedule/places/PlacesScheduleApi"
import { useCallback, useEffect, useState } from "react"
import { ISchedulePlaceCard } from "../../components/schedulePlaceCard/interfaces/ISchedulePlaceCard"
import { useHistory, useParams } from "react-router-dom"
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext"

const RouterPrefix = "/private/agendamento/list/"

export const UsePlaceScheduleListPage = () => {

    const [values, setValues] = useState<ISchedulePlaceCard[]>()

    const { push } = useHistory();
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();
    const { updateRouters } = useBreadcumbs();
    const { tab } = useParams<{ tab: string }>()

    const getPlaceList = useCallback(() => {
        if (currentLocal) {
            showLoading()
            GetPlaceScheduleListUseCase(currentLocal.id, PlacesScheduleApi).then((response) => {
                setValues(response);
            }).finally(() => {
                hideLoading();
            })
        }
    },[currentLocal]);

    useEffect(() => {
        getPlaceList()
    }, [currentLocal]);

    useEffect(() => {
        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Passeios',
                url: RouterPrefix+tab
            }
        ])
    }, [tab, updateRouters]);

    const goAddPlaceSchedule = useCallback(() => {
        push(`/private/agendamento/lugares/adicionar/`);
    }, [push])

    return (
        {
            values,
            setValues,
            goAddPlaceSchedule,
            getPlaceList
        }
    )
}
