import { ISurveyItem } from "modules/automation/presentation/interfaces/ISurvey";
import { ICrmEvent } from "./ICrmEventData";

export enum CampaignChannel {
    unknown = 0,
    email = 1,
    phone = 2,
    whatsapp = 3,
    signup = 4,
    push = 5,
}
export enum CampaignTemplate {
    event = 1,
    info = 2,
    survey
}
export enum CampaignStatus {
    Draft = 0,
    Running = 1,
    Sent = 2,
    Stopped = 3,
    Pending = 4,
    Approved = 5,
    Reproved = 6
}
// export interface ICampaignEvent {
//     id: string;
//     name: string;
//     startDate?: string;
//     endDate?: string;
//     url?: string;
//     bannerUrl?: string;
//     thumbnailUrl?: string;
// }
export interface ICampaignEmail {
    event?: ICrmEvent;
    from?: string;
    replyTo: string;
    subject: string;
    promoter: string;
    // greeting: string,
    messageBody: string;
    survey?: ISurveyItem;
}
export interface ICampaignWhatsapp {
    event?: ICrmEvent;
    message: string;
}
export interface ICampaignPush {
    date?: string;
    time?: string;
    imageUrl?: string;
    subject?: string;
    messageBody?: string;
    survey?: ISurveyItem;
}
export interface ICampaignFormValue {
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    email?: ICampaignEmail;
    whatsapp?: ICampaignWhatsapp;
    push?: ICampaignPush
}

export interface IReprovedActivity {
    actionName: string;
    createdAt: Date;
    createdById: string;
    description: string;
    id: string;
    modifiedAt: Date;
}

export interface ICampaignValues {
    id: string,
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    status: CampaignStatus;
    totalCustomers: number;

    createdAt?: string;
    eventId?: string;
    eventName?: string;
    // addresseeQuantity: number;
    sendedAt: string,
    subject: string,
    promoter: string,
    replyTo: string,
    messageBody: string,
    totalCustomersPhoneNumber: number,
    totalCustomersEmail: number
    deliveryTime?: Date;
    imageUrl?: string;
    surveyId?: string;
    reprovedActivity: IReprovedActivity;
}


export interface ICampaignItem {
    id: string,
    name: string;
    channel: CampaignChannel;
    template: CampaignTemplate;
    status: CampaignStatus;
    totalCustomers: number;

    createdAt?: string;
    eventId?: string;
    eventName?: string;
    // addresseeQuantity: number;
    sendedAt: string,
    deliveryTime?: Date;
    subject: string,
    messageBody: string,
    ownerName: string,
}

export interface ICampaignData {
    currentPage: number,
    totalPages: number,
    totalRegisters: number,
    data: ICampaignItem[]
}
