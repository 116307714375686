import { FC, useMemo } from "react";
import styles from "./TIDsList.module.scss";
import { ITID } from "../../interfaces/ITIDsList";
import TIDsItem from "../tidsItem/TIDsItem";
import { IAprovacaoTIDsColumns } from "../../interfaces/IAprovacaoTIDsColumns";
import { SideSheetType } from "../aprovacaoTIDsSideSheet/AprovacaoTIDsSideSheet";

export interface ITIDsListProps {
  tidsList: ITID[] | null;
  columns: IAprovacaoTIDsColumns[];
  openSidesheet: (id: string, type: SideSheetType) => void
}

const TIDsList: FC<ITIDsListProps> = ({
  tidsList,
  columns,
  openSidesheet,
}) => {
  const renderItems = useMemo(
    () =>
      tidsList && tidsList?.map((it) => (
        <TIDsItem
          key={it.AcquirerMerchantId}
          columns={columns}
          tid={it}
          openSidesheet={openSidesheet}
        />
      )),
      [columns, openSidesheet, tidsList]
    );

  return <div id={styles.TIDsList}>{renderItems}</div>;
};

export default TIDsList;
