import { IPromotersResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IAccessService } from '../../interfaces/IAccessService';

const GetPromotersWithAccessUseCase = async (
  accessService: IAccessService,
  eventId: string,
  localId: string,
): Promise<IPromotersResponse[]> => {
  return await accessService.getPromotersWithAccess(eventId, localId);
};

export default GetPromotersWithAccessUseCase;
