import { IGetSplitLocalsResponse } from 'modules/products/domain/dto/IGetSplitLocalsResponse';
import { IProductsService } from '../../../domain/interfaces/IProductsService';

const GetSplitLocalsUseCase = async (
  productsService: IProductsService,
  localId: string,
): Promise<IGetSplitLocalsResponse[]> => {
  return await productsService.getSplitLocals(localId);
};

export default GetSplitLocalsUseCase;
