import React, { FC } from 'react'
import PlaceItemScheduleForm from '../../components/placeItemScheduleForm/PlaceItemScheduleForm';
import styles from './EditFormPlaceSchedulePage.module.scss'
import UseEditFormPlaceSchedulePage from './UseEditFormPlaceSchedulePage';
export interface IAddPlaceSchedulePageProps {
    //propertys
}
const EditFormPlaceSchedulePage: FC= () => {
    const { orderPads, services, onAddPlaceHandle, defaultValues, getPlaceScheduleByIdData } = UseEditFormPlaceSchedulePage();
    return (
        <div id={styles.EditFormPlaceSchedulePage} >
            <div className={styles.container} >
                <PlaceItemScheduleForm orderPadsList={orderPads} defaultValues={defaultValues} servicesList={services} onSubmit={onAddPlaceHandle} reloadPageEdit={getPlaceScheduleByIdData} ></PlaceItemScheduleForm>
            </div>
        </div>
    )
}
export default EditFormPlaceSchedulePage