import { IAverageTopList } from "components/graphs/averageTopList/IAverageTopList";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import IDashboardService from "../interfaces/IDashboardService";

const GetSaleByEquipmentUseCase = async (dashboardService: IDashboardService, filter: IFilterRequest): Promise<IAverageTopList> => {
  const result = await dashboardService.getSaleByEquipment(filter);
  result.amountLabel = 'Valor';
  result.label = 'Dispositivo';
  return result;
}

export default GetSaleByEquipmentUseCase;