import styles from "./ConfigSankhyaPayment.module.scss";
import { useEffect, useMemo } from "react";
import { DeleteOutline } from "@mui/icons-material";
import Input from "components/ui/input/Input";
import { FormHelperText, MenuItem, Select } from "@mui/material";
import { ISankhyaPaymentRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IGetSankhyaPaymentFormResponse } from "modules/config/integrationsConfig/domain/dto/IGetSankhyaPaymentFormResponse";
import { ISankhyaPaymentFormErrors } from "modules/config/integrationsConfig/presentation/pages/configSankhya/validade/SankhyaPaymentValidation";
import { UseConfigSankhya } from "modules/config/integrationsConfig/presentation/pages/configSankhya/UseConfigSankhya";

export interface IConfigSankhyaPaymentProps {
  creditCard: boolean;
  setCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
  creditValues: ISankhyaPaymentRequest;
  setCreditValues: React.Dispatch<React.SetStateAction<ISankhyaPaymentRequest>>;
  formsOfPayment?: IGetSankhyaPaymentFormResponse[];
  errors: ISankhyaPaymentFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<ISankhyaPaymentFormErrors>>;
}

const ConfigSankhyaPayment = ({
  creditCard,
  setCreditCard,
  creditValues,
  setCreditValues,
  formsOfPayment,
  errors,
  setErrors,
}: IConfigSankhyaPaymentProps) => {
  const { currentLocal } = useLocal();
  const { configValues, deleteSankhyaPayment } = UseConfigSankhya();

  const payments = useMemo(() => configValues?.formsOfPayment ?? [], [configValues?.formsOfPayment]);  

  const allFormsOfPayments = useMemo(() => formsOfPayment ?? [], [formsOfPayment]);
  
  const meepPaymentForms = useMemo(() => {
    return allFormsOfPayments.filter((item) => {
      return !payments.some((it) => it.meepFormOfPaymentId === item.id);
    });
  },
    [payments, allFormsOfPayments]
  );

  useEffect(() => {
    if (currentLocal) {
      return setCreditValues({...creditValues, MeepOwnerId: currentLocal.id });
    }
  }, [setCreditValues, currentLocal]);

  const handlePaymentForm = (id: string) => {
    return allFormsOfPayments?.find((it) => it.id === id)
  };  

  if (creditCard) {
    return (
      <>
        <div>
          <label htmlFor="meep-form-of-payment-id" className={styles.required}>
            Formas de pagamento MEEP
          </label>
          <Select
            name="meep-form-of-payment-id"
            value={creditValues.MeepFormOfPaymentId}
            style={{ width: "100%" }}
            size="small"
            onChange={(ev) => {
              setCreditValues((prev) => ({
                ...prev,
                MeepFormOfPaymentId: ev.target.value,
              }));
              setErrors((prev) => ({ ...prev, MeepFormOfPaymentId: undefined }));
            }}
            error={!!errors.MeepFormOfPaymentId}
          >
            {meepPaymentForms?.map((form) => (
              <MenuItem value={form.id} key={form.id}>
                {form.descricao}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText 
            style={{ color: "#f44336", margin: "4px 14px 0" }}
          >
            {errors.MeepFormOfPaymentId}
          </FormHelperText>
        </div>
        <div>
          <label htmlFor="sankhya-bank" className={styles.required}>
            Banco Sankhya
          </label>
          <Input
            name="sankhya-bank"
            variant={"outlined"}
            placeholder="Banco Sankhya"
            type="number"
            value={creditValues.SankhyaBank}
            onChange={(ev) => {
              setCreditValues((prev) => ({
                ...prev,
                SankhyaBank: Number(ev.target.value),
              }));
              setErrors((prev) => ({ ...prev, SankhyaBank: undefined }));
            }}
            error={!!errors.SankhyaBank}
            helperText={errors.SankhyaBank}
            />
        </div>
        <div>
          <label htmlFor="sankhya-account" className={styles.required}>
            Conta Sankhya
          </label>
          <Input
            name="sankhya-account"
            variant={"outlined"}
            placeholder="Banco Sankhya"
            type="number"
            value={creditValues.SankhyaAccount}
            onChange={(ev) => {
              setCreditValues((prev) => ({
                ...prev,
                SankhyaAccount: Number(ev.target.value),
              }));
              setErrors((prev) => ({ ...prev, SankhyaAccount: undefined }));
            }}
            error={!!errors.SankhyaAccount}
            helperText={errors.SankhyaAccount}
          />
        </div>
        <div>
          <label htmlFor="sankhya-payment-type-id" className={styles.required}>
            Tipo de pagamento
          </label>
          <Input
            name="sankhya-payment-type-id"
            variant={"outlined"}
            placeholder="Tipo de pagamento"
            type="number"
            value={creditValues.SankhyaPaymentTypeId}
            onChange={(ev) => {
              setCreditValues((prev) => ({
                ...prev,
                SankhyaPaymentTypeId: Number(ev.target.value),
              }));
              setErrors((prev) => ({ ...prev, SankhyaPaymentTypeId: undefined }));
            }}
            error={!!errors.SankhyaPaymentTypeId}
            helperText={errors.SankhyaPaymentTypeId}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.paymentButton} onClick={() => setCreditCard(true)}>
        <img src="/assets/icon/add-circle.svg" alt="Novo" />
        <span>Novo pagamento</span>
      </div>
      {payments.map((item) => (
        <div
          className={`${styles.paymentButton} ${styles.creditCardButton}`}
          key={item.id}
        >
          <span>{handlePaymentForm(item.meepFormOfPaymentId)?.tipoPagamento.descricao}</span>
          <div className={styles.creditCardEndButtons}>
            <DeleteOutline 
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteSankhyaPayment(item.id);
            }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ConfigSankhyaPayment;
