import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./SaleForDevicePage.module.scss";
import ForDeviceFilter from "./components/forDeviceFilter/ForDeviceFilter";
import ForDeviceHeader from "./components/forDeviceHeader/ForDeviceHeader";
import ForDeviceItem from "./components/forDeviceItem/ForDeviceItem";
import { UseSaleForDevicePage } from "./hooks/UseSaleForDevicePage";
import { IPostSaleForDeviceResponse } from "../domain/dto/IPostSaleForDeviceResponse";
import UseDimension from "components/dimension/UseDimension";
import ForDeviceTotalItem from "./components/forDeviceTotalItem/ForDeviceTotalItem";
import { Divider, Radio, Skeleton } from "@mui/material";
import { IPostSaleForDeviceParams } from "../domain/dto/IPostSaleForDeviceParams";
import ForDeviceItemMobile from "./components/forDeviceItemMobile/ForDeviceItemMobile";
import Sidesheet from "components/sidesheet/Sidesheet";
import CashiersSidesheet from "./components/cashiersSidesheet/CashiersSidesheet";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import UseCashlessDashboardPage from "modules/cashlessDashboard/presentation/UseCashlessDashboardPage";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { moneyMaskNumber } from "services/utils/Money";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import ObservationDevice from "./components/observationDevice/ObservationDevice";

interface IXLSXExportData {
    Equipamento: string;
    NumeroSérie: string;
    Cancelado: number;
    Cashless: number;
    Debito: number;
    DebitoNT: number;
    Credito: number;
    CreditoNT: number;
    Dinheiro: number;
    Desconto: number;
    Voucher: number;
    Outros: number;
    EmAberto: number;
    Suprimento: number;
    Sangria: number;
    TaxaServico: number;
    Total: number;
}


const SaleForDevicePage = () => {
    const { postSaleForDeviceReport, reportData: response, isLoading, cashiersByPeriod: defaultCashiersByPeriod, getCashiersByPeriod } = UseSaleForDevicePage();
    const { dimensions } = UseDimension();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();
    const { cashiers } = UseCashlessDashboardPage();

    const [params, setParams] = useState({} as IPostSaleForDeviceParams);
    const [openSidesheet, setOpenSidesheet] = useState(false);
    const [openExportSidesheet, setOpenExportSidesheet] = useState(false);
    const [exportSidesheetStep, setExportSidesheetStep] = useState(1);
    const [exportType, setExportType] = useState<"pdf" | "xlsx">("xlsx");
    const [onExport, setOnExport] = useState<string | null>(null);
    const [totalDevices, setTotalDevices] = useState<number>(0);

    const pdfRef = useRef<HTMLDivElement>(null);

    const reportData = useMemo(() => response ?? {} as IPostSaleForDeviceResponse, [response]);
    const cashiersByPeriod = useMemo(() => defaultCashiersByPeriod ?? [], [defaultCashiersByPeriod]);
    const showExportButton = useMemo(() => !isLoading && !!Object.keys(reportData).length && !!reportData.total && !!reportData.vendas.length, [isLoading, reportData]);

    useEffect(() => {
        if (params.sessaoId || (params.dataInicio && params.dataFim)) {
            postSaleForDeviceReport(params);
        }
    }, [params, postSaleForDeviceReport]);

     useEffect(() => {
        if (reportData.vendas){
            const devices: string[] = [];
            reportData.vendas.forEach((itemVendas) => {
                itemVendas.equipamentos.forEach((item) => {
                    if (!devices.includes(item.equipamentoNome) && item.equipamentoNome.toLocaleLowerCase() !== 'online')
                        devices.push(item.equipamentoNome);
                });
            });
            setTotalDevices(devices.length);
        }

    }, [reportData.vendas]);

    const skeletons = Array.from({ length: 4 }, (_, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: '40px', marginBottom: "10px" }} />
    ));

    const handleOpenSidesheet = useCallback((startDate: Date, endDate: Date) => {
        setOpenSidesheet(true);
        getCashiersByPeriod(startDate, endDate);
    }, [getCashiersByPeriod]);

    const generatePDF = useCallback(async () => {
        if (!pdfRef.current) return;
    
        const pdf = new jsPDF({ orientation: 'landscape', format: [800, 600] });
        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = 1260;
        const padding = 20;
    
        const elements = pdfRef.current.querySelectorAll(".div-to-capture");
        const divs = Array.from(elements).filter((element) => element instanceof HTMLElement);
    
        let currentHeight = 0;
        let divsByPage: Element[] = [];
        let pages: Element[] = [];
        
        for (const div of divs) {
          const { height } = div.getBoundingClientRect();
    
          if (currentHeight + height < pageHeight) {
            currentHeight += height;
            divsByPage.push(div);
          } else {
            const uniqueDiv = document.createElement("div");
            
            divsByPage.forEach(element => {
              uniqueDiv.appendChild(element.cloneNode(true));
            });
            
            pages.push(uniqueDiv);
            currentHeight = height;
            divsByPage = [div];
          }
        }
    
        if (divsByPage.length) {
          const uniqueDiv = document.createElement("div");
    
          divsByPage.forEach(element => {
            uniqueDiv.appendChild(element.cloneNode(true));
          });
          
          pages.push(uniqueDiv);
        }
    
        for (let i = 0; i < pages.length; i++) {
          const tempDiv = pages[i] as HTMLElement;
          tempDiv.style.width = "1915px";
          
          if (i === pages.length - 1) {
            tempDiv.style.height = "1260px";
          }

          document.body.appendChild(tempDiv);
    
          const canvas = await html2canvas(tempDiv);
          const imgData = canvas.toDataURL("image/png");
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
          pdf.addImage(imgData, "PNG", padding, padding, imgWidth - 2 * padding, imgHeight - 5 * padding);
    
          if (i !== pages.length - 1) {
            pdf.addPage();
          }
    
          document.body.removeChild(tempDiv);
        }
    
        setOnExport(null);
        hideLoading();
        setExportSidesheetStep(exportSidesheetStep + 1);
        
        const fileName = "venda_por_equipamento.pdf";
        pdf.save(fileName);
    }, [hideLoading, setExportSidesheetStep, exportSidesheetStep]);
    
    const generateXLSX = useCallback(() => {
        if (reportData) {
            const deviceData = reportData.vendas.flatMap(
            (sale) =>
                sale.equipamentos.reduce((array, device, deviceIndex, devicesArray) => {
                    const deviceData = {
                        Equipamento: device.equipamentoNome,
                        NumeroSérie: device.equipamentoNumeroSerie,
                        Cancelado: device.cancelado ?? 0, 
                        Cashless: device.valorCashless ?? 0, 
                        Debito: device.valorDebito ?? 0, 
                        DebitoNT: device.valorDebitoNT ?? 0, 
                        Credito: device.valorCredito ?? 0, 
                        CreditoNT: device.valorCreditoNT ?? 0, 
                        Dinheiro: device.valorDinheiro ?? 0, 
                        Desconto: device.valorDesconto ?? 0, 
                        Voucher: device.valorVoucher ?? 0, 
                        Outros: device.valorOutro ?? 0, 
                        EmAberto: device.valorEmAberto ?? 0, 
                        Suprimento: device.suprimentos ?? 0, 
                        Sangria: device.sangrias ?? 0, 
                        TaxaServico: device.taxaServico ?? 0, 
                        Total: device.valorTotal ?? 0, 
                    };
    
                    array = [...array, deviceData];
                    
                    if (deviceIndex === devicesArray.length - 1) {
                        const lastDeviceData = {
                            Equipamento: "",
                            NumeroSérie: format(new Date(sale.dataInicio + "Z"), "dd/MM/yyyy' - 'HH:mm") ?? "",
                            Cancelado: sale.cancelado ?? 0,
                            Cashless: sale.cashless ?? 0,
                            Debito: sale.debito ?? 0,
                            DebitoNT: sale.debitoNT ?? 0,
                            Credito: sale.credito ?? 0,
                            CreditoNT: sale.creditoNT ?? 0,
                            Dinheiro: sale.dinheiro ?? 0,
                            Desconto: sale.desconto ?? 0,
                            Voucher: sale.voucher ?? 0,
                            Outros: sale.outro ?? 0,
                            EmAberto: sale.emAberto ?? 0,
                            Suprimento: sale.suprimento ?? 0,
                            Sangria: sale.sangria ?? 0,
                            TaxaServico: sale.taxaServico ?? 0,
                            Total: sale.total ?? 0,
                        };
                        array = [...array, lastDeviceData];
                    }
                
                    return array;
                }, [] as IXLSXExportData[])
            );
    
            const totalData = {
                Equipamento: "",
                NumeroSérie: "Total Geral:",
                Cancelado: reportData.cancelado ?? 0,
                Cashless: reportData.cashless ?? 0,
                Debito: reportData.debito ?? 0,
                DebitoNT: reportData.debitoNT ?? 0,
                Credito: reportData.credito ?? 0,
                CreditoNT: reportData.creditoNT ?? 0,
                Dinheiro: reportData.dinheiro ?? 0,
                Desconto: reportData.desconto ?? 0,
                Voucher: reportData.voucher ?? 0,
                Outros: reportData.outros ?? 0,
                EmAberto: reportData.emAberto ?? 0,
                Suprimento: reportData.suprimento ?? 0,
                Sangria: reportData.sangria ?? 0,
                TaxaServico: reportData.taxaServico ?? 0,
                Total: reportData.total ?? 0,
            } as IXLSXExportData;
    
            deviceData.push(totalData);
    
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(deviceData);
            const currencyFormat = '"R$" #,##0.00';
            const currencyColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
            currencyColumns.forEach(col => {
                for (let i = 2; i <= deviceData.length + 1; i++) {
                    const cellRef = `${col}${i}`;
                    if (worksheet[cellRef]) {
                        worksheet[cellRef].z = currencyFormat; 
                    }
                }
            });
    
            XLSX.utils.book_append_sheet(
                workbook,
                worksheet,
                "venda_por_equipamento"
            );
    
            const blob = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            const blobUrl = URL.createObjectURL(
                new Blob([blob], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
    
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", "venda_por_equipamento.xlsx");
            document.body.appendChild(link);
    
            link.click();
            document.body.removeChild(link);
    
            setOnExport(null);
            hideLoading();
            setExportSidesheetStep(exportSidesheetStep + 1);
        }
    }, [exportSidesheetStep, hideLoading, reportData]);
    

    const handleOnClickExport = useCallback(async () => {
        showLoading();
        if (params.dataInicio && params.dataFim) {
            await getCashiersByPeriod(new Date(params.dataInicio ?? ""), new Date(params.dataFim ?? ""));
        }
        if (exportType === "pdf") {
            setOnExport("pdf");
            setTimeout(() => {
                generatePDF();
            }, 2000);
            return;
        }
        setOnExport("xlsx");
        setTimeout(() => {
            generateXLSX();
        }, 2000);
    }, [exportType, generatePDF, showLoading, getCashiersByPeriod, params.dataInicio, params.dataFim, generateXLSX]);    

    return (
        <div id={styles.SaleForDevicePage} ref={pdfRef}>
            <ForDeviceFilter
                params={params}
                setParams={setParams}
                handleOpenSidesheet={handleOpenSidesheet}
                handleOnClickExport={() => setOpenExportSidesheet(true)}
                showExportButton={showExportButton}
                totalDevices={totalDevices}
            />
            {isLoading 
            ? (<div style={{ marginTop: "1rem" }}>
                {skeletons}
                </div>)
            : !!Object.keys(reportData).length && reportData.vendas && reportData.vendas.length && (
                <>
                    <div
                        className={styles.table}
                        style={{ overflowX: dimensions.width < 2075 && dimensions.width > 650 ? "scroll" : "hidden" }}
                    >
                        {onExport === "pdf" && (
                            <div className={`${styles.pdfTitle} div-to-capture`}>
                                <h2>{currentLocal?.name}</h2>
                                <h3>Relatório de venda por equipamento</h3>
                                <div className={styles.pdfCashier}>
                                    <h4>{params.sessaoId ? "Caixa" : "Período"}</h4>
                                    {params.sessaoId 
                                    ? (<span>{cashiers.find((it) => it.id === params.sessaoId)?.name ?? ""}</span>)
                                    :  (
                                        <div className={styles.cashier} >
                                            {cashiersByPeriod?.length ? cashiersByPeriod.map((cashier) => (<span key={cashier.id}>{cashier.name}</span>)) : <div />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {dimensions.width > 650 && <ForDeviceHeader params={params} setParams={setParams} />}
                        {!!reportData.vendas && reportData.vendas.map((venda, index) => (
                            <>
                                {dimensions.width > 650 
                                    ? <ForDeviceItem item={venda} key={venda.sessaoId} />
                                    : <ForDeviceItemMobile item={venda} key={venda.sessaoId} />
                                }
                                {index !== reportData.vendas.length - 1 && <Divider className={`${styles.divider} div-to-capture`} style={{ margin: "1rem 0 1.625rem" }} />}
                            </>
                        ))}
                        <ForDeviceTotalItem item={reportData} onExport={onExport} />
                    </div>
                  <ObservationDevice/>
                </>
            )}
            {!isLoading && !!Object.keys(reportData).length && !reportData.total && !reportData.vendas && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    <img src="/assets/img/empty-box.png" alt="" />
                    <span><b>Não há dados</b> para exibição.</span>
                </div>
            )}

            {openSidesheet && 
                <Sidesheet
                    open={openSidesheet}
                    onClose={() => setOpenSidesheet(false)}
                    title={
                        <h2>
                            Caixas do <b>período</b>
                        </h2>
                    }
                    content={<CashiersSidesheet cashiersByPeriod={cashiersByPeriod} isLoading={isLoading} />}
                    continueButton="Fechar"
                    handleContinueButton={() => setOpenSidesheet(false)}
                    currentStep={1}
                    totalSteps={1}
                    notTotalHeight
                />
            }

            {openExportSidesheet && 
                <Sidesheet
                    open={openExportSidesheet}
                    onClose={() => {
                        setOpenExportSidesheet(false);
                        setExportSidesheetStep(1);
                    }}
                    title={<h2>Exportar</h2>}
                    content={
                        <div className={styles.exporSidesheet}>
                            <span>Selecione abaixo uma opção para exportar o relatório</span>
                            <div>
                                <Radio
                                size="medium"
                                sx={{
                                    color: "#32008E",
                                    "&.Mui-checked": {
                                    color: "#32008E",
                                    },
                                }}
                                checked={exportType === "xlsx"}
                                onChange={(_, checked) => setExportType("xlsx")}
                                id="export-xlsx"
                                />{" "}
                                <label htmlFor="export-xlsx">XLSX</label>
                            </div>
                            <div>
                                <Radio
                                size="medium"
                                sx={{
                                    color: "#32008E",
                                    "&.Mui-checked": {
                                    color: "#32008E",
                                    },
                                }}
                                checked={exportType === "pdf"}
                                onChange={(_, checked) => setExportType("pdf")}
                                id="export-pdf"
                                />{" "}
                                <label htmlFor="export-pdf">PDF</label>
                            </div>
                        </div>
                    }
                    continueButton="Exportar"
                    handleContinueButton={handleOnClickExport}
                    cancelButton
                    currentStep={exportSidesheetStep}
                    totalSteps={2}
                    notTotalHeight
                    feedback={
                        <SidesheetFeedback
                          text="Relatório exportado com sucesso!"
                          success
                        />
                      }
                />
            }
        </div>
    )
}

export default SaleForDevicePage;