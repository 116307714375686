import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import ProductsPage from 'modules/products/presentation/ProductPage'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TabPage from '../dashboard/tabPage/TabPage'

export interface IProductPageProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    value?: string,
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        role: string;
    }[]
}

const ProductPage: FC<IProductPageProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const currentTab = menuItems.find(item => tab === item.tab);
        const routers = [{
            label: "Produtos",
            title: currentTab?.title
        }];

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.title : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.title
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters]);

    const menuItems:IMenuItem[] = [
        {
            title: "Produto",
            tab: "lista",
            role: "",
            component: <ProductsPage />,
        },
        {
            title: "Categorias",
            tab: "categorias",
            value: "/produtos/categorias",
            role: "CadastroCategoriaCliente"
        },
        {
            title: "Agendamento",
            tab: "Agendamento",
            value: "/produtos/agendamento",
            role: "AgendamentoProdutos"
        },
        {
            title: "Lista",
            tab: "Lista",
            value: "",
            subItems: [
                {
                    label: "Listas",
                    tab: "Listas",
                    url: "/produtos/lista/listas",
                    role: "CadastrarListaProdutoPortal"
                },
                {
                    label: "Vincular à equipamento",
                    tab: "Vincular à equipamento",
                    url: "/produtos/lista/vincularAEquipamento",
                    role: "VinculoListaProduto"
                }
            ]
        },
        {
            title: "Limitar Acesso",
            tab: "Limitar Acesso",
            value: "/produtos/limitarAcesso",
            role: "LimitarAcessoCategoriaProduto"
        },
        {
            title: "Produtos ignorados",
            tab: "Produtos ignorados",
            value: "/produtos/skippedproducts",
            role: "VinculoListaProduto"
        }
    ];

    const subTabComponent = useCallback((tabItem: IMenuItem) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/products/" + tabItem.tab}
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.role,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        /> : tabItem.component || <IframePage src={tabItem.value!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/products'}
            tabsPages={menuItems.map((tabItem) => (
                {
                    label: tabItem.title,
                    tab: tabItem.tab,
                    roles: tabItem.role,
                    component: subTabComponent(tabItem)
                }
            ))} />
    );
}
export default ProductPage;
