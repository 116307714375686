import React, { useEffect } from "react";
import { TextareaAutosize } from "@material-ui/core";
import Button from "components/ui/Button/Button";
import { IAddGuestForm } from "modules/events/domain/models/IEvents";
import style from "./AddGuestsForm.module.scss";
import { UseAddGuestForm } from "./UseAddGuestForm";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { useParams, useRouteMatch } from "react-router-dom";

interface IAddGuestsFormProps {
  handleSubmit: (guestList: IAddGuestForm) => void;
  listName: string | undefined;
  eventName: string | undefined;
  eventId: string | undefined;
  listCpf: string[];
  setListCpf: any;
}

export const AddGuestsForm: React.FC<IAddGuestsFormProps> = ({
  handleSubmit,
  listName,
  setListCpf,
  eventName,
  eventId
}) => {

  const {handleChange, handleValidate, isValid, submitHandle, inputList} =
    UseAddGuestForm(handleSubmit);
  const { updateRouters } = useBreadcumbs()
  const { listId } = useParams<{ listId: string }>();


  useEffect(()=> {
    setListCpf(inputList.split("\n"));
  }, [inputList]);

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Eventos', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.LISTA_CONVIDADOS, title: eventName, label: "Lista de Convidados", url: `/private/event/eventos/listas/${eventId}` },
      { id: BreadcrumbsEnum.CONVIDADOS, title: listName ?? "Convidados", label: listName ? "Convidados" : '', url: `/private/event/eventos/listas/convidados/${listId}` },
      { id: BreadcrumbsEnum.ADD_CONVIDADOS, title: "Adicionar convidados", url: `/private/event/eventos/listas/convidados/lista/adicionar/${listId}` },
    ])
  }, [eventId, eventName, listId, listName, updateRouters])

  return (
    <form onSubmit={submitHandle} id={style.AddGuestsForm}>
      <h2>Adicionar Convidados à lista: {listName}</h2>

      <TextareaAutosize
        value={inputList}
        required
        minRows={5}
        style={{}}
        onChange={handleChange}
        placeholder="Lista de CPF's"
      />

      <div>
        <Button onClick={() => handleValidate()}>Validar</Button>
        <Button type="submit" disabled={!isValid}>
          Confirmar
        </Button>
      </div>
    </form>
  );
};
