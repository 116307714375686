import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import AddDeviceProfileUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/AddDeviceProfileUseCase'
import GetDeviceProfileByIdUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/_GetDeviceProfileByIdUseCase'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi'
import { IDeviceProfileValue } from '../../components/deviceProfile/profileForms/profileForm/IDeviceProfileValue'
import { FormDeviceProfileStep } from "../../../domain/interface/FormDeviceProfileSteps"
import EditDeviceProfileUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceProfileInfo/EditDeviceProfileUseCase'
import { IDeviceProfile } from '../../interfaces/IDeviceProfile'
import { IFilterProfileDevice } from '../../components/deviceProfile/filterProfile/IFilterDeviceProfile'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

const UseDeviceProfile = (onChangeFilterHandle: (filter: IFilterProfileDevice) => void, path: string = "/private/pdv/perfil") => {
    const { showLoading, hideLoading } = useUi();
    const { push } = useHistory();
    const { currentLocal } = useLocal();
    const [deviceProfileDefault, setdefaulDeviceProfile] = useState<IDeviceProfile>()
    const [localId, setLocalId] = useState<string>();

    useEffect(() => {
      if(currentLocal){
        setLocalId(currentLocal.id)
      }
    }, [currentLocal])


    const addNewProfile = useCallback(async (profileId: string, values: IDeviceProfileValue) => {
        try {
            showLoading()
            const serviceDeviceProfile = DeviceProfileApi();
            if (localId) {
                const response = await AddDeviceProfileUseCase(profileId, localId, serviceDeviceProfile, values);
                push(`${path}/editar/${response.id}/` + FormDeviceProfileStep.SYSTEM);
                onChangeFilterHandle({});
            } else {
                console.error("Local não selecionado");
            }
        } finally {
            hideLoading();
        }
    }, [showLoading, localId, push, path, onChangeFilterHandle, hideLoading]);

    const editNewProfile = useCallback(async (profileId: string, values: IDeviceProfileValue) => {
        try {
            if (localId) {
                showLoading()
                const serviceDeviceProfile = DeviceProfileApi();
                await EditDeviceProfileUseCase(serviceDeviceProfile, localId, profileId, values);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.SYSTEM);
                onChangeFilterHandle({});
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [localId, showLoading, push, path, onChangeFilterHandle, hideLoading]);

    const getDefaultValue = useCallback(async (profileId: string) => {
        try {
            if (localId && profileId) {
                showLoading()
                const serviceDeviceProfile = DeviceProfileApi();
                const response = await GetDeviceProfileByIdUseCase(serviceDeviceProfile, localId, profileId);
                setdefaulDeviceProfile(response);
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [localId, hideLoading, showLoading])

    return ({
        addNewProfile,
        editNewProfile,
        getDefaultValue,
        deviceProfileDefault
    })
}

export default UseDeviceProfile