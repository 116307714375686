import { z } from "zod";

export const registerSchema = z.object({
  email: z.string()
    .min(1, { message: "Campo obrigatório!" })
    .email("E-mail inválido"),
  password: z.string()
    .min(6, { message: "A senha deve conter ao menos 6 caracteres." }),
  confirmPassword: z.string()
    .min(1, { message: "A confirmação de senha é obrigatória!" }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "As senhas não conferem.",
  path: ["confirmPassword"],
});

export type IRegisterSchema = z.infer<typeof registerSchema>;
