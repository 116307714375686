import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { EnumExportType } from "interfaces/EnumExport";
import GetHouseFlowDetailsItemUseCase from "modules/houseFlow/application/useCases/GetHouseFlowDetailsItemUseCase";
import PostHouseFlowExportUseCase from "modules/houseFlow/application/useCases/PostHouseFlowExportUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import HouseFlowApi from "services/api/houseFlow/HouseFlowApi";


export interface ExportParamsClientHouseFlow {

  localId?: string;
  cardId: string;
  exportFormat: EnumExportType;

}

const UseHouseFlowExport = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentLocal } = useLocal();
  const { toast } = useUi();



  const postExportHouseFlow = useCallback(
    async (params: ExportParamsClientHouseFlow) => {
      try {
        const serviceHouseFlow = HouseFlowApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await PostHouseFlowExportUseCase(serviceHouseFlow,
            currentLocal?.id,
            params.cardId,
            params.exportFormat ?? EnumExportType.EXCEL
          )
          toast("Em breve você irá receber o relatório no email", "success");
          return response
        }
      } catch {
        toast("Falha ao exportar.", "error");

      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, toast]
  );

  return {
    isLoading,
    postExportHouseFlow,
  };
};

export default UseHouseFlowExport;
