
import { IGetDiscountCouponsReportFilterRequest } from "../../domain/dto/IGetDiscountCouponsReportFilterRequest";
import { IDiscountCouponsReportFilter } from "../../presentation/components/discountCouponFilter/interfaces/IDiscountCouponReportFilter";
const ParseReportFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IDiscountCouponsReportFilter) => {

  const request: IGetDiscountCouponsReportFilterRequest = {

    ownerId: localId,
    cashierId: filter?.cashierId,
    createdStartDate: filter?.createdStartDate,
    createdEndDate: filter?.createdEndDate,
    customerName: filter?.customerName,
    customerCPF: filter?.customerCPF,
    code: filter?.code,
    orderId: filter?.orderId,
    minValue: filter?.minValue,
    maxValue: filter?.maxValue,
    deviceId: filter?.deviceId,
    sort: filter?.sort,
    page: pagination?.page,
    pageSize: pagination?.pageSize

  };

  return request;
};

export default ParseReportFilterToRequestUseCase

