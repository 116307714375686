import styles from './GeneralDashboardPage.module.scss'
import { MyAccount } from '../components/myAccount/MyAccount'
import UseMyAccount from '../hooks/UseMyAccount';
import { useHistory } from 'react-router-dom';
import { AllMovements } from '../components/allMovements/AllMovements';
import TransactionChart from '../components/transactionChart/TransactionChart';
import PosPaidPieChart from '../components/posPaidGeneral/PosPaidPieChart';
import Cashless from '../components/cashlessGeneral/Cashless';
import PreRecharge from '../components/preRecharge/PreRecharge';
import AverageTicket from '../components/averageTicket/AverageTicket';
import AvegareTicketCards from '../components/avegareTicketCard/AverageTicketCards';
import TurnoverTable from '../components/turnoverTable/TurnoverTable';
import { UseGeneralDashboardPage } from './UseGeneralDashboardPage';
import DashTicketFilter from '../components/generalDashFilter/GeneralDashFilter';
import TicketGeneral from '../components/ticketGeneral/TicketGeneral';
import DashboardContainer from '../components/dashboardContainer/DashboardContainer';
import RankingCategories from '../components/rankingCategories/RankingCategories';
import GridDashboard from '../components/gridDashboard/GridDashboard';
import RankingProducts from '../components/rakingProducts/RankingProducts';
import RankingOperators from '../components/rankingOperators/RankingOperators';
import ModalProductsWithout from '../components/modalProductsWithoutSplit/ModalProductsWithoutSplit';
import ProductionMovements from '../components/productionMovements/ProductionMovements';
import RankingCustomers from '../components/rankingCustomers/RankingCustomers';

const GeneralDashboardPage = () => {
  const { 
    filter, 
    onChangeFilterHandle, 
    financialInformation,  
    filterType, 
    setFilterType, 
    cashierEvents, 
    getCashiersEvents, 
    allMovements,
    posPaidData,
    cashlessData,
    preRechargeData,
    avarageTicketData,
    ticketData,
    rankingProducts,
    rankingOperators,
    rankingCustomers,
    rankingCategories,
    turnoverData,
    isProductLoaded,
    productsWithoutSplit,
   } = UseGeneralDashboardPage();

  const history = useHistory();
  const myAccount = UseMyAccount(filter);

  const goMyAccount = () => {
    history.push('/private/contaDigital')
  }

  return (
    <div id={styles.GeneralDashboardPage}>
      {(productsWithoutSplit.listProductsWithoutSplit?.TotalRecords ?? 0) > 0 && <ModalProductsWithout list={productsWithoutSplit.listProductsWithoutSplit} />}
      <MyAccount {...myAccount} goMyAccount={goMyAccount} />
      <DashTicketFilter
        onSubmit={(value) => onChangeFilterHandle(value)}
        defaultValues={filter}
        filterType={filterType}
        setFilterType={setFilterType}
        getCashiersByPeriod={getCashiersEvents}
        cashiers={cashierEvents}
      />
      <AvegareTicketCards {...financialInformation} />
      <AllMovements {...allMovements} />      
      <TransactionChart transactions={allMovements.allMovements?.financialMovements}/>
      {
        (posPaidData.posPaidData && posPaidData.posPaidData?.totalCommands > 0) &&
        <PosPaidPieChart {...posPaidData} />
      }
      {
        (ticketData.ticketData && ticketData.ticketData.total > 0) &&
        <TicketGeneral {...ticketData} />
      }
      {
        (cashlessData.cashlessData && (cashlessData.cashlessData.totalRechargered > 0 || cashlessData.cashlessData?.totalCards > 0)) &&
        <Cashless {...cashlessData} />   
      }
      {
        (preRechargeData.preRechargeData && preRechargeData.preRechargeData?.total > 0) &&
        <PreRecharge {...preRechargeData} />   
      }
      {
        (avarageTicketData.averageTicketData && (avarageTicketData.averageTicketData.cahlessRecharge > 0 || avarageTicketData.averageTicketData.cashlessConsumption > 0 || avarageTicketData.averageTicketData.posPaidIndividual > 0 || avarageTicketData.averageTicketData.posPaidTable > 0 || avarageTicketData.averageTicketData.posPaidTicketZero > 0 || avarageTicketData.averageTicketData.ticketIdentifiedClient > 0 || avarageTicketData.averageTicketData.ticketPayment > 0) ) &&
        <AverageTicket {...avarageTicketData} />
      }
      {
       ( turnoverData.turnoverTableData && turnoverData.turnoverTableData?.tableToration?.length > 0) &&
       <TurnoverTable {...turnoverData} />
      }
      {((posPaidData.posPaidData?.totalClosedProduction ?? 0) > 0 ||
       (cashlessData.cashlessData?.totalRechargeProduction ?? 0) > 0 ||
       (ticketData.ticketData?.totalProduction ?? 0) > 0) &&
        <ProductionMovements 
          isLoading={false}
          posPaidData={posPaidData.posPaidData}
          cashlessData={cashlessData.cashlessData}
          ticketData={ticketData.ticketData}
        />   
      }
      {!financialInformation.isLoadingFinancialInfo && (
        <DashboardContainer
          minHeight={400}
          title={"Top Produtos"}
          span={6}
          hideIcons
        >
          <RankingProducts {...rankingProducts} />
        </DashboardContainer>
      )}
      <GridDashboard>
        {isProductLoaded && (
          <>
            {((rankingCategories.salesCategories?.topParentCategories.length ?? 0) > 0) && 
            <DashboardContainer
              minHeight={400}
              title={"Top categoria pai"}
              span={3}
              hideIcons
            >
              <RankingCategories categories={rankingCategories.salesCategories?.topParentCategories} {...rankingCategories} />
            </DashboardContainer>}
            <DashboardContainer
              minHeight={400}
              title={"Top categoria"}
              span={3}
              hideIcons
            >
              <RankingCategories categories={rankingCategories.salesCategories?.topCategories} {...rankingCategories} />
            </DashboardContainer>

            <DashboardContainer
              minHeight={400}
              title={"Top Operadores"}
              span={3}
              hideIcons
            >
              <RankingOperators {...rankingOperators} />
            </DashboardContainer>
          </>
        )}
        
        {!financialInformation.isLoadingFinancialInfo && <RankingCustomers {...rankingCustomers} />}
      </GridDashboard>
    </div>
  )
}

export default GeneralDashboardPage
