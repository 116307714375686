import { IFields, IPaymentType, IPostPaymentLinkRequest, PaymentTypeForm } from "modules/paymentLink/domain/dto/IPostPaymentLinkRequest";
import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";
import { PaymentTypeEnum } from "modules/paymentLink/presentation/interfaces/INewPaymentLink";
import { IPaymentLinkInfoForm } from "modules/paymentLink/presentation/interfaces/IPaymentLinkForms";
import { IPaymentLinkValuesForm } from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";

export const AddPaymentLinkUseCase = async (
  service: IPaymentLinkService,
  localId: any,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
) => {
  const result = await service.postPaymentLink(
    parsePostPaymentLinkPayload(localId, info, values)
  );
  return result;
};

const parsePostPaymentLinkPayload = (
  localId: string,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
): IPostPaymentLinkRequest => {

  let paymentTypes: IPaymentType[] = [];

  if (values.credit) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.CREDIT, mePayLinkPaymentFields: []})
  }

  if (values.ticket) {
    let ticketFields: IFields[] = [];

    if (values.showDocumentBoleto) {
      ticketFields.push({ type: PaymentTypeForm.DOCUMENT, isRequired: false });
    }
    if (values.showNameOrCompanyNameBoleto) {
      ticketFields.push({ type: PaymentTypeForm.NAME, isRequired: false });
    }
    if (values.showPhoneNumberBoleto) {
      ticketFields.push({ type: PaymentTypeForm.PHONE, isRequired: false });
    }
    if (values.showAddressBoleto) {
      ticketFields.push({ type: PaymentTypeForm.ADDRESS, isRequired: false });
    }
    ticketFields.push({ type: PaymentTypeForm.EMAIL, isRequired: true });

    paymentTypes.push({
      paymentTypeId: PaymentTypeEnum.TICKET,
      mePayLinkPaymentFields: ticketFields
    });
  }

  if (values.pix) {
    let pixFields: IFields[] = [];

    if (values.showPhoneNumberPix) {
      pixFields.push({ type: PaymentTypeForm.PHONE, isRequired: false });
    }
    if (values.showAddressPix) {
      pixFields.push({ type: PaymentTypeForm.ADDRESS, isRequired: false });
    }
    pixFields.push({ type: PaymentTypeForm.NAME, isRequired: true });
    pixFields.push({ type: PaymentTypeForm.DOCUMENT, isRequired: true });
    pixFields.push({ type: PaymentTypeForm.EMAIL, isRequired: true });

    paymentTypes.push({
      paymentTypeId: PaymentTypeEnum.PIX,
      mePayLinkPaymentFields: pixFields
    });
  }
  return {
    localId,
    name: info.name,
    expirationDate: info.expiration,
    description: info.description,
    successMessage: info.successMessage,
    image: info.img,
    productId: values.productId,
    price: Number(values.price.replace(/,/g, '.')),
    maximumInstallments: values.credit ? values.installments : undefined,
    interestPerInstallment: values.credit ? values.tax : undefined,
    paymentTypes,
    shouldChargeFee: values.shouldChargeFee,
    isSinglePayment: values.isSinglePayment,
    isRecurrenceLimit: values.isRecurrenceLimit,
    amountOfCharges: values.amountOfCharges,
    billingStartsInCurrentMonth: values.billingStartsInCurrentMonth,
  };
};


