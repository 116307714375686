import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "../FidelityForm.module.scss";
import { Button, TextField } from "@material-ui/core";
import PointTarget from "./PointTarget";
import UseDimension from "components/dimension/UseDimension";

export interface FidelityPointRules {
  type: number;
  target: number;
  value: number;
}

export interface IFidelityPointFormValue {
  points: number;
  rules: FidelityPointRules[];
}

export interface IFidelityPointFormProps {
  //propertys
  defaultValue?: IFidelityPointFormValue;
  onSubmit?: (value: IFidelityPointFormValue) => void;
  onClickBack?: () => void;
}
const FidelityPointForm: FC<IFidelityPointFormProps> = ({
  onSubmit,
  onClickBack,
  defaultValue,
}) => {
  const [values, setValues] = React.useState<IFidelityPointFormValue>({
    points: 0,
    rules: [
      {
        type: 1,
        target: 0,
        value: 0,
      } as FidelityPointRules,
      {
        type: 2,
        target: 1,
        value: 0,
      } as FidelityPointRules,
      {
        type: 3,
        target: 0,
        value: 0,
      } as FidelityPointRules,
    ],
  });

  const [errors, setErrors] = useState<{
    rules?: [
      {
        type: 1;
        target?: string;
        value?: string;
      },
      {
        type: 2;
        target?: string;
        value?: string;
      },
      {
        type: 3;
        target?: string;
        value?: string;
      }
    ];
  }>({
    rules: [
      {
        type: 1,
        target: "",
        value: "",
      },
      {
        type: 2,
        target: "",
        value: "",
      },
      {
        type: 3,
        target: "",
        value: "",
      },
    ],
  });

  const { dimensions } = UseDimension();

  // const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
  //     setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  // }, []);

  const changeRulesHandle = useCallback((value: FidelityPointRules) => {
    setValues((prev) => ({
      ...prev,
      rules: prev.rules.map((item) => {
        if (item.type === value.type) {
          return value;
        }
        return item;
      }),
    }));
  }, []);

  const validate = useCallback(() => {
    if (values.rules[0].value < 0 || values.rules[1].value < 0) {
      setErrors((prev) => ({
        ...prev,
        points: "Não é permitido pontuação negativa",
      }));
      return false;
    }

    setErrors({});
    return true;
  }, [values.rules]);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (validate()) {
        onSubmit?.(values);
      }
    },
    [onSubmit, validate, values]
  );

  useEffect(() => {
    if (defaultValue) {
      setValues((prev) => {
        const newValues = {
          points: defaultValue.points,
          rules: prev.rules.map((item) => ({
            type: item.type,
            value:
              defaultValue.rules.find((rule) => rule.type === item.type)
                ?.value ?? 0,
            target:
              defaultValue.rules.find((rule) => rule.type === item.type)
                ?.target ?? 0,
          })),
        };
        return newValues;
      });
    }
  }, [defaultValue]);

  return (
    <form onSubmit={submitHandle}>
      <div className={styles.formContainer}>
        <div className={styles.row} style={{ flexDirection: dimensions.width < 450 ? "column" : "row" }}>
          <img
            src="/assets/img/target.svg"
            alt=""
            className={styles.headerIcon}
            style={{ alignSelf: dimensions.width < 450 ? "center" : "" }}
          />
          <h4>
            Esses são os <strong>pontos</strong> que seu cliente deve conquistar
            para chegar no <strong>nível de fidelidade.</strong>
          </h4>
        </div>
        <PointTarget
          onChange={changeRulesHandle}
          values={values.rules[0]}
          targetLabel={"A cada valor consumido"}
          pointsLabel={"Ganha ponto(s)"}
          targetPlaceholder="Ex.: R$10,00"
          pointsPlaceholder="Ex.: 10"
          moneyMask
          type="tel"
        />
        <PointTarget
          onChange={changeRulesHandle}
          values={values.rules[1]}
          targetValueFixed={1}
          targetLabel={"A cada visita"}
          pointsLabel={"Ganha ponto(s)"}
          type="tel"
        />
        {/* <PointTarget
                    onChange={changeRulesHandle}
                    values={values.rules[2]}
                    targetLabel={'A cada aniversário do cliente'}
                    pointsLabel={'Ganha ponto(s)'}
                /> */}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttons}
          variant="outlined"
          color="secondary"
          onClick={onClickBack}
        >
          Voltar
        </Button>
        <Button
          className={styles.buttons}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Salvar
        </Button>
      </div>
    </form>
  );
};
export default FidelityPointForm;
