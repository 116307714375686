import { ICategoryListItem, ICategoryListItemResponse } from "modules/dashboard/domain/dto/categoriesList/ICategoriesList.type";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import IDashboardService from "../interfaces/IDashboardService";

const GetSaleByCategoryIdUseCase = async (
  dashboardService: IDashboardService,
  filter: IFilterRequest,
  categoryId: string
): Promise<ICategoryListItemResponse> => {
  return await dashboardService.getSaleByCategoryId(filter, categoryId);
};

export default GetSaleByCategoryIdUseCase;