import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { IEventSelect } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet"

const GetEventsUseCase = async (service: ICatalogService, localId: string): Promise<IEventSelect[]> => {
    const response = await service.getEvents(localId);
    return (response.items || []).map(item => ({
        id: item.id,
        name: item.name
    }))
}
export default GetEventsUseCase