import styles from '../Trial/TrialPage.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import UseProspect from '../../hooks/UseProspect';
import { useEffect, useState } from 'react';
import SaasSuccessLayout from '../SaasSuccessLayout';
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum';
import { useQuery } from 'hooks/UseQuery';

export const BasicSuccessPage = () => {
    const history = useHistory();
    const { email } = UseProspect();
    const location = useLocation<{ type: string }>();
    const query = useQuery();

    const [type, setType] = useState(0);

    useEffect(() => {
        (query.get("type") || location.state?.type) && setType(Number(query.get("type") ?? location.state?.type));
    }, [location, query]);

    useEffect(() => {
        setTimeout(() => {
            history.push(`/login?email=${email}`);
        }, 25000);
    }, []);

    return (
        <SaasSuccessLayout
            progressBarColor={type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? "#00739E" : undefined}
            background={type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment
                ? "linear-gradient(180deg, #00AEEF 0%, #3CA0C6 100%)"
                : "linear-gradient(270deg, #EEF0FB 0%, #E1C7FF 100%)"
            }
            logo={type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? "/assets/img/logo-mee-white.svg" : undefined}
        >
            <div id={styles.BasicSuccessPage}>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <span
                                style={{
                                    fontWeight: 900,
                                    color: type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? "#FFF" : "#399C54",
                                    marginBottom: "8px"
                                }}
                            >
                                {type === ProspectType.Cielo ? "Seu estabelecimento foi criado com sucesso! 🎉" : "Seu pagamento foi realizado com sucesso! 🎉✅"}
                            </span>
                            <span style={{ color: type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? "#FFF" : "" }}>
                                Agora você está pronto para <b>aproveitar tudo</b> que a Mee tem a oferecer! 
                            </span>
                            <p style={{ color: type === ProspectType.Cielo || type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? "#FFF" : "" }}>
                                Aguarde, estamos redirecionado você para o Portal Mee 
                            </p>
                        </div>
                        <div className={styles.img}>
                            <img src="/assets/gif/intro-saas.gif" alt="" />
                        </div>
                    </div>

                    {/* <div className={styles.tag}>
                        <img className={styles.plusMask1} src="/assets/icon/saas/trial/plus-mask1.svg" alt="" />
                        
                        <div>
                            <span>Sabia que por mais <b>R$12,90 por mês</b> você adquire uma maquininha da Meep? <span>Aproveite e garanta a sua no Portal Meep!</span></span>
                        </div>
                        
                        <img className={styles.hand} src="/assets/icon/saas/hand.svg" alt="" />
                        <img className={styles.plusMask2} src="/assets/icon/saas/trial/plus-mask2.svg" alt="" />
                    </div> */}
                </div>
            </div>
        </SaasSuccessLayout>
    )
}