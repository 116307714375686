import React from 'react';
import styles from './StatusFilter.module.scss';
import { PrintingStatus } from 'modules/printQueue/presentation/interfaces/IPrintQueueList';
import { Button } from '@mui/material';

interface StatusFilterProps {
  selectedStatuses: number[];
  onStatusChange: (status: keyof typeof PrintingStatus) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ selectedStatuses, onStatusChange }) => {  
  const statuses = [
    { label: 'Todos', value: 'Received' },
    { label: 'Finalizada', value: 'Printed' },
    { label: 'Na fila', value: 'InQueue' },
    { label: 'Erro', value: 'Error' }
  ];

  return (
    <div id={styles.StatusFilter}>
      {statuses.map((status) => {
        const statusValue = PrintingStatus[status.value as keyof typeof PrintingStatus];
        const isSelected = selectedStatuses.includes(statusValue);

        return (
          <Button
            key={status.value}
            className={`${styles.statusButton} ${isSelected ? styles.selected : ''}`}
            onClick={() => onStatusChange(status.value as keyof typeof PrintingStatus)}
          >
            {status.label}
          </Button>
        );
      })}
    </div>
  );
};

export default StatusFilter;
