import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import { IPagSeguroParamsValues } from './IPagSeguroParamsValues';
import styles from './PagSeguroParamsForm.module.scss'
import { IconButton, InputAdornment } from '@material-ui/core';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import Input from 'components/ui/input/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export interface IPagSeguroParamsFormProps {
    defaultValue?: IPagSeguroParamsValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IPagSeguroParamsValues) => void
    onClickBack: (value: IPagSeguroParamsValues) => void
}

const PagSeguroParamsForm: React.FC<IPagSeguroParamsFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IPagSeguroParamsValues>(defaultValue ?? {} as IPagSeguroParamsValues);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show),[]);

    const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },[]);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeHandle = (property: string, value: any) => {
        setValues((prev) => ({ ...prev, [property]: value }));

        onModified(true);
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    return (
        <form id={styles.PagSeguroParamsForm} onSubmit={submitHandle}>
            <span>
                Configuração de acesso ao PagSeguro
            </span>
            <div className={styles.container}>
                <FormItemContainer className={styles.formItemContainer} label={"Usuário PagSeguro"}>
                    <Input 
                        placeholder='Usuário PagSeguro'
                        variant={"outlined"} 
                        InputProps={{
                            autoComplete: "one-time-code"
                        }}
                        value={values?.pagSeguroEmail || ''} 
                        onChange={ev => onChangeHandle("pagSeguroEmail", ev.target.value)} 
                    />
                </FormItemContainer>
                <FormItemContainer className={styles.formItemContainer} label={"Senha PagSeguro"}>
                    <Input 
                        placeholder='Senha PagSeguro'
                        variant={"outlined"} 
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            autoComplete: "one-time-code",
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                        value={values?.pagSeguroPassword || ''} 
                        onChange={ev => onChangeHandle("pagSeguroPassword", ev.target.value)} 
                    />
                </FormItemContainer>
                <FormItemContainer className={styles.formItemContainer} label={"Token"}>
                    <Input 
                        placeholder='Token'
                        variant={"outlined"} 
                        value={values?.pagSeguroToken || ''} 
                        onChange={ev => onChangeHandle("pagSeguroToken", ev.target.value)} 
                    />
                </FormItemContainer>

            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default PagSeguroParamsForm