import { IPromotersFormErrors } from "./IGuestListFormErros";

export const validate = (formData: IPromotersFormErrors, setErrors: React.Dispatch<React.SetStateAction<IPromotersFormErrors>>) => {
  let _errors: IPromotersFormErrors = {};
  let hasError = false;

  if (!formData.mainPromoter) {
    _errors = { ..._errors, mainPromoter: "Insira o promoter responsável pela lista" };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};