import SubItemsProductCategory, { IProductRankingCategoryData } from "modules/salesDashboard/presentation/components/categoryRanking/subItemProductCategory/SubItemsProductsCategory"
import { IFilterValue } from "modules/salesDashboard/presentation/components/filter/IFilter";
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService"
import { makeFilterRequest } from "./MakeFilterRequest";


const GetRankingProductsCategoryUseCase = async (service: ISalesDashboardService, categoryId: string, filter: IFilterValue): Promise<IProductRankingCategoryData> => {
    const response = service.getProductsRankingCategory(categoryId, makeFilterRequest({ filter }));
    return (response)
}
export default GetRankingProductsCategoryUseCase