import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Drawer, IconButton } from "@material-ui/core";
import styles from "./DiscountCouponItem.module.scss";
import {
  DeleteOutline,
  Download,
  EditOutlined,
  VerticalAlignBottom,
} from "@mui/icons-material";
import {
  DiscountCouponsColunmsPropertyType,
  IDiscountCouponsColumnsConfig,
} from "../../interfaces/IColumnsConfig";
import {
  ICupomItem,
  IDiscountCouponsList,
} from "modules/config/discountCoupons/presentation/interfaces/IDiscountCouponsList";
import Utils from "services/utils/Utils";
import { moneyMaskNumber } from "services/utils/Money";
import { EditCoupon } from "../../../editCoupon/EditCoupon";
import UseDiscountCouponsItem from "modules/config/discountCoupons/presentation/hooks/UseDiscountCouponsItem";
import { IDiscountCouponItem } from "modules/config/discountCoupons/presentation/interfaces/IDiscountCouponItem";
import { IParamsClientDiscountCouponsList } from "modules/config/discountCoupons/presentation/hooks/UseDiscountCouponsList";
import { IGetDiscountCouponsResponse } from "modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import warningImg from "../../../../../../../../res/assets/images/warning.png";
import { Button, CircularProgress } from "@mui/material";
import UseDeleteCoupon from "modules/config/discountCoupons/presentation/hooks/UseDeleteCoupon";
import { DownloadCoupon } from "modules/config/discountCoupons/presentation/components/downloadCoupon/DownloadCoupon";
import UseDownloadCouponItem from "modules/config/discountCoupons/presentation/hooks/UseDownloadCouponItem";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

interface Props {
  columns: IDiscountCouponsColumnsConfig[];
  userItemData: ICupomItem;
  discountCouponsList: IDiscountCouponsList;
  refrash: (
    params?: IParamsClientDiscountCouponsList | undefined
  ) => Promise<IGetDiscountCouponsResponse | undefined>;
  params: IParamsClientDiscountCouponsList;
}

const DiscountCouponItem: React.FC<Props> = ({
  userItemData,
  columns,
  discountCouponsList,
  refrash,
  params,
}) => {
  const { getDiscountCouponItem, discountCouponsItem, isLoadingEdit } =
    UseDiscountCouponsItem();
  const { getDownloadCouponItem, downloadCouponItem, isLoading } =
    UseDownloadCouponItem();

  const [openSideSheet, setOpenSideSheet] = useState<boolean>(false);
  const [openSideSheetDelete, setOpenSideSheetDelete] =
    useState<boolean>(false);
  const [openSideSheetDownloadCoupon, setOpenSideSheetDownloadCoupon] =
    useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<IDiscountCouponItem>();

  const { deleteCoupon } = UseDeleteCoupon();
  const { hasAccessRole } = useAuth();

  const openDrown = useCallback(
    (couponId: string) => {
      setOpenSideSheet(true);
      getDiscountCouponItem(couponId);
    },
    [getDiscountCouponItem]
  );

  const closeDrown = useCallback(() => {
    setOpenSideSheet(false);
  }, []);

  const openDownloadCoupon = useCallback(
    (couponId: string) => {
      getDownloadCouponItem(couponId);
      setOpenSideSheetDownloadCoupon(true);
    },
    [getDownloadCouponItem]
  );

  const closeDownloadCoupon = useCallback(() => {
    setOpenSideSheetDownloadCoupon(false);
  }, []);

  const openDrownDelete = useCallback(() => {
    setOpenSideSheetDelete(true);
  }, []);

  const closeDrownDelete = useCallback(() => {
    setOpenSideSheetDelete(false);
  }, []);

  const onDeleteCouponItem = useCallback(
    async (couponId: string) => {
      try {
        await deleteCoupon(couponId);
        refrash({
          pagination: {
            page: params.pagination?.page,
            pageSize: params.pagination?.pageSize,
          },
          sort: {
            orientation: "asc",
            type: "code",
          },
          filter: {
            code: params?.filter?.code,
            valueType: params?.filter?.valueType,
            createdStartdAt: params?.filter?.createdStartdAt,
            createdEndAt: params?.filter?.createdEndAt,
            valueMin: params?.filter?.valueMin,
            valueMax: params?.filter?.valueMax,
            startAt: params?.filter?.startAt,
            finishAt: params?.filter?.finishAt,
          },
        });
      } finally {
        setOpenSideSheetDelete(false);
      }
    },
    [
      deleteCoupon,
      params.pagination?.page,
      params.pagination?.pageSize,
      refrash,
    ]
  );

  useEffect(() => {
    if (discountCouponsItem) {
      setSelectedItem(discountCouponsItem);
    }
  }, [discountCouponsItem]);

  const formatValues = useCallback(
    (property: DiscountCouponsColunmsPropertyType): ReactNode => {
      if (userItemData.hasOwnProperty("couponId")) {
        switch (property) {
          //formatar status

          case "value":
            if (userItemData.valueType === 1) {
              return `${userItemData?.value}%`;
            } else {
              return moneyMaskNumber(userItemData?.value);
            }

          case "createdAt":
            return userItemData?.createdAt
              ? Utils.toDateAndTime(userItemData?.createdAt)
              : "-";
          case "finishAt":
            return userItemData?.finishAt
              ? Utils.toDateAndTime(userItemData?.finishAt)
              : "-";

          default:
            return userItemData?.[property] ?? "-";
        }
      }
    },
    [userItemData]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span>{formatValues(colunm.property)}</span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  return (
    <>
      <div id={styles.DiscountCouponItem}>
        <div className={styles.container}>
          {List}
          <div className={styles.icon}>
            {hasAccessRole("EditarCupomDesconto") && (
              <>
                <EditOutlined
                  onClick={() => openDrown(userItemData.couponId)}
                />
                <DeleteOutline onClick={() => openDrownDelete()} />
              </>
            )}
            <VerticalAlignBottom
              onClick={() => openDownloadCoupon(userItemData.couponId)}
            />
          </div>
        </div>
      </div>
      <Drawer open={openSideSheet} anchor="right" onClose={closeDrown}>
        <div id={styles.drawer}>
          <EditCoupon
            closeDrown={closeDrown}
            selectedItem={selectedItem}
            refrash={refrash}
            isLoadingEdit={isLoadingEdit}
          />
        </div>
      </Drawer>
      <Drawer
        open={openSideSheetDownloadCoupon}
        anchor="right"
        onClose={closeDownloadCoupon}
      >
        <div id={styles.drawer}>
          <DownloadCoupon
            item={downloadCouponItem}
            closeDownloadCoupon={closeDownloadCoupon}
            isLoading={isLoading}
          />
        </div>
      </Drawer>
      <RightDrawer
        title="Excluir "
        titleBold="cupom?"
        open={openSideSheetDelete}
        onClose={closeDrownDelete}
      >
        <div id={styles.deleteModal}>
          <div>
            <img src={warningImg} alt="warning_image" />
            <p>Deseja realmente excluir o cupom de desconto?</p>
          </div>
          <div>
            <Button
              fullWidth={false}
              onClick={closeDrownDelete}
              color="primary"
              variant="outlined"
              className={styles.outlined}
            >
              Voltar
            </Button>
            <Button
              fullWidth={false}
              onClick={() => onDeleteCouponItem(userItemData.couponId)}
              color="primary"
              variant="contained"
              className={styles.contained}
            >
              Excluir
            </Button>
          </div>
        </div>
      </RightDrawer>
    </>
  );
};

export default DiscountCouponItem;
