import { Dialog, Drawer } from '@material-ui/core'
import { AddCircle, Close } from '@material-ui/icons'
import Button from 'components/ui/Button/Button'
import List from 'modules/customers/presentation/components/list/List'
import React, { useEffect } from 'react'
import { SmartPrinterForm } from '../components/smartPrinterIForm/SmartPrinterForm'
import styles from './SmartPrinterPage.module.scss'
import { UseSmartPrinterPage } from './UseSmartPrinterPage'

export const SmartPrinterPage = () => {
    const {
        list,
        onSubmit,
        selectedSmartPrinter,
        handleEditSmartPrinterModal: handleSmartPrinterModal,
        handleCloseSmartPrinterEditModal,
        productList,
        printerList,
        tableList,
        totemList,
        handleOpenAddSmartPrinterModal,
        handleCloseAddSmartPrinterModal,
        openAddSmartPrinter,
        deletePrinterSmart,
        deleteSmartPrinterOpenModal,
        deleteSmartPrintCloseModal,
        selectedDeleteSmartPrinter,
        getSmartPrinters,
        getProductList,
        getTableList,
        getTotemList,
        getPrinterList
    } = UseSmartPrinterPage();


    useEffect(() => {
        getSmartPrinters();
        getProductList();
        getTableList();
        getTotemList();
        getPrinterList();
    }, [getSmartPrinters, getProductList, getTableList, getTotemList, getPrinterList])

    const configColumns = {
        name: { text: "Setor" },
        printerName: { text: "Impressora" },
    }

    return (
        <>
            <div id={styles.SmartPrinterPage}>
                <div className={styles.container} >
                    <div className={styles.buttonAddNewPrinter}>
                        <Button fullWidth={false} onClick={() => handleOpenAddSmartPrinterModal()}>
                            Adicionar configuração<AddCircle />
                        </Button>
                    </div>
                    {
                        list && (
                            <List
                                list={list.list ?? []}
                                onClickSort={(property) => { }}
                                onClick={value => { }}
                                columnsConfigs={configColumns}
                                actionButtons={[
                                    { icon: "edit", onClick: (item) => handleSmartPrinterModal(item) },
                                    { icon: 'deleteOutlineOutlinedIcon', onClick: (item) => deleteSmartPrinterOpenModal(item) }
                                ]}
                            />
                        )
                    }
                </div>
                <div className={styles.drawerContainer}>
                    <Drawer className={styles.drawer} anchor='right' open={!!selectedSmartPrinter} onClose={handleCloseSmartPrinterEditModal}>
                        <SmartPrinterForm onSubmit={onSubmit} onClose={handleCloseSmartPrinterEditModal} defaultValues={selectedSmartPrinter} productList={productList} printerList={printerList} tableList={tableList} totemList={totemList} />
                    </Drawer>
                    <Drawer className={styles.drawer} anchor='right' open={openAddSmartPrinter} onClose={handleCloseAddSmartPrinterModal}>
                        <SmartPrinterForm onSubmit={onSubmit} onClose={handleCloseAddSmartPrinterModal} defaultValues={selectedSmartPrinter} productList={productList} printerList={printerList} tableList={tableList} totemList={totemList} />
                    </Drawer>
                </div>
            </div>
            <Dialog fullWidth open={!!selectedDeleteSmartPrinter} onClose={deleteSmartPrintCloseModal}>
                <div className={styles.containerModalDelete}>
                    <div className={styles.containerTitle}>
                        <h1>Deseja excluir configuração?</h1>
                        <Close onClick={deleteSmartPrintCloseModal} />
                    </div>
                    <div>
                        <p>Está ação não poderar ser desfeita!</p>
                    </div>
                    <div className={styles.containerButton}>
                        <Button fullWidth={false} type="button" onClick={deletePrinterSmart}>{"Deletar"}</Button>
                        <Button fullWidth={false} variant="outlined" onClick={deleteSmartPrintCloseModal}>Voltar</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
