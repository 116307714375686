import { ISelect } from "interfaces/ISelect";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetDeviceListUseCase from "../../application/useCases/GetDeviceListUseCase";


const UseReportFilterDeviceList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentLocal } = useLocal();
  const [reportDeviceList, setReportDeviceList] = useState<ISelect[]>([]);


  const getReportDeviceList = useCallback(
    async () => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetDeviceListUseCase(serviceDiscountCoupons,
            currentLocal.id,
            [2, 3]
          );
          setReportDeviceList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    reportDeviceList,
    isLoading,
    getReportDeviceList,
    setReportDeviceList,
  };
};

export default UseReportFilterDeviceList;
