import React, { FC, useState } from "react";
import { ISavedFilter } from "../../interfaces/ISavedFilter";
import styles from "./SavedFilterList.module.scss";
import { Skeleton, Drawer } from "@mui/material";
import { Icon, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "components/ui/Button/Button";

const SavedFilterList: FC<{
  isLoading?: boolean;
  savedFilters: ISavedFilter[];
  onClickDeleteFilters: (value: ISavedFilter) => Promise<void>;
  onClickSavedFilter: (value: ISavedFilter) => void;
}> = ({
  savedFilters,
  onClickSavedFilter,
  isLoading,
  onClickDeleteFilters,
}) => {
  const [selectedFilterToDelete, setSelectedFilterToDelete] =
    useState<ISavedFilter>();

  const handleSubmit = async () => {
    await onClickDeleteFilters(selectedFilterToDelete!)
    setSelectedFilterToDelete(undefined)
  }

  return (
    <div id={styles.SavedFilterList}>
      <span className={styles.title}>Meus filtros favoritos:</span>
      <div className={styles.list}>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={95} height={75} />
            <Skeleton variant="text" width={95} height={75} />
            <Skeleton variant="text" width={95} height={75} />
          </>
        ) : 
          !!savedFilters.length ?
          savedFilters?.map((item) => (
            <>
              <Button
                variant="outlined"
                color="primary"
                className={styles.button}
                onClick={() => onClickSavedFilter(item)}
              >
                {item.name}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    setSelectedFilterToDelete(item);
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Button>
            </>
          )) : 
          <span className={styles.empty}>Você ainda não possui filtros favoritos</span>
        }

        <Drawer
          anchor="right"
          open={!!selectedFilterToDelete}
          onClose={() => setSelectedFilterToDelete(undefined)}
        >
          <form id={styles.drawerSaveFilter}>
            <div className={styles.headerDrawer}>
              <h1 className={styles.tileDeleteFilter}>
                Remover <strong>filtro favorito</strong>
              </h1>
              <IconButton onClick={() => setSelectedFilterToDelete(undefined)}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div>
              <h2 className={styles.confirmDeleteFilter}>
                Deseja realmente remover o filtro "
                {selectedFilterToDelete?.name}"?
              </h2>
            </div>
            <div className={styles.confirmDelete}>
              <img
                src="/assets/img/alert.png"
                alt="alert"
                className={styles.contentImgCancel}
              />
              <p>
                Lembre-se: essa ação não poderá ser
                <br />
                desfeita.
              </p>
            </div>
            <div className={styles.divButtons}>
              <Button variant="outlined" onClick={() => setSelectedFilterToDelete(undefined)} className={styles.buttonBack}>
                Voltar
              </Button>
              <Button
                disabled={isLoading}
                variant="contained"
                className={styles.deleteButton}
                onClick={() => {
                  selectedFilterToDelete && handleSubmit();
                }}
              >
                {!isLoading ? 'Remover' : 
                  <img
                    src="/assets/img/loader.gif"
                    alt="loader"
                    className={styles.loader}
                  />
                }
              </Button>
            </div>
          </form>
        </Drawer>
      </div>
    </div>
  );
};

export default SavedFilterList;
