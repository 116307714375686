import { ILinkCardsApiService } from "modules/linkCards/domain/interfaces/ILinkCardsApiService";
import { api } from "../Api";
import { ISort } from "interfaces/ISort";
import { IGetLinkCardsParams } from "modules/linkCards/domain/dto/IGetLinkCardsParams";
import { IGetLinkCardsListResponse } from "modules/linkCards/domain/dto/IGetLinkCardsListResponse";
import { IDeleteLinkCardsResponse } from "modules/linkCards/domain/dto/IDeleteLinkCardsResponse";
import { IPostAddLinkCardsResponse } from "modules/linkCards/domain/dto/IPostAddLinkCardsResponse";

const LinkCardsApi = (): ILinkCardsApiService => {
  const getLinkCards = async (params?: IGetLinkCardsParams, sort?: ISort): Promise<IGetLinkCardsListResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }

    const response = await api.get("/cards/autoactivation", { params: data });
    return response.data;
  };

  const deleteLinkCardsItem = async (request: { localId: string; tags: string; }): Promise<IDeleteLinkCardsResponse> => {
  
    const response = await api.delete(`cards/autoactivation`, {
      data: request
    });
  
    return response.data;
  }
  const addLinkCardsItem = async (request: { localId: string; tags: string; }): Promise<IPostAddLinkCardsResponse> => {
  
    const response = await api.post(`cards/autoactivation`, request);   
  
    return response.data;
  }

  return { getLinkCards, deleteLinkCardsItem, addLinkCardsItem };
}

export default LinkCardsApi;
