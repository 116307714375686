import { IDiscountListResponse, IGetListDiscountsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const GetBaseLIstUseCase = async (
  baseListService: IDiscountBaseList,
  localId: string,
): Promise<IDiscountListResponse[]> => {
  return await baseListService.getBaseList(localId);
};

export default GetBaseLIstUseCase;
