import { v4 } from "uuid"
import { ICustomersService } from "../interfaces/ICustomersService"

const GetEventsListUseCase = async (customerService: ICustomersService, locals: { name: string, id: string }[]): Promise<{ name: string, id: string }[]> => {

    return eventMock
}
export default GetEventsListUseCase

const eventMock = [
    { id: v4(), name: "Baladinha" },
    { id: v4(), name: "Festa" },
    { id: v4(), name: "happy hour" },
    { id: v4(), name: "Ano Novoa" },
]

