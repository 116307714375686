import { IFinancialData } from "modules/cashlessDashboard/presentation/components/financialData/interfaces/IFinancialData";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetFinancialDataUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<IFinancialData> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getFinancialData(filterRequest);

  return response;
}

export default GetFinancialDataUseCase
