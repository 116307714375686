import { IPlaceScheduleApi } from "modules/schedule/application/interfaces/api/IPlaceScheduleApi"
import { IAddPlaceScheduleRequest } from "modules/schedule/domain/dto/placeSchedule/IAddPlaceScheduleRequest";
import { IAddPlaceScheduleResponse } from "modules/schedule/domain/dto/placeSchedule/IAddPlaceScheduleResponse";
import { ISpecialDaysRequest } from "modules/schedule/domain/dto/placeSchedule/IAddUnavailabilityRequest";
import { IEditPlaceScheduleRequest } from "modules/schedule/domain/dto/placeSchedule/IEditPlaceScheduleRequest";
import { IEditSpecialDaysRequest } from "modules/schedule/domain/dto/placeSchedule/IEditUnavailabilityRequest";
import { IEnabledPlaceScheduleRequest } from "modules/schedule/domain/dto/placeSchedule/IEnabledPlaceScheduleRequest";
import { IGetAllDemandedServicesByPlaceIdResponse } from "modules/schedule/domain/dto/placeSchedule/IGetAllDemandedServicesByPlaceIdResponse";
import { IGetOrderPadsByLocalIdResponse } from "modules/schedule/domain/dto/placeSchedule/IGetOrderPadsByLocalIdResponse";
import { IGetPlaceScheduleByIdResponse } from "modules/schedule/domain/dto/placeSchedule/IGetPlaceScheduleByIdResponse";
import { IGetPlaceScheduleListResponseByLocalIdDTO } from "modules/schedule/domain/dto/placeSchedule/IGetPlaceScheduleListResponseByLocalIdDTO";
import { IGetServiceScheduleByLocalIdResponseDTO } from "modules/schedule/domain/dto/placeSchedule/IGetServiceScheduleByLocalIdResponse";
import { IGetSpecialDaysResponse } from "modules/schedule/domain/dto/placeSchedule/IGetUnavailabilityResponse";
import { api } from "../../Api"

const PlacesScheduleApi = (): IPlaceScheduleApi => {

    const getOrderPadsIdsByLocalId = async (localId: string): Promise<IGetOrderPadsByLocalIdResponse> => {
        const response = await api.get<IGetOrderPadsByLocalIdResponse>(`/PlaceSchedule/GetOrderPads/${localId}`);
        return response.data;
    }

    const getServicesScheduleIdsByLocalId = async (localId: string): Promise<IGetServiceScheduleByLocalIdResponseDTO> => {
        const response = await api.get<IGetServiceScheduleByLocalIdResponseDTO>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: true } });
        return response.data;
    }

    const getPlaceScheduleListIdsByLocalId = async (localId: string): Promise<IGetPlaceScheduleListResponseByLocalIdDTO> => {
        const response = await api.get<IGetPlaceScheduleListResponseByLocalIdDTO>(`/PlaceSchedule/GetPlacesScheduleByFilter/${localId}`);
        return response.data;
    }

    const getPlaceScheduleById = async (placeId: string): Promise<IGetPlaceScheduleByIdResponse> => {
        const response = await api.get<IGetPlaceScheduleByIdResponse>(`/PlaceSchedule/ById/${placeId}`);
        return response.data;
    }

    const getUnavailabilityByServiceId = async (serviceId: string): Promise<IGetSpecialDaysResponse[]> => {
        const response = await api.get<IGetSpecialDaysResponse[]>(`/Schedule/SpecialDay/${serviceId}`);
        return response.data;
    }

    const addUnavailability = async (request: ISpecialDaysRequest, placeId: string): Promise<void> => {
        const response = await api.post<ISpecialDaysRequest>(`/Schedule/${placeId}/SpecialDay`, request);
        return;
    }

    const deleteUnavailability = async (id: string) => {
        await api.delete<ISpecialDaysRequest>(`/Schedule/SpecialDay/${id}`);
        return null;
    }

    const addPlace = async (request: IAddPlaceScheduleRequest): Promise<IAddPlaceScheduleResponse> => {
        const response = await api.post<IAddPlaceScheduleResponse>(`/PlaceSchedule/AddPlaces`, request);
        return response.data;
    }

    const editPlace = async (request: IEditPlaceScheduleRequest): Promise<void> => {
        const response = await api.put<IEditPlaceScheduleRequest>(`/PlaceSchedule/Update`, { ...request, minimumLeadTimeInDays: undefined });
        return;
    }

    const editUnavailability = async (request: IEditSpecialDaysRequest, placeId: string): Promise<void> => {
        const response = await api.put<IEditSpecialDaysRequest>(`/Schedule/SpecialDay/${placeId}`, request);
        return;
    }

    const enabledPlace = async (request: IEnabledPlaceScheduleRequest): Promise<void> => {
        const response = await api.post<IEnabledPlaceScheduleRequest>(`/PlaceSchedule/enable`, request);
        return;
    }

    const disablePlace = async (request: IEnabledPlaceScheduleRequest): Promise<void> => {
        const response = await api.post<IEnabledPlaceScheduleRequest>(`/PlaceSchedule/disable`, request);
        return;
    }

    const deletePlace = async (placeId: string): Promise<void> => {
        const response = await api.delete(`/Schedule/${placeId}`);
        return;
    }

    const getAllDemandedServices = async (initDate: string, finalDate: string, scheduleId: string): Promise<IGetAllDemandedServicesByPlaceIdResponse[]> => {
        const params = {
            initDate,
            finalDate,
            scheduleId
        }

        const response = await api.get<IGetAllDemandedServicesByPlaceIdResponse[]>(`/DemandSchedule/GetAllDemandedServices`, {
            params
        });

        return response.data;
    }

    return (

        {
            getOrderPadsIdsByLocalId,
            getServicesScheduleIdsByLocalId,
            addPlace,
            editPlace,
            getPlaceScheduleListIdsByLocalId,
            getPlaceScheduleById,
            getUnavailabilityByServiceId,
            addUnavailability,
            deleteUnavailability,
            editUnavailability,
            enabledPlace,
            disablePlace,
            deletePlace,
            getAllDemandedServices
        }
    )
}

export default PlacesScheduleApi
