import { IServiceProductCard } from "modules/schedule/presentation/components/serviceProductCard/interfaces/IServiceProductCard";
import { IServiceScheduleApiCB } from "../../interfaces/api/IServicesScheduleApi";
export const GetServicesListBylocalIdUseCase = async (localId: string, servicesScheduleApi: IServiceScheduleApiCB): Promise<IServiceProductCard[]> => {
    const _servicesScheduleApi = servicesScheduleApi();
    const response = await _servicesScheduleApi.getServicesListByLocalId(localId);

    return response.items.flatMap(item => (item.services)).map(item => ({
        id: item.id,
        duration: item.duration,
        durationType: item.durationType,
        name: item.name,
        serviceCategoryId: "",
        serviceCategoryName: item.categoryName,
        imageUrl: item.imageUrl,
        otherValues: item.prices.map(item => ({ nome: item.description, value: item.price, productListId: "", note: "" }))
    }))
    // return valuesMock//TODO: remover mock

}

// const valuesMock: IServiceProductCard[] = [
//     {
//         id: "001",
//         duration: 60,
//         image: "https://www.meridiano.com.br/blog/wp-content/uploads/2018/07/Meridiano-Blog-1170x772.jpg",
//         name: "Passeio no Forrózão",
//         durationType: 1,
//         otherValues: [
//             {
//                 //    id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 nome: "Adulto",
//                 productListId: "0da4f67f-ab1e-495c-ad9b-2fd640dda027",
//                 value: 24,
//                 note: ''
            
//             },
//             {
//                 //    id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 nome: "Criança",
//                 productListId: "0da4f67f-ab1e-495c-ad9b-2fd640dda023",
//                 value: 12,
//                 note: ''
//             },

//         ],
//         serviceCategoryName: "",
//         serviceCategoryId: "89ade86d-6650-4724-9b39-0c5211f2df9e",
//     },
//     {
//         id: "002",
//         duration: 60,
//         image: "https://www.casacadecouro.com.br/wp-content/uploads/2017/07/POVO-1.jpg",
//         name: "Passeio no Rock in Roll",
//         durationType: 1,
//         otherValues: [
//             {
//                 //id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 nome: "Aniversariante",
//                 productListId: "0da4f67f-ab1e-495c-ad9b-2fd640dda027",
//                 value: 5,
//                 note: ''

//             },
//             {
//                 //id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 nome: "Adultos",
//                 productListId: "0da4f67f-ab1e-495c-ad9b-2fd640dda027",
//                 value: 25,
//                 note: ''
//             },
//         ],
//         serviceCategoryName: "",
//         serviceCategoryId: "89ade86d-6650-4724-9b39-0c5211f2df9e",
//     },
//     {
//         id: "003",
//         duration: 60,
//         durationType: 0,
//         image: "http://ipiranganews.inf.br/wp-content/uploads/show-heliopolis-05-1113.jpg",
//         name: "Passeio no Funkão",
//         otherValues: [
//             {
//                 //id: "e249ed5e-9669-4598-88bb-7c1de4533b7f",
//                 nome: "Adultos",
//                 productListId: "0da4f67f-ab1e-495c-ad9b-2fd640dda027",
//                 value: 12,
//                 note: ''
//             },
//         ],
//         serviceCategoryName: "",
//         serviceCategoryId: "89ade86d-6650-4724-9b39-0c5211f2df9e",
//     },
// ]