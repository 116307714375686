import { z } from "zod";

export const simpleRegisterSchema = z.object({
  email: z.string()
    .min(1, { message: "Campo obrigatório!" })
    .email("E-mail inválido"),
  password: z.string()
    .min(6, { message: "A senha deve conter ao menos 6 caracteres." }),
  name: z.string().optional(),
  establishmentName: z.string()
    .min(1, { message: "Campo obrigatório!" }),
  establishmentDocument: z.string().optional(),
  segment: z.union([z.number(), z.undefined()]),
});

export type ISimpleRegisterSchema = z.infer<typeof simpleRegisterSchema>;
