import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
    type: 'error' | 'warning';
    customMessage?: string;
    empty?: boolean;
}

export const ErrorMessage = ({ type, customMessage, empty }: ErrorMessageProps) => {
    const defaultMessage = type === 'error' ?
        'Tivemos algum problema, recarrege a página e tente novamente!' :
        'Nenhuma informação encontrada!'

    return (
        <div id={styles.ErrorMessage}>
            {
                empty ?
                    <img src="/assets/img/empty-box.png" alt="" />
                    :
                    <img src={`/assets/icon/sales-dashboard-${type}.svg`} alt="" />
            }
            <span>{customMessage || defaultMessage}</span>
        </div>
    )
}