import { useCallback } from "react";
import { MenuItem, Select, TextField } from "@mui/material";
import styles from "./SolicitationsFilter.module.scss";
import { IFilterParams } from "../../interface/IFilterParams";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import Button from "components/ui/Button/Button";

export interface ISolicitationsFilterProps {
    filter: IFilterParams;
    setFilter: React.Dispatch<React.SetStateAction<IFilterParams>>;
    refetch: () => void;
}

const acquirerTypeOptions = [
    {
        title: "Stone",
        value: AcquirerTypeEnum.Stone,
    },
    {
        title: "PagSeguro",
        value: AcquirerTypeEnum.PagSeguro,
    },
    {
        title: "Getnet",
        value: AcquirerTypeEnum.GetNet,
    },
]

const SolicitationsFilter = ({ filter, setFilter, refetch }: ISolicitationsFilterProps) => {
    const clearFilters = useCallback(() => {
        setFilter((prev) => ({ ...prev, keyword: "", acquirerType: undefined }));
    }, [setFilter]);

    return (
        <div id={styles.SolicitationsFilter}>
            <div className={styles.input}>
                <label htmlFor="keyword" className={styles.required}>Buscar por estabelecimento</label>
                <TextField
                    name="keyword"
                    size="small"
                    value={filter.keyword}
                    placeholder="Digite o nome ou CNPJ"
                    onChange={(ev) => setFilter((prev) => ({...prev, keyword: ev.target.value}))}
                    fullWidth
                />
            </div>

            <div className={styles.input}>
                <label htmlFor="acquirerType">Filtrar por adquirente</label>
                <Select
                    name="acquirerType"
                    size="small"
                    value={filter.acquirerType ?? ""}
                    placeholder="Selecione a adquirente"
                    onChange={(ev) => setFilter((prev) => ({...prev, acquirerType: Number(ev.target.value)}))}
                    fullWidth
                >
                    {acquirerTypeOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                    ))}
                </Select>
            </div>

            <Button color="secondary" onClick={refetch} disabled={!filter.keyword && !!filter.acquirerType}>Buscar</Button>
            <Button variant="text" onClick={clearFilters}>Limpar</Button>
        </div>
    )
}

export default SolicitationsFilter;