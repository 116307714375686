import { Drawer, Icon, Skeleton } from "@mui/material";
import { differenceInYears } from "date-fns";
import { ICrmCustomerData } from "modules/crm/presentation/intefaces/CrmUserData";
import { EnumGender } from "modules/crm/presentation/intefaces/GenderEnum";
import { ICustomerEventsListData } from "modules/crm/presentation/intefaces/ICustomerEventData";
import { FC, useCallback, useEffect, useState } from "react";
import CPF from "services/utils/CPF";
import { ICrmColumnsConfig, ICrmCustomerEventsColumnsConfig } from "../interfaces/IColumnsConfig";
import styles from "./CrmAcorddion.module.scss";
import CrmCustomerEventsList from "./CrmCustomerEventsList";
import { FidelityHistory } from "./fidelityHistory/FidelityHistory";
import { FidelityEditSummary } from "./fidelityEditSummary/FidelityEditSummary";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFidelityPlans } from "modules/fidelity/presentation/interfaces/IFidelityPlans";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { WhatsApp } from "@material-ui/icons";

interface CrmAcorddionProp {
  userDetail: ICrmCustomerData;
  fidelityList: IFidelityPlans[];
  getCustomerEvents: (
    customerId: string,
    pagination: { page: number; pageSize: number }
  ) => Promise<ICustomerEventsListData>;
  columnsConfig: ICrmCustomerEventsColumnsConfig[];
  columns: ICrmColumnsConfig[]
}

const CrmAcorddion: FC<CrmAcorddionProp> = ({
  userDetail,
  getCustomerEvents,
  columnsConfig,
  fidelityList,
  columns
}) => {
  const [eventData, setEventData] = useState<ICustomerEventsListData>();
  const [isLoadingList, setIsLoading] = useState(false);
  const [modalHistoryOpened, setModalHistoryOpened] = useState(false);
  const [modalEditSummary, setModalEditSummary] = useState(false);
  const [customerFidelityPlan, setCustomerFidelityPlan] = useState(userDetail.customerFidelityPlan);
  const [fidelityPlanName, setFidelityPlanName] = useState('-');
  const [fidelityPlanIcon, setFidelityPlanIcon] = useState<string>();
  const { checkPermission } = usePlans();
  const [fidelityPlansOrdered, setFidelityPlansOrdered] = useState<IFidelityPlans[]>();

  const { currentLocal } = useLocal();

  useEffect(() => {
    setCustomerFidelityPlan(userDetail.customerFidelityPlan);
  }, [userDetail.customerFidelityPlan]);

  useEffect(() => {
    if (fidelityList.length && !fidelityPlansOrdered) {
      setFidelityPlansOrdered(JSON.parse(JSON.stringify(fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1))));
    }
  }, [fidelityList, fidelityPlansOrdered]);

  const getCustomerEventsHandle = useCallback(async (pagination: { page: number; pageSize: number }) => {
    try {
      if (checkPermission(FeatureTypeEnum.CRM)) {
        setIsLoading(true);
        const response = await getCustomerEvents?.(userDetail.id, pagination);
        setEventData(response);
      }
    } finally {
      setIsLoading(false);
    }
  },
    [checkPermission, getCustomerEvents, userDetail.id]
  );

  const handleOnFinishEditSummary = () => {
    setModalEditSummary(false);
    // setCustomerFidelityPlan(prev => ({
    //   ...prev,
    //   name,
    //   icon,
    //   points,
    //   coins
    // }))
  }

  const TableItem = (props: {
    label: string;
    value?: string | number | JSX.Element;
    isLoading?: boolean;
    darker?: boolean;
    stronger?: boolean;
    flex?: boolean;
    premium?: boolean;
  }) => {
    return props.isLoading ? (
      <Skeleton />
    ) : (
      <div style={props.flex ? { display: "flex", alignItems: "center" } : {}}>
        <label>{props.label}</label>
        <span
          style={{
            color: props.darker ? "#333" : "#5F5F5F",
            fontWeight: props.stronger ? "700" : "400",
            alignItems: "center",
            display: props.flex ? "flex" : "initial",
          }}
        >
          {props.premium && !checkPermission(FeatureTypeEnum.CRM) ?
            <div style={{ height: 20, width: 100, backgroundColor: '#F5F5F5', top: 6, left: 5, position: 'relative', display: 'inline-flex' }} /> :
            props.value
          }
        </span>
      </div>
    );
  };

  const Gender = (value?: EnumGender) => {
    function mountGenderChip(value?: EnumGender) {
      if (value === EnumGender.male) {
        return (
          <div className={`${styles.genreChip} ${styles.male}`}>Masculino</div>
        );
      } else if (value === EnumGender.female) {
        return (
          <div className={`${styles.genreChip} ${styles.female}`}>Feminino</div>
        );
      } else {
        return <div className={styles.genreChip}>Desconhecido</div>;
      }
    }

    return (
      <div>
        <label>Sexo: </label>
        {mountGenderChip(value)}
      </div>
    );
  };

  const mountAge = (birthDay: string) => {
    if (!birthDay) {
      return "-";
    }

    const age = differenceInYears(new Date(), new Date(birthDay));

    return age > 1 ? age + " anos" : age + " ano";
  };

  const mountEvents = (totalRegisters?: number) => {
    if (!totalRegisters) {
      return currentLocal?.systemIdentifier === 1 ? "Nenhuma visita" : "Nenhum evento";
    }
    if (currentLocal?.systemIdentifier === 1) {
      return totalRegisters + (totalRegisters > 1 ? " visitas" : " visita");
    }
    return totalRegisters + (totalRegisters > 1 ? " eventos" : " evento");
  };

  const formatCpf = (cpf: string) => {
    const regex = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/);
    if (!regex.test(cpf)) return cpf;

    let newCpf =
      cpf[0] + "**." + cpf.split(".")[1] + "." + cpf.split(".")[2][0] + "**-**";

    return newCpf;
  };

  useEffect(() => {
    let fidelityPlan: IFidelityPlans;
    fidelityPlansOrdered?.forEach((x: IFidelityPlans) => {
      const pointsRequired = x.pointsRequired || 0;
      if (customerFidelityPlan && customerFidelityPlan.points >= pointsRequired && !fidelityPlan) {
        fidelityPlan = x
        setFidelityPlanName(x.name)
        setFidelityPlanIcon(x.customization?.icon)
      }
    });

  }, [customerFidelityPlan, fidelityPlansOrdered]);

  return (
    <div className={styles.container}>
      <h2>Dados Pessoais</h2>
      <div className={styles.contentInfoAccordion}>
        <div className={styles.row}>
          <TableItem label="E-mail: " value={userDetail.email} />
          

          {
             !(columns.find(item => item.property === "phoneNumber")?.hide ?? false) &&
             <TableItem label="Telefone: " value={
              <>
                {userDetail.phoneNumber}{" "}
                {(userDetail.phoneNumber?.length ?? 0) > 0 && (
                  <a href={`https://api.whatsapp.com/send?phone=55${userDetail.phoneNumber?.replace(/[^0-9]+/gi, '')}`} title="Clique para abrir o WhatsApp">
                    <WhatsApp color="secondary" />
                  </a>
                )}
              </>} /> 
          }
          {
             !(columns.find(item => item.property === "birthday")?.hide ?? false) && 
              <TableItem
              label="Data de nascimento: "
              value={userDetail.birthday ? new Date(userDetail.birthday).toLocaleDateString() : "-"}
              />
          }
          <TableItem
            label="Possui app Meep? "
            value={userDetail.hasApp ? "Sim" : "Não"}
          />
        </div>
        <div className={styles.row}>
        { !(columns.find(item => item.property === "email")?.hide ?? false) && 
            <TableItem
            label="CPF: "
            value={formatCpf(CPF.cpfMask(userDetail.document || ""))}
            />
        }
          
          { !(columns.find(item => item.property === "gender")?.hide ?? false) &&  Gender(userDetail.gender?.id)}
          {
            !(columns.find(item => item.property === "age")?.hide ?? false) && 
              <TableItem label="Idade: " value={mountAge(userDetail.birthday)} />
          }
        </div>
      </div>
      {
        (userDetail.addresses?.length ?? 0) > 0 && !(columnsConfig.find(item => item.property === "address")?.hide ?? false) && 
        <>
          <h2>Endereço</h2>
          <div className={styles.contentInfoAccordion}>
            <div className={styles.row}>
              <TableItem label="CEP: " value={userDetail.addresses![0].postalCode} />
              <TableItem label="Lougradoro: " value={userDetail.addresses![0].street} />
              <TableItem label="Number: " value={userDetail.addresses![0]?.number} />
              <TableItem label="Bairro: " value={userDetail.addresses![0]?.neighborhood} />
              <TableItem label="Complemento: " value={userDetail.addresses![0]?.complement} />
              
            </div>
          </div>
        </>
      }
      {
        !(columns.find(item => item.property === "customerFidelityPlan")?.hide ?? false) && 
        <>
          <div className={styles.fidelityHeaderRow}>
            <h2>Programa de fidelidade</h2>
            <button disabled={!checkPermission(FeatureTypeEnum.CRM)} type='button' onClick={() => setModalEditSummary(true)}>
              <Icon className={styles.fidelityHeaderIcon}>edit</Icon>
              <span>Editar pontos</span>
            </button>
            <button disabled={!checkPermission(FeatureTypeEnum.CRM)} type='button' onClick={() => setModalHistoryOpened(true)}>
              <Icon className={styles.fidelityHeaderIcon}>schedule</Icon>
              <span>Ver histórico</span>
            </button>
          </div>
          {
            !checkPermission(FeatureTypeEnum.CRM) &&
            <div className={styles.upgradeContainer}>
              <img src="/assets/icon/golden-crown.svg" alt="" />
              <span>Para ver as informações, faça upgrade para o <a href="/private/plans">{currentLocal?.systemIdentifier === 1 ? "Plano Avançado" : "Plano Premium"}</a></span>
            </div>
          }
          <div className={styles.contentInfoAccordion}>
            <div className={styles.row}>
              <TableItem
                flex
                label="Este é um cliente: "
                premium
                value={
                  <>
                    {
                      customerFidelityPlan &&
                      <img
                        className={styles.fidelityImage}
                        src={`/assets/icon/fidelity/gray/${fidelityPlanIcon}.svg`}
                        alt=""
                      />
                    }
                    <strong>{checkPermission(FeatureTypeEnum.CRM) ? fidelityPlanName || ' -' : ' -'}</strong>
                  </>
                }
              />
              <TableItem
                stronger
                label="Quantidade de pontos: "
                value={customerFidelityPlan?.points || ' -'}
                premium
              />
            </div>
            <div className={styles.row} style={{ justifyContent: "flex-end" }}>
              <TableItem
                stronger
                label="Quantidade de moedas: "
                value={customerFidelityPlan?.coins || ' -'}
                premium
              />
            </div>
          </div>
        </>
      }
      <div className={styles.ContentInfoEventsByUser}>
        <h2>{currentLocal?.systemIdentifier === 1 ? "Visitas" : "Participação em eventos"}</h2>
        {
          !checkPermission(FeatureTypeEnum.CRM) &&
          <div className={styles.upgradeContainer}>
            <img src="/assets/icon/golden-crown.svg" alt="" />
            <span>Para ver as informações, faça upgrade para o <a href="/private/plans">{currentLocal?.systemIdentifier === 1 ? "Plano Avançado" : "Plano Premium"}</a></span>
          </div>
        }
        <div className={styles.contentInfoAccordion}>
          <div className={styles.row}>
            <TableItem
              isLoading={isLoadingList}
              darker
              label={
                userDetail.gender?.id === EnumGender.female
                  ? currentLocal?.systemIdentifier !== 1 ? "Essa cliente participou de: " : "Essa cliente possui: "
                  : currentLocal?.systemIdentifier !== 1 ? "Esse cliente participou de: " : "Esse cliente possui: "
              }
              value={mountEvents(eventData?.totalRegisters)}
              premium
            />
            <TableItem
              isLoading={isLoadingList}
              darker
              label={currentLocal?.systemIdentifier !== 1 ? "Último evento: " : "Última visita: "}
              value={eventData?.lastRegisteredEvent || "-"}
              premium
            />
          </div>
          <div className={styles.row}></div>
        </div>
        {currentLocal?.systemIdentifier !== 1 &&
          <CrmCustomerEventsList
            columnsConfig={columnsConfig}
            data={eventData}
            onChangePage={getCustomerEventsHandle}
            isLoading={isLoadingList}
          />
        }
      </div>
      <Drawer open={modalHistoryOpened} onClose={() => setModalHistoryOpened(false)} anchor="right" className={styles.drawer}>
        <FidelityHistory customerDocument={userDetail.document} onClose={() => setModalHistoryOpened(false)} />
      </Drawer>
      <Drawer open={modalEditSummary} onClose={() => setModalEditSummary(false)} anchor="right" className={styles.drawer}>
        <FidelityEditSummary customerDocument={userDetail.document} customerName={userDetail.name} onClose={() => setModalEditSummary(false)} onFinish={handleOnFinishEditSummary} />
      </Drawer>
    </div>

  );
};

export default CrmAcorddion;
