import Sidesheet from "components/sidesheet/Sidesheet";
import TableFragment from "modules/table/presentation/TableFragment";
import { ITableFormValue } from "modules/table/presentation/components/tableForm/TablerForm";
import { ITableItem } from "modules/table/presentation/components/tableList/TableList";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IOperatorsSidesheetProps {
    openTable: boolean | "add";
    setOpenTable: React.Dispatch<React.SetStateAction<boolean | "add">>;
    onClose: () => void;
}

const TablesSidesheet = ({ openTable, setOpenTable, onClose }: IOperatorsSidesheetProps) => {
    const [title, setTitle] = useState("Mesas");
    const [error, setError] = useState("");
    const [openAddTable, setOpenAddTable] = useState<boolean>(false);
    const [selectedTableToEdit, setSelectedTableToEdit] = useState<ITableFormValue>();
    const [selectedTableToDelete, setSelectedTableToDelete] = useState<ITableItem>();

    useEffect(() => {
        if (openTable === "add") setOpenAddTable(true);
    }, [openTable]);

    const isTablesList = useMemo(
      () =>
        !openAddTable &&
        !selectedTableToEdit &&
        !selectedTableToDelete,
      [openAddTable, selectedTableToDelete, selectedTableToEdit]
    );

    const handleCustomizedCancelButton = useCallback(() => {
        if (isTablesList) {
            return setOpenTable(false);
        }
        setError("");
        setTitle("Mesas");
        setOpenAddTable(false);
        setSelectedTableToEdit(undefined);
        setSelectedTableToDelete(undefined);
    }, [isTablesList, setOpenTable]);

    return (
        <Sidesheet
            open={openTable}
            onClose={onClose}
            title={<h2>{title}</h2>}
            content={
                <TableFragment
                    setTitle={setTitle}
                    openAddTable={openAddTable}
                    setOpenAddTable={setOpenAddTable}
                    selectedTableToEdit={selectedTableToEdit}
                    setSelectedTableToEdit={setSelectedTableToEdit}
                    selectedTableToDelete={selectedTableToDelete}
                    setSelectedTableToDelete={setSelectedTableToDelete}
                    setOpenTable={setOpenTable}
                    error={error}
                    setError={setError}
                    onClose={onClose}
                />
            }
            currentStep={0}
            totalSteps={0}
            notTotalHeight
            arrowBack={!isTablesList}
            handleArrowBack={handleCustomizedCancelButton}
        />
    );
}

export default TablesSidesheet;