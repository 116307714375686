import { IPostAddLinkCardsResponse } from "modules/linkCards/domain/dto/IPostAddLinkCardsResponse";
import { ILinkCardsApiService } from "modules/linkCards/domain/interfaces/ILinkCardsApiService";


export const AddLinkCardsItemUseCase = async (service: ILinkCardsApiService, tags: string, localId: string): Promise<IPostAddLinkCardsResponse> => {

    const request = {
        localId: localId,
        tags: tags,
    }

    const response = await service.addLinkCardsItem(request);    
    return response;


}


