import { useCallback, useEffect } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import TerminalService from "services/api/terminal/TerminalService";
import { useQuery } from "react-query";
import GetTerminalDevicesUseCase from "modules/Terminal/application/useCases/GetTerminalDevicesUseCase";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";

const terminalService = TerminalService();

export const UseConfigPagSeguro = () => {
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();

    const getTerminalDevices = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    const response = await GetTerminalDevicesUseCase(terminalService, currentLocal.id, DeviceType.POS, "PAG");
                    return response;
                } catch (error) {
                    console.error(error);
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const { data, isLoading, refetch, isFetching } = useQuery<IDeviceTerminalItemResponse[] | undefined>(
        `@GetnetPos`, () => {
            return getTerminalDevices();
        },
        { enabled: !!currentLocal, retry: false }
    )

    useEffect(() => {
        if (isLoading && !isFetching) {
            showLoading();
        } else {
            hideLoading();
        }
    }, [isLoading, showLoading, hideLoading, isFetching])

    return {
        data,
        isLoading,
        refetch,
    }
}