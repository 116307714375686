import Button from 'components/ui/Button/Button';
import Checkbox from 'components/ui/checkbox/Checkbox';
import React, { FC } from 'react';
import { useReactToPrint } from 'react-to-print';
import styles from './Export.module.scss';
import UseExport from './UseExport';
interface IExportProps {
  closeExportModal: () => void;
  componentRef: React.MutableRefObject<HTMLDivElement | null>;
  handleShowDetails: (show: boolean) => void;
  handleShowResume: (show: boolean) => void;
  handleShowSystemType: (show: boolean) => void;
  handleShowTickets: (show: boolean) => void;
}

export const Export: FC<IExportProps> = ({
  closeExportModal,
  componentRef,
  handleShowDetails,
  handleShowResume,
  handleShowSystemType,
  handleShowTickets,
}) => {
  const {
    selectAll,
    setAll,
    resum,
    setResum,
    tickets,
    setTickets,
    systemType,
    setSystemType,
    details,
    setDetails,
  } = UseExport(
    handleShowDetails,
    handleShowResume,
    handleShowSystemType,
    handleShowTickets,
  );

  const checkExports = selectAll || resum || tickets || systemType || details ? true : false;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id={styles.Export}>
      <p>
        Selecione abaixo as sessões do relatório de fechamento que deseja
        exportar em PDF.
      </p>
      <div>
        <div>
          <Checkbox
            label="Selecionar todos"
            color="primary"
            checked={selectAll}
            onChange={(_, checked) => setAll(checked)}
          />
        </div>
        <div>
          <Checkbox
            label="Resumo do fechamento"
            color="primary"
            checked={resum}
            onChange={(_, checked) => setResum(checked)}
          />
        </div>
        <div>
          <Checkbox
            label="Ingressos e pessoas na lista"
            color="primary"
            checked={tickets}
            onChange={(_, checked) => setTickets(checked)}
          />
        </div>
        <div>
          <Checkbox
            label="Fechamento por tipo de sistema"
            color="primary"
            checked={systemType}
            onChange={(_, checked) => setSystemType(checked)}
          />
        </div>
        <div>
          <Checkbox
            label="Detalhamento"
            color="primary"
            checked={details}
            onChange={(_, checked) => setDetails(checked)}
          />
        </div>
      </div>
      <div>
        <Button
          fullWidth={false}
          onClick={closeExportModal}
          color="primary"
          variant="outlined">
          Cancelar
        </Button>
        <Button
          fullWidth={false}
          onClick={handlePrint}
          color="primary"
          variant="contained"
          disabled={!checkExports}>
          Exportar
        </Button>
      </div>
    </div>
  );
};
