import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import EditCollaboratorUseCase from 'modules/schedule/application/useCases/collaboratorSchedule/EditCollaboratorUseCase';
import GetCollaboratorUseCase from 'modules/schedule/application/useCases/collaboratorSchedule/GetCollaboratorUseCase';
import GetOperatorsUseCase from 'modules/schedule/application/useCases/collaboratorSchedule/GetOperatorsUseCase';
import { GetServiceScheduleByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetServiceScheduleByLocalIdUseCase';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import ColaboratorScheduleApi from 'services/api/schedule/colaborator/ColaboratorScheduleApi';
import AddCollaboratorUseCase from '../../../application/useCases/collaboratorSchedule/AddCollaboratorUseCase'
import { OperatorSelect } from '../../components/colaboratorScheduleForm/ColaboratorScheduleForm';
import { ICollaboratorScheduleFormValue } from '../../components/colaboratorScheduleForm/ColaboratorScheduleFormValue';
import EditColaboratorSchedulePage from './EditColaboratorSchedulePage';



export const UseEditColaboratorSchedulePage = () => {


  const { currentLocal } = useLocal();
  const { showLoading, hideLoading } = useUi();

  const [operators, setOperators] = useState<OperatorSelect[]>();

  const [services, setServices] = useState<ISelect[]>()

  const { collaboratorId } = useParams<{ collaboratorId: string }>()

  const [defaultCollaborator, setDefaultCollaborator] = useState<ICollaboratorScheduleFormValue>()

  const { push } = useHistory();



  const onSubmitFormHandle = useCallback((values: ICollaboratorScheduleFormValue) => {

    if (currentLocal) {
      if (defaultCollaborator && defaultCollaborator.id) {
        showLoading()
        const colalboratorService = ColaboratorScheduleApi();

        EditCollaboratorUseCase(colalboratorService, currentLocal.id, defaultCollaborator?.id, { ...values, minimumLeadTimeInDays: 0, maximumLeadTimeInDays: 365 })
          .then(() => {

            push('/private/agendamento/list/colaborador');
          })
          .finally(() => {
            hideLoading();
          })
      }
    }
  }, [currentLocal, defaultCollaborator, hideLoading, push, showLoading]);


  const getServices = useCallback(() => {
    if (currentLocal) {
      showLoading()
      const service = ColaboratorScheduleApi;
      GetServiceScheduleByLocalIdUseCase(currentLocal?.id, service)
        .then(reponse => setServices(reponse))
        .finally(() => { hideLoading() })

    }
  }, [currentLocal, hideLoading, showLoading])

  const getOperator = useCallback(() => {
    if (currentLocal) {
      showLoading()
      const service = ColaboratorScheduleApi();
      GetOperatorsUseCase(service, currentLocal.id)
        .then(reponse => setOperators(reponse))
        .finally(() => { hideLoading() })

    }
  }, [currentLocal, hideLoading, showLoading])


  const getCollaborator = useCallback(() => {
    if (currentLocal) {
      showLoading()
      const service = ColaboratorScheduleApi();
      GetCollaboratorUseCase(service, collaboratorId)
        .then(reponse => setDefaultCollaborator(reponse))
        .finally(() => { hideLoading() })
    }
  }, [collaboratorId, currentLocal, hideLoading, showLoading])



  useEffect(() => {
    getCollaborator();
  }, [collaboratorId, getCollaborator])

  useEffect(() => {
    getServices()
    getOperator()

    return () => {

    }
  }, [getOperator, getServices])


  return ({
    onSubmitFormHandle,
    operators,
    services,
    defaultCollaborator
  });
};
