import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import { IGetLicensesResponse } from "modules/Terminal/application/dtos/IGetLicensesResponse";
import GetLicensesUseCase from "modules/Terminal/application/useCases/GetLicensesUseCase";
import GetTerminalDevicesUseCase from "modules/Terminal/application/useCases/GetTerminalDevicesUseCase";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetAllSimplePosWithDeviceUseCase from "modules/salesPos/application/GetAllSimplePosWithDeviceUseCase";
import IGetPendingExternalPosUseCase from "modules/salesPos/application/IGetPendingExternalPosUseCase";
import PutCompleteSolicitationUseCase from "modules/salesPos/application/PutCompleteSolicitationUseCase";
import { IGetPendingExternalPosResponse } from "modules/salesPos/domain/dto/IGetPendingExternalPosResponse";
import { ISimplePos } from "modules/salesPos/domain/dto/IGetSimplePosResponse";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import AddExternalPosApi from "services/api/salesPos/AddExternalPosApi";
import AddSimplePosApi from "services/api/salesPos/AddSimplePosApi";
import TerminalService from "services/api/terminal/TerminalService";

const salesSimplePosService = AddSimplePosApi();
const terminalService = TerminalService();
const externalPosService = AddExternalPosApi();

export const UseSalesPosPage = (disableFetch?: boolean) => {
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi();

    const [terminalsList, setTerminalsList] = useState<IDeviceTerminalItemResponse[]>();
    const [pendingPosList, setPendingPosList] = useState<IGetPendingExternalPosResponse[]>();
    const [isLoadingLicenses, setIsLoadingLicenses] = useState(true);
    const [posLicenses, setPosLicenses] = useState<IGetLicensesResponse>();

    const getSimplePos = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    // const response = await GetSimplePosUseCase(salesSimplePosService, currentLocal.id);
                    const response = await GetAllSimplePosWithDeviceUseCase(salesSimplePosService, currentLocal.id);
                    return response.items;
                } finally {
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const { data: simplePosList, isLoading, refetch, isFetching } = useQuery<ISimplePos[] | undefined>(
        `@SimplePos`, () => {
            return getSimplePos();
        },
        { enabled: !!currentLocal, retry: false }
    )

    useEffect(() => {
        if (isLoading && !isFetching && !disableFetch) {
            showLoading();
        } else {
            hideLoading();
        }
    }, [isLoading, showLoading, hideLoading, isFetching, disableFetch])

    const getTerminalDevices = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    const response = await GetTerminalDevicesUseCase(terminalService, currentLocal.id);
                    setTerminalsList(response);
                } catch (error) {
                    console.error(error);
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const getPendingSolicitations = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    const response = await IGetPendingExternalPosUseCase(externalPosService, currentLocal.id);
                    setPendingPosList(response);
                } catch (error) {
                    console.error(error);
                }
            }
            return undefined;
        },
        [currentLocal]
    );

    const putCompleteSolicitation = useCallback(async (solicitationId: string) => {
        try {
            await PutCompleteSolicitationUseCase(externalPosService, solicitationId);
            return "success";
        } catch (error) {
            console.error(error);
        }
    }, []);

    const getPosLicenses = useCallback(async () => {
        if (currentLocal) {
            try {
                const response = await GetLicensesUseCase(terminalService, currentLocal.id);
                setPosLicenses(response.find((type) => type.deviceTypeId === DeviceType.POS));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingLicenses(false);
            }
        }
    }, [currentLocal]);

    return {
        simplePosList,
        isLoading,
        terminalsList,
        pendingPosList,
        isLoadingLicenses,
        posLicenses,
        refetch,
        getTerminalDevices,
        getPendingSolicitations,
        putCompleteSolicitation,
        getPosLicenses,
    }
}