import { IOriginType } from "modules/cashlessDashboard/presentation/components/rechargeOrigin/interfaces/IOriginType";
import { ITopDevices } from "modules/cashlessDashboard/presentation/components/topDevices/interfaces/ITopDevices";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetTopDevicesUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams, type: IOriginType): Promise<ITopDevices> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getTopDevices(filterRequest, type);

  return response;
}

export default GetTopDevicesUseCase
