import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import styles from "./AlertModal.module.scss";
import { ReactNode } from "react";

interface ModalProps {
  open: boolean;
  title: string | JSX.Element;
  text: string | ReactNode;
  subText?: string | ReactNode;
  children?: ReactNode;
  onClose: () => void;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm?: () => void;
  alertImg?: string;
}

export const AlertModal = ({
  open,
  title,
  onClose,
  onConfirm,
  text,
  subText,
  confirmLabel,
  cancelLabel,
  children,
  alertImg,
}: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.warning}>
          <img src={alertImg ?? "/assets/icon/delete-confirmation.svg"} alt="" />
          <span>{text}</span>
        </div>
        <span>{subText}</span>
        {children}
        <div className={styles.buttonsContainer}>
          <Button variant="outlined" fullWidth={false} onClick={onClose}>
            {cancelLabel}
          </Button>
          {onConfirm && <Button fullWidth={false} onClick={onConfirm}>
            {confirmLabel}
          </Button>}
        </div>
      </div>
    </RightDrawer>
  );
};
