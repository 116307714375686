
import { EnumValueType, IGetDiscountCouponFilterRequest } from "../../domain/dto/IGetDiscountCouponFilterRequest";
import { IDiscountCouponFilter } from "../../presentation/components/discountCouponFilter/interfaces/IDiscountCouponFilter";
const ParseFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IDiscountCouponFilter) => {

  const request: IGetDiscountCouponFilterRequest = {
  
    ownerId: localId,
    code: filter?.code,
    valueType: filter?.valueType,
    createdStartdAt: filter?.createdStartdAt,
    createdEndAt: filter?.createdEndAt,
    valueMin: filter?.valueMin,
    valueMax: filter?.valueMax,
    startAt: filter?.startAt,
    finishAt: filter?.finishAt,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterToRequestUseCase

