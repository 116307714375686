import { TextField } from "@material-ui/core";
import {
  ITIDValidation,
  ITIDValidationErrors,
} from "modules/aprovacaoTIDs/presentation/interfaces/ITIDValidation";
import { ChangeEvent, FC } from "react";
import styles from "./TIDValidationForm.module.scss";

export interface ITIDValidationForm {
  values: ITIDValidation;
  handleChangeForm: (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors: ITIDValidationErrors;
}

const TIDValidationForm: FC<ITIDValidationForm> = ({
  handleChangeForm,
  values,
  errors,
}) => {
  return (
    <div id={styles.TIDValidationForm}>
      <div className={styles.item}>
        <label>ID</label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="TID Id"
          variant="outlined"
          name="AdditionalTidsId"
          value={values?.AdditionalTidsId}
          disabled
        />
      </div>
      <div className={styles.item}>
        <label>
          Número de série do equipamento<span> *</span>
        </label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="ID"
          variant="outlined"
          name="DeviceSerialNumber"
          value={values?.DeviceSerialNumber}
          error={!!errors?.DeviceSerialNumber}
          helperText={errors?.DeviceSerialNumber}
          onChange={handleChangeForm}
        />
      </div>
    </div>
  );
};

export default TIDValidationForm;
