import React, { useCallback, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";
import { ICloseEventHeader } from "../ICloseEvent";
const UseCloseHeader = (
  handleIsExport: (isExport: boolean) => void,
  handleShowDetails: (show: boolean) => void,
  handleShowResume: (show: boolean) => void,
  handleShowSystemType: (show: boolean) => void,
  handleShowTickets: (show: boolean) => void
) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const closeEventModal = useCallback(() => {
    setOpenEdit(false);
  }, []);

  const openEditModal = useCallback(() => {
    setOpenEdit(true);
  }, []);

  const closeExportModal = useCallback(() => {
    setOpenExport(false);
    handleShowDetails(true);
    handleShowResume(true);
    handleShowSystemType(true);
    handleShowTickets(true);
    handleIsExport(false);
  }, []);

  const openExportModal = useCallback(() => {
    setOpenExport(true);
    handleIsExport(true);
  }, []);

  return {
    openEdit,
    closeEventModal,
    openEditModal,
    openExport,
    closeExportModal,
    openExportModal,
    isClosed, 
    setIsClosed
  };
};

export default UseCloseHeader;
