import { api } from "../Api";
import { ICashClosingReportApiService } from "modules/cashClosing/domain/interface/ICashClosingReportApiService";
import { IPostCashClosingParams } from "modules/cashClosing/domain/dto/IPostCashClosingParams";
import { IPostCashClosingResponse } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";
import AntiCorruptionCashClosingService from "./antiCorruption/AntiCorruptionCashClosingService";

const antiCorruptionCashClosingService = AntiCorruptionCashClosingService(api);

const CashClosingReportApi = (): ICashClosingReportApiService => {
  const postCashClosing = async (params: IPostCashClosingParams): Promise<IPostCashClosingResponse> => {
    return antiCorruptionCashClosingService.postCashClosingOldApi(params);
  }

  return { postCashClosing };
}

export default CashClosingReportApi;
