import GetCampaignMessagesUseCase from 'modules/crm/application/useCases/report/GetCampaignMessagesUseCase'
import GetCampaignMessageTotalsUseCase from 'modules/crm/application/useCases/report/GetCampaignMessageTotalsUseCase'
import ReSendCampaignUseCase from 'modules/crm/application/useCases/ReSendCampaignUseCase'
import StopCampaignUseCase from 'modules/crm/application/useCases/StopCampaignUseCase'
import { stringify } from 'querystring'
import React, { FC, useCallback, useState } from 'react'
import CrmServiceApi from 'services/api/crm/CrmApi'
import { ICampaignMessagesData } from '../intefaces/CampaignMessage'
import { ICampaignMessageTotals } from '../intefaces/ICampaignMessageTotals'

const UseCampaignMessages = () => {
    const [campaignTotals, setCampaignTotals] = useState<ICampaignMessageTotals>();
    const [isLoadingCampaignTotals, setIsLoadingCampaignTotals] = useState(false);
    const [campaignMessages, setCampaignMessages] = useState<ICampaignMessagesData>();
    const [campaignFailedMessages, setCampaignFailedMessages] = useState<ICampaignMessagesData>();
    const [isLoadingMessageList, setIsLoadingMessageList] = useState(false);
    const [isLoadingReSend, setIsLoadingReSend] = useState(false);
    const [isLoadingStop, setIsLoadingStop] = useState(false);
    const [isLoadingFailed, setIsLoadingFailed] = useState(false);


    const getCampaignMessages = useCallback(async (campaignId: string, params: { hasFailed?: boolean, pagination: { page?: number, pageSize?: number } }) => {
        try {

            setIsLoadingMessageList(true)
            const service = CrmServiceApi()
            const response = await GetCampaignMessagesUseCase(service, campaignId, params)
            setCampaignMessages(response)
            return response;
        } finally {
            setIsLoadingMessageList(false)
        }
    }, [],)

    const getFailedCampaignMessages = useCallback(async (campaignId: string, params: { pagination: { page?: number, pageSize?: number } }) => {
        try {

            setIsLoadingFailed(true)
            const service = CrmServiceApi()
            const response = await GetCampaignMessagesUseCase(service, campaignId, { ...params, hasFailed: true })
            setCampaignFailedMessages(response)
            return response;
        } finally {
            setIsLoadingFailed(false)
        }
    }, [],)

    const getCampaigTotals = useCallback(async (campaignId: string) => {
        try {
            setIsLoadingCampaignTotals(true)
            const service = CrmServiceApi()
            const response = await GetCampaignMessageTotalsUseCase(service, campaignId)
            setCampaignTotals(response);
            // getCampaignMessages(campaignId, { pagination: { page: 1 } })
        } finally {
            setIsLoadingCampaignTotals(false)
        }
    }, [])

    const resendCampaign = useCallback(async (campaignId: string) => {
        try {
            setIsLoadingReSend(true)
            const service = CrmServiceApi();
            await ReSendCampaignUseCase(service, campaignId)
        } finally {
            setIsLoadingReSend(false)
        }
    }, [])

    const stopCampaign = useCallback(async (campaignId: string) => {
        try {
            setIsLoadingStop(true)
            const service = CrmServiceApi();
            await StopCampaignUseCase(service, campaignId)
        } finally {
            setIsLoadingStop(false)
        }
    }, [])


    return ({
        campaignMessages,
        isLoadingMessageList,
        getCampaignMessages,
        getCampaigTotals,
        campaignTotals,
        isLoadingCampaignTotals,
        resendCampaign,
        campaignFailedMessages,
        isLoadingFailed,
        getFailedCampaignMessages,
        stopCampaign
    })
}

export default UseCampaignMessages