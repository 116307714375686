import { IProductsService } from "modules/products/domain/interfaces/IProductsService"

const ChangeStockControlUseCase = async (service: IProductsService, productIds: string[], stockControl: boolean): Promise<void> => {
    const promises: Promise<void>[] = []

    productIds.forEach(x => {
        promises.push(service.changeStockControl(x, stockControl));
    })

    await Promise.all(promises);
}
export default ChangeStockControlUseCase