import { IKDSItem } from "modules/kds/presentation/components/KDSItem/IKDSItem";
import { ILocalPrinterOrder } from "../domain/dtos/ILocalPrinterOrder";
import { ILocalPrinterService } from "../domain/interfaces/ILocalPrinterService";


const SendToLocalPrinterUseCase = async (
  localPrinterService: ILocalPrinterService,
  item: IKDSItem,
) => {


  const localPrinterOrder: ILocalPrinterOrder = {
    id: item.id,
    table: item.table??"",
    orderItem: item.order.items.map(orderItem => ({
      printerName: orderItem.printerName,
      name: orderItem.name,
      productPrice: orderItem.value,
      quantity: orderItem.quantity,
      orderComposition: orderItem.compositions?.map((compositionItem) => ({
        description: compositionItem.description,
        compositionItems: compositionItem.options.map((option) => ({
          description: option,
        }))
      }))
    }))
  }
  await localPrinterService.sendToLocalPrinter(localPrinterOrder, item.code, item.customer?.name?? "" + " " + item.table, item.remotePrinter.address);
};
export default SendToLocalPrinterUseCase;
