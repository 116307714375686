import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { useCallback, useEffect, useState } from 'react'
import { IFilterValue } from './components/filter/IFilter';
import { IProduct } from './components/filter/IProduct';
import GetProductsUseCase from '../application/useCases/GetProductsUseCase';
import TicketsReportService from 'services/api/ticketsReport/TicketsReportService';
import GetTicketsValidationUseCase from '../application/useCases/GetTicketsValidationUseCase';
import { ITicketsValidation } from './interfaces/ITicketsValidation';
import GetTicketsValidationDetailUseCase from '../application/useCases/GetTicketsValidationDetailUseCase';
import GetVendingDeviceUseCase from '../application/useCases/GetVendingDeviceUseCase';
import GetValidationDeviceUseCase from '../application/useCases/GetValidationDeviceUseCase';
import GetValidationOperatorUseCase from '../application/useCases/GetValidationOperatorUseCase';
import { IVendingDevice } from './interfaces/IVendingDevice';
import { IValidationDevice } from './interfaces/IValidationDevice';
import { IValidationOperator } from './interfaces/IValidationOperator';
import GetCashiersUseCase from '../application/useCases/GetCashiersUseCase';
import { ICashiers } from './interfaces/ICashiers';
import { ICategory } from './interfaces/ICategories';
import GetCategoriesUseCase from '../application/useCases/GetCategoriesUseCase';
import GetTicketsValidationReportUseCase from '../application/useCases/GetTicketsValidationReportUseCase';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

const ticketsReportService = TicketsReportService();

const TicketsReport = () => {
    const { currentLocal } = useLocal();
    const [filter, setFilter] = useState<IFilterValue>({} as IFilterValue)
    const [cashiers, setCashiers] = useState<ICashiers[]>([])
    const [products, setProducts] = useState<IProduct[]>([])
    const [vendingDevices, setVendingDevices] = useState<IVendingDevice[]>([])
    const [validationDevices, setValidationDevices] = useState<IValidationDevice[]>([])
    const [validationOperators, setValidationOperators] = useState<IValidationOperator[]>([])
    const [categories, setCategories] = useState<ICategory[]>([])
    const [parentCategories, setParentCategories] = useState<ICategory[]>([])
    const [data, setData] = useState<ITicketsValidation>()
    const [loading, setLoading] = useState(false)
    const { toast } = useUi();

    const onChangeFilterHandle = (value: IFilterValue) => {
        setFilter(value);
        getData({}, value)
    }
    
    /**
     * Métodos de GET DATA
     */
    const getData = useCallback(async ({ page, sortField, sortOrientation }: { page?: number, sortField?: string, sortOrientation?: 'asc' | 'desc' }, _filter?: IFilterValue) => {
        setLoading(true)
        try {
            const response = await GetTicketsValidationUseCase(ticketsReportService, { filter: _filter || filter, page, sortField, sortOrientation, localId: currentLocal?.id });
            setData(response)
        } catch {
            toast("Ocorreu um erro ao buscar os dados. Tente novamente", "error");
        } finally {
            setLoading(false)
        }
    }, [currentLocal?.id, filter, toast])

    const getDataDetailed = useCallback(async (ticketId: string) => {
        try {
            const response = await GetTicketsValidationDetailUseCase(ticketsReportService, currentLocal!.id, ticketId );
            return response
        } catch (err) {
            toast("Ocorreu um erro ao buscar os dados. Tente novamente", "error");
            throw err;
        }
    }, [currentLocal, toast])

    const exportReport = useCallback(async () => {
        try {
            await GetTicketsValidationReportUseCase(ticketsReportService, { filter, localId: currentLocal?.id });
            toast("Em breve você irá receber o relatório no email", "success");
        } catch {
            toast("Ocorreu um erro ao gerar o excel. Tente novamente", "error");
        }
    }, [currentLocal?.id, filter, toast])
    
    /**
     * Métodos de UseEffects
     */
    useEffect(() => {
        (async () => {
            const promises = [
                GetCashiersUseCase(ticketsReportService, currentLocal!.id),
                GetProductsUseCase(ticketsReportService, currentLocal!.id),
                GetVendingDeviceUseCase(ticketsReportService, currentLocal!.id),
                GetValidationDeviceUseCase(ticketsReportService, currentLocal!.id),
                GetValidationOperatorUseCase(ticketsReportService, currentLocal!.id),
                GetCategoriesUseCase(ticketsReportService, currentLocal!.id),
            ]

            const response: Array<any> = await Promise.all(promises);

            setCashiers(response[0])
            setProducts(response[1])
            setVendingDevices(response[2])
            setValidationDevices(response[3])
            setValidationOperators(response[4])
            setCategories(response[5].categorias.filter((x: ICategory) => x.paiId))
            setParentCategories(response[5].categoriasPai)
        })()
    }, [currentLocal])

    return ({
        filter,
        cashiers,
        products,
        vendingDevices,
        validationDevices,
        validationOperators,
        categories,
        parentCategories,
        data,
        loading,
        getData,
        getDataDetailed,
        onChangeFilterHandle,
        exportReport
    })
}

export default TicketsReport