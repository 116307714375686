import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './SupplyLocals.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import LocalsService from 'modules/meepErp/services/api/Locals/LocalsService';
import { ILocalsFilterValue } from 'modules/meepErp/presentation/components/locals/localsList/localsFilter/ILocalsFilter';
import LocalsList from 'modules/meepErp/presentation/components/locals/localsList/LocalsList';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import UseQueryState from '../../hook/UseQueryFilter';
import { Add } from '@mui/icons-material';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'services/api/Api';
import DeleteErp from '../../components/ui/delete/DeleteErp';
import { getSupplyStorageLocalsUseCase, onDeleteLocalsUseCase } from '../../../application/useCases/supplyLocals/SupplyLocalsUseCase';
import { IGetStorageLocalsItemResponse, IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { tabsRegister } from '../../ErpRoutes';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

export interface ILocalsProps {
    //propertys
}

const SupplyStorageLocalsPage: FC<ILocalsProps> = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<ILocalsFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Locais de estoque",
            },                        
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  


    const updateIsActive = useCallback(async (id: string, isActive: boolean, value: IGetStorageLocalsItemResponse) => {
        if (isActive) {
            const service = LocalsService();
            await service.enableLocal(id)
        } else {
            const service = LocalsService();
            await service.disableLocal(id);
        }
        return { ...value, isActive }
    }, [])

    //ReactiQuery

    const localsStock = useQuery(['@localsStock', currentLocal?.id, filter], () => getSupplyStorageLocalsUseCase(filter), {
        enabled: !!currentLocal?.id,
    })

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetStorageLocalsListResponse | undefined>(['@localsStock'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteLocalsMutation = useMutation((id: string) => onDeleteLocalsUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteLocalsMutation.mutateAsync(id);
    }, [onDeleteLocalsMutation])

    const onSuccessUpdateIsActive = (value: IGetStorageLocalsItemResponse, { id, isActive }: { id: string; isActive: boolean }) => {
        queryClient.setQueriesData<IGetStorageLocalsListResponse | undefined>(
            ['@localsStock'],
            (previous) =>
                previous
                    ? {
                        ...previous,
                        items: previous.items.map((item) =>
                            item.id === id ? { ...item, isActive } : item
                        ) ?? []
                    }
                    : undefined
        );
    };

    const onUpdateIsActiveMutation = useMutation(
        ({ id, isActive, value }: { id: string; isActive: boolean; value: IGetStorageLocalsItemResponse }) =>
            updateIsActive(id, isActive, value),
        {
            onSuccess: onSuccessUpdateIsActive
        }
    );

    const onConfirmEnable = useCallback(async (id: string, isActive: boolean, value: IGetStorageLocalsItemResponse) => {
        return await onUpdateIsActiveMutation.mutateAsync({ id, isActive, value });

    }, [onUpdateIsActiveMutation]);

    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/locals/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/locals/update/${id}`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    return (
        <div id={styles.Locals} >
            <TabsErp data={tabsRegister}/>
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar local
                    </Button>
                </div>
            }
            <LocalsList
                filter={filter}
                onChangeFilter={updateFilter}
                localsStock={localsStock.data}
                isLoading={localsStock.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
                onClickEnable={onConfirmEnable}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'local de estoque'}
                text={'Deseja realmente excluir o local de estoque?'}
                textFeedback={'Local de estoque excluído com sucesso!'}
                auxiliaryText='Ao excluir um local de estoque todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
            />
        </div>
    )
}
export default SupplyStorageLocalsPage



