import { FC, useState } from "react";
import CreateCampaignComponent from "../components/crmFilterForm/components/Campaign/CampaignComponent/CampaignComponent";
import CrmFilterForm from "../components/crmFilterForm/CrmFilterForm";
import CrmList from "../components/crmList/CrmList";
import UseCrmPage from "./UseCrmPage";
import styles from "./CrmPage.module.scss"
import Sidesheet from "components/sidesheet/Sidesheet";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { CrmExportType } from "../components/crmFilterForm/interfaces/ICrmFilterValue";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import SaasCrmFilterForm from "../components/crmFilterForm/SaasCrmFilterForm";

const CrmPage: FC = () => {
  const {
    customers,
    eventsByCategory,
    ticketCategories,
    onClickSelectAllHandle,
    onChangeFilterHandle,
    onChangePaginationHandle,
    postSaveFilter,
    savedFilters,
    isLoadingCrmCustomer,
    deleteFilter,
    params,
    getCustomerEvents,
    isLoadingCrmSavedFilter,
    createCampaignWithCustomerAndFilters,
    onClickCreateCampaignWithEvent,
    onClickCreateCampaignByCampaign,
    eventsList,
    campaignsList,
    fidelityList,
    onExportClients,
  } = UseCrmPage();

  const { currentLocal } = useLocal();
  const [step, setStep] = useState(1);
  const [optionDownload, setOptionDownload] = useState("csv");
  const [openSidesheetDownload, setOpenSidesheetDownload] = useState<null | string>(null);

  const onDownload = async () => {
    if (optionDownload === "csv") {
      await onExportClients(CrmExportType.CSV);
    } else {
      await onExportClients(CrmExportType.XLSX);
    }
    
    setStep(2);
  };

  return (
    <div id={styles.CrmPage}>
      <CreateCampaignComponent
        events={eventsList?.data ?? []}
        campaigns={campaignsList?.data ?? []}
        onClickCreateCampaignWithSelectedCostumers={
          createCampaignWithCustomerAndFilters
        }
        onClickCreateCampaignByEvent={onClickCreateCampaignWithEvent}
        onClickCreateCampaignByCampaign={onClickCreateCampaignByCampaign}
      />
      {
        currentLocal?.systemIdentifier !== 1 
        ? <CrmFilterForm
          isLoading={isLoadingCrmSavedFilter}
          onClickDeleteFilter={deleteFilter}
          onSubmit={onChangeFilterHandle}
          events={eventsList ? eventsList.data : []}
          eventCategories={eventsByCategory ? eventsByCategory.data : []}
          ticketsCategories={ticketCategories ? ticketCategories.data : []}
          fidelityPlanIds={fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1) ?? []}
          fidelityList={fidelityList || []}
          savedFilters={savedFilters ? savedFilters : []}
          onSaveFilter={postSaveFilter}
          setOpenSidesheetDownload={setOpenSidesheetDownload}
        />
        : <SaasCrmFilterForm
          isLoading={isLoadingCrmSavedFilter}
          onClickDeleteFilter={deleteFilter}
          onSubmit={onChangeFilterHandle}
          events={eventsList ? eventsList.data : []}
          eventCategories={eventsByCategory ? eventsByCategory.data : []}
          ticketsCategories={ticketCategories ? ticketCategories.data : []}
          fidelityPlanIds={fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1) ?? []}
          fidelityList={fidelityList || []}
          savedFilters={savedFilters ? savedFilters : []}
          onSaveFilter={postSaveFilter}
        />
      }    
      <CrmList
        isLoading={isLoadingCrmCustomer}
        onClickSelectAll={onClickSelectAllHandle}
        list={customers}
        fidelityList={fidelityList || []}
        pagination={params?.pagination}
        onChangePagination={onChangePaginationHandle}
        getCustomerEvents={getCustomerEvents}
      />

      <Sidesheet
        open={openSidesheetDownload}
        onClose={() => {
          setOpenSidesheetDownload(null);
          setStep(1);
        }}
        title={<h1>Exportar</h1>}
        content={
          <div>
            <p>Selecione abaixo uma opção para exportar o relatório</p>
            <RadioGroup
              onChange={(_, value) => setOptionDownload(value)}
              value={optionDownload}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              <div className={styles.radioItem}>
                <FormControlLabel
                  value={`csv`}
                  control={<Radio />}
                  label="CSV"
                />
              </div>            
            </RadioGroup>
          </div>
        }
        cancelButton
        continueButton={"Exportar"}
        handleContinueButton={onDownload}
        currentStep={step}
        totalSteps={2}
        feedback={
          <SidesheetFeedback text="O relatório foi enviado para o seu e-mail. Ele chegará em breve." success />
        }
      />
    </div>
  );
};
export default CrmPage;
