import { api } from "../Api";
import { IDigitalAccountApi } from "modules/contaDigital/presentation/pages/interface/IDigitalAccountApi";
import { DigitalAccountResponse } from "modules/contaDigital/models/dtos/DigitalAccountResponse";
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";
import { BankDetailDepositResponse } from "modules/contaDigital/models/dtos/BankDetailDepositResponse";
import { WithdrawalRequest } from "modules/contaDigital/models/dtos/WithdrawalRequest";
import ObterContasDigitais from "./antiCorruptionDigitalAccountService/ObterContasDigitais";
import ObterSaldoContaDigital from "./antiCorruptionDigitalAccountService/ObterSaldoContaDigital";
import ObterDadosBancariosDeposito from "./antiCorruptionDigitalAccountService/ObterDadosBancariosDeposito";
import { PayAccountRequest } from "modules/contaDigital/models/dtos/PayAccountRequest";
import { ExtractResponse } from "modules/contaDigital/models/dtos/ExtractResponse";
import { SearchAnticipateRequest } from "modules/contaDigital/models/dtos/SearchAnticipateRequest";
import { ConfirmAntecipateRequest } from "modules/contaDigital/models/dtos/ConfirmAntecipateRequest";
import { SimulateAntecipateRequest } from "modules/contaDigital/models/dtos/SimulateAntecipateRequest";
import { TotalLockResponse } from "modules/contaDigital/models/dtos/TotalLockResponse";
import { IDetailsLockResponse } from "modules/contaDigital/models/dtos/IDetailsLockResponse";
import { IDetailsRetainedResponse } from "modules/contaDigital/models/dtos/IDetailsRetainedResponse";

const DigitalAccountApi = (): IDigitalAccountApi => {
  const getDigitalAccounts = async (
    ownerId: string
  ): Promise<DigitalAccountResponse[]> => {
    return ObterContasDigitais(api, ownerId);
  };

  const getDigitalAccountBalance = async (
    ownerId: string,
    digitalAccountId: string
  ): Promise<DigitalAccountBalanceResponse> => {
    return ObterSaldoContaDigital(api, ownerId, digitalAccountId);
  };

  const getBankDetail = async (
    ownerId: string
  ): Promise<BankDetailDepositResponse[]> => {
    return ObterDadosBancariosDeposito(api, ownerId);
  };

  const sendConfirmationSms = async (
    ownerId: string,
    digitalAccountId: string
  ): Promise<void> => {
    const body = {
      localClienteId: ownerId,
      dadoBancarioId: digitalAccountId,
    };
    await api.post("/ContaDigital/EnviarSMSConfirmacao", body);
  };

  const sendChangeBalanceConfirmationSms = async (): Promise<void> => {
    await api.post("/ContaDigital/EnviarSMSAlterarSaldo");
  };

  const deleteDadoBancario = async (id: string): Promise<void> => {
    await api.post(`/ContaDigital/RemoverDadoBancarioContaDigital/${id}`);
  };

  const postWithdrawal = async (data: WithdrawalRequest): Promise<void> => {
    await api.post("/ContaDigital/RealizarSaque", data);
  };

  const postPayAccount = async (body: PayAccountRequest): Promise<void> => {
    await api.post("/ContaDigital/PagarBoleto", body);
  };

  const getNextAntecipate = async (
    ownerId: string,
    digitalAccountId: string
  ): Promise<void> => {
    const result = await api.get(
      `/ContaDigital/ProximasLiberacoes/${ownerId}/${digitalAccountId}`
    );
    return result.data;
  };

  const postSearchAnticipate = async (
    body: SearchAnticipateRequest
  ): Promise<void> => {
    const result = await api.post(
      "/Financeiro/BuscarAntecipacoesDoLocal",
      body
    );
    return result.data;
  };

  const postSimulateAnticipate = async (
    body: SimulateAntecipateRequest
  ): Promise<void> => {
    const result = await api.post("/Financeiro/SimularAntecipacao/", body);
    return result.data;
  };

  const postConfirmAnticipate = async (
    body: ConfirmAntecipateRequest
  ): Promise<void> => {
    await api.post("/Financeiro/ConfirmarAntecipacao/", body);
  };

  const getExtract = async (
    BankAccountId: string,
    StartDate: string,
    EndDate: string,
    LocalId: string,
    Page: number,
    PageSize: number
  ): Promise<ExtractResponse> => {
    const result = await api.get(
      `/ContaDigital/GetExtract?BankAccountId=${BankAccountId}&StartDate=${StartDate}&EndDate=${EndDate}&LocalId=${LocalId}&Page=${Page}&PageSize=${PageSize}`
    );
    return result.data;
  };

  const getTotalLock = async (localId: string): Promise<TotalLockResponse> => {
    const result = await api.get(`/financial/totalLock/${localId}`);
    return result.data;
  };

  const getDetailsRetained = async (localId: string): Promise<IDetailsRetainedResponse[]> => {
    const result = await api.get(`/ContaDigital/BuscarDetalhamentoSaldoRetido/${localId}`);
    return result.data;
  };

  const getDetailsLock = async (localId: string): Promise<IDetailsLockResponse> => {

    const params = {
      localId: localId,
      status: 1,
      "sort.type": 'date',
      "sort.orientation": "desc"
    }

    const result = await api.get(`/Financial/Lock/`, {params: params});
    return result.data;
  };

  return {
    getDigitalAccounts,
    getDigitalAccountBalance,
    getBankDetail,
    sendConfirmationSms,
    sendChangeBalanceConfirmationSms,
    postWithdrawal,
    postPayAccount,
    deleteDadoBancario,
    getExtract,
    postSearchAnticipate,
    postConfirmAnticipate,
    getNextAntecipate,
    postSimulateAnticipate,
    getTotalLock,
    getDetailsLock,
    getDetailsRetained
  };
};

export default DigitalAccountApi;
