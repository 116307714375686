import { ImportExport } from "@mui/icons-material";
import { IParamsClientDiscountCouponsReport } from "modules/config/discountCoupons/presentation/hooks/UseDiscountCouponsReport";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IDiscountCouponsReportColumnsConfig } from "../../interfaces/IColumnsConfig";
import styles from "./DiscountCouponsReportItem.module.scss";

interface DiscountCouponHeaderProps {
  columns: IDiscountCouponsReportColumnsConfig[];
  params: IParamsClientDiscountCouponsReport;
  setParams: React.Dispatch<
    React.SetStateAction<IParamsClientDiscountCouponsReport>
  >;
}

const DiscountCouponsReportHeader: FC<DiscountCouponHeaderProps> = ({
  columns,
  setParams,
}) => {
  const onClickOrderColumn = useCallback(
    (orderBy: string) => {
      setParams((prev) => ({
        ...prev,
        sort: {
          type: orderBy,
          orientation: prev?.sort?.orientation === "asc" ? "desc" : "asc",
        },
        pagination: { page: 0, pageSize: prev.pagination?.pageSize },
      }));
    },
    [setParams]
  );

  const header = useMemo(
    () =>
      columns.map(
        (column, index) =>
          !column.hide && (
            <div key={index} className={styles.row} style={column.style}>
              <span className={styles.order}>
                {column.title}{" "}
                <ImportExport
                  onClick={() => onClickOrderColumn(column.property)}
                />{" "}
              </span>
            </div>
          )
      ),
    [columns, onClickOrderColumn]
  );

  return (
    <div id={styles.DiscountCouponHeaderContainer}>
      <div className={styles.container}>
        {header}
        <div className={styles.icon}></div>
      </div>
    </div>
  );
};

export default DiscountCouponsReportHeader;
