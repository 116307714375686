import { UseQueryResult, useQuery } from "react-query";

import { GetCashiersList } from "../requests";
import { CashiersTypesCollection, GetCashiersListProps } from "../types";
import { BaseErrorTypes, normalizeResponse } from "services/api/Api";

type UseCashiersListResult = UseQueryResult<
  CashiersTypesCollection,
  BaseErrorTypes
>;

export const UseCashiersListQueryName = "UseCashiersList";

export const UseListCashiers = ({
  startDate,
  endDate,
  localId,
}: GetCashiersListProps): UseCashiersListResult => {
  const request = async (): Promise<CashiersTypesCollection> => {
    const response = await GetCashiersList({ startDate, endDate, localId });
    return normalizeResponse<CashiersTypesCollection>(response);
  };

  return useQuery<CashiersTypesCollection, BaseErrorTypes>(
    [UseCashiersListQueryName, startDate, endDate],
    request,
    { refetchOnWindowFocus: false }
  );
};
