import UseDimension from "components/dimension/UseDimension";
import ContentCard from "../contentCard/ContentCard";
import styles from "./FilterContent.module.scss";
import { Divider } from "@mui/material";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";

export interface IFilterContentProps {
  title: string;
  data: ITutoriaisRecords[];
}

const FilterContent = ({ title, data }: IFilterContentProps) => {
  const { isMobile } = UseDimension();

  return (
    <div id={styles.FilterContent}>
      <div className={styles.containerTitle}>
        <h3>{title}</h3>
        <Divider />
      </div>
      <div
        className={styles.containerCards}
        style={{ justifyContent: isMobile ? "center" : "flex-start" }}
      >
        {data.map((item) => (
          <ContentCard
            img={item.imagePath}
            title={item.title}
            description={item.description}
            link={item.fullTutorialUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterContent;
