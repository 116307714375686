import styles from "components/sidesheet/Sidesheet.module.scss";
import { IPostMobiConfigRequest } from "modules/config/integrationsConfig/domain/dto/MobiConfig/IPostMobiConfigRequest";
import { IMobiFormErrors } from "../../../pages/configMobi/validade/MobiValidation";
import Input from "components/ui/input/Input";

export interface IConfigContentMobiProps {
  step: number;
  values: IPostMobiConfigRequest;
  setValues: React.Dispatch<React.SetStateAction<IPostMobiConfigRequest>>;
  errors: IMobiFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<IMobiFormErrors>>;
}

const ConfigContentMobi = ({
  step,
  values,
  setValues,
  errors,
  setErrors,
}: IConfigContentMobiProps) => {
  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="login" className={styles.required}>
              Login
            </label>
            <Input
              name="login"
              variant={"outlined"}
              value={values?.UsuarioNFCe}
              onChange={(ev) => {
                setValues((prev) => ({
                  ...prev,
                  UsuarioNFCe: ev.target.value,
                }));
                setErrors((prev) => ({ ...prev, UsuarioNFCe: undefined }));
              }}
              error={!!errors.UsuarioNFCe}
              helperText={errors.UsuarioNFCe}
            />
          </div>
          <div>
            <label htmlFor="pass" className={styles.required}>
              Senha
            </label>
            <Input
              name="pass"
              variant={"outlined"}
              value={values?.SenhaNFCe}
              onChange={(ev) => {
                setValues((prev) => ({ ...prev, SenhaNFCe: ev.target.value }));
                setErrors((prev) => ({ ...prev, SenhaNFCe: undefined }));
              }}
              error={!!errors.SenhaNFCe}
              helperText={errors.SenhaNFCe}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentMobi;
