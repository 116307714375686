import { FC, useCallback, useEffect, useState } from 'react'
import styles from './NesteRoles.module.scss'
import { IRoles as IMenuRoles } from './ISubMenu';
import { NestedRolesCard } from './NestedRolesCard';
import Sidesheet from 'components/sidesheet/Sidesheet';
import TabView from 'components/tab/TabView';
import { TextField } from '@mui/material';

export interface INesteRolesProps {
    //propertys
}

export interface INestedFormValues {
    name: string;
    permissionIds: string[]
}

export interface INesteRolesProps {
    open: boolean;
    menuRoles: IMenuRoles[];
    defaultValues?: INestedFormValues
    onSubmit: (values: INestedFormValues) => void;
    onClose: () => void;
}

const NesteRoles: FC<INesteRolesProps> = ({ open, menuRoles, defaultValues, onSubmit, onClose }) => {
    const eventsSubmenus = menuRoles.filter(submenu => submenu.PositionType === 1);
    const eventSubmenus = menuRoles.filter(submenu => submenu.PositionType === 2);

    const [permissionsIds, setPermissionIds] = useState<string[]>([]);
    const [description, setDescription] = useState<string>("");
    const [errorDescription, setErrorDescription] = useState<string>("");


    useEffect(() => {
        if (defaultValues) {
            setDescription(defaultValues.name);
            setPermissionIds(defaultValues.permissionIds);
        } else {
            setDescription("");
            setPermissionIds([]);
        }
    }, [defaultValues])

    const [step, setStep] = useState<"DESCRIPTION" | "ROLES">('DESCRIPTION')

    const onSubmitHandle = useCallback(() => {
        if (onSubmit) {
            onSubmit({ permissionIds: permissionsIds, name: description } ?? []);
            setStep("DESCRIPTION")
            setDescription('')
            setPermissionIds([])
        }
    }, [onSubmit, permissionsIds, description]);

    const onSubmitDescription = useCallback(() => {
        if (description) {
            setStep('ROLES');
        } else {
            setErrorDescription("Campo obrigatório!");
        }
    }, [description]);

    const handleShowTabView = useCallback(() => (
        <TabView
            index={step === "DESCRIPTION" ? 0 : 1}
            tabs={[
                {
                    label: "Descrição",
                    value: "DESCRIPTION",
                },
                {
                    label: "Permissões",
                    value: "ROLES",
                },
            ]}
            onChangeTab={(tab) => tab.value === "ROLES" ? onSubmitDescription() : setStep("DESCRIPTION")}
        />
    ), [onSubmitDescription, step]);

    const handleContent = useCallback(() => {
        if (step === 'DESCRIPTION') {
            return (
                <div>
                    <label className={styles.required}>Descrição</label>
                    <TextField
                        multiline
                        defaultValue="Default Value"
                        variant="outlined"
                        size='small'
                        fullWidth
                        required
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                            setErrorDescription("");
                        }}
                        error={!!errorDescription}
                        helperText={errorDescription}
                    />
                </div>
            )
        }
        return (
            <div className={styles.container}>
                {eventSubmenus.length > 0 && (
                    <div className={styles.level} >
                        <h2>Local</h2>
                        {eventSubmenus.map((submenu) => (
                            <NestedRolesCard defaultIsOpen={false} key={submenu.Id} submenu={submenu} values={permissionsIds} onChange={setPermissionIds} />
                        ))}
                    </div>
                )}
                {eventsSubmenus.length > 0 && (
                    <div className={styles.level}>
                        <h2>Eventos</h2>
                        {eventsSubmenus.map((submenu) => (
                            <NestedRolesCard defaultIsOpen={false} key={submenu.Id} submenu={submenu} values={permissionsIds} onChange={setPermissionIds} />
                        ))}
                    </div>
                )}
            </div>
        )
    }, [description, errorDescription, eventSubmenus, eventsSubmenus, permissionsIds, step]);

    const handleClose = useCallback(() => {
        onClose();
        setStep("DESCRIPTION")
        setDescription('')
        setPermissionIds([])
    }, [onClose]);

    return (
        <Sidesheet
          open={open}
          onClose={handleClose}
          title={
            <h2>
              {defaultValues ? "Editar" : "Novo"} <b>cargo</b>
            </h2>
          }
          tabView={handleShowTabView()}
          content={handleContent()}
          currentStep={step === "DESCRIPTION" ? 1 : 2}
          totalSteps={2}
          continueButton={step === "DESCRIPTION" ? "Continuar" : "Salvar"}
          handleContinueButton={() => step === "DESCRIPTION" ? onSubmitDescription() : onSubmitHandle()}
          cancelButton
          notTotalHeight
        />
    );
};

export default NesteRoles;
