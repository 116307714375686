import { ICreateCampaignWithCustomersRequest } from "modules/crm/models/dtos/CreateCampaignWithCustomersRequest"
import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { ICrmFilterValue } from "modules/crm/presentation/components/crmFilterForm/interfaces/ICrmFilterValue"


const CreateCampaignWithCustomerAndFiltersUseCase = async (service: ICrmService, ownerId: string, params: { filter?: ICrmFilterValue, exceptionCustomers?: { id: string }[], customers?: { id: string }[], totalAudience: number }) => {


    let _body = { ...params.filter, eventId: params.filter?.events?.map(item => item.id) ?? [], ownerId };
    delete _body.events;
    var filterBody = JSON.stringify(_body);

    const request: ICreateCampaignWithCustomersRequest = {
        ownerId: ownerId,
        customersIds: params.customers?.map(item => item.id),
        exceptionCustomersIds: params.customers?.map(item => item.id),
        filterBody: filterBody,
        totalAudience: params.totalAudience,
    }

    const response = await service.createCampaignWithCustomersAndFilter(request)
    return (response)
}
export default CreateCampaignWithCustomerAndFiltersUseCase