import { FC, useCallback, useEffect, useState } from "react";
import styles from "./Categories.module.scss";
import { ItemCard } from "./itemCard/ItemCard";
import { ICategories, ICategoryProducts } from "./interfaces/ICategory";
import { IGetDataParams } from "../../interfaces/IGetDataParams";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 } from 'uuid';
import { Skeleton } from "../skeleton/Skeleton";

interface ICategoriesProps {
  getCategoriesData: ({ page }: IGetDataParams) => Promise<ICategories>
  getCategoryProductsData: ({ page }: IGetDataParams, categoryId: string) => Promise<ICategoryProducts>
}

const Categories: FC<ICategoriesProps> = ({ getCategoriesData, getCategoryProductsData }) => {
    const [data, setData] = useState<ICategories>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    const randonId = v4();
    
    const getData = useCallback(async (page?: number) => {
      clearContainerError(dashboardContainerEnum.CATEGORIES);
      try {
          !page && setOnLoading(true);
          const response = await getCategoriesData({ page });
          if (page) {
              setData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
          } else {
              setData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
          }

          if (!response.records.length)
              setOnError({
                  type: 'warning',
                  containerId: dashboardContainerEnum.CATEGORIES
              })
      } catch (error: any) {
          setOnError({
              type: 'error',
              containerId: dashboardContainerEnum.CATEGORIES
          })
      } finally {
          setOnLoading(false);
      }
    }, [clearContainerError, getCategoriesData, setOnError]);

    useEffect(() => {
        getData(0);
    }, [getData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    const getSubItems = useCallback(async (categoryId: string) => {
      return await getCategoryProductsData({ page: currentPage }, categoryId)
    }, [currentPage, getCategoryProductsData])

    return (
        <div id={styles.Categories}>
          <div className={styles.header}>
            <p>Nome da categoria</p>
            <p>Total</p>
          </div>

          {
            onLoading ? <Skeleton items={10} /> :
              <InfiniteScroll
                  next={() => {
                      getMore();
                  }}
                  hasMore={data.totalRecords > data.records.length}
                  loader={<Skeleton />}
                  dataLength={data.records.length}
                  scrollableTarget={randonId}
              >
                {data?.records?.map((item, key) => (
                  <ItemCard
                    key={item.id}
                    item={item}
                    getSubItems={getSubItems}
                  />
                ))}
              </InfiniteScroll>
          }

        </div>
    );
}

export default Categories;
