import Layout from 'components/layout/presentation/Layout'
import SaleNowPage from 'modules/Terminal/presentation/SaleNowPage'
import React from 'react'

export const SaleNow = () => {
    return (
        <Layout>
            <SaleNowPage />
        </Layout>
    )
}
