import React, { FC, useCallback, useState } from 'react'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import UseDeleteLinkCardsItem from 'modules/linkCards/presentation/hooks/UseDeleteLinkCardsItem';
import { ILinkCardItem } from 'modules/linkCards/presentation/interfaces/ILinkCardsList';


const UseLinkCardsActions = (getData: () => void, selectedLinkCard: ILinkCardItem[], setSelectedLinkCards: React.Dispatch<React.SetStateAction<ILinkCardItem[]>>) => {
    const { toast, showLoading, hideLoading } = useUi()
    const [deleteProductsModalOpen, setDeleteProductsModalOpen] = useState(false)

  const { deleteLinkCardsItem, isLoadingDelete, successDelete, deleteData } = UseDeleteLinkCardsItem();


    const onClickAction = useCallback(async () => {
        if (!selectedLinkCard.length) {
            toast("Selecione ao menos um produto", "error");
            return
        }
        setDeleteProductsModalOpen(true)
    }, [selectedLinkCard, toast])


    const onDeleteLinkCards = useCallback(async () => {
        if (!selectedLinkCard) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }
        showLoading();
        try {
            const tagsString = selectedLinkCard.map(x => x.tag).join(';');
            await deleteLinkCardsItem(tagsString)            
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [selectedLinkCard, showLoading, toast, deleteLinkCardsItem, hideLoading])

    return ({
        onClickAction,
        deleteProductsModalOpen,
        onDeleteLinkCards,
        isLoadingDelete,
        successDelete,
        deleteData,
        setDeleteProductsModalOpen
    })
}


export default UseLinkCardsActions