import React, { FC } from 'react'
import styles from './CustomButton.module.scss'
import { IconButton } from '@mui/material'
import { AddCircleOutlineOutlined, EditOutlined } from '@material-ui/icons';
export interface ICustomButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    title: string;
    children?: React.ReactNode;
    disableAdd?: boolean;
    onClickAdd: () => void;
    disableEdit?: boolean;
    onClickEdit: () => void;
}
const CustomButton: FC<ICustomButtonProps> = (props: ICustomButtonProps) => {
    return (
        <div id={styles.CustomButton}>
            <div className={styles.header}>
                <h2>{props.title}</h2>
                {!props.disableEdit && 
                    <IconButton onClick={props.onClickEdit} disabled={props.disableEdit}>
                        <EditOutlined />
                    </IconButton>
                }
                {!props.disableAdd && 
                    <IconButton onClick={props.onClickAdd} disabled={props.disableAdd}>
                        <AddCircleOutlineOutlined />
                    </IconButton>
                }
            </div>
            {!!props.children && <div className={styles.content}>
                {props.children}
            </div>}
        </div>
    )
}
export default CustomButton