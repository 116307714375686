import { AxiosInstance } from "axios"
import { IGetLojasResponse } from "modules/products/domain/dto/IGetLojasResponse"

const ObterLojas = async (api: AxiosInstance, localId: string): Promise<IGetLojasResponse> => {
    const response = await api.get<{ Name: string, Id: string }[]>("/store?localClienteId=" + localId)
    
    return {
        records: response.data.map(x => ({ name: x.Name, id: x.Id })),
        totalRecords: response.data.length,
    }
}
export default ObterLojas