import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { FC, useMemo } from "react";
import styles from "./AprovacaoTIDsHeader.module.scss";
import { IAprovacaoTIDsColumns } from "../../interfaces/IAprovacaoTIDsColumns";

export interface IAprovacaoTIDsHeader {
  columns: IAprovacaoTIDsColumns[];
}

const AprovacaoTIDsHeader: FC<IAprovacaoTIDsHeader> = ({
  columns,
}) => {
  const { isMobile } = useUi();

  const header = useMemo(
    () =>
      columns.map(
        (column, index) =>
          !column.hide && (
            <div key={index} className={styles.row} style={column.style}>
              <span className={styles.order}>
                {column.title}{" "}
              </span>
            </div>
          )
      ),
    [columns]
  );

  if (isMobile) {
    return <></>;
  }

  return (
    <div id={styles.AprovacaoTIDsHeader}>
      <div className={styles.container}>
        {header}
        <div className={styles.icon} style={{ flex: 0.2 }} />
      </div>
    </div>
  );
};

export default AprovacaoTIDsHeader;
