import React, { useCallback, useState } from 'react'
import styles from './LinkCardsPage.module.scss'
import Button from 'components/ui/Button/Button'
import { AddCircle } from '@mui/icons-material'
import { UseLinkCardsPage } from './UseLinkCardsPage'
import { LinkCardsList } from './linkCardsList/LinkCardsList'
import { LinkCardFilter } from '../components/linkCardsFilter/LinkCardFilter'
import LinkCardsActions from './linkCardsList/components/linkCardsActions/LinkCardsActions'
import AddLinkCards from './linkCardsList/components/addLinkCards/AddLinkCards'
import UseAddLinkCardsItem from '../hooks/UseAddLinkCardsItem'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'

const LinkCardsPage = () => {
  const [openSidesheet, setOpenSidesheet] = useState<'add' | null>(null);
  

  const {getLinkCardsList, selectedLinkCard, setAllSelected, allSelected, setSelectedLinkCards, isLoading, linkCardsList, onChangeFilterHandle, onChangePaginationHandle, params, setParams, onSelectLinkCardsHandle} = UseLinkCardsPage();

  const {addLinkCards, addLinkCardsItem, isLoadingAdd} = UseAddLinkCardsItem();


  const { hasAccessRole } = useAuth();


  const reload = useCallback(() => {
    getLinkCardsList({ ...params })
  }, [getLinkCardsList, params])

  return (
    <div id={styles.LinkCardsPage}>
      {hasAccessRole("PreAtivacaoCartoesCadastrar") && (           
        <div className={styles.buttonLinkCards}>
          {
            <Button onClick={() => setOpenSidesheet('add')}>
              Vincular cartões
              <AddCircle />
            </Button>
          }
        </div>
      )}

      <LinkCardFilter onSubmit={onChangeFilterHandle} />
         
      <LinkCardsActions setAllSelected={setAllSelected} reloadList={reload} selectedLinkCard={selectedLinkCard}  setSelectedLinkCards={setSelectedLinkCards}/>
      
      <LinkCardsList 
         linkCardsList={linkCardsList}
         isLoading={isLoading}
         onChangePaginationHandle={onChangePaginationHandle}
         params={params}
         setParams={setParams}
         open={openSidesheet === "add"}
         setOpenSidesheet={setOpenSidesheet}
         pagination={params?.pagination}
         reload={reload}
         setSelectedLinkCards={setSelectedLinkCards}
         onSelectLinkCardsHandle={onSelectLinkCardsHandle}
         setAllSelected={setAllSelected}
         allSelected={allSelected}
      />         
         
      <AddLinkCards setAllSelected={setAllSelected} open={openSidesheet} addLinkCards={addLinkCards}  setOpenSidesheet={setOpenSidesheet} addLinkCardsItem={addLinkCardsItem} isLoadingAdd={isLoadingAdd} reload={reload}/>
    </div>
  )
}

export default LinkCardsPage
