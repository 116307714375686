import { Dialog } from '@material-ui/core';
import { Close, Delete, Edit } from '@material-ui/icons';
import Button from 'components/ui/Button/Button';
import { Modal } from 'components/ui/modal/Modal';
import { EnumDurationType } from 'modules/schedule/domain/dto/serviceSchedule/EnumDurationType';
import React from 'react';
import { IServiceProductCard } from './interfaces/IServiceProductCard';
import styles from './ServiceProductCard.module.scss';
import { UseServiceProductCard } from './UseServiceProductCard';

interface ServiceProductCardProps {
    value: IServiceProductCard;
    refreshList: (currentLocal: string) => void
}

export const ServiceProductCard: React.FC<ServiceProductCardProps> = ({ value, refreshList }) => {


    const { handleModal, toggleModal, onClickServiceHandle, handleModalRemoveService, deleteService, toggleModalRemoveService } = UseServiceProductCard(refreshList);

    return (
        <div id={styles.ServiceProductCard}>
            <div className={styles.serviceProductCardContainer}>

                <div className={styles.image}>
                    {value.imageUrl && <img src={value.imageUrl} />}
                </div>
                <div className={styles.details}>
                    <div className={`${styles.inline} ${styles.title}`}>
                        <h3>{value.name}</h3>
                        <div>
                            <Edit onClick={() => onClickServiceHandle(value.id)} />
                            <Delete onClick={() => handleModalRemoveService()} />

                        </div>
                    </div>
                    {
                        value.durationType === EnumDurationType.Horas ?
                            <p>Duração: <span>{value.duration} horas</span></p>
                            :
                            <p>Duração: <span>{value.duration} minutos</span></p>

                    }
                    <div className={styles.otherValues}>
                        <Button onClick={handleModal} variant="text"><u>Visualizar lista de valores</u></Button>
                    </div>
                </div>
            </div>

            <Dialog fullWidth open={toggleModal} onClose={handleModal}>
                <div className={styles.containerModal}>
                    <div className={styles.containerTitle}>
                        <h1>Todos os valores</h1>
                        <Close onClick={handleModal} />
                    </div>
                    {
                        value.otherValues.map(otherValue => (
                            <div className={styles.modalOtherValues} key={value.id}>
                                <p>
                                    {otherValue.nome}:
                                    <span>{new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(otherValue.value)}</span>
                                </p>
                            </div>
                        ))}
                </div>
            </Dialog>

            <Modal toggleModal={handleModalRemoveService} onConfirm={() => deleteService(value.id)} open={toggleModalRemoveService} title="Deseja remover este serviço?">
                <p>Tem certeza que deseja excluír este serviço?</p>
                <p>Está ação não poderar ser desfeita!</p>
            </Modal>
        </div>

    )
}
