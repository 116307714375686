import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import GetStatusIfoodConfigurationUseCase from "modules/config/integrationsConfig/application/useCases/GetStatusIfoodConfigurationUseCase";
import PostIFoodStatusConfigUseCase from "modules/config/integrationsConfig/application/useCases/PostIFoodStatusConfigUseCase";
import { IIFoodStatusConfigRequest } from "modules/config/integrationsConfig/domain/dto/iFoodStatusConfig/iFoodStatusConfigRequest";
import { IGetStatusIfoodConfigurationResponse } from "modules/config/integrationsConfig/domain/dto/GetStatusIfoodConfigurationResponse";
import { IIFoodStatusConfigResponse } from "modules/config/integrationsConfig/domain/dto/iFoodStatusConfig/iFoodStatusConfigResponse";

const service = IntegrationApi();

export const UseConfigIFood = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getIFoodStatus, setGetIFoodStatus] =
    useState<IGetStatusIfoodConfigurationResponse>();
  const [postIFoodStatus, setPostIFoodStatus] =
    useState<IIFoodStatusConfigResponse>();
  const [values, setValues] = useState<IIFoodStatusConfigRequest>(
    {} as { id: string; isActive: boolean; merchantId: string }
  );
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getStatusIfoodConfiguration = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetStatusIfoodConfigurationUseCase(
          service,
          currentLocal?.id
        );
        setGetIFoodStatus(response);
        setValues({
          id: response.id,
          isActive: response.isActive,
          merchantId: response.merchantId,
        });

        return "ok";
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal]);

  const postIFoodStatusConfiguration = useCallback(async () => {
    try {
      setIsLoading(true);
      if(!values.id && currentLocal){
        values.id = currentLocal.id
      }
      const response = await PostIFoodStatusConfigUseCase(service, values);
      setPostIFoodStatus(response);
      setValues({
        id: response.id,
        isActive: response.isActive,
        merchantId: response.merchantId,
      });

      return "ok";
    } catch {
      toast("Ocorreu um erro. Tente novamente", "error");
    } finally {
      setIsLoading(false);
    }
  }, [values, toast, currentLocal]);

  const saveConfig = useCallback(() => {
    if (!values?.merchantId) return;
    if (
      values?.merchantId !== getIFoodStatus?.merchantId ||
      values?.isActive !== getIFoodStatus?.isActive
    )
      return postIFoodStatusConfiguration();
    else return getStatusIfoodConfiguration();
  }, [
    values.merchantId,
    values.isActive,
    getIFoodStatus,
    postIFoodStatusConfiguration,
    getStatusIfoodConfiguration,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetStatusIfoodConfigurationUseCase(
            service,
            currentLocal?.id
          );
          setGetIFoodStatus(response);
          setValues({
            id: response.id,
            isActive: response.isActive,
            merchantId: response.merchantId,
          });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentLocal]);

  return {
    isLoading,
    getIFoodStatus,
    postIFoodStatus,
    values,
    setValues,
    saveConfig,
  };
};
