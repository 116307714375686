import { IconButton } from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { format, parseISO } from "date-fns";
import { ITicketsValidationItem } from "modules/ticketsReport/presentation/interfaces/ITicketsValidation";
import { ITicketsValidationDetail } from "modules/ticketsReport/presentation/interfaces/ITicketsValidationDetail";
import { useState } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import styles from './ProductItem.module.scss'

interface ProductItemProps {
    getData: (ticketId: string) => Promise<ITicketsValidationDetail>
    item: ITicketsValidationItem
}

export const ProductItem = ({ getData, item }: ProductItemProps) => {
    const [showContent, setShowContent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [detailedItem, setDetailedItem] = useState<ITicketsValidationDetail>()
    const [hasError, setHasError] = useState(false);

    const handleOpenCard = async () => {
        setLoading(true)
        setHasError(false)

        try {
            const response = await getData(item?.ticketId)
            setDetailedItem(response)
        } catch {
            setHasError(true)
        } finally {
            setLoading(false)
        }

    }

    return (
        <div id={styles.ProductItem}>
            <div>
                <div>{item.productName}</div>
                <div>{format(parseISO(item.saleDate), "dd/MM' - 'HH:mm")}</div>
                <div>{item.validationDate ? format(parseISO(item.validationDate), "dd/MM' - 'HH:mm") : '-'}</div>
                <div>{item.customerName}</div>
                <div>** {item.customerCPF}</div>
                <div>{moneyMaskNumber(item.productValue)}</div>
                <div>
                    <IconButton onClick={() => { 
                        handleOpenCard();
                        setShowContent(prev => !prev)
                    }}>
                        {!showContent ? <KeyboardArrowDown htmlColor="#001537" /> : <KeyboardArrowUp htmlColor="#001537" />}
                    </IconButton>
                </div>
            </div>
            {
                showContent &&
                <>
                    {
                        loading ? 
                            <div className={styles.loading}>
                                <img className={styles.loader} src="/assets/img/loader.gif" alt="" />
                            </div>
                        : hasError ?
                            <div className={styles.loading}>
                                <ErrorMessage type="error" />
                            </div>
                        :
                            <div className={styles.subItem}>
                                <div className={styles.row}>
                                    <div className={styles.itemTitle}>
                                        <div>Categoria pai</div>
                                        <div>Categorias</div>
                                    </div>
                                    <div>
                                        <div>{detailedItem?.parentCategoryName || '-'}</div>
                                        <div>{detailedItem?.categoryName || '-'}</div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.itemTitle}>
                                        <div>Operador da venda</div>
                                        <div>Operador da pré-validação</div>
                                        <div>Operador da validação</div>
                                    </div>
                                    <div>
                                        <div>{detailedItem?.saleOperatorName || '-'}</div>
                                        <div>{detailedItem?.preValidationOperatorName || '-'}</div>
                                        <div>{detailedItem?.validationOperatorName || '-'}</div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.itemTitle}>
                                        <div>Equipamento de venda</div>
                                        <div>Equipamento de validação</div>
                                    </div>
                                    <div>
                                        <div>{detailedItem?.saleDeviceName || '-'}</div>
                                        <div>{detailedItem?.validationDeviceName || '-'}</div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            }
        </div>
                
    )
}