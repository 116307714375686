import Layout from "components/layout/presentation/Layout"
import { useEffect, useState } from "react";
import { BILandingPage } from "./lading/BILandingPage";
import BiPage from "./biPage/BiPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import PlansApi from "services/api/plans/PlansApi";
import { GetSubscriptionUseCase } from "modules/plans/application/GetSubscriptionUseCase";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { useParams } from "react-router-dom";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

const service = PlansApi();

export const BIPageContainer = () => {
    const [hasBI, setHasBI] = useState(false);
    const [loading, setLoading] = useState(true);
    const { currentLocal } = useLocal();
    const { id } = useParams<{ id: string }>();

    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "BI",
            },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

    useEffect(() => {
        if (currentLocal) {
            (async () => {
                try {
                    const subscription = await GetSubscriptionUseCase(service, currentLocal!.id);
                    setHasBI(!!subscription.additionalFeatures.find(x => x.featureTypeValue === FeatureTypeEnum.BI));
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [currentLocal])

    return (
        <Layout>
            {loading ?
                <div style={{ textAlign: 'center', marginTop: 60 }}>
                    <img src="/assets/img/loader.gif" alt="" style={{ width: 50 }} />
                </div> :
                !hasBI ?
                    <BILandingPage /> :
                    <BiPage powerBiFeatureId={id} />
            }
        </Layout>
    )
}