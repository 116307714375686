import Layout from "components/layout/presentation/Layout";
import CrmReportPage from "modules/crm/presentation/pages/report/CrmReportPage";
import React from "react";

export const Report = () => {
  return (
    <Layout>
      <CrmReportPage />
    </Layout>
  );
};
