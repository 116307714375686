import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { FC } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from './ConfigForm.module.scss';
import Input from 'components/ui/input/Input';
import { ITicketForm, ITicketFormErrors } from '../../ITicketForm';
import { FormControlLabel, IconButton, Radio, Switch } from '@material-ui/core';

interface IConfirmFormProps {
    errors: ITicketFormErrors;
    ticket: ITicketForm;
    eventName: string;
    onChangeTicketFormValue: (prop: string, value: any) => void;
    changeMaxQuantityPerUser: (sub?: boolean) => void;
}

export const ConfigForm: FC<IConfirmFormProps> = ({
    errors,
    ticket,
    eventName,
    onChangeTicketFormValue,
    changeMaxQuantityPerUser
}) => {
    return (
        <div id={styles.BaseForm}>
            <FormItemContainer label="Quantidade por cliente" className={styles.formItem}>
                <div className={styles.rangeContainer}>
                    <IconButton onClick={() => changeMaxQuantityPerUser(true)}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                    <div className={`${styles.input} ${styles.rangeInput}`}> {ticket.maxQuantityPerUser || 1} </div>
                    <IconButton onClick={() => changeMaxQuantityPerUser()}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </div>
            </FormItemContainer>

            <FormItemContainer label={'Tipo do ingresso'} className={`${styles.formItem} ${styles.radioContainer}`}>
                <FormControlLabel
                    value={ticket.type}
                    control={<Radio />}
                    label={'Nome na lista'}
                    checked={!ticket.type || ticket.type === 15}
                    onChange={(_, checked) => {
                        if (checked) {
                            onChangeTicketFormValue('type', 15)
                        }
                    }}
                />
                <FormControlLabel
                    value={ticket.type}
                    control={<Radio />}
                    label={'Avulso'}
                    checked={ticket.type === 16}
                    onChange={(_, checked) => {
                        if (checked) {
                            onChangeTicketFormValue('type', 16)
                        }
                    }}
                />
            </FormItemContainer>


            <FormItemContainer className={styles.formItem}>
                <FormControlLabel
                    value={ticket.adultHood}
                    control={<Switch />}
                    label={'Exclusivo para maiores de 18 anos?'}
                    checked={ticket.adultHood}
                    onChange={(_, checked) => {
                        onChangeTicketFormValue('adultHood', checked)
                    }}
                />
            </FormItemContainer>

            <FormItemContainer className={styles.formItem}>
                <FormControlLabel
                    value={ticket.hasExpireTime}
                    control={<Switch />}
                    label={'Tempo limite para check-in após a compra do ingresso?'}
                    checked={ticket.hasExpireTime}
                    onChange={(_, checked) => {
                        onChangeTicketFormValue('hasExpireTime', checked)
                    }}
                />
            </FormItemContainer>

            {
                ticket.hasExpireTime &&
                <FormItemContainer label={'Limite para check-in (min)'} className={styles.formItem}>
                    <Input
                        name="nome"
                        variant='outlined'
                        value={ticket.expireTime}
                        className={styles.input}
                        inputProps={{ maxLength: 3 }}
                        onChange={evt => onChangeTicketFormValue('expireTime', evt.target.value)}
                        placeholder="Ex.: 60min"
                    />
                </FormItemContainer>
            }

        </div>
    );
};
