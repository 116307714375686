import { IEditMassiveDeviceProfileResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/editMassiveDeviceProfile/IEditMassiveDeviceProfileResponse";
import { IMassiveDeviceProfileValue } from "modules/equipamento/presentation/components/deviceProfileForm/IMassiveDeviceProfileValue";
import { IDeviceProfileService } from "../../../domain/interface/IDeviceProfileService";

const EditMassiveDeviceProfileUseCase = async (profileId: string, localId: string, profileService: IDeviceProfileService, values: IMassiveDeviceProfileValue[]): Promise<IEditMassiveDeviceProfileResponse> => {
    const reponse = await profileService.editMassiveDeviceProfile(localId, profileId, { devices: values.map(item => ({ id: item.id, enabled: item.enabled })) })

    return reponse;
}

export default EditMassiveDeviceProfileUseCase