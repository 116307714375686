import { FC } from 'react'
import { ErrorProvider } from './contexts/error/ErrorContext'
import SalesDashboardPage from './SalesDashboardPage'

const SalesDashboardPageContainer: FC = () => {
    return (
        <ErrorProvider>
            <SalesDashboardPage />
        </ErrorProvider>
    )
}

export default SalesDashboardPageContainer