import { useCallback, useEffect, useState } from "react";
import PlansApi from "services/api/plans/PlansApi";
import { usePlans } from "../../hooks/PlansContext";
import { IPlans } from "../../interfaces/IPlans";
import { ILocalPlans, ILocalPlansItem } from "../../interfaces/ILocalPlans";
import { GetLocalPlansUseCase } from "modules/plans/application/GetLocalPlansUseCase";
import { SubscribePlanUseCase } from "modules/plans/application/SubscribePlanUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { SubscribeFeatureUseCase } from "modules/plans/application/SubscribeFeatureUseCase";
import { IDowngrade } from "../../interfaces/IDowngrade";
import { GetDowngradeSolicitationsUseCase } from "modules/plans/application/GetDowngradeSolicitationsUseCase";

const service = PlansApi();

type IFilterType = { planId?: string, keyword?: string, hasDowngradeSolicitation?: boolean };

export const UseConfigPlans = () => {
  const { getPlans, } = usePlans();
  const { toast } = useUi();

  const [plans, setPlans] = useState<IPlans[]>();
  const [historyModalOpened, setHistoryModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [selectedLocalPlan, setSelectedLocalPlan] = useState<ILocalPlansItem>();
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [localPlans, setLocalPlans] = useState<ILocalPlans>();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downgradeSolicitations, setDowngradeSolicitations] = useState<IDowngrade[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<IFilterType>({
    planId: undefined,
    keyword: '',
    hasDowngradeSolicitation: false
  });

  useEffect(() => {
    (async () => {
        const promises: [Promise<IPlans[] | undefined>, Promise<IDowngrade[]>] = [
          getPlans(),
          GetDowngradeSolicitationsUseCase(service)
        ]

        const planResponse = await promises[0];
        setPlans(planResponse);

        const downgradeResponse = await promises[1];
        setDowngradeSolicitations(downgradeResponse);
    })();
  }, [getPlans, toast]);

  const getData = useCallback(async (page?: number, _filter?: IFilterType) => {
    try {
      setLoading(true);
      const response = await GetLocalPlansUseCase(service, page || 1, _filter?.planId, _filter?.keyword, _filter?.hasDowngradeSolicitation);
      setLocalPlans(response);
      setCurrentPage(page || 1);
    } catch {
      toast('Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    getData(1);
  }, [getData]);

  const handleChangePlan = async (planId?: string, additionalFeatures?: string[]) => {
    const getToken = localStorage.getItem("@token");
    const token = getToken && JSON.parse(getToken);
    const user = JSON.parse(token.user)

    setSaving(true);
    if (planId) {
      try {
        await SubscribePlanUseCase(service, user.cpf, selectedLocalPlan!.ownerId, planId, additionalFeatures, selectedLocalPlan?.downgradeSubscriptions?.id);
      } catch {
        toast('Ocorreu um erro ao alterar o plano.', 'error');
      } finally {
        setSaving(false);
      }
    } else {
      try {
        const promises: Promise<void>[] = [];

        additionalFeatures?.forEach(async (x) => {
          promises.push(SubscribeFeatureUseCase(service, user.cpf, selectedLocalPlan!.ownerId, x));
        });

        await Promise.all(promises);
      } catch {
        toast('Ocorreu um erro ao contratar as novas features.', 'error');
      } finally {
        setSaving(false);
      }
    }
    setSuccessModalOpened(true);
    setEditModalOpened(false);
  }

  return {
    plans,
    historyModalOpened,
    selectedLocalPlan,
    editModalOpened,
    localPlans,
    downgradeSolicitations,
    saving,
    currentPage,
    loading,
    filter,
    successModalOpened,
    setSuccessModalOpened,
    setFilter,
    setEditModalOpened,
    setHistoryModalOpened,
    setSelectedLocalPlan,
    handleChangePlan,
    getData,
  }
}
