import { useCallback, useEffect, useState } from "react";
import UsePrintQueueList, { IParamsPrintQueueList } from "../hooks/UsePrintQueueList";
import UseEquipamentsPrintQueue from "../hooks/UseEquipamentsPrintQueue";
import UsePrinterList from "../hooks/UsePrinterList";
import { IPrintQueueFilter } from "../components/printQueueFilter/interfaces/IPrintQueueFilter";
import UseTableList from "../hooks/UseTableList";
import UseOperatorList from "../hooks/UseOperatorList";


export const UsePrintQueuePage = () => {
    const [params, setParams] = useState<IParamsPrintQueueList>();

    const { printQueueList, getPrintQueueList, isLoading } = UsePrintQueueList();    

    const {equipamentPrintQueueList, getEquipmentsPrintQueueList} = UseEquipamentsPrintQueue();

    const {getTableList, isLoadingTable, tableList} = UseTableList();

    const {getOperatorList, operatorList} = UseOperatorList();

    const { getPrinterList, printerList } = UsePrinterList();

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IPrintQueueFilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 1, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getPrintQueueList({ ...params });
    }, [getPrintQueueList, params]);
    
    useEffect(() => {
        getEquipmentsPrintQueueList();
    }, [getEquipmentsPrintQueueList]);

    useEffect(() => {
        getPrinterList();
    }, [getPrinterList]);

    useEffect(() => {
        getTableList();
    }, [getTableList]);

    useEffect(() => {
        getOperatorList();
    }, [getOperatorList]);

    return (
        {
            printQueueList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getPrintQueueList,
            equipamentPrintQueueList,
            printerList,
            getTableList,
            tableList,
            isLoadingTable,
            operatorList
        }
    )
}
