import { Autocomplete, TextField } from "@mui/material";
import styles from "../menuPai/MenuPai.module.scss";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";
import { IPostCreateMenuPayload, MenuTypeEnum } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";
import { useEffect, useState } from "react";
import UseSelectParentId from "../../../hooks/UseSelectParentId";
import { IGetMenuListResponse } from "modules/admin/menuConfig/domain/dto/IGetMenuListResponse";
import { IGetMenuList } from "../AddMenuSidesheet";
import { IPutEditMenuPayload } from "modules/admin/menuConfig/domain/dto/IPutEditMenuPayload";
import { removeAccentsAndSpace } from "services/utils/String";
import { Switch } from "@material-ui/core";

interface IMenuNetoProps {
    menuNetoValues: IPostCreateMenuPayload;
    setMenuNetoValues: React.Dispatch<React.SetStateAction<IPostCreateMenuPayload>>;
    errors: string | undefined;
    setErrors: React.Dispatch<React.SetStateAction<string | undefined>>;
    menuNetoEditItem?: IPutEditMenuPayload;
    defaultPlans?: IPlans[];
    menuList: IGetMenuList[];
}

const MenuNeto = ({ menuNetoValues, setMenuNetoValues, errors, setErrors, menuNetoEditItem, defaultPlans, menuList }: IMenuNetoProps) => {
    const [role, setRole] = useState("VisualizarMenu");
    const [menuPai, setMenuPai] = useState<IGetMenuListResponse | null>(null);

    const { parentId, parentTitle } = UseSelectParentId();

    useEffect(() => {
        if (parentId) {
            setMenuNetoValues({...menuNetoValues, parentId: parentId });
            findMenuWithId(parentId);
        }
    }, [parentId, setMenuNetoValues]);

    useEffect(() => {
        if (menuNetoEditItem?.title) {
            setMenuNetoValues({
                title: menuNetoEditItem.title,
                description: menuNetoEditItem.description,
                role: menuNetoEditItem.role,
                icon: menuNetoEditItem.icon,
                route: menuNetoEditItem.route,
                menuConfigurationId: menuNetoEditItem.menuConfigurationId,
                positionType: menuNetoEditItem.positionType,
                parentId: menuNetoEditItem.parentId,
                planIds: menuNetoEditItem.planIds,
                type: menuNetoEditItem.type,
            });
            setRole(menuNetoEditItem.role);
        }
    }, [menuNetoEditItem, setMenuNetoValues]);

    const findMenuWithId = (idToFind: string) => {
        let findMenu: IGetMenuListResponse | null = null;
    
        const findMenuRecursive = (menus: IGetMenuListResponse[], idPath: IGetMenuListResponse[] = []): boolean => {
            for (const menu of menus) {                
                const currentPath = [...idPath, menu];
    
                if (menu.id === idToFind) {
                    findMenu = currentPath[0];
                    return true;
                }
    
                if (menu.submenus && menu.submenus.length > 0) {
                    const found = findMenuRecursive(menu.submenus, currentPath);
                    if (found) return true;
                }
            }
    
            return false;
        };
    
        menuList.some(item => {
            return findMenuRecursive(item.menus);
        });
        
        setMenuPai(findMenu);
    }
    
    return (
        <div id={styles.MenuNeto}>
            <div className={styles.input}>
                <label htmlFor="menu-pai">
                    Menu pai
                </label>
                <TextField
                    name="menu-pai"
                    variant="outlined"
                    size="small"
                    value={menuPai?.title}
                    disabled
                    className={styles.disabled}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="menu-filho">
                    Menu filho
                </label>
                <TextField
                    name="menu-filho"
                    variant="outlined"
                    size="small"
                    value={parentTitle}
                    disabled
                    className={styles.disabled}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="name" className={styles.required}>
                    Nome
                </label>
                <TextField
                    name="name"
                    variant="outlined"
                    size="small"
                    value={menuNetoValues.title}
                    onChange={(ev) => {
                        setMenuNetoValues({
                            ...menuNetoValues,
                            title: ev.target.value,
                            role: `VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`,
                            description: `Visualizar Menu ${ev.target.value}`,
                        });
                        setRole(`VisualizarMenu${removeAccentsAndSpace(ev.target.value)}`);
                        setErrors(undefined);
                    }}
                    error={!!errors}
                    helperText={errors}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="permission">
                    Permissão
                </label>
                <TextField
                    name="permission"
                    variant="outlined"
                    size="small"
                    value={role}
                    disabled
                    className={styles.disabled}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="permission-name">
                    Nome da permissão
                </label>
                <TextField
                    name="permission-name"
                    variant="outlined"
                    size="small"
                    value={menuNetoValues.description}
                    onChange={(ev) => setMenuNetoValues({...menuNetoValues, description: ev.target.value })}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="plans">
                    Planos
                </label>
                <Autocomplete
                    options={defaultPlans ?? []}
                    getOptionLabel={option => option.name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Selecione os planos"
                            InputProps={{
                                style: { padding: '0 20px 0 10px' },
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const plansIds = values.map((it) => it.id);
                        setMenuNetoValues({...menuNetoValues, planIds: plansIds});
                    }}
                />
            </div>
            <div className={styles.input}>
                <label htmlFor="url">
                    URL
                </label>
                <TextField
                    name="url"
                    variant="outlined"
                    size="small"
                    value={menuNetoValues.route}
                    onChange={(ev) => {
                        setMenuNetoValues({...menuNetoValues, route: ev.target.value })
                    }}
                />
            </div>
            <div className={`${styles.input} ${styles.switch}`}>
                <Switch
                    checked={menuNetoValues.type === MenuTypeEnum.PREMIUM}
                    onChange={(_, value: boolean) => setMenuNetoValues({ ...menuNetoValues, type: value ? MenuTypeEnum.PREMIUM : MenuTypeEnum.STANDARD })}
                    id="display-crown-icon" />
                <label htmlFor="display-crown-icon">Exibir ícone de coroa</label>
            </div>  
        </div>
    )
}

export default MenuNeto;