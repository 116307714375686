import { IGetOperatorByLocalIdResponse } from "modules/saleForProduct/domain/dto/IGetOperatorByLocalIdResponse";
import { api } from "../Api";
import { IPostSaleForProductParams } from "modules/saleForProduct/domain/dto/IPostSaleForProductParams";
import { IPostSaleForProductResponse } from "modules/saleForProduct/domain/dto/IPostSaleForProductResponse";
import { ISaleForProductReportService } from "modules/saleForProduct/domain/interface/ISaleForProductReportService";
import { IGetCashlessTagsResponse } from "modules/saleForProduct/domain/dto/IGetCashlessTagsResponse";

const SaleForProductReportApi = (): ISaleForProductReportService => {
  const postSaleForProduct = async (params: IPostSaleForProductParams): Promise<IPostSaleForProductResponse> => {
      const respomse = await api.post<IPostSaleForProductResponse>(`/Relatorio/BuscarRelatorioVendasProdutos`, params);
      return respomse.data;
  }

  const getOperatorByLocalId = async (localId: string): Promise<IGetOperatorByLocalIdResponse[]> => {
      const respomse = await api.get<IGetOperatorByLocalIdResponse[]>(`/Operador/BuscarOperadoresParaFiltrosPorLocalId/${localId}`);
      return respomse.data;
  }

  const getCashlessTags = async (localId: string): Promise<IGetCashlessTagsResponse[]> => {
      const respomse = await api.get<IGetCashlessTagsResponse[]>(`/tags/cashless?incluirProdutos=false&localClienteId=${localId}`);
      return respomse.data;
  }

  return { postSaleForProduct, getOperatorByLocalId, getCashlessTags };
}

export default SaleForProductReportApi;
