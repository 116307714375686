import { IRankingOperatorsItemResponse, IRankingOperatorsResponse } from "modules/salesDashboard/domain/dtos/rankingOperators/IRankingOperatorsResponse";
import { GetRankingProductsResponse, IRankinProductResponse } from "modules/salesDashboard/domain/dtos/rankingProducts/GetRankingProductsResponse";
import { v4 } from "uuid";
import { ISalesBar } from "../../../../modules/salesDashboard/presentation/components/salesBarChart/interfaces/SalesBarData";
import { faker } from '@faker-js/faker';
import { GetCardValuesResponse } from "modules/salesDashboard/domain/dtos/cardValues/GetCardValuesResponse";
import { IRequestParams } from "modules/salesDashboard/domain/dtos/RequestParams";
import { ILocal } from "modules/local/domain/models/ILocal";
import { variedColors } from "components/graphs/utils/colors";
import { CategoryRankingResponse } from "modules/salesDashboard/domain/dtos/rankingCategory/CategoryRankingResponse";
import { IRankingStoresResponse } from "modules/salesDashboard/domain/dtos/rankingStores/GetRankingStoresResponse";
import { GetRankingDeviceSalesResponse } from "modules/salesDashboard/domain/dtos/deviceSales/GetDeviceSalesResponse";

const localsDataString = localStorage.getItem('@locals')
const locals: ILocal[] = localsDataString ? JSON.parse(localsDataString) : [];

const getLocalColor = (selectedLocals: string[], localId: string) => {
    return variedColors[selectedLocals.indexOf(localId) || 0];
}

export const getSalesMock = async (request: IRequestParams): Promise<ISalesBar> => {

    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 1000))

    await delay
    return ({
        totalRecords: 10,

        records: [
            {
                date: "Sexta",
                consumerInfo: request.selectedPlaces.map((item, index) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            },
            {
                date: "Sabado",
                consumerInfo: request.selectedPlaces.map((item, index) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            },
            {
                date: "Domingo",
                consumerInfo: request.selectedPlaces.map((item, index) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            },
            {
                date: "Segunda",
                consumerInfo: request.selectedPlaces.map((item) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            },
            {
                date: "05/09",
                consumerInfo: request.selectedPlaces.map((item) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            },
            {
                date: "06/09",
                consumerInfo: request.selectedPlaces.map((item) => ({
                    placeId: item,
                    placeName: locals?.find(x => x.id === item)?.name || '',
                    color: faker.color.rgb().replace("#",""),
                    consumerValues: [
                        { label: "Almoço", value: Math.random() * 500, key: "" },
                        { label: "Happy Hour", value: Math.random() * 500, key: "" },
                        { label: "Janta", value: Math.random() * 500, key: "" }
                    ],
                    total: Math.random() * 500,
                    average: Math.random() * 500
                })) ?? []
            }
        ]
    })
}




export const getProductsRankingMock = async (request: IRequestParams): Promise<GetRankingProductsResponse> => {

    const response: IRankinProductResponse[] | undefined = request.selectedPlaces.map<IRankinProductResponse[]>((local, index) => ([
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: "",
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 1,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 2,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 3,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 3,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 3,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 3,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        },
        {
            imageUrl: "",
            id: v4(),
            placeId: "",
            placeName: '',
            color: getLocalColor(request.selectedPlaces, local),
            name: "produto " + locals?.find(x => x.id === local)?.name || '',
            total: faker.datatype.float(),
            categoryName: "Categoria",
            categoryId: '',
            quantity: (index + 1) + 1 * 3,
            subItems: [
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: "",
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryId: "Categoria",
                    categoryName: "Categoria",
                    quantity: (index + 1) + 1 * 2,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
                {
                    imageUrl: "",
                    id: v4(),
                    placeId: "",
                    placeName: '',
                    color: getLocalColor(request.selectedPlaces, local),
                    name: "produto " + locals?.find(x => x.id === local)?.name || '',
                    total: faker.datatype.float(),
                    categoryName: "Categoria",
                    categoryId: '',
                    quantity: (index + 1) + 1 * 3,
                },
            ]
        }
    ])).flatMap(item => item).sort((a, b) => (a.total - b.total))

    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 2000))

    await delay

    return { records: response ?? [], totalRecords: 100 }

}

export const getOperatorRakingMock = async (request: IRequestParams): Promise<IRankingOperatorsResponse> => {
    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 3000))

    await delay

    const response = request.selectedPlaces.map<IRankingOperatorsItemResponse[]>(item => ([
        {
            placeId: item,
            operatorId: faker.datatype.uuid(),
            operatorName: faker.name.firstName(),
            color: faker.color.rgb().replace("#",""),
            imgUrl: faker.image.imageUrl(),
            totalConsumption: Number(faker.finance.amount()),
            totalCharged: Number(faker.finance.amount())
        },
        {
            placeId: item,
            operatorId: faker.datatype.uuid(),
            operatorName: faker.name.firstName(),
            color: faker.color.rgb().replace("#",""),
            imgUrl: faker.image.dataUri(),
            totalConsumption: Number(faker.finance.amount()),
            totalCharged: Number(faker.finance.amount())
        },
        {
            placeId: item,
            operatorId: faker.datatype.uuid(),
            operatorName: faker.name.firstName(),
            color: faker.color.rgb().replace("#",""),
            imgUrl: faker.image.imageUrl(),
            totalConsumption: Number(faker.finance.amount()),
            totalCharged: Number(faker.finance.amount())
        }]
    )).flatMap(item => item).sort((a, b) => (a.totalCharged - b.totalCharged))

    return { records: response ?? [], totalRecords: 50 }
}


export const getCardValuesMock = async (request: IRequestParams): Promise<GetCardValuesResponse> => {
    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 3000))

    await delay

    const response: GetCardValuesResponse = {
        received: {
            consumed: faker.datatype.float(),
            serviceFee: faker.datatype.float(),
            received: faker.datatype.float(),
        },
        values: {
            consumed: faker.datatype.float(),
            opened: faker.datatype.float()

        },
        avergeTickets: {
            tables: 26,
            consumedTable: faker.datatype.float(),
            curtomers: 500,
            consumedCustomers: faker.datatype.float()
        }
    }

    return response
}



export const getCategoryRankingMock = async (): Promise<CategoryRankingResponse> => {
    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 2000))

    await delay

    const response: CategoryRankingResponse = {
        records: [
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.commerce.productMaterial(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.commerce.productMaterial(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },

        ],
        totalRecords: 100,
    }

    return response
}
export const getStoreRankingMock = async (): Promise<IRankingStoresResponse> => {
    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 2000))

    await delay

    const response: CategoryRankingResponse = {
        records: [
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.commerce.productMaterial(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.commerce.productMaterial(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },
            {
                id: faker.datatype.uuid(),
                name: faker.commerce.productMaterial(),
                color: faker.color.rgb().replace("#",""),
                placeId: faker.commerce.productMaterial(),
                total: Number(faker.commerce.price())
            },

        ],
        totalRecords: 100,
    }

    return response
}


export const getDeviceSalesMock = async (): Promise<GetRankingDeviceSalesResponse> => {
    const delay = new Promise(
        (resolve, reject) => setTimeout(() => {
            resolve("")
        }, 2000))

    await delay;

    const response: GetRankingDeviceSalesResponse = {
        records: [
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },
            {
                placeId: '',
                color: '',
                deviceId: faker.datatype.uuid(),
                deviceName: faker.commerce.productMaterial(),
                totalConsumption: Number(faker.commerce.price())
            },

        ],
        totalRecords: 30
    }
    return response
}