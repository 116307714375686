import { IPostSaleForProductParams } from "../domain/dto/IPostSaleForProductParams";
import { IPostSaleForProductResponse } from "../domain/dto/IPostSaleForProductResponse";
import { ISaleForProductReportService } from "../domain/interface/ISaleForProductReportService";


const PostSaleForProductUseCase = async (service: ISaleForProductReportService, params: IPostSaleForProductParams): Promise<IPostSaleForProductResponse> => {
  const response = service.postSaleForProduct(params);
  return response;
};

export default PostSaleForProductUseCase;
