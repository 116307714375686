import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PrintQueueApi from "services/api/printQueue/PrintQueueApi";
import { ITableList } from "../interfaces/ITableList";
import GetTableListUseCase from "modules/printQueue/application/useCases/GetTableListUseCase";


const UseTableList = () => {

  const [isLoadingTable, setIsLoadingTable] = useState<boolean>();
  const [tableList, setTableList] = useState<ITableList[]>([])
  const { currentLocal } = useLocal();

  const getTableList = useCallback(
    async () => {
      try {
        const service = PrintQueueApi();
        setIsLoadingTable(true);
        if (currentLocal) {
          const response = await GetTableListUseCase(service,
            currentLocal.id
          );

          setTableList(([...response]));

          return response
        }
      } finally {
        setIsLoadingTable(false);
      }
    },
    [currentLocal]
  );


  return {
    tableList,
    isLoadingTable,
    getTableList,
    setTableList,
  };
};

export default UseTableList;
