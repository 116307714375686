import React, { FC, useCallback } from "react";
import { Button } from "@mui/material";
import { InputContainer } from "../../../_inputContainer/InputContainer";
import { EnumGender } from "../../../../intefaces/GenderEnum";
import styles from './InputGender.module.scss'
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";


export const InputGender: FC<{ value?: EnumGender; onChange: (value?: EnumGender) => void; }> = ({ value, onChange }) => {
  const { checkPermission } = usePlans();

  const onChangeHandle = useCallback((_value: EnumGender) => {
    if (value !== _value) {
      onChange(_value);
    } else {
      onChange(undefined);
    }
  }, [onChange, value]);

  return <InputContainer flex={1}>
    <div id={styles.InputGender}>
      <label className={!checkPermission(FeatureTypeEnum.CRM) ? "premiumLabel" : ''} style={{ minHeight: "1em", fontSize: 14, color: !checkPermission(FeatureTypeEnum.CRM) ? '#A2A2A2' : '#333', lineHeight: '150%' }}>Sexo</label>
      <div className={styles.buttonContainer}>
        <Button disabled={!checkPermission(FeatureTypeEnum.CRM)} className={`${styles.button} ${value === EnumGender.male ? styles.selected : ''}`} size="small" style={{ backgroundColor: value === EnumGender.male ? '#F2F8FF' : '#FFF', border: '1px solid #B3C2D3' }} onClick={() => onChangeHandle(EnumGender.male)}>Masculino</Button>
        <Button disabled={!checkPermission(FeatureTypeEnum.CRM)} className={`${styles.button} ${value === EnumGender.female ? styles.selected : ''}`} size="small" style={{ backgroundColor: value === EnumGender.female ? '#FFE5EA' : '#FFF', border: '1px solid #FF8A9E' }} onClick={() => onChangeHandle(EnumGender.female)}>Feminino</Button>
        {/* <Button className={styles.button} size="small" variant={value === EnumGender.unknow ? "contained" : "outlined"} onClick={() => onChangeHandle(EnumGender.unknow)}>Desconehcido</Button> */}
      </div>
    </div>
  </InputContainer>;
};
