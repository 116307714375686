import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const DeleteGuestUseCase = async (
  guestsService: IEventsGuestsApi,
  guestId: string,
  localId: string,
): Promise<void> => {
  return await guestsService.deleteGuestFromList(guestId, localId);
};

export default DeleteGuestUseCase;
