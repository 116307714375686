import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason, AutocompleteGetTagProps, AutocompleteInputChangeReason, AutocompleteRenderOptionState, FilterOptionsState } from "@material-ui/lab";
import React, { ChangeEvent, FC, PropsWithChildren } from "react";
import style from './AutoComplete.module.scss'

const useStyles = makeStyles({
  root: {
    "& .MuiAutocomplete-tagSizeSmall": {
      background: "transparent",
      border: "1px solid #BDBDBD",
      borderRadius: "13px",
      color: "var(--text)",
      "& svg": {
        "& path": {
          d: "path('M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z')"
        }
      }
    }
  }
});

interface IAutoCompleteProps<T = any> {
  value?: NonNullable<T> | T[];
  inputRef?: React.Ref<any>
  defaultValue?: NonNullable<T> | T[];
  name?: string;
  label?: string;
  onChange: (event: ChangeEvent<{}>, value: string | NonNullable<T> | (string | T)[], reason: AutocompleteChangeReason) => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  renderOption?: ((option: T, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined
  options: T[];
  getOptionLabel?: (option: T) => string;
  multiple?: boolean;
  getOptionSelected?: (option: T, value: T) => boolean;
  noOptionsText?: string;
  disabled?: boolean;
  variant?: "filled" | "outlined" | "standard";
  filterSelectedOptions?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  limitTags?: number;
  filterOptions?: ((options: T[], state: FilterOptionsState<T>) => T[]);
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  className?: string;
  renderTags?: (value: T[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
}

const AutoComplete = <T,>({
  value,
  defaultValue,
  onChange,
  onInputChange,
  options,
  renderOption,
  filterSelectedOptions,
  getOptionLabel,
  multiple,
  name,
  getOptionSelected,
  noOptionsText,
  disabled,
  variant = "standard",
  required,
  label,
  error,
  helperText,
  limitTags,
  filterOptions,
  disableCloseOnSelect,
  placeholder,
  className,
  inputRef,
  renderTags
}: PropsWithChildren<IAutoCompleteProps<T>>) => {

  const CustomTextField = (props: any) => {
    const classes = useStyles();
    return <TextField {...props} className={classes.root}/>
  }

  return (
    <Autocomplete
      disableCloseOnSelect={disableCloseOnSelect}
      filterOptions={filterOptions}
      limitTags={limitTags}
      options={options}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      getOptionSelected={getOptionSelected}
      fullWidth
      className={className}
      filterSelectedOptions={filterSelectedOptions}
      multiple={multiple}
      disableClearable
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      renderOption={renderOption}
      disabled={disabled}
      onInputChange={onInputChange}
      classes={{ popper: style.popper }}
      size={"small"}
      renderInput={(params) => (
        <CustomTextField
          placeholder={placeholder}
          inputRef={inputRef}
          color={"secondary"}
          label={label}
          required={required}
          variant={variant}
          name={name}
          error={error}
          helperText={helperText}
          {...params}
        ></CustomTextField>
      )}
    />
  );
};
export default AutoComplete;
