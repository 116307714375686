import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import GetProductsUseCase from "modules/events/application/useCases/discounts/GetProductsUseCase";
import { IDiscountListResponse } from "modules/events/domain/dto/IEventsResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback, useEffect, useState } from "react";
import { EventsDiscountsApi } from "services/api/events/discounts/EventsDiscountsApi";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import {
  IDiscountListForm,
  IDiscountListSubmitForm,
  IDiscountProductType,
} from "../discountForm/interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "../discountPeopleListForm/interfaces/IProductList";
import { IDiscountList } from "./interfaces/DiscountListForm.types";
import { IDiscountListFormErros } from "./validation/interface/IDiscountListFormValidationErros";
import { validate } from "./validation/DiscountListFormValidation";

interface IUseDiscountListForm {
  productList: IProductItemToDiscount[] | undefined
  handleDiscountChange: (value: IDiscountListForm[]) => void
  handleSubmitForm: (evt: React.SyntheticEvent) => void
  handleChangeDescription: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChangeName: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  discountList: IDiscountListForm[];
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  handleChange: (value: number) => void;
  errors: IDiscountListFormErros
}

const UseDiscountListForm = (handleSubmit: (list: IDiscountListSubmitForm) => void): IUseDiscountListForm => {
  const { toast, showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const discountsService = EventsDiscountsApi();
  const baseListService = DiscountBaseListApi();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [productList, setProductList] = useState<IProductItemToDiscount[]>();
  const [discountList, setDiscountList] = useState<IDiscountListForm[]>([]);
  const [listDiscount, setListDiscount] = useState<IDiscountListResponse[]>()
  const [index, setIndex] = useState(0)
  const [errors, setErrors] = useState<IDiscountListFormErros>({});


  useEffect(() => {
    const getProductsUseCase = async (localId: string) => {
      showLoading();
      try {
        const response = await GetProductsUseCase(discountsService, localId);
        setProductList(response);
      } finally {
        hideLoading();
      }
    };

    if (currentLocal) {
      getProductsUseCase(currentLocal.id);
    }
  }, [currentLocal]);

  useEffect(() => {
    const getDiscountsList = async (localId: string) => {
      showLoading();
      try {
        if (currentLocal) {
          const response = await GetBaseLIstUseCase(baseListService, localId);
          setListDiscount(response);

        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoading();
      }
    };
    if (currentLocal) {
      getDiscountsList(currentLocal.id);
    }
  }, [currentLocal]);

  const handleChangeName = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    setName(value);
  };

  const handleChangeDescription = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    setDescription(value);
  };

  const handleSubmitForm = useCallback(
    (evt: React.SyntheticEvent) => {
      evt.preventDefault();

      const discounts: IDiscountList[] = discountList.map((item) => {
        return {
          priceDiscount: item.type === IDiscountProductType.money ? { price: item.discount } : undefined,
          percentualDiscount: item.type === IDiscountProductType.percentual ? { percentual: item.discount } : undefined,
          productId: item.product.id,
        };
      });

      let existedName = listDiscount?.findIndex(item => item.name === name);

      if (existedName && existedName <= -1) {
      
          if (validate(name, description, discounts, setErrors, setIndex)) {
            handleSubmit({ name, description, discounts });
          
        } else {
          toast("Prencha todos os dados para continuar", "error");
        }
      } else {
        toast("Nome já utilizado", "error");

      }


    },
    [discountList, listDiscount, name, description, handleSubmit, toast]
  );

  const handleChange = useCallback((value: number) => {
    setIndex(value)
  }, []);

  const handleDiscountChange = (value: IDiscountListForm[]) => {
    setDiscountList(value);
  };

  return {
    discountList,
    handleChangeName,
    handleChangeDescription,
    handleSubmitForm,
    handleDiscountChange,
    productList,
    handleChange,
    index,
    setIndex,
    errors
  };
};

export default UseDiscountListForm;
