
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./PrintQueue.module.scss";
import { IPrintQueueColumnsConfig } from "../interfaces/IColumnsConfig";

interface PrintQueueHeaderProps {
  columns: IPrintQueueColumnsConfig[],
}


const PrintQueueHeader: FC<PrintQueueHeaderProps> = ({ columns }) => {




  const header = useMemo(() => columns.map((column, index) =>
    !column.hide && <div
      key={index}
      className={styles.row}
      style={column.style}
    >
      <span className={styles.order}>{column.title} </span>
    </div>
  ), [columns])

  return (
    <div id={styles.PrintQueueHeaderContainer}>
      <div className={styles.container}>      
        {header}
        <div className={styles.icon}></div>     
      </div>
    </div>
  );
};

export default PrintQueueHeader;
