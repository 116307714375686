import GetClientListUseCase from "modules/crm/application/useCases/getClientListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import { CrmExportType, ICrmFilterValue } from "../components/crmFilterForm/interfaces/ICrmFilterValue";
import { IGetClientsResponse } from "modules/crm/models/dtos/IGetClientsResponse";
import GetCustomerEventsUseCase from "modules/crm/application/useCases/GetCustomerEventsUseCase";
import ExportClientListUseCase from "modules/crm/application/useCases/ExportClientListUseCase";

export interface ParamsClientCrm {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  filter?: ICrmFilterValue;
}

const UseGetCustomersCrm = () => {
  const [isLoadingCrmCustomer, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<IGetClientsResponse>();
  const { currentLocal } = useLocal();

  const getData = useCallback(async (params?: ParamsClientCrm) => {
    try {
      const serviceCrm = CrmServiceApi();
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetClientListUseCase(
          serviceCrm,
          currentLocal.id,
          params?.pagination ?? { page: 1, pageSize: 20 },
          { ...params?.filter, keyword: params?.filter?.keyword?.replace(/[^a-zA-Z0-9 ]/g, '') },
        );
        setCustomers(response);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  const exportClientList = useCallback(async (type: CrmExportType, params?: ParamsClientCrm) => {
    const serviceCrm = CrmServiceApi();
    if (currentLocal) {
      return await ExportClientListUseCase(
        serviceCrm,
        currentLocal.id,
        params?.pagination ?? { page: 1, pageSize: 20 },
        { ...params?.filter, keyword: params?.filter?.keyword?.replace(/[^a-zA-Z0-9 ]/g, '') },
        type
      );
    }
  }, [currentLocal]);

  const getCustomerEvents = useCallback((customerId: string, pagination: { page: number, pageSize: number }) => {
    const serviceCrm = CrmServiceApi();
    const response = GetCustomerEventsUseCase(serviceCrm, customerId, pagination);
    return response
  }, [])

  return {
    customers,
    isLoadingCrmCustomer,
    getData,
    getCustomerEvents,
    exportClientList,
  };
};

export default UseGetCustomersCrm;
