import { ImportExport } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";
import styles from "../../pages/SmartPrinterPage.module.scss";
import UseDimension from "components/dimension/UseDimension";

export interface ISaasTableHeaderProps {
  params?: IPostSaleForDeviceParams;
  setParams?: React.Dispatch<React.SetStateAction<IPostSaleForDeviceParams>>;
}

const titleHeader = [
  {
    title: "Nome",
    value: "",
  },
  {
    title: "Impressora",
    value: "",
  },
  {
    title: "Tipo de impressão",
    value: "",
  },
];

const SaasTableHeader = ({ params, setParams }: ISaasTableHeaderProps) => {
    const { dimensions } = UseDimension();
    const [orderBy, setOrderBy] = useState({ Type: "name" } as {
        Orientation: "asc" | "desc",
        Type: string,
    });

    useEffect(() => {
        if (orderBy.Type && orderBy.Orientation && setParams) {
        setParams((prev) => ({
            ...prev,
            Sort: {
            Orientation: orderBy.Orientation,
            Type: orderBy.Type,
            }
        }));
        }
    }, [orderBy, setParams]);
  

    return (
        <>
        {titleHeader.map((item) => {
            return item.title === "Tipo de impressão" && dimensions.width < 600 ? undefined : (
            <div key={item.value} className={styles.header}>
                {item.title}
                {item.value && (
                    <IconButton
                        disableFocusRipple
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setOrderBy({
                                Orientation: params && params.Sort?.Orientation === "asc" ? "desc" : "asc",
                                Type: item.value ?? "",
                            });
                        }}
                    >
                        <ImportExport />
                    </IconButton>
                )}
            </div>
            );
        })}
        </>
    );
};
export default SaasTableHeader;
