import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import MeepTicketsPage from "modules/meepTickets/presentation/pages/MeepTicketsPage";

const MeepTickets: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Ingressos",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <MeepTicketsPage />
    </Layout>
  );
};
export default MeepTickets;
