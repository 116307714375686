import { IGetTutoriaisParams } from "modules/tutoriais/domain/dto/IGetTutoriaisParams";
import { IGetTutoriaisResponse } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { ITutoriaisService } from "modules/tutoriais/domain/interface/ITutoriaisService";

const GetTutoriaisUseCase = async (
    service: ITutoriaisService,
    page?: number,
    params?: IGetTutoriaisParams,
    pageSize?: number
  ): Promise<IGetTutoriaisResponse> => {
    const response = service.getTutoriais({...params, currentPage: page ?? 0, pageSize: pageSize ?? 10});
    return response;
};
export default GetTutoriaisUseCase;
