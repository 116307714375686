import React, { FormEvent, useCallback, useState } from 'react'
import styles from './PermutationFilter.module.scss'
import { TextField } from '@material-ui/core'
import { IPermutationAccountfilter } from './interfaces/IPermutationAccountfilter';
import Button from 'components/ui/Button/Button';

export interface IPermutationAccountFilterProps {
  onSubmit: (filter: IPermutationAccountfilter) => void;
}
export const PermutationFilter: React.FC<IPermutationAccountFilterProps> = ({ onSubmit }) => {

  const [values, setValues] = useState<IPermutationAccountfilter>();

  const changeHandleName = useCallback((name: string) => {
    setValues((prev) => ({ ...prev, name: name }));
  }, []);
  const changeHandleDocument = useCallback((document: string) => {
    setValues((prev) => ({ ...prev, document: document }));
  }, []);


  const changeHandleBalanceMin = useCallback((balanceMin: string) => {
    setValues((prev) => ({ ...prev, balanceMin: Number(balanceMin) === 0 ? undefined : Number(balanceMin) }));
  }, []);
  const changeHandleBalanceMax = useCallback((balanceMax: string) => {
    setValues((prev) => ({ ...prev, balanceMax: Number(balanceMax) === 0 ? undefined : Number(balanceMax) }));
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      values && onSubmit(values);

    },
    [onSubmit, values]
  ); 
  return (
    <div id={styles.PermutationFilter}>
      <div id={styles.HouseFlowFilter}>
        <form onSubmit={submitHandle}>

          <div className={styles.containerFilter}>
            <div className={styles.content}>
              <div className={styles.itemInfo}>
                <label>Nome</label>
                <TextField fullWidth inputProps={{ maxLength: 250 }} size='small' placeholder='Insira o nome' variant='outlined' name={'name'} onChange={(ev) => changeHandleName(ev.target.value)} />
              </div>
              <div className={styles.itemInfo}>
                <label>Documento</label>
                <TextField fullWidth inputProps={{ maxLength: 14 }} size='small' placeholder='Insira o número do documento' variant='outlined' name={'name'} onChange={(ev) => changeHandleDocument(ev.target.value)} />
              </div>
              <div className={styles.balance}>
                <div className={styles.item}>
                  <label>Saldo</label>
                  <TextField inputProps={{ maxLength: 25 }} size='small' type="number" placeholder={"Min. R$"} variant='outlined' name={'balanceMin'} onChange={(ev) => changeHandleBalanceMin(ev.target.value)}  />
                </div>
                <div className={styles.item}>
                  <TextField inputProps={{ maxLength: 25 }} size='small' type="number" placeholder={"Max. R$"} variant='outlined' name={'balanceMax'} onChange={(ev) => changeHandleBalanceMax(ev.target.value)}  />
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button variant={'contained'} className={styles.button} fullWidth type='submit' >Buscar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
