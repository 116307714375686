import LocalsService from 'modules/meepErp/services/api/Locals/LocalsService';
import { ILocalsFilterValue } from 'modules/meepErp/presentation/components/locals/localsList/localsFilter/ILocalsFilter';
import { IGetStorageLocalsListRequest } from '../../dtos/storageLocals/IGetStorageLocalsListRequest';

//useCases
export const getSupplyStorageLocalsUseCase = async (filter: ILocalsFilterValue) => {
    const service = LocalsService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
    const request: IGetStorageLocalsListRequest = {
        page: page,
        pageSize: pageSize,
        orderBy: sortBy ?? 'code',
        ascending: filter.ascending === undefined ? true : filter.ascending,
        name: filter.name,
        code: filter.code
    };
    return service.getLocalsList(request);
};
export const onDeleteLocalsUseCase = async (id: string) => {
    const service = LocalsService();
    return await service.deleteLocals(id);
};


