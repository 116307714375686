import GetSavedFilters from "modules/crm/application/useCases/getSavedFiltersUseCase";
import PostSaveFilterCrm from "modules/crm/application/useCases/postSavedFilterUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import DeleteFilter from "modules/crm/application/useCases/deleteFilterUseCase";
import { ISavedFilter } from "../components/crmFilterForm/interfaces/ISavedFilter";


const UseSavedFilter = () => {
  const { currentLocal } = useLocal();
  const [isLoadingCrmSavedFilter, setIsLoading] = useState<boolean>(false);
  const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>();

  const getSavesdFilters = useCallback(
    async () => {
      try {
        const serviceCrm = CrmServiceApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetSavedFilters(serviceCrm, currentLocal.id);
          setSavedFilters(response);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );

  const postSaveFilter = useCallback(async (params: { name: string, filterString: string }) => {
    try {
      setIsLoading(true)
      const serviceCrm = CrmServiceApi();
      if (currentLocal) {
        await PostSaveFilterCrm(
          serviceCrm,
          currentLocal.id,
          params.name,
          params.filterString
        );
        await getSavesdFilters();
      }
    } finally {
      setIsLoading(false)
    }
  }, [currentLocal, getSavesdFilters]);

  const deleteFilter = useCallback(
    async (filterToDelete: ISavedFilter) => {
      try {
        const serviceCrm = CrmServiceApi();
        setIsLoading(true);
        if (currentLocal) {
          await DeleteFilter(serviceCrm, filterToDelete.id);
          const filters = savedFilters?.filter((item) => item.id !== filterToDelete.id );
          setSavedFilters(filters)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, savedFilters]
  );



  return {
    postSaveFilter,
    savedFilters,
    isLoadingCrmSavedFilter,
    getSavesdFilters,
    deleteFilter,
  };
};

export default UseSavedFilter;
