import { useMenuContext } from "components/layout/presentation/menu/context/MenuContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import DeleteEventUseCase from "modules/events/application/useCases/events/DeleteEventUseCase";
import GetOrganizerEventsUseCase from "modules/events/application/useCases/events/GetOrganizerEventsUseCase";
import IntegrateEventUseCase from "modules/events/application/useCases/events/IntegrateEventUseCase";
import { IOrganizerEvents } from "modules/events/domain/models/IOrganizerEvents";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";

interface IUseEventCard {
  goToEventList: (eventId: string, eventName: string) => void;
  goToPeopleList: (eventId: string, eventName: string) => void;
  goToEditEvent: (eventId: string, eventName: string) => void;
  showDeleteModal: boolean;
  toggleDeleteModal: () => void;
  deleteEvent: (eventId: string) => Promise<void>;
  adminAccess: boolean;
  goToManageEvent: (eventId: string) => void;
  linkOpened: boolean;
  setLinkOpened: (open: boolean) => void;
  getOrganizerEvents: () => Promise<void>;
  organizerEvents: IOrganizerEvents[];
  onIntegrateMeepTickets: (
    eventId: string,
    ioticketsEventId: number,
    localId: string,
  ) => Promise<void>;
}
const eventsService = EventsApi();

const UseEventCard = (): IUseEventCard => {
  const { currentLocal } = useLocal();
  const {  hasAccessRole } = useAuth();
  const adminAccess = hasAccessRole("AdminNomeLista");
  const { push } = useHistory();
  const { showLoading, hideLoading } = useUi();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [linkOpened, setLinkOpened] = useState(false);
  const [organizerEvents, setOrganizerEvents] = useState<IOrganizerEvents[]>(
    []
  );
  const { setMenuPosition } = useMenuContext();


  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteEvent = async (eventId: string) => {
    if (!currentLocal) return;

    showLoading();
    try {
      await DeleteEventUseCase(eventsService, eventId, currentLocal.id);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      toggleDeleteModal();
    }
  };

  const goToEventList = useCallback(
    (eventId: string, eventName?: string) => {
      setMenuPosition(1);
      push(`/private/event/eventos/listas/${eventId}`, { eventName: eventName });
    },
    [push, setMenuPosition]
  );

  const goToPeopleList = useCallback(
    (eventId: string, eventName?: string) => {
      setMenuPosition(1);
      push(`/private/event/eventos/convidados/${eventId}`, { eventName: eventName });
    },
    [push, setMenuPosition]
  );

  const goToEditEvent = useCallback(
    (eventId: string) => {
      push(`/private/event/eventos/editar/${eventId}`);
    },
    [push]
  );

  const goToManageEvent = useCallback(
    (eventId: string) => {
      localStorage.setItem("@eventId", eventId);
      setMenuPosition(1);
      push(`/private/event/eventos/gerenciar/${eventId}`);
    },
    [push, setMenuPosition]
  );

  const getOrganizerEvents = useCallback(async () => {
    if (currentLocal) {
      const response = await GetOrganizerEventsUseCase(
        eventsService,
        currentLocal?.id
      );
      setOrganizerEvents(response);
    }
  }, [currentLocal]);

  const onIntegrateMeepTickets = useCallback(async (eventId: string, ioticketsEventId: number, localId: string) => {
      await IntegrateEventUseCase(eventsService, eventId, ioticketsEventId, localId);
    }, []);

  return {
    goToEventList,
    goToPeopleList,
    goToEditEvent,
    deleteEvent,
    showDeleteModal,
    toggleDeleteModal,
    adminAccess,
    goToManageEvent,
    linkOpened,
    setLinkOpened,
    getOrganizerEvents,
    organizerEvents,
    onIntegrateMeepTickets,
  };
};

export default UseEventCard;
