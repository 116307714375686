import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { getRolesByFunctionUseCase } from "modules/cargos/application/useCases/getRolesByFunctionUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { SubscribeFeatureUseCase } from "modules/plans/application/SubscribeFeatureUseCase";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFeatures } from "modules/plans/presentation/interfaces/IFeatures";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CargosApi from "services/api/cargos/CargosApi";
import PlansApi from "services/api/plans/PlansApi";

const service = PlansApi()
const roleService = CargosApi();

export const UsePurchaseBi = () => {
  const { getAdditionalFeatures } = usePlans();
  const [biFeature, setBiFeature] = useState<IFeatures>();
  const [loading, setLoading] = useState(true);
  const { currentLocal } = useLocal();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionModalOpened, setPermissionModalOpened] = useState(false);
  const { toast } = useUi();

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        try {
          setLoading(true);
          const response = await getAdditionalFeatures([FeatureTypeEnum.BI]);

          if (response?.length) setBiFeature(response[0]);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [currentLocal, getAdditionalFeatures]);

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        try {
          const response = await getRolesByFunctionUseCase(roleService, currentLocal.id);
          setHasPermission(!!response.find(x => x.name.toLocaleLowerCase() === 'acessos' || x.name.toLocaleLowerCase() === 'admin'));
        } finally { }
      }
    })()
  }, [currentLocal]);

  const subscribeFeature = async () => {
    try {
      if (!hasPermission) {
        setPermissionModalOpened(true);
      } else {
        setSaving(true);
        const getToken = localStorage.getItem("@token");
        const token = getToken && JSON.parse(getToken);
        const user = JSON.parse(token.user);

        await SubscribeFeatureUseCase(service, user.cpf, currentLocal!.id, biFeature!.id);
        history.push('/private/bi');
      }
    } catch {
      toast('Ocorreu um erro ao contratar o power bi', 'error');
      setSaving(false);
    }
  }

  return {
    biFeature,
    loading,
    subscribeFeature,
    saving,
    permissionModalOpened,
    setPermissionModalOpened
  }
}
