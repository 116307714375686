import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import { Dialog, IconButton, Switch } from '@material-ui/core'
import { AddCircle, Close, Delete, Edit } from '@material-ui/icons'
import React, { FC } from 'react'
import styles from './AddSpecialDaysForm.module.scss'
import UseAddSpecialDaysForm from './UseAddSpecialDaysForm'
import { Modal } from 'components/ui/modal/Modal'
import { IAddSpecialDays } from 'modules/schedule/domain/models/ISpecialDays'
export interface IUnavailabilityFormProps {
    // values: IUnavailabilityForm[];
    // onChange: (values: IUnavailabilityForm[]) => void;
    handleSpecialDays?: (values: IAddSpecialDays[]) => void
}
const AddSpecialDaysForm: FC<IUnavailabilityFormProps> = ({ handleSpecialDays }) => {
    const {
        values,
        setSelectedStart,
        setSelectedEnd,
        selectedStart,
        selectedEnd,
        toggleModalUnavailability,
        onClickRemoveUnavailability,
        handleModal,
        handleOpenModalEdit,
        isEdit,
        haveReservationThisDay,
        toggleHaveReservationModal,
        handleModalHaveReservation,
        // UnavailabilityHandle,
        specialDays,
        timeStart,
        setTimeStart,
        timeEnd,
        setTimeEnd,
        isOpenChecked,
        setIsOpenChecked,
        amountOccupancy,
        changeAmountOccupancyHandle,
        handleChangeChecked,


    } = UseAddSpecialDaysForm(handleSpecialDays)

    return (
        <div id={styles.UnavailabilityForm} >
            <div className={styles.containerHelp}>
                <h2>Datas especiais</h2>
                <TooltipInfo
                    title={"Datas especiais"}
                    paragraph={"Insira as datas em que seu passeio vai funcionar ou deixar de funcionar em um dia especifico."}
                />
            </div>
            <p>Insira as datas em que seu passeio vai funcionar ou deixar de funcionar em um dia especifico.</p>

            <div className={styles.container} >
                <Button fullWidth={false} onClick={handleModal} variant="outlined">
                    Adicionar data especial <AddCircle />
                </Button>

                {values?.map((item, index) => {

                    return (
                        <div key={index} className={styles.horarioItem}>
                            <div className={styles.horarioLabel}>
                                {
                                    item.closed ?
                                        <p>Fechado: <span>{item.start.toLocaleString().slice(0, 10).split("-").reduce(function (p, c) { return c + "/" + p })} à {item.end.toLocaleString().slice(0, 10).split("-").reduce(function (p, c) { return c + "/" + p })}</span></p>
                                        :
                                        <p>Aberto: <span>{item.start.toLocaleString().slice(0, 10).split("-").reduce(function (p, c) { return c + "/" + p })} à {item.end.toLocaleString().slice(0, 10).split("-").reduce(function (p, c) { return c + "/" + p })}</span></p>
                                }
                            </div>
                            <IconButton onClick={() => item.id && handleOpenModalEdit(item)}> <Edit /></IconButton>
                            <IconButton onClick={() => item.id && onClickRemoveUnavailability(item.id)}> <Delete /></IconButton>
                        </div>
                    )
                })
                }
            </div>

            <Dialog fullWidth open={toggleModalUnavailability} onClose={handleModal}>
                <div className={styles.containerDialog}>
                    <div className={styles.containerTitleModal}>
                        <h1>Data especial:</h1>
                        <Close onClick={handleModal} />
                    </div>
                    <FormItemContainer row>
                        <FormItemContainer flex={2} label={"Data - início"}>
                            <Input value={selectedStart} type={"date"} onChange={(ev) => { setSelectedStart(ev.target.value) }} ></Input>
                        </FormItemContainer>
                        <FormItemContainer flex={2} label={"Data - fim"}>
                            <Input value={selectedEnd} type={"date"} onChange={(ev) => { setSelectedEnd(ev.target.value) }} ></Input>
                        </FormItemContainer>
                    </FormItemContainer>
                    <FormItemContainer row>
                        <FormItemContainer flex={2} label={"Hora - início"}>
                            <Input value={timeStart} type={"time"} onChange={(ev) => { setTimeStart(ev.target.value) }} ></Input>
                        </FormItemContainer>
                        <FormItemContainer flex={2} label={"Hora - fim"}>
                            <Input value={timeEnd} type={"time"} onChange={(ev) => { setTimeEnd(ev.target.value) }} ></Input>
                        </FormItemContainer>
                    </FormItemContainer>
                    <FormItemContainer row>
                        <FormItemContainer flex={2} label={"Capacidade"}>
                            <Input value={amountOccupancy} type={"number"} onChange={(ev) => changeAmountOccupancyHandle(ev.target.value)} ></Input>
                        </FormItemContainer>
                        <FormItemContainer flex={2} >
                            <div className={styles.containerSwitch}>
                                <Switch color="primary" checked={isOpenChecked} onChange={handleChangeChecked} ></Switch>
                                <p>Aberto</p>
                            </div>
                        </FormItemContainer>
                    </FormItemContainer>
                    <FormItemContainer >
                    </FormItemContainer>
                    <div className={styles.containerButton}>

                        <Button fullWidth={false} variant="outlined" onClick={handleModal}>Cancelar</Button>
                        {
                            !isOpenChecked ?
                                !isEdit ?
                                    <Button disabled={!selectedStart || !selectedEnd} fullWidth={false} onClick={haveReservationThisDay}>{'Salvar'}</Button>
                                    :
                                    <Button disabled={!selectedStart || !selectedEnd} fullWidth={false} onClick={haveReservationThisDay}>{'Editar'}</Button>
                                :
                                !isEdit ?
                                    <Button disabled={!selectedStart || !selectedEnd || !timeStart || !timeEnd || !amountOccupancy} fullWidth={false} onClick={haveReservationThisDay}>{'Salvar'}</Button>
                                    :
                                    <Button disabled={!selectedStart || !selectedEnd || !timeStart || !timeEnd || !amountOccupancy} fullWidth={false} onClick={haveReservationThisDay}>{'Editar'}</Button>

                        }
                    </div>

                </div>
            </Dialog>


            <Modal toggleModal={handleModalHaveReservation} onConfirm={haveReservationThisDay} open={toggleHaveReservationModal} title="Deseja adicionar esta indisponibilidade?">
                <p>Tem certeza que deseja adicionar esta indisponibilidade?</p>
                <p>Existe reservas para este período de dias</p>
            </Modal>



        </div>
    )
}
export default AddSpecialDaysForm