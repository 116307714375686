import React, { FC, useEffect, useState } from "react";
import styles from "./RegisterPage.module.scss";
import { TextField } from "@material-ui/core";
import { UseRegisterPage } from "./UseRegisterPage";
import ShowPassword from "../../components/showPassword/ShowPassword";
import SaasPublicLayout from "../SaasPublicLayout";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IRegisterSchema, registerSchema } from "modules/saas/domain/schema/registerSchema";
import { CircularProgress } from "@mui/material";
import Button from "components/ui/Button/Button";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";

export interface IRegisterPageProps {
    //propertys
}

const RegisterPage: FC<IRegisterPageProps> = () => {
    const {
        isLoading,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        defaultValues,
        handleSubmit: submitForm,
    } = UseRegisterPage();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IRegisterSchema>({
        resolver: zodResolver(registerSchema)
    });

    useEffect(() => {
        setValue("email", defaultValues.email ?? "");
    }, [defaultValues, setValue]);

    return (
        <SaasPublicLayout
            step={defaultValues.type === ProspectType.Cielo || defaultValues.type === ProspectType.CieloEvent || defaultValues.type === ProspectType.CieloEstablishment ? 2 : 1}
            totalSteps={defaultValues.type === ProspectType.Cielo || defaultValues.type === ProspectType.CieloEvent || defaultValues.type === ProspectType.CieloEstablishment ? 6 : 5}
            progressBarColor={progressBarColor}
            backgroundColorTop={backgroundColorTop}
            backgroundColorBottom={backgroundColorBottom}
            terms
        >
            <div className={styles.container}>
                <h1>Vamos criar a sua conta</h1>
                <form
                    onSubmit={handleSubmit(submitForm)}
                >
                    <div className={styles.column}>
                        <label htmlFor="email" className={styles.required}>E-mail</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            {...register("email")}
                            helperText={errors.email?.message}
                            error={!!errors.email}
                            disabled={isLoading}
                        />
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="password" className={styles.required}>Senha</label>
                        <TextField
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            {...register("password")}
                            helperText={errors.password?.message}
                            error={!!errors.password}
                            disabled={isLoading}
                        />
                        <ShowPassword showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="confirmPassword" className={styles.required}>Confirme a senha</label>
                        <TextField
                            variant="outlined"
                            type={showConfirmPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            {...register("confirmPassword")}
                            helperText={errors.confirmPassword?.message}
                            error={!!errors.confirmPassword}
                            disabled={isLoading}
                        />
                        <ShowPassword showPassword={showConfirmPassword} setShowPassword={setShowConfirmPassword} />
                    </div>

                    <Button type="submit" color="secondary" style={{ backgroundColor: backgroundColorTop }}>
                        {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                    </Button>
                </form>
            </div>
        </SaasPublicLayout>
    )
}
export default RegisterPage