import { IResponseGetNovidades } from "modules/adminNovidades/application/interfaces/INovidadesService";
import DeleteNovidades from "modules/adminNovidades/application/useCases/DeleteServicesNovidades";
import GetNovidades from "modules/adminNovidades/application/useCases/GetServicesNovidades";
import { useCallback, useEffect, useState } from "react";
import { NovidadesApi } from "services/api/novidades/NovidadesApi";

const UseAdminNovidadesPage = () => {
  const [listNovidades, setListNovidade] = useState<IResponseGetNovidades[]>();
  const [typeOrder, setTypeOrder] = useState("date");
  const [orientation, setOrientation] = useState(false);
  const [totalNovidades, setTotalNovidades] = useState(1);
  const [page, setPage] = useState(0);
  const [qtdNovidadesPage, setQtdNovidadesPage] = useState(10);
  const [step, setStep] = useState(1);
  const [stepDelete, setStepDelete] = useState(1);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");

  const novidadesService = NovidadesApi();

  const param = {
    pageSize: qtdNovidadesPage,
    page: page,
    type: typeOrder,
    orientation: orientation ? "asc" : "desc",
    category: category,
  };

  const changeOrder = (value: string) => {
    setTypeOrder(value);
    setOrientation(!orientation);
  };

  const getNovidades = useCallback(() => {
    setLoading(true);
    GetNovidades(novidadesService, param)
      .then((response) => {
        setListNovidade(response.records);
        setTotalNovidades(response.totalRecords);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [orientation, typeOrder, page, qtdNovidadesPage, step, category]);

  const deleteNovidades = (id: string) => {
    DeleteNovidades(novidadesService, id).then(() => {
      setStepDelete(2);
      getNovidades();
    });
  };

  useEffect(() => {
    getNovidades();
  }, [orientation, typeOrder, page, qtdNovidadesPage, step, category]);

  return {
    changeOrder,
    listNovidades,
    setPage,
    totalNovidades,
    setQtdNovidadesPage,
    qtdNovidadesPage,
    step,
    setStep,
    loading,
    deleteNovidades,
    stepDelete,
    setStepDelete,
    setCategory,
  };
};

export default UseAdminNovidadesPage;
