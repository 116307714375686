import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ReportProductsItem.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IReportProductsColumnsConfig, ReportProductsColunmsPropertyType } from "pages/private/relatorio/components/interfaces/IColumnsConfig";
import { GEtSalesResponse, ProductsTypes } from "services/api/outputreport";

interface Props {
  columns: IReportProductsColumnsConfig[]
  userItemData: ProductsTypes;
}

const ReportProductsItem: React.FC<Props> = ({ userItemData, columns }) => {

  const formatValues = useCallback((property: ReportProductsColunmsPropertyType): ReactNode => {
    if (userItemData.hasOwnProperty("ProductId")) {
      switch (property) {
        //formatar status  
        case 'Total':
          return moneyMaskNumber(userItemData?.Total);
        case 'UnityPrice':
          return moneyMaskNumber(userItemData?.UnityPrice);
        default:
          return userItemData?.[property] ?? '-'
      }
    }
  }, [userItemData])

  const List = useMemo(() => columns.map((colunm, index) => {
    return (!colunm.hide && <div
      key={index}
      className={styles.row}
      style={colunm.style ?? { flex: 1 }}
    >
      <span>{formatValues(colunm.property)}</span>
    </div>)
  }), [columns, formatValues])

  return (

    <div id={styles.ReportProductsItem}>
      <div className={styles.containerItem}>
        <div className={styles.content}>
          {List}         
        </div>
      </div>
    </div>

  );
};

export default ReportProductsItem;

