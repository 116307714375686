import { IPrintQueueApiService } from "modules/printQueue/domain/interfaces/IPrintQueueApiService";
import { IOperatorList } from "modules/printQueue/presentation/interfaces/IOperatorList";

const GetOperatorListUseCase = async (service: IPrintQueueApiService, localClienteId: string):Promise<IOperatorList[]> => {


    const response = await service.getOperators(localClienteId);
    return response
}

export default GetOperatorListUseCase;



