import React, { FC } from 'react'
import CatalogPage from 'modules/catalog/presentation/CatalogPage'
import Layout from 'components/layout/presentation/Layout'

export interface IindexProps {
    //propertys
}
const Catalog: FC<IindexProps> = () => {
    return (
        <Layout >
            <CatalogPage />
        </Layout>
    )
}
export default Catalog