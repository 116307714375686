export const moneyMask = (text: string) => {
    var value = text ? Number.parseInt(text.substr(0, 14).replace(/[^0-9]/g, "")).toString() : "0,00"
    var returnValue: string = value.length > 2 ? value.slice(0, value.length - 2) + "," + value.slice(value.length - 2, value.length) : value.length === 2 ? "0," + value : "0,0" + value
    return ("R$ " + returnValue)
}
export const moneyMaskNumber = (money?: number) => {
    return money?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })??'R$ 0,00'
}

export const moneyToFloat = (text: string) => {
    text = text.replace(/[^0-9]/g, "") ;
    text = text ? text: '0';
    return Number.parseFloat(text.replace(/[^0-9]/g, "")) / 100;
}

// export const money = (value: number) => {
//     return new Intl.NumberFormat("pt-BR", {
//         style: "currency",
//         currency: "BRL",
//     }).format(value)

// }