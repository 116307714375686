import { TotalLockResponse } from "modules/contaDigital/models/dtos/TotalLockResponse";
import { IDigitalAccountApi } from "modules/contaDigital/presentation/pages/interface/IDigitalAccountApi";

const GetTotalLockUseCase = async (
  accountService: IDigitalAccountApi,
  localId: string
): Promise<TotalLockResponse> => {
  return await accountService.getTotalLock(localId);
};

export default GetTotalLockUseCase;