import { IPaginatedList } from 'models/api/IApi';
import {
  ICreateDiscountListRequest,
  IDiscountsRequest,
  IEditDiscountsRequest
} from 'modules/events/domain/dto/IEventsRequest';
import { IAddPersonToPublicPeopleListResponse, IDiscountListResponse, IPeopleListToEvent } from 'modules/events/domain/dto/IEventsResponse';
import { api } from 'services/api/Api';
import { IDiscountBaseList } from '../../../../modules/events/application/interfaces/IDiscountBaseListService';

const DiscountBaseListApi = (): IDiscountBaseList => {
  const getBaseList = async (
    localId: string,
  ): Promise<IDiscountListResponse[]> => {
    const result = await api.get(
      `SuperApp/Event/FindBaseListsByLocal?localId=${localId}`,
    );
    return result.data;
  };

  const createBaseList = async (
    discountList: ICreateDiscountListRequest,
  ): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/AddBaseList`, {
      ...discountList,
    });
    return result.data;
  };

  const addDiscountToBaseList = async (
    discount: IDiscountsRequest,
    localId: string,
  ): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/AddBaseListDiscount?localId=${localId}`, {
      ...discount,
    });
    return result.data;
  };

  const deleteDiscount = async (discountId: string, localId: string,): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeleteBaseListDiscount/${discountId}?localId=${localId}`);
  };

  const deleteBaseList = async (discountListId: string, localId: string,): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeleteBaseList/${discountListId}?localId=${localId}`);
  };

  const linkDiscountBaseList = async (
    baseListId: string | null,
    peopleListId: string,
    localId: string,
  ) => {
    await api.post(`SuperApp/Event/LinkBaseListToPeopleList?localId=${localId}`, {
      baseListId,
      peopleListId,
    });
  };

  const getEventDiscountBaseList = async (
    localId: string,
    eventId: string,
    page?: number,
    pageSize?: number
  ): Promise<IPaginatedList<IDiscountListResponse>> => {
    let query = `?localId=${localId}`;
    query += `&eventId=${eventId}`;
    query += page ? `&page=${page}` : "&page=0";
    query += pageSize ? `&pageSize=${pageSize}` : "";
    const result = await api.get(`SuperApp/Event/FindBaseListWithTicketByEvent${query}`);

    return result.data;
  };

  const editBaseListDiscount = async (
    discount: IEditDiscountsRequest,
    localId: string,
  ): Promise<void> => {
    const result = await api.put(`SuperApp/Event/EditBaseListDiscount?localId=${localId}`, {
      ...discount,
    });
    return result.data;
  };

  const getPeopleListToEvent = async (listId: string): Promise<IPeopleListToEvent> => {
    const result = await api.get(`SuperApp/Event/GetPeopleListToEvent/${listId}`);
    return result.data;
  };

  const addPersonToPublicPeopleList = async (listId: string, cpfs: string[]): Promise<IAddPersonToPublicPeopleListResponse> => {
    const result = await api.post(`SuperApp/Event/addPersonToPublicPeopleList`, { peopleListId: listId, cpfs, ignoreAlert: true });
    return result.data;
  };

  return {
    getBaseList,
    createBaseList,
    addDiscountToBaseList,
    deleteDiscount,
    deleteBaseList,
    linkDiscountBaseList,
    getEventDiscountBaseList,
    editBaseListDiscount,
    getPeopleListToEvent,
    addPersonToPublicPeopleList
  };
};

export default DiscountBaseListApi;
