import { Icon, IconButton } from "@material-ui/core";
import React, { ChangeEvent, FC, InputHTMLAttributes, useCallback, useRef, useState } from "react";
import styles from "./InputImage.module.scss";
interface InputImageProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChangeImage?: (imageBase64: { imagemUrl?: string; imagem?: string }[], name: string | undefined) => void;
  onDeleteImage?: (imageBase64: { imagemUrl?: string; imagem?: string }[], name: string | undefined) => void;
  values?: { imagemUrl?: string; imagem?: string }[];
  width?: number;
  height?: number;
  multiple?: boolean;
  name?: string;
  error?: boolean;
  helperText?: string;


}

const InputImage: FC<InputImageProps> = ({ inputProps, onChangeImage, children, values, width = 100, multiple, height = 100, onDeleteImage, name, error, helperText }) => {
  const refInput = useRef<HTMLInputElement>(null);

  const fileToBase64 = (file: File | null) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      // Convert data to base64
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  const onDeleteHandle = useCallback(
    (index: number) => {
      if (values) {
        const newValues = values.filter((intem, _index) => _index !== index);
        onDeleteImage && onDeleteImage(newValues, inputProps?.name);
      }
    },
    [onDeleteImage, values]
  );

  const onChangeHandle = async (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;
    const images: string[] = [];
    if (files?.length) {

      for (let i = 0; i < files.length; i++) {
        const base64 = await fileToBase64(files.item(i));
        images.push(String(base64));
      }

      onChangeImage &&
        onChangeImage(
          images.map((baseUrl) => ({ imagem: baseUrl })),
          inputProps?.name ?? name
        );
    }
  };

  const onClick = () => {
    refInput.current?.click();
  };

  return (
    <div id={styles.inputImage}>
      {children ?? (
        <>
          {multiple &&
            values?.map(
              (imagem, index) =>
                imagem && (
                  <div key={index} className={styles.imagemInput} style={{ width, height }}>
                    <img src={imagem.imagemUrl ?? imagem.imagem}></img>
                    <IconButton onClick={() => onDeleteHandle(index)} size="small" className={styles.closeButton}>
                      <Icon>cancel</Icon>
                    </IconButton>
                  </div>
                )
            )}
          <div onClick={onClick} className={styles.imagemInput} style={{ width, height }}>
            {!multiple && !!values?.length ? (
              <>
                <img src={values[0].imagemUrl ?? values[0].imagem}></img>
                <IconButton size="small" className={styles.closeButton}>
                  <Icon>edit</Icon>
                </IconButton>
              </>
            ) : (
              <>
                <Icon fontSize={"large"}>add_a_photo</Icon>
                {error && <p>{helperText}</p>}
              </>
            )}
          </div>
        </>
      )}

      <input
        name={name}
        {...inputProps}
        ref={refInput}
        id="input-file"
        accept="image/x-png,image/gif,image/jpeg"
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={onChangeHandle}
      />
    </div>
  );
};

export default InputImage;
