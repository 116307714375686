import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import KDSPage from 'modules/kds/presentation/KDSPage'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TabPage from '../dashboard/tabPage/TabPage'
import UseManageEventPage from 'modules/events/presentation/pages/manageEvent/UseManageEventPage'
import SaasKDSPage from 'modules/kds/presentation/SaasKDSPage'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

export interface IMeepFoodProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    value?: string,
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        role: string;
    }[]
}

const MeepFood: FC<IMeepFoodProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();
    const { event } = UseManageEventPage();
    const { currentLocal } = useLocal();

    useEffect(() => {
        const currentTab = menuItems.find(item => tab === item.tab);
        const routers = [
            {
                title: "Evento/Caixa",
                url: "/private/eventos/list",
            },
            {
                title: event?.name
              },
              {
                title: "Pedidos",
            },
            {
            label: "MeepFood",
            title: currentTab?.title
        }];

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.title : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.title
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters, event]);

    const menuItems:IMenuItem[] = [
        {
            title: "Painel",
            tab: "painel",
            role: "Painel",
            component: currentLocal?.systemIdentifier === 1 ? <SaasKDSPage /> : <KDSPage />,
        },
        {
            title: "Configuração",
            tab: "configuracao",
            value: "/meepFood/configuracao",
            role: "MeepFoodConfiguration"
        },
        {
            title: "Integração iFood",
            tab: "integracaoIfood",
            value: "/meepFood/integracaoIfood",
            role: "IntegracaoIfoodKds"
        },
        {
            title: "Tutorial",
            tab: "tutorial",
            value: "/meepFood/tutorial",
            role: "Painel"
        }
    ];

    const subTabComponent = useCallback((tabItem: IMenuItem) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/meepFood/" + tabItem.tab}
            subTabEnabled
            hideTabs
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.role,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        /> : tabItem.component || <IframePage src={tabItem.value!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/meepFood'}
            tabsPages={menuItems.map((tabItem) => (
                {
                    label: tabItem.title,
                    tab: tabItem.tab,
                    roles: tabItem.role,
                    component: subTabComponent(tabItem)
                }
            ))}
            hideTabs
        />
    );
}
export default MeepFood;
