import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { IProductActionEnum } from "../../interfaces/IProductActionEnum";
import {
  EnumProductType,
  IProductItem,
} from "../../interfaces/IProductListData";
import styles from "./ProductItem.module.scss";
import UseProductPage from "../../UseProductPage";
import UseEnableDisableAllModal from "../../UseEnableDisableAllModal";

export interface IProductItemProps {
  //propertys
  item?: IProductItem;
  checked?: boolean;
  checkedAll?: boolean;
  onChangeCheckBox?: (value: boolean) => void;
  onClickEdit?: () => void;
  onClickCopy?: (item: IProductItem) => void;
  onClickDelete?: () => void;
  onChangeEnabled?: (checked: boolean) => void;
  onClickAction?: (action: IProductActionEnum) => void;
  isLoading?: boolean;
}
const ProductItem: FC<IProductItemProps> = memo(
  ({
    item,
    checked,
    onChangeCheckBox,
    onClickEdit,
    onClickCopy,
    onClickDelete,
    onChangeEnabled,
    onClickAction,
  }) => {
    const [modalDelete, setModalDelete] = useState(false);
    const [modalCopy, setModalCopy] = useState(false);
    const [enableAll, setEnableAll] = useState<boolean | undefined>();

    const { toggleEDAModal, setActualAction } = UseEnableDisableAllModal();

    const { verifyAllChecked, isLoading } = UseProductPage();

    useEffect(() => {
      setEnableAll(verifyAllChecked);
    }, [verifyAllChecked]);

    const handleToggleEDAModal = (value: boolean) => {
      setActualAction(value);
      toggleEDAModal();
    };

    const { hasAccessRole } = useAuth();

    const [anchorEl, setAnchorEl] = React.useState<
      EventTarget & HTMLButtonElement
    >();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(undefined);
    };

    const onClickActionHandle = useCallback(
      (action: IProductActionEnum) => {
        onClickAction?.(action);
        handleClose();
      },
      [onClickAction]
    );

    const onConfirmDeleteHandle = useCallback(() => {
      onClickDelete?.();
      setModalDelete(false);
    }, [onClickDelete]);

    const onConfirmCopyHandle = useCallback(() => {
      onClickCopy?.(item!);
      setModalCopy(false);
    }, [item, onClickCopy]);

    const deleteProductEnabled = (type: EnumProductType) => {
      return (
        type === EnumProductType.PRODUTO ||
        type === EnumProductType.VARIAVEL ||
        type === EnumProductType.KILO ||
        type === EnumProductType.INGRESSO_COM_NOME_NA_LISTA ||
        type === EnumProductType.INGRESSO_AVULSO
      );
    };

    return item ? (
      <div id={styles.ProductItem}>
        <div className={styles.container}>
          <div className={styles.row} style={{ width: 50 }}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onChangeCheckBox?.(checked)}
            />
          </div>
          <div className={styles.row} style={{ width: 60 }}>
            <img
              src={item.imageUrl}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/icon/image_placeholder.svg";
              }}
              width={60}
              height={60}
            />
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            {item.name}
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            {item.categoryName}
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            <div className={styles.row} style={{ flex: 1 }}>
              CFOP: {item.cfop}
            </div>
            <div className={styles.row} style={{ flex: 1 }}>
              CEST: {item.cest}
            </div>
            <div className={styles.row} style={{ flex: 1 }}>
              NCM: {item.ncm}
            </div>
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(item.price)}
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            {deleteProductEnabled(item.type) &&
              hasAccessRole("HabilitarDesabilitarProdutoPortal") && (
                <Switch
                  defaultChecked={item.enabled}
                  onChange={(ev, value) => onChangeEnabled?.(value)}
                />
              )}
          </div>
          <div
            className={styles.row}
            style={{
              flex: 1,
              flexDirection: `row`,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {item.type !== EnumProductType.DESATIVACAO_POS_PAGO_COMANDA &&
              item.type !== EnumProductType.DESATIVACAO_CASHLESS_POS_PAGO &&
              item.type !== EnumProductType.DESATIVACAO_CASHLESS_PRE_PAGO &&
              item.type !== EnumProductType.ATIVACAO_CASHLESS_POS_PAGO &&
              item.type !== EnumProductType.ATIVACAO_CASHLESS_PRE_PAGO &&
              item.type !== EnumProductType.ATIVACAO_POS_PAGO_COMANDA &&
              item.type !== EnumProductType.TAXA_DE_SERVICO &&
              item.type !== EnumProductType.TAXA_DE_ENTREGA &&
              item.type !== EnumProductType.AJUSTE &&
              hasAccessRole("DuplicarProdutoPortal") && (
                <IconButton onClick={() => setModalCopy(true)}>
                  <Icon>content_copy</Icon>
                </IconButton>
              )}
            {deleteProductEnabled(item.type) &&
              hasAccessRole("RemoverProdutoPortal") && (
                <IconButton onClick={() => setModalDelete(true)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
            {hasAccessRole("EditarProdutoPortal") && (
              <IconButton onClick={onClickEdit}>
                <Icon>edit</Icon>
              </IconButton>
            )}
          </div>
        </div>
        <Dialog open={modalDelete}>
          <DialogTitle>Apagar Produto</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja apagar o produto
            </DialogContentText>
            <DialogContentText>
              <b>{item.name}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.containerButtonModal}>
            <Button variant="outlined" onClick={() => setModalDelete(false)}>Cancelar</Button>
            <Button variant="contained" onClick={onConfirmDeleteHandle}>Apagar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={modalCopy}>
          <DialogTitle>Duplicar Produto</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja duplicar o produto
            </DialogContentText>
            <DialogContentText>
              <b>{item.name}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.containerButtonModal}>
            <Button variant="outlined" onClick={() => setModalCopy(false)}>Cancelar</Button>
            <Button variant="contained" onClick={onConfirmCopyHandle}>Duplicar</Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : isLoading ? (
      <Skeleton variant={"rect"} height={80}></Skeleton>
    ) : (
      <div id={styles.ProductItemHeader}>
        <div className={styles.container}>
          <div className={styles.row} style={{ width: 50 }}>
            <Checkbox
              checked={checked}
              onChange={(_, _checked) => {
                onChangeCheckBox?.(_checked);
              }}
            />
          </div>
          <div className={styles.row} style={{ width: 60 }}></div>
          <div className={styles.row} style={{ flex: 1 }}>
            Nome
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            Categoria
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            Fiscal
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            Valor
          </div>
          <div className={styles.row} style={{ flex: 1 }}>
            <div className={styles.swithHeader}>
              <div>Ativo</div>
              {enableAll !== undefined && (
                <Switch
                  checked={enableAll}
                  onChange={(ev, value) => handleToggleEDAModal?.(value)}
                />
              )}
            </div>
          </div>
          <div
            className={styles.row}
            style={{ flex: 1, alignItems: "flex-end" }}
          >
            {hasAccessRole("EditarProdutoPortal") && (
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                className={styles.action}
                onClick={handleClick}
              >
                Ações
              </Button>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              // keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() =>
                  onClickActionHandle(IProductActionEnum.CHANGE_PRINTER)
                }
              >
                <Icon>printer</Icon>Alterar impressora
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onClickActionHandle(IProductActionEnum.CHANGE_STOCK_CONTROL)
                }
              >
                <Icon>inventory_2</Icon>Alterar controle de estoque
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onClickActionHandle(IProductActionEnum.CHANGE_STORE)
                }
              >
                <Icon>storefront</Icon>Alterar loja
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onClickActionHandle(IProductActionEnum.CHANGE_TAX_DATA)
                }
              >
                <Icon>account_balance</Icon>Alterar dados fiscais
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onClickActionHandle(IProductActionEnum.DELETE_PRODUCTS)
                }
              >
                <Icon>delete</Icon>Excluir produtos
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
);
export default memo(ProductItem);
