import { IExportRequest } from 'modules/houseFlow/domain/dto/IExportRequest';
import { IGetHouseFlowDetailsItemResponse } from 'modules/houseFlow/domain/dto/IGetHouseFlowDetailsItemResponse';
import { IGetHouseFlowListResponse } from 'modules/houseFlow/domain/dto/IGetHouseFlowListResponse';
import { IGetHouseFlowProductListResponse } from 'modules/houseFlow/domain/dto/IGetHouseFlowProductListResponse';
import { IParamsHouseFlowFilterRequest } from 'modules/houseFlow/domain/dto/IHouseFlowFilterRequest';
import { IHouseFlowService } from 'modules/houseFlow/domain/interfaces/IHouseFlowService'
import { mock } from 'modules/houseFlow/presentation/pages/houseFlowMock';
import { api } from "../Api"

const HouseFlowApi = (): IHouseFlowService => {
  const getHouseFlow = async (localId: string, request: IParamsHouseFlowFilterRequest): Promise<IGetHouseFlowListResponse> => {


    const response = await api.get<IGetHouseFlowListResponse>(`/prepaideletroniccommandflow/${localId}/cards?`, { params: request });
    return response.data;
    // const response = await mock;
    // return response;
  };
  const getHouseFlowDetailsItem = async (localId: string, cardId?: string, pagination?: { page?: number; pageSize?: number; }): Promise<IGetHouseFlowDetailsItemResponse> => {

    const response = await api.get<IGetHouseFlowDetailsItemResponse>(`/prepaideletroniccommandflow/${localId}/cards/${cardId}/orders?`, { params: pagination });
    return response.data;
  };

  const getHouseProductListFlow = async (localId: string, cardId?: string, orderId?: string, pagination?: { page?: number; pageSize?: number; }, sortOrientation?: string, sortType?: string): Promise<IGetHouseFlowProductListResponse> => {

    const params = {
      page: pagination?.page,
      pageSize: pagination?.pageSize,
      "sort.Type": sortType,
      "sort.Orientation": sortOrientation,
    }

    const response = await api.get<IGetHouseFlowProductListResponse>(`/prepaideletroniccommandflow/${localId}/cards/${cardId}/orders/${orderId}`, { params: params });
    return response.data;
  };

  const postHouseFlowExport = async (request: IExportRequest): Promise<void> => {
    const response = await api.post(`/prepaideletroniccommandflow/report`, request);
    return response.data
  }

  return {
    getHouseFlow,
    getHouseFlowDetailsItem,
    getHouseProductListFlow,
    postHouseFlowExport

  }
}

export default HouseFlowApi