import { Autocomplete, TextField } from "@mui/material";
import styles from "./actionBulk.module.scss";

const optionsAcoes = [
  {
    label: "Enviar atualização de dados",
    value: 'atualizações',
  },
  {
    label: "Habilitar / desabilitar Wi-fi",
    value: 'wifi',
  },
  {
    label: "Enviar mensagem",
    value: 'mensagem',
  }
];

interface ActionBulkProps {
  qtdChecked: number;
  setOpenSideshet: React.Dispatch<React.SetStateAction<string | null>>
  setBulkActions: React.Dispatch<React.SetStateAction<boolean>>
}

const ActionBulk = ({qtdChecked, setOpenSideshet, setBulkActions} : ActionBulkProps) => {
  return (
    <div id={styles.ActionBulk}>
      <p>
        <span>{qtdChecked}</span> equipamentos selecionados
      </p>
      <Autocomplete
        disablePortal
        className={styles.selectAcoes}
        options={optionsAcoes}
        onChange={(event, selectedValue)  => {
          selectedValue && setOpenSideshet(selectedValue.value)
          setBulkActions(true)
        }}
        noOptionsText="Nenhuma opção encontrada"
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="Ações" />
        )}
      />
    </div>
  );
};

export default ActionBulk;
