import React from "react";
import styles from "./ProductionMovements.module.scss";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";
import { IPosPaid } from "../../interfaces/IPosPaid";
import { ICashless } from "../../interfaces/ICashless";
import { ITicket } from "../../interfaces/ITicket";

interface IProductionMovementsProps {
  posPaidData: IPosPaid | undefined;
  cashlessData: ICashless | undefined
  ticketData: ITicket | undefined
  isLoading: boolean | undefined;
}

const ProductionMovements: React.FC<IProductionMovementsProps> = ({
  posPaidData,
  cashlessData,
  ticketData,
  isLoading,
}) => {
  const { isMobile } = useUi();

  return (
    <div id={styles.ProductionMovements}>
      <div className={styles.topTitle}>
        <p>Produção</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile}>
      {(posPaidData?.totalClosedProduction ?? 0) > 0 && (
          <>
            <div>
              <div className={styles.highlightValue}>
                <h5>Pós-pago</h5>
                <span>{moneyMaskNumber(posPaidData?.productionConsumption ?? 0)}</span>
              </div>
              <p>Comandas <strong>{posPaidData?.totalClosedProduction ?? 0}</strong></p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(posPaidData?.productionServiceFeeValue)}</strong></p>
            </div>
          </>
        )}
        {(cashlessData?.totalProductionCards ?? 0) > 0 && (
          <>
            {
              !isMobile && (posPaidData?.totalClosedProduction ?? 0) > 0 &&
              <div className={styles.separator}></div>
            }
            <div>
              <div className={styles.highlightValue}>
                <h5>Cashless</h5>
                <span>{moneyMaskNumber(cashlessData?.productionConsumedValue ?? 0)}</span>
              </div>
              <p>Cartões: <strong>{cashlessData?.totalProductionCards ?? 0}</strong></p>
              <p>Ativação: <strong>{moneyMaskNumber(cashlessData?.productionActivationValue)}</strong></p>
              <p>Recarga: <strong>{moneyMaskNumber(cashlessData?.productionRechargeValue)}</strong></p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(cashlessData?.productionServiceFeeValue)}</strong></p>
            </div>
          </>
        )}
        {(ticketData?.totalProduction ?? 0) > 0 && (
          <>
            {
              !isMobile &&
              ((posPaidData?.totalClosedProduction ?? 0) > 0 || (cashlessData?.totalProductionCards ?? 0) > 0) &&
              <div className={styles.separator}></div>
            }
            <div>
              <div className={styles.highlightValue}>
                <h5>Ficha</h5>
                <span>{moneyMaskNumber(ticketData?.productionConsumedValue ?? 0)}</span>
              </div>
              <p>Pedidos: <strong>{ticketData?.totalProduction ?? 0}</strong></p>
              <p>Pagamento: <strong>{moneyMaskNumber(ticketData?.productionPaymentValue ?? 0)}</strong></p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(ticketData?.productionServiceFeeValue ?? 0)}</strong></p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductionMovements;
