import GetPromotersFromLocalUseCase from "modules/events/application/useCases/access/GetPromotersFromLocalUseCase";
// import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import { IBaseList } from "modules/events/domain/dto/IEventsResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback, useEffect, useState } from "react";
import { AccessApi } from "services/api/events/access/AccessApi";
// import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { moneyToFloat } from "services/utils/Money";
import { IDiscountProductListForm } from "../discountPeopleListForm/interfaces/IDiscountProductListForm";
import { IGuestListForm, IGuestListFormErrors } from "./interfaces/GuestListForm.types";
import { IPromoters } from "./interfaces/IPromoters";
import { QuantityEnum } from "./interfaces/QuantityEnum";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import { validate } from '../../pages/eventGuestsLists/components/AddGuestListValidation';

const UseGuestListForm = (handleSubmit: (list: IGuestListForm) => void) => {
  const { currentLocal } = useLocal();
  const baseListService = DiscountBaseListApi();
  const accessService = AccessApi();
  

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [baseListId, setBaseListId] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [hour, setHour] = useState<string>();
  const [promotersList, setPromotersList] = useState<IPromoters[]>([]);
  const [mainPromoterId, setMainPromoterId] = useState<string>('');
  const [promotersIdsWithAccess, setPromotersIdsWithAccess] = useState<string[]>([]);
  const [discountLimit, setDiscountLimit] = useState<number>()
  const [discountList, setDiscountList] = useState<IDiscountProductListForm[]>([]);
  const [baseList, setBaseList] = useState<IBaseList[]>([]);

  const [errors, setErrors] = useState<IGuestListFormErrors>({});

  // useEffect(() => {
  // }, [discountLimit]);

  // useEffect(() => {
  // }, [mainPromoterId]);

  // useEffect(() => {
  // }, [baseListId]);

  // useEffect(() => {
  // }, [promotersIdsWithAccess]);
  

  useEffect(() => {
    const getDiscountsList = async () => {
      try {
        if (currentLocal) {
          const response = await GetBaseLIstUseCase(baseListService, currentLocal.id);
          setBaseList(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };
    const getPromotersList = async () => {
      try {
        if (currentLocal) {
          const response = await GetPromotersFromLocalUseCase(accessService, currentLocal.id);
          setPromotersList(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getDiscountsList();
    getPromotersList();
  }, [currentLocal]);

  const handleChangeBaseListId = (id: string) => {
    setBaseListId(id);
  };

  const handleChangeMainPromoter = (promoter: IPromoters) => {
    setMainPromoterId(promoter.id);
  };

  const handleChangePromoterList = (promoters: IPromoters[]) => {
    const promotersIds = promoters.map((it) => it.id);
    setPromotersIdsWithAccess(promotersIds);
  };

  const handleChangeName = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    setName(value);
  };

  const handleChangeDescription = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    setDescription(value);
  };

  const handleChangeDate = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    setDate(value);
  };

  const handleChangeDiscountLimit = useCallback((value: string) => {
    setDiscountLimit(moneyToFloat(value));
  }, []);

  // const handleChangeDiscountLimit = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { value } = evt.target;
  //   setDiscountLimit(Number(value));
  // };

  // const handleChangeHour = useCallback((evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { value } = evt.target;
  //   setHour(value);
  // }, []);

  const handleChangeEvent = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
    evt.preventDefault();
  };

  const handleClearError = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      [property]: null
    })
  }

  const handleSubmitForm = (evt: React.SyntheticEvent) => {
    evt.preventDefault();

    let closingDateTime = date
    let formData = { name, description, closingDateTime, baseListId, discountLimit, mainPromoterId, promotersIdsWithAccess }
    
    // if (!validate(formData, setErrors)) {
    //   closingDateTime = new Date(`${date}`).toISOString();
    //   formData = { name, description, closingDateTime, baseListId, discountLimit, mainPromoterId, promotersIdsWithAccess }
    //   handleSubmit(formData);
    // }
  }

  // const handleChangeQuantity = (value: QuantityEnum) => {
  //   setInvitesQuantity(value);
  // };

  const handleDiscountChange = (values: IDiscountProductListForm[]) => {
    setDiscountList(values);
  };

  return {
    handleChangeName,
    handleChangeDescription,
    handleChangeDate,
    handleSubmitForm,
    baseList,
    handleChangeBaseListId,
    promotersList,
    handleChangeMainPromoter,
    handleChangePromoterList,
    handleChangeDiscountLimit,
    discountLimit,
    errors,
    handleClearError
  };
};

export default UseGuestListForm;
