import Button from "components/ui/Button/Button";
import styles from "./LandingPage.module.scss";
import { Divider } from "@material-ui/core";
import FreeContentCard from "./freeContentCard/FreeContentCard";
import {
  AddCircleOutline,
  ArrowForwardOutlined,
  CheckCircleOutlined,
  SettingsOutlined,
} from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import { PremiumHint } from "modules/plans/presentation/components/premiumHint/PremiumHint";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { PremiumModal } from "modules/plans/presentation/components/premiumModal/PremiumModal";
import { ReactNode, useState } from "react";
import { IIncludedItems } from "./domain/IIncludedItems";

interface LandingPageProps {
  title: string;
  subtitle: string;
  description: string;
  logo: string;
  setOpen: () => void;
  featureType?: FeatureTypeEnum;
  includedItems: IIncludedItems[];
  includedItemsTitle?: ReactNode;
  aboutTitle: ReactNode;
  about: string;
  customFooter?: ReactNode;
  showConnect?: boolean;
  footerImg?: string;
  footerImgLink?: string;
  footerButtonLink?: string;
}

const LandingPage = ({ title, 
    subtitle, 
    description, 
    logo, 
    setOpen, 
    featureType, 
    includedItems, 
    aboutTitle,
    about, 
    includedItemsTitle,
    customFooter,
    showConnect = true,
    footerImg,
    footerImgLink,
    footerButtonLink,
 }: LandingPageProps) => {
  const { isMobile, dimensions } = UseDimension();
  const { checkPermission } = usePlans();
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div id={styles.LandingPageContainer}>
      {
          !!featureType && !checkPermission(featureType) &&
          <PremiumHint />
        }
      <div className={styles.header}>
        <div
          className={`${styles.headerContent} ${
            isMobile && styles.headerContentMobile
          }`}
        >
          <img
            src={logo}
            alt=""
            width={isMobile ? "100px" : "152px"}
          />
          <div className={styles.headerInfo}>
            {isMobile ? (
              <div className={styles.headerInfoMobile}>
                <h1>{title}</h1>
                <p>{subtitle}</p>
              </div>
            ) : (
              <>
                <h1>{title}</h1>
                <p>{subtitle}</p>
              </>
            )}
            <span>
              {description}
            </span>
          </div>
          {!isMobile && showConnect && (
            <Button
              className={styles.headerButton}
              onClick={() => !!featureType && !checkPermission(featureType) ? setModalOpened(true) : setOpen()}
            >
              Integrar
            </Button>
          )}
        </div>
        {isMobile && showConnect && (
          <Button
            className={styles.headerButton}
            onClick={() => setOpen()}
          >
            Fazer integração
          </Button>
        )}
        <Divider />
      </div>
      <div
        className={`${styles.middleContent} ${
          isMobile && styles.middleContentMobile
        }`}
      >
        <span>{aboutTitle}</span>
        <div className={styles.middleInfo}>{about}</div>
      </div>
      <div className={styles.includedContent}>
        <span
          className={`${styles.includedTitle} ${
            isMobile && styles.includedTitleMobile
          }`}
        >
          {includedItemsTitle ?? <>O que está incluso nessa <span className="purpleText">integração</span>:</>}
        </span>
        <div className={styles.cardsContainer}>
          {includedItems.map((item) => (
            <FreeContentCard
              title={item.title}
              description={item.description}
              isMobile={isMobile}
              key={item.title}
              img={item.img}
            />
          ))}
        </div>
      </div>
      {showConnect &&
        <div className={styles.footerButton}>
          <Button
            className={styles.button}
            style={{ width: isMobile ? "21rem" : "" }}
            variant={"contained"}
            color={"primary"}
            fullWidth
            onClick={() => !!featureType && !checkPermission(featureType) ? setModalOpened(true) : setOpen()}
          >
            Quero integrar
          </Button>
        </div>
      }
      {
        !!customFooter ?
        <>{customFooter}</> :
        <div
          className={`${styles.footerContent} ${
            isMobile && styles.footerContentMobile
          }`}
        >
          <div className={styles.footerContent} style={{ padding: "0" }}>
            {footerImg 
              ? (
                <a href={footerImgLink ?? ""} target='_blank' rel="noreferrer">
                  <img src={footerImg} alt="" width={dimensions.width < 450 ? "320px" : ""} />
                </a>
              )
              : (
                <>
                  <div className={styles.footerIcon}>
                    <AddCircleOutline className={styles.icon} />
                  </div>
                  <ArrowForwardOutlined />
                  <div className={styles.footerIcon}>
                    <SettingsOutlined className={styles.icon} />
                  </div>
                  <ArrowForwardOutlined />
                  <div className={styles.footerIcon}>
                    <CheckCircleOutlined className={styles.icon} />
                  </div>
                </>
              )
            }
          </div>
          <div className={styles.footerInfo}>
            <h1>Como funciona?</h1>
            <p>
              <b>Para realizar a integração, é simples!</b><br />
              Clique no botão abaixo e acesse o tutorial com o passo a passo.
            </p>
            <Button
              className={styles.btnFooter}
              variant="outlined"
              onClick={() => footerButtonLink ? window.open(footerButtonLink) : null}
            >
              Como integrar?
            </Button>
          </div>
        </div>
      }

      <PremiumModal opened={modalOpened} onClose={() => setModalOpened(false)} />
    </div>
  );
};
export default LandingPage;
