import Layout from "components/layout/presentation/Layout";
import React, { FC } from "react";
import ProductPage from "./ProductPage";

const Products: FC = () => {
  return (
    <Layout>
      <ProductPage />
    </Layout>
  );
};

export default Products;
