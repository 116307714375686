import { IGetDayTopProductsResponse } from "../../dtos/IGetDayTopProductsResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetDayTopProductsUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetDayTopProductsResponse> => {
  return await service.getDayTopProducts(localId, cashierId, day);
};

export default GetDayTopProductsUseCase;
