import { FC, useCallback, useMemo, useState } from 'react'
import { AlertModal } from '../../alertModal/AlertModal';
import { ICategoryItem } from '../categoryList/ICategoryItem';
import { Autocomplete } from '@mui/material';
import { InputLabel } from '../../ui/InputLabel';
export interface IAlertDelteCategoryProps {
    //propertys
    categories: ICategoryItem[];
    categoryToDelete?: ICategoryItem;
    onConfirm: (category: ICategoryItem, categoryToReceive?: ICategoryItem | null) => void
    onClose: () => void
}
const AlertDeleteCategory: FC<IAlertDelteCategoryProps> = ({ categories, categoryToDelete, onConfirm, onClose }) => {

    const [categoryToReceive, setCategoryToReceive] = useState<ICategoryItem | null>(null);
    const [error, setError] = useState<string | null>(null);
    const onChangeCagetoryToReceive = (category: ICategoryItem | null) => {
        setCategoryToReceive(category ?? null);
    };

    const onConfirmHandle = useCallback(() => {
        setError(null)
        onConfirm(categoryToDelete!, categoryToReceive ?? null)
    }, [categoryToDelete, categoryToReceive, onConfirm]);

    const categoriesFiltered = useMemo(() => categories.filter(item => item.id !== categoryToDelete?.id), [categories, categoryToDelete?.id])

    const onCloseHandle = useCallback(() => {
        setCategoryToReceive(null)
        onClose()
    }, [onClose]);

    return (
        <AlertModal
            open={!!categoryToDelete}
            title={<div>Apagar <b>categoria</b></div>}
            text={<div>Tem certeza que deseja apagar a categoria <b>{categoryToDelete?.description}</b>?</div>}
            subText={"Escolha uma categoria para transferir os produtos"}
            onClose={onCloseHandle}
            confirmLabel={'Apagar'}
            cancelLabel={'Cancelar'}
            onConfirm={onConfirmHandle}
        >
            <Autocomplete
                options={categoriesFiltered ?? []}
                value={categoryToReceive}
                onChange={(_, value) => onChangeCagetoryToReceive(value)}
                getOptionLabel={(option: ICategoryItem) => option.description}
                renderInput={(params) => <InputLabel
                    // label={"Selecione uma categoria:"}
                    {...params}
                    error={!!error}
                    helperText={!!error}
                />}
            ></Autocomplete>
        </AlertModal>
    )
}
export default AlertDeleteCategory