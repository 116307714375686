import { faker } from "@faker-js/faker"
import { AxiosInstance } from "axios"
import { IGetProductsListRequest } from "modules/products/domain/dto/IGetProductsListRequest"
import { IGetProductsListResponse } from "modules/products/domain/dto/IGetProductsListResponse"
import { EnumProductType } from "modules/products/presentation/interfaces/IProductListData"

const ObterListaDeProdutos = async (api: AxiosInstance, request: IGetProductsListRequest): Promise<IGetProductsListResponse> => {
    const params = {
        keyword: request.keyword,
        localId: request.localId,
        page: request.page,
        pageSize: request.pageSize,
        categoriasIds: request.categoriesIds,
        tipoProdutoId: request.productType
    }
    const response = await api.get<IObterProdutoResponse>('/Produto/BuscarProdutosDoLocal', { params })
    const parsedResponse: IGetProductsListResponse = {
        items: response.data.Registros.map((produtoItem) => (
            {
                id: produtoItem.id,
                name: produtoItem.nome,
                imageUrl: `${process.env.REACT_APP_MEEP_CUSTOM_BLOB_URL}/${produtoItem.localId}/${produtoItem.id}.jpg?v=${produtoItem.versaoImagem}`,
                storeId: produtoItem.storeId,
                categoryName: produtoItem.categoriaLocalCliente,
                categoryId: produtoItem.categoriaLocalClienteId,
                price: produtoItem.valor,
                cfop: produtoItem.cfop,
                ncm: produtoItem.ncm,
                cest: produtoItem.cest,
                enabled: produtoItem.ativado,
                type: produtoItem.tipo as EnumProductType
            }
        )),
        count: response.data.QuantidadeTotal,
    }

    return parsedResponse
}

export default ObterListaDeProdutos


interface IObterProdutoResponse {
    QuantidadeTotal: number,
    Registros: IProduto[]
}

interface IProduto {
    id: string,
    localId: string,
    nomeLocal: string,
    produtoId: string,
    nome: string,
    categoriaId: string,
    categoria: string,
    valor: number,
    ativado: boolean,
    destaque: boolean,

    categoriaLocalClienteId: string,
    categoriaLocalCliente: string,

    tipo: unknown, //enum

    ncm?: string,
    cfop?: string,
    cest?: string,

    storeId: string,

    produtoComposicao: boolean,
    versaoImagem: string,
}


export enum EnumTipoProduto {
    PRODUTO = 1,
    RECARGA = 2,
    VARIAVEL = 3,
    PROMOCAO = 4,
    ATIVACAO_CASHLESS_PRE_PAGO = 5,
    DESATIVACAO_CASHLESS_PRE_PAGO = 6,
    ATIVACAO_CASHLESS_POS_PAGO = 7,
    DESATIVACAO_CASHLESS_POS_PAGO = 8,
    ATIVACAO_POS_PAGO_COMANDA = 9,
    DESATIVACAO_POS_PAGO_COMANDA = 10,
    TAXA_DE_SERVICO = 11,
    KILO = 12,
    AJUSTE = 13,
    TAXA_DE_ENTREGA = 14,
    INGRESSO_COM_NOME_NA_LISTA = 15,
    INGRESSO_AVULSO = 16
  }