import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import GetReportOrderUseCase from "../../application/useCases/GetReportOrderUseCase";
import { IOrderItem, IReportOrder } from "../interfaces/IReportOrder";

export interface IParamsClientDiscountCouponsOrderReport {
  pagination?: {
    page?: number;
    pageSize?: number;
  };

  orderId: string
}

const UseReportOrder = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const { currentLocal } = useLocal();

  const [reportOrder, setReportOrder] = useState<IReportOrder>()
  const [recordOrder, setRecordOrder] = useState<IOrderItem[]>()
  const [count, setCount] = useState(20)


  const getReportOrder = useCallback(
    async (params: IParamsClientDiscountCouponsOrderReport) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetReportOrderUseCase(serviceDiscountCoupons,
            currentLocal.id,
            params?.orderId,
            params?.pagination ?? { page: 0, pageSize: 20 },
          );


          if (params?.pagination?.page) {
            setRecordOrder(prev => ([...(prev ?? []), ...response.records]));
            setCount(response.totalRecords);
          } else {
            setRecordOrder(([...response.records]));

            setCount(response.totalRecords);
          }

          setReportOrder(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );

  return {
    isLoading,
    recordOrder,
    getReportOrder,
    reportOrder,
    setReportOrder,    
    count
  };
};

export default UseReportOrder;
