import { api } from "../Api";
import { invoiceUrl } from "Enviroment";
import { IInvoicePublicService } from "modules/invoice/domain/interfaces/IInvoicePublicService";
import { IGetCompanyResponse } from "modules/invoice/domain/dto/IGetCompanyResponse";
import { ISaveCompanyRequest } from "modules/invoice/domain/dto/ISaveCompanyRequest";
import { EnvironmentEnum } from "modules/invoice/presentation/interfaces/EnvironmentEnum";
import { IImportInvoiceConfigFromCsvResponse } from "modules/invoice/domain/dto/IImportInvoiceConfigFromCsvResponse";
import { IGetTaxResponse } from "modules/invoice/domain/dto/IGetTaxResponse";
import { IGetTaxProductsResponse } from "modules/invoice/domain/dto/IGetTaxProductsResponse";
import { ITaxSelect } from "modules/invoice/presentation/interfaces/ITaxSelect";

const InvoicePublicApi = (): IInvoicePublicService => {
  const baseUrl = invoiceUrl;

  const createCompany = async (localId: string, apiKey: string): Promise<IGetCompanyResponse> => {
    const response = await api.post(`${baseUrl}/public/Companies`, { id: localId }, { headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const editCompany = async (localId: string, request: ISaveCompanyRequest, apiKey: string): Promise<IGetCompanyResponse> => {
    const response = await api.put(`${baseUrl}/public/companies/${localId}`, request, { headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getCompany = async (localId: string, apiKey: string): Promise<IGetCompanyResponse> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const validateCertificate = async (localId: string, base64: string, password: string, apiKey: string): Promise<boolean> => {
    const response = await api.post(`${baseUrl}/public/companies/${localId}/certificate/validate`, { base64, password }, { headers: { "x-api-key": apiKey } });
    return response.data.isValid;
  }

  const getEnvironment = async (localId: string, apiKey: string): Promise<EnvironmentEnum> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/environment`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data.environment;
  }

  const updateEnvironment = async (localId: string, environment: EnvironmentEnum, apiKey: string): Promise<void> => {
    const response = await api.put(`${baseUrl}/public/companies/${localId}/environment`, { companyId: localId, environment }, { headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const importInvoiceConfigFromCsv = async (localId: string, csv: string, apiKey: string): Promise<IImportInvoiceConfigFromCsvResponse> => {
    const response = await api.post(`${baseUrl}/public/companies/importInvoiceConfigFromCsv`, { companyId: localId, csv }, { headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getTax = async (localId: string, apiKey: string, ncm?: string, keyword?: string, page = 0, ): Promise<IGetTaxResponse> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax`, { params: { ncm, keyword, page, pageSize: 20, disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getTaxProducts = async (localId: string, cfop: string, ncm: string, apiKey: string): Promise<IGetTaxProductsResponse[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/products`, { params: { Cfop: cfop, Ncm: ncm, disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data || [];
  }

  const getTaxFileUrl = async (localId: string, apiKey: string): Promise<string> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/taxFile`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data.url;
  }

  const getNcmList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/ncm`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getCfopList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/cfop`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getIcmsList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/icms/taxSituations`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getIpiList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/ipi/taxSituations`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getCofinsList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/cofins/taxSituations`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getPisList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/pis/taxSituations`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  const getIcmsSourcesList = async (localId: string, apiKey: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/public/companies/${localId}/tax/selects/icms/sources`, { params: { disableError: true }, headers: { "x-api-key": apiKey } });
    return response.data;
  }

  return {
    createCompany,
    editCompany,
    getCompany,
    validateCertificate,
    getEnvironment,
    updateEnvironment,
    importInvoiceConfigFromCsv,
    getTax,
    getTaxProducts,
    getTaxFileUrl,
    getNcmList,
    getCfopList,
    getIcmsList,
    getIpiList,
    getCofinsList,
    getPisList,
    getIcmsSourcesList,
  }
};

export default InvoicePublicApi;
