import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle
} from "react";
import AddTIDsForm from "../forms/addTIDs/AddTIDsForm";
import { UseTIDsAdicionaisSideSheet } from "./UseTIDsAdicionaisSideSheet";

export interface TIDsAdicionaisSideSheetHandles {
  openSideSheet: (TIDsAdicionaisId?: string) => void;
}

export interface ITIDsAdicionaisSideSheet {
  getTIDsList: () => Promise<void>
}

const TIDsAdicionaisSideSheet: ForwardRefRenderFunction<
  TIDsAdicionaisSideSheetHandles,
  ITIDsAdicionaisSideSheet
> = ({ getTIDsList }, ref) => {
  const {
    isOpen,
    openSideSheet,
    onClose,
    isLoading,
    handleSave,
    handleChangeForm,
    values,
    errors
  } = UseTIDsAdicionaisSideSheet(getTIDsList);

  useImperativeHandle(ref, () => ({
    openSideSheet,
  }));

  const handleContent = useCallback(() => {
    return <AddTIDsForm handleChangeForm={handleChangeForm} values={values} errors={errors} />;
  }, [errors, handleChangeForm, values]);

  return (
    <Sidesheet
      open={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={
        <h2>
          Criar <b>TIDs</b>
        </h2>
      }
      content={handleContent()}
      continueButton={"Adicionar"}
      handleContinueButton={handleSave}
      customizedCancelButton={"Cancelar"}
      cancelButton={true}
      notTotalHeight
      feedback={
        <SidesheetFeedback text={`TIDs cadastrados com sucesso!`} success />
      }
    />
  );
};

export default forwardRef(TIDsAdicionaisSideSheet);
