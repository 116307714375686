import { Checkbox, Switch, TextField } from '@material-ui/core'
import styles from './PaymentForm.module.scss'
import InputMasked from 'components/inputMasked/InputMasked'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { SubscriptionType } from 'modules/saas/domain/interfaces/SubscriptionType';
import { IPlansItem } from '../../interfaces/plans/IPlans';
import { ICheckoutForm } from '../../interfaces/checkout/ICheckoutForm';
import { MenuItem, Select } from '@mui/material';
import { UsePaymentForm } from './UsePaymentForm';
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum';
import RenderIf from 'components/renderIf/RenderIf';

interface PaymentFormProps {
    selectedType: SubscriptionType;
    selectedPlan?: IPlansItem;
    plans: IPlansItem[];
    form: ICheckoutForm;
    wPos: boolean;
    formLoading: boolean;
    setForm: Dispatch<SetStateAction<ICheckoutForm>>;
    onSubmit: (useSameAddress: boolean) => void;
    validate?: boolean;
    type?: ProspectType;
    cieloPosPrice?: string;
    cieloPosFullPrice?: string;
    cieloPosDiscount?: string;
}

export const PaymentForm = ({
    selectedType,
    selectedPlan,
    form,
    setForm,
    plans,
    wPos,
    onSubmit,
    formLoading,
    validate = true,
    type,
    cieloPosPrice,
    cieloPosFullPrice,
    cieloPosDiscount
}: PaymentFormProps) => {
    const [accepted, setAccepted] = useState(false);
    const [useSameAddress, setUseSameAddress] = useState(false);

    const {
        errors,
        setErrors,
        creditCardExpiration,
        setCreditCardExpirationHandle,
        handleSubmit,
        mounParcelValue,
        mountDetailedValue,
        handleSearchByCEP
    } = UsePaymentForm({ selectedPlan, form, setForm, plans, wPos, onSubmit, toValidate: true, useSameAddress });

    useEffect(() => {
        if (useSameAddress) {
            setErrors(prev => ({
                ...prev,
                zipCode: undefined,
                number: undefined,
                street: undefined,
                neighborhood: undefined,
            }));
            setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: {} } } as ICheckoutForm));
        }
    }, [setErrors, setForm, useSameAddress]);

    const mountInstallmentsSelect = useMemo(() => {
        return !!plans.find(x => x.id === selectedPlan?.id)?.price &&
            selectedPlan?.subscriptionType === SubscriptionType.ANNUAL &&
            type !== ProspectType.CieloEvent &&
            <div className={styles.row}>
                <div className={styles.column}>
                    <label htmlFor='installment' className={styles.required}>Parcelamento</label>
                    <Select
                        name='installment'
                        value={form.creditCardPayment?.installment}
                        style={{ width: "100%" }}
                        size="small"
                        onChange={(e: any) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, installment: e.target.value } }))}
                    >
                        {
                            Array.from(Array(12).keys()).map((x, index) => (
                                <MenuItem value={index + 1} key={index + 1}>
                                    {index + 1}x de {type === ProspectType.CieloEstablishment ? mounParcelValue(index + 1, selectedPlan, cieloPosPrice) : mounParcelValue(index + 1, selectedPlan)}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </div>
            </div>
    }, [cieloPosPrice, form.creditCardPayment?.installment, mounParcelValue, plans, selectedPlan, setForm, type]);

    return (
        <div className={styles.container}>
            <RenderIf condition={selectedPlan?.subscriptionType !== 4}>
                <h1 className={styles.addressTitle} style={{ margin: "16px 0" }}>Pagamento</h1>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor="discountCoupon">Cupom de desconto</label>
                        <TextField
                            variant="outlined"
                            placeholder='Digite seu cupom de desconto'
                            fullWidth
                            size='small'
                            name="discountCoupon"
                            value={form.planSelected?.discountCoupon}
                            onChange={(e) => setForm(prev => ({ ...prev, planSelected: { ...prev.planSelected, discountCoupon: e.target.value } }))}
                        />
                    </div>
                    <div className={styles.column} style={{ flex: 0 }}></div>
                    {!type && selectedPlan && (
                        <div className={`${styles.column} ${styles.columnValue}`}>
                            <span className={styles.posValue}>{mountDetailedValue(selectedPlan)}</span>
                            <span className={styles.value}>Valor total: <b>{mounParcelValue(form.creditCardPayment.installment, selectedPlan)}/mês</b></span>
                        </div>
                    )}
                    {!!type && (
                        type === ProspectType.CieloEvent
                            ? (
                                <div className={`${styles.column} ${styles.columnValue}`}>
                                    <span className={styles.posValue}>Evento</span>
                                    <span className={styles.value}>
                                        {cieloPosFullPrice ? <span className={styles.fullPrice}>{cieloPosFullPrice}</span> : "Valor total:"} <b>{cieloPosPrice}/mês</b>
                                    </span>
                                    {!!cieloPosDiscount && <span className={styles.discount}>🤑 Você está economizando <b>{cieloPosDiscount}</b></span>}
                                </div>
                            ) : (
                                selectedPlan &&
                                <div className={`${styles.column} ${styles.columnValue}`}>
                                    <span className={styles.posValue}>{mountDetailedValue(selectedPlan)}{cieloPosPrice ? ` + ${cieloPosPrice} de licença` : ""}</span>
                                    <span className={styles.value}>Valor total: <b>{mounParcelValue(form.creditCardPayment.installment, selectedPlan, cieloPosPrice)}/mês</b></span>
                                </div>
                            )
                    )
                    }
                </div>

                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor='cardNumber' className={styles.required}>Número do cartão</label>
                        <InputMasked
                            variant="outlined"
                            mask='9999 9999 9999 9999'
                            placeholder='9999 9999 9999 9999'
                            fullWidth
                            name='cardNumber'
                            value={form.creditCardPayment?.cardNumber}
                            onChange={(e:any) => {
                                setErrors(prev => ({ ...prev, cardNumber: undefined }));
                                setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, cardNumber: e.target.value.replace(/\s+/g, "") } }));
                            }}
                            error={!!errors.cardNumber}
                            helperText={errors.cardNumber}
                            type="tel"
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor='cardHolderName' className={styles.required}>Nome no cartão</label>
                        <TextField
                            variant="outlined"
                            placeholder='Digite igual no cartão'
                            fullWidth
                            size='small'
                            name='cardHolderName'
                            value={form.creditCardPayment?.cardHolderName}
                            onChange={(e) => {
                                setErrors(prev => ({ ...prev, cardHolderName: undefined }));
                                setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, cardHolderName: e.target.value } }));
                            }}
                            error={!!errors.cardHolderName}
                            helperText={errors.cardHolderName}
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.column}>
                        <label htmlFor='document' className={styles.required}>Documento</label>
                        {/*<InputMasked
                            name='document'
                            variant='outlined'
                            fullWidth
                            mask={mask}
                            placeholder="Digite o CPF do titular"
                            value={form.creditCardPayment?.document}
                            onChange={handleDocumentChange}
                            error={!!errors.document}
                            helperText={errors.document}
                            type="tel"
                        />*/}
                        <TextField
                            name='document'
                            variant='outlined'
                            fullWidth
                            placeholder="Digite o CPF ou CNPJ do titular"
                            value={form.creditCardPayment?.document}
                            onChange={(e) => {
                                setErrors(prev => ({ ...prev, document: undefined }));
                                setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, document: DocumentMask(e.target.value) } }));
                            }}
                            error={!!errors.document}
                            helperText={errors.document}
                            type="tel"
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.row} style={{ flexDirection: "row", marginBottom: 0 }}>
                        <div className={styles.column}>
                            <label htmlFor='dueDate' className={styles.required}>Validade</label>
                            <InputMasked
                                name='dueDate'
                                variant='outlined'
                                fullWidth
                                mask="99/99"
                                placeholder="MM/AA"
                                value={creditCardExpiration}
                                onChange={(e: any) => setCreditCardExpirationHandle(e.target.value)}
                                error={!!errors.creditCardExpiration}
                                helperText={errors.creditCardExpiration}
                                type="tel"
                            />
                        </div>
                        <div className={styles.column}>
                            <label htmlFor='cvv' className={styles.required}>CVV</label>
                            <InputMasked
                                name='cvv'
                                variant='outlined'
                                fullWidth
                                mask="999"
                                placeholder="000"
                                value={form.creditCardPayment?.cvv}
                                onChange={(e: any) => {
                                    setErrors(prev => ({ ...prev, cvv: undefined }));
                                    setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, cvv: e.target.value } }));
                                }}
                                error={!!errors.cvv}
                                helperText={errors.cvv}
                                type="tel"
                            />
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.cardsContainer}`}>
                        <img src="/assets/icon/saas/cards/visa.svg" alt="" />
                        <img src="/assets/icon/saas/cards/master.svg" alt="" />
                        <img src="/assets/icon/saas/cards/amex.svg" alt="" />
                        <img src="/assets/icon/saas/cards/apple.svg" alt="" />
                        <img src="/assets/icon/saas/cards/google.svg" alt="" />
                    </div>
                </div>

                {mountInstallmentsSelect}

                <div className={styles.separator} />

                <h1 className={styles.addressTitle}>Endereço de cobrança</h1>
                <div className={styles.useSameAddress}>
                    <Switch value={useSameAddress} onChange={() => setUseSameAddress(!useSameAddress)} />
                    <p>Usar o mesmo endereço cadastrado do estabelecimento</p>
                </div>

                <div className={styles.addressContainer} style={{ opacity: useSameAddress ? 0 : 1 }}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="zipCode" className={styles.required}>CEP</label>
                            <InputMasked
                                name="zipCode"
                                variant="outlined"
                                fullWidth
                                mask="99999-999"
                                value={form?.creditCardPayment.billingAddress.zipCode ?? ""}
                                onChange={(ev: any) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: { ...prev.creditCardPayment.billingAddress, zipCode: ev.target.value } } }))}
                                helperText={errors.zipCode}
                                error={!!errors.zipCode}
                                type="tel"
                                onBlur={(ev) => handleSearchByCEP(ev.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="street" className={styles.required}>Endereço</label>
                            <TextField
                                name="street"
                                value={form?.creditCardPayment.billingAddress.street ?? ""}
                                onChange={(ev) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: { ...prev.creditCardPayment.billingAddress, street: ev.target.value } } }))}
                                variant="outlined"
                                fullWidth
                                size="small"
                                helperText={errors.street}
                                error={!!errors.street}
                                placeholder="Digite seu endereço"
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="addressNumber" className={styles.required}>Número</label>
                            <TextField
                                name="addressNumber"
                                value={form?.creditCardPayment.billingAddress.number ?? ""}
                                onChange={(ev) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: { ...prev.creditCardPayment.billingAddress, number: ev.target.value } } }))}
                                variant="outlined"
                                fullWidth
                                size="small"
                                helperText={errors.number}
                                error={!!errors.number}
                                type="tel"
                            />
                        </div>
                        <div className={styles.column}>
                            <label htmlFor="neighborhood" className={styles.required}>Bairro</label>
                            <TextField
                                name="neighborhood"
                                value={form?.creditCardPayment.billingAddress.neighborhood ?? ""}
                                onChange={(ev) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: { ...prev.creditCardPayment.billingAddress, neighborhood: ev.target.value } } }))}
                                variant="outlined"
                                fullWidth
                                size="small"
                                helperText={errors.neighborhood}
                                error={!!errors.neighborhood}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor='addressComplement'>Complemento</label>
                            <TextField
                                name='addressComplement'
                                value={form?.creditCardPayment.billingAddress.complement ?? ""}
                                onChange={(ev) => setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, billingAddress: { ...prev.creditCardPayment.billingAddress, complement: ev.target.value } } }))}
                                variant="outlined"
                                fullWidth
                                size='small'
                            />
                        </div>
                    </div>
                </div>
            </RenderIf>

            <div className={`${styles.row} ${styles.transition}`} style={{ marginTop: useSameAddress ? -320 : 0, position: 'relative', zIndex: useSameAddress ? 9 : 'inherit' }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox className={styles.termsCheckbox} checked={accepted} onChange={() => setAccepted(!accepted)} />
                    <span className={styles.terms}>
                        Ao continuar, declaro que concordo com os <a target="_blank" rel="noreferrer" href="https://975d0d01-7cfd-477b-86b2-035f8412c27c.usrfiles.com/ugd/975d0d_aa0583717f694182961ba85f3412d0bb.pdf">Termos de Uso e Adesão da Mee</a>
                    </span>
                </div>
                {/* REGULAR SUBSCRIPTION */}
                <RenderIf condition={selectedPlan?.subscriptionType !== 4}>
                    <button
                        disabled={!accepted || formLoading}
                        onClick={handleSubmit}
                        className={styles.continueButton}
                    >
                        {
                            formLoading ? 'Enviando...' : 'Continuar'
                        }
                    </button>
                </RenderIf>

                {/* FREEMIUM SUBSCRIPTION */}
                <RenderIf condition={selectedPlan?.subscriptionType === 4}>
                    <button className={styles.continueButton} disabled={!accepted} onClick={handleSubmit}>
                        {formLoading ? 'Enviando...' : 'Continuar Free'}
                    </button>
                </RenderIf>
            </div>
        </div>
    )
}




export const DocumentMask = (v: string) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
}