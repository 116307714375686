import { memo, ReactNode, useMemo, useState } from 'react'
import { Icon, IconButton } from '@material-ui/core';
import RankingListItem from './IRankingListItem';
import styles from './RankingList.module.scss'
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 } from 'uuid';
import { Pagination } from '@mui/material';
import { Skeleton } from '../skeleton/Skeleton';

export interface IRankingItem {

    id?: string,
    imgUrl?: string,
    name?: string;
    localId?: string,
    color?: string,
    isCancelled?: boolean,
    [key: string]: any;

}
export interface IRankingListProps<T> {
    //propertys
    list?: T[],
    height?: number,
    formatTitle?: (property: string) => string
    onClick?: (value: T) => void
    title?: string
    colunms: {
        [key: string]: {
            title?: string,
            onClickSort?: (key: string) => void
            formatValue?: (value: any) => string
            hide?: boolean
            iconButtonStyles?: {}

        }
    }
    accordeon?: (item: T) => ReactNode;
    getColorItem?: (id?: string) => string;
    getMore?: (filter?: { pagination?: { page?: number, pageSize?: number } }) => void
    count?: number
    infinitScroll?: boolean;
    isLoading?: boolean;
    header?: ReactNode;
    pagination?: { page?: number, pageSize?: number }
    nested?: boolean
}

const RankingList = <T extends IRankingItem,>({ 
    list,
    onClick,
    isLoading = true, 
    title, 
    pagination, 
    colunms, 
    getMore, 
    count,
    accordeon,
    getColorItem,
    header,
    nested
    }: IRankingListProps<T>) => {

    const [firstLoad, setFirstLoad] = useState(true);

    const randonId = v4();
    const lista = useMemo(() => (
        list?.map((item, index) => (
            <RankingListItem
                item={item}
                getColorItem={getColorItem}
                colunms={colunms}
                onClick={() =>  onClick?.(item)}
                color={"#" + item.color}
                accordeon={accordeon}
                key={item.id}
                clickable={onClick ? true : false}
            />
        ))), [accordeon, colunms, getColorItem, list, onClick])

    return (
        <div className={`${styles.RankingLive} ${nested ? styles.nested : ''}`} id={randonId} style={{ overflowY: 'scroll', height: pagination ? `unset` : 500 }}>
            <h2> {title}</h2>
            <section className={styles.wrapper}>
                <div>
                    {header}
                </div>
                <div className={styles.header} >
                    {
                        colunms && Object.keys(colunms)?.map(key => key !== "id" && key !== 'imgUrl' && !colunms[key].hide ?
                            <span className={!colunms[key].title ? styles.whiteSpace : ""} key={key.toString()}>{colunms[key].title}{colunms[key].onClickSort && <IconButton onClick={() => colunms[key]?.onClickSort?.(key)} size='small' ><Icon style={colunms[key].iconButtonStyles}>swap_vert</Icon></IconButton>}</span>//TITULO
                            :
                            <span className={styles.img} key={key.toString()}></span>
                        )
                    }
                    {accordeon && <div className={styles.accordeonButton} />}
                </div>
                <div className={styles.container} >
                    {


                        !list?.length ?
                            <section className={styles.wrapper}>
                                {isLoading ?
                                        <Skeleton items={pagination?.pageSize ?? 5} cardStyle={styles.skelletonCardStyle} />
                                        :                                       
                                        <div>Nenhum resultado</div>
                                    }
                            </section>
                            :
                            pagination ?
                                <>
                                    {isLoading ?
                                        <Skeleton items={pagination?.pageSize ?? 5} cardStyle={styles.skelletonCardStyle} />
                                        :
                                        lista
                                    }
                                    <Pagination 
                                        count={Math.ceil((count ?? 1) / (pagination.pageSize ?? 1))} 
                                        page={pagination.page} 
                                        disabled={count ? list.length >= count : false}
                                        onChange={(_, page) => getMore?.({ pagination: { page: page - 1, pageSize: pagination?.pageSize } })} 
                                    ></Pagination>
                                </>
                                :
                                isLoading ? <Skeleton items={firstLoad ? 5 : 3} /> :
                                    <InfiniteScroll
                                        next={() => {
                                            setFirstLoad(true);
                                            getMore?.();
                                        }}
                                        hasMore={count ? list.length < count : false}
                                        loader={<Skeleton />}
                                        dataLength={list.length}
                                        scrollableTarget={randonId}
                                    >
                                        {lista}
                                    </InfiniteScroll>

                    }
                </div>
            </section >
        </div >
    )
}
export default memo(RankingList)




