import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { PostSankhyaConfigurationUseCase } from "modules/config/integrationsConfig/application/useCases/PostSankhyaConfigurationUseCase copy";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { ISankhyaConfigurationRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationRequest";
import { ISankhyaPaymentRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentRequest";
import { PostSankhyaPaymentUseCase } from "modules/config/integrationsConfig/application/useCases/PostSankhyaPaymentUseCase";
import { GetSankhyaConfigurationUseCase } from "modules/config/integrationsConfig/application/useCases/GetSankhyaConfigurationUseCase";
import { IGetSankhyaConfigurationResponse } from "modules/config/integrationsConfig/domain/dto/IGetSankhyaConfigurationResponse";
import GetSankhyaPaymentFormUseCase from "modules/config/integrationsConfig/application/useCases/GetSankhyaPaymentFormUseCase";
import { IGetSankhyaPaymentFormResponse } from "modules/config/integrationsConfig/domain/dto/IGetSankhyaPaymentFormResponse";
import DeleteSankhyaPaymentUseCase from "modules/config/integrationsConfig/application/useCases/DeleteSankhyaPaymentUseCase";

const service = IntegrationApi();

export const UseConfigSankhya = () => {
  const [loading, setLoading] = useState(true);
  const [paymentForms, setPaymentForms] =
    useState<IGetSankhyaPaymentFormResponse[]>();
  const [configValues, setConfigValues] =
    useState<IGetSankhyaConfigurationResponse | null>(null);
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getSankhyaConfig = useCallback(
    async () => {
      if (currentLocal) {
        try {
          setLoading(true);
          const response = await GetSankhyaConfigurationUseCase(
            service,
            currentLocal.id
          );
          setConfigValues(response);
        } finally {
          setLoading(false);
        }
      }
    }, [currentLocal])

  const createAndUpdateConfig = useCallback(
    async (payloadConfigValues: ISankhyaConfigurationRequest) => {
      try {
        setLoading(true);
        await PostSankhyaConfigurationUseCase(service, payloadConfigValues);
        getSankhyaConfig();
        return "ok";
      } catch {
        toast(
          "Não foi possível criar a configuração. Tente novamente",
          "error"
        );
      } finally {
        setLoading(false);
      }
    },
    [getSankhyaConfig, toast]
  );

  const getSankhyaPaymentForm = useCallback(
    async () => {
      if (currentLocal) {
        try {
          setLoading(true);
          const response = await GetSankhyaPaymentFormUseCase(
            service,
            currentLocal.id
          );
          setPaymentForms(response);
        } finally {
          setLoading(false);
        }
      }
    }, [currentLocal]);

  const createPaymentForm = useCallback(
    async (paymentValues: ISankhyaPaymentRequest) => {
      try {
        setLoading(true);
        await PostSankhyaPaymentUseCase(service, paymentValues);
        await getSankhyaConfig();
        return "ok";
      } catch {
        toast(
          "Não foi possível criar o método de pagamento. Tente novamente",
          "error"
        );
      } finally {
        setLoading(false);
      }
    },
    [getSankhyaConfig, toast]
  );

  const deleteSankhyaPayment = useCallback(
    async (paymentTypeId: string) => {
      try {
        setLoading(true);
        await DeleteSankhyaPaymentUseCase(service, paymentTypeId);
        await getSankhyaConfig();
        toast(
          "Método de pagamento excluído com sucesso!",
          "success"
        );
        return "ok";
      } catch {
        toast(
          "Não foi possível excluir o método de pagamento. Tente novamente",
          "error"
        );
      } finally {
        setLoading(false);
      }
    },
    [getSankhyaConfig, toast]
  );

  useEffect(() => {
    getSankhyaConfig();
    getSankhyaPaymentForm();
  }, [getSankhyaConfig, getSankhyaPaymentForm]);  

  return {
    loading,
    configValues,
    paymentForms,
    createAndUpdateConfig,
    createPaymentForm,
    deleteSankhyaPayment,
  };
};
