import { FC } from "react";
import { ImportExport } from "@mui/icons-material";
import styles from "./TableHeader.module.scss";

const titleHeader = ["Equipamento", "Modelo", "Tipo", "Bateria", "Bobina", "Operador", "Sincronizado", "Status", "Versão"];

const TableHeader: FC = () => {
  return (
    <div id={styles.TableHeader}>
      {titleHeader.map((item) => {
        return (
          <div className={styles.container}>
            <span>{item}</span>
            <ImportExport />
          </div>          
        );
      })}
    </div>
  );
};
export default TableHeader;
