import { request } from "services/api/Api";
import { CashiersTypesCollection, GetCashiersListProps } from "../types";

const GetCashiersList = async (props: GetCashiersListProps) => {
  return await request<CashiersTypesCollection>({
    url: "/Reports/Sales/Cashiers",
    params: {
      ...props,
    },
  });
};

export { GetCashiersList };
