import { AxiosInstance } from "axios";
import { IGetCashiersResponse } from "modules/cashlessDashboard/domain/dto/getCashiers/IGetCashiersResponse";
import { FormatCashierDate } from "pages/private/relatorio/components/OutputReport/utils";
import { CashiersTypesCollection } from "services/api/outputreport";

export interface IBuscarCaixasResponse {
  sessaoId: string;
  dataInicio?: string;
  dataFim?: string;
  sessaoNome: string;
}

export const getBuscarCaixas = async (
  api: AxiosInstance,
  localId: string,
  startDate?: Date,
  endDate?: Date
): Promise<IGetCashiersResponse[]> => {
  const response = await api.get<CashiersTypesCollection>(
    "/Reports/Sales/Cashiers",
    {
      params: {
        localId,
        startDate,
        endDate,
      },
    }
  );

  const parsedResponse = response.data.map<IGetCashiersResponse>((item) => ({
    id: item.Id,
    name: FormatCashierDate({
      startDate: item.StartDate,
      endDate: item.EndDate,
    }),
    startDate: item.StartDate,
    endDate: item.EndDate,
    localId,
  }));

  return parsedResponse;
};
