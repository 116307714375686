import { Close, OpenInNewOutlined } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import { useCallback, useEffect, useState } from "react";
import styles from "./SmartPrinterPage.module.scss";
import { UseSmartPrinterPage } from "./UseSmartPrinterPage";
import { IconButton } from "@mui/material";
import { AddCircle, AddCircleOutline } from "@mui/icons-material";
import SaasTableHeader from "../components/saasTableHeader/SaasTableHeader";
import PrintersList from "../components/printersList/PrintersList";
import { useHistory } from "react-router-dom";
import PrinterSectorConfigForm, { } from "../components/printerSectorConfig/PrinterSectorConfig";
import PrintersInfoModal from "../components/printersInfoModal/PrintersInfoModal";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import PrintersFragment from "../components/printersFragment/PrintersFragment";
import UseDimension from "components/dimension/UseDimension";

export const SaasSmartPrinterPage = () => {
    const {
        list,
        getProductList,
        getPrinterList,
        getDeviceProfiles,
        onSubmit,
        getSmartPrinters,
        defaultValues,
        selectedDeleteSmartPrinter,
        selectedSmartPrinter,
        deleteSmartPrinterOpenModal,
        deleteSmartPrintCloseModal,
        deletePrinterSmart,
        handleEditSmartPrinterModal,
        handleCloseSmartPrinterEditModal,
        handleOpenAddSmartPrinterModal,
        handleCloseAddSmartPrinterModal,
        openAddSmartPrinter,
        getTableList,
        getTotemList,
        currentLocal,
    } = UseSmartPrinterPage();
    const history = useHistory();
    const { dimensions } = UseDimension();

    useEffect(() => {
        getSmartPrinters();
    }, [getSmartPrinters]);


    const [bannerOpen, setBannerOpen] = useState(true);
    const [openAddNewPrinter, setOpenAddNewPrinter] = useState(false);
    const [openModal, setOpenModal] = useState(false);


    const navigateIntegrations = useCallback(() => {
        history.push("/private/automatizacao/integrations", { hub: true });
    }, [history]);

    return (
        <div id={styles.SaasSmartPrinterPage}>
            {bannerOpen && (
                <div className={styles.bannerConfig}>
                    <IconButton className={styles.bannerCloseButton} onClick={() => setBannerOpen(false)} size="small"><Close /></IconButton>
                    <div>
                        <h2><b>Impressão</b></h2>
                        <span>Imprima remotamente nas impressoras do seu estabelecimento. Você pode direcionar o que deseja imprimir de acordo com a sua operação.</span>
                        <a href="https://www.youtube.com/shorts/kFLEFz581kA" target="_blank" rel="noreferrer">Como configuro minha impressão?</a>
                    </div>
                    <img src="/assets/icon/saas/banner-print.png" alt="" />
                </div>
            )}

            {currentLocal?.systemIdentifier === SystemIdentifierEnum.General && (
                <div className={styles.pageTitle}>
                    <div>
                        <p>Impressoras</p>
                        <IconButton onClick={() => setOpenAddNewPrinter(true)}><AddCircle /></IconButton>
                    </div>
                    <p>Você possui {list.count} impressoras</p>
                </div>
            )}

            <div className={styles.tableHeader}>
                {list.count
                    ? <>
                        <SaasTableHeader />
                        {dimensions.width > 500 ? <div className={styles.lastHeader} /> : null}
                    </>
                    : <div />
                }
                <div className={styles.topButtons}>
                    <Button variant="outlined" onClick={() => setOpenModal(true)}>
                        Entenda como funciona
                    </Button>
                    <Button onClick={() => handleOpenAddSmartPrinterModal()} endIcon={<AddCircleOutline />}>
                        Nova impressão
                    </Button>
                </div>
            </div>

            <div className={styles.container} >
                {
                    list.count
                        ? list.list.map((item) => (
                            <PrintersList
                                key={item.id}
                                item={item}
                                onClickEdit={(item) => handleEditSmartPrinterModal(item)}
                                onClickDelete={(item) => deleteSmartPrinterOpenModal(item)}
                            />
                        )) : (
                            <div className={styles.empty}>
                                <div>
                                    <img src="/assets/icon/print-empty.png" alt="" />
                                    <p>Parece que você ainda não tem o serviço de impressão </p>
                                    <span>Não se preocupe, é simples:</span>
                                </div>
                                <div>
                                    <span>
                                        1. Acesse o menu <b style={{ color: "#955CFF" }}>‘Integrações’</b>
                                        <IconButton onClick={navigateIntegrations}><OpenInNewOutlined fontSize="small" /></IconButton>
                                    </span>
                                    <span>
                                        2. Vá até a opção <b>‘Meep Hub’</b>
                                    </span>
                                    <span>
                                        3. Clique em ‘Conectar’
                                    </span>
                                    <span>
                                        4. Depois disso, continue por aqui!
                                    </span>
                                </div>
                            </div>
                        )
                }
            </div>

            <Sidesheet
                open={openAddNewPrinter}
                onClose={() => setOpenAddNewPrinter(false)}
                title={<h2>Impressoras</h2>}
                content={<PrintersFragment onClose={() => setOpenAddNewPrinter(false)} />}
                currentStep={1}
                totalSteps={1}
            />
            <Sidesheet
                open={openAddSmartPrinter}
                onClose={() => handleCloseAddSmartPrinterModal()}
                title={<h2>Impressão</h2>}
                content={
                    <PrinterSectorConfigForm
                        isSaas={currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas}
                        onClose={() => handleCloseAddSmartPrinterModal()}
                        getPrinters={getPrinterList}
                        getDeviceProfiles={getDeviceProfiles}
                        onSubmmit={onSubmit}
                        defaultValues={defaultValues}
                        getProducts={getProductList}
                        getTables={getTableList}
                        getTotems={getTotemList}
                    />
                }
                currentStep={1}
                totalSteps={1}
            />
            <Sidesheet
                open={!!selectedSmartPrinter}
                onClose={() => handleCloseSmartPrinterEditModal()}
                title={<h2>Impressão</h2>}
                content={
                    <PrinterSectorConfigForm
                        isSaas={currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas}
                        getTables={getTableList}
                        getTotems={getTotemList}
                        onClose={() => handleCloseSmartPrinterEditModal()}
                        defaultValues={defaultValues}
                        getPrinters={getPrinterList}
                        getDeviceProfiles={getDeviceProfiles}
                        onSubmmit={onSubmit}
                        getProducts={getProductList}
                    />
                }
                currentStep={1}
                totalSteps={1}
            />
            <Sidesheet
                open={!!selectedDeleteSmartPrinter}
                onClose={deleteSmartPrintCloseModal}
                title={<h2>Remover <b>impressão</b></h2>}
                content={
                    <SidesheetFeedback
                        text={
                            <span style={{ lineHeight: "150%" }}>
                                Deseja realmente remover a impressão? <br />Você não poderá mais alterar as configurações.
                            </span>
                        }
                    />
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Remover"
                handleContinueButton={deletePrinterSmart}
                cancelButton
            />

            {openModal && <PrintersInfoModal open={openModal} onClose={() => setOpenModal(false)} />}
        </div>
    )
}
