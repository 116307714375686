import { ICashierItem } from "modules/cashlessDashboard/presentation/components/filter/ICashier";
import styles from "./CashiersSidesheet.module.scss";
import { format } from "date-fns";
import { Skeleton } from "@mui/material";

interface ICashiersSidesheetProps {
    cashiersByPeriod?: ICashierItem[];
    isLoading: boolean;
}

const CashiersSidesheet = ({ cashiersByPeriod, isLoading }: ICashiersSidesheetProps) => {
    const skeletons = Array.from({ length: 4 }, (_, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: '30px', marginBottom: "8px" }} />
    ));

    return (
        <div id={styles.CashiersSidesheet}>
            {isLoading && (
                <div style={{ marginTop: "1rem" }}>
                    {skeletons}
                </div>
            )}
            {!isLoading && (
                cashiersByPeriod?.length ? cashiersByPeriod.map((cashier) => {
                    if (cashier.startDate && cashier.endDate) {
                        return (
                            <div className={styles.cashier} key={cashier.id}>
                                {format(new Date(cashier.startDate + "Z"), "dd/MM/yyyy', 'HH:mm")} - {format(new Date(cashier.endDate + "Z"), "dd/MM/yyyy', 'HH:mm")}
                            </div>
                        )
                    }
                    return;
                }) : (
                    <div>
                        <span>Não há caixas para o período selecionado.</span>
                    </div>
                )
            )}
        </div>
    )
}

export default CashiersSidesheet;