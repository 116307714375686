import Layout from "components/layout/presentation/Layout";
import { FormServicePage } from "modules/schedule/presentation/pages/formService/FormServicePage";
import React, { FC } from "react";

const EditServicesSchedule: FC = () => {
  return (
    <Layout>
      <FormServicePage />
    </Layout>
  );
};
export default EditServicesSchedule;
