import { IAddTIDForm, IAddTIDFormErrors } from "modules/tidsAdicionais/presentation/interfaces/ITIDsAddForm";

export const validate = (
  values: IAddTIDForm,
  setErrors: React.Dispatch<React.SetStateAction<IAddTIDFormErrors>>
) => {
  let _errors: IAddTIDFormErrors = {};
  let hasError = false;
  if ((!values?.MerchantDocument || values.MerchantDocument.length === 0)) {
    _errors = { ..._errors, MerchantDocument: "Campo obrigatório" };
    hasError = true;
  } else if (values.MerchantDocument.length !== 11) {
    _errors = { ..._errors, MerchantDocument: "Documento inválido" };
    hasError = true;
  }
  if (!values.AcquirerMerchantId) {
    _errors = { ..._errors, AcquirerMerchantId: "Campo obrigatório" };
    hasError = true;
  }
  if (!values.Tids) {
    _errors = { ..._errors, Tids: "Campo obrigatório" };
    hasError = true;
  }
  if (!values.PayStoreAcquirerType) {
    _errors = { ..._errors, PayStoreAcquirerType: "Campo obrigatório" };
    hasError = true;
  }
  if ((!values.QuantityTids || values.QuantityTids === 0)) {
    _errors = { ..._errors, QuantityTids: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};
