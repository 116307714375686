import { IGetPaymentLinkRecurrenceParams } from "modules/paymentLinkReport/domain/dto/IGetPaymentLinkRecurrenceParams";
import { IPaymentLinkRecurrencefilter } from "modules/paymentLinkReport/presentation/components/paymentLinkRecurrenceFilter/interfaces/IPaymentLinkRecurrencefilter";


const ParseFilterToRequestUseCase = (localId?: string, pagination?: { page?: number, pageSize?: number }, filter?: IPaymentLinkRecurrencefilter) => {

  const request: IGetPaymentLinkRecurrenceParams = {
    localId: localId,
    isActive: filter?.isActive,
    linkName: filter?.linkName,
    productId: filter?.productId,
    document: filter?.document,
    signatureDate: filter?.signatureDate,
    value: filter?.value,
    page: pagination?.page,
    pageSize: pagination?.pageSize
  };

  return request;
};

export default ParseFilterToRequestUseCase

