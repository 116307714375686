import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { IBaseList } from "modules/events/domain/dto/IEventsResponse";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";
import styles from "./GuestListForm.module.scss";
import { IGuestListForm } from "./interfaces/GuestListForm.types";
import { IPromoters } from "./interfaces/IPromoters";
import UseGuestListForm from "./UseGuestListForm";
import moment from 'moment';
import 'moment/locale/pt-br';

export interface IGuestListFormProps {
  handleSubmit: (list: IGuestListForm) => void;
  handleCancel?: () => void;
}

const GuestListForm: FC<IGuestListFormProps> = ({ handleSubmit, handleCancel }) => {
  const {
    handleChangeName,
    handleChangeDescription,
    handleChangeDate,
    handleSubmitForm,
    baseList,
    handleChangeBaseListId,
    promotersList,
    handleChangeMainPromoter,
    handleChangePromoterList,
    handleChangeDiscountLimit,
    discountLimit,
    errors,
    handleClearError
  } = UseGuestListForm(handleSubmit);

  // const renderQuantitySelection = (value: QuantityEnum) => {
  //   switch (value) {
  //     case QuantityEnum.TOTAL:
  //       return (
  //         <>
  //           <Input type="number" label="Quantidade de convidados da lista" />
  //           <p>Os Promoters podem adicionar convidados até atingir a o limite desta lista.</p>
  //         </>
  //       );
  //     case QuantityEnum.FRACAO:
  //       return (
  //         <>
  //           <Input type="number" label="Quantidade por Promoter" />
  //           <p>Os Promoters podem adicionar convidados até atingir a quantidade permitida por Promoter.</p>
  //         </>
  //       );
  //     case QuantityEnum.INDIVIDUAL:
  //       return (
  //         <>
  //           {promotersAccess?.map((promoter, key) => (
  //             <Input key={key} type="number" label={promoter.name} />
  //           ))}
  //           {!!promotersAccess?.length ? (
  //             <p>Os Promoters podem adicionar convidados até atingir seu limite de convidados.</p>
  //           ) : (
  //             <p>Adicione os Promoters que poderão adicionar convidados a esta lista.</p>
  //           )}
  //         </>
  //       );
  //     case QuantityEnum.ILIMITADO:
  //       return <p>Os Promoters podem adicionar convidados a lista até atingir a lotação total do local do evento.</p>;
  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <form id={styles.GuestListForm} onSubmit={handleSubmitForm}>
      <div>
        <div>
          <h2>Informações da Lista</h2>
        </div>
        <div className={styles.listName}>
          <div>
            <Input
              
              fullWidth={false}
              label="Nome"
              placeholder="Escreva o nome da Lista"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 50,
              }}
              onChange={handleChangeName}
              error={!!errors.name}
              helperText={errors.name}
              onFocus={handleClearError('name')}
            />
            <Input
              
              fullWidth={false}
              label="Descrição"
              placeholder="Escreva a descrição da lista"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={handleChangeDescription}
              error={!!errors.description}
              helperText={errors.description}
              onFocus={handleClearError('description')}
            />
          </div>
        </div>
      </div>
      <div className={styles.closingDate}>
        <h2>Fechamento da Lista</h2>
        <div>
          <FormItemContainer row>
            <FormItemContainer>
              <Input
                fullWidth={false}
                label="Data"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: Utils.toInputDateString(new Date())
                }}
                onChange={handleChangeDate}
                error={!!errors.closingDateTime}
                helperText={errors.closingDateTime}
                onFocus={handleClearError('closingDateTime')}
              />
            </FormItemContainer>
          </FormItemContainer>
          <p>Não será possível adicionar mais convidados após o horário de fechamento da lista!</p>
        </div>
      </div>

      <div className={styles.discounts}>
        <h2>Configurações de descontos</h2>
        <div>
          <FormItemContainer label="Lista de descontos">
            <AutoComplete
                
              placeholder="Selecione a lista"
              options={baseList}
              getOptionLabel={(option) => option.name}
              onChange={(_evt, item) => handleChangeBaseListId((item as IBaseList).id)}
              error={!!errors.baseListId}
              helperText={errors.baseListId}
            />
          </FormItemContainer>
          <Input
            
            fullWidth={false}
            label="Limite de desconto"
            placeholder="Valor"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 50,
            }}
            value={moneyMaskNumber(discountLimit)}
            onChange={(ev) => handleChangeDiscountLimit(ev.target.value)}
            error={!!errors.discountLimit}
            helperText={errors.discountLimit}
          />
        </div>
      </div>

      <div className={styles.promoters}>
        <h2>Promoters da lista</h2>
        <div>
          <FormItemContainer label="Promoter responsável">
            <AutoComplete
              
              placeholder="Selecione o promoter"
              options={promotersList}
              getOptionLabel={(option) => option.name}
              onChange={(_evt, item) => handleChangeMainPromoter(item as IPromoters)}
              error={!!errors.mainPromoterId}
              helperText={errors.mainPromoterId}
            />
          </FormItemContainer>
          <FormItemContainer label="Promoters com permissão">
            <AutoComplete
              
              placeholder="Selecione os promoters"
              multiple
              limitTags={2}
              options={promotersList}
              getOptionLabel={(option) => option.name}
              onChange={(_evt, item) => handleChangePromoterList(item as IPromoters[])}
              error={!!errors.promotersIdsWithAccess}
              helperText={errors.promotersIdsWithAccess}
              disableCloseOnSelect
            />
          </FormItemContainer>
        </div>
      </div>
      <footer>
        {handleCancel && (
          <Button fullWidth={false} variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
        )}
        <Button fullWidth={false} type="submit">
          Adicionar Lista
        </Button>
      </footer>
    </form>
  );
};
export default GuestListForm;
