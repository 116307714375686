import { IExportRequest } from "modules/houseFlow/domain/dto/IExportRequest"
import { IHouseFlowService } from "modules/houseFlow/domain/interfaces/IHouseFlowService"



const PostHouseFlowExportUseCase = async (service: IHouseFlowService, localId: string, cardId: string, exportFormat: number): Promise<void> => {


    const request: IExportRequest = {
        localId: localId,
        cardId: cardId,
        exportFormat: exportFormat
    }

    const response = await service.postHouseFlowExport(request)
    return (response)
}
export default PostHouseFlowExportUseCase