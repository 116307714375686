import GetWhatsappConfigurationCrmUseCase from 'modules/crm/application/useCases/configuration/GetWhatsappConfigurationCrmUseCase';
import GetWhatsappQrCodeCrmUseCase from 'modules/crm/application/useCases/configuration/GetWhatsappQrCodeCrmUseCase';
import GetWhatsappStatusCrmUseCase from 'modules/crm/application/useCases/configuration/GetWhatsappStatusCrmUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useState } from 'react'
import CrmServiceApi from 'services/api/crm/CrmApi';
import { IWhatsappConfiguration } from '../interfaces/IWhatsappConfiguration';
const UseWhatsappConfiguration = (auto?: boolean) => {

    const [whatsappStatus, setIsOnlineWhatsapp] = useState<boolean>();
    const [isLoadingWhatsappStatus, setIsLoadingWhatsappStatus] = useState<boolean>(false);
    const { currentLocal } = useLocal()
    const [whatsappConfiguration, setWhatsappConfiguration] = useState<IWhatsappConfiguration>();
    const [isLoadingConfiguration, setIsLoadingConfiguration] = useState<boolean>(false);
    const [whatsAppQrCode, setWhatsAppQrCode] = useState<string>();
    const [isLoadingQrCode, setIsLoadingQrCode] = useState<boolean>(false);

    const getWhatsappStatus = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoadingWhatsappStatus(true)
                const service = CrmServiceApi()
                const response = await GetWhatsappStatusCrmUseCase(service, currentLocal.id);
                setIsOnlineWhatsapp(response.connected)
                return response
            } finally {
                setIsLoadingWhatsappStatus(false)
            }
        }
    }, [currentLocal])

    const getConfiguration = useCallback(async () => {
        try {
            setIsLoadingConfiguration(true)
            if (currentLocal) {
                const service = CrmServiceApi();
                const response = await GetWhatsappConfigurationCrmUseCase(service, currentLocal.id);
                setWhatsappConfiguration(response);
            }

        } finally {
            setIsLoadingConfiguration(false);
        }
    }, [currentLocal])

    const getQrCode = useCallback(async () => {
        try {
            setIsLoadingQrCode(true)
            if (currentLocal) {
                const service = CrmServiceApi();
                const response = await GetWhatsappQrCodeCrmUseCase(service, currentLocal.id);
                setWhatsAppQrCode(response.value)
                setIsOnlineWhatsapp(response.connected)
            }

        } finally {
            setIsLoadingQrCode(false)
        }
    }, [currentLocal])

    // useEffect(() => {
    //     getWhatsappStatus();
    // }, [getWhatsappStatus])

    return ({
        whatsappStatus,
        isLoadingWhatsappStatus,
        getWhatsappStatus,
        getConfiguration,
        whatsappConfiguration,
        isLoadingConfiguration,
        whatsAppQrCode,
        isLoadingQrCode,
        getQrCode
    })
}

export default UseWhatsappConfiguration
