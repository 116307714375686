import { IGetDayResumeResponse } from "../../dtos/IGetDayResumeResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetDayResumeUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetDayResumeResponse> => {
  return await service.getDayResume(localId, cashierId, day);
};

export default GetDayResumeUseCase;
