import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import { MenuItem, Slider, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import React, { ChangeEvent } from 'react'
import OtherValuesForm from './components/_otherValuesForm/OtherValuesForm'
import { EnumDurationType, IServiceScheduleForm } from './interfaces/IServiceForm'
import { UseServiceForm } from './UseServiceForm'
import styles from './ServiceForm.module.scss';
import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import InputImage from 'components/ui/inputImage/InputImage'
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage'
import InputTextArea from 'components/ui/inputTextArea/InputTextArea'
import { EditorText } from 'components/editorText/EditorText'
import { DescriptionForm } from './components/descriptionForm/DescriptionForm'

import CropEasy from 'components/ui/resizeImage/CropEasy'

interface IServiceFormProps {
    defaultValues?: IServiceScheduleForm;
    onSubmit: (values: IServiceScheduleForm) => void;
    serviceCategories: ISelect[];
    productLists: ISelect[];
    onClickAddNewCategory?: () => void
    onClickAddNewProduct?: () => void
}

export const ServiceForm: React.FC<IServiceFormProps> = ({ defaultValues, onSubmit, serviceCategories, onClickAddNewCategory, productLists, onClickAddNewProduct }) => {

    const {
        values,
        onChangeHandle,
        onSubmitHandle,
        onChangeImageHandle,
        onChangeOtherValuesHandle,
        handleChangeSingleImage,
        showcaseImages,
        onChangeMultipleImagesHandle,
        onDeleteMultipleImagesHandle,
        onChangeEditorText,
        errors,
        setValues,
        openCrop,
        setOpenCrop,
        onFileChangeSingleImage,
        imageUrl,
        onFileChangeMultipleImage,
        imageMultipleUrl,
        handleImage,
        cropEasyRef,
        serviceSubCategories
    } = UseServiceForm(onSubmit, serviceCategories, defaultValues);

    return (
        <div id={styles.ServiceForm}>
            <div className={styles.container}>

                <form onSubmit={onSubmitHandle} noValidate={true}>
                    <FormItemContainer >
                        <div className={styles.containerHelp}>
                            <h2>Informações do serviço</h2>
                            <TooltipInfo
                                title={"Informações do serviço"}
                                paragraph={"Nos campos abaixo, você irá colocar todas as informações referente ao serviço que será cadastrado, por exemplo: quantidade de pessoas, tempo de duração (em minutos), nome e a categoria."}
                            />
                        </div>

                        <div className={styles.formHeader}>
                            <div>
                                <FormItemContainer label={"Nome"}>
                                    <Input
                                        name="name"
                                        onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                        value={values.name}
                                        placeholder="Visita a gramado"
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </FormItemContainer>
                                <FormItemContainer row>
                                    <FormItemContainer label={"Duração do serviço"} flex={0.5}>
                                        <Input
                                            name="duration"
                                            type={"number"}
                                            inputProps={
                                                {
                                                    inputMode: "numeric",
                                                    min: 0,
                                                }
                                            }
                                            onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                            value={values.duration}
                                            placeholder="60"
                                            error={!!errors.duration}
                                            helperText={errors.duration}
                                        // label="duration"
                                        />
                                    </FormItemContainer>
                                    <div style={{
                                        flex: 0.5
                                    }}>
                                        <FormItemContainer label={"Horas/Minutos"} >
                                            <Input
                                              name="durationType"
                                              select
                                              onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                              value={values.durationType}
                                              error={!!errors.durationType}
                                              helperText={errors.durationType}
                                              // label="duration"
                                            >
                                                <MenuItem value={EnumDurationType.Minutos}>Minutos</MenuItem>
                                                <MenuItem value={EnumDurationType.Horas}>Horas</MenuItem>

                                            </Input>
                                        </FormItemContainer>
                                    </div>
                                </FormItemContainer>
                                <FormItemContainer row label={"Selecione a categoria"}>
                                    <FormItemContainer flex={10}>
                                        <Input
                                            name="serviceCategoryId"
                                            onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                            value={values.serviceCategoryId}
                                            select
                                            placeholder="Selecione a categoria"
                                            error={!!errors.serviceCategoryId}
                                            helperText={errors.serviceCategoryId}
                                        // label="seviceCategoryId"description
                                        >
                                            {
                                                serviceCategories?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                                ))
                                            }
                                        </Input>
                                    </FormItemContainer>
                                </FormItemContainer>
                                <FormItemContainer row label={"Selecione a subcategoria"}>
                                    <FormItemContainer flex={10}>
                                        <Input
                                            disabled={!serviceSubCategories?.length}
                                            name="serviceSubCategoryId"
                                            onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                            value={values.serviceSubCategoryId}
                                            select
                                            placeholder="Selecione a subcategoria"
                                            error={!!errors.serviceSubCategoryId}
                                            helperText={errors.serviceSubCategoryId}
                                        // label="seviceCategoryId"description
                                        >
                                            <MenuItem value="">(Nenhuma)</MenuItem>
                                            {
                                                serviceSubCategories?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                                ))
                                            }
                                        </Input>
                                    </FormItemContainer>
                                </FormItemContainer>
                            </div>
                            <div className={styles.containerImage}>
                                <div>
                                    <div >                                        

                                            <CropEasy ref={cropEasyRef} />
                                            
                                            <InputSingleImage
                                                value={values.imageUrl}
                                                onChangeImage={onFileChangeSingleImage}
                                                error={!!errors.imageUrl}
                                                helperText={errors.imageUrl}
                                                limitPixelHeight={1080}
                                                limitPixelWidth={1920}
                                                limitMb={7}
                                            >
                                            </InputSingleImage>
                                        
                                    </div>


                                </div>
                                <p>A imagem será usada na página do seu passeio.</p>

                            </div>

                        </div>
                        <FormItemContainer label={"Descrição"}>
                            <InputTextArea
                                error={!!errors.description}
                                helperText={errors.description}
                                inputProps={{ maxLength: 600 }}
                                value={values.description}
                                variant="outlined"
                                rows={4}
                                multiline
                                name={"description"}
                                onChange={(ev) => onChangeHandle(ev.target.name, ev.target.value)}
                                placeholder="Descrição do passeio"
                            />
                        </FormItemContainer>
                        <div className={styles.containerHelp}>
                            <h2>Detalhes do serviço</h2>
                            <TooltipInfo
                                title={"Detalhes do serviço"}
                                paragraph={"Aqui será inserido todos os detalhes dos serviço ofericido. Máximo de 4(quatro)."}
                            />
                        </div>

                        <DescriptionForm onChange={onChangeEditorText} values={values.moreDescriptions} />

                        {/* <EditorText text={values.description} onChangeHandle={onChangeEditorText} /> */}
                        <div className={styles.containerHelp}>
                            <h2>Galeria</h2>
                            <TooltipInfo
                                title={"Galeria"}
                                paragraph={"Tamanho mínimo de 300x300px e máximo 1080x1080px. A imagem será usada na página do seu passeio."}
                            />
                        </div>
                        <div className={styles.containerGallery}>
                                                     
                                <InputImage
                                    values={showcaseImages}
                                    multiple
                                    onChangeImage={onFileChangeMultipleImage}
                                    onDeleteImage={onDeleteMultipleImagesHandle}
                                    error={!!errors.showCases}
                                    helperText={errors.showCases}
                                >
                                </InputImage>
                            
                        </div>
                        <div className={styles.containerHelp}>
                            <h2>Valor do passeio</h2>
                            <TooltipInfo
                                title={"Valores"}
                                paragraph={"Cadastre os valores separadamente de acordo com sua preferência, por exemplo: adultos, crianças, aposentados, aniversariantes e outros. Você pode escolher um valor para cada tipo."}
                            />
                        </div>
                        <FormItemContainer flex={2} >
                            <OtherValuesForm
                                values={values.otherValues}
                                onChange={onChangeOtherValuesHandle}
                                filteredProductList={productLists.filter(list => !values.otherValues.find(value => value.productListId === list.id))}
                                productList={productLists}
                                hasError={errors}
                                onClickAddNewProduct={onClickAddNewProduct}
                            />
                        </FormItemContainer>

                        <div className={styles.buttonSubmit}>
                            <Button fullWidth={false} /* disabled={values.otherValues.length < 1 || values.moreDescriptions.length < 1} */ type={"submit"}>{defaultValues ? "Editar serviço" : "Criar serviço"}</Button>
                        </div>
                    </FormItemContainer>
                </form >
            </div>
        </div>
    )
}



