import { FC, useState } from "react";
import styles from "./AntecipateReceivedPage.module.scss";
import { ArrowBackIos, AddCircle } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import UseDimension from "components/dimension/UseDimension";
import Button from "components/ui/Button/Button";
import TableHeader from "../../components/tableHeader/TableHeader";
import TableItem from "../../components/tableItem/TableItem";
import { UseAntecipateReceivedPage } from "./UseAntecipateReceivedPage";
import { setHours, setMinutes, setSeconds, subMonths } from "date-fns";
import TableMobile from "../../components/tableMobile/TableMobile";
import AntecipateForm from "../../components/antecipateForm/AnticipateForm";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { Skeleton } from "components/skeleton/Skeleton";

const AntecipateReceivedPage: FC = () => {
  const [startDate, setStartDate] = useState<Date>(
    setSeconds(setMinutes(setHours(subMonths(new Date(), 1), 0), 0), 0)
  );
  const [endDate, setEndDate] = useState<Date>(
    setSeconds(setMinutes(setHours(new Date(), 23), 59), 59)
  );
  const [openSidesheet, setOpenSidesheet] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const { postSearchAnticipate, listAntecipate, postConfirmAnticipate, loading } =
    UseAntecipateReceivedPage();
  const { push } = useHistory();
  const { dimensions } = UseDimension();
  const { account: accountId } = useParams<{ account: string }>();
  const { toast } = useUi();

  const search = () => {
    postSearchAnticipate(startDate, endDate);
  };

  const onSubmit = async (values: any) => {
    try {
      await postConfirmAnticipate(
        values.conta,
        values.senha,
        values.valorReceber
      );
      setStep(2);
    } catch (error: any) {
      toast(error.response.data.message, "error");
    }
  };

  return (
    <>
      <div id={styles.Antecipar}>
        <div
          className={styles.back}
          onClick={() =>
            push(`/private/contaDigital/new/antecipate/${accountId}`)
          }
        >
          <ArrowBackIos />
          Voltar
        </div>
        <Button
          fullWidth={dimensions.width > 900 ? false : true}
          className={styles.addButton}
          onClick={() => setOpenSidesheet("true")}
        >
          Antecipar valor
          <AddCircle />
        </Button>
        <div className={styles.containerFiltro}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <div className={styles.calendarBox}>
              <DesktopDatePicker
                value={startDate}
                disableFuture
                onChange={(ev: any) => setStartDate(new Date(ev))}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <p>
                      Período - ínicio{" "}
                      <span style={{ color: "#ED0029" }}>*</span>
                    </p>
                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
              <DesktopDatePicker
                value={endDate}
                minDate={startDate}
                disableFuture
                onChange={(ev: any) => {
                  setEndDate(new Date(ev));
                }}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <p>
                      Período - fim <span style={{ color: "#ED0029" }}>*</span>
                    </p>
                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
            </div>
          </LocalizationProvider>

          <button className={styles.search} onClick={() => search()}>
            Buscar
          </button>
        </div>
        {loading && (
        <div className={styles.skeleton}>
          <Skeleton items={5} cardStyle={styles.cardSkeleton}/>
        </div>
      )}
        {listAntecipate && (
          <>
            {listAntecipate?.length > 0 ? (
              <div className={styles.table}>
                {dimensions.width > 1000 ? (
                  <>
                    <TableHeader />
                    {listAntecipate?.map((item) => {
                      return <TableItem item={item} />;
                    })}
                  </>
                ) : (
                  <>
                    {listAntecipate.map((item) => {
                      return <TableMobile item={item} />;
                    })}
                  </>
                )}
              </div>
            ) : (
              <div className={styles.emptyList}>
                <img src="/assets/img/empty-box.png" alt="" />
                <span>
                  Você <b>não possui</b> nenhuma antecipação!
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <AntecipateForm
        onSubmit={onSubmit}
        open={openSidesheet}
        setOpen={setOpenSidesheet}
        step={step}
        setStep={setStep}
      />
    </>
  );
};
export default AntecipateReceivedPage;
