import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IFinancialInvoicesList } from "../interfaces/IFinancialInvoicesList";
import FinancialInvoicesApi from "services/api/faturas/FinancialInvoicesApi";
import GetFinancialInvoicesUseCase from "modules/faturas/application/useCases/GetFinancialInvoicesUseCase";

const UseFinancialInvoicesList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [financialInvoicesList, setFinancialInvoicesList] = useState<IFinancialInvoicesList[]>([]);
  const { currentLocal } = useLocal();

  const getFinancialInvoiceList = useCallback(async () => {
    setIsLoading(true);
    try {
      if (currentLocal) {
        const servicePermutation = FinancialInvoicesApi();
        const response = await GetFinancialInvoicesUseCase(
          servicePermutation,
          currentLocal.id
        );
        
        setFinancialInvoicesList(([...response])); 
      }
    } catch (error) {
      console.error('Erro ao buscar as faturas:', error);
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  return {
    financialInvoicesList,
    isLoading,
    getFinancialInvoiceList,
    setFinancialInvoicesList,
  };
};

export default UseFinancialInvoicesList;
