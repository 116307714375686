import React, { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { InputContainer } from "../_inputContainer/InputContainer";
import { moneyMaskNumber, moneyToFloat } from "services/utils/Money";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";


export const InputCRM: FC<TextFieldProps & { premium?: boolean, showPremiumCrown?: boolean, required?: boolean, label?: string; moneyMask?: boolean, onChangeValue?: (value: string, name: string) => void }> = ({ ...props }) => {
  const { checkPermission } = usePlans();

  const getValue = () => {
    const { moneyMask, value } = props;
    let newValue = '';

    if (moneyMask) {
      if (props.value !== 'undefined' && props.value !== undefined && props.value !== null) newValue = moneyMaskNumber(Number(props.value))
    } else {
      newValue = value as string;
    }

    return newValue;
  }
  
  return <InputContainer gap={4} style={{ justifyContent: 'flex-end', height: 65, flexDirection: 'row-reverse' }}>
    <label className={props.showPremiumCrown && !checkPermission(FeatureTypeEnum.CRM) ? 'premiumLabel' : ''} style={{ minHeight: "1em", fontSize: 14, color: props.premium && !checkPermission(FeatureTypeEnum.CRM) ? '#A2A2A2' : '#333', lineHeight: '150%',  }}>{props.label || ""}{!!props.label && props.required && <span style={{ color: 'red' }}>*</span>}</label>
    <TextField
      {...props}
      fullWidth
      label={undefined}
      size={"small"}
      minRows={10}
      onChange={(ev) => {
        props.onChange?.(ev)
        props.onChangeValue?.(props.moneyMask ? (String(moneyToFloat(String(ev.target.value ?? "R$ 0,00")))) : ev.target.value, ev.target.name);
      }}
      value={getValue()}
      style={{ alignSelf: 'flex-end' }}
      disabled={props.premium && !checkPermission(FeatureTypeEnum.CRM)}
    >
      {props.children}
    </TextField>
  </InputContainer>;
};
