import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { ICustomerForm, IValuesItemCustomerForm } from './interfaces/ICustomerForm'

export const UseCustomerForm = (onSubmit: (customer: ICustomerForm) => void, valuesList: IValuesItemCustomerForm[], defaultValue?: ICustomerForm) => {
    const [values, setValues] = useState<ICustomerForm>({
        name: "",
        document: "",
        birthDate: "",
    })

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeCustomerValueHandle = useCallback((listId: string) => {
        const _value = valuesList.find(item => item.listId === listId);
        setValues((prev) => ({ ...prev, value: _value ?? undefined }));
    }, [valuesList]);

    // const changeCustomerValueHandle = useCallback((value: IValuesItemCustomerForm) => {
    //     setValues((prev) => ({ ...prev, value: value }));
    // }, []);


    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [values])

    return ({ values, changeCustomerValueHandle, changeHandle, submitHandle })
}
