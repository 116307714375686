import PageHeader from "components/ui/pageHeader/PageHeader";
import Layout from "components/layout/presentation/Layout";
import AddDiscounts from "modules/events/presentation/pages/addDiscountsList/AddDiscounts";

const AddDiscountsPage = () => {
  return (
    <Layout>
      <PageHeader title="Adicionar Lista de Descontos" />
      {/* <AddDiscounts /> */}
    </Layout>
  );
};

export default AddDiscountsPage;
