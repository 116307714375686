import { Button, Icon, IconButton, Switch } from '@material-ui/core';
import styles from './ConfigExchangeModal.module.scss';
import { useEffect, useState } from 'react';

interface ConfigExchangeModalProps {
    loading: boolean;
    enabled: boolean;
    onClose: () => void;
    onSubmit: (enabled: boolean) => void;
}

export const ConfigExchangeModal = ({ loading, enabled, onClose, onSubmit }: ConfigExchangeModalProps) => {
    const [checked, setChecked] = useState(enabled);

    useEffect(() => {
        setChecked(enabled);
    }, [enabled]);
    
    return (
        <div className={styles.container}>
            <header>
                <h1>Troca de<b>&nbsp;moedas</b></h1> <IconButton onClick={onClose}><Icon>close</Icon></IconButton>
            </header>
            
            <p>A troca de moedas significa que seu cliente poderá utilizá-las no momento de pagamento ao consumir no seu estabelecimento pelo Aplicativo Meep.</p>

            <div className={styles.infoContainer}>
                <Icon>info_outlined</Icon>
                <p><b>Importante:</b>todos os níveis de fidelidade poderão trocar as moedas, de acordo com a cotação definida.</p>
            </div>

            <div className={styles.switchContainer}>
                <Switch checked={checked} onChange={() => setChecked(!checked)} />
                <span>Troca de moedas habilitadas</span>
            </div>

            <div className={styles.buttonContainer}>
                <div>
                    <Button className={styles.buttons} color='secondary' onClick={onClose} variant='outlined'>Voltar</Button>
                </div>
                <div>
                    <Button disabled={loading} onClick={() => onSubmit(checked)} className={styles.buttons} type='button' variant='contained' color='secondary'>{
                        loading ? 'Salvando...' :
                        'Salvar'
                    }</Button>
                </div>
            </div>
        </div>
    )
}