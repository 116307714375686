import { ChangeEvent, useCallback, useEffect, useState } from "react";
import styles from "./FilterCashCancellation.module.scss";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { endOfDay, format, startOfDay } from "date-fns";
import { IPostCashCancellationParams } from "modules/cashCancellation/domain/dto/IPostCashCancellationParams";
import {
  CloseOutlined,
  SortOutlined,
  VerticalAlignBottomOutlined,
} from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import UseCashlessDashboardPage from "modules/cashlessDashboard/presentation/UseCashlessDashboardPage";
import Button from "components/ui/Button/Button";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { TabFilter } from "components/ui/tabFilter/TabFilter";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { UseSaleForDevicePage } from "modules/saleForDevice/presentation/hooks/UseSaleForDevicePage";
import Utils from "services/utils/Utils";

export interface IFilterCashCancellation {
  setFilters: React.Dispatch<React.SetStateAction<IPostCashCancellationParams>>;
  filters: IPostCashCancellationParams;
  handleSubmit: () => void;
  setOpenSidesheet: React.Dispatch<React.SetStateAction<string | null>>;
}

const deviceTypeFilter = [
  {
    id: 0,
    name: "Todos",
  },
  {
    id: DeviceType.POS,
    name: "POS",
  },
  {
    id: DeviceType.TOTEM,
    name: "Totem",
  },
  {
    id: DeviceType.TABLET,
    name: "Tablet",
  },
];

const FilterCashCancellation = ({
  setFilters,
  filters,
  handleSubmit,
  setOpenSidesheet,
}: IFilterCashCancellation) => {
  const [filterBy, setFilterBy] = useState("");
  const { cashiers } = UseCashlessDashboardPage();
  const [advancedFilters, setAdvancedFilters] = useState(false);
  const [caixa, setCaixa] = useState<string>("");
  const { profileList, getAdvancedFiltersOptions } = UseSaleForDevicePage();

  const { dimensions } = UseDimension();

  const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    const selectedDateTime = new Date(ev.target.value);
    const dateTimeInUTC = selectedDateTime.toISOString();
    setFilters((prev) => ({ ...prev, [ev.target.name]: dateTimeInUTC }));
  }, []);

  useEffect(() => {
    getAdvancedFiltersOptions();
  }, [getAdvancedFiltersOptions]);

  const onChangeDeviceType = useCallback(
    (item: { id: number; name: string }) => {
      let arr: number[] = [];

      if (item.id === 0) {
        return setFilters({ ...filters, TiposDispositivos: [0] });
      }

      const removeAllSelected =
        filters.TiposDispositivos?.filter((x) => x !== 0) ?? [];

      const selectedItem = filters.TiposDispositivos?.find(
        (x) => x === item.id
      );

      if (selectedItem) {
        arr = removeAllSelected?.filter((x) => x !== item.id) ?? [];
      } else {
        arr = [...(removeAllSelected ?? []), item.id];
      }

      if (arr.includes(4)) {
        arr.push(3);
      }

      if (!arr.length) {
        return setFilters({ ...filters, TiposDispositivos: [0] });
      }
      setFilters({ ...filters, TiposDispositivos: arr });
    },
    [filters]
  );


  const handleChangeFilterBy = useCallback((filter: string) => {
    if (filter === 'period') {
      setFilters({ ...filters, dataInicio: startOfDay(new Date()).toISOString(), dataFim: endOfDay(new Date()).toISOString(), sessaoId: "" })
    }
    else {
      setFilters({ ...filters, dataInicio: null, dataFim: null, sessaoId: null });
    }
    return setFilterBy(filter);
  }, [filters]);

  return (
    <div id={styles.FilterCashCancellation}>
      <div
        className={styles.displayRow}
        style={{ justifyContent: "flex-start", marginBottom: "30px" }}
      >
        <div className={styles.input}>
          <span className={styles.required}>Filtrar por</span>
          <Select
            value={filterBy}
            size="small"
            placeholder="Selecione um filtro"
            onChange={(ev) => {
              handleChangeFilterBy(ev.target.value)
            }}
          >
            <MenuItem value="period">Período</MenuItem>
            <MenuItem value="cashier">Caixa</MenuItem>
          </Select>
        </div>
        {filterBy === "period" && (
          <>
            <div className={styles.input}>
              <span className={styles.required}>Inicio</span>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <TextField
                  size='small'
                  name={'dataInicio'}
                  onChange={changeHandleDate}
                  fullWidth
                  value={filters.dataInicio && Utils.toInputDateString(filters.dataInicio)}
                  type="datetime-local" />
              </LocalizationProvider>

            </div>
            <div className={styles.input}>
              <span className={styles.required}>Fim</span>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <TextField
                  size='small'
                  name={'dataFim'}
                  onChange={changeHandleDate}
                  fullWidth
                  value={filters.dataFim && Utils.toInputDateString(filters.dataFim)}
                  type="datetime-local" />
              </LocalizationProvider>
            </div>
          </>
        )}
        {filterBy === "cashier" && (
          <div
            className={styles.input}
            style={{
              width: "100%",
              maxWidth: dimensions.width > 650 ? "350px" : "100%",
            }}
          >
            <span className={styles.required}>Caixa</span>
            <Select
              style={{ flex: 2, minWidth: 350 }}
              size="small"
              onChange={(ev) => {
                const selectedItemId = ev.target.value;
                const selectedItem = cashiers.find(
                  (item) => item.id === selectedItemId
                );
                if (selectedItem) {
                  setCaixa(selectedItem.name);
                }
                setFilters({ ...filters, sessaoId: ev.target.value });
              }}
              value={filters.sessaoId}
            >
              {cashiers.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </div>
        )}
        {!advancedFilters && (
          <Button
            color="secondary"
            onClick={handleSubmit}
            disabled={
              (filterBy === "cashier" && !filters.sessaoId) ||
              (filterBy === "period" &&
                !filters.dataFim &&
                !filters.dataInicio) ||
              !filterBy
            }
          >
            Buscar
          </Button>
        )}
      </div>

      <div className={`${styles.displayRow} ${styles.filterEndButton}`}>
        <div
          className={`${styles.displayRow} ${styles.endButtons}`}
          style={{ justifyContent: "space-between" }}
        >
          <div className={styles.btnRow} onClick={() => setOpenSidesheet('true')}>
            <span>Exportar</span>
            <VerticalAlignBottomOutlined />
          </div>
          <div
            className={styles.btnRow}
            onClick={() => setAdvancedFilters(!advancedFilters)}
          >
            <span>Filtros avançados</span>
            {advancedFilters ? (
              <CloseOutlined
                style={{ color: "rgba(95, 95, 95, 1)", width: "16px" }}
              />
            ) : (
              <SortOutlined style={{ color: "rgba(95, 95, 95, 1)" }} />
            )}
          </div>
        </div>
      </div>
      {advancedFilters && (
        <div
          className={styles.displayRow}
          style={{ justifyContent: "flex-start" }}
        >
          <div className={styles.filters}>
            <FormItemContainer
              label={"Tipo de equipamento"}
              className={styles.formLabel}
              padding={0}
              labelMargin="0 0 8px 0"
            >
              <TabFilter
                items={deviceTypeFilter}
                value="id"
                label="name"
                selectedItems={
                  filters.TiposDispositivos?.map((x) => x.toString()) ?? []
                }
                onChange={onChangeDeviceType}
              />
            </FormItemContainer>
          </div>
          <div
            className={styles.input}
            style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}
          >
            <span>PDV</span>
            <Autocomplete
              options={profileList}
              getOptionLabel={(option) => option.name}
              noOptionsText="Nenhuma opção encontrada"
              multiple
              limitTags={1}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    filters.profileIds?.length === 0 || !filters.profileIds
                      ? "Selecione um PDV"
                      : ""
                  }
                  InputProps={{
                    style: { padding: "0 20px 0 10px" },
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_ev, values) => {
                const profilesIds = values.map((it) => it.id);
                setFilters({ ...filters, profileIds: profilesIds });
              }}
            />
          </div>
          <div
            className={styles.input}
            style={{ maxWidth: dimensions.width > 650 ? "200px" : "100%" }}
          >
            <span>Tipo de cancelamento</span>
            <Select
              size="small"
              onChange={(ev) =>
                setFilters({
                  ...filters,
                  TipoCancelamento: Number(ev.target.value),
                })
              }
            >
              <MenuItem value={1}>Pedido</MenuItem>
              <MenuItem value={2}>Item</MenuItem>
            </Select>
          </div>
          <Button
            color="secondary"
            onClick={handleSubmit}
            disabled={
              (filterBy === "cashier" && !filters.sessaoId) ||
              (filterBy === "period" &&
                !filters.dataFim &&
                !filters.dataInicio) ||
              !filterBy
            }
          >
            Buscar
          </Button>
        </div>
      )}
      {filters.dataInicio && filters.dataFim && filterBy === "period" && (
        <div className={styles.btnRow} style={{ cursor: "default" }}>
          <span className={styles.infoFilter}>
            <b>Período:</b>{" "}
            {filters.dataInicio
              ? format(new Date(filters.dataInicio), "dd/MM/yyyy' - 'HH:mm")
              : "-"}{" "}
            <b>até</b>{" "}
            {filters.dataFim
              ? format(new Date(filters.dataFim), "dd/MM/yyyy' - 'HH:mm")
              : "-"}
          </span>
        </div>
      )}
      {filters.sessaoId && filterBy === "cashier" && (
        <div className={styles.btnRow} style={{ cursor: "default" }}>
          <span className={styles.infoFilter}>
            <b>Caixa:</b> {caixa}
          </span>
        </div>
      )}
    </div>
  );
};

export default FilterCashCancellation;
