import { IEvents } from "modules/events/domain/models/IEvents";
import moment from "moment";
import { FC, useMemo } from "react";
import { ManageTitle } from "../manageTitle/ManageTitle";
import styles from "./CashSelection.module.scss";
import UseCashSelection from "./UseCashSelection";

interface ICashSelectionProps {
  event: IEvents;
  refreshEvent: () => Promise<void>;
  responseConfigLocal: boolean | undefined
}

export const CashSelection: FC<ICashSelectionProps> = ({ event: { cashier }, refreshEvent, responseConfigLocal }) => {
  const { openCashier } = UseCashSelection(refreshEvent, cashier);

  const cashierDescription = useMemo(() => {
    if (!cashier && !openCashier) return "O estabelecimento não possui nenhum caixa aberto.";

    let startDate = cashier?.startDate ? moment.utc(cashier?.startDate) : null;
    let openCashierStartDate = openCashier?.startDate ? moment.utc(openCashier?.startDate) : null;

    let formattedDate = startDate?.local().format('DD/MM/YYYY - HH:mm') || openCashierStartDate!.local().format('DD/MM/YYYY - HH:mm');

    if (cashier?.endDate) {
      let endDate = moment.utc(cashier.endDate);
      formattedDate += ` - ${endDate.local().format('DD/MM/YYYY - HH:mm')}`;
    }

    return formattedDate;
  }, [cashier, openCashier])

  const hasCashier = cashier?.cashierName || openCashier?.cashierName;

  return (
    <div id={styles.CashSelection}>
      <ManageTitle title="Caixa Vinculado" subTitle="Vincule o caixa que será utilizado no evento, essa ação é importante para obter dados e realizar mais configurações do seu evento." />      
      
      <div className={cashier ? styles.linked : styles.noLinked}>
        <p>
          {!hasCashier ? 
            "O estabelecimento não possui nenhum caixa aberto." : 
            <>
              <b>Caixa: </b>
              {cashierDescription}
            </> 
          }
        </p>
        {/* {!cashier ? (
          <Button
            fullWidth={false}
            variant="contained"
            disabled={!hasCashier || loading}
            onClick={() => handleCashSelection(openCashier?.cashierId || "")}
          >
            Vincular
            {loading && <CircularProgress color="error" size={20} />}
          </Button>
        ) : (
          <p>Caixa Vinculado</p>
        )} */}
      </div>
    </div>
  );
};
