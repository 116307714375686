import styles from './BaitFirstLoginModal.module.scss';

const BaitFirstLoginModal = ({ closeFn }: any) => (
    <div className={styles.baitModalBackdrop}>
        <div className={styles.baitModalContent} style={{ backgroundImage: 'url(/assets/img/bait-welcome-modal.svg)'}}>
            <div className={styles.baitModalCloseIcon} onClick={closeFn}>&#x2715;</div>
            <p className={styles.baitModalTitle}>Bem vindo ao sistema</p>
            <div className={styles.baitModalLogo} style={{ backgroundImage: 'url(/assets/img/LogoMee.svg)'}}></div>
            <p className={styles.baitModalDescription}>Aproveite todas as ferramentas <b>gratuitas</b> que estamos disponibilizando para agregar ainda mais <b>sucesso</b> em seu estabelecimento</p>

            <button className={styles.baitModalButton} onClick={closeFn}>Acessar ferramentas</button>
        </div>
    </div>
)

export default BaitFirstLoginModal;