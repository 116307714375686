import { IGetDigitalAccountsService } from "../interfaces/IContaDigitalApiService";

const GetDigitalAccounts = async (
  accountService: IGetDigitalAccountsService,
  ownerId: string
): Promise<any> => {
  return await accountService.getDigitalAccounts(ownerId);
};

export default GetDigitalAccounts;
