import Layout from "components/layout/presentation/Layout";
import { PurchasePlanPage } from "./PurchasePlanPage";

export const PurchasePlan = () => {
  return (
    <Layout>
      <PurchasePlanPage />
    </Layout>
  );
};
