import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetTransactionDetailsUseCase from "modules/transactionReport/application/GetTransactionDetailsUseCase";
import GetTransactionsReportUseCase from "modules/transactionReport/application/GetTransactionsReportUseCase";
import GetTransactionsUseCase from "modules/transactionReport/application/GetTransactionsUseCase";
import PostTransactionsExportUseCase from "modules/transactionReport/application/PostTransactionsExportUseCase";
import { IGetTransactionDetailsResponse } from "modules/transactionReport/domain/dto/IGetTransactionDetailsResponse";
import { IGetTransactionsParams } from "modules/transactionReport/domain/dto/IGetTransactionsParams";
import { IEquipments, IPaymentTypes } from "modules/transactionReport/domain/dto/IGetTransactionsReportResponse";
import { ITransactionsRecords } from "modules/transactionReport/domain/dto/IGetTransactionsResponse";
import { IPostTransactionsExportParams } from "modules/transactionReport/domain/dto/IPostTransactionsExportParams";
import { useCallback, useState } from "react";
import TransactionReportApi from "services/api/transactionReport/TransactionReportApi";

const transactionsApi = TransactionReportApi();

export const UseTransactionReportPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [equipments, setEquipments] = useState<IEquipments[]>();
    const [paymentTypes, setPaymentTypes] = useState<IPaymentTypes[]>();
    const [transactionsList, setTransactionsList] = useState<ITransactionsRecords[]>();
    const [transactionDetails, setTransactionDetails] = useState<IGetTransactionDetailsResponse>();
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const { currentLocal } = useLocal();

    const getTransactionsList = useCallback(
        async (params: IGetTransactionsParams, page?: number, pageSize?: number) => {
            if (currentLocal) {
                try {
                  setIsLoading(true);
                  const response = await GetTransactionsUseCase(transactionsApi, currentLocal.id, params, page, pageSize);
          
                  setTransactionsList(response.Records);
                  setTotalTransactions(response.TotalRecords);
                  setTotalPages(Math.ceil(response.TotalRecords / (pageSize ?? 10)));
                  setCurrentPage(page ?? 0);
                } finally {
                  setIsLoading(false);
                }
            }
        },
        [currentLocal]
    );

    const getTransactionsReport = useCallback(
        async () => {
            if (currentLocal) {
                try {
                  setIsLoading(true);
                  const response = await GetTransactionsReportUseCase(transactionsApi, currentLocal.id);
                  setEquipments(response.Equipments);
                  setPaymentTypes(response.PaymentTypes);
                } finally {
                  setIsLoading(false);
                }
            }
        },
        [currentLocal]
    );

    const getTransactionDetails = useCallback(
      async (transactionId: string) => {
          if (currentLocal) {
              try {
                setIsLoading(true);
                const response = await GetTransactionDetailsUseCase(transactionsApi, currentLocal.id, transactionId);
                setTransactionDetails(response);
              } finally {
                setIsLoading(false);
              }
          }
      },
      [currentLocal]
    );
    
    const nextPage = (params: IGetTransactionsParams, page: number, pageSize?: number) => {
      if (currentPage <= totalPages) {
        getTransactionsList(params, page, pageSize);
      }
    }

    const postTransactionsExport = useCallback(
      async (params: IPostTransactionsExportParams) => {
          if (currentLocal) {
              try {
                setIsLoading(true);
                const response = await PostTransactionsExportUseCase(transactionsApi, {...params, LocalClienteId: currentLocal.id});
                return response;
              } catch (error: any) {
                return error.response.status;
              } finally {
                setIsLoading(false);
              }
          }
      },
      [currentLocal]
    );

    return {
        isLoading,
        equipments,
        paymentTypes,
        transactionsList,
        totalTransactions,
        transactionDetails,
        totalPages,
        currentPage,
        getTransactionsList,
        getTransactionsReport,
        getTransactionDetails,
        nextPage,
        postTransactionsExport,
    }
}
