import { FC, useEffect } from 'react'
import styles from './RechargeOrigin.module.scss'
import { IRechargeOrigin } from './interfaces/IRechargeOrigin';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '../skeleton/Skeleton';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';

export interface IRechargeOriginProps {
    getData: () => Promise<void>;
    loading: boolean;
    data?: IRechargeOrigin;
    onGeneratingPDF?: boolean;
    triggerOperator: boolean;
    expiresAt: string | undefined;
    triggerCustomer: boolean;
    triggerDevices: boolean;
    triggerProducts: boolean;
    triggerCategories: boolean
}

const RechargeOrigin: FC<IRechargeOriginProps> = ({ getData, triggerCategories, triggerProducts, loading, data, onGeneratingPDF, triggerOperator, expiresAt, triggerCustomer, triggerDevices }) => {
    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        if (data && expiresAt && new Date() > new Date(expiresAt))
          getData();
      }, [triggerOperator, triggerCustomer, triggerDevices, triggerProducts, triggerCategories]);

    if (loading) {
        return (
            <div id={styles.RechargeOrigin}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id={styles.RechargeOrigin}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.totalRecharged.total)}</span>
                        <span>
                            Total recarregado
                            {!onGeneratingPDF && <Tooltip
                                title="Total recarregado"
                                text="Valor total recarregado no período selecionado e o valor divido entre os equipamentos usados."
                            />}
                        </span>
                    </div>

                    {(data?.totalRecharged.app ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Aplicativo:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalRecharged.app)}</span>
                        </div>
                    )}

                    {(data?.totalRecharged.totem ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Totem:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalRecharged.totem)}</span>
                        </div>
                    )}

                    {(data?.totalRecharged.pos ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>POS:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalRecharged.pos)}</span>
                        </div>
                    )}

                    {(data?.totalRecharged.posPlus ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>POS+:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalRecharged.posPlus)}</span>
                        </div>
                    )}

                    {(data?.totalRecharged.other ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Outros:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalRecharged.other)}</span>
                        </div>
                    )}
                </div>

                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.totalConsumed.total)}</span>
                        <span>
                            Total consumido cashless
                            {!onGeneratingPDF && <Tooltip
                                title="Total consumido cashless"
                                text="Valor total consumido cashless no período selecionado. Temos também o valor total consumido por equipamento e sua proporção diante do total."
                            />}
                        </span>
                    </div>

                    {(data?.totalConsumed.app ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Aplicativo:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalConsumed.app)}</span>
                        </div>
                    )}

                    {(data?.totalConsumed.totem ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Totem:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalConsumed.totem)}</span>
                        </div>
                    )}

                    {(data?.totalConsumed.pos ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>POS:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalConsumed.pos)}</span>
                        </div>
                    )}

                    {(data?.totalConsumed.posPlus ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>POS+:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalConsumed.posPlus)}</span>
                        </div>
                    )}

                    {(data?.totalConsumed.other ?? 0) > 0 && (
                        <div className={styles.row}>
                            <span className={styles.label}>Outros:</span>
                            <span className={styles.value}>{moneyMaskNumber(data?.totalConsumed.other)}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default RechargeOrigin



