import { IPostPermutionAccountRequest } from "modules/permutation/domain/dto/IPostPermutionAccountRquest";
import { IAddPermutationAccount } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";

const ParseAddPermutationAccountRequestUseCase  = (
  request: IAddPermutationAccount,
  localId: string
) => {
  const _request: IPostPermutionAccountRequest = {
    placeId: localId,
    name: request.name,
    document: request.document,
    documentTypeId: request.documentTypeId,
    children: request.children,
    operation: {
      reason: request.operation.reason,
      value: Number(request.operation.value)
    }
  };

  return _request;
};

export default ParseAddPermutationAccountRequestUseCase;
