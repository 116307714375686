import { IOperationRequest  } from "modules/permutation/domain/dto/IPostPermutionAccountRquest";
import {  IOperation } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";

const ParseUpdateRequestUseCase  = (
  request: IOperation,
  placeId: string,
) => {
  const _request: IOperationRequest = {

      placeId: placeId,
      reason: request.reason,
      value: Number(request.value)

  };

  return _request;
};

export default ParseUpdateRequestUseCase;
