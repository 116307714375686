import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteProductUseCase from "modules/products/application/useCases/deleteProductUseCase/DeleteProductUseCase";
import { useCallback, useEffect, useState } from "react";
import { ProductsApi } from "services/api/products/ProductsApi";
import { ITicketForm, ITicketFormErrors, TicketFormStep } from "./ITicketForm";
import { validate } from "./validation/TicketFormValidation";
import GetLocaLConfigurationUseCase from "modules/events/application/useCases/voucher/GetLocalConfigurationUseCase";
import { ILocalConfiguration } from "../../../cashlessConsummation/interfaces/ILocalConfiguration";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import LocalService from "services/api/local/LocalService";

const UseTicketForm = (ticket: ITicketForm, closeEditModal: () => void, handleFormTicket: (ticket: ITicketForm) => void) => {
  const productService = ProductsApi();
  const [ticketForm, setTicketForm] = useState<ITicketForm>({ ...ticket, hasExpireTime: !!ticket.expireTime });
  const [errors, setErrors] = useState<ITicketFormErrors>({} as ITicketFormErrors);
  const { toast, showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const [deleting, setDeleting] = useState(false);
  const [formStep, setFormStep] = useState(TicketFormStep.BASE);
  const [localConfiguration, setLocalConfiguration] = useState<ILocalConfiguration>();
  const localService = LocalService();
  const [isEdit, setIsEdit] = useState(false)


  const handleChangeTicketFormValue = useCallback((prop: string, value: any) => {
    setTicketForm(prev => ({
      ...prev,
      [prop]: value
    }));
  }, []);

  useEffect(() => {
    if (ticket.id) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [ticket.id])


  const handleChangeMaxQuantityPerUser = useCallback((sub?: boolean) => {
    let newValue = !sub ? (ticketForm.maxQuantityPerUser || 1) + 1 : (ticketForm.maxQuantityPerUser || 1) - 1;
    if (newValue < 0) newValue = 0;

    setTicketForm(prev => ({
      ...prev,
      maxQuantityPerUser: newValue || 0
    }));
  }, [ticketForm.maxQuantityPerUser]);

  const handleDeleteProduct = useCallback(() => {
    setDeleting(true);
  }, []);

  const handleCancelDeleteProduct = useCallback(() => {
    setDeleting(false);
  }, []);


  const getLocalConfigurations = useCallback(async () => {
    try {
      if (currentLocal?.id) {
        const result = await GetLocaLConfigurationUseCase(localService, currentLocal?.id);
        setLocalConfiguration(result);
      }
    } catch (error) {
    }
  }, [currentLocal?.id, localService]);

  useEffect(() => {
    getLocalConfigurations()
  }, [])

  const submitForm = useCallback(async () => {
    if (validate(ticketForm, setErrors,)) {
      showLoading()

      handleFormTicket(ticketForm);
      hideLoading()
    }
  }, [handleFormTicket, hideLoading, showLoading, ticketForm]);

  const deleteProduct = useCallback(async () => {
    try {
      showLoading()
      await DeleteProductUseCase(productService, ticketForm.id);
      toast("Ingresso excluído com sucesso!", "success");
      closeEditModal();
      hideLoading()
    } catch (error) {
      hideLoading()
    }
  }, [closeEditModal, hideLoading, productService, showLoading, ticketForm, toast]);

  const validateStep = useCallback(() => {
    switch (formStep) {
      case TicketFormStep.BASE:

        return validate(ticketForm, setErrors, localConfiguration?.gerarNFCE, isEdit);

      case TicketFormStep.CONFIG:
        return validate(ticketForm, setErrors, localConfiguration?.gerarNFCE);
      default:
        return false;
    }
  }, [formStep, isEdit, localConfiguration?.gerarNFCE, ticketForm]);

  const handleFormStep = useCallback((nextStep: TicketFormStep) => {
    validateStep() && setFormStep(nextStep);
  },
    [validateStep]
  );

  return {
    ticketForm,
    errors,
    deleting,
    formStep,
    handleFormStep,
    handleChangeTicketFormValue,
    handleChangeMaxQuantityPerUser,
    submitForm,
    handleDeleteProduct,
    handleCancelDeleteProduct,
    deleteProduct
  };
};

export default UseTicketForm;
