import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { TooltipInfo } from 'components/ui/tooltip/Tooltip';
import { Dialog, IconButton } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import React, { FC, useMemo } from 'react'
import CustomerForm from '../customerForm/CustomerForm';
import { IPlacesScheduleReserve } from './interfaces/IPlacesSchedules';
import { ServiceValueItem } from './interfaces/IProductServiceValues';
import { ICustomerReserveServiceForm, IReserveScheduleForm } from './interfaces/IReserveScheduleForm';
import { IServiceItemReserve } from './interfaces/IServiceSchedule';
import styles from './ReserveScheduleForm.module.scss'
import { UseReserveScheduleForm } from './UseReserveScheduleForm';
export interface IReserveScheduleFormProps {
    defaultServiceId?: string;
    onChangeServiceId: (serviceId: string) => void;
    services: IServiceItemReserve[];
    serviceValues: ServiceValueItem[]
    placeSchedules: IPlacesScheduleReserve[];
    onSubmit: (values: IReserveScheduleForm) => void;
    defaultValues?: IReserveScheduleForm;
}
const ReserveScheduleForm: FC<IReserveScheduleFormProps> = ({ onSubmit, defaultValues, placeSchedules, serviceValues, services, defaultServiceId, onChangeServiceId }) => {

    const {
        customers,
        onAddChangeCustomerHandle,
        values,
        onChangeServiceHandle,
        onChangePlacesScheduleHandle,
        onChangeDateHandle,
        openModalClient,
        setOpenModalClient,
        toggleModalReserve,
        submitHandle,
        removeFormByIndex
    } = UseReserveScheduleForm(onChangeServiceId, services, onSubmit, defaultServiceId, defaultValues);


    const generateForms = (customers: ICustomerReserveServiceForm[], values: IReserveScheduleForm) => (
        <div className={styles.containerList}>
            <Button variant="outlined" fullWidth={false} disabled={!values?.service} onClick={() => setOpenModalClient(true)} >Novo Cliente <AddCircle /></Button>
            {
                customers?.map((item, key) => (
                    <div key={key}>
                        <div className={styles.containerListCustomers}>

                            <p>Nome: <span>{item.name}</span></p>

                            <p>Documento: <span>{item.document}</span></p>

                            <p>Nasc.: <span>{item.birthDate}</span></p>

                            {item.value &&
                                <p>Valor: <span>
                                    {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(serviceValues.find(customerListItem => item.value?.listId === customerListItem.listId)?.value ?? -1)}

                                </span></p>
                            }
                        </div>
                        <IconButton onClick={() => removeFormByIndex(key)}> <Delete /></IconButton>
                    </div>
                ))
            }
        </div>
    )


    const customerView = useMemo(() => generateForms(customers, values), [customers, values]);


    return (
        <div id={styles.ReserveScheduleForm} >
            <div className={styles.container} >
                <form onSubmit={submitHandle}>
                    <FormItemContainer>
                        <div className={styles.containerHelp}>
                            <h2>Informações do passeio</h2>
                            <TooltipInfo
                                title={"Informações do Lugar"}
                                paragraph={"Selecione o passeio e a data que o cliente deseja, lembre-se de confirmar todas as informações com os clientes."}
                            />
                        </div>
                        <FormItemContainer label={"Serviço"}>
                            <AutoComplete
                                required
                                value={values?.service}
                                options={services}
                                disabled={!!defaultServiceId}
                                getOptionLabel={item => item.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(_, value) => {
                                    onChangeServiceHandle(value as IServiceItemReserve)
                                }}
                            />
                        </FormItemContainer>
                        <FormItemContainer label={"Lugar"}>
                            <AutoComplete
                                required
                                value={values?.placeSchedule}
                                options={placeSchedules}
                                getOptionLabel={item => item.name}
                                getOptionSelected={(option, value) => option.serviceScheduledId === value.serviceScheduledId}
                                onChange={(_, value) => {
                                    onChangePlacesScheduleHandle(value as IPlacesScheduleReserve)
                                }}
                            />
                        </FormItemContainer>
                        <FormItemContainer label={"Data"}>
                            <Input
                                required
                                type={"date"}
                                value={values?.date}
                                onChange={(ev) => onChangeDateHandle(ev.target.value)}
                            />
                        </FormItemContainer>
                    </FormItemContainer>
                    <div className={styles.containerHelp}>
                        <h2>Informações do cliente</h2>
                        <TooltipInfo
                            title={"Informações do cliente"}
                            paragraph={"Clique em “Novo Cliente” e preencha todos os campos solicitados para adicionar um cliente na reserva do passeio."}
                        />
                    </div>
                    {customerView}

                    <div className={styles.buttonSave}>
                        <Button
                            // disabled={Boolean(!values.customers.length && !values.date && !values.placeSchedule && values.service)}
                            type={"submit"}
                            fullWidth={false}
                        >
                            Fazer reserva
                        </Button>
                    </div>

                </form>
                <Dialog open={openModalClient} fullWidth onClose={() => setOpenModalClient(false)}>
                    <CustomerForm valuesCustomer={serviceValues} onSubmit={onAddChangeCustomerHandle} toggleModal={toggleModalReserve}></CustomerForm>
                </Dialog>
            </div >
        </div >
    )
}
export default ReserveScheduleForm