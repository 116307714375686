import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./FidelityForm.module.scss";
import { Button, Icon, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FidelityNameForm, {
  IFidelityNameFormValue,
} from "./name/FidelityNameForm";
import FidelityPointForm, {
  IFidelityPointFormValue,
} from "./poitnRules/FidelityPointForm";
import FidelityCoinsForm, {
  IFidelityCoinsFormValue,
} from "./coinsRules/FidelityCoinsForm";
import GetFidelityLevelValuesByIdUseCase from "modules/fidelity/application/useCases/GetFidelityLevelValuesByIdUseCase";
import { FidelityServiceApi } from "services/api/fidelity/FidelityApi";
import CreateFidelityLevelUseCase from "modules/fidelity/application/useCases/CreateFidelityLevelUseCase";
import FidelityCustomizationForm, {
  IFidelityCustomizationFormValue,
} from "./settings/FidelityCustomizationForm";
import UpdateNameFidelityLevelUseCase from "modules/fidelity/application/useCases/UpdateNameFidelityLevelUseCase";
import UpdateCustomizationFidelityLevelUseCase from "modules/fidelity/application/useCases/UpdateCustomizationFidelityLevelUseCase";
import UpdatePointRulesFidelityLevelUseCase from "modules/fidelity/application/useCases/UpdatePointsRulesFidelityLevelUseCase";
import UpdateCoinsRulesFidelityLevelUseCase from "modules/fidelity/application/useCases/UpdateCoinsRulesFidelityLevelUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import TabView from "components/tab/TabView";
import FidelityBenefitsForm, { IFidelityBenefitsFormValue } from "./benefits/FidelityBenefitsForm";
import UpdateFidelityLevelBenefitsUseCase from "modules/fidelity/application/useCases/UpdateFidelityLevelBenefitsUseCase";
import FidelityProductsForm, { IProductFidelity } from "./products/FidelityProductsForm";
import GetProductsListFidelityUseCase from "modules/fidelity/application/useCases/GetProductsListFidelityUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import UpdateFidelityProductsUseCase from "modules/fidelity/application/useCases/UpdateFidelityProductsUseCase";
import GetFidelityProductsDefaultUseCase from "modules/fidelity/application/useCases/GetFidelityProductsDefaultUseCase";
import { IFidelityPlans } from "../../interfaces/IFidelityPlans";

const fidelityService = FidelityServiceApi();
export interface IFidelityValues {
  name: string;
  pointsRequired: number;
  customization: IFidelityCustomizationFormValue;
  levelScoringRules: IFidelityPointFormValue;
  coinsScoringRules: IFidelityCoinsFormValue;
  benefits: IFidelityBenefitsFormValue[];
}

export interface IFidelityFormProps {
  //propertys
  fidelityId?: string;
  onClose: () => void;
  step?: FidelityFormStep;
  localId: string;
  isFirst: boolean;
  data: IFidelityPlans[];
  setFidelityTitleForm: React.Dispatch<React.SetStateAction<{
    title: string;
    strong: string;
  }>>;
}

export enum FidelityFormStep {
  name = 0,
  customization = 1,
  points = 2,
  coins = 3,
  success = 4,
  benefits = 5,
  products = 6,
}

const FidelityForm: FC<IFidelityFormProps> = ({
  onClose,
  step,
  fidelityId,
  localId,
  isFirst,
  data,
  setFidelityTitleForm
}) => {
  const [defaultValue, setDefaultValue] = useState<IFidelityValues>();
  const [isLoading, setIsLoading] = useState(false);
  const { currentLocal } = useLocal();
  const [createFidelityLevelError, setCreateFidelityLevelError] =
    useState<string>("");
  const { replace } = useHistory();
  const { showLoading, hideLoading } = useUi();

  useEffect(() => {
    if (step === FidelityFormStep.benefits) {
      return setFidelityTitleForm({ title: "Escrever outros", strong: "benefícios" });
    }
    return setFidelityTitleForm({ title: "Nível de", strong: "fidelidade" });
  }, [setFidelityTitleForm, step]);

  const getDefaultValues = useCallback(async (fidelityId: string): Promise<IFidelityValues> => {
    try {
      setIsLoading(true);
      showLoading();
      const response = await GetFidelityLevelValuesByIdUseCase(
        fidelityService,
        fidelityId
      );
      return response;
    } finally {
      setIsLoading(false);
      hideLoading();
    }
  },
    [hideLoading, showLoading]
  );

  const getDefaultValuesHandle = useCallback(async () => {
    if (fidelityId) {
      const responseValue = await getDefaultValues(fidelityId);
      setDefaultValue(responseValue);
    }
  }, [fidelityId, getDefaultValues]);

  const onCreateOrUpdateBenefits = useCallback(async (values: IFidelityBenefitsFormValue[]) => {
    try {
      setIsLoading(true);
      showLoading();
      if (fidelityId) {
        await UpdateFidelityLevelBenefitsUseCase(
          fidelityService,
          localId,
          fidelityId,
          values
        );
        getDefaultValuesHandle();
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.success]
          }/${fidelityId}`
        );
      }
    } finally {
      setIsLoading(false);
      hideLoading();
    }
  }, [fidelityId, getDefaultValuesHandle, hideLoading, localId, replace, showLoading])

  const onCreateOrUpdateFidelityLevel = useCallback(
    async (values: IFidelityNameFormValue) => {
      try {
        setIsLoading(true);
        showLoading();
        if (fidelityId) {
          await UpdateNameFidelityLevelUseCase(
            fidelityService,
            localId,
            fidelityId,
            values
          );
          replace(
            `/private/fidelity/${FidelityFormStep[FidelityFormStep.customization]
            }/${fidelityId}`
          );
        } else {
          const response = await CreateFidelityLevelUseCase(
            fidelityService,
            localId,
            values
          );
          replace(
            `/private/fidelity/${FidelityFormStep[FidelityFormStep.customization]
            }/${response.id}`
          );
        }
      } catch (error: any) {
        setCreateFidelityLevelError(error.response.data.errorMessage);
      } finally {
        setIsLoading(false);
        hideLoading();
      }
    },
    [fidelityId, hideLoading, localId, replace, showLoading]
  );

  const onUpdateFidelityCustomization = useCallback(
    async (values: IFidelityCustomizationFormValue) => {
      try {
        setIsLoading(true);
        showLoading();
        if (fidelityId) {
          await UpdateCustomizationFidelityLevelUseCase(
            fidelityService,
            localId,
            fidelityId,
            values
          );
          replace(
            `/private/fidelity/${FidelityFormStep[FidelityFormStep.points]
            }/${fidelityId}`
          );
        }
      } finally {
        setIsLoading(false);
        hideLoading();
      }
    },
    [fidelityId, hideLoading, localId, replace, showLoading]
  );

  const onUpdateFidelityPoints = useCallback(
    async (values: IFidelityPointFormValue) => {
      try {
        setIsLoading(true);
        showLoading();
        if (fidelityId) {
          await UpdatePointRulesFidelityLevelUseCase(
            fidelityService,
            localId,
            fidelityId,
            values
          );
          replace(
            `/private/fidelity/${FidelityFormStep[FidelityFormStep.coins]
            }/${fidelityId}`
          );
        }
      } finally {
        setIsLoading(false);
        hideLoading();
      }
    },
    [fidelityId, hideLoading, localId, replace, showLoading]
  );

  const onUpdateFidelityCoins = useCallback(
    async (values: IFidelityCoinsFormValue) => {
      try {
        setIsLoading(true);
        showLoading();
        if (fidelityId) {
          await UpdateCoinsRulesFidelityLevelUseCase(
            fidelityService,
            localId,
            fidelityId,
            values
          );
          replace(
            `/private/fidelity/${FidelityFormStep[FidelityFormStep.success]
            }/${fidelityId}`
          );
        }
      } finally {
        setIsLoading(false);
        hideLoading();
      }
    },
    [fidelityId, hideLoading, localId, replace, showLoading]
  );


  const onUpdateFidelityProducts = useCallback(async (values: IProductFidelity[]) => {

    try {
      setIsLoading(true);
      showLoading();
      if (fidelityId) {
        await UpdateFidelityProductsUseCase(fidelityService, localId, fidelityId, values);
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.success]
          }/${fidelityId}`
        );
      }
    } finally {
      setIsLoading(false);
      hideLoading();
    }
  }, [fidelityId, hideLoading, localId, replace, showLoading])

  const onClickBackHandle = useCallback(() => {
    switch (step) {
      case FidelityFormStep.customization:
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.name]
          }/${fidelityId}`
        );
        break;
      case FidelityFormStep.points:
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.customization]
          }/${fidelityId}`
        );
        break;
      case FidelityFormStep.coins:
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.points]
          }/${fidelityId}`
        );
        break;
      case FidelityFormStep.benefits:
        replace(
          `/private/fidelity/${FidelityFormStep[FidelityFormStep.success]
          }/${fidelityId}`
        );
        break;

      default:
        break;
    }
  }, [fidelityId, replace, step]);

  useEffect(() => {
    getDefaultValuesHandle();
  }, [getDefaultValuesHandle]);

  const getProductList = useCallback(
    async () => {
      const response = await GetProductsListFidelityUseCase(fidelityService, currentLocal!.id);
      return response
    },
    [currentLocal],
  )
  const getFidelityProducts = useCallback(
    async () => {
      try {
        setIsLoading(true);
        showLoading();
        if (fidelityId) {
          const response = await GetFidelityProductsDefaultUseCase(fidelityService, fidelityId);
          return response
        }
      } finally {
        setIsLoading(false);
        hideLoading();
      }
    },
    [fidelityId, hideLoading, showLoading],
  )


  var formSteps = useMemo(() => {
    switch (step) {
      case FidelityFormStep.name:
        return (
          <FidelityNameForm
            defaultValue={defaultValue}
            onClose={onClose}
            onSubmit={onCreateOrUpdateFidelityLevel}
            isFirst={isFirst}
            responseError={createFidelityLevelError}
            data={data}
            fidelityId={fidelityId}
          />
        );
      case FidelityFormStep.customization:
        return (
          <FidelityCustomizationForm
            defaultValue={defaultValue?.customization}
            onSubmit={onUpdateFidelityCustomization}
            onClickBack={onClickBackHandle}
          />
        );
      case FidelityFormStep.points:
        return (
          <FidelityPointForm
            defaultValue={defaultValue?.levelScoringRules}
            onSubmit={onUpdateFidelityPoints}
            onClickBack={onClickBackHandle}
          />
        );
      case FidelityFormStep.coins:
        return (
          <FidelityCoinsForm
            defaultValue={defaultValue?.coinsScoringRules}
            onSubmit={onUpdateFidelityCoins}
            onClickBack={onClickBackHandle}
          />
        );
      case FidelityFormStep.products:
        return (
          <FidelityProductsForm
            getProductList={getProductList}
            currentExchange={defaultValue?.coinsScoringRules.coinExchange?.amountValue}
            onSubmit={onUpdateFidelityProducts}
            getDefault={getFidelityProducts}
          />
        );
      case FidelityFormStep.success:
        return (
          <div className={styles.successContainer}>
            <div className={styles.success}>
              <img src="/assets/img/concluded.png" alt="" />
              <span>
                Pronto! O <strong>nível de fidelidade</strong> foi criado com
                sucesso. Seus clientes já passarão a{" "}
                <strong>contabilizar pontos e moedas!</strong>
              </span>
            </div>
            <div className={styles.separator}>
              <div />
              <span>você também pode</span>
              <div />
            </div>
            <button className={styles.ghostButton} onClick={() => {
              replace(
                `/private/fidelity/${FidelityFormStep[FidelityFormStep.benefits]
                }/${fidelityId}`
              );
            }}>
              <Icon>star</Icon>
              <span className={styles.text}>Escrever outros benefícios</span>
              <Icon>chevron_right</Icon>
            </button>
            <button className={styles.ghostButton} onClick={() => {
              replace(
                `/private/fidelity/${FidelityFormStep[FidelityFormStep.products]
                }/${fidelityId}`
              );
            }}>
              <Icon>star</Icon>
              <span className={styles.text}>Produtos</span>
              <Icon>chevron_right</Icon>
            </button>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.buttons}
                type="button"
                onClick={onClose}
                variant="contained"
                color="secondary"
              >
                Fechar
              </Button>
            </div>
          </div>
        );
      case FidelityFormStep.benefits:
        return (
          <FidelityBenefitsForm
            defaultValues={defaultValue?.benefits}
            onSubmit={onCreateOrUpdateBenefits}
            onClickBack={onClickBackHandle}
          />
        );
    }
  }, [
    step,
    defaultValue,
    fidelityId,
    isFirst,
    createFidelityLevelError,
    onClose,
    onCreateOrUpdateFidelityLevel,
    onUpdateFidelityCustomization, onClickBackHandle, onUpdateFidelityPoints, onUpdateFidelityCoins, getProductList, onUpdateFidelityProducts, getFidelityProducts, onCreateOrUpdateBenefits, replace,
  ]);

  return (
    <div id={styles.FidelityForm}>
      {/* <header>
        {
          step === FidelityFormStep.benefits &&
          <IconButton onClick={onClickBackHandle} className={styles.backButton}><Icon>chevron_left</Icon></IconButton>
        }
        <h1>
          {getFormTitle()[0]}<b>&nbsp;{getFormTitle()[1]}</b>
        </h1>{" "}
        <IconButton onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </header> */}

      <div style={{ width: "100%" }}>
        {(step !== FidelityFormStep.success && step !== FidelityFormStep.benefits) && (
          <TabView
            index={step ?? FidelityFormStep.name}
            tabs={[
              { label: "Detalhes", value: FidelityFormStep.name },
              { label: "Personalização", value: FidelityFormStep.customization },
              { label: "Pontos", value: FidelityFormStep.points },
              { label: "Moedas", value: FidelityFormStep.coins },
            ]}
            scrollButton="on"
          />
        )}        
      </div>

      <div className={styles.container}>{formSteps}</div>
    </div>
  );
};
export default FidelityForm;
