import AddPersonToPublicPeopleListUseCase from "modules/events/application/useCases/discounts/AddPersonToPublicPeopleListUseCase";
import GetPeopleListToEventUseCase from "modules/events/application/useCases/discounts/GetPeopleListToEventUseCase";
import { IPeopleListToEvent } from "modules/events/domain/dto/IEventsResponse";
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import CPF from "services/utils/CPF";

const service = DiscountBaseListApi();

const validate = (cpfList: string[]): [boolean, string[]] => {
  let cpfValid: string[] = [];
  let cpfInvalids: string[] = [];

  cpfList.forEach((item) => {
    item = item.replace(/\D/g, "")
    if (item.length && CPF.isValid(item)) {
      cpfValid.push(item);
    } else {
      if (item.length)
        cpfInvalids.push(item);
    }
  });

  if (cpfInvalids.length) {
    return [false, cpfInvalids];
  } else {
    return [true, []];
  }
};

export const UsePublicGuestList = () => {
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<IPeopleListToEvent>({} as IPeopleListToEvent);
  const { id } = useParams<{ id: string }>();
  const [invalidDocs, setInvalidDocs] = useState<string[] | undefined>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [listNotFound, setListNotFound] = useState(false);
  const [usersToMigrate, setUsersToMigrate] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await GetPeopleListToEventUseCase(service, id);
        setData(response);
        if (!response.event) setListNotFound(true);
      } catch {
        setListNotFound(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const handleValidate = useCallback((cpfList: string) => {
    setInvalidDocs(undefined);
    const listArr = cpfList.split("\n");
    const [isValid, cpfStrList] = validate(listArr);

    if (!isValid) {
      setInvalidDocs(cpfStrList);
      return false;
    }

    return true;
  }, [])

  const handleSubmit = async (cpfList: string) => {
    try {
      if (handleValidate(cpfList)) {
        setSaveLoading(true);
        const response = await AddPersonToPublicPeopleListUseCase(service, id, cpfList.replace(/[^\d\n]/g, "").split("\n").filter(item => item !== ''));
        if (response.guestsListedToMigration.length) {
          if (cpfList.split('\n').length === response.guestsListedToMigration.length) {
            setIsFailModalOpen(true);
          } else {
            setUsersToMigrate(response.guestsListedToMigration.map(x => x.cpf));
            setIsSuccess(true);
          }
        } else {
          setIsSuccess(true);
        }
      }
    } finally {
      setSaveLoading(false);
    }
  }

  return {
    loading,
    data,
    isModalOpen,
    setIsModalOpen,
    isSuccess,
    setIsSuccess,
    handleSubmit,
    saveLoading,
    invalidDocs,
    isDisabled,
    listNotFound,
    usersToMigrate,
    isFailModalOpen,
    setIsFailModalOpen,
    setIsDisabled,
  }
}