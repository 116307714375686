import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { OnBoardingSteps } from 'modules/onBoardingTutorials/domain/enums/OnBoardingSteps';
import UseOnboardingStore from 'modules/onBoardingTutorials/store/OnBoardingStore';
import { closeTutorialCard } from 'modules/onBoardingTutorials/store/TutorialCardStore';
import { useCallback, useState } from 'react'
import OnBoardingService from 'services/api/onBoardingTutorial/OnBoardingService';

const onBoardingService = OnBoardingService();

const UseHomeTutorials = () => {
    const { hasOnboardingStep, onFinishStep, onCloseStep } = UseOnboardingStore();
    const [openModalCielo, setOpenModalCielo] = useState(false);
    const { currentLocal } = useLocal();


    const showCieloTutorial = useCallback(
        async () => {
            if (currentLocal === undefined) return;
            onFinishStep(onBoardingService, {
                localId: currentLocal?.id ?? '',
                step: OnBoardingSteps.Welcome
            })
            closeTutorialCard();

            const hasOnBoardStep = await hasOnboardingStep(onBoardingService, currentLocal?.id, OnBoardingSteps.Cielo);
            console.log(hasOnBoardStep);
            setOpenModalCielo(!hasOnBoardStep);
        }, [currentLocal, hasOnboardingStep, onFinishStep]
    );

    const onFinishCieloStep = useCallback(() => {
        setOpenModalCielo(false);
        onFinishStep(onBoardingService, {
            localId: currentLocal?.id ?? '',
            step: OnBoardingSteps.Cielo,
        });
    }, [currentLocal?.id, onFinishStep]);

    return ({ showCieloTutorial, onFinishCieloStep, openModalCielo });
}

export default UseHomeTutorials;