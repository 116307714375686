import GetRechargeOriginUseCase from "modules/cashlessDashboard/application/useCases/GetRechargeOriginUseCase";
import { useCallback, useState } from "react";
import CashlessDashboardService from "services/api/cashlessDashboard/CashlessDashboardService";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { IFilterValue } from "../filter/IFilter";
import { IRechargeOrigin } from "./interfaces/IRechargeOrigin";

const cashlessDashboardService = CashlessDashboardService();

export const UseRechargeOrigin = (filter: IFilterValue) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<IRechargeOrigin>()

    const { setOnError, clearContainerError } = useError()

    const getData = useCallback(async () => {
        try {
            clearContainerError(dashboardContainerEnum.RECHARGE_ORIGIN);
            clearContainerError(dashboardContainerEnum.RECHARGE_ORIGIN_CHART);
            setLoading(true)
            if (filter.cashierIds.length){ 
                const response = await GetRechargeOriginUseCase(cashlessDashboardService, { filter });
                setData(response)
            }
        } catch (err) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RECHARGE_ORIGIN
            })
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RECHARGE_ORIGIN_CHART
            })
        } finally {
            setLoading(false)
        }
    }, [clearContainerError, filter, setOnError])

    return {
        loading, 
        data,
        getData,
    }
}
