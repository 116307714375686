import { IPostNewWizard } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";
import { IPutEditWizard } from "modules/cadastrarTutorial/domain/dto/IPutEditWizard";
import { ICadastrarTutorialService } from "modules/cadastrarTutorial/domain/interface/ICadastrarTutorialService";
import { api } from 'services/api/Api';

const baseUrl = process.env.REACT_APP_DYNAMICMENU_URL;

const CadastrarTutorialApi = (): ICadastrarTutorialService => {
    const postNewTutorial = async (request: IPostNewWizard): Promise<any> => {
        const respomse = await api.post<any>(`${baseUrl}/PageWizard/`, request);
        return respomse.data;
    }

    const putEditTutorial = async (request: IPutEditWizard): Promise<any> => {
        const respomse = await api.put<any>(`${baseUrl}/PageWizard/`, request);
        return respomse.data;
    }

    const deleteTutorial = async (tutorialId: string): Promise<any> => {
        const respomse = await api.delete<any>(`${baseUrl}/PageWizard/${tutorialId}`);
        return respomse.data;
    }

    return {postNewTutorial, putEditTutorial, deleteTutorial};
}

export default CadastrarTutorialApi;
