import { useHistory } from 'react-router-dom';
import styles from './BlurPremium.module.scss';
import { FeatureTypeEnum } from '../../interfaces/FeatureTypeEnum';
import { usePlans } from '../../hooks/PlansContext';

interface BlurPremiumProps {
    feature: FeatureTypeEnum;
    children: JSX.Element;
}

export const BlurPremium = ({ feature, children }: BlurPremiumProps) => {
    const history = useHistory();
    const { checkPermission } = usePlans();

    const getText = () => {
        switch (feature) {
            case FeatureTypeEnum.FIDELITY:
                return 'ao Programa de Fidelidade';
            case FeatureTypeEnum.AUTOMACAO:
                return 'à Automação';
            case FeatureTypeEnum.SURVEY:
                return 'à Pesquisa de Satisfação';
            case FeatureTypeEnum.CRM:
                return 'às Campanhas';
                default:
                return 'aos Eventos';
        }
    }

    return (
        <>
            {
                !checkPermission(feature) &&
                <div className={styles.container}>
                    <div>
                        <img src="/assets/icon/golden-crowns.svg" alt="" />

                        <span>Para ter acesso {getText()}, <b>seja Premium!</b></span>

                        <button onClick={() => history.push('/private/plans')}>Fazer upgrade</button>
                    </div>
                </div>
            }
            <div style={!checkPermission(feature) ? { filter: 'blur(5px)' } : {}}>
                {children}
            </div>
        </>
    )
}