import React, { FC, useCallback, useMemo, useState } from 'react'
import styles from './SupplyList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import DefaultActionButtonBuilder from '../../ui/defaultListActionButtons/DefaultListActionButtons';
import { ISupllyNameItemSelect, ISupplyFilterValue } from './supplyFilter/ISupplyFilter';
import { IGetSupplyItemResponse, IGetSupplyListResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
import SupplyFilter from './supplyFilter/SupplyFilter';
import { ISupplyGroupList, ISupplyGroupListRequest } from '../suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos';
import { Icon } from '@mui/material';
import { Box } from '../../ui/form/FormContainers';
import Sidesheet from 'components/sidesheet/Sidesheet';
import { formatCurrency } from 'modules/meepErp/presentation/utils/Format';
import { IGetSupplyCostsResponse } from 'modules/meepErp/application/dtos/supply/IGetSupplyCostsResponse';

export interface ISupplyListProps {
    filter: ISupplyFilterValue
    isLoading: boolean;
    supplyName: ISupllyNameItemSelect[]
    supply?: IGetSupplyListResponse
    onChangeFilter: (filter: ISupplyFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => void
    onClickEnable: (id: string, isActive: boolean, value: IGetSupplyItemResponse) => Promise<IGetSupplyItemResponse>
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
    getSupplyCosts: (id: string) => Promise<void>
    itemSupply?: IGetSupplyCostsResponse[]
    setItemSupply: React.Dispatch<React.SetStateAction<IGetSupplyCostsResponse[] | undefined>>

}
const SupplyList: FC<ISupplyListProps> = ({
    supplyName,
    supply,
    onDelete,
    onEdit,
    onClickEnable,
    filter,
    onChangeFilter,
    isLoading,
    getSupplyGroupList,
    GetSearchNameSupplyGroup,
    itemSupply,
    setItemSupply,
    getSupplyCosts
}) => {
    const { hasAccessRole } = useAuth()
    const [openCost, setOpenCost] = useState(false)

    const submitFilter = useCallback((values: ISupplyFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])


    const columnsConfig: ColumnConfigs<IGetSupplyItemResponse> = {
        code: { title: "Código", sortable: true },
        name: { title: "Nome", sortable: true },
        supplyGroup: { title: "Grupo de insumo", sortable: true },
        minStock: { title: "Estoque mínimo", sortable: true },
        meepProductId: { title: "Produto de venda", sortable: true, renderRow: (value: boolean) => value ? "Sim" : "Não" },
    }

    const actionButtons = [
        {
            tooltip: "Cálculo de custo",
            icon: "attach_money",
            showLoading: true,
            onClick: (value: IGetSupplyItemResponse) => {
                getSupplyCosts(value.id)
                setOpenCost(true)
            }
        },
        {
            tooltip: "Status",
            iconRender: (item: IGetSupplyItemResponse) => item.isActive ? <Icon style={{ color: '#001537' }}>visibility</Icon> : <Icon style={{ color: '#001537' }}>visibility_off</Icon>,
            showLoading: true,
            onClick: (value: IGetSupplyItemResponse) => onClickEnable(value.id, !value.isActive, value)
        },
        {
            tooltip: "Editar",
            icon: "edit",
            showLoading: true,
            onClick: (value: IGetSupplyItemResponse) => onEdit(value.id)
        },
        {
            tooltip: "Excluir",
            icon: "delete",
            showLoading: true,
            onClick: (value: IGetSupplyItemResponse) => onDelete(value.id)
        },
    ]

    const pagination = useMemo(() => {
        return {
            page: supply?.page ?? 1,
            pageSize: 10,
            count: supply?.totalPages ?? 0
        }
    }, [supply?.totalPages, supply?.page])

    const totalCost = itemSupply?.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.cost;
    }, 0);

    return (
        <div id={styles.InventoryOperationsList} >
            <SupplyFilter
                supplyName={supplyName}
                onSubmit={submitFilter}
                defaultValue={filter}
                getSupplyGroupList={getSupplyGroupList}
                GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
            />
            <List
                loading={isLoading}
                minWidth={700}
                list={supply?.items}
                columnsConfigs={columnsConfig}
                actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                pagination={pagination}
                onChangePage={onChangePage}
                onClickSort={onClickSort}

            />
            <Sidesheet
                open={openCost}
                onClose={() => {
                    setOpenCost(false)
                    setItemSupply(undefined)
                }}
                title={<h2>Cálculo de <b>custo</b></h2>}
                content={
                    <div className={styles.cost}>
                        {itemSupply && itemSupply?.length > 0 ? (
                            <>
                                {itemSupply?.map(item => {
                                    return (
                                        <div className={styles.costContainer}>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Código:</span>
                                                <span className={styles.value}>{item?.code}</span>
                                            </div>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Nome:</span>
                                                <span className={styles.value}>{item?.name}</span>
                                            </div>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Quantidade:</span>
                                                <span className={styles.value}>{item?.quantity}</span>
                                            </div>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Unidade:</span>
                                                <span className={styles.value}>{item?.measurementUnitSymbol}</span>
                                            </div>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Local de estoque:</span>
                                                <span className={styles.value}>{item?.storageLocationName}</span>
                                            </div>
                                            <div className={styles.costItem}>
                                                <span className={styles.title}>Custo:</span>
                                                <span className={styles.cost}>{formatCurrency(item?.cost)}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                             
                                    <Box style={{ flex: 'initial' }}>
                                        <div>
                                            <label>Valor Total</label>
                                            <span> {totalCost !== undefined && formatCurrency(totalCost)}</span>
                                        </div>
                                    </Box>
                                
                            </>
                        ) : (
                            <div className={styles.emptyList}>
                                <img src="/assets/img/empty-box.png" alt="" />
                                <span>Este produto <b>não possui composição!</b></span>
                            </div>
                        )}
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                notTotalHeight
                continueButton="Fechar"
                handleContinueButton={() => {
                    setOpenCost(false)
                    setItemSupply(undefined)
                }}

            />
        </div>
    )
}
export default SupplyList