import { IGradientTable } from "components/graphs/gradientTable/IGradientTable.types";
import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService";
import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "./MakeFilterRequest";

const GetPaymentFormEquipmentUseCase = async (service: ISalesDashboardService, params: IUseCaseParams): Promise<IGradientTable> => {
  const filterRequest = makeFilterRequest(params);
  
  return await service.getPaymentFormEquipment(filterRequest);
}

export default GetPaymentFormEquipmentUseCase;