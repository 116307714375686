import React, { useCallback, useEffect } from "react";
import { Close } from "@mui/icons-material";

import styles from "./LeftSideMenu.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";

const freemiumUrls = [
    '/private/plans',
    '/private/equipe',
    '/private/solucoesOnline',
    '/private/catalog',
    '/private/ferramentas',
    '/private/saas/acesso-freemium',
    '/private/saas/payment',
];

interface Props {
    onClose: () => void;
    title: string;
    titleBold?: string;
    menuRef?: React.RefObject<HTMLDivElement>
}

const LeftSideMenu: React.FC<Props> = ({ onClose, title, children, menuRef, titleBold }) => {
    const { pathname } = useLocation();
    const { subscription } = usePlans();
    const { push } = useHistory();

    const validatePath = useCallback(() => {
        if (subscription?.subscriptionType === SubscriptionType.FREEMIUM) {
            const urlIndex = freemiumUrls.findIndex(url => pathname.startsWith(url));

            if (urlIndex === -1) {
                push('/private/saas/acesso-limitado');
            }
        }

    }, [pathname, subscription]);

    useEffect(() => {
        validatePath();
    }, [pathname, subscription, validatePath])

    return (
        <div ref={menuRef} id={styles.LeftSideMenu}>
            <div className={styles.header}>
                <div>
                    <span>{title}</span>
                    <span className={styles.titleBold}>{titleBold}</span>
                </div>
                <Close onClick={onClose} />
            </div>
            {children}
        </div>
    );
};

export default LeftSideMenu;