import PageHeader from "components/ui/pageHeader/PageHeader";
import React, { FC } from "react";
import GuestListForm from "../../components/guestListForm/GuestListForm";
import { UseAddGuestListPage } from "./UseAddGuestListPage";

const AddGuestListPage: FC = () => {
  const { handleAddList, handleCancel } = UseAddGuestListPage();

  return (
    <div>
      <PageHeader title={"Adicionar Lista"} />
      <GuestListForm handleSubmit={handleAddList} handleCancel={handleCancel} />
    </div>
  );
};
export default AddGuestListPage;
