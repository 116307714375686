import { IClosePendingRequest } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

const ClosePendingsUseCase = async (
  eventsService: IEventsApi,
  pendingItem: IClosePendingRequest
): Promise<any> => {
  return await eventsService.closePending(pendingItem);
};

export default ClosePendingsUseCase;
