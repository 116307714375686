import { IResponseConfigLocal } from "modules/gestaoVendas/domain/dtos/IGetSegmentos";

export interface IBodySegmentos {
  TipoLocalId: number;
}
export interface IBodyMesas {
  LocalClienteId: string;
  Inicio: number;
  Fim: number;
}
export interface IReponseMesas {
  Id: string;
  Numero: string;
  ProprietarioId: string;
}

export interface IReponseOperacoes {
  Id: string;
  ComandaIndividual: boolean;
  Mesa: boolean;
  PrePagoFicha: boolean;
  PrePagoCashless: boolean;
}

export interface IReponseOperadores {
  papeis: string[];
  nome: string;
}

export interface IBodyOperacoes {
  LocalClienteId: string;
  ComandaIndividual: boolean;
  Mesa: boolean;
  PrePagoFicha: boolean;
  PrePagoCashless: boolean;
}

export enum OperatiomMethodTypes {
  Ticket = 1,
  Cashless = 2,
  OrderPad = 4,
}

export interface IGetConfigDataResponse {
  id: string;
  catalogUrl: string;
  isActive: boolean;
  allowOnlinePayment: boolean;
  allowOnlinePaymentWeb: boolean;
  enableShoppingCartOnVirtualMenu: boolean;
  enableServiceTax: boolean;
  serviceTaxPercentage: number;
  allowOnlinePaymentApp: boolean;
  allowConsumeInApp: boolean;
  enableServiceTaxInApp: boolean;
  inPlaceEnabled: boolean;
  serviceTaxPercentageInApp: number;
  maxCheckInDistanceMeters: number;
  allowManualCheckinWithCardActivation: boolean;
  allowConsumeCashless: boolean;
  allowConsumeTicketInApp: boolean;
  isVisibleInApp: boolean;
  featuredApp: boolean;
  catalogApp: {
    id: string;
    description: string;
  };
  catalogWeb: {
    id: string;
    description: string;
  };
  notificationPhone: boolean;
  allowCheckinWithoutLocation: boolean;
  isTemporarilyClosed: boolean;
}

export interface IPostConfigDataRequest {
  localId: string;
  catalogAppId?: string;
  catalogWebId?: string;
  isActive: boolean;
  allowOnlinePaymentWeb: boolean;
  enableShoppingCartOnVirtualMenu: boolean;
  enableServiceTax: boolean;
  serviceTaxPercentage: number;
  allowOnlinePaymentApp: boolean;
  allowConsumeInApp: boolean;
  enableServiceTaxInApp: boolean;
  inPlaceEnabled: boolean;
  serviceTaxPercentageInApp: number;
  maxCheckInDistanceMeters: number;
  allowManualCheckinWithCardActivation: boolean;
  allowConsumeCashless: boolean;
  allowConsumeTicketInApp: boolean;
  isVisibleInApp: boolean;
  featuredApp: boolean;
  notificationPhone: boolean;
  allowCheckinWithoutLocation: boolean;
  isTemporarilyClosed: boolean;
}

export interface IUpdateSegmentosService {
  updateSegmentos: (id: string, body: IBodySegmentos) => Promise<void>;
}
export interface IGetSegmentosService {
  getSegmentos: (id: string) => Promise<IResponseConfigLocal>;
}
export interface IGetMesasService {
  getMesas: (id: string) => Promise<IReponseMesas[]>;
}
export interface IGetOperadorService {
  getOperador: (id: string) => Promise<IReponseOperadores[]>;
}
export interface IPostMesasService {
  postMesas: (body: IBodyMesas) => Promise<void>;//TODO: Por que ta com void se tem retorno ?
}
export interface IPostOperacoesService {
  postOperacao: (id: string, body: IBodyOperacoes) => Promise<void>;//TODO: Por que ta com void se tem retorno ?
}

export interface IDefaultProfilesService {
  postDefaultProfiles: (id: string, methods: OperatiomMethodTypes[]) => Promise<void>;//TODO: Por que ta com void se tem retorno ?
}
export interface IGetOperacoesService {
  getOperacao: (id: string) => Promise<IReponseOperacoes>;
}
export interface IGetConfigDataService {
  getConfigData: (id: string) => Promise<IGetConfigDataResponse>;
}

export interface IPostConfigDataService {
  postConfigData: (request: IPostConfigDataRequest) => Promise<void>;
}

export interface ICloseLocalService {
  closeLocal: (localId: string, enabled: boolean) => Promise<void>;
}

export type IUpdateSegmentosServiceApiCB = () => IUpdateSegmentosService;//TODO: Por que ta com void se tem retorno ?

export type IGetSegmentosServiceApiCB = () => IGetSegmentosService;

export type IGetMesasServiceApiCB = () => IGetMesasService;

export type IGetOperacoesServiceApiCB = () => IGetOperacoesService;

export type IPostMesasServiceApiCB = () => IPostMesasService;

export type IPostOperacoesServiceApiCB = () => IPostOperacoesService;

export type IDefaultProfilesServiceApiCB = () => IDefaultProfilesService;

export type IGetOperadorServiceApiCB = () => IGetOperadorService;
