import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddDeviceUseCase from "modules/equipamento/application/useCases/addDevice/AddDeviceUseCase";
import GetLicenseListUseCase from "modules/equipamento/application/useCases/deviceLicense/GetLicenseListUseCase";
import EditDeviceUseCase from "modules/equipamento/application/useCases/editDevice/EditDeviceUseCase";
import { FormDeviceStep } from "modules/config/deviceConfig/domain/interface/FormDeviceStep";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { IDeviceLicenseItem } from "../components/deviceForms/deviceLicenseForm/interface/IDeviceLicenseItem";
import { IDeviceValue } from "../components/deviceForms/deviceLicenseForm/interface/IDeviceValue";
import DeviceApi from "services/api/equipamento/DeviceApi";

const UseDeviceLicense = () => {
  const [licenses, setLicenses] = useState<IDeviceLicenseItem[]>();

  const { showLoading, hideLoading } = useUi();
  const { push } = useHistory();

  const addNewDevice = useCallback(
    async (values: IDeviceValue) => {
      try {
        showLoading();
        const serviceDevice = DeviceApi();
        const response = await AddDeviceUseCase(serviceDevice, values);
        push(
          `/private/configuracao/pdv/lista/editar/${response.id}/` +
            FormDeviceStep.LINK
        );
      } finally {
        hideLoading();
      }
    },
    [hideLoading, push, showLoading]
  );

  const editNewDevice = useCallback(
    async (deviceId: string, values: IDeviceValue) => {
      try {
        showLoading();
        const serviceDevice = DeviceApi();
        await EditDeviceUseCase(serviceDevice, deviceId, values);
        push(
          `/private/configuracao/pdv/lista/editar/${deviceId}/` +
            FormDeviceStep.LINK
        );
      } finally {
        hideLoading();
      }
    },
    [hideLoading, push, showLoading]
  );

  const onClickLicense = useCallback(
    (licenseItem: IDeviceLicenseItem) => {
      addNewDevice({ type: licenseItem.type.value });
    },
    [addNewDevice]
  );

  const getLicenseList = useCallback(
    async (localId: string) => {
      try {
        showLoading();
        const service = DeviceApi();
        const licenseList = await GetLicenseListUseCase(service, localId);
        setLicenses(licenseList);
      } finally {
        hideLoading();
      }
    },
    [hideLoading, showLoading]
  );

  return {
    licenses,
    addNewDevice,
    editNewDevice,
    onClickLicense,
    getLicenseList,
  };
};

export default UseDeviceLicense;
