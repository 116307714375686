import React from 'react';
import Button from '../Button/Button';
import styles from './FilterForm.module.scss'

interface FilterFormProps {
  onSubmit: any,
}


const FilterForm: React.FC<FilterFormProps> = ({

  onSubmit,
  children

}) => {

  return (
    <div id={styles.FilterForm}>

      <form style={{ width: "100%" }} onSubmit={onSubmit}>

        {children}

        <div>
          <Button className={styles.button} fullWidth={false} onClick={onSubmit}>
            Buscar
          </Button>
        </div>


      </form>
    </div>
  );
}

export default FilterForm;