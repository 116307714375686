import { ShortcutsEnum } from "./IUsabilityValues";

export const homeScreenShortcutsOptions = [
    ShortcutsEnum["Vender produtos"],
    ShortcutsEnum["Sincronizar"],
    ShortcutsEnum["Histórico"],
    ShortcutsEnum["Sair do caixa"],
    ShortcutsEnum["Fechar caixa"],
    ShortcutsEnum["Reconectar impressora"],
    ShortcutsEnum["Estornar produto"],
    ShortcutsEnum["Consumir produto"],
    ShortcutsEnum["Extrato parcial"],
    ShortcutsEnum["Desativar cartão"],
    ShortcutsEnum["Transferência cartão"],
    ShortcutsEnum["Validar saldo"],
    ShortcutsEnum["Agrupar cartões"],
    ShortcutsEnum["Recarga ativação"],
    ShortcutsEnum["Comandar produtos"],
    ShortcutsEnum["Transferência comanda"],
    ShortcutsEnum["Extrato parcial comanda"],
    ShortcutsEnum["Validar Saldo"],
    ShortcutsEnum["Desativar pós pago"],
    ShortcutsEnum["Atualizar dados"],
    ShortcutsEnum["Meep food"],
    ShortcutsEnum["Meep check"],
    ShortcutsEnum["Substituir comanda"],
];
