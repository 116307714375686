import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import EquipamentoApi from "services/api/equipamento/DeviceApi";
import GetEquipmentsUseCase from "modules/equipamento/application/useCases/GetEquipmentsUseCase";
import { IGetEquipmentsResponse } from "modules/equipamento/domain/dto/IGetEquipmentsResponse";

const service = EquipamentoApi();

export const UseGetEquipments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [equipments, setEquipments] = useState<IGetEquipmentsResponse>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const getEquipments = useCallback(async () => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        const response = await GetEquipmentsUseCase(service, currentLocal?.id);
        setEquipments(response);
      } catch (error) {
        toast("Ocorreu um erro. Tente de novo.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, toast]);

  useEffect(() => {
    getEquipments();
  }, [getEquipments]);

  return {
    isLoading,
    equipments,
    getEquipments,
  };
};
