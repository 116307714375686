import OpenInNew from '@mui/icons-material/OpenInNew';

import Button from 'components/ui/Button/Button';
import styles from './CalculatorBanner.module.scss';

interface CalculatorBannerProps {
    title: string;
    description: string;
    exploreSystemFn: () => void;
    howToWorksFn: () => void;
}

const CalculatorBanner = ({ title, description, exploreSystemFn, howToWorksFn }: CalculatorBannerProps) => {
    return (
        <div className={styles.calculatorBanner}>
            <div className={styles.calculatorBannerTexts}>
                <p className={styles.calculatorBannerLabel}>Mee by Meep</p>
                <p className={styles.calculatorBannerTitle}>{title}</p>
                <p className={styles.calculatorBannerDescription}>{description}</p>
            </div>
            <div className={styles.calculatorBannerActions}>
                <Button endIcon={<OpenInNew />} onClick={exploreSystemFn}>Explore o sistema</Button>
                <Button variant="outlined" onClick={howToWorksFn}>Entenda como funciona</Button>
            </div>
        </div>
    );
}

export default CalculatorBanner;