import { IProduct } from "models/product/IProduct";

export const parseObject = (product: IProduct, eventoId: string) => ({
  adultHood:product.adultHood ,
  codigoBarra: product.barCode,
  bloquearImpressao:product.blockPrinting ,
  cest:product.cest ,
  cfop:product.cfop ,
  composicao:product.compositions ,
  informacaoProduto: product.description,
  ativado: product.isActive,
  categoria: product.productCategoryId,
  categoriaId: product.productCategoryId,
  categoriaLocalCliente: product.localClientCategory,
  categoriaLocalClienteId: product.localClientCategoryId ,
  classificacaoNutricional:product.nutritionalClassification ,
  codigoInterno:product.internalCode ,
  controlaEstoque:product.stockControl ,
  destaque: product.isFeatured,
  eventoId,
  expireTime: product.expireTime,
  id: product.id,
  impressoraPadraoId: product.printerName,
  isencaoTaxaServico: product.noServiceFee,
  localId:product.localId ,
  ncm: product.ncm,
  nome: product.name,
  nomeLocal: product.localName,
  observacaoGeralDoProduto:product.productGeneralObservation ,
  quantidadeReservado:product.reservedQuantity ,
  storeId:product.storeId ,
  restricaoPorSexo:product.genderRestriction ,
  tara: product.tare,
  tipo: product.type,
  valor: product.price,
});
