import { Drawer } from '@material-ui/core';
import { FC, useMemo } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons';
import { IDeviceProfileItem } from '../interfaces/IProfileItem';
import { Close } from '@material-ui/icons';

import styles from './DeleteAlert.module.scss';
import AlertButton from '../../../alertButton/AlertButton';
export interface IDeleteAlertProps {
    profile?: IDeviceProfileItem;
    onClose: () => void;
    onConfirm: (profile: IDeviceProfileItem) => void;
}
const DeleteProfileAlert: FC<IDeleteAlertProps> = ({ profile, onClose, onConfirm }) => {


    const linkedDevices = useMemo(() => {
        let counter = 0;
        profile?.devices?.forEach(item => {
            if (item.profile?.id === profile.id) {
                counter += 1;
            }
        });
        return counter;
    }, [profile?.devices, profile?.id]);

    const pluralize = (text: string) => {
        const pluralized = text[text.length - 1] === 'e' ? text + 'm' : text + 's';

        if (profile && linkedDevices > 1) return pluralized;
        return text;
    }

    return (
        <Drawer anchor='right' open={!!profile}>
            <div className={styles.container}>
                <div className={styles.containerTitle}>
                    <h2>Excluir <span>Perfil de Venda</span></h2>
                    <Close onClick={onClose} />
                </div>

                <AlertButton 
                    type="warnning" 
                    label={`Deseja realmente excluir o perfil de venda? \n${pluralize('Existe')} ${linkedDevices} ${pluralize('equipamento')} ${pluralize('vinculado')} à ele.`}
                />
                        
                {/* {
                    profile?.devices.length &&
                        <div className={styles.devices}>
                            <span>Equipamentos vinculados:</span>
                            <div>
                                {
                                    profile.devices.map(device => (
                                        <div key={device.id}>{device.name || 'SEM TAG'} ({device.typeName})</div>
                                    ))
                                }
                            </div>
                        </div>
                } */}

                <FooterButtons onClickBack={onClose}
                    onClickNext={() => profile && onConfirm(profile)} labelBack={'Cancelar'} labelNext={'Apagar'} />
            </div>
        </Drawer>
    )
}
export default DeleteProfileAlert
