import { ITerminalService } from "../interface/ITerminalService"
import { PostApproveSolicitationResponse } from "../dtos/PostApproveSolicitationResponse";
import { generateRandomString } from "modules/Terminal/presentation/component/terminalSidesheets/TerminalSidesheets";

const PostRefuseSolicitationUseCase = async (service: ITerminalService, localId: string, solicitationId: string): Promise<PostApproveSolicitationResponse> => {
  const response = await service.postRefuseSolicitation(localId, {
    navigatorSolicitationId: solicitationId,
    navigatorName: "Navegador" + generateRandomString(5)
  });
  return (response);
}

export default PostRefuseSolicitationUseCase;
