import Layout from "components/layout/presentation/Layout";
import PageHeader from "components/ui/pageHeader/PageHeader";
import EditEventsPage from "modules/events/presentation/pages/editEvents/EditEventsPage";
import React from "react";

const EditEvents = () => {
  return (
    <Layout>
      <PageHeader title="Editar Evento" />
      <EditEventsPage />
    </Layout>
  );
};

export default EditEvents;
