import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { PermutationPage } from "modules/permutation/presentation/pages/PermutationPage";
import { DonationPage } from "modules/donations/presentation/pages/DonationPage";

const Donations: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Doações",
      },      
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <DonationPage />
    </Layout>
  );
};
export default Donations;
