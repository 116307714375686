import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { IPostFacebookConfigRequest } from "modules/config/integrationsConfig/domain/dto/FacebookConfig/IPostFacebookConfigRequest";
import GetFacebookConfigUseCase from "modules/config/integrationsConfig/application/useCases/GetFacebookConfigUseCase";
import PostFacebookConfigUseCase from "modules/config/integrationsConfig/application/useCases/PostFacebookConfigUseCase";

const service = IntegrationApi();

export const UseConfigFacebook = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getIntegration, setGetIntegration] = useState<string>();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const postFacebookConfiguration = useCallback(
    async (values: IPostFacebookConfigRequest) => {
      try {
        setIsLoading(true);
        await PostFacebookConfigUseCase(service, values);
        return "ok";
      } catch {
        toast("Ocorreu um erro. Tente novamente", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [toast]
  );

  const onSubmitHandle = useCallback(
    async (
      values: IPostFacebookConfigRequest,
      step: number,
      setStep: React.Dispatch<React.SetStateAction<number>>
    ) => {
      const res = await postFacebookConfiguration(values);
      if (res === "ok") {
        setStep(step + 1);
      }
    },
    [postFacebookConfiguration]
  );

  useEffect(() => {
    (async () => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          const response = await GetFacebookConfigUseCase(
            service,
            currentLocal?.id
          );
          setGetIntegration(response);
          return "ok";
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [currentLocal, postFacebookConfiguration]);

  return {
    isLoading,
    getIntegration,
    onSubmitHandle,
  };
};
