import { FC, useCallback, useEffect, useState } from 'react'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import CategoryRanking from '../categoryRanking/CategoryRanking'
import RankingList from '../rakingList/RankingList'
import styles from './RankingStores.module.scss'

export interface IRankingStoresValues {
    records: {
        id: string
        placeId: string
        color: string
        name: string
        averageCustomer?: number
        averageTable?: number
        total: number
    }[],
    totalRecords: number
}
export interface IRankingStoresProps {
    getTopParent: ({ page, sortField, sortOrientation }: IGetDataParams, parentCategoryId?: string) => Promise<IRankingStoresValues>
    getSubCategory: ({ page, sortField, sortOrientation }: IGetDataParams, subCategoryId?: string) => Promise<IRankingStoresValues>
}

const RankingStores: FC<IRankingStoresProps> = ({ getTopParent, getSubCategory }) => {

    const [storeValue, setStoreValue] = useState<IRankingStoresValues>();
    const [isLoading, setIsLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    
    const getData = useCallback(async (page?: number) => {
        try {
            clearContainerError(dashboardContainerEnum.RANKING_CATEGORY);
            !page && setIsLoading(true);
            const response = await getTopParent({ page });
            setStoreValue(prev => ({ records: page ? [...prev?.records || [], ...response.records] : response.records, totalRecords: response.totalRecords }));

            if (!page && !response.records.length) {
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.RANKING_CATEGORY
                })
            }
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RANKING_CATEGORY
            })
        } finally {
            setIsLoading(false);
        }
    }, [clearContainerError, getTopParent, setOnError])

    useEffect(() => {
        getData(0);
    }, [getData])

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value)

    // const list = useMemo(() => <div className={styles.listContainer}>
    //     {storeValue?.records.map((store) => <div className={styles.storeItem}>
    //     </div>)}
    // </div>, [storeValue])

    return (
        <div className={styles.RankingStores} >
            <div className={styles.list} >

                <RankingList
                    list={storeValue?.records}
                    count={storeValue?.totalRecords}
                    isLoading={isLoading}
                    getMore={getMore}
                    colunms={{
                        name: { title: "Loja" },
                        averageCustomer: { title: "Ticket médio pessoa", formatValue: (value) => formatCurrency(value), },
                        averageTable: { title: "Ticket médio mesa", formatValue: (value) => formatCurrency(value), },
                        total: { title: "Total" }
                    }}
                    accordeon={(item) => <CategoryRanking
                        subCategoryId={item.id}
                        getData={getTopParent} 
                        getSubItems={getSubCategory}
                        group={false}
                        nested={true}
                    />}
                ></RankingList>

            </div>
        </div>
    )
}
export default RankingStores