import { create } from "zustand";

type EnableDisableAllModalTypes = {
  openEDAModal: boolean;
  actualAction: boolean;
  setOpenModal: (state: boolean) => void;
  setActualAction: (state: boolean) => void;
  toggleEDAModal: () => void;
};

const UseEnableDisableAllModal = create<EnableDisableAllModalTypes>(
  (set, get) => ({
    openEDAModal: false,
    actualAction: false,
    setOpenModal: (openEDAModal) => {
      set(() => ({
        openEDAModal,
      }));
    },
    toggleEDAModal: () => {
      set(() => ({
        openEDAModal: !get().openEDAModal,
      }));
    },
    setActualAction: (actualAction) => {
      set(() => ({
        actualAction,
      }));
    },
  })
);

export default UseEnableDisableAllModal;
