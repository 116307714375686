import { IAverageTopList, IAverageTopProductsList } from "components/graphs/averageTopList/IAverageTopList";
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import IDashboardService from "../interfaces/IDashboardService";

const GetTopProductsUseCase = async (
  dashboardService: IDashboardService,
  filter: IFilterRequest
): Promise<IAverageTopProductsList> => { 
  return await dashboardService.getTopProducts(filter);
};

export default GetTopProductsUseCase;
