import styles from "./SidesheetProfile.module.scss";
import Sidesheet from "components/sidesheet/Sidesheet";
import { IDeviceProfileItem } from "../profileList/interfaces/IProfileItem";
import { Checkbox } from "@material-ui/core";
import { useCallback } from "react";
import { IPaginationProfileDevice } from "../filterProfile/IFilterDeviceProfile";
import { Pagination } from '@mui/material';

interface ISidesheetProfile {
  openSidesheet: string | null;
  setOpenSidesheet: React.Dispatch<React.SetStateAction<string | null>>;
  profileList: IDeviceProfileItem[] | undefined;
  setProfileList: React.Dispatch<React.SetStateAction< IDeviceProfileItem[] | undefined>>
  postPerfis: () => void
  totalCount: number
  onChangePaginationHandle: (pagination: IPaginationProfileDevice) => void;
  pagination?: IPaginationProfileDevice;
}

const SidesheetProfile = ({
  setOpenSidesheet,
  openSidesheet,
  profileList,
  setProfileList,
  postPerfis,
  totalCount,
  onChangePaginationHandle,
  pagination
}: ISidesheetProfile) => {

  const handleChecked = (index : number) => {
    if(profileList){
    const updatedActive = [...profileList];
    updatedActive[index].checked = !updatedActive[index].checked;
    setProfileList(updatedActive);
    }
  }

  const onChangePageHandle = useCallback(
    (page: number, pageSize?: number) => {
        onChangePaginationHandle({ ...pagination, offset: page - 1, limit: pageSize });
    },
    [onChangePaginationHandle, pagination]
);

  return (
    <Sidesheet
      open={openSidesheet}
      handleContinueButton={() => {
        setOpenSidesheet(null)
        postPerfis()
      }}
      onClose={() => {
        setOpenSidesheet(null);
      }}
      title={
        <h1>
          Perfil<b> de venda</b>
        </h1>
      }
      content={
        <div id={styles.contentSidesheet}>
          {profileList?.map((item, index) => {
            const operationList = item?.operationsMethods.filter((opList) => {
              return opList.enabled;
            });

            const paymentList = item?.paymentMethods.filter((payList) => {
              return payList.enabled;
            });

            return (
              <div className={styles.item}>
                <Checkbox checked={item.checked} onChange={() => handleChecked(index)}/>
                <span className={styles.title}>{item.name}</span>
                <div className={styles.options}>
                  {operationList.length > 0 && (
                    <div className={styles.optionsItem}>
                      <span className={styles.optionsTitle}>Operação</span>
                      {operationList.map((opList) => {
                        return <span>{opList.name}</span>;
                      })}
                    </div>
                  )}
                  {item.catalogs.length > 0 && (
                    <div className={styles.optionsItem}>
                      <span className={styles.optionsTitle}>Cardápio</span>
                      {item.catalogs.map((catList) => {
                        return <span>{catList.name}</span>;
                      })}
                    </div>
                  )}
                  {paymentList.length > 0 && (
                    <div className={styles.optionsItem}>
                      <span className={styles.optionsTitle}>Formas de pagamento</span>
                      <div>
                      {paymentList.map((payList) => {
                        return <span>{payList.name}, </span>;
                      })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {totalCount > 50 && <div className={styles.containerPagination}>
                <Pagination
                    variant="outlined"
                    className={styles.paginationNumber}
                    shape="rounded"
                    count={Math.ceil(totalCount / 50)}
                    sx={{
                        '.MuiPaginationItem-root': {
                            borderColor: '#BDBDBD',
                            color: '#001537',
                        },
                        '.Mui-selected': {
                            fontWeight: 'bold',
                            borderColor: '#001537',
                        },
                    }}
                    onChange={(_, page) => onChangePageHandle?.(page, 50)}
                    page={((pagination?.offset ?? 0) / 50) + 1}
                />
            </div>}
        </div>
      }
      cancelButton
      continueButton={"Vincular perfis"}
      currentStep={1}
      totalSteps={1}
      notTotalHeight
    />
  );
};

export default SidesheetProfile;
