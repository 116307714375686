import { faker } from "@faker-js/faker";
import { addHours } from "date-fns";
import { IGetAverageTicketConsumeResponse } from "modules/customersDashboard/domain/dto/averageTicketConsume/GetAverageTicketConsumeResponse";
import { IGetAverageTicketIngressConsumeResponse } from "modules/customersDashboard/domain/dto/averageTicketIngressConsume/GetAverageTicketIngressConsumeResponse";
import { IGetAverageTicketPerHourResponse } from "modules/customersDashboard/domain/dto/averageTicketPerHour/AverageTicketPerHour";
import { IGetCheckinPerHourDataResponse } from "modules/customersDashboard/domain/dto/checkinPerHour/CheckinPerHourDataResponse";
import { IGetCheckiTableResponse } from "modules/customersDashboard/domain/dto/checkinTable/IGetCheckiTableResponse";
import { IGetUtlizationCouponResponse } from "modules/customersDashboard/domain/dto/couponUtlization/IGetUtilizationCouponResponse";
import { IGetCustomerListResponse } from "modules/customersDashboard/domain/dto/customerList/IGetCustomerListResponse";
import { ICustomerDashboardFilter } from "modules/customersDashboard/domain/dto/FilterRequest.type";
import { IGetGuestListResponse, IGuestItemResponse } from "modules/customersDashboard/domain/dto/guestList/IGuestListResponse";
import { IGetPermanencyTableResponse } from "modules/customersDashboard/domain/dto/permanecyTable/GetPermanencyTableResponse";
import { IGetRankingLastOrderResponse } from "modules/customersDashboard/domain/dto/rankingLastOrder/GetRankingLastOrderResponse";
import { IGetCanceledProductRankingResponse } from "modules/customersDashboard/domain/dto/rankingProducts/IGetCanceledProductRankingResponse";
import { IGetProductRankingResponse } from "modules/customersDashboard/domain/dto/rankingProducts/IGetProductRankingResponse";
import { IGetVouchersResponse } from "modules/customersDashboard/domain/dto/vouchers/IGetVouchersResponse";


// export const getEventListMock = async (request: IGetEventListRequest): Promise<IGetEventListResponse> => {

//   await delay(1000)

//   const array = [...Array(200)]
//   const records = array.map((item, index) => ({
//     eventId: faker.datatype.uuid(),
//     name: "Name" + index,
//     startdate: faker.date.past().toISOString(),
//     enddame: faker.date.past().toISOString(),
//   })).fmlfer(item => request.keyword ? item.name.toLowerCase().includes(request.keyword.toLowerCase()) : true)
//   //   feuurn request.input ? (item.name.toLowerCase().trim().includes(request.input.toLowerCase().trim()) || item.name.trim() === request.input.trim()) : true
//   // })uslice(((request?.page ?? 1) - 1) * (request?.pageSize ?? 10), (request?.page ?? 1) * (request?.pageSize ?? 10) + (request?.pageSize ?? 10))
//   const response = {
//     records: records.slice(((request?.page ?? 1) - 1) * (request?.pageSize ?? 10), (request?.page ?? 1) * (request?.pageSize ?? 10) + (request?.pageSize ?? 10)),
//     // const records = MockGrande.records.filter(item => {,
//     totalRecords: records.length
//   }

//   return response
// }

export const getCheckinTableMock = async (request: ICustomerDashboardFilter): Promise<IGetCheckiTableResponse> => {
  await delay(1200);
  return {
    customers: {
      total: 380,
      male: 200,
      female: 150,
      unknown: 30,
    },
    customersAge: {
      average: 27,
      male: 30,
      female: 25,
      unknown: 28
    },
    customersChekinPerHour: [{
        date: "2022-11-06T20:00:00",
        male: 4,
        female: 14,
        unknown: 0
    }, {
        date: "2022-11-06T21:00:00",
        male: 33,
        female: 43,
        unknown: 0
    }, {
        date: "2022-11-06T22:00:00",
        male: 58,
        female: 62,
        unknown: 0
    }, {
        date: "2022-11-06T23:00:00",
        male: 77,
        female: 82,
        unknown: 0
    }, {
        date: "2022-11-07T00:00:00",
        male: 122,
        female: 79,
        unknown: 1
    }, {
        date: "2022-11-07T01:00:00",
        male: 35,
        female: 30,
        unknown: 0
    }, {
        date: "2022-11-07T02:00:00",
        male: 33,
        female: 10,
        unknown: 0
    }, {
        date: "2022-11-07T03:00:00",
        male: 6,
        female: 2,
        unknown: 0
    }, {
        date: "2022-11-07T04:00:00",
        male: 0,
        female: 0,
        unknown: 0
    }],
    averageCustomerTime: {
      male: "4:36:3",
      female: "",
      unknown: "",
      average: "4:36:3"
  }
  }
}

export const getVouchersMock = async (request: ICustomerDashboardFilter): Promise<IGetVouchersResponse> => {
  await delay(1200);
  return {
    totalRecords: 4,
    records: [
      {
        id: '5',
        name: 'SOUMEEP - 5%',
        total: 18
      },
      {
        id: '10',
        name: 'SOUMEEP - 10%',
        total: 53
      },
      {
        id: '20',
        name: 'SOUMEEP - 20%',
        total: 27
      },
      {
        id: '40',
        name: 'SOUMEEP - 40%',
        total: 5
      },
    ]
  }
}



export const getCheckinPerHourMock = async (request: ICustomerDashboardFilter): Promise<IGetCheckinPerHourDataResponse> => {
  await delay(1000)

  const array = [...Array(12)]
  const records = array.map((item, index) => ({

    date: addHours(new Date(), index).toString(),
    male: faker.datatype.number({ min: 100, max: 300 }),
    female: faker.datatype.number({ min: 100, max: 300 }),
    unknown: faker.datatype.number({ min: 100, max: 300 }),


  }))
  const response = {
    records: records,
    totalRecords: records.length
  }

  return response
}


export const getPermancyTableMock = async (request: ICustomerDashboardFilter): Promise<IGetPermanencyTableResponse> => {
  await delay(2000)
  return {
    average: "4h12",
    male: "3h05",
    female: "5h05",
    unknown: "2h05",
  }
}


export const getAverageTicketIngressConsumeMock = async (request: ICustomerDashboardFilter): Promise<IGetAverageTicketIngressConsumeResponse> => {
  await delay(2000)
  return {
    consume: {
      value: faker.datatype.number({ min: 100, max: 655 }),
      male: faker.datatype.number({ min: 100, max: 655 }),
      female: faker.datatype.number({ min: 100, max: 655 }),
      unknown: faker.datatype.number({ min: 100, max: 655 }),
    },
    deviceOrigin: {
      total: 300,
      devices: [
        {
          label: "Aplicativo",
          total: 150
        },
        {
          label: "POS",
          total: 90
        },
        {
          label: "MeepTickets",
          total: 60
        }
      ]
    }
  }
}

export const getAverageTicketConsumeMock = async (request: ICustomerDashboardFilter): Promise<IGetAverageTicketConsumeResponse> => {
  await delay(2000)
  return {} as IGetAverageTicketConsumeResponse
}


export const getGuestListMocks = async (request: ICustomerDashboardFilter): Promise<IGetGuestListResponse> => {

  await delay(700);

  const records: IGuestItemResponse[] = ([...Array(request.pageSize)]).map((item) => ({
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    isActive: true,
    quantity: {
      male: 99,
      female: 99,
      unknown: 99
    },
    sold: {
      male: 99,
      female: 99,
      unknown: 99
    },
    checkin: {
      male: 99,
      female: 99,
      unknown: 99
    },
    averageTicket: {
      male: 99,
      female: 99,
      unknown: 99
    },
  }))

  return ({ records: records, totalRecords: 100 })
}


export const getCustomersListMock = async (request: ICustomerDashboardFilter): Promise<IGetCustomerListResponse> => {
  await delay(750)
  const response: IGetCustomerListResponse = {
    totalCheckin: faker.datatype.number(1000),
    totalBirthday: faker.datatype.number(10),
    totalInPlace: faker.datatype.number(1000),
    records: ([...Array(request.pageSize)].map((_, index) => ({
      id: faker.datatype.uuid(),
      imgUrl: faker.image.imageUrl(),
      name: faker.name.fullName(),
      bornDate: index === 1 ? new Date().toISOString() : faker.date.birthdate().toISOString(),
      birthday: faker.datatype.boolean(),
      age:faker.datatype.number(80),
      visits: faker.datatype.number(100),
      totalConsumed: faker.datatype.number(4000),
      inPlace: faker.datatype.boolean(),
    }))),
    totalRecords: 36,
  }
  return response
}

export const getAverageTicketPerHourMock = async (): Promise<IGetAverageTicketPerHourResponse> => {

  await delay(750)
  const response = {
    records: ([...Array(12)].map((_, index) => ({
      time: `${index}h00`,
      average: faker.datatype.number(500),
      male: faker.datatype.number(500),
      female: faker.datatype.number(500),
      unknown: faker.datatype.number(500),
    })))
  }

  return response
}

export const getUtilizationCouponMocks = async (request: ICustomerDashboardFilter): Promise<IGetUtlizationCouponResponse> => {
  return {
    records: [...Array(3)].map((_, index) => ({
      id: faker.datatype.uuid(),
      description: faker.animal.bird(),
      quantity: faker.datatype.number(),
    })),
    totalRecords: 3,
  }

}

export const getProductsRakingMocks = async (request: ICustomerDashboardFilter): Promise<IGetProductRankingResponse> => {
  await delay(3000)
  return {
    records: [...Array(request.pageSize)].map((_, index) => ({
      productId: faker.datatype.uuid(),
      name: faker.commerce.product() + (index),
      imgUrl: faker.image.imageUrl(),
      categoryName: faker.commerce.productMaterial(),
      quantity: faker.datatype.number(),
      total: faker.datatype.float()
    })),
    totalRecords: 50,
  }
}

export const getCanceledProductsRakingMocks = async (request: ICustomerDashboardFilter): Promise<IGetCanceledProductRankingResponse> => {
  await delay(4000)
  return {
    records: [...Array(request.pageSize)].map((_, index) => ({
      operatorId: faker.datatype.uuid(),
      name: faker.commerce.product() + (index),
      canceledQauntity: faker.datatype.number(),
      canceledTotalNet: faker.datatype.number(),
      lastCanceledValueNet: faker.datatype.float(),
      lastCanceledAt: faker.date.recent().toISOString()
    })),
    totalRecords: 39,
  }
}
export const getLastOrderRakingMocks = async (request: ICustomerDashboardFilter): Promise<IGetRankingLastOrderResponse> => {
  await delay(2000)
  return {
    records: [...Array(request.pageSize)].map((_, index) => ({
      productId: faker.datatype.uuid(),
      productNome: faker.commerce.product() + (index),
      createdDate: faker.date.past().toISOString(),
      totalNet: Number(faker.commerce.price()),
      deviceName: faker.animal.dog(),
      operatorName: faker.name.fullName(),
      customerName: faker.name.firstName(),
      isCancelled: false,
    })),
    totalRecords: 39,
  }
}




const delay = (time?: number) => new Promise((resolve) => {
  setTimeout(() => resolve(""), time ?? 1000)
})
