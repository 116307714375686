import GetAverageTicketConsumeUseCase from 'modules/customersDashboard/application/useCases/GetAverageTicketConsumeUseCase';
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type'
import {  useCallback, useState } from 'react'
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService';
import { IAverageTicketConsumeData } from '../components/averageTicketConsume/AverageTicketConsume';

const UseAverageTicketConsume = (filter: ICustomerDashboardFilter) => {
    const [data, setData] = useState<IAverageTicketConsumeData>();
    const [isLoading, setIsLoading] = useState(false);

    const getData = useCallback(async () => {
        try {
            const serviceCustomersDashboard = CustomersDashboardService();
            setIsLoading(true)
            const response = await GetAverageTicketConsumeUseCase(serviceCustomersDashboard, filter);
            setData(response)
        } finally {
            setIsLoading(false)
        }

    }, [filter])
    return ({
        data,
        isLoading,
        getData
    })
}

export default UseAverageTicketConsume