import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import styles from './PaymentChart.module.scss'
import { variedColorsNewDash } from '../utils';
import { Lens } from '@mui/icons-material';
import { format, isValid, parseISO } from 'date-fns';
import FallbackMainDash from '../fallbackMainDash/FallbackMainDash';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
}

interface PaymentChartProps {
    data?: {
        records: {
            paymentForm: string
            total: number
        }[],
        period: string
    };
    isLoading: boolean
    filter?: string
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip} >
                <p><b>{payload[0].payload.paymentForm}</b></p>
                <p>{payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
        );
    }

    return null;
};

const PaymentChart = ({ data, isLoading, filter }: PaymentChartProps) => {
    return (
        <div id={styles.PaymentChart}>
            <div className={styles.top}>
                <div>
                    <p className={styles.title}>Pagamentos mais usados do {isValid(new Date(filter ?? "")) ? "dia" : "caixa"}</p>
                    <p className={styles.description}>Acompanhe as formas de pagamento mais usadas</p>
                </div>
                <p className={styles.description}>{data && format(parseISO(data.period + "Z"), 'dd/MM/yyyy HH:mm')}</p>
            </div>
            {isLoading ? (
                <div className={styles.containerLoading}>
                    <div className={styles.animatedLoading}></div>
                </div>
            ) : (
                <div className={styles.scroll}>
                    {data && data.records.length > 0 ? (
                        <>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart width={250} height={250}>
                                    <Pie
                                        data={data?.records}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={100}
                                        dataKey="total"
                                    >
                                        {variedColorsNewDash.map((color, index) => (
                                            <Cell key={`cell-${index}`} fill={color} />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        content={({ active, payload }) =>
                                            <CustomTooltip active={active} payload={payload} />}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <div className={styles.listProducts}>
                                {data?.records.map((item, index) => {
                                    const color = variedColorsNewDash[index % variedColorsNewDash.length]
                                    return (
                                        <div className={styles.item}>
                                            <div className={styles.payment}>
                                                <Lens style={{ color: color }} />
                                                <p>{item.paymentForm}</p>
                                            </div>
                                            <p>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <FallbackMainDash />
                    )}


                </div>
            )}
        </div>
    )
}

export default PaymentChart