import { IPromotersResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IAccessService } from '../../interfaces/IAccessService';

const GetPromotersFromLocalUseCase = async (
  accessService: IAccessService,
  localId: string,
): Promise<IPromotersResponse[]> => {
  return await accessService.getPromotersFromLocal(localId);
};

export default GetPromotersFromLocalUseCase;