import SegmentService from "modules/meepErp/services/api/Segment/SegmentService";
import { IGetSegmentListRequest } from "../../dtos/segment/IGetSegmentListRequest";
import { IGetSegmentItemResponse, IGetSegmentListResponse } from "../../dtos/segment/IGetLSegmentListResponse";
import { ISaveSegmentRequest, IUpdateSegmentRequest } from "../../dtos/segment/ISaveSegmentRequest";

export const GetSegmentListUseCase = async (request: IGetSegmentListRequest): Promise<IGetSegmentListResponse> => {
  const response = await SegmentService().getSegmentList(request);
  return response;
}

export const GetSegmentListToSelectUseCase = async (): Promise<IGetSegmentItemResponse[]> => {
  const response = await SegmentService().getSegmentList({
    page: 1,
    pageSize: 1000
  });
  return response.items;
}

export const GetSegmentItemUseCase = async (id: string) => {
  const response = await SegmentService().getSegmentItem(id);
  return response;
}

export const DeleteSegmentUseCase = async (id: string) => {
  await SegmentService().deleteSegment(id);
}

export const CreateSegmentUseCase = async (data: ISaveSegmentRequest) => {
  const response = await SegmentService().createSegment(data);
  return response;
}

export const UpdateSegmentUseCase = async (data: IUpdateSegmentRequest) => {
  const response = await SegmentService().updateSegment(data);
  return response;
}
