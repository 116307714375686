import DeleteCollaboratorUseCase from "modules/colaboradores/application/deleteCollaboratorUseCase";
import GetCollaboratorsUseCase from "modules/colaboradores/application/getCollaboratorsUseCase";
import GetNewCollaboratorUseCase from "modules/colaboradores/application/getNewCollaboratorUseCase";
import PostNewCollaboratorUseCase from "modules/colaboradores/application/postNewCollaboratorUseCase";
import PostRegisterPortalUseCase from "modules/colaboradores/application/postRegisterPortal";
import PutEditCollaboratorUseCase from "modules/colaboradores/application/putEditCollaboratorUseCase";
import { IDeleteCollaboratorParams } from "modules/colaboradores/domain/dto/IDeleteCollaboratorParams";
import { IEditCollaboratorRequest } from "modules/colaboradores/domain/dto/IEditCollaboratorRequest";
import { INewCollaboratorRequest } from "modules/colaboradores/domain/dto/INewCollaboratorRequest";
import { IPostRegisterPortal } from "modules/colaboradores/domain/dto/IPostRegisterPortalParams";
import { IGetColaboradoresParams } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresParams";
import { IEmployeesList } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresResponse";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import ColaboradoresApi from "services/api/colaboradores/ColaboradoresApi";
import IntegrationApi from "services/api/config/integration/IntegrationApi";

const colaboradoresApi = ColaboradoresApi();
const integrationService = IntegrationApi();

const UseColaboradoresPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [colaboradoresList, setColaboradoresList] = useState<IEmployeesList[]>();

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [localDocument, setLocalDocument] = useState("");

    const { currentLocal } = useLocal();

    const getCollaboratorsList = useCallback(
        async (page: number, params?: IGetColaboradoresParams, pageSize?: number) => {
            if (currentLocal) {
                try {
                    setIsLoading(true);
                    const response = await GetCollaboratorsUseCase(colaboradoresApi, page, params, pageSize);

                    setColaboradoresList(response.Employees);
                    setTotalPages(response.PageCount);
                    setCurrentPage(page);

                    return response
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [currentLocal]
    );

    const postNewCollaborator = useCallback(
        async (request: INewCollaboratorRequest) => {
            try {
                setIsLoading(true);
                await PostNewCollaboratorUseCase(colaboradoresApi, request);
                return "ok";
            } catch (error: any) {
                if (error.response.data.includes("Usuário já está incluído no Cargo")) {
                    // toast("Usuário já está incluído no cargo.", "error");
                    return "included";
                }
                return "error";
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const getNewCollaborator = useCallback(
        async (email: string) => {
            const response = await GetNewCollaboratorUseCase(colaboradoresApi, email);
            return response
        },
        []
    );

    const postRegisterPortal = useCallback(
        async (request: IPostRegisterPortal) => {
            await PostRegisterPortalUseCase(colaboradoresApi, request);
        },
        []
    );

    const putEditCollaborator = useCallback(
        async (request: IEditCollaboratorRequest) => {
            try {
                setIsLoading(true);
                await PutEditCollaboratorUseCase(colaboradoresApi, request);
                return "ok";
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const deleteCollaborator = useCallback(
        async (params: IDeleteCollaboratorParams) => {
            try {
                setIsLoading(true);
                await DeleteCollaboratorUseCase(colaboradoresApi, params);
                return "ok";
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const nextCollaboratorPage = (params: IGetColaboradoresParams, page: number, pageSize?: number) => {
        if (currentPage < totalPages) {
            getCollaboratorsList(page, params, pageSize);
            setCurrentPage(currentPage + 1);
        }
    }

    const getLocalMainData = useCallback(async () => {
        if (currentLocal) {
          const response = await GetLocalMainDataUseCase(integrationService, currentLocal.id);
          setLocalDocument(response.document);
        }
    }, [currentLocal]);

    return {
        isLoading,
        colaboradoresList,
        getCollaboratorsList,
        postNewCollaborator,
        currentPage,
        totalPages,
        putEditCollaborator,
        deleteCollaborator,
        nextCollaboratorPage,
        postRegisterPortal,
        getNewCollaborator,
        localDocument,
        getLocalMainData
    }
}

export default UseColaboradoresPage;