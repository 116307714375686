import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditDeviceOperatorUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceOperator/EditDeviceOperatorUseCase';
import GetDeviceOperatorListBylocalIdUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/GetDeviceOperatorListBylocalIdUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IOperatorItem, IOperatorItemValue } from '../../components/deviceProfile/profileForms/operatorsForm/interfaces/IOperatorsValues'

const UseDeviceOperator = (path: string = "/private/pdv/perfil") => {

    const [operators, setOperators] = useState<IOperatorItem[]>();
    // const [deviceOpertorsDefault, setDeviceOpertorsDefault] = useState<IOperatorItemValue[]>();
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();

    const getOperatorsList = useCallback(async (localId: string) => {
        try {
            showLoading()
            const deviceProfileService = DeviceProfileApi();
            const response = await GetDeviceOperatorListBylocalIdUseCase(deviceProfileService, localId)
            setOperators(response);
        } finally {
            hideLoading()
        }
    }, [hideLoading, showLoading])


    // const getDeviceOperatorDefault = useCallback(async (profileId: string) => {
    //     try {
    //         showLoading()
    //         const deviceProfileService = DeviceProfileApi();
    //         const response = await GetDeviceOpertorDefaultByProfileIdUseCase(deviceProfileService, profileId)
    //         setDeviceOpertorsDefault(response);
    //     } finally {
    //         hideLoading()
    //     }
    // }, [hideLoading, showLoading])

    const editDeviceOperator = useCallback(async (profileId: string, operatorValues: IOperatorItemValue[]) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                const response = await EditDeviceOperatorUseCase(deviceProfileService, currentLocal.id, profileId, operatorValues);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);

            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        operators,
        getOperatorsList,
        editDeviceOperator,
    })
}

export default UseDeviceOperator