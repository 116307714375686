import { FC } from "react";
import styles from "./AntecipatePage.module.scss";
import { ArrowBackIos } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import UseDimension from "components/dimension/UseDimension";
import Button from "components/ui/Button/Button";
import { UseAntecipatePage } from "./UseAntecipatePage";
import { formatarValor } from "../utils";
import { format } from "date-fns";

const AntecipatePage: FC = () => {
  const { push } = useHistory();
  const { dimensions } = UseDimension();
  const { listNextAntecipate } = UseAntecipatePage();
  const { account: accountId } = useParams<{ account: string }>();

  return (
    <div id={styles.Antecipar}>
      <div
        className={styles.back}
        onClick={() => push("/private/contaDigital/new")}
      >
        <ArrowBackIos />
        Voltar
      </div>
      <h1>
        Próximas <b>liberações</b>
      </h1>
      <p>
        É o dinheiro que recebeu, mas que ainda não pode ser usado. Você sempre
        poderá consultar quando estará disponível.
      </p>
      <Button
        fullWidth={dimensions.width > 900 ? false : true}
        className={styles.addButton}
        onClick={() => push(`/private/contaDigital/new/antecipate-received/${accountId}`)}
      >
        Antecipar recebíveis
      </Button>
      {listNextAntecipate && listNextAntecipate.valores.length > 0 && 
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.container}>Data</div>
          <div className={styles.container}>Valor</div>
        </div>
        {listNextAntecipate.valores.map(item => {
          return(
            <div className={styles.tableItem}>
            <span>{format(new Date(item.data), 'dd/MM/yyyy')}</span>
            <span>{formatarValor(item.valorTotalLiquido)}</span>
          </div>
          )
        })}
       
      </div>
}
    </div>
  );
};
export default AntecipatePage;
