import { Api } from "services/api/Api";
import { delayMock, getPaginatedInvoiceMOCK } from "./InvoiceMOCK";
import { IInvoiceService } from "modules/meepErp/application/interfaces/_invoice/IInvoiceService";
import { IGetInvoiceListRequest } from "modules/meepErp/application/dtos/invoice/IGetInvoiceListRequest";
import { IGetInvoiceListResponse } from "modules/meepErp/application/dtos/invoice/IGetInvoiceListResponse";
import { IInvoiceListRequest, IInvoiceListResponse } from "modules/meepErp/application/dtos/invoice/IInvoiceDtos";
import { erpUrl } from "Enviroment";
import { IGetQuantityStockResponse } from "modules/meepErp/application/dtos/invoice/IGetQuantityStockResponse";

const BASE_URL = erpUrl + "/api";

const InvoiceService = (): IInvoiceService => {
  const api = Api();

  const getInvoiceList = async (request: IInvoiceListRequest): Promise<IInvoiceListResponse> => {
    const response = await api.get<IInvoiceListResponse>(`${BASE_URL}/invoices`, { params: request });
    return response.data;
  };

  const deleteInvoice = async (id: string): Promise<void> => {
    await api.delete(`${BASE_URL}/invoices/${id}`);
  };

  const getQuantityStock = async (storageLocationId: string, supplyId : string): Promise<IGetQuantityStockResponse> => {
    const response = await api.get<IGetQuantityStockResponse>(`${BASE_URL}/inventory/supplyStocks/storage-location/${storageLocationId}/supply/${supplyId}`);
    return response.data;
  };

  return {
    getInvoiceList,
    deleteInvoice,
    getQuantityStock
  };
}
export default InvoiceService
