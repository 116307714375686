import { Button } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './FooterButtons.module.scss'
import { IDeviceItemValue } from '../deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem'
export interface IFooterButtonsProps {
    //propertys
    labelNext?: string,
    labelBack?: string,
    disabledNext?: boolean,
    disabledBack?: boolean,
    onClickBack?: any,
    onClickNext?: () => void,
    nextType?: "submit"
}
const FooterButtons: FC<IFooterButtonsProps> = ({
    onClickBack,
    onClickNext,
    labelNext = "Próximo",
    labelBack = "Voltar",
    disabledNext,
    disabledBack,
    nextType
}) => {

    return (
        <div id={styles.FooterButtons} >
            <div className={styles.row}>
                {
                    onClickBack &&
                    <Button disabled={disabledBack} className={styles.button} variant='outlined' onClick={onClickBack}>{labelBack}</Button>
                }
                {
                    (onClickNext || nextType) &&
                    <Button disabled={disabledNext} className={`${styles.button} ${styles.submitButton}`} variant='contained' onClick={onClickNext} type={nextType}>{labelNext}</Button>
                }
            </div>
        </div>
    )
}
export default FooterButtons