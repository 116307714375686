import { FC, useEffect, useState } from "react";
import styles from "./NovidadesPage.module.scss";
import Card from "../components/card/Card";
import { useHistory } from "react-router-dom";
import {
  ArrowBackIosNewRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import UseAdminNovidadesPage from "modules/adminNovidades/presentation/pages/UseAdminNovidadesPages";
import moment from "moment";
import { MenuItem, Pagination, TextField } from "@mui/material";
import { Skeleton } from "components/skeleton/Skeleton";
import UseNovidadesPage from "./UseNovidadesPage";

const NovidadesPage: FC = () => {
  const [countPage, setCountPage] = useState(1);
  const { push } = useHistory();
  const {
    listNovidades,
    setPage,
    setQtdNovidadesPage,
    totalNovidades,
    qtdNovidadesPage,
    loading,
    setCategory,
  } = UseAdminNovidadesPage();

  const { listCategory } = UseNovidadesPage();

  useEffect(() => {
    setCountPage(Math.ceil(totalNovidades / qtdNovidadesPage));
  }, [totalNovidades, qtdNovidadesPage]);

  return (
    <div id={styles.NovidadesPage}>
      <div className={styles.back} onClick={() => push("/")}>
        <ArrowBackIosNewRounded style={{ width: "10px" }} />
        <span>Voltar ao portal</span>
      </div>
      <div className={styles.containerHeader}>
        <div className={styles.containerTitle}>
          <img src="/assets/icon/open-book-emoji.svg" alt="" />
          <h1>Novidades</h1>
        </div>
        <button onClick={() => push("/private/tutoriais")}>
          <img src="/assets/icon/hot-icon.svg" alt="" />
          <span>Veja todos os tutoriais</span>
          <KeyboardArrowRightRounded />
        </button>
      </div>

      <div className={styles.contentTag}>
        {listCategory.map((item) => {
          return (
            <div onClick={() => setCategory(item)} className={styles.tag}>
              {item}
            </div>
          );
        })}
      </div>

      <div className={styles.contentCard}>
        {listNovidades && !loading ? (
          listNovidades.map((item) => {
            return (
              <Card
                title={item.title}
                text={item.description}
                img={item.img}
                date={moment(item.date).format("DD/MM/YYYY - HH:mm")}
                link={item.link}
              />
            );
          })
        ) : (
          <div className={styles.contentSkeleton}>
            <Skeleton items={1} cardStyle={styles.skeleton} />
          </div>
        )}
      </div>
      <div className={styles.conteinerPagination}>
        <div className={styles.boxMultiplePage}>
          <span>Exibir</span>
          <TextField
            variant="outlined"
            size="small"
            select
            onChange={(ev) => {
              setQtdNovidadesPage(Number(ev.target.value));
              setPage(0);
            }}
          >
            <MenuItem className={styles.textPage} value={10}>
              10
            </MenuItem>
            <MenuItem className={styles.textPage} value={20}>
              20
            </MenuItem>
            <MenuItem className={styles.textPage} value={50}>
              50
            </MenuItem>
          </TextField>
        </div>
        <Pagination
          variant="outlined"
          className={styles.paginationNumber}
          shape="rounded"
          count={countPage}
          sx={{
            ".MuiPaginationItem-root": {
              borderColor: "#BDBDBD",
              color: "#001537",
            },
            ".Mui-selected": {
              fontWeight: "bold",
              borderColor: "#001537",
            },
          }}
          onChange={(_, page) => setPage(page - 1)}
        />
      </div>
    </div>
  );
};
export default NovidadesPage;
