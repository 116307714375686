import React, { FC, FormEvent, useCallback, useEffect } from "react";
import styles from "../FidelityForm.module.scss";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { IColor, PickerColor } from "./IconColorPicker/ColorPicker";
import { IIcon, PickerIcon } from "./IconColorPicker/IconPicker";
import FidelityAppPreview from "./FidelityAppPreview/FidelityAppPreview";
import UseDimension from "components/dimension/UseDimension";

export interface IFidelityCustomizationFormValue {
  icon: string;
  color: string;
  isVisibleApp: boolean;
}

export interface IFidelityCustomizationFormProps {
  //propertys
  defaultValue?: IFidelityCustomizationFormValue;
  onSubmit?: (value: IFidelityCustomizationFormValue) => void;
  onClickBack?: () => void;
}

const FidelityCustomizationForm: FC<IFidelityCustomizationFormProps> = ({
  defaultValue,
  onSubmit,
  onClickBack,
}) => {
  const [color, setColor] = React.useState<IColor>();
  const [icon, setIcon] = React.useState<IIcon>();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState({ icon: '', color: '' });

  const { dimensions } = UseDimension();

  const validate = useCallback(() => {
    if (color?.colors.length === 0) {
      return false;
    }
    if (icon?.id === "") {
      return false;
    }
    return true;
  }, [color?.colors.length, icon?.id]);

  useEffect(() => {
    if (defaultValue) {
      defaultValue.isVisibleApp ? setIsVisible(true) : setIsVisible(false);
      defaultValue.color.includes("|")
        ? setColor({ colors: defaultValue.color.split("|") })
        : setColor({ colors: [defaultValue.color] });
      defaultValue.icon
        ? setIcon({ id: defaultValue.icon })
        : setIcon({ id: "" });
    }
  }, [defaultValue]);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      let hasError = false;

      if (!icon?.id) {
        setErrors(prev => ({ ...prev, icon: 'Selecione um ícone' }))
        hasError = true;
      }
      
      if (!color) {
        setErrors(prev => ({ ...prev, color: 'Selecione uma cor' }))
        hasError = true;
      }

      if (hasError) {
        return false;
      }

      if (icon && color) {
        const data: IFidelityCustomizationFormValue = {
          icon: icon.id,
          isVisibleApp: isVisible,
          color:
            color.colors.length === 1
              ? color.colors[0]
              : color.colors.length === 2
              ? color.colors[0] + "|" + color.colors[1]
              : "#000",
        };
        if (validate()) {
          onSubmit?.(data);
        }
      }
    },
    [color, icon, isVisible, onSubmit, validate]
  );

  return (
    <form onSubmit={submitHandle}>
      <div className={styles.formContainer}>
        <h4>
          Essa é a forma que seus clientes verão o nível de fidelidade no App Meep:
        </h4>
        <div className={styles.row} style={dimensions.width < 540 ? { flexDirection: "column", alignItems: "center" } : {}}>
          <div className={styles.formContainer}>
            <div className={styles.row}>
              <div className={styles.item}>
                <label className={styles.required}>Selecione uma cor</label>
                <PickerColor value={color} onChange={setColor} />
                {!!errors.color && <span className={styles.error}>{errors.color}</span>}
              </div>
              <div className={styles.item}>
                <label className={styles.required}>Selecione um Icone</label>
                <PickerIcon value={icon} onChange={setIcon} />
                {!!errors.icon && <span className={styles.error}>{errors.icon}</span>}
              </div>
            </div>
            <div className={`${styles.item} ${styles.switchContainer}`}>
              <FormControlLabel
                checked={isVisible}
                onChange={(_, value) => setIsVisible(value)}
                control={<Switch defaultChecked />}
                label="Deixar nível visível para o usuário no Aplicativo"
              />
            </div>
          </div>
          {isVisible ? (
            <FidelityAppPreview
              style={{
                background:
                  color?.colors.length === 1
                    ? color.colors[0]
                    : `linear-gradient(to bottom, ${color?.colors[0]}, ${color?.colors[1]})`,
              }}
              imageUrl={"/assets/icon/fidelity/white/" + icon?.id + ".svg"}
            />
          ) : (
            <div className={styles.previewWithoutBanner} />
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div>
          <Button
            className={styles.buttons}
            color="secondary"
            onClick={onClickBack}
            variant="outlined"
          >
            Voltar
          </Button>
        </div>
        <div>
          <Button
            className={styles.buttons}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Salvar
          </Button>
        </div>
      </div>
    </form>
  );
};
export default FidelityCustomizationForm;
