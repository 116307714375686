import { Icon } from '@material-ui/core';
import styles from './PurchasePlan.module.scss';
import { Checkbox, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UsePurchasePlan } from './UsePurchasePlan';
import { FeatureTypeEnum } from '../../interfaces/FeatureTypeEnum';
import { moneyMaskNumber } from 'services/utils/Money';
import { PermissionModal } from '../../components/permissionModal/PermissionMoral';

export const PurchasePlanPage = () => {
    const [agreeded, setAgreeded] = useState(false);
    const history = useHistory();
    const {
        additionalFeatures,
        pushFeature,
        emailFeature,
        setPushFeature,
        setEmailFeature,
        subscribePlan,
        getTotalValue,
        defaultEmailFeature,
        defaultPushFeature,
        plan,
        saving,
        loading,
        permissionModalOpened,
        setPermissionModalOpened
    } = UsePurchasePlan();

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.backContainer}>
                    <button onClick={() => history.goBack()}>
                        <Icon>chevron_left</Icon>
                    </button>
                    <span>Voltar</span>
                </div>

                <div className={styles.header}>
                    <span>Plano <b>Premium</b></span>
                </div>

                <div className={styles.descriptionContainer}>
                    <div>
                        <Icon>info_outlined</Icon>
                        <p>Como funciona o <b>período de um mês?</b></p>
                    </div>

                    <p>
                        Ao contratar o Plano Premium, você estará experimentando com exclusividade as novas funcionalidades do Portal Meep e participará ativamente na construção daquilo que é melhor para o seu negócio. 
                        Você terá acesso às funcionalidades de <b>forma gratuita pelo período de 1 mês</b> e após isso será cobrado o valor descrito abaixo:
                    </p>
                </div>

                <div className={styles.cardsContainer}>
                    <div className={styles.cardContainer}>
                        <span>Período de <b>cobrança</b></span>

                        <div className={styles.card}>
                            <img src="/assets/icon/plans/monthly.svg" alt="" />
                            <div>
                                <strong>Mensal</strong>
                                <span>{moneyMaskNumber(plan.price)}<span>/mês</span></span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardContainer}>
                        <span>Forma de <b>pagamento</b></span>

                        <div className={styles.card}>
                            <img src="/assets/icon/plans/payment.svg" alt="" />
                            <div>
                                <strong>Fatura Meep</strong>
                                <span>Aditivo contratual</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.addonsContainer}>
                    {
                        loading ?
                        <Skeleton variant="rectangular" width="100%" height={72} style={{ borderRadius: 8 }} /> :
                        additionalFeatures.filter(x => x.featureTypeValue === FeatureTypeEnum.PUSH).map(x => (
                            <button
                                onClick={() => setPushFeature(x)}
                                className={`
                                    ${defaultPushFeature?.id === x.id ? styles.current : ''} 
                                    ${pushFeature?.id === x.id ? styles.selected : ''}
                                `}
                            >
                                <div>
                                    <img src={`/assets/icon/plans/push-outlined-${pushFeature?.id === x.id ? 'white' : 'purple'}.svg`} alt="" />
                                    <span>Push</span>
                                </div>
                                <p>{x.purchaseDescription}</p>
                            </button>
                        ))
                    }
                </div>

                <div className={styles.addonsContainer}>
                    {
                        loading ?
                        <Skeleton variant="rectangular" width="100%" height={72} style={{ borderRadius: 8 }} /> :
                        additionalFeatures.filter(x => x.featureTypeValue === FeatureTypeEnum.EMAIL).map(x => (
                            <button
                                onClick={() => setEmailFeature(x)}
                                className={`${defaultEmailFeature?.id === x.id ? styles.current : ''} ${emailFeature?.id === x.id ? styles.selected : ''}`}
                            >
                                <div>
                                    <img src={`/assets/icon/plans/email-outlined-${emailFeature?.id === x.id ? 'white' : 'purple'}.svg`} alt="" />
                                    <span>Email</span>
                                </div>
                                <p>{x.purchaseDescription}</p>
                            </button>
                        ))
                    }
                </div>

                {/* <div className={styles.bill}>
                    <h3>Dados da cobrança</h3>

                    <div>
                        <div>
                            <span>João Carlos de Almeida</span>
                            <span><b>CPF/CNPJ: </b>999.999.999-99</span>
                            <span><b>Telefone: </b>(31) 99999-9999</span>
                        </div>

                        <div>
                            <span><b>CEP: </b>30458-99</span>
                            <span><b>Endereço: </b>Rua Carlos Dias 33, Alto Barroca - Belo Horizonte</span>
                        </div>
                    </div>
                </div> */}

                <div className={styles.checkboxContainer}>
                    <Checkbox checked={agreeded} color="primary" className={styles.checkbox} onChange={() => setAgreeded(prev => !prev)} />
                    <span>Concordo com os <a rel="noreferrer" href="https://drive.google.com/file/d/1ncv_Aef0ajM412c_URVeo3tes42OJB2C/view?usp=sharing" target='_blank'>termos de adesão</a> e com a cobrança mensal de <b>{moneyMaskNumber(getTotalValue())}</b> após o término do período de 1 mês</span>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.summary}>
                    <header>Resumo do <b>plano</b></header>

                    <div className={styles.summaryContent}>
                        <span className={styles.summaryTitle}>Incluso no plano:</span>

                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>CRM</span>
                        </div>
                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>Programa de fidelidade</span>
                        </div>
                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>Criação de campanhas</span>
                        </div>
                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>10.000 envios de e-mail</span>
                        </div>
                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>8 envios de push</span>
                        </div>
                        <div className={styles.item}>
                            <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                            <span className={styles.itemText}>Pesquisa de satisfação</span>
                        </div>

                        {
                            pushFeature && (!!pushFeature.index && pushFeature.index > 0) &&
                            <div className={styles.item}>
                                <Icon className={styles.itemIcon}>add_circle_outline</Icon>
                                <span className={styles.itemText}>{pushFeature.quantity! - 8} push/mês</span>
                            </div>
                        }

                        {
                            emailFeature && (!!emailFeature.index && emailFeature.index > 0) &&
                            <div className={styles.item}>
                                <Icon className={styles.itemIcon}>add_circle_outline</Icon>
                                <span className={styles.itemText}>{new Intl.NumberFormat("pt-BR").format(emailFeature.quantity! - 10000)} e-mails/mês</span>
                            </div>
                        }

                        <div className={styles.separator} />

                        <footer>
                            <span className={styles.value}><b>Teste por 1 mês</b></span>                            
                            <span className={styles.highlight}>depois disso, apenas R${moneyMaskNumber(getTotalValue()).replace('R$', '')}/mês</span>
                        </footer>

                        <button onClick={subscribePlan} disabled={!agreeded || saving}>{ saving ? 'Enviando...' : 'Contratar plano' }</button>
                    </div>
                </div>
            </div>
            <PermissionModal title="Upgrade de plano" open={permissionModalOpened} onClose={() => setPermissionModalOpened(false)} />
        </div>
    )
}