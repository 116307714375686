import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import axios from "axios";
import { ICheckoutForm } from "../../interfaces/checkout/ICheckoutForm";
import { isValid, parse } from 'date-fns';
import { IPlansItem } from "../../interfaces/plans/IPlans";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";

interface ErrorType {
    cardNumber?: string;
    cardHolderName?: string;
    document?: string;
    creditCardExpiration?: string;
    cvv?: string;
    zipCode?: string;
    number?: string;
    street?: string;
    neighborhood?: string;
}

interface IUsePaymentFormProps {
    selectedPlan?: IPlansItem;
    plans: IPlansItem[];
    form: ICheckoutForm;
    wPos: boolean;
    setForm: Dispatch<SetStateAction<ICheckoutForm>>;
    onSubmit: (useSameAddress: boolean) => void;
    toValidate?: boolean;
    useSameAddress: boolean;
}

export const UsePaymentForm = ({
    selectedPlan,
    plans,
    form,
    wPos,
    setForm,
    onSubmit,
    toValidate,
    useSameAddress,
}: IUsePaymentFormProps) => {
    const [errors, setErrors] = useState({} as ErrorType);
    const [creditCardExpiration, setCreditCardExpiration] = useState('');

    const { showLoading, hideLoading } = useUi();

    const setCreditCardExpirationHandle = useCallback((value: string) => {
        setErrors(prev => ({ ...prev, creditCardExpiration: undefined }));
        setForm(prev => ({ ...prev, creditCardPayment: { ...prev.creditCardPayment, expiryMonth: value.slice(0, 2), expiryYear: "20" + value.slice(3, 5) } }));
        setCreditCardExpiration(`${value.slice(0, 2)}/${value.slice(3, 5)}`);
    }, [setForm, setCreditCardExpiration]);

    const validate = async () => {
        let valid = true;
        let errorsData = {} as ErrorType;

        if (!form?.creditCardPayment.cardNumber) {
            errorsData.cardNumber = 'O número do cartão é obrigatório';
            valid = false;
        }
        if (form?.creditCardPayment.cardNumber && form.creditCardPayment.cardNumber.replace(/\D/g, '').length < 16) {
            errorsData.cardNumber = 'Número inválido';
            valid = false;
        }

        if (!form?.creditCardPayment.cardHolderName) {
            errorsData.cardHolderName = 'O nome no cartão é obrigatório';
            valid = false;
        }
        if (form?.creditCardPayment.cardHolderName && form.creditCardPayment.cardHolderName.length < 3) {
            errorsData.cardHolderName = 'Nome inválido';
            valid = false;
        }

        if (!form?.creditCardPayment.document) {
            errorsData.document = 'O CPF é obrigatório';
            valid = false;
        }
        if (form?.creditCardPayment.document && form.creditCardPayment.document.replace(/\D/g, '').length < 11) {
            errorsData.document = 'CPF inválido';
            valid = false;
        }

        if (!creditCardExpiration) {
            errorsData.creditCardExpiration = 'A validade é obrigatória';
            valid = false;
        }


        if (creditCardExpiration && !isValid(parse(creditCardExpiration, 'MM/yy', new Date()))) {
            errorsData.creditCardExpiration = 'Data inválida';
            valid = false;
        }

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100;

        const [expirationMonth, expirationYear] = creditCardExpiration.split('/').map(Number);

        if (expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < currentMonth)) {
            errorsData.creditCardExpiration = 'Cartão vencido';
            valid = false;
        }

        if (!form?.creditCardPayment.cvv) {
            errorsData.cvv = 'O CVV é obrigatório';
            valid = false;
        }
        if (form?.creditCardPayment.cvv && form.creditCardPayment.cvv.replace(/\D/g, '').length < 3) {
            errorsData.cvv = 'CVV inválido';
            valid = false;
        }

        if (!useSameAddress) {
            if (!form?.creditCardPayment.billingAddress.zipCode) {
                errorsData.zipCode = 'O CEP é obrigatório';
                valid = false;
            }
            if (form?.creditCardPayment.billingAddress.zipCode && form.creditCardPayment.billingAddress.zipCode.replace(/\D/g, '').length < 8) {
                errorsData.zipCode = 'O CEP é obrigatório';
                valid = false;
            }

            if (!form?.creditCardPayment.billingAddress.street) {
                errorsData.street = 'O endereço é obrigatório';
                valid = false;
            }

            if (!form?.creditCardPayment.billingAddress.number) {
                errorsData.number = 'O número é obrigatório';
                valid = false;
            }

            if (!form?.creditCardPayment.billingAddress.neighborhood) {
                errorsData.neighborhood = 'O bairro é obrigatório';
                valid = false;
            }
        }

        setErrors(errorsData);
        return valid;
    }

    const handleSubmit = async () => {
        if (toValidate) {
            const isValid = await validate();
            if (!isValid) return;
        }

        onSubmit(useSameAddress);
    }

    const mounParcelValue = useCallback((parcel: number, selectedPlan: IPlansItem, cieloPosPrice?: string) => {
        const ciloPriceToNumber = cieloPosPrice ? Number(cieloPosPrice.replace("R$", "").trim().replace(",", ".")) : 0;

        if (selectedPlan) {
            if (selectedPlan.subscriptionType === SubscriptionType.MONTHLY) {
                return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(selectedPlan!.price + ciloPriceToNumber);
            }
            let planValue = (selectedPlan!.price + (ciloPriceToNumber * 12)) / parcel;
            if (selectedPlan?.features.length && wPos) planValue += selectedPlan.features[0]!.price! / parcel;

            return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(planValue);
        }
    }, [wPos])

    const mountDetailedValue = useCallback((plan: IPlansItem) => {

        let result = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(plan!.price) + ' ' + plan?.name.toLowerCase();

        if (plan?.features.length && wPos) {
            result += ' + ' + new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(plan!.features[0].price! * (form.creditCardPayment.installment || 1)) + ' maquininha';
        }

        return result;
    }, [form.creditCardPayment.installment, wPos])

    const handleSearchByCEP = useCallback(async (value: string) => {
        const cep = value.replace(/\D/g, "");
        const validateCEP = /^[0-9]{8}$/;

        if (validateCEP.test(cep)) {
            try {
                setErrors(prev => ({ ...prev, zipCode: undefined }));
                showLoading();
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

                if (!response.data.erro) {
                    setErrors(prev => ({
                        ...prev,
                        zipCode: undefined,
                        number: undefined,
                        street: undefined,
                        neighborhood: undefined,
                    }));
                    setForm(prev => ({
                        ...prev,
                        creditCardPayment: {
                            ...prev.creditCardPayment,
                            billingAddress: {
                                ...prev.creditCardPayment.billingAddress,
                                street: response.data.logradouro,
                                neighborhood: response.data.bairro,
                                city: response.data.localidade,
                                state: response.data.uf,
                                country: "Brasil"
                            }
                        }
                    }));
                } else {
                    setErrors(prev => ({ ...prev, zipCode: 'CEP não encontrado' }));
                }
            } finally {
                hideLoading();
            }
        } else {
            setErrors(prev => ({ ...prev, zipCode: 'CEP inválido' }));
        }
    }, [hideLoading, setForm, showLoading]);

    return {
        errors,
        setErrors,
        creditCardExpiration,
        setCreditCardExpirationHandle,
        handleSubmit,
        mounParcelValue,
        mountDetailedValue,
        handleSearchByCEP
    }
}