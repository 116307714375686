import { useUi } from "contexts/userInterface/UserInterfaceContext";
import PostForgetUseCase from "modules/auth/application/useCases/PostForgotPassword";
import React, { FormEvent, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ForgotPasswordApi } from "services/api/auth/ForgotPasswordApi";

export const UseForgotPassword = () => {
  const { push } = useHistory();
  const { showLoading, hideLoading, toast } = useUi();
  const location = useLocation();

  const [forgotPassword, setForgotPassword] = useState<string>("");
  const [errors, setErrors] = useState<string>("");

  const goBackLogin = useCallback(() => {
    push("/login");
  }, [push]);

  const changeHandleForgotPassword = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForgotPassword(evt.target.value);
    },
    []
  );

  const onSubmit = useCallback(async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!forgotPassword) {
      return setErrors("Campo obrigatório");
    }
    try {
      showLoading();
      await PostForgetUseCase(ForgotPasswordApi, forgotPassword);
      toast("E-mail enviado com sucesso!", "success");
      push("/login");
    } catch (error: any) {
      if (error.response.data.message === "CPF ou e-mail inválido.") {
        toast("CPF ou e-mail não cadastrado.", "error");
        push("/login/cadastro");
      }
    } finally {
      hideLoading();
    }
  }, [forgotPassword, showLoading, toast, push, hideLoading]);

  return {
    goBackLogin,
    changeHandleForgotPassword,
    onSubmit,
    errors,
    setErrors,
  };
};
