import { IFinancialInvoiceApiService } from "modules/faturas/domain/interfaces/IFinancialInvoiceApiService";
import { api } from "../Api";
import { IGetFinancialInvoicesListResponse } from "modules/faturas/domain/dto/IGetFinancialInvoicesListResponse";

const FinancialInvoicesApi = (): IFinancialInvoiceApiService => {
  const getFinancialInvoiceList = async (localClienteId: string): Promise<IGetFinancialInvoicesListResponse[]> => {
    const data = {
      localClienteId: localClienteId
    }

    const response = await api.post("/Admin/BuscarFaturasDoCliente", data);
    return response.data;
  };


  return { getFinancialInvoiceList };
}

export default FinancialInvoicesApi;
