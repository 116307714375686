import { ISupplyFormValues } from "modules/meepErp/presentation/components/supply/supllyForm/ISupplyFormValues";
import SupplyService from "modules/meepErp/services/api/Supply/SupplyService";
import { ISaveSupplyRequest } from "../../dtos/supply/ISaveSupplyRequest";
import { ISupplyFilterValue } from "modules/meepErp/presentation/components/supply/supplyList/supplyFilter/ISupplyFilter";


export const SaveSupplyUseCase = async (localId: string, values: ISupplyFormValues) => {
    const service = SupplyService();
    const request: ISaveSupplyRequest = {
        // ...values,
        name: values.name,
        supplyGroupId: values.supplyGroup?.id!,
        description: values.description,
        measurementUnitId: values.measurementUnitId!,
        grossWeight: values.grossWeight,
        netWeight: values.netWeight,
        minimumStock: values.minimumStock,
        conversions: values.conversions,
        meepProductId: values.product,
        storageLocationId: values.storageLocationId,
        compositions: values.compositions,
        xmlEquivalences: []
    };

    return await service.addSupply(localId, request);
};

export const UpdateSupplyUseCase = async (localId: string, id: string, values: ISupplyFormValues) => {
    const service = SupplyService();
    const request: ISaveSupplyRequest = {
        // ...values,
        name: values.name,
        supplyGroupId: values.supplyGroup?.id!,
        description: values.description,
        measurementUnitId: values.measurementUnitId!,
        grossWeight: values.grossWeight,
        netWeight: values.netWeight,
        minimumStock: values.minimumStock,
        conversions: values.conversions,
        meepProductId: values.product,
        storageLocationId: values.storageLocationId,
        compositions: values.compositions,
        xmlEquivalences: []
    };

    return await service.updateSupply(localId, id, request);
};

export const getSuppliesListUseCase = (localId: string, filter: ISupplyFilterValue) => {
    const service = SupplyService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
    const request = {
        code: filter.code,
        page: page,
        pageSize: pageSize,
        orderBy: sortBy,
        ascending: filter.ascending,
        name: filter.name,
        supplyGroupId: filter.supplyGroupId,
        minStockRange: filter.minStockRange,
        maxStockRange: filter.maxStockRange,
        isProduct: filter.isProduct
    };
    return service.getSupplyList(localId, request);
}


export const getSupplyUseCase = async (localId: string, id: string) => {
    const service = SupplyService();
    return await service.getSupplyItem(localId, id);
}

export const getCompositionsUseCase = async (localId: string, supplyId: string) => {
    const service = SupplyService();
    return await service.getCompositions(localId, supplyId);
}

export const getSupplyCostsUseCase = async (localId: string, id: string) => {
    const service = SupplyService();
    return await service.getSupplyCosts(localId, id);
}