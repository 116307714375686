import { IFinancialData } from "modules/cashlessDashboard/presentation/components/financialData/interfaces/IFinancialData";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetProductionInfoUseCase = async (
  service: ICashlessDashboardService,
  params: any
) => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getProduction(filterRequest);

  return response;
};

export { GetProductionInfoUseCase };
