import CNPJ from "services/utils/CNPJ";
import { z } from "zod";

const validateSequence = (input: string): boolean => {
  const regex = /^(\d)\1*$/;
  return !regex.test(input);
};

export const localSchema = z.object({
  establishmentDocument: z.string()
    .min(14, { message: "Campo obrigatório!" })
    .refine((input) => validateSequence(input) && CNPJ.isValid(input), { message: "CNPJ inválido!" }),
  establishmentName: z.string()
    .min(1, { message: "Campo obrigatório!" }),
  segment: z.string()
    .min(1, { message: "Campo obrigatório!" }),
});

export type ILocalSchema = z.infer<typeof localSchema>;
