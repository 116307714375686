import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService";
import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams";
import { ILocalResume } from "modules/salesDashboard/presentation/components/localResume/ILocalResume";
import { makeFilterRequest } from "./MakeFilterRequest";

const GetLocalResumeUseCase = async (service: ISalesDashboardService, params: IUseCaseParams): Promise<ILocalResume> => {
  const filterRequest = makeFilterRequest(params);

  const response = await service.getLocalResume(filterRequest);

  response.records = response.records.map(x => ({ ...x, color: x.color ? `#${x.color}` : ''}));

  return response;
}

export default GetLocalResumeUseCase


