import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import React, { useCallback, useState, KeyboardEvent } from 'react'
import { v4 } from 'uuid'
import { IOtherValuesForm } from '../../interfaces/IServiceForm'

const UseOtherValuesForm = (values: IOtherValuesForm[], onChange: (values: IOtherValuesForm[]) => void, productList: ISelect[], onClickAddNewProduct?: () => void) => {

    const [newValue, setNewValue] = useState<IOtherValuesForm>({
        description: "",
        productListId: "",
        id: "",
        value: 0,
        minAge: 0,
        maxAge: 0
    } as IOtherValuesForm);
    const [isEdit, setIsEdit] = useState(false);

    const [selectedProductList, setSelectedProductList] = useState<ISelect | undefined>({ description: "", id: "" });

    const onChangeHandle = useCallback((name: string, value: string) => {
        if (name === "value") {
            setNewValue(prev => ({ ...prev, [name]: Number.parseFloat(value === "" ? "0" : value) }));
        } else {
            setNewValue(prev => ({ ...prev, [name]: value }));
        }
    }, [])

    const changeProductListSelectHandle = useCallback((values: ISelect) => {
        if (values.id === "add") {
            onClickAddNewProduct && onClickAddNewProduct();
            setSelectedProductList(undefined);
        } else {
            setSelectedProductList(values);
        }
    }, [onClickAddNewProduct]);

    const onChangeHandlePrice = useCallback(
        (name: string, value: string) => {
            setNewValue(prev => ({ ...prev, [name]: value }))
        }, [])


    const onKeyPressHandle = useCallback(
        (ev: KeyboardEvent<HTMLDivElement>) => {
            if (ev.key == "Enter") {
                ev.preventDefault();
            }
        },
        []
    );

    const onHandleChangeAge  = useCallback(
        (name: string, value: string) => {
            let age = parseInt(value);
            if( age > 200 ){
                setNewValue(prev => ({ ...prev, [name]: 200 }))
            }else{
                setNewValue(prev => ({ ...prev, [name]: value }))
            }
        }, [])

    const onAddHandle = useCallback(
        () => {
            if (selectedProductList) {
                onChange([...values, { ...newValue, id: v4(), productListId: selectedProductList.id }]);
            }
            setNewValue({
                description: "",
                productListId: "",
                id: "",
                value: 0,
                minAge: 0,
                maxAge: 0
            } as IOtherValuesForm)
            setSelectedProductList(undefined);
            setIsEdit(false);

        },
        [newValue, onChange, values, selectedProductList],
    )



    const onClickEditItem = useCallback((productListId: string) => {
        setIsEdit(true);
        if (values) {
            const item = values.find(_item => _item.productListId === productListId);

            const selectedItem = productList.find(item => item.id === productListId);
            setSelectedProductList(selectedItem);

            setNewValue({
                description: item?.description,
                productListId: item?.productListId,
                value: item?.value,
                minAge: item?.minAge,
                maxAge: item?.maxAge
            } as IOtherValuesForm)
            onChange(values.filter(item => item.productListId !== productListId))
        }
    }, [onChange, values, productList]);

    const onRemoveHandle = useCallback(
        (value: IOtherValuesForm) => {
            onChange(values.filter(item => item.productListId !== value.productListId))
        },
        [onChange, values],
    )


    return (
        {
            onChangeHandle,
            onAddHandle,
            onRemoveHandle,
            onKeyPressHandle,
            newValue,
            onChangeHandlePrice,
            selectedProductList,
            changeProductListSelectHandle,
            onClickEditItem,
            onHandleChangeAge,
            isEdit
        }
    )
}

export default UseOtherValuesForm
