import GetAverageTicketUseCase from "modules/cashlessDashboard/application/useCases/GetAverageTicketUseCase";
import { useCallback, useState } from "react";
import CashlessDashboardService from "services/api/cashlessDashboard/CashlessDashboardService";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { IFilterValue } from "../filter/IFilter";
import { IAverageTicket } from "./interfaces/IAverageTicket";

const cashlessDashboardService = CashlessDashboardService();

export const UseAverageTicket = (filter: IFilterValue) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<IAverageTicket>()

    const { setOnError, clearContainerError } = useError()

    const getData = useCallback(async () => {
        try {
            clearContainerError(dashboardContainerEnum.AVERAGE_TICKET);
            clearContainerError(dashboardContainerEnum.AVERAGE_TICKET_CHART);
            setLoading(true)
            if (filter.cashierIds.length){ 
                const response = await GetAverageTicketUseCase(cashlessDashboardService, { filter });
                setData(response)
            }
        } catch (err) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.AVERAGE_TICKET
            })
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.AVERAGE_TICKET_CHART
            })
        } finally {
            setLoading(false)
        }
    }, [clearContainerError, filter, setOnError])

    return {
        loading, 
        data,
        getData,
    }
}
