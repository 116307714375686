import { AddCircle } from "@material-ui/icons";
import { InfinityScroll } from "components/infinityScroll/InfinityScroll";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import { CashlessConsummationForm } from "modules/events/presentation/components/cashlessConsummationForm/CashlessConsummationForm";
import { FC } from "react";
import { ManageTitle } from "../manageTitle/ManageTitle";
import styles from "./CashlessConsummation.module.scss";
import { CashlessConsummationCard } from "./components/cashlessConsummationCard/CashlessConsummationCard";
import UseCashlessConsummation from "./UseCashlessConsummation";
import { IEvents, OriginType } from "modules/events/domain/models/IEvents";

interface ICashlessConsummationProps {
  cashierId?: string;
  event: IEvents;
}

export const CashlessConsummation: FC<ICashlessConsummationProps> = ({
  cashierId,
  event,
}) => {
  const {
    handleCloseBalanceModal,
    handleOpenBalanceModal,
    showAddBalanceModal,
    cashlessList,
    handleAddCashlessConsummation,
    loadingCashlessList,
    getCashlessList,
    totalRecords,
    localId,
    localConfiguration,
  } = UseCashlessConsummation(cashierId);
  if (!localConfiguration?.cashlessPrepaid) return null;
  return (
    <div id={styles.CashlessConsummation}>
      <ManageTitle
        title="Saldo"
        subTitle="Adicione saldo virtual para ser usado através do Meep app para qualquer pessoa do evento."
      />
      {cashierId && event.originType === OriginType.Portal && (
        <Button
          fullWidth={false}
          onClick={handleOpenBalanceModal}
          className={styles.addBalanceButton}
        >
          Adicionar saldo
          <AddCircle />
        </Button>
      )}
      <div>
        {cashierId && localId && (
          <InfinityScroll
            className={styles.cardList}
            onBottomHit={getCashlessList}
            hasNext={cashlessList.length < totalRecords}
            isLoading={loadingCashlessList}
          >
            {cashlessList && cashlessList.length > 0 && (
              <>
                <div className={styles.listHeader}>
                  <h3>Nome</h3>
                  <h3>CPF</h3>
                  <h3>Descrição</h3>
                  <h3>Saldo atual</h3>
                </div>
                {cashlessList.map((item, key) => (
                  <CashlessConsummationCard
                    key={key}
                    cashlessConsummation={item}
                    refreshCashlessList={getCashlessList}
                  />
                ))}
              </>
            )}
            {cashlessList && cashlessList.length === 0 && (
              <div className={styles.empty}>
                <h3>Nenhum cartão virtual em aberto encontrado!</h3>
                <p>
                  Os cartões virtuais consedidos, vinculados a este evento,
                  aparecerão nesta lista!
                </p>
              </div>
            )}
          </InfinityScroll>
        )}
        {!cashierId && (
          <div className={styles.empty}>
            <h3>Caixa desvinculado!</h3>
            <p>As comandas vinculadas a este evento, aparecerão nesta lista!</p>
          </div>
        )}
      </div>
      <RightDrawer
        title="Adicionar saldo cashless"
        open={showAddBalanceModal}
        onClose={handleCloseBalanceModal}
        className={styles.modal}
      >
        <CashlessConsummationForm
          handleCancel={handleCloseBalanceModal}
          handleSubmit={handleAddCashlessConsummation}
        />
      </RightDrawer>
    </div>
  );
};
