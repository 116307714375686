import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import React, { FC, useCallback, useState } from "react";
import DiscountForm from "../discountForm/DiscountForm";
import { IDiscountListForm, IDiscountListSubmitForm } from "../discountForm/interfaces/IDiscountProductListForm";
import style from "./DiscountListForm.module.scss";
import UseDiscountListForm from "./UseDiscountListForm";
import DiscountProductForm from "../discountForm/components/form/DiscountProductForm";
import { Tab, Tabs } from "@mui/material";
import SwipeableViews from "react-swipeable-views";

export interface IDiscountListFormProps {
  handleSubmit: (list: IDiscountListSubmitForm) => void;
  handleCancel?: () => void;
  onClickCloseHandle: () => void
}

const DiscountListForm: FC<IDiscountListFormProps> = ({
  handleSubmit,
  handleCancel,
  onClickCloseHandle
}) => {

  const {
    discountList,
    handleChangeName,
    handleChangeDescription,
    handleSubmitForm,
    handleDiscountChange,
    productList,
    index,
    setIndex,
    handleChange,
    errors
  } = UseDiscountListForm(
    handleSubmit
  );


  const onSubmitAddHandle = useCallback(

    (newValue: IDiscountListForm[]) => {
      // const oldValue = value.filter((item) => !newValue.find((_item) => _item.product.id === item.product.id));
      handleDiscountChange([...discountList, ...newValue]);
    },
    [discountList, handleDiscountChange]
  );

  return (
    <form id={style.GuestListForm} onSubmit={handleSubmitForm}>
      <div>
        <Tabs
          value={index}
          onChange={(ev, value) => handleChange(value)}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Detalhes" />
          <Tab label="Descontos" />
        </Tabs>
        <SwipeableViews
          index={index}
          className={style.contentTabs}
          onChangeIndex={(value) => handleChange(value)}
        >
          <>
            <div className={style.contentDetails}>
              <div className={style.listName}>
                <FormItemContainer>
                  <Input
                    fullWidth={false}
                    label="Nome"
                    variant="outlined"
                    placeholder="Lista de descontos"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 50
                    }}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </FormItemContainer>
                <FormItemContainer>
                  <Input
                    fullWidth={false}
                    label="Descrição"
                    variant="outlined"
                    placeholder="Descrição da lista de descontos"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 100
                    }}
                    onChange={handleChangeDescription}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </FormItemContainer>
              </div>
            </div>
          </>
          <>
            {
              productList &&
              <div className={style.contentDiscount}>
                <DiscountProductForm productList={productList} discountsList={discountList} onSubmit={onSubmitAddHandle} />

                <div className={style.discountContainer}>
                  <h2>Descontos</h2>
                  <FormItemContainer>
                    <DiscountForm value={discountList} errors={errors} onChange={handleDiscountChange} productList={productList} />
                  </FormItemContainer>
                </div>
              </div>
            }
          </>
        </SwipeableViews>
      </div>

      {
        index === 0 &&
        <footer>

          <Button fullWidth={false} variant="outlined" onClick={onClickCloseHandle}>Cancelar </Button>
          <Button fullWidth={false} onClick={() => handleChange(1)} >Continuar</Button>


        </footer>
      }
      {
        index === 1 &&
        <footer>

          <Button fullWidth={false} variant="outlined" onClick={() => handleChange(0)}>Voltar </Button>
          <Button fullWidth={false} type="submit">
            Adicionar Lista
          </Button>
        </footer>
      }

    </form>
  );
};
export default DiscountListForm;
