import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import { ITerminalValues } from './ITerminalValues';
import { FormControlLabel,  Switch } from '@material-ui/core';


export interface ITerminalFormProps {
    defaultValue?: ITerminalValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: ITerminalValues) => void
    onClickBack: (value: ITerminalValues) => void
}

const TerminalForm: React.FC<ITerminalFormProps> = ({ onModified, onClickBack, defaultValue, onSubmit }) => {
    const [values, setValues] = useState<ITerminalValues>(defaultValue ?? {} as ITerminalValues);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeChecked = (property: string, value: any) => {
        if (value) {
            setValues((prev) => ({ ...prev, [property]: 1 }));
        } else {
            setValues((prev) => ({ ...prev, [property]: 0 }));
        }

        onModified(true);
    }


    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])

    return (
        <form onSubmit={submitHandle}>
            <div >

                <FormControlLabel
                 onChange={(ev, checked) => onChangeChecked("operatorMode", checked)}
                checked={values.operatorMode ? true : false}
                    control={<Switch color='secondary' />}
                    label={
                        <p>Dispositivo utilizado somente por um operador</p>
                    }
                />
                <FormControlLabel
                onChange={(ev, checked) => onChangeChecked("tableMode", checked)}
                checked={values.tableMode ? true : false}
                    control={<Switch color='secondary' />}
                    label={<p>Visualizar como mapa de mesa</p>}
                />
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default TerminalForm