import { IGetDataParams } from 'modules/salesDashboard/presentation/interfaces/IGetDataParams';
import React, { FC, useEffect, useState } from 'react'
import { Skeleton } from '../../skeleton/Skeleton'
import { groupByEnum, IProductRankingData, IRankinProduct } from '../IRankinProduct'
import styles from './SubItemsProducts.module.scss'
export interface ISubItemsProductsProps {
    categoryId: string,
    groupBy: groupByEnum | undefined;
    getSubItems: ({ groupBy }: IGetDataParams, productGroupId? :string) => Promise<IProductRankingData>,
}
const SubItemsProducts: FC<ISubItemsProductsProps> = ({ categoryId, groupBy, getSubItems }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [subItems, setSubItems] = useState<IRankinProduct[] | undefined>();
    
    useEffect(() => {
        if (getSubItems) {
            setIsLoading(true);
            getSubItems?.({ groupBy }, categoryId).then(response => {
                setSubItems(response.records);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [categoryId, getSubItems, groupBy])

    return (
        <div id={styles.SubItemsProducts} >
            {
                isLoading ?
                    <div>
                        <Skeleton />
                    </div>
                    :
                    !subItems?.length && !isLoading ? <span className={styles.noData}>Não foram encontrados produtos nesta categoria!</span> :
                    subItems?.map((subitem) =>
                        <div className={styles.subItem}>
                            <label>
                                <div className={styles.icon} style={{ backgroundColor: subitem.color ? '#' + subitem.color : '#ccc' }} />
                                {subitem.name}
                            </label>
                            <label>{subitem.categoryName}</label>
                            <label className={styles.quantityColumn}>{subitem.quantity}</label>
                            <label>R${subitem.total.toFixed(2)}</label>
                        </div>
                    )
            }
        </div>
    )
}
export default SubItemsProducts