import { IBodySegmentos, IUpdateSegmentosService } from "../interfaces/IGestaoVendasService";

const UpdateSegmentos = async (
  accountService: IUpdateSegmentosService,
  id: string,
  body: IBodySegmentos
): Promise<void> => {
  return await accountService.updateSegmentos(id, body);
};

export default UpdateSegmentos;