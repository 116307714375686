import { IGetKdsListRequest } from "modules/kds/domain/dto/getKdsList/GetKdsListRequest";
import { IKDSLocalStorage } from "modules/kds/domain/interfaces/IKDSLocalStorage"
import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSFilter } from "modules/kds/presentation/interfaces/IKDSFilter";

const VerifyUpdateOrderUseCase = async (service: IKDSService, localStorage: IKDSLocalStorage, filter: IKDSFilter, localId: string) => {

const adjustTime = (date: string | undefined): string => {
    if (!date) return '';
    const brasiliaOffset = -3; // UTC-3
    const dateObj = new Date(date);
    dateObj.setUTCHours(dateObj.getUTCHours() + brasiliaOffset);
    return dateObj.toISOString();
};


  const request: IGetKdsListRequest = {
        localId: localId,
        startDate: adjustTime(String(filter.startDate)),
        endDate: adjustTime(String(filter.endDate)),
        stepIds: filter.steps?.map(item => item.id),
        input: filter.input,
        tableIds: filter?.tables?.map(item => item.id),
        page: 1,
        pageSize: 1,
        storesId: filter.store?.id,
        orientation: "desc",
        groupedByTable: false,
        sortByTable: false
    }

    const response = await service.getKDSList(request)
    if (response.items.length) {
        const id = response.items[0].id;
        const localStorageId = localStorage.getLastOrderId();
        if (id === localStorageId) {
            return false
        } else {
            localStorage.saveLastOrderId(id);
            return true
        }
    } else {
        return true
    }
}
export default VerifyUpdateOrderUseCase
