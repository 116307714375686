import { GetSurveysUseCase } from 'modules/automation/application/useCases/GetSurveysUseCase'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { ISurveyItem } from '../interfaces/ISurvey'
import AutomationService from 'services/api/automation/AutomationService'
import { ILocal } from 'modules/local/domain/models/ILocal'
import { SyncWithTrackUseCase } from 'modules/automation/application/useCases/SyncWithTrackUseCase'



const service = AutomationService()

const UseSurveyList = (currentLocal?: ILocal) => {
    const [surveys, setSurveys] = useState<ISurveyItem[]>([]);
    const [isLoadingSurvey, setIsLoadingSurvey] = useState(false)

    const getSurveys = useCallback(() => {
        (async () => {
            try {
                setIsLoadingSurvey(true)
                if (currentLocal) {
                    await SyncWithTrackUseCase(service, currentLocal?.id)
                    const response = await GetSurveysUseCase(service, currentLocal.id);
                    setSurveys(response.data)
                }
            } finally {
                setIsLoadingSurvey(false)
            }
        })()
    }, [currentLocal])

    return ({ getSurveys, surveys, isLoadingSurvey })
}

export default UseSurveyList