import { useCallback, useState } from 'react'
import { ITransferFormErrors, ITransferFormItem } from './interfaces/ITransferForm'
import { validate } from './validade/TranferFormValidation';

export const UseTransferForm = (onSubmit: (values: ITransferFormItem) => void, stepTransfer: number, defaultValues?: ITransferFormItem) => {

    const [values, setValues] = useState(defaultValues ?? {
        conta: '',
        banco:  '',
        agencia: '',
        digitoAg: '',
        contaCorrente: '',
        digitoConta: '',
        documento: '',
        nome: '',
        valor: '',
        codigo: '',
        TaxaTED: 0,
        SaldoRestante: 0
    })

    const [errors, setErrors] = useState<ITransferFormErrors>({});

    const onChangeHandle = useCallback((name: string, value: any) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback(() => {
        if (validate(values, setErrors, stepTransfer)) {
            onSubmit(values);
        }
    }, [onSubmit, values, stepTransfer]);

    return (
        {
            values,
            errors,
            onChangeHandle,
            onSubmitHandle,
        }
    )
}