import React, { FC } from 'react';
import styles from './ManageTitle.module.scss';

interface IManageTitleProps {
  title: string;
  subTitle: string;
};

export const ManageTitle: FC<IManageTitleProps> = ({ title, subTitle }) => {
  return (
    <div id={styles.ManageTitle}>
      <h2>{title}</h2>
      <p>{subTitle}</p>
    </div>
  );
};