import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import styles from "./ShowPassword.module.scss";

export interface IShowPasswordProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowPassword = ({
  showPassword,
  setShowPassword,
}: IShowPasswordProps) => {
  return (
    <div
      id={styles.ShowPassword}
      onClick={() => setShowPassword(!showPassword)}
    >
      {!showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
    </div>
  );
};

export default ShowPassword;
