import React, { FC, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import SegmentForm from 'modules/meepErp/presentation/components/segment/segmentForm/SegmentForm'
import { ISegmentValues } from 'modules/meepErp/presentation/components/segment/segmentForm/SegmentValues'
import { UseLocalStore } from 'modules/local/presentation/store/UseLocalStore'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import SegmentService from 'modules/meepErp/services/api/Segment/SegmentService'
import { ISegmentResponse } from 'modules/meepErp/application/dtos/segment/ISegmentResponse'
import { IGetSegmentListResponse } from 'modules/meepErp/application/dtos/segment/IGetLSegmentListResponse'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'

const SegmentAddOrUpdatePage: FC = () => {

    const [currentLocal] = UseLocalStore(state => [state.currentLocal])
    const { id } = useParams<{ id?: string }>();
    const queryClient = useQueryClient();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title:"Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Segmento",
                url: "/private/meeperp/segment"
            },
            {
                title: "Cadastro de segmento",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const submitHandler = useCallback(async (values: ISegmentValues, id?: string) => {
        try {
            showLoading();
            if (id) {
                const service = SegmentService();
                return await service.updateSegment({ ...values, id: id! }); //chamar usecases
            } else {
                const service = SegmentService();
                return await service.createSegment({ ...values, isActive: true }); //chamar usecases
            }
        } finally {
            hideLoading();
        }
    }, [])



    const getDefaultValue = useCallback(async (id?: string) => {
        try {
            showLoading();
            if (!id) return;
            const response = await SegmentService().getSegmentItem(id); // chamar use case
            return response;
        } finally {
            hideLoading();
        }
    }, [])


    //REACT QUERY
    const defaultValue = useQuery('@segment', () => getDefaultValue(id), {
        enabled: !!id && !!currentLocal?.id,
    })

    const onSuccessfulSubmit = useCallback((data: ISegmentResponse) => {
        if (id) {
            queryClient.setQueriesData<IGetSegmentListResponse | undefined>(['@segment'], (previous) =>
            (previous ? {
                ...previous,
                items: previous?.items?.map(item => item.id === id ? data : item) ?? []
            } : undefined))
        } else {
            queryClient.setQueriesData<IGetSegmentListResponse | undefined>(['@segment'], (previous) =>
            (previous ? {
                ...previous,
                items: [{ ...data, isActive: true }, ...previous.items]
            } : undefined))
        }

        replace('/private/meeperp/segment/')
    }, [id, queryClient, replace])

    const submitForm = useMutation((values: ISegmentValues) => submitHandler(values, id), {
        onSuccess: onSuccessfulSubmit
    })

    const onClickSubmitForm = async (values: ISegmentValues) => {
        await submitForm.mutateAsync(values);
    }

    return (
        <div>
            <Back route='/private/meeperp/segment' margin />
            <div>
                <SegmentForm
                    isLoading={submitForm.isLoading || defaultValue.isLoading}
                    disabled={submitForm.isLoading || defaultValue.isLoading}
                    defaultValue={defaultValue.data}
                    onSubmit={onClickSubmitForm}
                />
            </div>
        </div>
    )
}
export default SegmentAddOrUpdatePage
