import { IPaginatedList } from 'models/api/IApi';
import { IEventsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IGetEventsPeriodResumeResponse } from 'modules/events/domain/dto/IGetEventsPeriodResumeResponse';
import { EventStatusEnum } from 'modules/events/domain/models/IEvents';
import { EventPriodResumeValue } from 'modules/events/presentation/components/FilterEvents/FilterEventList';
import { IEventsApi } from '../../interfaces/IEventsService';

const GetEventsPeriodResumeUseCase = async (
  eventsService: IEventsApi,
  localId: string,
  mounth?: number,
  year?: number,
): Promise<EventPriodResumeValue> => {
  return await eventsService.getEventsPeriodResume(localId, mounth, year);
};

export default GetEventsPeriodResumeUseCase;
