import {
  IPerfilBody,
  IPerfilEventoBody,
  IUpdateEquipamentosBody,
} from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/IPerfilService";
import { api } from "services/api/Api";
import { deviceConfigApi } from "../equipamento/DeviceConfigApi";

export const PerfilApi = (): any => {
  const getPerfilVinculo = async (id: string): Promise<any> => {
    const result = await api.get(
      `/Proprietario/tipovinculo?LocalClienteId=${id}`
    );
    return result.data;
  };

  const postPerfilVinculo = async (
    id: string,
    body: IPerfilBody
  ): Promise<any> => {
    const result = await api.post<any>(
      `/Proprietario/tipovinculo?LocalClienteId=${id}`,
      body
    );
    return result.data;
  };

  const postAddPerfilEvento = async (
    id: string,
    body: IPerfilEventoBody
  ): Promise<any> => {
    const result = await deviceConfigApi.post<any>(
      `locals/${id}/configuration`,
      body
    );
    return result.data;
  };

  const getPerfilEvento = async (
    id: string,
    idEvento: string,    
    limit: number,
    offset: number,
    profileName: string,
  ): Promise<any> => {
    const result = await deviceConfigApi.get<any>(
      `locals/${id}/configuration?LocalId=${id}&&eventId=${idEvento}&Limit=${limit}&Offset=${offset}&profileName=${profileName}`
    );
    return {
      localId: result.data.localId,
      totalCount: result.data.totalCount,
      bond: result.data.bond.map((item: any) => ({
        id: item.profileId,
        name: item.profileName,
        devices: item.devices ? item.devices : [],
      })),
    };
  };

  const getDisponibilidade = async (
    id: string,
  ): Promise<any> => {
    const result = await api.get<any>(
      `disponibilidade/${id}`
    );
    return result.data;
  };

  const getEquipamentos = async (
    id: string,
    idEvento: string,
    profileId: string,
    deviceName: string,
    type: number
  ): Promise<any> => {
    const paramsDeviceName = deviceName ? `&query=${deviceName}` : "";
    const paramsDeviceType = type ? `&Types=${type}` : "";

    const result = await deviceConfigApi.get<any>(
      `locals/${id}/devices/event-devices?eventId=${idEvento}&profileId=${profileId}${paramsDeviceName}${paramsDeviceType}`
    );
    return result.data;
  };

  const updateAddPerfilEvento = async (
    id: string,
    body: IPerfilEventoBody
  ): Promise<any> => {
    const result = await deviceConfigApi.put<any>(
      `locals/${id}/configuration`,
      body
    );
    return result.data;
  };

  const updateEquipamentos = async (
    id: string,
    body: IUpdateEquipamentosBody
  ): Promise<any> => {
    const result = await deviceConfigApi.put<any>(
      `locals/${id}/configuration/devices`,
      body
    );
    return result.data;
  };

  const deletePerfilEvento = async (
    id: string,
    idEvento: string,
    profileId: string
  ): Promise<any> => {
    const result = await deviceConfigApi.delete<any>(
      `locals/${id}/configuration?eventId=${idEvento}&profileId=${profileId}`
    );
    return result.data;
  };

  return {
    getPerfilVinculo,
    postPerfilVinculo,
    postAddPerfilEvento,
    getPerfilEvento,
    updateAddPerfilEvento,
    deletePerfilEvento,
    updateEquipamentos,
    getEquipamentos,
    getDisponibilidade
  };
};
