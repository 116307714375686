import Layout from "components/layout/presentation/Layout";
import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import StockOperationAddOrUpdatePage from "./pages/operations/AddOrUpdate/StockOperationAddOrUpdatePage";
import StockOperationsPage from "./pages/operations/StockOperations";
import PartnersPage from "./pages/partner/PartnersPage";
import SupplyStorageLocalsPage from "./pages/storageLocals/SupplyLocals";
import LocalAddOrUpdatePage from "./pages/storageLocals/LocalAddOrUpdate/LocalAddOrUpdatePage";
import UnitPage from "./pages/unit/Unit";
import UnitAddOrUpdatePage from "./pages/unit/UnitAddOrUpdate/UnitAddOrUpdatePage";
import SegmentPage from "./pages/segment/Segment";
import SegmentAddOrUpdatePage from "./pages/segment/SegmentAddOrUpdate/SegmentAddOrUpdatePage";
import SupplyPage from "./pages/supply/Supply";
import AddOrUpdateCorporatePartnerPage from "./pages/partner/addOrUpdate/AddOrUpdateCorporatePartnerPage";
import AddOrUpdateIndividualPartnerPage from "./pages/partner/addOrUpdate/AddOrUpdateIndividualPartnerPage";
import SupplyGroupPage from "./pages/supply/supplyGroup/SupplyGroupPage";
import AddOrUpdateSupplyGroupPage from "./pages/supply/supplyGroup/addOrUpdateSupplyGroup/AddOrUpdateSupplyGroupPage";
import CreateOrUpdateSupplyPage from "./pages/supply/creatOrUpdateSupply/CreateOrUpdateSupplyPage";
import InvoicePage from "./pages/invoice/InvoicePage";
import AddOrUpdateInvoice from "./pages/invoice/AddOrUpdateInvoice/AddOrUpdateInvoicePage";
import SupplyStockPage from "./pages/supplyStock/SupplyStockPage";
import SupplyStockMovementPage from "./pages/supplyStockMovement/SupplyStockMovementPage";
import CreateOrUpdateInventoryPage from "./pages/inventory/createInventory/CreateOrUpdateInventoryPage";
import InventoryPage from "./pages/inventory/InventoryPage";

export const ErpRoutes = () => {
  return <Layout>
    <Switch>

      {/* Inventory Routes */}
      <Route exact path="/private/meeperp/operations/add" component={StockOperationAddOrUpdatePage} />
      <Route exact path="/private/meeperp/operations/update/:id" component={StockOperationAddOrUpdatePage} />
      <Route path="/private/meeperp/operations/" component={StockOperationsPage} />
      {/* Partner Routes */}
      <Route
        path="/private/meeperp/partners/addOrUpdate/corporate/:id?"
        component={AddOrUpdateCorporatePartnerPage}
      />
      <Route
        path="/private/meeperp/partners/addOrUpdate/individual/:id?"
        component={AddOrUpdateIndividualPartnerPage}
      />
      <Route exact path="/private/meeperp/partners/" component={PartnersPage} />
      {/* Locals Routes */}
      <Route exact path="/private/meeperp/locals/update/:id" component={LocalAddOrUpdatePage} />
      <Route exact path="/private/meeperp/locals/add" component={LocalAddOrUpdatePage} />
      <Route path="/private/meeperp/locals/" component={SupplyStorageLocalsPage} />
      {/* Unit Routes */}
      <Route path="/private/meeperp/unit/update/:id" component={UnitAddOrUpdatePage} />
      <Route path="/private/meeperp/unit/add" component={UnitAddOrUpdatePage} />
      <Route path="/private/meeperp/unit/" component={UnitPage} />
      {/* Segment Routes */}
      <Route path="/private/meeperp/segment/update/:id" component={SegmentAddOrUpdatePage} />
      <Route path="/private/meeperp/segment/add" component={SegmentAddOrUpdatePage} />
      <Route path="/private/meeperp/segment/" component={SegmentPage} />
      {/* Supply Routes */}
      <Route path="/private/meeperp/supply/supplyGroups/update/:id" component={AddOrUpdateSupplyGroupPage} />
      <Route path="/private/meeperp/supply/supplyGroups/add" component={AddOrUpdateSupplyGroupPage} />
      <Route path="/private/meeperp/supply/supplyGroups" component={SupplyGroupPage} />
      <Route path="/private/meeperp/supply/update/:id" component={CreateOrUpdateSupplyPage} />
      <Route path="/private/meeperp/supply/add" component={CreateOrUpdateSupplyPage} />
      <Route path="/private/meeperp/supply/" component={SupplyPage} />
      {/* Invoice */}
      <Route path="/private/meeperp/invoices/update/:id/:type" component={AddOrUpdateInvoice} />
      <Route path="/private/meeperp/invoices/add" component={AddOrUpdateInvoice} />
      <Route path="/private/meeperp/invoices" component={InvoicePage} />
      {/* Supply Stock */}
      <Route path="/private/meeperp/supplyStocks" component={SupplyStockPage} />
      {/*Supply movements*/}
      <Route path="/private/meeperp/supplyStockMovement" component={SupplyStockMovementPage} />
      {/* Inventory Routes */}
      <Route exact path="/private/meeperp/inventory/add" component={CreateOrUpdateInventoryPage} />
      <Route exact path="/private/meeperp/inventory/update/:id" component={CreateOrUpdateInventoryPage} />
      <Route path="/private/meeperp/inventory" component={InventoryPage} />
      {/* Erp Menu */}
      <Route path="/private/meeperp" component={ErpMenu} />

    </Switch>
  </Layout>
};


const ErpMenu = () => {
  const { push } = useHistory();
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    padding: 16

  }}>
    <h1>ERP Menu</h1>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/inventory/operations")}>Inventory Operations</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/partners")}>Partners</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/locals")}>Locals</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/unit")}>Units</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/segment")}>Segments</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/supply")}>Supply</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/invoices")}>Invoices</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/supplyStocks")}>Supply Stocks</a>
    <a style={{ cursor: 'pointer' }} onClick={() => push("/private/meeperp/supplyStockMovement")}>Supply Stock Movements</a>
  </div>
}

export const tabsRegister = [
  {
    label: 'Segmentos',
    path: 'segment',
  },
  {
    label: 'Parceiros',
    path: 'partners'
  },
  {
    label: 'Grupos de insumos',
    path: 'supply/supplyGroups',
  },
  {
    label: 'Locais de estoque',
    path: 'locals',
  },
  {
    label: 'Unidades de medida',
    path: 'unit',
  },
  {
    label: 'Insumos',
    path: 'supply',
  },
  {
    label: 'Tipos de operações',
    path: 'operations',
  },
]

export const tabsStock = [
  {
    label: 'Movimento de estoque',
    path: 'supplyStockMovement',
  },
  {
    label: 'Posição de estoque',
    path: 'supplyStocks'
  },

]