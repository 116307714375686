import { IDetailsLockResponse } from "modules/contaDigital/models/dtos/IDetailsLockResponse";
import { IDigitalAccountApi } from "modules/contaDigital/presentation/pages/interface/IDigitalAccountApi";

const GetDetailsLockUseCase = async (
  accountService: IDigitalAccountApi,
  localId: string
): Promise<IDetailsLockResponse> => {
  return await accountService.getDetailsLock(localId);
};

export default GetDetailsLockUseCase;