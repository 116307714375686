import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
export interface ILimitarAcessoProps {
  //propertys
}
const LimitarAcesso: FC<ILimitarAcessoProps> = () => {
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    updateRouters([
      {
        title: "Limitar Acessos",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <IframePage src={"/produtos/limitarAcesso"} />
    </Layout>
  );
};
export default LimitarAcesso;
