import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IAddChild } from "modules/permutation/presentation/interfaces/IAddChild";
import ParseAddChildRequestUseCase from "./ParseAddChildRequestUseCase";

export const AddChildUseCase = async (service: IPermutationApiService, request: IAddChild, localId: string, accountId: string,): Promise<void> => {

  const parse = ParseAddChildRequestUseCase(request, localId);
  const _request = await service.postAddChild(parse, accountId);
  return _request;
}


