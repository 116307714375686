import { IGetUnitListRequest } from "modules/meepErp/application/dtos/unit/IGetUnitListRequest";
import { IGetUnitItemResponse, IGetUnitListResponse } from "modules/meepErp/application/dtos/unit/IGetLUnitListResponse";
import { delayMock, getPaginatedUnitMOCK, getUnitItemMOCK } from "./UnitMOCK";
import { IUnitService } from "modules/meepErp/application/interfaces/_unit/IUnitService";
import { ISaveUnitRequest, IUpdateUnitRequest } from "modules/meepErp/application/dtos/unit/ISaveUnitRequest";
import { IUnitResponse } from "modules/meepErp/application/dtos/unit/IUnitResponse";
import { Api } from "services/api/Api";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { erpUrl } from "Enviroment";

const BASE_URL = erpUrl;

const UnitService = (): IUnitService => {
  const api = Api();

  const getUnitList = async (request: IGetUnitListRequest): Promise<IGetUnitListResponse> => {
    // implementation goes here
    // return await getPaginatedUnitMOCK(request);
    const response = await api.get<IGetUnitListResponse>(
      `${BASE_URL}/api/Inventory/MeasurementUnit`,
      { params: request }
    );
    return response.data;
  };

  const getUnitItem = async (ownerId: string, id: string): Promise<IGetUnitItemResponse> => {
    // implementation goes here
    // return await getUnitItemMOCK(id);
    const response = await api.get<IGetUnitItemResponse>(
      `${BASE_URL}/api/Inventory/MeasurementUnit/${id}`
    );
    return response.data;

  };

  const deleteUnit = async (id: string): Promise<void> => {
    // implementation goes here
    await api.delete(`${BASE_URL}/api/Inventory/MeasurementUnit/${id}`);
  };

  const createUnit = async (
    data: ISaveUnitRequest
  ): Promise<IUnitResponse> => {
    // implementation goes here
    const response = await api.post<IUnitResponse>(
      `${BASE_URL}/api/Inventory/MeasurementUnit`,
      data
    );
    return response.data;
  };

  const updateUnit = async (
    data: IUpdateUnitRequest
  ): Promise<IUnitResponse> => {
    const response = await api.put<IUnitResponse>(
      `${BASE_URL}/api/Inventory/MeasurementUnit/${data.id}`,
      data
    );
    return response.data;
  };

  const enableUnit = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/MeasurementUnit/${id}/Enable`);
    return response.data
  };

  const disableUnit = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/MeasurementUnit/${id}/Disable`);
    return response.data
  };

  return {
    getUnitList,
    getUnitItem,
    deleteUnit,
    createUnit,
    updateUnit,
    enableUnit,
    disableUnit
  };
}
export default UnitService

