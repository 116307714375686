
import { Autocomplete, TextField } from '@mui/material'
import styles from './Search.module.scss'
import Button from 'components/ui/Button/Button'
import UseRolesList from 'modules/cargos/presentation/hooks/UseRolesList'
import { useEffect, useMemo, useState } from 'react'
import { IGetColaboradoresSearchParams } from 'modules/colaboradores/domain/dto/IGetColaboradoresSearchParams'
import UseRegisteredRolesList from 'modules/cargos/presentation/hooks/UseRegisteredRolesList'

export interface ISearchProps {
    setSearchParams: React.Dispatch<React.SetStateAction<IGetColaboradoresSearchParams>>;
    setExpandedAccordions: React.Dispatch<React.SetStateAction<string>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Search = ({ setSearchParams, setExpandedAccordions, setCurrentPage }: ISearchProps) => {
    const [search, setSearch] = useState<IGetColaboradoresSearchParams>({} as IGetColaboradoresSearchParams);
    const { getAllRolesList, allRolesList } = UseRolesList();
    const { registeredRolesList, setRegisteredRolesList } = UseRegisteredRolesList();

    useEffect(() => {
        getAllRolesList();
    }, [getAllRolesList]);

    useEffect(() => {
        if (allRolesList) {
            setRegisteredRolesList(allRolesList);
        }
    }, [allRolesList]);

    const rolesList = useMemo(() => registeredRolesList ?? [], [registeredRolesList]);

    return (
        <div id={styles.Search}>
            <div className={styles.item}>
                <label>Nome do colaborador</label>
                <TextField
                    className={styles.textField}
                    value={search.employeeName}
                    placeholder="Filtrar pelo nome do operador"
                    onChange={(ev) => setSearch({...search, employeeName: ev.target.value})}
                />
            </div>
            <div className={styles.item}>
                <label>Cargo</label>
                <Autocomplete
                    disablePortal
                    options={rolesList}
                    getOptionLabel={option => option.Name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Filtrar pelo cargo"
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, autocompleteValues) => {
                        const rolesIds = autocompleteValues.map((it) => it.Id);
                        setSearch({...search, profileId: rolesIds.join(",") });
                    }}
                />
            </div>
            <div className={styles.item}>
                <label>E-mail</label>
                <TextField
                    className={styles.textField}
                    value={search.employeeEmail}
                    placeholder="Filtrar pelo e-mail do operador"
                    onChange={(ev) => setSearch({...search, employeeEmail: ev.target.value})}
                />
            </div>
            <Button
                className={styles.buttonSearch}
                onClick={() => {
                    setExpandedAccordions("");
                    setSearchParams(search);
                    if (Object.keys(search).length !== 0) {
                    setCurrentPage(1)
                    }
                }}
            >
                Buscar
            </Button>
        </div>
    )
}
export default Search;
