import React, { FC, useEffect } from 'react'
import DeviceOriginTable from '../deviceTable/DeviceTable'
import GenderTable from '../genderTable/GenderTable'
import styles from './AverageTicketConsume.module.scss'

export interface IAverageTicketConsumeData {
    products: {
        consume: Consume,
        deviceOrigin: DeviceOrigin,
        averageConsumptionPerHour: Array<AverageConsumptionPerHour>
    },
    tickets: {
        consume: Consume
        deviceOrigin: DeviceOrigin,
        averageConsumptionPerHour: Array<AverageConsumptionPerHour>
    }
}

export interface AverageConsumptionPerHour {
    type: number
    time: string
    male: number
    female: number
    unknown: number
}

export interface DeviceOrigin {
    total: number
    devices: {
        label: string,
        total: number
    }[]
}

export interface Consume {
    male: number
    female: number
    unknown: number
    value: number
}

export interface IAverageTicketConsumeProps {
    //propertys
    data?: IAverageTicketConsumeData
    isLoading?: boolean
    getData: () => void
    type: keyof IAverageTicketConsumeData
}
const AverageTicketConsume: FC<IAverageTicketConsumeProps> = ({ data, getData, isLoading, type }) => {
    useEffect(() => {
        getData();
    }, [getData])

    return (
        <div id={styles.AverageTicketConsume} >
            <GenderTable isLoading={isLoading} data={{ ...data?.[type]?.consume, valueLabel: "Valor médio de consumo" }} />
            <DeviceOriginTable isLoading={isLoading} total={data?.[type]?.deviceOrigin?.total} data={data?.[type]?.deviceOrigin?.devices}></DeviceOriginTable>
        </div>
    )
}
export default AverageTicketConsume