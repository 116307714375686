import { FC, useCallback, useEffect, useState } from 'react'
import styles from './StockOperations.module.scss'
import InventoryOperationsService from 'modules/meepErp/services/api/stockOperations/StockOperationsService';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import UseQueryState from 'modules/meepErp/presentation/hook/UseQueryFilter';
import { queryClient } from 'services/api/Api';
import { IGetStockOperationListResponse, IStockOperationItemResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse';
import DeleteErp from 'modules/meepErp/presentation/components/ui/delete/DeleteErp';
import TabsErp from 'modules/meepErp/presentation/components/ui/tabs/TabsErp';
import { Add } from '@material-ui/icons';
import { getOpeartionTypesUseCase, getStockOperationsUseCase, onDeleteOperationUseCase } from '../../../application/useCases/operations/StockOperationsUseCase';
import { IStockOperationFilterValue } from '../../components/stockOperations/stockOperationsList/InventoryOperationsFilter/IInvetoryFilter';
import StockOperationsList from '../../components/stockOperations/stockOperationsList/InventoryOperationsList';
import { tabsRegister } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

const StockOperationsPage: FC = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<IStockOperationFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Operações",
            },                         
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  



    //ReactiQuery

    // const operationTypes = useQuery('@operationTypes', getOpeartionTypesUseCase);

    const inventoryOperations = useQuery(['@inventoryOperations', currentLocal?.id, filter], () => getStockOperationsUseCase(currentLocal?.id!, filter), {
        enabled: !!currentLocal?.id,
    })



    const updateIsActive = useCallback(async (id: string, isActive: boolean, value: IStockOperationItemResponse) => {
        if (isActive) {
            const service = InventoryOperationsService();
            await service.enableInventoryOperation(id);
        } else {
            const service = InventoryOperationsService();
            await service.disableInventoryOperation(id);
        }
        return { ...value, isActive };
    }, []);


    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetStockOperationListResponse | undefined>(['@inventoryOperations'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteOperationMutation = useMutation((id: string) => onDeleteOperationUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteOperationMutation.mutateAsync(id);
    }, [onDeleteOperationMutation])

    const onSuccessUpdateIsActive = (value: IStockOperationItemResponse, { id, isActive }: { id: string; isActive: boolean }) => {
        queryClient.setQueriesData<IGetStockOperationListResponse | undefined>(
            ['@inventoryOperations'],
            (previous) =>
                previous
                    ? {
                        ...previous,
                        items: previous.items.map((item) =>
                            item.id === id ? { ...item, isActive } : item
                        ) ?? []
                    }
                    : undefined
        );
    };

    const onUpdateIsActiveMutation = useMutation(
        ({ id, isActive, value }: { id: string; isActive: boolean; value: IStockOperationItemResponse }) =>
            updateIsActive(id, isActive, value),
        {
            onSuccess: onSuccessUpdateIsActive
        }
    );

    const onConfirmEnable = useCallback(async (id: string, isActive: boolean, value: IStockOperationItemResponse) => {
        return await onUpdateIsActiveMutation.mutateAsync({ id, isActive, value });

    }, [onUpdateIsActiveMutation]);


    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/operations/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/operations/update/${id}`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    return (
        <div id={styles.Operations} >
            <TabsErp data={tabsRegister}/>
            <div>
                {/* <Button
                    fullWidth={false}
                    className={styles.buttonAdd}
                    onClick={onClickAdd}
                    endIcon={<Add className={styles.iconAdd} />}>
                    Adicionar tipo de operação
                </Button> */}
            </div>
            <StockOperationsList
                filter={filter}
                onChangeFilter={updateFilter}
                inventoryOperations={inventoryOperations.data}
                isLoading={inventoryOperations.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
                onClickEnable={onConfirmEnable}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'operação'}
                text={'Deseja realmente excluir a operação?'}
                textFeedback={'Operação excluído com sucesso!'}
                auxiliaryText='Ao excluir um tipo de operação todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
            />
        </div>
    )
}
export default StockOperationsPage



