import { z } from "zod";

const validateSequence = (input: string): boolean => {
  const regex = /^(\d)\1*$/;
  return !regex.test(input);
};

export const simpleTrialModalSchema = z.object({
  name: z.string()
  .min(2, "Campo obrigatório!"),
  document: z.string()
    .min(11, "Campo obrigatório!")
    .refine((input) => validateSequence(input), { message: "CPF inválido!" }),
  phone: z.string()
    .min(11, "Campo obrigatório!")
    .refine((input) => validateSequence(input), { message: "Telefone inválido!" }),
  establishmentDocument: z.string()
    .min(14, "Campo obrigatório!")
    .refine((input) => validateSequence(input), { message: "CNPJ inválido!" }),
  cep: z.string()
    .min(8, "Campo obrigatório!"),
  street: z.string()
    .min(1, "Campo obrigatório!"),
  number: z.string()
    .min(1, "Campo obrigatório!"),
  complement: z.string().optional(),
  neighborhood: z.string()
    .min(1, "Campo obrigatório!"),
  city: z.string(),
  state: z.string(),
  country: z.string(),
});

export type ISimpleTrialModalSchema = z.infer<typeof simpleTrialModalSchema>;
