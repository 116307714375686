import Button from "components/ui/Button/Button";
import styles from "./CadastrarTutorialPage.module.scss";
import { AddCircle } from "@material-ui/icons";
import TutorialHeader from "../components/tutorialHeader/TutorialHeader";
import UseDimension from "components/dimension/UseDimension";
import TutorialItem from "../components/tutorialItem/TutorialItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import TutorialItemMobile from "../components/tutorialItemMobile/TutorialItemMobile";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { IGetTutoriaisParams } from "modules/tutoriais/domain/dto/IGetTutoriaisParams";
import UseTutorialPage from "modules/tutoriais/presentation/hooks/UseTutorialPage";
import { MenuItem, TextField } from "@material-ui/core";
import { Pagination } from "@mui/material";
import SidesheetTutoriais from "../components/sidesheetTutoriais/SidesheetTutoriais";

const CadastrarTutorialPage = () => {
  const [openSidesheet, setOpenSidesheet] = useState<
      "isNew" | "isEditing" | "isDeleting" | null
  >(null);
  const [editAndDeleteItem, setEditAndDeleteItem] = useState<ITutoriaisRecords>({} as ITutoriaisRecords);
  const [params, setParams] = useState<IGetTutoriaisParams>(
    {} as IGetTutoriaisParams);
    const [pageSize, setPageSize] = useState<string>("10");

  const { dimensions } = UseDimension();
  const { tutoriaisList: getTutoriaisListRes, totalPages, currentPage, nextPage, getTutoriaisList} = UseTutorialPage();

  useEffect(() => {
    getTutoriaisList(currentPage, params, Number(pageSize));
  }, [getTutoriaisList, params, currentPage, pageSize]);

  const onCloseSideSheet = useCallback(() => {
    setOpenSidesheet(null);
    getTutoriaisList(0, {}, Number(pageSize));
  }, [setOpenSidesheet, getTutoriaisList, pageSize]);  

  const tutoriaisList = useMemo(() => getTutoriaisListRes ?? [], [getTutoriaisListRes]);

  return (
    <div id={styles.CadastrarTutorialPage}>
      {dimensions.width < 700 && <h1>Tutorial</h1>}
      <Button
        className={styles.btnAdd}
        endIcon={<AddCircle />}
        onClick={() => setOpenSidesheet("isNew")}
        style={{ width: dimensions.width < 700 ? "100%" : "180px" }}
        >
        Adicionar tutorial
      </Button>
      {dimensions.width > 700 && <h1>Tutorial</h1>}
      <div>
        {dimensions.width > 700 && <TutorialHeader params={params} setParams={setParams} />}
        {tutoriaisList?.map((item) => {
            return (
                <>
                {dimensions.width > 700 ? (
                    <TutorialItem
                      item={item}
                      setOpenSidesheet={setOpenSidesheet}
                      setEditAndDeleteItem={setEditAndDeleteItem}
                    />
                ) : (
                    <TutorialItemMobile
                      item={item}
                      setOpenSidesheet={setOpenSidesheet}
                      setEditAndDeleteItem={setEditAndDeleteItem}
                    />
                )}
                </>
            );
        })}
        {!!totalPages && (
          <div className={styles.conteinerPagination}>
            <div className={styles.boxMultiplePage}>
              <span>Exibir</span>
              <TextField
                variant="outlined"
                size="small"
                select
                value={pageSize}
                defaultValue={pageSize}
                onChange={(ev) => setPageSize(ev.target.value)}
              >
                <MenuItem className={styles.textPage} value={10}>
                  10
                </MenuItem>
                <MenuItem className={styles.textPage} value={20}>
                  20
                </MenuItem>
                <MenuItem className={styles.textPage} value={50}>
                  50
                </MenuItem>
              </TextField>
            </div>
            {totalPages > 0 && (
              <Pagination
                variant="outlined"
                className={styles.paginationNumber}
                shape="rounded"
                sx={{
                  ".MuiPaginationItem-root": {
                    borderColor: "#BDBDBD",
                    color: "#001537",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    borderColor: "#001537",
                  },
                }}
                count={totalPages}
                page={currentPage + 1}
                onChange={(_, value) => nextPage(value - 1, params, Number(pageSize))}
              />
            )}
          </div>
        )}
      </div>

      {openSidesheet && (
        <SidesheetTutoriais 
          open={openSidesheet}
          onCloseSideSheet={onCloseSideSheet}
          item={editAndDeleteItem}
        />
      )}
    </div>
  );
};

export default CadastrarTutorialPage;
