import { IGetTurnoverTableResponse } from "modules/salesDashboard/domain/dtos/turnoverTable/IGetTurnoverTableResponse";
import { faker } from '@faker-js/faker';
import { IRequestParams } from "modules/salesDashboard/domain/dtos/RequestParams";
import { IGetLocalResumeResponse } from "modules/salesDashboard/domain/dtos/localResume/IGetLocalResumeResponse";
import { ILocal } from "modules/local/domain/models/ILocal";
import { variedColors } from "components/graphs/utils/colors";
import { IGetRankingClientsResponse } from "modules/salesDashboard/domain/dtos/rankingClients/IGetRankingClientsResponse";
import { IGradientTableResponse } from "modules/dashboard/domain/dto/gradientTable/IGradientTable.type";

export enum SalesDashboardMockTypes {
  GET_GIRO_MESA = 'getTurnoverTableMock',
  GET_LOCAL_RESUME = 'getLocalResumeMock',
  GET_RANKING_CLIENTS = 'getRankingClientsMock',
  GET_GRADIENT_TABLE = 'getGradientTableMock',
}

const requestMockDelay = 1500; // 1 segundo e meio
const wait = () => new Promise(res => setTimeout(res, requestMockDelay));

export const SalesDashboardMock = () => {
  const localsDataString = localStorage.getItem('@locals')
  const locals: ILocal[] = localsDataString ? JSON.parse(localsDataString) : [];

  const getLocalColor = (selectedLocals: string[], localId: string) => {
    return variedColors[selectedLocals.indexOf(localId) || 0];
  }

  const makeMock = async <T>(mockCallback: () => T): Promise<T> => {
    await wait();

    const result = mockCallback();
    return result;
  }

  const getTurnoverTableMock = (request: IRequestParams): IGetTurnoverTableResponse => {
    const totalRecords = 100; //Number(faker.random.numeric(2));

    const selectedLocalsDataString = localStorage.getItem('@dashboardSelectedLocals')
    const selectedLocals = selectedLocalsDataString ? JSON.parse(selectedLocalsDataString).map((x: string) => x) : [];

    const result = {
      placeId: faker.datatype.uuid(),
      totalRecords,
      color: getLocalColor(selectedLocals, request.selectedPlaces[0]),
      average: Number(faker.random.numeric(2)),
      records: (() => {
        let arr = [];
        for (let i = 0; i < totalRecords; i++) {
          arr.push({
            tableId: faker.datatype.uuid(),
            number: (i + 1).toString(),
            turn: Number(faker.random.numeric(2)),
          });
        }
        return arr
      })(),
    };

    result.records = result.records
      .slice((request.page - 1) * request.pageSize, request.pageSize * request.page)
      .sort((current, next) => {
        if (request.sort?.orientation === 'asc')
          return Number(current.number) > Number(next.number) ? 1 : -1
        else
          return Number(current.number) > Number(next.number) ? -1 : -1
      });

    return result;
  }

  const getLocalResumeMock = (request: IRequestParams): IGetLocalResumeResponse => {
    const result: IGetLocalResumeResponse = {
      received: {
        consumed: Number(faker.random.numeric(4)),
        received: Number(faker.random.numeric(4)),
        serviceFee: Number(faker.random.numeric(4)),
        counterSales: Number(faker.random.numeric(4)),
        discounts: Number(faker.random.numeric(4)),
        customerConsumption: Number(faker.random.numeric(4)),
      },
      values: {
        consumed: Number(faker.random.numeric(4)),
        opened: Number(faker.random.numeric(4)),
      },
      averageTickets: {
        tables: Number(faker.random.numeric(4)),
        consumedTable: Number(faker.random.numeric(4)),
        customers: Number(faker.random.numeric(4)),
        consumedCustomers: Number(faker.random.numeric(4)),
      },
      totalRecords: 100,
      records: request.selectedPlaces.map(item => ({
        placeId: item,
        name: locals?.find(x => x.id === item)?.name || '',
        color: getLocalColor(request.selectedPlaces, item),
        received: Number(faker.random.numeric(4)),
        consumed: Number(faker.random.numeric(4)),
        openedConsume: Number(faker.random.numeric(3)),
        tables: Number(faker.random.numeric(2)),
        customers: Number(faker.random.numeric(3)),
        averageConsumePerTable: Number(faker.random.numeric(3)),
        averageConsumePerCustomer: Number(faker.random.numeric(2)),
        turnoverTable: Number(faker.random.numeric(2)),
        openedTable: Number(faker.random.numeric(2))
      }))
    };

    return result;
  }

  const getRankingClientsMock = (request: IRequestParams): IGetRankingClientsResponse => {

    const selectedLocalsDataString = localStorage.getItem('@dashboardSelectedLocals')
    const selectedLocals = selectedLocalsDataString ? JSON.parse(selectedLocalsDataString).map((x: string) => x) : [];
    const totalRecords = selectedLocals.length * 15;

    const result: IGetRankingClientsResponse = {
      totalRecords,
      records: (() => {
        let arr = [];

        for (let i = 0; i < selectedLocals.length; i++) {
          for (let j = 0; j < totalRecords / selectedLocals.length; j++) {
            arr.push({
              placeId: selectedLocals[i],
              placeName: locals?.find(x => x.id === selectedLocals[i])?.name || '',
              color: getLocalColor(request.selectedPlaces, selectedLocals[i]),
              customerId: faker.datatype.uuid(),
              customerName: faker.name.fullName(),
              rank: Number(faker.random.numeric(2)),
              rankPlace: Number(faker.random.numeric(2)),
              imgUrl: 'https://i.pravatar.cc/300',
              totalConsumption: Number(faker.finance.amount(7, 10))
            });
          }
        }
        return arr
      })(),
    };

    result.records = result.records.slice((request.page - 1) * request.pageSize, request.pageSize * request.page)

    return result;
  }

  const getGradientTableMock = (requset: IRequestParams): IGradientTableResponse => {
    const selectedLocalsDataString = localStorage.getItem('@dashboardSelectedLocals')
    const selectedLocals = selectedLocalsDataString ? JSON.parse(selectedLocalsDataString).map((x: string) => x) : [];


    const result: IGradientTableResponse = {
      equipments: ['POS', 'Balcão', 'Tablet', 'Totem', 'Online'],
      paymentForm: [
        'Crédito',
        'Débito',
        'Dinheiro',
        'Cheque',
        'Voucher',
        'Comandado',
      ],
      // places: selectedLocals.map((x: string) => ({ id: x, name: locals?.find(y => y.id === x)?.name || '' })),
      places: [
        {
          id: 'place001',
          name: 'Savassi',
        },
        {
          id: 'place002',
          name: 'Savassi 2',
        },
      ],
      amount: [
        [
          [100.5, 351], // Crédito
          [200.9, 185], // Débito
          [75.6, 98], // Dinheiro
          [0, 456], // Cheque
          [25, 165], // Voucher
          [400, 484], // Comandado
        ],
        [
          [100.5, 351], // Crédito
          [200.9, 185], // Débito
          [75.6, 98], // Dinheiro
          [0, 456], // Cheque
          [25, 165], // Voucher
          [400, 484], // Comandado
        ],
        [
          [100.5, 351], // Crédito
          [200.9, 185], // Débito
          [75.6, 98], // Dinheiro
          [0, 456], // Cheque
          [25, 165], // Voucher
          [400, 484], // Comandado
        ],
        [
          [100.5, 351], // Crédito
          [200.9, 185], // Débito
          [75.6, 98], // Dinheiro
          [0, 456], // Cheque
          [25, 165], // Voucher
          [400, 484], // Comandado
        ],
        [
          [100.5, 351], // Crédito
          [200.9, 185], // Débito
          [75.6, 98], // Dinheiro
          [0, 456], // Cheque
          [25, 165], // Voucher
          [400, 484], // Comandado
        ],
      ],
    };

    return result;
  }

  return {
    makeMock,
    getTurnoverTableMock,
    getLocalResumeMock,
    getRankingClientsMock,
    getGradientTableMock,
  };
}
