import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CreateBaseListUseCase from "modules/events/application/useCases/baseList/CreateBaseListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { IDiscountListForm } from "../../components/discountsListForm/interfaces/DiscountListForm.types";

interface IUseAddDiscounts {
  handleSubmit: (list: IDiscountListForm) => Promise<void>;
}

const UseAddDiscounts = (onClickCloseHandle: () => void): IUseAddDiscounts => {
  const { currentLocal } = useLocal();
  const { toast, hideLoading, showLoading } = useUi();
  const baseListService = DiscountBaseListApi();

  const handleSubmit = async (list: IDiscountListForm) => {
    showLoading();
    if (currentLocal) {
      try {
        await CreateBaseListUseCase(baseListService, { ...list, localId: currentLocal?.id });
        onClickCloseHandle();
        toast("Lista adicionada com sucesso!", "success");
      } catch (error) {
        toast("Erro ao adicionar lista", "error");
      } finally {
        hideLoading();
      }
    }
  };

  return {
    handleSubmit,
  };
};

export default UseAddDiscounts;
