import { Button, CircularProgress } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import { IFilterValue } from "./IFilter";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ICashierItem } from "./ICashier";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { IGetCashiersResponse } from "modules/cashlessDashboard/domain/dto/getCashiers/IGetCashiersResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CashierSideSheet } from "pages/private/relatorio/components/OutputReport/components/cashiersSideSheet/cashiersSideSheet";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import { addDays, subDays } from "date-fns";

export interface IFilterProps {
  onSubmit: (value: IFilterValue) => void;
  cashiers: ICashierItem[];
  defaultValues?: IFilterValue;
  isLoading?: boolean;
  filterType: string;
  getCashiersByPeriod: any;
  setFilterType: (filter: string) => any;
}

const filterTypes = [
  {
    value: "cashier",
    description: "Caixa",
  },
  {
    value: "period",
    description: "Período",
  },
];

const Filter: FC<IFilterProps> = ({
  onSubmit,
  cashiers,
  defaultValues,
  filterType,
  getCashiersByPeriod,
  setFilterType,
  isLoading,
}) => {
  const { toast } = useUi();
  const { currentLocal } = useLocal();

  const [values, setValues] = useState<IFilterValue>({
    cashierIds: [],
  });

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [endMaxDate, setEndMaxDate] = useState<any>();
  const [cashiersByPeriod, setCashiersByPeriod] = useState([]);

  const [openCashierSideSheet, setOpenCashierSideSheet] = useState(false);

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues);
    }
  }, [defaultValues]);

  const submitHandle = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      if (filterType === "period") {
        const response = await getCashiersByPeriod(
          currentLocal?.id,
          startDate,
          endDate
        );

        setCashiersByPeriod(response);

        values.cashierIds = response;

        if (!values.cashierIds.length) {
          toast(
            "Não foram encontrados caixas para o período informado.",
            "error"
          );
          return;
        }

        onSubmit(values);
      } else {
        if (!values.cashierIds.length) {
          toast("Selecione pelo menos um caixa.", "error");
          return;
        }
        onSubmit(values);
      }
    },
    [
      currentLocal?.id,
      endDate,
      filterType,
      getCashiersByPeriod,
      onSubmit,
      startDate,
      toast,
      values,
    ]
  );

  const changeAutoCompleteSessionHandle = useCallback(
    (values?: ICashierItem[]) => {
      setValues((prev) => ({ ...prev, cashierIds: values || [] }));
    },
    []
  );

  const changeAutoCompleteStartDateHandle = (value: Date) => {
    if (!value) return;

    value.setHours(value.getHours() + 3);

    setStartDate(value);
    setValues((prev) => ({ ...prev, startDate: value }));
  };

  const changeAutoCompleteEndDateHandle = useCallback((value: Date) => {
    setEndDate(value);
    setValues((prev) => ({ ...prev, endDate: value }));
  }, []);

  const getSelectedSessionsCountLabel = (count: number) => {
    const firstCashier = cashiers.find((x) => x.id === values.cashierIds[0].id);

    return (
      <>
        <div className={styles.selectedCashierItem}>{firstCashier?.name}</div>
        {count > 1 && `+${count - 1} selecionados`}
      </>
    );
  };

  return (
    <div id={styles.Filter}>
      <form onSubmit={submitHandle}>
        <Autocomplete
          className={styles.input}
          style={{ flex: 2, minWidth: 350 }}
          size="small"
          disableClearable
          renderInput={(props) => (
            <div className={styles.inputContainer}>
              <span>
                Filtrar por{" "}
                <Tooltip
                  title="Escolha o período"
                  text={[
                    "Último caixa: os dados serão do último caixa (aberto ou fechado).",
                    "Selecionar caixa: os dados serão do(s) caixa(s) selecionado(s). É possível selecionar mais de um caixa (máx. 30).",
                    "Período de caixa: os dados serão do(s) caixa(s) fechados dentro do período em dias. É possível selecionar um período máximo de um mês (30 dias)."
                  ]}
                />
              </span>
              <TextField
                maxRows={1}
                label={undefined}
                name="filterType"
                {...props}
              />
            </div>
          )}
          isOptionEqualToValue={(option, values) =>
            option.value === values.value
          }
          options={filterTypes}
          getOptionLabel={(option) => option.description}
          onChange={(ev, value) => setFilterType(value.value)}
          value={filterTypes.find((x) => x.value === filterType)}
        />
        {filterType === "cashier" && (
          <Autocomplete
            className={styles.input}
            multiple
            style={{ flex: 2, minWidth: 350 }}
            size="small"
            renderTags={() =>
              getSelectedSessionsCountLabel(values.cashierIds?.length || 0)
            }
            renderInput={(props) => (
              <div className={styles.inputContainer}>
                <span>Filtrar por caixa</span>
                <TextField
                  maxRows={1}
                  placeholder={
                    (values.cashierIds.length ?? 0) > 0
                      ? ""
                      : "Selecione um ou mais caixas"
                  }
                  name={"session"}
                  {...props}
                  InputLabelProps={{ shrink: false }}
                />
              </div>
            )}
            renderOption={(props, option) => {
              return (
                <span {...props} key={option.id}>
                  {option.name}
                </span>
              );
            }}
            isOptionEqualToValue={(option, values) => option.id === values.id}
            options={cashiers}
            getOptionLabel={(option) => option.name}
            onChange={(ev, value) => changeAutoCompleteSessionHandle(value)}
            value={values.cashierIds}
          />
        )}
        {filterType === "period" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                value={startDate}
                disableFuture
                minDate={new Date("2017-01-01")}
                onChange={(ev) =>
                  changeAutoCompleteStartDateHandle(new Date(ev))
                }
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <span>Período - ínicio</span>

                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />

              <DesktopDatePicker
                value={endDate}
                minDate={startDate}
                maxDate={addDays(startDate, 30)}
                onChange={(ev) => {
                  changeAutoCompleteEndDateHandle(new Date(ev));
                }}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <span>Período - fim (Máximo 31 dias)</span>

                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
            </LocalizationProvider>
          </>
        )}
        <div className={styles.containerButton}>
          <Button
            className={styles.button}
            variant={"contained"}
            color={"primary"}
            disabled={isLoading || (filterType === "cashier" && !values.cashierIds.length) || (filterType === "period" && (endDate > addDays(startDate, 30) || endDate < subDays(startDate, 1)))}
            type={"submit"}
          >
            Buscar
            {isLoading && <CircularProgress />}
          </Button>
        </div>
      </form>
      <div className={styles.controls}>
        {filterType === "period" &&
          cashiersByPeriod?.length !== undefined &&
          cashiersByPeriod?.length > 0 && (
            <button
              type="button"
              onClick={() => setOpenCashierSideSheet(true)}
              // disabled={!data?.records.length}
            >
              Caixas do período filtrado
              <img
                className={styles.export}
                src="/assets/icon/arrow-right.png"
                alt=""
              />
            </button>
          )}
          {filterType === "cashier" &&
          values.cashierIds?.length > 1 && (
            <button
              type="button"
              onClick={() => setOpenCashierSideSheet(true)}
              // disabled={!data?.records.length}
            >
              Caixas filtrados
              <img
                className={styles.export}
                src="/assets/icon/arrow-right.png"
                alt=""
              />
            </button>
          )}
      </div>
      <CashierSideSheet
        filterType={filterType}
        open={openCashierSideSheet}
        onClose={() => setOpenCashierSideSheet(false)}
        cashiers={cashiersByPeriod}
        cashiersFilter={values.cashierIds}
      />
    </div>
  );
};
export default Filter;
