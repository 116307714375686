import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { ITotalReport } from "modules/crm/presentation/intefaces/TotalReport"
import { ChannelEnum } from "modules/crm/presentation/interfaces/ChannelEnum"

const GetTotalReportUseCase = async (service: ICrmService, localId: string, channel?: ChannelEnum):Promise<ITotalReport[]> => {
    let response = await service.getTotalReport(localId);

    if (channel) {
      response = response.filter(x => x.channel === channel);
    }

    return response
}

export default GetTotalReportUseCase;
