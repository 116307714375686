import { useCallback, useState } from "react";
import CadastrarTutorialApi from "services/api/cadastrarTutorial/CadastrarTutorialApi";
import PostCadastrarTutorialUseCase from "modules/cadastrarTutorial/application/useCases/PostCadastrarTutorialUseCase";
import { IPostNewWizard } from "modules/cadastrarTutorial/domain/dto/IPostNewWizard";
import PutEditTutorialUseCase from "modules/cadastrarTutorial/application/useCases/PutEditTutorialUseCase";
import { IPutEditWizard } from "modules/cadastrarTutorial/domain/dto/IPutEditWizard";
import DeleteTutorialUseCase from "modules/cadastrarTutorial/application/useCases/DeleteTutorialUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const tutorialApi = CadastrarTutorialApi();

const UseCadastrarTutorialPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { toast } = useUi();

  const postNewTutorial = useCallback(
    async (request: IPostNewWizard) => {
      try {
        setIsLoading(true);
        await PostCadastrarTutorialUseCase(tutorialApi, request);
        return "ok";
      } catch (error: any) {
        if (error.response?.data.errors.DuplicatedEntity[0]) {
          toast(error.response?.data.errors.DuplicatedEntity[0], "error");
        }
      } finally {
        setIsLoading(false);
      }
    },
    [toast]
  );

  const putEditTutorial = useCallback(
    async (request: IPutEditWizard) => {
      try {
        setIsLoading(true);
        await PutEditTutorialUseCase(tutorialApi, request);
        return "ok";
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const deleteTutorial = useCallback(
    async (tutorialId: string) => {
      try {
        setIsLoading(true);
        await DeleteTutorialUseCase(tutorialApi, tutorialId);
        return "ok";
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    postNewTutorial,
    putEditTutorial,
    deleteTutorial,
  };
};

export default UseCadastrarTutorialPage;
