import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { FC, useState } from "react";
import styles from "./InputSearch.module.scss";

interface InputSearchProps {
    onChange?: (value: string | number) => void;
    className?: string;
    placeholder?: string;
    autoSearch?: boolean;
}

const InputSearch: FC<InputSearchProps> = ({
    onChange,
    className,
    placeholder = "Pesquisar",
    autoSearch,
}) => {
    const [inputValue, setInputValue] = useState<string | number>("");

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInputValue(event.target.value);
        if (autoSearch) {
            onChange && onChange(event.target.value);
        }
    };

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onChange && onChange(inputValue);
    };

    return (
        <form id={styles.InputSearch} className={className} onSubmit={handleSearch}>
            <input
                placeholder={placeholder}
                onChange={handleChange}
                value={inputValue}
            />
            <IconButton type="submit">
                <SearchIcon className={styles.searchIcon} />
            </IconButton>
        </form>
    );
};

export default InputSearch;
