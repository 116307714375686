import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { AuthApi } from "services/api/auth/AuthApi";
import { useHistory, useLocation } from "react-router-dom";
import GetUserInfoUseCase from "modules/auth/application/useCases/GetUserInfoUseCase";
import { AuthPageValidation, IAuthPageFormErrors } from "./validate/AuthPageValidation";
import { useQuery } from "hooks/UseQuery";
import CryptoJS from "crypto-js";
import { cryptPassIv, cryptPassKey } from "Enviroment";
import { IAuthResponse } from "modules/auth/models/dto/IAuthResponse";

export interface IAuthPageRequest {
  userName: string;
  password: string;
}

const authApi = AuthApi();

export const UseAuthPage = () => {
  const { showLoading, hideLoading, toast } = useUi();
  const { saveAuth: setAuth } = useAuth();
  const { replace, push } = useHistory();
  const [requiresMFA, setRequiresMFA] = useState(false);
  const query = useQuery();
  const location = useLocation<{ crypt: string }>();
  const [values, setValues] = useState<IAuthPageRequest>({} as IAuthPageRequest);
  const [errors, setErrors] = useState<IAuthPageFormErrors>({});
  const [username, setUsername] = useState("");

  const redirectSuccessPage = useCallback(async (response: IAuthResponse) => {
    const useInfoResponse = await GetUserInfoUseCase(
      authApi,
      response.access_token,
    );
    setAuth({ ...response, locals: useInfoResponse.locals });
    const lastPage = localStorage.getItem("@lastPage");
    if (lastPage !== null && lastPage !== undefined && lastPage !== "/login" && lastPage !== "/signin" && lastPage !== "/") {
      replace(lastPage);
    } else {
      replace("/private");
    }
  }, [replace, setAuth]);

  const onClickLogin = useCallback(
    async (userName: string, password: string) => {
      try {
        showLoading();
        const response = await authApi.loginMfa(userName, password);
        if (response.requiredMfa) {
          setRequiresMFA(true);
          setUsername(userName);
        } else {
          redirectSuccessPage(response);
        } 
      } catch (error: any) {
        toast("Credenciais incorretas. Verifique o nome de usuário e a senha", "error");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, redirectSuccessPage, showLoading, toast]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (AuthPageValidation(values, setErrors)) {
        onClickLogin(values.userName, values.password);
      }
    },
    [values, onClickLogin]
  );

  const onClickForgotPassword = useCallback(() => {
    push("login/esqueciSenha");
  }, [push]);

  const onClickSignup = useCallback(() => {
    push("login/cadastro");
  }, [push]);

  const changeHandle = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  useEffect(() => {
    query.get('email') && setValues((prev) => ({...prev, userName: query.get('email') as string}));
  }, [query]);

  const getSHA256 = (key: string) => {
    if (!key) {
      return null;
    }
    const hash = CryptoJS.SHA256(key);
    return hash.toString(CryptoJS.enc.Hex);
  }

  const getSHA128 = (iv: string) => {
    if (!iv) {
      return null;
    }
    const hash = CryptoJS.SHA256(iv);
    return hash.toString(CryptoJS.enc.Hex).slice(0, 32);
  }

  const validadeMfaCodeHandle = useCallback(async (code: string, userName: string) => {
    if (code.length !== 6) {
      setErrors({...errors, code: "Por favor, insira o código completo."});
      return;
    }
    try {
      setErrors({...errors, code: undefined});
      const response = await authApi.validateMfaCode(userName, code.toUpperCase());
      redirectSuccessPage(response)
    } catch (e) {
      setErrors({...errors, code: "Erro ao validar o código. Tente novamente."});
    }
  }, [errors, redirectSuccessPage]);

  const resendMfaCodeHandle = useCallback(async () => {
    try {
      await authApi.resendMfaCode(username);
      setErrors({...errors, code: undefined});
    } catch {
      setErrors({...errors, code: "Erro ao reenviar o código. Tente novamente."});
    }
  }, [errors, username]);

  useEffect(() => {
    const encryptedPass = decodeURIComponent(location.state?.crypt ?? "");
    
    if (encryptedPass) {
      try {
        const keyHex = getSHA256(cryptPassKey ?? "");
        const ivHex = getSHA128(cryptPassIv ?? "");

        if (keyHex && ivHex) {
          const decrypted = CryptoJS.AES.decrypt(
            encryptedPass,
            CryptoJS.enc.Hex.parse(keyHex),
            { iv: CryptoJS.enc.Hex.parse(ivHex), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
          );
    
          const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);
    
          setValues((prev) => ({...prev, password: decryptedMessage}));
        }
      } catch (error) {
        console.error("Erro ao descriptografar:", error);
      }
    }
  }, [location.state?.crypt, query]);

  return {
    onClickLogin,
    values,
    submitHandle,
    changeHandle,
    onClickForgotPassword,
    onClickSignup,
    errors,
    setErrors,
    setRequiresMFA,
    requiresMFA,
    validadeMfaCodeHandle,
    resendMfaCodeHandle,
    username,
  };
};
