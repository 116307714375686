import React, { FC, useMemo } from "react";
import styles from "./AprovacaoTIDs.module.scss";
import { UseAprovacaoTIDs } from "./UseAprovacaoTIDs";
import { Skeleton } from "@mui/material";
import AprovacaoTIDsSideSheet from "../components/aprovacaoTIDsSideSheet/AprovacaoTIDsSideSheet";
import TIDsList from "../components/tidsList/TIDsList";
import AprovacaoTIDsHeader from "../components/aprovacaoTIDsHeader/AprovacaoTIDsHeader";

export interface IAprovacaoTIDs {

}

const AprovacaoTIDsPage: FC<IAprovacaoTIDs> = ({}) => {

  const {
    isMobile,
    sideSheetRef,
    columns,
    openSidesheet,
    isLoading,
    tidsList,
    getTIDsList
  } = UseAprovacaoTIDs();

  const loadAnimation = useMemo(
    () =>
      isMobile ? (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
          <Skeleton variant="rectangular" width="100%" height={240} />
        </div>
      ) : (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
        </div>
      ),
    [isMobile]
  );

  return (
    <div id={styles.AprovacaoTIDs}>
      {isLoading ? (
        <>{loadAnimation}</>
      ) : !tidsList ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            <b>Falha ao obter</b> solicitações TIDs.
          </span>
          <span>Tente novamente!</span>
        </div>
      ) : tidsList?.length === 0 ? (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt="Empty box" />
          <span>
            Você não possui <b>solicitações TIDs</b> recentes!
          </span>
        </div>
      ) : (
        <>
          <AprovacaoTIDsHeader columns={columns} />
          <TIDsList
            columns={columns}
            tidsList={tidsList}
            openSidesheet={openSidesheet}
          />
        </>
      )}

      <AprovacaoTIDsSideSheet ref={sideSheetRef} getTIDsList={getTIDsList} />
    </div>
  )
}

export default AprovacaoTIDsPage;