import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import DeleteEventUseCase from 'modules/events/application/useCases/events/DeleteEventUseCase';
import EditEventUseCase from 'modules/events/application/useCases/events/EditEventUseCase';
import { IEditEventRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IEvents, IEventsForm } from 'modules/events/domain/models/IEvents';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { EventsApi } from 'services/api/events/events/EventsApi';
import Utils from 'services/utils/Utils';

const parseIEventToIEventForm = (event: IEvents) => {
  const eventForm: IEventsForm = {
    ...event,
    startDateString: Utils.toInputDateString(
      event.openingDates[0].startDate,
    ),
    endDateString: Utils.toInputDateString(
      event.openingDates[0].endDate,
    ),
    startSaleDate: Utils.toInputDateString(
      event.availableForSalesStart,
    ),
    endSaleDate: Utils.toInputDateString(event.availableForSalesEnd),
    address: undefined,
    attractionName: event.attractions.length ? event.attractions[0].name : '',
  };
  return eventForm;
};

const UseManageHeader = (refreshEvent: () => Promise<void>) => {

  const eventsService = EventsApi();
  const { currentLocal } = useLocal();
  const { toast, hideLoading, showLoading } = useUi();
  const [openEdit, setOpenEdit] = useState(false);
  const { goBack } = useHistory();
  const [openDelete, setOpenDelete] = useState(false);
  const { push } = useHistory();

  const closeEditModal = useCallback(() => {
    setOpenEdit(false);
  }, []);

  const openEditModal = useCallback(() => {
    setOpenEdit(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setOpenDelete(false);
  }, []);

  const openDeleteModal = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const goToCloseEvent = (eventId: string) => {
    return push(`/private/event/eventos/fechamento/${eventId}`);
  };

  const handleSubmitEdit = useCallback(
    async (event: IEventsForm) => {
      const eventsService = EventsApi();
      if (currentLocal && event.id) {
        showLoading();
        try {
          const eventToEdit: IEditEventRequest = {
            id: event.id,
            name: event.name,
            imageUrl: event.imageUrl,
            availableForSalesStart: new Date(event.startSaleDate).toISOString(),
            availableForSalesEnd: new Date(event.endSaleDate).toISOString(),
            description: event.description,
            openingDates: [
              {
                startDate: new Date(event.startDateString).toISOString(),
                endDate: new Date(event.endDateString).toISOString(),
              },
            ],
            attractions: event.attractions,
          };

          await EditEventUseCase(eventsService, eventToEdit, currentLocal.id);
          toast('Evento editado com sucesso!', 'success');
          refreshEvent();
          closeEditModal();
        } finally {
          hideLoading();
        }
      }
    },
    [closeEditModal, currentLocal, hideLoading, refreshEvent, showLoading, toast],
  );

  const deleteEvent = async (eventId: string) => {
    if (!currentLocal) return;

    showLoading();
    try {
      await DeleteEventUseCase(eventsService, eventId, currentLocal.id);
      closeDeleteModal();
      goBack();
    } catch (error) {
      // console.error(error);
    } finally {
      hideLoading();
      // goBack();
    }
  };

  return {
    openEdit,
    closeEditModal,
    openEditModal,
    handleSubmitEdit,
    parseIEventToIEventForm,
    openDelete,
    closeDeleteModal,
    openDeleteModal,
    deleteEvent,
    goToCloseEvent
  }
}

export default UseManageHeader;