
import { IGetLocalResumeConfigurationResponse } from 'modules/local/domain/dtos/IGetLocalResumeResponse';
import { IlocalService } from 'modules/local/domain/interfaces/IlocalService';
import { IProductsService } from 'modules/products/domain/interfaces/IProductsService';

const GetLocaLConfigurationUseCase = async (
  service: IProductsService,
  localId: string,
): Promise<IGetLocalResumeConfigurationResponse> => {
  const result = await service.getLocalConfiguration(localId);
  return result;
};

export default GetLocaLConfigurationUseCase;