import React, { ChangeEvent, FC, useCallback } from 'react';
import { InputLabel } from './InputLabel';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import { TextFieldProps } from '@mui/material';


export interface InputNumberProps extends Omit<TextFieldProps, keyof { onChange: any; value: number; }> {
    flex?: number;
    maxWidth?: number | string;
    onChange?: (ev: ChangeEvent<HTMLInputElement>, value: number) => void;
    value?: number;
    gap?: number | string;
    styledSize?: boolean;
}
export const InputMoney: FC<InputNumberProps> = (props) => {

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const value = moneyToFloat(ev.target.value);
        const target = ev.target;
        const newEv: ChangeEvent<HTMLInputElement> = { ...ev, target: { ...target, name: target.name, value: value.toFixed(2) } };
        props.onChange?.(newEv, value);
    }, [props]);

    return <>
        <InputLabel {...props} value={moneyMaskNumber(props?.value ? Number(props?.value) : 0)} onChange={changeHandle} styledSize={props.styledSize} />
    </>;
};
