import { ICashlessVoucherResponse, IPaymentMethod } from 'modules/events/domain/dto/IEventsResponse';
import { ICashlessVoucerApi } from '../../interfaces/ICashlessVoucherService';

const GetPaymentMethodUseCase = async (
  cashlessVoucherService: ICashlessVoucerApi,
  localId: string,
): Promise<IPaymentMethod[]> => {
  return await cashlessVoucherService.getPaymentMethod(localId);
};

export default GetPaymentMethodUseCase;