import Layout from "components/layout/presentation/Layout";
import DiscountsListPage from "modules/events/presentation/pages/discountsList/DiscountsListPage";
import EventsPage from "modules/events/presentation/pages/EventsPage";
import TabPage from "../dashboard/tabPage/TabPage";

function Events() {
  // const {tab} = useParams<{tab: string}>()
  return (
    <Layout>
      <>
        {/* <EventsPage /> */}

        <TabPage
          hideTabs
          tabsPages={[
            {
              label: "Eventos",
              tab: "eventos",
              component: <EventsPage />,
            },
            {
              label: "Descontos",
              tab: "descontos",
              component: <DiscountsListPage />,
            },
          ]}
          routerPrefix={"/private/eventos/list"}
        />
      </>
    </Layout>
  );
}

export default Events;
