import { Button, IconButton, TextField } from '@material-ui/core';
import { Search } from '@mui/icons-material';
import { Checkbox, Icon, InputAdornment, Switch } from '@mui/material';
import UseDeviceList from 'modules/config/discountCoupons/presentation/hooks/UseDeviceList';
import { ICouponForm } from 'modules/config/discountCoupons/presentation/interfaces/ICouponForm';
import { IDevices } from 'modules/config/discountCoupons/presentation/interfaces/IDiscountCouponItem';
import React, { useCallback, useEffect, useState } from 'react'
import { EnumCouponStep } from '../../../CouponForm';
import { EnumDeviceType, IDeviceItem } from '../interfaces/IDeviceItem';
import { DeviceCard } from './components/deviceCard/DeviceCard';
import styles from './DeviceItem.module.scss'

export interface IDeviceItemProps {
    closeSideSheetDevice: () => void;
    setValues: React.Dispatch<React.SetStateAction<ICouponForm>>
    values: ICouponForm
    onSubmitAddCoupon: (values: ICouponForm) => void
    onChangeFormStep: (value: EnumCouponStep) => void
    isLoading: boolean | undefined
    isEdit: boolean

}

export const DeviceItem: React.FC<IDeviceItemProps> = ({ closeSideSheetDevice, setValues, onSubmitAddCoupon, values, onChangeFormStep, isLoading, isEdit }) => {


    const { getDeviceList, deviceList, setDeviceList } = UseDeviceList()

    const [searchDevice, setSearchDevice] = useState('')
    const [selectedTypes, setSelectedTypes] = useState<number[]>(values.deviceTypes)


    const [deviceListFiltered, setDeviceListFiltered] = useState<IDeviceItem[]>([]);

    useEffect(() => {
        if (selectedTypes.length) {
            getDeviceList(selectedTypes);


        }

    }, [getDeviceList, isEdit, selectedTypes]);  


    const handleTypesChange = (value: number, checked: boolean) => {

        const valueNumber = Number(value)

        if (checked) {
            setSelectedTypes([...selectedTypes, valueNumber]);
            setValues(prev => ({ ...prev, deviceTypes: [...values.deviceTypes, valueNumber] }));
        } else {
            setSelectedTypes(selectedTypes.filter(type => type !== valueNumber));
            setValues(prev => ({
                ...prev,
                deviceTypes: values.deviceTypes.filter(item => item !== valueNumber),
                devices: prev.devices.filter(item => item.deviceType !== valueNumber )
            }));

        }
    }

    const handleDeviceChange = useCallback((value: IDevices, checked: boolean) => {
        

            if (checked) {
                setValues(prev => ({ ...prev, devices: [...values.devices, value] }));
            } else {
                setValues(prev => ({ ...prev, devices: values.devices.filter(item => item.deviceId !== value.deviceId) }));
            }
        
    }, [setValues, values.devices]);


    const onCheckedAll = useCallback((checked: boolean) => {
        if (checked) {
            setValues(prev => ({ ...prev, devices: deviceListFiltered.map(item => ({deviceId: item.id, deviceType: Number(item.type), name: item.name})) }));
        } else {
            setValues(prev => ({ ...prev, devices: [] }));
        }
    }, [deviceListFiltered, setValues])


    const onSubmit = useCallback(() => {

        onSubmitAddCoupon?.(values);

    }, [onSubmitAddCoupon, values])


    useEffect(() => {
        if (searchDevice) {
            setDeviceListFiltered(deviceList?.filter((x) => x.name?.toLowerCase().includes(searchDevice.toLowerCase())));
        } else {
            setDeviceListFiltered(deviceList);
        }
    }, [deviceList, searchDevice, setDeviceList, setDeviceListFiltered]);


    return (
        <div id={styles.DeviceItem}>
            <div>

                <div className={styles.titleTop}>
                    <h2>{isEdit ? "Editar" : "Novo"}<b> cupom de desconto</b></h2>
                    <IconButton size='small' onClick={closeSideSheetDevice}><Icon>close</Icon></IconButton>
                </div>

                <div className={styles.content}>
                    <label>Uso do cupom</label>
                    <div className={styles.containerCheckbox}>
                        {/* <div>
                            <Checkbox onChange={(ev) => handleTypesChange(EnumDeviceType.TOTEM, ev.target.checked)} checked={!!values.deviceTypes.find(item => item === EnumDeviceType.TOTEM)} color="secondary" />
                            <p>
                                Totem
                            </p>
                        </div>
                        <div>
                            <Checkbox onChange={(ev) => handleTypesChange(EnumDeviceType.POS, ev.target.checked)} checked={!!values.deviceTypes.find(item => item === EnumDeviceType.POS)} color="secondary" />
                            <p>
                                POS
                            </p>
                        </div> */}
                        <div>
                            <Checkbox onChange={(ev) => handleTypesChange(EnumDeviceType.APP, ev.target.checked)} checked={!!values.deviceTypes.find(item => item === EnumDeviceType.APP)} color="secondary" />
                            <p>
                                App
                            </p>
                        </div>
                        <div>
                            <Checkbox onChange={(ev) => handleTypesChange(EnumDeviceType.WEBAPP, ev.target.checked)} checked={!!values.deviceTypes.find(item => item === EnumDeviceType.WEBAPP)} color="secondary" />
                            <p>
                                Webapp
                            </p>
                        </div>
                    </div>
                    {/* <TextField
                        className={styles.search}
                        variant="outlined"
                        fullWidth
                        size='small'
                        label="Procurar equipamentos"
                        onChange={(e) => setSearchDevice(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Search /></InputAdornment>,
                        }}
                    /> */}
                </div>
                {
                    !!deviceListFiltered.length &&
                    <div className={styles.selectedAll}>
                        <span> Selecionar todos </span>  <Switch color='secondary' checked={!!(values.devices.length === deviceListFiltered.length)} onChange={(ev) => onCheckedAll(ev.target.checked)} />
                    </div>
                }
                <div className={styles.contentList}>
                    {

                        deviceListFiltered.map((item, index) => {
                            return (
                                <DeviceCard
                                    key={index}
                                    item={item}
                                    handleDeviceChange={handleDeviceChange}
                                    checked={!!values.devices.find(_item => _item.deviceId === item.id)}
                                    values={values}
                                    setValues={setValues}
                                />
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className={styles.containerButton}>
                <Button variant="outlined" className={styles.outlined} onClick={closeSideSheetDevice}>Voltar</Button>
                <Button variant="contained" className={styles.contained} onClick={onSubmit}>{isEdit ? "Editar" : "Criar"}</Button>
            </div>

        </div>
    )
}
