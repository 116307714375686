import { IDiscountBaseList } from '../../interfaces/IDiscountBaseListService';

const DeleteBaseListUseCase = async (
  baseListService: IDiscountBaseList,
  baseListId: string,
  localId: string,
): Promise<void> => {
  return await baseListService.deleteBaseList(baseListId, localId);
};

export default DeleteBaseListUseCase;
