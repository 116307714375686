import { Drawer } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';

import styles from './ConfirmOrderAction.module.scss';
import FooterButtons from 'modules/config/deviceConfig/presentation/components/footerButtons/FooterButtons';
import RenderIf from 'components/renderIf/RenderIf';
import Input from "components/ui/input/Input";

const VALID_EMAIL_REGEX = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

export interface IConfirmOrderActionProps {
    isOpen: boolean;
    actionType: string;
    cancelFn: () => void;
    confirmFn: (email?: string) => void;
}
const ConfirmOrderAction: FC<IConfirmOrderActionProps> = ({ isOpen, actionType, cancelFn, confirmFn }) => {
    const [confirmTexts, setConfirmTexts] = useState({ title: '', message: '' });
    const [email, setEmail] = useState({ value: '', hasError: false });

    useEffect(() => {
        setConfirmTexts(confirmTextsHandler(actionType));
    }, [actionType]);

    const confirmTextsHandler = (type: string) => {
        switch (type) {
            case 'RESEND':
                return {
                    title: 'reemissão de NFC-e',
                    message: 'Ao confirmar a NFC-e será reemitida, deseja continuar?',
                }
            case 'UPDATE':
                return {
                    title: 'Atualização e reenvio de NFC-e',
                    message: 'Ao confirmar a NFC-e será atualizada e reenviada, deseja continuar?',
                }
            case 'SEND_EMAIL':
                return {
                    title: 'envio por e-mail',
                    message: 'Preencha o e-mail para qual vai enviar a NFC-e ou apenas clique em confirmar para enviar ao e-mail cadastrado no local.',
                }
            case 'CANCEL':
                return {
                    title: 'cancelamento da NFC-e',
                    message: 'Ao confirmar a NFC-e será cancelada, deseja continuar?',
                }
            default:
                return {
                    title: '',
                    message: '',
                }
        }
    }

    const confirmAction = () => {
        if (actionType === 'SEND_EMAIL') {
            validateBeforeConfirm();
            return;
        }

        confirmFn();
    }

    const validateBeforeConfirm = () => {
        if (email.value.length > 0 && !email.value.match(VALID_EMAIL_REGEX)) {
            setEmail(prev => ({ ...prev, hasError: true }));
            return;
        }

        confirmFn(email.value);
    }

    return (
        <Drawer anchor='right' open={isOpen}>
            <div className={styles.container}>
                <div className={styles.containerTitle}>
                    <h2>Confirmar <span>{confirmTexts.title}</span></h2>
                    <Close onClick={cancelFn} />
                </div>

                <p>{confirmTexts.message}</p>

                <RenderIf condition={actionType === 'SEND_EMAIL'}>
                    <div className={styles.emailWrapper}>
                        <label htmlFor="email">E-mail</label>
                        <Input
                            name={"email"}
                            variant={"outlined"}
                            value={email.value}
                            placeholder="email@email.com"
                            onChange={(ev) => setEmail({ value: ev.target.value, hasError: false })}
                            error={email.hasError}
                        />

                        <RenderIf condition={email.hasError}>
                            <span className={styles.emailError}>Digite um e-mail válido para continuar</span>
                        </RenderIf>
                    </div>
                </RenderIf>

                <FooterButtons
                    onClickBack={cancelFn}
                    onClickNext={confirmAction}
                    labelBack={'Cancelar'}
                    labelNext={'Confirmar'}
                />
            </div>
        </Drawer>
    )
}
export default ConfirmOrderAction;