
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DiscountCouponsApi from "services/api/discountCoupons/DiscountCouponsApi";
import { AddCouponUseCase } from "../../application/useCases/AddCouponUseCase";
import { IAddCouponResponse } from "../../domain/dto/IAddCouponResponse";
import { ICouponForm } from "../interfaces/ICouponForm";


const UseAddCoupon = (onClose: () => void,) => {

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successAdd, setSuccessAdd] = useState(false)
  const [couponIdResponse, setCouponIdResponse] = useState<IAddCouponResponse>()

  const postAddCoupon = useCallback(
    async (values: ICouponForm) => {
      try {
        const serviceDiscountCoupons = DiscountCouponsApi();
        setIsLoadingAdd(true);
        if (currentLocal) {
          const response = await AddCouponUseCase(serviceDiscountCoupons,
            {...values, devices: values.devices.map(item => item.deviceId)},
            currentLocal.id,
            );
            setCouponIdResponse(response)
            toast("Cupom criado com sucesso", "success")        
            setSuccessAdd(true)
          return response
        }
      }  catch {
        setSuccessAdd(false)
      }
      
      finally {
        setIsLoadingAdd(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessAdd,
    successAdd,
    isLoadingAdd,
    postAddCoupon,
    couponIdResponse

  };
};

export default UseAddCoupon;
