import { useContext } from "react";
import { ProductContext } from "./context/ProductPageContext";

const UseProductPage = (): any => {
  const context = useContext(ProductContext);

  return { ...context };
};

export default UseProductPage;
