import { IGetMyAccountResponse } from "modules/generalDashboard/domain/dto/IGetMyAccountResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IMyAccount } from "modules/generalDashboard/presentation/interfaces/IMyAccount";


const GetMyAccountUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IMyAccount> => {
    const response: IGetMyAccountResponse = await service.getMyAccount(localId);

    const parse: IMyAccount = {
        retainedBalance: response.SaldoRetido,
        balanceAvailable: response.SaldoDisponivel,
        balanceToBeReleased: response.SaldoALiberar 
    };

    return parse;
}

export default GetMyAccountUseCase;