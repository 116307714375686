import { Radio } from "@mui/material";
import { MenuItem, TextField } from "@mui/material";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { GraphLoading } from "components/ui/graphLoading/GraphLoading";
import { dashboardContainerEnum } from "modules/cashlessDashboard/presentation/interfaces/DashboardContainerEnum";
import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Bar,
  BarChart,
  Legend,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { moneyMaskNumber } from "services/utils/Money";
import { IFilterValue } from "../../filter/IFilter";
import { IOriginType } from "../../rechargeOrigin/interfaces/IOriginType";
import { IAverageTicket } from "../interfaces/IAverageTicket";
import styles from "./BarAverageTicket.module.scss";
import UseDimension from "components/dimension/UseDimension";

interface IChartData {
  time: string;
  date: string;
  average: number;
  male: number;
  female: number;
  unknown: number;
}

interface BarAverageTicketProps {
  loading: boolean;
  data?: IAverageTicket;
  filter?: IFilterValue;
  onGeneratingPDF?: boolean;
  defaultType?: IOriginType;
}

const MAX_HOURS_TO_SHOW = 24;

const BarAverageTicket: FC<BarAverageTicketProps> = ({
  data,
  loading,
  filter,
  onGeneratingPDF,
  defaultType,
}) => {
  const [type, setType] = useState<IOriginType>(defaultType ?? IOriginType.recharged);
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [cashierId, setCashierId] = useState<string | undefined>();

  const { isMobile } = UseDimension();

  useEffect(() => {
    const values =
      type === IOriginType.consumed ? data?.consumption : data?.recharge;

    const cashier = cashierId
      ? values?.cashiers.find((x) => x.id === cashierId)
      : values?.cashiers[0];

    if (!cashierId || !filter?.cashierIds.find((x) => x.id === cashierId))
      setCashierId(cashier?.id || filter?.cashierIds[0].id);

    const newValues =
      cashier?.perHour
        .slice(
          cashier.perHour.length - MAX_HOURS_TO_SHOW,
          cashier.perHour.length
        )
        .map((item) => ({
          ...item,
          time: item.date
            ? new Date(`${item.date}Z`)
                .toLocaleTimeString([], {
                  timeStyle: "short",
                  hour12: false,
                })
                .split(":")[0] + "h"
            : "",
        })) || [];

    setChartData(newValues);
  }, [cashierId, data?.consumption, data?.recharge, filter?.cashierIds, type]);

  if (loading) {
    return (
      <div id={styles.BarChart}>
        <div className={styles.loading}>
          <GraphLoading />
        </div>
      </div>
    );
  }

  const checkError = () => {
    return !chartData?.reduce((acc, next) => acc + next.average, 0);
  };

  return (
    <div id={styles.BarChart}>
      <div className={styles.cashiers}>
        <div className={styles.filter}>
          {onGeneratingPDF 
          ? (
            <h3>{type === 1 ? "Consumo cashless" : "Recarga"}</h3>
          ) : (
            <>
              <div>
                <Radio
                  size="medium"
                  sx={{
                    color: "#32008E",
                    "&.Mui-checked": {
                      color: "#32008E",
                    },
                  }}
                  checked={type === IOriginType.recharged}
                  onChange={(_, checked) => setType(IOriginType.recharged)}
                  id="ticket_recarga"
                />{" "}
                <label htmlFor="ticket_recarga">Recarga</label>
              </div>
              <div>
                <Radio
                  size="medium"
                  sx={{
                    color: "#32008E",
                    "&.Mui-checked": {
                      color: "#32008E",
                    },
                  }}
                  checked={type === IOriginType.consumed}
                  onChange={(_, checked) => setType(IOriginType.consumed)}
                  id="ticket_consumo"
                />{" "}
                <label htmlFor="ticket_consumo">Consumo cashless</label>
              </div>
            </>
          )}
        </div>
        <TextField
          size="small"
          className={styles.period}
          style={{ minWidth: 300 }}
          select
          variant="outlined"
          type={"date"}
          name={"period"}
          value={cashierId}
          onChange={(e) => setCashierId(e.target.value)}
          disabled={filter?.cashierIds.length === 1}
        >
          {filter?.cashierIds.map((x) => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {!checkError() ? (
        <ResponsiveContainer width={"100%"} height={isMobile ? 600 : 250}>
          {isMobile ? (
            <ResponsiveContainer width="100%">
              <BarChart data={chartData} layout="vertical" barGap={0}>
                <XAxis type="number" />
                <YAxis dataKey="time" reversed type="category" />
                {!onGeneratingPDF && <Tooltip
                  formatter={(value: number) => moneyMaskNumber(value)}
                />}
                <Legend
                  pointsAtX={150}
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={{ paddingBottom: 15 }}
                />
                <Bar
                  dataKey="male"
                  name="Masculino"
                  stackId="a"
                  fill="#32008E"
                  maxBarSize={40}
                />
                <Bar
                  dataKey="female"
                  name="Feminino"
                  stackId="b"
                  fill="#955CFF"
                  maxBarSize={40}
                />
                <Bar
                  dataKey="unknown"
                  name="Não informado"
                  stackId="c"
                  fill="#A2A2A2"
                  maxBarSize={40}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <BarChart
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barGap={0}
            >
              <XAxis
                dataKey="time"
                tickLine={false}
                label={{
                  value: "Horário",
                  position: "insideBottomRight",
                  dy: 10,
                  fill: "var(--text)",
                }}
              />
              <YAxis
                tickLine={false}
                label={{
                  value: "Ticket médio",
                  position: "top",
                  dy: -15,
                  fill: "var(--text)",
                }}
              />
              {!onGeneratingPDF && <Tooltip formatter={(value: number) => moneyMaskNumber(value)} />}
              <Legend
                pointsAtX={150}
                verticalAlign="top"
                align="right"
                wrapperStyle={{ paddingBottom: 15 }}
              />

              <Bar dataKey="male" name="Masculino" stackId="a" fill="#32008E">
                <LabelList dataKey="male" position="top" />
              </Bar>
              <Bar dataKey="female" name="Feminino" stackId="b" fill="#955CFF">
                <LabelList dataKey="female" position="top" />
              </Bar>
              <Bar
                dataKey="unknown"
                name="Não informado"
                stackId="c"
                fill="#A2A2A2"
              >
                <LabelList dataKey="unknown" position="top" />
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      ) : (
        <ErrorMessage type="warning" />
      )}
    </div>
  );
};
export default BarAverageTicket;
