import { api } from "../Api";
import { invoiceUrl } from "Enviroment";
import { IOrderService } from "modules/order/domain/interfaces/IOrderService";
import { IPaginatedOrders } from "modules/order/presentation/interfaces/IPaginatedOrders";
import { ISendOrderByEmailParams } from "modules/order/presentation/interfaces/ISendOrderByEmailParams";

const OrderApi = (): IOrderService => {
  const invoiceUrlPath = invoiceUrl;

  const getOrders = async (localId: string, params: any): Promise<IPaginatedOrders> => {
    const response = await api.get(`/InvoiceGateway/Local/${localId}/Orders`, { params });
    return response.data;
  }

  const reprocessOrder = async (companyId: string, invoiceId: string): Promise<any> => {
    const response = await api.post(`${invoiceUrlPath}/Companies/${companyId}/Invoices/${invoiceId}/Reprocess`, null);
    return response.data;
  }

  const cancelOrder = async (companyId: string, invoiceId: string): Promise<any> => {
    const response = await api.delete(`${invoiceUrlPath}/Companies/${companyId}/Invoices/${invoiceId}/Cancel`);
    return response.data;
  }

  const sendOrderByEmail = async (companyId: string, invoiceId: string, params: ISendOrderByEmailParams): Promise<any> => {
    const response = await api.post(`${invoiceUrlPath}/Companies/${companyId}/Invoices/${invoiceId}/SendEmail`, null, { params });
    return response.data;
  }

  const updateAndReprocessOrder = async (companyId: string, invoiceId: string): Promise<any> => {
    const response = await api.post(`${invoiceUrlPath}/Companies/${companyId}/Invoices/${invoiceId}/UpdateTaxAndReprocess`, null);
    return response.data;
  }

  return {
    getOrders,
    reprocessOrder,
    cancelOrder,
    sendOrderByEmail,
    updateAndReprocessOrder,
  }
};

export default OrderApi;


