import { FC } from "react";
import Layout from "components/layout/presentation/Layout";
import NovidadesPage from "modules/novidades/presentation/pages/NovidadesPage";

const Novidades: FC = () => {
  return (
    <Layout disabledMenu>
      <NovidadesPage />
    </Layout>
  );
};
export default Novidades;
