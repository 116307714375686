import NovidadesItem from "../components/novidadesItem.tsx/NovidadesItem";
import OrderNovidades from "../components/orderNovidades/OrderNovidades";
import styles from "./adminNovidadesPage.module.scss";
import Button from "components/ui/Button/Button";
import { AddCircle } from "@mui/icons-material";
import UseDimension from "components/dimension/UseDimension";
import NovidadesItemMobile from "../components/novidadesItemMobile/NovidadesItemMobile";
import { useCallback, useEffect, useState } from "react";
import SidesheetNovidades from "../components/sidesheetNovidades/SidesheetNovidades";
import { ISidesheetFormItem } from "../components/sidesheetNovidades/interfaces/ISidesheetForm";
import { NovidadesApi } from "services/api/novidades/NovidadesApi";
import PostNovidades from "modules/adminNovidades/application/useCases/PostServicesNovidades";
import UseAdminNovidadesPage from "./UseAdminNovidadesPages";
import { IResponseGetNovidades } from "modules/adminNovidades/application/interfaces/INovidadesService";
import { MenuItem, Pagination, TextField } from "@mui/material";
import moment from "moment";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { Skeleton } from "components/skeleton/Skeleton";
import UpdateNovidades from "modules/adminNovidades/application/useCases/UpdateServicesNovidades";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import Sidesheet from "components/sidesheet/Sidesheet";

const AdminNovidadesPage = () => {
  const [openSidesheet, setOpenSidesheet] = useState<string | null>(null);
  const [sidesheetDelete, setSidesheetDelete] = useState<string | null>(null);
  const [objEdit, setObjEdit] = useState<ISidesheetFormItem | undefined>(
    undefined
  );
    const { dimensions } = UseDimension();
  const novidadesService = NovidadesApi();
  const { showLoading, hideLoading } = useUi();
  const {
    listNovidades,
    changeOrder,
    setPage,
    totalNovidades,
    qtdNovidadesPage,
    setQtdNovidadesPage,
    step,
    setStep,
    loading,
    deleteNovidades,
    stepDelete,
    setStepDelete
  } = UseAdminNovidadesPage();
  const [countPage, setCountPage] = useState(1);
  const [id, setId] = useState('');

  useEffect(() => {
    setCountPage(Math.ceil(totalNovidades / qtdNovidadesPage));
  }, [totalNovidades, qtdNovidadesPage]);

  const onSubmit = useCallback((values) => {
    const { id, ...otherParams } = values;
    showLoading();
    PostNovidades(novidadesService, otherParams)
      .then(() => {
        hideLoading();
        setStep(2);
      })
      .catch(() => {
        hideLoading();
      });
  }, []);

  const onUpdate = useCallback((values) => {
    const { id, ...otherParams } = values;
    showLoading();
    UpdateNovidades(novidadesService, values.id, otherParams)
      .then(() => {
        hideLoading();
        setStep(2);
      })
      .catch(() => {
        hideLoading();
      });
  }, []);

  

  const actionEdit = (item: IResponseGetNovidades) => {
    setObjEdit({
      id: item.id,
      Title: item.title,
      Description: item.description,
      Category: item.category,
      Link: item.link,
      img: item.img,
    });
  };

  return (
    <div id={styles.AdminNovidades}>
      <Button
        className={styles.btnAdd}
        endIcon={<AddCircle />}
        onClick={() => {
          setOpenSidesheet("adicionar");
          setObjEdit(undefined);
        }}
      >
        Adicionar novidades
      </Button>
      <h1>Novidade</h1>
      {dimensions.width > 700 && <OrderNovidades onClick={changeOrder} />}
      {listNovidades && !loading ? (
        listNovidades.map((item) => {
          return (
            <>
              {dimensions.width > 700 ? (
                <NovidadesItem
                  data={moment(item.date).format("DD/MM/YY")}
                  titulo={item.title}
                  categoria={item.category}
                  actionEdit={() => {
                    actionEdit(item);
                    setOpenSidesheet("editar");
                  }}
                  actionDelete={() => {
                    setId(item.id)
                    setSidesheetDelete('true')
                  }}
                />
              ) : (
                <NovidadesItemMobile
                  data={moment(item.date).format("DD/MM/YY")}
                  titulo={item.title}
                  categoria={item.category}
                  actionEdit={() => {
                    actionEdit(item);
                    setOpenSidesheet("editar");
                  }}
                  actionDelete={() => {
                    setId(item.id)
                    setSidesheetDelete('true')
                  }}
                />
              )}
            </>
          );
        })
      ) : (
        <Skeleton items={10} cardStyle={styles.skeleton} />
      )}
      <div className={styles.conteinerPagination}>
        <div className={styles.boxMultiplePage}>
          <span>Exibir</span>
          <TextField
            variant="outlined"
            size="small"
            select
            onChange={(ev) => {
              setQtdNovidadesPage(Number(ev.target.value));
              setPage(0);
            }}
          >
            <MenuItem className={styles.textPage} value={10}>
              10
            </MenuItem>
            <MenuItem className={styles.textPage} value={20}>
              20
            </MenuItem>
            <MenuItem className={styles.textPage} value={50}>
              50
            </MenuItem>
          </TextField>
        </div>
        <Pagination
          variant="outlined"
          className={styles.paginationNumber}
          shape="rounded"
          count={countPage}
          sx={{
            ".MuiPaginationItem-root": {
              borderColor: "#BDBDBD",
              color: "#001537",
            },
            ".Mui-selected": {
              fontWeight: "bold",
              borderColor: "#001537",
            },
          }}
          onChange={(_, page) => setPage(page - 1)}
        />
      </div>
      <SidesheetNovidades
        openSidesheet={openSidesheet}
        setOpenSidesheet={setOpenSidesheet}
        onSubmit={openSidesheet === "adicionar" ? onSubmit : onUpdate}
        objEdit={objEdit}
        step={step}
        setStep={setStep}
      />
          <Sidesheet
      open={sidesheetDelete}
      onClose={() => {
        setSidesheetDelete(null);
        setStepDelete(1);
      }}
      title={
        <h1>
          Excluir <b> Novidade</b>
        </h1>
      }
      content={<SidesheetFeedback text="Essa ação não poderá ser desfeita!" />

      }
      cancelButton
      continueButton={"Excluir"}
      handleContinueButton={() => deleteNovidades(id)}
      currentStep={stepDelete}
      totalSteps={2}
      notTotalHeight={dimensions.height < 660}
      feedback={
        <SidesheetFeedback text="Novidade excluída com sucesso!" success />
      }
    />
    </div>
  );
};
export default AdminNovidadesPage;
