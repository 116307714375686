import React, { FC, memo, useEffect, useRef, useState } from "react";
import styles from "./InfinityScroll.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

const isBottom = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return false;
  }
  const divHeight = ref.current.scrollHeight - ref.current.clientHeight;
  return divHeight <= Math.ceil(ref.current.scrollTop);
};

const isBottomHorizontal = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return false;
  }
  const divWidth = ref.current.scrollWidth - ref.current.clientWidth;
  return divWidth <= Math.ceil(ref.current.scrollLeft);
};

interface IInfinityScrollProps {
  onBottomHit: (page?: number) => Promise<void>;
  isLoading: boolean;
  hasNext: boolean;
  isHorizontal?: boolean;
  className?: string;
  loadComponent?: JSX.Element;
  firstLoadComponent?: JSX.Element;
  resetScroll?: boolean;
  isToInitialLoad?: boolean;
}

export const InfinityScroll: FC<IInfinityScrollProps> = ({
  hasNext,
  isLoading,
  onBottomHit,
  className,
  loadComponent,
  firstLoadComponent,
  isHorizontal,
  children,
  resetScroll,
  isToInitialLoad = true,
}) => {
  const [initialLoad, setInitialLoad] = useState(isToInitialLoad);
  const contentRef = useRef<HTMLDivElement>(null);
  const firstPage = isToInitialLoad ? 0 : 1;
  const [page, setPage] = useState(firstPage);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if (resetScroll) {
      setScroll(0);
      setPage(0);
      setInitialLoad(true);
      contentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [resetScroll]);

  useEffect(() => {
    if (initialLoad && hasNext) {
      onBottomHit()
        .then(() => {
          setPage(1);
          setInitialLoad(false);
        })
        .catch(() => {});
    }
  }, [initialLoad, hasNext]); // Não incluir onBottomHit(), senão a requisição inicial é repetida 3x

  useEffect(() => {
    let onScroll = () => {};
    !isHorizontal
      ? (onScroll = () => {
          if (
            !isLoading &&
            hasNext &&
            isBottom(contentRef) &&
            scroll <= (contentRef?.current?.scrollTop ?? 0)
          ) {
            setInitialLoad(false);
            onBottomHit(page);
            setPage(page + 1);
            setScroll(contentRef?.current?.scrollTop ?? 0);
            contentRef.current?.scrollTo(0, contentRef.current.scrollHeight);
          }
        })
      : (onScroll = () => {
          if (
            !isLoading &&
            hasNext &&
            isBottomHorizontal(contentRef) &&
            scroll < (contentRef?.current?.scrollLeft ?? 0)
          ) {
            setInitialLoad(false);
            onBottomHit(page);
            setPage(page + 1);
            setScroll(contentRef?.current?.scrollLeft ?? 0);
            contentRef.current?.scrollTo(0, contentRef.current.scrollWidth);
          }
        });
    if (contentRef) {
      contentRef.current?.addEventListener("scroll", onScroll);
    }

    return () => contentRef.current?.removeEventListener("scroll", onScroll);
  }, [hasNext, isHorizontal, isLoading, onBottomHit, page, scroll,resetScroll]);

  const Loading = (page: number) => {
    if (page === firstPage) {
      return (
        firstLoadComponent ?? (
          <div className={styles.firstLoad}>
            <CircularProgress color="error" />
          </div>
        )
      );
    } else {
      return (
        loadComponent ?? (
          <LinearProgress color="inherit" className={styles.linearProgress} />
        )
      );
    }
  };

  return (
    <div id={styles.InfinityScroll} className={className} ref={contentRef}>
      {children}
      {isLoading && Loading(page)}
    </div>
  );
};
