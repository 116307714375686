import { IGetServiceScheduleByLocalIdResponseDTO } from 'modules/schedule/domain/dto/placeSchedule/IGetServiceScheduleByLocalIdResponse'
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import { PlaceScheduleApiFunction } from '../../interfaces/api/IPlaceScheduleApi'

export const GetServiceScheduleByLocalIdUseCase = async (
    localId: string,
    scheduleApi: () => ({ getServicesScheduleIdsByLocalId: (localID: string) => Promise<IGetServiceScheduleByLocalIdResponseDTO> })
): Promise<ISelect[]> => {
    const placeApi = scheduleApi()
    const response = await placeApi.getServicesScheduleIdsByLocalId(localId)
    return response.items.flatMap(item => item.services).map((item) => ({ description: item.name, id: item.id }))
}
