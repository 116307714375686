import React, { FC } from "react";
import AlphabeticalEventPeopleList from "./components/guestCard/AlphabeticalEventPeopleList";
import style from "./EventPeopleListPage.module.scss";
import { UseEventPeopleListPage } from "./UseEventPeopleListPage";

export interface IGuestListPageProps { }

const EventPeopleListPage: FC<IGuestListPageProps> = () => {
  const { nameList, event, getGetList, onChangePaginationHandle, params, setParams, onChangeFilterHandle } = UseEventPeopleListPage();

  return (
    <div id={style.GuestListTab}>
      {nameList && (
        <AlphabeticalEventPeopleList
          guestList={nameList}
          eventName={event?.name}
          closingdateTime={event?.openingDates?.length ? event.openingDates[0].startDate : undefined}
          refreshList={getGetList}
          onChangePaginationHandle={onChangePaginationHandle}
          params={params}
          pagination={params.pagination}
          setParams={setParams}
          onChangeFilterHandle={onChangeFilterHandle}
        />
      )}
    </div>
  );
};
export default EventPeopleListPage;
