import { ISort } from "interfaces/ISort";
import { IGetPaymentLinkRecurrenceListResponse } from "modules/paymentLinkReport/domain/dto/IGetPaymentLinkRecurrenceListResponse";
import { IPaymentLinkRecurrenceApiService } from "modules/paymentLinkReport/domain/interfaces/IPaymentLinkRecurrenceApiService";
import { IPaymentLinkRecurrencefilter } from "modules/paymentLinkReport/presentation/components/paymentLinkRecurrenceFilter/interfaces/IPaymentLinkRecurrencefilter";
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase";


const GetPaymentLinkRecurrenceUseCase = async (service: IPaymentLinkRecurrenceApiService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IPaymentLinkRecurrencefilter): Promise<IGetPaymentLinkRecurrenceListResponse> => {
   
    const request =  ParseFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getRecurrenceList(request, sort)
    return (response)
}
export default GetPaymentLinkRecurrenceUseCase