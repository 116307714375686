import { FC, useEffect } from 'react'
import styles from './Canceled.module.scss'
import { ICanceled } from './interfaces/ICanceled';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '../skeleton/Skeleton';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';

export interface ICanceledProps {
    getData: () => Promise<void>;
    loading: boolean;
    data?: ICanceled
    onGeneratingPDF?: boolean;
    triggerOperator: boolean;
    expiresAt: string | undefined;
    triggerCustomer: boolean;
    triggerDevices: boolean;
    triggerProducts: boolean;
    triggerCategories: boolean
}

const Canceled: FC<ICanceledProps> = ({ triggerCategories, triggerProducts, getData, loading, data, onGeneratingPDF, triggerOperator, triggerCustomer,
    expiresAt, triggerDevices }) => {
    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        if (data && expiresAt && new Date() > new Date(expiresAt))
            getData();
    }, [triggerCategories, triggerOperator, triggerCustomer, triggerDevices, triggerProducts]);

    if (loading) {
        return (
            <div id={styles.Canceled}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                    <div className={styles.column}>
                        <Skeleton items={3} cardStyle={styles.skeletonCardStyle} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id={styles.Canceled}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.recharge.total)}</span>
                        <div>
                            <span>Cancelamento de recarga</span>
                            {!onGeneratingPDF && <Tooltip title="Cancelamento de recarga" text="Valor total de cancelamentos das recargas no período selecionado." />}
                        </div>
                    </div>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.consumption.total)}</span>
                        <div>
                            <span>Cancelamento de consumo cashless</span>
                            {!onGeneratingPDF && <Tooltip title="Cancelamentos de consumo cashless" text="Valor total de cancelamentos dos consumo cashless no período selecionado." />}
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.header}>
                        <span>{moneyMaskNumber(data?.consumption.serviceTax)}</span>
                        <div>
                            <span>Cancelamento de taxa de serviço</span>
                            {!onGeneratingPDF && <Tooltip title="Cancelamento de taxa de serviço" text="Valor total de taxa de serviço vinculada aos cancelamentos de recarga e/ou consumo." />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Canceled



