import styles from "./PrintersList.module.scss";
import { ISmartPrinterItem } from "../smartPrinterIForm/interfaces/ISmartPrinterForm";
import { IconButton } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";

interface IPrintersListProps {
  item: ISmartPrinterItem;
  onClickEdit: (item: ISmartPrinterItem) => void;
  onClickDelete: (item: ISmartPrinterItem) => void;
}

const PrintersList = ({ item, onClickEdit, onClickDelete }: IPrintersListProps) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.PrintersList}>
      <div className={styles.content}>
        <span>{item.name}</span>
        <span>{item.printerName}</span>
        {dimensions.width > 600 && <span>{item.type === 1 ? "Imprimir pedidos" : "Imprimir cupom fiscal, extrato da conta e ficha"}</span>}
        {dimensions.width > 500 && (
          <div className={styles.actions}>
            {/* <Button variant="outlined" color="secondary">Testar</Button> */}
            <IconButton onClick={() => onClickEdit(item)} className={styles.buttonAction}><EditOutlined /></IconButton>
            <IconButton onClick={() => onClickDelete(item)} className={styles.buttonAction}><DeleteOutlined /></IconButton>
          </div>
        )}
      </div>
      {dimensions.width <= 500 && (
        <div className={styles.actions}>
          {/* <Button variant="outlined" color="secondary">Testar</Button> */}
          <IconButton onClick={() => onClickEdit(item)} className={styles.buttonAction}><EditOutlined /></IconButton>
          <IconButton onClick={() => onClickDelete(item)} className={styles.buttonAction}><DeleteOutlined /></IconButton>
        </div>
      )}
    </div>
  );
};
export default PrintersList;
