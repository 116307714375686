import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IOperation } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";
import ParseUpdateRequestUseCase from "./ParseUpdateRequestUseCase";


export const UpdateOperationUseCase = async (service: IPermutationApiService, accountId: string, request: IOperation, placeId: string): Promise<void> => {

    const parse = ParseUpdateRequestUseCase(request, placeId);
    const _request = await service.postUpdateOperation(accountId, parse);
    return _request;
}


