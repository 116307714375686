
import React, { FC } from 'react'
import styles from './ui.module.scss';

const Row: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
    return (
        <div {...props} className={props.className ? `${props.className} ` : `` + styles.row} />
    )
}

export default Row