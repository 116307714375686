import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import { FC, useEffect, useState } from "react";
import styles from "./AuthPage.module.scss";
import { UseAuthPage } from "./UseAuthPage";
import LayoutAuth from "../components/layoutAuth/LayoutAuth";
import UseDimension from "components/dimension/UseDimension";
import ShowPassword from "../components/showPassword/ShowPassword";
import { useQuery } from "hooks/UseQuery";
import ValidateAccessCode from "./validateAccessCode/ValidateAccessCode";

export interface IAuthPageProps {
  //propertys
}

const AuthPage: FC<IAuthPageProps> = () => {
  const {
    values,
    submitHandle,
    changeHandle,
    onClickForgotPassword,
    errors,
    setErrors,
    requiresMFA,
    setRequiresMFA,
    validadeMfaCodeHandle,
    resendMfaCodeHandle,
    username,
  } = UseAuthPage();

  const [showPassword, setShowPassword] = useState(false);
  const { isMobile } = UseDimension();
  const query = useQuery();

  useEffect(() => {
    const fromBait = query.get("bait");
    if (fromBait) {
      setTimeout(() => {
        localStorage.setItem('@baitFirstLogin', fromBait);
      }, 100);
    } 
  }, [query]);  

  return (
    <LayoutAuth>
      <div id={styles.AuthPage}>
        <div className={styles.container}>
          {isMobile && (
            <img
              src="/assets/img/meep-primary.svg"
              alt=""
              className={styles.logoMobile}
            />
          )}
          
          {requiresMFA ? (
            <ValidateAccessCode 
              validadeMfaCodeHandle={(code) => validadeMfaCodeHandle(code, username)}
              resendMfaCodeHandle={resendMfaCodeHandle}
              onBack={() => setRequiresMFA(false)}
              errors={errors}
            /> 
          ) : (
            <>
              <div style={{ textAlign: isMobile ? "center" : "start" }}>
                <h1>
                  <b>Bem-vindo(a)</b>
                </h1>
                <h1>à sua nova experiência</h1>
              </div>
              <form className={styles.form} onSubmit={(ev) => submitHandle(ev) }>
                  <div className={styles.input}>
                  <label htmlFor="userName" className={styles.required}>
                    E-mail
                  </label>
                  <Input
                    name={"userName"}
                    variant={"outlined"}
                    value={values.userName}
                    placeholder="email@email.com"
                    onChange={(ev) => {
                      changeHandle(ev);
                      setErrors((prev) => ({ ...prev, userName: undefined }));
                    }}
                    error={!!errors.userName}
                    helperText={errors.userName}
                  />
                </div>
                <div className={styles.input}>
                  <label htmlFor="password" className={styles.required}>
                    Senha
                  </label>
                  <Input
                    name={"password"}
                    variant={"outlined"}
                    value={values.password}
                    placeholder="******"
                    type={showPassword ? "text" : "password"}
                    onChange={(ev) => {
                      changeHandle(ev);
                      setErrors((prev) => ({ ...prev, password: undefined }));
                    }}
                    error={!!errors.password}
                    helperText={
                      <span className={styles.helperText}>{errors.password}</span>
                    }
                  />
                  <ShowPassword
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </div>
                <span
                  className={`${styles.forgotPassword} ${
                    isMobile && styles.forgotPasswordMobile
                  }`}
                  onClick={onClickForgotPassword}
                >
                  Esqueci minha senha
                </span>
                <div className={styles.buttons}>             
                    <Button type={"submit"}>Login</Button>         
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </LayoutAuth>
  );
};
export default AuthPage;
