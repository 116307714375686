import { FC, useEffect } from "react";
import { useQuery } from "hooks/UseQuery";
import RegisterPage from "modules/saas/presentation/pages/Register/RegisterPage";
import SimpleRegister from "modules/saas/presentation/pages/SimpleRegister/SimpleRegister";

const SaasRegister: FC = () => {
    const query = useQuery();
    const type = query.get('type');
    const bait = query.get('bait');

    useEffect(() => {
        if (bait !== null) {
            localStorage.setItem('@baitFirstLogin', 'true');
        }
    }, []);

    return (
        type !== "5" ? <RegisterPage /> : <SimpleRegister />
    );
};

export default SaasRegister;
