import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PaymentLinkRecurrenceApi from "services/api/paymentLinkRecurrence/PaymentLinkRecurrenceApi";
import { IProductItem } from "../interfaces/IProductItem";
import GetAllProductsUseCase from "modules/paymentLinkReport/application/useCases/GetAllProductsUseCase";


const UsePaymentLinkProductsList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [productList, setProductList] = useState<IProductItem[]>()
  const { currentLocal } = useLocal();

  const getPaymentLinkProductsList = useCallback(
    async () => {
      try {
        const service = PaymentLinkRecurrenceApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetAllProductsUseCase(service,
            currentLocal.id,
          );

          setProductList(([...response]));

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    productList,
    isLoading,
    getPaymentLinkProductsList,
    setProductList,
  };
};

export default UsePaymentLinkProductsList;
