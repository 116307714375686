import { IEventsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsApi } from '../../interfaces/IEventsService';

const GetEventsDetailsUseCase = async (
  eventsService: IEventsApi,
  eventId: string,
  localId: string,
): Promise<IEventsResponse> => {
  return await eventsService.getEventDetails(eventId, localId);
};

export default GetEventsDetailsUseCase;
