import { CheckCircle, Info, KeyboardArrowRight } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import InventorySupplyFilter from "./inventorySupplyFilter/InventorySupplyFilter";
import List, { ColumnConfigs, ISort } from "../../ui/list/List";
import { IGetInventoryResponse, IGetInventorySupplyItemResponse, IUpdateInventorySupplyBalanceResponse } from "modules/meepErp/application/dtos/invetory/InvetoryOperationDto";
import styles from "./InventorySupplyList.module.scss";
import { IAutocompletePaginatedRequest, IAutocompletePaginatedListResponse } from "../../autocomplete/AutocompleteInput";
import { ISupplyGroupListRequest, ISupplyGroupList } from "../../supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup";
import { SelectAutocompleteItem } from "../inventoryForm/InventoryForm";
import { IInventorySupplyFilterValue } from "./inventorySupplyFilter/InventorySupplyFilterValue";
import { CircularProgress, Icon, IconButton, TextField } from "@material-ui/core";
import { IActionButtons } from "../../ui/list/Item/Item";
import { format, parseISO } from "date-fns";

export interface IInventorySupplyListProps {
    //propertys
    filter: IInventorySupplyFilterValue
    isLoading: boolean;
    inventorySupply?: IGetInventoryResponse
    onChangeFilter: (filter: IInventorySupplyFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    getSupply: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    getSuplyStorageLocals: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
    updateSupplyItem: (request: { id: string, countedQuantity: number }) => Promise<IUpdateInventorySupplyBalanceResponse>
    disabled?: boolean
}

const InventorySupplyList: FC<IInventorySupplyListProps> = ({
    inventorySupply,
    onDelete,
    filter,
    onChangeFilter,
    isLoading,
    getSupply,
    getSupplyGroupList,
    getSuplyStorageLocals,
    updateSupplyItem,
    disabled
}) => {

    const submitFilter = useCallback((values: IInventorySupplyFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ orderBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])


    const columnsConfig: ColumnConfigs<IGetInventorySupplyItemResponse & { id: string }> = {
        supplyCode: {
            title: "Código",
            sortable: true,
            renderRow: (value) => value
        },
        supplyGroupName: {
            title: "Grupo",
            sortable: true,
            renderRow: (value) => value
        },
        supplyName: {
            title: "Insumo",
            sortable: true,
            renderRow: (value) => value
        },
        storageLocationName: {
            title: "Local de estoque",
            sortable: true,
            renderRow: (value) => value
        },
      
        measurementUnitSymbol: {
            title: "Unidade",
            sortable: true,
            renderRow: (value) => value
        },
        balance: {
            title: "Saldo",
            sortable: true,
            renderRow: (value) => value
        },
        countedQuantity: {
            title: "Quantidade contada",
            sortable: true,
            renderRow: (value, item, setItem) => <QuantityInput
                disabled={disabled}
                defaultValue={value}
                onSubmit={async (newValue) => {
                    try {
                        const response = await updateSupplyItem({
                            id: item.id,
                            countedQuantity: newValue
                        })
                        setItem({ ...item, countedQuantity: newValue, difference: response.difference })
                    } catch (e) {
                        setItem({ ...value })
                    }
                }} />
        },
        difference: {
            title: "Divergência",
            sortable: true,
            renderRow: (value) => value === 0 ? <span style={{ fontWeight: "600" }} >{value}</span> : value > 0 ? <span style={{ color: "#1a5", fontWeight: "600" }} >{value}</span> : <span style={{ color: "#a22", fontWeight: "600" }} >{value}</span>
        },
        date: {
            title: "Data",
            sortable: true,
            renderRow: (value) => <span>{new Date(value).toLocaleDateString('pt-BR', { 
                day: '2-digit', 
                month: '2-digit', 
                year: 'numeric',
            })} <br/>{format(new Date(value + 'Z'), 'HH:mm')}</span>
        },

        status: {
            title: "Status",
            sortable: true,
            renderRow: (value, item) => item.countedQuantity !== null ? <CheckCircle style={{ color: "#1a5" }} /> : <Info style={{ color: "#a22" }} />
        }

    }

    const actionButtons: IActionButtons<IGetInventorySupplyItemResponse & { id: string }>[] = [
        {
            tooltip: "Excluir",
            icon: "delete",
            showLoading: true,
            buttonProps: {
                tabIndex: -1
            },
            onClick: (value: IGetInventorySupplyItemResponse) => onDelete(value.inventorySupplyId),
        },
    ]
    const pagination = useMemo(() => {
        return {
            page: inventorySupply?.inventorySupplyItens?.page ?? 1,
            pageSize: 10,
            count: inventorySupply?.inventorySupplyItens?.totalPages ?? 0
        }
    }, [inventorySupply])

    return (
        <div id={styles.InventorySupplyList} >
            {
                <div className={styles.listTitle}>
                    <h2>Inventário - {inventorySupply?.date && new Date(inventorySupply?.date).toLocaleDateString()}</h2>
                    <h3>{inventorySupply?.description}</h3>
                </div>
            }
            <InventorySupplyFilter
                getSupply={getSupply}
                getSupplyGroupList={getSupplyGroupList}
                getSuplyStorageLocals={getSuplyStorageLocals}
                onSubmit={submitFilter}
                defaultValue={filter}
            />

            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={inventorySupply?.inventorySupplyItens?.items?.map(item => ({ ...item, id: item.inventorySupplyId })) ?? []}
                    columnsConfigs={columnsConfig}
                    actionButtons={actionButtons}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default InventorySupplyList



interface IQuantityInputProps {
    defaultValue: number;
    onSubmit: (value: number) => Promise<void>;
    disabled?: boolean;
}
const QuantityInput: FC<IQuantityInputProps> = ({
    defaultValue,
    onSubmit,
    disabled
}) => {
    const [value, setValue] = useState(defaultValue?.toString());
    const [editMode, setEditMode] = useState(false);
    const [edited, setEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(defaultValue?.toString())
        setEdited(false);
    }, [defaultValue])

    const onChange = useCallback((value: string) => {
        setValue(value);
        setEdited(true);
    }, [])
    const onSubmitHandler = useCallback(async () => {
        try {
            setIsLoading(true);
            await onSubmit(Number(value));
            setEditMode(false);
            setEdited(false);
        } finally {
            setIsLoading(false);
        }
    }, [onSubmit, value])

    const onCancel = useCallback(() => {
        setValue(defaultValue?.toString());
        setEditMode(false);
        setEdited(false);
    }, [defaultValue])

    const onClickEdit = useCallback(() => {
        setEditMode(true);
        setEdited(false);
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(0, inputRef.current.value.length)
    }, [])

    const onKeyDown = useCallback((ev: React.KeyboardEvent) => {
        if (!edited) return;
        if (ev.key === 'Enter') {
            onSubmitHandler();
        }
        if (ev.key === 'Escape') {
            onCancel();
            inputRef.current?.blur();
        }
    }, [edited, onCancel, onSubmitHandler])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 200,
            cursor: 'pointer'
        }}
        // onClick={() => inputRef.current?.focus()}
        >
            <TextField
                onKeyDown={onKeyDown}
                // disabled={!editMode || isLoading}
                value={value}
                disabled={disabled}
                type="number"
                inputRef={inputRef}
                onFocus={ev => ev.target.select()}
                onChange={ev => onChange(ev.target.value)}
                InputProps={
                    {
                        // endAdornment: (
                        //     editMode ?
                        //         (
                        //             isLoading ?
                        //                 <CircularProgress
                        //                     size={20}
                        //                     color="inherit"
                        //                 />
                        //                 :
                        //                 <>
                        //                     <IconButton size="small" disabled={isLoading} onClick={() => onCancel()}><Icon fontSize="small">close</Icon></IconButton>
                        //                     {edited && <IconButton size="small" disabled={!edited || isLoading} onClick={() => onSubmitHandler(value)}><Icon fontSize="small">check</Icon></IconButton>}
                        //                 </>
                        //         )
                        //         :
                        //         <IconButton size="small" onClick={onClickEdit}><Icon fontSize="small">edit</Icon></IconButton>

                        // ),
                        endAdornment: isLoading ?
                            <CircularProgress
                                size={20}
                                color="inherit"
                            />
                            :
                            <>
                                {edited && <IconButton size="small" disabled={isLoading} onClick={() => onCancel()}><Icon fontSize="small">close</Icon></IconButton>}
                                {edited && value !== "" && <IconButton size="small" disabled={!edited || isLoading} onClick={() => onSubmitHandler()}><Icon fontSize="small">check</Icon></IconButton>}
                            </>,

                        startAdornment: (

                            null
                        )
                    }
                }
            />
        </div>
    )
}
