import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { ICloseEvent } from "../../ICloseEvent";
import { CloseTitle } from "../closeTitle/CloseTitle";
import styles from "./Resume.module.scss";

interface IResumeProps {
  event: ICloseEvent;
  isExport: boolean;
  renderPercentage: Function;
}

export const Resume: FC<IResumeProps> = ({ event, isExport, renderPercentage }) => {

  return (
    <div id={styles.Resume}>
      <CloseTitle
        isExport={isExport}
        title="Resumo geral"
        subTitle="Informações referentes à venda de ingressos e consumo de produtos."
      />
      <div className={isExport ? styles.export : ""}>
        <section className={styles.row}>
          <section className={styles.card}>
            <h2>
              {moneyMaskNumber(
                event?.eventResume?.totalBilled.totalBilled ?? 0
              )}
            </h2>
            <h3>Total faturado</h3>
            <p>
              <span className={styles.description}>Consumo de produtos:</span>
              {`${moneyMaskNumber(
                event.eventResume?.totalBilled.totalProductConsumption ?? 0
              )} (${renderPercentage(
                event.eventResume.totalBilled.totalProductConsumption,
                event.eventResume.totalBilled.totalBilled
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Ingressos vendidos:</span>
              {`${moneyMaskNumber(
                event.eventResume?.totalBilled.totalTickets ?? 0
              )} (${renderPercentage(
                event.eventResume.totalBilled.totalTickets,
                event.eventResume.totalBilled.totalBilled
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Taxa de serviço:</span>
              {`${moneyMaskNumber(
                event.eventResume?.totalBilled.totalServiceTax ?? 0
              )} (${renderPercentage(
                event.eventResume.totalBilled.totalServiceTax,
                event.eventResume.totalBilled.totalBilled
              )}%)`}
            </p>
          </section>
          <section className={styles.card}>
            <h2>{event.eventTickets?.eventTicketsResume?.checkins.amount}</h2>
            <h3>Pessoas que estiveram no evento</h3>
            <p>
              <span className={styles.description}>Masculino:</span>
              {`${
                event.eventTickets?.eventTicketsResume?.checkins.men ?? 0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsResume?.checkins.men,
                event.eventTickets?.eventTicketsResume?.checkins.amount
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Feminino:</span>
              {`${
                event.eventTickets?.eventTicketsResume?.checkins.women ?? 0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsResume?.checkins.women,
                event.eventTickets?.eventTicketsResume?.checkins.amount
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Não informado:</span>
              {`${
                event.eventTickets?.eventTicketsResume?.checkins.notSpecified ?? 0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsResume?.checkins.notSpecified,
                event.eventTickets?.eventTicketsResume?.checkins.amount
              )}%)`}
            </p>
          </section>
        </section>
        <section className={styles.row}>
          <section className={styles.card}>
            <h2>
              {moneyMaskNumber(
                event.eventResume.averageSpend.averageSpend
              )}
            </h2>
            <h3>Ticket médio total</h3>
            <p>
              <span className={styles.description}>Consumo de produtos:</span>
              {`${moneyMaskNumber(
                event.eventResume.averageSpend.averageProductSpend ?? 0
              )} (${renderPercentage(
                event.eventResume.averageSpend.averageProductSpend,
                event.eventResume.averageSpend.averageSpend
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Ingressos vendidos:</span>
              {`${moneyMaskNumber(
                event.eventResume.averageSpend.averageTicketSpend ?? 0
              )} (${renderPercentage(
                event.eventResume.averageSpend.averageTicketSpend,
                event.eventResume.averageSpend.averageSpend
              )}%)`}
            </p>
          </section>
          <section className={styles.card}>
            <h2>{event.eventResume.offTickets.amount}</h2>
            <h3>Quantidade de OFF's</h3>
            <p>
              <span className={styles.description}>Masculino:</span>
              {`${
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount.men ??
                0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount.men,
                event.eventResume.offTickets.amount
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Feminino:</span>
              {`${
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                  .women ?? 0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount.women,
                event.eventResume.offTickets.amount
              )}%)`}
            </p>
            <p>
              <span className={styles.description}>Não informado:</span>
              {`${
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                  .notSpecified ?? 0
              } (${renderPercentage(
                event.eventTickets?.eventTicketsGuests?.freeTicketsAmount
                  .notSpecified,
                event.eventResume.offTickets.amount
              )}%)`}
            </p>
          </section>
        </section>
      </div>
    </div>
  );
};
