import GetCheckinTableUseCase from 'modules/customersDashboard/application/useCases/GetCheckinTableUseCase'
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type'
import { useCallback, useState } from 'react'
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService'
import { ICheckinTableData } from '../components/checkinTable/ICheckinTable'

const UseCheckinTable = (filter: ICustomerDashboardFilter) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<ICheckinTableData>();
    const [checkinPieData, setCheckinPieData] = useState<{ name: string, value: number, color: string }[]>();
    const [error] = useState<string>()

    const getCheckinTableData = useCallback(async () => {
        if (!filter.eventId && !filter.cashierId) return;

        const serviceCustomersDashboard = CustomersDashboardService();
        try {
            setIsLoading(true)
            const response = await GetCheckinTableUseCase(serviceCustomersDashboard, filter);
            setCheckinPieData([
                {
                    name: "Masculino", value: response.customers.male, color:`#4D13B7`
                },
                {
                    name: "Feminino", value: response.customers.female, color:`#955CFF`
                },
                {
                    name: "Não informado", value: response.customers.unknown, color:`#A2A2A2`
                },
            ])
            setData(response)
        } finally {
            setIsLoading(false)
        }
    }, [filter])

    return ({
        isLoading,
        data,
        checkinPieData,
        getCheckinTableData,
        error
    })
}

export default UseCheckinTable