import React, { FC, useCallback, useEffect, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IOriginType } from '../rechargeOrigin/interfaces/IOriginType'
import { ITopOperators } from './interfaces/ITopOperators'

export interface ITopOperatorsProps {
    getTopOperators: ({ page }: IGetDataParams) => Promise<ITopOperators>
    type: IOriginType
}

const TopOperators: FC<ITopOperatorsProps> = ({ getTopOperators, type }) => {
    const [topOperatorsData, setTopOperatorsData] = useState<ITopOperators>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    
    const getData = useCallback(async (page?: number) => {
        clearContainerError(dashboardContainerEnum.TOP_OPERATORS);
        try {
            !page && setOnLoading(true);
            const response = await getTopOperators?.({ page });
            if (page) {
                setTopOperatorsData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
            } else {
                setTopOperatorsData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
            }

            if (!response.records.length)
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.TOP_OPERATORS
                })
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.TOP_OPERATORS
            })
        } finally {
            setOnLoading(false);
        }
    }, [getTopOperators, clearContainerError, setOnError]);

    useEffect(() => {
        setCurrentPage(0)
    }, [type])

    useEffect(() => {
        getData(0);
    }, [getData, type]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    return (
        <div>
            <RankingList
                // keyProp="id"
                list={topOperatorsData?.records}
                count={topOperatorsData?.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    name: { title: "Nome" },
                    total: { title: "Total", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default TopOperators