import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import Layout from 'components/layout/presentation/Layout';
import styles from './CoilCalculatorPage.module.scss';
import CoilCalculator from '../../components/coilCalculator/CoilCalculator';
import CalculatorBanner from '../../components/calculatorBanner/CalculatorBanner';
import { WizardInfoModal } from 'components/widgets';

const modalContent = [
    {
        image: '/assets/img/coil-calculator-phone.png',
        title: '',
        description: '1. Insira a quantidade média de pedidos que você emite por dia',
    },{
        image: '/assets/img/coil-calculator-phone.png',
        title: '',
        description: '2. Escolha o tamanho do layout da ficha que você emite para cada pedido realizado',
    },{
        image: '/assets/img/coil-calculator-phone.png',
        title: '',
        description: '3. Selecione o tamanho da bobina que você utiliza',
    }
];

const CoilCalculatorPage = () => {
    const history = useHistory();
    const [showTipModal, setShowTipModal] = useState(false);

    return (
        <Layout limitedWidth bgColor="linear-gradient(180deg, #F3EDFF 0%, rgba(149, 92, 255, 0.3) 85%)">
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className={styles.toolsCalculatorReturn} onClick={() => history.push('/private/ferramentas')}>
                        <ChevronLeft fontSize="large" />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <CalculatorBanner
                        title="Bem vindo à calculadora de bobinas"
                        description="Calcule a média de bobinas necessárias para sua operação"
                        exploreSystemFn={() => history.push('/private')}
                        howToWorksFn={() => setShowTipModal(true)}
                    />

                    <WizardInfoModal
                        isOpen={showTipModal}
                        content={modalContent}
                        closeFn={() => setShowTipModal(false)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CoilCalculator />
                </Grid>
            </Grid>
        </Layout>
    );
}

export default CoilCalculatorPage;