import { 
  BaseTextFieldProps, 
  InputAdornment, 
  InputLabelProps, 
  OutlinedInputProps, 
  TextField, 
  TextFieldProps 
} from "@material-ui/core";
import React, { FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";

export interface InputProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  InputProps?: Partial<OutlinedInputProps>;
  multiline?: boolean;
  endAdornmentText?: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
  fullwidth?: boolean;
  name?: string;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  select?: boolean;
  disabled?: boolean;
  testId?: string;
  label?: string
  InputLabelProps?: Partial<InputLabelProps>,
  error?: boolean
  helperText?: React.ReactNode;
  color?: 'primary' | 'secondary';
  rows?: number
}

const Input: FC<InputProps> = ({
  inputProps,
  children,
  multiline,
  onChange,
  onFocus,
  onBlur,
  value,
  type,
  className,
  variant,
  fullWidth = true,
  name,
  required,
  placeholder,
  select,
  disabled,
  testId,
  label,
  InputLabelProps,
  error,
  helperText,
  color = 'secondary',
  rows,
  InputProps
}) => {
  return (
    <TextField
      size="small"
      select={select}
      disabled={disabled}
      name={name}
      rows={rows}
      className={`${className} ${styles.textfield}`}
      multiline={multiline}
      value={value}
      variant={variant}
      placeholder={placeholder}
      color={color}
      type={type}
      fullWidth={fullWidth}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      required={required}
      label={label}
      InputLabelProps={InputLabelProps}
      InputProps={{...InputProps}}
      inputProps={{
        ...inputProps,
        "data-testid": testId
        // classes: { underline: styles.underlineClass },
        // endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      }}

      error={error}
      helperText={helperText}

    >
      {children}
    </TextField >
  );
};

export default Input;
