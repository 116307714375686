import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './PremiumModal.module.scss';
import { Dialog, Icon, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useHistory } from 'react-router-dom';
import { usePlans } from '../../hooks/PlansContext';
import { IPlans } from '../../interfaces/IPlans';
import Button from 'components/ui/Button/Button';
import UseOpenSaasModal from '../../hooks/UseOpenSaasModal';
import { LaunchOutlined } from '@material-ui/icons';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

interface PremiumModalProps {
    opened: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PremiumModal = ({ opened, onClose }: PremiumModalProps) => {
    const history = useHistory();
    const { openSaasModal, setOpenSaasModal } = UseOpenSaasModal();
    const { plans, getPlans } = usePlans();
    const { currentLocal } = useLocal();
    const [loading, setLoading] = useState(false);
    const [premiumPlan, setPremiumPlan] = useState<IPlans>({} as IPlans);

    useEffect(() => {
        (async () => {
            let response: IPlans[] | undefined = undefined;
            setLoading(true);
            if (!plans) {
                response = await getPlans();
            } else {
                response = plans;
            }

            if (!!response) {
                const _premiumPlan = response.find(x => x.name === 'Plano Premium');
                if (_premiumPlan) setPremiumPlan(_premiumPlan);
            }
            setLoading(false);
        })();
    }, [getPlans, plans]);

    const isSaas = useMemo(() => currentLocal?.systemIdentifier === 1, [currentLocal?.systemIdentifier]);

    const openedModal = useMemo(() => isSaas ? openSaasModal : opened, [isSaas, openSaasModal, opened]);

    const handleCloseModal = useCallback(
        () => 
            isSaas ? setOpenSaasModal(false) : onClose,
        [isSaas, onClose, setOpenSaasModal]
    );

    const handleUpgradeClick = useCallback(() => {
        return history.push('/private/plans')
    }, [history]);

    return (
        <Dialog
            open={openedModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            classes={{
                paper: styles.paper,
            }}
        >
            {isSaas 
            ? (
                <div className={styles.containerSaas}>
                    <button className={styles.closeButton} onClick={handleCloseModal}>
                        <Icon className={styles.closeIcon}>close</Icon>
                    </button>
                    <img src="/assets/icon/saas/modal/saas-bubble-bg.svg" alt="" className={styles.saasBubbleBg} />
                    <div className={styles.content}>
                        <img src="/assets/icon/saas/modal/saas-computer-bg.svg" alt="" />
                        <div className={styles.info}>
                            <div className={styles.title}>
                                <div>
                                    <p>Aproveite muito mais:</p>
                                    <img src="/assets/icon/golden-crown.svg" alt="" />
                                </div>
                                <h1>FAÇA UPGRADE!</h1>
                                <img src="/assets/icon/saas/modal/underline.svg" alt="" className={styles.saasunderline}  />
                            </div>
                            <p>Para usar funcionalidades exclusivas, <b>contrate o Plano Avançado!</b></p>
                            <Button
                                onClick={handleUpgradeClick}
                                style={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "rgba(149, 92, 255, 1)" }}
                            >
                                Fazer upgrade
                            </Button>
                            <Button
                                variant="outlined"
                                endIcon={<LaunchOutlined />}
                                onClick={() => window.open("https://www.youtube.com/@Meebymeep/videos", "_blank")}
                                style={{ color: "rgba(255, 255, 255, 1)", borderColor: "rgba(255, 255, 255, 1)" }}
                            >
                                Entenda como funciona
                            </Button>
                        </div>
                    </div>
                    <img src="/assets/icon/saas/modal/saas-vector-bg.svg" alt="" className={styles.saasVectorBg} />
                </div>
            ) : (
                <div className={styles.container}>
                    <img src="/assets/icon/plans/plan-modal-bg.svg" alt="" className={styles.bg} />
                    <div className={styles.sidebar}>
                        <img src="/assets/icon/plans/crowns.svg" alt="" />
                    </div>
                    <button className={styles.closeButton} onClick={onClose}>
                        <Icon className={styles.closeIcon}>close</Icon>
                    </button>
                    <div className={styles.content}>
                        <h3>Aproveite muito mais:</h3>
                        <div className={styles.titleContainer}>
                            <h1>EXPERIMENTO O PLANO PREMIUM!</h1>
                            <img src="/assets/icon/golden-crown.svg" alt="" />
                        </div>
                        <div className={styles.beta}>
                            <span>Versão Beta</span>
                        </div>

                        <p>
                            Pronto para impulsionar o seu negócio como nunca antes? 
                            Experimente o <b>Plano Premium da Meep por 1 mês</b> e desbloqueie uma série de ferramentas estratégicas essenciais que transformarão a sua jornada empresarial.
                        </p>

                        <h4>O que está incluso</h4>
                        <div className={styles.benefitsContainer}>
                            <div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>Programa de fidelidade</span>
                                </div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>Envio de e-mails</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>CRM</span>
                                </div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>Envio de push</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>Criação de campanhas</span>
                                </div>
                                <div className={styles.item}>
                                    <Icon className={styles.itemIcon}>check_circle_outline_icon</Icon>
                                    <span className={styles.benefitsText}>Pesquisa de satisfação</span>
                                </div>
                            </div>
                        </div>

                        <footer>
                            {/* <div>
                                <span className={styles.value}><b>Teste por 1 mês</b></span>
                                <span className={styles.highlight}>depois disso, apenas R$597,00/mês</span>
                            </div> */}
                            <button onClick={() => history.push(`/private/plans/purchase/${premiumPlan.id}`)}>Experimentar por 1 mês</button>
                        </footer>
                    </div>
                </div>
            )}
        </Dialog>
    )
}