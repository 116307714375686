import { IEventsRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IEventsApi } from '../../interfaces/IEventsService';

const CreateEventUseCase = async (
  eventsService: IEventsApi,
  event: IEventsRequest,
): Promise<void> => {
  return await eventsService.createNewEvent(event);
};

export default CreateEventUseCase;
