import { create } from "zustand";
import { IntegrationTypeEnum } from "../interfaces/IntegrationTypeEnum";

interface ISelectParentId {
  deleteType: IntegrationTypeEnum | null;
  setDeleteType: (deleteType: IntegrationTypeEnum | null) => void;
};

const UseDeleteSidesheet = create<ISelectParentId>(
    (set) => ({
      deleteType: null,
      setDeleteType: (selectDeleteType) => {
        set(() => ({
          deleteType: selectDeleteType,
        }));
      },
    })
);

export default UseDeleteSidesheet;
