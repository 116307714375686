import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UsePaymentLinkList from "../hooks/UsePaymentLinkList";
import { IGetPaymentLinkListParams } from "../interfaces/IGetPaymentLinkListParams";
import { ILinkFilter } from "../interfaces/ILinkFilter";
import { IPaymentLinkColumns } from "../interfaces/IPaymentLinkColumns";
import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import styles from "./PaymentLinkPage.module.scss";
import { Skeleton } from "@mui/material";
import { PaymentLinkSideSheetHandles } from "../components/paymentLinkSideSheet/PaymentLinkSideSheet";

export enum sideSheetEnum {
  "isNew",
  "isEditing",
  "isDeleting",
}

export const UsePaymentLinkPage = () => {
  const { dimensions } = UseDimension();
  const { isMobile } = useUi();
  const { getPaymentLinkList, isLoading, paymentLinkList } =
    UsePaymentLinkList();

  const sideSheetRef = useRef<PaymentLinkSideSheetHandles>(null);

  const [params, setParams] = useState<IGetPaymentLinkListParams>({
    sort: { orientation: "asc", type: "name" },
    pagination: { pageSize: 20, page: 0 },
  });

  const [columns, setColumns] = useState<IPaymentLinkColumns[]>([
    {
      property: "name",
      title: "Nome",
    },
    {
      property: "price",
      title: "Valor",
    },
    {
      property: "expiration",
      title: "Expira em",
    },
  ]);

  const countPage = useMemo(
    () =>
      Math.ceil(
        (paymentLinkList?.totalRecords ?? 1) /
          (params.pagination?.pageSize ?? 20)
      ),
    [paymentLinkList?.totalRecords, params.pagination?.pageSize]
  );

  const loadAnimation = useMemo(
    () =>
      isMobile ? (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
        </div>
      ) : (
        <div className={styles.load}>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={60} />
        </div>
      ),
    [isMobile]
  );

  const columnsLimit = useCallback(
    (_columns: IPaymentLinkColumns[], width: number) => {
      if (width > 0) {
        const max =
          width > 0 && width <= 600
            ? 1
            : width > 600 && width <= 800
            ? 2
            : width > 800 && width <= 1024
            ? 3
            : 5;
        let count = 0;
        return _columns.map((it) => {
          if (!it.hide) {
            count++;
            if (count > max) {
              return { ...it, hide: true };
            } else {
              return it;
            }
          } else {
            return { ...it, hide: false };
          }
        });
      } else {
        return _columns;
      }
    },
    []
  );

  useEffect(() => {
    setColumns((prev) => columnsLimit(prev, dimensions.width));
  }, [columnsLimit, dimensions.width]);

  const handlePagination = useCallback(
    (pagination: { page?: number; pageSize?: number }) => {
      setParams((prev) => ({ ...prev, pagination }));
    },
    [setParams]
  );

  const handleOrderBy = useCallback((orderBy: string) => {
    setParams((prev) => ({
      ...prev,
      sort: {
        type: orderBy,
        orientation: prev?.sort?.orientation === "asc" ? "desc" : "asc",
      },
      pagination: {
        page: prev.pagination?.page,
        pageSize: prev.pagination?.pageSize,
      },
    }));
  }, []);

  const openSidesheet = useCallback((id?: string) => {
    sideSheetRef.current?.openSideSheet(id);
  }, []);

  const handleFilter = useCallback(
    (filter: ILinkFilter | null) => {
      setParams((prev) => ({
        ...prev,
        filter,
        pagination: { page: 0, pageSize: prev?.pagination?.pageSize },
      }));
    },
    [setParams]
  );

  useEffect(() => {
    getPaymentLinkList({ ...params });
  }, [getPaymentLinkList, params]);

  return {
    openSidesheet,
    handleFilter,
    isLoading,
    paymentLinkList,
    handlePagination,
    handleOrderBy,
    columns,
    params,
    countPage,
    loadAnimation,
    sideSheetRef,
    getPaymentLinkList
  };
};
