import styles from "./SaqueForm.module.scss";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { Info } from "@mui/icons-material";
import { UseSaqueForm } from "./UseSaqueForm";
import { ISaqueFormItem } from "./interfaces/ISaqueForm";
import { DigitalAccountResponse } from "modules/contaDigital/models/dtos/DigitalAccountResponse";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import Sidesheet from "components/sidesheet/Sidesheet";
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";
import UseDimension from "components/dimension/UseDimension";
import { formatarValor } from "../../pages/utils";

interface SaqueFormProps {
    onSubmit: (values: ISaqueFormItem) => void;
    account: DigitalAccountResponse | undefined;
    openSaque: null | string;
    balance?: DigitalAccountBalanceResponse
    setOpenSaque: React.Dispatch<React.SetStateAction<string | null>>;
    stepSaque: number;
    setStepSaque: React.Dispatch<React.SetStateAction<number>>;
}

const SaqueForm = ({
    account,
    openSaque,
    setOpenSaque,
    setStepSaque,
    stepSaque,
    onSubmit,
    balance
}: SaqueFormProps) => {
    const { errors, values, onChangeHandle, onSubmitHandle } = UseSaqueForm(onSubmit);
    const { isMobile } = UseDimension();

    const saldoPosSaque = balance ? balance?.balanceResult.balanceAvailable - balance?.balanceResult.tedFee - Number(values.valor) : 0

    const handleSubmit = () => {
        if (saldoPosSaque >= 0) {
          onSubmitHandle()
        }
      }
    
    return (
        <>
            <Sidesheet
                open={openSaque}
                onClose={() => {
                    setOpenSaque(null)
                    setStepSaque(1)
                    onChangeHandle('valor', '')
                }}
                title={
                    <h2>
                        Novo <b>saque</b>
                    </h2>
                }
                content={
                    <div id={styles.SaqueForm}>
                        <div>
                            {!isMobile && <p className={styles.infoGeral}><Info/>Transferência será creditada em dois dias úteis.</p>}
                            <div className={styles.saldo}>
                                <p>Saldo disponível</p>
                                <p className={styles.value_available}>{balance && formatarValor(balance?.balanceResult.balanceAvailable)}</p>
                            </div>

                            <div className={styles.box}>
                                <div className={styles.line}>
                                    <p>Banco:</p>
                                    <p className={styles.values}>{account?.banco}</p>
                                </div>
                                <div className={styles.line}>
                                    <p>Agência:</p>
                                    <p className={styles.values}>{account?.agencia}</p>
                                </div>
                                <div className={styles.line}>
                                    <p>Conta corrente:</p>
                                    <p className={styles.values}>{account?.contaCorrente}</p>
                                </div>
                                <div className={styles.line}>
                                    <p>CPF / CNPJ:</p>
                                    <p className={styles.values}>{account?.documento}</p>
                                </div>
                                <div className={styles.line}>
                                    <p>Nome:</p>
                                    <p className={styles.values}>{account?.nome}</p>
                                </div>
                                <FormItemContainer label={"Valor do saque"} required>
                                    <Input
                                        name={"valor"}
                                        value={values.valor}
                                        onChange={(ev) => {
                                            onChangeHandle(ev.target.name, ev.target.value);
                                        }}
                                        required
                                        variant="outlined"
                                        error={!!errors.valor}
                                        helperText={errors.valor}
                                        placeholder="R$ 0,00"
                                        type="number"
                                    />
                                </FormItemContainer>
                                <p className={styles.infos}>Custo TED: <b>{balance && formatarValor(balance?.balanceResult.tedFee)}</b></p>
                                <p className={styles.infos}>Saldo após saque: <b>{saldoPosSaque && formatarValor(saldoPosSaque)}</b></p>
                                {isMobile && <p className={styles.infoGeralMobile}>Transferência será creditada em dois dias úteis.</p>}
                            </div>
                        </div>
                    </div>
                }
                currentStep={stepSaque}
                totalSteps={2}
                continueButton="Sacar"
                handleContinueButton={handleSubmit}
                cancelButton
                feedback={
                    <SidesheetFeedback
                        text={"Saque realizado com sucesso! Comprovante disponível no relatório de saques."}
                        success
                    />
                }
            />
        </>
    );
};
export default SaqueForm;
