import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { DeletePlaceByPlaceIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/DeletePlaceByPlaceIdUseCase';
import { PostDisablePlaceByIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostDisablePlaceByIdUseCase';
import { PostEnabledPlaceByIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostEnabledPlaceByIdUseCase';
import { IEnabledPlaceScheduleRequest } from 'modules/schedule/domain/dto/placeSchedule/IEnabledPlaceScheduleRequest';
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import PlacesScheduleApi from 'services/api/schedule/places/PlacesScheduleApi';
import { ISchedulePlaceCard } from './interfaces/ISchedulePlaceCard';
import { validate as uuidValidate } from 'uuid';

export const UseSchedulePlaceCard = (value: ISchedulePlaceCard, getPlaceList: () => void) => {

    const [toggleModalAvailableDays, setToggleModalAvailableDays] = useState(false)
    const [toggleModalServices, setToggleModalServices] = useState(false)
    const [toggleModalRemovePlace, setToggleModalRemovePlace] = useState(false);
    const { showLoading, hideLoading, toast } = useUi();

    const { push } = useHistory();


    const handleChangeEnabled = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

        const newArray: IEnabledPlaceScheduleRequest = {
            id: value.id
        }

        if (checked) {
            showLoading()
            PostEnabledPlaceByIdUseCase(newArray, PlacesScheduleApi).then((reponse) => {
                getPlaceList();
                toast("Passeio ativado com sucesso", "success");
            }).catch((error) => {

            }).finally(() => {
                hideLoading();
            });
        } else {
            showLoading()
            PostDisablePlaceByIdUseCase(newArray, PlacesScheduleApi).then((reponse) => {
                getPlaceList();
                toast("Passeio desativado com sucesso", "success");
            }).catch((error) => {
                
            }).finally(() => {
                hideLoading();
            });
        }
    }, []);

    const removeUuid = (name: string) => {
        const nameArray = name?.split(" ")
        const findUuid = nameArray?.find(uuid => uuidValidate(uuid))
        const nameFind = nameArray?.filter(item => item !== findUuid).join(" ")
        return nameFind
    }


    const deletePlace = useCallback((serviceId: string) => {
        showLoading()
        DeletePlaceByPlaceIdUseCase(serviceId, PlacesScheduleApi).then((response) => {
            getPlaceList();
            toast("Passeio removido com sucesso!", "success");
        }).catch((error) => {
            toast("Algo deu errado, tente novamente!", "error");
        }).finally(() => {
            hideLoading();
            setToggleModalRemovePlace(false)
        });
    }, []);

    const handleModalAvailableDays = () => {
        setToggleModalAvailableDays(!toggleModalAvailableDays)
    }

    const handleModalServices = () => {
        setToggleModalServices(!toggleModalServices)
    }


    const handleModalRemovePlace = () => {
        setToggleModalRemovePlace(!toggleModalRemovePlace)
    }

    const onClickServiceHandle = (placeId: string) => {
        push(`/private/agendamento/lugares/editar/${placeId}`)
    }

    return (
        {
            handleModalAvailableDays,
            toggleModalAvailableDays,
            setToggleModalAvailableDays,
            handleModalServices,
            toggleModalServices,
            setToggleModalServices,
            onClickServiceHandle,
            handleChangeEnabled,
            deletePlace,
            handleModalRemovePlace,
            toggleModalRemovePlace,
            removeUuid
        }
    )
}
