import React, { FC, useCallback, useMemo } from 'react'
import styles from './SupplyStockList.module.scss'
import { IGetSupplyStockListRequest, IGetSupplyStockListResponse, ISupplyStockItemResponse, ISupplyStockTotalResponse } from 'modules/meepErp/application/dtos/supplyStock/IGetSupplyStockListResponse'
import List, { ColumnConfigs, ISort } from '../ui/list/List';
import SupplyStockFilter, { ISupplyStockFilterValues } from './supplyStockFilter/SupplyStockFilter';
import { IAutocompletePaginatedListResponse, IAutocompletePaginatedRequest } from '../autocomplete/AutocompleteInput';
import { IGetSupplySelectItemResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
export interface ISupplyStockListProps {
    //propertys
    supplyStocks?: IGetSupplyStockListResponse;
    filter: IGetSupplyStockListRequest;
    isLoading: boolean;
    onChangeFilter: (filter: IGetSupplyStockListRequest) => void
    getSupplyLocalStorage: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
    getSupply: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<IGetSupplySelectItemResponse>>
    supplyStocksTotal?: ISupplyStockTotalResponse
}
const SupplyStockList: FC<ISupplyStockListProps> = ({
    supplyStocks,
    filter,
    isLoading,
    onChangeFilter,
    getSupplyLocalStorage,
    getSupply,
    supplyStocksTotal
}) => {

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ ...filter, page: page, pageSize: size })
    }, [filter, onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ ...filter, orderBy: sortBy.property, ascending: sortBy.ascending })
    }, [filter, onChangeFilter])

    const pagination = useMemo(() => ({
        page: supplyStocks?.page ?? 1,
        pageSize: 10,
        count: supplyStocks?.totalPages ?? 0
    }), [supplyStocks?.page, supplyStocks?.totalPages])

    const columnsConfig: ColumnConfigs<ISupplyStockItemResponse> = {
        code: { title: "Código", sortable: true },
        supplyName: { title: "Nome", sortable: true },
        supplyGroupName: { title: "Grupo", sortable: true },
        quantity: { title: "Quantidade", sortable: true },
        measurementUnitName: { title: "Unidade", sortable: true },
        storageLocationName: { title: "Local de Armazenamento", sortable: true },
        unitCost: { title: "Custo Unitário", sortable: true, renderRow: (value) => value.amount.toLocaleString('pt-BR', { style: 'currency', currency: value.currency }) },
        totalCost: { title: "Custo Total", sortable: true, renderRow: (value) => value.amount.toLocaleString('pt-BR', { style: 'currency', currency: value.currency })},
    }

    const onChnageFilter = useCallback((filter: ISupplyStockFilterValues) => {
        onChangeFilter({ ...filter, page: 1, pageSize: 10 })
    }, [onChangeFilter])
    return (
        <div id={styles.SupplyStockList} >
            <SupplyStockFilter
                defaultValue={filter}
                onSubmit={onChnageFilter}
                getSupplyLocalStorage={getSupplyLocalStorage}
                getSupply={getSupply}
            />

            <List
                loading={isLoading}
                minWidth={700}
                list={supplyStocks?.items}
                columnsConfigs={columnsConfig}
                // actionButtons={actionButtons}
                pagination={pagination}
                onChangePage={onChangePage}
                onClickSort={onClickSort}
                total
                valueTotal={supplyStocksTotal && supplyStocksTotal.amount.toLocaleString('pt-BR', { style: 'currency', currency: supplyStocksTotal.currency })}
            />
        </div>
    )
}


export default SupplyStockList;