import Button from "components/ui/Button/Button";
import styles from "./IntegratedPage.module.scss";
import { AddCircle, Search } from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import { Autocomplete, MenuItem, Pagination, TextField } from "@mui/material";
import Input from "components/ui/input/Input";
import MeepTicketsHeader from "../meepTicketsHeader/MeepTicketsHeader";
import MeepTicketsTable from "../meepTicketsTable/MeepTicketsTable";
import UseMeepTickets from "../../hooks/UseMeepTickets";
import { useEffect, useState } from "react";
import { IGetOrganizerEventParams } from "modules/meepTickets/domain/dto/IGetOrganizerEventParams";
import MeepTicketsTableMobile from "../meepTicketsTableMobile/MeepTicketsTableMobile";
import { BlurPremium } from "modules/plans/presentation/components/blurPremium/BlurPremium";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

const statusOptions = [
  {
    name: "Não publicado",
    value: "UNPUBLISHED",
  },
  {
    name: "Publicado",
    value: "PUBLISHED",
  },
  {
    name: "Finalizado",
    value: "FINISHED",
  },
]

const IntegratedPage = () => {
  const [getParams, setGetParams] = useState<IGetOrganizerEventParams>(
    {} as IGetOrganizerEventParams);
  const [search, setSearch] = useState<{ filter?: string, status?: string }>({});

  const [pageSize, setPageSize] = useState<string>("10");

  const { isMobile, dimensions } = UseDimension();
  const {
    isLoading,
    organizerEventData,
    totalPages,
    currentPage,
    nextPage,
    getOrganizerEvent
  } = UseMeepTickets();

  useEffect(() => {
    getOrganizerEvent(currentPage, Number(pageSize), getParams);
  }, [getOrganizerEvent, currentPage, pageSize, getParams]);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setGetParams({ ...getParams, filter: search.filter, status: search.status });
  };

  return (
    <div id={styles.IntegratedPage}>
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <h2>Ingressos</h2>
          <div className={styles.connectedTitle}>
            <p>Meep Tickets</p>
            <div className={styles.connected}>
              <div className={styles.greenCircle} />
              <span>Conectado</span>
            </div>
          </div>
          <p>
            Melhore a experiência de seu público e gerencie seu evento de forma prática
          </p>
        </div>

        <Button
          className={styles.buttonAdd}
          endIcon={<AddCircle fontSize="medium" />}
          style={{ width: isMobile ? "100%" : "172px" }}
          onClick={() =>
            window.open("https://produtor.meep.com.br/evento/novo", "_blank")
          }
        >
          Cadastrar evento
        </Button>

        <BlurPremium feature={FeatureTypeEnum.TICKETS}>
          <>
            <form
              style={{ flexDirection: isMobile ? "column" : "row" }}
              onSubmit={(ev) => handleSubmit(ev)}
            >
              <div className={styles.item}>
                <Input
                  placeholder={"Pesquisar evento"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<Search fontSize="small" />),
                  }}
                  value={search.filter}
                  onChange={(ev) => setSearch({ ...search, filter: ev.target.value })}
                />
              </div>
              <div className={styles.item}>
                <label>Status</label>
                <Autocomplete
                  disablePortal
                  options={statusOptions.map((it) => it.name)}
                  noOptionsText="Nenhuma opção encontrada"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filtrar por status"
                      InputProps={{
                        style: { padding: '1px 20px 1px 10px' },
                        ...params.InputProps,
                      }}
                    />
                  )}
                  onChange={(_, val: any) => {
                    const value = statusOptions.find((status) => status.name === val);
                    if (value) {
                      setSearch({ ...search, status: value.value });
                    } else {
                      setSearch({ ...search, status: "" });
                    }
                  }}
                />
              </div>
              <Button
                className={styles.buttonSearch}
                disabled={isLoading}
                style={{ width: isMobile ? "100%" : "80px" }}
                type="submit"
              >
                Buscar
              </Button>
            </form>

            <div>
              {organizerEventData && organizerEventData.length > 0 && (
                dimensions.width > 800
                  ? (<>
                    <MeepTicketsHeader getParams={getParams} setGetParams={setGetParams} />
                    <MeepTicketsTable data={organizerEventData} />
                  </>)
                  : <MeepTicketsTableMobile data={organizerEventData} />
              )}
              {!!totalPages && organizerEventData && organizerEventData.length > 0 && (
                <div className={styles.conteinerPagination}>
                  <div className={styles.boxMultiplePage}>
                    <span>Exibir</span>
                    <TextField
                      variant="outlined"
                      size="small"
                      select
                      value={pageSize}
                      defaultValue={pageSize}
                      onChange={(ev) => setPageSize(ev.target.value)}
                    >
                      <MenuItem className={styles.textPage} value={10}>
                        10
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={20}>
                        20
                      </MenuItem>
                      <MenuItem className={styles.textPage} value={50}>
                        50
                      </MenuItem>
                    </TextField>
                  </div>
                  {totalPages > 0 && (
                    <Pagination
                      variant="outlined"
                      className={styles.paginationNumber}
                      shape="rounded"
                      sx={{
                        ".MuiPaginationItem-root": {
                          borderColor: "#BDBDBD",
                          color: "#001537",
                        },
                        ".Mui-selected": {
                          fontWeight: "bold",
                          borderColor: "#001537",
                        },
                      }}
                      count={totalPages}
                      page={currentPage + 1}
                      onChange={(_, value) => nextPage(value - 1, Number(pageSize))}
                    />
                  )}
                </div>
              )}
              {(!organizerEventData || organizerEventData.length === 0) && (
                <div className={styles.emptyList}>
                  <img src="/assets/img/empty-box.png" alt="" />
                  <span>Você ainda <b>não possui</b> eventos cadastrados!</span>
                </div>
              )}
            </div>
          </>
        </BlurPremium>
      </div>
    </div>
  );
};
export default IntegratedPage;
