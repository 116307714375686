import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './AutocompleteEvents.module.scss'
import { IEventItemFilterAutocomplete, IEventListFilterAutocomplete, IRequestEventListFilter } from './FilterAutocompleteEventsList'
import { useMenuContext } from 'components/layout/presentation/menu/context/MenuContext'
export interface IAutocompleteEventsProps {
    //propertys
    getEventList: (request: IRequestEventListFilter) => void
    onChange: (value: { eventId?: string, cashierId: string }) => void,
    filter?: { eventId?: string },
    eventList?: IEventListFilterAutocomplete
    onLoading?: boolean
}
const AutocompleteEvents: FC<IAutocompleteEventsProps> = ({
    getEventList,
    onChange: onChangeEvent,
    filter,
    eventList,
    onLoading
}) => {
    const [value, setValue] = useState<IEventItemFilterAutocomplete>()
    const { id } = useParams<{ id: string }>()
    const getData = useCallback(async () => {
        getEventList({});
    }, [getEventList])

    const { menuPosition } = useMenuContext();

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        if (eventList?.records.length) {
            setValue(eventList?.records.find(item => item.eventId === filter?.eventId));
        }
    }, [eventList, eventList?.records, filter]);
    
    const onChange = useCallback((value: IEventItemFilterAutocomplete | null) => {
        if (value) {
            onChangeEvent({ eventId: value.eventId, cashierId: value.cashierId });
            setValue(value);
        }
    }, [onChangeEvent]);

    useEffect(() => {
        if (eventList?.records.length) {
            const eventId = localStorage.getItem("@eventId");
            const event = eventList.records.find(item => item.eventId === eventId);

            if (menuPosition === 1 && event) {
                onChange(event);
            }
        }
    }, [eventList?.records, menuPosition]);

    return (
        <div id={styles.AutocompleteEvents} >
            {!!eventList?.records && !id && <Autocomplete
                // disabled={onLoading}
                noOptionsText={onLoading ? "carregando" : "Na há eventos"}
                size='small'
                color='primary'
                placeholder={onLoading ? "Carregando" : "Evento"}
                options={eventList?.records ?? []}
                fullWidth
                getOptionLabel={item => item.eventName ? item.eventName : `${"Caixa: "}${new Date(item.cashierStartDate + "Z").toLocaleString()}${item.cashierEndDate != null ? (" - " + new Date(item.cashierEndDate + "Z").toLocaleString()) : ""}`}
                value={value}
                onChange={(_, value) => onChange(value)}
                renderInput={(params) => <TextField variant='outlined' label={"Eventos"} {...params} />}
            />
            }
            {/* {eventId} */}
            {value?.eventName && <h1>{value.eventName}</h1>}
        </div>
    )
}
export default AutocompleteEvents