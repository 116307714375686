import styles from './SkeletonMeepFeeList.module.scss'
import { Skeleton } from '@mui/material'

const SkeletonMeepFeeList = () => {
    return (
        <div id={styles.SkeletonMeepFeeList}>
            <div className={styles.listItems}>
                {new Array(20).fill(0).map(() => {
                    return <Skeleton variant='rectangular' style={{ width: '100%', height: 56 }}></Skeleton>;
                })}
            </div>
        </div>
    )
}
export default SkeletonMeepFeeList;