import React, { FC } from "react";
import FormHeader, { IFormHeaderProps } from "../formHeader/FormHeader";
import styles from "./FormContainer.module.scss";
import FooterButtons, {
  IFooterButtonsProps,
} from "modules/config/deviceConfig/presentation/components/footerButtons/FooterButtons";
export interface IFormContainerProps {
  //propertys
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onClickNext?: () => void;
  onClickClose?: () => void;
  onClickBack?: () => void;
  footerProps?: IFooterButtonsProps;
  headerProps?: IFormHeaderProps;
  disabledNext?: boolean;
}
const FormContainer: FC<IFormContainerProps> = ({
  children,
  title,
  subTitle,
  onClickClose,
  onClickBack,
  onClickNext,
  footerProps,
  disabledNext,
}) => {
  return (
    <div id={styles.FormContainer}>
      <FormHeader onClickClose={onClickClose} onClickBack={onClickBack}>
        {title}
      </FormHeader>
      {subTitle}
      {children}
      <FooterButtons
        {...footerProps}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        disabledNext={disabledNext}
      />
    </div>
  );
};
export default FormContainer;
