import { differenceInDays, format, parse } from 'date-fns'
import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IFilterValue, IlocalFilter, PeriodEnum } from '../filter/IFilter'
import { ISalesBar, ISalesBarConsumerInfo } from './interfaces/SalesBarData'
import styles from './SalesBarChart.module.scss'
export interface ISalesBarChartProps {
    defaultData?: ISalesBar
    getData?: () => Promise<ISalesBar>;
    selectedLocals: IlocalFilter[];
    filter?: IFilterValue;
}

const SalesBarChart: FC<ISalesBarChartProps> = ({ getData, defaultData, selectedLocals, filter }) => {

    const [data, setData] = useState<ISalesBar | undefined>(defaultData);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { clearContainerError, setOnError } = useError();
    const [consultDate, setConsultDate] = useState('');

    const column = 20;
    const gap = 4;

    const onGetData = useCallback(async () => {
        if (getData) {
            clearContainerError?.(dashboardContainerEnum.SALES_BAR);

            setIsLoading(true);
            try {
                const data = await getData?.();
                setData(data);

                setConsultDate(
                    format(new Date(), "HH'h'mm")
                )

                if (!data.records.length) {
                    setOnError({
                        type: 'warning',
                        containerId: dashboardContainerEnum.SALES_BAR
                    })
                }
            } catch (error: any) {
                setOnError({
                    type: 'error',
                    containerId: dashboardContainerEnum.SALES_BAR
                })
            } finally {
                setIsLoading(false);
            }

        }
    }, [clearContainerError, getData, setOnError])

    useEffect(() => {
        onGetData();
    }, [onGetData])

    const getColor = useCallback((localId: string) => {
        if (data?.records?.length) {
            const consumerInfo = data.records.reduce((acc, next) => [...acc, ...next.consumerInfo], [] as ISalesBarConsumerInfo[]);
            const color = consumerInfo.find(x => x.placeId === localId)?.color;

            return color ? '#' + color : '#cccccc';
        }
    }, [data?.records])

    const padding = (index: number, length: number) => {
        return ((column / 2 * length - gap) - (column + gap) * index) - (4 * gap / length - column / 2)
    }

    const getDayInfo = useCallback((label: string) => {
        const value = data?.records.find(item => item.date === label)
        return (
            <div id={styles.tooltip}>
                {label}
                {value?.consumerInfo.map(item =>

                    <div className={styles.localItem} style={{ color: "#" + (item.color || 'ccc') }}>
                        {/* {label} */}
                        <div className={styles.item}>
                            <div className={styles.icon} style={{ backgroundColor: "#" + (item?.color || 'cccccc') }} />
                            {formatCurrency(item.consumerValues[1]?.value)}
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon} style={{ backgroundColor: "#" + (item?.color || 'cccccc') + '99' }} />
                            {formatCurrency(item.consumerValues[2]?.value)}
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon} style={{ backgroundColor: "#" + (item?.color || 'cccccc') + '55' }} />
                            {formatCurrency(item.consumerValues[0]?.value)}
                        </div>
                        <div className={styles.item}>
                            <div className={styles.line} style={{ backgroundColor: "#" + (item?.color || 'cccccc') }} />
                            <span>
                                {formatCurrency(item.average)}
                            </span>
                        </div>
                        <div className={styles.item}>
                            <span className={styles.totalLabel}>
                                Total
                            </span>
                            <span>
                                {formatCurrency(item.consumerValues.reduce((acc, next) => acc + next.value ,0))}
                            </span>
                        </div>
                        {/* {item.consumerValues.map(item => <div className={styles.subItem}>
                            <label>{item.label}</label>
                            <span>{item.value}</span>
                        </div>)} */}
                    </div>
                ) ?? <div>div</div>
                }
            </div>
        )
    }, [data?.records])

    if (filter?.period === PeriodEnum.INTERVAL && filter.endDate && filter.startDate) {
        const startDate = parse(filter.startDate, 'yyyy-MM-dd', new Date());
        const endDate = parse(filter.endDate, 'yyyy-MM-dd', new Date());
        if (differenceInDays(endDate, startDate) > 7) {
            return (
                <div className={styles.forbidden}>
                    <img src="/assets/icon/calendar.svg" alt="" />
                    <p>O gráfico de faturamento é apresentado para períodos filtrados menores que 7 dias.</p>
                </div>
            )
        }
    }

    const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => (
              <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} fill="#666">
                <tspan textAnchor="middle" x="0">{payload.value.split(' ')[0]}</tspan>
                <tspan textAnchor="middle" x="0" dy="20">{payload.value.split(' ')[1]}</tspan>
              </text>
            </g>
      );

    return (

        <div id={styles.SalesBarChart} >
            {/* <h2>Faturamento</h2> */}
            {/* <pre>{JSON.stringify(data)}</pre> */}
            <div className={styles.legend}>
                <div>
                    <img src="/assets/icon/courve-legend.svg" alt="" />
                    <span>Faturamento do horário</span>
                </div>
                <div>
                    <div className={styles.legendColor} />
                    <span>{data?.records[0]?.consumerInfo[0]?.consumerValues[1]?.label}</span>
                </div>
                <div>
                    <div className={styles.legendColorLow} />
                    <span>{data?.records[0]?.consumerInfo[0]?.consumerValues[2]?.label}</span>
                </div>
                <div>
                    <div className={styles.legendColorLower} />
                    <span>{data?.records[0]?.consumerInfo[0]?.consumerValues[0]?.label}</span>
                </div>
                <div>
                    <span>Horário de consulta:</span>
                    <span>{consultDate}</span>
                </div>

            </div>
            <div
                className={styles.container}>
                <div
                    className={styles.chart}
                    style={{ width: '100vw', minWidth: selectedLocals.length * (data?.records.length ?? 1) * column + gap * 3 + 200 }}
                >
                    {

                        isLoading ? <div className={styles.animatedLoading}></div>
                            :
                            <ResponsiveContainer
                                width="100%"
                                height="100%"
                            >
                                <ComposedChart
                                    barGap={4}
                                    data={data?.records}
                                    margin={{
                                        top: 20,
                                        right: 50,
                                        bottom: 20,
                                        left: 50,
                                    }}
                                >
                                    {/* <CartesianGrid stroke="#f5f5f5" /> */}
                                    <XAxis 
                                        dataKey="date"
                                        tickLine={false}
                                        type='category'
                                        tick={<CustomizedAxisTick />}
                                    // label={{ value: 'Data', position: 'insideBottomRight', offset: '50%' }}

                                    // scale="band"
                                    />
                                    <YAxis

                                        tickLine={false}
                                        tickSize={8}
                                        tickFormatter={(value) => (new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(value))}
                                    // label={{ value: 'Consumo', angle: -90, position: 'insideLeft' }}
                                    />
                                    <Tooltip content={({ payload, label }) => getDayInfo(label)} />
                                    {/* <Legend /> */}

                                    {
                                        selectedLocals?.map((item, index, array) => (
                                            <Fragment key={index}>
                                                <XAxis hide xAxisId={item.id} dataKey="date" type='category' padding={{ left: -padding(index, array.length), right: padding(index, array.length) }} />
                                                <Line strokeWidth={3} type="monotone" xAxisId={item.id} dataKey={`consumerInfo[${index}].average`} z={2} startOffset={300} stroke={getColor(item.id)} />
                                                <Bar dataKey={`consumerInfo[${index}].consumerValues[1].value`} stackId={item.id} barSize={20} fill={getColor(item.id) + "FF"} />
                                                <Bar dataKey={`consumerInfo[${index}].consumerValues[2].value`} stackId={item.id} barSize={20} fill={getColor(item.id) + "99"} />
                                                <Bar dataKey={`consumerInfo[${index}].consumerValues[0].value`} stackId={item.id} barSize={20} fill={getColor(item.id) + "55"} />
                                                <Bar dataKey={`consumerInfo[${index}].consumerValues[3].value`} stackId={item.id} barSize={20} fill={getColor(item.id) + "22"} />
                                                <Bar dataKey={`consumerInfo[${index}].consumerValues[4].value`} stackId={item.id} barSize={20} fill={getColor(item.id) + "33"} />
                                            </Fragment>
                                        ))
                                    }
                                </ComposedChart>
                            </ResponsiveContainer>
                    }
                </div>
            </div>
        </div >
    )
}
export default SalesBarChart

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value || 0)
