import AutoComplete from 'components/ui/autoComplete/AutoComplete'
import Button from 'components/ui/Button/Button'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import { Icon, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import React, { FC, useCallback, useRef, useState } from 'react'
import { IAvalableDaysPlaceForm } from '../placeScheduleForm/interface/IAvalableDaysPlaceScheduleForm'
import styles from './AvailableDaysForm.module.scss'
import UseAvailableDaysForm, { IWeekDays } from './UseAvailableDaysForm'
import { IPlaceFormErros } from '../placeScheduleForm/interface/IPlaceFormErros'
export interface IAvailableDaysFormProps {
    values: IAvalableDaysPlaceForm[];
    onChange: (values: IAvalableDaysPlaceForm[]) => void;
    hasError?: IPlaceFormErros;
}
const AvailableDaysForm: FC<IAvailableDaysFormProps> = ({ values, onChange, hasError }) => {
    const {
        setselectedDay,
        selectedDay,
        setSelectedInicio,
        setSelectedFim,
        selectedInicio,
        selectedFim,
        onClickAdd,
        onClickRemoveItem,
        onClickEditItem,
        error,
        inputRef,
        weekDays,
        hideButtonEdit
    } = UseAvailableDaysForm(onChange, values)




    const dayWeekToString = (dayWeek: number) => {
        switch (dayWeek) {
            case 0:
                return "Domingo"
            case 1:
                return "Segunda"
            case 2:
                return "Terça"
            case 3:
                return "Quarta"
            case 4:
                return "Quinta"
            case 5:
                return "Sexta"
            case 6:
                return "Sábado"
            default:
                break;
        }
    }


    // function removeItensExistentes(item: IAvalableDaysPlaceForm) {
    //     let index = weekDays.findIndex(weekDay => weekDay.value === item.dayOfWeek);
    //     if (index !== -1) {
    //         weekDays.splice(index, 1);
    //     }
    // }

    return (
        <div id={styles.AvailableDaysForm} >
            <div className={styles.containerHelp}>
                <h2>Horários</h2>
                <TooltipInfo
                    title={"Horários"}
                    paragraph={"Define o horário de abertura do seu lugar de acordo com o dia da semana."}
                />
            </div>
            <div className={styles.container} >

                <FormItemContainer row>
                    <FormItemContainer flex={6} label={"Dia da semana"}>
                        <AutoComplete
                            key={selectedDay?.value}
                            inputRef={inputRef}
                            value={selectedDay}
                            options={weekDays.sort((a, b) => a.value - b.value)}
                            getOptionLabel={item => item.label}
                            onChange={(_, value) => { setselectedDay(value as { label: string, value: number }) }}
                        />
                    </FormItemContainer>
                    <FormItemContainer flex={2} label={"Inicio"}>
                        <Input value={selectedInicio} type={"time"} onChange={(ev) => { setSelectedInicio(ev.target.value) }} ></Input>
                    </FormItemContainer>
                    <FormItemContainer flex={2} label={"Fim"}>
                        <Input value={selectedFim} type={"time"} onChange={(ev) => { setSelectedFim(ev.target.value) }} ></Input>
                    </FormItemContainer>
                    <FormItemContainer flex={2} >
                        <Button disabled={!(selectedDay && selectedInicio && selectedFim)} className={!(selectedDay && selectedInicio && selectedFim) ? '' : styles.additional} variant="outlined" onClick={onClickAdd}>Adicionar</Button>
                    </FormItemContainer>
                </FormItemContainer>
                {error ?
                    <FormItemContainer flex={2} >
                        <div style={{ color: 'red', justifyContent: 'center', alignItems: "center" }}><Icon>error</Icon>{error}</div>
                    </FormItemContainer>
                    :
                    null
                }

                {

                    hasError && hasError.availableDays &&
                    <p className={styles.textError}>Necessário definir ao menos um horário para o serviço.</p>
                }

                {values?.map((item, index) => {
                    // removeItensExistentes(item);
                    return (
                        <div key={index} className={styles.horarioItem}>
                            <div className={styles.horarioLabel}>
                                {dayWeekToString(item.dayOfWeek)} - Abre: {item.startHour.substr(0, 5)} - Fecha: {item.endHour.substr(0, 5)}
                            </div>
                            {
                                hideButtonEdit ?
                                    <IconButton disabled onClick={() => onClickEditItem(item.dayOfWeek)}> <Edit /></IconButton>
                                    :
                                    <IconButton onClick={() => onClickEditItem(item.dayOfWeek)}> <Edit /></IconButton>

                            }
                            <IconButton onClick={() => onClickRemoveItem(index)}> <Delete /></IconButton>
                        </div>

                    );
                })}
            </div>
        </div>
    )
}
export default AvailableDaysForm