import { useHistory } from "react-router-dom";
import styles from "./HelpItem.module.scss";
import {
  FiberNew,
  OpenInNew,
  CastForEducation,
  Lightbulb,
  Close,
} from "@mui/icons-material";

interface HelpItemProps {
  onClose?: () => void;
  isMobile?: boolean;
}

export const HelpItem = ({ onClose, isMobile }: HelpItemProps) => {
  const { push } = useHistory();

  return (
    <div id={styles.HelpItem}>
      {isMobile && (
        <div className={styles.containerTitle}>
          <h1>Ajuda</h1>
          <Close onClick={onClose} />
        </div>
      )}
      <div
        className={styles.linkTop}
        onClick={() => push("/private/tutoriais")}
      >
        <CastForEducation />
        <span>Tutoriais</span>
      </div>
      <div
        className={styles.linkTop}
        onClick={() => push("/private/novidades")}
      >
        <Lightbulb />
        <span>Novidades</span>
      </div>
      {/* <div className={styles.linkTop}>
        <a
          href="https://api.whatsapp.com/send?phone=5531982031435"
          target="_blank" rel="noreferrer"
        >
          <img src="/assets/icon/mdi_face-agent.svg" />
          <span>Entrar em contato com o suporte</span>
        </a>
      </div>
      <div className={styles.footer}>
        <a
          href="https://meepdev.atlassian.net/wiki/spaces/RN/pages/32407785/Evolutivas+Portal"
          target="_blank"
        >
          <div className={styles.alignLink}>
            <FiberNew />
            <span>Novas funcionalidades!</span>
          </div>
          <OpenInNew />
        </a>
      </div> */}
    </div>
  );
};
