import { api } from "../Api";
import { ICrmService } from "modules/crm/models/interface/ICrmService";
import { IGetClientsResponse } from "modules/crm/models/dtos/IGetClientsResponse";
import { ICrmFilterRequest } from "modules/crm/models/dtos/ICrmFilterRequest";
import { IGetCustomeerEventRequest } from "modules/crm/models/dtos/customerEvents/IGetCustomeerEventRequest";
import { IGetCustomerEventResponse } from "modules/crm/models/dtos/customerEvents/IGetCustomerEventResponse";
import { IGetCrmEventsRequest } from "modules/crm/models/dtos/IGetCrmEventsRequest";
import { ISaveFilterRequest } from "modules/crm/models/dtos/ISaveFilterRequest";
import { IGetCrmEventsResponse } from "modules/crm/models/dtos/IGetCrmEventsResponse";
import { IGetSaveFilterResponse } from "modules/crm/models/dtos/IGetSaveFilterResponse";
import { ISaveFilterResponse } from "modules/crm/models/dtos/ISaveFilterResponse";
import { ICreateCampaignWithCustomersRequest } from "modules/crm/models/dtos/CreateCampaignWithCustomersRequest";
import { ICreateCampaignItemResponse } from "modules/crm/models/dtos/ICreateCampaignItemResponse";
import { IGetCampaignRequest } from "modules/crm/models/dtos/IGetCampaignRequest";
import { IGetCampaignsResponse, IGetCampaignValuesResponse } from "modules/crm/models/dtos/IGetCampaignResponse";
import { IAddCrmEventRequest } from "modules/crm/models/dtos/IAddCrmEventRequest";
import { IAddCrmEventResponse } from "modules/crm/models/dtos/IAddCrmEventResponse";
import { ISaveCampaignResponse } from "modules/crm/models/dtos/ISaveCampaignResponse";
import { ISaveCampaingRequest } from "modules/crm/models/dtos/ISaveCampaingRequest";
import { IGetWhatsappConfigurationCRMResponse } from "modules/crm/models/dtos/configurationCrm/whatsapp/IGetWhatsappConfigurationCRMResponse";
import { IGetWhatsappStatusResponse } from "modules/crm/models/dtos/configurationCrm/whatsapp/IGetWhatsappStatusResponse";
import { IGetWhasappQrCodeResponse } from "modules/crm/models/dtos/configurationCrm/whatsapp/IGetWhasappQrCodeResponse";
import { IGetCampaignMessagesRequest, IGetCampaignMessagesResponse } from "modules/crm/models/dtos/report/IGetCampaignMessagesResponse";
import { IGetCampaignMessageTotalsResponse } from "modules/crm/models/dtos/report/IGetCampaignStatusResponse";
import { IGetCrmPlanResponse } from "modules/crm/models/dtos/configurationCrm/plans/IGetCrmPlanResponse";
import { IGetSignatureDataResponse, IGetSignatureResponse, ISaveSignatureObject } from "modules/crm/models/dtos/configurationCrm/signature/IGetSignatureResponse";
import { IGetTotalReportResponse } from "modules/crm/models/dtos/configurationCrm/signature/IGetTotalReportResponse";
import qs from "qs";
import { crmUrl } from "Enviroment";

const CrmServiceApi = (): ICrmService => {
  const baseUrl = crmUrl ?? "https://crm-api.meep.cloud/api";
  // const baseUrl = "https://localhost:7236/api";

  const getClients = async (
    request: ICrmFilterRequest
  ): Promise<IGetClientsResponse> => {
    const response = await api.get(`${baseUrl}/Customers`, { params: request, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) });
    return response.data;
  };

  const getCustomerEvents = async (
    id: string,
    request: IGetCustomeerEventRequest
  ): Promise<IGetCustomerEventResponse> => {
    const response = await api.get<IGetCustomerEventResponse>(`${baseUrl}/CustomerEvents/` + id, { params: request });
    return response.data;
    // const response = await getCRMCustomerEventsMock(request);
    // return response;
  };

  const getEventsList = async (request: IGetCrmEventsRequest): Promise<IGetCrmEventsResponse> => {
    const response = await api.get<IGetCrmEventsResponse>(`${baseUrl}/Events`, { params: request });
    return response.data;
  };

  const getEventsByCategory = async (request: IGetCrmEventsRequest): Promise<IGetCrmEventsResponse> => {
    const response = await api.get<IGetCrmEventsResponse>(`${baseUrl}/EventCategories`, {
      params: request,
    });

    return response.data;
  };

  const getTicketCategory = async (request: IGetCrmEventsRequest): Promise<IGetCrmEventsResponse> => {
    const response = await api.get<IGetCrmEventsResponse>(`${baseUrl}/TicketCategories`, {
      params: request,
    });

    return response.data;
  };

  const getSavedFilter = async (request: { OwnerId: string }): Promise<IGetSaveFilterResponse> => {
    const response = await api.get<IGetSaveFilterResponse>(`${baseUrl}/SavedFilters`, {
      params: request,
    });

    return response.data;
  };

  const saveFilterClients = async (request: ISaveFilterRequest): Promise<ISaveFilterResponse> => {
    const response = await api.post<ISaveFilterResponse>(`${baseUrl}/SavedFilters`, request);
    return response.data;
  };

  const deleteFilter = async (id: string) => {
    const response = await api.delete(`${baseUrl}/SavedFilters/${id}`);
    return response.data
  }

  const createCampaignWithCustomersAndFilter = async (request: ICreateCampaignWithCustomersRequest): Promise<ICreateCampaignItemResponse> => {
    const response = await api.post<ICreateCampaignItemResponse>(`${baseUrl}/campaigns`, request);
    return response.data
  }
  const createCampaignWithCampaignId = async (campaignId: string): Promise<ICreateCampaignItemResponse> => {
    const response = await api.post<ICreateCampaignItemResponse>(`${baseUrl}/campaigns/create/${campaignId}`);
    return response.data
  }

  const getCampaings = async (request: IGetCampaignRequest): Promise<IGetCampaignsResponse> => {
    const response = await api.get<IGetCampaignsResponse>(`${baseUrl}/Campaigns?Sort.Property=createdAt&Sort.Orientation=desc`, { params: request })
    return response.data;
    // return await getCampaignsMock(request)
  }

  const getPushCampaigns = async (request: IGetCampaignRequest): Promise<IGetCampaignsResponse> => {

    const status = request.status?.map((item) => `&status=${item}`).join("") || undefined;

    const response = await api.get<IGetCampaignsResponse>(`${baseUrl}/Campaigns/pushCampaigns${status ? `?${status}` : ''}`)
    return response.data;
  }

  const getCampaingById = async (campaignId: string): Promise<IGetCampaignValuesResponse> => {
    const response = await api.get<IGetCampaignValuesResponse>(`${baseUrl}/Campaigns/${campaignId}`)
    return response.data;
    // return await getCampaignsMock(request)
  }

  const addEvent = async (event: IAddCrmEventRequest): Promise<IAddCrmEventResponse> => {
    const response = await api.post<IAddCrmEventResponse>(`${baseUrl}/events`, event);
    return response.data
  }

  const approve = async (campaignId: string): Promise<void> => {
    await api.post(`${baseUrl}/campaigns/${campaignId}/approve`);
  }

  const reprove = async (campaignId: string, reason: string): Promise<void> => {
    await api.post(`${baseUrl}/campaigns/${campaignId}/reprove`, { reason });
  }

  const saveCampaign = async (campaignId: string, request: ISaveCampaingRequest): Promise<ISaveCampaignResponse> => {
    const response = await api.put<ISaveCampaignResponse>(`${baseUrl}/Campaigns/${campaignId}`, request);
    return response.data
  }

  const sendCampaign = async (campaignId: string): Promise<void> => {
    const response = await api.post(`${baseUrl}/Campaigns/send/${campaignId}`);
    return response.data
  }

  const reSendCampaign = async (campaignId: string): Promise<void> => {
    const response = await api.post(`${baseUrl}/messages/resend/${campaignId}`);
    return response.data
  }

  const getConfiguration = async (ownerId: string): Promise<IGetWhatsappConfigurationCRMResponse> => {
    const response = await api.get<IGetWhatsappConfigurationCRMResponse>(`${baseUrl}/whatsapp/configuration/${ownerId}`);
    return response.data
  }
  const getWhatsappStatus = async (ownerId: string): Promise<IGetWhatsappStatusResponse> => {
    const response = await api.get<IGetWhatsappStatusResponse>(`${baseUrl}/whatsapp/status/${ownerId}`);
    return response.data
  }
  const getWhatsappQrCode = async (ownerId: string): Promise<IGetWhasappQrCodeResponse> => {
    const response = await api.get<IGetWhasappQrCodeResponse>(`${baseUrl}/whatsapp/qrcode/${ownerId}`);
    return response.data
  }

  const getCampaignMessages = async (campaignId: string, request: IGetCampaignMessagesRequest): Promise<IGetCampaignMessagesResponse> => {
    const response = await api.get<IGetCampaignMessagesResponse>(`${baseUrl}/messages/${campaignId}`, { params: request });

    return response.data
  }

  const getCampaignMessageTotals = async (campaignId: string): Promise<IGetCampaignMessageTotalsResponse> => {
    const response = await api.get<IGetCampaignMessageTotalsResponse>(`${baseUrl}/messages/status/${campaignId}`);
    return response.data
  }

  const sendMessagePreview = async (campaignId: string, postObject: { value: string }[]): Promise<void> => {
    await api.post(`${baseUrl}/Messages/send/preview/${campaignId}`, postObject)
  }

  const getTotalReport = async (localId: string): Promise<IGetTotalReportResponse[]> => {
    const response = await api.get(`${baseUrl}/Messages/totalReport`, {
      params: {
        ownerId: localId,
        disableError: true
      }
    })

    return response.data
  }

  const getPlans = async (): Promise<IGetCrmPlanResponse> => {
    const response = await api.get(`${baseUrl}/plans`);
    return response.data
  }
  const getSignature = async (ownerId: string): Promise<IGetSignatureResponse> => {
    const response = await api.get(`${baseUrl}/Signatures`, { params: { ownerId } });
    return response.data
  }

  const saveSignature = async (object: ISaveSignatureObject): Promise<IGetSignatureDataResponse> => {
    const response = await api.post(`${baseUrl}/Signatures/`, object);
    return response.data
  }
  const stopCampaing = async (campaignId: string): Promise<void> => {
    await api.put(`${baseUrl}/campaigns/stopSend/${campaignId}`);
  }

  const exportXlsxClients = async (
    request: ICrmFilterRequest
  ): Promise<BigUint64Array> => {
    const response = await api.get(`${baseUrl}/Export/customer-report/excel`, { 
      params: request, 
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: "arraybuffer",
    });
    return response.data;
  };

  const exportCsvClients = async (
    request: ICrmFilterRequest
  ): Promise<BigUint64Array> => {
    const response = await api.get(`${baseUrl}/Export/customer/csv`, { 
      params: request, 
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: "arraybuffer",
    });
    return response.data;
  };

  return {
    getClients,
    getCustomerEvents,
    getEventsList,
    getEventsByCategory,
    getSavedFilter,
    saveFilterClients,
    getTicketCategory,
    deleteFilter,
    createCampaignWithCustomersAndFilter,
    createCampaignWithCampaignId,
    getCampaings,
    getCampaingById,
    addEvent,
    saveCampaign,
    getConfiguration,
    getWhatsappStatus,
    getWhatsappQrCode,
    sendCampaign,
    getCampaignMessages,
    getCampaignMessageTotals,
    reSendCampaign,
    sendMessagePreview,
    getPlans,
    getSignature,
    saveSignature,
    stopCampaign: stopCampaing,
    getTotalReport,
    approve,
    reprove,
    getPushCampaigns,
    exportXlsxClients,
    exportCsvClients,
  };
};

export default CrmServiceApi;
