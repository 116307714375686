import { useEffect, useState } from 'react';

import Layout from 'components/layout/presentation/Layout';
import styles from './DigitalMenuConfigPage.module.scss';
import generalStyles from './DigitalMenuGeneral.module.scss';
import DigitalMenuConfigForm from '../components/digitalMenuConfigForm/DigitalMenuConfigForm';
import { UseGestaoVendas } from 'modules/gestaoVendas/presentation/hooks/UseGestaoVendas';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

const DigitalMenuConfigPage = () => {
  const { address, handleSaveAddress } = UseGestaoVendas(true);
  const [headerTexts, setHeaderTexts] = useState({ title: '', titleHighlight: '', subtitle: '' });

  const { currentLocal } = useLocal();

  useEffect(() => {
    // saas user texts
    if (currentLocal?.systemIdentifier === 1) {
      setHeaderTexts({
          title: 'Cardápio',
          titleHighlight: 'digital',
          subtitle: 'O cardápio digital é uma inovação que transforma a maneira como clientes interagem com o seu negócio.'
      });
    } else {
        // regular user texts
        setHeaderTexts({
          title: 'Configure o perfil',
          titleHighlight: 'do seu estabelecimento para o App',
          subtitle: 'Seja descoberto por muitas pessoas! Potencialize suas vendas e otimize sua operação!'
      });
    }
  }, [currentLocal?.systemIdentifier]);

  return (
    <Layout>
      <div className={styles.digitalMenuConfigPageWrapper}>
        <div className={styles.digitalMenuContent}>
          <p className={generalStyles.digitalMenuTitle}>
            <span>{headerTexts.title}</span> {headerTexts.titleHighlight}
          </p>

          <p className={generalStyles.digitalMenuSubtitle}>
            {headerTexts.subtitle}
          </p>

          <DigitalMenuConfigForm currentAddress={address} addressHandlerFn={handleSaveAddress} />
        </div>

        <img className={styles.digitalMenuSideImage} src="/assets/img/digital-menu-phone-preview.png" alt="digital-menu-phone-preview" />
      </div>
    </Layout>
  )
}

export default DigitalMenuConfigPage