import { IFilterDeviceRequest } from "modules/equipamento/domain/dto/getDevice/IFilterDeviceRequest";
import { IGetDeviceListResponse } from "modules/equipamento/domain/dto/getDevice/IGetDeviceListResponse";
import { IDeviceService } from "modules/equipamento/domain/interface/IDeviceService";
import { IFilterDevice } from "modules/equipamento/presentation/components/filterDevice/IFilterDevice";


const GetDeviceUseCase = async (localId: string, deviceService: IDeviceService, deviceFilter?: IFilterDevice): Promise<IGetDeviceListResponse> => {
    const filter: IFilterDeviceRequest = {
        types: deviceFilter?.types?.map(x => Number(x.id)),
        profileIds: deviceFilter?.profiles?.map(x => x.id),
        offset: deviceFilter?.pagination?.offset ?? 0,
        limit: deviceFilter?.pagination?.limit ?? 50,
        query: deviceFilter?.query,
    }
    const response = await deviceService.GetDeviceList(localId, filter);
    return response
}

export default GetDeviceUseCase