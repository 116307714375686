import { useCallback, useEffect, useState } from "react";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import InvoiceApi from "services/api/invoice/InvoiceApi";
import { GetCompanyUseCase } from "modules/invoice/application/useCases/GetCompanyUseCase";
import { CreateCompanyUseCase } from "modules/invoice/application/useCases/CreateCompanyUseCase";
import { ICompany } from "../interfaces/ICompany";

const service = InvoiceApi();
const integrationService = IntegrationApi();

export const UseInvoicePage = () => {
    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const history = useHistory();
    const { subscription } = usePlans();

    const [preLoading, setPreLoading] = useState(false);
    const [company, setCompany] = useState<ICompany>();
    const [loading, setLoading] = useState(false);
    const [companyStatus, setCompanyStatus] = useState<"notCreated" | "creating" | "created">("notCreated");
    const [localDocument, setLocalDocument] = useState("");
    const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);

    const getData = useCallback(async () => {
        if (currentLocal) {
        try {
            setPreLoading(true);
            const response = await GetCompanyUseCase(service, currentLocal.id);
            setCompany(response);
            if (!!response && !response.isComplete) {
            history.push("/private/invoice/form");
            } else if (response.isComplete) {
            setCompanyStatus("created");
            }
        } catch (err: any) {
            const error = JSON.parse(err);
            if (error?.message?.includes("404")) {
            setCompanyStatus("notCreated")
            } else if (error?.message?.includes("404")) {
            setCompanyStatus("creating");
            } else {
            toast("Houve um erro ao buscar os dados do estabelecimento. Tenta novamente.", "error");
            }
        }
        finally {
            setPreLoading(false);
        }
        }
    }, [currentLocal, history, toast]);

    const getLocalMainData = useCallback(async () => {
        if (currentLocal) {
        const response = await GetLocalMainDataUseCase(integrationService, currentLocal.id);
        setLocalDocument(response.document);
        }
    }, [currentLocal]);

    useEffect(() => {
        getData();
        if (subscription?.isTrial) {
            getLocalMainData();
        }
    }, [getData, getLocalMainData, subscription?.isTrial]);

    const handleCreateEnvironment = useCallback(async () => {
        if (subscription?.isTrial && localDocument === "28302604000101") {
          return setOpenSimpleTrialModal(true);
        }
        if (currentLocal) {
            try {
              setLoading(true);
              await CreateCompanyUseCase(service, currentLocal.id);
              getData();
            } catch {
              toast("Houve um erro ao criar o ambiente. Tenta novamente.", "error");
            } finally {
              setLoading(false);
            }
        }
    }, [currentLocal, getData, localDocument, subscription?.isTrial, toast]);

    return {
        preLoading,
        company,
        loading,
        companyStatus,
        localDocument,
        openSimpleTrialModal,
        setOpenSimpleTrialModal,
        handleCreateEnvironment
    }
}
