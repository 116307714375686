import { useCallback, useState } from "react";
import { IPaymentLInkList } from "../interfaces/IPaymentLInkList";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import PaymentLinkService from "services/api/paymentLink/PaymentLinkService";
import GetPaymentLinkUseCase from "modules/paymentLink/application/useCases/GetPaymentLinkUseCase";
import { IGetPaymentLinkListParams } from "../interfaces/IGetPaymentLinkListParams";

const UsePaymentLinkList = () => {
  const { currentLocal } = useLocal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentLinkList, setPaymentLinkList] =
    useState<IPaymentLInkList | null>(null);

  const getPaymentLinkList = useCallback(
    async (params?: IGetPaymentLinkListParams) => {
      try {
        const service = PaymentLinkService();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPaymentLinkUseCase(
            service,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter
          );

          setPaymentLinkList(response);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );

  return {
    isLoading,
    paymentLinkList,
    getPaymentLinkList,
  };
};

export default UsePaymentLinkList;
