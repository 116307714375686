import { Button, Icon, IconButton, MenuItem } from "@material-ui/core";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { DateRangerPicker } from "components/dateRangerPicker/DateRangerPicker";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import {
  addDays,
  addMinutes,
  differenceInDays,
  format,
  isValid,
  parse,
  subDays,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { ISelect } from "interfaces/ISelect";
import { IDiscountCouponsReportFilter } from "modules/config/discountCoupons/presentation/components/discountCouponFilter/interfaces/IDiscountCouponReportFilter";
import { IGetSessionsList } from "modules/config/discountCoupons/presentation/interfaces/IGetSessionsList";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "./ReportCouponFilter.module.scss";
import { Clear } from "@mui/icons-material";

export interface IReportCouponFilterProps {
  onSubmit: (filter: IDiscountCouponsReportFilter) => void;
  sessionItem: IGetSessionsList | undefined;
  reportDeviceList: ISelect[];
}

export enum EnumFilterType {
  CAIXA = 1,
  PERIODO,
}

const MAX_PERIOD_RANGE = 30;

const defaultDevice = {
  id: "",
  name: "Selecione o equipamento",
};

export const ReportCouponFilter: React.FC<IReportCouponFilterProps> = ({
  onSubmit,
  sessionItem,
  reportDeviceList,
}) => {
  const [values, setValues] = useState<IDiscountCouponsReportFilter>();

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [filterBy, setFilterBy] = useState(EnumFilterType.CAIXA);
  const [disabledButton, setDisabledButton] = useState(false);

  const [selectedDefaultDevice, setSelectedDefaultDevice] =
    useState(defaultDevice);

  const changeHandleMinValue = useCallback((valueMin: string) => {
    setValues((prev) => ({
      ...prev,
      minValue: Number(valueMin) === 0 ? undefined : Number(valueMin),
    }));
  }, []);
  const changeHandleMaxValue = useCallback((valueMax: string) => {
    setValues((prev) => ({
      ...prev,
      maxValue: Number(valueMax) === 0 ? undefined : Number(valueMax),
    }));
  }, []);
  const changeHandleCustomerName = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, customerName: value }));
  }, []);

  const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);

  const onClickShowAdevancedFilterHandle = useCallback(() => {
    setShowAdvancedFilter((prev) => !prev);
  }, []);

  const changeHandleValueType = useCallback((valueType: string) => {
    const type = Number(valueType);
    if (type === 1) {
      setFilterBy(EnumFilterType.CAIXA);
      setValues((prev) => ({
        ...prev,
        createdEndDate: undefined,
        createdStartDate: undefined,
      }));
    } else {
      setFilterBy(EnumFilterType.PERIODO);
      setValues((prev) => ({ ...prev, cashierId: undefined }));
    }
  }, []);

  const formatDate = (date?: Date | null) => {
    if (!date || !isValid(date)) return undefined;
    return format(
      new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000),
      "yyyy-MM-dd"
    );
  };

  const mountDate = (date?: string) => {
    if (!date) return new Date();

    return addMinutes(new Date(date), new Date(date).getTimezoneOffset());
  };

  const handleChangeStartDate = useCallback(
    (startDate: Date | null) => {
      let endDate = values?.createdEndDate
        ? parse(values?.createdEndDate, "yyyy-MM-dd", new Date())
        : new Date();

      if (endDate && startDate && isValid(endDate)) {
        const diff = differenceInDays(endDate, startDate);
        if (diff > MAX_PERIOD_RANGE) {
          endDate = addDays(startDate, MAX_PERIOD_RANGE);
        } else if (diff < 0) {
          endDate = startDate;
        }
      }

      setValues((prev) => ({
        ...prev,
        createdStartDate: formatDate(startDate),
        createdEndDate: formatDate(endDate),
      }));
    },
    [values]
  );

  const handleChangeEndDate = useCallback(
    (endDate: Date | null) => {
      let startDate = values?.createdStartDate
        ? parse(values?.createdStartDate, "yyyy-MM-dd", new Date())
        : new Date();

      if (startDate && endDate && isValid(startDate)) {
        const diff = differenceInDays(endDate, startDate);
        if (diff > MAX_PERIOD_RANGE) {
          startDate = subDays(endDate, MAX_PERIOD_RANGE);
        } else if (diff < 0) {
          startDate = endDate;
        }
      }
      setValues((prev) => ({
        ...prev,
        createdStartDate: formatDate(startDate),
        createdEndDate: formatDate(endDate),
      }));
    },
    [values]
  );

  const handleChangeSession = useCallback(
    (property: string, value: string | undefined | null) => {
      setValues((prev) => ({
        ...prev,
        [property]: value ?? "",
      }));
    },
    []
  );

  const changeHandleCPF = useCallback(
    (cpf: string) => {
      if (!!cpf.length && cpf.length !== 4) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
      setValues((prev) => ({ ...prev, customerCPF: cpf }));
    },
    [setValues]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const createdStart = new Date(values?.createdStartDate ?? "");
      const createdEnd = new Date(values?.createdEndDate ?? "");
      values &&
        onSubmit({
          ...values,
          createdStartDate: values.createdStartDate
            ? new Date(
                createdStart.getFullYear(),
                createdStart.getMonth(),
                createdStart.getDate() + 1,
                0,
                0,
                0,
                0
              ).toISOString()
            : undefined,
          createdEndDate: values.createdEndDate
            ? new Date(
                createdEnd.getFullYear(),
                createdEnd.getMonth(),
                createdEnd.getDate() + 1,
                23,
                59,
                59
              ).toISOString()
            : undefined,
        });
    },
    [onSubmit, values]
  );

  useEffect(() => {
    const peridoInitialDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
      0,
      0,
      0,
      0
    ).toISOString();
    const periodFinishDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
      0
    ).toISOString();

    if (filterBy === EnumFilterType.PERIODO) {
      setValues((prev) => ({
        ...prev,
        createdStartDate: peridoInitialDate,
        createdEndDate: periodFinishDate,
      }));
    }
  }, [filterBy]);

  return (
    <div id={styles.ReportCouponFilter}>
      <form onSubmit={submitHandle}>
        <div className={styles.containerFilter}>
          <div className={styles.content}>
            <div className={styles.itemCoupon}>
              <label>Filtrar por</label>
              <TextField
                fullWidth
                inputProps={{ maxLength: 50 }}
                size="small"
                placeholder="Filtrar por"
                value={filterBy}
                variant="outlined"
                select
                onChange={(ev) => changeHandleValueType(ev.target.value)}
              >
                <MenuItem value={EnumFilterType.CAIXA}>Caixa</MenuItem>
                <MenuItem value={EnumFilterType.PERIODO}>Período</MenuItem>
              </TextField>
            </div>
            {filterBy === EnumFilterType.CAIXA && (
              <div className={styles.item}>
                <label>Caixa</label>
                <AutoComplete
                  variant="outlined"
                  options={sessionItem?.items ?? []}
                  getOptionLabel={(option) => option.name}
                  placeholder="Selecione o caixa"
                  onChange={(_, value: any) => {
                    handleChangeSession("cashierId", value.id);
                  }}
                />
              </div>
            )}

            {filterBy === EnumFilterType.PERIODO && (
              <div className={styles.item}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <div className={styles.itemDate}>
                    <DesktopDatePicker
                      value={mountDate(values?.createdStartDate)}
                      disableFuture
                      minDate={new Date("2017-01-01")}
                      onChange={(newValue) => {
                        handleChangeStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <div
                          className={`${styles.inputContainer} ${styles.inputDateContainer}`}
                        >
                          <label>Período utilização - ínicio</label>

                          <TextField
                            {...params}
                            size="small"
                            className={`${styles.datePicker} dashboardFilterDatePicker`}
                            type="date"
                          />
                        </div>
                      )}
                    />
                    <DesktopDatePicker
                      value={mountDate(values?.createdEndDate)}
                      disableFuture
                      minDate={new Date("2017-01-01")}
                      onChange={(newValue) => {
                        handleChangeEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <div
                          className={`${styles.inputContainer} ${styles.inputDateContainer}`}
                        >
                          <label>Período utilização - fim</label>

                          <TextField
                            {...params}
                            size="small"
                            className={`${styles.datePicker} dashboardFilterDatePicker`}
                            type="date"
                          />
                        </div>
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            )}
          </div>
          <div className={styles.containerButton}>
            {!showAdvancedFilter && (
              <Button
                variant={"contained"}
                className={
                  disabledButton ? styles.disabledButton : styles.button
                }
                disabled={disabledButton}
                fullWidth
                style={{ height: 40, borderRadius: 8, width: 110 }}
                type="submit"
              >
                Buscar
              </Button>
            )}
          </div>
        </div>
        <div className={styles.advancedFilterButton}>
          <Button
            className={styles.buttonFilterAdvanced}
            onClick={onClickShowAdevancedFilterHandle}
          >
            Filtros avançados
            {!showAdvancedFilter ? <Icon>sort</Icon> : <Icon>close</Icon>}
          </Button>
        </div>

        {showAdvancedFilter && (
          <div className={styles.advancedFilter}>
            <div className={styles.content}>
              <div className={styles.up}>
                <div className={styles.header}>
                  <label>Nome do cliente</label>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    size="small"
                    placeholder="Insira o nome do cliente"
                    variant="outlined"
                    name="customerName"
                    onChange={(ev) => changeHandleCustomerName(ev.target.value)}
                  />
                </div>
                <div className={styles.main}>
                  <label>CPF</label>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    placeholder="Filtrar pelos 4 últimos números do CPF"
                    name="customerCPF"
                    variant="outlined"
                    onChange={(ev) =>
                      changeHandleCPF(ev.target.value.substring(0, 4))
                    }
                    value={values?.customerCPF}
                  />
                </div>
                <div className={styles.footer}>
                  <label>Código</label>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    placeholder="Filtrar por código de desconto"
                    name="code"
                    variant="outlined"
                    onChange={changeHandle}
                  />
                </div>
              </div>
              <div className={styles.down}>
                <div className={styles.header}>
                  <label>Pedido</label>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 6 }}
                    size="small"
                    placeholder="Filtrar por nº do pedido"
                    name="orderId"
                    variant="outlined"
                    onChange={changeHandle}
                  />
                </div>
                <div className={styles.itemValue}>
                  <div className={styles.item}>
                    <label> Valor de desconto aplicado</label>
                    <TextField
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      type="number"
                      placeholder={"R$ min."}
                      variant="outlined"
                      name={"valueMin"}
                      onChange={(ev) => changeHandleMinValue(ev.target.value)}
                    />
                  </div>
                  <div className={styles.valueMax}>
                    <TextField
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      type="number"
                      placeholder={"R$ max."}
                      variant="outlined"
                      name={"valueMax"}
                      onChange={(ev) => changeHandleMaxValue(ev.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <label>Equipamento de utilização</label>
                  <Autocomplete
                    disableClearable
                    options={[defaultDevice, ...(reportDeviceList ?? [])]}
                    getOptionLabel={(option) => option.name}
                    value={selectedDefaultDevice}
                    onChange={(_, value: any) => {
                      setSelectedDefaultDevice(value);
                      handleChangeSession("deviceId", value.id);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </div>
                <div className={styles.containerButton}>
                  <Button
                    variant={"contained"}
                    className={
                      disabledButton ? styles.disabledButton : styles.button
                    }
                    disabled={disabledButton}
                    fullWidth
                    style={{ height: 40, borderRadius: 8, width: 110 }}
                    type="submit"
                  >
                    Buscar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
