import Layout from "components/layout/presentation/Layout";
import StockPage from "./StockPage";

const Estoque = () => {
  return (
    <Layout>
      <StockPage />
    </Layout>
  );
};

export default Estoque;
