import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';
import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import AvailableDaysForm from '../availableDaysForm/AvailableDaysForm';
import { ISelect } from './ColaboratorScheduleForm';
import styles from './ColaboratorScheduleForm.module.scss'
import { AvalableDayItemSchedule, ICollaboratorScheduleFormValue } from './ColaboratorScheduleFormValue'

interface OperatorSelect {
    id: string;
    name: string;
}

const UseColaboratorScheduleForm = (operators: OperatorSelect[], services: ISelect[], onSubmit: (value: ICollaboratorScheduleFormValue) => void, defaultValues?: ICollaboratorScheduleFormValue,) => {
    const [values, setValues] = useState<ICollaboratorScheduleFormValue>(defaultValues ?? {
        operatorId: "",
        name: "",
        description: "--",
        imageUrl: "",
        maximumLeadTimeInDays: 0,
        minimumLeadTimeInDays: 0,
        image: "",
        servicesIds: [],
        availableDays: []

    });

    const [selectedOperator, setSelectedOperator] = useState<OperatorSelect>();

    const [selectedService, setSelectedService] = useState<ISelect[]>();


    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const RouterPrefix = "/private/agendamento/list/"

        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Colaboradores',
                url: RouterPrefix + 'colaborador'
            },
            {
                title: 'Adicionar colaborador',
            },
        ])
    }, [updateRouters]);

    useEffect(() => {
        if (defaultValues && operators.length && services.length) {
            setValues({
                operatorId: defaultValues.operatorId,
                name: defaultValues.name,
                description: "--",
                maximumLeadTimeInDays: 0,
                minimumLeadTimeInDays: 0,
                imageUrl: defaultValues.image,
                image: defaultValues.imageUrl,
                servicesIds: defaultValues.servicesIds,
                availableDays: defaultValues.availableDays
            })
            setSelectedOperator(operators.find(item => item.id === defaultValues.operatorId) ?? operators[0]);
            setSelectedService(services?.filter(item => (defaultValues.servicesIds.includes(item.id))) ?? [])

        } else {
            setSelectedService([]);
            setSelectedOperator(operators[0]);
        }
    }, [defaultValues, operators, services])



    const validate = useCallback(() => {
        return true //TODO: implement validate
    }, [values, operators, services])

    const onSubmitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (validate()) {
            if (selectedOperator && !!selectedService) {

                const submitValue: ICollaboratorScheduleFormValue = {
                    ...values,
                    operatorId: selectedOperator.id,
                    servicesIds: selectedService.map(item => item.id),
                }
                onSubmit(submitValue);

            }
        }
    }, [onSubmit, selectedOperator, selectedService, validate, values]);


    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const onChangeAvailableDaysHandle = useCallback((availableValues: AvalableDayItemSchedule[]) => {
        setValues(prev => ({ ...prev, availableDays: availableValues }));
    }, [])

    const handleChangeSingleImage = useCallback((image: string) => {
        setValues(prev => ({ ...prev, imageUrl: image }));
    }, [])

    const handleChangeOperator = useCallback((operator: OperatorSelect) => {
        // setValues(prev => ({ ...prev, operatorId: operator.id }));
        setSelectedOperator(operator);
    }, [])

    const handleChangeService = useCallback((service: ISelect[]) => {
        // setValues(prev => ({ ...prev, servicesIds: [service.id] }));
        setSelectedService(service)
    }, [])

    return (
        {
            values,
            selectedOperator,
            selectedService,
            onSubmitHandle,
            changeHandle,
            onChangeAvailableDaysHandle,
            handleChangeSingleImage,
            handleChangeOperator,
            handleChangeService
        }
    )
}
export default UseColaboratorScheduleForm