import { FC } from "react";
import { ILocalItem, ILocalResumeEvent } from "../../interfaces/ILocalResume";
import { Circle } from "@mui/icons-material";
import styles from "./LocalCardItem.module.scss";
import { Icon } from "@material-ui/core";
export interface ILocalCardItemProps {
  //propertys
  item: ILocalItem;
  onClick: (value: ILocalItem) => void;
}
const LocalCardItem: FC<ILocalCardItemProps> = ({ item, onClick }) => {
  return (
    <div id={styles.LocalCardItem} onClick={() => onClick(item)}>
      <div className={styles.statusItem}>
        {item.cashierClosing ? (
          <>
            <Circle className={styles.iconGray} />
            <span>Fechado</span>
          </>
        ) : (
          <>
            <Circle className={styles.iconGreen} />
            <span>Aberto</span>
          </>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.image}>
          <img
            src={item.imgUrl}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/icon/placeholder.svg";
            }}
          />
        </div>
        <div className={styles.detail}>
          <span className={[styles.localName, item.cashierClosing ? "" : styles.localOpened].join(" ")}>{item.name}</span>
          {item.cashierOpening && (
            <div className={[styles.eventDetail, item.cashierClosing ? styles.localClosed : ""].join(" ")}>
              <div className={styles.item}>
                <label>
                  {item.cashierOpening && (
                    <>
                      <Icon fontSize="small" className={item.cashierClosing ? "" : styles.iconLocalOpened}>lock_open_outlined</Icon>
                      {new Date(item.cashierOpening).toLocaleString("pt-BR", {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </>
                  )}
                </label>
              </div>
              {item.cashierClosing && (
                <div className={styles.item}>
                  <label>
                    <Icon fontSize="small">lock_outlined</Icon>
                    {new Date(item.cashierClosing).toLocaleString("pt-BR", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LocalCardItem;
