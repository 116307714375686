
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetCollaboratorsByLocalIdUseCase from 'modules/schedule/application/useCases/collaboratorSchedule/GetCollaboratorsByLocalIdUseCase';
import React, { createContext, FC, useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import ColaboratorScheduleApi from 'services/api/schedule/colaborator/ColaboratorScheduleApi';
import { ICollaboratorScheduleItem } from './ICollaboratorList';
import {DeleteCollaboratorUseCase} from 'modules/schedule/application/useCases/collaboratorSchedule/DeleteCollaboratorUseCase';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

const RouterPrefix = "/private/agendamento/list/"

export const UseCollaboratorListSchedulePage = () => {
    const [collaborators, setCollaborators] = useState<ICollaboratorScheduleItem[]>();
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading, toast } = useUi();
    const { push } = useHistory();
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleModalRemoveCollaborator, setToggleModalRemoveCollaborator] = useState(false);
    const { updateRouters } = useBreadcumbs();
    const { tab } = useParams<{ tab: string }>();

    const handleModal = () => {
        setToggleModal(!toggleModal)
    };

    const [currentCollaborator, setCurrentCollaborator] = useState <ICollaboratorScheduleItem | null> (null) ;  

    const handleModalRemoveCollaborator = () => {
        setToggleModalRemoveCollaborator(!setToggleModalRemoveCollaborator)
    }

    const deleteCollaborator = (id: string) => {
        showLoading()
        DeleteCollaboratorUseCase(id, ColaboratorScheduleApi).then((response) => {
            toast("Colaborador removido com sucesso!", "success");
                getCollaboratorsList();
                setToggleModalRemoveCollaborator(false);

        }).catch((error) => {
            toast("Algo deu errado, tente novamente!", "error");
        }).finally(() => {
            setToggleModalRemoveCollaborator(false);
            hideLoading();
        });
    };

    const getCollaboratorsList = useCallback(
        () => {
            if (currentLocal) {
                showLoading();
                const service = ColaboratorScheduleApi();

                GetCollaboratorsByLocalIdUseCase(service, currentLocal.id).then((response) => {
                    setCollaborators(response.map((item) => ({
                        id: item.id,
                        name: item.name,
                        operatorId: item.operatorId,
                        // services: ServiceSchedule[],
                        // availableDays: AvailableDate[],
                        isEnabled: item.isEnabled,
                        description: item.description,
                    })));
                }).finally(() => {
                    hideLoading()
                })
            }
        },
        [currentLocal, hideLoading, showLoading],
    )

    useEffect(() => {
        getCollaboratorsList();
    }, [currentLocal, getCollaboratorsList])

    useEffect(() => {
        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Colaboradores',
                url: RouterPrefix + tab
            }
        ])
    }, [updateRouters]);

    const onClickEdit = useCallback((value: { id: string }) => {
        push(`/private/agendamento/colaborador/editar/${value.id}`);
    }, [push])
    const onClickAdd = useCallback(() => {
        push(`/private/agendamento/colaborador/adicionar/`);
    }, [push])


    return ({ 
        collaborators, 
        onClickEdit, 
        onClickAdd, 
        handleModal, 
        handleModalRemoveCollaborator, 
        deleteCollaborator,
        toggleModal,
        toggleModalRemoveCollaborator,
        setToggleModalRemoveCollaborator,
        setCurrentCollaborator,
        currentCollaborator });


};
