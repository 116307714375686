import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetSplitLocalsUseCase from "modules/products/application/useCases/getSplitLocalsUseCase/GetSplitLocalsUseCase";
import GetCategoriesListUseCase from "modules/products/application/useCases/getCategoriesListUseCase/GetCategoriesListUseCase";
import { IGetSplitLocalsResponse } from "modules/products/domain/dto/IGetSplitLocalsResponse";
import GetCashlessTagsUseCase from "modules/saleForProduct/application/GetCashlessTagsUseCase";
import GetOperatorByLocalIdUseCase from "modules/saleForProduct/application/GetOperatorByLocalIdUseCase";
import PostSaleForProductUseCase from "modules/saleForProduct/application/PostSaleForProductUseCase";
import { IGetCashlessTagsResponse } from "modules/saleForProduct/domain/dto/IGetCashlessTagsResponse";
import { IGetOperatorByLocalIdResponse } from "modules/saleForProduct/domain/dto/IGetOperatorByLocalIdResponse";
import { IPostSaleForProductParams } from "modules/saleForProduct/domain/dto/IPostSaleForProductParams";
import { IPostSaleForProductResponse } from "modules/saleForProduct/domain/dto/IPostSaleForProductResponse";
import GetProductsUseCase from "modules/ticketsReport/application/useCases/GetProductsUseCase";
import { IProduct } from "modules/ticketsReport/presentation/components/filter/IProduct";
import { useCallback, useState } from "react";
import { ProductsApi } from "services/api/products/ProductsApi";
import SaleForProductReportApi from "services/api/saleForProductReport/SaleForProductReportApi";
import TicketsReportService from "services/api/ticketsReport/TicketsReportService";

const forProductApi = SaleForProductReportApi();
const productService = ProductsApi();
const ticketsReportService = TicketsReportService();

export const UseSaleForProductPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<IPostSaleForProductResponse>();
  const [operators, setOperators] = useState<IGetOperatorByLocalIdResponse[]>([]);
  const [categoriesList, setCategoriesList] = useState<{
    id: string;
    name: string;
  }[]>([]);
  const [cashlessTags, setCashlessTags] = useState<IGetCashlessTagsResponse[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [locaisSplit, setLocaisSplit] = useState<IGetSplitLocalsResponse[]>([])

  const { currentLocal } = useLocal();

  const postSaleForProduct = useCallback(
    async (params: IPostSaleForProductParams) => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          const response = await PostSaleForProductUseCase(forProductApi, { ...params, localId: currentLocal.id });
          setReportData(response);
        } catch (error: any) {
          return error.response;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentLocal]
  );

  const getAdvancedFiltersOptions = useCallback(
    async () => {
      if (currentLocal) {
        try {
          setIsLoading(true);
          const promises = [
            GetOperatorByLocalIdUseCase(forProductApi, currentLocal.id),
            GetCategoriesListUseCase(productService, currentLocal.id),
            GetCashlessTagsUseCase(forProductApi, currentLocal.id),
            GetProductsUseCase(ticketsReportService, currentLocal.id),
            GetSplitLocalsUseCase(productService, currentLocal.id)
          ];

          const response: Array<any> = await Promise.all(promises);

          setOperators(response[0]);
          setCategoriesList(response[1]);
          setCashlessTags(response[2]);
          setProducts(response[3]);
          setLocaisSplit(response[4])
        } catch (error: any) {
          return error.response;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [currentLocal]
  );

  return {
    isLoading,
    reportData,
    categoriesList,
    cashlessTags,
    operators,
    products,
    locaisSplit,
    postSaleForProduct,
    getAdvancedFiltersOptions
  }
}