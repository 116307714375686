import Layout from "components/layout/presentation/Layout";
import { AddReserveSchedulePage } from "modules/schedule/presentation/pages/addReseveSchedule/AddReserveSchedulePage";
import React, { FC } from "react";

const AddReserveSchedule: FC = () => {
  return (
    <Layout>
      <AddReserveSchedulePage></AddReserveSchedulePage>
    </Layout>
  );
};
export default AddReserveSchedule;
