import qs from "qs";
import { IFilterDeviceRequest } from "../../domain/dto/IFilterDeviceRequest";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";
import { IDeviceItem } from "../../presentation/components/couponForm/components/equipamentForm/interfaces/IDeviceItem";
import { IParamsClientDeviceList } from "../../presentation/hooks/UseDeviceList";


const GetDeviceListUseCase = async (deviceService: IDiscountCouponsService, localId: string, deviceFilter?: number[]): Promise<IDeviceItem[]> => {

    const response = await deviceService.getDeviceList(localId, deviceFilter);
    return response.items
}


export default GetDeviceListUseCase