import { ImportExport } from "@mui/icons-material";
import styles from "./CargosHeader.module.scss";
import { IGetRegisteredRolesListParams } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams";
import { useEffect, useState } from "react";

export interface ICargosHeaderProps {
  getParams: IGetRegisteredRolesListParams;
  setGetParams: React.Dispatch<React.SetStateAction<IGetRegisteredRolesListParams>>;
}

const titleHeader = [
  {
    title: "Cargo",
    value: "Name",
  },
  {
    title: "Permissões",
    value: "PermissionCount",
  },
  {
    title: "Colaboradores",
    value: "EmployeeCount",
  },
];

const CargosHeader = ({ getParams, setGetParams }: ICargosHeaderProps) => {
  const [orderBy, setOrderBy] = useState<{
    ordemColumnDesc: boolean,
    orderByColumn: string,
  }>({ ordemColumnDesc: false, orderByColumn: "Name" });

  useEffect(() => {
    if (getParams.ordemColumnDesc !== orderBy.ordemColumnDesc || getParams.orderByColumn !== orderBy.orderByColumn) {
      setGetParams({
        ...getParams,
        ordemColumnDesc: orderBy.ordemColumnDesc,
        orderByColumn: orderBy.orderByColumn,
      });
    }
  }, [orderBy]);

  return (
    <div id={styles.CargosHeader}>
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            <div
              onClick={() => {
                setOrderBy({
                  ordemColumnDesc: !orderBy?.ordemColumnDesc,
                  orderByColumn: item.value,
                });
              }}
            >
              <ImportExport />
            </div>
          </div>          
        );
      })}
      <div className={styles.last}/>
    </div>
  );
};
export default CargosHeader;
