import { FC, useEffect, useRef, useState } from "react";
import styles from "./CashlessDashboardPage.module.scss";
import UseCashlessDashboardPage from "./UseCashlessDashboardPage";
import GridDashboard from "./components/gridDashboard/GridDashboard";
import DashboarContainer from "./components/dashboarContainer/DashboarContainer";
import Filter from "./components/filter/Filter";
import { dashboardContainerEnum } from "./interfaces/DashboardContainerEnum";
import FinancialData from "./components/financialData/FinancialData";
import RechargePaymentMethods from "./components/rechargePaymentMethods/RechargePaymentMethods";
import RechargeOrigin from "./components/rechargeOrigin/RechargeOrigin";
import PieRechargeOrigin from "./components/rechargeOrigin/pieRechargeOrigin/PieRechargeOrigin";
import { UseRechargeOrigin } from "./components/rechargeOrigin/UseRechargeOrigin";
import AverageTicket from "./components/averageTicket/AverageTicket";
import { UseAverageTicket } from "./components/averageTicket/UseAverageTicket";
import BarAverageTicket from "./components/averageTicket/barAverageTicket/BarAverageTicket";
import PieCanceled from "./components/canceled/pieCanceled/PieCanceled";
import { UseCanceled } from "./components/canceled/UseCanceled";
import Canceled from "./components/canceled/Canceled";
import { UseCardsActivation } from "./components/cardsActivation/UseCardsActivation";
import CardsActivation from "./components/cardsActivation/CardsActivation";
import BarCardsActivation from "./components/cardsActivation/barCardsActivation/BarCardsActivation";
import TopOperators from "./components/topOperators/TopOperators";
import TopCustomers from "./components/topCustomers/TopCustomers";
import TopDevices from "./components/topDevices/TopDevices";
import { Icon, Radio } from "@material-ui/core";
import TopProducts from "./components/topProducts/TopProducts";
import Categories from "./components/categories/Categories";
import { IOriginType } from "./components/rechargeOrigin/interfaces/IOriginType";
import { ProductionInfo } from "./components/productionInfo/productionInfo";
import RechargeMethods from "./components/rechargePaymentMethods/RechargeMethods";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import PreRechargePaymentMethods from "./components/preRechargePaymentMethods/PreRechargePaymentMethods";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { format } from "date-fns";
import { VerticalAlignBottomOutlined } from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";

const CashlessDashboardPage: FC = () => {
  const {
    filter,
    filterType,
    cashiers,
    type,
    setType,
    onChangeFilterHandle,
    getFinancialData,
    getRechargePaymentMethodsData,
    getTopOperators,
    getTopCustomers,
    getTopDevices,
    getTopProducts,
    getCategories,
    getCashiersByPeriod,
    getCategoryProducts,
    getRechargeMethodsData,
    exportTopOperators,
    exportTopCustomers,
    exportTopDevices,
    exportTopProducts,
    getProductionInfo,
    exportCategories,
    setFilterType,
    firstData,
    cachedAt : dateCached,
    preRechargeData,
    triggerOperator,
    expiresAt,
    triggerCustomer,
    triggerDevices,
    triggerProducts,
    triggerCategories
  } = UseCashlessDashboardPage();

  const cardsActivation = UseCardsActivation(filter);
  const rechargeOrigin = UseRechargeOrigin(filter);
  const averageTicket = UseAverageTicket(filter);
  const canceled = UseCanceled(filter);
  const { isMobile } = UseDimension();
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();

  const [cachedAt, setCachedAt] = useState();
  const [expireAt, setExpireAt] = useState();
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [onGeneratingPDF, setOnGeneratingPDF] = useState(false);

  const pdfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expireAt) {
      setShowRefreshButton(false);
      const expireAtDate = new Date(expireAt);

      const now = new Date();

      const secondsToExpire = (
        (expireAtDate.getTime() - now.getTime()) /
        1000
      ).toString();

      const handleTime = parseInt(secondsToExpire.replaceAll(".", ""));

      setTimeout(() => {
        setShowRefreshButton(true);
      }, handleTime);
    }
  }, [expireAt]);

  const generatePDF = async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF();
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = 1900;
    const padding = 10;

    const elements = pdfRef.current.querySelectorAll(".div-to-capture");
    const divs = Array.from(elements).filter((element) => element instanceof HTMLElement);

    let currentHeight = 0;
    let divsByPage: Element[] = [];
    let pages: Element[] = [];
    
    for (const div of divs) {
      const { height } = div.getBoundingClientRect();

      if (currentHeight + height < pageHeight) {
        currentHeight += height;
        divsByPage.push(div);
      } else {
        const uniqueDiv = document.createElement("div");
        
        divsByPage.forEach(element => {
          uniqueDiv.appendChild(element.cloneNode(true));
        });
        
        pages.push(uniqueDiv);
        currentHeight = height;
        divsByPage = [div];
      }
    }

    if (divsByPage.length) {
      const uniqueDiv = document.createElement("div");

      divsByPage.forEach(element => {
        uniqueDiv.appendChild(element.cloneNode(true));
      });
      
      pages.push(uniqueDiv);
    }

    for (let i = 0; i < pages.length; i++) {
      const tempDiv = pages[i] as HTMLElement;
      tempDiv.style.width = "1400px";
      
      if (i === pages.length - 1 && i >= 2) {
        tempDiv.style.height = "100vh";
      }

      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", padding, padding, imgWidth - 2 * padding, imgHeight - 2 * padding);

      if (i !== pages.length - 1) {
        pdf.addPage();
      }

      document.body.removeChild(tempDiv);
    }

    setOnGeneratingPDF(false);
    hideLoading();

    const fileName = "cashless.pdf";
    pdf.save(fileName);
  };

  return (
    <div id={styles.CashlessDashboardPage}>
      <div className={styles.upperContent}>
        <div className={styles.updateDashboard}>
          <span className={styles.refreshMessage}>
            Atualizado em:{" "}
            {dateCached &&
              format(new Date(dateCached), 'dd/MM/yyyy - HH:mm')
            }
          </span>
          {showRefreshButton && (
            <>
              <button
                className={styles.refreshButton}
                onClick={() => onChangeFilterHandle(filter)}
              >
                <Icon>refresh</Icon> Atualizar
              </button>
              <Tooltip
                title="Atualizar"
                text="Os dados são atualizados a cada 5 minutos. Ao passar esse tempo basta clicar em “Atualizar” para que o dashboard mostre os dados mais recentes."
              />
            </>
          )}
        </div>
        {!isMobile && (
          <div
            className={styles.export}
            onClick={() => {
              setOnGeneratingPDF(true);
              showLoading();
              setTimeout(() => {
                generatePDF();
              }, 2000);
            }}
          >
            <span>Exportar</span>
            <VerticalAlignBottomOutlined />
          </div>
        )}
      </div>
      <GridDashboard>
        <DashboarContainer isChartContainer={false}>
          <Filter
            onSubmit={(value) => onChangeFilterHandle(value)}
            defaultValues={filter}
            filterType={filterType}
            setFilterType={setFilterType}
            cashiers={cashiers}
            getCashiersByPeriod={getCashiersByPeriod}
          />
        </DashboarContainer>

          {!!cashiers.length && (
            <>
              <div ref={pdfRef}>
                {onGeneratingPDF && (
                  <div className={`${"div-to-capture"} ${styles.pdfTitle}`}>
                    <h2>{currentLocal?.name}</h2>
                    <h3>Dashboard Cashless</h3>
                    <DashboarContainer
                      id={dashboardContainerEnum.FINANCIAL_DATA}
                      title={filterType === "period" ? "Período" : "Caixa"}
                      span={6}
                      borderless
                      mainTitle
                      hideIcons={true}
                    >
                      <div className={styles.pdfCashier}>
                        {filter.cashierIds.map((item) => (
                          <span key={item.id}>{item.name}</span>
                        ))}
                      </div>
                    </DashboarContainer>
                  </div>
                  )}
                <GridDashboard>
                  <div className="div-to-capture">
                    <DashboarContainer
                      id={dashboardContainerEnum.FINANCIAL_DATA}
                      title="Financeiro"
                      span={6}
                      borderless
                      mainTitle
                      hideIcons={true}
                    >
                      <FinancialData getData={getFinancialData} onGeneratingPDF={onGeneratingPDF} />
                    </DashboarContainer>
                  </div>
                  {
                    firstData &&
                    <>
                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.RECHARGE_PAYMENT_DATA}
                          span={6}
                          title="Formas de Pagamento Fichas"
                          tooltip={"Separação de formas de pagamento do total faturado."}
                          borderless
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <RechargePaymentMethods getData={getRechargeMethodsData} onGeneratingPDF={onGeneratingPDF} />
                        </DashboarContainer>
                      </div>

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.RECHARGE_PAYMENT_DATA}
                          span={6}
                          title="Formas de Pagamento Cashless"
                          tooltip={"Separação de formas de pagamento do total recarregado."}
                          borderless
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <RechargeMethods getData={getRechargePaymentMethodsData} onGeneratingPDF={onGeneratingPDF} />
                        </DashboarContainer>
                      </div>

                    {preRechargeData && preRechargeData.total > 0 &&
                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.PRE_RECHARGE}
                          span={6}
                          title="Pré-recarga"
                          tooltip={"Formas de pagamento da pré-recarga."}
                          borderless
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <PreRechargePaymentMethods defaultData={preRechargeData} onGeneratingPDF={onGeneratingPDF} />
                        </DashboarContainer>
                      </div>
                    }
                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.CARDS_ACTIVATION}
                          title="Cartões"
                          span={6}
                          hideIcons={true}
                          mainTitle
                          showData
                        >
                          <CardsActivation {...cardsActivation} triggerCategories={triggerCategories} triggerProducts={triggerProducts} onGeneratingPDF={onGeneratingPDF} triggerOperator={triggerOperator} triggerCustomer={triggerCustomer} triggerDevices={triggerDevices} expiresAt={expiresAt}/>
                        </DashboarContainer>
                      </div>

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.CARDS_ACTIVATION_CHART}
                          title="Cartões por horário"
                          tooltip="Esse gráfico de linhas mostra todo período de um caixa e a relação de quantidade de cartões ativados e desativados por horário. Por mostrar somente um caixa, quando mais de um caixa for selecionado no filtro, temos a escolha de qual deles será analisado no gráfico."
                          span={6}
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <BarCardsActivation filter={filter} {...cardsActivation} onGeneratingPDF={onGeneratingPDF} />
                        </DashboarContainer>
                      </div>

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.RECHARGE_ORIGIN}
                          title="Origem"
                          hideIcons={true}
                          span={3}
                          mainTitle
                          showData
                        >
                          <RechargeOrigin {...rechargeOrigin} triggerCategories={triggerCategories} triggerOperator={triggerOperator} onGeneratingPDF={onGeneratingPDF} triggerProducts={triggerProducts} expiresAt={expiresAt} triggerCustomer={triggerCustomer} triggerDevices={triggerDevices}/>
                        </DashboarContainer>
                      </div>

                      {!onGeneratingPDF && (
                        <div className="div-to-capture">
                          <DashboarContainer
                            id={dashboardContainerEnum.RECHARGE_ORIGIN_CHART}
                            title="Gráfico de origem"
                            tooltip="Esse gráfico de pizza mostra a proporção de cada equipamento diante do valor total.O valor total varia entre recarga e consumo sendo alterado com um filtro."
                            span={3}
                            mainTitle
                            showData
                            hideIcons={onGeneratingPDF}
                          >
                            <PieRechargeOrigin {...rechargeOrigin} />
                          </DashboarContainer>
                        </div>
                      )}

                      {onGeneratingPDF && (
                        <>
                          <div className="div-to-capture">
                            <DashboarContainer
                              id={dashboardContainerEnum.RECHARGE_ORIGIN_CHART}
                              title="Gráfico de origem"
                              tooltip="Esse gráfico de pizza mostra a proporção de cada equipamento diante do valor total.O valor total varia entre recarga e consumo sendo alterado com um filtro."
                              span={3}
                              mainTitle
                              showData
                              hideIcons={onGeneratingPDF}
                            >
                              <PieRechargeOrigin {...rechargeOrigin} defaultType={IOriginType.recharged} onGeneratingPDF={onGeneratingPDF} />
                            </DashboarContainer>
                          </div>

                          <div className="div-to-capture">
                            <DashboarContainer
                              id={dashboardContainerEnum.RECHARGE_ORIGIN_CHART}
                              title="Gráfico de origem"
                              tooltip="Esse gráfico de pizza mostra a proporção de cada equipamento diante do valor total.O valor total varia entre recarga e consumo sendo alterado com um filtro."
                              span={3}
                              mainTitle
                              showData
                              hideIcons={onGeneratingPDF}
                            >
                              <PieRechargeOrigin {...rechargeOrigin} defaultType={IOriginType.consumed} onGeneratingPDF={onGeneratingPDF} />
                            </DashboarContainer>
                          </div>
                        </>
                      )}

                      <div className="div-to-capture">
                        <DashboarContainer
                          title="Produção"
                          tooltip="Esse bloco é voltado para valores da forma de pagamento de produção no período selecionado. Valor total de recarga, valor total de consumo e valor total de vendas em fichas."
                          span={3}
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <ProductionInfo
                            getProductionData={getProductionInfo}
                            setCachedAt={setCachedAt}
                            setExpireAt={setExpireAt}
                          />
                        </DashboarContainer>
                      </div>

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.AVERAGE_TICKET}
                          title="Ticket médio"
                          hideIcons={true}
                          span={3}
                          mainTitle
                          showData
                        >
                          <AverageTicket {...averageTicket} onGeneratingPDF={onGeneratingPDF} triggerCustomer={triggerCustomer} triggerProducts={triggerProducts} triggerCategories={triggerCategories} triggerOperator={triggerOperator} expiresAt={expiresAt} triggerDevices={triggerDevices}/>
                        </DashboarContainer>
                      </div>

                      {!onGeneratingPDF && (
                        <div className="div-to-capture">
                          <DashboarContainer
                            id={dashboardContainerEnum.AVERAGE_TICKET_CHART}
                            title="Ticket médio por horário"
                            tooltip="Gráfico de barras relacionando o ticket médio (total, masculino, feminino e sexo não informado) ao horário de um caixa. Por mostrar somente um caixa, quando mais de um caixa for selecionado no filtro, temos a escolha de qual deles será analisado no gráfico."
                            span={6}
                            mainTitle
                            showData
                            hideIcons={onGeneratingPDF}
                          >
                            <BarAverageTicket filter={filter} {...averageTicket} onGeneratingPDF={onGeneratingPDF} />
                          </DashboarContainer>
                        </div>
                      )}

                      {onGeneratingPDF && (
                        <>
                          <div className="div-to-capture">
                            <DashboarContainer
                              id={dashboardContainerEnum.AVERAGE_TICKET_CHART}
                              title="Ticket médio por horário"
                              tooltip="Gráfico de barras relacionando o ticket médio (total, masculino, feminino e sexo não informado) ao horário de um caixa. Por mostrar somente um caixa, quando mais de um caixa for selecionado no filtro, temos a escolha de qual deles será analisado no gráfico."
                              span={6}
                              mainTitle
                              showData
                              hideIcons={onGeneratingPDF}
                            >
                              <BarAverageTicket filter={filter} {...averageTicket} onGeneratingPDF={onGeneratingPDF} defaultType={IOriginType.recharged} />
                            </DashboarContainer>
                          </div>

                          <div className="div-to-capture">
                            <DashboarContainer
                              id={dashboardContainerEnum.AVERAGE_TICKET_CHART}
                              title="Ticket médio por horário"
                              tooltip="Gráfico de barras relacionando o ticket médio (total, masculino, feminino e sexo não informado) ao horário de um caixa. Por mostrar somente um caixa, quando mais de um caixa for selecionado no filtro, temos a escolha de qual deles será analisado no gráfico."
                              span={6}
                              mainTitle
                              showData
                              hideIcons={onGeneratingPDF}
                            >
                              <BarAverageTicket filter={filter} {...averageTicket} onGeneratingPDF={onGeneratingPDF} defaultType={IOriginType.consumed} />
                            </DashboarContainer>
                          </div>
                        </>
                      )}

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.CANCELED}
                          title="Cancelamentos"
                          span={3}
                          hideIcons
                          mainTitle
                          showData
                        >
                          <Canceled {...canceled} onGeneratingPDF={onGeneratingPDF} triggerOperator={triggerOperator} triggerCategories={triggerCategories} triggerProducts={triggerProducts} triggerCustomer={triggerCustomer} expiresAt={expiresAt} triggerDevices={triggerDevices}/>
                        </DashboarContainer>
                      </div>

                      <div className="div-to-capture">
                        <DashboarContainer
                          id={dashboardContainerEnum.CANCELED_CHART}
                          title="Gráfico de cancelamentos"
                          tooltip="Esse gráfico de pizza mostra a proporção de cada tipo de cancelamento diante do total no período selecionado."
                          span={3}
                          mainTitle
                          showData
                          hideIcons={onGeneratingPDF}
                        >
                          <PieCanceled {...canceled} />
                        </DashboarContainer>
                      </div>
                    </>
                  }
                </GridDashboard>
              </div>

              {firstData &&
                <>
                  <DashboarContainer
                    title="Resumo da operação"
                    borderless
                    hideIcons
                    mainTitle
                  >
                    <div className={styles.filter}>
                      <div>
                        <Radio
                          size="small"
                          checked={type === IOriginType.recharged}
                          onChange={(_, checked) => setType(IOriginType.recharged)}
                        />{" "}
                        <span>Recarga</span>
                      </div>
                      <div>
                        <Radio
                          size="small"
                          checked={type === IOriginType.consumed}
                          onChange={(_, checked) => setType(IOriginType.consumed)}
                        />{" "}
                        <span>Consumo (cashless e ficha)</span>
                      </div>
                    </div>
                  </DashboarContainer>

                  <DashboarContainer
                    id={dashboardContainerEnum.TOP_OPERATORS}
                    title={"Top operadores"}
                    tooltip="Rankings dos melhores operadores no período selecionado. Depedendendo da escolha do filtro serão carregados os operadores que mais fizeram recarga ou que realizaram mais consumos."
                    span={2}
                    onExport={exportTopOperators}
                  >
                    <TopOperators type={type} getTopOperators={getTopOperators} />
                  </DashboarContainer>

                  <DashboarContainer
                    id={dashboardContainerEnum.TOP_CUSTOMERS}
                    title={"Top clientes"}
                    tooltip="Rankings dos melhores clientes no período selecionado. Depedendendo da escolha do filtro serão carregados os clientes que mais fizeram recarga ou que realizaram mais consumos."
                    span={2}
                    onExport={exportTopCustomers}
                  >
                    <TopCustomers type={type} getTopCustomers={getTopCustomers} />
                  </DashboarContainer>

                  <DashboarContainer
                    id={dashboardContainerEnum.TOP_DEVICES}
                    title={"Top dispositivos"}
                    tooltip="Rankings dos melhores equipamentos no período selecionado. Depedendendo da escolha do filtro serão carregados os equipamentos que mais fizeram recarga ou que realizaram mais consumos."
                    span={2}
                    onExport={exportTopDevices}
                  >
                    <TopDevices type={type} getTopDevices={getTopDevices} />
                  </DashboarContainer>

                  <span className={styles.consumacaoProdutos}>
                    Consumação de produtos
                  </span>

                  <DashboarContainer
                    id={dashboardContainerEnum.TOP_PRODUCTS}
                    title={"Top produtos"}
                    tooltip="Rankings dos produtos que foram mais consumidos no período selecionado."
                    span={3}
                    onExport={exportTopProducts}
                  >
                    <TopProducts getTopProducts={getTopProducts} />
                  </DashboarContainer>

                  <DashboarContainer
                    id={dashboardContainerEnum.CATEGORIES}
                    title={"Consumo por categoria"}
                    tooltip="Rankings das categorias de produtos que mais foram consumidos no período selecionado."
                    span={3}
                    onExport={exportCategories}
                  >
                    <Categories
                      getCategoriesData={getCategories}
                      getCategoryProductsData={getCategoryProducts}
                    />
                  </DashboarContainer>
                </>
              }
            </>
          )}
      </GridDashboard>
    </div>
  );
};

export default CashlessDashboardPage;
