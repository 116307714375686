import {
  ExportSalesFormatEnum,
  SortPaymentsInterface,
  SortProductInterface,
  UseExportSalesReport,
  UseSales,
} from "services/api/outputreport";
import styles from "./OutputReport.module.scss";
import { ExportSideSheet, Filter, ReportPaymentsList } from "./components";
import { ReportProductsList } from "./components/reportProducts/ReportProductsList";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";
import { Skeleton } from "@mui/material";

const OutputReport = () => {
  const { currentLocal } = useLocal();
  const [filter, setFilter] = useState<any>({});
  const [openExportSideSheet, setOpenExportSideSheet] = useState(false);
  const [sortProducts, setSortProducts] = useState<SortProductInterface>();
  const [sortPayments, setSortPayments] = useState<SortPaymentsInterface>();
  const [exportType, setExportType] = useState<ExportSalesFormatEnum>();
  const [listType, setListType] = useState<string | undefined>("product");
  const [queryEnabled, setQueryEnabled] = useState(false);
  const { data, isRefetching, isLoading, refetch, isSuccess } = UseSales({
    localId: currentLocal?.id as string,
    enabled: queryEnabled,
    ...filter,
    ...sortProducts,
    ...sortPayments,
  });
  const { toast } = useUi();

  const resolveTableHeader = useCallback((sales: number, payment: number) => {
    const value: number = sales - payment;

    switch (Math.sign(value)) {
      case 0:
        return undefined;
      case 1:
        return "sales";
      case -1:
        return "payments";
      default:
        return undefined;
    }
  }, []);

  const renderTableHeader = useCallback((sales?: number, payments?: number) => {
    if (!sales || !payments) return;

    const result = resolveTableHeader(sales, payments);

    if (result === "sales") {
      return (
        <div className={styles.resumeTotal}>
          <div>
            <p>
              A receber{" "}
              <Tooltip
                title="A Receber"
                text="Quando o valor total de produtos for MAIOR que o valor total de formas de pagamento, significa que existe valor a receber. (Pós-pago)"
              />
            </p>
            <strong>{moneyMaskNumber((sales ?? 0) - (payments ?? 0))}</strong>
          </div>
        </div>
      );
    }

    if (result === "payments") {
      return (
        <div className={styles.resumeTotal}>
          <div>
            <p>
              Saldo remanescente{" "}
              <Tooltip
                title="Saldo remanescente"
                text="Quando o valor total de produtos for MENOR que o valor total de formas de pagamento, significa que existe valor de saldo residual. (Pré-pago)"
              />
            </p>
            <strong>{moneyMaskNumber((payments ?? 0) - (sales ?? 0))}</strong>
          </div>
        </div>
      );
    }
  }, [resolveTableHeader]);

  const { refetch: refetchexportSalesReport } = UseExportSalesReport({
    ...filter,
    localId: currentLocal?.id as string,
    exportFormat: exportType,
    onSuccess: () => {
      setOpenExportSideSheet(false);
      toast("Relatório solicitado com sucesso.", "success");
    },
    ónError: () => {
      toast(
        "Não foi possível solicitar o envio do relatório, tente novamente.",
        "error"
      );
    },
  });

  const fetchData = (data: any) => {
    setFilter(data);
    setQueryEnabled(true);
    setTimeout(() => {
      refetch(data);
    }, 500);

    return;
  };

  return (
    <div id={styles.OutputReporter}>
      <Filter
        fetchData={fetchData}
        setListType={setListType}
        setOpenExportSideSheet={setOpenExportSideSheet}
        openExportSideSheet={openExportSideSheet}
      />
      <div className={styles.container}>
        {renderTableHeader(data?.Sales.TotalNet, data?.Payments.Balance)}
        {listType === "product" && (
          <ReportProductsList
            products={data}
            isLoading={isRefetching}
            isSuccess={isSuccess}
            setSortProducts={setSortProducts}
          />
        )}
        {listType === "payment" && (
          <ReportPaymentsList
            payments={data}
            isSuccess={isSuccess}
            isLoading={isRefetching}
            setSortPayments={setSortPayments}
          />
        )}
        {isLoading && (
          <div className={styles.gridContainer}>
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>
        )}
      </div>
      <ExportSideSheet
        open={openExportSideSheet}
        title="Exportar"
        onClose={() => setOpenExportSideSheet(false)}
        onConfirm={() => refetchexportSalesReport()}
        isLoadingExport={false}
        exportType={exportType}
        changeSelectExportType={setExportType}
      />
    </div>
  );
};

export { OutputReport };
