export enum dashboardContainerEnum {
    LOCAL_RESUME = 'localResume',
    SALES_BAR = 'salesBar',
    CARD_VALUES = 'cardValues',
    GRADIENT_TABLE = 'gradientTable',
    RANKING_PRODUCTS = 'rankingProducts',
    RANKING_OPERATORS = 'rankingOperators',
    RANKING_CATEGORY = 'rankingCategory',
    RANKING_SUBCATEGORY = 'rankingSubgategory',
    GIRO_MESA = 'turnoverTable',
    RANKING_CLIENTS = 'rankingClients',
    RANKING_DEVICE = 'rankingDevice',
    SALES_PLACE = "salesPlace"
}