import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { IDiscountListForm, IDiscountProductType } from "../../interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "../../interfaces/IProductList";
import styles from "./DiscountProductForm.module.scss";
import UseDiscountProductForm from "./UseDiscountProductForm";

export interface IDiscountProductFormProps {
  //propertys
  discountsList: IDiscountListForm[];
  onSubmit: (value: IDiscountListForm[]) => void;
  productList: IProductItemToDiscount[];
  onClose?: () => void;
}
const DiscountProductForm: FC<IDiscountProductFormProps> = ({ onSubmit, onClose, productList, discountsList }) => {
  const {
    discount,
    products,
    type,
    submitHandle,
    changeProductHandle,
    changeDiscountTypeHandle,
    changeDiscountValueHandle,
    changeDiscountPercentualHandle,
    errors,
    handleClearAll,
    handleSelectAll,
    changeValidDateHandle,
    validDateTime,
    // changeValidTimeHandle,
    // validTime
  } = UseDiscountProductForm(onSubmit, productList);
  return (
    <div id={styles.DiscountPeopleForm}>
      <div className={styles.container}>
        <form>
          <div className={styles.content}>
            <FormItemContainer>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="tipo"
                  defaultValue={IDiscountProductType.percentual}
                  name="type"
                  onChange={(_ev, value) => changeDiscountTypeHandle(value)}
                  value={type}
                  style={{ flexDirection: 'row' }}
                >
                  <FormControlLabel value={IDiscountProductType.percentual} control={<Radio />} label="Percentual" />
                  <FormControlLabel value={IDiscountProductType.money} control={<Radio />} label="Valor" />
                </RadioGroup>
              </FormControl>
            </FormItemContainer>
            <div className={styles.buttons}>
              <Button fullWidth={false} variant="text" onClick={handleSelectAll}>
                Selecionar todos
              </Button>
              <Button fullWidth={false} variant="outlined" onClick={handleClearAll}>
                Limpar
              </Button>
            </div>
            <FormItemContainer>
              <AutoComplete
                options={productList}
                label={"Selecione o(s) produto(s)"}
                limitTags={3}
                multiple
                disableCloseOnSelect
                variant="outlined"
                // filterSelectedOptions
                noOptionsText="Sem opções"
                required={products.length === 0}
                value={products}
                getOptionLabel={(option) => option.name + " - " + moneyMaskNumber(option.price)}
                onChange={(_value, items) => changeProductHandle(items as IProductItemToDiscount[])}
                error={!!errors.product}
                helperText={errors.product}
              />
            </FormItemContainer>

            {type === IDiscountProductType.percentual ? (
              <div className={styles.discount}>
                <FormItemContainer label={"Desconto(%)"} className={styles.discount}>
                  <Input
                    error={!!errors.discount}
                    helperText={errors.discount}
                    required
                    variant="outlined"
                    name={"discount"}
                    endAdornmentText={"%"}
                    value={discount}
                    onChange={(ev) => changeDiscountPercentualHandle(ev.target.value)}
                  />
                  {/* {values.product && <div>valor: {moneyMaskNumber((values.product.price * values.discount) / 100)}</div>} */}
                </FormItemContainer>
              </div>
            ) : (
              <div className={styles.discount}>
                <FormItemContainer label={"Valor"} >
                  <Input
                    error={!!errors.discount}
                    helperText={errors.discount}
                    required
                    variant="outlined"
                    name={"discount"}
                    value={moneyMaskNumber(discount)}
                    onChange={(ev) => changeDiscountValueHandle(ev.target.value)}
                  />
                </FormItemContainer>
              </div>
            )}
            <FormItemContainer row>
              {onClose && (
                <FormItemContainer>
                  <Button onClick={onClose} variant={"outlined"}>
                    Cancelar
                  </Button>
                </FormItemContainer>
              )}
              <FormItemContainer>
                <div className={styles.buttonConfirm}> 
                  <Button type={"button"} fullWidth={false} onClick={() => submitHandle()}>
                    Adicionar
                  </Button>
                </div>
              </FormItemContainer>
            </FormItemContainer>
          </div>
        </form>
      </div>
    </div>
  );
};
export default DiscountProductForm;
