import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";
import { create } from "zustand";

interface ISelectedEvent {
    registeredRolesList: IGetAllRolesResponse[] | null;
    setRegisteredRolesList: (list: IGetAllRolesResponse[] | null) => void;
};

const UseRegisteredRolesList = create<ISelectedEvent>(
    (set) => ({
        registeredRolesList: null,
        setRegisteredRolesList: (list) => {
          set(() => ({
            registeredRolesList: list,
          }));
        },
    })
);

export default UseRegisteredRolesList;
