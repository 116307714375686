import React, { useEffect, useState } from "react";
import { AddCircle } from "@material-ui/icons";
import styles from "./DiscountsListPage.module.scss";
import Button from "components/ui/Button/Button";
import UseDiscountList from "./UseDiscountList";
import { ListCard } from "./components/listCard/ListCard";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { IListObj } from "../../utils/utils";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import AddDiscounts from "../addDiscountsList/AddDiscounts";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

const DiscountsListPage = () => {
  const {
    gotToAddDiscountsList,
    filteredDiscounts,
    getDiscountsList,
    adminAccess,
    discountsFilterList,
    filterByList,
  } = UseDiscountList();

  const { updateRouters } = useBreadcumbs();

  const [openModalAdd, setOpenModalAdd] = useState(false);

  const onClickCloseHandle = () => {
    setOpenModalAdd(!openModalAdd);
  };

  useEffect(() => {
      updateRouters([
        {
          title: "Marketing",
        },
        {
          title: "Desconto - Lista de convidados",
        },
      ]);

      return () => {
      updateRouters([]);
      };
  }, [updateRouters]);

  return (
    <div id={styles.DiscountsListPage}>
      {adminAccess && (
        <Button
          fullWidth={false}
          onClick={onClickCloseHandle}
          className={styles.addListButton}
        >
          Adicionar lista
          <AddCircle />
        </Button>
      )}

      <Autocomplete
        options={discountsFilterList}
        size="small"
        getOptionLabel={(option) => option.name}
        onChange={(_evt, value: IListObj | null) => filterByList(value)}
        renderInput={(params) => (
          <TextField
            className={styles.name}
            label="Nome"
            variant="outlined"
            {...params}
          />
        )}
      />

      <div className={styles.containerCard}>
        {filteredDiscounts &&
          filteredDiscounts.map((item) => (
            <div key={item.id}>
              <ListCard
                discountList={item}
                refreshList={getDiscountsList}
                adminAccess={adminAccess}
              />
            </div>
          ))}
      </div>

      <RightDrawer
        open={openModalAdd}
        title={"Adicionar lista de desconto"}
        onClose={onClickCloseHandle}
      >
        <AddDiscounts onClickCloseHandle={onClickCloseHandle} getDiscountsList={getDiscountsList} />
      </RightDrawer>
    </div>
  );
};

export default DiscountsListPage;
