import { IGetBankDetailService } from "../interfaces/IContaDigitalApiService";

const GetBankDetail = async (
  accountService: IGetBankDetailService,
  ownerId: string
): Promise<any> => {
  return await accountService.getBankDetail(ownerId);
};

export default GetBankDetail;
