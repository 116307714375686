import React, { FC, useEffect } from "react";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { CampaignChannel } from "../../interfaces/ICampaign";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigCrmEmail } from "./UseConfigCrmEmail";
import Sidesheet from "components/sidesheet/Sidesheet";
import ConfigContentEmail from "../../components/_configCampaign/configContentEmail/configContentEmail";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

export interface IConfigCrmEmailProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  showConnect?: boolean;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  children?: JSX.Element;
}

export interface ICrmPlan {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  price: number;
  quantityMessages: number;
  isActive: boolean;
}

export interface ICrmSignature {
  id: string;
  beginAt: string; //date
  finishAt?: string; //date
  expirationAt?: string; //date
  channel: CampaignChannel;
  isActive: true;
  ownerId: string;
  plan: ICrmPlan;
}

const ConfigCrmEmail: FC<IConfigCrmEmailProps> = ({
  open,
  setOpen,
  step,
  setStep,
  children,
  showConnect = true
}) => {
  const {
    getSignature,
    isLoadingSignature,
    signature,
    onClickAllowPlanHandle,
    isLoadingPlans,
    plans,
    getPlans,
  } = UseConfigCrmEmail();

  useEffect(() => {
    if (step === 1) {
      getPlans();
    }
  }, [getPlans, step]);

  useEffect(() => {
    getSignature();
  }, [getSignature]);

  return (
    <>
      <ContentBox
        loading={isLoadingSignature}
        status={signature?.isActive ? "enabled" : "disabled"}
        premium={true}
        type={IntegrationTypeEnum.EMAIL}
        text="Ferramenta de envio de emails para seus clientes, diretamente do CRM Meep"
        showConnect={showConnect}
        setOpen={setOpen}
        featureType={FeatureTypeEnum.EMAIL}
      />
      {/* <div> */}
      {/* <b>Passo {step}/3</b> */}
      {/* {step === ConfigCrmEmailStep.plans && (
              <div>
                <h2 className={styles.chooseTitle}>
                  Escolha o plano desejado para enviar emails aos seus clientes
                  por meio do CRM Meep:
                </h2>
                {isLoadingPlans && <div>carregando planos</div>}
                <div className={styles.plansList}>
                  {plans?.map((item) => (
                    <div className={styles.planBox}>
                      <span className={styles.name}>{item.name}</span> */}
      {/* <div className={styles.description}>
                                                {item.description}
                                            </div> */}
      {/* <div className={styles.quantityMessages}>
                        Envie até{" "}
                        <strong>
                          {" "}
                          {(item.quantityMessages / 1000).toFixed(3)} emails
                        </strong>
                      </div>
                      <div className={styles.separator} />
                      <div className={styles.price}>
                        {item.price
                          ? new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(item.price) + "/mês"
                          : "Grátis"}
                      </div>
                      <button
                        type="button"
                        onClick={() => selectPlanHandle(item)}
                      >
                        Quero contratar
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {step === ConfigCrmEmailStep.terms && (
              <div>
                <CrmConfigWhatsAppStepCrmConfigTermsStep
                  channel={CampaignChannel.email}
                  price={selectedPlan?.price}
                  isLoading={isLoadingSignature}
                  onHandleContract={onClickAllowPlanHandle}
                  onBack={() => setStep(ConfigCrmEmailStep.plans)}
                />
              </div>
            )}
            {step === ConfigCrmEmailStep.signature && signature && (
              <div className={styles.signatureContent}>
                <h1>Pronto!</h1>
                <div className={styles.success}>
                  <img src="/assets/icon/check.svg" alt="" />
                  <p>Contratação do email realizado com sucesso!</p>
                </div>
                <div className={styles.row}>
                  <div className={styles.signatureBox}>
                    <div className={styles.icon}>
                      {" "}
                      <Icon fontSize={"large"}>check</Icon> Configurado{" "}
                    </div>
                    <div className={styles.signatureContent}>
                      <div className={styles.plan}>
                        Plano: <b>{signature.plan.name}</b>
                      </div>
                      <div className={styles.plan}>
                        Quantidade por mês:{" "}
                        <b>{signature.plan.quantityMessages}</b>
                      </div>
                      <div className={styles.price}>
                        Preço:{" "}
                        <b>
                          {signature.plan.price
                            ? new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(signature.plan.price) + "/mês"
                            : "Grátis"}
                        </b>
                      </div>
                      <div className={styles.date}>
                        Assinado em:
                        <b>
                          {" "}
                          {new Date(signature?.beginAt + "Z").toLocaleString()}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className={styles.renovationBox}>
                    <div>
                      <span>
                        Renova em:{" "}
                        <strong>
                          01/{format(addMonths(new Date(), 1), "MM/yyyy")} 00:00
                        </strong>
                      </span>
                    </div>

                    <strong>Emails enviados:</strong>

                    {totalReport && (
                      <div className={styles.progress}>
                        <div>
                          <div
                            style={{
                              width: `${(
                                (totalReport!.totalSent /
                                  totalReport!.totalPlan) *
                                100
                              ).toFixed(0)}`,
                            }}
                          ></div>
                        </div>
                        <span>
                          {new Intl.NumberFormat("pt-BR").format(
                            totalReport.totalSent
                          )}
                          /
                          {new Intl.NumberFormat("pt-BR").format(
                            totalReport.totalPlan
                          )}{" "}
                          emails
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div> */}
      <>


        {children ? children :
          <>
            {open && open === "Email" && (
              <Sidesheet
                open={open}
                onClose={() => setOpen(null)}
                title={
                  <h2>
                    Integração - <b>{open}</b>
                  </h2>
                }
                content={<ConfigContentEmail step={step} />}
                cancelButton
                continueButton={step === 1 ? "Contratar" : "Concluir"}
                handleContinueButton={
                  step === 2
                    ? () => {
                      onClickAllowPlanHandle();
                      setStep(step + 1);
                    }
                    : () => setStep(step + 1)
                }
                currentStep={step}
                totalSteps={4}
                isLoading={
                  (step === 1 && isLoadingPlans) ||
                  !plans ||
                  (step === 2 && isLoadingSignature)
                }
                feedback={
                  <SidesheetFeedback
                    text="Integração realizada com sucesso!"
                    success
                  />
                }
                showSteps
              />
            )}
          </>
        }


      </>
    </>
  );
};
export default ConfigCrmEmail;
