import React, { FC, useCallback, useState } from 'react'
import styles from './ExportProductSheet.module.scss'
import { Button } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';
import UseProductPage from 'modules/products/presentation/UseProductPage';
import { ExportSideSheet } from 'modules/products/presentation/components/exportSideSheet/ExportSideSheet';

export interface IDetectedProductValue {
    categoryName: string,
    productName: string,
    value: string,
    NCM: string,
    CEST: string,
    CFOP: string,
    barCode: string,
    internalCode: string,
    productId: string,
    externalCode: string,
    error?: string,
}

const ExportProductSheet: FC = () => {
  const {
      getExportList,
      exportSideSheet,
      setExportSideSheet,
      isLoadingExport,
      localConfiguration
    } = UseProductPage();
    
    const [exportType, setExportType] = useState<string>()
  
    const closeExportSideSheet = useCallback(() => {
        setExportSideSheet(false);
        setExportType('')
    }, [setExportSideSheet]);

    const openExportSideSheet = useCallback(() => {
        setExportSideSheet(true);
    }, [setExportSideSheet]);

    const changeSelectExportType = useCallback((exportType: string) => {
        setExportType(exportType);
    }, []);
    
    return (
      <>
        <div id={styles.exportProductSheet}>
          <Button
            onClick={openExportSideSheet}
            variant="text"
            className={styles.exportButton}
          >
            <span> Exportar </span> <VerticalAlignBottom />
          </Button>
        </div>
    
      <ExportSideSheet
          open={exportSideSheet}
          onClose={closeExportSideSheet}
          title="Exportar"
          exportType={exportType}
          onConfirm={() => getExportList(exportType)}
          changeSelectExportType={changeSelectExportType}
          isLoadingExport={isLoadingExport}
          localConfiguration={localConfiguration}
      />
    </>
    );
}

export default ExportProductSheet;
