import { MenuItem, Select, TextField } from "@mui/material";
import styles from "modules/saleForDevice/presentation/components/forDeviceFilter/ForDeviceFilter.module.scss";
import { KeyboardArrowRightOutlined, VerticalAlignBottomOutlined } from "@material-ui/icons";
import { ChangeEvent, useCallback, useState } from "react";
import UseCashlessDashboardPage from "modules/cashlessDashboard/presentation/UseCashlessDashboardPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import Utils from "services/utils/Utils";
import { IPostCashClosingParams } from "modules/cashClosing/domain/dto/IPostCashClosingParams";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

export interface ICashClosingFilterProps {
    params: IPostCashClosingParams;
    setParams: React.Dispatch<React.SetStateAction<IPostCashClosingParams>>;
    handleOpenSidesheet: (startDate: Date, endDate: Date) => void;
    handleOnClickExport: () => void;
    showExportButton: boolean;
    isLoading: boolean;
}

const CashClosingFilter = ({
    params,
    setParams,
    handleOpenSidesheet,
    handleOnClickExport,
    showExportButton,
    isLoading,
}: ICashClosingFilterProps) => {
    const { toast } = useUi();
    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();
    const { cashiers } = UseCashlessDashboardPage();
    
    const [filterBy, setFilterBy] = useState("");
    const [filters, setFilters] = useState({ localId: currentLocal?.id, TiposDispositivos: [0] } as IPostCashClosingParams);
    const [isSearching, setIsSearching] = useState(false);

    const handleChangeFilterBy = useCallback((filter: string) => {
        setFilters({...filters, dataInicio: "", dataFim: "", sessaoId: "" });
        return setFilterBy(filter);
    }, [filters]);

    const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const selectedDateTime = new Date(ev.target.value);
        const dateTimeInUTC = selectedDateTime.toISOString();
        setFilters((prev) => ({ ...prev, [ev.target.name]: dateTimeInUTC }));
    }, []);

    const handleSubmit = useCallback(() => {
        setIsSearching(true);
        setParams({...filters, shouldUseNewSchema: currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas });
    }, [currentLocal?.systemIdentifier, filters, setParams]);

    const handleClickOpenSidesheet = useCallback(() => {
        if (filters.dataInicio && filters.dataFim) {
            handleOpenSidesheet(new Date(filters.dataInicio ?? ""), new Date(filters.dataFim ?? ""));
            return;
        }
        toast("Selecione um período.", "error");
    }, [toast, filters.dataInicio, filters.dataFim, handleOpenSidesheet]);

    return (
        <div id={styles.ForDeviceFilter}>
            <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
                <div className={styles.input}>
                    <span className={styles.required}>Filtrar por</span>
                    <Select
                        value={filterBy}
                        size="small"
                        onChange={(ev) => handleChangeFilterBy(ev.target.value)}
                    >
                        <MenuItem value="period">Período</MenuItem>
                        <MenuItem value="cashier">Caixa</MenuItem>
                    </Select>
                </div>
                {filterBy === "period" && (
                    <>
                    <div className={styles.input}>
                        <span className={styles.required}>Inicio</span>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <TextField
                                size='small'
                                name={'dataInicio'}
                                onChange={changeHandleDate}
                                fullWidth
                                value={filters.dataInicio && Utils.toInputDateString(filters.dataInicio)}
                                type="datetime-local" />
                        </LocalizationProvider>
                    </div>
                    <div className={styles.input}>
                        <span className={styles.required}>Fim</span>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <TextField
                                size='small'
                                name={'dataFim'}
                                onChange={changeHandleDate}
                                fullWidth
                                value={filters.dataFim && Utils.toInputDateString(filters.dataFim)}
                                type="datetime-local" />
                        </LocalizationProvider>
                    </div>
                </>
                )}
                {filterBy === "cashier" && (
                    <div className={styles.input} style={{ width: "100%", maxWidth: dimensions.width > 650 ? "350px" : "100%" }}>
                        <span className={styles.required}>Caixa</span>
                        <Select
                            style={{ flex: 2, minWidth: 350 }}
                            size="small"
                            onChange={(ev) => setFilters({...filters, sessaoId: ev.target.value})}
                            value={filters.sessaoId}
                        >
                            {cashiers.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
                <Button color="secondary" onClick={handleSubmit} disabled={isLoading}>Buscar</Button>
            </div>
            <div className={`${styles.displayRow} ${styles.filterEndButton}`} style={{ justifyContent: "space-between", maxWidth: "100%" }}>
                {params.dataInicio && params.dataFim ? (
                    <div className={styles.btnRow} onClick={handleClickOpenSidesheet}>
                        <span>Caixas do período filtrado</span>
                        <KeyboardArrowRightOutlined />
                    </div>
                ) : (
                    <div style={{ width: "10px" }}/>
                )}
                <div className={`${styles.displayRow} ${styles.endButtons}`} style={{ justifyContent: "space-between", maxWidth: "fit-content" }}>
                    {showExportButton ? (
                        <div className={styles.btnRow} onClick={handleOnClickExport}>
                            <span>Exportar</span>
                            <VerticalAlignBottomOutlined />
                        </div>
                    ) : (<div />)}
                </div>
            </div>
            {filters.dataInicio && filters.dataFim && isSearching && (
                <div className={styles.btnRow} style={{ cursor: "default" }}>
                    <span><b>Período:</b> {filters.dataInicio ? format(new Date(filters.dataInicio), "dd/MM/yyyy' - 'HH:mm") : "-"} <b>até</b> {filters.dataFim ? format(new Date(filters.dataFim), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
                </div>
            )}
        </div>
    )
}

export default CashClosingFilter;