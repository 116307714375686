import { useCallback, useEffect, useState } from 'react';
import { IDiscountList } from '../../../IDiscountListForm';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import { isValid } from 'date-fns';
import { IProductItemToDiscount } from 'modules/events/presentation/components/discountPeopleListForm/interfaces/IProductList';
import { useParams } from 'react-router-dom';
import { EventsApi } from 'services/api/events/events/EventsApi';
import GetEventsDetailsUseCase from 'modules/events/application/useCases/events/GetEventsDetailsUseCase';
import { IEvents } from 'modules/events/domain/models/IEvents';
import moment from 'moment';
import { IProductItemDiscount } from '../../../../productList/interfaces/IProductItem';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

interface UseNewDiscountFormProps {
    onClose: () => void;
    onAddDiscount: (newDiscount: IDiscountList[]) => void;
    productList?: IProductItemToDiscount[];
}

interface IDiscount extends Omit<IDiscountList, 'productId'> {
    productId?: string | null;
}

interface Error {
    [key: string]: string
}

const UseNewDiscountForm = ({ onClose, productList, onAddDiscount }: UseNewDiscountFormProps) => {
    const [selectedProducts, setSelectedProducts] = useState<IProductItemDiscount>();
    const [date, setDate] = useState<Date | null>(null);
    const [time, setTime] = useState<Date | null>(null);
    const [discount, setDiscount] = useState<IDiscount>({} as IDiscount);
    const [finalTicketValue, setFinalTicketValue] = useState<number>(0);
    const [errors, setErrors] = useState<Error>({} as Error);
    const { eventId } = useParams<{ eventId: string }>();
    const [event, setEvent] = useState<IEvents>();
    const { currentLocal } = useLocal();

    const getEvent = useCallback(async () => {
        if (!currentLocal) return;
        
        const eventsService = EventsApi();
        const getEventsDetails = await GetEventsDetailsUseCase(eventsService, eventId, currentLocal?.id);
        setEvent(getEventsDetails);
    }, [eventId, currentLocal]);

    useEffect(() => {
        getEvent();
    }, [getEvent]);

    const handleSubmit = () => {
        setErrors({} as Error);
        if (!date) {
            setErrors(prev => ({ ...prev, date: 'Campo obrigatório' }));
        }
        else if (!isValid(date)) {
            setErrors(prev => ({ ...prev, date: 'Data inválida' }));
        }
        if (!time) {
            setErrors(prev => ({ ...prev, time: 'Campo obrigatório' }));
        } else if (!isValid(time)) {
            setErrors(prev => ({ ...prev, time: 'Horário inválido' }));
        }

        const dateTime = new Date(date!.getFullYear(), date!.getMonth(), date!.getDate(),
            time!.getHours(), time!.getMinutes(), time!.getSeconds());

        if (dateTime && event?.openingDates[0].endDate) {
            var eventstartDate = event?.openingDates[0].startDate;
            var eventEndDate = event?.openingDates[0].endDate;
            if (moment(dateTime).isAfter(moment(eventEndDate)) || moment(dateTime).isBefore(moment(eventstartDate))) {
                setErrors(prev => ({ ...prev, datetime: 'A data e hora informada está fora do período do Evento' }));
                return;
            }
        }

        const discounts = [] as IDiscountList[];

        if (selectedProducts) {
            discounts.push({ ...discount, productId: selectedProducts.id, name: selectedProducts.name, price: selectedProducts.price })
        }

        onAddDiscount(discounts);
        onClose();
        setDiscount({} as IDiscount);
    }

    const handleChangeDiscount = (value: string) => {
        setFinalTicketValue(moneyToFloat(value));
        if (selectedProducts?.price) {
            moneyToFloat(value) <= selectedProducts?.price ?
                setDiscount(prev => ({ ...prev, priceDiscount: { price: selectedProducts.price - moneyToFloat(value) } })) :
                setDiscount(prev => ({ ...prev, priceDiscount: { price: 0 } }))
        }
    }

    const handleChangeTicket = (value: IProductItemDiscount) => {
        setSelectedProducts(value);
        setFinalTicketValue(value.price);
        setDiscount(prev => ({ ...prev, priceDiscount: { price: 0 } }))
    }

    const getDiscountValue = () => {
        if (selectedProducts?.price && finalTicketValue > selectedProducts?.price) {
            return moneyMaskNumber(selectedProducts.price);
        }
        return moneyMaskNumber(finalTicketValue);
    }

    return {
        selectedProducts,
        date,
        time,
        errors,
        discount,
        handleChangeTicket,
        getDiscountValue,
        setDate,
        setTime,
        handleChangeDiscount,
        handleSubmit
    };
};

export default UseNewDiscountForm;
