
import { ISaveStockOperationRequest, IUpdateStockOperationRequest } from "modules/meepErp/application/dtos/stockOperations/ISaveStockOperationRequest";
import { Api } from "services/api/Api";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { IGetStockOperationListRequest } from "modules/meepErp/application/dtos/stockOperations/IGetStockOperationListRequest";
import { IGetStockOperationListResponse } from "modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse";
import { IGetStockOperationResponse } from "modules/meepErp/application/dtos/stockOperations/IStockOperationResponse";
import { IStockOperationService } from "modules/meepErp/application/interfaces/_stockOperations/IStockOperationsService";
import { IStockTypeItemSelect } from "modules/meepErp/presentation/components/stockOperations/stockOperationForm/StockOperationValues";
import { erpUrl } from "Enviroment";

const BASE_URL = erpUrl;

const StockOperationsService = (): IStockOperationService => {
  const api = Api();
  const getInventoryOperation = async (ownerId: string, id: string): Promise<IGetStockOperationResponse> => {
    const response = await api.get<IGetStockOperationResponse>(`${BASE_URL}/api/Inventory/Operations/${id}`)
    return response.data;
  };

  const getInventoryOperationsList = async (ownerId: string, request: IGetStockOperationListRequest): Promise<IGetStockOperationListResponse> => {
    const response = await api.get<IGetStockOperationListResponse>(`${BASE_URL}/api/Inventory/Operations`, { params: request })
    return response.data
  };

  const getOperationTypes = async (): Promise<IStockTypeItemSelect[]> => {
    // implementation goes here
    const response = await api.get<IStockTypeItemSelect[]>(`${BASE_URL}/api/Inventory/Operations/Types`)
    return response.data;
  };

  const createInventoryOperation = async (data: ISaveStockOperationRequest): Promise<IGetStockOperationResponse> => {
    // implementation goes here
    //  return await getInventoryOperationMOCK();
    const response = await api.post<IGetStockOperationResponse>(`${BASE_URL}/api/Inventory/Operations`, data)
    return response.data;
  };

  const updateInventoryOperation = async (data: IUpdateStockOperationRequest): Promise<IGetStockOperationResponse> => {
    const response = await api.put<IGetStockOperationResponse>(`${BASE_URL}/api/Inventory/Operations/${data.id}`, data)
    return response.data;
  };

  const deleteInventoryOperation = async (id: string): Promise<void> => {
    // implementation goes here
    await api.delete(`${BASE_URL}/api/Inventory/Operations/${id}`);
  };

  const enableInventoryOperation = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/Operations/${id}/Enable`);
    return response.data
  };

  const disableInventoryOperation = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/Operations/${id}/Disable`);
    return response.data
  };

  return {
    getInventoryOperation,
    getInventoryOperationsList,
    getOperationTypes,
    createInventoryOperation,
    updateInventoryOperation,
    deleteInventoryOperation,
    enableInventoryOperation,
    disableInventoryOperation
  };
}
export default StockOperationsService


// getInventoryOperations: (ownerId: string) => Promise<IInventoryOperationResponse>;
//   getPaginatedInventoryOperations: (ownerId: string, request: IInvetoryOperationListRequest ) => Promise<IInvetoryOperationListResponse>;
//   getOperationTypes: () => Promise<IOperationTypeItemSelect[]>;
//   createInventoryOperation: (data: IInventoryOperationValues) => Promise<void>;
//   updateInventoryOperation: (data: IInventoryOperationValues) => Promise<void>;
//   deleteInventoryOperation: (id: string) => Promise<void>;
