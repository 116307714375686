
import { INovidadesBody, IUpdateNovidadesService } from "../interfaces/INovidadesService";

const UpdateNovidades = async (
  novidadesService: IUpdateNovidadesService,
  id: string,
  body: INovidadesBody,
): Promise<any> => {
  return await novidadesService.updateNovidades(id, body);
};

export default UpdateNovidades;