import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AddDiscountToBaseListUseCase from 'modules/events/application/useCases/baseList/AddDiscountUseCase';
import EditBaseListDiscountUseCase from 'modules/events/application/useCases/baseList/EditBaseListDiscountUseCase';
import {
  DiscountProductTypeEnum,
  IAddDiscountToListRequest,
  IEditDiscountsRequest,
} from 'modules/events/domain/dto/IEventsRequest';
import {
  IBaseList,
  IDiscounts,
} from 'modules/events/presentation/components/discounts/interfaces/IDiscounts';
import {
  IDiscountProductListForm,
  IDiscountProductType,
} from 'modules/events/presentation/pages/discountsList/components/discountListEditForm/interfaces/IDiscountProductListForm';
import { useCallback, useState } from 'react';
import DiscountBaseListApi from 'services/api/events/discountsList/DiscountBaseListApi';
import TicketsApi from 'services/api/events/tickets/TicketsApi';
import { useParams } from 'react-router-dom';
import ListTicketUseCase from 'modules/events/application/useCases/events/tickets/ListTicketUseCase';
import { ITicketForm } from '../../../tickets/components/ticketForm/ITicketForm';
import GetEventDiscountBaseLIstUseCase from 'modules/events/application/useCases/baseList/GetEventDiscountBaseLIstUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IDiscountListResponse } from 'modules/events/domain/dto/IEventsResponse';

const UseBaseListCard = (
  baseList: IBaseList,
  refreshDiscountList: () => Promise<void>,
) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [openEdit, setOpenEdit] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productList, setProductList] = useState<ITicketForm[]>([]);
  const [discountToEdit, setDiscountToEdit] = useState<IDiscounts | null>(null);
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const ticketService = TicketsApi();
  const baseListService = DiscountBaseListApi();
  const getProductsUseCase = async (discountList: IDiscountListResponse[], listId: string) => {
    try {
      const response = await ListTicketUseCase(ticketService, eventId);
      var listTicket = response.records.filter(listTicket => !discountList?.find(discountListValue => (discountListValue.discounts.find(discountValue => discountListValue.id === listId && discountValue.productId === listTicket.id))));
      setProductList(listTicket);
    } catch (error) {
    }
  };

  const getEventDiscountBaseList = useCallback(async (localId: string, listId: string) => {
    const getEventDiscount = await GetEventDiscountBaseLIstUseCase(
      baseListService,
      localId,
      eventId
    );
    getProductsUseCase(getEventDiscount.records, listId);
  }, []);

  const handleDiscountToEdit = useCallback((discount: IDiscounts, listId: string) => {
    setDiscountToEdit(discount);
    setOpenEdit(true);
    if (currentLocal) {
      getEventDiscountBaseList(currentLocal.id, listId);
    }
  }, []);

  const showContent = useCallback(() => {
    setIsCollapsed(true);
  }, []);

  const hideContent = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  const closeEditModal = useCallback(() => {
    setDiscountToEdit(null);
    setOpenEdit(false);
  }, []);

  const closeAddModal = useCallback(() => {
    setOpenAdd(false);
  }, []);

  const openAddModal = useCallback((listId: string) => {
    if (currentLocal) {
      getEventDiscountBaseList(currentLocal.id, listId);
    }
    setOpenAdd(true);
  }, []);

  const handleEditDiscount = useCallback(
    async (discount: IDiscountProductListForm) => {
      const discountBaseListService = DiscountBaseListApi();

      if (typeof discount.validTime === 'string') {
        const [horas, minutos] = discount.validTime?.split(':')
        discount.validDate?.setHours(Number(horas))
        discount.validDate?.setMinutes(Number(minutos))
      } else if (discount.validTime instanceof Date) {
        discount.validDate?.setHours(discount.validTime.getHours());
        discount.validDate?.setMinutes(discount.validTime.getMinutes());
      }

      const discountToEdit: IEditDiscountsRequest = {
        productId: discount.product.id,
        id: discount.id || '',
        discountValue: discount.discount,
        discountProductType: DiscountProductTypeEnum.price,
        validUntil: discount.validDate?.toISOString()
      };
      if (!currentLocal) return;
      try {
        await EditBaseListDiscountUseCase(discountBaseListService, discountToEdit, currentLocal.id);
        toast('Ingresso editado com sucesso!', 'success');
        refreshDiscountList();
      } catch (error) {
      }
    },
    [currentLocal],
  );

  const handleAddDiscount = useCallback(
    async (discount: IDiscountProductListForm) => {
      const baseListService = DiscountBaseListApi();
      if (typeof discount.validTime === 'string') {
        const [horas, minutos] = discount.validTime?.split(':')
        discount.validDate?.setHours(Number(horas))
        discount.validDate?.setMinutes(Number(minutos))
      } else if (discount.validTime instanceof Date) {
        discount.validDate?.setHours(discount.validTime.getHours());
        discount.validDate?.setMinutes(discount.validTime.getMinutes());
      }
      try {
        const requestDiscount: IAddDiscountToListRequest = {
          baseListId: baseList.id,
          productId: discount.product.id,
          percentualDiscount:
            discount.type === IDiscountProductType.percentual
              ? { percentual: discount.discount }
              : undefined,
          priceDiscount:
            discount.type === IDiscountProductType.money
              ? { price: discount.discount }
              : undefined,
          validUntil: discount.validDate?.toISOString()
        };
        if (!currentLocal) return;
        await AddDiscountToBaseListUseCase(baseListService, requestDiscount, currentLocal.id);
        toast('Ingresso adicionado com sucesso!', 'success');
        refreshDiscountList();
      } catch (error) {
      }
    },
    [currentLocal],
  );

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[10],
      fontSize: 14,
      padding: 15,
      borderRadius: 17,
      maxWidth: 250,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      width: '1.5em',
    },
  }));

  return {
    openEdit,
    closeEditModal,
    openAdd,
    closeAddModal,
    openAddModal,
    isCollapsed,
    showContent,
    hideContent,
    productList,
    handleDiscountToEdit,
    discountToEdit,
    handleEditDiscount,
    handleAddDiscount,
    LightTooltip,
  };
};

export default UseBaseListCard;
