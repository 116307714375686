import GetPromotersFromLocalUseCase from "modules/events/application/useCases/access/GetPromotersFromLocalUseCase";
import GetPromotersWithAccessUseCase from "modules/events/application/useCases/access/GetPromotersWithAccessUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccessApi } from "services/api/events/access/AccessApi";
import { IPromoters } from "./interfaces/IPromoters";

const accessService = AccessApi();
interface IUseGrantAccessForm {
  promotersFromPlace: IPromoters[];
  selectedPromoters: IPromoters[];
  changePromotersHandle: (promoters: IPromoters[]) => void;
  handleSelectAll: () => void;
  handleClearAll: () => void;
  handleCancel: () => void;
  onSubmit: () => void;
}

const UseGrantAccessForm = (
  closeModal: () => void,
  handleConfirm: (promoters: IPromoters[]) => Promise<void>
): IUseGrantAccessForm => {
  const { currentLocal } = useLocal();
  const [localId, setLocalId] = useState<string>();
  const { eventId } = useParams<{ eventId: string }>();
  const [promotersFromPlace, setPromotersFromPlace] = useState<IPromoters[]>([]);
  const [selectedPromoters, setSelectedPromoters] = useState<IPromoters[]>([]);

  useEffect(() => {
    if (currentLocal?.id) {
      setLocalId(currentLocal.id);
    }
  }, [currentLocal]);

  const getPromotersWithAccessGranted = useCallback(async (eventId: string) => {
    if (!currentLocal) return;
    try {
      const result = await GetPromotersWithAccessUseCase(accessService, eventId, currentLocal.id);
      setSelectedPromoters(result);
    } catch (error) {
      console.error(error);
    }
  }, [currentLocal]);

  useEffect(() => {
    if (localId) {
      const getLocalPromoters = async (localId: string) => {
        try {
          const result = await GetPromotersFromLocalUseCase(accessService, localId);
          setPromotersFromPlace(result);
        } catch (error) {
          console.error(error);
        }
      };
      getLocalPromoters(localId);
    }
    getPromotersWithAccessGranted(eventId);
  }, [eventId, getPromotersWithAccessGranted, localId]);

  const changePromotersHandle = useCallback((promoters: IPromoters[]) => {
    setSelectedPromoters(promoters);
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedPromoters(promotersFromPlace);
  }, [promotersFromPlace]);

  const handleClearAll = useCallback(() => {
    setSelectedPromoters([]);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedPromoters([]);
    closeModal();
  }, [closeModal]);

  const onSubmit = useCallback(() => {
    handleConfirm(selectedPromoters);
    if (selectedPromoters.length) {
      getPromotersWithAccessGranted(eventId);
    }
  }, [eventId, getPromotersWithAccessGranted, handleConfirm, selectedPromoters]);

  return {
    promotersFromPlace,
    selectedPromoters,
    changePromotersHandle,
    handleClearAll,
    handleSelectAll,
    handleCancel,
    onSubmit,
  };
};

export default UseGrantAccessForm;
