type DateType = string | null;

type FormatCashierDateTypes = {
  startDate: any;
  endDate: any;
};

const resolveAlgorism = (number: number) =>
  number <= 9 ? `0${number}` : number;

const FormatDate = (dateString?: DateType) => {
  if (!dateString) return;

  const date = new Date(dateString);

  date.setHours(date.getHours() - 3);

  return `${resolveAlgorism(date.getDate())}/${resolveAlgorism(
    date.getMonth() + 1
  )}/${date.getFullYear()} ${resolveAlgorism(
    date.getHours()
  )}:${resolveAlgorism(date.getMinutes())}:${resolveAlgorism(
    date.getSeconds()
  )}`;
};

export const FormatCashierDate = ({
  startDate,
  endDate,
}: FormatCashierDateTypes) =>
  `${FormatDate(startDate)}${endDate ? " - " + FormatDate(endDate) : ""}`;
