import { AxiosInstance } from "axios"

const AlterarLojaEmMassa = async (api: AxiosInstance, productId: string, storeId: string ): Promise<void> => {
    const body = { 
        produtoId: productId, 
        lojaId: storeId 
    }
    
    await api.post("/Produto/EditarLojaEmMassa", body)
}
export default AlterarLojaEmMassa