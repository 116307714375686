import { ICollaboratorsApiService } from "../domain/interface/ICollaboratorsApiService";
import { IGetColaboradoresParams } from "../domain/dto/getColaboradores/IGetColaboradoresParams";
import { IGetColaboradoresResponse } from "../domain/dto/getColaboradores/IGetColaboradoresResponse";

const GetCollaboratorsUseCase =
  async ( service: ICollaboratorsApiService, page: number, params?: IGetColaboradoresParams, pageSize?: number ): Promise<IGetColaboradoresResponse> => {
    const response = service.getCollaborators({...params, page: page || 0, pageSize: pageSize || 10});
    return response;
};

export default GetCollaboratorsUseCase;
