import { z } from "zod";

export const ISupplyStockMovementFilterSchema = z.object({
    dateStart: z.date( { message: 'Periodo é obrigatório' }),
    dateEnd: z.date({ message: 'Periodo é obrigatório' }),
    supplyCode: z.number({ message: 'Código é obrigatório' }),
    supplyName: z.string({ message: 'Insumo é obrigatório' }),
    storageLocationId: z.string({ message: 'Local de estoque é obrigatório' }),
    operationId: z.string().optional()
})

