import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PrintQueueApi from "services/api/printQueue/PrintQueueApi";
import { IPrinterItem } from "../interfaces/IPrinterItem";
import GetPrinterListUseCase from "modules/printQueue/application/useCases/GetPrinterListUseCase";


const UsePrinterList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [printerList, setPrinterList] = useState<IPrinterItem[]>()
  const { currentLocal } = useLocal();

  const getPrinterList = useCallback(
    async () => {
      try {
        const service = PrintQueueApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPrinterListUseCase(service,
            currentLocal.id
          );

          setPrinterList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    printerList,
    isLoading,
    getPrinterList,
    setPrinterList,
  };
};

export default UsePrinterList;
