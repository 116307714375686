import GetCustomerListUseCase from "modules/customersDashboard/application/useCases/GetCustomerListUseCase";
import { ICustomerDashboardFilter } from "modules/customersDashboard/domain/dto/FilterRequest.type";
import { useCallback, useState } from "react";
import CustomersDashboardService from "services/api/customersDashboard/CustomersDashboardService";
import { ICustomerListData } from "../components/customerList/ICustomerList";


export const UseCustomerList = (filter: ICustomerDashboardFilter) => {
    const [data, setData] = useState<ICustomerListData>();
    const [isLoading, setIsLoading] = useState(false);

    const getData = useCallback(async (_filter?: { keyword?: string, customerFilter?: string, page?: number, pageSize?: number, sortType?: string, sortOrientation?: string, enabledInfinityScroll?: boolean }) => {
        try {
            setIsLoading(true)
            const dashboardLiveService = CustomersDashboardService();
            const response = await GetCustomerListUseCase(dashboardLiveService, { ...filter, ..._filter, sort: { type: _filter?.sortType, orientation: _filter?.sortOrientation ?? 'desc' } })
            if (_filter?.page && _filter?.page > 0 && _filter?.enabledInfinityScroll) {
                setData(prev => ({
                    records: [...prev?.records ?? [], ...response.records],
                    totalRecords: response.totalRecords,
                    totalBirthday: response.totalBirthday,
                    totalCheckin: response.totalCheckin,
                    totalInPlace: response.totalInPlace,
                }))
            } else {
                setData(response)
            }
        } finally {
            setIsLoading(false)
        }
    }, [filter])

    return ({
        data,
        isLoading,
        getData
    });
};
