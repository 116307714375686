import { useCallback, useState } from "react";
import GetTutoriaisUseCase from "modules/tutoriais/application/useCases/GetTutoriaisUseCase";
import TutoriaisApi from "services/api/tutoriais/TutoriaisApi";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";
import { IGetTutoriaisParams } from "modules/tutoriais/domain/dto/IGetTutoriaisParams";

const tutoriaisApi = TutoriaisApi();

const UseTutorialPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [tutoriaisList, setTutoriaisList] = useState<ITutoriaisRecords[]>();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const getTutoriaisList = useCallback(
    async (page: number, params?: IGetTutoriaisParams, pageSize?: number) => {
      try {
        setIsLoading(true);
        const response = await GetTutoriaisUseCase(tutoriaisApi, page, params, pageSize);

        setTutoriaisList(response.records);
        setTotalPages(response.totalPages);
        setTotalRegisters(response.totalRecords);
        setCurrentPage(page);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const nextPage = (page: number, params?: IGetTutoriaisParams, pageSize?: number) => {
    if (currentPage < totalPages) {
        getTutoriaisList(page, params, pageSize);
    }
  }

  return {
    isLoading,
    tutoriaisList,
    totalPages,
    currentPage,
    totalRegisters,
    nextPage,
    getTutoriaisList,
  };
};

export default UseTutorialPage;
