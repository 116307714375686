import { ISort } from "interfaces/ISort";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { ILinkCardsList } from "../interfaces/ILinkCardsList";
import { ILinkCardsFilter } from "../components/linkCardsFilter/interfaces/ILinkCardsFilter";
import GetLinkCardsUseCase from "modules/linkCards/application/useCases/GetLinkCardsUseCase";
import LinkCardsApi from "services/api/linkCards/LinkCardsApi";

export interface IParamsLinkCardsList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: ISort
  filter?: ILinkCardsFilter
}

const UseLinkCardsList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [linkCardsList, setLinkCardsList] = useState<ILinkCardsList>()
  const { currentLocal } = useLocal();

  const getLinkCardsList = useCallback(
    async (params?: IParamsLinkCardsList) => {
      try {
        const serviceLinkCards = LinkCardsApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetLinkCardsUseCase(serviceLinkCards,
            currentLocal.id,
            params?.sort,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.filter,
          );

          setLinkCardsList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    linkCardsList,
    isLoading,
    getLinkCardsList,
    setLinkCardsList,
  };
};

export default UseLinkCardsList;
