import {
  IGetSupplyItemResponse,
  IGetSupplyListResponse,
} from "modules/meepErp/application/dtos/supply/IGetLSupplyListResponse";
import { getSupplyNameMOCK } from "./SupplyMOCK";
import { IGetSupplyListRequest } from "modules/meepErp/application/dtos/supply/IGetSupplyListRequest";
import { IGetSupplyNameResponse } from "modules/meepErp/application/dtos/supply/IGetSupplyNameResponse";
import { ISupplyService } from "modules/meepErp/application/interfaces/supply/supply/ISupplyService";
import {
  ISaveSupplyRequest,
  ISaveSupplyResponse,
  IUpdateSupplyRequest,
  IUpdateSupplyResponse,
} from "modules/meepErp/application/dtos/supply/ISaveSupplyRequest";
import { Api } from "services/api/Api";
import { erpUrl } from "Enviroment";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { IGetCompositionsItemResponse } from "modules/meepErp/application/dtos/supply/IGetCompositionsResponse";
import { IGetSupplyCostsResponse } from "modules/meepErp/application/dtos/supply/IGetSupplyCostsResponse";

const BaseUrl = erpUrl + "/api";
const api = Api();

const SupplyService = (): ISupplyService => {
  const addSupply = async (
    ownerId: string,
    supplyRequest: ISaveSupplyRequest
  ): Promise<ISaveSupplyResponse> => {
    const response = await api.post<ISaveSupplyResponse>(
      `${BaseUrl}/${ownerId}/supplies`,
      supplyRequest
    );
    return response.data;
  };

  const updateSupply = async (
    ownerId: string,
    id: string,
    supplyRequest: IUpdateSupplyRequest
  ): Promise<IUpdateSupplyResponse> => {
    const response = await api.put<IUpdateSupplyResponse>(
      `${BaseUrl}/${ownerId}/supplies/${id}`,
      supplyRequest
    );
    return response.data;
  };

  const getSupplyList = async (
    ownerId: string,
    request: IGetSupplyListRequest
  ): Promise<IGetSupplyListResponse> => {
    const response = await api.get<IGetSupplyListResponse>(
      `${BaseUrl}/${ownerId}/supplies`,
      { params: request }
    );
    return response.data;
  };

  const getCompositions = async (
    ownerId: string,
    supplyId: string
  ): Promise<IGetCompositionsItemResponse[]> => {
    const response = await api.get<IGetCompositionsItemResponse[]>(
      `${BaseUrl}/${ownerId}/supplies/compositions/${supplyId}`
    );
    return response.data;
  };

  const getSupplyItem = async (
    ownerId: string,
    id: string
  ): Promise<IGetSupplyItemResponse> => {
    const response = await api.get<IGetSupplyItemResponse>(
      `${BaseUrl}/${ownerId}/supplies/${id}`
    );

    return {
      ...response.data,
      supplyGroupName: response.data.supplyGroup ?? "",
    };
  };

  const getSupplyCosts = async (
    ownerId: string,
    id: string
  ): Promise<IGetSupplyCostsResponse[]> => {
    const response = await api.get<IGetSupplyCostsResponse[]>(
      `${BaseUrl}/${ownerId}/supplies/supplyCosts/${id}`
    );
    return response.data
  } 

  const deleteSupply = async (ownerId: string, id: string): Promise<void> => {
    return await api.delete(`${BaseUrl}/${ownerId}/supplies/${id}`);
  };

  const getSupplyNameList = async (
    ownerId: string
  ): Promise<IGetSupplyNameResponse[]> => {
    // implementation goes here
    return await getSupplyNameMOCK();
  };

  const enableSupply = async (
    ownerId: string,
    id: string
  ): Promise<IEnableDisableResponse> => {
    return await api.patch(`${BaseUrl}/${ownerId}/supplies/${id}/Enable`);
  };
  const disableSupply = async (
    ownerId: string,
    id: string
  ): Promise<IEnableDisableResponse> => {
    return await api.patch(`${BaseUrl}/${ownerId}/supplies/${id}/Disable`);
  };

  return {
    addSupply,
    getSupplyList,
    getSupplyItem,
    deleteSupply,
    getSupplyNameList,
    enableSupply,
    disableSupply,
    updateSupply,
    getCompositions,
    getSupplyCosts
  };
};
export default SupplyService;
