import { TextField } from "@material-ui/core";
import {
  IAddTIDForm,
  IAddTIDFormErrors,
} from "modules/tidsAdicionais/presentation/interfaces/ITIDsAddForm";
import { ChangeEvent, FC } from "react";
import CPF from "services/utils/CPF";
import styles from "./AddTIDsForm.module.scss";

export interface IAddTIDsForm {
  values: IAddTIDForm;
  handleChangeForm: (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors: IAddTIDFormErrors;
}

const AddTIDsForm: FC<IAddTIDsForm> = ({
  handleChangeForm,
  values,
  errors,
}) => {
  return (
    <div id={styles.AddTIDsForm}>
      <div className={styles.item}>
        <label>
          Documento<span> *</span>
        </label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="Documento"
          variant="outlined"
          name="MerchantDocument"
          value={CPF.cpfMask(values?.MerchantDocument)}
          error={!!errors?.MerchantDocument}
          helperText={errors?.MerchantDocument}
          onChange={handleChangeForm}
        />
      </div>
      <div className={styles.item}>
        <label>
          ID<span> *</span>
        </label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="ID"
          variant="outlined"
          name="AcquirerMerchantId"
          value={values?.AcquirerMerchantId}
          error={!!errors?.AcquirerMerchantId}
          helperText={errors?.AcquirerMerchantId}
          onChange={handleChangeForm}
        />
      </div>
      <div className={styles.item}>
        <label>
          Tipo adquirente<span> *</span>
        </label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="ID"
          variant="outlined"
          name="PayStoreAcquirerType"
          value={values?.PayStoreAcquirerType}
          error={!!errors?.PayStoreAcquirerType}
          helperText={errors?.PayStoreAcquirerType}
          onChange={handleChangeForm}
        />
      </div>
      <div className={styles.item}>
        <label>
          TIDs<span> *</span>
        </label>
        <TextField
          fullWidth
          size="small"
          placeholder={`Os TIDs possuem oito dígitos, começam com BW e devem ser separados por ;\nEx.: BW111111;BW11111112`}
          variant="outlined"
          name="Tids"
          multiline
          minRows={3}
          value={values?.Tids}
          error={!!errors?.Tids}
          helperText={errors?.Tids}
          onChange={handleChangeForm}
        />
      </div>
      <div className={styles.item}>
        <label>
          TIDs<span> *</span>
        </label>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="QuantityTids"
          multiline
          value={values?.QuantityTids}
          error={!!errors?.QuantityTids}
          helperText={errors?.QuantityTids}
        />
      </div>
    </div>
  );
};

export default AddTIDsForm;
