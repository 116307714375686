import Layout from "components/layout/presentation/Layout";
import AutomationsPage from "./AutomationPage";

export const Automations = () => {
  return (
    <Layout>
      <AutomationsPage />
    </Layout>
  );
};
