import ITicketsReportService from "modules/ticketsReport/domain/interfaces/ITicketsReportService";
import { ITicketsValidation } from "modules/ticketsReport/presentation/interfaces/ITicketsValidation";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetTicketsValidationUseCase = async (service: ITicketsReportService, params: IUseCaseParams): Promise<ITicketsValidation> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getTicketsValidation(filterRequest);

  return response;
}

export default GetTicketsValidationUseCase
