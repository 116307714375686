import { useCallback, useEffect } from "react";
import { IPlansItem } from "../../interfaces/plans/IPlans";
import styles from "./CieloPosContainer.module.scss";
import { AddOutlined, OpenInNewOutlined, RemoveOutlined } from "@material-ui/icons";
import UseDimension from "components/dimension/UseDimension";
import { IconButton } from "@mui/material";

interface ICieloPosContainerProps {
    selectedPlan?: IPlansItem;
    count: number;
    posPriceCalc: (quantity: number) => void;
}

const CieloPosContainer = ({ selectedPlan, count, posPriceCalc }: ICieloPosContainerProps) => {
    const { dimensions } = UseDimension();

    useEffect(() => {
        if (!!selectedPlan) {
            posPriceCalc(0);
        }
    }, [posPriceCalc, selectedPlan]);

    const handleLeftCaretValue = useCallback(() => {
        if (dimensions.width <= 667) {
            return "50%";
        }
        if (dimensions.width <= 928) {
            return selectedPlan?.index === 0 ? "25%" : selectedPlan?.index === 1 ? "70%" : "50%";
        }
        return selectedPlan?.index === 0 ? 110 : selectedPlan?.index === 1 ? 370 : 630;
    }, [dimensions.width, selectedPlan?.index]);

    const handleAddPos = useCallback(() => posPriceCalc(count + 1), [count, posPriceCalc]);

    const handleRemovePos = useCallback(() => posPriceCalc(count - 1), [count, posPriceCalc]);

    return (
        <div id={styles.CieloPosContainer}>
            <img
                src="/assets/icon/saas/caret.png"
                alt=""
                className={styles.caret}
                style={{
                    left: selectedPlan
                    ? handleLeftCaretValue()
                    : "50%"
                }}
            />
            <div>
                <div className={styles.info}>
                    {!!selectedPlan
                        ? <>
                            <p>Precisa de mais licenças de maquininha e terminal online? ✨</p>
                            <span>Já {selectedPlan.name === "Avançado" ? "existem 5 licenças" : selectedPlan.name === "Intermediário" ? "existem 3 licenças" : "existe 1 licença"} no plano escolhido. Você pode adicionar mais, caso precise!</span>
                        </>
                        : <>
                            <p>Quantas máquinas serão usadas no evento? ✨</p>
                            <span>Essa quantidade representa as licenças do Mee que serão disponibilizadas para o seu evento</span>
                        </>
                    }
                </div>
            </div>
            <div>
                <div className={styles.count}>
                    <div className={styles.counter}>
                        <IconButton
                            size="small"
                            disabled={!!selectedPlan ? count === 0 : count === 1}
                            color="primary"
                            onClick={handleRemovePos}
                        >
                            <RemoveOutlined fontSize="small" />
                        </IconButton>
                        <p>{count}</p>
                        <IconButton size="small" color="primary" onClick={handleAddPos}>
                            <AddOutlined fontSize="small" />
                        </IconButton>
                    </div>
                    {!!selectedPlan 
                        ? <span>R$ {selectedPlan.name === "Avançado" ? "29,90" : selectedPlan.name === "Intermediário" ? "39,90" : "49,90"}/cada</span>
                        : <span>R$ {count <= 5 ? "50" : count >= 6 && count <= 10 ? "40" : count >= 11 && count <= 15 ? "35" : "30"},00/cada</span>
                    }
                    {!selectedPlan && (
                        <button className={styles.tableValuesBtn} onClick={() => window.open("/public/saas/cieloPrices", "_blank")}>
                            Tabela de preços <OpenInNewOutlined />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CieloPosContainer;