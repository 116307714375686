import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { AddSmartPrinterByLocalClienteIdUseCase } from '../../application/useCases/AddSmartPrinterByLocalClienteIdUseCase';
import { DeleteSmartPrinterByIdUseCase } from '../../application/useCases/DeleteSmartPrinterByIdUseCase';
import { EditSmartPrinterByLocalClienteIdUseCase } from '../../application/useCases/EditSmartPrinterByLocalClienteIdUseCase';
import GetPrinterListUseCase from '../../application/useCases/GetPrinterListUseCase';
import GetProductListUseCase from '../../application/useCases/GetProductListUseCase';
import GetSmartPrinterUseCase from '../../application/useCases/GetSmartPrinterUseCase';
import GetTableListUseCase from '../../application/useCases/GetTableListUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useEffect, useState } from 'react'
import PrinterApi from 'services/api/config/printer/PrinterApi';
import { v4 } from 'uuid';
import { IPrinterItem, IProductItem, ISmartPrinter, ISmartPrinterItem, ITableItem, ITotemItem } from '../components/smartPrinterIForm/interfaces/ISmartPrinterForm';
import GetTotemListUseCase from '../../application/useCases/GetTotemListUseCase';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import GetProfileListUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/GetProfileListUseCase';
import GetSmartPrinterByIdUseCase from '../../application/useCases/GetSmartPrinterByIdUseCase';

export const UseSmartPrinterPage = () => {
    const { currentLocal } = useLocal();
    const { toast, showLoading, hideLoading } = useUi();
    const [productList, setProductList] = useState<IProductItem[]>([]);
    const [printerList, setPrinterList] = useState<IPrinterItem[]>([]);
    const [tableList, setTableList] = useState<ITableItem[]>([]);
    const [totemList, setTotemList] = useState<ITotemItem[]>([]);

    const [list, setList] = useState<ISmartPrinter>({
        list: [
            {
                id: '',
                name: '',
                ownerId: '',
                printerId: '',
                productsIds: [],
                tablesIds: [],
                totemsIds: [],
                printerName: ''
            }
        ]
    })

    const [defaultValues, setDefaultValues] = useState<ISmartPrinterItem>()
    const [selectedSmartPrinter, setSelectedSmartPrinter] = useState<ISmartPrinterItem>()
    const [openAddSmartPrinter, setOpenAddSmartPrinter] = useState(false)
    const [selectedDeleteSmartPrinter, setSelectedDeleteSmartPrinter] = useState<ISmartPrinterItem>()
    //const [pagination, setPagination] = useState<{ page: number, size: number }>({ page: 1, size: 5 });


    const handleEditSmartPrinterModal = (_smartPrinterItem: ISmartPrinterItem) => {
        setSelectedSmartPrinter(_smartPrinterItem)
    }

    const handleCloseSmartPrinterEditModal = () => {
        setSelectedSmartPrinter(undefined)
        setDefaultValues(undefined)
    }
    
    const handleOpenAddSmartPrinterModal = () => {
        setOpenAddSmartPrinter(true);
    }
    
    const handleCloseAddSmartPrinterModal = () => {
        setOpenAddSmartPrinter(false);
        setDefaultValues(undefined)
    }

    const getSmartPrinters = useCallback(() => {
        if (currentLocal) {
            showLoading();

            GetSmartPrinterUseCase(PrinterApi, currentLocal.id).then((response) => {
                setList(response);


            }).finally(() => {
                hideLoading();
            })
        }
    }, [currentLocal, hideLoading, showLoading]);

    const getDefaultSmartPrinters = useCallback(async (id: string) => {
        try {
            showLoading();
            const response = await GetSmartPrinterByIdUseCase(PrinterApi, id);
            if (response)
                setDefaultValues(response);
            return response as ISmartPrinterItem;
        } finally {
            hideLoading();
        }
    }, [hideLoading, showLoading]);


    useEffect(() => {
        if (selectedSmartPrinter) {
            getDefaultSmartPrinters(selectedSmartPrinter.id);
        } else {
            setDefaultValues(undefined);
        }
    }, [getDefaultSmartPrinters, selectedSmartPrinter]);


    const getProductList = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading();
                const response = await GetProductListUseCase(PrinterApi, currentLocal.id);
                setProductList(response);
                return response;
            } finally {
                hideLoading();
            }
        }
    }, [currentLocal, hideLoading, showLoading]);

    const getTableList = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading();
                const response = await GetTableListUseCase(PrinterApi, currentLocal.id)
                setTableList(response);
                return response ?? []
            } finally {
                hideLoading();
            }

        }else{
            throw new Error("Erro ao buscar mesas")
        }
    }, [currentLocal, hideLoading, showLoading]);

    const getTotemList = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading();
                const response = await GetTotemListUseCase(PrinterApi, currentLocal.id)
                setTotemList(response);
                return response ?? []
            } finally {
                hideLoading();
            }
        }else{
            throw new Error("Erro ao buscar totens")
        }
    }, [currentLocal, hideLoading, showLoading]);

    const getPrinterList = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading();
                const response = await GetPrinterListUseCase(PrinterApi, currentLocal.id)
                setPrinterList(response);
                return response;
            } catch {
                throw new Error("Erro ao buscar impressoras");
            } finally {
                hideLoading();
            }
        } else {
            throw new Error("Erro ao buscar impressoras");
        }
    }, [currentLocal, hideLoading, showLoading]);


    const deletePrinterSmart = useCallback(() => {
        showLoading()
        if (selectedDeleteSmartPrinter) {
            DeleteSmartPrinterByIdUseCase(PrinterApi, selectedDeleteSmartPrinter.id).then(response => {
                toast("Configuração de impressora inteligente deletada com sucesso", "success");
            }).finally(() => {
                hideLoading();
                deleteSmartPrintCloseModal();
                getSmartPrinters();
            })
        }

    }, [getSmartPrinters, hideLoading, selectedDeleteSmartPrinter, showLoading, toast]);

    const deleteSmartPrinterOpenModal = (deleteSmartPrinter: ISmartPrinterItem) => {
        setSelectedDeleteSmartPrinter(deleteSmartPrinter)
    }
    const deleteSmartPrintCloseModal = () => {
        setSelectedDeleteSmartPrinter(undefined)
    }



   

    const getDeviceProfiles = useCallback(async () => {
        const deviceProvilesService = DeviceProfileApi()
        if (currentLocal) {
            const getDeviceProfiles = await GetProfileListUseCase(deviceProvilesService, currentLocal?.id);
            return getDeviceProfiles;
        } else {
            throw new Error("Erro ao buscar perfis de dispositivo");
        }
    }, [currentLocal])

    const onSubmit = useCallback(async (values: ISmartPrinterItem) => {
        try {
            showLoading()
            if (currentLocal) {

                if (!!values.id) {
                   const response = await EditSmartPrinterByLocalClienteIdUseCase(PrinterApi, values, values.id)
                   await getDefaultSmartPrinters(response.id);
                } else {
                    const smartPrinter: ISmartPrinterItem = {
                        ...values,
                        id: v4(),
                        ownerId: currentLocal.id,
                    }
                    const response = await AddSmartPrinterByLocalClienteIdUseCase(PrinterApi, smartPrinter, currentLocal.id)
                    await getDefaultSmartPrinters(response.id);
                
                }
            }
        } finally {
            hideLoading()
            // handleCloseSmartPrinterEditModal()
            getSmartPrinters()
        }
    }, [currentLocal, getDefaultSmartPrinters, getSmartPrinters, hideLoading, showLoading]);

    return (
        {
            list,
            setList,
            setDefaultValues,
            defaultValues,
            onSubmit,

            selectedSmartPrinter,
            productList,
            printerList,
            tableList,
            totemList,
            handleEditSmartPrinterModal,
            handleCloseSmartPrinterEditModal,
            handleOpenAddSmartPrinterModal,
            handleCloseAddSmartPrinterModal,
            openAddSmartPrinter,
            deletePrinterSmart,
            deleteSmartPrinterOpenModal,
            deleteSmartPrintCloseModal,
            selectedDeleteSmartPrinter,
            getProductList,
            getSmartPrinters,
            getTableList,
            getTotemList,
            getPrinterList,
            getDeviceProfiles,
            getDefaultSmartPrinters,
            currentLocal,
        }
    )
}
