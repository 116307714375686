import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './InventoryPage.module.scss'
import InventoryList from '../../components/inventory/inventoryList/InventoryList'
import DeleteErp from '../../components/ui/delete/DeleteErp'
import InvetoryService from 'modules/meepErp/services/api/inventory/InvetoryService'
import { IInventoryFilterValue } from '../../components/inventory/inventoryList/inventoryFilter/IInventoryFilter'
import { IGetInventoryListRequest, IGetInventoryListResponse } from 'modules/meepErp/application/dtos/invetory/InvetoryOperationDto'
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import UseQueryState from '../../hook/UseQueryFilter'
import { queryClient } from 'services/api/Api';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { DeleteInventoryUseCase } from 'modules/meepErp/application/interfaces/inventory/InventoryUseCases'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
export interface IInventoryPageProps {
    //propertys
}
const InventoryPage: FC<IInventoryPageProps> = () => {
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { state: filter, updateState: updateFilter } = UseQueryState<IInventoryFilterValue>();
    const { push } = useHistory()
    const { currentLocal } = useLocal()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Inventário",
            },  
        ]);
        
        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    //useCases
    const getInventoryListUseCase = async (filter: IInventoryFilterValue) => {
        if (!currentLocal?.id) return;
        const service = InvetoryService()
        const page = filter.page ?? 1;
        const pageSize = filter.pageSize ?? 10;
        const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
        const request: IGetInventoryListRequest = {
            page: page,
            pageSize: pageSize,
            orderBy: sortBy,
            ascending: filter.ascending,
            date: filter.date,
            description: filter.description
        }
        return service.getInventoryList(currentLocal.id, request);
    }

    //ReactiQuery

    const inventory = useQuery(['@inventory', filter], () => getInventoryListUseCase(filter))

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetInventoryListResponse | undefined>(['@inventory'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteOperationMutation = useMutation((id: string) => {
        if (currentLocal) {
            return DeleteInventoryUseCase(currentLocal.id, id);
        } else {
            // Handle the case where currentLocal or currentLocal.id is undefined
            throw new Error('currentLocal is undefined or does not have an id.');
        }
    }, {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteOperationMutation.mutateAsync(id);
    }, [onDeleteOperationMutation])


    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/inventory/add');
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    const onClickView = useCallback((id: string) => {  
        push(`/private/meeperp/inventory/update/${id}`);
    }, [push])
    return (
        <div id={styles.InventoryPage} >
            <div>
                <Button
                    fullWidth={false}
                    className={styles.buttonAdd}
                    onClick={onClickAdd}
                    endIcon={<Add className={styles.iconAdd} />}>
                    Adicionar inventário
                </Button>
            </div>
            <InventoryList
                filter={filter}
                onChangeFilter={updateFilter}
                inventory={inventory.data}
                onDelete={onClickDelete}
                isLoading={inventory.isLoading}
                onView={onClickView}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'inventário'}
                text={'Deseja realmente excluir o inventário?'}
                textFeedback={'Inventário excluído com sucesso!'}
                auxiliaryText='Ao excluir um inventário toda movimentação realizada será desfeita e impactará na posição de estoque dos insumos incluídos no inventário.'
            />
        </div>
    )
}
export default InventoryPage