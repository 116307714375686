import { WithdrawalRequest } from "modules/contaDigital/models/dtos/WithdrawalRequest";
import { IPostWithdrawalService } from "../interfaces/IContaDigitalApiService";

const PostWithdrawal = async (
  accountService: IPostWithdrawalService,
  body: WithdrawalRequest
): Promise<any> => {
  return await accountService.postWithdrawal(body);
};

export default PostWithdrawal;
