import { FC, ReactNode } from 'react'
import { ICampaignPush } from '../../intefaces/ICampaign'
import { InputContainer } from '../_inputContainer/InputContainer'
import { InputCRM } from '../_inputCRMFilter/InputCRM'
import styles from './TempateCampaignEventPush.module.scss'
import InputSingleImage from 'components/ui/inputSingleImage/InputSingleImage';
import { IMG } from 'services/utils/IMG';
import { format } from 'date-fns'
import { TextField } from '@material-ui/core'
import InputMasked from 'components/inputMasked/InputMasked'

type errorType = {
    [key in keyof ICampaignPush]: string
}

export interface ITempateCampaignEventPushProps {
    values?: ICampaignPush
    validate?: (validate: boolean) => void,
    onChange: (values: ICampaignPush) => void
    errors?: errorType;
    instruction: ReactNode[]
}
const TempateCampaignEventPush: FC<ITempateCampaignEventPushProps> = ({ values, onChange, errors, instruction }) => {
    return (
        <>
            <InputContainer style={{ marginBottom: 32 }}>
                {instruction[0]}
                <span className={styles.customComunicationTitle}>O envio do push passa por aprovação interna da Meep.
                    Sendo assim, sua mensagem será lida pelo nosso time em até 24h úteis <br />antes de ser aprovada.
                    Caso o conteúdo esteja de acordo com nossas políticas será enviado.
                </span>

                {/* <div className={styles.datePickerContainer}>
                    {values?.date}
                    <InputMasked
                        name={'date'}
                        variant='outlined'
                        mask="99/99/9999"
                        maskPlaceholder={format(new Date(), "dd/MM/yyyy")}
                        value={values?.date || ''}
                        className={styles.input}
                        helperText={errors?.date}
                        error={!!errors?.date}
                        onChange={(ev: any) => {
                            onChange({ ...values, date: ev.target.value })
                        }}
                    />
                    {values?.time}
                    <InputMasked
                        name={'time'}
                        variant='outlined'
                        mask="99:99"
                        maskPlaceholder={format(new Date(), "HH:mm")}
                        value={values?.time || ''}
                        className={styles.input}
                        helperText={errors?.time}
                        error={!!errors?.time}
                        onChange={(ev: any) => {
                            onChange({ ...values, time: ev.target.value })
                        }}
                    />
                </div> */}
                <div className={styles.datePickerContainer}>
                    <TextField
                        name={'date'}
                        variant='outlined'
                        // mask="99/99/9999"
                        // placeholder={format(new Date(), "dd/MM/yyyy")}
                        value={values?.date || ''}
                        className={styles.input}
                        helperText={errors?.date}
                        error={!!errors?.date}
                        onChange={(ev: any) => {
                            onChange({ ...values, date: ev.target.value })
                        }}
                        inputProps={{
                            type: "date"
                        }}
                    />
                    <TextField
                        name={'time'}
                        variant='outlined'
                        // mask="99:99"
                        // placeholder={format(new Date(), "HH:mm")}
                        value={values?.time || ''}
                        className={styles.input}
                        helperText={errors?.time}
                        error={!!errors?.time}
                        onChange={(ev: any) => {
                            onChange({ ...values, time: ev.target.value })
                        }}
                        inputProps={{
                            type: "time"
                        }}
                    />
                </div>
                {/* <span className={styles.datePickerInfo}>Seu push será enviado para revisão em 24h.</span> */}
            </InputContainer>

            <InputContainer style={{ marginBottom: 32 }}>
                {instruction[1]}
                <div className={styles.row}>
                    {/* <div className={styles.imageInput}>
                        <InputSingleImage
                            value={values?.imageUrl}
                            onChangeImage={(imageUrl) => onChange({ ...values, imageUrl })}
                            name="imageUrl"
                            accept={[IMG.JPEG, IMG.PNG]}
                            height={180}
                            width={180}
                        />
                        <div className={styles.imageInputHint}>
                            <ul>
                                <li>
                                    Envie uma imagem nos formatos, PNG ou JPG  com o tamanho de 128x128px.
                                </li>
                                <li>A imagem será usada na sua notificação de push.</li>
                            </ul>
                        </div>
                    </div> */}
                    <div className={styles.pushInfos}>
                        <InputContainer>
                            <InputCRM
                                required
                                error={!!errors?.subject}
                                helperText={errors?.subject}
                                label={"Título do push"}
                                placeholder='Max.: 24 caracteres'
                                name='subject'
                                value={values?.subject}
                                onChange={(e) => onChange({ ...values, subject: e.target.value })}
                                inputProps={{ maxLength: 24 }}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputCRM
                                error={!!errors?.messageBody}
                                helperText={errors?.messageBody}
                                placeholder='Insira o texto do seu push'
                                name='messageBody'
                                value={values?.messageBody}
                                onChange={(e) => onChange({ ...values, messageBody: e.target.value })}
                                inputProps={{ maxLength: 120 }}
                                multiline
                                rows={3}
                            />
                        </InputContainer>
                        <span className={styles.messageBodyFooter}>Máximo 120 caracteres</span>
                    </div>
                </div>
                <div className={styles.previewContainer}>
                    <div className={styles.iphoneContainer}>
                        <img src="/assets/img/iphone.png" alt="" />
                        <div className={styles.notificationCard}>
                            {
                                values?.imageUrl &&
                                <div className={styles.imgContainer}>
                                    <img src={values?.imageUrl} alt="" />
                                </div>
                            }
                            <div className={styles.infosContainer}>
                                <span>{values?.subject}</span>
                                <span>{values?.messageBody}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </InputContainer>
        </>
    )
}
export default TempateCampaignEventPush
