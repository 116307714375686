import { AxiosInstance } from "axios";
import { IGetOperatorListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/IGetOperatorListResponse";

export interface IBuscarOperadoreItem {
    id: string,
    nome: string,
}


export const GetOperadores = async (api: AxiosInstance, localId: string): Promise<IGetOperatorListResponse> => {
    const response = await api.get<IBuscarOperadoreItem[]>('/Proprietario/BuscarOperadoresDoLocal/' + localId)
    return { items: response.data.map((item) => ({ name: item.nome, id: item.id, imageUrl: "" })) }
}
