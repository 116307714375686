import React, { FC, useEffect } from 'react'
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { ButtonContainer, Container, InputContainer, Row } from '../../ui/form/FormContainers';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import SkeletonForm from '../../ui/form/skeletonForm/SkeletonForm';
import { ISegmentValues } from './SegmentValues';
import { ISegmentSchema } from './SegmentFormValidation';
import styles from './SegmentForm.module.scss'
import Button from 'components/ui/Button/Button';

export interface ISegmentFormProps {
    onSubmit: (values: ISegmentValues) => Promise<void>
    defaultValue?: ISegmentValues
    isLoading?: boolean
    disabled?: boolean
}
const SegmentForm: FC<ISegmentFormProps> = ({
    onSubmit,
    defaultValue,
    isLoading,
    disabled
}) => {
    const { push } = useHistory();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<ISegmentValues>({
        resolver: zodResolver(ISegmentSchema),
        defaultValues: defaultValue ?? {
            name: "",
            isActive: true,
        }
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        }
    }, [defaultValue, reset]);


    return (
        isLoading ? <SkeletonForm lines={1} /> :
            <form onSubmit={handleSubmit(onSubmit)} id={styles.SegmentForm}>
                <Container >
                    <Row>
                        <InputContainer label='Nome' requerid>
                            <TextField
                                {...register("name")}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                disabled={isLoading || disabled}
                            />
                        </InputContainer>
                    </Row>

                    <ButtonContainer >
                        <Button color='secondary' onClick={() => push('/private/meeperp/segment')} fullWidth={false} variant='outlined'>Cancelar</Button>
                        <Button type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                    </ButtonContainer>
                </Container>
            </form>
    )
}
export default SegmentForm