import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IGetPermutionItemResponse } from "modules/permutation/domain/dto/IGetPermutionItemResponse";


const GetPermutationItemUseCase = async (service: IPermutationApiService, localId: string, accountId: string): Promise<IGetPermutionItemResponse> => {
   

    const response = await service.getAccountItem(localId, accountId)
    return (response)
}
export default GetPermutationItemUseCase