import { IGetDigitalAccountBalanceService } from "../interfaces/IContaDigitalApiService";

const GetDigitalAccountsBalance = async (
  accountService: IGetDigitalAccountBalanceService,
  ownerId: string,
  digitalAccountId: string
): Promise<any> => {
  return await accountService.getDigitalAccountBalance(ownerId, digitalAccountId);
};

export default GetDigitalAccountsBalance;
