import { Modal } from '@material-ui/core';

import styles from './RaiseSalesModal.module.scss';
import UseDimension from 'components/dimension/UseDimension';
import RenderIf from 'components/renderIf/RenderIf';

const RaiseSalesModal = ({ isOpen = false, closeFn }: any) => {
    const { isMobile } = UseDimension();

    return (
        <>
            <Modal open={isOpen}>
                <div className={styles.raiseSalesModalWrapper}>

                    <div className={styles.raiseSalesHeader}>
                        <p className={styles.raiseSalesTitle}>Aumente suas vendas com o cardápio digital</p>
                        <button className={styles.raiseSalesClose} onClick={closeFn}>&#x2715;</button>
                    </div>

                    <p className={styles.raiseSalesSubtitle}>
                        Um cardápio bem estruturado e com fotos estratégicas pode <b>aumentar suas vendas</b> significativamente.
                    </p>

                    <div className={styles.raiseSalesContentWrapper}>
                        <RenderIf condition={!isMobile}>
                            <div className={styles.raiseSalesVideoWrapper}>
                                <div className={styles.raiseSalesVideoFrame} style={{ backgroundImage: `url(/assets/img/digital-menu-phone-frame.png)`}}></div>
                                <video className={styles.raiseSalesVideo} autoPlay width="220"  height="440" loop>
                                    <source src="/assets/video/raise-sales.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </RenderIf>
                        <div>
                            <p className={styles.raiseSalesListTitle}>Nosso dicas para que seu cardápio seja atraente</p>

                            <div className={styles.raiseSalesItem}>
                                <div className={styles.raiseSalesItemImage} style={{ backgroundImage: `url(/assets/img/digital-menu-raise-sales-camera.svg)`}}></div>
                                <div>
                                    <p className={styles.raiseSalesItemTitle}>Qualidade das imagens</p>
                                    <p className={styles.raiseSalesItemText}>Use fotos de alta qualidade e que valorizem o prato, para torná-lo mais desejável.</p>
                                </div>
                            </div>

                            <div className={styles.raiseSalesItem}>
                                <div className={styles.raiseSalesItemImage} style={{ backgroundImage: `url(/assets/img/digital-menu-raise-sales-cupcake.svg)`}}></div>
                                <div>
                                    <p className={styles.raiseSalesItemTitle}>Descrições apetitosas</p>
                                    <p className={styles.raiseSalesItemText}>Descreva os ingredientes do prato e como ele é preparado, para que o cliente não tenha dúvidas na hora do pedido.</p>
                                </div>
                            </div>

                            <div className={styles.raiseSalesItem}>
                                <div className={styles.raiseSalesItemImage} style={{ backgroundImage: `url(/assets/img/digital-menu-raise-sales-pencil.svg)`}}></div>
                                <div>
                                    <p className={styles.raiseSalesItemTitle}>Mantenha o cardápio atualizado</p>
                                    <p className={styles.raiseSalesItemText}>Atualize o seu cardápio regularmente, com novos pratos, preços, promoções e fotos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default RaiseSalesModal;