import { Icon } from '@material-ui/core';
import styles from './PublicGuestListForm.module.scss';
import Input from "components/ui/input/Input";
import { useState } from 'react';

interface PublicGuestListFormProps {
    onClose: () => void;
    onSubmit: (cpfList: string) => void;
    invalidDocs?: string[];
    loading: boolean;
}

export const PublicGuestListForm = ({ onClose, onSubmit, invalidDocs, loading }: PublicGuestListFormProps) => {
    const [values, setValues] = useState('');
    
    return (
        <div className={styles.container}>
            <header>
                <span>Adicionar <strong>CPF na lista</strong></span>
                <button onClick={onClose} className={styles.closeButton}>
                    <Icon>close</Icon>
                </button>
            </header>

            <label>Lista de CPF</label>
            <Input
                variant="outlined"
                fullWidth
                value={values}
                onChange={(ev) => setValues(ev.target.value.replace(/[^\d.\n-]/g, ''))}
                multiline
                rows={6}
                placeholder={`000.000.000-00\n000.000.000-00\n000.000.000-00\n000.000.000-00\n000.000.000-00\n000.000.000-00`}
                error={!!invalidDocs?.length}
                // helperText={errors.shareDescription}
            />
            <span className={styles.hint}>Quando for adicionar, colocar em formato de lista como no exemplo. </span>

            {
                !!invalidDocs?.length &&
                <div className={styles.invalidDocs}>
                    <span>Os seguintes CPFs <b>são inválidos:</b></span>
                    {
                        invalidDocs.map(x => (
                            <span>{x}</span>
                        ))
                    }
                </div>
            }

            <div className={styles.buttonsContainer}>
                <button onClick={onClose} type='button'>Voltar</button>
                <button disabled={loading || !values} onClick={() => onSubmit(values)} type='button'>{loading ? 'Salvando...' : 'Salvar'}</button>
            </div>
        </div>
    )
}