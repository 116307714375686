import React, { FC, useEffect } from 'react'
import styles from './UnitForm.module.scss'
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { ButtonContainer, Container, InputContainer, Row } from '../../ui/form/FormContainers';
import { TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { EMPTY_UNIT_VALUES, IUnitValues } from './UnitValues';
import { IUnitSchema } from './UnitFormValidation';
import SkeletonForm from '../../ui/form/skeletonForm/SkeletonForm';
import Button from 'components/ui/Button/Button';

export interface IUnitFormProps {
    onSubmit: (values: IUnitValues) => Promise<void>
    defaultValue?: IUnitValues
    isLoading?: boolean
    disabled?: boolean
}
const UnitForm: FC<IUnitFormProps> = ({
    onSubmit,
    defaultValue,
    isLoading,
    disabled
}) => {
    const { push } = useHistory();
    const { id } = useParams<{ id?: string }>();
    const { register, handleSubmit, reset, formState: { errors }, watch } = useForm<IUnitValues>({
        resolver: zodResolver(IUnitSchema),
        defaultValues: EMPTY_UNIT_VALUES 
    });

    useEffect(() => {
        if (id) {
            reset(defaultValue);
        }else{
            reset(EMPTY_UNIT_VALUES)
        }
    }, [defaultValue, reset]);

    return (
        isLoading ? <SkeletonForm lines={1}/> :
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Container center >
                    <Row>
                        <InputContainer  flex={3}  label='Nome' requerid>
                            <TextField
                                {...register("name")}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                disabled={isLoading || disabled}
                                value={watch("name") ?? ''}
                            />
                        </InputContainer>
                        <InputContainer  label='Sigla' requerid>
                            <TextField
                                {...register("symbol")}
                                error={!!errors.symbol}
                                helperText={errors.symbol?.message}
                                disabled={isLoading || disabled}
                                value={watch("symbol") ?? ''}
                            />
                        </InputContainer>
                    </Row>

                    <ButtonContainer >
                        <Button color='secondary' disabled={isLoading || disabled} onClick={() => push('/private/meeperp/unit')} fullWidth={false} variant='outlined'>Cancelar</Button>
                        <Button type="submit" disabled={isLoading || disabled} fullWidth={false} color='secondary'>Concluir</Button>
                    </ButtonContainer>

                </Container>
            </form>
    )
}
export default UnitForm