import { IGetTransactionsParams } from "../domain/dto/IGetTransactionsParams";
import { IGetTransactionsResponse } from "../domain/dto/IGetTransactionsResponse";
import { ITransactionReportService } from "../domain/interface/ITransactionReportService"

const GetTransactionsUseCase = async (
  service: ITransactionReportService,
  localClienteId: string,
  params?: IGetTransactionsParams,
  page?: number,
  pageSize?: number
): Promise<IGetTransactionsResponse> => {
  const response = service.getTransactions({...params, LocalClienteId: localClienteId, page: page ?? 0, pageSize: pageSize ?? 10});
  return response;
};
export default GetTransactionsUseCase;
