import { UseQueryResult, useQuery } from "react-query";

import { BaseErrorTypes, normalizeResponse } from "services/api/Api";
import { GetAdvanceFilterLists } from "../requests";
import {
  AdvancedFiltersTypesCollection,
  GetAdvancedFilterListsProps,
} from "../types/GetAdvancedFilterLists.types";

type UseAdvanceFilterListResult = UseQueryResult<
  AdvancedFiltersTypesCollection,
  BaseErrorTypes
>;

export const UseAdvanceFilterListQueryName = "UseAdvanceFilterList";

export const UseAdvanceFilterList = ({
  startDate,
  endDate,
  localId,
  cashierIds,
}: GetAdvancedFilterListsProps): UseAdvanceFilterListResult => {
  const request = async (): Promise<AdvancedFiltersTypesCollection> => {
    if (!cashierIds || (cashierIds && !cashierIds.length)) {
      return {} as AdvancedFiltersTypesCollection;
    }
    const response = await GetAdvanceFilterLists({
      startDate,
      endDate,
      localId,
      cashierIds,
    });
    return normalizeResponse<AdvancedFiltersTypesCollection>(response);
  };

  return useQuery<AdvancedFiltersTypesCollection, BaseErrorTypes>(
    [UseAdvanceFilterListQueryName, startDate, endDate, cashierIds],
    request,
    { refetchOnWindowFocus: false }
  );
};
