import { IPayFormErrors, IPayFormItem } from "../interfaces/IPayForm";

export const validate = (
  formData: IPayFormItem,
  setErrors: React.Dispatch<React.SetStateAction<IPayFormErrors>>,
  currentStep: number
) => {
  let _errors: IPayFormErrors = {};
  let hasError = false;

  function validarCPF(cpf: string) {
    const cleanedCPF = cpf.replace(/\D/g, "");
    if (cleanedCPF.length !== 11) return false;

    const cpfArray = cleanedCPF.split("").map(Number);

    // Verificação dos dígitos verificadores
    const dv1 = cpfArray[9];
    const dv2 = cpfArray[10];
    const calcDV1 =
      cpfArray.reduce(
        (acc, val, idx) => (idx < 9 ? acc + val * (10 - idx) : acc),
        0
      ) % 11;
    const calcDV2 =
      (cpfArray.reduce(
        (acc, val, idx) => (idx < 10 ? acc + val * (11 - idx) : acc),
        0
      ) %
        11) %
      10;

    return dv1 === calcDV1 && dv2 === calcDV2;
  }

  function validarCNPJ(cnpj: string) {
    const cleanedCNPJ = cnpj.replace(/\D/g, "");
    if (cleanedCNPJ.length !== 14) return false;

    const cnpjArray = cleanedCNPJ.split("").map(Number);

    // Verificação dos dígitos verificadores
    const dv1 = cnpjArray[12];
    const dv2 = cnpjArray[13];
    const calcDV1 =
      (cnpjArray.reduce(
        (acc, val, idx) => (idx < 12 ? acc + val * (5 - (idx % 4)) : acc),
        0
      ) %
        11) %
      10;
    const calcDV2 =
      (cnpjArray.reduce(
        (acc, val, idx) => (idx < 13 ? acc + val * (6 - (idx % 4)) : acc),
        0
      ) %
        11) %
      10;

    return dv1 === calcDV1 && dv2 === calcDV2;
  }

  if (currentStep === 2) {
    if (!formData.codigo) {
      _errors = { ..._errors, codigo: "O campo código é obrigatório" };
      hasError = true;
    }
  }

  if (!formData.documento) {
    _errors = {
      ..._errors,
      documento: "O campo CPF ou CNPJ do beneficiário do boleto é obrigatório",
    };
    hasError = true;
  } else if (
    !validarCPF(formData.documento) &&
    !validarCNPJ(formData.documento)
  ) {
    _errors = { ..._errors, documento: "CPF ou CNPJ inválido" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};

//CONSTANTES PARA VALIDAR BOLETO
const PARTIALS = [
  {
    end: 9,
    start: 0,
    index: 9,
  },
  {
    end: 20,
    start: 10,
    index: 20,
  },
  {
    end: 31,
    start: 21,
    index: 31,
  },
];
const LENGTH$3 = 47;
const CHECK_DIGIT_POSITION = 4;
const MOD_11_WEIGHTS = {
  end: 9,
  initial: 2,
};
const MOD_10_WEIGHTS = [2, 1];
const DIGITABLE_LINE_TO_BOLETO_CONVERT_POSITIONS = [
  {
    end: 4,
    start: 0,
  },
  {
    end: 47,
    start: 32,
  },
  {
    end: 9,
    start: 4,
  },
  {
    end: 20,
    start: 10,
  },
  {
    end: 31,
    start: 21,
  },
];

const isValidLength = (digitableLine: string) => {
  return digitableLine.length === LENGTH$3;
};

const onlyNumbers = (input: string) => {
  return String(input).replace(/[^\d]/g, "");
};

const mod10 = (partial: string) => {
  const sum = partial
    .split("")
    .reverse()
    .reduce(function (acc, digit, index) {
      const result = parseInt(digit, 10) * MOD_10_WEIGHTS[index % 2];
      return acc + (result > 9 ? 1 + (result % 10) : result);
    }, 0);

  const mod = sum % 10;
  return mod > 0 ? 10 - mod : 0;
};

function isValidPartials(digitableLine: string) {
  return PARTIALS.every(({ start, end, index }) => {
    const mod = mod10(digitableLine.substring(start, end));
    return +digitableLine[index] === mod;
  });
}

function parse(digitableLine: string) {
  return DIGITABLE_LINE_TO_BOLETO_CONVERT_POSITIONS.reduce(function (acc, pos) {
    return acc + digitableLine.substring(pos.start, pos.end);
  }, "");
}

function mod11(value: string) {
  let weight = MOD_11_WEIGHTS.initial;
  const sum = value
    .split("")
    .reverse()
    .reduce(function (acc, digit) {
      const result = parseInt(digit, 10) * weight;
      weight =
        weight < MOD_11_WEIGHTS.end ? weight + 1 : MOD_11_WEIGHTS.initial;
      return acc + result;
    }, 0);
  const mod = sum % 11;
  return mod === 0 || mod === 1 ? 1 : 11 - mod;
}

function isValidCheckDigit(parsedDigitableLine: string) {
  const mod = mod11(
    parsedDigitableLine.slice(0, CHECK_DIGIT_POSITION) +
      parsedDigitableLine.slice(CHECK_DIGIT_POSITION + 1)
  );
  return +parsedDigitableLine[CHECK_DIGIT_POSITION] === mod;
}

export const isValidBoleto = (digitableLine: string) => {
  if (!digitableLine || typeof digitableLine !== "string") return false;
  const digits = onlyNumbers(digitableLine);
  if (!isValidLength(digits)) return false;
  if (!isValidPartials(digits)) return false;
  const parsedDigits = parse(digits);
  return isValidCheckDigit(parsedDigits);
};

const feriados = [
  new Date(2023, 10 - 1, 2), // Finados - 02 de novembro de 2023
  new Date(2023, 11 - 1, 15), // Proclamação da República - 15 de novembro de 2023
  new Date(2023, 11 - 1, 25), // Natal - 25 de dezembro de 2023
  new Date(2024, 0, 1), // Ano Novo - 01 de janeiro de 2024
  new Date(2024, 1 - 1, 25), // Carnaval - 25 de fevereiro de 2024
  new Date(2024, 1 - 1, 26), // Carnaval - 26 de fevereiro de 2024
  new Date(2024, 3 - 1, 29), // Sexta-feira Santa - 29 de março de 2024
  new Date(2024, 4 - 1, 21), // Tiradentes - 21 de abril de 2024
  new Date(2024, 4 - 1, 25), // Dia Mundial do Trabalho - 25 de abril de 2024
  new Date(2024, 8 - 1, 15), // Assunção de Nossa Senhora - 15 de agosto de 2024
  new Date(2024, 9 - 1, 7), // Independência do Brasil - 07 de setembro de 2024
  new Date(2024, 10 - 1, 12), // Nossa Senhora Aparecida - 12 de outubro de 2024
  new Date(2024, 10 - 1, 28), // Finados - 28 de outubro de 2024
  new Date(2024, 11 - 1, 15), // Proclamação da República - 15 de novembro de 2024
  new Date(2024, 11 - 1, 25), // Natal - 25 de dezembro de 2024
  new Date(2025, 0, 1), // Ano Novo - 01 de janeiro de 2025
  new Date(2025, 1 - 1, 20), // Carnaval - 20 de janeiro de 2025
  new Date(2025, 1 - 1, 21), // Carnaval - 21 de janeiro de 2025
  new Date(2025, 3 - 1, 18), // Sexta-feira Santa - 18 de março de 2025
  new Date(2025, 4 - 1, 21), // Tiradentes - 21 de abril de 2025
  new Date(2025, 4 - 1, 25), // Dia Mundial do Trabalho - 25 de abril de 2025
  new Date(2025, 8 - 1, 15), // Assunção de Nossa Senhora - 15 de agosto de 2025
  new Date(2025, 9 - 1, 7), // Independência do Brasil - 07 de setembro de 2025
  new Date(2025, 10 - 1, 12), // Nossa Senhora Aparecida - 12 de outubro de 2025
  new Date(2025, 10 - 1, 27), // Finados - 27 de outubro de 2025
  new Date(2025, 11 - 1, 15), // Proclamação da República - 15 de novembro de 2025
  new Date(2025, 11 - 1, 25), // Natal - 25 de dezembro de 2025
];

export const ValidarVencimentoBoleto = (numeroBoleto: string) => {
  let digitableLine = numeroBoleto.replace(/\D/g, "");
  let numeroVencimento = digitableLine.substr(-14);
  let vencimento = numeroVencimento.substr(0, 4);

  let dataInicialFebraban = new Date(1997, 10 - 1, 7);
  let dateJS = new Date(
    dataInicialFebraban.getTime() + Number(vencimento) * 24 * 60 * 60 * 1000
  );
  let dataAtual = new Date();
  let diasUteis = 0;

  const ehSabadoOuDomingo = (date: Date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const ehFeriado = (date: Date) => {
    return feriados.some((feriado) => {
      return (
        date.getFullYear() === feriado.getFullYear() &&
        date.getMonth() === feriado.getMonth() &&
        date.getDate() === feriado.getDate()
      );
    });
  };

  while (diasUteis < 2) {
    let isDiaUtil = !ehSabadoOuDomingo(dataAtual) && !ehFeriado(dataAtual);
    dataAtual = new Date(dataAtual.getTime() + 24 * 60 * 60 * 1000);
    if (isDiaUtil) {
      diasUteis++;
    }
  }

  if (dateJS < dataAtual) {
    return false;
  } else {
    return true;
  }
};
