import { IUpdateFidelityRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityRequest";
import { ScoringSchemeType } from "modules/fidelity/domain/dtos/ScoringSchemeType";
import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService"
import { IFidelityNameFormValue } from "modules/fidelity/presentation/components/forms/name/FidelityNameForm";
import { IFidelityPointFormValue } from "modules/fidelity/presentation/components/forms/poitnRules/FidelityPointForm";

const UpdatePointRulesFidelityLevelUseCase = async (service: IFidelityService, ownerId: string, fidelityId: string, values: IFidelityPointFormValue): Promise<IFidelityNameFormValue> => {
    const request: IUpdateFidelityRequest = {
        levelScoringRules: values.rules.map(item => ({
            points: item.value || 0,
            target: item.type === ScoringSchemeType.FREQUENCY ? 1 : item.target,
            type: item.type,
        }))
    }
    var response = await service.updateFidelityLevel(ownerId, fidelityId, request);
    return response
}

export default UpdatePointRulesFidelityLevelUseCase