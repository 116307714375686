import { FC, useEffect, useMemo, useState } from 'react'
import styles from './TerminalPage.module.scss'
import TerminalDeviceItem from './component/TerminalItem/TerminalDeviceItem';
import { UseTerminalPage } from './hooks/UseTerminalPage';
import { Close } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import OperatorAndTableFragment from './component/operatorAndTableFragment/OperatorAndTableFragment';

export interface ITerminalPageProps {
    //propertys
}

const TerminalPage: FC<ITerminalPageProps> = () => {
    const {
        devices,
        getCatalogs,
        catalogList,
        patchUpdateTerminalConfig,
        getNavigatorSolicitations,
        postApproveSolicitation,
        postRefuseSolicitation,
        postRefuseAllSolicitations,
        postCloseSession,
        refetch,
        printerSectors,
        getPrinterSectors
    } = UseTerminalPage();

    const [bannerOpen, setBannerOpen] = useState(true);

    useEffect(() => {
        getCatalogs();
        getPrinterSectors();
    }, [getCatalogs, getPrinterSectors]);

    const listDevice = useMemo(() => devices?.map((terminaDevice, index) =>
        <TerminalDeviceItem
            deviceType={terminaDevice.device.type.value}
            updateTerminalConfig={patchUpdateTerminalConfig}
            key={terminaDevice.device.id}
            device={terminaDevice}
            catalogs={catalogList ?? []}
            printerSectors={printerSectors ?? []}
            getNavigatorSolicitations={getNavigatorSolicitations}
            onAproveSolicitation={postApproveSolicitation}
            onRefuseSolicitation={postRefuseSolicitation}
            postRefuseAllSolicitations={postRefuseAllSolicitations}
            onEndSession={postCloseSession}
            getTerminalDevices={refetch}
            salesNowTerminal={index === 0}
        />
    ), [catalogList, getNavigatorSolicitations, patchUpdateTerminalConfig, postApproveSolicitation, postCloseSession, postRefuseAllSolicitations, postRefuseSolicitation, printerSectors, refetch, devices]);

    return (
        <div id={styles.TerminalPage}>
            {bannerOpen && (
                <div className={styles.bannerConfig}>
                    <IconButton className={styles.bannerCloseButton} onClick={() => setBannerOpen(false)} size="small"><Close /></IconButton>
                    <div>
                        <h2><b>Configure</b> seus terminais do jeito que seu negócio <b>precisa</b></h2>
                        <span>Personalize cada um dos terminais para operar de formas diferentes.  Além disso, aprove e controle os dispositivos que estão acessando.</span>
                    </div>
                    <img src="/assets/icon/saas/browser_icon.png" alt="" />
                </div>
            )}
            <div className={styles.headerButtonsContainer}>
                <OperatorAndTableFragment />
                {/* <CustomButton
                    title="Formas de pagamento"
                    icon="add_circle"
                    onClick={() => setOpenTable(true)}
                >
                    <div className={styles.buttonChildren}>
                        <span>Você tem {paymentMethods?.length} formas de pagamento.</span>
                    </div>
                </CustomButton> */}
            </div>
            <div className={styles.container} >
                {listDevice}
            </div>
        </div>
    )
}
export default TerminalPage;