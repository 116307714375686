import { api } from "../Api";
import { plansUrl } from "Enviroment";
import { ISaasChangePlanService } from "modules/saasChangePlan/domain/interface/ISaasChangePlanService";
import { ISaasChangePlanRequest } from "modules/saasChangePlan/domain/dto/ISaasChangePlanRequest";

const baseUrl = plansUrl;

export const SaasChangePlanApi = (): ISaasChangePlanService => {
    const changePlan = async (request: ISaasChangePlanRequest): Promise<void> => {
        return await api.post(`${baseUrl}/subscriptions/saas/change-plan`, request);
    }

    return { changePlan }
}