import { FC, useEffect, useRef } from "react";
import styles from "./SaasSuccessLayout.module.scss";

export interface ISaasSuccessLayoutProps {
    background?: string;
    logo?: string;
    progressBarColor?: string;
}

const SaasSuccessLayout: FC<ISaasSuccessLayoutProps> = ({ children, background, logo, progressBarColor }) => {
    const headerRef = useRef<HTMLDivElement>(null);

    const headerHeight = headerRef.current?.clientHeight ?? 93;

    const handleProgress = () => {
        const successBar = document.getElementById("successBar") as HTMLElement;
        if (successBar) {
            successBar.style.width = "100%";
        }
    }

    useEffect(() => {
        handleProgress();
    }, []);
    
    return (
        <div id={styles.SaasSuccessLayout} style={{ background: background ?? "" }}>
            <div className={styles.header}>
                <img src={logo ?? "/assets/img/LogoMee.svg"} alt="" />
                <div className={styles.successBar}>
                    <div id="successBar" style={{ backgroundColor: progressBarColor }} />
                </div>
            </div>
            <div className={styles.content} style={{ height: `calc(100vh - ${headerHeight}px)` }}>
                {children}
            </div>
        </div>
    );
};

export default SaasSuccessLayout;
  