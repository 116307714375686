import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Add from "@material-ui/icons/AddCircle";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { FC } from "react";
import { PromoterList } from "../promoterList/PromoterList";
import styles from "./GrantAccessForm.module.scss";
import { IPromoters } from "./interfaces/IPromoters";
import UseGrantAccessForm from "./UseGrantAccessForm";

interface IGrantAccessForm {
  listId?: string;
  onClose: () => void;
}

const GrantAccessForm: FC<IGrantAccessForm> = ({ listId, onClose }) => {
  const { addPromoter, deletePromoter, promoterWithAccess, promotersFromPlace, handleChangePromoter, adding } =
    UseGrantAccessForm(listId);

  return (
    <div id={styles.GrantAccessForm}>
      <div>

        <p>
          Estes são os promoters com <b>acesso apenas a esta lista.</b> Qualquer alteração feita aqui, irá sobrepor as
          alterações feitas para os promoters de todas as listas.
        </p>
        <PromoterList promoters={promoterWithAccess?.filter(p => !p.isMainPromoter) || []} onClickDelete={deletePromoter} />
        <div className={styles.add}>
          <FormItemContainer row>
            <FormItemContainer flex={15} label="Adicionar Promoter">
              <AutoComplete
                options={promotersFromPlace.filter(list => !promoterWithAccess?.find(value => value.id === list.id))}
                value={promoterWithAccess || []}
                getOptionLabel={(option) => option.name}
                onChange={(_evt, item) => handleChangePromoter(item as IPromoters)}
              />
            </FormItemContainer>
            <FormItemContainer flex={2}>
              {adding ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton onClick={addPromoter}>
                  <Add />
                </IconButton>
              )}
            </FormItemContainer>
          </FormItemContainer>
        </div>
      </div>
      <div className={styles.action}>
        <Button fullWidth={false} onClick={onClose} color="primary">
          Concluir
        </Button>
      </div>
    </div>
  );
};

export default GrantAccessForm;
