import React, { FC, useCallback, useEffect, useState } from 'react'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IRankingClients } from './IRankingClients'
import styles from './RankingClients.module.scss'

export interface IRankingClientsProps {
    getRankingClients: ({ page, pageSize }: IGetDataParams) => Promise<IRankingClients>
}

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)

const DEFAULT_PAGE_SIZE = 25;

const RankingClients: FC<IRankingClientsProps> = ({ getRankingClients }) => {
    const [rankingClientsData, setRankingClienData] = useState<IRankingClients>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);

    const getData = useCallback(async (page?: number) => {
        try {
            clearContainerError(dashboardContainerEnum.RANKING_CLIENTS);

            !page && setOnLoading(true);
            const response = await getRankingClients({ page, pageSize: DEFAULT_PAGE_SIZE });
            setRankingClienData(prev => ({ ...response, records: page ? [...prev?.records || [], ...response.records] : [...response.records] }));
            setCurrentPage(prev => prev + 1);

            if (!page && !response.records.length) {
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.RANKING_CLIENTS
                })
            }
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.RANKING_CLIENTS
            })
        } finally {
            setOnLoading(false);
        }
    }, [clearContainerError, getRankingClients, setOnError]);

    useEffect(() => {
        getData();
    }, [getData]);

    const getMore = useCallback(() => {
        getData(currentPage);
    }, [currentPage, getData])

    return (
        <div id={styles.RankingClients} >
            <RankingList
                // keyProp="customerId"
                list={rankingClientsData.records}
                count={rankingClientsData.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    customerName: { title: "Nome do usuário" },
                    totalConsumption: { title: "Consumo", formatValue: (value) => formatCurrency(value) },
                }}
            />
        </div>
    )
}
export default RankingClients
