import { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './PartnerFilter.module.scss'
import { MenuItem, TextField } from '@material-ui/core'
import { Container, FilterButtonContainer, InputContainer } from '../../../ui/form/FormContainers'
import Row from 'modules/catalog/presentation/componentes/ui/Row'
import { useForm } from 'react-hook-form'
import { IPartnerFilterValue } from './IPartnerFilter'
import { PartnerType } from 'modules/meepErp/models/partner/PartnerType'
import { Icon } from '@mui/material'
import Button from 'components/ui/Button/Button'

export interface IPartnerFilterProps {
    defaultValue?: IPartnerFilterValue
    onSubmit: (values: IPartnerFilterValue) => void
}

const PartnerFilter: FC<IPartnerFilterProps> = ({ defaultValue, onSubmit }) => {
    const { register, handleSubmit, reset, watch } = useForm<IPartnerFilterValue>({
        defaultValues: defaultValue ?? {
            code: "",
            name: "",
            partnerType: "",
            document: ''
        }
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        }
    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: IPartnerFilterValue) => {
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.ascending;
        delete allFields.sortBy;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            code: '',
            name: '',
            partnerType: "",
            document: ''
        });
        onSubmit({
            code: '',
            name: '',
            partnerType: "",
            document: ''
        })
    }, [onSubmit, reset])

    return (
        <Container>
            <form
                id={styles.PartnerFilter}
                onSubmit={handleSubmit(onSubmitHandle)}
            >
                <Row className={styles.row}>
                    <InputContainer label="Código">
                        <TextField
                            {...register("code")}
                        />
                    </InputContainer>
                    <InputContainer label="CNPJ/CPF">
                        <TextField
                            {...register("document")}
                        />
                    </InputContainer>
                    <InputContainer  label="Nome">
                        <TextField
                            {...register("name")}
                        />
                    </InputContainer>
                    <InputContainer  label="Tipo">
                        <TextField
                            {...register("partnerType")}
                            select
                            value={allFields.partnerType ?? ''}
                        >
                            <MenuItem value="">Selecione</MenuItem>
                            <MenuItem value={PartnerType.Supplier}>Fornecedor</MenuItem>
                            <MenuItem value={PartnerType.Customer}>Cliente</MenuItem>
                        </TextField>
                    </InputContainer>
                    <InputContainer  >
                        <FilterButtonContainer minWidth={100} >
                            {isAnyFieldFilled &&
                                <Button
                                    onClick={onClickResetFilter}
                                    variant="text"
                                    color="primary"
                                    startIcon={<Icon>close</Icon>}>
                                    Limpar filtro
                                </Button>}
                            <Button color='secondary' type="submit" style={{width: '76px', minWidth: '76px'}}>Buscar</Button>
                        </FilterButtonContainer>
                    </InputContainer>
                </Row>
            </form >
        </Container>
    )
}
export default PartnerFilter