import { IGetAllDemandedServicesByPlaceIdResponse } from "modules/schedule/domain/dto/placeSchedule/IGetAllDemandedServicesByPlaceIdResponse";
import { IPlaceItemScheduleForm } from "modules/schedule/presentation/components/placeItemScheduleForm/interface/IPlaceItemScheduleForm";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const GetAllDemandedServicesByPlaceId = async (initDate: string, finalDate: string, placeId: string, placeScheduleApi: PlaceScheduleApiFunction): Promise<IGetAllDemandedServicesByPlaceIdResponse[]> => {
    const _placeScheduleApi = placeScheduleApi();
    const response = await _placeScheduleApi.getAllDemandedServices(initDate, finalDate, placeId); 
    return response;
}

