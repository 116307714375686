import Layout from "components/layout/presentation/Layout";
import { PlaceScheduleListPage } from "modules/schedule/presentation/pages/placeScheduleList/PlaceScheduleListPage";
import React, { FC } from "react";

const PlaceSchedule: FC = () => {
  return (
    <Layout>
      <PlaceScheduleListPage />
    </Layout>
  );
};
export default PlaceSchedule;
