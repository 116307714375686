import qs from 'qs';
import React, { FC, useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
const UseQueryState = <T extends { [keys: string]: any },>() => {

    const { search, pathname } = useLocation();
    const { push } = useHistory();
    
    const parsedSeach = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);


    const state: T = useMemo(() => {
        const filter = parsedSeach;
        return filter as T;
    }, [parsedSeach])

    const updateState = useCallback(<T,>(_state: T & { [key: string]: any }) => {
        const newFilter = { ...state, ..._state };
        for (const key in newFilter) {
            if ((newFilter[key] === undefined) || newFilter[key] === "" || newFilter[key] === null) {
                delete newFilter[key];
            }
        }

        const querystring = qs.stringify(newFilter, { addQueryPrefix: false, arrayFormat: 'repeat' })
        console.log(querystring)
        push({
            pathname,
            search: querystring
        })
    }, [state, pathname, push])

    return ({ updateState, state })
}

export default UseQueryState