import { Autocomplete, MenuItem, Select, Switch, TextField } from "@mui/material";
import styles from "./ForProductFilter.module.scss";
import { CloseOutlined, KeyboardArrowRightOutlined, SortOutlined, VerticalAlignBottomOutlined } from "@material-ui/icons";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import UseCashlessDashboardPage from "modules/cashlessDashboard/presentation/UseCashlessDashboardPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { endOfDay, format, startOfDay } from "date-fns";
import Button from "components/ui/Button/Button";
import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IPostSaleForProductParams } from "modules/saleForProduct/domain/dto/IPostSaleForProductParams";
import { UseSaleForDevicePage } from "modules/saleForDevice/presentation/hooks/UseSaleForDevicePage";
import { UseSaleForProductPage } from "../../hooks/UseSaleForProductPage";
import UseProductss from "modules/config/deviceConfig/presentation/hooks/deviceProfile/UseProductss";
import AdvancedProductFilter from "./advancedProductFilter/AdvancedProductFilter";
import Utils from 'services/utils/Utils';
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

export interface IForProductFilterProps {
    setParams: React.Dispatch<React.SetStateAction<IPostSaleForProductParams>>;
    handleOpenSidesheet: (startDate: Date, endDate: Date) => void;
    setOpenSidesheetDownload: React.Dispatch<React.SetStateAction<string | null>>;
}

const ForProductFilter = ({ setParams, handleOpenSidesheet, setOpenSidesheetDownload }: IForProductFilterProps) => {
    const { toast } = useUi();
    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();
    const { cashiers } = UseCashlessDashboardPage();
    const { vendingDevices, profileList, getAdvancedFiltersOptions: getAdvancedDeviceFiltersOptions, isLoading, devicesTypes } = UseSaleForDevicePage();
    const { operators, categoriesList, cashlessTags, products, getAdvancedFiltersOptions, locaisSplit } = UseSaleForProductPage();
    const { productList, getProductList } = UseProductss();

    const [filterBy, setFilterBy] = useState("");
    const [advancedFilters, setAdvancedFilters] = useState(false);
    const [filters, setFilters] = useState({ localId: currentLocal?.id, exibirAdicionais: false } as IPostSaleForProductParams);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        getAdvancedFiltersOptions();
        getAdvancedDeviceFiltersOptions();
        getProductList();
    }, [getAdvancedFiltersOptions, getAdvancedDeviceFiltersOptions, getProductList]);

    const handleChangeFilterBy = useCallback((filter: string) => {
        if(filter === 'period'){
        setFilters({...filters, dataInicio: startOfDay(new Date()).toISOString(), dataFim: endOfDay(new Date()).toISOString(), sessaoId: "" })}
        else {
            setFilters({...filters, dataInicio: "", dataFim: "", sessaoId: "" });
        }
        return setFilterBy(filter);
    }, [filters]);

    const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const selectedDateTime = new Date(ev.target.value);
        const dateTimeInUTC = selectedDateTime.toISOString();
        setFilters((prev) => ({ ...prev, [ev.target.name]: dateTimeInUTC }));
    }, []);

    const onChangeStatusType = useCallback((item: { id: number, name: string }) => {
        let arr: string[] = [];

        const removeAllSelected = filters.statusPedido?.filter((x) => Number(x) !== 0) ?? [];

        const selectedItem = filters.statusPedido?.find((x) => Number(x) === item.id);

        if (selectedItem) {
            arr = removeAllSelected?.filter((x) => Number(x) !== item.id) ?? [];
        } else {
            arr = [...(removeAllSelected ?? []), item.id.toString()];
        }

        setFilters({ ...filters, statusPedido: arr });
    }, [filters]);

    const onChangeDevicesTypes = useCallback((item: { id: number, name: string }) => {
        let arr: number[] = [];
        const removeAllSelected = filters.tiposDispositivos?.filter((x) => Number(x) !== 0) ?? [];
        const selectedItem = filters.tiposDispositivos?.find((x) => Number(x) === item.id);
    
        if (selectedItem) {
            arr = removeAllSelected?.filter((x) => Number(x) !== item.id) ?? [];
            if (item.id === 3) {
                arr = arr.filter((x) => x !== 4); // Remove POS+ se POS for removida
            }            
        } else {
            arr = [...(removeAllSelected ?? []), item.id];
    
            if (item.id === 3) {
                arr.push(4); // Adiciona POS+ se POS for selecionada
            }
        }
        setFilters({ ...filters, tiposDispositivos: arr });
    }, [filters]);

    const onChangeDateType = useCallback((item: { id: number, name: string }) => {
        setFilters({ ...filters, dateType: item.id });
    }, [filters]);

    const onChangeSystemType = useCallback((item: { id: number, name: string }) => {
        const selectedItem = filters.tipoSistema;

        if (selectedItem === item.id.toString()) {
            return setFilters({ ...filters, tipoSistema: "" });
        }

        setFilters({ ...filters, tipoSistema: item.id.toString() });
    }, [filters]);

    const onChangeSaleType = useCallback((item: { id: number, name: string }) => {
        const selectedItem = filters.tipoVenda;

        if (selectedItem === item.id.toString()) {
            return setFilters({ ...filters, tipoVenda: "" });
        }

        setFilters({ ...filters, tipoVenda: item.id.toString() });
    }, [filters]);

    const handleSubmit = useCallback(() => {
        setIsSearching(true);
        setParams({...filters, shouldUseNewSchema: currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas });
    }, [currentLocal?.systemIdentifier, filters, setParams]);

    const handleClickOpenSidesheet = useCallback(() => {
        if (filters.dataInicio && filters.dataFim) {
            handleOpenSidesheet(new Date(filters.dataInicio ?? ""), new Date(filters.dataFim ?? ""));
            return;
        }
        toast("Selecione um período.", "error");
    }, [toast, filters.dataInicio, filters.dataFim, handleOpenSidesheet]);

    return (
        <div id={styles.ForProductFilter}>
            <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
                <div className={styles.input}>
                    <span className={styles.required}>Filtrar por</span>
                    <Select
                        value={filterBy}
                        size="small"
                        onChange={(ev) => handleChangeFilterBy(ev.target.value)}
                    >
                        <MenuItem value="period">Período</MenuItem>
                        <MenuItem value="cashier">Caixa</MenuItem>
                    </Select>
                </div>
                {filterBy === "period" && (
                    <>
                        <div className={styles.input}>
                            <span className={styles.required}>Inicio</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TextField
                                    size='small'
                                    name={'dataInicio'}
                                    onChange={changeHandleDate}
                                    fullWidth
                                    value={filters.dataInicio && Utils.toInputDateString(filters.dataInicio)}
                                    type="datetime-local" />
                            </LocalizationProvider>

                        </div>
                        <div className={styles.input}>
                            <span className={styles.required}>Fim</span>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <TextField
                                    size='small'
                                    name={'dataFim'}
                                    onChange={changeHandleDate}
                                    fullWidth
                                    value={filters.dataFim && Utils.toInputDateString(filters.dataFim)}
                                    type="datetime-local" />
                            </LocalizationProvider>
                        </div>
                    </>
                )}
                {filterBy === "cashier" && (
                    <div className={styles.input} style={{ width: "100%", maxWidth: dimensions.width > 650 ? "350px" : "100%" }}>
                        <span className={styles.required}>Caixa</span>
                        <Select
                            style={{ flex: 2, minWidth: 350 }}
                            size="small"
                            onChange={(ev) => setFilters({ ...filters, sessaoId: ev.target.value })}
                            value={filters.sessaoId}
                        >
                            {cashiers.map((item) => (
                                <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
                {currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && (
                    <div className={styles.input}>
                        <span>Equipamento</span>
                        <Autocomplete
                            options={vendingDevices}
                            getOptionLabel={option => option.nome}
                            noOptionsText="Nenhuma opção encontrada"
                            multiple
                            limitTags={1}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            onChange={(_ev, values) => {
                                const posIds = values.map((it) => it.id);
                                setFilters({ ...filters, usuarioMeepPOSIds: posIds });
                            }}
                        />
                    </div>
                )}
                {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                    <>
                        <div className={`${styles.displayRow} ${styles.inputSwitch}`}>
                            <Switch
                                value={filters.somenteContasPagas}
                                checked={filters.somenteContasPagas}
                                onChange={(ev) => setFilters({ ...filters, somenteContasPagas: ev.target.checked })}
                            />
                            <span>Considerar somente contas pagas</span>
                        </div>
                        <div className={`${styles.displayRow} ${styles.inputSwitch}`}>
                            <Switch
                                value={filters.exibirAdicionais}
                                checked={filters.exibirAdicionais}
                                onChange={(ev) => setFilters({ ...filters, exibirAdicionais: ev.target.checked })}
                            />
                            <span>Exibir adicionais</span>
                        </div>
                    </>
                )}
                {!advancedFilters && <Button color="secondary" onClick={handleSubmit} disabled={isLoading}>Buscar</Button>}
            </div>
            <div className={`${styles.displayRow} ${styles.filterEndButton}`} style={{ justifyContent: "space-between" }}>
                {filters.dataInicio && filters.dataFim && isSearching ? (
                    <div className={styles.btnRow} onClick={handleClickOpenSidesheet}>
                        <span>Caixas do período filtrado</span>
                        <KeyboardArrowRightOutlined />
                    </div>
                ) : (
                    <div style={{ width: "10px" }} />
                )}
                <div className={`${styles.displayRow} ${styles.endButtons}`} style={{ justifyContent: "space-between" }}>
                    <div className={styles.btnRow} onClick={() => setOpenSidesheetDownload('true')}>
                        <span>Exportar</span>
                        <VerticalAlignBottomOutlined />
                    </div>
                    <div className={styles.btnRow} onClick={() => setAdvancedFilters(!advancedFilters)}>
                        <span>Filtros avançados</span>
                        {advancedFilters ? <CloseOutlined style={{ color: "rgba(95, 95, 95, 1)", width: "16px" }} /> : <SortOutlined style={{ color: "rgba(95, 95, 95, 1)" }} />}
                    </div>
                </div>
            </div>
            {advancedFilters && (
                <AdvancedProductFilter
                    filters={filters}
                    setFilters={setFilters}
                    onChangeStatusType={onChangeStatusType}
                    onChangeDevicesTypes={onChangeDevicesTypes}
                    onChangeDateType={onChangeDateType}
                    onChangeSystemType={onChangeSystemType}
                    onChangeSaleType={onChangeSaleType}
                    vendingDevices={vendingDevices}
                    profileList={profileList}
                    operators={operators}
                    categoriesList={categoriesList}
                    cashlessTags={cashlessTags}
                    products={products}
                    productList={productList}
                    handleSubmit={handleSubmit}
                    locais={locaisSplit}
                    devicesTypes={devicesTypes} 
                />
            )}
            {filters.dataInicio && filters.dataFim && isSearching && (
                <div className={styles.btnRow} style={{ cursor: "default" }}>
                    <span><b>Período:</b> {filters.dataInicio ? format(new Date(filters.dataInicio), "dd/MM/yyyy' - 'HH:mm") : "-"} <b>até</b> {filters.dataFim ? format(new Date(filters.dataFim), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
                </div>
            )}
        </div>
    )
}

export default ForProductFilter;