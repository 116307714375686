import { Button, CircularProgress, MenuItem } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import styles from './Filter.module.scss'
import { addDays, addMinutes, differenceInDays, format, isValid, parse, subDays } from 'date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ptBR } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/lab';
import { IFilterValue, IlocalFilter, ISession, PeriodEnum } from './IFilter';
import SetSelectedLocalsUseCase from 'modules/dashboard/application/useCases/SetSelectedLocalsUseCase';
import { DashboardSelectedLocals } from 'services/repository/dashboard/DashboardSelectedLocals';
import { IGetSessionsItemResponse, IGetSessionsListResponse } from 'modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

const colors = [
    '#FF1744',
    '#FF6B00',
    '#399C54',
    '#955CFF',
    '#11AAAC',
    '#ff9900',
    '#0f88fa',
]

const MAX_PERIOD_RANGE = 30;

export interface IFilterProps {
    onSubmit: (value: IFilterValue) => void;
    getSessions: (local: IlocalFilter) => Promise<IGetSessionsListResponse>;
    defaultValues?: IFilterValue;
    locals: IlocalFilter[];
    isLoading?: boolean;
}
const Filter: FC<IFilterProps> = ({ onSubmit, getSessions, defaultValues, locals, isLoading }) => {
    const [sessions, setSessions] = useState<IGetSessionsItemResponse[]>([]);

    const { toast } = useUi();

    const [values, setValues] = useState<IFilterValue>({
        locals: [],
        sessions: [],
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        period: PeriodEnum.LAST_SESSION
    });

    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true)

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues)
        }
    }, [defaultValues]);

    useEffect(() => {
        (async () => {
            if (values.locals.length === 1) {
                const result = await getSessions(values.locals[0]);
                setSessions(result.items);
            }
        })();
    }, [getSessions, values.locals]);

    const formatDate = (date?: Date | null) => {
        if (!date || !isValid(date)) return undefined;
        return format(new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000), "yyyy-MM-dd");
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (values.period === PeriodEnum.INTERVAL) {
            if (!values.startDate || !values.endDate) {
                toast('Informe a data início e data fim.', "error");
                return;
            }
            if (values.startDate && !isValid(parse(values.startDate, 'yyyy-MM-dd', new Date()))) {
                toast('Data início inválida.', "error");
                return;
            }
            if (values.endDate && !isValid(parse(values.endDate, 'yyyy-MM-dd', new Date()))) {
                toast('Data fim inválida.', "error");
                return;
            }
        } else if (values.period === PeriodEnum.SESSION) {
            if (!values.sessions?.length) {
                toast('Selecione pelo menos um caixa.', "error");
                return;
            }
        }

        onSubmit({ ...values, locals: values.locals.map((item, index) => ({ ...item, color: colors[index] })) })
    }, [onSubmit, toast, values]);

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeAutoCompleteLocalHandle = useCallback((locals: IlocalFilter[]) => {
        if (locals.length <= 7) {
            setValues((prev) => ({ ...prev, locals }));
        }

        if (locals.length > 1) {
            setValues(prev => ({ ...prev, period: PeriodEnum.LAST_SESSION, sessions: [] }))
        }

        SetSelectedLocalsUseCase(DashboardSelectedLocals, locals.map(x => x.id))
    }, []);

    const changeAutoCompleteSessionHandle = useCallback((values?: ISession[]) => {
        setValues((prev) => ({ ...prev, sessions: values }));
    }, []);

    const getSelectedEstablishmentCountLabel = (count: number) => {
        return `${count} Estabelecimento${count > 1 ? 's' : ''} selecionado${count > 1 ? 's' : ''}`;
    }

    const getSelectedSessionsCountLabel = (count: number) => {
        return `${count} Caixa${count > 1 ? 's' : ''} selecionado${count > 1 ? 's' : ''}`;
    }

    const handleChangeStartDate = (startDate: Date | null) => {
        let endDate = values.endDate ? parse(values.endDate, 'yyyy-MM-dd', new Date()) : new Date();

        if (endDate && startDate && isValid(endDate)) {
            const diff = differenceInDays(endDate, startDate);
            if (diff > MAX_PERIOD_RANGE) {
                endDate = addDays(startDate, MAX_PERIOD_RANGE);
            } else if (diff < 0) {
                endDate = startDate;
            }
        }

        setValues(prev => ({ ...prev, startDate: formatDate(startDate), endDate: formatDate(endDate) }));
    }

    const handleChangeEndDate = useCallback((endDate: Date | null) => {
        let startDate = values.startDate ? parse(values.startDate, 'yyyy-MM-dd', new Date()) : new Date();

        if (startDate && endDate && isValid(startDate)) {
            const diff = differenceInDays(endDate, startDate);
            if (diff > MAX_PERIOD_RANGE) {
                startDate = subDays(endDate, MAX_PERIOD_RANGE);
            } else if (diff < 0) {
                startDate = endDate;
            }
        }

        setValues(prev => ({ ...prev, startDate: formatDate(startDate), endDate: formatDate(endDate) }));
    }, [values])

    const mountDate = (date?: string) => {
        if (!date) return new Date();

        return addMinutes(new Date(date), new Date(date).getTimezoneOffset());
    }

    return (
        <div id={styles.Filter} >
            <form onSubmit={submitHandle}>
                <div className={styles.contentFilter}>
                    <Autocomplete
                        className={styles.input}
                        multiple={true}
                        style={{ minWidth: 300 }}
                        size='small'
                        renderTags={() => (
                            showPlaceholder ? getSelectedEstablishmentCountLabel(values.locals.length) : ''
                        )}
                        renderInput={(props) =>
                            <div className={styles.inputContainer}>
                                <span>Escolha o estabelecimento</span>
                                <TextField
                                    maxRows={1}
                                    name={"locals"}
                                    onBlur={() => setShowPlaceholder(true)}
                                    onChange={() => setShowPlaceholder(false)}
                                    {...props}
                                />
                            </div>
                        }
                        renderOption={(props, option) => {
                            return (
                                <span {...props} key={option.id}>
                                    {option.name}
                                </span>
                            );
                        }}
                        isOptionEqualToValue={(option, values) => option.id === values.id}
                        options={locals}
                        getOptionLabel={(option) => option.name}
                        onChange={(ev, value) => {
                            setShowPlaceholder(true);
                            changeAutoCompleteLocalHandle(value)
                        }}
                        value={values.locals}
                    />
                    {/* <div className={styles.formContainer}> */}
                    <div className={styles.inputContainer}>
                        <span>Escolha o período</span>
                        <TextField
                            size={'medium'}
                            className={styles.period}
                            style={{ minWidth: 300 }}
                            select
                            variant='outlined'
                            type={'date'}
                            name={'period'}
                            value={values.period}
                            onChange={changeHandle}
                        >
                            <MenuItem value={PeriodEnum.LAST_SESSION}>Último caixa</MenuItem>
                            {
                                values.locals.length === 1 &&
                                <MenuItem value={PeriodEnum.SESSION}>Selecionar caixa</MenuItem>
                            }
                            <MenuItem value={PeriodEnum.INTERVAL}>Período de caixa</MenuItem>
                        </TextField>
                    </div>
                    {values.period === PeriodEnum.SESSION &&
                        <Autocomplete
                            className={styles.input}
                            multiple
                            style={{ minWidth: 300 }}
                            size='small'
                            renderTags={() => (
                                getSelectedSessionsCountLabel(values.sessions?.length ?? 0)
                            )}
                            renderInput={(props) =>
                                <div className={styles.inputContainer}>
                                    <span>Escolha o caixa</span>
                                    <TextField
                                        maxRows={1}
                                        hiddenLabel={true}
                                        placeholder={(values.sessions?.length ?? 0) > 0 ? '' : 'Selecione um ou mais caixas'}
                                        name={"session"}
                                        {...props}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            }
                            renderOption={(props, option) => {
                                return (
                                    <span {...props} key={option.id}>
                                        {option.name}
                                    </span>
                                );
                            }}
                            isOptionEqualToValue={(option, values) => option.id === values.id}
                            options={sessions}
                            getOptionLabel={(option) => option.name}
                            onChange={(ev, value) => changeAutoCompleteSessionHandle(value)}
                            value={values.sessions}

                        />
                    }
                    {values.period === PeriodEnum.INTERVAL &&
                        <div className={styles.containerDate}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    value={mountDate(values.startDate)}
                                    disableFuture
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        handleChangeStartDate(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <div className={`${styles.inputContainer} ${styles.inputDateContainer}`}>
                                            <span>De</span>
                                            <TextField
                                                {...params}
                                                size="small"
                                                className={`${styles.datePicker} dashboardFilterDatePicker`}
                                                type="date"
                                                fullWidth
                                            />
                                        </div>
                                    )}
                                />
                                <DesktopDatePicker
                                    value={mountDate(values.endDate)}
                                    disableFuture
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        handleChangeEndDate(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <div className={`${styles.inputContainer} ${styles.inputDateContainer}`}>
                                            <span>Para</span>
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                                className={`${styles.datePicker} dashboardFilterDatePicker`}
                                                type="date"
                                            />
                                        </div>
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    }
                <div className={styles.buttonFooter}>
                    <Button
                        className={styles.button}
                        variant={'contained'}
                        color={'primary'}
                        disabled={isLoading || !values.locals.length}
                        type={'submit'}
                        fullWidth
                    >
                        Buscar
                        {isLoading && <CircularProgress />}
                    </Button>
                </div>
                </div>
            </form>
        </div>
    )
}
export default Filter