import { Icon } from '@material-ui/core';
import styles from './PublicGuestListSuccess.module.scss';

interface PublicGuestListSuccessProps {
    onClose: () => void;
    usersToMigrate?: string[];
}

export const PublicGuestListSuccess = ({ onClose, usersToMigrate }: PublicGuestListSuccessProps) => {
    return (
        <div className={styles.container}>
            <header>
                <button onClick={onClose} className={styles.closeButton}>
                    <Icon>close</Icon>
                </button>
            </header>

            <div className={styles.content}>
                <Icon>check_circle_outline</Icon>
                <span className={styles.text}><strong>Pronto!</strong><br />Os CPFs foram adicionados<br />na lista com sucesso.</span>

                {
                    !!usersToMigrate?.length &&
                    <div className={styles.migrate}>
                        <span>Atenção: alguns CPFs <b>não puderam ser inseridos</b> porque já estão em outra lista de convidados:</span>
                        <ul>
                            {
                                usersToMigrate.map(x => (
                                    <li>{x}</li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}