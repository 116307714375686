import { MenuItem, TextField } from "@mui/material";
import { GraphLoading } from "components/ui/graphLoading/GraphLoading";
import { FC, useEffect, useState } from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IFilterValue } from "../../filter/IFilter";
import { ICardsActivation } from "../interfaces/ICardsActivation";
import styles from "./BarCardsActivation.module.scss";

interface IChartData {
  time: string;
  activated: number;
  deactivated: number;
}

interface BarCardsActivationProps {
  loading: boolean;
  data?: ICardsActivation;
  filter?: IFilterValue;
  onGeneratingPDF?: boolean;
}

const MAX_HOURS_TO_SHOW = 24;

const BarCardsActivation: FC<BarCardsActivationProps> = ({
  data,
  loading,
  filter,
  onGeneratingPDF,
}) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [cashierId, setCashierId] = useState<string | undefined>();

  useEffect(() => {
    if ((filter?.cashierIds.length ?? 0) == 1) {
      setCashierId(filter?.cashierIds[0].id);
    }

    const activatedCashier = cashierId
      ? data?.activated.cashiers.find((x) => x.id === cashierId)
      : data?.activated.cashiers[0];
    const deactivatedCashier = cashierId
      ? data?.deactivated.cashiers.find((x) => x.id === cashierId)
      : data?.deactivated.cashiers[0];

    if (!cashierId) setCashierId(activatedCashier?.id || filter?.cashierIds[0].id);

    const newValues =
      activatedCashier?.perHour
        .slice(
          activatedCashier.perHour.length - MAX_HOURS_TO_SHOW,
          activatedCashier.perHour.length
        )
        .map((item, index) => ({
          time: item.date
            ? new Date(`${item.date}Z`)
                .toLocaleTimeString([], {
                  timeStyle: "short",
                  hour12: false,
                })
                .split(":")[0] + "h"
            : "",
          activated: item.total,
          deactivated: deactivatedCashier?.perHour[index].total || 0,
        })) || [];

    setChartData(newValues);
  }, [cashierId, data?.activated.cashiers, data?.deactivated.cashiers]);

  if (loading) {
    return (
      <div id={styles.BarChart}>
        <div className={styles.loading}>
          <GraphLoading />
        </div>
      </div>
    );
  }

  return (
    <div id={styles.BarChart}>
      <div className={styles.flexLine}>
        <div className={styles.cashiers}>
          {filter?.cashierIds && filter?.cashierIds.length > 1 && (
            <TextField
              size="small"
              className={styles.period}
              style={{ minWidth: 300 }}
              select
              variant="outlined"
              type={"date"}
              name={"period"}
              value={cashierId}
              onChange={(e) => setCashierId(e.target.value)}
              disabled={filter?.cashierIds.length === 1}
            >
              {filter?.cashierIds.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        <div className={styles.legendBox}>
          <div className={styles.legendRow}>
            <div className={styles.indicatorDeactive}></div>
            <div className={styles.legendText}>Desativados</div>
          </div>
          <div className={styles.legendRow}>
            <div className={styles.indicatorActive}></div>
            <div className={styles.legendText}>Ativados</div>
          </div>
        </div>
      </div>
      {data && (
        <ResponsiveContainer width={"100%"} height={250}>
          <LineChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="time"
              tickLine={false}
              label={{
                value: "Horário",
                position: "insideBottomRight",
                dy: 10,
                fill: "var(--text)",
              }}
            />
            <YAxis
              tickLine={false}
              label={{
                value: "Ticket médio",
                position: "top",
                dy: -15,
                fill: "var(--text)",
              }}
            />
            {!onGeneratingPDF && <Tooltip />}

            <Line
              dataKey="activated"
              name="Ativados"
              stroke="#4D13B7"
              fill="#4D13B7"
            />
            <Line
              dataKey="deactivated"
              name="Desativados"
              stroke="#BDBDBD"
              fill="#BDBDBD"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
export default BarCardsActivation;
