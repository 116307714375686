import styles from "./Monitoramento.module.scss";
import SearchMonitoramento from "../components/search/SearchMonitoramento";
import CardItem from "../components/card/CardItem";
import ActionBulk from "../components/actionBulk/actionBulk";
import AccordionMain from "../components/accordionMain/AccordionMain";
import { MenuItem, Pagination, TextField } from "@mui/material";
import { colorIcon } from "./utils";
import TableHeader from "../components/tableHeader/TableHeader";
import UseDimension from "components/dimension/UseDimension";
import AccordionItem from "../components/accordionItem/AccordionItem";
import { useEffect, useState } from "react";
import AccordionItemMobile from "../components/accordionItemMobile/AccordionItemMobile";
import SideshetMonitoramento from "../components/sideshetMonitoramento/SideshetMonitoramento";
import PopoverItem from "../components/popover/PopoverItem";

const mock = [
  {
    evento: "Camarote Chalé",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: true,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "baixa",
        bobina: true,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "wifi",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: true,
        totem: "Wi-Fi",
      },
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "alta",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "dados",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Wi-Fi",
      },
    ],
  },
  {
    evento: "Pista Chalé",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "media",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "dados",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Cabo",
      },
    ],
  },
  {
    evento: "Área Externa",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "media",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "sem_conexao",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Wi-Fi",
      },
    ],
  },
  {
    evento: "Pista premium",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "media",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "dados",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Wi-Fi",
      },
    ],
  },
  {
    evento: "Área select",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "media",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "dados",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Cabo",
      },
    ],
  },
  {
    evento: "Pista exclusiva",
    qtd_equipamentos: 10,
    pouca_bateria: 3,
    tempo_resposta: 10,
    wifi: 3,
    dados: 3,
    sem_conexao: 3,
    fora_caixa: 10,
    conectado_caixa: 10,
    equipamentos: [
      {
        nome: "PAG0454",
        bloqueado: false,
        modelo: "SUNMI P2",
        tipo: "POS+",
        bateria: "media",
        bobina: false,
        operador: "Leo Dornas",
        sincronizado: "00/00/00 00:00",
        status: "1 min atrás",
        versao: "2.0.1.1",
        rede: "dados",
        tempo_resposta: "10s",
        ip: "192.168.18.250",
        nome_rede: "netvitua.2.5g",
        transacao: "00/00/00 - 00:00",
        pedido: "00/00/00 - 00:00",
        atualizacao: "00/00/00 - 00:00",
        velocidade: "100mb/down - 10mb/up",
        caixa: false,
        totem: "Wi-Fi",
      },
    ],
  },
];

const MonitoramentoPage = () => {
  const [checkedMain, setCheckedMain] = useState(
    Array(mock.length).fill(false)
  );
  const [checkedItem, setCheckedItem] = useState(
    mock.map((item) => Array(item.equipamentos.length).fill(false))
  );
  const [bulkActions, setBulkActions] = useState(false);
  const [qtdChecked, setQtdChecked] = useState(0);
  const [openSideshet, setOpenSideshet] = useState<string | null>(null);
  const [isMenuPopover, setIsMenuPopover] = useState<HTMLButtonElement | null>(
    null
  );

  const openPopover = Boolean(isMenuPopover);

  const { dimensions } = UseDimension();

  const handleAccordionMainClick = (index: number) => {
    const updatedCheckedMain = [...checkedMain];
    const updatedCheckedItem = [...checkedItem];
    updatedCheckedMain[index] = !updatedCheckedMain[index];
    updatedCheckedItem[index] = Array(updatedCheckedItem[index].length).fill(
      updatedCheckedMain[index]
    );

    setCheckedMain(updatedCheckedMain);
    setCheckedItem(updatedCheckedItem);
  };

  const handleAccordionItemClick = (index: number, indexEquip: number) => {
    const updatedCheckedItem = [...checkedItem];
    const updatedCheckedMain = [...checkedMain];
    updatedCheckedItem[index][indexEquip] =
      !updatedCheckedItem[index][indexEquip];

    const lenghtItem = updatedCheckedItem[index].filter((item) => item);

    if (lenghtItem.length === updatedCheckedItem[index].length) {
      updatedCheckedMain[index] = true;
      setCheckedMain(updatedCheckedMain);
    } else {
      updatedCheckedMain[index] = false;
      setCheckedMain(updatedCheckedMain);
    }

    setCheckedItem(updatedCheckedItem);
  };

  useEffect(() => {
    setQtdChecked(checkedItem.flat().filter((value) => value === true).length);
  }, [checkedItem]);

  return (
    <div id={styles.Monitoramento}>
      <p className={styles.title}>
        <span>10 equipamentos</span> vinculados
      </p>
      <SearchMonitoramento />
      <p className={styles.titleCards}>
        Visão <span>geral</span>
      </p>
      <p className={styles.subTitleCards}>
        Tempo de resposta do servidor: <span>700ms</span>
      </p>
      <div className={styles.containerCards}>
        <CardItem
          colorIcon={colorIcon("red")}
          icon="battery_2_bar"
          title="Pouca bateria"
          description="3 equipamentos"
        />
        <CardItem
          colorIcon={colorIcon("red")}
          icon="block"
          title="Bloqueados"
          description="30 equipamentos"
        />
        <CardItem
          colorIcon={colorIcon("red")}
          icon="lock"
          title="Fora do Caixa"
          description="10 equipamentos"
        />
        <CardItem
          colorIcon={colorIcon("green")}
          icon="lock_open"
          title="Conectados ao Caixa"
          description="10 equipamentos"
        />
        <CardItem
          colorIcon={colorIcon()}
          icon="public_off"
          title="Sem Conexão"
          description="30 desconectados"
        />
        <CardItem
          colorIcon={colorIcon()}
          icon="access_time"
          title="Tempo de resposta"
          description="10 equipamentos lentos"
        />
        <CardItem
          colorIcon={colorIcon()}
          icon="wifi"
          title="Wi-Fi"
          description="30 conectados"
        />
        <CardItem
          colorIcon={colorIcon()}
          icon="signal_cellular_alt"
          title="Rede Móvel"
          description="30 conectados"
        />
      </div>
      {qtdChecked > 0 && (
        <ActionBulk
          qtdChecked={qtdChecked}
          setOpenSideshet={setOpenSideshet}
          setBulkActions={setBulkActions}
        />
      )}
      {mock.map((item, index) => {
        const selecionados = checkedItem[index].filter((item: boolean) => item);
        return (
          <AccordionMain
            title={item.evento}
            conectadoCaixa={item.conectado_caixa}
            equipamentos={item.qtd_equipamentos}
            poucaBateria={item.pouca_bateria}
            tempoResposta={item.tempo_resposta}
            wifi={item.wifi}
            dados={item.dados}
            semConexao={item.sem_conexao}
            foraCaixa={item.fora_caixa}
            isChecked={checkedMain[index]}
            onAccordionMainClick={() => handleAccordionMainClick(index)}
            selecionados={selecionados.length}
          >
            {dimensions.width > 1200 && <TableHeader />}
            {item.equipamentos.map((itemEquip, indexEquip) => {
              return (
                <>
                  {dimensions.width > 1200 ? (
                    <AccordionItem
                      index={indexEquip}
                      nome={itemEquip.nome}
                      bloqueado={itemEquip.bloqueado}
                      modelo={itemEquip.modelo}
                      tipo={itemEquip.tipo}
                      bateria={itemEquip.bateria}
                      bobina={itemEquip.bobina}
                      operador={itemEquip.operador}
                      sincronizado={itemEquip.sincronizado}
                      status={itemEquip.status}
                      versao={itemEquip.versao}
                      rede={itemEquip.rede}
                      tempo_resposta={itemEquip.tempo_resposta}
                      ip={itemEquip.ip}
                      nome_rede={itemEquip.nome_rede}
                      transacao={itemEquip.transacao}
                      pedido={itemEquip.pedido}
                      atualizacao={itemEquip.atualizacao}
                      velocidade={itemEquip.velocidade}
                      caixa={itemEquip.caixa}
                      totem={itemEquip.totem}
                      isChecked={checkedItem[index][indexEquip]}
                      setIsMenuPopover={setIsMenuPopover}
                      onAccordionItemnClick={() =>
                        handleAccordionItemClick(index, indexEquip)
                      }
                    />
                  ) : (
                    <AccordionItemMobile
                      index={indexEquip}
                      nome={itemEquip.nome}
                      bloqueado={itemEquip.bloqueado}
                      modelo={itemEquip.modelo}
                      tipo={itemEquip.tipo}
                      bateria={itemEquip.bateria}
                      bobina={itemEquip.bobina}
                      operador={itemEquip.operador}
                      sincronizado={itemEquip.sincronizado}
                      status={itemEquip.status}
                      versao={itemEquip.versao}
                      rede={itemEquip.rede}
                      tempo_resposta={itemEquip.tempo_resposta}
                      ip={itemEquip.ip}
                      nome_rede={itemEquip.nome_rede}
                      transacao={itemEquip.transacao}
                      pedido={itemEquip.pedido}
                      atualizacao={itemEquip.atualizacao}
                      velocidade={itemEquip.velocidade}
                      caixa={itemEquip.caixa}
                      totem={itemEquip.totem}
                      isChecked={checkedItem[index][indexEquip]}
                      setIsMenuPopover={setIsMenuPopover}
                      onAccordionItemnClick={() =>
                        handleAccordionItemClick(index, indexEquip)
                      }
                    />
                  )}
                  <PopoverItem
                    isMenuPopover={isMenuPopover}
                    openPopover={openPopover}
                    setIsMenuPopover={setIsMenuPopover}
                    actionFechar={() => setOpenSideshet("fechar")}
                    actionAtualizacoes={() => setOpenSideshet("atualizações")}
                    actionWifi={() => setOpenSideshet("wifi")}
                    actionMensagem={() => setOpenSideshet("mensagem")}
                    actionBloquear={() => setOpenSideshet("bloquear")}
                  />
                </>
              );
            })}
          </AccordionMain>
        );
      })}
      <div className={styles.conteinerPagination}>
        <div className={styles.boxMultiplePage}>
          <span>Exibir</span>
          <TextField variant="outlined" size="small" select>
            <MenuItem className={styles.textPage} value={10}>
              10
            </MenuItem>
            <MenuItem className={styles.textPage} value={20}>
              20
            </MenuItem>
            <MenuItem className={styles.textPage} value={50}>
              50
            </MenuItem>
          </TextField>
        </div>
        <Pagination
          variant="outlined"
          className={styles.paginationNumber}
          shape="rounded"
          count={5}
          sx={{
            ".MuiPaginationItem-root": {
              borderColor: "#BDBDBD",
              color: "#001537",
            },
            ".Mui-selected": {
              fontWeight: "bold",
              borderColor: "#001537",
            },
          }}
        />
      </div>
      <SideshetMonitoramento
        openSideshet={openSideshet}
        setOpenSideshet={setOpenSideshet}
        setBulkActions={setBulkActions}
        bulkActions={bulkActions}
        qtdChecked={qtdChecked}
      />
    </div>
  );
};
export default MonitoramentoPage;
