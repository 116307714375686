import { ClickAwayListener, Icon, Popper } from '@material-ui/core';
import styles from './InvoiceRegister.module.scss';
import { useState } from 'react';

export const Popover = ({ title, description }: { title: string, description: string }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event?: React.MouseEvent<HTMLElement>, show?: boolean) => {
        if (show)
            setAnchorEl(event!.currentTarget);
        else
            setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div className={styles.popoverContainer}>
            {
                open &&
                <ClickAwayListener onClickAway={() => handleClick(undefined, false)}>
                    <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
                        <div className={styles.popover}>
                            <div className={styles.header}>
                                <span>{title}</span>
                                <button onClick={(ev) => handleClick(ev, false)}>
                                    <Icon>close</Icon>
                                </button>
                            </div>

                            <p>
                                {description}
                            </p>
                        </div>
                    </Popper>
                </ClickAwayListener>
            }
            <button aria-describedby={id} type="button" onClick={ev => handleClick(ev, true)}>
                <Icon>info_outlined</Icon>
            </button>
        </div>
    )
}