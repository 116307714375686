import React, { useCallback, useEffect, useRef, useState } from 'react'

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Lottie from "react-lottie";
import styles from './DonationPage.module.scss'
import { ArrowForwardIos, Facebook, Instagram, RemoveCircle, TaskAlt, Twitter } from '@mui/icons-material'
import { Carousel } from 'components/ui/carousel/Carousel';
import { v4 } from 'uuid';
import { ProgressBar } from '../components/progressBar/ProgressBar';
import donationCash from '../../../../res/assets/animations/donate-count.json'
import Sidesheet from 'components/sidesheet/Sidesheet';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { FormControlLabel, Switch } from '@mui/material';
import { IDonation } from '../interfaces/IDonation';
import { LocalRepository } from 'services/repository/local/LocalRepository';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { moneyMaskNumber } from 'services/utils/Money';



const loadingAnimation = {
    loop: true,
    autoplay: true,
    animationData: donationCash,
};

const data: IDonation = {

    isActive: true,
    title: "Rio Grande do Sul",
    localCurrentValue: 100000,
    share: {
        baackgroundImage: "",
        text: ""
    },
    donationStats: {
        totalSstablishments: 110,
        totalClients: 1100,
        totalHelped: 1000000
    },
    donationProgess: {
        goal: 100000,
        currentValue: 63000
    }

}

const carrosel = [
    {
        id: v4(),
        imageUrl: '/assets/icon/donate/arenamrv.png'
    },
]


export const DonationPage = () => {

    const [openDrownActive, setOpenDrownActive] = useState(false)
    const [values, setValues] = useState<IDonation>(data ?? {

        isActive: false,
        title: "",
        localCurrentValue: "",
        share: {
            baackgroundImage: "",
            text: ""
        },
        donationStats: {
            totalSstablishments: 0,
            totalClients: 0,
            totalHelped: 0
        },
        donationProgess: {
            goal: 0,
            currentValue: 0
        }

    })
    const { getCurrentLocal } = LocalRepository()
    const { isMobile } = useUi()
    const { currentLocal } = useLocal();
    const [nameLocal, setNameLocal] = useState('')
    const [image, setImage] = useState('')

    const divRef = useRef<HTMLDivElement>(null);

    const progress = Math.min((values.donationProgess.currentValue / values.donationProgess.goal) * 100, 100);

    const imageLocal = `${process.env.REACT_APP_URL_IMAGEM}clientes-profile/${currentLocal?.id}.png`;


    const convertHtmltoImage = useCallback(async () => {
        if (divRef.current === null) {
            return
        }
        try {
            const result = await toPng(divRef.current, { cacheBust: true, })
            setImage(result)
            return result
        } catch (error) {
            console.log(error);
        }

    }, [])

    useEffect(() => {
        const local = getCurrentLocal();
        local && setNameLocal(local?.name)

        convertHtmltoImage()
    }, [convertHtmltoImage, getCurrentLocal])


    const handleShare = useCallback(() => {

        saveAs(image, 'donate.png');


    }, [image])


    const onClose = useCallback(() => {

        setOpenDrownActive(false)
    }, [])

    const continueOrSave = useCallback(async () => {

        onClose();

        //Chamar api para habilita/desabilitar doação

    }, [onClose])


    const changeDonateActive = useCallback((checked: boolean) => {
        setValues((prev) => ({ ...prev, isActive: checked }));
    }, []);



    const handleContent = useCallback(() => {

        let message = `Durante a realização do pagamento seu cliente poderá escolher se deseja doar ou não.`;
        let message2 = `Em todos os pagamentos será perguntado se deseja doar.`;

        return (
            <div className={styles.contentSideSheetDonate}>
                <p>{message}</p>
                <p>{message2}</p>
                <div>
                    <FormItemContainer>
                        <FormControlLabel
                            checked={values.isActive}
                            onChange={(ev, checked) => changeDonateActive(checked)}
                            control={<Switch color='secondary' />} label="Ativar doações"
                        />
                    </FormItemContainer>
                    <div>

                        <img src="/assets/icon/donate/exemploDoacao.png" alt="Exemplo de doações" />
                    </div>
                </div>
            </div>
        )

    }, [changeDonateActive, values.isActive])




    return (
        <div id={styles.DonationPage}>
            <div className={styles.header}>
                <div className={styles.left}>
                    <div className={styles.top}>
                        <div>

                            <img src="/assets/icon/donate/giftdonate.png" alt="doação" />
                        </div>
                        <div>
                            <h1>Ajude o Rio <span>Grande do Sul</span></h1>
                            <p>Seus clientes agora podem escolher se irão realizar doações na hora do pagamento das compras em seu estabelecimento. <b>Sem nenhum custo para você!</b></p>
                            <div className={styles.containerActiveDonate}>

                                {
                                    values.isActive ?
                                        <div className={styles.active}>
                                            <TaskAlt />
                                            <span>Doações ativas</span>
                                        </div>
                                        :
                                        <div className={styles.inactive}>
                                            <RemoveCircle />
                                            <span>Doações inativas</span>
                                        </div>
                                }
                                <div className={styles.config} onClick={() => setOpenDrownActive(true)}>
                                    <span>Configurar</span>
                                    <ArrowForwardIos />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <h3>Veja como é <b>fácil ajudar</b></h3>
                        <p>Faça a diferença, permita que os clientes de seu estabelecimento realizem doações durante o pagamento da suas compras.</p>
                        <div>
                            <div>
                                <img src="/assets/icon/donate/money.png" alt="Dinheiro" />
                                <span> Seu cliente escolhe a forma de pagamento que deseja na hora do pagamento</span>
                            </div>
                            <div>
                                <img src="/assets/icon/donate/donations.png" alt="Doações" />
                                <span>O cliente pode arredondar o valor total da compra para realizar a doação ou se deseja doar um valor especifico</span>
                            </div>
                            <div>
                                <img src="/assets/icon/donate/donationlove.png" alt="Doar com coração" />
                                <span>Pronto, o valor doado irá direto para nossa conta de doação. Sem custos para seu estabelecimento</span>
                            </div>
                        </div>
                        <span>*Ex.: A compra deu R$10,75 a sugestão de doação será até arredondar o valor para R$11,00 doando assim R$0,25. Ou doando um valor de forma livre.</span>
                    </div>
                </div>
                <div className={styles.right}>

                    <div className={styles.containerCard} ref={divRef}>
                        <div className={styles.header}>
                            {

                                imageLocal ?
                                    <img src={imageLocal} alt="Logo estabelecimento"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "/assets/icon/image_placeholder.svg";
                                        }}
                                    />
                                    :
                                    <span>{nameLocal}</span>
                            }
                        </div>

                        <div className={styles.card}>
                            <p>Já arrecadou</p>
                            <p className={styles.value}>R$0,00</p>
                            <p>para ajudar as vítimas da tragédia do RS</p>
                        </div>
                        <div>
                            <img src="/assets/icon/donate/logomeeppurple.png" alt="Logo meep" />
                        </div>
                    </div>
                    {/* <div className={styles.socialMidia}>
                        <div>
                            <p>Compartilhar:</p>

                            <Facebook />
                            <Instagram />
                            <Twitter onClick={handleShare} />
                        </div>
                        <p>Marque a gente que iremos repostar <b>@app.meep</b></p>
                    </div> */}
                </div>

            </div>
            <div className={styles.main}>
                <div className={styles.numberDonations}>
                    <h3>Nossos números em doações</h3>
                    <p>Nossos números do últimos mês são impressionantes. Comece ajudar você também!</p>
                    <div className={styles.containerQuantityDonation}>
                        <div>
                            <p>+200</p>
                            <p>Estabelecimentos participantes</p>
                        </div>
                        <div>
                            <p>+ 10.000</p>
                            <p>Clientes que já doaram</p>
                        </div>
                        <div>
                            <p>+ de 10.000</p>
                            <p>Já ajudadas</p>
                        </div>
                    </div>
                </div>
                <div className={styles.realValueTotal}>
                    <Lottie
                        width={125}
                        height={207}
                        speed={1}
                        options={loadingAnimation}
                        isStopped={false}
                        isPaused={false}
                    />
                    <div>

                        <h3>Valor total arrecadado em tempo real</h3>
                        <p>Sempre que recebemos uma doação de todos os locais que participam dessa iniciativa o valor é atualizado em tempo real</p>
                        {
                            isMobile ?
                                <div className={styles.progressContainer}>
                                    <div className={styles.progressBarBackground}>

                                        <div className={styles.progressIndicator} style={{ left: `${progress}%` }}>
                                            <span>Estamos aqui</span>
                                            <div className={styles.triangle}></div>
                                        </div>
                                        <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
                                    </div>

                                    <p>{moneyMaskNumber(values.donationProgess.currentValue)}</p>
                                </div>
                                :
                                <ProgressBar currentValue={data.donationProgess.currentValue} goal={data.donationProgess.goal} />
                        }
                    </div>
                </div>
                <div className={styles.video}>
                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/Sq-K1FsyogQ?controls=0" title="YouTube video Gramado" ></iframe>

                </div>
            </div>
            <div className={styles.footer}>
                <h3>Veja quem <b> já está ajudando </b></h3>
                {/* <div>
                    <Carousel values={carrosel} />
                </div> */}
                <div>
                    <img src="/assets/icon/donate/arenamrv.png" alt="Arena mrv" />
                </div>
            </div>

            <Sidesheet
                isLoading={false}
                open={openDrownActive}
                onClose={onClose}
                title={
                    <h2>
                        Realizar <b>doações</b>
                    </h2>
                }
                content={handleContent()}
                cancelButton
                totalSteps={1}
                continueButton={"Concluir"}
                handleContinueButton={continueOrSave}
            />
        </div>
    )
}
