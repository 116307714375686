import { RequestType } from "models/api/IApi";
import { api } from "../Api";
import ITicketsReportService from "modules/ticketsReport/domain/interfaces/ITicketsReportService";
import { IGetProductsResponse } from "modules/ticketsReport/domain/dto/products/IGetProductsResponse";
import { IRequestParams } from "modules/ticketsReport/domain/interfaces/RequestParams";
import { IGetTicketsValidationResponse } from "modules/ticketsReport/domain/dto/ticketsValidation/IGetTicketsValidationResponse";
import { IGetTicketsValidationDetailResponse } from "modules/ticketsReport/domain/dto/ticketsValidationDetail/IGetTicketsValidationDetailResponse";
import { IGetVendingDeviceResponse } from "modules/ticketsReport/domain/dto/vendingDevice/IGetVendingDeviceResponse";
import { IGetValidationDeviceResponse } from "modules/ticketsReport/domain/dto/validationDevice/IGetValidationDeviceResponse";
import { IGetValidationOperatorResponse } from "modules/ticketsReport/domain/dto/validationOperator/IGetValidationOperatorResponse";
import { IGetCashiersResponse } from "modules/ticketsReport/domain/dto/cashiers/IGetCashiersResponse";
import { IGetCategoriesResponse } from "modules/ticketsReport/domain/dto/categories/IGetCategoriesResponse";

const TicketsReportService = (): ITicketsReportService => {
    const makeQuery = (request: IRequestParams) => {
        let query = "?";
        query += request.sort?.type ? `&sort.type=${request.sort?.type}` : '';
        query += request.sort?.orientation ? `&sort.orientation=${request.sort?.orientation}` : '';

        return query;
    }
    
    /*
    Método base de request. 
    Todos os requests que forem seguir o mesmo padrão devem OBRIGATORIAMENTE utilizar esse método
    */
    async function executeRequest<T>(requestType: RequestType, request: IRequestParams, uri: string, params?: any): Promise<T> {
        const query = makeQuery(request);

        delete request.sort

        const result = await api.get<T>(
            `relatorio/${uri}${query}`,
            { params: { disableError: true, ...params, ...request } }
        );

        return result.data;
    }

    // Caso queira fazer um tratamento padrão de erros, fazer aqui
    const catchError = (err: any) => {
        throw new Error(err);
    }

    const getCashiers = async (localId: string): Promise<IGetCashiersResponse[]> => {
        const response = await api.get('/Relatorio/BuscarSessoesPOSDoLocal', { params: { disableError: true, LocalClienteId: localId } });
        return response.data;
    };

    const getProducts = async (localId: string): Promise<IGetProductsResponse[]> => {
        const response = await api.get('Produto/BuscarProdutosDoLocalParaFiltro', { params: { disableError: true, localId } });
        return response.data;
    };
    
    const getVendingDevice = async (localId: string): Promise<IGetVendingDeviceResponse[]> => {
        const response = await api.get(`Proprietario/BuscarEquipamentosQueJaTrabalharamNoLocal/${localId}`, { params: { disableError: true, localId } });
        return response.data;
    };

    const getValidationDevice = async (localId: string): Promise<IGetValidationDeviceResponse[]> => {
        const response = await api.post('Equipamento/BuscarMeepChecks', { disableError: true, LocalClienteId: localId });
        return response.data;
    };

    const getValidationOperator = async (localId: string): Promise<IGetValidationOperatorResponse[]> => {
        const response = await api.get(`Proprietario/BuscarOperadoresDoLocal/${localId}`, { params: { disableError: true, localId } });
        return response.data;
    };

    const getCategories = async (localId: string): Promise<IGetCategoriesResponse> => {
        const response = await api.get(`Produto/BuscarCategoriasPorLocalId/${localId}`, { params: { disableError: true, localId } });
        return response.data;
    };

    const getTicketsValidation = async (request: IRequestParams): Promise<IGetTicketsValidationResponse> => {
        let response = {} as IGetTicketsValidationResponse;

        try {
            response = await executeRequest<IGetTicketsValidationResponse>(RequestType.GET, request, 'GetTicketsValidation');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    const getTicketValidationDetail = async (localId: string, ticketId: string): Promise<IGetTicketsValidationDetailResponse> => {
        let response = {} as IGetTicketsValidationDetailResponse;

        try {
            response = await executeRequest<IGetTicketsValidationDetailResponse>(RequestType.GET, { }, 'GetTicketValidationDetail', { localId, ticketId });
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    const getTicketValidationReport = async (request: IRequestParams): Promise<void> => {
        try {
            await executeRequest<IGetTicketsValidationDetailResponse>(RequestType.GET, request, 'GetTicketValidationReport');
        } catch (err: any) {
            catchError(err);
        }
    }

    return ({
        getCashiers,
        getProducts,
        getTicketsValidation,
        getTicketValidationDetail,
        getVendingDevice,
        getValidationDevice,
        getValidationOperator,
        getCategories,
        getTicketValidationReport
    })
}
export default TicketsReportService