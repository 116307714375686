import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import Autocomplete from 'components/ui/autoComplete/AutoComplete'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import { Icon, IconButton, MenuItem } from '@material-ui/core';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { IOtherValuesForm } from '../../interfaces/IServiceForm';
import UseOtherValuesForm from './UseOtherValuesForm';
import styles from "./OtherValuesForm.module.scss";
import { AddCircle } from '@material-ui/icons';
import AutoComplete from 'components/ui/autoComplete/AutoComplete';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import { IServiceFormErros } from '../../interfaces/IServiceFormErros';


interface IOtherValuesFormProps {
    values: IOtherValuesForm[];
    onChange: (values: IOtherValuesForm[]) => void;
    productList: ISelect[];
    filteredProductList: ISelect[];
    onClickAddNewProduct?: () => void;
    hasError: IServiceFormErros;
}

export const OtherValuesForm: FC<IOtherValuesFormProps> = ({ values, onChange, productList, onClickAddNewProduct, filteredProductList, hasError }) => {

    const { onHandleChangeAge, onKeyPressHandle, newValue, onRemoveHandle, onChangeHandle, onAddHandle, onChangeHandlePrice, selectedProductList, changeProductListSelectHandle, onClickEditItem, isEdit } = UseOtherValuesForm(values, onChange, productList, onClickAddNewProduct)

    const valuesListCB = useCallback(
        () => {
            return (
                <div id={styles.OtherValuesForm}>
                    {


                        values.map((item, index) => (
                            <div className={styles.otherValuesList} key={index}>
                                <div className={styles.containerList}>
                                    <p>
                                        {
                                            `${productList.find(productListItem => item.productListId === productListItem.id)?.description ?? ""}` + ": " + `${new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(item.value)}`
                                        }
                                    </p>

                                    {
                                        item.minAge !== null && item.minAge !== null ?

                                            <p>{item.minAge}-{item.maxAge} anos</p>
                                            :
                                            ''
                                    }
                                </div>
                                <div className={styles.containerButtons}>
                                    <IconButton
                                        disabled={isEdit}
                                        onClick={() => onClickEditItem(item.productListId)}
                                    >
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => onRemoveHandle(item)}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        ))

                    }
                </div>
            );
        },
        [values, productList]
    );

    const valuesList = useMemo(valuesListCB, [valuesListCB])


    const renderOption = (option: ISelect, state: AutocompleteRenderOptionState) => {
        if (option.id === "add") {
            return <div style={{ display: "flex", flex: 1, alignItems: "center" }} onClick={onClickAddNewProduct}><AddCircle style={{ marginRight: 10, color: "#ED0029" }} />Adicionar Lista</div>
        } else {
            return <div>{option.description}</div>
        }

    }

    return (
        <div className={styles.containerInputs}>


            <FormItemContainer row>
                <FormItemContainer flex={10} label={"Lista de Produto"}>
                    <AutoComplete
                        key={selectedProductList?.description}
                        variant="standard"
                        value={selectedProductList}
                        getOptionLabel={(option) => option.description}
                        getOptionSelected={(option, value) => option.id === value.id}
                        options={[{ description: "", id: "add" }, ...filteredProductList.sort((a, b) => (a.description > b.description) ? 1 : -1)]}
                        name="productListId"
                        onChange={(ev, values) => changeProductListSelectHandle(values as ISelect)}
                        renderOption={renderOption}
                    />
                </FormItemContainer>
                <FormItemContainer flex={8} label={"Preço"} >
                    <Input
                        name="value"
                        value={newValue.value}
                        type="number"
                        onChange={(ev) => onChangeHandlePrice(ev.target.name, ev.target.value)}
                        inputProps={
                            {
                                onKeyPress: onKeyPressHandle,
                                inputMode: "numeric",
                                min: 0
                            }
                        }
                    />
                </FormItemContainer>
                <FormItemContainer flex={6} label={"Idade minima"} >
                    <Input
                        name="minAge"
                        value={newValue.minAge}
                        type="number"
                        onChange={(ev) => onHandleChangeAge (ev.target.name, ev.target.value)}
                        inputProps={
                            {
                                onKeyPress: onKeyPressHandle,
                                inputMode: "numeric",
                                min: 0
                            }
                        }
                    />
                </FormItemContainer>
                <FormItemContainer flex={6} label={"Idade máxima"} >
                    <Input
                        name="maxAge"
                        value={newValue.maxAge}
                        type="number"
                        onChange={(ev) => onHandleChangeAge (ev.target.name, ev.target.value)}
                        inputProps={
                            {
                                onKeyPress: onKeyPressHandle,
                                inputMode: "numeric",
                                min: 0
                            }
                        }
                    />
                </FormItemContainer>
                <FormItemContainer flex={1}>
                    <Button className={styles.button} disabled={!selectedProductList?.description} variant="outlined" onClick={onAddHandle}>Adicionar</Button>
                </FormItemContainer>
            </FormItemContainer>

            {

                hasError.otherValues && 
                <p className={styles.textError}>Necessário adicionar ao menos um valor para o passeio.</p>
            }

            {valuesList}
        </div>
    );
};
export default memo(OtherValuesForm);
