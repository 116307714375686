import { v4 } from "uuid";

interface MockSettings {
    label: string;
    route?: string;
    image?: string;
    papel?: string;
    subItems?: MockSettings[];
}

const MMOCKSETTINGS: MockSettings[] = [
    {
        label: "EDI",
        papel: "ExtratosDigitais",
        subItems: [
            {
                label: "Extratos Digitais",
                route: `/private/admin/EDI/eletronicExtracts`,
                papel: ""
            },
            {
                label: "Importar",
                route: `/private/admin/EDI/importEletronicExtracts`,
                papel: ""
            }
        ]
    },
    {
        label: "Processar Transações",
        image: "lock",
        papel: "ProcessarTransacoes",
        subItems: [
            {
                label: "Processar Transações",
                route: "/admin/TransactionsProcess/transactionsProcess",
                papel: ""
            }
        ]
    },
    {
        label: "Equipamentos",
        subItems: [
            {
                label: "Equipamento",
                route: `/private/admin/equipamentos/equipamentos`,
                papel: "CadastroKit"
            },
            {
                label: "Licenças",
                route: `/private/admin/equipamentos/licencas`,
                papel: "LicencaEquipamento"
            },
            {
                label: "Monitoramento",
                route: `/private/admin/equipamentos/monitoramento`,
                papel: "MonitorarEquipamentos"
            },
            {
                label: "Importar",
                route: `/private/admin/equipamentos/importar`,
                papel: "ImportarEquipamentos"
            }
        ]
    },
    {
        label: "Importação",
        subItems: [
            {
                label: "Produtos",
                route: `/private/admin/importacao/produtos`,
                papel: "ImportarProdutosAdmin"
            },
            {
                label: "Cartões",
                route: `/private/admin/importacao/cartoes`,
                papel: "ImportarCartoes"
            }
        ]
    },
    {
        label: "Acessos",
        route: `/private/admin/acessosAdmin`,
        papel: "Acessos"
    },
    {
        label: "Informações financeiras do local",
        route: `/private/admin/informacoesFinanceirasLocal`,
        papel: "InformacoesFinanceirasLocal"
    },
    {
        label: "Clientes bloqueados",
        route: `/private/admin/clientesBloqueados`,
        papel: "ClientesBloqueadosPortal"
    },
    {
        label: "Gerenciar cartões nominais",
        route: `/private/admin/gerenciarCartoesNominais`,
        papel: "GerenciarCartoesNominaisPortal"
    },
    {
        label: "papel de parede equipamento",
        route: `/private/admin/papelDeParedeEquipamento`,
        papel: "PapelDeParedeEquipamento"
    },
    {
        label: "Parâmetros globais",
        route: `/private/admin/parametrosGlobais`,
        papel: "ParametrosGlobais"
    },
    {
        label: "Buscar transações",
        route: `/private/admin/buscarTransacoes`,
        papel: "BuscarTransacoes"
    },
    {
        label: "Mensagens dashboard",
        route: `/private/admin/mensagensDashboard`,
        papel: "DashBoardMensagem"
    },
    {
        label: "Menu de venda personalizado",
        route: `/private/admin/customSaleMenu`,
        papel: "CustomSaleMenu"
    },
    {
        label: "Validar cartões",
        route: `/private/admin/verificarCartaoCredito`,
        papel: "VerificarCartaoCredito"
    },
    {
        label: "Manutenção semanal",
        route: "/private/admin/manutencaoSemanal",
        papel: "GerenciaManutencaoSemanal"
    },
    {
        label: "MPPagamentos",
        papel: "CredenciamentoMPPagamentos",
        subItems: [
            {
              label: "Status Credenciamento",
              route: "/private/admin/statusCredenciamento",
              papel: "CredenciamentoMPPagamentos",
            },
            {
              label: "Credenciamento",
              route: "/private/admin/credenciamentoMPPagamentos",
              papel: "CredenciamentoMPPagamentos",
            },
            {
              label: "Terminais",
              route: "/private/admin/terminaisMPPagamentos",
              papel: "CredenciamentoMPPagamentos",
            },
            {
              label: "TIDs Adicionais",
              route: "/private/admin/Complience/TIDsAdicionais",
              papel: "SolicitarTdisAdicionais",
            },
            {
              label: "Aprovação TIDs",
              route: "/private/admin/Complience/AprovacaoTIDs",
              papel: "AprovarTdisAdicionais",
            }
        ]
    }
];

export interface IGetMenuResponse {
    id: string;
    title: string;
    type: number;
    route?: string;
    icon?: string;
    submenus: IGetMenuResponse[];
    order?: number;
}





function convertMockSettingsToIGetMenuResponse(mockSettings: MockSettings[], paper: string[]): IGetMenuResponse[] {
    const result: IGetMenuResponse[] = [];



    mockSettings.forEach(mockSetting => {
        const iGetMenuResponse: IGetMenuResponse = {
            id: v4(),
            title: mockSetting.label,
            type: 1,
            route: mockSetting.route,
            submenus: [],
        };

        if (mockSetting.subItems) {
            iGetMenuResponse.submenus = convertMockSettingsToIGetMenuResponse(mockSetting.subItems, paper);
        }

        if (mockSetting.papel) {
            if (paper.includes(mockSetting.papel)) {
                result.push(iGetMenuResponse);
            }
        } else {
            result.push(iGetMenuResponse);
        }
    });

    return result;
}

export const mockMenuStrings = (papers: string[]) => {
    return convertMockSettingsToIGetMenuResponse(MMOCKSETTINGS || [], papers);
}

