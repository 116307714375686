import styles from "./SaasChangePlanPage.module.scss";
import { UseSaasChangePlanPage } from "./hooks/UseSaasChangePlanPage";
import { KeyboardArrowLeftOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Button from "components/ui/Button/Button";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import { Divider, MenuItem, Select } from "@mui/material";
import { useCallback, useState } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { addDays, format } from "date-fns";
import UseDimension from "components/dimension/UseDimension";
import SaasPlanList from "components/saasPlansOverlay/SaasPlanList";

enum StepEnum {
    Plans = 1,
    Payment = 2,
}

const planTypes = [
    { name: "Básico", grade: 1 },
    { name: "Intermediário", grade: 2 },
    { name: "Avançado", grade: 3 },
];

const SaasChangePlanPage = () => {
    const history = useHistory();
    const { dimensions } = UseDimension();
    const {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        currentPlan,
        plans,
        installments,
        setInstallments,
        mounParcelValue,
        defaultCard,
        expiresIn,
        handleSubmit,
    } = UseSaasChangePlanPage();

    const [step, setStep] = useState(StepEnum.Plans);

    const goBack = () => {
        return history.push("/private/plans");
    }

    const handleNextButton = useCallback(() => {
        if (step === StepEnum.Plans) {
            return setStep(StepEnum.Payment);
        }
        return handleSubmit();
    }, [handleSubmit, step]);

    const handleBackButton = useCallback(() => {
        if (step === StepEnum.Plans) {
            return history.push("/private/plans");
        }
        return setStep(StepEnum.Plans);
    }, [history, step]);

    const handleCardFlag = useCallback((flag: string) => {
        switch (flag) {
            case "visa":
                return <img src="/assets/icon/saas/cards/visa.svg" alt="" />;
            case "master":
                return <img src="/assets/icon/saas/cards/master.svg" alt="" />;
            case "amex":
                return <img src="/assets/icon/saas/cards/amex.svg" alt="" />;
            case "apple":
                return <img src="/assets/icon/saas/cards/apple.svg" alt="" />;
            case "google":
                return <img src="/assets/icon/saas/cards/google.svg" alt="" />;
            default:
                return <div />;
        }
    }, []);

    const handleStartNewPlan = useCallback(() => {
        const currentPlanType = planTypes.find(plan => plan.name === currentPlan?.name);
        const selectedPlanType = planTypes.find(plan => plan.name === selectedPlan?.name);

        if (currentPlanType && selectedPlanType) {
            if (selectedPlanType?.grade >= currentPlanType?.grade) {
                return format(new Date(), 'dd/MM/yyyy');
            }

            const expiresInPlusOne = addDays(new Date(expiresIn), 1);

            return format(expiresInPlusOne, 'dd/MM/yyyy');
        }
    }, [currentPlan, expiresIn, selectedPlan]);

    return (
        <div id={styles.SaasChangePlanPage}>
            <div className={styles.backContainer} onClick={goBack}>
                <KeyboardArrowLeftOutlined className={styles.icon} />
                {dimensions.width > 650 && <span>Voltar</span>}
            </div>
            {step === StepEnum.Plans && (
                <>
                    <div className={styles.info}>
                        <p>Alterar <b>plano</b></p>
                        <p>Você pode alterar o plano conforme as <br/> necessidades do seu negócio</p>
                    </div>

                    <SaasPlanList
                        isCieloCustomer={false}
                        showAnnualOption={false}
                        plans={plans}
                        selectedMode={selectedType}
                        selectedPlan={selectedPlan}
                        selectModeFn={setSelectedType}
                        selectPlanFn={setSelectedPlan}
                    />
                </>
            )}
            {step === StepEnum.Payment && (
                <>
                    <div className={styles.info}>
                        <p>Confirmar <b>alteração de plano</b></p>
                        <p>Certifique-se do plano selecionado antes de confirmar</p>
                    </div>
                    <div className={styles.plans}>
                        <div className={styles.planInfo}>
                            <p>Plano atual:</p>
                            <div>
                                <span>{currentPlan?.name}</span>
                                <span><b>{moneyMaskNumber(currentPlan?.price ?? 0)}/mês</b></span>
                            </div>
                        </div>
                        <Divider style={{ color: "#E0E0E0" }}/>
                        <div className={styles.planInfo}>
                            <p>Novo plano:</p>
                            <div>
                                <span>{selectedPlan?.name}</span>
                                <span><b>{moneyMaskNumber(selectedPlan?.price ?? 0)}/{selectedType === SubscriptionType.ANNUAL ? "ano" : "mês"}</b></span>
                            </div>
                        </div>
                        <div className={styles.paymentForm}>
                            <span>Forma de pagamento:</span>
                            <div>
                                {handleCardFlag("master")}
                                <span>**** {defaultCard!.card.lastFourDigits} | {defaultCard!.card.expirationMonth}/{defaultCard!.card.expirationYear.toString().slice(2)}</span>
                            </div>
                        </div>
                        {
                            !!plans.find(x => x.id === selectedPlan?.id)?.price && selectedType === SubscriptionType.ANNUAL &&
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <label htmlFor='installment' className={styles.required}>Parcelamento</label>
                                    <Select
                                        name='installment'
                                        value={installments}
                                        style={{ width: "100%" }}
                                        size="small"
                                        onChange={(e: any) => setInstallments(Number(e.target.value))}
                                    >
                                        {
                                            Array.from(Array(12).keys()).map((x, index) => (
                                                <MenuItem value={index + 1} key={index + 1}>
                                                    {index + 1}x de {mounParcelValue(index + 1)}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.dateInfo}>
                        <p>Seu novo plano começará em <b>{handleStartNewPlan()}</b> <br /> Ou seja, após o período de contratação atual.</p>
                        <span>
                            Ao confirmar, você concorda com os <a href="https://975d0d01-7cfd-477b-86b2-035f8412c27c.usrfiles.com/ugd/975d0d_aa0583717f694182961ba85f3412d0bb.pdf" target="_blank" rel="noreferrer">Termos de Uso.</a>
                        </span>
                        <span>Você também concorda que o novo plano será renovado mensalmente a até que você cancele a assinatura.</span>
                    </div>
                </>
            )}
            <div className={styles.footerButtons}>
                <Button variant="outlined" color="secondary" onClick={handleBackButton}>Voltar</Button>
                <Button color="secondary" disabled={currentPlan === selectedPlan} onClick={handleNextButton}>Continuar</Button>
            </div>
        </div>
    )
}

export default SaasChangePlanPage;