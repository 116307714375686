import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetOrganizerEventUseCase from "modules/meepTickets/application/useCases/GetOrganizerEventUseCase";
import { IGetOrganizerEventParams } from "modules/meepTickets/domain/dto/IGetOrganizerEventParams";
import { IGetOrganizerEventDataContent, IGetOrganizerEventResponse } from "modules/meepTickets/domain/dto/IGetOrganizerEventResponse";
import { useCallback, useState } from "react";
import MeepTicketsApi from "services/api/meepTickets/MeepTicketsApi";

const meepTicketsApi = MeepTicketsApi();

const UseMeepTickets = () => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [organizerEvent, setOrganizerEvent] = useState<IGetOrganizerEventResponse>();
    const [organizerEventData, setOrganizerEventData] = useState<IGetOrganizerEventDataContent[]>();
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRegisters, setTotalRegisters] = useState(0);

    const { currentLocal } = useLocal();

    const getOrganizerEvent = useCallback(
        async (page: number, pageSize?: number, params?: IGetOrganizerEventParams) => {
            try {
                if (currentLocal) {
                    setIsLoading(true);
                    const response = await GetOrganizerEventUseCase(meepTicketsApi, currentLocal.id, page, pageSize, params);
        
                    setOrganizerEvent(response);
                    setOrganizerEventData(response.data.content);
                    setTotalPages(response.data.totalPages);
                    setTotalRegisters(response.data.numberOfElements);
                    setCurrentPage(page);
                }
            } finally {
            setIsLoading(false);
            }
        },
        [currentLocal]
    );

    const nextPage = (page: number, pageSize?: number, params?: IGetOrganizerEventParams) => {
        if (organizerEventData && organizerEventData.length <= totalRegisters) {
            getOrganizerEvent(page, pageSize, params);
        }
    }

    return {
    isLoading,
    organizerEvent,
    organizerEventData,
    totalPages,
    currentPage,
    totalRegisters,
    nextPage,
    getOrganizerEvent,
    };
}

export default UseMeepTickets;