import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { ISelect } from 'modules/schedule/domain/models/IDropDown';
import { Icon, IconButton } from '@material-ui/core';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IDescriptionForm } from '../../interfaces/IServiceForm';
import UseDescriptionForm from './UseDescriptionForm';
import styles from "./DescriptionForm.module.scss";
import { EditorText } from 'components/editorText/EditorText';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';


interface IDescriptionFormProps {
    values: IDescriptionForm[];
    onChange: (values: IDescriptionForm[]) => void;
}

export const DescriptionForm: FC<IDescriptionFormProps> = ({ values, onChange }) => {

    const { newValue, onRemoveHandle, onChangeDescription, onAddHandle, isEdit, onEditDescription } = UseDescriptionForm(values, onChange)

    const [selectedOpen, setSelectedOpen] = useState<string>()

    const onClickSelected = (id: string | undefined) => {

        if (id === selectedOpen) {
            setSelectedOpen(undefined)
        } else {
            setSelectedOpen(id)
        }

    }


    const valuesListCB = useCallback(
        () => {
            return (
                <div id={styles.DescriptionForm}>
                    {values.map((item, index) => (
                        <div>
                            <div className={styles.descriptionContainer} key={index}>

                                <div className={styles.containerHeader}>
                                    <div className={styles.title}>
                                        <p>{item.title}</p>
                                    </div>
                                    <div className={styles.buttons}>

                                        <IconButton
                                            onClick={() => onRemoveHandle(item)}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                        {
                                            isEdit ?

                                                <IconButton
                                                    disabled
                                                    onClick={() => onEditDescription(item.id ?? '')}
                                                >
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                :
                                                <IconButton
                                                    onClick={() => onEditDescription(item.id ?? '')}
                                                >
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                        }
                                        <div onClick={() => onClickSelected(item.id)}>
                                            <IconButton>
                                                {
                                                    selectedOpen === item.id ? (
                                                        <KeyboardArrowDown />
                                                    ) : (
                                                        <KeyboardArrowUp />
                                                    )
                                                }
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        selectedOpen === item.id ? styles.showCard : styles.hideCard
                                    }
                                >
                                    <div>
                                        <span>Descrição:</span>
                                        <p dangerouslySetInnerHTML={{ __html: item.description.replace(/<script>/g, '') }}></p>
                                        {/* {item.description.split('\n').map(str => <p>{str}</p>)} */}


                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            );
        },
        [values, selectedOpen, onRemoveHandle]
    );

    const valuesList = useMemo(valuesListCB, [valuesListCB])


    return (
        <div className={styles.containerInputs}>


            <FormItemContainer column>

                <FormItemContainer>

                    <FormItemContainer label={"Título"} >
                        <Input
                            name="title"
                            value={newValue.title}
                            onChange={(ev) => onChangeDescription(ev.target.name, ev.target.value)}
                            inputProps={{ maxLength: 100 }}
                        />
                    </FormItemContainer>
                    <FormItemContainer label={"Descrição"}>
                        {/* <Input
                            name="description"
                            value={newValue.description}
                            multiline
                            rows={4}
                            onChange={(ev) => onChangeDescription(ev.target.name, ev.target.value)}
                            variant={"outlined"}

                        /> */}
                        <EditorText text={newValue.description} onChangeDescription={value => onChangeDescription("description", value)} />
                    </FormItemContainer>
                </FormItemContainer>
                <div className={styles.button}>
                    <Button fullWidth={false} variant="outlined" disabled={values.length >= 4 || !newValue.description.length || !newValue.title.length} onClick={onAddHandle}>Adicionar</Button>
                </div>
            </FormItemContainer>

            {valuesList}
        </div>
    );
};

