import { FC, useEffect, useState } from "react";
import styles from "./ExtratoPage.module.scss";
import { ArrowBackIos } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { UseContaDigitalPage } from "../UseContaDigitalPage";
import { useHistory, useParams } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { formatarValor } from "../utils";
import { format } from "date-fns";
import UseDimension from "components/dimension/UseDimension";
import { Skeleton } from "components/skeleton/Skeleton";

const buttonsDate = [
  {
    label: "7 dias",
    value: 7,
  },
  {
    label: "15 dias",
    value: 15,
  },
  {
    label: "30 dias",
    value: 30,
  },
  {
    label: "Outro período",
    value: 0,
  },
];

const ExtratoPage: FC = () => {
  const [filtroDate, setFiltroDate] = useState(7);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  const {
    listExtract,
    balance,
    getDigitalAccountBalance,
    handleDate,
    loadingExtract,
  } = UseContaDigitalPage();
  const { push } = useHistory();
  const { account: accountId } = useParams<{ account: string }>();
  const { dimensions } = UseDimension();

  useEffect(() => {
    getDigitalAccountBalance(accountId);
  }, [accountId, listExtract]);

  const search = () => {
    handleDate(filtroDate, startDate, endDate);
  };

  return (
    <div id={styles.Extrato}>
      <div
        className={styles.back}
        onClick={() => push("/private/contaDigital/new")}
      >
        <ArrowBackIos />
        Voltar
      </div>
      <div className={styles.containerFiltro}>
        <div>
          <p>
            Periodo <span style={{ color: "#ED0029" }}>*</span>
          </p>
          <div className={styles.boxFiltro}>
            {buttonsDate.map((item) => {
              return (
                <div
                  className={
                    filtroDate === item.value
                      ? styles.filtroButtonActive
                      : styles.filtroButton
                  }
                  onClick={() => setFiltroDate(item.value)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>
        {filtroDate === 0 && (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <div className={styles.calendarBox}>
              <DesktopDatePicker
                value={startDate}
                disableFuture
                onChange={(ev: any) => setStartDate(new Date(ev))}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <p>
                      Período - ínicio{" "}
                      <span style={{ color: "#ED0029" }}>*</span>
                    </p>
                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
              <DesktopDatePicker
                value={endDate}
                minDate={startDate}
                disableFuture
                onChange={(ev) => {
                  setEndDate(new Date(ev));
                }}
                renderInput={(params) => (
                  <div className={styles.inputContainer}>
                    <p>
                      Período - fim <span style={{ color: "#ED0029" }}>*</span>
                    </p>
                    <TextField {...params} size="small" type="date" />
                  </div>
                )}
              />
            </div>
          </LocalizationProvider>
        )}
        <button className={styles.search} onClick={() => search()}>
          Buscar
        </button>
      </div>
      <div className={styles.containerSaldo}>
        {/* <div className={styles.boxSaldo}>
          <p>Saldo do período</p>
          <p>R$ 200,00</p>
        </div> */}
        <div className={styles.boxSaldo}>
          <p>Saldo atual</p>
          <p className={styles.saldo}>
            {balance && formatarValor(balance?.balanceResult.balanceAvailable)}
          </p>
        </div>
      </div>
      {loadingExtract && (
        <div className={styles.skeleton}>
          <Skeleton items={5} />
        </div>
      )}
      {listExtract && (
        <>
          {listExtract.Records.length > 0 ? (
            <div className={styles.table}>
              <div className={styles.tableHeaderFirst}>
                <div className={styles.titleHeader}>
                  {dimensions.width > 900 ? "Data" : "Data/Descrição"}
                </div>
                {dimensions.width > 900 && (
                  <div className={styles.titleHeader}>Descrição</div>
                )}
                <div className={styles.titleHeader}>Entrada/saída</div>
              </div>
              {listExtract.Records.map((item) => {
                return (
                  <>
                    <div className={styles.tableHeader}>
                      <div
                        className={styles.titleHeaderFirst}
                        style={{ paddingLeft: "10px" }}
                      >
                        {item.Date && format(new Date(item.Date), "dd/MM/yyyy")}
                      </div>
                      <div className={styles.titleHeaderFirst}>
                        Saldo do dia
                      </div>
                      <div className={styles.titleHeaderFirst}>
                        {formatarValor(item.Balance)}
                      </div>
                    </div>
                    {item.ExtractRecords.map((itemExtract) => {
                      return (
                        <div className={styles.tableBody}>
                          {dimensions.width > 900 && (
                            <div className={styles.boxBody}>
                              {itemExtract.Date &&
                                format(
                                  new Date(itemExtract.Date),
                                  "dd/MM/yyyy"
                                )}
                            </div>
                          )}
                          <div className={styles.boxBody}>
                            {dimensions.width <= 900 && (
                              <p>
                                {" "}
                                {itemExtract.Date &&
                                  format(
                                    new Date(itemExtract.Date),
                                    "dd/MM/yyyy"
                                  )}
                              </p>
                            )}
                            <div className={styles.bullet}>
                              {itemExtract.Description}
                            </div>
                            <p>
                              Dados bancários: {""}
                              {itemExtract.BankAccountCode} - {""}
                              {itemExtract.BankAccountNameHolder} - {""}
                              {itemExtract.BankAccountCpf}
                            </p>
                            <p>Responsavel: {itemExtract.Responsible}</p>
                          </div>
                          <div
                            className={
                              itemExtract.Value < 0
                                ? styles.valueNegative
                                : styles.valuePositive
                            }
                          >
                            {formatarValor(itemExtract.Value)}
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          ) : (
            <div className={styles.notFound}>
              <img
                src="/assets/img/not-found.png"
                alt="Nenhuma informação encontrada!"
              />
              <p>Nenhuma informação encontrada!</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ExtratoPage;
