import { darken, lighten } from '@material-ui/core/styles';

export const variedColors = [
  "#FF5760",
  "#FFB300",
  "#00BFA5",
  "#9D82BF",
  "#FF7221",
  "#63ACE1",
  "#A3CD30",
  "#5C344E",
  "#22279F",
  "#734300",
  "#484040",
  "#5B6566",
  "#34495E",
  "#5E1328",
  "#550505",
  "#B61100",
  "#783200",
  "#106B59",
  "#104365",
];

export const redVariants = ['#FCD3D0', '#FFC1BC', '#FFA19C', '#FF787F', '#FF5760'];
export const yellowVariants = ['#FDE7BF', '#FFE2AD', '#FFD893', '#FFC558', '#FFB300'];
export const cianoVariants = ['#C1EBE0', '#B6E7DA', '#A1E0D1', '#55CAB3', '#00BFA5'];
export const purpleVariants = ['#CFC4DE', '#C5B7D8', '#B8A6DF', '#AB95C6', '#9D82BF'];
export const orangeVariants = ['#EEBDA2', '#F29D6D', '#F38C52', '#F88442', '#FF7221'];
export const blueVariants = ['#C6DDF3', '#B0D0EF', '#A2C7EB', '#78B8E7', '#63ACE1'];
export const greenVariants = ['#CFEA88', '#CAE581', '#BEE062', '#B2DC42', '#A3CD30'];

export const colorsVariants = [
  redVariants,
  yellowVariants,
  cianoVariants,
  purpleVariants,
  orangeVariants,
  blueVariants,
  greenVariants
];

export const getVariantColor = (colorIndex: number, value: number, higherValue: number) => {
  if (value / higherValue >= 0.8) {
    return colorsVariants[colorIndex][4];
  } else if (value / higherValue >= 0.6) {
    return colorsVariants[colorIndex][3];
  } else if (value / higherValue >= 0.4) {
    return colorsVariants[colorIndex][2];
  } else if (value / higherValue >= 0.2) {
    return colorsVariants[colorIndex][1];
  } else {
    return colorsVariants[colorIndex][0];
  }
}

export const getVariantColorFromBase = (color: string, value: number, higherValue: number, index: number) => {
  if (!color) return getVariantColor(index, value, higherValue);

  if (value / higherValue >= 0.8) {
    return darken('#' + color, 0.2)
  } else if (value / higherValue >= 0.6) {
    return darken('#' + color, 0.1)
  } else if (value / higherValue >= 0.4) {
    return '#' + color;
  } else if (value / higherValue >= 0.2) {
    return lighten('#' + color, 0.1)
  } else {
    return lighten('#' + color, 0.2)
  }
}

// export const getVariantColor = (colorIndex: number, value: number, higherValue: number) => {
//   if (value / higherValue < 0.2) {
//     return colorsVariants[colorIndex][0];
//   } else if (value / higherValue < 0.4) {
//     return colorsVariants[colorIndex][1];
//   } else if (value / higherValue <= 0.6) {
//     return colorsVariants[colorIndex][2];
//   } else if (value / higherValue <= 0.8) {
//     return colorsVariants[colorIndex][3];
//   } else if (value / higherValue <= 1) {
//     return colorsVariants[colorIndex][4];
//   } else {
//     return "transparent";
//   }
// }
