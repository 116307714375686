export interface ICashier {
  id: string;
  name: string;
  startDate: string; //ISOString
  finalDate: string | null; //ISOString
}

export interface IBalance {
  billed: number;
  serviceTax: number;
  consumed: number;
}

export interface IGuestsCount {
  total: number;
  men: number;
  women: number;
}

export interface IAveregeSpend {
  total: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface ITickets {
  totalBilled: number;
  totalSelled: number;
  menSelled: number;
  womenSelled: number;
  totalCheckin: number;
  menCheckin: number;
  womenCheckin: number;
  averagePrice: number;
  averageMenPrice: number;
  averageWomenPrice: number;
}

export interface IGuestList {
  totalBilled: number;
  guestCount: number;
  menCount: number;
  womenCount: number;
  averageConsumption: number;
  averageMenConsumption: number;
  averageWomenConsumption: number;
  ticketsSold: number;
  menTicketSold: number;
  womenTicketSold: number;
  totalFreeEntry: number;
  menFreeEntry: number;
  womenFreeEntry: number;
  totalCheckin: number;
  menCheckin: number;
  womenCheckin: number;
  totalDiscounts: number;
  menDiscounts: number;
  womenDiscounts: number;
}

export interface IPrePaidCashless {
  total: number;
  serviceTax: number;
  consumed: number;
  averageRecharge: number;
  averageConsumption: number;
  activeCard: number;
  pendingCards: number;
  pendingItems?: IPendingItems[];
}

export interface IPendingItems {
  localId: string;
  historicoAssociacaoId: string;
  activatedIn: string;
  number: string;
  amount: number;
  observation: string;
}

export interface IPosPaidCashless {
  total: number;
  serviceTax: number;
  consumed: number;
  averageConsumption: number;
  activeCards: number;
  pendingCards: number;
  pendingConsumptionAmount: number;
  pendingItems?: IPendingItems[];
}
export interface IPosPaidShared {
  total: number;
  serviceTax: number;
  consumed: number;
  averageConsumption: number;
  commandCount: number;
  pendingCommand: number;
  pendingConsumptionAmount: number;
  pendingItems?: IPendingItems[];
}
export interface IPrePaidChip {
  total: number;
  serviceTax: number;
  consumed: number;
  averageConsumption: number;
  chipAmount: number;
  pendingItems?: IPendingItems[];
}

export interface ISystemTypes {
  prePaidCashless?: IPrePaidCashless;
  posPaidCashless?: IPosPaidCashless;
  posPaidShared?: IPosPaidShared;
  prePaidChip?: IPrePaidChip;
}

export interface IDetailingData {
  credit: number;
  debit: number;
  cash: number;
  voucher: number;
  exchange: number;
  bonus: number;
  lost: number;
  others: number;
  total: number;
}

export interface IDetailing {
  label: string;
  detailingData: IDetailingData;
}

export interface ICloseEvent {
  eventId: string;
  localId: string;
  eventClosed: boolean;
  eventFinancialPending: boolean;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  availableForSalesStart: string;
  availableForSalesEnd: string;
  eventResume: IEventResume;
  eventProductConsumption: IEventProductConsumption;
  eventTickets: IEventsTickets;
  eventSystemTypes: ISystemTypes;
  eventDetailing:  IDetailing[];
}

export interface IEventResume {
  totalBilled: IEventResumeTotalBilled;
  amountCheckinGuests: IEventAmountTicketsSold;
  averageSpend: IResumeAveregeSpend;
  offTickets: IOffTickets;
}

export interface IEventAmountTicketsSold{
  amount: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface IResumeAveregeSpend {
  averageProductSpend: number;
  averageSpend: number;
  averageTicketSpend: number;
}

export interface IOffTickets {
  amount: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface IEventProductConsumption {
  totalBilled: ITotalBilled;
  averageSpend: IAveregeSpend;
}

export interface IEventSystemTypes {
  systemTypes: ISystemTypes;
}

export interface ITotalBilled {
  totalBilled: number;
  productConsumption: number;
  serviceTax: number;
}

export interface IEventResumeTotalBilled {
  totalBilled: number;
  totalTickets: number;
  totalProductConsumption: number;
  totalServiceTax: number;
}

export interface IEventsTickets {
  eventTicketsResume: IEventTicketsResume;
  eventTicketsNotGuests: IEventTicketsNotGuests;
  eventTicketsGuests: IEventTicketsGuests;
}

export interface IEventTicketsNotGuests {
  totalBilled: number;
  ticketsSold: ITicketsSold;
  checkins: ITicketsSold;
  averageSpend: IAveregeSpend;
}

export interface IEventTicketsGuests {
  totalBilled: number;
  ticketsSold: ITicketsSold;
  checkins: ITicketsSold;
  averageSpend: ITicketsSoldTotal;
  freeTicketsAmount: ITicketsSold;
  freeTicketsTotal: IFreeTicketsTotal;
}

export interface IFreeTicketsTotal {
  total: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface IEventTicketsResume {
  totalBilled: number;
  ticketsSold: ITicketsSold;
  checkins: ITicketsSold;
  averageSpend: IAveregeSpend;
}

export interface ITicketsSold {
  amount: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface ITicketsSoldTotal {
  total: number;
  men: number;
  women: number;
  notSpecified: number;
}

export interface ICloseEventHeader {
  id: string;
  name: string;
  cashier: ICashier;
}

export enum PendingSystemTypeEnum {
  posPaidCashless = 1,
  posPaidShared = 2,
  prePaidCashless = 3,
}

export interface IPendingCard {
  id: string;
  name: string;
  value: number;
  openDate: string;
}

export interface ICloseDetails {
  id: string;
  detailing: IDetailing[];
}

export interface ICloseSystemTypesResum {
  id: string;
  systemTypes: ISystemTypes;
}

export interface ICloseResume {
  totalBilled: IEventResumeTotalBilled;
  amountTicketsSold: number;
  averageSpend: number;
  offTickets: number;
}

export interface ICloseTickets {
  id: string;
  tickets: ITickets;
  guestList: IGuestList;
}
