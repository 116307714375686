import React, { FC, useEffect, useState } from "react";
import styles from "./BiPage.module.scss";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { Icon } from "@material-ui/core";
import { MenuItem, TextField } from '@mui/material';
import { GetPowerBIConfigUseCase } from "modules/bi/application/useCases/GetPowerBIConfigUseCase";
import PlansApi from "services/api/plans/PlansApi";
import { IPowerBIConfigItem } from "../../interfaces/IPowerBIConfig";
import { useHistory } from "react-router-dom";
export interface IBiPageProps {
  powerBiFeatureId?: string;
}

const service = PlansApi();

const BiPage: FC<IBiPageProps> = ({ powerBiFeatureId }) => {
  const { currentLocal } = useLocal();
  const [accordeonOpened, setAccordeonOpened] = useState(false);
  const [biList, setBiList] = useState<IPowerBIConfigItem>();
  const history = useHistory();
  const [featureId, setFeatureId] = useState(powerBiFeatureId);

  useEffect(() => {
    setFeatureId(powerBiFeatureId)
  }, [powerBiFeatureId])

  useEffect(() => {
    if (currentLocal) {
      (async () => {
        const response = await GetPowerBIConfigUseCase(service, currentLocal?.id);
        setBiList(response.data.length && response.data[0].powerBIFeature.length ? response.data[0] : undefined);
        if (!powerBiFeatureId && response.data.length && response.data[0].powerBIFeature.length) {
          setFeatureId(response.data[0].powerBIFeature[0].id);
          history.push(`/private/bi/${response.data[0].powerBIFeature[0].id}`);
          window.location.reload();
        }

      })();
    }
  }, [currentLocal, history, powerBiFeatureId]);

  // useEffect(() => {
  //   if (currentLocal) {
  //     switch (currentLocal?.id.toLocaleLowerCase()) {
  //       case "05b6edf4-abac-e3cc-9c1e-c88cf3a0afcb": //Clube Chalezinho
  //         setUrl(
  //           "https://app.powerbi.com/view?r=eyJrIjoiNWE5MjVkZmItYzdhMi00YzEwLTg5ZTAtZjQ4MmIxM2Q5ZWU3IiwidCI6ImM1ZDQ4Njc5LWQxZDUtNDBlZC04NDJhLWUyMTQ0ZjNmOGM5ZiJ9"
  //         );
  //         break;
  //       case "96729772-f2d1-ee44-53b4-6d3f428bf6e0": //quintal
  //         setUrl(
  //           "https://app.powerbi.com/view?r=eyJrIjoiYmU4OTgwMzgtZmE5Ny00YmFlLThlYjMtNTIxMGZlZWM3MjJhIiwidCI6ImM1ZDQ4Njc5LWQxZDUtNDBlZC04NDJhLWUyMTQ0ZjNmOGM5ZiJ9"
  //         );

  //         break;
  //       case "fcc579ee-9f19-42ac-b9a8-363653c92ab4": //havanna
  //         setUrl(
  //           "https://app.powerbi.com/view?r=eyJrIjoiNWE0MzVhNjMtY2VjMS00MDlkLTliODUtMjY1MGY1ZWQyYTg1IiwidCI6ImM1ZDQ4Njc5LWQxZDUtNDBlZC04NDJhLWUyMTQ0ZjNmOGM5ZiJ9"
  //         );
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [currentLocal]);

  return (
    <>
      {!!biList ? (
        <div id={styles.BiPage}>
          {
            biList.powerBIFeature.length > 1 &&
            <div className={styles.biFilter}>
              <span>O Power BI <b>estratégico e personalizado</b> para o seu negócio.</span>
              <TextField
                size={'medium'}
                className={styles.selectBI}
                select
                variant='outlined'
                value={featureId}
                onChange={e => history.push(`/private/bi/${e.target.value}`)}
              >
                {
                  biList.powerBIFeature?.map(x => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                  ))
                }
              </TextField>
            </div>
          }
          {
            featureId &&
            <iframe
              title="Report Section"
              width={"100%"}
              src={biList.powerBIFeature.find(x => x.id === featureId)!.url}
              frameBorder={0}
              allowFullScreen
            />
          }
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.nodata}>
            <div>
              <img className={styles.bgImage} src="/assets/icon/bi/no-data-bg.svg" alt="" />
              <div>
                <img src="/assets/icon/bi/no-data.svg" alt="" />
                <span>Aguarde 7 dias úteis. 🕙</span>
                <span>Estamos <span>organizando as informações</span> do seu quadro BI!</span>
              </div>
            </div>
          </div>
          <div className={styles.accordeon}>
            <div className={styles.row}>
              <span>Enquanto seu quadro ainda não está pronto, <b>confira as vantagens de ter o BI na sua empresa.</b></span>
              <button onClick={() => setAccordeonOpened(!accordeonOpened)}>
                <Icon className={styles.chevron}>{accordeonOpened ? 'expand_less' : 'expand_more'}</Icon>
              </button>
            </div>

            {
              accordeonOpened &&
              <div className={styles.accordeonContent}>
                <div className={styles.itemsRow}>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/management.svg" alt="" />
                      <span>Gestão centralizada</span>
                    </div>
                    <p>
                      Você não precisa mais utilizar várias ferramentas: encontre
                      tudo em um só lugar.
                    </p>
                  </div>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/reports.svg" alt="" />
                      <span>Relatórios detalhados</span>
                    </div>
                    <p>
                      Relatórios detalhados sobre seu caixa, produtos e todas as
                      vendas realizadas.
                    </p>
                  </div>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/real-time.svg" alt="" />
                      <span>Informações em tempo real</span>
                    </div>
                    <p>
                      Saiba quantas pessoas estão no seu estabelecimento e o
                      faturamento em tempo real.
                    </p>
                  </div>
                </div>

                <div className={styles.itemsRow}>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/order-tracking.svg" alt="" />
                      <span>Acompanhamento de pedidos</span>
                    </div>
                    <p>
                      Acompanhe os pedidos dos seus clientes e saiba para qual
                      mesa deve ser entregue.
                    </p>
                  </div>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/digital.svg" alt="" />
                      <span>Conta digital gratuita</span>
                    </div>
                    <p>
                      Gerencie as finanças do seu negócio com uma conta digital
                      gratuita: transfira, pague e saque.
                    </p>
                  </div>
                  <div>
                    <div className={styles.header}>
                      <img src="/assets/icon/bi/products.svg" alt="" />
                      <span>Produtos mais vendidos</span>
                    </div>
                    <p>
                      Saiba quais produtos do seu cardápio foram mais vendidos e a
                      forma mais comum de pagamento.
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};
export default BiPage;
