import React, { FC } from "react";
import AlphabeticalGuestList from "./components/guestCard/AlphabeticalGuestList";
import style from "./GuestsListPage.module.scss";
import { UseGuestListPage } from "./UseGuestListPage";

export interface IGuestListPageProps { }

const GuestsListPage: FC<IGuestListPageProps> = () => {
  const { nameList, getGetList, onClickGoToAddGuest } = UseGuestListPage();

  return (
    <div id={style.GuestListTab}>
      {nameList && <AlphabeticalGuestList guestList={nameList} refreshList={getGetList} goToAddGuests={onClickGoToAddGuest} />}
    </div>
  );
};
export default GuestsListPage;
