import Layout from "components/layout/presentation/Layout"
import { ConfigOnlinePaymentPage } from "./ConfigOnlinePaymentPage"
import { useEffect, useState } from "react";
import { useMenuContext } from "components/layout/presentation/menu/context/MenuContext";
import { CircularProgress } from "@material-ui/core";

export const ConfigOnlinePayment = () => {
    const { verifyRouter } = useMenuContext();

    const [verifying, setVerifying] = useState(true);

    useEffect(() => {
        verifyRouter() && setVerifying(false);
    }, [verifyRouter]);
    
    return (
        <Layout>
            {verifying 
                ? (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                </div>
                ) : <ConfigOnlinePaymentPage />
            }
        </Layout>
    )
}