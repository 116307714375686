import { RefObject, useEffect, useMemo, useState } from "react"
import { intersection } from "zod"

export default function useOnScreen(ref?: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(true)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [])

    useEffect(() => {
      console.log('useOnScreen', isIntersecting, ref?.current)
    },[isIntersecting, ref])

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [observer, ref])

    return isIntersecting
}
