import { UseQueryResult, useQuery } from "react-query";

import { GetSales } from "../requests";
import { GEtSalesResponse, GetSalesProps } from "../types";
import { BaseErrorTypes, normalizeResponse } from "services/api/Api";

type UseSalesResult = UseQueryResult<GEtSalesResponse, BaseErrorTypes>;

export const UseGetSalesQueryName = "UseGetSales";

export const UseSales = (props: GetSalesProps): UseSalesResult => {

    const { enabled, ...rest } = props;

    const request = async (): Promise<GEtSalesResponse> => {
        const response = await GetSales(rest);
        return normalizeResponse<GEtSalesResponse>(response);
    };

    return useQuery<GEtSalesResponse, BaseErrorTypes>(
        [UseGetSalesQueryName, { ...rest }],
        request,
        { refetchOnWindowFocus: false, enabled }
    );
};
