import { useUi } from "contexts/userInterface/UserInterfaceContext";
import EditCustomerIdentificationUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/deviceCustomerIdentification/EditCustomerIdentificationUseCase";
import { IDeviceProfile } from "modules/equipamento/domain/dto/getDeviceById/IGetDeviceByIdResponse";
import { IEditDeviceCustomerIdentificationRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/editDeviceCustomerIdentification/EditDeviceCustomerIdentification";
import { ICustomerIdentification } from "modules/config/deviceConfig/domain/interface/configLocalAndDevice/IDeviceAndLocalConfig";
import { FormDeviceProfileStep } from "modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";

const UseDeviceCustomerIdentification = (path: string = "/private/pdv/perfil") => {
  const { showLoading, hideLoading } = useUi();
  const { push } = useHistory();
  const { currentLocal } = useLocal();

  const editCustomerIdentification = useCallback(
    async (
      profileId: string,
      values: IEditDeviceCustomerIdentificationRequest
    ) => {
      try {
        if (currentLocal) {
          showLoading();
          const service = DeviceProfileApi();
          const response = await EditCustomerIdentificationUseCase(
            service,
            currentLocal.id,
            profileId,
            values
          );
          push(
            `${path}/editar/${profileId}/` +
              FormDeviceProfileStep.OTHER
          );
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, hideLoading, push, showLoading]
  );

  return { editCustomerIdentification };
};

export default UseDeviceCustomerIdentification;
