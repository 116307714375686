

import { ILocalsService } from "modules/meepErp/application/interfaces/_locals/ILocalsService";
import {
  ISaveStorageLocalsRequest,
  IUpdateStorageLocalsRequest,
} from "modules/meepErp/application/dtos/storageLocals/ISaveLocalsRequest";
import { IStorageLocalsResponse } from "modules/meepErp/application/dtos/storageLocals/ILocalsResponse";
import { Api } from "services/api/Api";
import { IEnableDisableResponse } from "modules/meepErp/application/dtos/IPatchIsActive";
import { IGetStorageLocalsListRequest } from "modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListRequest";
import { IGetStorageLocalsListResponse, IGetStorageLocalsItemResponse } from "modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse";
import { erpUrl } from "Enviroment";

const BASE_URL = erpUrl;

const LocalsService = (): ILocalsService => {
  const api = Api();

  const getLocalsList = async (
    request: IGetStorageLocalsListRequest
  ): Promise<IGetStorageLocalsListResponse> => {
    // implementation goes here
    // return await getPaginatedLocalsMOCK(request);
    const response = await api.get<IGetStorageLocalsListResponse>(
      `${BASE_URL}/api/Inventory/StorageLocations`,
      { params: request }
    );
    return response.data;
  };

  const getLocalsItem = async (
    ownerId: string,
    id: string
  ): Promise<IGetStorageLocalsItemResponse> => {
    // implementation goes here
    // return await getLocalsItemMOCK(id);
    const response = await api.get<IGetStorageLocalsItemResponse>(
      `${BASE_URL}/api/Inventory/StorageLocations/${id}`
    );
    return response.data;
  };

  const deleteLocals = async (id: string): Promise<void> => {
    // implementation goes here
    // await delayMock(3000);
    await api.delete(`${BASE_URL}/api/Inventory/StorageLocations/${id}`);
  };

  const createLocals = async (
    data: ISaveStorageLocalsRequest
  ): Promise<IStorageLocalsResponse> => {
    // implementation goes here
    const response = await api.post<IStorageLocalsResponse>(
      `${BASE_URL}/api/Inventory/StorageLocations`,
      data
    );
    return response.data;
  };

  const updateLocals = async (
    data: IUpdateStorageLocalsRequest
  ): Promise<IStorageLocalsResponse> => {
    const response = await api.put<IStorageLocalsResponse>(
      `${BASE_URL}/api/Inventory/StorageLocations/${data.id}`,
      data
    );
    return response.data;
  };

  const enableLocal = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/StorageLocations/${id}/Enable`);
    return response.data
  };

  const disableLocal = async (id: string): Promise<IEnableDisableResponse> => {
    // implementation goes here
    const response = await api.patch(`${BASE_URL}/api/Inventory/StorageLocations/${id}/Disable`);
    return response.data
  };

  return {
    getLocalsList,
    getLocalsItem,
    deleteLocals,
    createLocals,
    updateLocals,
    enableLocal,
    disableLocal
  };
};
export default LocalsService;
