export interface IUnitValues {
  id?: string; // GUID
  symbol: string; // Sigla (obrigatório)
  name: string; // Unidade (obrigatório)
  isActive: boolean;
}

export const EMPTY_UNIT_VALUES = {
  symbol: '',
  name: ''
}
