import { Checkbox, Icon, IconButton } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { ICrmColumnsConfig } from "../interfaces/IColumnsConfig";
import styles from "./CrmUser.module.scss";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

interface CrmHeaderProps {
  columns: ICrmColumnsConfig[],
  onClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onClickSelectAll?: (state: boolean) => void
}

const CrmHeader: FC<CrmHeaderProps> = ({ columns, onClickMenu, onClickSelectAll }) => {
  const { checkPermission } = usePlans();
  
  const header = useMemo(() => columns.map((column, index) =>
    !column.hide && <div
      key={index}
      className={styles.row}
      style={column.style}
    >
      {
        (column.isPremium && !checkPermission(FeatureTypeEnum.CRM)) &&
        <img src="/assets/icon/golden-crown-label.svg" alt="" />
      }
      <span>{column.title}</span>
    </div>
  ), [checkPermission, columns])

  return (
    <div id={styles.CrmHeaderContainer}>
      <div className={styles.container}>
        <div className={styles.checkbox}>
          <Checkbox onChange={(_, value) => onClickSelectAll?.(value)} />
        </div>
        {header}
        <div
          className={`${styles.icon} ${styles.moreIcon}`}
        >
          <IconButton disabled={!checkPermission(FeatureTypeEnum.CRM)} onClick={onClickMenu}>
            <Icon>
              more_horiz
            </Icon>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CrmHeader;
