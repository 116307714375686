import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import styles from "./SaasPlanPage.module.scss";
import { Check, Close, OpenInNewOutlined, WatchLaterOutlined } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import { useHistory } from "react-router-dom";
import { PaymentMethod } from "../../../../saas/presentation/components/paymentMethod/PaymentMethod";
import { Divider, MenuItem, Pagination, Skeleton, TextField } from "@mui/material";
import { comparisonData } from "./SaasPlanPageUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import Sidesheet from "components/sidesheet/Sidesheet";
import { differenceInDays, format } from "date-fns";
import { UseSaasPlanPage } from "../../hooks/UseSaasPlanPage";
import { PaymentStatusEnum } from "modules/plans/domain/dtos/IGetPaymentHistoryResponse";
import { ptBR } from 'date-fns/locale';
import SimpleTrialModal from "../../components/simpleTrialModal/SimpleTrialModal";
import SaasPlanList from "components/saasPlansOverlay/SaasPlanList";

const brands = [
    { name: "Visa", pattern: "4" },
    { name: "Mastercard", pattern: "5" },
    { name: "American Express", pattern: "3" },
];

export const SaasPlanPage = () => {
    const { subscription, isLoading } = usePlans();
    const history = useHistory();
    const {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        history: paymentHhistory,
        currentPage,
        totalPages,
        getHistory,
        nextPage,
        defaultCard,
        getLocalMainData,
        localDocument
    } = UseSaasPlanPage();

    const [openCancelPlanSidesheet, setOpenCancelPlanSidesheet] = useState(false);
    const [pageSize, setPageSize] = useState<string>("10");
    const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);
    const [openInvoiceSidesheet, setOpenInvoiceSidesheet] = useState(false);

    useEffect(() => {
        getHistory(currentPage, Number(pageSize));
    }, [currentPage, getHistory, pageSize]);

    const navigatePayment = () => {
        if (isTrial && localDocument === "28302604000101") {
            return setOpenSimpleTrialModal(true);
        }
        return history.push("/private/saas/payment");
    }

    const navigateUpdatePayment = () => {
        return history.push("/private/saas/mudar-forma-pagamento");
    }

    const navigateUpdatePlan = () => {
        return history.push("/private/saas/mudar-plano");
    }

    const isTrial = useMemo(() => subscription?.isTrial ?? false, [subscription?.isTrial]);

    useEffect(() => {
        if (isTrial) {
            getLocalMainData();
        }
    }, [getLocalMainData, isTrial]);

    const handleCancelAnnualPlan = useCallback(() => {
        if (!subscription?.createdAt) return false;
        const startPlanDate =  new Date(subscription?.createdAt);
        return differenceInDays(new Date(), startPlanDate) <= 7;
    }, [subscription?.createdAt]);

    const handleFindBrand = useCallback(() => {
        const brand = brands.find(brand => brand.pattern === defaultCard?.card.firstSixDigits[0]);
        return brand?.name ?? "cartão";
    }, [defaultCard]);

    const handleStatus = (status: PaymentStatusEnum) => {
        switch (status) {
            case 1:
                return "Criado";
            case 2:
                return "Pendente";
            case 3:
                return "Autorizado pendente de captura";
            case 4:
                return "Falha no pagamento";
            case 5:
                return "Pago";
            case 6:
                return "Pago parcialmente";
            case 7:
                return "Aguardando pagamento";
            case 8:
                return "Estornado";
            case 9:
                return "Estorno solicitado";
            case 10:
                return "Estornado parcialmente";
            case 11:
                return "Cancelado";
            case 12:
                return "Cancelado parcialmente";
            case 13:
                return "Erro no cancelamento";
            case 14:
                return "Erro no estorno";
            case 15:
                return "Aguardando cancelamento";
            case 16:
                return "Com erro";
            case 17:
                return "Falha";
            default:
                return "Desconhecido";
        }
    }

    const handleStatusColor = (status: PaymentStatusEnum) => {
        switch (status) {
            case 4:
                return "#F44336";
            case 5:
                return "#399c54";
            default:
                return "";
        }
    }

    const skeletons = Array.from({ length: 4 }, (_, index) => (
        <Skeleton
            key={index}
            variant="text"
            sx={{ fontSize: '40px', marginBottom: "10px", backgroundColor: "#ededed" }}
            width="100%"
        />
    ));

    const capitalizeFirstLetter = (string: string) => {
        return string[0].toUpperCase() + string.slice(1);
    }

    const historyList = useMemo(() => paymentHhistory?.map((item) => (
        <div className={styles.historyContent} key={item.id}>
            <div>
                <span>{item.status === PaymentStatusEnum.NotAuthorized && "⚠️ "}Compra efetuada</span>
                <span>{capitalizeFirstLetter(format(new Date(item.createdAt), 'MMMM, yyyy', { locale: ptBR }))}</span>
            </div>
            <span>{item.installments ? item.installments + "x" : ""}{moneyMaskNumber(item.value / (item.installments ?? 1))}</span>
            <div>
                <span style={{ color: handleStatusColor(item.status) }}>{handleStatus(item.status)}</span>
                {item.status === PaymentStatusEnum.Captured && <a href="#" onClick={() => setOpenInvoiceSidesheet(true)}>Nota fiscal</a>}
            </div>
        </div>
    )), [paymentHhistory]);

    if (isLoading) {
        return (
            <div className={styles.loadingContainer}>
                <div>
                    <div>
                        <Skeleton width="100%" height="150px" sx={{ backgroundColor: "#ededed" }} />
                        <Skeleton width="100%" height="150px" sx={{ backgroundColor: "#ededed" }} />
                    </div>
                    {skeletons}
                </div>
            </div>
        );
    }

    if (isTrial) {
        return (
            <div className={styles.containerTrial}>
                <div className={styles.top}>
                    <div>
                        <p>Seu plano: <b>{subscription?.planName}</b></p>
                        <span>Você está no período <b>grátis</b></span>
                    </div>
                    <div onClick={navigatePayment}>
                        <span>Contratar plano</span>
                        <OpenInNewOutlined />
                    </div>
                </div>

                <div className={styles.content}>
                    <div className={styles.contentInfo}>
                        <img src="/assets/img/advanced-plan-saas.svg" alt="" />
                        <div>
                            <p>Você está no período <b>grátis!</b></p>
                            <span>Aproveite tudo que a Meep tem a oferecer para o seu negócio!  Gostou? Então <b>contrate um plano!</b></span>
                        </div>
                        <Button onClick={navigatePayment} endIcon={<OpenInNewOutlined />}>Contratar agora</Button>
                    </div>

                    <div className={styles.divider}>
                        <Divider />
                        <span>Conheça os planos</span>
                        <Divider />
                    </div>

                    <SaasPlanList
                        isCieloCustomer={false}
                        plans={plans}
                        selectedMode={selectedType}
                        selectedPlan={selectedPlan}
                        selectModeFn={setSelectedType}
                        selectPlanFn={setSelectedPlan}
                        hasBalance={false}             
                 />

                    <div className={styles.comparasion}>
                        <h2>Entenda o que <b>cada plano oferece</b></h2>
                        <p>Explore nossos planos e comece a simplificar a operação do seu negócio com o sistema criado para você</p>
                    </div>

                    <div className={styles.comparisonContainer}>
                        <div>
                            <div />
                            <span>Básico</span>
                            <span>Intermediário</span>
                            <span>Avançado</span>
                        </div>
                        {comparisonData.map((item) => (
                            <div key={item.title}>
                                <span>{item.title}</span>
                                {typeof item.basic === "string"
                                    ? <span>{item.basic}</span>
                                    : item.basic ? <Check className={styles.check} /> : <Close className={styles.close} />
                                }
                                {typeof item.intermediary === "string"
                                    ? <span>{item.intermediary}</span>
                                    : item.intermediary ? <Check className={styles.check} /> : <Close className={styles.close} />
                                }
                                {typeof item.advanced === "string"
                                    ? <span>{item.advanced}</span>
                                    : item.advanced ? <Check className={styles.check} /> : <Close className={styles.close} />
                                }
                            </div>
                        ))}
                    </div>
                </div>

                <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />
            </div>
        )
    }

    return (
        <>
            <div className={styles.container}>
                <header>
                    {subscription?.planName === "Evento"
                        ? (
                            <div>
                                <span>Plano <b>Eventos</b></span>
                                <span>Valor: <b>{moneyMaskNumber(paymentHhistory[0].value ?? 0)}</b>/30 dias</span>
                                <span>Término do plano: <b>{format(new Date(subscription?.expiresIn), "dd/MM/yyyy")}</b></span>
                            </div>
                        ) : (
                            <div>
                                <span>Seu plano: <b>{subscription?.planName}</b></span>
                                <span>Valor: <b>{moneyMaskNumber(subscription?.planPrice ?? 0)}/{subscription?.subscriptionType === SubscriptionType.ANNUAL ? "anual" : "mensal"}</b></span>
                                {subscription?.expiresIn && subscription?.subscriptionType === SubscriptionType.MONTHLY && (
                                    <>
                                        <span>Forma de pagamento: <b>{handleFindBrand()}</b> com final <b>{defaultCard?.card.lastFourDigits ?? ""}</b></span>
                                        <span>Próxima cobrança: <b>{format(new Date(subscription?.expiresIn), "dd/MM/yyyy")}</b></span>
                                    </>
                                )}
                            </div>
                        )
                    }

                    <div>
                        {subscription?.subscriptionType === SubscriptionType.ANNUAL && handleCancelAnnualPlan() && (
                            <button onClick={() => setOpenCancelPlanSidesheet(true)} style={{ color: "#F44336" }}>
                                Cancelar plano
                            </button>
                        )}
                        {subscription?.subscriptionType === SubscriptionType.MONTHLY && (
                            <>
                                <button onClick={navigateUpdatePayment}>Gerenciar forma de pagamento</button>
                                <button onClick={navigateUpdatePlan}>Alterar plano</button>
                                <button onClick={() => setOpenCancelPlanSidesheet(true)} style={{ color: "#F44336" }}>Cancelar plano</button>
                            </>
                        )}
                    </div>
                </header>

                <div className={styles.history} style={{ height: "100%" }}>
                    <h1>Histórico de compra</h1>
                    <Divider />

                    {paymentHhistory.length
                        ? (
                            <div className={styles.historyTable}>
                                <div className={styles.historyHeader}>
                                    <span>Mês</span>
                                    <span>Valor</span>
                                    <span>Status</span>
                                </div>
                                {historyList}
                            </div>
                        ) : (
                            <div className={styles.empty}>
                                <span>Não se preocupe, <br />seu histórico será exibido aqui em breve!</span>
                            </div>
                        )
                    }

                    {!!totalPages && (
                        <div className={styles.conteinerPagination}>
                            <div className={styles.boxMultiplePage}>
                                <span>Exibir</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    select
                                    value={pageSize}
                                    defaultValue={pageSize}
                                    onChange={(ev) => setPageSize(ev.target.value)}
                                >
                                    <MenuItem className={styles.textPage} value={10}>
                                        10
                                    </MenuItem>
                                    <MenuItem className={styles.textPage} value={20}>
                                        20
                                    </MenuItem>
                                    <MenuItem className={styles.textPage} value={50}>
                                        50
                                    </MenuItem>
                                </TextField>
                            </div>
                            {totalPages > 0 && (
                                <Pagination
                                    variant="outlined"
                                    className={styles.paginationNumber}
                                    shape="rounded"
                                    sx={{
                                        ".MuiPaginationItem-root": {
                                            borderColor: "#BDBDBD",
                                            color: "#001537",
                                        },
                                        ".Mui-selected": {
                                            fontWeight: "bold",
                                            borderColor: "#001537",
                                        },
                                    }}
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(_, value) => nextPage(value, Number(pageSize))}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <Sidesheet
                open={openCancelPlanSidesheet}
                onClose={() => setOpenCancelPlanSidesheet(false)}
                title={<h2><b>Cancelamento de plano</b></h2>}
                content={
                    <div className={styles.sidesheetContent}>
                        <span>
                            É uma pena que você queira cancelar seu plano com a gente! <br />
                            Para prosseguir com o cancelamento, basta enviar um email para <b>suportemee@mee.com.br</b> e informar seu CNPJ.
                        </span>
                        <div className={styles.tip}>
                            <img src="/assets/icon/saas/icon-idea.svg" alt="Dica" />
                            <span>
                                Você também pode solicitar o cancelamento pelo nosso chat de atendimento. Clique no ícone à direta e converse diretamente com a gente!
                            </span>
                        </div>
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Fechar"
                handleContinueButton={() => setOpenCancelPlanSidesheet(false)}
            />

            <Sidesheet
                open={openInvoiceSidesheet}
                onClose={() => setOpenInvoiceSidesheet(false)}
                title={<h2><b>Nota fiscal</b></h2>}
                content={
                    <div className={styles.sidesheetContent}>
                        <WatchLaterOutlined fontSize="large" style={{ color: "#CAAEFF" }} />
                        <span>
                            Estamos gerando sua nota fiscal.<br/>
                            Em breve estará disponível para você!
                        </span>
                        <div className={styles.tip} style={{ maxWidth: "450px" }}>
                            <img src="/assets/icon/saas/icon-idea.svg" alt="Dica" />
                            <span>
                                Em caso de dúvidas, clique no ícone à direta e converse diretamente com a gente pelo chat!
                            </span>
                        </div>
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Fechar"
                handleContinueButton={() => setOpenInvoiceSidesheet(false)}
            />
        </>
    )
}