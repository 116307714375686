import styles from "./Operacao.module.scss";
import Radio from "components/ui/radio/Radio";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import Input from "components/ui/input/Input";
import Button from "components/ui/Button/Button";
import { InfoOutlined, AddCircle } from "@mui/icons-material";
import { useState } from "react";
import { IReponseMesas } from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";

interface IOperacoes {
  operacoes: {
    img: string;
    textParteUm: string;
    textNegrito: string;
    textParteDois: string;
    saas: boolean;
  }[];
  checkedItem: boolean[];
  setCheckedItem: (checked: boolean[]) => void;
  responseMesas: IReponseMesas[] | undefined;
  postMesas: (e: number) => void;
  identificadorSistema?: number
}

const Operacao = ({
  operacoes,
  checkedItem,
  setCheckedItem,
  responseMesas,
  postMesas,
  identificadorSistema
}: IOperacoes) => {
  const [table, setTable] = useState<string | undefined>();
  const [qtdMesas, setQtdMesas] = useState<undefined | number>();

  const handleActive = (index: number) => {
    let updatedActive = [...checkedItem];

    if (index === 0 && updatedActive[1]) {
      updatedActive[1] = false;
    } else if (index === 1 && updatedActive[0]) {
      updatedActive[0] = false;
    }

    updatedActive[index] = !updatedActive[index];

    setCheckedItem(updatedActive);
  };

  return (
    <div id={styles.Operacao}>
      <p className={styles.title}>
        Como funciona sua <span>operação de venda?</span>
      </p>
      <p className={styles.text}>
        Marque a opção que melhor descreve sua operação! Você pode escolher mais
        de uma, se necessário.
      </p>
      <div className={styles.contentCards}>
        {operacoes.map((item, index) => {
          if (identificadorSistema === 1 && item.saas) {
            return (
              <div
                className={checkedItem[index] ? styles.cardActive : styles.card}
                onClick={() => handleActive(index)}
              >
                <img src={item.img} alt="imagem card" />
                <p>
                  {item.textParteUm} <b>{item.textNegrito}</b>{" "}
                  {item.textParteDois}
                </p>
              </div>
            );
          }
          if (identificadorSistema === 0) {
            return (
              <div
                className={checkedItem[index] ? styles.cardActive : styles.card}
                onClick={() => handleActive(index)}
              >
                <img src={item.img} alt="imagem card" />
                <p>
                  {item.textParteUm} <b>{item.textNegrito}</b>{" "}
                  {item.textParteDois}
                </p>
              </div>
            );
          }
        })}
      </div>
      <div className={styles.table}>
        <p className={styles.title}>
          Você utiliza <span>mesas?</span>
        </p>
        <p className={styles.text}>
          Caso você utilize, no final de todo consumo será solicitado o número
          da mesa.
        </p>
        <RadioGroup
          name="mesas"
          row
          style={{ marginTop: "10px" }}
          onChange={(e) => setTable(e.target.value)}
          defaultValue="false"
        >
          <FormControlLabel
            value={"true"}
            control={<Radio />}
            label="Sim"
            style={{ color: "#5F5F5F" }}
          />
          <FormControlLabel
            value={"false"}
            control={<Radio />}
            label="Não"
            style={{ color: "#5F5F5F" }}
          />
        </RadioGroup>
        {table === "true" && (
          <>
            {responseMesas && responseMesas.length > 0 ? (
              <>
                <p style={{ marginTop: "20px" }}>
                  Você possui já <b>{responseMesas.length} mesas</b> cadastradas
                </p>

                <Button
                  className={styles.buttonOutline}
                  onClick={() =>
                    window.open("/private/cadastros/comandas", "_blank")
                  }
                >
                  Cadastrar mais mesas <AddCircle />
                </Button>
              </>
            ) : (
              <>
                <div className={styles.contentInput}>
                  <div className={styles.boxInput}>
                    <FormItemContainer
                      label={"Quantas mesas?"}
                      required
                      className={styles.inputTable}
                    >
                      <Input
                        name={"mesas"}
                        required
                        variant="outlined"
                        type="number"
                        onChange={(e) => setQtdMesas(Number(e.target.value))}
                      />
                    </FormItemContainer>
                  </div>
                  <Button
                    className={styles.buttonSave}
                    onClick={() => qtdMesas && postMesas(qtdMesas)}
                  >
                    Salvar
                  </Button>
                </div>
                <div className={styles.info}>
                  <InfoOutlined />
                  <p>
                    Ao clicar em salvar, você estará cadastrando as mesas para
                    seu estabelecimento. É possível cadastrar mais, se
                    necessário.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Operacao;
