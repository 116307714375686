import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService"
import { IFidelityValues } from "modules/fidelity/presentation/components/forms/FidelityForm"

const GetFidelityLevelValuesByIdUseCase = async (service: IFidelityService, fidelityId: string): Promise<IFidelityValues> => {
    const response = await service.getFidelityLevelById(fidelityId);

    const responseValues: IFidelityValues = {
        name: response.name,
        pointsRequired: response.pointsRequired,
        benefits: response.benefits,
        customization: response.customization && {
            color: response.customization.color,
            icon: response.customization.icon,
            isVisibleApp: response.customization.isVisibleApp,
        },
        levelScoringRules: {
            points: response.pointsRequired,
            rules: response.levelScoringRules ? response.levelScoringRules?.map(item => ({
                type: item.type,
                target: item.target,
                value: item.points,
            })) : [],
        },
        coinsScoringRules: {
            rules: response.coinsScoringRules ? response.coinsScoringRules?.map(item => ({
                type: item.type,
                target: item.target,
                value: item.coins,
            })) : [],
            coinExchange: response.coinExchange
        },
    } as IFidelityValues
    return (responseValues);
}
export default GetFidelityLevelValuesByIdUseCase

