export interface ITicketForm {
  id: string;
  name: string;
  price: number;
  imageUrl: string;
  genderRestriction?: ITicketGenderRestriction;
  maxQuantityPerUser: number;
  type: ITicketProductType;
  adultHood: boolean;
  hasExpireTime?: boolean;
  expireTime?: number;
  ncm?: number;
  cest?: number;
  cfop?: number;
  isActive?: boolean;
}

export interface ITicketFormErrors {
  name: string;
  price: string;
  imageUrl: string;
  ncm: string;
  cest: string;
  cfop: string;
}

export enum TicketFormStep {
  BASE = 0,
  CONFIG = 1,
}

enum ITicketGenderRestriction {
  NAO_INFORMADO = 0,
  MALE = 1,
  FEMALE = 2
}

enum ITicketProductType {
  NOME_LISTA = 15,
  AVULSO = 16
}

export interface ITicketList {
  totalRecords: number;
  records: ITicketForm[];
}