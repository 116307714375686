import { ISort } from "interfaces/ISort";
import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IUseChildfilter } from "modules/permutation/presentation/interfaces/IUseChildfilter";
import { IGetUseChildResponse } from "modules/permutation/domain/dto/IGetUseChildResponse";
import ParseFilterUseChildRequestUseCase from "./ParseFilterUseChildRequestUseCase";

const GetChildUseCase = async (service: IPermutationApiService, accountId: string, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IUseChildfilter): Promise<IGetUseChildResponse> => {
    const request = ParseFilterUseChildRequestUseCase(localId, pagination, filter)
    const response = await service.getUseChild(accountId, request, sort)
    return (response)
}
export default GetChildUseCase