import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { MeepFeePage } from "modules/meepFee/presentation/pages/MeepFeePage";

const MeepFee: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Financeiro",
      },
      {
        label: "Financeiro",
        title: "Taxas",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <MeepFeePage />
    </Layout>
  );
};

export default MeepFee;
