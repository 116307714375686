import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import styles from './_ProductComplementCategoryForm.module.scss'
import { InputLabel } from '../../ui/InputLabel';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { InputNumber } from '../../ui/InputNumber';
import { ContainerLabel } from '../../ui/ContainerLabel';
import { Separator } from '../../ui/Separator';
import { IProductComplementCategoryFormValue } from '../interfaces/IProductComplementCategoryFormValue';
import ButtonsForm from '../../ui/ButtonsForm';
import { v4 } from 'uuid';
import Row from '../../ui/Row';

export interface IProductComplementCategoryFormProps {
    //propertys
    defaultValues?: IProductComplementCategoryFormValue;
    onSubmit: (values: IProductComplementCategoryFormValue) => void
    onCancel: () => void
    isLoading?: boolean
    disabledEditCategory?: boolean
}

const ProductComplementCategoryForm: FC<IProductComplementCategoryFormProps> = ({
    defaultValues,
    onSubmit,
    isLoading,
    onCancel,
    disabledEditCategory
}) => {

    const [values, setValues] = useState<IProductComplementCategoryFormValue>({
        id: v4(), description: "", min: 0, max: 5, freeQuantity: 0
    });

    const [isRequired, setIsRequired] = useState(false);
    const [hasFreeQuantity, setHasFreeQuantity] = useState(false);
    const [errors, setErrors] = useState<{
        categoryId?: string, //Edit ProductCategory
        description?: string,
        min?: string,
        max?: string,
        freeQuantity?: string,
    }>({});

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
        }
    }, [defaultValues])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const onChangeMaxHandle = useCallback((ev,) => {
        setValues((prev) => ({ ...prev, max: Number(ev.target.value) }));
    }, []);

    const onChangeMinHandle = useCallback((ev, value: number) => {
        var _value = value;
        if (isRequired) {
            if (value < 1) {
                _value = 1
            }
        } else {
            if (value < 0) {
                _value = 0;
            }
        }
        setValues((prev) => ({ ...prev, min: _value }));
    }, [isRequired]);

    const onChangeIsRequired = useCallback((value: string) => {
        if (value === "1") {
            if (values.min === 0) {
                setValues((prev) => ({ ...prev, min: 1 }));
            }
            setIsRequired(true);
        } else {
            if ((values.min ?? 0) > 0) {
                setValues((prev) => ({ ...prev, min: 0 }));
            }
            setIsRequired(false);
        }
    }, [values.min])

    const onChangeHasFreeQuantityRequired = useCallback((_, value: string) => {
        if (value === "1") {
            setValues((prev) => ({ ...prev, freeQuantity: 1 }));

            setHasFreeQuantity(true)
        } else {
            setValues((prev) => ({ ...prev, freeQuantity: 0 }));
            setHasFreeQuantity(false);
        }
    }, [])

    const onChangeFreeQuantity = useCallback((ev,) => {
        setValues((prev) => ({ ...prev, freeQuantity: Number(ev.target.value) }));
    }, []);

    const validateForm = useCallback((values: IProductComplementCategoryFormValue) => {

        if (values.description.length === 0) {
            setErrors(prev => ({ description: "O campo descrição é obrigatário" }))
            return false;
        } else if (values.description.length > 50) {
            setErrors(prev => ({ description: "O campo descrição deve ter no máximo 50 caracteres" }))
            return false;
        } else if (isRequired && values.min === 0) {
            setErrors(prev => ({ min: "O campo mínimo é obrigatário" }))
            return false;
        }

        setErrors({});
        return true;
    }, [isRequired])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (validateForm(values)) {
            onSubmit?.(values);
        }
    }, [onSubmit, validateForm, values])

    return (
        <form id={styles.ProductComplementCategoryForm} onSubmit={submitHandle} >
            <div className={styles.container} >
                <div style={{ opacity: disabledEditCategory ? 0.5 : 1 }}>
                    <InputLabel name={"description"} error={!!errors.description} helperText={errors.description} required={true} disabled={disabledEditCategory} value={values.description} label="Descrição" onChange={changeHandle} ></InputLabel>
                </div>
                <div>Seu cliente precisa selecionar um complemento?</div>
                <RadioGroup
                    value={(isRequired || (values.min ?? 0) > 0) ? 1 : 0}
                    onChange={(ev, value) => onChangeIsRequired(value)}
                >
                    <FormControlLabel value={0} control={<Radio />} label={<div><b>Não</b>, os itens serão opcionais</div>} />
                    <FormControlLabel value={1} control={<Radio />} label={<div><b>Sim</b>, será obrigatório selecionar</div>} />
                </RadioGroup>
                <Row style={{justifyContent: 'space-around'}}>
                    <InputNumber name="min" label="mínimo" min={isRequired ? 1 : 0} value={values.min} onChange={onChangeMinHandle} styledSize noLabel></InputNumber>
                    <InputNumber name="max" label="máximo" min={values.min} value={values.max} onChange={onChangeMaxHandle} styledSize noLabel></InputNumber>
                </Row>
                <Separator />
            </div>
            <div className={styles.footer}>
                <ButtonsForm onCancel={onCancel} color='primary' width="100px" cancelLabel="Voltar" />
            </div>
        </form>
    )
}

export default ProductComplementCategoryForm;