import { ISidesheetFormErrors, ISidesheetFormItem } from "../interfaces/ISidesheetForm";

export const SidesheetValidation = (formData: ISidesheetFormItem, setErrors: React.Dispatch<React.SetStateAction<ISidesheetFormErrors>>) => {
  let _errors: ISidesheetFormErrors = {};
  let hasError = false;

  if (!formData.Title || formData.Title.trim().length === 0) {
    _errors = { ..._errors, Title: "Campo TÍtulo é obrigatório" };
    hasError = true;
  }

  if (!formData.Description || formData.Description.trim().length === 0) {
    _errors = { ..._errors, Description: "Campo Descrição é obrigatório" };
    hasError = true;
  } else if (formData.Description.length > 300) {
    _errors = { ..._errors, Description: "A Descrição não pode ter mais de 300 caracteres" };
    hasError = true;
  }

  if (!formData.Category || formData.Category.trim().length === 0) {
    _errors = { ..._errors, Category: "Campo Categoria é obrigatório" };
    hasError = true;
  }

  if (!formData.Link || formData.Link.trim().length === 0) {
    _errors = { ..._errors, Link: "Campo Link é obrigatório" };
    hasError = true;
  }

  if (!formData.img) {
    _errors = { ..._errors, img: "A Imagem é obrigatória" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
