import React, { CSSProperties, useMemo, useState } from 'react'
import styles from '../List.module.scss'
import { IListItem } from '../interfaces/IList'
import { ColumnConfigs, ColumnConfigsItem } from '../List'
import { Icon, IconButton, MenuItem, MenuList, Popover, Tooltip } from '@material-ui/core'
import useOnScreen from '../onScreen/UseOnScreen'

export interface IActionButtons<T extends IListItem> {
    icon?: string
    iconRender?: ((item: T) => React.ReactNode)
    render?: (item: T) => React.ReactNode
    showLoading?: boolean
    tooltip?: string
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
    onClick: (item: T) => Promise<T> | Promise<void> | void | T
}

export interface IMenuButton<T extends IListItem> {
    label: string,
    icon?: string,
    onClick?: (item: T) => void
}

export interface ItemProps<T extends IListItem> {
    item: T,
    onClick?: (value: T) => void
    formatValue?: (values: string) => string
    columnsConfigs?: ColumnConfigs<T>
    actionButtons?: IActionButtons<T>[],
    style?: React.CSSProperties,
    enableDnd?: boolean,
    menuButtons?: IMenuButton<T>[]
    renderAccordion?: (item: T) => React.ReactNode | undefined
    sub?: boolean
    showAccordion?: (item: T) => boolean
    subLevel?: number
}
const Item = <T extends IListItem,>({ item: _item, enableDnd, onClick, formatValue, columnsConfigs, actionButtons, style, menuButtons: menuButton, renderAccordion, sub, showAccordion, subLevel }: ItemProps<T>) => {

    var [item, setItem] = useState<T>(_item)
    var [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const refContainer = React.useRef<HTMLDivElement>(null);
    const onScreen = useOnScreen(refContainer)

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const onClickActionButtons = async (item: T, actionButton?: (item: T) => Promise<T> | Promise<void> | void | T) => {
        setIsLoading(true)
        const response = await actionButton?.(item);
        if (response) setItem(response);
        setIsLoading(false)
    }

    const itemAccordion = useMemo(() => {
        if (renderAccordion && openAccordion) {
            return (
                <div id={styles.accordion} style={{ display: openAccordion ? "block" : "none" }}>
                    {renderAccordion(item)}
                </div>
            )
        }
        return null
    }, [item, openAccordion, renderAccordion])


    const _showAccordion = useMemo(() => {
        if (showAccordion) {
            return showAccordion(item)
        }
        return false
    }, [item, showAccordion])


    return (
        <div key={item.id} ref={refContainer} className={sub ? styles.subItemContainer : styles.itemContainer} >
            <div
                key={item.id}
                style={{
                    ...style, opacity: isLoading ? 0.5 : 1,
                }}
                className={styles.item}
                onClick={() => !isLoading && onClick && onClick(item)}
            >
                {
                    enableDnd && <Icon className={styles.dragIcon}>drag_handle</Icon>
                }
                {columnsConfigs ? Object.keys(columnsConfigs).map((key, index) => {
                    if (!columnsConfigs[key]?.hide) {
                        if (key === "imageUrl") {
                            return (
                                <span
                                    className={styles.row}
                                    style={{ maxWidth: columnsConfigs[key]?.width ?? 76, minWidth: key === "imageUrl" ? 76 : "unset" }}
                                >
                                    <div className={styles.image}>
                                        <span style={{ paddingLeft: sub && index === 0 ? 16 * (subLevel ?? 1) : 0 }} />{item[key] && <img src={item[key]} alt={"foto de " + item.name}></img>}
                                    </div>
                                </span>
                            )
                        } else if (columnsConfigs[key]?.renderRow) {
                            return (
                                <span
                                    className={styles.row}
                                    style={{ maxWidth: columnsConfigs[key]?.width, minWidth: key === "imageUrl" ? 100 : "unset" }}
                                >
                                    <span style={{ paddingLeft: sub && index === 0 ? 16 * (subLevel ?? 1) : 0 }} />
                                    {columnsConfigs[key]?.renderRow?.(item[key], item, setItem)}
                                </span>
                            )
                        } else return (
                            <span
                                className={styles.row}
                                style={{ maxWidth: columnsConfigs[key]?.width, minWidth: key === "imageUrl" ? 100 : "unset" }}
                            >
                                <span style={{ paddingLeft: sub && index === 0 ? 16 * (subLevel ?? 1) : 0 }} />
                                {formatValue ? formatValue(item[key]) : item[key]}
                            </span>
                        )
                    }
                    return null
                })
                    :
                    Object.keys(item).map((key, index) => {
                        if (key === "imageUrl") {
                            return (<span
                                className={styles.row}
                                style={{ width: 100 }}
                            ><div className={styles.image}>{item[key] && <img src={item[key]} alt={"foto de " + item.name}></img>}</div></span>)
                        }
                        if (item.hasOwnProperty(key) && key !== `id`) {
                            return (<span
                                className={styles.row}>{formatValue ? formatValue(item[key]) : item[key]}</span>)
                        }

                        return null
                    })

                }

                {actionButtons && <div id={styles.actionButtons}>
                    <div id={styles.renderButtons}>
                        {actionButtons.map(actionButtonItem => (
                            actionButtonItem.render ?
                                actionButtonItem.render(item)
                                :
                                <Tooltip title={actionButtonItem.tooltip ?? ""}>
                                    <IconButton tabIndex={actionButtonItem.buttonProps?.tabIndex} disabled={isLoading} onClick={() => onClickActionButtons(item, actionButtonItem.onClick)}>
                                        {
                                            actionButtonItem.iconRender ?
                                                actionButtonItem.iconRender(item)
                                                :
                                                actionButtonItem.icon &&
                                                <Icon style={{ color: "#001537" }}>
                                                    {actionButtonItem.icon}
                                                </Icon>
                                        }
                                    </IconButton>
                                </Tooltip>
                        ))}
                    </div>
                </div>
                }

                {!!menuButton?.length && <div id={styles.renderButtons}>
                    <IconButton onClick={(ev) => openMenu(ev)}><Icon>more_horiz</Icon></IconButton>
                </div>}

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closeMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuList>
                        {menuButton?.map(menuButtonItem => <MenuItem onClick={() => {
                            menuButtonItem.onClick?.(item);
                            closeMenu()
                        }}>{menuButtonItem.icon ? <Icon>{menuButtonItem.icon}</Icon> : null}{menuButtonItem.label}</MenuItem>)}
                    </MenuList>
                </Popover>
                {renderAccordion &&
                    <IconButton size='small' disabled={!_showAccordion} style={{
                        visibility: _showAccordion ? "visible" : "hidden",
                    }} onClick={() => setOpenAccordion(!openAccordion)}><Icon>{openAccordion ? "expand_less" : "expand_more"}</Icon></IconButton>
                }
            </div>
            <div id={styles.itemChildren}>
                {itemAccordion}
            </div>
        </div >
    )
}

export default Item
