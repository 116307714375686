import { FC, memo, useEffect } from 'react'
import { ILocalResume, ILocalResumeItem } from './ILocalResume'
import styles from './LocalResume.module.scss'
import './LocalResume.css'
import Slider, { Settings } from "react-slick";
import LocalResumeItem from './components/localResumeItem/LocalResumeItem';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import StickyLegend from '../stickyLegend/StickyLegend';
import { Skeleton } from '../skeleton/Skeleton';
import { useError } from '../../contexts/error/ErrorContext';
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum';
import { IFilterValue } from '../filter/IFilter';

export interface ILocalResumeProps {
    filter: IFilterValue;
    onPressClose: (localResume: ILocalResumeItem) => void,
    localResume: ILocalResume,
    loading: boolean;
    getLocalResume: (filter: IFilterValue) => Promise<ILocalResume>;
    setInitialFilter: React.Dispatch<React.SetStateAction<boolean>>;
}
const LocalResume: FC<ILocalResumeProps> = memo(({ filter, onPressClose, localResume, loading, getLocalResume, setInitialFilter }) => {
    const { setManyOnError, clearManyContainerError } = useError();


    var settings: Settings = {
        slidesToShow: localResume.records?.length && localResume.records?.length > 3 ? 3 : localResume.records?.length,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        arrows: true,
        centerMode: false, // !!(localResume.records?.length && localResume.records?.length > 3),
        centerPadding: "30px",
        className: 'slides',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: localResume.records?.length && localResume.records?.length > 2 ? 2 : localResume.records?.length,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: localResume.records?.length && localResume.records?.length > 1 ? 1 : localResume.records?.length,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: localResume.records?.length && localResume.records?.length > 1 ? 1 : localResume.records?.length,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        clearManyContainerError([dashboardContainerEnum.LOCAL_RESUME, dashboardContainerEnum.CARD_VALUES, dashboardContainerEnum.SALES_PLACE]);

        (async () => {
            try {
                const response = await getLocalResume(filter);

                if (!response.records.length) {
                    setManyOnError({
                        type: 'warning',
                    }, [dashboardContainerEnum.LOCAL_RESUME, dashboardContainerEnum.CARD_VALUES, dashboardContainerEnum.SALES_PLACE])
                }
            } catch {
                setManyOnError({
                    type: 'error',
                }, [dashboardContainerEnum.LOCAL_RESUME, dashboardContainerEnum.CARD_VALUES, dashboardContainerEnum.SALES_PLACE])
            } finally {
                setInitialFilter(true);
            }
        })();
    }, [clearManyContainerError, filter, getLocalResume, setInitialFilter, setManyOnError])

    return (
        <div id={styles.LocalResume} >
            <div className={styles.container} style={{ padding: localResume.records?.length > 3 ? '0 30px' : '0' }}>
                {!loading ?
                    <>
                        <Slider {...settings} arrows={true} className={styles.slick}>
                            {localResume.records?.map((item) => <LocalResumeItem key={item.placeId} onPressClose={onPressClose} localResume={item} hideClose={localResume.records.length === 1} />)}
                        </Slider>
                        <StickyLegend data={localResume.records} />
                    </> :
                    <Skeleton items={3} cardStyle={styles.skeletonCard} containerStyle={styles.skeletonContainer} />
                }
            </div>
        </div>
    )
})
export default LocalResume