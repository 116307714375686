import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './Unit.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import UnitList from '../../components/unit/unitList/UnitList';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { IUnitFilterValue } from '../../components/unit/unitList/unitFilter/IUnitFilter';
import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import UseQueryState from '../../hook/UseQueryFilter';
import { useMutation, useQuery } from 'react-query';
import { IGetUnitItemResponse, IGetUnitListResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse';
import { queryClient } from 'services/api/Api';
import DeleteErp from '../../components/ui/delete/DeleteErp';
import { getUnitUseCase, onDeleteUnitUseCase, updateIsActive } from '../../../application/useCases/Units/UnitUseCases';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { tabsRegister } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

export interface IUnitProps {
    //propertys
}

const UnitPage: FC<IUnitProps> = () => {
    const { currentLocal } = useLocal();
    const { push } = useHistory();
    const { state: filter, updateState: updateFilter } = UseQueryState<IUnitFilterValue>();
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Unidade de medida",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    //ReactiQuery

    const unit = useQuery(['@unit', currentLocal?.id, filter], () => getUnitUseCase(filter), {
        enabled: !!currentLocal?.id,
    })

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetUnitListResponse | undefined>(['@unit'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteLocalsMutation = useMutation((id: string) => onDeleteUnitUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteLocalsMutation.mutateAsync(id);
    }, [onDeleteLocalsMutation])

    const onSuccessUpdateIsActive = (value: IGetUnitItemResponse, { id, isActive }: { id: string; isActive: boolean }) => {
        queryClient.setQueriesData<IGetUnitListResponse | undefined>(
            ['@unit'],
            (previous) =>
                previous
                    ? {
                        ...previous,
                        items: previous.items.map((item) =>
                            item.id === id ? { ...item, isActive } : item
                        ) ?? []
                    }
                    : undefined
        );
    };

    const onUpdateIsActiveMutation = useMutation(
        ({ id, isActive, value }: { id: string; isActive: boolean; value: IGetUnitItemResponse }) =>
            updateIsActive(id, isActive, value),
        {
            onSuccess: onSuccessUpdateIsActive
        }
    );

    const onConfirmEnable = useCallback(async (id: string, isActive: boolean, value: IGetUnitItemResponse) => {
        return await onUpdateIsActiveMutation.mutateAsync({ id, isActive, value });
    }, [onUpdateIsActiveMutation]);

    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/unit/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/unit/update/${id}`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    return (
        <div id={styles.Unit} >
            <TabsErp data={tabsRegister}/>
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar unidade de medida
                    </Button>
                </div>
            }
            <UnitList
                filter={filter}
                onChangeFilter={updateFilter}
                unit={unit.data}
                isLoading={unit.isLoading}
                onEdit={onClickEdit}
                onDelete={onClickDelete}
                onClickEnable={onConfirmEnable}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'unidade de medida'}
                text={'Deseja realmente excluir a unidade de medida selecionada?'}
                textFeedback={'Unidade de medida excluída com sucesso!'}
            />
        </div>
    )
}
export default UnitPage
