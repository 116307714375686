import { ISankhyaPaymentRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaPayment/ISankhyaPaymentRequest";

export interface ISankhyaPaymentFormErrors {
  MeepFormOfPaymentId?: string;
  SankhyaBank?: string;
  SankhyaAccount?: string;
  SankhyaPaymentTypeId?: string;
}

export const SankhyaPaymentValidation = (formData: ISankhyaPaymentRequest, setErrors: React.Dispatch<React.SetStateAction<ISankhyaPaymentFormErrors>>) => {
  let _errors: ISankhyaPaymentFormErrors = {};
  let hasError = false;

  if (!formData.MeepFormOfPaymentId) {
    _errors = { ..._errors, MeepFormOfPaymentId: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaBank) {
    _errors = { ..._errors, SankhyaBank: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaAccount) {
    _errors = { ..._errors, SankhyaAccount: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaPaymentTypeId) {
    _errors = { ..._errors, SankhyaPaymentTypeId: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
