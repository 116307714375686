import { IReserveScheduleApiCB } from 'modules/schedule/application/interfaces/api/IReserveScheduleApi';
import { AddPlaceDemandScheduleRequestDTO, ServiceDemandedRequestDTO } from 'modules/schedule/domain/dto/ReserveSchedule/IAddReseveScheduleWithouPaymentRequest';
import { IReserveScheduleForm } from '../../../presentation/components/reserveScheduleForm/interfaces/IReserveScheduleForm';

const AddReserveScheduleUseCase = async(values: IReserveScheduleForm, serviceScheduleApi: IReserveScheduleApiCB) => {

    const serviceRequest: ServiceDemandedRequestDTO[] = values.customers.map<ServiceDemandedRequestDTO>((customer) => ({
        serviceScheduledId: values.placeSchedule.serviceScheduledId,
        start: values.date,
        // duration?: number,
        listProductId: customer.value?.listId,
        client: {
            birthDate: customer.birthDate,
            name: customer.name,
            document: customer.document
        }
    }));

    const request: AddPlaceDemandScheduleRequestDTO = {
        // id?: string;
        // userId?: string;
        services: serviceRequest
    };
    await serviceScheduleApi().postAddReseveSchedule(request);
};

export default AddReserveScheduleUseCase