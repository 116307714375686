import { TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './PermutationItemForm.module.scss'
import { Button } from '@mui/material'
import { AddCircle, RemoveCircleRounded } from '@mui/icons-material'
import { IOperation } from 'modules/permutation/presentation/interfaces/IAddPermutionAccount'
import { IOperationErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros'
import { moneyMaskNumber } from 'services/utils/Money'
import { IOperationBalance } from 'modules/permutation/presentation/interfaces/IOperationBalance'
import { InputMoney } from 'modules/catalog/presentation/componentes/ui/InputMoney'


interface IPermutationItemFormProps {
    changeHandleValue: (val: string) => void
    changeHandleReason: (name: string) => void
    values: IOperation
    inputValue: string
    inputReason: string
    isAdd: boolean | undefined
    setIsAdd: React.Dispatch<React.SetStateAction<boolean | undefined>>
    onSave: () => void
    errorsObs: IOperationErros
    isEdit: React.MutableRefObject<boolean>
    onSaveUpdateOperation: () => Promise<void>
    getBalanceOperation: () => void
    operationBalance: IOperationBalance | undefined
}

export const PermutationItemForm: React.FC<IPermutationItemFormProps> = ({ inputReason, onSaveUpdateOperation, operationBalance, getBalanceOperation, isEdit, errorsObs, changeHandleValue, changeHandleReason, values, inputValue, setIsAdd, isAdd, onSave }) => {

    const [hide, setHide] = useState(true)
    let displayValue;
    if (operationBalance && operationBalance.balance !== undefined) {
        displayValue = operationBalance.balance;
    } else {
        displayValue = values.value ?? 0;
    }

    const formattedValue = moneyMaskNumber(Number(displayValue));


    return (
        <div id={styles.PermutationItemForm}>

            <div className={styles.containerValue}>
                <div><span>Saldo atual</span> <span>{formattedValue}</span></div>
                <div>
                    <Button variant='outlined' onClick={() => { setIsAdd(true); setHide(false); }}>
                        Adicionar saldo
                        <AddCircle />
                    </Button>
                    <Button variant='contained' onClick={() => { setIsAdd(false); setHide(false); }}>
                        Remover saldo
                        <RemoveCircleRounded />
                    </Button>
                </div>
            </div>
            {
                !hide &&
                <div className={styles.conteinerForm}>
                    <div className={styles.item}>
                        {
                            isAdd ?
                                <>
                                    <label>Adicionar saldo <span>*</span></label>
                                    <InputMoney
                                        error={!!errorsObs.value}
                                        helperText={errorsObs.value}
                                        name={"value"}
                                        value={Number(inputValue)}
                                        onChange={(ev) =>
                                            changeHandleValue(ev.target.value)
                                        }
                                    ></InputMoney>
                                </>
                                :
                                <>
                                    <label>Remover saldo <span>*</span></label>
                                    <InputMoney
                                        error={!!errorsObs.value}
                                        helperText={errorsObs.value}
                                        name={"value"}
                                        value={Number(inputValue)}
                                        onChange={(ev) =>
                                            changeHandleValue(ev.target.value)
                                        }
                                    ></InputMoney>
                                </>
                        }
                    </div>
                    <div className={styles.item}>
                        <label>Motivo <span>*</span></label>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 250 }}
                            size="small"
                            placeholder=""
                            variant="outlined"
                            name="reason"
                            error={!!errorsObs.reason}
                            helperText={errorsObs.reason}
                            onChange={(ev) =>
                                changeHandleReason(ev.target.value)
                            }
                        />
                    </div>

                    <div className={styles.buttonFooter}>
                        <Button variant='outlined' onClick={() => setHide(true)}>
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                if (isEdit.current) {
                                    onSaveUpdateOperation();
                                } else {
                                    onSave();
                                }
                                setHide(true);
                            }}
                            disabled={inputReason.length <= 0 && inputReason.length <= 0}
                        >
                            Salvar
                        </Button>
                    </div>
                </div>
            }
        </div >
    )
}
