import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './SegmentList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { ISegmentFilterValue } from './segmentFilter/ISegmentFilter';
import { IGetSegmentItemResponse, IGetSegmentListResponse } from 'modules/meepErp/application/dtos/segment/IGetLSegmentListResponse';
import SegmentFilter from './segmentFilter/SegmentFilter';
import DefaultActionButtonBuilder from '../../ui/defaultListActionButtons/DefaultListActionButtons';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';

export interface ISegmentListProps {
    //propertys
    filter: ISegmentFilterValue
    isLoading: boolean;
    segment?: IGetSegmentListResponse
    onChangeFilter: (filter: ISegmentFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => void
    onClickEnable: (id: string, isActive: boolean, value: IGetSegmentItemResponse ) => Promise<IGetSegmentItemResponse>
}
const SegmentList: FC<ISegmentListProps> = ({
    segment,
    onDelete,
    onEdit,
    onClickEnable,
    filter,
    onChangeFilter,
    isLoading
}) => {
    const { hasAccessRole } = useAuth()
    useEffect(() => {
        console.log(filter, 'filter')
    }, [filter])

    const submitFilter = useCallback((values: ISegmentFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])

 
    const columnsConfig: ColumnConfigs<IGetSegmentItemResponse> = {
        name: { title: "Nome", sortable: true },

    }
    const actionButtons = DefaultActionButtonBuilder<IGetSegmentItemResponse>({ onDelete, onEdit, onClickEnable })
    
    const pagination = useMemo(() => {
        return {
            page: segment?.page ?? 1,
            pageSize: 10,
            count: segment?.totalPages ?? 0
        }
    }, [segment?.totalPages, segment?.page])

    return (
        <div id={styles.SegmentList} >
            <SegmentFilter
                onSubmit={submitFilter}
                defaultValue={filter}
            />
            <div></div>
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={segment?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default SegmentList