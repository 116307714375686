import { Popover } from "@material-ui/core";
import styles from "./PopoverItem.module.scss";

interface PopoverItemProps {
  openPopover: boolean;
  isMenuPopover: HTMLButtonElement | null;
  setIsMenuPopover: (arg: HTMLButtonElement | null) => void;
  actionFechar: () => void;
  actionAtualizacoes: () => void;
  actionWifi: () => void;
  actionMensagem: () => void;
  actionBloquear: () => void;
}

const PopoverItem = ({
  openPopover,
  isMenuPopover,
  setIsMenuPopover,
  actionFechar,
  actionAtualizacoes,
  actionWifi,
  actionMensagem,
  actionBloquear,
}: PopoverItemProps) => {
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={isMenuPopover}
      onClose={() => setIsMenuPopover(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          boxShadow: "0px 4px 12px 0px #0000001A",
          borderRadius: "4px",
          padding: "8px 16px 8px 16px",
        },
      }}
    >
      <div className={styles.popoverContainer}>
        <p onClick={actionFechar}>Fechar caixa manual</p>
        <p onClick={actionAtualizacoes}>Enviar atualização de dados</p>
        <p onClick={actionWifi}>Habilitar/desabilitar Wi-Fi</p>
        <p onClick={actionMensagem}>Enviar mensagem</p>
        <p onClick={actionBloquear}>Bloquear equipamento</p>
      </div>
    </Popover>
  );
};
export default PopoverItem;
