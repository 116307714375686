import GetCategoryNovidades from "modules/novidades/application/useCases/GetCategoryServicesNovidades";
import { useEffect, useState } from "react";
import { NovidadesApi } from "services/api/novidades/NovidadesApi";

const UseNovidadesPage = () => {
  const [listCategory, setListCategory] = useState([]);

  const novidadesService = NovidadesApi();

  useEffect(() => {
    GetCategoryNovidades(novidadesService).then((response) => {
      setListCategory(response);
    });
  }, []);

  return {
    listCategory,
  };
};

export default UseNovidadesPage;
