import React, { FC, FormEvent, useCallback, useEffect } from "react";
import styles from "../FidelityForm.module.scss";
import { Button } from "@material-ui/core";
import PointTarget from "../poitnRules/PointTarget";
import ExchangeForm, { ICoinExchangeValues } from "../exchange/ExchangeForm";
import UseDimension from "components/dimension/UseDimension";

export interface FidelityCoinsRules {
  type: number;
  target: number;
  value: number;
  expireDays?: number;
}

export interface IFidelityCoinsFormValue {
  rules: FidelityCoinsRules[];
  coinExchange?: ICoinExchangeValues;
}

export interface IFidelityCoinsFormProps {
  //propertys
  defaultValue?: IFidelityCoinsFormValue;
  onSubmit?: (value: IFidelityCoinsFormValue) => void;
  onClickBack?: () => void;
}
const FidelityCoinsForm: FC<IFidelityCoinsFormProps> = ({
  onSubmit,
  onClickBack,
  defaultValue,
}) => {
  const [values, setValues] = React.useState<IFidelityCoinsFormValue>({
    rules: [
      {
        type: 1,
      } as FidelityCoinsRules,
      {
        type: 2,
      } as FidelityCoinsRules,
      {
        type: 3,
      } as FidelityCoinsRules,
    ],
    coinExchange: {
      enabled: true,
      amountValue: 0
    }
  });

  const { dimensions } = UseDimension();

  const changeRulesHandle = useCallback((value: FidelityCoinsRules) => {
    setValues((prev) => ({
      ...prev,
      rules: prev.rules.map((item) => {
        if (item.type === value.type) {
          return value;
        }
        return item;
      }),
    }));
  }, []);

  const changeCoinExchangehandle = useCallback((value: ICoinExchangeValues) => {
    setValues((prev) => ({
      ...prev,
      coinExchange: value
    }));
  }, [])

  const validate = useCallback(() => {
    return true;
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (validate()) {
        onSubmit?.(values);
      }
    },
    [onSubmit, validate, values]
  );

  useEffect(() => {
    if (defaultValue) {
      setValues((prev) => {
        const newValues: IFidelityCoinsFormValue = {
          rules: prev.rules.map((item) => ({
            type: item.type,
            value:
              defaultValue.rules.find((rule) => rule.type === item.type)
                ?.value ?? 0,
            target:
              defaultValue.rules.find((rule) => rule.type === item.type)
                ?.target ?? 0,
          })),
          coinExchange: defaultValue.coinExchange
        };
        return newValues;
      });
    }
  }, [defaultValue]);

  return (
    <form onSubmit={submitHandle}>
      <div className={styles.formContainer}>
        <div className={styles.row} style={{ flexDirection: dimensions.width < 450 ? "column" : "row" }}>
          <img
            src="/assets/img/currency.svg"
            alt=""
            className={styles.headerIcon}
            style={{ alignSelf: dimensions.width < 450 ? "center" : "" }}
          />
          <h4>
            Essas são as <strong>moedas</strong> que seu cliente acumula após
            obter o <strong>nível de fidelidade.</strong> Ah, essas moedas
            poderão ser trocadas por dinheiro depois!
          </h4>
        </div>

        <PointTarget
          onChange={changeRulesHandle}
          values={values.rules[0]}
          targetLabel={"A cada valor consumido"}
          pointsLabel={"Ganha Moeda(s)"}
          targetPlaceholder="Ex.: R$0,00"
          pointsPlaceholder="Ex.: 10"
          moneyMask
          type="tel"
        />

        <PointTarget
          onChange={changeRulesHandle}
          values={values.rules[1]}
          targetLabel={"A cada visita"}
          pointsLabel={"Ganha Moeda(s)"}
          targetValueFixed={1}
          targetPlaceholder="Ex.: 5"
          pointsPlaceholder="Ex.: 7"
          type="tel"
        />

        {/* <PointTarget
            onChange={changeRulesHandle}
            values={values.rules[2]}
            targetLabel={'A cada aniversário do cliente'}
            pointsLabel={'Ganha Moeda(s)'}
            targetPlaceholder='Ex.: 1'
            pointsPlaceholder='Ex.: 7'
        /> */}
        {dimensions.width > 449 && <div>Agora, defina a cotação de moedas do nível:</div>}
        {
           <ExchangeForm
            onChange={changeCoinExchangehandle}
            values={values.coinExchange}
          />
        }
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttons}
          onClick={onClickBack}
          variant="outlined"
          color="secondary"
        >
          Voltar
        </Button>
        <Button
          className={styles.buttons}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Salvar
        </Button>
      </div>
    </form>
  );
};
export default FidelityCoinsForm;
