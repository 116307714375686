import { IGetDayPaymentsResponse } from "../../dtos/IGetDayPaymentsResponse";
import { IMainDashoboardService } from "../../interfaces/IMainDashoboardService";

const GetDayPaymentsUseCase = async (
  service: IMainDashoboardService,
  localId: string,
  cashierId?: string,
  day?: string
): Promise<IGetDayPaymentsResponse> => {
  return await service.getDayPayments(localId, cashierId, day);
};

export default GetDayPaymentsUseCase;
