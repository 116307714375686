import { FC } from 'react';
import styles from './NewDiscountForm.module.scss';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider, DesktopTimePicker } from '@mui/lab';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import Input from 'components/ui/input/Input';
import { Autocomplete } from '@mui/material'
import { IDiscountList } from '../../../IDiscountListForm';
import { IProductItemToDiscount } from 'modules/events/presentation/components/discountPeopleListForm/interfaces/IProductList';
import UseNewDiscountForm from './UseNewDiscountForm';
import { moneyMaskNumber } from 'services/utils/Money';
import { IProductItemDiscount } from '../../../../productList/interfaces/IProductItem';

interface NewDiscountFormProps {
    onClose: () => void;
    onAddDiscount: (newDiscount: IDiscountList[]) => void;
    productList?: IProductItemToDiscount[];
}

export const NewDiscountForm: FC<NewDiscountFormProps> = ({ onClose, productList, onAddDiscount }) => {
    const {
        selectedProducts,
        date,
        time,
        errors,
        discount,
        handleChangeTicket,
        getDiscountValue,
        setDate,
        setTime,
        handleChangeDiscount,
        handleSubmit
    } = UseNewDiscountForm({ onClose, productList, onAddDiscount });

    return (
        <div id={styles.NewDiscount}>
            <span className={styles.title}>Novo desconto</span>

            <FormItemContainer label={"Selecione o ingresso"}>
                <Autocomplete
                    className={styles.inputProduct}
                    options={productList || []}
                    getOptionLabel={option => option.name}
                    value={selectedProducts}
                    onChange={(ev, value) => { handleChangeTicket(value as IProductItemDiscount) }}

                    renderInput={(params) => (
                        <TextField
                            variant={"outlined"}
                            {...params}
                        ></TextField>
                    )}
                />
                {selectedProducts && (
                    <p className={styles.ticketValue}>
                        Valor do ingresso: {moneyMaskNumber(selectedProducts.price)}
                    </p>
                )}
            </FormItemContainer>

            <FormItemContainer label={'Valor final do ingresso'} className={`${styles.formItem}`}>
                <Input
                    name="finalTicketValue"
                    variant='outlined'
                    value={getDiscountValue()}
                    className={styles.input}
                    inputProps={{ maxLength: 50 }}
                    onChange={evt => { handleChangeDiscount(evt.target.value) }}
                    error={!!errors.finalTicketValue}
                    helperText={errors.finalTicketValue}
                    placeholder={"R$ 0,00"}
                />
                <p className={styles.discountApplied}>
                    {`${moneyMaskNumber(
                        discount.priceDiscount?.price ?? 0,
                    )} de desconto aplicado`}
                </p>
            </FormItemContainer>

            <div className={styles.dateContainer}>
                <FormItemContainer label={"Desconto ativo até"} className={styles.formItem}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <DesktopDatePicker
                            value={date}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                            disablePast
                            renderInput={(params) => <TextField
                                {...params}
                                size="small"
                                className={`${styles.datePicker} dashboardFilterDatePicker`}
                                type="date"
                                name="date"
                                error={!!errors.date}
                                helperText={errors.date}
                            />}
                        />
                    </LocalizationProvider>
                </FormItemContainer>

                <FormItemContainer label={"Horário limite"} className={styles.formItem}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <DesktopTimePicker
                            value={time instanceof Date && !isNaN(time.getTime()) ? null : time}
                            onChange={(newValue) => {
                                if (newValue instanceof Date && !isNaN(newValue.getTime())) setTime(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                size="small"
                                className={`${styles.datePicker} dashboardFilterDatePicker`}
                                type="date"
                                name="time"
                                error={!!errors.time}
                                helperText={errors.time}
                            />}
                        />
                    </LocalizationProvider>
                </FormItemContainer>
            </div>
            <div className={styles.validationDatetime}>
                {errors.datetime}
            </div>

            <div className={styles.footer}>
                <button onClick={onClose}>
                    Cancelar
                </button>
                <button disabled={!selectedProducts} onClick={handleSubmit}>
                    Adicionar desconto
                </button>
            </div>
        </div>
    );
};
