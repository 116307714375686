import { Autocomplete, MenuItem, Select, Switch, TextField } from "@mui/material";
import styles from "../ForProductFilter.module.scss";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { TabFilter } from "components/ui/tabFilter/TabFilter";
import Button from "components/ui/Button/Button";
import { IPostSaleForProductParams } from "modules/saleForProduct/domain/dto/IPostSaleForProductParams";
import UseDimension from "components/dimension/UseDimension";
import { IGetOperatorByLocalIdResponse } from "modules/saleForProduct/domain/dto/IGetOperatorByLocalIdResponse";
import { IGetCashlessTagsResponse } from "modules/saleForProduct/domain/dto/IGetCashlessTagsResponse";
import { IProduct } from "modules/ticketsReport/presentation/components/filter/IProduct";
import { IProductList } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/productsForm/IProducts";
import { IVendingDevice } from "modules/ticketsReport/presentation/interfaces/IVendingDevice";
import { IDeviceType } from "modules/ticketsReport/presentation/interfaces/IDeviceType";
import { IDeviceProfileItem } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileList/interfaces/IProfileItem";
import { UseSaleForDevicePage } from "modules/saleForDevice/presentation/hooks/UseSaleForDevicePage";
import { IGetSplitLocalsResponse } from "modules/products/domain/dto/IGetSplitLocalsResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

interface IAdvancedProductFilterProps {
    filters: IPostSaleForProductParams;
    setFilters: React.Dispatch<React.SetStateAction<IPostSaleForProductParams>>;
    onChangeStatusType: (item: { id: number; name: string; }) => void;
    onChangeDevicesTypes: (item: { id: number; name: string; }) => void;
    onChangeDateType: (item: { id: number; name: string; }) => void;
    onChangeSystemType: (item: { id: number; name: string; }) => void;
    onChangeSaleType: (item: { id: number; name: string; }) => void;
    vendingDevices: IVendingDevice[];
    profileList: IDeviceProfileItem[];
    operators: IGetOperatorByLocalIdResponse[];
    categoriesList: { id: string; name: string; }[];
    cashlessTags: IGetCashlessTagsResponse[];
    products: IProduct[];
    productList?: IProductList[];
    handleSubmit: () => void;
    locais: IGetSplitLocalsResponse[];
    devicesTypes: IDeviceType[]; 
}
const statusTypes = [
    {
        id: 3,
        name: "Realizado",
    },
    {
        id: 4,
        name: "Cancelado",
    },
    {
        id: 9,
        name: "Produção",
    },
];

const dateTypes = [
    {
        id: 1,
        name: "Data da venda",
    },
    {
        id: 2,
        name: "Data do faturamento",
    },
];

const systemTypes = [
    {
        id: 1,
        name: "Pré-pago ficha",
    },
    {
        id: 2,
        name: "Cashless",
    },
];

const saleTypes = [
    {
        id: 1,
        name: "Online",
    },
    {
        id: 2,
        name: "Offline",
    },
];


const devicesTypes: IDeviceType[] = [
    { id: 1, description: "APP" },
    { id: 2, description: "Totem" },
    { id: 3, description: "POS" },
];

const AdvancedProductFilter = ({
    filters,
    setFilters,
    onChangeStatusType,
    onChangeDevicesTypes,
    onChangeDateType,
    onChangeSystemType,
    onChangeSaleType,
    vendingDevices,
    profileList,
    operators,
    categoriesList,
    cashlessTags,
    products,
    locais,
    productList,
    handleSubmit,
}: IAdvancedProductFilterProps) => {
    const { isLoading } = UseSaleForDevicePage();
    const { dimensions } = UseDimension();
    const { currentLocal } = useLocal();

    return (
        <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
            <div className={styles.filters}>
                <FormItemContainer
                    label="Status"
                    labelSize="14px"
                    className={styles.formLabel}
                    padding={0}
                    labelMargin="0 0 8px 0"
                >
                    <TabFilter
                        items={statusTypes}
                        value="id"
                        label="name"
                        selectedItems={filters.statusPedido?.map((x) => x.toString()) ?? []}
                        onChange={onChangeStatusType}
                    />
                </FormItemContainer>
            </div>
            <div className={styles.filters}>
                <FormItemContainer
                    label="Data"
                    labelSize="14px"
                    className={styles.formLabel}
                    padding={0}
                    labelMargin="0 0 8px 0"
                >
                    <TabFilter
                        items={dateTypes}
                        value="id"
                        label="name"
                        selectedItems={[filters.dateType?.toString() ?? ""]}
                        onChange={onChangeDateType}
                    />
                </FormItemContainer>
            </div>
            <div className={styles.filters}>
                <FormItemContainer
                    label="Tipo de sistema"
                    labelSize="14px"
                    className={styles.formLabel}
                    padding={0}
                    labelMargin="0 0 8px 0"
                >
                    <TabFilter
                        items={systemTypes}
                        value="id"
                        label="name"
                        selectedItems={[filters.tipoSistema?.toString() ?? ""]}
                        onChange={onChangeSystemType}
                    />
                </FormItemContainer>
            </div>
            {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                <div className={styles.filters}>
                    <FormItemContainer
                        label="Tipo de venda"
                        labelSize="14px"
                        className={styles.formLabel}
                        padding={0}
                        labelMargin="0 0 8px 0"
                    >
                        <TabFilter
                            items={saleTypes}
                            value="id"
                            label="name"
                            selectedItems={[filters.tipoVenda?.toString() ?? ""]}
                            onChange={onChangeSaleType}
                        />
                    </FormItemContainer>
                </div>
            )}
  {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
 <div className={styles.filters}>
 <FormItemContainer
     label="Tipo de Disponsitivo"
     labelSize="14px"
     className={styles.formLabel}
     padding={0}
     labelMargin="0 0 8px 0"
 >
     <TabFilter
         items={devicesTypes}
         value="id"
         label="description"
         selectedItems={filters.tiposDispositivos?.map((x) => x.toString()) ?? []}
         onChange={onChangeDevicesTypes}
     />
 </FormItemContainer>
</div>
)}

{currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                <div className={styles.input}>
                    <span>Dispositivo</span>
                    <Autocomplete
                        options={vendingDevices}
                        getOptionLabel={option => option.nome}
                        noOptionsText="Nenhuma opção encontrada"
                        multiple
                        limitTags={1}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                        onChange={(_ev, values) => {
                            const posIds = values.map((it) => it.id);
                            setFilters({ ...filters, usuarioMeepPOSIds: posIds });
                        }}
                    />
                </div>
            )}

            <div className={styles.input}>
                <span>Operador</span>
                <Autocomplete
                    options={operators}
                    getOptionLabel={option => option.nome}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const operatorsIds = values.map((it) => it.id);
                        setFilters({ ...filters, operadoresIds: operatorsIds });
                    }}
                />
            </div>
            <div className={styles.input}>
                <span>Categoria</span>
                <Autocomplete
                    options={categoriesList as { id: string, name: string }[] ?? []}
                    getOptionLabel={option => option.name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const categoriesIds = values.map((it) => it.id);
                        setFilters({ ...filters, categoriaIds: categoriesIds });
                    }}
                />
            </div>
            {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                <div className={styles.input}>
                    <span>Grupo de cartão</span>
                    <Select
                        value={filters.etiquetaId}
                        size="small"
                        onChange={(ev) => setFilters({ ...filters, etiquetaId: ev.target.value })}
                    >
                        <MenuItem value="" />
                        {(cashlessTags ?? []).map((item) => (
                            <MenuItem value={item.Id} key={item.Id}>{item.Nome}</MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}>
                    <span>PDV</span>
                    <Autocomplete
                        options={profileList}
                        getOptionLabel={option => option.name}
                        noOptionsText="Nenhuma opção encontrada"
                        multiple
                        limitTags={1}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                        onChange={(_ev, values) => {
                            const profilesIds = values.map((it) => it.id);
                            setFilters({ ...filters, profileIds: profilesIds });
                        }}
                    />
                </div>
            )}
            <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}>
                <span>{currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas ? "Lista" : "Cardápio"}</span>
                <Autocomplete
                    options={productList ?? []}
                    getOptionLabel={option => option.name}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const productsListsIds = values.map((it) => it.id);
                        setFilters({ ...filters, listaProdutosIds: productsListsIds });
                    }}
                />
            </div>
            <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}>
                <span>Produto</span>
                <Autocomplete
                    options={products ?? []}
                    getOptionLabel={option => option.nome}
                    noOptionsText="Nenhuma opção encontrada"
                    multiple
                    limitTags={1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    )}
                    onChange={(_ev, values) => {
                        const productsListsIds = values.map((it) => it.id);
                        setFilters({ ...filters, produtosIds: productsListsIds });
                    }}
                />
            </div>
            {locais && locais.length > 0 &&
                <div className={styles.input} style={{ maxWidth: dimensions.width > 650 ? "300px" : "100%" }}>
                    <span>Local</span>
                    <Autocomplete
                        options={locais ?? []}
                        getOptionLabel={option => option.Nome}
                        noOptionsText="Nenhuma opção encontrada"
                        multiple
                        limitTags={1}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                        onChange={(_ev, values) => {
                            const locaisListsIds = values.map((it) => it.Id);
                            setFilters({ ...filters, locaisSplitId: locaisListsIds });
                        }}
                    />
                </div>
            }
            {currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && (
                <>
                    <div className={`${styles.displayRow} ${styles.inputSwitch}`}>
                        <Switch
                            value={filters.somenteContasPagas}
                            checked={filters.somenteContasPagas}
                            onChange={(ev) => setFilters({ ...filters, somenteContasPagas: ev.target.checked })}
                        />
                        <span>Considerar somente contas pagas</span>
                    </div>
                    <div className={`${styles.displayRow} ${styles.inputSwitch}`}>
                        <Switch
                            value={filters.exibirAdicionais}
                            checked={filters.exibirAdicionais}
                            onChange={(ev) => setFilters({ ...filters, exibirAdicionais: ev.target.checked })}
                        />
                        <span>Exibir adicionais</span>
                    </div>
                </>
            )}
            <Button color="secondary" onClick={handleSubmit} disabled={isLoading}>Buscar</Button>
        </div>
    )
}

export default AdvancedProductFilter;