import { IAddCatalogRequest, IEditCatalogRequest } from "modules/catalog/domain/dtos/ISaveCatalogRequest"
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { ICatalogFormValue } from "modules/catalog/presentation/componentes/catalogListForm/CatalogForm"

const EditCatalogUseCase = async (service: ICatalogService, localId: string, catalogId: string, values: ICatalogFormValue) => {


    const request: IEditCatalogRequest = {
        id: catalogId,
        showInApp: values.showInApp,
        description: values.description,
        showInWebApp: values.showInWebApp,
        products: values.products.map(item => ({
            id: item.id,
            name: item.name,
            // imageUrl?: string,
            category: {
                id: "",
                name: ""
            },
            value: item.value,
            discount: item.discount
        }))
    }

    await service.editCatalog(localId, request)
}
export default EditCatalogUseCase