import { useCallback, useEffect, useState } from "react";
import styles from "./TerminalSidesheets.module.scss";
import { format } from "date-fns";
import Button from "components/ui/Button/Button";
import { IDeviceItem } from "modules/Terminal/models/ITerminalDeficeItem";
import { GetNavigatorSolicitationsResponse } from "modules/Terminal/application/dtos/GetNavigatorSolicitationsResponse";

interface ITerminalSidesheetsProps {
    terminalDeviceItem: IDeviceItem;
    getNavigatorSolicitations: (deviceId: string) => Promise<GetNavigatorSolicitationsResponse>;
    onAproveSolicitation: (solicitationID: string) => Promise<void>;
    onRefuseSolicitation: (solicitationID: string) => Promise<void>;
    postRefuseAllSolicitations: (deviceId: string) => Promise<void>;
    onEndSession: (deviceId: string) => Promise<void>;
    onClose: () => void;
}

const SideSheetSolicitations = ({
    terminalDeviceItem,
    getNavigatorSolicitations,
    onAproveSolicitation,
    onRefuseSolicitation,
    postRefuseAllSolicitations,
    onEndSession,
    onClose
}: ITerminalSidesheetsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [navigatorSolicitations, setNavigatorSolicitations] = useState<GetNavigatorSolicitationsResponse>();

    const getSolicitations = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getNavigatorSolicitations(terminalDeviceItem.device.id);
            setNavigatorSolicitations(response);
        } finally {
            setIsLoading(false);
        }
    }, [getNavigatorSolicitations, terminalDeviceItem.device.id]);

    useEffect(() => {
        if (terminalDeviceItem.device.id) {
            getSolicitations();
        }
    }, [getSolicitations, terminalDeviceItem.device.id]);

    const handleApproveSolicitation = useCallback(async (solicitationId: string) => {
        try {
            setIsLoading(true);
            await onAproveSolicitation(solicitationId);
            onClose();
        } finally {
            setIsLoading(false)
        }
    }, [onAproveSolicitation, onClose]);

    const handleRefuseSolicitation = useCallback(async (solicitationId: string) => {
        try {
            setIsLoading(true);
            await onRefuseSolicitation(solicitationId);
            onClose();
        } finally {
            setIsLoading(false)
        }
    }, [onClose, onRefuseSolicitation]);

    const handleRefuseAllSolicitations = useCallback(async (deviceId: string) => {
        try {
            setIsLoading(true);
            await postRefuseAllSolicitations(deviceId);
            onClose();
        } finally {
            setIsLoading(false)
        }
    }, [onClose, postRefuseAllSolicitations]);

    const handleCloseSession = useCallback(async (deviceId: string) => {
        try {
            setIsLoading(true);
            await onEndSession(deviceId);
            onClose();
        } finally {
            setIsLoading(false);
        }
    }, [onClose, onEndSession]);

    return (
        <div className={styles.deviceContent}>
            {!!terminalDeviceItem.navigator?.navigatorSessionId && (
                <>
                    <div className={styles.deviceApproved}>
                        <span className={styles.deviceStatus}>Sua sessão atual</span>
                        <span style={{ marginBottom: "10px" }}><b>Dispositivo:</b> {terminalDeviceItem.device.name}</span>
                        <div>
                            <div>
                                <div className={styles.approved} />
                                <span><b>Status:</b> Aprovado</span>
                            </div>
                            <Button variant="outlined" fullWidth onClick={() => handleCloseSession(terminalDeviceItem.device.id)}>
                                Finalizar sessão
                            </Button>
                        </div>
                    </div>
                    <div className={styles.divider} />
                </>
            )}
            {!!navigatorSolicitations?.items.length && (
                <div className={styles.title}>
                    <h2>Solicitações em aberto</h2>
                    <Button
                        onClick={() => handleRefuseAllSolicitations(terminalDeviceItem.device.id)}
                        variant="outlined"
                    >
                        Negar todas
                    </Button>
                </div>
            )}
            {navigatorSolicitations?.items.map((item) => (
                <div className={styles.device} key={item.id}>
                    <span style={{ marginBottom: "10px" }}><b>Navegador:</b> {item.navigatorName}</span>
                    <span style={{ marginBottom: "10px" }}><b>Dispositivo:</b> {item.device.name}</span>
                    <span><b>Data do acesso:</b> {item.createdAt ? format(new Date(item.createdAt), "dd/MM/yyyy HH:mm") : ""}</span>
                    <div>
                        <div>
                            <div className={styles.statusColor} />
                            <span><b>Status:</b> Aguardando aprovação</span>
                        </div>

                        <div>
                            <Button
                                onClick={() => handleApproveSolicitation(item.id)}
                            >
                                Aprovar
                            </Button>
                            <Button
                                onClick={() => handleRefuseSolicitation(item.id)}
                                variant="outlined"
                            >
                                Negar
                            </Button>
                        </div>
                    </div>
                    {/* <div>
                        <span><b>id:</b>{item.id}</span>
                    </div>
                    <div>
                        <span><b>navigatorSessionId:</b>{item.navigatorSessionId}</span>
                    </div> */}
                </div>
            ))}
        </div>
    );
}

export default SideSheetSolicitations;

export const generateRandomString = (length: number) => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    return randomString;
}