import styles from "./CashClosingItem.module.scss";
import { IResultCategories } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface ICashClosingItem {
  item: IResultCategories;
}

const CashClosingItem = ({ item }: ICashClosingItem) => {
  return (
    <div id={styles.CashClosingItem} className="div-to-capture">
      {item.Itens && item.Itens.map((categoryItem) => (
        <div className={styles.item} key={categoryItem.Id}>
          <span>{categoryItem.Nome}</span>
          <span>{moneyMaskNumber(categoryItem.Cancelado ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Cashless ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Debito ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.DebitoNT ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Credito ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.CreditoNT ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Dinheiro ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Desconto ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Voucher ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Outro ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.EmAberto ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Suprimento ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.Sangria ?? 0)}</span>
          <span>{moneyMaskNumber(categoryItem.TotalItem ?? 0)}</span>
        </div>
      ))}
      <div className={styles.totalItem}>
        <span>{item.Nome}</span>
        <span>{moneyMaskNumber(item.Cancelado ?? 0)}</span>
        <span>{moneyMaskNumber(item.Cashless ?? 0)}</span>
        <span>{moneyMaskNumber(item.Debito ?? 0)}</span>
        <span>{moneyMaskNumber(item.DebitoNT ?? 0)}</span>
        <span>{moneyMaskNumber(item.Credito ?? 0)}</span>
        <span>{moneyMaskNumber(item.CreditoNT ?? 0)}</span>
        <span>{moneyMaskNumber(item.Dinheiro ?? 0)}</span>
        <span>{moneyMaskNumber(item.Desconto ?? 0)}</span>
        <span>{moneyMaskNumber(item.Voucher ?? 0)}</span>
        <span>{moneyMaskNumber(item.Outro ?? 0)}</span>
        <span>{moneyMaskNumber(item.EmAberto ?? 0)}</span>
        <span>{moneyMaskNumber(item.Suprimento ?? 0)}</span>
        <span>{moneyMaskNumber(item.Sangria ?? 0)}</span>
        <span>{moneyMaskNumber(item.TotalCategoria ?? 0)}</span>
      </div>
    </div>
  );
};
export default CashClosingItem;
