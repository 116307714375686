import Layout from "components/layout/presentation/Layout";
import React, { FC } from "react";
import { Integrations } from "./integrationsPage";

const Integracao: FC = () => {
  return (
    <Layout>
      <Integrations />
    </Layout>
  );
};
export default Integracao;
