import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PrintQueueApi from "services/api/printQueue/PrintQueueApi";
import GetEquipmentsUseCase from "modules/printQueue/application/useCases/GetEquipmentsUseCase";
import { IGetEquipments } from "../interfaces/IGetEquipments";


const UseEquipamentsPrintQueue = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [equipamentPrintQueueList, setEquipmentsPrintQueueList] = useState<IGetEquipments[]>()
  const { currentLocal } = useLocal();

  const getEquipmentsPrintQueueList = useCallback(
    async () => {
      try {
        const service = PrintQueueApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetEquipmentsUseCase(service,
            currentLocal.id
          );

          setEquipmentsPrintQueueList(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    equipamentPrintQueueList,
    isLoading,
    getEquipmentsPrintQueueList,
    setEquipmentsPrintQueueList,
  };
};

export default UseEquipamentsPrintQueue;
