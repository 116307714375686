export interface IUsabilityValues {
    homeScreenShortcutsEnabled: boolean;
    homeScreenShortcuts: ShortcutsEnum[];
    isCategoryAllEnabled: boolean;
    isPaymentScreenAfterProductEnabled: boolean;
    isRequestOrderNoteEnabled: boolean;
    isOpenVariableProductAutomaticallyEnabled: boolean;
    isValidateBalanceAutomaticallyEnabled: boolean;
    isCallPaymentAutomaticallyEnabled: boolean;
    isShowConfirmCashPaymentEnabled: boolean;
    isLastActionTakenEnabled: boolean;
    openVideoAfterSale: boolean;
    readBarcodeWithRearCamera: boolean;
    isConsultExtractEnabled: boolean;
}

export enum ShortcutsEnum {
    "Vender produtos" = 0,
    "Sincronizar" = 1,
    "Histórico" = 2,
    "Sair do caixa" = 3,
    "Fechar caixa" = 4,
    "Reconectar impressora" = 5,
    "Estornar produto" = 6,
    "Consumir produto" = 7,
    "Extrato parcial" = 8,
    "Desativar cartão" = 9,
    "Transferência cartão" = 10,
    "Validar saldo" = 11,
    "Agrupar cartões" = 12,
    "Recarga ativação" = 13,
    "Comandar produtos" = 14,
    "Transferência comanda" = 15,
    "Extrato parcial comanda" = 16,
    "Validar Saldo" = 17,
    "Desativar pós pago" = 18,
    "Atualizar dados" = 19,
    "Meep food" = 20,
    "Meep check" = 21,
    "Substituir comanda" = 22 
}