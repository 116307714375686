import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import ColaboradoresPage from "modules/colaboradores/presentation/pages/ColaboradoresPage";

const Colaboradores: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Equipe",
      },
      {
        label: "Equipe",
        title: "Colaboradores",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <ColaboradoresPage />
    </Layout>
  );
};
export default Colaboradores;
