import { IPutEditSimplePosRequest } from "../domain/dto/IPutEditSimplePosRequest";
import { IPutEditSimplePosResponse } from "../domain/dto/IPutEditSimplePosResponse";
import { IAddSimplePosService } from "../domain/interface/IAddSimplePosService";

const PutEditSimplePosUseCase = async (service: IAddSimplePosService, localId: string, simplePosId: string, request: IPutEditSimplePosRequest): Promise<IPutEditSimplePosResponse> => {
  const response = service.putEditSimplePos(localId, simplePosId, request);
  return response;
};

export default PutEditSimplePosUseCase;
