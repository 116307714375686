export const operacoes = [
  {
    img: "/assets/img/gestao_vendas/garcom_mesa.png",
    textParteUm: "O atendimento é feito pelo",
    textNegrito: "garçom, na mesa,",
    textParteDois: "e o pagamento é realizado no fechamento da conta.",
    saas: true
  },
  {
    img: "/assets/img/gestao_vendas/comanda_individual.png",
    textParteUm: "O cliente recebe uma",
    textNegrito: "comanda individual",
    textParteDois: "e realiza o pagamento ao final do atendimento.",
    saas: false
  },
  {
    img: "/assets/img/gestao_vendas/paga_antes.png",
    textParteUm: "A venda é realizada no balcão ou na mesa e o cliente",
    textNegrito: "paga pelo produto antes",
    textParteDois: "de consumir.",
    saas: true
  },
  {
    img: "/assets/img/gestao_vendas/cartao.png",
    textParteUm: "O cliente",
    textNegrito: "recarrega o cartão",
    textParteDois: "de consumo e realiza o pedido no balcão ou na mesa.",
    saas: false
  },
];

export const segmentos = [
  {
    nome: "Bares",
    img: "/assets/icon/gestao_vendas/cerveja.svg",
    imgActive: "/assets/icon/gestao_vendas/cerveja_white.svg",
    id: 1,
  },
  {
    nome: "Restaurantes",
    img: "/assets/icon/gestao_vendas/restaurante-icon.svg",
    imgActive: "/assets/icon/gestao_vendas/restaurante_white.svg",
    id: 9,
  },
  {
    nome: "Baladas",
    img: "/assets/icon/gestao_vendas/balada.svg",
    imgActive: "/assets/icon/gestao_vendas/balada_white.svg",
    id: 2,
  },
  {
    nome: "Eventos / Shows",
    img: "/assets/icon/gestao_vendas/eventos.svg",
    imgActive: "/assets/icon/gestao_vendas/eventos_white.svg",
    id: 8,
  },
  {
    nome: "Hotéis",
    img: "/assets/icon/gestao_vendas/hotel.svg",
    imgActive: "/assets/icon/gestao_vendas/hotel_white.svg",
    id: 17,
  },
  {
    nome: "Estádios",
    img: "/assets/icon/gestao_vendas/estadio.svg",
    imgActive: "/assets/icon/gestao_vendas/estadio_white.svg",
    id: 7,
  },
  {
    nome: "Escolas",
    img: "/assets/icon/gestao_vendas/escola.svg",
    imgActive: "/assets/icon/gestao_vendas/escola_white.svg",
    id: 4,
  },
  {
    nome: "Food Park",
    img: "/assets/icon/gestao_vendas/food.svg",
    imgActive: "/assets/icon/gestao_vendas/food_white.svg",
    id: 30,
  },
];

export const equipamentos = [
  {
    nome: "POS",
    img: "/assets/img/gestao_vendas/pos.png",
    saas: true,
    geral: true,
    target: 'Comprar maquininha',
  },
  {
    nome: "Terminal",
    img: "/assets/img/gestao_vendas/terminal.png",
    saas: true,
    geral: false
  },
  {
    nome: "Totem",
    img: "/assets/img/gestao_vendas/totem.png",
    saas: false,
    geral: true
  },
  {
    nome: "Tablet",
    img: "/assets/img/gestao_vendas/tablet.png",
    saas: false,
    geral: true

  },
  {
    nome: "Validador",
    img: "/assets/img/gestao_vendas/validador.png",
    saas: true,
    geral: true
  },
];

export const aplicativo = [
  {
    nome: "Aplicativo Meep",
    img: "/assets/img/gestao_vendas/aplicativo.png",
  },
  {
    nome: "Soluções QR Code",
    img: "/assets/img/gestao_vendas/qr_code.png",
  },
];

export const date = [
  {
    day: "Segunda-feira",
    horas: [],
  },
  {
    day: "Terça-feira",
    horas: [],
  },
  {
    day: "Quarta-feira",
    horas: [],
  },
  {
    day: "Quinta-feira",
    horas: [],
  },
  {
    day: "Sexta-feira",
    horas: [],
  },
  {
    day: "Sábado",
    horas: [],
  },
  {
    day: "Domingo",
    horas: [],
  },
];

export const tipoLocal = (id?: number) => {
  switch (id) {
    case 1:
      return "Bares";
    case 9:
      return "Restaurantes";
    case 2:
      return "Baladas";
    case 8:
      return "Eventos / Shows";
    case 17:
      return "Hotéis";
    case 7:
      return "Estádios";
    case 4:
      return "Escolas";
    case 30:
      return "Food Park";
    default:
      return "";
  }
};
