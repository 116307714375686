import { IAddMultiplePromotersRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IAccessService } from '../../interfaces/IAccessService';

const AddMultiplePromotersToEventUseCase = async (
  accessService: IAccessService,
  promoters: IAddMultiplePromotersRequest,
  localId: string,
): Promise<void> => {
  return await accessService.addMultiplePromotersToEvent(promoters, localId);
};

export default AddMultiplePromotersToEventUseCase;
