import { ICategoryProducts } from "modules/cashlessDashboard/presentation/components/categories/interfaces/ICategory";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetCategoryProductsUseCase = async (service: ICashlessDashboardService, params: IUseCaseParams, categoryId: string): Promise<ICategoryProducts> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getCategoryProducts(filterRequest, categoryId);

  return response;
}

export default GetCategoryProductsUseCase
