export enum IMG {
  PNG = "image/png",
  JPEG = "image/jpeg",
  GIF = "image/gif",
}

const imageLoad = (fileReader: FileReader): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.src = fileReader.result as string;
    image.onload = () => {
      var height = image.naturalHeight;
      var width = image.naturalWidth;
      return resolve({ height, width });
    };
  });
}

const readAsDataURL = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = async () => {
      const { height, width } = await imageLoad(fileReader);
      return resolve({ width, height });
    };
    fileReader.readAsDataURL(file);
  });
}

/**
 *
 * @param image File to be validated
 * @param types Accepted types - PNG | JPEG | GIF - enum IMG
 * @param maxSize Image max size in Mega bytes
 * @param maxWidth Image max width in pixel
 * @param maxHeight Image max height in pixel
 * @returns
 * isValid: bollean
 * &
 * errorMessage: string
 */
export const imageValidation = async (
  image: File,
  types: IMG[],
  maxSize: number,
  maxWidth: number,
  maxHeight: number
) => {
  let isValid = false;
  let errorMessage = "";
  const MB = 1000000;

  if (types.map((it) => it.toString()).includes(image.type)) {
    if (image.size <= maxSize * MB) {
      const { height, width } = await readAsDataURL(image);

      if (height > maxHeight || width > maxWidth) {
        errorMessage = `Imagem excede a resolução máxima de ${maxWidth}x${maxHeight} pixels`;
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      errorMessage = `Imagem excede o tamanho limite de ${maxSize}Mb`;
    }
  } else {
    isValid = false;
    errorMessage = "Tipo de arquivo";
  }

  return {
    isValid,
    errorMessage,
  };
};
