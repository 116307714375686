import { ISort } from "interfaces/ISort"

export interface IDiscountCouponFilter {
    sort?: ISort
    ownerId?: string,
    code?: string,
    valueType?: EnumValueType,
    createdStartdAt?: string,
    createdEndAt?: string,
    valueMin?: number,
    valueMax?: number,
    startAt?: string,
    finishAt?: string

}

export enum EnumValueType {
    PERCENT = 1,
    ABSOLUTE,
}