import { ISupplyGroupValues } from 'modules/meepErp/presentation/components/supply/suplyGroup/suplyGroupForm/SupplyGroupForm';
import SupplyGroupService from 'modules/meepErp/services/api/Supply/SupplyGroup/SupplyGroupService';
import { ISupplyGroupList } from 'modules/meepErp/presentation/components/supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup';
import { ISupplyGroupFilterValue } from 'modules/meepErp/presentation/components/supply/suplyGroup/supplyGroupList/supplyGroupListFilter/ISupplyGroupFilter';
import { IGetSupplyGroupListResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyGroupListDtos';
import { IGetSupplyNameRequest } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos';

export const getSupplyGroupUseCase = (id: string) => {
    const service = SupplyGroupService();
    return service.getSupplyGroup(id);
};
export const getSupplyGroupListUseCase = async (filter: ISupplyGroupFilterValue): Promise<IGetSupplyGroupListResponse> => {
    const service = SupplyGroupService();
    const request = {
        page: 1,
        pageSize: 20,
        parentId: filter.parentId === '' ? undefined : filter.parentId,
        ...filter,
        orderBy: filter.orderBy ?? 'code',
        ascending: filter.ascending === undefined ? true : filter.ascending,
        code: filter.code || filter.name
        // name: ;
        //   orderBy?: string[];
        //   ascending?: boolean;
    };
    const response = await service.getSupplyGroupsList(request);
    return response;
    // console.log('.')
    // return {} as ISupplyGroupList;
};
export const AddSupplyGroupUseCase = async (values: ISupplyGroupValues) => {
    const service = SupplyGroupService();
    const request = {
        ...values
    };
    if (values.parentId === '') {
        delete request.parentId;
    }
    return await service.createSupplyGroup(request);
};
export const UpdateSupplyGroupUseCase = async (id: string, values: ISupplyGroupValues) => {
    const service = SupplyGroupService();
    const request = {
        ...values
    };
    if (values.parentId === '') {
        delete request.parentId;
    }
    return await service.updateSupplyGroup({ id, ...request });
};


export const DeleteSupplyGroupUseCase = async (id: string) => {
    const service = SupplyGroupService();
    return await service.deleteSupplyGroup(id);
}

export const EnableSupplyGroupUseCase = async (id: string, isEnabled: boolean) => {
    const service = SupplyGroupService();
    if (isEnabled) {
        return await service.enableSupplyGroup(id);
    } else {
        return await service.disableSupplyGroup(id);
    }
}

export const searchNameSupplyGroupUseCase = async (filter: IGetSupplyNameRequest) => {
    const service = SupplyGroupService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const request: IGetSupplyNameRequest = {
        page: page,
        pageSize: pageSize,
        keyword: filter.keyword

    };
    return service.searchNameSupplyGroup(request);
};
