import { capitalize } from "services/utils/String";
import { IIntegrationService } from "../../domain/dto/IIntegrationService";
import { IOrganizer } from "../../presentation/interfaces/IOrganizer";

export const GetOrganizerByDocumentUseCase = async (service: IIntegrationService, localId: string): Promise<IOrganizer | null> => {
    const response = await service.getOrganizerByDocument(localId);

    if (!response.isSuccess) return null

    const result = { ...response.data! }
    
    if (!response.data?.fullAddress) {
        result.fullAddress = capitalize(response.data?.street) + ' ' + capitalize(response.data?.number) + ', ' + capitalize(response.data?.neighborhood) + ' - ' + capitalize(response.data?.city) + '/' + response.data?.state.toUpperCase();
    }

    return result;
}