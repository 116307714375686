import styles from "./CrmConfigTermsStep.module.scss";
import { Checkbox } from "@material-ui/core";
import React from "react";
import { CampaignChannel } from "modules/crm/presentation/intefaces/ICampaign";

export interface Props {
  onHandleContract: () => void;
  email?: string,
  price?: number,
  channel?: CampaignChannel
}

const CrmConfigWhatsAppStepCrmConfigTermsStep = ({ onHandleContract, price, email, channel }: Props) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <div className={styles.content}>
      {price && <p className={styles.text}>
        {/* Você irá receber um email para {email} para que seja realizada a
        confirmação da contratação da ferramenta.  */}
        Tal contratação irá gerar uma
        cobrança de{" "}
        {price?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
          .replace(/\s/g, "")}
        , mensal e um aditivo no seu contrato junto à Meep.
      </p>}
      <div className={styles.buttonContainer}>
        <div className={styles.contentTerm}>
          <Checkbox
            color="primary"
            size="small"
            className={styles.checkbox}
            checked={checked}
            onClick={() => setChecked(!checked)}
          />
          <p className={styles.term}>
            Concordo com nossos{" "}
            <a className={styles.link} href="/">
              termos de uso
            </a>{" "}
            e com a cobrança mensal de{" "}
            {price?.toLocaleString("pt-br", { style: "currency", currency: "BRL", }).replace(/\s/g, "")}{" "}
            para enviar campanhas via {channel === CampaignChannel.email ? "e-mail" : "whatsapp"}
          </p>
        </div>
        <button
          className={styles.buttonContract}
          disabled={checked ? false : true}
          onClick={() => onHandleContract()}
        >
          Contratar
        </button>
      </div>
    </div>
  );
};

export default CrmConfigWhatsAppStepCrmConfigTermsStep;
