
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { IOperation } from "../interfaces/IAddPermutionAccount";
import { UpdateOperationUseCase } from "modules/permutation/application/useCases/UpdateOperationUseCase";

const UseUpdateOperation = () => {

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successAdd, setSuccessAdd] = useState(false)
  

  const postUpdateOperation = useCallback(
    async (accountId: string, values: IOperation) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingAdd(true);
        if (currentLocal) {
          const response = await UpdateOperationUseCase(servicePermutation,
            accountId,
            values,
            currentLocal.id,
            );
            toast("Atualizado com sucesso", "success")        
            setSuccessAdd(true)
          return response
        }
      }  catch {
        setSuccessAdd(false)
      }
      
      finally {
        setIsLoadingAdd(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessAdd,
    successAdd,
    isLoadingAdd,
    postUpdateOperation,   

  };
};

export default UseUpdateOperation;
