export enum PermissionsErp {
    ERP_ESTOQUE_CENTRAL_LANCAMNETO_CADASTRAR = 'ErpEstoqueCentralLancamentoCadastrar',
    ERP_ESTOQUE_CADASTROS_CADASTRAR = 'ErpEstoqueCadastrosCadastrar',
    ERP_ESTOQUE_POSICIONAMENTO_CADASTRAR ='ErpEstoquePorcionamentoCadastrar',
    ERP_ESTOQUE_FICHA_TECNICA_CADASTRAR ='ErpEstoqueFichaTecnicaCadastrar',
    ERP_ESTOQUE_INVENTARIO_CADASTRAR ='ErpEstoqueInventarioCadastrar',
    ERP_ESTOQUE_CADASTRAR ='ErpEstoqueInventarioCadastrar',
  }
  

  