import { IUpdateFidelityRequest } from "modules/fidelity/domain/dtos/IUpdateFidelityRequest";
import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService"
import { IFidelityNameFormValue } from "modules/fidelity/presentation/components/forms/name/FidelityNameForm";
import { IFidelityCustomizationFormValue } from "modules/fidelity/presentation/components/forms/settings/FidelityCustomizationForm";

const UpdateCustomizationFidelityLevelUseCase = async (service: IFidelityService, ownerId: string, fidelityId: string, values: IFidelityCustomizationFormValue): Promise<IFidelityNameFormValue> => {
    const request: IUpdateFidelityRequest = {
        customization: values
    }
    var response = await service.updateFidelityLevel(ownerId, fidelityId, request);
    return response
}

export default UpdateCustomizationFidelityLevelUseCase