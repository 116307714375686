
import { Icon, IconButton } from '@material-ui/core';
import { Download } from '@mui/icons-material';
import { EnumExportType } from 'interfaces/EnumExport';
import TabView from 'modules/config/deviceConfig/presentation/components/tab/TabView';
import React, { useCallback, useEffect, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money';
import Utils from 'services/utils/Utils';
import UseHouseFlowDetailsItem, { DetailsItemParamsClientHouseFlow } from '../../hooks/UseHouseFlowDetails';
import UseHouseFlowExport from '../../hooks/UseHouseFlowExport';
import { IHouseFlow, IOrderPadsItem } from '../../interfaces/IHouseFlow';
import ConsumptionList from './components/consumptionList/ConsumptionList';

import styles from './HouseFLowSideSheetDetails.module.scss';

export interface IHouseFLowSideSheetDetailsProps {
    item: IOrderPadsItem;
    houseFlowList: IHouseFlow;
    closeDrown: () => void
}

export enum EnumDetailsStep {
    RESUMO = 0,
    CONSUMO
}

const HouseFLowSideSheetDetails: React.FC<IHouseFLowSideSheetDetailsProps> = ({ item, houseFlowList, closeDrown }) => {

    const { getHouseFlowDetailsItem, detailsItem, isLoading, count } = UseHouseFlowDetailsItem();

    const { postExportHouseFlow } = UseHouseFlowExport();

    const [step, setStep] = useState<EnumDetailsStep>(EnumDetailsStep.RESUMO);

    const [params, setParams] = useState<DetailsItemParamsClientHouseFlow>();

    const [currentPage, setCurrentPage] = useState(0);




    useEffect(() => {
        getHouseFlowDetailsItem({ ...params, cardId: item.cardId });
    }, [getHouseFlowDetailsItem, item.cardId, params])


    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        setParams({ cardId: item.cardId, pagination: { page: currentPage + 1, pageSize: 20 } })
    }, [currentPage, item.cardId]);


    const onChangeFormStep = useCallback((value: EnumDetailsStep) => {
        setStep(value);
    }, []);

    return (

        <div id={styles.HouseFLowSideSheetDetails}>
            <div className={styles.header}>
                <div className={styles.comand}>
                    <h2>Comanda  <b> {item.cardNumber}</b></h2>
                    <IconButton size='small' onClick={closeDrown}><Icon>close</Icon></IconButton>
                </div>
                <div className={styles.date}>
                    <span>Abertura: {Utils.toDateAndTime(houseFlowList.cashierOpenDate)}</span>
                    <span onClick={() => postExportHouseFlow({ cardId: item.cardId, exportFormat: EnumExportType.EXCEL })} className={styles.icon}>Exportar <Download /></span>
                </div>
            </div>
            <div className={styles.tab}>
                <TabView
                    index={step}
                    tabs={
                        [
                            { label: 'Resumo', value: EnumDetailsStep.RESUMO },
                            { label: 'Consumo', value: EnumDetailsStep.CONSUMO },
                        ]
                    }
                    onChangeTab={(tab, value) => onChangeFormStep?.(value)}
                />

            </div>
            <div className={styles.container}>
                {
                    step === EnumDetailsStep.RESUMO &&
                    <div className={styles.content}>
                        <p>Total consumido <span> {moneyMaskNumber(item.cardTotalNet)}</span></p>
                        <p>Total de desconto<span>{moneyMaskNumber(item.cardTotalDiscounts)}</span></p>
                        <p>Total da taxa de serviço<span>{moneyMaskNumber(item.cardTotalService)}</span></p>
                    </div>
                }
                {
                    step === EnumDetailsStep.CONSUMO &&
                    <div className={styles.content}>
                        <ConsumptionList
                            consumptionList={detailsItem}
                            params={params}
                            getMore={getMore}
                            isLoading={isLoading}
                            count={count}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default HouseFLowSideSheetDetails