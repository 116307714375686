import { IAddPermutationAccount } from "modules/permutation/presentation/interfaces/IAddPermutionAccount";
import { IOperationErros } from "modules/permutation/presentation/interfaces/IPermutationFormErros";

export const validationPermutationItemForm = (formData: IAddPermutationAccount, setErrors: React.Dispatch<React.SetStateAction<IOperationErros>>) => {
  let _errors: IOperationErros = {};
  let hasError = false;
  if (!formData.operation.reason) {
    _errors = { ..._errors, reason: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.operation.value) {
    _errors = { ..._errors, value: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};
