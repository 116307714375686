import { Drawer, Icon, IconButton } from '@material-ui/core';
import styles from './DeleteModal.module.scss';

interface DeleteMoralProps {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export const DeleteModal = ({ open, loading, onClose, onDelete }: DeleteMoralProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Excluir <b>&nbsp;publicação</b></h1> <IconButton onClick={onClose}><Icon>close</Icon></IconButton>
                </header>
                <div className={styles.alertBox}>
                    <img src="/assets/img/alert.png" alt="" />
                    <span>Deseja realmente excluir a <strong>publicação selecionada?</strong></span>
                </div>

                <div className={styles.buttonsContainer}>
                    <div>
                        <button className={styles.outlinedButton} onClick={onClose}>Fechar</button>
                    </div>
                    <div>
                        <button disabled={loading} onClick={onDelete} type='button'>{
                            loading ? 'Deletando...' :
                                'Confirmar'
                        }</button>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}