import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { useState } from "react";
import styles from "./ChangeTaxDataModal.module.scss";

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (taxData: { cfop: string; cest: string; ncm: string }) => void;
}

export const ChangeTaxDataModal = ({
  open,
  title,
  onClose,
  onConfirm,
}: ModalProps) => {
  const [cfop, setCfop] = useState("");
  const [cest, setCest] = useState("");
  const [ncm, setNcm] = useState("");
  const [errors, setErrors] = useState<{
    cfop?: string;
    cest?: string;
    ncm?: string;
  }>();

  const handleValidate = () => {
    setErrors(undefined);
    let hasError = false;
    if (!cfop) {
      setErrors((prev) => ({ ...prev, cfop: "Campo obrigatório" }));
      hasError = true;
    } else if (!cfop.match(/[0-9]{1}\.[0-9]{3}/)) {
      setErrors((prev) => ({
        ...prev,
        cfop: "O CFOP deve estar no padrão 9.999",
      }));
      hasError = true;
    }

    if (!cest) {
      setErrors((prev) => ({ ...prev, cest: "Campo obrigatório" }));
      hasError = true;
    } else if (!cest.match(/[0-9]{2}\.[0-9]{3}\.[0-9]{2}/)) {
      setErrors((prev) => ({
        ...prev,
        cest: "O CEST deve estar no padrão 99.999.99",
      }));
      hasError = true;
    }

    if (!ncm) {
      setErrors((prev) => ({ ...prev, ncm: "Campo obrigatório" }));
      hasError = true;
    } else if (!ncm.match(/[0-9]{4}\.[0-9]{2}\.[0-9]{2}/)) {
      setErrors((prev) => ({
        ...prev,
        ncm: "O NCM deve estar no padrão 9999.99.99",
      }));
      hasError = true;
    }

    if (!hasError) onConfirm({ cfop, cest, ncm });
  };

  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.form}>
          <FormItemContainer label={"CFOP"} className={styles.formContainer}>
            <Input
              error={!!errors?.cfop}
              helperText={errors?.cfop}
              variant="outlined"
              required
              inputProps={{ maxLength: 5 }}
              value={cfop}
              onChange={(e) => setCfop(e.target.value)}
            />
          </FormItemContainer>
          <FormItemContainer label={"CEST"} className={styles.formContainer}>
            <Input
              error={!!errors?.cest}
              helperText={errors?.cest}
              variant="outlined"
              required
              inputProps={{ maxLength: 9 }}
              value={cest}
              onChange={(e) => setCest(e.target.value)}
            />
          </FormItemContainer>
          <FormItemContainer label={"NCM"} className={styles.formContainer}>
            <Input
              error={!!errors?.ncm}
              helperText={errors?.ncm}
              variant="outlined"
              required
              inputProps={{ maxLength: 10 }}
              value={ncm}
              onChange={(e) => setNcm(e.target.value)}
            />
          </FormItemContainer>
        </div>
        <div className={styles.buttonsContainer}>
          <Button variant="outlined" fullWidth={false} onClick={onClose}>
            Cancelar
          </Button>
          <Button fullWidth={false} onClick={handleValidate}>
            Salvar
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
