import { IProfileConfigurationRequest } from "modules/app/domain/dtos/IProfileConfigurationRequest";
import { api } from "../Api";
import { IAppService } from "modules/app/domain/interfaces/IAppService";
import { IProfileConfigurationResponse } from "modules/app/domain/dtos/IProfileConfigurationResponse";

const AppApi = (): IAppService => {
  const saveProfileConfiguration = async (request: IProfileConfigurationRequest): Promise<void> => {
    return await api.post(`/app/profileconfiguration`, request, { params: { disableError: true } });
  };

  const getProfileConfiguration = async (localId: string): Promise<IProfileConfigurationResponse> => {
    const response = await api.get(`/app/profileconfiguration/${localId}`, { params: { disableError: true } });
    return response.data;
  };

  const verifyProfileExists = async (localId: string): Promise<boolean> => {
    const response = await api.get(`/app/CheckProfileConfiguration/${localId}`, { params: { disableError: true } });
    return response.data;
  };

  return {
    saveProfileConfiguration,
    getProfileConfiguration,
    verifyProfileExists
  };
};

export default AppApi;
