import UseDimension from "components/dimension/UseDimension";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { AprovacaoTIDsSideSheetHandles, SideSheetType } from "../components/aprovacaoTIDsSideSheet/AprovacaoTIDsSideSheet";
import { UseTIDsList } from "../hooks/UseTIDsList";
import { IAprovacaoTIDsColumns } from "../interfaces/IAprovacaoTIDsColumns";


export const UseAprovacaoTIDs = () => {
  const { dimensions } = UseDimension();
  const { isMobile } = useUi();
  const { getTIDsList, isLoading, tidsList } = UseTIDsList();

  const sideSheetRef = useRef<AprovacaoTIDsSideSheetHandles>(null);

  const [columns, setColumns] = useState<IAprovacaoTIDsColumns[]>([
    {
      property: "UsuarioName",
      title: "Usuário",
    },
    {
      property: "MerchantDocument",
      title: "Documento",
    },
    {
      property: "AcquirerMerchantId",
      title: "Mid",
    },
    {
      property: "Tids",
      title: "TIDs",
    },
    {
      property: "Status",
      title: "Status",
    },
    {
      property: "CreatedAt",
      title: "Criado em",
    },
    {
      property: "CompletedAt",
      title: "Aprovado em",
    },
  ]);

  const columnsLimit = useCallback(
    (_columns: IAprovacaoTIDsColumns[], width: number) => {
      if (width > 0) {
        const max =
          width > 0 && width <= 600
            ? 2
            : width > 600 && width <= 800
            ? 4
            : width > 800 && width <= 1024
            ? 5
            : 7;
        let count = 0;
        return _columns.map((it) => {
          if (!it.hide) {
            count++;
            if (count > max) {
              return { ...it, hide: true };
            } else {
              return it;
            }
          } else {
            return { ...it, hide: false };
          }
        });
      } else {
        return _columns;
      }
    },
    []
  );

  useEffect(() => {
    setColumns((prev) => columnsLimit(prev, dimensions.width));
  }, [columnsLimit, dimensions.width]);

  const openSidesheet = useCallback((id: string, type: SideSheetType) => {
    sideSheetRef.current?.openSideSheet(id, type);
  }, []);

  useEffect(() => {
    getTIDsList();
  }, [getTIDsList])
  

  return {
    isMobile,
    sideSheetRef,
    columns,
    openSidesheet,
    isLoading,
    tidsList,
    getTIDsList
  }
}