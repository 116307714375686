import { IKDSService } from "modules/kds/domain/interfaces/IKDSService";
import { IDeviceSelect } from "modules/kds/presentation/interfaces/IDeviceItem";



const GetDeviceListUseCase = async (service: IKDSService, localId: string): Promise<IDeviceSelect> => {

    const response = await service.getDeviceList(localId);
    return response
}


export default GetDeviceListUseCase