import { useCallback, useEffect, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetTrackConfigUseCase } from "modules/saas/application/useCases/GetTrackConfigUseCase";
import { ITrackConfig, ITrackConfigItem } from "../../interfaces/trackConfig/ITrackConfig";
import { SaasApi } from "services/api/saas/SaasApi";
import { ITrackConfigForm } from "../../interfaces/trackConfig/ITrackConfigForm";
import { SaveTrackConfigUseCase } from "modules/saas/application/useCases/SaveTrackConfigUseCase";

const service = SaasApi();

type IFilterType = { keyword?: string };

export const UseTrackConfig = () => {
  const { toast } = useUi();

  const [selectedConfig, setSelectedConfig] = useState<ITrackConfigItem>();
  const [config, setConfig] = useState<ITrackConfig>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({} as ITrackConfigForm);
  const [modalOpened, setModalOpened] = useState(false);
  const [filter, setFilter] = useState<IFilterType>({
    keyword: '',
  });

  const getData = useCallback(async (page?: number, _filter?: IFilterType) => {
    try {
      setLoading(true);
      const response = await GetTrackConfigUseCase(service, page || 1, _filter?.keyword);
      setConfig(response);
      setCurrentPage(page || 1);
    } catch {
      toast('Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    getData(1);
  }, [getData]);

  const handleSubmit = async () => {
    try {
      await SaveTrackConfigUseCase(service, selectedConfig!.id, form);
    } catch {
      toast('Houve um erro ao salvar os dados. Tente novamente.', 'error');
    }
  }

  return {
    selectedConfig,
    config,
    currentPage,
    loading,
    filter,
    form,
    modalOpened,
    setModalOpened,
    setForm,
    setFilter,
    setSelectedConfig,
    getData,
    handleSubmit
  }
}
