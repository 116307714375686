import { AxiosInstance } from "axios"
import { IGetOperadorByName } from "modules/operators/application/dtos/IGetOperadorByName";
import { SaveOpertaorRequest as SaveOperatorRequest } from "modules/operators/application/dtos/SaveOpertaorRequest";
import { IOperatorItem } from "modules/operators/domain/models/IOperatorItem";

const OperatorAnticorruption = (api: AxiosInstance) => {
    const getOperatorList = async (localId: string) => {
        const response = await api.get<OperadorResponse[]>(`Proprietario/BuscarOperadoresDoLocal/${localId}`);
        return response.data.map(item => operadorToOperatorItem(item));
    }
    const addOperator = (localId: string, operator: SaveOperatorRequest): Promise<void> => {
        const request = requestOperatorToOperador(localId, operator);
        return api.post(`Proprietario/AdicionarOperador`, request);
    }
    const editOperator = (localId: string, operator: SaveOperatorRequest): Promise<void> => {
        const request = requestOperatorToOperador(localId, operator);
        return api.post(`Proprietario/EditarOperador`, request);
    }
    const deleteOperator = (localId: string, id: string): Promise<void> => {
        return api.post(`Proprietario/RemoverOperador/${id}`);
    }
    const getOperatorByName = async (localId: string, operator: string) => {
        const response = await api.get<IGetOperadorByName[]>(`Proprietario/BuscarOperadores`, { params: {
          localId: localId,
          keyword: operator
        }});
        return response.data;
    }
    return ({
      getOperatorList,
      addOperator,
      editOperator,
      deleteOperator,
      getOperatorByName
    })
}
export default OperatorAnticorruption




const operadorToOperatorItem = (operador: OperadorResponse): IOperatorItem => {
    return {
        id: operador.id,
        name: operador.nome,
        password: operador.senha ?? "",
        privilegedAccess: operador.acessoPrivilegiado,
        roles: operador.papeis,
        commission: operador.comissao ?? 0,
        useCashlessCardAsPassword: operador.usarCartaoCashlessComoSenha,
        tag: operador.tag ?? ""
    }
}

interface OperadorResponse {
    id: string;
    nome: string;
    senha: string | null;
    acessoPrivilegiado: boolean;
    papeis: string[];
    comissao: number | null;
    usarCartaoCashlessComoSenha: boolean;
    tag: string | null;
}



interface SalvarOperadorRequest {
    id: string | null;
    nome: string;
    senha: string;
    confirmarSenha: string;
    localId: string;
    comissao: number;
    papeis: string[];
    acessoPrivilegiado: boolean;
    usarCartaoCashlessComoSenha: boolean;
    tag: string | null;
}

const requestOperatorToOperador = (localId: string, request: SaveOperatorRequest): SalvarOperadorRequest => {
    return {
        id: request.id ?? null,
        nome: request.name,
        senha: request.password,
        confirmarSenha: request.password,
        localId: localId,
        comissao: request.commission,
        papeis: request.roles,
        acessoPrivilegiado: request.privilegedAccess,
        usarCartaoCashlessComoSenha: request.useCashlessCardAsPassword,
        tag: request.tag
    }
}
