import React from 'react';
import { IActionButtons } from './Item/Item';
import styles from './List.module.scss';
import { Icon, IconButton } from '@material-ui/core';
import { IListValues, ColumnConfigs, ColumnConfigsItem } from './List';
import { IListItem } from './interfaces/IList';


export interface IListHeaderProps<T extends IListItem> {
    enableDnd: boolean | undefined;
    listValues: (T & IListValues)[];
    columnsConfigs?: ColumnConfigs<T>
    onClickSortHandler: (property: string) => void; actionButtons: IActionButtons<T>[] | undefined;
    renderAccordion?: boolean
}
export const ListHeader = <T extends IListItem,>({
    enableDnd, listValues, columnsConfigs, onClickSortHandler, actionButtons, renderAccordion
}: IListHeaderProps<T>
) => {
    return <div className={styles.header}>
        {enableDnd && <Icon className={styles.dragIcon}>drag_handle</Icon>}
        {!!listValues.length && Object.keys(columnsConfigs ?? listValues[0]).map((key, index) => {
            if (columnsConfigs?.hasOwnProperty("imageUrl") && key === "imageUrl" && !columnsConfigs?.[key]?.hide) {
                return (<span
                    key={key}
                    className={styles.title}
                    style={{ maxWidth: columnsConfigs[key]?.width ? columnsConfigs[key]?.width?? 76 : 76 }}
                >
                    {!columnsConfigs[key]?.hideTitle ? columnsConfigs[key]?.title : ""}
                </span>
                );
            }
            if (columnsConfigs?.hasOwnProperty(key) && key !== "id" && !columnsConfigs?.[key]?.hide) {
                return (
                    <div
                        className={styles.title}
                        key={key}

                        style={{ maxWidth: columnsConfigs[key]?.width ?? "unset" }}
                    >
                        <span className={styles.text}>{!columnsConfigs[key]?.hideTitle ? columnsConfigs[key]?.title : ""}</span>

                        <IconButton className={styles.sortButton} onClick={() => onClickSortHandler?.(key)} size='small'>
                            {columnsConfigs[key]?.sortable && <Icon fontSize={"small"}>swap_vert</Icon>}
                        </IconButton>
                    </div>
                );
            } else {
                return null;
            }
        })}
        {actionButtons &&
            <span style={{ width: actionButtons.length * 48 + (renderAccordion ? 48 : 0) }}></span>
        }
    </div>;
};
