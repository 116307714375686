import { RequestType } from "models/api/IApi";
import { api } from "../Api";
import { IRequestParams } from "modules/cashlessDashboard/domain/interfaces/RequestParams";
import ICashlessDashboardService from "modules/cashlessDashboard/domain/interfaces/ICashlessDashboardService";
import { IGetFinancialDataResponse } from "modules/cashlessDashboard/domain/dto/financialData/IGetFinancialDataResponse";
import { CashhlessDashboardMockTypes, CashlessDashboardMock } from "./mock";
import { getBuscarCaixas } from "./antiCorruption/BuscarCaixas";
import { IGetCashiersResponse } from "modules/cashlessDashboard/domain/dto/getCashiers/IGetCashiersResponse";
import {
  TicketPaymentDetailsTypes,
} from "modules/cashlessDashboard/domain/dto/rechargePaymentMethodsData/IGetRechargePaymentMethodsDataResponse";
import { IGetRechargeOriginResponse } from "modules/cashlessDashboard/domain/dto/rechargeOrigin/IGetRechargeOriginResponse";
import { IGetAverageTicketResponse } from "modules/cashlessDashboard/domain/dto/averageTicket/IGetAverageTicketResponse";
import { IGetCanceledResponse } from "modules/cashlessDashboard/domain/dto/canceled/IGetCanceledResponse";
import { IGetCardsActivationResponse } from "modules/cashlessDashboard/domain/dto/cardsActivation/IGetCardsActivationResponse";
import { IGetTopOperatorsResponse } from "modules/cashlessDashboard/domain/dto/topOperators/IGetTopOperatorsResponse";
import { IGetTopCustomersResponse } from "modules/cashlessDashboard/domain/dto/topCustomers/IGetTopCustomersResponse";
import { IGetTopDevicesResponse } from "modules/cashlessDashboard/domain/dto/topDevices/IGetTopDevicesResponse";
import { IGetTopProductsResponse } from "modules/cashlessDashboard/domain/dto/topProducts/IGetTopProductsResponse";
import {
  IGetCategoriesResponse,
  IGetCategoryProductsResponse,
} from "modules/cashlessDashboard/domain/dto/categories/IGetCategoriesResponse";
import { IOriginType } from "modules/cashlessDashboard/presentation/components/rechargeOrigin/interfaces/IOriginType";
import { ProductionInfoResponse } from "modules/cashlessDashboard/domain/dto/productionInfo/getProductionInfoResponse";
import { IGetPreRechargeMethodsDataResponse } from "modules/cashlessDashboard/domain/dto/preRechargePaymentMethodsData/IGetPreRechargeMethodsDataResponse";

const mock = CashlessDashboardMock();
const useMock = false;

const CashlessDashboardService = (): ICashlessDashboardService => {
  /*
    Método utilizado apenas para montagem da query PADRÃO.
    Não alterar para casos específicos
    */
  const makeQuery = (request: IRequestParams) => {
    const unifyArray = (prop: string, array: string[]) => {
      return array.map((item) => `&${prop}=${item}`).join("");
    };

    let query = "?" + unifyArray("cashierIds", request.cashierIds);
    query += request.sort?.type ? `&sort.type=${request.sort?.type}` : "";
    query += request.sort?.orientation
      ? `&sort.orientation=${request.sort?.orientation}`
      : "";
    query += `&page=${request.page || 0}`;
    query += `&pageSize=${request.pageSize || 20}`;

    if (request.shouldUseNewSchema)
      query += `&shouldUseNewSchema=${request.shouldUseNewSchema}`;

    if (request.ownerId)
      query += `&ownerId=${request.ownerId}`;

    return query;
  };

  /*
    Método base de request.
    Todos os requests que forem seguir o mesmo padrão devem OBRIGATORIAMENTE utilizar esse método
    */
  async function executeRequest<T>(
    requestType: RequestType,
    request: IRequestParams,
    uri: string,
    params?: any
  ): Promise<T> {
    const query = makeQuery(request);

    const result = await api[requestType]<T>(
      `dashboard/cashless/${uri}${RequestType.GET ? query : ""}`,
      { params: { disableError: true, ...params } }
    );

    return result.data;
  }

  // Caso queira fazer um tratamento padrão de erros, fazer aqui
  const catchError = (err: any) => {
    throw new Error(err);
  };

  const getFinancialData = async (
    request: IRequestParams
  ): Promise<IGetFinancialDataResponse> => {
    let response = {} as IGetFinancialDataResponse;

    try {
      response = useMock
        ? await mock.makeMock<IGetFinancialDataResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_FINANCIAL_DATA](request)
          )
        : await executeRequest<IGetFinancialDataResponse>(
            RequestType.GET,
            request,
            "financial"
          );
    } catch (err: any) {
      catchError(err);
    }

    return response;
  };

  const getProduction = async (
    request: IRequestParams
  ): Promise<ProductionInfoResponse> => {
    let response = {} as ProductionInfoResponse;

    try {
      response = await executeRequest<ProductionInfoResponse>(
        RequestType.GET,
        request,
        "ProductionResume"
      );
    } catch (err: any) {
      catchError(err);
    }

    return response;
  };

  const getRechargePaymentMethodsData = async (
    request: IRequestParams
  ): Promise<TicketPaymentDetailsTypes> => {
    let response = {} as TicketPaymentDetailsTypes;
    try {
      response = await executeRequest<TicketPaymentDetailsTypes>(
            RequestType.GET,
            request,
            "AccountPaymentDetails"
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getRechargeMethodsData = async (
    request: IRequestParams
  ): Promise<TicketPaymentDetailsTypes> => {
    let response = {} as TicketPaymentDetailsTypes;
    try {
      response = await executeRequest<TicketPaymentDetailsTypes>(
        RequestType.GET,
        request,
        "TicketPaymentDetails",
      );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getPreRechargeMethodsData = async (
    cashierIds: string[]
  ): Promise<IGetPreRechargeMethodsDataResponse> => {
    let response = {} as IGetPreRechargeMethodsDataResponse;
    try {
      const res = await api.get<IGetPreRechargeMethodsDataResponse>("dashboard/cashless/preRechargeResume", {
        params: { cashierIds }
      });
      response = res.data;
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getRechargeOrigin = async (
    request: IRequestParams
  ): Promise<IGetRechargeOriginResponse> => {
    let response = {} as IGetRechargeOriginResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetRechargeOriginResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_RECHARGE_ORIGIN](request)
          )
        : await executeRequest<IGetRechargeOriginResponse>(
            RequestType.GET,
            request,
            "rechargeOrigin"
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getAverageTicket = async (
    request: IRequestParams
  ): Promise<IGetAverageTicketResponse> => {
    let response = {} as IGetAverageTicketResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetAverageTicketResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_AVERAGE_TICKET](request)
          )
        : await executeRequest<IGetAverageTicketResponse>(
            RequestType.GET,
            request,
            "averageTicket"
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getCanceled = async (
    request: IRequestParams
  ): Promise<IGetCanceledResponse> => {
    let response = {} as IGetCanceledResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetCanceledResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_CANCELED](request)
          )
        : await executeRequest<IGetCanceledResponse>(
            RequestType.GET,
            request,
            "canceled"
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getCardsActivation = async (
    request: IRequestParams
  ): Promise<IGetCardsActivationResponse> => {
    let response = {} as IGetCardsActivationResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetCardsActivationResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_CARDS_ACTIVATION](request)
          )
        : await executeRequest<IGetCardsActivationResponse>(
            RequestType.GET,
            request,
            "cardsActivation"
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getTopOperators = async (
    request: IRequestParams,
    type: IOriginType,
    localId?: string
  ): Promise<IGetTopOperatorsResponse> => {
    let response = {} as IGetTopOperatorsResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetTopOperatorsResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_TOP_OPERATORS](request)
          )
        : await executeRequest<IGetTopOperatorsResponse>(
            RequestType.GET,
            request,
            "operators",
            { type, localId, exportFormat: request.exportFormat }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getTopCustomers = async (
    request: IRequestParams,
    type: IOriginType,
    localId?: string
  ): Promise<IGetTopCustomersResponse> => {
    let response = {} as IGetTopCustomersResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetTopCustomersResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_TOP_CUSTOMERS](request)
          )
        : await executeRequest<IGetTopCustomersResponse>(
            RequestType.GET,
            request,
            "customers",
            { type, localId, exportFormat: request.exportFormat }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getTopDevices = async (
    request: IRequestParams,
    type: IOriginType,
    localId?: string
  ): Promise<IGetTopDevicesResponse> => {
    let response = {} as IGetTopDevicesResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetTopDevicesResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_TOP_DEVICES](request)
          )
        : await executeRequest<IGetTopDevicesResponse>(
            RequestType.GET,
            request,
            "devices",
            { type, localId, exportFormat: request.exportFormat }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getTopProducts = async (
    request: IRequestParams,
    localId?: string
  ): Promise<IGetTopProductsResponse> => {
    let response = {} as IGetTopProductsResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetTopProductsResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_TOP_PRODUCTS](request)
          )
        : await executeRequest<IGetTopProductsResponse>(
            RequestType.GET,
            request,
            "products",
            { localId, exportFormat: request.exportFormat }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getCategories = async (
    request: IRequestParams,
    localId?: string
  ): Promise<IGetCategoriesResponse> => {
    let response = {} as IGetCategoriesResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetCategoriesResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_CATEGORIES](request)
          )
        : await executeRequest<IGetCategoriesResponse>(
            RequestType.GET,
            request,
            "categories",
            { localId, exportFormat: request.exportFormat }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getCategoryProducts = async (
    request: IRequestParams,
    categoryId: string
  ): Promise<IGetCategoryProductsResponse> => {
    let response = {} as IGetCategoryProductsResponse;
    try {
      response = useMock
        ? await mock.makeMock<IGetCategoryProductsResponse>(() =>
            mock[CashhlessDashboardMockTypes.GET_CATEGORY_PRODUCTS](request)
          )
        : await executeRequest<IGetCategoryProductsResponse>(
            RequestType.GET,
            request,
            "categories",
            { categoryId }
          );
    } catch (err: any) {
      catchError(err);
    }
    return response;
  };

  const getCashiers = async (
    localId: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<IGetCashiersResponse[]> => {
    return await getBuscarCaixas(api, localId, startDate, endDate);
  };

  return {
    getCashiers,
    getFinancialData,
    getRechargePaymentMethodsData,
    getRechargeOrigin,
    getAverageTicket,
    getCanceled,
    getCardsActivation,
    getTopOperators,
    getTopCustomers,
    getTopDevices,
    getTopProducts,
    getProduction,
    getRechargeMethodsData,
    getPreRechargeMethodsData,
    getCategories,
    getCategoryProducts,
  };
};
export default CashlessDashboardService;
