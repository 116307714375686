
import styles from './ObservationDevice.module.scss'
import { Info } from "@material-ui/icons";

const ObservationDevice = () => {
    return(
        <div className={`${styles.footerInfo} div-to-capture`}>
        <span><Info />Observação:</span>
        <p>1 - O valor de <b>venda cashless</b> não entra no total, uma vez que já está presente na recarga/consumo do cashless nas formas de pagamento Crédito/Débito/Dinheiro.</p>
        <p>2 - É considerado como <b>cancelado</b> apenas vendas que foram faturadas (pagas) e em seguida cancelada. <b>Cancelamento de itens</b> durante a operação de PÓS-PAGO antes do pagamento não são considerados aqui.</p>
        <p>3 - O Valor de <b>venda em aberto</b> se refere a contas fechadas manualmente com valor em aberto e não entra no valor total.</p>
    </div>
    )
}
export default ObservationDevice