import { IconButton } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import Button from 'components/ui/Button/Button';
import { TooltipInfo } from 'components/ui/tooltip/Tooltip';
import React from 'react'
import { CustomFieldCard } from '../../components/customFieldCard/CustomFieldCard';
import styles from './CustomFieldPage.module.scss'
import { UseCustomFieldPage } from './UseCustomFieldPage';

export const CustomFieldPage = () => {

    const { values, onSubmit, addQuantity, setValues } = UseCustomFieldPage();

    return (
        <div id={styles.CustomFieldPage}>
            <div className={styles.containerHelp}>

                <h2>Configuração de Campo Personalizado</h2>

                <TooltipInfo
                    title={"Configuração de Campo Personalizado"}
                    paragraph={"Preecher instruções"}
                />
            </div>

            <CustomFieldCard values={values} addQuantity={addQuantity} setValues={setValues} onSubmit={onSubmit}/>

        </div>
    )
}
