import { ChangeEvent, useCallback, useState } from "react";
import { IAddTIDForm, IAddTIDFormErrors } from "../../interfaces/ITIDsAddForm";
import ComplienceService from "services/api/complience/ComplienceService";
import CreateTIDsUseCase from "modules/tidsAdicionais/application/useCases/CreateTIDsUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { validate } from "../forms/addTIDs/AddTIDsValidation";

export const UseTIDsAdicionaisSideSheet = (
  getTIDsList: () => Promise<void>
) => {
  const { toast } = useUi();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState<IAddTIDForm>({
    AcquirerMerchantId: "",
    MerchantDocument: "",
    PayStoreAcquirerType: 3,
    QuantityTids: 0,
    Tids: "",
  });

  const [errors, setErrors] = useState<IAddTIDFormErrors>({
    MerchantDocument: "",
    AcquirerMerchantId: "",
    Tids: "",
    QuantityTids: "",
    PayStoreAcquirerType: "",
  });

  const handleChangeForm = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = ev.target.name;
      const value = ev.target.value;
      if (name === "Tids") {
        const splited =
          value?.length === 0
            ? 0
            : value?.split(";").filter((code) => code.trim() !== "");

        const QuantityTids = splited === 0 ? 0 : splited.length;
        setValues((prev) => ({
          ...prev,
          QuantityTids,
          Tids: value,
        }));
      } else if (name === "MerchantDocument") {
        setValues((prev) => ({
          ...prev,
          MerchantDocument: value.replace(/\D/g, ""),
        }));
      } else {
        setValues((prev) => ({ ...prev, [name]: value }));
      }
    },
    []
  );

  const openSideSheet = useCallback(async () => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setValues({
      AcquirerMerchantId: "",
      MerchantDocument: "",
      PayStoreAcquirerType: 3,
      QuantityTids: 0,
      Tids: "",
    });
    setErrors({
      AcquirerMerchantId: "",
      MerchantDocument: "",
      PayStoreAcquirerType: "",
      QuantityTids: "",
      Tids: "",
    });
    setIsOpen(false);
  }, []);

  const handleSave = useCallback(async () => {
    if (validate(values, setErrors)) {
      try {
        const service = ComplienceService();
        setIsLoading(true);
        await CreateTIDsUseCase(service, values);
        toast("Solicitação TIDs adicionada com sucesso", "success");
        getTIDsList();
        onClose();
      } finally {
        setIsLoading(false);
      }
    }
  }, [getTIDsList, onClose, toast, values]);

  return {
    isOpen,
    openSideSheet,
    onClose,
    isLoading,
    handleSave,
    values,
    handleChangeForm,
    errors,
  };
};
