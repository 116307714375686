import { request } from "services/api/Api";

import {
  AdvancedFiltersTypesCollection,
  GetAdvancedFilterListsProps,
} from "../types/GetAdvancedFilterLists.types";

const GetAdvanceFilterLists = async (props: GetAdvancedFilterListsProps) => {
  return await request<AdvancedFiltersTypesCollection>({
    url: "/Reports/Sales/AdvanceFilterLists",
    params: {
      ...props,
    },
  });
};

export { GetAdvanceFilterLists };
