import { ITurnoverTable } from "modules/salesDashboard/presentation/components/turnoverTable/interfaces/ITurnoverTable";
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "./MakeFilterRequest";

const GetTurnoverTableUseCase = async (service: ISalesDashboardService, localId: string, params: IUseCaseParams): Promise<ITurnoverTable> => {
  const filterRequest = makeFilterRequest(params);

  filterRequest.selectedPlaces = [localId];
  filterRequest.sort = {
    orientation: filterRequest.sort?.orientation!,
    type: filterRequest.sort?.type!
  };

  const response = await service.getTurnoverTable(filterRequest);

  response.color = response.color ? `#${response.color}` : '';

  return response;
}

export default GetTurnoverTableUseCase


