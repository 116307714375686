import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TabPage from '../dashboard/tabPage/TabPage'

export interface IRegistersPageProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    value?: string,
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        role: string;
    }[]
}

const RegistersPage: FC<IRegistersPageProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const currentTab = menuItems.find(item => tab === item.tab);
        const routers = [{
            label: "Cadastros",
            title: currentTab?.title
        }];

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.title : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.title
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters]);

    const menuItems:IMenuItem[] = [
        {
            title: "Clientes",
            value: "clientes",
            tab: "clientes",
            role: "",
            subItems: [
                {
                    label: "Listagem de clientes",
                    tab: "clientes",
                    url: "/cadastro/clientes",
                    role: "ClienteEstabelecimentoPortal"
                },
                // {
                //     label: "Categoria",
                //     tab: "categoria",
                //     url: "/cadastro/categoriaClientes",
                //     role: "CategoriaClientePortal"
                // }
            ]
        },
        {
            title: "Operadores",
            value: "/cadastro/operador",
            tab: "operadores",
            role: "CadastrarOperadorPortal",
        },
        {
            title: "Promoções",
            value: "/cadastro/promocoes",
            tab: "promocoes",
            role: "CadastrarPromocaoPortal",
        },
        {
            title: "Planos",
            value: "/cadastro/planos",
            tab: "planos",
            role: "CadastrarPlanoPortal"
        },
        {
            title: "Comandas",
            value: "/cadastro/comandas",
            tab: "comandas",
            role: "CadastrarComandaPortal"
        },
        {
            title: "Impressoras",
            value: "/cadastro/impressoras",
            tab: "impressoras",
            role: "CadastrarImpressoraPortal"
        },
        {
            title: "Formas de pagamento",
            value: "/cadastro/formasPagamento",
            tab: "formasPagamento",
            role: "CadastroFormaPagamento"
        },
        {
            title: "Lojas",
            value: "/cadastro/stores",
            tab: "stores",
            role: "CadastroLoja"
        },
        {
            title: "Link de pagamento",
            value: "/cadastro/mepaylink",
            tab: "mepaylink",
            role: "CadastroLink"
        },
    ];

    const subTabComponent = useCallback((tabItem: IMenuItem) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/cadastros/" + tabItem.tab}
            hideTabs
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.role,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        /> : tabItem.component || <IframePage src={tabItem.value!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/cadastros'}
            hideTabs
            tabsPages={menuItems.map((tabItem) => (
                {
                    label: tabItem.title,
                    tab: tabItem.tab,
                    roles: tabItem.role,
                    component: subTabComponent(tabItem)
                }
            ))} />
    );
}
export default RegistersPage;
