import Button from "components/ui/Button/Button";
import TabNavigation from "components/ui/tabNavigation/TabNavigation";
import { IGuestListForm } from "modules/events/presentation/components/guestListForm/interfaces/GuestListForm.types";
import { FC, useMemo } from "react";
import style from "./GuestListForm.module.scss";
import { GuestListFormStep } from "./IGuestListForm";
import { DetailsForm } from "./subForms/details/DetailsForm";
import { DiscountsForm } from "./subForms/discounts/DiscountsForm";
import { PromotersForm } from "./subForms/promoters/PromotersForm";
import UseGuestListForm from "./UseGuestListForm";
import { ShareListForm } from "./subForms/shareList/ShareListForm";
import TabView from "components/tab/TabView";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

interface IGuestListProps {
  guestList: IGuestListForm | null;
  handleSubmit: (guestListForm: IGuestListForm) => void;
  onCancel: () => void;
  adminAccess?: boolean;
}

const GuestListForm: FC<IGuestListProps> = ({ guestList, handleSubmit, onCancel, adminAccess }) => {
  const {
    changePromotersHandle,
    closingDateTime,
    description,
    detailsErrors,
    discountLimit,
    discountsErrors,
    discountsList,
    formStep,
    formType,
    handleChangeDay,
    handleChangeDescription,
    handleChangeDiscountLimit,
    handleChangeDiscountList,
    handleChangeIsActive,
    handleChangeMainPromoter,
    handleChangeName,
    handleChangeUseDiscountLimit,
    handleFormStep,
    isActive,
    mainPromoter,
    name,
    promotersErrors,
    promotersList,
    promotersWithAccess,
    baseList,
    handleSubmitButton,
    useDiscountLimit,
    copyConfiguration,
    copyGuests,
    handleChangeCopyConfiguration,
    handleChangeCopyGuests,
    validUntil,
    changeValidDateHandle,
    enableDiscountExpiration,
    handleChangeEnableDiscountExpiration,
    handleChangeShareUrl,
    handleChangeShareDescription,
    handleChangeShareEnabled,
    shareEnabled,
    shareUrl,
    shareDescription,
    guestListErrors,
    showUrlForm
  } = UseGuestListForm(guestList, handleSubmit);


  const tabs = useMemo(() => {
    let _tabs = [
      { label: "Nome", value: GuestListFormStep.DETAILS },
      { label: "Promoters", value: GuestListFormStep.PROMOTERS },
      { label: "Descontos", value: GuestListFormStep.DISCOUNTS },
    ]
    if (showUrlForm) {
      _tabs.push({ label: "URL", value: GuestListFormStep.URL })
    }
    return _tabs
  }, [showUrlForm])
  
  return (
    <div id={style.GuestListForm}>
      <div className={style.body}>
        <TabView
          tabs={tabs}
          index={formStep}
          onChangeTab={(tab, value) => handleFormStep?.(tab.value)}
        />
        <div className={style.content}>
          {
            formStep === GuestListFormStep.DETAILS &&
            <DetailsForm
              day={closingDateTime}
              copyConfiguration={copyConfiguration}
              copyGuests={copyGuests}
              description={description}
              errors={detailsErrors}
              handleChangeDay={handleChangeDay}
              handleChangeDescription={handleChangeDescription}
              handleChangeName={handleChangeName}
              handleChangeCopyConfiguration={handleChangeCopyConfiguration}
              handleChangeCopyGuests={handleChangeCopyGuests}
              isActive={!!isActive}
              handleChangeIsActive={handleChangeIsActive}
              name={name}
              type={formType}
            />
          }

          {
            formStep === GuestListFormStep.PROMOTERS &&
            <PromotersForm
              errors={promotersErrors}
              handleChangeMainPromoter={handleChangeMainPromoter}
              mainPromoter={mainPromoter}
              promotersList={promotersList}
              promotersWithAccess={promotersWithAccess}
              changePromotersHandle={changePromotersHandle}
            />
          }

          {formStep === GuestListFormStep.DISCOUNTS && (
            <>
              <DiscountsForm
                discountList={discountsList}
                errors={discountsErrors}
                handleDiscountList={handleChangeDiscountList}
                discountLimit={discountLimit}
                selectedDiscountList={baseList}
                handleChangeDiscountLimit={handleChangeDiscountLimit}
                useDiscountLimit={useDiscountLimit}
                handleChangeUseDiscountLimit={handleChangeUseDiscountLimit}
                validUntil={validUntil}
                changeValidDateHandle={changeValidDateHandle}
                enableDiscountExpiration={enableDiscountExpiration}
                handleChangeEnableDiscountExpiration={handleChangeEnableDiscountExpiration}
              />
              <div>
                <p>Para adicionar uma nova lista de desconto basta ir em</p>
                <p>configuração de desconto &gt; adicionar nova lista de desconto</p>
              </div>
            </>
          )}

          {
            formStep === GuestListFormStep.URL &&
            <ShareListForm
              errors={guestListErrors}
              handleChangeShareUrl={handleChangeShareUrl}
              handleChangeShareDescription={handleChangeShareDescription}
              handleChangeShareEnabled={handleChangeShareEnabled}
              shareEnabled={shareEnabled}
              shareUrl={shareUrl}
              shareDescription={shareDescription}
            />
          }
        </div>
      </div>
      <div className={style.action}>
        <Button fullWidth={false} onClick={onCancel} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button fullWidth={false} onClick={handleSubmitButton} color="primary">
          {formStep === GuestListFormStep.DISCOUNTS ? "Salvar" : "Continuar"}
        </Button>
      </div>
    </div>
  );
};

export default GuestListForm;
