import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetSmartPrinterUseCase = async (smartPrintersApi: ISmartPrinterApiCB, localClienteId: string) => {

    const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
    const response = await _smartPrintersApi.getSmartPrinterByLocalClenteId(localClienteId);
    return response
}
export default GetSmartPrinterUseCase



