import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { AddCustomerInCategoryUseCase } from "modules/customerCategories/application/useCases/AddCustomerInCategoryUseCase";
import { GetCustomerCategoriesUseCase } from "modules/customerCategories/application/useCases/GetCustomerCategoriesUseCase";
import { RemoveCustomerFromCategoryUseCase } from "modules/customerCategories/application/useCases/RemoveCustomerFromCategoryUseCase";
import { ICustomerCategory } from "modules/customerCategories/presentation/interfaces/ICustomerCategories";
import DeleteGuestUseCase from "modules/events/application/useCases/guests/DeleteGuestUseCase";
import DoPreCheckinUseCase from "modules/events/application/useCases/guests/DoPreCheckinUseCase";
import DoStraightCheckinUseCase from "modules/events/application/useCases/guests/DoStraightCheckinUseCase";
import { IGuest, IPeopleList } from "modules/events/presentation/pages/eventGuestsLists/interfaces/IEventGuestsListsPage";
import { IListObj, IArrObj, parseObjToArr } from "modules/events/presentation/utils/utils";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CustomerCategoryApi } from "services/api/customerCategory/CustomerCategoryApi";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";

const getWindowHeight = () => {
  const { innerHeight } = window;
  return innerHeight - 328;
};

const service = CustomerCategoryApi();

export const UseAlphabeticalGuestList = (guestList: IPeopleList, refreshList: () => Promise<void>) => {
  const guestService = EventsGuestsApi();
  const { hideLoading, showLoading } = useUi();
  const { currentLocal } = useLocal();

  const [filteredList, setFilteredList] = useState<IGuest[]>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());
  const [promoterList, setPromoterList] = useState<IListObj[]>([]);
  const [filtered, setFiltered] = useState<IGuest[]>();
  const [userToDelete, setUserToDelete] = useState<IListObj | null>(null);
  const [listCpfList, setListCpfList] = useState<IListObj[]>([]);
  const [preCheckinUser, setPreCheckinUser] = useState<IGuest | null>(null);
  const [showPrecheckinModal, setShowPrecheckinModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ICustomerCategory[]>([]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteUser = (id: string, name: string) => {
    setUserToDelete({ id, name });
    toggleDeleteModal();
  };

  const deleteGuest = async (guestId: string) => {
    if (!currentLocal) return;

    showLoading();
    try {
      await DeleteGuestUseCase(guestService, guestId, currentLocal.id);
      await refreshList();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      setUserToDelete(null);
      toggleDeleteModal();
    }
  };

  const setPrecheckinUser = (guest: IGuest) => {
    setPreCheckinUser(guest);
    togglePreCheckinModal();
  }

  const togglePreCheckinModal = () => {
    setShowPrecheckinModal(!showPrecheckinModal);
  }

  const handlePreCheckin = async (guestId: string, needsPreCheckin: boolean) => {
    if (!currentLocal) return;

    try {
      if (needsPreCheckin) {
        await DoPreCheckinUseCase(guestService, guestId, currentLocal.id);
      } else {
        await DoStraightCheckinUseCase(guestService, guestId, currentLocal.id);
      }
      refreshList()
    } catch (error) {
      console.error(error);
    } finally {
      togglePreCheckinModal();
    }
  } 

  useEffect(() => {
    if (guestList) {
      setFilteredList(guestList.people);

      const promoters = guestList.people.reduce(
        (item, acumulador) => ({
          ...item,
          [acumulador.promoterId]: acumulador.promoterName,
        }),
        {} as IArrObj
      );
      setPromoterList(parseObjToArr(promoters));

      const listCpf = guestList.people.reduce(
        (item, acumulador) => ({
          ...item,
          [acumulador.cpf]: acumulador.cpf,
        }),
        {} as IArrObj
      );
      setListCpfList(parseObjToArr(listCpf));
    }
  }, [guestList]);

  useEffect(() => {
    if (filteredList) {
      setFiltered(filteredList);
    }
  }, [filteredList]);

  const personFilter = (id: string) => {
    if (id === "-1") {
      setFiltered(guestList.people);
    } else {
      const guests = guestList.people.filter((item) => item.id === id);
      setFiltered(guests);
    }
  };

  const promoterFilter = (id: string) => {
    const guests = guestList.people.filter((item) => item.promoterId === id);
    setFiltered(guests);
  };

  const cpfFilter = (id: string) => {
    const guests = guestList.people.filter((item) => item.cpf === id);
    setFiltered(guests);
  };

  const filterByCpf = (event: ChangeEvent<{}>, value: IListObj | null) => {
    event.preventDefault();
    if (value) {
      cpfFilter(value?.id);
    } else {
      personFilter("-1");
    }
  };

  const filterByGuest = (event: ChangeEvent<{}>, value: IGuest | null) => {
    event.preventDefault();
    if (value) {
      personFilter(value?.id);
    } else {
      personFilter("-1");
    }
  };

  const filterByPromoter = (event: ChangeEvent<{}>, value: IListObj | null) => {
    event.preventDefault();
    if (value) {
      promoterFilter(value.id);
    } else {
      personFilter("-1");
    }
  };

  const checkinCounter = (guests: IGuest[] | undefined) => {
    if (guests) {
      const totalGuests = guests.length;
      const checkedGuests = guests.reduce((checkeds, current) => {
        return current.checkinAt ? checkeds + 1 : checkeds;
      }, 0);

      return `${checkedGuests} / ${totalGuests}`;
    }
    return "";
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(getWindowHeight());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCategories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetCustomerCategoriesUseCase(service, currentLocal!.id);
      setCategories(response.categories.filter(x => x.visible));
    } finally {
      setLoading(false);
    }
  }, [currentLocal]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const mountCategories = (item: IGuest) => {
    return categories.map(x => item.peopleCategories?.map(y => y.id).includes(x.id) ? { ...x, isActive: true } : { ...x, isActive: false })
  }

  const addCustomerInCategory = async (categoryId: string, peopleId: string) => {
    try {
      showLoading();
      await AddCustomerInCategoryUseCase(service, categoryId, peopleId, currentLocal!.id);
      await refreshList();
    } finally {
      hideLoading();
    }
  }

  const removeCustomerFromCategory = async (categoryId: string, customerDocument: string) => {
    try {
      showLoading();
      await RemoveCustomerFromCategoryUseCase(service, categoryId, customerDocument, currentLocal!.id);
      await refreshList();
    } finally {
      hideLoading();
    }
  }

  return {
    listCpfList,
    filterByCpf,
    handleDeleteUser,
    filterByGuest,
    promoterList,
    filterByPromoter,
    filtered,
    windowHeight,
    userToDelete,
    showDeleteModal,
    toggleDeleteModal,
    deleteGuest,
    checkinCounter,
    handlePreCheckin,
    togglePreCheckinModal,
    preCheckinUser,
    setPrecheckinUser,
    showPrecheckinModal,
    loading,
    mountCategories,
    addCustomerInCategory,
    removeCustomerFromCategory,
  };
};
