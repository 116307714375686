
import { IconButton } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Pagination } from '@mui/material'
import UseHouseFlowProductList, { ProdudutsParamsClientHouseFlow } from 'modules/houseFlow/presentation/hooks/UseHouseFlowProductList'
import { IDetailsItem } from 'modules/houseFlow/presentation/interfaces/IHouseFlowDetails'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import Utils from 'services/utils/Utils'
import { ConsumptionProductList } from '../consumptionProductList/ConsumptionProductList'
import styles from './ConsumptionItem.module.scss'


export interface IConsumptionItemProps {
    item: IDetailsItem
}

const ConsumptionItem: React.FC<IConsumptionItemProps> = ({ item }) => {


    const { getHouseFlowProductList, houseFlowProductList } = UseHouseFlowProductList();

    const [params, setParams] = useState<ProdudutsParamsClientHouseFlow>();


    const [dropDown, setDropDown] = useState(false)


    const openDropDown = useCallback(() => {
        setDropDown(true);
        getHouseFlowProductList({ ...params, cardId: item.cardId, orderId: item.orderId });
    }, [getHouseFlowProductList, item.cardId, item.orderId, params])


    const closeDropDown = useCallback(() => {
        setDropDown(false);
    }, [])

    const onChangePaginationHandle = useCallback((page: number, pageSize?: number) => {
        setParams((prev) => {
            getHouseFlowProductList({ ...params, cardId: item.cardId, orderId: item.orderId, pagination: { page: page - 1, pageSize: pageSize } });
            return ({ ...prev, pagination: { page: page - 1, pageSize: pageSize } })
        }
        );
    }, [getHouseFlowProductList, item.cardId, item.orderId, params]);

    const countPage = useMemo(() => Math.ceil((houseFlowProductList?.totalRecords ?? 1) / (params?.pagination?.pageSize ?? 20)), [houseFlowProductList?.totalRecords, params?.pagination?.pageSize])

    return (
        <div id={styles.ConsumptionItem}>
            <div className={styles.header}>
                <b title={item.orderId}>{item.orderId.slice(0,8)}...</b>

                {
                    !dropDown ?
                        <div
                            className={styles.icon}
                        >
                            <p>{Utils.toDateAndTime(item.orderDate)} </p>
                            <KeyboardArrowDown onClick={openDropDown} />
                        </div>
                        :

                        <div
                            className={styles.icon}
                        >
                            <p>{Utils.toDateAndTime(item.orderDate)} </p>
                            <KeyboardArrowUp onClick={closeDropDown} />
                        </div>

                }
            </div>

            {
                dropDown &&
                <div className={styles.content}>

                    <div className={styles.row}>
                        <p>Operador:</p>
                        <p>{item.operatorName ? item.operatorName : '-'}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Equipamento:</p>
                        <p>{item.deviceName ? item.deviceName : '-'}</p>
                    </div>
                    <div className={styles.row}>
                        <p>Cliente:</p>
                        <p>{item.customerName ? item.customerName : '-'}</p>
                    </div>
                    <div className={styles.row}>
                        <p>CPF:</p>
                        <p>{item.customerCPF ? ("***" + item?.customerCPF) : '-'}</p>
                    </div>
                    <div className={styles.listProduct}>
                        {
                            houseFlowProductList?.records.map((item, index) => (

                                <ConsumptionProductList
                                    key={index}
                                    item={item}
                                />
                            ))

                        }

                        <div className={styles.pagination}>
                            <Pagination
                                count={countPage}
                                onChange={(_, page) => onChangePaginationHandle(page, params?.pagination?.pageSize)}
                                page={((params?.pagination?.page ? params?.pagination?.page + 1 : 1))}
                            />

                        </div>

                    </div>
                    <div className={styles.row}>
                        <p>Sub-total:</p>
                        <b>{item.orderTotalGross ? moneyMaskNumber(item.orderTotalGross) : '-'}</b>
                    </div>
                    {item.coupons.map(cupom => (
                        <div className={styles.row}>
                            <p>Cupons:</p>
                            <p>{cupom.name} <b>{moneyMaskNumber(cupom.value)}</b></p>
                        </div>
                    ))}
                    <div className={styles.row}>
                        <p>Desconto:</p>
                        <b>{item.orderTotalDiscounts ? moneyMaskNumber(item.orderTotalDiscounts) : '-'}</b>
                    </div>
                    <div className={styles.row}>
                        <p>Taxa de serviço:</p>
                        <b>{item.orderTotalService ? moneyMaskNumber(item.orderTotalService) : '-'}</b>
                    </div>
                </div>
            }
            <div className={styles.footer}>
                <p>Total do pedido:</p>
                <b>{item.orderTotalNet ? moneyMaskNumber(item.orderTotalNet) : '-'}</b>
            </div>
        </div>
    )
}

export default ConsumptionItem