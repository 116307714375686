import { IGetMesasService, IReponseMesas } from "../interfaces/IGestaoVendasService";

const GetMesas = async (
  accountService: IGetMesasService,
  id: string
): Promise<IReponseMesas[]> => {
  return await accountService.getMesas(id);
};

export default GetMesas;
