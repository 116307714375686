import { Button, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./TabPage.module.scss";

interface ITabObject {
  label: string;
  tab: string;
  component: ReactNode;
  roles?: string;
}

export interface IDashboardPageProps {
  //propertys
  routerPrefix: string;
  tabsPages: ITabObject[];
  intialTab?: string;
  subTabEnabled?: boolean;
  hideTabs?: boolean
}
const TabPage: FC<IDashboardPageProps> = ({
  routerPrefix,
  intialTab,
  tabsPages,
  subTabEnabled,
  hideTabs
}) => {
  const { tab, subTab } = useParams<{ tab: string; subTab?: string }>();
  const { push } = useHistory();
  const { hasAccessRole } = useAuth();
  const [tabPagesWithAcess, setTabPagesWithAccess] = useState<ITabObject[]>([]);

  useEffect(() => {
    setTabPagesWithAccess(
      tabsPages.filter((item) => hasAccessRole(item.roles)|| process.env.NODE_ENV === 'development')
    );
  }, [hasAccessRole, tabsPages]);

  const handleChangeTab = useCallback(
    (newValue: string) => {
      push(routerPrefix + "/" + newValue);
    },
    [push, routerPrefix]
  );

  useEffect(() => {
    if (subTabEnabled) {
      if (!subTab && tabPagesWithAcess.length) {
        const firstSubTab = tabPagesWithAcess.find((item) =>
          hasAccessRole(item.roles) || process.env.NODE_ENV === 'development'
        );
        if (firstSubTab) {
          push(routerPrefix + "/" + firstSubTab.tab);
        }
      }
    } else {
      if (!tab && tabPagesWithAcess.length) {
        const firstTab = tabPagesWithAcess.find((item) =>
          hasAccessRole(item.roles) || process.env.NODE_ENV === 'development'
        );
        if (firstTab) {
          push(routerPrefix + "/" + firstTab.tab);
        }
      }
    }
  }, [
    hasAccessRole,
    intialTab,
    push,
    routerPrefix,
    subTab,
    subTabEnabled,
    tab,
    tabPagesWithAcess,
    tabPagesWithAcess.length,
  ]);

  const tabs = useMemo(
    () =>
      !subTabEnabled ? (
        <TabContext value={tab}>
          {!hideTabs && <TabList
            scrollButtons="auto"
            className={styles.pageTab}
            TabIndicatorProps={{ className: styles.indicator }}
            onChange={(_, _tabValues) => handleChangeTab(_tabValues)}
            aria-label="lab API tabs example"
          >
            {tabPagesWithAcess.map((item, index) => (
              <Tab
                key={item.label + index}
                label={item.label}
                value={item.tab}
              />
            ))}
          </TabList>}
        </TabContext>
      ) : (
        !hideTabs && <div className={styles.subTabContainer}>
          {tabPagesWithAcess.map((item, index) => (
            <Button
              size="small"
              key={item.label + index}
              className={`${styles.button} ${subTab === item.tab ? styles.selectedButton : ""
                }`}
              variant={subTab === item.tab ? "contained" : undefined}
              onClick={() => handleChangeTab(item.tab)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      ),
    [handleChangeTab, hideTabs, subTab, subTabEnabled, tab, tabPagesWithAcess]
  );

  const pageList = useMemo(
    () =>
      tabPagesWithAcess.map(
        (item, index) =>
          (subTabEnabled ? item.tab === subTab : item.tab === tab) && (
            <>{item.component}</>
          )
      ),
    [subTab, subTabEnabled, tab, tabPagesWithAcess]
  );

  return (
    <div id={styles.TabPage}>
      <div style={{ marginBottom: 8 }}>
        {tabPagesWithAcess.length > 1 && tabs}
      </div>
      <div className={styles.container}>
        {tabPagesWithAcess.length > 0 ? (
          pageList
        ) : (
          <div>
            <h2>
              Parece que você não tem permissão pra acessar este conteúdo.
            </h2>
            <Link to={"/private"}>
              <Button color="primary" variant="outlined">
                Voltar para inicio
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default TabPage;
