
import { EditCustomerPointsSummaryRequest } from "modules/fidelity/domain/dtos/EditCustomerPointsSummaryRequest";
import { IFidelityService } from "modules/fidelity/domain/interfaces/IFidelityService";
import { BalanceType } from "modules/fidelity/presentation/interfaces/IFidelityBalance";

export const EditCustomerSummaryUseCase = (service: IFidelityService, customerDocument: string, ownerId: string, balanceType: BalanceType, points: number, coins: number, reason: string): Promise<void> => {

    const request: EditCustomerPointsSummaryRequest = {
        balanceType,
        coins,
        points,
        reason
    }
    return service.editSummary(customerDocument, ownerId, request);
}