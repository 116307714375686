import { IDeleteDadoBancarioService } from "../interfaces/IContaDigitalApiService";

const DeleteDadoBancario = async (
  accountService: IDeleteDadoBancarioService,
  id: string
): Promise<any> => {
  return await accountService.deleteDadoBancario(id);
};

export default DeleteDadoBancario;
