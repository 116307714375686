import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ReportProductsList.module.scss";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { Skeleton } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import ReportProductsHeader from "./components/ReportProductsHeader";
import { IReportProductsColumnsConfig } from "../../../interfaces/IColumnsConfig";
import {
  GEtSalesResponse,
  SortPaymentsInterface,
} from "services/api/outputreport";

import ReportPaymentsItem from "./components/ReportPaymentsItem";
import { moneyMaskNumber } from "services/utils/Money";

type ReportProductsListProps = {
  payments?: GEtSalesResponse;
  isLoading: boolean;
  isSuccess: boolean;
  setSortPayments: (sortProducts: SortPaymentsInterface) => void;
};

export const ReportPaymentsList: FC<ReportProductsListProps> = ({
  payments,

  isLoading,
  setSortPayments,
}) => {
  const { dimensions } = UseDimension();

  const [colunms, setColunms] = useState<any[]>([
    {
      property: "Description",
      title: "Forma de pagamento",
      style: {
        flex: 1,
      },
    },
    {
      property: "TotalPaid",
      title: "Valor total",
      style: {
        flex: 0,
      },
    },
  ]);

  const columnsLimit = useCallback(
    (_columns: IReportProductsColumnsConfig[], width: number) => {
      if (width > 0) {
        const calcMax = (width: number) => {
          if (width > 0 && width <= 600) {
            return 1;
          } else if (width > 600 && width <= 800) {
            return 2;
          } else if (width > 800 && width <= 1024) {
            return 3;
          } else {
            return 5;
          }
        };
        const max = calcMax(width);
        let count = 0;
        const newValue = _columns.map((item, index) => {
          if (!item.hide) {
            count++;
            if (count > max) {
              return { ...item, hide: true };
            } else {
              return item;
            }
          } else {
            return { ...item, hide: false };
          }
        });
        return newValue;
      } else {
        return _columns;
      }
    },
    []
  );

  useEffect(() => {
    setColunms((prev) => columnsLimit(prev, dimensions.width));
  }, [columnsLimit, dimensions.width]);

  const List = useMemo(
    () => (
      <div className={styles.list}>
        <ReportProductsHeader
          columns={colunms}
          setSortProducts={setSortPayments}
          filterName="payments"
        />
        {isLoading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
          </>
        ) : (
          <>
            {payments?.Payments.Types?.map((item, index) => {
              return (
                <ReportPaymentsItem
                  key={index}
                  userItemData={item}
                  columns={colunms}
                />
              );
            })}
          </>
        )}
      </div>
    ),
    [colunms, isLoading, payments?.Payments.Types, setSortPayments]
  );

  return (
    <div id={styles.ReportProductsList}>
      {!isLoading && !payments?.Sales.Products?.length ? (
        <ErrorMessage type="warning" />
      ) : (
        <>{List}</>
      )}
      {payments?.Payments.Canceled !== 0 && (
        <div className={styles.rowPayments} style={{ flex: 1 }}>
          <span>Total de cancelamento</span>
          <span>{moneyMaskNumber(payments?.Payments.Canceled)}</span>
        </div>
      )}
      {payments?.Payments.Balance !== 0 && (
        <div className={styles.rowPaymentsTotal} style={{ flex: 1 }}>
          <span>Total</span>
          <span>{moneyMaskNumber(payments?.Payments.Balance)}</span>
        </div>
      )}
    </div>
  );
};
