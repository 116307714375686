import { Switch } from '@mui/material'
import { ICouponForm } from 'modules/config/discountCoupons/presentation/interfaces/ICouponForm'
import { IDevices } from 'modules/config/discountCoupons/presentation/interfaces/IDiscountCouponItem'
import React, { useEffect } from 'react'
import { EnumDeviceType, IDeviceItem, IDeviceType } from '../../../interfaces/IDeviceItem'
import styles from './DeviceCard.module.scss'

export interface IDeviceCardProps {
    item: IDeviceItem
    handleDeviceChange: (value: IDevices, checked: boolean) => void
    checked: boolean
    values: ICouponForm;
    setValues: React.Dispatch<React.SetStateAction<ICouponForm>>
}

export const DeviceCard: React.FC<IDeviceCardProps> = ({ item, handleDeviceChange, checked, values, setValues }) => {


    const getDeviceTypeImage = (deviceType: IDeviceType) => {
        switch (deviceType.value) {
            case EnumDeviceType.POS:
                return '/assets/icon/device-pos.svg';
            case EnumDeviceType.TOTEM:
                return '/assets/icon/device-kiosk.svg';
            case EnumDeviceType.POSPLUS:
                return '/assets/icon/device-pos.svg';
            default:
                return '/assets/icon/device-tablet.svg';
        }
    };

    return (
        <div id={styles.DeviceCard}>
            <div className={styles.container}>
                <img src={getDeviceTypeImage(item.type)} alt={"icone do dispositivo"} />
                <span>{item.name}</span>
            </div>
            <Switch checked={checked} color='secondary' onChange={(ev) => handleDeviceChange({deviceId: item.id, deviceType: Number(item.type), name: item.name}, ev.target.checked)} />
        </div>
    )
}
