import { IPermutationAccountResponse } from 'modules/events/domain/dto/IEventsResponse';
import { ICashlessVoucerApi } from '../../interfaces/ICashlessVoucherService';

const GetPermutationAccountUseCase = async (
  cashlessVoucherService: ICashlessVoucerApi,
  document: string,
  localId: string,
  accountId: string,
): Promise<IPermutationAccountResponse> => {
  return await cashlessVoucherService.getPermutationAccount(document, localId, accountId);
};

export default GetPermutationAccountUseCase;