import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetEventsDetailsUseCase from "modules/events/application/useCases/events/GetEventsDetailsUseCase";
import GetGuestsOnEventUseCase from "modules/events/application/useCases/guests/GetGuestsOnEventUseCase";
import { IEvents } from "modules/events/domain/models/IEvents";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { IEventPeopleList } from "../eventGuestsLists/interfaces/IEventGuestsListsPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";

interface IUseEventPeopleList {
  nameList: IEventPeopleList | undefined;
  event: IEvents | undefined;
  getGetList: () => Promise<void>;
  onChangePaginationHandle: (pagination: {
    page?: number;
    pageSize?: number;
  }) => void;
  onChangeFilterHandle: (filter: IEventGuestFilter) => void;
  params: IParamsClientEventGuestList;
  setParams: Dispatch<SetStateAction<IParamsClientEventGuestList>>
}

export interface IEventGuestFilter {
  name?: string,
  listNameId?: string,
  promoterId?: string,
  cpf?: string,
}

export interface IParamsClientEventGuestList {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  filter?: IEventGuestFilter
}

const guestService = EventsGuestsApi();
const eventsService = EventsApi();

export const UseEventPeopleListPage = (): IUseEventPeopleList => {
  const { currentLocal } = useLocal();
  const { hideLoading, showLoading } = useUi();
  const { eventId } = useParams<{ eventId: string }>();
  const [nameList, setNameList] = useState<IEventPeopleList>();
  const [event, setEvent] = useState<IEvents>();
  const [params, setParams] = useState<IParamsClientEventGuestList>({});
  const { updateRouters } = useBreadcumbs()

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Eventos', url: '/private/eventos/list/eventos' },
      { id: BreadcrumbsEnum.CONVIDADOS, title: event?.name ?? "Convidados", label: event?.name ? "Convidados" : '', url: `/private/event/eventos/convidados/${eventId}` },
    ])
  }, [updateRouters, event, eventId]);

  const getGetList = useCallback(async (params?: IParamsClientEventGuestList) => {
    if (!currentLocal) return;

    showLoading();
    try {
      const result = await GetGuestsOnEventUseCase(guestService,
        eventId,
        currentLocal.id,
        params?.pagination ?? { page: 0, pageSize: 20 },
        params?.filter
      );

      setNameList(result);
      
      if (!currentLocal) return;
      const eventDetails = await GetEventsDetailsUseCase(eventsService, eventId, currentLocal?.id);
      setEvent(eventDetails);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  }, [eventId, hideLoading, showLoading, currentLocal]);

  const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
    setParams((prev) => ({ ...prev, pagination }));
  }, [setParams]);

  const onChangeFilterHandle = useCallback((filter: IEventGuestFilter) => {
    setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }));
  }, [setParams]);

  useEffect(() => {

    if (params)
      getGetList({ ...params });
  }, [getGetList, params]);

  return {
    nameList,
    event,
    getGetList,
    onChangePaginationHandle,
    onChangeFilterHandle,
    params,
    setParams
  };
};
