import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { FC, useMemo } from "react";
import styles from "./TIDsAdicionaisHeader.module.scss";
import { ITIDsAdicionaisColumns } from "../../interfaces/ITIDsAdicionaisColumns";

export interface ITIDsAdicionaisHeaderHeader {
  columns: ITIDsAdicionaisColumns[];
}

const TIDsAdicionaisHeader: FC<ITIDsAdicionaisHeaderHeader> = ({
  columns,
}) => {
  const { isMobile } = useUi();

  const header = useMemo(
    () =>
      columns.map(
        (column, index) =>
          !column.hide && (
            <div key={index} className={styles.row} style={column.style}>
              <span className={styles.order}>
                {column.title}{" "}
              </span>
            </div>
          )
      ),
    [columns]
  );

  if (isMobile) {
    return <></>;
  }

  return (
    <div id={styles.TIDsAdicionaisHeader}>
      <div className={styles.container}>
        {header}
        <div className={styles.icon} style={{ flex: 0.2 }} />
      </div>
    </div>
  );
};

export default TIDsAdicionaisHeader;
