import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import MainDashboardPage from "modules/mainDashboard/presentation/pages/MainDashboard";
import { FC, useEffect } from "react";

const MainDashboard: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
      updateRouters([
          { title: "Dashboard" },
          {
            label: "Dashboard",
            title: "Principal",
          },
      ]);

      return () => {
      updateRouters([]);
      };
  }, [updateRouters]);
  return (
    <Layout bgColor="rgba(250, 250, 250, 0.5)">
      <MainDashboardPage/>
    </Layout>
  );
};
export default MainDashboard;