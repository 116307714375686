import { IEditCollaboratorRequest } from "modules/colaboradores/domain/dto/IEditCollaboratorRequest";

export interface IEditCollaboratorFormErrors {
  ProfileIds?: string;
}

export const EditCollaboratorValidation  = (formData: IEditCollaboratorRequest, setErrors: React.Dispatch<React.SetStateAction<IEditCollaboratorFormErrors>>) => {
  let _errors: IEditCollaboratorFormErrors = {};
  let hasError = false;

  if (formData.ProfileIds.length === 0) {
    _errors = { ..._errors, ProfileIds: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
