import { Checkbox } from '@material-ui/core';
import Button from 'components/ui/Button/Button';
import { IGuestAlreadyInList } from 'modules/events/domain/models/IEvents';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './MigrateGuestList.module.scss';
import UseMigrateGuestList from './UseMigrateGuestList';

interface IMigrateGuestListProps {
  listName: string | undefined;
  guestsToMigrate: IGuestAlreadyInList[] | undefined;
  toggleModal: () => void;
  hasPermissionToMigrate?: boolean
}

export const MigrateGuestList: FC<IMigrateGuestListProps> = ({
  guestsToMigrate,
  listName,
  hasPermissionToMigrate,
  toggleModal,
}) => {
  const {
    checkAll,
    guestList,
    handleCheck,
    handleCheckAll,
    isChecked,
    handleConfirmMigration,
  } = UseMigrateGuestList(guestsToMigrate, toggleModal, listName);

  return (
    <div id={styles.MigrateGuestList}>
      <h2>Alguns convidados se encontram em outra lista!</h2>
      {hasPermissionToMigrate ?
        <h2>
          <br />
          Selecione os convidados que deseja
          <br />
          migrar para a lista: {listName}
        </h2>
        :
        <h2>
          <br />
          Você não tem permissão para migrar.
        </h2>
      }
      <div className={styles.tableContainer}>
        <div className={styles.item}>
          <div>
            {hasPermissionToMigrate && <Checkbox
              value="selectAll"
              checked={checkAll}
              color="primary"
              size="small"
              onChange={handleCheckAll}
            />}
          </div>
          <p>CPF</p>
          <p>Nome</p>
          <p>Lista atual</p>
        </div>
        {guestsToMigrate &&
          guestsToMigrate.map((guest, key) => (
            <div key={key} className={styles.item}>
              <div>
                {hasPermissionToMigrate && <Checkbox
                  value={guest.cpf}
                  checked={isChecked(guest.cpf, guestList)}
                  color="primary"
                  size="small"
                  onChange={handleCheck}
                />}
              </div>
              <p>{guest.cpf}</p>
              <p>{guest.name}</p>
              <p>{guest.listName}</p>
            </div>
          ))}
      </div>
      {
        hasPermissionToMigrate ?
          <Button onClick={() => handleConfirmMigration(guestList)}>
            Confirmar
          </Button>
          :
          <Button onClick={() => toggleModal()}>
            OK
          </Button>
      }
    </div>
  );
};
