import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './CatalogListTab.module.scss'
import { Icon, Tab, Tabs } from '@mui/material';
import Button from 'components/ui/Button/Button';
import { AddRounded } from '@material-ui/icons';
import { useParams } from 'react-router-dom';



export interface ICatalogListItem {
    id: string,
    description: string,
    isDefault: boolean,
}

export interface ICatalogListTabProps {
    //propertys
    list: ICatalogListItem[];
    onChangeList: (listItem?: ICatalogListItem) => void
    onClickAddList: () => void
    children?: ReactNode
}
const CatalogListTab: FC<ICatalogListTabProps> = ({ list, children, onChangeList, onClickAddList }) => {
    const [currentListId, setCurrentListId] = useState<string | null>((list.length > 0) ? list[0].id : null);

    const { catalogId } = useParams<{ catalogId: string }>();

    useEffect(() => {
        if (catalogId) {
            const filterCurrentListId = list.find(item => item.id === catalogId)
            if (filterCurrentListId) {
                setCurrentListId(filterCurrentListId.id)
                onChangeList?.(filterCurrentListId)
            }
        } else {
            if (list.length > 0) {
                setCurrentListId(list[0].id)
                onChangeList?.(list[0]);
            }
        }

    }, [list, onChangeList])


    const onChangeTabHandle = useCallback((_, value: string) => {
        setCurrentListId(value);
        const _list = list.find(item => item.id === value);
        onChangeList?.(_list);
    }, [list, onChangeList])

    var tab = useMemo(() => <div className={styles.tabConainer}>

        <Tabs
            value={currentListId}
            onChange={onChangeTabHandle}
            variant="scrollable"
            scrollButtons="auto"
        >
            <Tab
                label={"Todos os produtos"}
                value={null}
            />
            {
                list.map((item) => (
                    <Tab
                        key={item.id}
                        label={item.description}
                        value={item.id}
                    />
                ))
            }
        </Tabs>
        <div className={styles.buttonConainer}>
            <Button fullWidth variant='text' onClick={onClickAddList} color='secondary'><AddRounded fontSize="small" />Novo cardápio</Button>
        </div>
    </div>, [currentListId, list, onChangeTabHandle, onClickAddList])


    const isMobile = useMemo(() => window.innerWidth < 768, [])

    var newTab = useMemo(() => <div className={styles.tabConainer}>
        {
            list.map((item) => (
                <button
                    key={item.id}
                    className={currentListId === item.id ? styles.tabItemSelected : styles.tabItem}
                    onClick={() => onChangeTabHandle(null, item.id)}
                    value={item.id}
                >
                    {item.description}
                </button>
            ))
        }
        <button className={styles.tabItemAdd} onClick={onClickAddList} color='secondary'><AddRounded fontSize="small" />Novo cardápio</button>

        <div className={styles.bottomLine}></div>
    </div>, [currentListId, list, onChangeTabHandle, onClickAddList])


    var _tab = useMemo(() => <div className={styles.tabConainer}>

        <div className={styles.bottomLine}></div>
        <Tabs
            value={currentListId}
            onChange={onChangeTabHandle}
            variant="scrollable"
            scrollButtons="auto"
            className={styles.tabConainer}
            TabIndicatorProps={
                {
                    style: {
                        display: "none"
                    }
                }
            }
        >
            <Tab
                label={<div className={styles.tabLabel}><Icon fontSize={'small'}>fastfood</Icon>Todos os produtos</div>}
                value={null}
                className={currentListId === null ? styles.tabItemSelected : styles.tabItemAll}
            />
            {
                list.map((item) => (
                    <Tab
                        key={item.id}
                        label={
                            <div className={styles.tabLabel}>
                                {/* <Icon fontSize={'small'}>notes</Icon> */}
                                <img
                                    src="/assets/icon/catalog_icon.svg"
                                    alt="Icone cardápio"
                                />
                                {item.description}
                            </div>}
                        value={item.id}
                        className={currentListId === item.id ? styles.tabItemSelected : styles.tabItem}
                    />
                ))
            }
        </Tabs>
        <div style={{ display: 'flex' }}>
            <Button variant='text' onClick={onClickAddList} color='secondary'><Icon fontSize={'small'}>add_notes</Icon>{!isMobile && 'Novo cardápio'}</Button>
        </div>
    </div>, [currentListId, isMobile, list, onChangeTabHandle, onClickAddList])




    return (
        <div id={styles.CatalogListTab} >
            {/* {tab} */}
            {_tab}
            {/* {newTab} */}
            <div className={styles.container} >
                {children}
            </div>
        </div>
    )
}
export default CatalogListTab