import { IGetTransactionDetailsResponse } from "../domain/dto/IGetTransactionDetailsResponse";
import { ITransactionReportService } from "../domain/interface/ITransactionReportService"

const GetTransactionDetailsUseCase = async (
  service: ITransactionReportService,
  localId: string,
  transactionId: string
): Promise<IGetTransactionDetailsResponse> => {
  const response = service.getTransactionDetails(localId, transactionId);
  return response;
};

export default GetTransactionDetailsUseCase;
