import { ISocialService } from "modules/posts/domain/interfaces/ISocialService";
import { IGetPostsResponse } from "modules/posts/domain/dtos/posts/IGetPostsResponse";
import { ICreatePostRequest } from "modules/posts/domain/dtos/posts/ICreatePostRequest";
import { ICreatePostResponse } from "modules/posts/domain/dtos/posts/ICreatePostResponse";
import { api } from "../Api";
import { socialUrl } from "Enviroment";
import { IUpdatePostRequest } from "modules/posts/domain/dtos/posts/IUpdatePostRequest";

const SocialApi = (): ISocialService => {
  const baseUrl = socialUrl;

  const createPost = async (localId: string, request: ICreatePostRequest): Promise<ICreatePostResponse> => {
    const response = await api.post(`${baseUrl}/social/local/${localId}/posts`, request);
    return response.data;
  }

  const editPost = async (localId: string, request: IUpdatePostRequest): Promise<ICreatePostResponse> => {
    const response = await api.put(`${baseUrl}/social/local/${localId}/posts/${request.id}`, request);
    return response.data;
  }

  const getPosts = async (localId: string, includeFuturesPosts?: boolean, page?: number, pageSize?: number): Promise<IGetPostsResponse> => {
    const response = await api.get(`${baseUrl}/social/local/${localId}/posts`, { params: { page, pageSize, includeFuturesPosts: includeFuturesPosts } });
    return response.data;
  }

  const deletePost = async (localId: string, postId: string): Promise<void> => {
    return api.delete(`${baseUrl}/social/local/${localId}/posts/${postId}`)
  }

  return {
    getPosts,
    createPost,
    deletePost,
    editPost
  }
};

export default SocialApi;
