import GetHouseFlowDetailsItemUseCase from "modules/houseFlow/application/useCases/GetHouseFlowDetailsItemUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import HouseFlowApi from "services/api/houseFlow/HouseFlowApi";
import { IDetailsItem } from "../interfaces/IHouseFlowDetails";


export interface DetailsItemParamsClientHouseFlow {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  localId?: string;
  cardId?: string;
}

const UseHouseFlowDetailsItem = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detailsItem, setHouseFlowDetailsItem] = useState<IDetailsItem[]>();
  const { currentLocal } = useLocal();
  const [count, setCount] = useState(20);



  const getHouseFlowDetailsItem = useCallback(
    async (params?: DetailsItemParamsClientHouseFlow) => {
      try {
        const serviceHouseFlow = HouseFlowApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetHouseFlowDetailsItemUseCase(serviceHouseFlow,
            currentLocal.id,
            params?.cardId,
            params?.pagination ?? { page: 0, pageSize: 20 })

          if (params?.pagination?.page) {
            setHouseFlowDetailsItem(prev => ([...(prev ?? []), ...response.records]));
            setCount(response.totalRecords);
          } else {
            setHouseFlowDetailsItem(([...response.records]));
            setCount(response.totalRecords);
          }
          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );

  return {
    detailsItem,
    isLoading,
    getHouseFlowDetailsItem,
    setHouseFlowDetailsItem,
    count,
  };
};

export default UseHouseFlowDetailsItem;
