import Button from "components/ui/Button/Button";
import SaasPublicLayout from "../SaasPublicLayout"
import { CircularProgress } from "@mui/material";
import styles from "./CieloSystemPage.module.scss";
import { useState } from "react";
import { UseCieloSystemPage } from "./UseCieloSystemPage";

enum ICieloSystem {
    freeEstablishment = 3,
    event = 6,
    establishment = 7
}

const CieloSystemPage = () => {
    const { isLoading, type, handleSubmit } = UseCieloSystemPage();

    const [selectedSystem, setSelectedSystem] = useState<ICieloSystem>();

    return (
        <SaasPublicLayout step={1} totalSteps={6} progressBarColor="#00739E" backgroundColorTop="#00AEEF" backgroundColorBottom="#00AEEF" terms>
            <div className={styles.container}>
                <h1>Olá! Conta para a gente: <b>como você irá usar o sistema?</b></h1>
                <div className={styles.content}>
                    <button
                        className={selectedSystem === ICieloSystem.event ? styles.selected : ""}
                        onClick={() => setSelectedSystem(ICieloSystem.event)}
                    >
                        <img src="/assets/icon/saas/party-icon.png" alt="Evento" />
                        <span>Sou um <br/><b>evento!</b></span>
                    </button>
                    <button
                        className={selectedSystem === ICieloSystem.establishment || selectedSystem === ICieloSystem.freeEstablishment ? styles.selected : ""}
                        onClick={() => setSelectedSystem(type === "3" ? ICieloSystem.freeEstablishment : ICieloSystem.establishment)}
                    >
                        <img src="/assets/icon/saas/pizza-icon.png" alt="Estabelecimento" />
                        <span>Sou um <b>estabelecimento!</b></span>
                    </button>
                </div>
                <Button
                    type="submit"
                    style={{ backgroundColor: "#00AEEF" }}
                    onClick={() => handleSubmit(selectedSystem!)}
                    disabled={!selectedSystem}
                >
                    {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                </Button>
            </div>
        </SaasPublicLayout>
    )
}

export default CieloSystemPage;