import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CloseEventUseCase from "modules/events/application/useCases/events/CloseEventUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EventsApi } from "services/api/events/events/EventsApi";

const UseCloseEvent = (closeModal:() => void, isClosed: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { eventId } = useParams<{ eventId: string }>();
  const eventService = EventsApi();
  const { showLoading, hideLoading, toast } = useUi();
  const { currentLocal } = useLocal();

  const handleCloseEvent = useCallback(async () => {
    if (!currentLocal) return;
  
    try {
      showLoading();
      await CloseEventUseCase(eventService, eventId, currentLocal.id);
      toast("Evento fechado com sucesso!", "success");
      closeModal();
      isClosed(true)
    } catch (error: any) {
      toast( error.response?.data?.message, "error");
    } finally {
      hideLoading();
    }
  }, [closeModal, currentLocal, eventId, eventService, hideLoading, isClosed, showLoading, toast]);

  return {
    handleCloseEvent,
  };
};

export default UseCloseEvent;
