import { IPostCreateMenuPayload } from "modules/admin/menuConfig/domain/dto/IPostCreateMenuPayload";

export interface IMenuPaiFormErrors {
	title?: string;
  icon?: string;
}

export const MenuPaiValidation = (formData: IPostCreateMenuPayload, setErrors: React.Dispatch<React.SetStateAction<IMenuPaiFormErrors>>) => {
  let _errors: IMenuPaiFormErrors = {};
  let hasError = false;

  if (!formData.title) {
    _errors = { ..._errors, title: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.icon) {
    _errors = { ..._errors, icon: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
