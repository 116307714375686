import { IGetPaymentLinkByIdResponse } from "./IGetPaymentLinkByIdResponse";

export interface IPaymentType {
  paymentTypeId: PaymentTypeEnum;
  mePayLinkPaymentFields: IFields[];
}
export interface IFields {
  type?: PaymentTypeForm;
  isRequired?: boolean;
}

export enum PaymentTypeForm {
  DOCUMENT = 1,
  NAME = 2,
  PHONE = 3,
  EMAIL = 4,
  ADDRESS = 5,
}


export enum PaymentTypeEnum {
  CREDIT = 1,
  TICKET = 9,
  PIX = 18
}

export interface IPostPaymentLinkRequest {
  productId: string;
  localId: string;
  name: string;
  image?: string;
  paymentTypes: IPaymentType[];
  description?: string;
  successMessage?: string;
  price: number;
  expirationDate: string;
  maximumInstallments?: number;
  allowPaymentSlip?: boolean;
  interestPerInstallment?: number;
  shouldChargeFee?: boolean;
  isSinglePayment?: boolean;
  isRecurrenceLimit?: boolean;
  amountOfCharges?: number;
  billingStartsInCurrentMonth?: boolean;
}

export interface IPostPaymentLinkResponse extends IGetPaymentLinkByIdResponse {}
