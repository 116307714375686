import { FC, useCallback, useMemo } from 'react'
import styles from './InventoryOperationsList.module.scss'
import { IGetStockOperationListResponse, IStockOperationItemResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse';
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { SupplyStockMovementType } from 'modules/meepErp/models/stockMovement/_SupplyStockMovementType';
import { IStockOperationFilterValue } from './InventoryOperationsFilter/IInvetoryFilter';
import DefaultActionButtonBuilder from '../../ui/defaultListActionButtons/DefaultListActionButtons';
import { useAuth } from "modules/auth/presentation/context/AuthContext";
export interface IStockOperationsListProps {
    filter: IStockOperationFilterValue
    isLoading: boolean;
    inventoryOperations?: IGetStockOperationListResponse
    onChangeFilter: (filter: IStockOperationFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => void
    onClickEnable: (id: string, isActive: boolean, value: IStockOperationItemResponse) => Promise<IStockOperationItemResponse>
}
const StockOperationsList: FC<IStockOperationsListProps> = ({
    inventoryOperations,
    onDelete,
    onEdit,
    onClickEnable,
    filter,
    onChangeFilter,
    isLoading
}) => {
    const { hasAccessRole } = useAuth()

    const submitFilter = useCallback((values: IStockOperationFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ orderBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])


    const columnsConfig: ColumnConfigs<IStockOperationItemResponse> = {
        code: { title: "Código", sortable: true },
        name: { title: "Operação", sortable: true },
        inventoryMovementType: {
            title: "Tipo",
            renderRow: (value: number) => value === SupplyStockMovementType.Input ? "Entrada" : "Saída",
            sortable: true
        },
        canUpdateInventory: { title: "Atualiza estoque", renderRow: (value: boolean) => value ? "Sim" : "Não" },
        canUpdateInventoryCost: { title: "Atualiza custo", renderRow: (value: boolean) => value ? "Sim" : "Não" },
    }


    const actionButtons = DefaultActionButtonBuilder<IStockOperationItemResponse>({ onDelete, onEdit, onClickEnable })

    const pagination = useMemo(() => {
        return {
            page: inventoryOperations?.page ?? 1,
            pageSize: 10,
            count: inventoryOperations?.totalPages ?? 0
        }
    }, [inventoryOperations?.totalPages, inventoryOperations?.page])


    return (
        <div id={styles.InventoryOperationsList} >
            <List
                loading={isLoading}
                minWidth={700}
                list={inventoryOperations?.items}
                columnsConfigs={columnsConfig}
                // actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                // pagination={pagination}
                onChangePage={onChangePage}
                onClickSort={onClickSort}
            />
        </div>
    )
}
export default StockOperationsList