import GetNotifications from "../application/useCases/GetServicesNotifications";
import { useCallback, useState } from "react";
import { NotificationslApi } from "services/api/notifications/NotificationsOldApi";
import { NotificationProps } from "../domain/dto/INotificationProps";
import GetNotificationsByTypeUseCase from "../application/useCases/GetNotificationsByTypeUseCase";
import SaveReadNotificationUseCase from "../application/useCases/SaveReadNotificationUseCase";
import { IGetNotificationsByTypeResponse } from "../domain/dto/IGetNotificationsByTypeResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import UseChatBase from "./UseChatBase";
import GetTutoriaisUseCase from "modules/tutoriais/application/useCases/GetTutoriaisUseCase";
import TutoriaisApi from "services/api/tutoriais/TutoriaisApi";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";
import { AuthRepository } from "services/repository/auth/AuthRepository";

const notificationsService = NotificationslApi();
const tutoriaisApi = TutoriaisApi();
const integrationService = IntegrationApi();

const UseLayout = () => {
  const [notificationsList, setNotificationsList] =
    useState<NotificationProps[]>();
  const [requiredNotifications, setRequiredNotifications] =
    useState<IGetNotificationsByTypeResponse[]>();
  const [notRequiredNotifications, setNotRequiredNotifications] =
    useState<IGetNotificationsByTypeResponse[]>();
  const [bannerNotifications, setBannerNotifications] =
    useState<IGetNotificationsByTypeResponse[]>();
    const [localDocument, setLocalDocument] = useState<string>();

  const getToken = localStorage.getItem("@token");
  const token = getToken && JSON.parse(getToken);

  const showModal = localStorage.getItem("@showNotificationModal");
  const showBanner = localStorage.getItem("@showBanner");
  const { currentLocal } = useLocal();
  const { auth } = useAuth();

  UseChatBase(
    currentLocal?.systemIdentifier ?? 0,
    auth?.user?.name ?? "",
    auth?.user?.id ?? "",
    auth?.user?.email ?? ""
  );
  
  const getNotifications = useCallback(() => {
    GetNotifications(notificationsService, token?.access_token, currentLocal?.systemIdentifier ?? 0).then(
      (response) => {
        setNotificationsList(response);
      }
    );
  }, [currentLocal?.systemIdentifier, token?.access_token]);

  const getNotificationsByType = useCallback((type?: string) => {
    const token = AuthRepository().get()?.access_token ?? "";
    GetNotificationsByTypeUseCase(notificationsService, token, currentLocal?.systemIdentifier ?? 0, type).then(
      (response) => {
        if (!type) {
          const required = response.filter((item) => item.MensagemDashBoardTipo === 1);
          const notRequired = response.filter((item) => item.MensagemDashBoardTipo === 2);
          const banner = response.filter((item) => item.MensagemDashBoardTipo === 3);

          setRequiredNotifications(required);
          setNotRequiredNotifications(notRequired);
          setBannerNotifications(banner);
          return;
        }
        if (type === "1") {
          setRequiredNotifications(response);
        }
        if (type === "2") {
          setNotRequiredNotifications(response);
        }
        if (type === "3") {
          setBannerNotifications(response);
        }
      }
    );
  }, [currentLocal?.systemIdentifier]);

  const saveReadNotification = useCallback(
    async (id: string) => {
      try {
        await SaveReadNotificationUseCase(notificationsService, { id: id });
        return "ok";
      } catch (error: any) {
        return error.response;
      }
    }, []
  );

  const getTutoriaisList = useCallback(
    async (page: number, menuId: string) => {
      try {
        const response = await GetTutoriaisUseCase(tutoriaisApi, page, { menuId });
        return response.records;
      } catch (error: any) {
        return error.response;
      }
    },
    []
  );

  const getLocalMainData = useCallback(async () => {
    if (currentLocal) {
      const response = await GetLocalMainDataUseCase(integrationService, currentLocal.id);
      setLocalDocument(response.document);
    }
  }, [currentLocal]);

  return {
    notificationsList,
    getNotifications,
    showModal,
    showBanner,
    requiredNotifications,
    notRequiredNotifications,
    bannerNotifications,
    getNotificationsByType,
    saveReadNotification,
    getTutoriaisList,
    localDocument,
    getLocalMainData,
  };
};

export default UseLayout;
