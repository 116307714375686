import { FC } from "react";
import { AuthProvider } from "modules/auth/presentation/context/AuthContext";
import AuthPage from "modules/auth/presentation/page/AuthPage";
import SaasAuthPage from "modules/auth/presentation/page/SaasAuthPage";

export interface ILoginProps {
  //propertys
}
const Login: FC<ILoginProps> = () => {
  const baseUrl = window.location.origin;

  return (
    <AuthProvider disableRedirectToLogin>
      {baseUrl.includes("connect.meep") ? <SaasAuthPage /> : <AuthPage />}
    </AuthProvider>
  );
};
export default Login;
