import { Drawer, Icon } from '@material-ui/core';
import styles from './OnlinePaymentHistoryModal.module.scss';
import { useEffect, useState } from 'react';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { OnlinePaymentApi } from 'services/api/onlinePayment/OnlinePaymentApi';
import { GetLocalOnlinePaymentHistoryUseCase } from 'modules/onlinePayment/application/useCases/GetLocalOnlinePaymentHistoryUseCase';
import { ILocalOnlineItemPayment } from '../../interfaces/ILocalOnlinePayment';
import { IOnlinePaymentHistory } from '../../interfaces/IOnlinePaymentHistory';

interface PlanHistoryModalProps {
    open: boolean;
    localOnlinePayment: ILocalOnlineItemPayment;
    onClose: () => void;
}

const service = OnlinePaymentApi();

export const OnlinePaymentHistoryModal = ({ open, localOnlinePayment, onClose }: PlanHistoryModalProps) => {
    const { toast } = useUi();

    const [history, setHistory] = useState<IOnlinePaymentHistory[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await GetLocalOnlinePaymentHistoryUseCase(service, localOnlinePayment.localId);
                setHistory(response);
            } catch {
                toast('Ocorreu um erro ao buscar o histórico de transações desse local', 'error');
            } finally {
                setLoading(false);
            }
        })();
    }, [localOnlinePayment.localId, toast]);

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Solicitações de <b>pagamento</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    {
                        loading ?
                            <>
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                                <Skeleton variant="rectangular" width="100%" height={50} style={{ marginBottom: 8, borderRadius: 8 }} />
                            </> :
                            history.map(x => (
                                <div className={styles.row}>
                                    <div><Icon>work_outlined</Icon></div>
                                    <div>
                                        <span>{format(new Date(x.changeDate), 'dd/MM/yyyy HH:mm')}</span>
                                        <h2>{x.changeTitle}</h2>
                                        <span>{x.changeDescription} {x.changedBy ? `por ${x.changedBy}` : ''}</span>
                                    </div>
                                </div>
                            ))
                    }
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}