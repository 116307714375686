import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { PerfilApi } from "services/api/perfil/PerfilApi";
import { useParams } from "react-router-dom";
import GetPerfilEvento from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/GetPerfilEventoUseCase";
import PostPerfilEvento from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/PostPerfilEventoUseCase";
import {
  IDisponibilidadeResponse,
  IPerfilEventoResponse,
} from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/IPerfilService";
import UpdateAddPerfilEvento from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/UpdatePerfilUseCase";
import DeletePerfilEvento from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/DeletePerfilUseCase";
import UpdateEquipamentos from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/UpdateEquipamentosUseCase";
import GetEquipamentos from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/GetEquipamentosUseCase";
import GetDisponibilidade from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/GetDisponibilidadeUseCase";
import {
  IFilterProfileDevice,
  IPaginationProfileDevice,
} from "../../components/deviceProfile/filterProfile/IFilterDeviceProfile";

const UseProfileEventoPage = () => {
  const [perfilEvento, setPerfilEvento] = useState<
    IPerfilEventoResponse | undefined
  >();
  const [filterProfileEvent, setFilterProfileEvent] =
    useState<IFilterProfileDevice>({});

  const { currentLocal } = useLocal();

  const perfilService = PerfilApi();

  const { eventId } = useParams<{ eventId: string }>();

  const [profileName, setProfileName] = useState("");

  const [devices, setDevices] = useState();

  const [disponibilidade, setDisponibilidade] = useState<
    IDisponibilidadeResponse[] | undefined
  >();

  const updateAddPerfilEvento = useCallback(
    (profiles) => {
      if (currentLocal) {
        UpdateAddPerfilEvento(perfilService, currentLocal?.id, {
          eventId: eventId,
          profiles: profiles,
        }).then(() => {
          getPerfilEvento();
        });
      }
    },
    [currentLocal]
  );

  const getEquipamentos = useCallback(
    (profileId, deviceName?, deviceType?) => {
      if (currentLocal) {
        GetEquipamentos(
          perfilService,
          currentLocal?.id,
          eventId,
          profileId,
          deviceName,
          deviceType
        ).then((response) => {
          setDevices(response.devices);
          getPerfilEvento();
        });
      }
    },
    [currentLocal]
  );

  const updateEquipamentos = useCallback(
    (body, profileId) => {
      if (currentLocal) {
        UpdateEquipamentos(perfilService, currentLocal?.id, body);
      }
    },
    [currentLocal]
  );

  const onSearch = (e: string) => {
    setProfileName(e);
  };

  const postAddPerfilEvento = useCallback(
    (profiles) => {
      if (currentLocal) {
        PostPerfilEvento(perfilService, currentLocal?.id, {
          eventId: eventId,
        }).then(() => {
          updateAddPerfilEvento(profiles);
        });
      }
    },
    [currentLocal]
  );

  const deletePerfilEvento = useCallback(
    (profielId) => {
      if (currentLocal) {
        DeletePerfilEvento(
          perfilService,
          currentLocal?.id,
          eventId,
          profielId
        ).then(() => {
          getPerfilEvento();
        });
      }
    },
    [currentLocal]
  );

  const getDisponibilidade = useCallback(() => {
    if (currentLocal) {
      GetDisponibilidade(perfilService, currentLocal?.id).then((response) => {
        setDisponibilidade(response.equipamentos);
      });
    }
  }, [currentLocal]);

  const getPerfilEvento = useCallback(
    (limit?, offset?) => {
      if (currentLocal) {
        GetPerfilEvento(
          perfilService,
          currentLocal?.id,
          eventId,
          limit || filterProfileEvent.pagination?.limit || 50,
          offset || filterProfileEvent.pagination?.offset || 0,
          profileName
        ).then((response) => {
          setPerfilEvento(response);
        });
      }
    },
    [currentLocal, profileName]
  );

  const onChangePaginationHandleEvent = useCallback(
    async (pagination: IPaginationProfileDevice) => {
      setFilterProfileEvent((prev) => ({
        ...prev,
        pagination: {
          offset: (pagination.offset ?? 0) * (pagination.limit ?? 50),
          limit: pagination.limit,
        },
      }));
      await getPerfilEvento(pagination.limit, (pagination.offset ?? 0) * (pagination.limit ?? 50));
    },
    [setFilterProfileEvent, getPerfilEvento]
  );

  useEffect(() => {
    getPerfilEvento();
    getDisponibilidade();
  }, [currentLocal, eventId, profileName]);

  return {
    perfilEvento,
    postAddPerfilEvento,
    getPerfilEvento,
    updateAddPerfilEvento,
    deletePerfilEvento,
    onSearch,
    profileName,
    updateEquipamentos,
    getEquipamentos,
    devices,
    disponibilidade,
    onChangePaginationHandleEvent,
    filterProfileEvent,
  };
};

export default UseProfileEventoPage;
