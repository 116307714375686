import { FormControlLabel, Switch } from '@material-ui/core'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './DeviceCatalogForm.module.scss'
import { IDeviceCatalogItem, IDeviceCatalogValue } from './IDeviceCatalogForm'
export interface IDeviceCatalogFormProps {
    //propertys
    defaultValue?: IDeviceCatalogValue[];
    catalogs?: IDeviceCatalogItem[];
    modified: boolean;
    onModified: (modified: boolean) => void;
    onSubmit: (values: IDeviceCatalogValue[]) => void
    onClickBack?: (values: IDeviceCatalogValue[]) => void
}
const DeviceCatalogForm: FC<IDeviceCatalogFormProps> = ({ defaultValue, catalogs, modified, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IDeviceCatalogValue[]>(defaultValue ?? []);

    useEffect(() => {
        if (catalogs) {
            if (defaultValue) {
                const catalogValues = catalogs.map<IDeviceCatalogValue>((item) => ({ ...item, enabled: defaultValue.find(defaultItem => defaultItem.id === item.id)?.enabled ?? false }))
                setValues(catalogValues)
                onModified(false);
            }
        } else {
            console.error("Nao foi enviado a lista de cardapios")
        }
    }, [catalogs, defaultValue, onModified]);

    const changeHandle = useCallback((catalogId: string, checked: boolean) => {
        setValues((prev) => (prev.map(item => (item.id === catalogId ? ({ ...item, enabled: checked }) : (item)))));
        onModified(true);
    }, [onModified]);

    const CatalogList = useMemo(() => (
        catalogs?.map(catalogItem => (
            <FormItemContainer >
                <FormControlLabel
                    checked={values.find(valueItem => valueItem.id === catalogItem.id)?.enabled}
                    onChange={(ev, checked) => changeHandle(catalogItem.id, checked)}
                    control={<Switch color='secondary' />} label={catalogItem.name}
                />
            </FormItemContainer>
        ))
    ), [catalogs, changeHandle, values]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])

    return (
        <form id={styles.DeviceCatalogForm} onSubmit={submitHandle}>
            <div className={styles.container} >
                <div>
                    {CatalogList}
                </div>
            </div>
            <FooterButtons
                onClickBack={() => onClickBack?.(values)}
                labelNext={"Salvar"}
                nextType="submit"
            />
        </form>
    )
}
export default DeviceCatalogForm