import { FC, useEffect } from "react";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import CargosPage from "modules/cargos/presentation/pages/CargosPage";

const Cargos: FC = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Equipe",
      },
      {
        label: "Equipe",
        title: "Cargos",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <CargosPage />
    </Layout>
  );
};
export default Cargos;
