import { IGetKdsListRequest } from "modules/kds/domain/dto/getKdsList/GetKdsListRequest"
import { IKDSLocalStorage } from "modules/kds/domain/interfaces/IKDSLocalStorage"
import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSList } from "modules/kds/presentation/components/KDSGrid/IKDSList"
import { IKDSFilter } from "modules/kds/presentation/interfaces/IKDSFilter"

const GetKDSListUseCase = async (service: IKDSService, localStorage: IKDSLocalStorage, filter: IKDSFilter, localId: string): Promise<IKDSList> => {

    const adjustTime = (date: string | undefined): string => {
        if (!date) return '';
        const brasiliaOffset = -3; // UTC-3
        const dateObj = new Date(date);
        dateObj.setUTCHours(dateObj.getUTCHours() + brasiliaOffset);
        return dateObj.toISOString();
    };

    const request: IGetKdsListRequest = {
        localId: localId,
        startDate: adjustTime(String(filter.startDate)),
        endDate: adjustTime(String(filter.endDate)),
        stepIds: filter.steps?.map(item => item.id),
        deviceId: filter.deviceId,
        input: filter.input,
        tableIds: filter?.tables?.map(item => item.id),
        page: filter.page,
        pageSize: filter.pageSize,
        storesId: filter.store?.id,
        orientation: filter.orientation,
        groupedByTable: false,
        sortByTable: false,
        maxCodigoPainelSenha: filter.maxPanelCode,
        minCodigoPainelSenha: filter.minPanelCode
    };

    const response = await service.getKDSList(request);
    if (response.items.length && filter.page === 1) {
        localStorage.saveLastOrderId(response.items[0].id);
    }
    return (response);
};

export default GetKDSListUseCase;



