import styles from "./CashClosingItemMobile.module.scss";
import { IResultCategories } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface ICashClosingItemMobileProps {
  item: IResultCategories;
}

const CashClosingItemMobile = ({ item }: ICashClosingItemMobileProps) => {
  return (
    <div id={styles.CashClosingItemMobile} className="div-to-capture">
      {item.Itens && item.Itens.map((categoryItem) => (
        <div className={styles.categoryItem} key={categoryItem.Id}>
          <div className={styles.item}>
            <span className={styles.title}>Origem:</span>
            <span>{categoryItem.Nome}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cancelado:</span>
            <span>{moneyMaskNumber(categoryItem.Cancelado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cashless:</span>
            <span>{moneyMaskNumber(categoryItem.Cashless ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito:</span>
            <span>{moneyMaskNumber(categoryItem.Debito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito (NT):</span>
            <span>{moneyMaskNumber(categoryItem.DebitoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito:</span>
            <span>{moneyMaskNumber(categoryItem.Credito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito (NT):</span>
            <span>{moneyMaskNumber(categoryItem.CreditoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Dinheiro:</span>
            <span>{moneyMaskNumber(categoryItem.Dinheiro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Desconto:</span>
            <span>{moneyMaskNumber(categoryItem.Desconto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Voucher:</span>
            <span>{moneyMaskNumber(categoryItem.Voucher ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Outros:</span>
            <span>{moneyMaskNumber(categoryItem.Outro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Em aberto:</span>
            <span>{moneyMaskNumber(categoryItem.EmAberto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Suprimento:</span>
            <span>{moneyMaskNumber(categoryItem.Suprimento ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Sangria:</span>
            <span>{moneyMaskNumber(categoryItem.Sangria ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total:</span>
            <span>{moneyMaskNumber(categoryItem.TotalItem ?? 0)}</span>
          </div>
        </div>
      ))}
      <div className={styles.totalItem}>
        <div className={styles.item}>
          <span>{item.Nome}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total cancelado:</span>
          <span>{moneyMaskNumber(item.Cancelado ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total cashless:</span>
          <span>{moneyMaskNumber(item.Cashless ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total débito:</span>
          <span>{moneyMaskNumber(item.Debito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total débito (NT):</span>
          <span>{moneyMaskNumber(item.DebitoNT ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total crédito:</span>
          <span>{moneyMaskNumber(item.Credito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total crédito (NT):</span>
          <span>{moneyMaskNumber(item.CreditoNT ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total dinheiro:</span>
          <span>{moneyMaskNumber(item.Dinheiro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total desconto:</span>
          <span>{moneyMaskNumber(item.Desconto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total voucher:</span>
          <span>{moneyMaskNumber(item.Voucher ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total outros:</span>
          <span>{moneyMaskNumber(item.Outro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total em aberto:</span>
          <span>{moneyMaskNumber(item.EmAberto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total suprimento:</span>
          <span>{moneyMaskNumber(item.Suprimento ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total sangria:</span>
          <span>{moneyMaskNumber(item.Sangria ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total:</span>
          <span>{moneyMaskNumber(item.TotalCategoria ?? 0)}</span>
        </div>
      </div>
    </div>
  );
};
export default CashClosingItemMobile;
