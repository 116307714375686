import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./PaymentLinkRecurrenceItemMobile.module.scss";
import { Close, KeyboardArrowRight } from "@mui/icons-material";
import CPF from "services/utils/CPF";
import CNPJ from "services/utils/CNPJ";
import { moneyMaskNumber } from "services/utils/Money";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UsePaymentLinkDetailsList from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkDetailsList";
import UsePaymentLinkCancelSubscription from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkCancelSubscription";
import DeleteValidation from "components/deleteValidation/DeleteValidation";
import PaymentLinkDetails from "modules/paymentLinkReport/presentation/components/paymentLinkDetails/PaymentLinkDetails";
import { IParamsPaymentLinkList } from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkReportList";
import {
  IMePayLinkPaymenItemResult,
  IPaymentLinkRecurrenceList,
} from "modules/paymentLinkReport/presentation/interfaces/IPaymentLinkRecurrenceList";
import Utils from "services/utils/Utils";

interface IPaymentLinkRecurrenceItemMobileProps {
  userItemData: IMePayLinkPaymenItemResult;
  paymentLinkRecurrenceList: IPaymentLinkRecurrenceList;
  reload: () => void;
  params: IParamsPaymentLinkList;
}

export const PaymentLinkRecurrenceItemMobile: React.FC<
  IPaymentLinkRecurrenceItemMobileProps
> = ({ reload, userItemData }) => {
  const [step, setStep] = useState(1);
  const [openDrownDelete, setOpenDrownDelete] = useState(false);
  const [openDrownDetails, setOpenDrownDetails] = useState(false);
  const { cancelSubscriptionPaymentLink, isLoadingCancel } =
    UsePaymentLinkCancelSubscription();
  const { getPaymentLinkDetailsList, isLoading, paymentLinkDetailsList } =
    UsePaymentLinkDetailsList();

  const onClose = useCallback(() => {
    if (step === 2) {
      reload();
    }
    setStep(1);
    setOpenDrownDelete(false);
  }, [reload, step]);

  const onCloseDetails = useCallback(() => {
    setOpenDrownDetails(false);
  }, []);

  const continueOrSave = useCallback(async () => {
    if (step === 2) {
      onClose();
    } else {
      const res = await cancelSubscriptionPaymentLink(userItemData.id);
      if (res === "ok") {
        return setStep(step + 1);
      }
    }
  }, [cancelSubscriptionPaymentLink, onClose, step, userItemData.id]);

  const continueDetails = useCallback(() => {
    if (step === 1) {
      onCloseDetails();
    }
  }, [onCloseDetails, step]);

  const closedOrDeleteButton = useCallback(() => {
    if (step === 2) {
      return "Fechar";
    } else {
      return "Cancelar";
    }
  }, [step]);

  const closedOrDetailsButton = useCallback(() => {
    return "Fechar";
  }, []);

  const openDrowDelete = useCallback(() => {
    setOpenDrownDelete(true);
  }, []);

  const openDrowModalDetails = useCallback(() => {
    setOpenDrownDetails(true);
  }, []);

  const formatDocument = useCallback((document: string) => {
    if (CPF.isValid(document)) return CPF.cpfMask(document);
    else return CNPJ.mask(document);
  }, []);

  useEffect(() => {
    if (openDrownDetails) {
      getPaymentLinkDetailsList(userItemData.id);
    }
  }, [getPaymentLinkDetailsList, openDrownDetails, userItemData.id]);

  const handleContent = useCallback(() => {
    let highlightText = `${userItemData.description}?`;
    let message = `Deseja realmente cancelar a recorrência`;

    return <DeleteValidation text={message} highlightText={highlightText} />;
  }, [userItemData.description]);

  const handleContentDetails = useCallback(() => {
    return (
      <PaymentLinkDetails
        paymentLinkDetailsList={paymentLinkDetailsList}
        isLoading={isLoading}
      />
    );
  }, [isLoading, paymentLinkDetailsList]);

  const List = useMemo(
    () => (
      <div className={styles.containerItem}>
        <div className={styles.top}>
          <span>{userItemData.description}</span>
          <div className={styles.icon}>
            {userItemData.isActive && (
              <Close titleAccess="Cancelar" onClick={openDrowDelete} />
            )}
            <KeyboardArrowRight
              titleAccess="Detalhes"
              onClick={openDrowModalDetails}
            />
          </div>
        </div>
        <div className={styles.mid}>
          <div>
            <span>Produto:</span>
            <span>{userItemData.productName}</span>
          </div>
          <div>
            <span>Data assinatura:</span>
            <span>{Utils.toDate(userItemData?.createdAt + "Z")}</span>
          </div>
          <div>
            <span>CNPJ/CPF:</span>
            <span>{formatDocument(userItemData.document)}</span>
          </div>
          <div>
            <span>Nome:</span>
            <span>{userItemData.userName}</span>
          </div>
          <div>
            <span>Valor:</span>
            <span>{moneyMaskNumber(userItemData?.value)}</span>
          </div>
        </div>
        <div className={styles.footer}>
          <span>Status:</span>
          <span>
            {userItemData?.isActive ? (
              <div className={styles.active}>Ativo</div>
            ) : (
              <div className={styles.cancel}>Cancelado</div>
            )}
          </span>
        </div>
      </div>
    ),
    [
      formatDocument,
      openDrowDelete,
      openDrowModalDetails,
      userItemData?.createdAt,
      userItemData.description,
      userItemData.document,
      userItemData.isActive,
      userItemData.productName,
      userItemData?.value,
    ]
  );

  return (
    <div id={styles.PaymentLinkRecurrenceItemMobile}>
      {List}      
      <Sidesheet
        isLoading={isLoadingCancel}
        open={openDrownDelete}
        onClose={onClose}
        title={
          <h2>
            Cancelar <b>assinatura</b>
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        handleContinueButton={continueOrSave}
        continueButton={closedOrDeleteButton()}
        cancelButton
        customizedCancelButton={"Voltar"}
        feedback={
          <SidesheetFeedback
            text="Recorrência cancelada com sucesso!"
            success
          />
        }
      />
      <Sidesheet
        isLoading={isLoading}
        open={openDrownDetails}
        onClose={onCloseDetails}
        title={
          <h2>
            Mais <b>informações</b>
          </h2>
        }
        content={handleContentDetails()}
        currentStep={step}
        totalSteps={1}
        handleContinueButton={continueDetails}
        continueButton={closedOrDetailsButton()}
      />
    </div>
  );
};
