import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import stylesForm from "../FidelityForm.module.scss";
import styles from './FidelityProductsForm.module.scss'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { Button, Icon, Switch, TextField } from '@material-ui/core'
import FidelityProductItemForm from './fidelityProductItemForm/FidelityProductItemForm'
import ExchangeForm, { ICoinExchangeValues } from '../exchange/ExchangeForm';
import { moneyMask, moneyMaskNumber } from 'services/utils/Money';

export interface IProductItem {
    id: string,
    name: string,
    imageUrl: string,
    price: number
}


export interface IProductFidelity {
    id: string,
    name: string,
    imageUrl: string,
    price: number,
    coinsPrice: number,
    isActive: boolean
}


export interface IFidelityProductsFormProps {
    //propertys
    getProductList: () => Promise<IProductItem[]>
    currentExchange?: number
    onPressBack?: () => void
    onSubmit: (values: IProductFidelity[]) => void
    getDefault: () => Promise<IProductFidelity[] | undefined>
}
const FidelityProductsForm: FC<IFidelityProductsFormProps> = ({ currentExchange, getProductList, onPressBack, onSubmit, getDefault }) => {

    const [fidelityProductsEdited, setFidelityProductsEdited] = useState<IProductFidelity[]>();
    const [productList, setProductList] = useState<IProductItem[]>([]);
    const [selectedProductItem, setselectedProductItem] = useState<IProductItem | null>(null);

    var getProductListData = useCallback(async () => {
        var response = await getProductList()
        setProductList(response);
    }, [getProductList])

    var getDefaultValues = useCallback(async () => {
        var response = await getDefault();
        if (response) {
            setFidelityProductsEdited(response);
        }
    }, [getDefault])

    useEffect(() => {
        getDefaultValues();
    }, [getDefaultValues])

    useEffect(() => {
        getProductListData()
    }, [getProductListData])

    const addNewProduct = useCallback((currentSelected: IProductFidelity[] = [], _product: IProductItem, _currentExchange?: number) => {
        const hasProduct = currentSelected.find(item => item.id === _product.id)
        if (hasProduct) {
            return (currentSelected)
        }
        const newProduct: IProductFidelity = {
            ..._product,
            coinsPrice: Math.ceil(_product.price / (_currentExchange ?? 1)),
            isActive: true
        }
        return [...currentSelected, newProduct]
    }, [])

    const removeProduct = (currentSelected: IProductFidelity[] = [], _product: IProductFidelity) => {
        return currentSelected.filter(item => item.id !== _product.id)
    }

    const filterOptions = (options: IProductItem[], selected: IProductFidelity[] = []) => {
        return options.filter(option => !selected.find(item => item.id === option.id))
    }

    const changeProduct = (selectedPrdocuts: IProductFidelity[] = [], productItem: IProductFidelity) => {
        return selectedPrdocuts.map(item => {
            if (productItem.id === item.id) {
                return productItem
            }
            else {
                return item
            }
        })
    }

    const selectAll = useCallback((_selectedProducts: IProductFidelity[] = [], _productList: IProductItem[], _currentExchange?: number) => {
        var newSelectedProducts = _selectedProducts;
        _productList.forEach((item) => { newSelectedProducts = addNewProduct(newSelectedProducts, item, _currentExchange) })
        return newSelectedProducts;
    }, [addNewProduct])

    const repriceAll = useCallback((_selectedProducts: IProductFidelity[] = [], _currentExchange?: number) => {
        return _selectedProducts.map((item) => ({ ...item, coinsPrice: Math.ceil(item.price / (_currentExchange ?? 1)) }))
    }, [])

    const onSelectProduct = useCallback(
        (value: IProductItem | null) => {
            if (value) {
                setselectedProductItem(value);
                setFidelityProductsEdited(prev => addNewProduct(prev, value, currentExchange));
            }
        }, [addNewProduct, currentExchange])

    const onRemoveProduct = useCallback(
        (value: IProductFidelity | null) => {
            if (value) {
                setFidelityProductsEdited(prev => removeProduct(prev, value))
                setselectedProductItem(null);
            }
        }, [])

    const onChangeProduct = useCallback(
        (value: IProductFidelity | null) => {
            if (value) {
                setFidelityProductsEdited(prev => changeProduct(prev, value))
                setselectedProductItem(null);
            }
        }, [])

    const onSelectAll = useCallback((_, value) => {
        if (value) {

            setFidelityProductsEdited(prev => selectAll(prev, productList, currentExchange))
        } else {
            setFidelityProductsEdited([])
        }
    }, [currentExchange, productList, selectAll])

    const onRepriceAll = useCallback(() => {
        setFidelityProductsEdited(prev => repriceAll(prev, currentExchange))
    }, [currentExchange, repriceAll],)



    const productFidelitList = useMemo(() => fidelityProductsEdited?.map(fidelityProductItem =>
        <FidelityProductItemForm
            fidelityProductItem={fidelityProductItem}
            onChange={onChangeProduct}
            onDelete={onRemoveProduct}
        />
    ), [onChangeProduct, onRemoveProduct, fidelityProductsEdited])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (fidelityProductsEdited)
            onSubmit(fidelityProductsEdited);
    }, [fidelityProductsEdited, onSubmit])

    return (
        <div>
            <div className={styles.description}><Icon>info-outline</Icon><span>Seu cliente poderá trocar as moedas obtidas pelos produtos selecionados abaixo. Os que não estiverem habilitados não poderão ser pagos com moedas.</span></div>
            <div className={styles.exchange}>
                <div>
                    <label>Cada moeda:</label>
                    <input disabled value={1}></input>
                </div>     <Icon>east</Icon> <div>
                    <label>Equivale a:</label>
                    <input disabled value={moneyMaskNumber(currentExchange)}></input>
                </div>
            </div>
            <div className={styles.productForm}>
                <form onSubmit={submitHandle} >
                    <div className={styles.formContainer}>


                        <div className={styles.item}>
                            <label> Busque produtos</label>
                            <Autocomplete
                                fullWidth
                                options={productList}

                                value={selectedProductItem}
                                renderInput={(params) => <TextField variant='outlined' placeholder='Buscar produto'{...params} />}
                                getOptionLabel={option => option.name}
                                filterOptions={(options) => filterOptions(options, fidelityProductsEdited)}
                                onChange={(_, value) => onSelectProduct(value)}
                            />
                        </div>
                        <div className={styles.actions}>
                            <div className={stylesForm.item}>
                                <Button onClick={onRepriceAll} color='secondary'><Icon>refresh</Icon> Voltar preços</Button>
                            </div>
                            <div className={stylesForm.item}>
                                <label>Selecionar todos</label>
                                <Switch aria-label='Selecionar todos' checked={productList.length === productFidelitList?.length} onChange={onSelectAll} ></Switch>
                            </div>
                        </div>
                        <div className={stylesForm.item}>
                            <div className={styles.productList} >
                                {productFidelitList}
                            </div>
                        </div>
                    </div>
                    <div className={stylesForm.buttonContainer}>
                        <Button className={stylesForm.buttons} type='button' onClick={onPressBack} variant='outlined' color='secondary'>Cancelar</Button>
                        <Button className={stylesForm.buttons} type='submit' variant='contained' color='secondary'>Continuar</Button>
                    </div>
                </form >
            </div >
        </div >
    )
}
export default FidelityProductsForm


