import styles from "components/sidesheet/Sidesheet.module.scss";
import Input from "components/ui/input/Input";
import { IPostIFoodMarketConfigRequest } from "modules/config/integrationsConfig/domain/dto/iFoodMarketConfig/IPostIFoodMarketConfigRequest";
import { IFoodMarketFormErrors } from "../../../pages/configIFoodMarket/validade/IFoodMarketValidation";

export interface IConfigContentIFoodMarketProps {
  step: number;
  values: IPostIFoodMarketConfigRequest;
  setValues: React.Dispatch<React.SetStateAction<IPostIFoodMarketConfigRequest>>;
  error: IFoodMarketFormErrors;
  setError: React.Dispatch<React.SetStateAction<IFoodMarketFormErrors>>;
}

const ConfigContentIFoodMarket = ({
  step,
  values,
  setValues,
  error,
  setError,
}: IConfigContentIFoodMarketProps) => {
  return (
    <>
      {step === 1 && (
        <>
          <div>
            <label htmlFor="user" className={styles.required}>
              Usuário
            </label>
            <Input
              name="user"
              variant={"outlined"}
              value={values?.username}
              onChange={(ev) => {
                setError({...error, username: undefined });
                setValues((prev) => ({
                  ...prev,
                  username: ev.target.value,
                }));
              }}
              error={!!error.username}
              helperText={error.username}
            />
          </div>
          <div>
            <label htmlFor="password" className={styles.required}>
              Senha
            </label>
            <Input
              name="password"
              variant={"outlined"}
              value={values?.password}
              onChange={(ev) => {
                setError({...error, password: undefined });
                setValues((prev) => ({
                  ...prev,
                  password: ev.target.value,
                }));
              }}
              error={!!error.password}
              helperText={error.password}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfigContentIFoodMarket;
