import { makeStyles, TextField } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import PageHeader from "components/ui/pageHeader/PageHeader";
import Utils from "services/utils/Utils";
import GrantMultipleAccessForm from "../../components/grantMultipleAccessForm/GrantMultipleAccessForm";
import { IArrObj } from "../../utils/utils";
import GuestListForm from "./components/GuestListForm/GuestListForm";
import { GuestsLists } from "./components/GuestsLists/GuestsLists";
import styles from "./EventGuestsListsPage.module.scss";
import { IGuestListFilter } from "./interfaces/IEventGuestsListsPage";
import { UseEventGuestsListsPage } from "./UseEventGuestsListsPage";

const useStyles = makeStyles({
  root: {
    height: "40px",
    width: "100%",
    padding: 0,
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
    },
  },
});

export const EventGuestsListsPage = () => {
  const CustomTextField = (props: any) => {
    const classes = useStyles();
    return <TextField {...props} className={classes.root} />;
  };

  const {
    showMultipleAccessModal,
    closeMultipleAccessModal,
    openMultipleAccessModal,
    handleGrantMultipleAccess,
    adminAccess,
    nameFilter,
    promoterFilter,
    closingDate,
    baseListFilter,
    getEventLists,
    filterByList,
    filterByBaseList,
    filterByPromoter,
    filterByClosingDate,
    filteredEventGuestLists,
    closeAddListModal,
    openAddListModal,
    showAddListModal,
    handleAddList,
  } = UseEventGuestsListsPage();

  return (
    <div id={styles.EventGuestsListsPage}>
      <PageHeader title="Listas de convidados" />

      <div className={styles.infoEvent}>
        <section className={styles.row}>
          <section className={styles.columnButton}>
            <Button
              fullWidth={false}
              onClick={openAddListModal}
              className={`${styles.infoEventButton} ${styles.addList}`}
            >
              Adicionar lista
              <AddCircle />
            </Button>
          </section>
          <section className={styles.columnButton}>
            {/* <Button
              fullWidth={false}
              onClick={openMultipleAccessModal}
              className={`${styles.infoEventButton} ${styles.promoters}`}
              variant="outlined"
            >
              Configuração de Promoters
            </Button> */}
          </section>
        </section>
        <section className={styles.row}>
          <section className={styles.column}>
            <label>Data de fechamento</label>
            <Autocomplete
              options={closingDate}
              fullWidth
              getOptionLabel={(option) => Utils.toDateAndTime(option.label)}
              onChange={(_evt, value: IArrObj | null) =>
                filterByClosingDate(value)
              }
              size="small"
              renderInput={(params) => (
                <CustomTextField
                  placeholder="Data de fechamento"
                  color={"secondary"}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </section>
          <section className={styles.column}>
            <label>Nome da lista</label>
            <Autocomplete
              options={nameFilter}
              fullWidth
              getOptionLabel={(option) => option.name}
              onChange={(_evt, value: IGuestListFilter | null) =>
                filterByList(value)
              }
              size="small"
              renderInput={(params) => (
                <CustomTextField
                  placeholder="Digite o nome da lista"
                  color={"secondary"}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </section>
          <section className={styles.column}>
            <label>Promoter responsável</label>
            <Autocomplete
              options={promoterFilter}
              fullWidth
              getOptionLabel={(option) => option.name}
              onChange={(_evt, value: IGuestListFilter | null) =>
                filterByPromoter(value)
              }
              size="small"
              renderInput={(params) => (
                <CustomTextField
                  placeholder="Digite o nome da lista"
                  color={"secondary"}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </section>
          <section className={styles.column}>
            <label>Lista de desconto</label>
            <Autocomplete
              options={baseListFilter}
              fullWidth
              getOptionLabel={(option) => option.name}
              onChange={(_evt, value: IGuestListFilter | null) =>
                filterByBaseList(value)
              }
              size="small"
              renderInput={(params) => (
                <CustomTextField
                  placeholder="Digite o nome da lista"
                  color={"secondary"}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </section>
        </section>
      </div>

      <GuestsLists
        adminAccess={adminAccess}
        refreshList={getEventLists}
        data={filteredEventGuestLists}
      />

      <RightDrawer
        title={
          <p>
            Adicionar <b>lista de convidados</b>
          </p>
        }
        open={showAddListModal}
        onClose={closeAddListModal}
        className={styles.modal}
      >
        <GuestListForm
          guestList={null}
          handleSubmit={handleAddList}
          onCancel={closeAddListModal}
          adminAccess={adminAccess}
        />
      </RightDrawer>

      <RightDrawer
        title={
          <p>
            Adicionar <b>Promoters</b>
          </p>
        }
        open={showMultipleAccessModal}
        onClose={closeMultipleAccessModal}
        className={styles.modal}
      >
        <GrantMultipleAccessForm
          closeModal={closeMultipleAccessModal}
          handleConfirm={handleGrantMultipleAccess}
        />
      </RightDrawer>
    </div>
  );
};
