import { FormControlLabel, Icon, IconButton, Radio, RadioGroup } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { TextField, FormControl, FormLabel, Button } from '@mui/material'
import { Search } from '@mui/icons-material';
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage'
import { Skeleton } from 'modules/salesDashboard/presentation/components/skeleton/Skeleton'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomerItem from './CustomerItem'
import styles from './CustomerList.module.scss'
import { ICustomerListData } from './ICustomerList'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
export interface ICustomerListProps {
    //propertys
    averageTicket?: number,
    isLoading?: boolean,
    data?: ICustomerListData,
    getData?: (filter?: { keyword?: string, customersFilter?: string, page?: number, pageSize?: number, sortType?: string, sortOrientation?: string, enabledInfinityScroll?: boolean }) => void
    // pagination: { page: number, pageSize: number }
    enabledInfinityScroll?: boolean
}

const pageSize = 10

const CustomerList: FC<ICustomerListProps> = ({
    isLoading,
    data,
    getData,
    averageTicket,
    enabledInfinityScroll = false
}) => {
    const [filter, setFilter] = useState<{ customersFilter?: string, page?: number, pageSize?: number, sortType?: string, sortOrientation?: string, enabledInfinityScroll?: boolean }>({ customersFilter: 'all', page: 0, pageSize: pageSize, sortOrientation: 'desc', sortType: 'totalConsumed' })
    const [keyword, setKeyword] = useState('')

    const { isMobile } = useUi();

    useEffect(() => {
        getData?.({ ...filter, enabledInfinityScroll, keyword });
    }, [filter, getData, enabledInfinityScroll])

    const handleSearch = () => {
        setFilter(prev => ({ ...prev, page: 0 }))
    }

    const getMore = useCallback(() => {
        setFilter(prev => ({ ...prev, page: (prev.page ?? 0) + 1 }));
    }, [])

    const onClickSort = useCallback((key: string) => {
        setFilter?.(prev => ({
            ...prev,
            page: 0,
            sortType: key,
            sortOrientation: prev.sortOrientation === 'asc' ? 'desc' : 'asc',
        }));
    }, [setFilter])

    const onClickType = useCallback((customersFilter: string) => {
        setFilter(prev => ({
            ...prev,
            page: 0,
            customersFilter
        }));
    }, [])

    const onClickPage = useCallback((page: number) => {
        setFilter(prev => ({
            ...prev,
            page: page,
        }));
    }, [])
    const getCountPage = useMemo(() => {
        const total = filter.customersFilter === 'inPlace' ? data?.totalInPlace :
            filter.customersFilter === 'Birthday' ? data?.totalBirthday : data?.totalRecords
        return Math.ceil((total ?? 1) / (filter.pageSize ?? 1))
    }, [data?.totalBirthday, data?.totalInPlace, data?.totalRecords, filter.customersFilter, filter.pageSize])


    const list = useMemo(() => data?.records.map(item =>
        <CustomerItem
            item={item}
            averageTicket={averageTicket}
        />
    ), [averageTicket, data?.records])

    if (!data?.records && !isLoading) {
        return <div id={styles.CustomerList}>
            <ErrorMessage type="warning" />
        </div>
    }

    return (

        <div id={styles.CustomerList} >
            <div className={styles.container} >
                <div className={styles.filter} >
                    <div>

                        <FormControl>
                            <FormLabel className={styles.label}>Visualizar por</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="all"
                                name="radio-buttons-group"
                                row
                                onChange={(_, value) => onClickType(value)}
                            >
                                <FormControlLabel className={styles.textRadioGroup} label="Todos" control={<Radio />} value="all" />
                                <FormControlLabel className={styles.textRadioGroup} label="Ativos" control={<Radio />} value="inPlace" />
                                <FormControlLabel className={styles.textRadioGroup} label="Aniversariantes" control={<Radio />} value="Birthday" />
                            </RadioGroup>
                        </FormControl>
                     
                        <FormControl fullWidth={isMobile ? true : false}>
                            <FormLabel className={styles.label}>Cliente</FormLabel>
                            <TextField
                                placeholder="Selecione um cliente"
                                fullWidth
                                className={styles.search}
                                InputProps={{
                                    endAdornment: <Search style={{ color: "#5f5f5f" }} />
                                }}
                                size='small' name={'input'} onChange={(e) => setKeyword(e.target.value)} value={keyword}
                            />
                        </FormControl>
                    </div>
                    <div className={styles.containerButton}>

                        <Button
                            className={styles.filterButton}
                            fullWidth
                            variant='contained'
                            type='submit'
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>
                    </div>
                </div>
                {
                    !data?.records.length ?
                        <div id={styles.GestListLive}>
                            <ErrorMessage type="warning" />
                        </div>
                        :
                        <>
                            <div className={styles.totals} >
                                {/* <div className={styles.row} >
                                <span>{data?.totalCheckin}</span><label>Pessoas que realizaram checkin</label>
                            </div> */}
                                <div className={styles.row} >
                                    <span>{data?.totalInPlace}</span><label>Pessoas com comanda ativa</label>
                                </div>
                                <div className={styles.row} >
                                    <span>{data?.totalBirthday}</span><label>Aniversariantes no local</label>
                                </div>
                            </div>
                            <div className={styles.header} >
                                <div className={styles.row} >
                                    {/* name, bornDate, age, birthday, visits, totalConsumed ou inPlace */}
                                    <span>Nome</span><IconButton size={'small'} onClick={() => onClickSort("name")}><Icon>swap_vert</Icon></IconButton>
                                </div>
                                <div className={styles.row} >
                                    <span>Idade</span><IconButton size={'small'} onClick={() => onClickSort("age")}><Icon>swap_vert</Icon></IconButton>
                                </div>
                                <div className={styles.row} >
                                    <span>Aniversário</span><IconButton size={'small'} onClick={() => onClickSort("bornDate")}><Icon>swap_vert</Icon></IconButton>
                                </div>
                                <div className={styles.row} >
                                    <span>Visitas</span><IconButton size={'small'} onClick={() => onClickSort("visits")}><Icon>swap_vert</Icon></IconButton>
                                </div>
                                <div className={styles.row} >
                                    <span>Total Consumido</span><IconButton size={'small'} onClick={() => onClickSort("totalConsumed")}><Icon>swap_vert</Icon></IconButton>
                                </div>
                            </div>
                            <div id={"customerList"} className={styles.list} style={{ height: !enabledInfinityScroll ? `unset` : 500 }} >
                                {
                                    !enabledInfinityScroll ? <>
                                        {isLoading ?
                                            <Skeleton items={pageSize} cardStyle={styles.skeleton} />
                                            :
                                            <>
                                                {list}
                                            </>
                                        }
                                        < Pagination
                                            count={getCountPage}
                                            page={(filter.page ?? 0) + 1}
                                            onChange={(_, page) => onClickPage(page - 1)}
                                        />
                                    </> :
                                        <InfiniteScroll
                                            next={getMore}
                                            hasMore={!!data && data?.records.length < data?.totalRecords}
                                            loader={<Skeleton items={4} />}
                                            dataLength={data?.records.length ?? 0}
                                            scrollableTarget={"customerList"}
                                        >
                                            {list}
                                        </InfiniteScroll>
                                }
                            </div>
                        </>
                }
            </div>
        </div>

    )


}
export default CustomerList