import { IAddExternalPosService } from "modules/salesPos/domain/interface/IAddExternalPosService";
import { IPostAddExternalPosRequest } from "modules/salesPos/domain/dto/IPostAddExternalPosRequest";
import { IGetPendingExternalPosResponse } from "modules/salesPos/domain/dto/IGetPendingExternalPosResponse";
import { api } from "../Api";
import { plansUrl } from "Enviroment";

const baseUrl = plansUrl;

const AddExternalPosApi = (): IAddExternalPosService => {
  const getPendingExternalPos = async (localId: string): Promise<IGetPendingExternalPosResponse[]> => {
    const response = await api.get<IGetPendingExternalPosResponse[]>(`${baseUrl}/devices/solicitations/owner/${localId}/statuses?status=1&status=2&status=3`);
    return response.data;
  }

  const postExternalPos = async (request: IPostAddExternalPosRequest): Promise<{ id: string }> => {
    const response = await api.post<{ id: string }>(`${baseUrl}/devices/solicitations`, request);
    return response.data;
  }

  const putCompleteSolicitation = async (solicitationId: string): Promise<void> => {
    const response = await api.put<void>(`${baseUrl}/devices/solicitations/${solicitationId}/complete`);
    return response.data;
  }

  return { getPendingExternalPos, postExternalPos, putCompleteSolicitation };
}

export default AddExternalPosApi;
