import { Drawer, Icon, Radio } from '@material-ui/core';
import styles from './ChangePlanModal.module.scss';
import { IPlans } from '../../interfaces/IPlans';
import { MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { ISubscription } from '../../interfaces/ISubscription';
import { usePlans } from '../../hooks/PlansContext';
import { ILocalPlansItem } from '../../interfaces/ILocalPlans';
import { IFeatures } from '../../interfaces/IFeatures';
import { moneyMaskNumber } from 'services/utils/Money';
import { FeatureTypeEnum } from '../../interfaces/FeatureTypeEnum';

interface ChangePlanModalProps {
    open: boolean;
    localPlan: ILocalPlansItem;
    plans?: IPlans[];
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (planId?: string, additionalFeatures?: string[]) => void;
}

export const ChangePlanModal = ({ open, plans, localPlan, isLoading, onClose, onSubmit }: ChangePlanModalProps) => {
    const [subscription, setSubscription] = useState<ISubscription>();
    const [loading, setLoading] = useState(false);
    const { getSubscription, getAdditionalFeatures } = usePlans();
    const [selectedPlan, setSelectedPlan] = useState('');
    const [featuresToRemove, setFeaturesToRemove] = useState<IFeatures[]>([]);
    const [additionalFeatures, setAdditionalFeatures] = useState<IFeatures[]>([]);
    const [selectedAdditionalEmail, setSelectedAdditionalEmail] = useState('');
    const [selectedAdditionalPush, setSelectedAdditionalPush] = useState('');

    const handleSubmit = () => {
        const planId = localPlan.actualPlanId !== selectedPlan ? selectedPlan : undefined;
        const additionalFeatures = [];

        if (!!selectedAdditionalEmail && !subscription?.additionalFeatures.find(x => x.id === selectedAdditionalEmail))
            additionalFeatures.push(selectedAdditionalEmail);
        
            if (!!selectedAdditionalPush && !subscription?.additionalFeatures.find(x => x.id === selectedAdditionalPush))
            additionalFeatures.push(selectedAdditionalPush);

        if (!planId && !additionalFeatures.length) onClose();
        else onSubmit(planId, additionalFeatures);
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await getSubscription(localPlan.ownerId);
                setSubscription(response);
                const email = response?.additionalFeatures.find(x => x.featureTypeValue === FeatureTypeEnum.EMAIL);
                const push = response?.additionalFeatures.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH);
                if (email) setSelectedAdditionalEmail(email.id);
                if (push) setSelectedAdditionalPush(push.id);
                setSelectedPlan(localPlan.actualPlanId);
            } finally {
                setLoading(false);
            }
        })();
    }, [getSubscription, localPlan.actualPlanId, localPlan.ownerId]);

    useEffect(() => {
        if (!!selectedPlan && !!localPlan.actualPlanId && selectedPlan !== localPlan.actualPlanId) {
            subscription?.additionalFeatures.forEach(x => {
                if (x.dependencyFeaturePlans?.length && !x.dependencyFeaturePlans?.find(y => y.planId === selectedPlan)) {
                    setFeaturesToRemove(prev => [...prev, x]);
                } else {
                    setFeaturesToRemove(prev => prev.filter(y => y.id !== x.id));
                }
            })
        } else {
            setFeaturesToRemove([]);
        }
    }, [localPlan.actualPlanId, selectedPlan, subscription]);

    useEffect(() => {
        if (!!subscription?.planId && !!selectedPlan && subscription?.planId !== selectedPlan) {
            if (plans?.find(x => x.id === selectedPlan)?.isDefault) {
                setSelectedAdditionalEmail('');
                setSelectedAdditionalPush('');
            } else {
                setSelectedAdditionalEmail(additionalFeatures.find(x => x.featureTypeValue === FeatureTypeEnum.EMAIL && x.index === 0)?.id || '');
                setSelectedAdditionalPush(additionalFeatures.find(x => x.featureTypeValue === FeatureTypeEnum.PUSH && x.index === 0)?.id || '');
            }
        }
    }, [additionalFeatures, plans, selectedPlan, subscription?.planId])

    useEffect(() => {
        (async () => {
            const features = await getAdditionalFeatures([FeatureTypeEnum.PUSH, FeatureTypeEnum.EMAIL]);
            setAdditionalFeatures(features);
        })();
    }, [getAdditionalFeatures]);

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Editar <b>plano</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                {
                    loading ? <div className={styles.loaderContainer}><img src="/assets/img/loader.gif" alt="" /></div> :
                        <>
                            <div className={styles.content}>
                                <label>Estabelecimento</label>
                                <TextField
                                    className={styles.textField}
                                    size='small'
                                    disabled
                                    value={localPlan.ownerName}
                                />

                                <label>Plano</label>
                                <TextField
                                    className={styles.textField}
                                    size={'small'}
                                    select
                                    variant='outlined'
                                    value={selectedPlan}
                                    onChange={(e) => setSelectedPlan(e.target.value)}
                                >
                                    {
                                        plans?.map(x => (
                                            <MenuItem value={x.id}>{x.name}</MenuItem>
                                        ))
                                    }
                                </TextField>

                                {
                                    !!featuresToRemove.length && (
                                        <>
                                            <span className={styles.addonsTitle}>Adicionais a serem removidos</span>

                                            {
                                                featuresToRemove.map(x => (
                                                    <div className={styles.row}>
                                                        <img src={`/assets/icon/plans/${x.featureTypeDescription.toLowerCase()}-outlined-purple.svg`} alt="" />
                                                        <span className={styles.text}>{x.name} - {x.purchaseDescription} {x.price ? '| ' + moneyMaskNumber(x.price) : ''}</span>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    )
                                }

                                {
                                    !plans?.find(x => x.id === selectedPlan)?.isDefault && (
                                        <>
                                            <span className={styles.addonsTitle}>Adicionais de Email:</span>
                                            {
                                                additionalFeatures.filter(x => x.featureTypeValue === FeatureTypeEnum.EMAIL).map(x => (
                                                    <div className={styles.row}>
                                                        <Radio checked={x.id === selectedAdditionalEmail} onChange={() => setSelectedAdditionalEmail(x.id)} />
                                                        <span className={styles.text}>{x.name} - {x.purchaseDescription}</span>
                                                    </div>
                                                ))
                                            }

                                            <span className={styles.addonsTitle}>Adicionais de Push:</span>
                                            {
                                                additionalFeatures.filter(x => x.featureTypeValue === FeatureTypeEnum.PUSH).map(x => (
                                                    <div className={styles.row}>
                                                        <Radio checked={x.id === selectedAdditionalPush} onChange={() => setSelectedAdditionalPush(x.id)} />
                                                        <span className={styles.text}>{x.name} - {x.purchaseDescription}</span>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    )
                                }

                                <div className={styles.separator}>
                                    <div />
                                    <span>O que isso significa?</span>
                                    <div />
                                </div>

                                <p>As alterações de plano realizadas refletirão no Portal Meep do estabelecimento selecionado. Por isso, certifique-se da ação.</p>

                                <p>Lembrando que os adicionais também serão cancelados com o Plano Premium.</p>
                            </div>
                            <div className={styles.buttonsContainer}>
                                <button onClick={onClose}>Cancelar</button>
                                <button disabled={isLoading} onClick={handleSubmit}>{isLoading ? 'Enviando...' : 'Salvar'}</button>
                            </div>
                        </>
                }
            </div>
        </Drawer>
    )
}