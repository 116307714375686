import { FormControlLabel, Switch } from '@material-ui/core'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './DevicePaymentForm.module.scss'
import { IDevicePaymentValue, IPaymentMethodItem, PaymentType } from './interfaces/IDevicePaymentValue'
import { ICustomPaymentItemResponse } from 'modules/config/deviceConfig/domain/dto/deviceProfile/getCustomPaymentList/ICustomPaymentResponse'
export interface IDevicePaymentFormProps {
    defaultValue?: IDevicePaymentValue
    hideDetails?: boolean;
    methods?: IPaymentMethodItem[];
    customMethods?: ICustomPaymentItemResponse[];
    onModified: (modified: boolean) => void;
    onClickBack?: (value: IDevicePaymentValue) => void
    onSubmit: (values: IDevicePaymentValue) => void
}

const DevicePaymentForm: FC<IDevicePaymentFormProps> = ({ methods: paymentMethods, customMethods, defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IDevicePaymentValue>(defaultValue ?? {} as IDevicePaymentValue);


    useEffect(() => {
        if (paymentMethods) {
            if (!defaultValue) {
                setValues(prevState => ({
                    ...prevState,
                    methods: paymentMethods.map(item => ({ enabled: false, ...item }))
                }));
            } else {
                setValues(prevState => ({
                    ...prevState,
                    methods: paymentMethods.map(item => {
                        const defaultMethod = defaultValue.methods.find(defaultItem => item.type === defaultItem.type);
                        return ({
                            enabled: defaultMethod?.enabled ?? false,
                            ...item,
                            name: defaultMethod?.name ?? item.name
                        });
                    })
                }));
            }
        }
    }, [defaultValue, paymentMethods]);
    
    useEffect(() => {
        if (customMethods) {
            if (!defaultValue) {
                setValues(prevState => ({
                    ...prevState,
                    customMethods: customMethods.map(item => ({ enabled: false, ...item }))
                }));
            } else {
                setValues(prevState => ({
                    ...prevState,
                    customMethods: customMethods.map(item => {
                        const defaultMethod = defaultValue.customMethods.find(defaultItem => item.type === defaultItem.type);
                        return ({
                            enabled: defaultMethod?.enabled ?? false,
                            ...item,
                            name: defaultMethod?.name ?? item.name
                        });
                    })
                }));
            }
        }
    }, [defaultValue, customMethods]);
    
    
    const changePaymentMethodHandle = useCallback((methodType: PaymentType, checked: boolean) => {
        setValues((prev) => ({ ...prev, methods: prev.methods.map((item) => item.type === methodType ? { ...item, enabled: checked } : item) }));
        onModified(true);
    }, [onModified]);
    
    const changeCustomPaymentMethodHandle = useCallback((methodType: number, checked: boolean) => {
        setValues((prev) => ({ ...prev, customMethods: prev.customMethods.map((item) => item.type === methodType ? { ...item, enabled: checked } : item) }));
        onModified(true);
    }, [onModified]);
    const onChangeHandle = useCallback((property: string, value: any) => {
        const properties = property.split('.');
        const fullObj = values[properties[0] as keyof IDevicePaymentValue] as object;

        if (properties.length === 1) {
            setValues((prev) => ({ ...prev, [properties[0]]: value }));
        } else {
            setValues((prev) => ({ ...prev, [properties[0]]: { ...fullObj, [properties[1]]: value } }));
        }

        onModified(true);
    }, [onModified, values]);

    const paymentMethodsList = useMemo(() => {
        return paymentMethods?.map(item => {
            return (
                <FormItemContainer key={item.name}>
                    <FormControlLabel
                        checked={values.methods?.find(valueItem => valueItem.type === item.type)?.enabled || false}
                        onChange={(ev, checked) => changePaymentMethodHandle(item.type, checked)}
                        control={<Switch color='secondary' />} label={item.name}
                    />
                </FormItemContainer>
            )
        })
    }, [changePaymentMethodHandle, paymentMethods, values.methods]);
    
    const customPaymentMethodsList = useMemo(() => {
        return customMethods?.map(item => {
            return (
                <FormItemContainer key={item.name}>
                    <FormControlLabel
                        checked={values.customMethods?.find(valueItem => valueItem.type === item.type)?.enabled || false}
                        onChange={(ev, checked) => changeCustomPaymentMethodHandle(item.type, checked)}
                        control={<Switch color='secondary' />} label={item.name}
                    />
                </FormItemContainer>
            )
        })
    }, [changeCustomPaymentMethodHandle, customMethods, values.customMethods]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])

    const formatPercent = (value: number) => {
        if (!value) return '';
        return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);
    }

    const handleChangePercent = useCallback((property: string, ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const onlyNumbers = parseInt(ev.target.value.replace(/\D/g, ''), 10);
        onChangeHandle(property, (onlyNumbers > 9999 ? 9999 : onlyNumbers) / 100)
    }, [onChangeHandle]);


    return (
        <form id={styles.DevicePaymentForm} onSubmit={submitHandle}>
            <div className={styles.container} >
                <span>Selecione as formas de pagamento aceitas no seu PDV.</span>
                <div>
                    <h4>Métodos de pagamento</h4>
                    <FormItemContainer className={styles.formItemContainer}>
                        <div className={styles.itemsContainer}>
                            {paymentMethodsList}
                        </div>
                    </FormItemContainer>
                    {(customMethods?.length ?? 0) > 0 && (
                        <>
                            <h4>Métodos de pagamento customizados</h4>
                            <FormItemContainer className={styles.formItemContainer}>
                                {customPaymentMethodsList}
                            </FormItemContainer>
                        </>
                    )}
                    <h4>Taxa de serviço</h4>
                    <FormItemContainer className={styles.formItemContainer}>
                        <FormItemContainer>
                            <FormControlLabel
                                checked={values.serviceFee?.enabled || false}
                                onChange={(ev, checked) => onChangeHandle('serviceFee.enabled', checked)}
                                control={<Switch color='secondary' />} label={"Cobrar taxa de serviço"}
                            />
                        </FormItemContainer>
                        {
                            values.serviceFee?.enabled &&
                            <FormItemContainer flex={2} label={"Taxa de serviço (%)"}>
                                <Input
                                    variant={"outlined"}
                                    value={formatPercent(values?.serviceFee?.value || 0)}
                                    onChange={ev => handleChangePercent('serviceFee.value', ev)}
                                />
                            </FormItemContainer>
                        }
                    </FormItemContainer>
                    <h4>Outras configurações</h4>
                    <FormItemContainer  >
                        <FormItemContainer flex={1}>
                            <FormControlLabel
                                checked={values.isPaymentDivisorEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isPaymentDivisorEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Aceita divisão do pagamento"}
                            />
                        </FormItemContainer>
                        {/* <FormItemContainer>
                            <FormControlLabel
                                checked={values.isMixedPaymentEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isMixedPaymentEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Aceita pagamento misto"}
                            />
                        </FormItemContainer> */}
                        <FormItemContainer>
                            <FormControlLabel
                                checked={values.isInstallmentEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isInstallmentEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Aceita Parcelamento"}
                            />
                        </FormItemContainer>
                        {values.isInstallmentEnabled && (
                            <FormItemContainer flex={1}>
                                <FormControlLabel
                                    checked={values.interestRate?.enabled || false}
                                    onChange={(ev, checked) => onChangeHandle('interestRate.enabled', checked)}
                                    control={<Switch color='secondary' />} label={"Aplicar Juros"}
                                />
                            </FormItemContainer>
                        )}
                        {values.interestRate?.enabled && values.isInstallmentEnabled &&
                            <FormItemContainer flex={2} label={"Taxa de juros (%)"}>
                                <Input
                                    variant={"outlined"}
                                    value={formatPercent(values?.interestRate?.value || 0)}
                                    onChange={ev => handleChangePercent('interestRate.value', ev)}
                                />
                            </FormItemContainer>}
                        <FormItemContainer flex={1}>
                            <FormControlLabel
                                checked={values.isRefundEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isRefundEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Desabilitar estorno de produto"}
                            />
                        </FormItemContainer>
                        <FormItemContainer>
                            <FormControlLabel
                                checked={values.isShowChangeMoneyEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isShowChangeMoneyEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Habilitar troco"}
                            />
                        </FormItemContainer>

                        <FormItemContainer flex={1}>
                            <FormControlLabel
                                checked={values.isReceiptPrintingEnabled || false}
                                onChange={(ev, checked) => onChangeHandle("isReceiptPrintingEnabled", checked)}
                                control={<Switch color='secondary' />} label={"Imprimir comprovante"}
                            />
                        </FormItemContainer>
                        <FormItemContainer flex={2} label={"Desconto máximo (%)"}>
                            <Input
                                variant={"outlined"}
                                value={formatPercent(values.maximunDiscount || 0)}
                                onChange={ev => handleChangePercent("maximunDiscount", ev)}
                            />
                        </FormItemContainer>
                    </FormItemContainer>
                </div>
            </div>
            {/* <FooterButtons onClickBack={() => onClickBack(values)} onClickNext={() => onClickNext(values)} /> */}
            <FooterButtons
                onClickBack={() => { onClickBack?.(values) }}
                labelNext="Salvar e continuar"
                nextType="submit"
            />
        </form>
    )
}
export default DevicePaymentForm