import { FC } from "react";
import Button from "components/ui/Button/Button";
import styles from "./SelectedDevices.module.scss";
import { IDeviceItem } from "../deviceList/interfaces/IDeviceItem";

interface SelectedDevicesProps {
  deviceList: IDeviceItem[];
  onChangeProfileHandler: () => void;
}

const DeviceItem: FC<SelectedDevicesProps> = ({
  deviceList,
  onChangeProfileHandler,
}) => {
  return (
    <div id={styles.selectedDevices}>
      <div>{deviceList.length}</div>
      <span>Selecionados</span>

      <Button
        fullWidth={false}
        onClick={onChangeProfileHandler}
        className={styles.changeProfileButton}
      >
        Alterar perfil
      </Button>
    </div>
  );
};

export default DeviceItem;
