import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { AttachMoneyOutlined, OpenInNewOutlined, PrintOutlined } from "@material-ui/icons";
import styles from "./ConfigMeepHub.module.scss";
import Sidesheet from "components/sidesheet/Sidesheet";
import ConfigContentMeepHub from "../../components/_configCampaign/configContentMeepHub/ConfigContentMeepHub";
import { UseConfigMeepHub } from "./UseConfigMeepHub";
import { useCallback, useEffect, useMemo } from "react";
import { HubStatusEnum } from "modules/config/integrationsConfig/domain/dto/IGetHubByOwnerIdResponse";
import { IconButton } from "@mui/material";

export interface IConfigMeepHubProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
}

const ConfigMeepHub = ({ open, setOpen }: IConfigMeepHubProps) => {
  const {
    isLoadingToken,
    isLoading,
    hubByOwnerId,
    refreshTokenResponse,
    hubActive,
    resumeConfiguration,
    getHubToken,
    postHubRefreshKeyUseCase,
    postHubToggleActivation
  } = UseConfigMeepHub();

  useEffect(() => {
    getHubToken();
  }, [getHubToken]);

  const handleRefreshToken = useCallback(async () => {
    await postHubRefreshKeyUseCase();
  }, [postHubRefreshKeyUseCase]);

  const handleToggleConnection = useCallback(async (enable: boolean) => {
    await postHubToggleActivation(enable);
  }, [postHubToggleActivation]);

  const handleStatus = useMemo(
    () => (hubByOwnerId?.status === HubStatusEnum.Connected ? "enabled" : "disabled"),
    [hubByOwnerId?.status]
  );
  const isLocalServerAddressEmpty = !resumeConfiguration?.enderecoServidorLocal;  
  return (
    <>
      <ContentBox
        status={handleStatus}
        loading={isLoading}
        type={IntegrationTypeEnum.MEEP_HUB}
        showSwitch={isLocalServerAddressEmpty}
        // text="Utilize o Meep Hub para tornar a gestão do seu estabelecimento mais completa, com serviços estratégicos e necessários."
        text={
          <div id={styles.MeepHub}>
            <div className={styles.content}>
              <p>O que está incluído nesse serviço:</p>
              <div>
                <PrintOutlined fontSize="large"/>
                <span>Impressão de pedidos, cupom fiscal, dentre outros</span>
              </div>
              <div>
                <AttachMoneyOutlined fontSize="large"/>
                <span>Integração de pagamentos com o Terminal Online</span>
              </div>
            </div>
          </div>
        }
        setOpen={setOpen}
        fullWidth
        extraContent={
          <div id={styles.MeepHub}>
            <div className={styles.content} style={{ marginRight: "auto" }}>
              <p>Impressoras homologadas:</p>
              <ul>
                <li>Epson TM-T20 e TM-T20x <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-epson-t20", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></li>
                <li>Bematech 4200 <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-bematech", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></li>
                <li>Elgin i8 e i9 <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-elgin", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></li>
              </ul>
            </div>
          </div>
        }
        enabledConnection={hubActive}
        handleToggleConnection={handleToggleConnection}
      />
      {open && open === "Meep Hub" && (
        <Sidesheet
          isLoading={isLoadingToken}
          open={open}
          onClose={() => setOpen(null)}
          title={<h2>Meep <b>Hub</b></h2>}
          content={
            <ConfigContentMeepHub
              token={hubByOwnerId?.key ?? ""}
              newToken={refreshTokenResponse?.key}
              handleRefreshToken={handleRefreshToken}
            />
          }
          currentStep={1}
          totalSteps={1}
        />
      )}
    </>
  );
};

export default ConfigMeepHub;
