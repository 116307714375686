import { IPaginatedList } from 'models/api/IApi';
import { IEventsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { EventStatusEnum } from 'modules/events/domain/models/IEvents';
import { IEventsApi } from '../../interfaces/IEventsService';

const GetEventsUseCase = async (
  eventsService: IEventsApi,
  localId: string,
  status: EventStatusEnum,
  page: number,
  month?: number,
  year?: number,
  keyword?: string
): Promise<IPaginatedList<IEventsResponse>> => {
  return await eventsService.getEvents(localId, status, page, month, year, keyword);
};

export default GetEventsUseCase;
