import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService"
import { CategoryRankingValue } from "modules/salesDashboard/presentation/components/categoryRanking/CategoryRankingValue"
import { IProductRankingCategoryData } from "modules/salesDashboard/presentation/components/categoryRanking/subItemProductCategory/SubItemsProductsCategory"
import { IFilterValue } from "modules/salesDashboard/presentation/components/filter/IFilter"
import { IRankingStoresValues } from "modules/salesDashboard/presentation/components/rankingStores/RankingStores"
import { makeFilterRequest } from "./MakeFilterRequest"

export const getRankingStoreUseCase = async (service: ISalesDashboardService, filter: IFilterValue): Promise<IRankingStoresValues> => {
    const request = makeFilterRequest({ filter });
    const response = service.getStoresRanking(request)
    return response
}
export const getCategoryRankingStoreUseCase = async (service: ISalesDashboardService, storeId: string = "", filter: IFilterValue): Promise<CategoryRankingValue> => {
    const request = makeFilterRequest({ filter });
    const response = service.getStoreRankingCategory(storeId, request)
    return response
}