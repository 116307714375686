import { InputAdornment, TextField, Switch } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import SwitchEvent from "@mui/material/Switch";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import FooterButtons from "../../../footerButtons/FooterButtons";
import styles from "./DeviceProfileForm.module.scss";
import "./DeviceToProfileForm.css";
import { IDeviceEventValue, IDeviceItemValue } from "./interfaces/IDeviceItem";
import { IFilterDevice } from "modules/equipamento/presentation/components/filterDevice/IFilterDevice";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "components/skeleton/Skeleton";
import { v4 } from "uuid";
import { IGetDeviceListResponse } from "modules/equipamento/domain/dto/getDevice/IGetDeviceListResponse";
import Button from "components/ui/Button/Button";
import { useParams } from "react-router-dom";
import { IDisponibilidadeResponse } from "modules/config/deviceConfig/application/useCase/deviceProfile/perfilPDV/IPerfilService";

const equipmentTypes = [
  {
    value: 0,
    name: "Todos",
  },
  {
    value: 4,
    name: "POS+",
  },
  {
    value: 3,
    name: "POS",
  },
  {
    value: 9,
    name: "Tablet",
  },
  {
    value: 2,
    name: "Totem",
  },
  {
    value: 11,
    name: "Terminal",
  },
];

export interface IDeviceProfileFormProps {
  //propertys
  deviceList?: IGetDeviceListResponse;
  currentProfileId: string;
  modified?: boolean;
  onModified?: (modified: boolean) => void;
  onSubmit?: (values: IDeviceItemValue[]) => void;
  onClickBack: (value: IDeviceItemValue[]) => void;
  onUpdateDevices?: (value: IDeviceEventValue) => void;
  getMoreDevices: (deviceFilter?: IFilterDevice) => void;
  nivelEvento?: boolean;
  perfilVinculo?: number;
  getEquipamentos?: any;
  disponibilidade?: IDisponibilidadeResponse[];
  deviceListEvent?: {
    deviceId: string;
    isInOtherEvent: boolean;
    isSelected: boolean;
    name: string;
  }[];
}
const DeviceToProfileForm: FC<IDeviceProfileFormProps> = ({
  deviceList,
  currentProfileId,
  onModified,
  onSubmit,
  onClickBack,
  getMoreDevices,
  onUpdateDevices,
  nivelEvento,
  perfilVinculo,
  deviceListEvent,
  getEquipamentos,
  disponibilidade
}) => {
  const availableDeviceList = useMemo(() => {
    return !nivelEvento
      ? deviceList
      ?.items.filter(
        (device) =>
          device.profile?.id === currentProfileId || device.profile === null
      )
      : deviceList?.items.filter(
        (device) => device.profile?.id === currentProfileId
      );
  }, [currentProfileId, deviceList?.items, nivelEvento]);

  const [values, setValues] = useState<IDeviceItemValue[]>(
    availableDeviceList?.map((item) => ({
      ...item,
      enabled: item.profile?.id === currentProfileId,
    })) ?? []
  );

  const { eventId } = useParams<{ eventId: string }>();
  const [valuesEvent, setValuesEvent] = useState<IDeviceEventValue>({
    eventId: eventId,
    profile: currentProfileId,
    devices: [],
  });
  const [onSearch, setOnSearch] = useState(false);

  useEffect(() => {
    if (deviceListEvent) {
      setValuesEvent((prevState) => {
        const selectedDeviceIds = deviceListEvent
          .filter(
            (device) =>
              device.isSelected && !prevState.devices.includes(device.deviceId)
          )
          .map((device) => device.deviceId);

        const updatedDevices = [...prevState.devices, ...selectedDeviceIds];

        return {
          ...prevState,
          devices: updatedDevices,
        };
      });
    }
  }, [deviceListEvent]);

  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState<number>();

  useEffect(() => {
    if (availableDeviceList) {
      if (!onSearch) {
        setValues(
          availableDeviceList.map((item) => ({
            ...item,
            enabled: item.profile?.id === currentProfileId,
          })) ?? []
        );
      }
    }
  }, [availableDeviceList, currentProfileId, onSearch]);

  const getMore = useCallback(() => {
    if (deviceList) {
      const offset = deviceList.items.length;
      const limit = 30;
      getMoreDevices({ pagination: { limit, offset } });
    }
  }, [deviceList, getMoreDevices]);

  const handleSearchEvent = () => {
    getEquipamentos(currentProfileId, searchTerm, typeFilter)
  }

  const handleSearch = useCallback(() => {
    if (typeFilter || searchTerm) {
      const all = [
        {
          id: "4",
          name: "POS+",
        },
        {
          id: "3",
          name: "POS",
        },
        {
          id: "9",
          name: "Tablet",
        },
        {
          id: "2",
          name: "Totem",
        },
      ];
      const getTypes = (type?: number) => {
        if (!type || type === 0) {
          return all;
        } else {
          return all.filter((it) => it.id === String(type));
        }
      };
      getMoreDevices({ query: searchTerm, types: getTypes(typeFilter) });
    } else {
      getMoreDevices();
    }
    setOnSearch(true)
  }, [getMoreDevices, searchTerm, typeFilter]);

  const changeHandle = useCallback(
    (deviceId: string, checked: boolean) => {
      setValues((prev) =>
        prev?.map((item) =>
          item.id === deviceId ? { ...item, enabled: checked } : item
        )
      );
      onModified && onModified(true);
    },
    [onModified]
  );

  const getDeviceTypeImage = (deviceType: DeviceType) => {
    switch (deviceType) {
      case DeviceType.POS:
        return "/assets/icon/device-pos.svg";
      case DeviceType.TOTEM:
        return "/assets/icon/device-kiosk.svg";
      case DeviceType.CHECK:
        return "/assets/icon/device-tablet.svg";
      case DeviceType.TABLET:
        return "/assets/icon/device-check.svg";
      default:
        return "/assets/icon/device-kiosk.svg";
    }
  };

  const onSubmitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit, values]
  );

  const [dispositivos, setDispositivos] = useState(deviceListEvent)

  useEffect(() => {
    setDispositivos(deviceListEvent)
  }, [deviceListEvent])

  const changeHandleEvent = (deviceId: string, isChecked: boolean) => {
    setValuesEvent((prevState: IDeviceEventValue) => {
      const isDeviceAlreadyPresent = prevState.devices.includes(deviceId);

      if (isChecked && !isDeviceAlreadyPresent) {
        return {
          ...prevState,
          devices: [...prevState.devices, deviceId],
        };
      } else if (!isChecked && isDeviceAlreadyPresent) {
        return {
          ...prevState,
          devices: prevState.devices.filter((id) => id !== deviceId),
        };
      }

      return prevState;
    });
    setDispositivos(dispositivos => dispositivos && dispositivos.map(dispositivo =>
      dispositivo.deviceId === deviceId ? { ...dispositivo, isSelected: isChecked } : dispositivo
    ));
  };

  const ListDevice = useMemo(
    () => (
      <div className={styles.list}>
        {availableDeviceList?.length ? (
          availableDeviceList.map((deviceItem) => (
            <div
              key={deviceItem.id}
              onClick={() => {
                changeHandle(
                  deviceItem.id,
                  !!!values?.find((item) => item.id === deviceItem.id)?.enabled
                );
              }}
              className={styles.item}
            >
              <div className={styles.imageUrl}>
                <img
                  src={getDeviceTypeImage(deviceItem.type.value)}
                  alt={"icone do dispositivo"}
                />
              </div>
              <label>{deviceItem.name}</label>
              {!nivelEvento && (
                <Switch
                  checked={
                    !!values?.find((item) => item.id === deviceItem.id)?.enabled
                  }
                  onChange={(ev, checked) => {
                    changeHandle(deviceItem.id, checked);
                  }}
                  color="secondary"
                />
              )}
            </div>
          ))
        ) : (
          <div>Nenhum dispositivo encontrado</div>
        )}
      </div>
    ),
    [availableDeviceList, nivelEvento, values, changeHandle]
  );

  const ListDeviceEvent = useMemo(
    () => (
      <div className={styles.list}>
        {dispositivos?.length ? (
          dispositivos.map((deviceItem: any) => {
            const objetoDisponivel = disponibilidade && disponibilidade.find(objeto => objeto.Id === deviceItem.deviceId);
            return (
              <div key={deviceItem.deviceId} className={deviceItem.isInOtherEvent || deviceItem.isInOtherProfile || (objetoDisponivel && objetoDisponivel.DataLogin && !objetoDisponivel.DataLogout) ? styles.itemEventDisable : styles.itemEvent}>
                <div className={styles.contentEvent}>
                  <div className={styles.imageUrl}>
                    <img
                      src={getDeviceTypeImage(deviceItem.deviceType.value)}
                      alt={"icone do dispositivo"}
                    />
                  </div>
                  <label>{deviceItem.name}</label>
                  <SwitchEvent
                    checked={deviceItem.isSelected}
                    onChange={(ev, checked) => {
                      changeHandleEvent(deviceItem.deviceId, checked);
                    }}
                    color="secondary"
                    disabled={deviceItem.isInOtherEvent || deviceItem.isInOtherProfile || (objetoDisponivel && objetoDisponivel.DataLogin && !objetoDisponivel.DataLogout)}
                  />
                </div>
                {deviceItem.isInOtherEvent && <p className={styles.otherEvent}>Vinculado em outro evento</p>}
                {deviceItem.isInOtherProfile && <p className={styles.otherEvent}>Vinculado em outro perfil</p>}
                {(objetoDisponivel && objetoDisponivel.DataLogin && !objetoDisponivel.DataLogout) && <p className={styles.otherEvent}>Equipamento Logado</p>}
              </div>
            );
          })
        ) : (
          <p>Nenhum dispositivo encontrado.</p>
        )}
      </div>
    ),
    [disponibilidade, dispositivos]
  );

  const randonId = v4();

  return (
    <form id={styles.DeviceProfileForm} onSubmit={onSubmitHandle}>
      <span>
        {!(nivelEvento && perfilVinculo === 1) && (<>Selecione quais equipamentos deseja <b>vincular</b> em seu perfil de
          venda:</>
        )}
      </span>

      <div className={styles.container}>
        <div className={styles.search}>
          <div className={styles.searchFirstItem}>
            <TextField
              className="device-to-profile-form-search"
              variant="outlined"
              label="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <AutoComplete
            className="autocomplete-filter-profile"
            variant="outlined"
            placeholder="Todos"
            options={equipmentTypes}
            getOptionLabel={(option) => option.name}
            onChange={(_, val: any) => {
              setTypeFilter(val.value);
            }}
          />
        </div>

        <div className={styles.boxButton}>
          <Button
            variant={"contained"}
            className={styles.buttonSearch}
            fullWidth
            onClick={() => {
              if (nivelEvento && perfilVinculo === 2) {
                handleSearchEvent()
              } else {
                handleSearch();
              }
            }}
          >
            Buscar
          </Button>
        </div>

        <div style={{ width: "100%" }}>
          <InfiniteScroll
            next={getMore}
            hasMore={
              (deviceList?.items.length ?? 0) < (deviceList?.totalCount ?? 0)
            }
            loader={<Skeleton />}
            dataLength={availableDeviceList?.length ?? 0}
            scrollableTarget={randonId}
          >
            {nivelEvento && perfilVinculo === 2
              ? ListDeviceEvent
              : ListDevice}
          </InfiniteScroll>
        </div>
      </div>
      {nivelEvento && perfilVinculo === 2 && onUpdateDevices && (
        <FooterButtons
          nextType="submit"
          onClickBack={onClickBack}
          onClickNext={() => onUpdateDevices(valuesEvent)}
          labelNext="Salvar"
          labelBack="Cancelar"
        />
      )}
      {nivelEvento && perfilVinculo === 1 && (
        <FooterButtons onClickBack={onClickBack} labelBack="Fechar" />
      )}
      {!nivelEvento && (
        <FooterButtons
          nextType="submit"
          onClickBack={() => onClickBack?.(values)}
          labelNext="Salvar e continuar"
        />
      )}
    </form>
  );
};
export default DeviceToProfileForm;
