import React, { useCallback, useState } from 'react';
import Sidesheet from 'components/sidesheet/Sidesheet';
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback';
import { IPostAddLinkCardsResponse } from 'modules/linkCards/domain/dto/IPostAddLinkCardsResponse';
import { AddCircle } from '@mui/icons-material';
import { useDropzone } from "react-dropzone";
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { IAddLinkCards } from 'modules/linkCards/presentation/interfaces/IAddLinkCards';
import FeedBackAddLinkCards from './feedBackAddLinkCards/FeedBackAddLinkCards';
import styles from './AddLinkCards.module.scss';

interface IAddLinkCardsProps {
  open: "add" | null;
  addLinkCardsItem: (tags: string) => Promise<IPostAddLinkCardsResponse | undefined>;
  isLoadingAdd: boolean | undefined;
  reload: () => void;
  setOpenSidesheet: React.Dispatch<React.SetStateAction<"add" | null>>;
  addLinkCards: IAddLinkCards | undefined;
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddLinkCards: React.FC<IAddLinkCardsProps> = ({
  open, isLoadingAdd, reload, setAllSelected, addLinkCardsItem, setOpenSidesheet, addLinkCards
}) => {

  const [step, setStep] = useState(1);
  const [tags, setTags] = useState<string[] | undefined>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const { toast } = useUi();

  function csvToJSON(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const csv = e.target?.result as string;
        const lines = csv.split("\n");
        const result: string[] = [];

        for (let i = 0; i < lines.length; i++) {
          const values = lines[i].split(",");
          result.push(...values);
        }

        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Error reading the CSV file."));
      };

      reader.readAsText(file);
    });
  }

  const splitOnPast = (data: string) => data.split(";").map(d => d.trim());

  const onClose = useCallback(() => {
    if (step === 2) {
      reload();
    }
    setStep(1);
    setOpenSidesheet(null);
    setAllSelected(false);
    setTags(undefined);
  }, [reload, setAllSelected, setOpenSidesheet, step]);

  const continueOrSave = useCallback(async () => {
    await addLinkCardsItem(tags?.join(';') ?? '');
    setStep(step + 1);
  }, [addLinkCardsItem, step, tags]);

  const changeHandleTag = useCallback((newTags: string[]) => {
    setTags(newTags);
    setInputValue(''); // Clear input value after adding a tag
  }, []);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const handleInputKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        changeHandleTag([...(tags ?? []), value]);
      }
    }
  }, [inputValue, tags, changeHandleTag]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      try {
        const csvData = await csvToJSON(file);
        const parsedTags = csvData.flatMap(line => line.split(";").map(tag => tag.trim()));
        changeHandleTag(parsedTags);
      } catch (error) {
        console.error(error);
      }
    }
  }, [changeHandleTag]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    onDropRejected: () => toast("A extensão do arquivo deve ser .csv", "error"),
  });

  const handleContent = useCallback(() => {
    return (
      <div className={styles.containerTags}>
        <p>Insira as tags dos cartões separados por ponto e virgula “ ; “</p>
        <div className={styles.contentTags}>
          <div className={styles.tags}>
            <label>Cartões</label>
            <TagsInput
              value={tags ?? []}
              className={styles.inputTags}
              onChange={changeHandleTag}
              onlyUnique
              pasteSplit={splitOnPast}
              addOnPaste
              inputProps={{
                placeholder: "Digite as tags dos cartões",
                value: inputValue,
                onChange: handleInputChange,
                onBlur: () => {
                  const value = inputValue.trim();
                  if (value) {
                    changeHandleTag([...(tags ?? []), value]);
                  }
                },
                onKeyDown: handleInputKeyDown,
              }}
            />
          </div>
          <p>ou</p>
          <div>
            <div {...getRootProps()} className={styles.uploadButton}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p><span>Solte o arquivo .csv</span></p>
              ) : (
                <>
                  <span>Fazer upload csv</span>
                  <AddCircle />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }, [changeHandleTag, getInputProps, getRootProps, isDragActive, tags, inputValue, handleInputChange, handleInputKeyDown]);

  return (
    <Sidesheet
      isLoading={isLoadingAdd}
      open={open}
      onClose={onClose}
      title={<h2>Vincular <b>cartões</b></h2>}
      content={handleContent()}
      currentStep={step}
      totalSteps={2}
      handleContinueButton={continueOrSave}
      continueButton={'Vincular'}
      cancelButton
      feedback={
        addLinkCards?.totalCardsWithError && addLinkCards?.totalCardsWithError > 0 ?
          <FeedBackAddLinkCards addLinkCards={addLinkCards} text="Erro ao vincular cartões" helperText={`${addLinkCards?.totalLinkedCards} cartões adicionados com sucesso!`} />
          :
          <SidesheetFeedback text="Cartões vinculados com sucesso!" success />
      }
    />
  );
};

export default AddLinkCards;
