import { Button, TextField } from '@material-ui/core';
import React, { FormEvent, useCallback, useState } from 'react'
import { ParamsClientHouseFlow } from '../../hooks/UseHouseFlowList';
import { InputStatus } from './components/inputStatus/InputStatus';
import { EnumCardStatus, IHouseFlowFilter } from './components/interfaces/IHouseFlowFilter';
import styles from "./HouseFlowFilter.module.scss";

export interface IHouseFlowFilterProps {
  onSubmit: (filter: IHouseFlowFilter) => void;
  setParams: React.Dispatch<React.SetStateAction<ParamsClientHouseFlow>>
  params: ParamsClientHouseFlow | undefined;
  values: IHouseFlowFilter | undefined
  setValues: React.Dispatch<React.SetStateAction<IHouseFlowFilter | undefined>>
}

export const HouseFlowFilter: React.FC<IHouseFlowFilterProps> = ({
  onSubmit,
  params,
  setParams,
  values,
  setValues
}) => {

  const [disabledButton, setDisabledButton] = useState(false)

  const changeHandleTag = useCallback((cardNumber: string) => {
    setValues((prev) => ({ ...prev, cardNumber: cardNumber }));
  }, [setValues]);
  const changeHandleCustomer = useCallback((customerName: string) => {
    setValues((prev) => ({ ...prev, customerName: customerName }));
  }, [setValues]);
  const changeHandleCPF = useCallback((cpf: string) => {
    if (!!cpf.length && cpf.length !== 4) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
    setValues((prev) => ({ ...prev, customerCPF: cpf }));
  }, [setValues]);

  const onChangeStatusHandle = useCallback((value?: EnumCardStatus) => {
    setValues((prev) => ({ ...prev, sortOrientation: params?.filter?.sortOrientation, sortType: params?.filter?.sortType, cardStatus: value }));
  }, [params?.filter?.sortOrientation, params?.filter?.sortType, setValues]);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      values && onSubmit(values);

    },
    [onSubmit, values]
  );


  return (
    <div id={styles.HouseFlowFilter}>
      <form onSubmit={submitHandle}>

        <div className={styles.containerFilter}>
          <div className={styles.item}>
            <label>Comanda</label>
            <TextField inputProps={{ maxLength: 50 }} size='small' placeholder='Filtrar por comanda' variant='outlined' name={'cardNumber'} onChange={(ev) => changeHandleTag(ev.target.value.trim())} />
          </div>
          <div className={styles.item}>
            <label>Cliente</label>
            <TextField inputProps={{ maxLength: 50 }} size='small' placeholder='Filtrar por cliente' variant='outlined' name={'customerName'} onChange={(ev) => changeHandleCustomer(ev.target.value.trim())} />
          </div>
          <div className={styles.item}>
            <label>CPF</label>
            <TextField size='small' placeholder="Filtrar pelos 4 últimos números do CPF" type="number" variant='outlined' name={'customerCPF'} onChange={(ev) => changeHandleCPF(ev.target.value.substring(0, 4))} value={values?.customerCPF} />
          </div>
        </div>
        <div className={styles.footer}>
          <InputStatus onChange={onChangeStatusHandle} value={values?.cardStatus} params={params} />

          <Button variant={'contained'} className={disabledButton ? styles.disabledButton : styles.button} disabled={disabledButton} fullWidth style={{ height: 40, borderRadius: 8, width: 110 }} type='submit' >Buscar</Button>


        </div>
      </form>
    </div>
  )
}
