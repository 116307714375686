import { api } from "../Api";
import { IPermutationApiService } from "modules/permutation/domain/interfaces/IPermutationApiService";
import { IGetPermutationAccountResponse } from "modules/permutation/domain/dto/IGetPermutationAccountResponse";
import { IGetPermutationAccountParams } from "modules/permutation/domain/dto/IGetPermutationAccountParams";
import { ISort } from "interfaces/ISort";
import { IOperationRequest, IPostPermutionAccountRequest } from "modules/permutation/domain/dto/IPostPermutionAccountRquest";
import { IGetPermutionItemResponse } from "modules/permutation/domain/dto/IGetPermutionItemResponse";
import { IGetUseChildParams } from "modules/permutation/domain/dto/IGetUseChildParams";
import { IGetUseChildResponse } from "modules/permutation/domain/dto/IGetUseChildResponse";
import { IPostAddChildRequest } from "modules/permutation/domain/dto/IPostAddChildRequest";
import { IPutEditChildRequest } from "modules/permutation/domain/dto/IPutEditChildRequest";

const PermutationApi = (): IPermutationApiService => {
  const getAccounts = async (params?: IGetPermutationAccountParams, sort?: ISort): Promise<IGetPermutationAccountResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }

    const response = await api.get("/permutation/account", { params: data });
    return response.data;
  };

  const getAccountItem = async (localId: string, accountId: string): Promise<IGetPermutionItemResponse> => {
    const data = {
      localId: localId
    }
    const response = await api.get(`permutation/account/${accountId}`, { params: data });
    return response.data;
  };

  const getOperationBalance = async (localId: string, accountId: string): Promise<IGetPermutionItemResponse> => {
    const data = {
      placeId: localId
    }
    const response = await api.get(`permutation/account/${accountId}/operation`, { params: data });
    return response.data;
  };

  const getUseChild = async (accountId: string, params?: IGetUseChildParams, sort?: ISort): Promise<IGetUseChildResponse> => {
    const data = {
      ...params,
      "sort.orientation": sort?.orientation,
      "sort.type": sort?.type,
    }
    const response = await api.get(`permutation/account/${accountId}/children`, { params: data });
    return response.data;
  };

  const postAddPermutationAccount = async (request: IPostPermutionAccountRequest): Promise<void> => {
    await api.post<void>(`permutation/account`, request);
  }

  const postUpdateOperation = async (accountId: string, request: IOperationRequest): Promise<void> => {
    await api.post<void>(`permutation/account/${accountId}/operation`, request);
  }

  const postAddChild = async (request: IPostAddChildRequest, accountId: string): Promise<void> => {
    await api.post<void>(`permutation/account/${accountId}/children`, request);
  }
  const putEditChild = async (request: IPutEditChildRequest, accountId: string, childrenId: string): Promise<void> => {
    await api.put<void>(`permutation/account/${accountId}/children/${childrenId}`, request);
  }
  const deleteChild = async (localId: string, accountId: string, childrenId: string): Promise<void> => {
    const data = {
      placeId: localId
    }
    await api.delete<void>(`permutation/account/${accountId}/children/${childrenId}`, { params: data });
  }
  const deletePemutationAccount = async (accountId: string, localId: string): Promise<any> => {
    const data = {
      placeId: localId
    }
    const response = await api.delete<void>(`permutation/account/${accountId}`, { params: data });
    return response.data
  }

  return { getAccounts, postAddPermutationAccount, getAccountItem, postUpdateOperation, getOperationBalance, getUseChild, deletePemutationAccount, deleteChild, postAddChild, putEditChild };
}

export default PermutationApi;
