import { IconButton, Tooltip } from "@material-ui/core";
import {
  AccessTimeOutlined,
  CancelOutlined,
  DoneAllOutlined,
  RestoreOutlined,
  TaskAltOutlined,
  UnpublishedOutlined
} from "@mui/icons-material";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteValidation from "modules/permutation/presentation/pages/permutationAccountList/components/permutationAccountListContainer/deleteValidation/DeleteValidation";
import CancelTIDsSolicitationUseCase from "modules/tidsAdicionais/application/useCases/CancelTIDsSolicitationUseCase";
import { FC, useCallback, useMemo, useState } from "react";
import ComplienceService from "services/api/complience/ComplienceService";
import CPF from "services/utils/CPF";
import Utils from "services/utils/Utils";
import {
  ITIDsAdicionaisColumns,
  ITIDsAdicionaisProperty,
} from "../../interfaces/ITIDsAdicionaisColumns";
import { AdditionalTidsStatusEnum, ITID } from "../../interfaces/ITIDsList";
import styles from "./TIDsItem.module.scss";

export interface ITIDsItem {
  tid: ITID;
  columns: ITIDsAdicionaisColumns[];
  getTIDsList: () => Promise<void>;
}

const TIDsItem: FC<ITIDsItem> = ({ tid, columns, getTIDsList }) => {
  const { isMobile, toast } = useUi();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = useCallback(() => {
    setShowCancelModal(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setShowCancelModal(false);
  }, []);

  const cancelTIDsSolicitation = useCallback(async () => {
    try {
      setIsLoading(true);
      const service = ComplienceService();
      await CancelTIDsSolicitationUseCase(service, tid.Id);
      getTIDsList();
      toast("Cancelado com sucesso", "success");
    } finally {
      setIsLoading(false);
      closeDeleteModal();
    }
  }, [closeDeleteModal, getTIDsList, tid.Id, toast]);

  const formatValues = useCallback(
    (property: ITIDsAdicionaisProperty): string => {
      switch (property) {
        case "MerchantDocument":
          return CPF.cpfHideMask(tid.MerchantDocument);
        case "CreatedAt":
          return Utils.toDate(tid.CreatedAt);
        case "CompletedAt":
          return tid.CompletedAt ? Utils.toDate(tid.CreatedAt) : "-";
        case "Status":
          return AdditionalTidsStatusEnum[tid.Status];
        case "Tids":
          return tid.Tids.slice(0, 10) + "...";

        default:
          return String(tid?.[property] ?? "");
      }
    },
    [tid]
  );

  const copyTIDsToClipboard = useCallback(() => {
    navigator.clipboard.writeText(tid.Tids);
    toast("TIDs copiados para areá de transferencia", "success");
  }, [tid.Tids, toast]);

  const WebItem = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide &&
          (colunm.property === "Tids" ? (
            <Tooltip title="Copiar TIDs" placement="top">
              <div
                key={index}
                className={styles.row}
                style={
                  colunm.style ?? {
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    cursor: "pointer",
                  }
                }
                onClick={copyTIDsToClipboard}
              >
                <span title={formatValues(colunm.property)}>
                  {formatValues(colunm.property)}
                </span>
              </div>
            </Tooltip>
          ) : (
            <div
              key={index}
              className={styles.row}
              style={
                colunm.style ?? {
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                }
              }
            >
              <span title={formatValues(colunm.property)}>
                {formatValues(colunm.property)}
              </span>
            </div>
          ))
        );
      }),
    [columns, copyTIDsToClipboard, formatValues]
  );

  const MobileItem = useMemo(
    () => (
      <>
        <div className={styles.mid}>
          <span>{tid.UsuarioName ?? ""}</span>
          <div className={styles.icon}>
            {tid.Status === AdditionalTidsStatusEnum.Pendente ? (
              <Tooltip title="Cancelar">
                <IconButton size="small" onClick={handleCancel}>
                  <AccessTimeOutlined />
                </IconButton>
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Aprovado ? (
              <Tooltip title="Aprovado" style={{ cursor: "default" }}>
                <TaskAltOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Cancelado ? (
              <Tooltip title="Cancelado" style={{ cursor: "default" }}>
                <CancelOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Recusado ? (
              <Tooltip title="Recusado" style={{ cursor: "default" }}>
                <UnpublishedOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Validação ? (
              <Tooltip title="Em validação" style={{ cursor: "default" }}>
                <RestoreOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Completo" style={{ cursor: "default" }}>
                <DoneAllOutlined />
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.mid}>
          <span>Documento:</span>
          <span>{CPF.cpfHideMask(tid.MerchantDocument)}</span>
        </div>
        <div className={styles.mid}>
          <span>Id:</span>
          <span>{tid.AcquirerMerchantId}</span>
        </div>
        <div className={styles.mid}>
          <span>TIDs:</span>
          <Tooltip title="Copiar TIDs" placement="top">
            <span style={{ cursor: "pointer" }} onClick={copyTIDsToClipboard}>
              {tid.Tids.slice(0, 10) + "..."}
            </span>
          </Tooltip>
        </div>
        <div className={styles.mid}>
          <span>Status:</span>
          <span>{AdditionalTidsStatusEnum[tid.Status]}</span>
        </div>
        <div className={styles.mid}>
          <span>Criado em:</span>
          <span>{Utils.toDate(tid.CreatedAt)}</span>
        </div>
        <div className={styles.footer}>
          <span>Completo em:</span>
          <span>{tid?.CompletedAt ? Utils.toDate(tid.CompletedAt) : "-"}</span>
        </div>
      </>
    ),
    [
      tid.UsuarioName,
      tid.Status,
      tid.MerchantDocument,
      tid.AcquirerMerchantId,
      tid.Tids,
      tid.CreatedAt,
      tid.CompletedAt,
      handleCancel,
      copyTIDsToClipboard,
    ]
  );

  return (
    <div id={styles.TIDsItem}>
      {isMobile ? (
        <div className={styles.mobileContainer}>{MobileItem}</div>
      ) : (
        <div className={styles.webContainer}>
          {WebItem}
          <div className={styles.icon} style={{minWidth: 60}}>
            {tid.Status === AdditionalTidsStatusEnum.Pendente ? (
              <Tooltip title="Cancelar">
                <IconButton size="small" onClick={handleCancel}>
                  <AccessTimeOutlined />
                </IconButton>
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Aprovado ? (
              <Tooltip title="Aprovado" style={{ cursor: "default" }}>
                <TaskAltOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Cancelado ? (
              <Tooltip title="Cancelado" style={{ cursor: "default" }}>
                <CancelOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Recusado ? (
              <Tooltip title="Recusado" style={{ cursor: "default" }}>
                <UnpublishedOutlined />
              </Tooltip>
            ) : tid.Status === AdditionalTidsStatusEnum.Validação ? (
              <Tooltip title="Em validação" style={{ cursor: "default" }}>
                <RestoreOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Completo" style={{ cursor: "default" }}>
                <DoneAllOutlined />
              </Tooltip>
            )}
          </div>
        </div>
      )}

      <Sidesheet
        open={showCancelModal}
        onClose={closeDeleteModal}
        isLoading={isLoading}
        title={
          <h2>
            Cancelar <b>solicitação de novos TIDs</b>
          </h2>
        }
        content={
          <DeleteValidation
            text="Deseja realmente cancelar esta solicitação?"
            highlightText="Essa ação não poderá ser desfeita!"
          />
        }
        handleContinueButton={cancelTIDsSolicitation}
        notTotalHeight
        continueButton="Confirmar"
        feedback={
          <SidesheetFeedback
            text={`Solicitação de TIDs cancelada com sucesso!`}
            success
          />
        }
      />
    </div>
  );
};

export default TIDsItem;
