import { FC } from "react";
import { RadioOptionProps } from "./radioOption.types";
import styles from "./radioOption.module.scss";

const RadioOption: FC<RadioOptionProps> = ({ label, InputProps }) => {
  return (
    <label id={styles.InputRadio}>
      <input type="radio" {...InputProps} />
      {label}
    </label>
  );
};
export { RadioOption };
