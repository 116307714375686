import { useCallback, useEffect, useState } from "react";
import { IPostForm } from "../../interfaces/IPostForm";
import { CreatePostUseCases } from "modules/posts/application/useCases/CreatePostUseCase";
import SocialApi from "services/api/social/SocialApi";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { parse } from "date-fns";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import { IPaginatedPosts, IPost } from "../../interfaces/IPosts";
import { GetPostsUseCase } from "modules/posts/application/useCases/GetPostsUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { DeletePostUseCase } from "modules/posts/application/useCases/DeletePostUseCase";
import { EditPostUseCase } from "modules/posts/application/useCases/UpdatePostUseCase";
import { IUpdatePost } from "../../interfaces/IUpdatePost";

const service = SocialApi();

export const UsePosts = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [selectedPost, setSelectedPost] = useState<IPost>();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState<IPaginatedPosts>();
  const [includeFuturesPosts, setIncludeFuturesPosts] = useState(true);

  const { currentLocal } = useLocal();
  const { user } = useUser();
  const { toast } = useUi();

  const [currentPage, setCurrentPage] = useState(1);

  const getData = useCallback(async (page?: number, _includeFuturesPosts = true) => {
    if (currentLocal) {
      try {
        setLoading(true);
        const response = await GetPostsUseCase(service, currentLocal.id, _includeFuturesPosts, page || 1);
        setData(response);
        setCurrentPage(page || 1);
      } finally {
        setLoading(false);
      }
    }
  }, [currentLocal]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getData(1, includeFuturesPosts)
  }, [getData, includeFuturesPosts]);

  const handleSubmit = async (form: IPostForm) => {
    if (currentLocal && user) {
      const formData = {
        id: form.id,
        content: form.content,
        eventId: form.eventId,
        date: form.date ? parse(`${form.date} ${form.time}`, 'dd/MM/yyyy HH:mm', new Date()).toUTCString() : undefined,
        userId: user.id,
        imageBase64: form.image?.startsWith('http') ? undefined : form.image,
        imageUrl: form.image?.startsWith('http') ? form.image : undefined,
      };

      if (!formData.id) await CreatePostUseCases(service, currentLocal.id, formData);
      else await EditPostUseCase(service, currentLocal.id, formData as IUpdatePost);
      getData(1, true);
      setSelectedPost(undefined);
    }
  }

  const handleDeletePost = async () => {
    if (currentLocal) {
      try {
        setDeleteLoading(true);
        await DeletePostUseCase(service, currentLocal.id, selectedPost!.id);
        setDeleteModalOpened(false);
        getData(1, true);
      } catch {
        toast('Ocorreu um erro ao remover a publicação. Tente novamente.', 'error');
      } finally {
        setSelectedPost(undefined);
        setDeleteLoading(false);
      }
    }
  }

  return {
    loading,
    data,
    modalVisible,
    selectedPost,
    currentPage,
    deleteModalOpened,
    deleteLoading,
    includeFuturesPosts,
    setIncludeFuturesPosts,
    setDeleteModalOpened,
    getData,
    setSelectedPost,
    setModalVisible,
    handleSubmit,
    handleDeletePost
  }
}
