import { AxiosInstance } from "axios"

const AlteraImpressoraPadrao = async (api: AxiosInstance, productId: string, defaultPrinterId?: string): Promise<void> => {
    const body = {
        produtoId: productId,
        impressoraPadraoId: defaultPrinterId
    }
    
    await api.post("/Produto/EditarImpressorasProdutoEmMassa", body)
}
export default AlteraImpressoraPadrao