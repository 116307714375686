import { getVariantColorFromBase, variedColors } from 'components/graphs/utils/colors';
import { FC, memo } from 'react';
import { moneyMaskNumber } from 'services/utils/Money';
import { Skeleton } from '../skeleton/Skeleton';

import styles from './GradientTable.module.scss';
import { IGradientTable } from './IGradientTable.types';
import UseGradientTable from './UseGradientTable';

interface IGradientTableProps {
  title: string;
  getData: () => Promise<IGradientTable>;
  hideTotal?: boolean;
}

const GradientTable: FC<IGradientTableProps> = memo(({ getData, hideTotal }) => {
  const { data, loading, columnWidth, higherValue, totalRow, columnLegendsWidth } = UseGradientTable(getData, hideTotal);

  const renderColumnLegends = (columnData: string[]) =>
    columnData.map((item, key) => (
      <div
        key={key}
        style={{
          flex: columnLegendsWidth,
          minWidth: 120 * totalRow[0]?.length + 9.6,
          minHeight: 40,
          padding: "0 4.8px",
          color: 'var(--text)',
          borderLeft: key === 0 ? '' : 'solid 1.5px #E0E0E0',
        }}>
        {item}
      </div>
    ));

  const renderTableContent = (tableData: IGradientTable) =>
    tableData.amount.map((row, key) => (
      <div key={key} className={styles.tableRow}>
        <div
          className={styles.equipment}
          style={{
            flex: columnWidth / totalRow[0]?.length,
            minWidth: 120,
            color: 'var(--text)',
            textAlign: 'center',
            position: 'absolute',
            backgroundColor: '#fff',
            height: 40,
            justifyContent: 'flex-end',

          }}>
          {tableData.equipments[key]}
        </div>
        {row.map((columnValues, columnValuesKey) => (
          <div
            key={columnValuesKey}
            style={{
              flex: columnLegendsWidth,
              minWidth: 120 * totalRow[0]?.length + 9.6,
              borderLeft: columnValuesKey === 0 ? '' : 'solid 1.5px #E0E0E0',
              marginLeft: columnValuesKey === 0 ? 120 : 0,
              justifyContent: 'flex-end',

            }}>
            {columnValues.map((cellValue, cellValueKey) => (
              <div
                key={cellValueKey}
                style={{
                  minWidth: 120,
                  minHeight: 40,
                  background: getVariantColorFromBase(
                    tableData.places[cellValueKey].color || '',
                    cellValue,
                    higherValue[cellValueKey],
                    cellValueKey
                  ),
                }}>
                {moneyMaskNumber(cellValue)}
              </div>
            ))}
          </div>
        ))}
        {!hideTotal && (
          <div
            style={{
              flex: columnWidth / totalRow[0]?.length,
              minWidth: 120,
              color: variedColors[0],
              justifyContent: 'flex-end'

            }}>
            {moneyMaskNumber(
              row.reduce(
                (total, currentValue) =>
                  total +
                  currentValue.reduce(
                    (totalRow, current) => totalRow + current,
                    0,
                  ),
                0,
              ),
            )}
          </div>
        )}
      </div>
    ));

  const renderTotal = (totalData: number[][]) =>
    totalData.map((columnTotals, key) => (
      <div
        key={key}
        style={{
          flex: columnLegendsWidth,
          minWidth: 120 * totalRow[0]?.length + 9.6,
          borderLeft: key === 0 ? '' : 'solid 1.5px #E0E0E0',
          justifyContent: 'flex-end',
          marginLeft: key === 0 ? 120 : 0,
        }}>
        {columnTotals.map((total, index) => (
          <div
            key={index}
            style={{
              minHeight: 40,
              flex: columnWidth,
              justifyContent: 'flex-end',
              display: "flex",
              minWidth: 120,
              color: 'var(--quaternary)',
            }}>
            {moneyMaskNumber(total)}
          </div>
        ))}
      </div>
    ));

  if (loading) return <Skeleton items={5} containerStyle={styles.skeletonContainerStyle} cardStyle={styles.skeletonCardStyle} />

  return (
    <div id={styles.GradientTable}>
      <div className={styles.container}>
        <div className={styles.columnLegends}>
          <div
            style={{
              flex: columnWidth / totalRow[0]?.length,
              maxWidth: 120,
              minWidth: 120,
              backgroundColor: '#fff',
              height: 40
            }}
          >
            <div
              style={{
                flex: columnWidth / totalRow[0]?.length,
                minWidth: 120,
                position: 'absolute',
                backgroundColor: '#fff',
                height: 40
              }}
            />
          </div>
          {renderColumnLegends(data.paymentForm)}
          {!hideTotal && (
            <div
              // className={styles.total}
              style={{
                flex: columnWidth / totalRow[0]?.length,
                minWidth: 120,
              }}>
              Total
            </div>
          )}
        </div>
        <div className={styles.tableContent}>
          {renderTableContent(data)}
          {!hideTotal && (
            <div className={styles.tableRow}>
              <div
                className={styles.total}
                style={{
                  flex: columnWidth / totalRow[0]?.length,
                  minWidth: 120,
                  position: 'absolute',
                  backgroundColor: '#fff',
                  textAlign: "end",
                  height: 40
                }}>
                Total
              </div>
              {renderTotal(totalRow)}
              <div
                style={{
                  flex: columnWidth / totalRow[0]?.length,
                  minWidth: 120,
                  textAlign: "end",
                  color: 'var(--quaternary)',
                }}
                className={styles.totalNumber}
              >
                {
                  moneyMaskNumber(data.amount.map((row, key) => (

                    row.reduce(
                      (total, currentValue) =>
                        total +
                        currentValue.reduce(
                          (totalRow, current) => totalRow + current,
                          0,
                        ),
                      0,

                    )
                  )).reduce((t, c) => t + c, 0))
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
},
);

export default GradientTable;
