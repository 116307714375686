import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import styles from "./NotificationBanner.module.scss";
import { Checkbox } from "@material-ui/core";
import UseDimension from "components/dimension/UseDimension";
import { useCallback, useMemo, useState } from "react";
import UseLayout from "../UseLayout";

export interface INotificationBannerProps {
  notification: IGetNotificationsByTypeResponse;
}

const NotificationBanner = ({ notification }: INotificationBannerProps) => {
  const [notShowAgain, setNotShowAgain] = useState(true);

  const { isMobile, dimensions } = UseDimension();
  const { saveReadNotification } = UseLayout();

  const handleNotificationText = useMemo(() => {
    if (dimensions.width > 1600 && notification.Texto.length > 150) {
      return `${notification.Texto.slice(0, 150)}...`;
    }
    if (dimensions.width > 1300 && notification.Texto.length > 140) {
      return `${notification.Texto.slice(0, 140)}...`;
    }
    if (dimensions.width > 1000 && notification.Texto.length > 100) {
      return `${notification.Texto.slice(0, 100)}...`;
    }
    if (dimensions.width > 700 && notification.Texto.length > 50) {
      return `${notification.Texto.slice(0, 50)}...`;
    }
    if (dimensions.width > 500 && notification.Texto.length > 30) {
      return `${notification.Texto.slice(0, 30)}...`;
    }
    if (isMobile && notification.Texto.length > 15) {
      return `${notification.Texto.slice(0, 15)}...`;
    }
    return notification.Texto;
  }, [isMobile, notification.Texto, dimensions.width]);

  const handleCloseBanner = useCallback(() => {
    if (notShowAgain) {
      localStorage.removeItem("@showBanner");
      return saveReadNotification(notification.Id);
    }
    localStorage.setItem("@showBanner", "false");
  }, [notShowAgain, saveReadNotification, notification.Id]);

  return (
    <div id={styles.NotificationBanner}>
      <div
        className={
          isMobile ? styles.bannerContainerMobile : styles.bannerContainer
        }
      >
        <span>{handleNotificationText}</span>
        <div className={styles.endContent}>
          <div className={styles.checkboxContent}>
            <Checkbox
              defaultChecked
              className={styles.checkbox}
              size={isMobile ? "small" : "medium"}
              value={notShowAgain}
              onChange={() => {
                setNotShowAgain(!notShowAgain);
              }}
            />
            <label htmlFor="trackco-id" className={styles.labelCheckbox}>
              Não exibir novamente
            </label>
          </div>
          <button onClick={handleCloseBanner}>Fechar</button>
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
