import { IMeepFee } from "modules/meepFee/presentation/interfaces/IMeepFee";
import { IMeepFeeApiService } from "modules/meepFee/application/interfaces/IMeepFeeApiService";


const GetMeepFeeUseCase = async (service: IMeepFeeApiService, ownerId: string): Promise<IMeepFee[]> => {
    const response = await service.getMeepFee(ownerId)
    return response.map(item => {
        return {
            name: item.Nome,
            value: item.Valor,
        }
    });
}

export default GetMeepFeeUseCase;