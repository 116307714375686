import { Checkbox, Icon, IconButton, Switch, TextField } from '@material-ui/core';
import styles from './CategoryForm.module.scss';
import { useEffect, useState } from 'react';
import { IIcon, PickerIcon } from '../IconPicker/IconPicker';
import { ICustomerCategory } from '../../interfaces/ICustomerCategories';

interface CategoryFormProps {
    onClose: () => void;
    onSubmit: (data: ICustomerCategory) => void;
    defaultValues?: ICustomerCategory;
    loading: boolean;
    data: ICustomerCategory[];
}

export const CategoryForm = ({ onClose, onSubmit, defaultValues, loading, data }: CategoryFormProps) => {
    const [icon, setIcon] = useState<IIcon>();
    const [values, setValues] = useState<ICustomerCategory>({} as ICustomerCategory);
    const [agreeded, setAgreeded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        description: '',
        icon: '',
    });

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
            setAgreeded(true);
            setVisible(defaultValues.visible);
            if (defaultValues.icon) setIcon({ id: defaultValues.icon });
        }
    }, [defaultValues])

    const handleSubmit = () => {
        let hasError = false;
        setErrors({ name: '', description: '', icon: ''});

        if (!values.name) {
            setErrors(prev => ({ ...prev, name: 'Campo obrigatório.'}));
            hasError = true;
        } else if (data.find(x => x.name.toLowerCase() === values.name.toLowerCase())) {
            setErrors(prev => ({ ...prev, name: 'Já existe uma categoria com esse nome.'}));
            hasError = true;
        }

        if (!values.description) {
            setErrors(prev => ({ ...prev, description: 'Campo obrigatório.'}));
            hasError = true;
        }

        if (!icon) {
            setErrors(prev => ({ ...prev, icon: 'Campo obrigatório.'}));
            hasError = true;
        }

        if (hasError) return;

        onSubmit({ ...values, icon: icon!.id, visible })
    }

    return (
        <div className={styles.container}>
            <header>
                <h1>
                    Nova<b>&nbsp;Categoria</b>
                </h1>
                <IconButton onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </header>

            <div className={styles.content}>
                <span className={styles.subTitle}>É possível criar uma categoria para os seus clientes.</span>

                <label htmlFor='name' className={styles.required}>
                    Noma da categoria:
                </label>
                <TextField
                    name='name'
                    onChange={(e) => setValues(prev => ({ ...prev, name: e.target.value }))}
                    value={values.name}
                    variant="outlined"
                    fullWidth
                    size='small'
                    helperText={errors.name}
                    error={!!errors.name}
                    className={styles.textField}
                    placeholder='Escreva o nome'
                />

                <label htmlFor='description' className={styles.required}>
                    Descrição da categoria:
                </label>
                <TextField
                    name='description'
                    onChange={(e) => setValues(prev => ({ ...prev, description: e.target.value }))}
                    value={values.description}
                    variant="outlined"
                    fullWidth
                    size='small'
                    helperText={errors.description}
                    error={!!errors.description}
                    className={styles.textField}
                    placeholder='Descreva a categoria'
                />

                <label className={styles.required}>Selecione um ícone</label>
                <PickerIcon value={icon} onChange={setIcon} data={data} />

                <div className={styles.switchContainer}>
                    <Switch color='secondary' checked={visible} onChange={(_, checked) => setVisible(checked)} />
                    <span className={styles.switchText}>Deixar visível na lista de convidados</span>
                </div>

                <div className={styles.checkboxContainer}>
                    <Checkbox className={styles.checkbox} checked={agreeded} onChange={(ev) => setAgreeded(!agreeded)} color='primary' size='small' />
                    <span className={`${styles.text} ${styles.required}`}>Ao continuar, você declara e garante que está ciente do uso correto desta funcionalidade e assume total e exclusiva responsabilidade pelas informações utilizadas durante a categorização dos seus clientes.</span>
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <button onClick={onClose}>Cancelar</button>
                <button disabled={loading || !agreeded} onClick={handleSubmit}>{ loading ? 'Salvando...' : 'Salvar'}</button>
            </div>
        </div>
    )
}