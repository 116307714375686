import Button from "components/ui/Button/Button";
import { Icon, IconButton } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./InputSingleImage.module.scss";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { imageValidation, IMG } from "services/utils/IMG";

interface InputImageProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChangeImage?: (image: string) => void;
  onChangeMultipleImage?: (
    imageBase64: { imageUrl?: string; image?: string }[],
    name?: string
  ) => void;
  onDeleteImage?: (
    imageBase64: { imageUrl?: string; image?: string }[],
    name?: string
  ) => void;
  value?: string;
  multipleValues?: { imageUrl?: string; image?: string }[];
  width?: number;
  height?: number;
  multiple?: boolean;
  name?: string;
  error?: boolean;
  helperText?: string;
  accept?: IMG[];
  limitPixelWidth?: number;
  limitPixelHeight?: number;
  limitMb?: number;
}

const InputSingleImage: FC<InputImageProps> = ({
  inputProps,
  onChangeMultipleImage,
  onChangeImage,
  children,
  value,
  multipleValues,
  width = 130,
  multiple,
  height = 130,
  onDeleteImage,
  name,
  limitPixelWidth = 800,
  limitPixelHeight = 800,
  limitMb = 5,
  error,
  helperText,
  accept = [IMG.JPEG, IMG.PNG, IMG.GIF],
}) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [singleImage, setSingleImage] = useState<string | undefined>(value);
  const { toast } = useUi();

  useEffect(() => {
    if (value?.match("https://")) {
      setSingleImage(`${value}?timestamp=${new Date().toISOString()}`);
    } else {
      setSingleImage(value);
    }
  }, [value]);

  const fileToBase64 = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const onDeleteHandle = useCallback(
    (index: number) => {
      const newValues = multipleValues?.filter(
        (_item, _index) => _index !== index
      );
      if (newValues) {
        onDeleteImage && onDeleteImage(newValues, inputProps?.name);
      }
    },
    [multipleValues, onDeleteImage, inputProps?.name]
  );
  
  const onChangeHandle = async (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files;
    // eslint-disable-next-line prefer-const
    let images: string[] = [];
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const _file = files?.item(i);
        if (_file && limitMb && limitPixelHeight && limitPixelWidth) {
          const { errorMessage, isValid } = await imageValidation(
            _file,
            accept,
            limitMb,
            limitPixelWidth,
            limitPixelHeight,
          );
          if (!isValid) {
            toast(errorMessage, "error");
          } else {
            const base64 = await fileToBase64(_file);
            images.push(String(base64));
          }
        }
      }
      if (onChangeMultipleImage && multiple) {
        onChangeMultipleImage(
          images.map((baseUrl) => ({ image: baseUrl })),
          inputProps?.name ?? name
        );
      } else if (onChangeImage) {
        const [image] = images;
        onChangeImage(image);
      }
    }
  };

  const onClick = () => {
    refInput.current?.click();
  };

  return (
    <div id={styles.InputSingleImage}>
      {children ?? (
        <>
          {multiple &&
            multipleValues?.map(
              (image, index) =>
                image && (
                  <div
                    key={index}
                    className={styles.imageInput}
                    style={{ width, height }}
                  >
                    <img src={image.image ?? image.imageUrl} alt="edit_image" />
                    <IconButton
                      onClick={() => onDeleteHandle(index)}
                      size="small"
                      className={styles.closeButton}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                )
            )}
          <div
            onClick={onClick}
            className={styles.imageInput}
            style={{ width, height }}
          >
            {!multiple && singleImage ? (
              <>
                <img src={singleImage} alt="edit_image" />
                <IconButton size="medium" className={styles.closeButton}>
                  <Icon>edit</Icon>
                </IconButton>
              </>
            ) : (
              <div className={styles.buttonContainer}>
                <Icon fontSize={"large"}>publish</Icon>
                <Button fullWidth={false}>
                  <b>Enviar</b>
                </Button>
                {error && <p>{helperText}</p>}
              </div>
            )}
          </div>
        </>
      )}

      <input
        name={name}
        {...inputProps}
        ref={refInput}
        id="input-file"
        accept={accept.join(",")}
        type="file"
        multiple={multiple}
        style={{ display: "none" }}
        onChange={onChangeHandle}
      />
    </div>
  );
};

export default InputSingleImage;
