import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetPlansUseCase } from "modules/saas/application/useCases/GetPlansUseCase";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import { IPlansItem } from "modules/saas/presentation/interfaces/plans/IPlans";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SaasApi } from "services/api/saas/SaasApi";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import PlansApi from "services/api/plans/PlansApi";
import { GetSubscriptionUseCase } from "modules/plans/application/GetSubscriptionUseCase";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { IGetAllCardsResponse } from "modules/saasPayment/domain/dto/IGetAllCardsResponse";
import { SaasPaymentsApi } from "services/api/saasPayment/SaasPaymentsApi";
import { GetLocalSubscriptionIdUseCase } from "modules/saasPayment/application/GetLocalSubscriptionIdUseCase";
import { GetAllCardsUseCase } from "modules/saasPayment/application/GetAllCardsUseCase";
import { SaasChangePlanUseCase } from "modules/saasChangePlan/application/SaasChangePlanUseCase";
import { SaasChangePlanApi } from "services/api/saasChangePlan/SaasChangePlanApi";
import { ISaasChangePlanRequest } from "modules/saasChangePlan/domain/dto/ISaasChangePlanRequest";

const service = SaasChangePlanApi();
const saasService = SaasApi();
const planService = PlansApi();
const saasPaymentService = SaasPaymentsApi();

export const UseSaasChangePlanPage = () => {
    const [selectedType, setSelectedType] = useState<SubscriptionType>(SubscriptionType.MONTHLY);
    const [selectedPlan, setSelectedPlan] = useState<IPlansItem>();
    const [currentPlan, setCurrentPlan] = useState<IPlansItem>();
    const [plans, setPlans] = useState<IPlansItem[]>([]);
    const [installments, setInstallments] = useState(12);
    const [defaultCard, setDefaultCard] = useState<IGetAllCardsResponse>();
    const [expiresIn, setExpiresIn] = useState("");
    
    const history = useHistory();
    const { showLoading, hideLoading, toast } = useUi();
    const { currentLocal } = useLocal();
    const { getSubscription } = usePlans();
    
    useEffect(() => {
        (async () => {
            try {
                showLoading();
                const params = `categoryId=1&subscriptionType=4&subscriptionType=${selectedType}`;
                const planResponse = await GetPlansUseCase(saasService, params);
                const subscription = await GetSubscriptionUseCase(planService, currentLocal!.id);
                const subscriptionCard = await GetLocalSubscriptionIdUseCase(saasPaymentService, currentLocal!.id);
                const cards = await GetAllCardsUseCase(saasPaymentService, subscriptionCard.id);
                
                if (subscription.subscriptionType === SubscriptionType.ANNUAL) {
                    return history.push('/public/plans?');
                }

                setPlans(planResponse);
                setDefaultCard(cards.find(card => card.isDefault));
                setExpiresIn(subscription.expiresIn);

                if (planResponse.length) {
                    setCurrentPlan(planResponse.find(x => x.subscriptionType === subscription.subscriptionType && x.name === subscription.planName));
                    setSelectedPlan(planResponse.find(x => x.subscriptionType === subscription.subscriptionType && x.name === subscription.planName));
                    setSelectedType(subscription.subscriptionType);
                }
            } finally {
                hideLoading();
            }
        })();
    }, [currentLocal, hideLoading, history, showLoading]);

    const mounParcelValue = (parcel: number) => {
        const plan = plans.find(x => x.id === selectedPlan?.id);

        if (plan) {
            if (plan.subscriptionType === SubscriptionType.MONTHLY) {
                return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(plan.price);
            }
            let planValue = plan.price / parcel;

            return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(planValue);
        }
    }

    const wait = (ms: number) => new Promise(func => setTimeout(func, ms));

    const handleSubmit = async () => {
        if (currentLocal) {
            try {
                showLoading();
                let formData = {} as ISaasChangePlanRequest;
    
                formData.planSelected = {
                    ...formData.planSelected,
                    planId: selectedPlan!.id,
                    featureIds: selectedPlan!.features.map(item => item.id),
                    subscriptionType: selectedPlan!.subscriptionType,
                    amount: selectedPlan!.price,
                }

                if (selectedType === SubscriptionType.ANNUAL) {
                    formData.installment = installments;
                } else {
                    formData.installment = 1;                    
                }

                await SaasChangePlanUseCase(service, {...formData, ownerId: currentLocal.id });

                await wait(3000);

                await getSubscription();

                toast("Troca de plano realizada com sucesso!", "success");
                history.push("/private/plans");
            } finally {
                hideLoading();
            }
        }
    }

    return {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        currentPlan,
        plans,
        installments,
        setInstallments,
        mounParcelValue,
        defaultCard,
        expiresIn,
        handleSubmit,
    }
}