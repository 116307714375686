import React, { useCallback, useMemo, useRef, useState } from 'react'
import styles from './UseForm.module.scss'
import { AddCircle, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { UseFormItem } from './components/useFormItem/UseFormItem'
import { IChildren } from 'modules/permutation/presentation/interfaces/IAddPermutionAccount'
import Sidesheet from 'components/sidesheet/Sidesheet'
import { UseFormItemEdit } from './components/useFormItemEdit/UseFormItemEdit'
import { IPermutationForm } from 'modules/permutation/presentation/interfaces/IPermutationForm'
import { IParamsUseChildList } from 'modules/permutation/presentation/hooks/UseChildList'
import { IUseChildfilter } from 'modules/permutation/presentation/interfaces/IUseChildfilter'
import { IRecordsItem, IUseChildList } from 'modules/permutation/presentation/interfaces/IUseChildList'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import UseAddChild from 'modules/permutation/presentation/hooks/UseAddChild'
import { IAddChild } from 'modules/permutation/presentation/interfaces/IAddChild'
import UseEditChild from 'modules/permutation/presentation/hooks/UseEditChild'
import { IEditChild } from 'modules/permutation/presentation/interfaces/IEditChild'
import UseDeleteChild from 'modules/permutation/presentation/hooks/UseDeleteChild'
import { MenuItem, Pagination, TextField } from '@mui/material'
import DeleteRole from 'modules/cargos/presentation/components/sidesheetCargos/deleteRole/DeleteRole'
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback'
import DeleteValidation from './components/deleteValidation/DeleteValidation'
import { IChildrenErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros'
import { validateUseForm } from '../../validation/UseFormValidation'

interface IUseFormProps {
    values: IPermutationForm
    changeHandle: (name: string, value: string) => void
    changeHandleChecked: (checked: boolean) => void
    onSaveUse: (newChildren: IChildren[], isEdit: boolean) => void
    inputChecked: boolean
    closeSideSheetUseForm: () => void
    openSideSheetUseForm: () => void
    sideSheetUseForm: boolean
    setValues: React.Dispatch<React.SetStateAction<IPermutationForm>>
    isLoading: boolean | undefined
    params: IParamsUseChildList
    setParams: React.Dispatch<React.SetStateAction<IParamsUseChildList>>
    onChangeFilterHandle: (filter: IUseChildfilter) => void
    onChangePaginationHandle: (pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    }) => void
    useChildList: IUseChildList | undefined
    isEdit: React.MutableRefObject<boolean>
    reload: () => void
    pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    } | undefined
}

export const UseForm: React.FC<IUseFormProps> = ({ reload, onChangePaginationHandle, pagination, useChildList, isEdit, setValues, values, inputChecked, sideSheetUseForm, openSideSheetUseForm, closeSideSheetUseForm, changeHandle, changeHandleChecked, onSaveUse }) => {



    const [itemEdit, setItemEdit] = useState<IChildren>()
    const [sideSheetEdit, setSideSheetEdit] = useState(false)
    const { showLoading, hideLoading } = useUi();
    const { postAddPermutation } = UseAddChild();
    const { putEditChild } = UseEditChild();
    const { deleteChild, isLoadingDelete } = UseDeleteChild();
    const [step, setStep] = useState(1);
    const [openDrownDelete, setOpenDrownDelete] = useState(false)
    const idEdit = useRef('')
    const hasAccount = useRef(false)
    const [errors, setErrors] = useState<IChildrenErros>({})



    const deleteNewUse = useCallback((id: string) => {
        const itemDelete = values.children.filter(item => item.id !== id);
        setValues({ ...values, children: itemDelete })
    }, [setValues, values])

    const openSideSheetEdit = (item: IChildren) => {
        setItemEdit(item)
        setSideSheetEdit(true)
    }
    const closeSideSheetEdit = () => {
        setSideSheetEdit(false)
    }

    const onSubmitHandleEdit = useCallback(async (item: IAddChild) => {
        showLoading();
        try {
            if (validateUseForm(item, setErrors)) {
                values.id && await postAddPermutation(values.id, item)
                closeSideSheetUseForm();
                reload();
            }
        } finally {
            hideLoading();
        }
    }, [closeSideSheetUseForm, hideLoading, postAddPermutation, reload, showLoading, values.id])

    const onSubmitHandleEditChild = useCallback(async (item: IEditChild, childrenId: string) => {
        showLoading();
        try {
            values.id && await putEditChild(values.id, childrenId, item)
            closeSideSheetUseForm();
            reload();
        } finally {
            hideLoading();
        }
    }, [closeSideSheetUseForm, hideLoading, putEditChild, reload, showLoading, values.id])

    const onClose = useCallback(() => {
        if (step === 2) {
            reload();
        }
        setStep(1)
        setOpenDrownDelete(false)
    }, [reload, step])

    const openDrowDelete = useCallback(
        (item: IRecordsItem) => {
            idEdit.current = item.id;
            hasAccount.current = item.hasOpenMeepAccount;
            setOpenDrownDelete(true);
        },
        []
    );

    const continueOrSave = useCallback(async () => {
        if (step === 1 && hasAccount.current) {
            onClose();
        } else {
            const res = await deleteChild(values.id ?? '', idEdit.current)
            if (res === "ok") {
                return setStep(step + 1);
            }
        }

    }, [deleteChild, onClose, step, values.id])

    const closedOrDeleteButton = useCallback(() => {
        if (step === 1 && hasAccount.current) {
            return "Fechar"
        } else {
            return "Excluír"
        }
    }, [step])

    const handleContent = useCallback(() => {
        if (step === 1 && hasAccount.current) {
            return <DeleteValidation />
        } else {
            return <DeleteRole />
        }
    }, [step])



    const ListAdd = useMemo(
        () =>
            values.children.map((item, index) => {
                return (
                    <div className={styles.containerList} key={index}>
                        {item.name}
                        <div className={styles.icon}>
                            {
                                <>
                                    <EditOutlined
                                        onClick={() => openSideSheetEdit(item)}
                                    />
                                    <DeleteOutline onClick={() => item?.id && deleteNewUse(item?.id)} />
                                </>
                            }
                        </div>
                    </div>
                );
            }),
        [deleteNewUse, values.children]
    );

    const ListEdit = useMemo(
        () =>
            useChildList?.records.map((item, index) => {
                return (
                    <div className={styles.containerList} key={index}>
                        {item.name}
                        <div className={styles.icon}>
                            {
                                <>
                                    {/* <EditOutlined
                                        onClick={() => openSideSheetEdit(item)}
                                    /> */}
                                    <DeleteOutline onClick={() => openDrowDelete(item)} />
                                </>
                            }
                        </div>
                    </div>
                );
            }),
        [openDrowDelete, useChildList?.records]
    );

    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, page: page - 1, pageSize });
        },
        [onChangePaginationHandle, pagination]
    );


    const countPage = useMemo(() => Math.ceil((useChildList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [useChildList?.totalRecords, pagination?.pageSize])


    return (
        <div id={styles.UseForm}>
            <div className={styles.addUse} onClick={openSideSheetUseForm}>
                <AddCircle /> <span>Adicionar utilização</span>
            </div>
            {
                !isEdit.current ?
                    ListAdd
                    :
                    <>
                        {ListEdit}
                        <div className={styles.conteinerPagination}>
                            <div className={styles.boxMultiplePage}>
                                <span>Exibir</span>
                                <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                    <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                    <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                    <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                </TextField>
                            </div>
                            <Pagination
                                variant="outlined"
                                className={styles.paginationNumber}
                                shape="rounded"
                                count={countPage}
                                sx={{
                                    '.MuiPaginationItem-root': {
                                        borderColor: '#BDBDBD',
                                        color: '#001537',
                                    },
                                    '.Mui-selected': {
                                        fontWeight: 'bold',
                                        borderColor: '#001537',
                                    },
                                }}
                                onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                page={((pagination?.page ? pagination?.page + 1 : 1))}
                            />
                        </div>
                    </>
            }

            <Sidesheet
                open={sideSheetUseForm}
                onClose={closeSideSheetUseForm}
                title={<h2> Adicionar <b>utilização</b> </h2>}
                content={
                    <UseFormItem
                        isEdit={isEdit}
                        onSaveUse={onSaveUse}
                        values={values.children}
                        onSubmitHandleEdit={onSubmitHandleEdit}
                        closeSideSheetUseForm={closeSideSheetUseForm}
                        setErrors={setErrors}
                        errors={errors}
                    />
                }
                cancelButton
                currentStep={0}
                totalSteps={0}
                notTotalHeight
                handleArrowBack={closeSideSheetUseForm}
            />

            <Sidesheet
                open={sideSheetEdit}
                onClose={closeSideSheetEdit}
                title={<h2> Editar <b>utilização</b> </h2>}
                content={
                    <UseFormItemEdit
                        setErrors={setErrors}
                        errors={errors}
                        isEdit={isEdit}
                        onSubmitHandleEditChild={onSubmitHandleEditChild}
                        onSaveChildren={onSaveUse}
                        defaultValues={itemEdit}
                        closeSideSheetUseForm={closeSideSheetEdit}
                    />
                }
                continueButton={'Editar'}
                cancelButton
                currentStep={0}
                totalSteps={0}
                notTotalHeight
                handleArrowBack={closeSideSheetEdit}
            />

            <Sidesheet
                isLoading={isLoadingDelete}
                open={openDrownDelete}
                onClose={onClose}
                title={
                    <h2>
                        Excluir <b>permuta</b>
                    </h2>
                }
                content={handleContent()}
                currentStep={step}
                totalSteps={2}
                handleContinueButton={continueOrSave}
                continueButton={closedOrDeleteButton()}
                cancelButton={!hasAccount.current}
                feedback={
                    <SidesheetFeedback text="Utilização excluída com sucesso!" success />
                }
            />

        </div >
    )
}
