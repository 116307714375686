import { useHistory } from "react-router-dom";
import UseProspect from "./UseProspect";

export const UseBackPage = () => {
    const history = useHistory();
    const { email, prospectId, key, type } = UseProspect();

    const onBack = (page: string) => {
        if (page === "register" || page === "login" || page === "code") {
            return history.push(`/public/saas/${page}?email=${email}&type=${type}`);
        }
        history.push(`/public/saas/${page}?prospectId=${prospectId}&key=${key}&type=${type}&email${email}`);
    }

    return { onBack }
}