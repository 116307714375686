import styles from "./ForDeviceItem.module.scss";
import { format } from "date-fns";
import { ISales } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface IForDeviceItem {
  item: ISales;
}

const ForDeviceItem = ({ item }: IForDeviceItem) => {
  return (
    <div id={styles.ForDeviceItem} >
      {item.equipamentos && item.equipamentos.map((device) => (
        <div className={`${styles.deviceItem} div-to-capture`} key={device.equipamentoId}>
          <span>{device.equipamentoNome}</span>
          <span>
            {device.equipamentoNumeroSerie && device.equipamentoNumeroSerie.length > 6
              ? `${device.equipamentoNumeroSerie.substring(0, 6)}...` : device.equipamentoNumeroSerie
            }
          </span>
          <span>{moneyMaskNumber(device.cancelado ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorCashless ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorDebito ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorDebitoNT ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorCredito ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorCreditoNT ?? 0)}</span>
          {/* <span>PIX</span> */}
          <span>{moneyMaskNumber(device.valorDinheiro ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorDesconto ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorVoucher ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorOutro ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorEmAberto ?? 0)}</span>
          <span>{moneyMaskNumber(device.suprimentos ?? 0)}</span>
          <span>{moneyMaskNumber(device.sangrias ?? 0)}</span>
          <span>{moneyMaskNumber(device.taxaServico ?? 0)}</span>
          <span>{moneyMaskNumber(device.valorTotal ?? 0)}</span>
        </div>
      ))}
        <div className={`${styles.totalItem} div-to-capture`}>
          <span>{item.dataInicio ? format(new Date(item.dataInicio + "Z"), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
          <span>{moneyMaskNumber(item.cancelado ?? 0)}</span>
          <span>{moneyMaskNumber(item.cashless ?? 0)}</span>
          <span>{moneyMaskNumber(item.debito ?? 0)}</span>
          <span>{moneyMaskNumber(item.debitoNT ?? 0)}</span>
          <span>{moneyMaskNumber(item.credito ?? 0)}</span>
          <span>{moneyMaskNumber(item.creditoNT ?? 0)}</span>
          {/* <span>PIX</span> */}
          <span>{moneyMaskNumber(item.dinheiro ?? 0)}</span>
          <span>{moneyMaskNumber(item.desconto ?? 0)}</span>
          <span>{moneyMaskNumber(item.voucher ?? 0)}</span>
          <span>{moneyMaskNumber(item.outro ?? 0)}</span>
          <span>{moneyMaskNumber(item.emAberto ?? 0)}</span>
          <span>{moneyMaskNumber(item.suprimento ?? 0)}</span>
          <span>{moneyMaskNumber(item.sangria ?? 0)}</span>
          <span>{moneyMaskNumber(item.taxaServico ?? 0)}</span>
          <span>{moneyMaskNumber(item.total ?? 0)}</span>
        </div>
      </div>
  );
};
export default ForDeviceItem;
