import { IMigrateGuestsRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IMigratedGuestsResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const MigrateGuestsUseCase = async (
  guestsService: IEventsGuestsApi,
  guests: IMigrateGuestsRequest,
  listId: string,
  localId: string,
): Promise<IMigratedGuestsResponse> => {
  return await guestsService.migrateGuestList(guests, listId, localId);
};

export default MigrateGuestsUseCase;
