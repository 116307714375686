import { StatusBox } from "components/ui/StatusBox/StatusBox";
import styles from "../../ImportDrawer.module.scss";

import Button from "components/ui/Button/Button";
import { FC } from "react";
import { SucessViewProps } from "./SucessView.types";

const SucessView: FC<SucessViewProps> = ({ onClose }) => {
  return (
    <>
      <div className={styles.content}>
        <StatusBox
          type="sucess"
          message="Pronto! Sua importação foi concluída com sucesso."
        />
      </div>
      <div className={styles.actionsRow}>
        <Button fullWidth={false} onClick={() => onClose()}>
          Fechar
        </Button>
      </div>
    </>
  );
};

export { SucessView };
