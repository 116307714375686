export enum EnumProductType {
    PRODUTO = 1,
    RECARGA = 2,
    VARIAVEL = 3,
    PROMOCAO = 4,
    ATIVACAO_CASHLESS_PRE_PAGO = 5,
    DESATIVACAO_CASHLESS_PRE_PAGO = 6,
    ATIVACAO_CASHLESS_POS_PAGO = 7,
    DESATIVACAO_CASHLESS_POS_PAGO = 8,
    ATIVACAO_POS_PAGO_COMANDA = 9,
    DESATIVACAO_POS_PAGO_COMANDA = 10,
    TAXA_DE_SERVICO = 11,
    KILO = 12,
    AJUSTE = 13,
    TAXA_DE_ENTREGA = 14,
    INGRESSO_COM_NOME_NA_LISTA = 15,
    INGRESSO_AVULSO = 16,
    DOACAO = 18

}

export enum EnumGender {
    SEM_RESTRICOES = 0,
    MASCULINO = 1,
    FEMININO = 2
}

export enum CompositionProductType {
    FIXO = 1,
    VARIAVEL = 2,
    COMPOSICAO = 3
}

export enum EnumObservationType {
    EXCLUSIVO = 1,
    INCLUSIVO = 2,
}


export interface ICategorySelect {
    id: string;
    description: string;
    index?: number;
}

export interface IProductSelect {
    id: string;
    name: string;
    value: number;
    categoryId?: string;
    categoryName?: string;
}

export interface IPrinterSelect {
    id: string;
    name: string;
    IP: string;
}


export interface IProductFormValue {

    //category
    categoryId?: string;
    //categoryId?: ICategorySelect | null; // Categoria 

    //detail
    name: string; // Nome
    productInformation: string; // InformacaoProduto
    type?: EnumProductType;
    value?: number; // Valor (optional)
    image?: string;
    imageUrl?: string;

    //indetification
    internalCode?: string; // CodigoInterno
    integrationCode?: string; // CodigoIntegracao (optional)mber; // Tipo (optional)
    barcode?: string; // codigoBarra (optional)

    //advance
    defaultPrinter?: IPrinterSelect | null
    sexRestriction?: EnumGender; // RestricaoPorSexo (optional)
    expireTime?: number; // ExpireTime (optional)
    maxQuantityPerUser?: number; // QuantidadeMaximaPorUsuario (optional)

    tare?: number; // Tara (optional)

    dontShowInKDS?: boolean; // DontShowInKDS
    adultHood: boolean; // AdultHood
    serviceFeeExemption?: boolean; // IsencaoTaxaServico (optional)
    featuredProduct?: boolean;
    //invoice
    cest?: string; // Cest (optional)
    cfop?: string; // Cfop (optional)
    ncm?: string; // Ncm (optional)

    //Observations
    observations: IObservationProductFormValue[]; // Observacoes

    //Additionals And Composition
    complements: IComplementGroupFormValue[]; // Composicao


    //Remover
    productComposition?: boolean; // ProdutoComposicao (optional) morreu
    freeAdditionalQuantity?: number; // QuantidadeAdicionaisGratuito (optional) morreu
}

//Observations
export interface IOpcoesFormValue {
    id: string; // original name: id
    description: string; // original name: descricao
}


export interface IObservationProductFormValue {
    id: string; // original name: id
    description: string; // original name: descricao
    type: EnumObservationType; // original name: tipo
    isReserved?: boolean; // original name: ehDeReserva
    options: IOpcoesFormValue[]; // original name: opcoes
}




//complemento
//TABELA CategoriaProdutoLocalCliente 
export interface IProductComplementCategoryFormValue { 
    categoryId?: string; //original name: Id. Deverá editar a categoria 
    description: string; //original name: Nome
    min: number;
    max: number;
    freeQuantity: number;
}


export interface IComplementProductFormValue {
    //Complement vwComposicaoProduto
    id: string; //id do relacionamento
    value: number; //Value será criado tanto em vwProduto quanto em vwComposicaoProduto.
    type: CompositionProductType;
    quantity?: number;
    min?: number;
    max?: number;
    freeQuantity?: number;
    //OBS:  Parent_Id é o Id do produto principal que esta sendo cadastrado.
   
    //product vwProduto
    name: string;
    productValue: number;
    productId?: string; //Case exista deverá editar um produto, deverá também atualizar a tabela vwComposicaoProduto de todos os produtos que também possuem esse produto como composição
    image?: string;
    imageUrl?: string;
    ncm?: string;
    cfop?: string;
    cest?: string;

}



export interface IComplementGroupFormValue {
    complementCategory: IProductComplementCategoryFormValue
    complementProducts: IComplementProductFormValue[];
};

/*
    complementCategory deverá ser criado uma categoria de produtos e caso esse objeto ja venha com categoryId deverá atualizar uma categoria existente.
    Em seguida deverá criar ou editar produtos dessa categoria e o id do produto que sera criado para composiçã sera usado na tabela vwComposicaoProduto na propriedade Referencia_Id
 */








