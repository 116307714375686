import { IPostSystemConfigPayload } from "modules/admin/menuConfig/domain/dto/IPostSystemConfigPayload";

export interface INameFormErrors {
	name?: string;
  systemIdentifier?: string;
}

export const NameValidation = (formData: IPostSystemConfigPayload, setErrors: React.Dispatch<React.SetStateAction<INameFormErrors>>) => {
  let _errors: INameFormErrors = {};
  let hasError = false;

  if (!formData.name) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }

  if (formData.systemIdentifier === undefined || (!formData.systemIdentifier && formData.systemIdentifier !== 0)) {
    _errors = { ..._errors, systemIdentifier: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
