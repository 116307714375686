import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CopyDeviceUseCase from "modules/equipamento/application/useCases/CopyDeviceUseCase";
import GetProfileListUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/GetProfileListUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DeviceApi from "services/api/equipamento/DeviceApi";
import DeviceProfileApi from "services/api/config/device/DeviceProfileApi";
import { IDeviceItem } from "../components/deviceList/interfaces/IDeviceItem";
import EditMassiveDeviceProfileUseCase from "modules/config/deviceConfig/application/useCase/deviceProfile/EditMassiveDeviceProfileUseCase";
import { IDeviceProfileItem } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileList/interfaces/IProfileItem";
import { IFilterDevice, IPaginationProfileDevice } from "../components/filterDevice/IFilterDevice";
import { IMassiveDeviceProfileValue } from "../components/deviceProfileForm/IMassiveDeviceProfileValue";
import GetDeviceUseCase from "modules/equipamento/application/useCases/GetDeviceUseCase";

const UseDevicePage = () => {
  const [preloading, setPreloading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<IDeviceItem[]>([]);

  const deviceService = useMemo(DeviceApi, []);
  const deviceProfileService = useMemo(DeviceProfileApi, []);

  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const { push } = useHistory();
  const { action } = useParams<{ action: string }>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [deviceList, setDeviceList] = useState<IDeviceItem[]>();
  const [filteredDeviceList, setFilteredDeviceList] = useState<IDeviceItem[]>();
  const [filterDevice, setFilterDevice] = useState<IFilterDevice>({});
  const [profileList, setProfileList] = useState<IDeviceProfileItem[]>();

  const onClickAddDeviceHandle = () => {
    push(`/private/pdv/equipamento/adicionar`);
  };
  const onClickEditDeviceHandle = (profileId: string) => {
    push(`/private/pdv/equipamento/editar/` + profileId);
  };

  const getDeviceListOnChangeFilter = useCallback(
    async (filter: IFilterDevice) => {
      try {
        if (currentLocal) {
          setLoading(true);
          showLoading();

          const response = await GetDeviceUseCase(currentLocal.id, deviceService, filterDevice);
          setDeviceList(response.items);
          setTotalCount(response.totalCount);
          setFilteredDeviceList(response.items);
        } else {
          console.error("Nenhum local selecionado");
        }
      } catch (error) {
      } finally {
        hideLoading();
        setLoading(false);
      }
    },
    [
      currentLocal,
      showLoading,
      deviceService,
      deviceProfileService,
      filterDevice,
      hideLoading,
    ]
  );

  const onChangePaginationHandle = useCallback((pagination: IPaginationProfileDevice) => {
    setFilterDevice((prev) => ({ ...prev, pagination: { offset: (pagination.offset ?? 0) * (pagination.limit ?? 50), limit: pagination.limit } }));
  }, [setFilterDevice]);

  const onSelectItem = useCallback(
    (deviceItem) => {
      const selectedItem = selectedDevices.find((x) => x.id === deviceItem.id);

      if (selectedItem) {
        setSelectedDevices((prevState) =>
          prevState.filter((x) => x.id !== deviceItem.id)
        );
      } else {
        setSelectedDevices((prevState) => [...prevState, deviceItem]);
      }
    },
    [selectedDevices]
  );

  const onChangeProfileHandler = useCallback(() => {
    push(`/private/pdv/equipamento/editarPefil/`);
  }, [push]);

  const copyDevice = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        showLoading();
        await CopyDeviceUseCase(deviceService, id);
      } catch (error) {
      } finally {
        setLoading(false);
        hideLoading();
      }
    },
    [deviceService, hideLoading, showLoading]
  );

  const getAllOnOpenPage = useCallback(async () => {
    try {
      setLoading(true);
      showLoading();
      if (currentLocal) {
        const promiseDevices = GetDeviceUseCase(
          currentLocal.id,
          deviceService,
          filterDevice
        );
        const promiseProfiles = GetProfileListUseCase(
          deviceProfileService,
          currentLocal.id,
          {}
        );
        const [responseDevices, responseProfiles] = await Promise.all([
          promiseDevices,
          promiseProfiles,
        ]);
        setDeviceList(responseDevices.items);
        setFilteredDeviceList(responseDevices.items);
        setTotalCount(responseDevices.totalCount);
        setProfileList(responseProfiles);
      }
    } finally {
      setLoading(false);
      setPreloading(false);
      hideLoading();
    }
  }, [
    currentLocal,
    deviceProfileService,
    deviceService,
    filterDevice,
    hideLoading,
    showLoading,
  ]);

  const onClickDeviceHandle = useCallback((profileItem: IDeviceItem) => {
    //open modal edit
  }, []);

  const onAddDeviceHandle = useCallback(() => {
    //open modal edit
  }, []);

  const onCopyDeviceHandle = useCallback(
    (deviceItem: IDeviceItem) => {
      copyDevice(deviceItem.id);
    },
    [copyDevice]
  );

  const onChangeFilterHandle = useCallback((filter: IFilterDevice) => {
    setFilterDevice(filter);
  }, []);

  const onFilterHandle = useCallback(() => {
    getDeviceListOnChangeFilter(filterDevice);
  }, [filterDevice, getDeviceListOnChangeFilter]);

  const onCloseModal = useCallback(() => {
    push(`/private/pdv/equipamento`);
  }, [push]);

  const onChangeDeviceProfile = useCallback(
    async (profileId: string, devices: IMassiveDeviceProfileValue[]) => {
      try {
        if (currentLocal) {
          showLoading();
          const profileService = DeviceProfileApi();
          await EditMassiveDeviceProfileUseCase(
            profileId,
            currentLocal.id,
            profileService,
            devices
          );
          onCloseModal();
          getAllOnOpenPage();
          setSelectedDevices([]);
        } else {
          console.error("local não selecionado");
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, getAllOnOpenPage, hideLoading, onCloseModal, showLoading]
  );

  useEffect(() => {
    getAllOnOpenPage();
  }, [getAllOnOpenPage]);

  const onSearch = useCallback((ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setFilterDevice((prev) => ({ ...prev, query: searchTerm, pagination: { offset: 0 } }));
  }, [setFilterDevice, searchTerm]);

  return {
    preloading,
    loading,
    deviceList,
    filteredDeviceList,
    profileList,
    filterDevice,
    action,
    selectedDevices,
    searchTerm,
    totalCount,
    setSearchTerm,
    onChangeProfileHandler,
    onSelectItem,
    onClickDeviceHandle,
    onCopyDeviceHandle,
    onChangeFilterHandle,
    onFilterHandle,
    onAddDeviceHandle,
    onClickAddDeviceHandle,
    onClickEditDeviceHandle,
    setSelectedDevices,
    onChangeDeviceProfile,
    onCloseModal,
    onChangePaginationHandle,
    onSearch,
  };
};

export default UseDevicePage;
