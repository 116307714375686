import { Icon, IconButton } from '@material-ui/core'
import { IGetDiscountCouponsResponse } from 'modules/config/discountCoupons/domain/dto/IGetDiscountCouponsResponse'
import React from 'react'
import { CouponForm } from '../../components/couponForm/CouponForm'
import { IParamsClientDiscountCouponsList } from '../../hooks/UseDiscountCouponsList'
import styles from './AddNewCoupon.module.scss'

export interface IAddNewCouponProps {
    closeDrown: () => void
    refresh: (params?: IParamsClientDiscountCouponsList | undefined) => Promise<IGetDiscountCouponsResponse | undefined>

}

export const AddNewCoupon: React.FC<IAddNewCouponProps> = ({ closeDrown, refresh }) => {
    return (
        <div id={styles.AddNewCoupon}>
            <div className={styles.titleTop}>
                <h2>Novo <b>cupom de desconto</b></h2>
                <IconButton size='small' onClick={closeDrown}><Icon>close</Icon></IconButton>
            </div>

            <CouponForm refrash={refresh}  onClose={closeDrown}/>

        </div>
    )
}
