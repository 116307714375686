import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./LinkCardsItem.module.scss";
import { DeleteOutline } from "@mui/icons-material";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import DeleteValidation from "../../../../../../../components/deleteValidation/DeleteValidation";
import { ILinkCardsColumnsConfig, LinkCardsColunmsPropertyType } from "../../interfaces/IColumnsConfig";
import { ILinkCardsList, ILinkCardItem } from "../../../../interfaces/ILinkCardsList";
import Utils from "services/utils/Utils";
import { IParamsLinkCardsList } from "modules/linkCards/presentation/hooks/UseLinkCardsList";
import UseDeleteLinkCardsItem from "modules/linkCards/presentation/hooks/UseDeleteLinkCardsItem";
import { Checkbox } from "@mui/material";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

interface Props {
  columns: ILinkCardsColumnsConfig[];
  userItemData: ILinkCardItem;
  linkCardsList: ILinkCardsList;
  reload: () => void;
  params: IParamsLinkCardsList;
  setOpenSidesheet: React.Dispatch<React.SetStateAction<"add" | null>>
  open: boolean
  onCheckLinkCardHandle: (product: ILinkCardItem, value: boolean) => void
  allSelected: boolean
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>  
  setSelectedLinkCards: React.Dispatch<React.SetStateAction<ILinkCardItem[]>>
  
}

const LinkCardsItem: React.FC<Props> = ({
  userItemData,
  columns,
  setOpenSidesheet,
  reload,  
  onCheckLinkCardHandle,
  allSelected,
  setAllSelected,
  setSelectedLinkCards,
  linkCardsList
}) => {

  const [step, setStep] = useState(1);
  const [openDrownDelete, setOpenDrownDelete] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const { deleteLinkCardsItem, isLoadingDelete, successDelete, deleteData } = UseDeleteLinkCardsItem();

  const { hasAccessRole } = useAuth();


  const onClose = useCallback(() => {
    if(step === 2) {
      reload();
    }
    setStep(1)
    setOpenDrownDelete(false)
    setAllSelected(false)
  }, [reload, setAllSelected, step])
 
  const continueOrSave = useCallback(async () => {  
      const res = await deleteLinkCardsItem(userItemData.tag);
      if (successDelete.current) {  
        setIsChecked(false);
        return setStep(step + 1);
      }
  }, [deleteLinkCardsItem, step, successDelete, userItemData.tag])

  const closedOrDeleteButton = useCallback(() => {
    if (step === 1) {
      return "Excluír"
    } else {
      return "Fechar"
    }
  }, [step])
  
  const openDrowDelete = useCallback(
    () => {
      setOpenDrownDelete(true);
    },
    []
  );

  const formatValues = useCallback(
    (property: LinkCardsColunmsPropertyType): string => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {       
          case "createdAt":
            return userItemData?.createdAt
              ? Utils.toDate(userItemData?.createdAt)
              : "-";

          default:
            return String(userItemData?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [userItemData]
  );

  const onCheckedLinkCardsHandel = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
  
      setSelectedLinkCards(prev => {
        let updatedSelection;
  
        if (checked) {
          updatedSelection = [...prev, userItemData];
        } else {
          updatedSelection = prev.filter(item => item.id !== userItemData.id);
        }
        const allItemsSelected = updatedSelection.length === linkCardsList?.records?.length;
  
        if (allItemsSelected) {
          setAllSelected(true);
        } else if (updatedSelection.length === 0) {
          setAllSelected(false);
        }
  
        return updatedSelection;
      });
    },
    [linkCardsList?.records, setSelectedLinkCards, userItemData, setAllSelected]
  );
  

  useEffect(() => {
    setIsChecked(allSelected);
  }, [allSelected]);

  const List = useMemo(
    () =>
    <>
        {hasAccessRole("PreAtivacaoCartoesCadastrar") && (           
          <div className={styles.checkButton} >
            <Checkbox
                  style={{ height: 40 }}
                  checked={isChecked}
                  onChange={(_, _checked) => {
                      onCheckedLinkCardsHandel?.(_checked);
                  }}
              />
          </div>
        )}     
    { columns.map((column, index) => {
        return (
          !column.hide && (
            <div
            key={index}
            className={styles.row}
            style={column.style ?? { flex: 1 }}
            >            
              <span title={formatValues(column.property)}>{formatValues(column.property)}</span>
            </div>
          )
        );
      })}
      </>,
    [columns, formatValues, hasAccessRole, isChecked, onCheckedLinkCardsHandel]
  );

  const handleContent = useCallback(() => {
    let highlightText = `${userItemData.tag}?`;
    let message = `Deseja realmente excluir este vínculo de cartões`;
  

    return <DeleteValidation text={message} highlightText={highlightText} />
  }, [userItemData.tag])

  return (
    <>
      <div id={styles.LinkCardsItem}>
        <div className={styles.container}>
          {List}
          {hasAccessRole("PreAtivacaoCartoesCadastrar") && (           
            <div className={styles.icon}>
            <DeleteOutline titleAccess="Excluir" onClick={openDrowDelete} />
            </div>
           )}      
        </div>
      </div>
      <Sidesheet
        isLoading={isLoadingDelete}
        open={openDrownDelete}
        onClose={onClose}
        title={
          <h2>
            Excluir <b>vínculo</b>
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        handleContinueButton={continueOrSave} 
        continueButton={closedOrDeleteButton()}
        feedback={
       
          deleteData?.totalCardsWithError && deleteData?.totalCardsWithError > 0 ?
                <SidesheetFeedback text="Erro ao deletar cartão"/>
                :
                <SidesheetFeedback text="Cartão deletado com sucesso!" success />

        }
      />
    </>
  );
};

export default LinkCardsItem;
