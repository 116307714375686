import { api } from "../Api";
import { ISaleForDeviceReportService } from "modules/saleForDevice/domain/interface/ISaleForDeviceReportService";
import { IPostSaleForDeviceParams } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceParams";
import { IPostSaleForDeviceResponse } from "modules/saleForDevice/domain/dto/IPostSaleForDeviceResponse";

const SaleForDeviceReportApi = (): ISaleForDeviceReportService => {
  const postSaleForDevice = async (params: IPostSaleForDeviceParams): Promise<IPostSaleForDeviceResponse> => {
      const respomse = await api.post<IPostSaleForDeviceResponse>(`/Relatorio/BuscarVendasPorEquipamentoPOS`, params);
      return respomse.data;
  }

  return { postSaleForDevice };
}

export default SaleForDeviceReportApi;
