import { Drawer, Icon } from '@material-ui/core';
import styles from './PermissionModal.module.scss';

interface PermissionModalProps {
    title: string;
    open: boolean;
    onClose: () => void;
}

export const PermissionModal = ({ title, open, onClose }: PermissionModalProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>{title.split(' ')[0]} <b>{title.split(' ')[1]} {title.split(' ')[2]}</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>
                <div className={styles.content}>
                    <img src="/assets/icon/plans/permission.svg" alt="" />
                    <p>
                        Ops, parece que você não tem permissão para realizar essa contratação!
                        Para isso, é necessário ser <b>administrador do estabelecimento.</b>
                    </p>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}