import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import Layout from 'components/layout/presentation/Layout';
import styles from './IfoodCalculatorPage.module.scss';
import IfoodCalculator from '../../components/ifoodCalculator/IfoodCalculator';
import CalculatorBanner from '../../components/calculatorBanner/CalculatorBanner';
import { WizardInfoModal } from 'components/widgets';

const modalContent = [
    {
        image: '/assets/img/ifood-calculator-phone.png',
        title: '1. Insira o valor do seu produto',
        description: 'Preencha informando o valor de venda o seu produto atualmente fora da plataforma',
    },{
        image: '/assets/img/ifood-calculator-phone.png',
        title: '2. Selecione sua entrega',
        description: 'Escolha como é feito o seu delivery, entrega própria ou entregador do Ifood e escolha se deseja cobrar ou não esse valor',
    },{
        image: '/assets/img/ifood-calculator-phone.png',
        title: '3. Calcule o valor com desconto',
        description: 'Você pode escolher se deseja calcular esse valor com ou sem um cupom de desconto',
    },{
        image: '/assets/img/ifood-calculator-phone.png',
        title: '4. Período de repasse',
        description: 'Selecione qual o seu período de repasse e pronto! ✅',
    }
];

const IfoodCalculatorPage = () => {
    const history = useHistory();
    const [showTipModal, setShowTipModal] = useState(false);

    return (
        <Layout limitedWidth bgColor="linear-gradient(180deg, #F3EDFF 0%, rgba(149, 92, 255, 0.3) 85%)">
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className={styles.toolsCalculatorReturn} onClick={() => history.push('/private/ferramentas')}>
                        <ChevronLeft fontSize="large"  />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <CalculatorBanner
                        title="Bem vindo à calculadora iFood"
                        description="Calcule o valor de venda de seus produtos no Ifood e não perca dinheiro com taxas"
                        exploreSystemFn={() => history.push('/private')}
                        howToWorksFn={() => setShowTipModal(true)}
                    />

                    <WizardInfoModal
                        isOpen={showTipModal}
                        content={modalContent}
                        closeFn={() => setShowTipModal(false)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <IfoodCalculator />
                </Grid>
            </Grid>
        </Layout>
    );
}

export default IfoodCalculatorPage;