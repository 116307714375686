import { TextField, Icon } from "@material-ui/core";
import { FC, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Row, InputContainer, Container, FilterButtonContainer } from "../../ui/form/FormContainers";
import AutocompleteInput, { IAutocompletePaginatedListResponse, IAutocompletePaginatedRequest } from "../../autocomplete/AutocompleteInput";
import { IGetSupplySelectItemResponse } from "modules/meepErp/application/dtos/supply/IGetLSupplyListResponse";
import Button from "components/ui/Button/Button";
import styles from './SupplyStockFilter.module.scss'

export interface ISupplyStockFilterValues {
    page?: number;
    pageSize?: number;
    orderBy?: string;
    ascending?: boolean
    supplyId?: string;
    supplyName?: string;
    name?: string;
    code?: number | null;
    date?: string;
    supplyGroupId?: string;
    storageLocationId?: string;
    storageLocationName?: string;
    minQuantity?: number;
    maxQuantity?: number;
    unitCost?: number;
    totalCost?: number;
}

export interface ISupplyStockFilterProps {
    defaultValue?: ISupplyStockFilterValues
    onSubmit: (values: ISupplyStockFilterValues) => void
    getSupplyLocalStorage: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
    getSupply: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<IGetSupplySelectItemResponse>>

}
const SupplyStockFilter: FC<ISupplyStockFilterProps> = ({
    defaultValue,
    onSubmit,
    getSupplyLocalStorage,
    getSupply
}) => {
    const { register, handleSubmit, reset, setValue, watch } = useForm<ISupplyStockFilterValues>({
        defaultValues: defaultValue
    });

    useEffect(() => {
        if (defaultValue) {
            reset(defaultValue);
        }
    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: ISupplyStockFilterValues) => {
        delete values.supplyName;
        delete values.storageLocationName;
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();
    console.log(allFields, 'allFields')

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.orderBy;
        delete allFields.ascending;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            code: null,
            name: '',
            storageLocationId: '',
            storageLocationName: '',
            supplyId: '',
            supplyName: ''
        });
        onSubmit({
            code: null,
            name: '',
            storageLocationId: '',
            storageLocationName: '',
            supplyId: '',
            supplyName: ''

        })
    }, [onSubmit, reset])



    return (
        <form
            onSubmit={handleSubmit(onSubmitHandle)}
            id={styles.SupplyStockFilter}
        >
            <Container>
                <Row className={styles.row}>
                    <InputContainer label="Código">
                        <TextField
                            {...register("code")}
                        />
                    </InputContainer>
                    <InputContainer label="Nome">
                        <TextField
                            {...register("name")}
                        />
                    </InputContainer>
                    <InputContainer label={"Local de estoque"}>
                        <AutocompleteInput
                            getList={getSupplyLocalStorage}
                            getOptionLabel={option => option.name ?? ""}
                            textInput={watch("storageLocationName")}
                            value={{
                                id: watch("storageLocationId"),
                                name: watch("storageLocationName")
                            }}
                            onSelect={(option) => {
                                if (!option)
                                    return;
                                setValue("storageLocationName", option.name);
                                setValue("storageLocationId", option.id);
                            }}
                        />
                    </InputContainer>
                    <InputContainer label="Insumo" >
                        <AutocompleteInput
                            getList={getSupply}
                            getOptionLabel={option => option.name}
                            textInput={watch("supplyName")}
                            value={
                                {
                                    id: watch("supplyId") ?? '',
                                    name: watch("supplyName") ?? '',
                                    code: 0
                                }
                            }
                            onSelect={(option) => {
                                if (!option)
                                    return;
                                setValue("supplyName", option.name);
                                setValue("supplyId", option.id);
                            }}
                        ></AutocompleteInput>
                    </InputContainer>
                    <InputContainer  >
                    <FilterButtonContainer minWidth={100} >
                        {isAnyFieldFilled &&
                            <Button
                                onClick={onClickResetFilter}
                                variant="text"
                                color="primary"
                                startIcon={<Icon>close</Icon>}>
                                Limpar filtro
                            </Button>}
                        <Button color='secondary' type="submit" style={{width: '76px', minWidth: '76px'}}>Buscar</Button>
                    </FilterButtonContainer>
                </InputContainer>
                </Row>
            </Container>
        </form >
    )
}
export default SupplyStockFilter