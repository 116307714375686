import GetVouchersUseCase from 'modules/customersDashboard/application/useCases/GetVouchersUseCase'
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type'
import { useCallback, useState } from 'react'
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService'
import { IVouchersDataItem } from '../components/pieVouchers/IVouchers'

const UseVouchers = (filter: ICustomerDashboardFilter) => {
    const [isLoading, setIsLoading] = useState(false)
    const [vouchersData, setVouchersData] = useState<IVouchersDataItem[]>();
    const [error] = useState<string>()

    const getVouchersData = useCallback(async () => {
        if (!filter.eventId && !filter.cashierId) return;

        const serviceCustomersDashboard = CustomersDashboardService();
        try {
            setIsLoading(true)
            const response = await GetVouchersUseCase(serviceCustomersDashboard, filter);
            setVouchersData(response.records)
        } finally {
            setIsLoading(false)
        }
    }, [filter])

    return ({
        isLoading,
        vouchersData,
        getVouchersData,
        error
    })
}

export default UseVouchers