
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";


export const DeleteCouponUseCase = async (service: IDiscountCouponsService, couponId: string, localId: string) => {

    const _request = await service.deleteDiscountCoupon(couponId, localId);
    return _request;
}


