import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TabPage from '../dashboard/tabPage/TabPage'

export interface IStockPageProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    value?: string,
    component?: React.ReactNode;
    subItems?: {
        label: string;
        tab: string;
        url: string;
        role: string;
    }[]
}

const StockPage: FC<IStockPageProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        const currentTab = menuItems.find(item => tab === item.tab);
        const routers = [{
            label: "Estoque",
            title: currentTab?.title
        }];

        if (currentTab?.subItems?.length) {
            routers.push({
                label: currentTab?.subItems?.length ? currentTab?.title : '',
                title: currentTab?.subItems?.find(item => item.tab === subTab)?.label || currentTab?.title
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters]);

    const menuItems:IMenuItem[] = [
        {
            title: "Entrada",
            value: "/estoque/entrada",
            tab: "entrada",
            role: "EstoqueEntradaPortal"
        },
        {
            title: "Saída",
            value: "/estoque/saida",
            tab: "saida",
            role: "EstoqueSaidaPortal"
        },
        {
            title: "Posição",
            value: "/estoque/posicao",
            tab: "posicao",
            role: "EstoquePosicaoPortal"
        },
        {
            title: "Motivo",
            value: "/estoque/motivo",
            tab: "motivo",
            role: "EstoquePosicaoPortal"
        }
    ];

    const subTabComponent = useCallback((tabItem: IMenuItem) => !!tabItem.subItems?.length ?
        <TabPage
            routerPrefix={"/private/estoque/" + tabItem.tab}
            subTabEnabled
            tabsPages={tabItem.subItems?.map(subTabItem => (
                {
                    label: subTabItem.label,
                    tab: subTabItem.tab,
                    roles: subTabItem.role,
                    component: <>
                        <IframePage src={subTabItem.url} />
                    </>
                }
            ))}
        /> : tabItem.component || <IframePage src={tabItem.value!} />, []);

    return (
        <TabPage
            routerPrefix={'/private/estoque'}
            tabsPages={menuItems.map((tabItem) => (
                {
                    label: tabItem.title,
                    tab: tabItem.tab,
                    roles: tabItem.role,
                    component: subTabComponent(tabItem)
                }
            ))} />
    );
}
export default StockPage;
