import { FC } from 'react';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { IProductItemToDiscount } from 'modules/events/presentation/components/discountPeopleListForm/interfaces/IProductList';
import { IDiscounts } from 'modules/events/presentation/components/discounts/interfaces/IDiscounts';
import { IDiscountProductListForm } from 'modules/events/presentation/pages/discountsList/components/discountListEditForm/interfaces/IDiscountProductListForm';
import { moneyMaskNumber } from 'services/utils/Money';
import styles from './DiscountProductForm.module.scss';
import UseDiscountProductForm from './UseDiscountProductForm';
import { IEvents } from 'modules/events/domain/models/IEvents';
import { Autocomplete } from '@mui/material'
import Checkbox from 'components/ui/checkbox/Checkbox';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { ITicketForm } from '../../../tickets/components/ticketForm/ITicketForm';

export interface IDiscountProductFormProps {
  onSubmit: (value: IDiscountProductListForm) => Promise<any>;
  productList: ITicketForm[];
  onClose: () => void;
  defaultDiscount?: IDiscounts | null;
  discounts: IDiscounts[];
  event: IEvents
}
const DiscountProductForm: FC<IDiscountProductFormProps> = ({
  onSubmit,
  onClose,
  productList,
  defaultDiscount,
  discounts,
  event
}) => {
  const {
    submitHandle,
    changeProductHandle,
    changeDiscountValueHandle,
    values,
    setValues,
    errors,
    closeModal
  } = UseDiscountProductForm(onSubmit, onClose, discounts, defaultDiscount, event);

  return (
    <div id={styles.DiscountPeopleForm}>
      <form onSubmit={submitHandle}>
        <FormItemContainer>
          {defaultDiscount && (
            <>
              <div className={styles.defaultDiscount}>
                <p>
                  Evento: <b>{event.name}</b>
                </p>

                <p>
                  Ingresso atual do desconto:  <b>{defaultDiscount.productName}</b>
                </p>
              </div>
            </>
          )}
          <FormItemContainer
            label={defaultDiscount ? 'Substituir ingresso do desconto por' : 'Selecione o ingresso'}
            className={styles.itemContainer}>
            <Autocomplete
              className={styles.inputProduct}
              options={productList}
              noOptionsText="Sem opções"
              value={values?.product}
              getOptionLabel={option => option.name}
              onChange={(_value, item) =>
                changeProductHandle(item as IProductItemToDiscount)
              }

              renderInput={(params) => (
                <TextField
                  variant={"outlined"}
                  {...params}
                ></TextField>
              )}
            />
            {values?.product?.price && (
              <p className={styles.ticketValue}>
                Valor do ingresso: {moneyMaskNumber(values?.product?.price)}
              </p>
            )}
          </FormItemContainer>

          <div>
            <FormItemContainer
              label={'Valor final do ingresso'}
              className={styles.itemContainer}>
              <Input
                error={!!errors.discount}
                helperText={errors.discount}
                disabled={!values?.product}
                required
                name={'discount'}
                variant='outlined'
                value={moneyMaskNumber(
                  (values?.product?.price || 0) - (values?.discount || 0),
                )}
                onChange={ev => changeDiscountValueHandle(ev.target.value)}
              />
              <p>{`${moneyMaskNumber(
                values?.discount ?? 0,
              )} de desconto aplicado`}</p>
            </FormItemContainer>

            <div className={styles.dateContainer}>
              <FormItemContainer label={"Data limite"} className={styles.formItem}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DesktopDatePicker
                    value={values?.validDate ?? null}
                    onChange={(newValue) => {
                      if (newValue) setValues((prev) => ({ ...prev, validDate: newValue }));
                    }}
                    disablePast
                    renderInput={(params) => <TextField
                      {...params}
                      size="small"
                      className={`${styles.datePicker} dashboardFilterDatePicker`}
                      type="date"
                      name="date"
                      error={!!errors.validDate}
                      helperText={errors.validDate}
                    />}
                  />
                </LocalizationProvider>
                {defaultDiscount && (
                  <Checkbox
                    label="Usar data final do evento"
                    onChange={(_, checked) => setValues((prev) => ({ ...prev, validDate: checked ? new Date(event.openingDates[0].endDate) : undefined }))}
                  />
                )}
              </FormItemContainer>

              <FormItemContainer label={"Horário limite"} className={styles.formItem}>
                <TextField
                  value={values.validTime ?? null}
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev, validTime: e.target.value
                    }));
                  }}
                  name="time"
                  type="time"
                  className={`${styles.datePicker} dashboardFilterDatePicker`}
                  size="small"
                  error={!!errors.validTime}
                  helperText={errors.validTime}
                />
                {defaultDiscount && (
                  <Checkbox
                    label="Manter horário de desconto"
                    className="dateItemCheckbox"
                    onChange={(_, checked) => setValues((prev) => ({ ...prev, validTime: checked && defaultDiscount ? new Date(defaultDiscount?.validUntil) : undefined }))}
                  />
                )}
              </FormItemContainer>
            </div>
          </div>
        </FormItemContainer>
      </form>
      <div className={styles.actions}>
        <Button onClick={closeModal} variant={'outlined'} className={styles.cancel} fullWidth={false}>
          Voltar
        </Button>
        <Button
          type={'button'}
          onClick={() => submitHandle()}
          fullWidth={false}
          className={values?.product?.price ? styles.confirm : ''}
          disabled={values?.product?.price ? false : true}
        >
          Concluir
        </Button>
      </div>
    </div>
  );
};
export default DiscountProductForm;
