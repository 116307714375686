import Layout from "components/layout/presentation/Layout";
import { PlansPage } from "./PlansPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { SaasPlanPage } from "./SaasPlanPage";

export const Plans = () => {
  const { currentLocal } = useLocal();

  return (
    <Layout>
      {currentLocal?.systemIdentifier === 1 ? <SaasPlanPage /> : <PlansPage />}
    </Layout>
  );
};
