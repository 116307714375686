import { FC, useEffect } from "react";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { IDeviceLicenseItem } from "./interface/IDeviceLicenseItem";
import styles from "./DeviceLicenseForm.module.scss";
import FormContainer from "../../formContainer/FormContainer";
export interface ILicenseFormProps {
  //propertys
  licences?: IDeviceLicenseItem[];
  onClickItem: (license: IDeviceLicenseItem) => void;
  onClose: () => void;
}
const DeviceLicenseForm: FC<ILicenseFormProps> = ({
  licences,
  onClickItem,
  onClose,
}) => {
  const getDeviceTypeImage = (deviceType: DeviceType) => {
    switch (deviceType) {
      case DeviceType.POS:
        return "/assets/icon/device-pos.svg";
      case DeviceType.TOTEM:
        return "/assets/icon/device-kiosk.svg";
      case DeviceType.CHECK:
        return "/assets/icon/device-tablet.svg";
      case DeviceType.TABLET:
        return "/assets/icon/device-check.svg";
    }
  };
  const getDeviceTypeName = (deviceType: DeviceType) => {
    switch (deviceType) {
      case DeviceType.POS:
        return "Maquininha";
      case DeviceType.TOTEM:
        return "Totem";
      case DeviceType.CHECK:
        return "Validador de ficha";
      case DeviceType.TABLET:
        return "Tablet de mesa";
    }
  };

  useEffect(() => {
    return () => {};
  }, [licences]);

  return (
    <div id={styles.LicenseForm}>
      <FormContainer
        title={
          <span>
            Adicionar <b>Equipamento</b>
          </span>
        }
        onClickClose={onClose}
      >
        <div className={styles.list}>
          {!licences?.length ? (
            <div>Nenhuma licença encontrada</div>
          ) : (
            licences.map((item) => (
              <div
                key={item.id}
                id={styles.DeviceLicenseItem}
                onClick={() => onClickItem(item)}
              >
                <div className={styles.icon}>
                  <img
                    src={getDeviceTypeImage(item.type.value)}
                    alt={"Icone do dispositivo"}
                  ></img>
                </div>
                <div className={styles.typeName}>
                  {item.type.name
                    ? item.type.name
                    : getDeviceTypeName(item.type.value)}
                </div>
                <div className={styles.used}>
                  <label>Usadas: </label>
                  {item.totalUsed}
                </div>
                <div className={styles.remain}>
                  <label>Disponiveis: </label>
                  {item.total - item.totalUsed}
                </div>
              </div>
            ))
          )}
        </div>
      </FormContainer>
    </div>
  );
};
export default DeviceLicenseForm;
