
import { Dialog } from '@material-ui/core'
import React, { FC } from 'react'
import ServiceCategoryForm from '../../components/serviceCategoryForm/ServiceCategoryForm'
import { ServiceForm } from '../../components/serviceForm/ServiceForm'
import ServiceProductListForm from '../../components/serviceProdutctForm/ServiceProductListForm'
import styles from './AddServicePage.module.scss';
import { UseAddServicePage } from './UseAddServicePage'

export const AddServicePage: FC = () => {

    const {
        productLists,
        serviceCategories,
        serviceMainCategories,
        openDrawerCagetory,
        setOpenDrawerCagetory,
        addNewServiceCategoryHandle,
        addNewServiceProductListHandle,
        addServiceScheduleHandle,
        toggleModalCategory,
        toggleModalProduct,
        openDrawerProduct,
        setOpenDrawerProduct,
        serviceCategoriesAll
    } = UseAddServicePage();
    return (

        <div id={styles.AddServicePage}>

            <div className={styles.containerServiceForm}>
                <ServiceForm
                    productLists={productLists}
                    serviceCategories={serviceCategoriesAll}
                    onClickAddNewCategory={() => setOpenDrawerCagetory(true)}
                    onSubmit={addServiceScheduleHandle}
                    onClickAddNewProduct={() => setOpenDrawerProduct(true)}
                />
            </div>
            <Dialog maxWidth="lg" open={openDrawerCagetory} onClose={() =>
                setOpenDrawerCagetory(false)}>
                <div>
                    <ServiceCategoryForm onSubmit={addNewServiceCategoryHandle} serviceMainCategories={serviceCategories} onClickClose={toggleModalCategory} />
                </div>
            </Dialog>
            <Dialog  fullWidth open={openDrawerProduct} onClose={() =>
                setOpenDrawerProduct(false)}>                
                    <ServiceProductListForm onSubmit={addNewServiceProductListHandle} toggleModal={toggleModalProduct} />               
            </Dialog>
        </div>
    )
}
