import { api } from "../Api";
import { IAddPosService } from "modules/salesPos/domain/interface/IAddPosService";
import { IPostAddPosRequest } from "modules/salesPos/domain/dto/IPostAddPosRequest";
import { IDeletePosRequest } from "modules/salesPos/domain/dto/IDeletePosRequest";
import { plansUrl } from "Enviroment";

const baseUrl = plansUrl;

const AddPosApi = (): IAddPosService => {
  const postAddCieloPos = async (request: IPostAddPosRequest): Promise<void> => {
    const respomse = await api.post<void>(`${baseUrl}/subscriptions/saas/change-plan/add-external-pos`, {
      OwnerId: request.localId,
      Imei: request.imei,
      AcquirerType: request.acquirerType
    });
    return respomse.data;
  }

  const postAddPos = async (request: IPostAddPosRequest): Promise<void> => {
    const respomse = await api.post<void>(`/UsuarioMeepPOS/LinkToLocalByImei`, {
      OwnerId: request.localId,
      Imei: request.imei,
      AcquirerType: request.acquirerType
    });
    return respomse.data;
  }

  const deletePos = async (request: IDeletePosRequest): Promise<void> => {
    const respomse = await api.post<void>(`/UsuarioMeepPOS/UnlinkFromLocal`, {
        OwnerId: request.localId,
        DeviceId: request.deviceId
      },
      { params: { disableError: true } }
    );
    return respomse.data;
  }

  return { postAddCieloPos, postAddPos, deletePos };
}

export default AddPosApi;
