import { useCallback, useState } from 'react'
import { IAntecipateFormErrors, IAtecipateFormItem } from './interfaces/IAntecipateForm'
import { validate } from './validade/AntecipateFormValidation';

export const UseAnticipateForm = (onSubmit: (values: IAtecipateFormItem) => void, defaultValues?: IAtecipateFormItem) => {

    const [values, setValues] = useState(defaultValues ?? {
        conta: '',
        valor: undefined,
        senha: '',
        valorReceber: 0
    })

    const [errors, setErrors] = useState<IAntecipateFormErrors>({});

    const onChangeHandle = useCallback((name: string, value: string | number) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback(() => {
        if (validate(values, setErrors)) {
            onSubmit(values);
        }
    }, [onSubmit, values]);

    return (
        {
            values,
            errors,
            onChangeHandle,
            onSubmitHandle,
        }
    )
}