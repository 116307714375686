import React, { useCallback, useMemo, useState } from "react";
import styles from "./PosModal.module.scss";
import { Close, InfoOutlined, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Slide } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} style={{ backgroundColor: "transparent" }} />;
});

export interface IPosModalProps {
    open: boolean;
    onClose: () => void;
}

const PosModal = ({ open, onClose }: IPosModalProps) => {
    const [step, setStep] = useState(1);

    const contentByStep = useMemo(() => {
        switch (step) {
          case 1:
            return (
                <>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/saas/pos-modal-step1.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>1.</p>
                            <p>Acesse a loja de aplicativos da sua maquininha.</p>
                        </div>
                    </div>
                </>
            );
          case 2:
            return (
                <>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/saas/pos-modal-step2.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>2.</p>
                            <p>Busque por <b>‘Mee’</b> na barra de pesquisa.</p>
                        </div>
                    </div>
                </>
            );
          case 3:
            return (
                <>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/saas/pos-modal-step3.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>3.</p>
                            <p>Abra o App da Meep e clique no ícone <InfoOutlined  fontSize="small" style={{ margin: "0 3px" }}/> para exibir o IMEI e número de série.</p>
                        </div>
                    </div>
                </>
            );
          case 4:
            return (
                <>
                    <div className={styles.image}>
                        <img src="/assets/icon/saas/pos-modal-bg.svg" alt="" className={styles.bg}/>
                        <img src="/assets/icon/saas/pos-modal-step4.png" alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h1>Como funciona?</h1>
                        <div>
                            <p>4.</p>
                            <p>Insira esses dados no Portal Mee para criar sua máquina.</p>
                        </div>
                        <div>
                            <p style={{ margin: 0 }}>✅</p>
                            <p>Depois disso, ela vai estar pronta para lançar pedidos, efetuar pagamentos e muito mais!</p>
                        </div>
                    </div>
                </>
            );
          default:
            return <div />
        }
    }, [step]);

    const handleCloseModal = useCallback(() => {
        onClose();
        setStep(1);
    }, [onClose]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseModal}
            PaperProps={{
                style: { backgroundColor: 'transparent', boxShadow: 'none' },
            }}
        >
            <div id={styles.PosModal}>
                <img src="/assets/icon/saas/pos-modal-bg-2.svg" alt="" className={styles.modalBgBottom}/>
                <header>
                    <IconButton onClick={handleCloseModal}>
                        <Close />
                    </IconButton>
                </header>
                <DialogContent className={styles.modalContent}>
                    <div className={styles.contentInfo}>
                        {step !== 1 ? <IconButton onClick={() => setStep(step - 1)}><KeyboardArrowLeftOutlined /></IconButton> : <div />}
                        {contentByStep}
                        {step !== 4 ? <IconButton onClick={() => setStep(step + 1)}><KeyboardArrowRightOutlined /></IconButton> : <div />}
                    </div>
                    <div className={styles.contentStep}>
                        <div className={`${styles.step} ${step === 1 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 2 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 3 && styles.currentStep}`}/>
                        <div className={`${styles.step} ${step === 4 && styles.currentStep}`}/>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
};
export default PosModal;
