import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import styles from './LinkCardFilter.module.scss'
import { TextField } from '@material-ui/core'
import Button from 'components/ui/Button/Button';
import { ILinkCardsFilter } from './interfaces/ILinkCardsFilter';

export interface ILinkCardsFilterProps {
  onSubmit: (filter: ILinkCardsFilter) => void;
}
export const LinkCardFilter: React.FC<ILinkCardsFilterProps> = ({ onSubmit }) => {

  const [values, setValues] = useState<ILinkCardsFilter>();

  const changeHandleTag = useCallback((tag: string) => {
    setValues((prev) => ({ ...prev, tag: tag }));
  }, []);

  const changeHandleCreatedBy = useCallback((createdBy: string) => {
    setValues((prev) => ({ ...prev, createdBy: createdBy }));
  }, []);

  const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  }, []);


  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const createdAt = new Date(values?.createdAt ?? '');
      values && onSubmit({...values,
        createdAt: values.createdAt ? new Date(createdAt.getFullYear(), createdAt.getMonth(), createdAt.getDate() + 1, 0, 0, 0, 0).toISOString() : undefined,
      });

    },
    [onSubmit, values]
  ); 
  
  return (
    <div id={styles.LinkCardFilter}>
      <div id={styles.HouseFlowFilter}>
        <form onSubmit={submitHandle}>

          <div className={styles.containerFilter}>
            <div className={styles.content}>
              <div className={styles.itemInfo}>
                <label>Tag</label>
                <TextField fullWidth inputProps={{ maxLength: 250 }} size='small' placeholder='Insira a tag' variant='outlined' name={'name'} onChange={(ev) => changeHandleTag(ev.target.value)} />
              </div>
              <div className={styles.itemInfo}>
                <label>Vinculado por</label>
                <TextField fullWidth inputProps={{ maxLength: 14 }} size='small' placeholder='Nome do usuário' variant='outlined' name={'name'} onChange={(ev) => changeHandleCreatedBy(ev.target.value)} />
              </div>             
              <div className={styles.item}>
                <label>Vinculado em</label>
                <TextField size='small' type="date" fullWidth variant='outlined' name='createdAt' value={values?.createdAt} onChange={changeHandleDate} />
              </div>
            </div>
            <div className={styles.footer}>
              <Button variant={'contained'} className={styles.button} fullWidth type='submit' >Buscar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
