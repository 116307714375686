import { IPeopleListResponse } from 'modules/events/domain/dto/IEventsResponse';
import { IEventsGuestsApi } from '../../interfaces/IGuestsService';

const GetGuestsOnListUseCase = async (
  guestsService: IEventsGuestsApi,
  guestListId: string,
  localId: string,
): Promise<IPeopleListResponse> => {
  return await guestsService.getPeopleOnList(guestListId, localId);
};

export default GetGuestsOnListUseCase;
