import React, { FC, useCallback, useState } from 'react';
import { ICloseEvent, IPendingCard, IPendingItems, } from '../../../../ICloseEvent';
import styles from './SettlePending.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'components/ui/Button/Button';
import { CardSettlePending } from '../cardSettlePending/CardSettlePending';
import { IClosePedingFormErros } from '../../UseSystemTypeResume';

interface ISettlePendingProps {
  handleCloseModal: () => void;
  closePending: () => void;
  pendingItems: IPendingItems[];
  onChangePedingCard: (id: string, text: string) => void;
  errors: IClosePedingFormErros[];
}

export const SettlePending: FC<ISettlePendingProps> = ({ handleCloseModal, closePending, pendingItems, onChangePedingCard, errors }) => {

  if (pendingItems.length === 0) {
    return null
  }
  return (
    <div id={styles.SettlePending}>
      <h2>
        Essas são as pendências da sua operação durante o evento. É importante
        regularizá-las antes de realizar o fechamento.
      </h2>
      <p>Cartões que ainda estão abertos:</p>

      {pendingItems ? (
        <div className={styles.pendingList}>
          <div className={styles.header}>
            <h3>Número</h3>
            <h3>Valor</h3>
            <h3>Abertura</h3>
          </div>
          <div className={styles.container}>
            {pendingItems.map((item, index) => (              
              <CardSettlePending key={index} pendingItems={item} errors={errors} onChangePedingCard={onChangePedingCard} />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.progress}>
          <CircularProgress color="error" />
        </div>
      )}

      <footer>
        <p>Deseja fechar todos os cartões listados?</p>
        <p>
          <b>Caso feche, o cartão será desativado e o valor removido.</b>
        </p>
      </footer>

      <div>
        <Button fullWidth={false} variant="outlined" onClick={handleCloseModal}>Voltar</Button>
        <Button fullWidth={false} onClick={closePending}>Fechar cartões</Button>
      </div>
    </div>
  );
};
