import { Icon } from '@material-ui/core';
import styles from './PublicGuestListFail.module.scss';

interface PublicGuestListFailProps {
    onClose: () => void;
    isMigrate?: boolean;
}

export const PublicGuestListFail = ({ onClose, isMigrate }: PublicGuestListFailProps) => {
    return (
        <div className={styles.container}>
            <header>
                <button onClick={onClose} className={styles.closeButton}>
                    <Icon>close</Icon>
                </button>
            </header>

            <div className={styles.content}>
                <Icon>info_outline</Icon>
                <span className={styles.text}>Não foi possível inserir os<br />convidados na lista.</span>

                <p>
                    {
                        isMigrate ?
                            'O(s) convidado(s) já foi inserido em uma lista de convidado.' :
                            'A quantidade de CPF’s é maior do que a permitida. Remova para continuar.'
                    }
                </p>
            </div>
        </div>
    )
}