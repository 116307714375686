
import Layout from 'components/layout/presentation/Layout'
import AddOrUpdateProductPage from 'modules/catalog/presentation/pages/AddOrUpdateProductPage'
import React from 'react'

export const AddProduct = () => {
    return (
        <Layout>
            <AddOrUpdateProductPage></AddOrUpdateProductPage>
        </Layout>
    )
}
