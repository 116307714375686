import { Button, Icon, IconButton, Switch } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { IDeviceProfileItem } from "./interfaces/IProfileItem";
import styles from "./ProfileDeviceList.module.scss";
import UseDimension from "components/dimension/UseDimension";

interface ProfileItemProps {
  profileItem: IDeviceProfileItem;
  onClickCopyItem?: (IProfileItem: IDeviceProfileItem) => void;
  onClickDelete?: (IProfileItem: IDeviceProfileItem) => void;
  onClickChangeStatus?: (
    profileItem: IDeviceProfileItem,
    enabled: boolean
  ) => void;
  onClickItem: (profileItem: IDeviceProfileItem) => void;
  nivelEvento?: boolean;
  perfilVinculo?: number;
}

const ProfileDeviceItem: FC<ProfileItemProps> = ({
  profileItem,
  onClickCopyItem,
  onClickDelete,
  onClickChangeStatus,
  onClickItem,
  nivelEvento,
  perfilVinculo,
}) => {
  const hasSubItems = useMemo(
    () => (!nivelEvento ||  (nivelEvento && perfilVinculo === 1)) && profileItem.operationsMethods.length && profileItem.catalogs.length,
    [profileItem]
  );
  const [enabled, setEnabled] = useState(profileItem.enabled);

  const { dimensions } = UseDimension();

  const operationLIst = (!nivelEvento ||  (nivelEvento && perfilVinculo === 1)) && profileItem.operationsMethods.filter((opList) => {
    return opList.enabled;
  });

  const paymentLIst = (!nivelEvento ||  (nivelEvento && perfilVinculo === 1)) && profileItem.paymentMethods.filter((payList) => {
    return payList.enabled;
  });

  return (
    <div id={styles.profileItem}>
      <div
        className={`${styles.header} ${!hasSubItems ? styles.alignCenter : ""}`}
      >
        <div>
          <b>{profileItem.name}</b>
        </div>
        <div className={styles.actions}>
          <div>
            {dimensions.width > 900 && !nivelEvento && (
              <span className={styles.textSwitch}>
                {enabled ? "Habilitado" : "Desabilitado"}
              </span>
            )}
            {!nivelEvento && onClickChangeStatus && (
              <Switch
                checked={enabled}
                onChange={(_, checked) => {
                  setEnabled(!enabled);
                  onClickChangeStatus(profileItem, checked);
                }}
              />
            )}
            {!nivelEvento && onClickCopyItem && (
              <IconButton onClick={() => onClickCopyItem(profileItem)}>
                <Icon className={styles.icon}>content_copy</Icon>
              </IconButton>
            )}
            {onClickDelete &&
              ((nivelEvento && perfilVinculo === 2) || !nivelEvento) && (
                <IconButton onClick={() => onClickDelete(profileItem)}>
                  <Icon className={styles.icon}>delete_outline</Icon>
                </IconButton>
              )}
          </div>
          {dimensions.width > 900 && (
            <div>
              <Button
                variant={"outlined"}
                onClick={() => onClickItem(profileItem)}
                className={styles.detailsButton}
              >
                {nivelEvento ? "Equipamentos" : "Ver detalhes"}
              </Button>
            </div>
          )}
        </div>
      </div>
      {(!nivelEvento ||  (nivelEvento && perfilVinculo === 1)) &&  (
        <div className={styles.container}>
          {operationLIst && operationLIst.length > 0 && (
            <div className={styles.row}>
              <div className={styles.titutloItens}>Operação</div>
              <div>{operationLIst.map((item) => `${item.name}, `)}</div>
            </div>
          )}
          {!!profileItem.catalogs.length && (
            <div className={styles.row}>
              <div>Cardápios</div>
              <div>{profileItem.catalogs.map((item) => `${item.name},`)}</div>
            </div>
          )}
          {paymentLIst && paymentLIst.length > 0 && (
            <div className={styles.row}>
              <div>Formas de pagamento</div>
              <div>{paymentLIst.map((item) => `${item.name}, `)}</div>
            </div>
          )}
        </div>
      )}
      {dimensions.width <= 900 && (
        <div>
          <Button
            variant={"outlined"}
            onClick={() => onClickItem(profileItem)}
            className={styles.detailsButton}
          >
            {nivelEvento ? "Equipamentos" : "Ver detalhes"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProfileDeviceItem;
