import { ISankhyaConfigurationRequest } from "modules/config/integrationsConfig/domain/dto/SankhyaConfiguration/ISankhyaConfigurationRequest";

export interface ISankhyaFormErrors {
  SankhyaCompanyId?: string;
  Protocol?: string;
  Host?: string;
  Port?: string;
  DefaultSeller?: string;
  SankhyaUser?: string;
  SankhyaPass?: string;
  ValidateTicketTop?: string;
  SankhyaCodNat?: string;
  SankhyaTypeSaleCode?: string;
}

export const SankhyaValidation = (formData: ISankhyaConfigurationRequest, setErrors: React.Dispatch<React.SetStateAction<ISankhyaFormErrors>>) => {
  let _errors: ISankhyaFormErrors = {};
  let hasError = false;

  if (!formData.SankhyaCompanyId) {
    _errors = { ..._errors, SankhyaCompanyId: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaUser) {
    _errors = { ..._errors, SankhyaUser: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaPass) {
    _errors = { ..._errors, SankhyaPass: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaCodNat) {
    _errors = { ..._errors, SankhyaCodNat: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.SankhyaTypeSaleCode) {
    _errors = { ..._errors, SankhyaTypeSaleCode: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.ValidateTicketTop) {
    _errors = { ..._errors, ValidateTicketTop: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.DefaultSeller) {
    _errors = { ..._errors, DefaultSeller: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.Protocol) {
    _errors = { ..._errors, Protocol: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.Host) {
    _errors = { ..._errors, Host: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.Port) {
    _errors = { ..._errors, Port: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);

  return !hasError;
};
