import { IconButton } from "@material-ui/core";
import Switch from "components/ui/switch/Switch";
import { FC, useState } from "react";
import styles from "./TicketCard.module.scss";
import Button from "components/ui/Button/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { moneyMaskNumber } from "services/utils/Money";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import warningImg from "res/assets/images/warning.png";
import { ITicketForm } from "../ticketForm/ITicketForm";

interface ITicketCardProps {
  ticket: ITicketForm;
  openFormModal: (ticket: ITicketForm) => void;
  onToggleTicketStatus: (
    ticketId: string,
    checked: boolean
  ) => Promise<boolean>;
  onDeleteTicket: (ticketId: string) => Promise<void>;
}

export const TicketCard: FC<ITicketCardProps> = ({
  ticket,
  openFormModal,
  onToggleTicketStatus,
  onDeleteTicket,
}) => {
  const [isActive, setIsActive] = useState(ticket.isActive);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const handleToggleStatus = async (checked: boolean) => {
    checked = await onToggleTicketStatus(ticket.id, checked);
    setIsActive(checked);
  };
  const handleClick = () => {
    onDeleteTicket(ticket.id);
    setDeleteModal(false);
  };

  return (
    <div id={styles.TicketCard}>
      <p>{ticket.name}</p>
      <p>{moneyMaskNumber(ticket.price)}</p>
      <Switch
        onChange={(_, checked) => handleToggleStatus(checked)}
        checked={isActive}
        className={styles.switchContainer}
      />
      <div>
        <IconButton
          onClick={() => setDeleteModal(true)}
          className={styles.deleteButton}
        >
          <DeleteOutlineIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={() => openFormModal(ticket)}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </div>
      <RightDrawer
        title={`Deseja realmente excluir o ingresso ${ticket.name} ?`}
        open={showDeleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <div className={styles.deleteModal}>
          <div>
            <img src={warningImg} alt="warning_image" />
            <p>Lembre-se: essa ação não poderá ser desfeita!</p>
          </div>
          <div>
            <Button
              fullWidth={false}
              onClick={() => setDeleteModal(false)}
              color="primary"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              fullWidth={false}
              onClick={handleClick}
              color="primary"
              variant="contained"
            >
              Excluir
            </Button>
          </div>
        </div>
      </RightDrawer>
    </div>
  );
};
