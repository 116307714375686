import styles from "./TransferForm.module.scss";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { UseTransferForm } from "./UseTransferForm";
import { ITransferFormItem } from "./interfaces/ITransferForm";
import { Autocomplete, TextField } from "@mui/material";
import Sidesheet from "components/sidesheet/Sidesheet";
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { formatCpfCnpj, formatarValor, onlyNumber, optionsBancos } from "../../pages/utils";
import { BankDetailDepositResponse } from "modules/contaDigital/models/dtos/BankDetailDepositResponse";
import { Info } from "@mui/icons-material";
import { useEffect } from "react";

interface TransferFormProps {
  onSubmit: (values: ITransferFormItem) => void;
  balance?: DigitalAccountBalanceResponse;
  open: null | string;
  setOpen: React.Dispatch<React.SetStateAction<string | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  bankDetail: BankDetailDepositResponse[] | undefined;
  ConfirmationSms: () => void;
  taxaBoletoTerceiro: string | undefined;
  setRemoveAccount: React.Dispatch<React.SetStateAction<string | null>>;
  setDadosBancariosId: React.Dispatch<React.SetStateAction<string | undefined>>;
  dadosBancariosId: string | undefined;
}

const TransferForm = ({
  onSubmit,
  open,
  setOpen,
  step,
  setStep,
  balance,
  bankDetail,
  ConfirmationSms,
  taxaBoletoTerceiro,
  setRemoveAccount,
  setDadosBancariosId,
  dadosBancariosId,
}: TransferFormProps) => {
  const { errors, values, onChangeHandle, onSubmitHandle } = UseTransferForm(
    onSubmit,
    step
  );

  const TedTerceiro = parseFloat(
    //@ts-ignore
    parseFloat(3.67 + values.valor * (taxaBoletoTerceiro / 100)).toFixed(2)
  );

  const SaldoRestante = balance
    ? balance?.balanceResult.balanceAvailable -
      TedTerceiro -
      Number(values.valor)
    : 0;

  useEffect(() => {
    onChangeHandle("TaxaTED", TedTerceiro);
    onChangeHandle("SaldoRestante", SaldoRestante);
  }, [balance, values.valor]);

  const handleSubmit = () => {
    if (SaldoRestante >= 0) {
      onSubmitHandle();
    }
  };

  return (
    <>
      <Sidesheet
        open={open}
        onClose={() => {
          setOpen(null);
          setStep(1);
          onChangeHandle("conta", "");
          onChangeHandle("banco", "");
          onChangeHandle("agencia", "");
          onChangeHandle("digitoAg", "");
          onChangeHandle("contaCorrente", "");
          onChangeHandle("digitoConta", "");
          onChangeHandle("documento", "");
          onChangeHandle("nome", "");
          onChangeHandle("valor", "");
        }}
        title={
          <h2>
            Nova <b>transferência</b>
          </h2>
        }
        content={
          <>
            {step === 1 && (
              <div id={styles.TransferForm}>
                <div>
                  <p className={styles.infoGeral}>
                    <Info />O pagamento de boletos é realizado em dois dias
                    úteis.
                  </p>
                  <div className={styles.saldo}>
                    <p>Saldo Disponível</p>
                    <p className={styles.value_available}>
                      {balance &&
                        formatarValor(balance?.balanceResult.balanceAvailable)}
                    </p>
                  </div>
                  <div className={styles.container}>
                    <div className={styles.selectAccount}>
                      <FormItemContainer label={"Selecione a conta"} required>
                        <Autocomplete
                          className={styles.select}
                          options={bankDetail || []}
                          getOptionLabel={(option) => option.description}
                          onChange={(event, value) => {
                            onChangeHandle(
                              "conta",
                              value ? value.description : ""
                            );
                            setDadosBancariosId(value?.id);
                            onChangeHandle(
                              "banco",
                              value
                                ? optionsBancos.find(
                                    (item) => item.value === value.bank
                                  )
                                : ""
                            );
                            onChangeHandle(
                              "agencia",
                              value ? value.branchNumber : ""
                            );
                            onChangeHandle(
                              "digitoAg",
                              value ? value.branchDigit : ""
                            );
                            onChangeHandle(
                              "contaCorrente",
                              value ? value.accountNumber : ""
                            );
                            onChangeHandle(
                              "digitoConta",
                              value ? value.accountDigit : ""
                            );
                            onChangeHandle(
                              "documento",
                              value ? value.document : ""
                            );
                            onChangeHandle("codigo", "");
                            onChangeHandle("nome", value ? value.name : "");
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant={"outlined"}
                              {...params}
                              error={!!errors.conta}
                              helperText={errors.conta}
                            ></TextField>
                          )}
                        />
                      </FormItemContainer>
                      {dadosBancariosId && values.conta && (
                        <p
                          className={styles.remove}
                          onClick={() => setRemoveAccount("true")}
                        >
                          Remover
                        </p>
                      )}
                    </div>
                    <FormItemContainer label={"Banco"} required>
                      <Autocomplete
                        className={styles.select}
                        options={optionsBancos}
                        getOptionLabel={(option) => option.text}
                        value={
                          typeof values.banco !== "string" ? values.banco : null
                        }
                        onChange={(event, value) =>
                          onChangeHandle("banco", value ? value : "")
                        }
                        renderInput={(params) => (
                          <TextField
                            variant={"outlined"}
                            {...params}
                            error={!!errors.banco}
                            helperText={errors.banco}
                          ></TextField>
                        )}
                      />
                    </FormItemContainer>
                    <div className={styles.bank}>
                      <div className={styles.account}>
                        <FormItemContainer label={"Agência"} required>
                          <Input
                            name={"agencia"}
                            value={onlyNumber(values.agencia)}
                            onChange={(ev) => {
                              onChangeHandle(ev.target.name, ev.target.value);
                            }}
                            required
                            variant="outlined"
                            error={!!errors.agencia}
                            helperText={errors.agencia}
                            InputProps={{
                              inputProps: {
                                maxLength: 4,
                              },
                            }}
                          />
                        </FormItemContainer>
                      </div>
                      {typeof values.banco !== "string" &&
                        values.banco.value === "001" && (
                          <div className={styles.input_digito}>
                            <FormItemContainer label={"Digito"} required>
                              <Input
                                name={"digitoAg"}
                                value={onlyNumber(values.digitoAg)}
                                onChange={(ev) => {
                                  onChangeHandle(
                                    ev.target.name,
                                    ev.target.value
                                  );
                                }}
                                required
                                variant="outlined"
                                error={!!errors.digitoAg}
                                helperText={errors.digitoAg}
                                InputProps={{
                                  inputProps: {
                                    maxLength: 2,
                                  },
                                }}
                              />
                            </FormItemContainer>
                          </div>
                        )}
                    </div>
                    <div className={styles.bank}>
                      <div className={styles.account}>
                        <FormItemContainer label={"Conta Corrente"} required>
                          <Input
                            name={"contaCorrente"}
                            value={onlyNumber(values.contaCorrente)}
                            onChange={(ev) => {
                              onChangeHandle(ev.target.name, ev.target.value);
                            }}
                            required
                            variant="outlined"
                            error={!!errors.contaCorrente}
                            helperText={errors.contaCorrente}
                            InputProps={{
                              inputProps: {
                                maxLength: 10,
                              },
                            }}
                          />
                        </FormItemContainer>
                      </div>
                      <div className={styles.input_digito}>
                        <FormItemContainer label={"Digito"} required>
                          <Input
                            name={"digitoConta"}
                            value={onlyNumber(values.digitoConta)}
                            onChange={(ev) => {
                              onChangeHandle(ev.target.name, ev.target.value);
                            }}
                            required
                            variant="outlined"
                            error={!!errors.digitoConta}
                            helperText={errors.digitoConta}
                            InputProps={{
                              inputProps: {
                                maxLength: 2,
                              },
                            }}
                          />
                        </FormItemContainer>
                      </div>
                    </div>
                    <FormItemContainer
                      label={"CPF/CNPJ da conta titular"}
                      required
                    >
                      <Input
                        name={"documento"}
                        value={formatCpfCnpj(values.documento)}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.documento}
                        helperText={errors.documento}
                      />
                    </FormItemContainer>
                    <FormItemContainer label={"Nome do titular"} required>
                      <Input
                        name={"nome"}
                        value={values.nome}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.nome}
                        helperText={errors.nome}
                        InputProps={{
                          inputProps: {
                            maxLength: 100,
                          },
                        }}
                      />
                    </FormItemContainer>
                    <FormItemContainer label={"Valor"} required>
                      <Input
                        name={"valor"}
                        value={values.valor}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.valor}
                        helperText={errors.valor}
                        type="number"
                        placeholder="R$ 0,00"
                      />
                    </FormItemContainer>
                  </div>
                  <p className={styles.infos}>
                    Custo TED: (R$ 3,67 + {taxaBoletoTerceiro}%) ={" "}
                    <b>{formatarValor(TedTerceiro)}</b>
                  </p>
                  <p className={styles.infos}>
                    Saldo após saque:{" "}
                    <b>{SaldoRestante && formatarValor(SaldoRestante)}</b>
                  </p>
                </div>
              </div>
            )}
            {step === 2 && (
              <SidesheetFeedback
                text={
                  "Atenção! Confira todos os dados do pagamento antes de confirmar."
                }
                helperText={
                  <div className={styles.boxConfirm}>
                    <p>
                      Informamos que é de sua total responsabilidade as
                      informações aqui fornecidas. A Meep não se responsabiliza
                      por qualquer pagamento realizado com os dados informados
                      incorretos e que se enquadram em fraudes.
                    </p>
                    <p className={styles.confirm}>
                      Confirma o saque no valor de{" "}
                      {formatarValor(Number(values.valor))} para {values.nome}?
                    </p>
                  </div>
                }
              />
            )}
            {step === 3 && (
              <div id={styles.TransferForm}>
                <div>
                  <p className={styles.infoGeral}>
                    <Info />
                    Digite o código que foi enviado para seu telefone
                  </p>

                  <FormItemContainer label={"Código"} required>
                    <Input
                      name={"codigo"}
                      value={values.codigo}
                      onChange={(ev) => {
                        onChangeHandle(ev.target.name, ev.target.value);
                      }}
                      required
                      variant="outlined"
                      error={!!errors.codigo}
                      helperText={errors.codigo}
                    />
                  </FormItemContainer>
                  <p
                    className={styles.reenviar}
                    onClick={() => ConfirmationSms()}
                  >
                    Reenviar o código
                  </p>
                </div>
              </div>
            )}
          </>
        }
        currentStep={step}
        totalSteps={4}
        notTotalHeight
        continueButton="Transferir"
        handleContinueButton={handleSubmit}
        cancelButton
        feedback={
          <SidesheetFeedback
            text={
              "Transferência realizada com sucesso! Comprovante disponível no relatório de saques."
            }
            success
          />
        }
      />
    </>
  );
};
export default TransferForm;
