
import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { ICampaignFormValue } from "modules/crm/presentation/intefaces/ICampaign"

const SendCampaignUseCase = (service: ICrmService, campaignId: string) => {
    const response = service.sendCampaign(campaignId);
    return (response)
}
export default SendCampaignUseCase  


