import styles from "./PayForm.module.scss";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { Info } from "@mui/icons-material";
import { UsePayForm } from "./UsePayForm";
import { IPayFormItem } from "./interfaces/IPayForm";
import { DigitalAccountResponse } from "modules/contaDigital/models/dtos/DigitalAccountResponse";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import Sidesheet from "components/sidesheet/Sidesheet";
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";
import UseDimension from "components/dimension/UseDimension";
import {
  ValidarVencimentoBoleto,
  isValidBoleto,
} from "./validade/PayFormValidation";
import { useEffect, useState } from "react";
import { formatCpfCnpj } from "../../pages/utils";

interface PayFormProps {
  onSubmit: (values: IPayFormItem) => void;
  account: DigitalAccountResponse | undefined;
  open: null | string;
  balance?: DigitalAccountBalanceResponse;
  setOpen: React.Dispatch<React.SetStateAction<string | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  ConfirmationSms: () => void;
  taxaBoletoTerceiro?: string
}

const PayForm = ({
  open,
  setOpen,
  setStep,
  step,
  onSubmit,
  balance,
  ConfirmationSms,
  taxaBoletoTerceiro
}: PayFormProps) => {
  const { errors, values, onChangeHandle, onSubmitHandle } =
    UsePayForm(onSubmit, step);
  const { isMobile } = UseDimension();
  const [valid, setValid] = useState(false);
  const [saldoAposSaque, setSaldoAposSaque] = useState(0);
  const [custoBoleto, setCustoBoleto] = useState(0)

  const calculaValorBoleto = (numeroBoleto: string) => {
    const digitableLine = numeroBoleto.replace(/\D/g, '');

    if (digitableLine.length === 47) {
      const initialPosition = 37;
      const valueLength = initialPosition + 10;

      let value = digitableLine.substring(initialPosition, valueLength);

      while (value.length > 0 && value.substring(0, 1) === '0') {
        value = value.substring(1);
      }

      if (value.length > 2) {
        const decimalPosition = value.length - 2;
        value = value.substring(0, decimalPosition) + '.' + value.substring(decimalPosition);
      }
      onChangeHandle('valor', value)
    }

  }

  const calcularSaldo = () => {
    let valorAtualizado = Math.max(values.valor, 0);

    let valorTotal = valorAtualizado + Math.max(values.multa, 0) - Math.max(values.desconto, 0);

    if (balance && !isValidBoleto(values.CodigoBarras)) {
      setSaldoAposSaque(balance?.balanceResult.balanceAvailable);
      setCustoBoleto(0)
    }

    if (valorTotal && balance && valid && isValidBoleto(values.CodigoBarras)) {  
        //@ts-ignore
        onChangeHandle('custoSaque', parseFloat(parseFloat(3.67 + (valorTotal * (taxaBoletoTerceiro / 100))).toFixed(2)))
        setSaldoAposSaque(balance?.balanceResult.balanceAvailable - (valorTotal + values.custoSaque));
        setCustoBoleto(3.67 + (0.0099 * values.valor))
      }
    onChangeHandle('SaldoRestante', saldoAposSaque)
  }

  useEffect(() => {
    calcularSaldo()
  }, [balance, values.valor, valid, values.CodigoBarras])

  function formatarValor(valor: number) {
    return valor?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  const handleSubmit = () => {
    isValidBoleto(values.CodigoBarras);
    setValid(true);

    if (isValidBoleto(values.CodigoBarras) && ValidarVencimentoBoleto(values.CodigoBarras) && saldoAposSaque >= 0) {
      onSubmitHandle()
    }
  }

  return (
      <Sidesheet
        open={open}
        onClose={() => {
          setOpen(null);
          setStep(1);
          onChangeHandle("CodigoBarras", "");
          onChangeHandle("codigo", "");
          onChangeHandle("valor", "");
          onChangeHandle("documento", "");
          setValid(false)
        }}
        title={
          <h2>
            Novo <b>pagamento</b>
          </h2>
        }
        content={
          <>
            {step === 1 &&
              <div id={styles.PayForm}>
                <div>
                  {!isMobile && (
                    <p className={styles.infoGeral}>
                      <Info />Transferência será creditada em dois dias úteis.
                    </p>
                  )}
                  <div className={styles.saldo}>
                    <p>Saldo disponível</p>
                    <p className={styles.value_available}>
                      {balance &&
                        formatarValor(balance?.balanceResult.balanceAvailable)}
                    </p>
                  </div>

                  <FormItemContainer
                    label={"CPF ou CNPJ do beneficiário do boleto"}
                    required
                  >
                    <Input
                      name={"documento"}
                      value={values.documento}
                      onChange={(ev) => {                        
                        onChangeHandle(ev.target.name, formatCpfCnpj(ev.target.value));
                      }}
                      required
                      variant="outlined"
                      error={!!errors.documento}
                      helperText={errors.documento}
                    />
                  </FormItemContainer>

                  <FormItemContainer label={"Código do boleto"} required>
                    <Input
                      name={"CodigoBarras"}
                      value={values.CodigoBarras}
                      onChange={(ev) => {
                        onChangeHandle(ev.target.name, ev.target.value);
                        calculaValorBoleto(ev.target.value)
                      }}
                      required
                      variant="outlined"
                      error={!!errors.CodigoBarras}
                      helperText={errors.CodigoBarras}
                    />
                    {valid && isValidBoleto(values.CodigoBarras) && (
                      <p className={styles.isValid}>Boleto válido</p>
                    )}
                    {valid && !ValidarVencimentoBoleto(values.CodigoBarras) && isValidBoleto(values.CodigoBarras) && (
                      <p className={styles.isNotValid}>
                        Pagamento habilitado apenas para boletos com vencimento
                        superior a dois dias úteis
                      </p>
                    )}
                    {valid && !isValidBoleto(values.CodigoBarras) && (
                      <p className={styles.isNotValid}>
                        Boleto inválido ou modelo ainda não é aceito pelo sistema
                        MEEP
                      </p>
                    )}
                  </FormItemContainer>

                  <div className={styles.boxButton}>
                    <button
                      className={styles.filledButton}
                      onClick={() => {
                        isValidBoleto(values.CodigoBarras);
                        setValid(true);
                        calcularSaldo()
                      }}
                    >
                      Validar boleto
                    </button>
                  </div>

                  <div className={styles.boxValues}>
                    <FormItemContainer label={"Valor nominal"} required>
                      <Input
                        name={"valor"}
                        value={valid && isValidBoleto(values.CodigoBarras) ? formatarValor(Number(values.valor)): ''}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.valor}
                        helperText={errors.valor}
                        placeholder="R$ 0,00"
                        disabled={true}
                      />
                    </FormItemContainer>

                    <FormItemContainer label={"Valor da multa"} required>
                      <Input
                        name={"multa"}
                        value={Number(values.multa)}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.multa}
                        helperText={errors.multa}
                        placeholder="R$ 0,00"
                        disabled={!isValidBoleto(values.CodigoBarras) || !ValidarVencimentoBoleto(values.CodigoBarras) || saldoAposSaque < 0}
                      />
                    </FormItemContainer>

                    <FormItemContainer label={"Valor do desconto"} required>
                      <Input
                        name={"desconto"}
                        value={Number(values.desconto)}
                        onChange={(ev) => {
                          onChangeHandle(ev.target.name, ev.target.value);
                        }}
                        required
                        variant="outlined"
                        error={!!errors.desconto}
                        helperText={errors.desconto}
                        placeholder="R$ 0,00"
                        disabled={!isValidBoleto(values.CodigoBarras) || !ValidarVencimentoBoleto(values.CodigoBarras) || saldoAposSaque < 0}
                      />
                    </FormItemContainer>
                  </div>
                  <p className={styles.infos}>
                    Custo do boleto: <b>R$ 3,67 + {taxaBoletoTerceiro}% {custoBoleto > 0 && `= ${formatarValor(custoBoleto)}`}</b>
                  </p>
                  <p className={styles.infos}>
                    Saldo após pagamento:{" "}
                    <b>{formatarValor(saldoAposSaque)}</b>{" "}
                    {saldoAposSaque < 0 && <span className={styles.isNotValid}>(Saldo indisponível para pagamento)</span>}
                  </p>
                  <p className={styles.infoGeralMobile}>
                    O pagamento de boletos é realizado em dois dias úteis.
                  </p>
                  {isMobile && (
                    <p className={styles.infoGeralMobile}>
                      Valide se a data de vencimento do boleto está dentro do prazo
                      de pagamento.
                    </p>
                  )}
                </div>
              </div>
            }
            {step === 2 &&
              <div id={styles.PayForm}>
                <div>
                  <p className={styles.infoGeral}>
                    <Info />Digite o código que foi enviado para seu telefone
                  </p>

                  <FormItemContainer label={"Código"} required>
                    <Input
                      name={"codigo"}
                      value={values.codigo}
                      onChange={(ev) => {
                        onChangeHandle(ev.target.name, ev.target.value);
                      }}
                      required
                      variant="outlined"
                      error={!!errors.codigo}
                      helperText={errors.codigo}
                    />
                  </FormItemContainer>
                  <p className={styles.reenviar} onClick={() => ConfirmationSms()}>Reenviar o código</p>
                </div>
              </div>
            }
          </>
        }
        currentStep={step}
        totalSteps={3}
        continueButton="Pagar"
        handleContinueButton={handleSubmit}
        cancelButton
        feedback={
          <SidesheetFeedback
            text={"Pagamento realizado com sucesso!"}
            success
          />
        }
      />
  );
};
export default PayForm;
