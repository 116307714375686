import React, { FC } from 'react';
import Lottie from 'react-lottie';
import graph_loading from '../../../res/assets/animations/graph_loading.json';

const meepLoadingAnimation = {
  loop: true,
  autoplay: true,
  animationData: graph_loading,
};

interface IGraphLoadingProps {
  width?: number;
  speed?: number;
}

export const GraphLoading: FC<IGraphLoadingProps> = ({
  width = 200,
  speed = 1,
}) => (
  <Lottie
    width={width}
    speed={speed}
    options={meepLoadingAnimation}
    isStopped={false}
    isPaused={false}
  />
);
