import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, FilterButtonContainer, InputContainer, Row } from '../../../ui/form/FormContainers'
import { useForm } from 'react-hook-form'
import { Icon } from '@mui/material'
import { IInventorySupplyFilterValue } from './InventorySupplyFilterValue'
import styles from './InventorySupplyFilter.module.scss'
import AutocompleteInput, { IAutocompletePaginatedRequest, IAutocompletePaginatedListResponse } from '../../../autocomplete/AutocompleteInput'
import { ISupplyGroupListRequest, ISupplyGroupList, ISupplyGroupItem } from '../../../supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import { SelectAutocompleteItem } from '../../inventoryForm/InventoryForm'
import SupplyGroupInput from '../../../supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/ParentGroupInput'
import { set } from 'date-fns'
import Button from 'components/ui/Button/Button'

export interface IInventorySupplyFilterProps {
    defaultValue?: IInventorySupplyFilterValue
    onSubmit: (values: IInventorySupplyFilterValue) => void
    getSupply: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
    getSupplyGroupList: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    getSuplyStorageLocals: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse<SelectAutocompleteItem>>
}

const InventorySupplyFilter: FC<IInventorySupplyFilterProps> = ({ defaultValue, onSubmit,
    getSupply,
    getSupplyGroupList,
    getSuplyStorageLocals

}) => {
    const { register, handleSubmit, reset, watch, setValue } = useForm<IInventorySupplyFilterValue>({
        defaultValues: defaultValue ?? {
            supplyId: "",
            supplyGroupId: "",
            storageLocationId: "",
        },

    });


    const [supplyStorageLocals, setSupplyStorageLocals] = useState<SelectAutocompleteItem>()
    const [supplyGroup, setSupplyGroup] = useState<ISupplyGroupItem>()
    const [supply, setSupply] = useState<SelectAutocompleteItem>()

    // useEffect(() => {
    //     if (defaultValue) {
    //         reset(defaultValue);

    //     }
    // }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: IInventorySupplyFilterValue) => {
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            supplyId: "",
            supplyGroupId: "",
            storageLocationId: "",
        });
        onSubmit({
            supplyId: "",
            supplyGroupId: "",
            storageLocationId: "",
            page: 0,
            pageSize: 10
        })
        setSupply(undefined);
        setSupplyGroup(undefined);
        setSupplyStorageLocals(undefined);
    }, [onSubmit, reset])

    return (
        <Container style={{ marginTop: 16, marginBottom: 16 }}>
            <form
                id={styles.InventorySupplyFilter}
                onSubmit={handleSubmit(onSubmitHandle)}
            >
                <Row >
                    <InputContainer label="Grupo de insumo" >
                        <SupplyGroupInput
                            getSupplyGroupList={getSupplyGroupList}
                            value={supplyGroup}
                            inputText={supplyGroup?.name ?? ""}
                            onSelect={(item) => {
                                setValue('supplyGroupId', item?.id)
                                setSupplyGroup(item)
                            }}
                        // inputText={watch('supplyGroupName')}
                        />
                    </InputContainer>
                    <InputContainer label="Insumo" >
                        <AutocompleteInput
                            value={supply}
                            textInput={supply?.name ?? ""}
                            getList={getSupply}
                            getOptionLabel={(option) => option.name}
                            onSelect={(value) => {
                                setValue('supplyId', value?.id)
                                setSupply(value)
                            }}
                        />
                    </InputContainer>
                    <InputContainer label="Local de estoque" >
                        <AutocompleteInput
                            value={supplyStorageLocals}
                            textInput={supplyStorageLocals?.name ?? ""}
                            getList={getSuplyStorageLocals}
                            getOptionLabel={(option) => option.name}
                            onSelect={(value) => {
                                setValue('storageLocationId', value?.id)
                                setSupplyStorageLocals(value)
                            }}
                        />
                    </InputContainer>
                    <FilterButtonContainer minWidth={100} >
                        {isAnyFieldFilled &&
                            <Button
                                onClick={onClickResetFilter}
                                variant="text"
                                color="primary"
                                startIcon={<Icon>close</Icon>}>
                                Limpar filtro
                            </Button>}
                        <Button color='secondary' type="submit" style={{width: '76px', minWidth: '76px'}} >Buscar</Button>
                    </FilterButtonContainer>
                </Row>
            </form >
        </Container>
    )
}
export default InventorySupplyFilter