import { FC, useState } from "react";
import styles from "./ImportDrawer.module.scss";
import { ErrorView, ImportView } from "./components";
import { Icon } from "@material-ui/core";
import { Drawer } from "@mui/material";
import { ImportDrawerProps } from "./ImportDrawer.types";
import { SucessView } from "./components/SucessView/SucessView";

const ImportDrawer: FC<ImportDrawerProps> = ({ open, onClose, getData }) => {
  const [viewNavigation, setViewNavigation] = useState("initial");

  const finishImport = () => {
    onClose();
    setViewNavigation("initial");
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      style={{ zIndex: 9999 }}
    >
      <div id={styles.drawer}>
        <div className={styles.container}>
          <div className={styles.titleRow}>
            <h2 className={styles.title}>
              Importar <strong>produtos</strong>
            </h2>
            <Icon onClick={onClose} className={styles.pointer}>
              close
            </Icon>
          </div>
          {viewNavigation === "initial" && (
            <ImportView
              setNavigation={setViewNavigation}
              onClose={finishImport}
            />
          )}
          {viewNavigation === "error" && <ErrorView onClose={finishImport} />}
          {viewNavigation === "sucess" && (
            <SucessView
              onClose={() => {
                finishImport();
                getData();
              }}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export { ImportDrawer };
