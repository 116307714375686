import Skeleton from '@mui/lab/Skeleton';
import React, { FC } from 'react';
import styles from './EventCardSkeleton.module.scss';

interface IEventCardSkeletonProps {
};

export const EventCardSkeleton: FC<IEventCardSkeletonProps> = ({}) => {
  return (
    <div id={styles.EventCardSkeleton}>
      <div className={styles.first}>
          <div>
            <Skeleton variant="rectangular" />
          </div>
          <div>
            <Skeleton width={150} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
        <div className={styles.second}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
        <div className={styles.third}>
          <div>
            <Skeleton />
            <Skeleton />
          </div>
          <div>
            <Skeleton />
          </div>
        </div>
    </div>
  );
};