
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PermutationApi from "services/api/permutation/PermutationApi";
import { DeletePermutationAccountUseCase } from "modules/permutation/application/useCases/DeletePermutationAccountUseCase";

const UseDeletePermutationAccount = () => {

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>();
  const { currentLocal } = useLocal();
  const { toast } = useUi()
  const [successDelete, setSuccessDelete] = useState(false)


  const deletePermutation = useCallback(
    async (accountId: string) => {
      try {
        const servicePermutation = PermutationApi();

        setIsLoadingDelete(true);
        if (currentLocal) {
          await DeletePermutationAccountUseCase(servicePermutation,
            accountId,
            currentLocal.id,
          );
          toast("Permuta exclída com sucesso", "success")
          setSuccessDelete(true)
          return "ok"
        }
      } catch {
        setSuccessDelete(false)
      }

      finally {
        setIsLoadingDelete(false);
      }
    },
    [currentLocal, toast]
  );


  return {
    setSuccessDelete,
    successDelete,
    isLoadingDelete,
    deletePermutation,

  };
};

export default UseDeletePermutationAccount;
