import { ICrmService } from "modules/crm/models/interface/ICrmService";
import { ISavedFilter } from "modules/crm/presentation/components/crmFilterForm/interfaces/ISavedFilter";

const GetSavedFilters = async (
  service: ICrmService,
  OwnerId: string,
): Promise<ISavedFilter[]> => {
  const response = await service.getSavedFilter({ OwnerId });

  return response.data;
};

export default GetSavedFilters;
