import { ISort } from "interfaces/ISort"
import { ILinkCardsFilter } from "modules/linkCards/presentation/components/linkCardsFilter/interfaces/ILinkCardsFilter"
import ParseFilterToRequestUseCase from "./ParseFilterToRequestUseCase"
import { IGetLinkCardsListResponse } from "modules/linkCards/domain/dto/IGetLinkCardsListResponse"
import { ILinkCardsApiService } from "modules/linkCards/domain/interfaces/ILinkCardsApiService"



const GetPermutationUseCase = async (service: ILinkCardsApiService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: ILinkCardsFilter): Promise<IGetLinkCardsListResponse> => {
   
    const request =  ParseFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getLinkCards(request, sort)
    return (response)
}
export default GetPermutationUseCase