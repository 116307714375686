import Layout from "components/layout/presentation/Layout";
import EditProductPage from "modules/products/presentation/pages/edit/EditProductPage";
import React from "react";

export const EditProduct = () => {
  return (
    <Layout>
      <EditProductPage />
    </Layout>
  );
};
