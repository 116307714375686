import UseDimension from "components/dimension/UseDimension";
import styles from "./CashClosingTotalItem.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IPostCashClosingResponse } from "modules/cashClosing/domain/dto/IPostCashClosingResponse";
import { useMemo } from "react";

interface ICashClosingTotalItem {
  item: IPostCashClosingResponse;
  onExport?: string | null;
}

const CashClosingTotalItem = ({ item, onExport }: ICashClosingTotalItem) => {
  const { dimensions } = UseDimension();

  const data = useMemo(() => item.resultadoSessao ?? {}, [item.resultadoSessao]);

  return (
    <div id={styles.CashClosingTotalItem} className="div-to-capture">
      {dimensions.width > 650 || onExport === "pdf" ? (
        <div className={styles.totalItem}>
          <span>Total Geral:</span>
          <span>{moneyMaskNumber(data.CanceladoTotal)}</span>
          <span>{moneyMaskNumber(data.CashlessTotal)}</span>
          <span>{moneyMaskNumber(data.DebitoTotal)}</span>
          <span>{moneyMaskNumber(data.DebitoNTTotal)}</span>
          <span>{moneyMaskNumber(data.CreditoTotal)}</span>
          <span>{moneyMaskNumber(data.CreditoNTTotal)}</span>
          <span>{moneyMaskNumber(data.DinheiroTotal)}</span>
          <span>{moneyMaskNumber(data.DescontoTotal)}</span>
          <span>{moneyMaskNumber(data.VoucherTotal)}</span>
          <span>{moneyMaskNumber(data.OutrosTotal)}</span>
          <span>{moneyMaskNumber(data.EmAbertoTotal)}</span>
          <span>{moneyMaskNumber(data.SuprimentoTotal)}</span>
          <span>{moneyMaskNumber(data.SangriaTotal)}</span>
          <span>{moneyMaskNumber(data.TotalGeral)}</span>
        </div>
      ) : (
        <div className={styles.totalItemMobile}>
          <div className={styles.item}>
            <span>Total geral</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total cancelado:</span>
            <span>{moneyMaskNumber(data.CanceladoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total cashless:</span>
            <span>{moneyMaskNumber(data.CashlessTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total débito:</span>
            <span>{moneyMaskNumber(data.DebitoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total débito (NT):</span>
            <span>{moneyMaskNumber(data.DebitoNTTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total crédito:</span>
            <span>{moneyMaskNumber(data.CreditoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total crédito (NT):</span>
            <span>{moneyMaskNumber(data.CreditoNTTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total dinheiro:</span>
            <span>{moneyMaskNumber(data.DinheiroTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total desconto:</span>
            <span>{moneyMaskNumber(data.DescontoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total voucher:</span>
            <span>{moneyMaskNumber(data.VoucherTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total outros:</span>
            <span>{moneyMaskNumber(data.OutrosTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total em aberto:</span>
            <span>{moneyMaskNumber(data.EmAbertoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total suprimento:</span>
            <span>{moneyMaskNumber(data.SuprimentoTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total sangria:</span>
            <span>{moneyMaskNumber(data.SangriaTotal ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total:</span>
            <span>{moneyMaskNumber(data.TotalGeral ?? 0)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default CashClosingTotalItem;
