import Layout from "components/layout/presentation/Layout";
import styles from "./SurveyLandingPage.module.scss";
import ConfigTrack from "modules/config/integrationsConfig/presentation/pages/configTrack/ConfigTrack";
import { IntegrationTypeEnum } from "modules/config/integrationsConfig/presentation/interfaces/IntegrationTypeEnum";
import { useEffect, useState } from "react";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { PremiumHint } from "modules/plans/presentation/components/premiumHint/PremiumHint";
import { useHistory } from "react-router-dom";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

export const SurveyLandingPage = () => {
  const [step, setStep] = useState(1);
  const { checkPermission } = usePlans();
  const [modalOpened, setModalOpened] = useState<IntegrationTypeEnum | null>(
    null
  );
  const history = useHistory();
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    updateRouters([
      {
        title: "Clientes",
      },
      {
        label: "Clientes",
        title: "Pesquisa de satisfação",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <div className={styles.container}>
        {
          !checkPermission(FeatureTypeEnum.SURVEY) &&
          <PremiumHint />
        }
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.titleContainer}>
              <img src="/assets/icon/search-person.svg" alt="" />
              <h1>
                Pesquisa de <span>satisfação</span>
              </h1>
            </div>

            <p className={styles.surveyDescription}>
              Você está pronto para transformar a voz dos seus clientes em oportunidades de crescimento? 
              Integre com nossos parceiros e colete informações valiosas diretamente dos seus próprios clientes. 
            </p>
            <button onClick={() => history.push('/private/track')} className={styles.integrateButton}>Integrar</button>
          </div>
          <div className={styles.column}>
            <img src="/assets/img/survey-landing-1.png" alt="" />
          </div>
        </div>

        <div className={styles.partners}>
          <div>
            <span className={styles.partnersTitle}>
              Nossos parceiros disponíveis:
            </span>

            <ConfigTrack
              open={modalOpened}
              setOpen={setModalOpened}
              step={step}
              setStep={setStep}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <img src="/assets/img/survey-landing-2.png" alt="" />
          </div>
          <div className={styles.column}>
            <span className={styles.subtitle}>Obtenha insights valiosos</span>
            <p className={styles.whatIsIt}>
              Eleve a excelência dos seu estabelecimento com  nossos parceiros! Entenda as necessidades dos seus clientes e 
              aprimore sua oferta com base em feedbacks reais. <b>Demonstre que ele está no centro das suas operações</b> e 
              construa relacionamentos duradouros enquanto impulsiona o crescimento do seu negócio. 
              Otimize resultados e conquiste a satisfação dos seus clientes!
            </p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <span className={styles.subtitle}>Como funciona?</span>
            <p className={styles.howItWorks}>
              Escolha um parceiro e envie pesquisas tanto de forma automatizada nos pontos de contato do seu cliente 
              (pagamento na POS, Totem e App Meep) quanto de forma manual através de QR Code na mesa. 
              Depois disso, você vai conseguir entender melhor os seus clientes para <b>tomar decisões assertivas a partir dos feedbacks.</b>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
