
import { Button, Icon } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import List from 'modules/customers/presentation/components/list/List';
import React, { FC } from 'react'
import styles from './CollaboratorListSchedulePage.module.scss'
import {UseCollaboratorListSchedulePage}  from './UseCollaboratorListSchedulePage';
import  {Modal}  from 'components/ui/modal/Modal';
export interface ICollaboratorListSchedulePageProps {
    //propertys
}
export const CollaboratorListSchedulePage: FC<ICollaboratorListSchedulePageProps> = (value, id) => {
    const { collaborators, 
        onClickEdit, 
        onClickAdd, 
        handleModal, 
        handleModalRemoveCollaborator, 
        deleteCollaborator,
        toggleModal,
        setToggleModalRemoveCollaborator,
        setCurrentCollaborator,
        currentCollaborator,
        toggleModalRemoveCollaborator  } = UseCollaboratorListSchedulePage();
    return (
        <div id={styles.CollaboratorListSchedulePage} >
            <div className={styles.container} >
                <h2>Colaboradores</h2>
                <div className={styles.header}>
                    <Button size={'small'} color={'primary'} variant={'contained'} onClick={onClickAdd}>  Adicionar novo <AddCircle /> </Button>
                </div>
                {collaborators &&
                    <List
                        list={collaborators}
                        columnsConfigs={{
                            name: { text: `nome` },
                            // descricao: { text: 'descricão' }
                        }}
                        menuButtons={
                            [
                                {
                                    label: 'Editar',
                                    icon: 'edit',
                                    onClick: onClickEdit
                                },
                                {
                                    label: 'Excluir',
                                    icon: 'delete',
                                    onClick: (collaborator) => {
                                        setCurrentCollaborator(collaborator);
                                        setToggleModalRemoveCollaborator(true);
                                    }
                                }
                            ]
                        }
                    />
                }

            
            </div>
            {
                currentCollaborator !== null && (
                    <Modal toggleModal={handleModalRemoveCollaborator} onConfirm={() => deleteCollaborator(currentCollaborator.id)} 
                    open={toggleModalRemoveCollaborator} title="Deseja remover este serviço?">
                    <p>Tem certeza que deseja excluír este colaborador?</p>
                    <p>Está ação não poderar ser desfeita!</p>
                    </Modal>
                )  
            }
            
        </div>

    )
}