import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GetCampaignsUseCase from "modules/crm/application/useCases/GetCampaignsUseCase";
import { ICampaignData } from "../intefaces/ICampaign";
import CrmServiceApi from "services/api/crm/CrmApi";

const UseCampaignsList = () => {
  const [isLoadingCampaignList, setIsLoading] = useState<boolean>();
  const [campaignsList, setCampaigns] = useState<ICampaignData>();
  const { currentLocal } = useLocal();

  const getCampaignsList = useCallback(async (params?: { pagination?: { page?: number, pageSize?: number } }) => {
    try {
      const service = CrmServiceApi();
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetCampaignsUseCase(service, currentLocal.id, params?.pagination);
        setCampaigns(response);
        return response
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  return {
    campaignsList,
    isLoadingCampaignList,
    getCampaignsList,
  };
};

export default UseCampaignsList;
