import { IGetKdsStepsTotalRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalRequest"
import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSFilter } from "modules/kds/presentation/interfaces/IKDSFilter"

const GetStepTotalUseCase = async (service: IKDSService, filter: IKDSFilter, localId: string, stepId: string): Promise<{ total: number }> => {
    
    const adjustTime = (date: string | undefined): string => {
        if (!date) return '';
        const brasiliaOffset = -3; // UTC-3
        const dateObj = new Date(date);
        dateObj.setUTCHours(dateObj.getUTCHours() + brasiliaOffset);
        return dateObj.toISOString();
    };

    const request: IGetKdsStepsTotalRequest = {
        localId: localId,
        stepId: stepId,
        startDate: adjustTime(String(filter.startDate)),
        endDate: adjustTime(String(filter.endDate)),
    }


    const response = await service.getStepsTotals({ ...request, stepId });
    return (response)
}
export default GetStepTotalUseCase  