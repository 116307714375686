import { TicketPaymentDetailsTypes } from "modules/cashlessDashboard/domain/dto/rechargePaymentMethodsData/IGetRechargePaymentMethodsDataResponse";
import React, { useCallback, useEffect, useState } from "react";
import { useError } from "../../contexts/error/ErrorContext";
import { dashboardContainerEnum } from "../../interfaces/DashboardContainerEnum";
import { Skeleton } from "../skeleton/Skeleton";
import styles from "./RechargePaymentMethods.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { Tooltip } from "components/graphs/components/container/components/tooltip/Tooltip";

export interface IRechargePaymentMethodsProps {
  getData: () => Promise<TicketPaymentDetailsTypes | undefined>;
  onGeneratingPDF?: boolean;
}

const RechargePaymentMethods: React.FC<IRechargePaymentMethodsProps> = ({
  getData,
  onGeneratingPDF,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TicketPaymentDetailsTypes>();

  const { setOnError, clearContainerError } = useError();

  useEffect(() => {
    (async () => {
      try {
        clearContainerError(dashboardContainerEnum.RECHARGE_PAYMENT_DATA);
        setLoading(true);
        const response = await getData();
        setData(response);
      } catch {
        setOnError({
          type: "error",
          containerId: dashboardContainerEnum.RECHARGE_PAYMENT_DATA,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [clearContainerError, getData, setOnError]);

  const getTotalMoney = useCallback(() => {
    const money = data?.transacted.filter(x => x.paymentDescription === "Dinheiro");
    const totalMoney = (money?.length ?? 0) > 0 ? money![0].total : 0;

    return totalMoney;
  }, [data?.transacted]);

  if (loading) {
    return (
      <div id={styles.RechargePaymentMethods}>
        <div className={styles.container}>
          <div className={styles.column}>
            <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />
          </div>
          <div className={styles.column}>
            <Skeleton items={1} cardStyle={styles.skeletonCardStyle} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.RechargePaymentMethods}>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.contentTitle}>
            <span className={styles.label}>
              Transacionados
              {!onGeneratingPDF && <Tooltip
                buttonClassName={styles.helpIcon}
                title="Transacionados"
                text="Valor total de faturamento realizadas em formas de pagamento onde há transação. Esse valor leva em consideração o valor de todas formas de pagamento configuradas para o estabelecimento onde há transação ."
              />}
            </span>
            <span className={styles.total}>
              {moneyMaskNumber((data?.totalTransacted ?? 0) - getTotalMoney())}
            </span>
          </div>
          {data?.transacted.filter(x => x.paymentDescription !== "Dinheiro").map((item) => (
            <div className={styles.paymentTypes}>
              <span className={styles.label}>{item.paymentDescription}</span>
              <span className={styles.value}>
                {moneyMaskNumber(item.total)}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.column}>
          <div className={styles.contentTitle}>
            <span className={styles.label}>
              Não transacionados
              {!onGeneratingPDF && <Tooltip
                buttonClassName={styles.helpIcon}
                title="Não transacionados"
                text="Valor total de faturamento realizadas em formas de pagamento onde não há transação.Esse valor leva em consideração o valor de cada formas de pagamento configuradas para o estabelecimento onde não há transação."
              />}
            </span>
            <span className={styles.total}>
              {moneyMaskNumber((data?.totalNonTransacted ?? 0) + getTotalMoney())}
            </span>
          </div>
          {data?.nonTransacted?.map((item) => (
            <div className={styles.paymentTypes}>
              <span className={styles.label}>{item.paymentDescription}</span>
              <span className={styles.value}>
                {moneyMaskNumber(item.total)}
              </span>
            </div>
          ))}
          {data?.transacted.filter(x => x.paymentDescription === "Dinheiro").map((item) => (
            <div className={styles.paymentTypes}>
              <span className={styles.label}>{item.paymentDescription}</span>
              <span className={styles.value}>
                {moneyMaskNumber(item.total)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RechargePaymentMethods;
