import { Icon, IconButton, TextField } from '@material-ui/core'
import React, { ChangeEvent, FC, FormEvent, useCallback, useState } from 'react'
import styles from './LocalFilter.module.scss'
export interface ILocalFilterProps {
    //propertys
    onSubmit: (value: { search: string }) => void
    isLoading?: boolean
}
const LocalFilter: FC<ILocalFilterProps> = ({ onSubmit, isLoading }) => {

    const [values, setValues] = useState({ search: "" })

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)
    }, [onSubmit, values])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    return (
        <form id={styles.LocalFilter} onSubmit={submitHandle} >
            <div className={styles.inputSearch}>
                <TextField 
                    disabled={isLoading}
                    variant={'outlined'}
                    name='search'
                    value={values.search}
                    placeholder={"Pesquisar"}
                    onChange={changeHandle}
                />
                <IconButton title='Pesquisar' className={styles.button} disabled={isLoading} type={'submit'}><Icon>search</Icon></IconButton>
            </div>
        </form >
    )
}
export default LocalFilter