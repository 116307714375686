import { Icon } from '@material-ui/core'
import React, { FC } from 'react'
import { ILocalResumeItem } from '../../ILocalResume'
import styles from './LocalResumeItem.module.scss'
export interface ILocalResumeItemProps {
    //propertys
    localResume?: ILocalResumeItem
    onPressClose: (localResume: ILocalResumeItem) => void
    hideClose?:boolean
}
const LocalResumeItem: FC<ILocalResumeItemProps> = ({ localResume, onPressClose, hideClose }) => {
    function getLocalName() {
        if (localResume && localResume.name.length > 80)
            return localResume?.name.slice(0, 80) + '...';
        
            return localResume?.name;
    }
    
    return (
        !localResume ? <div></div> :
            <div id={styles.LocalResumeItem}>
                <div className={styles.top}>
                    <div className={styles.dot} style={{ backgroundColor: localResume?.color }}></div>
                    <h3>{getLocalName()}</h3>
                    {!hideClose && <Icon onClick={() => onPressClose(localResume)} className={styles.icon} fontSize={'small'}>close</Icon>}
                </div>
                <div className={styles.middle}>
                    <div>
                        <label>Recebido</label>
                        <span style={{ color: localResume.color }}>
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(localResume.received)}
                        </span>
                    </div>
                    <div>
                        <label>Consumido</label>
                        <span style={{ color: localResume.color }}>
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(localResume.consumed)}
                        </span>
                    </div>
                    <div>
                        <label>Em aberto</label>
                        <span style={{ color: localResume.color }}>
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(localResume.openedConsume)}
                        </span>
                    </div>
                </div >
                <div className={styles.bottom}>
                    <div className={styles.start}>
                        <b>Ticket Médio Consumo</b>
                        <div>
                            <label>{localResume.tables} mesas</label>
                            <span>{new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(localResume.averageConsumePerTable)}</span>
                        </div>
                        <div>
                            <label>{localResume.customers} pessoas</label>
                            <span>{new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(localResume.averageConsumePerCustomer)} </span>
                        </div>

                    </div >
                    <div className={styles.end}>
                        <b>Mesa</b>
                        <div>
                            <label>Média de giro</label>
                            <span>{localResume.turnoverTable}</span>
                        </div>
                        <div>
                            <label>Em aberto</label>
                            <span>{localResume.openedTable}</span>
                        </div>
                    </div >
                </div >
            </div >
    )
}
export default LocalResumeItem