import { useCallback, useEffect, useState } from "react";
import { IPermutationAccountfilter } from "../components/permutationFilter/interfaces/IPermutationAccountfilter";
import UsePermutationAccountList, { IParamsPemutationAccountList } from "../hooks/UsePermutationAccountList";


export const UsePermutationPage = () => {

    const { getPermutationAccountList, permutationAccountList, isLoading } = UsePermutationAccountList();


    const [params, setParams] = useState<IParamsPemutationAccountList>({
        sort: { orientation: 'asc', type: 'name' }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IPermutationAccountfilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getPermutationAccountList({ ...params });
    }, [getPermutationAccountList, params]);


    return (
        {
            permutationAccountList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getPermutationAccountList
        }
    )
}
