import { IGetEquipamentosService } from "./IPerfilService";

const GetEquipamentos = async (
  accountService: IGetEquipamentosService,
  id: string,
  idEvento: string,
  profileId: string,
  deviceName: string,
  type: string | number,
): Promise<any> => {
  return await accountService.getEquipamentos(id, idEvento, profileId, deviceName, type);
};

export default GetEquipamentos;