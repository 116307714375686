import React from 'react'
import ReserveScheduleForm from '../../components/reserveScheduleForm/ReserveScheduleForm'
import { UseAddReserveSchedulePage } from './UseAddReserveSchedulePage';

export const AddReserveSchedulePage = () => {
    const { servicesValue, placeSchedule,servicesSchedule, onChangeServiceId,onSubmitFormHandle } = UseAddReserveSchedulePage();
    return (
        <div>
            <ReserveScheduleForm
                onSubmit={onSubmitFormHandle}
                placeSchedules={placeSchedule}
                serviceValues={servicesValue}
                services={servicesSchedule}
                onChangeServiceId={onChangeServiceId}
            />
        </div>
    )
}
