import { ButtonBase, Icon as MIcon } from '@material-ui/core';
import React, { FC } from 'react';
import styles from './OtherButton.module.scss';
export interface IOtherButtonProps {
    icon: React.ReactNode;
    onClick(): void;
    label: string;
}
const OtherButton: FC<IOtherButtonProps> = ({ icon, onClick, label }) => {
    return (
        <div id={styles.OtherButton}>
            <ButtonBase onClick={onClick} className={styles.buttonBase}>
                {icon} <label>{label}</label> <MIcon>navigate_next</MIcon>
            </ButtonBase>
        </div>
    );
};

export default OtherButton