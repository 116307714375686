import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { GetServicesListBylocalIdUseCase } from 'modules/schedule/application/useCases/serviceSchedule/GetServicesListBylocalIdUseCase'
import { ServicesScheduleApi } from 'services/api/schedule/service/ServiceScheduleApi'
import React, { useCallback, useEffect, useState } from 'react'
import { IServiceProductCard } from '../../components/serviceProductCard/interfaces/IServiceProductCard'
import { useHistory, useParams } from 'react-router-dom'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'

const RouterPrefix = "/private/agendamento/list/"

export const UseServiceScheduleListPage = () => {

    const [values, setValues] = useState<IServiceProductCard[]>()
    const { push } = useHistory();
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading, toast } = useUi();
    const { updateRouters } = useBreadcumbs();
    const { tab } = useParams<{ tab: string }>()

    const getServicesList = useCallback((currentLocal: string) => {

        showLoading()
        GetServicesListBylocalIdUseCase(currentLocal, ServicesScheduleApi).then((response) => {
            setValues(response);
        }).finally(() => {
            hideLoading();
        })
    }, []);

    useEffect(() => {
        if (currentLocal) {
            getServicesList(currentLocal.id)
        }
    }, [currentLocal]);

    useEffect(() => {
        updateRouters([
            {
                title: "Agendamentos",
                url: RouterPrefix
            },
            {
                label: 'Agendamentos',
                title: 'Agenda de Serviços',
                url: RouterPrefix+tab
            }
        ])
    }, [tab, updateRouters]);

    const goAddProductList = useCallback(() => {
        push(`/private/agendamento/servicos/adicionar/`);
    }, [push])

    return (
        {
            values,
            setValues,
            goAddProductList,
            getServicesList
        }
    )
}
