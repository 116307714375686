import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';

export interface IItem {
    id: string,
    [key: string]: any;
}

export interface IListProps<T extends IItem> {
    list: T[];
    renderItem: (item: T, index: number, dragHandleProps: DraggableProvidedDragHandleProps | undefined
        ) => JSX.Element;
    onChangeOrder?: (list: T[]) => void;
    isDragInDropDisabled?: boolean;
    className?: string;
}

const DnDList = <T extends { id: string }>({ list, renderItem, onChangeOrder, isDragInDropDisabled, className }: IListProps<T>) => {
    const [internalList, setInternalList] = useState<T[]>(list);

    useEffect(() => {
        setInternalList(list)
    }, [list])

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newList = Array.from(internalList);
        const [removed] = newList.splice(result.source.index, 1);
        newList.splice(result.destination.index, 0, removed);

        setInternalList(newList);
        onChangeOrder?.(newList);
    }, [internalList, onChangeOrder]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list" isDropDisabled={isDragInDropDisabled}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={className}
                    >
                        {internalList.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragInDropDisabled}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        {renderItem(item, index, provided.dragHandleProps)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DnDList;
