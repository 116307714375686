import KDSPage from "modules/kds/presentation/KDSPage";
import { AuthProvider } from "modules/auth/presentation/context/AuthContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { UserProvider } from "modules/user/domains/presentation/contexts/UserContext";
import SaasKDSPage from "modules/kds/presentation/SaasKDSPage";

const Painel = () => {
  const { currentLocal } = useLocal();

  return (
    <AuthProvider>
      <UserProvider>
        {currentLocal?.systemIdentifier === 1 ? <SaasKDSPage /> : <KDSPage />}
      </UserProvider>
    </AuthProvider>
  );
};
export default Painel;
