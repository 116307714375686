import React, { useEffect, useState } from 'react';
import { IKDSStep } from '../../interfaces/IKDSSteps';
import styles from './SelectorSteps.module.scss';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

const StepItem = ({ onClickHandle, step, isSelected, getStepTotals, setTotal }: {
    getStepTotals?: (stepId: string) => Promise<{ total: number } | null | undefined>,
    onClickHandle: (step: IKDSStep) => void;
    step: IKDSStep;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    isSelected: (step: IKDSStep) => boolean;
}) => {
    const [itemTotal, setItemTotal] = useState<number>();

    const { currentLocal } = useLocal();

    useEffect(() => {
      setTotal(prev => prev - (itemTotal ?? 0));
      setItemTotal(0)
      getStepTotals?.(step.id).then((response) => {
        setItemTotal(response?.total)
        setTotal(prev => prev + (response?.total ?? 0));
      })
    }, [getStepTotals, setTotal, step]);    

    const isSaas = currentLocal?.systemIdentifier === 1;

    return (
      <div
        onClick={() => onClickHandle(step)}
        className={isSelected(step) ? styles.itemSelected : styles.item}
      >
        <label className={styles.label}>{step.name}</label>
        {!!itemTotal && (
          <div
            className={`${isSaas ? styles.saasBadge : styles.badge} ${
              isSelected(step) ? styles.saasBadgeSelected : ""
            }`}
          >
            {itemTotal > 99 ? "+99" : itemTotal}
          </div>
        )}
      </div>
    );
};

export default StepItem