import { IPromoters } from "modules/events/presentation/components/guestListForm/interfaces/IPromoters";

export enum PreCheckinStatus {
    Pending = 1,
    Waiting = 2,
}
export interface IGuest {
    id: string;
    name: string;
    cpf: string;
    phone: number;
    // eventId: string;
    // guestListId: string;
    // guestListName: string;
    promoterId: string;
    promoterName: string;
    invitedAt: string;
    checkinAt: string;
    listId: string;
    listName: string;
    preCheckinStatus: PreCheckinStatus;
    isOnAlert:boolean;
    isGuestListCheckinEnabledWithTicketValidator: boolean;
    peopleCategories?: Array<{
      id: string;
      name: string;
      icon: string;
    }>,
}

export interface IEventPeopleList {
  totalRecords: number;
  records: IEventPeople[];
  totalCheckIn: number
}

export interface IEventPeople {
    id: string;
    name: string;
    cpf: string;
    phone: string;
    promoterId: string;
    promoterName: string;
    invitedAt: string;
    checkinAt: string;
    listId: string;
    listName: string;
    isOnAlert:boolean;
    preCheckinStatus: PreCheckinStatus;
    boughtATicket: boolean;
    isGuestListCheckinEnabledWithTicketValidator: boolean;
    peopleCategories?: Array<{
      id: string;
      name: string;
      icon: string;
    }>,
  }

export interface IPeopleList {
    name: string;
    isActive: boolean;
    isOnAlert: boolean;
    description: string;
    closingDateTime: string;
    eventName: string;
    eventId: string;
    people: IGuest[];
}

export interface IGuestsList {
    id: string;
    name: string;
    eventId: string;
    eventName: string;
    createdBy: string;
    maxGuests: number;
    guests?: IGuest[];
    endTime: Date;
    addGrantedPromoters: string;
    invitesLimit: string;
}


export interface IGuestListFilter {
    id: string;
    name: string;
}

export interface IDiscount {
    id: string;
    productName: string;
    discountType: string;
    productId: string;
    validUntil: string;
    percentualDiscount: {
      percentual: number;
    } | null;
    priceDiscount: {
      price: number;
    } | null;
  }

  export interface IBaseList {
    id: string;
    name: string;
  }
  export interface IEventsGuestList {
    id: string;
    name: string;
    description: string;
    closingDateTime: string;
    guestCount: number;
    femaleGuestCount: number;
    maleGuestCount: number;
    isActive: boolean;
    hasCopy?: boolean;
    copyConfiguration: boolean;
    copyGuests: boolean;
    notOnGuestListPeopleListId: string;
    validUntil?: string;
    // mainPromoterId: string;
    mainPromoter: IPromoters;

    eventName: string;

    peopleListLink?: IEventsGuestListLink

    baseList?: IBaseList;
    discountLimit: number;

    totalGuestCheckinCount: number;
    maleGuestCheckinCount: number;
    femaleGuestCheckinCount: number;

    totalGuestSales: number;
    maleGuestSales: number;
    femaleGuestSales: number;
    totalSalesNotInvite: number;

  }

  export interface IEventsGuestListLink {
    enabled: boolean;
    name: string;
    description: string;
    maxPeopleList: number;
    expiresIn: string;
    visibleInApp: boolean;
  }
