import { IGetColaboradoresParams } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresParams";
import { IGetColaboradoresResponse } from "modules/colaboradores/domain/dto/getColaboradores/IGetColaboradoresResponse";
import { ICollaboratorsApiService } from "modules/colaboradores/domain/interface/ICollaboratorsApiService";
import { api } from "../Api";
import { INewCollaboratorRequest } from "modules/colaboradores/domain/dto/INewCollaboratorRequest";
import { IEditCollaboratorRequest } from "modules/colaboradores/domain/dto/IEditCollaboratorRequest";
import { IDeleteCollaboratorParams } from "modules/colaboradores/domain/dto/IDeleteCollaboratorParams";
import { IPostRegisterPortal } from "modules/colaboradores/domain/dto/IPostRegisterPortalParams";

const ColaboradoresApi = (): ICollaboratorsApiService => {
    const getCollaborators = async (params?: IGetColaboradoresParams): Promise<IGetColaboradoresResponse> => {
        const response = await api.get("/Acesso/Colaborador", { params: params});
        return response.data;
    };

    const postNewCollaborator = async (request: INewCollaboratorRequest): Promise<any> => {
        const response = await api.post("/Acesso/Colaborador", request, { params: { disableError: true }});
        return response.data;
    };

    const getNewCollaborator = async (email: string): Promise<any> => {
        const response = await api.get(`/Acesso/VerificarEmail/${email}`,);
        return response.data;
    };
    
    const putEditCollaborator = async (request: IEditCollaboratorRequest): Promise<any> => {
        const response = await api.put("/Acesso/Colaborador", request);
        return response.data;
    };

    const deleteCollaborator = async (params: IDeleteCollaboratorParams): Promise<any> => {
        const response = await api.delete("/Acesso/Colaborador", { params: params });
        return response.data;
    };

    const postRegisterPortal = async (params: IPostRegisterPortal): Promise<any> => {
        const response = await api.post("/Account/RegisterPortal",  params );
        return response.data;
    };

    return { getCollaborators, postNewCollaborator, putEditCollaborator, deleteCollaborator, postRegisterPortal, getNewCollaborator };
}

export default ColaboradoresApi;
