import { Icon, IconButton, TextField } from '@material-ui/core';
import styles from './CategoryCustomers.module.scss';
import { ICategoryCustomers } from '../../interfaces/ICategoryCustomers';
import { Pagination } from '@material-ui/lab';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';

interface CategoryCustomersProps {
    onClose: () => void;
    getData: (page?: number, name?: string, document?: string) => void;
    onDelete: (customerId: string) => void;
    data?: ICategoryCustomers;
    categoryName: string;
    loading: boolean;
    page: number;
}

export const CategoryCustomers = ({ onClose, getData, onDelete, categoryName, loading, page, data }: CategoryCustomersProps) => {
    const [document, setDocument] = useState('');
    const [name, setName] = useState('');
    const [selectedItem, setSelectedItem] = useState('');


    const handleFilter = useCallback(() => {
        getData(0, name, document);
    }, [getData, name, document])

    useEffect(() => {
        handleFilter()
    }, [ handleFilter]);

    const totalPages = useMemo(() => {
        let total = (data?.totalRecords || 0) / 20;
        total = Math.floor(total);

        return total || 1;
    }, [data?.totalRecords]);

    return (
        <div className={styles.container}>
            <header>
                <h1>
                    Clientes cadastrados na categoria <span>{categoryName}</span>
                </h1>
                <IconButton onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </header>

            <div className={styles.content}>

                <div className={styles.filter}>
                    <div>
                        <label className={styles.required}>
                            Buscar cliente por nome:
                        </label>
                        <TextField
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="outlined"
                            fullWidth
                            size='small'
                            className={styles.textField}
                            placeholder='Busque por nome'
                        />
                    </div>
                    <div>
                        <label className={styles.required}>
                            Buscar cliente por cpf:
                        </label>
                        <TextField
                            onChange={(e) => setDocument(e.target.value)}
                            value={document}
                            variant="outlined"
                            fullWidth
                            size='small'
                            className={styles.textField}
                            placeholder='Busque por CPF'
                        />
                    </div>
                </div>
                <button onClick={handleFilter} className={styles.filterButton}>
                    Buscar
                </button>

                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td colSpan={3}>
                                        <div className={styles.loader}>
                                            <Skeleton variant="rectangular" width="100%" height={40} className={styles.skeleton} />
                                            <Skeleton variant="rectangular" width="100%" height={40} className={styles.skeleton} />
                                            <Skeleton variant="rectangular" width="100%" height={40} className={styles.skeleton} />
                                        </div>

                                    </td>
                                </tr>
                                :
                                !!data?.records.length ? data?.records.map(item => (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.document}</td>
                                        <td>
                                            {loading && selectedItem === item.id ?
                                                <img src="/assets/img/loader.gif" alt="" style={{ width: 20 }} /> :
                                                <button onClick={() => {
                                                    onDelete(item.id);
                                                    setSelectedItem(item.id)
                                                }}><Icon>delete_outline</Icon></button>
                                            }
                                        </td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={2}>
                                            <div className={styles.emptyTable}>
                                                <span>Essa categoria não possui nenhum cliente!</span>
                                            </div>
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
                <Pagination
                    page={page}
                    color="primary"
                    className={styles.pagination}
                    count={totalPages}
                    onChange={(ev, page) => getData(page)}
                />
            </div>
        </div>
    )
}