import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage'
import { FC, useEffect } from 'react'
import GenderTable from '../genderTable/GenderTable'
import styles from './CheckinTable.module.scss'
import { ICheckinTableData } from './ICheckinTable'

export interface ICheckinTableProps {
    getCheckinTableData: () => void
    isLoading?: boolean,
    data?: ICheckinTableData
    error?: string
}
const CheckinTable: FC<ICheckinTableProps> = ({ getCheckinTableData, isLoading, data }) => {

    useEffect(() => {
        getCheckinTableData();
    }, [getCheckinTableData])

    if (!data?.customers && !data?.customersAge && !isLoading) {
        return <div id={styles.CheckinTable}>
            <ErrorMessage type="warning" />
        </div>
    }

    return (
        <div id={styles.CheckinTable} >
            <div className={styles.container} >
                <GenderTable
                    isLoading={isLoading}
                    data={
                        {
                            icon: "person",
                            value: (data?.customers?.total || 0).toFixed(0),
                            valueLabel: `Total de pessoas`,
                            male: (data?.customers?.male || 0).toFixed(0),
                            female: (data?.customers?.female || 0).toFixed(0),
                            unknown: (data?.customers?.unknown || 0).toFixed(0),
                        }
                    }
                />
                <GenderTable
                    isLoading={isLoading}
                    data={
                        {
                            icon: "calendar_month",
                            value: (data?.customersAge?.average || 0).toFixed(0),
                            valueLabel: `Média de idade`,
                            male: (data?.customersAge?.male || 0).toFixed(0),
                            female: (data?.customersAge?.female || 0).toFixed(0),
                            unknown: (data?.customersAge?.unknown || 0).toFixed(0),
                        }
                    }
                />
            </div>
        </div>
    )
}
export default CheckinTable