import { FC } from 'react';
import styles from './Tab.module.scss';

interface TabProps {
    item: any;
    label: string;
    selected: boolean;
    onChange: (item: any) => void;
}

const Tab: FC<TabProps> = ({ item, label, selected, onChange }) => {
    return (
        <div className={`${styles.tab} ${selected ? styles.selected : ''}`} onClick={() => onChange(item)}>
            {item[label]}
        </div>
    );
}

export { Tab };