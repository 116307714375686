import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import { FC, useEffect } from "react";
import ContaDigitalPage from "modules/contaDigital/presentation/pages/ContaDigitalPage";
import { useParams } from "react-router-dom";
export interface IDigitalAcountProps {
  //propertys
}
const ContaDigital: FC<IDigitalAcountProps> = () => {
  const { updateRouters } = useBreadcumbs();
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    updateRouters([
      {
        title: "Conta digital",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      {type === 'new' ? (
        <ContaDigitalPage />
      ):(
        <IframePage src={"/contaDigital"} />
      )}       
    </Layout>
  );
};
export default ContaDigital;
