import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Modal } from 'components/ui/modal/Modal'
import { Search } from '@mui/icons-material';
import { FormControl, TextField, FormLabel, Button } from '@mui/material'
import RankingList, { IRankingItem } from 'modules/salesDashboard/presentation/components/rakingList/RankingList'
import React, { FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import Utils from 'services/utils/Utils'
import { ICanceledProductRankingData, IProductRankingData, IProductsCanceledByOperatorData } from './IProductRanking'
import styles from './ProductRanking.module.scss'
import ProductsCanceledOperatorList from './productsCanceledOperator/ProductsCanceledOperatorList'
import { ErrorMessage } from 'components/ui/errorMessage/ErrorMessage';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

const PageSize = 10

export interface IProductRankingProps {
    //propertys
    dataProduct?: IProductRankingData
    getDataProduct: (filter: { keyword?: string, page?: number, pageSize?: number, sort?: { orientation?: 'asc' | 'desc', type?: string } }) => void
    onLoadingProduct: boolean,
    dataCanceledProduct?: ICanceledProductRankingData
    getDataCanceledProduct: (fitler: { keyword?: string, page?: number, pageSize?: number, sort?: { orientation?: 'asc' | 'desc', type?: string } }) => void
    onLoadingCanceledProduct: boolean,
    eventId?: string,

    getProductCanceledByOperator: (_filter: {
        page?: number;
        pageSize?: number;
        sortBy?: string;
        operatorId: string;
    }) => Promise<void>
    dataProductCanceledByOperator: IProductsCanceledByOperatorData | undefined;
    onLoadingProductCanceledByOperator: boolean
}
const ProductRanking: FC<IProductRankingProps> = ({ dataProduct,
    getDataProduct,
    onLoadingProduct,
    dataCanceledProduct,
    getDataCanceledProduct,
    onLoadingCanceledProduct,
    getProductCanceledByOperator,
    dataProductCanceledByOperator,
    onLoadingProductCanceledByOperator,
}) => {

    const [visibleRanking, setVisibleRanking] = useState<"products" | "canceled">("products")
    const [productRankingFilter, setProductRankingFilter] = useState<{ page?: number, pageSize?: number, sort?: { type?: string, orientation?: 'asc' | 'desc' } }>({ page: 0, pageSize: PageSize })
    const [canceledProductRankingFilter, setCanceledProductRankingFilter] = useState<{ page?: number, pageSize?: number, sort?: { type?: string, orientation?: 'asc' | 'desc' } }>({ page: 0, pageSize: PageSize })
    const [openModal, setOpenModal] = useState<string>();
    const [keyword, setKeyword] = useState('')
    const { isMobile } = useUi();


    const getMoreProducts = useCallback((filter?: { pagination?: { page?: number, pageSize?: number } }) => {
        setProductRankingFilter(prev => ({ ...prev, ...filter?.pagination }));
    }, [])

    const getMoreCanceledProducts = useCallback((filter?: { pagination?: { page?: number, pageSize?: number } }) => {
        setCanceledProductRankingFilter(prev => ({ ...prev, ...filter?.pagination }));
    }, [])

    useEffect(() => {
        getDataProduct({ ...productRankingFilter, keyword })
    }, [getDataProduct, productRankingFilter])

    useEffect(() => {
        getDataCanceledProduct({ ...canceledProductRankingFilter, keyword })
    }, [canceledProductRankingFilter, getDataCanceledProduct, productRankingFilter])


    const sortProducts = useCallback((key: string) => {
        setProductRankingFilter(prev => ({ ...prev, page: 0, sort: { type: key, orientation: prev?.sort?.orientation === 'desc' ? 'asc' : 'desc' } }));
    }, [])

    const sortCanceledProducts = useCallback((key: string) => {
        setCanceledProductRankingFilter(prev => ({ ...prev, page: 0, sort: { type: key, orientation: prev?.sort?.orientation === 'desc' ? 'asc' : 'desc' } }));
    }, [])

    const getOrderCancellationsByOperator = useCallback(async (item: IRankingItem) => {
        setOpenModal(item.id)
    }, [])

    const toggleCancellationsOperatorModal = () => {
        setOpenModal(undefined)
    }

    const handleSearch = () => {
        if (visibleRanking === 'products') {
            setProductRankingFilter(prev => ({ ...prev, page: 0 }));
        } else {
            setCanceledProductRankingFilter(prev => ({ ...prev, page: 0 }));
        }
    }

    return (
        <div id={styles.ProductRanking} >
            <div className={styles.filter} >
                <FormControl component="fieldset">
                    <FormLabel className={styles.label}>Visualizar por</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="products"
                        name="radio-buttons-group"
                        row
                        onChange={(_, value) => setVisibleRanking(value as SetStateAction<"products" | "canceled">)}
                    >
                        <FormControlLabel label="Produtos" control={<Radio />} value="products" />
                        <FormControlLabel label="Cancelados" control={<Radio />} value="canceled" />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth={isMobile ? true : false}>
                    <FormLabel className={styles.label}>{visibleRanking === 'canceled' ? 'Operador' : 'Produto'}</FormLabel>
                    <TextField
                        placeholder={`Selecione um ${visibleRanking === 'canceled' ? 'Operador' : 'Produto'}`}
                        fullWidth
                        className={styles.search}
                        InputProps={{
                            endAdornment: <Search style={{ color: "#5f5f5f" }} />
                        }}
                        size='small' name={'input'} onChange={(e) => setKeyword(e.target.value)} value={keyword}
                    />
                </FormControl>
                <div className={styles.containerButton}>

                    <Button
                        className={styles.filterButton}
                        fullWidth
                        variant='contained'
                        type='submit'
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </div>
            </div>
            {
                ((visibleRanking === "products" && !dataProduct?.records.length) || (visibleRanking === "canceled" && !dataCanceledProduct?.records.length)) ?
                    <div id={styles.GestListLive}>
                        <ErrorMessage type="warning" />
                    </div>
                    :
                    <>
                        {visibleRanking === "products" && <div className={styles.container} >
                            <RankingList
                                list={dataProduct?.records}
                                count={dataProduct?.totalRecords}
                                getMore={getMoreProducts}
                                isLoading={onLoadingProduct}
                                pagination={{ page: (productRankingFilter.page ?? 0) + 1, pageSize: productRankingFilter.pageSize }}
                                colunms={{
                                    imgUrl: {},
                                    name: { title: "Produto", onClickSort: sortProducts },
                                    categoryName: { title: "Categoria", onClickSort: sortProducts },
                                    quantity: { title: "Qtd", onClickSort: sortProducts },
                                    totalGross: { title: "Total", onClickSort: sortProducts },
                                }}
                            />
                        </div>}
                        {visibleRanking === "canceled" && <div className={styles.container} >
                            <RankingList
                                list={dataCanceledProduct?.records}
                                count={dataCanceledProduct?.totalRecords}
                                getMore={getMoreCanceledProducts}
                                isLoading={onLoadingCanceledProduct}
                                pagination={{ page: (canceledProductRankingFilter.page ?? 0) + 1, pageSize: canceledProductRankingFilter.pageSize }}
                                colunms={{
                                    name: { title: "Nome", onClickSort: sortCanceledProducts },
                                    canceledQuantity: { title: "Cancelados", onClickSort: sortCanceledProducts },
                                    canceledTotalNet: { title: "Total Cancelado", onClickSort: sortCanceledProducts },
                                    lastCanceledTotalNet: { title: "Último Cancelamento", onClickSort: sortCanceledProducts },
                                    lastCanceledAt: { title: "Hora", onClickSort: sortCanceledProducts, formatValue: (value) => Utils.toDateAndTime(value) },
                                }}
                                onClick={getOrderCancellationsByOperator}
                            />

                            <Modal
                                toggleModal={toggleCancellationsOperatorModal}
                                open={!!openModal}
                                title="Cancelamentos do operador"
                                showButtons={false}
                            >
                                {openModal && (
                                    <ProductsCanceledOperatorList
                                        dataProductsCanceledOperator={dataProductCanceledByOperator}
                                        getDataProductsCanceledOperator={getProductCanceledByOperator}
                                        onLoadingProductsCanceledOperator={onLoadingProductCanceledByOperator}
                                        operatorId={openModal} />
                                )}
                            </Modal>
                        </div>}
                    </>
            }
        </div>
    )
}
export default ProductRanking