import { Icon, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import React, { FC, useEffect, useMemo } from "react";
import styles from "./DiscountProductList.module.scss";

export interface IDiscountProductListProps {
  discountsList: IDiscounts[];
  onPressEdit?: (item: IDiscounts, index: number) => void;
  onPressDelete: (item: IDiscounts, index: number) => void;
  deleting?: IDiscounts | null;
  adminAccess?: boolean;
}
const DiscountProductList: FC<IDiscountProductListProps> = ({
  discountsList,
  onPressEdit,
  onPressDelete,
  deleting,
  adminAccess,
}) => {

  const listItems = useMemo(
    () => (
      <div className={styles.discountList}>
        {discountsList.length === 0 && <div className={styles.emptyMessage}>Nenhum desconto cadastrado!</div>}
        {discountsList.map((item, index) => (
          <div key={index} className={styles.discountItem}>
            <div>
              <div>
                {item.productName}:
                {item.discountType === "Price" ? (
                  <p>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.priceDiscount?.price || 0)}{" "}
                    de desconto
                    {/* {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.priceWithDiscount)} */}
                  </p>
                ) : (
                  <p>
                    {item.percentualDiscount?.percentual || 0}% de desconto
                    {/* {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.priceWithDiscount)} */}
                  </p>
                )}
              </div>
              <div>
                {" "}
                Até: {new Date(item.validUntil).toLocaleString("pt-BR", { timeStyle: "short", dateStyle: "short" })}
              </div>
            </div>

            {onPressEdit && (
              <IconButton onClick={() => onPressEdit(item, index)} className={styles.icon} size="small">
                <Icon fontSize="small">edit</Icon>
              </IconButton>
            )}
            {onPressDelete &&
              adminAccess &&
              (deleting && deleting.id === item.id ? (
                <span>
                  <CircularProgress />
                </span>
              ) : (
                item.id.length && (
                  <IconButton onClick={() => onPressDelete(item, index)} className={styles.icon}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )
              ))}
          </div>
        ))}
      </div>
    ),
    [discountsList, onPressEdit, onPressDelete, adminAccess, deleting]
  );

  return (
    <div id={styles.DiscountProductList}>
      <div className={styles.container}>{listItems}</div>
    </div>
  );
};
export default DiscountProductList;
