import { AddCircle } from "@mui/icons-material";
import { Button, Drawer } from "@mui/material";
import React, { useCallback, useState } from "react";
import { AddNewCoupon } from "./pages/addNewCoupon/AddNewCoupon";
import { DiscountCouponFilter } from "./components/discountCouponFilter/DiscountCouponFilter";
import styles from "./DiscountCouponsPage.module.scss";
import { DiscountCouponsList } from "./pages/discountCouponsList/DiscountCouponsList";
import { UseDiscountCouponsPage } from "./UseDiscountCouponsPage";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

export const DiscountCouponsPage = () => {
  const {
    discountCouponsList,
    isLoading,
    onChangePaginationHandle,
    params,
    setParams,
    onChangeFilterHandle,
    getDiscountCouponsList,
  } = UseDiscountCouponsPage();

  const [openAddNewCoupon, setOpenAddNewCoupon] = useState<boolean>(false);

  const { hasAccessRole } = useAuth();

  const openDrown = useCallback(() => {
    setOpenAddNewCoupon(true);
  }, []);
  const closeDrown = useCallback(() => {
    setOpenAddNewCoupon(false);
  }, []);

  return (
    <div id={styles.DiscountCouponsPage}>
      <div className={styles.buttonAddNewCoupon}>
        {hasAccessRole("EditarCupomDesconto") && (
          <Button onClick={openDrown}>
            Adicionar cupom de desconto
            <AddCircle />
          </Button>
        )}
      </div>

      <DiscountCouponFilter
        params={params}
        setParams={setParams}
        onSubmit={onChangeFilterHandle}
      />

      <DiscountCouponsList
        discountCouponsList={discountCouponsList}
        isLoading={isLoading}
        onChangePaginationHandle={onChangePaginationHandle}
        params={params}
        setParams={setParams}
        pagination={params?.pagination}
        refrash={getDiscountCouponsList}
      />

      <Drawer open={openAddNewCoupon} anchor="right" onClose={closeDrown}>
        <div id={styles.drawer}>
          <AddNewCoupon
            refresh={getDiscountCouponsList}
            closeDrown={closeDrown}
          />
        </div>
      </Drawer>
    </div>
  );
};
