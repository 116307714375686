import { FC, useState, useCallback } from 'react'
import styles from './UserButton.module.scss'
import { useUser } from 'modules/user/domains/presentation/contexts/UserContext';

const UserButton: FC = () => {
    const [errorImage, setErrorImage] = useState(false);

    const { user } = useUser();

    const partesNome = user?.name.split(' ');
    const primeiraLetraPrimeiroNome = partesNome && partesNome[0].charAt(0);
    const primeiraLetraUltimoNome = partesNome && partesNome.length > 1 ? partesNome[partesNome.length - 1].charAt(0) : '';

    const photoPath = `${process.env.REACT_APP_URL_IMAGEM}usuarios-profile/${user?.id ?? ""}.png`;

    const onError = useCallback(() => {
        setErrorImage(true);
    }, []);
   
    return (
        <div id={styles.UserButton} >
            {!errorImage ? (
                <div className={styles.photo} >
                    <img width={40} height={40} src={photoPath} alt="Foto do usuário" onError={onError} />
                </div>
            ) : (
                <div className={styles.iniciais} >
                    <p>{primeiraLetraPrimeiroNome}{primeiraLetraUltimoNome}</p>
                </div>
            )}
        </div>
    );
}

export default UserButton;
