import Layout from "components/layout/presentation/Layout";
import { FidelityPage } from "./FidelityPage";

export const FidelityPageContainer = () => {
  return (
    <Layout>
      <FidelityPage />
    </Layout>
  );
};
