import { IReserveScheduleApi } from "modules/schedule/application/interfaces/api/IReserveScheduleApi";
import { GetPlacesScheduleByServiceIdResponseDTO } from "modules/schedule/domain/dto/ReserveSchedule/GetPlacesScheduleByServiceIdResponse";
import { IGetServicesByLocalResponse } from "modules/schedule/domain/dto/ReserveSchedule/GetServicesByLocalIdResponseDTO";
import { AddPlaceDemandScheduleRequestDTO } from "modules/schedule/domain/dto/ReserveSchedule/IAddReseveScheduleWithouPaymentRequest";
import { GetProductValuesByServiceIdResponseDTO } from "modules/schedule/domain/dto/ReserveSchedule/IGetProductValuesByServiceIdResponse";
import { IGetReservationListResponse } from "modules/schedule/domain/dto/ReserveSchedule/IGetReservationListResponse";
import { IPatchCheckedByStaffByDemandedScheduleIdRequest } from "modules/schedule/domain/dto/ReserveSchedule/IPatchCheckedByStaffByDemandedScheduleIdRequest";
import { IState, IStateEnum } from "modules/schedule/domain/models/IReserveList";
import { api } from "../../Api";

export const ReserveScheduleApi = (): IReserveScheduleApi => {

    const getServicesByLocalId = async (localId: string): Promise<IGetServicesByLocalResponse> => {
        const response = await api.get<IGetServicesByLocalResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: true } });
        return (response.data);
    }

    const getPlacesByServiceId = async (serviceId: string): Promise<GetPlacesScheduleByServiceIdResponseDTO> => {
        const response = await api.get<GetPlacesScheduleByServiceIdResponseDTO>(`/PlaceSchedule/GetPlacesScheduleByServiceId/${serviceId}`);
        return (response.data);
    }

    const getServicesValuesByServiceId = async (serviceId: string): Promise<GetProductValuesByServiceIdResponseDTO> => {
        const response = await api.get<GetProductValuesByServiceIdResponseDTO>(`/ServiceSchedule/GetProductValuesByServiceId/${serviceId}`);
        return (response.data);
    }

    const getReservationList = async (localClienteId: string, page: number, pageSize: number, start: string, end: string, keyword: string, status: IStateEnum[]): Promise<IGetReservationListResponse> => {
        const params = {
            localClienteId,
            page,
            pageSize,
            start,
            end,
            keyword,
            status
        }

        const response = await api.get<IGetReservationListResponse>(`/DemandSchedule/ReservationList`, {
            params
        });      

        return response.data
    }  

    const postAddReseveSchedule = async (request: AddPlaceDemandScheduleRequestDTO): Promise<void> => {
        const response = await api.post(`DemandSchedule/Place/WithoutPayment`, request);
        return
    }

    const postCheckedByStaff = async (checkedByStaff: string, value: IPatchCheckedByStaffByDemandedScheduleIdRequest): Promise<void> => {
        const response = await api.patch(`DemandSchedule/CheckedByStaff/${checkedByStaff}`, value );
        return
    }

    const deleteReserve = async (demandedScheduleId: string) : Promise<void> => {
        await api.post(`DemandSchedule/cancel`, {id: demandedScheduleId});        
    }


    return {
        getServicesByLocalId,
        getPlacesByServiceId,
        getServicesValuesByServiceId,
        postAddReseveSchedule,
        getReservationList,
        deleteReserve,
        postCheckedByStaff
    }
}