import GetLocalResumeUseCase from "modules/salesDashboard/application/useCases/GetLocalResumeUseCase";
import { createContext, FC, useCallback, useContext, useState } from "react";
import SalesDashboardService from "services/api/salesDashboard/SalesDashboardService";
import { IFilterValue } from "../../components/filter/IFilter";
import { ILocalResume } from "../../components/localResume/ILocalResume";


interface ILocalResumeContext {
  localResume: ILocalResume;
  loading: boolean;
  getLocalResume: (filter: IFilterValue) => Promise<ILocalResume>;
}

const salesDashboardService = SalesDashboardService();

const LocalResumeContext = createContext<ILocalResumeContext>({} as ILocalResumeContext);

export const useLocalResume = () => {
  const context = useContext(LocalResumeContext);
  return context;
};

export const LocalResumeConsumer = LocalResumeContext.Consumer;
export const LocalResumeProvider: FC = ({ children }) => {
  const [localResume, setLocalResume] = useState({} as ILocalResume);
  const [loading, setLoading] = useState(false);

  const getLocalResume = useCallback(async (filter: IFilterValue) => {
    setLoading(true);
    const response = await GetLocalResumeUseCase(salesDashboardService, { filter });
    setLocalResume(response)
    setLoading(false);
    return response
}, [])

  return (
    <LocalResumeContext.Provider value={{ localResume, getLocalResume, loading }}>
      <>{children}</>
    </LocalResumeContext.Provider>
  );
};
