import { IOperatorService } from "modules/operators/domain/interfaces/IOperatorService";
import { IOperatorItem } from "modules/operators/domain/models/IOperatorItem";


const GetOperatorListUseCase = async (service: IOperatorService, localId: string): Promise<IOperatorItem[]> => {
    const response = await service.getOperatorList(localId);
    const operators = response.map((operator) => {
        return {
            id: operator.id,
            name: operator.name,
            password: operator.password,
            privilegedAccess: operator.privilegedAccess,
            roles: operator.roles,
            commission: operator.commission,
            useCashlessCardAsPassword: operator.useCashlessCardAsPassword,
            tag: operator.tag
        }
    })
    return operators;
}

export default GetOperatorListUseCase;