import React, { CSSProperties, FC } from "react";
import styles from "./FidelityAppPreview.module.scss";
export interface IFidelityAppPreviewProps {
  //propertys
  imageUrl: string;
  style: CSSProperties;
}
const FidelityAppPreview: FC<IFidelityAppPreviewProps> = ({
  imageUrl,
  style,
}) => {
  return (
    <div id={styles.FidelityAppPreview}>
      <div className={styles.container}>
        <div className={styles.box} style={style}>
          <img src={imageUrl} alt="" />
          <div>
            <span>
              Seja bem vindo(a), <b>cliente Ouro!</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FidelityAppPreview;
