import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useQuery } from "hooks/UseQuery";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProspectSaasUseCase } from "modules/saas/application/useCases/ProspectSaasUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { getParamsString } from "../../utils/params";
import { ILocalSaasForm } from "../../interfaces/local/ILocalSaasForm";
import { CheckLocalDocumentUseCase } from "modules/saas/application/useCases/CheckLocalDocumentUseCase";
import { SaveLocalUseCase } from "modules/saas/application/useCases/SaveLocalUseCase";
import { ILocalSegments } from "../../interfaces/localSegments/ILocalSegments";
import { GetLocalSegmentsUseCase } from "modules/saas/application/useCases/GetLocalSegmentsUseCase";
import UseProspect from "../../hooks/UseProspect";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import { ILocalSchema } from "modules/saas/domain/schema/localSchema";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";

const service = SaasApi();

export const UseLocalPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState({} as ILocalSaasForm);
    const [segments, setSegments] = useState<ILocalSegments[]>([]);
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    let query = useQuery();
    const history = useHistory();
    const { toast, showLoading, hideLoading } = useUi();
    const { setProspectId, setKey, setEmail, setType, type } = UseProspect();
    const location = useLocation<{ type: string }>();

    const prospectId = query.get('prospectId');
    const key = query.get('key');

    useEffect(() => {
        if (!prospectId || !key) {
            history.push("/public/saas/register?" + getParamsString(query));
        } else {
            setProspectId(prospectId);
            setKey(key);
        }
    }, [history, key, prospectId, query, setKey, setProspectId]);

    useEffect(() => {
        (async () => {
            try {
                showLoading();
                if (prospectId && key) {
                    const response = await ProspectSaasUseCase(service, prospectId, key);
                    setDefaultValues(prev => ({
                        ...prev,
                        document: response.establishment?.document,
                        name: response.establishment?.name,
                        segment: response.establishment?.segment,
                    }))
                    setEmail(response.email);
                    setType(response.type);

                    if (response.type === ProspectType.Cielo || response.type === ProspectType.CieloEvent || response.type === ProspectType.CieloEstablishment) {
                        setProgressBarColor("#00739E");
                        setBackgroundColorTop("#00AEEF");
                        setBackgroundColorBottom("#00AEEF");
                    } else {
                        setProgressBarColor("#865FDD");
                        setBackgroundColorTop("#6B41C9");
                        setBackgroundColorBottom("#865FDD");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    toast('A chave fornecida está inválida ou expirou.', 'error');
                    history.push("/public/saas/register", { type: location.state?.type });
                } else {
                    toast('Ocorreu um erro ao fazer a busca dos dados. Tente novamente.', 'error');
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, history, key, location.state?.type, prospectId, query, setEmail, setType, showLoading, toast]);

    useEffect(() => {
        (async () => {
            const response = await GetLocalSegmentsUseCase(service);
            setSegments(response);
        })();
    }, [])

    const handleSubmit = async (values: ILocalSchema, setError: UseFormSetError<ILocalSchema>, clearErrors: UseFormClearErrors<ILocalSchema>) => {
        try {
            setIsLoading(true);
            clearErrors("establishmentDocument");

            const alredyRegistered = await CheckLocalDocumentUseCase(service, values.establishmentDocument.replace(/\./g, '').replace('-', '').replace('/', ''));
            if (alredyRegistered) {
                return setError("establishmentDocument", { message: "Esse CNPJ já está sendo usado!" });
            }

            await SaveLocalUseCase(service,
                {
                    name: values.establishmentName,
                    document: values.establishmentDocument.replace(/\./g, '').replace('-', '').replace('/', ''),
                    segment: Number(values.segment)
                },
                prospectId!, key!);

            history.push(`/public/saas/address?prospectId=${prospectId}&key=${key}`, { type: type });
        } catch {
            toast('Ocorreu um erro ao salvar os dados. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        defaultValues,
        segments,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit,
    }
}