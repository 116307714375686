import { IAddGuestForm } from "modules/events/domain/models/IEvents";
import React, { FormEvent, useCallback, useState } from "react";
import CPF from "services/utils/CPF";

const validate = (cpfList: string[]): [boolean, string] => {
  let cpfValid: string[] = [];
  let cpfInvalids: string[] = [];

  cpfList.forEach((item) => {
    item = item.replace(/\D/g, "")
    if (item.length && CPF.isValid(item)) {
      cpfValid.push(item);
    } else {
      if (item.length)
        cpfInvalids.push(item);
    }
  });

  if (cpfInvalids.length) {
    const cpfStrList = cpfInvalids
      .concat("^^^^ CPFs acima Inválidos ^^^^")
      .concat(cpfValid)
      .toString()
      .replaceAll(",", "\n");
    return [false, cpfStrList];
  } else if (cpfValid.length) {
    const cpfStrList = cpfValid.toString().replaceAll(",", "\n");
    return [true, cpfStrList];
  } else {
    return [false, ''];
  }
};

interface IUseAddGuestForm {
  inputList: string
  isValid: boolean;
  handleValidate: () => void;
  submitHandle: (ev: FormEvent<HTMLFormElement>) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const UseAddGuestForm = (
  handleSubmit: (guestList: IAddGuestForm) => void
): IUseAddGuestForm => {
  const [isValid, setIsValid] = useState(false);
  const [inputList, setInputList] = useState<string>("");

  const handleValidate = useCallback(() => {
    const listArr = inputList.split("\n");
    const [onlyValid, cpfStrList] = validate(listArr);
    setIsValid(onlyValid);
    setInputList(cpfStrList);
  }, [inputList])

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      const guestList: IAddGuestForm = {
        cpfList: inputList.split("\n"),
        IgnoreAlert: false,
      };

      handleSubmit(guestList);
    },
    [handleSubmit, inputList]
  );

  const handleChange = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.persist();
    const { value } = event.target;
    setInputList(value);
    setIsValid(false);
  }, []);

  return {
    inputList,
    isValid,
    handleValidate,
    submitHandle,
    handleChange
  };
};
