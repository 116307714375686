import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import { Facebook, Info, Instagram, Language, PhotoCamera, WhatsApp } from '@material-ui/icons';
import { MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';

import styles from './DigitalMenuConfigForm.module.scss';
import RenderIf from 'components/renderIf/RenderIf';
import Input from "components/ui/input/Input";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import SidesheetHorario from 'modules/gestaoVendas/presentation/components/sidesheetHorario/SidesheetHorario';
import { AddressModal } from 'modules/gestaoVendas/presentation/components/address/AddressModal';
import { FeaturedMediaEnum } from 'modules/gestaoVendas/presentation/pages/interfaces/FeaturedMediaEnum';

import AppApi from 'services/api/app/AppApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { IProfileConfigurationResponse } from 'modules/app/domain/dtos/IProfileConfigurationResponse';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

interface FormError {
  logo?: string;
  banner?: string;
  description?: string;
  catalogUrl?: string;
}

const appService = AppApi();

const DigitalMenuConfigForm = ({ currentAddress, addressHandlerFn }: any) => {
  const { currentLocal } = useLocal();
  const { toast } = useUi();
  const history = useHistory();

  const logoInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const [formData, setFormData] = useState<IProfileConfigurationResponse>({} as IProfileConfigurationResponse);
  const [formError, setFormError] = useState<FormError>({});

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    setFormData(old => ({ ...old, addressLocal: currentAddress }));
  }, [currentAddress]);

  const getFormData = async () => {
    setIsLoading(true);
    try {
      const data = await appService.getProfileConfiguration(currentLocal!.id);
      data.availableDay = data.availableDay.map(item => ({ ...item, checked: true }));
      setFormData(data);

      const state: any = history.location.state;

      if (state.hasOwnProperty('showDates')) {
        setShowScheduleModal(true);
      }
    } catch(e) {

    } finally {
      setIsLoading(false);
    }
  }

  const validateForm = () => {
    let hasError = false;
    setFormError({});

    if (formData.logo) {
      const logo = new Image();
      logo.src = formData.logo ?? '';

      logo.onerror = () => {
        setFormError((prev: any) => ({ ...prev, logo: "O logo é obrigatório" }));
        hasError = true;
      }
    }

    if (currentLocal?.systemIdentifier !== 1 && formData.banner) {
      const banner = new Image();
      banner.src = formData.banner ?? '';

      banner.onerror = () => {
        setFormError((prev: any) => ({ ...prev, banner: "O banner é obrigatório" }));
        hasError = true;
      }
    }

    if (!formData.description) {
      setFormError((prev: any) => ({ ...prev, description: "A descrição é obrigatória" }));
      hasError = true;
    }

    if (!formData.catalogUrl) {
      setFormError((prev: any) => ({ ...prev, catalogUrl: "A URL é obrigatória" }));
      hasError = true;
    }

    return hasError;
  };

  const saveFormChanges = async () => {
    if (validateForm()) {
      return;
    }

    try {
      setIsSaving(true);
      const payload = formData;
      if (payload.banner?.startsWith('http')) delete payload.banner;
      if (payload.logo?.startsWith('http')) delete payload.logo;
      payload.localId = currentLocal!.id;

      await appService.saveProfileConfiguration(payload);
      history.push('/private/solucoesOnline');
    } catch(e) {
      toast('Houve um erro ao tentar salvar os dados do cardápio digital.', 'error');
    } finally {
      setIsSaving(false);
    }
  }

  const changeImageHandler = async (ev: ChangeEvent<HTMLInputElement>) => {
    const fileObj = ev.target.files && ev.target.files[0];
    if (!fileObj) {
      return;
    }
    const image = await resizeFile(fileObj);

    updateFormData(ev.target.name, image);
  };

  const openSelectImage = (type: 'logo' | 'banner') => {
    if (type === 'logo') {
      setFormError((prev: any) => ({...prev, logo: null }));
      logoInputRef.current?.click();
      return;
    }

    if (type === 'banner') {
      setFormError((prev: any) => ({...prev, banner: null }));
      bannerInputRef.current?.click();
    }
  };

  const resizeFile = (file: File): Promise<string | Blob | File | ProgressEvent<FileReader>> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        }, 'base64');
    });
  }

  const getFullAddress = () => {
    if (formData.addressLocal) {
      return `${formData.addressLocal!.street}${formData.addressLocal!.number ? ', ' + formData.addressLocal!.number : ''} - ${formData.addressLocal!.neighborhood}, ${formData.addressLocal!.city} - ${formData.addressLocal.uf || formData.addressLocal.state}`;
    }

    return '';
  }

  const updateFormData = (field: string, value: any) => {
    setFormData((data: any) => ({ ...data, [field]: value }));
  }

  return (
    <>
      <p className={styles.digitalMenuSectionTitle}>Perfil do estabelecimento</p>
      <div className={styles.digitalMenuIdentification}>
        <div className={styles.digitalMenuLogoWrapper} data-error={formError?.logo} onClick={() => openSelectImage('logo')}>
          <input
            name="logo"
            id="input-file"
            type="file"
            ref={logoInputRef}
            accept=".jpg, .jpeg, .png"
            style={{ display: "none" }}
            onChange={changeImageHandler}
          />

          <RenderIf condition={!isLoading && !!formData?.logo}>
            <div className={styles.digitalMenuPreviewLogo} style={{ backgroundImage: `url(${formData?.logo})`}}></div>
          </RenderIf>

          <RenderIf condition={!isLoading && !formData?.logo}>
            <div className={styles.digitalMenuImageInput}>
              <PhotoCamera style={{ fontSize: '56px' }} />
              <label className={styles.digitalMenuImageLabel} data-required="true" data-error={formError?.logo}>
                Selecione uma foto do seu logo
              </label>
            </div>
          </RenderIf>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {/* PLACE NAME */}
            <Grid item xs={12}>
              <label className={styles.digitalMenuLabel}>
                <span>Nome do estabelecimento</span>
                <Tooltip title="Nome do estabelecimento não pode ser alterado. Em caso de dúvidas, entre em contato com nosso suporte." arrow>
                  <Info />
                </Tooltip>
              </label>
              <Input
                name="estabelecimento"
                value={currentLocal?.name}
                variant="outlined"
                required
                disabled
              />
            </Grid>

            {/* PLACE DESCRIPTION */}
            <Grid item xs={12}>
              <label className={styles.digitalMenuLabel} data-required="true">Descrição do local</label>
              <InputTextArea
                name="description"
                value={formData?.description}
                variant="outlined"
                placeholder="Escreva aqui uma breve descrição do seu estabelecimento"
                rows={3}
                multiline
                required
                error={!!formError?.description}
                helperText={formError?.description}
                onChange={(ev) => {
                  setFormError((prev: any) => ({...prev, description: null }));
                  updateFormData(ev.target.name, ev.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* URL */}
        <Grid item xs={12} md={8}>
          <label className={styles.digitalMenuLabel} data-required="true">URL do estabelecimento</label>
          <div className={styles.digitalMenuUrlWrapper}>
            <p className={styles.digitalMenuUrlPrefix}>https://mepay.meep.cloud/</p>
            <Input
              name="catalogUrl"
              value={formData?.catalogUrl}
              variant="outlined"
              required
              error={!!formError?.catalogUrl}
              helperText={formError?.catalogUrl}
              onChange={(ev) => {
                setFormError((prev: any) => ({...prev, catalogUrl: null }));
                updateFormData(ev.target.name, ev.target.value);
              }}
            />
          </div>
        </Grid>

        {/* COLOR */}
        <Grid item xs={12} md={4}>
          <label className={styles.digitalMenuLabel}>Cor do seu estabelecimento</label>
          <Input
            name="color"
            value={formData?.color}
            variant="outlined"
            type="color"
            className={styles.digitalMenuColor}
            onChange={(ev) => updateFormData(ev.target.name, ev.target.value)}
          />
        </Grid>

        {/* BANNER */}
        <RenderIf condition={currentLocal?.systemIdentifier !== 1}>
          <Grid item xs={12} md={12}>
            <label className={styles.digitalMenuLabel} data-required="true">Capa</label>
            <p className={styles.digitalMenuBannerWarning}>(Tamanho 375 x 234 pixels)</p>

            <div className={styles.digitalMenuBannerWrapper} data-error={formError?.banner} onClick={() => openSelectImage('banner')}>
              <input
                name="banner"
                id="input-banner-file"
                type="file"
                ref={bannerInputRef}
                accept=".jpg, .jpeg, .png"
                style={{ display: "none" }}
                onChange={changeImageHandler}
              />

              <RenderIf condition={!isLoading && !!formData?.banner}>
                <div className={styles.digitalMenuPreviewBanner} style={{ backgroundImage: `url(${formData?.banner})`}}></div>
              </RenderIf>

              <RenderIf condition={!isLoading && !formData?.banner}>
                <div className={styles.digitalMenuImageInput}>
                  <PhotoCamera style={{ fontSize: '56px' }} />
                  <label className={styles.digitalMenuImageLabel} data-required="true" data-error={formError?.banner}>
                    Escolha uma foto para a capa do seu estabelecimento
                  </label>
                </div>
              </RenderIf>
            </div>
          </Grid>
        </RenderIf>

        {/* ADDRESS */}
        <Grid item xs={12} md={12}>
          <label className={styles.digitalMenuLabel} data-required="true">Endereço</label>

          <RenderIf condition={!!formData.addressLocal}>
            <div className={styles.digitalMenuCurrentAddress}>
              <b>Atual: </b>
              <span>{getFullAddress()}</span>
            </div>
          </RenderIf>

          <button className={styles.digitalMenuButton} onClick={() => setShowAddressModal(true)}>
            Configurar endereço
          </button>
        </Grid>

        {/* SITE */}
        <Grid item xs={12} md={6}>
          <label className={styles.digitalMenuLabel}>Site</label>
          <Input
            name="siteUrl"
            value={formData?.siteUrl}
            variant="outlined"
            placeholder="www.seulocal.com.br"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Language color="secondary" />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => updateFormData(ev.target.name, ev.target.value)}
          />
        </Grid>

        {/* WHATSAPP */}
        <Grid item xs={12} md={6}>
          <label className={styles.digitalMenuLabel}>WhatsApp</label>
          <Input
            name="whatsapp"
            value={formData?.whatsapp}
            variant="outlined"
            placeholder="(xx) xxxxx-xxxx"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsApp color="secondary" />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => updateFormData(ev.target.name, ev.target.value)}
          />
        </Grid>

        {/* FACEBOOK */}
        <Grid item xs={12} md={6}>
          <label className={styles.digitalMenuLabel}>Facebook</label>
          <Input
            name="facebook"
            value={formData?.facebook}
            variant="outlined"
            placeholder="/Seulocal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Facebook color="secondary" />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => updateFormData(ev.target.name, ev.target.value)}
          />
        </Grid>

        {/* INSTAGRAM */}
        <Grid item xs={12} md={6}>
          <label className={styles.digitalMenuLabel}>Instagram</label>
          <Input
            name="instagram"
            value={formData?.instagram}
            variant="outlined"
            placeholder="@seulocal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Instagram color="secondary" />
                </InputAdornment>
              ),
            }}
            onChange={(ev) => updateFormData(ev.target.name, ev.target.value)}
          />
        </Grid>

        {/* HIGHLIGHT INFO */}
        <Grid item xs={12}>
          <div >
            <label className={styles.digitalMenuLabel}>Destacar informação</label>
            <RenderIf condition={!isLoading}>
              <Select
                name="featuredMedia"
                variant="outlined"
                className={styles.digitalMenuHighlight}
                value={formData?.featuredMedia}
                onChange={(ev) => updateFormData(ev.target.name as string, ev.target.value)}
              >
                <MenuItem value={" "} style={{ display: "none" }} />
                <MenuItem value={FeaturedMediaEnum.Instagram}>
                  <Instagram color="secondary" />
                  <span className={styles.digitalMenuHighlightOption}>Instagram</span>
                </MenuItem>
                <MenuItem value={FeaturedMediaEnum.WhatsApp}>
                  <WhatsApp color="secondary" />
                  <span className={styles.digitalMenuHighlightOption}>WhatsApp</span>
                </MenuItem>
                <MenuItem value={FeaturedMediaEnum.Site}>
                  <Language color="secondary" />
                  <span className={styles.digitalMenuHighlightOption}>Site</span>
                </MenuItem>
                <MenuItem value={FeaturedMediaEnum.Facebook}>
                  <Facebook color="secondary" />
                  <span className={styles.digitalMenuHighlightOption}>Facebook</span>
                </MenuItem>
              </Select>
            </RenderIf>
          </div>
        </Grid>

        {/* WORK SCHEDULE */}
        <Grid item xs={12}>
          <label className={styles.digitalMenuLabel}>Horário de funcionamento</label>

          <button className={styles.digitalMenuButton} onClick={() => setShowScheduleModal(true)}>
            Configurar horário de funcionamento
          </button>
        </Grid>
      </Grid>

      <div className={styles.digitalMenuActionBox}>
        <button className={styles.digitalMenuButton} data-type="save" onClick={saveFormChanges}>Salvar</button>
      </div>

      <RenderIf condition={!isLoading}>
        <AddressModal
          open={showAddressModal}
          currentAddress={formData.addressLocal}
          loading={isLoading}
          onSubmit={addressHandlerFn}
          onClose={() => setShowAddressModal(false)}
        />

        <SidesheetHorario
          openSidesheet={showScheduleModal}
          setOpenSidesheet={setShowScheduleModal}
          dateItens={formData?.availableDay}
          handleChangeDate={($event) => updateFormData('availableDay', $event)}
        />
      </RenderIf>
    </>
  )
}

export default DigitalMenuConfigForm;