import { INovidadesBody } from "modules/adminNovidades/application/interfaces/INovidadesService";
import { api } from "services/api/Api";

export const NovidadesApi = (): any => {
  const postNovidades = async (body: INovidadesBody): Promise<any> => {
    const result = await api.post<any>("/newsletter", body);
    return result.data;
  };

  const updateNovidades = async (
    id: string,
    body: INovidadesBody
  ): Promise<any> => {
    const result = await api.put<any>(`/newsletter/${id}`, body);
    return result.data;
  };

  const getNovidades = async (params: {
    pageSize: number;
    page: number;
    type: string;
    orientation: string;
    category?: string;
  }): Promise<any> => {
    const { type, orientation, category, ...otherParams } = params;
    let modifiedParams;

    if (category) {
      modifiedParams = {
        ...otherParams,
        "sort.type": type,
        "sort.orientation": orientation,
        category: category,
      };
    } else {
      modifiedParams = {
        ...otherParams,
        "sort.type": type,
        "sort.orientation": orientation,
      };
    }

    const result = await api.get<any>("/newsletter", {
      params: modifiedParams,
    });
    return result.data;
  };

  const deleteNovidades = async (id: string): Promise<any> => {
    const result = await api.delete<any>(`/newsletter/${id}`);
    return result.data;
  };

  const getCategoryNovidades = async (): Promise<any> => {
    const result = await api.get<any>("newsletter/category");
    return result.data;
  };

  return {
    postNovidades,
    getNovidades,
    deleteNovidades,
    getCategoryNovidades,
    updateNovidades,
  };
};
