import React, { useCallback, useEffect, useState } from 'react'
import styles from './UseFormItemEdit.module.scss'
import { IChildren } from 'modules/permutation/presentation/interfaces/IAddPermutionAccount'
import { Switch, TextField } from '@material-ui/core'
import InputMasked from 'components/inputMasked/InputMasked'
import { IChildrenErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros'
import { validateUseForm } from '../../../../validation/UseFormValidation'
import Button from 'components/ui/Button/Button'
import { IEditChild } from 'modules/permutation/presentation/interfaces/IEditChild'

interface IUseFormItemProps {
  defaultValues: IChildren | undefined
  closeSideSheetUseForm: () => void
  onSaveChildren: (newChildren: IChildren[], isEdit: boolean) => void
  isEdit: React.MutableRefObject<boolean>
  onSubmitHandleEditChild: (item: IEditChild, childrenId: string) => Promise<void>
  setErrors: React.Dispatch<React.SetStateAction<IChildrenErros>>
  errors: IChildrenErros
}


export const UseFormItemEdit: React.FC<IUseFormItemProps> = ({ setErrors, errors, onSubmitHandleEditChild, defaultValues, isEdit, closeSideSheetUseForm, onSaveChildren,
}) => {

  const [values, setValues] = useState<IChildren>(defaultValues ?? {
    document: '',
    hasLimit: false,
    limit: undefined,
    name: '',
  })


  const [items, setItems] = useState<IChildren[]>([]);

  const onSubmitHandle = () => {
    const newItem: IChildren = {
      id: values.id,
      document: values.document,
      name: values.name,
      limit: values.limit,
      hasLimit: values.hasLimit,
    };

    if (validateUseForm(newItem, setErrors)) {
      const newItems = [...items, newItem];
      setItems(newItems);
      onSaveChildren(newItems, true);
    }
    closeSideSheetUseForm();
  };

  const changeHandleCPFUse = useCallback((val: string) => {
    setValues({ ...values, document: val.replace(/\D/g, '') })
  }, [values]);

  const changeHandleNameUse = useCallback((val: string) => {
    setValues({ ...values, name: val })
  }, [values]);

  const changeHandleLimitUse = useCallback((val: string) => {
    if (values.hasLimit) {
      setValues({ ...values, limit: undefined })
    }
    setValues({ ...values, limit: Number(val) })
  }, [values]);

  const changeHandleChecked = useCallback((checked: boolean) => {
    setValues({ ...values, hasLimit: checked })
  }, [values]);


  return (
    <div id={styles.UseFormItemEdit}>
      <div className={styles.item}>
        <label>CPF <span>*</span></label>
        <InputMasked
          name="document"
          variant='outlined'
          fullWidth
          mask="999.999.999-99"
          placeholder="Digite seu CPF"
          value={values.document}
          onChange={(ev: any) =>
            changeHandleCPFUse(ev.target.value)
          }
          type="tel"
          error={!!errors.document}
          helperText={errors.document}
        />
      </div>
      <div className={styles.item}>
        <label>Nome <span>*</span></label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder=""
          variant="outlined"
          name="name"
          value={values.name}
          onChange={(ev) =>
            changeHandleNameUse(ev.target.value)
          }
          error={!!errors.name}
          helperText={errors.name}
        />
        <div className={styles.containerSwitch}>
          <Switch
            color="secondary"
            name="hasLimit"
            checked={values.hasLimit}
            onChange={(ev) =>
              changeHandleChecked(ev.target.checked)
            }
          />
          Inserir valor de utilização
        </div>

        {
          values.hasLimit &&
          <div className={styles.item}>
            <label>Valor de utilização</label>
            <TextField
              fullWidth
              inputProps={{ maxLength: 50 }}
              size="small"
              placeholder=""
              variant="outlined"
              name="limit"
              value={values.limit}
              onChange={(ev) =>
                changeHandleLimitUse(ev.target.value)
              }
              type='number'
            />
          </div>
        }
      </div>
      <div className={styles.containerFooter}>
        <div className={styles.buttonFooter}>
          <Button variant='outlined' onClick={closeSideSheetUseForm}>
            Cancelar
          </Button>
          <Button variant='contained' onClick={isEdit.current ? () => onSubmitHandleEditChild({ hasLimit: values.hasLimit, limit: values.limit ?? 0 }, values?.id ?? '') : onSubmitHandle}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  )
}
