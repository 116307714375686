import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigMeepTickets } from "./UseConfigMeepTickets";
import { useMemo } from "react";
import Sidesheet from "components/sidesheet/Sidesheet";
import ConfigContentMeepTickets from "../../components/_configCampaign/configContentMeepTickets/ConfigContentMeepTickets";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";

export interface IConfigMeepTicketsProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigMeepTickets = ({
  open,
  setOpen,
  step,
  setStep,
}: IConfigMeepTicketsProps) => {
  const { loading, integrated, organizer, integrateOrganizer } =
    UseConfigMeepTickets();

  const handleContinueButton = async () => {
    const response = await integrateOrganizer();
    if (response === "ok") return setStep(step + 1);
  };

  const handleStatus = useMemo(
    () => (integrated ? "enabled" : "disabled"),
    [integrated]
  );

  return (
    <>
      <ContentBox
        loading={loading}
        status={handleStatus}
        type={IntegrationTypeEnum.MEEP_TICKETS}
        premium={true}
        text="Ticketeira da Meep para vender ingressos para o seu evento"
        setOpen={setOpen}
        getMoreRef="/private/tickets"
        featureType={FeatureTypeEnum.TICKETS}
      />
      {open && open === "Meep Tickets" && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={<ConfigContentMeepTickets />}
          cancelButton={!!organizer}
          continueButton={!organizer && !integrated ? "Fechar" : "Integrar"}
          handleContinueButton={() =>
            !organizer && !integrated ? setOpen(null) : handleContinueButton()
          }
          isLoading={loading}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração realizada com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigMeepTickets;
