import { useCallback, useRef, useState } from "react";
import { Info, VerticalAlignBottomOutlined } from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import { jsPDF } from "jspdf";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import html2canvas from "html2canvas";
import Radio from "@material-ui/core/Radio";
import * as XLSX from "xlsx";
import { format } from "date-fns";

import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { UseSalesByDayPage } from "./hooks/UseSaleForDayPage";
import styles from "./SaleForDayPage.module.scss";
import Sidesheet from "components/sidesheet/Sidesheet";
import SalesByDayFilter from "./components/salesByDayFilter/SalesByDayFilter";
import UseDimension from "components/dimension/UseDimension";
import SalesByDayItem from "./components/salesByDayItem/SalesByDayItem";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import SalesByDayHeader from "./components/salesByDayHeader/SalesByDayHeader";
import SalesByDayFooter from "./components/salesByDayFooter/SalesByDayFooter";
import SalesByDayItemMobile from "./components/salesByDayItemMobile/SalesByDayItemMobile";
import { formatarValor } from "modules/contaDigital/presentation/pages/utils";
import { IGetSalesByDaysVenda } from "../domain/dto/IGetSalesByDayResponse";
import { IGetSalesByDayParams } from "../domain/dto/IGetSalesByDayParams";

const SaleForDayPage = () => {
  const { getSalesByDayList, isLoading, reportData } = UseSalesByDayPage();
  const { dimensions } = UseDimension();
  const { showLoading, hideLoading } = useUi();
  const [step, setStep] = useState(1);
  const [optionDownload, setOptionDownload] = useState("xlsx");
  const [openSidesheetDownload, setOpenSidesheetDownload] = useState<null | string>(null);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });


  const skeletons = Array.from({ length: 4 }, (_, index) => (
    <Skeleton
      key={index}
      variant="text"
      sx={{ fontSize: "40px", marginBottom: "10px" }}
    />
  ));

  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = useCallback(async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF({ orientation: "landscape", format: [800, 600] });
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = 1260;
    const padding = 20;

    const elements = pdfRef.current.querySelectorAll(".div-to-capture");
    const divs = Array.from(elements).filter(
      (element) => element instanceof HTMLElement
    );

    let currentHeight = 0;
    let divsByPage: Element[] = [];
    let pages: Element[] = [];

    for (const div of divs) {
      const { height } = div.getBoundingClientRect();

      if (currentHeight + height < pageHeight) {
        currentHeight += height;
        divsByPage.push(div);
      } else {
        const uniqueDiv = document.createElement("div");

        divsByPage.forEach((element) => {
          uniqueDiv.appendChild(element.cloneNode(true));
        });

        pages.push(uniqueDiv);
        currentHeight = height;
        divsByPage = [div];
      }
    }

    if (divsByPage.length) {
      const uniqueDiv = document.createElement("div");

      divsByPage.forEach((element) => {
        uniqueDiv.appendChild(element.cloneNode(true));
      });

      pages.push(uniqueDiv);
    }

    for (let i = 0; i < pages.length; i++) {
      const tempDiv = pages[i] as HTMLElement;
      tempDiv.style.width = "2300px";

      if (i === pages.length - 1) {
        tempDiv.style.height = "100%";
      }

      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        imgWidth - 2 * padding,
        imgHeight - 2 * padding
      );

      if (i !== pages.length - 1) {
        pdf.addPage();
      }

      document.body.removeChild(tempDiv);
    }

    const fileName = "relatorio_venda_por_dia.pdf";
      pdf.save(fileName);
      hideLoading()
      setStep(2)
    }, [hideLoading]);

    const downloadXlsx = () => {
      if (reportData) {
        const data = reportData.Vendas.map((item: IGetSalesByDaysVenda) => ({
          DataVenda: format(new Date(item.DataVendas), 'dd/MM/yyyy'),
          Cashless: item.Cashless ?? 0, 
          Cancelado: item.Cancelado ?? 0, 
          Boleto: item.Boleto ?? 0, 
          Debito: item.Debito ?? 0, 
          DebitoNT: item.DebitoNT ?? 0, 
          Credito: item.Credito ?? 0, 
          CreditoNT: item.CreditoNT ?? 0, 
          Dinheiro: item.Dinheiro ?? 0, 
          Desconto: item.Desconto ?? 0, 
          Voucher: item.Voucher ?? 0, 
          Outros: item.Outros ?? 0, 
          EmAberto: item.EmAberto ?? 0, 
          ValorTotalTransacionado: item.ValorTotalTransacionado ?? 0, 
          ValorTotalNaoTransacionado: item.ValorTotalNaoTransacionado ?? 0, 
          Total: item.Total ?? 0, 
        }));
    
        data.push({
          DataVenda: "Total Geral:",
          Cashless: reportData.TotalCashless ?? 0, 
          Cancelado: reportData.TotalCancelado ?? 0, 
          Boleto: reportData.TotalBoleto ?? 0, 
          Debito: reportData.TotalDebito ?? 0, 
          DebitoNT: reportData.TotalDebitoNT ?? 0, 
          Credito: reportData.TotalCredito ?? 0, 
          CreditoNT: reportData.TotalCreditoNT ?? 0, 
          Dinheiro: reportData.TotalDinheiro ?? 0, 
          Desconto: reportData.TotalDesconto ?? 0, 
          Voucher: reportData.TotalVoucher ?? 0, 
          Outros: reportData.TotalOutros ?? 0, 
          EmAberto: reportData.TotalEmAberto ?? 0, 
          ValorTotalTransacionado: reportData.TotalValorTotalTransacionado ?? 0, 
          ValorTotalNaoTransacionado: reportData.TotalValorTotalNaoTransacionado ?? 0, 
          Total: reportData.TotalGeral ?? 0, 
        });
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        const currencyFormat = '"R$" #,##0.00'; 
        const currencyColumns = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P','Q']; 
    
        currencyColumns.forEach(col => {
          for (let i = 2; i <= data.length + 1; i++) {
            const cellRef = `${col}${i}`;
            if (worksheet[cellRef]) {
              worksheet[cellRef].z = currencyFormat; 
            }
          }
        });
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório venda por dia");
    
        const blob = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        const blobUrl = URL.createObjectURL(
          new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
    
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "relatorio_venda_por_dia.xlsx");
        document.body.appendChild(link);
    
        link.click();
        document.body.removeChild(link);
        hideLoading();
        setStep(2);
      }
    };
    

  const onDownload = () => {
    showLoading();
    if (optionDownload === "xlsx") {
      downloadXlsx();
    } else generatePDF();
  };

  const getReportData = (params: IGetSalesByDayParams) => {
    setDateRange(() => ({
      start: format(new Date(params.dataInicio + 'T00:00'), 'dd/MM/yyyy'),
      end: format(new Date(params.dataFim + 'T00:00'), 'dd/MM/yyyy')
    }));
    getSalesByDayList(params);
  }

  return (
    <div>
      <SalesByDayFilter setParams={(params: IGetSalesByDayParams) => getReportData(params)} />

      <div className={`${styles.displayRow} ${styles.filterEndButton}`} style={{ width: "fit-content", marginLeft: 'auto' }} data-enabled={(reportData && reportData?.Vendas.length > 0) || false}>
        <div className={styles.btnRow} data-enabled={(reportData && reportData?.Vendas.length > 0) || false} onClick={() => setOpenSidesheetDownload('true')}>
          <span>Exportar</span>
          <VerticalAlignBottomOutlined />
        </div>
      </div>

      {dateRange.start && dateRange.end && (
        <div className={styles.btnRow} style={{ cursor: "default", marginBottom: '10px' }}>
          <span><b>Período:</b> {dateRange.start} <b>até</b> {dateRange.end}</span>
        </div>
      )}

      {isLoading ? (
        <div style={{ marginTop: "1rem" }}>{skeletons}</div>
      ) : (
        reportData && reportData?.Vendas.length > 0 && (
          <div ref={pdfRef}>
            <div className={styles.table}>
              {dimensions.width > 650 && (
                <div className="div-to-capture">
                  <SalesByDayHeader />
                </div>
              )}
              {reportData.Vendas.map((venda: IGetSalesByDaysVenda, index: number) => (
                <>
                  {dimensions.width > 650 ? (
                    <div className="div-to-capture">
                      <SalesByDayItem item={venda} key={index} />
                    </div>
                  ) : (
                    <div className="div-to-capture">
                      <SalesByDayItemMobile
                        item={venda}
                        key={index}
                      />
                    </div>
                  )}
                </>
              ))}

              <div className="div-to-capture">
                <SalesByDayFooter item={reportData} />
              </div>
            </div>

            <div className="div-to-capture">
              <span>
                <Info />
                Observação:
              </span>
              <p style={{ marginTop: '10px' }}>
                1 - O valor de venda cashless não entra no total, uma vez que já está presente na venda de recarga do cashless nas formas Crédito/Débito/Dinheiro.
              </p>
              <p style={{ marginTop: '10px' }}>
                2 - É considerado como cancelado apenas vendas que foram faturadas (pagas) e em seguida cancelada. Cancelamento de itens durante a operação de PÓS-PAGO antes do pagamento não são considerados aqui.
              </p>
              <p style={{ marginTop: '10px' }}>
                3 - O Valor de venda em aberto se refere a contas fechadas manualmente com valor em aberto e não entra no valor total.
              </p>
              <p style={{ marginTop: '10px' }}>
                4 - NT - Não transacionado pela Meep.
              </p>
            </div>
          </div>
        )
      )}
      {reportData && !reportData?.Vendas.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src="/assets/img/empty-box.png" alt="" />
          <span>
            <b>Não há dados</b> para exibição.
          </span>
        </div>
      )}

      <Sidesheet open={openSidesheetDownload} onClose={() => { setOpenSidesheetDownload(null); setStep(1); }}
        title={<h1>Exportar</h1>}
        continueButton={"Exportar"}
        handleContinueButton={onDownload}
        currentStep={step}
        totalSteps={2}
        cancelButton
        feedback={ <SidesheetFeedback text="Relatório exportado com sucesso!" success /> }
        content={
          <div>
            <p>Selecione abaixo uma opção para exportar o relatório</p>
            <RadioGroup onChange={(_, value) => setOptionDownload(value)} value={optionDownload}>
              <div className={styles.radioItem}>
                <FormControlLabel value="xlsx" label="XLSX" control={<Radio />} />
              </div>

              <div className={styles.radioItem}>
                <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export default SaleForDayPage;
