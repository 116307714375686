import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddDiscountToBaseListUseCase from "modules/events/application/useCases/baseList/AddDiscountUseCase";
import DeleteBaseListUseCase from "modules/events/application/useCases/baseList/DeleteBaseListUseCase";
import DeleteDiscountUseCase from "modules/events/application/useCases/baseList/DeleteDiscountUseCase";
import EditBaseListDiscountUseCase from "modules/events/application/useCases/baseList/EditBaseListDiscountUseCase";
import GetProductsUseCase from "modules/events/application/useCases/discounts/GetProductsUseCase";
import { DiscountProductTypeEnum, IAddDiscountToListRequest, IEditDiscountsRequest } from "modules/events/domain/dto/IEventsRequest";
import { IProductItemToDiscount } from "modules/events/presentation/components/discountPeopleListForm/interfaces/IProductList";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useState } from "react";
import { EventsDiscountsApi } from "services/api/events/discounts/EventsDiscountsApi";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import {
  IDiscountProductListForm,
  IDiscountProductType,
} from "../discountListEditForm/interfaces/IDiscountProductListForm";

interface IUseListCard {
  deleteList: (listId: string) => Promise<void>;
  onClickEditDiscounts: (discountsList: IDiscounts[], baseListId: string) => Promise<void>;
  toggleEditDiscountsModal: () => void;
  showEditDiscountsModal: boolean;
  productList: IProductItemToDiscount[] | undefined;
  onDeleteDiscount: (discountId: string) => Promise<void>;
  onAddDiscount: (newValue: IDiscountProductListForm, baseListId: string) => Promise<any>;
  discountsToEdit: IDiscounts[] | undefined;
  handleEditDiscount: (discount: IDiscountProductListForm) => Promise<void>
  toggleDeleteDiscountsModal: () => void;
  deleteConfirmationModal: boolean;
}

const UseListCard = (refreshList: () => Promise<void>): IUseListCard => {
  const { currentLocal } = useLocal();
  const { hideLoading, showLoading, toast } = useUi();
  const discountsService = EventsDiscountsApi();
  const baseListService = DiscountBaseListApi();
  const [productList, setProductList] = useState<IProductItemToDiscount[]>();
  const [discountsToEdit, setDiscountsToEdit] = useState<IDiscounts[]>();
  const [showEditDiscountsModal, setShowEditDiscountsModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)

  const getProductsUseCase = async (localId: string) => {
    showLoading();
    try {
      const response = await GetProductsUseCase(discountsService, localId);
      setProductList(response);
    } finally {
      hideLoading();
    }
  };

  const deleteList = async (listId: string) => {
    if (!currentLocal) return;
    showLoading();
    try {
      await DeleteBaseListUseCase(baseListService, listId, currentLocal.id);
      toast("Lista removida com sucesso!", "success");
      refreshList();
      toggleDeleteDiscountsModal();
    } catch (error) {
      toast("Algo deu errado, tente novamente!", "error");
    } finally {
      hideLoading();
    }
  };

  const toggleEditDiscountsModal = () => {
    if (showEditDiscountsModal) {
      refreshList();
    }
    setShowEditDiscountsModal(!showEditDiscountsModal);
  };

  const toggleDeleteDiscountsModal = () => {
    setDeleteConfirmationModal(!deleteConfirmationModal);
  };

  const onClickEditDiscounts = async (discountsList: IDiscounts[], baseListId: string) => {
    toggleEditDiscountsModal();
    if (currentLocal) {
      getProductsUseCase(currentLocal.id);
    }
    setDiscountsToEdit(discountsList);
  };

  const onDeleteDiscount = async (discountId: string): Promise<void> => {
    if (!currentLocal) return;
    try {
      showLoading()
      await DeleteDiscountUseCase(baseListService, discountId, currentLocal.id);
      // setDiscountsToEdit((prev) => prev?.filter((item) => item.id !== discountId));
      refreshList()
      // toggleDeleteDiscountsModal();
      toast('Item excluido com sucesso', 'success');
    } catch (error) {
      // throw error;
      toast('Falha ao excluir lista', 'error');
    } finally {
      hideLoading()
    }
  };

  const onAddDiscount = async (newValue: IDiscountProductListForm, baseListId: string): Promise<any> => {
    try {
      if (!currentLocal) return;
      const newDiscount: IAddDiscountToListRequest = {
        baseListId,
        productId: newValue.product.id,
        percentualDiscount:
          newValue.type === IDiscountProductType.percentual
            ? {
              percentual: newValue.discount,
            }
            : undefined,
        priceDiscount:
          newValue.type === IDiscountProductType.money
            ? {
              price: newValue.discount,
            }
            : undefined,
      };
      await AddDiscountToBaseListUseCase(baseListService, newDiscount, currentLocal.id);
      toast('Desconto adicionado com sucesso!', 'success');
      toggleEditDiscountsModal();
    } catch (error) {
      throw error;
    }
  };

  const handleEditDiscount =
    async (discount: IDiscountProductListForm) => {
      if (!currentLocal) return;
      const discountBaseListService = DiscountBaseListApi();
      const discountToEdit: IEditDiscountsRequest = {
        productId: discount.product.id,
        id: discount.id || '',
        discountValue: discount.discount,
        discountProductType: discount.type === IDiscountProductType.money ? DiscountProductTypeEnum.price : DiscountProductTypeEnum.percentual,
      };
      try {
        await EditBaseListDiscountUseCase(discountBaseListService, discountToEdit, currentLocal.id);
        toast('Desconto editado com sucesso!', 'success');
        toggleEditDiscountsModal();
        // refreshDiscountList();
      } catch (error) {
      }
    };

  return {
    deleteList,
    onClickEditDiscounts,
    toggleEditDiscountsModal,
    showEditDiscountsModal,
    productList,
    onDeleteDiscount,
    onAddDiscount,
    discountsToEdit,
    handleEditDiscount,
    toggleDeleteDiscountsModal,
    deleteConfirmationModal
  };
};

export default UseListCard;
