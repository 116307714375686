import { IEditSpecialDaysRequest } from "modules/schedule/domain/dto/placeSchedule/IEditUnavailabilityRequest";
import { PlaceScheduleApiFunction } from "../../interfaces/api/IPlaceScheduleApi";

export const PutEditUnavailabilityByServiceIdUseCase = async (request: IEditSpecialDaysRequest, placeId: string, placeScheduleApi: PlaceScheduleApiFunction) => {
    const _placeScheduleApi = placeScheduleApi();
    await _placeScheduleApi.editUnavailability(request, placeId);
    return request;
}


