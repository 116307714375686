import { ImportExport } from "@mui/icons-material";
import styles from "./MeepTicketsHeader.module.scss";
import { useEffect, useState } from "react";
import { IGetOrganizerEventParams } from "modules/meepTickets/domain/dto/IGetOrganizerEventParams";

export interface IMeepTicketsHeaderProps {
  getParams: IGetOrganizerEventParams;
  setGetParams: React.Dispatch<React.SetStateAction<IGetOrganizerEventParams>>;
}

export const ticketsTitleHeader = [
  {
    title: "Nome",
    value: "name",
  },
  {
    title: "Início",
    value: "startDate",
  },
  {
    title: "Fim",
    value: "endDate",
  },
  {
    title: "Local",
    value: "eventsLocation",
  },
  {
    title: "Status",
    value: "status",
  },
];

const MeepTicketsHeader = ({ getParams, setGetParams }: IMeepTicketsHeaderProps) => {
  const [selectedColumn, setsSlectedColumn] = useState("name");
  const [ordemColumnAsc, setOrdemColumnAsc] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    setGetParams({...getParams, sort: `${selectedColumn},${ordemColumnAsc}`});
  }, [selectedColumn, ordemColumnAsc]);

  return (
    <div id={styles.MeepTicketsHeader}>
      {ticketsTitleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            <div onClick={() => {
              setOrdemColumnAsc((prev) => prev === "asc" ? "desc" : "asc");
              setsSlectedColumn(item.value);
              }}
            >
              <ImportExport />
            </div>
          </div>          
        );
      })}
    </div>
  );
};
export default MeepTicketsHeader;
