import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import styles from "./ForgotPassword.module.scss";
import { UseForgotPassword } from "./UseForgotPassword";
import LayoutAuth from "../../components/layoutAuth/LayoutAuth";
import UseDimension from "components/dimension/UseDimension";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";

const ForgotPasswordPage = () => {
  const { goBackLogin, changeHandleForgotPassword, onSubmit, errors, setErrors } =
    UseForgotPassword();
  const { isMobile } = UseDimension();

  return (
    <LayoutAuth>
      <div id={styles.ForgotPassword}>
        <div className={styles.container}>
          {isMobile && (
            <img
              src="/assets/img/meep-primary.svg"
              alt=""
              className={styles.logoMobile}
            />
          )}
          <div className={styles.containerTitle}>
            <KeyboardArrowLeftRounded
              onClick={goBackLogin}
              style={{ cursor: "pointer" }}
            />
            <span>Recuperar senha</span>
          </div>
          <form className={styles.form} onSubmit={(ev) => onSubmit(ev)}>
            <div className={styles.input}>
              <label htmlFor="cpfOuEmail" className={styles.required}>
                CPF ou E-mail
              </label>
              <Input
                // required
                name={"cpfOuEmail"}
                variant={"outlined"}
                placeholder="Digite seu CPF ou e-mail"
                onChange={(ev) => {
                  setErrors("");
                  changeHandleForgotPassword(ev);
                }}
                error={!!errors}
                helperText={errors}
              />
            </div>
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button type="submit">
                  Recuperar
                </Button>
              </div>
              <div className={styles.button}>
                {!isMobile ? (
                  <Button variant={"outlined"} onClick={goBackLogin}>
                    Voltar
                  </Button>
                ) : (
                  <span onClick={goBackLogin} className={styles.backButton}>
                    Voltar
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutAuth>
  );
};
export default ForgotPasswordPage;
