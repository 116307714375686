import { ImportExport } from "@mui/icons-material";
import React, { FC, useCallback, useMemo } from "react";
import styles from "./LinkCardsItem.module.scss";
import { ILinkCardsColumnsConfig } from "../../interfaces/IColumnsConfig";
import { IParamsLinkCardsList } from "modules/linkCards/presentation/hooks/UseLinkCardsList";
import { Checkbox } from "@mui/material";
import { ILinkCardItem, ILinkCardsList } from "modules/linkCards/presentation/interfaces/ILinkCardsList";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

interface LinkCardsItemHeaderProps {
  columns: ILinkCardsColumnsConfig[],
  params: IParamsLinkCardsList;
  setParams: React.Dispatch<React.SetStateAction<IParamsLinkCardsList>>
  setSelectedLinkCards: React.Dispatch<React.SetStateAction<ILinkCardItem[]>>
  allSelected: boolean
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>
  linkCardsList: ILinkCardsList | undefined
}


const LinkCardsItemHeader: FC<LinkCardsItemHeaderProps> = ({ columns, setParams, setAllSelected, setSelectedLinkCards, allSelected, linkCardsList }) => {

  const { hasAccessRole } = useAuth();

  
  const onClickOrderColumn = useCallback((orderBy: string) => {
    setParams(prev => ({ ...prev, sort: { type: orderBy, orientation: prev?.sort?.orientation === 'asc' ? 'desc' : 'asc' }, pagination: { page: 0, pageSize: prev.pagination?.pageSize } }))

  }, [setParams]);

  const onToggleSelectAll = useCallback((checked: boolean) => {

    
    setAllSelected(checked);
  
    if (checked) {
      setSelectedLinkCards(linkCardsList?.records ?? []);
    } else {
      setSelectedLinkCards([]);
    }
  }, [linkCardsList?.records, setAllSelected, setSelectedLinkCards]);

const header = useMemo(() => 
  <>
     {hasAccessRole("PreAtivacaoCartoesCadastrar") && (           
             <div className={styles.checkButton} >
             <Checkbox
                   style={{ height: 40 }}
                   checked={allSelected}
                   onChange={(_, checked) => onToggleSelectAll(checked)}
               />
           </div>
        )} 
 
    { columns.map((column, index) => {
        return (
          !column.hide &&
          <div
            key={index}
            className={styles.row}
            style={column.style}
          >
              <span className={styles.order}>{column.title} <ImportExport onClick={() => onClickOrderColumn(column.property)} /> </span>
          </div>
        )
      }
    )}
  </>,  
 [hasAccessRole, allSelected, columns, onToggleSelectAll, onClickOrderColumn]
);

  return (
    <div id={styles.LinkCardsItemHeaderContainer}>
      <div className={styles.container}>      
        {header}
        <div className={styles.icon}></div>     
      </div>
    </div>
  );
};

export default LinkCardsItemHeader;
