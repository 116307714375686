import React, { FormEvent, useCallback, useState } from 'react'
import { IServiceScheduleForm } from '../serviceForm/interfaces/IServiceForm';
import { IProductListServiceScheduleForm } from './interfaces/IProductListServiceScheduleForm';

const UseServiceProductListForm = (onSubmit: (values: IProductListServiceScheduleForm) => void, defaultValues?: IProductListServiceScheduleForm) => {
   
    const [values, setValues] = useState<IProductListServiceScheduleForm>(defaultValues ?? {
        description: "",
    });

    const onChangeHandle = useCallback((name: string, value: string) => {
        setValues(prev => ({ ...prev, [name]: value }))
    }, []);

    const onSubmitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);
 

    return ({ values, onChangeHandle, onSubmitHandle })
}

export default UseServiceProductListForm
