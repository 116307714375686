import { useCallback, useEffect, useState } from "react";
import { usePlans } from "../../hooks/PlansContext";
import { IFeatures } from "../../interfaces/IFeatures";
import { FeatureTypeEnum } from "../../interfaces/FeatureTypeEnum";
import { IPlans } from "../../interfaces/IPlans";
import { ISubscription } from "../../interfaces/ISubscription";
import { GetDowngradeSolicitationsUseCase } from "modules/plans/application/GetDowngradeSolicitationsUseCase";
import PlansApi from "services/api/plans/PlansApi";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IDowngrade } from "../../interfaces/IDowngrade";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { RequestDowngradeUseCase } from "modules/plans/application/RequestDowngradeUseCase";
import { getRolesByFunctionUseCase } from "modules/cargos/application/useCases/getRolesByFunctionUseCase";
import CargosApi from "services/api/cargos/CargosApi";

const service = PlansApi();
const roleService = CargosApi();

export const UsePlans = () => {
    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const { plans, getPlans, getSubscription } = usePlans();

    const [isPremium, setIsPremium] = useState(false);
    const [features, setFeatures] = useState<IFeatures[]>([]);
    const [premiumPlan, setPremiumPlan] = useState<IPlans>({} as IPlans);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [downgradeRequest, setDowngradeRequest] = useState<IDowngrade>();
    const [downgradeRequestModalOpened, setDowngradeRequestModalOpened] = useState(false);
    const [downgradePermissionModalOpened, setDowngradePermissionModalOpened] = useState(false);
    const [downgradeRequestSuccessModalOpened, setDowngradeRequestSuccessModalOpened] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        if (currentLocal) {
            (async () => {
                const response = await GetDowngradeSolicitationsUseCase(service, currentLocal?.id);
                setDowngradeRequest(response.length ? response[0] : undefined);
            })();
        }
    }, [currentLocal]);

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    const response = await getRolesByFunctionUseCase(roleService, currentLocal.id);
                    setHasPermission(!!response.find(x => x.name.toLocaleLowerCase() === 'acessos' || x.name.toLocaleLowerCase() === 'admin'));
                } finally { }
            }
        })()
    }, [currentLocal]);

    useEffect(() => {
        (async () => {
            try {
                const promises: [Promise<IPlans[] | undefined>, Promise<ISubscription | undefined>] = [getPlans(), getSubscription()];
                const [plans, subscription] = await Promise.all(promises);
                let premiumPlan: IPlans | undefined = undefined;

                if (plans?.length) {
                    premiumPlan = plans.find(x => x.name === 'Plano Premium');
                    if (premiumPlan) setPremiumPlan(premiumPlan);
                }
                if (subscription) {
                    setIsPremium(!!premiumPlan && premiumPlan.id === subscription?.planId);
                    const filtered = subscription?.additionalFeatures?.filter(x => x.featureTypeValue === FeatureTypeEnum.PUSH || x.featureTypeValue === FeatureTypeEnum.EMAIL) || [];
                    setFeatures(filtered);
                }

                setLoading(false);
            } catch {
                setLoading(false);
            }
        })();
    }, [getPlans, getSubscription]);

    const requestDowngrade = useCallback(async () => {
        try {
            if (!currentLocal || !plans || !downgradeRequest) return;
            if (!hasPermission) {
                setDowngradePermissionModalOpened(true);
                setDowngradeRequestModalOpened(false);
            } else {
                setSaving(true);
                const getToken = localStorage.getItem("@token");
                const token = getToken && JSON.parse(getToken);
                const user = JSON.parse(token.user)

                await RequestDowngradeUseCase(service, currentLocal!.id, user.cpf, plans?.find(x => x.isDefault)!.id);
                setDowngradeRequestSuccessModalOpened(true);
                setDowngradeRequestModalOpened(false);
            }
        } catch {
            toast('Houve um erro ao solicitar o downgrade.', 'error');
        } finally {
            setSaving(false);
        }
    }, [currentLocal, hasPermission, plans, toast])

    return {
        isPremium,
        features,
        premiumPlan,
        loading,
        downgradeRequest,
        downgradeRequestModalOpened,
        downgradeRequestSuccessModalOpened,
        saving,
        downgradePermissionModalOpened,
        setDowngradePermissionModalOpened,
        setDowngradeRequestModalOpened,
        setDowngradeRequestSuccessModalOpened,
        requestDowngrade
    }
}