import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
} from "@material-ui/core";
import { Autocomplete, Button, CircularProgress, Dialog, Icon, TextField } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import React, { FC, useCallback,  useMemo, useRef, useState } from "react";
import { IKDSItem, IKDSItemStep, KDSStatusStep } from "./IKDSItem";
import styles from "./KDSItem.module.scss";
import {
  MoreVertOutlined,
  Moped,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import KDSSideSheetOrder from "../kDSSideSheetOrder/KDSSideSheetOrder";
import KDSTimer from "../KDSTimer/KDSTimer";
import AlertButton from "modules/config/deviceConfig/presentation/components/alertButton/AlertButton";
import { IIssueNFCe } from "../../interfaces/IIssueNFE";
import { INFCeResponse } from "modules/kds/domain/dto/nfe/NFCeResponse";
import { moneyMaskNumber } from "services/utils/Money";
import Whatsapp from "res/assets/images/whatsapp.png";
import Utils from "services/utils/Utils";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import UseSocketPrinter from "modules/printer/presentation/hooks/UseSocketPrinter";
import { LayoutPrinter } from "modules/printer/presentation/layoutPrinter/invoice/resumoLayoutPrinter/LayoutPrinter";
import Sidesheet from "components/sidesheet/Sidesheet";
import { IPrinter } from "../../interfaces/IPrinter";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import InvoicePrinterLayout from "modules/printer/presentation/layoutPrinter/invoice/InvoicePrinterLayout";
import QRCode from "qrcode"

export enum KDSStepAction {
  Accept = `acceptOrder`,
  Refuse = `refuseOrder`,
  Start = `startOrder`,
  Transport = `deliverOrder`,
  Refund = `returnedOrder`,
  Conclude = `receivedOrder`,
}
export interface IKDSItemProps {
  kdsItem: IKDSItem;
  onClickAction?: (
    action: KDSStepAction,
    item: IKDSItem
  ) => Promise<IKDSItemStep>;
  onClickPrint?: (item: IKDSItem) => Promise<void>;
  postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>;
  issueNFCe: INFCeResponse[];
  summaryView: boolean;
  setSummaryView: React.Dispatch<React.SetStateAction<boolean>>;
  printerList: IPrinter[] | undefined
}

const KDSItem: FC<IKDSItemProps> = ({
  kdsItem,
  onClickAction,
  onClickPrint,
  postIssueNfe,
  issueNFCe,
  summaryView,
  printerList
}) => {
  const [item, setItem] = useState<IKDSItem>(kdsItem);
  
  const password = useRef('');
  
  
  const [isLoading, setIsLoading] = useState(false);

  const [openAlertReceivedOrder, setOpenAlertReceivedOrder] = useState(false);
  
  const [openDrawer, setOpenDrawer] = useState(false);
  
  const [openDrownSelectedPrinter, setOpenDrownSelectedDelete] = useState(false)

  const [selectedPrinter, setSelectedPrinter] = useState<IPrinter | null>(null);

  const [error, setError] = useState('');

  const isPrinterOrder = useRef<boolean>(false);


  const [step, setStep] = useState(1);



  const { hasAccessRole } = useAuth();
  
  const [refuseModal, setRefuseModal] = useState(false);
  
  const [giveBackModal, setGiveBackModal] = useState(false);


  const {showLoading, hideLoading, toast} = useUi()
  const {sentToPrinter, getElementHtml, isLoadingSocketPrinter} = UseSocketPrinter();
  

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    password.current = value;
  };
  const onClose = useCallback(() => {  
    setStep(1)
    setOpenDrownSelectedDelete(false)
    isPrinterOrder.current = false;
    setError('')
    setSelectedPrinter(null)
  }, [])

  const onClickActionHandle = useCallback(
    async (action: KDSStepAction, item: IKDSItem) => {
      if(action == 'refuseOrder' && password.current == ""){
        toast("A senha é obrigatória", 'error');
        return;
      }
      if (onClickAction) {
        try {
          setIsLoading(true);
          const response: IKDSItemStep = await onClickAction(action, {...item, password:password.current} );
          setItem((prev) => ({ ...prev, currentStep: response }));
        } finally {
          setRefuseModal(false);
          setIsLoading(false);
        }
      }
    },
    [onClickAction, item]
  );
  const closeAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(false);
  }, []);

  const openAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(true);
  }, []);

  const confirmAlertReceivedHandle = useCallback(() => {
    onClickActionHandle?.(KDSStepAction.Conclude, item);
    setOpenAlertReceivedOrder(false);
  }, [item, onClickActionHandle]);

  const closeRefuseModal = useCallback(() => {
    setRefuseModal(false);
    password.current = "";
    postIssueNfe({ pedidoId: "", documento: "" });
  }, [postIssueNfe]);

  const openRefuseModal = useCallback(() => {
    password.current = "";
    setRefuseModal(true); 
  }, []);

  const closeGiveBackModal = useCallback(() => {
    setGiveBackModal(false);
  }, []);

  const openGiveBackModal = useCallback(() => {
    setGiveBackModal(true);
  }, []);

  const openDrawerOrder = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const closeDrawerOrder = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const changeSelectedPrinter = useCallback((printer: IPrinter | null) => {
    setSelectedPrinter(printer);

    if (printerList?.some(item => item.name === printer?.name)) {
      setError('');
    }
}, [printerList]);

  const closedOrPrinterButton = useCallback(() => {
    if (step === 1) {
      return "Imprimir"
    } else {
      return "Fechar"
    }
  }, [step])

  const generateQR = async (text: any) => {
    return await QRCode.toDataURL(text, { width: 500 })
}

 
  const sentInvoiceToPrinterNFC = useCallback(async (nfc: INFCeResponse, qrCode?: string) => {
    try {
        showLoading();    
            const reactElement = <InvoicePrinterLayout invoice={nfc} qrCode={qrCode} />;

            const html = await getElementHtml(reactElement);
          
           const res =  await sentToPrinter(
                item.id,
                selectedPrinter?.name || printerList?.[0]?.name || '',
                html
              );       
            if (res === "ok") {       
                return setStep(step + 1);              
            } else {
              onClose();     
            }
              
    } catch {
        toast("Erro ao imprimir cupom fiscal", 'error')
        throw new Error("Erro ao imprimir nota fiscal");
    } finally {
        hideLoading();
    }
},[getElementHtml, hideLoading, item.id, onClose, printerList, selectedPrinter?.name, sentToPrinter, showLoading, step, toast]); 


const continueOrSaveNFCe = useCallback(async () => {
  if (!selectedPrinter || !printerList?.some(printer => printer.name === selectedPrinter.name)) {
    setError('Selecione uma impressora para imprimir o pedido');
    return;
  } else {

    try {
      const response = await postIssueNfe({ pedidoId: item.id, documento: item.customer?.document })     
      const qrCode = await generateQR(response?.[0].sefaz["url-danfe"])
      
      if (response && response.length > 0) {      
        
        await sentInvoiceToPrinterNFC(response[0], qrCode);                 
          
      } else {
          console.error('Nenhuma nota encontrada para enviar à impressora.');
      }
      toast("Impressão realizada com sucesso", "success")
      
    } catch (err) {
      toast("Falha em imprimir cupom fiscal", "error")
    }
  } 
}, [item.customer?.document, item.id, postIssueNfe, printerList, selectedPrinter, sentInvoiceToPrinterNFC, toast])


const continueOrSave = useCallback(async () => {
  if (!selectedPrinter || !printerList?.some(printer => printer.name === selectedPrinter.name)) {
    setError('Selecione uma impressora para imprimir o pedido');
    return;
  } else if (step === 1) {
    const reactElement = <LayoutPrinter ticketToPrint={kdsItem} />;
    const html = await getElementHtml(reactElement);
    const res = await sentToPrinter(item.id, selectedPrinter?.name || printerList?.[0]?.name || '', html);
    if (res === "ok") {       
      return setStep(step + 1);
    }
  } else {
    onClose();     
  }
}, [getElementHtml, item.id, kdsItem, onClose, printerList, selectedPrinter, sentToPrinter, step])


  const sentInvoiceToPrinter = useCallback(async (kdsItem: IKDSItem) => {
    try {
        showLoading();
            const reactElement = <LayoutPrinter ticketToPrint={kdsItem} />;
            const html = await getElementHtml(reactElement);
            await sentToPrinter(item.id, selectedPrinter?.name || printerList?.[0]?.name || '', html);
       
    } catch {
        throw new Error("Erro ao imprimir nota fiscal");
    } finally {
        hideLoading();
    }
},[getElementHtml, hideLoading, item.id, printerList, selectedPrinter?.name, sentToPrinter, showLoading]); 

  const onClickPrintHandle = useCallback(async () => {
    try {
      await sentInvoiceToPrinter(kdsItem);

    } finally {
    }
  }, [kdsItem, sentInvoiceToPrinter]);  


const colorsStatus = (nameStep: IKDSItemStep) => {
    switch (nameStep.name) {
      case KDSStatusStep.PENDING:
        return `#ED0029`;
      case KDSStatusStep.ACCEPTED:
        return `#6C2EE0`;
      case KDSStatusStep.FINISHED:
        return `#399C54`;
      case KDSStatusStep.PREPARING:
        return `#FF5C78`;
      case KDSStatusStep.TRANSPORT:
        return `#F44336`;
      case KDSStatusStep.DELIVERED:
        return `#399C54`;
      case KDSStatusStep.REFUSED:
        return `#BDBDBD`;
      default:
        return `#333333`;
    }
  };

  const buttons = useMemo(() => {
    if (item.currentStep.name)
      switch (item.currentStep.name) {
        case KDSStatusStep.PENDING:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.recuse}
                disabled={isLoading}
                onClick={openRefuseModal}
              >
                Recusar
              </Button>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Accept, item)
                }
              >
                Aceitar pedido
              </Button>
            </div>
          );
        case KDSStatusStep.ACCEPTED:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() => onClickActionHandle?.(KDSStepAction.Start, item)}
              >
                Iniciar
              </Button>
            </div>
          );
        case KDSStatusStep.PREPARING:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Transport, item)
                }
              >
                Despachar pedido <Moped style={{ marginLeft: 10 }} />
              </Button>
            </div>
          );
        case KDSStatusStep.TRANSPORT:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.recuse}
                disabled={isLoading}
                onClick={openGiveBackModal}
              >
                Devolver
              </Button>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={openAlertReceivedHandle}
              >
                Finalizar
              </Button>
            </div>
          );
        default:
          return null;
      }
  }, [
    isLoading,
    item,
    onClickActionHandle,
    openAlertReceivedHandle,
    openGiveBackModal,
    openRefuseModal,
  ]);

  const distanceFromNow = useMemo(
    () => formatDistanceToNow(new Date(item.createdAt), { locale: ptBR }),
    [item]
  );

  const handleOpenWhatsapp = (phone: string) => {
    window.open(`https://api.whatsapp.com/send?phone=55${phone}`);
  };

  const handleContent = useCallback(() => {   
     
    const message = "Selecione uma impressora para imprimir o pedido";    

    return (
      <div className={styles.contentSideSheetPrinter}>
        <div>
          <p><InfoOutlined/> {message}</p>
        </div>
        
          <Autocomplete
              fullWidth
              componentName="printer"              
              options={printerList ?? []}
              value={selectedPrinter}
              size="small"
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <>
                  <label htmlFor="printer" className={styles.required}>Impressora <span style={{color: 'red'}}>*</span></label>
                  <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      required
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: "2px 35px 4px 10px"},                        
                      }}
                      error={!!error}
                      helperText={error ?? error}
                      />
                  </>
              )}
              onChange={(_, value) => changeSelectedPrinter(value)}
              disablePortal={false}
          />
        </div>
    )
  }, [changeSelectedPrinter, error, printerList, selectedPrinter])

  const itemContent = useMemo(
    () => (
      <>
        <div>
          {isLoading && (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
        {item.panelCode > 1 && (
          <div className={styles.senha}>
            <span>Senha: <b>{item.panelCode.toString().padStart(4, '0')}</b></span>
          </div>
        )}
        <div
          className={styles.header}
          style={{ backgroundColor: colorsStatus(item?.currentStep) }}
        >
          <div className={styles.headerTop}>
            <span className={styles.code}> {item.code}</span>

            <div className={styles.more}>
              {item.orderOrigin === 2 ? (
                <img src="/assets/img/ifood.png" alt="Logo ifood" />
              ) : (
                item.table && (
                  <>
                    <span className={styles.table}>
                      Mesa: <span>{item.table}</span>
                    </span>
                    <span>{item.customer?.name}</span>
                  </>
                )
              )}
              {!openDrawer ? (
                <IconButton
                  size="small"
                  style={{ color: "#fff" }}
                  onClick={() => openDrawerOrder()}
                >
                  <MoreVertOutlined />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  style={{ color: "#fff" }}
                  onClick={() => closeDrawerOrder()}
                >
                  <Icon>close</Icon>
                </IconButton>
              )}
            </div>
          </div>
          <div className={styles.summaryView}>
            {summaryView && (
              <>
                <div className={styles.topSummary}>
                  {item.customer?.phoneNumber && (
                    <img
                      src={Whatsapp}
                      alt="Ícone whatsapp"
                      className={styles.linkItem}
                      onClick={() =>
                        handleOpenWhatsapp(item.customer?.phoneNumber ?? "")
                      }
                    />
                  )}
                  <span>{item.customer?.name && item.customer?.name}</span>
                </div>
                {item.deviceName && <span>Equipamento: {item.deviceName}</span>}
              </>
            )}
            <div className={styles.timerContainer}>
              {summaryView && (
                <span>({Utils.toDateAndTime(item.createdAt)})</span>
              )}
              <div className={styles.headerFooter}>
                {item.currentStep.name !== "Entregue" &&
                  item.currentStep.name !== "Recusado" &&
                  item.currentStep.name !== "Devolvido" ? (
                  <KDSTimer startDate={new Date(item.createdAt)} />
                ) : !summaryView ? (
                  <div style={{ fontSize: 12, opacity: 0.8 }}>
                    {new Date(item.createdAt).toLocaleString()}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className={styles.headerMain}>
            <span style={{ color: colorsStatus(item?.currentStep) }}>
              {" "}
              {item.currentStep.name}
            </span>
          </div>
        </div>
        <div className={styles.content}>
          {item.observation && (
            <div className={styles.observation}>
              <span>{item.observation}</span>
            </div>
          )}

          <div className={styles.order}>
            <div className={styles.list}>
              {item.order.items.map((orderItem, index) => {
                let showAdicionaisTag = true;
                if (
                  orderItem.compositions &&
                  orderItem.compositions.length === 1 &&
                  orderItem.compositions[0].description === "Observação"
                ) {
                  showAdicionaisTag = false;
                }
                return (
                  <div key={index} className={styles.orderItem}>
                    <span className={styles.categoryItem}>
                      {orderItem.nameCategory}
                    </span>
                    <div className={styles.top}>
                      <span>
                        {orderItem.quantity}x {orderItem.name}
                      </span>
                      {hasAccessRole("KDSVisualizarValores") && (
                        <span className={styles.value}>
                          {moneyMaskNumber(orderItem.value)}
                        </span>
                      )}
                    </div>
                    {orderItem.compositions
                      ?.filter((item) => !item.options.length)
                      .map(
                        (compositionItem, index) =>
                          !!compositionItem.description && (
                            <div className={styles.compositionItem} key={index}>
                              {index === 0 && (
                                <p className={styles.textObservation}>
                                  Observação:
                                </p>
                              )}
                              <span className={styles.observationComposition}>
                                {compositionItem.description}
                              </span>
                            </div>
                          )
                      )}
                    {!!orderItem.compositions?.length && (
                      <div className={styles.compositions}>
                        {orderItem.compositions.map(
                          (compositionItem, index) => (
                            <>
                              <div className={styles.compositionItem}>
                                {index === 0 && showAdicionaisTag && (
                                  <p className={styles.additional}>
                                    Adicionais:
                                  </p>
                                )}
                                <span className={styles.observationComposition}>
                                  {compositionItem.description}
                                </span>
                                {compositionItem.options.length > 0 && (
                                  <div
                                    className={styles.compositionItem}
                                    key={index}
                                  >
                                    <span>
                                      {compositionItem.options.map(
                                        (option, index) => (
                                          <ul>
                                            <li key={index}> - {option}</li>
                                          </ul>
                                        )
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {hasAccessRole("KDSVisualizarValores") && (
          <div className={styles.total}>
            <label>Valor</label>
            <span>{moneyMaskNumber(item.amount)}</span>
          </div>
        )}
        {!!buttons ? (
          <div className={styles.footer}>{buttons}</div>
        ) : (
          <div className={styles.footer}></div>
        )}
      </>
    ),
    [
      buttons,
      closeDrawerOrder,
      hasAccessRole,
      isLoading,
      item.amount,
      item.code,
      item.createdAt,
      item.currentStep,
      item.customer?.name,
      item.customer?.phoneNumber,
      item.deviceName,
      item.observation,
      item.order.items,
      item.orderOrigin,
      item.table,
      openDrawer,
      openDrawerOrder,
      summaryView,
    ]
  );

  return (
    <>
      <div id={styles.KDSItem}>
        {itemContent}
        <Dialog
          open={openAlertReceivedOrder}
          onClose={closeAlertReceivedHandle}
        >
          <DialogTitle>Confirmar entrega do pedido</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ao confirmar essa ação não pode ser desfeita
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertReceivedHandle}>Cancelar</Button>
            <Button onClick={confirmAlertReceivedHandle}>Confirmar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <div id={styles.drawer}>
          <KDSSideSheetOrder
            colorsStatus={colorsStatus}
            kdsItem={item}
            postIssueNfe={postIssueNfe}
            onClickActionHandle={onClickActionHandle}
            onClickPrintHandle={onClickPrintHandle}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            openAlertReceivedHandle={openAlertReceivedHandle}
            isLoading={isLoading}
            openRefuseModal={openRefuseModal}
            openGiveBackModal={openGiveBackModal}
            issueNFCe={issueNFCe}
            setOpenDrownSelectedDelete={setOpenDrownSelectedDelete}
            printerList={printerList}
            selectedPrinter={selectedPrinter}
            sentInvoiceToPrinterNFC={sentInvoiceToPrinterNFC}
            isPrinterOrder={isPrinterOrder}
          />
        </div>
      </Drawer>

      <Drawer anchor="right" open={!!giveBackModal}>
        <div id={styles.drawer}>
          <div className={styles.containerModal}>
            <div className={styles.containerHeader}>
              <div className={styles.title}>
                <h2>
                  Devolver <span>pedido</span>
                </h2>
                <Close onClick={closeGiveBackModal} />
              </div>

              <AlertButton
                type="warnning"
                label={`Deseja realmente devolver o pedido ${item.code}?`}
              />
            </div>
            <div className={styles.containerButtons}>
              <Button
                fullWidth={false}
                onClick={closeGiveBackModal}
                variant="outlined"
                className={styles.cancel}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Refund, item)
                }
                variant="contained"
                className={styles.action}
              >
                Devolver pedido
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer anchor="right" open={!!refuseModal}>
  <div id={styles.drawer}>
    <div className={styles.containerModal}>
      <div className={styles.containerHeader}>
        <div className={styles.title}>
          <h2>
            Recusar <span>pedido</span>
          </h2>
          <Close onClick={closeRefuseModal} />
        </div>

        <AlertButton
          type="warnning"
          label={`Deseja realmente recusar o pedido ${item.code}? \nPor favor, digite sua senha para confirmar.`}
        />
      <div className={styles.content}>
        <TextField
          label="Senha"
          type="password"
          onChange={handlePasswordChange}
          variant="outlined"
          fullWidth
          required
          className={styles.inputPassword}
          style={{
            marginTop: '20px', 
          }}
        />
      </div>

      </div>  
      <div className={styles.containerButtons}>
        <Button
          fullWidth={false}
          onClick={closeRefuseModal}
          variant="outlined"
          className={styles.cancel}
        >
          Cancelar
        </Button>
        <Button
          fullWidth={false}
          onClick={() => onClickActionHandle?.(KDSStepAction.Refuse, item)}
          variant="contained"
          className={styles.action}
          disabled={isLoading}
        >
          Recusar pedido
        </Button>
      </div>
    </div>
  </div>
</Drawer>


      <Sidesheet
        isLoading={isLoadingSocketPrinter}
        open={openDrownSelectedPrinter}
        onClose={onClose}
        title={
          <h2>
            Selecionar <b>Impressora</b>
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        handleContinueButton={() => {isPrinterOrder.current ? continueOrSave() :  continueOrSaveNFCe()}} 
        continueButton={closedOrPrinterButton()}
        cancelButton
        feedback={
          <SidesheetFeedback text="Impresso com sucesso!" success />
        }
      />
    </>
  );
};
export default KDSItem;
