import ICustomersDashboardService from "modules/customersDashboard/domain/interfaces/ICustomersDashboardService"
import { IGetAverageTicketPerHourResponse } from "modules/customersDashboard/domain/dto/averageTicketPerHour/AverageTicketPerHour"
import { IGetCheckinPerHourDataResponse } from "modules/customersDashboard/domain/dto/checkinPerHour/CheckinPerHourDataResponse"
import { IGetClientsByRegionResponse } from "modules/customersDashboard/domain/dto/clientsByRegion/IGetClientsByRegionResponse"
import { IGetUtlizationCouponResponse } from "modules/customersDashboard/domain/dto/couponUtlization/IGetUtilizationCouponResponse"
import { IGetEventListResponse } from "modules/customersDashboard/domain/dto/eventList/IGetEventListResponse"
import { ICustomerDashboardFilter } from "modules/customersDashboard/domain/dto/FilterRequest.type"
import { IGetGuestListResponse } from "modules/customersDashboard/domain/dto/guestList/IGuestListResponse"
import { IGetEventListRequest } from "modules/customersDashboard/domain/dto/eventList/IGetEventListRequest"
import { api } from "../Api"
import { getUtilizationCouponMocks, getProductsRakingMocks, getCanceledProductsRakingMocks, getLastOrderRakingMocks } from "./CustomersDashboardServiceMock"
import { IGetCheckiTableResponse } from "modules/customersDashboard/domain/dto/checkinTable/IGetCheckiTableResponse"
import { IGetPermanencyTableResponse } from "modules/customersDashboard/domain/dto/permanecyTable/GetPermanencyTableResponse"
import { IGetAverageTicketConsumeResponse } from "modules/customersDashboard/domain/dto/averageTicketConsume/GetAverageTicketConsumeResponse"
import { IGetCustomerListResponse } from "modules/customersDashboard/domain/dto/customerList/IGetCustomerListResponse"
import { IGetAverageTicketIngressConsumeResponse } from "modules/customersDashboard/domain/dto/averageTicketIngressConsume/GetAverageTicketIngressConsumeResponse"
import { IGetProductRankingResponse } from "modules/customersDashboard/domain/dto/rankingProducts/IGetProductRankingResponse"
import { IGetCanceledProductRankingResponse } from "modules/customersDashboard/domain/dto/rankingProducts/IGetCanceledProductRankingResponse"
import AntiCorruptionDashboarCustomer from "./antiCorruptionDashboardCustomers/AntiCorruptionDashboarCustomer"
import { IGetVouchersResponse } from "modules/customersDashboard/domain/dto/vouchers/IGetVouchersResponse"
import { IGetRankingLastOrderResponse } from "modules/customersDashboard/domain/dto/rankingLastOrder/GetRankingLastOrderResponse"
import { IGetProductsCanceledByOperatorResponse } from "modules/customersDashboard/domain/dto/rankingProducts/IGetProductsCanceledByOperatorResponse"

const CustomersDashboardService = (): ICustomersDashboardService => {

    // const getAverageResume = async (request: ICustomerDashboardFilter): Promise<IGetLiveAverageResumeResponse> => {
    //     const response = await api.get<IGetLiveAverageResumeResponse>("/dashboard/v2/AverageConsume", { params: request })
    //     return response.data
    // }

    // const getAverageTicketPerAge = async (request: ICustomerDashboardFilter): Promise<IGetAverageTicketPerAgeResponse> => {
    //     throw (new Error("Não implementado"));
    // }

    // const getCustomersRaking = async (request: ICustomerDashboardFilter): Promise<IGetCustomersRankingResponse> => {
    //     const response = await api.get<IGetCustomersRankingResponse>("/dashboard/v2/CustomerRanking", { params: request })
    //     return response.data
    // }

    // const getLastOrderRaking = async (request: ICustomerDashboardFilter): Promise<IGetLastOrderRankingResponse> => {
    //     const response = await api.get<IGetLastOrderRankingResponse>("/dashboard/v2/LastOrderRanking", { params: request })
    //     return response.data
    // }

    const mountSortQuery = (sort?: {
        type?: string | undefined;
        orientation?: string | undefined;
    }) => {
        let query = Object.keys(sort || {}).length ? "?" : '';
        query += sort?.type ? `&sort.type=${sort?.type}` : '';
        query += sort?.orientation ? `&sort.orientation=${sort?.orientation}` : '';

        return query;
    }

    const getClientsByRegion = async (request: ICustomerDashboardFilter): Promise<IGetClientsByRegionResponse> => {
        const response = await api.get('', { params: request })
        return response.data
    }
    //
    const getEventList = async (request: IGetEventListRequest): Promise<IGetEventListResponse> => {
        const response = await api.get<IGetEventListResponse>("/SuperApp/Event/Dashboards/Operations/Search", { params: { ...request, keyword: "", page: 0, pageSize: 100 } })
        return response.data
        // const response = await AntiCorruptionDashboarCustomer(api).getEventList(request)
        // return response
        // return await getEventListMock(request);
    }
    const getCheckinTable = async (request: ICustomerDashboardFilter): Promise<IGetCheckiTableResponse> => {
        const response = await api.get<IGetCheckiTableResponse>("/SuperApp/Event/Dashboards/Operations/CheckinCustomers", { params: request })
        return response.data

        // return await getCheckinTableMock(request);
    }

    const getVouchers = async (request: ICustomerDashboardFilter): Promise<IGetVouchersResponse> => {
        const response = await api.get<IGetVouchersResponse>("/SuperApp/Event/Dashboards/Operations/CustomerVouchers", { params: request })
        return response.data

        // return await getVouchersMock(request);
    }

    const getCheckinPerHour = async (request: ICustomerDashboardFilter): Promise<IGetCheckinPerHourDataResponse> => {
        const response = await api.get<IGetCheckinPerHourDataResponse>("/SuperApp/Event/Dashboards/Operations/CheckinPerHour", { params: request })
        return response.data

        // return await getCheckinPerHourMock(request);
    }
    const getPermancyTable = async (request: ICustomerDashboardFilter): Promise<IGetPermanencyTableResponse> => {
        const response = await api.get<IGetPermanencyTableResponse>("/SuperApp/Event/Dashboards/Operations/AverageCustomerTime", { params: request })
        return response.data

        // return await getPermancyTableMock(request);
    }
    const getAverageTicketConsume = async (request: ICustomerDashboardFilter): Promise<IGetAverageTicketConsumeResponse> => {
        const response = await api.get<IGetAverageTicketConsumeResponse>("/SuperApp/Event/Dashboards/Operations/AverageCustomerConsumption", { params: request })
        return response.data

        // return await getAverageTicketConsumeMock(request); 
    }
    const getGuestList = async ({ eventId, localId, page, pageSize, guestsFilter, sort, ...rest }: ICustomerDashboardFilter): Promise<IGetGuestListResponse> => {
        const response = await api.get<IGetGuestListResponse>(`/SuperApp/Event/Dashboards/Operations/GuestsList${mountSortQuery(sort)}`, { params: { eventId, localId, page, pageSize, guestsFilter, ...rest } })
        return response.data

        // return await getGuestListMocks(request);
        // const response = await api.get<IGetGuestListResponse>("/dashboard/v2/GuestsConversion", { params: request })
        // return response.data
    }

    const getCustomersList = async ({ eventId, localId, page, pageSize, customersFilter, sort, ...rest }: ICustomerDashboardFilter): Promise<IGetCustomerListResponse> => {
        const response = await api.get<IGetCustomerListResponse>(`/SuperApp/Event/Dashboards/Operations/CustomersList${mountSortQuery(sort)}`, { params: { eventId, localId, page, pageSize, customersFilter, ...rest } })
        return response.data

        // return await getCustomersListMock(request);
    }

    const getUtilizationCoupon = async (request: ICustomerDashboardFilter): Promise<IGetUtlizationCouponResponse> => {
        // const response = await api.get<IGetUtlizationCouponResponse>("/dashboard/v2/CouponUtilization", { params: request })
        // return response.data
        return getUtilizationCouponMocks(request)
    }

    const getProductsRaking = async (request: ICustomerDashboardFilter): Promise<IGetProductRankingResponse> => {
        var _request = {
            ...request, "sort.type": request.sort?.type, "sort.orientation": request.sort?.orientation, sort: undefined, topProductsFilter: request.operation
        }
        delete _request.sort
        const response = await api.get<IGetProductRankingResponse>("/SuperApp/Event/Dashboards/Operations/TopProducts", { params: { ..._request, topProductsFilter: request.operation } })
        return response.data
        // return getProductsRakingMocks(request)
    }
    const getCanceledProductsRaking = async (request: ICustomerDashboardFilter): Promise<IGetCanceledProductRankingResponse> => {
        const response = await api.get<IGetCanceledProductRankingResponse>("/SuperApp/Event/Dashboards/Operations/OrderCancellations", { params: { ...request, "sort.type": request.sort?.type, "sort.orientation": request.sort?.orientation, sort: undefined, orderCancellationsFilter: request.operation } })
        return response.data
        // return getCanceledProductsRakingMocks(request)
    }
    const getProductsCanceledByOperator = async (request: ICustomerDashboardFilter): Promise<IGetProductsCanceledByOperatorResponse> => {
        const response = await api.get<IGetProductsCanceledByOperatorResponse>("/SuperApp/Event/Dashboards/Operations/OrderCancellationsByOperator", { params: { ...request, cashierId: request.cashierId, "sort.type": request.sort?.type, "sort.orientation": request.sort?.orientation, sort: undefined, OrderCancellationsFilter: request.operation } })
        return response.data
    }
    const getLastOrderProductsRaking = async (request: ICustomerDashboardFilter): Promise<IGetRankingLastOrderResponse> => {
        const response = await api.get<IGetRankingLastOrderResponse>("/SuperApp/Event/Dashboards/Operations/GuestsProductsConsumption", { params: { ...request, "sort.type": request.sort?.type, "sort.orientation": request.sort?.orientation, sort: undefined, guestsProductsFilter: request.operation } })
        return response.data
        // return getLastOrderRakingMocks(request)
    }

    return ({
        getEventList,
        getCheckinTable,
        getVouchers,
        getPermancyTable,
        getAverageTicketConsume,
        getGuestList,
        getCustomersList,
        getCheckinPerHour,
        getUtilizationCoupon,
        getClientsByRegion,
        getProductsRaking,
        getCanceledProductsRaking,
        getLastOrderProductsRaking,
        getProductsCanceledByOperator,
        //
        // getAverageResume,
        // getAverageTicketPerAge,
        // getProductsRaking,
        // getCustomersRaking,
        // getLastOrderRaking,
        // getClientsByRegion
    })
}
export default CustomersDashboardService