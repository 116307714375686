import { IEventsForm } from "modules/events/domain/models/IEvents";
import Utils from "services/utils/Utils";
import { IEventsFormErrors } from "../interfaces/IEventsForm";

export const validate = (formData: IEventsForm, setErrors: React.Dispatch<React.SetStateAction<IEventsFormErrors>>) => {
  let _errors: IEventsFormErrors = {};
  let hasError = false;
  if (!formData.name) {
    _errors = { ..._errors, name: "Insira um nome para o evento" };
    hasError = true;
  }
  if (!formData.imageUrl) {
    _errors = { ..._errors, imageUrl: "Insira uma imagem para o evento" };
    hasError = true;
  }
  if (!formData.startDateString) {
    _errors = { ..._errors, startDateString: "Insira a data inicial do evento" };
    hasError = true;
  }
  if (
    formData.startDateString &&
    formData.endDateString &&
    !Utils.dateValidation(formData.startDateString, "<", formData.endDateString)
  ) {
    _errors = { ..._errors, startDateString: "Data e hora inicial deve ser menor que a final" };
    hasError = true;
  }
  if (!formData.endDateString) {
    _errors = { ..._errors, endDateString: "Insira a data final do evento" };
    hasError = true;
  }
  if (
    formData.startDateString &&
    formData.endDateString &&
    !Utils.dateValidation(formData.endDateString, ">", formData.startDateString)
  ) {
    _errors = { ..._errors, endDateString: "Data e hora final deve ser maior que a inicial" };
    hasError = true;
  }
  if (!formData.startSaleDate) {
    _errors = { ..._errors, startSaleDate: "Insira a data de início das vendas" };
    hasError = true;
  }
  if (
    formData.startSaleDate &&
    formData.endSaleDate &&
    !Utils.dateValidation(formData.startSaleDate, "<", formData.endSaleDate)
  ) {
    _errors = { ..._errors, startSaleDate: "Insira a data de início das vendas" };
    hasError = true;
  }
  if (!formData.endSaleDate) {
    _errors = { ..._errors, endSaleDate: "Insira a data final das vendas" };
    hasError = true;
  }
  if (
    formData.startSaleDate &&
    formData.endSaleDate &&
    !Utils.dateValidation(formData.endSaleDate, ">", formData.startSaleDate)
  ) {
    _errors = { ..._errors, endSaleDate: "Insira a data final das vendas" };
    hasError = true;
  }
  setErrors(_errors);
  return !hasError;
};
