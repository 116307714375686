import UseDimension from "components/dimension/UseDimension";
import styles from "components/sidesheet/Sidesheet.module.scss";

const DeleteCollaborator = () => {
  const { isMobile } = UseDimension();

  return (
    <>
      <div
        className={`${styles.successContent} ${
          isMobile && styles.successContentMobile
        }`}
      >
        <img src={"/assets/icon/warning-icon.svg"} alt="Aviso" />
        <div className={styles.successText}>
          <span>Essa ação não poderá ser desfeita!</span>
        </div>
      </div>
    </>
  );
};

export default DeleteCollaborator;
