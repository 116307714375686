import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./ShowPassword.module.scss";
import { IconButton } from "@mui/material";

export interface IShowPasswordProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowPassword = ({
  showPassword,
  setShowPassword,
}: IShowPasswordProps) => {
  return (
    <IconButton className={styles.showPassword} onClick={() => setShowPassword(!showPassword)}>
      {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
    </IconButton>
  );
};

export default ShowPassword;
