import { Icon } from '@material-ui/core';
import styles from './SaasAlert.module.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { PlanStatus } from 'modules/saas/domain/interfaces/PlanStatus';
import { UseSaasAlert } from './UseSaasAlert';
import SimpleTrialModal from 'modules/plans/presentation/components/simpleTrialModal/SimpleTrialModal';

export const SaasAlert = () => {
    const [isBlocked, setIsBlocked] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isToShow, setIsToShow] = useState(false);
    const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);
    const [cieloEvent, setCieloEvent] = useState(false);

    const history = useHistory();
    const { subscription } = usePlans();
    const { localDocument } = UseSaasAlert();

    useEffect(() => {
        setIsToShow(false);
        if (!subscription) return;
        if (subscription?.planName === "Evento" && subscription?.status?.id === PlanStatus.ACTIVE) {
            setCieloEvent(true);
            return setIsToShow(true);
        }
        if (subscription?.isTrial && subscription?.status?.id === PlanStatus.ACTIVE) {
            setIsTrial(true);
            return setIsToShow(true);
        }
        if (subscription?.status?.id === PlanStatus.PENDING) {
            return setIsToShow(true);
        }
        if (subscription?.status?.id !== PlanStatus.ACTIVE) {
            setIsBlocked(true);
            return setIsToShow(true);
        }
    }, [subscription, subscription?.isTrial, subscription?.status]);

    const navigatePayment = () => {
        if (subscription?.isTrial && localDocument === "28302604000101") {
            return setOpenSimpleTrialModal(true);
        }
        return history.push("/private/saas/payment");
    }

    const navigateChangePayment = () => {
        return history.push("/private/saas/mudar-forma-pagamento");
    }

    if (!isToShow)
        return <></>

    if (cieloEvent) {
        return (
            <div className={styles.trialContainer}>
                <img src="/assets/icon/saas/party-outlined.svg" alt="" />
                <span className={styles.text}><b>Você está no plano de eventos! Restam {subscription?.daysToExpiry} dias</b></span>
            </div>
        )
    }

    if (isTrial) {
        return (
            <>
                <div className={styles.trialContainer}>
                    <img src="/assets/icon/saas/saas-alert-shine.svg" alt="" />
                    <span className={styles.text}>Bem-vindo! Você está no <b>período grátis</b></span>
                    <button onClick={navigatePayment}>Contratar agora</button>
                </div>
                <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />
            </>
        )
    }

    return (
        <>
            <div className={styles.container} style={{ backgroundColor: !isBlocked ? "#FFB300" : "#F44336" }}>
                <img src="/assets/icon/saas/alert.svg" alt="" />
                {
                    !isBlocked 
                        ? <span className={styles.text}>Houve uma falha no pagamento do seu plano. Verifique ou <b>altere a forma de pagamento.</b></span>
                        : <span className={styles.text}>Não identificamos seu pagamento. <b>Atualize sua forma de pagamento</b> para continuar usando.</span>
                }
                <button onClick={!isBlocked ? navigateChangePayment : navigatePayment}>
                    <Icon className={styles.caret}>chevron_right</Icon>
                </button>
            </div>
            <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />
        </>
    )
}