import UnitService from 'modules/meepErp/services/api/Unit/UnitService';
import { IUnitFilterValue } from '../../../presentation/components/unit/unitList/unitFilter/IUnitFilter';
import { IGetUnitListRequest } from 'modules/meepErp/application/dtos/unit/IGetUnitListRequest';
import { IGetUnitItemResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse';

export const getUnitUseCase = async ( filter: IUnitFilterValue) => {
    const service = UnitService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
    const request: IGetUnitListRequest = {
        page: page,
        pageSize: pageSize,
        orderBy: sortBy,
        ascending: filter.ascending,
        name: filter.name,
        symbol: filter.symbol
    };
    return service.getUnitList( request);
};
export const onDeleteUnitUseCase = async (id: string) => {
    const service = UnitService();
    return await service.deleteUnit(id);
};
export const updateIsActive = async (id: string, isActive: boolean, value: IGetUnitItemResponse) => {
    if (isActive) {
        const service = UnitService();
        await service.enableUnit(id);
    } else {
        const service = UnitService();
        await service.disableUnit(id);
    }
    return { ...value, isActive };
};
