import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { getItem } from 'localforage';
import React, { FC, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { AuthRepository } from 'services/repository/auth/AuthRepository';
import styles from './EditProductPage.module.scss'
import { IframePage } from '../../../../oldPortal/presentation/components/iframe/IframePage';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
export interface IEditProductPageProps {
    //propertys
}
const EditProductPage: FC<IEditProductPageProps> = () => {
    const { productId } = useParams<{ productId: string }>();
    const { push } = useHistory()
    const { toast } = useUi()
    const { path } = useRouteMatch()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            { label: "Cadastro", title: "Produtos", url: '/private/cadastros/produtos' },
            { title: "Editar produto" },
        ])
    }, [updateRouters])
    
    useEffect(() => {

        const eventCB = (ev: MessageEvent<any>) => {
            if (ev.data.status === "success") {
                toast("Produto salvo com sucesso", "success");
                push("/private/cadastros/produtos")
            } else if (ev.data.action === "PRODUCT_BACK") {
                push("/private/products/lista")
            }
        }

        window.addEventListener("message", eventCB)

        return () => {
            window.removeEventListener("message", eventCB)
        }
    }, [push, toast])

    return (
        <div id={styles.EditProductPage} >
            <IframePage src={'/produtos/produto/editar/' + productId} />
        </div>
    )
}
export default EditProductPage



