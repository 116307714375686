import { Icon, IconButton } from "@material-ui/core";
import styles from "./SearchItem.module.scss";
import { TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Search, Star, StarBorder, Close } from "@mui/icons-material";
import { UseMenuList } from "../menu/UseMenuList";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { IMenuItemRows } from "components/layout/domain/dto/IGetMenuHistoryResponse";
import { useMenuContext } from "../menu/context/MenuContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import SearchItemSkeleton from "./SearchItemSkeleton";

interface SearchItemProps {
  isMobile?: boolean;
  onClose?: () => void;
  handleOnClickFavorite: (id: string) => Promise<void> | undefined;
}

export const SearchItem = ({ isMobile, onClose, handleOnClickFavorite }: SearchItemProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [onSearch, setOnSearch] = useState(false);

  const {
    menuSearchList,
    getMenuSearch,
    loadingSearch,
    deleteMenuFavorite,
    postMenuFavorite,
    getMenuHistory, 
    menuHistoryList,
    loadingHistoryList,
  } = UseMenuList();
  const { hideLoading } = useUi();
  const { push } = useHistory();
  const { menuId } = useMenuContext();
  const { currentLocal } = useLocal();

  useEffect(() => {
    hideLoading();
    getMenuHistory();
  }, [getMenuHistory, hideLoading]);

  const handleToggleFavorite = useCallback(
    async (item: IMenuItemRows) => {
      if (item.id === menuId && !isMobile) {
        await handleOnClickFavorite(item.id);
        await getMenuHistory();
        return;
      }
      if (currentLocal) {
        if (item.isFavorited) {
          await deleteMenuFavorite({
            menuId: item.id,
            ownerId: currentLocal?.id,
          });
          await getMenuHistory();
          return;
        }
        await postMenuFavorite({
          menuId: item.id,
          ownerId: currentLocal?.id,
        });
        await getMenuHistory();
      }
    },
    [
      getMenuHistory,
      handleOnClickFavorite,
      currentLocal,
      deleteMenuFavorite,
      postMenuFavorite,
      menuId,
      isMobile
    ]
  );

  const onClickRoute = useCallback(
    (route: string) => {
      push(route.trim());
    },
    [push]
  );

  const menuList = useMemo(() => 
  onSearch 
    ? menuSearchList ?? []
    : menuHistoryList ?? [],
  [onSearch, menuSearchList, menuHistoryList]);

  return (
    <div id={styles.SearchItem}>
      {isMobile && (
        <div className={styles.containerTitle}>
          <Close onClick={onClose} />
        </div>
      )}
      <form
        style={{ border: "none", padding: 0 }}
        onSubmit={(ev) => {
          ev.preventDefault();
          setOnSearch(searchValue ? true : false);
          getMenuSearch(searchValue);
        }}
      >
        <TextField
          disabled={loadingSearch}
          variant={"outlined"}
          name="search"
          value={searchValue}
          placeholder={"Pesquisar"}
          onChange={(ev) => setSearchValue(ev.target.value)}
          fullWidth
          InputProps={{
            style: { padding: "0 8px" },
            endAdornment: (
              <IconButton
                title="Pesquisar"
                disabled={loadingSearch}
                type={"submit"}
              >
                <Search fontSize="small" className={styles.iconSearch} />
              </IconButton>
            ),
          }}
        />
      </form>
      {loadingHistoryList 
      ? <SearchItemSkeleton /> 
      : menuList?.map((obj) => {
        if (obj.menuItemRows.length > 1) {
          return (
            <div className={styles.category} key={obj.menuItemRows[0].title}>
              <p className={styles.title}>{obj.menuItemRows[0].title}</p>
              {obj.menuItemRows.map((item, index) => 
                index > 0 && (
                  <div className={styles.search} key={item.id}>
                    <div
                      className={styles.box}
                      onClick={() => onClickRoute(item.route)}
                    >
                      <Icon className={styles.iconCategory} fontSize="medium">
                        {obj.menuItemRows[0].icon}
                      </Icon>
                      <div>
                        <p className={styles.subTitle}>{item.title}</p>
                        {item.lastSeen && (
                        <p className={styles.date}>
                          Visto em:{" "}
                          {format(new Date(item.lastSeen), "dd/MM/yyyy")}
                        </p>
                        )}
                      </div>
                    </div>
                    {item.isFavorited ? (
                      <Star
                        className={styles.starYellow}
                        onClick={() => handleToggleFavorite(item)}
                      />
                    ) : (
                      <StarBorder
                        className={styles.starGray}
                        onClick={() => handleToggleFavorite(item)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          )
        }
        return (
          <div className={styles.category} key={obj.menuItemRows[0].title}>
            {obj.menuItemRows.map((item) => (
              <div className={styles.search} key={item.id}>
                <div
                  className={styles.box}
                  onClick={() => onClickRoute(item.route)}
                >
                  <Icon className={styles.iconCategory} fontSize="medium">
                    {item.icon}
                  </Icon>
                  <div>
                    <p className={styles.subTitle}>{item.title}</p>
                    {item.lastSeen && (
                      <p className={styles.date}>
                        Visto em:{" "}
                        {format(new Date(item.lastSeen), "dd/MM/yyyy")}
                      </p>
                    )}
                  </div>
                </div>
                {item.isFavorited ? (
                  <Star
                    className={styles.starYellow}
                    onClick={() => handleToggleFavorite(item)}
                  />
                ) : (
                  <StarBorder
                    className={styles.starGray}
                    onClick={() => handleToggleFavorite(item)}
                  />
                )}
              </div>
            ))}
          </div>
        )        
      })}
      {!menuList.length && onSearch && (
        <div className={styles.emptyList}>
          <img src="/assets/img/empty-box.png" alt=""/>
          <span>Nenhum resultado encontrado.</span>
        </div>
      )}
    </div>
  );
};
