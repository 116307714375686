import React, { useCallback, useState } from 'react'
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type';
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService';
import { IProductsCanceledByOperatorData, ICanceledProductRankingData, IProductRankingData } from '../components/productRaking/IProductRanking';
import { OperationStringEnum } from '../components/operationFilter/OperationFilter';
import GetProductsCanceledByOperatorUseCase from 'modules/customersDashboard/application/useCases/GetProductsCanceledByOperatorUseCase';

const UseProductsCanceledByOperator = (filter: ICustomerDashboardFilter, operationFilter?: OperationStringEnum) => {

    const [data, setData] = useState<IProductsCanceledByOperatorData>();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>()

    const getData = useCallback(async (_filter: { operatorId: string, page?: number, pageSize?: number, sortBy?: string }) => {
        try {
            setIsLoading(true)
            const service = CustomersDashboardService();
            const response = await GetProductsCanceledByOperatorUseCase(service, { ...filter, ..._filter, cashierId: filter.cashierId, operation: operationFilter, operatorId: _filter.operatorId });
            setData(response)
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [filter, operationFilter])

    return ({
        data,
        isLoading,
        error,
        getData,
    })
}

export default UseProductsCanceledByOperator