import React, { FC } from 'react';
import styles from './Empty.module.scss';
import empty from '../../../res/assets/images/undraw.png'

interface IEmptyProps {
};

export const Empty: FC<IEmptyProps> = ({}) => {
  return (
    <div id={styles.Empty}>
      <img src={empty} alt="empty_img" />
      <h2>Não encontramos dados suficientes para exibir.</h2>
    </div>
  );
};