import { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './InvoiceFilter.module.scss'
import { Icon, TextField } from '@material-ui/core'
import { Container, FilterButtonContainer, InputContainer } from '../../../ui/form/FormContainers'
import Row from 'modules/catalog/presentation/componentes/ui/Row'
import { useForm } from 'react-hook-form'
import { IInvoiceFilterValue } from './IInvoiceFilter'
import { MenuItem } from '@mui/material'
import { IGetSupplyStorageLocationListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetSupplyStorageLocationListDtos'
import Button from 'components/ui/Button/Button'
import { DateRangePicker } from 'modules/crm/presentation/components/crmFilterForm/components/dateRangerPicker/DateRangePicker'

export interface IInvoiceFilterProps {
    defaultValue?: IInvoiceFilterValue
    onSubmit: (values: IInvoiceFilterValue) => void
    operations?: IGetSupplyStorageLocationListResponse
}

const InvoiceFilter: FC<IInvoiceFilterProps> = ({ defaultValue, onSubmit, operations }) => {
    const { register, handleSubmit, reset, watch, setValue } = useForm<IInvoiceFilterValue>({
        defaultValues: defaultValue ?? {
        }
    });

    useEffect(() => {
        reset(defaultValue);
    }, [defaultValue, reset])

    const onSubmitHandle = useCallback((values: IInvoiceFilterValue) => {
        onSubmit(values);
    }, [onSubmit])

    const allFields = watch();

    const isAnyFieldFilled = useMemo(() => {
        delete allFields.page;
        delete allFields.pageSize;
        delete allFields.ascending;
        delete allFields.sortBy;

        return Object.values(allFields).some(value => value !== undefined && value !== '');
    }, [allFields]);

    const onClickResetFilter = useCallback(() => {
        reset({
            number: '',
            operationTypeId: '',
            quantity: null,
            issueDate: '',
            date: '',
            total: null,
            dateStart: null,
            dateEnd: null,
            issueDateStart: null,
            issueDateEnd: null
        });
        onSubmit({
            number: '',
            operationTypeId: '',
            quantity: null,
            issueDate: '',
            date: '',
            total: null,
            dateStart: null,
            dateEnd: null,
            issueDateStart: null,
            issueDateEnd: null
        })
    }, [onSubmit, reset])

    const onChangeDate = useCallback(
        (_values?: [Date, Date] | [Date]) => {
            if (_values) {
                const _startDate = _values[0];
                const _endDate = _values[1] || undefined;
                setValue('dateStart', _startDate)
                setValue('dateEnd', _endDate)
            }
        },
        []
    );

    const onChangeIssueDate = useCallback(
        (_values?: [Date, Date] | [Date]) => {
            if (_values) {
                const _startDate = _values[0];
                const _endDate = _values[1] || undefined;
                setValue('issueDateStart', _startDate)
                setValue('issueDateEnd', _endDate)
            }
        },
        []
    );

    return (
        <Container>
            <form
                id={styles.InvoiceFilter}
                onSubmit={handleSubmit(onSubmitHandle)} >
                <Row className={styles.row}>
                    <InputContainer label='Período de emissão'>
                    <div className={styles.date}>
                        <DateRangePicker
                            label={""}
                            placeholder={""}
                            onChange={onChangeIssueDate}
                            selectRange
                            value={
                                allFields.issueDateStart && allFields.issueDateEnd
                                    ? `${new Date(allFields.issueDateStart).toLocaleDateString(
                                        "pt-BR"
                                    )} a ${new Date(allFields.issueDateEnd).toLocaleDateString(
                                        "pt-BR"
                                    )}`
                                    : ""
                            }

                            valueCalendar={[
                                allFields.issueDateStart || null,
                                allFields.issueDateEnd || null,
                            ]}
                        />
                        </div>
                    </InputContainer>
                    <InputContainer label='Período de entrada/saída'>
                    <div className={styles.date}>
                        <DateRangePicker
                            label={""}
                            placeholder={""}
                            onChange={onChangeDate}
                            selectRange
                            value={
                                allFields.dateStart && allFields.dateEnd
                                    ? `${new Date(allFields.dateStart).toLocaleDateString(
                                        "pt-BR"
                                    )} a ${new Date(allFields.dateEnd).toLocaleDateString(
                                        "pt-BR"
                                    )}`
                                    : ""
                            }

                            valueCalendar={[
                                allFields.dateStart || null,
                                allFields.dateEnd || null,
                            ]}
                        />
                        </div>
                    </InputContainer>
                    <InputContainer label="N° da nota ">
                        <TextField
                            {...register("number")}
                        />
                    </InputContainer>
                    <InputContainer label="Tipo de operação">
                        <TextField
                            {...register("operationTypeId")}
                            select
                            value={allFields.operationTypeId ?? ''}
                        >
                            {operations?.items.map(item => {
                                return (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            })}
                        </TextField>
                    </InputContainer>
                    <InputContainer label="Quantidade">
                        <TextField
                            {...register("quantity")}
                        />
                    </InputContainer>
                    <InputContainer label="Valor da nota">
                        <TextField
                            {...register("total")}
                        />
                    </InputContainer>
                    <InputContainer  >
                        <FilterButtonContainer minWidth={100} >
                            {isAnyFieldFilled &&
                                <Button
                                    onClick={onClickResetFilter}
                                    variant="text"
                                    color="primary"
                                    startIcon={<Icon>close</Icon>}>
                                    Limpar filtro
                                </Button>}
                            <Button color='secondary' type="submit" style={{ width: '76px', minWidth: '76px' }}>Buscar</Button>
                        </FilterButtonContainer>
                    </InputContainer>
                </Row>
            </form >
        </Container>
    )
}
export default InvoiceFilter