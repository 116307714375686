import { FC, useEffect } from "react";
import { ManageTitle } from "../manageTitle/ManageTitle";
import { BaseListCard } from "./components/discountCard/BaseListCard";
import { AddCircle } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import styles from "./TicketDiscount.module.scss";
import UseTicketDiscount from "./UseTicketDiscount";
import { InfinityScroll } from "components/infinityScroll/InfinityScroll";
import CircularProgress from "@mui/material/CircularProgress";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import { DiscountListForm } from "./components/discountListForm/DiscountListForm";
import { IEvents, OriginType } from "modules/events/domain/models/IEvents";

interface ITicketDiscountProps {
  event: IEvents;
  refreshDiscountList: boolean;
  resetRefreshDisountList: () => void;
  refreshEvent: () => Promise<void>;
}

export const TicketDiscount: FC<ITicketDiscountProps> = ({
  event,
  refreshDiscountList,
  refreshEvent,
  resetRefreshDisountList,
}) => {
  const {
    discountsList,
    loading,
    totalRecords,
    localId,
    openForm,
    selectedDiscountList,
    openFormModal,
    getEventDiscountBaseList,
    closeFormModal,
    handleSaveDiscountList,
  } = UseTicketDiscount(event, resetRefreshDisountList, refreshEvent);

  useEffect(() => {
    if (refreshDiscountList) {
      getEventDiscountBaseList();
    }
  }, [getEventDiscountBaseList, refreshDiscountList]);

  return (
    <div id={styles.TicketDiscount}>
      <ManageTitle
        title="Descontos"
        subTitle="Selecione quais listas farão parte do seu evento Você também pode editá-las, excluí-las ou criar novas."
      />

      {event.originType === OriginType.Portal && (
        <Button
          fullWidth={false}
          onClick={() => openFormModal()}
          className={styles.addDIscountListButton}
        >
          Adicionar lista de desconto
          <AddCircle />
        </Button>
      )}

      {loading && !totalRecords ? (
        <div className={styles.loading}>
          <CircularProgress color="error" />
        </div>
      ) : (
        event.id &&
        localId && (
          <InfinityScroll
            className={styles.cardList}
            onBottomHit={getEventDiscountBaseList}
            hasNext={discountsList.length < totalRecords}
            isLoading={loading}
          >
            <div className={styles.ticketDiscountList}>
              {discountsList &&
                discountsList.length > 0 &&
                discountsList.map((item, key) => (
                  <BaseListCard
                    key={key}
                    event={event}
                    baseList={item}
                    refreshDiscountList={getEventDiscountBaseList}
                  />
                ))}
              {discountsList && !totalRecords && discountsList.length === 0 && (
                <div className={styles.empty}>
                  <h3>Nenhum desconto de ingresso encontrado!</h3>
                  <p>
                    Vincule produtos do tipo ingresso ao evento e adicione-o
                    <br /> em alguma lista de descontos para poder utiliza-lo no
                    evento.
                  </p>
                </div>
              )}
            </div>
          </InfinityScroll>
        )
      )}

      <RightDrawer
        title={
          selectedDiscountList.id ? (
            <span>
              Editar <strong>lista de desconto</strong>
            </span>
          ) : (
            <span>
              Adicionar <strong>lista de desconto</strong>
            </span>
          )
        }
        open={openForm}
        onClose={closeFormModal}
        className={styles.modal}
      >
        <DiscountListForm
          closeFormModal={closeFormModal}
          handleFormDiscountList={handleSaveDiscountList}
          discountList={selectedDiscountList}
        />
      </RightDrawer>
    </div>
  );
};
