import { IGetCashlessTagsResponse } from "../domain/dto/IGetCashlessTagsResponse";
import { ISaleForProductReportService } from "../domain/interface/ISaleForProductReportService";


const GetCashlessTagsUseCase = async (service: ISaleForProductReportService, localId: string): Promise<IGetCashlessTagsResponse[]> => {
  const response = service.getCashlessTags(localId);
  return response;
};

export default GetCashlessTagsUseCase;
