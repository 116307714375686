import Sidesheet from 'components/sidesheet/Sidesheet';
import TabView from 'components/tab/TabView';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IAddPermutationAccount, IChildren, IOperation } from '../../../interfaces/IAddPermutionAccount';
import styles from './AddPermutation.module.scss';
import { IPermutationForm } from 'modules/permutation/presentation/interfaces/IPermutationForm';
import { DataForm } from 'modules/permutation/presentation/components/permutationForm/components/dataForm/DataForm';
import { PermutationItemForm } from 'modules/permutation/presentation/components/permutationForm/components/permutationItemForm/PermutationItemForm';
import { UseForm } from 'modules/permutation/presentation/components/permutationForm/components/useForm/UseForm';
import { IOperationErros, IPermutationFormErros } from 'modules/permutation/presentation/interfaces/IPermutationFormErros';
import { validatePermutationForm } from 'modules/permutation/presentation/components/permutationForm/validation/PermutationFormValidation';
import { validationPermutationItemForm } from 'modules/permutation/presentation/components/permutationForm/validation/PermutationItemFormValidation';
import { IPermutionItem } from 'modules/permutation/presentation/interfaces/IPermutionItem';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import UseUpdateOperation from 'modules/permutation/presentation/hooks/UseUpdateOperation';
import UseOperationBalance from 'modules/permutation/presentation/hooks/UseOperationBalance';
import { UseChildForm } from './UseChildForm';
import SidesheetFeedback from 'components/sidesheet/sidesheetFeedback/SidesheetFeedback';
import UseAddPermutationAccount from 'modules/permutation/presentation/hooks/UseAddPermutationAccount';
import { AddPermutationStepEnum } from '../../PermutationPage';
import { IPermutationAccountList } from 'modules/permutation/presentation/interfaces/IPermutationAccountList';
import EditValidation from './components/editValidation/EditValidation';


export interface IAddPermutationProps {
  open: boolean;
  onSubmit: (values: IAddPermutationAccount) => Promise<void>;
  defaultValues: IPermutionItem | undefined
  setPermutationAccountItem: React.Dispatch<React.SetStateAction<IPermutionItem | undefined>>
  setOpenSidesheet: React.Dispatch<React.SetStateAction<"isNew" | "isEditing" | "isDeleting" | null>>
  isLoadingEdit: boolean | undefined
  reloadPageList: () => void
  setStep: React.Dispatch<React.SetStateAction<AddPermutationStepEnum>>
  step: AddPermutationStepEnum
  successAdd: React.MutableRefObject<boolean>
  permutationAccountList: IPermutationAccountList | undefined
}


export enum DocumentType {
  "CPF" = 1,
  "CNPJ" = 2
}

export const AddPermutation: React.FC<IAddPermutationProps> = ({ permutationAccountList, successAdd, setStep, step, reloadPageList, setPermutationAccountItem, isLoadingEdit, setOpenSidesheet, open, onSubmit, defaultValues }) => {


  const [isAdd, setIsAdd] = useState<boolean>();
  const [optionDocument, setOptionDocument] = useState(
    defaultValues && defaultValues.documentTypeId !== undefined
      ? defaultValues.documentTypeId
      : DocumentType.CPF
  );
  const [sideSheetUseForm, setSideSheetUseForm] = useState(false)
  const isEdit = useRef(false)
  const [values, setValues] = useState<IPermutationForm>({
    document: '',
    children: [],
    documentTypeId: 0,
    name: '',
    operation: {} as IOperation,
    placeId: ''
  });
  const [errors, setErrors] = useState<IPermutationFormErros>({});
  const [errorsObs, setErrorsObs] = useState<IOperationErros>({});
  const [inputValue, setInputValue] = useState<string>('')
  const [inputReason, setInputReason] = useState<string>('')
  const [inputChecked, setInputChecked] = useState<boolean>(false)

  const { showLoading, hideLoading } = useUi();
  const { postUpdateOperation } = UseUpdateOperation();
  const { getOperationBalance, operationBalance, setOperationBalance } = UseOperationBalance();


  useEffect(() => {
    if (defaultValues) {
      setValues({
        id: defaultValues.id ?? '',
        document: defaultValues.document ?? '',
        children: [],
        documentTypeId: defaultValues.documentTypeId ?? 0,
        name: defaultValues.name ?? '',
        operation: { value: String(defaultValues?.balance) ?? 0, reason: '' },
        placeId: ''
      });
      isEdit.current = true
    }
  }, [defaultValues]);

  const { getUseChildList, isLoading, params, setParams, onChangeFilterHandle, onChangePaginationHandle, useChildList } = UseChildForm(values);

  const reload = useCallback(() => {
    values.id && getUseChildList(values.id, { ...params })
  }, [getUseChildList, params, values.id])

  const onClose = useCallback(() => {
    setOpenSidesheet(null);
    setPermutationAccountItem(undefined)
    setValues({
      document: '',
      children: [],
      documentTypeId: 0,
      name: '',
      operation: { value: '', reason: '' } as IOperation,
      placeId: ''
    });
    isEdit.current = false;
    setOperationBalance(undefined)
    setErrors({})
    successAdd.current = false

  }, [setOpenSidesheet, setOperationBalance, setPermutationAccountItem, successAdd]);

  const changeHandle = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const changeHandleCpfOrCnpj = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value.replace(/\D/g, '') }));
  }, []);

  const getBalanceOperation = useCallback(() => {
    if (defaultValues) {

      getOperationBalance(defaultValues.id)
    }
  }, [defaultValues, getOperationBalance])


  const onSave = useCallback(() => {
    const val = inputValue;
    if (!isAdd) {
      setValues(prev => ({ ...prev, operation: { ...prev.operation, value: String(Number(prev.operation.value ?? 0) - Number(val ?? 0)), reason: inputReason } }));
    } else {
      setValues(prev => ({ ...prev, operation: { ...prev.operation, value: String(Number(prev.operation.value ?? 0) + Number(val ?? 0)), reason: inputReason } }));
    }
    setInputValue('')
    setInputReason('')
  }, [inputReason, inputValue, isAdd]);

  const onSaveUpdateOperation = useCallback(async () => {
    showLoading();
    const val = inputValue;
    try {
      if (defaultValues) {
        if (!isAdd) {
          await postUpdateOperation(defaultValues.id, { value: String(Number(val ?? 0) * -1), reason: inputReason });

        } else {

          await postUpdateOperation(defaultValues.id, { value: String(Number(val ?? 0)), reason: inputReason });
        }
      }
      getBalanceOperation();

    } finally {
      hideLoading();
      setInputValue('')
      setInputReason('')
    }
  }, [defaultValues, getBalanceOperation, hideLoading, inputReason, inputValue, isAdd, postUpdateOperation, showLoading])


  const openSideSheetUseForm = () => {
    setSideSheetUseForm(true)
  }
  const closeSideSheetUseForm = () => {
    setSideSheetUseForm(false)
  }


  const changeHandleChecked = useCallback((checked: boolean) => {
    setInputChecked(checked)
  }, []);

  const changeHandleDocument = useCallback((document: DocumentType) => {
    setOptionDocument(document)
  }, []);

  const changeHandleValue = useCallback((val: string) => {
    setInputValue(val)
  }, []);

  const changeHandleReason = useCallback((val: string) => {
    setInputReason(val);
  }, []);

  const handleChangeTab = useCallback((currentTabe: AddPermutationStepEnum) => {
    setStep(currentTabe);
  }, [setStep]);

  const onSubmitPermutation = useCallback(() => {
    setStep(AddPermutationStepEnum.PERMUTATION);
  }, [setStep]);

  const onSubmitUse = useCallback(() => {
    setStep(AddPermutationStepEnum.USE);
  }, [setStep]);

  const findItemList = useCallback(() => {
    const itemList = permutationAccountList?.records.find(item => item.id === values.id);
    return itemList?.hasOpenMeepAccount;
  }, [permutationAccountList?.records, values.id])

  const onSaveUse = useCallback((newChildren: IChildren[], isEdit: boolean) => {
    if (isEdit) {

      setValues(prevValues => {
        const updatedChildren = prevValues.children.map(existingChild => {
          const matchingChild = newChildren.find(child => child.id === existingChild.id);
          return matchingChild ? matchingChild : existingChild;
        });

        return {
          ...prevValues,
          documentTypeId: optionDocument,
          children: updatedChildren
        };
      });
    } else {
      setValues(prevValues => ({
        ...prevValues,
        documentTypeId: optionDocument,
        children: newChildren
      }));
    }
    closeSideSheetUseForm();
  }, [optionDocument]);

  const containerType = useMemo(() => {
    return isEdit.current ? values.documentTypeId : optionDocument

  }, [isEdit, optionDocument, values.documentTypeId])

  const handleShowTabView = useCallback(() => {
    const tabs = [
      {
        label: "Dados",
        value: AddPermutationStepEnum.DATA,
      },
      {
        label: "Permutas",
        value: AddPermutationStepEnum.PERMUTATION,
      }
    ];

    if (containerType === DocumentType.CNPJ) {
      tabs.push({
        label: "Utilização",
        value: AddPermutationStepEnum.USE,
      });
    }
    if (successAdd.current || findItemList()) {
      <></>
    } else {
      return (
        <TabView
          disabled
          index={step}
          tabs={tabs}
          onChangeTab={(tab, value) => handleChangeTab?.(value)}
        />
      );
    }
  }, [containerType, findItemList, handleChangeTab, step, successAdd]);


  const handleContentBlockEdit = useCallback(() => {
    return <EditValidation />;
  }, [])


  const handleContent = useCallback(() => {
    if (step === AddPermutationStepEnum.DATA) {
      return (
        <DataForm
          isEdit={isEdit}
          containerType={containerType}
          changeHandleCpfOrCnpj={changeHandleCpfOrCnpj}
          setErrors={setErrors}
          errors={errors}
          changeHandle={changeHandle}
          changeHandleDocument={changeHandleDocument}
          optionDocument={optionDocument}
          setOptionDocument={setOptionDocument}
          values={values}
        />
      );
    } else if (step === AddPermutationStepEnum.PERMUTATION) {
      return (
        <PermutationItemForm
          operationBalance={operationBalance}
          getBalanceOperation={getBalanceOperation}
          onSaveUpdateOperation={onSaveUpdateOperation}
          isEdit={isEdit}
          errorsObs={errorsObs}
          onSave={onSave}
          values={values.operation}
          setIsAdd={setIsAdd}
          isAdd={isAdd}
          inputValue={inputValue}
          inputReason={inputReason}
          changeHandleValue={changeHandleValue}
          changeHandleReason={changeHandleReason}
        />
      );
    } else if (step === AddPermutationStepEnum.USE && containerType === DocumentType.CNPJ) {
      return (
        <UseForm
          isEdit={isEdit}
          pagination={params?.pagination}
          isLoading={isLoading}
          params={params}
          setParams={setParams}
          onChangeFilterHandle={onChangeFilterHandle}
          onChangePaginationHandle={onChangePaginationHandle}
          useChildList={useChildList}
          setValues={setValues}
          closeSideSheetUseForm={closeSideSheetUseForm}
          openSideSheetUseForm={openSideSheetUseForm}
          sideSheetUseForm={sideSheetUseForm}
          values={values}
          inputChecked={inputChecked}
          changeHandle={changeHandle}
          changeHandleChecked={changeHandleChecked}
          onSaveUse={onSaveUse}
          reload={reload}
        />
      );
    } else {
      return null;
    }

  }, [changeHandle, changeHandleChecked, changeHandleCpfOrCnpj, changeHandleDocument, changeHandleReason, changeHandleValue, containerType, errors, errorsObs, getBalanceOperation, inputChecked, inputReason, inputValue, isAdd, isLoading, onChangeFilterHandle, onChangePaginationHandle, onSave, onSaveUpdateOperation, onSaveUse, operationBalance, optionDocument, params, reload, setParams, sideSheetUseForm, step, useChildList, values]);
  const handleClose = useCallback(() => {

    onClose();
    setStep(AddPermutationStepEnum.DATA)
  }, [onClose, setStep]);

  const handleSubmit = useCallback(() => {
    onSubmit({ ...values, documentTypeId: optionDocument })
  }, [onSubmit, optionDocument, values])

  const continueOrSave = useCallback(() => {

    if (findItemList()) {
      return handleClose();
    } else {


      if (containerType === DocumentType.CNPJ) {
        if (step === AddPermutationStepEnum.DATA) {
          if (validatePermutationForm(values, setErrors, containerType)) {
            onSubmitPermutation();
          }
        } else if (step === AddPermutationStepEnum.PERMUTATION) {
          if (isEdit.current) {
            onSubmitUse();
          } else {
            if (validationPermutationItemForm(values, setErrorsObs)) {
              onSubmitUse();
            }
          }
        } else {
          if (isEdit.current) {
            successAdd.current = true
            reloadPageList();
            return setStep(step + 1)

          } else {
            handleSubmit();
          }
        }
      } else {
        if (step === AddPermutationStepEnum.DATA) {
          if (validatePermutationForm(values, setErrors, containerType)) {
            onSubmitPermutation();
          }
        } else if (step === AddPermutationStepEnum.PERMUTATION) {
          if (isEdit.current) {
            successAdd.current = true
            reloadPageList();
            return setStep(step + 1);
          } else {
            if (validationPermutationItemForm(values, setErrorsObs)) {
              handleSubmit();
            }
          }
        }

      }
    }
  }, [containerType, findItemList, handleClose, handleSubmit, onSubmitPermutation, onSubmitUse, reloadPageList, setStep, step, successAdd, values])


  const continueOrSaveButton = useCallback(() => {
    if (findItemList()) {
      return "Fechar"
    } else {

      if (containerType === DocumentType.CNPJ) {
        if (step === AddPermutationStepEnum.DATA || step === AddPermutationStepEnum.PERMUTATION) {
          return "Continuar"
        } else {
          return "Salvar"
        }
      } else {
        if (step === AddPermutationStepEnum.DATA) {
          return "Continuar"

        } else {
          return "Salvar"
        }
      }
    }
  }, [containerType, findItemList, step])

  const continueOrBackButton = useCallback(() => {

    if (step === AddPermutationStepEnum.DATA) {
      return onClose();
    } else if (step === AddPermutationStepEnum.PERMUTATION) {
      return handleChangeTab(AddPermutationStepEnum.DATA)
    } else if (step === AddPermutationStepEnum.USE) {
      return handleChangeTab(AddPermutationStepEnum.PERMUTATION)
    }

  }, [handleChangeTab, onClose, step])

  const cancelOrBackButtonText = useCallback(() => {
    if (step === AddPermutationStepEnum.DATA) {
      return "Cancelar";
    } else if (step === AddPermutationStepEnum.PERMUTATION || step === AddPermutationStepEnum.USE) {
      return "Voltar";
    }
  }, [step]);

  const customizedCancelButtonText = cancelOrBackButtonText();

  return (
    <Sidesheet
      open={open}
      onClose={handleClose}
      isLoading={isLoadingEdit}
      title={
        <h2>
          {defaultValues ? "Editar" : "Nova"} <b>permuta</b>
        </h2>
      }
      tabView={handleShowTabView()}
      content={findItemList() ? handleContentBlockEdit() : handleContent()}
      currentStep={step}
      totalSteps={containerType === DocumentType.CNPJ ? 3 : 2}
      continueButton={continueOrSaveButton()}
      handleContinueButton={continueOrSave}
      customizedCancelButton={customizedCancelButtonText}
      cancelButton={!findItemList()}
      notTotalHeight
      handleCustomizedCancelButton={continueOrBackButton}
      feedback={
        <SidesheetFeedback text="Permuta adicionada com sucesso!" success />
      }
    />
  )
}
