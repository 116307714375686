import { useCallback, useState } from "react";
import PostCancellation from "../application/useCases/PostCancellationUseCase";
import { CashCancellationApi } from "services/api/cashCancellation/cashCancellationApi";
import { IPostCashCancellationResponse } from "../domain/dto/IPostCashCancellationResponse";

export const UseCashCancellationPage = () => {
  const [cancellationList, setCancellationList] = useState<IPostCashCancellationResponse| undefined>();

  const cancellationService = CashCancellationApi();

  const postCancellation = useCallback(async (filters) => {
      const response = await PostCancellation(cancellationService, filters);
      setCancellationList(response);
  }, [cancellationService]);

  return {
    postCancellation,
    cancellationList,
  };
};
