import { Switch as SwitchMaterial, SwitchProps, ThemeProvider } from "@material-ui/core";
import React, { FC } from "react";
import { theme } from "../../../styles/temas/MateriaUi";
import styles from "./Switch.module.scss";
export interface ISwitchProps extends SwitchProps {}
const Switch: FC<ISwitchProps> = (props) => {
  return (
    <div id={styles.Switch}>
      <ThemeProvider theme={theme}>
        <SwitchMaterial
          {...props}
          classes={{
            switchBase: styles.switchBase,
            checked: styles.checked,
            thumb: styles.thumb,
            track: styles.track,
            disabled: styles.disabled,
            root: styles.root,
          }}
        />
      </ThemeProvider>
    </div>
  );
};
export default Switch;
