import { TooltipInfo } from 'components/ui/tooltip/Tooltip'
import Autocomplete from 'components/ui/autoComplete/AutoComplete'
import { Sort } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import styles from './ReserveListPage.module.scss'
import { UseReserveListPage } from './UseReserveListPage'
import FilterForm from 'components/ui/Filter/FilterForm'
import Input from 'components/ui/input/Input'
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer'
import { Pagination } from 'components/ui/pagination/Pagination'
import { IState, IStateEnum } from 'modules/schedule/domain/models/IReserveList'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { ReserveListCardMobile } from '../../components/reserveListCardMobile/ReserveListCardMobile'
import { ReserveListCard } from '../../components/reserveListCard/ReserveListCard'


const options: IStateEnum[] = [
    IStateEnum.Pendente,
    IStateEnum.Concluída,
    IStateEnum.Cancelada
]

export const ReserveListPage = () => {

    const {
        values,
        page,
        changePage,
        initialDate,
        finalDate,
        keyword,
        handleFinalDateFilter,
        pageSize,
        onFilter,
        handleKeywordFilter,
        handleInitialDateFilter,
        refreshReserveList,
        changeStateHandle,
        state,
        selectedState

    } = UseReserveListPage();

    const { isMobile } = useUi();

    const [showFilter, setShowFilter] = useState(false)


    const toogleFilter = () => {
        setShowFilter(!showFilter)
    }

    // useEffect(() => {

    // }, [state])

    return (
        <div id={styles.ReserveListPage}>
            <div className={styles.containerFilterButton}>
                <button className={styles.filterButton} onClick={toogleFilter}>
                    <p>Filtro</p>
                    <Sort className={styles.filterSort} />
                </button>
            </div>
            <div className={styles.containerFilter} >
                {/* <Button fullWidth={false} onClick={() => {}}>
                    Adicionar reserva <AddCircle />
                </Button> */}
                {
                    showFilter &&
                    <div className={styles.filter}>

                        <FormItemContainer>
                            <h1>
                                Opções de filtro
                            </h1>
                            <FilterForm onSubmit={onFilter}>
                                <FormItemContainer row>
                                    <FormItemContainer label={"Data inicial"}>
                                        <Input
                                            value={initialDate}
                                            fullWidth
                                            name="start"
                                            type="date"
                                            onChange={
                                                handleInitialDateFilter
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormItemContainer>
                                    <FormItemContainer label={"Data final"}>
                                        <Input
                                            value={finalDate}
                                            fullWidth
                                            name="end"
                                            type="date"
                                            onChange={
                                                handleFinalDateFilter
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormItemContainer>
                                </FormItemContainer>
                                <FormItemContainer label={"Palavra chave"}>
                                    <Input value={keyword} required name="keyword" onChange={handleKeywordFilter} />
                                </FormItemContainer>
                                <div className={styles.containerStatus}>

                                    <FormItemContainer label={"Status"}>
                                        <Autocomplete
                                            variant="standard"
                                            multiple
                                            value={state}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => IStateEnum[option]}
                                            options={options}
                                            name="servicesScheduleIds"
                                            onChange={(ev, values) => changeStateHandle(values as IStateEnum[])} />
                                    </FormItemContainer>

                                </div>
                            </FilterForm>
                        </FormItemContainer>
                    </div>
                }
            </div>

            <div className={styles.containerHelp}>
                <h2>Listas de reservas</h2>
                <TooltipInfo
                    title={"Listas de reservas"}
                    paragraph={"Preencha as informações do seu lugar, como: lotação máxima, serviços e comandas."}
                />
            </div>

            <div className={styles.container}>
                <div>
                    {values?.details && values?.details.length > 0 ? (
                        <Pagination
                            data={values?.details}
                            RenderComponent={(item, index) => !isMobile ? <ReserveListCard value={item} refreshList={refreshReserveList} index={index} /> : <ReserveListCardMobile value={item} refreshList={refreshReserveList} index={index} />}
                            pageLimit={values.count}
                            pageHandle={changePage}
                            page={page}
                            dataLimit={pageSize}
                        />
                    ) : (

                        <div className={styles.containerInfoList}>
                            <p>Não há informações para serem exibidas</p>
                        </div>

                    )}
                </div>
            </div>
        </div >
    )
}