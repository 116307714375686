import { FC } from 'react'
import { Skeleton } from '@mui/material'
import styles from './SkeletonForm.module.scss'

export interface ISkeletonFormProps {
    //propertys
    lines: number
}
const SkeletonForm: FC<ISkeletonFormProps> = ({ lines }) => {
    return (
        <div className={styles.container}>
            {new Array(lines).fill(null).map((_, index) => {
                return (
                    <Skeleton variant='rectangular' style={{ width: '100%', height: 50, borderRadius: '5px' }}></Skeleton>
                )
            })}
        </div>
    )
}
export default SkeletonForm