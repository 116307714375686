import { useEffect, useState } from 'react';
import TabView from '../../../../../components/tab/TabView'
import styles from './EditInvoiceModal.module.scss';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { ICompany } from '../../interfaces/ICompany';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { ICompanyForm } from '../../interfaces/ICompanyForm';
import { UpdateCompanyUseCase } from 'modules/invoice/application/useCases/UpdateCompanyUseCase';
import { InvoiceRegister } from '../../InvoiceRegister/InvoiceRegister';
import { InvoiceAccounting } from '../../InvoiceAccounting/InvoiceAccounting';

interface EditInvoiceModalProps {
    company: ICompany;
    onClose: () => void;
    onSave: () => void;
}

const service = InvoiceApi();

export const EditInvoiceModal = ({ company, onSave, onClose }: EditInvoiceModalProps) => {
    const [tab, setTab] = useState(0);
    const [preLoading, setPreloading] = useState(true);
    const [form, setForm] = useState({} as ICompanyForm);
    const [loading, setLoading] = useState(false);

    const { currentLocal } = useLocal();
    const { toast } = useUi();

    useEffect(() => {
        (async () => {
            if (currentLocal?.id) {
                try {
                    setPreloading(true);
                    const response = await service.getCompany(currentLocal.id);
                    setForm({ ...response, certificate: undefined, hasCertificate: !!response.certificate?.url });
                } finally {
                    setPreloading(false);
                }
            }
        })();
    }, [currentLocal?.id]);

    const onNextStep = () => {
        setTab(1);
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await UpdateCompanyUseCase(service, company!.id, { ...form, certificate: !form.hasCertificate ? { ...form.certificate!, saveFile: true } : undefined });
            toast('Informações fiscais salvas com sucesso.', 'success');
            onSave();
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.tabsContainer}>
                <TabView
                    index={tab}
                    scrollButton="off"
                    tabs={[
                        { label: 'Informações básicas', value: 0, disabled: true },
                        { label: 'Informações contábeis', value: 1, disabled: true },
                    ]}
                    onChangeTab={tab => setTab(tab.value)}
                />
            </div>

            <div className={styles.content}>
                {
                    preLoading !== false ?
                        <div className={styles.loadingContainer}>
                            <img src="/assets/icon/saas/loading.gif" alt="" />
                        </div> :
                        tab === 0
                            ? <InvoiceRegister setForm={setForm} form={form} onCancel={onClose} onSubmit={onNextStep} modal />
                            : <InvoiceAccounting loading={loading} setForm={setForm} form={form} onCancel={() => setTab(0)} onSubmit={handleSubmit} modal />
                }
            </div>
        </div>
    )
}