import { titleHeaderAntecipar } from "../../pages/utils";
import styles from "./TableHeader.module.scss";

const TableHeader = () => {
  return (
    <div id={styles.TableHeader}>
      {titleHeaderAntecipar.map((item, index) => {
        return (
          <div key={index} className={styles.container}>
            {item}
          </div>
        );
      })}
    </div>
  );
};
export default TableHeader;
