import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import SegmentService from 'modules/meepErp/services/api/Segment/SegmentService';
import SegmentList from '../../components/segment/segmentList/SegmentList';
import DeleteErp from '../../components/ui/delete/DeleteErp';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ISegmentFilterValue } from '../../components/segment/segmentList/segmentFilter/ISegmentFilter';
import { IGetSegmentListRequest } from 'modules/meepErp/application/dtos/segment/IGetSegmentListRequest';
import { queryClient } from 'services/api/Api';
import { IGetSegmentItemResponse, IGetSegmentListResponse } from 'modules/meepErp/application/dtos/segment/IGetLSegmentListResponse';
import UseQueryState from '../../hook/UseQueryFilter';
import { Button, Icon } from '@material-ui/core';
import TabsErp from '../../components/ui/tabs/TabsErp';
import styles from './Segment.module.scss';
import { Add } from '@material-ui/icons';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { tabsRegister } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';


const SegmentPage: FC = () => {
    const [itemToDelete, setItemToDelete] = useState<string>();
    const { state: filter, updateState: updateFilter } = UseQueryState<ISegmentFilterValue>();
    const { currentLocal } = useLocal();
    const { push } = useHistory()
    const { hasAccessRole } = useAuth()
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Segmento",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    //useCases
    const getSegmentListUseCase = async (filter: ISegmentFilterValue) => {
        const service = SegmentService()
        const page = filter.page ?? 1;
        const pageSize = filter.pageSize ?? 10;
        const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
        const request: IGetSegmentListRequest = {
            page: page,
            pageSize: pageSize,
            orderBy: sortBy,
            ascending: filter.ascending,
            name: filter.name
        }
        return service.getSegmentList(request);
    }


    const onDeleteOperationUseCase = async (id: string) => {
        const service = SegmentService();
        return await service.deleteSegment(id);
    }

    const updateIsActive = useCallback(async (id: string, isActive: boolean, value: IGetSegmentItemResponse) => {
        if (isActive) {
            const service = SegmentService();
            await service.enableSegment(id)
        } else {
            const service = SegmentService();
            await service.disableSegment(id);
        }
        return { ...value, isActive }
    }, [])

    //ReactiQuery

    const segment = useQuery(['@segment', filter], () => getSegmentListUseCase(filter))

    const onSuccessDelete = (_: void, id: string) => {
        queryClient.setQueriesData<IGetSegmentListResponse | undefined>(['@segment'], (previous) =>
        (previous ? {
            ...previous,
            items: previous.items.filter(item => item.id !== id) ?? []
        } : undefined))
    }

    const onDeleteOperationMutation = useMutation((id: string) => onDeleteOperationUseCase(id), {
        onSuccess: onSuccessDelete
    });

    const onConfirmDelete = useCallback(async (id: string) => {
        await onDeleteOperationMutation.mutateAsync(id);
    }, [onDeleteOperationMutation])

    const onSuccessUpdateIsActive = (value: IGetSegmentItemResponse, { id, isActive }: { id: string; isActive: boolean }) => {
        queryClient.setQueriesData<IGetSegmentListResponse | undefined>(
            ['@segment'],
            (previous) =>
                previous
                    ? {
                        ...previous,
                        items: previous.items.map((item) =>
                            item.id === id ? { ...item, isActive } : item
                        ) ?? []
                    }
                    : undefined
        );
    };

    const onUpdateIsActiveMutation = useMutation(
        ({ id, isActive, value }: { id: string; isActive: boolean; value: IGetSegmentItemResponse }) =>
            updateIsActive(id, isActive, value),
        {
            onSuccess: onSuccessUpdateIsActive
        }
    );

    const onConfirmEnable = useCallback(async (id: string, isActive: boolean, value: IGetSegmentItemResponse) => {
        return await onUpdateIsActiveMutation.mutateAsync({ id, isActive, value });
    }, [onUpdateIsActiveMutation]);

    //Events
    const onClickAdd = useCallback(() => {
        push('/private/meeperp/segment/add');
    }, [push])

    const onClickEdit = useCallback(async (id: string) => {
        push(`/private/meeperp/segment/update/${id}`);
    }, [push])

    const onClickDelete = useCallback((id: string) => {
        setItemToDelete(id);
    }, [])

    return (
        <div id={styles.Segment}>
            <TabsErp data={tabsRegister} />
            {hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) &&
                <div>
                    <Button
                        fullWidth={false}
                        className={styles.buttonAdd}
                        onClick={onClickAdd}
                        endIcon={<Add className={styles.iconAdd} />}>
                        Adicionar segmento
                    </Button>
                </div>
            }
            <SegmentList
                filter={filter}
                onChangeFilter={updateFilter}
                segment={segment.data}
                onDelete={onClickDelete}
                onEdit={onClickEdit}
                isLoading={segment.isLoading}
                onClickEnable={onConfirmEnable}
            />
            <DeleteErp
                open={!!itemToDelete}
                onClose={() => setItemToDelete(undefined)}
                onSubmit={() => onConfirmDelete(itemToDelete!)}
                title={'segmento'}
                text={'Deseja realmente excluir o segmento selecionado?'}
                textFeedback={'Segmento excluído com sucesso!'}
                auxiliaryText='Ao excluir um segmento todo seu histórico ainda permanecerá no sistema mas ele não aparecerá como opção em qualquer configuração realizada.'
            />
        </div>
    )
}
export default SegmentPage

