import React, { FC, useEffect, useMemo } from 'react'
import styles from './NotificationV2.module.scss'
import { Badge, Button, CircularProgress, Drawer, Icon, IconButton, Popover, Switch } from '@mui/material';
import { INotificationItem } from './INotificationItem';
import { NotificationType } from 'modules/notification/domain/TypeNotification';


export interface INotificationV2Props {
    //propertys
    // notifications: INotification[];
    isLoading?: boolean;
    defaultNotifications?: INotificationItem[];
    onOpen: (item: INotificationItem) => void;
    autoOpens?: boolean;
}
const NotificationV2: FC<INotificationV2Props> = ({ isLoading, defaultNotifications, onOpen }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [notifications, setNotifications] = React.useState<INotificationItem[]>([]);
    const [openAll, setOpenAll] = React.useState(false);

    const [hideRead, setHideRead] = React.useState(false);

    const buttonRef = React.useRef<HTMLButtonElement>(null);


    useEffect(() => {
        if (defaultNotifications) {
            setNotifications(defaultNotifications);
        }
    }, [defaultNotifications])

    useEffect(() => {
        if (notifications.length > 0 && notifications.some(item => item.type === NotificationType.TERMINAL_SOLICITATION) && openAll === false) {
            setAnchorEl(buttonRef.current);
        }
    }, [notifications, openAll])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const notificationItems = <div className={styles.notificationList}>{notifications.filter((_, index) => index < 5).map((item, index) => (
        <NotificationItem key={index} item={item} onOpen={onOpen} />
    ))}</div>;

    const notificationAllItems = useMemo(() => (<div className={styles.notificationList}>{notifications.map((item, index) => (
        !(item.read && hideRead) && <NotificationItem key={index} item={item} onOpen={onOpen} />
    ))}</div>), [hideRead, notifications, onOpen]);

    const noReadQuantity = useMemo(() => notifications.filter(item => !item.read).length, [notifications]);

    return (<div id={styles.NotificationV2} >

        <IconButton ref={buttonRef} aria-describedby={id} onClick={handleClick}>
            <Badge showZero={false} badgeContent={noReadQuantity} color="primary"> <Icon>notifications</Icon></Badge>
        </IconButton>
        <Popover
            id={id}
            open={open}
            classes={{ paper: styles.popover }}
            PaperProps={{ elevation: 1 }}
            anchorEl={anchorEl}
            onClose={handleClose}

            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <IconButton className={styles.closeButton} onClick={handleClose}><Icon>close</Icon></IconButton>
            {notificationItems}
            <footer>
                <Button onClick={() => { setOpenAll(true); handleClose() }}>Ver todas</Button>
            </footer>
        </Popover>
        <Drawer anchor="right" open={openAll} onClose={() => { setOpenAll(false); handleClose() }}>
            <div id={styles.notificationDrawer}>
                <header>
                    <h2>Notificações</h2>
                    <IconButton className={styles.closeButton} onClick={() => { setOpenAll(false); handleClose() }}><Icon>close</Icon></IconButton>
                </header>
                <main>
                    <div className={styles.switchRead}>
                        <span>Mostrar apenas não lidas</span><Switch checked={hideRead} onChange={() => setHideRead(!hideRead)} color="primary" size='small' />
                    </div>
                    {notificationAllItems}
                </main>
            </div>
        </Drawer>
    </div>)
}
export default NotificationV2

interface NotificationItemProps {
    item: INotificationItem;
    onOpen: (item: INotificationItem) => void;
}

const NotificationItem: FC<NotificationItemProps> = ({ item, onOpen }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [hideActions, setHideActions] = React.useState(false);
    const [open, setOpen] = React.useState(item.open ?? false);

    const onConfirmHandler = async () => {
        try {
            setIsLoading(true);
            await item.onClickConfirm?.();

        } finally {
            setIsLoading(false);
            setHideActions(true);
        }
    }
    const onDismissHandler = async () => {
        try {
            setIsLoading(true);
            await item.onClickDismiss?.();
        } finally {
            setIsLoading(false);
            setHideActions(true);
        }
    }

    const onClickOpen = () => {
        setOpen(true);
        onOpen(item);
    }
    const onClickClose = () => {
        setOpen(false);
        onOpen(item);
    }



    return (<div id={styles.NotificationItem} >
        <div className={styles.header} style={{ opacity: isLoading ? 0.5 : 'unset' }}>
            <div className={styles.title}>
                <b>{item.title}</b>
                <span>{new Date(item.startDate).toLocaleDateString()}</span>
            </div>
            {open ? <IconButton onClick={onClickClose}><Icon>expand_less</Icon></IconButton> : <IconButton onClick={onClickOpen}><Icon>expand_more</Icon></IconButton>}
        </div>

        {open && <>
            <main>
                <span>{item.description}</span>
            </main>
            {!hideActions && <footer>
                {item.onClickConfirm && <Button disabled={isLoading} variant='contained' className={styles.confirm} onClick={onConfirmHandler}>{item.confirmLabel}</Button>}
                {item.onClickDismiss && <Button disabled={isLoading} variant='outlined' className={styles.refuse} onClick={onDismissHandler}>{item.dismissLabel}</Button>}
            </footer>}
        </>}
        {isLoading && <CircularProgress size={15} className={styles.loading} />}
    </div>
    )
}