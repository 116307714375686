import React, { FC, useCallback, useEffect, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IOriginType } from '../rechargeOrigin/interfaces/IOriginType'
import { ITopDevices } from './interfaces/ITopDevices'

export interface ITopDevicesProps {
    getTopDevices: ({ page }: IGetDataParams) => Promise<ITopDevices>
    type: IOriginType
}

const TopDevices: FC<ITopDevicesProps> = ({ getTopDevices, type }) => {
    const [topDevicesData, setTopDevicesData] = useState<ITopDevices>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [currentPage, setCurrentPage] = useState(0);
    
    const getData = useCallback(async (page?: number) => {
        clearContainerError(dashboardContainerEnum.TOP_DEVICES);
        try {
            !page && setOnLoading(true);
            const response = await getTopDevices?.({ page });
            if (page) {
                setTopDevicesData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
            } else {
                setTopDevicesData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
            }

            if (!response.records.length)
                setOnError({
                    type: 'warning',
                    containerId: dashboardContainerEnum.TOP_DEVICES
                })
        } catch (error: any) {
            setOnError({
                type: 'error',
                containerId: dashboardContainerEnum.TOP_DEVICES
            })
        } finally {
            setOnLoading(false);
        }
    }, [getTopDevices, clearContainerError, setOnError]);

    useEffect(() => {
        setCurrentPage(0)
    }, [type])

    useEffect(() => {
        getData(0);
    }, [getData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    return (
        <div>
            <RankingList
                // keyProp="id"
                list={topDevicesData?.records}
                count={topDevicesData?.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    name: { title: "Nome" },
                    total: { title: "Total", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default TopDevices