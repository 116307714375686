import { Icon, IconButton, Switch } from '@material-ui/core';
import styles from './Automation.module.scss';
import { UseAutomationPage } from './UseAutomationPage';
import { Pagination } from "@material-ui/lab";
import { Drawer } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import { AutomationForm } from './components/AutomationForm/AutomationForm';
import { AutomationTriggerType } from '../domain/interfaces/AutomationTriggerType';
import Layout from 'components/layout/presentation/Layout';
import { PremiumHint } from 'modules/plans/presentation/components/premiumHint/PremiumHint';
import { FeatureTypeEnum } from 'modules/plans/presentation/interfaces/FeatureTypeEnum';
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext';
import { PremiumModal } from 'modules/plans/presentation/components/premiumModal/PremiumModal';
import { BlurPremium } from 'modules/plans/presentation/components/blurPremium/BlurPremium';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

export const AutomationPage = () => {
    const {
        isLoading,
        data,
        isDrawerOpened,
        selectedAutomation,
        getData,
        getDataHandle,
        handleCloseForm,
        setIsDrawerOpened,
        handleToggleStatus,
        handleDelete
    } = UseAutomationPage();

    const { checkPermission } = usePlans();
    const [modalOpened, setModalOpened] = useState(false);

    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
        {
            title: "Automações",
        },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

    const getTriggerTypeLabel = (trigger: AutomationTriggerType) => {
        switch (trigger) {
            case AutomationTriggerType.ON_CLOSER_CASHIER:
                return 'Após o fechamento do caixa/evento';
            case AutomationTriggerType.ON_PAYMENT:
                return 'Ao realizar pagamento';
            default:
                return "--"
        }
    }

    const List = useMemo(() => {
        return (!isLoading ? (
            data?.data.map((item) => {
                return (
                    <tr>
                        <td>{item.name}</td>
                        <td>{getTriggerTypeLabel(item.triggerType)}</td>
                        <td><Switch className={`${styles.switch} ${!item.enabled ? styles.disabled : ''}`} checked={item.enabled} onChange={(e, checked) => handleToggleStatus(checked, item.id)} color='secondary' /></td>
                        {/* <td>Acessar histórico</td> */}
                        <td>
                            <IconButton onClick={() => handleDelete(item)}><Icon>navigate_next</Icon></IconButton>
                        </td>
                    </tr>
                );
            })
        ) : (
            <>
                <tr><Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} /></tr>
                <tr><Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} /></tr>
                <tr><Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} /></tr>
                <tr><Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} /></tr>
                <tr><Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: 4, marginBottom: 4, borderRadius: 4 }} /></tr>
            </>
        )
        );
    }, [data?.data, handleDelete, handleToggleStatus, isLoading]);

    return (
        <Layout>
            <div className={styles.container}>
                {
                    !checkPermission(FeatureTypeEnum.AUTOMACAO) && <PremiumHint />
                }
                <header>
                    <h1>Suas <strong>Automações</strong></h1>
                    <span>Crie automações e torne sua gestão muito mais inteligente!</span>
                    <button onClick={() => !checkPermission(FeatureTypeEnum.AUTOMACAO) ? setModalOpened(true) : setIsDrawerOpened(true)}>
                        Automatizar Ações
                        <img src="/assets/icon/automation-active.svg" alt="" />
                    </button>
                </header>
                <BlurPremium feature={FeatureTypeEnum.AUTOMACAO}>
                    {
                        data?.data.length || isLoading ?
                            <div className={styles.table}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Gatilho</th>
                                            <th>Status</th>
                                            {/* <th>Histórico</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {List}
                                    </tbody>
                                </table>
                                <Pagination
                                    color="primary"
                                    className={styles.pagination}
                                    defaultPage={data?.currentPage}
                                    count={data?.totalPages}
                                    onChange={(ev, page) => getDataHandle(page)}
                                />
                            </div> :
                            <div className={styles.noDataContainer}>
                                <img src="/assets/icon/automation-no-data-1.svg" alt="" />
                                <img src="/assets/icon/automation-no-data-2.svg" alt="" />
                                <img src="/assets/icon/automation-no-data-3.svg" alt="" />
                            </div>
                    }
                </BlurPremium>

                <Drawer open={isDrawerOpened} anchor='right' ModalProps={{ onBackdropClick: () => handleCloseForm() }}>
                    <AutomationForm closeStepForm={handleCloseForm} getData={getData} selectedItem={selectedAutomation} />
                </Drawer>
            </div>

            <PremiumModal opened={modalOpened} onClose={() => setModalOpened(false)} />
        </Layout>
    )
}