import GetHouseFlowProductListUseCase from "modules/houseFlow/application/useCases/GetHouseFlowProductListUseCase";
import GetHouseFlowUseCase from "modules/houseFlow/application/useCases/GetHouseFlowUseCase";
import { EnumCardStatus } from "modules/houseFlow/domain/dto/IGetHouseFlowListResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import HouseFlowApi from "services/api/houseFlow/HouseFlowApi";
import { IHouseFlow } from "../interfaces/IHouseFlow";
import { IHouseFlowProductList } from "../interfaces/IHouseFlowProductList";



export interface ProdudutsParamsClientHouseFlow {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  localId?: string;
  cardId?: string;
  orderId?: string;
  sortOrientation?: string;
  sortType?: string;
}

const UseHouseFlowProductList = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [houseFlowProductList, setHouseFlowProductList] = useState<IHouseFlowProductList>();
  const { currentLocal } = useLocal();
 

  const getHouseFlowProductList = useCallback(
    async (params?: ProdudutsParamsClientHouseFlow) => {
      try {
        const serviceHouseFlow = HouseFlowApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetHouseFlowProductListUseCase(
            serviceHouseFlow,
            currentLocal.id,
            params?.cardId,
            params?.orderId,
            params?.pagination ?? { page: 0, pageSize: 20 },
            params?.sortOrientation ?? 'asc',
            params?.sortType ?? 'productName'
          );             
          setHouseFlowProductList(response);
          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );



  return {
    houseFlowProductList,
    isLoading,
    getHouseFlowProductList,
    setHouseFlowProductList,
  };
};

export default UseHouseFlowProductList;
