import { useCallback, useMemo } from "react";
import styles from "./CrmCampaignList.module.scss";
import { Icon, Pagination, Skeleton } from "@mui/material";
import { CampaignTemplate, CampaignChannel, ICampaignData, ICampaignItem, CampaignStatus } from "../../intefaces/ICampaign";
import { IconButton } from "@material-ui/core";
import { differenceInMinutes } from "date-fns";
import { BlurPremium } from "modules/plans/presentation/components/blurPremium/BlurPremium";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import UseDimension from "components/dimension/UseDimension";
import { NavigateNext } from "@material-ui/icons";

export interface Props {
  campaignsData?: ICampaignData;
  getData: (params: { pagination: { page: number, pageSize: number } }) => void
  isLoading?: boolean;
  onClickItem: (item: ICampaignItem) => void
}

const CrmCampaignList = ({ campaignsData, getData, isLoading, onClickItem }: Props) => {
  const { dimensions } = UseDimension();

  const getDataHandle = useCallback((page: number) => {
    getData({ pagination: { page, pageSize: 10 } })
  }, [getData])

  const getStatusLabel = (status: CampaignStatus) => {
    switch (status) {
      case CampaignStatus.Approved:
        return 'Aprovado';
      case CampaignStatus.Draft:
        return 'Rascunho';
      case CampaignStatus.Pending:
        return 'Aguardando aprovação';
      case CampaignStatus.Reproved:
        return 'Recusado';
      case CampaignStatus.Running:
        return 'Enviando';
      case CampaignStatus.Sent:
        return 'Enviado';
      case CampaignStatus.Stopped:
        return 'Cancelado';
    }
  }

  const titleHeader = ["Nome da campanha", "Data de envio", "Meio de envio", "Template", "Destinatários", "Status"];

  const isSchedule = useCallback((campaingItem: ICampaignItem) => (
    campaingItem.deliveryTime &&
    <div className={styles.scheduleTime}>
      {/* {differenceInMinutes(new Date(), new Date(campaingItem.deliveryTime + "Z"))}-- */}
      {
        differenceInMinutes(new Date(), new Date(campaingItem.deliveryTime + "Z")) < 0 ?
          <Icon fontSize={"small"}>schedule</Icon > : null
      }
      {new Date(campaingItem.deliveryTime + "Z").toLocaleString()}
    </div>

  ), [])

  const List = useMemo(() => {
    return (!isLoading ? (
      campaignsData?.data.map((item) => {
        return (
          <>
            {dimensions.width > 650 
                ? (
                  <div className={styles.item}>
                    <span style={{ color: "#4d13b7", fontWeight: 600 }}>{item.name}</span>
                    <span>{item.deliveryTime ? isSchedule(item) : item.sendedAt ? new Date(item.sendedAt).toLocaleString() : "Não enviado"}</span>
                    <span>{item.channel === CampaignChannel.whatsapp ? "Whatsapp" : item.channel === CampaignChannel.push ? "Push" : "Email"}</span>
                    <span>{item.template === CampaignTemplate.event ? "Eventos" : item.template === CampaignTemplate.info ? "Informar" : "Outro"}</span>
                    <span>{item.totalCustomers}</span>
                    <span>{item.status === CampaignStatus.Sent ? "Enviado" : getStatusLabel(item.status)}</span>
                    <IconButton onClick={() => onClickItem(item)}><NavigateNext /></IconButton>
                  </div>
                )
                : (
                  <div className={styles.itemMobile}>
                    <div className={styles.row}>
                      <span className={styles.title}>Nome da campanha</span>
                      <div>
                        <span style={{ color: "#4d13b7", fontWeight: 600 }}>{item.name}</span>
                        <IconButton size="small" onClick={() => onClickItem(item)}><NavigateNext fontSize="small"/></IconButton>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.title}>Data de envio</span>
                      <span>{item.deliveryTime ? isSchedule(item) : item.sendedAt ? new Date(item.sendedAt).toLocaleString() : "Não enviado"}</span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.title}>Meio de envio</span>
                      <span>{item.channel === CampaignChannel.whatsapp ? "Whatsapp" : item.channel === CampaignChannel.push ? "Push" : "Email"}</span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.title}>Template</span>
                      <span>{item.template === CampaignTemplate.event ? "Eventos" : item.template === CampaignTemplate.info ? "Informar" : "Outro"}</span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.title}>Destinatários</span>
                      <span>{item.totalCustomers}</span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.title}>Status</span>
                      <span>{item.status === CampaignStatus.Sent ? "Enviado" : getStatusLabel(item.status)}</span>
                    </div>
                  </div>
                )
            }
          </>
        );
      })
    ) : (
      <>
        <tr><Skeleton variant="rectangular" width="100%" height={40} /></tr>
        <tr><Skeleton variant="rectangular" width="100%" height={40} /></tr>
        <tr><Skeleton variant="rectangular" width="100%" height={40} /></tr>
        <tr><Skeleton variant="rectangular" width="100%" height={40} /></tr>
        <tr><Skeleton variant="rectangular" width="100%" height={40} /></tr>
      </>
    )
    );
  }, [campaignsData?.data, dimensions.width, isLoading, isSchedule, onClickItem]);

  return (
    <BlurPremium feature={FeatureTypeEnum.CRM}>
      <div id={styles.CrmCampaignList}>
        <div className={styles.table}>
          {dimensions.width > 650 && 
            <div className={styles.header}>
              {titleHeader.map((item) => (<div>{item}</div>))}
            </div>
          }
          {!isLoading && !campaignsData?.data.length && (
            <div className={styles.empty}>
              <div className={styles.cardEmpty}>
                <div className={styles.cardImg} style={{ paddingLeft: 0 }}>
                  <img src="/assets/img/campaign-empty-1.svg" alt="" />
                  <span>1</span>
                </div>
                <p>A ferramenta de <b>Campanhas de Marketing</b> foi feita para você para enviar comunicados, promoções e se relacionar com seus clientes!</p>
              </div>
              <div className={styles.cardEmpty}>
                <div className={styles.cardImg} style={{ padding: "5rem 4rem 4rem" }}>
                  <img src="/assets/img/campaign-empty-2.svg" alt="" />
                  <span>2</span>
                </div>
                <p>Utilize os <b>clientes do CRM do seu estabelecimento</b> e comece a enviar campanhas!</p>
              </div>
            </div>
          )}
          {List}
        </div>
        {!isLoading && campaignsData?.data.length ? (
          <Pagination
            style={{ alignSelf: dimensions.width > 650 ? "flex-end" : "center" }}
            className={styles.pagination}
            defaultPage={campaignsData?.currentPage}
            count={campaignsData?.totalPages}
            onChange={(ev, page) => getDataHandle(page)}
            variant="outlined"
            shape="rounded"
            sx={{
              '.MuiPaginationItem-root': {
                borderColor: '#BDBDBD',
                color: '#001537',
              },
              '.Mui-selected': {
                fontWeight: 'bold',
                borderColor: '#001537',
              },
            }}
          />
        ) : <div/>}
      </div>
    </BlurPremium>
  );
};

export default CrmCampaignList;
