import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useError } from "./contexts/error/ErrorContext";
import { IFilterValue } from "./components/filter/IFilter";
import GetCashiersUseCase from "../application/useCases/GetCashiersUseCase";
import CashlessDashboardService from "services/api/cashlessDashboard/CashlessDashboardService";
import GetFinancialDataUseCase from "../application/useCases/GetFinancialDataUseCase";
import { ICashierItem } from "./components/filter/ICashier";
import GetRechargePaymentMethodsDataUseCase from "../application/useCases/GetRechargePaymentMethodsDataUseCase";
import GetTopOperatorsUseCase from "../application/useCases/GetTopOperatorsUseCase";
import GetTopCustomersUseCase from "../application/useCases/GetTopCustomersUseCase";
import GetTopDevicesUseCase from "../application/useCases/GetTopDevicesUseCase";
import { IOriginType } from "./components/rechargeOrigin/interfaces/IOriginType";
import GetTopProductsUseCase from "../application/useCases/GetTopProductsUseCase";
import GetCategoriesUseCase from "../application/useCases/GetCategoriesUseCase";
import GetCategoryProductsUseCase from "../application/useCases/GetCategoryProductsUseCase";
import ExportTopProductsUseCase from "../application/useCases/ExportTopProductsUseCase";
import ExportCategoriesUseCase from "../application/useCases/ExportCategoriesUseCase";
import ExportTopDevicesUseCase from "../application/useCases/ExportTopDevicesUseCase";
import ExportTopCustomersUseCase from "../application/useCases/ExportTopCustomersUseCase";
import ExportTopOperatorsUseCase from "../application/useCases/ExportTopOperatorsUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetProductionInfoUseCase } from "../application/useCases/GetProductionInfoUseCase";
import { CashiersTypesCollection } from "services/api/outputreport";
import { IGetCashiersResponse } from "../domain/dto/getCashiers/IGetCashiersResponse";
import { FormatCashierDate } from "pages/private/relatorio/components/OutputReport/utils";
import { api } from "services/api/Api";
import GetRechargeMethodsDataUseCase from "../application/useCases/GetRechargeMethodsData";
import GetPreRechargeMethodsUseCase from "../application/useCases/GetPreRechargeMethodsUseCase";
import { IGetPreRechargeMethodsDataResponse } from "../domain/dto/preRechargePaymentMethodsData/IGetPreRechargeMethodsDataResponse";
import { TicketPaymentDetailsTypes } from "../domain/dto/rechargePaymentMethodsData/IGetRechargePaymentMethodsDataResponse";
import { ProductionInfoResponse } from "../domain/dto/productionInfo/getProductionInfoResponse";
import { ITopOperators } from "./components/topOperators/interfaces/ITopOperators";
import { ITopDevices } from "./components/topDevices/interfaces/ITopDevices";
import { ITopCustomers } from "./components/topCustomers/interfaces/ITopCustomers";
import { ITopProducts } from "./components/topProducts/interfaces/ITopProducts";

const cashlessDashboardService = CashlessDashboardService();

const UseSalesDashboardPage = () => {
  const { currentLocal } = useLocal();
  const [filter, setFilter] = useState<IFilterValue>({
    cashierIds: [],
  } as IFilterValue);

  const [filterType, setFilterType] = useState("cashier");

  const [cashiers, setCashiers] = useState<ICashierItem[]>([]);
  const [cachedAt, setCachedAt] = useState<string | undefined>();
  const [type, setType] = useState<IOriginType>(IOriginType.recharged);
  const { clearErrors } = useError();
  const { toast } = useUi();
  const [firstData, setFirstData] = useState(false);
  const [preRechargeData, setPreRechargeData] = useState<IGetPreRechargeMethodsDataResponse>();
  const [triggerOperator, setTriggerOperator] = useState(false)
  const [triggerCustomer, setTriggerCustomer] = useState(false)
  const [triggerDevices, setTriggerDevices] = useState(false)
  const [triggerProducts, setTriggerProducts] = useState(false)
  const [triggerCategories, setTriggerCategories] = useState(false)
  const [expiresAt, setExpiresAt] = useState<string | undefined>()
  const [rechargeMethodsData, setRechargeMethodsData] = useState<TicketPaymentDetailsTypes | undefined>()
  const [productInfo, setProductInfo] = useState<ProductionInfoResponse | undefined>()
  const [topOperators, setTopOperators] = useState<ITopOperators | undefined>()
  const [devices, setDevices] = useState<ITopDevices | undefined>()
  const [customer, setCustomer] = useState<ITopCustomers | undefined>()
  const [products, setProducts] = useState< ITopProducts | undefined>()
  const userList = useMemo(() => [
    "B50D92A4-88B3-431E-89E4-5E39EC869A32",
    "3AF67B09-737A-40C9-8815-F165067F90CF",
    "DCBD2EB3-302C-43C1-8A12-F9632F2BAB12"
  ], []);

  const onChangeFilterHandle = (value: IFilterValue) => {
    const { cashierIds, ...rest } = value;
    const shouldUseNewSchema = userList.includes(getLoggedUser());

    setFirstData(false);
    setFilter({ 
      cashierIds, 
      ...rest, 
      aux: !filter.aux, 
      shouldUseNewSchema: shouldUseNewSchema,
      ownerId: currentLocal?.id,
    });
    clearErrors();
  };

  /**
   * Métodos de GET DATA
   */

  const getFinancialData = useCallback(async () => {  
    if (filter.cashierIds.length) {
      const cashierIds = filter.cashierIds.map((item) => item.id);
      const response = await GetFinancialDataUseCase(cashlessDashboardService, {
        filter,
      });
      const preRechargeRes = await GetPreRechargeMethodsUseCase(cashlessDashboardService, cashierIds);
      setCachedAt(response.cacheInfo?.cachedAt)
      setExpiresAt(response.cacheInfo?.expiresAt)
      setFirstData(true);
      setPreRechargeData(preRechargeRes);
      return { response, preRechargeRes };
    }
  }, [filter]);

  const getRechargePaymentMethodsData = useCallback(async () => {
    if (filter.cashierIds.length)
      return await GetRechargePaymentMethodsDataUseCase(
        cashlessDashboardService,
        { filter }
      );
  }, [filter]);

  const getRechargeMethodsData = useCallback(async (): Promise<TicketPaymentDetailsTypes | undefined> => {
    if (filter.cashierIds.length) {
      const response = await GetRechargeMethodsDataUseCase(cashlessDashboardService, {
        filter,
      });
      setRechargeMethodsData(response)
      return response
    }

  }, [filter]);

  const getTopOperators = useCallback(
    async ({ page }) => {

      const response = await GetTopOperatorsUseCase(
        cashlessDashboardService,
        { filter, page, pageSize: 20 },
        type
      );
      setTriggerOperator(!triggerOperator)
      setTopOperators(response)
      return response
    },
    [filter, type]
  );

  const exportTopOperators = useCallback(async () => {
    try {
      await ExportTopOperatorsUseCase(
        cashlessDashboardService,
        { filter, localId: currentLocal?.id },
        type
      );
      toast("Em breve você irá receber o relatório no email", "success");
    } catch {
      toast(
        "Ocorreu um erro ao gerar o excel. Tente novamente mais tarde",
        "error"
      );
    }
  }, [currentLocal?.id, filter, toast, type]);

  const getTopCustomers = useCallback(
    async ({ page }) => {      
      const response = await GetTopCustomersUseCase(
        cashlessDashboardService,
        { filter, page, pageSize: 20 },
        type
      );
      setTriggerCustomer(!triggerCustomer)
      setCustomer(response)
      return response
    },
    [filter, type]
  );

  const exportTopCustomers = useCallback(async () => {
    try {
      await ExportTopCustomersUseCase(
        cashlessDashboardService,
        { filter, localId: currentLocal?.id },
        type
      );
      toast("Em breve você irá receber o relatório no email", "success");
    } catch {
      toast(
        "Ocorreu um erro ao gerar o excel. Tente novamente mais tarde",
        "error"
      );
    }
  }, [currentLocal?.id, filter, toast, type]);

  const getTopDevices = useCallback(
    async ({ page }) => {
      const response = await GetTopDevicesUseCase(
        cashlessDashboardService,
        { filter, page, pageSize: 20 },
        type
      );
      setTriggerDevices(!triggerDevices)
      setDevices(response)
      return response
    },
    [filter, type]
  );

  const exportTopDevices = useCallback(async () => {
    try {
      await ExportTopDevicesUseCase(
        cashlessDashboardService,
        { filter, localId: currentLocal?.id },
        type
      );
      toast("Em breve você irá receber o relatório no email", "success");
    } catch {
      toast(
        "Ocorreu um erro ao gerar o excel. Tente novamente mais tarde",
        "error"
      );
    }
  }, [currentLocal?.id, filter, toast, type]);

  const getTopProducts = useCallback(
    async ({ page, sortField, sortOrientation }) => {
      const response =  await GetTopProductsUseCase(cashlessDashboardService, {
        filter,
        page,
        pageSize: 20,
        sortField,
        sortOrientation,
      });
      setTriggerProducts(!triggerProducts)
      setProducts(response)
      return response
    },
    [filter]
  );

  const exportTopProducts = useCallback(async () => {
    try {
      ExportTopProductsUseCase(cashlessDashboardService, {
        filter,
        localId: currentLocal?.id,
      });
      toast("Em breve você irá receber o relatório no email", "success");
    } catch {
      toast(
        "Ocorreu um erro ao gerar o excel. Tente novamente mais tarde",
        "error"
      );
    }
  }, [currentLocal?.id, filter, toast]);

  const getCategories = useCallback(
    async ({ page, sortField, sortOrientation }) => {
      setTriggerCategories(!triggerCategories)
      return await GetCategoriesUseCase(cashlessDashboardService, {
        filter,
        page,
        pageSize: 20,
        sortField,
        sortOrientation,
      });
    },
    [filter]
  );

  const exportCategories = useCallback(async () => {
    try {
      await ExportCategoriesUseCase(cashlessDashboardService, {
        filter,
        localId: currentLocal?.id,
      });
      toast("Em breve você irá receber o relatório no email", "success");
    } catch {
      toast(
        "Ocorreu um erro ao gerar o excel. Tente novamente mais tarde",
        "error"
      );
    }
  }, [currentLocal?.id, filter, toast]);

  const getCategoryProducts = useCallback(
    async ({ page, sortField, sortOrientation }, categoryId) => {
      return await GetCategoryProductsUseCase(
        cashlessDashboardService,
        { filter, page, pageSize: 20, sortField, sortOrientation },
        categoryId
      );
    },
    [filter]
  );

  const getProductionInfo = useCallback(async () => {
    const request = await GetProductionInfoUseCase(cashlessDashboardService, {
      filter,
    });
    setProductInfo(request)
    return request;
  }, [filter]);

  const getCashiersByPeriod = useCallback(
    async (localId, startDate, endDate) => {
      const response = await api.get<CashiersTypesCollection>(
        "/Reports/Sales/Cashiers",
        {
          params: {
            localId,
            startDate,
            endDate,
          },
        }
      );

      const parsedResponse = response.data.map<IGetCashiersResponse>(
        (item) => ({
          id: item.Id,
          name: FormatCashierDate({
            startDate: item.StartDate,
            endDate: item.EndDate,
          }),
          startDate: item.StartDate,
          endDate: item.EndDate,
          localId,
        })
      );

      return parsedResponse;
    },
    []
  );

  /**
   * Métodos de UseEffects
   */
  useEffect(() => {
    (async () => {
      const response = await GetCashiersUseCase(
        cashlessDashboardService,
        currentLocal!.id
      );
      const shouldUseNewSchema = userList.includes(getLoggedUser());

      setCashiers(response);
      setFilter((prev) => ({ 
        ...prev, 
        cashierIds: [response[0]], 
        shouldUseNewSchema: shouldUseNewSchema,
        ownerId: currentLocal?.id,
      }));
    })();
  }, [currentLocal, userList]);

  useEffect(() => {
    if (expiresAt && rechargeMethodsData && productInfo 
      && new Date() > new Date(expiresAt)
    ) {
      getFinancialData()
      getRechargeMethodsData()
      getProductionInfo()
    }
  }, [triggerOperator, triggerCustomer, triggerDevices, triggerProducts, triggerCategories])

  useEffect(() => {
    if (expiresAt && topOperators 
      && new Date() > new Date(expiresAt)
    ) {
      getTopOperators(0)
    }
  }, [triggerCustomer, triggerDevices, triggerProducts, triggerCategories])

  useEffect(() => {
    if (expiresAt && customer 
      && new Date() > new Date(expiresAt)
    ) {
      getTopCustomers(0)
    }
  }, [triggerDevices, triggerProducts, triggerCategories])

  useEffect(() => {
    if (expiresAt && devices 
      && new Date() > new Date(expiresAt)
    ) {
      getTopDevices(0)
    }
  }, [triggerProducts, triggerCategories])

  useEffect(() => {
    if (expiresAt && products 
      && new Date() > new Date(expiresAt)
    ) {
      getTopProducts(0)
    }
  }, [triggerCategories])

  // TODO: Remover
  // Método temporário para validação do dashboard com tabelas Invoice
  const getLoggedUser = () => {
    const loggedUserData = localStorage.getItem("@auth");
    if (loggedUserData) {
      return JSON.parse(loggedUserData)?.user?.id?.toUpperCase();
    }

    return null;
  }

  return {
    filter,
    cashiers,
    filterType,
    type,
    setType,
    onChangeFilterHandle,
    getFinancialData,
    getRechargePaymentMethodsData,
    getTopOperators,
    getTopCustomers,
    getTopDevices,
    getTopProducts,
    getCategories,
    getCategoryProducts,
    exportTopOperators,
    exportTopCustomers,
    exportTopDevices,
    getCashiersByPeriod,
    exportTopProducts,
    exportCategories,
    getProductionInfo,
    setFilterType,
    getRechargeMethodsData,
    firstData,
    cachedAt,
    preRechargeData,
    triggerOperator,
    expiresAt,
    triggerCustomer,
    triggerDevices,
    triggerProducts,
    triggerCategories
  };
};

export default UseSalesDashboardPage;
