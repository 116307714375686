import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import styles from './SalesPerDay.module.scss'
import UseDimension from 'components/dimension/UseDimension';
import { format, parseISO } from 'date-fns';
import FallbackMainDash from '../fallbackMainDash/FallbackMainDash';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface SalesPerDayProps {
    data?: {
        records: {
            day: number,
            total: number
        }[],
        period: string
    };
    isLoading: boolean
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip} >
                <p><b>Dia {label}</b></p>
                <p>{payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
        );
    }

    return null;
};

const SalesPerDay = ({ data, isLoading }: SalesPerDayProps) => {
    const { isMobile } = UseDimension()

    const filterData = data?.records.filter(record => record.total !== 0)

    return (
        <div id={styles.SalesPerDay}>
            <div className={styles.top}>
                <div>
                    <p className={styles.title}>Vendas diárias durante o mês</p>
                    <p className={styles.description}>Acompanhe as vendas durante o mês</p>
                </div>
                <p className={styles.description}>{data && format(parseISO(data?.period + "Z"), 'dd/MM/yyyy HH:mm')}</p>
            </div>
            {isMobile ? (
                <>
                    {data?.records.map(item => {
                        return (
                            <div className={styles.lineInfo}>
                                <p className={styles.day}>Dia {item.day}</p>
                                <p className={styles.value}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        )
                    })}

                </>
            ) : (
                <>
                    {isLoading ? (
                        <div className={styles.animatedLoading}></div>
                    ) : (
                        <>
                            {filterData && filterData.length > 0 ? (
                                <ResponsiveContainer width="100%" height={400}>
                                    <AreaChart
                                        barGap={4}
                                        data={filterData}
                                        margin={{
                                            top: 30,
                                            bottom: 20,
                                        }}
                                    >
                                        <XAxis dataKey="day" tickLine={false} />
                                        <YAxis
                                            tickLine={false}
                                            tick={false}
                                        />
                                        <Tooltip
                                            content={({ active, payload, label }) =>
                                                <CustomTooltip active={active} payload={payload} label={label} />}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="total"
                                            stroke="#001537"
                                            fill='url(#colorGradient)'
                                            strokeWidth={2}
                                            dot={{ r: 2, fill: '#001537' }}
                                            activeDot={{ r: 4 }}
                                        />
                                        <defs>
                                            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#32008E" stopOpacity={0.3} />
                                                <stop offset="95%" stopColor="#32008E" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                    </AreaChart>
                                </ResponsiveContainer>
                            ) : (
                                <FallbackMainDash />
                            )}
                        </>
                    )}
                </>

            )}


        </div>
    );
};

export default SalesPerDay;
