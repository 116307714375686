import { useUi } from "contexts/userInterface/UserInterfaceContext";
import GetBaseLIstUseCase from "modules/events/application/useCases/baseList/GetBaseLIstUseCase";
import { IDiscountListResponse } from "modules/events/domain/dto/IEventsResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DiscountBaseListApi from "services/api/events/discountsList/DiscountBaseListApi";
import { IListObj, parseObjToArr, IArrObj } from "../../utils/utils";
import { useAuth } from "modules/auth/presentation/context/AuthContext";


interface IUseDiscountList {
  filteredDiscounts: IDiscountListResponse[] | undefined;
  gotToAddDiscountsList: () => void;
  getDiscountsList: () => Promise<void>;
  adminAccess: boolean
  discountsFilterList: IListObj[]
  filterByList: (value: IListObj | null) => void
}

const UseDiscountList = (): IUseDiscountList => {
  const { hasAccessRole } = useAuth();
  const adminAccess = hasAccessRole('AdminNomeLista');
  const { hideLoading, showLoading } = useUi();
  const { currentLocal } = useLocal();
  const [discountsList, setDiscountsList] = useState<IDiscountListResponse[]>();
  const [filteredDiscounts, setfilteredDiscounts] = useState<IDiscountListResponse[]>();
  const baseListService = DiscountBaseListApi();
  const { push } = useHistory();



  const getDiscountsList = async () => {
    showLoading();
    try {
      if (currentLocal) {
        const response = await GetBaseLIstUseCase(baseListService, currentLocal.id);
        setDiscountsList(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    getDiscountsList();
  }, [currentLocal]);

  useEffect(() => {
    if (discountsList) {
      setfilteredDiscounts(discountsList)
    }
  }, [discountsList]);
  

  const filterByList = (value: IListObj | null) => {
    if (value) {
      setfilteredDiscounts(filteredDiscounts?.filter((item) => item.id === value.id));
    } else {
      setfilteredDiscounts(discountsList);
    }
  };

  const { discountsFilterList } = useMemo(() => {
    const discounts: IListObj[] = discountsList?.map((item) => ({ id: item.id, name: item.name })) ?? [];

    const discountsFilterList = parseObjToArr(
      discounts?.reduce(
        (reducer, current) => ({
          ...reducer,
          [current.id]: current.name,
        }),
        {} as IArrObj
      ) || ({} as IArrObj)
    );

    return {
      discountsFilterList,
    };
  }, [discountsList]);

  const gotToAddDiscountsList = () => {
    push("/private/descontos/adicionarLista");
  };

  return {
    filteredDiscounts,
    gotToAddDiscountsList,
    getDiscountsList,
    adminAccess,
    discountsFilterList,
    filterByList
  };
};

export default UseDiscountList;
