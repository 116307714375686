import { IAppService } from "modules/app/domain/interfaces/IAppService";
import { IGestaoVendas } from "modules/gestaoVendas/presentation/pages/interfaces/IGestaoVendas";

export const GetProfileConfigurationUseCase = async (service: IAppService, localId: string): Promise<IGestaoVendas> => {
  return await service.getProfileConfiguration(localId);
}


export const VerifyProfileExistsUseCase = async (service: IAppService, localId: string): Promise<boolean> => {
  return await service.verifyProfileExists(localId);
}
