import styles from "./Card.module.scss";

interface ICard {
  title: string;
  text: string;
  img: string;
  date: string;
  link: string;
}

const Card = ({ title, text, img, date, link }: ICard) => {
  return (
    <div id={styles.Card}>
      <div className={styles.contentTitle}>
        <div className={styles.logo}>
          <img src="/assets/img/icon-logo.svg" alt="logo meep" />
        </div>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.date}>{date}</p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.boxImg}>
        <img src={img} alt="imagem" />
        </div>
        <p>{text}</p>
        <a href={link}>
          Saiba mais...
        </a>
      </div>
    </div>
  );
};
export default Card;
