import { IFilterDeviceProfileRequest } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceProfile/IFilterDeviceProfileRequest";
import { IDeviceProfileListResponse } from "modules/config/deviceConfig/domain/dto/deviceProfile/getDeviceProfile/IGetDeviceProfileListResponse";
import { IDeviceProfileService } from "modules/config/deviceConfig/domain/interface/IDeviceProfileService";
import { IFilterProfileDevice } from "modules/config/deviceConfig/presentation/components/deviceProfile/filterProfile/IFilterDeviceProfile";

export const GetProfileListPaginationUseCase = async (profileService: IDeviceProfileService, localId: string, profileFilter: IFilterProfileDevice): Promise<IDeviceProfileListResponse> => {
    const filter: IFilterDeviceProfileRequest = {
        operationType: profileFilter.operation?.id,
        offset: profileFilter.pagination?.offset ?? 0,
        limit: profileFilter.pagination?.limit ?? 50,
    }
    const response = await profileService.getDeviceProfileList(localId, filter);

    return {
        items: response.items.map(item => ({
            id: item.id,
            name: item.name,
            description: item.description,
            isDefault: item.isDefault,
            enabled: item.isEnabled,
            operationsMethods: item.operation.methods.map(method => ({ name: method.name, type: method.type, enabled: method.enabled })),
            catalogs: item.catalogs,
            devices: item.devices,
            paymentMethods: item.payment.methods.map(method => ({ name: method.name, type: method.type, enabled: method.enabled })),
        })),
        totalCount: response.totalCount,
    };
}

export default GetProfileListPaginationUseCase
