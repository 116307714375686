
import { IPostCashCancellationParams } from "modules/cashCancellation/domain/dto/IPostCashCancellationParams";
import { IPostCancellationService } from "../interfaces/ICashCancellationApiService";

const PostCancellation = async (
  accountService: IPostCancellationService,
  body: IPostCashCancellationParams
): Promise<any> => {
  return await accountService.postCancellation(body);
};

export default PostCancellation;
