import React, { FC, useCallback } from 'react'
import styles from './PopMenu.module.scss'
import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import UseDimension from 'components/dimension/UseDimension'
export interface IMenuProps {
    onClickEdit?: () => void
    onClickRemove?: () => void
    actions?: {
        label: string,
        icon?: JSX.Element | string,
        action: () => void
    }[]
    editLabel?: string
    removeLabel?: string
    //propertys
}
const PopMenu: FC<IMenuProps> = ({
    onClickEdit,
    editLabel,
    onClickRemove, 
    actions
}) => {
    const { dimensions } = UseDimension();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openMoreMenuHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMoreMenuHandle = () => {
        setAnchorEl(null);
    };

    const onClickEditHandle = useCallback(() => {
        onClickEdit?.()
    }, [onClickEdit]);

    const onClickRemoveHandle = useCallback(() => {
        onClickRemove?.()
    }, [onClickRemove]);

    return (
        (onClickEdit || onClickRemove || !!actions?.length) ? <>
            <IconButton onClick={openMoreMenuHandle}>
                <Icon style={{ color: dimensions.width <= 400 ? "rgba(0, 21, 55, 1)" : "" }}>more_vert</Icon>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={closeMoreMenuHandle}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {onClickEdit && (
                    <MenuItem
                        onClick={() => {
                            closeMoreMenuHandle();
                            onClickEditHandle();
                        }}
                    >
                        <EditOutlined /> {editLabel || "Editar"}
                    </MenuItem>
                )}
                {onClickRemove && (
                    <MenuItem
                        onClick={() => {
                            closeMoreMenuHandle();
                            onClickRemoveHandle();
                        }}
                    >
                        <DeleteOutlined />Remover
                    </MenuItem>
                )}
                {actions?.map(item => (
                    <MenuItem
                    key={item.label}
                        onClick={() => {
                            closeMoreMenuHandle();
                            item.action();
                        }}
                        className={styles.popMenuItem}
                    >
                        {item.icon}{item.label}
                    </MenuItem>
                ))}
            </Menu>
        </> 
        : 
        <></>

    )
}
export default PopMenu