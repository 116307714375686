import { IUseCaseParams } from "modules/salesDashboard/domain/interfaces/IUseCaseParams"
import ISalesDashboardService from "../../domain/interfaces/ISalesDashboardService"
import { CategoryRankingValue } from "../../presentation/components/categoryRanking/CategoryRankingValue"
import { makeFilterRequest } from "./MakeFilterRequest"

const GetRankingSubCategoryUseCase = async (service: ISalesDashboardService, { sortField = 'total', ...params }: IUseCaseParams, subCategoryId?: string): Promise<CategoryRankingValue> => {
    const request = makeFilterRequest({ sortField, ...params })
    
    return await service.getTopSubCategories(request, subCategoryId);
}
export default GetRankingSubCategoryUseCase