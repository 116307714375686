import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { ImpressoraPadraoItem } from "modules/products/domain/models/product/ImpressoraPadrao";
import { useState } from "react";
import styles from "./ChangePrinterModal.module.scss";

interface ModalProps {
  open: boolean;
  title: string;
  printers: ImpressoraPadraoItem[];
  onClose: () => void;
  onConfirm: (printerId?: string) => void;
}

export const ChangePrinterModal = ({
  open,
  title,
  printers,
  onClose,
  onConfirm,
}: ModalProps) => {
  const [selectedPrinter, setSelectedPrinter] =
    useState<ImpressoraPadraoItem>();
  const [error, setError] = useState("");

  const handleValidate = () => {
    // setError('')
    // if (!selectedPrinter) {
    //     setError('Seleciona a impressora padrão')
    //     return
    // }
    onConfirm(selectedPrinter?.id);
  };

  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.form}>
          <FormItemContainer
            label="Impressora"
            className={styles.formContainer}
          >
            <AutoComplete
              variant="outlined"
              placeholder="Selecione uma impressora"
              options={printers}
              getOptionLabel={(option) => option.name}
              onChange={(_evt, item) =>
                setSelectedPrinter(item as ImpressoraPadraoItem)
              }
              error={!!error}
              helperText={error}
            />
          </FormItemContainer>
        </div>
        <div className={styles.buttonsContainer}>
          <Button variant="outlined" fullWidth={false} onClick={onClose}>
            Cancelar
          </Button>
          <Button fullWidth={false} onClick={handleValidate}>
            Salvar
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
