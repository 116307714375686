import { IComplienceService } from "modules/tidsAdicionais/domain/interfaces/IComplienceService";

const RejectTIDsSolicitationUseCase = async (
  service: IComplienceService,
  id: string,
) => {
  const response = await service.rejectTIDsSolicitation(id);
  return response;
};

export default RejectTIDsSolicitationUseCase;
