import { ICashlessVoucherRequest } from 'modules/events/domain/dto/IEventsResponse';
import { ICashlessVoucerApi } from '../../interfaces/ICashlessVoucherService';

const CreateCashlessVoucherUseCase = async (
  cashlessVoucherService: ICashlessVoucerApi,
  cashlessVoucher: ICashlessVoucherRequest,
): Promise<void> => {
  return await cashlessVoucherService.createCashlessVoucher(cashlessVoucher);
};

export default CreateCashlessVoucherUseCase;