import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import AddCieloPosUseCase from "modules/salesPos/application/AddCieloPosUseCase";
import AddPosUseCase from "modules/salesPos/application/AddPosUseCase";
import PostAddSimplePosUseCase from "modules/salesPos/application/PostAddSimplePosUseCase";
import PostExternalPosUseCase from "modules/salesPos/application/PostExternalPosUseCase";
import { IPostAddExternalPosRequest } from "modules/salesPos/domain/dto/IPostAddExternalPosRequest";
import { IPostAddPosRequest } from "modules/salesPos/domain/dto/IPostAddPosRequest";
import { IPostAddSimplePosRequest } from "modules/salesPos/domain/dto/IPostAddSimplePosRequest";
import { ChangeEvent, useCallback, useState } from "react";
import AddExternalPosApi from "services/api/salesPos/AddExternalPosApi";
import AddPosApi from "services/api/salesPos/AddPosApi";
import AddSimplePosApi from "services/api/salesPos/AddSimplePosApi";

interface ISimpleFormErrors {
    name?: string;
    posId?: string;
    terminalId?: string;
}

interface IExternalPosErrors {
    integrationCode?: string;
    serialNumber?: string;
}

const service = AddPosApi();
const serviceSimplePos = AddSimplePosApi();
const serviceExternalPos = AddExternalPosApi();

export const UseAddPos = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [cieloSmartForm, setCieloSmartForm] = useState<IPostAddPosRequest>({} as IPostAddPosRequest);
    const [error, setError] = useState("");
    const [cieloSimpleForm, setCieloSimpleForm] = useState<IPostAddSimplePosRequest>({} as IPostAddSimplePosRequest);
    const [errorsSimpleForm, setErrorsSimpleForm] = useState<ISimpleFormErrors>({} as ISimpleFormErrors);
    const [externalPosForm, setExternalPosForm] = useState<IPostAddExternalPosRequest>({} as IPostAddExternalPosRequest);
    const [errorsExternalPos, setErrorsExternalPos] = useState<IExternalPosErrors>({} as IExternalPosErrors);

    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const { subscription } = usePlans();

    const changeHandleSmart = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setError("");
        setCieloSmartForm((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const handleSubmitSmart = useCallback(async () => {
        if (currentLocal) {
            if (!cieloSmartForm.imei) {
                return setError("IMEI é obrigatório");
            }
    
            try {
                setIsLoading(true);
                if (subscription?.local.creationStep && subscription.local.creationStep === ProspectType.Cielo) {
                    await AddCieloPosUseCase(service, {...cieloSmartForm, localId: currentLocal.id, acquirerType: AcquirerTypeEnum.Cielo });
                } else {
                    await AddPosUseCase(service, {...cieloSmartForm, localId: currentLocal.id, acquirerType: AcquirerTypeEnum.Cielo });
                }
                return "ok";
            } catch (error) {
                console.error(error);
                return "error";
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal, cieloSmartForm, subscription]);

    const simplePosValidation = useCallback(() => {
        let _errors: ISimpleFormErrors = {};
        let hasError = false;
      
        if (!cieloSimpleForm.name) {
          _errors = { ..._errors, name: "Campo obrigatório" };
          hasError = true;
        }
        if (!cieloSimpleForm.posId) {
          _errors = { ..._errors, posId: "Campo obrigatório" };
          hasError = true;
        }
        if (!cieloSimpleForm.deviceId) {
          _errors = { ..._errors, terminalId: "Campo obrigatório" };
          hasError = true;
        }
      
        setErrorsSimpleForm(_errors);
      
        return !hasError;
    }, [cieloSimpleForm]);

    const externalPosValidation = useCallback(() => {
        let _errors: IExternalPosErrors = {};
        let hasError = false;
      
        if (!externalPosForm.integrationCode) {
          _errors = { ..._errors, integrationCode: "Campo obrigatório" };
          hasError = true;
        }
        if (!externalPosForm.serialNumber) {
          _errors = { ..._errors, serialNumber: "Campo obrigatório" };
          hasError = true;
        }
      
        setErrorsExternalPos(_errors);
      
        return !hasError;
    }, [externalPosForm]);

    const changeHandleSimple = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setErrorsSimpleForm((prev) => ({ ...prev, [ev.target.name]: "" }));
        setCieloSimpleForm((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeHandleExternal = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setErrorsExternalPos((prev) => ({ ...prev, [ev.target.name]: "" }));
        if (externalPosForm.acquirerType === AcquirerTypeEnum.PagSeguro) {
            setExternalPosForm((prev) => ({ ...prev, integrationCode: ev.target.value }));
        }
        setExternalPosForm((prev) => ({ ...prev, [ev.target.name]: ev.target.name === "acquirerType" ? Number(ev.target.value) : ev.target.value }));
    }, [externalPosForm]);

    const handleClearErrors = () => {
        setErrorsExternalPos({});
        setErrorsSimpleForm({});
    };

    const handleSubmitSimple = useCallback(async () => {
        if (currentLocal) {
            if (!simplePosValidation()) return;
    
            try {
                setIsLoading(true);
                await PostAddSimplePosUseCase(serviceSimplePos, currentLocal.id, {...cieloSimpleForm, localId: currentLocal.id, type: 0 });
                return "ok";
            } catch (error) {
                toast("Verifique se o ID está correto.", "error");
                return "error";
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal, cieloSimpleForm, simplePosValidation, toast]);

    const handleSubmitExternalPos = useCallback(async () => {
        if (currentLocal) {
            if (!externalPosValidation()) return;
    
            try {
                setIsLoading(true);
                await PostExternalPosUseCase(serviceExternalPos, {...externalPosForm, ownerId: currentLocal.id });
                return "ok";
            } catch (error) {
                return "error";
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal, externalPosForm, externalPosValidation]);

    return {
        isLoading,
        cieloSmartForm,
        error,
        cieloSimpleForm,
        setCieloSimpleForm,
        errorsSimpleForm,
        setErrorsSimpleForm,
        changeHandleSmart,
        handleSubmitSmart,
        changeHandleSimple,
        handleSubmitSimple,
        externalPosForm,
        errorsExternalPos,
        changeHandleExternal,
        handleSubmitExternalPos,
        handleClearErrors
    }
}
