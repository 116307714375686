import { useCallback, useState } from "react";
import GetSystemConfigListUseCase from "../../application/GetSystemConfigListUseCase";
import MenuConfigApi from "services/api/menuConfig/MenuConfigApi";
import { IGetSystemConfigResponse } from "../../domain/dto/IGetSystemConfigResponse";
import { IPostSystemConfigPayload } from "../../domain/dto/IPostSystemConfigPayload";
import PostSystemConfigUseCase from "../../application/PostSystemConfigUseCase";
import PutSystemConfigUseCase from "../../application/PutSystemConfigUseCase";
import DeleteSystemConfigUseCase from "../../application/DeleteSystemConfigUseCase";
import GetSystemIdsUseCase from "../../application/GetSystemIdsUseCase";
import { IGetSystemIdsResponse } from "../../domain/dto/IGetSystemIdsResponse";
import GetSystemConfigByIdUseCase from "../../application/GetSystemConfigByIdUseCase";
import { IGetSystemConfigByIdResponse } from "../../domain/dto/IGetSystemConfigByIdResponse";
import PostCreateMenuUseCase from "../../application/PostCreateMenuUseCase";
import { IPostCreateMenuPayload } from "../../domain/dto/IPostCreateMenuPayload";
import { IPutEditMenuPayload } from "../../domain/dto/IPutEditMenuPayload";
import PutEditMenuUseCase from "../../application/PutEditMenuUseCase";
import DeleteMenuUseCase from "../../application/DeleteMenuUseCase";
import { IPutMenuOrder } from "../../domain/dto/IPutMenuOrder";
import PutMenuOrderUseCase from "../../application/PutMenuOrderUseCase";
import GetMenuListUseCase from "../../application/GetMenuListUseCase";
import { IGetMenuListResponse } from "../../domain/dto/IGetMenuListResponse";
import GetMenuByIdUseCase from "../../application/GetMenuByIdUseCase";
import GetUpdatePermissionUseCase from "../../application/GetUpdatePermissionUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";

const menuConfigApi = MenuConfigApi();

export const UseMenuConfigPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [systemConfigList, setSystemConfigList] = useState<IGetSystemConfigResponse[]>([]);
    const [systemConfigById, setSystemConfigById] = useState<IGetSystemConfigByIdResponse>();
    const [systemIdsList, setSystemIdsList] = useState<IGetSystemIdsResponse[]>([]);
    const [menuList, setMenuList] = useState<IGetMenuListResponse[][]>();

    const { currentLocal } = useLocal();

    const getSystemConfigList = useCallback(
        async () => {
            try {
                setIsLoading(true);
                const response = await GetSystemConfigListUseCase(menuConfigApi);
                setSystemConfigList(response);
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );
    
    const getUpdatePermission = useCallback(
        async () => {
            try {
                setIsLoading(true);
                await GetUpdatePermissionUseCase(menuConfigApi, currentLocal?.systemIdentifier ?? 0);
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, [currentLocal?.systemIdentifier]
    );

    const getSystemConfigById = useCallback(
        async (menuConfigId: string) => {
            try {
                setIsLoading(true);
                const response = await GetSystemConfigByIdUseCase(menuConfigApi, menuConfigId);
                setSystemConfigById(response);
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const postSystemConfig = useCallback(
        async (payload: IPostSystemConfigPayload) => {
            try {
                setIsLoading(true);
                await PostSystemConfigUseCase(menuConfigApi, payload);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const putSystemConfig = useCallback(
        async (menuConfigId: string, payload: IPostSystemConfigPayload) => {
            try {
                setIsLoading(true);
                await PutSystemConfigUseCase(menuConfigApi, menuConfigId, payload);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const deleteSystemConfig = useCallback(
        async (menuConfigId: string) => {
            try {
                setIsLoading(true);
                await DeleteSystemConfigUseCase(menuConfigApi, menuConfigId);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const getSystemIds = useCallback(
        async () => {
            try {
                setIsLoading(true);
                const response = await GetSystemIdsUseCase(menuConfigApi);
                setSystemIdsList(response);
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const postCreateMenu = useCallback(
        async (payload: IPostCreateMenuPayload) => {
            try {
                setIsLoading(true);
                await PostCreateMenuUseCase(menuConfigApi, payload);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const putEditMenu = useCallback(
        async (payload: IPutEditMenuPayload) => {
            try {
                setIsLoading(true);
                await PutEditMenuUseCase(menuConfigApi, payload);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );
    
    const deleteMenu = useCallback(
        async (menuId: string) => {
            try {
                setIsLoading(true);
                await DeleteMenuUseCase(menuConfigApi, menuId);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );
    
    const putMenuOrder = useCallback(
        async (payload: IPutMenuOrder) => {
            try {
                setIsLoading(true);
                await PutMenuOrderUseCase(menuConfigApi, payload);
                return "ok";
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );
    
    const getMenulist = useCallback(
        async (systemConfigId: string) => {
            try {
                setIsLoading(true);
                const response = await GetMenuListUseCase(menuConfigApi, systemConfigId);
                setMenuList(response);
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    const getMenuById = useCallback(
        async (menuId: string) => {
            try {
                setIsLoading(true);
                const response = await GetMenuByIdUseCase(menuConfigApi, menuId);
                return response;
            } catch (error: any) {
                return error.response;
            } finally {
                setIsLoading(false);
            }
        }, []
    );

    return {
        isLoading,
        systemConfigList,
        systemConfigById,
        systemIdsList,
        getSystemConfigList,
        getSystemConfigById,
        postSystemConfig,
        putSystemConfig,
        deleteSystemConfig,
        getSystemIds,
        postCreateMenu,
        putEditMenu,
        deleteMenu,
        putMenuOrder,
        menuList,
        getMenulist,
        getMenuById,
        getUpdatePermission,
    }
}