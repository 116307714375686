import styles from "./VendaOnline.module.scss";
import { IAvailableDay, IGestaoVendas } from "../../pages/interfaces/IGestaoVendas";
import { IFormErrors, VendaOnlineAppMeep } from "./VendaOnlineAppMeep";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";

interface IVendaOnline {
  aplicativo: {
    nome: string;
    img: string;
  }[];
  checkedItem: boolean[];
  setCheckedItem: (checked: boolean[]) => void;
  values: IGestaoVendas;
  onChangeHandle: (name: string, value: string) => void;
  handleChangeDate: (days: IAvailableDay[]) => void;
  dateItens: IAvailableDay[];
  address?: IAddress;
  handleSaveAddress: (_address: IAddress) => void;
  loadingAddress: boolean;
  errors: IFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<IFormErrors>>;
}

const VendaOnline = ({
  aplicativo,
  setCheckedItem,
  checkedItem,
  values,
  dateItens,
  onChangeHandle,
  handleChangeDate,
  address,
  handleSaveAddress,
  loadingAddress,
  errors,
  setErrors
}: IVendaOnline) => {
  const handleActive = (index: number) => {
    const updatedActive = [...checkedItem];
    updatedActive[index] = !updatedActive[index];
    setCheckedItem(updatedActive);
  };

  

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        Agora, conta pra gente: você irá realizar <span>vendas online?</span>
      </p>
      <p className={styles.text}>
        Ofereça ao seu cliente mais informações do seu estabelecimento como:
        cardápio do dia e eventos, além de mais flexibilidade nas vendas.
      </p>
      <div className={styles.contentCards}>
        {aplicativo.map((item, index) => {
          return (
            <div
              className={checkedItem[index] ? styles.cardActive : styles.card}
              onClick={() => handleActive(index)}
            >
              <img src={item.img} alt="imagem aplicativo" />
              <p>{item.nome}</p>
            </div>
          );
        })}
      </div>
      {checkedItem.some((item) => item) && (
        <VendaOnlineAppMeep
          values={values}
          onChangeHandle={onChangeHandle}
          handleChangeDate={handleChangeDate}
          dateItens={dateItens}
          address={address}
          handleSaveAddress={handleSaveAddress}
          loading={loadingAddress}
          errors={errors}
            setErrors={setErrors}
        />
      )}
    </div>
  );
};

export default VendaOnline;
