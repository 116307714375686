import { useState } from "react";
import styles from "./ProfileBond.module.scss";
import { Button, FormControlLabel, RadioGroup } from "@material-ui/core";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { Info } from "@mui/icons-material";
import Radio from "components/ui/radio/Radio";
import UseDimension from "components/dimension/UseDimension";

interface IProfileBond {
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  setVinculo: React.Dispatch<React.SetStateAction<string>>
  postPerfilVinculo: () => void
  vinculo: string
}

const ProfileBond = ({setStep, step, postPerfilVinculo, setVinculo, vinculo} : IProfileBond) => {
  const [openSidesheet, setOpenSidesheet] = useState<string | null>(null);
  const { dimensions } = UseDimension();

  return (
    <>
      <div id={styles.ProfileBond}>
        <div>
          <p className={styles.text}>
            Escolha como deseja <b>vincular seus equipamentos</b>
          </p>
          {dimensions.width <= 900 && (
            <p className={styles.text}>
              Vínculo atual: {vinculo === '1' ? <b>Local</b> : <b>Evento</b>}
            </p>
          )}
        </div>
        <div>
        {dimensions.width > 900 && (
          <span className={styles.text}>
            Vínculo atual:  {vinculo === '1' ? <b>Local</b> : <b>Evento</b>}
          </span>
        )}
          <Button
            variant={"outlined"}
            onClick={() => setOpenSidesheet("true")}
            className={styles.bondButton}
          >
            Alterar vínculo
          </Button>
        </div>
      </div>
      <Sidesheet
        open={openSidesheet}
        onClose={() => {
          setOpenSidesheet(null);
          setStep(1);
        }}
        title={
          <h1>
            Vínculo de <b> equipamento</b>
          </h1>
        }
        content={
          step === 1 ? (
            <div className={styles.contentSidesheet}>
              <div className={styles.info}>
                <Info className={styles.iconInfo} />
                <span className={styles.textInfo}>
                  Escolha como deseja vincular seus equipamentos
                </span>
              </div>
              <RadioGroup defaultValue={vinculo} name="fix-bond" onChange={(e) => setVinculo(e.target.value)}>
                <div className={styles.itemRadio}>
                  <FormControlLabel
                    value={'1'}
                    control={<Radio />}
                    label="Local"
                  />
                  <p>
                    Essa seleção implica que deseja vincular os mesmos
                    equipamentos para todos os caixas/evento criados.
                  </p>
                </div>
                <div className={styles.itemRadio}>
                  <FormControlLabel
                    value={'2'}
                    control={<Radio />}
                    label="Evento"
                  />
                  <p>
                    Essa seleção implica que deseja vincular diferentes
                    equipamentos para cada caixa/evento criado.
                  </p>
                </div>
              </RadioGroup>
            </div>
          ) : (
            <SidesheetFeedback
              text={
                <>
                  Deseja alterar o vínculo atual? <br/>Vínculo atual:  {vinculo === '1' ? <b>Local</b> : <b>Evento</b>}
                </>
              }
            />
          )
        }
        cancelButton
        continueButton={step === 1 ? "Concluir" : "Salvar"}
        handleContinueButton={step === 1 ? () => setStep(step + 1) : postPerfilVinculo}
        currentStep={step}
        totalSteps={3}
        feedback={
          <SidesheetFeedback text="Vinculo alterado com sucesso" success />
        }
      />
    </>
  );
};

export default ProfileBond;
