import Layout from "components/layout/presentation/Layout";
import EditFormPlaceSchedulePage from "modules/schedule/presentation/pages/editformPlaceItemSchedule/EditFormPlaceSchedulePage";
import React from "react";

const FormPlaceSchedule = () => {
  return (
    <Layout>
      <EditFormPlaceSchedulePage />
    </Layout>
  );
};

export default FormPlaceSchedule;
