import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { SelectCrmCustomerProvider } from "modules/crm/presentation/context/SelectCustomerContext";
import CrmCampaignPage from "modules/crm/presentation/pages/campaign/CrmCampaignPage";
import CrmConfigPage from "modules/crm/presentation/pages/config/CrmConfigPage";
import ConfigCrmWhatsapp from "modules/crm/presentation/pages/configCrmWhatsapp/ConfigCrmWhatsapp";
import CrmPage from "modules/crm/presentation/pages/CrmPage";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TabPage from "../dashboard/tabPage/TabPage";
import { FidelityPage } from "modules/fidelity/presentation/FidelityPage";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";

export const Crm = () => {
  const { updateRouters } = useBreadcumbs();
  const { tab } = useParams<{ tab: string }>();
  const { getSubscription } = usePlans();

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  useEffect(() => {
    const menuTitle = {
      title: "Marketing",
    };
    if (tab === "customers") {
      updateRouters([
        menuTitle,
        {
          label: "Clientes",
          title: "CRM",
        },
      ]);
    }
    if (tab === "campaign") {
      updateRouters([
        menuTitle,
        {
          label: "Clientes",
          title: "Campanhas",
        },
      ]);
    }
    if (tab === "fidelity") {
      updateRouters([
        menuTitle,
        {
          label: "Clientes",
          title: "Fidelidade",
        },
      ]);
    }
    return () => {
      updateRouters([]);
    };
  }, [tab, updateRouters]);

  return (
    <Layout>
      <SelectCrmCustomerProvider>
        {
          tab === 'customers' ?
          <CrmPage /> :
          tab === 'campaign' ?
          <CrmCampaignPage /> :
          <FidelityPage />
        }
        {/* <TabPage
          intialTab="customers"
          routerPrefix={"/private/crm"}
          tabsPages={[
            {
              label: "Clientes",
              tab: "customers",
              roles: "",
              component: <CrmPage />,
            },
            {
              label: "Campanhas",
              tab: "campaign",
              roles: "",
              component: <CrmCampaignPage />,
            },
            {
              label: "Fidelidade",
              tab: "fidelity",
              roles: "Bi", //TODO: CRIAR ROLE PARA FIDELITY
              component: <FidelityPage />,
            },
          ]}
        ></TabPage> */}
      </SelectCrmCustomerProvider>
    </Layout>
  );
};
