import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpandMore,
  Battery2Bar,
  AccessTime,
  Wifi,
  SignalCellularAlt,
  PublicOff,
  LockOpen,
  Lock,
} from "@mui/icons-material";
import styles from "./AccordionMain.module.scss";
import { colorIcon } from "../../pages/utils";
import UseDimension from "components/dimension/UseDimension";
import {IAccordionMain} from '../../pages/interfaces'

const AccordionMain = ({
  title,
  poucaBateria,
  equipamentos,
  tempoResposta,
  wifi,
  dados,
  semConexao,
  foraCaixa,
  conectadoCaixa,
  selecionados,
  isChecked,
  onAccordionMainClick,
  children,
}: IAccordionMain) => {
  const useStyles = makeStyles(() => ({
    accordion: {
      border: "1px solid #E0E0E0",
      boxShadow: "none",
      borderRadius: "16px !important",
    },
    accordionSumary: {
      margin: "5px 7px",
      color: "#5f5f5f",
      fontWeight: "bold",
      fontSize: "18px",
    },
    accordionDetails: {
      padding: "0 23px 16px",
      flexDirection: "column",
    },
    customTooltip: {
      backgroundColor: "white",
      color: "#5F5F5F",
      boxShadow: "0px 4px 12px 0px #0000001A",
    },
  }));

  const stylesMui = useStyles();
  const { dimensions } = UseDimension();

  return (
    <div id={styles.AccordionMain}>
      <Accordion className={stylesMui.accordion}>
        <AccordionSummary
          className={stylesMui.accordionSumary}
          expandIcon={<ExpandMore />}
        >
          <div className={styles.containerTitle}>
              <div className={styles.itemLeft}>
                <Checkbox checked={isChecked} onChange={onAccordionMainClick} />
                <span className={styles.title}>{title}</span>
                {dimensions.width > 900 && (
                <div className={styles.description}>
                  <span className={styles.equipamentos}>
                    Quantidade de equipamentos: <span>{equipamentos}</span>
                  </span>
                  {selecionados > 0 && (
                    <span className={styles.equipamentos}>
                      Selecionados: <span>{selecionados}</span>
                    </span>
                  )}
                </div>
              )}
              </div>
             
            <div className={styles.itensRight}>
              <div className={styles.item}>
                <Tooltip
                  title="Bateria fraca"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <Battery2Bar className={colorIcon("red")} />
                </Tooltip>
                {poucaBateria}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Tempo de esposta"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <AccessTime className={colorIcon()} />
                </Tooltip>
                {tempoResposta}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Conectados ao Wi-Fi"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <Wifi className={colorIcon()} />
                </Tooltip>
                {wifi}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Conectados à rede móvel"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <SignalCellularAlt className={colorIcon()} />
                </Tooltip>
                {dados}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Sem conexão"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <PublicOff className={colorIcon()} />
                </Tooltip>
                {semConexao}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Fora do caixa"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <Lock className={colorIcon("red")} />
                </Tooltip>
                {foraCaixa}
              </div>
              <div className={styles.item}>
                <Tooltip
                  title="Conectado ao caixa"
                  classes={{ tooltip: stylesMui.customTooltip }}
                >
                  <LockOpen className={colorIcon("green")} />
                </Tooltip>
                {conectadoCaixa}
              </div>
            </div>
            {dimensions.width <= 900 && (
              <div className={styles.description}>
                <p className={styles.equipamentos}>
                  Quantidade de equipamentos: <span>{equipamentos}</span>
                </p>
                {selecionados > 0 && (
                  <p className={styles.equipamentos}>
                    Selecionados: <span>{selecionados}</span>
                  </p>
                )}
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={stylesMui.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionMain;
