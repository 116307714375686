import { useCallback, useEffect, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { OnlinePaymentApi } from "services/api/onlinePayment/OnlinePaymentApi";
import { ILocalOnlineItemPayment, ILocalOnlinePayment } from "../../interfaces/ILocalOnlinePayment";
import { GetLocalOnlinePaymentUseCase } from "modules/onlinePayment/application/useCases/GetLocalOnlinePaymentUseCase";
import { EnableOnlinePaymentStatusUseCase } from "modules/onlinePayment/application/useCases/EnableOnlinePaymentStatusUseCase";
import { DisableOnlinePaymentStatusUseCase } from "modules/onlinePayment/application/useCases/DisableOnlinePaymentStatusUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { PostLocalOnlinePaymentUseCase } from "modules/onlinePayment/application/useCases/PostLocalOnlinePaymentUseCase";
import { GetOnlinePaymentStatusUseCase } from "modules/onlinePayment/application/useCases/GetOnlinePaymentStatusUseCase";
import { IGetOnlinePaymentStatusUseCase } from "modules/onlinePayment/domain/dtos/IGetOnlinePaymentStatusUseCase";

const service = OnlinePaymentApi();

type IFilterType = { keyword?: string, hasOnlinePaymentSolicitation?: boolean };

export const UseConfigOnlinePayment = () => {
  const { toast } = useUi();
  const { currentLocal } = useLocal();

  const [historyModalOpened, setHistoryModalOpened] = useState(false);
  const [selectedLocalOnlinePayment, setSelectedLocalOnlinePayment] = useState<ILocalOnlineItemPayment>();
  const [changeOnlinePaymentStatusModalOpen, setChangeOnlinePaymentStatusModalOpen] = useState(false);
  const [localOnlinePayment, setLocalOnlinePayment] = useState<ILocalOnlinePayment>();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localOnlinePaymentSolicitations, setLocalOnlinePaymentSolicitations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<IFilterType>({
    keyword: '',
    hasOnlinePaymentSolicitation: false
  });
  const [onlinePaymentStatus, setOnlinePaymentStatus] = useState<IGetOnlinePaymentStatusUseCase>({} as IGetOnlinePaymentStatusUseCase);

  useEffect(() => {
    (async () => {
        const response = await GetLocalOnlinePaymentUseCase(service, 1, undefined, true);
        setLocalOnlinePaymentSolicitations(response.totalRegisters);
    })();
  }, []);

  const getData = useCallback(async (page?: number, _filter?: IFilterType) => {
    try {
      setLoading(true);
      const response = await GetLocalOnlinePaymentUseCase(service, page || 1, _filter?.keyword, _filter?.hasOnlinePaymentSolicitation);
      setLocalOnlinePayment(response);
      setCurrentPage(page || 1);
    } catch {
      toast('Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    getData(1);
  }, [getData]);

  const getOnlinePaymentStatus = useCallback(async () => {
    if (currentLocal) {
      try {
        setLoading(true);
        const response = await GetOnlinePaymentStatusUseCase(service, currentLocal.id);
        setOnlinePaymentStatus(response);
      } catch {
        toast('Ocorreu um erro, tente novamente.', 'error');
      } finally {
        setLoading(false);
      }
    }
  }, [toast, currentLocal]);

  const postOnlinePayment = useCallback(async () => {
    if (currentLocal) {
      try {
        setLoading(true);
        await PostLocalOnlinePaymentUseCase(service, currentLocal.id);
        return "ok";
      } catch (error: any) {
        if (error.response.data.detail.includes("solicitação em aberto")) {
          toast(error.response.data.detail, 'error');
        }
      } finally {
        setLoading(false);
      }
    }
  }, [toast, currentLocal]);

  const handleChangeOnlinePayment = async (enabled: boolean) => {
    try {
      setSaving(true);

      if (enabled)
        await EnableOnlinePaymentStatusUseCase(service, selectedLocalOnlinePayment!.localId);
      else
        await DisableOnlinePaymentStatusUseCase(service, selectedLocalOnlinePayment!.localId);

      setSelectedLocalOnlinePayment(undefined);
      setChangeOnlinePaymentStatusModalOpen(false);
      getData(currentPage, filter);
    } catch {
      toast(`Houve um problema ao ${enabled ? 'habilitar' : 'desabilitar'} o pagamento online`, 'error');
    } finally {
      setSaving(false);
    }
  }

  return {
    selectedLocalOnlinePayment,
    historyModalOpened,
    localOnlinePayment,
    localOnlinePaymentSolicitations,
    currentPage,
    loading,
    filter,
    saving,
    changeOnlinePaymentStatusModalOpen,
    setChangeOnlinePaymentStatusModalOpen,
    setFilter,
    setHistoryModalOpened,
    setSelectedLocalOnlinePayment,
    getData,
    handleChangeOnlinePayment,
    postOnlinePayment,
    getOnlinePaymentStatus,
    onlinePaymentStatus,
  }
}
