export interface IPostCreateMenuPayload {
  title: string;
  description: string;
  role: string;
  icon: string;
  route: string;
  menuConfigurationId: string;
  positionType: number;
  parentId?: string;
  planIds: string[];
  type: MenuTypeEnum;
}

export enum MenuTypeEnum {
  UNRELEASED = 0,
  STANDARD = 1,
  PREMIUM = 2
}
