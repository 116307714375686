import { useEffect, useCallback } from "react";
import { Icon, IconButton, MenuItem, TextField } from "@material-ui/core";
import { IIndividualPartnerValues, EMPTY_PARTNER_VALUES, ISegmentItem } from "./IIndividualPartnerValues.types";
import { useForm, useFieldArray, Form } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IndividualPartnerValidatorSchema } from "./IndividualPartnerValidatorSchema";
import { PartnerType } from "modules/meepErp/models/partner/PartnerType";
import InputMask from "react-input-mask";
import { ButtonContainer, Container, InputContainer, Row } from "../../../ui/form/FormContainers";
import SelectOriginTypeForm from "../selectPartnerOriginType/SelectOriginTypeForm";
import { PartnerOriginType } from "modules/meepErp/models/partner/PartnerOriginType";
import { UFlist } from "modules/meepErp/presentation/utils/UFlist";
import Back from "../../../ui/back/Back";
import Button from "components/ui/Button/Button";
import { useHistory } from "react-router-dom";

export interface IIndividualPartnerFormProps {
    onChangeOriginType: (originType: PartnerOriginType) => void;
    isLoading?: boolean;
    disabled?: boolean;
    segments: ISegmentItem[];
    defaultValues?: IIndividualPartnerValues;
    onSubmit: (values: IIndividualPartnerValues) => Promise<any>;
    isSubmiting?: boolean;
}

const IndividualPartnerForm: React.FC<IIndividualPartnerFormProps> = ({ onSubmit, defaultValues, isLoading, isSubmiting, disabled, onChangeOriginType }) => {
    const { push } = useHistory()
    const { control, register, handleSubmit, reset, formState, watch } = useForm<IIndividualPartnerValues>({
        defaultValues: EMPTY_PARTNER_VALUES,
        resolver: zodResolver(IndividualPartnerValidatorSchema)
    });

    const contacts = useFieldArray({ control, name: "contacts" });
    const values = watch(); // usado para colocar valores no select

    useEffect(() => {
        console.log("formErros", formState.errors);
    }, [formState.errors]);

    const onSubmitHandler = useCallback(async (data: IIndividualPartnerValues) => {
        await onSubmit(data);
    }, [onSubmit]);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        } else {
            reset(EMPTY_PARTNER_VALUES)
        }
    }, [defaultValues, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Container >
                <Back route='/private/meeperp/partners' margin />
                <Container subContainer={true} title={"1. Dados Gerais"}  >
                    {!defaultValues && <SelectOriginTypeForm
                        disabled={disabled}
                        originType={PartnerOriginType.INDIVIDUAL}
                        onChange={onChangeOriginType}
                    />}
                    {
                        <>
                            <Row>
                                {!!defaultValues && <InputContainer flex={2} label="Código">
                                    <TextField
                                        disabled
                                        {...register("code")}
                                        helperText={formState.errors.code?.message}
                                        error={!!formState.errors.code}
                                    />
                                </InputContainer>
                                }
                                <InputContainer style={{ flex: '0 1 30%' }} label="CPF" requerid>
                                    <InputMask
                                        disabled={disabled}
                                        mask={"999.999.999-99"}
                                        {...register("document")}
                                    >
                                        {() => <TextField
                                            {...register("document")}
                                            helperText={formState.errors.document?.message}
                                            error={!!formState.errors.document}
                                        />
                                        }</InputMask>
                                    {/* VALUES: {values.cnpj} */}
                                </InputContainer>

                            </Row>
                            <Row>
                                <InputContainer label="Nome" requerid>
                                    <TextField
                                        disabled={disabled}
                                        {...register("name")}
                                        helperText={formState.errors.name?.message}
                                        error={!!formState.errors.name}
                                    />
                                </InputContainer>

                            </Row>
                            <Row>
                                <InputContainer style={{ flex: '0 1 50%' }} label="Tipo" requerid>
                                    <TextField
                                        disabled={disabled}
                                        select
                                        defaultValue={PartnerType.Supplier}
                                        {...register("type")}
                                        value={values.type}
                                        helperText={formState.errors.type?.message}
                                        error={!!formState.errors.type}
                                    >
                                        <MenuItem value={PartnerType.Supplier}>Fornecedor</MenuItem>
                                        <MenuItem value={PartnerType.Customer}>Cliente</MenuItem>
                                    </TextField>
                                </InputContainer>
                            </Row>
                        </>
                    }
                </Container>
                <Container subContainer={true} title="2. Contatos">
                    <Row>
                        <Button
                            color="primary"
                            fullWidth={false}
                            variant="outlined"
                            endIcon={<Icon
                                style={{ background: '#32008E', borderRadius: '50%', color: 'white' }}
                            >
                                add
                            </Icon>}
                            onClick={() => contacts.append({ name: "", phone: "", email: "" })}>
                            Novo Contato

                        </Button>
                    </Row>
                    {values?.contacts.map((_, index) => (
                        <Row key={index}>
                            <InputContainer label="Nome">
                                <TextField
                                    disabled={disabled}
                                    //register={register(`contacts.${index}.name`)}
                                    {...register(`contacts.${index}.name`)}
                                    helperText={formState.errors.contacts?.[index]?.name?.message}
                                    error={!!formState.errors.contacts?.[index]?.name}
                                />
                            </InputContainer>
                            <InputContainer label="Telefone">
                                <InputMask
                                    disabled={disabled}
                                    {...register(`contacts.${index}.phone`)}
                                    mask={"(99) 99999-9999"}
                                >
                                    {() => <TextField
                                        {...register(`contacts.${index}.phone`)}
                                        helperText={formState.errors.contacts?.[index]?.phone?.message}
                                        error={!!formState.errors.contacts?.[index]?.phone}
                                    />}
                                </InputMask>
                            </InputContainer>
                            <InputContainer label="Email">
                                <TextField
                                    disabled={disabled}
                                    {...register(`contacts.${index}.email`)}
                                    helperText={formState.errors.contacts?.[index]?.email?.message}
                                    error={!!formState.errors.contacts?.[index]?.email}
                                />
                            </InputContainer>
                            {
                                <InputContainer flex={0}>
                                    <IconButton onClick={() => contacts.remove(index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </InputContainer>
                            }
                        </Row>
                    ))}

                </Container>
                <Container subContainer={true} title="3. Endereço">
                    <Row>
                        <InputContainer
                            label="CEP">
                            <InputMask
                                mask={"99999-999"}
                                disabled={disabled}
                                {...register("address.zipCode")}

                            >
                                {() => <TextField
                                    type="CEP"
                                    helperText={formState.errors.address?.zipCode?.message}
                                    {...register("address.zipCode")}
                                    error={!!formState.errors.address?.zipCode}
                                />}
                            </InputMask>
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer label="Endereço">
                            <TextField
                                disabled={disabled}
                                {...register("address.street")}
                                helperText={formState.errors.address?.street?.message}
                                error={!!formState.errors.address?.street}
                            />
                        </InputContainer>
                        <InputContainer label="Número">
                            <TextField
                                disabled={disabled}
                                {...register("address.number")}
                                helperText={formState.errors.address?.number?.message}
                                error={!!formState.errors.address?.number}
                            />
                        </InputContainer>
                        <InputContainer label="Bairro">
                            <TextField
                                disabled={disabled}
                                {...register("address.neighborhood")}
                                helperText={formState.errors.address?.neighborhood?.message}
                                error={!!formState.errors.address?.neighborhood}
                            />
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer label="Cidade">
                            <TextField
                                disabled={disabled}
                                {...register("address.city")}
                                helperText={formState.errors.address?.city?.message}
                                error={!!formState.errors.address?.city}
                            />
                        </InputContainer>
                        <InputContainer label="Estado">
                            <TextField
                                disabled={disabled}
                                select
                                defaultValue={PartnerType.Supplier}
                                {...register("address.state")}
                                value={values.address.state}
                            >
                                {
                                    UFlist.map((x: string) => (
                                        <MenuItem value={x}>
                                            {x}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </InputContainer>

                    </Row>
                </Container>

                <ButtonContainer>
                    <Button color='secondary' disabled={isLoading || disabled} onClick={() => push('/private/meeperp/partners')} fullWidth={false} variant='outlined'>Cancelar</Button>
                    <Button disabled={isLoading || disabled} type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                </ButtonContainer>
            </Container>
        </form >
    );
};



export default IndividualPartnerForm;