import { IGetPerfilEventoService } from "./IPerfilService";

const GetPerfilEvento = async (
  accountService: IGetPerfilEventoService,
  id: string,
  idEvento: string,
  limit: number,
  offset: number,
  profileName?: string,
): Promise<any> => {
  return await accountService.getPerfilEvento(id, idEvento, limit, offset, profileName );
};

export default GetPerfilEvento;