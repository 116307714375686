export enum EnumSurveyEvent {
    ON_CLOSE_ACCOUNT = 1,
    ON_PAYMENT_APP,
    AFTER_EVENT,
    ON_ADD_PROMOTER_LIST,
    ON_PAYMENT_POS,
}

export const getName = (input: EnumSurveyEvent) => {
    switch (input) {
        case EnumSurveyEvent.ON_CLOSE_ACCOUNT:
            return "Ao fechar conta no App";
        case EnumSurveyEvent.ON_PAYMENT_APP:
            return "Ao realizar pagamento no App";
        case EnumSurveyEvent.ON_ADD_PROMOTER_LIST:
            return "Ao adicionar na lista de promoter";
        case EnumSurveyEvent.ON_PAYMENT_POS:
            return "Ao realizar pagamento na POS";
        case EnumSurveyEvent.AFTER_EVENT:
            return "Após evento";
    }
}
