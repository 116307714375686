import React from "react";
import styles from "./Review.module.scss";
import Input from "components/ui/input/Input";
import Button from "components/ui/Button/Button";
import {
  ArrowForwardIos,
  Circle,
  Edit,
  Info,
  ExpandMore,
  Language,
  Facebook,
  Instagram,
  WhatsApp,
} from "@mui/icons-material";
import { IGestaoVendas } from "../../pages/interfaces/IGestaoVendas";
import {
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
} from "@mui/material";
import InputTextArea from "components/ui/inputTextArea/InputTextArea";
import { makeStyles } from "@material-ui/core/styles";
import { tipoLocal } from "../../pages/uteis";
import {
  IReponseMesas,
  IReponseOperacoes,
  IReponseOperadores,
} from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";

interface IReview {
  values: IGestaoVendas;
  setStepe: React.Dispatch<React.SetStateAction<number>>;
  getSegmento: number | undefined;
  responseMesas: IReponseMesas[] | undefined;
  responseOperacoes: IReponseOperacoes | undefined;
  operadorList: IReponseOperadores[] | undefined;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const Review = ({
  values,
  setStepe,
  getSegmento,
  responseMesas,
  responseOperacoes,
  operadorList,
  setTrigger,
}: IReview) => {
  const useStyles = makeStyles(() => ({
    accordion: {
      marginTop: "20px",
      borderBottom: "1px solid #E0E0E0",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      boxShadow: "none !important",
      "&::before": {
        display: "none",
      },
    },
  }));

  const stylesMui = useStyles();

  const labels = [];

  if (responseOperacoes?.ComandaIndividual) {
    labels.push("Pós-Pago Comanda");
  }

  if (responseOperacoes?.Mesa) {
    labels.push("Pós-Pago Mesa");
  }

  if (responseOperacoes?.PrePagoFicha) {
    labels.push("Pré-Pago Ficha");
  }

  if (responseOperacoes?.PrePagoCashless) {
    labels.push("Pré-Pago Cashless");
  }

  const valorInputOperacao = labels.join(", ");

  return (
    <div id={styles.Review}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.boxLeft}>
            <p className={styles.title}>
              Seu <span>segmento</span>
            </p>
            <Input
              name={"seguimento"}
              value={tipoLocal(getSegmento)}
              variant="outlined"
              disabled
            />
          </div>
          <Button
            className={styles.button}
            onClick={() => {
              setStepe(1)
              setTrigger(false);
            }}
          >
            Editar configurações
          </Button>
        </div>
      </div>

      <div className={styles.container}>
        <p className={styles.title}>
          Como é sua <span>operação de venda:</span>
        </p>
        <Input
          name={"seguimento"}
          value={valorInputOperacao}
          variant="outlined"
          disabled
        />
        {responseMesas && responseMesas?.length > 0 && (
          <p className={styles.mesas}>
            Voce possui <b>{responseMesas.length}</b> mesas cadastradas
          </p>
        )}
      </div>
      <div className={styles.container}>
        <p className={styles.title}>
          Seus <span>equipamentos</span> para venda:
        </p>
        <div className={styles.contentEquipamento}>
          <div className={styles.box}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={styles.title}>Gerencie seus PDV’s</p>
              <a href="/private/pdv/perfil" target="_blank">
                <ArrowForwardIos />
              </a>
            </div>
            <p className={styles.description}>
              Visualize os seus perfis de venda do seu estabelecimento
            </p>
          </div>
          <div className={styles.box}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={styles.title}>Crie um novo PDV</p>
              <a href="/private/pdv/perfil/adicionar" target="_blank">
                <ArrowForwardIos />
              </a>
            </div>
            <p className={styles.description}>
              Crie um novo perfil de venda do seu etabelecimento
            </p>
          </div>
        </div>
        <p className={styles.title}>
          Configuração dos <span>operadores</span>:
        </p>
        <div className={styles.contentEquipamento}>
          {operadorList &&
            operadorList.slice(0, 2).map((item) => {
              return (
                <div className={styles.box}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.titleOperador}>
                      <p className={styles.title}>{item.nome}</p>
                      <Circle />
                    </div>
                    <a href="/private/cadastros/operadores" target="_blank">
                      <Edit />
                    </a>
                  </div>
                  <div className={styles.password}></div>
                  <p className={styles.text}>
                    Permissões: selecionado {item.papeis.length} de 27
                  </p>
                </div>
              );
            })}
          {operadorList && operadorList?.length > 2 && (
            <div
              className={styles.boxOperador}
              onClick={() =>
                window.open("/private/cadastros/operadores", "_blank")
              }
            >
              <p className={styles.title}>
                + {operadorList?.length - 2} operadores
              </p>
              <p className={styles.description}>
                Clique aqui para <b>visualizar todos os operadores</b>{" "}
                cadastrados
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.perfil}>
        <p className={styles.title}>
          Configuração do <span>perfil do estabelecimento</span>
        </p>
        <div className={styles.perfilContent}>
          <div className={styles.boxImg}>
            <img src={values.logo} alt="logo" />
          </div>
          <div className={styles.perfilForm}>
            <div className={styles.inputEstabelecimento}>
              <div className={styles.titleEstabelecimento}>
                <p className={styles.title}>Nome do estabelecimento</p>
                <Tooltip
                  title="Nome do estabelecimento não pode ser alterado. Em caso de dúvidas, entre em contato com nosso suporte."
                  arrow
                >
                  <Info />
                </Tooltip>
              </div>
              <Input
                name={"estabelecimento"}
                value={values.localName}
                required
                variant="outlined"
                disabled
              />
              <div className={styles.boxInput}>
                <p className={styles.title}>
                  Descrição do local <span style={{ color: "#C40022" }}>*</span>
                </p>
                <InputTextArea
                  name={"description"}
                  value={values.description}
                  variant="outlined"
                  rows={3}
                  multiline
                  disabled
                  placeholder="Escreva aqui uma breve descrição do seu estabelecimento"
                />
              </div>
              <div className={styles.boxInput}>
                <p className={styles.title}>
                  Endereço <span style={{ color: "#C40022" }}>*</span>
                </p>
                <Input
                  name={"endereco"}
                  value={values.endereco}
                  disabled
                  variant="outlined"
                  placeholder="Digite o endereço do seu estabelecimento"
                />
              </div>
            </div>
          </div>
        </div>
        <Accordion className={stylesMui.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <p className={styles.titleAccordion}>Ver mais configurações</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.accordionDetails}>
              <p className={styles.title}>Site</p>
              <Input
                name={"site"}
                value={values.siteUrl}
                variant="outlined"
                placeholder="www.seulocal.com.br"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Language color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={styles.redeSocialContent}>
                <div>
                  <p className={styles.title}>Facebook</p>
                  <Input
                    name={"facebook"}
                    value={values.facebook}
                    disabled
                    variant="outlined"
                    placeholder="/Seulocal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Facebook color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <p className={styles.title}>Instagram</p>
                  <Input
                    name={"instagram"}
                    value={values.instagram}
                    disabled
                    variant="outlined"
                    placeholder="@seulocal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Instagram color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className={styles.redeSocialContent}>
                <div>
                  <p className={styles.title}>WhatsApp</p>
                  <Input
                    name={"whatsapp"}
                    value={values.whatsapp}
                    disabled
                    variant="outlined"
                    placeholder="(xx) xxxxx-xxxx"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WhatsApp color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Review;
