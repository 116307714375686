import { useHistory, useParams } from 'react-router-dom';
import styles from './PublicInvoicePage.module.scss';
import { useEffect, useState } from 'react';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { InvoiceAccounting } from 'modules/invoice/presentation/InvoiceAccounting/InvoiceAccounting';
import { InvoiceRegister } from 'modules/invoice/presentation/InvoiceRegister/InvoiceRegister';
import { ICompany } from 'modules/invoice/presentation/interfaces/ICompany';
import { ICompanyForm } from 'modules/invoice/presentation/interfaces/ICompanyForm';
import InvoicePublicApi from 'services/api/invoice/InvoicePublicApi';
import { useQuery } from 'hooks/UseQuery';
import { UpdateCompanyUseCase } from './application/useCases/UpdateCompanyUseCase';
import { Icon } from '@material-ui/core';

const service = InvoicePublicApi();
let hasFetched = false;

export const PublicInvoicePage = () => {
    const [tab, setTab] = useState<'register' | 'accounting' | 'success'>('register');
    const [preLoading, setPreloading] = useState(true);
    const [company, setCompany] = useState<ICompany>();
    const [form, setForm] = useState({} as ICompanyForm);
    const [loading, setLoading] = useState(false);

    const { localId } = useParams<{ localId: string }>();
    const { toast } = useUi();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        (async () => {
            const apiKey = query.get('apiKey');

            if (localId && !hasFetched) {
                try {
                    setPreloading(true);
                    hasFetched = true;
                    const response = await service.getCompany(localId, apiKey!);
                    setCompany(response);
                    setForm({ ...response, certificate: undefined, hasCertificate: !!response.certificate?.url });
                } catch (error) {
                    toast('Chave inválida ou expirada. Solicite novamente.', 'error');
                } finally {
                    setPreloading(false);
                }
            }
        })();
    }, [localId, query, toast]);

    const onNextStep = () => {
        setTab('accounting');
    }

    const handleSubmit = async () => {
        try {
            const apiKey = query.get('apiKey');
            setLoading(true);
            await UpdateCompanyUseCase(service, company!.id, { ...form, certificate: !form.hasCertificate ? { ...form.certificate!, saveFile: true } : undefined }, apiKey!);
            toast('Informações fiscais salvas com sucesso.', 'success');
            setTab('success');
        } catch {
            toast('Houve um erro ao salvar as informações fiscais. Tente novamente.', 'error');
            setLoading(false);
        }
    }

    if (preLoading !== false) {
        return (
            <div className={styles.noDataContainer}>
                <img src="/assets/icon/saas/loading.gif" alt="" />
            </div>
        )
    }

    if (tab === 'success') {
        return (
            <div className={styles.successContainer}>
                <Icon className={styles.icon}>check_circle</Icon>
                <h1>Cadastro realizado com sucesso !</h1>

                <p>Para continuar, acesse o portal Meep.</p>

                <button onClick={() => history.push('/private/invoice')}>
                    Acessar portal Meep
                </button>
            </div>
        )
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <h1>Informações cadastrais</h1>

                <div className={styles.formContainer}>
                    <header>
                        <div className={`${styles.tabContainer} ${tab === 'register' ? styles.selected : ''}`}>
                            <span>
                                {`1 ${tab === 'register' ? 'Cadastro' : ''}`}
                            </span>
                        </div>
                        <div className={`${styles.tabContainer} ${tab === 'accounting' ? styles.selected : ''}`}>
                            <span>
                                {`2 ${tab === 'accounting' ? 'Informações contábeis' : ''}`}
                            </span>
                        </div>
                    </header>
                    {
                        tab === 'register'
                        ? <InvoiceRegister setForm={setForm} form={form} onCancel={() => history.push('/private/invoice')} onSubmit={onNextStep} modal />
                        : <InvoiceAccounting loading={loading} setForm={setForm} form={form} onCancel={() => setTab('register')} onSubmit={handleSubmit} modal />
                    }
                </div>
            </div>
        </div>
    )
}