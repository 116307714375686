import { memo, useCallback } from "react";
import { FilterOperatorType, ICrmFormOperatorValue } from "../../interfaces/ICrmFilterValue";
import { MenuItem, Icon, IconButton } from "@material-ui/core";
import { InputCRM } from "../../../_inputCRMFilter/InputCRM";
import { InputContainer } from "../../../_inputContainer/InputContainer";

interface SelecOperatorCRMFilterProps {
  name?: string;
  label?: string;
  startLabel?: string;
  endLabel?: string;
  values?: ICrmFormOperatorValue;
  type?: string;
  moneyMask?: boolean;
  premium?: boolean;
  showPremiumCrown?: boolean;
  onChange: (value: ICrmFormOperatorValue) => void;
  onClear: () => void;
}

const SelectOperatorCRMFilter = ({
  label,
  startLabel,
  endLabel,
  values,
  type,
  onChange,
  onClear,
  moneyMask,
  premium,
  showPremiumCrown
}: SelecOperatorCRMFilterProps) => {
  const changeHandle = useCallback((value: string | undefined, name: string) => {
    let newValues: ICrmFormOperatorValue;

    if (name === "type") {
      newValues = { ...values, filterType: value ? Number(value) : undefined, startValue: undefined, endValue: undefined };
    } else {
      newValues = {
        ...(values ?? { filterType: FilterOperatorType.LESS_THAN, startValue: undefined, endValue: undefined }),
        [name]: value && value !== "" ? value : undefined,
      };
    }
    onChange?.(newValues);
  },
    [onChange, values]
  );

  const getPlaceholder = () => {
    let placeholder = startLabel || "Início";

    if (values?.filterType === FilterOperatorType.LESS_THAN) placeholder = endLabel || placeholder
    else if (values?.filterType === FilterOperatorType.GREATER_THAN) placeholder = startLabel || placeholder

    return placeholder
  }

  return (
    <InputContainer row style={{ position: 'relative' }}>
      <label style={{ position: "absolute", top: 0, left: 0 }}>{label}</label>
      <InputCRM
        // moneyMask={moneyMask}
        onChangeValue={changeHandle}
        name={"type"}
        select
        // label={label}
        value={values?.filterType || 0}
        premium={premium}
        showPremiumCrown={showPremiumCrown}
      >
        <MenuItem value={FilterOperatorType.LESS_THAN}>Menor que</MenuItem>
        <MenuItem value={FilterOperatorType.GREATER_THAN}>Maior que</MenuItem>
        <MenuItem value={FilterOperatorType.BETWEEN}>Entre</MenuItem>
      </InputCRM>
      <div style={{ position: 'relative', flex: 1, height: 65 }}>
        <InputCRM
          inputProps={{ min: 0 }}
          moneyMask={moneyMask}
          type={type}
          value={values?.startValue}
          name={"startValue"}
          placeholder={getPlaceholder()}
          onChangeValue={changeHandle}
          style={{ justifyContent: 'flex-end !important' }}
          premium={premium}
          showPremiumCrown={showPremiumCrown}
        />
        {values?.filterType !== 3 && values?.startValue !== undefined && values?.startValue !== null && <div style={{ position: 'absolute', bottom: 6, right: 5 }}>
          <IconButton size="small" onClick={onClear}><Icon>close</Icon></IconButton>
        </div>}
      </div>
      {values?.filterType === 3 && (
        <>
          <p style={{ display: "flex", alignItems: "end", marginBottom: 10 }}>e</p>
          <div style={{ position: 'relative', flex: 1, height: 65 }}>
            <InputCRM
              inputProps={{ min: 0 }}
              moneyMask={moneyMask}
              type={type}
              name={"endValue"}
              value={`${values?.endValue}`}
              placeholder={endLabel || "Final"}
              onChangeValue={changeHandle}
              premium={premium}
              showPremiumCrown={showPremiumCrown}
            />
            {values?.filterType === 3 && values?.endValue !== undefined && values?.endValue !== null && <div style={{ position: 'absolute', bottom: 6, right: 5 }}>
              <IconButton size="small" onClick={onClear}><Icon>close</Icon></IconButton>
            </div>}
          </div>
        </>
      )}
    </InputContainer>
  );
};

export default memo(SelectOperatorCRMFilter);
