import { FC, ReactNode } from "react";
import styles from "./dividerText.module.scss";

type DividerTextProps = {
  children: string | ReactNode | ReactNode[];
};

const DividerText: FC<DividerTextProps> = ({ children }) => {
  return (
    <div className={styles.DividerText}>
      <span className={styles.content}>{children}</span>
    </div>
  );
};

export { DividerText };
