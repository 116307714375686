import { AxiosInstance } from "axios"
import { IFilterRequest } from "modules/dashboard/domain/dto/FilterRequest.type";
import { ITopListResponse, ITopListItem } from "modules/dashboard/domain/dto/topList/ITopList.type";

interface BuscarOperadorResponse {
    nome: string, valor: number
}

const BuscarTopOperadores = async (api: AxiosInstance, request: IFilterRequest): Promise<ITopListResponse> => {
    const promises = request.selectedPlaces.map((_localId) => {
        return api.get<BuscarOperadorResponse[]>(`/DashBoard/BuscarTopOperadoresPorPeriodo`, { params: { LocalId: _localId, PeriodoBusca: request.periodoBusca,DataInicial: request.startDate, DataFinal: request.endDate } });
    })

    const responses = await Promise.all(promises)

    const operators = responses.map((item, index) => (item.data.map<ITopListItem>(item => ({
        name: item.nome,
        amount: item.valor,
        index
    }))));

    const sortedOperators = operators.reduce((data, current) => [...data, ...current]).sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1);

    return {
        label: "Operador",
        records: [sortedOperators],
        amountLabel: "Valor",
        totalRecords: Math.max(...operators.map(item => item.length))
    }
}

export default BuscarTopOperadores
