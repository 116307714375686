// import { useEventsMock } from "modules/events/UseEvents.mock";
import { IAddDiscountToListRequest } from "modules/events/domain/dto/IEventsRequest";
import { IGetDiscountProductsItemResponse, IGetListDiscountsResponse } from "modules/events/domain/dto/IEventsResponse";
import { api } from "../../Api";
import { IEventsDiscountsApi } from "../../../../modules/events/application/interfaces/IDiscountsService";
import axios from "axios";

export const EventsDiscountsApi = (): IEventsDiscountsApi => {
 
    const getDiscountProductsLists = async (localId: string): Promise<IGetDiscountProductsItemResponse[]> => {
        // const result = await axios.get(`http://25.30.59.182/Promptus.Meep.Server/api/SuperApp/Event/FindProductsSuitableForDiscount?localId=${localId}`);
        const result = await api.get(`/SuperApp/Event/FindProductsSuitableForDiscount?localId=${localId}`);
        return result.data;
    };
 
    const getDiscountsList = async (peopleListId: string): Promise<IGetListDiscountsResponse[]> => {
        const result = await api.get(`/SuperApp/Event/FindPeopleListDiscounts?peopleListId=${peopleListId}`);
        return result.data;
    };

    const deleteDiscount = async (itemId: string): Promise<void> => {
        await api.delete(`/SuperApp/Event/DeletePeopleListDiscount/${itemId}`);
    }

    const addDiscountToList = async (newDiscount: IAddDiscountToListRequest): Promise<void> => {
        const result = await api.post('/SuperApp/Event/AddDiscountToPeopleList', {...newDiscount});
        return result.data;
    }

    
    return {
        getDiscountProductsLists,
        getDiscountsList,
        deleteDiscount,
        addDiscountToList,
    };
};
