import { Icon } from '@material-ui/core'
import { differenceInYears, format } from 'date-fns'
import React, { FC, useCallback } from 'react'
import { moneyMaskNumber } from 'services/utils/Money'
import styles from './CustomerList.module.scss'
import { ICustomerItemData } from './ICustomerList'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
export interface ICustomerItemProps {
    //propertys
    averageTicket?: number
    item: ICustomerItemData
}
const CustomerItem: FC<ICustomerItemProps> = ({
    averageTicket,
    item
}) => {

    const { isMobile } = useUi();
    const isBirthday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date()

        if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth()) {
            return true;
        } else {
            return false;
        }
    }

    const consumedColor = useCallback((value: number) => {
        if (averageTicket) {
            if (averageTicket > value) {
                return 'red'
            } else if (averageTicket === value) {
                return 'orange'
            } else {
                return '#1a5'
            }
        } else { return undefined }
    }, [averageTicket])
    return (
        <div className={styles.item} >
            <div className={styles.row}>
                {isMobile && <span className={styles.titleRow}>Nome:</span>}
                <span>{item.name === "" ? 'Não informado' : item.name}</span>
                {item.inPlace && <div className={styles.inPlace} >
                  {!isMobile && <span>Ativo</span>}
                </div>}
            </div>
            <div className={styles.row}>
                {isMobile && <span className={styles.titleRow}>Idade:</span>}
                <span>{item.age || '-'}</span>
            </div>
            <div className={styles.row} >
                {isMobile && <span className={styles.titleRow}>Data:</span>}
                {item.bornDate && (<><span>{format(new Date(item.bornDate), "dd/MM")}</span>{item.birthday && <Icon style={{ color: '#74f' }}>cake</Icon>}</>)}
            </div>
            <div className={styles.row}>
                {isMobile && <span className={styles.titleRow}>Visitas:</span>}
                <span>{item.visits}</span>
            </div>
            <div className={styles.row} style={{ color: consumedColor(item.totalConsumed) }} >
                {isMobile && <span className={styles.titleRow}>Total consumido:</span>}
                <span>{moneyMaskNumber(item.totalConsumed)}</span>
            </div>
        </div>
    )
}
export default CustomerItem