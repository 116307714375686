import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IEventsForm } from "modules/events/domain/models/IEvents";
import { IEditEventRequest } from "modules/events/domain/dto/IEventsRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EventsApi } from "services/api/events/events/EventsApi";
import GetEventsDetailsUseCase from "modules/events/application/useCases/events/GetEventsDetailsUseCase";
import EditEventUseCase from "modules/events/application/useCases/events/EditEventUseCase";

interface IUseEditEvents {
  handleSubmit: (event: IEventsForm) => Promise<void>;
  handleCancel: () => void;
  defaultEvent: IEventsForm | undefined;
}

export const UseEditEventsPage = (): IUseEditEvents => {
  const { eventId } = useParams<{ eventId: string }>();
  const { replace } = useHistory();
  const { toast, hideLoading, showLoading } = useUi();
  const { currentLocal } = useLocal();
  const [defaultEvent, setDefaultEvent] = useState<IEventsForm>();

  const handleCancel = useCallback(() => {
    replace("/private/eventos/list");
  }, [replace]);

  useEffect(() => {
    const eventsService = EventsApi();
    const getEvent = async () => {
      showLoading();
      try {
        if (!currentLocal) return;

        const result = await GetEventsDetailsUseCase(eventsService, eventId, currentLocal?.id);
        const startDateString = `${new Date(result.openingDates[0].startDate).toLocaleString().split(' ')[0].split('/').reverse().join('-')}T${new Date(result.openingDates[0].startDate).toLocaleTimeString()}`;
        const endDateString = `${new Date(result.openingDates[0].endDate).toLocaleString().split(' ')[0].split('/').reverse().join('-')}T${new Date(result.openingDates[0].endDate).toLocaleTimeString()}`;
        const startSaleDate = `${new Date(result.availableForSalesStart).toLocaleString().split(' ')[0].split('/').reverse().join('-')}T${new Date(result.availableForSalesStart).toLocaleTimeString()}`;
        const endSaleDate = `${new Date(result.availableForSalesEnd).toLocaleString().split(' ')[0].split('/').reverse().join('-')}T${new Date(result.availableForSalesEnd).toLocaleTimeString()}`;
        
        const eventForm: IEventsForm = {
          id: result.id,
          name: result.name,
          attractions: result.attractions,
          imageUrl: result.imageUrl,
          attractionName: result.attractions[0].name,
          
          startDateString,
          endDateString,
          startSaleDate,
          endSaleDate,
        };
        setDefaultEvent(eventForm);
      } catch (error) {
        // console.error(error);
      } finally {
        hideLoading();
      }
    };
    getEvent();
  }, [eventId, currentLocal, showLoading, hideLoading]);

  const handleSubmit = useCallback(
    async (event: IEventsForm) => {
      const eventsService = EventsApi();
      if (currentLocal && event.id) {
        showLoading();
        try {
          const eventToEdit: IEditEventRequest = {
            id: event.id,
            name: event.name,
            imageUrl: event.imageUrl,
            availableForSalesStart: new Date(event.startSaleDate).toISOString(),
            availableForSalesEnd: new Date(event.endSaleDate).toISOString(),
            openingDates: [
              {
                startDate: new Date(event.startDateString).toISOString(),
                endDate: new Date(event.endDateString).toISOString(),
              },
            ],
            attractions: [
              {
                name: event.attractionName || "",
                imageUrl: "",
              },
            ],
          };

          await EditEventUseCase(eventsService, eventToEdit, currentLocal.id);
          toast("Evento editado com sucesso!", "success");
          handleCancel();
        } catch (error) {
          toast("Erro ao editar evento", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [currentLocal, handleCancel, hideLoading, showLoading, toast]
  );

  return {
    handleSubmit,
    handleCancel,
    defaultEvent,
  };
};
