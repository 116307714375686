import { format } from "date-fns";
import { ptBR } from 'date-fns/locale'
import { GetFidelityBalanceUseCase } from "modules/fidelity/application/useCases/GetFidelityBalanceUseCase";
import { IFidelityBalanceItem } from "modules/fidelity/presentation/interfaces/IFidelityBalance";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react"
import { FidelityServiceApi } from "services/api/fidelity/FidelityApi";

const service = FidelityServiceApi();
export enum historyStep {
    POINTS = 0,
    COINS = 1,
}

export const UseFidelityHistory = (customerDocument: string) => {
    const { currentLocal } = useLocal();
    
    const [step, setStep] = useState<historyStep>(historyStep.POINTS);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRegisters, setTotalRegisters] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState<{ title: string; data: IFidelityBalanceItem[]}[]>([])
    const [lastDate, setLastDate] = useState();

    const getData = useCallback(async (page: number, type: 'coins' | 'points' = 'points') => {
        try {
            setLoading(true);

            if (currentLocal) {
                const response = await GetFidelityBalanceUseCase(service, type, customerDocument, currentLocal?.id, page);
                const aux: { title: string; data: IFidelityBalanceItem[]}[] = [];
                response.data.forEach(x => {
                    let title = format(new Date(x.date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
                    title = format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) === title ? 'Hoje' : title;

                    const alreadyPushed = aux.find(x => x.title === title);

                    if (alreadyPushed) {
                        aux.find(x => x.title === title)!.data.push(x);
                    } else {
                        aux.push({
                            title,
                            data: [x]
                        })
                    }
                })

                setData(aux);

                setTotalRegisters(response.totalRegisters);
                setTotalPages(response.totalPages);
                setCurrentPage(page);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [currentLocal, customerDocument])

    useEffect(() => {
        getData(1, step === historyStep.COINS ? 'coins' : 'points');
    }, [getData, step])

    const getMore = (page: number) => {
        if (data.length < totalRegisters)
        getData(page, step === historyStep.COINS ? 'coins' : 'points');
    }

    return {
        loading,
        currentPage,
        totalPages,
        data,
        step,
        lastDate,
        setLastDate,
        setStep,
        getMore,
    }
}