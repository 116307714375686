import { IRequestParams } from "modules/salesDashboard/domain/dtos/RequestParams";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";

export const makeFilterRequest = ({
    filter,
    page = 0,
    pageSize = 20,
    sortField,
    groupBy = '',
    sortOrientation = 'desc'
}: IUseCaseParams) => {
    const filterRequest: IRequestParams = {
        selectedCashiers: filter.sessions ? filter.sessions.map(x => x.id) : undefined,
        selectedPlaces: filter.locals.map(x => x.id) || [],
        startDate: filter.startDate,
        endDate: filter.endDate,
        searchPeriod: filter.period,
        page,
        pageSize,
        groupBy,
        sort: {
            type: sortField || '',
            orientation: sortOrientation || ''
        }
    }

    return filterRequest;
}
