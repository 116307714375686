import React, { CSSProperties, useState } from 'react'
import styles from '../List.module.scss'
import { IListItem } from '../IList'
import { ColumnConfigs } from '../List'
import { Icon, IconButton, MenuItem, MenuList, Popover } from '@material-ui/core'

export interface IActionButtons<T extends IListItem> {
    icon: string
    onClick: (item: T) => void
}

export interface IMenuButton<T extends IListItem> {
    label: string,
    icon?: string,
    onClick?: (item: T) => void
}

export interface ItemProps<T extends IListItem> {
    item: T,
    onClick?: (value: T) => void
    formatValue?: (values: string) => string
    columnsConfigs?: ColumnConfigs,
    actionButtons?: IActionButtons<T>[],
    style?: React.CSSProperties,
    enableDnd?: boolean,
    menuButtons?: IMenuButton<T>[]
}
const Item = <T extends IListItem,>({ item, enableDnd, onClick, formatValue, columnsConfigs, actionButtons, style, menuButtons: menuButton }: ItemProps<T>) => {



    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div key={item.id} style={{ ...style }} className={styles.item} onClick={() => onClick && onClick(item)}>
            {
                enableDnd && <Icon className={styles.dragIcon}>drag_handle</Icon>
            }
            {columnsConfigs ? Object.keys(columnsConfigs).map((key) => {
                if (!columnsConfigs[key].hide) {
                    if (key === "imageUrl") {
                        return (
                            <span
                                className={styles.row}
                                style={{ maxWidth: columnsConfigs[key].width ?? 76, minWidth: key === "imageUrl" ? 76 : "unset" }}
                            >
                                <div className={styles.image}>
                                    {item[key] && <img src={item[key]} alt={"foto de " + item.name}></img>}
                                </div>
                            </span>
                        )
                    }
                    return (
                        <span
                            className={styles.row}
                            style={{ maxWidth: columnsConfigs[key].width, minWidth: key === "imageUrl" ? 100 : "unset" }}
                        >
                            {formatValue ? formatValue(item[key]) : item[key]}
                        </span>
                    )
                }
                return null
            })
                :
                Object.keys(item).map((key, index) => {
                    if (key === "imageUrl") {
                        return (<span
                            className={styles.row}
                            style={{ width: 100 }}
                        ><div className={styles.image}>{item[key] && <img src={item[key]} alt={"foto de " + item.name}></img>}</div></span>)
                    }
                    if (item.hasOwnProperty(key) && key !== `id`) {
                        return (<span
                            className={styles.row}>{formatValue ? formatValue(item[key]) : item[key]}</span>)
                    }

                    return null
                })

            }

            {actionButtons && <div id={styles.actionButtons}>
                <div id={styles.renderButtons}>
                    {actionButtons.map(actionButtonItem => (<IconButton onClick={() => actionButtonItem.onClick(item)}><Icon style={{ color: "#001537" }}>{actionButtonItem.icon}</Icon></IconButton>))}
                </div>
            </div>
            }

            {!!menuButton?.length && <div id={styles.renderButtons}>
                <IconButton onClick={(ev) => openMenu(ev)}><Icon>more_horiz</Icon></IconButton>
            </div>}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList>
                    {menuButton?.map(menuButtonItem => <MenuItem onClick={() => {
                        menuButtonItem.onClick?.(item);
                        closeMenu()
                    }}>{menuButtonItem.icon ? <Icon>{menuButtonItem.icon}</Icon> : null}{menuButtonItem.label}</MenuItem>)}
                </MenuList>
            </Popover>
        </div>
    )
}

export default Item
