import React, { useCallback, useState } from 'react'
import GetProductRankingUseCase from 'modules/customersDashboard/application/useCases/GetProductRankingUseCase';
import { ICustomerDashboardFilter } from 'modules/customersDashboard/domain/dto/FilterRequest.type';
import CustomersDashboardService from 'services/api/customersDashboard/CustomersDashboardService';
import { ICanceledProductRankingData, IProductRankingData } from '../components/productRaking/IProductRanking';
import GetCanceledProductRankingUseCase from 'modules/customersDashboard/application/useCases/GetCanceledProductRankingUseCase';
import { OperationStringEnum } from '../components/operationFilter/OperationFilter';

const UseCanceledProductRanking = (filter: ICustomerDashboardFilter, operationFilter?: OperationStringEnum) => {

    const [data, setData] = useState<ICanceledProductRankingData>();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>()

    const getData = useCallback(async (_filter: { keyword?: string, page?: number, pageSize?: number, sort?: { type?: string, orientation?: 'asc' | 'desc' } }) => {
        try {
            // if (_filter.page === 0) {
            setIsLoading(true)
            // }
            const service = CustomersDashboardService();
            const response = await GetCanceledProductRankingUseCase(service, { ...filter, ..._filter, operation: operationFilter });
            setData(response)
            //infinityScroll
            // if (_filter > 0) {
            //     setData(response)
            // } else {
            //     setData(prev => ({ ...response, records: [...prev?.records ?? [], ...response.records] }))
            // }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [filter, operationFilter])

    return ({
        data,
        isLoading,
        error,
        getData,
    })
}

export default UseCanceledProductRanking