
export enum AcquirerTypeEnum {
  ADIQ = 3,
}

export enum AdditionalTidsStatusEnum {
  Pendente = 1,
  Validação,
  Aprovado,
  Recusado,
  Completo,
  Cancelado,
}

export interface ITID {
  Id: string;
  AcquirerType: AcquirerTypeEnum,
  MerchantDocument: string,
  AcquirerMerchantId: string,
  Tids: string,
  Status: AdditionalTidsStatusEnum,
  UsuarioName: string,
  CreatedAt: string,
  CompletedAt?: string,
}
