import { FC } from 'react'
import { ErrorProvider } from './contexts/error/ErrorContext'
import CashlessDashboardPage from './CashlessDashboardPage'

const CashlessDashboardPageContainer: FC = () => {
    return (
        <ErrorProvider>
            <CashlessDashboardPage />
        </ErrorProvider>
    )
}

export default CashlessDashboardPageContainer