import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './UnitList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { IGetUnitItemResponse, IGetUnitListResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse';
import { IUnitFilterValue } from './unitFilter/IUnitFilter';
import UnitFilter from './unitFilter/UnitFilter';
import DefaultActionButtonBuilder from '../../ui/defaultListActionButtons/DefaultListActionButtons';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';

export interface IUnitListProps {
    filter: IUnitFilterValue
    isLoading: boolean;
    unit?: IGetUnitListResponse
    onChangeFilter: (filter: IUnitFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => Promise<void>
    onClickEnable: (id: string, isActive: boolean, value: IGetUnitItemResponse ) => Promise<IGetUnitItemResponse>
}
const UnitList: FC<IUnitListProps> = ({
    filter,
    isLoading,
    unit,
    onChangeFilter,
    onDelete,
    onEdit,
    onClickEnable
}) => {
    const { hasAccessRole } = useAuth()
    const submitFilter = useCallback((values: IUnitFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])
    const columnsConfig: ColumnConfigs<IGetUnitItemResponse> = {
        name: { title: "Nome", sortable: true },
        symbol: { title: "Sigla", sortable: true },

    }
    const actionButtons = DefaultActionButtonBuilder<IGetUnitItemResponse>({ onDelete, onEdit, onClickEnable })

    const pagination = useMemo(() => {
        return {
            page: unit?.page ?? 1,
            pageSize: 10,
            count: unit?.totalPages ?? 0
        }
    }, [unit?.page, unit?.totalPages])
    return (
        <div id={styles.UnitList} >
            <UnitFilter
                onSubmit={submitFilter}
                defaultValue={filter}
            />
            <div></div>
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={unit?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CADASTROS_CADASTRAR) ? actionButtons : undefined}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default UnitList