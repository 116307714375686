import styles from "./ConfigContentMeepHub.module.scss";
import Input from "components/ui/input/Input";
import { OpenInNewOutlined, PrintOutlined, RefreshOutlined } from "@material-ui/icons";
import { ContentCopyOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import Button from "components/ui/Button/Button";
import { useMemo } from "react";
import { meepHub } from "Enviroment";

export interface IConfigContentMeepHubProps {
  token: string;
  handleRefreshToken: () => Promise<void>;
  newToken?: string;
}

const ConfigContentMeepHub = ({ token, handleRefreshToken, newToken }: IConfigContentMeepHubProps) => {
  const refreshedToken = useMemo(() => newToken ?? token, [newToken, token]);

  return (
    <div id={styles.ConfigContentMeepHub}>
      <div className={styles.content}>
        <p>Para usar o MeepHub, siga os passos abaixo:</p>
        <div className={styles.step}>
          <p>1. Faça download do aplicativo no seu computador</p>
          <div className={styles.firstStep}>
            <div>
              <img src="/assets/icon/meep-tickets.svg" alt=""/>
              <span style={{ color: "#333333" }}><b>Meep Hub</b></span>
            </div>
            <a href={meepHub}>
              <FileDownloadOutlined />
              <span>Fazer download</span>
            </a>
          </div>
        </div>
        <div className={styles.step}>
          <p>2. Insira o token do seu estabelecimento:</p>
          <div className={styles.secondStep}>
            <label htmlFor="token">
              Token
            </label>
            <div className={styles.input}>
              <Input
                name="token"
                variant="outlined"
                value={newToken ?? token}
                disabled
              />
              <IconButton
                size="small"
                onClick={handleRefreshToken}
                className={styles.refresh}
              >
                <RefreshOutlined style={{ transform: "scaleX(-1)" }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                    navigator.clipboard.writeText(refreshedToken);
                    toast.info("Token copiado para area de transferência!");
                }}
              >
                <ContentCopyOutlined />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={styles.step}>
          <p>3. Depois de inserir o token, você está pronto para usar os serviços disponíveis:</p>
          <div className={styles.thirdStep}>
            <PrintOutlined fontSize="large"/>
            <div>
              <span>Epson TM-T20 e TM-T20x</span>
              <span>Como configuro? <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-epson-t20", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></span>
            </div>
          </div>
          <div className={styles.thirdStep}>
            <PrintOutlined fontSize="large"/>
            <div>
              <span>Bematech 4200</span>
              <span>Como configuro? <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-bematech", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></span>
            </div>
          </div>
          <div className={styles.thirdStep}>
            <PrintOutlined fontSize="large"/>
            <div>
              <span>Elgin i8 e i9</span>
              <span>Como configuro? <IconButton onClick={() => window.open("https://www.ajuda.meep.com.br/impressao-elgin", "_blank")}><OpenInNewOutlined fontSize="small" /></IconButton></span>
            </div>
          </div>
          <div className={styles.warning}>
            <img src="/assets/icon/sad-face.svg" alt=""/>
            <span>Sua impressora não está aqui? <b>Chame a gente pelo Chat!</b></span>
          </div>
        </div>
      </div>
      <Button variant="outlined">Voltar</Button>
    </div>
  );
};

export default ConfigContentMeepHub;
