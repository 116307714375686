import { ICanceled } from "modules/cashlessDashboard/presentation/components/canceled/interfaces/ICanceled";
import ICashlessDashboardService from "../../domain/interfaces/ICashlessDashboardService";
import { IUseCaseParams } from "../../domain/interfaces/IUseCaseParams";
import { makeFilterRequest } from "../MakeFilterRequest";

const GetCanceledCase = async (service: ICashlessDashboardService, params: IUseCaseParams): Promise<ICanceled> => {
  const filterRequest = makeFilterRequest(params);
  const response = await service.getCanceled(filterRequest);

  const result: ICanceled = {
    consumption: {
      ...response.consumption,
      malePercentage: response.consumption.total ? Number(((response.consumption.male / response.consumption.total) * 100).toFixed(2)) : 0,
      femalePercentage: response.consumption.total ? Number(((response.consumption.female / response.consumption.total) * 100).toFixed(2)) : 0,
      unknownPercentage: response.consumption.total ? Number(((response.consumption.unknown / response.consumption.total) * 100).toFixed(2)) : 0,
    },
    recharge: {
      ...response.recharge,
      malePercentage: response.recharge.total ? Number(((response.recharge.male / response.recharge.total) * 100).toFixed(2)) : 0,
      femalePercentage: response.recharge.total ? Number(((response.recharge.female / response.recharge.total) * 100).toFixed(2)) : 0,
      unknownPercentage: response.recharge.total ? Number(((response.recharge.unknown / response.recharge.total) * 100).toFixed(2)) : 0,
    }
  }

  return result;
}

export default GetCanceledCase
