import { IMeepFee } from '../../interfaces/IMeepFee'
import styles from './MeepFeeItem.module.scss'

export interface MeepFeeItemProps {
  item: IMeepFee,
}

export const MeepFeeItem = ({ item }: MeepFeeItemProps) => {
  return (
    <tr className={styles.meepFeeItem}>
      <td>{item.name}</td>
      <td>{item.value.toFixed(2)}%</td>
    </tr>
  )
}
