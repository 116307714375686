import { GetCustomerCategoriesUseCase } from "modules/customerCategories/application/useCases/GetCustomerCategoriesUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { CustomerCategoryApi } from "services/api/customerCategory/CustomerCategoryApi";
import { ICustomerCategory } from "../interfaces/ICustomerCategories";
import { CreateCustomerCategoryUseCase } from "modules/customerCategories/application/useCases/CreateCustomerCategoryUseCase";
import { DeleteCustomerCategoryUseCase } from "modules/customerCategories/application/useCases/DeleteCustomerCategoryUseCase";
import { ICategoryCustomers } from "../interfaces/ICategoryCustomers";
import { GetCategoryCustomersUseCase } from "modules/customerCategories/application/useCases/GetCategoryCustomersUseCase";
import { RemoveCustomerFromCategoryByIdUseCase } from "modules/customerCategories/application/useCases/RemoveCustomerFromCategoryByIdUseCase";

const service = CustomerCategoryApi();

export const UseCustomerCategories = () => {
    const [preLoading, setPreLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ICustomerCategory[]>([]);
    const [filteredData, setFilteredData] = useState<ICustomerCategory[]>([]);
    const [formOpened, setFormOpened] = useState(false);
    const [deleteOpened, setDeleteOpened] = useState(false);
    const [categoryCustomersOpened, setCategoryCustomersOpened] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<ICustomerCategory>();
    const [categoryCustomers, setCategoryCustomers] = useState<ICategoryCustomers>();
    const [currentPage, setCurrentPage] = useState(0);
    const [name, setName] = useState('');

    const { currentLocal } = useLocal();

    const getCategoryCustomers = useCallback(async (page?: number, name?: string, document?: string) => {
        try {
            setLoading(true);
            const response = await GetCategoryCustomersUseCase(service, currentLocal!.id, selectedCategory!.id, page ?? 0, name, document);
            setCategoryCustomers(response);
        } finally {
            setLoading(false);
        }
    }, [currentLocal, selectedCategory])

    const getData = useCallback(async () => {
        try {
            if (currentLocal) {
                setPreLoading(true);
                const response = await GetCustomerCategoriesUseCase(service, currentLocal.id);
                setData(response.categories);
                setFilteredData(response.categories);
            }
        } finally {
            setPreLoading(false);
        }
    }, [currentLocal]);

    useEffect(() => {
        if (!name) {
            setFilteredData(data);
        } else {
            setFilteredData(data.filter(x => x.name.toLowerCase().includes(name.toLowerCase())))
        }
    }, [data, name]);

    useEffect(() => {
        getData();
    }, [currentLocal, getData]);

    const handleCreateCategory = async (data: ICustomerCategory) => {
        try {
            setLoading(true);
            let formData = data;
            if (selectedCategory) formData = { ...data, id: selectedCategory.id }
            await CreateCustomerCategoryUseCase(service, currentLocal!.id, formData);
            setSelectedCategory(undefined);
            setFormOpened(false);
            getData();
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteCategory = async () => {
        try {
            if (selectedCategory) {
                setLoading(true);
                await DeleteCustomerCategoryUseCase(service, selectedCategory.id);
                setSelectedCategory(undefined);
                setDeleteOpened(false);
                getData();
            }
        } finally {
            setLoading(false);
        }
    }

    const handleToggleIsVisible = async (id: string) => {
        try {
            const item = data.find(x => x.id === id)!;
            const formData = { ...item, visible: !item.visible }
            await CreateCustomerCategoryUseCase(service, currentLocal!.id, formData);
            getData();
        } finally {
            setLoading(false);
        }
    }

    const removeCustomerFromCategory = async (customerId: string) => {
        try {
            setLoading(true);
            await RemoveCustomerFromCategoryByIdUseCase(service, selectedCategory!.id, customerId, currentLocal!.id);
            setCategoryCustomersOpened(false);
        } finally {
            setLoading(false);
        }
    }

    return {
        preLoading,
        data,
        filteredData,
        formOpened,
        categoryCustomersOpened,
        deleteOpened,
        loading,
        selectedCategory,
        categoryCustomers,
        currentPage,
        name,
        setName,
        setSelectedCategory,
        setLoading,
        setDeleteOpened,
        setFormOpened,
        setCategoryCustomersOpened,
        handleCreateCategory,
        handleDeleteCategory,
        getCategoryCustomers,
        handleToggleIsVisible,
        removeCustomerFromCategory
    }
}
