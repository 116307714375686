import React, { FC } from "react";
import EventsForm from "../../components/eventsForm/EventsForm";
import { UseEditEventsPage } from "./UseEditEventsPage";

export interface IEditEventsPageProps {}

const EditEventsPage: FC<IEditEventsPageProps> = () => {
  const { handleCancel, handleSubmit, defaultEvent } = UseEditEventsPage();
  return <EventsForm handleSubmit={handleSubmit} handleCancel={handleCancel} defaultEvent={defaultEvent} />;
};
export default EditEventsPage;
