import { IDeletePerfilEventoService } from "./IPerfilService";

const DeletePerfilEvento = async (
  accountService: IDeletePerfilEventoService,
  id: string,
  idEvento: string,
  profileId: string
): Promise<any> => {
  return await accountService.deletePerfilEvento(id, idEvento, profileId);
};

export default DeletePerfilEvento;