import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteGuestUseCase from "modules/events/application/useCases/guests/DeleteGuestUseCase";
import DoPreCheckinUseCase from "modules/events/application/useCases/guests/DoPreCheckinUseCase";
import { IEventPeople, IEventPeopleList, IEventsGuestList } from "modules/events/presentation/pages/eventGuestsLists/interfaces/IEventGuestsListsPage";
import { IListObj, IArrObj, parseObjToArr } from "modules/events/presentation/utils/utils";
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { IEventGuestFilter } from "../../UseEventPeopleListPage";
import GetGuestListUseCase from "modules/events/application/useCases/guests/GetGuestListUseCase";
import { useParams } from "react-router-dom";
import { ICustomerCategory } from "modules/customerCategories/presentation/interfaces/ICustomerCategories";
import { AddCustomerInCategoryUseCase } from "modules/customerCategories/application/useCases/AddCustomerInCategoryUseCase";
import { CustomerCategoryApi } from "services/api/customerCategory/CustomerCategoryApi";
import { RemoveCustomerFromCategoryUseCase } from "modules/customerCategories/application/useCases/RemoveCustomerFromCategoryUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { GetCustomerCategoriesUseCase } from "modules/customerCategories/application/useCases/GetCustomerCategoriesUseCase";
import DoStraightCheckinUseCase from "modules/events/application/useCases/guests/DoStraightCheckinUseCase";

const getWindowHeight = () => {
  const { innerHeight } = window;
  return innerHeight - 407;
};

const service = CustomerCategoryApi();

export const UseAlphabeticalEventPeopleList = (guestList: IEventPeopleList, refreshList: () => Promise<void>, onChangeFilterHandle: (filter: IEventGuestFilter) => void) => {
  const guestService = EventsGuestsApi();
  const { hideLoading, showLoading } = useUi();
  const { currentLocal } = useLocal();

  const [promoterList, setPromoterList] = useState<IListObj[]>([]);
  const [listNameList, setListNameList] = useState<IListObj[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<IListObj | null>(null);
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());
  const [listCpfList, setListCpfList] = useState<IListObj[]>([]);
  const [preCheckinUser, setPreCheckinUser] = useState<IEventPeople | null>(null);
  const [showPrecheckinModal, setShowPrecheckinModal] = useState(false);
  const [value, setValue] = useState<IEventGuestFilter>()
  const { eventId } = useParams<{ eventId: string }>();
  const [eventGuestList, setEventGuestList] = useState<IEventsGuestList[]>()
  const [categories, setCategories] = useState<ICustomerCategory[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(getWindowHeight());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePreCheckin = async (guestId: string, needsPreCheckin: boolean) => {
    if (!currentLocal) return;

    try {
      if (needsPreCheckin) {
        await DoPreCheckinUseCase(guestService, guestId, currentLocal.id);
      } else {
        await DoStraightCheckinUseCase(guestService, guestId, currentLocal.id);
      }

      refreshList();
    } catch (error) {
      console.error(error);
    } finally {
      togglePreCheckinModal();
    }
  }

  const setPrecheckinUser = (guest: IEventPeople) => {
    setPreCheckinUser(guest);
    togglePreCheckinModal();
  }

  const togglePreCheckinModal = () => {
    setShowPrecheckinModal(!showPrecheckinModal);
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteUser = (id: string, name: string) => {
    setUserToDelete({ id, name });
    toggleDeleteModal();
  };

  const deleteGuest = async (guestId: string) => {
    if (!currentLocal) return;

    showLoading();
    try {
      await DeleteGuestUseCase(guestService, guestId, currentLocal.id);
      await refreshList();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
      setUserToDelete(null);
      toggleDeleteModal();
    }
  };

  const getEventLists = useCallback(async () => {
    if (!currentLocal) return;

    showLoading();
    try {
      const result = await GetGuestListUseCase(guestService, eventId, currentLocal.id);
      setEventGuestList(result)
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  }, [currentLocal, eventId, hideLoading, showLoading]);

  useEffect(() => {
    getEventLists()
  }, [getEventLists])

  useEffect(() => {
    if (guestList) {
      const promoters = guestList.records.reduce(
        (item, acumulador) => ({
          ...item,
          [acumulador.promoterId]: acumulador.promoterName,
        }),
        {} as IArrObj
      );
      setPromoterList(parseObjToArr(promoters));

      const listName = guestList.records.reduce(
        (item, acumulador) => ({
          ...item,
          [acumulador.listId]: acumulador.listName,
        }),
        {} as IArrObj
      );
      setListNameList(parseObjToArr(listName));

      const listCpf = guestList.records.reduce(
        (item, acumulador) => ({
          ...item,
          [acumulador.cpf]: acumulador.cpf,
        }),
        {} as IArrObj
      );
      setListCpfList(parseObjToArr(listCpf));
    }
  }, [guestList]);

  const filterByCpf = useCallback((prop: string, value: any) => {
    setValue(prev => ({
      ...prev,
      [prop]: value.replace(/\D/g, '')
    }));
  }, []);

  const filterByGuest = (value: string) => {
    setValue((prev) => ({ ...prev, name: value }))
  };

  const filterByList = (event: ChangeEvent<{}>, value: IListObj | null) => {
    event.preventDefault();
    setValue((prev) => ({ ...prev, listNameId: value?.id }))
  };

  const filterByPromoter = (value: string) => {
    setValue((prev) => ({ ...prev, promoterId: value }))
  };


  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      value && onChangeFilterHandle({
        ...value,
        cpf: value.cpf,
        listNameId: value.listNameId,
        name: value.name,
        promoterId: value.promoterId
      });

    },
    [onChangeFilterHandle, value]
  );

  const checkinCounter = (guests: IEventPeople[] | undefined) => {
    if (guests) {
      const totalGuests = guests.length;
      const checkedGuests = guests.reduce((checked, current) => {
        return current.checkinAt ? checked++ : checked;
      }, 0);

      return `${checkedGuests} / ${totalGuests}`;
    }
    return "";
  };

  const getCategories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetCustomerCategoriesUseCase(service, currentLocal!.id);
      setCategories(response.categories.filter(x => x.visible));
    } finally {
      setLoading(false);
    }
  }, [currentLocal]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const mountCategories = (item: IEventPeople) => {
    return categories.map(x => item.peopleCategories?.map(y => y.id).includes(x.id) ? { ...x, isActive: true } : { ...x, isActive: false })
  }

  const addCustomerInCategory = async (categoryId: string, peopleId: string) => {
    try {
      showLoading();
      await AddCustomerInCategoryUseCase(service, categoryId, peopleId, currentLocal!.id);
      await refreshList();
    } finally {
      hideLoading();
    }
  }

  const removeCustomerFromCategory = async (categoryId: string, peopleId: string) => {
    try {
      showLoading();
      await RemoveCustomerFromCategoryUseCase(service, categoryId, peopleId, currentLocal!.id);
      await refreshList();
    } finally {
      hideLoading();
    }
  }

  return {
    handleDeleteUser,
    filterByGuest,
    listNameList,
    filterByList,
    promoterList,
    filterByPromoter,
    checkinCounter,
    windowHeight,
    userToDelete,
    showDeleteModal,
    toggleDeleteModal,
    deleteGuest,
    filterByCpf,
    listCpfList,
    handlePreCheckin,
    togglePreCheckinModal,
    preCheckinUser,
    setPrecheckinUser,
    showPrecheckinModal,
    submitHandle,
    eventGuestList,
    value,
    loading,
    mountCategories,
    addCustomerInCategory,
    removeCustomerFromCategory,
  };
};
