import { useCallback, useEffect, useState } from "react";
import { ILocalSegments } from "../../interfaces/localSegments/ILocalSegments";
import { SaasApi } from "services/api/saas/SaasApi";
import { GetLocalSegmentsUseCase } from "modules/saas/application/useCases/GetLocalSegmentsUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "hooks/UseQuery";
import { AuthApi } from "services/api/auth/AuthApi";
import { AuthenticateSaasUseCase } from "modules/saas/application/useCases/AuthenticateSaasUseCase";
import { IAuthenticateSaas } from "../../interfaces/authenticate/IAuthenticateSaas";
import SimpleCreateLocalUseCase from "modules/saas/application/useCases/SimpleCreateLocalUseCase";
import { ISimpleCreateLocalRequest } from "modules/saas/domain/dtos/createLocal/ISimpleCreateLocalRequest";
import CryptoJS from "crypto-js";
import { cryptPassKey, cryptPassIv } from 'Enviroment';

export enum StepEnum {
    email = 1,
    local = 2
}

const authApi = AuthApi();
const service = SaasApi();

export const UseSimpleLoginPage = () => {
    const { toast } = useUi();
    const { push } = useHistory();
    const query = useQuery();
    const location = useLocation<{ type: string }>();
    
    const [segments, setSegments] = useState<ILocalSegments[]>([]);
    const [step, setStep] = useState(StepEnum.email);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [authResponse, setAuthResponse] = useState<IAuthenticateSaas>();
    const [encryptedPass, setEncryptedPass] = useState("");
    
    useEffect(() => {
        query.get("email") && setEmail(query.get("email") as string);
        query.get("type") && setType(query.get("type") as string ?? location.state?.type);
    }, [location.state?.type, query]);

    useEffect(() => {
        (async () => {
            const response = await GetLocalSegmentsUseCase(service);
            setSegments(response);
        })();
    }, []);

    const handleForgetPassword = useCallback(() => {
        push(`/public/saas/forget-password?type=${type}&email=${email}`, { type: type });
    }, [email, push, type]);

    const getSHA256 = (key: string) => {
        if (!key) {
            return null;
        }
    
        const hash = CryptoJS.SHA256(key);
        return hash.toString(CryptoJS.enc.Hex);
    }
    
    const getSHA128 = (iv: string) => {
        if (!iv) {
            return null;
        }
    
        const hash = CryptoJS.SHA256(iv);
        return hash.toString(CryptoJS.enc.Hex).slice(0, 32);
    }

    const encrypt = useCallback((pass: string) => {
        try {
          const keyHex = getSHA256(cryptPassKey ?? "");
          const ivHex = getSHA128(cryptPassIv ?? "");
      
          if (keyHex && ivHex) {
            const encrypted = CryptoJS.AES.encrypt(
              pass,
              CryptoJS.enc.Hex.parse(keyHex),
              { iv: CryptoJS.enc.Hex.parse(ivHex), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
            );
      
            return encodeURIComponent(encrypted.toString());
          }
        } catch (error) {
          console.error("Erro ao criptografar:", error);
          return null;
        }
    }, []);

    const handleLogin = useCallback(async (values: { email: string, password: string }) => {
        try {
            setIsLoading(true);

            const auth = await authApi.login(values.email, values.password);
            const response = await AuthenticateSaasUseCase(service, {
                email: values.email,
                type: 5,
            }, auth.access_token);

            const encryptPass = encrypt(values.password);
            setEncryptedPass(encryptPass ?? "");

            setAuthResponse(response);
            setStep(StepEnum.local);
        } catch {
            toast('E-mail ou senha inválidos, tente novamente!', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [encrypt, toast]);
    
    const handleCreateLocal = useCallback(async (request: ISimpleCreateLocalRequest) => {
        if (authResponse?.prospectId && authResponse?.key) {
            try {
                setIsLoading(true);            
                await SimpleCreateLocalUseCase(service, authResponse.prospectId, authResponse.key, request);
                push(`/public/saas/simple-success?email=${email}`, { crypt: encryptedPass });
            } catch {
                toast('Ocorreu um erro. Tente novamente.', 'error');
            } finally {
                setIsLoading(false);
            }
        }
    }, [authResponse, email, encryptedPass, push, toast]);

    return {
        segments,
        step,
        isLoading,
        handleLogin,
        handleCreateLocal,
        handleForgetPassword
    }
}