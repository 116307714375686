import React, { FC, useMemo } from 'react'
import styles from './SearchItem.module.scss'
import { Skeleton } from '@mui/material'

const SearchItemSkeleton: FC = () => {
    const item = useMemo(() => 
      <div className={styles.category}>
          <div className={styles.search}>
            <div className={styles.box}>
              <Skeleton variant="rectangular" className={styles.iconCategory} width={20} height={20} />
              <div>
                <p className={styles.wrapSubTitle}>
                  <Skeleton className={styles.subTitle} variant="text" width={"80%"} />
                </p>
                <p className={styles.wrapDate}>
                  <Skeleton className={styles.date} variant="text" width={"80%"} />
                </p>
              </div>
            </div>
            <Skeleton variant="rectangular" width={20} height={20} />
          </div>
        </div>, [])
    return (
        <>
            {item}
            {item}
            {item}
        </>
    )
}
export default SearchItemSkeleton