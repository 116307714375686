import { FC, useCallback, useMemo } from "react";
import { IEvents } from "modules/events/domain/models/IEvents";
import EventCard, { IEventCardResume } from "./components/eventCard/EventCard";
import { EventCardSkeleton } from "./components/eventCardSkeleton/EventCardSkeleton";
import style from "./MyEvents.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Pagination } from "@mui/material";

export interface IMyEventsProps {
  events: IEvents[];
  getEvents: (pagination: { page: number, pageSize?: number }) => void;
  pagination?: { page?: number, pageSize?: number }
  loading: boolean;
  totalRecords: number;
  scrollabelId: string;
  infinity?: boolean
  getEventCardResumeItem?: (id: string) => IEventCardResume | undefined
  integrated: boolean
}

const MyEvents: FC<IMyEventsProps> = ({ events, getEvents, loading, totalRecords, scrollabelId, pagination, infinity = false, getEventCardResumeItem, integrated }) => {

  const getMore = useCallback(() => {
    getEvents({ page: (pagination?.page ?? 0) + 1, pageSize: (pagination?.pageSize ?? 10) })
  }, [getEvents, pagination])


  const getPage = useCallback((page: number) => {
    getEvents({ page: page - 1, pageSize: pagination?.pageSize })
  }, [getEvents, pagination?.pageSize])


  const count = useMemo(() => Math.ceil(totalRecords / (pagination?.pageSize ?? 10)), [pagination?.pageSize, totalRecords])

  const InitialLoad = useMemo(() => <div className={style.initialLoad}>
    <EventCardSkeleton />
  </div>, []);

  const list = useMemo(() => {
    let _lastDate: string = ""
    return (events.map((event, index) => {
      const _date = new Date(event.startDate).toLocaleDateString('pt-Br', { dateStyle: 'short' })
      if (_lastDate !== _date) {
        _lastDate = _date;
        return (
          <>
            <h2>{_date}</h2>
            <EventCard key={index} event={event} resume={getEventCardResumeItem?.(event.id)} integrated={integrated} />
          </>)
      } else {
        return (<>
          <EventCard key={index} event={event} resume={getEventCardResumeItem?.(event.id)} integrated={integrated} />
        </>)
      }


    }))
  }, [events, getEventCardResumeItem, integrated])

  return (
    <div id={style.MyEventsTab}>
      {infinity ? <InfiniteScroll
        className={style.list}
        dataLength={events.length}
        next={getMore}
        hasMore={events.length < totalRecords}
        loader={InitialLoad}
        scrollableTarget={scrollabelId}
      >
        {loading ? InitialLoad :
          <div className={style.list}>
            {list}
            {(events.length === 0 && !loading) && (
              <div className={style.emptyList}>Nenhum evento encontrado!</div>
            )}
          </div>
        }
      </InfiniteScroll>
        :

        loading ? InitialLoad :
          <div className={style.list}>
            {list}
            {(events.length === 0 && !loading) && (
              <div className={style.emptyList}>Nenhum evento encontrado!</div>
            )}
            <Pagination
              style={{ alignSelf: 'flex-end' }}
              variant="outlined"
              className={style.paginationNumber}
              shape="rounded"
              count={count} page={(pagination?.page ?? 0) + 1}
              onChange={(_, page) => getPage(page)}
              sx={{
                '.MuiPaginationItem-root': {
                  borderColor: '#BDBDBD',
                  color: '#001537',
                },
                '.Mui-selected': {
                  fontWeight: 'bold',
                  borderColor: '#001537',
                },
              }}
            />
          </div>

      }
    </div>
  );
};
export default MyEvents;
