import styles from "modules/saleForDevice/presentation/components/forDeviceHeader/ForDeviceHeader.module.scss";
import { IPostCashClosingParams } from "modules/cashClosing/domain/dto/IPostCashClosingParams";

export interface ICashClosingHeaderProps {
  params: IPostCashClosingParams;
  setParams: React.Dispatch<React.SetStateAction<IPostCashClosingParams>>;
}

const titleHeader = [
  {
    title: "Origem",
    value: "",
  },
  {
    title: "Cancelado",
    value: "",
  },
  {
    title: "Cashless",
    value: "",
  },
  {
    title: "Débito",
    value: "",
  },
  {
    title: "Débito (NT)",
    value: "",
  },
  {
    title: "Crédito",
    value: "",
  },
  {
    title: "Crédito (NT)",
    value: "",
  },
  {
    title: "Dinheiro",
    value: "",
  },
  {
    title: "Desconto",
    value: "",
  },
  {
    title: "Voucher",
    value: "",
  },
  {
    title: "Outros",
    value: "",
  },
  {
    title: "Em aberto",
    value: "",
  },
  {
    title: "Suprimento",
    value: "",
  },
  {
    title: "Sangria",
    value: "",
  },
  {
    title: "Total",
    value: "",
  },
];

const CashClosingHeader = ({ params, setParams }: ICashClosingHeaderProps) => {
  // const [orderBy, setOrderBy] = useState({ Type: "equipamentoNome" } as {
  //   Orientation: "asc" | "desc",
  //   Type: string,
  // });

  // useEffect(() => {
  //   if (orderBy.Type && orderBy.Orientation) {
  //     setParams({
  //       ...params,
  //       Sort: {
  //         Orientation: orderBy.Orientation,
  //         Type: orderBy.Type,
  //       }
  //     });
  //   }
  // }, [orderBy]);
  

  return (
    <div
      id={styles.CashClosingHeader}
      className="div-to-capture"
      style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }}
    >
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            {/* <IconButton
              disableRipple
              onClick={() => {
                setOrderBy({
                  Orientation: params.Sort?.Orientation === "asc" ? "desc" : "asc",
                  Type: item.value ?? "",
                });
              }}
            >
              <ImportExport />
            </IconButton> */}
          </div>
        );
      })}
    </div>
  );
};
export default CashClosingHeader;
