import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSStep } from "modules/kds/presentation/interfaces/IKDSSteps"

const GetKDSStepListUseCase = async (service: IKDSService, localId: string): Promise<IKDSStep[]> => {

    const response = await service.getSteps({ localId })
    return (response.items)
}
export default GetKDSStepListUseCase

