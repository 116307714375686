import { Pagination } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { IProductActionEnum } from "../../interfaces/IProductActionEnum";
import {
  IProductItem,
  IProductListData,
} from "../../interfaces/IProductListData";
import ProductItem from "../productItem/ProductItem";
import styles from "./ProductList.module.scss";
import UseProductPage from "../../UseProductPage";

export interface IProductListProps {
  //propertys
  productList?: IProductListData;
  isLoading?: boolean;
  pagination: { page?: number; pageSize?: number };
  onChangePage: (pagination: { page: number; pageSize: number }) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  onChangeEnabled: (id: string, enabled: boolean) => void;
  onClickActionWithProducts: (
    action: IProductActionEnum,
    selectedProducts?: IProductItem[]
  ) => void;
  onClickCopy?: (item: IProductItem) => void;
}
const ProductList: FC<IProductListProps> = ({ onClickActionWithProducts }) => {
  const [selectedProducts, setSelectedProducts] = useState<IProductItem[]>([]);

  const {
    productList,
    onChangePagination,
    isLoading,
    filter,
    onClickEdit,
    onChangeEnabled,
    onClickDelete,
    onClickCopy,
  } = UseProductPage();

  const onCheckItem = useCallback((product: IProductItem, checked: boolean) => {
    if (checked) {
      setSelectedProducts((prev) => [...prev, product]);
    } else {
      setSelectedProducts((prev) =>
        prev.filter((item) => item.id !== product.id)
      );
    }
  }, []);

  const onCheckAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedProducts(productList?.items ?? []);
      } else {
        setSelectedProducts([]);
      }
    },
    [productList]
  );

  const totalPage = useMemo(
    () => Math.ceil((productList?.count ?? 1) / (filter?.pageSize ?? 10)),
    [filter, productList]
  );

  const onClickAction = useCallback(
    (action: IProductActionEnum) => {
      onClickActionWithProducts(action, selectedProducts);
    },
    [onClickActionWithProducts, selectedProducts]
  );

  return (
    <div id={styles.ProductList}>
      <div className={styles.container}>
        <ProductItem
          onChangeCheckBox={(checked) => onCheckAll(checked)}
          checked={!!(selectedProducts.length === productList?.items.length)}
          onClickAction={onClickAction}
        />
        {isLoading ? (
          <>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
            <ProductItem isLoading={isLoading}></ProductItem>
          </>
        ) : productList?.items.length ? (
          productList?.items.map((item: any) => (
            <ProductItem
              key={item.id}
              item={item}
              onChangeCheckBox={(checked) => onCheckItem(item, checked)}
              checked={!!selectedProducts.find((_item) => _item.id === item.id)}
              onClickEdit={() => onClickEdit(item.id)}
              onChangeEnabled={(value) => onChangeEnabled(item.id, value)}
              onClickDelete={() => onClickDelete(item.id)}
              onClickCopy={onClickCopy}
            />
          ))
        ) : (
          <div className={styles.containerNotFound}>
            <img
              src={"/assets/img/notfound.png"}
              alt="Nenhum local encontrado!"
            />
            <p className={styles.text}>Nenhum produto encontrado!</p>
          </div>
        )}
      </div>
      <Pagination
        variant="outlined"
        shape="rounded"
        sx={{
          '.MuiPaginationItem-root': {
            borderColor: '#BDBDBD',
            color: '#001537',
          },
          '.Mui-selected': {
            fontWeight: 'bold',
            borderColor: '#001537',
          },
        }}
        className={styles.pagination}
        count={totalPage}
        page={(filter?.page ?? 0) + 1}
        onChange={(ev, page) =>
          onChangePagination({
            page: page - 1,
            pageSize: filter?.pageSize ?? 10,
          })
        }
      />
    </div>
  );
};

export default ProductList;
