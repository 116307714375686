import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { GetOrderPadsIdsByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetOrderPadsIdsByLocalIdUseCase';
import { GetServiceScheduleByLocalIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetServiceScheduleByLocalIdUseCase';
import { PostAddPlaceScheduleUseCase } from 'modules/schedule/application/useCases/placeSchedule/PostAddPlaceScheduleUseCase';
import { IAddPlaceScheduleRequest } from 'modules/schedule/domain/dto/placeSchedule/IAddPlaceScheduleRequest';
import { ISelect } from 'modules/schedule/domain/models/IDropDown'
import PlacesScheduleApi from 'services/api/schedule/places/PlacesScheduleApi';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import { GetPlaceScheduleByIdUseCase } from 'modules/schedule/application/useCases/placeSchedule/GetPlaceScheduleByIdUseCase';
import { IPlaceItemScheduleForm } from '../../components/placeItemScheduleForm/interface/IPlaceItemScheduleForm';
import { PutEditPlaceScheduleUseCase } from 'modules/schedule/application/useCases/placeSchedule/PutEditPlaceScheduleUseCase';

const UseEditFormPlaceSchedulePage = () => {

    const [orderPads, setOrderPads] = useState<ISelect[]>([]);
    const [services, setService] = useState<ISelect[]>([]);
    const [defaultValues, setDefaultValues] = useState<IPlaceItemScheduleForm>()
    const { currentLocal } = useLocal();

    const { showLoading, hideLoading, toast } = useUi();
    const { push } = useHistory();

    const { placeId } = useParams<{ placeId: string }>();

    useEffect(() => {
        if (currentLocal) {
            showLoading()
            GetOrderPadsIdsByLocalIdUseCase(currentLocal?.id, PlacesScheduleApi).then(response => {
                setOrderPads(response);
            }).finally(() => {
                hideLoading()
            })
        }
    }, [currentLocal])

    useEffect(() => {
        if (currentLocal) {
            showLoading()
            GetServiceScheduleByLocalIdUseCase(currentLocal?.id, PlacesScheduleApi).then(response => {
                setService(response);
            }).finally(() => {
                hideLoading()
            })
        }
    }, [currentLocal])


    const getPlaceScheduleByIdData = useCallback(() => {
        showLoading();
        GetPlaceScheduleByIdUseCase(placeId, PlacesScheduleApi).then((response) => {
            setDefaultValues(response);

        }).finally(() => {
            hideLoading();
        })
    }, [placeId]);

    useEffect(() => {
        getPlaceScheduleByIdData();
    }, [getPlaceScheduleByIdData])

    const onAddPlaceHandle = useCallback(
        (values: IPlaceItemScheduleForm) => {
            showLoading()
            if (placeId && currentLocal) {

                const placeScheduleRequest: IPlaceItemScheduleForm = {
                    ...values,
                    localId: currentLocal.id,
                }
                PutEditPlaceScheduleUseCase(placeScheduleRequest, PlacesScheduleApi).then((response) => {
                    //redirect to list here
                    toast("Passeio editado com sucesso", "success");
                    push('/private/agendamento/list/lugares')
                }).catch(() => {

                }).finally(() => {
                    hideLoading();
                });
            }
        },
        [placeId, currentLocal],
    )

    return (
        {
            orderPads,
            services,
            onAddPlaceHandle,
            defaultValues,
            getPlaceScheduleByIdData
        }
    )
}

export default UseEditFormPlaceSchedulePage
