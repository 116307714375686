import { ICrmService } from "modules/crm/models/interface/ICrmService";
import { CrmExportType, ICrmFilterValue } from "modules/crm/presentation/components/crmFilterForm/interfaces/ICrmFilterValue";
import parseFilterToRequestUseCase from "./parseFilterToRequestUseCase";

const ExportClientListUseCase = async (
  service: ICrmService,
  localId: string,
  pagination?: { page?: number, pageSize?: number },
  filter?: ICrmFilterValue,
  type?: CrmExportType,
) => {
  const request = parseFilterToRequestUseCase(localId, filter, pagination);

  if (type === CrmExportType.XLSX) {
    return await service?.exportXlsxClients(request);
  } else {
    return await service?.exportCsvClients(request);
  }
};

export default ExportClientListUseCase;
