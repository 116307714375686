import React, { FC, useCallback, useEffect } from 'react'
import styles from './AddOrUpdateSupplyGroupPage.module.scss'
import SupplyGroupForm, { ISupplyGroupValues } from 'modules/meepErp/presentation/components/supply/suplyGroup/suplyGroupForm/SupplyGroupForm'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { IGetSupplyGroupItemResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyGroupListDtos'
import { queryClient } from 'services/api/Api'
import { getSupplyGroupUseCase, getSupplyGroupListUseCase, AddSupplyGroupUseCase, UpdateSupplyGroupUseCase, searchNameSupplyGroupUseCase } from '../../../../../application/useCases/supply/supplyGroup/SupplyGroupUseCases'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { IGetSupplyNameRequest } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'

const AddOrUpdateSupplyGroupPage: FC = () => {

    const { id } = useParams<{ id: string }>()
    const { push } = useHistory()
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Grupo de insumos",
                url: '/private/meeperp/supply/supplyGroups'
            },
            {
                title: "Cadastro de grupo de insumos",
            },          
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);  

    const defaultValue = useQuery(['getSupplyGroup', id], async() => {
        try {
            showLoading();
            return await getSupplyGroupUseCase(id)
        } finally {
            hideLoading();
        }
    }, {
        enabled: !!id
    })

    const GetSearchNameSupplyGroup = useCallback(async (request: IGetSupplyNameRequest) => {
        return await searchNameSupplyGroupUseCase(request);
    }, [])

    const getSupplyGroupListHandler = useCallback(async (requeset: { parentId?: string, code?: string }) => {
        try {
            showLoading();
            return await getSupplyGroupListUseCase({ page: 1, pageSize: 20, parentId: requeset.parentId, code: requeset.code })
        } finally {
            hideLoading();
        }
    }, [hideLoading, showLoading])

    const onSucessAddSupplyGroup = useCallback(async (newValue: IGetSupplyGroupItemResponse | undefined) => {
        if (!newValue) return
        // queryClient.setQueryData(['getSupplyGroup', newValue.id], newValue)
        queryClient.invalidateQueries(['getSupplyGroupList'])
    }, [])

    const addSupplyGroup = useMutation(AddSupplyGroupUseCase, {
        onSuccess: onSucessAddSupplyGroup
    })
    const updateSupplyGroup = useMutation(['updateGroup', id], async (values: ISupplyGroupValues) => {
        return await UpdateSupplyGroupUseCase(id, values);
    }, {
        onSuccess: onSucessAddSupplyGroup
    });

    const onSubmitHandler = useCallback(async (values: ISupplyGroupValues) => {
        try {
            showLoading();
            if (id) {
                await updateSupplyGroup.mutateAsync(values)
            } else {
                await addSupplyGroup.mutateAsync(values)
            }
            push('/private/meeperp/supply/supplyGroups')
        } catch {
            console.log('error')
        } finally {
            hideLoading();

        }

    }, [addSupplyGroup, hideLoading, id, push, showLoading, updateSupplyGroup])

    return (
        <div id={styles.AddOrUpdateSupplyGroupPage} >
            <div className={styles.container} >
                <SupplyGroupForm
                    disabled={addSupplyGroup.isLoading || updateSupplyGroup.isLoading}
                    defaultValues={defaultValue.data}
                    getSupplyGroupList={getSupplyGroupListHandler}
                    onSubmit={onSubmitHandler}
                    GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                />
            </div>
        </div>
    )
}
export default AddOrUpdateSupplyGroupPage



