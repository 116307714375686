import Button from "components/ui/Button/Button";
import styles from "./Equipamento.module.scss";
import { ArrowForwardIos, Edit, InfoOutlined, Circle } from "@mui/icons-material";
import { useState } from "react";
import InfoComponent from "../info/Info";
import { IGetEquipmentsResponse } from "modules/equipamento/domain/dto/IGetEquipmentsResponse";
import { IReponseOperadores } from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";
interface IEquipamento {
  operadorList: IReponseOperadores[] | undefined;
  getEquipamentos: IGetEquipmentsResponse | undefined;
  equipamento: {
    img: string;
    nome: string;
    geral: boolean;
    saas: boolean;
    target?: string
  }[];
  identificadorSistema?: number;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Equipamento = ({
  equipamento,
  getEquipamentos,
  operadorList,
  setOpenModal,
  identificadorSistema
}: IEquipamento) => {
  const checkedEquipamento = [
    {
      value: "POS",
      checked: false,
    },
    {
      value: "Terminal",
      checked: false,
    },
    {
      value: "Totem",
      checked: false,
    },
    {
      value: "Tablet",
      checked: false,
    },
    {
      value: "Validador",
      checked: false,
    },
  ];

  const [updateCheck, setUpdateCheck] = useState([...checkedEquipamento]);

  const diferenca = [];

  for (let i = 0; i < checkedEquipamento.length; i++) {
    const equipamento = checkedEquipamento[i];
    const deviceTypeName = equipamento.value.toLowerCase();

    const equipamentoEncontrado = getEquipamentos?.Records.find(
      (e) => e.DeviceTypeName.toLowerCase() === deviceTypeName  && e.Licenses > 0
    );

    if (equipamentoEncontrado) {
      equipamento.checked = true;
    }
  }

  for (let i = 0; i < checkedEquipamento.length; i++) {
    if (!checkedEquipamento[i].checked && updateCheck[i].checked) {
      diferenca.push(checkedEquipamento[i]);
    }
  }

  const handleActive = (index: number) => {
    const updatedActive = [...updateCheck];
    if (!checkedEquipamento[index].checked) {
      updateCheck[index].checked = !updatedActive[index].checked;
      setUpdateCheck(updatedActive);
    }
  };

  return (
    <div id={styles.Equipamento}>
      <p className={styles.title}>
        Estamos quase acabando! Escolha os <span>equipamentos</span> para
        começar a vender:
      </p>
      <p className={styles.text}>
        Selecione as opções que você utiliza no seu estabelecimento.
      </p>
      <div className={styles.contentCards}>
        {equipamento.map((item, index) => {
          if (identificadorSistema === 1 && item.saas) {
            return (
              <div className={styles.cardSaasGeral}>
                <div
                  className={
                    updateCheck[index].checked ? styles.cardActiveSaas : styles.cardSaas
                  }
                  onClick={() => handleActive(index)}
                >
                  <img src={item.img} alt="imagem card" />
                  <div className={styles.boxNome}>
                    <p>{item.nome}</p>
                  </div>
                </div>
              </div>
            );
          }
          if (identificadorSistema === 0 && item.geral) {
            return (
              <div
                className={
                  updateCheck[index].checked ? styles.cardActive : styles.card
                }
                onClick={() => handleActive(index)}
              >
                <img src={item.img} alt="imagem card" />
                <div className={styles.boxNome}>
                  <p>{item.nome}</p>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={styles.container}>
        {diferenca.length > 0 && <InfoComponent equipamento={diferenca} identificadorSistema={identificadorSistema} />}
        <p>
          Seus equipamentos já foram selecionados. Agora, configure seus{" "}
          <b>perfis de venda (PDV’s)</b>:
        </p>
        <div className={styles.content}>
          <div className={styles.box}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={styles.title}>Gerencie seus PDV’s</p>
              <a href="/private/pdv/perfil" target="_blank">
                <ArrowForwardIos />
              </a>
            </div>
            <p className={styles.description}>
              Visualize os seus perfis de venda do seu estabelecimento
            </p>
          </div>
          <div className={styles.box}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={styles.title}>Crie um novo PDV</p>
              <a href="/private/pdv/perfil/adicionar" target="_blank">
                <ArrowForwardIos />
              </a>
            </div>
            <p className={styles.description}>
              Crie um novo perfil de venda do seu etabelecimento
            </p>
          </div>
          {identificadorSistema === 1 &&
            <div className={styles.box}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className={styles.title}>Gerenciar terminais</p>
                <a href="/private/terminal" target="_blank">
                  <ArrowForwardIos />
                </a>
              </div>
              <p className={styles.description}>
                Gerencie os terminais de venda do seu estabelecimento
              </p>
            </div>
          }
        </div>
      </div>
      <div className={styles.container}>
        <p>
          Agora, cadastre os <span>operadores</span> para começar a usar os
          equipamentos:
        </p>
        <div className={styles.content}>
          {operadorList &&
            operadorList.slice(0, 2).map((item) => {
              return (
                <div className={styles.box}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.titleOperador}>
                      <p className={styles.title}>{item.nome}</p>
                      <Circle />
                    </div>
                    <a href="/private/cadastros/operadores" target="_blank">
                      <Edit />
                    </a>
                  </div>
                  <div className={styles.password}></div>
                  <p className={styles.text}>
                    Permissões: selecionado {item.papeis.length} de 27
                  </p>
                </div>
              );
            })}
          {operadorList && operadorList?.length > 2 && (
            <div className={styles.boxOperador} onClick={() =>
              window.open("/private/cadastros/operadores", "_blank")
            }>
              <p className={styles.title}>
                + {operadorList?.length - 2} operadores
              </p>
              <p className={styles.description}>
                Clique aqui para <b>visualizar todos os operadores</b> cadastrados
              </p>
            </div>
          )}
        </div>
        <div className={styles.boxInfo}>
          <Button
            onClick={() =>
              window.open("/private/cadastros/operadores", "_blank")
            }
            className={styles.button}
          >
            Criar novo operador
          </Button>
          <div className={styles.info}>
            <InfoOutlined />
            <p>
              Foram criados operadores padrões de acordo com os equipamentos
              selecionados.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipamento;
