import Button from 'components/ui/Button/Button';
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import React, { FC } from 'react'
import styles from "./ServiceProductListForm.module.scss";
import { ArrowBackIos } from '@material-ui/icons';
import { IProductListServiceScheduleForm } from './interfaces/IProductListServiceScheduleForm';
import UseServiceProductListForm from './UseServiceProductListForm';

interface ServiceProductListFormProps {
    defaultValues?: IProductListServiceScheduleForm,
    onSubmit: (values: IProductListServiceScheduleForm) => void,
    toggleModal: () => void,
}
const ServiceProductListForm: FC<ServiceProductListFormProps> = ({ defaultValues, onSubmit, toggleModal }) => {
    const {
        values,
        onChangeHandle,
        onSubmitHandle
    } = UseServiceProductListForm(onSubmit, defaultValues);
    return (
        <div id={styles.ServiceProductListForm}>
            <h2><ArrowBackIos onClick={toggleModal} /> Nova <span>Lista</span> </h2>

            <form onSubmit={onSubmitHandle}>
                <FormItemContainer >
                    <FormItemContainer label={"Nome da Lista"}>
                        <Input
                            name={"description"}
                            value={values.description}
                            placeholder="Ex.: Aniversariante"
                            onChange={ev => { onChangeHandle(ev.target.name, ev.target.value) }}
                            required
                        />
                    </FormItemContainer>                   
                    <div className={styles.containerButton}>
                        <Button fullWidth={false} type="submit">{defaultValues ? "Atualizar" : "Concluir"}</Button>
                        <Button fullWidth={false}  variant="outlined" onClick={toggleModal}>Voltar</Button>
                    </div>
                </FormItemContainer>
            </form>
        </div>
    )
}

export default ServiceProductListForm