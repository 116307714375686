export interface IFidelityBalance {
    currentPage: number;
    totalPages: number;
    totalRegisters: number;
    data: IFidelityBalanceItem[];
}

export interface IFidelityBalanceItem {
    balanceType: BalanceType;
    description: string;
    details: string;
    date: Date;
    launchType: LaunchType;
}

export enum BalanceType {
    ADD_BALANCE = 1,
    SUB_BALANCE = 2,
    REFUND_BALANCE = 3
}

export enum LaunchType {
    CURRENCY = 1, 
    FREQUENCY = 2, 
    BIRTHDAY = 3, 
    MANUAL = 4
}