import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { FC } from "react";
import styles from "./GrantMultipleAccessForm.module.scss";
import { IPromoters } from "./interfaces/IPromoters";
import UseGrantAccessForm from "./UseGrantMultipleAccessForm";

interface IGrantAccessForm {
  closeModal: () => void;
  handleConfirm: (promoters: IPromoters[]) => Promise<void>;
}

const GrantAccessForm: FC<IGrantAccessForm> = ({ closeModal, handleConfirm }) => {
  const {
    promotersFromPlace,
    selectedPromoters,
    changePromotersHandle,
    handleClearAll,
    handleCancel,
    handleSelectAll,
    onSubmit,
  } = UseGrantAccessForm(closeModal, handleConfirm);

  return (
    <div id={styles.GrantAccessForm}>
      <div>
        <p>
          Todo promoter adicionado <b>terá acesso à todas as listas de convidados atuais e nas que serão criadas do seu evento.</b> Todo promoter <b>excluído terá seu acesso retirado de todas as listas atuais e das listas que serão criadas do seu evento.</b>
        </p>
        <FormItemContainer label="Promoters com permissão" className={styles.select}>
          <AutoComplete
            variant="outlined"
            filterSelectedOptions
            multiple
            limitTags={2}
            disableCloseOnSelect
            noOptionsText="Sem opções"
            options={promotersFromPlace.filter(list => !selectedPromoters?.find(value => value.id === list.id))}
            value={selectedPromoters}
            getOptionLabel={(option) => option.name}
            onChange={(_evt, item) => changePromotersHandle(item as IPromoters[])}
          />
        </FormItemContainer>
      </div>
      <div className={styles.modalActions}>
        <Button fullWidth={false} onClick={handleCancel} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button fullWidth={false} onClick={onSubmit} color="primary">
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default GrantAccessForm;
