import DeletePrinterUseCase from "modules/config/smartPrinterConfig/application/useCases/DeletePrinterUseCase";
import GetPrintersUseCase from "modules/config/smartPrinterConfig/application/useCases/GetPrintersUseCase";
import PostNewPrinterUseCase from "modules/config/smartPrinterConfig/application/useCases/PostNewPrinterUseCase";
import { IPostNewPrinterRequest } from "modules/config/smartPrinterConfig/domain/dtos/IPostNewPrinterRequest";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import PrinterApi from "services/api/config/printer/PrinterApi";

export const UsePrintersFragment = () => {
    const { currentLocal } = useLocal();

    const [simplePrintersLoading, setSimplePrintersLoading] = useState(false);

    const getSimplePrintersList = useCallback(async () => {
        if (currentLocal) {
            try {
                setSimplePrintersLoading(true);
                const response = await GetPrintersUseCase(PrinterApi, currentLocal.id);
                return response;
            } catch {
                throw new Error("Erro ao buscar impressoras");
            } finally {
                setSimplePrintersLoading(false);
            }
        } else {
            throw new Error("Erro ao buscar impressoras");
        }
    }, [currentLocal]);

    const postNewSimplePrinter = useCallback(async (request: IPostNewPrinterRequest) => {
        if (currentLocal) {
            try {
                setSimplePrintersLoading(true);
                const response = await PostNewPrinterUseCase(PrinterApi, {
                    ...request,
                    localClienteId: currentLocal.id,
                });
                return response;
            } catch {
                throw new Error("Erro ao adicionar impressora");
            } finally {
                setSimplePrintersLoading(false);
            }
        } else {
            throw new Error("Erro ao adicionar impressora");
        }
    }, [currentLocal]);

    const deleteSimplePrinter = useCallback(async (printerId: string) => {
        if (currentLocal) {
            try {
                setSimplePrintersLoading(true);
                const response = await DeletePrinterUseCase(PrinterApi, printerId);
                return response;
            } catch {
                throw new Error("Erro ao deletar impressora");
            } finally {
                setSimplePrintersLoading(false);
            }
        } else {
            throw new Error("Erro ao deletar impressora");
        }
    }, [currentLocal]);

    return (
        {
            simplePrintersLoading,
            getSimplePrintersList,
            postNewSimplePrinter,
            deleteSimplePrinter
        }
    )
}
