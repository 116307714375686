import { IGetOperacoesService, IReponseOperacoes } from "../interfaces/IGestaoVendasService";

const GetOperacoes = async (
  accountService: IGetOperacoesService,
  id: string
): Promise<IReponseOperacoes> => {
  return await accountService.getOperacao(id);
};

export default GetOperacoes;
