import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./PaymentLinkRecurrenceItem.module.scss";
import { Close, KeyboardArrowRight, QrCode } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import CPF from "services/utils/CPF";
import CNPJ from "services/utils/CNPJ";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import {
  IPaymentLinkRecurrenceColumnsConfig,
  PaymentLinkRecurrenceColunmsPropertyType,
} from "../interfaces/IColumnsConfig";
import {
  IMePayLinkPaymenItemResult,
  IPaymentLinkRecurrenceList,
} from "modules/paymentLinkReport/presentation/interfaces/IPaymentLinkRecurrenceList";
import { IParamsPaymentLinkList } from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkReportList";
import DeleteValidation from "components/deleteValidation/DeleteValidation";
import UsePaymentLinkCancelSubscription from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkCancelSubscription";
import Utils from "services/utils/Utils";
import UsePaymentLinkDetailsList from "modules/paymentLinkReport/presentation/hooks/UsePaymentLinkDetailsList";
import PaymentLinkDetails from "modules/paymentLinkReport/presentation/components/paymentLinkDetails/PaymentLinkDetails";

interface Props {
  columns: IPaymentLinkRecurrenceColumnsConfig[];
  userItemData: IMePayLinkPaymenItemResult;
  paymentLinkRecurrenceList: IPaymentLinkRecurrenceList;
  reload: () => void;
  params: IParamsPaymentLinkList;
}

const PaymentLinkRecurrenceItem: React.FC<Props> = ({
  userItemData,
  columns,
  reload,
}) => {
  const [step, setStep] = useState(1);
  const [openDrownDelete, setOpenDrownDelete] = useState(false);
  const [openDrownDetails, setOpenDrownDetails] = useState(false);
  const { cancelSubscriptionPaymentLink, isLoadingCancel } =
    UsePaymentLinkCancelSubscription();
  const { getPaymentLinkDetailsList, isLoading, paymentLinkDetailsList } =
    UsePaymentLinkDetailsList();

  const onClose = useCallback(() => {
    if (step === 2) {
      reload();
    }
    setStep(1);
    setOpenDrownDelete(false);
  }, [reload, step]);

  const onCloseDetails = useCallback(() => {
    setOpenDrownDetails(false);
  }, []); 

  const continueOrSave = useCallback(async () => {
    if (step === 2) {
      onClose();
    } else {
      const res = await cancelSubscriptionPaymentLink(userItemData.id);
      if (res === "ok") {
        return setStep(step + 1);
      }
    }
  }, [cancelSubscriptionPaymentLink, onClose, step, userItemData.id]);

  const continueDetails = useCallback(() => {
    if (step === 1) {
      onCloseDetails();
    }
  }, [onCloseDetails, step]);

  const closedOrDeleteButton = useCallback(() => {
    if (step === 2) {
      return "Fechar";
    } else {
      return "Cancelar";
    }
  }, [step]);

  const closedOrDetailsButton = useCallback(() => {
    return "Fechar";
  }, []);

  const openDrowDelete = useCallback(() => {
    setOpenDrownDelete(true);
  }, []);

  const openDrowModalDetails = useCallback(() => {
    setOpenDrownDetails(true);
  }, []);

  const formatDocument = useCallback((document: string) => {
    if (CPF.isValid(document)) return CPF.cpfMask(document);
    else return CNPJ.mask(document);
  }, []);

  const formatValues = useCallback(
    (
      property: PaymentLinkRecurrenceColunmsPropertyType
    ): string | JSX.Element => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {
          case "description":
            return userItemData?.description ? userItemData?.description : "-";
          case "productName":
            return userItemData?.productName ? userItemData?.productName : "-";
          case "createdAt":
            return userItemData?.createdAt
              ? Utils.toDate(userItemData?.createdAt + "Z")
              : "-";
          case "userName":
            return userItemData?.userName ? userItemData?.userName : "-";
          case "isActive":
            return userItemData?.isActive ? (
              <div className={styles.active}>Ativo</div>
            ) : (
              <div className={styles.cancel}>Cancelado</div>
            );
          case "document":
            return userItemData?.document
              ? formatDocument(userItemData?.document)
              : "-";
          case "value":
            return userItemData?.value
              ? moneyMaskNumber(userItemData?.value)
              : "-";

          default:
            return String(userItemData?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [formatDocument, userItemData]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        const value = formatValues(colunm.property);
        const plainText = typeof value === "string" ? value : "";
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span title={plainText}>{value}</span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  useEffect(() => {
    if (openDrownDetails) {
      getPaymentLinkDetailsList(userItemData.id);
    }
  }, [getPaymentLinkDetailsList, openDrownDetails, userItemData.id]);

  const handleContent = useCallback(() => {
    let highlightText = `${userItemData.description}?`;
    let message = `Deseja realmente cancelar a recorrência`;

    return <DeleteValidation text={message} highlightText={highlightText} />;
  }, [userItemData.description]);

  const handleContentDetails = useCallback(() => {
    return (
      <PaymentLinkDetails
        paymentLinkDetailsList={paymentLinkDetailsList}
        isLoading={isLoading}
      />
    );
  }, [isLoading, paymentLinkDetailsList]);

  return (
    <>
      <div id={styles.PaymentLinkRecurrenceItem}>
        <div className={styles.container}>
          {List}
          <div className={styles.icon}>
            {userItemData.isActive && (
              <Close titleAccess="Cancelar" onClick={openDrowDelete} />
            )}
            <KeyboardArrowRight
              titleAccess="Detalhes"
              onClick={openDrowModalDetails}
            />
          </div>
        </div>
      </div>
      <Sidesheet
        isLoading={isLoadingCancel}
        open={openDrownDelete}
        onClose={onClose}
        title={
          <h2>
            Cancelar <b>assinatura</b>
          </h2>
        }
        content={handleContent()}
        currentStep={step}
        totalSteps={2}
        handleContinueButton={continueOrSave}
        continueButton={closedOrDeleteButton()}
        cancelButton
        customizedCancelButton={"Voltar"}
        feedback={
          <SidesheetFeedback
            text="Recorrência cancelada com sucesso!"
            success
          />
        }
      />
      <Sidesheet
        isLoading={isLoading}
        open={openDrownDetails}
        onClose={onCloseDetails}
        title={
          <h2>
            Mais <b>informações</b>
          </h2>
        }
        content={handleContentDetails()}
        currentStep={step}
        totalSteps={1}
        handleContinueButton={continueDetails}
        continueButton={closedOrDetailsButton()}
      />     
    </>
  );
};

export default PaymentLinkRecurrenceItem;
