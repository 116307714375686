import { Autocomplete, Drawer, IconButton } from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CampaignTemplate, ICampaignEmail } from '../../intefaces/ICampaign'
import { InputContainer } from '../_inputContainer/InputContainer'
import { InputCRM } from '../_inputCRMFilter/InputCRM'
import styles from './TempateCampaignEventEmail.module.scss'

import draftToHtml from 'draftjs-to-html';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import { ICrmEvent } from '../../intefaces/ICrmEventData'
import { messageBodyEmail } from './messageBodyEmail'
import { Button } from '@material-ui/core'
import { ISurveyItem } from 'modules/automation/presentation/interfaces/ISurvey'
import { messageBodySurveyEmail } from './messageBodyEmailSurvey'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

export interface ITempateCampaignEventEmailProps {
    //propertys
    events?: ICrmEvent[],
    defaultMessageBody?: string
    campaign: {
        name: string
    }
    values?: ICampaignEmail
    onChange: (values: ICampaignEmail) => void
    erros?: {
        event?: string,
        from?: string,
        replyTo?: string,
        subject?: string,
        promoter?: string,
        // greeting:string,
        messageBody?: string,
        survey?: string,
    }
    requireEvent?: boolean,
    surveys?: ISurveyItem[],
    template: CampaignTemplate,
    requireSurvey?: boolean
}
const TempateCampaignEventEmail: FC<ITempateCampaignEventEmailProps> = ({ events, campaign, onChange, values, defaultMessageBody, erros, requireEvent, template, surveys }) => {
    const [openPreview, setOpenPreview] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [showEditor, setShowEditor] = useState(false);

    const { currentLocal } = useLocal();

    useEffect(() => {
        if (defaultMessageBody) {
            const start = defaultMessageBody.indexOf("<content>") + 9;
            const end = defaultMessageBody.indexOf("</content>");

            if (start) {
                const _editorString = defaultMessageBody.substring(start, end)
                const fromHtml = convertFromHTML(_editorString)
                setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(fromHtml.contentBlocks, fromHtml.entityMap)))
            }
        }
    }, [defaultMessageBody, values?.messageBody])

    useEffect(()=>{
        if(!!values?.survey){
            onChange({ ...values ?? { event: undefined, from: undefined, replyTo: "", subject: "", promoter: "", messageBody: "", survey: undefined }, messageBody:  messageBodySurveyEmail() })
        }
    },[onChange, values, values?.survey])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...values ?? { event: undefined, from: undefined, replyTo: "", subject: "", promoter: "", messageBody: "", survey: undefined }, [ev.target.name]: ev.target.value });
    }, [onChange, values]);

    const onChangeEventHandle = useCallback((event?: ICrmEvent) => {
        onChange({ ...values ?? { event: undefined, from: undefined, replyTo: "", subject: "", promoter: "", messageBody: "", survey: undefined }, event: event });
    }, [onChange, values]);

    const onChangeSurveyHandle = useCallback((survey?: ISurveyItem) => {
        onChange({ ...values ?? { event: undefined, from: undefined, replyTo: "", subject: "", promoter: "", messageBody: messageBodySurveyEmail(), survey: undefined }, survey: survey });
    }, [onChange, values]);

    const onChangeEditor = useCallback((value: EditorState) => {
        setEditorState(value)
        const editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        onChange({ ...values ?? { event: undefined, from: undefined, replyTo: "", subject: "", promoter: "", messageBody: "", survey: undefined }, messageBody: messageBodyEmail(editorValue, false, values?.event) })
    }, [editorState, onChange, values])

    const editoString = useMemo(() => draftToHtml(convertToRaw(editorState.getCurrentContent())), [editorState]);

    const distinctEvents = useMemo(() => {
        const distinctArray = events?.filter((value, index, self) => {
          return self.findIndex(item => item.name === value.name) === index;
        });
        return distinctArray
      }, [events])

    return (
        <div id={styles.TempateCampaignEmail} >
            <div className={styles.container} >
                <InputContainer style={{ width: "100%" }}>
                    {distinctEvents && currentLocal?.systemIdentifier !== 1 && <InputContainer>
                        <Autocomplete
                            options={distinctEvents}
                            onChange={(_, value) => onChangeEventHandle(value ?? undefined)}
                            value={values?.event}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <InputCRM
                                    error={!!erros?.event}
                                    helperText={erros?.event}
                                    label={"Evento"}
                                    {...params}
                                />
                            )}
                        />
                    </InputContainer>}
                    {values?.survey?.trackSurveyName}
                    {surveys && template === CampaignTemplate.survey && <InputContainer>
                        <Autocomplete
                            options={surveys}
                            onChange={(_, value) => onChangeSurveyHandle(value ?? undefined)}
                            value={values?.survey}
                            getOptionLabel={(option) => option.trackSurveyName}
                            renderInput={(params) => (
                                <InputCRM
                                    error={!!erros?.survey}
                                    helperText={erros?.survey}
                                    label={"Pesquisa"}
                                    {...params}
                                />
                            )}
                        />
                    </InputContainer>}
                    <InputContainer style={{ width: "100%" }}>
                        <InputCRM name={"subject"}
                            error={!!erros?.subject}
                            helperText={erros?.subject}
                            value={values?.subject}
                            onChange={changeHandle}
                            label={"Assunto"}
                        />
                    </InputContainer>

                    {(template === CampaignTemplate.info || template === CampaignTemplate.event) && <InputContainer style={{ width: "100%" }}>
                        <div className={styles.previewContainer}>
                            <div className={styles.header}>
                                <h1>{campaign.name}</h1><Button variant='outlined' onClick={() => setOpenPreview(true)}>Visualizar</Button>
                            </div>
                            <div className={!erros?.messageBody ? styles.paper : styles.paperError} >
                                {values?.event && <div className={styles.contentHeader}>
                                    <img src={values?.event?.bannerUrl} alt={"imagem do event"} />
                                </div>}
                                <div className={styles.content}>
                                    {values?.event ?
                                        <div className={styles.eventInfo}>
                                            <h1>{values?.event.name}</h1>
                                            <div className={styles.eventInfoItem}>
                                                <label>Data:</label><span>{new Date(values.event.startDate).toLocaleString()}</span>
                                            </div>
                                        </div> : requireEvent && <div className={styles.emptyEvent}>Selecione um evento</div>
                                    }
                                    {

                                        <InputContainer>
                                            {/* <InputContainer>
                                            <InputCRM onChange={changeHandle} label="Selecione um saudação" />
                                        </InputContainer> */}
                                            <InputContainer>
                                                Corpo do email
                                                <div className={styles.editorContainer}>
                                                    <IconButton size="small" onClick={() => setShowEditor(!showEditor)}>
                                                        {showEditor ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                                                    </IconButton>
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={onChangeEditor}
                                                        toolbarHidden={showEditor}
                                                    />
                                                </div>
                                            </InputContainer>
                                            {erros?.messageBody && <div className={styles.errorMessage}>Insira o corpo do email</div>}
                                        </InputContainer>}


                                </div>
                            </div>
                        </div>
                    </InputContainer>}
                    {(template === CampaignTemplate.survey) &&
                        <div dangerouslySetInnerHTML={{ __html: messageBodySurveyEmail(true) }}>


                        </div>
                    }
                </InputContainer>
            </div>
            <Drawer open={openPreview} onClose={() => setOpenPreview(false)}>
                <iframe style={{ width: "80vw", maxWidth: "100%", height: "100vh" }} title={"preview"} srcDoc={template === CampaignTemplate.survey ? messageBodySurveyEmail(true) : messageBodyEmail(editoString, true, values?.event)} />
            </Drawer>
        </div >
    )
}
export default TempateCampaignEventEmail