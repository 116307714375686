import { ISort } from "interfaces/ISort";
import { IGetDiscountCouponsReportResponse } from "../../domain/dto/IGetDiscountCouponsReportResponse";
import { IDiscountCouponsService } from "../../domain/interfaces/IDiscountCouponsService";
import { IDiscountCouponsReportFilter } from "../../presentation/components/discountCouponFilter/interfaces/IDiscountCouponReportFilter";
import ParseReportFilterToRequestUseCase from "./ParseReportFilterToRequestUseCase";


const GetDiscountCouponsReportUseCase = async (service: IDiscountCouponsService, localId: string, sort?: ISort, pagination?: { page?: number, pageSize?: number }, filter?: IDiscountCouponsReportFilter): Promise<IGetDiscountCouponsReportResponse> => {
   
    const request =  ParseReportFilterToRequestUseCase(localId, pagination, filter)
    const response = await service.getDiscountCouponsReport(request, sort)
    return (response)
}
export default GetDiscountCouponsReportUseCase