import { ITotemItem } from "../../presentation/components/smartPrinterIForm/interfaces/ISmartPrinterForm";
import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetProductListUseCase = async (smartPrintersApi: ISmartPrinterApiCB, localClienteId: string):Promise<ITotemItem[]> => {

    const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
    const response = await _smartPrintersApi.getProductListByLocalClenteId(localClienteId);
    return response
}

export default GetProductListUseCase;



