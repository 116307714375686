import { FC } from 'react';
import { moneyMaskNumber } from 'services/utils/Money';
import styles from './PosPaidCard.module.scss';
import { IPosPaid } from '../../IPosPaid';
import CPF from 'services/utils/CPF';

interface IPosPaidCardProps {
  posPaid: IPosPaid
};

export const PosPaidCard: FC<IPosPaidCardProps> = ({ posPaid }) => {
  return (
    <div id={styles.PosPaidCard}>
      <p>{posPaid.name?.trim() || '-'}</p>
      <p>{CPF.cpfMask(posPaid.cpf) || '-'}</p>
      <p>{moneyMaskNumber(posPaid.openValue)}</p>
      {/* <p>{moneyMaskNumber(posPaid.consumed)}</p> */}
      {/* <div>
        <p>{posPaid.card}</p> */}

        {/* <IconButton>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton> */}

      {/* </div> */}
    </div>
  );
};