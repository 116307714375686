import { IPrinterItem } from "../../presentation/components/smartPrinterIForm/interfaces/ISmartPrinterForm";
import { ISmartPrinterApi, ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetPrinterListUseCase = async (smartPrintersApi: ISmartPrinterApiCB, localClienteId: string):Promise<IPrinterItem[]> => {

    const _smartPrintersApi: ISmartPrinterApi = smartPrintersApi();
    const response = await _smartPrintersApi.getPrinterListByLocalClenteId(localClienteId);
    return response
}

export default GetPrinterListUseCase;



