import { useMemo } from "react";
import { LinkOutlined } from "@material-ui/icons";
import { ContentCopyOutlined } from "@mui/icons-material";
import Button from "components/ui/Button/Button";
import { terminaUrl } from "Enviroment";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { IDeviceItem } from "modules/Terminal/models/ITerminalDeficeItem";
import { toast } from "react-toastify";
import styles from "./TerminalCard.module.scss";
import { useHistory } from "react-router-dom";

interface ITerminalCardProps {
    terminalDevice: IDeviceItem;
    salesNowTerminal?: boolean;
}

const TerminalCard = ({ terminalDevice, salesNowTerminal = false }: ITerminalCardProps) => {
    const { currentLocal } = useLocal();
    const history = useHistory();
    
    const currentTerminalLink = useMemo(() => `${terminaUrl ?? "http://terminal.meep.cloud"}/navigator/${currentLocal?.id}/${terminalDevice.device.id}`, [currentLocal?.id, terminalDevice.device.id]);

    const navigate = () => {
        return history.push("/private/vendas/saleNow");
    }

    if (salesNowTerminal) {
        return (
            <div id={styles.TerminalCard}>
                <h2>
                    <LinkOutlined style={{ width: "20px" }} />
                    Terminal: Vender agora
                </h2>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={navigate}
                >
                    Clique para vender
                </Button>
            </div>
        )
    }
    return (
      <div id={styles.TerminalCard}>
        <h2>
            <LinkOutlined style={{ width: "20px" }} />
            Link para o {terminalDevice.profile?.name}
        </h2>
        <div>
            <a
                href={currentTerminalLink}
                target="_blank"
                rel="noreferrer"
            >
                {currentTerminalLink.slice(0, 42)}...
            </a>
            <Button
                variant="outlined"
                color="inherit"
                endIcon={<ContentCopyOutlined />}
                onClick={() => {
                    navigator.clipboard.writeText(currentTerminalLink);
                    toast.info("Link copiado para area de transferência!");
                }}
            >
                Copiar link
            </Button>
        </div>
      </div>
    );
}

export default TerminalCard;
