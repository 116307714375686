
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useRef, useState } from "react";
import LinkCardsApi from "services/api/linkCards/LinkCardsApi";
import { DeleteLinkCardsItemUseCase } from "modules/linkCards/application/useCases/DeleteLinkCardsItemUseCase";
import { IDeleteLinkCards } from "../interfaces/IDeleteLinkCards";

const UseDeleteLinkCardsItem = () => {

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>();
  const { currentLocal } = useLocal();
  const [deleteData, setDeleteData] = useState<IDeleteLinkCards>()
  const successDelete = useRef(false);
  


  const deleteLinkCardsItem = useCallback(
    async (tags: string) => {
      try {
        const serviceLinkCards = LinkCardsApi();

        setIsLoadingDelete(true);
        if (currentLocal) {
         const response = await DeleteLinkCardsItemUseCase(serviceLinkCards,
            tags,
            currentLocal.id,
          );
          setDeleteData(response)
          successDelete.current = true;
          return response
        }
      } catch {
        successDelete.current = false;

      }

      finally {
        setIsLoadingDelete(false);
      }
    },
    [currentLocal]
  );


  return {    
    successDelete,
    isLoadingDelete,
    deleteLinkCardsItem,
    deleteData

  };
};

export default UseDeleteLinkCardsItem;
