import { FC, useCallback, useEffect, useState } from 'react';
import styles from './AddOrUpdatePartnerPage.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { CreateIndividualPartnerUseCase, GetIndividualPartnerUseCase, UpdateIndividualPartnerUseCase } from 'modules/meepErp/application/useCases/partners/PartnersUseCases';
import { GetSegmentListToSelectUseCase } from 'modules/meepErp/application/useCases/segments/SegmentUseCases';
import { IIndividualPartnerValues } from 'modules/meepErp/presentation/components/partners/partnerForm/individualParterForm/IIndividualPartnerValues.types';
import IndividualPartnerForm from 'modules/meepErp/presentation/components/partners/partnerForm/individualParterForm/IndividualPartnerForm';
import { GetDefaultPartnerResponse, PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import { queryClient } from 'services/api/Api';
import { PartnerOriginType } from 'modules/meepErp/models/partner/PartnerOriginType';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

const AddOrUpdateIndividualPartnerPage: FC = () => {

    const { id } = useParams<{ partnerOriginType: string; id?: string; }>();
    const { replace } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Cadastros",
            },
            {
                title: "Parceiros",
                url: '/private/meeperp/partners/'
            },
            {
                title: "Cadastro de parceiros",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    //queries
    const sectors = useQuery(['sectors'], GetSegmentListToSelectUseCase);

    //indidvidual
    const getDefaultIndividualValues = useCallback(async () => {
        if (id) {
            try {
                showLoading();
                const values = await GetIndividualPartnerUseCase(id);
                return values;
            } finally {
                hideLoading();
            }
        }
    }, [hideLoading, id, showLoading]);

    const onSubmitIndividual = useCallback(async (values: IIndividualPartnerValues) => {
        try {
            showLoading();
            if (id) {
                return await UpdateIndividualPartnerUseCase(id, values);
            } else {
                return await CreateIndividualPartnerUseCase(values);
            }
        } finally {
            hideLoading();
            queryClient.invalidateQueries(['@partners'])
        }
    }, [hideLoading, id, showLoading]);



    const defaultIndividualValues = useQuery(['@defaultPartner', id], getDefaultIndividualValues, { enabled: !!id });

    const onSucessfulSubmit = useCallback((data: GetDefaultPartnerResponse) => {
        if (id) {
            queryClient.setQueriesData<PartnerListResponse | undefined>(['@partners'], (previous) =>
            (previous ? {
                ...previous,
                items: previous?.items?.map(item => item.id === id ? data : item) ?? []
            } : undefined))
        } else {
            queryClient.setQueriesData<PartnerListResponse | undefined>(['@partners'], (previous) =>
            (previous ? {
                ...previous,
                items: [data, ...previous.items]
            } : undefined))
        }

        queryClient.invalidateQueries(['@partners'])
        replace('/private/meeperp/partners')

    }, [replace])


    const onSubmitIndividualForm = useMutation(onSubmitIndividual, {
        onSuccess: onSucessfulSubmit
    });

    const onChangeOriginType = useCallback((originType: PartnerOriginType) => {
        replace(`/private/meeperp/partners/addOrUpdate/${originType === PartnerOriginType.CORPORATE ? 'corporate' : 'individual'}/${id ?? ""}`)
    }, [id, replace])

    return (
        <div id={styles.AddOrUpdatePartnerPage}>
            <div className={styles.container}>
                <IndividualPartnerForm
                    disabled={defaultIndividualValues.isLoading}
                    onChangeOriginType={onChangeOriginType}
                    defaultValues={defaultIndividualValues.data}
                    segments={sectors.data ?? []}
                    isLoading={defaultIndividualValues.isLoading}
                    // isSubmitting={onSubmitIndividualForm.isLoading}
                    onSubmit={onSubmitIndividualForm.mutateAsync}
                />
            </div>
        </div>
    );
};



export default AddOrUpdateIndividualPartnerPage;