import React, { FC, useCallback, useEffect, useState } from 'react'
import { useError } from '../../contexts/error/ErrorContext'
import { dashboardContainerEnum } from '../../interfaces/DashboardContainerEnum'
import { IGetDataParams } from '../../interfaces/IGetDataParams'
import RankingList from '../rakingList/RankingList'
import { IRankingOperators } from './IRankingOperators'
import styles from './RankingOperators.module.scss'
export interface IRankingOperatorsProps {
    //propertys
    getRankingOperators?: ({ page, sortField, sortOrientation }: IGetDataParams) => Promise<IRankingOperators>
}

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
}).format(value)
const RankingOperators: FC<IRankingOperatorsProps> = ({ getRankingOperators }) => {
    const [rankinkOperatorsData, setRankinkOperatorsData] = useState<IRankingOperators>({ records: [], totalRecords: 0 });
    const [onLoading, setOnLoading] = useState(false);
    const { setOnError, clearContainerError } = useError();
    const [sortField, setSortField] = useState<string>()
    const [sortOrientation, setSortOrientation] = useState<'asc' | 'desc'>('desc')
    const [currentPage, setCurrentPage] = useState(0);
    
    const getData = useCallback(async (page?: number) => {
        if (getRankingOperators) {
            clearContainerError(dashboardContainerEnum.RANKING_OPERATORS);
            try {
                !page && setOnLoading(true);
                const response = await getRankingOperators?.({ sortField, page, sortOrientation });
                if (page) {
                    setRankinkOperatorsData(prev => ({ ...prev, records: [...prev?.records ?? [], ...response.records], totalRecords: response.totalRecords }));
                } else {
                    setRankinkOperatorsData(prev => ({ ...prev, records: [...response.records], totalRecords: response.totalRecords }));
                }

                if (!response.records.length)
                    setOnError({
                        type: 'warning',
                        containerId: dashboardContainerEnum.RANKING_OPERATORS
                    })
            } catch (error: any) {
                setOnError({
                    type: 'error',
                    containerId: dashboardContainerEnum.RANKING_OPERATORS
                })
            } finally {
                setOnLoading(false);
            }
        }
    }, [getRankingOperators, clearContainerError, sortField, sortOrientation, setOnError]);

    useEffect(() => {
        getData(0);
    }, [getData]);

    const getMore = useCallback(() => {
        setCurrentPage(currentPage + 1);
        getData(currentPage + 1);
    }, [currentPage, getData])

    const onClickSortHandle = useCallback((property: string) => {
        setSortField(property);
        setSortOrientation(prev => prev === 'asc' ? 'desc' : 'asc')
        setCurrentPage(0);
    }, [])

    return (
        <div id={styles.RankingOperators} >
            <RankingList
                // keyProp="id"
                list={rankinkOperatorsData?.records}
                count={rankinkOperatorsData?.totalRecords}
                getMore={getMore}
                isLoading={onLoading}
                colunms={{
                    operatorName: { title: "Operador", onClickSort: onClickSortHandle },
                    totalConsumption: { title: "Consumido", formatValue: (value) => formatCurrency(value), onClickSort: onClickSortHandle },
                    totalCharged: { title: "Cobrado", formatValue: (value) => formatCurrency(value), onClickSort: onClickSortHandle },
                }}
            />
        </div>
    )
}
export default RankingOperators