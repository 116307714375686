import { UseQueryResult, useQuery } from "react-query";

import { BaseErrorTypes, normalizeResponse } from "services/api/Api";

import { GetExportSalesReport } from "../requests/GetExportSalesReport";
import { GetExportSalesReportProps } from "../types/GetExportSalesReport.types";

type UseExportSalesReportResult = UseQueryResult<any, BaseErrorTypes>;

export const UseExportSalesReportQueryName = "UseExportSalesReport";

export const UseExportSalesReport = (
  props: GetExportSalesReportProps
): UseExportSalesReportResult => {
  const { onSuccess, onError, ...rest } = props;
  const request = async (): Promise<any> => {
    const response = await GetExportSalesReport({ ...rest });
    return normalizeResponse<any>(response);
  };

  return useQuery<any, BaseErrorTypes>(
    [UseExportSalesReportQueryName],
    request,
    { refetchOnWindowFocus: false, enabled: false, onSuccess, onError }
  );
};
