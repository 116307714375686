import { Drawer } from '@material-ui/core';
import { FC } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons';
import { Close } from '@material-ui/icons';

import styles from './ConfirmChangeNfce.module.scss';
import AlertButton from '../../../alertButton/AlertButton';

export interface IConfirmChangeNfceProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}
const ConfirmChangeNfce: FC<IConfirmChangeNfceProps> = ({ open, onClose, onConfirm }) => {
    return (
        <Drawer anchor='right' open={open} BackdropProps={{ invisible: true }}>
            <div className={styles.container}>
                <div className={styles.containerTitle}>
                    <h2>Atenção <span>geração de NFC-e</span></h2>
                    <Close onClick={onClose} />
                </div>
                
                <AlertButton 
                    type="warnning" 
                    label="Atenção! O local que este PDV está vinculado gera NFC-e. Tem certeza que deseja desabilitar a função de gerar NFC-e neste equipamento?" />
                
                <FooterButtons 
                    onClickBack={onClose}
                    onClickNext={() => onConfirm()} 
                    labelBack={'Cancelar'} 
                    labelNext={'Sim'} 
                />
            </div>
        </Drawer>
    )
}
export default ConfirmChangeNfce