
import { INovidadesBody, IPostNovidadesService } from "../interfaces/INovidadesService";

const PostNovidades = async (
  novidadesService: IPostNovidadesService,
  body: INovidadesBody,
): Promise<any> => {
  return await novidadesService.postNovidades(body);
};

export default PostNovidades;