import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSStores } from "modules/kds/presentation/interfaces/_IKDSStores"

const GetKDSStoresListUseCase = async (service: IKDSService, localId: string): Promise<IKDSStores[]> => {

    const response = await service.getStores({ localId })
    return (response.items)
}
export default GetKDSStoresListUseCase

