import { Drawer, Icon } from '@material-ui/core';
import styles from './DowngradeRequestModal.module.scss';

interface DowngradeRequestModalProps {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const DowngradeRequestModal = ({ open, loading, onClose, onSubmit }: DowngradeRequestModalProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>Voltar para o <b>Plano Básico</b></h1>
                    <button onClick={onClose}><Icon>close</Icon></button>
                </header>

                <div className={styles.content}>

                    <div className={styles.successContainer}>
                        <img src="/assets/img/alert.png" alt="" />
                        <span>Deseja realmente voltar para o <b>Plano Básico?</b></span>
                    </div>
                    
                    <div className={styles.separator}>
                        <div />
                        <span>O que isso significa?</span>
                        <div />
                    </div>

                    <p>Sua solicitação de cancelamento do Plano Premium será enviada para o Analista de Sucesso do Cliente responsável. </p>

                    <p>Após isso, todas as funcionalidades do Plano Premium <b>estarão indisponíveis</b> para o seu estabelecimento.</p>
                </div>
                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Voltar</button>
                    <button disabled={loading} onClick={onSubmit}>{loading ? 'Enviando...' : 'Confirmar'}</button>
                </div>
            </div>
        </Drawer>
    )
}