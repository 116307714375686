import React, { FC, ReactNode } from 'react'
import styles from './ui.module.scss'
import { Icon, IconButton } from '@material-ui/core'
export interface IContainerDrawerProps {
    //propertys
    title: ReactNode | string,
    children: ReactNode,
    onClose?: () => void
}
const ContainerDrawer: FC<IContainerDrawerProps> = ({ title, children, onClose }) => {
    return (
        <div id={styles.ContainerDrawer} >
            {onClose && <IconButton className={styles.closeIcon} onClick={onClose}><Icon>close</Icon></IconButton>}
            <div className={styles.title}>{title}</div>
            <div className={styles.container} >
                {children}
            </div>
        </div>
    )
}
export default ContainerDrawer