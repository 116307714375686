import React from "react";
import { RightDrawer } from "../../../../../components/layout/presentation/rightDrawer/RightDrawer";

import style from "./ListAlertGuests.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import Button from "components/ui/Button/Button";
import {
  EnumTypeGuestFailed,
  IAddGuestForm,
  IGuestFailedInList,
} from "../../../domain/models/IEvents";
import CPF from "../../../../../services/utils/CPF";

interface IListAlertGuestProps {
  listGuestFailed: IGuestFailedInList[] | undefined;
  listCpf: string[];
  setListGuestFailed: any;
  open: boolean;
  toggleModal: (show: boolean) => void;
  handleSubmit: (guestList: IAddGuestForm) => void;
}

const ListAlertGuests: React.FC<IListAlertGuestProps> = ({
  open,
  toggleModal,
  listGuestFailed,
  setListGuestFailed,
  listCpf,
  handleSubmit,
}) => {
  const onCloseGuestList = () => {
    toggleModal(false);
  };

  const removeCpfFromList = (index: number) => () => {
    if (listGuestFailed) {
      listGuestFailed[index].removed = true;
      setListGuestFailed([...listGuestFailed]);
    }
  };

  const onHandleSubmit = () => {
    const itemsToSend = listCpf.filter((cpf) => {
      const found = listGuestFailed?.find((guestFailed) => {
        return guestFailed.cpf === cpf;
      });

      return !found?.removed;
    });

    const guestList: IAddGuestForm = {
      cpfList: itemsToSend,
      IgnoreAlert: true,
    };

    handleSubmit(guestList);
  };

  const removeGuestWithAlert = () => {
    const items = listGuestFailed?.map((guest) => {
      if (guest.kind === EnumTypeGuestFailed.BLOCK) {
        return guest;
      }

      return {
        ...guest,
        removed: guest.kind === EnumTypeGuestFailed.ALERT,
      };
    });

    setListGuestFailed(items);
  };

  const removeGuestWithBlock = () => {
    const items = listGuestFailed?.map((guest) => {
      if (guest.kind === EnumTypeGuestFailed.ALERT) {
        return guest;
      }

      return {
        ...guest,
        removed: guest.kind === EnumTypeGuestFailed.BLOCK,
      };
    });

    setListGuestFailed(items);
  };

  const removeAll = () => {
    const items = listGuestFailed?.map((guest) => {
      return {
        ...guest,
        removed: true,
      };
    });

    setListGuestFailed(items);
  };

  const hasAlerted = !!listGuestFailed?.filter(
    (guest) => guest.kind === EnumTypeGuestFailed.ALERT && !guest.removed
  ).length;
  const hasBlocked = !!listGuestFailed?.filter(
    (guest) => guest.kind === EnumTypeGuestFailed.BLOCK && !guest.removed
  ).length;

  const getMessageTitle = () => {
    let messageErrors = {
      [EnumTypeGuestFailed.ALERT_BLOCK]:
        "O CPF’s abaixo estão bloqueados ou com alertas, remová-os da lista.",
      [EnumTypeGuestFailed.ALERT]:
        "O CPF’s abaixo possuem alertas, você pode adicioná-los. Caso opte por não adicionar, remová-os da lista.",
      [EnumTypeGuestFailed.BLOCK]:
        "O CPF’s abaixo estão bloqueados, remová-os da lista.",
      [EnumTypeGuestFailed.RESOLVED]:
        "Todas as pendências resolvidas! Confirme o envio da lista de CPF’s.",
    };

    let kind = EnumTypeGuestFailed.RESOLVED;
    if (hasBlocked && hasAlerted) {
      kind = EnumTypeGuestFailed.ALERT_BLOCK;
    } else if (hasAlerted && !hasBlocked) {
      kind = EnumTypeGuestFailed.ALERT;
    } else if (hasBlocked) {
      kind = EnumTypeGuestFailed.BLOCK;
    }

    return messageErrors[kind];
  };

  return (
    <RightDrawer
      title="Alerta!"
      open={open}
      onClose={onCloseGuestList}
      className={style.ListAlertGuestsModal}
    >
      <div id={style.ListAlertGuestsContainer}>
        <div className={style.Alert}>
          <div className={style.IconAlert}>
            <span className={style.TextIconAlert}>!</span>
          </div>
          <p> {getMessageTitle()} </p>
        </div>

        <div className={style.ListGuests}>
          {listGuestFailed?.map((guest, index) => (
            <div className={style.ListGuestItem} key={String(index)}>
              <span className={guest.removed ? style.removed : undefined}>
                {guest.removed && <CheckIcon />}
                {CPF.cpfMask(guest?.cpf || "")}
              </span>

              {guest.kind === EnumTypeGuestFailed.BLOCK && (
                <Button
                  className={style.ButtonTextIcon}
                  startIcon={<BlockIcon />}
                  fullWidth={false}
                  disabled
                  variant={"text"}
                >
                  Bloqueado&nbsp;{guest.removed ? "(Removido)" : null}
                </Button>
              )}

              {guest.kind === EnumTypeGuestFailed.ALERT && (
                <Button
                  className={style.ButtonTextIcon}
                  startIcon={<CloseIcon />}
                  fullWidth={false}
                  disabled={guest.removed}
                  variant={"text"}
                  onClick={removeCpfFromList(index)}
                >
                  Remover da lista&nbsp;{guest.removed ? "(Removido)" : null}
                </Button>
              )}
            </div>
          ))}
        </div>

        <div className={style.ListButtons}>
          {hasAlerted && !hasBlocked && (
            <Button
              startIcon={<CloseIcon />}
              fullWidth={false}
              variant={"text"}
              onClick={removeAll}
            >
              Remover todos da lista
            </Button>
          )}

          {!(hasAlerted && !hasBlocked) && hasAlerted && (
            <Button
              startIcon={<CloseIcon />}
              fullWidth={false}
              variant={"text"}
              onClick={removeGuestWithAlert}
            >
              Remover com alerta
            </Button>
          )}

          {hasBlocked && (
            <Button
              startIcon={<BlockIcon />}
              fullWidth={false}
              variant={"text"}
              onClick={removeGuestWithBlock}
            >
              Remover bloqueados
            </Button>
          )}
        </div>

        <div className={style.action}>
          <Button
            fullWidth={false}
            variant="outlined"
            onClick={onCloseGuestList}
          >
            Cancelar
          </Button>
          <Button
            fullWidth={false}
            type="button"
            disabled={hasBlocked}
            onClick={onHandleSubmit}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};

export default ListAlertGuests;
