import React, { FC, useMemo } from 'react'
import styles from './OperatorList.module.scss'
import { IOperatorItem } from 'modules/operators/domain/models/IOperatorItem'
import { IconButton } from '@material-ui/core'
import { Divider, Skeleton } from '@mui/material'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
export interface IOperatorListProps {
    //propertys
    operatorList: IOperatorItem[],
    onClickEdit: (operator: IOperatorItem) => void,
    onClickDelete: (operator: IOperatorItem) => void,
    onClickAdd: () => void
    isLoading: boolean
}
const OperatorList: FC<IOperatorListProps> = ({ operatorList, onClickEdit, onClickDelete, onClickAdd, isLoading }) => {

    const list = useMemo(() => <div className={styles.listContainer}>
        {
            operatorList.map((operator, index) => (
                <>
                    <div key={index} className={styles.operatorItem} >
                        <div className={styles.name} >{operator.name}</div>
                        {/* <div className={styles.roles} >{operator.roles.join(',')}</div> */}
                        <div className={styles.actions} >
                            <IconButton onClick={() => onClickEdit(operator)} disableRipple ><EditOutlined /></IconButton>
                            <IconButton onClick={() => onClickDelete(operator)} ><DeleteOutlined /></IconButton>
                        </div>
                    </div>
                    {index !== operatorList.length -1 && <Divider />}
                </>
            ))
        }
    </div>, [operatorList, onClickEdit, onClickDelete]);

    const skeleton = useMemo(() => (
        <div className={styles.listContainer}>
            {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} width="100%" height={50} />
            ))}
        </ div>
    ), []);

    return (
        <div id={styles.OperatorList} >
            <div className={styles.container} >
                {isLoading ? skeleton : list}
            </div>
        </div>
    )
}
export default OperatorList