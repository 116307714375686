import { useCallback, useEffect, useState } from 'react';

const UseExport = (
  handleShowDetails: (show: boolean) => void,
  handleShowResume: (show: boolean) => void,
  handleShowSystemType: (show: boolean) => void,
  handleShowTickets: (show: boolean) => void,
) => {
  const [selectAll, setSelectAll] = useState(true);
  const [resum, setResum] = useState(true);
  const [tickets, setTickets] = useState(true);
  const [systemType, setSystemType] = useState(true);
  const [details, setDetails] = useState(true);

  useEffect(() => {
    if (resum && tickets && systemType && details) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [details, resum, systemType, tickets]);

  const setAll = useCallback((checked: boolean) => {
    setResum(checked);
    setTickets(checked);
    setSystemType(checked);
    setDetails(checked);
  }, []);

  useEffect(() => {
    handleShowDetails(details);
    handleShowResume(resum);
    handleShowSystemType(systemType);
    handleShowTickets(tickets);
  }, [details, resum, systemType, tickets]);

  return {
    selectAll,
    setAll,
    resum,
    setResum,
    tickets,
    setTickets,
    systemType,
    setSystemType,
    details,
    setDetails,
    // handleExport,
  };
};

export default UseExport;
