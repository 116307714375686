import { IconButton, Tooltip } from "@material-ui/core";
import {
  AccessTimeOutlined,
  CancelOutlined,
  DoneAllOutlined,
  RemoveDoneOutlined,
  TaskAltOutlined,
  UnpublishedOutlined,
} from "@mui/icons-material";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { FC, useCallback, useMemo } from "react";
import CPF from "services/utils/CPF";
import Utils from "services/utils/Utils";
import {
  IAprovacaoTIDsColumns,
  IAprovacaoTIDsProperty,
} from "../../interfaces/IAprovacaoTIDsColumns";
import { AdditionalTidsStatusEnum, ITID } from "../../interfaces/ITIDsList";
import { SideSheetType } from "../aprovacaoTIDsSideSheet/AprovacaoTIDsSideSheet";
import styles from "./TIDsItem.module.scss";

export interface ITIDsItem {
  tid: ITID;
  columns: IAprovacaoTIDsColumns[];
  openSidesheet: (id: string, type: SideSheetType) => void;
}

const TIDsItem: FC<ITIDsItem> = ({ tid, columns, openSidesheet }) => {
  const { isMobile, toast } = useUi();

  const formatValues = useCallback(
    (property: IAprovacaoTIDsProperty): string => {
      switch (property) {
        case "MerchantDocument":
          return CPF.cpfHideMask(tid.MerchantDocument);
        case "CreatedAt":
          return Utils.toDate(tid.CreatedAt);
        case "CompletedAt":
          return tid.CompletedAt ? Utils.toDate(tid.CreatedAt) : "-";
        case "Status":
          return AdditionalTidsStatusEnum[tid.Status];
        case "Tids":
          return tid.Tids.slice(0, 10) + "...";

        default:
          return String(tid?.[property] ?? "");
      }
    },
    [tid]
  );

  const copyTIDsToClipboard = useCallback(() => {
    navigator.clipboard.writeText(tid.Tids);
    toast("TIDs copiados para área de transferência", "success");
  }, [tid.Tids, toast]);

  const WebItem = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide &&
          (colunm.property === "Tids" ? (
            <Tooltip title="Copiar TIDs" placement="top">
              <div
                key={index}
                className={styles.row}
                style={
                  colunm.style ?? {
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    cursor: "pointer",
                  }
                }
                onClick={copyTIDsToClipboard}
              >
                <span title={formatValues(colunm.property)}>
                  {formatValues(colunm.property)}
                </span>
              </div>
            </Tooltip>
          ) : (
            <div
              key={index}
              className={styles.row}
              style={
                colunm.style ?? {
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                }
              }
            >
              <span title={formatValues(colunm.property)}>
                {formatValues(colunm.property)}
              </span>
            </div>
          ))
        );
      }),
    [columns, copyTIDsToClipboard, formatValues]
  );

  const MobileItem = useMemo(
    () => (
      <>
        <div className={styles.mid}>
          <span>{tid.UsuarioName ?? ""}</span>
          {tid.Status === AdditionalTidsStatusEnum.Pendente ? (
            <div className={styles.icon}>
              <Tooltip title="Validar">
                <IconButton
                  size="small"
                  onClick={() => openSidesheet(tid.Id, "VALIDATE")}
                >
                  <AccessTimeOutlined />
                </IconButton>
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Validação ? (
            <div style={{ display: "flex" }}>
              <div className={styles.icon}>
                <Tooltip title="Rejeitar">
                  <IconButton
                    size="small"
                    onClick={() => openSidesheet(tid.Id, "REJECT")}
                  >
                    <RemoveDoneOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={styles.icon}>
                <Tooltip title="Aprovar">
                  <IconButton
                    size="small"
                    onClick={() => openSidesheet(tid.Id, "APPROVE")}
                  >
                    <DoneAllOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Aprovado ? (
            <div className={styles.icon}>
              <Tooltip title="Aprovado" style={{ cursor: "default" }}>
                <TaskAltOutlined />
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Cancelado ? (
            <div className={styles.icon}>
              <Tooltip title="Cancelado" style={{ cursor: "default" }}>
                <CancelOutlined />
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Recusado ? (
            <div className={styles.icon}>
              <Tooltip title="Recusado" style={{ cursor: "default" }}>
                <UnpublishedOutlined />
              </Tooltip>
            </div>
          ) : (
            <div className={styles.icon}>
              <Tooltip title="Completo" style={{ cursor: "default" }}>
                <DoneAllOutlined />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.mid}>
          <span>Documento:</span>
          <span>{CPF.cpfHideMask(tid.MerchantDocument)}</span>
        </div>
        <div className={styles.mid}>
          <span>Id:</span>
          <span>{tid.AcquirerMerchantId}</span>
        </div>
        <div className={styles.mid}>
          <span>TIDs:</span>
          <Tooltip title="Copiar TIDs" placement="top">
            <span style={{ cursor: "pointer" }} onClick={copyTIDsToClipboard}>
              {tid.Tids.slice(0, 10) + "..."}
            </span>
          </Tooltip>
        </div>
        <div className={styles.mid}>
          <span>Status:</span>
          <span>{AdditionalTidsStatusEnum[tid.Status]}</span>
        </div>
        <div className={styles.mid}>
          <span>Criado em:</span>
          <span>{Utils.toDate(tid.CreatedAt)}</span>
        </div>
        <div className={styles.footer}>
          <span>Completo em:</span>
          <span>{tid?.CompletedAt ? Utils.toDate(tid.CompletedAt) : "-"}</span>
        </div>
      </>
    ),
    [
      tid.UsuarioName,
      tid.Status,
      tid.MerchantDocument,
      tid.AcquirerMerchantId,
      tid.Tids,
      tid.CreatedAt,
      tid.CompletedAt,
      tid.Id,
      copyTIDsToClipboard,
      openSidesheet,
    ]
  );

  return (
    <div id={styles.TIDsItem}>
      {isMobile ? (
        <div className={styles.mobileContainer}>{MobileItem}</div>
      ) : (
        <div className={styles.webContainer}>
          {WebItem}
          {tid.Status === AdditionalTidsStatusEnum.Pendente ? (
            <div className={styles.icon} style={{minWidth: 67}}>
              <Tooltip title="Validar">
                <IconButton
                  size="small"
                  onClick={() => openSidesheet(tid.Id, "VALIDATE")}
                >
                  <AccessTimeOutlined />
                </IconButton>
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Validação ? (
            <>
              <div className={styles.icon}>
                <Tooltip title="Rejeitar">
                  <IconButton
                    size="small"
                    onClick={() => openSidesheet(tid.Id, "REJECT")}
                  >
                    <RemoveDoneOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={styles.icon}>
                <Tooltip title="Aprovar">
                  <IconButton
                    size="small"
                    onClick={() => openSidesheet(tid.Id, "APPROVE")}
                  >
                    <DoneAllOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          ) : tid.Status === AdditionalTidsStatusEnum.Aprovado ? (
            <div className={styles.icon} style={{minWidth: 67}}>
              <Tooltip title="Aprovado" style={{ cursor: "default" }}>
                <TaskAltOutlined />
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Cancelado ? (
            <div className={styles.icon} style={{minWidth: 67}}>
              <Tooltip title="Cancelado" style={{ cursor: "default" }}>
                <CancelOutlined />
              </Tooltip>
            </div>
          ) : tid.Status === AdditionalTidsStatusEnum.Recusado ? (
            <div className={styles.icon} style={{minWidth: 67}}>
              <Tooltip title="Recusado" style={{ cursor: "default" }}>
                <UnpublishedOutlined />
              </Tooltip>
            </div>
          ) : (
            <div className={styles.icon} style={{minWidth: 67}}>
              <Tooltip title="Completo" style={{ cursor: "default" }}>
                <DoneAllOutlined />
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TIDsItem;
