import { AddCircle } from '@mui/icons-material'
import React, { useCallback, useRef, useState } from 'react'
import styles from './PermutationPage.module.scss'
import { PermutationFilter } from '../components/permutationFilter/PermutationFilter'
import { UsePermutationPage } from './UsePermutationPage'
import { PermutationAccountList } from './permutationAccountList/PermutationAccountList'
import { AddPermutation } from './permutationAccountList/addPermutation/AddPermutation'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { IAddPermutationAccount } from '../interfaces/IAddPermutionAccount'
import UseAddPermutationAccount from '../hooks/UseAddPermutationAccount'
import Button from 'components/ui/Button/Button'
import UsePermutationAccountItem from '../hooks/UsePermutationAccountItem'

export enum AddPermutationStepEnum {
  DATA = 0,
  PERMUTATION = 1,
  USE = 2,
}


export const PermutationPage = () => {

  const { getPermutationAccountList, isLoading, onChangeFilterHandle, params, setParams, onChangePaginationHandle, permutationAccountList } = UsePermutationPage();
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();
  const { postAddPermutation } = UseAddPermutationAccount();
  const successAdd = useRef(false);

  const [step, setStep] = useState<AddPermutationStepEnum>(0)

  const { getPermutationAccountItem, permutationAccountItem, setPermutationAccountItem, isLoadingEdit } = UsePermutationAccountItem();


  const [openSidesheet, setOpenSidesheet] = useState<
    "isNew" | "isEditing" | "isDeleting" | null
  >(null);

  const reloadPageList = useCallback(() => {
    getPermutationAccountList({ ...params })
  }, [getPermutationAccountList, params])

  const onSubmit = useCallback(async (values: IAddPermutationAccount) => {
    showLoading();
    try {
      if (currentLocal) {
        const res = await postAddPermutation({ ...values, placeId: currentLocal?.id });
        if (res === 'ok') {
          setStep(step + 1)
          reloadPageList();
          successAdd.current = true;
        }
      }
    } finally {
      hideLoading();
    }
  }, [currentLocal, hideLoading, postAddPermutation, reloadPageList, showLoading, step]);

  const reload = useCallback(() => {
    getPermutationAccountList({ ...params })
  }, [getPermutationAccountList, params])


  return (
    <div id={styles.PermutationPage}>
      <div className={styles.buttonAddPermutation}>
        {
          <Button onClick={() => setOpenSidesheet("isNew")}>
            Adicionar permuta
            <AddCircle />
          </Button>
        }
      </div>
      <PermutationFilter onSubmit={onChangeFilterHandle} />


      <PermutationAccountList
        permutationAccountList={permutationAccountList}
        isLoading={isLoading}
        onChangePaginationHandle={onChangePaginationHandle}
        params={params}
        getPermutationAccountItem={getPermutationAccountItem}
        setParams={setParams}
        open={openSidesheet === "isEditing"}
        setOpenSidesheet={setOpenSidesheet}
        pagination={params?.pagination}
        reload={reload}
      />

      <AddPermutation
        open={openSidesheet === "isNew" || openSidesheet === "isEditing"}
        onSubmit={onSubmit}
        permutationAccountList={permutationAccountList}
        isLoadingEdit={isLoadingEdit}
        setPermutationAccountItem={setPermutationAccountItem}
        defaultValues={permutationAccountItem}
        setOpenSidesheet={setOpenSidesheet}
        reloadPageList={reloadPageList}
        setStep={setStep}
        step={step}
        successAdd={successAdd}
      />

    </div>
  )
}
