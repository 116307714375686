import { Icon } from "@material-ui/core";
import styles from "./CardItem.module.scss";
interface CardItemProps {
  icon: string;
  title: string;
  description: string;
  colorIcon: string;
}

const CardItem = ({ icon, title, description, colorIcon }: CardItemProps) => {
  return (
    <div id={styles.CardItem}>
      <Icon fontSize='small' className={colorIcon}>{icon}</Icon>
      <p>
        {title} : <span className={styles.description}>{description}</span>
      </p>
    </div>
  );
};
export default CardItem;
