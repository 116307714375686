import { ImportExport } from "@mui/icons-material";
import styles from "./OrderNovidades.module.scss";

const titleHeader = [
  {
    label: "Data publicação",
    value: "date",
  },
  {
    label: "Título",
    value: "title",
  },

  {
    label: "Categoria",
    value: "category",
  },
];

interface IOrderNovidades {
  onClick: (value : string) => void
}

const OrderNovidades = ({onClick} : IOrderNovidades) => {
  return (
    <div id={styles.OrderNovidades}>
      {titleHeader.map((item) => {
        return (
          <div
            className={styles.container}
            onClick={() => {
              onClick(item.value)
            }}
          >
            {item.label}
            <ImportExport />
          </div>
        );
      })}
      <div className={styles.last} />
    </div>
  );
};
export default OrderNovidades;
