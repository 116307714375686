import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { BreadcrumbsEnum } from "components/breadcumbs/IBreadcumbsRouter";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddMultiplePromotersToEventUseCase from "modules/events/application/useCases/access/AddMultiplePromotersToEventUseCase";
import CreateGuestListUseCase from "modules/events/application/useCases/guests/CreateGuestListUseCase";
import GetGuestListUseCase from "modules/events/application/useCases/guests/GetGuestListUseCase";
import { IAddMultiplePromotersRequest } from "modules/events/domain/dto/IEventsRequest";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { AccessApi } from "services/api/events/access/AccessApi";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";
import { IPromoters } from "../../components/grantAccessForm/interfaces/IPromoters";
import { IGuestListForm } from "../../components/guestListForm/interfaces/GuestListForm.types";
import { IArrObj, IListObj } from "../../utils/utils";
import { IEventsGuestList, IGuestListFilter } from "./interfaces/IEventGuestsListsPage";
import UseManageEventPage from "../manageEvent/UseManageEventPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

const guestService = EventsGuestsApi();

const parseObjToArr = (promoterObj: IArrObj) => {
  let arr: IListObj[] = [];
  Object.keys(promoterObj).forEach((item) => arr.push({ id: item, name: promoterObj[item] }));
  return arr;
};

export const UseEventGuestsListsPage = () => {
  const { hasAccessRole } = useAuth();
  const adminAccess = hasAccessRole("AdminNomeLista");
  const { hideLoading, showLoading, toast } = useUi();
  const { eventId } = useParams<{ eventId: string }>();
  const accessService = AccessApi();
  const [showMultipleAccessModal, setShowMultipleAccessModal] = useState(false);
  const [eventGuestLists, setEventGuestLists] = useState<IEventsGuestList[]>();
  const [filteredEventGuestLists, setFilteredEventGuestLists] = useState<IEventsGuestList[]>();
  const [showAddListModal, setShowAddListModal] = useState(false);
  const [filters, setFilters] = useState<IEventsGuestList[]>()
  const { updateRouters } = useBreadcumbs();
  const {event} = UseManageEventPage()
  const { currentLocal } = useLocal();

  const { url } = useRouteMatch()

  const getEventLists = useCallback(async () => {
    if (!currentLocal) return;

    showLoading();
    try {
      const result = await GetGuestListUseCase(guestService, eventId, currentLocal.id);
      setEventGuestLists(result);
      setFilters(result);
      setFilteredEventGuestLists(result)
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  }, [currentLocal, eventId, hideLoading, showLoading]);

  useEffect(() => {
    updateRouters([
      { id: BreadcrumbsEnum.EVENTOS, title: 'Evento/Caixa', url: '/private/eventos/list/eventos' },
      { title: event?.name, label: "Lista de Convidados" },
      { id: BreadcrumbsEnum.LISTA_CONVIDADOS, title: 'Lista de Convidados', label: "Lista de Convidados", url: `/private/event/eventos/listas/${eventId}` },

    ])
  }, [updateRouters, event?.name,  eventId, url])

  useEffect(() => {
    if (eventId) {
      getEventLists();
    }
  }, [eventId, getEventLists]);

  const { nameFilter, baseListFilter, closingDate, promoterFilter } = useMemo(() => {
    const nameFilter: IGuestListFilter[] = eventGuestLists?.map((item) => ({ id: item.id, name: item.name })) ?? [];

    const promoterFilter = parseObjToArr(
      eventGuestLists?.reduce(
        (reducer, current) => ({
          ...reducer,
          [current?.mainPromoter?.id]: current?.mainPromoter?.name,
        }),
        {} as IArrObj
      ) || ({} as IArrObj)
    ).filter((it) => it.name !== undefined);

    const baseListFilter = parseObjToArr(
      eventGuestLists?.reduce(
        (reducer, current) =>
          current.baseList
            ? {
              ...reducer,
              [current.baseList?.id]: current.baseList?.name,
            }
            : reducer,
        {} as IArrObj
      ) || ({} as IArrObj)
    );

    const closingDate: IArrObj[] = [];

    eventGuestLists?.forEach((event, index) => {
      let cd: IArrObj = {
        label: event.closingDateTime
      };

      let existedClosingDateIndex = closingDate.findIndex(obj => obj.label === cd.label);
      if (existedClosingDateIndex <= -1) {
        closingDate.push(cd);
      }
    })

    return {
      nameFilter,
      promoterFilter,
      baseListFilter,
      closingDate,
    };
  }, [eventGuestLists]);

  const filterByList = useCallback(
    (value: IGuestListFilter | null) => {
      if (value) {
        setFilteredEventGuestLists(filters?.filter((item) => item.id === value.id));
      } else {
        setFilteredEventGuestLists(undefined)
        setFilteredEventGuestLists(eventGuestLists);
      }
    },
    [eventGuestLists, filters]
  );

  const filterByBaseList = useCallback(
    (value: IListObj | null) => {
      if (value) {
        setFilteredEventGuestLists(filters?.filter((item) => item.baseList?.id === value.id));
      } else {
        setFilteredEventGuestLists(eventGuestLists);
      }
    },
    [eventGuestLists, filters]
  );

  const filterByClosingDate = useCallback(
    (value: IArrObj | null) => {
      if (value) {
        setFilteredEventGuestLists(filters?.filter((item) => item.closingDateTime === value.label));
      } else {
        setFilteredEventGuestLists(eventGuestLists);
      }
    },
    [eventGuestLists, filters]
  );

  const filterByPromoter = useCallback(
    (value: IListObj | null) => {
      if (value) {
        setFilteredEventGuestLists(filters?.filter((item) => item.mainPromoter.id === value.id));
      } else {
        setFilteredEventGuestLists(eventGuestLists);
      }
    },
    [eventGuestLists, filters]
  );

  const openMultipleAccessModal = useCallback(() => {
    setShowMultipleAccessModal(true);
  }, [])

  const closeMultipleAccessModal = useCallback(() => {
    setShowMultipleAccessModal(false);
  }, [])

  const handleGrantMultipleAccess = useCallback(
    async (promoters: IPromoters[]) => {
      if (!currentLocal) return;
      const cpfs = promoters.map((item) => item.cpf && item.cpf).filter((item) => item !== null);
      showLoading();
      try {
        const addRequest: IAddMultiplePromotersRequest = {
          eventId,
          cpfs,
        };
        await AddMultiplePromotersToEventUseCase(accessService, addRequest, currentLocal.id);
        toast("Acessos garantidos com sucesso!", "success");
        closeMultipleAccessModal();
      } finally {
        hideLoading();
      }
    },
    [accessService, closeMultipleAccessModal, eventId, hideLoading, showLoading, toast, currentLocal]
  );

  const openAddListModal = useCallback(() => {
    setShowAddListModal(true);
  }, []);

  const closeAddListModal = useCallback(() => {
    setShowAddListModal(false);
  }, []);

  const handleAddList = useCallback(async (list: IGuestListForm) => {
    if (!currentLocal) return;

      showLoading();
      try {
        await CreateGuestListUseCase(guestService, {
          eventId,
          baseListId: list.baseList?.id,
          closingDateTime: new Date(list.closingDateTime).toISOString(),
          description: list.description,
          discountLimit: list.discountLimit,
          mainPromoterId: list.mainPromoter.id,
          name: list.name,
          promotersIdsWithAccess: list.promotersWithAccess?.map((it) => it.id) ?? [],
          useDiscountLimit: list.useDiscountLimit,
          copyConfiguration: list.copyConfiguration,
          copyGuests: list.copyGuests,
          validUntil: list.validUntil && new Date(list.validUntil).toISOString(),
          shareDescription: list.shareDescription,
          shareEnabled: list.shareEnabled,
        }, currentLocal.id);
        toast("Lista criada com sucesso!", "success");
        getEventLists();
        closeAddListModal();
      } catch (error: any) {
        if (error.response.data.message.includes("A data está fora do periodo do evento")) {
          return toast("Data posterior à data de encerramento do evento.", "error");
        }
      } finally {
        hideLoading();
      }
    },
    [closeAddListModal, currentLocal, eventId, getEventLists, hideLoading, showLoading, toast]
  );

  return {
    showMultipleAccessModal,
    openMultipleAccessModal,
    closeMultipleAccessModal,
    handleGrantMultipleAccess,
    adminAccess,
    nameFilter,
    promoterFilter,
    closingDate,
    baseListFilter,
    getEventLists,
    filterByList,
    filterByBaseList,
    filterByPromoter,
    filterByClosingDate,
    filteredEventGuestLists,
    openAddListModal,
    closeAddListModal,
    showAddListModal,
    handleAddList,
  };
};