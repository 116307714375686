import { FC, useEffect } from "react";
import MyEvents from "../components/myEvents/MyEvents";
import { UseEventsPage } from "./UseEventsPage";
import styles from "./EventsPage.module.scss";
import FilterEventList from "../components/FilterEvents/FilterEventList";
import AlertTips from "components/breadcumbs/components/tips/AlertTips";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";

const EventsPage: FC = () => {
  const {
    eventsData,
    getMore,
    filter,
    eventsLoading,
    totalRecords,
    onChangeFilter,
    resume,
    goToAddEvents,
    eventsResumeLoading,
    getEventCardResumeItem,
    integrated,
  } = UseEventsPage();

  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
      updateRouters([
        {
          title: "Evento/Caixa",
        },
      ]);

      return () => {
      updateRouters([]);
      };
  }, [updateRouters]);


  return (
    <div id={styles.EventsPage}>
      <AlertTips
        tips={{
          id: "@eventsTips",
          value:
            "Bem-vindo a nova tela de eventos. Agora com navegação mais intuitiva.",
        }}
      />
      <FilterEventList
        resume={resume}
        title={""}
        values={filter}
        onChangeFilter={onChangeFilter}
        onSubmitSearchFilter={onChangeFilter}
        goToAddEvents={goToAddEvents}
        eventsResumeLoading={eventsResumeLoading}
      />
      <div className={styles.content}>
        <MyEvents
          events={eventsData}
          getEvents={getMore}
          loading={eventsLoading}
          totalRecords={totalRecords}
          scrollabelId={styles.EventsPage}
          pagination={filter}
          getEventCardResumeItem={getEventCardResumeItem}
          integrated={integrated}
        />
      </div>
    </div>
  );
};
export default EventsPage;
