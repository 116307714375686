import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditDeviceCatalogUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceCatalog/EditDeviceCatalogUseCase';
import GetDeviceCatalogListUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceCatalog/GetDeviceCatalogListUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IDeviceCatalogItem, IDeviceCatalogValue } from '../../components/deviceProfile/profileForms/deviceCatalogForm/IDeviceCatalogForm';

const UseDeviceCatalog = (path: string = "/private/pdv/perfil") => {

    const [catalogs, setCatalogs] = useState<IDeviceCatalogItem[]>();
    // const [deviceCatalogsDefault, setDeviceCatalogsDefault] = useState<IDeviceCatalogValue[]>();
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal()

    const getCatalogsList = useCallback(async (localId: string) => {
        try {
            showLoading()
            const deviceProfileService = DeviceProfileApi();
            const response = await GetDeviceCatalogListUseCase(deviceProfileService, localId)
            setCatalogs(response);
        } finally {
            hideLoading()
        }
    }, [hideLoading, showLoading])



    const editDeviceCatalog = useCallback(async (profileId: string, CatalogValues: IDeviceCatalogValue[]) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                const response = await EditDeviceCatalogUseCase(deviceProfileService, currentLocal.id, profileId, CatalogValues);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);

            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        catalogs,
        getCatalogsList,
        editDeviceCatalog,
    })



}

export default UseDeviceCatalog