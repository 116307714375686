import { ApiError } from "components/ui/apiError/ApiError";
import { Empty } from "components/ui/empty/Empty";
import { GraphLoading } from "components/ui/graphLoading/GraphLoading";
import { FC } from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ICheckinTableData } from "../checkinTable/ICheckinTable";
import styles from "./CheckinPerHourBarChart.module.scss";

export interface IBarChartProps {
  //propertys
  data?: ICheckinTableData;
  isLoading?: boolean;
  error?: string;
}
const CheckinPerHourBarChart: FC<IBarChartProps> = ({ data, isLoading, error }) => {
  if (isLoading) {
    return (
      <div id={styles.BarChart}>
        <div className={styles.loading}>
          <GraphLoading />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div id={styles.BarChart}>
        <div className={styles.filter}>
          <h2>Entrada</h2>
        </div>
        <div className={styles.loading}>
          <ApiError errorMessage="Falha ao obter dados" />
        </div>
      </div>
    );
  }

  if (data?.customersChekinPerHour.length === 0) {
    return (
      <div id={styles.BarChart}>
        <div className={styles.loading}>
          <Empty />
        </div>
      </div>
    );
  }

  return (
    <div id={styles.BarChart}>
      {data && (
        <ResponsiveContainer width={"100%"} height={200}>
          <BarChart
            data={data?.customersChekinPerHour.map((item) => ({
              time: item.date ? new Date(`${item.date}Z`).toLocaleTimeString([], {
                timeStyle: "short",
                hour12: false
              }) : '',
              Masculino: item.male,
              Feminino: item.female,
              "Não informado": item.unknown,
            }))}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="time"
              tickLine={false}
              label={{ value: "Horário", position: "insideBottomRight", dy: 10, fill: "var(--text)" }}
            />
            <YAxis
              tickLine={false}
              label={{ value: "Pessoas", position: "top", dy: -15, fill: "var(--text)" }}
            />
            <Tooltip />
            <Legend pointsAtX={150} verticalAlign="top" align="right" wrapperStyle={{ paddingBottom: 15 }} />
            <Bar dataKey="Masculino" stackId="a" fill="#4D13B7" />
            <Bar dataKey="Feminino" stackId="a" fill="#955CFF" />
            <Bar dataKey="Não informado" stackId="a" fill="#A2A2A2" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
export default CheckinPerHourBarChart;
