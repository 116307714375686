import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { isValid } from "date-fns";
import { IDiscountListResponse } from "modules/events/domain/dto/IEventsResponse";
import { IEvents } from "modules/events/domain/models/IEvents";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import {
  IDiscountProductListForm,
  IDiscountProductListFormErrors,
  IDiscountProductType,
} from "modules/events/presentation/pages/discountsList/components/discountListEditForm/interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "modules/events/presentation/pages/discountsList/components/discountListEditForm/interfaces/IProductList";
import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { moneyMaskNumber, moneyToFloat } from "services/utils/Money";
import Utils from "services/utils/Utils";

interface IUseDiscountProductListForm {
  changeDiscountTypeHandle: (value: string) => void;
  errors: IDiscountProductListFormErrors;
  changeProductHandle: (productItem: IProductItemToDiscount) => void;
  changeHandle: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  submitHandle: () => Promise<void>;
  changeDiscountValueHandle: (value: string) => void;
  values: IDiscountProductListForm;
  closeModal: () => void;
}

const UseDiscountProductListForm = (
  onSubmit: (value: IDiscountProductListForm) => Promise<any>,
  onClose: () => void,
  discounts: IDiscounts[],
  defaultDiscount?: IDiscounts | null,
  event?: IEvents
) => {
  const { hideLoading, showLoading, toast } = useUi();
  const [values, setValues] = useState<IDiscountProductListForm>({} as IDiscountProductListForm);
  const [errors, setErrors] = useState<IDiscountProductListFormErrors>({});
  const [validateDiscountsList, setValidateDiscountsList] = useState<string[]>([]);

  const closeModal = () => {
    onClose();
  };

  const validate = useCallback((values: IDiscountProductListForm) => {
    let _errors: IDiscountProductListFormErrors = {};
    let hasError = false;
    if (!values.validDate) {
      _errors = { ..._errors, validDate: "Campo obrigatório" };
      hasError = true;
    } else if (!isValid(values.validDate)) {
      _errors = { ..._errors, validDate: "Data inválida" };
      hasError = true;
    }
    if (!values.validTime) {
      _errors = { ..._errors, validTime: "Campo obrigatório" };
      hasError = true;
    } 
  
    setErrors(_errors);
    return !hasError;
  }, []);
  const submitHandle = useCallback(async () => {    
  
    values.id = defaultDiscount ? defaultDiscount.id : undefined;
    values.type = IDiscountProductType.money;
    if (
      validate(
        {
          ...values,
          validDate: values.validDate,
        }
      )
    ) {
      showLoading();
      try {
        await onSubmit({
          ...values,
          validTime: values.validTime,
        });
        closeModal();
      } catch (error) {
        // toast("Falha ao adicionar desconto!", "error");
      } finally {
        hideLoading();
      }
    }
  }, [values, validateDiscountsList]);

  const changeDiscountValueHandle = useCallback((value: string) => {
    value = values?.product?.price
      ? moneyToFloat(value) > values.product.price
        ? moneyMaskNumber(values.product.price)
        : value
      : '0'
    setValues((prev) => ({
      ...prev,
      discount: (prev.product?.price || 0) - moneyToFloat(value),
    }));
  }, [values]);

  const changeProductHandle = useCallback((productItem: IProductItemToDiscount) => {
    setValues((prev) => ({ ...prev, product: productItem, discount: 0 }));
  }, []);

  return {
    values,
    setValues,
    changeDiscountValueHandle,
    submitHandle,
    changeProductHandle,
    errors,
    closeModal
  };
};

export default UseDiscountProductListForm;
