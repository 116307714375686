import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import CrmConfigPage from "modules/config/integrationsConfig/presentation/pages/CrmConfigPage";
import { FC, useEffect } from "react";

export interface IAutomationsPageProps {
  //propertys
}

const AutomationsPage: FC<IAutomationsPageProps> = () => {
  const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
        {
            title: "Integrações",
        },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

  return <CrmConfigPage />;
};
export default AutomationsPage;
