import { IServiceScheduleForm } from 'modules/schedule/presentation/components/serviceForm/interfaces/IServiceForm';
import React from 'react'
import { IServiceScheduleApiCB } from '../../interfaces/api/IServicesScheduleApi';

export const GetServiceByIdUseCase = async (serviceId: string, _serviceApi: IServiceScheduleApiCB): Promise<IServiceScheduleForm> => {
    const servicesApi = _serviceApi();
    const response = await servicesApi.getServicesById(serviceId);

    const service: IServiceScheduleForm = {
        id: response.id,
        duration: response.duration,
        productId: response.productId,
        durationType: response.durationType,
        description: response.description,
        moreDescriptions: response.moreDescriptions.map(item => ({ id: item.id, description: item.description, title: item.title })),
        name: response.name,
        value: response.value,
        showcaseImagesUrls: response.showcaseImages?.map(item => item.url),
        serviceCategoryId: response.serviceCategoryId,
        serviceCategoryName: response.serviceCategoryName,
        imageUrl: response.imageUrl,
        otherValues: response.otherValues.map(item => ({ description: item.description, value: item.value, productListId: item.productListId, maxAge: item.maxAge, minAge: item.minAge }))
    }
    return service;
}
