import React, { useEffect } from 'react'
import CustomerCategoriesPage from './CustomerCategoriesPage'
import Layout from 'components/layout/presentation/Layout'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';

const CustomerCategories = () => {
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
        {
            title: "Clientes",
        },
        {
            label: "Clientes",
            title: "Categoria de clientes",
        },
        ]);

        return () => {
        updateRouters([]);
        };
    }, [updateRouters]);

    return (
        <Layout>
            <CustomerCategoriesPage />
        </Layout>
    )
}

export default CustomerCategories
