import styles from './MeepFeePage.module.scss'
import { UseMeepFeePage } from '../hooks/UseMeepFeePage'
import { MeepFeeItem } from '../components/meepFeeItem/MeepFeeItem';
import SkeletonMeepFeeList from '../components/meepFeeItem/skeleton/SkeletonMeepFeeList';

export const MeepFeePage = () => {
  const { isLoading, feeList } = UseMeepFeePage();

  return (
    <div id={styles.MeepFeePage}>
      {isLoading 
      ? <SkeletonMeepFeeList /> 
      : feeList.length > 0 
        ? (
          <table>
            <tr>
              <th>Taxa</th>
              <th>Valor</th>
            </tr>
            {feeList.map(item => {
              return (
                <MeepFeeItem item={item} />
              );
            })}
          </table>
        )
        : <div>Nenhuma taxa encontrada</div>}
    </div>
  )
}
