import axios from "axios"
import { IGetIoticketsEventByUrlResponse } from "modules/crm/models/dtos/ioticketsEvents/IGetIoticketsEventByUrlResponse"
import { IIoticketService } from "modules/crm/models/interface/IIoticketService"

const IoticketService = (): IIoticketService => {
    const getEventByUrl = async (url: string): Promise<IGetIoticketsEventByUrlResponse> => {
        const response = await axios.get<IGetIoticketsEventByUrlResponse>(`https://ingressos.meep.com.br/events/api/v1/public/event/url?url=${url}&appType=WEB`, { headers: { tenant_id: "meep-tickets-tenant" } })
        return response.data
    }
    return ({ getEventByUrl })
}
export default IoticketService