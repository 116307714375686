import { ISmartPrinterApiCB } from "../api/ISmartPrinterApi";

const GetSmartPrinterByIdUseCase = async (smartPrintersApi: ISmartPrinterApiCB, id: string) => {
    const response = await smartPrintersApi().getSmartPrinterById(id);
    return response
}
export default GetSmartPrinterByIdUseCase



