export interface IAddressRequest {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface IAttractionsRequest {
  id?: string;
  name: string;
  imageUrl: string;
}

export interface IOpeningDatesRequest {
  startDate: string;
  endDate: string;
}

export interface IEventsRequest {
  name: string;
  imageUrl: string;
  address?: IAddressRequest;
  attractions: IAttractionsRequest[];
  description?: string;

  openingDates: IOpeningDatesRequest[];
  latitude?: number | null;
  longitude?: number | null;
  localId: string;
  /**
   * Date ISOString
   */
  availableForSalesStart: string;
  /**
   * Date ISOString
   */
  availableForSalesEnd: string;
}

export interface IEditEventRequest {
  id: string;
  name: string;
  imageUrl: string;
  description?: string;
  attractions: IAttractionsRequest[];
  openingDates: IOpeningDatesRequest[];
  /**
   * Date ISOString
   */
  availableForSalesStart: string;
  /**
   * Date ISOString
   */
  availableForSalesEnd: string;
}

export interface IEditEventListRequest {
  baseListId?: string
  closingDateTime: string;
  description: string;
  eventId?: string;
  discountLimit?: number;
  isActive: boolean;
  mainPromoterId: string;
  name: string;
  promotersIdsWithAccess?: string[];
  useDiscountLimit: boolean;
  copyConfiguration: boolean;
  copyGuests: boolean;
  validUntil?: string;
  shareEnabled?: boolean,
  shareUrl?: string,
  shareDescription?: string,
}

export interface IPriceDiscount {
  price: number;
}

export interface IPercentDiscountRequest {
  percent: number;
}

export interface IEventDiscountRequest {
  percentDiscount?: IPercentDiscountRequest;
  priceDiscount?: IPriceDiscount;
  validUntil: string;
  productId: string;
}

export interface IEventsListRequest {
  baseListId?: string;
  closingDateTime: string;
  description: string;
  discountLimit?: number;
  eventId: string;
  mainPromoterId: string;
  name: string;
  promotersIdsWithAccess: string[]
  useDiscountLimit?: boolean;
  copyConfiguration: boolean;
  copyGuests: boolean;
  validUntil?: string;

  shareEnabled?: boolean,
  shareUrl?: string,
  shareDescription?: string,
}

export interface IGuestRequest {
  peopleListId: string;
  cpfs: string[];
  IgnoreAlert: boolean;
}

export interface IAddDiscountToListRequest {
  baseListId: string;
  productId: string;
  percentualDiscount?: {
    percentual: number;
  };
  priceDiscount?: {
    price: number;
  };
  validUntil?: string;
}

export interface IDiscountsRequest {
  productId: string;
  percentualDiscount?: {
    percentual: number;
  };
  priceDiscount?: {
    price: number;
  };
}

export enum DiscountProductTypeEnum {
  price = 1,
  percentual = 2,
}

export interface IEditDiscountsRequest {
  id: string;
  productId: string;
  discountValue: number;
  discountProductType: DiscountProductTypeEnum;
  validUntil?: string | undefined
}

export interface ICreateDiscountListRequest {
  name: string;
  description: string;
  localId: string;
  discounts: IDiscountsRequest[];
}

export interface IPromoterRequest {
  id: string;
  name: string;
  cpf: string;
}

export interface IAddPromoterRequest {
  peopleListId: string;
  cpf: string;
}

export interface IAddMultiplePromotersRequest {
  eventId: string;
  cpfs: string[];
}

export interface IMigrateGuestsRequest {
  cpfs: string[];
}

export interface ICreateTicketRequest {
  localClienteId: string;
  name: string;
  price: number;
  imageUrl: string;
  genderRestriction?: number;
  maxQuantityPerUser: number;
  type: number;
  adultHood: boolean;
  expireTime: number | null;
}

export interface IUpdateTicketRequest {
  localClienteId: string;
  name: string;
  price: number;
  imageUrl: string;
  genderRestriction?: number;
  maxQuantityPerUser: number;
  type: number;
  adultHood: boolean;
  expireTime: number | null;
  isActive?: boolean;
}

export interface IUpdateGuestListLinkRequest {
  peopleListId?: string;
  linkName: string;
  linkDescription: string;
  maxPeoplesOnList: number;
  enable: boolean;
  visibleInApp: boolean;
}
