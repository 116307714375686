import styles from "./AnticipateForm.module.scss";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { UseAnticipateForm } from "./UseAnticipateForm";
import { IAtecipateFormItem } from "./interfaces/IAntecipateForm";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import Sidesheet from "components/sidesheet/Sidesheet";
import { formatarValor } from "../../pages/utils";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { UseContaDigitalPage } from "../../pages/UseContaDigitalPage";
import { UseAntecipateReceivedPage } from "../../pages/antecipateReceived/UseAntecipateReceivedPage";
import { CircularProgress } from "@material-ui/core";
interface AntecipateFormProps {
  onSubmit: (values: IAtecipateFormItem) => void;
  open: null | string;
  setOpen: React.Dispatch<React.SetStateAction<string | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const AntecipateForm = ({
  open,
  setOpen,
  setStep,
  step,
  onSubmit,
}: AntecipateFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { errors, values, onChangeHandle, onSubmitHandle } =
    UseAnticipateForm(onSubmit);
  const { getDigitalAccountBalance, contasDigitais, balance, loadingBalance } =
    UseContaDigitalPage();
  const { postSimulateAnticipate, simulate } = UseAntecipateReceivedPage();

  const handleSubmit = () => {
    onSubmitHandle();
  };

  useEffect(() => {
    if (values.conta) {
      getDigitalAccountBalance(values.conta);
    }
  }, [values.conta]);

  useEffect(() => {
    if (balance) {
      onChangeHandle("valor", balance?.balanceResult.balanceToRelease);
    }
  }, [balance]);

const handleSimulate = () => {
  if (values.conta && values.valor) {
    postSimulateAnticipate(values.conta, Number(values.valor));
  }
}

  useEffect(() => {
    const valorReceber =
      simulate && simulate.valor - simulate.taxa - simulate.taxaAplicada;
    onChangeHandle("valorReceber", valorReceber || 0);
  }, [simulate]);

  return (
    <>
      <Sidesheet
        open={open}
        onClose={() => {
          setOpen(null);
          setStep(1);
          onChangeHandle("senha", "");
          onChangeHandle("conta", "");
          onChangeHandle("valor", "");
        }}
        title={
          <h2>
            Nova <b>antecipação</b>
          </h2>
        }
        content={
          <div id={styles.AntecipateForm}>
            <div className={styles.selectAccount}>
              <FormItemContainer label={"Conta Bancária"} required>
                <Autocomplete
                  className={styles.select}
                  options={contasDigitais || []}
                  getOptionLabel={(option) => option.description}
                  onChange={(event, value) => {
                    onChangeHandle("conta", value ? value?.id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecione a conta"
                      variant={"outlined"}
                      {...params}
                      error={!!errors.conta}
                      helperText={errors.conta}
                    ></TextField>
                  )}
                />
              </FormItemContainer>
            </div>
            {loadingBalance && (
              <div className={styles.loading}>
                <CircularProgress color="primary" size={26}></CircularProgress>
              </div>
            )}
            {balance && values.conta && !loadingBalance && (
              <>
                <div className={styles.saldo}>
                  <span className={styles.text_value}>
                    Disponível para antecipação
                  </span>
                  <span className={styles.value_available}>
                    {formatarValor(balance.balanceResult.balanceToRelease)}
                  </span>
                </div>
                <FormItemContainer label={"Valor da antecipação"} required>
                  <Input
                    name={"valor"}
                    value={values.valor}
                    onChange={(ev) => {
                      onChangeHandle(ev.target.name, ev.target.value);
                    }}
                    onBlur={handleSimulate}
                    required
                    variant="outlined"
                    error={!!errors.valor}
                    helperText={errors.valor}
                    placeholder="R$ 0,00"
                    type="number"
                    disabled={balance.balanceResult.balanceToRelease === 0}
                  />
                </FormItemContainer>
                <p className={styles.infos}>
                  Taxa de antecipação:{" "}
                  <b>{simulate && formatarValor(simulate.taxaAplicada)}</b>
                </p>
                <p className={styles.infos}>
                  Taxa de processamento:{" "}
                  <b>{simulate && formatarValor(simulate.taxaProcessamento)}</b>
                </p>
                <div className={styles.saldo}>
                  <span className={styles.text_value}>Valor a receber</span>
                  <span className={styles.value_receive}>
                    {formatarValor(
                      values.valorReceber > 0 && simulate
                        ? values.valorReceber - simulate.taxaProcessamento
                        : values.valorReceber
                    )}
                  </span>
                </div>
                <FormItemContainer label={"Senha"} required>
                  <Input
                    name={"senha"}
                    type={showPassword ? "text" : "password"}
                    value={values.senha}
                    onChange={(ev) => {
                      onChangeHandle(ev.target.name, ev.target.value);
                    }}
                    required
                    variant="outlined"
                    error={!!errors.senha}
                    helperText={errors.senha}
                    placeholder="Insira sua senha"
                    InputProps={{
                      endAdornment: (
                        <div
                          className={styles.password}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </div>
                      ),
                    }}
                  />
                </FormItemContainer>
              </>
            )}
          </div>
        }
        currentStep={step}
        totalSteps={2}
        continueButton="Antecipar"
        handleContinueButton={handleSubmit}
        cancelButton
        feedback={
          <SidesheetFeedback
            text={"Antecipação realizada com sucesso!"}
            success
          />
        }
      />
    </>
  );
};
export default AntecipateForm;
