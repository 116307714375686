import { IGetOperadorService, IReponseOperadores } from "../interfaces/IGestaoVendasService";

const GetOperador = async (
  accountService: IGetOperadorService,
  id: string
): Promise<IReponseOperadores[]> => {
  return await accountService.getOperador(id);
};

export default GetOperador;
