import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import AutoComplete from "components/ui/autoComplete/AutoComplete";
import Button from "components/ui/Button/Button";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { IDiscounts } from "modules/events/presentation/components/discounts/interfaces/IDiscounts";
import React, { FC } from "react";
import { moneyMaskNumber } from "services/utils/Money";
import Utils from "services/utils/Utils";
import { IDiscountProductListForm, IDiscountProductType } from "../../interfaces/IDiscountProductListForm";
import { IProductItemToDiscount } from "../../interfaces/IProductList";
import styles from "./DiscountProductForm.module.scss";
import UseDiscountProductForm from "./UseDiscountProductForm";

export interface IDiscountProductFormProps {
  onSubmit: (value: IDiscountProductListForm, type: 'EDIT' | 'ADD') => Promise<any>;
  productList: IProductItemToDiscount[];
  onClose: () => void;
  defaultValue?: IDiscounts | null;
  discountsList: IDiscounts[];
}
const DiscountProductForm: FC<IDiscountProductFormProps> = ({
  onSubmit,
  onClose,
  productList,
  defaultValue,
  discountsList
}) => {
  const {
    submitHandle,
    changeHandle,
    changeProductHandle,
    changeDiscountValueHandle,
    changeDiscountTypeHandle,
    values,
    errors,
    closeModal,
  } = UseDiscountProductForm(onSubmit, onClose, productList, defaultValue, discountsList);

  return (
    <div id={styles.DiscountPeopleForm}>
      <div className={styles.container}>
        <form onSubmit={submitHandle}>
          <FormItemContainer>
            <FormItemContainer>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="tipo"
                  defaultValue={IDiscountProductType.percentual}
                  name="type"
                  onChange={(_ev, value) => changeDiscountTypeHandle(value)}
                  value={values?.type}
                >
                  <FormControlLabel value={IDiscountProductType.percentual} control={<Radio />} label="Percentual" />
                  <FormControlLabel value={IDiscountProductType.money} control={<Radio />} label="Valor" />
                </RadioGroup>
              </FormControl>
            </FormItemContainer>
            <FormItemContainer label={"Selecione o produto"}>
              <AutoComplete
                options={productList}
                noOptionsText="Sem opções"
                defaultValue={defaultValue ? {
                  id: defaultValue?.productId,
                  name: defaultValue?.productName,
                  price: defaultValue?.price
                } : undefined}
                value={values?.product}
                variant="outlined"
                getOptionLabel={(option) => `${option.name} - R$${option.price}`}
                onChange={(_value, item) => changeProductHandle(item as IProductItemToDiscount)}
                error={!!errors.product}
                helperText={errors.product}
                disabled={values?.product != null}
              />
            </FormItemContainer>
            
            {values?.type === "Percentual" ? (
              <FormItemContainer label={"Desconto (%)"}>
                <Input
                  variant="outlined"
                  error={!!errors.discount}
                  helperText={errors.discount}
                  required
                  name={"discount"}
                  endAdornmentText={"%"}
                  value={values?.discount || ""}
                  onChange={changeHandle}
                />
              </FormItemContainer>
            ) : (
              <FormItemContainer label={"Desconto (R$)"}>
                <Input
                  variant="outlined"
                  error={!!errors.discount}
                  helperText={errors.discount}
                  required
                  name={"discount"}
                  value={moneyMaskNumber(values?.discount)}
                  onChange={(ev) => changeDiscountValueHandle(ev.target.value)}
                />
              </FormItemContainer>
            )}
            {/* {values.product &&
              values.discount > 0 &&
              (values.type === IDiscountProductType.percentual ? (
                <FormItemContainer row label="Valor final">
                  <p>{moneyMaskNumber(values.product?.price - (values.product.price * (values.discount / 100)))}</p>
                </FormItemContainer>
              ) : (
                <FormItemContainer row label="Valor final">
                  <p>{moneyMaskNumber(values.product?.price - values.discount)}</p>
                </FormItemContainer>
              ))} */}
          </FormItemContainer>
        </form>
        <div className={styles.containerButton}>

          <Button onClick={closeModal} fullWidth={false} variant={"outlined"}>
            Cancelar
          </Button>
          <Button type={"button"} fullWidth={false} onClick={() => submitHandle()}>
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DiscountProductForm;
