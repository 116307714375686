import { Switch, TextField } from "@mui/material";
import { Button } from "@material-ui/core";
import styles from "./segmentForm.module.scss";
import { DividerText } from "components/dividerText/dividerText";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { FC, useCallback, useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { validateCPF } from "./utils";
import { useDropzone } from "react-dropzone";
import { EnumCouponStep } from "../../CouponForm";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

type SegmentFormTypes = {
  onChangeFormStep: (value: EnumCouponStep) => void;
  cpfs: string[];
  setCpfs: React.Dispatch<React.SetStateAction<any[]>>;
  setIsRequiredCPF: React.Dispatch<React.SetStateAction<boolean>>;
  isRequiredCPF: boolean;
};

export const formatCPF = (cpf: string) =>
  cpf
    ?.replace(/\D/g, "")
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

export const SegmentForm: FC<SegmentFormTypes> = ({
  onChangeFormStep,
  cpfs,
  setCpfs,
  setIsRequiredCPF,
  isRequiredCPF,
}) => {
  const [invalidData, setInvalidData] = useState<string[]>([]);
  const { toast } = useUi();

  function csvToJSON(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const csv = e.target?.result as string;
        const lines = csv.split("\n");
        const result: string[] = [];

        for (let i = 0; i < lines.length; i++) {
          const values = lines[i].split(",");
          result.push(...values);
        }

        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error("Error reading the CSV file."));
      };

      reader.readAsText(file);
    });
  }

  const splitOnPast = (data: string) => {
    return data.split(";").map((d) => d.trim());
  };

  const handleCPFS = (data: string[]) => {
    const length = data.length;
    const validCPFs = [] as string[];
    const invalidCPFs = [] as string[];

    for (let i = 0; i <= length; i++) {
      if (validateCPF(data[i])) {
        validCPFs.push(formatCPF(data[i]));
      } else {
        invalidCPFs.push(formatCPF(data[i]));
      }

      if (i === length) {
        const filterInvalids = invalidCPFs.filter(
          (cpf) => cpf !== undefined && cpf !== ""
        );
        if (filterInvalids.length > 0) {
          setInvalidData(filterInvalids);
        }

        if (cpfs.length > validCPFs.length) {
          setCpfs(Array.from(new Set(validCPFs)));
        } else {
          setCpfs((prev) => {
            const array = [...prev, ...validCPFs];
            return Array.from(new Set(array));
          });
        }
      }
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      try {
        const csvData = await csvToJSON(file);
        handleCPFS(csvData);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    onDropRejected: () => toast("A extensão do arquivo deve ser .csv", "error"),
  });

  return (
    <div id={styles.SegmentForm}>
      <div>
        <Switch
          color="secondary"
          checked={isRequiredCPF}
          value={isRequiredCPF}
          name="forFirstOrder"
          onChange={(_, value) => {
            setIsRequiredCPF(value);
          }}
        />{" "}
        Vincular CPF's
      </div>

      {isRequiredCPF && (
        <>
          <DividerText>
            Digite os CPF’s separados por ponto e virgula “ ; “
          </DividerText>
          <label>
            CPF
            <TagsInput
              value={cpfs}
              onChange={handleCPFS}
              onlyUnique
              addKeys={[9, 13, 186]}
              addOnPaste
              pasteSplit={splitOnPast}
              inputProps={{
                placeholder: "Digite o CPF",
              }}
            />
          </label>
          <DividerText>ou importe uma planilha no formato .CSV</DividerText>
          <div
            {...getRootProps()}
            className={
              isRequiredCPF ? styles.uploadButton : styles.disabledUploadButton
            }
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>
                <span>Solte o arquivo .csv</span>
              </p>
            ) : (
              <>
                <span>Fazer upload</span>
                <FileUploadIcon />
              </>
            )}
          </div>

          {invalidData.length > 0 && (
            <p className={styles.errorText}>
              <strong>CPFs inválidos removidos da lista:</strong>{" "}
              {invalidData.join(", ")}
            </p>
          )}
        </>
      )}

      <div className={styles.containerButton}>
        <Button
          variant="outlined"
          className={styles.outlined}
          onClick={() => onChangeFormStep(EnumCouponStep.DETALHES)}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          className={styles.contained}
          onClick={() => onChangeFormStep(EnumCouponStep.OPERACAO)}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};
