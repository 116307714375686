import { ITerminalService } from "../interface/ITerminalService"
import { PatchUpdateTerminalConfigRequest } from "../dtos/PatchUpdateTerminalConfigRequest";
import { PatchUpdateTerminalConfigResponse } from "../dtos/PatchUpdateTerminalConfigResponse";

const PatchUpdateTerminalConfigUseCase = async (service: ITerminalService, localId: string, request: PatchUpdateTerminalConfigRequest): Promise<PatchUpdateTerminalConfigResponse> => {
  const response = await service.patchUpdateTerminalConfig(localId, request);
  return (response);
}

export default PatchUpdateTerminalConfigUseCase;
