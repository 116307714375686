import { Drawer, Icon, IconButton } from '@material-ui/core';
import styles from './DeleteModal.module.scss';

interface DeleteMoralProps {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export const DeleteModal = ({ open, loading, onClose, onDelete }: DeleteMoralProps) => {
    return (
        <Drawer anchor='right' open={open} onClose={onClose}>

            <div className={styles.container}>
                <header>
                    <h1>Excluir <b>&nbsp;link</b></h1> <IconButton onClick={onClose}><Icon>close</Icon></IconButton>
                </header>
                <div className={styles.alertBox}>
                    <img src="/assets/img/alert.png" alt="" />
                    <span>Tem certeza que deseja <b>excluir</b> esse link ?</span>
                </div>

                <div className={styles.buttonsContainer}>
                    <button className={styles.buttons} onClick={onClose}>Voltar</button>

                    <button disabled={loading} onClick={onDelete} className={styles.buttons}>{
                        loading ? 'Excluindo...' :
                            'Excluir'
                    }</button>
                </div>
            </div>
        </Drawer>

    )
}