import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditDeviceInvoiceUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/deviceInvoice/EditDeviceInvoiceUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IDeviceInvoiceValue } from '../../components/deviceProfile/profileForms/deviceInvoiceForm/interfaces/InvoiceValues';

const UseDeviceInvoice = (path: string = "/private/pdv/perfil") => {

    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();


    const editDeviceInvoice = useCallback(async (profileId: string, InvoiceValues: IDeviceInvoiceValue) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                const response = await EditDeviceInvoiceUseCase(deviceProfileService, currentLocal.id, profileId, InvoiceValues);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);

            } else {
                console.error("local não selecionado");
            }
        } catch (error) {
            console.error(error)
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        editDeviceInvoice,
    })
}

export default UseDeviceInvoice