import { ImportExport } from "@mui/icons-material";
import styles from "./SolicitationsHeader.module.scss";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { IFilterParams } from "../../interface/IFilterParams";

export interface ISolicitationsHeaderProps {
  filter: IFilterParams;
  setFilter: React.Dispatch<React.SetStateAction<IFilterParams>>;
}

const titleHeader = [
  {
    title: "Local",
    value: "document",
  },
  {
    title: "Adquirente",
    value: "acquirerType",
  },
  {
    title: "Data da solicitação",
    value: "createdAt",
  },
  {
    title: "Número para liberação",
    value: "integrationCode",
  },
  {
    title: "Número de serial",
    value: "serialNumber",
  },
  {
    title: "Ação",
    value: "status",
  },
];

const SolicitationsHeader = ({ filter, setFilter }: ISolicitationsHeaderProps) => {
  const [orderBy, setOrderBy] = useState({ column: "createdAt" } as {
    orientation: "ASC" | "DESC",
    column: string,
  });

  useEffect(() => {
    if (orderBy.column && orderBy.orientation) {
      setFilter((prev) => ({ ...prev, orientation: orderBy.orientation, column: orderBy.column }));
    }
  }, [orderBy, setFilter]);  

  return (
    <div id={styles.SolicitationsHeader}>
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
          >
            {item.title}
            {!!item.value && (
              <IconButton
                disableRipple
                onClick={() => {
                  setOrderBy({ orientation: filter.orientation === "ASC" ? "DESC" : "ASC", column: item.value });
                }}
              >
                <ImportExport />
              </IconButton>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SolicitationsHeader;
