import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react'
import styles from './CrmCampaignForm.module.scss'
import CrmTypeCampaignSelector from '../crmTypeCampaignSelector/CrmTypeCampaignSelector'
import { InputContainer } from '../_inputContainer/InputContainer'
import { InputCRM } from '../_inputCRMFilter/InputCRM'
import { ICampaignFormValue, CampaignChannel, CampaignTemplate, ICampaignWhatsapp, ICampaignEmail, ICampaignValues, ICampaignPush, CampaignStatus } from '../../intefaces/ICampaign'
import TempateCampaignEventEmail from '../templateCampaignEventEmail/TempateCampaignEventEmail'
import TempateCampaignEventWhatsapp from '../templateCampaignEventWhatsapp/TempateCampaignEventWhatsapp'
import { Button } from '@mui/material'
import { ICrmEvent } from '../../intefaces/ICrmEventData'
import QuantityChannelAlert from '../quantityChannelAlert/QuantityChannelAlert'
import { ICrmSignature } from '../../pages/configCrmEmail/ConfigCrmEmail'
import TempateCampaignEventPush from '../templateCampaignEventPush/TempateCampaignEventPush';
import { addDays, format, isAfter, isValid } from 'date-fns'
import { ISurveyItem } from 'modules/automation/presentation/interfaces/ISurvey'
import { messageBodySurveyEmail } from '../templateCampaignEventEmail/messageBodyEmailSurvey'
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext'
import { PremiumModal } from 'modules/plans/presentation/components/premiumModal/PremiumModal'
import { FeatureTypeEnum } from 'modules/plans/presentation/interfaces/FeatureTypeEnum'
import GetTotalReportUseCase from 'modules/crm/application/useCases/plans/GetTotalReportUseCase'
import CrmServiceApi from 'services/api/crm/CrmApi'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { ChannelEnum } from '../../interfaces/ChannelEnum'
import { AccessTimeOutlined } from '@material-ui/icons'

const ALLOW_PUSH = true
const service = CrmServiceApi();

export interface ICrmCampaignFormProps {
    //propertys
    events: ICrmEvent[],
    defaultValues?: ICampaignFormValue,
    onClickSave: (values: ICampaignFormValue) => void
    onClickSaveAndSend: (values: ICampaignFormValue) => void
    onClickImport?: () => void
    whatsappStatus?: boolean
    campaingValues: ICampaignValues
    signature?: ICrmSignature
    surveys?: ISurveyItem[]
}
const CrmCampaignForm: FC<ICrmCampaignFormProps> = ({ events, defaultValues, onClickImport, onClickSave, onClickSaveAndSend, whatsappStatus, campaingValues, signature, surveys }) => {
    const { checkPermission } = usePlans();
    const { currentLocal } = useLocal();
    const [modalOpened, setModalOpened] = useState(false);
    const [emailsRemaining, setEmailsRemaining] = useState('');
    const [pushsRemaining, setPushsRemaining] = useState('');

    const [errors, setErrors] = useState<{
        name?: string,
        channel?: string,
        template?: string,
        whatsapp?: {
            event?: string,
            message?: string
        },
        email?: {
            event?: string,
            from?: string,
            replyTo?: string,
            subject?: string,
            // greeting:string,
            messageBody?: string,
            survey?: string
        }
        push?: {
            date?: string;
            time?: string;
            imageUrl?: string;
            subject?: string;
            messageBody?: string;
        }
    }>();

    const [values, setValues] = useState<ICampaignFormValue>({
        name: "",
        channel: CampaignChannel.email,
        template: currentLocal?.systemIdentifier !== 1 ? CampaignTemplate.event : CampaignTemplate.info,
        email: {
            event: {
                id: "",
                name: "",
                startDate: "",
            },
            messageBody: "",
            replyTo: "",
            subject: "",
            promoter: "",
            survey: {
                id: "",
                trackConfigurationId: "",
                trackSurveyId: "",
                trackSurveyName: "",
            }
        },
        whatsapp: {
            event: {
                id: "",
                name: "",
                startDate: "",
            },
            message: ""
        },
        push: {
            date: "",
            time: "",
            imageUrl: "",
            subject: "",
            messageBody: "",
        }
    })

    useEffect(() => {
        if (defaultValues) {
            setValues({ ...defaultValues })
        }
        if (currentLocal?.systemIdentifier === 1 && !defaultValues?.template) {
            setValues((prev) => ({ ...prev, template: CampaignTemplate.info }));
        }
    }, [currentLocal?.systemIdentifier, defaultValues])

    useEffect(() => {
        (async () => {
            try {
                const response = await GetTotalReportUseCase(service, currentLocal!.id);
                setEmailsRemaining(response.find(x => x.channel === ChannelEnum.Email)?.report.totalAvailable.toString() || '');
                setPushsRemaining(response.find(x => x.channel === ChannelEnum.Push)?.report.totalAvailable.toString() || '');
            } catch { }
        })();
    }, [currentLocal]);

    const validateForm = useCallback(() => {
        setErrors(undefined)
        let hasError = false;
        if (values.name.length < 4) {
            setErrors(prev => ({ ...prev, name: "Insira um nome válido" }))
            hasError = true;
        }
        if (values.channel === CampaignChannel.whatsapp) {
            if (!values.template) {
                setErrors(prev => ({ ...prev, template: "Selecione um template" }))
                hasError = true;
            }
            if (values.template === CampaignTemplate.event) {
                if (!values.whatsapp?.event) {
                    setErrors(prev => ({ ...prev, whatsapp: { ...prev?.whatsapp, event: "Selecione um evento" } }))
                    hasError = true;
                }
                if (!values.whatsapp?.message || values.whatsapp?.message === "" || (values.whatsapp?.message && values.whatsapp?.message.length < 3)) {
                    setErrors(prev => ({ ...prev, whatsapp: { ...prev?.whatsapp, message: "Insira uma mensagem válida" } }))
                    hasError = true;
                }
            }
        } else if (values.channel === CampaignChannel.push) {
            let dateCompleted = true;
            if (!values.push?.subject) {
                setErrors(prev => ({ ...prev, push: { ...prev?.push, subject: 'Informe um título para a notificação' } }))
                hasError = true;
            }
            if (!values.push?.messageBody) {
                setErrors(prev => ({ ...prev, push: { ...prev?.push, subject: 'Informe um texto para a notificação' } }))
                hasError = true;
            }

            if (!values.push?.date) {
                setErrors(prev => ({ ...prev, push: { ...prev?.push, date: 'Informe uma data' } }))
                hasError = true;
                dateCompleted = false;
            }
            if (!values.push?.time) {
                setErrors(prev => ({ ...prev, push: { ...prev?.push, time: 'Informe um horário' } }))
                hasError = true;
                dateCompleted = false;
            }

            if (dateCompleted) {
                // const date = parse(values.push?.date + ' ' + values.push?.time, 'dd/MM/yyyy HH:mm', new Date())
                const date = new Date(values.push?.date + ' ' + values.push?.time)

                if (!isValid(date)) {
                    setErrors(prev => ({ ...prev, push: { ...prev?.push, date: 'Data e/ou hora inválidos' } }))
                    hasError = true;
                }
                // else if (isBefore(date, addDays(new Date(), 1))) {
                //     setErrors(prev => ({ ...prev, push: { ...prev?.push, date: 'Informe uma data e hora com pelo menos 24 horas de diferença' } }))
                //     hasError = true;
                // }                 
                // else if (isBefore(date, addMinutes(new Date(), 1))) {
                //     setErrors(prev => ({ ...prev, push: { ...prev?.push, date: 'Informe uma data e hora com pelo menos 5 minutos de diferença' } }))
                //     hasError = true;
                // }
                else if (isAfter(date, addDays(new Date(), 7))) {
                    setErrors(prev => ({ ...prev, push: { ...prev?.push, date: 'Informe uma data e hora com no máximo 7 dias de diferença' } }))
                    hasError = true;
                }

                // if (!isValid(values.push?.time)) setErrors(prev => ({ ...prev, push: { ...prev?.push, time: 'Horário inválido' } }))
                // else if (values.push?.date && isBefore(values.push?.date?.setTime(new Date(values.push!.time!).getTime()), new Date())) setErrors(prev => ({ ...prev, push: { ...prev?.push, time: 'A hora precisa ser superior à hora atual' } }))
            }

        } else {
            if (!values.template) {
                setErrors(prev => ({ ...prev, template: "Selecione um template" }))
                hasError = true;
            }
            if (values.template === CampaignTemplate.event && !values.email?.event) {
                setErrors(prev => ({ ...prev, email: { ...prev?.email, event: "Selecione um evento" } }))
                hasError = true;
            }
            if (!values.email?.subject || values.email?.subject === "") {
                setErrors(prev => ({ ...prev, email: { ...prev?.email, subject: "Insira um assunto válido" } }))
                hasError = true;
            }
            if (!values.email?.messageBody || values.email?.messageBody === "") {
                setErrors(prev => ({ ...prev, email: { ...prev?.email, messageBody: "Insira uma mensagem válida" } }))
                hasError = true;
            }
            if (values.template === CampaignTemplate.survey) {
                if (!values.email?.survey) {
                    setErrors(prev => ({ ...prev, email: { ...prev?.email, survey: "Selecione uma pesquisa" } }))
                    hasError = true;

                }
            }
        }

        return !hasError;

    }, [values])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);
    const changeTypeHandle = useCallback((value: CampaignChannel) => {
        setValues((prev) => ({ ...prev, channel: value }));
    }, []);

    const changeTemplateHandle = useCallback((value: CampaignTemplate) => {
        var body = "";
        if (value === CampaignTemplate.survey) {
            body = messageBodySurveyEmail();
        }
        setValues((prev) => ({ ...prev, template: value, messageBody: body }));
    }, [])

    const changeWhatsAppTemplate = useCallback((values: ICampaignWhatsapp) => {
        setValues((prev) => ({ ...prev, whatsapp: values }))
    }, [])

    const changePushTemplate = useCallback((values: ICampaignPush) => {
        setValues((prev) => ({ ...prev, template: CampaignTemplate.info, push: values }))
    }, [])

    const changeEmailTemplate = useCallback((values: ICampaignEmail) => {
        setValues((prev) => ({ ...prev, email: values }))
    }, [])

    const onClickSaveHandle = useCallback(() => {
        onClickSave?.(values);
    }, [onClickSave, values])

    const onClickSaveAndSendHandle = useCallback(() => {
        if (!checkPermission(FeatureTypeEnum.CRM)) setModalOpened(true)
        else if (validateForm()) {
            onClickSaveAndSend?.(values);
        }
    }, [checkPermission, onClickSaveAndSend, validateForm, values])

    const Instruction = memo((props: { number: number, text?: string, strong?: string, lastPart?: string }) => (
        <div className={styles.instructions}>
            <div className={styles.number}>
                {props.number}
            </div>
            {!!props.text && props.text}
            {!!props.strong && <span className={styles.strong}>{props.strong}</span>}
            {!!props.lastPart && <span>{props.lastPart}</span>}
        </div>
    ))

    const CheckBox = memo((props: { disabled?: boolean, label: string, description: string, value: boolean; onChange: (value: boolean) => void; soon?: boolean; cursor?: string }) => {
        return (
            <button
                onClick={() => props.onChange(true)}
                disabled={props.disabled}
                className={`${styles.checkBox} ${props.value ? styles.selected : ''}`}
                style={{ cursor: props.cursor ?? "pointer" }}
            >
                <h1>{props.label}</h1>
                {props.soon && (
                    <div className={styles.soon}>
                        <AccessTimeOutlined className={styles.icon}/>
                        <p>Em breve</p>
                    </div>
                )}
                <p>{props.description}</p>
            </button>
        )
    })

    return (
        <>
            <form id={styles.CrmCampaignForm}>
                {
                    campaingValues.reprovedActivity && (
                        <div className={styles.reprovedContainer}>
                            <img src="/assets/icon/warning.svg" alt="" />
                            <span>
                                Seu push foi <strong>reprovado</strong> em {format(new Date(campaingValues.reprovedActivity.createdAt), "dd/MM/yyyy 'às' HH:mm")} porque [{campaingValues.reprovedActivity.description.replace(': ', '')}]. Altere sua campanha e envie novamente para análise.
                            </span>
                        </div>
                    )
                }
                <InputContainer>
                    <InputContainer style={{ marginBottom: 32, width: "100%" }}>
                        <Instruction number={1} text={"Primeiro dê um"} strong="nome para sua campanha:" />
                        <InputCRM error={!!errors?.name} helperText={errors?.name} label={"Nome da campanha"} placeholder="Digite um nome" name={"name"} value={values.name} onChange={changeHandle} />
                    </InputContainer>

                    <div className={styles.separator} />

                    <InputContainer style={{ marginBottom: 32, width: "100%" }}>
                        <Instruction number={2} text={"Escolha os"} strong="destinatários:" />
                        <div>
                            <span className={styles.totalCustomers}>{campaingValues.totalCustomers} destinatários selecionados</span>
                            {/* <b> Ver todos</b> */}
                        </div>
                    </InputContainer>

                    <div className={styles.separator} />

                    <InputContainer style={{ marginBottom: 32, width: "100%" }}>
                        <Instruction number={3} text={"Qual a"} strong="forma de envio" lastPart="da comunicação?" />
                        <CrmTypeCampaignSelector
                            disbledEmail={campaingValues.status !== CampaignStatus.Draft}
                            whatsappStatus={whatsappStatus}
                            onChange={changeTypeHandle}
                            value={values.channel}
                            allowPush={ALLOW_PUSH && currentLocal?.systemIdentifier !== 1}
                            emailsRemaining={emailsRemaining}
                            pushsRemaining={pushsRemaining}
                        />
                        {
                            values.channel !== CampaignChannel.push &&
                            <QuantityChannelAlert
                                selectedChannel={values.channel}
                                totalCustomer={campaingValues.totalCustomers}
                                quantityCustomersWithPhonenumber={campaingValues.totalCustomersPhoneNumber}
                                quantityCustomersWithEmail={campaingValues.totalCustomersEmail}
                            />
                        }
                    </InputContainer>
                    {
                        values.channel !== CampaignChannel.push ?
                            <>
                                <InputContainer style={{ marginBottom: 32, width: "100%" }}>
                                    <Instruction number={4} text={"Personalize sua"} strong="comunicação:" />
                                    <span className={styles.customComunicationTitle}>Sobre o que você quer falar?</span>
                                    <div className={styles.checkboxContainer} style={{ border: `solid  #c00 ${!!errors?.template ? 1 : 0}px` }}>
                                        {currentLocal?.systemIdentifier !== 1 && (
                                            <CheckBox
                                                label={'Convidar para um evento'}
                                                description={'Use para convidar à pessoas específicas para o seu evento, juntamente com o link para compra de ingressos'}
                                                value={values.template === CampaignTemplate.event}
                                                onChange={(value: boolean) => {
                                                    changeTemplateHandle(CampaignTemplate.event)
                                                }}
                                            />
                                        )}
                                        <CheckBox
                                            // disabled
                                            label={'Informar sobre algo'}
                                            description={'Use para avisar aos seus clientes sobre alteração de endereço e horário, por exemplo'}
                                            value={values.template === CampaignTemplate.info}
                                            onChange={(value: boolean) => {
                                                changeTemplateHandle(CampaignTemplate.info)
                                            }}
                                        />
                                        <CheckBox
                                            // disabled
                                            label={'Pesquisa de satisfação'}
                                            description={'Use para enviar uma pesquisa de satisfção e descobrir como foi a experiencia do seu cliente'}
                                            value={values.template === CampaignTemplate.survey}
                                            onChange={(value: boolean) => {
                                                changeTemplateHandle(CampaignTemplate.survey)
                                            }}
                                            soon={currentLocal?.systemIdentifier === 1}
                                            cursor={currentLocal?.systemIdentifier === 1 ? 'default' : ''}
                                            disabled={currentLocal?.systemIdentifier === 1}
                                        />
                                    </div>
                                    <div className={styles.helperText}>{errors?.template}</div>
                                </InputContainer>
                                {/* {values.template === CampaignTemplate.event && onClickImport &&
                                    <InputContainer>
                                        <Button variant='outlined' className={styles.importButton} onClick={onClickImport}><Icon>file_download</Icon> Importar eventos</Button>
                                    </InputContainer>
                                } */}
                                <InputContainer style={{ width: "100%" }}>
                                    {values.template === CampaignTemplate.event && values.channel === CampaignChannel.email &&
                                        <TempateCampaignEventEmail
                                            campaign={values}
                                            events={events}
                                            defaultMessageBody={defaultValues?.email?.messageBody}
                                            values={values.email}
                                            erros={errors?.email}
                                            onChange={changeEmailTemplate}
                                            template={values.template}
                                            requireEvent
                                        />
                                    }
                                    {values.template === CampaignTemplate.info && values.channel === CampaignChannel.email &&
                                        <TempateCampaignEventEmail
                                            campaign={values}
                                            events={events}
                                            defaultMessageBody={defaultValues?.email?.messageBody}
                                            values={values.email}
                                            erros={errors?.email}
                                            onChange={changeEmailTemplate}
                                            template={values.template}
                                        />
                                    }

                                    {values.template === CampaignTemplate.survey && values.channel === CampaignChannel.email &&
                                        <TempateCampaignEventEmail
                                            campaign={values}
                                            defaultMessageBody={defaultValues?.email?.messageBody}
                                            values={values.email}
                                            erros={errors?.email}
                                            surveys={surveys}
                                            onChange={changeEmailTemplate}
                                            template={values.template}
                                        />
                                    }
                                    {values.template === CampaignTemplate.event && values.channel === CampaignChannel.whatsapp &&
                                        <TempateCampaignEventWhatsapp
                                            events={events}
                                            campaign={values}
                                            values={values.whatsapp}
                                            onChange={changeWhatsAppTemplate}
                                            errors={errors?.whatsapp}
                                        />
                                    }
                                    {values.template === CampaignTemplate.info && values.channel === CampaignChannel.whatsapp &&
                                        <div>Not Implemented</div>
                                    }



                                </InputContainer>
                            </> :
                            ALLOW_PUSH &&
                            <TempateCampaignEventPush
                                values={values.push}
                                onChange={changePushTemplate}
                                errors={errors?.push}
                                instruction={[
                                    <Instruction number={4} strong="Escolha uma data" lastPart='para sua notificação:' />,
                                    <Instruction number={5} text={"Personalize sua"} strong="comunicação:" />
                                ]}
                            />
                    }

                    <InputContainer row>
                        <Button className={styles.saveButton} variant='outlined' color={'primary'} onClick={onClickSaveHandle}>{campaingValues.status === CampaignStatus.Draft ? "Salvar rascunho" : "Salvar campanha"}</Button>
                        {campaingValues.status === CampaignStatus.Draft && <Button className={styles.sendButton} disabled={!signature?.isActive} variant='contained' color={'primary'} onClick={onClickSaveAndSendHandle}>
                            Enviar campanha
                            {!checkPermission(FeatureTypeEnum.CRM) && <img src="/assets/icon/golden-crown.svg" alt="" style={{ marginLeft: 8 }} />}
                        </Button>}
                    </InputContainer>
                </InputContainer>
            </form >
            <PremiumModal opened={modalOpened} onClose={() => setModalOpened(false)} />
        </>
    )
}
export default CrmCampaignForm
