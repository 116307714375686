import { ICrmService } from "modules/crm/models/interface/ICrmService"
import { CampaignChannel } from "modules/crm/presentation/intefaces/ICampaign"
import { ICrmPlan, ICrmSignature } from "modules/crm/presentation/pages/configCrmEmail/ConfigCrmEmail"

export interface SaveSignatureUseCaseProps {
    service: ICrmService;
    ownerId: string;
    ownerName: string;
    userId: string
    userName: string;
    plan: ICrmPlan;
    channel: CampaignChannel;
    isActive?: boolean;
}

const SaveSignatureUseCase = async ({service, ownerId, ownerName, plan, channel, userId, userName, isActive = true}: SaveSignatureUseCaseProps): Promise<ICrmSignature> => {
    const request = {
        channel,
        isActive,
        ownerId,
        ownerName,
        planId: plan.id,
        userName,
        userId
    }
    const response = await service.saveSignature(request)
    return (response)
}
export default SaveSignatureUseCase