import { LinearProgress } from '@material-ui/core';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './IframgePage.module.scss'
import { v4 } from 'uuid';

export interface IframePageProps {
    //propertys
    src?: string;
    delay?: number;
    fullSrc?: string;
    fullScreen?: boolean;
}

export const IframePage: FC<IframePageProps> = ({ src = "", fullScreen, fullSrc, delay = 3000 }) => {

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { currentLocal } = useLocal();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (delay) {
            setIsLoading(true)
            const timer = setTimeout(() => {
                setIsLoading(false)
            }, delay);

            return () => {
                clearTimeout(timer)
                setIsLoading(false)
            }
        }
    }, [delay]);

    const sendMessage = useCallback(() => {
        if (currentLocal) {
            iframeRef.current?.contentWindow?.postMessage({
                auth: localStorage.getItem(`@token`),
                src,
                localCorrente: {
                    localId: currentLocal.id,
                    nomeLocal: currentLocal.name
                }
            }, "*");
        }
    }, [currentLocal, src]);

    const iframe = useMemo(() => <div id={styles.IframgePage}>
        {isLoading && <LinearProgress className={styles.linearProgressBar} />}
        <iframe ref={iframeRef} title={'Meep'} src={fullSrc ?? (process.env.REACT_APP_OLD_PORTAL_URL + src + "?v=" + v4())} width={'100%'} height={'100%'} style={{ border: "none", padding: 0, visibility: isLoading ? "hidden" : "visible", borderRadius: 8, maxHeight: `100%`, minHeight: fullScreen ? '99vh' : 'calc(100vh - 120px)', overflow: 'hidden' }} onLoad={sendMessage} />
    </div>, [fullScreen, fullSrc, isLoading, sendMessage, src])

    return (iframe);
};

