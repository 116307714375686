import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import EditOperationParamsUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/operationParams/EditOperationParamsUseCase';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import { IOperationParamsValues } from '../../components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues';

const UseOperationParams = (path: string = "/private/pdv/perfil") => {
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();


    const editOperationParam = useCallback(async (profileId: string, ParamValues: IOperationParamsValues) => {
        try {
            if (currentLocal) {
                showLoading()
                const deviceProfileService = DeviceProfileApi();
                await EditOperationParamsUseCase(deviceProfileService, currentLocal.id, profileId, ParamValues);
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        editOperationParam,
    })
}


export default UseOperationParams