import { useEffect, useCallback } from "react";
import { Icon, IconButton, MenuItem, TextField } from "@material-ui/core";
import { ICorporatePartnerValues, EMPTY_PARTNER_VALUES, ISegmentItem } from "./ICorporatePartnerValues.types";
import { useForm, useFieldArray, Form } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CorporatePartnerValidatorSchema } from "./CorporatePartnerValidatorSchema";
import { PartnerType } from "modules/meepErp/models/partner/PartnerType";
import InputMask from "react-input-mask";
import { ButtonContainer, Container, InputContainer, Row } from "../../../ui/form/FormContainers";
import { PartnerOriginType } from "modules/meepErp/models/partner/PartnerOriginType";
import SelectOriginTypeForm from "../selectPartnerOriginType/SelectOriginTypeForm";
import { Autocomplete } from "@material-ui/lab";
import { UFlist } from "modules/meepErp/presentation/utils/UFlist";
import Back from "../../../ui/back/Back";
import Button from "components/ui/Button/Button";
import { useHistory } from "react-router-dom";

export interface IPartnerFormProps {
    onChangeOriginType: (originType: PartnerOriginType) => void;
    isLoading?: boolean;
    disabled?: boolean;
    segments: ISegmentItem[];
    defaultValues?: ICorporatePartnerValues;
    onSubmit: (values: ICorporatePartnerValues) => Promise<any>;
}

const CorporatePartnerForm: React.FC<IPartnerFormProps> = ({ onSubmit, segments, defaultValues, isLoading, disabled, onChangeOriginType }) => {
    const { push } = useHistory()
    const { control, register, handleSubmit, reset, setValue, formState, watch } = useForm<ICorporatePartnerValues>({
        defaultValues: EMPTY_PARTNER_VALUES,
        resolver: zodResolver(CorporatePartnerValidatorSchema)
    });

    const contacts = useFieldArray({ control, name: "contacts" });
    const values = watch(); // usado para colocar valores no select


    useEffect(() => {
        console.log("formErros", formState.errors);
    }, [formState.errors]);


    const onSubmitHandler = useCallback(async (data: ICorporatePartnerValues) => {
        console.log(data);
        await onSubmit(data);
    }, [onSubmit]);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        } else {
            reset(EMPTY_PARTNER_VALUES)
        }
    }, [defaultValues, reset, segments]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Container >
                <Back route='/private/meeperp/partners' margin />
                {isLoading && <span>Carregando...</span>}
                <Container subContainer={true} title={"1. Dados Gerais"}  >
                    {!defaultValues && <SelectOriginTypeForm
                        disabled={disabled && !!defaultValues}
                        originType={PartnerOriginType.CORPORATE}
                        onChange={onChangeOriginType}
                    />}
                    <Row>
                        {!!defaultValues &&
                            <InputContainer flex={4} label="Código">
                                <TextField
                                    disabled
                                    {...register("code")}
                                />
                            </InputContainer>
                        }
                        <InputContainer htmlFor={"document"} flex={8} label='CNPJ' requerid>
                            <InputMask
                                mask={"99.999.999/9999-99"}
                                required
                                {...register("document")}

                            >
                                {() => <TextField
                                    disabled={disabled}
                                    {...register("document")}
                                    helperText={formState.errors.document?.message}
                                    error={!!formState.errors.document}
                                />
                                }</InputMask>
                        </InputContainer>

                    </Row>

                    <Row>
                        <InputContainer label="Razão Social" requerid>
                            <TextField
                                disabled={disabled}
                                {...register("tradingName")}
                                helperText={formState.errors.tradingName?.message}
                                error={!!formState.errors.tradingName}
                            />
                        </InputContainer >
                        <InputContainer label="Nome Fantasia" requerid>
                            <TextField
                                disabled={disabled}
                                {...register("name")}
                                helperText={formState.errors.name?.message}
                                error={!!formState.errors.name}
                            />
                        </InputContainer>


                    </Row>
                    <Row>
                        <InputContainer label="Inscrição Estadual">
                            <TextField
                                disabled={disabled}
                                {...register("stateRegistration")}
                                helperText={formState.errors.stateRegistration?.message}
                                error={!!formState.errors.stateRegistration}
                            />
                            {formState.errors.stateRegistration?.message}
                        </InputContainer>
                        <InputContainer label="Inscrição Municipal" flex={1}>
                            <TextField
                                disabled={disabled}
                                {...register("municipalRegistration")}
                                helperText={formState.errors.municipalRegistration?.message}
                                error={!!formState.errors.municipalRegistration}
                            />
                        </InputContainer>
                    </Row>
                    <Row>

                        <InputContainer label="Tipo" requerid maxWidth={watch('type') === PartnerType.Customer ? 300 : ''}>
                            <TextField
                                disabled={disabled}
                                select
                                defaultValue={PartnerType.Supplier}
                                {...register("type")}
                                value={values.type}
                                helperText={formState.errors.type?.message}
                                error={!!formState.errors.type}
                            >
                                <MenuItem value={PartnerType.Supplier}>Fornecedor</MenuItem>
                                <MenuItem value={PartnerType.Customer}>Cliente</MenuItem>
                            </TextField>
                        </InputContainer>
                        {watch('type') === PartnerType.Supplier &&
                            <InputContainer label="Segmento">
                                <Autocomplete
                                    options={segments}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        setValue("sectorTypeId", value?.id ?? null);
                                    }}
                                    value={segments.find((segment) => segment.id === values.sectorTypeId) ?? null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            error={!!formState.errors.sectorTypeId}
                                            helperText={formState.errors.sectorTypeId?.message}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </InputContainer>
                        }
                    </Row>
                    <Row>
                        <InputContainer label="Representante legal">
                            <TextField
                                disabled={disabled}
                                {...register("legalRepresentative.name")}
                                helperText={formState.errors.legalRepresentative?.name?.message}
                                error={!!formState.errors.legalRepresentative?.name}
                            />
                        </InputContainer>
                        <InputContainer label="CPF">

                            <InputMask
                                mask={"999.999.999-99"}
                                {...register("legalRepresentative.document")}

                            >
                                {() => <TextField
                                    disabled={disabled}
                                    {...register("legalRepresentative.document")}
                                    helperText={formState.errors.legalRepresentative?.document?.message}
                                    error={!!formState.errors.legalRepresentative?.document}
                                />}
                            </InputMask>
                        </InputContainer>
                    </Row>


                </Container>
                <Container subContainer={true} title="2. Contatos">
                    <Row>
                    <Button
                            color="primary"
                            fullWidth={false}
                            variant="outlined"
                            endIcon={<Icon
                                style={{ background: '#32008E', borderRadius: '50%', color: 'white' }}
                            >
                                add
                            </Icon>}
                            onClick={() => contacts.append({ name: "", phone: "", email: "" })}>
                            Novo Contato
                        </Button>
                    </Row>
                    {values.contacts.map((_, index) => (
                        <Row key={index}>
                            <InputContainer label="Nome">
                                <TextField
                                    disabled={disabled}

                                    //register={register(`contacts.${index}.name`)}
                                    {...register(`contacts.${index}.name`)}
                                    helperText={formState.errors.contacts?.[index]?.name?.message}
                                    error={!!formState.errors.contacts?.[index]?.name}
                                />
                            </InputContainer>
                            <InputContainer label="Telefone">
                                <InputMask
                                    mask={"(99) 99999-9999"}
                                    {...register(`contacts.${index}.phone`)}
                                >
                                    {() => <TextField
                                        disabled={disabled}
                                        {...register(`contacts.${index}.phone`)}

                                        helperText={formState.errors.contacts?.[index]?.phone?.message}
                                        error={!!formState.errors.contacts?.[index]?.phone}
                                    />}
                                </InputMask>
                            </InputContainer>
                            <InputContainer label="Email">
                                <TextField
                                    disabled={disabled}
                                    {...register(`contacts.${index}.email`)}
                                    helperText={formState.errors.contacts?.[index]?.email?.message}
                                    error={!!formState.errors.contacts?.[index]?.email}
                                />
                            </InputContainer>
                            {
                                <InputContainer flex={0}>
                                    <IconButton onClick={() => contacts.remove(index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </InputContainer>
                            }
                        </Row>
                    ))}

                </Container>
                <Container subContainer={true} title="3. Endereço">
                    <Row>
                        <InputContainer
                            maxWidth={400}
                            label="CEP">
                            <InputMask
                                mask={"99999-999"}
                                {...register("address.zipCode")}

                            >
                                {() => <TextField
                                    disabled={disabled}
                                    type="CEP"
                                    {...register("address.zipCode")}
                                    helperText={formState.errors.address?.zipCode?.message}
                                    error={!!formState.errors.address?.zipCode}
                                />}
                            </InputMask>
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer label="Endereço">
                            <TextField
                                disabled={disabled}
                                {...register("address.street")}
                                helperText={formState.errors.address?.street?.message}
                                error={!!formState.errors.address?.street}
                            />
                        </InputContainer>
                        <InputContainer label="Número">
                            <TextField
                                disabled={disabled}
                                {...register("address.number")}
                                helperText={formState.errors.address?.number?.message}
                                error={!!formState.errors.address?.number}
                            />
                        </InputContainer>
                        <InputContainer label="Bairro">
                            <TextField
                                disabled={disabled}
                                {...register("address.neighborhood")}
                                helperText={formState.errors.address?.neighborhood?.message}
                                error={!!formState.errors.address?.neighborhood}
                            />
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer label="Cidade">
                            <TextField
                                disabled={disabled}
                                {...register("address.city")}
                                helperText={formState.errors.address?.city?.message}
                                error={!!formState.errors.address?.city}
                            />
                        </InputContainer>
                        <InputContainer label="Estado">
                            <TextField
                                disabled={disabled}
                                select
                                defaultValue={PartnerType.Supplier}
                                {...register("address.state")}
                                value={values.address.state}
                            >
                                {
                                    UFlist.map((x: string) => (
                                        <MenuItem value={x}>
                                            {x}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </InputContainer>
                    </Row>
                </Container>
                <ButtonContainer>
                    <Button color='secondary' disabled={isLoading || disabled} onClick={() => push('/private/meeperp/partners')} fullWidth={false} variant='outlined'>Cancelar</Button>
                    <Button disabled={isLoading || disabled} type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                </ButtonContainer>

            </Container>
        </form >
    );
};



export default CorporatePartnerForm;