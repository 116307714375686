import { IEditEventRequest } from 'modules/events/domain/dto/IEventsRequest';
import { IEventsApi } from '../../interfaces/IEventsService';

const EditEventUseCase = async (
  eventsService: IEventsApi,
  event: IEditEventRequest,
  localId: string,
): Promise<void> => {
  return await eventsService.editEvent(event, localId);
};

export default EditEventUseCase;
