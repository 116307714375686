import { Button, Icon } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './ErroCrmMessageAlert.module.scss'
export interface IErroCrmMessageAlertProps {
    //propertys
    onClickResend: () => void
    totalFail: number
    onClickView?: () => void
    view?: boolean
}
const ErroCrmMessageAlert: FC<IErroCrmMessageAlertProps> = ({ onClickResend, totalFail, view, onClickView }) => {
    return (<div id={styles.ErroCrmMessageAlert}>
        <div className={styles.text}>
            <Icon>error</Icon>
            <p>Ao todo, <b>{totalFail} mensagens</b> não foram entregues</p>
        </div>
        {
            view ?
                <Button size="small" onClick={onClickView} variant={'text'} color={"primary"}>Visualizar</Button>
                :
                <Button size="small" onClick={onClickResend} variant={'text'} color={"primary"}>Enviar novamente</Button>
        }
    </div>

    )
}
export default ErroCrmMessageAlert