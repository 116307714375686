import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import CrmServiceApi from "services/api/crm/CrmApi";
import GetEventsByCategory from "modules/crm/application/useCases/getEventsByCategoryUseCase";
import { IGetCrmEventsResponse } from "modules/crm/models/dtos/IGetCrmEventsResponse";

const UseGetEventsByCategory = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [eventsByCategory, setEventsByCategory] =
    useState<IGetCrmEventsResponse>();
  const { currentLocal } = useLocal();

  const getEventsByCategory = useCallback(async () => {
    try {
      const serviceCrm = CrmServiceApi();
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetEventsByCategory(
          serviceCrm,
          currentLocal.id,
          ""
        );
        setEventsByCategory(response);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  return {
    eventsByCategory,
    isLoading,
    getEventsByCategory,
  };
};

export default UseGetEventsByCategory;
