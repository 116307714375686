import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import Button from "components/ui/Button/Button";
import styles from "./ChangeStockControlModal.module.scss";

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (control: boolean) => void;
}

export const ChangeStockControlModal = ({
  open,
  title,
  onClose,
  onConfirm,
}: ModalProps) => {
  return (
    <RightDrawer open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <span>Deseja controlar o estoque dos produtos?</span>
        <div className={styles.buttonsContainer}>
          <Button variant="text" fullWidth={false} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="outlined"
            fullWidth={false}
            onClick={() => onConfirm(false)}
          >
            Não
          </Button>
          <Button fullWidth={false} onClick={() => onConfirm(true)}>
            Sim
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};
