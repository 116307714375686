import { Icon } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './CrmTypeCampaignSelector.module.scss'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CampaignChannel } from "../../intefaces/ICampaign";
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { AccessTimeOutlined, InfoOutlined } from '@material-ui/icons';
export interface ICrmTypeCampaignSelectorProps {
    //propertys
    value?: CampaignChannel
    onChange: (values: CampaignChannel) => void
    disbledEmail?: boolean
    disbledPush?: boolean
    whatsappStatus?: boolean
    allowPush?: boolean;
    emailsRemaining?: string;
    pushsRemaining?: string;
}
const CrmTypeCampaignSelector: FC<ICrmTypeCampaignSelectorProps> = ({ value, onChange, disbledEmail, whatsappStatus, allowPush, emailsRemaining, pushsRemaining }) => {
    const { currentLocal } = useLocal();

    return (
        <div id={styles.CrmTypeCampaignSelector} >
            <button disabled={disbledEmail} className={`${styles.button} ${value === CampaignChannel.email ? styles.selected : ''}`} onClick={(ev) => { ev.preventDefault(); onChange(CampaignChannel.email) }} >
                <h3><Icon>mail</Icon>E-mail</h3>
                <div className={styles.bulletCard}>
                    <InfoOutlined className={styles.icon}/>
                    <p>{new Intl.NumberFormat("pt-BR").format(Number(emailsRemaining || 0))} restantes</p>
                </div>
                <div>Utilize para comunicar sobre mudanças ou enviar convite para um evento</div>
            </button>

            {
                allowPush &&
                <button disabled={false} className={`${styles.button} ${value === CampaignChannel.push ? styles.selected : ''}`} onClick={(ev) => { ev.preventDefault(); onChange(CampaignChannel.push) }}  >
                    <h3><Icon>notifications</Icon>Notificação via Push</h3>
                    <div className={styles.bulletCard}>
                        <InfoOutlined className={styles.icon}/>
                        <p>{pushsRemaining} restantes</p>
                    </div>
                    <div>Notifique seus clientes através das notificações do celular.</div>
                </button>
            }

            <button
                disabled={!whatsappStatus}
                className={`${styles.button} ${
                    value === CampaignChannel.whatsapp ? styles.selected : ""
                } ${currentLocal?.systemIdentifier === 1 ? styles.wppSaas : "" }`}
                onClick={(ev) => {
                    ev.preventDefault();
                    onChange(CampaignChannel.whatsapp);
                }}
                style={{ cursor: currentLocal?.systemIdentifier === 1 ? "default" : "pointer" }}
            >
                <h3><WhatsAppIcon />Whatsapp</h3>
                <div className={`${styles.bulletCard} ${styles.soon}`}>
                    <AccessTimeOutlined className={styles.icon}/>
                    <p>Em breve</p>
                </div>
                <div>Utilize para comunicar com seus clientes por meio do Whatsapp, de forma rápida e prática</div>
                {/* <div className={whatsappStatus ? styles.statusConfigured : styles.statusNotConfigured}>{whatsappStatus ? "Conectado" : "Em breve"}</div> */}
            </button>

        </div>
    )
}
export default CrmTypeCampaignSelector