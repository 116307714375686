export const formatMonthNumberFromString = (monthNumber: number) => {
    switch (monthNumber) {
        case 1: return 'jan';
        case 2: return 'fev';
        case 3: return 'mar';
        case 4: return 'abr';
        case 5: return 'mai';
        case 6: return 'jun';
        case 7: return 'jul';
        case 8: return 'ago';
        case 9: return 'set';
        case 10: return 'out';
        case 11: return 'nov';
        case 12: return 'dez';
        default: return '';
    }
}

export const variedColorsNewDash = [
    '#FFB300',
    '#00BFA5',
    '#FF7221',
    '#FF5760',
    '#9D82BF',
    '#FF7E7E',
    '#FFCC6E',
    '#7ED5C2',
    '#B09ACB',
    '#FD8643',
    '#8DCDC0',
    '#78B8E7',
    '#855072',
    '#3439C1',
    '#B76B00',
    '#6A6060',
    '#7D9092',
    '#5A7591',
    '#912846',
    '#991D1D',
    '#C52413',
    '#BC4F00',
    '#266A98',
];