import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';

const TextFieldMoney: FC<TextFieldProps> = (props) => {
    return <TextField
        {...props}
        value={props.value === null || isNaN(Number(props.value)) ? '' : moneyMaskNumber(Number(props.value))}
        onChange={(ev) => {
            props.onChange?.({
                ...ev,
                target: {
                    ...ev.target,
                    value: moneyToFloat(ev.target.value).toString()
                }
            });
        }} />;
};

export default TextFieldMoney;
