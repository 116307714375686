import {  IDefaultProfilesService, OperatiomMethodTypes } from "../interfaces/IGestaoVendasService";

const PostDefaultProfiles = async (
  accountService: IDefaultProfilesService,
  id: string,
  methods: OperatiomMethodTypes[],
): Promise<void> => {
  return await accountService.postDefaultProfiles(id, methods);
};

export default PostDefaultProfiles;