import React, { FC, useCallback, useMemo } from 'react'
import { IDeviceProfileItem } from './interfaces/IProfileItem'
import ProfileDeviceItem from './ProfileDeviceItem'
import styles from './ProfileDeviceList.module.scss'
import { Pagination } from '@mui/material';
import { IPaginationProfileDevice } from '../filterProfile/IFilterDeviceProfile';

interface ProfileListProps {
    profileList: IDeviceProfileItem[];
    onClickCopyItem?: (item: IDeviceProfileItem) => void;
    onClickDeleteItem?: (item: IDeviceProfileItem) => void;
    onClickChangeStatusItem?: (item: IDeviceProfileItem, enabled: boolean) => void;
    onClickItem: (item: IDeviceProfileItem) => void;
    nivelEvento?: boolean;
    onChangePaginationHandle: (pagination: IPaginationProfileDevice) => void;
    pagination?: IPaginationProfileDevice;
    totalCount: number;
    perfilVinculo?: number
    profileIdEventos?: any
}

const ProfileDeviceList: FC<ProfileListProps> = ({ 
    profileList, 
    onClickDeleteItem, 
    onClickChangeStatusItem, 
    onClickItem, 
    onClickCopyItem, 
    nivelEvento,
    onChangePaginationHandle,
    pagination,
    totalCount, 
    perfilVinculo,
    profileIdEventos
}) => {

    const List = useMemo(() => {
        const list = (nivelEvento && perfilVinculo === 2 ? profileIdEventos : profileList);

        if (!list) {
            return (
            <div className={styles.emptyList}>
                <img src="/assets/img/empty-box.png" alt=""/>
                <span>Você ainda <b>não possui</b> nenhum perfil de venda configurado!</span>
            </div>);
        }

        return (
        <div className={styles.listContainer}>
            {
                list?.map((profileItem : any) => (
                    <>
                    <ProfileDeviceItem
                        key={profileItem.id}
                        profileItem={profileItem}
                        onClickCopyItem={onClickCopyItem}
                        onClickItem={onClickItem}
                        onClickDelete={onClickDeleteItem}
                        onClickChangeStatus={onClickChangeStatusItem}
                        nivelEvento={nivelEvento}
                        perfilVinculo={perfilVinculo}
                    />
                    </>
                ))
            }              
        </div>
    )}, [onClickChangeStatusItem, onClickCopyItem, onClickDeleteItem, onClickItem, profileList, nivelEvento])

    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, offset: page - 1, limit: pageSize });
        },
        [onChangePaginationHandle, pagination]
    );
    
    return (
        <div id={styles.profileList}>
            {List}
            
            {totalCount > 50 && <div className={styles.containerPagination}>
                <Pagination
                    variant="outlined"
                    className={styles.paginationNumber}
                    shape="rounded"
                    count={Math.ceil(totalCount / 50)}
                    sx={{
                        '.MuiPaginationItem-root': {
                            borderColor: '#BDBDBD',
                            color: '#001537',
                        },
                        '.Mui-selected': {
                            fontWeight: 'bold',
                            borderColor: '#001537',
                        },
                    }}
                    onChange={(_, page) => onChangePageHandle?.(page, 50)}
                    page={((pagination?.offset ?? 0) / 50) + 1}
                />
            </div>}
        </div>
    );
}

export default ProfileDeviceList;